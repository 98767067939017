/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type withholdingAttachmentTaxPrintQueryVariables = {|
  type_tax?: ?string,
  start_date?: ?any,
  end_date?: ?any,
|};
export type withholdingAttachmentTaxPrintQueryResponse = {|
  +selfProject: ?{|
    +taxIdNumber: ?string
  |},
  +withholdingTaxViewer: ?{|
    +allWithholdingTax: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +totalPrepaidDeposit: ?number,
          +payRecordGroup: ?{|
            +id: string,
            +docNumber: string,
            +payRecord: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +whtAmount: number,
                  +whtRatePercent: ?number,
                  +purchaseRecord: {|
                    +id: string,
                    +preTaxAmount: number,
                    +prepaidDepositTransactionUsed: ?{|
                      +edges: $ReadOnlyArray<?{|
                        +node: ?{|
                          +amount: ?number,
                          +prepaidDepositTransaction: {|
                            +whtRatePercent: ?number,
                            +vat: ?number,
                          |},
                        |}
                      |}>
                    |},
                    +acceptCreditNoteTransaction: ?{|
                      +edges: $ReadOnlyArray<?{|
                        +node: ?{|
                          +price: number
                        |}
                      |}>
                    |},
                  |},
                |}
              |}>
            |},
          |},
          +otherExpense: ?{|
            +otherExpenseTransaction: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +preTaxAmount: ?number,
                  +whtRate: ?number,
                |}
              |}>
            |}
          |},
        |}
      |}>
    |},
    +summary: ?any,
  |},
|};
export type withholdingAttachmentTaxPrintQuery = {|
  variables: withholdingAttachmentTaxPrintQueryVariables,
  response: withholdingAttachmentTaxPrintQueryResponse,
|};
*/


/*
query withholdingAttachmentTaxPrintQuery(
  $type_tax: String
  $start_date: DateTime
  $end_date: DateTime
) {
  selfProject {
    taxIdNumber
    id
  }
  withholdingTaxViewer {
    allWithholdingTax(pnd: $type_tax, status: "active", startDate: $start_date, endDate: $end_date) {
      edges {
        node {
          id
          totalPrepaidDeposit
          payRecordGroup {
            id
            docNumber
            payRecord {
              edges {
                node {
                  id
                  whtAmount
                  whtRatePercent
                  purchaseRecord {
                    id
                    preTaxAmount
                    prepaidDepositTransactionUsed {
                      edges {
                        node {
                          amount
                          prepaidDepositTransaction {
                            whtRatePercent
                            vat
                            id
                          }
                          id
                        }
                      }
                    }
                    acceptCreditNoteTransaction(status: "paid") {
                      edges {
                        node {
                          price
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          otherExpense {
            otherExpenseTransaction {
              edges {
                node {
                  preTaxAmount
                  whtRate
                  id
                }
              }
            }
            id
          }
        }
      }
    }
    summary(status: "active", typeTax: $type_tax, startDate: $start_date, endDate: $end_date)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type_tax"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxIdNumber",
  "storageKey": null
},
v4 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "end_date"
},
v5 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "start_date"
},
v6 = {
  "kind": "Literal",
  "name": "status",
  "value": "active"
},
v7 = [
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "pnd",
    "variableName": "type_tax"
  },
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPrepaidDeposit",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtAmount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRatePercent",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preTaxAmount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "paid"
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": [
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "kind": "Variable",
      "name": "typeTax",
      "variableName": "type_tax"
    }
  ],
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "withholdingAttachmentTaxPrintQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WithholdingTaxViewer",
        "kind": "LinkedField",
        "name": "withholdingTaxViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "WithholdingTaxNodeConnection",
            "kind": "LinkedField",
            "name": "allWithholdingTax",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WithholdingTaxNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WithholdingTaxNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PayRecordGroupNode",
                        "kind": "LinkedField",
                        "name": "payRecordGroup",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PayRecordNodeConnection",
                            "kind": "LinkedField",
                            "name": "payRecord",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PayRecordNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PayRecordNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PurchaseRecordNode",
                                        "kind": "LinkedField",
                                        "name": "purchaseRecord",
                                        "plural": false,
                                        "selections": [
                                          (v8/*: any*/),
                                          (v13/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                            "kind": "LinkedField",
                                            "name": "prepaidDepositTransactionUsed",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PrepaidDepositTransactionUsedNode",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v14/*: any*/),
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "PrepaidDepositTransactionNode",
                                                        "kind": "LinkedField",
                                                        "name": "prepaidDepositTransaction",
                                                        "plural": false,
                                                        "selections": [
                                                          (v12/*: any*/),
                                                          (v15/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": (v16/*: any*/),
                                            "concreteType": "AcceptCreditNoteNodeConnection",
                                            "kind": "LinkedField",
                                            "name": "acceptCreditNoteTransaction",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AcceptCreditNoteNodeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AcceptCreditNoteNode",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v17/*: any*/)
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherExpenseNode",
                        "kind": "LinkedField",
                        "name": "otherExpense",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OtherExpenseTransactionNodeConnection",
                            "kind": "LinkedField",
                            "name": "otherExpenseTransaction",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OtherExpenseTransactionNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OtherExpenseTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v18/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "withholdingAttachmentTaxPrintQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WithholdingTaxViewer",
        "kind": "LinkedField",
        "name": "withholdingTaxViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "WithholdingTaxNodeConnection",
            "kind": "LinkedField",
            "name": "allWithholdingTax",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WithholdingTaxNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WithholdingTaxNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PayRecordGroupNode",
                        "kind": "LinkedField",
                        "name": "payRecordGroup",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PayRecordNodeConnection",
                            "kind": "LinkedField",
                            "name": "payRecord",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PayRecordNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PayRecordNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PurchaseRecordNode",
                                        "kind": "LinkedField",
                                        "name": "purchaseRecord",
                                        "plural": false,
                                        "selections": [
                                          (v8/*: any*/),
                                          (v13/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                            "kind": "LinkedField",
                                            "name": "prepaidDepositTransactionUsed",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PrepaidDepositTransactionUsedNode",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v14/*: any*/),
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "PrepaidDepositTransactionNode",
                                                        "kind": "LinkedField",
                                                        "name": "prepaidDepositTransaction",
                                                        "plural": false,
                                                        "selections": [
                                                          (v12/*: any*/),
                                                          (v15/*: any*/),
                                                          (v8/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      },
                                                      (v8/*: any*/)
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": (v16/*: any*/),
                                            "concreteType": "AcceptCreditNoteNodeConnection",
                                            "kind": "LinkedField",
                                            "name": "acceptCreditNoteTransaction",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AcceptCreditNoteNodeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AcceptCreditNoteNode",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v17/*: any*/),
                                                      (v8/*: any*/)
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherExpenseNode",
                        "kind": "LinkedField",
                        "name": "otherExpense",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OtherExpenseTransactionNodeConnection",
                            "kind": "LinkedField",
                            "name": "otherExpenseTransaction",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OtherExpenseTransactionNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OtherExpenseTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v18/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b348149036d62526df308b55347e234c",
    "id": null,
    "metadata": {},
    "name": "withholdingAttachmentTaxPrintQuery",
    "operationKind": "query",
    "text": "query withholdingAttachmentTaxPrintQuery(\n  $type_tax: String\n  $start_date: DateTime\n  $end_date: DateTime\n) {\n  selfProject {\n    taxIdNumber\n    id\n  }\n  withholdingTaxViewer {\n    allWithholdingTax(pnd: $type_tax, status: \"active\", startDate: $start_date, endDate: $end_date) {\n      edges {\n        node {\n          id\n          totalPrepaidDeposit\n          payRecordGroup {\n            id\n            docNumber\n            payRecord {\n              edges {\n                node {\n                  id\n                  whtAmount\n                  whtRatePercent\n                  purchaseRecord {\n                    id\n                    preTaxAmount\n                    prepaidDepositTransactionUsed {\n                      edges {\n                        node {\n                          amount\n                          prepaidDepositTransaction {\n                            whtRatePercent\n                            vat\n                            id\n                          }\n                          id\n                        }\n                      }\n                    }\n                    acceptCreditNoteTransaction(status: \"paid\") {\n                      edges {\n                        node {\n                          price\n                          id\n                        }\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n          otherExpense {\n            otherExpenseTransaction {\n              edges {\n                node {\n                  preTaxAmount\n                  whtRate\n                  id\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n    summary(status: \"active\", typeTax: $type_tax, startDate: $start_date, endDate: $end_date)\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26ef6784c3c68c2918f0214d71cf7bdf';

module.exports = node;
