/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProcurementFormStatus = "ACTIVE" | "APPROVE" | "DRAFT" | "EDIT" | "VOID" | "%future added value";
export type ProcurementFormType = "ASSET" | "PRODUCT" | "SERVICE" | "SERVICE_CONTACT" | "%future added value";
export type manageRequestTableQueryVariables = {|
  status?: ?string,
  first?: ?number,
  last?: ?number,
  search?: ?string,
  startDate?: ?any,
  endDate?: ?any,
|};
export type manageRequestTableQueryResponse = {|
  +allProcurementForm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +creditor: {|
          +id: string,
          +name: string,
        |},
        +buyDate: any,
        +type: ProcurementFormType,
        +status: ProcurementFormStatus,
        +total: ?number,
        +comparativeCompany: ?number,
        +lowestOffer: number,
        +highestOffer: number,
        +contractor: ?string,
        +urgent: boolean,
        +voidRemark: string,
        +editRemark: string,
        +approveDate: ?any,
        +voidDate: ?any,
        +voider: ?string,
        +approver: ?string,
        +procurementList: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +productAndService: ?{|
                +productCode: string,
                +name: string,
              |},
              +chartOfAccount: ?{|
                +name: string,
                +nameEn: ?string,
                +chartOfAccountCode: string,
              |},
              +description: string,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type manageRequestTableQuery = {|
  variables: manageRequestTableQueryVariables,
  response: manageRequestTableQueryResponse,
|};
*/


/*
query manageRequestTableQuery(
  $status: String
  $first: Int
  $last: Int
  $search: String
  $startDate: Date
  $endDate: Date
) {
  allProcurementForm(status: $status, first: $first, last: $last, search: $search, startDate: $startDate, endDate: $endDate) {
    edges {
      node {
        id
        docNumber
        creditor {
          id
          name
        }
        buyDate
        type
        status
        total
        comparativeCompany
        lowestOffer
        highestOffer
        contractor
        urgent
        voidRemark
        editRemark
        approveDate
        voidDate
        voider
        approver
        procurementList {
          edges {
            node {
              productAndService {
                productCode
                name
                id
              }
              chartOfAccount {
                name
                nameEn
                chartOfAccountCode
                id
              }
              description
              id
            }
          }
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "creditor",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buyDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comparativeCompany",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lowestOffer",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highestOffer",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractor",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urgent",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editRemark",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approveDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voider",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approver",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameEn",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "manageRequestTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ProcurementFormNodeConnection",
        "kind": "LinkedField",
        "name": "allProcurementForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProcurementFormNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProcurementFormNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProcurementListNodeConnection",
                    "kind": "LinkedField",
                    "name": "procurementList",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProcurementListNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProcurementListNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v26/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChartOfAccountNode",
                                "kind": "LinkedField",
                                "name": "chartOfAccount",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v27/*: any*/),
                                  (v28/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v29/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v30/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "manageRequestTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ProcurementFormNodeConnection",
        "kind": "LinkedField",
        "name": "allProcurementForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProcurementFormNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProcurementFormNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProcurementListNodeConnection",
                    "kind": "LinkedField",
                    "name": "procurementList",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProcurementListNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProcurementListNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v26/*: any*/),
                                  (v9/*: any*/),
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChartOfAccountNode",
                                "kind": "LinkedField",
                                "name": "chartOfAccount",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v27/*: any*/),
                                  (v28/*: any*/),
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v29/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v30/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9511fc22bf8bc61bad31b77b7e19ab55",
    "id": null,
    "metadata": {},
    "name": "manageRequestTableQuery",
    "operationKind": "query",
    "text": "query manageRequestTableQuery(\n  $status: String\n  $first: Int\n  $last: Int\n  $search: String\n  $startDate: Date\n  $endDate: Date\n) {\n  allProcurementForm(status: $status, first: $first, last: $last, search: $search, startDate: $startDate, endDate: $endDate) {\n    edges {\n      node {\n        id\n        docNumber\n        creditor {\n          id\n          name\n        }\n        buyDate\n        type\n        status\n        total\n        comparativeCompany\n        lowestOffer\n        highestOffer\n        contractor\n        urgent\n        voidRemark\n        editRemark\n        approveDate\n        voidDate\n        voider\n        approver\n        procurementList {\n          edges {\n            node {\n              productAndService {\n                productCode\n                name\n                id\n              }\n              chartOfAccount {\n                name\n                nameEn\n                chartOfAccountCode\n                id\n              }\n              description\n              id\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '906e11a42cda497388fed6191b2ff31e';

module.exports = node;
