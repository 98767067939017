import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

var token_id = localStorage.getItem("token");
var header = {
  authorization: `JWT ${token_id}`,
  "Content-Type": "application/json",
};

function getClaimReportALL(data) {
  return axios.get(`${getApiRoot()}insure/claim`, {
    headers: header,
    params: {
      report: data.report || "",
      start_date: data.start_date || "" ,
      end_date : data.end_date || "",
      search: data.search || "",
      status: data.status || "",
      order_by: "new",
      index_first : data.first || "",
      index_last: data.last || "",
    },
  });
}

function getClaimReportById(id) {
  return axios.get(`${getApiRoot()}insure/claim`, {
    headers: header,
    params: {
      claim_id: parseInt(id),
    },
  });
}

function getClaimSummaryReport(data) {
  return axios.get(`${getApiRoot()}insure/report_claim`, {
    headers: header,
    params: {
      start_date: data.start_date || "",
      end_date: data.end_date || "",
      claim_type: data.claim_type || "",
    },
  });
}

function getInsuranceCurrent() {
  return axios.get(`${getApiRoot()}insure/insurance`, {
    headers: header,
    params: {
      current: true,
    },
  });
}

export default {
  getClaimReportById,
  getClaimReportALL,
  getClaimSummaryReport,
  getInsuranceCurrent,
};
