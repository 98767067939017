import React from "react";
import "../../styles/commonArea.scss";
import Information from "./information";
import Accessories from "./accessories";
import Terms from "./Terms";
import PublicServiceOpening from "./publicServiceOpening";
import TimeLimit from "./timeLimit";
import iconBooking from "./iconBooking";
import { Translation } from "react-i18next";
const CommonAreaCreateStep1 = ({
    handleChange,
    state,
    addEquipment,
    addRule,
    subRule,
    delePDF,
    addHoliday,
    handleOpen,
    handleOpenNew,
    handleCheck24h,
    handleLimitTime,
    onChangeTime,
}) => {
    return (
        <Translation>
            {
                t =>
                    <React.Fragment>
                        <div className="claim">
                            <div className="create" style={{zoom:'80%'}}>
                                <label>
                                    <p className="headerLabel18 fw-600 ml-2">01 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:General information")}</span></p>
                                    <iconBooking.iconStep1 />
                                </label>
                                <label className="ml-2 mr-2">
                                    <p className="headerLabel18 fw-600 ml-2">02 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:Application reservation setting")}</span></p>
                                    <iconBooking.iconStep1_2 />
                                </label>
                                <label>
                                    <p className="headerLabel18 fw-600 ml-2">03 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:Facility picture")}</span></p>
                                    <iconBooking.iconStep1_3 />
                                </label>
                            </div>
                        </div>

                        <Information handleChange={handleChange} state={state} create />
                        <Accessories handleChange={handleChange} state={state} addEquipment={addEquipment} />
                        <Terms handleChange={handleChange} state={state} addRule={addRule} subRule={subRule}
                        
                        delePDF={delePDF}/>
                        <PublicServiceOpening
                            handleChange={handleChange}
                            state={state}
                            addHoliday={addHoliday}
                            handleOpen={handleOpen}
                            handleOpenNew={handleOpenNew}
                            handleCheck24h={handleCheck24h}
                            onChangeTime={onChangeTime}
                        />
                        {/* <Rights handleChange={handleChange} state={state} addRound={addRound} subRound={subRound} /> */}
                        {state.status === "temporarily_open" && (
                            <TimeLimit handleChange={handleChange} state={state} handleLimitTime={handleLimitTime} />
                        )}
                        <div className="row claim mt-10">
                            <div className="col-12 text-right">
                                <button className="btn-primary" type="submit">
                                    {t("Allaction:next")}
                                </button>

                            </div>
                            <div className="mt-10 mb-10" />
                        </div>
                    </React.Fragment>
            }
        </Translation>
    );
};

export default CommonAreaCreateStep1;