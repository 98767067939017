import React, { Component } from "react";
import environment from "../../env/environment";
import { commitMutation } from 'react-relay'
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from "i18next";
import Swal from 'sweetalert2';
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Switch from "../../components/Switch/Switch";
import Loading from "../../libs/loading";
import './settingReceiptDepositUse.scss';

const mutation = graphql`
    mutation settingReceiptDepositUseMutation ($input: UpdatReceiptDepositUsedSettingInput!) {
        updatReceiptDepositUsedSetting(input: $input) {
            ok
        }
}`

const query = graphql`
    query settingReceiptDepositUseQuery {
        receiptDepositViewer{
            getRdUsedSetting
        }  
}`

class SettingDepositUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
        canUseRd: false,
        loading: false,
        loadingSubmit: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({ loading: true })
    fetchQuery(environment, query, {}).then((result) => {
        this.setState({ 
            canUseRd: result.receiptDepositViewer.getRdUsedSetting,
            loading: false 
        })
    }).catch(err => {
        this.setState({ loading: false })
        console.log(err)
    })
  }

  handleChange(checked) {
        this.setState({
            canUseRd: checked,
        })
  }

  submit = () => {
    this.setState({ loadingSubmit: true })
    let { canUseRd } = this.state
    const variables = { 
        input: 
        {menuOn: canUseRd}
    };
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                if (response.updatReceiptDepositUsedSetting.ok) {
                    this.setState({ loadingSubmit: false })
                    Swal.fire(i18n.t("invoiceRoleSetting:Successful record"), "", "success").then(() => {
                        window.location.reload();
                    });                
                } else {
                    this.setState({ loadingSubmit: false })
                    Swal.fire(i18n.t("invoiceRoleSetting:Save failed"), "", "warning").then(() => {
                        window.location.reload();
                    });  
                }
            },
            onError: err => {
                console.error(err)
                this.setState({ loadingSubmit: false })
                Swal.fire(i18n.t("invoiceRoleSetting:Save failed"), "", "warning").then(() => {
                    window.location.reload();
                });  
            },
        },
    );
  }

  render() {
    let { loading, canUseRd, loadingSubmit } = this.state
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <div className="container-fluid box" id="setting-receiptdeposit-use">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/home">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("setting:setupReceiptDepositUse")}</span>}
                  </Translation>
                </h3>
              </div>
            </div>
            <div className="d-flex justify-content-center  mb-3  mt-5">
            <Translation>
                   { t =>(                    
                        !loading ?
                                    <div className="col-8">
                                        <div className="line">
                                            <div className="header mt-2 mb-2">
                                                {t('receiptDepositUseSetting:Usage setting')}
                                            </div>
                                        </div>

                                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span className="bold">
                                                {t('receiptDepositUseSetting:Receipt Deposits made after the invoice date are acceptable.')}
                                            </span>
                                            <Switch
                                                id='canUseRd'
                                                onChange={this.handleChange}
                                                checked={canUseRd}
                                            />
                                        </div>


                                        <div className="row justify-content-end" style={{ marginTop: 80 }}>
                                            <React.Fragment>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary submit-seting"
                                                    onClick={this.submit}
                                                    style={{ justifyContent: 'center', backgroundColor: '#1567B4', color: '#FFF' }}
                                                >
                                                    {loadingSubmit ? <span className="spinner-border spinner-border-sm align-middle mr-2 text-light" /> : null}
                                                    {t('invoiceRoleSetting:Saved')}
                                                </button>

                                            </React.Fragment>
                                        </div>
                                    </div>
                                :
                            <Loading />                                    
                    )}
                </Translation>                
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default SettingDepositUse;
