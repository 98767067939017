import {
    commitMutation,
} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from '../../../../env/environment';

const mutation = graphql`
    mutation saveNotiDayContractExpenseMutation($input: SaveNotiDayContractExpenseInput!){
        saveNotiDayContractExpense(input:$input) 
        {
            ok
            message
        }
    }
`;

export default function saveNotiDayContractExpense(variables, callback, error_callback) {
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
