import React, {Component} from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Wrapper from '../../../components/wrapper';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import SelectContact from '../../income/selectContact';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import CreateForm from "./otherReceiverCreateForm";


class OtherCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact_list: [],
            accountType: "other_receiver",
            transactions: [],
            contactType : '',
            carReportDocList:[],
            carTransactionIdList:[],
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
        this.getContactType = this.getContactType.bind(this)
        this.getcarReportDocList = this.getcarReportDocList.bind(this)
        this.getcarTransactionIdList = this.getcarTransactionIdList.bind(this)
    }

    updateCustomerList(contact_list) {
        this.setState({contact_list: contact_list})
    }

    getContactType(contact_type){
        this.setState({contactType:contact_type})
    }

    getcarReportDocList(value){
        this.setState({carReportDocList:value})
    }

    getcarTransactionIdList(value){
        this.setState({carTransactionIdList:value})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                   initialContactType="residential"
                                   nextLink="/accounting/income/other_receiver/form/create"
                                   backLink="/accounting/income/other_receiver/list/all"
                                   getContactType={this.getContactType}
                                   carReportDocList={this.props.location?.state?.carReportDocList}
                                   getcarReportDocList={this.getcarReportDocList}
                                   carTransactionIdList={this.props.location?.state?.checkList}
                                   getcarTransactionIdList={this.getcarTransactionIdList}
                                  
                    />
                    }
                    {this.props.match.params.status === 'create' &&
                    <CreateForm contactList={this.state.contact_list}
                                   accountType={this.state.accountType}
                                   contactType={this.state.contactType}
                                   carReportDocList={this.state.carReportDocList}
                                   carTransactionIdList={this.state.carTransactionIdList}
                                   />
                    }

                </WrapperContent>
            </Wrapper>


        )
    }
}

export default OtherCreate;