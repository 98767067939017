/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type waterMeterSelectDateQueryVariables = {|
  service: string,
  contact: string,
  month: number,
  year: number,
|};
export type waterMeterSelectDateQueryResponse = {|
  +periodServicePricing: ?{|
    +unit: ?number,
    +unitPrice: ?number,
    +totalPrice: ?number,
    +description: ?string,
  |}
|};
export type waterMeterSelectDateQuery = {|
  variables: waterMeterSelectDateQueryVariables,
  response: waterMeterSelectDateQueryResponse,
|};
*/


/*
query waterMeterSelectDateQuery(
  $service: String!
  $contact: String!
  $month: Int!
  $year: Int!
) {
  periodServicePricing(service: $service, contact: $contact, month: $month, year: $year) {
    unit
    unitPrice
    totalPrice
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contact",
        "variableName": "contact"
      },
      {
        "kind": "Variable",
        "name": "month",
        "variableName": "month"
      },
      {
        "kind": "Variable",
        "name": "service",
        "variableName": "service"
      },
      {
        "kind": "Variable",
        "name": "year",
        "variableName": "year"
      }
    ],
    "concreteType": "PeriodServicePricing",
    "kind": "LinkedField",
    "name": "periodServicePricing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "waterMeterSelectDateQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "waterMeterSelectDateQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "86563dee5cf2035e68f68652989a0aad",
    "id": null,
    "metadata": {},
    "name": "waterMeterSelectDateQuery",
    "operationKind": "query",
    "text": "query waterMeterSelectDateQuery(\n  $service: String!\n  $contact: String!\n  $month: Int!\n  $year: Int!\n) {\n  periodServicePricing(service: $service, contact: $contact, month: $month, year: $year) {\n    unit\n    unitPrice\n    totalPrice\n    description\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1b757156796f81b6d7fa05cc71a2a1e';

module.exports = node;
