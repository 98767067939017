import React, {Component} from 'react';
import Header from '../../../components/header/';
import Sidebar from '../../../components/sidebar/';
import Wrapper from '../../../components/wrapper/';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import "../styles/survey.scss"
import Navigation from "./navigation";
import ModalSurvey from "./modalSurvey";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {QueryRenderer} from "react-relay";
import SurveyDetail from "./surveyDetail";
import { Translation } from 'react-i18next';
import Loading from "../../../libs/loading";
const _ = require('lodash');

const query = graphql`
    query surveyDetailListQuery($surveyId: ID!) {
        survey(id: $surveyId){
            id
            title
        }
        getResidentialVoteBySurvey(surveyId: $surveyId) {
            surveyId
            countVoted
            countAll
        }
    }
`;

class SurveyDetailList extends Component {

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid box" id="survey">
                        <QueryRenderer
                            environment={environment}
                            query={query}
                            variables={{
                                surveyId: this.props.match.params.id,
                            }}
                            cacheConfig={{use_cache: false}}
                            render={({error, props}) => {
                                if (error) {
                                    return <div className="alert alert-danger"
                                                role="alert">{error.message}</div>;
                                } else if (props) {
                                    return (
                                        <Translation>{t=>
                                            <React.Fragment>
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                    <h3 className="overflow-wrap">
                                                        <Link to="/contact/survey">
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                alt="back" className="back"/>
                                                        </Link>
                                                        {props.survey.title}
                                                    </h3>

                                                </div>

                                                <div className="col text-right">
                                                    <ModalSurvey survey_id={this.props.match.params.id} type="prototype"
                                                                 status={false}/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col ml-4">
                                                    <p>{t('survey:number_of_votes')} {props.getResidentialVoteBySurvey.countVoted}/{props.getResidentialVoteBySurvey.countAll}</p>
                                                </div>
                                            </div>

                                            <div className="content-inner">

                                                <Navigation id={this.props.match.params.id}/>

                                                <SurveyDetail survey_id={this.props.match.params.id}
                                                              status={this.props.match.params.status}/>
                                            </div>
                                        </React.Fragment>
                                        }</Translation>
                                    )
                                }
                                return <Loading/>
                            }}/>
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default SurveyDetailList;
