import React, { Component } from 'react'
import NavigationMenuSetup from '../../libs/navigationMenuSetup'
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
export default class ClaimTopMenuNavigation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      countPurchaseRequisition: window.localStorage.getItem("countPurchaseRequisition") || "0",
      countPurchaseOrder: window.localStorage.getItem("countPurchaseOrder") || "0",
    }
  }

  componentDidMount() {
    if (this.props.center) {
      NavigationMenuSetup();
    }
    // this.getData()
  }

  // getData = () => {
  //   // let newCountPurchaseRequisition = 0
  //   // let newCountPurchaseOrder = 0
  // }

  render() {
    return (
      <div className="container-fluid bg-gray">

        <div className={"row "
          + (this.props.mini ? "mini " : "")
          + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

          {this.props.center &&
            <Translation>{t => <div className="col-12 mt-5 mb-5"><h3 className="text-center">{t("งานบริหารทรัพย์ส่วนกลาง")}</h3></div>}</Translation>
          }

          <div className={"col " + (this.props.center ? " fade-up" : "")}>
            <ul>
              <li>
                <a className="dropdown-toggle align-items-center d-flex" href="/" role="button"
                  id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <img style={{ width: 24, height: 24 }}
                    src={process.env.PUBLIC_URL + '/images/propertyManagement/logoOrder.svg'}
                    alt="parcel-top-menu"
                    className={this.props.mini && 'd-none'} />
                  <Translation>
                    {
                      t => <div className="p-0">
                        {t('claimRequst:OrderandProcurement')}
                      </div>
                    }
                  </Translation>
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                  {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_calendar' }) && */}
                  <Link to="/purchasing/manageRequest/list/all">
                    <Translation>
                      {
                        t => <div>
                          {t('set_permission:Procurement')}
                        </div>
                      }
                    </Translation>
                  </Link>
                  {/* } */}

                  {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_list' }) && */}
                  <Link to="/purchasing/manageOrder/list/all">
                    <Translation>
                      {
                        t => <div>
                          {t('claimRequst:PurchaseOrder')}
                        </div>
                      }
                    </Translation>
                  </Link>
                  {/* } */}
                </div>
              </li>

              {
                // localStorage.getItem('site_id') && localStorage.getItem('site_id') === '331' &&
                <li>
                  <a className="dropdown-toggle align-items-center d-flex" href="/" role="button"
                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <img style={{ width: 24, height: 24 }}
                      src={process.env.PUBLIC_URL + '/images/propertyManagement/2.svg'}
                      alt="parcel-top-menu"
                      className={this.props.mini && 'd-none'} />
                    <Translation>
                      {
                        t => <div className="p-0">
                         {t("MenuName:Claim Request")}
                        </div>
                      }
                    </Translation>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_calendar' }) && */}
                    <Link to="/purchasing/claim/all">
                      <Translation>
                        {
                          t => <div>
                            {t('MenuName:Claim Request Box')}
                          </div>
                        }
                      </Translation>
                    </Link>
                    {/* } */}

                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_list' }) && */}
                    <Link to="/purchasing/claim/report">
                      <Translation>
                        {
                          t => <div>
                            {t('MenuName:Claim Reports')}
                          </div>
                        }
                      </Translation>
                    </Link>
                    {/* } */}
                    <Link to="/purchasing/insurrance">
                      <Translation>
                        {
                          t => <div>
                            {t('MenuName:Insurrance Information')}
                          </div>
                        }
                      </Translation>
                    </Link>
                    <Link to="/setting/settingClaim">
                      <Translation>
                        {
                          t => <div>
                            {t('MenuName:Claims Setting')}
                          </div>
                        }
                      </Translation>
                    </Link>
                  </div>
                </li>
              }


            </ul>
          </div>
        </div>
      </div>
    )
  }
}
