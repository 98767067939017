import React, {Component} from "react";
import {format} from "date-fns";
import getNameResidential from "../../../../libs/getNameResidential";
import numberWithComma from "../../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import jwtDecode from 'jwt-decode';
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import './receivableOutstandingSummaryReport.scss'

const query = graphql`
  query receivableOutstandingSummaryReportPDFRenderTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

class ReceivableOutstandingSummaryReportPDFRenderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: "",
            printBy: "",
            dueDate: "",
            customerType: "",
            contactName: "",
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    dueDate: this.props.dueDate,
                    customerType: this.props.customerType,
                    contactName: this.props.contactName,
                })
            }
        }
        )
    }

    numberWithComma(num) {
        let isNegative = false;

        // if (num === 0) {
        //     return formattedNum = "-";
        //   }
        // Check if the number is negative
        if (num < 0) {
          isNegative = true;
          num = Math.abs(num); // Convert negative number to positive
        }
        // Add .00 to the number
        num = num.toFixed(2);
        // Format the number with commas
        let formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (num === 0) {
            return formattedNum = "-";
          }
        // Add a minus sign for negative numbers
        if (isNegative) {
          formattedNum = '(' + formattedNum + ')';
        }
        return formattedNum;
    }

    render() {
            let sumOutstandingBalance = 0;
            let sumNotOverdueBalance = 0;
            let sumOverdueOneMonthBalance = 0;
            let sumOverdueTwoMonthsBalance = 0;
            let sumOverdueThreeMonthsBalance = 0;
            let sumOverdueFourMonthsBalance = 0;
            let sumOverdueFiveMonthsBalance = 0;
            let sumOverdueSixMonthsBalance = 0;
            let sumOverdueOverSixMonthsBalance = 0;

            let dataAll = this.props.props.reportOutstandingReceivableSummary?.edges;

            dataAll.map((dataRow, index) => {
                sumOutstandingBalance += dataRow.node.outstandingBalance;
                sumNotOverdueBalance += dataRow.node.notOverdueBalance;
                sumOverdueOneMonthBalance += dataRow.node.overdueOneMonthBalance;
                sumOverdueTwoMonthsBalance += dataRow.node.overdueTwoMonthsBalance;
                sumOverdueThreeMonthsBalance += dataRow.node.overdueThreeMonthsBalance;
                sumOverdueFourMonthsBalance += dataRow.node.overdueFourMonthsBalance;
                sumOverdueFiveMonthsBalance += dataRow.node.overdueFiveMonthsBalance;
                sumOverdueSixMonthsBalance += dataRow.node.overdueSixMonthsBalance;
                sumOverdueOverSixMonthsBalance += dataRow.node.overdueOverSixMonthsBalance;
                return dataRow
            })

            let dataChunked = [];
            let rowPerpage = 25;
            for (let i=0;i<dataAll.length ;i += rowPerpage) {
                let chunk = dataAll.slice(i, i + rowPerpage);
                dataChunked.push(chunk);            
            }

        return (
            <React.Fragment>       
                {dataChunked.map((data, page_index)=>(
                    <div className='print-page-a4 hoziA4' key={page_index}>
                                    {/* แสดงหัวกระดาษ */}
                                    <div>
                                            <p className='f12' style={{ lineHeight: 1.5 }}> {this.state.projectName}</p>
                                            <p className='f10'>รายงานลูกหนี้คงค้างแบบสรุป</p>
                                            <p className='f10'>ณ วันที่ {format(this.state.dueDate, "DD-MM-YYYY")}</p>
                                            <p className='f10'>เรียงตามประเภทลูกค้า - {this.state.customerType}</p> 
                                            <p className='f10'>เรียงตามผู้ติดต่อ - {this.state.contactName}</p>
                                    </div>
                                    {/* แสดงตาราง */}
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <table className="table tebleBorder">
                                                <thead className='tableHead'>
                                                    <tr>
                                                        <th rowSpan={2} className="text-center width3">ลำดับ</th>
                                                        <th rowSpan={2} className="text-center width7">บ้านเลขที่/<br/>เลขที่ห้อง</th>
                                                        <th rowSpan={2} className="text-center width35">ชื่อ</th>
                                                        <th rowSpan={2} className="text-center width6">ยังไม่เกินกำหนด</th>
                                                        <th colSpan={7} className="text-center width42">เกินกำหนด(วัน)</th>
                                                        <th rowSpan={2} className="text-center width7">ค้างชำระสุทธิ</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-center width6">1-30</th>
                                                        <th className="text-center width6">31-60</th>
                                                        <th className="text-center width6">61-90</th>
                                                        <th className="text-center width6">91-120</th>
                                                        <th className="text-center width6">121-150</th>
                                                        <th className="text-center width6">151-180</th>
                                                        <th className="text-center width6">มากกว่า180</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tableBody'> 
                                                    {data.map((transaction, index) => (
                                                        <tr key={transaction.node.id}>
                                                                    <td className="text-center">{(page_index * rowPerpage) + (index + 1)}</td>
                                                                    <td className="text-left">{transaction.node.typeOfContact === 'RESIDENTIAL' ? transaction.node.name : transaction.node.refNumber}</td>
                                                                    <td className="text-left">
                                                                        {transaction.node.typeOfContact === 'RESIDENTIAL' ? getNameResidential(transaction.node.firstName, transaction.node.lastName): transaction.node.name}
                                                                    {/* {" "}                                            
                                                                    {
                                                                    (dataRow.node.payGroup === "RENT" || dataRow.node.payGroup === "AGENT" || dataRow.node.payGroup === "OWNER" || dataRow.node.payGroup === "DEVELOPER") &&
                                                                    <label className="text-danger">
                                                                    ({dataRow.node.payGroup === "RENT" || dataRow.node.payGroup === "AGENT" || dataRow.node.payGroup === "OWNER" || dataRow.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${dataRow.node.payGroup}`) : ""})
                                                                    </label>
                                                                    } */}
                                                                    </td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.notOverdueBalance, "")}</td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.overdueOneMonthBalance, "")}</td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.overdueTwoMonthsBalance, "")}</td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.overdueThreeMonthsBalance, "")}</td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.overdueFourMonthsBalance, "")}</td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.overdueFiveMonthsBalance, "")}</td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.overdueSixMonthsBalance, "")}</td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.overdueOverSixMonthsBalance, "")}</td>
                                                                    <td className="text-right">{this.numberWithComma(transaction.node.outstandingBalance, "")}</td>
                                                        </tr>   
                                                    ))}                                                 
                                                </tbody>
                                                <tfoot className="tableFooter">
                                                   {!dataChunked[page_index+1] &&
                                                        <tr key = {'summmaryRow' + page_index}>
                                                            <td colSpan={3} className="text-center"><strong>รวมทั้งหมด</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumNotOverdueBalance, "")}</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumOverdueOneMonthBalance, "")}</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumOverdueTwoMonthsBalance, "")}</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumOverdueThreeMonthsBalance, "")}</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumOverdueFourMonthsBalance, "")}</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumOverdueFiveMonthsBalance, "")}</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumOverdueSixMonthsBalance, "")}</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumOverdueOverSixMonthsBalance, "")}</strong></td>
                                                            <td className="text-right"><strong>{numberWithComma(sumOutstandingBalance, "")}</strong></td>
                                                        </tr> 
                                                    }                                                  
                                                </tfoot>
                                            </table>
                                        </div>    
                                    </div>    
                                    {/* แสดงเลขหน้า และผู้พิมพ์ */}
                                    <div className="footer">
                                        <div className="row">
                                            <div className="col-5" />
                                            <div className="col-2 text-center">
                                                <p className="f10 pb-0 mb-0">{page_index + 1}/{dataChunked.length}</p>
                                            </div>
                                            <div className="col-5 text-right">
                                                <p className="f10 pb-0 mb-0">
                                                    ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>   
                    </div>
                ))}
            </React.Fragment>
        )
    }
}

export default ReceivableOutstandingSummaryReportPDFRenderTable;