import React from "react";
import ReactPaginate from "react-paginate";
import "../contact/commonArea/styles/commonArea.scss";
import { Translation } from "react-i18next";

const Paginate = ({
  state: { all_item, page_count, page_select, num_record },
  changePageLimit,
  handlePageClick,
}) => {
  return (
    <div className="text-left">
      <div className="dropdown page-limit d-none d-md-block page-limit">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Translation>{(t) => t("pagination:show")}</Translation>
          <span>{all_item}</span>
          <Translation>{(t) => t("pagination:items/page")}</Translation>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div className="dropdown-item" onClick={() => changePageLimit(10)}>
            <Translation>{(t) => t("pagination:show")}</Translation> 10
            <Translation>{(t) => t("pagination:items/page")}</Translation>
          </div>
          <div className="dropdown-item" onClick={() => changePageLimit(20)}>
            <Translation>{(t) => t("pagination:show")}</Translation> 20
            <Translation>{(t) => t("pagination:items/page")}</Translation>
          </div>
          <div className="dropdown-item" onClick={() => changePageLimit(50)}>
            <Translation>{(t) => t("pagination:show")}</Translation> 50
            <Translation>{(t) => t("pagination:items/page")}</Translation>
          </div>
        </div>
      </div>
      <div className="text-right">
        {page_count > 1 && (
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={<span className="btn page-link">...</span>}
            breakClassName={"break-me"}
            pageClassName="page-item"
            marginPagesDisplayed={1}
            pageRangeDisplayed={4}
            previousClassName="sr-only"
            containerClassName={"pagination float-right"}
            subContainerClassName={"pagination-page"}
            nextLinkClassName="sr-only"
            pageLinkClassName="page-link"
            activeClassName={"active"}
            pageCount={page_count}
            initialPage={page_select}
            onPageChange={handlePageClick}
          />
        )}
        <div className="total-item-count">
          <Translation>{(t) => t("pagination:all_items")}</Translation>
          {num_record}
        </div>
      </div>
    </div>
  );
};

export default Paginate;
