import React, { Component } from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { QueryRenderer } from 'react-relay';
import environment from "../../../env/environment";
import { Link,Redirect } from "react-router-dom";
import numberWithComma from "../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import thLocale from "date-fns/locale/th";
import { format } from "date-fns";
import _ from "lodash"
import Swal from "sweetalert2";
import bankTransferupdateMutation from "./bankTransferupdate"
import { Translation } from 'react-i18next';
import i18n from "i18next"

const bankTransfer_Detail = graphql`
    query bankTransferDetailQuery($iddoc: String) {
        bankTransferViewer {
            allBankTransferDetail(iddoc: $iddoc,) {
                edges {
                    node {
                        id
                        docNumber
                        transferOut {
                            id
                            bankName
                            accountNumber
                            accountType
                        }
                        transferIn {
                            id
                            bankName
                            accountNumber
                            accountType
                        }
                        fee
                        amount
                        date
                        description
                        added
                    }
                }
            }
        }

        allAccountRecordGroup(refTransaction: $iddoc) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }

        allBankAccountTransaction(refTransaction: $iddoc) {
            edges{
                node{
                    id
                    price
                    date
                    bankAccount {
                        bankName
                        accountNumber
                    }
                    description
                }
            }
        }
    }
`;

class BankTransferDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirect: false,
        };
        this.onChangeStatus = this.onChangeStatus.bind(this);
        // this.onError = this.onError.bind(this);
    }

    onChangeStatus() {
        Swal.fire({
            title: i18n.t("bank_transfer:Are you sure to cancel this transfer between account slip ?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18n.t("bank_transfer:No"),
            confirmButtonText: i18n.t("bank_transfer:Yes")
        }).then(async (result) => {
            if(result.value){
                let { value: void_remark } = await Swal.fire({
                    title: i18n.t("bank_transfer:Note"),
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: i18n.t("bank_transfer:Cancel"),
                    confirmButtonText: i18n.t("bank_transfer:Confirm"),
                    inputValidator: (value) => {
                        if (!value) {
                            return i18n.t("bank_transfer:Please enter the cancellation note!")
                        }
                    }
    
                });
                if (void_remark) {
                    this.setState({ loading: true });
                    let input = {
                            "iddoc": this.props.match.params.id,
                            "status": "void",
                            "voidRemark": void_remark,
                        };
    
                    bankTransferupdateMutation(input,
                        (response) => {
                            if (response.updateBankTransfer.ok === true) {
                                Swal.fire(
                                    i18n.t("bank_transfer:Successfully"),
                                    i18n.t("bank_transfer:Invoice cancelled"),
                                    'success'
                                );
                                this.setState({ redirect: true, loading: false });
                            } else {
                                Swal.fire(i18n.t("bank_transfer:Failed to cancel the invoice"), response.updateBankTransfer.warningText, 'warning');
                                this.setState({ loading: false });
                            }
    
                        }, () => {
                            this.setState({ loading: false });
                        });
    
                } else {
                    this.setState({ loading: false });
                }
            }else {
                this.setState({ loading: false });
            }
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={"/accounting/finance/bank-transfer/"}/>
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />

                    <QueryRenderer
                        environment={environment}
                        query={bankTransfer_Detail}
                        cacheConfig={{ use_cache: false }}
                        variables={
                            {
                                iddoc: this.props.match.params.id,
                            }
                        }
                        render={({ error, props }) => {
                            if (error) {
                                return <div className="alert alert-danger" role="alert">{error.message}</div>;
                            } else if (props) {
                                return (
                                    <div className="container-fluid box">

                                        <div className="row justify-content-between">
                                            <div className="col">
                                                <h3 className="mb-4">
                                                    <Link to="/accounting/finance/bank-transfer/">
                                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back" />
                                                    </Link>
                                                    <Translation>{t=><span>{t("bank_transfer:Transfer money between account No.")} #{props.bankTransferViewer.allBankTransferDetail.edges[0].node.docNumber}</span>}</Translation>
                                                </h3>
                                            </div>
                                            {/* <div className="col text-right">
                                            </div> */}
                                        </div>

                                        <div className="content-inner">

                                            <div className="row mb-3">
                                                <Translation>{t=><div className="col-4">{t("bank_transfer:Account description")}: {props.allAccountRecordGroup.edges[0].node.name}</div>}</Translation>
                                                <div className="col-8 text-right">
                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_bank_transfer_create' }) &&
                                                        <Translation>
                                                            {t=>
                                                            <button className="btn btn-danger add mr-3" disabled={this.state.loading}
                                                                onClick={this.onChangeStatus}>
                                                                {this.state.loading &&
                                                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                                {t("bank_transfer:Cancel this transfer between account slip")}
                                                            </button>}
                                                        </Translation>
                                                    }
                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_bank_transfer_print' }) &&
                                                    <Link to={"/document/bank-transfer/"+this.props.match.params.id} target="_blank">
                                                        <Translation>
                                                            {t=>
                                                            <button type="button" className="btn btn-primary add">
                                                                {t("bank_transfer:Print this transfer between account slip")}
                                                            </button>}
                                                        </Translation>
                                                    </Link>
                                                    }
                                                </div>
                                            </div>

                                            <Translation>
                                                {t=>
                                                <div className="row">
                                                    <div className="col">{t("bank_transfer:Detail")}: {props.bankTransferViewer.allBankTransferDetail.edges[0].node.description}
                                                    </div>
                                                    <div className="col">{t("bank_transfer:Since")}: {format(props.bankTransferViewer.allBankTransferDetail.edges[0].node.date, "DD/MM/YYYY")}</div>
                                                    <div className="col">{t("bank_transfer:Refer from")}: {props.allAccountRecordGroup.edges[0].node.refNumber}</div>
                                                </div>}
                                            </Translation>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="table-responsive fade-up card mt-3">
                                                        <table className="table table-hover">
                                                            <thead className="thead-dark">
                                                                <Translation>
                                                                    {t=>
                                                                    <tr>
                                                                        <th width={100}>{t("bank_transfer:No.")}</th>
                                                                        <th width={250}>{t("bank_transfer:Account name")}</th>
                                                                        <th>{t("bank_transfer:Description")}</th>
                                                                        <th className="text-right" width={200}>{t("bank_transfer:Debit")}</th>
                                                                        <th className="text-right" width={200}>{t("bank_transfer:Credit")}</th>
                                                                    </tr>}
                                                                </Translation>
                                                            </thead>
                                                            <tbody>

                                                                {props.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((record) => (
                                                                    record.node.debit > 0 &&
                                                                    <tr key={record.node.id}>
                                                                        <td>{record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                                        <td>{record.node.chartOfAccountCode.name}</td>
                                                                        <td>{record.node.name} {props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.name}</td>
                                                                        <td className="text-right">{numberWithComma(record.node.debit)}</td>
                                                                        <td className="text-right">{numberWithComma(record.node.credit)}</td>
                                                                    </tr>
                                                                ))}
                                                                {props.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((record) => (
                                                                    record.node.credit > 0 &&
                                                                    <tr key={record.node.id}>
                                                                        <td>{record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                                        <td>{record.node.chartOfAccountCode.name}</td>
                                                                        <td>{record.node.name} {props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.name}</td>
                                                                        <td className="text-right">{numberWithComma(record.node.debit)}</td>
                                                                        <td className="text-right">{numberWithComma(record.node.credit)}</td>
                                                                    </tr>
                                                                ))}

                                                                <tr>
                                                                    <Translation>{t=><td colSpan="3" className="text-right">{t("bank_transfer:Total")}</td>}</Translation>
                                                                    <td className="text-right">{numberWithComma(props.allAccountRecordGroup.edges[0].node.totalDebit)}</td>
                                                                    <td className="text-right">{numberWithComma(props.allAccountRecordGroup.edges[0].node.totalCredit)}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div>
                                                        {
                                                            parseFloat(props.allAccountRecordGroup.edges[0].node.totalDebit) !== parseFloat(props.allAccountRecordGroup.edges[0].node.totalCredit) &&
                                                            <Translation>{t=><h6 className="text-center mt-3 text-danger">{t("bank_transfer:Updating list")}</h6>}</Translation>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <Translation>
                                                            {t=>
                                                            <div className="col">
                                                                <p><strong>{t("bank_transfer:Detail")}</strong></p>
                                                                <p style={{fontWeight:'bold'}}>เงินโอนเข้า/Transfer In</p>
                                                                {props.bankTransferViewer.allBankTransferDetail.edges.map((record) => {
                                                                    return (
                                                                        <p key={record.node.transferIn.id}>{t("bank_transfer:Bank")}&nbsp;&nbsp;{record.node.transferIn.bankName}
                                                                    &emsp;{t("bank_transfer:Account No.")}&nbsp;&nbsp;{record.node.transferIn.accountNumber}
                                                                    &emsp;{t("bank_transfer:Date")}&nbsp;&nbsp;{format(record.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                                    &emsp;{t("bank_transfer:Amount")}&nbsp;&nbsp;{numberWithComma(record.node.amount)} {t("bank_transfer:Baht")}</p>
                                                                    )
                                                                })}
                                                                <p style={{fontWeight:'bold'}}>เงินโอนออก/Transfer out</p>
                                                                {props.bankTransferViewer.allBankTransferDetail.edges.map((record) => {
                                                                    return (
                                                                        <p key={record.node.transferOut.id}>{t("bank_transfer:Bank")}&nbsp;&nbsp;{record.node.transferOut.bankName}
                                                                    &emsp;{t("bank_transfer:Account No.")}&nbsp;&nbsp;{record.node.transferOut.accountNumber}
                                                                    &emsp;{t("bank_transfer:Date")}&nbsp;&nbsp;{format(record.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                                    &emsp;{t("bank_transfer:Amount")}&nbsp;&nbsp;{numberWithComma(record.node.amount + record.node.fee)} {t("bank_transfer:Baht")}</p>
                                                                    )
                                                                })}
                                                            </div>}
                                                        </Translation>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )
                            }
                        }}
                    />
                </WrapperContent>
            </Wrapper>
        );
    }
}
export default BankTransferDetail;