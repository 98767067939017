
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import './regisCollectionLawFrim.scss'

class CollectionLawFrimStep extends Component {

    render() {
        return (
            <Translation>
            { t => (
            <>
                <div className="stepper-wrapper">
                    <div className="stepper-item completed active">
                        <div className="step-counter">
                            {
                                this.props.regisCLFStepFinish1 ?   
                                <img src="https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/check.png" /> 
                               :
                                <img src="https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/loading.png" />
                            }
                        </div>
                        <div className="step-name">
                        {t('collectionLawFirm:Terms and Conditions for Using')} <br/> 
                        {t('collectionLawFirm:Debt Collection Attorney Service')}
                        </div>
                    </div>
                    <div className={`stepper-item ${this.props.regisCLFStepFinish2 !== undefined ? 
                        this.props.regisCLFStepFinish2 ? 'completed active' : '' : ''} `}>
                        <div className={`step-counter ${this.props.regisCLFStepFinish2 !== undefined ? 
                        this.props.regisCLFStepFinish2 ? '' : 'check' : ''} `}>
                            {
                                this.props.regisCLFStepFinish2 !== undefined
                                 ?
                                this.props.regisCLFStepFinish2 ?
                                <img src="https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/check.png" /> 
                                :
                                <img src="https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/loading.png" /> 
                                : ''
                           }
                        </div>
                        <div className="step-name">
                        {t('collectionLawFirm:Download The Attorney')}  <br/> 
                        {t('collectionLawFirm:Appointment Document1')}
                        </div>
                    </div>
                    <div className={`stepper-item 
                            ${this.props.regisCLFStepFinish3 !== undefined ? 
                            this.props.regisCLFStepFinish3 ? 'completed active TTT' : '':''} 
                        `}>
                       <div className={`step-counter ${this.props.regisCLFStepFinish3 !== undefined ? 
                        this.props.regisCLFStepFinish3 ? '' : 'check' : ''} `}>
                            {
                              this.props.regisCLFStepFinish3 !== undefined
                                ?
                               this.props.regisCLFStepFinish3 ?
                               <img src="https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/check.png" /> 
                               :
                               <img src="https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/loading.png" /> 
                               : ''
                            }
                        </div>
                        <div className="step-name">
                        {t('collectionLawFirm:Attach Attorney')} <br/>
                        {t('collectionLawFirm:For Documents')}</div>
                    </div>
                    <div className={`stepper-item 
                            ${this.props.regisCLFStepFinish4 !== undefined ? 
                            this.props.regisCLFStepFinish4 ? 'completed active' : '':''} 
                        `}>
                        <div className={`step-counter ${this.props.regisCLFStepFinish4 !== undefined ? 
                        this.props.regisCLFStepFinish4 ? '' : 'check' : ''} `}>
                             {
                                this.props.regisCLFStepFinish4 !== undefined
                                ?
                               this.props.regisCLFStepFinish4 ?
                               <img src="https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/check.png" /> 
                               :
                               <img src="https://silverman-storage-backup.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/loading.png" /> 
                               : ''
                            }
                        </div>
                        <div className="step-name">
                        {t('collectionLawFirm:Confirm the application')}</div>
                    </div>
                    {/* <div className={`stepper-item 
                            ${this.props.regisCLFStepFinish5 !== undefined ? 
                            this.props.regisCLFStepFinish5 ? 'completed active' : '':''} 
                        `}>
                         <div className={`step-counter ${this.props.regisCLFStepFinish5 !== undefined ? 
                        this.props.regisCLFStepFinish5 ? '' : 'check' : ''} `}>
                        {
                                this.props.regisCLFStepFinish5 !== undefined
                                ?
                               this.props.regisCLFStepFinish5 ?
                               <img src="https://silverman-storage.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/check.png" /> 
                               :
                               <img src="https://silverman-storage.s3.ap-southeast-1.amazonaws.com/silverman-storage/public/registration/loading.png" /> 
                               : ''
                            }
                        </div>
                    </div> */}
                </div>
            </>
            )}
            </Translation>
        );
    }
}

export default CollectionLawFrimStep;