import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import PettyCashPayment from "./pettyCashPayment"
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperPettyCashPaymentQuery($id: ID!) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            taxIdNumber
            juristicContactNumber
            logo
        }
        pettyCashPayment(id: $id){
            id
            docNumber
            issuedDate
            total
            pettyCashRecord{
                edges{
                    node{
                        id
                        issuedDate
                        docNumber
                        chartOfAccount {
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        price
                        status
                        setPettyCash{
                            docNumber
                            withdrawer
                            description
                        }
                    }
                }
            }
            otherExpensePaymentChannel{
                edges{
                    node{
                        id
                        otherExpense{
                            id
                            docNumber
                            issuedDate
                            otherExpenseTransaction{
                                edges{
                                    node{
                                        id
                                        description
                                        productAndService{
                                            id
                                            name
                                            productCode
                                        }
                                        chartOfAccount{
                                            id
                                            name
                                            chartOfAccountCode
                                        }

                                    }
                                }
                            }
                        }
                        description
                        price
                        status
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
            payPaymentChannel{
                edges{
                    node{
                        id
                        payRecordGroup{
                            id
                            docNumber
                            issuedDate
                            payRecord{
                                edges{
                                    node{
                                        id
                                        purchaseRecord{
                                            id
                                            description
                                            productAndService{
                                                id
                                                name
                                                productCode
                                            }
                                            chartOfAccount{
                                                id
                                                name
                                                chartOfAccountCode
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        description
                        price
                        status
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
            advancePettyCashChannel{
                totalCount
                edges{
                    node{
                        id
                        advance{
                            id
                            docNumber
                            issuedDate
                            dueDate
                            withdrawer
                            description
                            amount
                            status
                            voidRemark
                            chartOfAccount{
                                id
                                name
                                chartOfAccountCode
                            }
                        }
                        clearAdvance{
                            id
                            docNumber
                            description
                            issuedDate
                            status
                            advance{
                                id
                                withdrawer
                            }
                            clearAdvanceTransaction{
                                edges{
                                    node{
                                        id
                                        description
                                        price
                                        refClearAdvanceTransactionDoc
                                        chartOfAccount{
                                            id
                                            name
                                            chartOfAccountCode
                                        }
                                    }
                                }
                            }
                        }
                        description
                        price
                        statusSetPettyCash
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }

            clearGuaranteeMoneyReceivedPettyCashChannel{
                edges{
                    node{
                        id
                        clearGuaranteeMoneyReceived{
                            id
                            docNumber
                            issuedDate
                            status
                            contact{
                                refNumber
                                firstName
                                lastName
                                name
                            }
                            guarantee {
                                docNumber
                                chartOfAccount {
                                    id
                                    chartOfAccountCode
                                    name
                                }
                            }
                        }
                        description
                        price
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
            bankAccountTransaction {
                edges {
                    node {
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction {
                edges {
                    node {
                        id
                        price
                    }
                }
            }
            chequeTransaction {
                edges {
                    node {
                        id
                        date
                        bankAccount {
                            id
                            bankName
                            branch
                        }
                        chequeNumber
                        price
                    }
                }
            }
        }
        pettyCashPaymentAccountRecordGroup(pettyCashPayment: $id){
            edges{
                node{
                    id
                    totalDebit
                    totalCredit
                    accountRecord {
                        edges {
                            node {
                                id
                                debit
                                credit
                                name
                                chartOfAccountCode {
                                    id
                                    chartOfAccountCode
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperPettyCashPayment extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    print() {
        window.print();
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Petty Cash Payment</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id: this.props.match.params.id}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return(
                                <PettyCashPayment 
                                    query={props}
                                    languages={props.userConfig.languages}
                                />
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperPettyCashPayment;
