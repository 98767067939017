import React, { Component } from 'react';
import CreateFormUnit from './createFormUnit';
import CreateFormJuristic from './createFormJuristic';
import CreateFormTenant from './createFormTenant';
import { format } from 'date-fns';
import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import createAndUpdateRequisitionNote from './mutation/createAndUpdateRequisitionNote';
import Swal from 'sweetalert2';
import { Redirect } from "react-router-dom";
import upload from '../../libs/upload';
import ModalVoidStockManagement from './modalVoid/modalVoidStockManagement';
import getNameResidential from "../../libs/getNameResidential"
import i18next from 'i18next';

const query = graphql`
  query createAndUpdateFromStockQuery($requisitionNoteId: [String]){
    myUser {
      juristic {
          id firstName lastName image
      }
    }
    productViewer{
      allProduct(type_In: "product", isActive: true){
        edges{
          node{
            id
            productCode
            name
            noNumber
            stock {
              edges {
                node {
                  id
                  cost
                  leftInStock
                  added
                  transaction{
                      date
                  }
                }
              }
            }
          }
        }
      }
    }
    allRequisitionNote(requisitionNoteId: $requisitionNoteId){
      edges{
        node{
          id
          status
          docNumber
          typeRequisition
          typeNote
          issuedDate
          recipient
          firstNameRecipient
          lastNameRecipient
          username
          objective
          remark
          contact{
            id
            name
            firstName
            lastName
          }
          juristict{
            id
            firstName
            lastName
          }
          requisitionList{
            edges{
              node{
              id
              numberOfPieces
              numberOfBalance
              noNumber
              productAndService{
                  id
                  noNumber
                  name
                  productCode
                  stock {
                    edges {
                      node {
                        id
                        cost
                        leftInStock
                        added
                        transaction{
                            date
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          requisitionNoteDocuments{
            edges{
              node{
                id
                fileName
                fileUpload
              }
            }
          }
        }
      }
    }
  }
`

let requisitionListItem = {
  requisitionListId: "",
  productAndServiceId: "",
  productCode: "",
  name: "",
  numberOfPieces: "",
  remaining: "",
  noNumber: "",
  haveNo:false,
}

export default class createAndUpdateFromStock extends Component {

  constructor(props) {
    super(props)

    let dateNow = new Date()
    let year = dateNow.getFullYear()
    let mouth = dateNow.getMonth() + 1

    this.state = {
      requisitionNote: {
        requisitionNoteId: "",
        typeRequisition: "",
        typeNote: "",
        contactId: "",
        coOwnerMember: "",
        docNumber: `STF-${year}${this.pad(mouth, 2)}XXXX`,
        issuedDate: dateNow,
        recipient: "",
        firstNameRecipient: "",
        lastNameRecipient: "",
        objective: "",
        remark: "",
        username: "",
        status: "",
        requisitionList: [],
        requisitionNoteDocuments: []
      },
      juristict: "",
      allProductList: [],
      switchSelectProduct: false,
      redirectTo: "",
      contactList: "",
      openModalVoid: false
    }
  }

  componentDidMount(){
    this.getData()
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.requisitionNote.recipient !== this.state.requisitionNote.recipient){
      if(this.state.requisitionNote.recipient === "owner"){
        if(this.props.match.params.action === "create"){
          this.setRecipient(this.props.location.state?.contactList[0]?.node?.firstName, this.props.location.state?.contactList[0]?.node?.lastName)
        }
        else if(this.props.match.params.action === "edit" && this.state.contactList){
          this.setRecipient(this.state.contactList.firstName, this.state.contactList.lastName)
        }
        
      }else if(this.state.requisitionNote.recipient === "other" ){
        if(this.props.match.params.action === "create"){
          this.setRecipient("", "")
        }
        else if(this.props.match.params.action === "edit" &&  this.state.contactList){
          this.setRecipient("", "")
        }
      }
    }
    if(this.state.requisitionNote.issuedDate !== prevState.requisitionNote.issuedDate && this.props.match.params.action === "create"){
      let dateNow = this.state.requisitionNote.issuedDate
      let year = dateNow.getFullYear()
      let mouth = dateNow.getMonth() + 1

      this.setState(
        ({ requisitionNote }) => ({
          requisitionNote: { 
            ...requisitionNote, 
            docNumber: `STF-${year}${this.pad(mouth, 2)}XXXX`, 
          }
        })
      );
    }
  }

  pad = (number, length) => {
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
  }

  setRecipient = (firstNameRecipient, lastNameRecipient) => {
    this.setState(
      ({ requisitionNote }) => ({
        requisitionNote: { 
          ...requisitionNote, 
          firstNameRecipient: firstNameRecipient || "", 
          lastNameRecipient:  lastNameRecipient || ""
        }
      })
    );
  }

  getData = () => {
    fetchQuery(environment, query, {requisitionNoteId: this.props.location.state.requisitionNoteID || []}).then((res) => {

      if(this.props.match.params.action === "create" ){//create

        let newRequisitionList = []

        if(this.props.match.params.status === "juristic"){
          _.forEach(this.props.location.state.productList, (product) => {
            let total_stock = 0;

            if(product.node.stock.edges.length > 0){
              product.node.stock.edges.forEach((stock, t_index) => {
                  total_stock = total_stock + stock.node.leftInStock;
                }
              )
            }

            newRequisitionList.push({
              requisitionListId: "",
              productAndServiceId: product.node.id,
              productCode: product.node.productCode,
              name: product.node.name,
              numberOfPieces: "",
              remaining: total_stock,
              noNumber: "",
              haveNo: product.node.noNumber,
            })
            
          })
        }

        let newAllProduct = []

        _.forEach(res.productViewer.allProduct.edges, (product, index) => {
          let total_stock = 0;
          if(product.node.stock.edges.length > 0){
            product.node.stock.edges.forEach((stock, t_index) => {
                total_stock = total_stock + stock.node.leftInStock;
              }
            )
          }
          newAllProduct.push({
            node: {
              id: product.node.id,
              name: product.node.name,
              noNumber: product.node.noNumber,
              productCode: product.node.productCode,
              remaining: total_stock
            }
          })
        })

        this.setState(
          ({ requisitionNote }) => ({
            requisitionNote: { 
              ...requisitionNote, 
              typeRequisition: this.props.location.state.typeRequisition,
              typeNote: this.props.location.state.typeRequisition === "project" ? this.props.match.params.status : "",
              contactId: this.props.location.state.contactList 
                            ? this.props.location.state.contactList[0]?.node.id 
                            : "",
              coOwnerMember: this.props.location.state.contactList && `${getNameResidential(this.props.location.state.contactList[0]?.node.firstName || "",this.props.location.state.contactList[0]?.node.lastName || "" )}` ,
              recipient: this.props.location.state.typeRequisition === "unit" ? "owner" : "",
              firstNameRecipient:  this.props.match.params.status === "unit" 
                                      ? this.props.location.state.contactList 
                                          ? this.props.location.state.contactList[0]?.node.firstName 
                                          : ""
                                      : "",
              lastNameRecipient: this.props.match.params.status === "unit" 
                                    ? this.props.location.state.contactList 
                                      ? this.props.location.state.contactList[0]?.node.lastName
                                      : ""
                                    : "",
              requisitionList: newRequisitionList
            }
          }), () => this.setState({
            juristict: `${res.myUser.juristic.firstName} ${res.myUser.juristic.lastName}`, 
            allProductList: newAllProduct
          })
        );

      }else if(this.props.match.params.action === "edit"){

        let itemRequisitionNote = res.allRequisitionNote.edges[0].node

        let newRequisitionList = []
        let newRequisitionNoteDocuments = []

        _.forEach(itemRequisitionNote.requisitionList.edges, (product) => {

          newRequisitionList.push({
            requisitionListId: product.node.id,
            productAndServiceId: product.node.productAndService.id,
            productCode: product.node.productAndService.productCode,
            name: product.node.productAndService.name,
            numberOfPieces: product.node.numberOfPieces,
            remaining: product.node.numberOfBalance,
            noNumber: product.node.noNumber,
          })
          
        })

        _.forEach(itemRequisitionNote.requisitionNoteDocuments.edges, (requisitionNoteDocuments) => {

          newRequisitionNoteDocuments.push({
            requisitionNoteDocumentsId: requisitionNoteDocuments.node.id, 
            fileUpload : requisitionNoteDocuments.node.fileUpload, 
            fileName : requisitionNoteDocuments.node.fileName,
            status : "",
          })
          
        })

        this.setState(
          ({ requisitionNote }) => ({
            requisitionNote: { 
              ...requisitionNote, 
              requisitionNoteId: this.props.location.state.requisitionNoteID,
              typeRequisition:  _.lowerCase(itemRequisitionNote.typeRequisition),
              docNumber: itemRequisitionNote.docNumber,
              typeNote:  itemRequisitionNote.typeNote ? _.lowerCase(itemRequisitionNote.typeNote) : "",
              contactId: itemRequisitionNote.contact?.id || "",
              coOwnerMember: `${itemRequisitionNote.contact?.firstName} ${itemRequisitionNote.contact?.lastName}`,
              issuedDate: new Date(itemRequisitionNote.issuedDate),
              recipient: itemRequisitionNote.recipient ? _.lowerCase(itemRequisitionNote.recipient) : "",
              username: itemRequisitionNote.username,
              firstNameRecipient:  itemRequisitionNote.firstNameRecipient,
              lastNameRecipient: itemRequisitionNote.lastNameRecipient,
              objective: itemRequisitionNote.objective,
              remark: itemRequisitionNote.remark,
              requisitionList: newRequisitionList,
              requisitionNoteDocuments: newRequisitionNoteDocuments
            }
          }), () => this.setState({
            juristict: `${itemRequisitionNote.juristict.firstName} ${itemRequisitionNote.juristict.lastName}`, 
            contactList: itemRequisitionNote.contact
          })
        );
      }

    })
  }

  handleChangeInput = (e) => {
    let { name, value } = e.target;
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });

    if(e.target1){
      let { name, value } = e.target1;
      this.setState(prevState => {
        return _.set(_.cloneDeep(prevState), name, value)
      });
    }
  }

  appendFileInputRow = (name, fileInput) => {
    let file = { 
      requisitionNoteDocumentsId: "",
      fileName : fileInput.node.nameFile,
      fileUpload : fileInput.node.fileUpload,
      status: ""
    }

    let newFile = []

    if(name === "requisitionNote.requisitionNoteDocuments"){
      newFile = [...this.state.requisitionNote.requisitionNoteDocuments, {...file}]
    }

    this.setState((prevState) => {
        return _.set(_.cloneDeep(prevState), name, newFile);
    });
  }

  handleRemoveImgSelect = (name, id, index) => {

    let listsSplice = []

    if(name  === "requisitionNote.requisitionNoteDocuments" && id){
      this.setState(prevState => {
        return _.set(_.cloneDeep(prevState), 
          `requisitionNote.requisitionNoteDocuments[${index}].status`, 
          "delete"
        )
      })
    }else{
      listsSplice = [...this.state.requisitionNote.requisitionNoteDocuments]
      listsSplice.splice(index, 1)

      this.setState(prevState => {
        return _.set(_.cloneDeep(prevState), name, listsSplice)
      })
    }
  }

  appendRequisitionList = (e) => {
    let newrequisitionListItem = _.clone(requisitionListItem)

    _.forEach(this.state.allProductList, (item) => {
      if(item.node.id === e.target.value){
        newrequisitionListItem.productCode = item.node.productCode
        newrequisitionListItem.name = item.node.name
        newrequisitionListItem.productAndServiceId = e.target.value
        newrequisitionListItem.remaining = item.node.remaining
        newrequisitionListItem.haveNo = item.node.noNumber
      }
    })

    if(newrequisitionListItem.remaining === 0){
      Swal.fire('ไม่สามารถเลือกสินค้าได้', 'เนื่องจากไม่มีสินค้าคงเหลือ', 'warning');
    }else{
      this.setState(
        ({ requisitionNote }) => ({
          requisitionNote: { 
            ...requisitionNote, 
            requisitionList: [...this.state.requisitionNote.requisitionList, newrequisitionListItem]
          }
        }), () => this.setState({switchSelectProduct: false})
      );
    }
  }

  handleRemoveSelect = (name, index) => {
    let listsSplice = []

    listsSplice = [...this.state.requisitionNote.requisitionList]
    listsSplice.splice(index, 1)

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, listsSplice)
    })
  }

  callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload("/stock/data" + path, file);
      resolve(res);
    });
  };

  onsubmit = (e) => {
    e.preventDefault();

    let newRequisitionList = []

    _.forEach(this.state.requisitionNote.requisitionList, (item, index) => {
      newRequisitionList.push({
        productAndServiceId: item.productAndServiceId,
        numberOfPieces: parseInt(item.numberOfPieces),
        noNumber: item.noNumber,
        requisitionListId: item.requisitionListId,
        remaining:item.remaining,
      })
    })
    this.onCreateAndUpdateRequisitionNote("active", newRequisitionList)
  }

  handleVoid = () => {
    let newRequisitionList = []

    _.forEach(this.state.requisitionNote.requisitionList, (item, index) => {
      newRequisitionList.push({
        productAndServiceId: item.productAndServiceId,
        numberOfPieces: parseInt(item.numberOfPieces),
        noNumber: item.noNumber,
        requisitionListId: item.requisitionListId,
        remaining:item.remaining,
      })
    })

    this.setState({openModalVoid: false}, () => this.onCreateAndUpdateRequisitionNote("void", newRequisitionList))
    
  }

  onCreateAndUpdateRequisitionNote = async(status, newRequisitionList) => {

    let error_newRequisitionList = _.filter(newRequisitionList, function (e) { return e.remaining < 0; })
    if(newRequisitionList.length > 0 && error_newRequisitionList.length == 0){
      let requisitionNoteDocuments = []
      if(this.state.requisitionNote.requisitionNoteDocuments.length > 0){
          for (const file of this.state.requisitionNote.requisitionNoteDocuments){
              if(file.requisitionNoteDocumentsId === ""){
                  await this.callUpload('', file.fileUpload).then((data) => {
                    requisitionNoteDocuments.push({
                        requisitionNoteDocumentsId : "" , 
                        fileUpload : data.key.replace("silverman-storage/private/", "") , 
                        status : "",
                        fileName : file.fileName
                      })
                  });
              }else{
                if(file.status === "delete"){
                  requisitionNoteDocuments.push({
                    requisitionNoteDocumentsId : file.requisitionNoteDocumentsId, 
                    status : file.status,
                  })
                }
              }
          }
      }
      let variables = {
        input: {
          requisitionNote: {
            requisitionNoteId: this.state.requisitionNote.requisitionNoteId,
            typeRequisition: this.state.requisitionNote.typeRequisition,
            typeNote:  this.state.requisitionNote.typeNote,
            contactId: this.state.requisitionNote.contactId ,
            issuedDate: format(this.state.requisitionNote.issuedDate, "YYYY-MM-DD"),
            recipient: this.state.requisitionNote.recipient,
            firstNameRecipient: this.state.requisitionNote.firstNameRecipient,
            lastNameRecipient:  this.state.requisitionNote.lastNameRecipient,
            username: this.state.requisitionNote.username,
            objective: this.state.requisitionNote.objective,
            remark: this.state.requisitionNote.remark,
            noteVoid: "",
            status: status,
            requisitionList: newRequisitionList,
            requisitionNoteDocuments: requisitionNoteDocuments
          }
        }
      }

      if(status === "active"){
        createAndUpdateRequisitionNote(
          variables, 
          (res) => { 
            if(res.createAndUpdateRequisitionNote.ok){
              Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                this.setState({redirectTo: "/stockandsupplies/stockManagement/list/all"})
              })
    
            }else{
              Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please try again."), 'warning')
            }
          }, 
          () => { Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning')}
        )
      }else if(status === "void"){
        let {value: void_remark } = await Swal.fire({
          title: i18next.t("stockCreateAndUpdate:Note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#1567B4",
          cancelButtonColor: "#d33",
          cancelButtonText:  i18next.t("Allaction:cancel"),
          confirmButtonText:  i18next.t("Allaction:Confirm"),
          inputValidator: (value) => {
            if (!value) {
              return i18next.t("Allaction:Please fill input before cancel")
            }
          },
        });

        if(void_remark){

          _.set(variables, "input.requisitionNote.noteVoid", void_remark)

          createAndUpdateRequisitionNote(
            variables, 
            (res) => { 
              if(res.createAndUpdateRequisitionNote.ok){
                Swal.fire(i18next.t("Allaction:Canceled Successfully"), '', 'success').then(() => {
                  this.setState({redirectTo: "/stockandsupplies/stockManagement/list/all"})
                })
      
              }else{
                Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), i18next.t("Allaction:Please try again."), 'warning')
              }
            }, 
            () => { Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), '', 'warning')}
          )
        }
        
      }

    }else{
      if(error_newRequisitionList.length > 0){
        Swal.fire(i18next.t("stockCreateAndUpdate:Someting has requisition is over remaining amount"), '', 'warning')
      }else{
         Swal.fire("Error!", i18next.t("stockCreateAndUpdate:Please add requisition list"), 'warning')
      }
     
    }
  }

  handleChangeNumberOfPieces = (inputNumberOfPieces, even, index, id) => {  
    let allProduct = _.clone(this.state.allProductList)
    let { name, value } = even.target
    let arrNewRemaining = _.find(allProduct , {node : {id : id}})
    let indexProductRemaining = _.findIndex(allProduct, function(o) { return o.node.id === id })

    if (value.indexOf(".") >= 0) {
      value = value.substr(0, value.indexOf("."))
    } else {
      value = value
    }
    
    let newRemaining = (arrNewRemaining.node.remaining + (inputNumberOfPieces  - (value === '' ? 0 : parseInt(value))))
    
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), `${name}.numberOfPieces`, value)
    })
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), `${name}.remaining`, newRemaining)
    })
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState),`allProductList[${indexProductRemaining}].node.remaining`, newRemaining)
    })

  }

  handleOpenModal = () => {
    this.setState({
      openModalVoid: true
    })
  }

  handleCloseModal = () => {
    this.setState({
      openModalVoid: false
    })
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />
    }
    return (
      <React.Fragment>
        {this.props.match.params.status === "unit" &&
          <CreateFormUnit
            requisitionNoteID={this.props.location.state.requisitionNoteID}
            action={this.props.match.params.action}
            coOwnerMember={this.state.requisitionNote.coOwnerMember}
            docNumber={this.state.requisitionNote.docNumber}
            issuedDate={this.state.requisitionNote.issuedDate}
            recipient={this.state.requisitionNote.recipient}
            firstNameRecipient={this.state.requisitionNote.firstNameRecipient}
            lastNameRecipient={this.state.requisitionNote.lastNameRecipient}
            juristict={this.state.juristict}
            objective={this.state.requisitionNote.objective}
            requisitionList={this.state.requisitionNote.requisitionList}
            allProductList={this.state.allProductList}
            switchSelectProduct={this.state.switchSelectProduct}
            appendRequisitionList={this.appendRequisitionList}
            requisitionNoteDocuments={this.state.requisitionNote.requisitionNoteDocuments}
            remark={this.state.requisitionNote.remark}
            status={this.props.match.params.status}
            handleChangeInput={this.handleChangeInput}
            appendFileInputRow={this.appendFileInputRow}
            handleRemoveImgSelect={this.handleRemoveImgSelect}
            handleRemoveSelect={this.handleRemoveSelect}
            handleChangeNumberOfPieces={this.handleChangeNumberOfPieces}
            onsubmit={this.onsubmit}
            handleOpenModal={this.handleOpenModal}
            />
        }
        {this.props.match.params.status === "tenant" &&
          <CreateFormTenant 
            requisitionNoteID={this.props.location.state.requisitionNoteID}
            action={this.props.match.params.action}
            coOwnerMember={this.state.requisitionNote.coOwnerMember}
            docNumber={this.state.requisitionNote.docNumber}
            issuedDate={this.state.requisitionNote.issuedDate}
            juristict={this.state.juristict}
            objective={this.state.requisitionNote.objective}
            allProductList={this.state.allProductList}
            switchSelectProduct={this.state.switchSelectProduct}
            appendRequisitionList={this.appendRequisitionList}
            requisitionList={this.state.requisitionNote.requisitionList}
            requisitionNoteDocuments={this.state.requisitionNote.requisitionNoteDocuments}
            remark={this.state.requisitionNote.remark}
            handleChangeInput={this.handleChangeInput}
            appendFileInputRow={this.appendFileInputRow}
            handleRemoveImgSelect={this.handleRemoveImgSelect}
            handleRemoveSelect={this.handleRemoveSelect}
            handleChangeNumberOfPieces={this.handleChangeNumberOfPieces}
            onsubmit={this.onsubmit}
            handleOpenModal={this.handleOpenModal}
            />
        }
        {this.props.match.params.status === "juristic" &&
          <CreateFormJuristic
            requisitionNoteID={this.props.location.state.requisitionNoteID}
            action={this.props.match.params.action}
            coOwnerMember={this.state.requisitionNote.coOwnerMember}
            docNumber={this.state.requisitionNote.docNumber}
            issuedDate={this.state.requisitionNote.issuedDate} 
            juristict={this.state.juristict}
            objective={this.state.requisitionNote.objective}
            username={this.state.requisitionNote.username}
            allProductList={this.state.allProductList}
            switchSelectProduct={this.state.switchSelectProduct}
            appendRequisitionList={this.appendRequisitionList}
            requisitionList={this.state.requisitionNote.requisitionList}
            requisitionNoteDocuments={this.state.requisitionNote.requisitionNoteDocuments}
            remark={this.state.requisitionNote.remark}
            handleChangeInput={this.handleChangeInput}
            appendFileInputRow={this.appendFileInputRow}
            handleRemoveImgSelect={this.handleRemoveImgSelect}
            handleRemoveSelect={this.handleRemoveSelect}
            handleChangeNumberOfPieces={this.handleChangeNumberOfPieces}
            onsubmit={this.onsubmit}
            handleOpenModal={this.handleOpenModal}
            />
        }

        {this.state.openModalVoid && 
          <ModalVoidStockManagement 
            open={this.state.openModalVoid}
            handleVoid={this.handleVoid}
            handleCloseModal={this.handleCloseModal}/>
        }
        
      </React.Fragment>
    )
  }
}
