import styled from "styled-components";
const P2 = styled.div`
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: ${props => props.fontSize || '1rem'}!important; 
  line-height: 1.5rem !important;
  color: #2D2D30 !important;
  ${props => props.report && 'font-size: 12px !important;'}
  /* color: #171B21; */
`;
const Td = styled.td`
  font-family: "Sarabun" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: ${props => props.fontSize || '1rem'}!important; 
  line-height: 1.5rem !important;
  color: #2D2D30 !important;
  ${props => props.report && 'font-size: 12px !important;'}
`;

const B1 = styled.div`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'liga' off;
  color: ${props => props.color || '#2D2D30'} !important;
`;

const B2 = styled.div`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: 'liga' off;
  color: ${props => props.color || '#2D2D30'} !important;
`;
const BL2 = styled.label`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: 'liga' off;
  color: ${props => props.color || '#2D2D30'} !important;
`;

const B3 = styled.div`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  font-feature-settings: 'liga' off;
  color: ${props => props.color && '#2D2D30'};
  
`;

// const H4 = styled.div`
const H3 = styled.div`
  font-family: "Kanit" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 28px;
  line-height: 42px;
  color: #0F0F0F !important;
  `

const H4 = styled.div`
  font-family: "Kanit" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #2D2D30 !important;
`;

const HS4 = styled.span`
  font-family: "Kanit" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  /* line-height: 36px !important; */
  color: #2D2D30 !important;
`;

const H5 = styled.div`
  font-family: "Kanit" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: ${props => props.color || '#2D2D30'} !important;
`;

const HS5 = styled.span`
  font-family: "Kanit" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: ${props => props.color || '#2D2D30'} !important;
`;

const H6 = styled.div`
  font-family: "Kanit" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: ${props => props.fontSize || '1rem'} !important;  
  line-height: 1.5rem !important;
  color: ${props => props.color || '#2D2D30'} !important;
  ${props => props.report && 'font-size: 12px !important;'}
`
const HS6 = styled.span`
  font-family: "Kanit" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: ${props => props.fontSize || '1rem'} !important;  
  line-height: 1.5rem !important;
  color: ${props => props.color || '#2D2D30'} !important;
  ${props => props.report && 'font-size: 12px !important;'}
`;

const Disable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  height: ${props => props.height || '48px'} !important;  
  left: 0px;
  background: #E9ECEF;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 8px 0px;
  padding: 0px 16px;

  font-family: Kanit;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #4D4D4D;
`;

const MButton = styled.button`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* padding: 8px 16px; */
  height: 38px;
  background: ${props => props.background || '#1567B4'} !important; 
  border: ${props => props.border || '1px solid #1567B4'} ;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.color || '#FFFFFF'} !important;
  flex: none;
  margin: 0px 8px;
`;
const LButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.minWidth && 'min-width: ' + props.minWidth + ' !important;'}
  ${props => props.justifyContent && 'justify-content: ' + props.justifyContent + ' !important;'}
  padding: 12px 24px 12px 24px;
  background: ${props => props.background || '#1567B4'} !important; 
  border: ${props => props.border || '0px solid '} ;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.color || '#FFFFFF'} !important;
  flex: none;
  margin: 0px 8px;
`;

const TagStatus = styled.div`
justify-content: center;
align-items: center;
text-align: center;
/* height: 26px; */
padding: 4px 8px 4px 8px;
background: ${props => props.background || '#FFBD2D'}!important; 
border-radius: 20px;
/* justify-items: center; */

font-family: Kanit;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 18px;
color: #FFFFFF;
`;

const Line = styled.div`
height: 0px;
border: 1px solid #D9D9D9;
`;

const Th = styled.th`
  font-family: "Kanit" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: ${props => props.fontSize || '1rem'} !important;  
  line-height: 1.5rem !important;
  color: #2D2D30 !important;
  ${props => props.report && 'font-size: 12px !important;'}
`;


const Tag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  min-width: 77px;
  height: 26px;
  background: ${props => props.color || '#1567B4'};
  border-radius: 20px;

  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
`;


const AlertTooltip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  width: auto;
  height: 56px;
  background:${props => props.background || '#E3FFE5'} !important;
  border-radius: 4px;
`;

const Input = styled.input`
  padding: 15px 16px;
  height: ${props => props.height || '32px'};
  background: ${props => props.background || '#FFFFFF'};
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  ${props => props.width && 'width: 454px !important;'}
  ${props => props.maxWidth && `max-width: ${props.maxWidth} `}
`;

const CButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px 8px 16px;
  background: ${props => props.background || '#FFFFFF'};
  border: 1px solid #B3B3B3;
  box-sizing: border-box;
  border-radius: 4px;
`;

export { P2, H5, H6, B2, B3, H4, H3, Td, Th, HS4, Disable, TagStatus, Line, MButton, LButton, AlertTooltip, B1, BL2, Input, CButton, HS5, HS6, Tag };
