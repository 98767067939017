import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Navigation from "./navigation";

import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";

import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import OtherExpenseListTable from "./otherExpenseListTable";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import i18next from "i18next";

const allOtherExpense = graphql`
  query otherExpenseListQuery(
    $status: String!
    $search: String
    $start_date: DateTime
    $end_date: DateTime
    $first: Int
    $last: Int
  ) {
    allOtherExpense(
      status: $status
      search: $search
      startDate: $start_date
      endDate: $end_date
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node {
          id
          docNumber
          total
          issuedDate
          creator
          status
          remark
          refNumber
          refOtherExpenseDoc
          taxInvoicePeriod
          taxInvoiceDate
          added
          taxInvoiceNumber
          unknownContact
          updated
          voidRemark
          contact {
            id
            refNumber
            name
          }
          otherExpenseTransaction{
            edges{
              node{
                description
                whtRate
                total
              }
            }
          }
        }
      }
    }
  }
`;

class OtherExpenseList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      temp_start_date: null,
      temp_end_date: null,
      search: "",
      search_input: "",
      advance_search: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.setState({
      status: this.props.match.params.status,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="receive-list">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("expense:other_expense")+ " "+"(OE)"}</span>}
                  </Translation>
                </h3>
              </div>
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_other_expense_create",
                }) && (
                    <Link to="/accounting/expense/other_expense/form/select_customer">
                      <button
                        type="button"
                        className="btn btn-primary add float-right"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                          alt="plus"
                        />
                        <Translation>
                          {(t) => <span>{t("expense:add_other_expense")}</span>}
                        </Translation>
                      </button>
                    </Link>
                  )}
              </div>
            </div>
            <Translation>
              {t =>
                <div className="content-inner">
                  <Navigation />

                  <div className="row mb-2 mt-1">
                    <div className="col-12 text-right">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>
                      {/* <button
                    type="button"
                    className="btn btn-outline-secondary float-right"
                    onClick={() =>
                      this.setState({
                        advance_search: !this.state.advance_search,
                      })
                    }
                  >
                    <img
                      src="/images/icons/filter-icon.png"
                      alt="filter-icon"
                    />
                  </button> */}
                      <InlineAdvanceFilter
                        start_date={this.state.temp_start_date}
                        end_date={this.state.temp_end_date}
                        handleChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col">
                      <OtherExpenseListTable
                        query={allOtherExpense}
                        search={this.state.search}
                        start_date={this.state.start_date}
                        end_date={this.state.end_date}
                        status={this.props.match.params.status}
                      />
                    </div>
                  </div>
                </div>
              }
            </Translation>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default OtherExpenseList;
