import React, { Component } from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import environment from "../../env/environment";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import _ from "lodash";
import CollectionInvoiceTable from "./collectionInvoiceTable";
import './collection.scss'
import UpdateCollectionMutation from "./updateCollection";
import i18n from 'i18next';
import DeleteInvoice from "./deleteInvoice";
import getNameResidential from "../../libs/getNameResidential";
import Loading from "../../libs/loading";
import CollectionLetterAttachMutation from './collectionLetterAttachMutation';
import i18next from "i18next";
import {Translation} from "react-i18next";

const query = graphql`
    query collectionLetterDetailQuery($collectionLettersId: [String]) {
        allCollectionLetters(collectionLettersId:$collectionLettersId){
            edges{
                node{
                    id
                    numberOfTimes
                    refDocNumber
                    docNumber
                    added
                    issuedDate
                    creator
                    status
                    creatorDelete
                    voidNote
                    updated
                    sumTotal
                    added
                    contact {
                        id
                        name
                        refNumber
                        firstName
                        lastName
                        registeredName
                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode
                        typeOfContact
                    }
                    documentCollectionLetters{
                    edges{
                        node{
                            id
                            fileName
                            fileUpload
                            numPage
                        }
                    }
                    }
                    recordCollectionLetters{
                        edges {
                            node {
                                id
                                sumTotalRecord
                                transaction{
                                  id
                                  description
                                  total
                                  invoice{
                                      id
                                      docNumber
                                      dueDate
                                      total
                                  }
                                  productAndService {
                                      id
                                      productCode
                                      name
                                  }
                                  chartOfAccount {
                                      chartOfAccountCode
                                  } 
                                }
                            }
                        }
                    }
                }
            }
        }  
    }

`;
class CollectionLetterDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      reQuery : false,
      tabGreen : true,
      tabRed : true,
      reload : false,
    };
    this.attachFile = this.attachFile.bind(this);
    this.onDeleteAttach = this.onDeleteAttach.bind(this);
  }


  onCancelLetter = async (id) => {
    this.setState({loading : true})
    let { value: void_remark }  = await Swal.fire({
      title: `${i18next.t("Allaction:Do you want to void for this document")}?`,
      type: 'warning',
      text: i18next.t("Allaction:Please enter a cancellation note."),
      input: 'text',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: i18next.t("Allaction:Confirm"),
      cancelButtonText: i18next.t("Allaction:cancel"),
      inputValidator: (value) => {
        if (!value) {
            return i18next.t("Allaction:Please enter a cancellation note.")
        }
      }
    })
    if (void_remark) {
      this.setState({loading: true});
      let variables = {
        input : {
          updateDebitManual : [{
            collectionLettersId : id,
            status : 'void',
            delete : false,
            voidNote : void_remark
          }]
        }
      }
      UpdateCollectionMutation(variables, this.onCancelSuccess,this.onCancelError)
    }
  }

  onConfirmLetter = (id) => {
    this.setState({loading : true})
    let updateDebitManual = []
    updateDebitManual.push({
      collectionLettersId : id,
      status : 'active',
      delete : false
    })
    let variables = {
      input : {
        updateDebitManual : updateDebitManual
      }
    }

    UpdateCollectionMutation(variables, this.onSubmitSuccess,this.onSubmitError)
  }

  onDraftLetter = (id) => {
    this.setState({loading : true})
    let updateDebitManual = []
    updateDebitManual.push({
      collectionLettersId : id,
      status : 'draft',
      delete : false
    })
    let variables = {
      input : {
        updateDebitManual : updateDebitManual
      }
    }
    UpdateCollectionMutation(variables, this.onSubmitSuccess,this.onSubmitError)
  }

  onSubmitSuccess = (response) => {
    this.setState({loading: false});
    if (response) {
        Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
            this.setState({redirect:true})
        });
    } else {
        Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
    }
  }

  onSubmitError = (err) => {
      this.setState({loading: false});
      Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
  }

  onDeleteSuccess = (response) => {
    if (response) {
        Swal.fire('ลบรายการสำเร็จ', '', 'success').then(() => {
          this.setState({loading: false , reQuery : !this.state.reQuery});
        });
    } else {
        Swal.fire('ลบรายการไม่สำเร็จ', '', 'warning').then(() => {
          this.setState({loading: false , reQuery : !this.state.reQuery});
        })
    }
  }

  onCancelError = (err) => {
      this.setState({loading: false});
      Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
  }

  onCancelSuccess = (response) => {
    if (response) {
        Swal.fire('ยกเลิกหนังสือทวงถามสำเร็จ', '', 'success').then(() => {
          this.setState({redirect : true});
        });
    } else {
        Swal.fire('ยกเลิกหนังสือทวงถามไม่สำเร็จ', '', 'warning').then(() => {
          this.setState({loading: false , reQuery : !this.state.reQuery});
        })
    }
  }



  onDeleteError = (err) => {
      this.setState({loading: false});
      Swal.fire('ลบรายการไม่สำเร็จ', i18next.t("Allaction:Please try again."), 'warning')
  }


  onDelete = (id) => {
    Swal.fire({
      title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: i18next.t("Allaction:Yes"),
      cancelButtonText: i18next.t("Allaction:cancel")
    }).then((result) => {
        if (result.value) {
          let variables = {
            input : {
              deleteDebitManual : [id]
            }
          } 
          DeleteInvoice(variables, this.onDeleteSuccess,this.onDeleteError)

        }
    })
  }

  attachFile(documentNum, startNumberOfAttach = 0) {
    this.props.history.push({
      pathname: `/accounting/collection_letters/detail/ACTIVE/${this.props.match.params.id}/attach`,
      state: {
        documentNum: documentNum,
        startNumberOfAttach: startNumberOfAttach
      }
    });
  }

  onDeleteAttach = (attach) => {
    this.setState({loading: true});
        let variables = {
            "input": {
                "fileDocument": {
                    "collectionLettersId":`${this.props.match.params.id}`,
                    "pdf":[
                        {
                            "id": attach.node.id,
                            "status": "delete"
                        },
                    ]
                }
                } 
        };
        CollectionLetterAttachMutation(variables,
            () => Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                this.setState({
                    loading: false,
                    reload:true
                })
                })
            , () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error').then(() => {
                this.setState({loading: false});
            })
        );    
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/accounting/collection_letters/list/all" />;
    }
    if (this.state.reload) {
      window.location.reload();
    }
   

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <Translation>
         {  t =>  
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              collectionLettersId: [this.props.match.params.id],
              reQuery : this.state.reQuery
            }}
            render={({ error, props }) => {
              if (error) {
                return (
                  <div className="alert alert-danger" role="alert">
                    {error.message}
                  </div>
                );
              } else if (props) {
                let address = ''
                let name = ''
                if(props.allCollectionLetters.edges[0].node.contact.registeredProvince.includes("กรุงเทพ") || props.allCollectionLetters.edges[0].node.contact.registeredProvince.includes("กทม")){
                  address = props.allCollectionLetters.edges[0].node.contact.registeredAddress+" " +
                  (props.allCollectionLetters.edges[0].node.contact.registeredDistrict && 'แขวง' + props.allCollectionLetters.edges[0].node.contact.registeredDistrict) +" " +
                  (props.allCollectionLetters.edges[0].node.contact.registeredCity && 'เขต' + props.allCollectionLetters.edges[0].node.contact.registeredCity)+" " +
                  (props.allCollectionLetters.edges[0].node.contact.registeredProvince && props.allCollectionLetters.edges[0].node.contact.registeredProvince) +" "+
                  props.allCollectionLetters.edges[0].node.contact.registeredPostalCode + " " +
                  props.allCollectionLetters.edges[0].node.contact.registeredCountry
                }else{
                  address = props.allCollectionLetters.edges[0].node.contact.registeredAddress+" " +
                  (props.allCollectionLetters.edges[0].node.contact.registeredDistrict && 'ตำบล' + props.allCollectionLetters.edges[0].node.contact.registeredDistrict)+" " +
                  (props.allCollectionLetters.edges[0].node.contact.registeredCity && 'อำเภอ' + props.allCollectionLetters.edges[0].node.contact.registeredCity)+" " +
                  (props.allCollectionLetters.edges[0].node.contact.registeredProvince &&  'จังหวัด' + props.allCollectionLetters.edges[0].node.contact.registeredProvince)+" " +
                  props.allCollectionLetters.edges[0].node.contact.registeredPostalCode + " " +
                  props.allCollectionLetters.edges[0].node.contact.registeredCountry
                }
                if(props.allCollectionLetters.edges[0].node.contact.typeOfContact === 'RESIDENTIAL'){
                  name = getNameResidential( props.allCollectionLetters.edges[0].node.contact.firstName , props.allCollectionLetters.edges[0].node.contact.lastName )
                }else{
                  name = props.allCollectionLetters.edges[0].node.contact.name
                }
                return (
                  <div className="container-fluid box" id="collection-letter">
                    <div className="row justify-content-between">
                      <div className="col">
                        <h4 className="mb-4">
                          <Link to={this.props.location?.state?.linkBack ?? "/accounting/collection_letters/list/all"}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/wrapperContent/back.png"
                              }
                              alt="back"
                              className="back"
                            />
                          </Link>
                          {this.props.match.params.status === 'DRAFT' ? name :props.allCollectionLetters.edges[0].node.docNumber }
                        </h4>
                      </div>
                      {(this.props.match.params.status !== 'DRAFT' && this.props.match.params.status !== 'VOID' && this.props.match.params.status !== 'PAID') &&
                        <div className="col text-right">
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_print' }) &&
                            <Link
                              to={
                                "/document/collection-letter/" + props.allCollectionLetters.edges[0].node.numberOfTimes +'/'+this.props.match.params.id
                              }
                              target='_blank'
                            >
                              <button
                                className="btn btn-print"
                                disabled={this.state.loading}
                              >
                                <span>{t("collectionLetter:Print")}</span>
                              </button>
                            </Link>}
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_delete' }) &&
                              <button
                                className="btn btn-cancel ml-2"
                                disabled={this.state.loading}
                                onClick={() => this.onCancelLetter(props.allCollectionLetters.edges[0].node.id)}
                              >
                                <span>{t("collectionLetter:Cancel")}</span>
                              </button>
                            }
                        </div>
                      }
                    </div>
                    {props.allCollectionLetters.edges[0].node.status === 'PAID' && this.state.tabGreen &&
                      <div className="row col-12 pl-4 ml-1 pr-4">
                        <div className="tabGreen col-12">
                          <span>{t("collectionLetter:The payment for the outstanding debt has been completed as of")} {format(props.allCollectionLetters.edges[0].node.issuedDate,'DD/MM/YYYY')}</span>
                          <img className="cursor float-right d-flex align-items-center"  src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="plus"
                           onClick= {() => this.setState({tabGreen : false})}
                            />
                        </div>
                      </div>
                    }
                    {props.allCollectionLetters.edges[0].node.status === 'VOID' && this.state.tabRed &&
                      <div className="row col-12 pl-4 ml-1 pr-4">
                        <div className="tabRed col-12">
                          <span>{t("collectionLetter:Cancelled: Reason")} {props.allCollectionLetters.edges[0].node.voidNote} โดย {props.allCollectionLetters.edges[0].node.creatorDelete} เมื่อวันที่ {format(props.allCollectionLetters.edges[0].node.issuedDate,'DD/MM/YYYY')}</span>
                          <img className="cursor float-right d-flex align-items-center"  src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="plus"
                            onClick= {() => this.setState({tabRed : false})}
                            />
                        </div>
                      </div>
                    }
                    {props.allCollectionLetters.edges[0].node.status !== 'DRAFT' &&
                      <React.Fragment>
                        <div className="row mt-3 pl-4 pr-4">
                          <div className="col-2">
                            <h6>{i18next.t("receiveSelectInvoice:Date of issue")}</h6>
                          </div>
                          <div className="col-2">
                            <h6>{i18next.t("collectionLetter:Time of request")}</h6>
                          </div>
                          <div className="col-2">
                            <h6>{i18next.t("collectionLetter:Created Date")}</h6>
                          </div>
                          {(props.allCollectionLetters.edges[0].node.numberOfTimes > 1 && props.allCollectionLetters.edges[0].node.status === 'ACTIVE') &&
                            <div className="col-2">
                              <h6>{i18next.t("cheque:Reference document")}</h6>
                            </div>
                          }
                        </div>
                        <div className="row pl-4 mb-3 pr-4">
                          <div className="col-2">
                            <input type="text" className="form-control" 
                              disabled={true} value={format(props.allCollectionLetters.edges[0].node.updated,'DD/MM/YYYY')}
                            />
                          </div>
                          <div className="col-2">
                            <input type="text" className="form-control" 
                              disabled={true} value={props.allCollectionLetters.edges[0].node.numberOfTimes}
                            />
                          </div>
                          <div className="col-2">
                            <input type="text" className="form-control" 
                              disabled={true} value={format(props.allCollectionLetters.edges[0].node.added,'DD/MM/YYYY HH:mm:ss')}
                            />
                          </div>

                          {(props.allCollectionLetters.edges[0].node.numberOfTimes > 1 && props.allCollectionLetters.edges[0].node.status === 'ACTIVE') &&
                            <div className="col-2">
                              <input type="text" className="form-control" 
                                disabled={true} value={props.allCollectionLetters.edges[0].node.refDocNumber}
                              />
                            </div>
                          }
                        </div>
                        
                      </React.Fragment>
                    }
                    <div className="row mt-4 pl-4 pr-4">
                      <div className="col-12 mt-4">
                        <h4>{this.props.match.params.status !== 'DRAFT' ? i18next.t(`collectionLetter:Recipient of Collection Letter`) : i18next.t(`selectContact:debtor_information`)}</h4>
                        <hr/>
                      </div>
                    </div>
                    <div className="row pl-4 mt-2 pr-4">
                      <div className="col-2">
                        <h6>{i18next.t("collectionLetter:Co-owner ID / debtor")}</h6>
                      </div>
                      <div className="col-4">
                        <h6>{i18next.t("collectionLetter:Name")}</h6>
                      </div>
                      <div className="col-6">
                      <h6>{i18next.t("collectionLetter:Address")}</h6>
                      </div>
                    </div>
                    <div className="row pl-4 pr-4 mb-2">
                      <div className="col-2">
                        <input type="text" className="form-control" 
                          disabled={true} value={props.allCollectionLetters.edges[0].node.contact.refNumber}
                        />
                      </div>
                      <div className="col-4">
                        <input type="text" className="form-control" 
                          disabled={true} value={name}
                        />
                      </div>
                      <div className="col-5">
                        <input type="text" className="form-control" 
                          disabled={true} value={address}
                        />
                      </div>
                    </div>

                    
                    <CollectionInvoiceTable props={props.allCollectionLetters.edges[0]} onDelete ={this.onDelete} sumTotal={props.allCollectionLetters.edges[0].node.sumTotal}/>
                    {this.props.match.params.status === 'DRAFT' && 
                      <div className="row mt-3">
                          <div className="col-12 d-flex justify-content-end p-4">
                              {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_delete' }) && 
                                <Link  to="/accounting/collection_letters/list/draft"><button type="button" className="btn-cancel-draft mr-3" >ยกเลิก</button></Link>
                                }
                              {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_edit' }) &&
                                <button type="button" className="btn-print mr-3" onClick={() => this.onDraftLetter(props.allCollectionLetters.edges[0].node.id)}>
                                  {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                  บันทึกแบบร่าง
                                </button>
                              }
                              {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_approve' }) &&
                                <button type="button" className="btn-confirm" onClick={() => this.onConfirmLetter(props.allCollectionLetters.edges[0].node.id)}>
                                    {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                    อนุมัติ
                                </button>
                              }
                          </div>
                      </div>
                    }

                    {/* ถ้ามีเอกสารแนบจะแสดงตารางนี้ */}
                    <div className="row pl-4 pr-4 mb-2 mt-4">
                      {props.allCollectionLetters.edges[0].node.documentCollectionLetters.edges.length > 0 &&
                      <div className="row col-12 mb-3" style={{marginTop:'48px'}}>
                        <div className="attachText">
                          <h4>{t("collectionLetter:Attached documents")}</h4>
                          <hr />
                        </div>
                        <div className="row mt-3 col-12">
                          <div className="table-responsive">
                            <table className="table table-collection tableAttach">
                                <thead className="thead-light headTable">
                                  <tr>
                                      <th className="text-left">{t("collectionLetter:Attached name")}</th>
                                      <th className="text-center">{t("collectionLawFirm:Number of documents (sheets)")}</th>
                                      <th></th>
                                  </tr>
                                </thead>
                                <tbody className="tableList">
                                    {props.allCollectionLetters.edges[0].node.documentCollectionLetters.edges?.map((attach, index) => {
                                    return (
                                      <tr key={index}>
                                          <td className="text-left">
                                                <a download={attach.node.fileName} href={attach.node.fileUpload} target="_blank">
                                                    <span className="cursor text-blue">{index+1}. {attach.node.fileName}</span>
                                                </a>
                                          </td>
                                          <td className="text-center">{attach.node.numPage}</td>
                                          <td className="text-center">
                                              {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_delete' }) &&
                                                <button
                                                    type="button"
                                                    className="btn btn-noHover btn-outline-secondary ml-2 delBin"
                                                    onClick={()=>this.onDeleteAttach(attach)}
                                                    >
                                                    <img
                                                        src={
                                                        process.env.PUBLIC_URL +
                                                        "/images/icons/bin-outline.png"
                                                        }
                                                        alt="delete-icon"
                                                    />
                                                </button>
                                              }
                                          </td>
                                      </tr>

                                    )})}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                    <div className="row pl-4 pr-4 mb-2 mt-4">
                      <div className="attachBtnCollectionDetail" onClick={()=>this.attachFile(props.allCollectionLetters.edges[0].node.docNumber, props.allCollectionLetters.edges[0].node.documentCollectionLetters.edges?.length)}>
                        + {t("collectionLetter:Attached documents")}
                      </div>
                    </div>
                  </div>
                );
              }
              return <div className="row col-12 text-center"><Loading/></div >
            }}
          />
        </WrapperContent>
        }
        </Translation>
      </Wrapper>
    );
  }
}

export default CollectionLetterDetail;
