import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import _ from "lodash"
import { Translation } from "react-i18next";
import i18next from "i18next";


class AssetRegisterReportTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            asset : this.props.props,
        }
    }

    checkTypeAsset(type){
        switch (type) {
            case "LAND" :
                return "ที่ดิน";
            case "BUILDING" :
                return "อาคาร";
            case "BUILDING_RENOVATION" :
                return "ส่วนต่อเติมและปรับปรุงอาคาร";
            case "OFFICE_DECORATION" :
                return "เครื่องตกแต่งสำนักงาน";
            case "OFFICE_EQUIPMENT":
                return "อุปกรณ์และเครื่องใช้สำนักงาน";
            case "TOOLS" :
                return "เครื่องมือ/อุปกรณ์งานช่าง";
            case "TRAINING_EQUIPMENT" :
                return "เครื่องออกกำลังกาย/สันทนาการ";
            case "VEHICLE" :
                return "ยานพาหนะ";
            case "COMMON_PROPERTY" :
                return "ทรัพย์สินส่วนกลาง";
            case "OTHER_ASSET" :
                return "สินทรัพย์อื่นๆ";
            case "INTANGIBLE_ASSET" :
                return "สินทรัพย์ไม่มีตัวตน";

        }
    }

    componentDidMount(){
        this.props.handlerAsset(this.props.props)
    }
    render() {
        let order_id = 0;
        //หาราคารวมของแต่ละชนิด
        let assetEachType = _.chain(this.props.props.allAsset.edges).groupBy('node.type').map((asset,index)=> {
            let total_sum_asset_depreciate = 0.0; 
            //คำนวณค่าเสื่อม
            asset.map((asset_depreciate_list) => {
                total_sum_asset_depreciate += asset_depreciate_list.node.historicalAmount; //ราคารวมค่าเสื่อมสะสม + ค่าเสื่อมยกมา
                total_sum_asset_depreciate += _.sumBy(asset_depreciate_list.node.assetDepreciate.edges,'node.depreciatePrice')

            })
            return ({  
                total_costPrice : _.sumBy(asset,'node.costPrice'), //สินทรัพย์
                total_assetDepreciate : total_sum_asset_depreciate, //ค่าเสื่อมสะสม
                total_assetAfterDepreciate : _.sumBy(asset,'node.costPrice') - total_sum_asset_depreciate, //สินทรัพย์หลังหักค่าเสื่อมสะสม
                asset : asset,
                type : _.sumBy(asset,'node.type'),
            })
        }).value();
        let asset = {
            //ราคารวมทั้งหมด
            sum_costPrice : _.sumBy(assetEachType,'total_costPrice') , //สินทรัพย์
            sum_assetDepreciate : _.sumBy(assetEachType,'total_assetDepreciate'), //ค่าเสื่อม
            sum_assetAfterDepreciate : _.sumBy(assetEachType,'total_assetAfterDepreciate'), //สินทรัพย์หลังหักค่าเสื่อมสะสม
            assetEachType : assetEachType,
            count_list : assetEachType.length
        }
        return (
            <Translation>
                {t =>
                <React.Fragment>
                    {asset.assetEachType.map((asset_each_type,index_asset_type) => {
                        order_id = 0;
                        return (
                            <React.Fragment key = {"asset" + index_asset_type} >
                                {asset_each_type.asset.map((asset,index) => {
                                    order_id += 1;
                                    let sum_asset_depreciate = asset.node.historicalAmount; //ราคารวมค่าเสื่อมสะสม + ค่าเสื่อมยกมา
                                    sum_asset_depreciate += _.sumBy(asset.node.assetDepreciate.edges,'node.depreciatePrice')
                                    let diffDate = new Date(new Date() -  new Date(asset.node.purchaseDate))
                                    let year = (diffDate.toISOString().slice(0, 4) - 1970)
                                    let month = diffDate.getMonth()
                                    return (
                                        <React.Fragment key={asset.node.id + index}>
                                            {index === 0 && 
                                                <tr className="table-active" key="head"> 
                                                    <td colSpan={24}>{_.find(this.props.asset_type,{code: asset.node.type.toLowerCase()}).header_name}</td>
                                                </tr> 
                                            }
                                            <tr>
                                                <td className="text-center">{order_id}</td>
                                                <td className="text-center">{this.checkTypeAsset(asset.node.type)}</td>
                                                <td className="text-center">{asset.node.docNumber}</td>
                                                <td className="text-center">{asset.node.internalAssetCode}</td>
                                                <td>{asset.node.name}</td>
                                                <td>{asset.node.description}</td>
                                                <td className="text-center">{asset.node.storage}</td>
                                                <td className="text-center">{asset.node.seller ? asset.node.seller.refNumber : '-'}</td>
                                                <td className="text-center">{asset.node.seller ? asset.node.seller.name : asset.node.sellerOther ? asset.node.sellerOther : '-'}</td>
                                                <td className="text-center">1</td>
                                                <td className="text-center">{asset.node.nounClassifier}</td>
                                                <td className="text-center">{asset.node.calDepreciation ? 'เส้นตรง' : '-'}</td>
                                                <td className="text-right">{numberWithComma(asset.node.depreciationRatePercent, '0.00')}</td>
                                                <td className="text-right">{asset.node.lifeTimeYear ? asset.node.lifeTimeYear : '-'}</td>
                                                <td className="text-center">{asset.node.purchaseDate ? format(asset.node.purchaseDate, 'DD/MM/YYYY', {locale: thLocale}) : '-'}</td>
                                                <td className="text-right">{numberWithComma(asset.node.costPrice,'0.00')}</td>
                                                <td className="text-right">{numberWithComma(asset.node.carcassPrice,'0.00')}</td>
                                                <td className="text-center">{year === 0 ? '-' : year}</td>
                                                <td className="text-center">{month === 0 ? '-' : month}</td>
                                                <td className="text-center">{asset.node.calDepreciationDate ? format(asset.node.calDepreciationDate, 'DD/MM/YYYY', {locale: thLocale}) : '-'}</td>
                                                <td className="text-right">{numberWithComma(sum_asset_depreciate, '0.00')}</td>
                                                <td className="text-right">{numberWithComma(asset.node.costPrice - sum_asset_depreciate , '0.00')}</td>
                                                <td className="text-center">{asset.node.saleDate ? format(asset.node.saleDate, 'DD/MM/YYYY', {locale: thLocale}) : '-'}</td>
                                                <td className="text-center">{asset.node.saleStatus !== 'ACTIVE' ? t('asset:'+ _.lowerCase(asset.node.saleStatus)) : '-'}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                                <tr key ="foot">
                                    <td colSpan={14}/>
                                    <td  className="text-right"><strong>{i18next.t("ReportAssetDetail:Sum")}</strong></td>
                                    <td className="text-right"><strong>{numberWithComma(asset_each_type.total_costPrice)}</strong></td>
                                    <td colSpan={4} />
                                    <td className="text-right"><strong>{numberWithComma(asset_each_type.total_assetDepreciate)}</strong></td>
                                    <td className="text-right"><strong>{numberWithComma(asset_each_type.total_assetAfterDepreciate)}</strong></td>
                                    <td colSpan={2} />
                                </tr>
                            </React.Fragment>
                        )
                    })}
                    {this.props.props.allAsset.edges.length > 0 && asset.count_list > 1 &&
                    <tr>
                        <td colSpan={14}/>
                        <td className="text-right"><strong>{i18next.t("ReportAssetDetail:Total")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(asset.sum_costPrice)}</strong></td>
                        <td colSpan={4} />
                        <td className="text-right"><strong>{numberWithComma(asset.sum_assetDepreciate)}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(asset.sum_assetAfterDepreciate)}</strong></td>
                        <td colSpan={2} />
                    </tr>
                    }
                </React.Fragment>
                }
            </Translation>
        )
    }
}

export default AssetRegisterReportTable;
