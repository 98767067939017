import React, {Component} from 'react';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Wrapper from '../components/wrapper';
import WrapperContent from '../components/wrapper/wrapperContent';
import Loading from "../libs/loading"
import UploadFileList from './uploadFileList';
import {Redirect} from 'react-router';

class UploadFileWrapper extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            checkOnline: true
        }
    }
    
    render() {
        if(this.props.match.params.status === undefined){
            return <Redirect to={"all"}/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    {this.state.loading ? <Loading/>
                     : <UploadFileList center={true} status={ this.props.match.params.status}/>
                    }
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default UploadFileWrapper;
