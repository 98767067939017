/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type allSiteBySuperAdminQueryVariables = {|
  typeOfProject?: ?string,
  search?: ?string,
  order?: ?string,
|};
export type allSiteBySuperAdminQueryResponse = {|
  +allSelectJuristic: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +site: {|
          +id: string,
          +domain: string,
          +project: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +nameTh: ?string,
                +nameEn: ?string,
                +typeOfProject: ?ProjectTypeOfProject,
                +logo: ?string,
              |}
            |}>
          |},
        |},
      |}
    |}>,
  |}
|};
export type allSiteBySuperAdminQuery = {|
  variables: allSiteBySuperAdminQueryVariables,
  response: allSiteBySuperAdminQueryResponse,
|};
*/


/*
query allSiteBySuperAdminQuery(
  $typeOfProject: String
  $search: String
  $order: String
) {
  allSelectJuristic(typeOfProject: $typeOfProject, search: $search, order: $order) {
    totalCount
    edges {
      node {
        id
        site {
          id
          domain
          project {
            edges {
              node {
                id
                nameTh
                nameEn
                typeOfProject
                logo
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfProject"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "typeOfProject",
        "variableName": "typeOfProject"
      }
    ],
    "concreteType": "SelectJuristicNodeConnection",
    "kind": "LinkedField",
    "name": "allSelectJuristic",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SelectJuristicNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SelectJuristicNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteType",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectNodeConnection",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "nameTh",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "nameEn",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "typeOfProject",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "logo",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "allSiteBySuperAdminQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "allSiteBySuperAdminQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "f2380613187f94a6d6a272c0de034191",
    "id": null,
    "metadata": {},
    "name": "allSiteBySuperAdminQuery",
    "operationKind": "query",
    "text": "query allSiteBySuperAdminQuery(\n  $typeOfProject: String\n  $search: String\n  $order: String\n) {\n  allSelectJuristic(typeOfProject: $typeOfProject, search: $search, order: $order) {\n    totalCount\n    edges {\n      node {\n        id\n        site {\n          id\n          domain\n          project {\n            edges {\n              node {\n                id\n                nameTh\n                nameEn\n                typeOfProject\n                logo\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '00f5c77758e018b7d4dc80af2731932f';

module.exports = node;
