import React, { Component, useEffect, useState } from 'react';
import Header from '../components/header/';
import Sidebar from '../components/sidebar/';
import Wrapper from '../components/wrapper/';
import { format } from 'date-fns';
import thLocale from "date-fns/locale/th";
import WrapperContent from '../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../env/environment";
import { fetchQuery } from "relay-runtime";
import Loading from "../libs/loading";
import Lightbox from "react-images";
import './style.scss'



const query = graphql`
  query announceDetailQuery($id: ID!) {
    juristicNews(id: $id) {
      id
      title
      titleEn
      detail
      detailEn
      previewImage
      status
      newsType
      creator
      added
      scheduleTime
      highlight
      group {
        id
        name
        nameEn
      }
      juristicNewsImages {
        edges {
          node {
            id
            fileUrl
            fileUpload
          }
        }
      }
      juristicNewsFile{
          edges{
            node{
              fileName
              fileUpload
              fileUrl
            }
          }
      }
    }
    allProjects {
            edges {
            node {
            periodCreate
        }
      }
    }
  }
`;

 export default function AnnounceDetail(props) {
    const [isMobileView, setisMobileView] = useState(true);
    const [data, setData] = useState([])
    const [isloading, setIsloading] = useState(false);
    const [isOpenLightBox, setOpenLightBox] = useState(false);
    const [imageLightBox, setImageLightBox] = useState('');


    const toggleLightBox = (open_light_box, image = '') =>{
     // this.setState({open_light_box: open_light_box, image_light_box: image})
     setOpenLightBox(open_light_box)
     setImageLightBox(image)
     }

    useEffect(() => {
        const checkForDevice =  window.innerWidth < 640;
        setisMobileView(checkForDevice)
    },[window.innerWidth < 640]);

    useEffect(() => {
        fetchQuery(environment, query,{id:props.match.params.id}).then((res) => {
         if(res.juristicNews){
            setData(res.juristicNews)
            setIsloading(true)
         }
        })
      }, [])

 return (
    !isloading ? <Loading/> :
        <Wrapper>
            {
                !isMobileView ? <> <Header/><Sidebar/></> :''
            }
            <div id="wrapper-content-detail" style={{backgroundColor:'#FAFAFA',overflow:'hidden'}}>
                <div className='container-fluid box'>
                    <div className='card'>
                    <div className="col">
                        <h3 className="mb-4 mt-4">
                            <Link to="/announce-dashboard/news" style={{color:'#000000'}}>
                                <img
                                    src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                    alt="back" className="back" />
                                    {data.title || '-'}
                            </Link>
                        </h3>
                    </div>
                    <div className="general-box p-4" style={{backgroundColor:'#ffffff'}}>
                        <div className="row col-md-6">
                            <img src={data.previewImage} className="card-img-top img-fluid dashboard-detail-img"/>
                        </div>
                        <div className="row col-md-8 mt-4">
                            <div
                            className="content-detail"
                            dangerouslySetInnerHTML={{
                                 __html: data?.detail,
                            }}/>
                        </div>
                        <div className="row col-md-8 mt-4">
                            <div
                            className="content-detail"
                            dangerouslySetInnerHTML={{
                              __html: data?.detailEn,
                            }}/>
                        </div>
                        <div>
                            <p className='pt-2' style={{margin:0}}> 
                            <span className="font-italic mr-2">{format(data.added,"DD/MM/YYYY - HH:mm น.",
                                            { locale: thLocale }) || '-'}</span>    
                            <Translation>{t => <strong className='pr-2'>{t('announceDashboard:by')}</strong>}</Translation>
                             {data.creator || '-'} </p>
                          <p> <Translation>{t => <strong className='pr-2'>{t('announceDashboard:category')}</strong>}</Translation> {data?.group?.name || '-'}</p>
                        </div>
                        <div className="mt-6">
                        <Translation>{t => <h4>{t('announceDashboard:image')}</h4>}</Translation>
                          {
                            data.juristicNewsImages?.edges.map(
                              (docs, index) => {  
                                return (
                                  <>
                                    <img src={docs.node.fileUrl} alt="feedback-img" className='img-fluid'
                                    onClick={() => toggleLightBox(true,docs.node.fileUrl)}
                                    style={{ width:'250px',margin:'5px 5px',cursor: "pointer"}}/>
                                  </>
                                )
                            })
                          }
                          <Lightbox
                            images={[{ src:imageLightBox }]}
                            onClose={() => toggleLightBox(false)}
                            isOpen={isOpenLightBox}
                            backdropClosesModal={true}
                            showImageCount={false}
                          />
                        </div>
                        <div className="mt-6">
                        <Translation>{t => <h4>{t('announceDashboard:file')}</h4>}</Translation>
                            {
                              data.juristicNewsFile?.edges?.map((files, inx) => {                                
                                let file = files?.node ? files.node : files
                                let checkLastNameFile = file?.fileName?.includes(".") ? "fileName" : "fileUpload"
                                let typeFile =file[checkLastNameFile]?.includes(".pdf") ? "pdf" : file[checkLastNameFile]?.includes(".jpg") || file[checkLastNameFile]?.includes(".jepg") ? "jpg" : "png";                                
                                
                                
                                return (

                                  <p key={inx}>
                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                          <a href={file?.fileUrl} target="_blank"> {file?.fileName ? file?.fileName : file?.node?.fileName}</a>
                                  </p>

                                )
                              })
                            }
                        </div>
                        
                    </div>
                    </div>
                    
                </div>
                
            </div>
        </Wrapper>
 );
    
}