import React, { Component } from 'react';
import Header from '../../../../components/header/index';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import BackButtonIcon from "../../../../components/BackBtn/indexBack"; 
import i18next from "i18next";
import { Translation } from 'react-i18next';
import VMSLogsTable from './vmsLogsTable'
import SeacrhDetails from '../../../../components/search/indexSearch';
import API from '../../../../api/silverGraund'
import { format } from "date-fns";
import Swal from "sweetalert2";
import './logsvms.scss'


class VMSLogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
            startDate: null,
            endDate: null,
            searchUserId: "",
            loading: true,
            loadingPage: true,
            indexFirst: 0,
            indexLast: 10,
            countData: 0,
            pageNow: 0,
            page_get_api_now: 1,
            pageAll: 1,

            AllUser: null,
            logData: null,

            token_log: null,
            domain: '',
            data:[]
        }

        this.handlePropsChange = this.handlePropsChange.bind(this)
        this.fetchData = this.fetchData.bind(this)
    }

      fetchData = async () => {
        try {
          const res = await API.getcarVms();
          if (res.data.data) {
            let datas = []
            datas = res.data.data
            this.setState({ 
                data: datas,
                loading:false
            })
        }
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error if necessary
        }
      };

    componentDidMount() {
        this.fetchData();
    }

    handlePropsChange(channel_list) {
        let search = ''
        let startDate = ''
        let endDate = ''
        let typePerson = ""
        let objectCar = ""
    
        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            } else if (e.name === "start_date") {
                startDate = format(e.value, "YYYY-MM-DD")
            } else if (e.name === "end_date") {
                endDate = format(e.value, "YYYY-MM-DD")
            } 
        });
        this.setState({
            search: search,
            startDate: startDate === '1970-01-01' ? null : startDate,
            endDate: endDate === '1970-01-01' ? null : endDate,
            loading: true
        }, () => {

            let start_date = startDate === '1970-01-01' ? null : startDate
            let end_date = endDate === '1970-01-01' ? null : endDate

            API.getSearchVisitmange(search, start_date, end_date, typePerson, objectCar).then((res) => {
                if (res.data.data) {
                    let datas = []
                    datas = res.data.data
                    this.setState({ data: datas,loading:false })
                }
            }, () => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error'))
        })
    }
    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="logs-table" >
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={"vms logs"}
                                />
                                <div className='filterSearch' style={{ zoom: '90%' }}>
                                    <SeacrhDetails
                                        selectAndSearchIs={false}
                                        startDateIs={true}
                                        endDateIs={true}
                                        searchBoxId={true}
                                        // searchBoxText={i18next.t("cheque:ค้นหา")}
                                        search={this.state.search}
                                        start_date={this.state.startDate}
                                        end_date={this.state.endDate}
                                        handlePropsChange={this.handlePropsChange}
                                    />
                                </div>
                                <div className="content-inner">
                                    <VMSLogsTable data={this.state.data} loading={this.state.loading}/>
                                </div>
                            </div>
                        }
                    </Translation>
                </div>

            </Wrapper>

        )
    }
}

export default VMSLogs;