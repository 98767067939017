/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingRoleQueryVariables = {|
  siteId?: ?string
|};
export type settingRoleQueryResponse = {|
  +getSiteButtonNode: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +key: {|
          +keyName: ?string
        |},
        +show: boolean,
      |}
    |}>
  |},
  +getAllSite: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +getAllKey: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +keyName: ?string,
        +description: ?string,
      |}
    |}>
  |},
|};
export type settingRoleQuery = {|
  variables: settingRoleQueryVariables,
  response: settingRoleQueryResponse,
|};
*/


/*
query settingRoleQuery(
  $siteId: String
) {
  getSiteButtonNode(siteId: $siteId) {
    edges {
      node {
        key {
          keyName
          id
        }
        show
        id
      }
    }
  }
  getAllSite {
    edges {
      node {
        id
        name
      }
    }
  }
  getAllKey {
    edges {
      node {
        id
        keyName
        description
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "siteId",
    "variableName": "siteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteNodeConnection",
  "kind": "LinkedField",
  "name": "getAllSite",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ButtonKeyNodeConnection",
  "kind": "LinkedField",
  "name": "getAllKey",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ButtonKeyNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ButtonKeyNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SiteButtonKeyNodeConnection",
        "kind": "LinkedField",
        "name": "getSiteButtonNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteButtonKeyNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteButtonKeyNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ButtonKeyNode",
                    "kind": "LinkedField",
                    "name": "key",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SiteButtonKeyNodeConnection",
        "kind": "LinkedField",
        "name": "getSiteButtonNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteButtonKeyNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteButtonKeyNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ButtonKeyNode",
                    "kind": "LinkedField",
                    "name": "key",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "aceefbd6fa84d56e76ca9f5e0de576a1",
    "id": null,
    "metadata": {},
    "name": "settingRoleQuery",
    "operationKind": "query",
    "text": "query settingRoleQuery(\n  $siteId: String\n) {\n  getSiteButtonNode(siteId: $siteId) {\n    edges {\n      node {\n        key {\n          keyName\n          id\n        }\n        show\n        id\n      }\n    }\n  }\n  getAllSite {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  getAllKey {\n    edges {\n      node {\n        id\n        keyName\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17b0942fa07b7df3ffc6f4ed38404afe';

module.exports = node;
