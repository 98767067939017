/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAndUpdateBoardTenantInput = {|
  tenantId?: ?string,
  residentialId?: ?string,
  boardId?: ?string,
  image?: ?string,
  signature?: ?string,
  position?: ?string,
  authorization?: ?boolean,
  financial?: ?number,
  status?: ?string,
  education?: ?any,
  workExperience?: ?any,
  filepdf?: ?any,
  boardTerm?: ?any,
  clientMutationId?: ?string,
|};
export type createAndUpdateBoardTenantMutationVariables = {|
  input: CreateAndUpdateBoardTenantInput
|};
export type createAndUpdateBoardTenantMutationResponse = {|
  +createAndUpdateBoardTenant: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type createAndUpdateBoardTenantMutation = {|
  variables: createAndUpdateBoardTenantMutationVariables,
  response: createAndUpdateBoardTenantMutationResponse,
|};
*/


/*
mutation createAndUpdateBoardTenantMutation(
  $input: CreateAndUpdateBoardTenantInput!
) {
  createAndUpdateBoardTenant(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAndUpdateBoardTenantPayload",
    "kind": "LinkedField",
    "name": "createAndUpdateBoardTenant",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAndUpdateBoardTenantMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAndUpdateBoardTenantMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d6a71369fdee63772132ed2218f2d640",
    "id": null,
    "metadata": {},
    "name": "createAndUpdateBoardTenantMutation",
    "operationKind": "mutation",
    "text": "mutation createAndUpdateBoardTenantMutation(\n  $input: CreateAndUpdateBoardTenantInput!\n) {\n  createAndUpdateBoardTenant(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '51244aef65bed04795d22e4c3e8fb59a';

module.exports = node;
