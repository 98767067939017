import React, { Component } from 'react';
import '../styles/main.scss';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import $ from "jquery";
import { Helmet } from "react-helmet";

const _ = require('lodash');

const surveyQuery = graphql`
    query surveyQuery($id: ID!, $residential_vote: ID) {
        survey(id: $id){
            id
            title
            detail
            status
            residentialVoteSurvey(id: $residential_vote){
                edges{
                    node{
                        residential{
                            id
                            name
                            size
                            residentialHouseholder{
                                edges{
                                    node{
                                        type
                                        id
                                        tenant{
                                            firstName
                                            lastName
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            surveyQuestion{
                edges{
                    node{
                        id
                        title
                        type
                        questionChoice{
                            edges{
                                node{
                                    id
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
        residentialSizeAll{
            sizeAll
        }
    }
`;

class Survey extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            detail: '',
            status: '',
            survey_question_answer: [],
            summary_vote: [],
            all_vote: [],
            disable_edit_vote: true,
            loading: false,
            size_all: false,
            residentialVoteSurvey: [],
        };
    }

    componentDidMount() {

        this.init();
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + signature);
            $('tbody tr:last-child td').css({ paddingBottom: diff });
        }, 200);
    }

    init() {
        if (this.props.survey_id) {
            fetchQuery(environment, surveyQuery, { id: this.props.survey_id, residential_vote: this.props.residential_vote_id }).then((data) => {
                if (_.isObject(data.survey)) {
                    let survey_question = [];
                    data.survey.surveyQuestion.edges.forEach((question) => {
                        let question_new = _.cloneDeep(question.node);
                        survey_question = [...survey_question, question_new];
                    });
                    this.setState({
                        title: data.survey.title,
                        detail: data.survey.detail,
                        status: data.survey.status,
                        residentialVoteSurvey: data.survey.residentialVoteSurvey.edges,
                        survey_question_answer: survey_question,
                        size_all: data.residentialSizeAll.sizeAll,
                    });
                }

                if (this.props.type === "result") {
                    this.residentialVoteInit();
                }
            });
        }
    }

    residentialVoteInit() {
        fetchQuery(environment, this.props.query, {
            id: this.props.survey_id,
            residential_vote_id: this.props.residential_vote_id
        }).then((data) => {
            if (data.allVote.edges.length > 0) {
                let all_vote = [];
                data.allVote.edges.forEach((vote) => {
                    let vote_new = _.cloneDeep(vote.node);
                    all_vote = [...all_vote, vote_new];
                });
                this.setState({
                    all_vote: all_vote,
                });
            }
        });
    }

    print() {
        window.print();
    }

    render() {
        let count_recorde = 0
        let keep_next = {};
        let name_rundom = Math.random().toString(36).substring(7);
        let keyname = Math.random().toString(36).substring(7);
        let pang_next = 0
        let pang_keep = 0
        let under_pang = [];
        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Survey</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>


                <div className="print-page-a4" key={keyname}>
                    <div className="subpage">
                        <div className="head">
                            <h4 className="overflow-wrap" style={{ border: '1px solid', padding: "10px" }}>แบบสอบถาม
                                : {this.state.title}</h4>
                            <div><span className="title-into">ชื่อเจ้าของกรรมสิทธิ์/Name
                                House-Owner :</span>{this.props.residential_vote_id ? this.state.residentialVoteSurvey.map((residential_vote) =>
                                residential_vote.node.residential.residentialHouseholder.edges.map((house_holder) =>
                                    house_holder.node.type === "OWNER" &&
                                    ' ' + house_holder.node.tenant.firstName + ' ' + house_holder.node.tenant.lastName
                                )
                            ) : '.....................................................................................................................................'}
                            </div>
                            <div><span className="title-into">
                                บ้านเลขที่/Unit(House)
                                No. </span>{this.props.residential_vote_id ? this.state.residentialVoteSurvey.map((residential_vote) =>
                                residential_vote.node.residential.name
                            ) : '........................'}
                                <span className="title-into">อัตราส่วนค่าใช้จ่าย/คะแนนเสียง </span>{this.props.size ? (parseFloat(this.props.size) / this.state.size_all).toFixed(2) : "................................................................................"}
                            </div>
                            <div>
                                <span className="title-into">จํานวนอัตราส่วนกรรมสิทธิ์/Right to common assets</span> {(this.props.type === "result" && this.props.size) ? parseFloat(this.props.size).toFixed(2) : "....................................................................................................................."}
                            </div>

                            <div><span className="title-into">รายละเอียด/description</span><div> </div>
                                <span className="title-description">{this.state.detail.replaceAll(/<[^>]+>/g, '').replaceAll('&nbsp;', ' ')}</span></div>
                        </div>

                        <div className="detail">
                            <div className="customer-detail-vote">
                                {this.state.survey_question_answer.map((question, index_question) => {
                                    count_recorde = count_recorde + 1 + question.questionChoice.edges.length;
                                    if (count_recorde < 40) {
                                        return (
                                            <React.Fragment key={question.id}>
                                                <div style={{ marginTop: "2px",fontWeight: 'bold',fontSize: '13px' }}>
                                                        {index_question + 1}{". " + question.title}
                                                </div>

                                                {question.questionChoice.edges.map((questionChoice, index_choice) =>
                                                    <div style={{ marginTop: "1px" }} key={questionChoice.node.id}>
                                                        {this.state.all_vote.length > 0 ?
                                                            <React.Fragment>
                                                                <input
                                                                    type={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "radio" : "checkbox"}
                                                                    checked={this.state.all_vote.some(x => x.choice.id === questionChoice.node.id) ? true : false}
                                                                    disabled={this.state.disable_edit_vote}
                                                                    id={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}
                                                                />
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <input
                                                                    type={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "radio" : "checkbox"}
                                                                    checked={false}
                                                                    disabled={this.state.disable_edit_vote}
                                                                    id={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                        <span style={{ marginLeft: "2%" }}>{questionChoice.node.title}</span>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )
                                    } else {
                                        if (Object.keys(keep_next).length === 0) {
                                            keep_next['key' + name_rundom] = [];
                                            keep_next['key' + name_rundom].push(question)
                                        }
                                        else {
                                            if (keep_next['key' + name_rundom].length < 10)
                                                keep_next['key' + name_rundom].push(question)
                                            else {
                                                name_rundom = Math.random().toString(36).substring(7);
                                                keep_next['key' + name_rundom] = [];
                                                keep_next['key' + name_rundom].push(question)
                                            }
                                        }
                                        if (pang_next === 0) pang_next = index_question;
                                        return null
                                    }
                                }
                                )}
                            </div>
                        </div>

                        {Object.keys(keep_next).length === 0 &&
                            <div className="footer">
                                <div className="d-flex justify-content-end">
                                    <div className="right mt-3 text-center">
                                        .................................................................<br />
                                                        ลงชื่อ/Signed
                                                    </div>
                                </div>
                            </div>}

                    </div>
                </div>
                {/* pang > dynamic  */}
                {Object.keys(keep_next).length !== 0 && Object.entries(keep_next).map(([index, result], index_stack) => {
                    let show_array = []
                    if (pang_keep !== 0) pang_next = pang_keep;

                    if (under_pang.length !== 0) {
                        under_pang.forEach((node, index) => {
                            show_array.push(node)
                        })
                        result.forEach((node, index) => {
                            show_array.push(node)
                        })
                    } else {
                        result.forEach((node, index) => {
                            show_array.push(node)
                        })
                    }
                    count_recorde = 0
                    pang_keep = 0
                    under_pang = [];
                    keyname = Math.random().toString(36).substring(7);
                    return (
                        <div className="print-page-a4" key={keyname}>
                            <div className="subpage">
                                <div className="detail">
                                    <div className="customer-detail-vote">
                                        {show_array.map((question, index_question) => {
                                            count_recorde = count_recorde + 1 + question.questionChoice.edges.length;
                                            if (count_recorde < 45) {
                                                pang_keep = index_question + 1 + pang_next;
                                                return (
                                                    <React.Fragment key={question.id}>
                                                        <div style={{ marginTop: "2px",fontWeight: 'bold',fontSize: '13px' }}>
                                                                {index_question + 1 + pang_next}{". " + question.title}
                                                        </div>

                                                        {question.questionChoice.edges.map((questionChoice, index_choice) =>
                                                            <div style={{ marginTop: "1px" }} key={questionChoice.node.id}>
                                                                {this.state.all_vote.length > 0 ?
                                                                    <React.Fragment>
                                                                        <input
                                                                            type={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "radio" : "checkbox"}
                                                                            checked={this.state.all_vote.some(x => x.choice.id === questionChoice.node.id) ? true : false}
                                                                            disabled={this.state.disable_edit_vote}
                                                                            id={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}
                                                                        />
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <input
                                                                            type={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "radio" : "checkbox"}
                                                                            checked={false}
                                                                            disabled={this.state.disable_edit_vote}
                                                                            id={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}
                                                                        />
                                                                    </React.Fragment>
                                                                }
                                                                <span style={{ marginLeft: "2%" }}>{questionChoice.node.title}</span>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )
                                            } else {
                                                under_pang.push(question)
                                                return null;
                                            }
                                        })
                                        }

                                    </div>
                                </div>

                                {(Object.keys(keep_next).length - 1) === index_stack &&
                                    <div className="footer">
                                        <div className="d-flex justify-content-end">
                                            <div className="right mt-3 text-center">
                                                .................................................................<br />
                                                        ลงชื่อ/Signed
                                                    </div>
                                        </div>
                                    </div>}


                            </div>
                        </div>)
                })
                }
            </React.Fragment>

        );
    }
}

export default Survey;
