import React from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import ComponentPagination from "../../../../libs/componentPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import HistoryTable from "./historyTable";
import HistorySummary from "./historySummary";
import ServerExport from "./serverExport";
import { Redirect } from "react-router";
import getNameResidential from "../../../../libs/getNameResidential";
import _ from "lodash";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import { withRouter } from 'react-router';
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import './historyReportPDF.scss'
import i18next from "i18next";

const query = graphql`
  query historyQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $newEndDate: Date
    $search: String
    $contactId: ID
    $id: ID!
  ) {
    contact(id: $id) {
      id
      refNumber
      name
      firstName
      lastName
      vat

      registeredAddress
      registeredCountry
      registeredProvince
      registeredDistrict
      registeredCity
      registeredPostalCode
      typeOfContact
      typeOfSupplier
    }
    invoiceViewer {
      allInvoice(
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        contact_Id: $contactId
        order: "doc_number"
        suborder: "issued_date"
        status_In: "active, overdue, paid, partial_payment"
      ) {
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            payGroup
            contact {
              id
              name
              firstName
              lastName
              refNumber
              typeOfContact
            }

            transaction {
              edges {
                node {
                  id
                  description
                  total
                  creditNoteTransaction(issuedDate_Lte: $newEndDate , status: "paid") {
                    edges {
                      node {
                        id
                        docNumber
                        issuedDate
                        price
                        status
                        receive{
                          id
                        }
                      }
                    }
                  }

                  
                  ##Cn V2
                  creditNoteTransactionRecord(creditNote_IssuedDate_Lte: $newEndDate , status: "paid"){
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              status
                          }
                      }
                  }
                  
                  receiptDepositTransactionUsed(receiptDeposit_IssuedDate_Lte: $newEndDate) {
                    edges {
                      node {
                        id
                        amount
                        coupleUse
                        receiptDeposit {
                          id
                          docNumber
                          issuedDate
                          total
                        }
                        receiptDepositTransaction {
                          couple
                        }
                      }
                    }
                  }

                  receiveTransaction(
                    receive_Status_In: "paid, partial_payment",
                    receive_IssuedDate_Lte: $newEndDate
                  ) {
                    edges {
                      node {
                        id
                        amount
                        receiptDepositTransactionUsed {
                          edges {
                            node {
                              id
                              amount
                            }
                          }
                        }
                        receive {
                          id
                          docNumber
                          issuedDate
                          status
                          total
                          creditNoteReceive {
                            edges {
                              node {
                                id
                                docNumber
                                price
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

class History extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.search = "";
    this.state.search_input = "";
    this.state.customer_type = "";
    this.state.contact = props.customerList;
    this.state.checkFetchState = false;
    this.state.dataResult = "";
    this.state.loading = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchDataQuery = this.fetchDataQuery.bind(this);
    this.checkLoadingExcel = this.checkLoadingExcel.bind(this);

  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "historyQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

    this.fetchDataQuery();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    },
      () => {
        this.fetchDataQuery()
      });
  }

  onClickGetData(data) {
    this.props.history.push(`/accounting/report/account-receivable/history/list/PDF`, {
      data: data,
      contactId: this.props.customerList,
      id: this.props.customerList[0],
      typePrint: "selectedCustomer",
    })
  }

  fetchDataQuery() {
    this.setState({
      checkFetchState: false,
      loading: true,
    })
    fetchQuery(environment, query, {
      // first: this.state.first,
      // last: this.state.last,
      startDate: this.state.start_date,
      endDate: this.state.end_date,
      newEndDate: this.state.end_date.toISOString().slice(0, 10),
      search: this.state.search,
      contactId: this.props.customerList,
      id: this.props.customerList[0],
    }).then((item) => {
      this.setState({ dataResult: item, checkFetchState: true, loading: false });
    }).catch(error => {
    //   Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    });;
  }

  checkLoadingExcel(status) {
    this.setState({ checkFetchState: status });
  }

  render() {
    let payGroup = this.state.dataResult.invoiceViewer?.allInvoice?.edges[0]?.node?.payGroup;

    if (this.props.customerList.length > 0) {
      return (
        <div className="container-fluid box">
          <div className="row justify-content-between historyReport">
            <div className="col">
              <h3 className="mb-4">
                <Link to="/accounting/report/account-receivable/history/select-customer">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                    }
                    alt="back"
                    className="back"
                  />
                </Link>
                <Translation>
                  {(t) => <span>{i18next.t("receivable_dashboard:Accounts Receivable Movement Report")}</span>}
                </Translation>
              </h3>
            </div>
            {/* <div className="col">
              {
                _.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) && <ServerExport state={this.state} />
              }
              {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                codename: "report_receivable_print",
              }) && <ServerExportAll state={this.state} className={"ml-2 mr-2"} />
              } 
            </div> */}
            <div className={`col`}>
              {
                _.some(JSON.parse(localStorage.getItem("permission_list")),
                  {
                    codename: "report_receivable_print",
                  }) &&
                <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                  {!this.state.checkFetchState ?
                    <React.Fragment>
                      <button type="button" className="btnPrint">
                        <span className="spinner-border spinner-border-sm align-middle mr-2" />
                        <Translation>{t => t("meter:Preparing information")}</Translation>
                      </button>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                        <Translation>{t => t("PageList:Print")}</Translation>
                      </button>
                    </React.Fragment>
                  }
                  <div className={`dropdown-menu`}>
                    <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                    <ServerExport state={this.state} checkLoadingExcel={this.checkLoadingExcel} />
                  </div>
                </div>
              }
            </div>
          </div>

          <Translation>
            {t =>
              <div className="content-inner">
                <div className="row justify-content-end mb-2">
                  <div className="col">
                    <div className="input-group float-right w-auto ml-2">
                      <input
                        type="text"
                        className="form-control search-input input-size"
                        placeholder={i18next.t("AllFilter:Search")}
                        name="search"
                        value={this.state.search_input}
                        onChange={(e) =>
                          this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleSearch(e)}
                      >
                        {i18next.t("AllFilter:Search")}
                      </button>
                    </div>
                    <div className="float-right ml-2">
                      <DatePickerAdapter
                        name="temp_end_date"
                        className="form-control"
                        selected={this.state.temp_end_date}
                        // maxDate={this.state.current_date}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>

                    <div className="float-right ml-2">
                      <DatePickerAdapter
                        name="temp_start_date"
                        className="form-control"
                        selected={this.state.temp_start_date}
                        // minDate={this.state.min_date}
                        // maxDate={this.state.end_date}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                {this.state.loading ?
                  <Loading />
                  :
                  this.state.dataResult &&
                  <React.Fragment>
                    <div className="row mt-3">
                      <div className="col-12">
                        <div className="card fade-up">
                          <div className="col">
                            <p>
                              <strong>{i18next.t("historyReport:Customer")}</strong>
                            </p>
                            <p>
                              {this.state.dataResult.contact
                                ? this.state.dataResult.contact.typeOfContact ===
                                  "RESIDENTIAL"
                                  ? this.state.dataResult.contact.refNumber +
                                  " " +
                                  this.state.dataResult.contact.name +
                                  " " +
                                  getNameResidential(
                                    this.state.dataResult.contact.firstName,
                                    this.state.dataResult.contact.lastName
                                  )
                                  : this.state.dataResult.contact.refNumber +
                                  " " +
                                  this.state.dataResult.contact.name
                                : "-"}
                                 {" "}                                            
                                {
                                (payGroup === "RENT" || payGroup === "AGENT" || payGroup === "OWNER" || payGroup === "DEVELOPER") &&
                                <label className="text-danger">
                                ({payGroup === "RENT" || payGroup === "AGENT" || payGroup === "OWNER" || payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${payGroup}`) : ""})
                                </label>
                                } 
                            </p>
                          </div>
                        </div>
                        <div className="card fade-up">
                          <div className="table-responsive">
                            <table className="table table-hover table-bordered">
                              <thead className="thead-light">
                                <tr>
                                  <th className="text-center">{i18next.t("historyReport:Sequence")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Invoice Date")}</th>
                                  <th className="text-center" width={120}>{i18next.t("historyReport:Invoice Number")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Description")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Amount")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Receipt Date")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Receipt Number")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Paid Amount")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Credit Note Date")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Credit Note")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Credit Note Amount")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Advanced Payment Date")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Advanced Payment")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Advanced Payment Amount")}</th>
                                  <th className="text-center">{i18next.t("historyReport:Remaining Balance")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <HistoryTable
                                  query={this.state.dataResult}
                                  state={this.state}
                                />
                              </tbody>
                              <tfoot>
                                <HistorySummary
                                  query={this.state.dataResult}
                                  state={this.state}
                                />
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                }
              </div>
            }
          </Translation>
        </div>
      );
    }
    return (
      <Redirect to="/accounting/report/account-receivable/history/select-customer" />
    );
  }
}

export default withRouter(History);
