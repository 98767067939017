import React, {Component} from 'react';
import Header from '../../components/header/';
import Sidebar from '../../components/sidebar/';
import Wrapper from '../../components/wrapper/';
import WrapperContent from '../../components/wrapper/wrapperContent';
import ContactTopMenuNavigation from '../contactTopMenuNavigation';
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import queryCheckSiteOnline from "../../libs/checkSiteOnline"
import Loading from "../../libs/loading"
import AlertWithholdPage from '../../libs/alertWithholdPage';
// import {Link} from "react-router-dom";
// import {Translation} from "react-i18next";
import "../styles/index.scss"

class Index extends Component {

    constructor(props){
        super(props)

        this.state = {
            loading: false,
            checkOnline: true
        }
    }

    componentWillMount(){
        this.setState({loading: true})
        fetchQuery(environment, queryCheckSiteOnline).then(data => {
            this.setState({checkOnline: data.checkOnline, loading: false})
        });
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    {this.state.loading ? <Loading/>
                        : !this.state.checkOnline ? <AlertWithholdPage/>
                            : <ContactTopMenuNavigation center={true}/>
                    }
                    {/*<div className="container-fluid box" id="contact-dashboard">*/}
                    {/*    <div className="content-inner">*/}
                    {/*        <div className="row justify-content-between">*/}
                    {/*            <div className="col">*/}
                    {/*                <h3 className="mb-4">*/}
                    {/*                    <Translation>*/}
                    {/*                        {*/}
                    {/*                            t => <span>{t('contact_menu:contact_dashboard')}</span>*/}
                    {/*                        }*/}
                    {/*                    </Translation>*/}
                    {/*                    <Link to=""><span className="color size"> ดูสรุปย้อนหลังทั้งหมด</span></Link>*/}
                    {/*                </h3>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="row">*/}
                    {/*            <div className="col">*/}
                    {/*                <div className="card-wrapper">*/}
                    {/*                    <div className="card-body pt-4">*/}
                    {/*                        <div className="row text-center">*/}
                    {/*                            <div className="col">*/}
                    {/*                                <p>ข่าวประชาสัมพันธ์ในเดือนนี้</p>*/}
                    {/*                                <h3>17</h3>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col">*/}
                    {/*                                <p>จดหมาย/พัสดุในเดือนนี้</p>*/}
                    {/*                                <h3>275</h3>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col">*/}
                    {/*                                <p>เรื่องร้องเรียนเดือนนี้</p>*/}
                    {/*                                <h3>39</h3>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col">*/}
                    {/*                                <p>งานซ่อมเดือนนี้</p>*/}
                    {/*                                <h3>8</h3>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-4 mt-4">*/}
                    {/*                <img src={process.env.PUBLIC_URL + '/images/icons/complaint-icon.png'}*/}
                    {/*                     alt="complain-icon"/>*/}
                    {/*                <span className="ml-3">*/}
                    {/*                    <strong>รายการร้องเรียนที่เข้ามาล่าสุด</strong>*/}
                    {/*                </span>*/}
                    {/*                <div className="card-wrapper mt-4 p-2">*/}
                    {/*                    <div className="card mb-1">*/}
                    {/*                        <div className="card-body">*/}
                    {/*                            <div className="row">*/}
                    {/*                                <div className="col-md-2">*/}
                    {/*                                    <img*/}
                    {/*                                        src="http://lorempixel.com/output/people-q-c-60-60-1.jpg"*/}
                    {/*                                        alt="owner-profile"*/}
                    {/*                                        className="rounded-circle avatar-img profile-img contact-dashboard"/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md-7">*/}
                    {/*                                    <p>สมโภชน์ โภชนา</p>*/}
                    {/*                                    <p className="small">*/}
                    {/*                                        ห้อง <Link to="">1107</Link>*/}
                    {/*                                    </p>*/}
                    {/*                                    <p className="small color gray-color">สันทนาการฮอตแฮปปี้สไตรค์*/}
                    {/*                                        กาญจนาภิเษกว้อย...</p>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md-3 text-right">*/}
                    {/*                                    <p className="color blue-color">17:32 น.</p>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="col-md-4 mt-4">*/}
                    {/*                <img src={process.env.PUBLIC_URL + '/images/icons/complaint-icon.png'}*/}
                    {/*                     alt="complain-icon"/>*/}
                    {/*                <span className="ml-3">*/}
                    {/*                    <strong>รายการร้องเรียนที่เข้ามาล่าสุด</strong>*/}
                    {/*                </span>*/}
                    {/*                <div className="card-wrapper mt-4 p-2">*/}
                    {/*                    <div className="card mb-1">*/}
                    {/*                        <div className="card-body">*/}
                    {/*                            <div className="row">*/}
                    {/*                                <div className="col-md-2">*/}
                    {/*                                    <img*/}
                    {/*                                        src="http://lorempixel.com/output/people-q-c-60-60-1.jpg"*/}
                    {/*                                        alt="owner-profile"*/}
                    {/*                                        className="rounded-circle avatar-img profile-img contact-dashboard"/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md-7">*/}
                    {/*                                    <p>สมโภชน์ โภชนา</p>*/}
                    {/*                                    <p className="small">*/}
                    {/*                                        ห้อง <Link to="">1107</Link>*/}
                    {/*                                    </p>*/}
                    {/*                                    <p className="small color gray-color">สันทนาการฮอตแฮปปี้สไตรค์*/}
                    {/*                                        กาญจนาภิเษกว้อย...</p>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="col-md-3 text-right">*/}
                    {/*                                    <p className="color blue-color">17:32 น.</p>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}

                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="col-md-4 mt-4">*/}
                    {/*                <img src={process.env.PUBLIC_URL + '/images/icons/calendar-fix-icon.png'}*/}
                    {/*                     alt="calendar-fix-icon"/>*/}
                    {/*                <span className="ml-3">*/}
                    {/*                    <strong>ตารางงานซ่อมแซมที่กำลังจะถึง</strong>*/}
                    {/*                </span>*/}
                    {/*                <div className="card-wrapper mt-4 p-2">*/}
                    {/*                    <h6 className="p-2">15 มกราคม 2562</h6>*/}
                    {/*                    <div className="card mb-2">*/}
                    {/*                        <div className="card-body">*/}
                    {/*                            <div className="row">*/}
                    {/*                                <div className="col-md-12">*/}
                    {/*                                    <p className="highlight orange">17:32น.</p>*/}
                    {/*                                    <p className="mt-3">ตรวจสอบท่อน้ำทิ้ง</p>*/}
                    {/*                                    <div className="row">*/}
                    {/*                                        <div className="col-md-6 col-6">*/}
                    {/*                                            <span className="small">ห้อง 1107</span>*/}
                    {/*                                        </div>*/}
                    {/*                                        <div className="col-md-6 col-6 text-right">*/}
                    {/*                                            <span className="size color blue-color">ช่างเก่า</span>*/}
                    {/*                                        </div>*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}

                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default Index;
