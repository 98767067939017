import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import ListGroupSetting from "../listGroupSetting";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, commitMutation } from "relay-runtime";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import { format } from "date-fns";
import "./settingAccountForm.scss"
import _ from "lodash";
import i18n from "i18next";
import jwtDecode from "jwt-decode";

const query = graphql`
    query settingAccountFormQuery($id:ID!) {

        allAccountProjectManager(id:$id){
            edges{
              node{
                id
                startSite
                startAccount
                startDateAccountPeriod
                endDateAccountPeriod
              }
            }
        }

    }`;

const mutation = graphql`
    mutation settingAccountFormMutation($input: CreateAccountProjectManagerInput!) {
        createAccountProjectManager(input: $input) {
            ok
        }
    }
`;

class SettingAccountForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirectToList: false,
            //วันเริ่มใช้งานระบบ
            start_date: null,
            // month_close_statement: "1",
            // lock_date: new Date(),
            //วันเริ่มใช้งานระบบบัญชี
            start_date_account: "",
            //เริ่มงวด
            start_date_account_period: "",
            //สิ้นงวด
            end_date_account_period: "",
            user_super_admin: false,

            start_date_accountNew: false,
            start_date_account_periodNew: false,
            end_date_account_periodNew: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillMount() {
        this.queryChartOfAccount();
        let token = window.localStorage.getItem('token');
        let token_decode = jwtDecode(token)
        this.setState({ user_super_admin: token_decode.user_super_admin })
    }

    queryChartOfAccount() {
        fetchQuery(environment, query, {
            id: 1,
        }).then(data => {
            let data_clone = _.cloneDeep(data);
            let start_date = null
            let start_date_account = ""
            let start_date_account_period = ""
            let end_date_account_period = ""
            if(data_clone?.allAccountProjectManager?.edges.length > 0){
                data_clone.allAccountProjectManager.edges.forEach((allAccountProjectManager) => {

                    if(allAccountProjectManager.node.startSite !== "1970-01-01"){
                        start_date = new Date(allAccountProjectManager.node.startSite)
                    }
                        
                    // เมื่อเปิดไซต์ใหม่ครั้งแรกต้องเป็นค่าว่างเสมอ
                    // start_date_account ถ้าเลือกวันที่และบันทึกไปแล้วจะต้องกลับมาแก้ไขไม่ได้
                    
                    start_date_account = allAccountProjectManager.node.startAccount ? new Date(allAccountProjectManager.node.startAccount) : ""
                    // start_date_account_period , end_date_account_period ถ้าบันทึกแล้วต้องกลับมาแก้ไขได้
                    
                    start_date_account_period = allAccountProjectManager.node.startDateAccountPeriod ? new Date(allAccountProjectManager.node.startDateAccountPeriod) : ""
                    end_date_account_period = allAccountProjectManager.node.endDateAccountPeriod ? new Date(allAccountProjectManager.node.endDateAccountPeriod) : ""
                });
            }
            this.setState({
                start_date: start_date,
                start_date_account: start_date_account,
                start_date_account_period: start_date_account_period,
                end_date_account_period: end_date_account_period,
                start_date_accountNew: !start_date_account ? false : true,
            });
        })
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit() {
        this.setState( { loading: true } );

        let variables = {
            input: {
                startSite: format(this.state.start_date, 'YYYY-MM-DD'),
                startAccount: format(this.state.start_date_account, 'YYYY-MM-DD'),
                startDateAccountPeriod: format(this.state.start_date_account_period, 'YYYY-MM-DD'),
                endDateAccountPeriod: format(this.state.end_date_account_period, 'YYYY-MM-DD'),
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                // uploadables,
                onCompleted: (response) => {
                    if (response.createAccountProjectManager.ok) {
                        Swal.fire(i18n.t('settingAccount:Save successfully'), '', 'success').then(() => {
                            this.setState({ redirectToList: true, loading: false });
                            this.queryChartOfAccount()
                        })
                    } else {
                        Swal.fire(i18n.t('settingAccount:Failed to save'), '', 'warning');
                        this.setState({ loading: false });

                    }
                },
                onError: (err) => {
                    Swal.fire('Error!', i18n.t('settingAccount:Please try again'), 'warning')
                    this.setState({ loading: false });
                },
            },
        );
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box" style={{height: '90vh'}}>
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('settingAccount:Account setting')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <Translation>
                            {t =>
                                <div className="content-inner">
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <ListGroupSetting page={'account_setting'} type={'ACCOUNT'} />
                                        </div>
                                        <div className="col-8">
                                            <div className="row mt-4 ml-4 mr-3 mb-3" style={{ alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <h4>{t('settingAccount:System start date')}</h4>
                                                    <p>{this.state.start_date ? format(this.state.start_date, 'DD/MM/YYYY') : "-"}</p>
                                                </div>

                                            </div>
                                            {/* <div className="card fade-up" style ={{display : 'flex' }}> */}
                                            {/* <div className="mt-4 ml-4 mr-3 mb-3" style={{ alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <h6>{t('settingAccount:System start date')}</h6>
                                                </div>
                                                <div className="col-3">
                                                    <DatePickerAdapter id="start_date"
                                                        className="form-control" name="start_date"
                                                        selected={this.state.start_date}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="mt-3 ml-4 mr-3 mt-3 mb-3" style={{ alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <h6>{t('settingAccount:Account system start date')}</h6>
                                                </div>
                                                <div className="col-3">
                                                    <DatePickerAdapter id="start_date_account"
                                                        className="form-control" name="start_date_account"
                                                        selected={this.state.start_date_account}
                                                        onChange={this.handleInputChange}
                                                        disable={this.state.start_date_accountNew}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-10 mt-5 ml-4 mr-3 mt-3 mb-3">
                                                <h4>{t('settingAccount:Accounting Period')}</h4>
                                                <hr/>
                                            </div>
                                            <div className="row mt-3 ml-4 mr-3 mt-3 mb-3" style={{ alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <h6>{t('settingAccount:Account system period start date')}</h6>
                                                    <div className="input-group">
                                                        <DatePickerAdapter id="start_date_account_period"
                                                            className="form-control" name="start_date_account_period"
                                                            selected={this.state.start_date_account_period}
                                                            // dateFormat={"dd/MM"}
                                                            // showMonthYearPicker={true}
                                                            disable={!this.state.user_super_admin}
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <h6>{t('settingAccount:Account system period end date')}</h6>
                                                    <DatePickerAdapter id="end_date_account_period"
                                                        className="form-control" name="end_date_account_period"
                                                        selected={this.state.end_date_account_period}
                                                        // dateFormat={"dd/MM"}
                                                        // showMonthYearPicker={true}
                                                        disable={!this.state.user_super_admin}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>

                                            </div>
                                            {/* <div className="mt-3 ml-4 mr-3 mt-5 mb-3" style={{ alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <h4>วันที่ล็อกระบบ</h4>
                                                    <p>ตั้งค่าให้ล็อกระบบในทุกๆวันที่ระบุภายในเดือน</p>
                                                </div>
                                                <div className="col-2">
                                                    <input className="form-control" name="dete_for_lock_account" disabled={true} />
                                                </div>
                                            </div> */}
                                            {/* <div className="table-responsive">
                                                        <table className="table table-hover">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th colSpan={2} className="pl-3 pt-3"><h5>ตั้งค่าบัญชี</h5></th>
                                                                </tr>
                                                            </thead>
        
                                                            <tbody>
                                                                */}
                                            {/* <tr>
                                                                <td  className="rowDetail" width="300">วันที่เริ่มช้งานระบบบัญชี</td>
                                                                <td  className="rowDetail">
                                                                    <div className="col-3 dropdownMonth">
                                                                        <div className="form-group">
                                                                            <select name="month_close_statement"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.month_close_statement}
                                                                                    className="form-control dropdown-custom-arrow">
                                                                                <option value="1">มกราคม</option>
                                                                                <option value="2">กุมภาพันธ์</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr> */}
                                            {/* <tr>
                                                                <td  className="rowDetail" width="300">ล็อควันที่ป้องกันการแก้ไข</td>
                                                                <td  className="rowDetail">
                                                                    <DatePickerAdapter id="lock_date"
                                                                                    className="form-control" name="lock_date"
                                                                                    selected={this.state.lock_date}
                                                                                    onChange={this.handleInputChange}
                                                                    />
                                                                </td>
                                                            </tr> */}
                                            {/* </tbody>
                                                        </table>
                                                    </div> */}
                                            {/* </div> */}

                                            {this.state.user_super_admin &&
                                                <div className="row mt-4 fade-up">
                                                    <div className="col text-right">
                                                        <button type="submit" className="btn btn-primary add" onClick={() => this.onSubmit()}>{t('settingAccount:Save')}</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            }
                        </Translation>
                    </div>

                </WrapperContent>
            </Wrapper>
        )
    }
}

export default SettingAccountForm;
