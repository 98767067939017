/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type creditCardListAllQueryVariables = {||};
export type creditCardListAllQueryResponse = {|
  +creditCardDepositGroups: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +depositDate: any,
        +description: ?string,
        +bank: {|
          +bankName: string,
          +accountNumber: string,
        |},
        +depositTotal: number,
        +feeTotal: number,
      |}
    |}>,
    +totalCount: ?number,
  |},
  +selfProject: ?{|
    +timeZone: ?string
  |},
|};
export type creditCardListAllQuery = {|
  variables: creditCardListAllQueryVariables,
  response: creditCardListAllQueryResponse,
|};
*/


/*
query creditCardListAllQuery {
  creditCardDepositGroups {
    edges {
      node {
        id
        docNumber
        depositDate
        description
        bank {
          bankName
          accountNumber
          id
        }
        depositTotal
        feeTotal
      }
    }
    totalCount
  }
  selfProject {
    timeZone
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositTotal",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feeTotal",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "creditCardListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreditCardDepositGroupConnection",
        "kind": "LinkedField",
        "name": "creditCardDepositGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreditCardDepositGroupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreditCardDepositGroup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountNode",
                    "kind": "LinkedField",
                    "name": "bank",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "creditCardListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreditCardDepositGroupConnection",
        "kind": "LinkedField",
        "name": "creditCardDepositGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreditCardDepositGroupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreditCardDepositGroup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountNode",
                    "kind": "LinkedField",
                    "name": "bank",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d26a239931c99431c4173c8fcd3db221",
    "id": null,
    "metadata": {},
    "name": "creditCardListAllQuery",
    "operationKind": "query",
    "text": "query creditCardListAllQuery {\n  creditCardDepositGroups {\n    edges {\n      node {\n        id\n        docNumber\n        depositDate\n        description\n        bank {\n          bankName\n          accountNumber\n          id\n        }\n        depositTotal\n        feeTotal\n      }\n    }\n    totalCount\n  }\n  selfProject {\n    timeZone\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a4d804f2eb15034b43cb719aa5cc072';

module.exports = node;
