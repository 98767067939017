import React, { Component } from 'react'
import "../style/manageRequest.scss"
import Select from 'react-select';
import i18next from 'i18next';

class formCondiotionWaranty extends Component {
  render() {
    return (
      <div id="formCondiotionWaranty">
        <h4>{i18next.t("purchaseRequisition:Other conditions")}:</h4>
        <div className="lineBottom" />
        {
          !this.props.disibleInput ?
            <div className="row">
              <div className="col-3">

                <div style={{ position: 'relative' }}>
                  <label>{i18next.t("purchaseRequisition:Warranty")}</label>
                  <div style={{ position: 'relative' }}>
                    <Select
                      placeholder={i18next.t("purchaseRequisition:Choose warranty conditions")}
                      options={this.props.optionConditionWaranty}
                      isSearchable={false}
                      onChange={this.props.handleChangeSelect}
                    />
                    <input name="purchaseRequisition.conditionWaranty" type="text"
                      value={this.props.purchaseRequisition.conditionWaranty || ""}
                      style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1, opacity: 0 }}
                      onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                        />
                  </div>



                </div>
              </div>

              <div className='col-3'>

                {
                  this.props.purchaseRequisition.conditionWaranty === "other" ?
                    <React.Fragment>
                      <label>{i18next.t("purchaseRequisition:Specify")}</label>
                      <input style={{ height: '48px' }}
                        placeholder={i18next.t("purchaseRequisition:Specify")}
                        className="form-control"
                        disabled={this.props.disibleInput}
                        type="number" name='purchaseRequisition.conditionWarantyOther'
                        onKeyPress={this.props.handleChangeSelect}
                        onChange={this.props.handleChangeSelect} />
                    </React.Fragment>
                    :
                    ""
                }
              </div>
            </div> 
            :
            this.props.purchaseRequisition.warrantyDay.toString() ?
            <div className="row">
              {
                this.props.purchaseRequisition.warrantyDay !== 0 && 
                this.props.purchaseRequisition.warrantyDay !== 30 && 
                this.props.purchaseRequisition.warrantyDay !== 60 && 
                this.props.purchaseRequisition.warrantyDay !== 90 && 
                this.props.purchaseRequisition.warrantyDay !== 120 ?
                  <React.Fragment>
                    <div className='col-3'>
                      <label>{i18next.t("purchaseRequisition:Warranty")}</label>
                      <select className='form-control' disabled>
                        <option>
                        {i18next.t("purchaseRequisition:Specify")}
                        </option>
                      </select>
                    </div>
                    <div className='col-3'>
                      <label>{i18next.t("purchaseRequisition:Specify")}</label>
                      <input value={this.props.purchaseRequisition.warrantyDay + " วัน"} disabled className='form-control'/>
                    </div>
                  </React.Fragment>
                  :
                  <div className='col-3'>
                    <label>{i18next.t("purchaseRequisition:Warranty")}</label>
                    <select className='form-control' disabled>
                      <option>
                        {this.props.purchaseRequisition.warrantyDay === 0 ? i18next.t("purchaseRequisition:No warranty") : this.props.purchaseRequisition.warrantyDay + i18next.t("purchaseRequisition:Days")}
                      </option>
                    </select>
                  </div>
              } 
            </div> 
            : 
            ""
        }


      </div>
    )
  }
}
export default formCondiotionWaranty;
