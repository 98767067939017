

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import UploadImageInput from "../libs/uploadImageInput";
import Swal from 'sweetalert2';
import numberWithComma from "../libs/numberWithComma";
import {format} from "date-fns";
import upload from '../libs/upload';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../env/environment";
import API from '../api/silverGraund2'
import settingAPI from '../api/setting'
import _ from "lodash";
import './vms.scss'
import i18next from 'i18next';

const query = graphql`
    query vmsPaymentQuery{
        selfProject {
            name
            logo
        }  
}`;
// let dateNow = new Date()
class VMSPayment extends Component {

    constructor(props) {
        super(props)
        this.state = {
          lang:false,
          paymentData:[],
          slipImg:"",
          isSlipImgResize:"",
          isSlipImg:false,
          codeData:"",
          projectName:"",
          projectLogo:"",
          slipImgUpload:"",
          settingCalu:[],
          hours:null,
          minutes:null
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getTime = this.getTime.bind(this);
        this.downloadQR = this.downloadQR.bind(this);
        this.getPersonType = this.getPersonType.bind(this)
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, value)});   
    }

    componentDidMount(){
        this.getPayment()
        this.getSelfProject()
        this.getcaluletePackingSetting()
    }

    getSelfProject(){
        fetchQuery(environment, query).then(data => {
            if(data.selfProject){
                this.setState({
                    projectName:data.selfProject.name,
                    projectLogo:data.selfProject.logo
                })
            }
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/vms/" + path, file);
          resolve(res);
        });
    };


    getPayment(){
        let idCode = this.props.match.params.id
        API.getPayment(idCode).then((res) => {
           if(res.data.data){
                this.setState({
                    codeData: res.data.code,
                    paymentData : res.data.data,
                })
           }
        }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getcaluletePackingSetting(){
        settingAPI.getcaluletePackingSetting().then((res) => {
            if(res.data.settingCalu){
                 this.setState({
                    settingCalu: res.data.settingCalu,
                    hours : res.data.settingCalu.estampStatus ? res.data.settingCalu.etampTimer ? res.data.settingCalu.etampTimer : res.data.settingCalu.etampTimerHH : res.data.settingCalu.etampTimerVistor ? res.data.settingCalu.etampTimerVistor : res.data.settingCalu.etampTimerVistorHH,
                    minutes : res.data.settingCalu.estampStatus ? res.data.settingCalu.etampTimer ? 0 : res.data.settingCalu.etampTimerMM : res.data.settingCalu.etampTimerVistor ? 0 : res.data.settingCalu.etampTimerVistorMM
                 })
            }
         }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getTime (type) {
        if (type === 'hours') {
            return parseInt(Math.abs(new Date(this.state.paymentData.paymentDate) - new Date(this.state.paymentData.startDate)) / (1000 * 60 * 60))
        } else {
           return parseInt(Math.abs(new Date(this.state.paymentData.paymentDate).getTime() - new Date(this.state.paymentData.startDate).getTime()) / (1000 * 60) % 60)
        }
    }

    downloadQR = () => {
        const canvas = document.getElementById("paymentQR");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrcode1.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    getPersonType(val){
        let result;
        switch (val) {
            case "tenant":
              result = "ผู้เช่า/ลูกบ้าน"
              break;
            case "contact":
              result = "ติดต่อสำนักงานขาย"
              break;
            case "appointment":
              result = "ผู้ติดต่อนัดหมายล่วงหน้า"
            case "person":
                result = "บุคคลทั่วไป"
              break;
          }
          return result;
    }
    



    render() {

        const {estampStatus,etampTimer,etampTimerHH,etampTimerMM,etampTimerVistor,etampTimerVistorHH,etampTimerVistorMM} = this.state.settingCalu
        let A = (this.getTime('hours') * 3600) + (this.getTime() * 60)
        let B = estampStatus ? etampTimer ? (etampTimer * 3600) : (etampTimerHH * 3600 + etampTimerMM * 60) : etampTimerVistor ? (etampTimerVistor * 3600) : (etampTimerVistorHH * 3600 + etampTimerVistorMM * 60)
        let hours;
        let minutes;
        let price;

        if (Math.sign(A - B) === 1) {
             hours = Math.floor((A - B) / 3600); // get hours
             minutes = Math.floor(((A - B) - (hours * 3600)) / 60); // get minutes
             price = (hours * parseFloat(this.state.settingCalu?.toPayCal) + (minutes >= this.state.settingCalu?.toCalOverTime ? parseFloat(this.state.settingCalu.toPayCal) : 0))
           // setOverTime({ hours, minutes, price: (hours * parseFloat(toPayCal) + (minutes >= toCalOverTime ? parseFloat(toPayCal) : 0)) })
        } else {
            //setOverTime(false)
        }

        return (
            <>
                  <div id="wrapper-content">
                    <div id='vms-wrapper'>
                        <div className='p-4 vms-payment'>
                           <div className='row'>
                                <div className='col'>
                                    <span onClick={ () => this.setState({lang : !this.state.lang})} className='btn-lang' style={{position:'absolute'}}>
                                        <img src={this.state.lang ? (process.env.PUBLIC_URL + '/images/flags/en.png') : (process.env.PUBLIC_URL + '/images/flags/th.png')} 
                                        alt="flag-icon" className='flag'/>
                                        <h6><small>{this.state.lang ? 'EN':'ไทย'}</small></h6>
                                    </span>
                                </div>
                           </div>
                           
                            <div>
                            <div className='text-center p-2'>
                               <img
                                 src={this.state.projectLogo && this.state.projectLogo}
                                  alt="svm"
                                  className="svm" style={{width:'109px',height:'80px'}}
                                />
                            </div>
                                <p className='text-center'>{this.state.projectName && this.state.projectName}</p>
                                <p className='pt-2'> 
                                    <span className='span-blue'>{this.getPersonType(this.state.paymentData.typePerson && this.state.paymentData.typePerson)}</span>
                                    {this.state.paymentData.overtime > 0 ? <span className='span-red'> {this.state.lang ? 'Overtime' :'เกินเวลา'} </span> :'' }
                                </p>
                                <p className='pt-2'>{this.state.paymentData.carRegistration && this.state.paymentData.carRegistration}, {this.state.paymentData.province && this.state.paymentData.province}</p>
                            </div>
                            <div className="row pt-4">
                                <div className='col text-center'>
                                        <div className='setting-webview-qr'>
                                             <label>{this.state.lang ? 'Please scan the QR code to pay the parking fee for the project.' :'กรุณาสแกนเพื่อชำระค่าจอดรถให้กับโครงการ'}</label>
                                             <label>{this.state.lang ? 'Bank' :'ธนาคาร'} : {this.state.paymentData.bankName ? this.state.paymentData.bankName :'-' } {this.state.lang ? 'Account Number' :'เลขบัญชี'} : {this.state.paymentData.accountNumber ? this.state.paymentData.accountNumber :'-' }</label>
                                             <label>{this.state.lang ? 'Bank Name' :'ชื่อบัญชี'}  : {this.state.paymentData.accountName ? this.state.paymentData.accountName : '-'}</label>
                                          </div>
                                    <div id="" className='pt-4'>
                                           <img src={this.state.paymentData.payment} 
                                            style={{width:'235px', height:'auto'}}/>
                                    </div>
                                    <div className='row pt-5'>
                                        <div className='col'>
                                        </div>
                                        <div className='col'>
                                                <a href={this.state.paymentData.payment}>
                                                    <button className="btn btn-add-slip" style={{width:'160px',fontSize:'14px'}}>
                                                        <img src={process.env.PUBLIC_URL + '/images/setting-walkin/download.png'}
                                                         className='pr-4' /> 
                                                        {this.state.lang ? 'Open QR Code' :' เปิด QR Code'}
                                                    </button>
                                                </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='setting-webview-detail-time pt-4'> 
                                    <div className='row'>
                                        <div className='col text-gray'> 
                                                <label>{this.state.lang ? 'Parking time' :'เวลาเข้าจอด'} </label>
                                                <label style={{color:'#1567B4',fontSize:'18px'}}>{format(this.state.paymentData.startDate, "HH:mm A")}</label>
                                                <span>{format(this.state.paymentData.startDate, "DD/MM/YYYY")}</span>
                                        </div>
                                        <div className='col text-center'>
                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconArrowRight.png'}
                                                    style={{ height: '30px'}} />
                                        </div>
                                        <div className='col text-right text-gray'> 
                                                <label>{this.state.lang ? 'Exit time' :'เวลาออก'}</label>
                                                <label style={{color:'#CA0824',fontSize:'18px'}}>{format(this.state.paymentData.paymentDate, "HH:mm A")}</label> 
                                                <span>{format(this.state.paymentData.paymentDate, "DD/MM/YYYY")}</span>
                                        </div>
                                    </div>
                                     <div className='row pt-4'>
                                             <div className='col-6'>
                                                 <label>{this.state.lang ? 'Total parking time' :'ระยะเวลาที่เข้าจอด'}</label>
                                             </div>
                                             <div className='col text-right'>
                                                  <label> {this.getTime('hours')} {this.state.lang ? 'hours' :'ชั่วโมง'}  {this.getTime('minutes')} {this.state.lang ? 'minutes' :'นาที'}</label>
                                             </div>
                                      </div>
                                        <div className='row'>
                                               <div className='col'>
                                                     <label>{this.state.lang ? 'Free' :'จอดฟรี'}  </label>
                                                </div>
                                               <div className='col'> </div>
                                                 <div className='col-6 text-right'>
                                                    <label>{this.state.hours || 0} {this.state.lang ? 'hours' :'ชั่วโมง'} {this.state.minutes || 0} {this.state.lang ? 'minutes' :'นาที'}  </label> 
                                                </div>
                                         </div>
                                        <div className='row'>
                                             <div className='col-6'>
                                                  <label> {this.state.lang ? 'Overtime' :'ระยะเวลาเกิน'}  </label>
                                            </div>
                                            <div className='col text-right'>
                                              <label> {hours || 0} {this.state.lang ? 'hours' :'ชั่วโมง'} {minutes || 0 } {this.state.lang ? 'minutes' :'นาที'}  </label>
                                             </div>
                                        </div>
                                        <div className='row'>
                                             <div className='col-6'>
                                                <label>{this.state.lang ? 'Parking Fee' :'อัตราค่าจอดรถ'} </label>
                                             </div>
                                             <div className='col text-right'>
                                                <label>{this.state.settingCalu.toPayCal || 0} {this.state.lang ? 'Baht / Hour' :'บาท/ชั่วโมง'} </label>
                                             </div>
                                       </div>
                                        <div>
                                           <hr style={{marginTop:'0'}}/>
                                        </div>
                                          <div className='row'>
                                             <div className='col-6'>
                                                 <label style={{color:'#212830'}}><strong> {this.state.lang ? 'Total Fee' :'รวมค่าที่จอดรถ'} </strong></label>
                                             </div>
                                             <div className='col text-right'>
                                                <label style={{color:'#212830'}}> 
                                                {numberWithComma(price || 0, "0.00")}{this.state.lang ? 'Baht' :'บาท'}  </label>
                                             </div>
                                             </div>
                                        <div>
                                         <hr style={{marginTop:'0'}}/>
                                       </div>
                                         <div> 
                                         <label style={{color:'#212830'}}><strong>{this.state.lang ? 'Conditions' :'ข้อกำหนดและเงื่อนไข'} </strong></label>
                                           <div>
                                                {
                                                    this.state.paymentData.condition ? this.state.paymentData.condition : ''
                                                }
                                           </div>
                                        </div>

                            </div>
                              {
                                this.state.slipImg  && 
                                    <div>
                                        <h4 className='pt-2'>{this.state.lang ? 'Attach transfer slip' :'หลักฐานการชำระเงิน'}</h4>
                                        <div className='img-box-vms-payment text-center'>
                                            <img src={URL.createObjectURL(this.state.slipImg)}
                                                style={{ width: '50%', height: 'auto'}} />
                                        </div>
                                    </div>
                              }
                             {
                             this.state.slipImg === ''?
                                <div className='pt-4'>
                                 <div className="upload-file">
                                    <label className="btn btn-primary add btn-add-slip" style={{width:'100%'}} htmlFor="uploadFile">{this.state.lang ? 'Attach transfer slip' :'แนบหลักฐานการชำระเงิน'} </label>
                                    <div className="upload-input">
                                        <UploadImageInput
                                            id={"uploadFile"} setField="slipImg" 
                                            onChange={this.handleInputChange}
                                            />
                                    </div>
                                </div>
                               </div> :''
                            }
                            {
                            this.state.slipImg && 
                            <div className='pt-4'>
                                 <div className="upload-file">
                                    <label className="btn btn-add-new-slip" style={{width:'100%'}} 
                                    htmlFor="uploadFile"> {this.state.lang ? 'Attach transfer slip again' :'แนบหลักฐานการชำระเงินใหม่'}</label>
                                    <div className="upload-input">
                                        <UploadImageInput
                                            id={"uploadFile"} setField="slipImg" 
                                            onChange={this.handleInputChange}
                                            />
                                    </div>
                                </div>
                            </div>
                            }
                            <p className=''>{this.state.isSlipImg ? this.state.lang ? `Please take a photo of a slip` :'กรุณาถ่ายรูปสลิป' : ''}</p>
                            {this.state.slipImg && 
                                <div className='pt-2'>
                                    <Link to={{ pathname: "/vms/vms-payment-check/",
                                                state: this.state,price
                                               }}
                                               >
                                    {/* <Link to="/vms/vms-payment-check/" state={{ state: this.state,price: price }}> */}
                                        <button type='button' className="btn btn-primary add mr-2" style={{width:'100%'}}>
                                        {this.state.lang ? 'Next' : 'ถัดไป'} 
                                        </button>
                                    </Link>
                                </div>
                            }
                            <div className='text-center pt-4 vms-payment-credit'>
                                <p>{this.state.lang ? 'Responsible by' :'รับผิดชอบการรักษาความปลอดภัยโดย'} {this.state.paymentData.securityName}</p>
                            </div>
                        </div>
                    </div>
                  </div>
            </>
                
        );
    }
}

export default VMSPayment;
