import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Translation } from 'react-i18next';


class Navigation extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink exact={true} to="/contact/fix-requests/forcommon/review">
                                        {t("PageList:Review")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact/fix-requests/forcommon/pending">
                                        {t("PageList:Pending")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact/fix-requests/forcommon/approve">
                                        {t("PageList:Approve")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact/fix-requests/forcommon/finish">
                                        {t("PageList:Finish")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact/fix-requests/forcommon/void">
                                        {t("PageList:Cancled")}
                                    </NavLink>
                                </li>
                            </ul>


                        </div>
                    </div>}
            </Translation>
        )
    }
}

export default Navigation;