import {
      commitMutation,
  
  } from 'react-relay'
  import {graphql} from "babel-plugin-relay/macro";
  import environment from "../../../env/environment";
  
  
  const mutation = graphql`
   mutation CreatePreinvoiceMutation($input: CreateInvoiceInput!){
      createInvoice(input: $input){
          ok
          warningText
        }
      }
  `;
  
  
  export default function CreatePreinvoiceMutation(variables,uploadables, callback, error_callback) {
  
      commitMutation(
          environment,
          {
              mutation,
              variables,
              uploadables,
              onCompleted: (response) => {
                  callback(response)
              },
              onError: (err) => {
                  error_callback(err);
              },
          },
      )
  }
  