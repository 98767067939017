import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import Calendars from './calendar';
import BookingCommomAreaList from './bookingCommonAreaList';
import BookingCommomArea from './bookingCommonArea';
import BookingCustomer from './bookingCustomerSingha';
import BookingDetail from './bookingDetail';
import CommonAreaCreate from './CommonAreaList/create';
import ServiceSettingList from "./serviceSettingList";
import ServiceSettingCreateUpdateForm from './serviceSettingCreateUpdateForm';
import AccountingList from './accountingList';
import AccountingDetails from './accountingDetails';
import AccountingAddIncome from './accountingAddIncome';
import SettingBooking from './settingBooking/settingBooking';
import CommonAreaListNew from './CommonAreaList/commonAreaList';
import EditNewFacility from './CommonAreaList/edit';
import ScoreManageByRoomSetDataScore from './scoreManage/scoreManageByRoomSetDataScore';
import ScoreManageByRoomSelect from './scoreManage/scoreManageByRoomSelect';

class CommonAreaSingha extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/managescore`} component={ScoreManageByRoomSelect}/>
                <PrivateRoute exact path={`${this.props.match.url}/managescore/setscore`} component={ScoreManageByRoomSetDataScore}/>
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={BookingCommomAreaList}/>
                <PrivateRoute exact path={`${this.props.match.url}/calendar/month`} component={Calendars}/>
                <PrivateRoute exact path={`${this.props.match.url}/booking/add`} component={BookingCommomArea}/>
                <PrivateRoute exact path={`${this.props.match.url}/booking/create/:status`} component={BookingCustomer}/>
                <PrivateRoute exact path={`${this.props.match.url}/booking/detail/:id`} component={BookingDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/booking/common-area/:status`} component={CommonAreaListNew}/>
                <PrivateRoute exact path={`${this.props.match.url}/booking/common-area/create/facility`} component={CommonAreaCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/booking/common-area/edit/facility/:id`} component={EditNewFacility}/>
                <PrivateRoute exact path={`${this.props.match.url}/services/:status`} component={ServiceSettingList}/>
                <PrivateRoute exact path={`${this.props.match.url}/services/setting/:status`} component={ServiceSettingCreateUpdateForm}/>
                <PrivateRoute exact path={`${this.props.match.url}/services/setting/:status/:id`} component={ServiceSettingCreateUpdateForm}/>
                <PrivateRoute exact path={`${this.props.match.url}/accounting/:status`} component={AccountingList}/>
                <PrivateRoute exact path={`${this.props.match.url}/accounting/details/:id`} component={AccountingDetails}/>
                <PrivateRoute exact path={`${this.props.match.url}/accounting/addIncome/:status/:id`} component={AccountingAddIncome}/>
                <PrivateRoute exact path={`${this.props.match.url}/setting/right/`} component={SettingBooking}/>

                
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default CommonAreaSingha;