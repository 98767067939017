import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import Eia from './eia/eiaView'
import Ereportmatra from './ereportmatra/ereportmatraView'
import NoMatch from "../components/noMatch";
import SendDocDashBoard from './sendDocDashBoard'

class Senddoc extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={SendDocDashBoard}/>
                <PrivateRoute exact path={`${this.props.match.url}/eia`} component={Eia}/>
                <PrivateRoute exact path={`${this.props.match.url}/ereportmatra`} component={Ereportmatra}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Senddoc;