import React, { Component,forwardRef } from 'react';
import SilvermanAgentStep from './silvermanAgentStep'
import DatePicker from "react-datepicker";
import './silvermanAgent.scss'

class SilvermanAgentPrice extends Component {

    constructor(props) {
        super(props)

        this.state = {
            autoCheckContactUser:false,
        }
    }

    render() {
        return (
            <>
            <div id="svm-agent-status">
                    <div style={{backgroundColor:'#fff'}} className='p-4'>
                        <SilvermanAgentStep svmAgentStepFinish3={this.props.svmAgentStepFinish3} lang={this.props.lang}/>
                        <div>
                            <h5>{this.props.lang === 'en' ? 'Price':'ราคา'}<small><span className='text-danger'>*</span></small></h5>
                            {
                                this.props.announceSell &&
                                <div className='mt-2'>
                                    <span>{this.props.lang === 'en' ? 'Price':'ราคาขาย'}<small><span className='text-danger'>*</span>{this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</small></span>
                                    <input type="text" className="form-control" name='price' placeholder={this.props.lang === 'en' ? 'Price':'ราคาขาย'} onChange={this.props.handleInputChange} 
                                    /> 
                                </div>
                            }
                            {/* {
                                this.props.announceDown && 
                                <div className='mt-2'>
                                    <span>ราคาขายดาวน์ <span className='text-danger'>*</span> </span>
                                    <input type="text" className="form-control" name='downPrice' placeholder='ราคาขายดาวน์' onChange={this.props.handleInputChange}/>
                                    <span className='mt-2'>ราคาขายจริง <span className='text-danger'>*</span> </span>
                                    <input type="text" name="realPrice" className="form-control"  placeholder='ราคาขายจริง' onChange={this.props.handleInputChange} />
                                </div>
                            }
                            {
                                this.props.announceLease && 
                                <div className='mt-2'>
                                    <span>ค่าเซ้ง <span className='text-danger'>*</span> </span>
                                    <input type="text" name="leasePrice" className="form-control"  placeholder='ค่าเซ้ง' onChange={this.props.handleInputChange}  />
                                </div>
                            } */}
                            {
                                this.props.announceBuy && 
                                <div className='mt-2'>
                                    <span>{this.props.lang === 'en' ? 'Buy':'ซื้อ'}<small><span className='text-danger'>*</span>{this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</small></span>
                                    <input type="text" name="buyPrice" className="form-control"  placeholder={this.props.lang === 'en' ? 'Buy':'ซื้อ'} onChange={this.props.handleInputChange} 
                                    />
                                </div>
                            }
                            {
                                this.props.announceLookRent && 
                                <div className='mt-2'>
                                    <span>{this.props.lang === 'en' ? 'Rent':'งบจ่ายค่าเช่า'}<small><span className='text-danger'>*</span>{this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</small></span>
                                    <input type="text" className="form-control" onChange={this.props.handleInputChange} name="lookRentPrice" placeholder={this.props.lang === 'en' ? 'Rent':'งบจ่ายค่าเช่า'} />
                                    <h5 className='mt-2'>{this.props.lang === 'en' ? 'Period contact':'ระยะสัญญา'}</h5>
                                    <div className='row'>
                                        <div className='col'><input type="checkbox" name="twelveTerm" className='myLookRentPric' onChange = {() => this.props.handleInputChangeCheckBox('twelveTerm',!this.props.thirdTerm)}/> {this.props.lang === 'en' ? '12 Month':'12 เดือน'}</div>
                                        <div className='col'><input type="checkbox" name="sixTerm" className='myLookRentPric' onChange = {() => this.props.handleInputChangeCheckBox('sixTerm',!this.props.sixTerm)}/> {this.props.lang === 'en' ? '6 Month':'6 เดือน'}</div>
                                        <div className='col'><input type="checkbox" name="thirdTerm" className='myLookRentPric' onChange = {() => this.props.handleInputChangeCheckBox('thirdTerm',!this.props.thirdTerm)}/> {this.props.lang === 'en' ? '3 Month':'3 เดือน'}</div>
                                    </div>
                                </div>
                            }
                            {
                            this.props.announceForRent && 
                            <>
                            <div className='mt-2'>
                                <span>{this.props.lang === 'en' ? 'Date available':'วันที่พร้อมให้เช่า'}<small><span className='text-danger'>*</span></small></span>
                                <div className='datePicker mb-4'>
                                    <DatePicker
                                        name='rentDate'
                                        id='datepicker'
                                        className="form-control searchDateBox"
                                        selected={this.props.rentDate}
                                        customInput={<ButtonDate data/>}
                                        onChange={(e) => this.props.handleDateChange(e)}
                                        dateFormat="dd/MM/yyyy" placeholderText='dd/mm/yyyy'
                                        required
                                    />
                                </div>
                                <span className='mt-2'>{this.props.lang === 'en' ? 'Rent Price':'ค่าเช่ารายเดือน'}<small><span className='text-danger'>*</span>{this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</small></span>
                                <input type="text" className="form-control" name='rentPrice' placeholder={this.props.lang === 'en' ? 'Rent Price':'ค่าเช่ารายเดือน'} onChange={this.props.handleInputChange}/> 
                            </div>
                            {/* { this.props.announceForRent ?  this.props.priceAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fill the price':'กรุณากรอกข้อมูลราคา'}</span> :'' :''}
                            {this.props.announceForRent ? this.props.onlyNumberPrice ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</span> :'' :''}  */}
                            </>
                            
                            }
                            {
                                this.props.priceAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fill the price':'กรุณากรอกข้อมูลราคา'}</span> :''
                            }
                            {
                                this.props.onlyNumberPrice ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</span> :''
                            }
                        </div>

                    </div>
                    <div style={{backgroundColor:'#fff'}} className='mt-4 p-4'>
                        <div>
                            <h5>{this.props.lang === 'en' ? 'Contact Name':'ชื่อผู้ติดต่อ'}<small><span className='text-danger'>*</span></small></h5>
                            <input type="text" className="form-control" name='contactName' placeholder={this.props.lang === 'en' ? 'Fullname':'ชื่อ - สกุล'}  onChange={this.props.handleInputChange} />
                            {/* <input type="text" className="form-control" name='name' placeholder='ชื่อ' onChange={this.props.handleInputChange} />
                            <input type="text" className="form-control" name='lastName' placeholder='นามสกุล' onChange={this.props.handleInputChange} /> */}
                        </div>
                        {/* <div className='mt-6'>
                            <h5>ชื่อผู้ประกาศ <span className='text-danger'>*</span></h5>
    
                            <input type="checkbox" name="autoCheckContact" onChange = {() => this.props.onSetAutoCheckContact()}/>  ใช้ชื่อผู้ติดต่อ
                            {
                                this.props.autoCheckContact ? <input type="text" className="form-control mt-2" value={this.props.copyContactName } disabled /> :
                                <input type="text" className="form-control mt-2" name='callName' onChange={this.props.handleInputChange}  placeholder='ชื่อผู้ประกาศ' required />
                            }
                        </div> */}
                        {
                                this.props.contactAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fill contace name':'กรุณากรอกข้อมูลผู้ติดต่อ'}</span> :''
                        }
                    </div>
                    <div style={{backgroundColor:'#fff'}} className='mt-4 p-4'>
                        <div className='mt-4'>
                            <h5> {this.props.lang === 'en' ? 'Contact':'ช่องทางติดต่อ'}</h5>
                            <span>{this.props.lang === 'en' ? 'Phone':'เบอร์'} <span className='text-danger'>*</span> <small>{this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</small> </span>
                            <input type="text" className="form-control" name='phone' placeholder={this.props.lang === 'en' ? 'Phone':'เบอร์'} id="phone" onChange={this.props.handleInputChange}/>
                        </div>
                        <div className='mt-4'>
                            <span>ID Line <span className='text-danger'>*</span> </span>
                            <input type="text" className="form-control" name='line' placeholder='ID Line'  onChange={this.props.handleInputChange} />
                        </div>
                        <div className='mt-4'>
                            <span>E-mail<span className='text-danger'>*</span></span>
                            <input type="email" className="form-control" name='email' placeholder='E-mail'  onChange={this.props.handleInputChange} />
                        </div>
                        {
                            this.props.socialAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Select select a contact':'กรุณากรอกช่องทางติดต่อ'}</span> :''
                        }
                        {
                                this.props.onlyNumberSocial ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</span> :''
                        }
                    </div>
                    <div style={{backgroundColor:'#fff'}} className='mt-4 p-4'>
                            <div style={{width:'62%'}}>
                                 <h5>{this.props.lang === 'en' ? 'Which channels do you want Silverman Agent to contact?':'อยากให้ Silverman Agent ติดต่อผ่านช่องทางไหน? '} <span className='text-danger'>*</span></h5>
                            </div>
                          
                            <div className='row'>
                                <div className='col'>
                                    <input type="checkbox" name="silvermanPhone" className='svmContact'
                                     onChange = {() => this.props.handleInputChangeCheckBox('silvermanPhone',!this.props.silvermanPhone)}/> 
                                     <span className='pl-2'>{this.props.lang === 'en' ? 'Phone':'โทรศัพท์'}</span>
                                </div>
                                <div className='col'>
                                    <input type="checkbox" name="silvermanLine"  className='svmContact'
                                     onChange = {() => this.props.handleInputChangeCheckBox('silvermanLine',!this.props.silvermanLine)}/><span className='pl-2'>Line</span>
                                </div>
                                <div className='col'>
                                    <input type="checkbox" name="silvermanEmail"  className='svmContact'
                                    onChange = {() => this.props.handleInputChangeCheckBox('silvermanEmail',!this.props.silvermanEmail)}/><span className='pl-2'>{this.props.lang === 'en' ? 'Email':'อีเมลล์'}</span>
                                </div>
                            </div>
                            {
                                this.props.svmContactAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Select select a contact':'กรุณาเลือกช่องทางติดต่อ'}</span> :''
                            }
                           
                    </div>
               
                <div className='footer-svm-agent'>
                    <div className='pt-6 pb-6'>
                         <button type='submit' onClick={ (e) => this.props.onSubmit(e)} className="btn btn-primary btn-svm-agent"
                         disabled={this.props.loading}
                         >
                         {this.props.lang === 'en' ? 'Submit':'ส่งข้อมูล'}
                         </button>
                    </div>
                </div>
            </div>
            
            </>
           
        )}
}

const ButtonDate = forwardRef(({ value, onClick, date,data }, ref) => (
    <button
      type="button"
      className="button-date"
      style={{ textAlignLast: "start" }}
      onClick={onClick}
      ref={ref}
    >
      {value}
      {value ? '' :(
        <div className="calendars">
          <span
            style={{
              fontSize:'14px'
            }}
          >
            dd/mm/yyyy
          </span>
        </div>
      ) }
    </button>
  ));

export default SilvermanAgentPrice;