import React, { useEffect, useState } from "react";
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import i18n from "i18next";
import environment from "../../../env/environment";
import "./style/chequeListTable.scss";
import _ from "lodash";
import { Translation } from "react-i18next";
import { H5, H4, H6, P2, HS4 } from '../../../components/Text/Text'
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { fetchQuery } from "relay-runtime";
import accountTypeText from '../../../libs/accountTypeText'
import SetFormatNumber from "./setFormatNumber";
import Swal from "sweetalert2";
import { Redirect } from 'react-router'
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
  query chequeControlViewQuery($bankAccountId: String) {
    chequeControl(bankAccountId: $bankAccountId) {
      totalCount
      edges{
          node{
              id
              docNumber
              bankName
              branch
              bankLogo
              accountType
              accountName
              accountNameEn
              accountNumber
              updated
              reorderPoint
              chequeDetails(status:true){
                  edges{
                      node{
                          id
                          status
                          noNumber
                          startCheque
                          endCheque
                          lastCheque
                          chequeNo(status:true){
                              totalCount
                              edges{
                                  node{
                                      id
                                      status
                                      noNumber
                                  }
                              }
                          }
                      }
                  }
              }
              
          }
      }          
  }
  }
`;

const mutation = graphql`
 mutation chequeControlViewMutation($input: CreateAndUpdateChequeDetailsInput!){
  createAndUpdateChequeDetails(input: $input){
        ok
        }
    }
`;

const ChequeControlView = ({ match: { params: id } }) => {
  const [state, setstate] = useState({ bankName: '', branch: '', bankLogo: '', accountType: '', accountName: '', accountNameEn: '', accountNumber: '', chequeDetails: [], docNumber: '', reorderPoint: '', chequeCount: '', lastCheque: '' })
  const [chequeDetails, setChequeDetails] = useState([])
  const [model, setModel] = useState('close')
  const [redirectToList, setRedirectToList] = useState(false)
  const [checkEdit, setCheckEdit] = useState(false)

  useEffect(() => {
    if (checkEdit) {
      window.addEventListener('beforeunload', function (e) {
        e.preventDefault();
        e.returnValue = '';
      });
    }
  }, [checkEdit])


  useEffect(() => getData(id), [id])

  const getData = (param) => {
    fetchQuery(environment, query, { bankAccountId: param.id }).then(response_data => {
      let { bankName, branch, bankLogo, accountType, accountName, accountNameEn, accountNumber, chequeDetails, docNumber, reorderPoint } = _.get(response_data, 'chequeControl.edges[0].node')
      setstate({ ...state, bankName, branch, bankLogo, accountType, accountName, accountNameEn, accountNumber, docNumber, reorderPoint, chequeCount: _.sumBy(chequeDetails.edges, 'node.chequeNo.totalCount'), lastCheque: chequeDetails.edges.length })
      setChequeDetails(chequeDetails.edges?.map((item) => { return { ...item.node, chequeDetailsId: item.node.id } }) || [])
    });
  }


  const setDetail = (state) => {
    setModel('close')
    setCheckEdit(true)
    if (state.status === 'auto' ?
      (state.cheque_no.length > 0 && state.cheque_amount.length > 0 && state.cheque_no_start.length === 8) :
      (state.cheque_no.length > 0 && state.cheque_amount.length > 0)) {
      setChequeDetails([...chequeDetails, {
        "chequeDetailsId": "",
        "noNumber": state.cheque_no,
        "startCheque": (state.status === 'auto' ? state.items_auto[0].noNumber : state.items[0].noNumber).toString(),
        "endCheque": (state.status === 'auto' ? state.items_auto[state.items_auto.length - 1].noNumber : state.items[state.items.length - 1].noNumber).toString(),
        "status": true,
        "chequeNo": state.status === 'auto' ? state.items_auto : state.items,
        "totalCount": state.status === 'auto' ? state.items_auto.length : state.items.length
      }])
    }
  }


  const onSubmit = () => {
    let variables = {
      input: {
        "chequeDetails": {
          "bankAccountId": id.id,
          "reorderPoint": state.reorderPoint,
          "chequeDetails": _.filter(chequeDetails, function (o) { return o.chequeDetailsId === ''; })
        }
      }

    }
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          if (response.createAndUpdateChequeDetails.ok) {
            Swal.fire(i18n.t("cheque:Success!"), '', 'success').then((result) => { getData(id) })
          } else {
            Swal.fire(i18n.t("cheque:Failed"), '', 'warning').then((result) => { getData(id) })
          }
          setCheckEdit(false)
        },
        onError: (err) => {
          setCheckEdit(false)
          Swal.fire('server error', '', 'error').then((result) => { })
        },
      },
    )
  };

  const handleDelete = (e) => {
    let data = _.cloneDeep(chequeDetails)
    _.pullAt(data, [e.target.name])
    setChequeDetails([...data])
  }

  useEffect(() => {
    if (redirectToList) {
      return <Redirect to="/accounting/finance/cheque-control/list/all" />
    }
  }, [redirectToList])


  const handleClick = (event) => {
    if (checkEdit) {
      if (window.confirm(i18next.t("chequeControlView:Due to changes in the data, would you like to save the information?"))) {
        onSubmit()
        event.preventDefault();
      } else {
        event.preventDefault();
      }
    }
  }

  return (
    <Wrapper>
      <Header />
      <Sidebar />
      <WrapperContent>
        <AccountingTopMenuNavigation mini={true} />
        {/* <form id="form-deposit" > */}
        <Translation>
          {t =>
            <div className="container-fluid box">
              <BackButtonIcon
                LinkBack={"/accounting/finance/cheque-control/list/all"}
                LinkText={state.docNumber}
              />
              <div className="content-inner">
                <div className="d-flex justify-content-end pr-10" >
                  <H4>{i18next.t("chequeControlView:Total Remaining Number of Cheque")} : </H4>
                  <HS4>{state.chequeCount}</HS4>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-8" >
                    <div className="col-6 mt-6">
                      <H5> {i18next.t("chequeControlView:Bank")} </H5>
                      <input type="text" className="form-control mt-2" disabled value={state.bankName || ''} />
                    </div>
                    <div className="col-6 mt-6">
                      <H5> {i18next.t("chequeControlView:Branch")} </H5>
                      <input type="text" className="form-control mt-2" disabled value={state.branch || ''} />
                    </div>
                    <div className="col-6 mt-6">
                      <H5 className="mb-6" > {i18next.t("chequeControlView:Bank Logo")} </H5>
                      <span >
                        <img src={state.bankLogo || ''} alt="back" className="back" style={{ width: 48, height: 48 }} />
                      </span>
                    </div>
                    <div className="col-6 mt-12">
                      <H5> {i18next.t("chequeControlView:Account Type")} </H5>
                      <input type="text" className="form-control mt-2" disabled value={t(accountTypeText(state.accountType)) || ''} />
                    </div>
                    <div className="d-flex mt-6">
                      <div className="col-6">
                        <H5> {i18next.t("chequeControlView:Account Name (Thai)")} </H5>
                        <input type="text" className="form-control mt-2" disabled value={state.accountName || ''} />
                      </div>
                      <div className="col-6">
                        <H5> {i18next.t("chequeControlView:Account Name (ENG)")} </H5>
                        <input type="text" className="form-control mt-2" disabled value={state.accountNameEn || ''} />
                      </div>
                    </div>
                    <div className="col-6 mt-6">
                      <H5> {i18next.t("chequeControlView:Account Number")} </H5>
                      <input type="text" className="form-control mt-2" disabled value={state.accountNumber || ''} />
                    </div>

                    <div className="d-flex justify-content-between mt-12">
                      <div className='col' >
                        <H4>{i18next.t("chequeControlView:Cheque Register Details")}</H4>
                      </div>
                      <div className="d-flex justify-content-end ">
                        <div className="col">
                          <div className="form-group row" >
                            <label htmlFor="staticEmail" className="col-sm-6 col-form-label">{i18next.t("chequeControlView:Set the Number of Cheque")}</label>
                            <div className="col-sm-6">
                              <input type="text" className="form-control" value={state.reorderPoint}
                                disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_chequecontrol_create" })}
                                onChange={(e) => { setstate({ ...state, reorderPoint: e.target.value }) }} style={{ maxWidth: 100 }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive col-12 mt-6 ">
                      <table className="table table-hover">
                        <thead className="thead-light">
                          <Translation>{(t) =>
                            <tr>
                              <th className="text-center"><H6 >{i18next.t("chequeControlView:Book Number")}</H6></th>
                              <th className="text-center"><H6 >{i18next.t("chequeControlView:Starting Cheque Number")}</H6></th>
                              <th className="text-center"><H6 >{i18next.t("chequeControlView:Ending Cheque Number")}</H6></th>
                              <th className="text-center"><H6 >{i18next.t("chequeControlView:Latest Used Cheque Number")}</H6></th>
                              <th className="text-center"><H6 >{i18next.t("chequeControlView:Remaining Quantity")}</H6></th>
                              <th className="text-center"></th>
                            </tr>
                          }</Translation>
                        </thead>
                        <tbody>
                          {_.map(chequeDetails, (item, index) => {
                            return (
                              <Translation key={index}>{(t) =>
                                <tr>
                                  <td className="text-center" width='60'><P2 >{item.noNumber}</P2></td>
                                  <td className="text-center" width='60'><P2 >{item.startCheque}</P2></td>
                                  <td className="text-center" width='60'><P2 >{item.endCheque}</P2></td>
                                  <td className="text-center" width='60'><P2 >{item.lastCheque || '-'}</P2></td>
                                  <td className="text-center" width='60'><P2 >{item.chequeNo.totalCount || item.totalCount}</P2></td>
                                  {state.lastCheque < index + 1 ?
                                    <td className="text-center" width='60' >
                                      <img style={{ width: 24, height: 24 }} src={process.env.PUBLIC_URL + '/images/icons/chequeControl/icon_delete.png'} onClick={handleDelete} name={`${index}`} />
                                    </td> :
                                    <td className="text-center" width='60'></td>}
                                </tr>
                              }</Translation>
                            )
                          })}
                          <SetFormatNumber setDetail={setDetail} model={model} setModel={() => setModel('close')} />
                        </tbody>
                      </table>
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_chequecontrol_create" }) &&
                        <div type="button" data-toggle="modal" data-target="#modalSetFormatNumber2" style={{ fontFamily: 'Kanit', fontSize: 16, color: '#1567B4', fontWeight: 600 }} className='mt-8 col'
                          onClick={() => setModel('open')}
                        >+ {t("settingAll:Add list")}</div>
                      }
                    </div>

                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_chequecontrol_create" }) &&
                      <div className="d-flex justify-content-end mt-12 col-12">
                        <button type='button' onClick={onSubmit} style={{ backgroundColor: '#1567B4', minHeight: 48, paddingLeft: 24, paddingRight: 24, justifyItems: 'center', color: '#FFFFFF', borderWidth: 0, borderRadius: 4 }}   >{i18next.t("Allaction:save")}</button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>}
        </Translation>
        {/* </form> */}
      </WrapperContent>
    </Wrapper>
  );
};
export default ChequeControlView

