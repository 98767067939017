import React from 'react';
import { Modal } from 'react-bootstrap';
import "./developer.scss";


function DeveloperPopup(props) {
    const goHome = () =>{
        window.location.href = '/home';
    }
     return (
      <>
      <div id="popupBanner">
        <Modal show={true}  id="modal"  size="lg" className='modal-popup-banner'
            aria-labelledby="contained-modal-title-vcenter"
            centered >
                <div style={{position:'revert',backgroundColor:'#fff'}}>               
                    <img
                    src={process.env.PUBLIC_URL + '/images/banner/developer.png'}
                    alt="alert" className='img-fluid '/>
                    <img onClick={() => goHome() }
                    src={process.env.PUBLIC_URL + '/images/banner/Union.png'}
                    alt="alert" 
                    style={{width:'20px',position:'absolute',right:'3%',top:'4%',cursor:'pointer'}}
                    /> 
                    <div className='p-4 developer-popup'>
                        <div className='row'>
                            <div className='col' style={{color:'#1567B4'}}>“ขณะนี้นิติบุคคลโครงการท่าน ยังไม่ได้เปิดใช้ฟังก์ชั่น สำหรับผู้พัฒนาโครงการ
    กรุณาติดต่อทีมงาน Silverman Support เพื่อเปิดใช้บริการฟังก์ชั่นนี้”</div>
                            <div className='col'>
                                <div className='row'>
                                    <div className='col'>ติดต่อทีมงาน Silverman</div>
                                </div>
                                <div className='row' style={{fontSize:'12px'}}>
                                    <div className='col-4'> 
                                    <img src={process.env.PUBLIC_URL + '/images/icons/developer/phone.png'}
                                        alt="phone" style={{width:'15px'}} className='img-fluid'/>
                                    090-1234566 </div>
                                    <div className='col-4' style={{padding:'0'}}>
                                    <img src={process.env.PUBLIC_URL + '/images/icons/developer/email.png'}
                                        alt="email" style={{width:'18px'}} className='img-fluid pr-1'/>hello@Silverman.app</div>
                                    <div className='col-4'> <img src={process.env.PUBLIC_URL + '/images/icons/developer/line.png'}
                                        alt="line" style={{width:'15px'}} className='img-fluid'/> @Silvermanapp </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Modal>
      </div>
      </>
     )

}

export default DeveloperPopup;