import {
  commitMutation,
} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../../env/environment";


const mutation = graphql`
mutation CreateEditorDocumentLogMutation ($input: CreateEditorDocumentLogInput!) {
    createEditorDocumentLog (input: $input) {
        ok
        message
    }
}
`;

export default function CreateEditorDocumentLogMutation(input, callback, error_callback) {
  const variables = {
    input: input
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        callback(response)
      },
      onError: (err) => {
          error_callback(err);
      },
    },
  )
}
