import React, { Component } from "react";
import { graphql } from "babel-plugin-relay/macro";
import Sidebar from "../../components/sidebar";
import Wrapper from "../../components/wrapper";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Header from "../../components/header"
import SettingDelete from "./settingDelete";
import { commitMutation, fetchQuery } from "react-relay";
import environment from "../../env/environment";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import "./settingNote.scss"
import _ from "lodash";
import Swal from "sweetalert2";
import i18n from "i18next";

const mutation = graphql`
    mutation settingNoteInvoiceMutation($input: CreateUpdateQuickMassageInput!){
        createUpdateQuickMassage(input:$input){
            ok
        }
    }`;
const query = graphql`
    query settingNoteInvoiceQuery($id:ID, $type:String) {
        selfProject{
            keyProjectQr
        }
        quickMassageViewer(id:$id, typeNote:$type){
            edges{
              node{
                id
                massageNote
                typeNote
                creator
              }
            }
        }
    }`;
let transaction_object = {
    id: "",
    massageNote: "",
    typeNote: "",
};

const $ = window.jQuery;

class SettingNoteInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            reQuery: false,
            transaction_list: [],
            keyProjectQr:false,
            permissionEdit: _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_settingnoteinvoice_edit'}) ? 
            _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_settingnoteinvoice_edit'}) : false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.appendNewRow = this.appendNewRow.bind(this);
        this.setTransactionList = this.setTransactionList.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        this.setState({
            transaction_list: [...this.state.transaction_list, { ...transaction_object }],
        });
        this.queryData();
    }

    componentDidMount(){
        let max_rows = 6;
            if(this.state.keyProjectQr){
                max_rows = 2;
            }
        $(document).ready(function () {
            $('textarea[data-limit-rows=true]')
                .on('keypress', function (event) {
                    var textarea = $(this),
                        text = textarea.val(),
                        numberOfLines = (text.match(/\n/g) || []).length + 1,
                        maxRows =  max_rows
            
                    if (event.which === 13 && numberOfLines === maxRows ) {
                    return false;
                    }
                });
        });
    }

    queryData() {
        fetchQuery(environment, query, {
            id: null,
            type: 'invoice'
        }).then(data => {
            let data_clone = _.cloneDeep(data);
            let transaction_list = [...this.state.transaction_list];
            if (data_clone.quickMassageViewer.edges.length > 0) {
                transaction_list = []
                data_clone.quickMassageViewer.edges.forEach((quickMassage) => {
                    let quickMassage_empty = _.cloneDeep(transaction_object);
                    quickMassage_empty.id = quickMassage.node.id;
                    quickMassage_empty.massageNote = quickMassage.node.massageNote;
                    quickMassage_empty.typeNote = quickMassage.node.typeNote
                    transaction_list.push(quickMassage_empty)
                });
                this.setState({
                    transaction_list: transaction_list,
                });
            }
            if(data_clone.selfProject.keyProjectQr !== null){
                this.setState({
                    keyProjectQr : true
                })
            }
        })
    }

    handleInputChange(e) {
        if (e.target.name === "massageNote"){
            e.target.style.height = 'inherit';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }
        if (e.target.dataset.key === 'transaction_list') {
            let transaction_list = [...this.state.transaction_list];
            transaction_list[e.target.dataset.id][e.target.name] = e.target.value;
            transaction_list[e.target.dataset.id]['typeNote'] = 'invoice';
            let max_rows = 6;
            if(this.state.keyProjectQr){
                max_rows = 2;
            }
            $(document).ready(function () {
                $('textarea[data-limit-rows=true]')
                  .on('keypress', function (event) {
                      var textarea = $(this),
                          text = textarea.val(),
                          numberOfLines = (text.match(/\n/g) || []).length + 1,
                          maxRows =  max_rows
              
                      if (event.which === 13 && numberOfLines === maxRows ) {
                        return false;
                      }
                  });
              });
            
            this.setState({ transaction_list: [...transaction_list] });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }

    }
    setTransactionList(new_transaction) {
        this.setState({ transaction_list: new_transaction })
    }

    appendNewRow() {
        this.setState({ transaction_list: [...this.state.transaction_list, { ...transaction_object }] });
    }

    onSubmit(e) {
        e.preventDefault();
        let isError = false;
        let max_length = this.state.keyProjectQr ? 200 : 800;
        let number_note_error = "";
        this.state.transaction_list.forEach((note,index) => {
            if(note.massageNote.length > max_length){
                isError = true
                number_note_error += (index+1) + " "
            }
        })
        if(isError){
            Swal.fire(i18n.t('settingNote:Can\'t save'), i18n.t('settingNote:Because') + i18n.t('settingNote:Note') + number_note_error + i18n.t('settingNote:messages exceed') + max_length +  i18n.t('settingNote:characters'), 'warning').then(() => {
                this.setState({ redirectToList: true, loading: false });
            })
        }else{
            this.setState({ loading: true });
            let variables = {
                input: {
                    quickMassage: JSON.stringify(this.state.transaction_list),
                }
            };
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    // uploadables,
                    onCompleted: (response) => {
                        if (response.createUpdateQuickMassage.ok) {
                            Swal.fire( i18n.t('settingNote:Save successfully'), '', 'success').then(() => {
                                this.setState({ redirectToList: true, loading: false });
                                this.queryData();
                            })
                        } else {
                            Swal.fire( i18n.t('settingNote:Can\'t save'), '', 'warning');
                            this.setState({ loading: false });

                        }
                    },
                    onError: (err) => {
                        Swal.fire('Error!',  i18n.t('settingNote:Please try again'), 'warning')
                        this.setState({ loading: false });
                    },
                },
            );
        }
        
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box" id="note-setting">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('settingNote:Note invoice setting')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <Translation>
                            {t => 
                                <div className="content-inner fade-up">
                                    <div className="row">
                                        <div className="col-2">
                                        </div>
                                        <div className="col-8">
                                            {/* <h5> ตั้งค่าหมายเหตุใบแจ้งหนี้</h5> */}
                                            <form onSubmit={this.onSubmit}>
                                                <div className="card fade-up" style={{border:'none'}}>
                                                    {
                                                        this.state.transaction_list.map((transaction, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className="row mt-3 col ml-2 head-note" >
                                                                    <strong className="note">{t('settingNote:Note')} #{index + 1}</strong> 
                                                                    <p className="text-danger ml-3 max-length"> 
                                                                        *{t('settingNote:Enter message not more than')} {this.state.keyProjectQr ? "200" : "800"} 
                                                                        {t('settingNote:characters')} {transaction.massageNote.length}/{this.state.keyProjectQr ? "200" : "800"} {t('settingNote:And no more than 2 lines')}
                                                                        {(index === 0 && !this.state.keyProjectQr) && " ," + t("settingDocument:notOverLine")}
                                                                    </p>
                                                                </div>
                                                                <div className="row mt-1" style={{ display: "flex", alignItems: 'center' }}>
                                                                    <div className="col ml-3">
                                                                        <textarea type="text" className="form-control" 
                                                                          style={{width:'100%', borderRadius:'10px'}}
                                                                            name="massageNote"
                                                                            data-key="transaction_list"
                                                                            data-id={index}
                                                                            value={transaction.massageNote}
                                                                            onChange={this.handleInputChange}
                                                                            required = {true}
                                                                            maxLength={this.state.keyProjectQr ? 200 : 800}
                                                                            data-limit-rows="true"
                                                                            disabled={!this.state.permissionEdit}
                                                                            />
        
                                                                    </div>
                                                                    <div className="col-1 mr-3">
                                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_settingnoteinvoice_delete'}) &&
                                                                            <SettingDelete transaction={transaction}
                                                                                transaction_list={this.state.transaction_list}
                                                                                setTransactionList={this.setTransactionList} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                        )
                                                    }
                                                    <div className="row">
                                                        <div className="col ml-3">
                                                            {this.state.permissionEdit &&
                                                                <button type="button" className="btn add-button add mt-4 mb-3"
                                                                    onClick={() => this.appendNewRow()} > +{t('settingNote:Add note')}
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3 d-flex justify-content-center">
                                                   {this.state.permissionEdit &&
                                                    <button type="submit" className="btn btn-primary add col-2" >
                                                        {t('settingNote:Save')}
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Translation>
                    </div>

                </WrapperContent>
            </Wrapper>
        )

    }

}
export default SettingNoteInvoice;