import { graphql } from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../../env/environment";

const mutation = graphql`
    mutation createCompanyMutation ($input: CreateJuristicCompanyInput!) {
        createJuristicCompany (input: $input) {
        ok
    }
}

`

export default function createCompany(variables, callback, error_callback) {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          callback(response.createJuristicCompany.ok)
        },
        onError: (err) => {
            error_callback(err);
        },
      },
    )
  }