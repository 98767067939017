import React, {Component} from 'react';
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import SearchSelect from "../../../libs/searchSelect";
import {graphql} from "babel-plugin-relay/macro";
import ChequeDepositMutation from "./mutations/ChequeDepositMutation"
import Swal from "sweetalert2";
import {fetchQuery} from "relay-runtime";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from "i18next";
import {Redirect} from 'react-router'
import getNameResidential from "../../../libs/getNameResidential";
import {format} from "date-fns";

import LightBox from "../../../libs/lightBox";

const _ = require('lodash');



const query = graphql`
    query chequeDepositQuery($id: ID!){
        chequeDeposit(id:$id){
            id
            chequeNumber
            refNumber
            image
            contact{
                name
                firstName
                lastName
                typeOfContact
            }
            bankName
            branchNumber
            date
            depositDate
            price
            status
            unknownContact
            depositBank{
                id
            }
            note
        }
        bankAccountViewer{
            allBankAccount(status: true,accountType_In:"current_account,saving_account,saving_deposit,fixed_deposit"){
                edges{
                    node{
                        id
                        accountNumber
                        accountName
                        bankName
                        accountType
                    }
                }
            }
        }
    }
`;

const cheque_detail = {
    id: '',
    chequeNumber: '',
    refNumber: '',
    contact: {
        firstName: '',
        lastName: '',
    },
    bankName: '',
    branchNumber: '',
    date: '',
    depositDate: '',
    price: '',
    status: '',
    image: '',
};

class ChequeDeposit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deposit_date: new Date(),
            bank_select: '',
            image_upload: '',
            loading: false,
            bank_account: [],
            cheque_detail: cheque_detail,
            redirectToList: false,
            status: "deposit",
            image_upload_display: '',
            signreq:Math.random().toString(36)
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {

        fetchQuery(environment, query, {id: this.props.match.params.id}).then(data => {
            // let bank = data.chequeDeposit.depositBank.id
            let bank = '';
            let deposit_date = new Date();
            if (data.chequeDeposit.depositBank) {
                bank = data.chequeDeposit.depositBank.id
            }
            if (data.chequeDeposit.depositDate) {
                deposit_date = new Date(data.chequeDeposit.depositDate)
            }
            // console.log(data.bankAccountViewer.allBankAccount.edges);
            this.setState({
                bank_account: data.bankAccountViewer.allBankAccount.edges,
                cheque_detail: data.chequeDeposit,
                bank_select: bank,
                deposit_date: deposit_date,
                image: data.chequeDeposit.image
            });
        });


    }


    handleInputChange(e) {
        if (e.target.name === 'image_upload') {
            if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
                this.setState({
                    image_upload: e.currentTarget.files[0],
                    image_upload_display: URL.createObjectURL(e.currentTarget.files[0])
                });
            }
        } else {
            let event = _.cloneDeep(e);
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    event.target.name,
                    event.target.value
                );
            })
            // this.setState({[e.target.name]: e.target.value})
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let input = {
            id: this.state.cheque_detail.id,
            depositBank: this.state.bank_select,
            depositDate: this.state.deposit_date,
            status: this.state.status,
            clientMutationId:"CD"+this.state.signreq,
        };

        const uploadables = {image: this.state.image_upload};

        if (this.state.status === "deposit") {
            ChequeDepositMutation(input, uploadables, this.onSuccess, this.onError)
        }

        //void
        if (this.state.status === "not_deposit") {
            Swal.fire({
                title: i18n.t("cheque:Are you sure to cancel deposit this cheque?"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: i18n.t("cheque:Cancel"),
                confirmButtonText: i18n.t("cheque:Yes")
            }).then((result) => {
                if (result.value) {
                    ChequeDepositMutation(input, uploadables, this.onSuccess, this.onError)
                } else {
                    this.setState({loading: false});
                }
            })
        }

    }

    onSuccess(response) {
        this.setState({loading: false});
        if (response.updateChequeDeposit.ok) {
            Swal.fire(i18n.t("cheque:Save successfully"), '', 'success').then(() => {
                this.setState({redirectToList: true});
            });

        } else {
            Swal.fire(i18n.t("cheque:Save unsuccessfully"), response.updateChequeDeposit.warningText, 'warning');

        }
    }

    onError() {
        this.setState({loading: false});
        Swal.fire(i18n.t("cheque:Save unsuccessfully"), '', 'warning');
    }


    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/finance/cheque-deposit/list/deposit"/>
        }
        return (

            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <form id="form-deposit" onSubmit={this.onSubmit}>
                        <div className="container-fluid box">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/accounting/finance/cheque-deposit/list/not_deposit">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                 alt="back" className="back"/>
                                        </Link>
                                        {this.state.cheque_detail.status === 'NOT_DEPOSIT' ?
                                            <Translation>
                                                {
                                                    t => <span>{t('cheque:cheque_not_deposit')}</span>
                                                }
                                            </Translation>
                                            :
                                            <Translation>{t=><>{t("cheque:cheque")+" "+"(QR)"}</>}</Translation>
                                        }
                                    </h3>
                                </div>
                                <div className="col text-right">
                                    {this.state.cheque_detail.status === 'DEPOSIT' && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_cheque_deposit_delete'}) &&
                                    <button className="btn btn-danger add mr-3" disabled={this.state.loading}
                                            onClick={() => this.setState({status: "not_deposit"})}>
                                        {this.state.loading &&
                                        <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                            <Translation>{t=><>{t("cheque:Cancel a cheque")}</>}</Translation>
                                    </button>
                                    }
                                </div>
                            </div>
                            <div className="content-inner">
                                <div className="card">
                                    <div className="card-body">


                                        <div className="row mb-3">
                                            <div className="col-2">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="chequeNumber"
                                                           className="col-form-label">
                                                        {t("cheque:Cheque date")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="chequeNumber"
                                                       className="col-form-label">
                                                           {format(this.state.cheque_detail.date, 'DD/MM/YYYY')}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row ">
                                            <div className="col-2">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="chequeNumber"
                                                           className="col-form-label">
                                                        {t("cheque:Cheque No.")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="chequeNumber"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.chequeNumber}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-2">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque:Bank")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.bankName}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-2">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque:Branch")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.branchNumber}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className = "row mb-3">
                                            <div className="col-2">
                                                <Translation>{t=><label className="col-form-label">{t("cheque:Get amount")}</label>}</Translation>
                                            </div>
                                            <div className = "col-5">
                                                <Translation>
                                                    {t=>
                                                    <label
                                                        className="col-form-label">{numberWithComma(this.state.cheque_detail.price)} {t("cheque:Baht")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                        </div>

                                        <div className="row ">
                                            <div className="col-2">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="contact"
                                                           className="col-form-label">
                                                        {t("cheque:Customer")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="contact"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.contact
                                                        ? this.state.cheque_detail.contact.typeOfContact === "RESIDENTIAL"
                                                            ? getNameResidential(this.state.cheque_detail.contact.firstName, this.state.cheque_detail.contact.lastName) 
                                                            : this.state.cheque_detail.contact.name 
                                                        : this.state.cheque_detail.unknownContact || "-"}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        
                                        <div className="row mb-3">
                                            <div className="col-2">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque:Reference document")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.refNumber}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className = "row mb-3">
                                            <div className = "col-2">
                                                <Translation>
                                                   {t=>
                                                   <label htmlFor = "description" className ="col-form-label">
                                                        {t("cheque:Note")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                {this.state.cheque_detail.note ? <label className = "col-form-label"> {this.state.cheque_detail.note}</label> : <label> - </label>}
                                            </div>
                                        </div>

                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_cheque_deposit_edit'}) &&
                                        <React.Fragment>
                                            <hr/>
                                            <div className="row mt-3">
                                                <div className="col-2">
                                                    <Translation>
                                                        {t=>
                                                        <label htmlFor="bankDeposit"
                                                               className="col-form-label">
                                                            {t("cheque:Deposit this cheque at")}
                                                        </label>}
                                                    </Translation>
                                                </div>
                                                <div className="col-4">
                                                    <SearchSelect onChange={this.handleInputChange}
                                                                  value={this.state.bank_select}
                                                                  name="bank_select"
                                                                  placeholder="กรุณาเลือกธนาคาร"
                                                                  queryObject={this.state.bank_account}
                                                                  keyOfValue="id"
                                                                  require={true}
                                                                  disabled={this.state.cheque_detail.status === 'NOT_DEPOSIT' ? false : true}
                                                                  keyOfLabel="bankName:accountType:accountNumber"/>
                                                </div>
                                                {/* <div className="col bottom">
                                                    <label
                                                        className={this.state.image_upload.name ? "btn btn-success mb-0 cursor font-medium mr-3" : "btn btn-secondary mb-0 cursor font-medium mr-3"}>
                                                        {this.state.image_upload.name ? "เรียบร้อย" : "แนบหลักฐาน"}
                                                        <input type="file" name="image_upload"
                                                               onChange={this.handleInputChange}
                                                               hidden/>
                                                    </label>
                                                    {this.state.image.name}
                                                </div>
                                                <div className="col-1"/> */}
                                            </div>


                                            <div className="p-2">
                                                    {this.state.image ? <LightBox image={this.state.image} /> :<div></div>}
                                                </div>

                                            <div className="row mt-3">
                                                <div className="col-2">
                                                    <Translation>
                                                        {t=>
                                                        <label htmlFor="dateDeposit"
                                                               className="col-form-label">
                                                            {t("cheque:Since")}
                                                        </label>}
                                                    </Translation>
                                                </div>
                                                <div className="col">
                                                    <DatePickerAdapter id="dateDeposit"
                                                                       className="form-control" name="deposit_date"
                                                                       selected={this.state.deposit_date}
                                                                       onChange={this.handleInputChange}
                                                                       disable={this.state.cheque_detail.status === 'NOT_DEPOSIT' ? false : true}
                                                                       require={true}/>
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                        </React.Fragment>

                                        }

                                    </div>
                                </div>


                                <div className="row mt-4">
                                    {this.state.cheque_detail.status === 'NOT_DEPOSIT' && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_cheque_deposit_edit'}) &&
                                    <div className="col-8 text-right">
                                        <div className="btn-group mr-2">
                                            {/* <Link to="/accounting/finance/cheque-deposit/list/not_deposit">
                                                <button type="button" className="btn btn-secondary add">
                                                    ยกเลิก
                                                </button>
                                            </Link> */}
                                        </div>
                                        <div className="btn-group mr-2">
                                            <button type="submit" className="btn btn-primary add" form="form-deposit"
                                                    disabled={this.state.loading}>
                                                {this.state.loading &&
                                                <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                <Translation>{t=><>{t("cheque:Cash a cheque")}</>}</Translation>
                                            </button>
                                        </div>
                                    </div>
                                    }
                                </div>

                            </div>

                        </div>
                    </form>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default ChequeDeposit;
