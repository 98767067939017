import React, {Component} from "react";
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import Loading from '../../../../libs/loading';
import { QueryRenderer } from "react-relay";
import { formatDateISO } from "../../../../libs/date";
import ReceivableOutstandingSummaryReportPDFRenderTable from "./receivableOutstandingSummaryReportPDFRenderTable";
import './receivableOutstandingSummaryReport.scss'

const query = graphql`
  query receivableOutstandingSummaryReportPDFQuery(
    $dueDate: Date!
    $contactId: ID
    $typeOfContact: String
    $search: String
  ) {
    reportOutstandingReceivableSummary(
      dueDate: $dueDate
      contactId: $contactId
      typeOfContact: $typeOfContact
      search: $search
    ) {
      edges {
        node {
          id
          name
          refNumber
          firstName
          lastName
          typeOfContact
          outstandingBalance
          notOverdueBalance
          overdueOneMonthBalance
          overdueTwoMonthsBalance
          overdueThreeMonthsBalance
          overdueFourMonthsBalance
          overdueFiveMonthsBalance
          overdueSixMonthsBalance
          overdueOverSixMonthsBalance
        }
      }
    }
  }
`;

class ReceivableOutstandingSummaryReportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data,
        })
    }

    render() {
        return (
            <React.Fragment>
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }
                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Receivable Outstanding Summary</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div id="receiveableOutstandingSummaryReportPDF">
                            <div className="print-top-menu">
                                    <div className="logo">
                                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                    </div>
                                    <div className="print" onClick={() => window.print()}>
                                        PRINT
                                    </div>
                            </div>                           
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                cacheConfig={{ use_cache: false }}
                                variables={{
                                    contactId: this.state.data.contact,
                                    typeOfContact: this.state.data.customer_type,
                                    search: this.state.data.search,
                                    dueDate: formatDateISO(this.state.data.dueDate),
                                }}
                                render={({ error, props }) => {
                                if (props) {
                                    let customerType = 'ทั้งหมด';
                                    let contactName = 'ทั้งหมด';

                                    if(this.state.data.contact !== "") {
                                        if(props.reportOutstandingReceivableSummary?.edges[0].node.typeOfContact === "RESIDENTIAL"){
                                            contactName = props.reportOutstandingReceivableSummary.edges[0].node.refNumber
                                            +" "+props.reportOutstandingReceivableSummary.edges[0].node.firstName
                                            +" "+props.reportOutstandingReceivableSummary.edges[0].node.lastName
                                        } else {
                                            contactName = props.reportOutstandingReceivableSummary.edges[0].node.refNumber
                                            +" "+props.reportOutstandingReceivableSummary.edges[0].node.name                                        
                                        }
                                    }

                                    switch (this.state.data.customer_type) {
                                        case "residential":
                                          customerType = "เฉพาะเจ้าของร่วม/สมาชิก";
                                          break;
                                        case "supplier":
                                          customerType = "เฉพาะลูกหนี้";
                                          break;          
                                    }    
                                    
                                    return (
                                        <ReceivableOutstandingSummaryReportPDFRenderTable 
                                            props={props}
                                            dueDate={this.state.data.dueDate}
                                            contactName={contactName}
                                            customerType={customerType}
                                        />
                                    );
                                } else {
                                    return <Loading/>
                                }
                                }}
                            />
                        </div>    
            </React.Fragment>
        )
    }
}

export default ReceivableOutstandingSummaryReportPDF;