/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type allOtherReceivesQueryVariables = {|
  search?: ?string
|};
export type allOtherReceivesQueryResponse = {|
  +allOtherReceive: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +unknownContact: ?string,
        +contact: ?{|
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
      |}
    |}>
  |}
|};
export type allOtherReceivesQuery = {|
  variables: allOtherReceivesQueryVariables,
  response: allOtherReceivesQueryResponse,
|};
*/


/*
query allOtherReceivesQuery(
  $search: String
) {
  allOtherReceive(search: $search) {
    edges {
      node {
        id
        unknownContact
        contact {
          name
          firstName
          lastName
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unknownContact",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "allOtherReceivesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OtherReceiveNodeConnection",
        "kind": "LinkedField",
        "name": "allOtherReceive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OtherReceiveNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OtherReceiveNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "allOtherReceivesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OtherReceiveNodeConnection",
        "kind": "LinkedField",
        "name": "allOtherReceive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OtherReceiveNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OtherReceiveNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d369043c2fc8cc13bc1889b52ad7dff6",
    "id": null,
    "metadata": {},
    "name": "allOtherReceivesQuery",
    "operationKind": "query",
    "text": "query allOtherReceivesQuery(\n  $search: String\n) {\n  allOtherReceive(search: $search) {\n    edges {\n      node {\n        id\n        unknownContact\n        contact {\n          name\n          firstName\n          lastName\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6fd19e77848e3e0b8b85d845fdda9db7';

module.exports = node;
