import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import AssetDepreciateReportTable from "./assetDepreciateReportTable";
import ExportAssetDepreciateReport from "./server_export";
import _ from "lodash";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { format, setDate, setMonth, lastDayOfMonth } from "date-fns";

import Loading from "../../../../libs/loading"
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
  query assetDepreciateReportQuery(
    $search: String
    # $first: Int
    # $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $type: String
    $last_date: DateTime
  ) {
    allAssetReport(
      search: $search
      # first: $first
      # last: $last
      startDate: $start_date
      endDate: $end_date
      type: $type
      lastDate: $last_date
    ) {
      totalCount
      edges {
        node {
          id
          docNumber
          name
          purchaseDate
          costPrice
          calDepreciation
          depreciationRatePercent
          carcassPrice
          saleStatus
          saleDate

          historyPreviousAmount
          depreciationAmount
          historyNextAmount
          calDepreciationDate
          calSchedule
          type
          historicalAmount
        }
      }
    }
  }
`;

class AssetDepreciateReport extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.start_date = setDate(setMonth(new Date(), 0), 1);
    this.state.end_date = lastDayOfMonth(new Date());
    this.state.temp_start_date = setDate(setMonth(new Date(), 0), 1);
    this.state.temp_end_date = lastDayOfMonth(new Date());

    this.state.advance_search = false;
    this.state.search = "";
    this.state.search_input = "";
    this.state.type = "";
    this.state.temp_type = "";
    this.state.last_date = lastDayOfMonth(
      setMonth(
        setDate(setMonth(new Date(), 0), 1),
        setDate(setMonth(new Date(), 0), 1).getMonth() - 1
      )
    );
    this.state.temp_last_date = lastDayOfMonth(
      setMonth(
        setDate(setMonth(new Date(), 0), 1),
        setDate(setMonth(new Date(), 0), 1).getMonth() - 1
      )
    );

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.advanceSearchToggle = this.advanceSearchToggle.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "assetDepreciateReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    if (e.target.name === "temp_start_date") {
      let last_date = lastDayOfMonth(
        setMonth(e.target.value, e.target.value.getMonth() - 1)
      );
      this.setState({ temp_last_date: last_date });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      type: this.state.temp_type,
      last_date: this.state.temp_last_date,
    });
  }

  advanceSearchToggle() {
    this.setState({ advance_search: !this.state.advance_search });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/asset"}
                  LinkText={i18next.t("ReportAsset:Depreciation Details Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_asset_print",
                      }) && <ExportAssetDepreciateReport state={this.state} />}
                    </div>
                  }
                />

                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="form-group form-inline float-right row ml-2">
                        <label className="col-form-label ml-2 mr-2">ถึง</label>
                        <div className="col">
                          <DatePickerAdapter
                            id="temp_end_date"
                            name="temp_end_date"
                            className="form-control"
                            dateFormat="MM/yyyy"
                            popperPlacement={"top"}
                            showMonthYearPicker={true}
                            startDate={this.state.temp_start_date}
                            endDate={this.state.temp_end_date}
                            selected={this.state.temp_end_date}
                            onChange={this.handleChange}
                            required={true}
                            selectsEnd={true}
                            minDate={this.state.start_month}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          id="temp_start_date"
                          name="temp_start_date"
                          className="form-control"
                          dateFormat="MM/yyyy"
                          popperPlacement={"top"}
                          showMonthYearPicker={true}
                          selected={this.state.temp_start_date}
                          onChange={this.handleChange}
                          required={true}
                          startDate={this.state.temp_start_date}
                          endDate={this.state.temp_end_date}
                          selectsStart={true}
                          maxDate={this.state.temp_end_date}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn btn-outline-secondary float-right"
                        onClick={this.advanceSearchToggle}
                      >
                        <img
                          src="/images/icons/filter-icon.png"
                          alt="filter-icon"
                        />
                      </button>
                    </div>
                  </div>

                  {this.state.advance_search && (
                    <div className="row fade-up">
                      <div className="col">
                        <div className="card advance-search">
                          <div className="row">
                            <div className="col-md-4 border-right">
                              <div className="form-group">
                                <label>{i18next.t("ReportAssetDetail:Asset Category")}</label>
                                <select
                                  name="temp_type"
                                  onChange={this.handleChange}
                                  value={this.state.temp_type}
                                  className="form-control dropdown-custom-arrow"
                                >
                                  <option value="">{i18next.t("ReportAssetDetail:All")}</option>
                                  <option value="land">
                                    LAN - {i18next.t("assetSetting:land")}</option>
                                  <option value="building">
                                    B &nbsp;&nbsp;&nbsp;&nbsp;- {i18next.t("assetSetting:building")}</option>
                                  <option value="building_renovation">
                                    BR &nbsp;&nbsp;- {i18next.t("assetSetting:building_renovation")}</option>
                                  <option value="office_decoration">
                                    OD &nbsp;&nbsp;- {i18next.t("assetSetting:office_decoration")}</option>
                                  <option value="office_equipment">
                                    EQ &nbsp;&nbsp;- {i18next.t("assetSetting:office_equipment")}</option>
                                  <option value="tools">
                                    T &nbsp;&nbsp;&nbsp;&nbsp;- {i18next.t("assetSetting:tools")}</option>
                                  <option value="training_equipment">
                                    TE &nbsp;&nbsp;- {i18next.t("assetSetting:training_equipment")}</option>
                                  <option value="vehicle">
                                    V &nbsp;&nbsp;&nbsp;&nbsp;- {i18next.t("assetSetting:vehicle")}</option>
                                  <option value="common_property">
                                    CP &nbsp;&nbsp;- {i18next.t("assetSetting:common_property")}</option>
                                  <option value="other_asset">
                                    OA &nbsp;&nbsp;- {i18next.t("assetSetting:other_asset")}</option>
                                  <option value="intangible_asset">
                                    ITG &nbsp;- {i18next.t("assetSetting:intangible_asset")}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{
                      // first: this.state.first,
                      // last: this.state.last,
                      search: this.state.search,
                      end_date: this.state.end_date,
                      type: this.state.type,
                      last_date: this.state.last_date,
                      start_date: this.state.start_date,
                    }}
                    render={({ error, props }) => {
                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table className="table table-hover">
                                      <thead className="thead-light">
                                        <tr>
                                          <th className="text-center">{i18next.t("ReportAssetDetail:Sequence")}</th>
                                          <th className="text-center">{i18next.t("ReportAssetDetail:Code")}</th>
                                          <th className="text-left">{i18next.t("ReportAssetDetail:Product Name")}</th>
                                          <th className="text-center">
                                            {i18next.t("ReportAssetDetail:Purchase Date")}
                                          </th>
                                          <th className="text-right">
                                            {i18next.t("ReportAssetDetail:Asset Value (THB)")}
                                          </th>
                                          <th className="text-right">
                                            {i18next.t("ReportAssetDetail:Depreciation Rate (%)")}
                                          </th>
                                          <th className="text-right">
                                            {i18next.t("ReportAssetDetail:Accumulated Depreciation Brought Forward")}
                                          </th>
                                          <th className="text-right">
                                            {i18next.t("ReportAssetDetail:Depreciation Expense")} <br />
                                            {i18next.t("ReportAssetDetail:Start")}{" "}
                                            {this.state.start_date &&
                                              format(
                                                this.state.start_date,
                                                "MM-YYYY"
                                              )}{" "}
                                            {i18next.t("ReportAssetDetail:To")}{" "}
                                            {this.state.end_date &&
                                              format(
                                                this.state.end_date,
                                                "MM-YYYY"
                                              )}
                                          </th>
                                          <th className="text-right">
                                            {i18next.t("ReportAssetDetail:Accumulated Depreciation (THB)")}
                                          </th>
                                          <th className="text-center">
                                            {i18next.t("ReportAssetDetail:Disposal Date")}
                                          </th>
                                          <th className="text-center">
                                            {i18next.t("ReportAssetDetail:Disposal Method")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <AssetDepreciateReportTable
                                          props={props}
                                          state={this.state}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row">
                                                    <Pagination
                                                        changePage={this.changePage}
                                                        first={this.state.first}
                                                        last={this.state.last}
                                                        totalCount={props.allAssetReport.totalCount}
                                                    />
                                                </div> */}
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default AssetDepreciateReport;
