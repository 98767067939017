import React, { Component } from 'react';
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import environment from "../../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { Redirect } from 'react-router'
import PayPaymentChannelForm from "../../pay/payPaymentChannelForm";
import { parse } from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import AddWithdrawAmountMutation from "./mutations/addWithdrawAmountMutation"
import voidSetPettyCashMutation from './mutations/voidSetPettyCash';
import PaymentChannel from "../../../libs/paymentChannel";
import i18n from "i18next";
import _ from "lodash"
import i18next from 'i18next';

const getdocNumberNextAndPettyCashSetCreateQuery = graphql`
    query pettyCashSetCreateQuery($id: ID!){
        setPettyCashDocNumberNext
        setPettyCash(id:$id) {
            id
            docNumber
            added
            withdrawer
            description
            withdrawAmount
            issuedDate
            bankAccountTransaction{
                edges{
                    node{
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction{
                edges{
                    node{
                        id
                        price
                        added
                        date
                    }
                }
            }
            chequeTransaction{
                edges{
                    node{
                        id
                        date
                        price
                        chequeNumber
                        bankAccount{
                            id
                            accountName
                            branch
                            bankName
                        }
                    }
                }
            }
        }
        allProjects {
            edges {
                node {
                    periodCreate
                }
            }
       }
    }
`;


const mutation = graphql`
    mutation pettyCashSetCreateMutation($input: CreateSetPettyCashInput!){
        createSetPettyCash(input: $input){
            ok
            warningText
        }
    }`;


class PettyCashSetCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issuedDate: new Date(),
            description: '',
            price: '',
            image_upload: '',
            new_code: 'Loading...',
            withdrawer: '',
            redirectToList: false,
            loading: false,
            withdraw_amount: '',
            bank_account: '',
            cash_transaction: '',
            cheque_transaction: '',

            payChannel: [],
            upload: {},
            totalPay: 0,
            edit: false,
            add_price_limit: false,
            add_withdraw_amount: '',
            date: new Date(),
            can_edit: _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_edit' }),
            can_create: _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_create' }),
            disabled_input: false,
            disabled_input_date: false,

            temp_month_before: '',
            temp_month_after: '',
            period_create: ''

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.refreshPettyCashDocNumberNext = this.refreshPettyCashDocNumberNext.bind(this);
        this.updatePayChannel = this.updatePayChannel.bind(this);
        this.changeStatusButton = this.changeStatusButton.bind(this);
        this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
        this.onUpdateError = this.onUpdateError.bind(this);
        this.pettySetvoid = this.pettySetvoid.bind(this);
        this.onVoidSuccess = this.onVoidSuccess.bind(this);
        this.onVoidError = this.onVoidError.bind(this);
    }

    componentWillMount() {
        this.refreshPettyCashDocNumberNext();
        let check_perm = false;
        if (this.props.match.params.id) {
            // edit
            check_perm = !_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_edit' })
        }
        else {
            check_perm = !_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_create' })
        }
        this.setState({ disabled_input: check_perm })
    }


    refreshPettyCashDocNumberNext() {
        fetchQuery(environment, getdocNumberNextAndPettyCashSetCreateQuery, { id: this.props.match.params.id || "" }).then(data => {
            if (data.setPettyCash) {
                this.setState({
                    new_code: data.setPettyCash.docNumber,
                    issuedDate: parse(data.setPettyCash.issuedDate ? data.setPettyCash.issuedDate : data.setPettyCash.added),
                    withdrawer: data.setPettyCash.withdrawer,
                    description: data.setPettyCash.description,
                    withdraw_amount: data.setPettyCash.withdrawAmount,
                    bank_account: data.setPettyCash.bankAccountTransaction.edges,
                    cash_transaction: data.setPettyCash.cashTransaction.edges,
                    cheque_transaction: data.setPettyCash.chequeTransaction.edges,
                    edit: true,
                    disabled_input_date: true,                    
                })

            } else {
                if (data.setPettyCashDocNumberNext) {
                    this.setState({ new_code: data.setPettyCashDocNumberNext, edit: false })
                }
            }
            if (data) {
                this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
            }
        });
    }


    calPeriodMouth(val) {
        let month_before = new Date();
        let month_after = new Date();

        month_before.setMonth(month_before.getMonth() - parseInt(val));
        month_after.setMonth(month_after.getMonth() + parseInt(val));

        this.setState({
            temp_month_before: month_before,
            temp_month_after: month_after,
        })
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }


    onSubmit(e) {
        e.preventDefault();
        if (this.state.edit) {
            // edit
            if (this.state.add_price_limit && parseFloat(this.state.totalPay) !== parseFloat(this.state.add_withdraw_amount)) {
                Swal.fire(
                    i18next.t("pettyCashList:The amount is incorrect."),
                    i18next.t("pettyCashList:Please enter the withdrawal amount equal to")+ numberWithComma(this.state.add_withdraw_amount) + i18next.t("payCreateAccrued:Baht"),
                    'warning');
            } else {

                this.setState({ loading: true });
                let variables = {
                    input: {
                        id: this.props.match.params.id,
                        price: parseFloat(this.state.add_withdraw_amount),
                        paymentChannel: JSON.stringify(this.state.payChannel),
                        date: this.state.date,
                        description: this.state.description,
                        withdrawer: this.state.withdrawer,
                        addWithdrawAmount: this.state.add_price_limit,
                    }
                };
                let uploadables = this.state.upload;
                AddWithdrawAmountMutation(variables, uploadables, this.onUpdateSuccess, this.onUpdateError)

            }
        } else {
            // create
            this.setState({ loading: true });
            let variables = {
                input: {
                    description: this.state.description,
                    price: this.state.totalPay,
                    paymentChannel: JSON.stringify(this.state.payChannel),
                    withdrawer: this.state.withdrawer,
                    issuedDate: this.state.issuedDate,
                }
            };
            let uploadables = this.state.upload;
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        this.setState({ loading: false });
                        if (response.createSetPettyCash.ok) {
                            Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                this.setState({ redirectToList: true });
                            });
                        } else {
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createSetPettyCash.warningText, 'warning');
                        }

                    },
                    onError: (err) => {
                        this.setState({ loading: false });
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                    },
                },
            );
        }
    }

    updatePayChannel(channel_list) {
        let totalPay = 0;
        let upload = {};
        channel_list.forEach(channel => {
            if (channel.slug === "small-change" || channel.slug === "receipt-deposit") {
                totalPay -= parseFloat(channel.pay);
            } else {
                totalPay += parseFloat(channel.pay);
            }
            if (channel.image) {
                Object.assign(upload, { [channel.slug]: channel.image });
            }
        });
        this.setState({
            payChannel: channel_list,
            totalPay: totalPay,
            upload: upload
        });
    }

    changeStatusButton() {
        if (this.state.add_price_limit) {
            this.setState({ add_withdraw_amount: '', date: new Date() })
        }

        this.setState({ add_price_limit: !this.state.add_price_limit })
    }

    onUpdateSuccess(response) {
        this.setState({ loading: false });
        if (response) {
            Swal.fire(i18n.t("paymentgateway:Save successfully!"), '', 'success').then(() => {
                this.setState({ redirectToList: true });
            });
        } else {
            Swal.fire(i18n.t("paymentgateway:Save unsuccessfully"), i18next.t("Allaction:Please try again."), 'warning');
        }
    }

    onUpdateError(response) {
        this.setState({ loading: false });
        Swal.fire(i18n.t("paymentgateway:Save unsuccessfully"), '', 'warning');
    }

    pettySetvoid() {
        // ยกเลิกจ่ายเงินสดย่อย
        Swal.fire({
            title: i18next.t("pettyCashList:Are you sure to cancel this petty cash?"),
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("pettyCashList:Confirm"),
            cancelButtonText: i18next.t("pettyCashList:Cancel")
        }).then(async (result) => {
            if (result.value) {

                let { value: void_remark } = await Swal.fire({
                    title: i18next.t("pettyCashList:Note"),
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: i18next.t("pettyCashList:Confirm"),
                    cancelButtonText: i18next.t("pettyCashList:Cancel"),
                    inputValidator: (value) => {
                        if (!value) {
                            return i18next.t("pettyCashList:Please enter reason to cancel")
                        }
                    }
                });
                if (void_remark) {
                    let variables = {
                        input: {
                            id: this.props.match.params.id,
                            voidRemark: void_remark
                        }
                    };

                    voidSetPettyCashMutation(variables, this.onVoidSuccess, this.onVoidError)
                }
            } else {
                return;
            }
        })
    }

    onVoidSuccess(response) {
        if (response) {
            if (response.voidSetPettyCash.ok) {
                Swal.fire(
                    i18next.t("Allaction:Canceled Successfully"), '', 'success'
                ).then(() => {
                    this.setState({ redirectToList: true });
                });
            }
            else {
                Swal.fire(
                    i18next.t("Allaction:Canceled Unsuccessful"), response.voidSetPettyCash.warningText, 'warning'
                )
            }
        }
    }

    onVoidError(response) {
        Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), '', 'warning');
    }


    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/expense/petty_cash/list/set" />
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/expense/petty_cash/list/set">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t =>
                                                <React.Fragment>
                                                    <span>{t('accounting:add_petty_cash')+" "+"(SPC)"}</span>
                                                </React.Fragment>

                                        }
                                    </Translation>

                                </h3>
                            </div>


                            {this.state.edit &&
                                <div className="col text-right">

                                    <button
                                        className="btn btn-danger add mr-3 mb-4"
                                        disabled={this.state.loading}
                                        onClick={this.pettySetvoid}
                                    >
                                        {this.state.loading && (
                                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                        )}
                                        {i18n.t("pettyCash:cancel_petty_cash")}
                                    </button>
                                </div>
                            }

                        </div>
                        <div className="content-inner">
                            <form id="form-add" onSubmit={this.onSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <label htmlFor="docNumber"
                                                className="col-2 col-form-label">
                                                <strong>{i18n.t("pettyCash:number")}</strong>
                                            </label>
                                            <div className="col-5">
                                                <input type="text" className="form-control" id="docNumber"
                                                    value={this.state.new_code}
                                                    name="docNumber" disabled />
                                            </div>
                                            <div className="col-1" />
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountName"
                                                className="col-2 col-form-label">
                                                <strong>{i18n.t("pettyCash:date")}</strong>
                                            </label>
                                            <div className="col-5">
                                                <div className="input-group">
                                                    {/* <DatePickerAdapter
                                                        name="issuedDate" className="form-control"
                                                        selected={this.state.issuedDate}
                                                        onChange={this.handleInputChange}
                                                        disable={this.state.disabled_input}
                                                    /> */}
                                                    {
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                                                            <DatePickerAdapter
                                                                name="issuedDate"
                                                                id="issuedDate"
                                                                className="form-control"
                                                                selected={this.state.issuedDate}
                                                                minDate={this.state.temp_month_before}
                                                                // maxDate={this.state.temp_month_after}
                                                                onChange={this.handleInputChange}
                                                                required={true}
                                                            /> :
                                                            <DatePickerAdapter
                                                                name="issuedDate" className="form-control"
                                                                selected={this.state.issuedDate}
                                                                onChange={this.handleInputChange}
                                                                disable={this.state.disabled_input_date}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-1" />
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="withdrawer"
                                                className="col-2 col-form-label">
                                                <strong>{i18n.t("pettyCash:career")}</strong>
                                            </label>
                                            <div className="col-5">
                                                <input type="text" className="form-control" id="withdrawer"
                                                    value={this.state.withdrawer}
                                                    name="withdrawer" onChange={this.handleInputChange}
                                                    disabled={this.state.disabled_input}
                                                    required />
                                            </div>
                                            {!this.state.edit &&
                                                <label htmlFor="date"
                                                    className="col-4 col-form-label text-muted">{i18n.t("pettyCash:custodian")}</label>
                                            }

                                            <div className="col-1" />
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountNum"
                                                className="col-2 col-form-label">
                                                <strong>{i18n.t("pettyCash:description")}</strong>
                                            </label>
                                            <div className="col-5">
                                                <textarea className="form-control" maxLength={256}
                                                    rows="3" name="description" value={this.state.description}
                                                    disabled={this.state.disabled_input}
                                                    onChange={this.handleInputChange}
                                                    required />
                                            </div>
                                            <div className="col-1" />
                                        </div>
                                        {this.state.edit && this.state.can_edit &&
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <button type="button" className="btn btn-secondary add"
                                                        onClick={this.changeStatusButton}>
                                                        {i18n.t("pettyCash:increase")}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        {this.state.add_price_limit &&
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col">
                                                        <hr />
                                                        <div className="row mt-4">
                                                            <label htmlFor="withdraw_amount"
                                                                className="col-form-label col-2">
                                                                <strong>{i18n.t("pettyCash:withdraw_amount")}</strong>
                                                            </label>
                                                            <div className="col-3">
                                                                <input type="number"
                                                                    className="form-control text-right"
                                                                    value={this.state.withdraw_amount}
                                                                    name="withdraw_amount" disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <label htmlFor="withdraw_amount"
                                                        className="col-form-label col-2">
                                                        <strong>{i18n.t("pettyCash:additional_amount")}</strong>
                                                    </label>
                                                    <div className="col-3">
                                                        <input type="number" className="form-control text-right"
                                                            step="0.01"
                                                            value={this.state.add_withdraw_amount}
                                                            name="add_withdraw_amount"
                                                            required={true}
                                                            onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <label htmlFor="withdraw_amount"
                                                        className="col-form-label col-2">
                                                        <strong>{i18n.t("pettyCash:total")}</strong>
                                                    </label>
                                                    <div className="col-3 text-right">
                                                        <label htmlFor="withdraw_amount"
                                                            className="col-form-label">
                                                            <strong>
                                                                {numberWithComma(parseFloat(this.state.withdraw_amount) + parseFloat(this.state.add_withdraw_amount))}
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <label htmlFor="date"
                                                        className="col-form-label col-2">
                                                        <strong>{i18n.t("pettyCash:date")}</strong>
                                                    </label>
                                                    <div className="col-3">
                                                        <div className="input-group">
                                                            {
                                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                                                                <DatePickerAdapter
                                                                    name="date" className="form-control"
                                                                    selected={this.state.date}
                                                                    required={true}
                                                                    onChange={this.handleInputChange}
                                                                    minDate={this.state.temp_month_before}/> :
                                                                <DatePickerAdapter
                                                                    name="date" className="form-control"
                                                                    selected={this.state.date}
                                                                    required={true}
                                                                    onChange={this.handleInputChange}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <PayPaymentChannelForm updatePayChannel={this.updatePayChannel}
                                                    payment_type={'petty_cash'}
                                                    single_payment={true}
                                                    not_other_channel={true} issued_date={this.state.issuedDate} />


                                            </React.Fragment>
                                        }
                                        {this.state.edit && this.state.can_edit &&
                                            <div className="row mt-4">
                                                <div className="col-2" />
                                                <div className="col text-right">
                                                    <div className="btn-group mr-2">
                                                        <Link to="/accounting/expense/petty_cash/list/set">
                                                            <button type="button" className="btn btn-secondary add">
                                                                {i18n.t("pettyCash:cancel_order")}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div className="btn-group mr-2">
                                                        <button type="submit" className="btn btn-primary add"
                                                            form="form-add">
                                                            {i18n.t("pettyCash:save")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {!this.state.edit &&
                                    <PayPaymentChannelForm updatePayChannel={this.updatePayChannel}
                                        payment_type={'petty_cash'}
                                        single_payment={true} not_other_channel={true} issued_date={this.state.issuedDate} />
                                }

                            </form>

                            {this.state.edit &&
                                <PaymentChannel
                                    cashTransaction={this.state.cash_transaction}
                                    chequeTransaction={this.state.cheque_transaction}
                                    allBankAccountTransaction={this.state.bank_account}
                                />
                            }
                            {!this.state.edit &&
                                <div className="row mt-4">
                                    <div className="col-12 text-right">
                                        <div className="btn-group mr-2">
                                            <Link to="/accounting/expense/petty_cash/list/set">
                                                <button type="button" className="btn btn-secondary add">
                                                    {i18n.t("pettyCash:cancel")}
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="btn-group mr-2">
                                            <button type="submit" className="btn btn-primary add"
                                                form="form-add"
                                                disabled={this.state.loading}>
                                                {this.state.loading &&
                                                    <span
                                                        className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                {i18n.t("pettyCash:create_petty_cash")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default PettyCashSetCreate;
