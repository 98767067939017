/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type emergencyListQueryVariables = {||};
export type emergencyListQueryResponse = {|
  +emergencyCallViewer: ?{|
    +allEmergencyCall: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +callOutContactNumber: string,
          +contactNumber: string,
          +group: ?{|
            +id: string,
            +name: string,
          |},
        |}
      |}>
    |}
  |},
  +emergencyCallGroupViewer: ?{|
    +allEmergencyCallGroup: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |},
|};
export type emergencyListQuery = {|
  variables: emergencyListQueryVariables,
  response: emergencyListQueryResponse,
|};
*/


/*
query emergencyListQuery {
  emergencyCallViewer {
    allEmergencyCall {
      edges {
        node {
          id
          name
          callOutContactNumber
          contactNumber
          group {
            id
            name
          }
        }
      }
    }
    id
  }
  emergencyCallGroupViewer {
    allEmergencyCallGroup {
      edges {
        node {
          id
          name
        }
      }
      totalCount
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "EmergencyCallNodeConnection",
  "kind": "LinkedField",
  "name": "allEmergencyCall",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EmergencyCallNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmergencyCallNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "callOutContactNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EmergencyCallGroupNode",
              "kind": "LinkedField",
              "name": "group",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "EmergencyCallGroupNodeConnection",
  "kind": "LinkedField",
  "name": "allEmergencyCallGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EmergencyCallGroupNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmergencyCallGroupNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "emergencyListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmergencyCallViewer",
        "kind": "LinkedField",
        "name": "emergencyCallViewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmergencyCallGroupViewer",
        "kind": "LinkedField",
        "name": "emergencyCallGroupViewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "emergencyListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmergencyCallViewer",
        "kind": "LinkedField",
        "name": "emergencyCallViewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmergencyCallGroupViewer",
        "kind": "LinkedField",
        "name": "emergencyCallGroupViewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "690084fb2689d7c363302414cf0fcffc",
    "id": null,
    "metadata": {},
    "name": "emergencyListQuery",
    "operationKind": "query",
    "text": "query emergencyListQuery {\n  emergencyCallViewer {\n    allEmergencyCall {\n      edges {\n        node {\n          id\n          name\n          callOutContactNumber\n          contactNumber\n          group {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n  emergencyCallGroupViewer {\n    allEmergencyCallGroup {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a56ce05aa00c776d3cf9736afd9377e2';

module.exports = node;
