import React, { Component } from 'react';
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Wrapper from "../../../components/wrapper";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import BackButtonIcon from '../../../components/BackBtn/indexBack';

class Dashboard extends Component {

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon 
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t("DashboardPayable:Payable Report")}
                                />

                                <div className="content-inner">
                                    <div className="row fade-up">
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">1. {i18next.t("DashboardPayable:Expense Configuration Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/purchase"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">2. {i18next.t("DashboardPayable:Summary Payment Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/pay_summary"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">3. {i18next.t("DashboardPayable:Detailed Outstanding Payables Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/payable_outstanding"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row fade-up mt-3">
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">4. {i18next.t("DashboardPayable:Summary Outstanding Payables Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/payable_outstanding_summary"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">5. {i18next.t("DashboardPayable:Summary Report of All Payables by Type")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/payable_classified"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">6. {i18next.t("DashboardPayable:Payables Movement Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link
                                                        to="/accounting/report/account-payable/payable_movement/select-customer"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row fade-up mt-3">
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">7. {i18next.t("DashboardPayable:Receive Invoice Reduction Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/accept_credit_note"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">8. {i18next.t("DashboardPayable:Advance Payment Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/prepaid_deposit"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">9. {i18next.t("DashboardPayable:Advance Payment Movement Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/flow_of_prepaid_deposit/select-customer"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row fade-up mt-3">
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">10. {i18next.t("DashboardPayable:Petty Cash Movement Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/petty_cash"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">11. {i18next.t("DashboardPayable:Advance Cash Movement Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/advance"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xl-4 mb-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">12. {i18next.t("DashboardPayable:Miscellaneous Expense Record Report")}</h5>
                                                </div>
                                                <div className="card-footer bg-transparent">
                                                    <Link to="/accounting/report/account-payable/other_expense"
                                                        className="btn btn-primary btn-sm">{i18next.t("DashboardPayable:Views")}</Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>
        );
    }
}

export default Dashboard;
