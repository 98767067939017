import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import { getTypeProject } from "../../libs/getTypeOfProject";
import "../styles/modify_print.scss"
// import "../styles/receive.scss"
import _ from "lodash";
import getNameResidential from "../../libs/getNameResidential";
import i18next from 'i18next';
import i18n from 'i18next'
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';
const ThaiBaht = require('thai-baht-text');

class Pay extends Component {

    constructor(props) {
        super(props);

        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];

        this.state = {
            bangkokCode: bangkokCode,
            sum_wht_rate: 0,
            diff: 0,
            isOther: false,
            languages: "th",
        };
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.cutString = this.cutString.bind(this);
        this.isOther = this.isOther.bind(this)
    }
    componentWillMount() {
        i18n.changeLanguage(this.props.languages);
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
        })
        let sum_wht = this.props.payRecord.reduce(function (total, obj) {
            return total + parseFloat(obj.node.whtAmount || 0)
        }, 0);
        this.setState({ sum_wht_rate: sum_wht });
        this.isOther()
        this.setPage()
    }

    setPage() {
        setTimeout(() => {
            let page = $('.' + this.props.pageNumber);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height() ? page.find('.footer').height() : 0;
            let page_height = 1125 - 150;
            // let page_height = 1125 - 200;


            // let tbody = page.find('tbody').height()
            // let diff = 430 - tbody


            let diff = page_height - (head + detail + invoice + signature) - 20; //for diff blank between footer
            $('.' + this.props.pageNumber + ' table').css({ borderBottom: "1px solid" });
            if (this.props.current_page !== this.props.end_page) {
                $('.' + this.props.pageNumber + ' tbody tr:last-child td').css({ paddingBottom: "35px" });
            } else {
                $('.' + this.props.pageNumber + ' tbody tr:last-child td').css({ paddingBottom: diff });
            }
            this.setState({ diff: diff });
        }, 200);
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }
    getPrefix(type, code) {
        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        }
        else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        }
        else if (type === "province" && this.isBangkok(code)) {
            return ""
        }
        else if (type === "province") {
            return "จ."
        }
        else if (type === "district") {
            return "ต."
        }
        else if (type === "city") {
            return "อ."
        }
    }

    getValueAndType(transaction, path) {
        let list_fields = ["docNumber", "issuedDate"];
        if (transaction.node.purchaseRecord) {
            if (list_fields.includes(path)) {
                path = "purchaseRecordGroup." + path;
            }
            return _.get(transaction.node.purchaseRecord, path, i18next.t("Allaction:Not found"))
        } else {
            if (path === "unitItems") {
                return 1
            }
            return _.get(transaction.node, path, i18next.t("Allaction:Not found"))
        }
    }

    paymentChannel() {
        let cash_text = [];
        let bank_detail = [{ bankName2: "", accountNumber: "", price: 0 }]
        let cheque_detail = [{ chequeNumber: "", bankName: "", branch: "", price: 0 }]
        let channel = {};
        let pay_detail = {};
        let other_detail = [];
        if (this.props.payRecordGroup.cashTransaction.edges.length) {
            let cash = this.props.payRecordGroup.cashTransaction.edges
            let price_cash = 0;
            cash.map((val) => {
                price_cash = price_cash + val.node.price
                return null
            })
            cash_text.push(" จำนวน " + numberWithComma(price_cash, "0.00") + " บาท ")
            channel['cash'] = true

        }

        if (this.props.payRecordGroup.bankAccountTransaction.edges.length) {
            let bank = this.props.payRecordGroup.bankAccountTransaction.edges;
            bank.map((val, index) => {
                let find_index = bank_detail.findIndex((i) => i?.bankName2 === val.node.bankAccount.bankName && i?.accountNumber === val.node.bankAccount.accountNumber)
                if (find_index !== -1) {
                    let new_price = val.node.price + bank_detail[find_index].price
                    bank_detail[find_index].price = new_price
                } else {
                    bank_detail[index] = { bankName2: val.node.bankAccount.bankName, accountNumber: val.node.bankAccount.accountNumber, price: val.node.price, date: val.node.date }
                }
                return null
            })
            channel['bank'] = true
        }
        if (this.props.payRecordGroup.chequeTransaction.edges.length) {
            let cheque = this.props.payRecordGroup.chequeTransaction.edges;
            cheque.map((val, index) => {
                let find_index = cheque_detail.findIndex((i) => i?.bankName === val.node.bankAccount.bankName && i?.chequeNumber === val.node.chequeNumber)
                if (find_index !== -1) {
                    let new_price = cheque_detail[find_index].price + val.node.price
                    cheque_detail[find_index].price = new_price
                } else {
                    cheque_detail[index] = { chequeNumber: val.node.chequeNumber, bankName: val.node.bankAccount.bankName, branch: val.node.bankAccount.branch, price: val.node.price, date: val.node.date }
                }
                return null
            })
            channel['cheque'] = true

        }

        //if PP get paydetail from payType
        if (this.props.paymentChannelDetail?.payType.length > 0 && this.props.payRecordGroup.status === "DRAFT") {
            pay_detail = this.cutString(this.props.paymentChannelDetail.payType)
            if (pay_detail.payTransactionCash.length > 0) {
                channel['cash'] = true;
                cash_text.push(" จำนวน " + numberWithComma(pay_detail.payTransactionCash[0].price, "0.00") + " บาท ")
            }
            if (pay_detail.payTransactionBank.length > 0) {
                let bank = pay_detail.payTransactionBank;
                bank.forEach((val, index) => {
                    let find_index = bank_detail.findIndex((i) => i?.bankName2 === val.bankName && i?.accountNumber === val.bankAccount)
                    if (find_index !== -1) {
                        let new_price = parseFloat(val.price) + parseFloat(bank_detail[find_index].price)
                        bank_detail[find_index].price = new_price
                    } else {
                        bank_detail[index] = { bankName2: val.bankName, accountNumber: val.bankAccount, price: parseFloat(val.price) }
                    }
                })
                channel['bank'] = true
            }
            if (pay_detail.payTransactionCheque.length > 0) {
                let cheque = pay_detail.payTransactionCheque;
                cheque.forEach((val, index) => {
                    let find_index = cheque_detail.findIndex((i) => i?.bankName === val.bankName && i?.chequeNumber === val.chequeNumber)
                    if (find_index !== -1) {
                        let new_price = parseFloat(cheque_detail[find_index].price) + parseFloat(val.price)
                        cheque_detail[find_index].price = new_price
                    } else {
                        cheque_detail[index] = { chequeNumber: val.chequeNumber, bankName: val.bankName, branch: val.branch, price: parseFloat(val.price) }
                    }
                })
                channel['cheque'] = true
            }
            if (pay_detail.payTransactionOther.length > 0) {
                channel['other'] = true;
                other_detail = pay_detail.payTransactionOther;
            }

        }

        return { channel, cash_text, bank_detail, cheque_detail, other_detail }
    }

    cutString(payType) {
        let payTransactionBank = []
        let payTransactionCash = []
        let payTransactionCheque = []
        let payTransactionOther = []
        let total = 0

        payType.forEach((value) => {
            if (RegExp('ธนาคาร').test(value)) {
                if (RegExp('เลขที่เช็ค').test(value)) {
                    let transaction = value.split(",")
                    payTransactionCheque.push({ type: 'cheque', bankName: transaction[0].split(":")[1].replace("ธนาคารธนาคาร", "ธนาคาร"), bankAccount: transaction[1].split(":")[1], chequeNumber: transaction[2].split(":")[1], price: transaction[3].split(":")[1].replace("}", "") })
                } else {
                    let transaction = value.split(",")
                    payTransactionBank.push({ type: 'bank', bankName: transaction[0].split(":")[1].replace("ธนาคารธนาคาร", "ธนาคาร"), bankAccount: transaction[1].split(":")[1], price: transaction[2].split(":")[1].replace("}", "") })
                }
            } else if (RegExp('เงินสด').test(value)) {
                let transaction = value.split(",")
                total += parseFloat(transaction[1].split(":")[1].replace("}", ""))
            } else {
                let transaction = value.split(",")
                payTransactionOther.push({ type: 'other', detail: transaction[0].split(":")[1], price: transaction[1].split(":")[1].replace("}", "") })
            }
        })
        total !== 0 && payTransactionCash.push({ type: 'cash', price: total })

        return { payTransactionBank, payTransactionCheque, payTransactionOther, payTransactionCash }
    }

    isOther() {
        this.props.allAccountRecordGroup.edges.length > 0 &&
            this.props.allAccountRecordGroup.edges.forEach((Other) => (
                Other.node.accountRecord.edges.forEach((accountRecord) => {
                    if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                        accountRecord.node.chartOfAccountCode.chartOfAccountCode === "2125-03" ||
                        accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07") {
                        this.setState({
                            isOther: true
                        })
                    }
                })

            ))
    }

    render() {
        let paymentChannel = this.paymentChannel()
        let getType = getTypeProject(this.props.selfProject.typeOfProject)

        return (
            <div id="style-modify" className={"print-page-a4 " + this.props.pageNumber} style={{ position: 'relative' }}>
                <div className="subpage" style={{ overflow: 'hidden' }}>
                    <div className="head">
                        <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                            <div style={{ minWidth: 100, minHeight: 100 }}>
                                <img src={this.props.selfProject.logo} alt="silverman" />
                            </div>
                            {this.props.selfProject.id === 'UHJvamVjdE5vZGU6MzQz' ?
                                <div>
                                    <strong>{this.state.languages === "en" ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>
                                    <div>{this.state.languages === "en" ? (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} <br /> โทร {this.props.selfProject.juristicContactNumber || '-'}</div>
                                    <div>เลขประจำตัวผู้เสียภาษี {this.props.selfProject.taxIdNumber || '-'}</div>
                                </div>
                                :
                                <div>
                                    <strong>{this.state.languages === "en" ? this.props.selfProject.nameEn : this.props.selfProject.name}</strong>
                                    <div>{this.state.languages === "en" ? (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} โทร {this.props.selfProject.juristicContactNumber || '-'}</div>
                                    <div>เลขประจำตัวผู้เสียภาษี {this.props.selfProject.taxIdNumber || '-'}</div>
                                </div>

                            }

                        </div>

                        <div className={this.props.payRecordGroup.contact.typeOfContact !== 'RESIDENTIAL' ? "document-name customer-vat" : "document-name"}>
                            {
                                this.props.payRecordGroup.contact.typeOfContact !== 'RESIDENTIAL' &&
                                <React.Fragment>
                                    <div className="title">{this.props.payRecordGroup.status.includes("DRAFT") ? "ใบเตรียมจ่าย" : "ใบสำคัญจ่าย"}</div>
                                    <div className="title">{this.props.payRecordGroup.status.includes("DRAFT") ? "Prepare Voucher" : "Payment Voucher"}</div>
                                </React.Fragment>
                            }

                            {
                                this.props.check_type === "manuscript" ?
                                    <div className="title small">(ต้นฉบับ/Original)</div>
                                    :
                                    <div className="title small">(สำเนา/Copy)</div>
                            }
                        </div>
                    </div>

                    <div className="detail">
                        <div className="customer-detail">
                            <strong>รหัสลูกค้า/ID:</strong> {this.props.payRecordGroup.contact.refNumber}<br />
                            {this.props.payRecordGroup.contact.typeOfContact === 'RESIDENTIAL' ?
                                <React.Fragment>
                                    <strong>ชื่อลูกค้า/Customer:</strong>
                                    {getNameResidential(this.props.payRecordGroup.contact.firstName, this.props.payRecordGroup.contact.lastName)}
                                    <br />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <strong>ชื่อลูกค้า/Customer:</strong> {this.props.payRecordGroup.contact.name} <br />
                                </React.Fragment>
                            }
                            <strong>ที่อยู่/Address:</strong>

                            <div className="customer-detail address-space" style={{ width: "63% !important" }}>
                                {/* {this.props.payRecordGroup.contact.registeredAddress + " "}
                                {this.props.payRecordGroup.contact.registeredDistrict && this.getPrefix("district", this.props.payRecordGroup.contact.registeredPostalCode) + this.props.payRecordGroup.contact.registeredDistrict + " "}
                                {this.props.payRecordGroup.contact.registeredCity && this.getPrefix("city", this.props.payRecordGroup.contact.registeredPostalCode) + this.props.payRecordGroup.contact.registeredCity + " "}
                                {this.props.payRecordGroup.contact.registeredProvince && this.getPrefix("province", this.props.payRecordGroup.contact.registeredPostalCode) + this.props.payRecordGroup.contact.registeredProvince + " "}
                                {this.props.payRecordGroup.contact.registeredPostalCode + " "} */}


                                {/* BTA685 new rq */}
                                {this.props.payRecordGroup?.address + " "}
                                {this.props.payRecordGroup?.district && this.getPrefix("district", this.props.payRecordGroup.postalCode) + this.props.payRecordGroup.district + " "}
                                {this.props.payRecordGroup?.city && this.getPrefix("city", this.props.payRecordGroup.postalCode) + this.props.payRecordGroup.city + " "}
                                {this.props.payRecordGroup?.province && this.getPrefix("province", this.props.payRecordGroup.postalCode) + this.props.payRecordGroup.province + " "}
                                {this.props.payRecordGroup?.postalCode + " "}

                                {
                                    (this.props.payRecordGroup.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.payRecordGroup.contact.typeOfSupplier === "COMPANY") &&
                                    <React.Fragment>
                                        {
                                            this.props.payRecordGroup.contact.typeOfCompany === "HEADQUARTERS" ?
                                                "สำนักงานใหญ่"
                                                : this.props.payRecordGroup.contact.typeOfCompany === "BRANCH" &&
                                                "สาขา " + this.props.payRecordGroup.contact.nameBranch
                                        }
                                    </React.Fragment>
                                }
                            </div>
                            <br />
                            <div>
                                {this.props.payRecordGroup.contact.typeOfContact !== 'RESIDENTIAL' &&
                                    <React.Fragment>
                                        <p className='mb-0'><strong>เลขผู้เสียภาษี/Tax ID:</strong> {this.props.payRecordGroup.contact.taxIdNumber}</p>
                                        <p className='mb-0'><strong>ผู้ติดต่อ/Attention:</strong> {this.props.payRecordGroup.contact.firstName} {this.props.payRecordGroup.contact.lastName}</p>
                                        {this.props.payRecordGroup.contact.phone && <p className='mb-0'><strong>เบอร์โทรผู้ติดต่อ:</strong> {this.props.payRecordGroup.contact.phone}</p>}
                                        <p className='mb-0'>
                                            <strong>เลขที่ใบกำกับภาษี:</strong>
                                            {this.props.payRecordGroup.refNumber || "-"}
                                            <span className="tax-invoice-date">{this.props.payRecordGroup.refNumber ? 'ลวท.' + format(this.props.payRecordGroup.refPayDate, 'DD/MM/YYYY', { locale: thLocale })
                                                :
                                                this.props.payRecordGroup.refNumber ? 'ลวท.' + format(this.props.payRecordGroup.refPayDate, 'DD/MM/YYYY', { locale: thLocale })
                                                    : null}</span>
                                        </p>


                                        {/* <strong>เลขผู้เสียภาษี/Tax ID:</strong> {this.props.payRecordGroup.contact.taxIdNumber} <br /> */}
                                        {/* <strong>ผู้ติดต่อ/Attention:</strong> {this.props.payRecordGroup.contact.firstName} {this.props.payRecordGroup.contact.lastName} */}
                                        {/* {
                                            this.props.payRecordGroup.contact.phone &&
                                            <React.Fragment>
                                                <strong>เบอร์โทรผู้ติดต่อ:</strong> {this.props.payRecordGroup.contact.phone} <br/>
                                            </React.Fragment>
                                        } */}

                                        {/* &emsp;{this.props.payRecordGroup.contact.phone && "T: " + this.props.payRecordGroup.contact.phone}&emsp;{this.props.payRecordGroup.contact.email && "E: " + this.props.payRecordGroup.contact.email} <br /> */}
                                        {/* &emsp;{this.props.payRecordGroup.contact.phone && "T: " + this.props.payRecordGroup.contact.phone}&emsp;{this.props.payRecordGroup.contact.email && "E: " + this.props.payRecordGroup.contact.email} <br /> */}
                                        {/* <strong>เลขที่ใบกำกับภาษี:</strong> {this.props.payRecordGroup.refNumber || "-"} */}
                                        {/* <span className="tax-invoice-date">{this.props.payRecordGroup.refNumber ? 'ลวท.' + format(this.props.payRecordGroup.refPayDate, 'DD/MM/YYYY', { locale: thLocale })
                                            :
                                            this.props.payRecordGroup.refNumber ? 'ลวท.' + format(this.props.payRecordGroup.refPayDate, 'DD/MM/YYYY', { locale: thLocale })
                                                : null}</span> */}
                                    </React.Fragment>
                                }

                            </div>

                            {this.props.payRecordGroup.contact.firstName &&
                                this.props.payRecordGroup.contact.typeOfSupplier !== "PUBLIC_COMPANY" &&
                                this.props.payRecordGroup.contact.typeOfSupplier !== "COMPANY" &&
                                this.props.payRecordGroup.contact.firstName.substring(0, 6) === "บริษัท" && !this.props.payRecordGroup.contact.lastName &&
                                <React.Fragment>
                                    <strong>เลขผู้เสียภาษี/Tax
                                        ID:</strong> {this.props.payRecordGroup.contact.taxIdNumber} <br />
                                </React.Fragment>
                            }
                            <br />
                        </div>

                        <div
                            className={this.props.payRecordGroup.contact.typeOfContact === 'RESIDENTIAL' ? "document-detail" : "document-detail detail-outsider"} style={{ width: "37%" }}>
                            <strong
                                className={this.props.payRecordGroup.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider" : ''}>เลขที่/No:</strong> {this.props.payRecordGroup.docNumber}
                            <br />
                            <strong
                                className={this.props.payRecordGroup.contact.typeOfContact !== 'RESIDENTIAL' ? "outsider" : ''}>วันที่/Date:</strong> {format(this.props.payRecordGroup.refPayExpenseDate || this.props.payRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                            {this.props.payRecordGroup.contact.typeOfContact === 'RESIDENTIAL' &&
                                <React.Fragment>
                                    <strong>{getType === "highType" ? "เลขที่ห้องชุด/Room No.:" : "บ้านเลขที่/House No."}</strong> {this.props.payRecordGroup.contact.name} &nbsp;
                                    <br />
                                    <strong>พื้นที่/Space:</strong> {numberWithComma(this.props.payRecordGroup.contact.residential.size)} {this.props.selfProject.typeOfProject === "CONDOMINIUM" ? "ตร.ม." : "ตร.ว."}
                                    <br />
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className="text-center">
                                        ลำดับ <br />
                                        No.
                                    </th>
                                    {this.props.payRecordGroup.status.includes("DRAFT") ?
                                        <th className="text-center" style={{ width: '100px' }}>
                                            เลขใบตั้งค่าใช้จ่าย <br />
                                            Ref. No.
                                        </th> :
                                        <th className="text-center" style={{ width: '100px' }}>
                                            เลขใบเตรียมจ่าย <br />
                                            Ref. No.
                                        </th>
                                    }
                                    <th className="text-center">
                                        วันที่ <br />
                                        Date
                                    </th>

                                    <th className="text-center">
                                        รายละเอียด <br />
                                        Description
                                    </th>

                                    {/* <th width={50} className="text-center">
                                        จำนวนหน่วย <br />
                                    Unit
                                </th>
                                    <th width={80} className="text-center">
                                        ราคาต่อหน่วย <br />
                                    Unit Price
                                </th> */}

                                    <th className="text-center">
                                        จำนวนเงิน <br />
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {_.orderBy(this.props.payRecord, 'node.purchaseRecord.purchaseRecordGroup.docNumber', 'asc').map((transaction, index) => {
                                    return (
                                        <tr style={{ fontSize: "12px" }} key={transaction.node.id}>
                                            <td className="text-center">{(this.props.current_page * 20) + index + 1}</td>

                                            <td className="text-center">
                                                {this.getValueAndType(transaction, "docNumber")}
                                            </td>
                                            <td className="text-center">{format(this.getValueAndType(transaction, "issuedDate"), "DD/MM/YYYY")}</td>

                                            <td>
                                                {this.getValueAndType(transaction, "description")}
                                            </td>
                                            {/* <td className="text-center">
                                                {this.getValueAndType(transaction, "unitItems")}
                                            </td>
                                            <td className="text-right">
                                                {numberWithComma(transaction.node.amount)}
                                            </td> */}

                                            <td className="text-right">
                                                {numberWithComma(transaction.node.amount + transaction.node.whtAmount)}
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                                {this.props.payRecordGroup.acceptCreditNotePayRecordGroup.edges.map((accept_credit_note, index) => {
                                    return (
                                        <tr key={accept_credit_note.node.id}>
                                            <td className="text-center">{(this.props.current_page * 20) + index + 1}</td>
                                            <td className="text-center">
                                                {this.getValueAndType(accept_credit_note, "docNumber")}
                                            </td>
                                            <td className="text-center">{format(this.getValueAndType(accept_credit_note, "issuedDate"), "DD/MM/YYYY")}</td>

                                            <td>
                                                {this.getValueAndType(accept_credit_note, "description")}
                                            </td>
                                            {/* <td className="text-center">
                                                1
                                        </td>
                                            <td className="text-right">
                                                {numberWithComma(accept_credit_note.node.price)}
                                            </td> */}

                                            <td className="text-right">
                                                {numberWithComma(accept_credit_note.node.price)}
                                            </td>
                                        </tr>
                                    )
                                }
                                )}

                            </tbody>
                            <tfoot className="font-weight-bold">
                                <tr>
                                    <td colSpan={3} rowSpan={2} />
                                    <td>
                                        จำนวนเงิน/Amount
                                    </td>
                                    <td className="text-right">
                                        {numberWithComma(this.props.payRecordGroup.total + this.state.sum_wht_rate, "0.00")}
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        ภาษีหัก ณ ที่จ่าย/With holding tax
                                    </td>
                                    <td className="text-right">
                                        {numberWithComma(this.state.sum_wht_rate, "0.00")}
                                    </td>
                                </tr>


                                {this.props.current_page === this.props.end_page &&
                                    <tr>
                                        <td colSpan={3} className="text-center">
                                            {i18n.languages[0] === 'th' ?
                                                (ThaiBaht(this.props.payRecordGroup.total)) :
                                                (ConvertNumberToTextEng(this.props.payRecordGroup.total))}
                                        </td>

                                        <td >
                                            จำนวนเงินรวมทั้งสิ้น/Grand Total
                                        </td>
                                        <td className="text-right">
                                            {numberWithComma(this.props.payRecordGroup.total, "0.00")}
                                        </td>
                                    </tr>
                                }
                            </tfoot>
                        </table>

                        {this.props.current_page === this.props.end_page &&
                            <React.Fragment>
                                {!this.props.payRecordGroup.status.includes("DRAFT") &&
                                    <div className="remark">
                                        <span className="font-weight-bold">หมายเหตุ/Remarks:</span> <br />
                                        {this.props.payRecordGroup.remark || "-"}
                                    </div>
                                }

                                {!this.props.payRecordGroup.status.includes("DRAFT") &&
                                    <div className="remark">
                                        <span className="font-weight-bold">การชำระเงิน(Conditions of Payments)</span><br />
                                        {paymentChannel.channel['cash'] &&
                                            <div className="row col ml-1">
                                                <span className=" align-middle"> เงินสด/Cash </span>
                                                <div className="col">
                                                    <span>{paymentChannel.cash_text + ""} </span>
                                                </div>
                                            </div>
                                        }
                                        {paymentChannel.channel['bank'] &&
                                            <div className="col ml-1">
                                                <span className="align-middle"> เงินโอน/Bank Transfer </span><br />
                                                <div className="col ml-2">
                                                    {paymentChannel.bank_detail.map((text, index_bank) => {
                                                        return <div className="row" key={text.accountNumber + index_bank}><span key={text.accountNumber}> - {text.bankName2}&emsp;{text.accountNumber}{this.props.payRecordGroup.status.includes("DRAFT") ? "" : (" วันที่ " + format(text.date, 'DD/MM/YYYY', { locale: thLocale }))}&emsp;จำนวน&emsp;{numberWithComma(text.price, "0.00")} บาท<br /></span></div>
                                                    })}
                                                </div>
                                            </div>
                                        }

                                        {paymentChannel.channel['cheque'] &&
                                            <div className="col ml-1">
                                                <span className="align-middle"> เช็คธนาคาร/Cheque Bank </span><br />
                                                <div className="col ml-2">
                                                    {paymentChannel.cheque_detail.map((text, index_cheque) => {
                                                        return <div className="row" key={text.chequeNumber + index_cheque}><span key={text.chequeNumber}>- เช็คเลขที่ {text.chequeNumber}&emsp;{text.bankName}&emsp;{text.branch && "สาขา " + text.branch}{this.props.payRecordGroup.status.includes("DRAFT") ? "" : (" วันที่ " + format(text.date, 'DD/MM/YYYY', { locale: thLocale }))}&emsp;จำนวน {numberWithComma(text.price, "0.00")} บาท <br /></span></div>
                                                    })}
                                                </div>
                                            </div>
                                        }
                                        {this.props.payRecordGroup.paymentChannel.edges.length > 0 &&
                                            <div className="col ml-1" key="bank">
                                                <span className="align-middle"> เงินสดย่อย/Petty Cash </span><br />
                                                <div className="col ml-2">
                                                    {this.props.payRecordGroup.paymentChannel.edges.map((petty_cash, index) => {
                                                        return <div className="row" key={petty_cash.node.id + index}><span key={petty_cash.node.id}> - เลขที่ {petty_cash.node.setPettyCash.docNumber}&emsp;{"วันที่ " + format(petty_cash.node.date, 'DD/MM/YYYY', { locale: thLocale })} จำนวน {petty_cash.node.price > 0 ? numberWithComma(petty_cash.node.price) : numberWithComma(petty_cash.node.price, "0.00", false, true)} บาท</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        }

                                        {(paymentChannel.channel['other'] || (this.props.allAccountRecordGroup.edges.length > 0 && this.state.isOther)) &&
                                            <div className="col ml-1">
                                                <span className="align-middle"> อื่นๆ/Other </span>
                                                <div className="col ml-2">
                                                    {paymentChannel.other_detail.map((value, index) => {
                                                        return <div className="row" key={value.detail + index}><span key={value.detail}>- {value.detail}&emsp;จำนวน&emsp;{numberWithComma(parseFloat(value.price), "0.00")} บาท <br /></span></div>
                                                    })}
                                                    {
                                                        this.props.allAccountRecordGroup.edges.map((Other) => (
                                                            Other.node.accountRecord.edges.map((accountRecord, index_account) => {
                                                                if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                                                                    accountRecord.node.chartOfAccountCode.chartOfAccountCode === "2125-03" ||
                                                                    accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07") {
                                                                    let name = accountRecord.node.chartOfAccountCode.name
                                                                    if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" && accountRecord.node.debit > 0) {
                                                                        name = name.replace("รายรับ", "รายจ่าย")
                                                                    }
                                                                    return (
                                                                        <div className="row" key={accountRecord.node.id + index_account}>
                                                                            <span key={accountRecord.node.id} >- {name}&emsp;จำนวน&emsp;{numberWithComma(accountRecord.node.credit > 0 ? accountRecord.node.credit : accountRecord.node.debit)} บาท</span><br />
                                                                        </div>

                                                                    )
                                                                }
                                                                else {
                                                                    return null;
                                                                }
                                                            })

                                                        )
                                                        )
                                                    }
                                                </div>

                                            </div>
                                        }
                                        <br />

                                    </div>
                                }
                            </React.Fragment>
                        }
                    </div>
                    {this.props.payRecordGroup.status.includes("DRAFT") ? (this.props.current_page === this.props.end_page) &&
                        <div className="footer">
                            <div className="signature-large d-flex justify-content-end">
                                <div className="left text-center">
                                    .........................................................<br />
                                    <span className="font-weight-bold">ผู้จัดทำ / Organizer</span>
                                </div>
                                <div className="center text-center">
                                    .........................................................<br />
                                    <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                </div>
                                <div className="right text-center">
                                    .........................................................<br />
                                    <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                </div>
                            </div>
                        </div> : (this.props.current_page === this.props.end_page) &&
                    <div className="footer">
                        <div className="signature-large" >
                            <div className="column-foursign" >
                                ..................................................<br />
                                <span className="font-weight-bold">ผู้จัดทำ / Orgenizer</span>
                            </div >
                            <div className="column-foursign">
                                ..................................................<br />
                                <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                            </div>
                            <div className="column-foursign">
                                ..................................................<br />
                                <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                            </div>
                            <div className="column-foursign">
                                ..................................................<br />
                                <span className="font-weight-bold">ผู้รับเงิน / Receiver</span>
                            </div>
                        </div>
                    </div>
                    }
                    {/* <div className="number-page">
                        <br />
                        {this.props.current_page + 1}/{this.props.end_page + 1}
                    </div> */}

                </div>
            </div>
        );
    }
}

export default Pay;
