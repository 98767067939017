import React from "react";
import { commitMutation, QueryRenderer } from "react-relay";
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import getNameResidential from "../../../libs/getNameResidential";
import localStorageLib from "../../../libs/localstorage";
import Navigation from "./navigationReceive";
import Swal from "sweetalert2";
import { Redirect } from "react-router";
import {Translation} from "react-i18next";
import getDocumentNumber from "../../../libs/getDocumentNumber";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import i18n from "i18next";
import i18next from "i18next";
import Loading from "../../../libs/loading";
import ExportReceiveDetailPDF from "./receive_detail/receiveDetailPDF";

const mutation = graphql`
  mutation receiveListTableMutation($input: UpdateReceiveInput!) {
    updateReceive(input: $input) {
      ok
      warningText
    }
  }
`;

class ReceiveListTable extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.start_date = null;
    this.state.end_date = null;
    this.state.temp_start_date = null;
    this.state.temp_end_date = null;
    this.state.search = "";
    this.state.search_input = "";
    this.state.redirectToPrint = false;
    this.state.advance_search = false;
    this.state.loading_approve = false;
    this.state.re_query = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onClickPrintAll = this.onClickPrintAll.bind(this);
    this.onClickApprove = this.onClickApprove.bind(this);
    this.getPaymentMethod = this.getPaymentMethod.bind(this);
    this.onDeleteRtManual = this.onDeleteRtManual.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    if (this.state.status !== this.props.status) {
      this.setState(
        {
          status: this.props.status,
          checkList: [],
          checkListdoc: [],
          check_all: false,
        },
        () => {
          this.goFirst();
        }
      );
    }
  }

  onClickPrintAll() {
    if (this.state.checkList.length <= 0) {
      Swal.fire(i18n.t('receive:Please select an items'), "", "warning");
    }
  }

  getColorAndText(receive) {
    let text = "";
    let color = "text-center";
    switch (receive.node.status) {
      case "WAIT":
        text = "Waiting for review";
        break;
      case "PAID":
        text = "paid_info";
        break;
      default:
        text = "Canceled list";
        color = "text-center text-danger";
    }
    return <Translation>{t => <td className={color}>{t('receive:'+text)}</td>}</Translation>;
  }

  onClickApprove(receive_id_list) {
    if (receive_id_list.length !== 0) {
      Swal.fire({
        title: i18n.t('receive:Are you sure to confirm this receipt?'),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: i18n.t('receive:Cancel'),
        confirmButtonText: i18n.t('receive:Confirm'),
      }).then(async (result) => {
        if (result.value) {
          this.setState({ loading_approve: true });
          let variables = {
            input: {
              idList: receive_id_list,
              status: "paid",
            },
          };

          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              if (response.updateReceive.ok) {
                Swal.fire(i18n.t('receive:Successfully!'), "", "success").then(
                  () => {
                    this.setState({
                      loading_approve: false,
                      re_query: !this.state.re_query,
                    });
                  }
                );
              } else {
                if(response.updateReceive.warningText) {
                  Swal.fire(i18n.t('receive:Failed to confirm the receipt!'), response.updateReceive.warningText, "warning");
                  this.setState({ loading_approve: false });
                }else{
                  Swal.fire(i18n.t('receive:Failed to confirm the receipt!'), "", "warning");
                  this.setState({ loading_approve: false });         
                }
              }
            },
            onError: (err) => {
              Swal.fire("Error!", i18n.t('createReceive:Please try again.'), "warning");
              this.setState({ loading_approve: false });
            },
          });
        } else {
          this.setState({ loading_approve: false });
        }
      });
    } else {
      Swal.fire(i18n.t('receive:Please select an items'), "", "warning");
    }
  }

  getPaymentMethod(receive){
    let payment_channel = "";
    if (
      receive.node.cashDepositReceiveTransaction
        .totalCount !== 0
    ) {
      payment_channel = payment_channel + i18n.t('receive:cash');
    }
    if (receive.node.crossBank.totalCount !== 0) {
      payment_channel =
        payment_channel +
        (payment_channel.length > 0 ? "," : "") +
        "Cross Bill";
    }
    if (
      receive.node.bankAccountTransactionReceive
        .totalCount !== 0 &&
      receive.node.crossBank.totalCount === 0
    ) {
      payment_channel =
        payment_channel +
        (payment_channel.length > 0 ? "," : "") +
        i18n.t('receive:bank_transfer');
    }

    if (
      receive.node.chequeDeposit.totalCount !== 0
    ) {
      payment_channel =
        payment_channel +
        (payment_channel.length > 0 ? "," : "") +
        i18n.t('receive:cheque');
    }
    if ( receive.node.creditCardTransaction.totalCount !== 0) {
      payment_channel = payment_channel + (payment_channel.length > 0 ? "," : "") + i18n.t('receive:credit_card');
    }
    
    if ( receive.node.paymentGatewayMethod.totalCount !== 0 ) {
      if(receive.node.paymentGatewayMethod.edges[0].node.paymentType === 'crossbill'){
        payment_channel = payment_channel + (payment_channel.length > 0 ? "," : "") + "Cross Bill";
      }else{
        payment_channel = payment_channel + (payment_channel.length > 0 ? "," : "") + i18n.t('receive:payment_gateway');
      }
    }

    if (payment_channel.length === 0) {
      payment_channel = i18n.t('receive:other');
    }

    return payment_channel
  
  }

  onDeleteRtManual() {           
    if (this.state.checkList.length !== 0) {
        Swal.fire({
          title: `${i18next.t("batchRt:Confirm to cancel receipt")}`,
          type: 'warning',
          iconColor: '#E14558',
          showCancelButton: true,
          confirmButtonText: `${i18next.t("batchRt:Agree")}`,
          cancelButtonText: `${i18next.t("batchRt:Cancel")}`,
          reverseButtons: true
        }).then((resultFirst) => {
          if(resultFirst.value) {
            Swal.fire({
              title: `${i18next.t("batchRt:Reason for cancel document")}`,
              html: `<input type="text" class="swal2-input" id="remarkVoidRt">`,
              showCancelButton: true,
              confirmButtonText: `${i18next.t("batchRt:Confirm")}`,
              cancelButtonText:  `${i18next.t("batchRt:Cancel")}`,
              cancelButtonColor: "#E14558",
              reverseButtons: true,
            }).then((result) => {
            let remark = document.getElementById('remarkVoidRt').value;
            if (result.value) {
              let variables = {
                input: {
                  idList: this.state.checkList,
                  status: "void",
                  voidRemark: remark,
                },
              };
              commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response) => {                
                  if (response.updateReceive.ok) {
                    Swal.fire(i18n.t('batchRt:Successfully!'), "", "success").then(
                      () => {
                        this.setState({
                          re_query: !this.state.re_query,
                        });
                      }
                    );
                  } else {
                    if(response.updateReceive.warningText) {
                      Swal.fire(i18n.t('batchRt:Failed to cancel the receipt!'), response.updateReceive.warningText, "warning");              
                    }else{
                      Swal.fire(i18n.t('batchRt:Failed to cancel the receipt!'), "", "warning");                       
                    }
                  }
                },
                onError: (err) => {
                  Swal.fire("Error!", i18n.t('createReceive:Please try again.'), "warning");          
                },
              });
            }
            
            })
          }
        })
    } else {
      Swal.fire(i18n.t('receive:Please select an items'), "", "warning");
    }    
  }

  render() {
    if (this.state.redirectToPrint) {
      return (
        <Redirect
          to={{
            pathname: "/document/receive/all/",
            state: {
              checkList: this.state.checkList,
              checkListdoc: this.state.checkListdoc,
            },
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Navigation />
        <div className="row mb-2 mt-1">
          <div className="col-3">
            {_.some(JSON.parse(this.props.local_storage), {
              codename: "accounting_receive_print",
            }) && this.props.status === 'all' &&  (
              this.state.checkList.length > 0 ? (
                <>                
                { localStorage.getItem('site_id') === '199' ?
                <ExportReceiveDetailPDF
                    checkList={{checkList: this.state.checkList, checkListdoc: this.state.checkListdoc }}
                />:
                <Link
                    to={{
                      pathname: "/document/receive/all/",
                      state: {
                        checkList: this.state.checkList,
                        checkListdoc: this.state.checkListdoc,
                      },
                    }}
                  >
                    <button type="button" className="btn btn-light border">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/print-icon.png"
                        }
                        alt="print-icon"
                      />
                    </button>
                  </Link>
                }
                {localStorage.getItem('site_id') === '199' && <button
                      type="button"
                      className="btn btn-light border ml-2"
                      onClick={this.onDeleteRtManual}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/icons/deleteIconRed.svg"
                        }
                        alt="del-icon"
                      />
                    </button>} 
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-light border"
                    onClick={this.onClickPrintAll}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icons/print-icon.png"
                      }
                      alt="print-icon"
                    />
                  </button>
                  {localStorage.getItem('site_id') === '199' && <button
                    type="button"
                    className="btn btn-light border ml-2"
                    onClick={this.onDeleteRtManual}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icons/deleteIconRed.svg"
                      }
                      alt="del-icon"
                    />
                  </button>}
                </>
              )
            )}



            {this.props.status === "wait" && (
              <button
                type="button"
                className="btn btn-light border ml-1"
                onClick={() => this.onClickApprove(this.state.checkList)}
                disabled={this.state.loading_approve}
              >
                {this.state.loading_approve && (
                  <span className="spinner-border spinner-border-sm align-middle mr-2" />
                )}{" "}
                <Translation>{t => t('receive:Approve')}</Translation>
              </button>
            )}



          </div>

          <div className="col-9 text-right">
            <div className="input-group float-right w-auto ml-2">
              <Translation>
                {t => 
                  <input
                    type="text"
                    className="form-control input-size col-8"
                    placeholder={t('filterFunction:search')}
                    value={this.state.search_input}
                    name="search"
                    onChange={(e) =>
                      this.setState({ search_input: e.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                }
              </Translation>
              <button
                type="submit"
                className="btn btn-primary form-control search-button"
                name="search"
                value={this.state.search_input}
                onClick={(e) => this.handleSearch(e)}
              >
                <Translation>{t => t('filterFunction:search')}</Translation>
              </button>
            </div>
            {/* <button
              type="button"
              className="btn btn-outline-secondary float-right"
              onClick={() =>
                this.setState({ advance_search: !this.state.advance_search })
              }
            >
              <img src="/images/icons/filter-icon.png" alt="filter-icon" />
            </button> */}
            <InlineAdvanceFilter
              show_filter={this.state.advance_search}
              start_date={this.state.temp_start_date}
              end_date={this.state.temp_end_date}
              handleChange={this.handleChange}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <QueryRenderer
              environment={environment}
              query={this.props.query}
              variables={{
                search: this.state.search,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                first: this.state.first,
                last: this.state.last,
                status: this.props.status,
                re_query: this.state.re_query,
              }}
              render={({ error, props }) => {
                if (error) {
                  return <div>{error.message}</div>;
                } else if (props) {
                  return (
                    <React.Fragment>
                      <div className="table-responsive fade-up card">
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    this.getAllList(
                                      props.receiveViewer.allReceive.edges
                                    )
                                  }
                                  checked={this.state.check_all}
                                />
                              </th>
                              <th className="text-center" width={130}>
                                <Translation>{t => t('receive:no')}</Translation>
                              </th>
                              <th className="text-center"><Translation>{t => t('receive:Date')}</Translation></th>
                              {/*<th className="text-center">รหัส</th>*/}
                              <th><Translation>{t => t('receive:room_no')}</Translation></th>
                              <th><Translation>{t => t('receive:Name')}</Translation></th>
                              <th><Translation>{t => t('receive:Description')}</Translation></th>
                              <th className="text-right"><Translation>{t => t('receive:Amount')}</Translation></th>
                              <th className="text-right"><Translation>{t => t('receive:Total_amount')}</Translation></th>

                              <th className="text-center"><Translation>{t => t('receive:Payment method')}</Translation></th>
                              {this.props.status === "all" && (
                                <th className="text-center"><Translation>{t => t('receive:Status')}</Translation></th>
                              )}
                              <th className="text-center"><Translation>{t => t('receive:Date of creation')}</Translation></th>
                              {this.props.status === "void" && (
                                <th className="text-center"><Translation>{t => t('receive:Date of cancellation')}</Translation></th>
                              )}
                              {this.props.status !== "wait" && (
                                <th className="text-center"><Translation>{t => t('receive:by')}</Translation></th>
                              )}
                              {this.props.status === "void" && (
                                <th className="text-center"><Translation>{t => t('receive:Reason for cancellation')}</Translation></th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {props.receiveViewer.allReceive.edges.map(
                              (receive, index) => {
                                let order_id = this.state.first - localStorageLib.getPageLimit();
                                let accumulate = 0;
                                let payment_channel = "";
                                let nameOfCustomer = receive.node.receiveTransaction?.edges.length > 0 ? receive.node.receiveTransaction?.edges[0].node.transaction.invoice.firstName : ''
                                let lastNameOfCustomer = receive.node.receiveTransaction?.edges.length > 0 ? receive.node.receiveTransaction?.edges[0].node.transaction.invoice.lastName : ''
                                // let payGroup = receive.node.receiveTransaction?.edges.length > 0 ? receive.node.receiveTransaction?.edges[0].node.transaction.invoice.payGroup : receive.node.payGroup
                                if (receive.node.cashDepositReceiveTransaction.totalCount !== 0) 
                                {
                                  payment_channel = payment_channel + "cash";
                                }
                                if (receive.node.crossBank.totalCount !== 0) {
                                  payment_channel = payment_channel + (payment_channel.length > 0 ? "," : "") + "Cross Bill";
                                }
                                if (receive.node.bankAccountTransactionReceive.totalCount !== 0 && receive.node.crossBank.totalCount === 0) 
                                {
                                  payment_channel = payment_channel + (payment_channel.length > 0 ? "," : "") + "bank_transfer";
                                }

                                if (receive.node.chequeDeposit.totalCount !== 0) {
                                  payment_channel = payment_channel + (payment_channel.length > 0 ? "," : "") +"cheque";
                                }
                                if (receive.node.creditCardTransaction.totalCount !== 0
                                ) {
                                  payment_channel = payment_channel + (payment_channel.length > 0 ? "," : "") + "credit_card";
                                }

                                if (payment_channel.length === 0) {
                                  payment_channel = "other";
                                }
                                let receiveTransaction = _.filter(receive.node.receiveTransaction.edges, e => e.node.amount > 0 || e.node.receiver === 'svm_fix')

                                return receiveTransaction.map(
                                  (transaction, t_index) => {
                                    let number_of_transaction = receiveTransaction.length;
                                    accumulate = accumulate + transaction.node.amount;
                                    let row_number = t_index === 0 && order_id + index + 1;

                                    if (this.state.product_and_service && this.state.product_and_service !== transaction.node.transaction.productAndService.id
                                    ) {
                                      return true;
                                    } else if (this.state.product_and_service && this.state.product_and_service === transaction.node.transaction.productAndService.id
                                    ) {
                                      accumulate = transaction.node.amount;
                                      row_number = index + 1;
                                      number_of_transaction = t_index + 1;
                                    }
                                    return (
                                      <tr key={transaction.node.id}>
                                        <td>
                                          {row_number &&
                                            (receive.node.status === "PAID" ||
                                              receive.node.status === "WAIT") && (
                                              <input
                                                type="checkbox"
                                                onChange={() =>
                                                  this.appendToCheckList(
                                                    receive.node.id,
                                                    receive.node.docNumber
                                                  )
                                                }
                                                checked={this.checkList(receive.node.id)}
                                              />
                                            )}
                                        </td>
                                        <td className="text-center">
                                          {receive.node.status !== "VOID" ? (
                                            <Link
                                              to={
                                                "/accounting/income/receive/detail/" +
                                                receive.node.id +
                                                "/" +
                                                receive.node.docNumber
                                              }
                                              >
                                              {receive.node.docNumber || getDocumentNumber.get("receive", "XXXX",new Date(receive.node.issuedDate))}
                                            </Link>
                                          ) : (
                                            receive.node.docNumber || getDocumentNumber.get("receive","XXXX", new Date(receive.node.issuedDate) )
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {format(receive.node.issuedDate, "DD/MM/YYYY", { locale: thLocale })}
                                        </td>
                                        {/*<td className="text-center">{receive.node.contact.refNumber}</td>*/}
                                        <td>
                                          {receive.node.contact.typeOfContact === "RESIDENTIAL"
                                            ? receive.node.contact.name
                                            : "-"}{" "}
                                        </td>
                                        <td className="colorUse notKanit">
                                          {receive.node.contact.typeOfContact === "RESIDENTIAL" && receive.node.firstName && receive.node.lastName 
                                            ? getNameResidential(receive.node.firstName,receive.node.lastName) :
                                            receive.node.contact.typeOfContact === "RESIDENTIAL"
                                            ? getNameResidential(nameOfCustomer, lastNameOfCustomer)
                                            : receive.node.contact.name}{" "}

                                            {
                                              (receive.node.firstName && receive.node.lastName) && receive.node.payGroup === "AGENT" || receive.node.payGroup === "RENT" ?
                                              <label className='text-red'>({i18n.t("AgentRole:" + receive.node.payGroup)})</label>
                                              :
                                              ""
                                            }
                                        </td>
                                        <td>
                                          { transaction.node.receiver !== 'svm_fix' ? transaction.node.transaction.description.replace(
                                            / *\[[^)]*\] */g,
                                            " ") : " System Error" }
                                        </td>
                                        <td className="text-right">
                                          {numberWithComma( transaction.node.receiver !== 'svm_fix' ?  transaction.node.amount : '-')}
                                        </td>
                                        <td className="text-right">
                                          {t_index ===number_of_transaction - 1 && numberWithComma( transaction.node.receiver !== 'svm_fix' ? accumulate  : '-')}
                                        </td>
                                        <Translation>
                                          {t => 
                                          <td className="text-center">
                                            {/* {t('receive:'+payment_channel)} */}
                                            {this.getPaymentMethod(receive)}
                                          </td>
                                          }
                                        </Translation>
                                        {this.props.status === "all" &&
                                          this.getColorAndText(receive)}
                                        <td className="text-center">
                                          {format(receive.node.added,"DD/MM/YYYY", { locale: thLocale })}
                                        </td>
                                        {this.props.status === "void" && (
                                          <td className="text-center">
                                            {format( receive.node.updated,"DD/MM/YYYY",{ locale: thLocale })}
                                          </td>
                                        )}
                                        {this.props.status !== "wait" && (
                                          <td>{receive.node.creator}</td>
                                        )}
                                        {this.props.status === "void" && (
                                          <td className="text-center">
                                            {receive.node.voidRemark || "-"}
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  }
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={props.receiveViewer.allReceive.totalCount}
                        />
                      </div>
                    </React.Fragment>
                  );
                }
                return <Loading/>
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReceiveListTable;