import React, {Component} from 'react';
import {Translation} from "react-i18next";
import "./interestReport.scss"
import {fetchQuery} from "relay-runtime";
import environment from "../../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import _ from 'lodash';
import numberWithCommas from '../../../../libs/numberWithComma';
import { format } from 'date-fns';
import { Dropdown  } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { Link } from 'react-router-dom';
import InterestExcel from './interestExcel'
import i18next from 'i18next';
 
const query = graphql`
    query interestTableQuery($accountunitid: [String]) {
    allBankReport (accountunitid: $accountunitid) {
        edges{
            node{
                id
                refNumber
                issuedDate
                accountRecord{
                   edges{
                      node{
                          id
                          debit
                          bankUnit{
                              id
                          }
                      } 
                   } 
                }
                
            }
                
        }
    }
}
`;




class interestTable extends Component {

    constructor(props){
        super(props);
        this.state = {
            headerBankUnitID : [],
            headerBankUnitPrice : [],
            allBankReport : [],
            loading : false,
        }
    }

    fetchQuery = (list) => { 
        fetchQuery(environment, query, {accountunitid : list}).then((data) => {
            if(data.allBankReport){
                this.setState({
                    allBankReport : data.allBankReport?.edges
                },() => this.setState({loading:false}))
            }
        });
    }

    componentDidMount(){
        this.setState({loading:true})
        // let headerBankUnitID = []
        let headerBankUnitPrice = []
        let id_list = []
        for (const item of _.sortBy(this.props.selectList,'issuedDate')){
            id_list.push(item.value)
            headerBankUnitPrice.push(numberWithCommas(item.price))
        }
        this.setState({
            headerBankUnitPrice : headerBankUnitPrice,
            headerBankUnitID : id_list
        })
        this.fetchQuery(id_list)
    
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.selectList !== this.props.selectList){
            this.setState({loading:true})
            // let headerBankUnitID = []
            let headerBankUnitPrice = []
            let id_list = []            
            for (const item of _.sortBy(this.props.selectList,'issuedDate')){
                id_list.push(item.value)
                headerBankUnitPrice.push(numberWithCommas(item.price))
            }
            this.setState({
                headerBankUnitPrice : headerBankUnitPrice,
                headerBankUnitID : id_list
            })
            this.fetchQuery(id_list)
        }
    }


    render() {
        let sumBankUnit = []
        return (
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        {this.state.loading ?
                            <div className="mt-3 col ">
                                <span className="d-flex justify-content-center align-item-center">
                                <span
                                className="spinner-border spinner-border-sm align-middle mr-2"/> 
                                    {i18next.t("Allaction:Preparing information")}
                                </span>
                            </div>
                            :
                            <React.Fragment>
                                <div className="row col-12 mt-2 d-flex justify-content-end">
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'report_financial_print' }) && 
                                    <Dropdown>
                                        <DropdownToggle id="dropdown-item-button" bsPrefix = 'dropdown-export dropdown-toggle'>
                                            {i18next.t("Allaction:Print")}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <Dropdown.Item>
                                                <Link to={{pathname : "/accounting/report/finance/report-interest" ,
                                                    query : {id : this.state.headerBankUnitID , header : this.state.headerBankUnitPrice ,
                                                        firstDate:this.props.firstDate , lastDate:this.props.lastDate }}} 
                                                className="text-black">
                                                    <p className="text-black">PDF</p>
                                                </Link>
                                            </Dropdown.Item>
                                            <InterestExcel 
                                                projectName={this.props.projectName} 
                                                state={this.state}
                                                bankUnit={this.state.headerBankUnitPrice}
                                                allBankReport={this.state.allBankReport}
                                                headerBankUnitID ={this.state.headerBankUnitID}
                                                firstDate ={this.props.firstDate}
                                                lastDate={this.props.lastDate}
                                            />
                                        </DropdownMenu>
    
                                        
                                    </Dropdown>}
    
                                </div>
                                <div className="row ">
                                    <div className="col-12 d-flex justify-content-center">
                                        <h5>{this.props.projectName} <br/>
                                        {i18next.t("interestDashboard:Report on Detailed Estimation of Fixed Deposit Interest Income")} <br/>
                                        {i18next.t("interestDashboard:Month")} {this.props.firstDate === '' ? ' - ' : new Date(this.props.firstDate).toLocaleDateString('th-TH' , { year: 'numeric', month: 'long'}) }  
                                        {i18next.t("AllFilter:to")} {this.props.lastDate === '' ? ' - ' : new Date(this.props.lastDate).toLocaleDateString('th-TH' , { year: 'numeric', month: 'long'})}
                                        </h5>
                                    </div>
                                </div>
                                <div className="row mt-4 col-12">
                                    <div className="table-responsive">
                                        <table className="table interest-report table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" rowSpan={2}>{i18next.t("interestDashboard:Number")}</th>
                                                    <th className="text-center" rowSpan={2}>{i18next.t("interestDashboard:Month")}</th>
                                                    <th className="text-center" colSpan={this.state.headerBankUnitID.length > 0 ? this.state.headerBankUnitID.length : 1}>{i18next.t("interestDashboard:Interest Income Estimate")}</th>
                                                    <th className="text-center" rowSpan={2}>{i18next.t("interestDashboard:Total (THB)")}</th>
                                                </tr>
                                                <tr>
                                                    {this.state.headerBankUnitPrice.length > 0 ?
                                                        this.state.headerBankUnitPrice.map((head,index) => {
                                                        return (
                                                                    <th className="text-right" key={index} style={{borderTop : '1px solid #dee2e6 '}}>{head}</th>
                                                            )
    
                                                        })
                                                        :
                                                        <th></th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.allBankReport.map((transaction,index) => {
                                                    let sumJV = 0
                                                    return(
                                                        <tr key={index}>
                                                            <td className="text-center grey-bg">{transaction.node.refNumber}</td>
                                                            <td className="text-center grey-bg">{format(transaction.node.issuedDate,'DD/MM/YYYY')}</td>
                                                            {this.state.headerBankUnitID.map((id,index) => {
                                                                let interestDebit = _.find(transaction.node.accountRecord.edges,['node.bankUnit.id',id])
                                                                if(sumBankUnit[index]){
                                                                    
                                                                    sumBankUnit[index] = sumBankUnit[index] + (interestDebit ? interestDebit.node.debit : 0)
                                                                }else{
                                                                    sumBankUnit[index] = interestDebit? interestDebit.node.debit : 0
                                                                }
                                                                if(interestDebit){
                                                                    sumJV += interestDebit.node.debit
                                                                    
                                                                    return(
                                                                        <td key ={index} className="text-right">{numberWithCommas(interestDebit.node.debit)}</td>
                                                                    )
                                                                }else{
                                                                    return (<td key={index}> </td>)
                                                                }
                                                            })
                                                            }
                                                            <td className="text-right blue-bg">{numberWithCommas(sumJV)}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td className="text-center">{i18next.t("interestDashboard:Total")}</td>
                                                    {sumBankUnit.length > 0 ?
                                                        sumBankUnit.map((sum,index) => {
                                                            return(
                                                                <td key={index} className="text-right">{numberWithCommas(sum)}</td>
                                                            )
                                                        })
                                                        :
                                                        <td>-</td>
                                                    }
                                                    <td className="text-right">{numberWithCommas(_.sum(sumBankUnit))}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </Translation>
        );
    }
}

export default interestTable;
