import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import '../debtFreeRequest.scss'
import SearchSelect from '../../../libs/searchSelect';
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';
import i18next from 'i18next';

class DebtFreeCertificationInput extends Component{


    render(){
        let tenantName = this.props.state.tenantFirstName + " " + this.props.state.tenantLastName
        return(
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        <div className="row mt-4 margin-top pl-3"> 
                            <div className="col-12">
                                <h4>{i18next.t("debt_free_request:Details of the Debt-Free Certification")}</h4>
                            </div>
                            <hr/>
                        </div>
                        <div className="row col-12 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Date specified in the debt-free certificate")}</h6></div>
                            <div className="col-4">
                                <DatePickerNoti 
                                    selected={this.props.state.requestingDate}
                                    name="requestingDate"
                                    onChange={(e) => this.props.handleChange(e)}
                                    minDate={new Date().setDate(new Date(this.props.state.added).getDate() + (this.props.state.allSettingDebsFreeCertification ? this.props.state.allSettingDebsFreeCertification.node.waiting : 0))}
                                    disabled={(this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active'}
                                />
                            </div>
                        </div>
                        {this.props.status === 'create' || this.props.state.editInput ? 
                            <div className="row col-8 mt-4">
                                <div className="col-12"><h6>{i18next.t("debt_free_request:Name of the person requesting the certificate")} <span style={{color:'#E14558'}}>*</span></h6></div>
                                <div className="col-12">
                                    <SearchSelect 
                                        onChange={(e) => this.props.handleChange(e)}
                                        value={this.props.state.ownerId}
                                        name="ownerId"
                                        queryObject={this.props.state.ownerList}
                                        keyOfValue="id" require={true}
                                        keyOfLabel="firstName:lastName"
                                        disabled={this.props.state.ownerList.length === 0 || (this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                </div>
                            </div>
                            :
                            <div className="row col-8 mt-4">
                                <div className="col-12"><h6>{i18next.t("debt_free_request:Name of the person requesting the certificate")} <span style={{color:'#E14558'}}>*</span></h6></div>
                                <div className="col-12">
                                    <input type="number" 
                                        className="form-control form-control-border"
                                        name="phone"
                                        placeholder={tenantName}
                                        // onChange={(e) => this.props.handleChange(e)}
                                        disabled={!this.props.state.editInput}
                                        readOnly={true}
                                    />  
                                </div>
                            </div>
                        }
                        <div className="row col-8 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Phone number")} <span style={{color:'#E14558'}}>*</span></h6></div>
                            <div className="col-12">
                                <input type="tel" 
                                    value={this.props.state.phone} 
                                    className="form-control form-control-border"
                                    name="phone"
                                    onChange={(e) => this.props.handleChange(e)}
                                    disabled={(this.props.disabledInput && !this.props.state.editInput)  || this.props.status === 'active'}
                                />  
                            </div>
                        </div>
                        <div className="row col-8 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Name of the person receiving the property transfer")} <span style={{color:'#E14558'}}>*</span></h6></div>
                            <div className="col-12">
                                <input type="text" 
                                    value={this.props.state.nameTransfer} 
                                    className="form-control form-control-border"
                                    name="nameTransfer"
                                    onChange={(e) => this.props.handleChange(e)}
                                    required={true}
                                    disabled={(this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active'}
                                />  
                            </div>
                        </div>
                        <div className="row col-8 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Phone number")} <span style={{color:'#E14558'}}>*</span></h6></div>
                            <div className="col-12">
                                <input type="tel" 
                                    value={this.props.state.phoneTransfer} 
                                    className="form-control form-control-border"
                                    name="phoneTransfer"
                                    onChange={(e) => this.props.handleChange(e)}
                                    required={true}
                                    disabled={(this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active'}
                                />  
                            </div>
                        </div>
                        <div className="row col-8 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Document delivery")}</h6></div>
                            <div className="col-12  form-inline">
                                <input type="radio" name="fee" 
                                    id="pickup"
                                    className="radio-request mr-3"
                                    name="addressContact"
                                    onChange={(e) => this.props.toggleSwitch(e)} 
                                    checked={this.props.state.addressContact === 'pickup'}
                                    disabled={(this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active'}
                                />
                                <span htmlFor="pickup" className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>
                                {i18next.t("debt_free_request:Pick up in person at the legal entity")}
                                </span>
                            </div>
                            <div className="col-12 mt-1 form-inline">
                                <input type="radio" name="fee" 
                                    id="delivery"
                                    className="radio-request mr-3"
                                    name="addressContact"
                                    onChange={(e) => this.props.toggleSwitch(e)}
                                    checked={this.props.state.addressContact === 'delivery'}
                                    disabled={(this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active'}
                                />
                                <span htmlFor="delivery" className={((this.props.disabledInput && !this.props.state.editInput)|| this.props.status === 'active' )? 'disable' : ''}>
                                {i18next.t("debt_free_request:Delivery to the specified address")}
                                </span>
                            </div>
                        </div>
                        <div className="row col-8 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Address")}</h6></div>
                            <div className="col-12">
                                <textarea type="text" 
                                    value={this.props.state.address} 
                                    className="form-control  height-area form-control-border"
                                    name="address"
                                    onChange={(e) => this.props.handleChange(e)}
                                    disabled={(this.props.disabledInput && !this.props.state.editInput) || this.props.state.addressContact === 'pickup' ||this.props.status === 'active'}
                                />
                            </div>
                        </div>
                        
                    </React.Fragment>

                }

            </Translation>

        );
    }

}
export default DebtFreeCertificationInput;