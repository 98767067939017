import React ,{ Component } from "react";
// import chartJson from './json/chart_of_account';
import chartJson from "../../accounting/chartOfAccount/json/chart_of_account";
import _ from 'lodash';
import {graphql} from "babel-plugin-relay/macro";
import Wrapper from "../../components/wrapper";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accounting/accountingTopMenuNavigation";
import {Link , Prompt} from "react-router-dom";
import {Translation} from "react-i18next";
import RowRenderBringForward from "./rowRenderBringForward";
// import "./styles/styles.scss";
import environment from "../../env/environment";
import {fetchQuery,commitMutation} from "relay-runtime";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import numberWithComma from "../../libs/numberWithComma";
import Swal from "sweetalert2";
import { format } from "date-fns";
import ExportBringForward from "./exportBringForward";
import "./settingBringForward.scss"
import i18n from "i18next";

const mutation = graphql`
    mutation settingBringForwardMutation($input: ChartOfAccountUpdateMultiInput!){
        chartOfAccountUpdateMulti(input:$input){
            ok
            warningText
        }
    }`;
const query = graphql`
    query settingBringForwardQuery {
        viewer {
            allChartOfAccount(getHistoricalValue: true) {
                totalCount
                edges {
                    node {
                        id name nameEn chartOfAccountCode totalHistoricalDebit totalHistoricalCredit
                    }
                }
            }
            
        }
        allAccountProjectManager{
            edges{
              node{
                id
                tableAccount
              }
            }
        }
    }
`;

class SettingBringForward extends Component {
    constructor(pros) {
        super(pros);
        this.state = {
            treeValue: [],
            chartTree: [],
            chartCount: {},
            totalHistoricalCredit: {},
            totalHistoricalDebit: {},
            totalasset:0,
            totaldebt:0,
            totalequity:0,
            totalexpense:0,
            totalrevenue:0,
            sum_difference123:0,
            sum_difference45:0,
            chartCountTotal: 0,
            chartOfAccountList: [],
            start_date: null,
            start_date_old: null,
            edit_list:{},
            formIsHalfFilledOut: false,
            total_export: [],
            edit_date: false,
        };
        this.genChartList = this.genChartList.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.getChartQueryData = this.getChartQueryData.bind(this);
        this.countChart = this.countChart.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
                 
    }


    getCategory(code) {
        let categoryCode = code.charAt(0);
        if (categoryCode === '1') {
            return "ASSET"
        }
        if (categoryCode === '2') {
            return "DEBT"
        }
        if (categoryCode === '3') {
            return "EQUITY"
        }
        if (categoryCode === '4') {
            return "REVENUE"
        }
        if (categoryCode === '5') {
            return "EXPENSE"
        }
        // if (categoryCode === '9') {
        //     return "EXPENSE"
        // }
    }

    getChartQueryData(parent, data) {
        let chartList = [];
        data.viewer.allChartOfAccount.edges.map((chart) => {
            let chartCode = chart.node.chartOfAccountCode;
            if(chartCode.substring(0, 4) === parent.key) {
                chartList.push({data: {
                    id: chart.node.id,
                    code: chartCode,
                    name: chart.node.name,
                    nameEn: chart.node.nameEn,
                    totalHistoricalDebit: chart.node.totalHistoricalDebit,
                    totalHistoricalCredit: chart.node.totalHistoricalCredit,
                    category: this.getCategory(chartCode),
                    selected: false
                }});
            }
            return true;
        });
        return chartList;
    }

    genChartList(chart, chartTree, data) {
        chart.map((chart) => {
            let child = [];
            if (_.get(chart, 'child', []).length === 0) {
                // append data form database
                child = this.getChartQueryData(chart, data);
            }

            chartTree.push({
                data: {
                    code: chart.key + "-00",
                    name: chart.name.replace(chart.key + "-00 ", ""),
                    historical: 0,
                    category: this.getCategory(chart.key),
                },
                children: child
            });

            if (_.get(chart, 'child', []).length > 0) {
                this.genChartList(chart['child'], child, data);
            }
            return true;
        });
    };
    async countChart(data) {
        let chartCount = {};
        let totalHistoricalCredit = {};
        let totalHistoricalDebit = {};

        let totalasset = 0; //สินทรัพย์
        let totaldebt = 0; //หนี้สิน
        let totalequity = 0; //ส่วนของผู้ถือหุ้น
        let totalexpense = 0; //รายจ่าย
        let totalrevenue = 0; //รายรับ
        let sum_difference123 = 0; //ส่วนต่าง 1 2 3
        let sum_difference45 = 0; //ส่วนต่าง 4 5

        data.viewer.allChartOfAccount.edges.map((chart) => {
            //คำนวน สินทรัพย์ หนี้สิน ส่วนของผู้ถือหุ้น รายรับ รายจ่าย
            if(chart.node.chartOfAccountCode.slice(0,1) === "1"){
                totalasset += (chart.node.totalHistoricalDebit - chart.node.totalHistoricalCredit);
            }else if(chart.node.chartOfAccountCode.slice(0,1) === "5"){
                totalexpense += (chart.node.totalHistoricalDebit - chart.node.totalHistoricalCredit);
            }
            else if(chart.node.chartOfAccountCode.slice(0,1) === "2"){
                totaldebt += (chart.node.totalHistoricalCredit - chart.node.totalHistoricalDebit)
            }
            else if(chart.node.chartOfAccountCode.slice(0,1) === "3"){
                totalequity += (chart.node.totalHistoricalCredit - chart.node.totalHistoricalDebit)
            }
            else if(chart.node.chartOfAccountCode.slice(0,1) === "4"){
                totalrevenue += (chart.node.totalHistoricalCredit - chart.node.totalHistoricalDebit)
            }
            //คำนวน จำนวน , เดบิก , เครดิต
            let firstChartCode = chart.node.chartOfAccountCode.charAt(0);
            _.set(chartCount, firstChartCode, _.get(chartCount, firstChartCode, 0)+1)
            _.set(totalHistoricalCredit, firstChartCode, _.get(totalHistoricalCredit, firstChartCode, 0)+chart.node.totalHistoricalCredit)
            _.set(totalHistoricalDebit, firstChartCode, _.get(totalHistoricalDebit, firstChartCode, 0)+chart.node.totalHistoricalDebit)
            return null
        });
        //ส่วนต่าง หมวด 1 - 2+3
        sum_difference123 = Math.abs(totalasset - (totaldebt+totalequity));
        // ส่วนต่าง หมวด 4-5
        sum_difference45 = Math.abs(totalexpense - totalrevenue);

        await this.setState(
            {chartCount: chartCount,
            totalHistoricalCredit:totalHistoricalCredit,
            totalHistoricalDebit:totalHistoricalDebit,
            totalasset:totalasset,
            totaldebt:totaldebt,
            totalequity:totalequity,
            totalrevenue:totalrevenue,
            totalexpense:totalexpense,
            sum_difference123:sum_difference123,
            sum_difference45:sum_difference45,
            },
        );
    }

    componentDidMount() {
        this.getQuery()
    }

    getQuery(){
        fetchQuery(environment, query, {})
            .then(data => {
                this.countChart(data);
                let chartTree = [];
                this.genChartList(chartJson, chartTree, data);
                this.setState({
                    chartTree: chartTree,
                    chartCountTotal: data.viewer.allChartOfAccount.totalCount,
                    chartOfAccountList: data.viewer.allChartOfAccount.edges,
                    start_date:data.allAccountProjectManager?.edges[0]?.node?.tableAccount && new Date(data.allAccountProjectManager?.edges[0].node.tableAccount),
                    start_date_old:data.allAccountProjectManager?.edges[0]?.node?.tableAccount && new Date(data.allAccountProjectManager?.edges[0].node.tableAccount)
                });
            });
    }
    
    async handleChangeInput(e){
        if (e.target.name === "start_date") {
            await this.setState({start_date: e.target.value , formIsHalfFilledOut:true , edit_date : true})
        }

    }

    summary(values){
        let total = 0;
        Object.entries(values).forEach(([index_group,result],index_obj) => {
            total += result;
        })
        return parseFloat( Math.abs(total));
    }

    handleChange(id,value,key) {
        let value_change = value; 
         
        if(value_change < 0 ){
            Swal.fire("กรุณาใส่เลขจำนวนเต็ม", "", "warning");
            return;
        }else{

            if(value_change === ""){
                value_change = "0.00"
            }
    
            let select = _.forIn(this.state.chartTree, function (value1) {
                _.forIn(value1.children, function (value2) {
                    if (value2?.children) {
    
                        _.forIn(value2.children, function (value3) {
                            if (value3?.children) {
                                
                                _.forIn(value3.children, function (value4) {
                                    if (value4?.children) {
    
                                        _.forIn(value4.children, function (value5) {
                                            //Deep lv 5
                                            if (value5?.data.id === id) {
                                                value5.data[key] = value_change;
                                                value5.data['selected'] = true;
                                            }
    
                                        })
                                    }else{//Deep lv 4
                                        if (value4?.data.id === id) {
                                            value4.data[key] = value_change;
                                            value4.data['selected'] = true;
                                        }
                                    }
    
                                })
                            }//Deep lv 3
                            else{
                                if (value3?.data.id === id) {
                                    value3.data[key] = value_change;
                                    value3.data['selected'] = true;
                                }
                            }
                        })
    
                    }//Deep lv 2
                    else{
                        if (value2?.data.id === id) {
                            value2.data[key] = value_change;
                            value2.data['selected'] = true;
                        }
                    }
    
                })
            })

            this.setState({chartTree:select, formIsHalfFilledOut:true})
    
        }

       
       

        
    }
    onSubmit(){
        let input = [];
        _.forIn(this.state.chartTree, function (value1) {
            _.forIn(value1.children, function (value2) {
                if (value2?.children) {

                    _.forIn(value2.children, function (value3) {
                        if (value3?.children) {
                            
                            _.forIn(value3.children, function (value4) {
                                if (value4?.children) {

                                    _.forIn(value4.children, function (value5) {
                                        //Deep lv 5
                                        if (value5?.data['selected'] === true) {
                                            input.push(value5.data);
                                        }

                                    })
                                }else{//Deep lv 4
                                    if (value4?.data['selected'] === true) {
                                        input.push(value4.data);
                                    }
                                }

                            })
                        }//Deep lv 3
                        else{
                            if (value3?.data['selected'] === true) {
                                input.push(value3.data);
                            }
                        }
                    })

                }//Deep lv 2
                else{
                    if (value2?.data['selected'] === true) {
                        input.push(value2.data);
                    }
                }

            })
        })


        if(input.length > 0 || this.state.edit_date && this.state.start_date_old ){
            if(this.state.start_date){
                let variables = {
                    input: {
                        chartOfAccountJson: JSON.stringify(input),
                        tableAccount: format(this.state.start_date, 'YYYY-MM-DD'),
                    }
                };
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        // uploadables,
                        onCompleted: (response) => {
                            if (response.chartOfAccountUpdateMulti.ok) {
                                Swal.fire(i18n.t('settingAccount:Save successfully'), '', 'success').then(() => {
                                    this.setState({ redirectToList: true, loading: false , formIsHalfFilledOut : false ,  edit_date : false});
                                    this.getQuery();
                                })
                            } else {
                                Swal.fire(i18n.t('settingAccount:Failed to save'), response.chartOfAccountUpdateMulti.warningText, 'warning');
                                this.setState({ loading: false });
    
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18n.t('settingAccount:Please try again'), 'warning')
                            this.setState({ loading: false });
                        },
                    },
                );
            }else {
                Swal.fire('Error!', i18n.t('settingAccount:Please enter bring forward!'), 'warning')
            }
    

        }else{
            Swal.fire('Error!', i18n.t('settingAccount:Value changes were not found'), 'warning')
        }
    }

    render() {
   
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <Translation>{t => <Prompt when={this.state.formIsHalfFilledOut} message={t('settingAccount:You haven\'t saved your edits. / Are you sure to leave from the page?')} />}</Translation>
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <div className="row justify-content-between" >
                                    <div className="col form-inline" >
                                        <h3 className="mb-3">
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <span>{t('settingAccount:Bring forward setting')}</span>
                                        </h3>
                                        <h6 className="mb-3 ml-2">
                                            ({this.state.chartCountTotal} {t('settingAccount:items')})
                                        </h6>
                                        <h6 className="mb-3 ml-2">
                                            {t('settingAccount:at')}
                                        </h6>
                                        <div className ="ml-2 mb-3">
                                            <DatePickerAdapter  id="start_date"
                                                                className="form-control" name="start_date"
                                                                selected={this.state.start_date}
                                                                onChange={this.handleChangeInput}
                                            />
                                        </div>
                                        
                                    </div>
        
                                    <div className="col text-right">
                                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_chart_of_account_print'}) &&
                                        <ExportChartOfAccount chartTree={this.state.chartTree}/>
                                    } */}
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_accountrecord_print'}) &&
                                        <ExportBringForward chartTree={this.state.chartTree}
                                                        totalHistoricalCredit={this.state.totalHistoricalCredit}
                                                        totalHistoricalDebit={this.state.totalHistoricalDebit}
                                                        start_date = {this.state.start_date}
                                    />}
                                    
                                    </div>
                                </div>
        
                                <div className="content-inner">
                                    <div className="row">
                                        <div className="col">
        
                                            <div className="table-responsive fade-up card table-hover">
                                                <table className="table chart-of-account-table">
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th width={120}>{t('settingAccount:Account No.')}</th>
                                                        <th width={800}>{t('settingAccount:Account name')}</th>
                                                        <th width={120} className="text-center">{t('settingAccount:Group')}</th>
                                                        <th width={150} className="text-center">{t('settingAccount:Category')}</th>
                                                        <th width={180}className="text-center">{t('settingAccount:Debit')}</th>
                                                        <th width={180} className="text-center">{t('settingAccount:Credit')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.chartTree.length > 0 ?
                                                    <RowRenderBringForward 
                                                        chartTree={this.state.chartTree} 
                                                        level={0} 
                                                        chartCount={this.state.chartCount}
                                                        totalHistoricalCredit={this.state.totalHistoricalCredit}
                                                        totalHistoricalDebit={this.state.totalHistoricalDebit}
                                                        handleChange={this.handleChange} /> :
                                                    <tr><td colSpan={5}>loading...</td></tr>
                                                
                                                }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th colSpan={3} className ="summary_row" style ={{borderTop : "1px solid #dee2e6"}} />
                                                            <th className ="text-center summary_row" style ={{borderTop : "1px solid #dee2e6"}}>
                                                                {t('settingAccount:Total')}
                                                            </th>
                                                            <th className="text-right summary_row" style ={{borderLeft : "1px solid #dee2e6" ,borderTop : "1px solid #dee2e6"}}>
                                                                <span> {numberWithComma((this.summary(this.state.totalHistoricalDebit)),0)} </span>
                                                            </th>
                                                            <th className="text-right summary_row" style ={{borderTop : "1px solid #dee2e6"}}>
                                                                <span> {numberWithComma((this.summary(this.state.totalHistoricalCredit)),0)} </span>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={3} className ="summary_row" style ={{borderTop : "1px solid #dee2e6"}} />
                                                            <th className ="text-center summary_row" style={{borderTop : "1px solid #dee2e6" }}>
                                                                {t('settingAccount:Variance')}
                                                            </th>
                                                            <th colSpan={2} className ="text-center summary_row" style ={{borderLeft : "1px solid #dee2e6" ,borderTop : "1px solid #dee2e6"}}>
                                                                <span className ="color red-color">{numberWithComma(( this.summary(this.state.totalHistoricalDebit) - this.summary(this.state.totalHistoricalCredit)),0)}</span>
                                                            </th>
                                                        </tr>
        
                                                    </tfoot>
                                                </table>
                                            </div>
                                        <div className = "row mt-3 mb-3" >
                                            <div className ="col-8">
                                                <div className ="table-responsive fade-up card">
                                                    <table className ="table mt-2">
                                                        <tbody>
                                                        <tr className ="not_border_bottom text-center">
                                                                <th width ={'30%'}>
                                                                    {t('settingAccount:Asset')}
                                                                </th>
                                                                <th width ={'5%'} />
                                                                <th width ={'30%'}>
                                                                    {t('settingAccount:Liabilities')}
                                                                </th>
                                                                <th width ={'5%'}/>
                                                                <th width ={'30%'}>
                                                                    {t('settingAccount:Co-Owner/Member equity')}
                                                                </th>
                                                            </tr>
                                                            <tr className ="not_border_bottom text-center">
                                                                <td>
                                                                    <span>{numberWithComma(this.state.totalasset)}</span>
                                                                </td>
                                                                <td>
                                                                    <span>=</span>
                                                                </td>
                                                                <td>
                                                                    <span>{numberWithComma(this.state.totaldebt)}</span>
                                                                </td>
                                                                <td>
                                                                    <span>+</span>
                                                                </td>
                                                                <td>
                                                                    <span>{numberWithComma(this.state.totalequity)}</span>
                                                                </td>
                                                            </tr>
                                                            <tr style ={{borderTop : "1px solid #dee2e6" }}>
                                                                <th className ="text-center">
                                                                    {t('settingAccount:Variance')}
                                                                </th>
                                                                <td/>
                                                                <th className ="text-center">
                                                                    <span className ="color red-color">{numberWithComma(this.state.sum_difference123)}</span>
                                                                </th>
                                                                <td colSpan={2}/>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className ="col">
                                                <div className ="table-responsive fade-up card">
                                                    <table className ="table mt-2">
                                                    <tbody>
                                                        <tr className ="not_border_bottom text-center">
                                                                <th width ={'50%'}>
                                                                    {t('settingAccount:Revenue')}
                                                                </th>
                                                                <th width ={'50%'} style ={{borderLeft : "1px solid #dee2e6" }}>
                                                                    {t('settingAccount:Expense')}
                                                                </th>
                                                            </tr>
                                                            <tr className ="not_border_bottom text-center">
                                                                <td>
                                                                    <span>{numberWithComma(this.state.totalrevenue)}</span>
                                                                </td>
                                                                <td style ={{borderLeft : "1px solid #dee2e6" }}>
                                                                    <span>{numberWithComma(this.state.totalexpense)}</span>
                                                                </td>
                                                            </tr>
                                                            <tr style ={{borderTop : "1px solid #dee2e6" }}>
                                                                <th className ="text-center">
                                                                    {t('settingAccount:Variance')}
                                                                </th>
                                                                <th className ="text-center">
                                                                    <span className ="color red-color">{numberWithComma(this.state.sum_difference45)}</span>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> 
                                        </div>
                                        
                                        </div>
                                    </div>
                                
                                    <div className = "row">
                                        <div className = "col mb-3  btn-float"style ={{display:'flex' , justifyContent : 'flex-end'}} >
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_accountrecord_edit'}) &&
                                                <button type="submit" className="btn btn-primary add mr-1" onClick={this.onSubmit}>
                                                    {t('settingAccount:Save')}
                                                </button>
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>

        )
    }

} 
export default SettingBringForward;