import { commitMutation} from 'react-relay'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
  
  
const mutation = graphql`
mutation updateAddressMutationMutation($input:UpdateAddressInput!){
    updateAddress(input: $input){
            ok
        }
    }
`;

export default async function  updateAddressMutation(address,success,error) {
    let variables = { input : address.input}

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                if(response.updateAddress.ok){
                    success(true)
                    return true
                }else{
                    return false
                }
            },
            onError: (err) => {
                error(err)
                return false
            },
        },
    );
}