import React, { useEffect, useState } from 'react';
import "./style/createUpdateSettingJuristic.scss"
import Redirect from "react-router-dom/es/Redirect";
import { Translation } from "react-i18next";
const _ = require('lodash');

// const query = graphql`
//     query createUpdateSettingJuristicMuliQuery($id: ID!) {
//         juristic(id: $id) {
//             id
//             user {
//               id
//               username
//             }
//             firstName
//             lastName
//             role
//             image
//             signature
//         }
//     }
// `;

// const mutation = graphql`
//     mutation createUpdateSettingJuristicMuliMutation($input: CreateUpdateJuristicInput!) {
//         createUpdateJuristic(input: $input) {
//             newJuristic {
//                 id
//             }
//         }


//     }
// `;

const PermisionVMS = (props) => {
    // const [loading, setLoading] = useState(true);
    // const [saving, setSaving] = useState(false);
    // const [update, setUpdate] = useState(false);
    const [listpermision, setListPermision] = useState([])
    const [redirectToList] = useState(false)
    const [permision] = useState({
        create_input: ['vms_manage_visitor', 'vms_packing_income'],
        edit_input: ['vms_manage_visitor'],
        delete_input: ['vms_manage_visitor',],
        approve_input: ['vms_packing_income','vms_manage_visitor'],
        print_input: ['vms_manage_visitor', 'vms_visitor_report', 'vms_packing_income'],
        view_input: ['vms_manage_visitor', 'vms_visitor_report', 'vms_packing_income']
    })
    // const [allPermision, setAllPermision] = useState([])

    useEffect(() => {
        setListPermision(props.listPermision)
        // setAllPermision(props.permissions)
    }, [])

    const changeDataPermision = (event, key, type) => {
        let e = _.cloneDeep(event)
        props.checkUpdate(key + "_" + type, e.target.checked, type, permision, key)
    }

    const RetrunCheckbox = (child, type) => {
        let typee = type.split("_");

        let namePermiosn = child.key + type
        type !== "" ?? console.log(`${props.permissions[namePermiosn]}`);

        let forType =
            typee[1] === "create" ? permision.create_input.includes(child.key) :
                typee[1] === "edit" ? permision.edit_input.includes(child.key) :
                    typee[1] === "approve" ? permision.approve_input.includes(child.key) :
                        typee[1] === "delete" ? permision.delete_input.includes(child.key) :
                            permision.print_input.includes(child.key);

        let checked = props.permissions[namePermiosn]

        return (
            forType ?
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input"
                        id={child.key + type}
                        name={child.key + type}
                       // defaultChecked={checked || props.checkUserJuristicAdmin}
                        checked={checked || props.checkUserJuristicAdmin}
                        onChange={(e) => changeDataPermision(e, child.key, typee[1] ? typee[1] : "")}
                    />
                    <label className="custom-control-label"
                        htmlFor={child.key + type} />
                </div>
                :
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input"
                    //    defaultChecked={false}
                        checked={false}
                        disabled={true}
                    />
                    <label className="custom-control-label"
                        htmlFor={child.key + type} />
                </div>
        )
    }

    if (redirectToList) {
        return <Redirect to="/setting/juristic-setting" />
    }

    return (
        <Translation>
            {t =>
                <div className="row">
                    <div className="col-8">
                        <div className="table-resposive">
                            <table className="table table-hover">
                                {
                                    listpermision.map((item, listpermisionindex) => {
                                        return (
                                            <tbody key={listpermisionindex}>
                                                <tr className='table-active'>
                                                    <th>{t(`set_permission:${item.group_name}`)}</th>
                                                    <th className="text-center">{t("set_permission:All")}</th>
                                                    <th className="text-center">{t("set_permission:View")}</th>
                                                    <th className="text-center">{t("set_permission:Create")}</th>
                                                    <th className="text-center">{t("set_permission:Edit")}</th>
                                                    <th className="text-center">{t("set_permission:Approve")}</th>
                                                    <th className="text-center">{t("set_permission:Print")}</th>
                                                    <th className="text-center">{t("set_permission:Remove")}</th>
                                                </tr>
                                                {
                                                    item.child.map((itemChild, index) => {
                                                        return (
                                                            <tr key={"itemChild" + index} className='text-center'>
                                                                <td className='text-left'>{t(`set_permission:${itemChild.name}`)}</td>
                                                                <td>{RetrunCheckbox(itemChild, "")}</td>
                                                                <td>{RetrunCheckbox(itemChild, "_view")}</td>
                                                                <td>{RetrunCheckbox(itemChild, "_create")}</td>
                                                                <td>{RetrunCheckbox(itemChild, "_edit")}</td>
                                                                <td>{RetrunCheckbox(itemChild, "_approve")}</td>
                                                                <td>{RetrunCheckbox(itemChild, "_print")}</td>
                                                                <td>{RetrunCheckbox(itemChild, "_delete")}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        )
                                    })
                                }

                            </table>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    );
}


export default PermisionVMS;
