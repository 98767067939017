import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import _ from "lodash"
import Swal from 'sweetalert2'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment'
import { commitMutation } from "react-relay";
import { format, differenceInCalendarMonths, getDate } from 'date-fns'
import { Redirect } from "react-router-dom";
import upload from '../../libs/upload'

import queryContact from './queryGraphql/queryContact'
import queryAllChartOfAccountBudget from './queryGraphql/queryAllChartOfAccountBudget'

import "./style/manageRequest.scss"

// component
import FormHeadCheckType from './formHeadCheckType/formHeadCheckType'
import FormRequisition from './formRequisition/formRequisition'
import FormCompanyComparative from './formCompanyComparative/formCompanyComparative'
import FormContactDetails from './formContactDetails/formContactDetails'
import FormSummaryAndAdditional from './formSummaryAndAdditional/formSummaryAndAdditional'
import FormCondiotionWaranty from './formCondiotionWaranty/formCondiotionWaranty'
import ClaimTopMenuNavigation from '../claim/claimTopMenuNavigation'
import i18next from 'i18next'

const query = graphql`
  query createFormManageRequestQuery($type_In: String){
    productViewer{
      allProduct (type_In: $type_In,isActive: true){
        edges{
          node{
            id
            name
            productCode
            cost
            type
            price
            chartOfAccount{
                id
                chartOfAccountCode
                name
            }
            description
            taxRate
            inputTax
            outputTax
          }
        }
      }
    }
    viewer{
      allChartOfAccount(codeList: ["1210","1230-01"]){
        edges{
          node{
            id
            chartOfAccountCode
            name
          }
        }
      }
    }
    myUser {
      juristic {
          id firstName lastName image role
      }
    }
  }
`

const mutation = graphql`
  mutation createFormManageRequestMutation($input: CreateProcurementFormInput!) {
    createProcurementForm(input:$input) 
    {
      ok
      message
    }
  }
`;


let transaction = {
  list_id: "",
  description: "",
  unit_items: "1",
  price: "",
  unit: "",
  budget: "",
  output_tax: "",
  wht_rate: ""
}
export default class createFormManageRequest extends Component {

  constructor(props) {
    super(props)

    this.state = {
      purchaseRequisition: {
        creationDate: new Date(),
        addPurchasing: {
          title: "",
          detail: ""
        },
        creditorId: "",
        buyDate: new Date(),
        contractNumber: "",
        contractDate: new Date(),
        paymentTerms: "",
        otherPaymentTerms: "",
        productAndServiceId: "",
        chartOfAccountId: "",
        facilityUse: "",
        procurementFormContract: [],
        urgent: false,
        startContractDate: new Date(),
        endContractDate: new Date(),
        contractPeriod: "",
        procurementFormList: [transaction],
        comparativeCompany: "",
        lowestOffer: "",
        highestOffer: "",
        procurementFormComparative: [],
        contractor: "",
        contractorTel: "",
        contractorEmail: "",
        deliveryAddress: "",
        deliveryDate: new Date(),
        procurementFormImage: [],
        reason: "",
        feedback: "",
        remark: "",
        conditionWaranty: "",
        conditionWarantyOther: "",
        presenterName: "",
        presenterRole: "",
        signreq:Math.random().toString(36),

      },
      productAndServiceList: [],
      vat: "", 
      statusSubmit: "",
      redirectTo: false,
      optionConditionWaranty: [
        { value: 30, label: "30 "+ i18next.t("purchaseRequisition:Days") , name: 'purchaseRequisition.conditionWaranty' },
        { value: 60, label: "60 "+ i18next.t("purchaseRequisition:Days"), name: 'purchaseRequisition.conditionWaranty' },
        { value: 90, label: "90 "+ i18next.t("purchaseRequisition:Days"), name: 'purchaseRequisition.conditionWaranty' },
        { value: 120, label: "120 "+ i18next.t("purchaseRequisition:Days"), name: 'purchaseRequisition.conditionWaranty' },
        { value: "other", label: i18next.t("purchaseRequisition:Specify"), name: 'purchaseRequisition.conditionWaranty' },
        { value: "no", label: i18next.t("purchaseRequisition:No warranty"), name: 'purchaseRequisition.conditionWaranty' },
      ]
    }
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
  }

  componentWillMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.purchaseRequisition.creditorId !== this.state.purchaseRequisition.creditorId) {
      fetchQuery(environment, queryContact, { id: this.state.purchaseRequisition.creditorId }).then((response) => {
        this.setState(
          ({ purchaseRequisition }) => ({
            purchaseRequisition: {
              ...purchaseRequisition,
              contractor: `${response.contact.firstName} ${response.contact.lastName}`,
              contractorTel: response.contact.phone,
              contractorEmail: response.contact.email,
              deliveryAddress: "",
            }
          }), () => this.setState({ vat: response.contact.vat })
        );

      })
    }

  }

  getData = () => {

    let vilableType = ""

    if (this.props.match.params.type === "product") {
      vilableType = "product"
    } else if (this.props.match.params.type === "service" || this.props.match.params.type === "service_contact") {
      vilableType = "expense"
    }

    fetchQuery(environment, query, { type_In: vilableType }).then((response) => {

      let productAndServiceLists = [];

      if (response.productViewer && (this.props.match.params.type === "product" || this.props.match.params.type === "service" || this.props.match.params.type === "service_contact")) {
        _.map(response.productViewer.allProduct.edges, (product) => {
          productAndServiceLists.push(product)
        })
      }
      if (response.viewer && this.props.match.params.type === "asset") {

        _.map(response.viewer.allChartOfAccount.edges, (chart_of_account) => {
          let list_object = {
            id: chart_of_account.node.id,
            name: chart_of_account.node.name,
            productCode: chart_of_account.node.chartOfAccountCode,
            price: "",
            description: "",
            taxRate: "",
            chartOfAccount: {
              id: chart_of_account.node.id,
              chartOfAccountCode: chart_of_account.node.chartOfAccountCode,
              name: chart_of_account.node.name,
            },
            type: "chart_of_account",
          };

          productAndServiceLists.push({ node: list_object })
        })
      }

      this.setState({
        productAndServiceList: productAndServiceLists,
        presenterName: `${response.myUser.juristic.firstName} ${response.myUser.juristic.lastName}`,
        presenterRole: `${response.myUser.juristic.role}`
      })

    })
  }

  checkPurchaseRequisitionType = (type) => {

    let valueResult = ""

    switch (type) {
      case "product":
        valueResult = i18next.t("purchaseRequisition:Product")
        break;
      case "service":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(General work)")
        break;
      case "service_contact":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(Contract)")
        break;
      case "asset":
        valueResult = i18next.t("purchaseRequisition:Asset")
        break;

      default:
        break;
    }
    return valueResult
  }

  appendFileInputRow = (name, fileInput) => {
    let file = { node: { fileName: fileInput.node.nameFile, fileUpload: fileInput.node.fileUpload } }

    let newFile = []

    if (name === "purchaseRequisition.procurementFormContract") {
      newFile = [...this.state.purchaseRequisition.procurementFormContract, { ...file }]
    } else if (name === "purchaseRequisition.procurementFormComparative") {
      newFile = [...this.state.purchaseRequisition.procurementFormComparative, { ...file }]
    }

    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), name, newFile);
    });
  }

  appendRequisitionListRow = (name) => {
    let transactionItem = _.cloneDeep(transaction);
    let newTransaction = []

    newTransaction = [...this.state.purchaseRequisition.procurementFormList, { ...transactionItem }]

    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "purchaseRequisition.procurementFormList", newTransaction);
    });
  }

  handleRemoveRequisitionListRow = (rowIndex) => {
    let procurementFormList = [...this.state.purchaseRequisition.procurementFormList];
    procurementFormList.splice(rowIndex, 1);

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), "purchaseRequisition.procurementFormList", procurementFormList)
    })
  }

  handleRemoveImgSelect = (name, index) => {

    let listsSplice = []

    if (name === "purchaseRequisition.procurementFormImage") {
      listsSplice = [...this.state.purchaseRequisition.procurementFormImage]
      listsSplice.splice(index, 1)
    } else if (name === "purchaseRequisition.procurementFormComparative") {
      listsSplice = [...this.state.purchaseRequisition.procurementFormComparative]
      listsSplice.splice(index, 1)
    } else if (name === "purchaseRequisition.procurementFormContract") {
      listsSplice = [...this.state.purchaseRequisition.procurementFormContract]
      listsSplice.splice(index, 1)
    }

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, listsSplice)
    })

  }

  handleChangeSelect = (e) => {
    let dataProps = e.value ? e.value : e.target.value
    let dataName = e.value ? e.name : "purchaseRequisition.conditionWarantyOther"
    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), dataName, dataProps)
    });
  }

  handleChangeInput = (e) => {
    let { name, value } = e.target;

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  handleChangeDate = (name, date) => {

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, date)
    });
  }

  handleInputImage = (even) => {
    if (even.currentTarget.files[0] && even.currentTarget.files[0].type.split("/")[0] === "image") {
      let file = { node: { fileUpload: even.currentTarget.files[0] } }

      let newFile = [...this.state.purchaseRequisition.procurementFormImage, { ...file }]

      this.setState((prevState) => {
        return _.set(_.cloneDeep(prevState), "purchaseRequisition.procurementFormImage", newFile);
      });

    } else {
      Swal.fire(i18next.t("Allaction:Invalid file type"), i18next.t("Allaction:Please check again"), "warning");
    }
  }

  handleChangeProcurementFormList = (e) => {
    let { name, value } = e.target;
    let newTransaction = _.cloneDeep(transaction)
    let butgetOfItem = 0

    let procurementFormListArr = name.split(":")
    let productAndServiceListArr = _.filter(this.state.productAndServiceList, (o) => o.node.id === value)

    if (productAndServiceListArr[0].node.type === "EXPENSE" || productAndServiceListArr[0].node.type === "PRODUCT") {
      fetchQuery(
        environment,
        queryAllChartOfAccountBudget,
        {
          issuedDate: format(this.state.purchaseRequisition.creationDate, "YYYY-MM-DD"),
          lastDate: format(this.state.purchaseRequisition.buyDate, "YYYY-MM-DD"),
          productAndServiceId: productAndServiceListArr[0].node.id
        }
      ).then((response) => {
        if (response.allChartOfAccountBudget.edges.length > 0) {
          let summaryYear = JSON.parse(response.allChartOfAccountBudget.edges[0].node.summaryYear)
          butgetOfItem = summaryYear.budget_remaining
          
          // this.setProcurementFormList(
          //   procurementFormListArr, 
          //   productAndServiceListArr, 
          //   newTransaction, 
          //   value, 
          //   butgetOfItem
          // )
        }
        else{
          // this.setProcurementFormList(
          //   procurementFormListArr, 
          //   productAndServiceListArr, 
          //   newTransaction, 
          //   value, 
          //   butgetOfItem
          // )
        }
      })
    }else{
      // this.setProcurementFormList(
      //   procurementFormListArr, 
      //   productAndServiceListArr, 
      //   newTransaction, 
      //   value, 
      //   butgetOfItem
      // )
    }

    _.map(productAndServiceListArr, (item, index) => {
      _.set(newTransaction, "list_id", value)
      _.set(newTransaction, "description", item.node.name || "")
      _.set(newTransaction, "unit_items", "1")
      // _.set(newTransaction, "unit", "1")
      // _.set(newTransaction, "output_tax", value)
      _.set(newTransaction, "price", item.node?.cost || "")
      _.set(newTransaction, "budget", butgetOfItem)
      _.set(newTransaction, "wht_rate", item.node.taxRate || "")
    })

    let newName = `purchaseRequisition.procurementFormList[${parseInt(procurementFormListArr[1])}]`

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), newName, newTransaction)
    });
  }


  handleSubmit = async (e) => {

    e.preventDefault()

    const swalSaveFormManageRequest = Swal.mixin({
      customClass: {
        confirmButton: 'swal-BtnConfirm',
        cancelButton: 'swal-BtnCancel ml-3',
      },
      width: '640px',
      buttonsStyling: false
    })

    let newProcurementList = []
    let totalsAmount = 0
    let sumVat = 0
    let preTaxAmount = 0
    let totalItem = 0

    _.forEach(this.state.purchaseRequisition.procurementFormList, (item) => {

      preTaxAmount = parseFloat(item.unit_items) * parseFloat(item.price)
      sumVat = preTaxAmount * parseFloat(this.state.vat || 0) / 100;
      totalItem = preTaxAmount + sumVat

      let itemProcurementList = {
        list_id: item.list_id,
        description: item.description,
        unit_items: parseFloat(item.unit_items),
        price: parseFloat(item.price),
        unit: item.unit,
        budget: parseFloat(item.budget || 0),
        output_tax: parseFloat(this.state.vat || 0),
        wht_rate: parseFloat(item.wht_rate || 0),
        total: totalItem,
        discount: null,
        pre_tax_amount: preTaxAmount,
      }

      totalsAmount += totalItem
      newProcurementList.push(itemProcurementList)
    })

    let newProcurementFormContract = []

    if (this.state.purchaseRequisition.procurementFormContract.length > 0) {
      for (const item of _.cloneDeep(this.state.purchaseRequisition.procurementFormContract)) {
        let new_file = new File([item.node.fileUpload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${item.node.fileName}`);
        let data = await upload(`/contract_expense/contract`, new_file)
        newProcurementFormContract.push({ file_upload: data.key.replace("silverman-storage/private/", ""), file_name: item.node.fileName })
      }

    }

    let newProcurementFormComparative = []

    if (this.state.purchaseRequisition.procurementFormComparative.length > 0) {
      for (const item of this.state.purchaseRequisition.procurementFormComparative) {
        let new_file = new File([item.node.fileUpload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${item.node.fileName}`);
        let data = await upload(`/contract_expense/comparative`, new_file);
        newProcurementFormComparative.push({ file_upload: data.key.replace("silverman-storage/private/", ""), file_name: item.node.fileName })
      }
    }

    let newProcurementFormImage = []

    if (this.state.purchaseRequisition.procurementFormImage.length > 0) {

      for (const item of this.state.purchaseRequisition.procurementFormImage) {
        let new_file = new File([item.node.fileUpload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${item.node.fileUpload.name}`);
        let data = await upload(`/contract_expense/image`, new_file);
        newProcurementFormImage.push(data.key.replace("silverman-storage/private/", ""))
      }
    }

    let contractPeriod = differenceInCalendarMonths(this.state.purchaseRequisition.endContractDate, this.state.purchaseRequisition.startContractDate)
    if (getDate(this.state.purchaseRequisition.startContractDate) < getDate(this.state.purchaseRequisition.endContractDate)) {
      contractPeriod += 1
    }
    let conditionWaranty = 0
    let waranty = this.state.purchaseRequisition.conditionWaranty

    conditionWaranty = waranty !== "other" && waranty!== "no"? 
      parseFloat(waranty) : waranty === "no" || waranty === "" ? 0 : 
        this.state.purchaseRequisition.conditionWarantyOther 
    
    let variables = {
      input: {
        type: this.props.match.params.type,
        purchasing: this.state.purchaseRequisition.addPurchasing.detail,
        creditorId: this.state.purchaseRequisition.creditorId,
        buyDate: format(this.state.purchaseRequisition.buyDate, "YYYY-MM-DD"),
        contractNumber: this.state.purchaseRequisition.contractNumber,
        contractDate: this.state.purchaseRequisition.contractNumber ? format(this.state.purchaseRequisition.contractDate, "YYYY-MM-DD") : null,
        paymentTerms: this.state.purchaseRequisition.paymentTerms,
        otherPaymentTerms: this.state.purchaseRequisition.paymentTerms === "other" ? this.state.purchaseRequisition.otherPaymentTerms : "",
        // productAndServiceId: this.state.purchaseRequisition.productAndServiceId,
        chartOfAccountId: this.state.purchaseRequisition.chartOfAccountId,
        facilityUse: this.state.purchaseRequisition.facilityUse,
        procurementFormContract: newProcurementFormContract,
        // 2
        urgent: this.state.purchaseRequisition.urgent,
        startContractDate: format(this.state.purchaseRequisition.startContractDate, "YYYY-MM-DD"),
        endContractDate: format(this.state.purchaseRequisition.endContractDate, "YYYY-MM-DD"),
        contractPeriod: contractPeriod,
        procurementFormList: newProcurementList,
        // 3
        comparativeCompany: parseFloat(this.state.purchaseRequisition.comparativeCompany),
        lowestOffer: parseFloat(this.state.purchaseRequisition.lowestOffer),
        highestOffer: parseFloat(this.state.purchaseRequisition.highestOffer),
        procurementFormComparative: newProcurementFormComparative,
        // 4
        contractor: this.state.purchaseRequisition.contractor,
        contractorTel: this.state.purchaseRequisition.contractorTel,
        contractorEmail: this.state.purchaseRequisition.contractorEmail,
        deliveryAddress: this.state.purchaseRequisition.deliveryAddress,
        deliveryDate: format(this.state.purchaseRequisition.deliveryDate, "YYYY-MM-DD"),
        //5
        procurementFormImage: newProcurementFormImage,
        reason: this.state.purchaseRequisition.reason,
        feedback: this.state.purchaseRequisition.feedback,
        remark: this.state.purchaseRequisition.remark,

        status: this.state.statusSubmit,
        total: Math.round((totalsAmount + Number.EPSILON) * 100) / 100,
        warrantyDay: conditionWaranty,
        clientMutationId:"PRF"+this.state.signreq,
      },
    };
 
    if (this.state.statusSubmit === "active") {
      swalSaveFormManageRequest.fire({
        html:
          `<img class="my-4" src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue"/>
        <p><b>
        ${i18next.t("createFromManageRequest:If data is saved, the system will automatically present it to the approver")}<br/>
        ${i18next.t("createFromManageRequest:and cannot be edited until the approver rejects or approves the purchase requisition.")}<br/>
        ${i18next.t("createFromManageRequest:Do you want to continue the process")}?</b></p>`,
        showCancelButton: true,
        confirmButtonText: i18next.t("Allaction:Yes"),
        cancelButtonText: i18next.t("Allaction:cancel"),
      }).then(async (result) => {
        if (result.value) {
          commitMutation(
            environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              if (response.createProcurementForm.ok) {
                Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                  this.setState({
                    redirectTo: '/purchasing/manageRequest/list/all'
                  })
                })
              } else {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), `${response.createProcurementForm.message}`, "error");
              }
            },
            onError: (err) => {
              Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error");
            },
          }
          );
        }
      })
    } else if (this.state.statusSubmit === "draft") {
      commitMutation(
        environment, {
        mutation,
        variables,
        onCompleted: (response) => {
          if (response.createProcurementForm.ok) {
            Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
              this.setState({
                redirectTo: '/purchasing/manageRequest/list/all'
              })
            })
          } else {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error");
          }
        },
        onError: (err) => {
          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error");
        },
      }
      );
    }
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <ClaimTopMenuNavigation mini={true} />

          <div className="container-fluid box" id="createFormManageRequest">
            <form action="submit" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col">
                  <Translation>
                    {
                      t =>
                        <h4 className="mb-4">
                          <Link to={{
                            pathname: "/purchasing/manageRequest/createManageRequest",
                            query: { type: this.props.match.params.type }
                          }}
                          >
                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                              alt="back" className="back" />
                          </Link>
                          {t("purchaseRequisition:Create a new purchase requisition")}
                        </h4>
                    }
                  </Translation>
                </div>
              </div>

              <div className="content-inner">
                <div className="row spaceTop">
                  <div className="col">
                    <h5 className="headCheckType">{i18next.t("purchaseRequisition:Purchase requisition type")}: {this.checkPurchaseRequisitionType(this.props.match.params.type)}</h5>
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormHeadCheckType
                      handleChangeDate={this.handleChangeDate}
                      handleChangeInput={this.handleChangeInput}
                      appendFileInputRow={this.appendFileInputRow}
                      handleRemoveImgSelect={this.handleRemoveImgSelect}
                      purchaseRequisition={this.state.purchaseRequisition}
                      purchaseType={this.props.match.params.type} //เช่น สินค้า สินทรัพย์
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">

                    <FormRequisition
                      urgent={this.state.purchaseRequisition.urgent}
                      procurementFormList={this.state.purchaseRequisition.procurementFormList}
                      purchaseRequisition={this.state.purchaseRequisition}
                      vat={this.state.vat}
                      handleChangeInput={this.handleChangeInput}
                      appendRequisitionListRow={this.appendRequisitionListRow}
                      handleRemoveRequisitionListRow={this.handleRemoveRequisitionListRow}
                      handleChangeDate={this.handleChangeDate}
                      purchaseType={this.props.match.params.type} //เช่น สินค้า สินทรัพย์
                      handleChangeProcurementFormList={this.handleChangeProcurementFormList}
                      productAndServiceList={this.state.productAndServiceList}
                      optionConditionWaranty={this.state.optionConditionWaranty}
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormCondiotionWaranty
                      handleRemoveImgSelect={this.handleRemoveImgSelect}
                      handleChangeSelect={this.handleChangeSelect}
                      purchaseRequisition={this.state.purchaseRequisition}
                      optionConditionWaranty={this.state.optionConditionWaranty}
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormCompanyComparative
                      appendFileInputRow={this.appendFileInputRow}
                      handleRemoveImgSelect={this.handleRemoveImgSelect}
                      handleChangeInput={this.handleChangeInput}
                      purchaseRequisition={this.state.purchaseRequisition}
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormContactDetails
                      type={this.props.match.params.type}
                      handleChangeDate={this.handleChangeDate}
                      purchaseRequisition={this.state.purchaseRequisition}
                      handleChangeInput={this.handleChangeInput}
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                  <FormSummaryAndAdditional
                      vat={this.state.vat}
                      presenterName={this.state.presenterName}
                      purchaseRequisition={this.state.purchaseRequisition}
                      handleChangeInput={this.handleChangeInput}
                      handleInputImage={this.handleInputImage}
                      handleRemoveImgSelect={this.handleRemoveImgSelect}
                      disibleInput={this.state.disibleInput}
                      presenterRole={this.state.presenterRole}
                    />
                     
                  </div>
                </div>

                <div className="row spaceTop mb-5">
                  <div className="col d-flex justify-content-end">
                    <button type="submit" className="btnDraft" onClick={() => this.setState({ statusSubmit: "draft" })}>
                      <span>{i18next.t("purchaseRequisition:Save as draft")}</span>
                    </button>
                    <button type="submit" className="ml-5 btnSave" onClick={() => this.setState({ statusSubmit: "active" })}>
                      <span>{i18next.t("purchaseRequisition:Save")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </WrapperContent>
      </Wrapper>
    )
  }
}

