import React, {Component} from 'react';
import {Translation} from "react-i18next";
import {Link} from "react-router-dom";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import {fetchQuery} from "relay-runtime";
import environment from '../env/environment';
import {graphql} from "babel-plugin-relay/macro";

const _ = require('lodash');
const query = graphql`
    query registerTopMenuNavigationQuery {
        ownershipSummaryPercent
        allNoticeForeignOwnership {
        totalCount
            edges {
                node {
                    id
                    percentNoti
                }
            }
        }
    }
`;

class RegisterTopMenuNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
          allNoti: "",
          percentNation: "",
          countNotificationRegister: 0,
        };
        this.queryData = this.queryData.bind(this);
        this.calNumberOfNoti = this.calNumberOfNoti.bind(this);
    }

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
        this.queryData()
    }

    queryData() {
        this.setState({loading: true})
        fetchQuery(environment, query).then(response => {
            this.setState({
                allNoti: response.allNoticeForeignOwnership,
                percentNation: response.ownershipSummaryPercent
            },()=> this.calNumberOfNoti())
        }).catch(error => {
            console.error('Error:', error);
        });
    }

    calNumberOfNoti() {
        let countNotificationRegister = 0;
        this.state.allNoti && this.state.allNoti.edges.map((noti)=>{
            if(JSON.parse(this.state.percentNation).nationality_not_th_percent > noti.node.percentNoti){
                countNotificationRegister++
            }
            return noti
        })
        this.setState({
            countNotificationRegister: countNotificationRegister
        })
    }

    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"))
        return (
            <div className="container-fluid bg-gray">

                <div className={"row "
                +(this.props.mini ? "mini " : "")
                + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                    {this.props.center &&
                        <Translation>{t=><div className="col-12 mt-5 mb-5"><h3 className="text-center">{
                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                            textData?.data?.registerNav.register
                            :t("topMenuNavigation:register")}
                            </h3></div>}</Translation>
                    }

                    <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                        <ul>
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'tenant_tenant'}) 
                                &&
                                <li>
                                    <Link to="/register/residential/list/all">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/register/tenant-icon.png'}
                                            alt="tenant-icon"
                                            className={this.props.mini && 'd-none'}
                                        />
                                        <Translation>
                                            {t =>  
                                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                    <div>{textData?.data?.registerNav.tenant}</div>
                                                        :
                                                    <div>{t('topMenuNavigation:tenant')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_venhicles'}) &&
                                <li>
                                    <Link to="/register/vehicle/all">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/register/car-icon.png'}
                                            alt="car-icon"
                                            className={this.props.mini && 'd-none'}
                                        />
                                        <Translation>
                                            {
                                                t => <div>{t('topMenuNavigation:vehicle')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_keycards'}) &&
                                <li>
                                    <Link to="/register/key-card">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/register/key-card-icon.png'}
                                            alt="key-card-icon"
                                            className={this.props.mini && 'd-none'}
                                        />
                                        <Translation>
                                            {
                                                t => <div>
                                                    {t('topMenuNavigation:key card')}
                                                </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                                                        {
                                // _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'tenant_tenant'}) &&
                                <li>
                                    <Link to="/register/foreign-ownership">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icons/foreignOwnership.png'}
                                            alt="fluent_document-person-20-regular"
                                            className={this.props.mini && 'd-none'}
                                        />
                                        <Translation>
                                            {
                                                t => <div>
                                                    {t('topMenuNavigation:foreignOwnership')}
                                                    {this.state.countNotificationRegister > 0 &&
                                                        <span className="badge">
                                                           {this.state.countNotificationRegister}
                                                        </span>
                                                    }
                                                </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_pets'}) &&
                                <li>
                                    <Link to="/register/pet">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/register/pet-icon.png'}
                                            alt="pet-icon"
                                            className={this.props.mini && 'd-none'}
                                        />
                                        <Translation>
                                            {
                                                t => <div>{t('topMenuNavigation:pet')} </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'register_ownership'}) &&
                                <li>
                                    <Link to="/register/ownership/list">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/register/ownership-icon.png'}
                                            alt="ownership-icon"
                                            className={this.props.mini && 'd-none'}
                                        />
                                        <Translation>
                                            {
                                                t =>  _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                        <div>{this.props.mini? textData?.data?.registerNav.ownerHistoryNavbar : textData?.data?.registerNav.ownerHistory}</div>
                                                        :
                                                        <div>{t('topMenuNavigation:ownership')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'tenant_tenant'}) &&
                                <li>
                                    <Link to="/register/residentialhistory/list">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icons/fluent_document-person-20-regular.png'}
                                            alt="fluent_document-person-20-regular"
                                            className={this.props.mini && 'd-none'}
                                        />
                                        <Translation>
                                        {
                                            t => _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                    <div>{textData?.data?.registerNav.residentialHistory}</div>
                                                        :
                                                    <div>{t('topMenuNavigation:ResidentialHistory')}</div>
                                        }
                                        </Translation>
                                    </Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisterTopMenuNavigation;