import React from 'react';
import chartJson from './json/chart_of_account';
import _ from 'lodash';
import {graphql} from "babel-plugin-relay/macro";
import Wrapper from "../../components/wrapper";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import RowRender from "./rowRender";
import "./styles/styles.scss";
import environment from "../../env/environment";
import {fetchQuery} from "relay-runtime";
import ExportChartOfAccount from "./export";

const query = graphql`
    query chartOfAccountAllQuery {
        viewer {
            allChartOfAccount(getHistoricalValue: true) {
                totalCount
                edges {
                    node {
                        id name nameEn chartOfAccountCode totalHistoricalDebit totalHistoricalCredit
                    }
                }
            }
        }
    }
`;

class ChartOfAccount extends React.Component {

    constructor(pros) {
        super(pros);
        this.state = {
            treeValue: [],
            chartTree: [],
            chartCount: {},
            chartCountTotal: 0,
            chartOfAccountList: [],
        };
        this.genChartList = this.genChartList.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.getChartQueryData = this.getChartQueryData.bind(this);
        this.countChart = this.countChart.bind(this);
    }

    getCategory(code) {
        let categoryCode = code.charAt(0);
        if (categoryCode === '1') {
            return "ASSET"
        }
        if (categoryCode === '2') {
            return "DEBT"
        }
        if (categoryCode === '3') {
            return "EQUITY"
        }
        if (categoryCode === '4') {
            return "REVENUE"
        }
        if (categoryCode === '5') {
            return "EXPENSE"
        }
        // if (categoryCode === '9') {
        //     return "EXPENSE"
        // }
    }

    getChartQueryData(parent, data) {
        let chartList = [];
        data.viewer.allChartOfAccount.edges.map((chart) => {
            let chartCode = chart.node.chartOfAccountCode;
            if(chartCode.substring(0, 4) === parent.key) {
                chartList.push({data: {
                    id: chart.node.id,
                    code: chartCode,
                    name: chart.node.name,
                    nameEn: chart.node.nameEn,
                    totalHistoricalDebit: chart.node.totalHistoricalDebit,
                    totalHistoricalCredit: chart.node.totalHistoricalCredit,
                    category: this.getCategory(chartCode)
                }});
            }
            return true;
        });
        return chartList;
    }

    genChartList(chart, chartTree, data) {
        chart.map((chart) => {
            let child = [];
            if (_.get(chart, 'child', []).length === 0) {
                // append data form database
                child = this.getChartQueryData(chart, data);
            }

            chartTree.push({
                data: {
                    code: chart.key + "-00",
                    name: chart.name.replace(chart.key + "-00 ", ""),
                    historical: 0,
                    category: this.getCategory(chart.key)
                },
                children: child
            });

            if (_.get(chart, 'child', []).length > 0) {
                this.genChartList(chart['child'], child, data);
            }
            return true;
        });
    };

    countChart(data) {
        let chartCount = {};
        data.viewer.allChartOfAccount.edges.map((chart) => {
            let firstChartCode = chart.node.chartOfAccountCode.charAt(0);
            return _.set(chartCount, firstChartCode, _.get(chartCount, firstChartCode, 0)+1)
        });
        this.setState({chartCount: chartCount});
    }

    componentDidMount() {
        fetchQuery(environment, query, {})
            .then(data => {
                this.countChart(data);
                let chartTree = [];
                this.genChartList(chartJson, chartTree, data);
                this.setState({
                    chartTree: chartTree,
                    chartCountTotal: data.viewer.allChartOfAccount.totalCount,
                    chartOfAccountList: data.viewer.allChartOfAccount.edges
                });
            });
    }

    render() {

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <Translation>
                {t => (
                    <WrapperContent disabledOverflowX={true}>
                        <AccountingTopMenuNavigation mini={true}/>
                        <div className="container-fluid box">

                            <div className="row justify-content-between">
                                <div className="col form-inline">
                                    <h3 className="mb-4">
                                        <Link to="/setting">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back"/>
                                        </Link>
                                        <span>{t('accounting:chart of accounting')}</span>
                                    </h3>
                                    <h6 className="mb-3 ml-2">
                                        ({this.state.chartCountTotal} {t('chartofaccountSetting:items')})
                                    </h6>
                                </div>

                                <div className="col text-right">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_chart_of_account_print'}) &&
                                    <ExportChartOfAccount chartTree={this.state.chartTree}/>
                                }
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_chart_of_account_create'}) &&
                                    <Link to="/accounting/chart-of-account/form">
                                        <button type="button" className="btn btn-primary add" data-toggle="modal"
                                                data-target="#exampleModal">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                            {t('chartofaccountSetting:Add Chart of Account')}
                                        </button>
                                    </Link>
                                    }
                                </div>
                            </div>

                            <div className="content-inner">
                                <div className="row">
                                    <div className="col">

                                        <div className="table-responsive fade-up card table-hover">
                                            <table className="table chart-of-account-table">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th width={120}>{t('chartofaccountSetting:Account no.')}</th>
                                                    <th width={900}>{t("chartofaccountSetting:Account name's")}</th>
                                                    <th className="text-center">{t('chartofaccountSetting:Account category')}</th>
                                                    <th>{t('chartofaccountSetting:Category')}</th>
                                                    <th className="text-right">{t('chartofaccountSetting:Debit')}</th>
                                                    <th className="text-right">{t('chartofaccountSetting:Credit')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.chartTree.length > 0 ?
                                                <RowRender chartTree={this.state.chartTree} level={0} chartCount={this.state.chartCount}/> :
                                                <tr><td colSpan={5}>loading...</td></tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapperContent>
                )}  
                </Translation>
            </Wrapper>

        )
    }
}

export default ChartOfAccount;
