

import React, { Component } from 'react';
import './silvermanAgent.scss'
class SilvermanAgentStatus extends Component {

    constructor(props) {
        super(props)

        this.state = {
            status : '',
            agent : '',
            owner :'',
            buyer: '',
            tenant: ''
        }
    }

    setStatus(value){
        if(value ===  "owner"){
            this.setState({
                status : value,
                owner : value
            })
        }else{
            this.setState({ owner : ''})
        }
        
        if (value ===  "buyer"){
            this.setState({
                status : value,
                buyer : value
            })
        }else{
            this.setState({buyer : ''})
        }
        
        if(value ===  "agent"){
            this.setState({
                status : value,
                agent : value
            })
        }else{
            this.setState({agent : ''})
        }

        if (value ===  "tenant"){
            this.setState({
                status : value,
                tenant : value
            })
        }else{
            this.setState({ tenant : '' })
        }

        this.props.onSetAgentStatus(value)  
    }

    render() {
        return (
        
            <>
                <div id="wrapper-content" style={{backgroundColor:'#E5E5E5'}}>
                <div id='svm-agent-status'>
                    <div>
                        <div style={{backgroundColor:'#fff'}} className='p-4'>
                            {/* <div className="mb-6">
                                <Link to={{ pathname:`/silvermanagent/silvermanagent-accept`}}> 
                                <img src={process.env.PUBLIC_URL + "/images/icons/go-back.png"}/>
                                </Link>
                            </div> */}
                        <div>
                                <h4 className='svmtitle-blue'>{this.props.lang === 'en'? 'Let Silverman Agent assist you' : 'ให้ Silverman Agent เป็นตัวช่วย' }</h4>
                                <h4 className='svmtitle-blue'>{this.props.lang === 'en'? 'with buying, selling, leasing, or renting' : 'ในการซื้อ-ขาย-ปล่อย-เช่า' }</h4>
                                <h5 className='svmtitle-blue'>{this.props.lang === 'en'? 'your property today' : 'อสังหาริมทรัพย์คุณได้แล้ววันนี้' } 
                                <span className='svmtitle-red'>{this.props.lang === 'en' ? ' Free consultation' :' ปรึกษาฟรี'}</span></h5>
                        </div> 
                        <div className='svm-agent-content mt-4'>
                                 {this.props.lang === 'en' ? 'You can rely on Silverman Agent to assist you with:' :'คุณสามารถให้ Silverman Agent เป็นตัวช่วยในเรื่อง:'}
                                <br/>{this.props.lang === 'en' ?'Buying real estate at the right price' :'ซื้อ-อสังหาริมทรัพย์ในราคาที่ใช่'}
                                <br/>{this.props.lang === 'en' ?'Selling real estate at a satisfactory price' :'ขาย-อสังหาริมทรัพย์ในราคาที่พอใจ'}
                                <br/>{this.props.lang === 'en' ?'Leasing real estate for rent quickly' :'ปล่อย-อสังหาริมทรัพย์ให้เช่ารวดเร็วทันใจ'}
                                <br/>{this.props.lang === 'en' ?'Renting real estate in the location at a happy price' :'เช่า-อสังหาริมทรัพย์ในทำเล+ราคาที่แฮปปี้'}
                                <br/>{this.props.lang === 'en' ?'Consult with us for free!' :'ปรึกษาเราฟรี!'}

                                <div className='mt-4'>
                                    {/* <p><img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/phone.png"}/> 081-442-6888 </p> */}
                                    <p><img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/mail.png"}/> agent@silverman.app</p>
                                </div>
                        </div>
                        </div>
                        <div className='svm-agent-content mt-4 p-4' style={{backgroundColor:'#fff'}} >
                            <h5 className='text-center'>{this.props.lang === 'en' ? 'Select your status':'เลือกสถานะของคุณ'}</h5>
                            <div className='row'>
                                <div className='col'>
                                    <div className={`svm-agent-select text-center ${this.state.owner ? this.state.owner :''}`} id="owner" onClick={ () => this.setStatus("owner")}>
                                        <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/owner.png"} className="pt-5"/>
                                        <p>{this.props.lang === 'en' ? 'Landlord' : 'เจ้าของทรัพย์' }</p>
                                    </div>
                                </div>
                                {/* <div className='col'>
                                    <div className={`svm-agent-select text-center ${this.state.agent ? this.state.agent  :''}`} id="agent" onClick={() => this.setStatus("agent")}>
                                        <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/agent.png"} className="pt-5"/>
                                        <p>ตัวแทน (Agent)</p>
                                    </div>
                                </div> */}
                            </div>
                            <div className='row pt-4'>
                                <div className='col'>
                                    <div className={`svm-agent-select text-center ${this.state.tenant ? this.state.tenant  :''}`} id="tenant" onClick={() => this.setStatus("tenant")}>
                                        <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/tenant.png"} className="pt-5"/>
                                        <p>{this.props.lang === 'en' ? 'Tenant' : 'ผู้เช่า' }</p>
                                        </div>
                                </div>
                                <div className='col'>
                                    <div className={`svm-agent-select text-center ${this.state.buyer ? this.state.buyer  :''}`} id="buyer" onClick={() => this.setStatus("buyer")}>
                                        <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/buyer.png"} className="pt-5"/>
                                        <p>{this.props.lang === 'en' ? 'Buyer' : 'ผู้ซื้อ' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='footer-svm-agent'>
                            <div className='pt-6 pb-6'>
                                    <button onClick={ () => this.props.onSetStepFinish(true)} className="btn btn-primary btn-svm-agent">
                                     {this.props.lang === 'en' ? 'Next':'ถัดไป'}
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                </div>
                
            </>
             
        );
    } 
}

export default SilvermanAgentStatus;
