/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateBankUnitInput = {|
  bankUnit: any,
  clientMutationId?: ?string,
|};
export type updateBankUnitMutationVariables = {|
  input: UpdateBankUnitInput
|};
export type updateBankUnitMutationResponse = {|
  +updateBankUnit: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type updateBankUnitMutation = {|
  variables: updateBankUnitMutationVariables,
  response: updateBankUnitMutationResponse,
|};
*/


/*
mutation updateBankUnitMutation(
  $input: UpdateBankUnitInput!
) {
  updateBankUnit(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBankUnitPayload",
    "kind": "LinkedField",
    "name": "updateBankUnit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateBankUnitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateBankUnitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aa6e75ed7c11d282c206f288c892cf43",
    "id": null,
    "metadata": {},
    "name": "updateBankUnitMutation",
    "operationKind": "mutation",
    "text": "mutation updateBankUnitMutation(\n  $input: UpdateBankUnitInput!\n) {\n  updateBankUnit(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '11f3e12e55be9402f2f4e6a8d81c69d2';

module.exports = node;
