import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import {Helmet} from "react-helmet";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import "./fix_request.scss"
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import Loading from "../../libs/loading";

const query = graphql`
    query fixRequestQuery($id: ID!) {
        selfProject {
            id
            name
            address
            juristicContactNumber
            logo
        }
        room(id: $id) {
            id
            residential {
                name
                project{
                    typeOfProject
                }
            }
            name
            detail
            status
            image
            added
            note
            docNumber
            phone
            fixRequestRoom {
                id
                technician
                technicianName
            }
            handle {
                tenant {
                    firstName
                    lastName
                    phone
                    image
                }
            }
        }
    }
`;

class FixRequest extends Component {
    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    repeatDot(count) {
        let dot = '';
        let i;
        for (i = 0; i < count; i++) {
            dot = dot + '.'
        }
        return dot
    }

    print() {
        window.print();
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Fix request</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id: this.props.match.params.id}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {

                            return (
                                <div id="fix_request">
                                    <div className="print-page-a4">
                                        <div className="subpage">
                                            <div className="head">
                                                <div className="juristic-description">
                                                    <img src={props.selfProject.logo}
                                                         alt="silverman"/>
                                                    <strong>{props.selfProject.name}</strong>
                                                    <div>{props.selfProject.address} โทร {props.selfProject.juristicContactNumber || '-'}</div>
                                                </div>
                                            </div>
                                            <Translation>
                                                {t=>
                                                <div className="detail">
                                                    { i18next.language ==='th'?
                                                    <>
                                                                <h5 className="text-center">{t("fix_request:repair_form")}</h5>
                                                                <p className="text-right">
                                                                    {t("fix_request:no")} {props.room.docNumber ? props.room.docNumber : this.repeatDot(50)}
                                                                </p>
                                                                <b>{t("fix_request:part_1")}</b>
                                                                <p>
                                                                    {t("fix_request:item_1")} {props.room.handle.tenant ? (props.room.handle.tenant.firstName + ' ' + props.room.handle.tenant.lastName) : this.repeatDot(100)}
                                                                    {props.room.residential.project.typeOfProject === "VILLAGE" ? ' บ้านเลขที่ ' : ' เลขที่ห้อง '}{' ' + props.room.residential.name}

                                                                </p>
                                                                <p>{t("fix_request:item_2")} {props.room.phone !== '-' ? props.room.phone : props.room.handle.tenant ? props.room.handle.tenant.phone : this.repeatDot(106)}</p>
                                                                <p>{t("fix_request:item_3")} {this.repeatDot(90)} {t("fix_request:time")} {this.repeatDot(59)} {t("fix_request:date")} {this.repeatDot(58)}</p>
                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                                                    <p>{t("fix_request:item_4")} {props.room.detail ? props.room.detail : this.repeatDot(200)}</p>
                                                                    <img style={{ height: 80, marginRight: 8 }} src={props.room.image} alt="fix_request" />
                                                                </div>
                                                                {!props.room.detail &&
                                                                    <React.Fragment>
                                                                        <p>{this.repeatDot(250)}</p>
                                                                        <p>{this.repeatDot(250)}</p>
                                                                    </React.Fragment>
                                                                }
                                                                <p>{t("fix_request:note")} {this.repeatDot(232)}</p>
                                                                <p>{t("fix_request:item_5")} {this.repeatDot(100)} {t("fix_request:position")} {this.repeatDot(80)}</p>
                                                                <b>{t("fix_request:part_2")}</b>
                                                                <p>{t("fix_request:item_6")} {this.repeatDot(235)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{t("fix_request:item_7")} {this.repeatDot(161)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <div className="customer-detail">
                                                                    <p>{t("fix_request:item_8")}</p>
                                                                    <div>
                                                                        <input type="checkbox" id="technician-in" /><label
                                                                            htmlFor="technician-in">&emsp;{t("fix_request:in_house_Handyman")}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="document-detail">
                                                                    <p><input type="checkbox" id="warranty" /><label
                                                                        htmlFor="warranty">&emsp;{t("fix_request:warranty")}</label></p>
                                                                    <div><input type="checkbox" id="technician-out" /><label
                                                                        htmlFor="technician-out">&emsp;{t("fix_request:commercial_Handyman")}</label>
                                                                    </div>
                                                                    <p>&emsp;&emsp;{t("fix_request:no")} {this.repeatDot(65)}</p>
                                                                </div>
                                                                <br />
                                                                <p>{t("fix_request:item_9")} {this.repeatDot(218)}</p>
                                                                <br />
                                                                <div className="customer-detail">
                                                                    <p>{t("fix_request:evaluator")} {this.repeatDot(100)}</p>
                                                                    <p>{t("fix_request:date")} {this.repeatDot(108)}</p>
                                                                </div>
                                                                <div className="document-detail">
                                                                    <p>{t("fix_request:approver")} {this.repeatDot(85)}</p>
                                                                    <p>{t("fix_request:date")} {this.repeatDot(90)}</p>
                                                                </div>
                                                                <b>{t("fix_request:part_3")}</b>
                                                                <p>{t("fix_request:item_10")} {this.repeatDot(205)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{t("fix_request:item_11")}</p>
                                                                <div className="customer-detail">
                                                                    <input type="checkbox" id="pass" /><label
                                                                        htmlFor="pass">&emsp;{t("fix_request:pass")}</label>
                                                                    <br />
                                                                    <br />
                                                                    <b>
                                                                        {t("fix_request:inspector")}&nbsp;
                                                                    </b>
                                                                    {this.repeatDot(70)}
                                                                </div>
                                                                <div className="document-detail">
                                                                    <input type="checkbox" id="no-pass" /><label
                                                                        htmlFor="no-pass">&emsp;{t("fix_request:not_approve")}</label>
                                                                    <br />
                                                                    <br />
                                                                    <b>
                                                                        {t("fix_request:date")}&nbsp;
                                                                    </b>
                                                                    {this.repeatDot(70)}
                                                                </div>
                                                    </>
                                                    :
                                                    <>
                                                                <h5 className="text-center">{t("fix_request:repair_form")}</h5>
                                                                <p className="text-right">
                                                                    {t("fix_request:no")} {props.room.docNumber ? props.room.docNumber : this.repeatDot(50)}
                                                                </p>
                                                                <b>{t("fix_request:part_1")}</b>
                                                                <p>
                                                                    {t("fix_request:item_1")} {props.room.handle.tenant ? (props.room.handle.tenant.firstName + ' ' + props.room.handle.tenant.lastName) : this.repeatDot(100)}
                                                                    {props.room.residential.project.typeOfProject === "VILLAGE" ? ' บ้านเลขที่ ' : ' เลขที่ห้อง '}{' ' + props.room.residential.name}

                                                                </p>
                                                                <p>{t("fix_request:item_2")} {props.room.phone !== '-' ? props.room.phone : props.room.handle.tenant ? props.room.handle.tenant.phone : this.repeatDot(106)}</p>
                                                                <p>{t("fix_request:item_3")} {this.repeatDot(90)} {t("fix_request:time")} {this.repeatDot(54)} {t("fix_request:date")} {this.repeatDot(53)}</p>
                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                                                    <p>{t("fix_request:item_4")} {props.room.detail ? props.room.detail : this.repeatDot(200)}</p>
                                                                    <img style={{ height: 100, marginRight: 8 }} src={props.room.image} alt="fix_request" />
                                                                </div>
                                                                {!props.room.detail &&
                                                                    <React.Fragment>
                                                                        <p>{this.repeatDot(250)}</p>
                                                                        <p>{this.repeatDot(250)}</p>
                                                                    </React.Fragment>
                                                                }
                                                                <p>{t("fix_request:note")} {this.repeatDot(232)}</p>
                                                                <p>{t("fix_request:item_5")} {this.repeatDot(100)} {t("fix_request:position")} {this.repeatDot(60)}</p>
                                                                <b>{t("fix_request:part_2")}</b>
                                                                <p>{t("fix_request:item_6")} {this.repeatDot(215)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{t("fix_request:item_7")} {this.repeatDot(155)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <div className="customer-detail">
                                                                    <p>{t("fix_request:item_8")}</p>
                                                                    <div>
                                                                        <input type="checkbox" id="technician-in" /><label
                                                                            htmlFor="technician-in">&emsp;{t("fix_request:in_house_Handyman")}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="document-detail">
                                                                    <p><input type="checkbox" id="warranty" /><label
                                                                        htmlFor="warranty">&emsp;{t("fix_request:warranty")}</label></p>
                                                                    <div><input type="checkbox" id="technician-out" /><label
                                                                        htmlFor="technician-out">&emsp;{t("fix_request:commercial_Handyman")}</label>
                                                                    </div>
                                                                    <p>&emsp;&emsp;{t("fix_request:no")} {this.repeatDot(58)}</p>
                                                                </div>
                                                                <br />
                                                                <p>{t("fix_request:item_9")} {this.repeatDot(210)}</p>
                                                                <br />
                                                                <div className="customer-detail">
                                                                    <p>{t("fix_request:evaluator")} {this.repeatDot(90)}</p>
                                                                    <p>{t("fix_request:date")} {this.repeatDot(98)}</p>
                                                                </div>
                                                                <div className="document-detail">
                                                                    <p>{t("fix_request:approver")} {this.repeatDot(80)}</p>
                                                                    <p>{t("fix_request:date")} {this.repeatDot(89)}</p>
                                                                </div>
                                                                <b>{t("fix_request:part_3")}</b>
                                                                <p>{t("fix_request:item_10")} {this.repeatDot(201)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{this.repeatDot(250)}</p>
                                                                <p>{t("fix_request:item_11")}</p>
                                                                <div className="customer-detail">
                                                                    <input type="checkbox" id="pass" /><label
                                                                        htmlFor="pass">&emsp;{t("fix_request:pass")}</label>
                                                                    <br />
                                                                    <br />
                                                                    <b>
                                                                        {t("fix_request:inspector")}&nbsp;
                                                                    </b>
                                                                    {this.repeatDot(70)}
                                                                </div>
                                                                <div className="document-detail">
                                                                    <input type="checkbox" id="no-pass" /><label
                                                                        htmlFor="no-pass">&emsp;{t("fix_request:not_approve")}</label>
                                                                    <br />
                                                                    <br />
                                                                    <b>
                                                                        {t("fix_request:date")}&nbsp;
                                                                    </b>
                                                                    {this.repeatDot(70)}
                                                                </div>
                                                    </>
                                                    }
                                                    
                                                </div>
                                                }
                                            </Translation>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />
            </React.Fragment>
        );
    }
}

export default FixRequest;
