import React from "react";
import getApiRoot from "../../libs/getAPIRoot";
import Helpers from '../../libs/file_download_helper';
import { format } from "date-fns";
import { Translation } from "react-i18next";


class ExportFixRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event) => {
        this.setState({
            errors: null,
            loading: true,
        }, () => {
            Helpers.httpRequest(
                getApiRoot() + 'export/fix-request?start_date='
                + format(this.props.state.start_date, 'DD/MM/YYYY')
                + '&end_date=' + format(this.props.state.end_date, 'DD/MM/YYYY')
                + '&status=' + this.props.status
                + '&search=' + this.props.state.search
                + '&room_type=fix_request'
                + '&fix_type=' + this.props.state.fix_request_type,
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "fix-request.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });

        event.preventDefault();
    };

    render() {

        return (
            <form onSubmit={this.handleSubmit}>
                <Translation>
                    {
                        t=>
                <button type="submit" className="btn print float-right mr-3"
                        disabled={this.state.loading}>
                    {this.state.loading ?
                        <span>
                        <span
                            className="spinner-border spinner-border-sm align-middle mr-2"/> {t("register:Preparing information")}</span> :
                        <span>
                        <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                        {t("register:print")}
                        </span>
                    }
                </button>
            }
                </Translation>
            </form>
        )
    }
}

export default ExportFixRequest;
