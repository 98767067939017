/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type wrapperDebtFreeRequestQueryVariables = {|
  debsFreeCertificationRequestId?: ?$ReadOnlyArray<?string>
|};
export type wrapperDebtFreeRequestQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +bankCompCode: ?string,
    +bankServiceCode: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
    +postcodeProject: ?string,
    +provinceProject: ?string,
    +districtProject: ?string,
    +subDistrictProject: ?string,
  |},
  +allDebsFreeCertificationRequest: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +fee: boolean,
        +charge: ?number,
        +phone: ?string,
        +added: any,
        +addressContact: ?string,
        +address: ?string,
        +copyIdCard: boolean,
        +copyHouse: boolean,
        +copyApartment: boolean,
        +copySurnameChange: boolean,
        +otherDocument: boolean,
        +otherDocumentDescription: ?string,
        +attorney: boolean,
        +attorneyBook: ?string,
        +waterAndElectric: boolean,
        +requestingDate: ?any,
        +startSuspend: ?any,
        +endSuspend: ?any,
        +waiting: ?number,
        +lifetime: ?number,
        +nameTransfer: ?string,
        +phoneTransfer: ?string,
        +tenant: {|
          +id: string,
          +firstName: string,
          +lastName: ?string,
          +nameTitle: ?string,
        |},
        +contact: {|
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
          +residential: ?{|
            +size: number
          |},
        |},
      |}
    |}>
  |},
|};
export type wrapperDebtFreeRequestQuery = {|
  variables: wrapperDebtFreeRequestQueryVariables,
  response: wrapperDebtFreeRequestQueryResponse,
|};
*/


/*
query wrapperDebtFreeRequestQuery(
  $debsFreeCertificationRequestId: [String]
) {
  selfProject {
    id
    name
    address
    logo
    juristicContactNumber
    keyProjectQr
    bankCompCode
    bankServiceCode
    taxIdNumber
    typeOfProject
    postcodeProject
    provinceProject
    districtProject
    subDistrictProject
  }
  allDebsFreeCertificationRequest(debsFreeCertificationRequestId: $debsFreeCertificationRequestId) {
    edges {
      node {
        id
        docNumber
        fee
        charge
        phone
        added
        addressContact
        address
        copyIdCard
        copyHouse
        copyApartment
        copySurnameChange
        otherDocument
        otherDocumentDescription
        attorney
        attorneyBook
        waterAndElectric
        requestingDate
        startSuspend
        endSuspend
        waiting
        lifetime
        nameTransfer
        phoneTransfer
        tenant {
          id
          firstName
          lastName
          nameTitle
        }
        contact {
          id
          name
          firstName
          lastName
          residential {
            size
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "debsFreeCertificationRequestId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyProjectQr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankCompCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankServiceCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postcodeProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provinceProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "districtProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subDistrictProject",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "debsFreeCertificationRequestId",
    "variableName": "debsFreeCertificationRequestId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "charge",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressContact",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "copyIdCard",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "copyHouse",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "copyApartment",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "copySurnameChange",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "otherDocument",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "otherDocumentDescription",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attorney",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attorneyBook",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "waterAndElectric",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestingDate",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startSuspend",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endSuspend",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "waiting",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lifetime",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameTransfer",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneTransfer",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantNode",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v28/*: any*/),
    (v29/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameTitle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperDebtFreeRequestQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DebsFreeCertificationRequestNodeConnection",
        "kind": "LinkedField",
        "name": "allDebsFreeCertificationRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DebsFreeCertificationRequestNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DebsFreeCertificationRequestNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v3/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v30/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v31/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperDebtFreeRequestQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DebsFreeCertificationRequestNodeConnection",
        "kind": "LinkedField",
        "name": "allDebsFreeCertificationRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DebsFreeCertificationRequestNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DebsFreeCertificationRequestNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v3/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v30/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v31/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0091eda6275cf941ace7329f8cd8f873",
    "id": null,
    "metadata": {},
    "name": "wrapperDebtFreeRequestQuery",
    "operationKind": "query",
    "text": "query wrapperDebtFreeRequestQuery(\n  $debsFreeCertificationRequestId: [String]\n) {\n  selfProject {\n    id\n    name\n    address\n    logo\n    juristicContactNumber\n    keyProjectQr\n    bankCompCode\n    bankServiceCode\n    taxIdNumber\n    typeOfProject\n    postcodeProject\n    provinceProject\n    districtProject\n    subDistrictProject\n  }\n  allDebsFreeCertificationRequest(debsFreeCertificationRequestId: $debsFreeCertificationRequestId) {\n    edges {\n      node {\n        id\n        docNumber\n        fee\n        charge\n        phone\n        added\n        addressContact\n        address\n        copyIdCard\n        copyHouse\n        copyApartment\n        copySurnameChange\n        otherDocument\n        otherDocumentDescription\n        attorney\n        attorneyBook\n        waterAndElectric\n        requestingDate\n        startSuspend\n        endSuspend\n        waiting\n        lifetime\n        nameTransfer\n        phoneTransfer\n        tenant {\n          id\n          firstName\n          lastName\n          nameTitle\n        }\n        contact {\n          id\n          name\n          firstName\n          lastName\n          residential {\n            size\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '820d3431a8ac00e2149f7c53ca78273a';

module.exports = node;
