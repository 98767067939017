const numberToText = require('number-to-text')

export default function ConvertNumberToTextEng(number) {  
    let parts = ""
    if (typeof number === "number") {
        parts = number.toFixed(2).toString().split('.');
    } else if (typeof number === "string"){
        parts = number.split('.');
    }    
   
    const integerPart = parseInt(parts[0]);
    const decimalPart = parts[1] || '0';
    
    const integerText = numberToText.convertToText(integerPart);
    let resultText = integerText.charAt(0).toUpperCase() + integerText.slice(1);
    
    if (decimalPart !== '0' && parseInt(decimalPart) !== 0) {
        let decimalText = ' point';
        for (let digit of decimalPart) {
            decimalText += ` ${numberToText.convertToText(parseInt(digit))}`;
        }
        resultText += decimalText;
    }
    
    return resultText;
};
