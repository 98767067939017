/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type chartOfAccountCreateUpdateQueryVariables = {|
  id: string
|};
export type chartOfAccountCreateUpdateQueryResponse = {|
  +chartOfAccount: ?{|
    +id: string,
    +name: string,
    +nameEn: ?string,
    +chartOfAccountCode: string,
    +accountRecord: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +debit: number,
          +credit: number,
        |}
      |}>
    |},
  |}
|};
export type chartOfAccountCreateUpdateQuery = {|
  variables: chartOfAccountCreateUpdateQueryVariables,
  response: chartOfAccountCreateUpdateQueryResponse,
|};
*/


/*
query chartOfAccountCreateUpdateQuery(
  $id: ID!
) {
  chartOfAccount(id: $id) {
    id
    name
    nameEn
    chartOfAccountCode
    accountRecord(typeOfRecord: "historical") {
      edges {
        node {
          id
          name
          debit
          credit
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ChartOfAccountNode",
    "kind": "LinkedField",
    "name": "chartOfAccount",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameEn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "chartOfAccountCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "typeOfRecord",
            "value": "historical"
          }
        ],
        "concreteType": "AccountRecordNodeConnection",
        "kind": "LinkedField",
        "name": "accountRecord",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountRecordNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountRecordNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "debit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "credit",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "accountRecord(typeOfRecord:\"historical\")"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "chartOfAccountCreateUpdateQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "chartOfAccountCreateUpdateQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a0b801295ca149e80c522ff70cccc750",
    "id": null,
    "metadata": {},
    "name": "chartOfAccountCreateUpdateQuery",
    "operationKind": "query",
    "text": "query chartOfAccountCreateUpdateQuery(\n  $id: ID!\n) {\n  chartOfAccount(id: $id) {\n    id\n    name\n    nameEn\n    chartOfAccountCode\n    accountRecord(typeOfRecord: \"historical\") {\n      edges {\n        node {\n          id\n          name\n          debit\n          credit\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae7d1dc254dada03a0e1094268a2afee';

module.exports = node;
