import React, { Component } from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { QueryRenderer } from 'react-relay';
import environment from "../../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';
import '../collection.scss'
import SearchProject from '../../../project/searchProject';
import _ from 'lodash'
import { commitMutation } from "react-relay";
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { Redirect } from 'react-router';
import { fetchQuery } from "relay-runtime";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import getNameResidential from '../../../libs/getNameResidential';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import queryCollectionTotal from './query/collectionTotal'


const query = graphql`
query collectionLettersCreateQuery($typOfContact: String, $search: String) {
    allContactDebit (typOfContact: $typOfContact, search: $search) {
        edges{
            node{
                id
                refNumber
                name
                firstName
                lastName
                
            }
        }
    }
    
}`;

const mutation = graphql`
mutation collectionLettersCreateMutation ($input: CreateDebitManualInput!) {
    createDebitManual (input: $input) {
        ok
        message
    }
}`;
class CollectionLettersCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            typOfContact: 'residential',
            startDate: new Date(),
            loading: false,
            selectedContact: [],
            selectedContactID: [],
            loading: false,
            redirect: false,
            checkAll: false
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    selectContact(param, detail) {
        let selectedContact = _.cloneDeep(this.state.selectedContact)
        let selectedContactID = _.cloneDeep(this.state.selectedContactID)
        if (!selectedContactID.includes(param)) {
            selectedContactID.push(param)
            selectedContact.push(detail)
        }
        this.setState({
            selectedContact: selectedContact,
            selectedContactID: selectedContactID
        });
    }

    onDelete = (param) => {
        let selectedContactID = _.cloneDeep(this.state.selectedContactID);
        let selectedContact = _.cloneDeep(this.state.selectedContact)
        let index_id = selectedContactID.indexOf(param);
        if (index_id !== -1) {
            selectedContactID.splice(index_id, 1)
        }
        let index_contact = _.findIndex(selectedContact, { 'node': { 'id': param } })
        if (index_contact !== -1) {
            selectedContact.splice(index_contact, 1)
        }
        this.setState({
            selectedContact: selectedContact,
            selectedContactID: selectedContactID
        });
    }

    clearList = () => {
        this.setState({
            selectedContact: [],
            selectedContactID: [],
            checkAll: false,
        })
    }

    onSubmit = () => {
        this.setState({ loading: true })
        let createDebitManual = []
        let mycontactId
        if (this.state.selectedContactID?.length > 0) {
            this.state.selectedContactID.forEach((id) => {
                mycontactId = id
                createDebitManual.push({ contactId: id, startDate: format(this.state.startDate, 'YYYY-MM-DDT00:00:00') })
            })
            
            if(this.state.selectedContactID?.length > 1){
                console.log("เลือกทั้งหมด",createDebitManual);
                let variables = {
                    input: {
                        createDebitManual: createDebitManual
                    }}
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted: (response) => {
                                this.setState({ loading: false });
                                if (response.createDebitManual && response.createDebitManual?.ok) {
                                    Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                        this.setState({ loading: false, redirect: true });
                                    });
                                } else {
                                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() => {
                                        this.setState({ loading: false });
                                    })
                                }
                            },
                            onError: (err) => {
                                this.setState({ loading: false });
                                Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                            },
                        },
                    )
            }else{
                fetchQuery(environment, queryCollectionTotal, {contactId: mycontactId,startDate: format(this.state.startDate, 'YYYY-MM-DDT00:00:00') }).then((response) => {
                    console.log("เลือกอันเดียว",createDebitManual);
                    if(response.collectionTotal > 0){
                    let variables = {
                    input: {
                        createDebitManual: createDebitManual
                    }}
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted: (response) => {
                                this.setState({ loading: false });
                                if (response.createDebitManual && response.createDebitManual?.ok) {
                                    Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                        this.setState({ loading: false, redirect: true });
                                    });
                                } else {
                                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() => {
                                        this.setState({ loading: false });
                                    })
                                }
                            },
                            onError: (err) => {
                                this.setState({ loading: false });
                                Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                            },
                        },
                    )
                    }else{
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:There is no information on outstanding debtors are overdue."), 'warning').then(() => {
                            this.setState({ loading: false });
                        }) 
                    }
                
                })
            }
        
           

        } else {
            this.setState({ loading: false });
            Swal.fire('Error!', i18next.t("Allaction:Please selected items before continue"), 'warning');
        }
    }

    handleSearch = (text) => {
        this.setState({
            search: text,
        })
    }

    onClickIcon = () => {
        return ;
    }

    checkAll = () => {
        this.setState({
            checkAll: !this.state.checkAll
        }, () => {
            if (!this.state.checkAll) {
                this.setState({
                    selectedContact: [],
                    selectedContactID: [],
                })
            } else {
                fetchQuery(environment, query, {
                    typOfContact: this.state.typOfContact,
                    search: this.state.search
                }).then(data => {
                    let selectedContact = []
                    let selectedContactID = []
                    if (data.allContactDebit && data.allContactDebit.edges.length > 0) {
                        _.forEach(data.allContactDebit.edges, list => {
                            selectedContact.push(list)
                            selectedContactID.push(list.node.id)
                        })
                    }
                    this.setState({
                        selectedContact: selectedContact,
                        selectedContactID: selectedContactID,
                    })
                })
            }
        })
    }

    changeType = (type) => {
        if (type !== this.state.typOfContact) {
            this.setState({
                typOfContact: type,
                checkAll: false,
                selectedContact: [],
                selectedContactID: [],
            })
        }

    }


    render() {
        if (this.state.redirect) {
            return <Redirect to="/accounting/collection_letters/list/draft" />;
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="collection-letter">
                                <BackButtonIcon
                                    LinkBack={"/accounting/collection_letters/list/all"}
                                    LinkText={i18next.t("collectionLettersCreate:Create New Reminder")}

                                />

                                <div className="content-inner" style={{ marginTop: '48px' }}>
                                    <div className="row" style={{ marginBottom: '48px' }}>
                                        <div className="col-12"><h5>{i18next.t("collectionLettersCreate:Create Reminder for")}</h5></div>
                                        <div className="col-12 form-inline mt-1">
                                            <input type="radio"
                                                className="custom-radio mr-3"
                                                onChange={() => this.changeType('residential')}
                                                checked={this.state.typOfContact === 'residential'}

                                            />
                                            <span>{i18next.t("collectionLettersCreate:Co-owners")}</span>
                                        </div>
                                        <div className="col-12 form-inline mt-1">
                                            <input
                                                type="radio" className="custom-radio mr-3"
                                                onChange={() => this.changeType('supplier')}
                                                checked={this.state.typOfContact === 'supplier'}
                                            />
                                            <span>{i18next.t("collectionLettersCreate:Debts")}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-4" style={{ marginBottom: '48px' }}>
                                        <div className="col-6">
                                            <h6>{i18next.t("collectionLettersCreate:Search Transactions")}</h6>
                                        </div>
                                        <div className="col-6">
                                            <h6>{i18next.t("collectionLettersCreate:Document Date")}</h6>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <SearchProject callback_function={this.handleSearch} search={this.state.search} callback_function_click={this.onClickIcon}/>

                                        </div>
                                        <div className="col-2 mb-3">
                                            <DatePickerNoti
                                                selected={this.state.startDate}
                                                name={'startDate'}
                                                onChange={(e) => this.handleChange(e)}
                                                height='16px'
                                                width='16px'
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 form-inline">
                                            <div className="col-12 form-inline ">
                                                <h5 className="mr-3">{i18next.t("collectionLettersCreate:Items Subject to Debt Collection")}
                                                </h5>
                                                <input type="checkbox" className="custom-checkbox mr-3" checked={this.state.checkAll} onChange={() => this.checkAll()} />
                                                <span>{i18next.t("collectionLettersCreate:Select All Items")}</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="col-12"><h5>{i18next.t("collectionLettersCreate:Selected Items")}
                                             <span className='text-danger pl-2'>* {i18next.t("collectionLettersCreate:For rooms with no overdue debts, the system will not issue reminder letters.")}</span>
                                            </h5></div>
                                        </div>
                                    </div>
                                    <div className="row mt-3" style={{ marginTop: '24px', marginBottom: '48px' }}>
                                        <div className="col-6">
                                            <div className="col card colSelect">
                                                <div className="col-12">
                                                    <div className="row scrollTable" >
                                                        <div className="col" >
                                                            <div id="list-contact" >
                                                                <table className="table table-hover mt-4" style={{ fontSize: '16px' }}>
                                                                    <thead className="blue-head">
                                                                        <tr>
                                                                            <th width={100}>{i18next.t("collectionLettersCreate:Code")}</th>
                                                                            {this.state.typOfContact === 'residential' && <th width={100}>{i18next.t("collectionLettersCreate:Room Number")}</th>}
                                                                            <th width={200}>{i18next.t("collectionLettersCreate:Name")}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <QueryRenderer
                                                                            environment={environment}
                                                                            query={query}
                                                                            cacheConfig={{ use_cache: false }}
                                                                            variables={{ search: this.state.search, typOfContact: this.state.typOfContact }}
                                                                            render={({ error, props }) => {
                                                                                if (error) {
                                                                                    return <div>{error.message}</div>;
                                                                                } else if (props) {
                                                                                    return (
                                                                                        props.allContactDebit.edges.length > 0 &&
                                                                                        props.allContactDebit.edges.map((contact, index) => {
                                                                                            return (
                                                                                                <tr key={contact.node.id}
                                                                                                    id={contact.node.id}
                                                                                                    onClick={() => this.selectContact(contact.node.id, contact)}
                                                                                                >
                                                                                                    <td>{contact.node.refNumber}</td>
                                                                                                    <td>{contact.node.name}</td>
                                                                                                    {this.state.typOfContact === 'residential' && <td>
                                                                                                    {getNameResidential(contact.node.firstName,contact.node.lastName)}
                                                                                                       </td>}
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td><span><span className="spinner-border spinner-border-sm align-middle mr-2" /> {i18next.t("Alltion:Preparing information")}</span></td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            }}
                                                                        />
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="col card colSelect">
                                                <div className="col-12">
                                                    <div className="row scrollTable">
                                                        <div className="col">
                                                            <div id="list-contact">
                                                                <table className="table table-hover mt-4" style={{ fontSize: '16px' }}>
                                                                    <thead className="blue-head">
                                                                        <tr>
                                                                            <th width={100}>{i18next.t("collectionLettersCreate:Code")}</th>
                                                                            {this.state.typOfContact === 'residential' && <th width={120}>{i18next.t("collectionLettersCreate:Room Number")}</th>}
                                                                            <th width={200}>{i18next.t("collectionLettersCreate:Name")}</th>
                                                                            <th width={50} />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.selectedContact.length > 0 &&
                                                                            this.state.selectedContact.map((contact, index) => {
                                                                                return (
                                                                                    <tr key={contact.node.id + 'selected'}>
                                                                                        <td>{contact.node.refNumber}</td>
                                                                                        <td>{contact.node.name}</td>
                                                                                        {this.state.typOfContact === 'residential' && 
                                                                                        <td>  {getNameResidential(contact.node.firstName,contact.node.lastName)}</td>}
                                                                                        <td>
                                                                                            <img className="cursor" src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="plus"
                                                                                                onClick={() => this.onDelete(contact.node.id)} />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-end mb-2">
                                                        <div className="col">
                                                            <button type="button" className="btn btn-clear float-right" onClick={this.clearList}>
                                                                <span className="clear-list">{i18next.t("collectionLettersCreate:Clear Selected Items")}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end p-4">
                                            <button type="button" className="btn-confirm" onClick={() => this.onSubmit()}>
                                                {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                {i18next.t("collectionLettersCreate:Generate Document")}
                                            </button>
                                        </div>
                                        <div className="col-1" />
                                    </div>

                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>


        )
    }
}

export default CollectionLettersCreate;
