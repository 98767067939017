/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QuickMassageTypeNote = "INVOICE" | "PAYMENT_QUOTATION" | "QUOTATION" | "RECEIVED" | "%future added value";
export type invoiceSummaryQueryVariables = {|
  type?: ?string
|};
export type invoiceSummaryQueryResponse = {|
  +selfProject: ?{|
    +keyProjectQr: ?string
  |},
  +quickMassageViewer: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +massageNote: ?string,
        +typeNote: QuickMassageTypeNote,
        +creator: ?string,
      |}
    |}>
  |},
|};
export type invoiceSummaryQuery = {|
  variables: invoiceSummaryQueryVariables,
  response: invoiceSummaryQueryResponse,
|};
*/


/*
query invoiceSummaryQuery(
  $type: String
) {
  selfProject {
    keyProjectQr
    id
  }
  quickMassageViewer(typeNote: $type) {
    edges {
      node {
        id
        massageNote
        typeNote
        creator
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyProjectQr",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "typeNote",
      "variableName": "type"
    }
  ],
  "concreteType": "QuickMassageNodeConnection",
  "kind": "LinkedField",
  "name": "quickMassageViewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickMassageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuickMassageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "massageNote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeNote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "invoiceSummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "invoiceSummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "5e8387e362146d73db3f9a7649bda42c",
    "id": null,
    "metadata": {},
    "name": "invoiceSummaryQuery",
    "operationKind": "query",
    "text": "query invoiceSummaryQuery(\n  $type: String\n) {\n  selfProject {\n    keyProjectQr\n    id\n  }\n  quickMassageViewer(typeNote: $type) {\n    edges {\n      node {\n        id\n        massageNote\n        typeNote\n        creator\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b7f8ba70e2543fa9821abc336fcc23c9';

module.exports = node;
