import React from "react";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import ComponentPagination from "../../libs/componentPagination";
import { Prompt } from "react-router";
import _ from "lodash";
import Pagination from "../../libs/newPagination";
import { fetchQuery } from "relay-runtime";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import i18n from "i18next";
import './waterMeter.scss'
// import "../style/manageRequest.scss"
import upload from '../../libs/upload'
import "../../contact/chatgroup/styles/chatGroup.scss"
import UploadFileXlsxInput from "../../libs/uploadFileXlsx";
import Loading from "../../libs/loading"
import BackButtonIcon from '../../components/BackBtn/indexBack';
import Navigation from "./navigation";
import SortMeterWater from "./sortMeterWater";
import { format } from "date-fns";
import i18next from "i18next";
import SortOrderAsc from "../../components/FucntionLib/sortAsc";


var time;
const query = graphql`
  query waterMeterListRecordQuery(
    $first: Int
    $last: Int
    $year: Float
    $before_year: Float
    $before_month: Float
    $search: String
    $order: String
  ) {
    allResidential(
      first: $first
      last: $last
      order: $order
      search: $search
    ) {
      edges {
        node {
          id
          name
          waterMeterUnit(year: $year) {
            edges {
              node {
                id
                invoiceStatus
                month
                year
                issuedDate
                meterValue
                changeWaterMeter
                initialChangeMeterValue
                remark
                editDate
                creatorApp
                creator
                editCreatorDate
                waterMeterImage{
                    edges {
                        node{
                            id
                            fileName
                            fileUpload
                        }
                    }
                }
                waterMeterTag{
                    edges {
                        node{
                            id
                            name
                        }
                    }
                }
              }
            }
          }
          beforeMeterUnit: waterMeterUnit(
            year: $before_year
            month: $before_month
          ) {
            edges {
              node {
                id
                invoiceStatus
                month
                year
                issuedDate
                meterValue
                changeWaterMeter
                initialChangeMeterValue
                remark
                editDate
                creatorApp
                creator
                editCreatorDate
                waterMeterImage{
                    edges {
                        node{
                            id
                            fileName
                            fileUpload
                        }
                    }
                }
                waterMeterTag{
                    edges {
                        node{
                            id
                            name
                        }
                    }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

const mutation = graphql`
  mutation waterMeterListRecordMutation($input: UpdateWaterMeterRecordInput!) {
    updateWaterMeterRecord(input: $input) {
      ok
      warningText
      waterMeterList {
        edges {
          node {
            id
            month
            year
            meterValue
          }
        }
      }
    }
  }
`;

class WaterMeterListRecord extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.meter_data = false;
    this.state.search = "";
    this.state.search_input = "";
    this.state.temp_selected_year = "";
    this.state.document_upload = '';
    this.state.selectYear = new Date().getFullYear();
    this.state.selectMonth = null;
    this.state.search = null;
    this.state.onKeyUp = ""
    this.inputHandle = this.inputHandle.bind(this);
    this.checkDisableInput = this.checkDisableInput.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getMeterList = this.getMeterList.bind(this);
    this.meterInputHandle = this.meterInputHandle.bind(this);
    this.getMeterValue = this.getMeterValue.bind(this);
    this.compareMeterValue = this.compareMeterValue.bind(this);
    this.blurChecker = this.blurChecker.bind(this);
    this.save = this.save.bind(this);
    this.onCheckClassName = this.onCheckClassName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleInputDocument = this.handleInputDocument.bind(this);
    this.uploadFile = this.uploadFile.bind(this)
    this.handlePropsFormSort = this.handlePropsFormSort.bind(this)
  }

  componentWillMount() {
    this.date = new Date();
    let current_year = this.date.getFullYear();
    let current_month = this.date.getMonth() + 1;
    this.setState(
      {
        loading: false,
        saveDisable: true,
        list_of_year: [
          current_year + 1,
          current_year,
          current_year - 1,
          current_year - 2,
        ],
        selected_year: current_year,
        selectYear: current_year,
        temp_selected_year: current_year,
        current_year: current_year,
        current_month: current_month,
        disabled_input_list: {},
        table_data_list: {}, // meter data for render table
      },
      () => {
        this.checkDisableInput();
      }
    );
  }

  componentDidMount() {
    this.getMeterList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.first !== this.state.first ||
      prevState.last !== this.state.last
    ) {
      this.getMeterList();
    }

    if (prevState.search !== this.state.search || prevState.selectYear !== this.state.selectYear) {
      clearTimeout(time);
      time = setTimeout(() => {
        this.getMeterList();
      }, 500);
    }
  }

  getMeterValue(residential, month) {

    if (month === 0) {
      for (let i = 0; i < residential.node.beforeMeterUnit.edges.length; i++) {
        if (residential.node.beforeMeterUnit.edges[i].node.month === 12) {
          return {
            value: residential.node.beforeMeterUnit.edges[i].node.meterValue,
            is_change: residential.node.beforeMeterUnit.edges[i].node.changeWaterMeter,
            inVoiced: residential.node.beforeMeterUnit.edges[i].node.invoiceStatus,
            creator: residential.node.beforeMeterUnit.edges[i].node.creator,
            creatorApp: residential.node.beforeMeterUnit.edges[i].node.creatorApp,
          };
        }
      }
      return "";
    } else {
      for (let i = 0; i < residential.node.waterMeterUnit.edges.length; i++) {
        if (residential.node.waterMeterUnit.edges[i].node.month === month) {
          return {
            value: residential.node.waterMeterUnit.edges[i].node.meterValue,
            is_change: residential.node.waterMeterUnit.edges[i].node.changeWaterMeter,
            inVoiced: residential.node.waterMeterUnit.edges[i].node.invoiceStatus,
            creator: residential.node.waterMeterUnit.edges[i].node.creator,
            creatorApp: residential.node.waterMeterUnit.edges[i].node.creatorApp,

          };
        }
      }
      return "";
    }
  }

  handleInputDocument(e) {
    this.setState({
      document_upload: e.currentTarget.files[0],
      saveDisable: false
    });
  }

  getMeterList() {
    this.setState({ meter_data: false }, () => {

      let variables = {
        first: this.state.first,
        last: this.state.last,
        // year: this.state.selected_year,
        before_year: this.state.selected_year - 1,
        before_month: 12,
        year: this.state.selectYear,
        search: this.state.search,
        order: localStorage.getItem('site_id') && localStorage.getItem('site_id') !== '295' ? "added" : "name"
      };
      fetchQuery(environment, query, variables).then((results) => {
        let data = _.cloneDeep(results);
        let table_data_list = {};
        data.allResidential.edges.forEach((residential, index) => {
          table_data_list[residential.node.id] = [];
          Array.from(new Array(13)).forEach((value, month) => {
            let meter_value = this.getMeterValue(residential, month);
            let data_value = "";
            let warning_change_meter = false;
            let inVoiced = null;
            let creator = null;
            let creatorApp = null;
            if (_.isObject(meter_value)) {
              data_value = meter_value.value;
              inVoiced = meter_value.inVoiced
              creator = meter_value.creator
              creatorApp = meter_value.creatorApp
              if (meter_value.is_change) {
                warning_change_meter = true;
              }
            }

            table_data_list[residential.node.id].push({
              value: data_value,
              edit: false,
              warning: false,
              warning_gte: false,
              warning_change_meter: warning_change_meter,
              warning_null_before_month: false,
              warning_negative_number: false,
              inVoiced: inVoiced,
              creator: creator,
              creatorApp: creatorApp,
            });
          });
        });

        const copyOfResidentialEdges = [...data.allResidential.edges];
        let sortedResidentialEdges = []
        if (localStorage.getItem("site_id") === '636') {
          sortedResidentialEdges = SortOrderAsc(copyOfResidentialEdges)
        } else {
          sortedResidentialEdges = copyOfResidentialEdges
        }

        this.setState({
          meter_data: data,
          table_data_list: table_data_list,
          sortedResidentialEdges: sortedResidentialEdges
        });
      });
    });
  }

  meterInputHandle(e) {
    let table = _.cloneDeep(this.state.table_data_list);
    let event = _.cloneDeep(e);
    let input_name = event.target.name.split(".");
    let edit_field_name = _.concat(_.dropRight(input_name), "edit");
    // let _warning_negative_number = _.concat(_.dropRight(input_name), "warning_negative_number");
    _.set(table, input_name, event.target.value);
    _.set(table, edit_field_name, true);

    //ห้ามกรอกจำนวนติดลบ
    // if(parseFloat(event.target.value) < 0){
    //   _.set(table, _warning_negative_number, true);
    // }

    //เช็คห้ามกรอกค่าเดือนเว้นเดือน (ห้ามเว้น)
    _.forIn(table, (room, key) => {
      //เดือนแรกที่ถูกบันทึก
      let first_index_value = _.findIndex(_.get(table, key), "value");
      //เดือนสุดท้ายที่ถูกบันทึก
      let last_index_value = _.findLastIndex(_.get(table, key), "value");
      _.forIn(room, (meter_input, index) => {
        if (parseInt(first_index_value) !== -1) {

          if (parseFloat(meter_input.value) < 0) {
            _.set(table, [key, index, "warning_negative_number"], true);
          }

          if (parseInt(index) === parseInt(first_index_value) || (parseInt(index) < parseInt(first_index_value) && !meter_input.value)) {
            _.set(table, [key, index, "warning_null_before_month"], false);
          }
          else if (parseInt(index) > parseInt(first_index_value) && parseInt(index) < parseInt(last_index_value)) {
            meter_input.value || meter_input.value === 0
              ? _.set(table, [key, index, "warning_null_before_month"], false)
              : _.set(table, [key, index, "warning_null_before_month"], true);
          }
          else if (parseInt(index) === parseInt(last_index_value)) {
            if (_.get(table, [key, parseInt(index) - 1, "value"])) {
              _.set(table, [key, index, "warning_null_before_month"], false);
            }
          }
          else if (
            parseInt(index) > parseInt(last_index_value) &&
            !meter_input.value
          ) {
            _.set(table, [key, index, "warning_null_before_month"], false);
          }

        }
      });
    });

    this.setState({ table_data_list: table, saveDisable: false });
  }

  checkDisableInput() {
    let disabled_input_list = {};
    this.state.list_of_year.forEach((year, index) => {
      let each_year = { [year]: {} };
      let input_index = {};

      // generate list of disabled
      Array.from(new Array(13)).forEach((month, index) => {
        _.assign(input_index, { [index]: true });
        if (
          index > this.state.current_month - 3 &&
          index < this.state.current_month + 2 &&
          year === this.state.current_year
        ) {
          _.assign(input_index, { [index]: false });
        }
      });
      _.assign(each_year, { [year]: input_index });

      // assign new disable value for january and december month
      if (year === this.state.current_year && this.state.current_month === 12) {
        disabled_input_list[year + 1][0] = false;
        disabled_input_list[year + 1][1] = false;
      }
      disabled_input_list = _.assign(disabled_input_list, each_year);
    });

    this.setState({ disabled_input_list: disabled_input_list });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (_.get(form.elements, [index + 13])) {
        form.elements[index + 13].focus();
      }
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }

  handlePropsFormSort(date) {
    this.setState(
      {
        search: date[0].value,
        selectMonth: date[4].value,
        selectYear: parseInt(date[5].value),
        selected_year: parseInt(date[5].value),
      }
    );
  }


  inputHandle(e) {
    this.setState({
      temp_selected_year: e.target.value,
    });
  }

  handleSearch(event) {
    let call_back = () => { };
    call_back = this.getMeterList;

    this.setState(
      {
        selected_year: this.state.temp_selected_year,
        search: event.target.value,
      },
      () => {
        call_back();
      }
    );
  }

  blurChecker(e) {
    let event = _.cloneDeep(e);
    let input_current_month = event.target.name.split(".");
    let residential_id = input_current_month[0];
    let before_month = input_current_month[1] - 1 || 0;
    let index_before_month = [residential_id, before_month, "value"];
    this.compareMeterValue(input_current_month, index_before_month);
  }

  compareMeterValue(input_current_month, index_before_month) {
    let warning_field_name = _.concat(
      _.dropRight(input_current_month),
      "warning"
    );
    let warning_gte_field_name = _.concat(
      _.dropRight(input_current_month),
      "warning_gte"
    );
    let warning_change_meter_field_name = _.concat(
      _.dropRight(input_current_month),
      "warning_change_meter"
    );
    let table = _.cloneDeep(this.state.table_data_list);

    if (!_.get(table, warning_change_meter_field_name)) {
      if (
        parseInt(_.get(this.state.table_data_list, input_current_month)) <
        parseInt(_.get(this.state.table_data_list, index_before_month))
      ) {
        _.set(table, warning_field_name, true);
        _.set(table, warning_gte_field_name, false);
      } else if (
        parseInt(_.get(this.state.table_data_list, index_before_month)) !== 0 &&
        parseInt(_.get(this.state.table_data_list, index_before_month)) * 3 <
        parseInt(_.get(this.state.table_data_list, input_current_month))
      ) {
        _.set(table, warning_gte_field_name, true);
        _.set(table, warning_field_name, false);
      } else {
        _.set(table, warning_field_name, false);
        _.set(table, warning_gte_field_name, false);
      }
      this.setState({ table_data_list: table });
    }
  }

  save() {
    let update_meter_list = [];
    let table = _.cloneDeep(this.state.table_data_list);
    let null_list = [];
    let isNull = false;
    let uploadables = { document_upload: this.state.document_upload };

    _.forIn(table, (room, key) => {
      _.forIn(room, (value, index) => {
        value.warning_null_before_month && null_list.push(value);
      });
    });
    if (null_list.length > 0) {
      isNull = true;
      return Swal.fire(
        "ไม่สามารถบันทึกค่าได้",
        "กรุณาบันทึกค่าให้ถูกต้อง",
        "warning"
      );
    }

    // eslint-disable-next-line
    for (let room_id in this.state.table_data_list) {
      // eslint-disable-next-line
      for (let month in this.state.table_data_list[room_id]) {
        let meter = this.state.table_data_list[room_id][month];
        if (meter["edit"]) {
          update_meter_list.push({
            room_id: room_id,
            month: month,
            value: meter["value"],
          });
        }
      }
    }

    // if (uploadables || (!isNull && update_meter_list.length > 0 && this.state.loading === false)) {
    if (uploadables || (!isNull && update_meter_list.length > 0 && this.state.loading === false)) {
      let variables = {
        input: {
          year: this.state.selected_year,
          meterStack: JSON.stringify(update_meter_list),
          "channel": "web"
        },
      };

      this.setState({ loading: true });

      commitMutation(environment, {
        mutation,
        variables,
        uploadables,
        onCompleted: (response) => {
          if (response.updateWaterMeterRecord.ok) {
            Swal.fire(i18n.t('meter:Save successfully'), '', 'success').then(() => {
              this.getMeterList();
              this.setState({ loading: false });
            });
          } else {
            Swal.fire(i18n.t('meter:Failed to save'), response.updateWaterMeterRecord.warningText, 'warning').then(() => {
              this.getMeterList();
              this.setState({ loading: false });
            });
          }
        },
        onError: (err) => {
          Swal.fire(i18n.t('meter:Failed to save'), err, 'warning');
          this.setState({ loading: false });
        },
      });
    }
  }

  onCheckClassName(previous_meter, value, is_change = false) {
    if (is_change) {
      return "border border-warning meter form-control";
    } else if (
      (previous_meter > parseInt(value) && previous_meter && value) ||
      (previous_meter * 3 < parseInt(value) && previous_meter && value)
    ) {
      return "border border-danger meter form-control";
    } else {
      return "meter form-control";
    }
  }

  callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload("/groupChat" + path, file);
      resolve(res);
    });
  };

  uploadFile(e) {
    let event = _.cloneDeep(e);
    // let value = event.target.value
    let files = event.currentTarget.files[0];
    if (files) {
      // 20 MB
      if (files?.size > 20971520) {
        Swal.fire(i18next.t("Allaction:Unsuccessful "), `${i18next.t("Allaction:Please upload a new file as it exceeds the specified size of")} 20 MB`, 'error')
      }
      else if (files?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .xlsx ${i18next.t("Allaction:Only")}`, 'error')
      }
      else {
        // fileIsSel = false
        let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
        let fileOldList = _.cloneDeep(this.state.chooseFile);
        this.callUpload('', new_file).then((data) => {
          let dese = "groupChat/" + new_file.name
          fileOldList.push({ roomGroupImageId: "", fileName: new_file.name, fileUpload: dese, viewPtath: data.location, status: "" })
          this.setState({ chooseFile: fileOldList })
        });
      }
    }
  }

  render() {
    return (
      <Translation>
        {t =>
          <React.Fragment>
            <Prompt
              message={i18next.t("Allaction:waitingSaving")}
              when={this.state.loading}
            />
            <BackButtonIcon
              printdocLinkChk={true}
              addLinkButton={"/accounting/water-meter/createQRocde"}
              addLinkText={t("meter:createqrcode")}
              LinkBack={"/accounting"}
              LinkText={t("materWater:Water Meter Record")}
              btnOutlineChk={true}
              btnOutlineLink={"/accounting/water-meter/viewHistory"}
              btnOutlineText={t("meter:viewHistory")}
              btnprintChk={true}
              state={this.state}
            />
            <Navigation />

            <div className="row form-group mt-3">
              <div className="col-8"></div>
              <div className="col-4 ">
                <UploadFileXlsxInput
                  handleInputDocument={this.handleInputDocument}
                  documentUpload={this.state.document_upload}
                />
              </div>
            </div>
            <div>
              <SortMeterWater
                selectIs={true}
                selectText={t("materWater:Water Meter No")}
                selectMonthIs={false}
                searchBoxId={true}
                changMeter={this.state.meter_data ? true : false}
                handlePropsChange={this.handlePropsFormSort}
                selectYear={this.state.selectYear}
                search={this.state.search}
                data={this.state.meter_data}
              />
            </div>

            <div className="row waterMeter usedAll" >
              <div className="col-4" style={{}}>
              </div>
            </div>
            <div className="content-inner" id="waterMeter">
              <div className="row">
                {this.state.meter_data && (
                  <React.Fragment>
                    <div className="col-12">
                      {
                        this.state.loading ?
                          <div className="container-fluid box fixRequestFast text-center" >
                            <img style={{ width: 256, height: 256 }} src={process.env.PUBLIC_URL + '/images/iconAlerts/in-progress.gif'} alt="loading" />
                          </div> :
                          <form className="card">
                            <table className="table table-bordered">
                              <thead>
                                <tr className="bg-light text-center">
                                  <th>{t('meter:Room No.')}</th>
                                  <th>
                                    {t('meter:Dec')}{" "}
                                    {(parseInt(this.state.selectYear) - 1)
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Jan')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Feb')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Mar')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Apr')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:May')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Jun')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Jul')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Aug')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Sep')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Oct')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Nov')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t('meter:Dec')}{" "}
                                    {(parseInt(this.state.selectYear))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.sortedResidentialEdges?.map(
                                  (residential, residential_index) => (
                                    <tr key={residential.node.id}>
                                      <td className="bg-light">{residential.node.name}</td>
                                      {this.state.table_data_list[residential.node.id].map((meter, index) => {
                                        let previous_index = index - 1 < 0 ? 0 : index - 1;
                                        let previous_meter = parseInt(
                                          this.state.table_data_list[
                                          residential.node.id
                                          ][previous_index]["value"]
                                        );
                                        let ChkMeterBy = meter.creatorApp || '-'
                                        let UpdatedBy = meter.creator || '-'
                                        let now = parseInt(new Date().getMonth()) + 1
                                        let nowYear = new Date().getFullYear()
                                        let chkRedError = (previous_meter * 3 < parseInt(meter["value"]) && previous_meter && meter["value"])
                                        let ChkWariningChange = meter["warning_change_meter"]
                                        let waringNumberLess = (previous_meter > parseInt(meter["value"]) && previous_meter && meter["value"])
                                        return (
                                          <td key={"table-" + index} className="table-meter hoverShows">
                                            <div className="tooltipsSpan">
                                              {/* <img src="/images/iconAlerxts/iconInfo-Gray.png" /> */}
                                              <div className="tooltiptext text-left"
                                                style={{
                                                  height: meter.inVoiced ? 95 : chkRedError || ChkWariningChange || waringNumberLess ? 90 : 68,
                                                  top: meter.inVoiced ? -95 : chkRedError || ChkWariningChange || waringNumberLess ? -88 : -62
                                                }}>
                                                {index > now ? <p className="mb-1 mt-1">
                                                  {/* ยังไม่ถึงกำหนดเดือน */}
                                                  <span>ยังไม่สามารถบันทึกเลขมิเตอร์ได้ เนื่องจากยังไม่ถึงกำหนดเดือน</span><br />
                                                </p> :
                                                  <div className="colorUse">
                                                    <p className="mb-1 mt-1">
                                                      {/* นิติแก้ไขล่าสุด */}
                                                      <span>{t("materWater:Last edited by")}  </span><span className="mt-2" >:{UpdatedBy}</span>
                                                    </p>
                                                    <p className="mb-1 mt-1">
                                                      {/* ผู้ตรวจมิเตอร์ */}
                                                      <span>{t("materWater:Inspector by")}  </span><span className="mt-2" >:{ChkMeterBy}</span><br />
                                                    </p>
                                                    {
                                                      meter.inVoiced == true &&
                                                      <p className="mb-1 mt-1">
                                                        {/* ออกใบแจ้งหนี้แล้ว */}
                                                        <span className="tagInvoice">{t("materWater:Invoiced")}</span>
                                                      </p>
                                                    }
                                                    {
                                                      // แจ้งเตือนสถานะแต่ละช่อง เช่น บันทึกค่ามากกว่า 3 เท่า เป็นต้น
                                                      ChkWariningChange ?
                                                        <p className="text_FFBD2D fw-400">**{t('meter:Change meter')}**</p>
                                                        :
                                                        chkRedError || waringNumberLess ?
                                                          <p className="text-red fw-400">**{t('meter:' + (
                                                            waringNumberLess ?
                                                              "Record number less than the previous month" :
                                                              "Record number over 3 times of the previous month"
                                                          ))
                                                          }**</p>
                                                          :
                                                          ''
                                                    }

                                                  </div>
                                                }


                                              </div>
                                              <input
                                                name={
                                                  residential.node.id +
                                                  "." +
                                                  index +
                                                  ".value"
                                                }
                                                // value={meter["value"] || ''}
                                                defaultValue={meter["value"]}
                                                type="number"
                                                onChange={this.meterInputHandle}
                                                onKeyUp={() => this.setState({ onKeyUp: residential.node.id + "." + index + ".value" })}
                                                onKeyPress={this.handleKeyPress}
                                                onBlur={this.blurChecker}
                                                onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                tabIndex={residential_index + this.state.meter_data.allResidential.edges.length * index}
                                                disabled={(nowYear === this.state.selectYear && now < index) || meter.inVoiced == true}
                                                style={{ width: 80, height: 40, borderRadius: 10 }}
                                                className={this.onCheckClassName(
                                                  previous_meter,
                                                  meter["value"],
                                                  meter["warning_change_meter"]
                                                ) + ' meter'}
                                              />
                                            </div>

                                            {meter["warning"] && (
                                              <div className="warning">
                                                {t('meter:Record number less than the previous month')}
                                              </div>
                                            )}
                                            {meter["warning_null_before_month"] && (
                                              <div className="warning">
                                                {t('meter:Please fill out this field')}
                                              </div>
                                            )}
                                            {meter["warning_negative_number"] && (
                                              <div className="warning">
                                                {t('meter:Record number less zero')}
                                              </div>
                                            )}
                                            {/* {meter["warning_change_meter"] &&
                                              <div className="warning">
                                                {t('meter:Change meter')}
                                              </div>
                                            } */}
                                            {meter["warning_gte"] ||
                                              (chkRedError && this.state.onKeyUp === residential.node.id + "." + index + ".value") && (
                                                <div className="warning">
                                                  {t('meter:Record number over 3 times of the previous month')}
                                                </div>
                                              )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </form>}



                      <div className="col">
                        <div className="row claim">
                          {/* หมายเหตุ */}
                          <label className='labelInline mt-4'>{t("materWater:Note")}:</label>
                          <div className='col-8'>
                            <div style={{ float: 'left', marginRight: 20 }}>
                              <div style={{ position: 'relative' }}>
                                <div style={{
                                  width: 20,
                                  height: 20,
                                  backgroundColor: 'rgb(255, 189, 45)',
                                  position: 'absolute',
                                  top: 18,

                                }}></div>
                                {/* เปลี่ยนมิเตอร์ */}
                                <label style={{ position: 'relative', marginLeft: 36, marginTop: 18 }}>{t("materWater:The water meter has been changed")}</label>
                              </div>
                            </div>
                            <div style={{ float: 'left' }}>
                              <div style={{ position: 'relative' }}>
                                {/* การใช้น้ำผิดปกติ */}
                                <div style={{
                                  width: 20,
                                  height: 20,
                                  backgroundColor: '#EC2340',
                                  position: 'absolute',
                                  top: 18,
                                  // marginLeft: 160
                                }}></div>
                                <label style={{ position: 'relative', marginLeft: 36, marginTop: 18 }}>{t("materWater:Invalid value")}</label>
                              </div>
                            </div>
                          </div>

                          <div className="col text-right mt-5">

                            {
                              this.state.loading || this.state.saveDisable ?
                                //ในกรณียังไม่ได้แก้ไขจะแสดงปุ่มสีเทา
                                this.state.saveDisable ?
                                  <button className="btn btn-secondary"
                                    disabled={this.state.saveDisable}
                                  >{t('Allaction:save')}</button>
                                  :
                                  <span className="text-red">{t("Allaction:waitingSaving")}</span>
                                :
                                <button className="btn-primary"
                                  onClick={this.save}
                                  disabled={this.state.loading || this.state.saveDisable}
                                >
                                  {t('Allaction:save')}
                                </button>
                            }

                          </div>
                        </div>

                      </div>


                    </div>


                    {!this.state.loading && (
                      <Pagination
                        changePage={this.changePage}
                        first={this.state.first}
                        last={this.state.last}
                        totalCount={this.state.meter_data.allResidential.totalCount}
                      />
                    )}
                    <div style={{ height: 200 }} />
                  </React.Fragment>
                )}
                {!this.state.meter_data && <Loading />}
              </div>
            </div>

            {/* <div className="float-save row">
              <div className="col-6">
                <p className="p-0 m-0">{t('meter:Last edited')} </p>
                <p className="p-0 m-0">{t('meter:By')} </p>
              </div>
              <div className="col-6 text-right">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_water_meter_edit",
                }) && (
                    <React.Fragment>
                      <Link to="/accounting">
                        <button className="btn btn-secondary mr-1">{t('meter:Cancel')}</button>
                      </Link>
                      <button
                        className="btn btn-primary"
                        onClick={this.save}
                        disabled={this.state.loading}
                      >
                        {this.state.loading && <Loading />
                        }
                        {t('meter:Water meter record')}
                      </button>
                    </React.Fragment>
                  )}
              </div>
            </div> */}
          </React.Fragment>}
      </Translation>
    );
  }
}

export default WaterMeterListRecord;
