/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ParcelStatus = "DELETED" | "NOT_RECEIVE" | "RECEIVE" | "RETURN" | "%future added value";
export type genParcelA4QueryVariables = {|
  is_residential_active?: ?boolean,
  status?: ?string,
  order?: ?string,
  start_date?: ?any,
  end_date?: ?any,
|};
export type genParcelA4QueryResponse = {|
  +parcelViewer: ?{|
    +allParcel: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +trackingNumber: string,
          +serviceName: string,
          +description: string,
          +added: any,
          +status: ParcelStatus,
          +docNumber: ?string,
          +residential: {|
            +name: string
          |},
        |}
      |}>,
    |}
  |}
|};
export type genParcelA4Query = {|
  variables: genParcelA4QueryVariables,
  response: genParcelA4QueryResponse,
|};
*/


/*
query genParcelA4Query(
  $is_residential_active: Boolean
  $status: String
  $order: String
  $start_date: DateTime
  $end_date: DateTime
) {
  parcelViewer {
    allParcel(isResidentialActive: $is_residential_active, status: $status, order: $order, startDate: $start_date, endDate: $end_date) {
      totalCount
      edges {
        node {
          id
          name
          trackingNumber
          serviceName
          description
          added
          status
          docNumber
          residential {
            name
            id
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "is_residential_active"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v5 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "isResidentialActive",
    "variableName": "is_residential_active"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trackingNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "genParcelA4Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ParcelViewer",
        "kind": "LinkedField",
        "name": "parcelViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ParcelNodeConnection",
            "kind": "LinkedField",
            "name": "allParcel",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ParcelNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParcelNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "genParcelA4Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ParcelViewer",
        "kind": "LinkedField",
        "name": "parcelViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ParcelNodeConnection",
            "kind": "LinkedField",
            "name": "allParcel",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ParcelNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParcelNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44e3e79c3e6486fef70a64f44f813ab4",
    "id": null,
    "metadata": {},
    "name": "genParcelA4Query",
    "operationKind": "query",
    "text": "query genParcelA4Query(\n  $is_residential_active: Boolean\n  $status: String\n  $order: String\n  $start_date: DateTime\n  $end_date: DateTime\n) {\n  parcelViewer {\n    allParcel(isResidentialActive: $is_residential_active, status: $status, order: $order, startDate: $start_date, endDate: $end_date) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          trackingNumber\n          serviceName\n          description\n          added\n          status\n          docNumber\n          residential {\n            name\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '414b93550b0766a332241f21ad169006';

module.exports = node;
