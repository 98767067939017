import "../styles/createBudget.scss";
import React from "react";
import _ from "lodash";
import { H6, P2, Th, Td } from '../../../components/Text/Text'
import numberWithCommas from "../../../libs/numberWithComma";
import i18next from "i18next";
import GetdateOnEngAndTh from "../../../components/Action/getdateOnEngAndTh";

const BudgetSetingTable = ({ state, last_index, report = false, toggleHandleScroll }) => {
    return (
        <React.Fragment>
            <div className="fade-up">
                <div className="table-responsive2" style={{ overflowX: "auto", width: "100%", display: "block" }} onScroll={() => toggleHandleScroll("table-responsive2")}>
                    <div className="table" style={{ width: `${state.widthTable1}px`, height: "20px", margin: 0 }}></div>
                </div>
                <div className="table-responsive" onScroll={() => toggleHandleScroll("table-responsive")}>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <Th rowSpan={2} className="text-center"><H6 report={report}>{i18next.t("budgetSetingReport:Sequence")}</H6></Th>
                                <Th rowSpan={2} className="text-center"><H6 report={report}>{i18next.t("budgetSetingReport:Item")}</H6></Th>
                                <Th colSpan={state.list_month.length} className="text-center"><H6 report={report}>{i18next.t("budgetSetingReport:Monthly Budget")}</H6></Th>
                                <Th rowSpan={2} className="text-center"><H6 report={report}>{i18next.t("budgetSetingReport:Total Budget")}</H6></Th>
                            </tr>
                            <tr className="border-top">{state.list_month.map((item, index) => <Th key={index} className="text-center">
                                <H6 report={report} >
                                    <GetdateOnEngAndTh 
                                        date={item}
                                        // withDate={true}
                                        withMonthShort={true}
                                        withYearShort={true}
                                    />
                                </H6>
                                </Th>)}</tr>
                        </thead>
                        <tbody>
                            {_.map(state.lists[4], (item, index) => {
                                ++last_index;
                                return (
                                    // ข้อมูลในตารางไม่แปลนะ
                                    <React.Fragment key={index}>
                                        <tr>
                                            <Td className="text-center">{last_index}</Td>
                                            <Td style={{ paddingLeft: 50, minWidth: 300, }}>
                                                {index === 0 && <H6 style={{ marginLeft: -32 }} report={report} >รายรับ</H6>}
                                                <P2 report={report} >{item.name}</P2>
                                            </Td>
                                            {item.month_budget.map((month, index_month) => <Td key={index_month} width={100} height={24} className="text-center"><P2 report={report} >{numberWithCommas(month.node.budget)}</P2></Td>)}
                                            <Td width={200} className="text-center"><P2 report={report} >{numberWithCommas(item.total_budget)}</P2></Td>
                                        </tr>
                                        {index === state.lists[4].length - 1 && (
                                            <tr className="td-sum ">
                                                <Td></Td>
                                                <Td style={{ paddingLeft: 50, minWidth: 300, }}><H6 report={report} >รวมรายรับ</H6></Td>
                                                {state.sum_income.sum_month_budget.map((item, index) => <Td key={index} width={120} height={24} className="text-center"><P2 report={report} >{numberWithCommas(item)}</P2></Td>)}
                                                <Td width={200} className="text-center"><P2 report={report} >{numberWithCommas(state.sum_income.sum_total_budget)}</P2></Td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            {_.map(state.lists[5], (item, index_code) => {
                                return (
                                    <React.Fragment key={index_code}>
                                        {item.child.length > 0 && (
                                            <React.Fragment>
                                                <tr>
                                                    <Td />
                                                    <Td>
                                                        <div className="ml-2">
                                                            {index_code === 0 && <H6 report={report} >รายจ่าย</H6>}
                                                            <P2 report={report} >{item.name}</P2>
                                                        </div>
                                                    </Td>
                                                </tr>
                                                {_.map(item.child, (item_child, index) => {
                                                    ++last_index;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <Td className="text-center"><H6 report={report} >{last_index}</H6></Td>
                                                                <Td style={{ paddingLeft: 50, minWidth: 300 }}><P2 report={report} >{item_child.name}</P2></Td>
                                                                {item_child.month_budget.map((month, index_month) => <Td key={index_month} width={120} height={24} className="text-center"><P2 report={report} >{numberWithCommas(month.node.budget)}</P2></Td>)}
                                                                <Td width={200} className="text-center"><P2 report={report} >{numberWithCommas(item_child.total_budget)}</P2></Td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}

                                                <tr className="td-sum ">
                                                    <Td />
                                                    <Td style={{ paddingLeft: 50, minWidth: 300, }}><H6 report={report} >รวม{item.name}</H6></Td>
                                                    {state.sum_expenses[item.key].sum_month_budget.map((item, index) => <Td key={index} width={120} height={24} className="text-center"><H6 report={report} >{numberWithCommas(item)}</H6></Td>)}
                                                    <Td width={200} className="text-center"><H6 report={report} >{numberWithCommas(state.sum_expenses[item.key].sum_total_budget)}</H6></Td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            <tr className="Td-sum2 ">
                                <Td></Td>
                                <Td style={{ paddingLeft: 50, minWidth: 300 }}><H6 report={report} >รวมรายจ่าย</H6></Td>
                                {state.total_sum_expenses.sum_month_budget.map((item, index) => <Td key={index} width={120} height={24} className="text-center"><H6 report={report} >{numberWithCommas(item)}</H6></Td>)}
                                <Td width={200} className="text-center"><H6 report={report} >{numberWithCommas(state.total_sum_expenses.sum_total_budget)}</H6></Td>
                            </tr>
                            <tr className="td-sum3 ">
                                <Td></Td>
                                <Td style={{ paddingLeft: 50, minWidth: 300, }}><H6 report={report} >รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด</H6></Td>
                                {state.total_sum_expenses.sum_month_budget.map((item, index) =>
                                    <Td key={index} width={120} height={24} className="text-center">
                                        <H6 report={report} >{numberWithCommas(state.sum_income.sum_month_budget[index] - item)}</H6>
                                    </Td>)}
                                <Td width={200} className="text-center"><H6 report={report} >{numberWithCommas(state.sum_income.sum_total_budget - state.total_sum_expenses.sum_total_budget)}</H6></Td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}


export default BudgetSetingTable;