/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAndUpdateChequeTransactionInput = {|
  chequeTransaction: any,
  clientMutationId?: ?string,
|};
export type createAndUpdateChequeTransactionMutationVariables = {|
  input: CreateAndUpdateChequeTransactionInput
|};
export type createAndUpdateChequeTransactionMutationResponse = {|
  +createAndUpdateChequeTransaction: ?{|
    +ok: ?boolean
  |}
|};
export type createAndUpdateChequeTransactionMutation = {|
  variables: createAndUpdateChequeTransactionMutationVariables,
  response: createAndUpdateChequeTransactionMutationResponse,
|};
*/


/*
mutation createAndUpdateChequeTransactionMutation(
  $input: CreateAndUpdateChequeTransactionInput!
) {
  createAndUpdateChequeTransaction(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAndUpdateChequeTransactionPayload",
    "kind": "LinkedField",
    "name": "createAndUpdateChequeTransaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAndUpdateChequeTransactionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAndUpdateChequeTransactionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8342e0359a47d580405981185a6f6c94",
    "id": null,
    "metadata": {},
    "name": "createAndUpdateChequeTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation createAndUpdateChequeTransactionMutation(\n  $input: CreateAndUpdateChequeTransactionInput!\n) {\n  createAndUpdateChequeTransaction(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '382fcc474edcb02586a7b889338e8864';

module.exports = node;
