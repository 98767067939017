import React from 'react';
import {QueryRenderer} from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {Link} from "react-router-dom";
import localStorage from "../../../libs/localstorage";
import getNameResidential from "../../../libs/getNameResidential";
import { Translation } from 'react-i18next';
import Loading from "../../../libs/loading";
import i18next from 'i18next';
import i18n from 'i18next'
import Swal from "sweetalert2";
import ChangeStatusOtherReceiveMutation from "../invoice/invoice_detail/mutations/ChangeStatusOtherReceiveMutation";
import Redirect from "react-router-dom/es/Redirect";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
class OtherReceiverListTable extends ComponentPagination {

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.state.status !== this.props.status) {
            this.setState({
                status: this.props.status,
                loading: false,
                redirect: false,
                checkList: [],
                checkListdoc:[],
                check_all: false,
            }, () => {
                this.goFirst();
            })
        }
    }

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'PAID':
                text = 'รับชำระแล้ว';
                break;
            default:
                text = 'รายการที่ยกเลิก';
                color = 'text-center text-danger';
        }
        return <td className={color}>{text}</td>
    }


    deleteOtherReceiver(){
        Swal.fire({
        title: i18n.t("other_received:Are you sure to cancel this other received?"),
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18next.t("receipt_deposit:Yes"),
        cancelButtonText: i18next.t("receipt_deposit:Cancel"),
        }).then(async (result) => { 
            if(result){
                let input = {
                    otherReceiveIdList: this.state.checkList,
                    status: "void",
                };
                ChangeStatusOtherReceiveMutation(
                    input,
                    (response) => {
                      if (
                        response.updateOtherReceiveAndOtherReceiveTransactionStatus.ok
                      ) {
                        Swal.fire(
                          i18n.t("other_received:Success"),
                          i18n.t("other_received:Other receipts have been canceled"),
                          "success"
                        ).then(() => this.setState({ redirect: true, loading: false }));
                      } else {
                        Swal.fire(
                          i18n.t("other_received:Unsuccess"),
                          response.updateOtherReceiveAndOtherReceiveTransactionStatus
                            .warningText,
                          "warning"
                        ).then(() => this.setState({ loading: false }));
                      }
                    },
                    () => {
                      this.setState({ loading: false });
                      Swal.fire(
                        i18n.t("other_received:Unsuccess"),
                        i18n.t("other_received:Failed to cancel other receipts"),
                        "error"
                      );
                    }
                  );
        
          
            }
        
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/accounting/income/other_receiver/list/void" />;
        }
        return (
            <QueryRenderer
                environment={environment}
                query={this.props.query}
                variables={{
                    search: this.props.search,
                    issuedDate_Gte: this.props.start_date && format(this.props.start_date, 'YYYY-MM-DD'),
                    issuedDate_Lte: this.props.end_date && format(this.props.end_date, 'YYYY-MM-DD'),
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status
                }}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="row mb-2 mt-1">
                            <div className='col-3'>
                                    <Link to={{
                                        pathname: "/document/other_receiver/all/",
                                        state: {
                                        checkList: this.state.checkList,
                                        docNumber: this.state.checkListdoc
                                        },
                                    }}
                                    >
                                    {this.props.status !== 'void' &&
                                    <button type="button" className="btn btn-light border mb-3">
                                        <img src={process.env.PUBLIC_URL + "/images/icons/print-icon.png"}
                                        alt="print-icon"/>
                                    </button> 
                                    }
                                    </Link> 
                                    {
                                    this.state.status !== 'void' &&
                                        <button type="button" className="btn btn-light border mb-3 ml-3"
                                        onClick={() => this.deleteOtherReceiver()} >
                                        <img
                                        src={process.env.PUBLIC_URL + "/images/icons/delete.png"} alt="print-icon"/>
                                        </button>
                                    }
                                </div>
                                <div className="col-9 text-right">
                                    <Translation>
                                        {t=>
                                        <div className="input-group float-right w-auto ml-2">
                                        <input
                                            type="text"
                                            className="form-control input-size"
                                            placeholder={t("other_received:Search")}
                                            value={this.props.search_input}
                                            name="search"
                                            onChange={(e) =>
                                            this.props.handleSearchInputChange(e.target.value )
                                            }
                                            onKeyDown={this.props.handleKeyDown}
                                        />
                                        <button
                                            type="submit"
                                            className="btn btn-primary form-control search-button"
                                            name="search"
                                            value={this.props.search_input}
                                            onClick={(e) => this.props.handleSearch(e)}
                                        >
                                            {t("other_received:Search")}
                                        </button>
                                        </div>}
                                    </Translation>
                                    <InlineAdvanceFilter
                                        start_date={this.props.temp_start_date}
                                        end_date={this.props.temp_end_date}
                                        handleChange={this.props.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                            <div className="table-responsive fade-up">
                                <table className="table table-hover mt-2">
                                    <thead className="thead-light">
                                    <Translation>
                                        {t=>
                                        <tr>
                                            <th>
                                            {this.props.status !== 'void' &&
                                                <input type="checkbox"
                                                       onChange={() => this.getAllList(props.allOtherReceive.edges,true)}
                                                       checked={this.state.check_all}/>
                                            }
                                            </th>
                                            <th className="text-center" width={130}>{t("other_received:No.")}</th>
                                            <th className="text-center">{t("other_received:Date")}</th>
                                            <th className="text-center">{t("other_received:Code")}</th>
                                            <th>{t("other_received:Room No.")}</th>
                                            <th>{t("other_received:Name")}</th>
                                            <th>{t("other_received:Detail")}</th>
                                            <th className="text-right">{t("other_received:Amount")}</th>
                                            <th className="text-right">{t("other_received:Total amount")}</th>
                                            {this.props.status === 'void' &&
                                            <th className="text-center">{t("other_received:Date of cancellation")}</th>
                                            }
                                            <th className="text-center">{t("other_received:By")}</th>
                                            {this.props.status === 'all' &&
                                            <th className="text-center">{t("other_received:Staus")}</th>
                                            }
                                            {this.props.status === 'void' &&
                                            <th className="text-center">{t("other_received:Reason of cancellation")}</th>
                                            }
    
                                        </tr>}
                                    </Translation>
                                    </thead>
                                    <tbody>


                                    {props.allOtherReceive.edges.map((other_receive, index) => {
                                        let order_id = this.state.first - localStorage.getPageLimit();
                                        let accumulate = 0;
                                        return (
                                            other_receive.node.otherReceiveTransaction.edges.map((transaction, t_index) => {
                                                let number_of_transaction = other_receive.node.otherReceiveTransaction.edges.length;
                                                accumulate = accumulate + transaction.node.total;
                                                let row_number = (t_index === 0 && order_id + index + 1);

                                                if (this.state.product_and_service &&
                                                    this.state.product_and_service !== transaction.node.productAndService.id) {
                                                    return true;
                                                } else if (this.state.product_and_service &&
                                                    this.state.product_and_service === transaction.node.productAndService.id) {
                                                    accumulate = transaction.node.total;
                                                    row_number = index + 1;
                                                    number_of_transaction = t_index + 1;
                                                }
                                                return (
                                                    <tr key={transaction.node.id}>
                                                        <td>
                                                            {row_number && other_receive.node.status !== 'VOID' &&
                                                            <input type="checkbox"
                                                                   onChange={() => this.appendToCheckList(other_receive.node.id,other_receive.node.docNumber)}
                                                                   checked={
                                                                    this.checkList(other_receive.node.id)}
                                                                    />
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {other_receive.node.status === 'VOID' ?
                                                                other_receive.node.docNumber:
                                                                <Link
                                                                to={{
                                                                    pathname:"/accounting/income/other_receiver/view/" + other_receive.node.id + "/" + other_receive.node.docNumber,
                                                                    state: other_receive.node.contact
                                                                }} 
                                                                   // to={"/accounting/income/other_receiver/view/" + other_receive.node.id + "/" + other_receive.node.docNumber}
                                                                    >
                                                                    {other_receive.node.docNumber}
                                                                </Link>
                                                            }
                                                        </td>
                                                        <td className="text-center">{format(other_receive.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                        <td className="text-center">{other_receive.node.contact ? other_receive.node.contact.refNumber : '-'}</td>
                                                        <td>{other_receive.node.contact && other_receive.node.contact.typeOfContact === 'RESIDENTIAL' ? other_receive.node.contact.name : '-'} </td>
                                                        <td className="colorUse notKanit">
                                                            {other_receive.node.contact && other_receive.node.contact.typeOfContact === 'RESIDENTIAL' ?
                                                                getNameResidential(other_receive.node.firstName, other_receive.node.lastName):
                                                                other_receive.node.contact ? other_receive.node.contact.name: other_receive.node.unknownContact
                                                            }
                                                            {
                                                                other_receive?.node?.payGroup === "AGENT" || other_receive?.node?.payGroup === "RENT" ?
                                                                <label className='text-red'>&nbsp;({i18next.t("AgentRole:" + other_receive.node.payGroup)})</label>
                                                                :
                                                                ""
                                                            }
                                                            
{/*                                                             
                                                            {other_receive.node.contact && other_receive.node.contact.typeOfContact === 'RESIDENTIAL' ? getNameResidential(other_receive.node.contact.firstName, other_receive.node.contact.lastName) :
                                                            other_receive.node.contact ?
                                                            other_receive.node.contact.name
                                                                :
                                                                other_receive.node.unknownContact
                                                        }  */}
                                                        </td>

                                                        <td>{transaction.node.description}</td>

                                                        <td className="text-right">{numberWithComma(transaction.node.total)}</td>
                                                        <td className="text-right">{
                                                            t_index === number_of_transaction - 1 && numberWithComma(accumulate)
                                                        }</td>
                                                        {this.props.status === 'void' &&
                                                            <td className="text-center">{format(other_receive.node.updated, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                        }
                                                        <td className="text-center">{other_receive.node.creator || '-'}</td>
                                                        {this.props.status === 'all' &&
                                                            this.getColorAndText(other_receive)
                                                        }
                                                        {this.props.status === 'void' &&
                                                        <td className="text-center">{other_receive.node.voidRemark || '-'}</td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        )

                                    })
                                    }



                                    </tbody>
                                </table>
                            </div>
                                <Pagination changePage={this.changePage} first={this.state.first}
                                            last={this.state.last}
                                            totalCount={props.allOtherReceive.totalCount}/>
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default OtherReceiverListTable;
