import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import Navigation from "./navigation";
import {Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {Translation} from "react-i18next";
import {getDaysInMonth, setDate} from "date-fns";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import InputTaxList from "./inputTax/inputTaxList";
import OutputTaxList from "./outputTax/outputTaxList";
import DatePicker from "react-datepicker/es";
import InputTaxSearch from "../../SearchInput";

class TaxList extends Component {

    constructor(props) {
        super(props);
        let current_month = new Date();
        this.state = {
            start_date: setDate(current_month, 1),
            end_date: setDate(current_month, getDaysInMonth(current_month)),
            search: '',
        };
         this.onChangeSearch = this.onChangeSearch.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
    }

    onChangeSearch(text) {
        this.setState({search: text});
    }

    handleChangeStart(date) {
        this.setState({
            start_date: date,
            end_date: setDate(date, getDaysInMonth(date))
        });
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="receive-list">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                     <Translation>
                                        {
                                            t => <span>{t('tax:'+this.props.match.params.page)}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <div className="content-inner">
                            <Navigation/>

                            <div className="row mb-2 mt-1">
                                <div className="col-9">
                                    <div className="receive-search-date">
                                        <div className="form-inline float-right">
                                            <div className="form-group mb-2">
                                                <label htmlFor="start_date"
                                                       className="startDate mr-3">เรียกดู</label>
                                                <DatePicker id="start_date" className="form-control"
                                                            selected={this.state.start_date}
                                                            onChange={this.handleChangeStart}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 text-right">
                                      <InputTaxSearch callback_function={this.onChangeSearch}
                                                        search={this.state.search}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {
                                        this.props.match.params.page === 'output-tax' &&
                                        <OutputTaxList search={this.state.search}
                                                       start_date={this.state.start_date}
                                                       end_date={this.state.end_date}
                                        />
                                    }
                                    {
                                        this.props.match.params.page === 'input-tax' &&
                                        <InputTaxList search={this.state.search}
                                                      start_date={this.state.start_date}
                                                      end_date={this.state.end_date}

                                        />
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default TaxList;