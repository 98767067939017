import React, {Component} from "react";
import SearchSelect from "../../../libs/searchSelect";
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import {format} from "date-fns";
import {commitMutation} from "react-relay";
import { Translation } from "react-i18next";

const $ = window.jQuery;
const _ = require("lodash");

const query = graphql`
    query fineCreateModalQuery($type: String!){
        productViewer{
            allProduct(type:$type, serviceFine_FineType_In: "percentage, static"){
                edges{
                    node{
                        id
                        name
                        productCode
                    }
                }
            }
        }
        allProjects {
        edges {
            node {
                periodCreate
            }
          }
        }
    }
`;

const mutation = graphql`
    mutation fineCreateModalMutation($input: CreateFineGroupInput!){
        createFineGroup(input:$input){
            warningText
            newFineGroup{
                id
            }
        }
    }`;


class FineCreateModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading : false,
            error: false,
            success: false,
            warning_text: '',

            service_list: [],
            service: '',
            fine_date: '',

            temp_month_before : '',
            temp_month_after : '',
            period_create:''
        };
        this.onClickOpenModal = this.onClickOpenModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal', () => {
            this.setState({
                service: '',
                fine_date: '',

                loading: false,
                error: false,
                success: false,
                warning_text: '',
            })
        });
    }

    onClickOpenModal() {
        $(this.modal).modal('show');

        fetchQuery(environment, query, {type: 'service'}).then(data => {
            if (data) {
                this.setState({
                    service_list: data.productViewer.allProduct.edges
                })
                this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
            
            }
        });
    }

    handleInputChange(e){
        this.setState({[e.target.name]: e.target.value});
    }

    calPeriodMouth(val){
        let month_before = new Date();
        let month_after= new Date();
    
        month_before.setMonth(month_before.getMonth() -  parseInt(val));
        month_after.setMonth(month_after.getMonth() + parseInt(val));
    
        this.setState({
            temp_month_before : month_before,
            temp_month_after : month_after,
        })
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true, success: false, error: false});
        let variables = {
            input: {
                service: this.state.service,
                fineDate: format(this.state.fine_date, 'YYYY-MM-DD'),
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createFineGroup.newFineGroup) {
                        this.props.refreshQuery()
                        this.setState({loading: false, success: true});
                    } else {
                        this.setState({loading: false, error: true, warning_text: response.createFineGroup.warningText});
                    }
                },
                onError: (err) => {
                    this.setState({loading: false, error: true});
                },
            },
        )
    }

    render() {
        return (
            <Translation>
                {t => 
                    <React.Fragment>
                        <button type="button" className="btn btn-primary add float-right" onClick={this.onClickOpenModal}>
                            <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                            {t('fine:Create fines')}
                        </button>
        
                        <div className="modal fade bd-example-modal-md" id="bank-create-model" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal => this.modal = modal}>
                            <div className="modal-dialog modal-md" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="col">
                                            <div className="row">
                                                <h5 className="modal-title">
                                                    {t('fine:Create fines')}
                                                </h5>
                                            </div>
                                        </div>
                                        <button type="button" className="close" aria-label="Close" data-dismiss="modal">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form id="form-add" onSubmit={this.onSubmit}>
                                            {this.state.error && <div className="alert alert-danger text-left" role="alert">
                                                {t('fine:Can\'t create new fine.')}{this.state.warning_text}</div>
                                            }
                                            {this.state.loading && <div className="alert alert-primary text-left" role="alert">
                                                {t('fine:Saving')}</div>
                                            }
                                            {this.state.success && <div className="alert alert-primary text-left" role="alert">
                                                {t('fine:Save successfully')}</div>
                                            }
        
                                            <div className="row ">
                                                <div className="col-3">
                                                    <label htmlFor="service"
                                                        className="col-form-label">
                                                        {t('fine:Services')}
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <SearchSelect onChange={this.handleInputChange}
                                                                value={this.state.service}
                                                                name="service" placeholder="รายการ"
                                                                queryObject={this.state.service_list}
                                                                keyOfValue="id" require={true}
                                                                keyOfLabel="productCode:name"
                                                    />
                                                </div>
                                            </div>
        
                                            <div className="row mt-2">
                                                <div className="col-3">
                                                    <label htmlFor="fine_date"
                                                        className="col-form-label">
                                                        {t('fine:Fines date')}
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    {/* <DatePickerAdapter
                                                        name="fine_date" className="form-control"
                                                        id="fine_date"
                                                        selected={this.state.fine_date}
                                                        onChange={this.handleInputChange}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        maxDate={new Date()}
                                                        required={true}
                                                        page = {'FineCreateModal'}
                                                    /> */}
                                                    {
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                                                        <DatePickerAdapter
                                                            name="fine_date"
                                                            id="fine_date"
                                                            className="form-control"
                                                            selected={this.state.fine_date}
                                                            minDate={this.state.temp_month_before}
                                                            // maxDate={this.state.temp_month_after}
                                                            onChange={this.handleInputChange}
                                                            required={true}
                                                        /> : 
                                                        <DatePickerAdapter
                                                            name="fine_date" className="form-control"
                                                            id="fine_date"
                                                            selected={this.state.fine_date}
                                                            onChange={this.handleInputChange}
                                                            showMonthDropdown={true}
                                                            showYearDropdown={true}
                                                            maxDate={new Date()}
                                                            required={true}
                                                            page = {'FineCreateModal'}
                                                        />
                                                    }
                                                </div>
                                            </div>
        
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary add" data-dismiss="modal">{t('fine:Cancel')}
                                        </button>
                                        <button type="submit" className="btn btn-primary add" form="form-add" disabled={this.state.loading}>
                                            {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                            {t('fine:Create')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default FineCreateModal
