import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FileInput from '../../project/projectInfo/componentProjectList/fileInput'
import _ from "lodash"
import SelectProduct from '../selectProduct/selectProduct'
import ModalSelectNumber from './modalSelectNumber'
import '../style/stockandsupplies.scss'
import i18next from 'i18next'

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF',
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

export default class pickingList extends Component {

  constructor(props){
    super(props)
    this.state = {
      listNo : []
    }
  }

  handleChangeSwitch = () => {
    this.props.handleChangeInput({
      target: {
        name: "switchSelectProduct",
        value: true
      }
    })
  }

  render() {
    return (
      <div id="pickingList">
        <div className="row mt-4">

          <div className="col-12 d-flex align-items-center">
            <h4>{i18next.t("stockCreateAndUpdate:Lists")}</h4>
            {/* {this.props.status === "unit" && */}
              <div className="tooltipImg">
                <img src={process.env.PUBLIC_URL + '/images/icons/questionIcon.png'} alt="question" className="question ml-2"/>
                <span className="tooltiptext">
                {i18next.t("stockCreateAndUpdate:Some products have a unique number")}<br/>
                {i18next.t("stockCreateAndUpdate:such as")} คีย์การ์ด EP143002 ,บัตรจอดรถ 00123<br/>
                {i18next.t("stockCreateAndUpdate:which can Requisition only one item at a time.")}
                </span>
                <span className="tooltipTriangle">
                  <img src={process.env.PUBLIC_URL + '/images/tooltip/tooltipTriangle.png'} alt="tooltipTriangle"/>
                </span>
              </div>
            {/* } */}
          </div>

          <div className="col-12">
            <div className="fade-up">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th>{i18next.t("stockCreateAndUpdate:Code")}</th>
                    <th>{i18next.t("stockCreateAndUpdate:Lists")}</th>
                    <th className="text-center">{i18next.t("stockCreateAndUpdate:Quantity")}</th>
                    <th className="text-right">{i18next.t("stockCreateAndUpdate:Remaining amount")}</th>
                    {this.props.requestingNumber && <th className="text-left">{i18next.t("stockCreateAndUpdate:No.")}</th>}
                    {this.props.action === "create" && <th/>}
                  </tr>
                </thead>
                <tbody>
                  {_.map(this.props.requisitionList, (item, index) => {

                    return (
                      <tr key={index}>
                        <td style={{color: "#0382FA"}}>{item.productCode}</td>
                        <td style={{minWidth: 300}}>{item.name}</td>
                        <td width="300" className="text-center">
                          {this.props.action === "create" 
                            ? <div className="d-flex justify-content-center">
                                <input 
                                  type="number" min="1" step="1"
                                  className="form-control w-25 text-right"
                                  name={`requisitionNote.requisitionList[${index}]`}
                                  value={item.numberOfPieces}
                                  onChange={(e) => 
                                    this.props.handleChangeNumberOfPieces(item.numberOfPieces, e, index, item.productAndServiceId)
                                  }
                                   required
                                   max={item.haveNo ? 1 : parseInt(item.remaining) === 0 ? parseInt(item.numberOfPieces) : parseInt(item.numberOfPieces) && parseInt(item.numberOfPieces) > parseInt(item.remaining)}
                                  // max={(item.haveNo && this.props.status === "unit") ? 1 : parseInt(item.remaining) === 0 ? parseInt(item.numberOfPieces) : parseInt(item.numberOfPieces) && parseInt(item.numberOfPieces) > parseInt(item.remaining)}

                               
                                  //max={(item.haveNo && this.props.status === "unit") ? 1 : parseInt(item.remaining)}
                                  />
                              </div>
                            : <span>{item.numberOfPieces}</span>
                          }
                        </td>
                        <td className="text-right" width="250">{item.remaining}</td>
                        {this.props.requestingNumber && 
                            <td className="text-left" width="300">
                              {item.haveNo && 
                                  this.props.action !== 'edit' ?
                                  <ModalSelectNumber item={item} list={this.props.requisitionList} index={index} handleChange={this.props.handleChangeInput} />
                                  :
                                  <span>{item.noNumber}</span>
                              }
                            </td>
                        }
                        {this.props.action === "create" && <td width="200" className="text-right">
                          <img
                            src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'}
                            alt="cancel-icon"
                            className='cursor'
                            style={{ width: "10px", height: "10px", marginRight: 30 }}
                            onClick={() => 
                              this.props.handleRemoveSelect(
                                "requisitionNote.requisitionList", 
                                index
                              )} 
                          />
                        </td>}
                      </tr>
                    )
                  })}
                  {this.props.switchSelectProduct &&
                    <tr>
                      <td colSpan="2">
                        
                        <SelectProduct
                          appendRequisitionList={this.props.appendRequisitionList}
                          style={customStyles}
                          />
                      </td>
                      <td colSpan={this.props.requestingNumber ? "4" : "3"}/>
                    </tr>
                  }
                </tbody>
              </table>
              <div className="lineBottom"/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-5">
            {(this.props.requisitionList.length === 0 && !this.props.switchSelectProduct && this.props.action === "create")&& 
              <button className="btnBgAddList" type="button" onClick={this.handleChangeSwitch}>+{i18next.t("settingAll:Add list")}</button>
            }
            {(this.props.requisitionList.length > 0 && !this.props.switchSelectProduct && this.props.action === "create") && 
              <button className="btnAddList" type="button" onClick={this.handleChangeSwitch}>+{i18next.t("settingAll:Add list")}</button>
            }
          </div>
        </div>

        <div className="row mt-5">
          <div className="col d-flex align-items-center">
            <div className="upload-file">
              <label 
                className={"btn btn-sm btnAttach"} 
                htmlFor="CustomFile">
                  <img className="iconAttach" src={process.env.PUBLIC_URL + "/images/icons/attachFilePrimary.png"} alt="attachIcon" />
                  {i18next.t("stockCreateAndUpdate:Attach file")}
              </label>
              <div className="upload-input">
                <FileInput
                  id="CustomFile"
                  appendFileInputRow={(fileInput) => this.props.appendFileInputRow("requisitionNote.requisitionNoteDocuments", fileInput)}
                  />
              </div>
            </div>
            <span 
              className={"ml-4 textAttach"}
              >{i18next.t("stockCreateAndUpdate:Attach document .jpg or .pdf size not over 10 MB")}</span>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-4">
            <div
              className="slide"
            >
              {_.map(this.props.requisitionNoteDocuments, (item, index) => {
                let fileName = item.fileName
                let typeFileName = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)
                if(item.status !== "delete"){
                  return (
                    <div className="lineBottomSlide" key={index}>
                      <div className="item">
                        <div className="detail">
                          {typeFileName === "pdf"
                            ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="pdf" />
                            : typeFileName === "png"
                              ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pngIcon.png"} alt="png" />
                              : (typeFileName === "jpeg" || typeFileName === "jpg") && <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/jpgIcon.png"} alt="jpeg" />
                          }
                          <Link 
                            to="route"
                            onClick={(event) => { event.preventDefault(); 
                            window.open(
                              typeof item.fileUpload === "string"
                                ? item.fileUpload
                                : URL.createObjectURL(item.fileUpload)
                            ) }} 
                            target="blank">
                             {fileName.length >= 20 ? fileName.substr(0, 20) : fileName}
                          </Link>
                        </div>
                        <img
                          src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'}
                          alt="cancel-icon"
                          style={{ width: "10px", height: "10px" }}
                          onClick={() => 
                            this.props.handleRemoveImgSelect(
                              "requisitionNote.requisitionNoteDocuments", 
                              item.requisitionNoteDocumentsId,
                              index
                            )} 
                        />
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-6">
            <h6>{i18next.t("stockCreateAndUpdate:Note")}</h6>
            <textarea 
              // disabled={this.props.disibleInput}
              className={"bodyTextArea"}
              name="requisitionNote.remark"
              value={this.props.remark}
              onChange={this.props.handleChangeInput}
              required
              />
          </div>
        </div>

      </div>
    )
  }
}