/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type contactSummaryInvoiceQueryVariables = {|
  contactId?: ?string,
  status?: ?string,
|};
export type contactSummaryInvoiceQueryResponse = {|
  +countInvoiceByStatus: ?number,
  +summaryInvoiceByStatus: ?number,
|};
export type contactSummaryInvoiceQuery = {|
  variables: contactSummaryInvoiceQueryVariables,
  response: contactSummaryInvoiceQueryResponse,
|};
*/


/*
query contactSummaryInvoiceQuery(
  $contactId: String
  $status: String
) {
  countInvoiceByStatus(contactId: $contactId, status: $status)
  summaryInvoiceByStatus(contactId: $contactId, status: $status)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contactId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contactId",
    "variableName": "contactId"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "countInvoiceByStatus",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "summaryInvoiceByStatus",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contactSummaryInvoiceQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contactSummaryInvoiceQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "829d5aa5f21ca793c9b8bb10fff1ab0b",
    "id": null,
    "metadata": {},
    "name": "contactSummaryInvoiceQuery",
    "operationKind": "query",
    "text": "query contactSummaryInvoiceQuery(\n  $contactId: String\n  $status: String\n) {\n  countInvoiceByStatus(contactId: $contactId, status: $status)\n  summaryInvoiceByStatus(contactId: $contactId, status: $status)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0235e2fdb7eae3a3b6a15aa3e0d5387d';

module.exports = node;
