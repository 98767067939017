import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import Redirect from "react-router-dom/es/Redirect";
import PayChannel from "./payChannel";
import Swal from "sweetalert2";
import PayPaymentChannelFormInPay from "./core/payPaymentChannelFormInPay";
import _ from "lodash";
import UploadFileInput from "../../../libs/uploadFileInput";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import i18n from 'i18next';
import i18next from "i18next";

const mutation = graphql`
  mutation payDetailMutation($input: UpdateStatusPayInput!) {
    updateStatusPay(input: $input) {
      ok
      warningText
      newPay {
        id
      }
    }
  }
`;

const query = graphql`
  query payDetailQuery($id: ID!, $ref_transaction: String) {
    payRecordGroup(id: $id) {
      id
      contact {
        id
        name
        refNumber
        firstName
        lastName
        registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        typeOfContact
      }

      
      firstName 
      lastName
      name
      phone
      email
      taxIdNumber
      address
      district
      city
      province
      postalCode
      country

      payRecord {
        edges {
          node {
            id
            amount
            whtAmount
            purchaseRecord {
              id
              description
              unitItems
              price
              preTaxAmount
              vat
              paid
              whtRate
              whtRatePercent
              total
              prepaidDepositTransactionUsed {
                edges {
                  node {
                    amount
                  }
                }
              }
              acceptCreditNoteTransaction(status: "paid"){
                  edges {
                      node {
                          price
                          total
                      }
                  }
              }
              purchaseRecordGroup {
                id
                docNumber
                issuedDate
              }
              productAndService {
                id
                productCode
              }
              chartOfAccount {
                id
                name
                chartOfAccountCode
              }
            }
          }
        }
      }
      issuedDate
      refPayExpenseDate
      docNumber
      total
      remark
      status

      refNumber
      refPayDate
      refPayDoc

      paymentChannel(status:"paid",slug:"petty_cash"){
        edges{
          node{
            id
            price
            date
            setPettyCash{
              docNumber
              description
              withdrawer
          }
          }
        }
      }

      bankAccountTransaction {
        edges {
          node {
            id
            price
            date
            imageSlip
            bankAccount {
              bankName
              accountNumber
            }
          }
        }
      }
      cashTransaction {
        edges {
          node {
            id
            price
          }
        }
      }
      chequeTransaction {
        edges {
          node {
            id
            date
            bankAccount {
              id
              bankName
              accountName
              branch
            }
            chequeNumber
            price
          }
        }
      }
      acceptCreditNotePayRecordGroup(status_In: "waiting_pay, paid") {
        edges {
          node {
            id
            docNumber
            description
            price
          }
        }
      }
    }
    paymentChannelDatail(refTransaction: $ref_transaction) {
      refDoc
      date
      payType
      price
      imageslip
    }

    allAccountRecordGroup(refTransaction: $ref_transaction) {
      edges {
        node {
          id
          refNumber
          name
          added
          issuedDate
          category
          totalDebit
          totalCredit
          refTransaction
          creator
          contact {
            id
            name
            firstName
            lastName
          }
          accountRecord {
            edges {
              node {
                id
                name
                debit
                credit
                chartOfAccountCode {
                  id
                  name
                  chartOfAccountCode
                }
              }
            }
          }
        }
      }
    }
  }
`;

class PayDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,
      document_upload: "",
      ref_number: "",
      ref_date: "",
      payChannel: [],
      total: 0,
      totalPay: 0,
      remark: "",
      ref_pay_expense_date: new Date(),
      upload: {},
    };

    this.onChangeStatusVoid = this.onChangeStatusVoid.bind(this);
    this.onChangeStatusPaid = this.onChangeStatusPaid.bind(this);
    this.handleInputDocument = this.handleInputDocument.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updatePayChannel = this.updatePayChannel.bind(this);
    this.checkTypePayTitle = this.checkTypePayTitle.bind(this)
  }

  onChangeStatusVoid() {
    Swal.fire({
      title: "ต้องการยกเลิกบันทึกจ่ายค่าใช้จ่าย ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18next.t("Allaction:cancel"),
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18next.t("Allaction:Note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18next.t("Allaction:cancel"),
          confirmButtonText: i18next.t("Allaction:Confirm"),
          inputValidator: (value) => {
            if (!value) {
              return i18next.t("Allaction:Please enter a cancellation note.");
            }
          },
        });

        if (void_remark) {
          this.setState({ loading: true });
          let variables = {
            input: {
              clientMutationId: this.props.match.params.id,
              status: "void",
              voidRemark: void_remark,
            },
          };
          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              if (response.updateStatusPay.ok) {
                Swal.fire("ยกเลิกบันทึกจ่ายค่าใช้จ่ายสำเร็จ!", "", "success").then(() => {
                  this.setState({ redirect: true, loading: false });
                });
              } else {
                if(response.updateStatusPay.warningText){
                  Swal.fire("ยกเลิกบันทึกจ่ายค่าใช้จ่ายไม่สำเร็จ!", response.updateStatusPay.warningText, "warning");
                }else{
                  Swal.fire("ยกเลิกบันทึกจ่ายค่าใช้จ่ายไม่สำเร็จ!","", "warning");
                }
                this.setState({ loading: false });
              }
            },
            onError: (err) => {
              Swal.fire("Error!", i18next.t("Allaction:Please try again."), "warning");
              this.setState({ loading: false });
            },
          });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }

  updatePayChannel(channel_list) {
    let totalPay = 0;
    let upload = {};
   // channel_list.forEach(channel => {
    channel_list.forEach((channel, index) => {
      if (channel.slug === "small-change" || channel.slug === "fee") {
        totalPay -= parseFloat(channel.pay);
      }
      else if(channel.slug === "petty_cash"){
        let petty_cash = parseFloat(0);
        if(parseFloat(channel.pay)) petty_cash = parseFloat(channel.pay);
        totalPay += petty_cash;
      }
      else {
        totalPay += parseFloat(channel.pay);
      }
      if (channel.image) {
        upload[index] = channel.image;
       // Object.assign(upload, { [channel.slug]: channel.image });
      }
    });
    this.setState({
      payChannel: channel_list,
      totalPay: parseFloat((totalPay).toFixed(2)),
      upload: upload
    });
  }

  onChangeStatusPaid = (props) => (event) => {
    event.preventDefault();
    if (this.state.ref_number || this.state.ref_date || props.payRecordGroup.status === "DRAFT" || props.payRecordGroup.refNumber || props.payRecordGroup.refPayDate
    ) {
      Swal.fire({
        title: "คุณแน่ใจที่จะอนุมัติบันทึกจ่ายค่าใช้จ่าย ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: i18next.t("Allaction:cancel"),
        confirmButtonText: "ตกลง",
      }).then(async (result) => {
        if (result.value) {
          this.setState({ loading: true });
          let variables;
          if (props.payRecordGroup.status === "DRAFT") {
            variables = {
              input: {
                clientMutationId: this.props.match.params.id,
                payChannel: JSON.stringify(this.state.payChannel),
                remark: this.state.remark,
                status: "paid",
                refNumber: this.state.ref_number,
              },
            };
            if(this.state.ref_pay_expense_date !== ""){
              _.set(variables, "input.refPayExpenseDate", format(this.state.ref_pay_expense_date, "YYYY-MM-DD"));
            }
            if(props.payRecordGroup.total !== this.state.totalPay){
              Swal.fire(i18n.t("cash_deposit:Wrong amount"), i18n.t("cash_deposit:Please check the amount again"), 'warning').then(() => {
                this.setState({ loading: false });
              });
              return;
            }

          } else {
            variables = {
              input: {
                clientMutationId: this.props.match.params.id,
                status: "paidedit",
                refNumber: this.state.ref_number,
              },
            };
          }
          if (this.state.ref_date !== "") {
            _.set(variables, "input.refDate", format(this.state.ref_date, "YYYY-MM-DD")
            );
          }

         //let uploadables = Object.assign(this.state.upload);
        let uploadables = Object.assign(
          { document_upload: this.state.document_upload },
          this.state.upload
      );

          commitMutation(environment, {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
              if (response.updateStatusPay.newPay) {
                Swal.fire(
                  "อนุมัติบันทึกจ่ายค่าใช้จ่ายสำเร็จ!",
                  "",
                  "success"
                ).then(() => {
                  this.setState({
                    redirect: true,
                    loading: false,
                    uploading: false,
                  });
                });
              } else {
                Swal.fire(
                  "อนุมัติบันทึกจ่ายค่าใช้จ่ายไม่สำเร็จ!",
                  response.updateStatusPay.warningText,
                  "warning"
                );
                this.setState({ loading: false });
              }
            },
            onError: (err) => {
              Swal.fire("Error!", i18next.t("Allaction:Please try again."), "warning");
              this.setState({ loading: false });
            },
          });
        }
      });
    }
  };

  getTotalAmount(purchaseRecord) {
    let acnsums = 0
    let sumpd = 0
    purchaseRecord.acceptCreditNoteTransaction.edges.forEach((acnsum) => {
        acnsums = + acnsum.node.total
    }
    )
    if (_.find(purchaseRecord.prepaidDepositTransactionUsed.edges, { node: { prepaidDepositTransaction: { whtRatePercent: null } } }) === undefined) {
      purchaseRecord.prepaidDepositTransactionUsed.edges.forEach((pdsum) => {
            if (pdsum?.node?.prepaidDepositTransaction?.whtRatePercent) {
                sumpd = sumpd + Math.round((parseFloat(pdsum.node.amount) / (1 + parseFloat(purchaseRecord.vat || 0) / 100) + Number.EPSILON) * 100) / 100;
            }
        }
        )
    }
    let amount = (purchaseRecord.total) - (acnsums - sumpd)
    return amount
    // return parseFloat(purchaseRecord.total);
  }

  handleInputDocument(e) {
    this.setState({ document_upload: e.currentTarget.files[0], });
  }

  handleInputChange(e) {
    if (e.target.name === "ref_number") {
      if (e.target.value === "") {
        this.setState({ ref_date: "" });
      } else {
        if (this.state.ref_date === "") {
          this.setState({ ref_date: new Date() });
        }
        if(this.state.ref_pay_expense_date === ""){
          this.setState({ ref_pay_expense_date: new Date() });
        }
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  }

   checkTypePayTitle = (value) => {
      const substring = 'PP';
      let text = ''
      if (value.includes(substring)) {
          text = 'PP'
      } else {
          text = 'PS'
      }
      return "("+text+")"
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/accounting/expense/pay/list/all" />;
    }

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              id: this.props.match.params.id,
              ref_transaction: this.props.match.params.ref_transaction,
            }}
            render={({ error, props }) => {
              if (error) {
                return <div>Error :(</div>;
              } else if (props) {
                let total_sum = 0
                // หาวันที่ไม่ให้ทำจ่ายได้ก่อนวันที่ตั้งค่าใช้จ่าย
                let allDate = [];
                let min_date = new Date()
                if(props.payRecordGroup.status === "DRAFT" && props.paymentChannelDatail.payType.length === 0){
                  if (props.payRecordGroup.payRecord.edges.length > 1) {
                    props.payRecordGroup.payRecord.edges.forEach((value) => {
                          allDate.push(new Date(value.node.purchaseRecord.purchaseRecordGroup.issuedDate))
                      })
                  } else {
                      allDate.push(new Date(props.payRecordGroup.payRecord.edges[0].node.purchaseRecord.purchaseRecordGroup.issuedDate))
                  }
                  let sortDate = allDate.sort((date1, date2) => {
                      return date1 - date2
                  })
                  let max_issuedDate = 0
                  if (sortDate.length > 0) {
                      max_issuedDate = sortDate.length - 1
                  }
                  min_date = new Date(sortDate[max_issuedDate])
                }

                return (
                  <React.Fragment>
                    <div className="container-fluid box" id="invoice-detail">
                      <div className="row justify-content-between">
                        <div className="col">
                          <h3 className="mb-4">
                            <Link to="/accounting/expense/pay/list/all">
                              <img
                                src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"}
                                alt="back"
                                className="back"
                              />
                            </Link>
                            <Translation>
                              {(t) => <span>{t("accounting:pay")+" "+this.checkTypePayTitle(this.props.match.params.ref_transaction)}</span>}
                            </Translation>
                          </h3>
                        </div>
                        <div className="col text-right pb-2">
                          {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_pay_delete" }) && (
                            <button
                              type="button"
                              className="btn btn-danger add mr-3"
                              disabled={this.state.loading}
                              onClick={this.onChangeStatusVoid}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                              )}
                              <Translation>{t=> i18next.t("payCreateAccrued:Cancel expense payment record")}</Translation>
                              
                            </button>
                          )}

                          {
                            // props.payRecordGroup.status === "PAID" &&
                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_pay_print" }) && (
                              <Link
                                to={
                                  "/document/pay/" + this.props.match.params.id + "/" + this.props.match.params.ref_transaction
                                }
                                target="_blank"
                              >
                                <button className="btn btn-primary add" disabled={this.state.loading}>
                                  {props.payRecordGroup.status === "PAID" ? 
                                   <Translation>{t=> i18next.t("guarantee_money_received:Print payment voucher")}</Translation>
                                   :  <Translation>{t=> i18next.t("payCreateAccrued:Print pre - payment")}</Translation>}
                                </button>
                              </Link>
                            )}
                        </div>
                      </div>
                      <Translation>
                        {(t)=> 
                      <form id="form-payedit" onSubmit={this.onChangeStatusPaid(props)}>
                        <div className="content-inner">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("payCreateAccrued:Accounts Payable")}</strong>
                                  </p>
                                  <p>
                                    {props.payRecordGroup.contact.refNumber}{" "}
                                    {props.payRecordGroup.contact.name}{" "}
                                  </p>
                                </div>
                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("payCreateAccrued:Document number")}</strong>
                                  </p>
                                  <p>{props.payRecordGroup.docNumber}</p>
                                </div>
                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("payCreateAccrued:Payment Preparation Date")}</strong>
                                  </p>
                                  <p>
                                    {format(props.payRecordGroup.issuedDate, "DD/MM/YYYY", { locale: thLocale })}
                                  </p>
                                </div> 
                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("payCreateAccrued:Expense Document Issuance Date")}</strong>
                                    
                                    </p>
                                    <p>
                                      {
                                        props.payRecordGroup.docNumber.includes("PP") ?
                                        <DatePickerAdapter
                                          name="ref_pay_expense_date"
                                          className="form-control"
                                          selected={this.state.ref_pay_expense_date}
                                          onChange={this.handleInputChange}
                                          required={props.payRecordGroup.status === "PAID" || this.state.ref_number}
                                        /> :
                                          props.payRecordGroup.refPayExpenseDate ? format(props.payRecordGroup.refPayExpenseDate, "DD/MM/YYYY", { locale: thLocale }) : "-"
                                      }
                                    </p>

                                </div>
                              </div>

                              <div className="row">
                                <div className="col-3">
                                  <label htmlFor="ref_number" className="mr-5">
                                    <strong>{i18next.t("payCreateAccrued:Tax invoice/receipt number")}</strong>
                                  </label>
                                  {props.payRecordGroup.refNumber ? (
                                    <p>
                                      {props.payRecordGroup.refNumber || "-"}
                                    </p>
                                  ) : (
                                    <>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="ref_number"
                                      value={this.state.ref_number}
                                      onChange={this.handleInputChange}
                                      name="ref_number"
                                      required={props.payRecordGroup.status === "PAID" || this.state.ref_date}
                                      maxLength="30"
                                    /> 
                                     <strong className="text-danger font-small">{t("PurchaseCreate:*Enter text no more than 30 characters")} {this.state.ref_number.length} / 30</strong>
                                    </>
                                    
                                  )}
                                    
                                </div>
                                <div className="col-3">
                                  <label htmlFor="ref_number" className="mr-5">
                                    <strong>{i18next.t("payCreateAccrued:Date ")}</strong>
                                  </label>
                                  <div className="input-group">
                                    {props.payRecordGroup.refPayDate ? (
                                      <DatePickerAdapter
                                        name="refPayDate"
                                        className="form-control"
                                        selected={new Date(props.payRecordGroup.refPayDate)}
                                        onChange={this.handleInputChange}
                                        required={true}
                                        disable={true}
                                      />
                                    ) : (
                                      <DatePickerAdapter
                                        name="ref_date"
                                        className="form-control"
                                        selected={this.state.ref_date}
                                        onChange={this.handleInputChange}
                                        required={props.payRecordGroup.status === "PAID" || this.state.ref_number}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="col-3">
                                  {props.payRecordGroup.refPayDoc ? (
                                    <React.Fragment>
                                      <p>
                                        <strong>{i18next.t("PayList:Payment document")}</strong>
                                      </p>
                                      <a
                                        download="name_of_downloaded_file"
                                        href={props.payRecordGroup.refPayDoc}
                                      >
                                        <img
                                          src={process.env.PUBLIC_URL + "/images/icons/download-file-icon.png"}
                                          alt="back"
                                          className="download-file-icon"
                                        />
                                      </a>
                                    </React.Fragment>
                                  ) : props.payRecordGroup.status === "PAID" &&
                                    props.payRecordGroup.refNumber &&
                                    props.payRecordGroup.refPayDate ? (
                                    <div></div>
                                  ) : (
                                    <UploadFileInput
                                      handleInputDocument={this.handleInputDocument}
                                      documentUpload={this.state.document_upload}
                                    />
                                  )}
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("payCreateAccrued:Address")}</strong>
                                  </p>
                                  {/* <p>
                                    {props.payRecordGroup.contact?.registeredProvince === "กรุงเทพ" || props.payRecordGroup.contact?.registeredProvince.includes("กทม") ? (
                                      <>
                                        {props.payRecordGroup.contact.registeredAddress}
                                        {props.payRecordGroup.contact.registeredDistrict && ` แขวง${props.payRecordGroup.contact.registeredDistrict}`}
                                        {props.payRecordGroup.contact.registeredCity && ` เขต${props.payRecordGroup.contact.registeredCity}`}
                                        {props.payRecordGroup.contact.registeredProvince && ` ${props.payRecordGroup.contact.registeredProvince} `}
                                        {props.payRecordGroup.contact.registeredPostalCode}{" "}
                                        {props.payRecordGroup.contact.registeredCountry}
                                      </>
                                    ) : (
                                      <>
                                        {props.payRecordGroup.contact.registeredAddress}
                                        {props.payRecordGroup.contact.registeredDistrict && ` ตำบล${props.payRecordGroup.contact.registeredDistrict}`}
                                        {props.payRecordGroup.contact.registeredCity && ` อำเภอ${props.payRecordGroup.contact.registeredCity}`}
                                        {props.payRecordGroup.contact.registeredProvince && ` จังหวัด${props.payRecordGroup.contact.registeredProvince} `}
                                        {props.payRecordGroup.contact.registeredPostalCode}{" "}
                                        {props.payRecordGroup.contact.registeredCountry}
                                      </>
                                    )}
                                  </p> */}

                                   {/* BTA685 new rq */}
                                   <p>
                                   {props.payRecordGroup?.province === "กรุงเทพ" || props.payRecordGroup?.province === "กรุงเทพมหานคร" || props.payRecordGroup?.province.includes("กทม") ? (
                                            <>
                                            {props.payRecordGroup?.address + " "}
                                            {props.payRecordGroup?.district &&  ` แขวง${props.payRecordGroup.district}`}
                                            {props.payRecordGroup?.city && ` เขต${props.payRecordGroup.city}`}
                                            {props.payRecordGroup?.province && ` ${props.payRecordGroup.province} `}
                                            {props.payRecordGroup?.postalCode} {" "}
                                            {props.payRecordGroup?.country}
                                            </>
                                   ) : (
                                            <>
                                            {props.payRecordGroup.address}
                                            {props.payRecordGroup.district && ` ตำบล${props.payRecordGroup.district}`}
                                            {props.payRecordGroup.city && ` อำเภอ${props.payRecordGroup.city}`}
                                            {props.payRecordGroup.province && ` จังหวัด${props.payRecordGroup.province} `}
                                            {props.payRecordGroup.postalCode}{" "}
                                            {props.payRecordGroup.country}
                                            </>
                                   ) }
                                  </p>
                                </div>
                              </div>

                              <div className="table-responsive fade-up">
                                <table className="table table-hover mt-2">
                                  <thead className="thead-light">
                                    <tr>
                                      <th width="80">{i18next.t("payCreateAccrued:List")}</th>
                                      <th width="200">{i18next.t("payCreateAccrued:Description")}</th>
                                      <th className="text-right" width="80">
                                      {i18next.t("payCreateAccrued:Amount ")}
                                      </th>
                                      <th className="text-right" width="100">
                                      {i18next.t("payCreateAccrued:Withholding tax (Baht)")}
                                      </th>
                                      <th className="text-right" width="80">
                                      {i18next.t("payCreateAccrued:Total Amount")}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {_.orderBy(props.payRecordGroup.payRecord.edges,'node.purchaseRecord.purchaseRecordGroup.docNumber','asc').map((pay_record) => {
                                      total_sum += pay_record.node.amount;
                                      return(
                                        <tr key={pay_record.node.id}>
                                          <td>
                                            ({pay_record.node.purchaseRecord.purchaseRecordGroup.docNumber}){"\t"}
                                            {pay_record.node.purchaseRecord.productAndService
                                              ? pay_record.node.purchaseRecord.productAndService.productCode
                                              : pay_record.node.purchaseRecord.chartOfAccount.chartOfAccountCode +
                                              " " + pay_record.node.purchaseRecord.chartOfAccount.name}
                                          </td>
                                          <td>
                                            {pay_record.node.purchaseRecord.description}
                                          </td>
                                          <td className="text-right">
                                            {numberWithComma(this.getTotalAmount(pay_record.node.purchaseRecord))}
                                          </td>
                                          <td className="text-right">
                                            {numberWithComma(pay_record.node.whtAmount)}
                                          </td>
                                          <td className="text-right">
                                            {numberWithComma(pay_record.node.amount)}
                                          </td>
                                        </tr>
                                      )}
                                    )}

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          {props.payRecordGroup.status === "PAID" && (
                            <PayChannel
                              total={props.payRecordGroup.total}
                              bankTransaction={props.payRecordGroup.bankAccountTransaction.edges}
                              cashTransaction={props.payRecordGroup.cashTransaction.edges}
                              chequeTransaction={props.payRecordGroup.chequeTransaction.edges}
                              allAccountRecordGroup={props.allAccountRecordGroup?.edges}
                              paymentChannel={props.payRecordGroup.paymentChannel.edges}
                              account_type="pay"
                            />
                          )}

                          {/* {props.payRecordGroup.status === "DRAFT" &&
                            <PayPaymentChannelFormInPay updatePayChannel={this.updatePayChannel}
                              payment_type={'pay'}
                              pay_form={true}
                              issued_date={props.payRecordGroup.issuedDate}
                              auto_suggest={20}
                              pay={10}
                            />
                          } */}
      
                          {props.payRecordGroup.status === "DRAFT" && props.paymentChannelDatail.payType.length !== 0 ? (
                              <PayChannel
                                payType={props.paymentChannelDatail.payType}
                                account_type="draft"
                                date={props.paymentChannelDatail.date}
                                ref_doc={props.paymentChannelDatail.refDoc}
                                price={props.paymentChannelDatail.price}
                                image={props.paymentChannelDatail.imageslip}
                                total={props.payRecordGroup.total}
                                bankTransaction={props.payRecordGroup.bankAccountTransaction.edges}
                                cashTransaction={props.payRecordGroup.cashTransaction.edges}
                                chequeTransaction={props.payRecordGroup.chequeTransaction.edges}
                                allAccountRecordGroup={props.allAccountRecordGroup?.edges}
                              />
                            )
                            :
                            props.payRecordGroup.status === "DRAFT" && props.paymentChannelDatail.payType.length === 0 &&
                              <PayPaymentChannelFormInPay updatePayChannel={this.updatePayChannel}
                                payment_type={'pay'}
                                pay_form={true}
                                issued_date={props.payRecordGroup.issuedDate}
                                minDate={min_date}
                                auto_suggest={parseFloat((total_sum).toFixed(2))}
                                pay={this.state.totalPay}
                              />
                          }
                          <div className="row mt-4">
                            
                            <div className="col">
                              <label htmlFor="note">
                                <strong>{i18next.t("payCreateAccrued:Note")}</strong>
                              </label>
                              <textarea className="form-control" rows="2"
                                name="remark"
                                value={props.payRecordGroup.status === "DRAFT" ? this.state.remark : props.payRecordGroup.remark}
                                onChange={this.handleInputChange}
                                disabled={props.payRecordGroup.status === "DRAFT" ? false : true}
                              />
                            </div>

                            <div className="col offset-6 bg-light mr-3">
                              <div className="row p-3 mb-2 text-dark">
                                <div className="col text-left">{i18next.t("payCreateAccrued:Total")}</div>
                                <div className="col text-right">
                                  {" "}
                                  {numberWithComma(props.payRecordGroup.total)}{" "}
                                  {i18next.t("payCreateAccrued:Baht")}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          {(props.payRecordGroup.refNumber && props.payRecordGroup.refPayDate && props.payRecordGroup.status === "PAID") ||
                            this.state.uploading === false ? (
                            <div></div>
                          ) : (
                            <div className="row">
                              <div className="col text-right mt-2 button-grp">
                                <button type="submit"
                                  className="btn btn-primary add"
                                  disabled={this.state.loading}
                                >
                                  {this.state.loading && (<span className="spinner-border spinner-border-sm align-middle mr-2" />)}
                                  {props.payRecordGroup.status === "DRAFT" ? i18next.t("payCreateAccrued:Approve"): i18next.t("payCreateAccrued:Save")}
                                </button>
                              </div>
                            </div>
                          )}

                        </div>
                      </form>
                        
                        
                        }
                      </Translation>
                    </div>
                  </React.Fragment>
                );
              }
            }}
          />
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PayDetail;
