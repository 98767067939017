import React, {Component} from 'react';

import $ from "jquery";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import "../styles/cashDeposit.scss";
import { convertToTimeZone } from '../../libs/date';

import i18n from 'i18next'
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';
const ThaiBaht = require('thai-baht-text');

class PaymentGateway extends Component {

    componentDidMount() {
        i18n.changeLanguage(this.props.languages);
        setTimeout(() => {
            let page = $('.'+this.props.cash_page);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1000 - 150;
            let diff = page_height - (head + (detail+detail) + invoice + signature);
            $('.' + this.props.cash_page + ' table').css({borderBottom: "1px solid"});
            if (this.props.current_page !== this.props.end_page) {
                $('.' + this.props.cash_page + ' tbody tr:last-child td').css({paddingBottom: "35px"});
            } else {
                $('.' + this.props.cash_page + ' tbody tr:last-child td').css({paddingBottom: diff});
            }
        }, 200);
        

    }

    render() {
        return (

            <div className={"print-daily-journal-a4 " + this.props.cash_page} id="cash_deposit">
                <div className="subpage">
                    <div className="head">
                        <div className="juristic-description" style={{position:"relative"}}>
                            <img src={this.props.query.selfProject.logo} alt="silverman"/>
                            <span className="text-center" style={{position:"absolute"}}>
                                <div><strong style={{fontSize:12}}>{this.props.query.selfProject.name}</strong></div>
                                <div>{this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                            </span>
                        </div>
                    </div>
                    <div className="detail mt-3" >
                        <div className="customer-detail">
                            <br />
                            <strong>รายการลูกหนี้เพย์เมนท์เกตเวย์ ประจำวันที่ {this.props.query.paymentGatewayGroup.depositDate ? format(convertToTimeZone(this.props.query.paymentGatewayGroup.depositDate, this.props.query.selfProject.timeZone), 'DD/MM/YYYY', {locale: thLocale}) : format(new Date(), 'DD/MM/YYYY', {locale: thLocale})} </strong>
                        </div>
                        <div className="document-detail" >
                            <strong className="sub-document-detail">เลขที่ / No :</strong> {this.props.query.paymentGatewayGroup.docNumber ? this.props.query.paymentGatewayGroup.docNumber : '-'}
                            <br/>
                            <strong className="sub-document-detail">วันที่ / Date :</strong> {this.props.query.paymentGatewayGroup.depositDate ? format(convertToTimeZone(this.props.query.paymentGatewayGroup.depositDate, this.props.query.selfProject.timeZone), 'DD/MM/YYYY', {locale: thLocale}) : format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}
                        </div>
                        
                    </div>
                    

                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center">
                                    <strong>เลขที่</strong>
                                </th>
                                <th className="text-center">
                                    <strong>วันที่</strong>
                                </th>
                                <th className="text-center">
                                    <strong>คำอธิบาย</strong>
                                </th>
                                <th className="text-center">
                                    <strong>จำนวนเงิน</strong>
                                </th>
                            </tr>
                            </thead>
                            <tbody>

                            {this.props.all_payment_gateway.map((payment_gateway)=>{
                                return(
                                    <tr key={payment_gateway.node.id}>
                                        <td className="text-center">
                                            {payment_gateway.node.refNumber}
                                        </td>
                                        <td className="text-center"> {format(payment_gateway.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                        <td >{payment_gateway.node.description}</td>
                                        <td className="text-right">{numberWithComma(payment_gateway.node.total)}</td>
                                    </tr>
                                )
                            })}

                            </tbody>
                            <tfoot>
                            <tr>
                                <td className="text-center" style={{borderRight: "none"}}>
                                    <strong>รวม</strong>
                                </td>
                                <td colSpan={2} className="text-left" style={{borderLeft:"none"}}>
                                    <strong style={{fontSize:12}}>
                                        {i18n.languages[0] === 'th' ? 
                                        (ThaiBaht(this.props.total)):
                                        (ConvertNumberToTextEng(this.props.total))}
                                    </strong>
                                </td>
                                <td className="text-right">
                                    <strong style={{fontSize:12}}>{numberWithComma(this.props.total)}</strong>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div className="footer">
                        <div className="row pt-5">
                            <div className="col">
                                <span className="box-right">
                                ..................................................<br/>
                                <strong>ผู้จัดทำ</strong>
                            </span>
                            </div>
                        </div>
                        <div className="pt-2">
                            <span className="box-right">
                            วันที่ ................................
                            </span>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

export default PaymentGateway;
