import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import Wrapper from '../../../components/wrapper';
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from '../../accountingTopMenuNavigation';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/asset.scss';
import { fetchQuery , commitMutation} from "relay-runtime";
import environment from '../../../env/environment';
import _ from 'lodash'
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';
import Swal from 'sweetalert2';
import {format, setDate, setMonth, lastDayOfMonth, setYear} from "date-fns";
import {Redirect} from 'react-router';
import numberWithComma from '../../../libs/numberWithComma'
import CreateOtherReceive from './createOtherReceive';
import PaymentChannel from './paymentChannel';
import UpdateAssetMutation from './updateAsset';
import upload from '../../../libs/upload';
import PayChannel from './payChannel';
import Loading from "../../../libs/loading";
import i18next from 'i18next';

const mutation = graphql`
    mutation assetDisappearMutation($input: CreateAndUpdateDisappearAssetInput!) {
        createAndUpdateDisappearAsset(input: $input) {
            ok
        }
    }
`;


const query = graphql`
    query assetDisappearQuery($id: ID!,$date:Date) {
        asset(id: $id) {
            id
            type
            docNumber
            nounClassifier
            chartOfAccountType {
                chartOfAccountCode
                name
                id
            }
            chartOfAccountDepreciation {
                id
                chartOfAccountCode
                name
            }
            chartOfAccountDepreciationCumulative {
                id
                chartOfAccountCode
                name
            }
            name
            description
            storage
            remark
            internalAssetCode
            seller{
                id
            }
            sellerOther
            calDepreciation
            depreciationRatePercent
            depreciationRate
            lifeTimeYear
            purchaseDate
            saleDate
            calDepreciationDate
            costPrice
            carcassPrice
            calSchedule
            historicalValue
            historicalAmount
            saleStatus
            assetDepreciate(date_Lte:$date) {
                edges {
                    node {
                        id
                        depreciatePrice
                    }
                }
            }
            disappearAsset {
                edges {
                    node {
                        id
                        nameContact 
                        adressContact
                        saleDate
                        insurace
                        insuracePrice
                        insuraceAmount
                            approver
                            disappearDocument {
                                edges {
                                    node {
                                        fileName fileUpload id
                                    }
                                }
                            }
                    }
                }
            }
            otherReceiveTransaction {
            edges {
                node {
                    price
                    description
                    total
                    otherReceive{
                        docNumber 
                        unknownReceive {
                            edges {
                                node {
                                    id
                                    docNumber
                                    refNumber
                                    description
                                    amount
                                    chartOfAccount {
                                    chartOfAccountCode
                                    name
                                    }
                                }
                            }
                        }
                        bankAccountTransaction{
                            edges {
                                node {
                                    id
                                    price
                                    date
                                    imageSlip
                                    bankAccount {
                                        bankName
                                        accountNumber
                                    }
                                }
                            }
                        }
                        cashTransaction{
                            edges {
                                node {
                                    id
                                    description
                                    price
                                }
                            }
                        }
                        chequeDeposit{
                            edges {
                                node {
                                    id
                                    price
                                    date
                                    chequeNumber
                                    bankName
                                    branchNumber
                                    chequeImage
                                }
                            }
                        }
                        creditCardTransaction {
                            edges {
                                node {
                                    id
                                    amount
                                }
                            }
                        }
                    }
                }
            }
        }
        }
        viewer {
            allChartOfAccount(codeList:["4300"]) {
                edges {
                    node {
                        id chartOfAccountCode name
                    }
                }
            }
        }
        
    }
`;

class AssetDisappear extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading : false,
            redirect : false,
            last_date : lastDayOfMonth(setDate(setMonth(new Date(), new Date().getMonth() - 1), 1)),
            sum_asset_depreciate : 0,
            asset: {
                id: '',
                type: 'LAND',
                nounClassifier: '',
                chartOfAccountDepreciation: {id: ''},
                chartOfAccountType: {id: ''},
                chartOfAccountDepreciationCumulative: {id: ''},

                saleStatus: '',

                docNumber: '',
                name: '',
                description: '',
                storage: '',
                seller: {id: '',},
                sellerOther: "",
                remark:"",
                refNumber : '',

                calDepreciation: false,
                formula: 'STRAIGHT_LINE',
                depreciationRatePercent: 5,
                depreciationRate: 0.05,
                lifeTimeYear: 20,
                purchaseDate: new Date(),
                calDepreciationDate: new Date(),
                costPrice: 0,
                historicalValue: 0,
                carcassPrice: 1,
                calSchedule: "MONTHLY",
                historicalAmount: 0,
                saleDate: new Date(),
                internalAssetCode : ''
            },
            payChannel: [],
            upload: {},
            totalPay: 0,
            nameContact : '',
            adressContact : '',
            saleDate: new Date(),
            remark : '',
            chartOfAccount: '',
            chartOfAccountId : '',
            insurace : false,
            approver : '',
            disappearDocument : [],
            disappearAsset: {
                node : {
                    id : ''
                }
            },
            description: '',
            otherReceive : null,
            disappearAssetId : '',
            insuracePrice : 0,
            asset_amount : 0 //มูลค่าทางบัญชี
        }
       
    }

    componentDidMount(){
        this.fetchData(true)

    }


    fetchData = async(reload) => {
        if(reload){
            this.setState({loadingData : true})
        }
        await fetchQuery(environment , query , {
            id : this.props.match.params.id,
            date: format(this.state.last_date, 'YYYY-MM-DD')
        }).then(response => {
            if(response?.viewer.allChartOfAccount?.edges.length > 0){
                _.forEach(response.viewer.allChartOfAccount.edges , chartOfAccount => {
                    if(chartOfAccount.node.name === 'กำไร(ขาดทุน)จากการตัดจำหน่ายสินทรัพย์'){
                        this.setState({
                            chartOfAccount : chartOfAccount.node.chartOfAccountCode + ' ' + chartOfAccount.node.name,
                            chartOfAccountId : chartOfAccount.node.id
                        })
                    }
                })
            }
            if(response.asset.disappearAsset.edges?.length > 0){
                this.setState({
                    disappearAsset : response?.disappearAsset?.edges[0]
                })
            }

            let data = _.cloneDeep(response);
            _.set(data.asset, 'purchaseDate', data.asset.purchaseDate ? new Date(data.asset.purchaseDate) : null);
            _.set(data.asset, 'saleStatus', 'disappear');
            _.set(data.asset, 'calDepreciationDate', data.asset.calDepreciationDate ? new Date(data.asset.calDepreciationDate) : null);
            _.set(data.asset, 'saleDate', data.asset.saleDate ? new Date(data.asset.saleDate) : new Date());
            

            if (!_.get(data.asset, "seller") && !_.get(data.asset, "sellerOther")) {
                _.set(data.asset, 'seller', {id: "on_contact"});
            } else if (!_.get(data.asset, "seller") && _.get(data.asset, "sellerOther")) {
                _.set(data.asset, 'seller', {id: "other_select"});
            }

            this.setState({
                asset: data.asset,
                otherReceive : response.asset.otherReceiveTransaction?.edges.length > 0 ? response.asset.otherReceiveTransaction.edges[0] : null,
                // totalPay: response.asset.otherReceiveTransaction?.edges.length > 0 ? response.asset.otherReceiveTransaction.edges[0].node.price : 0,
                totalPay:response.asset.disappearAsset?.edges.length > 0 ?  response.asset.disappearAsset.edges[0].node.insuraceAmount : 0 ,
                description: response.asset.otherReceiveTransaction?.edges.length > 0 ? response.asset.otherReceiveTransaction.edges[0].node.description : 0,
                adressContact:  response.asset.disappearAsset?.edges.length > 0 ? response.asset.disappearAsset.edges[0].node.adressContact : '',
                nameContact:  response.asset.disappearAsset?.edges.length > 0 ? response.asset.disappearAsset.edges[0].node.nameContact : '',
                saleDate:  response.asset.disappearAsset?.edges.length > 0 ? new Date(response.asset.disappearAsset.edges[0].node.saleDate) : new Date(),
                approver : response.asset.disappearAsset?.edges.length > 0 ? response.asset.disappearAsset.edges[0].node.approver : '',
                insurace : response.asset.disappearAsset?.edges.length > 0 ? response.asset.disappearAsset.edges[0].node.insurace : false,
                insuraceAmount : response.asset.disappearAsset?.edges.length > 0 ? response.asset.disappearAsset.edges[0].node.insuraceAmount : null,
                insuracePrice : response.asset.disappearAsset?.edges.length > 0 ? response.asset.disappearAsset.edges[0].node.insuracePrice : null,
                disappearDocument : response.asset.disappearAsset?.edges.length > 0 ? response.asset.disappearAsset.edges[0].node.disappearDocument?.edges : [],
                disappearAssetId : response.asset.disappearAsset?.edges.length > 0 ? response.asset.disappearAsset.edges[0].node.id : ''

            },() => {
                this.calDepreciation()
                this.setState({
                    loadingData:false,
                    description : this.state.asset.chartOfAccountType.name
                })
            });

        }).catch(err => {
            alert(err)
        })

    }

    calDepreciation = () => {
        let amount = (this.state.asset.costPrice - this.state.asset.historicalAmount - this.state.asset.carcassPrice)
        let total_day = setYear(new Date(this.state.asset.purchaseDate) ,(new Date(this.state.asset.purchaseDate).getFullYear() + this.state.asset.lifeTimeYear))  - new Date(this.state.asset.calDepreciationDate)
        let total_day_date = Math.ceil(total_day / (1000 * 60 * 60 * 24));
        let amount_per_day = amount/total_day_date
        let numberOfDays = new Date(this.state.asset.saleDate).getDate()
        let asset_amount = amount_per_day*numberOfDays
        let sum_asset_depreciate = _.sumBy(this.state.asset.assetDepreciate.edges,'node.depreciatePrice')
        let total_asset = this.state.asset.costPrice - this.state.asset.historicalAmount - (sum_asset_depreciate + asset_amount)
        this.setState({
            asset_amount : total_asset
        })

    }
    handleChange = (e) => {
        let event = _.cloneDeep(e)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/asset/data" + path, file);
          resolve(res);
        });
    };


    onSubmit = () => {
        this.setState({
            loading : true
        })
        let input = {
            asset: JSON.stringify(this.state.asset),  
        };

        UpdateAssetMutation(input, this.onSuccessAsset, this.onError)

    }

    toggle = () => {
        this.setState({
            insurace : !this.state.insurace
        })
    }

    onSuccessAsset = async(response) => {
        let disappearDocument = []
        if(this.state.disappearDocument.length > 0){
            for (const file of this.state.disappearDocument){
                if(file.node.disappearDocumentId === ""){
                    await this.callUpload('', file.node.fileUpload).then((data) => {
                        disappearDocument.push({
                            disappearDocumentId : "" , 
                            fileUpload : data.key.replace("silverman-storage/private/", "") , 
                            status : "",
                            fileName : file.node.fileName
                        })
                    });
                }
            }
        }
        let variables = {
            input : {
                disappearAsset : {
                    disappearAssetId : this.state.disappearAssetId,
                    assetId : this.props.match.params.id,
                    insurace : this.state.insurace,
                    nameContact : this.state.nameContact,
                    adressContact : this.state.adressContact,
                    saleDate : format(this.state.asset.saleDate , 'YYYY-MM-DD'),
                    dueDate : format(this.state.asset.saleDate , 'YYYY-MM-DD'),
                    chartOfAccountBookId : this.state.chartOfAccountId,
                    productAndServiceId : this.state.asset.chartOfAccountType.id,
                    description : this.state.description,
                    numberOfAccount : 1,
                    total : this.state.asset_amount >= this.state.asset.carcassPrice ?  parseFloat(this.state.asset_amount) : parseFloat(this.state.asset.carcassPrice),
                    payChannel : this.state.payChannel.length > 0 ? this.state.payChannel[0].slug : '',
                    remark : this.state.remark,
                    insuraceAmount:parseFloat(this.state.totalPay),
                    approver:this.state.approver,
                    disappearDocument: disappearDocument,
                    insuracePrice : this.state.insuracePrice
                }
            }
        }
        
        if (response.createAsset.ok) {
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: async(response) => {
                        if (response.createAndUpdateDisappearAsset.ok) {
                            if(this.state.insurace){
                                await this.fetchData(false)
                                let invoice = {
                                    id: '',
                                    issuedDate : format(this.state.asset.saleDate , 'YYYY-MM-DD'),
                                    dueDate : format(this.state.asset.saleDate , 'YYYY-MM-DD'),
                                    taxType : 'no_tax',
                                    remark : this.state.remark,
                                    chartOfAccountId : this.state.asset.chartOfAccountType.id,
                                    invoiceType: 'other' ,
                                    status:'paid',
                                    approver : this.state.approver,
                                    updater : '',
                                    total: parseFloat(this.state.totalPay),
                                    historyShow : true
                                }
                        
                                
                                let transaction_list = [
                                    {
                                        description : this.state.description,
                                        discount : 0,
                                        id: "",
                                        preTaxAmount : parseFloat(this.state.totalPay),
                                        price : parseFloat(this.state.totalPay),
                                        productAndService : {
                                            chartOfAccount : {
                                                id : this.state.asset.chartOfAccountType.id,
                                                chartOfAccountCode : this.state.asset.chartOfAccountType.chartOfAccountCode ,
                                                name : this.state.asset.chartOfAccountType.name,
                                            },
                                            id : this.state.asset.chartOfAccountType.id,
                                            productCode : this.state.asset.chartOfAccountType.chartOfAccountCode ,
                                            name : this.state.asset.chartOfAccountType.name,
                                            totalLeftInStock : null,
                                            type : 'chart_of_account',
                                            price : ''
                                        },
                                        totalLeftInStock  : null,
                                        type : 'chart_of_account',
                                        unitItems : 1,
                                        vat : null,
                                        whtRate : 0,
                                        whtRatePercent : 0,
                                        price_type: ''
                                    }
                                ]

                                if (this.state.payChannel.length > 0) {
                                    for (const index in this.state.payChannel) {
                                      if (this.state.payChannel[`${index}`].cheque && this.state.payChannel[`${index}`].cheque.chequeImage) {
                            
                                        var new_file = new File([this.state.payChannel[`${index}`].cheque.chequeImage], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${this.state.payChannel[`${index}`].cheque.chequeImage.name}`);
                                        let data = await upload(`/other_receive`, new_file);
                            
                                        this.setState((prevState) => {
                                          return _.set(_.cloneDeep(prevState), `payChannel[${index}].cheque.chequeImage`, data.key.replace("silverman-storage/private/", ""));
                                        });
                                      }
                                    }
                                }
                                let input = {
                                      contactList: [this.state.nameContact],
                                      invoice: JSON.stringify(invoice),
                                      transactionList: JSON.stringify(transaction_list),
                                      payChannel: JSON.stringify(this.state.payChannel),
                                      disappearAssetId : this.state.disappearAssetId,
                                      assetId : this.props.match.params.id,
                                };


                                const uploadables = this.state.upload;

                                CreateOtherReceive(input,uploadables, this.onSuccessOR, this.onError)
                            }else{
                                Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                    this.setState({redirect: true});
                                });
                            }
                            
                        } else {
                            this.setState({loading: false});
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({loading: false});
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                    },
                },
            )
        } else {
            this.setState({loading: false});
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');

        }
    }

    onSuccessOR = (response) => {
        this.setState({loading: false});
        if (response.createOtherReceiveAndOtherReceiveTransaction.ok) {
            Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                this.setState({redirect: true});
            });

        } else {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');

        }
    }

    onError = () => {
        this.setState({loading: false});
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
    }

    handleInputFile = (e) => {
        let file = e.currentTarget.files[0];
        let defaultFile = _.cloneDeep(this.state.disappearDocument)
        if (file) {
            let totalBytes = file.size;
            // check gt 5 mb 5000000 from 10 mb RQ147
            if (totalBytes >  10485760) {
                Swal.fire('ขนาดไฟล์ใหญ่เกินไป!', 'กรุณาเลือกไฟล์ใหม่', 'warning');
                return
            } else {
                defaultFile.push(
                    { node : {
                        disappearDocumentId:"", 
                        fileUpload:file,
                        status:"",
                        fileName: file.name
                    }})
                this.setState({
                    disappearDocument : defaultFile
                })
            }
        }
    }

    deleteFile = (id) => {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(this.state.disappearDocument)
                let index_list = _.findIndex(_.cloneDeep(getData),{node : {disappearDocumentId : id}})
                if(index_list === -1){
                    getData.splice(id ,1)
                    this.setState({
                        disappearDocument : getData
                    })
                }
                else{
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            'disappearDocument'+'['+index_list+'].node.status',
                            'delete'
                        );
                    });
                }
            }
        })
    }
    

    updatePayChannel = (channel_list) => {
        let totalPay = 0;
        let upload = {};
        channel_list.forEach((channel, index) => {
          if (
            channel.slug === "small-change" ||
            channel.slug === "receipt-deposit" ||
            channel.slug === "other-expense"
          ) {
            if (
              this.state.total - this.state.total_discount - this.state.totalPay >
              0
            )
              totalPay += parseFloat(channel.pay);
            else totalPay -= parseFloat(channel.pay);
          } else {
            totalPay += parseFloat(channel.pay);
          }
          if (channel.image) {
            upload[index] = channel.image;
          }
        });
        this.setState({
          payChannel: channel_list,
          totalPay: totalPay,
          upload: upload,
        });
      }

      



    render() {
        if(this.state.redirect){
            return <Redirect to={"/accounting/asset/all/form/"+this.state.asset.docNumber+"/"+this.props.match.params.id}/>;
        }
        return (
           <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className='container-fluid box' id="asset-disappear">
                        <div className="row justify-content-between d-flex align-items-center">
                            <div className="col-12">
                                <Translation>
                                    {
                                        t => 
                                        <h4 className="mb-4 form-inline">
                                            <Link to={"/accounting/asset/all/form/"+this.state.asset.docNumber+"/"+this.props.match.params.id}>
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back-new.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <div className="mt-1">สูญหาย</div>
                                        </h4>
                                    }
                                </Translation>
                            </div>
                        </div>
                        <Translation>
                            { t=> 
                                <div className='content-inner pr-4'>
                                    {this.state.loadingData ?
                                    <Loading/>
                                    :
                                    <React.Fragment>
                                        <form id="asset" onSubmit={this.onSubmit}>
                                            <div className='row col-12 form-inline mt-24 d-flex align-items-center'>
                                                <h4 className='mr-3'>การคิดต้นทุนสินทรัพย์</h4>
                                                <input type='checkbox' className='form-control mr-3' checked={this.state.insurace}  disabled={this.props.match.params.status === 'view'} onChange={this.toggle} name='insurace' />
                                                <span>มีประกันการสูญหาย</span>
                                            </div>
                                            <hr />
                                            <div className='row mt-24'>
                                                <div className='col-4'>
                                                    <h6>ตัดเป็นค่าใช้จ่ายทั้งหมด ณ วันที่</h6>
                                                    <DatePickerNoti 
                                                        minDate={new Date(setDate(new Date,1))} 
                                                        className='form-control form-input-asset'
                                                        selected={this.state.asset.saleDate}
                                                        name='asset.saleDate'
                                                        onChange={this.handleChange}
                                                        width={20}
                                                        height={20}
                                                        disabled={this.props.match.params.status === 'view'}
                                                    />
                                                </div>
                                                <div className='col-2'>
                                                    {this.props.match.params.status === 'view' &&  this.state.otherReceive &&
                                                        <React.Fragment>
                                                            <h6>เลขที่เอกสารอ้างอิง</h6>
                                                            <input type='text' value={this.state.otherReceive.node.otherReceive.docNumber}
                                                                readOnly={true}
                                                                className='form-control form-input form-input-asset'
                                                                disabled={this.props.match.params.status === 'view'}
                                                            />
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                            <div className='row mt-24'>
                                                <div className='col-4'>
                                                    <h6>บัญชี</h6>
                                                    <input type='text' 
                                                        readOnly={true} value={this.state.chartOfAccount} 
                                                        disabled={this.props.match.params.status === 'view'}
                                                        className='form-control form-input' />
                                                </div>
                                            </div>

                                            <h5 className='mt-24'>แนบหลักฐานการสูญหาย</h5>
                                            {this.props.match.params.status === 'create' &&
                                            <div className='row col-12 mt-24 form-inline'>
                                                <div className='attachFile form-inline' >
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/attachIcon.png'}
                                                        alt="attach"/>
                                                    <label htmlFor="CustomFile" className='cursor'>แนบไฟล์</label>
                                                    <input type="file" 
                                                        id="CustomFile" 
                                                        accept="application/pdf, image/*" value=""
                                                        hidden={true}
                                                        onChange={(e) => this.handleInputFile(e)}
                                                    />
                                                </div>
                                                <span className='attach-warn ml-3'>แนบไฟล์ .jpg หรือ .pdf ขนาดไม่เกิน 2 MB</span>
                                            </div>}
                                                <div className='mt-24'>
                                                    {this.state.disappearDocument.map((file,index) => {
                                                        if(file.node.disappearDocumentId !== ""){
                                                            return(
                                                                <div className="col-12 mt-3 form-inline attach-file" key={index}>
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/pdf-icon.png'} alt="pdf" className="mr-3" style={{width:'24px',height:'24px'}}/>
                                                                    <a download="Download"
                                                                        href={file.node.fileUpload}>
                                                                        <span className="cursor mr-2 file-text">{file.node.fileName}</span>
                                                                    </a>
                                                                    {/* <img style={{width:'12.5px',height:'12px'}}
                                                                        src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="cancel" className="close-icon cursor" 
                                                                        onClick={() => this.deleteFile(file.node.disappearDocumentId === "" ? index : file.node.disappearDocumentId)}
                                                                    /> */}
                                                                    
                                                                </div>
                                                            );
                                                        }else{
                                                            return(
                                                                <div className="col-12 mt-3 form-inline attach-file" key={index}>
                                                                    <img style={{width:'24px',height:'24px'}}
                                                                        src={process.env.PUBLIC_URL + '/images/icons/pdf-icon.png'} alt="pdf" className="mr-3"/>
                                                                    <span className="mr-2 file-text">{file.node.fileName}</span>
                                                                    
                                                                        <img style={{width:'12.5px',height:'12px'}}
                                                                            src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="cancel" className="close-icon cursor" 
                                                                            onClick={() => this.deleteFile(file.node.disappearDocumentId === "" ? index : file.node.disappearDocumentId)}
                                                                        />
                                                                    
                                                                </div>
                                                            );
                                                        }
                                                    })
                                                    }
                                                </div>

                                            {this.state.insurace &&
                                                <React.Fragment>
                                                    <h6 className='mt-48'>มูลค่าเงินประกัน (บาท)</h6>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <input type='text' className='form-control form-input' 
                                                                name='insuracePrice' 
                                                                onChange={this.handleChange}
                                                                value={this.state.insuracePrice}
                                                                disabled={this.props.match.params.status === 'view'}
                                                            />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            <h6 className='mt-48'>ผู้อนุมัติการตัดจำหน่ายสินทรัพย์</h6>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input type='text' className='form-control form-input' 
                                                        name='approver' onChange={this.handleChange} 
                                                        value={this.state.approver}
                                                        disabled={this.props.match.params.status === 'view'}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.insurace &&
                                                <React.Fragment>
                                                    <h6 className='mt-24'>ได้รับเงินจาก</h6>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <input type='text' className='form-control form-input' 
                                                                name='nameContact' onChange={this.handleChange} 
                                                                value={this.state.nameContact}
                                                                disabled={this.props.match.params.status === 'view'}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* {this.state.asset.seller.id !== 'other_select' && 
                                                        <React.Fragment>
                                                            <h6 className='mt-24'>ที่อยู่</h6>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <textarea className='form-control' 
                                                                        name='adressContact' 
                                                                        onChange={this.handleChange} 
                                                                        value={this.state.adressContact}
                                                                        disabled={this.props.match.params.status === 'view'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    } */}
                                                    <h4 className='mt-48'>รายการ</h4>
                                                    <div className='row'>
                                                        <div className='table-responsive col-12'>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>รายการ</th>
                                                                        <th>บัญชี</th>
                                                                        <th>คำอธิบาย</th>
                                                                        <th>จำนวน</th>
                                                                        <th className='text-right'>ราคา/หน่วย (บาท)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            {this.state.asset.chartOfAccountType.name || ''}
                                                                        </td>
                                                                        <td>
                                                                            {this.state.asset.chartOfAccountType.chartOfAccountCode || ''}
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className='form-control' 
                                                                            value={this.state.description} name='description' onChange={this.handleChange}  
                                                                            disabled={this.props.match.params.status === 'view'} />
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            1
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className='form-control text-right' disabled={true} value={isNaN(this.state.totalPay) ? 0.00 : numberWithComma(this.state.totalPay)}/>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    
                
                                                    <h4 className='mt-48'>ช่องทางการรับชำระ</h4>
                                                    <hr />
                                                    {this.props.match.params.status === 'create' ? 
                                                        <PaymentChannel
                                                            updateReceiveChannel={this.updatePayChannel}
                                                            issued_date={new Date()}
                                                            receive_form={true}
                                                            pay={0}
                                                            total={0}
                                                            total_discount={0}
                                                            notReceiptDeposit={true}
                                                        />
                                                        :
                                                        this.state.otherReceive ? 
                                                        <PayChannel 
                                                            bankTransaction={
                                                                this.state.otherReceive.node.otherReceive.bankAccountTransaction.edges
                                                            }
                                                            cashTransaction={
                                                                this.state.otherReceive.node.otherReceive.cashTransaction.edges
                                                            }
                                                            chequeTransaction={
                                                                this.state.otherReceive.node.otherReceive.chequeDeposit.edges
                                                            }
                                                            creditCardTransaction={
                                                            this.state.otherReceive.node.otherReceive.creditCardTransaction.edges
                                                            }
                                                            unknownReceiveTransaction={this.state.otherReceive.node.otherReceive.unknownReceive.edges}
                                                        
                                                        />
                                                        :
                                                        <div />

                                                    }

                                                </React.Fragment>

                                            }
                                            <div className='row mt-48 cost-box pr-4'>
                                                {this.state.insurace &&
                                                    <React.Fragment>
                                                        <div className='col-4 mt-3 mb-3'>
                                                            <h5>มูลค่าที่ได้รับจากประกัน</h5>
                                                        </div>
                                                        <div className='col-8 float-right mt-3 mb-3'>
                                                            <h5 className='text-right float-right'>{numberWithComma(this.state.totalPay)}</h5>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                <div className='col-4 mt-3 mb-3'>
                                                    <h5>มูลค่าทางบัญชี</h5>
                                                </div>
                                                <div className='col-8 float-right mt-3 mb-3'>
                                                    <h5 className='text-right float-right'>{this.state.asset_amount >= this.state.asset.carcassPrice ? numberWithComma(this.state.asset_amount) : this.state.asset.carcassPrice}</h5>
                                                </div>
                                            </div>
                                        </form>

                                        {this.props.match.params.status === 'create' &&
                                        <div className='row col-12 mt-48 mb-48 d-flex justify-content-end'>
                                            <button type='button' onClick={() => this.onSubmit()} className='btn-confirm cursor'>
                                                {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                <span>บันทึก</span>
                                            </button>
                                        </div >
                                        }
                                       
                                    </React.Fragment>
                                    }
                                </div>
                            }
                        </Translation>
                    </div>
                </WrapperContent>

           </Wrapper>
        )
    }
}

export default AssetDisappear;