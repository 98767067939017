import React, {Component} from 'react';
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {Redirect} from 'react-router';
import SearchSelect from "../../../libs/searchSelect";
import environment from "../../../env/environment";
import {Link} from "react-router-dom";
import {graphql} from "babel-plugin-relay/macro";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import numberWithComma from "../../../libs/numberWithComma";
import {fetchQuery} from "relay-runtime";
import {commitMutation} from "react-relay";
import { Translation } from 'react-i18next';
import i18n from 'i18next';

const query = graphql`
    query creditCardDepositCreateFormQuery{
        bankAccountViewer{
            allBankAccount(status: true){
                edges{
                    node{
                        id
                        accountNumber
                        accountName
                        bankName

                    }
                }
            }
        }
        selfCreditCardSetting{
            id
            bankAccount{
                id
                accountName
                accountNumber
                accountType
                bankName
            }
            fee
        }
    }
`;

const mutation = graphql`
    mutation creditCardDepositCreateFormMutation($input : CreditCardDepositGroupCreateInput!){
        creditCardDepositGroupCreate(input: $input){
            clientMutationId
            ok
            warningText
        }
    }
`;

const _ = require('lodash');


class CreditCardDepositCreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transaction_list: this.props.select_transaction,
            total_all: 0,
            bank_select: '',
            image_upload: '',
            depositDate: new Date(),
            depositTotal: '',
            feeTotal: '',
            bank_list: [],
            fee: 0.0,
            description: '',

            loading: false,
            redirectToList: false,

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentWillMount() {
        let total_all = 0.0;
        await this.state.transaction_list.forEach((credit)=>{
            total_all = total_all + credit.amount
        });

        await fetchQuery(environment, query, {}).then((data) => {
            if (_.isObject(data.selfCreditCardSetting)) {
                this.setState({
                    bank_select: data.selfCreditCardSetting.bankAccount.id,
                    fee: data.selfCreditCardSetting.fee
                })
            }
            if (_.isObject(data.bankAccountViewer)) {
                this.setState({bank_list:data.bankAccountViewer.allBankAccount.edges})
            }
        });

        let feeTotal = parseFloat(total_all) * parseFloat(this.state.fee) / 100;
        let depositTotal = parseFloat(total_all) - feeTotal;
        this.setState({total_all: total_all, feeTotal: feeTotal.toFixed(2), depositTotal: depositTotal.toFixed(2)},);
    }

    handleInputChange(e) {
        if (e.target.name === 'image_upload') {
            if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
                this.setState({image_upload: e.currentTarget.files[0]});
            }
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    onSubmit(e) {
        e.preventDefault();
        if (parseFloat((parseFloat(this.state.depositTotal) + parseFloat(this.state.feeTotal)).toFixed(2)) === parseFloat(this.state.total_all)) {
            Swal.fire({
                title: i18n.t("cash_deposit:Are you sure to add a deposit item?"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: i18n.t("cash_deposit:Yes"),
                cancelButtonText: i18n.t("cash_deposit:Cancel")
            }).then((result) => {
                if (result.value) {
                    let variables = {
                        input: {
                            creditCardDepositGroup:{
                                "description": this.state.description,
                                "depositDateTime": new Date(`${this.state.depositDate.toDateString()} 00:00:00 +07:00`),
                                "bankAccountId": this.state.bank_select,
                                "depositTotal": this.state.depositTotal,
                                "feeTotal": this.state.feeTotal,
                                "transactions": JSON.stringify(this.state.transaction_list),
                            }
                        }
                    };

                    const uploadables = {image_deposit: this.state.image_upload};
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            uploadables,
                            onCompleted: (response) => {
                                this.setState({loading: false});
                                if (response.creditCardDepositGroupCreate.ok) {
                                    Swal.fire(i18n.t("cash_deposit:Save successfully!"), '', 'success').then(() => {
                                        this.setState({redirectToList: true});
                                    });
                                } else {
                                    Swal.fire(i18n.t("cash_deposit:Save unsuccessfully"), response.creditCardDepositGroupCreate.warningText, 'warning');
                                }

                            },
                            onError: (err) => {
                                this.setState({loading: false});
                                Swal.fire('Error!', i18n.t("cash_deposit:Please try again"), 'warning')
                            },
                        },
                    );

                }
            });

        } else {
            Swal.fire(i18n.t("cash_deposit:Wrong amount"), i18n.t("cash_deposit:Please check the amount again"), 'warning');
        }


    }

    getFieldTransaction(transaction, field_name) {
        if (transaction.receive) {
            return transaction.receive[field_name]
        } else if (transaction.otherReceive) {
            return transaction.otherReceive[field_name]
        } else if (transaction.receiptDeposit) {
             return transaction.receiptDeposit[field_name]
        }
    }

    render() {
        if(this.state.redirectToList) {
            return (
                <Redirect to="/accounting/finance/credit-card/list"/>
            );
        }

        if (this.props.select_transaction.length > 0 && !this.state.success) {
            return (
                <React.Fragment>
                    <div className="table-responsive fade-up">
                        <table className="table table-hover mt-2">
                            <thead className="thead-light">
                            <Translation>
                                {t=>
                                <tr>
                                    <th>{t("cash_deposit:No.")}</th>
                                    <th>{t("cash_deposit:Date")}</th>
                                    <th>{t("cash_deposit:Description")}</th>
                                    <th className="text-right">{t("cash_deposit:Amount")}</th>
                                </tr>}
                            </Translation>
                            </thead>
                            <tbody>
                            {this.state.transaction_list.map((transaction) => {
                                return (
                                    <tr key={transaction.id}>
                                        <td>
                                            {this.getFieldTransaction(transaction, 'docNumber')}
                                        </td>
                                        <td>
                                            {format(this.getFieldTransaction(transaction, 'issuedDate'), 'DD/MM/YYYY', {locale: thLocale})}
                                        </td>
                                        <td>{transaction.description}</td>
                                        <Translation>{t=><td className="text-right">{numberWithComma(transaction.amount)}  {t("cash_deposit:Baht")}</td>}</Translation>
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </table>
                    </div>

                    <div className="row mt-3 fade-up">
                        <div className="col-9"/>
                        <div className="col-3">
                            <div className="col">
                                <div className="row p-3 mb-2 bg-light text-dark">
                                    <Translation>{t=><div className="col text-left"><strong>{t("cash_deposit:All amount")}</strong></div>}</Translation>
                                    <div
                                        className="col text-right"><Translation>{t=><strong>{numberWithComma(this.state.total_all)} {t("cash_deposit:Baht")}</strong>}</Translation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-3 fade-up">
                        <form id="create-credit-deposit" onSubmit={this.onSubmit}>
                            <div className="card-body">
                                 <div className="row">
                                    <Translation>{t=><label className="col-2 col-form-label">{t("cash_deposit:Description")}</label>}</Translation>
                                    <div className="col-5">
                                        <textarea className="form-control" rows="4"
                                               name="description" maxLength={256}
                                               onChange={this.handleInputChange}
                                               value={this.state.description}
                                                  required={true}
                                        />

                                    </div>
                                </div>
                                <div className="row mt-3">
                                        <Translation>{t=><label className="col-2 col-form-label">{t("cash_deposit:Deposit date")}</label>}</Translation>
                                        <div className="col">
                                            <DatePickerAdapter
                                                name="depositDate" className="form-control"
                                                selected={this.state.depositDate} /*maxDate={this.state.dueDate}*/
                                                onChange={this.handleInputChange}
                                                required={true}
                                            />

                                        </div>
                                </div>


                                <div className="row mt-3">
                                    <Translation>{t=><label className="col-2 col-form-label">{t("cash_deposit:Deposit channel")}</label>}</Translation>
                                    <div className="col-3">
                                        <SearchSelect onChange={this.handleInputChange}
                                                      value={this.state.bank_select}
                                                      name="bank_select" require={true}
                                                      placeholder="กรุณาทำการตั้งค่าบัตรเครดิต"
                                                      queryObject={this.state.bank_list}
                                                      keyOfValue="id"
                                                      keyOfLabel="bankName:accountName:accountNumber"
                                                      disabled={true}
                                                      placeholder_default={true}
                                        />

                                    </div>
                                    <Translation>{t=><label className="col-2 col-form-label ml-3 text-center">{t("cash_deposit:Amount")}</label>}</Translation>
                                    <div className="col-2">
                                        <input type="number" className="form-control text-right"
                                               step="0.01"
                                               name="depositTotal"
                                               onChange={this.handleInputChange}
                                               value={this.state.depositTotal}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <Translation>{t=><label className="col-2 col-form-label">{t("cash_deposit:Bank fee")}</label>}</Translation>
                                    <div className="col-3">
                                        <input type="text" className="form-control text-center"
                                               name="depositTotal"
                                               value="ค่าธรรมเนียมธนาคาร"
                                               disabled={true}
                                        />
                                    </div>
                                    <Translation>{t=><label className="col-2 col-form-label ml-3 text-center">{t("cash_deposit:Amount")}</label>}</Translation>
                                    <div className="col-2">
                                        <input type="number" className="form-control text-right"
                                               step="0.01"
                                               name="feeTotal"
                                               onChange={this.handleInputChange}
                                               value={this.state.feeTotal}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col bottom">
                                        <Translation>
                                            {t=>
                                            <label
                                                className={this.state.image_upload.name ? "btn btn-success mb-0 cursor font-medium mr-3" : "btn btn-secondary mb-0 cursor font-medium mr-3"}>
                                                {this.state.image_upload.name ? t("cash_deposit:Done") : t("cash_deposit:Upload receipt")}
                                                <input type="file" name="image_upload"
                                                       onChange={this.handleInputChange}
                                                       hidden/>
                                            </label>}
                                        </Translation>
                                        {this.state.image_upload.name}
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="row mt-5">
                        <div className="col text-right">
                            <div className="btn-group mr-2">
                                <Link to="/accounting/finance/credit-card/list">
                                    <Translation>
                                        {t=>
                                        <button type="button" className="btn btn-secondary add">
                                            {t("cash_deposit:Cancel")}
                                        </button>}
                                    </Translation>
                                </Link>
                            </div>
                            <div className="btn-group mr-2">
                                <Translation>
                                    {t=>
                                    <button type="submit" className="btn btn-primary add" form="create-credit-deposit" disabled={this.state.loading}>
                                        {this.state.loading &&
                                        <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                        {t("cash_deposit:Create a depost")}
                                    </button>}
                                </Translation>
                            </div>
                        </div>
                    </div>

                </React.Fragment>

            )
        }
        return <Redirect to="/accounting/finance/credit-card/list"/>;
    }
}

export default CreditCardDepositCreateForm;
