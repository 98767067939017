import React, {Component, useEffect, useState,useCallback} from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Translation } from "react-i18next";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import Loading from '../../../../libs/loading';
import APILogin from '../../../../api/logHistory'
import { format } from "date-fns";
import i18next from 'i18next';
import jwtDecode from 'jwt-decode'


const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));


function Row(props) {
  const { row, secoundLogs, loading2, expandedRow, setExpandedRow, setLoading2 } = props;
  const isExpanded = expandedRow === row.id;

  const [open, setOpen] = React.useState(false);

  const toggleRow = () => {
    setExpandedRow(isExpanded ? null : row.id);
    if (!isExpanded) {
      setLoading2(true); // Set loading when expanding the row
    }
  };

  const closeRow = () => {
    setExpandedRow(null);
  };

  useEffect(() => {
    if (isExpanded && loading2) {
      // Simulate loading effect with a delay of 1 second
      const timer = setTimeout(() => {
        setLoading2(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isExpanded, loading2]);

  const handleClick = (id) => {
   setOpen(!open);    
    props.fetchData2(id)
  };


  return (
    <React.Fragment>
    <>
    <Translation>
        {(t) =>
        <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{fontFamily:'medium'}}>
          <TableCell align="center">{format(row.added, "YYYY-MM-DD") || "-"}</TableCell>
          <TableCell align="center">{row.working_type}</TableCell>
          <TableCell align="center">{row.material || "-"}</TableCell>
          <TableCell align="center">{row.residential?.home_number || "-"}</TableCell>
          <TableCell align="center">{row.tenant_contact?.first_name}{row.tenant_contact?.last_name}</TableCell>
          <TableCell align="center"> {t(`PageList:${row.status}`)}</TableCell>
          <TableCell align="center" />
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                isExpanded ? closeRow() : toggleRow();
                props.fetchData2(row.id);
              }}
            >
             {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0}} colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
              <Table size="small" className='slip-dark-gray'>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'20%' }}>{t("FixRequestReport:User")}</TableCell>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'5%' }}>{t("FixRequestReport:Type Work")}</TableCell>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'5%' }}>{t("FixRequestReport:Detail Log")}</TableCell>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'10%' }}>Log</TableCell>
                      <TableCell style={{ borderBottom: "none", paddingBottom: '0', paddingTop: '0',width:'15%'}}>{t("FixRequestReport:Time")}</TableCell>
                    </TableRow>
                  </TableHead>
                  { !loading2 ?
                  <TableBody>
                    {
                    secoundLogs?.length > 0 ? 
                      secoundLogs?.map((itemLogs, indexLogs) => {
                          return (
                            <React.Fragment key={`index_item_log ${indexLogs}`}>
                              <TableRow>
                                <TableCell style={{ paddingBottom: "16px"}}>{`${itemLogs.user.name} (${itemLogs.user.username}) `}</TableCell>
                                <TableCell style={{ paddingBottom: "16px"}}>{`${itemLogs.type}`}</TableCell>
                                <TableCell style={{ paddingBottom: "16px"}}>{`${itemLogs.note}`}</TableCell>
                                <TableCell style={{ paddingBottom: "16px"}}>{`${JSON.stringify(itemLogs.log)}`}</TableCell>
                                <TableCell style={{ paddingBottom: "16px"}}>{`${format(itemLogs.action_time, "YYYY-MM-DD")}`}</TableCell>
                              </TableRow>
                            </React.Fragment>
                          )
                        }) : 
                        <TableRow>
                            <TableCell style={{ paddingBottom: "16px" }}>{t("silvermanguard:notFound")}</TableCell>
                        </TableRow>
                    } 
                  </TableBody>
                    :  <Loading/>} 
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> 
           </>
           }
      </Translation>
    </>
    </React.Fragment>
  );
}



const RepairsRoomLogsTable = ({data,loading}) => {
  const [secoundLogs, setSecoundLogs] = useState([])
  const [countLog, setCountLog] = useState()
  const [loading2, setLoading2] = useState(true)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchData2 = async (id) => {
   await APILogin.getToken().then((res) => {
    let token = localStorage.getItem("token")
    let tokenDecode = jwtDecode(token)
        if (res?.data) {
          setLoading2(true)
          APILogin.getLogRepairRoom(res?.data?.access_token,id,tokenDecode?.site).then((res) => {
              if(res.data.logs){
                setSecoundLogs(res.data.logs)
                setLoading2(false)
              }
          }) 
        }
    })
  }


  return (
    <Translation>
    {(t) =>
    <TableContainer component={Paper}>
      { !loading ?
        <Table aria-label="collapsible table">
          <caption>{i18next.t("slipVerificationList:Number of Data Found")} {countLog}</caption>
          <TableHead>
            <TableRow>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',textAlign:'center'}}>{t("FixRequestReport:Issue date")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',textAlign:'center'}}>{t("FixRequestReport:Category")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',textAlign:'center'}}>{t("FixRequestReport:Work type")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',textAlign:'center'}}>{t("FixRequestReport:Room No.")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',textAlign:'center'}}>{t("FixRequestReport:From")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef',textAlign:'center'}}>{t("FixRequestReport:Status")}</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                 return ( 
                 <Row key={index} 
                   row={row}
                   fetchData2={fetchData2} 
                   loading2={loading2} 
                   secoundLogs={secoundLogs} 
                   expandedRow={expandedRow}
                   setExpandedRow={setExpandedRow}
                   setLoading2={setLoading2}/>
                  )
             }
            )}
          </TableBody>
        </Table>
        :<Loading/>
      } 
      <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
    }
    </Translation>
  );
}
export default RepairsRoomLogsTable;
