/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateDebitManualInput = {|
  createDebitManual: any,
  clientMutationId?: ?string,
|};
export type collectionLettersCreateMutationVariables = {|
  input: CreateDebitManualInput
|};
export type collectionLettersCreateMutationResponse = {|
  +createDebitManual: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type collectionLettersCreateMutation = {|
  variables: collectionLettersCreateMutationVariables,
  response: collectionLettersCreateMutationResponse,
|};
*/


/*
mutation collectionLettersCreateMutation(
  $input: CreateDebitManualInput!
) {
  createDebitManual(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDebitManualPayload",
    "kind": "LinkedField",
    "name": "createDebitManual",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLettersCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionLettersCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "76a9251c4526b8ad3290736c17cdaefd",
    "id": null,
    "metadata": {},
    "name": "collectionLettersCreateMutation",
    "operationKind": "mutation",
    "text": "mutation collectionLettersCreateMutation(\n  $input: CreateDebitManualInput!\n) {\n  createDebitManual(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '474bc6055d1a7227e076bf43c5fa7d7c';

module.exports = node;
