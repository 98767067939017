import React, { Component } from "react";
import "./styles/commonArea.scss";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { bookingService } from "./services";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import _ from "lodash";
import moment from "moment";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

class BookingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      transaction: [],
      num_people: 0,
      query: "",
      text: "",
      doc_number: "",
      status: "",
      name: "",
      start_time: "",
      time: "",
      remark: "",
      booking_name: "",
      residential_right_name: "",
      tel: "",
      total: "",
      void_remark: "",
      type_booker: "",
      end_time: "",
      booking_date: "",
      booking_month: "",
      booking_year: "",
      use_date: "",
      void_remark_text: '',

      use_right: '',
    };
  }

  async calcel() {
    const state = this.props.match.params.id;
    let token = localStorage.getItem("token");

    await bookingService
      .niti_cancel_booking(token, state, this.state.void_remark)
      .then((res) => {
        const data = res.data.message;
        if (data === "void successful") {
          this.props.history.push("/contact/global-area-singha/all");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onSubmit = (evt) => {
    evt.preventDefault();
    if (this.state.text === "") {
      alert("Please enter something!");
    } else {
      alert(this.state.text);
      this.setState({
        text: ""
      });
    }
  };

  onChange = (evt) => this.setState({ text: evt.target.value });

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    const state = this.props.match.params.id;
    let token = localStorage.getItem("token");

    await bookingService
      .one_niti_booking(token, state)
      .then((res) => {
        const data = res.data;
        const all_booking_bill = data.all_booking_bill;
        const booking_detail = data.booking_detail;
        let doc_number;
        let transaction;
        let total;
        all_booking_bill.map((item) => {
          doc_number = item.doc_number;
          transaction = item.booking_transaction;
          total = item.total;
        });

        this.setState({
          doc_number: doc_number,
          status: _.get(booking_detail, "status"),
          void_remark_text: _.get(booking_detail, "void_remark"),
          name: _.get(data.facility_detail, "name"),
          start_time: moment(_.get(booking_detail, "start_time"))
            .utc()
            .format("DD/MM/YYYY"),
          time: moment(_.get(booking_detail, "start_time"))
            .utc()
            .format("HH:mm"),
          end_time: moment(_.get(booking_detail, "end_time"))
            .utc()
            .format("HH:mm"),
          num_people: _.get(booking_detail, "num_people"),
          remark: _.get(booking_detail, "remark"),
          booking_name: _.get(booking_detail, "booking_name"),
          residential_right_name: _.get(
            booking_detail,
            "residential_right_name"
          ),
          tel: _.get(booking_detail, "tel"),
          transaction: transaction,
          total: total,
          use_right: _.get(booking_detail, "use_right"),
          type_booker: _.get(booking_detail, "type_user"),
          use_date:
            moment(new Date(_.get(booking_detail, "use_date"))).format(
              "DD"
            ) +
            " " +
            monthNames[
            new Date(_.get(booking_detail, "use_date")).getMonth()
            ] +
            " " +
            moment(new Date(_.get(booking_detail, "use_date"))).add(543, 'year').format(
              "YYYY"
            ),
          booking_date: moment(new Date(_.get(booking_detail, "booking_date"))).format("DD"),
          booking_month: monthNames[new Date(_.get(booking_detail, "booking_date")).getMonth()],
          booking_year: moment(new Date(_.get(booking_detail, "booking_date"))).format("YYYY")
          // booking_year: moment(new Date(_.get(booking_detail, "booking_date"))).add(543, 'year').format("YYYY")
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onChangeVoidRemark(event) {
    this.setState({
      void_remark: event.target.value
    });
  }

  render() {
    const {
      doc_number,
      status,
      name,
      start_time,
      end_time,
      time,
      num_people,
      remark,
      booking_name,
      residential_right_name,
      tel,
      transaction,
      total,
      type_booker,
      booking_date,
      booking_month,
      booking_year,
      use_date,
      void_remark_text,
      use_right
    } = this.state;

    const { calendar } = this.props.location;

    return (

      <Wrapper>
        <Header />
        <Sidebar />
        <Translation>
          {t =>
            <div className="modal" tabindex="-1" role="dialog" id="exampleModal">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header header-border-unset">
                    <h5 className="modal-title">{t("newBookingSingha_Booking:Cancel this booking")}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row mt-2">
                      <label className="col-6 font-label-cancel">
                        {t("newBookingSingha_Booking:Reason to Cancled")}
                      </label>
                    </div>
                    <div className="align-items-center d-flex">
                      <label className="col-form-label mr-2 font-note">
                        {t("newBookingSingha_Booking:Note")}
                      </label>
                      <input
                        type="text"
                        name="text"
                        placeholder={t("newBookingSingha_Booking:Remark")}
                        value={this.void_remark}
                        onChange={(value) => this.onChangeVoidRemark(value)}
                        className="calendars-wrapper colorborder form-control float-left"
                      />
                    </div>
                  </div>
                  <div className="modal-footer box-button-modal">
                    <button
                      className="btn cancel-modal"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.calcel()}
                    >
                      {t("newBookingSingha_Booking:Comfirm")}
                    </button>
                    <button
                      className="btn close-modal"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      {t("newBookingSingha_Booking:Closed this window")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </Translation>
        <WrapperContent>
          <Translation>
            {t =>
              <React.Fragment>
                <div className="container-fluid box">
                  <div className="row justify-content-between">
                    <div className="col">
                      <h3>
                        {calendar ?
                          <Link to="/contact/global-area-singha/calendar/month">
                            <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"}
                              alt="back"
                            />
                          </Link> :
                          <Link to="/contact/global-area-singha/all">
                            <img src={
                              process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                            }
                              alt="back"

                            />
                          </Link>
                        }
                        {calendar !== true ? (
                          <span className="text-header-booking ml-3">{doc_number}</span>
                        ) : (
                          <span className="text-header-booking ml-3">{use_date}</span>
                        )}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="container-type">
                  {void_remark_text !== '' && <div className="mb-5" style={{ width: '100%', height: '', backgroundColor: '#FDE5E8', padding: 24, borderRadius: 4 }} >
                    <div className="text-header-booking" style={{ color: '#E14558', fontSize: 22 }} >{t("newBookingSingha_Booking:Booking deatil")}</div>
                    <div className="mt-4 text-subheader-booking" style={{ color: '#E14558', fontSize: 16 }} >{void_remark_text}</div>
                  </div>}
                  <div className="d-flex justify-content-between">
                    <h3 className="text-header-booking"> {t("newBookingSingha_Booking:Booking deatil")}</h3>
                    <h3 className="text-header-booking">
                      {t("newBookingSingha_Booking:Status")}:
                      {status === "void" ? (
                        <span className="tag-void">{t("newBookingSingha_Booking:Void")}</span>
                      ) : status === "confirmed" ? (
                        <span className="tag-confirmed">{t("newBookingSingha_Booking:Confirm")}</span>
                      ) : status === "unconfirmed" ? (
                        <span className="tag-unconfirmed"> {t("newBookingSingha_Booking:Not Approve")}</span>
                      ) : status === "used" ? (
                        <span className="tag-used"> {t("newBookingSingha_Booking:Used")}</span>
                      ) : status === "overdue" && (
                        <span className="tag-overdue "> {t("newBookingSingha_Booking:Over Due")}</span>
                      )}
                    </h3>
                  </div>
                  <div className="line" />
                  <div style={{ paddingRight: "286px" }}>
                    <div style={{ paddingRight: "70px" }}>
                      <h5 className="mt-4 text-subheader-booking">{t("newBookingSingha_Booking:Booking Date")}</h5>
                      <input
                        className="custom-text-input"
                        type="text"
                        disabled={true}
                        value={`${booking_date} ${t("materWater:"+booking_month)} ${booking_year}`}
                      ></input>
                    </div>
                    <h5 className="mt-4 text-subheader-booking">{t("newBookingSingha_Booking:Facility name")}</h5>
                    <input
                      className="custom-text-input"
                      type="text"
                      disabled={true}
                      value={name}
                    ></input>
                    <div className="row mr-0 ml-0 mt-4">
                      <div>
                        <h5 classNam="text-subheader-booking">{t("newBookingSingha_Booking:Used Date")}</h5>
                        <input
                          className="custom-text-input time-right"
                          type="text"
                          disabled={true}
                          value={start_time}
                        ></input>
                      </div>
                    </div>
                    <div className="row mr-0 ml-0 mt-4">
                      <div>
                        <h5 className="text-subheader-booking">
                          {t("newBookingSingha_Booking:Start Date")}
                        </h5>
                        <input
                          className="custom-text-input time-right"
                          type="text"
                          disabled={true}
                          value={time}
                        ></input>
                      </div>
                      <div className="mt-0 ml-3">
                        <h5 className="text-subheader-booking"> {t("newBookingSingha_Booking:End Date")}</h5>
                        <input
                          className="custom-text-input time-right"
                          type="text"
                          disabled={true}
                          value={end_time}
                        ></input>
                      </div>
                    </div>
                    <h5 className="mt-4 text-subheader-booking">{t("newBookingSingha_Booking:Number of participants")}</h5>
                    <input className="custom-text-input" type="text" disabled={true} value={num_people} />
                    <h5 className="mt-4 text-subheader-booking">{t("newBookingSingha_Booking:Note")}</h5>
                    <input style={{ height: "96px" }} className="custom-text-input" type="text" disabled={true} value={remark} />
                    <h3 className="mt-5 text-header-booking pt-5">{t("newBookingSingha_Booking:Guest Details")}</h3>
                    <div className="line" />
                    <h5 className="mt-4 text-subheader-booking">{t("newBookingSingha_Booking:Booking name")}</h5>
                    <input className="custom-text-input" type="text" disabled={true} value={booking_name} />
                    {type_booker === "tenant" &&
                      <>
                        <h5 className="mt-4 text-subheader-booking">{t("newBookingSingha_Booking:Room number")}</h5>
                        <input className="custom-text-input" type="text" disabled={true} value={residential_right_name} />
                      </>
                    }
                    <h5 className="mt-4 text-subheader-booking">{t("newBookingSingha_Booking:Contact number")}</h5>
                    <input className="custom-text-input" type="text" disabled={true} value={tel} />
                  </div>
                  {type_booker !== "juristic" && (
                    <>
                      <div className="line" />
                      <div className="cardblue" style={{ color: "#0243AE" }}>
                        <div className="d-flex justify-content-between mtSub">
                          <span className="text-header-booking">{t("newBookingSingha_Setting:Amount")}</span>
                          <h3 className="text-header-booking">{parseInt(use_right)} {t("newBookingSingha_Setting:Point")}</h3>
                        </div>
                      </div>
                    </>
                  )}
                  {status !== "void" && status !== "used" &&
                    <div className="row float-right pt">
                      <button data-toggle="modal" data-target="#exampleModal" type="submit" className="btn cancel mr-3 mt-5 text-center p-0">
                        {t("Allaction:cancel")}
                      </button>
                    </div>
                  }
                </div>
              </React.Fragment>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default BookingDetail;
