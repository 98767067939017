/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type selectGlobalBankQueryVariables = {||};
export type selectGlobalBankQueryResponse = {|
  +allGlobalBankAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +name: string
      |}
    |}>
  |}
|};
export type selectGlobalBankQuery = {|
  variables: selectGlobalBankQueryVariables,
  response: selectGlobalBankQueryResponse,
|};
*/


/*
query selectGlobalBankQuery {
  allGlobalBankAccount(status: "active") {
    edges {
      node {
        name
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "active"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectGlobalBankQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "GlobalBankAccountNodeConnection",
        "kind": "LinkedField",
        "name": "allGlobalBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalBankAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GlobalBankAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allGlobalBankAccount(status:\"active\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "selectGlobalBankQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "GlobalBankAccountNodeConnection",
        "kind": "LinkedField",
        "name": "allGlobalBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalBankAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GlobalBankAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allGlobalBankAccount(status:\"active\")"
      }
    ]
  },
  "params": {
    "cacheID": "3965fa95e1d707e511b668af0308367c",
    "id": null,
    "metadata": {},
    "name": "selectGlobalBankQuery",
    "operationKind": "query",
    "text": "query selectGlobalBankQuery {\n  allGlobalBankAccount(status: \"active\") {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '079902ebee599b7505262ecfe192865f';

module.exports = node;
