/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountRecordGroupCategory = "GENERAL" | "HISTORICAL" | "PAYMENT" | "PURCHASE" | "RECEIVE" | "SALES" | "VOIDED" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type bankTransferQueryVariables = {|
  iddoc?: ?string
|};
export type bankTransferQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +nameEn: ?string,
    +address: ?string,
    +addressEn: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
  |},
  +allAccountRecordGroup: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +refNumber: ?string,
        +name: string,
        +added: any,
        +issuedDate: ?any,
        +category: AccountRecordGroupCategory,
        +totalDebit: number,
        +totalCredit: number,
        +refTransaction: ?string,
        +creator: ?string,
        +contact: ?{|
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
        +accountRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: string,
              +debit: number,
              +credit: number,
              +chartOfAccountCode: ?{|
                +id: string,
                +name: string,
                +chartOfAccountCode: string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +userConfig: ?{|
    +id: string,
    +languages: string,
  |},
|};
export type bankTransferQuery = {|
  variables: bankTransferQueryVariables,
  response: bankTransferQueryResponse,
|};
*/


/*
query bankTransferQuery(
  $iddoc: String
) {
  selfProject {
    id
    name
    nameEn
    address
    addressEn
    logo
    juristicContactNumber
    keyProjectQr
    taxIdNumber
    typeOfProject
  }
  allAccountRecordGroup(refTransaction: $iddoc) {
    edges {
      node {
        id
        refNumber
        name
        added
        issuedDate
        category
        totalDebit
        totalCredit
        refTransaction
        creator
        contact {
          id
          name
          firstName
          lastName
        }
        accountRecord {
          edges {
            node {
              id
              name
              debit
              credit
              chartOfAccountCode {
                id
                name
                chartOfAccountCode
              }
            }
          }
        }
      }
    }
  }
  userConfig {
    id
    languages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "iddoc"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameEn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addressEn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "juristicContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "keyProjectQr",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxIdNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfProject",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "refTransaction",
        "variableName": "iddoc"
      }
    ],
    "concreteType": "AccountRecordGroupNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountRecordGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountRecordGroupNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountRecordGroupNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refNumber",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalDebit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCredit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refTransaction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountRecordNodeConnection",
                "kind": "LinkedField",
                "name": "accountRecord",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountRecordNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountRecordNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "debit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "credit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ChartOfAccountNode",
                            "kind": "LinkedField",
                            "name": "chartOfAccountCode",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "chartOfAccountCode",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserConfigNode",
    "kind": "LinkedField",
    "name": "userConfig",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "languages",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransferQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankTransferQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e9a95c6bcca7fccfc68e5d63347cc348",
    "id": null,
    "metadata": {},
    "name": "bankTransferQuery",
    "operationKind": "query",
    "text": "query bankTransferQuery(\n  $iddoc: String\n) {\n  selfProject {\n    id\n    name\n    nameEn\n    address\n    addressEn\n    logo\n    juristicContactNumber\n    keyProjectQr\n    taxIdNumber\n    typeOfProject\n  }\n  allAccountRecordGroup(refTransaction: $iddoc) {\n    edges {\n      node {\n        id\n        refNumber\n        name\n        added\n        issuedDate\n        category\n        totalDebit\n        totalCredit\n        refTransaction\n        creator\n        contact {\n          id\n          name\n          firstName\n          lastName\n        }\n        accountRecord {\n          edges {\n            node {\n              id\n              name\n              debit\n              credit\n              chartOfAccountCode {\n                id\n                name\n                chartOfAccountCode\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  userConfig {\n    id\n    languages\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b51d63478f48bb930030dbf6178cb983';

module.exports = node;
