import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import SearchSelect from "../../../libs/searchSelect";
import SelectContactDropDown from "../../income/selectContactDropDown";
import Swal from "sweetalert2";
import {commitMutation} from "react-relay";
import {Redirect} from 'react-router'
import i18next from 'i18next'

import LightBox from "../../../libs/lightBox";

const _ = require('lodash');

const query = graphql`
    query createChequeDepositQuery($makePayment: Boolean, $receivePayment: Boolean, $accountType: String) {
        bankAccountViewer {
            allBankAccount(status: true, hasCheque: true, accountType_In:$accountType, makePayment:$makePayment, receivePayment:$receivePayment) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        bankName
                        branch
                    }
                }
            }
        }
        allGlobalBankAccount(status: "active"){
            edges{
                node{
                    id
                    name
                    logo
                    status
                }
            }
        }
    }
`;

const mutation = graphql`
 mutation createChequeDepositMutation($input: CreateChequeDepositInput!){
     createChequeDeposit(input: $input){
        ok
        warningText
        }
    }
`;

const cheque_detail = {
    id: '',
    chequeNumber: '',
    refNumber: '',
    contact: {id: ''},
    bankName: '',
    branchNumber: '',
    date: new Date(),
    depositDate: new Date(),
    price: '',
    //accountNumber: '',
    accountNumber: 'NONE',
    accountNumberId: 'NONE',
    unknown_contact: '',
    note: '',
    image_upload_display: ''
};


class CreateChequeDeposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cheque_deposit: cheque_detail,
            bank_list: [],
            loading: false,
            redirectToList: false,
            allGlobalBankAccount: [],
            other : '' ,
            upload: {},
            image_upload: '',
            signreq:Math.random().toString(36),
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.docInputChange = this.docInputChange.bind(this);
    }

    componentWillMount() {
        fetchQuery(environment, query, {
            makePayment: null,
            receivePayment: true,
            accountType: "current_account"
        }).then(response_data => {
            let allGlobalBank = response_data.allGlobalBankAccount.edges.map(value => {
                return {node : {id : value.node.id , name : value.node.name , status : value.node.status }}
            })
            allGlobalBank.push({node : {id : 'other' , name : 'อื่นๆ' , status : 'NONE'}})
            this.setState(
                {
                    bank_list: response_data.bankAccountViewer.allBankAccount.edges,
                    allGlobalBankAccount : allGlobalBank
                })
        });
        
    }

    docInputChange(e) {
        let event = _.cloneDeep(e)
        let upload = {};
        upload[0] = event.currentTarget.files[0]
        this.setState({
            upload : upload,
            image_upload_display: URL.createObjectURL(event.currentTarget.files[0])
        }) 
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e)
        
        if (event.target.name === 'bank_id') {
            this.state.allGlobalBankAccount.forEach((bank) => {
                if (bank.node.id === event.target.value) {
                    let name = bank.node.name;
                    if (bank.node.id === "other"){
                        this.setState(prevState => {
                            return _.set(
                                _.cloneDeep(prevState),
                                "cheque_deposit.bankName",
                                event.target.value
                            )
                        })
                        this.setState({
                            other : 'อื่นๆ'
                        })
                    }else {
                        this.setState({other : ''}) 
                    }
                    this.setState (prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            "cheque_deposit.bankName",
                            name
                        )
                    })
                }
            })
        }
        //fix B34 close function auto suggest
        // if (event.target.name === 'cheque_deposit.accountNumberId') {
        //     // auto suggest bank name and branch bank
        //     this.state.bank_list.forEach((bank) => {
        //         if (bank.node.id === event.target.value) {
        //             this.setState(prevState => {
        //                 return _.set(
        //                     _.cloneDeep(prevState),
        //                     "cheque_deposit.bankName",
        //                     bank.node.bankName
        //                 );
        //             });
        //             this.setState(prevState => {
        //                 return _.set(
        //                     _.cloneDeep(prevState),
        //                     "cheque_deposit.branchNumber",
        //                     bank.node.branch
        //                 );
        //             });
        //             this.setState(prevState => {
        //                 return _.set(
        //                     _.cloneDeep(prevState),
        //                     "cheque_deposit.accountNumber",
        //                     bank.node.accountNumber
        //                 );
        //             });
        //         }

        //     })
        // }
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        })
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let variables = {
            input: {
                chequeDeposit: JSON.stringify(this.state.cheque_deposit),
                clientMutationId:"CD"+this.state.signreqs,
            }
        };

        const uploadables = this.state.upload;

        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    if (response.createChequeDeposit.ok) {
                        Swal.fire(i18next.t("cheque:Success!"),'','success').then((result) => {
                            this.setState({redirectToList: true, loading: false});
                        })
                    }else{
                        Swal.fire(i18next.t("cheque:Failed"),response.createChequeDeposit.warningText,'warning').then((result) => {
                            this.setState({loading: false});
                        })
                    }
                },
                onError: (err) => {
                    Swal.fire('server error', '', 'error').then((result) => {
                        this.setState({loading: false});
                    })
                },
            },
        )
    }

    render() {
        if (this.state.redirectToList){
            return <Redirect to="/accounting/finance/cheque-deposit/list/not_deposit"/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <Translation>
                        {t=>
                        <form id="form-deposit" onSubmit={this.onSubmit}>
                            <div className="container-fluid box">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/accounting/finance/cheque-deposit/list/all">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                     alt="back" className="back"/>
                                            </Link>
                                            <span>{t('cheque:cheque')} </span>
                                            <span style={{fontSize : 16}} className='text-red'> **{t("accountWarning:finance_cheque_deposit")}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-2">
                                                    <label htmlFor="cheque_deposit.date"
                                                           className="col-form-label">
                                                        {t('cheque:Date')}
                                                    </label>
                                                </div>
                                                <div className="col">
                                                    <DatePickerAdapter id="cheque_deposit.date"
                                                                       className="form-control" name="cheque_deposit.date"
                                                                       selected={this.state.cheque_deposit.date}
                                                                       onChange={this.handleInputChange}
                                                                       disable={false}
                                                                       require={true}/>
                                                </div>
                                                <div className="col-1"/>
                                            </div>
    
                                            <div className="row mb-3">
                                                <div className="col-2">
                                                    <label htmlFor="cheque_deposit.chequeNumber"
                                                           className="col-form-label">
                                                        {t('cheque:Cheque No.')}
                                                    </label>
                                                </div>
                                                <div className="col-5">
                                                    <label htmlFor="cheque_deposit.chequeNumber"
                                                           className="col-form-label">
                                                        <input type="text"
                                                               name={"cheque_deposit.chequeNumber"}
                                                               className="form-control"
                                                               value={this.state.cheque_deposit.chequeNumber}
                                                               onChange={this.handleInputChange}
                                                               maxLength={8}
                                                               minLength={8}
                                                               pattern="[0-9]*"
                                                               required/>
                                                    </label>
                                                </div>
                                                <div className="col-1"/>
                                            </div>
    
                                            {/* <div className="row mb-3">
                                                <div className="col-1">
                                                    <label htmlFor="cheque_deposit.accountNumberId"
                                                           className="col-form-label">
                                                        รหัสธนาคาร
                                                    </label>
                                                </div>
                                                <div className="col-3">
                                                    <SearchSelect onChange={this.handleInputChange}
                                                                  value={this.state.cheque_deposit.accountNumberId}
                                                                  name="cheque_deposit.accountNumberId"
                                                                  placeholder="กรุณาเลือกธนาคาร"
                                                                  queryObject={this.state.bank_list}
                                                                  keyOfValue="id"
                                                                  require={true}
                                                                  disabled={false}
                                                                  keyOfLabel="docNumber"/>
                                                </div>
                                                <div className="col-1"/>
                                            </div> */}
                                            <div className ="row  mb-3">
                                                    <div className="col-2">
                                                        <label htmlFor="cheque_deposit.accountNumberId"
                                                               className="col-form-label">
                                                            {t('cheque:Bank')}
                                                        </label>
                                                    </div>
                                                    <div className="col-3">
                                                    <SearchSelect onChange={this.handleInputChange}
                                                                  value={this.state.bank_id}
                                                                  name="bank_id"
                                                                  placeholder="กรุณาเลือกธนาคาร"
                                                                  queryObject={_.sortBy(this.state.allGlobalBankAccount,'node.name' )}
                                                                  keyOfValue="id" require={true}
                                                                  keyOfLabel="name"/>
                                                    {this.state.other === "อื่นๆ" &&
                                                        <input type="text" className="form-control width-bank-name mt-3"
                                                               id="cheque_deposit.bankName"
                                                               placeholder="กรุณากรอกชื่อธนาคาร"
                                                               onChange={this.handleInputChange} name={"cheque_deposit.bankName"}
                                                               
                                                               required/>
                                                    }
                                                    </div>
                                                    <div className="col-1"/>
                                                </div>
    
                                            {/* <div className="row mb-3">
                                                <div className="col-1">
                                                    <label htmlFor="cheque_deposit.bankName"
                                                           className="col-form-label">
                                                        ธนาคาร
                                                    </label>
                                                </div>
                                                <div className="col-5">
                                                    <label htmlFor="cheque_deposit.bankName"
                                                           className="col-form-label">
                                                        <input type="text"
                                                           name={"cheque_deposit.bankName"}
                                                           className="form-control"
                                                           id="cheque_deposit.bankName" value={this.state.cheque_deposit.bankName}
                                                           onChange={this.handleInputChange} 
                                                           required/>
                                                    </label>
                                                </div>
                                                <div className="col-1"/>
                                            </div> */}
    
                                            {/* <div className="row mb-3">
                                                <div className="col-1">
                                                    <label htmlFor="cheque_deposit.accountNumber"
                                                           className="col-form-label">
                                                        เลขบัญชี
                                                    </label>
                                                </div>
                                                <div className="col-5">
                                                    <label htmlFor="cheque_deposit.accountNumber"
                                                           className="col-form-label">
                                                        <input type="text"
                                                           name={"cheque_deposit.accountNumber"}
                                                           className="form-control"
                                                           id="cheque_deposit.accountNumber" value={this.state.cheque_deposit.accountNumber}
                                                           onChange={this.handleInputChange} disabled={true}
                                                           required/>
                                                    </label>
                                                </div>
                                                <div className="col-1"/>
                                            </div> */}
    
                                            <div className="row mb-3">
                                                <div className="col-2">
                                                    <label htmlFor="cheque_deposit.branchNumber"
                                                           className="col-form-label">
                                                        {t('cheque:Branch')}
                                                    </label>
                                                </div>
                                                <div className="col-5">
                                                    <label htmlFor="cheque_deposit.branchNumber"
                                                           className="col-form-label">
                                                        <input type="text"
                                                           name={"cheque_deposit.branchNumber"}
                                                           className="form-control"
                                                           id="cheque_deposit.branchNumber"
                                                           onChange={this.handleInputChange} 
                                                           required/>
                                                    </label>
                                                </div>
                                                <div className="col-1"/>
                                            </div>
    
                                            <div className="row mb-3">
                                                <div className="col-2">
                                                    <label htmlFor="cheque_deposit.price"
                                                           className="col-form-label">
                                                        {t('cheque:Amount')}
                                                    </label>
                                                </div>
                                                <div className="col-5">
                                                    <label htmlFor="cheque_deposit.price"
                                                           className="col-form-label">
                                                        <input type="number"
                                                           name={"cheque_deposit.price"}
                                                           className="form-control"
                                                           id="cheque_deposit.price" value={this.state.cheque_deposit.price}
                                                           onChange={this.handleInputChange} disabled={false}
                                                           required/>
                                                    </label>
                                                </div>
                                                <div className="col-1"/>
                                            </div>
    
                                            <div className="row mb-3">
                                                <div className="col-2">
                                                    <label htmlFor="contact"
                                                           className="col-form-label">
                                                        {t('cheque:Debtor name')}
                                                    </label>
                                                </div>
                                                <div className="col-5">
                                                    <SelectContactDropDown
                                                        contact_type=''
                                                        handleInputChange={this.handleInputChange}
                                                        name="cheque_deposit.contact.id"
                                                        payment_type="receivable"
                                                        contact={this.state.cheque_deposit.contact.id}
                                                        asset={true}
                                                        require={true}
                                                        contact_key={true}
                                                    />
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                            {this.state.cheque_deposit.contact.id === "other_select" &&
                                            <div className="row mt-3">
                                                <div className="col-1"/>
                                                <div className="col-5">
                                                    <input type="text" className="form-control"
                                                           name="cheque_deposit.unknown_contact"
                                                           value={this.state.cheque_deposit.unknown_contact}
                                                           onChange={this.handleInputChange}
                                                           required={true}
                                                    />
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                            }
    
                                            <div className="row mb-3">
                                                <div className="col-2">
                                                    <label htmlFor="cheque_deposit.refNumber"
                                                           className="col-form-label">
                                                        {t('cheque:Reference document')}
                                                    </label>
                                                </div>
                                                <div className="col-2">
                                                    <label htmlFor="cheque_deposit.refNumber"
                                                           className="col-form-label">
                                                        <input type="text"
                                                           name={"cheque_deposit.refNumber"}
                                                           className="form-control"
                                                           id="cheque_deposit.refNumber" value={this.state.cheque_deposit.refNumber}
                                                           onChange={this.handleInputChange}
                                                           required/>
                                                    </label>
                                                </div>
    
                                                <div className="btn-group">
                                                        <label
                                                            className={this.state.upload.name ? "btn btn-success" : "btn btn-secondary"}>
                                                            {this.state.upload.name ? <>{t("cheque:Success")}</> : <>{t("cheque:Attach file")}</>}
                                                            <input type="file" name="image_upload"
                                                                   onChange={this.docInputChange}
                                                                   hidden/>
                                                        </label>
                                                <div className='mt-2 pl-2' style={{fontFamily:'medium'}}>
                                                 <small className='text-red' >{i18next.t("Allaction:Each file should not exceed 10 MB")} {i18next.t("Allaction:and just")} .pdf .jpg</small>
                                                </div>
                                               
                                                </div>
                                            </div>
    
                                            <div className="p-2">
                                                {this.state.image_upload_display ? <LightBox image={this.state.image_upload_display} /> :<div></div>}
                                                {this.state.upload.name}
                                            </div>
    
                                            <div className="row mb-3">
                                                <div className="col-2">
                                                    <label htmlFor="cheque_deposit.refNumber"
                                                           className="col-form-label">
                                                        {t('cheque:Note')}
                                                    </label>
                                                </div>
                                                <div className="col-5">
                                                    
                                                        <textarea className="form-control" maxLength="256" name={"cheque_deposit.note"}
                                                            value={this.state.cheque_deposit.note}
                                                            id = "cheque_deposit.note"
                                                            onChange={this.handleInputChange}/>
                                                  
                                                </div>
                                                <div className="col-1"/>
                                            </div>
    
                                            {/* <hr/> */}
    
                                            {/* <div className="row mb-3">
                                                <div className="col-1">
                                                    <label htmlFor="cheque_deposit.depositDate"
                                                           className="col-form-label">
                                                        ผ่านวันที่
                                                    </label>
                                                </div>
                                                <div className="col-5">
                                                    <DatePickerAdapter id="cheque_deposit.depositDate"
                                                                       className="form-control"
                                                                       name="cheque_deposit.depositDate"
                                                                       selected={this.state.cheque_deposit.depositDate}
                                                                       onChange={this.handleInputChange}
                                                                       disable={false}
                                                                       require={true}/>
                                                </div>
                                                <div className="col-1"/>
                                            </div> */}
                                        </div>
                                    </div>
    
                                    <div className="row mt-4">
                                        <div className="col text-right">
                                            <div className="btn-group mr-2">
                                                <Link to="/accounting/finance/cheque-deposit/list/all">
                                                    <button type="button" className="btn btn-secondary add">
                                                        {t('cheque:Cancel')}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="btn-group mr-2">
                                                <button type="submit" className="btn btn-primary add" form="form-deposit"
                                                        disabled={this.state.loading}>
                                                    {this.state.loading &&
                                                    <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                        {t('cheque:Save')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
    
                            </div>
                        </form>}
                    </Translation>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default CreateChequeDeposit;