import React, { Component } from 'react';
import UploadImageInput from './../../libs/uploadImageInput';
import { Link } from "react-router-dom";
import SearchSelect from "../../libs/searchSelect";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import { Translation } from "react-i18next";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import { Redirect } from 'react-router';
import "./styles/assetCreateAndUpdateForm.scss"
import NewsImageRender from "../../contact/news/newsimageRender";
import DeleteAssetImage from "./deleteAssetImage";
import DeleteAsset from "./deleteAsset";
import SelectContactDropDown from "../income/selectContactDropDown";
import numberWithComma from "../../libs/numberWithComma";
import { decode, encode } from 'base-64';
import apiSing from '../../api/sing'
import i18next from 'i18next';

const _ = require('lodash');

const mutation = graphql`
    mutation assetCreateAndUpdateFormMutation($input: CreateAssetInput!) {
        createAsset(input: $input) {
            ok
            warningText
        }
    }
`;

const query = graphql`
    query assetCreateAndUpdateFormQuery {
        viewer {
            allChartOfAccount {
                edges {
                    node {
                        id chartOfAccountCode name
                    }
                }
            }
        }
    }`;

const chartOfAccount = {
    LAND: {
        chartOfAccountType: '1210-01',
        chartOfAccountDepreciation: '',
        chartOfAccountDepreciationCumulative: '',
    },
    BUILDING: {
        chartOfAccountType: '1210-02',
        chartOfAccountDepreciation: '5700-01',
        chartOfAccountDepreciationCumulative: '1220-01',

        depreciationRatePercent: 5,
        depreciationRate: 0.05,
        lifeTimeYear: 20,
    },
    BUILDING_RENOVATION: {
        chartOfAccountType: '1210-03',
        chartOfAccountDepreciation: '5700-02',
        chartOfAccountDepreciationCumulative: '1220-02',

        depreciationRatePercent: 20,
        depreciationRate: 0.2,
        lifeTimeYear: 5,
    },
    OFFICE_DECORATION: {
        chartOfAccountType: '1210-04',
        chartOfAccountDepreciation: '5700-03',
        chartOfAccountDepreciationCumulative: '1220-03',

        depreciationRatePercent: 20,
        depreciationRate: 0.2,
        lifeTimeYear: 5,
    },
    OFFICE_EQUIPMENT: {
        chartOfAccountType: '1210-05',
        chartOfAccountDepreciation: '5700-04',
        chartOfAccountDepreciationCumulative: '1220-04',

        depreciationRatePercent: 20,
        depreciationRate: 0.2,
        lifeTimeYear: 5,
    },
    TOOLS: {
        chartOfAccountType: '1210-06',
        chartOfAccountDepreciation: '5700-05',
        chartOfAccountDepreciationCumulative: '1220-05',

        depreciationRatePercent: 20,
        depreciationRate: 0.2,
        lifeTimeYear: 5,
    },
    TRAINING_EQUIPMENT: {
        chartOfAccountType: '1210-07',
        chartOfAccountDepreciation: '5700-06',
        chartOfAccountDepreciationCumulative: '1220-06',

        depreciationRatePercent: 20,
        depreciationRate: 0.2,
        lifeTimeYear: 5,
    },
    VEHICLE: {
        chartOfAccountType: '1210-08',
        chartOfAccountDepreciation: '5700-07',
        chartOfAccountDepreciationCumulative: '1220-07',

        depreciationRatePercent: 20,
        depreciationRate: 0.2,
        lifeTimeYear: 5,
    },
    COMMON_PROPERTY: {
        chartOfAccountType: '1210-09',
        chartOfAccountDepreciation: '5700-08',
        chartOfAccountDepreciationCumulative: '1220-08',

        depreciationRatePercent: 20,
        depreciationRate: 0.2,
        lifeTimeYear: 5,
    },
    INTANGIBLE_ASSET: {
        chartOfAccountType: '1210-10',
        chartOfAccountDepreciation: '5700-09',
        chartOfAccountDepreciationCumulative: '1220-09',

        depreciationRatePercent: 20,
        depreciationRate: 0.2,
        lifeTimeYear: 5,
    },
};

class AssetCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToList: false,
            loading: false,
            qrAsset: {},
            asset: {
                id: '',
                type: 'LAND',
                nounClassifier: '',
                chartOfAccountDepreciation: { id: '' },
                chartOfAccountType: { id: '' },
                chartOfAccountDepreciationCumulative: { id: '' },

                docNumber: 'LANXXX',
                name: '',
                description: '',
                storage: '',
                seller: { id: '', },
                sellerOther: "",
                remark: "",

                calDepreciation: false,
                formula: 'STRAIGHT_LINE',
                depreciationRatePercent: 5,
                depreciationRate: 0.05,
                lifeTimeYear: 20,
                purchaseDate: new Date(),
                calDepreciationDate: new Date(),
                costPrice: 0,
                historicalValue: 0,
                carcassPrice: 1,
                calSchedule: "MONTHLY",
                historicalAmount: 0,
                saleDate: null,
                saleStatus: 'active'
            },
            upload_image_list: [],
            allAssetImage: {
                edges: []
            },

            chartOfAccountTypeList: [],
            chartOfAccountDepreciationCumulativeList: [],
            chartOfAccountDepreciationList: [],

            edit_only: false,
            lifeTimeYearOther: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteAssetImage = this.deleteAssetImage.bind(this);
        this.deleteAsset = this.deleteAsset.bind(this);
        this.init = this.init.bind(this);
        this.onMutation = this.onMutation.bind(this);
        this.approveStatus = this.approveStatus.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        fetchQuery(environment, query).then(data => {
            let chartOfAccountTypeList = [];
            let chartOfAccountDepreciationCumulativeList = [];
            let chartOfAccountDepreciationList = [];
            data.viewer.allChartOfAccount.edges.forEach((chart_of_account) => {
                if (chart_of_account.node.chartOfAccountCode.startsWith("1210")) {
                    chartOfAccountTypeList.push(chart_of_account);
                } else if (chart_of_account.node.chartOfAccountCode.startsWith("1220")) {
                    chartOfAccountDepreciationCumulativeList.push(chart_of_account);
                } else if (chart_of_account.node.chartOfAccountCode.startsWith("5700")) {
                    chartOfAccountDepreciationList.push(chart_of_account);
                }
            });
            this.setState({
                chartOfAccountTypeList: chartOfAccountTypeList,
                chartOfAccountDepreciationCumulativeList: chartOfAccountDepreciationCumulativeList,
                chartOfAccountDepreciationList: chartOfAccountDepreciationList,
            }, () => {
                //create
                if (!this.props.asset_id) {
                    let type = this.props.type === "all" ? "land" : this.props.type;
                    let event = {
                        target: {
                            name: "asset.type",
                            value: type.toUpperCase()
                        }
                    };
                    this.handleInputChange(event)
                }
            })
        });

        if (this.props.asset_id) {
            fetchQuery(environment, this.props.query, { id: this.props.asset_id }).then(response => {
                if (_.isObject(response.asset)) {
                    let data = _.cloneDeep(response);
                    _.set(data.asset, 'purchaseDate', new Date(data.asset.purchaseDate));
                    _.set(data.asset, 'calDepreciationDate', new Date(data.asset.calDepreciationDate));
                    if (data.asset.saleDate) {
                        _.set(data.asset, 'saleDate', new Date(data.asset.saleDate));
                    }

                    if (!_.get(data.asset, "seller") && !_.get(data.asset, "sellerOther")) {
                        _.set(data.asset, 'seller', { id: "on_contact" });
                    } else if (!_.get(data.asset, "seller") && _.get(data.asset, "sellerOther")) {
                        _.set(data.asset, 'seller', { id: "other_select" });
                    }

                    this.setState({
                        asset: data.asset,
                        allAssetImage: data.allAssetImage,
                        edit_only: true,
                    });
                    this.approveStatus()

                }
            });


        }
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        if (event.target.name === "asset.historicalAmount" && Number(this.state.asset.costPrice) < Number(value)) {
            Swal.fire(i18next.t("assetCreateOrUpdate:Unable to calculate depreciation."), i18next.t("assetCreateOrUpdate:Asset value is less than the accumulated depreciation."), 'warning');
            return null;
        }

        if (value === 'lifeTimeYearOther' || value === '20' || value === '5') {
            this.setState({ lifeTimeYearOther: '' })
        }
        this.setState(prevState => {
            if (event.target.type === 'checkbox') {
                value = !_.get(prevState, event.target.name);
            }

            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        }, () => this.switchState(event));
    }

    switchState(event) {
        let value = event.target.value;
        let name = event.target.name;

        if ((name === "asset.lifeTimeYear" || name === "asset.depreciationRatePercent")) {
            let value = event.target.value;
            let relationship_name = "asset.depreciationRatePercent";
            let relationship_value = 0;
            let depreciation_rate = 0;

            if (value === "20") {
                relationship_value = 5;
            }
            if (value === "5") {
                relationship_value = 20;
            }

            if (name === "asset.depreciationRatePercent") {
                relationship_name = "asset.lifeTimeYear";
                depreciation_rate = parseFloat(value) / 100
            } else {
                depreciation_rate = parseFloat(relationship_value) / 100
            }

            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    relationship_name,
                    relationship_value
                )
            }, () => this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    "asset.depreciationRate",
                    depreciation_rate
                )
            })
            );
        }

        if (name === "lifeTimeYearOther") {
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    "asset.depreciationRatePercent",
                    100 / value
                )
            }, () => this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    "asset.depreciationRate",
                    (100 / value) / 100
                )
            })
            );

        }

        if (name === "asset.type") {
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    "asset.docNumber",
                    this.findType(value) + "XXX"
                )
            });

            let chartOfAccountTypeId = '';
            let chartOfAccountDepreciationId = '';
            let chartOfAccountDepreciationCumulativeId = '';
            this.state.chartOfAccountTypeList.forEach((type) => {
                if (type.node.chartOfAccountCode === chartOfAccount[value].chartOfAccountType) {
                    chartOfAccountTypeId = type.node.id;
                }
            });
            this.state.chartOfAccountDepreciationList.forEach((depreciation) => {
                if (depreciation.node.chartOfAccountCode === chartOfAccount[value].chartOfAccountDepreciation) {
                    chartOfAccountDepreciationId = depreciation.node.id;
                }
            });
            this.state.chartOfAccountDepreciationCumulativeList.forEach((depreciation_cumulative) => {
                if (depreciation_cumulative.node.chartOfAccountCode === chartOfAccount[value].chartOfAccountDepreciationCumulative) {
                    chartOfAccountDepreciationCumulativeId = depreciation_cumulative.node.id;
                }
            });

            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    "asset.chartOfAccountDepreciation.id",
                    chartOfAccountDepreciationId
                )
            });

            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    "asset.chartOfAccountType.id",
                    chartOfAccountTypeId
                )
            });

            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    "asset.chartOfAccountDepreciationCumulative.id",
                    chartOfAccountDepreciationCumulativeId
                )
            });
            // อายุ กับ อัตราค่าเสื่อม
            if (value !== "LAND") {
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        "asset.depreciationRatePercent",
                        chartOfAccount[value].depreciationRatePercent
                    )
                });
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        "asset.depreciationRate",
                        chartOfAccount[value].depreciationRate
                    )
                });
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        "asset.lifeTimeYear",
                        chartOfAccount[value].lifeTimeYear
                    )
                });
            }
        }

    }

    onSubmit(e) {
        e.preventDefault();
        let cl = ((this.state.asset.costPrice - this.state.asset.carcassPrice) * this.state.asset.depreciationRate) / 365;
        if (this.state.asset.type !== "LAND" && this.state.asset.calDepreciation && cl.toFixed(2) <= 0) {
            return Swal.fire(i18next.t("assetCreateOrUpdate:Unable to calculate depreciation."), i18next.t("assetCreateOrUpdate:Please verify the cost and scrap value."), 'warning');
        }
        this.setState({ loading: true });
        //
        if (this.state.lifeTimeYearOther) {
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    "asset.lifeTimeYear",
                    this.state.lifeTimeYearOther
                )
            }, () => {
                this.onMutation()
            });
        } else {
            this.onMutation()
        }

    }

    onMutation() {
        let variables = {
            input: {
                asset: JSON.stringify(this.state.asset),
            }
        };
        let uploadables = Object.assign(this.state.upload_image_list);

        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    this.setState({ loading: false });
                    if (response.createAsset.ok) {
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                            this.setState({ redirectToList: true });
                        });
                    } else {
                        // Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createAsset.warningText, 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({ loading: false });
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )
    }

    findType(key) {
        let status = {
            LAND: "LAN",
            BUILDING: "B",
            BUILDING_RENOVATION: "BR",
            OFFICE_DECORATION: "OD",
            OFFICE_EQUIPMENT: "EQ",
            TOOLS: "T",
            TRAINING_EQUIPMENT: "TE",
            VEHICLE: "V",
            COMMON_PROPERTY: "CP",
            INTANGIBLE_ASSET: "ITG",
        };
        return status[key]
    }

    deleteAssetImage(news_image_id, index) {
        if (news_image_id) {
            // mutation delete
            let variables = {
                input: {
                    clientMutationId: news_image_id,
                }
            };
            DeleteAssetImage(variables, () => this.init(), () => Swal.fire(i18next.t("Allaction:Deleted Unsuccessful"), '', 'error'));

        } else {
            // delete upload_image_list
            let upload_image_list = [...this.state.upload_image_list];
            upload_image_list.splice(index, 1);
            this.setState({ upload_image_list: upload_image_list });
        }
    }

    deleteAsset(e) {
        e.preventDefault();
        this.setState({ loading: true });
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data") + "?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        id: this.props.asset_id,
                    }
                };
                DeleteAsset(variables,
                    () => Swal.fire(i18next.t("Allaction:Deleted Successfully"), '', 'success').then(() => {
                        this.setState({ redirectToList: true, loading: false });
                    })
                    , () => Swal.fire(i18next.t("Allaction:Deleted Unsuccessful"), '', 'error').then(() => {
                        this.setState({ loading: false });
                    })
                );
            } else {
                this.setState({ loading: false });
            }
        });
    }

    approveStatus() {
        let id = decode(this.state.asset.id).split(":")
        let idQR = (id[1] + this.state.asset.docNumber).toString()
        let qr = encode(idQR)
        apiSing.getQR(qr).then((res) => {
            this.setState({ qrAsset: res.data.asset[0] })
        })
    }

    render() {

        if (this.state.redirectToList || _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'assetLegacy'])?.menu_on === false) {
            return <Redirect to="/accounting/asset/all" />;
        }


        let approve_status = this.state.qrAsset.approve_status ? i18next.t("assetCreateOrUpdate:Assets checked.") : i18next.t("assetCreateOrUpdate:Pending verification.")
        return (
            <div className="container-fluid box" id="asset-create">
                <div className="row justify-content-between">
                    <div className="col">
                        <h3 className="mb-4 content-inner">
                            <Link to="/accounting/asset/all">
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                    alt="back" className="back" />
                            </Link>
                            <Translation>
                                {
                                    t => <span>{t('accounting:add asset')} <span className='text-danger'>**กรณีเพิ่มสินทรัพย์ยกมาให้หน้านี้ จะไม่ใช่การ ซื้อในรอบปัจจุบัน หลังจากบันทึกแล้วจะไม่เกิดบัญชีรายวันซื้อ</span></span>
                                }
                            </Translation>

                        </h3>
                    </div>
                    {this.props.asset_id && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_asset_delete' }) &&
                        <div className="col text-right mb-4">
                            {

                                this.state.qrAsset.approve_status ?
                                    <button className="btn btn-success add mr-2">
                                        <Translation>{t => <span>{approve_status}</span>}</Translation>
                                    </button>
                                    :
                                    <button className="btn btn-danger add mr-2">
                                        <Translation>{t => <span>{approve_status}</span>}</Translation>
                                    </button>
                            }




                            {
                                // localStorage.getItem('site_id') && localStorage.getItem('site_id') === '331' &&
                                <Link to={"/accounting/asset/assetPrintQrCode/" + this.state.asset.docNumber} target={"_blank"}>
                                    <button className="btn btn-primary add mr-2" disabled={this.state.loading}>
                                        <Translation>
                                            {
                                                t => <span>{t('Allaction:printQr asset')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>


                            }






                            <button className="btn btn-danger add" onClick={this.deleteAsset} disabled={this.state.loading}>
                                {this.state.loading &&
                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                <Translation>
                                    {
                                        t => <span>{t('accounting:delete asset')}</span>
                                    }
                                </Translation>
                            </button>
                        </div>
                    }

                </div>
                <Translation>
                    {t =>
                        <form onSubmit={this.onSubmit}>
                            <div className="content-inner fade-up">
                                <div className="row mt-3">
                                    <label className="col-2 col-form-label" htmlFor="type">
                                        {i18next.t("assetCreateOrUpdate:Category")}
                                    </label>
                                    <div className="col-3">
                                        <div className="input-group">
                                            <select className="form-control" name="asset.type" disabled={this.state.edit_only}
                                                value={this.state.asset.type}
                                                onChange={this.handleInputChange}>
                                                <option value="LAND">{i18next.t("assetSetting:land")}</option>
                                                <option value="BUILDING">{i18next.t("assetSetting:building")}</option>
                                                <option value="BUILDING_RENOVATION">{i18next.t("assetSetting:building_renovation")}</option>
                                                <option value="OFFICE_DECORATION">{i18next.t("assetSetting:office_decoration")}</option>
                                                <option value="OFFICE_EQUIPMENT">{i18next.t("assetSetting:office_equipment")}</option>
                                                <option value="TOOLS">{i18next.t("assetSetting:tools")}</option>
                                                <option value="TRAINING_EQUIPMENT">{i18next.t("assetSetting:training_equipment")}</option>
                                                <option value="VEHICLE">{i18next.t("assetSetting:vehicle")}</option>
                                                <option value="COMMON_PROPERTY">{i18next.t("assetSetting:common_property")}</option>
                                                <option value="INTANGIBLE_ASSET">{i18next.t("assetSetting:intangible_asset")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-1" />
                                    <label className="col-2 col-form-label" htmlFor="nounClassifier">
                                        {i18next.t("assetCreateOrUpdate:Unit")}
                                    </label>
                                    <div className="col-3">
                                        <input type="text" className="form-control" disabled={this.state.edit_only}
                                            name="asset.nounClassifier" maxLength={10}
                                            value={this.state.asset.nounClassifier}
                                            onChange={this.handleInputChange}
                                            placeholder="Ex. ตัว, อัน, คัน, เครื่อง"
                                            required />
                                        {/*<div className="input-group">*/}
                                        {/*    <select className="form-control" name="unit"*/}
                                        {/*            value={this.state.unit}*/}
                                        {/*            onChange={this.handleInputChange}>*/}
                                        {/*        <option value="NO_TAX">แปลง</option>*/}
                                        {/*        <option value="EXCLUDE_TAX">โครงการ</option>*/}
                                        {/*        <option value="INCLUDE_TAX">หลัง</option>*/}
                                        {/*        <option value="INCLUDE_TAX">สวน</option>*/}
                                        {/*        <option value="INCLUDE_TAX">ตัว</option>*/}
                                        {/*        <option value="INCLUDE_TAX">คัน</option>*/}
                                        {/*        <option value="INCLUDE_TAX">เครื่อง</option>*/}
                                        {/*        <option value="INCLUDE_TAX">สิทธิ์</option>*/}
                                        {/*    </select>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-2 col-form-label" htmlFor="chartOfAccountType">
                                        {i18next.t("assetCreateOrUpdate:Asset Account Number")}
                                    </label>
                                    <div className="col-3">
                                        <SearchSelect onChange={this.handleInputChange} disabled={this.state.edit_only}
                                            value={this.state.asset.chartOfAccountType.id}
                                            name="asset.chartOfAccountType.id" placeholder="รายการ"
                                            queryObject={this.state.chartOfAccountTypeList}
                                            keyOfValue="id" require={true}
                                            keyOfLabel="chartOfAccountCode:name" />
                                    </div>
                                </div>
                                {this.state.asset.type !== 'LAND' &&
                                    <div className="row mt-3 mb-5">
                                        <label className="col-2 col-form-label" htmlFor="chartOfAccountDepreciation">
                                            {i18next.t("assetCreateOrUpdate:Depreciation Expense")}
                                        </label>
                                        <div className="col-3">
                                            <SearchSelect onChange={this.handleInputChange} disabled={this.state.edit_only}
                                                value={this.state.asset.chartOfAccountDepreciation.id}
                                                name="asset.chartOfAccountDepreciation.id" placeholder="รายการ"
                                                queryObject={this.state.chartOfAccountDepreciationList}
                                                keyOfValue="id" require={true}
                                                keyOfLabel="chartOfAccountCode:name" />
                                        </div>
                                        <div className="col-1" />
                                        <label className="col-2 col-form-label" htmlFor="chartOfAccountDepreciationCumulative">
                                            {i18next.t("assetCreateOrUpdate:Accumulated Depreciation")}
                                        </label>
                                        <div className="col-3">
                                            <SearchSelect onChange={this.handleInputChange} disabled={this.state.edit_only}
                                                value={this.state.asset.chartOfAccountDepreciationCumulative.id}
                                                name="asset.chartOfAccountDepreciationCumulative.id" placeholder="รายการ"
                                                queryObject={this.state.chartOfAccountDepreciationCumulativeList}
                                                keyOfValue="id" require={true}
                                                keyOfLabel="chartOfAccountCode:name" />
                                        </div>
                                    </div>
                                }
                                <hr />

                                <div className="row justify-content-between mt-5">
                                    <div className="col-md-6">
                                        <h6>{i18next.t("assetCreateOrUpdate:Asset Information")}</h6>

                                        <div className="row mt-5">
                                            <label className="col-md-4 col-form-label" htmlFor="docNumber">
                                                {i18next.t("assetCreateOrUpdate:Asset Code")}
                                            </label>
                                            <div className="col-md-8">
                                                <input type="text" className="form-control"
                                                    name="asset.docNumber"
                                                    value={this.state.asset.docNumber}
                                                    onChange={this.handleInputChange}
                                                    disabled={true}
                                                    required />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label" htmlFor="name">
                                                {i18next.t("assetCreateOrUpdate:Asset Name")}
                                            </label>
                                            <div className="col">
                                                <input type="text" className="form-control"
                                                    name="asset.name"
                                                    maxLength={200}
                                                    value={this.state.asset.name}
                                                    onChange={this.handleInputChange}
                                                    required />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label" htmlFor="description">
                                                {i18next.t("assetCreateOrUpdate:Description")}
                                            </label>
                                            <div className="col">
                                                <textarea cols="" rows="3" className="form-control" id="description"
                                                    name="asset.description" maxLength={256}
                                                    value={this.state.asset.description} onChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label" htmlFor="remark">
                                                {i18next.t("assetCreateOrUpdate:Note")}
                                            </label>
                                            <div className="col">
                                                <textarea cols="" rows="3" className="form-control" id="remark"
                                                    name="asset.remark"
                                                    value={this.state.asset.remark} onChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label" htmlFor="storage">
                                                {i18next.t("assetCreateOrUpdate:Storage Location")}
                                            </label>
                                            <div className="col">
                                                <input type="text" className="form-control"
                                                    name="asset.storage"
                                                    maxLength={256}
                                                    value={this.state.asset.storage}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label" htmlFor="seller">
                                                {i18next.t("assetCreateOrUpdate:Seller")}
                                            </label>
                                            <div className="col">
                                                <SelectContactDropDown
                                                    contact_type=''
                                                    handleInputChange={this.handleInputChange}
                                                    name="asset.seller.id"
                                                    payment_type="payable"
                                                    contact={this.state.asset.seller.id}
                                                    asset={true}
                                                    require={true}
                                                    contact_key={false}
                                                />
                                            </div>
                                        </div>
                                        {this.state.asset.seller.id === "other_select" &&
                                            <div className="row mt-3">
                                                <div className="col-md-4" />
                                                <div className="col">
                                                    <input type="text" className="form-control"
                                                        name="asset.sellerOther"
                                                        value={this.state.asset.sellerOther}
                                                        onChange={this.handleInputChange}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label">
                                                {i18next.t("assetCreateOrUpdate:Image")}
                                            </label>
                                            <div className="col">
                                                <div className="card-columns">
                                                    {
                                                        this.state.allAssetImage.edges.map((asset_image, index) =>
                                                            <NewsImageRender key={index} src={asset_image.node.source}
                                                                onClickDelete={() => this.deleteAssetImage(asset_image.node.id, index)} />
                                                        )
                                                    }

                                                    {
                                                        this.state.upload_image_list.map((image, index) =>
                                                            <NewsImageRender key={index}
                                                                src={URL.createObjectURL(image)}
                                                                onClickDelete={() => this.deleteAssetImage(false, index)} />
                                                        )
                                                    }

                                                    <div className="upload-file">
                                                        <div>
                                                            <label htmlFor="uploadNewsImages"
                                                                className="create news-button-image btn btn-light mt-2">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/plus-icon.png'}
                                                                    alt="plus-icon"
                                                                    className="img-fluid plus asset-image" />
                                                            </label>
                                                        </div>
                                                        <div className="upload-input">
                                                            <UploadImageInput id="uploadNewsImages"
                                                                setField={"upload_image_list[" + this.state.upload_image_list.length + "]"}
                                                                onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-1"/>*/}


                                    <div className="col-md-6">
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label" htmlFor="costPrice">
                                                {i18next.t("assetCreateOrUpdate:Asset Value")}
                                            </label>
                                            <div className="col">
                                                <div className="input-group">
                                                    <input type="number" className="form-control"
                                                        name="asset.costPrice"
                                                        value={this.state.asset.costPrice}
                                                        onChange={this.handleInputChange}
                                                        disabled={this.state.edit_only}
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label" htmlFor="historicalValue">
                                                {i18next.t("assetCreateOrUpdate:Opening Asset Value")}
                                            </label>
                                            <div className="col">
                                                <div className="input-group">
                                                    <input type="number" className="form-control"
                                                        name="asset.historicalValue" min={0} step="0.000001"
                                                        value={this.state.asset.historicalValue}
                                                        onChange={this.handleInputChange}
                                                        disabled={this.state.edit_only}
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className="col-md-4 col-form-label" htmlFor="purchaseDate">
                                                {i18next.t("assetCreateOrUpdate:Purchase Date")}
                                            </label>
                                            <div className="col">
                                                <DatePickerAdapter id="purchaseDate"
                                                    className="form-control" name="asset.purchaseDate"
                                                    selected={this.state.asset.purchaseDate}
                                                    onChange={this.handleInputChange}
                                                    disable={this.state.edit_only}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4">

                                        </div>
                                        {this.state.asset.type !== 'LAND' &&
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="asset.calDepreciation"
                                                    checked={this.state.asset.calDepreciation}
                                                    className="custom-control-input"
                                                    id="calDepreciation"
                                                    onChange={this.handleInputChange}
                                                    disabled={this.state.edit_only}
                                                />
                                                <label className="custom-control-label"
                                                    htmlFor="calDepreciation"><h6>{i18next.t("assetCreateOrUpdate:Depreciation calculation or not")}?</h6></label>
                                            </div>
                                        }

                                        {(this.state.asset.type !== 'LAND' && this.state.asset.calDepreciation) &&
                                            <React.Fragment>

                                                <div className="row mt-4">
                                                    <label className="col-md-4 col-form-label" htmlFor="formula">
                                                        {i18next.t("assetCreateOrUpdate:Depreciation calculation method")}
                                                    </label>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <select className="form-control" name="asset.formula"
                                                                value={this.state.asset.formula} disabled={true}
                                                                onChange={this.handleInputChange}>
                                                                <option value="STRAIGHT_LINE">{i18next.t("assetCreateOrUpdate:Straight-line method")}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.lifeTimeYearOther !== '' ?
                                                        <div className="row mt-3">
                                                            <label className="col-md-4 col-form-label"
                                                                htmlFor="depreciationRatePercent">
                                                                {i18next.t("assetCreateOrUpdate:Depreciation Expense")}
                                                            </label>
                                                            <div className="col">
                                                                <div className="input-group">
                                                                    <select className="form-control" name="lifeTimeYearOther"
                                                                        value={this.state.asset.depreciationRatePercent}
                                                                        onChange={this.handleInputChange}
                                                                        disabled={true}>
                                                                        <option value={this.state.asset.depreciationRatePercent}>{numberWithComma(this.state.asset.depreciationRatePercent) + '%'}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row mt-3">
                                                            <label className="col-md-4 col-form-label"
                                                                htmlFor="depreciationRatePercent">
                                                                {i18next.t("assetCreateOrUpdate:Depreciation Expense")}
                                                            </label>
                                                            <div className="col">
                                                                <div className="input-group">
                                                                    {
                                                                        this.state.edit_only ?
                                                                            <select className="form-control"
                                                                                disabled={this.state.edit_only}>
                                                                                <option value={this.state.asset.depreciationRatePercent}>
                                                                                    {numberWithComma(this.state.asset.depreciationRatePercent) + '%'}
                                                                                </option>
                                                                            </select>
                                                                            :
                                                                            <select className="form-control"
                                                                                name="asset.depreciationRatePercent"
                                                                                value={this.state.asset.depreciationRatePercent}
                                                                                onChange={this.handleInputChange}
                                                                                disabled={this.state.edit_only}>
                                                                                <option value="5">5%</option>
                                                                                <option value="20">20%</option>
                                                                            </select>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                }

                                                <div className="row mt-3">
                                                    <label className="col-md-4 col-form-label" htmlFor="lifeTimeYear">
                                                        {i18next.t("assetCreateOrUpdate:Asset lifespan")}
                                                    </label>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            {
                                                                this.state.edit_only ?
                                                                    <select className="form-control" name="asset.lifeTimeYear"
                                                                        disabled={this.state.edit_only}>
                                                                        <option value={this.state.asset.lifeTimeYear}>{this.state.asset.lifeTimeYear + ' ปี'}</option>
                                                                    </select>
                                                                    :
                                                                    <select className="form-control" name="asset.lifeTimeYear"
                                                                        value={this.state.asset.lifeTimeYear}
                                                                        onChange={this.handleInputChange}
                                                                        disabled={this.state.edit_only}>
                                                                        <option value="20">20 ปี</option>
                                                                        <option value="5">5 ปี</option>
                                                                        <option value="lifeTimeYearOther">{i18next.t("assetSetassetCreateOrUpdateting:User-defined")}</option>
                                                                    </select>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.asset.lifeTimeYear === 'lifeTimeYearOther' &&
                                                    <div className="row mt-3">
                                                        <label className="col-md-4 col-form-label" />
                                                        <div className="col">
                                                            <div className="input-group">
                                                                <input type="number" className="form-control"
                                                                    name="lifeTimeYearOther"
                                                                    placeholder={"ระบุ"}
                                                                    value={this.state.lifeTimeYearOther}
                                                                    onChange={this.handleInputChange}
                                                                    disabled={this.state.edit_only}
                                                                    required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="row mt-3">
                                                    <label className="col-md-4 col-form-label" htmlFor="calDepreciationDate">
                                                        {i18next.t("assetCreateOrUpdate:Depreciation calculation date")}
                                                    </label>
                                                    <div className="col">
                                                        <DatePickerAdapter id="calDepreciationDate"
                                                            className="form-control" name="asset.calDepreciationDate"
                                                            selected={this.state.asset.calDepreciationDate}
                                                            onChange={this.handleInputChange}
                                                            disable={this.state.edit_only}
                                                            minDate={this.state.asset.purchaseDate}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="row mt-3">
                                        <label className="col-md-4 col-form-label" htmlFor="costPrice">
                                            ราคาทุน
                                        </label>
                                        <div className="col">
                                            <div className="input-group">
                                                <input type="number" className="form-control"
                                                       name="asset.costPrice"
                                                       value={this.state.asset.costPrice}
                                                       onChange={this.handleInputChange}
                                                       disabled={this.state.edit_only}
                                                       required/>
                                            </div>
                                        </div>
                                    </div> */}
                                                <div className="row mt-3">
                                                    <label className="col-md-4 col-form-label" htmlFor="carcassPrice">
                                                        {i18next.t("assetCreateOrUpdate:Scrap value")}
                                                    </label>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <input type="number" className="form-control"
                                                                name="asset.carcassPrice" min={1}
                                                                value={this.state.asset.carcassPrice}
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.edit_only}
                                                                required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <label className="col-md-4 col-form-label" htmlFor="calSchedule">
                                                        {i18next.t("assetCreateOrUpdate:Calculate monthly depreciation")}
                                                    </label>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <select className="form-control" name="asset.calSchedule"
                                                                disabled={true}
                                                                value={this.state.asset.calSchedule}
                                                                onChange={this.handleInputChange}>
                                                                <option value="MONTHLY">{i18next.t("assetCreateOrUpdate:Monthly")}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <label className="col-md-4 col-form-label" htmlFor="historicalAmount">
                                                        {i18next.t("assetCreateOrUpdate:Accumulated depreciation")}
                                                    </label>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <input type="number" className="form-control"
                                                                name="asset.historicalAmount"
                                                                value={this.state.asset.historicalAmount}
                                                                onChange={this.handleInputChange}
                                                                disabled={this.state.edit_only}
                                                                required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <label className="col-md-4 col-form-label" htmlFor="historicalAmount">
                                                        {i18next.t("assetCreateOrUpdate:Sales date")}
                                                    </label>
                                                    <div className="col">
                                                        <DatePickerAdapter id="saleDate"
                                                            className="form-control" name="asset.saleDate"
                                                            selected={this.state.asset.saleDate}
                                                            onChange={this.handleInputChange}
                                                        //    disable={this.state.asset.saleDate?true:false}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="col-1" />
                                </div>

                                <div className="row">
                                    {((this.props.asset_id && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_asset_edit' })) || (!this.props.asset_id && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_asset_create' }))) &&
                                        <div className="col text-right mt-4 button-grp">
                                            <button type="submit" className="btn btn-primary add mr-2"
                                                disabled={this.state.loading}>
                                                {this.state.loading &&
                                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                {i18next.t("Allaction:save")}
                                            </button>
                                            <Link to="/accounting/asset/all">
                                                <button type="button" className="btn btn-secondary add">
                                                    {i18next.t("Allaction:cancel")}
                                                </button>
                                            </Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    }
                </Translation>

            </div>
        )
    }
}

export default AssetCreate;
