/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type silvermanAgentCreateQueryVariables = {|
  residential_id: string
|};
export type silvermanAgentCreateQueryResponse = {|
  +myUserAgent: ?{|
    +id: string,
    +username: string,
    +tenant: ?{|
      +id: string,
      +nameTitle: ?string,
      +firstName: string,
      +lastName: ?string,
      +householder: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +residential: {|
              +id: string,
              +name: string,
              +district: ?string,
              +province: ?string,
              +project: {|
                +id: string,
                +name: string,
              |},
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type silvermanAgentCreateQuery = {|
  variables: silvermanAgentCreateQueryVariables,
  response: silvermanAgentCreateQueryResponse,
|};
*/


/*
query silvermanAgentCreateQuery(
  $residential_id: ID!
) {
  myUserAgent {
    id
    username
    tenant {
      id
      nameTitle
      firstName
      lastName
      householder(residential_Id: $residential_id) {
        edges {
          node {
            id
            residential {
              id
              name
              district
              province
              project {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "residential_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "myUserAgent",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TenantNode",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "residential_Id",
                "variableName": "residential_id"
              }
            ],
            "concreteType": "HouseHolderNodeConnection",
            "kind": "LinkedField",
            "name": "householder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HouseHolderNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "district",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "province",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectNode",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "silvermanAgentCreateQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "silvermanAgentCreateQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6cfcd4963c0da2c13e4af5d1e6bedaee",
    "id": null,
    "metadata": {},
    "name": "silvermanAgentCreateQuery",
    "operationKind": "query",
    "text": "query silvermanAgentCreateQuery(\n  $residential_id: ID!\n) {\n  myUserAgent {\n    id\n    username\n    tenant {\n      id\n      nameTitle\n      firstName\n      lastName\n      householder(residential_Id: $residential_id) {\n        edges {\n          node {\n            id\n            residential {\n              id\n              name\n              district\n              province\n              project {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7df9ab7ae0a790c537136e936f0418d9';

module.exports = node;
