import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "./selectContact.scss";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import ListContactSiriraj from './listContactSiriraj';
import numberWithCommas from '../../libs/numberWithComma';
import { fetchQuery } from 'react-relay';
import environment from '../../env/environment';
import format from 'date-fns/format';
import Loading from '../../libs/loading';
import ListContactSupplierReceivable from './listContactSupplierReceivable'


const query = graphql`
    query selectContactReceivableSirirajQuery($typeOfContact:String, $conveyance:String, $isActive: Boolean, $receivableFilter:Boolean, $periodDate:Date, $periodList:[Date]) {      
        contactViewer{
            id
            allContact(typeOfContact: $typeOfContact ,  conveyance: $conveyance, isActive: $isActive, receivableFilter: $receivableFilter , periodDate:$periodDate, periodList:$periodList){
                        edges{
                            node{
                                id
                                refNumber
                                name
                                firstName
                                lastName
                                residential {
                                    id
                                    conveyance
                                }
                                ownerName
                                mailingAddress
                                mailingDistrict
                                mailingCity
                                mailingProvince
                                mailingPostalCode
                                            
                                renterName
                                renterAddress
                                renterDistrict
                                renterCity
                                renterProvince
                                renterPostalCode

                                agentName
                                agentAddress
                                agentDistrict
                                agentCity
                                agentProvince
                                agentPostalCode

                                allAmountNotPaid
                            }
                        }
                    }
        },
        getAllPeriod
    }
`;

const list_expense = ['purchase', 'pay', 'accept_credit_note', 'prepaid_deposit', 'other_expense', 'payable_movement', 'flow_of_prepaid_deposit'];


class SelectContactReceivableSiriraj extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: [],
            contact_residential: [],
            contact_count: 0,
            select_value: 'residential',
            contact_type: 'supplier',
            payment_type: list_expense.includes(props.accountType) ? 'payable' : 'receivable',
            role: "",
            
            totalAmount: 0,
            allPeriod: [],
            allContact: [],            
            periodSelected: [],
            loading: false,
            loadingList: false,
        };

        this.selectContact = this.selectContact.bind(this);
        this.onContactTypeChange = this.onContactTypeChange.bind(this);
        this.fecthAllData = this.fecthAllData.bind(this);
        this.fetchSelectData = this.fetchSelectData.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
    }

    componentDidMount() {
       this.fecthAllData()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.periodSelected !== this.state.periodSelected) {
            if(this.state.periodSelected === []){
                this.fecthAllData()
            } else {
                this.fetchSelectData()
            }
        }
    }

    fecthAllData() {        
        let variables = {
            typeOfContact: "residential" ,  
            conveyance:  "all", 
            isActive: true, 
            receivableFilter: true,
        }
        this.setState({
            loading: true
        })
        fetchQuery(environment, query, variables).then(data => {
            this.setState({
                allPeriod: data.getAllPeriod,   
                allContact: data.contactViewer.allContact.edges,   
                loading: false,
            })
        })
    }

    fetchSelectData() {
        let variables = {
            typeOfContact: "residential" ,  
            conveyance:  "all", 
            isActive: true, 
            receivableFilter: true,
            periodList: this.state.periodSelected 
        }
        this.setState({
            loadingList: true
        })
        fetchQuery(environment, query, variables).then(data => {
            this.setState({
                allPeriod: data.getAllPeriod,   
                allContact: data.contactViewer.allContact.edges,   
                loadingList: false,
            })
        })
    }

    selectContact(param, detail) {
        let contact = [...this.state.contact];    
        let contact_residential = [...this.state.contact_residential];
        let contact_count = this.state.contact_count;
        let totalAmount = this.state.totalAmount
        
        //เช็คว่าใน this.state.contact มี id นี้อยู่รึป่าว
        if (contact.includes(param)) {        
            for (let selected in contact) {
                if (contact[selected] === param) {
                    contact.splice(selected, 1);
                    contact_count = contact_count - 1;
                    if (detail) contact_residential.splice(selected, 1);
                    break
                }
            }
            totalAmount -= detail.node.allAmountNotPaid;
        } else {
            contact.push(param);
            contact_count = contact_count + 1;
            totalAmount += detail.node.allAmountNotPaid;
            if (detail) contact_residential.push(detail.node);
        }

        this.setState({
            contact: contact,
            contact_residential: contact_residential,
            contact_count: contact_count,
            totalAmount: totalAmount,
        }, () => this.props.updateCustomerList(this.state.contact,this.state.contact_residential));
    }

    selectAllContact(param, detail) {
        let contact = [...this.state.contact];    
        let contact_residential = [...this.state.contact_residential];
        let contact_count = this.state.contact_count;
        let totalAmount = this.state.totalAmount
        
        //เช็คว่าใน this.state.contact มี id นี้อยู่รึป่าว
        if (contact.includes(param)) {        
            return
        } else {
            contact.push(param);
            contact_count = contact_count + 1;
            totalAmount += detail.node.allAmountNotPaid;
            if (detail) contact_residential.push(detail.node);
        }

        this.setState({
            contact: contact,
            contact_residential: contact_residential,
            contact_count: contact_count,
            totalAmount: totalAmount,
        }, () => this.props.updateCustomerList(this.state.contact,this.state.contact_residential));
    }


    onContactTypeChange = async(typeSelect) => {
        if(typeSelect !== "all") {
            this.setState({
                contact: [],
                contact_residential: [],
                contact_count: 0,
                totalAmount: 0,
            }, () => this.props.updateCustomerList(this.state.contact));
        } else {
            for(let i = 0; i < this.state.allContact.length; i++) {
                let param = this.state.allContact[i].node.id
                let detail = this.state.allContact[i]
                await this.selectAllContact(param, detail) 
            }
        }
    }

    handlePeriodChange(e, date) {
        const isChecked = e.target.checked;
        const periodSelected = this.state.periodSelected && [...this.state.periodSelected];
    
        if (isChecked) {
            periodSelected.push(date.period_date);
        } else {
            const index = periodSelected.indexOf(date.period_date);
            if (index !== -1) {
                periodSelected.splice(index, 1);
            }
        }

        this.setState({
            periodSelected: periodSelected
        },()=>{
            this.props.updateCustomerListAdd(periodSelected)
        });

        this.setState({
            contact: [],
            contact_residential: [],
            contact_count: 0,
            totalAmount: 0,        
        }, () => this.props.updateCustomerList(this.state.contact));
    }
    

    onContactTypeChange() {
        this.setState({
            contact: [],
            contact_residential: [],
            contact_count: 0,
        }, () => this.props.updateCustomerList(this.state.contact,this.state.contact_residential));
    }

    handleInputChange(e) {
        if (e.target.name === "contact_type") {
            this.onContactTypeChange();

            if (e.target.value === 'residential') {
                this.setState({ select_value: e.target.value, payment_type: '', contact_type: 'residential' })
            } else if (e.target.value === 'unknown') {
                this.setState({ select_value: e.target.value, payment_type: e.target.value, contact_type: 'unknown', contact: [''] }, () => this.props.accountType === "other_receiver" && this.props.getContactType(this.state.contact_type))
            } else {
                this.setState({ select_value: e.target.value, payment_type: e.target.value, contact_type: 'supplier', preinvoice:false }, () => this.props.accountType === "invoice" && this.props.updatePreinvoice(this.state.preinvoice))
            }

        }

    }
    
    render() {    
        return (            
            this.state.loading ?
            <Loading />    
                :
            <Translation>
            {
                t =>
            <div className="container-fluid box" id="select-contact">
                <div className="row">
                    <div className="col">
                        <h3 className="mb-4">
                            <Link to={this.props.backLink}>
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                    alt="back" className="back cursor" />
                            </Link>
                            <span>{t('accounting:add_' + this.props.accountType)}</span>

                        </h3>                    
                    </div>
                </div>
                
                <div className="content-inner">
                    <div className="card-add-table-invoice fade-up">
                        
                        {/* selected period */}
                        <div className="row">
                            <div className="col">
                                <strong>{t('batchRt:Monthly Outstanding Payment')}</strong>
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-2'>
                            {this.state.allPeriod.map((date, index) => {
                                const period = JSON.parse(date);
                                const isChecked = this.state.selectedPeriods?.includes(period.period_date);

                                return (                                    
                                        <label className="mr-2" key={index}>
                                            <input 
                                                className="mr-2" 
                                                type="checkbox" 
                                                checked={isChecked}
                                                disabled={this.state.loadingList}
                                                onChange={(e) => this.handlePeriodChange(e, period)}
                                            />
                                            {t('batchRt:month')} {period.period_month}/{period.period_year}
                                        </label>                                                              
                                );
                            })}
                        </div>

                        <div className="row mt-3">
                            <div className="col-3">
                                <select className="form-control" name="contact_type"
                                    onChange={this.handleInputChange}
                                    value={this.state.select_value}
                                    disabled={this.props.disable_select_type}
                                >
                                <React.Fragment>
                                    {!this.props.disableResidential && <Translation>{t => <option value="residential">{t('selectContact:co-owner_member')}</option>}</Translation>}
                                    {!this.props.disableReceivable && <Translation>{t => <option value="receivable">{t('selectContact:debtor_info')}</option>}</Translation>}
                                </React.Fragment>                                    
                                </select>
                            </div>
                        </div>

                        {/* List selected */}             
                        {this.state.select_value === 'residential' && 
                        <ListContactSiriraj 
                            selectContact={this.selectContact}
                            contactSelected={this.state.contact}
                            contactResidentialSelected={this.state.contact_residential}
                            contact_type={this.state.contact_type}
                            contact_count={this.state.contact_count}
                            onContactTypeChange={this.onContactTypeChange}
                            onSelectAllContact={this.onSelectAllContact}
                            accountType={this.props.accountType}
                            role={this.state.role} 
                            periodDate={this.state.periodSelected}
                            allContact={this.state.allContact}
                            loadingList={this.state.loadingList}
                        />}     

                        {(this.state.select_value === 'receivable') &&
                            <ListContactSupplierReceivable 
                                query={query}
                                selectContact={this.selectContact}
                                contactSelected={this.state.contact}
                                contact_type={this.state.contact_type}
                                payment_type={this.state.payment_type}
                                accountType={this.props.accountType}
                            />
                        }


                        {/* Bot summary */}
                        <div className="row mt-3">
                            <div className="col-4">
                                <u onClick={this.onContactTypeChange} className="cursor"><Translation>{t => t('selectContact:clear')}</Translation></u>                                
                            </div>
                            <div className="col-5 d-flex flex-row justify-content-between">
                                <p><b>{t('batchRt:Total Balance')}</b></p>
                                <p><b>{numberWithCommas(this.state.totalAmount)} {t('batchRt:Baht')}</b></p>
                            </div>
                            <div className="col-3 text-right">
                                <div className="btn-group mr-2">
                                    <Link to={this.props.backLink}>
                                        <button type="button" className="btn btn-secondary add">
                                            <Translation>{t => t('selectContact:cancel')}</Translation>
                                        </button>
                                    </Link>
                                </div>

                                <div className="btn-group mr-2">
                                    <Link to={this.props.nextLink}>
                                        <button type="button" className="btn btn-primary add" 
                                            disabled={ !(this.state.contact.length > 0)}>
                                            <Translation>{t => t('selectContact:next')}</Translation>
                                        </button>
                                    </Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            </Translation>            
        )
    }
}

export default SelectContactReceivableSiriraj;