import i18next from 'i18next';
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import "./styles/navigation.scss"

class Navigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: this.props.asset_type
        }
    }

    componentDidUpdate() {
        if (this.state.active !== this.props.asset_type) {
            this.setState({
                active: this.props.asset_type
            })
        }
    }

    render() {
        return (
            <div className="row navigate" id="navigation-asset" style={{ zoom: '80%'}}>
                <NavLink to="/accounting/asset/all"  className="col">
                    <div>
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'all' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/all.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/all.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:All")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/land"  className="col">
                    <div>
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'land' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/land.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/land.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Land")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/building"  className="col">
                    <div>
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'building' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/building.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/building.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Building")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/building_renovation"  className="col">
                    <div >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'building_renovation' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/building_renovation.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/building_renovation.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Building additions and improvements")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/office_decoration"  className="col">
                    <div  >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'office_decoration' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/office_decoration.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/office_decoration.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Office decorations")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/office_equipment"  className="col">
                    <div  >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'office_equipment' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/office_equipment.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/office_equipment.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Office equipment and supplies")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/tools"  className="col">
                    <div  >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'tools' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/tools.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/tools.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Tools / Mechanical equipment")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/training_equipment"  className="col">
                    <div  >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'training_equipment' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/training_equipment.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/training_equipment.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Exercise / Recreational equipment")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/vehicle"  className="col">
                    <div  >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'vehicle' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/vehicle.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/vehicle.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Vehicle")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/common_property"  className="col">
                    <div >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'common_property' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/common_property.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/common_property.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Common property")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/other_asset"  className="col">
                    <div  >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'other_asset' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/other_asset.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/other_asset.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Other assets")}
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to="/accounting/asset/intangible_asset"  className="col">
                    <div >
                        <div className="row">
                            <div className="col text-center">
                                {this.state.active === 'intangible_asset' ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/active/intangible_asset.png'}
                                        className="icon" alt="project-manager-profile" />
                                    :
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/asset-icon/disable/intangible_asset.png'}
                                        className="icon" alt="project-manager-profile" />
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col text-center">
                                {i18next.t("assetTable:Intangible assets")}
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>


        )
    }
}

export default Navigation;