import React, {Component} from 'react';
import './styles/fixRequest.scss';
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {Link} from "react-router-dom";
import { Translation } from 'react-i18next';
import _ from "lodash"
class FixListDetailApprove extends Component {
    onClickPrint() {
    }

    render() {
        return (
            <Translation>
                {
                t =>
            <div className="row">
                <div className="col-md-2"/>
                <div className="col-md-10">
                    <div id="accordion">
                        <div className="card-wrapper">
                            <div className="card-header cursor"
                                 id="headingOne">
                                <div className="row"
                                          data-toggle="collapse"
                                          data-target={"#collapse"+this.props.index}
                                          aria-expanded="false"
                                          aria-controls={"collapse"+this.props.index}>
                                    <div className="col">
                                        <h5 className="mb-0">
                                            <span className="btn mr-4">
                                                {t("fix_request:working_sheet_detail")}
                                            </span>
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_fix_request_print'}) &&
                                            <Link to={"/document/fix-request/" + this.props.room.node.id}>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} className="p-3"
                                                     alt="print-icon" onClick={this.onClickPrint}/>
                                            </Link>}
                                        </h5>
                                    </div>
                                    <div
                                        className="col d-flex align-items-end justify-content-end">
                                        <h5>+</h5>
                                    </div>
                                </div>
                            </div>

                            <div id={"collapse"+this.props.index}
                                 className="collapse">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5>{this.props.room.node.detail}</h5>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-md-4">
                                            <span className="grey-color">{t("fix_request:operation_date")}</span>
                                            <h5>{this.props.room.node.fixRequestRoom.startDate ? format(new Date(this.props.room.node.fixRequestRoom.startDate), 'DD/MM/YYYY', {locale: thLocale}) : '-'}</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <span className="grey-color">{t("fix_request:time")}</span>
                                            {this.props.room.node.fixRequestRoom.startTime && this.props.room.node.fixRequestRoom.endTime &&
                                            <h5>{this.props.room.node.fixRequestRoom.startTime + ' น.'} - {this.props.room.node.fixRequestRoom.endTime + ' น.'}</h5>
                                            }

                                        </div>
                                        <div className="col-md-4">
                                            <span className="grey-color">{t("fix_request:responsible")}</span>
                                            <h5>{this.props.room.node.fixRequestRoom.technicianName ? this.props.room.node.fixRequestRoom.technicianName : '-'}</h5>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-md-3">
                                            <span className="grey-color">{t("fix_request:contact_information")}</span>
                                            {this.props.room.node.fixRequestRoom.contactPerson ?
                                                <h5>{this.props.room.node.fixRequestRoom.contactPerson}</h5>
                                                :
                                                <h5>{this.props.room.node.handle.tenant.firstName} {this.props.room.node.handle.tenant.lastName}</h5>
                                            }
                                        </div>
                                        <div className="col-md-9 d-flex align-items-end">
                                            <div className="form-inline">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/icons/phone-icon.png'}
                                                    alt="phone-icon"
                                                    className="mr-3"/>
                                                {this.props.room.node.phone!=='-'?
                                                    <h5 className="mt-1">{this.props.room.node.phone}</h5>:
                                                    this.props.room.node.fixRequestRoom.contactNumber ?
                                                    <h5 className="mt-1">{this.props.room.node.fixRequestRoom.contactNumber}</h5>
                                                    :
                                                    <h5>{this.props.room.node.handle.tenant.phone}</h5>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }   
            </Translation>
        )
    }
}

export default FixListDetailApprove;