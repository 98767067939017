import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './debtFreeRequest.scss'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { fetchQuery } from "react-relay";
import i18next from 'i18next';
import { Translation } from 'react-i18next';

const query = graphql`
query navigationDebtsQuery{
    totalCountObject {
        totalCountActive
        totalCountPaid
    } 
}`;


class NavigationDebts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalCountActive: 0,
            totalCountPaid: 0
        }
    }

    componentDidMount() {
        fetchQuery(environment, query).then((data) => {
            if (data.totalCountObject) {
                this.setState({
                    totalCountActive: data.totalCountObject.totalCountActive,
                    totalCountPaid: data.totalCountObject.totalCountPaid
                })
            }
        })
    }
    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="debt-free-request">
                        <div className="col navigateTab navigate">
                            <ul className="navigateTab">
                                <li>
                                    <NavLink to="/accounting/debt_free_request/list/all" >
                                        <span className="navigateTab">{i18next.t("PageListPRPO:All")}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/debt_free_request/list/active" >
                                        <span className="navigateTab">{i18next.t("debt_free_request:Waiting for receipts")}</span>
                                        {(this.state.totalCountActive > 0) ?
                                            <p className="float-right font-weight-bold badge text-light bg-danger ml-2">{this.state.totalCountActive}</p> : false}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/debt_free_request/list/edit" >
                                        <span className="navigateTab">{i18next.t("PageListPRPO:Wait Edit")}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/debt_free_request/list/paid" >
                                        <span className="navigateTab">{i18next.t("PageListPRPO:Pending")}</span> {(this.state.totalCountPaid > 0) ?
                                            <p className="float-right font-weight-bold badge text-light bg-danger ml-2">{this.state.totalCountPaid}</p> : false}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/debt_free_request/list/approve" >
                                        <span className="navigateTab">{i18next.t("PageListPRPO:Approve")}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/debt_free_request/list/void" >
                                        <span className="navigateTab">{i18next.t("PageListPRPO:Cancled")}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default NavigationDebts;