/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JuristicNewsNewsType = "ALL" | "JURISTIC" | "TENANT" | "%future added value";
export type JuristicNewsStatus = "DRAFT" | "PUBLISH" | "VOID" | "%future added value";
export type announceDashboardListQueryVariables = {|
  first?: ?number,
  last?: ?number,
  search?: ?string,
  order?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  status?: ?string,
  page?: ?boolean,
|};
export type announceDashboardListQueryResponse = {|
  +allJuristicNews: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +titleEn: ?string,
        +detail: ?string,
        +detailEn: ?string,
        +status: JuristicNewsStatus,
        +creator: ?string,
        +added: any,
        +updated: any,
        +newsType: JuristicNewsNewsType,
        +scheduleTime: ?any,
        +highlight: boolean,
        +group: ?{|
          +id: string,
          +name: string,
          +nameEn: ?string,
        |},
      |}
    |}>
  |}
|};
export type announceDashboardListQuery = {|
  variables: announceDashboardListQueryVariables,
  response: announceDashboardListQueryResponse,
|};
*/


/*
query announceDashboardListQuery(
  $first: Int
  $last: Int
  $search: String
  $order: String
  $start_date: DateTime
  $end_date: DateTime
  $status: String
  $page: Boolean
) {
  allJuristicNews(first: $first, last: $last, search: $search, order: $order, startDate: $start_date, endDate: $end_date, newsStatus: $status, page: $page) {
    edges {
      node {
        id
        title
        titleEn
        detail
        detailEn
        status
        creator
        added
        updated
        newsType
        scheduleTime
        highlight
        group {
          id
          name
          nameEn
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "newsStatus",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      }
    ],
    "concreteType": "JuristicNewsNodeConnection",
    "kind": "LinkedField",
    "name": "allJuristicNews",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNewsNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNewsNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "titleEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detailEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newsType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highlight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicNewsGroupNode",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameEn",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "announceDashboardListQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "announceDashboardListQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "5bf42a5c3656bdceff8ce4f8d5ceb49a",
    "id": null,
    "metadata": {},
    "name": "announceDashboardListQuery",
    "operationKind": "query",
    "text": "query announceDashboardListQuery(\n  $first: Int\n  $last: Int\n  $search: String\n  $order: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $status: String\n  $page: Boolean\n) {\n  allJuristicNews(first: $first, last: $last, search: $search, order: $order, startDate: $start_date, endDate: $end_date, newsStatus: $status, page: $page) {\n    edges {\n      node {\n        id\n        title\n        titleEn\n        detail\n        detailEn\n        status\n        creator\n        added\n        updated\n        newsType\n        scheduleTime\n        highlight\n        group {\n          id\n          name\n          nameEn\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '68e78bed21e617834634f7663ef41348';

module.exports = node;
