/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createUpdateVehicleQueryVariables = {|
  category?: ?string
|};
export type createUpdateVehicleQueryResponse = {|
  +allCarBrand: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +brandName: string,
        +carmodelnameSet: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +carModelName: string,
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type createUpdateVehicleQuery = {|
  variables: createUpdateVehicleQueryVariables,
  response: createUpdateVehicleQueryResponse,
|};
*/


/*
query createUpdateVehicleQuery(
  $category: String
) {
  allCarBrand(category: $category) {
    edges {
      node {
        id
        brandName
        carmodelnameSet {
          edges {
            node {
              id
              carModelName
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "category"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "category",
        "variableName": "category"
      }
    ],
    "concreteType": "CarBrandNodeConnection",
    "kind": "LinkedField",
    "name": "allCarBrand",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CarBrandNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CarBrandNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CarModelNameNodeConnection",
                "kind": "LinkedField",
                "name": "carmodelnameSet",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CarModelNameNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CarModelNameNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "carModelName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUpdateVehicleQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUpdateVehicleQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8732e6ec5edb6e09260a79024db68e42",
    "id": null,
    "metadata": {},
    "name": "createUpdateVehicleQuery",
    "operationKind": "query",
    "text": "query createUpdateVehicleQuery(\n  $category: String\n) {\n  allCarBrand(category: $category) {\n    edges {\n      node {\n        id\n        brandName\n        carmodelnameSet {\n          edges {\n            node {\n              id\n              carModelName\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a440c428865e318c88f2d3c6a76a7638';

module.exports = node;
