/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentGatewayGroupStatus = "ACTIVE" | "VOID" | "%future added value";
export type paymentGatewayListAllQueryVariables = {||};
export type paymentGatewayListAllQueryResponse = {|
  +allPaymentGatewayGroup: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +status: PaymentGatewayGroupStatus,
        +docNumber: string,
        +depositDate: any,
        +description: ?string,
        +paymentGatewayTransaction: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +bank: {|
                +bankName: string,
                +accountNumber: string,
              |},
            |}
          |}>
        |},
        +depositTotal: number,
        +feeTotal: number,
      |}
    |}>,
    +totalCount: ?number,
  |},
  +selfProject: ?{|
    +timeZone: ?string
  |},
|};
export type paymentGatewayListAllQuery = {|
  variables: paymentGatewayListAllQueryVariables,
  response: paymentGatewayListAllQueryResponse,
|};
*/


/*
query paymentGatewayListAllQuery {
  allPaymentGatewayGroup {
    edges {
      node {
        id
        status
        docNumber
        depositDate
        description
        paymentGatewayTransaction {
          edges {
            node {
              id
              bank {
                bankName
                accountNumber
                id
              }
            }
          }
        }
        depositTotal
        feeTotal
      }
    }
    totalCount
  }
  selfProject {
    timeZone
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositTotal",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feeTotal",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentGatewayListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaymentGatewayGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPaymentGatewayGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentGatewayGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PaymentGatewayGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaymentGatewayTransactionNodeConnection",
                    "kind": "LinkedField",
                    "name": "paymentGatewayTransaction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaymentGatewayTransactionNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PaymentGatewayTransactionNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BankAccountNode",
                                "kind": "LinkedField",
                                "name": "bank",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "paymentGatewayListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaymentGatewayGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPaymentGatewayGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentGatewayGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PaymentGatewayGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaymentGatewayTransactionNodeConnection",
                    "kind": "LinkedField",
                    "name": "paymentGatewayTransaction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaymentGatewayTransactionNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PaymentGatewayTransactionNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BankAccountNode",
                                "kind": "LinkedField",
                                "name": "bank",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6829081c48554fbea9605c6a1677e88d",
    "id": null,
    "metadata": {},
    "name": "paymentGatewayListAllQuery",
    "operationKind": "query",
    "text": "query paymentGatewayListAllQuery {\n  allPaymentGatewayGroup {\n    edges {\n      node {\n        id\n        status\n        docNumber\n        depositDate\n        description\n        paymentGatewayTransaction {\n          edges {\n            node {\n              id\n              bank {\n                bankName\n                accountNumber\n                id\n              }\n            }\n          }\n        }\n        depositTotal\n        feeTotal\n      }\n    }\n    totalCount\n  }\n  selfProject {\n    timeZone\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04c9016ebb890f90ec1a44031855c87b';

module.exports = node;
