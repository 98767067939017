/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type invoiceTemplete3QueryVariables = {||};
export type invoiceTemplete3QueryResponse = {|
  +userConfig: ?{|
    +id: string,
    +languages: string,
  |}
|};
export type invoiceTemplete3Query = {|
  variables: invoiceTemplete3QueryVariables,
  response: invoiceTemplete3QueryResponse,
|};
*/


/*
query invoiceTemplete3Query {
  userConfig {
    id
    languages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserConfigNode",
    "kind": "LinkedField",
    "name": "userConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "languages",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "invoiceTemplete3Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "invoiceTemplete3Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d4471458f4bb580af21482492323697c",
    "id": null,
    "metadata": {},
    "name": "invoiceTemplete3Query",
    "operationKind": "query",
    "text": "query invoiceTemplete3Query {\n  userConfig {\n    id\n    languages\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f33296d22c359758f690797234afa051';

module.exports = node;
