/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountUnit = "DAY" | "MONTH" | "%future added value";
export type GlobalBankAccountStatus = "ACTIVE" | "DEACTIVATE" | "%future added value";
export type bankCreateModalQueryVariables = {||};
export type bankCreateModalQueryResponse = {|
  +bankAccountViewer: ?{|
    +backCodeNext: ?string
  |},
  +lastBankAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +notiStatus: boolean,
        +numberOfUnit: ?number,
        +unit: BankAccountUnit,
      |}
    |}>
  |},
  +allGlobalBankAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +logo: string,
        +status: GlobalBankAccountStatus,
      |}
    |}>
  |},
  +myUser: ?{|
    +id: string,
    +username: string,
    +juristic: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
      +image: ?string,
      +email: string,
      +phone: string,
    |},
  |},
|};
export type bankCreateModalQuery = {|
  variables: bankCreateModalQueryVariables,
  response: bankCreateModalQueryResponse,
|};
*/


/*
query bankCreateModalQuery {
  bankAccountViewer {
    backCodeNext
    id
  }
  lastBankAccount {
    edges {
      node {
        notiStatus
        numberOfUnit
        unit
        id
      }
    }
  }
  allGlobalBankAccount(status: "active") {
    edges {
      node {
        id
        name
        logo
        status
      }
    }
  }
  myUser {
    id
    username
    juristic {
      id
      firstName
      lastName
      image
      email
      phone
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backCodeNext",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notiStatus",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfUnit",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": "active"
    }
  ],
  "concreteType": "GlobalBankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allGlobalBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalBankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GlobalBankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allGlobalBankAccount(status:\"active\")"
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "myUser",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicNode",
      "kind": "LinkedField",
      "name": "juristic",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "bankCreateModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNodeConnection",
        "kind": "LinkedField",
        "name": "lastBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "bankCreateModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNodeConnection",
        "kind": "LinkedField",
        "name": "lastBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/)
    ]
  },
  "params": {
    "cacheID": "6cfcbfa085299a3a61c781e6ed599d17",
    "id": null,
    "metadata": {},
    "name": "bankCreateModalQuery",
    "operationKind": "query",
    "text": "query bankCreateModalQuery {\n  bankAccountViewer {\n    backCodeNext\n    id\n  }\n  lastBankAccount {\n    edges {\n      node {\n        notiStatus\n        numberOfUnit\n        unit\n        id\n      }\n    }\n  }\n  allGlobalBankAccount(status: \"active\") {\n    edges {\n      node {\n        id\n        name\n        logo\n        status\n      }\n    }\n  }\n  myUser {\n    id\n    username\n    juristic {\n      id\n      firstName\n      lastName\n      image\n      email\n      phone\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e46cb815abf0ae9cc60173a47debc4ef';

module.exports = node;
