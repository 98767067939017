import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";


class PettyCashReportRecordTable extends React.Component {

    render() {
        return (
            <tr key={this.props.petty_cash_record.id}>
                <td/>
                <td/>
                <td/>
                <td/>
                <td className="text-center">{format(this.props.petty_cash_record.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                <td className="text-center">{this.props.petty_cash_record.docNumber}</td>
                <td className="text-center">{this.props.petty_cash_record.chartOfAccount}</td>
                <td>{this.props.petty_cash_record.description}</td>
                <td className="text-right">{numberWithComma(this.props.petty_cash_record.price)}</td>
                <td className="text-right">{numberWithComma(this.props.total)}</td>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
            </tr>
        )
    }
}

export default PettyCashReportRecordTable;
