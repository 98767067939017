/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HouseHolderType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type ResidentialVoteStatus = "APPROVE" | "NOT_APPROVE" | "NOT_VOTE" | "%future added value";
export type surveyDetailQueryVariables = {|
  search?: ?string,
  first?: ?number,
  last?: ?number,
  status?: ?string,
  surveyId: string,
|};
export type surveyDetailQueryResponse = {|
  +surveyResidentialVote: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +residential: {|
          +id: string,
          +name: string,
          +size: number,
          +residentialHouseholder: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +type: HouseHolderType,
                +id: string,
                +tenant: {|
                  +firstName: string,
                  +lastName: ?string,
                |},
              |}
            |}>
          |},
        |},
        +status: ResidentialVoteStatus,
      |}
    |}>,
  |}
|};
export type surveyDetailQuery = {|
  variables: surveyDetailQueryVariables,
  response: surveyDetailQueryResponse,
|};
*/


/*
query surveyDetailQuery(
  $search: String
  $first: Int
  $last: Int
  $status: String
  $surveyId: ID!
) {
  surveyResidentialVote(search: $search, first: $first, last: $last, status: $status, surveyId: $surveyId) {
    totalCount
    edges {
      node {
        id
        residential {
          id
          name
          size
          residentialHouseholder {
            edges {
              node {
                type
                id
                tenant {
                  firstName
                  lastName
                  id
                }
              }
            }
          }
        }
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "surveyId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  },
  {
    "kind": "Variable",
    "name": "surveyId",
    "variableName": "surveyId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "surveyDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ResidentialVoteNodeConnection",
        "kind": "LinkedField",
        "name": "surveyResidentialVote",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialVoteNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialVoteNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeConnection",
                        "kind": "LinkedField",
                        "name": "residentialHouseholder",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HouseHolderNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TenantNode",
                                    "kind": "LinkedField",
                                    "name": "tenant",
                                    "plural": false,
                                    "selections": [
                                      (v11/*: any*/),
                                      (v12/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "surveyDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ResidentialVoteNodeConnection",
        "kind": "LinkedField",
        "name": "surveyResidentialVote",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialVoteNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialVoteNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeConnection",
                        "kind": "LinkedField",
                        "name": "residentialHouseholder",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HouseHolderNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TenantNode",
                                    "kind": "LinkedField",
                                    "name": "tenant",
                                    "plural": false,
                                    "selections": [
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2046bca0112432b6331d3574f3dbdf8f",
    "id": null,
    "metadata": {},
    "name": "surveyDetailQuery",
    "operationKind": "query",
    "text": "query surveyDetailQuery(\n  $search: String\n  $first: Int\n  $last: Int\n  $status: String\n  $surveyId: ID!\n) {\n  surveyResidentialVote(search: $search, first: $first, last: $last, status: $status, surveyId: $surveyId) {\n    totalCount\n    edges {\n      node {\n        id\n        residential {\n          id\n          name\n          size\n          residentialHouseholder {\n            edges {\n              node {\n                type\n                id\n                tenant {\n                  firstName\n                  lastName\n                  id\n                }\n              }\n            }\n          }\n        }\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94e5214b21563ce03010df88e6a06d3b';

module.exports = node;
