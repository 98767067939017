import React, {Component} from 'react';
import { Translation } from 'react-i18next';
import {Link} from "react-router-dom";

class ListGroupSetting extends Component {

    render() {
        return (
            <div className="list-group">
                {/*<Link to="/setting/account-setting">
                    <button type="button"
                            className={this.props.page === 'account_setting' ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}>
                        ตั้งค่าบัญชี
                    </button>
                </Link>*/}
                {this.props.page ==='permission_setting' &&
                    <Link to="/setting/juristic-setting">
                        <Translation>
                            {t=>
                                <button type="button"
                                        className={this.props.page === 'permission_setting' ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}>
                                    {t("setting:permission_setting")}
                                </button>
                            }
                        </Translation>
                    </Link>
                }
                {/*<Link to="/setting/SettingReplyAutoChat">
                    <button type="button"
                            className={this.props.page === 'auto_message_setting' ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}>
                        ระบบตอบอัตโนมัติ
                    </button>
                </Link>*/}
            </div>
        )
    }
}

export default ListGroupSetting;
