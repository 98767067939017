import '../report/report.scss';
import React from 'react';
import _ from "lodash";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { Dropdown } from "react-bootstrap";
import numberWithComma from "../../libs/numberWithCommaReport";
import axios from 'axios';
import getApiRoot from "../../libs/getAPIRoot";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const query = graphql`
    query workSheetExcelExportQuery($startDate: DateTime, $endDate: DateTime, $betweenStartId: String, $betweenEndId: String) {
        selfProject {
            name
        }
        allActiveChartOfAccount(startDate: $startDate, endDate: $endDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {
            edges {
              node {
                id
                chartOfAccountCode
                name
                totalDebit
                totalCredit
                totalHistoricalDebit
                totalHistoricalCredit
                totalPeriodDebit
                totalPeriodCredit
                balanceDebit
                balanceCredit
                balance
                update
              }
            }
        }
    }
`;

const WorkSheetExcelExport = ({ start_date, end_date, chart_of_account_start, chart_of_account_end }) => {
  let noManualJV = "{\"update_debit\": \"0.0\", \"update_credit\": \"0.0\", \"before_update_debit\": \"0\", \"before_update_credit\": \"0\"}";
  const getData = async () => {
    let res = await fetchQuery(environment, query, { startDate: start_date, endDate: end_date, betweenStartId: chart_of_account_start, betweenEndId: chart_of_account_end })
    if (res) { 
      let allActiveChartOfAccounts = res.allActiveChartOfAccount.edges.filter((n) => {
        return ((n.node.balanceCredit !== 0 || n.node.balanceDebit !== 0) && n.node.update !== noManualJV)
      })
      downloadExcel(res.selfProject, allActiveChartOfAccounts)
    } else {
      console.log('error ');
    }
  }

  const downloadExcel = async (selfProject, allActiveChartOfAccount) => {

    var token_id = localStorage.getItem("token");
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');
    // setWidthcolumns
    let columns = [{ width: 15 }, { width: 60 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]
    // setBorder
    let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    let fontSizes = { size: 11.5 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let textRight = { vertical: 'middle', horizontal: 'right' };
    let textLeft = { vertical: 'middle', horizontal: 'left' };
    let fontBold = { size: 11.5, bold: true };
    
    let lastIndex = allActiveChartOfAccount.length
    let accountStartFrom = allActiveChartOfAccount[0]?.node.name;
    let accountEndBy = allActiveChartOfAccount[lastIndex - 1]?.node.name

    worksheet.getCell('A1').value = selfProject.name
    worksheet.getCell('A1').font = { size: 11.5, bold: true };

    worksheet.getCell('A2').value = "กระดาษทำการ"
    worksheet.getCell('A2').font = { size: 11.5, bold: true };

    worksheet.getCell('A3').value = "บัญชี " + accountStartFrom + (accountStartFrom === accountEndBy? "": " ถึง " + accountEndBy ) 
    worksheet.getCell('A3').font = { size: 11.5, bold: true };

    worksheet.getCell('A4').value = `จากวันที่ ${format(start_date, 'DD/MM/YYYY', { locale: thLocale })} ถึง ${format(end_date, 'DD/MM/YYYY', { locale: thLocale })}`
    worksheet.getCell('A4').font = { size: 11.5, bold: true };
    worksheet.addRow();
    var headerRow = worksheet.addRow();
    let headers = ['กระดาษทำการ', 'งบทดลองก่อนปรับปรุง', 'รายการปรับปรุง', 'งบทดลองหลังปรับปรุง', 'งบรายรับ - รายจ่าย', 'งบแสดงฐานะทางการเงิน']
    const rowCount = worksheet.rowCount;
    worksheet.mergeCells(`A${rowCount}:B${rowCount}`);
    worksheet.mergeCells(`C${rowCount}:D${rowCount}`);
    worksheet.mergeCells(`E${rowCount}:F${rowCount}`);
    worksheet.mergeCells(`G${rowCount}:H${rowCount}`);
    worksheet.mergeCells(`I${rowCount}:J${rowCount}`);
    worksheet.mergeCells(`K${rowCount}:L${rowCount}`);

    let count = 1
    headers.forEach((item, index) => {
      let cell = headerRow.getCell(count);
      cell.value = item;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
      count += 2
    })
    let headers2 = ['เลขผังบัญชี	', 'ชื่อบัญชี	', 'เดบิต', 'เครดิต', 'เดบิต', 'เครดิต', 'เดบิต', 'เครดิต', 'เดบิต', 'เครดิต', 'เดบิต', 'เครดิต']
    var headerRow = worksheet.addRow();
    headers2.forEach((item, index) => {
      let cell = headerRow.getCell(index + 1);
      cell.value = item;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
    })


    let data = { data: [], style: [] }
    let unadjusted_trial_balance_debit = 0
    let unadjusted_trial_balance_credit = 0
    let adjusted_entry_debit = 0
    let adjusted_entry_credit = 0
    let adjusted_trial_balance_debit = 0
    let adjusted_trial_balance_credit = 0
    let income_expenses_debit = 0
    let income_expenses_credit = 0
    let statement_of_financial_position_debit = 0
    let statement_of_financial_position_credit = 0

    await allActiveChartOfAccount.map(async (chart, index) => {
      let code = _.get(chart, "node.chartOfAccountCode").slice(0, 1)
      let balanceDebit = _.get(chart, "node.balanceDebit")
      let balanceCredit = _.get(chart, "node.balanceCredit")
      let balance = _.get(chart, "node.balance")
      let update = JSON.parse(_.get(chart, "node.update"))

      balanceDebit > balanceCredit ? adjusted_trial_balance_debit += balance : adjusted_trial_balance_credit += balance

      if (code === '4' || code === "5") {
        balanceDebit > balanceCredit ? income_expenses_debit += balance : income_expenses_credit += balance
      } else if (code === '1' || code === "2" || code === "3") {
        balanceDebit > balanceCredit ? statement_of_financial_position_debit += balance : statement_of_financial_position_credit += balance
      }

      adjusted_entry_debit += parseFloat(update.update_debit)
      adjusted_entry_credit += parseFloat(update.update_credit)
      unadjusted_trial_balance_debit += parseFloat(update.before_update_debit)
      unadjusted_trial_balance_credit += parseFloat(update.before_update_credit)

      var dataRow = worksheet.addRow();
      data = {
        data: [
          chart.node.chartOfAccountCode,
          chart.node.name,
          numberWithComma(parseFloat(update.before_update_debit)),
          numberWithComma(parseFloat(update.before_update_credit)),
          numberWithComma(parseFloat(update.update_debit)),
          numberWithComma(parseFloat(update.update_credit)),
          balanceDebit > balanceCredit ? numberWithComma(balance) : "-",
          balanceDebit > balanceCredit ? "-" : numberWithComma(balance),
          code === '4' || code === '5' ? balanceDebit > balanceCredit ? numberWithComma(balance) : "-" : "-",
          code === '4' || code === '5' ? balanceDebit > balanceCredit ? "-" : numberWithComma(balance) : "-",
          code === '1' || code === '2' || code === '3' ? balanceDebit > balanceCredit ? numberWithComma(balance) : "-" : "-",
          code === '1' || code === '2' || code === '3' ? balanceDebit > balanceCredit ? "-" : numberWithComma(balance) : "-"
        ],
        style: [{ alignment: textLeft }, { alignment: textLeft }, { alignment: textRight }, { alignment: textRight }, { alignment: textRight }, { alignment: textRight },
        { alignment: textRight }, { alignment: textRight }, { alignment: textRight }, { alignment: textRight }, { alignment: textRight }, { alignment: textRight }]
      }


      headers2.map((header, index_header) => {
        let cell = dataRow.getCell(index_header + 1);
        cell.value = data.data[index_header];
        cell.alignment = data.style[index_header]?.alignment;
        cell.border = borders
        cell.font = fontSizes
      })

      if (allActiveChartOfAccount.length === index + 1) {
        // let cell = dataRow.getCell(index_header + 1);
        const rowCount = worksheet.rowCount + 1;
        worksheet.mergeCells(`A${rowCount}:B${rowCount}`);
        worksheet.getCell(`A${rowCount}`).value = "รวม";
        worksheet.getCell(`A${rowCount}`).border = borders
        worksheet.getCell(`A${rowCount}`).alignment = textCenter
        worksheet.getCell(`A${rowCount}`).font = fontBold

        worksheet.getCell(`C${rowCount}`).value = numberWithComma(unadjusted_trial_balance_debit);
        worksheet.getCell(`C${rowCount}`).border = borders
        worksheet.getCell(`C${rowCount}`).alignment = textRight
        worksheet.getCell(`C${rowCount}`).font = fontBold

        worksheet.getCell(`D${rowCount}`).value = numberWithComma(unadjusted_trial_balance_credit);
        worksheet.getCell(`D${rowCount}`).border = borders
        worksheet.getCell(`D${rowCount}`).alignment = textRight
        worksheet.getCell(`D${rowCount}`).font = fontBold

        worksheet.getCell(`E${rowCount}`).value = numberWithComma(adjusted_entry_debit);
        worksheet.getCell(`E${rowCount}`).border = borders
        worksheet.getCell(`E${rowCount}`).alignment = textRight
        worksheet.getCell(`E${rowCount}`).font = fontBold

        worksheet.getCell(`F${rowCount}`).value = numberWithComma(adjusted_entry_credit);
        worksheet.getCell(`F${rowCount}`).border = borders
        worksheet.getCell(`F${rowCount}`).alignment = textRight
        worksheet.getCell(`F${rowCount}`).font = fontBold

        worksheet.getCell(`G${rowCount}`).value = numberWithComma(adjusted_trial_balance_debit);
        worksheet.getCell(`G${rowCount}`).border = borders
        worksheet.getCell(`G${rowCount}`).alignment = textRight
        worksheet.getCell(`G${rowCount}`).font = fontBold

        worksheet.getCell(`H${rowCount}`).value = numberWithComma(adjusted_trial_balance_credit);
        worksheet.getCell(`H${rowCount}`).border = borders
        worksheet.getCell(`H${rowCount}`).alignment = textRight
        worksheet.getCell(`H${rowCount}`).font = fontBold

        worksheet.getCell(`I${rowCount}`).value = numberWithComma(income_expenses_debit);
        worksheet.getCell(`I${rowCount}`).border = borders
        worksheet.getCell(`I${rowCount}`).alignment = textRight
        worksheet.getCell(`I${rowCount}`).font = fontBold

        worksheet.getCell(`J${rowCount}`).value = numberWithComma(income_expenses_credit);
        worksheet.getCell(`J${rowCount}`).border = borders
        worksheet.getCell(`J${rowCount}`).alignment = textRight
        worksheet.getCell(`J${rowCount}`).font = fontBold

        worksheet.getCell(`K${rowCount}`).value = numberWithComma(statement_of_financial_position_debit);
        worksheet.getCell(`K${rowCount}`).border = borders
        worksheet.getCell(`K${rowCount}`).alignment = textRight
        worksheet.getCell(`K${rowCount}`).font = fontBold

        worksheet.getCell(`L${rowCount}`).value = numberWithComma(statement_of_financial_position_credit);
        worksheet.getCell(`L${rowCount}`).border = borders
        worksheet.getCell(`L${rowCount}`).alignment = textRight
        worksheet.getCell(`L${rowCount}`).font = fontBold
      }

    })

    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = format(new Date(), 'DD/MM/YYYY', { locale: thLocale })
        let formData = new FormData();
        formData.append('file', blob , "report.xlsx");
        formData.append('type', "excel");
        formData.append('type_report_ref', "work_sheet_excel_export_report");
        axios.post(`${getApiRoot()}taskdownload/add_download`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `JWT ${token_id}`,
          }
        })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
        })
        .catch(error => {
          console.error('There was an error sending the file to the server:', error);
        })
        .finally(() => {
          // Download the file after sending it to the API
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = nameFile;
          a.click();
        });

      });
  }


  return (
    <Dropdown.Item  ><p className="text-black" onClick={getData} >Excel</p></Dropdown.Item>
  );
};

export default WorkSheetExcelExport;