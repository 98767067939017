import '../../../document/styles/main.scss';
import React, {Component} from 'react';
import {format} from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import _ from "lodash";
import invoiceViewerQuery from '../createRequest/invoiceViewerQuery';
import { Translation } from 'react-i18next';
const ThaiBaht = require('thai-baht-text');

class DebtFreeRequest extends Component {

    constructor(props){
        super(props);
        this.state = {
            sum : 0,
            document : _.toLower(this.props.props.node.attorneyBook) === 'document' ? true : _.toLower(this.props.props.node.attorneyBook) === 'document_and_id_card' ? true : false,
            id_card : _.toLower(this.props.props.node.attorneyBook) === 'id_card' ? true : _.toLower(this.props.props.node.attorneyBook) === 'document_and_id_card' ? true : false,
        }
    }

    async componentDidMount(){
        let listInvoice = []
        listInvoice = await invoiceViewerQuery([this.props.props.node.contact.id])
        let sum = 0;
        if(listInvoice.invoiceViewer.allInvoiceByStatus.edges.length > 0){
            sum = _.sumBy(listInvoice.invoiceViewer.allInvoiceByStatus.edges , 'node.total')
        }  
        this.setState({
            sum : sum
        })
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.props.node.attorneyBook !== this.props.props.node.attorneyBook){
            this.switchAttorneyTypeToBoolean()
        }
    }

    switchAttorneyTypeToBoolean = () => {
        if(_.toLower(this.props.props.node.attorneyBook) === 'document'){
            this.setState({
                document : true,
                id_card : false
            })
        }else if(_.toLower(this.props.props.node.attorneyBook) === 'id_card'){
            this.setState({
                document : false,
                id_card : true
            })
        }else if(_.toLower(this.props.props.node.attorneyBook) === 'id_card'){
            this.setState({
                document : true,
                id_card : true
            })
        }
    }

    render() {
        
        return (
            <React.Fragment key={this.props.props.node.id}>
                <div className="print-page-a4" id="debtRequest" >
                    <div className="subpage pl-4 pr-4">
                        <div className="head "style={{marginBottom:'40px'}}>                  
                            <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                                <div style={{minHeight:100,minWidth:100}}> 
                                    <img src={this.props.selfProject.logo} alt="silverman"/>
                                </div>
                                <div>  
                                    <div>
                                        <div className='address-header' style={{fontSize:'13px'}}><strong>{this.props.selfProject.name}</strong></div>
                                        <Translation>{t=><div className="address-header" style={{fontSize:'13px'}}>{this.props.selfProject.address} </div>}</Translation>
                                        <Translation>{t=><div className="address-header"style={{fontSize:'13px'}}>{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                    </div>
                                    <Translation>{t=><div style={{fontSize:'13px'}}>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                                </div> 
                            </div>
                        </div>
                        <br/>
                        <div className="row col-12 d-flex justify-content-center"> 
                            <strong>แบบฟอร์มขอหนังสือรับรองหนี้</strong>
                        </div>
                        <div className="row col-12  d-flex justify-content-center"> 
                            <strong>Request Debt Free Certification</strong>
                        </div>
                        <div className='row col-12' style={{marginLeft:'5px'}}>
                            <div className='col-8 mt-4'>
                            </div>
                            <div className='col-4 mt-4'>
                                <strong>เลขที่/No. : </strong>
                                {/* support -> เปลี่ยนจากวันที่กดพิมพ์เอกสารมาเป็นวันที่ ระบุในหนังสือรับรองการปลอดหนี้จากตอนสร้างคำร้อง */}
                                <span>{this.props.props.node.docNumber}</span>
                            </div>
                        </div>
                        <div className='row col-12' style={{marginLeft:'5px'}}>
                            <div className='col-8 mt-1'>
                            </div>
                            <div className='col-4 mt-1'>
                                <strong>วันที่/Date : </strong>
                                {/* support -> เปลี่ยนจากวันที่กดพิมพ์เอกสารมาเป็นวันที่ ระบุในหนังสือรับรองการปลอดหนี้จากตอนสร้างคำร้อง */}
                                <span>{format(new Date(this.props.props.node.added),'DD/MM/YYYY')}</span>
                            </div>
                        </div>
                        <div className="row col-12 mt-1 d-flex justify-content-start detail-request">
                            <span><strong>ข้าพเจ้า</strong> {this.props.props.node.tenant.nameTitle}{this.props.props.node.tenant.firstName} {this.props.props.node.tenant.lastName}</span>
                        </div>
                        <div className="row col-12 mt-1 d-flex justify-content-start"> 
                            <span><strong>เจ้าของห้องชุดเลขที่</strong> {this.props.props.node.contact.name}</span>
                        </div>
                        <div className="row col-12 mt-1 d-flex justify-content-start"> 
                            <span><strong>ขนาดห้องชุด /ตร.ม.</strong> {this.props.props.node.contact.residential.size}</span>
                        </div>
                        <div className="row col-12 mt-1 d-flex justify-content-start"> 
                            <span><strong>ค่าใช้จ่ายค้างชำระ ณ วันที่</strong> {format(new Date() , 'DD/MM/YYYY')}   <strong>จำนวนเงิน</strong> {this.state.sum === 0 ? '0.00' : numberWithComma(this.state.sum,0.00)}  บาท</span>
                        </div>
                        <div className="row col-12 mt-1 d-flex justify-content-start"> 
                            <span><strong>ตัวอักษร</strong> {this.state.sum === 0 ? 'ศูนย์บาทถ้วน' : ThaiBaht(this.state.sum)}</span>
                        </div>
                        <br/>
                        <div className="row col-12 mt-3 d-flex margin justify-content-start detail-indent"> 
                            <span>&emsp;&emsp;&emsp;&emsp;ข้าพเจ้ามีความประสงค์ให้ระบุวันที่ออกหนังสือปลอดหนี้เป็นวันที่ {format(this.props.props.node.requestingDate,'DD/MM/YYYY')}  เพื่อโอนกรรมสิทธิ์ห้องชุด ให้แก่ {this.props.props.node.nameTransfer === null ? '................................................' : this.props.props.node.nameTransfer}  เบอร์โทรศัพท์ {this.props.props.node.phoneTransfer || '................................................'} </span>
                        </div>
                        <div className="row col-12 mt-1 d-flex justify-content-start"> 
                            <span>&emsp;&emsp;&emsp;&emsp;ได้แนบเอกสารเพื่อขอหนังสือรับรองการปลอดหนี้พร้อมรับรองสำเนาถูกต้อง ดังนี้</span>
                        </div>
                        <br/>


                        <div style={{marginLeft:'55px'}}>
                            <div className="row col-12 mt-4 d-flex justify-content-start form-inline"> 
                                <input type="checkBox" className="mr-2" checked={this.props.props.node.copyIdCard}  disabled={true}/> 
                                <span>สำเนาบัตรประชาชนเจ้าของห้องชุดผู้รับโอนกรรมสิทธิ์</span>
                            </div>
                            <div className="row col-12 mt-1 d-flex justify-content-start form-inline"> 
                                <input type="checkBox" className="mr-2" checked={this.props.props.node.copyHouse} disabled={true} /> <span>สำเนาทะเบียนบ้านเจ้าของห้องชุดผู้รับโอนกรรมสิทธิ์</span>
                            </div>
                            <div className="row col-12 mt-1 d-flex justify-content-start form-inline"> 
                                <input type="checkBox" className="mr-2" checked={this.props.props.node.copyApartment} disabled={true} /> <span>สำเนาหนังสือกรรมสิทธิ์ห้องชุด</span>
                            </div>
                            <div className="row col-12 mt-1 d-flex justify-content-start form-inline"> 
                                <input type="checkBox" className="mr-2" checked={this.props.props.node.copySurnameChange}  disabled={true}/> <span>สำเนาเอกสารการเปลี่ยนชื่อ-สกุล (ถ้ามี)</span>
                            </div>
                            <div className="row col-12 mt-1 d-flex justify-content-start form-inline"> 
                                <input type="checkBox" className="mr-2"  disabled={true} checked={this.state.document}/> <span>หนังสือมอบอำนาจ (กรณีดำเนินการแทน)</span>
                            </div>
                            <div className="row col-12 mt-1 d-flex justify-content-start form-inline"> 
                                <input type="checkBox" className="mr-2"  disabled={true} checked={this.state.id_card}/> <span>สำเนาบัตรประชาชนผู้รับมอบอำนาจ (กรณีดำเนินการแทน)</span>
                            </div>
                            <div className="row col-12 mt-1 d-flex justify-content-start form-inline"> 
                                <input type="checkBox" className="mr-2" checked={this.props.props.node.otherDocument}  disabled={true}/> <span>เอกสารอื่นๆ โปรดระบุ {this.props.props.node.otherDocumentDescription} </span>
                            </div>
                            <div className="row col-12 mt-1 d-flex justify-content-start form-inline"> 
                                <input type="checkBox" className="mr-2" checked={this.props.props.node.waterAndElectric}  disabled={true}/> <span>มีความประสงค์​แจ้งให้ระงับประปาและไฟฟ้าในวันที่ {this.props.props.node.startSuspend !== null ? format(this.props.props.node.startSuspend,'DD/MM/YYYY') : ' - '} ถึง {this.props.props.node.endSuspend !== null ? format(this.props.props.node.endSuspend,'DD/MM/YYYY') : ' - '} </span>
                            </div>
                            {this.props.props.node.fee &&
                                <div className="row col-12 mt-1 d-flex justify-content-start form-inline"> 
                                    <input type="checkBox" className="mr-2" checked={true}  disabled={true}/> <span>ชำระค่าธรรมเนียมขอหนังสือรับรองการปลอดหนี้ {this.props.props.node.charge} บาท/ชุด</span>
                                </div>
                            }
                        </div>
                        <div className="row mt-4 d-flex justify-content-start">  
                            <div className="col-2">
                                <strong>หมายเหตุ</strong>
                            </div>
                            <div className="col-10">
                                {this.props.props.node.fee && <strong>1. ค่าธรรมเนียมขอหนังสือรับรองการปลอดหนี้ {this.props.props.node.charge} บาท/ชุด <br/></strong>}
                                <strong>{this.props.props.node.fee ? '2.' : '1.'} หนังสือรับรองการปลอดหนี้มีผลใช้รับรองหนี้ได้ ภายใน {this.props.props.node.lifetime} วัน นับตั้งแต่วันที่ระบุในหนังสือรับรองการปลอดหนี้</strong>
                            </div>
                        </div>
                        <div className="row footer">
                        <div className="col-6"><strong>ลงชื่อ .................................................. เจ้าหน้าที่ผู้รับเรื่อง</strong></div>
                        <div className="col-6"><strong >ลงชื่อ ............................................. เจ้าของห้องชุด/ผู้ขอ</strong></div>
                        </div>
                    </div>
                    
                </div>
            </React.Fragment>
        )
    }                   
}

export default DebtFreeRequest;