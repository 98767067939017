import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import Navigation from "./navigation";

import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { graphql } from "babel-plugin-relay/macro";

import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import ContractServiceListTable from "./contractServiceListTable";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import { Translation } from "react-i18next";
import ExportContractService from "./exportContractService";

const query = graphql`
  query contractServiceListQuery(
    $status: String
    $search: String
    $first: Int
    $last: Int
    $startDate: Date
    $endDate: Date
  ) {
    allContractRevenue(
      status: $status
      search: $search
      startDate: $startDate
      endDate: $endDate
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node {
          id
          docNumber
          voidDate
          voider
          voidRemark
          issuedDate
          debtor {
            id
            refNumber
            name
          }
          contractNumber
          status
          total
          chartOfAccount {
            name
          }
          startContractDate
          endContractDate
          creator
          added
        }
      }
    }
  }
`;

class ContractServiceList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      temp_start_date: null,
      temp_end_date: null,
      search: "",
      search_input: "",
      advance_search: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.setState({
      status: this.props.match.params.status,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="receive-list">
            <div className="row justify-content-between">
              <div className="col">
                <Translation>
                  {(t) => (
                    <h3 className="mb-4">
                      <Link to="/accounting">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/wrapperContent/back.png"
                          }
                          alt="back"
                          className="back"
                        />
                      </Link>
                      {t("contract_service:Contract Service")+ " "+"(CS)"}
                    </h3>
                  )}
                </Translation>
              </div>

              <div className="col container-btn-export-contractService">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_contract_revenue_create",
                }) && (
                  <div>
                    <ExportContractService
                      query={query}
                      search={this.state.search}
                      start_date={this.state.start_date}
                      end_date={this.state.end_date}
                      status={this.props.match.params.status}
                    />
                    <Link to="/accounting/income/contract_service/form/select_customer">
                      <Translation>
                        {(t) => (
                          <button
                            type="button"
                            className="btn btn-primary add float-right"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/plus.png"
                              }
                              alt="plus"
                            />
                            {t("contract_service:Contract Service Add")}
                          </button>
                        )}
                      </Translation>
                    </Link>
                  </div>
                )}

                {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "accounting_contract_service_create",}) && (
                  <Link to="/accounting/income/contract_service/form/select_customer">
                    <Translation>
                      {t=>
                      <button type="button" className="btn btn-primary add float-right">
                        <img src={process.env.PUBLIC_URL + "/images/icons/plus.png"} alt="plus"/>
                        {t("contract_service:Contract Service")}
                      </button>}
                    </Translation>
                  </Link>
                )} */}
              </div>
            </div>
            <div className="content-inner">
              <Navigation />

              <div className="row mb-2 mt-1">
                <div className="col-12 text-right">
                  <Translation>
                    {(t) => (
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={t("contract_service:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {t("contract_service:Search")}
                        </button>
                      </div>
                    )}
                  </Translation>

                  <InlineAdvanceFilter
                    start_date={this.state.temp_start_date}
                    end_date={this.state.temp_end_date}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <ContractServiceListTable
                    query={query}
                    search={this.state.search}
                    start_date={this.state.start_date}
                    end_date={this.state.end_date}
                    status={this.props.match.params.status}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ContractServiceList;
