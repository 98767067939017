import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Translation} from "react-i18next";
import i18next from 'i18next';



class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/finance/cheque/list/all">
                               <Translation>
                                    {
                                        t => <span>{t('cheque:All')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/finance/cheque/list/wait">
                                <Translation>
                                    {
                                        t => <span>{t('cheque:cheque_wait')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/accounting/finance/cheque/list/clearing">
                                <Translation>
                                    {
                                        t => <span>{t('cheque:cheque_clearing')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/accounting/finance/cheque/list/bide">
                                <Translation>
                                    {
                                        t => <span>{i18next.t("ChequeTransaction:Cheque Pending Clearance")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/accounting/finance/cheque/list/void">
                                <Translation>
                                    {
                                        t => <span>{i18next.t("ChequeTransaction:Cheque Returned")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/accounting/finance/cheque/list/cancel">
                                <Translation>
                                    {
                                        t => <span>{i18next.t("ChequeTransaction:Cancel Passed Through Cheque Payment")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/accounting/finance/cheque/list/historical">
                                <Translation>
                                    {
                                        t => <span>{i18next.t("ChequeTransaction:Incoming Cheque Payment")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/accounting/finance/cheque/list/cancelhistorical">
                                <Translation>
                                    {
                                        t => <span>{i18next.t("ChequeTransaction:Cancel Incoming Cheque")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;