import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import thLocale from "date-fns/locale/th";
import _ from 'lodash';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Dropdown } from 'react-bootstrap';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNewBold.ttf',
        italics: 'THSarabunNewItalic.ttf',
        bolditalics: 'THSarabunNewBoldItalic.ttf'
    },
    Sarabun: {
        normal: 'Sarabun-Light.ttf',
        bold: 'Sarabun-Bold.ttf',
        italics: 'Sarabun-Italic.ttf',
        bolditalics: 'Sarabun-BoldItalic.ttf'
    }
}

const ExportKeycardPDF = (state) => {
    const getKeyCardName = (dataKey) => {
        let text = ""
        dataKey.map((keyeCard) => {
            text = keyeCard.node.keyCardActive ? text + " " + keyeCard.node.keyCardCode : text
        })
        return text;
    }

    const generatePdf = () => {
        let startItem = 0
        let dataMapExport = []
        _.map(state.dataExport, (item) => {
            startItem++
            return dataMapExport.push(
                [
                    { text: startItem, alignment: 'center', heihgt: 40, maxHeight: 40, fontSize: 10 },
                    { text: item.node.name, alignment: 'center', heihgt: 40, maxHeight: 40, fontSize: 10 },
                    { text: getKeyCardName(item.node.keyCard.edges), heihgt: 40, maxHeight: 40, fontSize: 10 },
                ]
            )
        })

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait', //portrait ตั้ง  || landscape นอน
            pageMargins: [20, 20, 20, 20],
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [0, 0],
                        },
                        {
                            text: `ผู้พิมพ์ ${state.namePrint} วันที่พิมพ์ ${format(new Date(), "DD/MM/YYYY")}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [20, 0],
                        },
                    ],
                };
            },
            content: [
                { text: state.project_name, style: 'header' },
                { text: 'สรุปรายการคีย์การ์ด', style: 'header' },
                { text: format(new Date(), "ณ DD MMMM YYYY", { locale: thLocale }), style: 'header' },
                { text: "", margin: [0, 0, 0, 10] },
                {
                    table: {
                        headerRows: 1,
                        widths: [30, 50, 450],
                        dontBreakRows: true, // ใช้สำหรับ ให้แถวสุดท้ายที่ตกบรรทัดให้ ขึ้นหน้าใหม่แทน
                        body: [
                            [
                                { text: 'ลำดับ', alignment: 'center', bold: true, fontSize: 10 },
                                { text: 'บ้านเลขที่/ห้องเลขที่', alignment: 'center', bold: true, fontSize: 10 },
                                { text: 'รายการเลขคีย์การ์ด', alignment: 'center', bold: true, fontSize: 10 },
                            ],
                            ...dataMapExport
                        ],
                    },
                    layout: 'auto',
                },
            ],
            defaultStyle: {
                font: 'Sarabun'
            },
            styles: {
                header: {
                    fontSize: 14,
                    bold: true
                },
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
            let preview = URL.createObjectURL(blob)
            window.open(preview)
        });
    };

    return (
        <Link
            to="route"
            target="blank"
            onClick={(event) => {
                event.preventDefault();
                generatePdf();
            }}>
            <Dropdown.Item>
                PDF
            </Dropdown.Item>
        </Link>
    );


}

export default ExportKeycardPDF;