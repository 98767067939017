import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../../env/environment";
const query = graphql`
  query summaryReportOutstandingReceivableQuery($dueDate: Date!, $typeOfContact: String) {
    summaryReportOutstandingReceivable(dueDate: $dueDate, typeOfContact: $typeOfContact){
      numRow
      sumBalance
    }
  }
`;

export const summaryReportOutstandingReceivable = async (variables, response) => {
  await fetchQuery(environment, query, variables)
    .then((data) => {
      if (data) {
        response(data);
      }
    })
    .catch((error) => {
      console.log('fetchQuery error');
      response(null);
    });
};
