import React from "react";
import PieChart from "./pieChart";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../libs/loading";
import {Translation} from 'react-i18next';
import { Link } from 'react-router-dom'

const query = graphql`
    query incomeGraphQuery ($startYear: Int ,$startMonth: Int) {
        productViewer {
            # productSummaryByStatusYear(year: $startYear, productType: "service", productType2: "servicefine") {
            #     edges {
            #         node {
            #             id
            #             name
            #             productCode
            #             colAllTotal
            #             colAllPaid
            #             colAllNotPay
            #         }
            #     }
            # }
            # newProductSummaryByStatusYear(year: $startYear, productType: "service", productType2: "servicefine")
            productSummaryByStatusYearAndMonth(year: $startYear , month: $startMonth, productType: "service", productType2: "servicefine")
        }
    }
`;

const month_list_arr = ["-","January","February","March","April","May","June","July","August","September","October","November","December"];

class IncomeGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year_list: [],
            selected_year: new Date().getFullYear(),
            month_list: [],
            selected_month: 0, // Default 0 เป็นเลือกทั้ง 12 เดือน หรือ ทั้งปี
        }
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        let year = new Date().getFullYear();
        let year_list = [];
        let month_list = [];
        for (let i=0;i<11;i++) {
            year_list.push(year - i);
        }
        this.setState({year_list});     
        month_list.push(...month_list_arr);
        this.setState({month_list});      
    }

    handleInputChange(e) {
        const selectedIndex = e.target.options.selectedIndex;
        e.target.name==="selected_year"?
        this.setState({[e.target.name]: e.target.value}):
        this.setState({[e.target.name]: e.target.options[selectedIndex].getAttribute('data-key')});
    }



    render() {      
        return (
            <React.Fragment>
                <div className="row align-items-baseline">
                    <div className="ml-4">
                    <Translation>
                        {
                            t => <h6>{t('dashboard:Billing in year')}</h6>
                        }
                    </Translation>
                    </div>
                    <div className="ml-2" style={{minWidth: "65px"}}>
                        <select name="selected_year" className="form-control dropdown-custom-arrow"
                                value={this.state.selected_year} onChange={this.handleInputChange}>
                            {this.state.year_list.map(value => {
                                return (
                                    <option value={value} key={value}>
                                        {value}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
           
                    <div className="ml-6">
                    <Translation>
                        {
                            t => <h6>{t('dashboard:Billing in month')}</h6>
                        }
                    </Translation>
                    </div>
                    <div className="ml-2" style={{minWidth: "68px"}}>
                        <select name="selected_month" className="form-control dropdown-custom-arrow" 
                                value={month_list_arr[this.state.selected_month]} onChange={this.handleInputChange}>
                            {this.state.month_list.map((value,i) => {
                                return (              
                                    <Translation>    
                                        { 
                                            t =>                                                                                                    
                                                <option value={value} key={i} data-key={i}>
                                                        {t(`dashboard:${value}`)}                     
                                                </option>  
                                        }           
                                    </Translation>                                                          
                                )
                            })}
                        </select>
                    </div> 

                    <div className="ml-6">
                        <Link
                            to={{
                                pathname :  `/home/reportDashboardSummary`,
                                state: {
                                    year : this.state.selected_year,
                                    month : this.state.selected_month
                                }
                            }}
                        >         
                        <button
                            type="button"
                            style={{float: 'bottom'}}
                            className="btn btn-outline-secondary btn-md"
                        >
                            <Translation>
                                    {
                                        t => <>{t('dashboard:Print')}</>
                                    }
                            </Translation>
                        </button>
                        </Link>           

                        
                    </div>      
                </div>

                <div className="row mt-6">
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        cacheConfig={{use_cache: false}}
                        variables={{
                                    startYear: this.state.selected_year,
                                    startMonth: this.state.selected_month
                                }}
                        render={({error, props}) => {
                            if (props) {
                                // return <React.Fragment>{_.sortBy(props.productViewer.productSummaryByStatusYear.edges,'node.productCode').map((product) => {
                                // return <React.Fragment>{JSON.parse(JSON.stringify(props.productViewer.newProductSummaryByStatusYear)).map((products) => {
                                return <React.Fragment>{JSON.parse(JSON.stringify(props.productViewer.productSummaryByStatusYearAndMonth)).map((products) => {
                                    let product = JSON.parse(products)
                                    if (product.col_all_total >0) {
                                        return (
                                            <div className="col-xl-3 col-md-4" key={product.id}>
                                                <div className="chart text-center w-auto">
                                                    <PieChart data={product}/>
                                                </div>
                                            </div>
                                        )
                                        
                                    }else{
                                        return <></>
                                    }
                                })}</React.Fragment>
                            } else {
                                return <Loading/>
                            }
                        }}/>
                </div>
                <div className="row ml-6">
                        <div className="graph-description">
                            <ul>
                                <Translation>
                                    {
                                        t => <li>{t('dashboard:Paid')}</li>
                                    }
                                </Translation>
                                <Translation>
                                    {
                                        t => <li>{t('dashboard:Unpaid')}</li>
                                    }
                                </Translation>
                            </ul>
                        </div>
                </div>
            </React.Fragment>
        )
    }
}


export default IncomeGraph;
