import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import _ from 'lodash';
import { Translation } from "react-i18next";
import i18next from "i18next";

const asset_type = [
    {
        code: "land",
        header_name: "LAN - ที่ดิน",
    },
    {
        code: "building",
        header_name: "B - อาคาร",
    },
    {
        code: "building_renovation",
        header_name: "BR - ส่วนต่อเติมและปรับปรุงอาคาร",
    },
    {
        code: "office_decoration",
        header_name: "OD - เครื่องตกแต่งสำนักงาน",
    },
    {
        code: "office_equipment",
        header_name: "EQ - อุปกรณ์และเครื่องใช้สำนักงาน",
    },
    {
        code: "tools",
        header_name: "T - เครื่องมือ/อุปกรณ์งานช่าง",
    },
    {
        code: "training_equipment",
        header_name: "TE - เครื่องออกกำลังกาย/สันทนาการ",
    },
    {
        code: "vehicle",
        header_name: "V - ยานพาหนะ",
    },
    {
        code: "common_property",
        header_name: "CP - ทรัพย์สินส่วนกลาง",
    },
    {
        code: "intangible_asset",
        header_name: "ITG - สินทรัพย์ไม่มีตัวตน",
    },
    {
        code: "other_asset",
        header_name: "OA - สินทรัพย์อื่นๆ",
    },
];
class AssetDepreciateReportTable extends React.Component {
    render() {
        // let order_id = this.props.state.first - localStoragePage.getPageLimit();
        let order_id = 0;
        let assetEachType = _.chain(this.props.props.allAssetReport.edges).groupBy('node.type').map((asset,index)=> {
            return ({  
                total_costPrice : _.sumBy(asset,'node.costPrice'), //สินทรัพย์
                total_assetDepreciate : _.sumBy(asset,'node.depreciationAmount'), //ค่าเสื่อมสะสม
                asset : asset,
                type : _.sumBy(asset,'node.type'), 
            })
        }).value();
        let sum_costPrice = _.sumBy(this.props.props.allAssetReport.edges,'node.costPrice');//สินทรัพย์
        let sum_assetDepreciate = 0.0; //ค่าเสื่อม
        let sum_historyPreviousAmount = 0.0;
        let sum_historyNextAmount = 0.0;
        let count_list = assetEachType.length
        return (
            <Translation>
                {t => 
                    <React.Fragment>
                        {assetEachType.map((asset_each_type,index) => {
                            let total_historyPreviousAmount = 0.0; //ค่าเสื่อมราคายกมา
                            let total_historyNextAmount = 0.0; //ค่าเสื่อมราคาสะสม
                            sum_assetDepreciate += asset_each_type.total_assetDepreciate;
                            return (
                                <React.Fragment key = {index}>
                                    {asset_each_type.asset.map((asset,index) => {
                                        total_historyPreviousAmount += asset.node.historyPreviousAmount+asset.node.historicalAmount;
                                        total_historyNextAmount += asset.node.historyNextAmount  +asset.node.historicalAmount;
                                        sum_historyPreviousAmount += asset.node.historyPreviousAmount+asset.node.historicalAmount;
                                        sum_historyNextAmount += asset.node.historyNextAmount+asset.node.historicalAmount;
                                        return (
                                            <React.Fragment key ={asset.node.id + 'depreciate'}>
                                                {index === 0 &&
                                                    <tr className="table-active" key="head"> 
                                                        <td colSpan={17}>{_.find(asset_type,{code: asset.node.type.toLowerCase()}).header_name}</td>
                                                    </tr> 
                                                }
                                                <tr key={asset.node.id}>
                                                    <td className="text-center" width="100">{order_id+ index + 1}</td>
                                                    <td className="text-center">{asset.node.docNumber}</td>
                                                    <td>{asset.node.name}</td>
                                                    <td className="text-center">{asset.node.purchaseDate ? format(asset.node.purchaseDate, 'DD/MM/YYYY', {locale: thLocale}): '-'}</td>
                                                    <td className="text-right">{numberWithComma(asset.node.costPrice,'0.00')}</td>
                                                    <td className="text-right">{asset.node.depreciationRatePercent ? numberWithComma(asset.node.depreciationRatePercent,'0.00') : '0.00'}</td>
                                                    <td className="text-right">{asset.node.calDepreciation ? numberWithComma(asset.node.historyPreviousAmount + asset.node.historicalAmount,'0.00') : '0.00'}</td>
                                                    <td className="text-right">{asset.node.calDepreciation ? numberWithComma(asset.node.depreciationAmount, '0.00') : '0.00'}</td>
                                                    <td className="text-right">{asset.node.calDepreciation ?  numberWithComma(asset.node.historyNextAmount +asset.node.historicalAmount, '0.00') : '0.00'}</td>
                                                    <td className="text-center">{asset.node.saleDate ? format(asset.node.saleDate, 'DD/MM/YYYY', {locale: thLocale}) : '-'}</td>
                                                    <td className="text-center">{asset.node.saleStatus !== 'ACTIVE' ? t('asset:'+ _.lowerCase(asset.node.saleStatus)) : '-'}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                    }
        
                                    <tr key ="foot">
                                        <td colSpan={3}/>
                                        <td className="text-right"><strong>{i18next.t("ReportAssetDetail:Sum")}</strong></td>
                                        <td className="text-right"><strong>{numberWithComma(asset_each_type.total_costPrice,'0.00')}</strong></td>
                                        <td/>
                                        <td className="text-right"><strong>{numberWithComma(total_historyPreviousAmount,'0.00')}</strong></td>
                                        <td className="text-right"><strong>{numberWithComma(asset_each_type.total_assetDepreciate,'0.00')}</strong></td>
                                        <td className="text-right"><strong>{numberWithComma(total_historyNextAmount,'0.00')}</strong></td>
                                        <td/>
                                        <td/>
                                    </tr>
                                </React.Fragment>
                            )
                        })
                        }
                        {this.props.state.type === '' && this.props.props.allAssetReport.edges.length > 0 && count_list > 1 &&
                            <tr key ="foot">
                            <td colSpan={3}/>
                            <td className="text-right"><strong>{i18next.t("ReportAssetDetail:Total")}</strong></td>
                            <td className="text-right"><strong>{numberWithComma(sum_costPrice,'0.00')}</strong></td>
                            <td/>
                            <td className="text-right"><strong>{numberWithComma(sum_historyPreviousAmount,'0.00')}</strong></td>
                            <td className="text-right"><strong>{numberWithComma(sum_assetDepreciate,'0.00')}</strong></td>
                            <td className="text-right"><strong>{numberWithComma(sum_historyNextAmount,'0.00')}</strong></td>
                            <td/>
                            <td/>
                        </tr>
        
                        }
        
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default AssetDepreciateReportTable;
