import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {Redirect} from 'react-router'
import ReceiptDepositCreateForm from "../../income/receipt_deposit/receiptDepositCreateForm";
import Loading from '../../../libs/loading';

const prepaidDepositForm = graphql`
    query prepaidDepositCreateFormQuery($id: ID!) {
        contact(id: $id) {
            id
            refNumber
            name
            firstName
            lastName

            registeredAddress
            registeredCountry
            registeredProvince
            registeredDistrict
            registeredCity
            registeredPostalCode
            typeOfContact
            typeOfSupplier
            vat
        }
        viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: "1152") {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
        selfProject{
            haveVat
        }
    }
`;

class PrepaidForm extends Component {

    render() {
        if (this.props.customerList.length > 0) {
            return (
                <QueryRenderer
                        environment={environment}
                        query={prepaidDepositForm}
                        variables={{id: this.props.customerList[0]}}
                        cacheConfig={{use_cache: false}}
                        render={({error, props}) => {
                            if (props) {
                                const whtopen = props.contact.typeOfSupplier === "FOUNDATION" || props.contact.typeOfSupplier === "ASSOCIATION" ? false : true;
                                const vatopen = props.contact.typeOfSupplier === "FOUNDATION" || props.contact.typeOfSupplier === "ASSOCIATION" ? false : true;
                                return <ReceiptDepositCreateForm customerList={this.props.customerList}
                                                             contact={props.contact} receiptDepositQuery={props}
                                                             query={prepaidDepositForm}
                                                             category={this.props.category}
                                                             headerWord={this.props.headerWord}
                                                             backLink={this.props.backLink}
                                                             openVat={vatopen}
                                                             openWht={whtopen}
                                                             project_have_vat={props.selfProject.haveVat}
                                                             isHasAgentRole={false}
                            />;
                            } else {
                                return <Loading/>
                            }
                        }}
                    />
            )
        }
        return <Redirect to="/accounting/expense/prepaid-deposit/form/select_customer"/>;
    }
}

export default PrepaidForm;
