import React, {Component} from 'react';
import {Link} from "react-router-dom";
import SearchSelect from "../../../libs/searchSelect";
import CreateAndUpdateProductMutation from './mutations/CreateAndUpdateProductMutation'
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
// import DatePickerAdapter from "../../../libs/datePickerAdapter";
import { Translation } from 'react-i18next';
import i18n from 'i18next';
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';
import Switch from "react-switch";
import SetFormatNumber from './setFormatNumber';
import "./style/productCreateAndUpdate.scss"
import i18next from 'i18next';

const _ = require('lodash');

const customStyles = {
    control: (style, { isDisabled }) => ({
      ...style,
      height: 48,
      minHeight: 48,
      backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF',
    }),
    indicatorSeparator: (style) => ({
      ...style,
      display: 'none'
    }),
};

class ProductCreateAndUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            edit_mod: false,
            product: {
                id: '',
                name: '',
                productCode: 'PXXX',
                cost: '',
                creditorChartOfAccount: {
                    id: ''
                },
                creditorChartOfAccountBuy: {
                    id: ''
                },
                price: '',
                chartOfAccount: {
                    id: ''
                },
                customerChartOfAccount: {
                    id: ''
                },
                allChartOfAccount:{
                    id:''
                },
                noNumber: false,
                collect: false,
                autoOrder: false,
                reorderPoint: null,
                type: 'product',
                description: ''
            },
            autoOrderText: "",
            noNumberText: "noHave",
            bring_forward_list: [],
            bring_edit_list: [],
            numberKey:[],
            bring_forward_object: {
                id: '',
                price: '',
                amount: '',
                date: new Date(),
                customCha: "",
                customNum: "",
                importKeycard: [],
            },
            itemShowFormat: [],
            indexBringForwardList: null,
            getPageLimit: 50,
            page: 0,
            creditorChartOfAccountBuyCheckFirst: false,
            creditorChartOfAccountCheckFirst: false,
            isHaveNumberOrder: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onDeleteRowBringForward = this.onDeleteRowBringForward.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentWillMount() {
        if (this.props.product) {
            let account_ing = _.filter(this.props.product?.productTransaction.edges,['node.list',"CONVERSION_BALANCE"]);
            let queryProduct = this.props.product

            let newProduct = {
                id: queryProduct.id,
                name: queryProduct.name,
                productCode: queryProduct.productCode,
                cost: queryProduct.cost,
                creditorChartOfAccount: {
                    id: queryProduct.creditorChartOfAccount?.id
                },
                creditorChartOfAccountBuy: {
                    id: queryProduct.creditorChartOfAccountBuy?.id
                },
                price: queryProduct.price,
                chartOfAccount: {
                    id: queryProduct.chartOfAccount.id
                },
                customerChartOfAccount: {
                    id: queryProduct.customerChartOfAccount.id
                },
                allChartOfAccount:{
                    id: queryProduct.chartOfAccount.id
                },
                noNumber: false,
                collect: queryProduct.collect,
                autoOrder: false,
                reorderPoint: queryProduct.reorderPoint,
                type: 'product',
                description: queryProduct.description
            }

            if(account_ing?.length > 0){
                this.setState({
                    product: newProduct, 
                    bring_edit_list: account_ing , 
                    numberKey: this.props.product.numberKey.edges,
                    edit_mod: false,
                    noNumberText: this.props.product.noNumber ? "have" : "noHave",
                    autoOrderText: this.props.product.autoOrder ? "auto" : "custom",
                    creditorChartOfAccountBuyCheckFirst: newProduct.creditorChartOfAccount?.id,
                    creditorChartOfAccountCheckFirst: newProduct.creditorChartOfAccount?.id,
                    isHaveNumberOrder : this.props.product.noNumber ? true : false
                })
            }else{
                this.setState({
                    product: newProduct,
                    bring_forward_list: [{...this.state.bring_forward_object}] , 
                    edit_mod: true,
                    noNumberText: this.props.product.noNumber ? "have" : "noHave",
                    autoOrderText: this.props.product.autoOrder ? "auto" : "custom",
                    creditorChartOfAccountBuyCheckFirst: newProduct.creditorChartOfAccount?.id,
                    creditorChartOfAccountCheckFirst: newProduct.creditorChartOfAccount?.id,
                    isHaveNumberOrder : this.props.product.noNumber ? true : false

                })
            }

        } else {
            // create
            this.setState({bring_forward_list: [{...this.state.bring_forward_object}] , edit_mod: true})
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.product.chartOfAccount.id !== this.state.product.chartOfAccount.id){
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    'product.allChartOfAccount.id',
                    this.state.product.chartOfAccount.id
                );
            });
        }
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        if(e.target.dataset.key === "bring_edit_list"){
            let bring_edit_list = _.cloneDeep(this.state.bring_edit_list);
            // console.log(bring_edit_list[e.target.dataset.id].node['price']);
            if(e.target.name.includes('price')){
                bring_edit_list[e.target.dataset.id].node['price'] = event.target.value
            }
            else if(e.target.name.includes('amount')){
                bring_edit_list[e.target.dataset.id].node['amount'] = event.target.value
            }
            else if(e.target.name.includes('date')){
                bring_edit_list[e.target.dataset.id].node['date'] = event.target.value
            }
            else if(e.target.name.includes('customCha')){
                bring_edit_list[e.target.dataset.id].node['customCha'] = event.target.value
            }
            else if(e.target.name.includes('customNum')){
                bring_edit_list[e.target.dataset.id].node['customNum'] = event.target.value
            }
            this.setState({bring_edit_list:[...bring_edit_list]})
        }
        else{
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    event.target.name,
                    value
                );
            });
        }
    }

    handleInputChangeItems = (event, index) => {

        let { name, value } = event.target;

        if(name.includes('amount')){
            let newKeycardArr = []
            this.setKeycardArr(newKeycardArr, value)
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    `bring_forward_list[${index}].importKeycard`,
                    newKeycardArr
                );
            })
        }

        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                name,
                value
            );
        });
    }

    setKeycardArr = (newKeycardArr, numInput) => {

        for (let index = 0; index < numInput; index++) {
            newKeycardArr.push({node :{ noNumber: "" }})
        }


        return newKeycardArr
    }

    onDeleteRowBringForward(index) {
        if(this.state.bring_forward_list.length > 0){
            this.setState(prevState => {
                let bring_forward_list_new = prevState.bring_forward_list;
                _.pullAt(bring_forward_list_new, [index]);

                return _.set(
                    _.cloneDeep(prevState),
                    'bring_forward_list',
                    bring_forward_list_new
                );
            })
        }else{
            this.setState(prevState => {
                let bring_forward_list_edit_new = prevState.bring_edit_list;
                _.pullAt(bring_forward_list_edit_new, [index]);

                return _.set(
                    _.cloneDeep(prevState),
                    'bring_edit_list',
                    bring_forward_list_edit_new
                );
            })         
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});

        let bringForwardList = []
        let productInput = _.clone(this.state.product);
        let warnNull = []
        let checkWarnNull = false
        _.set(productInput, "noNumber", this.state.noNumberText === "have" ? true : false)
        
        if(this.state.noNumberText === "have"){

            if(this.state.autoOrderText === "custom"){
                _.forEach(this.state.bring_forward_list, (item, indexItem) => {
                    let newImportKeycard = []
                    let keycardTextRow = ""
                    _.forEach(item.importKeycard, (itemKeycard, indexItemKeycard) => {
                        newImportKeycard.push(itemKeycard.node.noNumber)

                        if(itemKeycard.node.noNumber === ""){
                            checkWarnNull = true
                            keycardTextRow = keycardTextRow + `ลำดับที่ ${indexItemKeycard+1} `
                        }
                    })


                    warnNull.push({
                        bringForwardRow: `ครั้งที่ ${indexItem+1}`,
                        keycardRow: keycardTextRow,
                    })
    
                    bringForwardList.push({
                        id: item.id,
                        date: item.date,
                        price: item.price,
                        amount: item.amount,
                        importKeycard: newImportKeycard,
                    })
                })
                _.set(productInput, "autoOrder", false)
               
                
            }else if(this.state.autoOrderText === "auto"){
    
                _.forEach(this.state.bring_forward_list, (item, indexItem) => {
                    let newImportKeycard = []
                    for (let index = 0; index < item.amount; index++) {

                        let number = parseInt(item.customNum) + index
                        let customNum = item.customNum
                        customNum = customNum.substring(0, customNum.length - number.toString().length);
                        customNum = customNum + number
                        newImportKeycard.push(item.customCha + customNum)
                        // newImportKeycard.push(`${item.customCha}${parseInt(item.customNum)+index}`)
                    }
    
                    bringForwardList.push({
                        id: item.id,
                        date: item.date,
                        price: item.price,
                        amount: item.amount,
                        importKeycard: newImportKeycard,
                    })
                })
                _.set(productInput, "autoOrder", true)
            }
        }else if(this.state.noNumberText === "noHave"){
            _.forEach(this.state.bring_forward_list, (item, indexItem) => {
                bringForwardList.push({
                    id: item.id,
                    date: item.date,
                    price: item.price,
                    amount: item.amount,
                    importKeycard: [],
                })
            })
        }

        if(!checkWarnNull){
            let input = {
                product: JSON.stringify(productInput),
                bringForwardList: JSON.stringify(bringForwardList),
                bringEditList: JSON.stringify(this.state.bring_edit_list),
            };
            this.setState({loading: false});
            Swal.fire({
                title: i18next.t("productCreateAndUpdate:Once saved, some data cannot be edited."),
                type: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: i18next.t("Allaction:Yes"),
            }).then((result) => {
                if (result.value) {
                    CreateAndUpdateProductMutation(input, (response) => this.onCompleted(response), (response) => this.onError(response))
                }
            })

        }else{
            let textWarn = ""

            _.forEach(warnNull, (warn) => {
                if(warn.keycardRow){
                    textWarn = textWarn + `${warn.bringForwardRow} ` + `${warn.keycardRow} <br/> `
                }
            })

            this.setState({loading: false});
            Swal.fire(i18next.t("productCreateAndUpdate:Please fill in the product numbers for the brought forward balance completely"), textWarn, 'warning')

        }
    }

    onCompleted(response) {
        this.setState({loading: false});
        if (response.createAndUpdateProduct.newProduct.id) {
            Swal.fire(i18n.t("add_product:Save successfully!"), '', 'success').then(() => {
                this.setState({redirectToList: true})
            });
        }
    }

    onError(response) {
        this.setState({loading: false});
        Swal.fire('Error!', i18n.t("add_product:Please try again"), 'warning')
    }

    switchInput = (inputSwitch) => {
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'product.collect',
                inputSwitch
            );
        });
    }

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({page: page})
    }

    handleOpenFormat = (index, importKeycard) => {
        this.setState({
            indexBringForwardList: index,
            itemShowFormat: importKeycard,
            page: 0
        })
    }

    handleClose = (index, numberKeycard, nameSet) => {

        let newKeycardArr = []

        this.setKeycardArr(newKeycardArr, numberKeycard)
                
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                `bring_forward_list[${index}].importKeycard`,
                newKeycardArr
            );
        })
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/inventory/product/list/product"/>
        }

        return (
            <div id="productCreateAndUpdate">
                <Translation>
                    {t=>
                    <>
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="mb-4 content-inner">
                                    <Link to="/accounting/inventory/product/list/product">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back"/>
                                    </Link>
                                   {t("add_product:Add product")}
                            </h4>
                        </div>
                        <div className="col-md-6 text-right">
                            <div className="row form-group">
                                <h5 className="col col-form-label">
                                    {t("add_product:Product No.")}
                                </h5>
                                <div className="col">
                                    <input className="form-control text-center" value={this.state.product.productCode}
                                           disabled/>
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-inner">
                        <form id="form-add" onSubmit={this.onSubmit}>
                            <div className="form-group row">
                                <h5 htmlFor="product_name"
                                       className="col-1 col-form-label mt-4">{t("add_product:Product name")}</h5>
                                <div className="col-6">
                                    <p className="text-danger" style={{fontSize:'11px' ,marginBottom:'8px'}}> {t("add_product:*Enter text not over 80 character characters")} {this.state.product.name.length}/80 </p>
                                    <input type="text" className="form-control" id="product_name"
                                           name="product.name" onChange={this.handleInputChange}
                                           value={this.state.product.name}
                                           required
                                           maxLength={80}/> 
                                </div>
                            </div>
    
                            {/*ข้อมูลการซื้อ*/}
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group row">
                                        <h5 className="col-2 col-form-label">{t("add_product:Purchase information")}</h5>
                                        <h5 htmlFor="buy_unit_cost"
                                               className="col-2 col-form-label">{t("add_product:Purchase price per unit")}</h5>
                                        <div className="col-2">
                                            <input type="number" step="0.01" className="form-control"
                                                   id="buy_unit_cost"
                                                   name="product.cost" onChange={this.handleInputChange}
                                                   value={this.state.product.cost}
                                                   required/>
                                        </div>
                                    </div>
    
                                    <div className="row mt-3 form-group">
                                        <div className="col-2"/>
                                        <div className="col-2"> 
                                            <h5>{t("add_product:Recorded account")}</h5>
                                        </div>
                                        <div className="col-5">
                                            <SearchSelect onChange={this.handleInputChange}
                                                          value={this.state.product.creditorChartOfAccount?.id}
                                                          name="product.creditorChartOfAccount.id" placeholder="รายการ"
                                                          queryObject={this.props.chart_of_account.group5.edges}
                                                          keyOfValue="id" require={true}
                                                          keyOfLabel="chartOfAccountCode:name"
                                                          disabled={this.props.project_not_edit_account && this.state.creditorChartOfAccountCheckFirst}
                                                          style={customStyles}
                                            />
                                        </div>
                                        <div className="col-3"/>
                                    </div>

                                    <div className="row mt-3 form-group">
                                        <div className="col-2"/>
                                        <div className="col-2">
                                            <h5>{t("productCreateAndUpdate:Creditor")}</h5>
                                        </div>
                                        <div className="col-5">
                                            < SearchSelect onChange={this.handleInputChange}
                                                          value={this.state.product.creditorChartOfAccountBuy?.id}
                                                          name="product.creditorChartOfAccountBuy.id" placeholder="รายการ"
                                                          queryObject={this.props.chart_of_account.group2.edges}
                                                          keyOfValue="id" require={true}
                                                          keyOfLabel="chartOfAccountCode:name"
                                                          disabled={this.props.project_not_edit_account && this.state.creditorChartOfAccountBuyCheckFirst}
                                                          style={customStyles}
                                            />
                                        </div>
                                        <div className="col-3"/>
                                    </div>
                                </div>
                            </div>
    
                            {/*ข้อมูลการขาย*/}
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="form-group row">
                                        <h5 className="col-2 col-form-label">
                                            {t("add_product:Sales data")}
                                        </h5>
                                        <h5 htmlFor="sale_unit_cost"
                                               className="col-2 col-form-label">{t("add_product:Selling price per unit")}</h5>
                                        <div className="col-2">
                                            <input type="number" step="0.01"
                                                   className="form-control"
                                                   id="sale_unit_cost"
                                                   name="product.price"
                                                   onChange={this.handleInputChange}
                                                   value={this.state.product.price}
                                                   required/>
                                        </div>
                                        <div className="col-2"/>
                                    </div>
    
                                    <div className="row mt-3 form-group">
                                        <div className="col-2"/>
                                        <div className="col-2">
                                            <h5>{t("add_product:Recorded account")}</h5>
                                        </div>
                                        <div className="col-5">
                                            <SearchSelect onChange={this.handleInputChange}
                                                          value={this.state.product.chartOfAccount.id}
                                                          name="product.chartOfAccount.id" placeholder="รายการ"
                                                          queryObject={this.props.chart_of_account.group4.edges}
                                                          keyOfValue="id" require={true}
                                                          keyOfLabel="chartOfAccountCode:name"
                                                          disabled={this.props.project_not_edit_account}
                                                          style={customStyles}
                                            />
                                        </div>
                                        <div className="col-3"/>
                                    </div>
    
                                    <div className="row mt-3 form-group">
                                        <div className="col-2"/>
                                        <div className="col-2">
                                            <h5>{t("add_product:Debtor")}</h5>
                                        </div>
                                        <div className="col-5">
                                            <SearchSelect onChange={this.handleInputChange}
                                                          value={this.state.product.customerChartOfAccount.id}
                                                          name="product.customerChartOfAccount.id" placeholder="รายการ"
                                                          queryObject={this.props.chart_of_account.groupCustomerChartOfAccount.edges}
                                                          keyOfValue="id" require={true}
                                                          keyOfLabel="chartOfAccountCode:name"
                                                          disabled={this.props.project_not_edit_account}
                                                          style={customStyles}
                                            />
                                        </div>
                                        <div className="col-3"/>
                                    </div>
                                </div>
                            </div>
    
    
                            {/*ข้อมูลการคืน*/}
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="form-group row">
                                        <h5 className="col-2 col-form-label">
                                            {t("add_product:Return information")}
                                        </h5>
                                        <h5 className="col-2 col-form-label">
                                            {t("add_product:Recorded account")}
                                        </h5>
                                        <div className="col-5 form-group">
                                            <SearchSelect 
                                                value={this.state.product.chartOfAccount.id}
                                                name="product.chartOfAccount.id" placeholder="รายการ"
                                                queryObject={this.props.chart_of_account.group4.edges}
                                                keyOfValue="id" require={true}
                                                keyOfLabel="chartOfAccountCode:name"
                                                disabled={true}
                                                style={customStyles}
                                            />
                                        </div>
                                        <div className="col-3"/>
                                    </div>
                                </div>
                            </div>

                            {/* เลขที่ No. */}
                            <div className="card mt-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-2 d-flex">
                                            <h5>{i18next.t("productCreateAndUpdate:No.")}</h5>
                                            <div className="tooltipImg">
                                                <img src={process.env.PUBLIC_URL + '/images/icons/questionIcon.png'} alt="question" className="question ml-2"/>
                                                <span className="tooltiptext">
                                                {i18next.t("productCreateAndUpdate:Some products have a unique number")}<br/>
                                                {i18next.t("productCreateAndUpdate:Ex.")} คีย์การ์ด EP143002 ,บัตรจอดรถ 00123<br/>
                                                {i18next.t("productCreateAndUpdate:Only one item can be withdrawn at a time.")}
                                                </span>
                                                <span className="tooltipTriangle">
                                                    <img src={process.env.PUBLIC_URL + '/images/tooltip/tooltipTriangle.png'}/>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-1 d-flex align-items-center">
                                            <input type="radio" value="have"
                                                name="noNumberText" checked={this.state.noNumberText === "have"}  
                                                onChange={this.handleInputChange} 
                                                required
                                                disabled={!this.state.edit_mod}
                                                />
                                            <span className="ml-2">{i18next.t("productCreateAndUpdate:Yes")}</span>
                                        </div>
                                        <div className="col-1 ml-5 d-flex align-items-center">
                                            <input type="radio" value="noHave"
                                                name="noNumberText" checked={this.state.noNumberText === "noHave"}  
                                                onChange={this.handleInputChange} 
                                                required
                                                disabled={!this.state.edit_mod}
                                                />
                                            <span className="ml-2">{i18next.t("productCreateAndUpdate:No")}</span>
                                        </div>
                                    </div>

                                    {this.state.noNumberText === "have" &&

                                        <React.Fragment>
                                            <div className="row mt-4">
                                                <div className="col-2"/>
                                                <div className="col-5">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5>{i18next.t("productCreateAndUpdate:Sorting format")}</h5>
                                                        </div>

                                                        <div className="col-12">
                                                            <input type="radio" name="autoOrderText"
                                                                value="auto" checked={this.state.autoOrderText === "auto"}  
                                                                onChange={this.handleInputChange}  disabled={!this.state.edit_mod} 
                                                                required
                                                                />
                                                            <span className="ml-2">{i18next.t("productCreateAndUpdate:Automatic")}</span>
                                                        </div>

                                                        <div className="col-12 mt-5">
                                                            <input type="radio" name="autoOrderText"
                                                                value="custom"checked={this.state.autoOrderText === "custom"}  
                                                                onChange={this.handleInputChange}
                                                                disabled={!this.state.edit_mod} required
                                                                />
                                                            <span className="ml-2">{i18next.t("productCreateAndUpdate:Customized")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-12 d-flex">
                                                {i18next.t("productCreateAndUpdate:Key card system data collection")}
                                                    <Switch
                                                        checked={this.state.product.collect}
                                                        onChange={this.switchInput}
                                                        onColor="#F5F5F5"
                                                        onHandleColor="#1567B4"
                                                        offHandleColor="#BFBFBF"
                                                        offColor="#F5F5F5"
                                                        handleDiameter={25}
                                                        uncheckedIcon={<div/>}
                                                        checkedIcon={<div className="switch-open"><span className="ml-5">{i18next.t("productCreateAndUpdate:Keep")}</span></div>}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        height={20}
                                                        width={70}
                                                        className="ml-4"
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }

                                </div>
                            </div>
    
                            {
                                // ยอดยกมา
                                this.state.edit_mod ?
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <h5 className="col-2 col-form-label">
                                                {t("add_product:Bring forward")}
                                            </h5>
    
                                            <table className="table table-hover mt-2">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th>{t("add_product:Times")}</th>
                                                    <th width={200}>{t("add_product:Date")}</th>
                                                    <th className="text-right">{t("add_product:Price / Unit")}</th>
                                                    <th className="text-right">{t("add_product:Product number")}</th>
                                                    {(this.state.noNumberText === "have" && this.state.autoOrderText === "auto") &&
                                                        <React.Fragment>
                                                            <th>{i18next.t("productCreateAndUpdate:Alphabet")}</th>
                                                            <th className="text-right">{i18next.t("productCreateAndUpdate:Number")}</th>
                                                        </React.Fragment>
                                                    }
                                                    {(this.state.noNumberText === "have" && this.state.autoOrderText === "custom") && <th/>}
                                                    <th/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.bring_forward_list.map((bring_forward, index) =>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <DatePickerNoti 
                                                                    className="form-control"
                                                                    selected={bring_forward.date}
                                                                    name={"bring_forward_list[" + index + "].date"}
                                                                    onChange={this.handleInputChange}
                                                                    />
                                                            </td>
                                                            <td className="text-right">
                                                                <input type="number" step="0.01"
                                                                       className="form-control text-right"
                                                                       id="name_product"
                                                                       name={"bring_forward_list[" + index + "].price"}
                                                                       onChange={this.handleInputChange}
                                                                       value={bring_forward.price}
                                                                       required={this.state.bring_forward_list.length >= 1}/>
                                                            </td>
                                                            <td>
                                                                <input type="number" className="form-control text-right"
                                                                       name={"bring_forward_list[" + index + "].amount"}
                                                                       onChange={(e)=>  this.handleInputChangeItems(e, index)}
                                                                       value={bring_forward.amount}
                                                                       required={this.state.bring_forward_list.length >= 1}/>
                                                            </td>
                                                            {(this.state.noNumberText === "have" && this.state.autoOrderText === "auto") &&
                                                                <React.Fragment>
                                                                    <td>
                                                                        <input 
                                                                            type="text" className="form-control"  style={{width: 96}} 
                                                                            name={"bring_forward_list[" + index + "].customCha"} 
                                                                            value={bring_forward.customCha} 
                                                                            onChange={this.handleInputChange} 
                                                                            pattern="[a-zA-Z]{1,100}"
                                                                            required={this.state.bring_forward_list.length >= 1}
                                                                            />
                                                                    </td>
                                                                    <td className="d-flex justify-content-end">
                                                                        <input 
                                                                            type="number" className="form-control text-right" 
                                                                            style={{minWidth: 160}} 
                                                                            name={"bring_forward_list[" + index + "].customNum"} 
                                                                            value={bring_forward.customNum} 
                                                                            onChange={this.handleInputChange}
                                                                            required={this.state.bring_forward_list.length >= 1}
                                                                            />
                                                                    </td>
                                                                </React.Fragment>
                                                            }
                                                            {(this.state.noNumberText === "have" && this.state.autoOrderText === "custom") &&
                                                                <td>
                                                                    <button type="button" className="btnGray" 
                                                                            onClick={() => this.handleOpenFormat(index, bring_forward.importKeycard)} 
                                                                            data-toggle="modal" data-target="#modalSetFormatNumber">{i18next.t("productCreateAndUpdate:Keep")}</button>
                                                                </td>
                                                            }
                                                            <td>
                                                                {
                                                                    this.state.bring_forward_list.length >= 1 &&
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + '/images/icons/deleteRed.png'}
                                                                        alt="deleteRed" className="btn-delete cursor"
                                                                        onClick={() => this.onDeleteRowBringForward(index)}/>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                            <button type="button" className="btnAdd mt-2"
                                                    onClick={() => this.setState({bring_forward_list: [...this.state.bring_forward_list, {...this.state.bring_forward_object}]})}
                                                    >
                                                +{t("add_product:Add list")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <h5 className="col-2 col-form-label">
                                                {t("add_product:Bring forward")}
                                            </h5>
    
                                            <table className="table mt-2">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th className="text-center">{t("add_product:Times")}</th>
                                                    <th className="text-center" width={150}>{t("add_product:Date")}</th>
                                                    <th className="text-center">{t("add_product:Price / Unit")}</th>
                                                    <th className="text-center">{t("add_product:Product number")}</th>
                                                    {this.state.noNumberText === "have" && <th/>}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.bring_edit_list.map((list,index) =>{
                                                    return (
                                                    <React.Fragment key = {index}>
                                                        <tr key = {index} className="text-center">
                                                            <td className="text-center">{index + 1}</td>
    
                                                            <td>
                                                                <DatePickerNoti
                                                                    className="form-control"
                                                                    name={"bring_edit_list[" + index + "].date"}
                                                                    selected={new Date(list.node.date)}
                                                                    onChange={this.handleInputChange}
                                                                    disabled={true}
                                                                />
                                                            </td>
                                                            <td className="text-right">
                                                                <input type="number" step="0.01"
                                                                       className="form-control text-right"
                                                                       id={"bring_edit_list[" + index + "]"}
                                                                       name={"bring_edit_list[" + index + "].price"}
                                                                       onChange={this.handleInputChange}
                                                                       value={list.node.price}
                                                                       data-key="bring_edit_list"
                                                                       data-id={index}
                                                                       disabled
                                                                       />
                                                            </td>
                                                            <td>
                                                                <input type="number" className="form-control text-right"
                                                                       name={"bring_edit_list[" + index + "].amount"}
                                                                       onChange={this.handleInputChange}
                                                                       value={list.node.amount}
                                                                       data-key="bring_edit_list"
                                                                       data-id={index}
                                                                       disabled
                                                                       />
                                                            </td>
                                                            {this.state.noNumberText === "have" &&
                                                                <td>
                                                                    <button type="button" className="btnGray" 
                                                                            onClick={() => this.handleOpenFormat(index, this.state.numberKey)} 
                                                                            data-toggle="modal" data-target="#modalSetFormatNumber">{i18next.t("productCreateAndUpdate:Keep")}</button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    </React.Fragment>
                                                    )
                                                })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
    
                            }

                            <SetFormatNumber 
                                importKeycard={this.state.itemShowFormat}
                                index={this.state.indexBringForwardList} 
                                getPageLimit={this.state.getPageLimit}
                                page={this.state.page} handleInputChange={this.handleInputChange}
                                handlePageClick={this.handlePageClick} disableEdit={!this.state.edit_mod}
                                handleClose={this.handleClose}/>
    
                            {/* ยอดยกมา แบบแก้ไขได้
                            {this.props.product &&
    
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label className="col-2 col-form-label">
                                                ยอดยกมา
                                            </label>
    
                                            <table className="table mt-2">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th className="text-center">ครั้งที่</th>
                                                    <th className="text-center" width={150}>วันที่</th>
                                                    <th className="text-center">ราคาต่อหน่วย</th>
                                                    <th className="text-center">จำนวนสินค้า</th>
                                                    <th/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.bring_edit_list.map((list,index) =>{
                                                    return (
                                                    <React.Fragment key = {index}>
                                                        <tr key = {index} className="text-center">
                                                            <td className="text-center">{index + 1}</td>
    
                                                            <td>
                                                                <DatePickerAdapter
                                                                    data-key="bring_edit_list"
                                                                    className="form-control"
                                                                    name={"bring_edit_list[" + index + "].date"}
                                                                    selected={new Date(list.node.date)}
                                                                    onChange={this.handleInputChange}
                                                                    data-id={index}
                                                                    disabled
                                                                    
                                                                />
                                                            </td>
                                                            <td className="text-right">
                                                                <input type="number" step="0.01"
                                                                       className="form-control text-right"
                                                                       id={"bring_edit_list[" + index + "]"}
                                                                       name={"bring_edit_list[" + index + "].price"}
                                                                       onChange={this.handleInputChange}
                                                                       value={list.node.price}
                                                                       data-key="bring_edit_list"
                                                                       data-id={index}
                                                                       disabled
                                                                       />
                                                            </td>
                                                            <td>
                                                                <input type="number" className="form-control text-right"
                                                                       name={"bring_edit_list[" + index + "].amount"}
                                                                       onChange={this.handleInputChange}
                                                                       value={list.node.amount}
                                                                       data-key="bring_edit_list"
                                                                       data-id={index}
                                                                       disabled
                                                                       />
                                                            </td>
                                                            <td>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                    alt="delete" className="btn-delete cursor"
                                                                    onClick={() => this.onDeleteRowBringForward(index)}/>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                    )
                                                })
                                                }
                                                </tbody>
                                            </table>
                                            <button type="button" className="btn add mt-2"
                                                    onClick={() => this.setState({bring_edit_list: [...this.state.bring_edit_list, {node:this.state.bring_forward_object}]})}
                                                    style={{backgroundColor: '#f2f2f2'}}>
                                                +{i18next.t("settingAll:Add list")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            } */}
    
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>{i18next.t("productCreateAndUpdate:Reorder point")}</h5>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-12 d-flex align-items-center">
                                            <h5>{i18next.t("productCreateAndUpdate:Set quantity to reorder point")}</h5>
                                            <input 
                                                type="number"
                                                className="form-control ml-3" 
                                                style={{width: "80px"}}
                                                value={this.state.product.reorderPoint || ""}
                                                name="product.reorderPoint"
                                                onChange={this.handleInputChange}
                                                required
                                                />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-12 d-flex align-items-center colorUse">
                                            <h5><label className='w100'>{i18next.t("productCreateAndUpdate:Note")} :</label></h5>
                                            <div className="statusRed w150">
                                                <span style={{fontSize: '100%'}}>{i18next.t("productCreateAndUpdate:Low")}</span>
                                            </div>
                                            <span className='ml-3'>{i18next.t("productCreateAndUpdate:Less than the reorder point")}</span>
                                        </div>
                                        <div className="col-12 mt-2 d-flex align-items-center colorUse">
                                            <div className='w100'/>
                                            <div className="statusYellow w150">
                                                <span style={{fontSize: '100%'}}>{i18next.t("productCreateAndUpdate:Reorder point reached")}</span>
                                            </div>
                                            <span className='ml-3'>{i18next.t("productCreateAndUpdate:The reorder point has been reached.")}</span>
                                        </div>
                                        <div className="col-12 mt-2 d-flex align-items-center colorUse">
                                            <div className='w100'/>
                                            <div className="statusGreen w150">
                                                <span style={{fontSize: '100%'}}>{i18next.t("productCreateAndUpdate:Ready to use")}</span>
                                            </div>
                                            <span className='ml-3'>{i18next.t("productCreateAndUpdate:Exceeds the reorder point")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="row mt-4 form-group">
                                <div className="col-7 d-flex">
                                    <h5 className="col-2">
                                        {t("add_product:Description")}
                                    </h5>
                                    <div className="w-100 ml-5">
                                        <textarea 
                                            rows="3" name="product.description"
                                            onChange={this.handleInputChange}
                                            value={this.state.product.description || ''}/>
                                    </div>
                                </div>
                            </div>
    
                            <div className="row mt-3 fade-up">
                                <div className="col-7 text-right">
                                    {/* <div className="btn-group mr-2">
                                        <Link to="/accounting/inventory/product/list/product">
                                            <button type="button"
                                                    className="btn btn-secondary add">
                                                ยกเลิก
                                            </button>
                                        </Link>
                                    </div> */}
                                    <div className="btn-group">
                                        <button type="submit" className="btn btnSave"
                                                disabled={this.state.loading}>
                                            {this.state.loading && <span
                                                className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                            {t("add_product:Save")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    </>
                    }
                </Translation>
            </div>
        )
    }
}

export default ProductCreateAndUpdate;