import React, { Component } from 'react'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import { Helmet } from "react-helmet";
import Loading from '../../libs/loading'
import StockMenagement from "./stockMenagement"
import "./stockMenagement.scss"

const query = graphql`
  query wrapperStockMenagementQuery($requisitionNoteId: [String]) {
    selfProject{
      id
      name
      address
      logo
      juristicContactNumber
      keyProjectQr
      bankCompCode
      bankServiceCode
      taxIdNumber
      typeOfProject
    }
    allRequisitionNote(requisitionNoteId: $requisitionNoteId){
      edges{
        node{
          id
          status
          docNumber
          typeRequisition
          typeNote
          issuedDate
          recipient
          firstNameRecipient
          lastNameRecipient
          username
          objective
          remark
          contact{
            id
            name
            firstName
            lastName
          }
          juristict{
            id
            firstName
            lastName
          }
          requisitionList{
            edges{
              node{
              id
              numberOfPieces
              numberOfBalance
              noNumber
              productAndService{
                  id
                  noNumber
                  name
                  productCode
                  stock {
                    edges {
                      node {
                        id
                        cost
                        leftInStock
                        added
                        transaction{
                            date
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class wrapperStockMenagement extends Component {

  print() {
    window.print();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet
          style={[{
            "cssText": `
              body {
                background-color: #ececec;
                width: 100%;
                height: 100%;
              }
            `
          }]}>
          <meta charSet="utf-8"/>
          <title>StockMenagement Print</title>
          <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
        </Helmet>
        <div id="wrapperStockMenagement">
          <div className="print-top-menu">
            <div className="logoStockMenagement">
              <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
            </div>
            <button className="printStockMenagement" onClick={this.print}>
              <img src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'} alt="silverman"/>
              <span>พิมพ์</span>
            </button>
          </div>
        </div>
        <QueryRenderer
          environment={environment}
          query={query}
          cacheConfig={{use_cache: false}}
          variables={{requisitionNoteId: this.props.location.state.requisitionNoteId }}
          render={({error, props}) => {
              if (error) {
                  return <div className="alert alert-danger"
                              role="alert">{error.message}</div>;
              } else if (props) {
                  return(
                    <StockMenagement
                      selfProject={props.selfProject}
                      allRequisitionNote={props.allRequisitionNote.edges}
                      />
                  )
              }
              return <Loading/>;
          }}
        />
      </React.Fragment>
    )
  }
}

