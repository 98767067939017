/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClearAdvanceStatus = "CLEAR" | "VOID" | "%future added value";
export type clearAdvanceListTableQueryVariables = {|
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type clearAdvanceListTableQueryResponse = {|
  +allClearAdvance: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +description: string,
        +returnPrice: number,
        +overPrice: number,
        +status: ClearAdvanceStatus,
        +advance: {|
          +id: string,
          +amount: number,
          +docNumber: string,
        |},
        +clearAdvanceTransaction: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +price: number,
              +description: string,
              +refClearAdvanceTransactionDoc: ?string,
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type clearAdvanceListTableQuery = {|
  variables: clearAdvanceListTableQueryVariables,
  response: clearAdvanceListTableQueryResponse,
|};
*/


/*
query clearAdvanceListTableQuery(
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
) {
  allClearAdvance(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        docNumber
        issuedDate
        description
        returnPrice
        overPrice
        status
        advance {
          id
          amount
          docNumber
        }
        clearAdvanceTransaction {
          edges {
            node {
              id
              price
              description
              refClearAdvanceTransactionDoc
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      }
    ],
    "concreteType": "ClearAdvanceNodeConnection",
    "kind": "LinkedField",
    "name": "allClearAdvance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClearAdvanceNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClearAdvanceNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "returnPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvanceNode",
                "kind": "LinkedField",
                "name": "advance",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClearAdvanceTransactionNodeConnection",
                "kind": "LinkedField",
                "name": "clearAdvanceTransaction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClearAdvanceTransactionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClearAdvanceTransactionNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "refClearAdvanceTransactionDoc",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "clearAdvanceListTableQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "clearAdvanceListTableQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "dfbe611fcbe86ab9d6f300cb118bdd30",
    "id": null,
    "metadata": {},
    "name": "clearAdvanceListTableQuery",
    "operationKind": "query",
    "text": "query clearAdvanceListTableQuery(\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n) {\n  allClearAdvance(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        description\n        returnPrice\n        overPrice\n        status\n        advance {\n          id\n          amount\n          docNumber\n        }\n        clearAdvanceTransaction {\n          edges {\n            node {\n              id\n              price\n              description\n              refClearAdvanceTransactionDoc\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '723e986a01e5b00e9c3233721894297b';

module.exports = node;
