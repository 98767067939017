import React from 'react';
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import Loading from "../../libs/loading";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Translation } from 'react-i18next';
import i18next from 'i18next';
const WorkSheetTable = ({ start_date, end_date, loading, allActiveChartOfAccount, report = false, lastPage = false, sum, page, pageAll }) => {
    const nameAccountConvert = (name) => {
        let bank = name.includes('ธนาคาร')
        let nameBank = ""

        if (bank) {
            let nameSplit = name.split(" ")
            let nameBanksort = ""
            if (nameSplit[0] !== "ค่าธรรมเนียมธนาคาร") {
                switch (nameSplit[1]) {
                    case "ธนาคารกรุงศรีอยุธยา": nameBanksort = "BAY";
                        break;
                    case "ธนาคารซีไอเอ็มบีไทย": nameBanksort = "CIMBT";
                        break;
                    case "ธนาคารกรุงเทพ": nameBanksort = "BBL";
                        break;
                    case "ธนาคารไทยพาณิชย์": nameBanksort = "SCB";
                        break;
                    case "ธนาคารกสิกรไทย": nameBanksort = "KBANK";
                        break;
                    case "ธนาคารทีเอ็มบีธนชาต": nameBanksort = "TTB";
                        break;
                    case "ธนาคารกรุงไทย": nameBanksort = "KTB";
                        break;
                    case "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร": nameBanksort = "ธกส.";
                        break;
                    case "ธนาคารอาคารสงเคราะห์": nameBanksort = "ธอส.";
                        break;
                    default: nameBanksort = nameSplit[1]
                        break;
                }

                nameBank = nameSplit[0] + "\n" + nameBanksort + " " + nameSplit[2]
            }
            else {
                nameBank = name
            }
        } else {
            nameBank = name
        }
        return nameBank

    }
    return (
        <Translation>
            {t =>
                <div className="row" id='work-sheet-report-main' style={{ zoom: '75%' }}>
                    <div className="col">
                        {!loading ? <div className={!report ? "table-responsive card" : "table-responsive"}>
                            <table className="table">
                                <thead className={!report ? "table-dark" : ""}>
                                    <tr>
                                        <td className="font-weight-bold text-center" colSpan="2" style={{ height: 50, fontSize: report ? '18px' : '' }}>{i18next.t("workSheetTable:Accounting paper")}</td>
                                        <td colSpan="2" className="font-weight-bold text-center" style={{ fontSize: report ? '18px' : '' }}>{i18next.t("workSheetTable:Trial balance before adjustment")}</td>
                                        <td colSpan="2" className="font-weight-bold text-center" style={{ fontSize: report ? '18px' : '' }}>{i18next.t("workSheetTable:Adjustment entries")}</td>
                                        <td colSpan="2" className="font-weight-bold text-center" style={{ fontSize: report ? '18px' : '' }}>{i18next.t("workSheetTable:Trial balance after adjustment")}</td>
                                        <td colSpan="2" className="font-weight-bold text-center" style={{ fontSize: report ? '18px' : '' }}>{i18next.t("workSheetTable:Income statement")}</td>
                                        <td colSpan="2" className="font-weight-bold text-center" style={{ fontSize: report ? '18px' : '' }}>{i18next.t("workSheetTable:Balance sheet")}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold" style={{ height: 50, width: 90, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Account number")}</td>
                                        <td className="font-weight-bold" style={{ width: 250, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Account name")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Debit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Credit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Debit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Credit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Debit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Credit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Debit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Credit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Debit")}</td>
                                        <td className="font-weight-bold text-center" style={{ width: 110, fontSize: report ? '17px' : '' }}>{i18next.t("workSheetTable:Credit")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allActiveChartOfAccount.map((chart) => {
                                        let code = _.get(chart, "node.chartOfAccountCode").slice(0, 1)
                                        let balanceDebit = _.get(chart, "node.balanceDebit")
                                        let balanceCredit = _.get(chart, "node.balanceCredit")
                                        let balance = _.get(chart, "node.balance")
                                        let update = JSON.parse(_.get(chart, "node.update"))
                                        return (
                                            <tr key={chart.node.id}>
                                                <td style={{ fontSize: report ? '16px' : '' }}>{chart.node.chartOfAccountCode}</td>
                                                <td className={report ? 'fontTd' : ''} style={{ height: 50, maxHeight: 50, flexWrap: 'nowrap', whiteSpace: 'pre-wrap' }}>{nameAccountConvert(chart.node.name)}</td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>{numberWithComma(parseFloat(update.before_update_debit))}</td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>{numberWithComma(parseFloat(update.before_update_credit))}</td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>
                                                    {!report ? <Link to={"/accounting/general-ledger/"
                                                        + chart.node.chartOfAccountCode + "/"
                                                        + format(start_date, "YYYY-MM-DD") + "/"
                                                        + format(end_date, "YYYY-MM-DD")}
                                                        target={"_blank"}>
                                                        {numberWithComma(parseFloat(update.update_debit))}
                                                    </Link> : numberWithComma(parseFloat(update.update_debit))}
                                                </td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>
                                                    {!report ? <Link to={"/accounting/general-ledger/"
                                                        + chart.node.chartOfAccountCode + "/"
                                                        + format(start_date, "YYYY-MM-DD") + "/"
                                                        + format(end_date, "YYYY-MM-DD")}
                                                        target={"_blank"}>
                                                        {numberWithComma(parseFloat(update.update_credit))}
                                                    </Link> : numberWithComma(parseFloat(update.update_credit))}
                                                </td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>{balanceDebit > balanceCredit ? numberWithComma(balance) : "-"}</td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>{balanceDebit > balanceCredit ? "-" : numberWithComma(balance)}</td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>{code === '4' || code === '5' ? balanceDebit > balanceCredit ? numberWithComma(balance) : "-" : "-"}</td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>{code === '4' || code === '5' ? balanceDebit > balanceCredit ? "-" : numberWithComma(balance) : "-"}</td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>{code === '1' || code === '2' || code === '3' ? balanceDebit > balanceCredit ? numberWithComma(balance) : "-" : "-"}</td>
                                                <td className="text-right" style={{ fontSize: report ? '16px' : '' }}>{code === '1' || code === '2' || code === '3' ? balanceDebit > balanceCredit ? "-" : numberWithComma(balance) : "-"}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                {lastPage && <tfoot>
                                    <tr>
                                        <td colSpan="2" className="text-center" style={{ height: 50, fontWeight: '900', fontSize: report ? '18px' : '' }}><b>{i18next.t("workSheetTable:Total")}</b></td>


                                        {/* <td className="text-right" colSpan={2}
                                    style={{ minWidth: 80, maxWidth: 100, fontWeight: '900' , fontSize: report ? '18px' : '', padding: 0, paddingRight: '8px' }}>
                                        <p style={{marginRight: 70}}>{numberWithComma(sum.unadjusted_trial_balance_debit)}</p>
                                        <p>{numberWithComma(sum.unadjusted_trial_balance_credit)}</p>
                                </td>
                                <td className="text-right" colSpan={2}
                                    style={{ minWidth: 80, maxWidth: 100, fontWeight: '900' , fontSize: report ? '18px' : '', padding: 0, paddingRight: '8px' }}>
                                        <p style={{marginRight: 70}}>{numberWithComma(sum.adjusted_entry_debit)}</p>
                                        <p>{numberWithComma(sum.adjusted_entry_credit)}</p>
                                </td>
                                <td className="text-right" colSpan={2}
                                    style={{ minWidth: 80, maxWidth: 100, fontWeight: '900' , fontSize: report ? '18px' : '', padding: 0, paddingRight: '8px' }}>
                                        <p style={{marginRight: 70}}>{numberWithComma(sum.adjusted_trial_balance_debit)}</p>
                                        <p>{numberWithComma(sum.adjusted_trial_balance_credit)}</p>
                                </td>
                                <td className="text-right" colSpan={2}
                                    style={{ minWidth: 80, maxWidth: 100, fontWeight: '900' , fontSize: report ? '18px' : '', padding: 0, paddingRight: '8px' }}>
                                        <p style={{marginRight: 70}}>{numberWithComma(sum.income_expenses_debit)}</p>
                                        <p>{numberWithComma(sum.income_expenses_credit)}</p>
                                </td>
                                <td className="text-right" colSpan={2}
                                    style={{ minWidth: 80, maxWidth: 100, fontWeight: '900' , fontSize: report ? '18px' : '', padding: 0, paddingRight: '8px' }}>
                                        <p style={{marginRight: 70}}>{numberWithComma(sum.statement_of_financial_position_debit)}</p>
                                        <p>{numberWithComma(sum.statement_of_financial_position_credit)}</p>
                                </td> */}



                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }} >
                                            {numberWithComma(sum.unadjusted_trial_balance_debit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }} >
                                            {numberWithComma(sum.unadjusted_trial_balance_credit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }} >
                                            {numberWithComma(sum.adjusted_entry_debit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }} >
                                            {numberWithComma(sum.adjusted_entry_credit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }} >
                                            {numberWithComma(sum.adjusted_trial_balance_debit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }}>
                                            {numberWithComma(sum.adjusted_trial_balance_credit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }} >
                                            {numberWithComma(sum.income_expenses_debit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }} >
                                            {numberWithComma(sum.income_expenses_credit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }} >
                                            {numberWithComma(sum.statement_of_financial_position_debit)}</td>
                                        <td className="text-right" style={{ minWidth: 80, maxWidth: 100, fontWeight: '900', fontSize: report ? '12px' : '', padding: 0, paddingRight: '8px' }}>
                                            {numberWithComma(sum.statement_of_financial_position_credit)}</td>
                                    </tr>
                                </tfoot>}
                            </table>
                        </div> : <Loading />}
                    </div>
                </div>
            }
        </Translation>

    );
}

export default WorkSheetTable;
