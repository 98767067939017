import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import ChequeTransactionListAll from './chequeTransactionListAll'
import ChequeClearing from './chequeClearing'
import CreateChequeTransaction from './createChequeTransaction'
import ViewChequeTransaction from './viewChequeTransaction'
import WrapperChequeTransactionPrint from './wrapperChequeTransactionPrint'


class Cheque extends Component {

    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={ChequeTransactionListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ChequeTransactionListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/clearing/detail/:id`} component={ChequeClearing}/>
                <PrivateRoute exact path={`${this.props.match.url}/clearing/create`} component={CreateChequeTransaction}/>
                <PrivateRoute exact path={`${this.props.match.url}/clearing/view/:id`} component={ViewChequeTransaction}/>
                <PrivateRoute exact path={`${this.props.match.url}/print-cheque/:id`} component={WrapperChequeTransactionPrint}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/print-cheque/all/`} component={WrapperChequeTransactionPrint}/>
            </React.Fragment>
        );
    }
}

export default Cheque;