import React from "react";
import DatePicker from "react-datepicker";
import "../../styles/commonArea.scss";
import './commonArea.scss'
import { Translation } from "react-i18next";
import Rights from "./rights";

const TypeToBooking = ({
  HandleOnChange,
  state: {
    typeTobooking,
    timeToBooking,
    typeTobookingCustom,
    timeToBookingCustomList,
    timeToBreck,
    timeToBreckStartTime,
    timeToBreckEndTime,
    reservation_form,
    round
  },
  onClickSelectBtn,
  onChangeTime,
  addRound,
  handleChange,
  subRound,
  create
}) => {
  return (
    <Translation>
      {t =>
        <div className="booking-form mb  mt-4 claim">
          <div className="switch-languages-div d-flex justify-content-between create usedAll">
            <p className="headerLabel24 fw-400">{t("newBookingSingha_Create_step2:Reservation type")} <span className="label16 fw-400">( {t("newBookingSingha_Create_step2:Reservation period in Silverman application for resident")} )</span></p>
          </div>

          <div className="row">
            {/* {i18n.t("newBookingSingha_Setting:Based on the system default,")} */}
            <div className="usedAll">
              <div>

                <label className="containerRadio fw-300 ml-4" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:Default")}
                  <input type="radio" name="typeTobooking" value={true} defaultChecked={typeTobooking == true} onChange={HandleOnChange} />
                  <span className="checkmark checkmarks_dot_10" style={{ left: -3, top: -4, width: 20, height: 20, }}></span>
                  <div className="text-grey label16 fw-300 mt-1">{t("newBookingSingha_Create_step2:Select the time period you want to display in the Silverman application for residents to make reservations for example, if you select 30 minutes, the time will display “08.30, 09.00, 09.30 ...“ respectively.")}</div>

                  {
                    typeTobooking &&
                    <React.Fragment>
                      <div>
                        <button type="button" onClick={() => onClickSelectBtn(`timeToBooking`, 15)}
                          className={`btnSelectItem m-3 ml-0 floatLeft ${timeToBooking === 15 ? `selected` : ``}`} >
                          <label className="label16 fw-300 mt-2">{t("newBookingSingha_Create_step2:15 Minutes")}</label>
                        </button>

                        <button onClick={() => onClickSelectBtn(`timeToBooking`, 30)}
                          className={`btnSelectItem m-3 ml-0 floatLeft ${timeToBooking === 30 ? `selected` : ``}`}
                          type="button">
                          <label className="label16 fw-300 mt-2">{t("newBookingSingha_Create_step2:30 Minutes")}</label>
                        </button>

                        <button
                          onClick={() => onClickSelectBtn(`timeToBooking`, 60)}
                          className={`btnSelectItem m-3 ml-0 floatLeft ${timeToBooking === 60 ? `selected` : ``}`}
                          type="button">
                          <label className="label16 fw-300 mt-2">{t("newBookingSingha_Create_step2:60 Minutes")}</label>
                        </button>
                      </div>

                      <div style={{ clear: 'both' }} />

                      <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:Add a break time")}
                        <input type="checkbox" name="timeToBreck" defaultChecked={timeToBreck}
                          onChange={HandleOnChange} />
                        <span className="checkmarkCheckBox"></span>
                      </label>
                    </React.Fragment>
                  }


                </label>
                {
                  (timeToBreck && typeTobooking == true) &&
                  <div style={{ marginLeft: 50 }} >
                    <div className="text-grey label16 fw-300 mt-1 ml-1">{t("newBookingSingha_Create_step2:Specify the start and end period")}</div>
                    <div>
                      {/* // Time Start */}
                      <div style={{ width: 192 }} className="float-left">
                        <DatePicker
                          value={new Date("2023-01-01T" + timeToBreckStartTime).getTime()}
                          selected={new Date("2023-01-01T" + timeToBreckStartTime).getTime()}
                          onChange={(time) => onChangeTime(`startTime`, `timeToBreckStartTime`, time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control searchDate icon_ArrowDown"
                        />
                      </div>
                      <div className="float-left label16 fw-300">-</div>
                      {/* //  Time End */}
                      <div style={{ width: 210 }} className="float-left">
                        <DatePicker
                          value={new Date("2023-01-01T" + timeToBreckEndTime).getTime()}
                          selected={new Date("2023-01-01T" + timeToBreckEndTime).getTime()}
                          onChange={(time) => onChangeTime(`endTime`, `timeToBreckEndTime`, time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control searchDate icon_ArrowDown"
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>

              <div style={{ clear: 'both' }} />

              {/* กำหนดเอง */}

              <label className="containerRadio fw-300 ml-4" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:Custom")}
                <input type="radio" name="typeTobooking" value={false} defaultChecked={typeTobooking == false} onChange={HandleOnChange} />
                <span className="checkmark checkmarks_dot_10" style={{ left: -3, top: -4, width: 20, height: 20 }}></span>
                {
                  (typeTobooking == false && timeToBookingCustomList.length === 0) &&
                  <input type={"text"} required={true}
                    style={{ opacity: 0 }}
                    onInvalid={e => e.target.setCustomValidity(t("newBookingSingha_Booking:Please specify at least one custom period for your booking."))}
                    onInput={e => e.target.setCustomValidity('')} />
                }

                <div className="text-grey label16 fw-300 mt-1">{t("newBookingSingha_Create_step2:Manually schedule a reservation period")}</div>

                {
                  !typeTobooking &&
                  <div className="">
                    <div className="mt-2">
                      <div className="d-flex flex-row align-items-center text "></div>

                      <Rights create={create} timeToBookingCustomList={timeToBookingCustomList} addRound={addRound} handleChange={handleChange} reservation_form={reservation_form} subRound={subRound} typeTobooking={typeTobooking} />


                    </div>
                  </div>
                }

              </label>
            </div>
          </div>
        </div>
      }
    </Translation>
  );
};



export default TypeToBooking;
