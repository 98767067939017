import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import thLocale from "date-fns/locale/th";
import _ from 'lodash';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Dropdown } from 'react-bootstrap';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNewBold.ttf',
        italics: 'THSarabunNewItalic.ttf',
        bolditalics: 'THSarabunNewBoldItalic.ttf'
    },
    Sarabun: {
        normal: 'Sarabun-Light.ttf',
        bold: 'Sarabun-Bold.ttf',
        italics: 'Sarabun-Italic.ttf',
        bolditalics: 'Sarabun-BoldItalic.ttf'
    }
}

const ExportVehiclePDF = (state) => {
    const generatePdf = () => {
        let startItem = 0
        let dataMapExport = []
        _.map(state.dataExport, (item) => {
            startItem++
            _.map(item.node.car.edges, (car, indexCar) => {
                return dataMapExport.push(
                    [
                        { text: indexCar === 0 ? startItem : "", style: 'tabletd' },
                        { text: indexCar === 0 ? item.node.name : "", style: 'tabletd' },
                        { text: car.node.licencePlate, style: 'tabletd' },
                        { text: car.node.carType === "MOTORCYCLE" ? "รถจักรยานยนต์" : 'รถยนต์', style: 'tabletd' },
                        { text: car.node.carBrand, style: 'tabletd' },
                        { text: car.node.carDescription, style: 'tabletd_left' },
                        { text: car.node.carSticker, style: 'tabletd' },
                        { text: car.node.chargedFee ? "/" : "", style: 'tabletd' },
                        { text: car.node.floor ? car.node.floor : "-", style: 'tabletd' },
                        { text: car.node.space ? car.node.space : "-", style: 'tabletd' },
                    ]
                )
            })
        })

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [20, 40, 20, 40],
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [0, 0],
                        },
                        {
                            text: `ผู้พิมพ์ ${state.namePrint} วันที่พิมพ์ ${format(new Date(), "DD/MM/YYYY")}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [20, 0],
                        },
                    ],
                };
            },
            content: [
                { text: state.project_name, style: 'header' },
                { text: 'สรุปรายงานยานพาหนะ', style: 'header' },
                { text: format(new Date(), "ณ DD MMMM YYYY", { locale: thLocale }), style: 'header' },
                { text: "", margin: [0, 0, 0, 10] },
                {
                    table: {
                        headerRows: 1,
                        widths: [30, 50, 80, 80, 80, 180, 62, 60, 40, 45],
                        dontBreakRows: true, // ใช้สำหรับ ให้แถวสุดท้ายที่ตกบรรทัดให้ ขึ้นหน้าใหม่แทน
                        body: [
                            [
                                { text: 'ลำดับ', style: 'tablecellMiddle' },
                                { text: 'บ้านเลขที่/ห้องเลขที่', style: 'tablecell' },
                                { text: 'ทะเบียนรถ', style: 'tablecellMiddle' },
                                { text: 'ประเภท', style: 'tablecellMiddle' },
                                { text: 'ยี่ห้อ/รุ่น', style: 'tablecellMiddle' },
                                { text: 'ลักษณะรถ', style: 'tablecellMiddle' },
                                { text: 'เลขสติ๊กเกอร์', style: 'tablecellMiddle' },
                                { text: 'เลขสติ๊กเกอร์จอดรถ', style: 'tablecell' },
                                { text: 'ชั้นจอด', style: 'tablecellMiddle' },
                                { text: 'ช่องที่จอด', style: 'tablecellMiddle' }
                            ],
                            ...dataMapExport
                        ],
                    },
                    layout: 'auto',
                },
            ],
            defaultStyle: {
                font: 'Sarabun'
            },
            styles: {
                header: {
                    fontSize: 14,
                    bold: true
                },
                tablecell: {
                    alignment: 'center', bold: true, fontSize: 10, verticalAlignment: 'middle',
                },
                tablecellMiddle: {
                    alignment: 'center', bold: true, fontSize: 10, margin: [0,5,0,0],
                },
                tabletd: {
                    alignment: 'center', heihgt: 40, maxHeight: 40, fontSize: 10, verticalAlignment: 'middle',
                },
                tabletd_left: {
                    alignment: 'left', heihgt: 40, maxHeight: 40, fontSize: 10, verticalAlignment: 'middle',
                }
            }

        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
            let preview = URL.createObjectURL(blob)
            window.open(preview)
        });
    };

    return (
        <Link
            to="route"
            target="blank"
            onClick={(event) => {
                event.preventDefault();
                generatePdf();
            }}>
            <Dropdown.Item>
                PDF
            </Dropdown.Item>
        </Link>
    );


}

export default ExportVehiclePDF;