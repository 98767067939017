import React, {Component} from 'react';
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import Wrapper from "../../../../components/wrapper";
import SelectContact from "../../../income/selectContact";
import FlowOfPrepaidDepositReport from "./flowOfPrepaidDepositReport";

class SelectRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_list: [],
            accountType: "flow_of_prepaid_deposit",
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true}/>
                    {
                        this.props.match.params.status === "select-customer" &&
                        <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                       nextLink="/accounting/report/account-payable/flow_of_prepaid_deposit/list"
                                       backLink="/accounting/report/account-payable"
                                       disable_select_type={true}
                        />
                    }

                    {this.props.match.params.status === 'list' &&
                    <FlowOfPrepaidDepositReport customerList={this.state.customer_list}/>
                    }

                </div>
            </Wrapper>
        );
    }
}

export default SelectRoom;