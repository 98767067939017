import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import "./style/reportStock.scss"
import i18next from 'i18next';

export default class reportStockDetailTopmenu extends Component {

    render() {
        return (
            <div className="row" id="reportStockDetailTopmenu">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to={`${this.props.url}/all`} >
                                {i18next.t("stockAndReport:All")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${this.props.url}/buy`} >
                                {i18next.t("stockAndReport:Buy")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${this.props.url}/unit`} >
                                {i18next.t("stockAndReport:Rights")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${this.props.url}/sell`} >
                                {i18next.t("stockAndReport:Sell")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${this.props.url}/project`} >
                                {i18next.t("stockAndReport:Requisition")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
