import React, { useState } from 'react';
import { Translation } from "react-i18next";
import i18next from 'i18next';

const StatusView = (props) => {
  const [status, setStatus] = useState(props.status);



  let stauts =
    props.status === 'new' ? i18next.t("claimRequstList:Under consideration"):
      props.status === 'pending_approve' ? i18next.t("claimRequstList:Under insurance approval") :
        props.status === 'approve' ? i18next.t("claimRequstList:Receive compensation") :
          props.status === 'finish' ? i18next.t("claimRequstList:Finished") : "ยกเลิก/ปฏิเสธ"

  return (
    <Translation >
      {
        t =>
          <span className='status-View-Tab' style={{ float: 'right' }}>
            สถานะ:
            <label className={
              props.status === 'new' ? 'tab yellowClaim' :
                props.status === 'pending_approve' ? 'tab primaryClaim' :
                  props.status === 'approve' ? 'tab turquoiseClaim' :
                    props.status === 'finish' ? 'tab greenClaim' : 'tab redClaim'
            }>
              {stauts}
            </label>

          </span>
      }
    </Translation>
  );
}

export default StatusView;