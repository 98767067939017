import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import { Link } from "react-router-dom";
import i18n from 'i18next';
import { Translation } from "react-i18next";
import '../styles/index.scss'
import UploadImageInput from "../../libs/uploadImageInput";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import { graphql } from "babel-plugin-relay/macro";
import Redirect from "react-router-dom/es/Redirect";
import SearchSelect from "../../libs/searchSelect";
import { fetchQuery } from "relay-runtime";
import CreatableSelect from '../../libs/creatableSelect';

const _ = require('lodash');

const mutation = graphql`
    mutation createParcelMutation($input: CreateParcelInput!){
        createParcel(input: $input){
            ok
        }
    }
`;

const allResidential = graphql`
    query createParcelQuery {
        allResidential {
            totalCount
            edges {
                node {
                    id
                    name
                    residentialHouseholder{
                        edges{
                            node{
                                id
                                tenant{
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

class CreateParcel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirectToList: false,
            issued_date: new Date(),
            depositTime: "00:00",
            parcel_size_etc: '',
            parcel_create_list: [],
            residential_list: [],
            parcel_new_object: {
                residential_id: '',
                room_number: '',
                name: '',
                index: '',
                tracking_number: '',
                service_name: '',
                description: '',
                image_upload: '',
                tenant_id: ''
            },
            residential_householder_list: []
        };

        this.appendNewRow = this.appendNewRow.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.cancelImg = this.cancelImg.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
    }

    componentWillMount() {
        this.appendNewRow()
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        if (event.target.name.search(/description/i) > 1 && event.target.value === "อื่นๆ") {
            this.setState({
                parcel_size_etc: event.target.value
            })
        }
        else if (event.target.name.search(/description/i) > 1 &&
            (event.target.value !== "อื่นๆ" && (
                event.target.value === "ซองจดหมาย" ||
                event.target.value === "ซองขนาดเล็ก" ||
                event.target.value === "ซองขนาดใหญ่" ||
                event.target.value === "กล่องขนาดเล็ก" ||
                event.target.value === "กล่องขนาดใหญ่" ||
                event.target.value === ""))) {
            this.setState({
                parcel_size_etc: ''
            })
        }


        if (event.target.name.includes('residential_id')) {
            this.setResidentialHouseholder(e)
        }

        if (event.target.name.includes('tenant_id')) {
            !value?.__isNew__ && this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, value.value) });
            this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name.replace('tenant_id', 'name'), value.label) });
        } else {
            this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, value) });
        }
    }

    setResidentialHouseholder = async (e, xxx = '') => {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState({
            residential_householder_list: _.find(_.cloneDeep(this.state.residential_list) || [], function (o) { return o.node.id === value; }),
            tenant_id: ""
        });
    }

    deleteRow(index) {
        let parcel = [...this.state.parcel_create_list];
        if (index !== -1) {
            parcel.splice(index, 1)
        }
        this.setState({ parcel_create_list: parcel });
    }

    appendNewRow() {
        let parcel = this.state.parcel_new_object;
        parcel.index = this.state.parcel_create_list.length;
        this.setState({ parcel_create_list: [...this.state.parcel_create_list, { ...this.state.parcel_new_object }] });
        fetchQuery(environment, allResidential).then(response_data => {
            let dataRoom = [...response_data.allResidential.edges]
                this.setState({
                    residential_list: dataRoom?.sort(function (a, b) {
                        return a.node.name.localeCompare(b.node.name, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        });
                    })
                });
            // this.setState({
            //     residential_list: response_data.allResidential.edges,
            // });
        });
    }

    cancelImg(index) {
        this.setState(prevState => {
            return _.set(_.cloneDeep(prevState), "parcel_create_list[" + index + "].image_upload", "");
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let uploadables = {};
        this.state.parcel_create_list.forEach((parcel) => {
            if (parcel.image_upload) {
                Object.assign(uploadables, { ["image_parcel" + parcel.index]: parcel.image_upload });
            }
        });
        let variables = {
            input: {
                parcelObject: JSON.stringify(this.state.parcel_create_list),
                issuedDate: new Date(`${this.state.issued_date.toDateString()} ${this.state.depositTime} +07:00`)
            }
        };
        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createParcel.ok) {
                        Swal.fire(i18n.t("parcel:Save successfully"), '', 'success').then(() => {
                            this.setState({redirectToList: true});
                        });
                    } else {
                        Swal.fire(i18n.t("parcel:Failed to save"), i18n.t("parcel:Please check again"), 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire(i18n.t("parcel:Failed to save"), i18n.t("parcel:Please try again"), 'warning')
                },
            },
        )
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/contact/parcel/not_receive" />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX>
                    <ContactTopMenuNavigation mini={true} />
                    <form onSubmit={this.onSubmit} id="create-parcel">
                        <div className="container-fluid box" id="parcel">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/contact/parcel/not_receive">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back" />
                                        </Link>
                                        <Translation>
                                            {
                                                t => <span>{t('parcel:create_parcel')}</span>
                                            }
                                        </Translation>
                                    </h3>
                                </div>
                                <div className="col">

                                    <button type="submit" form="create-parcel" disabled={this.state.loading}
                                        className="btn btn-primary add float-right">
                                        <Translation>
                                            {
                                                t => <span>{t('parcel:confirm_and_send')}</span>
                                            }
                                        </Translation>
                                        {
                                            this.state.loading &&
                                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                        }
                                    </button>

                                    {/*<Link to="">*/}
                                    {/*    <button type="button" className="btn btn-outline-primary float-right add mr-3">*/}
                                    {/*        <Translation>*/}
                                    {/*            {*/}
                                    {/*                t => <span>{t('parcel:save_draft')}</span>*/}
                                    {/*            }*/}
                                    {/*        </Translation>*/}
                                    {/*    </button>*/}
                                    {/*</Link>*/}

                                    <Link to="/contact/parcel/not_receive">
                                        <button type="button" className="btn btn-secondary float-right add mr-3">
                                            <Translation>
                                                {
                                                    t => <span>{t('parcel:cancel')}</span>
                                                }
                                            </Translation>
                                        </button>
                                    </Link>
                                </div>
                            </div>

                            <div className="content-inner">
                                <div className="card mt-4 fade-up">
                                    <table className="table table-hover">
                                        <thead className="thead-light">
                                            <tr>
                                                <th />
                                                <Translation>{t => <th width={140}>{t("parcel:Unit")}</th>}</Translation>
                                                <Translation>{t => <th>{t("parcel:Recipient name")}</th>}</Translation>
                                                <Translation>{t => <th>Tracking</th>}</Translation>
                                                <Translation>{t => <th>{t("parcel:Sent by")}</th>}</Translation>
                                                <Translation>{t => <th>{t("parcel:Parcel feature")}</th>}</Translation>
                                                <th>&nbsp;</th>
                                                <Translation>{t => <th>{t("parcel:Picture")}</th>}</Translation>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.parcel_create_list.map((parcel, index) =>
                                                    <tr key={index} className="show">
                                                        <td>
                                                            <h6>{index + 1}.</h6>
                                                        </td>
                                                        <td>
                                                            <SearchSelect onChange={this.handleInputChange}
                                                                value={parcel.residential_id}
                                                                name={"parcel_create_list[" + index + "].residential_id"}
                                                                queryObject={this.state.residential_list}
                                                                keyOfValue="id"
                                                                require={true}
                                                                keyOfLabel="name" />
                                                        </td>
                                                        <td width={230} >
                                                            <CreatableSelect
                                                                options={this.state.residential_householder_list}
                                                                name={"parcel_create_list[" + index + "].tenant_id"}
                                                                value={parcel?.name}
                                                                require={true}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control"
                                                                value={this.state.tracking_number}
                                                                onChange={this.handleInputChange}
                                                                name={"parcel_create_list[" + index + "].tracking_number"}
                                                                required />
                                                        </td>
                                                        <td>
                                                            <select className="form-control"
                                                                value={this.state.service_name}
                                                                onChange={this.handleInputChange}
                                                                required={true}
                                                                name={"parcel_create_list[" + index + "].service_name"}>
                                                                <Translation>{t => <option value="">{t("parcel:Please select")}</option>}</Translation>
                                                                <Translation>{t => <option value="ไปรษณีย์ไทย">{t("parcel:Thailand Post")}</option>}</Translation>
                                                                <option value="Kerry Express">Kerry Express</option>
                                                                <option value="Flash Express">Flash Express</option>
                                                                <option value="J&T Express">J&T Express</option>
                                                                <option value="Ninja Van">Ninja Van</option>
                                                                <option value="DHL">DHL</option>
                                                                <option value="Shopee">Shopee</option>
                                                                <option value="BEST Express">BEST Express</option>
                                                                <option value="Alpha Fast">Alpha Fast</option>
                                                                <option value="SCG Express">SCG Express</option>
                                                                <option value="Lazada Express">Lazada Express</option>
                                                                <option value="Delivery">Delivery</option>
                                                                <Translation>{t => <option value="อื่นๆ">{t("parcel:Other")}</option>}</Translation>
                                                            </select>

                                                        </td>
                                                        <td>
                                                            <select className="form-control"
                                                                value={this.state.description}
                                                                onChange={this.handleInputChange}
                                                                required={true}
                                                                name={"parcel_create_list[" + index + "].description"}>
                                                                <Translation>{t => <option value="">{t("parcel:Please select")}</option>}</Translation>
                                                                <Translation>{t => <option value="ห่อเล็ก">{t("parcel:Small Pack")}</option>}</Translation>
                                                                <Translation>{t => <option value="ห่อกลาง">{t("parcel:Medium Pack")}</option>}</Translation>
                                                                <Translation>{t => <option value="ห่อใหญ่">{t("parcel:Large Pack")}</option>}</Translation>
                                                                <Translation>{t => <option value="ซองจดหมาย/เอกสาร">{t("parcel:Envelope/Document")}</option>}</Translation>
                                                                <Translation>{t => <option value="ซองขนาดเล็ก">{t("parcel:Small envelope")}</option>}</Translation>
                                                                <Translation>{t => <option value="ซองขนาดกลาง">{t("parcel:Medium Sachet")}</option>}</Translation>
                                                                <Translation>{t => <option value="ซองขนาดใหญ่">{t("parcel:Large envelope")}</option>}</Translation>
                                                                <Translation>{t => <option value="กล่องขนาดเล็ก">{t("parcel:Small box")}</option>}</Translation>
                                                                <Translation>{t => <option value="กล่องขนาดกลาง">{t("parcel:Medium box")}</option>}</Translation>
                                                                <Translation>{t => <option value="กล่องขนาดใหญ่">{t("parcel:Large box")}</option>}</Translation>
                                                                <Translation>{t => <option value="อื่นๆ">{t("parcel:Other")}</option>}</Translation>
                                                            </select>

                                                        </td>
                                                        <td>
                                                            {(this.state.parcel_create_list[index].description === "อื่นๆ" || this.state.parcel_size_etc !== '') &&
                                                                <input type="text" className="form-control"
                                                                    value={this.state.description}
                                                                    onChange={this.handleInputChange}
                                                                    name={"parcel_create_list[" + index + "].description"}
                                                                />
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="upload-file">
                                                                {parcel.image_upload ?
                                                                    <React.Fragment>
                                                                        <img src={URL.createObjectURL(parcel.image_upload)}
                                                                            alt="parcel"
                                                                            className="parcel-img" />
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/cancel-image-icon.png'}
                                                                            alt="cancel-icon"
                                                                            className="cancel-img img-fluid"
                                                                            onClick={() => this.cancelImg(index)} />

                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <label htmlFor={"uploadFile" + index}
                                                                            className="create btn btn-outline-secondary mt-2">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/plus-icon.png'}
                                                                                alt="plus-icon"
                                                                                className="img-fluid plus" />
                                                                        </label>
                                                                        <div className="upload-input">
                                                                            <UploadImageInput
                                                                                id={"uploadFile" + index}
                                                                                index={index}
                                                                                setField={"parcel_create_list[" + index + "].image_upload"}
                                                                                onChange={this.handleInputChange} />
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td width="60">
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                alt="delete-icon"
                                                                onClick={() => this.deleteRow(index)}
                                                                className="show-icon" />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="row mt-4">
                                    <div className="col">
                                        <Translation>
                                            {t =>
                                                <button type="button" className="btn btn-light bt-style"
                                                    onClick={this.appendNewRow}>
                                                    + {t("parcel:Add list")}
                                                </button>}
                                        </Translation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default CreateParcel;
