export function getTypeAsset(code) {
    if(code === '1210-01'){
        return 'land'
    }
    else if(code === '1210-02'){
        return 'building'
    }
    else if(code === '1210-03'){
        return 'building_renovation'
    }
    else if(code === '1210-04'){
        return 'office_decoration'
    }
    else if(code === '1210-05'){
        return 'office_equipment'
    }
    else if(code === '1210-06'){
        return 'tools'
    }
    else if(code === '1210-07'){
        return 'training_equipment'
    }
    else if(code === '1210-08'){
        return 'vehicle'
    }
    else if(code === '1210-09'){
        return 'common_property'
    }
    else if(code === '1210-10'){
        return 'other_asset'
    }else{
        return 'intangible_asset'
    } 
}
export default {getTypeAsset};