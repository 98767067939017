import React from "react";
import { format } from 'date-fns'
import i18next from "i18next";
import { Translation } from "react-i18next";


class BalanceSheetAccountHeader extends React.Component {

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <thead>
                            <tr>
                                <td colSpan={4}><h5 className="text-center mb-0"><u>{this.props.header_text}</u></h5></td>
                            </tr>
                            <tr>
                                <td></td>
                                {/* <td className="text-center align-middle"><sub>(หมายเหตุ)</sub></td> */}
                                <td className="text-center align-middle">
                                    <br />
                                    <h6 className="m-0 p-0 pt-1 pb-1">
                                        ({i18next.t("BalanceSheet:Note")})
                                    </h6>
                                </td>
                                <td className="text-center align-middle"></td>
                                <td className="text-center align-bottom" width={100}>
                                    <small>{i18next.t("BalanceSheet:Unit/THB")}</small>
                                    <hr />
                                    <h6 className="m-0 p-0 pt-1 pb-1">
                                        <span>{i18next.t("monthNameShort:" + format(this.props.state.end_date_first, 'MMM'))} {format(this.props.state.end_date_first, ' YYYY')}</span>
                                    </h6>
                                    <hr />
                                </td>
                                <td className="text-center align-bottom" width={100}>
                                    <small>{i18next.t("BalanceSheet:Unit/THB")}</small>
                                    <hr />
                                    <h6 className="m-0 p-0 pt-1 pb-1">
                                        <span>{i18next.t("monthNameShort:" + format(this.props.state.end_date_second, 'MMM'))} {format(this.props.state.end_date_second, ' YYYY')}</span>
                                    </h6>
                                    <hr />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <h6>{this.props.first_title}</h6>
                                </td>
                            </tr>
                        </thead>
                    </React.Fragment>

                }
            </Translation>

        )
    }
}
export default BalanceSheetAccountHeader;
