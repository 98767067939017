import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import { fetchQuery } from "relay-runtime";
import jwtDecode from 'jwt-decode';
import environment from '../../../../env/environment';
import numberWithComma from "../../../../libs/numberWithComma";
import _ from "lodash";
import './receiveableClassifiedReportPDF.scss'

const query = graphql`
  query receivableClassifiedReportPDFRenderTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

class ReceivableClassifiedPDFRenderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            printBy: "",
            list_product_code: "",
            summary_total: "",
            summary_paid: "",
            loading: "",
            search_status: "",
            month_list: [],
            asDate: "",
            start_period: "",
            end_period: "",
            product_and_service: "",
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    data: this.props.data,
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    list_product_code: this.props.list_product_code,
                    summary_total: this.props.summary_total,
                    summary_paid: this.props.summary_paid,
                    search_status: this.props.search_status,     
                    month_list: this.props.month_list,    
                    last_index: this.props.last_index,    
                    asDate: this.props.data.asDate,
                    start_period: this.props.data.start_period,
                    end_period: this.props.data.end_period,
                    product_and_service: this.props.data.product_and_service
                })
            }
        }
        )
    }

    calPercent(not_pay, all_total) {
        let cal = (not_pay * 100) / all_total;
        if (cal > 0) {
          return numberWithComma(cal) + "%";
        } else {
          return "-";
        }
      }

      numberWithComma(num) {
        let isNegative = false;

        // if (num === 0) {
        //     return formattedNum = "-";
        //   }
        // Check if the number is negative
        if (num < 0) {
          isNegative = true;
          num = Math.abs(num); // Convert negative number to positive
        }
        // Add .00 to the number
        num = num.toFixed(2);
        // Format the number with commas
        let formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (num === 0) {
            return formattedNum = "-";
          }
        // Add a minus sign for negative numbers
        if (isNegative) {
          formattedNum = '(' + formattedNum + ')';
        }
        return formattedNum;
    }      

    render() {
        let order = 0;
        let dataAll = [];
        let transactionRow = "";
        let summary_not_pay = 0;
        //  ประมวลผลข้อมูลทั้งหมด ก่อน chunked
        {_.sortBy(this.state.list_product_code, 'node.productCode').map((product, product_index) => {
                    let index_row = 0;
                    if (product.col_List.col_all_total !== 0) {
                        order += 1
                    }
                    this.state.month_list.map((month, index) => {
                        if (product.col_List["col" + (index + 1) + '_total'] !== 0) {
                            index_row += 1;
                        }

                                if(product.col_List["col" + (index + 1) + '_total'] !== 0)  
                                        {
                                            transactionRow = (
                                            <tr>
                                                <td className="text-center">
                                                    {index_row === 1 && order}
                                                </td>
                                                <td className="text-center">
                                                    {index_row === 1 && product.node.productCode}
                                                </td>
                                                <td className="text-left">
                                                    {index_row === 1 && product.node.name}
                                                </td>
                                                <td className="text-center">
                                                    {format(month, "MM/YYYY")}
                                                </td>
                                                <td className="text-right spaceRight">
                                                    {this.numberWithComma(product.col_List["col" + (index + 1) + '_total'])}
                                                </td>
                                                <td className="text-right spaceRight">
                                                    {this.numberWithComma(product.col_List["col" + (index + 1) + '_paid'])}
                                                </td>
                                                <td className="text-right spaceRight">
                                                    {this.numberWithComma(product.col_List["col" + (index + 1) + '_not_pay'])}
                                                </td>
                                                <td className="text-right spaceRight">
                                                    {this.calPercent(product.col_List["col" + (index + 1) + '_not_pay'], product.col_List["col" + (index + 1) + '_total'])}
                                                </td>
                                            </tr>
                                            )
                                            dataAll.push(transactionRow);
                                        }

                                if(index === this.state.last_index && product.col_List["col_all_total"] !== 0 )
                                        {
                                            transactionRow = (
                                                <tr>
                                                    <td colSpan={4} className="spaceLeft">
                                                        <strong>รวม {product.node.productCode} {product.node.name}</strong>
                                                    </td>
                                                    <td className="text-right spaceRight">
                                                        <strong>
                                                        {this.numberWithComma(product.col_List["col_all_total"])}
                                                        </strong>
                                                    </td>
                                                    <td className="text-right spaceRight">
                                                        <strong>
                                                        {this.numberWithComma(product.col_List["col_all_paid"])}
                                                        </strong>
                                                    </td>
                                                    <td className="text-right spaceRight">
                                                        <strong>
                                                        {this.numberWithComma(product.col_List["col_all_not_pay"])}
                                                        </strong>
                                                    </td>
                                                    <td className="text-right spaceRight">
                                                        <strong>
                                                        {this.calPercent(product.col_List["col_all_not_pay"], product.col_List["col_all_total"])}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            )
                                            dataAll.push(transactionRow);
                                            summary_not_pay += product.col_List["col_all_not_pay"];
                                        }
                        }
                    )
            }
        )}

        let summaryTotalRow = (
            <tr>
            <td colSpan={4} className="text-center">
              <strong>รวมทั้งหมด</strong>
            </td>
            <td className="text-right spaceRight">
              <strong>
                {numberWithComma(this.state.summary_total)}
              </strong>
            </td>
            <td className="text-right spaceRight">
              <strong>
                {numberWithComma(this.state.summary_paid)}
              </strong>
            </td>
            <td className="text-right spaceRight">
              <strong>
                {numberWithComma(summary_not_pay)}
              </strong>
            </td>
            <td className="text-right spaceRight">
              <strong>
                {this.calPercent(summary_not_pay, this.state.summary_total)}
              </strong>
            </td>
          </tr>
        )
        dataAll.push(summaryTotalRow);
        
        // ประมวลผล data เพิ่อใช้ในการ render แต่ละ A4
        let dataChunked = [];
        let rowPerpage = 27;
        for (let i=0;i<dataAll.length ;i += rowPerpage) {
            let chunk = dataAll.slice(i, i + rowPerpage);
            dataChunked.push(chunk);            
        }
        let productAndService = "ทั้งหมด";
        if(this.state.product_and_service) {
            productAndService = this.state.list_product_code[0].node.productCode + " " + this.state.list_product_code[0].node.name;
        } 
        
        return (
            dataChunked.map((data, index_page)=>(
                <React.Fragment key={index_page}>
                <div className='print-page-a4 hoziA4'>
                    {/* แสดงหัวกระดาษ */}
                    <div>
                            <p className='f12' style={{ lineHeight: 1.5 }}>{this.state.projectName}</p>
                            <p className='f10'>รายงานสรุปบัญชีลูกหนี้ทั้งหมดแยกประเภทสินค้า/รายรับ</p>
                            <p className='f10'>เรียงตามประเภทสินค้าและบริการ - {productAndService}</p>
                            <p className='f10'>เรียงตามเดือนที่ {new Date(this.state.start_period).getMonth()+1}/{new Date(this.state.start_period).getFullYear()} ถึง {new Date(this.state.end_period).getMonth()+1}/{new Date(this.state.end_period).getFullYear()}</p>
                            <p className='f10'>ณ วันที่ {format(this.state.asDate, "YYYY-MM-DD")}</p>
                    </div>

                    {/* แสดง ตาราง*/}
                    <div className="row mt-3">
                        <div className="col-12">
                            <table className='table tebleBorder'>
                                <thead className='tableHead'>
                                    <tr>
                                        <th className="text-center width10">ลำดับ</th>
                                        <th className="text-center width10">รหัสบัญชี</th>
                                        <th className="text-center width30">รายละเอียด</th>
                                        <th className="text-center width10">เดือน</th>
                                        <th className="text-center width10">จำนวนเงิน</th>
                                        <th className="text-center width10">ชำระแล้ว</th>
                                        <th className="text-center width10">จำนวนเงินค้างชำระ</th>
                                        <th className="text-center width10">เปอร์เซ็นค้างชำระ</th>
                                    </tr>
                                </thead>
                                <tbody className="tableBody">
                                        {data.map((data, index)=>(
                                            <React.Fragment key={index}>
                                                {data}
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* แสดงเลขหน้า และผู้พิมพ์ */}
                    <div className="footer">
                        <div className="row">
                            <div className="col-5" />
                            <div className="col-2 text-center">
                                <p className="f10 pb-0 mb-0">{index_page+1}/{dataChunked.length}</p>
                            </div>
                            <div className="col-5 text-right">
                                <p className="f10 pb-0 mb-0">
                                    ผู้พิมพ์ {this.state.printBy}  วันที่พิมพ์{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                </p>
                            </div>
                        </div>
                    </div>      
                </div>
                </React.Fragment>
            ))
        )
    }
}
export default ReceivableClassifiedPDFRenderTable;