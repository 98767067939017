import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import PettyCashRecordCreate from "./pettyCashRecordCreate"
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Loading from '../../../libs/loading';

const pettyCashRecordForm = graphql`
    query pettyCashRecordFormQuery {
       allSetPettyCash(status:"active"){
        totalCount
        edges{
          node{
            id
            docNumber
            description
            withdrawer
          }
        }
      }
       viewer {

        group5 : allChartOfAccount(chartOfAccountCode_Istartswith:"5"){
            totalCount
            edges{
                node {
                    id
                    chartOfAccountCode
                    name
                }
            }
        }
        group2 : allChartOfAccount(chartOfAccountCode_Istartswith:"2"){
            totalCount
            edges{
                node {
                    id
                    chartOfAccountCode
                    name
                }
            }
        }
        group1 : allChartOfAccount(chartOfAccountCode_Istartswith:"1"){
            totalCount
            edges{
                node {
                    id
                    chartOfAccountCode
                    name
                }
            }
        }
        
    }
    allSettingAsset(settingAssetId: "", typeAsset: ""){
            edges{
                node{
                    id
                    typeAsset
                    depreciationRatePercent
                    depreciationRate
                    lifeTimeYear
                }
            }
        }
    
}
`;

class PettyCashRecordForm extends Component {
    render() {
            return (
                <Wrapper>
                    <Header/>
                    <Sidebar/>
                    <div id="wrapper-content">
                        <AccountingTopMenuNavigation mini={true}/>
                        <div className="container-fluid box">
                            <div className="row">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/accounting/expense/petty_cash/list/record">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                 alt="back" className="back"/>
                                        </Link>
                                        <Translation>
                                            {
                                                t => <span>{t('pettyCash:petty_cash')}</span>
                                            }
                                        </Translation>
                                    </h3>
                                </div>
                            </div>
                            <QueryRenderer
                                environment={environment}
                                query={pettyCashRecordForm}
                                render={({error, props}) => {
                                    if (props) {
                                        let no_setting_asset = true
                                        if(props.allSettingAsset?.edges.length === 11){
                                            no_setting_asset = false
                                        }
                                        return <Translation>{(t)=> <PettyCashRecordCreate query={props} id_parms={this.props.match.params.id} no_setting_asset={no_setting_asset}/>}</Translation>;
                                    } else {
                                        return <Loading/>
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Wrapper>
            )
    }
}

export default PettyCashRecordForm;
