import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import HeadOfDevelopDashboard from './developerDashboard';
import NoMatch from "../components/noMatch";
import UnitStatus from "./unitStatus";
import RevenueContract from './revenueContract';
import UnitReport from "./unitReport"
import GeneralInfoDashboard from './generalInformation/generalInfoDashboard';

class Developer extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={HeadOfDevelopDashboard}/>
                <PrivateRoute path={`${this.props.match.url}/unit-status`} component={UnitStatus}/>
                <PrivateRoute path={`${this.props.match.url}/revenue-contract`} component={RevenueContract}/>
                <PrivateRoute path={`${this.props.match.url}/unit-report`} component={UnitReport}/>
                <PrivateRoute exact path={`${this.props.match.url}/general-info`} component={GeneralInfoDashboard}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Developer;