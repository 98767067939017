import React, { Component } from 'react'
import _ from "lodash"
import { format } from 'date-fns';
import numberWithComma from "../../../../libs/numberWithComma";

class budgetDraftYearCompareAverageYear extends Component {

  summaryRevenue = (codeValue) => {
    let result = 0;
    let sumRevenue_year_summaryYear_total_budget = 0;
    let sumRevenue_year_total_budget = 0;
    let sumRevenue_mount_summaryYear_total_budget = 0;
    let sumRevenue_mount_total_budget = 0;

    sumRevenue_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
    sumRevenue_year_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.total_budget || 0))
    sumRevenue_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
    sumRevenue_mount_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.total_budget / 12))
    // summaryYear_total_budget

    switch (codeValue) {
      case "4000-00 year_summaryYear_total_budget":
        result = sumRevenue_year_summaryYear_total_budget
        break;
      case "4000-00 year_total_budget":
        result = sumRevenue_year_total_budget
        break;
      case "4000-00 mount_summaryYear_total_budget":
        result = sumRevenue_mount_summaryYear_total_budget
        break;
      case "4000-00 mount_total_budget":
        result = sumRevenue_mount_total_budget
        break;
    }
    return result;

  }

  summaryExpense = (codeValue) => {
    let result = 0;
    let sumExpense_year_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_year_otherExpenses_total_budget = 0;
    let sumExpense_mount_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_mount_otherExpenses_total_budget = 0;

    let sumExpense_year_summaryYear_total_budget = 0;
    let sumExpense_year_total_budget = 0;
    let sumExpense_mount_summaryYear_total_budget = 0;
    let sumExpense_mount_total_budget = 0;

    if (codeValue.substring(0, 7) === "5800-00") {
      _.forIn(this.props.state.lists[1].children[8].children, function (item) {
        sumExpense_year_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget || 0))
        sumExpense_year_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget || 0))
        sumExpense_mount_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget / 12))
        sumExpense_mount_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget / 12))
      })
    } else {
      if (codeValue.substring(0, 7) === "5100-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5200-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5300-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5310-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5400-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5500-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget / 12))
      }
      if (codeValue.substring(0, 7) === "5600-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget / 12) )
      }
      if (codeValue.substring(0, 7) === "5700-00") {
        sumExpense_year_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_year_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_mount_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget / 12))
        sumExpense_mount_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget / 12))
      }
    }

    switch (codeValue) {
      case "5800-00 year_summaryYear_total_budget":
        result = sumExpense_year_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 mount_summaryYear_total_budget":
        result = sumExpense_mount_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 year_total_budget":
        result = sumExpense_year_otherExpenses_total_budget
        break;
      case "5800-00 mount_total_budget":
        result = sumExpense_mount_otherExpenses_total_budget
        break;
      case `${codeValue.substring(0, 7)} year_summaryYear_total_budget`:
        result = sumExpense_year_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} mount_summaryYear_total_budget`:
        result = sumExpense_mount_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} year_total_budget`:
        result = sumExpense_year_total_budget
        break;
      case `${codeValue.substring(0, 7)} mount_total_budget`:
        result = sumExpense_mount_total_budget
        break;
    }
    return result;

  }

  render() {
    let last_index = 0;
    let last_index_child = 0;
    let sumExpense_year_summaryYear_total_budget = 0;
    let sumExpense_mount_summaryYear_total_budget = 0;
    let sumExpense_year_total_budget = 0;
    let sumExpense_mount_total_budget = 0;
    return (
      <div className="fade-up" id="parcel" style={{width: "95%"}}>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th rowSpan={2} className="text-center">
                  ลำดับ
                </th>
                <th rowSpan={2} className="text-center">
                  รายการ
                </th>
                <th
                  colSpan={2}
                  className="text-center"
                >
                  งบประมาณปี {this.props.state.temp_year.getFullYear() - 1}
                </th>
                <th
                  colSpan={2}
                  className="text-center"
                >
                  งบประมาณปี {format(this.props.state.temp_start_date, "YYYY")}
                </th>
              </tr>
              <tr className="border-top">
                <th className="text-center" style={{ minWidth: 145 }}>รายเดือน</th>
                <th className="text-center" style={{ minWidth: 145 }}>รายปี</th>
                <th className="text-center" style={{ minWidth: 145 }}>รายเดือน</th>
                <th className="text-center" style={{ minWidth: 145 }}>รายปี</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.props.state.lists[0]?.children, (item, index) => {
                ++last_index;
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="text-center" width={105}>{last_index}</td>
                      <td
                        style={{ paddingLeft: 50, minWidth: 420 }}
                        width={450}
                      >
                        {index == 0 && (
                          <div style={{ marginLeft: -32 }}>
                            รายรับ
                          </div>
                        )}
                        {item.data.name}
                      </td>
                      <td height={24} className="text-center td-red-scale" >
                        {item.data?.summaryYear?.total_budget !== "" 
                          ? parseFloat(item.data?.summaryYear?.total_budget / 12).toFixed(2) : ""}
                      </td>
                      <td
                        className="td-red-scale"
                        height={24}
                      >
                        <input
                          disabled={item.data?.summaryYear?.total_budget_check || this.props.disabledApprove()}
                          type="number"
                          className="form-control text-center td-red-scale"
                          style={{ maxHeight: 24 }}
                          value={
                            item.data?.summaryYear?.total_budget || ""
                          }
                          onChange={this.props.handleChangeInput}
                          name={`lists[0].children[${index}].data.summaryYear.total_budget`}
                        />
                      </td>
                      <td height={24} className="text-center">
                        {item.data?.total_budget !== ""
                          ? parseFloat(item.data?.total_budget / 12).toFixed(2) : ""}
                      </td>
                      <td
                        height={24}
                      >
                        <input
                          disabled={this.props.disabledApprove()}
                          type="number"
                          className="form-control text-center"
                          style={{ maxHeight: 24 }}
                          value={
                            item.data?.total_budget || ""
                          }
                          onChange={(e) =>
                            this.props.handleChangeInputMount(e,
                              `lists[0].children[${index}].data.month_budget`, `lists[0].children[${index}].data.month_budget`, "total_budget")
                          }
                          name={`lists[0].children[${index}].data.total_budget`}
                        />
                      </td>
                    </tr>
                    {index === this.props.state.lists[0].children.length - 1 && (
                      <tr className="td-sum">
                        <td></td>
                        <td
                          style={{paddingLeft: 50}}
                        >
                          รวมรายรับ
                        </td>
                        <td
                          height={24}
                          className="text-center"
                          style={{ minWidth: 65, width: 135 }}
                        >
                          {this.summaryRevenue("4000-00 mount_summaryYear_total_budget") !== 0 ? 
                            numberWithComma(this.summaryRevenue("4000-00 mount_summaryYear_total_budget").toFixed(2)) : "-"} 
                        </td>
                        <td
                          height={24}
                          className="text-center"
                          style={{ minWidth: 65, width: 135 }}
                        >
                          {this.summaryRevenue("4000-00 year_summaryYear_total_budget") !== 0 ? 
                            numberWithComma(this.summaryRevenue("4000-00 year_summaryYear_total_budget").toFixed(2)) : "-"} 
                        </td>
                        <td
                          height={24}
                          className="text-center"
                          style={{ minWidth: 65, width: 135 }}
                        >
                          {this.summaryRevenue("4000-00 mount_total_budget") !== 0 ?
                            numberWithComma(this.summaryRevenue("4000-00 mount_total_budget").toFixed(2)) : "-"} 
                        </td>
                        <td
                          height={24}
                          className="text-center"
                          style={{ minWidth: 65, width: 135 }}
                        >
                          {this.summaryRevenue("4000-00 year_total_budget") !== 0 ? 
                            numberWithComma(this.summaryRevenue("4000-00 year_total_budget").toFixed(2)) : "-"} 
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
              {_.map(this.props.state.lists[1]?.children, (item, index_code) => {

                sumExpense_year_summaryYear_total_budget += this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`)
                sumExpense_mount_summaryYear_total_budget += this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`)
                sumExpense_year_total_budget += this.summaryExpense(`${item.data.code} year_total_budget`)
                sumExpense_mount_total_budget += this.summaryExpense(`${item.data.code} mount_total_budget`)

                return (
                  <React.Fragment key={index_code}>
                    <tr>
                      <td />
                      <td>
                        <div className="ml-2">
                          {index_code === 0 && <div>รายจ่าย</div>}
                          <div>{item.data.name}</div>
                        </div>
                      </td>
                      <td className="td-red-scale" />
                      <td className="td-red-scale" />
                      <td />
                      <td />
                    </tr>
                    {_.map(item.children, (item_child, index) => {
                      if (index_code !== 8) { ++last_index }
                      return (
                        <React.Fragment key={index}>
                          {item_child.children?.length > 0 ?
                            <React.Fragment>
                              <tr>
                                <td />
                                <td
                                  style={{ paddingLeft: 50 }}
                                >
                                  {item_child.data.name}
                                </td>
                                <td className="td-red-scale" />
                                <td className="td-red-scale" />
                                <td />
                                <td />
                              </tr>
                              {_.map(item_child?.children, (item_child_child, index_child_child) => {
                                ++last_index_child
                                return (
                                  <React.Fragment key={index_child_child}>
                                    <tr>
                                      <td className="text-center">
                                        {last_index + last_index_child}
                                      </td>
                                      <td
                                        style={{ paddingLeft: 100}}
                                      >
                                        {item_child_child.data.name}
                                      </td>
                                      <td height={24} className="text-center td-red-scale" >
                                        {item_child_child.data?.summaryYear?.total_budget !== ""
                                          ? parseFloat(item_child_child.data?.summaryYear?.total_budget / 12).toFixed(2) : ""}
                                      </td>
                                      <td
                                        className="td-red-scale"
                                        height={24}
                                      >
                                        <input
                                          disabled={item_child_child.data?.summaryYear?.total_budget_check || this.props.disabledApprove()}
                                          type="number"
                                          className="form-control text-center td-red-scale"
                                          style={{ maxHeight: 24 }}
                                          value={
                                            item_child_child.data?.summaryYear?.total_budget || ""
                                          }
                                          onChange={this.props.handleChangeInput}
                                          name={`lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.summaryYear.total_budget`}
                                        />
                                      </td>
                                      <td height={24} className="text-center">
                                        {item_child_child.data?.total_budget !== ""
                                          ? parseFloat(item_child_child.data?.total_budget / 12).toFixed(2) : ""}
                                      </td>
                                      <td
                                        height={24}
                                      >
                                        <input
                                          disabled={this.props.disabledApprove()}
                                          type="number"
                                          className="form-control text-center "
                                          style={{ maxHeight: 24 }}
                                          value={
                                            item_child_child.data?.total_budget || ""
                                          }
                                          name={`lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.total_budget`}
                                          onChange={(e) =>
                                            this.props.handleChangeInputMount(e,
                                              `lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.month_budget`,
                                              `lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.month_budget`, "total_budget"
                                          )}
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              })}
                            </React.Fragment> :
                            <React.Fragment>
                              <tr>
                                <td className="text-center">
                                  {last_index}
                                </td>
                                <td
                                  style={{ paddingLeft: 50 }}
                                >
                                  {item_child.data.name}
                                </td>
                                <td height={24} className="text-center td-red-scale" >
                                  {item_child.data?.summaryYear?.total_budget !== ""
                                    ? parseFloat(item_child.data?.summaryYear?.total_budget / 12).toFixed(2) : ""}
                                </td>
                                <td
                                  className="td-red-scale"
                                  height={24}
                                >
                                  <input
                                    disabled={item_child.data?.summaryYear?.total_budget_check || this.props.disabledApprove()}
                                    type="number"
                                    className="form-control text-center td-red-scale"
                                    style={{ maxHeight: 24 }}
                                    value={
                                      item_child.data?.summaryYear?.total_budget || ""
                                    }
                                    onChange={this.props.handleChangeInput}
                                    name={`lists[1].children[${index_code}].children[${index}].data.summaryYear.total_budget`}
                                  />
                                </td>
                                <td height={24} className="text-center" >
                                  {item_child.data?.total_budget !== ""
                                    ? parseFloat(item_child.data?.total_budget / 12).toFixed(2) : ""}
                                </td>
                                <td
                                  height={24}
                                >
                                  <input
                                    disabled={this.props.disabledApprove()}
                                    type="number"
                                    className="form-control text-center "
                                    style={{ maxHeight: 24 }}
                                    value={
                                      item_child.data?.total_budget || ""
                                    }
                                    name={`lists[1].children[${index_code}].children[${index}].data.total_budget`}
                                    onChange={(e) =>
                                      this.props.handleChangeInputMount(e,
                                        `lists[1].children[${index_code}].children[${index}].data.month_budget`, `lists[1].children[${index_code}].children[${index}].data.month_budget`, "total_budget")
                                    }
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          }
                        </React.Fragment>
                      );
                    })}

                    <tr className="td-sum ">
                      <td />
                      <td
                        style={{ paddingLeft: 50 }}
                      >
                        รวม{item.data.name}
                      </td>
                      <td
                        height={24}
                        className="text-center"
                      >
                        {this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`) !== 0 ?
                          numberWithComma(this.summaryExpense(`${item.data.code} mount_summaryYear_total_budget`).toFixed(2)) : "-"} 
                      </td>
                      <td
                        height={24}
                        className="text-center"
                      >
                        {this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`) !== 0 ?
                          numberWithComma(this.summaryExpense(`${item.data.code} year_summaryYear_total_budget`).toFixed(2)) : "-"} 
                      </td>
                      <td
                        height={24}
                        className="text-center"
                      >
                        {this.summaryExpense(`${item.data.code} mount_total_budget`) !== 0 ?
                          numberWithComma(this.summaryExpense(`${item.data.code} mount_total_budget`).toFixed(2)) : "-"} 
                      </td>
                      <td
                        height={24}
                        className="text-center"
                      >
                        {this.summaryExpense(`${item.data.code} year_total_budget`) !== 0 ?
                          numberWithComma(this.summaryExpense(`${item.data.code} year_total_budget`).toFixed(2)) : "-"} 
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              <tr className="td-sum2 ">
                <td />
                <td
                  style={{ paddingLeft: 50 }}
                >
                  รวมรายจ่าย
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {sumExpense_mount_summaryYear_total_budget !== 0 
                    ? numberWithComma(sumExpense_mount_summaryYear_total_budget.toFixed(2)) : "-"}  
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {sumExpense_year_summaryYear_total_budget !== 0 
                    ? numberWithComma(sumExpense_year_summaryYear_total_budget.toFixed(2)) : "-"}  
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {sumExpense_mount_total_budget !== 0 
                    ? numberWithComma(sumExpense_mount_total_budget.toFixed(2)) : "-"}  
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {sumExpense_year_total_budget !== 0 
                    ? numberWithComma(sumExpense_year_total_budget.toFixed(2)) : "-"}  
                </td>
              </tr>
              <tr className="td-sum3">
                <td />
                <td
                  style={{ paddingLeft: 50 }}
                >
                  รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {(this.summaryRevenue("4000-00 mount_summaryYear_total_budget") - sumExpense_mount_summaryYear_total_budget) !== 0
                    ? numberWithComma((this.summaryRevenue("4000-00 mount_summaryYear_total_budget") - sumExpense_mount_summaryYear_total_budget).toFixed(2)) : "-"} 
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {(this.summaryRevenue("4000-00 year_summaryYear_total_budget") - sumExpense_year_summaryYear_total_budget) !== 0 
                    ? numberWithComma((this.summaryRevenue("4000-00 year_summaryYear_total_budget") - sumExpense_year_summaryYear_total_budget).toFixed(2)) : "-"} 
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {(this.summaryRevenue("4000-00 mount_total_budget") - sumExpense_mount_total_budget) !== 0
                  ? numberWithComma((this.summaryRevenue("4000-00 mount_total_budget") - sumExpense_mount_total_budget).toFixed(2)) : "-"} 
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {(this.summaryRevenue("4000-00 year_total_budget") - sumExpense_year_total_budget) !== 0 
                  ? numberWithComma((this.summaryRevenue("4000-00 year_total_budget") - sumExpense_year_total_budget).toFixed(2)) : "-"} 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
export default budgetDraftYearCompareAverageYear;
