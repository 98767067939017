import React from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import ComponentPagination from "../../libs/componentPagination";

import _ from "lodash";
import Pagination from "../../libs/newPagination";
import { fetchQuery } from "relay-runtime";
import DatePicker from "react-datepicker/es";
import { setDate, getMonth, getYear, subMonths } from "date-fns";
import ExportElectricMeterCompare from "./serverExportElectricMeterCompare";
import numberWithComma from "../../libs/numberWithComma";
import Loading from "../../libs/loading"

var time;
const query = graphql`
  query electricMeterListCompareQuery(
    $search: String
    $first: Int
    $last: Int
    $month: Float
    $year: Float
    $previous_month: Float
    $pre_year: Float
  ) {
    current_month: allResidential(
      search: $search
      first: $first
      last: $last
      order: "added"
    ) {
      edges {
        node {
          id
          name
          electricMeterUnit(month: $month, year: $year) {
            edges {
              node {
                id
                month
                year
                meterValue
                changeElectricMeter
              }
            }
          }
        }
      }
      totalCount
    }
    previous_month: allResidential(first: $first, last: $last, order: "added") {
      edges {
        node {
          id
          name
          electricMeterUnit(month: $previous_month, year: $pre_year) {
            edges {
              node {
                id
                month
                year
                meterValue
              }
            }
          }
        }
      }
      totalCount
    }
    productViewer {
      allProduct(
        isActive: true
        type: "service"
        servicePricing_PricingType: "electric_meter"
      ) {
        edges {
          node {
            id
            name
            price
          }
        }
      }
    }
  }
`;

class ElectricMeterListCompare extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.meter_data = false;
    this.state.search = "";
    this.state.search_input = "";
    this.state.total_water_price = 0;
    this.getMeterList = this.getMeterList.bind(this);
    this.meterInputHandle = this.meterInputHandle.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {
    let date_month_now = new Date();
    let current_month = getMonth(date_month_now) + 1;
    this.setState({
      current_month: current_month,
      table_data_list: {}, // meter data for render table
      start_date: setDate(date_month_now, 1),
    });
  }

  componentDidMount() {
    this.getMeterList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.first !== this.state.first ||
      prevState.last !== this.state.last
    ) {
      this.getMeterList();
    }
    if (prevState.search !== this.state.search) {
      clearTimeout(time);
      time = setTimeout(() => {
        this.getMeterList();
      }, 500);
    }
  }

  getMeterList() {
    this.setState({ meter_data: false }, () => {
      let pre_month = subMonths(this.state.start_date, 1);
      let variables = {
        first: this.state.first,
        last: this.state.last,
        previous_month: getMonth(pre_month) + 1,
        month: getMonth(this.state.start_date) + 1,
        year: getYear(this.state.start_date),
        pre_year: getYear(pre_month),
        search: this.state.search,
      };

      fetchQuery(environment, query, variables).then((results) => {
        let data = _.cloneDeep(results);
        let table_data_list = {};
        let residential_id = [];
        data.previous_month.edges.forEach((residential, index) => {
          table_data_list[residential.node.id] = [];
          residential_id.push(residential.node.id);
          residential.node.electricMeterUnit.edges.forEach((water) => {
            table_data_list[residential.node.id].push({
              value: water.node.meterValue,
              edit: false,
              warning: false,
              disable: false,
            });
          });
          if (residential.node.electricMeterUnit.edges.length < 1) {
            table_data_list[residential.node.id].push({
              value: 0,
              edit: false,
              warning: false,
              disable: false,
            });
          }
        });
        data.current_month.edges.forEach((residential, index) => {
          residential.node.electricMeterUnit.edges.forEach((water) => {
            table_data_list[residential.node.id].push({
              value: water.node.meterValue,
              edit: false,
              warning: false,
              disable: false,
              is_change: water.node.changeElectricMeter,
            });
            table_data_list[residential.node.id].push({
              value: 0,
              disable: true,
            });
          });
          if (residential.node.electricMeterUnit.edges.length < 1) {
            table_data_list[residential.node.id].push({
              value: 0,
              edit: false,
              warning: false,
              disable: false,
            });
            table_data_list[residential.node.id].push({
              value: 0,
              disable: true,
            });
          }
        });

        data.productViewer.allProduct.edges.forEach((service, index) => {
          residential_id.forEach((id) => {
            table_data_list[id].push({ value: 0, disable: true });
          });
        });

        this.setState(
          {
            meter_data: data,
            table_data_list: table_data_list,
          },
          () => this.calMeterUsed()
        );
      });
    });
  }

  calMeterUsed() {
    let table_data_list = _.cloneDeep(this.state.table_data_list);
    let total_water_price = 0;
    this.state.meter_data.current_month.edges.forEach((residential, index) => {
      this.state.table_data_list[residential.node.id].forEach(
        (meter, meter_index) => {
          if (meter_index === 2) {
            let used = 0;
            if (table_data_list[residential.node.id][1].is_change) {
              used = table_data_list[residential.node.id][1].value;
            } else {
              used =
                table_data_list[residential.node.id][1].value -
                table_data_list[residential.node.id][0].value;
            }
            _.set(
              table_data_list,
              "[" + residential.node.id + "][" + meter_index + "].value",
              used
            );
          }
          if (meter_index !== 0 && meter_index !== 1 && meter_index !== 2) {
            let cal_water_price =
              _.get(table_data_list, "[" + residential.node.id + "][2].value") *
              this.state.meter_data.productViewer.allProduct.edges[
                meter_index - 3
              ].node.price;

            total_water_price += cal_water_price;
            _.set(
              table_data_list,
              "[" + residential.node.id + "][" + meter_index + "].value",
              cal_water_price
            );
          }
        }
      );
    });
    this.setState({
      table_data_list: table_data_list,
      total_water_price: total_water_price,
    });
  }

  meterInputHandle(e) {
    // ว่างเพราะไม่ต้องการแก้ไข ใส่เพื่อไม่ให้มี error
  }

  handleChangeStart(date) {
    this.setState(
      {
        start_date: date,
      },
    );
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState(
      {
        search: event.target.value,
      },
      () => this.getMeterList()
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-8">
            <h3>
              <Link to="/accounting">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                  }
                  alt="back"
                  className="back"
                />
              </Link>
              <Translation>
                {(t) => <span>{t("accounting:electric meter record")}</span>}
              </Translation>
            </h3>
          </div>

          <div className="col-6 form-inline ml-auto mr-0 mb-2">
            {_.some(JSON.parse(localStorage.getItem("permission_list")), {
              codename: "accounting_electric_meter_print",
            }) && <ExportElectricMeterCompare state={this.state} />}
            <form action="" className="ml-3">
              <DatePicker
                id="start_date"
                className="form-control"
                selected={this.state.start_date}
                onChange={this.handleChangeStart}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </form>
            <Translation>
              {t=>
              <div
                className="input-group float-right w-auto"
                style={{ marginLeft: 8 }}
              >
                <input
                  type="text"
                  className="form-control input-size"
                  placeholder={t("electric_meter_record:Search")}
                  value={this.state.search_input}
                  name="search"
                  onChange={(e) =>
                    this.setState({ search_input: e.target.value })
                  }
                  onKeyDown={this.handleKeyDown}
                />
                <button
                  type="submit"
                  className="btn btn-primary form-control search-button"
                  name="search"
                  value={this.state.search_input}
                  onClick={(e) => this.handleSearch(e)}
                >
                  {t("electric_meter_record:Search")}
                </button>
              </div>}
            </Translation>
          </div>
        </div>
        <div className="content-inner">
          <div className="row">
            {this.state.meter_data && (
              <React.Fragment>
                <div className="col-12">
                  <form className="card">
                    <table className="table table-bordered">
                      <thead>
                        <Translation>
                          {t=>
                          <tr className="bg-light text-center">
                            <th width="100">{t("electric_meter_record:Room No.")}</th>
                            <th width="60">{t("electric_meter_record:Previous month meter")}</th>
                            <th width="60">{t("electric_meter_record:Current month meter")}</th>
                            <th width="60">{t("electric_meter_record:Used")}</th>
                            {this.state.meter_data.productViewer.allProduct.edges.map(
                              (service) => {
                                return (
                                  <th width="60" key={service.node.id}>
                                    {t("electric_meter_record:Amount charged")} ({t("electric_meter_record:unit price")}{" "}
                                    {service.node.price})
                                  </th>
                                );
                              }
                            )}
                          </tr>}
                        </Translation>
                      </thead>
                      <tbody>
                        {this.state.meter_data.current_month.edges.map(
                          (residential, residential_index) => (
                            <tr key={residential.node.id}>
                              <td className="bg-light">
                                {residential.node.name}
                              </td>
                              {this.state.table_data_list[
                                residential.node.id
                              ].map((meter, index) => {
                                return (
                                  <React.Fragment key={"table-" + index}>
                                    <td className="table-meter">
                                      <input
                                        name={
                                          residential.node.id +
                                          "." +
                                          index +
                                          ".value"
                                        }
                                        value={meter["value"]}
                                        type="number"
                                        onChange={this.meterInputHandle}
                                        disabled={meter["disable"]}
                                        className={
                                          "meter form-control " +
                                          (meter["value"] < 0 && "text-red")
                                        }
                                      />
                                      {meter["warning"] && (
                                        <Translation>
                                          {t=>
                                          <div className="warning">
                                            {t("electric_meter_record:Record number less than the previous month")}
                                          </div>}
                                        </Translation>
                                      )}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>
                          )
                        )}
                        {this.state.meter_data.productViewer.allProduct.edges.map(
                          (service) => {
                            return (
                              <tr key={service.node.id}>
                                <td className="bg-light" />
                                <td className="bg-light" />
                                <td className="bg-light" />
                                <Translation>{t=><td className="bg-light text-right">{t("electric_meter_record:Total")}</td>}</Translation>
                                <td className="bg-light text-right">
                                  {numberWithComma(
                                    this.state.total_water_price,
                                    "0.00"
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </form>
                </div>

                <Pagination
                  changePage={this.changePage}
                  first={this.state.first}
                  last={this.state.last}
                  totalCount={this.state.meter_data.current_month.totalCount}
                />

                <div style={{ height: 200 }} />
              </React.Fragment>
            )}
            {!this.state.meter_data && <Loading/>}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ElectricMeterListCompare;
