import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import {commitMutation} from 'react-relay'
import environment from "../../../../env/environment";
import i18next from 'i18next';

const mutation = graphql`
    mutation otherExpenseDeleteMutation($input: DeleteTransactionInput!) {
        deleteTransaction(input: $input){
            ok
        }
    }`;

class OtherExpenseDelete extends Component {
    constructor(props) {
        super(props);
        this.onDeleteItem = this.onDeleteItem.bind(this)

    }

    onDeleteItem() {
        if (this.props.transaction.id === "" || this.props.transaction.id === undefined) {
            this.deleteRow()
        } else {
            this.mutationDelete()
        }
    }

    mutationDelete() {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                const variables = {
                    input: {
                        id: this.props.transaction.id
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.deleteTransaction.ok) {
                                Swal.fire('ลบสำเร็จ!', '', 'success');
                                this.deleteRow()
                            } else {
                                Swal.fire('ลบไม่สำเร็จ!', '', 'warning'
                                );
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                        },
                    },
                )

            }
        })
    }

    deleteRow() {
        let transaction_list_new = [...this.props.transaction_list];
        let index = transaction_list_new.indexOf(this.props.transaction);
        if (index !== -1) {
            transaction_list_new.splice(index, 1)
        }
        this.props.setTransactionList(transaction_list_new)
    }


    render() {
        return (
            <React.Fragment>
                {
                    this.props.transaction_list.length > 1 &&
                    <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                         alt="delete" className="btn-delete cursor"
                         onClick={this.onDeleteItem}/>
                }
            </React.Fragment>
        )
    }
}

export default OtherExpenseDelete;
