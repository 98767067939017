import React from 'react';
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import _ from "lodash";
import Loading from "../../libs/loading"
import BackButtonIcon from '../../components/BackBtn/indexBack';
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from '../../accounting/accountingTopMenuNavigation';

const query = graphql`
    query wrapperContactAllSelectQuery($search: String) {
        selfProject {
            id
            name
            address
            taxIdNumber
            juristicContactNumber
            logo
        }
        contactViewer {
            id
            allContact(search:$search, order: "ref_number") {
                totalCount
                edges {
                    node {
                        id
                        refNumber
                        name
                        mainEmail
                        email
                        typeOfPayment
                        typeOfContact
                        totalAmount
                        residential {
                            id
                            name
                        }
                        firstName
                        lastName
                        mainPhone
                        phone

                        firstNameRenter
                        lastNameRenter
                        emailRenter
                        phoneRenter

                        firstNameAgent
                        lastNameAgent
                        emailAgent
                        phoneAgent

                        isActive

                        mailingAddress
                        mailingCity
                        mailingCountry
                        mailingDistrict
                        mailingName
                        mailingPostalCode
                        mailingProvince
                        registeredName


                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode

                        renterAddress
                        renterCountry
                        renterProvince
                        renterDistrict
                        renterCity
                        renterPostalCode
                        
                        agentAddress
                        agentCountry
                        agentProvince
                        agentDistrict
                        agentCity
                        agentPostalCode



                    }
                }
            }
        }

    }
`;


class WrapperContactAllSelect extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            selfProject: {},
            selectNOwNumber: 0,
            selectNow: [],
            data: [],
            dataViews: [],
            selectAllChk: false,
            percentLoading: null,
            loading: true,
            searchBox: "",
            searchNotEnter: "",
            statusPrintFor: "all"
        }
        this.selectNow = this.selectNow.bind(this)
        this.removeSelectAll = this.removeSelectAll.bind(this)
        this.selectAllRoom = this.selectAllRoom.bind(this)
        this.handleInput = this.handleInput.bind(this)
        this.serach = this.serach.bind(this)
        this.serachEnter = this.serachEnter.bind(this)
        this.onChangeToPrintSelect = this.onChangeToPrintSelect.bind(this)
        this.setTimePercent = null
    }

    componentDidMount() {
        let value =
            this.props.match.params.typeOfPayment === "TENANT" ? "owner" :
                this.props.match.params.typeOfPayment === "RENT" ? "rent" :
                    this.props.match.params.typeOfPayment === "AGENT" ? "agent" : this.props.match.params.typeOfPayment
        this.getMeterList(value);

    }

    getMeterList(value) {
        let variables = {
            // first: this.state.first,
            // last: this.state.last,
        };

        this.setTimePercent = setInterval(() => {
            this.setState((prevState) => ({ percentLoading: prevState.percentLoading + 8 }))
        }, 1000)

        fetchQuery(environment, query, variables).then(results => {
            // let data = results?.allResidential?.edges?.map((n) => ({
            //     ...n.node,
            //     roomNumber: n?.node?.name,
            //     FnameOwn: n?.node?.residentialHouseholder?.edges[0]?.node?.tenant?.firstName,
            //     LnameOwn: n?.node?.residentialHouseholder?.edges[0]?.node?.tenant?.lastName,
            //     statusChecked: false,
            //     searchRoom: false
            // }))

            let dataCredit = results?.contactViewer?.allContact?.edges?.map((n) => ({
                ...n.node,
                statusChecked: false,
                searchRoom: false
            }))

            let cloneDatas = []

            if (value === "rent" || value === "agent") {
                cloneDatas = dataCredit.filter((item) => value === "agent" ? item.agentAddress !== "" : item.renterAddress !== "")
            } else if (value === "owner") {
                cloneDatas = dataCredit.filter((item) => item.typeOfContact === "RESIDENTIAL")
            } else if (value === "SUPPLIER") {
                let valueUp = value.toUpperCase()
                cloneDatas = dataCredit.filter((item) => item.typeOfContact === "SUPPLIER" && item.typeOfPayment === valueUp)
            } else {
                cloneDatas = dataCredit
            }

            this.setState({ data: dataCredit, dataViews: cloneDatas, percentLoading: null, selfProject: results?.selfProject, loading: false }, () => {
                clearInterval(this.setTimePercent)
            })
        });

    }

    selectNow(data) {
        let clone = _.cloneDeep(this.state.selectNow)
        let cloneAllResident = _.cloneDeep(this.state.dataViews)
        let indexFind = clone.findIndex(ex => ex.data.id === data.id)
        let indexFindInAllResident = cloneAllResident.findIndex(ex => ex.id === data.id)
        if (data.statusChecked) {
            clone.splice(indexFind, 1)
            let de = _.set(cloneAllResident, `[${indexFindInAllResident}].statusChecked`, false)
            let setData = _.set(data, `statusChecked`, false)

            document.getElementById(data.id).checked = false

        }
        else {
            let de = _.set(cloneAllResident, `[${indexFindInAllResident}].statusChecked`, true)
            let setData = _.set(data, `statusChecked`, true)
            let dataPull = { data: setData }
            clone.push(dataPull)
            document.getElementById(data.id).checked = true
        }
        this.setState({ selectNow: clone, dataViews: cloneAllResident })
    }
    removeSelectAll() {
        let dea = this.state.selectNow?.map((n) => document.getElementById(n.data.id).checked = false)
        let cloneAllResident = _.cloneDeep(this.state.dataViews)
        cloneAllResident.map((n) => {
            return (
                n.statusChecked = false
            )
        })

        this.setState({ selectNow: [], dataViews: cloneAllResident, selectAllChk: false })
    }

    selectAllRoom(type) {
        let clone = _.cloneDeep(this.state.selectNow)
        let cloneAllResident = _.cloneDeep(this.state.dataViews)
        let dea = cloneAllResident?.map((n) => document.getElementById(n.id).checked = type ? true : false)

        if (type) {
            clone = []

            cloneAllResident.map((n, index) => {
                let dataPush = { data: n }
                _.set(cloneAllResident, `[${index}].statusChecked`, true)
                clone.push(dataPush)
            })
        } else {
            cloneAllResident.map((n, index) => {
                _.set(cloneAllResident, `[${index}].statusChecked`, false)
                clone = []
            })
        }

        this.setState({ selectNow: clone, selectAllChk: type, dataViews: cloneAllResident, loading: false })
    }


    handleInput(e) {
        let search = e.target.value
        let deata = _.cloneDeep(this.state.dataViews)
        if (e.key === "Enter") {

            deata.map((n) => { n.searchRoom = false })
            this.setState({ [e.target.name]: e.target.value, searchBox: e.target.value, loading: true, dataViews: deata })
            this.serach(search);
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    serachEnter() {
        this.setState({ searchBox: this.state.searchNotEnter }, () => {
            this.serach(this.state.searchBox);
        })
    }

    serach(e) {
        let deata = _.cloneDeep(this.state.dataViews)
        deata.map((n) => {
            if (e && (n.name.includes(e) || n.refNumber.includes(e))) {
                n.searchRoom = true
            } else {
                n.searchRoom = false
            }
        })

        this.setState({ dataViews: deata, loading: false })
    }

    onChangeToPrintSelect(e) {
        let { name, value } = e.target
        let valueUp = value.toUpperCase()

        let cloneData = _.cloneDeep(this.state.data)
        cloneData = cloneData.map((n) => ({ ...n, statusChecked: false }))
        if (value === "all") {
            this.setState({ dataViews: cloneData, selectNow: [], statusPrintFor: value, loading: false, })
        } else if (value === "rent" || value === "agent") {
            let cloneDatas = cloneData.filter((item) => value === "agent" ? item.agentAddress !== "" : item.renterAddress !== "")
            this.setState({ dataViews: cloneDatas, selectNow: [], statusPrintFor: value, loading: false, })
        } else if (value === "owner") {
            let cloneDatas = cloneData.filter((item) => item.typeOfContact === "RESIDENTIAL")
            this.setState({ dataViews: cloneDatas, selectNow: [], statusPrintFor: value, loading: false, })
        } else {
            let cloneDatas = cloneData.filter((item) => item.typeOfContact === "SUPPLIER" && item.typeOfPayment === valueUp)
            this.setState({ dataViews: cloneDatas, selectNow: [], statusPrintFor: value, loading: false, })
        }
    }



    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={"/accounting/contact/all"}
                                    // LinkBack={this.props.history.goBack()}
                                    LinkText={t("printAllContact:Print Contact Address")}
                                />
                                <div className="content-inner claim">
                                    <div className="row create" style={{ fontFamily: 'sans-serif' }}>
                                        <div className='col-12'>
                                            <div className=''>
                                                <div className="usedAll">
                                                    <label className="containerRadio fs-14 fw-400 ml-0 floatLeft mr-3">{t("residential:All")}
                                                        <input type="radio" name="PrintSelect" value={'all'} defaultChecked={true}
                                                            onChange={this.onChangeToPrintSelect} />
                                                        <span className="checkmark checkmarks_dot_10"></span>
                                                    </label>

                                                    <label className="containerRadio fs-14 fw-400 ml-0 floatLeft mr-3">{t("residential:Owner Resident")}
                                                        <input type="radio" name="PrintSelect" value={'owner'}
                                                            onChange={this.onChangeToPrintSelect} />
                                                        <span className="checkmark checkmarks_dot_10"></span>
                                                    </label>

                                                    <label className="containerRadio fs-14 fw-400 ml-0 floatLeft mr-3">{t("PageListHistoryResident:Rental")}
                                                        <input type="radio" name="PrintSelect" value={'rent'}
                                                            onChange={this.onChangeToPrintSelect} />
                                                        <span className="checkmark checkmarks_dot_10"></span>
                                                    </label>

                                                    <label className="containerRadio fs-14 fw-400 ml-0 floatLeft mr-3">{t("PageListHistoryResident:Agent")}
                                                        <input type="radio" name="PrintSelect" value={'agent'}
                                                            onChange={this.onChangeToPrintSelect} />
                                                        <span className="checkmark checkmarks_dot_10"></span>
                                                    </label>

                                                    <label className="containerRadio fs-14 fw-400 ml-0 floatLeft mr-3">{t("accounting:receivable_setup")}
                                                        <input type="radio" name="PrintSelect" value={'receivable'}
                                                            onChange={this.onChangeToPrintSelect} />
                                                        <span className="checkmark checkmarks_dot_10"></span>
                                                    </label>

                                                    <label className="containerRadio fs-14 fw-400 ml-0 floatLeft mr-3">{t("accounting:payable_setup")}
                                                        <input type="radio" name="PrintSelect" value={'payable'}
                                                            onChange={this.onChangeToPrintSelect} />
                                                        <span className="checkmark checkmarks_dot_10"></span>
                                                    </label>



                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-7 ">
                                            <label className='fs-14 mb-6'>
                                                <span>
                                                    <input type={"checkbox"} className='mr-2'
                                                        checked={this.state.selectAllChk}
                                                        name='selectNowAll'
                                                        onChange={() => this.selectAllRoom(this.state.selectAllChk ? false : true)}
                                                    />
                                                    {t("printAllContact:Select All")}
                                                </span></label>
                                            <div className='card'>
                                                <div className='row meterWaterFunction'>
                                                    <div className='col-12 text-right' style={{ display: 'inline' }}>
                                                        <div className='floatLeft'>
                                                            <input type="text" name='searchNotEnter'
                                                                defaultValue={this.state.searchBox}
                                                                className='form-control searchBoxs'
                                                                placeholder={t("silvermanguard:search")}
                                                                onKeyUp={(e) => this.handleInput(e)}
                                                            // onKeyUp={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                            // onKeyPress={this.handleInput}
                                                            />
                                                            <span className='spanINBox cursor' onClick={this.serachEnter}>
                                                                <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='card' style={{ height: 450, overflowY: 'scroll' }}>
                                                    <table className='table mb-5'>
                                                        <tbody>
                                                            {
                                                                this.state.percentLoading &&
                                                                <tr>
                                                                    <td colSpan={3}>
                                                                        <Loading />
                                                                        <div className="progress">
                                                                            <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: `${this.state.percentLoading}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {
                                                                !this.state.loading &&
                                                                this.state.dataViews?.map((n, index) => {
                                                                    let nData = n
                                                                    let firstName = ''
                                                                    if (nData.typeOfContact === "RESIDENTIAL") {
                                                                        if (this.state.statusPrintFor === 'rent') {
                                                                            firstName = `(${nData?.name}) ${nData?.firstNameRenter ? nData?.firstNameRenter : '-'}`
                                                                        }
                                                                        else if (this.state.statusPrintFor === 'agent') {
                                                                            firstName = `(${nData?.name}) ${nData?.firstNameAgent ? nData?.firstNameAgent : '-'}`
                                                                        } else {
                                                                            firstName = `(${nData?.name}) ${nData?.firstName ? nData?.firstName : '-'}`
                                                                        }
                                                                    } else {
                                                                        firstName = nData?.name
                                                                    }

                                                                    if (this.state.searchBox !== "" && nData.searchRoom) {
                                                                        return (
                                                                            <tr key={index}
                                                                                onClick={() => this.selectNow(nData)}
                                                                                className={nData?.statusChecked == true ? 'bg-E6F2FE' : ''}>
                                                                                <td width={20}>
                                                                                    <input type={"checkbox"}
                                                                                        checked={nData?.statusChecked}
                                                                                        name='selectNow'
                                                                                        id={nData?.id} />
                                                                                </td>
                                                                                <td width={70}>{nData?.refNumber}</td>
                                                                                {/* <td>{`${nData?.name} ${nData?.firstName} ${nData?.lastName}`}</td> */}
                                                                                <td>
                                                                                    {firstName}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    } else if (this.state.searchBox === "" && !nData.searchRoom) {
                                                                        return (
                                                                            <tr key={"IDKey" + nData?.id}
                                                                                onClick={() => this.selectNow(nData)}
                                                                                className={nData?.statusChecked == true ? 'bg-E6F2FE' : ''}>
                                                                                <td width={20}>
                                                                                    <input type={"checkbox"}
                                                                                        checked={nData?.statusChecked}
                                                                                        name='selectNow'
                                                                                        id={nData?.id} />
                                                                                </td>
                                                                                <td width={70}>{`${nData?.refNumber}`}</td>
                                                                                <td>
                                                                                    { firstName }
                                                                                </td>

                                                                            </tr>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <span className='headerLabel18 cursor fw-600'
                                                onClick={this.removeSelectAll}
                                                style={{ color: '#E14558', fontSize: 12 }}>{t("materWater:Remove all")}</span>
                                        </div>
                                        <div className='col-5'>
                                            <label className='fs-14 mb-6'>
                                                {`${t("materWater:Selected item")} `}
                                                {
                                                    this.state.selectNow?.length > 1 ?
                                                        `(${this.state.selectNow?.length}) ${t("materWater:items")}` :
                                                        `(${this.state.selectNow?.length}) ${t("materWater:item")}`
                                                }
                                            </label>
                                            <div className='card' style={{ height: 500, overflowY: 'scroll', position: 'relative' }}>
                                                <table className='table'>
                                                    <tbody>
                                                        {
                                                            this.state.selectNow?.map((n, index) => {

                                                                let first_name = ''
                                                                let last_name = ''

                                                                if (this.state.statusPrintFor === 'rent') {
                                                                    first_name = _.get(n, "data.firstNameRenter", "")?.split(',');
                                                                    last_name = _.get(n, "data.lastNameRenter", "")?.split(',');
                                                                }
                                                                else if (this.state.statusPrintFor === 'agent') {
                                                                    first_name = _.get(n, "data.firstNameAgent", "")?.split(',');
                                                                    last_name = _.get(n, "data.lastNameAgent", "")?.split(',');
                                                                } else {
                                                                    first_name = _.get(n, "data.firstName", "")?.split(',');
                                                                    last_name = _.get(n, "data.lastName", "")?.split(',');
                                                                }

                                                                return (
                                                                    <tr key={"IDKeySelectNow" + index + n?.data?.id}>

                                                                        <td>{n?.data?.refNumber}</td>
                                                                        <td colSpan={n?.data?.typeOfContact !== "RESIDENTIAL" ? 2 : 1}>
                                                                            {n?.data?.name}
                                                                        </td>
                                                                        {
                                                                            n?.data?.typeOfContact === "RESIDENTIAL" &&
                                                                            <td width={350} style={{ maxWidth: 350 }}>
                                                                                {
                                                                                    first_name?.map((first, index) => {
                                                                                        return (
                                                                                            <span key={index}>{first} {last_name[index]},</span>
                                                                                        )
                                                                                    })

                                                                                }
                                                                            </td>
                                                                        }

                                                                        <td onClick={() => this.selectNow(n.data)}>
                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"}
                                                                                style={{ width: 16, height: 16 }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col text-right'>
                                            <button
                                                className='btn-primary'
                                                type={"button"}
                                                onClick={() =>
                                                    this.props.history.push(`/document/contact/all`, {
                                                        data: this.state.selectNow,
                                                        selfProject: this.state.selfProject,
                                                        statusPrintFor: this.state.statusPrintFor
                                                    })}
                                            >
                                                {t("Allaction:next")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default withRouter(WrapperContactAllSelect);
