import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import _ from "lodash"
import upload from '../../libs/upload'
import format from 'date-fns/format';
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import jwtDecode from 'jwt-decode'
import UpdateCreateChatMeeting from './mutations/updateCreateChatMeeting';
import i18n from 'i18next';


const query = graphql`
    query createMeetingQuery($search: String) { 
        allJuristic (search: $search) {
            edges {
                node { 
                    image
                    firstName 
                    firstNameEn
                    lastName 
                    lastNameEn   
                    role 
                    user {
                        id 
                    }
                }
            }
        }
        currentTenants (search: $search) {
            edges{
                node{
                    id
                    image
                    firstName
                    lastName
                    user{
                        id
                    }
                    householder {
                        edges {
                            node {
                                id
                                residential {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        
        }
    } 
`;

class CreateMeeting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: true,
            managerNiti: [],
            commuttee: [],
            selectNitiAll: false,
            selectcommAll: false,

            titlename: "",
            detail: "",
            tag: [],
            chooseFile: [],
            chooseMemberNiti: [],
            chooseMember: [],


            listTag: require('./ListTag.json')
        }
        this.selectTag = this.selectTag.bind(this)
        this.uploadFile = this.uploadFile.bind(this);
        this.selectMember = this.selectMember.bind(this)
        this.selectMemberComm = this.selectMemberComm.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.dateFile = this.dateFile.bind(this)

    }


    async componentDidMount() {
        await this.getManagerNitiandCommuttee()
    }

    getManagerNitiandCommuttee() {
        fetchQuery(environment, query, {
            search: this.state.search
        }).then(data => {
            if (data.allJuristic && data.currentTenants) {
                let currentTenants = []
                let allJuristic = []
                let data_currentTenants = data.currentTenants.edges.filter((n) => n.node.user !== null)
                data_currentTenants.map((n, index) => {
                    _.set(currentTenants, "[" + index + "].image", n.node.image || "");
                    _.set(currentTenants, "[" + index + "].fullName", n.node.firstName + " " + n.node.lastName || "-");
                    _.set(currentTenants, "[" + index + "].userId", n.node.user?.id || "");
                    _.set(currentTenants, "[" + index + "].homeName", n.node.householder?.edges[0]?.node?.residential?.name || "-");
                    _.set(currentTenants, "[" + index + "].selected", false);
                })

                let data_allJuristic = data.allJuristic.edges.filter((n) => n.node.user !== null)
                data_allJuristic.map((n, index) => {
                    if (n.node.user) {
                        _.set(allJuristic, "[" + index + "].image", n.node.image || "");
                        _.set(allJuristic, "[" + index + "].fullName", n.node.firstName + " " + n.node.lastName || "-");
                        _.set(allJuristic, "[" + index + "].userId", n.node.user?.id || "");
                        _.set(allJuristic, "[" + index + "].role", n.node.role || "-");
                        _.set(allJuristic, "[" + index + "].selected", false);
                    }
                })
                this.setState({ managerNiti: allJuristic, commuttee: currentTenants, tag: [], loading: false, })
            }
        })
    }

    selectMember(e, index, action, selc) {
        if (action === 'All') {
            let clone_Niti = _.cloneDeep(this.state.managerNiti)
            clone_Niti.map((n, inx) => {
                _.set(clone_Niti, "[" + inx + "].selected", selc ? false : true);
            })
            let choosemember = []
            let choose = clone_Niti.filter((n) => n.selected === true)
            choose.map((n, ind) => {
                _.set(choosemember, "[" + ind + "].roomGroupNumberReadId", "");
                _.set(choosemember, "[" + ind + "].userId", parseInt(n.userId));
                _.set(choosemember, "[" + ind + "].status", "");
            })
            this.setState({ managerNiti: clone_Niti, selectNitiAll: selc ? false : true, chooseMemberNiti: choosemember })
        }
        else {
            let clone_NitiS = _.cloneDeep(this.state.managerNiti)
            _.set(clone_NitiS, "[" + index + "].selected", e.selected ? false : true);
            let choosemember = []
            let choose = clone_NitiS.filter((n) => n.selected === true)
            choose.map((n, ind) => {
                _.set(choosemember, "[" + ind + "].roomGroupNumberReadId", "");
                _.set(choosemember, "[" + ind + "].userId", parseInt(n.userId));
                _.set(choosemember, "[" + ind + "].status", "");
            })

            this.setState({ managerNiti: clone_NitiS, chooseMemberNiti: choosemember })

        }
    }

    selectMemberComm(e, index, action, selc) {
        if (action === 'All') {
            let clone_Comm = _.cloneDeep(this.state.commuttee)
            clone_Comm.map((n, inx) => {
                _.set(clone_Comm, "[" + inx + "].selected", selc ? false : true);
            })
            let choosemember = []
            let choose = clone_Comm.filter((n) => n.selected === true)
            choose.map((n, ind) => {
                _.set(choosemember, "[" + ind + "].roomGroupNumberReadId", "");
                _.set(choosemember, "[" + ind + "].userId", parseInt(n.userId));
                _.set(choosemember, "[" + ind + "].status", "");
            })
            this.setState({ commuttee: clone_Comm, selectcommAll: selc ? false : true, chooseMember: choosemember })
        }
        else {
            let clone_CommS = _.cloneDeep(this.state.commuttee)
            _.set(clone_CommS, "[" + index + "].selected", e.selected ? false : true);
            let choosemember = []
            let choose = clone_CommS.filter((n) => n.selected === true)
            choose.map((n, ind) => {
                _.set(choosemember, "[" + ind + "].roomGroupNumberReadId", "");
                _.set(choosemember, "[" + ind + "].userId", parseInt(n.userId));
                _.set(choosemember, "[" + ind + "].status", "");
            })
            this.setState({ commuttee: clone_CommS, chooseMember: choosemember })
        }
    }

    selectTag(e, index) {
        let clone_listTag = _.cloneDeep(this.state.listTag)
        let selected = e.selected === true ? false : true
        clone_listTag.map((n) => { return (_.set(clone_listTag, "[" + index + "].selected", selected)) })
        let listChoose = clone_listTag.filter((n) => n.selected === true)
        let selectedData = []

        listChoose.map((n, inx) => {
            _.set(selectedData, "[" + inx + "].roomGroupTagId", "");
            _.set(selectedData, "[" + inx + "].tag", n.label);
            _.set(selectedData, "[" + inx + "].status", "")
        })
        this.setState({ listTag: clone_listTag, tag: selectedData })
    }

    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/groupChat" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        // let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 2097152) {
                Swal.fire(
                    i18n.t("Allaction:Failed to upload file"),
                    i18n.t("Allaction:Please upload a different file because it exceeds the size limit."),
                    'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(
                    i18n.t("Allaction:Failed to upload file"),
                    i18n.t("chatGroup:Supports file")
                    , 'error')
            }

            else {
                // fileIsSel = false
                let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                let fileOldList = _.cloneDeep(this.state.chooseFile);
                this.callUpload('', new_file).then((data) => {
                    let dese = "groupChat/" + new_file.name
                    fileOldList.push({ roomGroupImageId: "", fileName: new_file.name, fileUpload: dese, viewPtath: data.location, status: "" })
                    this.setState({ chooseFile: fileOldList })
                });
            }
        }
    }
    // END UPLOAD 
    onSubmitSuccess = (response) => {
        this.setState({ loading: false });
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.props.history.push("/contact/committee/pending")
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({ loading: false });
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }
    onSubmit(e) {
        e.preventDefault()
        let userIdOwn = jwtDecode(localStorage.getItem("token"))
        let data = {
            "input": {
                "roomGroup": {
                    "roomGroupId": "",
                    "name": this.state.titlename,
                    "detail": this.state.detail,
                    "status": "pending",
                    "note": "",
                    "group": [{ roomGroupNumberReadId: "", userId: userIdOwn.user_id, status: "creator" }, ...this.state.chooseMemberNiti, ...this.state.chooseMember],
                    "tag": this.state.tag,
                    "image": this.state.chooseFile.filter((n) => n.status === ''),
                    "document": [],
                    "clientMutationId": "KAK" + Math.random().toString(36),
                }
            }
        }
        UpdateCreateChatMeeting(data, this.onSubmitSuccess, this.onSubmitError)


    }

    dateFile = (index, data) => {
        // let cloneDataFile = _.cloneDeep(this.state.fileUpload)
        let DataFile = _.cloneDeep(this.state.chooseFile)
        let fileupload = []

        // const index_data_file = DataFile.findIndex(ex => ex.fileId === data.fileId)
        _.set(DataFile, "[" + index + "].status", "delete")
        fileupload = _.get(DataFile, "[" + index + "]")

        if (DataFile.findIndex(ex => ex.fileId === fileupload.fileId) < 0) {
            DataFile.push(fileupload)
        }

        if (data.status === '') {
            this.setState({ chooseFile: DataFile })
        }
    }

    render() {
        let seFile = this.state.chooseFile.filter((n) => n.status === '')
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box meetingChat" >
                                {
                                    this.state.loading === false ?
                                        <div className='create'>
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                    <h3 className="mb-4">
                                                        <Link to="/contact/committee/pending">
                                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                alt="back" className="back" />
                                                        </Link>
                                                        <span style={{ fontWeight: '400' }}>{t('chatGroup:create')}</span>                                                        
                                                    </h3>
                                                </div>
                                            </div>
                                            {
                                                <React.Fragment>
                                                    <div className='row'>
                                                        <div className="col-10 mx-auto">
                                                            <form onSubmit={this.onSubmit}>
                                                                <div>

                                                                    <section alt='header' style={{ marginTop: 20 }}>
                                                                        <h3>{t("chatGroup:Title")}</h3>
                                                                        <hr />
                                                                    </section>

                                                                    <section alt='titlename'>
                                                                        <label className='labelInput' >{t("chatGroup:Title name")} *</label>
                                                                        <input type={"text"} className='Input' required
                                                                            onKeyUp={(e) => this.setState({ titlename: e.target.value })}
                                                                            onChange={(e) => this.setState({ titlename: e.target.value })}
                                                                            name='titlename' defaultValue={this.state.titlename} />
                                                                    </section>

                                                                    <section alt='Detail'>
                                                                        <label className='labelInput' >{t("chatGroup:Detail")} *</label>
                                                                        <textarea name='Detail' className='textarea' defaultValue={this.state.detail}
                                                                            onKeyUp={(e) => this.setState({ detail: e.target.value })}
                                                                            onChange={(e) => this.setState({ detail: e.target.value })}
                                                                            required />
                                                                    </section>

                                                                    <section alt='Tag'>
                                                                        <div className='row'>
                                                                            <div className='col-6'>
                                                                                <label className='labelInput' >{t("chatGroup:Tag Title")} *</label>
                                                                                <label className='labelInput textGray' style={{ float: 'right', marginRight: "-8%" }}>({this.state.tag.length}/5)</label>
                                                                            </div>

                                                                            <input required type={"text"} defaultValue={this.state.tag[0] || ""}
                                                                                style={{ opacity: 0 }} />
                                                                            <div className='col-10'>
                                                                                {
                                                                                    this.state.listTag?.map((n, index) => {
                                                                                        return (
                                                                                            this.state.tag.length >= 5 ?
                                                                                                n.selected === true ?
                                                                                                    <label className={n.selected === true ? 'tag cursor tagBtnBlue' : ' tag cursor tagBtnGray'}
                                                                                                        key={'NewTag' + index}
                                                                                                        onClick={() => this.selectTag(n, index)}>
                                                                                                        {t('pdpaPrivacyPolicy:PrivacyPolicy') === "th" ? n.label : n.lableEn}
                                                                                                    </label>
                                                                                                    :
                                                                                                    <label className={n.selected === true ? 'tag cursor tagBtnBlue' : ' tag cursor tagBtnGray'}
                                                                                                        key={'NewTag' + index}>
                                                                                                        {t('pdpaPrivacyPolicy:PrivacyPolicy') === "th" ? n.label : n.lableEn}
                                                                                                    </label>
                                                                                                :
                                                                                                <label className={n.selected === true ? 'tag cursor tagBtnBlue' : ' tag cursor tagBtnGray'}
                                                                                                    key={'NewTag' + index}
                                                                                                    onClick={() => this.selectTag(n, index)}>
                                                                                                    {t('pdpaPrivacyPolicy:PrivacyPolicy') === "th" ? n.label : n.lableEn}
                                                                                                </label>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                    </section>


                                                                    <section alt='ChooseFile'>
                                                                        <React.Fragment>
                                                                            <div style={{ display: 'inline-flex', pointerEvents: seFile.length >= 3 ? 'none' : 'all' }}>
                                                                                <h3 style={{ marginTop: '40px' }}>{t("chatGroup:Choose File")}</h3>
                                                                                <label htmlFor="uploadFile" className="upload-btn-wrapper">

                                                                                    {/* {
                                                                                seFile.length >= 3 ? 
                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/chat/attachDisabled.png'} /> 
                                                                                :
                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/chat/btnBlueFile.png.png'} /> 
                                                                            } */}
                                                                                    <button className={seFile.length >= 3 ? "btn disabled" : "btn"}>
                                                                                        {
                                                                                            seFile.length >= 3 ?
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/chat/attachDisabled.svg'} alt='attachDisabled' />
                                                                                                :
                                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} alt='fileUpload' />
                                                                                        } {t("chatGroup:Choose File")}

                                                                                    </button>
                                                                                    &nbsp;&nbsp;
                                                                                    <span style={{ color: '#bfbfbf', fontWeight: 300 }}>({seFile.length + "/3"})</span>

                                                                                    <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                                                                        {t("chatGroup:Supports file")} </span>


                                                                                    <input type="file" name='file' id="uploadFile"
                                                                                        className='form-control uploadFile'
                                                                                        placeholder='uploadFile'
                                                                                        accept='application/.jpeg,.png,.jpg' onChange={this.uploadFile}
                                                                                    />

                                                                                </label>
                                                                            </div>
                                                                        </React.Fragment>


                                                                        {
                                                                            this.state.chooseFile.length > 0 &&
                                                                            this.state.chooseFile.map((file, inx) => {
                                                                                let typeFile = file.fileName.split(".")


                                                                                if (file.status !== "delete") {
                                                                                    return (
                                                                                        <p key={inx}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} alt="Icon" /> &nbsp;

                                                                                            <Link
                                                                                                to="route"
                                                                                                onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    window.open(
                                                                                                        typeof file.viewPtath === "string"
                                                                                                            ? file.viewPtath
                                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                                    )
                                                                                                }}
                                                                                                target="blank">{file.fileName}</Link>
                                                                                            &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)}> <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/closeGray03.svg"} alt='closeGray03' /> </label>
                                                                                        </p>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </section>


                                                                    <section alt='chooseMember'>
                                                                        <h3 style={{ marginTop: '40px' }}>{t("chatGroup:Choose member to meeting")} *</h3>
                                                                        <input required type={"text"} defaultValue={this.state.chooseMember[0] || this.state.chooseMemberNiti[0] || ""}
                                                                            style={{ opacity: 0, marginTop: "-20px" }} />
                                                                        <hr />
                                                                    </section>

                                                                    <section alt='Choose manage niti'>

                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className='text-center' style={{ width: 70 }}
                                                                                        onClick={() => this.selectMember("", "", "All", this.state.selectNitiAll)}>
                                                                                        <span style={{ color: "#BFBFBF", fontSize: "12px", fontWeight: '600' }}>{t("chatGroup:all")} </span><br />

                                                                                        {
                                                                                            this.state.selectNitiAll ?
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/checkbox.png'}
                                                                                                    width={25} height={25} alt='checkbox'
                                                                                                />
                                                                                                :
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/checkboxNull.png'}
                                                                                                    width={25} height={25} alt='checkboxNull' />
                                                                                        }

                                                                                    </th>
                                                                                    <th style={{ verticalAlign: 'bottom', fontSize: 18 }} colSpan={2}>{t("chatGroup:Choose manage niti")} <Link to={{ pathname: "/setting/juristic-setting"}}>({t("chatGroup:Set permission, add officials")})</Link></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    this.state.managerNiti.map((n, ind) => {
                                                                                        let jwtCreateRoom = jwtDecode(localStorage.getItem('token'))
                                                                                        return (

                                                                                            <tr key={ind} style={{ verticalAlign: 'middle' }}>
                                                                                                {
                                                                                                    parseFloat(n.userId) !== jwtCreateRoom.user_id &&
                                                                                                    <React.Fragment>
                                                                                                        <td className='text-center'
                                                                                                            onClick={() => this.selectMember(n, ind, "", "")}>
                                                                                                            {
                                                                                                                n.selected === true ?
                                                                                                                    <img src={process.env.PUBLIC_URL + '/images/iconAction/checkbox.png'}
                                                                                                                        width={25} height={25} alt='checkbox' />
                                                                                                                    :
                                                                                                                    <img src={process.env.PUBLIC_URL + '/images/iconAction/checkboxNull.png'}
                                                                                                                        width={25} height={25} alt='checkboxNull' />
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                n.image ?
                                                                                                                    <img src={n.image} width={44} height={44} className='rounded-circle' alt='circle' />
                                                                                                                    :
                                                                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/profileNull.png'}
                                                                                                                        width={44} height={44} alt='profileNull' className='rounded-circle' />
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td className='tableTextName'>
                                                                                                            <p className='colorBlack'>{n.fullName}</p>
                                                                                                            <p className='colorGray'>{n.role}</p>
                                                                                                        </td>

                                                                                                    </React.Fragment>
                                                                                                }

                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </section>

                                                                    <section alt='Choose commuttee'>

                                                                        <table style={{ marginTop: 20 }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className='text-center' style={{ width: 70 }}
                                                                                        onClick={() => this.selectMemberComm("", "", "All", this.state.selectcommAll)}>
                                                                                        <span style={{ color: "#BFBFBF", fontSize: "12px", fontWeight: '600' }}>{t("chatGroup:all")} </span><br />
                                                                                        {
                                                                                            this.state.selectcommAll ?
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/checkbox.png'}
                                                                                                    width={25} height={25} alt='checkbox'
                                                                                                />
                                                                                                :
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/checkboxNull.png'}
                                                                                                    width={25} height={25} alt='checkboxNull' />
                                                                                        }
                                                                                    </th>
                                                                                    <th style={{ verticalAlign: 'bottom', fontSize: 18 }} colSpan={2}>{t("chatGroup:Choose commuttee")}  <span className='text-red'>*{t("chatGroup:Show a list of only the apps that have been downloaded")}</span> <Link to={{ pathname: "/project/project-manager/list/current-board"}}>({t("chatGroup:Add committee")})</Link></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    this.state.commuttee.map((n, ind) => {
                                                                                        return (
                                                                                            <tr key={ind} style={{ verticalAlign: 'middle' }}>
                                                                                                <td className='text-center'
                                                                                                    onClick={() => this.selectMemberComm(n, ind, "", "")}>
                                                                                                    {
                                                                                                        n.selected === true ?
                                                                                                            <img src={process.env.PUBLIC_URL + '/images/iconAction/checkbox.png'}
                                                                                                                width={25} height={25} alt='checkbox' />
                                                                                                            :
                                                                                                            <img src={process.env.PUBLIC_URL + '/images/iconAction/checkboxNull.png'}
                                                                                                                width={25} height={25} alt='checkboxNull' />
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        n.image ?
                                                                                                            <img src={n.image} width={44} height={44} className='rounded-circle' alt='circle' />
                                                                                                            :
                                                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/profileNull.png'}
                                                                                                                width={44} height={44} className='rounded-circle' alt='profileNull' />
                                                                                                    }

                                                                                                </td>
                                                                                                <td className='tableTextName'>
                                                                                                    <p className='colorBlack'>{n.fullName}</p>
                                                                                                    <p className='colorGray'>{n.homeName}</p>
                                                                                                </td>
                                                                                            </tr>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </section>
                                                                </div>


                                                                <div className='row mt-2'>
                                                                    <div className='col-12 text-right'>
                                                                        <button type='submit' className='btn btn-primary'>{t("Allaction:save")}</button>
                                                                    </div>
                                                                </div>

                                                            </form>

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }


                                        </div>
                                        :
                                        <div className='text-center'>
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert/loading.gif'} alt='loading' />
                                        </div>
                                }



                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default withRouter(CreateMeeting);
