import React, { Component } from 'react'
import Wrapper from '../../components/wrapper';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import Navigation from './navigation';
import { graphql } from "babel-plugin-relay/macro";
import { Link } from 'react-router-dom';
import SettingFormCollectionLaw from './settingFormCollectionLaw';

const customStyles = {
    control: provided => ({
      ...provided,
      fontFamily: 'kanit'
    })
}

const query = graphql`
  query settingCollectionLetterLawQuery{
        allRegisterCollectionLawFirmSite{
            edges{
              node{
                  site{
                      id
                      project{
                          edges{
                              node{
                                  id
                                  nameTh
                              }
                          }
                      }
  
                  }
                  
              }
          }
    }
  }          
`;
class SettingCollectionLaw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getAllSite: [],
            siteID:null
        }
    
    }

    render(){

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <Translation>
                        {
                            t => 
                            <div className="container-fluid box" id="setting-collection-wrapper">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <Translation>
                                                {
                                                    t => <span>{t('collectionLawFirm:Setting Debt Collection Attorney')}</span>
                                                }
                                            </Translation>
                                        </h3>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                       <Navigation />
                                    </div>
                                </div>
                                <SettingFormCollectionLaw 
                                    status={this.props.match.params.status}  
                                />
                            </div>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        );
    }




}
export default SettingCollectionLaw;