import React, { useState } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import { graphql } from "babel-plugin-relay/macro";
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import _ from "lodash"
import CollectionLawFirmListTable from "./collectionLawFirmListTable";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import Swal from 'sweetalert2';
import i18next from 'i18next';
import Navigation from './navigation';
import SeacrhDetails from '../../components/search/indexSearch';
import voidCollectionLawFirmLibMutation from "./mutation/voidCollectionLawFirm";
import { Link } from 'react-router-dom';
import i18n from "i18next";

const query = graphql`
    query collectionLawFirmListQuery($status:String, $search:String, $startDate:DateTime, $endDate:DateTime, $first:Int, $last:Int){
        allCollectionLawFirm(status:$status, search:$search, startDate:$startDate, endDate:$endDate, first:$first, last:$last) {
            totalCount
            edges{
              node{
                id
                docNumber
                issuedDate
                added
                status
                dueDate
                contact{
                    id
                    refNumber
                    name
                }
                expenseList
                finalParagraphIndentation
                language

                lawyerName
                lawyerSignature
                debtName
                telJuristic
                telOffice

                totalDebt
                totalFine
                creator

                collectionLawFirmRecord{
                    edges{
                        node{
                            id
                            debt
                            fine
                            receiptName
                            receiptUpload
                            collectionLetters{
                                id
                                sumTotalPaidBeforeLegalclaim
                                sumTotalPaidAfterLegalclaim
                            }
                        }
                    }
                }

                documentCollectionLawFirm{
                    edges{
                        node{
                            id
                            fileName
                            fileUpload
                        }
                    }
                }
                receiveCollectionLawFirmRecord{
                    edges{
                        node{
                          id
                          total
                          paid
                          typePaid
                          status
                          receiveCollectionLawFirm{
                              id
                              docNumber
                              status
                          }  
                        }
                    }
                }
                fmcfCollectionLawFirm{
                    edges{
                        node{
                            id
                            docNumber
                            issuedDate
                            projectName
                            contactName
                            contactRoom
                            sizeRoom
                            balconyRoom
                            dueDateDays
                            dueDate
                            total
                            fine
                            paid

                            lawyerSignature
                            lawyerName
                            rulesRegulationsjuristic
                            telOffice
                            telJuristic
                            status
                            documentType
                            voidNote
                            creator
                            creatorEdit
                            creatorDelete
                            creatorApproval
                        }
                    }
                }
            }
        } 
    }
}
`;

const CollectionLawFirmList = (props) => {
    
    const [selectedId, setselectedId] = useState([])
    const [reQuery, setreQuery] = useState(false)
    const [searchBox, setsearchBox] = useState("")
    const [startDate, setstartDate] = useState(null)
    const [endDate, setendDate] = useState(null)

      //ค้นหา
    const handlePropsChange = (date) =>{
        setsearchBox(date[0].value)
        setstartDate(date[1].value)
        setendDate(date[2].value)
    }
    
    const handlePropsOnVoid = () => {
        let collectionLawFirmList = []
        let { value: void_remark } = Swal.fire({
            customClass: {
                title: 'swal2-title-custom',
                header: 'swal2-header-custom p-3',
                container: 'swal2-container-custom colorUse',
                content: 'swal2-content-custom border-top pt-3 p-3',
                footer: 'text-right',
                popup: 'swal-width-820',
                closeButton: 'text_0F0F0F pt-5 pr-5 h100',
                confirmButton: 'swal2-confirmToRight bg1567B4-textfff',
                cancelButton: 'fw-300',
                actions: 'justify-content-right'
              },
              title: i18next.t("collectionLawFirm:Canceling a follow-up document by an attorney"),
              showCloseButton: true,
              input: 'text',
              inputPlaceholder:i18next.t("collectionLawFirm:Reasons for cancellation"),
              // html:
              //   '<p class="row col-12"><label class="fs-18 mt-2 mr-3">เหตุผลทยกเลิก </label> <input type="text" id="reasonPleaseRequire" class="form-control h48 w500" placeholder="เหตุผลที่ยกเลิก" /></p>',
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: i18next.t("Allaction:Confirm"),
              cancelButtonText: i18next.t("Allaction:cancel"),
            inputValidator: (value) => {
                if (!value) {
                    return i18next.t("Allaction:Please fill input before cancel")
                }else{
                    _.forEach(selectedId, id => {
                        collectionLawFirmList.push({
                            Id: id,
                        })
                    })
                    

                    let variables = {
                        "input": {
                            "jsonObject": {
                                "collectionLawFirmList": collectionLawFirmList,
                                "voidRemark": value
                            }
                        }
                    }
                    voidCollectionLawFirmLibMutation(variables, onCancelSuccess,onCancelError)
                }
            }
        })

    }
    
    const onCancelSuccess = (response) => {
        if (response.ok) {
            Swal.fire('warning', '', 'success').then(() => {
                setreQuery(!reQuery)
            });
        } else {
            Swal.fire('Error!', response.massage, 'warning').then(() => {
                setreQuery(!reQuery)
            })
        }
      }
    
    const onCancelError = (err) => {
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    const ResetreQuery = () => {
        setreQuery(!reQuery)
    }
    
    return (
        <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent disabledOverflowX={true}>
                <AccountingTopMenuNavigation mini={true} />
                <Translation>
                    {
                        (t) =>
                            <div className="container-fluid box colorUse" id="collection-law-firm">
                                 <BackButtonIcon
                                        LinkBack={"/accounting"}
                                        LinkText={i18next.t('collectionLawFirm:Debt Collection Attorney')+" "+"(CLF)"}
                                 /> 
                                {
                                   _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'collection_law_frim_create' }) && 
                                   <div className='searchFunction'>
                                         <Link to={{ pathname: "/accounting/collection-law-firm/create", state: { backLink: window.location.pathname } }}>
                                            <button className="btn-primary ml-3 float-right"><img src={process.env.PUBLIC_URL + '/images/iconAction/E-notice/print.png'} className="w18 mr-3" />{i18next.t('collectionLawFirm:Issued Notice')} </button>
                                         </Link>
                                   </div>
                                   
                                }
                                
                                <div className="content-inner arrowInSelect">
                                    <Navigation />
                                    {
                                    props.match.params.status !== 'law' || _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'collection_law_frim_delete' }) && 
                                        <div className='floatLeft'>
                                        <button className='btn btn-outline-secondary m-1 w42 h42' onClick={handlePropsOnVoid}>
                                            <img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/downloadFile/delete.png'} />
                                        </button>
                                        </div>
                                    }
                                    <SeacrhDetails
                                        buttonDeleted={false}
                                        handlePropsOnVoid={handlePropsOnVoid}
                                        handlePropsChange={handlePropsChange}
                                        searchBoxId={true}
                                        startDateIs={true}
                                        endDateIs={true}
                                        iconDate={"calendarIcon"}
                                    /> 

                                    <CollectionLawFirmListTable
                                        query={query}
                                        setselectedId={setselectedId}
                                        ResetreQuery={ResetreQuery}
                                        status={props.match.params.status ?? ""}
                                        reQuery={reQuery}
                                        search={searchBox}
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                </div>
                            </div>
                    }
                </Translation>

            </WrapperContent>

        </Wrapper>

    )
}

export default CollectionLawFirmList;
