import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import RevenueContractList from './revenueContractList';
import RevenueContractCreate from './revenueContractCreate/revenueContractCreate';
import RevenueContractDetail from './revenueContractDetail';
import RevenueContractEdit from './revenueContractCreate/revenueContractEdit';

class RevenueContract extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={RevenueContractList}/>
                <PrivateRoute exact path={`${this.props.match.url}/create/:status/:type?`} component={RevenueContractCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={RevenueContractDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/edit/:id/:type`} component={RevenueContractEdit}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default RevenueContract;