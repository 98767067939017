import React, { Component } from 'react';
import { Translation } from 'react-i18next';

class RenterAddressDetail extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                <h6>{t("residential:no.")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterHomeNumber}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="renterHomeNumber" />
                            </div>
                            <div className="col">
                                <h6>{t("residential:Village/Building")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterVillageBuilding}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="renterVillageBuilding" />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Moo")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterVillageNumber}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="renterVillageNumber" />
                            </div>

                            <div className="col">
                                <h6>{t("residential:Soi")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterSoi}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="renterSoi" />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-6">
                                <h6>{t("residential:Road")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterLane}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="renterLane" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Sub-district")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุแขวง --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterSubDistrict}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="renterSubDistrict" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:District")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุเขต --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterDistrict}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="renterDistrict" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Province")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุจังหวัด --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterProvince}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="renterProvince" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Zip code")}</h6>
                                <input type="text"
                                    className="form-control"
                                    defaultValue={this.props.state.renterPostalCode}
                                    onChange={this.props.handleChangeInputAddress}
                                    maxLength="5"
                                    name="renterPostalCode" />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default RenterAddressDetail;