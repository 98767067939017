import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";

import ProductDetail from "./productDetail";
import ProductTransactionList from "./productTransactionList";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { Translation } from 'react-i18next';

class ProductTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: ''
        };
    }

    componentWillMount() {
        this.setState({id: this.props.match.params.id});
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="product-detail">
                        <div className="row">
                            <div className="col">
                                <Translation>
                                    {t=>
                                    <h3 className="mb-4">
                                        <Link to={this.props.match.params.product_type === 'product' ? "/accounting/inventory/product/list/product" : "/accounting/inventory/product/list/expense"}>
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                 alt="back" className="back"/>
                                        </Link>
                                        {t("inventory_list:Detail")}
                                    </h3>}
                                </Translation>
                            </div>
                        </div>

                        <div className="content-inner">
                            <div className="row">
                                <div className="col-sm-12 col-12">
                                    <ProductDetail id={this.state.id}
                                                   product_type={this.props.match.params.product_type}/>
                                </div>
                            </div>
                        </div>

                        {this.props.match.params.product_type === 'product' &&
                        <div className="content-inner">
                            <div className="row">
                                <div className="col-sm-12 col-12">
                                    <ProductTransactionList id={this.state.id}/>
                                </div>
                            </div>
                        </div>
                        }

                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ProductTransaction;