import React from "react";
import {fetchQuery} from "relay-runtime";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import _ from "lodash";


const query = graphql`
    query otherExpenseIndividualAreaPriceSettingQuery($service: String!, $contact: String!)  {
        periodServicePricing(service: $service, contact: $contact) {
            unit
            unitPrice
            totalPrice
            description
        }
    }
`;

class OtherExpenseIndividualAreaPriceSetting extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            service_id :''
        };
        this.queryPrice = this.queryPrice.bind(this);
    }

    componentWillMount() {
        if (_.get(this.props.transaction.productAndService, "id")) {
            this.queryPrice();
        }
    }

    queryPrice() {
        let variables = {
            "contact": this.props.contact[0],
            "service": this.props.transaction.productAndService.id
        };
        if(this.props.transaction.productAndService.id) {
            fetchQuery(environment, query, variables)
                .then(data => {
                    this.setState({
                        price: data.periodServicePricing.unitPrice,
                        unit: data.periodServicePricing.unit,
                        service_id: this.props.transaction.productAndService.id
                    });
                    this.props.setIndividualTransaction(this.props.transaction_index, this.state);
                });
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextState.service_id !== this.props.transaction.productAndService.id && nextProps.transaction.productAndService.id !== ""){
            this.queryPrice();
        }
    }

    render() {
        return (
            <span/>
        )
    }
}

export default OtherExpenseIndividualAreaPriceSetting;
