import React, { Component } from 'react';
import format from 'date-fns/format';
import { Link } from "react-router-dom";
import i18n from 'i18next';


class FixRequestWorksheetDetail extends Component {
    constructor(props) {
        super(props)
        this.functionReadRefFinishWork = this.functionReadRefFinishWork.bind(this)
    }

    functionReadData(slip) {
        let file = JSON.parse(slip.replace(/\'/g, "\""))


        const fileSlip = file.map((n, i) => {
            let typeFiles = n.fileName.split(".");
            return (
                <p key={i}>
                    <img src={process.env.PUBLIC_URL + '/images/typeFiles/' + typeFiles[1] + 'Icon.png'} style={{ height: '30px' }} />
                    <Link
                        to="route"
                        onClick={(event) => {
                            event.preventDefault();
                            window.open(
                                typeof n.viewPtath === "string"
                                    ? n.viewPtath
                                    : URL.createObjectURL(n.viewPtath)
                            )
                        }}
                        target="blank">{n.fileName}</Link>
                </p>

            )

        })
        return fileSlip
        // let slip = []
        // if(file){
        // let slip =  JSON.parse(file)
        //     console.log(slip);
        // }

    }

    functionReadRefFinishWork(ref, refName) {
        let typeFiles = refName.split("."); 
        return (
            <React.Fragment>
                <img src={process.env.PUBLIC_URL + '/images/typeFiles/' + typeFiles[1] + 'Icon.png'} style={{ height: '30px' }} />
                <Link
                    to="route"
                    onClick={(event) => {
                        event.preventDefault();
                        window.open(
                            typeof ref === "string"
                                ? ref
                                : URL.createObjectURL(ref)
                        )
                    }}
                    target="blank">{refName}</Link>

            </React.Fragment>
        )  
    }

    render() {
        let wworksheet = this.props.wworksheet
        let n = this.props.repoair
        return (
            <React.Fragment>
                <div className='col-sm-12 detailWorkingType'>
                    {n.working_type} : {n.material} : {n.details}</div>

                <div className='col-sm-3'><label className='fontLabelHeader'>{i18n.t("FixRequestModal:Type")}</label></div>
                <div className='col-sm-9'><label className='fontLabelBody'>{wworksheet.repair_type === "public" ? i18n.t("FixRequestModal:Private") :  i18n.t("FixRequestModal:Public")}</label></div>

                <div className='col-sm-3' ><label className='fontLabelHeader'>{i18n.t("FixRequestModal:Responsible staff")}</label></div>
                <div className='col-sm-9' ><label className='fontLabelBody'>{n.person_working ? n.person_working : '-'}</label></div>


                {
                    wworksheet.job_assigned_by_staff_name ?
                        <React.Fragment>
                            <div className='col-sm-3'><label className=' fontLabelHeader'>{i18n.t("FixRequestModal:Technician type")}</label></div>
                            <div className='col-sm-9'><label className='fontLabelBody'>{wworksheet.job_responsed_by_staff_name}</label></div>


                            <div className='col-sm-3' ><label className='fontLabelHeader'>{i18n.t("newBookingSingha_Booking:Contact number")}</label></div>
                                <div className='col-sm-9' ><label className='fontLabelBody'>
                                    {
                                        wworksheet.phone ?
                                            wworksheet.phone.substring(0, 3) + "-" +
                                            wworksheet.phone.substring(3, 6) + "-" +
                                            wworksheet.phone.substring(6) : '-'
                                    }
                                </label></div>

                            {/* <div className='col-sm-3'><label className=' fontLabelHeader'>{i18n.t("FixRequestModal:Contractor name")}</label></div>
                            <div className='col-sm-9'><label className='fontLabelBody'>{wworksheet.vendor ? wworksheet.vendor : '-'}</label></div> */}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className='col-sm-3' ><label className='fontLabelHeader'>{i18n.t("FixRequestModal:Work Type")}</label></div>
                            <div className='col-sm-9' ><label className='fontLabelBody'>
                                {
                                    wworksheet.working_type === "repair" ? i18n.t("FixRequestModal:Repair") :
                                        wworksheet.working_type === "improvement" ? i18n.t("FixRequestModal:Improvement") : i18n.t("FixRequestModal:etc.")}</label></div>

                            <div className='col-sm-3' ><label className='fontLabelHeader'>{i18n.t("FixRequestModal:Technician type")}</label></div>
                            <div className='col-sm-9' ><label className='fontLabelBody'>
                                {wworksheet.technician === "outside" ? i18n.t("FixRequestModal:Technician outside the project") : i18n.t("FixRequestModal:Technician in the project")}
                            </label></div>

                            <div className='col-sm-3' ><label className='fontLabelHeader'>{i18n.t("FixRequestModal:Technician name")}</label></div>
                            <div className='col-sm-9' >
                                <label className='fontLabelBody'>
                                    {
                                        wworksheet.job_responsed_by_staff_name ?
                                            wworksheet.job_responsed_by_staff_name :
                                            wworksheet.project_staff ?
                                                wworksheet.project_staff.name_title + wworksheet.project_staff.name_staff + " " + wworksheet.project_staff.last_name_staff :
                                                wworksheet.project_staff_other

                                    }
                                </label>
                            </div>
                        </React.Fragment>
                }




                <div className='col-sm-3'><label className=' fontLabelHeader'>{i18n.t("FixRequestModal:Contractor name")}</label></div>
                            <div className='col-sm-9'><label className='fontLabelBody'>{wworksheet.vendor ? wworksheet.vendor : '-'}</label></div>

                <div className='col-sm-3' style={{ fontSize: '16px', color: '#808080', margin: '20px 0 0 0', fontWeight: "400" }}>{i18n.t("FixRequestModal:Operation date")}</div>
                <div className='col-sm-9' style={{ fontSize: '16px', color: '#808080', margin: '20px 0 0 0', fontWeight: "400" }}>{i18n.t("FixRequestModal:Time")}</div>


                <div className='col-sm-3' >
                    <label className='fontLabelBody'>{format(wworksheet.appointments, "DD/MM/YYYY")}</label></div>
                <div className='col-sm-9' >
                    <label className='fontLabelBody'>{format(wworksheet.appointments, "HH:mm น.")}</label></div>

                {
                    wworksheet.description ?
                        <React.Fragment>
                            <div className='col-sm-3' ><label className='fontLabelHeader'>{i18n.t("FixRequestModal:Additional message")}</label></div>
                            <div className='col-sm-9' ><label className='fontLabelBody'>{wworksheet.description}</label></div>
                        </React.Fragment>
                        :
                        ""
                }

                {
                    (n.status === "approve" || n.status === "finish") && n.insure_status !== "have" &&
                    <React.Fragment>
                        <div className='col-sm-3' style={{ fontSize: '16px', color: '#808080', margin: '20px 0', fontWeight: "400" }}>{i18n.t("FixRequestModal:Proof of payment")}</div>
                        <div className='col-sm-9' style={{ fontSize: '16px', margin: '20px 0', fontWeight: "400" }}>

                            {
                                wworksheet.ref_slip ?
                                    this.functionReadData(wworksheet.ref_slip) : '-'
                                // this.getWorkSheet(wworksheet.id)
                            }
                        </div>
                    </React.Fragment>
                }

                {
                    n.status === "finish" ?
                        <React.Fragment>
                            <div className='col-sm-3' ><label className='fontLabelHeader'>{i18n.t("FixRequestModal:Description after finishing this work")}</label></div>
                            <div className='col-sm-9' ><label className='fontLabelBody'>{n.ref_description ? n.ref_description : '-'}</label></div>

                            <div className='col-sm-3' ><label className='fontLabelHeader'>{i18n.t("FixRequestModal:Attach picture after finishing this work")}</label></div>
                            <div className='col-sm-9' > <label className='fontLabelBody'>
                                {
                                    n.ref_image_upload ?
                                        this.functionReadRefFinishWork(n.ref_image_upload, n.ref_image_name)
                                        :
                                        "-"
                                }

                            </label></div>
                        </React.Fragment>
                        :
                        ""

                }


            </React.Fragment>
        )
    }
}


export default FixRequestWorksheetDetail
