/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type fixRequestWorksheetQueryVariables = {||};
export type fixRequestWorksheetQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +bankCompCode: ?string,
    +bankServiceCode: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
    +postcodeProject: ?string,
    +provinceProject: ?string,
    +districtProject: ?string,
    +subDistrictProject: ?string,
  |},
  +userConfig: ?{|
    +id: string,
    +languages: string,
  |},
|};
export type fixRequestWorksheetQuery = {|
  variables: fixRequestWorksheetQueryVariables,
  response: fixRequestWorksheetQueryResponse,
|};
*/


/*
query fixRequestWorksheetQuery {
  selfProject {
    id
    name
    address
    logo
    juristicContactNumber
    keyProjectQr
    bankCompCode
    bankServiceCode
    taxIdNumber
    typeOfProject
    postcodeProject
    provinceProject
    districtProject
    subDistrictProject
  }
  userConfig {
    id
    languages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "juristicContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "keyProjectQr",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bankCompCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bankServiceCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxIdNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfProject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postcodeProject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provinceProject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "districtProject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subDistrictProject",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserConfigNode",
    "kind": "LinkedField",
    "name": "userConfig",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "languages",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "fixRequestWorksheetQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fixRequestWorksheetQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7915fafbed0cbc7319606a85d2bbd5c",
    "id": null,
    "metadata": {},
    "name": "fixRequestWorksheetQuery",
    "operationKind": "query",
    "text": "query fixRequestWorksheetQuery {\n  selfProject {\n    id\n    name\n    address\n    logo\n    juristicContactNumber\n    keyProjectQr\n    bankCompCode\n    bankServiceCode\n    taxIdNumber\n    typeOfProject\n    postcodeProject\n    provinceProject\n    districtProject\n    subDistrictProject\n  }\n  userConfig {\n    id\n    languages\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a7d3f06dd9dc2b4c475eff61b134057';

module.exports = node;
