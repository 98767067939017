import React from 'react'
import { Component } from 'react';
import Swal from 'sweetalert2';
import Wrapper from '../../components/wrapper';
import WrapperContent from '../../components/wrapper/wrapperContent';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import { Translation } from 'react-i18next';
import {Link} from "react-router-dom";
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import './juristicCompany.scss'
import UploadImageInput from '../../libs/uploadImageInput';
import _ from 'lodash'
import format from 'date-fns/format'
import DatePickerAdapter from '../../libs/datePickerAdapter';
import SelectNamePrefix from '../../libs/selectNamePrefix';
import {graphql} from "babel-plugin-relay/macro";
import environment from '../../env/environment';
import {fetchQuery} from "relay-runtime";
import getDateTime from "../../libs/getDateTime"
import createTeam from "./graphQL/createTeam";
import updateTeam from "./graphQL/updateTeam";
import upload from '../../libs/upload';
import i18n from 'i18next';
import {Redirect} from 'react-router';
import numberWithComma from '../../libs/numberWithComma';
import i18next from 'i18next';

const query = graphql `
query createUpdatePersonnelQuery ($idTeam: String) {
    allTeam (idTeam: $idTeam) {
        edges {
            node {
                id
                teamType
                namePrefix
                firstName
                lastName
                department
                position
                authorizationRight
                maximumApprovalLimit
                dateOfPosition
                onlineStatus
                image
                dateOfEndPosition
                dateOfPosition
                employmentStatus
                address
                affiliation
                note
                email
                phone
                documentteamSet {
                    edges {
                        node {
                            id
                            fileName
                            fileUpload
                        }
                    }
                }
            }
        }
    }
}

`

const newDocumentFile = {
    nameFile : '',
    documentUpload : ''
}

const newPersonnel = {
    firstName : '',
    lastName : '',
    department : '',
    position : '',
    authorizationRight : true,
    maximumApprovalLimit : 0,
    dateOfPosition : new Date(),
    image : '',
    namePrefix : '',
    address : '',
    documentFile : [],
    phone : '',
    email : '',
    affiliation : '',
    dateOfEndPosition : '',
    note: '',
    employmentStatus : 'available'
}


class CreateUpdatePersonnel extends Component {
    constructor(props){
        super(props)
        this.state = {
            person : this.props.match.params.status === 'create' ?  newPersonnel : {},
            loading : false,
            statusTeam : this.props.match.params.statusTeam,
            status : this.props.match.params.status,
            id : this.props.match.params.id ? this.props.match.params.id : '',
            header : this.props.match.params.status === 'edit' ? 'Edit Personnel' : 'Add Personnel',
            uploadImage : '',
            redirectToList : false,
            authorization : 'right',
            edit : this.props.match.params.status === 'create' ? true : false,
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputDocument = this.handleInputDocument.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.addDocument = this.addDocument.bind(this);
        this.handleChangeNamFile = this.handleChangeNamFile.bind(this)
        this.handleNamePrefix = this.handleNamePrefix.bind(this)
        this.handleRadioButton = this.handleRadioButton.bind(this)
    }

    componentWillMount(){
        if(this.state.status === 'edit'){
            this.fetchData()
        }  
    }

    fetchData = () => {
        fetchQuery(environment, query , {
            idTeam: this.props.match.params.id
        }).then(response_data => {
            this.setState({
                person : response_data.allTeam.edges[0].node
            })
        });
    }

    handleNamePrefix(value){
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "person.namePrefix",
                value
            );
        });  
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        if(event.target.name === 'person.image.upload_image'){
            this.setState({
                uploadImage : value
            })
        }
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });  
    }

    handleChangeNamFile(e){
        let event = _.cloneDeep(e);
        let value = event.target.value;
        let id = event.target.dataset.id
        let documentFile = _.cloneDeep(this.state.person.documentFile)
        documentFile[id][event.target.name] = value
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "person.documentFile",
                documentFile
            );
        });
    }

    handleInputDocument(e){
        let id = parseInt(e.currentTarget.dataset.id)
        let documentFile = _.cloneDeep(this.state.person.documentFile)
        documentFile[id]["documentUpload"] = e.currentTarget.files[0]
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "person.documentFile",
                documentFile
            );
        });
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/juristicCompany/personnel" + path, file);
          resolve(res);
        });
      };

    async onSubmit(e) {
        e.preventDefault();
        let variables = {
            input : {
                team : {...this.state.person}
            }
        }
        delete variables.input.team.image
        if(this.state.uploadImage !== ''){
            await this.callUpload('', this.state.uploadImage).then((data) => {
                variables.input.team['image'] = data.key.replace("silverman-storage/private/", "");

            });
        }
        let keyListInput = ['department','affiliation', 'address' , 'phone' , 'email' , 'note' ]
        for (const [key, value] of Object.entries(variables.input.team)){
            if(_.includes(keyListInput,key) && value === ''){
                variables.input.team[key] = '-'
            }
        }

        _.set(variables.input.team, 'dateOfEndPosition', this.state.person.dateOfEndPosition !== '' ? format(this.state.person.dateOfEndPosition,'YYYY-MM-DD') : null);
        _.set(variables.input.team, 'dateOfPosition', format(this.state.person.dateOfPosition,'YYYY-MM-DD'));
        variables.input.team['document'] = []
        delete variables.input.documentteamSet
        
        if(this.state.status === 'create'){
            variables.input.team['teamType'] = this.state.statusTeam
            createTeam(variables, this.onSubmitSuccess,this.onSubmitError)
        }else{
            updateTeam(variables, this.onSubmitSuccess,this.onSubmitError)
        }
        
    }

    onSubmitSuccess = (response) => {
        this.setState({loading: false});
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.setState({redirectToList: true,edit:false})
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({loading: false});
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    addDocument(){
        let documentFile = [...this.state.person.documentFile , {...newDocumentFile}]
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                "person.documentFile",
                documentFile
            );
        });
    }

    onDeleteItem(index){
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let documentFile = _.cloneDeep(this.state.person.documentFile);
                documentFile.splice(index, 1)
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        "person.documentFile",
                        documentFile
                    );
                });
            }
        })
    }

    handleRadioButton(e){
        if(e.target.name === 'authorization'){
            if(e.target.id === 'right'){
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        'person.authorizationRight',
                        true
                    );
                });
            }else{
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        'person.authorizationRight',
                        false
                    );
                });
            }
        }

    }

    onCancel = () => {
        this.setState({
            edit : false
        } ,() => this.fetchData())
    }

    

    render(){
        if (this.state.redirectToList && this.state.status === 'create') {
            return <Redirect to={"/project/juristic-company/dashboard/"+this.state.statusTeam}/>;
        }
        return(
            <Wrapper>
                <Header />
                <Sidebar/>
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true}/>
                    <Translation>
                        {t=>
                            <div className="container-fluid box" id="projectDashboard">
                                <div className="row">
                                    <div className="col-md-4 col-xl-4">
                                        <h3 className="mb-4">
                                            <Link to={"/project/juristic-company/dashboard/"+this.state.statusTeam}>
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <span>{t('juristicCompany:'+this.state.header)}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner">
                                    {this.state.edit ? 
                                    <form id="juristic-manager-form" onSubmit={this.onSubmit}>
                                        <div className="row p-3 d-flex justify-content-center">
                                            <div className="col-md-10 col-sm-12">
                                                <div className="card card-form pt-3 pr-4 pt-1">
                                                    <div className="row mt-3">
                                                        <div className="col-md-3">
                                                            <div className="col-12 d-flex justify-content-center mt-2">
                                                                <img
                                                                    src={this.state.person.image ? this.state.person.image : process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                    alt="project-manager-profile"
                                                                    className="rounded-circle avatar-img profile-img"/>
                                                            </div>
                                                            <div className="col-12 d-flex justify-content-center mt-2">
                                                                <div className="upload-file">
                                                                    <div >
                                                                        <label className="btn-link"
                                                                                htmlFor="uploadProfileImages">{t('project_manager:Change picture')}</label>
                                                                    </div>
                                                                    <div className="upload-input">
                                                                        <UploadImageInput
                                                                            id="uploadProfileImages"
                                                                            setField={"person.image.upload_image:person.image"}
                                                                            onChange={this.handleInputChange}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="row mb-1">
                                                                <div className="col-12">
                                                                    <h6>{t('juristicCompany:Name Prefix')}</h6>
                                                                </div>
                                                                <div className="col-12">
                                                                    <SelectNamePrefix  callBack={this.handleNamePrefix} 
                                                                    value={this.state.person.namePrefix} required={true}/>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-6 mt-3">
                                                                    <h6>{t('juristicCompany:First Name')}</h6>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <h6>{t('juristicCompany:Last Name')}</h6>
                                                                </div>
                                                                <div className="col-6">
                                                                    <input type="text" className="form-control"
                                                                        value={this.state.person.firstName}
                                                                        onChange={this.handleInputChange}
                                                                        name="person.firstName" required/>
                                                                </div>
                                                                <div className="col-6">
                                                                <input type="text" className="form-control"
                                                                        value={this.state.person.lastName}
                                                                        onChange={this.handleInputChange}
                                                                        name="person.lastName" required/>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-1">
                                                                <div className="col-12 mt-3">
                                                                    <h6>{t('projectManager:Authorization Right')}</h6>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="custom-control custom-radio custom-control-inline" >
                                                                        <input
                                                                            type="radio"
                                                                            name="authorization"
                                                                            className="custom-control-input"
                                                                            id='not-right'
                                                                            onChange={this.handleRadioButton}
                                                                            checked={!this.state.person.authorizationRight}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label cursor"
                                                                            htmlFor='not-right'
                                                                            >
                                                                            {t('projectManager:Dont have right')}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="custom-control custom-radio custom-control-inline" >
                                                                        <input
                                                                            type="radio"
                                                                            name="authorization"
                                                                            className="custom-control-input"
                                                                            id='right'
                                                                            onChange={this.handleRadioButton}
                                                                            checked={this.state.person.authorizationRight}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label cursor"
                                                                            htmlFor='right'
                                                                            >
                                                                            {t('projectManager:Have right')}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1 ">
                                                                <div className="col-12 mt-2">
                                                                    <h6>{t('projectManager:Maximum approval limit')}</h6>
                                                                </div>
                                                                <div className="col-12">
                                                                    <input type="text" className="form-control text-right" 
                                                                    name="person.maximumApprovalLimit" 
                                                                    value={this.state.person.maximumApprovalLimit}
                                                                    onChange={this.handleInputChange}
                                                                    disabled={!this.state.person.authorizationRight}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-12 mt-3">
                                                                    <h6>{t('juristicCompany:Address')}</h6>
                                                                </div>
                                                                <div className="col-12 ">
                                                                    <textarea className="forminput form-control" value={this.state.person.address} 
                                                                    name="person.address" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-6 mt-2">
                                                                    <h6>{t('juristicCompany:Department')}</h6>
                                                                </div>
                                                                <div className="col-6 mt-2" >
                                                                    <h6>{t('juristicCompany:Position')}</h6>
                                                                </div>
                                                                <div className="col-6">
                                                                    <input type="text" className="form-control"
                                                                        value={this.state.person.department}
                                                                        onChange={this.handleInputChange}
                                                                        name="person.department" />
                                                                </div>
                                                                <div className="col-6 ">
                                                                <input type="text" className="form-control"
                                                                        value={this.state.person.position}
                                                                        onChange={this.handleInputChange}
                                                                        name="person.position" required/>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-6 mt-3">
                                                                    <h6>{t('juristicCompany:Affiliation')}</h6>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <h6>{t('juristicCompany:Employment status')}</h6>
                                                                </div>
                                                                <div className="col-6">
                                                                <input type="text" className="form-control"
                                                                        value={this.state.person.affiliation}
                                                                        onChange={this.handleInputChange}
                                                                        name="person.affiliation"/>
                                                                </div>
                                                                <div className="col-6">
                                                                <select className="form-control dropdown-custom-arrow"
                                                                        value={this.state.person.employmentStatus}
                                                                        onChange={this.handleInputChange}
                                                                        name="person.employmentStatus">
                                                                        <option value="available">{t('juristicCompany:Working')}</option>
                                                                        <option value="non-available">{t('juristicCompany:Out of contact')}</option>
                                                                </select>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-6 mt-3">
                                                                    <h6>{t('juristicCompany:Date of position')}</h6>
                                                                </div>
                                                                <div className="col-6 mt-3">
                                                                    <h6>{t('juristicCompany:Date of end position')}</h6>
                                                                </div>
                                                                <div className="col-6">
                                                                    <DatePickerAdapter 
                                                                        name="person.dateOfPosition"
                                                                        className="form-control"
                                                                        selected={getDateTime(this.state.person.dateOfPosition)}
                                                                        onChange={this.handleInputChange}
                                                                        required={true}
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <DatePickerAdapter 
                                                                        name="person.dateOfEndPosition"
                                                                        className="form-control"
                                                                        selected={getDateTime(this.state.person.dateOfEndPosition)}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-12">
                                                                    <h6>{t('juristicCompany:Note')}</h6>
                                                                </div>
                                                                <div className="col-12">
                                                                    <textarea className="form-control forminput" name="person.note" value={this.state.person.note} onChange={this.handleInputChange}/>

                                                                </div>
                                                            </div>
                                                            <div className="row input-group d-flex align-items-center mt-3">
                                                                <div className="col-12">
                                                                    <h6>{t('projectManager:Contact')}</h6>
                                                                </div>
                                                                <div className="col-12 mt-3"> 
                                                                    <div className="row">
                                                                        <div className="row ml-1 col-3 align-self-center">
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/phone-manager-icon.png'} alt="phone" className="icon"/>
                                                                            <h6 className="ml-2 ">{t('projectManager:Phone')}</h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input type="text" className="form-control custom-control-inline" onChange={this.handleInputChange} 
                                                                            name={'person.phone'} value={this.state.person.phone}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-3"> 
                                                                    <div className="row">
                                                                        <div className="row ml-1 col-3 align-self-center">
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/mail-icon.png'} alt="mail" className="icon" />
                                                                            <h6 className="ml-2">{t('projectManager:Email')}</h6>
                                                                        </div>
                                                                        <div className="col">
                                                                        <input type="text" className="form-control custom-control-inline" onChange={this.handleInputChange} 
                                                                        name="person.email" value={this.state.person.email}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row mt-4 bottom mb-3" >
                                                                <div className="col-12 text-right float-right">
                                                                    <button type="submit"
                                                                            className="btn btn-primary-blue pr-4 pl-4 mr-2"
                                                                            form="juristic-manager-form"  disabled={this.state.loading}>
                                                                        {this.state.loading && <span
                                                                            className="spinner-border spinner-border-sm align-middle mr-1"/>}
                                                                        <span>{t("juristicCompany:Confirm")}</span>
                                                                    </button>
                                                                    <button type="button"
                                                                            className="btn btn-secondary  pr-4 pl-4"
                                                                            onClick ={() => this.onCancel()}  disabled={this.state.loading}>
                                                                        {this.state.loading && <span
                                                                            className="spinner-border spinner-border-sm align-middle mr-1"/>}
                                                                        <span>{t("juristicCompany:Cancel")}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            
                                                
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </form>
                                    :
                                    <div className="row p-3 d-flex justify-content-center">
                                        <div className="col-md-10 col-sm-12">
                                            <div className="card card-form pt-3 pr-4 pt-1">
                                                <div className="row mt-3">
                                                    <div className="col-12  text-right float-right">
                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristiccompany_edit' }) &&
                                                            <button className="btn btn-outline-primary" onClick={() => this.setState({edit:true})}>
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/edit-blue-color.png'} 
                                                                alt="edit-icon" className="mr-1"/>{t('juristicCompany:Edit')}
                                                            </button>
                                                        }
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row mb-1">
                                                            <div className="col-md-2">
                                                                <div className="col-12 d-flex justify-content-center mt-2">
                                                                    <img
                                                                        src={this.state.person.image ? this.state.person.image : process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                        alt="project-manager-profile"
                                                                        className="rounded-circle avatar-img profile-img"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md">
                                                                <h4>{this.state.person.namePrefix} {this.state.person.firstName} {this.state.person.lastName}</h4> <br/>
                                                                <h6 className="head-grey">{this.state.person.position}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-1">
                                                            <div className="col-12 mt-4">
                                                                <h6>{t('projectManager:Authorization Right')}</h6>
                                                            </div>
                                                            <div className="col-12">
                                                                <span className="head-grey">{this.state.person.authorizationRight ?t('projectManager:Have right') :t('projectManager:Dont have right')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-1 ">
                                                            <div className="col-12 mt-2">
                                                                <h6>{t('projectManager:Maximum approval limit')}</h6>
                                                            </div>
                                                            <div className="col-12">
                                                                <span className="head-grey">{numberWithComma(this.state.person.maximumApprovalLimit,'-')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-1">
                                                            <div className="col-12 mt-3">
                                                                <h6>{t('juristicCompany:Address')}</h6>
                                                            </div>
                                                            <div className="col-12 ">
                                                                <span className="head-grey">{this.state.person.address}</span>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-1">
                                                            <div className="col-6 mt-2">
                                                                <h6>{t('juristicCompany:Department')}</h6>
                                                            </div>
                                                            <div className="col-6 mt-2" >
                                                                <h6>{t('juristicCompany:Position')}</h6>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="head-grey">{this.state.person.department}</span>
                                                            </div>
                                                            <div className="col-6 ">
                                                                <span className="head-grey">{this.state.person.position}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-1">
                                                            <div className="col-6 mt-3">
                                                                <h6>{t('juristicCompany:Affiliation')}</h6>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <h6>{t('juristicCompany:Employment status')}</h6>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="head-grey">{this.state.person.affiliation}</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="head-grey">{this.state.person.employmentStatus === 'available' ?
                                                                t(`juristicCompany:Working`) : t(`juristicCompany:Out of contact`)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-1">
                                                            <div className="col-6 mt-3">
                                                                <h6>{t('juristicCompany:Date of position')}</h6>
                                                            </div>
                                                            <div className="col-6 mt-3">
                                                                <h6>{t('juristicCompany:Date of end position')}</h6>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="head-grey">{format(getDateTime(this.state.person.dateOfPosition),'DD/MM/YYYY')}</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="head-grey">{(this.state.person.dateOfEndPosition !== null && this.state.person.dateOfEndPosition !== '') ? format(getDateTime(this.state.person.dateOfEndPosition),'DD/MM/YYYY') : '-'}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-12">
                                                                <h6>{t('juristicCompany:Note')}</h6>
                                                            </div>
                                                            <div className="col-12">
                                                                <span className="head-grey">{this.state.person.note}</span>

                                                            </div>
                                                        </div>
                                                        <div className="row input-group d-flex align-items-center mt-3">
                                                            <div className="col-12">
                                                                <h6>{t('projectManager:Contact')}</h6>
                                                            </div>
                                                            <div className="col-12 mt-3"> 
                                                                <div className="row">
                                                                    <div className="row ml-1 col-3 align-self-center">
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/phone-manager-icon.png'} alt="phone" className="icon"/>
                                                                        <h6 className="ml-2 ">{t('projectManager:Phone')}</h6>
                                                                    </div>
                                                                    <div className="col">
                                                                        <span className="head-grey">{this.state.person.phone || '-'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-3"> 
                                                                <div className="row">
                                                                    <div className="row ml-1 col-3 align-self-center">
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/mail-icon.png'} alt="mail" className="icon" />
                                                                        <h6 className="ml-2">{t('projectManager:Email')}</h6>
                                                                    </div>
                                                                    <div className="col">
                                                                        <span className="head-grey">{this.state.person.email || '-'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>

        );
    }


}
export default CreateUpdatePersonnel;

 // <div className="col-md col-sm-12">
                                    //     <div className="card card-form p-3">
                                    //         <div className="row mt-4">
                                    //             <div className="col-12">
                                    //                 <h6>{t('juristicCompany:Document')} </h6>
                                    //             </div>
                                    //             {this.state.person.documentteamSet.edges.length > 0 && 
                                    //                 this.state.person.documentteamSet.edges.map((document,index) => {
                                    //                     return(
                                    //                         <div className="row col-12 mb-2" key={index + 'edit'}>
                                    //                             <div className="input-group col-5 align-self-center">
                                    //                                 <div className="input-group-prepend">
                                    //                                         <strong><span className="input-group-text">{t('juristicManager:Name')}</span></strong>
                                    //                                     </div>
                                    //                                 <input type="text" className="form-control" value={document.node.fileName} 
                                    //                                 name="nameFile" onChange={this.handleChangeNamFile}
                                    //                                 data-key = "documentFile" data-id={index} required={true}/>
                                    //                             </div>
                                    //                             <div className="col ">
                                    //                                 <UploadFileInput
                                    //                                     handleInputDocument={this.handleInputDocument}
                                    //                                     documentUpload={document.node.fileUpload}
                                    //                                     dataId={index} dataKey="documentFile"
                                    //                                     disableHeaderText={true}
                                    //                                 /> 
                                    //                             </div>
                                    //                             <div className="col-1 align-self-center">
                                    //                                 <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                    //                                 alt="delete" className="btn-delete cursor"
                                    //                                 onClick={() => this.onDeleteItem(index)}/>
                                    //                             </div>
                                    //                         </div>
                                    //                     )

                                    //                 }) 
                                    //             }
                                    //             <div className="row col-12">
                                    //                 <button
                                    //                     type="button"
                                    //                     className="btn add-button add"
                                    //                     onClick={this.addDocument}
                                    //                 >
                                    //                     <span className="text-blue add-list" >+ {t('createUpdateForm:add_list')}</span>
                                    //                 </button>  
                                    //             </div>   

                                    //         </div>

                                    //     </div>
                                    // </div>


