/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type manegeTopMenuNavigationQueryVariables = {||};
export type manegeTopMenuNavigationQueryResponse = {|
  +getNotificationWaitPurchaseOrderes: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>
|};
export type manegeTopMenuNavigationQuery = {|
  variables: manegeTopMenuNavigationQueryVariables,
  response: manegeTopMenuNavigationQueryResponse,
|};
*/


/*
query manegeTopMenuNavigationQuery {
  getNotificationWaitPurchaseOrderes {
    type
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationType",
    "kind": "LinkedField",
    "name": "getNotificationWaitPurchaseOrderes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "manegeTopMenuNavigationQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "manegeTopMenuNavigationQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "46663bca4b277d05ed66c71b6cf05e9f",
    "id": null,
    "metadata": {},
    "name": "manegeTopMenuNavigationQuery",
    "operationKind": "query",
    "text": "query manegeTopMenuNavigationQuery {\n  getNotificationWaitPurchaseOrderes {\n    type\n    count\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64f2185eca05684a50c66a8060e1436e';

module.exports = node;
