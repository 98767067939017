import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../libs/loading";
import {Translation} from 'react-i18next';

const query = graphql`
    query parcelReportQuery($start_date:DateTime, $end_date:DateTime) {
        parcelViewer{
            notReceiveOneDay :countByStatusParcel(parcelStatus:"not_receive", startDate: $start_date, endDate: $end_date)
            notReceiveAll :countByStatusParcel(parcelStatus:"not_receive")
        } 
    }
`;

class ParcelReport extends React.Component {
    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{use_cache: false}}
                variables={{
                    start_date: new Date(),
                    end_date: new Date(),
                }}
                render={({error, props}) => {

                    if (error) {
                        return <div className="alert alert-danger"
                                    role="alert">{error.message}</div>;
                    } else if (props) {
                        return <div className="row parcel">
                            <div className="col-xl-6 text-center mt-xl-5 mt-3 pb-4">
                                <Translation>
                                    {
                                        t => <span>{t('dashboard:New parcels')}</span >
                                    }
                                </Translation>
                                <br/>
                                <Translation>
                                    {
                                        t => <span>{t('dashboard:in the past one day')}</span >
                                    }
                                </Translation>
                                <br/>
                                <span className="number">
                                    {props.parcelViewer.notReceiveOneDay}</span><br/>
                                <Translation>
                                    {
                                        t => <span>{t('dashboard:List')}</span >
                                    }
                                </Translation>
                            </div>
                            <div className="col-xl-6 text-center mt-xl-5 mt-3">
                                &nbsp;<br/>
                                <Translation>
                                    {
                                        t => <span>{t('dashboard:Backlog Parcels')}</span >
                                    }
                                </Translation><br/>
                                <span className="number">{props.parcelViewer.notReceiveAll}</span><br/>
                                <Translation>
                                    {
                                        t => <span>{t('dashboard:List')}</span >
                                    }
                                </Translation><br/>
                            </div>
                        </div>
                        }
                    return <Loading/>;
                }}
            />
        )
    }
}

export default ParcelReport;
