import SurveyCreateUpdateForm from "./surveyCreateUpdateForm";
import React, {Component} from 'react';
import "./styles/survey.scss"


class SurveyCreate extends Component {

    render() {
        return (
            <div className="container-fluid box" id="news">
                <SurveyCreateUpdateForm/>
            </div>

        )
    }
}

export default SurveyCreate;