import React from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import SettingInvoiceCreateForm from './settingInvoiceCreateForm';
import Loading from '../../libs/loading';
import _ from "lodash";
const query = graphql`
    query preInvoiceFormQuery {
        viewer {
            allChartOfAccount(chartOfAccountCode: "1131-12"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                        
                    }
                }
            }
        }
        group113110: viewer {
            allChartOfAccount(chartOfAccountCode: "1131-10"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                        
                    }
                }
            }
        }
        
        group4300: viewer {
            allChartOfAccount(chartOfAccountCode: "4300-04"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                        
                    }
                }
            }
        }
        productViewer{
            allProduct (type_In: "service,fine",isActive: true){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        customerChartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        creditorChartOfAccount{
                            id
                            chartOfAccountCode
                            name           
                        }
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        servicePricing {
                            edges {
                                node {
                                    id
                                    periodAdjustment
                                    pricingType
                                    rounding
                                    receiveDeposit{
                                        chartOfAccountCode
                                        name
                                    }
                                    deferredIncome{
                                        chartOfAccountCode
                                        name
                                    }
                                }
                            }
                        }
                        servicePricingEachUnit{
                            edges {
                                node {
                                    id
                                    pricing
                                    unit{
                                        id
                                    }
                                }
                            }                        
                        }
                        description
                        totalLeftInStock
                    }
                }
            }
        }
        selfProject{
            haveVat
        }
    }
`;

const PreInvoiceForm = (props) => {
    let listResidentId = props.listResidentId
    let listResident = props.listResident
    return (
            <div className="container-fluid box">
                <div className="row justify-content-between">
                    <div className="col">
                        <h3 className="mb-4">
                            <Link to="/setting/invoice-setting-terms/form/select">
                                <img src={process.env.PUBLIC_URL + '/images/icons/back.png'}
                                    alt="back" className="back" />
                            </Link>
                            <Translation>
                                {t =>
                                    <span className="text-global-header">
                                        {t("settingInvoiceTerms:createInvoiceTerms")}
                                    </span>
                                }
                            </Translation>
                        </h3>
                    </div>
                    {/* <div className="div-setting-invoice" /> */}
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    render={({error, props}) => {
                        if (error) {
                            return <div>{error.message}</div>;
                        } else if (props) {
                            return <SettingInvoiceCreateForm 
                                listContactId={listResidentId} 
                                contact={listResident} 
                                product={props.productViewer.allProduct.edges}
                                fine_chart_of_account={ _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'chart_of_account_sena_v2'])?.menu_on ? 
                                                        props.group113110.allChartOfAccount.edges 
                                                        : 
                                                        props.viewer.allChartOfAccount.edges
                                                    }
                                fine_chart_of_account4300={props.group4300.allChartOfAccount.edges}
                                sena={localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') ? true : false}
                            />
                        }
                        return <Loading/>
                    }}
                />
                    
            </div>
    )
}

export default PreInvoiceForm