
import { graphql } from "babel-plugin-relay/macro";

const checkResidentialQuery = graphql`
    query checkResidentailQuery($name: String) {
        checkResidential(name:$name){
        result
        reason
      }
    }
`;


export default checkResidentialQuery;