import React, { Component } from 'react'
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import DeveloperTopMenuNavigation from "../developerTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import residentialAPI from '../../register/serviceAPI'
import unitStatusAPI from './unitStatusAPI';
import _ from 'lodash'
import DatePickerAdapter from '../../libs/datePickerAdapter';
import getDateTime from '../../libs/getDateTime'
import BackButtonIcon from '../../components/BackBtn/indexBack';
import i18next from 'i18next';

class UnitStatusDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: localStorage.getItem("token"),
            roomNo: '',
            ownershipName: '',
            refNumber: '',
            type: '',
            size: '',
            ownershipRatio: '',
            conveyance: 'not_transferred',
            dateConveyance: '',
            insurance: [],
            repairInsurance: '',
            floor: '',
            giftList: [],
        }
    }
    async componentDidMount() {
        await residentialAPI.getResidential(this.state.token, this.props.match.params.id).then((response) => {
            if (response.data) {
                let name = '';
                _.forEach(response.data.ownershipName, (contact, index) => {
                    name = index === 0 ? contact : name + " , " + contact
                })
                this.setState({
                    roomNo: response.data.name,
                    ownershipName: name,
                    refNumber: response.data.refNumber,
                    dateConveyance: response.data.dateConveyance,
                    type: response.data.type,
                    size: response.data.size,
                    ownershipRatio: response.data.ownershipRatio,
                    conveyance: response.data.conveyance,
                    floor: response.data.floor
                })
            }
        })
        await unitStatusAPI.getOwnershipDetail(this.state.token, this.props.match.params.id).then((response) => {
            if (response.data) {
                this.setState({
                    insurance: response.data.insurance,
                    repairInsurance: response.data.repairInsurance,
                    giftList: response.data.gift,
                })
            }
        })

    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <DeveloperTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="developer-form">
                                <BackButtonIcon
                                    LinkBack={"/developer/unit-status/list/all"}
                                    LinkText={t("developer:Unit Status")}
                                />
                                <div className="content-inner content-inner-padding mt-3">
                                    <div className="row d-flex align-items-center">
                                        <div className="col form-inline">
                                            <h4 className="mr-4">{i18next.t("unitStatusDetail:Property Ownership Information")} </h4>
                                        </div>
                                        {this.state.dateConveyance &&
                                            <div className="col d-flex justify-content-end align-items-center">
                                                <h6 className="align-items-center mr-3">{i18next.t("unitStatusDetail:Transfer of Ownership Date")} </h6>
                                                <DatePickerAdapter
                                                    name="expireDate" className="form-control w-100 align-items-center"
                                                    selected={getDateTime(this.state.dateConveyance)}
                                                    disable={true}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <hr />
                                    <div className="row mt3">
                                        <div className="col-3">
                                            <h6>{i18next.t("unitStatusDetail:Room Number")} :</h6>
                                        </div>
                                        <div className="col-9">
                                            <span>{this.state.roomNo}</span>
                                        </div>
                                        <div className="col-3">
                                            <h6>{i18next.t("unitStatusDetail:Owner Name")}:</h6>
                                        </div>
                                        <div className="col-9">
                                            <span>{this.state.ownershipName}</span>
                                        </div>
                                        <div className="col-3">
                                            <h6>{i18next.t("unitStatusDetail:Type")}:</h6>
                                        </div>
                                        <div className="col-9">
                                            <span>{this.state.type}</span>
                                        </div>
                                        <div className="col-3">
                                            <h6>{i18next.t("unitStatusDetail:Size (sq.m.)")}:</h6>
                                        </div>
                                        <div className="col-9">
                                            <span>{this.state.size}</span>
                                        </div>
                                        <div className="col-3">
                                            <h6>{i18next.t("unitStatusDetail:Floor")}:</h6>
                                        </div>
                                        <div className="col-9">
                                            <span>{this.state.floor}</span>
                                        </div>
                                        <div className="col-3">
                                            <h6>{i18next.t("unitStatusDetail:Ownership Percentage")} (%):</h6>
                                        </div>
                                        <div className="col-9">
                                            <span>{this.state.ownershipRatio}</span>
                                        </div>
                                    </div>

                                    {/* ของแถม */}
                                    <div className="row d-flex align-items-center mt-6">
                                        <div className="col form-inline">
                                            <h4 className="mr-4 mt-3">{t("settingAll:freebies")}</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt3">
                                        {
                                            this.state.giftList.map((gift, index) => (
                                                <React.Fragment key={index}>

                                                    <div className="col-12 mt-4">
                                                        <h4>{t("settingAll:item")} {index + 1}</h4>
                                                    </div>
                                                    <div className='d-flex flex-row'>
                                                        {
                                                            gift.giftImage ?
                                                                <img
                                                                    className='rounded mr-4 pl-4'
                                                                    src={gift.giftImage}
                                                                    width="180px"
                                                                    height="180px"
                                                                    alt={gift.giftName}
                                                                /> :
                                                                <div className='pl-4 mr-4' style={{ width: "180px", height: "180px" }} />
                                                        }

                                                        <div className='align-self-center'>
                                                            <div className='d-flex flex-row'>
                                                                <h6 style={{ width: "220px" }}>{t("settingAll:freebie_in_thai_name")}:</h6>
                                                                <span>{gift.giftName}</span>
                                                            </div>
                                                            <div className='d-flex flex-row'>
                                                                <h6 style={{ width: "220px" }}>{t("settingAll:freebie_in_english_name")}:</h6>
                                                                <span>{gift.giftNameEn}</span>
                                                            </div>
                                                            <div className='d-flex flex-row'>
                                                                <h6 style={{ width: "220px" }}>{t("settingAll:quantity")}:</h6>
                                                                <span>{gift.unitItems}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        }

                                    </div>

                                    <div className="row d-flex align-items-center">
                                        <div className="col form-inline">
                                            <h4 className="mr-4 mt-3">{i18next.t("unitStatusDetail:Insurance")}</h4>
                                        </div>
                                        <div className="col-3 d-flex justify-content-end ">
                                            <span className="text-blue">{i18next.t("unitStatusDetail:Insurance Policy")}</span>
                                        </div>
                                    </div>

                                    <hr />
                                    {this.state.insurance.length === 0 ?
                                        <div className="row d-flex justify-content-center mt-2">
                                            {this.state.conveyance === 'already_transferred' ?
                                                <h4 className="mt-4">{i18next.t("unitStatusDetail:No insurance transactions recorded in the system")}</h4>
                                                :
                                                <React.Fragment>
                                                    <h4 className="text-red mt-4">{i18next.t("unitStatusDetail:No insurance department information available. Please transfer ownership before proceeding with insurance.")}</h4>
                                                    <div className="row d-flex justify-content-center col-12 mt-2">
                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'ownership_ownership_create' }) &&
                                                            <Link to="/developer/unit-status/create/select_customer">
                                                                <button type="button" className="selected-btn">
                                                                    <span>{i18next.t("unitStatusDetail:Transfer Ownership")}</span>
                                                                </button>
                                                            </Link>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                        :
                                        <div className="box-detail">
                                            <div className="row col-9 mb-3">
                                                <div className="col-md-7 col-sm-8">
                                                    {i18next.t("unitStatusDetail:Repair Warranty Period from Project Developer (months)")} <br />
                                                    <input type="number" value={this.state.repairInsurance} name="timeOfGuarantee"
                                                        className="form-control col-9" onChange={this.handleChange}
                                                        disabled={true} />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row col-12 mb-3 mt-3">
                                                <div className="col-12"><h5>{i18next.t("unitStatusDetail:Insurance Type")}</h5></div>
                                                <div className="col-6">
                                                    {this.state.insurance.map((transaction, index) => {
                                                        return (
                                                            <div className="input-group mt-2" key={index}>
                                                                <div className="col-7 box-transaction">
                                                                    <input type="checkbox" className="mr-2"
                                                                        checked={true}
                                                                        data-id={index}
                                                                        data-key="data-insurance"
                                                                        name="isInsure"
                                                                        disabled={true} />
                                                                    <span>{transaction.name}</span> <br />
                                                                    <div className="ml-4" >
                                                                        <span className="text-detail">{transaction.description}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    {i18next.t("unitStatusDetail:Insurance Expiry Date")}
                                                                    <DatePickerAdapter
                                                                        name="expireDate" className="form-control w-100"
                                                                        data-key="data-insurance"
                                                                        data-id={index}
                                                                        selected={getDateTime(transaction.expDate)}
                                                                        disable={true}
                                                                    />

                                                                </div>
                                                                <hr className="mt-2 insurance" />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>

                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>

        );
    }



}
export default UnitStatusDetail;