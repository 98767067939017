/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type generalLedgerTableBetweenChartOfAccountQueryVariables = {|
  startDate?: ?any,
  betweenStartId?: ?string,
  betweenEndId?: ?string,
|};
export type generalLedgerTableBetweenChartOfAccountQueryResponse = {|
  +historicalRecord: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +debit: number,
        +credit: number,
        +chartOfAccountCode: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |},
      |}
    |}>
  |},
  +summaryPreviousAccountRecord: ?{|
    +sumJson: ?any
  |},
  +betweenChartOfAccount: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +chartOfAccountCode: string,
        +name: string,
      |}
    |}>,
  |},
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +tableAccount: ?any,
      |}
    |}>
  |},
|};
export type generalLedgerTableBetweenChartOfAccountQuery = {|
  variables: generalLedgerTableBetweenChartOfAccountQueryVariables,
  response: generalLedgerTableBetweenChartOfAccountQueryResponse,
|};
*/


/*
query generalLedgerTableBetweenChartOfAccountQuery(
  $startDate: DateTime
  $betweenStartId: String
  $betweenEndId: String
) {
  historicalRecord: allAccountRecord(typeOfRecord: "historical", startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, orderLedger: true) {
    edges {
      node {
        id
        name
        debit
        credit
        chartOfAccountCode {
          id
          name
          chartOfAccountCode
        }
      }
    }
  }
  summaryPreviousAccountRecord(startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {
    sumJson
  }
  betweenChartOfAccount(betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {
    totalCount
    edges {
      node {
        id
        chartOfAccountCode
        name
      }
    }
  }
  allAccountProjectManager {
    edges {
      node {
        id
        tableAccount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenEndId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "betweenStartId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v3 = {
  "kind": "Variable",
  "name": "betweenEndId",
  "variableName": "betweenEndId"
},
v4 = {
  "kind": "Variable",
  "name": "betweenStartId",
  "variableName": "betweenStartId"
},
v5 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "startDate"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v9 = [
  {
    "alias": "historicalRecord",
    "args": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "kind": "Literal",
        "name": "orderLedger",
        "value": true
      },
      (v5/*: any*/),
      {
        "kind": "Literal",
        "name": "typeOfRecord",
        "value": "historical"
      }
    ],
    "concreteType": "AccountRecordNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountRecordNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountRecordNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountNode",
                "kind": "LinkedField",
                "name": "chartOfAccountCode",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "concreteType": "SummaryPreviousAccountRecord",
    "kind": "LinkedField",
    "name": "summaryPreviousAccountRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sumJson",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "concreteType": "ChartOfAccountNodeConnection",
    "kind": "LinkedField",
    "name": "betweenChartOfAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v8/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountProjectManagerNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountProjectManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountProjectManagerNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountProjectManagerNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tableAccount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "generalLedgerTableBetweenChartOfAccountQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "generalLedgerTableBetweenChartOfAccountQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "b24143d2c5be32f736fa25b631537e61",
    "id": null,
    "metadata": {},
    "name": "generalLedgerTableBetweenChartOfAccountQuery",
    "operationKind": "query",
    "text": "query generalLedgerTableBetweenChartOfAccountQuery(\n  $startDate: DateTime\n  $betweenStartId: String\n  $betweenEndId: String\n) {\n  historicalRecord: allAccountRecord(typeOfRecord: \"historical\", startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, orderLedger: true) {\n    edges {\n      node {\n        id\n        name\n        debit\n        credit\n        chartOfAccountCode {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n  }\n  summaryPreviousAccountRecord(startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {\n    sumJson\n  }\n  betweenChartOfAccount(betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {\n    totalCount\n    edges {\n      node {\n        id\n        chartOfAccountCode\n        name\n      }\n    }\n  }\n  allAccountProjectManager {\n    edges {\n      node {\n        id\n        tableAccount\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5a3a15d77c220d24037d808a02964b4';

module.exports = node;
