import React from "react";
import {format} from "date-fns";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import {fetchQuery} from "relay-runtime";
import SummaryListTable from "../accrualBasis/summaryListTable";
import Loading from "../../libs/loading";

const query = graphql`
    query channelPayListTableQuery ($typeOfReport: String, $start_date: DateTime, $end_date: DateTime, $month_count: Int) {
        chartOfAccountCashBasisChannel(startDate: $start_date, endDate: $end_date, monthCount: $month_count, typeOfReport: $typeOfReport) {
            edges {
                node {
                    id
                    name nameEn
                    chartOfAccountCode
                    col1Debit col2Debit col3Debit col4Debit col5Debit col6Debit col7Debit col8Debit col9Debit col10Debit col11Debit col12Debit
                    col1Credit col2Credit col3Credit col4Credit col5Credit col6Credit col7Credit col8Credit col9Credit col10Credit col11Credit col12Credit
                }
            }
            totalCount
        }
    }
`;

class ChannelPayListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            month_list: [],
            chartOfAccountCashBasisChannel: [],
            summary: [],
            total_row: [],
            list_data_loop: Array.from(new Array(this.props.state.month_count + 1)),
            loading: false,
            getPersentMonth: new Date(). getMonth() + 1,

        };
        this.setPeriod = this.setPeriod.bind(this);
        this.queryChartOfAccount = this.queryChartOfAccount.bind(this);
    }

    componentWillMount() {
        this.queryChartOfAccount();
    }

    queryChartOfAccount() {
        this.setState({
            loading: true,
        })
        fetchQuery(environment, query, {
            start_date: this.props.state.start_period,
            end_date: this.props.state.end_period,
            month_count: this.props.state.month_count,
            typeOfReport: this.props.channel.code
        }).then(data => {
            if (_.isObject(data.chartOfAccountCashBasisChannel)) {
                let summary = [];
                let total_row = [];
                //  let data_set = _.cloneDeep(data);
                 let chartOfAccountCashBasisChannel = []
                data.chartOfAccountCashBasisChannel.edges.forEach((chart, index) => {
                    let node = chart.node;
                    let total = 0;

                    if(new Date() > this.props.state.end_period){
                        for (let i = 1; i <= this.props.state.month_count + 1; i++) {
                            total += Number(node[`col${i}Debit`] - node[`col${i}Credit`])
                        }
                    }
                    else{
                        // เพิ่มเงื่อนไขจะแสดงถึงแค่เดือนปัจจุบัน จาก BTA1034             
                        for (let i = 1; i <= this.state.getPersentMonth; i++) {
                            total += Number(node[`col${i}Debit`] - node[`col${i}Credit`])
                        }
                    }


                    _.set(total_row, index, total);

                    let empty_value = true;
                    if (total > 0) {
                        empty_value = false
                    }

                    _.set(chart, 'node.empty_value', empty_value);

                    for (let i = 0; i < this.props.state.month_count + 2; i++) {
                        if (i === this.props.state.month_count + 1) {
                            _.set(summary, i, _.get(summary, i, 0) + total)

                        } else {
                            let debitAmount = 0
                            let creditAmount = 0
                            if(new Date() > this.props.state.end_period){  
                                debitAmount = ((i+1 > this.state.month_count + 1 ? 0 : node['col' + (i + 1) + 'Debit'] || 0))
                                creditAmount = (i+1 > this.state.month_count + 1 ? 0 : node['col' + (i + 1) + 'Credit'] || 0)
                            }
                            else{
                                // เพิ่มเงื่อนไขจะแสดงถึงแค่เดือนปัจจุบัน จาก BTA1034
                                debitAmount = ((i+1 > this.state.getPersentMonth ? 0 : node['col' + (i + 1) + 'Debit'] || 0))
                                creditAmount = (i+1 > this.state.getPersentMonth ? 0 : node['col' + (i + 1) + 'Credit'] || 0)
                            }


                            _.set(summary, i, _.get(summary, i, 0) + debitAmount - creditAmount)
                        }
                    }

                    let _chart = _.cloneDeep(chart);
                    _chart['node']['empty_value'] = total == 0;
                    chartOfAccountCashBasisChannel.push(_chart)
                });

                this.setState({
                    // chartOfAccountCashBasisChannel: data_set.chartOfAccountCashBasisChannel.edges,
                    chartOfAccountCashBasisChannel: chartOfAccountCashBasisChannel,
                    summary: summary,
                    total_row: total_row,
                    loading: false,
                }, () => this.props.updateSummary('total_cash_and_bank', this.state))
            }
        })
        this.setState({
            loading: false,
        })
    }


    componentDidMount() {
        this.setPeriod();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.start_period !== this.props.state.start_period || prevProps.state.end_period !== this.props.state.end_period) {
            this.setPeriod();
            this.queryChartOfAccount();
        }
    }

    setPeriod() {
        this.setState({
            loading: true,
        })
        let start_month = this.props.state.start_period.getMonth();
        let start_year = this.props.state.start_period.getFullYear();
        let month_list = [this.props.state.start_period];
        let list = Array.from(new Array(this.props.state.month_count));
        let list_data_loop = Array.from(new Array(this.props.state.month_count + 2));

        list.map((month, index) => {
            let new_date = new Date(start_year, start_month + (index + 1));
            if (index === this.props.state.month_count - 1) {
                new_date = this.props.state.end_period
            }
            return month_list.push(new_date);
        });
        this.setState({month_list: month_list, list_data_loop: list_data_loop, loading: false});
    }

    render() {
        return (
            this.state.loading?
            <Loading/>
            :
            <React.Fragment>
                {this.state.chartOfAccountCashBasisChannel.length > 0 &&
                <React.Fragment>
                    {this.props.channel.header &&
                    <thead className="text-center">
                    <tr>
                        <th className="text-left table-active"><strong><u>เงินสดและเงินฝากธนาคาร / Cash & Bank / B/F</u></strong></th>
                        {this.state.month_list.map((month, index) => {
                            return (
                                <th className="table-active" key={index+'-'+month}>
                                    {format(month, "MMM YY")}
                                </th>
                            )
                        })}
                        <th className="table-active" />
                    </tr>
                    </thead>
                    }
                    <tbody>
                    <tr>
                        <td width={360}><strong>{this.props.channel.header_name}</strong></td>
                        {this.state.month_list.map((month, index) => {
                            return (
                                <td width={100} key={'td-' + index}/>
                            )

                        })}
                        <td/>
                    </tr>
                    {this.state.chartOfAccountCashBasisChannel.map((chart, index) => {
                        if (chart.node.empty_value) {
                            return <React.Fragment key={index}/>;
                        }
                        return (
                            <React.Fragment key={chart.node.id}>
                                <tr>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;{chart.node.name}{chart.node.nameEn ? " / "+chart.node.nameEn: ""}</td>
                                    {this.state.month_list.map((month, index) => {
                                        let data_index = index + 1;
                                        return (
                                            <td className="text-right" key={'data-td-' + index}>
                                                 {/* เพิ่มเงื่อนไขจะแสดงถึงแค่เดือนปัจจุบัน จาก BTA1034 */}
                                                {(index >= this.state.getPersentMonth && new Date() < this.props.state.end_period) ?
                                                "-"
                                                :
                                                numberWithComma(chart.node['col' + data_index + 'Debit'] - chart.node['col' + data_index + 'Credit'])
                                                }
                                            </td>
                                        )
                                    })}
                                    <td/>
                                </tr>
                            </React.Fragment>

                        )
                    })}
                    <SummaryListTable text_summary={this.props.channel.summary_name}
                                      summary={this.state.summary}
                                      list_data_loop={this.state.list_data_loop}
                                      empty_last_col={true}
                    />

                    {this.props.channel.all_summary &&
                    <SummaryListTable text_summary={"รวมเงินสดและเงินฝากธนาคาร"}
                                      summary={this.props.total_cash_and_bank}
                                      list_data_loop={this.state.list_data_loop}
                                      empty_last_col={true}
                    />
                    }
                    </tbody>
                </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default ChannelPayListTable;
