/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateMessageInput = {|
  room: string,
  message: string,
  clientMutationId?: ?string,
|};
export type chatMutationVariables = {|
  input: CreateMessageInput
|};
export type chatMutationResponse = {|
  +createMessage: ?{|
    +newMessage: ?{|
      +id: string,
      +image: string,
    |}
  |}
|};
export type chatMutation = {|
  variables: chatMutationVariables,
  response: chatMutationResponse,
|};
*/


/*
mutation chatMutation(
  $input: CreateMessageInput!
) {
  createMessage(input: $input) {
    newMessage {
      id
      image
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMessagePayload",
    "kind": "LinkedField",
    "name": "createMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageNode",
        "kind": "LinkedField",
        "name": "newMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "chatMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "chatMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "af9fb30c2b81e4245798f945b06d0388",
    "id": null,
    "metadata": {},
    "name": "chatMutation",
    "operationKind": "mutation",
    "text": "mutation chatMutation(\n  $input: CreateMessageInput!\n) {\n  createMessage(input: $input) {\n    newMessage {\n      id\n      image\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ab8113f49708cb94cc104b1c37c63c4';

module.exports = node;
