import React, { Component } from 'react';
import { QueryRenderer } from 'react-relay';
import environment from "../../../env/environment";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import numberWithComma from "../../../libs/numberWithComma";
import { Translation } from 'react-i18next';
import i18n from 'i18next';
import paymentGatewayText from "../../../libs/paymentGatewayText";
import SearchSelect from "../../../libs/searchSelect";
import Swal from "sweetalert2";
import _ from "lodash";
import Loading from "../../../libs/loading";

class PaymentGatewayCreateSelectTransactionList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkList: [],
            check_all: false,
            on_submit: false,
            paymentGatewaySettingListType: null,
            total: 0.0
        };
        this.rowSelect = this.rowSelect.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkValidation = this.checkValidation.bind(this);
        this.restore = this.restore.bind(this)
    }

    handleCheckInput(id) {
        return this.state.checkList.some(e => e.id === id)
    }

    checkValidation(transaction){																			
        // ถ้า 3.3 เข้าธนาคารเดียวกันแต่มี deeplink มาผสมให้แจ้งเตือนว่า "กรุณาเลือกรายการนำฝากช่องทางรับชำระเดียวกัน"  
        // เพราะ deeplink เข้ารายรับไม่เหมือนคนอื่น (ข้อนี้ต้องอ่าน 4.2 ก่อน)																				
        // Note ทำเงื่อนไข

        // หน้านี้กรณี User เลือกต่างธ.กดนำฝากให้ระบบฟ้องว่า "กรุณาเลือกรายการนำฝากธนาคารเดียวกัน" ถึงจะสามารถกดสร้างรายการนำฝากได้

        //ถ้าไม่ได้ใส่ธนาคาร
        if(transaction.bankAccount === null  && this.state.checkList[0].bankAccount === null){
            let paymentType = {}
            let deeplink = 0
            let other_payment = 0
            this.state.checkList.forEach((e) =>{
                if (e.paymentGateway.paymentType in paymentType){
                    paymentType[e.paymentGateway.paymentType].push(e)
                }
                else {
                    paymentType[e.paymentGateway.paymentType] = []
                    paymentType[e.paymentGateway.paymentType].push(e)
                }

            })
            if (transaction.paymentGateway.paymentType in paymentType){
                paymentType[transaction.paymentGateway.paymentType].push(transaction)
            }
            else {
                paymentType[transaction.paymentGateway.paymentType] = []
                paymentType[transaction.paymentGateway.paymentType].push(transaction)
            }

            Object.entries(paymentType).map(([keys, result], index) => {
                if(keys === "DEEPLINK"){
                    deeplink += 1
                }else{
                    other_payment += 1
                }
                return keys
            })
            
            // เข้าธนาคารเดียวกันแต่มี deeplink มาผสมให้แจ้งเตือนว่า "กรุณาเลือกรายการนำฝากช่องทางรับชำระเดียวกัน"
            if(deeplink > 0 && other_payment > 0){
                return "กรุณาเลือกรายการนำฝากช่องทางรับชำระเดียวกัน"
            }else{
                return "ok"
            }
        }
        else if(transaction.bankAccount.id === this.state.checkList[0].bankAccount.id){
            let paymentType = {}
            let deeplink = 0
            let other_payment = 0
            this.state.checkList.forEach((e) =>{
                if (e.paymentGateway.paymentType in paymentType){
                    paymentType[e.paymentGateway.paymentType].push(e)
                }
                else {
                    paymentType[e.paymentGateway.paymentType] = []
                    paymentType[e.paymentGateway.paymentType].push(e)
                }

            })
            if (transaction.paymentGateway.paymentType in paymentType){
                paymentType[transaction.paymentGateway.paymentType].push(transaction)
            }
            else {
                paymentType[transaction.paymentGateway.paymentType] = []
                paymentType[transaction.paymentGateway.paymentType].push(transaction)
            }

            Object.entries(paymentType).map(([keys, result], index) => {
                if(keys === "DEEPLINK"){
                    deeplink += 1
                }else{
                    other_payment += 1
                }
            })
            
            // เข้าธนาคารเดียวกันแต่มี deeplink มาผสมให้แจ้งเตือนว่า "กรุณาเลือกรายการนำฝากช่องทางรับชำระเดียวกัน"
            if(deeplink > 0 && other_payment > 0){
                return "กรุณาเลือกรายการนำฝากช่องทางรับชำระเดียวกัน"
            }else{
                return "ok"
            }

        }else{
            return "กรุณาเลือกรายการนำฝากธนาคารเดียวกัน"
        }
    }

    handleChangeInput(e, transaction) {
        let current_checklist = [...this.state.checkList];
        let check_all = false;
        let total = this.state.total;
        let checkValidation = ""
        if (e.target.name === 'checkbox_all') {

            if (!this.state.check_all) check_all = true;

            if ((check_all && (current_checklist.length === transaction.length)) || !check_all) {
                current_checklist = [];
                total = 0.0;
            } else {
                if (current_checklist.length !== 0) {
                    current_checklist = [];
                    total = 0.0;
                }
                transaction.forEach((item) => {
                    current_checklist.push(item.node);
                    total += item.node.total
                });
            }

        } else if (e.target.name === 'checkbox') {
            //ติ้กออก
            if (current_checklist.some(e => e.id === transaction.node.id)) {
                current_checklist.splice(current_checklist.findIndex(x => x.id === transaction.node.id), 1);
                total = total - transaction.node.total;
            }//ติ้กเลือก
            else {
                if(current_checklist.length > 0){
                    checkValidation = this.checkValidation(transaction.node)
                    if(checkValidation !== "ok"){
                        Swal.fire(i18n.t("paymentgateway:Warning!"), checkValidation, 'warning');
                        return ;
                    }
                }

                current_checklist.push(transaction.node);
                total = total + transaction.node.total;
            }
        }

        this.setState({
            checkList: current_checklist,
            check_all: check_all,
            total: total
        }, () => this.props.updateSelectTransaction(this.state.checkList))
    }

    rowSelect(name, transaction) {
        let event = {
            target: {
                name: name,
                type: 'checkbox',
                dataset: {}
            }
        };
        this.handleChangeInput(event, transaction)
    }
    restore(){
        this.setState({  
            checkList: [],
            check_all: false,
            on_submit: false,
            paymentGatewaySettingListType: null,
            total: 0.0 
        });
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <QueryRenderer
                            environment={environment}
                            query={this.props.query}
                            variables={{
                                paymentGatewaySettingListType: this.state.paymentGatewaySettingListType,
                            }}
                            render={({ error, props }) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {

                                    let paymentGatewaySettingList = _.cloneDeep(props.paymentGatewaySettingList.edges)
                                    _.set(paymentGatewaySettingList,_.findIndex(paymentGatewaySettingList,{node:{paymentType:'TWOCTWOP'}}),{node:{paymentType:'2C2P'}})

                                    return (
                                    
                                        <React.Fragment>
                                            <div className="row mt-2 mb-1">
                                                <div className="col-3">
                                                    <Translation>{t => <h6>{t("paymentgateway:payment Method")}</h6>}</Translation>
                                                    <SearchSelect onChange={this.handleInputChange}
                                                        value={this.state.paymentGatewaySettingListType}
                                                        name="paymentGatewaySettingListType" placeholder="รายการ"
                                                        queryObject={paymentGatewaySettingList}
                                                        keyOfValue="paymentType"
                                                        keyOfLabel="paymentType" />
                                                </div>
                                                <div className="col-3">
                                                <Translation>
                                                    {t =>
                                                        <button className="btn btn-primary add mr-3 mt-5"
                                                            onClick={this.restore}>
                                                            {t("paymentgateway:Restore")}
                                                        </button>}
                                                </Translation>
                                                </div>
                                            </div>
                                            

                                            <form action="" >
                                                <div className="table-responsive fade-up">
                                                    <table className="table table-hover mt-2">
                                                        <thead className="thead-light">
                                                            
                                                            {/* <tr onDoubleClick={() => this.rowSelect('checkbox_all', props.allPaymentGatewayMethod.edges)}> */}
                                                            <tr>
                                                                {
                                                                    this.state.paymentGatewaySettingListType ?
                                                                        <th>
                                                                            <input type="checkbox"
                                                                                name="checkbox_all"
                                                                                onChange={(e) => {
                                                                                    this.handleChangeInput(e, props.allPaymentGatewayMethod.edges);
                                                                                }}
                                                                                checked={this.state.check_all} />
                                                                        </th>
                                                                    :
                                                                    <th/>
                                                                }

                                                                <Translation>
                                                                    {t =>
                                                                        <>
                                                                            <th>{t("paymentgateway:No.")}</th>
                                                                            <th>{t("paymentgateway:Date")}</th>
                                                                            <th>{t("paymentgateway:Description")}</th>
                                                                            <th>{t("paymentgateway:Amount")}</th>
                                                                            <th>{t("paymentgateway:bank")}</th>
                                                                            <th>{t("paymentgateway:payment Method")}</th>
                                                                        </>
                                                                    }
                                                                </Translation>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {props.allPaymentGatewayMethod.edges.map((payment_gateway) => {
                                                                let doc_number = '';
                                                                let issuedDate = '';
                                                                if (payment_gateway.node.receive) {
                                                                    doc_number = payment_gateway.node.receive.docNumber;
                                                                    issuedDate = payment_gateway.node.receive.issuedDate;
                                                                }
                                                                return (
                                                                    <tr key={payment_gateway.node.id} onDoubleClick={() => this.rowSelect('checkbox', payment_gateway)}>
                                                                        <td>
                                                                            <input type="checkbox"
                                                                                name="checkbox"
                                                                                onChange={(e) => { this.handleChangeInput(e, payment_gateway); }}
                                                                                checked={this.handleCheckInput(payment_gateway.node.id)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {doc_number}
                                                                        </td>
                                                                        <td> {format(issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        <td>{payment_gateway.node.description}</td>
                                                                        <Translation>{t => <td>{numberWithComma(payment_gateway.node.total)} {t("paymentgateway:Baht")}</td>}</Translation>
                                                                        <td>{payment_gateway?.node.bankAccount ? payment_gateway.node.bankAccount.bankName + " " + payment_gateway.node.bankAccount.accountNumber : '-'}</td>
                                                                        <td>{paymentGatewayText(payment_gateway.node.paymentType)}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {props.allPaymentGatewayMethod.edges.length !== 0 &&
                                                    <div className="row">
                                                        <div className="col" />
                                                        <div className="col">
                                                            <div
                                                                className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark">
                                                                <Translation>{t => <div className="text-left"><strong>{t("paymentgateway:Total amount deposit")}</strong></div>}</Translation>
                                                                <div
                                                                    className="text-right ml-5"><Translation>{t => <strong>{numberWithComma(this.state.total)} {t("paymentgateway:Baht")}</strong>}</Translation>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="row mt-3">
                                                    <div className="col text-right">
                                                        <div className="btn-group mr-2">
                                                            <Link to="/accounting/finance/payment-gateway/list">
                                                                <Translation>
                                                                    {t =>
                                                                        <button type="button" className="btn btn-secondary add">
                                                                            {t("paymentgateway:Cancel")}
                                                                        </button>}
                                                                </Translation>
                                                            </Link>
                                                        </div>
                                                        <div className="btn-group mr-2">
                                                            {this.state.checkList.length > 0 ?
                                                                <Link to="/accounting/finance/payment-gateway/create/form">
                                                                    <Translation>
                                                                        {t =>
                                                                            <button type="button"
                                                                                className="btn btn-primary add">
                                                                                {t("paymentgateway:Create a depost")}
                                                                            </button>}
                                                                    </Translation>
                                                                </Link>
                                                                :
                                                                <Translation>
                                                                    {t =>
                                                                        <button type="button"
                                                                            className="btn btn-light disabled add">
                                                                            {t("paymentgateway:Create a depost")}
                                                                        </button>}
                                                                </Translation>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </React.Fragment>
                                    )}
                                return <Loading/>
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PaymentGatewayCreateSelectTransactionList;
