import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import RegulationList from './regulationList';
import CreateUpdateRegulation from './createUpdateRegulation';

class Regulation extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={RegulationList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:type?/:id?`} component={CreateUpdateRegulation}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Regulation;