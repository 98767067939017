/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UploadJobStatus = "DISABLE" | "ENABLE" | "VOID" | "%future added value";
export type uploadFileTableQueryVariables = {|
  search?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  status?: ?string,
  first?: ?number,
  last?: ?number,
  typeFile?: ?string,
  menuName?: ?string,
|};
export type uploadFileTableQueryResponse = {|
  +allUploadJob: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +issuedDate: ?any,
        +typeFile: ?string,
        +pathFile: ?string,
        +fileName: ?string,
        +fileExtension: ?string,
        +menuName: ?string,
        +sizeFile: ?string,
        +refTransaction: ?string,
        +status: UploadJobStatus,
        +creator: ?string,
        +timeCreate: any,
      |}
    |}>
  |}
|};
export type uploadFileTableQuery = {|
  variables: uploadFileTableQueryVariables,
  response: uploadFileTableQueryResponse,
|};
*/


/*
query uploadFileTableQuery(
  $search: String
  $startDate: Date
  $endDate: Date
  $status: String
  $first: Int
  $last: Int
  $typeFile: String
  $menuName: String
) {
  allUploadJob(search: $search, startDate: $startDate, endDate: $endDate, status: $status, first: $first, last: $last, typeFile: $typeFile, menuName: $menuName) {
    edges {
      node {
        issuedDate
        typeFile
        pathFile
        fileName
        fileExtension
        menuName
        sizeFile
        refTransaction
        status
        creator
        timeCreate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "menuName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeFile"
},
v8 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "menuName",
    "variableName": "menuName"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  },
  {
    "kind": "Variable",
    "name": "typeFile",
    "variableName": "typeFile"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeFile",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pathFile",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileExtension",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "menuName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sizeFile",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refTransaction",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeCreate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "uploadFileTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "UploadJobNodeConnection",
        "kind": "LinkedField",
        "name": "allUploadJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadJobNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UploadJobNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "uploadFileTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "UploadJobNodeConnection",
        "kind": "LinkedField",
        "name": "allUploadJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadJobNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UploadJobNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68dfc161cf3cbb41b5a2e427ffaa2997",
    "id": null,
    "metadata": {},
    "name": "uploadFileTableQuery",
    "operationKind": "query",
    "text": "query uploadFileTableQuery(\n  $search: String\n  $startDate: Date\n  $endDate: Date\n  $status: String\n  $first: Int\n  $last: Int\n  $typeFile: String\n  $menuName: String\n) {\n  allUploadJob(search: $search, startDate: $startDate, endDate: $endDate, status: $status, first: $first, last: $last, typeFile: $typeFile, menuName: $menuName) {\n    edges {\n      node {\n        issuedDate\n        typeFile\n        pathFile\n        fileName\n        fileExtension\n        menuName\n        sizeFile\n        refTransaction\n        status\n        creator\n        timeCreate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b891f979ab6a95f3a647b2a229c875c5';

module.exports = node;
