import React from "react";
import ReactExport from "react-data-export";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";

import environment from "../../env/environment";
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import {Translation} from "react-i18next";

const query = graphql`
  query exportChartQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const _ = require('lodash');

class ExportChartOfAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiDataSet: [],
            projectName: '',
        };
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
    }
    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            this.setState({
                projectName: res.selfProject.name,
            })
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.chartTree !== this.props.chartTree) {
            this.setData(this.props.chartTree)
        }
    }

    setData(chart_tree) {
        let data = [];

        data.push(
            [
                {value: "ผังบัญชี", width: {wpx: 100}, style: {font: {bold: true, size: 18}}},
                {value: "", width: {wpx: 300}, style: {font: {bold: true, size: 18}}},
                {value: "", width: {wpx: 100}, style: {font: {bold: true, size: 18}}},
            ],
            [
                {value: "เลขที่บัญชี", width: {wpx: 100}, style: {font: {bold: true, size: 18}}},
                {value: "ชื่อบัญชี", width: {wpx: 300}, style: {font: {bold: true, size: 18}}},
                {value: "หมวดหมู่", width: {wpx: 100}, style: {font: {bold: true, size: 18}}},
            ],
        )

        chart_tree.forEach((chart_of_account) => {

            data.push([chart_of_account.data.code, chart_of_account.data.name, chart_of_account.data.category]);
            chart_of_account.children.forEach((chart_of_account) => {
                data.push([chart_of_account.data.code, "-"+chart_of_account.data.name, chart_of_account.data.category]);

                if (_.get(chart_of_account, "children")) {
                    chart_of_account.children.forEach((chart_of_account) => {
                        data.push([chart_of_account.data.code, "--"+chart_of_account.data.name, chart_of_account.data.category]);

                        if (_.get(chart_of_account, "children")) {
                            chart_of_account.children.forEach((chart_of_account) => {
                                data.push([chart_of_account.data.code, "---" + chart_of_account.data.name, chart_of_account.data.category]);

                                if (_.get(chart_of_account, "children")) {
                                    chart_of_account.children.forEach((chart_of_account) => {
                                        data.push([chart_of_account.data.code, "----" + chart_of_account.data.name, chart_of_account.data.category]);

                                    })
                                }
                            })
                        }
                    })
                }

            })
        });

        let multiDataSet = [
            {
                columns: [
                    {title: this.state.projectName, width: {wpx: 100}, style: {font: {bold: true, size: 18}}},
                    {title: "", width: {wpx: 300}, style: {font: {bold: true, size: 18}}},
                    {title: "", width: {wpx: 100}, style: {font: {bold: true, size: 18}}},
                ],
                data
            },
        ];

        this.setState({multiDataSet})
    }

    buttonElement() {
        return (
            <Translation>
                 {t => (
                    <button type="button" className="btn print mr-2">
                        <span>
                        <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                        {t('chartofaccountSetting:Print')}
                        </span>
                    </button>
                 )} 
            </Translation>
        );
    }

    render() {
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"chart_of_account_" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}>
                <ExcelSheet
                    dataSet={this.state.multiDataSet}
                    name="ChartOfAccount"
                />
            </ExcelFile>
        )
    }
}

export default ExportChartOfAccount