/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialHistoryStatus = "LIVEING" | "QUIT" | "%future added value";
export type ResidentialHistoryType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type historyDetailQueryVariables = {|
  id: string,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  order?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type historyDetailQueryResponse = {|
  +allResidentialHistory: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +nameResidential: string,
        +residential: ?{|
          +id: string,
          +name: string,
        |},
        +firstName: ?string,
        +lastName: ?string,
        +countries: string,
        +phone: ?string,
        +email: ?string,
        +remark: ?string,
        +type: ResidentialHistoryType,
        +status: ResidentialHistoryStatus,
        +liveing: ?any,
        +quit: ?any,
        +added: any,
      |}
    |}>,
  |}
|};
export type historyDetailQuery = {|
  variables: historyDetailQueryVariables,
  response: historyDetailQueryResponse,
|};
*/


/*
query historyDetailQuery(
  $id: ID!
  $search: String
  $start_date: Date
  $end_date: Date
  $order: String
  $first: Int
  $last: Int
) {
  allResidentialHistory(residential_Id: $id, search: $search, startDate: $start_date, endDate: $end_date, order: $order, first: $first, last: $last, type_In: "resident,renter,agent") {
    totalCount
    edges {
      node {
        id
        nameResidential
        residential {
          id
          name
        }
        firstName
        lastName
        countries
        phone
        email
        remark
        type
        status
        liveing
        quit
        added
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "residential_Id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      },
      {
        "kind": "Literal",
        "name": "type_In",
        "value": "resident,renter,agent"
      }
    ],
    "concreteType": "ResidentialHistoryNodeConnection",
    "kind": "LinkedField",
    "name": "allResidentialHistory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialHistoryNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialHistoryNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameResidential",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialNode",
                "kind": "LinkedField",
                "name": "residential",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countries",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "liveing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "historyDetailQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "historyDetailQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "75a144e151639cd681500a8ac48790ee",
    "id": null,
    "metadata": {},
    "name": "historyDetailQuery",
    "operationKind": "query",
    "text": "query historyDetailQuery(\n  $id: ID!\n  $search: String\n  $start_date: Date\n  $end_date: Date\n  $order: String\n  $first: Int\n  $last: Int\n) {\n  allResidentialHistory(residential_Id: $id, search: $search, startDate: $start_date, endDate: $end_date, order: $order, first: $first, last: $last, type_In: \"resident,renter,agent\") {\n    totalCount\n    edges {\n      node {\n        id\n        nameResidential\n        residential {\n          id\n          name\n        }\n        firstName\n        lastName\n        countries\n        phone\n        email\n        remark\n        type\n        status\n        liveing\n        quit\n        added\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dbe2978c6fc76b6da8c6c7819fb8c504';

module.exports = node;
