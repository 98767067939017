/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type payableOutstandingSummaryReportQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
  search?: ?string,
  productAndService?: ?string,
  receiveIssuedDate?: ?any,
  contact?: ?string,
  rangeOverdueDate?: ?$ReadOnlyArray<?number>,
  overdueDate?: ?string,
|};
export type payableOutstandingSummaryReportQueryResponse = {|
  +allPurchaseRecordGroup: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +dueDate: ?any,
        +contact: {|
          +id: string,
          +name: string,
          +refNumber: string,
        |},
        +total: number,
        +purchaseRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +description: string,
              +total: number,
              +payRecord: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +amount: number,
                    +whtAmount: number,
                    +payRecordGroup: {|
                      +issuedDate: any
                    |},
                  |}
                |}>
              |},
              +acceptCreditNoteTransaction: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +total: number,
                    +issuedDate: any,
                  |}
                |}>
              |},
              +prepaidDepositTransactionUsed: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +amount: ?number
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type payableOutstandingSummaryReportQuery = {|
  variables: payableOutstandingSummaryReportQueryVariables,
  response: payableOutstandingSummaryReportQueryResponse,
|};
*/


/*
query payableOutstandingSummaryReportQuery(
  $startDate: DateTime
  $endDate: DateTime
  $search: String
  $productAndService: String
  $receiveIssuedDate: Date
  $contact: String
  $rangeOverdueDate: [Int]
  $overdueDate: String
) {
  allPurchaseRecordGroup(startDate: $startDate, endDate: $endDate, search: $search, productAndService: $productAndService, contact: $contact, order: "doc_number", payableOutstanding: true, status_In: "active, partial_payment, paid, waiting_pay", rangeOverdueDate: $rangeOverdueDate, overdueDate: $overdueDate) {
    edges {
      node {
        id
        docNumber
        issuedDate
        dueDate
        contact {
          id
          name
          refNumber
        }
        total
        purchaseRecord {
          edges {
            node {
              id
              description
              total
              payRecord(payRecordGroup_IssuedDate_Lte: $receiveIssuedDate, payRecordGroup_Status: "paid") {
                edges {
                  node {
                    amount
                    whtAmount
                    payRecordGroup {
                      issuedDate
                      id
                    }
                    id
                  }
                }
              }
              acceptCreditNoteTransaction(status: "paid") {
                edges {
                  node {
                    total
                    issuedDate
                    id
                  }
                }
              }
              prepaidDepositTransactionUsed {
                edges {
                  node {
                    amount
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "overdueDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productAndService"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rangeOverdueDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "receiveIssuedDate"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v8 = [
  {
    "kind": "Variable",
    "name": "contact",
    "variableName": "contact"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "doc_number"
  },
  {
    "kind": "Variable",
    "name": "overdueDate",
    "variableName": "overdueDate"
  },
  {
    "kind": "Literal",
    "name": "payableOutstanding",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "productAndService",
    "variableName": "productAndService"
  },
  {
    "kind": "Variable",
    "name": "rangeOverdueDate",
    "variableName": "rangeOverdueDate"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Literal",
    "name": "status_In",
    "value": "active, partial_payment, paid, waiting_pay"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v16 = [
  {
    "kind": "Variable",
    "name": "payRecordGroup_IssuedDate_Lte",
    "variableName": "receiveIssuedDate"
  },
  {
    "kind": "Literal",
    "name": "payRecordGroup_Status",
    "value": "paid"
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtAmount",
  "storageKey": null
},
v19 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "paid"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "payableOutstandingSummaryReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "PurchaseRecordGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseRecordGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PurchaseRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "purchaseRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PurchaseRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PurchaseRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v15/*: any*/),
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": (v16/*: any*/),
                                "concreteType": "PayRecordNodeConnection",
                                "kind": "LinkedField",
                                "name": "payRecord",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PayRecordNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PayRecordNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v17/*: any*/),
                                          (v18/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PayRecordGroupNode",
                                            "kind": "LinkedField",
                                            "name": "payRecordGroup",
                                            "plural": false,
                                            "selections": [
                                              (v11/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": (v19/*: any*/),
                                "concreteType": "AcceptCreditNoteNodeConnection",
                                "kind": "LinkedField",
                                "name": "acceptCreditNoteTransaction",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AcceptCreditNoteNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AcceptCreditNoteNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v14/*: any*/),
                                          (v11/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                "kind": "LinkedField",
                                "name": "prepaidDepositTransactionUsed",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PrepaidDepositTransactionUsedNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v17/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "payableOutstandingSummaryReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "PurchaseRecordGroupNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseRecordGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PurchaseRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "purchaseRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PurchaseRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PurchaseRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v15/*: any*/),
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": (v16/*: any*/),
                                "concreteType": "PayRecordNodeConnection",
                                "kind": "LinkedField",
                                "name": "payRecord",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PayRecordNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PayRecordNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v17/*: any*/),
                                          (v18/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PayRecordGroupNode",
                                            "kind": "LinkedField",
                                            "name": "payRecordGroup",
                                            "plural": false,
                                            "selections": [
                                              (v11/*: any*/),
                                              (v9/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v9/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": (v19/*: any*/),
                                "concreteType": "AcceptCreditNoteNodeConnection",
                                "kind": "LinkedField",
                                "name": "acceptCreditNoteTransaction",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AcceptCreditNoteNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AcceptCreditNoteNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v14/*: any*/),
                                          (v11/*: any*/),
                                          (v9/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                "kind": "LinkedField",
                                "name": "prepaidDepositTransactionUsed",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PrepaidDepositTransactionUsedNode",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v17/*: any*/),
                                          (v9/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31f0e41db5127e6fe93dcc54787ac70a",
    "id": null,
    "metadata": {},
    "name": "payableOutstandingSummaryReportQuery",
    "operationKind": "query",
    "text": "query payableOutstandingSummaryReportQuery(\n  $startDate: DateTime\n  $endDate: DateTime\n  $search: String\n  $productAndService: String\n  $receiveIssuedDate: Date\n  $contact: String\n  $rangeOverdueDate: [Int]\n  $overdueDate: String\n) {\n  allPurchaseRecordGroup(startDate: $startDate, endDate: $endDate, search: $search, productAndService: $productAndService, contact: $contact, order: \"doc_number\", payableOutstanding: true, status_In: \"active, partial_payment, paid, waiting_pay\", rangeOverdueDate: $rangeOverdueDate, overdueDate: $overdueDate) {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        dueDate\n        contact {\n          id\n          name\n          refNumber\n        }\n        total\n        purchaseRecord {\n          edges {\n            node {\n              id\n              description\n              total\n              payRecord(payRecordGroup_IssuedDate_Lte: $receiveIssuedDate, payRecordGroup_Status: \"paid\") {\n                edges {\n                  node {\n                    amount\n                    whtAmount\n                    payRecordGroup {\n                      issuedDate\n                      id\n                    }\n                    id\n                  }\n                }\n              }\n              acceptCreditNoteTransaction(status: \"paid\") {\n                edges {\n                  node {\n                    total\n                    issuedDate\n                    id\n                  }\n                }\n              }\n              prepaidDepositTransactionUsed {\n                edges {\n                  node {\n                    amount\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eba7a91b01973666548573582ca87fcb';

module.exports = node;
