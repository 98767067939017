import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import UnitStatusList from "./unitStatusList";
import TransferOfOwnershipCreate from './transferOfOwnershipCreate/transferOfOwnershipCreate';
import UnitStatusDetail from './unitStatusDetail'

class UnitStatus extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={UnitStatusList}/>
                <PrivateRoute exact path={`${this.props.match.url}/create/:status/:id?`} component={TransferOfOwnershipCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={UnitStatusDetail}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default UnitStatus;