import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import PettyCashList from './pettyCashList';
import PettyCashRecordForm from './pettyCashRecordForm';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import PettyCashPaymentForm from './pettyCashPaymentForm';
import PettyCashSetCreate from './pettyCashSetCreate';
import PettyCashBringForwardSetCreate from './pettyCashBringForwardSetCreate';
import PettyCashPaymentDetail from './pettyCashPaymentDetailTable';


class PettyCash extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:page`} component={PettyCashList}/>
                <PrivateRoute exact path={`${this.props.match.url}/create-record/:id?`} component={PettyCashRecordForm}/>
                <PrivateRoute exact path={`${this.props.match.url}/payment/create`} component={PettyCashPaymentForm}/>
                <PrivateRoute exact path={`${this.props.match.url}/set/from/:id?`} component={PettyCashSetCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/setBringForward/from/:id?`} component={PettyCashBringForwardSetCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/payment/detail/:id`} component={PettyCashPaymentDetail}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default PettyCash;