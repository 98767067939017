import "./App.scss";
import "./i18n";
import React, {Component} from 'react';
import { Router, Route, Switch } from "react-router-dom";

import PrivateRoute from './libs/privateRoute'

import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
// import { QueryRenderer } from "react-relay";
import environment from "./env/environment";
// import { Redirect } from 'react-router';

// import LoginOld from './login';
import Login from './login/indexLoginHook';
import ResetPassword from "./login/juristicResetPassword";
import Home from './home';
import AnnounceDashboard from './announce'
import TermOfUser from "./login/termOfUser";
import PrivacyAndPolicy from "./login/privacyAndPolicy";
import Accounting from './accounting';
import Contact from './contact';
import Register from './register';
import Purchasing from './purchasing';
import Project from './project';
import Document from './document';
import Setting from './setting';
import Stockandsupplies from './stockandsupplies'
import Gruad from './silvermangruad';
import NoMatch from './components/noMatch';
import history from './history';
import Developer from './developer';
import AddOn from './addon'
import Senddoc from './senddoc'
import scanToRepair from "./scanQRtoRepair/scanToRepair";
import meetingAndScore from "./meetingAndScore";
import pdpaAndManagementRoute from "./PDPA";
import AcceptForMarketing from "./login/acceptForMarketing";
import AcceptForPolicy from "./login/acceptForPolicy";
import AcceptForPartner from "./login/acceptForPartner";
import SilvermanAgent from "./silvermanAgent"
import LotteryAward from "./lottery"
import Feedback from './feedback/feedbackCreate'
import FeedbackList from './feedback'
import DownloadFile from "./downloadFile";
import UploadFileFile from "./uploadFile";
import SelectProject from "./login/selectProject";
import CheckSite from "./login/switchStie";
import VMS from "./vmsWebview"
import DataAnalytic from "./dataAnalytic/index"
import invoiceEmail from "./invoiceEmail/wrapperInvoiceEmail"
import collectionLetterEmail from "./collectionLetterEmail/wrapperCollectionLetterEmail"

const query = graphql`
        query AppQuery {
            checkUserActive
            checkUserSiteActive
        }
`;
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserActive : true
        }
    }

    queryCheckUserActive(){
        fetchQuery(environment, query).then(response => { 
          let setUserActive = false
            if(response.checkUserSiteActive){
                setUserActive = true;
            }
            this.setState({
                isUserActive : setUserActive
            })
         } 
    )}

    componentDidMount(){
        let token = localStorage.getItem('token');
        if(token){
            this.queryCheckUserActive()
        }
    }

    render() {
        if (!this.state.isUserActive) {
            return  window.location = '/login';
        }
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/changeSite" component={CheckSite} />
                    <Route path="/login" component={Login}/>
                    <Route path="/reset-password" component={ResetPassword}/>
                    <Route path="/privacy-and-policy" component={PrivacyAndPolicy} />
                    <Route path="/term-of-user" component={TermOfUser} />
                    <Route path="/accept-for-marketing" component={AcceptForMarketing} />
                    <Route path="/accept-for-policy" component={AcceptForPolicy} />
                    <Route path="/accept-for-partner" component={AcceptForPartner} />
                    <Route path="/silvermanagent" component={SilvermanAgent} />
                    <Route path="/lottery-award" component={LotteryAward} />
                    <Route path="/feedback-post" component={Feedback}/>
                    <Route path="/invoice-email/:id" component={invoiceEmail}/>
                    <Route path="/collection-email/:numberOfTimes/:id?" component={collectionLetterEmail}/>
                    <PrivateRoute path="/select-project" component={SelectProject}/>        
                    <Route path="/vms" component={VMS}/>
                    <PrivateRoute path="/" exact component=""/>
                    <PrivateRoute path="/home" component={Home}/>
                    <PrivateRoute path="/announce-dashboard" component={AnnounceDashboard}/>
                    <PrivateRoute path="/feedback" component={FeedbackList} />
                    <PrivateRoute path="/accounting" component={Accounting}/>
                    <PrivateRoute path="/stockandsupplies" component={Stockandsupplies}/>
                    <PrivateRoute path="/contact" component={Contact}/>
                    <PrivateRoute path="/silvermanguard" component={Gruad}/>
                    <PrivateRoute path="/purchasing" component={Purchasing}/>
                    <PrivateRoute path="/register" component={Register}/>
                    <PrivateRoute path="/project" component={Project}/>
                    <PrivateRoute path="/setting" component={Setting}/>
                    <PrivateRoute path="/developer" component={Developer}/>
                    <PrivateRoute path="/addon" component={AddOn}/>
                    <PrivateRoute path="/senddoc" component={Senddoc}/>
                    <PrivateRoute path="/download_file" component={DownloadFile}/>
                    <PrivateRoute path="/uploadfile" component={UploadFileFile}/>
                    <Route path="/document" component={Document}/>
                    <Route path="/scanToRepair/:QRID" component={scanToRepair}/>
                    <PrivateRoute path="/meetingAndScore" component={meetingAndScore}/>
                    <PrivateRoute path="/policyAndManagement" component={pdpaAndManagementRoute}/>
                    <PrivateRoute path="/dataAnalytic" component={DataAnalytic}/>

                    <Route component={NoMatch}/>
                </Switch>
            </Router>
        )
    }
}

export default App;
