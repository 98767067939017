import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import _ from "lodash"
import { Translation } from "react-i18next";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withRouter } from 'react-router-dom';
import './assetReportCheckin.scss'
import i18next from "i18next";
class AssetCheckingReportTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          showApproveHistory: {},
        };
      }
    
      toggleShowHistory(dataIndex, assetIndex) {
        this.setState(prevState => {
          const showApproveHistory = { ...prevState.showApproveHistory };
          const key = `${dataIndex}-${assetIndex}`;
          showApproveHistory[key] = !showApproveHistory[key];
          return { showApproveHistory };
        });
      }
    

    getAssetType(assetType) {
        switch(assetType) {
            case "LAND":
                return "LAN - ที่ดิน";
            case "BUILDING":
                return "B - อาคาร";
            case "BUILDING_RENOVATION":
                return "BR - ส่วนต่อเติมและปรับปรุงอาคาร";
            case "OFFICE_DECORATION":
                return "OD - เครื่องตกแต่งสำนักงาน";
            case "OFFICE_EQUIPMENT":
                return "EQ - อุปกรณ์และเครื่องใช้สำนักงาน";
            case "TOOLS":
                return "T - เครื่องมือ/อุปกรณ์งานช่าง";
            case "TRAINING_EQUIPMENT":
                return "TE - เครื่องออกกำลังกาย/สันทนาการ";
            case "VEHICLE":
                return "V - ยานพาหนะ";
            case "COMMON_PROPERTY":
                return "CP - ทรัพย์สินส่วนกลาง";
            case "OTHER_ASSET":
                return "OA - สินทรัพย์อื่นๆ";
            case "INTANGIBLE_ASSET":
                return "ITG - สินทรัพย์ไม่มีตัวตน";
        }
    }
    getApproveText(approve) {
        if(approve === 'APPROVE') {
            return <Translation>{t=> i18next.t("ReportAssetDetail:Verified")}</Translation>
        } else {
            return <Translation>{t=> i18next.t("ReportAssetDetail:Cancel Verification")}</Translation>
        }
    }

    // onClickGetData(data, asset_type, docNumber, id) {
    //     // const newPath = `/accounting/asset/${asset_type}/form/${docNumber}/${id}`;
    //     // const url = window.location.origin + newPath;
    //     // const newTab = window.open(url, '_blank');
    //     // if (newTab) {
    //     //   newTab.data = data;
    //     // }
    //     console.log(data)
    //     const newPath = `/accounting/asset/all/form/${docNumber}/${id}`;
    //     const url = window.location.origin + newPath;
    //     const newTab = window.open(url, '_blank');
    //     if (newTab) {
    //       newTab.addEventListener('load', () => {
    //         newTab.data = data;
    //         console.log(data);
    //       });
    //     }
    // //   }
    onClickGetData(data, asset_type, docNumber, id) {
        this.props.history.push(`/accounting/asset/all/form/${docNumber}/${id}`,
        { data: data, approveReport:'approveReport'}
        )

    }

    render() {
        let assetEachType = _.chain(this.props.props.allAsset.edges).groupBy('node.type').map((asset,index)=> {
            return ({  
                asset : asset,
            })
            }).value();
        return (
            <Translation>
                {t =>
                <React.Fragment>
                            <div className="table-responsive" id="assetCheckingTable">
                                <table className="table table-hover">
                                  <thead className="thead-light">
                                    <tr>
                                      <th className="text-center" rowSpan={2}></th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Sequence")}</th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Asset Code")}</th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Internal Asset Code")}</th>
                                      <th className="text-left" rowSpan={2}>{i18next.t("ReportAssetDetail:Asset Name")}</th>
                                      <th className="text-left" rowSpan={2}>{i18next.t("ReportAssetDetail:Description")}</th>
                                      <th className="text-left" rowSpan={2}>{i18next.t("ReportAssetDetail:Storage Location")}</th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Amount")}</th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Unit")}</th>
                                      <th className="text-center" rowSpan={2} width={150}>{i18next.t("ReportAssetDetail:Purchase Date")}</th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Asset Value (THB)")}</th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Depreciation Rate (%)")}</th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Images")}</th>
                                      <th className="text-center" rowSpan={2} width={150}>{i18next.t("ReportAssetDetail:Status")}</th>
                                      <th className="text-center" rowSpan={2}>{i18next.t("ReportAssetDetail:Verification Number")}</th>
                                      <th className="text-center" rowSpan={2} width={150}>{i18next.t("ReportAssetDetail:Verifier")}</th>
                                      <th className="text-center" rowSpan={2} width={150}>{i18next.t("ReportAssetDetail:Verification Date")}</th>
                                      <th className="text-center" rowSpan={2} width={200}>{i18next.t("ReportAssetDetail:Additional Comments")}</th>
                                      <th className="text-center" rowSpan={2}></th>
                                    </tr>
                                  </thead>
                                  
                                  <tbody>
                                    {assetEachType.map((data, dataIndex) => {
                                    return data.asset.map((asset, assetIndex) => {
                                        const key = `${dataIndex}-${assetIndex}`;
                                        const isExpanded = this.state.showApproveHistory[key];

                                        let originalApproveArray = asset.node.approveAsset.edges;
                                        let sortAprove = originalApproveArray.length > 0 ? originalApproveArray.slice().reverse() : '';

                                        // let sortAprove = originalApproveArray.length > 0 ? originalApproveArray : '';
                                        return (

                                        <React.Fragment key={key}>
                                            {assetIndex === 0 && (
                                                <tr className="table-active" key={`row-${dataIndex}-${assetIndex}`}>
                                                    <td colSpan={40} className="main">
                                                        {this.getAssetType(asset.node.type)}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td className="text-center"></td>
                                                <td className="text-center">{assetIndex+1}</td>
                                               
                                                <td className="text-center">
                                                    <a className="assetLink" 
                                                    target="_blank"
                                                    onClick={() => this.onClickGetData(asset, this.props.asset_type,  asset.node.docNumber, asset.node.id)} 
                                                    >
                                                    {asset.node.docNumber}
                                                    </a>     
                                                </td>
                                                {/* <td className="text-center">{asset.node.docNumber}</td> */}
                                                <td className="text-center">{asset.node.name.internalAssetCode}</td>
                                                <td className="text-left">{asset.node.chartOfAccountType.name}</td>
                                                <td className="text-left">{asset.node.description}</td>
                                                <td className="text-left">{asset.node.storage}</td>
                                                <td className="text-center">1</td>
                                                <td className="text-center">{asset.node.nounClassifier}</td>
                                                <td className="text-center">{format(asset.node.purchaseDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-right">{numberWithComma(asset.node.costPrice)}</td>
                                                <td className="text-center">{asset.node.depreciationRatePercent ? `${asset.node.depreciationRatePercent}%` : ''}</td>
                                                {asset.node.assetImages.edges.length > 0 ? 
                                                    <td className="text-center imageAssetContainer">
                                                        {asset.node.assetImages.edges.map((img, index) => {
                                                        if (index < 2) {
                                                            return (
                                                            <img key={index}
                                                                src={img.node.source}
                                                                alt="assets_preview"
                                                                className="imageAsset"
                                                                style={{ width: '128px', height: '50px' }}
                                                            />
                                                            );
                                                        }
                                                        })}
                                                    </td>
                                                    :
                                                    <td className="text-center"></td>
                                                }
                                                {sortAprove ?
                                                <td className="text-center">
                                                    <div className={sortAprove[0].node.approveStatus === 'APPROVE' ? 'status-available text-center ml-2': 'status-non text-center ml-2'}>
                                                        <span>{this.getApproveText(sortAprove[0].node.approveStatus)}</span>
                                                    </div>
                                                </td>
                                                :
                                                <td className="text-center">
                                                    <div className={'status-non text-center ml-2'}>
                                                        <span>{i18next.t("ReportAssetDetail:Not Verified")}</span>
                                                    </div>
                                                </td>
                                                }
                                                <td className="text-center">
                                                    {sortAprove ? 
                                                    sortAprove[0].node.approveTerm !== 0 ? sortAprove[0].node.approveTerm : ""
                                                    : 
                                                    ""}
                                                </td>
                                                <td className="text-center">{sortAprove? sortAprove[0].node.approver : ''}</td>
                                                <td className="text-center">{sortAprove? format(sortAprove[0].node.approveDate, 'DD/MM/YYYY') : ''}</td>
                                                <td className="text-center">{sortAprove? sortAprove[0].node.remark : ''}</td>
                                                {sortAprove.length > 1 ? 
                                                    <td className="text-center" onClick={() => this.toggleShowHistory(dataIndex, assetIndex)}>
                                                        {isExpanded ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )}
                                                    </td>
                                                :
                                                    <td className="text-center"></td>
                                                }
                                                
                                            </tr>        
                                            {sortAprove && isExpanded && (
                                            <React.Fragment>
                                                {sortAprove?.map((data, index) => {
                                                if (index > 0) {
                                                    return (
                                                    <tr key={index} className={`transition ${isExpanded ? 'expanded' : ''}`}>
                                                        <td className="text-center"></td>
                                                        <td className="text-center" colSpan={12}></td>
                                                        <td className="text-center">
                                                            <div className={`${data.node.approveStatus === "APPROVE" ? 'status-available' : 'status-non'} text-center ml-2`}>
                                                                <span>{i18next.t("ReportAssetDetail:"+this.getApproveText(data.node.approveStatus))}</span>
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            {data.node.approveTerm !== 0 ? data.node.approveTerm : ""}
                                                        </td>
                                                        <td className="text-center">{data.node.approver}</td>
                                                        <td className="text-center">{format(data.node.approveDate, 'DD/MM/YYYY')}</td>
                                                        <td className="text-center">{data.node.remark}</td>
                                                        <td className="text-center"></td>
                                                    </tr> 
                                                    );
                                                }
                                                })}
                                            </React.Fragment>
                                            )}
                                            {assetIndex === data.asset.length - 1 && (
                                                <tr className="blankRow">
                                                    <td colSpan={19}></td>
                                                </tr>
                                            )}      
                                        </React.Fragment>
                                        );
                                    });
                                    })}
                                  </tbody>
                                </table>
                              </div>
                </React.Fragment>
                }
            </Translation>
        )
    }
}

export default withRouter(AssetCheckingReportTable);