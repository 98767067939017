/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditProcurementFormInput = {|
  procurementFormId: string,
  creditorId?: ?string,
  buyDate?: ?any,
  status?: ?string,
  contractNumber?: ?string,
  contractDate?: ?any,
  paymentTerms?: ?string,
  otherPaymentTerms?: ?string,
  comparativeCompany?: ?number,
  lowestOffer?: ?number,
  highestOffer?: ?number,
  contractor?: ?string,
  contractorTel?: ?string,
  contractorEmail?: ?string,
  deliveryAddress?: ?string,
  deliveryDate?: ?any,
  reason?: ?string,
  feedback?: ?string,
  remark?: ?string,
  urgent?: ?boolean,
  total?: ?string,
  warrantyDay?: ?number,
  procurementFormContract?: ?any,
  procurementFormList?: ?any,
  procurementFormComparative?: ?any,
  procurementFormImage?: ?$ReadOnlyArray<?string>,
  chartOfAccountId?: ?string,
  startContractDate?: ?any,
  endContractDate?: ?any,
  contractPeriod?: ?string,
  purchasing?: ?string,
  productAndServiceId?: ?string,
  facilityUse?: ?string,
  clientMutationId?: ?string,
|};
export type updateFormManageRequestMutationVariables = {|
  input: EditProcurementFormInput
|};
export type updateFormManageRequestMutationResponse = {|
  +editProcurementForm: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type updateFormManageRequestMutation = {|
  variables: updateFormManageRequestMutationVariables,
  response: updateFormManageRequestMutationResponse,
|};
*/


/*
mutation updateFormManageRequestMutation(
  $input: EditProcurementFormInput!
) {
  editProcurementForm(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditProcurementFormPayload",
    "kind": "LinkedField",
    "name": "editProcurementForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateFormManageRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateFormManageRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "69f9ab96278b4e234d53a033c526c437",
    "id": null,
    "metadata": {},
    "name": "updateFormManageRequestMutation",
    "operationKind": "mutation",
    "text": "mutation updateFormManageRequestMutation(\n  $input: EditProcurementFormInput!\n) {\n  editProcurementForm(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'db18d54b707f3f4a1b2a42c0eaa6392c';

module.exports = node;
