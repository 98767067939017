import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import PettyCashPaymentCreate from "./pettyCashPaymentCreate"
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Loading from '../../../libs/loading';

const query = graphql`
    query pettyCashPaymentFormQuery {
       allSetPettyCash(status:"active"){
        totalCount
        edges{
          node{
            id
            docNumber
            description
            withdrawer
          }
        }
      }
    }
`;

class PettyCashPaymentForm extends Component {

    render() {
            return (
                <Wrapper>
                    <Header/>
                    <Sidebar/>
                    <WrapperContent disabledOverflowX={true}>
                        <AccountingTopMenuNavigation mini={true}/>
                        <div className="container-fluid box">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/accounting/expense/petty_cash/list/payment">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back"/>
                                        </Link>
                                        <Translation>
                                            {
                                                t =>
                                                    <span>{t('accounting:add_petty_cash_payment')}</span>
                                            }
                                        </Translation>
                                    </h3>
                                </div>
                            </div>
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                render={({error, props}) => {
                                    if (props) {
                                        return <Translation>{(t)=> <PettyCashPaymentCreate query={props}/>}</Translation>;
                                    } else {
                                        return <Loading/>
                                    }
                                }}
                            />
                        </div>
                    </WrapperContent>
                </Wrapper>
            )
    }
}

export default PettyCashPaymentForm;
