import React, { Component } from 'react'
import Sidebar from '../../components/sidebar'
import Wrapper from '../../components/wrapper'
import Header from '../../components/header/index'
import WrapperContent from '../../components/wrapper/wrapperContent'
import Link from 'react-router-dom/es/Link';
import { Translation } from 'react-i18next'
import './styles.scss'
import ReactDOM from 'react-dom'


class JsbView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            iFrameHeight: '736px',
            loading: true,
        }
    }
    componentDidMount() {
        const height = ReactDOM?.findDOMNode(this)
        let heifh = parseInt(height.clientHeight) < 500 ? parseInt(window.innerHeight) :  parseInt(height.clientHeight) 
        let ViewHeightAutoWindow = (heifh - 200) + "px"
        this.setState({ iFrameHeight: ViewHeightAutoWindow, loading: false })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    {
                        this.state.loading == false ?
                            <div className="container-fluid box" id='jsb'>
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/addon">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            <Translation>
                                                {
                                                    t => <span>{t("MenuName:Parking space release service")}</span>
                                                }
                                            </Translation>
                                        </h3>
                                    </div>
                                </div>
                                <div className='row d-flex justify-content-center'>
                                    <div className='col'>
                                        <div className='pt-4'>
                                            <iframe
                                                id='jsb_view'
                                                src="https://jordsabuy.com/jsb_silverman" width="100%" height={this.state.iFrameHeight} allowFullScreen referrerPolicy='same-origin' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="text-center" >
                                <img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/loading.gif'} />
                            </div>
                    }

                </WrapperContent>
            </Wrapper>
        )
    }
}

export default JsbView;