import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import OtherExpenseList from "./otherExpenseList";
import OtherExpenseCreate from "./otherExpenseCreate";
import OtherExpenseView from "./otherExpenseView";

class Purchase extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={OtherExpenseList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status`} component={OtherExpenseCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/view/:id/:ref_transaction`} component={OtherExpenseView}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Purchase;