import React from 'react';
import PrivateRoute from '../../libs/privateRoute';
import { Route, Switch } from "react-router-dom";
import SettingInvoiceTermsList from './settingInvoiceList'
import SettingInvoiceTermsCreate from './settingInvoiceCreate';
import NoMatch from "../../components/noMatch";

function SettingInvoiceTerms (props){
    return(
        <Switch>
            <PrivateRoute exact path={`${props.match.url}`} component={SettingInvoiceTermsList} />
            <PrivateRoute exact path={`${props.match.url}/list/:status`} component={SettingInvoiceTermsList} />
            <PrivateRoute exact path={`${props.match.url}/form`} component={SettingInvoiceTermsCreate} />
            <Route component={NoMatch} />
        </Switch>
    )
}

export default SettingInvoiceTerms