import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";

const query = graphql`
  query getAllSettingDebtFreeQuery{
    allSettingDebsFreeCertification {
        edges{
            node{
                id
                fee
                charge
                waiting
                lifetime
            }
        }
    } 
  }
`;

const getAllSettingDebtFreeQuery = () => {
  return new Promise((resolve, reject) => {
      fetchQuery(environment, query).then(data => {
          resolve(data)
      }).catch(err => {
        alert(err)
      });
  });

};

export default getAllSettingDebtFreeQuery;
