
import React from "react";
import "../../styles/commonArea.scss";
import iconBooking from "./iconBooking";
import TypeToBooking from "./2typeToBook";
import AllowToBooking from "./2allowBooking";
import TimeToPreLocation from "./2timeToPreLocation";
import SettingLimitTime from "./2settingLimitTime";
import { Translation } from "react-i18next";
const CommonAreaCreateStep2 = ({
    HandleOnChange,
    state,
    onClickNext,
    onClickPrev,
    onClickSelectBtn,
    onChangeTime,
    addRound,
    handleChange,
    subRound,
    create
}) => {
    return (
        <Translation>
            {t =>
                <React.Fragment>
                    <div className="claim">
                        <div className="create" style={{zoom : '80%'}}>
                            <label>
                                <p className="headerLabel18 fw-600 ml-2">01 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:General information")}</span></p>
                                <iconBooking.iconStep1 />
                            </label>
                            <label className="ml-2 mr-2">
                                <p className="headerLabel18 fw-600 ml-2">02 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:Application reservation setting")}</span></p>
                                <iconBooking.iconStep2 />
                            </label>
                            <label>
                                <p className="headerLabel18 fw-600 ml-2">03 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:Facility picture")}</span></p>
                                <iconBooking.iconStep1_3 />
                            </label>
                        </div>
                    </div>

                    <TypeToBooking state={state} HandleOnChange={HandleOnChange} onClickSelectBtn={onClickSelectBtn} onChangeTime={onChangeTime} addRound={addRound} handleChange={handleChange} subRound={subRound} create={create} />
                    <AllowToBooking state={state} HandleOnChange={HandleOnChange} onChangeTime={onChangeTime} onClickSelectBtn={onClickSelectBtn} />
                    <TimeToPreLocation state={state} HandleOnChange={HandleOnChange} onClickSelectBtn={onClickSelectBtn} />
                    <SettingLimitTime state={state} HandleOnChange={HandleOnChange} />


                    <Translation>
                        {
                            t =>
                                <div className="row claim mt-10">
                                    <div className="col-6">
                                        <button className="btn-primary-outline" type="button" onClick={onClickPrev}>
                                            {t("Allaction:back")}
                                        </button>
                                    </div>
                                    <div className="text-right col-6">
                                        <button className="btn-primary" type="submit">
                                            {t("Allaction:next")}
                                        </button>
                                    </div>
                                </div>
                        }
                    </Translation>
                </React.Fragment>
            }
        </Translation>
    );
};

export default CommonAreaCreateStep2;