import _ from "lodash"
import React, { useState } from "react"
import { Translation } from "react-i18next"

const AddressDataShow = (props) => {
    const [address] = useState(props.address)
    const [bangkokCode] = useState([10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
        10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
        10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
        10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
        10160, 10210, 10310, 10280]) 

    const isBangkok = (code) => {
        if (_.indexOf(bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    const getPrefix = (type, code) => {
        let languages = props.languages
        if (type === "district" && isBangkok(code)) {
            return languages == "th" ? "แขวง" : "District "
        }
        else if (type === "city" && isBangkok(code)) {
            return languages == "th" ? "เขต" : "County "
        }
        else if (type === "province" && isBangkok(code)) {
            return languages == "th" ? "" : ""
        }
        else if (type === "province") {
            return languages == "th" ? "จ." : "Province "
        }
        else if (type === "district") {
            return languages == "th" ? "ต." : "District "
        }
        else if (type === "city") {
            return languages == "th" ? "อ." : "County "
        }
    }

    const convertData = (data) => {
        let checkProvince = data?.province?.includes("กรุงเทพ") || data?.province?.includes("กทม")

        let checkFromContact = data?.registeredAddress ? true : false
        let district = checkProvince ? "แขวง" : "ตำบล"
        let city = checkProvince ? "เขต" : "อำเภอ"
        let province = checkProvince ? " " : "จังหวัด"

        let addresssData = data.address ? data.address : data?.registeredAddress
        if (props.languages && addresssData) {
            addresssData = addresssData?.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road')
        }

        let districtData = data?.district
        if (props.ProjectID === "UHJvamVjdE5vZGU6NDE2" && props.custom) {
            districtData = districtData && getPrefix("district", data?.postalCode) + districtData
        } else if (props.ProjectID === "UHJvamVjdE5vZGU6MzQz" && props.custom) {
            districtData = districtData && getPrefix("district", data?.postalCode) + districtData
        } else {
            districtData = `${district}${data?.district}`
        }

        return (
            <label>
                <span>{`${addresssData} `}</span>
                <span>{`${districtData} `}</span>
                <span>{`${city}${data?.city} `}</span>
                <span>{`${province}${data?.province} `}</span>
                <span>{`${data?.postalCode} `}</span>
                <span>{checkFromContact ? data?.registeredCountry : data?.country}</span>
            </label>
        )
    }

    return (
        <Translation>
            {t =>
                !props?.from ?
                    <label> {address} </label>
                    :
                    <>
                        {convertData(props.address)}
                    </>
            }
        </Translation >
    )
}

export default AddressDataShow