import './styles/index.scss';
import React, {Component} from 'react';
import {QueryRenderer} from 'react-relay';
import {graphql} from 'babel-plugin-relay/macro';
import environment from '../../env/environment';
import _ from "lodash";

const indexQuery = graphql`
    query wrapperQuery {
        userConfig {
            sidebarSize
        }
    }
`;

class Wrapper extends Component {

    render() {
        const {children} = this.props;
        return (
            <React.Fragment>
                <QueryRenderer
                    cacheConfig={{use_cache: true}}
                    environment={environment}
                    query={indexQuery}
                    render={({error, props}) => {
                        let menu_class = localStorage.getItem("sidebarSize") || "half";
                        if(props) {
                            menu_class = _.get(props, "userConfig.sidebarSize", "half").toLowerCase();
                            localStorage.setItem("sidebarSize", menu_class);
                        }
                        return (
                            <div id="wrapper" className={menu_class}>
                                {children}
                            </div>
                        )
                    }}/>
            </React.Fragment>
        );
    }
}

export default Wrapper;
