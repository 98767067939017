import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import ContactList from './contactList';
import ContactCreateUpdate from './contactCreateUpdate';
import ContactDetail from './contactDetail';
import NoMatch from "../../components/noMatch";
import {Switch, Route} from "react-router-dom";

class Contact extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/form/:page/:typeOfPayment/:id?`} component={ContactCreateUpdate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:page/:id`} component={ContactDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/:typeOfContact?/:typeOfPayment?`} component={ContactList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Contact;