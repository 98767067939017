/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ContactTypeOfPayment = "PAYABLE" | "RECEIVABLE" | "%future added value";
export type ContactTypeOfPeople = "GENERAL_PEOPLE" | "JURISTIC_PEOPLE" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type contactListQueryVariables = {|
  typeOfContact?: ?string,
  typeOfPayment?: ?string,
  first?: ?number,
  last?: ?number,
  search?: ?string,
|};
export type contactListQueryResponse = {|
  +contactViewer: ?{|
    +id: string,
    +allContact: ?{|
      +totalCount: ?number,
      +pageInfo: {|
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
        +startCursor: ?string,
        +endCursor: ?string,
      |},
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +taxIdNumber: ?string,
          +mainEmail: ?string,
          +email: ?string,
          +typeOfPayment: ContactTypeOfPayment,
          +typeOfContact: ContactTypeOfContact,
          +typeOfPeople: ?ContactTypeOfPeople,
          +typeOfSupplier: ContactTypeOfSupplier,
          +totalAmount: number,
          +nameBranch: string,
          +residential: ?{|
            +id: string,
            +name: string,
          |},
          +prefixName: ?string,
          +firstName: string,
          +lastName: string,
          +mainPhone: string,
          +phone: string,
          +isActive: boolean,
        |}
      |}>,
    |},
  |}
|};
export type contactListQuery = {|
  variables: contactListQueryVariables,
  response: contactListQueryResponse,
|};
*/


/*
query contactListQuery(
  $typeOfContact: String
  $typeOfPayment: String
  $first: Int
  $last: Int
  $search: String
) {
  contactViewer {
    id
    allContact(typeOfContact: $typeOfContact, typeOfPayment: $typeOfPayment, first: $first, last: $last, search: $search, order: "ref_number") {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          refNumber
          name
          taxIdNumber
          mainEmail
          email
          typeOfPayment
          typeOfContact
          typeOfPeople
          typeOfSupplier
          totalAmount
          nameBranch
          residential {
            id
            name
          }
          prefixName
          firstName
          lastName
          mainPhone
          phone
          isActive
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfContact"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfPayment"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactViewer",
    "kind": "LinkedField",
    "name": "contactViewer",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "last",
            "variableName": "last"
          },
          {
            "kind": "Literal",
            "name": "order",
            "value": "ref_number"
          },
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "search"
          },
          {
            "kind": "Variable",
            "name": "typeOfContact",
            "variableName": "typeOfContact"
          },
          {
            "kind": "Variable",
            "name": "typeOfPayment",
            "variableName": "typeOfPayment"
          }
        ],
        "concreteType": "ContactNodeConnection",
        "kind": "LinkedField",
        "name": "allContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxIdNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mainEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfPayment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfContact",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfPeople",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfSupplier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameBranch",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prefixName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mainPhone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "contactListQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "contactListQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "2e1e6a8dbc88556c62aec82027443b90",
    "id": null,
    "metadata": {},
    "name": "contactListQuery",
    "operationKind": "query",
    "text": "query contactListQuery(\n  $typeOfContact: String\n  $typeOfPayment: String\n  $first: Int\n  $last: Int\n  $search: String\n) {\n  contactViewer {\n    id\n    allContact(typeOfContact: $typeOfContact, typeOfPayment: $typeOfPayment, first: $first, last: $last, search: $search, order: \"ref_number\") {\n      totalCount\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        endCursor\n      }\n      edges {\n        node {\n          id\n          refNumber\n          name\n          taxIdNumber\n          mainEmail\n          email\n          typeOfPayment\n          typeOfContact\n          typeOfPeople\n          typeOfSupplier\n          totalAmount\n          nameBranch\n          residential {\n            id\n            name\n          }\n          prefixName\n          firstName\n          lastName\n          mainPhone\n          phone\n          isActive\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42edaa414b0263d9cc770d3517d6e757';

module.exports = node;
