import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import _ from "lodash"
import ClaimTopMenuNavigation from '../claim/claimTopMenuNavigation'
import i18next from 'i18next'

export default class createManageRequest extends Component {

  constructor(props){
    super(props)

    this.state = {
      purchaseRequisitionType: "product"
    }
  }

  componentDidMount(){
    if(this.state.purchaseRequisitionType !== this.props.location.query && this.props.location.query){
      this.setState({purchaseRequisitionType: this.props.location.query.type})
    }
  }

  changePurchaseRequisitionType = (e) => {
    let value = e.target.value

    this.setState({purchaseRequisitionType: value})
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <ClaimTopMenuNavigation mini={true} />

          <div className="container-fluid box" id="createManageRequest">
            <div className="row">
              <div className="col">
                <Translation>
                  {
                    t =>
                      <h4 className="mb-4">
                        <Link to="/purchasing/manageRequest/list/all">
                          <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                            alt="back" className="back" />
                        </Link>
                        {t("purchaseRequisition:Create a new purchase requisition")}
                      </h4>
                  }
                </Translation>
              </div>
            </div>

            <div className="row w-75 mx-auto" style={{ marginTop: 48 }}>
              <div className="col">
                <h4>{i18next.t("purchaseRequisition:Choose purchase requisition type")}</h4>

                <div className="row">
                  <div className="col-12">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" className="custom-control-input"
                        id="product" name="purchaseRequisitionType"
                        value="product"
                        checked={_.lowerCase(this.state.purchaseRequisitionType) === 'product'}
                        onChange={this.changePurchaseRequisitionType}
                      />
                      <Translation>
                        {t => <label className="custom-control-label textDetail"
                          htmlFor="product">{i18next.t("purchaseRequisition:Product")}</label>}
                      </Translation>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" className="custom-control-input"
                        id="service" name="purchaseRequisitionType"
                        value="service"
                        checked={_.lowerCase(this.state.purchaseRequisitionType) === 'service'}
                        onChange={this.changePurchaseRequisitionType}
                      />
                      <Translation>
                        {t => <label className="custom-control-label textDetail"
                          htmlFor="service">{i18next.t("purchaseRequisition:Contract Service / Expenses(General work)")}</label>}
                      </Translation>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" className="custom-control-input"
                        id="service_contact" name="purchaseRequisitionType"
                        value="service_contact"
                        checked={this.state.purchaseRequisitionType === 'service_contact'}
                        onChange={this.changePurchaseRequisitionType}
                      />
                      <Translation>
                        {t => <label className="custom-control-label textDetail"
                          htmlFor="service_contact">{i18next.t("purchaseRequisition:Contract Service / Expenses(Contract)")}</label>}
                      </Translation>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" className="custom-control-input"
                        id="asset" name="purchaseRequisitionType"
                        value="asset"
                        checked={_.lowerCase(this.state.purchaseRequisitionType) === 'asset'}
                        onChange={this.changePurchaseRequisitionType}
                      />
                      <Translation>
                        {t => <label className="custom-control-label textDetail"
                          htmlFor="asset">{i18next.t("purchaseRequisition:Asset")}</label>}
                      </Translation>
                    </div>
                  </div>

                  <div className="col-12" style={{ marginTop: 48 }}>
                    <Link to={`/purchasing/manageRequest/createManageRequest/formManageRequest/${this.state.purchaseRequisitionType}`}>
                      <button type="button" className="btn btn-create float-right mr-2">
                        <Translation>
                          {
                            t => <span>{i18next.t("purchaseRequisition:Next")}</span>
                          }
                        </Translation>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    )
  }
}
