import React from 'react';
import {QueryRenderer} from 'react-relay';
import environment from "../../../env/environment";
import {format} from "date-fns";
import ComponentPagination from "../../../libs/componentPagination";
import thLocale from "date-fns/locale/th";
import Pagination from "../../../libs/newPagination";
import {Link} from "react-router-dom";
import numberWithComma from "../../../libs/numberWithComma";
import { convertToTimeZone } from '../../../libs/date';
import { Translation } from 'react-i18next';
import Loading from "../../../libs/loading";

class PaymentGatewayListTable extends ComponentPagination {

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <QueryRenderer
                            environment={environment}
                            query={this.props.query}
                            variables={{first : this.state.first, last: this.state.last}}
                            render={({error, props}) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    return <React.Fragment>
                                        <div className="table-responsive fade-up card">
                                            <table className="table table-hover">
                                                <thead className="thead-light">
                                                <Translation>
                                                    {t=>
                                                    <tr>
                                                        <th>{t("cash_deposit:No.")}</th>
                                                        <th>{t("cash_deposit:Date")}</th>
                                                        <th>{t("cash_deposit:Description")}</th>
                                                        <th>{t("cash_deposit:Deposit bank")}</th>
                                                        <th className="text-right">{t("cash_deposit:Amount")}</th>
                                                        <th className="text-right">{t("cash_deposit:Bank fees")}</th>
                                                    </tr>}
                                                </Translation>
                                                </thead>
                                                <tbody>

                                                {props.allPaymentGatewayGroup.edges.map((payment_gateway) => (
                                                    <tr key={payment_gateway.node.id}>
                                                        <td>
                                                            { payment_gateway.node.status === 'VOID' ?
                                                                payment_gateway.node.docNumber
                                                            :
                                                                <Link to={"/accounting/finance/payment-gateway/detail/" + payment_gateway.node.id}
                                                                >{payment_gateway.node.docNumber}
                                                                </Link>
                                                            }
                                                        </td>
                                                        <td> {format(convertToTimeZone(payment_gateway.node.depositDate, props.selfProject.timeZone), 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                        <td>{payment_gateway.node.status === 'VOID' ? 'ยกเลิกรายการ' : payment_gateway.node.description}</td>
                                                        <td>{payment_gateway.node.status === 'VOID' ? '-' : payment_gateway.node.paymentGatewayTransaction.edges[0]?.node.bank.bankName} {payment_gateway.node.paymentGatewayTransaction.edges[0]?.node.bank.accountNumber}</td>
                                                        <td className="text-right">{numberWithComma(payment_gateway.node.depositTotal)}</td>
                                                        <td className="text-right">{numberWithComma(payment_gateway.node.feeTotal)}</td>
                                                    </tr>
                                                ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <Pagination changePage={this.changePage} first={this.state.first}
                                                        last={this.state.last}
                                                        totalCount={props.allPaymentGatewayGroup.totalCount}/>
                                        </div>
                                    </React.Fragment>
                                }
                                return <Loading/>
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PaymentGatewayListTable;
