import React from "react";
import {format} from "date-fns";
import ReactExport from "react-data-export";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import jwtDecode from 'jwt-decode'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const _ = require('lodash');


class ExportParkingFeeReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiDataSet: [],
            projectName: "",
        };
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
        this.setDataOR = this.setDataOR.bind(this)
        this.getMethodPayment = this.getMethodPayment.bind(this)
        this.getRTStatus = this.getRTStatus.bind(this)
        this.getDate = this.getDate.bind(this)
        this.getTotalPrice = this.getTotalPrice.bind(this)
        this.getPersonType = this.getPersonType.bind(this)
    }

    
    getMethodPayment(value){
        let result
        if(value === 'cash'){
            result = 'เงินสด'
        }else{
            result = 'QR Code'
        }
        return result
    }

    getTotalPrice(paymentDate,startDate){
        const {estampStatus,etampTimer,etampTimerHH,etampTimerMM,etampTimerVistor,etampTimerVistorHH,etampTimerVistorMM} = this.props.state.settingCalu
        let A = (this.getTime('hours',paymentDate,startDate) * 3600) + (this.getTime('',paymentDate,startDate) * 60)
        let B = estampStatus ? etampTimer ? (etampTimer * 3600) : (etampTimerHH * 3600 + etampTimerMM * 60) : etampTimerVistor ? (etampTimerVistor * 3600) : (etampTimerVistorHH * 3600 + etampTimerVistorMM * 60)
        let hours;
        let minutes;
        let price;

        if (Math.sign(A - B) === 1) {
             hours = Math.floor((A - B) / 3600); // get hours
             minutes = Math.floor(((A - B) - (hours * 3600)) / 60); // get minutes
             price = (hours * parseFloat(this.props.state.settingCalu.toPayCal) + (minutes >= this.props.state.settingCalu.toCalOverTime ? parseFloat(this.props.state.settingCalu.toPayCal) : 0))
             return numberWithComma(price || 0, "0.00")
           // setOverTime({ hours, minutes, price: (hours * parseFloat(toPayCal) + (minutes >= toCalOverTime ? parseFloat(toPayCal) : 0)) })
        } else {
            //setOverTime(false)
        }
    }

    getTime (type,paymentDate,startDate) {
        if (type === 'hours') {
            return parseInt(Math.abs(new Date(paymentDate) - new Date(startDate)) / (1000 * 60 * 60))
        } else {
           return parseInt(Math.abs(new Date(paymentDate).getTime() - new Date(startDate).getTime()) / (1000 * 60) % 60)
        }
    }

    getRTStatus(value){
        let result;
        if(value === 'request'){
            result = "ออกใบเสร็จ"
        }else if(value === 'finished'){
            result = "ออกใบเสร็จแล้ว"
        }else{
            result = 'ไม่มีการขอใบเสร็จ' 
        }
        return result
    }

    
    getPersonType(val){
        let result;
        switch (val) {
            case "tenant":
              result = "ผู้เช่า/ลูกบ้าน"
              break;
            case "contact":
              result = "ติดต่อสำนักงานขาย"
              break;
            case "appointment":
              result = "ผู้ติดต่อนัดหมายล่วงหน้า"
            case "person":
                result = "บุคคลทั่วไป"
              break;
          }
          return result;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.state.data !== this.props.state.data) {
            !this.props.state.isUseAccount ? this.setData(this.props.state?.data) : this.setDataOR(this.props.state?.data)
        }
    }

    componentDidMount() {
        !this.props.state.isUseAccount ? this.setData(this.props.state.data) : this.setDataOR(this.props.state.data)
    }    

    getDate(){
        return format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")
    }

    setDataOR(props) {
        let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
        let printBy = `${jwtDecodes.first_name} ${jwtDecodes.last_name}`
        let data = [];

        //title head
        data.push([
            {
                value: "เลขที่", 
                style: {
                    font: {bold: true} , 
                    alignment: {horizontal: "center"} , 
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่-เวลาที่ชำระค่าที่จอดรถ",
                style: {
                    font: {bold: true}, 
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "เลขทะเบียนรถ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "จำนวนเงิน (บาท)",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            // !this.props.state.isUseAccount ? 
            // {
            //     value: "ประเภทบุคคล",
            //     style: {
            //         font: {bold: true} ,
            //         alignment: {horizontal: "center"},
            //         border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
            //     }
            // }:{value: ""},
            {
                value: "รูปแบบการชำระเงิน",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            // !this.props.state.isUseAccount ? 
            // {
            //     value: "หลักฐานการชำระเงิน",
            //     style: {
            //         font: {bold: true} ,
            //         alignment: {horizontal: "center"},
            //         border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
            //     }
            // }:{value: ""},
            {
                value: "สถานะการขอใบเสร็จ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            this.props.state.isUseAccount ? 
            {
                value: "เลขที่รายรับอื่นๆ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            }:{value: ""},
            this.props.state.isUseAccount ? 
            {
                value: "วันที่ออก",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            }:{value: ""},
            this.props.state.isUseAccount ? 
            {
                value: "ออกโดย",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            }:{value: ""}
        ])

        //detail 
        props && props.map((item,index) => {
                data.push([
                    {
                        value: item.reportCode ? item.reportCode : "-",
                        style: {
                            alignment: {horizontal: "center"} ,
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.dateUsingSt ? item.dateUsingSt +"-"+ item.timeUsingSt :'-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.carRegistration ?  item.carRegistration : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: this.getTotalPrice(item?.paymentDate,item?.startDate) || 0,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    // !this.props.state.isUseAccount ? 
                    // {
                    //     value: item.typePerson ? this.getPersonType(item.typePerson) : "-" ,
                    //     style:{
                    //         border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                    //     }
                    // }:{value: ""},
                    {
                        value: item.methodPayment ? this.getMethodPayment(item.methodPayment) : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    // !this.props.state.isUseAccount ? 
                    // {
                    //     value: item.refPayment ? item.refPayment : "-" ,
                    //     style:{
                    //         border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                    //     }
                    // }:{value: ""},
                    {
                        value: item.rtStatus ? this.getRTStatus(item.rtStatus) : '-',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    this.props.state.isUseAccount ? 
                    {
                        value: item.orDocnumber ? item.orDocnumber :'-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }:{value: ""},
                    this.props.state.isUseAccount ? 
                    {
                        value: item.orIssuedDate ? item.orIssuedDate : '-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }:{value: ""},
                    this.props.state.isUseAccount ? 
                    {
                        value: item.orCreator ? item.orCreator : '-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }:{value: ""},
                ])
        })
     
        let multiDataSet = [
            {  
                columns: [
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 250}},
                    {title: "", width: {wpx: 100}},
                ],
                data
            },
            {
                columns: [                
                        {title: "", style: { font: { sz: "11.5" } }},
                ],    
                data: [
                    [
                        {
                            value: "ผู้พิมพ์ : "+printBy,
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ],
                    [   {
                            value: "วันที่พิมพ์ : " + this.getDate(),
                            style: {font: {sz: "11.5", bold: true}}
                        },
                        
                    ]
                ]
            },
        ];

        this.setState({multiDataSet})
    }

    setData(props) {
        let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
        let printBy = `${jwtDecodes.first_name} ${jwtDecodes.last_name}`
        let data = [];
        
        //title head
        data.push([
            {
                value: "เลขที่", 
                style: {
                    font: {bold: true} , 
                    alignment: {horizontal: "center"} , 
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่-เวลาที่ชำระค่าที่จอดรถ",
                style: {
                    font: {bold: true}, 
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "เลขทะเบียนรถ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "จำนวนเงิน (บาท)",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
 
            {
                value: "ประเภทบุคคล",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "รูปแบบการชำระเงิน",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "หลักฐานการชำระเงิน",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "สถานะการขอใบเสร็จ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            }
        ])

        //detail 
        props && props.map((item,index) => {
                data.push([
                    {
                        value: item.reportCode ? item.reportCode : "-",
                        style: {
                            alignment: {horizontal: "center"} ,
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.dateUsingSt ? item.dateUsingSt :'-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.carRegistration ?  item.carRegistration : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: this.getTotalPrice(item?.paymentDate,item?.startDate) || 0,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.typePerson ? this.getPersonType(item.typePerson) : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.methodPayment ? this.getMethodPayment(item.methodPayment) : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }, 
                    {
                        value: item.refPayment ? item.refPayment : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.rtStatus ? this.getRTStatus(item.rtStatus) : '-',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }
                ])
        })
     
        let multiDataSet = [
            {  
                columns: [
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 250}},
                    {title: "", width: {wpx: 100}},
                ],
                data
            },
            {
                columns: [                
                        {title: "", style: { font: { sz: "11.5" } }},
                ],    
                data: [
                    [
                        {
                            value: "ผู้พิมพ์ : "+printBy,
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ],
                    [   {
                            value: "วันที่พิมพ์ : " + this.getDate(),
                            style: {font: {sz: "11.5", bold: true}}
                        },
                        
                    ]
                ]
            },
        ];

        this.setState({multiDataSet})
    }

    buttonElement() {
        return (
            <a className="dropdown-item" target={"_blank"} >Excel</a>
      
            // <span style={{cursor:'pointer'}}>Excel </span>
            // <button type="button" className="btn" disabled={this.state.loading} style={{padding:'0'}}>
               
            //  this.state.loading ?
            // <span className="spinner-border spinner-border-sm align-middle mr-2"> {i18next.t("Allaction:Preparing information")} </span>
            //              :
                            
            //     } 
            // </button>
        );
    }

    render() {
  
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"parking_fee_" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}
                >
                <ExcelSheet
                    dataSet={this.state.multiDataSet}
                    name="parkingFee"
                />
            </ExcelFile>
        )
    }

}

export default ExportParkingFeeReport;
