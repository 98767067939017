/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type getSelfProjectQueryVariables = {||};
export type getSelfProjectQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +logo: ?string,
    +address: ?string,
    +useAccount: boolean,
    +typeOfProject: ?ProjectTypeOfProject,
  |}
|};
export type getSelfProjectQuery = {|
  variables: getSelfProjectQueryVariables,
  response: getSelfProjectQueryResponse,
|};
*/


/*
query getSelfProjectQuery {
  selfProject {
    id
    name
    logo
    address
    useAccount
    typeOfProject
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "useAccount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfProject",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getSelfProjectQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getSelfProjectQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5e95efcc6682f72007a87f0d3777bd2a",
    "id": null,
    "metadata": {},
    "name": "getSelfProjectQuery",
    "operationKind": "query",
    "text": "query getSelfProjectQuery {\n  selfProject {\n    id\n    name\n    logo\n    address\n    useAccount\n    typeOfProject\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b136829fc8ca180d56abb4af8e57de51';

module.exports = node;
