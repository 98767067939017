import React, { Component } from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import _ from "lodash"
import '../debtFreeRequest.scss'
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import { format } from 'date-fns';
import ContactInput from './contactInput';
import DebtFreeCertificationInput from './debtFreeCertificationInput';
import DocumentInput from './documentInput';
import PaymentInput from './paymentInput'
import invoiceViewerQuery from './invoiceViewerQuery'
import contactViewerQuery from './contactViewerQuery';
import upload from "../../../libs/upload"
import ApproveModal from './approveModal';
import { Redirect } from 'react-router';
import getNameResidential from '../../../libs/getNameResidential';
import i18next from 'i18next';
import BackButtonIcon from "../../../components/BackBtn/indexBack";

const query = graphql`
query debtFreeFormQuery($id: ID){
    allDebsFreeCertificationRequest(id:$id){
        edges{
            node{
                id
                docNumber
                added
                updated
                nameTransfer
                phoneTransfer
                contact{
                    id
                    name
                    firstName
                    lastName
                }
                requestingDate
                waitingDate
                approvalDate
                cancellationDate
                price
                tenant {
                    id 
                    firstName
                    lastName
                }
                phone
                addressContact
                address
                copyIdCard
                copyHouse
                copyApartment
                copySurnameChange
                otherDocument
                otherDocumentDescription
                attorney
                attorneyBook
                waterAndElectric
                startSuspend
                endSuspend
                transactionDebtType
                creatorApprove
                creatorVoid
                noteVoid
                receive{
                    id
                    docNumber
                }
                otherReceive{
                    id
                    docNumber
                }    
                status  
                fee
                charge
                debsFreeCertificationRequestDocument{
                    edges {
                        node {
                            id
                            fileUpload
                            nameFile
                        }
                    }
                }
                payment

            }
        }
    }
}`;

const mutation = graphql`
  mutation debtFreeFormMutation($input: CreateAndUpdateDebsFreeCertificationRequestInput!)  {
    createAndUpdateDebsFreeCertificationRequest (input: $input) {
        ok
        message   
    }
  }
`;


class DebtFreeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingData: false,
            contactList: [],
            added: new Date(),
            debsFreeCertificationRequestId: '',
            contactId: '',
            requestingDate: new Date(),
            waitingDate: null,
            approvalDate: null,
            cancellationDate: null,
            price: 0.0,
            phone: '',
            addressContact: 'pickup',
            address: '',
            copyIdCard: false,
            copyHouse: false,
            copyApartment: false,
            copySurnameChange: false,
            otherDocument: false,
            otherDocumentDescription: '',
            attorney: false,
            attorneyBook: '',
            waterAndElectric: false,
            startSuspend: null,
            endSuspend: null,
            transactionDebtType: '',
            receiveId: '',
            otherReceiveId: '',
            debsFreeCertificationRequestDocuments: [],
            updated: new Date(),
            nameContact: '',
            addressContactField: '',
            sizeContact: '',
            ownershipRatio: '',
            ownerList: [],
            ownerId: '',
            showAlert: true,
            allSettingDebsFreeCertification: null,
            alertInputPaymentReceive: false,
            fee: false,
            charge: 0,
            docNumber: '',
            docNumberDebtRequest: 'Create',
            creatorApprove: '',
            creatorVoid: '',
            noteVoid: '',
            noteEdit: '',
            statusTransaction: 'approve', //status หลังกดอนุมัติหรือไม่อนุมัติ
            disabledInput: false,
            redirect: false,
            statusDoc: '',
            phoneTransfer: '',
            nameTransfer: '',
            payment: null,
            tenantFirstName: '',
            tenantLastName: '',
            permissionEdit: _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertificationrequest_edit' }) ?
                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertificationrequest_edit' }) : false,
            editInput: false,
            contactNameId: ""
        }
    }


    async componentDidMount() {
        this.setState({ loadingData: true })
        let contact = await contactViewerQuery()
        if (contact.contactViewer.allContact && contact.contactViewer.allContact.edges.length > 0) {
            this.setState({
                contactList: contact.contactViewer.allContact.edges
            })
        }
        if (contact.allSettingDebsFreeCertification && contact.allSettingDebsFreeCertification.edges.length > 0) {
            this.setState({
                allSettingDebsFreeCertification: contact.allSettingDebsFreeCertification.edges[0],
                requestingDate: new Date().setDate(new Date(this.state.added).getDate() +
                    (contact.allSettingDebsFreeCertification.edges[0] ? contact.allSettingDebsFreeCertification.edges[0].node.waiting : 0))
            })
        }
        if (this.props.match.params.status !== 'create') {
            this.fetchQuery()
        } else {
            this.setState({ loadingData: false })
            if (this.state.receiveId === '' && this.state.otherReceiveId === '') {
                this.setState({
                    alertInputPaymentReceive: true
                })
            }
        }
        if (this.props.match.params.status === 'paid' || this.props.match.params.status === 'edit' || this.props.match.params.status === 'approve' || this.props.match.params.status === 'void') {
            this.setState({
                disabledInput: true,
                editInput: this.props.match.params.status === 'edit' ? true : false
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.alertInputPaymentReceive !== this.state.alertInputPaymentReceive) {
            this.setState({
                alertInputPaymentReceive: this.state.alertInputPaymentReceive
            })
        }
    }

    fetchQuery = () => {
        fetchQuery(environment, query, { id: this.props.match.params.id })
            .then((data) => {
                if (data) {
                    if (data.allDebsFreeCertificationRequest && data.allDebsFreeCertificationRequest.edges.length > 0) {
                        let dataDebs = data.allDebsFreeCertificationRequest.edges[0]
                        this.setState({
                            added: new Date(dataDebs.node.added),
                            debsFreeCertificationRequestId: dataDebs.node.id,
                            contactId: dataDebs.node.contact.id,
                            requestingDate: dataDebs.node.requestingDate === null ? null : new Date(dataDebs.node.requestingDate),
                            approvalDate: dataDebs.node.approvalDate === null ? null : new Date(dataDebs.node.approvalDate),
                            cancellationDate: dataDebs.node.cancellationDate === null ? null : new Date(dataDebs.node.cancellationDate),
                            statusDoc: dataDebs.node.status,
                            price: dataDebs.node.price,
                            name: dataDebs.node.name,
                            phone: dataDebs.node.phone,
                            addressContact: _.lowerCase(dataDebs.node.addressContact),
                            address: dataDebs.node.address,
                            copyIdCard: dataDebs.node.copyIdCard,
                            copyHouse: dataDebs.node.copyHouse,
                            copyApartment: dataDebs.node.copyApartment,
                            copySurnameChange: dataDebs.node.copySurnameChange,
                            otherDocument: dataDebs.node.otherDocument,
                            otherDocumentDescription: dataDebs.node.otherDocumentDescription,
                            attorney: dataDebs.node.attorney,
                            attorneyBook: dataDebs.node.attorneyBook,
                            waterAndElectric: dataDebs.node.waterAndElectric,
                            startSuspend: dataDebs.node.startSuspend !== null ? new Date(dataDebs.node.startSuspend) : null,
                            endSuspend: dataDebs.node.endSuspend !== null ? new Date(dataDebs.node.endSuspend) : null,
                            transactionDebtType: dataDebs.node.transactionDebtType,
                            receiveId: dataDebs.node.receive === null ? '' : dataDebs.node.receive.id,
                            otherReceiveId: dataDebs.node.otherReceive === null ? '' : dataDebs.node.otherReceive.id,
                            fee: dataDebs.node.fee,
                            charge: dataDebs.node.charge,
                            debsFreeCertificationRequestDocuments: dataDebs.node.debsFreeCertificationRequestDocument.edges,
                            ownerId: dataDebs.node.tenant.id,
                            docNumber: dataDebs.node.receive === null ? dataDebs.node.otherReceive === null ? '' : dataDebs.node.otherReceive.docNumber : dataDebs.node.receive.docNumber,
                            updated: dataDebs.node.updated !== null ? new Date(dataDebs.node.updated) : null,
                            docNumberDebtRequest: this.props.match.params.status === 'create' ? "Create" : dataDebs.node.docNumber,
                            creatorApprove: dataDebs.node.creatorApprove,
                            creatorVoid: dataDebs.node.creatorVoid,
                            noteVoid: dataDebs.node.noteVoid,
                            nameTransfer: dataDebs.node.nameTransfer,
                            phoneTransfer: dataDebs.node.phoneTransfer,
                            payment: dataDebs.node.payment,
                            tenantFirstName: dataDebs.node.tenant.firstName,
                            tenantLastName: dataDebs.node.tenant.lastName
                        }, () => {
                            this.setContact()
                            if (this.state.receiveId === '' && this.state.otherReceiveId === '') {
                                this.setState({
                                    alertInputPaymentReceive: true
                                })
                            }

                        })
                    } else {
                        this.setState({ loadingData: false })
                    }
                } else {
                    this.setState({ loadingData: false })
                }
            })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/debtFree/data" + path, file);
            resolve(res);
        });
    };

    updateNote = (typeCancel, note) => {
        if (typeCancel === 'approve') {
            this.setState({
                statusTransaction: 'approve'
            }, () => {
                this.onSubmit()
            })
        } else {
            this.setState({
                [typeCancel]: note,
                statusTransaction: typeCancel === 'noteVoid' ? 'void' : 'edit'
            }, () => {
                this.onSubmit()
            })
        }
    }

    updateVoid = () => {
        this.setState({
            noteVoid: 'ระงับ / แจ้งยกเลิกการขอใบปลอดหนี้',
            statusTransaction: "void"
        }, () => {
            this.onSubmit()
        })
    }


    onSubmit = async () => {
        this.setState({ loading: true })
        let status = 'paid'
        //status => paid = รออนุมัติ , active = รอนิติเปิดใบเสร็จ , edit = ติกลับ , void = ยกเลิก
        if (this.props.match.params.status === 'create' || this.props.match.params.status === 'edit') {
            status = 'paid'
        } else if (this.props.match.params.status === 'paid') {
            status = this.state.statusTransaction
        } else if (this.props.match.params.status === 'active') {
            status = 'paid'
        }
        let debsFreeCertificationRequestDocuments = []
        if (this.state.debsFreeCertificationRequestDocuments.length > 0) {
            for (const file of this.state.debsFreeCertificationRequestDocuments) {
                if (file.node.id === "") {
                    await this.callUpload('', file.node.fileUpload).then((data) => {
                        debsFreeCertificationRequestDocuments.push({
                            debsFreeCertificationRequestDocumentsId: "",
                            fileUpload: data.key.replace("silverman-storage/private/", ""),
                            status: "",
                            nameFile: file.node.nameFile
                        })
                    });
                }
            }
        }
        let variables = {
            input: {
                debsFreeCertificationRequest: {
                    debsFreeCertificationRequestId: this.state.debsFreeCertificationRequestId,
                    added: this.state.added === null ? this.state.added : format(this.state.added, 'YYYY-MM-DDT00:00:00'),
                    contactId: this.state.contactId,
                    requestingDate: this.state.requestingDate === null ? this.state.requestingDate : format(this.state.requestingDate, 'YYYY-MM-DDT00:00:00'),
                    approvalDate: (this.props.match.params.status === 'paid' && this.state.statusTransaction === 'approve') ? format(new Date(), 'YYYY-MM-DDT00:00:00') : null,
                    cancellationDate: (this.props.match.params.status === 'paid' && this.state.statusTransaction === 'void') ? format(new Date(), 'YYYY-MM-DDT00:00:00') : null,
                    editDate: (this.props.match.params.status === 'paid' && this.state.statusTransaction === 'edit') ? format(new Date(), 'YYYY-MM-DDT00:00:00') : null,
                    price: this.state.price,
                    tenantId: this.state.ownerId,
                    phone: this.state.phone,
                    addressContact: _.lowerCase(this.state.addressContact),
                    address: this.state.address,
                    copyIdCard: this.state.copyIdCard,
                    copyHouse: this.state.copyHouse,
                    copyApartment: this.state.copyApartment,
                    copySurnameChange: this.state.copySurnameChange,
                    otherDocument: this.state.otherDocument,
                    otherDocumentDescription: this.state.otherDocumentDescription,
                    attorney: this.state.attorney,
                    attorneyBook: (this.state.attorneyBook === 'DOCUMENT_AND_ID_CARD' || this.state.attorneyBook === 'document_and_id_card') ? 'document_and_id_card' : _.lowerCase(this.state.attorneyBook).replace(' ', '_'),
                    waterAndElectric: this.state.waterAndElectric,
                    startSuspend: this.state.waterAndElectric && this.state.startSuspend ? format(this.state.startSuspend, 'YYYY-MM-DDT00:00:00') : null,
                    endSuspend: this.state.waterAndElectric && this.state.endSuspend ? format(this.state.endSuspend, 'YYYY-MM-DDT00:00:00') : null,
                    transactionDebtType: this.state.transactionDebtType,
                    receiveId: this.state.receiveId,
                    otherReceiveId: this.state.otherReceiveId,
                    status: status, // สถานะแก้ไข เมื่อกด ยกเลิก ต้องเป็น Void ไม่ใช่ Piad
                    debsFreeCertificationRequestDocuments: debsFreeCertificationRequestDocuments,
                    noteEdit: this.state.statusTransaction === 'edit' ? this.state.noteEdit : '',
                    noteVoid: this.state.statusTransaction === 'void' ? this.state.noteVoid : '',
                    phoneTransfer: this.state.phoneTransfer,
                    nameTransfer: this.state.nameTransfer,
                    notiStatus: (this.state.statusTransaction === 'edit' || this.state.statusTransaction === 'void' || this.props.match.params.status === 'paid') ? true : false
                }
            }
        }

        if (variables.input.debsFreeCertificationRequest.tenantId === '' || variables.input.debsFreeCertificationRequest.phone === '' || variables.input.debsFreeCertificationRequest.nameTransfer === '' || variables.input.debsFreeCertificationRequest.phoneTransfer === '') {
            Swal.fire(i18next.t('Allaction:Saved Unsuccessful'), i18next.t("parcel_list:Please complete the information!"), 'warning').then(() => {
                this.setState({ loading: false });
            })
        } else {
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        this.setState({ loading: false });
                        if (response.createAndUpdateDebsFreeCertificationRequest && response.createAndUpdateDebsFreeCertificationRequest?.ok) {
                            Swal.fire(i18next.t('Allaction:successcompleate'), '', 'success').then(() => {
                                this.setState({ redirect: true });
                            });
                        } else {
                            Swal.fire(i18next.t('Allaction:Saved Unsuccessful'), i18next.t("Allaction:Please check again"), 'warning').then(() => {
                                this.setState({ loading: false });
                            })
                        }
                    },
                    onError: (err) => {
                        this.setState({ loading: false });
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                    },
                },
            )
        }

    }

    handleChange = (event) => {
        let e = _.cloneDeep(event)
        if (event.target.name === 'contactId') {
            this.setState({
                contactId: e.target.value,
                ownerId: ''
            }, () => {
                this.setContact()
            })
        } else if (event.target.name === 'ownerId') {
            this.setState({
                ownerId: e.target.value
            })

        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }


    isBangkok = (code) => {
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];
        if (_.indexOf(bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix = (type, code) => {
        if (type === "subdistrict" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "district" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "subdistrict") {
            return "ต."
        } else if (type === "district") {
            return "อ."
        } else if (type === "lane") {
            return "ถ."
        } else if (type === "soi") {
            return "ซ."
        }
    }

    setContact = async () => {
        let contact = _.find(this.state.contactList, { node: { id: this.state.contactId } });
        let name = ''
        let size = ''
        let ownershipRatio = ''
        let address = ''
        if (contact) {
            name = getNameResidential(contact.node.firstName, contact.node.lastName);
            size = contact.node.residential.size;
            ownershipRatio = contact.node.residential.ownershipRatio;
            address = (contact.node.residential.homeNumber || "-") + " " +
                (contact.node.residential.villageBuilding ? contact.node.residential.villageBuilding + " " : ' ') +
                (contact.node.residential.villageNumber ? "หมู่ที่ " + contact.node.residential.villageNumber + " " : ' ') +
                (contact.node.residential.soi ? this.getPrefix("soi") + contact.node.residential.soi + " " : ' ') +
                (contact.node.residential.lane ? this.getPrefix("lane") + contact.node.residential.lane + " " : ' ') +
                (contact.node.residential.subDistrict ? this.getPrefix("subdistrict", contact.node.residential.postalCode) + contact.node.residential.subDistrict + " " : ' ') +
                (contact.node.residential.district ? this.getPrefix("district", contact.node.residential.postalCode) + contact.node.residential.district + " " : ' ') +
                (contact.node.residential.province ? this.getPrefix("province", contact.node.residential.postalCode) + contact.node.residential.province + " " : ' ') +
                (contact.node.residential.postalCode ? contact.node.residential.postalCode : '')

            let ownerList = []
            ownerList = _.filter(contact.node.residential.residentialHouseholder.edges, { node: { type: 'OWNER' } })
            let tenantList = []
            _.forEach(ownerList, list => {
                tenantList.push({ node: { id: list.node.tenant.id, firstName: list.node.tenant.firstName, lastName: list.node.tenant.lastName, fullName: list.node.tenant.firstName + " " + list.node.tenant.lastName } })
            })
            this.setState({
                ownerList: tenantList
            })
        }

        this.setState({
            nameContact: name,
            addressContactField: address,
            sizeContact: size,
            ownershipRatio: ownershipRatio,
        })
        let listInvoice = []
        listInvoice = await invoiceViewerQuery([this.state.contactId])
        let sum = 0;
        if (listInvoice.invoiceViewer.allInvoiceByStatus.edges.length > 0) {
            let sumTransaction = 0
            _.forEach(_.filter(listInvoice.invoiceViewer.allInvoiceByStatus.edges, invoice => {
                return new Date(invoice.node.issuedDate) <= new Date()
            }), (invoice) => {
                sumTransaction = _.sumBy(invoice.node.transaction.edges, 'node.total') - _.sumBy(invoice.node.transaction.edges, 'node.paid')
                sum += sumTransaction
            })
        }
        if (this.state.statusDoc === 'ACTIVE' || this.state.statusDoc === '' || this.state.statusDoc === 'PAID' || this.state.statusDoc === 'EDIT') {
            this.setState({
                price: sum
            })
        }

        this.setState({ loadingData: false })
    }

    toggleSwitch = (event) => {
        let e = _.cloneDeep(event)
        this.setState({
            [e.target.name]: e.target.id
        })
    }

    handleValueByName = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleInputFile = (e) => {
        let file = e.currentTarget.files[0];
        let defaultFile = _.cloneDeep(this.state.debsFreeCertificationRequestDocuments)
        if (file) {
            let totalBytes = file.size;
            // check gt 5 mb 5000000 from 10 mb RQ147
            if (totalBytes > 10485760) {
                Swal.fire('ขนาดไฟล์ใหญ่เกินไป!', 'กรุณาเลือกไฟล์ใหม่', 'warning');
                return
            } else {
                defaultFile.push(
                    {
                        node: {
                            id: "",
                            fileUpload: file,
                            status: "",
                            nameFile: file.name
                        }
                    })
                this.setState({
                    debsFreeCertificationRequestDocuments: defaultFile
                })
            }
        }
    }

    deleteFile = (id) => {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data") + "?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(this.state.debsFreeCertificationRequestDocuments)
                let index_list = _.findIndex(_.cloneDeep(getData), { node: { debsFreeCertificationRequestDocumentsId: id } })
                if (index_list === -1) {
                    getData.splice(id, 1)
                    this.setState({
                        debsFreeCertificationRequestDocuments: getData
                    })
                }
                else {
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            'debsFreeCertificationRequestDocuments['+index_list+'].node.status',
                            'delete'
                        );
                    });
                }
            }
        })
    }

    checkAlertInputPaymentReceive = (value) => {
        this.setState({
            alertInputPaymentReceive: value
        })
    }

    render() {
        if (this.state.redirect) {
            let path = (this.props.match.params.status === 'create' || this.props.match.params.status === 'edit' || this.props.match.params.status === 'active') ?
                '/accounting/debt_free_request/list/paid'
                :
                '/accounting/debt_free_request/list/' + this.state.statusTransaction
            return <Redirect to={{ pathname: path }} />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {(t) =>
                            <div className="container-fluid box" id="debt-free-request">
                                <BackButtonIcon
                                    LinkBack={this.props.match.params.status === 'create' ? "/accounting/debt_free_request/list/all" : "/accounting/debt_free_request/list/" + this.props.match.params.status}
                                    LinkText={t("debt_free_request:" + this.state.docNumberDebtRequest)}
                                />
                                <div className="content-inner form-request">
                                    {this.state.loadingData ?
                                        <span><span className="spinner-border spinner-border-sm align-middle mr-2" /> {i18next.t("Allaction:Preparing information")}</span>
                                        :
                                        <form id="form-debtFree">
                                            {(this.props.match.params.status === 'edit' && this.state.showAlert) &&
                                                <div className="row col-12 mb-3 d-flex justify-content-center">
                                                    <div className=" alert-tab tab-yellow form-inline">
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/alert-yellow.png'} alt="alert" className="mr-3" />
                                                        <span>{i18next.t("debt_free_request:This request is currently pending for data correction by the requester")}</span>
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/close-yellow.png'} alt="alert" className="close-icon cursor"
                                                            onClick={() => this.setState({ showAlert: false })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {(this.props.match.params.status === 'approve' && this.state.showAlert) &&
                                                <div className="row col-12 mb-3 d-flex justify-content-center">
                                                    <div className=" alert-tab tab-green form-inline">
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/success-icon.png'} alt="alert" className="mr-3" />
                                                        <span>{i18next.t("debt_free_request:Approved Date")} {format(this.state.approvalDate, 'DD/MM/YYYY')}{i18next.t("debt_free_request:By")} {this.state.creatorApprove}</span>
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/close-green.png'} alt="alert" className="close-icon cursor"
                                                            onClick={() => this.setState({ showAlert: false })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {(this.props.match.params.status === 'void' && this.state.showAlert) &&
                                                <div className="row col-12 mb-3 d-flex justify-content-center">
                                                    <div className=" alert-tab tab-red form-inline">
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/fail-icon.png'} alt="alert" className="mr-3" />
                                                        <span>{i18next.t("debt_free_request:Canceled")} {i18next.t("debt_free_request:When")} {format(this.state.cancellationDate, 'DD/MM/YYYY')} {i18next.t("debt_free_request:By")} {this.state.creatorVoid} {i18next.t("debt_free_request:Reason")}: {this.state.noteVoid}</span>
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/close-red.png'} alt="alert" className="close-icon cursor"
                                                            onClick={() => this.setState({ showAlert: false })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <div className="row pl-3 mb-3 ">
                                                <div className={this.props.match.params.status === 'create' ? 'col-12' : 'col-8'}>
                                                    <h6>{i18next.t("debt_free_request:Request Creation Date")}</h6>
                                                </div>
                                                {this.props.match.params.status !== 'create' &&
                                                    <div className="col-4">
                                                        <h6>{i18next.t("debt_free_request:Last Modified Date")}: {format(this.state.updated, 'DD/MM/YYYY')}</h6>
                                                    </div>

                                                }
                                                <div className="col-12">
                                                    <input type="text" value={format(this.state.added, 'DD/MM/YYYY')} disabled={true} className="form-control form-control-border col-2" />
                                                </div>
                                            </div>
                                            <ContactInput
                                                handleChange={this.handleChange}
                                                state={this.state}
                                                status={this.props.match.params.status}
                                            />
                                            <DebtFreeCertificationInput
                                                handleChange={this.handleChange}
                                                state={this.state}
                                                toggleSwitch={this.toggleSwitch}
                                                disabledInput={this.state.disabledInput}
                                                status={this.props.match.params.status}
                                            />
                                            <DocumentInput
                                                handleChange={this.handleChange}
                                                state={this.state}
                                                disabledInput={this.state.disabledInput}
                                                handleValueByName={this.handleValueByName}
                                                handleInputFile={this.handleInputFile}
                                                deleteFile={this.deleteFile}
                                                status={this.props.match.params.status}
                                            />
                                            {(this.props.match.params.status === 'create' ? this.state.allSettingDebsFreeCertification?.node.fee : this.state.fee) &&
                                                <PaymentInput state={this.state}
                                                    handleChange={this.handleChange} handleValueByName={this.handleValueByName}
                                                    checkAlertInputPaymentReceive={this.checkAlertInputPaymentReceive}
                                                    disabledInput={this.state.disabledInput}
                                                    status={this.props.match.params.status}
                                                    charge={this.props.match.params.status === 'create' ? this.state.allSettingDebsFreeCertification?.node.charge : this.state.charge}
                                                />
                                            }
                                            {(!this.state.disabledInput || this.state.editInput) &&
                                                <div className="row" id="setting-fee">
                                                    <div className="col-12 d-flex justify-content-end p-4 colorUse">
                                                        {/* <button type="submit" className="btn-print mr-2" disabled={this.state.alertInputPaymentReceive}>
                                                            {this.state.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2"/>}
                                                            บันทึกและพิมพ์ 
                                                        </button> */}

                                                        {
                                                            this.state.editInput &&
                                                            <React.Fragment>
                                                                <button className='btn bg-D9D9D9 text_808080 h48 mr-3'>{i18next.t("Allaction:Cancel")}</button>
                                                                <button type='button' className='btn bg_E14558 text_FFFFFF h48 mr-3' onClick={this.updateVoid} >{i18next.t("Allaction:Void")}</button>

                                                            </React.Fragment>
                                                        }
                                                        <ApproveModal props={this.state} onSubmit={this.onSubmit} submit={true} updateNote={this.updateNote} />
                                                    </div>
                                                    <div className="col-1" />
                                                </div>
                                            }

                                            {this.props.match.params.status === 'paid' &&
                                                <div className="row" id="setting-fee">
                                                    <div className="col-12 d-flex justify-content-end p-4">
                                                        <ApproveModal props={this.state} onSubmit={this.onSubmit} updateNote={this.updateNote} />
                                                    </div>
                                                    <div className="col-1" />
                                                </div>
                                            }
                                        </form>

                                    }

                                </div>
                            </div>
                        }
                    </Translation>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default DebtFreeForm;
