import React from "react";
import getApiRoot from "../../../../libs/getAPIRoot";
import axios from "axios";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import i18n from "i18next";
import { format } from "date-fns";

class ExportInvoicePdf extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            percent_loading: 0,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading && this.state.percent_loading >= 100) {
            this.setState({
                loading: false,
                percent_loading: 0
            }, () => Swal.fire(
                    {
                        title: i18n.t('invoice:Once the process is complete, the document will be in the downloadable documents section.'),
                        html: `<a href="/download_file/all"> Click </a>`,
                        type: "success",
                        showConfirmButton: false,
                    }
                )
            )
        }
    }

    async processInvoices(data) {
        const header = {
          authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        };
    
        for (const [index, [keys, download_job_record]] of Object.entries(data).entries()) {
          try {
            await axios.post(`${getApiRoot()}account/invoice-print-all`, { download_job_record_id: download_job_record,}, {
              headers: header,
            });
    
            // console.log("Response", response);
    
            this.setState({
              percent_loading: parseInt(((index + 1) / Object.entries(data).length) * 100),
            });
          } catch (error) {
            console.error("Error", error);
          }
        }
      }

    handleSubmit = (event) => {

        Swal.fire({
            title: i18n.t('invoice:confirm all prints. (excluding canceled invoices.)'),
            text: i18n.t('invoice:quantity') + " " + this.props.state.total_count_invoice + " " + i18n.t('invoice:list'),
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: i18n.t('invoice:yes'),
            cancelButtonText: i18n.t('invoice:no'),
        }).then(async (result) => {
            if (result.value) {
                await this.setState({
                    errors: null,
                    loading: true,
                }, async () => {
                    var token_id = localStorage.getItem("token");
                    var header = {
                        authorization: `JWT ${token_id}`,
                        "Content-Type": "application/json",
                    };

                    await Swal.fire({
                        type: 'warning',
                        title: i18n.t('invoice:Please wait for the process to complete. Otherwise, it may result in an incomplete document.'),
                        showConfirmButton: false,
                        timer: 10000,
                    })

                    let keet_one_await = await axios.get(`${getApiRoot()}account/invoice-queryid-all`, {
                        headers: header,
                        params: {
                            start_date: this.props.state.start_date ? format(new Date(this.props.state.start_date), "YYYY-MM-DD") : "",
                            end_date: this.props.state.start_date ? format(new Date(this.props.state.end_date), "YYYY-MM-DD") : "",
                            search: this.props.state.search,
                            is_contact_online:
                                this.props.state.app_status === "all"
                                    ? null
                                    : this.props.state.app_status === "active",
                            status: this.props.status,
                        }
                    })

                    if (keet_one_await.data.data) {
                        await this.processInvoices(keet_one_await.data.data);
                        // Object.entries(keet_one_await.data.data).forEach(async ([keys, download_job_record], index) => {
                        //     let data = {
                        //         download_job_record_id: download_job_record,
                        //     }
                        //     console.log("keet_one_await",index);

                        //     // Procress Data Invoice
                        //     await axios.post(`${getApiRoot()}account/invoice-print-all`, data, {
                        //         headers: header,
                        //     }).then((response) => {
                        //         console.log("response",response);
                        //         this.setState({
                        //             percent_loading: parseInt( ((index + 1) / keet_one_await.data.data.length) * 100)
                        //         })
                        //     })
                            
                        // })
                    }

                });


            } else {
                this.setState({ loading: false });
            }
        });
        event.preventDefault();
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit} style={{ float: 'left' }}>
                <button type="submit" className="btn-primary-scale-outline ml-2" style={{ height: 42 }}
                    disabled={this.state.loading}>
                    {this.state.loading ?
                        <span>
                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                            {this.state.percent_loading + "% "}
                            <Translation>{t => t('invoice:Please wait a moment.')}</Translation>
                        </span> :
                        <span> <Translation>{t => t('invoice:Printall')}</Translation>
                        </span>
                    }
                </button>
            </form>
        )
    }
}

export default ExportInvoicePdf;