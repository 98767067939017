/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type receiveQueryQueryVariables = {|
  search?: ?string
|};
export type receiveQueryQueryResponse = {|
  +receiveViewer: ?{|
    +allReceive: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string
        |}
      |}>,
    |}
  |}
|};
export type receiveQueryQuery = {|
  variables: receiveQueryQueryVariables,
  response: receiveQueryQueryResponse,
|};
*/


/*
query receiveQueryQuery(
  $search: String
) {
  receiveViewer {
    allReceive(search: $search) {
      totalCount
      edges {
        node {
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    }
  ],
  "concreteType": "ReceiveNodeConnection",
  "kind": "LinkedField",
  "name": "allReceive",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiveNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceiveNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "receiveQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReceiveViewer",
        "kind": "LinkedField",
        "name": "receiveViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "receiveQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReceiveViewer",
        "kind": "LinkedField",
        "name": "receiveViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b8063804af477f6fe37529a2e3a3a3d",
    "id": null,
    "metadata": {},
    "name": "receiveQueryQuery",
    "operationKind": "query",
    "text": "query receiveQueryQuery(\n  $search: String\n) {\n  receiveViewer {\n    allReceive(search: $search) {\n      totalCount\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ebe55d9ac60e3656185cc643067aff6';

module.exports = node;
