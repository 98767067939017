import { format } from 'date-fns'
import i18next from 'i18next'
import React, { Component } from 'react'
import "../style/contractExpense.scss"

export default class headAlertConTractExpense extends Component {

  boxBodyHeader = (status) => {
    let valueResult = ""
    let boxColor = ""
    let iconStart = ""
    let iconEnd = ""

    switch (status) {
      case "NEAR_END":
        valueResult = i18next.t("updateFormContractExpense:This contract is currently proceeding as usual, but it is approaching its expiration date. Please check to renew the contract or proceed with the cancellation.")
        boxColor = "boxYellow"
        iconStart = "iconAlert-Yellow"
        iconEnd = "close-Yellow"
        break;
      case "RENEW":
        valueResult = `${i18next.t("updateFormContractExpense:Contract renewed successfully. The contract will start anew on")} ${format("2021/12/12", "DD/MM/YYYY")} ${i18next.t("updateFormContractExpense:Contract renewed by")} ${"dddd"}`
        boxColor = "boxGreen"
        iconStart = "iconAlert-SuccessGreen"
        iconEnd = "close-Green"
        break;
      case "NOT_RENEW":
        valueResult = `${i18next.t("updateFormContractExpense:Contract ends on")} ${format("2021/12/12", "DD/MM/YYYY")} ${i18next.t("updateFormContractExpense:Recorded by")} ${"dddd"} ${i18next.t("updateFormContractExpense:Reason")}: ${"dddd"}`
        boxColor = "boxRed"
        iconStart = "iconAlert-ErrorRed"
        iconEnd = "close-Red"
        break;
      case "VOID":
        valueResult = `${i18next.t("updateFormContractExpense:Canceled on")} ${format("2021/12/12", "DD/MM/YYYY")} ${i18next.t("updateFormContractExpense:by")} ${"dddd"} .${i18next.t("updateFormContractExpense:Reason")}: ${"dddd"}`
        boxColor = "boxRed"
        iconStart = "iconAlert-ErrorRed"
        iconEnd = "close-Red"
        break;
    }

    return (
      <div className={`${boxColor}` + " bodyHeader"}>
        <div>
          <img
            className="mr-2"
            src={process.env.PUBLIC_URL + `/images/iconAlerts/${iconStart}.png`}
            alt={`${iconStart}`} />
          <span>{valueResult}</span>
        </div>
        <div>
          <img src={process.env.PUBLIC_URL + `/images/iconAlerts/${iconEnd}.png`} alt={`${iconEnd}`} onClick={this.props.handleCloseHeaderAlert} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div id="headAlertContractExpense" className="my-4">
        {this.boxBodyHeader(this.props.status)}
      </div>
    )
  }
}
