import { Helmet } from "react-helmet";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { format } from "date-fns";
import "./styles/generalLedger.scss";
import { fetchQuery } from "relay-runtime";
import GeneralLedgerPDFTable from "./generalLedgerPDFTable";
import jwtDecode from "jwt-decode";

const query = graphql`
  query generalLedgerPDFQuery {
    selfProject {
      name
      address
      logo
    }
  }
`;

class GeneralLedgerPDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAll: this.props.location.state,
      dataCurrent: this.props.location.state.data.currentRecord,
      dataPrevious: this.props.location.state.data.summaryPreviousRecord,
      startDate: format(this.props.location.state.startDate, "DD/MM/YYYY"),
      endDate: format(this.props.location.state.endDate, "DD/MM/YYYY"),
      accountName: this.props.location.state.data.betweenChartOfAccount,
      projectName: "",
      tableHeader: [],
      tableSummary: [],
      summaryTableFooter: [],
      PrintBy: "",
    };
  }

  componentWillMount() {
    // header of PDF
    fetchQuery(environment, query).then((res) => {
      if (res.selfProject) {
        // let dataAllParse = [];
        let jwtDecodes = jwtDecode(window.localStorage.getItem("token"));

        this.setState({
          projectName: res.selfProject.name,
          PrintBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
        });
      }
    });
  }

  print = () => {
    window.print();
  };

  numberWithCommas(num) {
    let isNegative = false;
    // Check if the number is negative
    if (num < 0) {
      isNegative = true;
      num = Math.abs(num); // Convert negative number to positive
    }
    // Add .00 to the number
    num = num.toFixed(2);
    // Format the number with commas
    let formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // Add a minus sign for negative numbers
    if (isNegative) {
      formattedNum = '(' + formattedNum + ')';
    }
    return formattedNum;
  }

  render() {
    let historyDebit = 0;
    let historyCredit = 0;
    let historyBalance = historyDebit + historyCredit;
    let accountNumber = ''
    const dataAllBeforeSlice = [];

    {this.state.dataPrevious.edges.map((data, index) => {
                accountNumber = data.node?.chartOfAccountCode?.chartOfAccountCode;
                // let balance = 0;
                let totalDebit = 0;
                let totalCredit = 0;
                let totalBalance = 0;
                let beginningText = data.node?.name;
            
                  // beginning balance
                  // check ถ้าเป็นหมวด 1 5 จะตั้งต้นจาก debit
                  if(accountNumber.slice(0,1) === '1' || accountNumber.slice(0,1) === '5'){
                    historyDebit = data.node?.debit;
                    historyCredit = data.node?.credit;
                    historyBalance = historyDebit - historyCredit;
                    // balance += historyBalance;
                  } else {
                  // check ถ้าเป็นหมวด 2 3 4 จะตั้งต้นจาก crebit  
                    historyDebit = data.node?.debit;
                    historyCredit = data.node?.credit;
                    historyBalance = historyCredit - historyDebit;
                    // balance += historyBalance;                  
                  }                   

                // First line of new account
                let beginningLine = ( 
                    <React.Fragment key={`beginning-${data.node.id}`}>
                    <tr className='text-center f14 beginningLine' style={{ pageBreakInside: 'avoid' }}>
                    <td colSpan={6} className='text-left f14'>{accountNumber} {data.node?.chartOfAccountCode?.name}{" "}{this.state.accountName?.edges[index]?.node?.name}
                    {"   "} {beginningText}
                    </td>
                    <td className='text-right f14'>{historyBalance === 0.00? '-': this.numberWithCommas(historyBalance)}</td>
                    </tr>
                    </React.Fragment>
                )

                totalBalance += historyBalance;
                dataAllBeforeSlice.push(beginningLine) 

                this.state.dataCurrent.edges
                .filter((edge) => edge.node.chartOfAccountCode.chartOfAccountCode === accountNumber)
                .sort((a, b) => {
                    const dateA = new Date(a.node?.group?.issuedDate);
                    const dateB = new Date(b.node?.group?.issuedDate);
                
                    if (dateA.toDateString() === dateB.toDateString()) {
                    const refNumA = a.node?.group?.refNumber;
                    const refNumB = b.node?.group?.refNumber;
                
                    // แยกเลขอ้างอิงเป็นสองส่วน ตัวอักษร prefix และเลข suffix
                    const [prefixA, suffixA] = refNumA.split("-");
                    const [prefixB, suffixB] = refNumB.split("-");
                
                    // เปรียบเทียบเลข suffix กันเอง
                    const suffixCompare = Number(suffixA) - Number(suffixB);
                
                    if (suffixCompare === 0) {
                        // ถ้าเลข suffix เท่ากัน ให้เปรียบเทียบตาม prefix ต่อ
                        return prefixA.localeCompare(prefixB);
                    } else {
                        return suffixCompare;
                    }    } else {
                        return dateA - dateB;
                    }

                })
                .map((data, index) => {
                  totalDebit += data.node?.debit;
                  totalCredit += data.node?.credit; 
                  // check ถ้าเป็นหมวด 1 5 จะตั้งต้นจาก debit
                  if(accountNumber.slice(0,1) === '1' || accountNumber.slice(0,1) === '5'){
                    totalBalance += data.node.debit - data.node.credit; 
                  } else {
                  // check ถ้าเป็นหมวด 2 3 4 จะตั้งต้นจาก crebit  
                    totalBalance += data.node.credit - data.node.debit;
                  }   
                    let rowGlData = (
                    <React.Fragment key={`line-${index}-${data.node.id}`}>
                        <tr className='text-center table-row-data'>
                        <td className='text-center f14'>{format(data.node?.group?.issuedDate, "DD/MM/YYYY")}</td>
                        <td className='text-center f14' colSpan={2}>{data.node?.group?.refNumber}</td>
                        <td className='text-left f14'>{data.node?.name}</td>
                        <td className='text-right f14'>{data.node?.debit === 0.00? '-': this.numberWithCommas(data.node?.debit)}</td>
                        <td className='text-right f14'>{data.node?.credit === 0.00? '-': this.numberWithCommas(data.node?.credit)}</td>
                        <td className='text-right f14'>{totalBalance === 0.00? '-': this.numberWithCommas(totalBalance)}</td>
                        </tr>
                    </React.Fragment>
                    )
                                        
                    dataAllBeforeSlice.push(rowGlData) 
                    return data
                });    
                
                let summaryLine = (                
                  <tr className="summaryRow" key={`line-${index}-${data.node.id}`}>
                      <td className='text-center f14' colSpan={4}>รวม{" "}{accountNumber} {data.node?.chartOfAccountCode?.name}{" "}{this.state.accountName?.edges[index]?.node?.name}</td>
                      <td className='text-right f14' colSpan={1}>{totalDebit === 0.00? '-' : this.numberWithCommas(totalDebit)}</td>
                      <td className='text-right f14' colSpan={1}>{totalCredit === 0.00? '-' : this.numberWithCommas(totalCredit)}</td>
                      <td className='text-right f14' colSpan={1}>{totalBalance === 0.00? '-' : this.numberWithCommas(totalBalance)}</td>
                  </tr>
                )
                dataAllBeforeSlice.push(summaryLine) 
                return data
        })}   
 
    const sliceData = [];
    const dataRowPerPage = 16;
    for (let i = 0; i < dataAllBeforeSlice.length; i += dataRowPerPage) {
        sliceData.push(dataAllBeforeSlice.slice(i, i + dataRowPerPage));
      }

    return (
      <React.Fragment>
                <Helmet
                    style={[{
                      "cssText": `
                        body {
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                            background-color: #FAFAFA;
                          }
                        
                          * {
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                          }
                        
                          .page {
                            width: 297mm;
                            max-hight:210mm;
                            min-height: 210mm;
                            padding: 10mm 5mm 5mm 5mm ;
                            border: 1px #000 solid;
                            border-radius: 2px;
                            background: white;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                            position: relative;
                          }
                          .pageNumber{
                            position: fixed;
                            bottom: 0;
                          }
                        
                          @page {
                            size: a4 landscape;
                            margin: 0;
                          }
                          @media print {
                            html, body {
                              width: 297mm;
                              height: 210mm;
                            }

                            @page: last{
                                .pageNumber {
                                    display: block;
                                }
                            }
                            .bg-gray{
                              background-color: #D3D3D3;
                            }
                            .page {
                              margin: 0;
                              border: initial;
                              border-radius: initial;
                              width: initial;
                              min-height: initial;
                              box-shadow: initial;
                              background: initial;
                              page-break-after: always;
                              position: relative;
                            }
                            .pageNumber{
                                position: fixed;
                                bottom: 0;
                            }
                          }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>GeneralLedger</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>
        {
            // แก้ชื่อ class
          <div id="generalLedgerPDF"> 
            <div className="print-top-menu " id="work-sheet-report-main">
              <div className="logo">
                <img
                  src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png"
                  alt="silverman"
                />
              </div>
              <div className="print" onClick={this.print}>
                PRINT
              </div>
            </div>

            {/* ตัด data เสร็จแล้วมาวน */}
            {/* dataAllParse = [page1, page2, page3] */}
            {sliceData.map((nn, index) => {
              
              return (
                <div className={"print-page-a4 hoziA4"} key={"index-generalLedger" + index}>
                    <div>
                      <div>
                          <div className="table-header">
                                  <p>{this.state.projectName}</p>
                                  <p>รายงานบัญชีแยกประเภท</p>
                                  <p>
                                      {this.state.accountName?.edges[0]?.node?.name} {this.state.accountName?.edges?.length > 1 ? (
                                      <span>ถึง{" "}{this.state.accountName?.edges[this.state.accountName.edges.length - 1]?.node?.name}</span>) : ("")}
                                  </p>
                                  <p>เรียงตามวันที่ {this.state.startDate} ถึง{" "}{this.state.endDate}</p>
                                  <br/>
                          </div>

                          {/* วนข้อมูลใน table จากที่ตัดมาต่อหนึ่งหน้า */}
                          <div>
                          <div id="generalLedgerPDF">
                            <table className='table'>
                                        <GeneralLedgerPDFTable 
                                          data={nn} lastPage={(index + 1) === sliceData.length}
                                          dataPrevious={this.state.dataPrevious}
                                          tableHeader={this.state.tableHeader}
                                          summaryTable={this.state.summaryTableFooter}
                                        />
                            </table>
                          </div>
                            {/* Page Footer */}
                            <div className="footer">
                              <div className="row">
                                <div className="col-4" />
                                <div className="col-4 text-center">
                                  <p className="f14 pb-0 mb-0">{index + 1}/{sliceData.length}</p>
                                </div>
                                <div className="col-4 text-right">
                                  <p className="f14 pb-0 mb-0">ผู้พิมพ์ {this.state.PrintBy} วันที่พิมพ์{" "} {format(new Date(),"DD/MM/YYYY เวลา HH:mm น.")}</p>
                                </div>
                              </div>
                            </div>

                          </div>
                      </div>
                    </div>

                </div>
              );
            })}
          </div>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(GeneralLedgerPDF);
