import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import environment from '../../../../env/environment';
import Loading from '../../../../libs/loading';
import { Helmet } from 'react-helmet';
import InvoiceReportPDFTable from './invoiceReportPDFTable';
import './invoiceReportPDF.scss'

const query = graphql`
  query invoiceReportPDFQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $newEndDate: Date
    $search: String
    $status: String
    $customerType: String
    $productAndService: String
    $productAndServiceID: ID
    $order: String
    $suborder: String
    $contact: String
  ) {
    invoiceViewer {
      allInvoice(
        approveOnly: true
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        statusNew: $status
        customerType: $customerType
        productAndService: $productAndService
        order: $order
        suborder: $suborder
        contact: $contact
        report: true
        useEndDate:true
      ) {
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            status
            name
            firstName
            lastName
            payGroup
            contact {
              name
              refNumber
              firstName
              lastName
              typeOfContact
              residential {
                name
              }
            }
            transaction(productAndService_Id: $productAndServiceID) {
              edges {
                node {
                  id
                  description
                  paid
                  total
                  productAndService {
                    id
                    productCode
                    name
                  }

                  creditNoteTransaction(status: "paid",issuedDate_Lte:$newEndDate) {
                    edges {
                      node {
                        id
                        docNumber
                        issuedDate
                        price
                        status
                      }
                    }
                  }
                  creditNoteTransactionRecord(status: "paid"){
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              status
                          }
                      }
                  }
                  receiptDepositTransactionUsed {
                    edges {
                      node {
                        id
                        coupleUse
                        receiptDeposit {
                          id
                          docNumber
                          issuedDate
                        }
                        receiptDepositTransaction {
                          couple
                        }
                      }
                    }
                  }

                  receiveTransaction (receive_IssuedDate_Lte:$newEndDate){
                    edges {
                      node {
                        id
                        amount
                        receive {
                          id
                          docNumber
                          status
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
      summaryOnlyReportInvoice(
        approveOnly: true
        startDate: $startDate
        endDate: $endDate
        search: $search
        statusNew: $status
        customerType: $customerType
        productAndService: $productAndService
        contact: $contact
      )
    }
  }
`;

class InvoiceReportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            PrintBy: "",
            loading: true
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data
        })
    }
    render() {
        return (
            <React.Fragment >
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }

                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Invoice Report</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
            {
                <div id="invoiceReportPDF">
                    <div className="print-top-menu">
                        <div className="logo">
                            <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                        </div>
                        <div className="print" onClick={() => window.print()}>
                            PRINT
                        </div>
                    </div>
                    <div>
                        <QueryRenderer
                            environment={environment}
                                            query={query}
                                            cacheConfig={{ use_cache: false }}
                                            variables={{
                                            first: null,
                                            last: null,
                                            startDate: this.state.data.start_date,
                                            endDate: this.state.data.end_date,
                                            newEndDate: this.state.data.end_date.toISOString().slice(0,10),
                                            search: this.state.data.search,
                                            status: this.state.data.status,
                                            customerType: this.state.data.customer_type,
                                            productAndService: this.state.data.product_and_service,
                                            productAndServiceID:this.state.data.product_and_service,
                                            order: "doc_number",
                                            contact: this.state.data.contact,
                                            suborder: this.state.data.suborder,
                                            }}
                                            render={({ error, props }) => { 
                                            if (props) {
                                                let data = props.invoiceViewer.allInvoice.edges;
                                                let contactName = 'ทั้งหมด';
                                                let productAndService = 'ทั้งหมด';
                                                if(this.state.data.product_and_service !== "") {
                                                  // เก็บค่าชื่อ product and service ตอน filter
                                                    props.invoiceViewer.allInvoice.edges[0].node.transaction.edges.map((invoice)=>{
                                                      if(invoice.node.productAndService.id === this.state.data.product_and_service) {
                                                        productAndService = invoice.node.productAndService.productCode
                                                        + " " +invoice.node.productAndService.name
                                                      }
                                                    })
                                                }
                                                if(this.state.data.contact !== "") {
                                                    contactName = props.invoiceViewer.allInvoice.edges[0].node.contact.refNumber 
                                                    + " " + props.invoiceViewer.allInvoice.edges[0].node.contact.name 
                                                    + " " + props.invoiceViewer.allInvoice.edges[0].node.contact.firstName
                                                    + " " + props.invoiceViewer.allInvoice.edges[0].node.contact.lastName  
                                                }
                                                
                                                return (
                                                <React.Fragment>
                                                    <InvoiceReportPDFTable
                                                        state={data}
                                                        status={this.state.data.status}
                                                        startDate={this.state.data.start_date}
                                                        endDate={this.state.data.end_date}
                                                        props={props}
                                                        contactName={contactName}
                                                        productAndService={productAndService}
                                                        productAndServiceID={this.state.data.product_and_service}
                                                    />
                                                </React.Fragment>
                                                );
                                            } else {
                                                return <Loading/>
                                            }
                            }}
                        />
                   </div>
                </div>
            }
        </React.Fragment>
        )
    }
}

export default withRouter(InvoiceReportPDF)

