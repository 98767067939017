import { commitMutation } from 'react-relay'
import { Component } from 'react';
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import i18n from "i18next";
import React from 'react';
import Swal from 'sweetalert2';
import Switch from '../../components/Switch/Switch'

import environment from "../../env/environment";
import Header from '../../components/header/index';
import Loading from '../../libs/loading';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper';
import WrapperContent from '../../components/wrapper/wrapperContent';

import "../../project/projectDashboard.scss"

const mutation = graphql`
    mutation settingParcelMutation ($input: SettingParcelInput!) {
    settingParcel(input: $input) 
        {
            ok
            message
        }
}`

const query = graphql`
    query settingParcelQuery {
        parcelSetting{
            useRole
            showOld
            showBefore
        }
}`

class settingParcel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            useRole: false,
            showOld: true,
            showBefore: false,
            loading: true,
            loading_submit: false
        }
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        this.setState({ loading: true })
        fetchQuery(environment, query, {}).then(({ parcelSetting: { useRole = false, showOld = true, showBefore = false } }) => {
            this.setState({ useRole, showOld, showBefore, loading: false })
        }).catch(err => {
            this.setState({ loading: false })
            alert(err)
        })
    }

    handleChange(checked, event, id) {
        if (id === 'useRole' && checked === false) {
            this.setState({
                useRole: checked,
                showOld: true
            })
        } else {
            this.setState({ [id]: checked })
        }
    }

    submit = () => {
        this.setState({ loading_submit: true })
        let { useRole, showOld, showBefore } = this.state
        const variables = { input: { useRole, showOld, showBefore } };
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: ({ settingParcel = null }, errors) => {
                    if (settingParcel && settingParcel.message === "SUCCESS") {
                        Swal.fire(i18n.t("createUpdateForm:Successful record"), "", "success")
                        this.setState({ loading_submit: false })
                    } else {
                        Swal.fire(i18n.t("parcelSetting:Save failed"), "", "warning");
                        this.setState({ loading_submit: false })
                    }
                },
                onError: err => {
                    Swal.fire(i18n.t("parcelSetting:Save failed"), "", "warning");
                    console.error(err)
                    this.setState({ loading_submit: false })
                },
            },
        );
    }

    render() {
        let { loading, useRole, showOld, showBefore, loading_submit } = this.state
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                   { t =>(
                    <WrapperContent>
                        {!loading ?
                            <div className="container-fluid box booking-form" id="parcel">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3>
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"} alt="back" className="back" />
                                            </Link>
                                            <Translation>{(t) => <span className="header">{t('parcelSetting:Parcel setting')}</span>}</Translation>
                                        </h3>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center  mb-3  mt-5">
                                    <div className="col-8">
                                        <div className="line">
                                            <div className="header mt-2 mb-2">{t('parcelSetting:Parcel setting')}</div>
                                        </div>

                                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span className="bold">
                                                {t('parcelSetting:Show results according to permission (Enables visibility sharing between Co-owners/residents and renters.)')}
                                            </span>
                                            <Switch
                                                id='useRole'
                                                onChange={this.handleChange}
                                                checked={useRole}
                                            />
                                        </div>
                                        <div className="col-12 mt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span className="bold">{t('parcelSetting:Show parcel (Before 17/11/2022)')}</span>
                                            <Switch
                                                id='showOld'
                                                onChange={this.handleChange}
                                                checked={showOld}
                                                disabled={!useRole}
                                            />
                                        </div>
                                        <div className="col-12 mt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span className="bold">{t('parcelSetting:Show parcels only during the time they enter the project. (Activate of parcels only during the period of residence.)')}</span>
                                            <Switch 
                                                id='showBefore'
                                                onChange={this.handleChange}
                                                checked={showBefore}
                                            />
                                        </div>

                                        {/* {this.state.permissionEdit && */}
                                        <div className="row justify-content-end" style={{ marginTop: 80 }}>
                                            <React.Fragment>
                                                <button
                                                    type="button"
                                                    className="btn submit-seting"
                                                    onClick={this.submit}
                                                    style={{ justifyContent: 'center', backgroundColor: '#1567B4', color: '#FFF' }}
                                                    disabled={loading_submit}
                                                >
                                                    {loading_submit ? <span className="spinner-border spinner-border-sm align-middle mr-2 text-light" /> : null}
                                                    {t('parcelSetting:Saved')}
                                                </button>

                                            </React.Fragment>
                                        </div>
                                        {/* //   } */}
                                    </div>

                                </div>
                            </div> :
                            <Loading />
                        }

                    </WrapperContent>
                    )}
                </Translation>
            </Wrapper>
        );
    }


}
export default settingParcel;