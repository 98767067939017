import getApiRoot from "../../../libs/getAPIRoot";
import axios from "axios";

async function getTaxWithingTaxList(status, start_date, end_date, search) {
  let token_id = await localStorage.getItem("token");
  let header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
  };
  return axios.get(`${getApiRoot()}export/tax?status=${status}&start_date=${start_date}&end_date=${end_date}&search=${search}`, { headers: header })
}

export default {
  getTaxWithingTaxList
};