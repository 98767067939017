import React, { Component } from "react";
import { format } from "date-fns";
import getNameResidential from "../../../../libs/getNameResidential";
import thLocale from "date-fns/locale/th";
import { graphql } from "babel-plugin-relay/macro";
import jwtDecode from 'jwt-decode';
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import './creditNoteReportPDF.scss';
import _ from 'lodash';
import i18next from "i18next";

const query = graphql`
  query creditNoteReportPDFRenderTableQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

class CreditNoteReportPDFRenderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: "",
            printBy: "",
            start_date: "",
            end_date: "",
            contactName: "",
            customerType: "",
            status: "",
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                this.setState({
                    projectName: res.selfProject.name,
                    printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    contactName: this.props.contactName,
                    customerType: this.props.customerType,
                    status: this.props.status,
                })
            }
        }
        )
    }

    removeTrailingZeros(description) {
        if (description.includes("ค่าน้ำประปา")) {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    numberWithComma(num) {
        let isNegative = false;
        // if (num === 0) {
        //     return formattedNum = "-";
        // }
        // Check if the number is negative
        if (num < 0) {
            isNegative = true;
            num = Math.abs(num); // Convert negative number to positive
        }
        // Add .00 to the number
        num = num.toFixed(2);

        // Format the number with commas
        let formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (num === 0) {
            return formattedNum = "-";
        }

        // Add a minus sign for negative numbers
        if (isNegative) {
            formattedNum = '(' + formattedNum + ')';
        }
        return formattedNum;
    }

    render() {
        //  ประมวลผลข้อมูล
        // let summaryPrice = 0;
        // let summaryTran = 0;
        let order = 0;
        let dateafterChunked = []
        let dataChunked = [];
        let rowPerpage = 25;
        this.props.props.creditNoteViewer.allCreditNote.edges.forEach(credit_note => {
            let TotalPrice = 0
            let TotalTran = 0
            if (credit_note.node.version === 'V1') {
                TotalPrice += credit_note.node.price;
                TotalTran += credit_note.node.transaction.total
                // summaryPrice += credit_note.node.price
                // summaryTran += credit_note.node.transaction.total
                
                let new_cn = _.cloneDeep(credit_note)
                _.set(new_cn, "node.TotalPrice", TotalPrice);
                _.set(new_cn, "node.TotalTran", TotalTran);
                dateafterChunked.push(new_cn)
            } else {
                credit_note.node.creditNoteTransactionRecord.edges.forEach((credit_note_transaction, index) => {

                    let new_cn = _.cloneDeep(credit_note_transaction)
                    // summaryPrice += credit_note_transaction.node.price
                    // summaryTran += credit_note_transaction.node.transaction.total
                    TotalPrice += credit_note_transaction.node.price
                    TotalTran += credit_note_transaction.node.transaction.total

                    _.set(new_cn, "node.version", credit_note.node.node);
                    _.set(new_cn, "node.credit_note", credit_note.node);
                    _.set(new_cn, "node.firstindex", index === 0 ? true : false);
                    _.set(new_cn, "node.lastindex", index + 1 === credit_note.node.creditNoteTransactionRecord.edges.length ? true : false);
                    _.set(new_cn, "node.TotalPrice", index + 1 === credit_note.node.creditNoteTransactionRecord.edges.length ? TotalPrice : 0);
                    _.set(new_cn, "node.TotalTran", index + 1 === credit_note.node.creditNoteTransactionRecord.edges.length ? TotalTran : 0);
                    dateafterChunked.push(new_cn)
                })
            }
        });


        for (let i = 0; i < dateafterChunked.length; i += rowPerpage) {
            let chunk = dateafterChunked.slice(i, i + rowPerpage);
            dataChunked.push(chunk);
        }
        return (
            <React.Fragment>
                {dataChunked.map((data, page_index) => (
                    <div className='print-page-a4 hoziA4' key={page_index}>
                        {/* แสดงหัวกระดาษ */}
                        <div>
                            <p className='f12' style={{ lineHeight: 1.5 }}> {this.state.projectName}</p>
                            <p className='f10'>รายงานสรุปใบลดหนี้</p>
                            <p className='f10'>เรียงตามประเภทลูกค้า - {this.state.customerType}</p>
                            <p className='f10'>เรียงตามผู้ติดต่อ - {this.state.contactName}</p>
                            <p className='f10'>เรียงตามสถานะ - {this.state.status}</p>
                            <p className='f10'>เรียงตามวันที่ - {format(this.state.start_date, "DD-MM-YYYY")} ถึง {format(this.state.end_date, "DD-MM-YYYY")}</p>
                        </div>
                        {/* แสดงตาราง */}
                        <div className="row mt-3">
                            <div className="col-12">
                                <table className="table tebleBorder">
                                    <thead className='tableHead'>
                                        <tr>
                                            <th className="text-center width2">ลำดับ</th>
                                            <th className="text-center width8">เลขที่ใบลดหนี้</th>
                                            <th className="text-center width9">วันที่ออกใบลดหนี้</th>
                                            <th className="text-center width9">เลขที่ห้อง/บ้านเลขที่</th>
                                            <th className="text-center width14">ชื่อ</th>
                                            <th className="text-center width8">อ้างอิงใบแจ้งหนี้</th>
                                            <th className="text-center width8">วันที่ออกใบแจ้งหนี้</th>
                                            <th className="text-center width20">รายละเอียด</th>
                                            <th className="text-center width8">จำนวนเงิน</th>
                                            <th className="text-center width8">จำนวนเงินลดหนี้</th>
                                            <th className="text-center width6">รวมลดหนี้</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tableBody'>
                                        {data.map((credit_note, index) => {
                                            if (credit_note.node.version === "V1") {
                                                if (credit_note.node.status !== "VOID") {
                                                    return (
                                                        <React.Fragment key={credit_note.node.id}>
                                                            <tr>
                                                                <td className="text-center">{order += 1}</td>
                                                                <td className="text-center">
                                                                    {credit_note.node.docNumber}
                                                                </td>
                                                                <td className="text-center">{format(credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td className="text-left">
                                                                    {credit_note.node.contact.residential ? credit_note.node.contact.residential.name : "-"}
                                                                </td>
                                                                <td className="text-left">
                                                                    {credit_note.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                                        getNameResidential(credit_note.node.contact.firstName, credit_note.node.contact.lastName)
                                                                    }

                                                                    {credit_note.node.contact.typeOfContact === "SUPPLIER" &&
                                                                        credit_note.node.contact.name
                                                                    }
                                                                       {" "}                                            
                                                                    {
                                                                    (credit_note.node.credit_note.payGroup === "RENT" || credit_note.node.credit_note.payGroup === "AGENT" || credit_note.node.credit_note.payGroup === "OWNER" || credit_note.node.credit_note.payGroup === "DEVELOPER") &&
                                                                    <label>
                                                                    ({credit_note.node.credit_note.payGroup === "RENT" || credit_note.node.credit_note.payGroup === "AGENT" || credit_note.node.credit_note.payGroup === "OWNER" || credit_note.node.credit_note.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${credit_note.node.credit_note.payGroup}`) : ""})
                                                                    </label>
                                                                    }                                                                          
                                                                </td>
                                                                <td className="text-center">{credit_note.node.transaction.invoice.docNumber}</td>
                                                                <td className="text-center">{format(credit_note.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                                                <td className="text-left">{
                                                                    this.removeTrailingZeros(credit_note.node.transaction.description)
                                                                }</td>
                                                                <td className="text-right">{this.numberWithComma(credit_note.node.transaction.total)}</td>
                                                                <td className="text-right">{this.numberWithComma(credit_note.node.price)}</td>
                                                                <td className="text-right">{this.numberWithComma(credit_note.node.TotalPrice, '')}</td>
                                                            </tr>
                                                            {/* {
                                                                (index === data.length - 1) &&
                                                                (
                                                                    <tr>
                                                                        <td colSpan={8} className="text-center"><strong>รวม</strong></td>
                                                                        <td className="text-right"><strong>{this.numberWithComma(TotalTran)}</strong></td>
                                                                        <td className="text-right"><strong>{this.numberWithComma(TotalPrice)}</strong></td>
                                                                    </tr>
                                                                )
                                                            } */}
                                                        </React.Fragment>
                                                    )
                                                } else {
                                                    return (
                                                        <React.Fragment key={credit_note.node.id}>
                                                            <tr>
                                                                <td className="text-center text-danger">{order += 1}</td>
                                                                <td className="text-center text-danger">
                                                                    {credit_note.node.docNumber}
                                                                </td>
                                                                <td className="text-center text-danger">{format(credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td className="text-center text-danger">
                                                                    {credit_note.node.contact.residential ? credit_note.node.contact.residential.name : "-"}
                                                                </td>
                                                                <td className="text-left text-danger">
                                                                    {credit_note.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                                        getNameResidential(credit_note.node.contact.firstName, credit_note.node.contact.lastName)
                                                                    }

                                                                    {credit_note.node.contact.typeOfContact === "SUPPLIER" &&
                                                                        credit_note.node.contact.name
                                                                    }
                                                                       {" "}                                            
                                                                    {
                                                                    (credit_note.node.credit_note.payGroup === "RENT" || credit_note.node.credit_note.payGroup === "AGENT" || credit_note.node.credit_note.payGroup === "OWNER" || credit_note.node.credit_note.payGroup === "DEVELOPER") &&
                                                                    <label>
                                                                    ({credit_note.node.credit_note.payGroup === "RENT" || credit_note.node.credit_note.payGroup === "AGENT" || credit_note.node.credit_note.payGroup === "OWNER" || credit_note.node.credit_note.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${credit_note.node.credit_note.payGroup}`) : ""})
                                                                    </label>
                                                                    }        
                                                                </td>
                                                                <td className="text-center text-danger">{credit_note.node.transaction.invoice.docNumber}</td>
                                                                <td className="text-center text-danger">{format(credit_note.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                                                <td className="text-left text-danger">{
                                                                    this.removeTrailingZeros(credit_note.node.transaction.description)
                                                                }</td>
                                                                <td className="text-right text-danger">{this.numberWithComma(0)}</td>
                                                                <td className="text-right text-danger">{this.numberWithComma(0)}</td>
                                                                <td className="text-right">{''}</td>
                                                            </tr>
                                                            {/* {
                                                                (index === data.length - 1) &&
                                                                (
                                                                    <tr>
                                                                        <td colSpan={8} className="text-center"><strong>รวม</strong></td>
                                                                        <td className="text-right"><strong>{this.numberWithComma(TotalTran)}</strong></td>
                                                                        <td className="text-right"><strong>{this.numberWithComma(TotalPrice)}</strong></td>
                                                                    </tr>
                                                                )
                                                            } */}
                                                        </React.Fragment>
                                                    )
                                                }
                                            } else {
                                                if (credit_note.node.status !== "VOID") {

                                                    return (
                                                        <React.Fragment key={credit_note.node.id}>
                                                            <tr>
                                                                <td className="text-center">{credit_note.node.firstindex ? order += 1 : ""}</td>
                                                                <td className="text-center">
                                                                    {credit_note.node.credit_note.docNumber}
                                                                </td>
                                                                <td className="text-center">{format(credit_note.node.credit_note.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td className="text-center">
                                                                    {credit_note.node.credit_note.contact.residential ? credit_note.node.credit_note.contact.residential.name : "-"}
                                                                </td>
                                                                <td className="text-left">
                                                                    {credit_note.node.credit_note.contact.typeOfContact === "RESIDENTIAL" &&
                                                                        getNameResidential(credit_note.node.credit_note.contact.firstName, credit_note.node.credit_note.contact.lastName)
                                                                    }

                                                                    {credit_note.node.credit_note.contact.typeOfContact === "SUPPLIER" &&
                                                                        credit_note.node.credit_note.contact.name
                                                                    }
                                                                       {" "}                                            
                                                                    {
                                                                    (credit_note.node.credit_note.payGroup === "RENT" || credit_note.node.credit_note.payGroup === "AGENT" || credit_note.node.credit_note.payGroup === "OWNER" || credit_note.node.credit_note.payGroup === "DEVELOPER") &&
                                                                    <label>
                                                                    ({credit_note.node.credit_note.payGroup === "RENT" || credit_note.node.credit_note.payGroup === "AGENT" || credit_note.node.credit_note.payGroup === "OWNER" || credit_note.node.credit_note.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${credit_note.node.credit_note.payGroup}`) : ""})
                                                                    </label>
                                                                    }        
                                                                </td>
                                                                <td className="text-center">{credit_note.node.transaction.invoice.docNumber}</td>
                                                                <td className="text-center">{format(credit_note.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                                                <td className="text-left">{
                                                                    this.removeTrailingZeros(credit_note.node.transaction.description)
                                                                }</td>
                                                                <td className="text-right">{this.numberWithComma(credit_note.node.transaction.total)}</td>
                                                                <td className="text-right">{this.numberWithComma(credit_note.node.price)}</td>
                                                                <td className="text-right">{credit_note.node.lastindex ? this.numberWithComma(credit_note.node.TotalPrice, '') : ''}</td>
                                                            </tr>
                                                            {/* {
                                                                    (jindex === credit_note.node.creditNoteTransactionRecord.edges.length - 1) &&
                                                                    (
                                                                        <tr>
                                                                            <td colSpan={8} className="text-center"><strong>รวม</strong></td>
                                                                            <td className="text-right"><strong>{this.numberWithComma(TotalTran)}</strong></td>
                                                                            <td className="text-right"><strong>{this.numberWithComma(TotalPrice)}</strong></td>
                                                                        </tr>
                                                                    )
                                                                } */}
                                                        </React.Fragment>
                                                    )


                                                } else {
                                                    return (
                                                        <React.Fragment key={credit_note.node.id}>
                                                            <tr>
                                                            <td className="text-center">{credit_note.node.firstindex ? order += 1 : ""}</td>
                                                                <td className="text-center  text-danger">
                                                                    {credit_note.node.credit_note.docNumber}
                                                                </td>
                                                                <td className="text-center  text-danger">{format(credit_note.node.credit_note.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td className="text-center  text-danger">
                                                                    {credit_note.node.credit_note.contact.residential ? credit_note.node.credit_note.contact.residential.name : "-"}
                                                                </td>
                                                                <td className="text-left  text-danger">
                                                                    {credit_note.node.credit_note.contact.typeOfContact === "RESIDENTIAL" &&
                                                                        getNameResidential(credit_note.node.credit_note.contact.firstName, credit_note.node.credit_note.contact.lastName)
                                                                    }

                                                                    {credit_note.node.credit_note.contact.typeOfContact === "SUPPLIER" &&
                                                                        credit_note.node.credit_note.contact.name
                                                                    }
                                                                       {" "}                                            
                                                                    {
                                                                    (credit_note.node.credit_note.payGroup === "RENT" || credit_note.node.credit_note.payGroup === "AGENT" || credit_note.node.credit_note.payGroup === "OWNER" || credit_note.node.credit_note.payGroup === "DEVELOPER") &&
                                                                    <label>
                                                                    ({credit_note.node.credit_note.payGroup === "RENT" || credit_note.node.credit_note.payGroup === "AGENT" || credit_note.node.credit_note.payGroup === "OWNER" || credit_note.node.credit_note.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${credit_note.node.credit_note.payGroup}`) : ""})
                                                                    </label>
                                                                    }        
                                                                </td>
                                                                <td className="text-center  text-danger">{credit_note.node.transaction.invoice.docNumber}</td>
                                                                <td className="text-center  text-danger">{format(credit_note.node.transaction.invoice.issuedDate, 'DD/MM/YYYY')}</td>
                                                                <td className="text-left  text-danger">{
                                                                    this.removeTrailingZeros(credit_note.node.transaction.description)
                                                                }</td>
                                                                <td className="text-right  text-danger">{this.numberWithComma(0)}</td>
                                                                <td className="text-right  text-danger">{this.numberWithComma(0)}</td>
                                                                <td className="text-right">{''}</td>
                                                            </tr>
                                                            {/* {
                                                                    (jindex === credit_note.node.creditNoteTransactionRecord.edges.length - 1) &&
                                                                    (
                                                                        <tr>
                                                                            <td colSpan={8} className="text-center"><strong>รวม</strong></td>
                                                                            <td className="text-right"><strong>{this.numberWithComma(TotalTran)}</strong></td>
                                                                            <td className="text-right"><strong>{this.numberWithComma(TotalPrice)}</strong></td>
                                                                        </tr>
                                                                    )
                                                                } */}
                                                        </React.Fragment>
                                                    )



                                                }
                                            }
                                        })}
                                    </tbody>
                                    <tfoot className="tableFooter">
                                        {page_index === dataChunked.length - 1 &&
                                            <tr>
                                                <td colSpan={8} className="text-center"><strong>รวมทั้งหมด</strong></td>
                                                <td className="text-right">
                                                    <strong>{this.numberWithComma(this.props.props.creditNoteViewer.summaryCreditNote[0])}</strong></td>
                                                <td className="text-right">
                                                    <strong>{this.numberWithComma(this.props.props.creditNoteViewer.summaryCreditNote[1])}</strong></td>
                                                <td className="text-right">
                                                    <strong>{this.numberWithComma(this.props.props.creditNoteViewer.summaryCreditNote[1])}</strong></td>
                                            </tr>
                                        }
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        {/* แสดงเลขหน้า และผู้พิมพ์ */}
                        <div className="footer">
                            <div className="row">
                                <div className="col-5" />
                                <div className="col-2 text-center">
                                    <p className="f10 pb-0 mb-0">{page_index + 1}/{dataChunked.length}</p>
                                </div>
                                <div className="col-5 text-right">
                                    <p className="f10 pb-0 mb-0">
                                        ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์{format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}

export default CreditNoteReportPDFRenderTable;