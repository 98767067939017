
import React, { Component } from 'react';
import format from 'date-fns/format';
import _ from 'lodash'
import '../receivable.scss'
//รวมการชำระเงินที่เป็นไปได้
class ReceivablePDFTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            optionAction: false,
            data: [],
            dataReport: [],
            wroksheetView: [],
            allResidential: [],
            page: 1,
            pageAll: 0,
            pageSize: 100,
            totalCount: 0,
            index_first: 0,
            index_last: 100,
            startDate: "",
            endDate: "",
            projectName: "",
            projectLogo: "",
            projectAddr: "",
            tableHeader: [],
            tableSummary: [],

            sumData: {}
        }
    }

    componentWillMount() {
        let sumSData = {}
        let sum1 = _.sumBy(this.props.data, ex => ex.payment1 ? parseFloat(ex.payment1) : 0)
        let sum2 = _.sumBy(this.props.data, ex => ex.payment2 ? parseFloat(ex.payment2) : 0)
        let sum3 = _.sumBy(this.props.data, ex => ex.payment3 ? parseFloat(ex.payment3) : 0)
        let sum4 = _.sumBy(this.props.data, ex => ex.payment4 ? parseFloat(ex.payment4) : 0)
        let sum5 = _.sumBy(this.props.data, ex => ex.payment5 ? parseFloat(ex.payment5) : 0)
        let sumOther = _.sumBy(this.props.data, ex => ex.other_price ? parseFloat(ex.other_price) : 0)
        let total = _.sumBy(this.props.data, ex => ex.total ? parseFloat(ex.total) : 0)

        sumSData = [sum1, sum2, sum3, sum4, sum5, sumOther, total]
        this.setState({ sumData: sumSData })

    }

    onConvertNumberMoney(number) {
        let ConvertNumber = parseFloat(number).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        return ConvertNumber === "NaN" ? "" : ConvertNumber
    }

    thFooterInDataTable(dataNumber, data, startIndex) {
        startIndex = startIndex ? startIndex : 0
        let TablesFild = []
        for (let index = startIndex; index < (dataNumber + startIndex); index++) {
            TablesFild.push(<th className='text-right' key={"thFooterInDataTable" + index}>{data[index] ? this.onConvertNumberMoney(data[index]) : ""}</th>)
        }
        return TablesFild
    }

    tdTableData(header, rowData) {
        let TablesFild = []
        for (let index = 1; index <= header.length; index++) {
            TablesFild.push(<td className='text-right' key={"tdTableData" + index}>
                {rowData['payment' + index] ? this.onConvertNumberMoney(rowData['payment' + index]) : ""}
            </td>
            )
        }
        return TablesFild
    }

    render() {
        let numberINHeader = parseInt(this.props.tableHeader.length) > 5 ? parseInt(this.props.tableHeader.length) - 3 : parseInt(this.props.tableHeader.length)
        // console.log(this.props.data);
        return (
            <React.Fragment >
                <section className="body">
                    <table className="table table-bordered">
                        <thead>
                            <tr className='text-center'>
                                <th rowSpan={3} style={{ width: 40 }}>ลำ<br />ดับ</th>
                                <th rowSpan={3} style={{ width: 90 }}>วันที่</th>
                                <th rowSpan={3} style={{ width: 125 }}>เลขที่เอกสาร</th>
                                <th rowSpan={3} style={{ width: 75 }}>เลขที่ห้อง/<br />บ้านเลขที่</th>
                                <th rowSpan={3}>ชื่อ</th>
                                <th colSpan={numberINHeader + 2}>ประเภทรายรับ</th>
                                <th colSpan={3}>ประเภทการรับชำระ</th>
                                <th rowSpan={3} style={{ width: 120 }}>จำนวนเงินรวม</th>
                            </tr>
                            <tr className='text-center'>
                                {
                                    this.props.tableHeader?.map((tableHeader, index_tableHeader) => {
                                        return (
                                            (index_tableHeader + 1) <= 5 &&
                                            <th key={index_tableHeader} rowSpan={2} className='width'>{tableHeader}</th>
                                        )
                                    })
                                }
                                <th colSpan={2}>อื่นๆ</th>
                                <th rowSpan={2} style={{ width: 70, maxWidth: 70, minWidth: 70 }}>ประเภท</th>
                                <th rowSpan={2} style={{ width: 140 }}>รายละเอียด</th>
                                <th rowSpan={2} className='width'>จำนวนเงิน</th>
                            </tr>
                            <tr className='text-center'>
                                <th style={{ width: 140 }}>รายการ</th>
                                <th className='width'>จำนวนเงิน</th>
                            </tr>
                        </thead>
                        <tbody className="current">
                            {
                                this.props.data?.map((data_Export, index) => {
                                    let rowData = data_Export
                                    let roomSplit = rowData?.room?.replace(" ", "\n")
                                    roomSplit = rowData?.room?.replace("(", " (")
                                    return (

                                        <React.Fragment key={"data_Exportss" + index}>
                                            <tr className='text-center' key={index}>
                                                <td className='no'><div>{rowData?.no}</div></td>
                                                <td className='nowerp'>{rowData?.no ? format(new Date(rowData?.issued_date), "DD/MM/YYYY") : ""}</td>
                                                <td className='nowerp'>{rowData?.doc_number}</td>
                                                <td>
                                                    <div className='text-center roomName'>
                                                        {roomSplit}
                                                    </div>
                                                </td>
                                                <td className='minWirdth' style={{ width: 100 }}>
                                                    <div className='text-left'> {rowData?.name}</div>
                                                </td>
                                                {this.tdTableData(this.props.tableHeader, rowData)}

                                                <td><div className='text-left'>{rowData?.other_detail}</div></td>
                                                <td className='text-right'>{rowData?.other_price ? this.onConvertNumberMoney(rowData?.other_price) : ""}</td>

                                                <td className='text-left'>{rowData?.type}</td>
                                                <td><div className='text-left'>{rowData?.type_datail}</div></td>
                                                <td className='text-right'>{rowData?.type_pay ? this.onConvertNumberMoney(rowData?.type_pay) : ""}</td>
                                                <td className='text-right'>{this.onConvertNumberMoney(rowData?.total)}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tbody>

                        <tfoot>
                            <tr className='text-center' key={"LastDataRecieecive"}>
                                <th colSpan={5}>รวมจำนวนเงิน</th>
                                {
                                    this.thFooterInDataTable(numberINHeader, this.state.sumData)
                                }
                                <th></th>
                                <th className='text-right'>{this.state.sumData[5] ? this.onConvertNumberMoney(this.state.sumData[5]) : ""}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th className='text-right'>{this.onConvertNumberMoney(this.state.sumData[6])}</th>
                            </tr>

                            {
                                this.props.lastPage &&
                                <tr className='text-center' key={"LastPageRecieecive"}>
                                    <th colSpan={5}>รวมจำนวนเงินทั้งหมด</th>
                                    {
                                        this.thFooterInDataTable(numberINHeader, this.props.summaryTable, 1)
                                    }
                                    <td></td>
                                    <th className='text-right'>{this.props.summaryTable.other ? this.onConvertNumberMoney(this.props.summaryTable.other) : ""}</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th className='text-right'>{this.onConvertNumberMoney(this.props.summaryTable.amount)}</th>
                                </tr>
                            }

                        </tfoot>
                    </table>
                </section>
            </React.Fragment>
        )
    }
}

export default ReceivablePDFTable






