import React from "react";
import { Translation } from "react-i18next";
import { Redirect } from "react-router";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import GuaranteeMoneyReceiveMovementTable from "./guaranteeMoneyReceiveMovementTable";
import ExportGuaranteeMoneyReceiveMovement from "./server_export";
import Loading from '../../../../libs/loading';
import _ from "lodash";
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from '../../../../components/BackBtn/indexBack';
import i18next from "i18next";

const query = graphql`
  query guaranteeMoneyReceiveMovementReportQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $customerType: String
    $contact: ID
    $paymentChannel: String
    $order: String
    $status: String
  ) {
    allGuaranteeMoneyReceived(
      first: $first
      last: $last
      startDate: $start_date
      endDate: $end_date
      search: $search
      customerType: $customerType
      contact: $contact
      paymentChannel: $paymentChannel
      order: $order
      status: $status
    ) {
      totalCount
      edges {
        node {
          id
          docNumber
          issuedDate
          chartOfAccount {
            chartOfAccountCode
            name
          }
          description
          amount
          status
          clearGuarantee {
            edges {
              node {
                id
                status
                docNumber
                issuedDate
                guaranteeTransaction {
                  edges {
                    node {
                      id
                      amount
                      typeOfTransaction
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
class GuaranteeMoneyReceiveMovementReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.customer_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.status = "";
    this.state.temp_status = "";
    this.state.contact = "";
    this.state.payment_channel = "";
    this.state.temp_payment_channel = "";

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "guaranteeMoneyReceiveMovementReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      status: this.state.temp_status,
      payment_channel: this.state.temp_payment_channel,
    });
  }

  render() {
    if (this.props.customerList.length > 0) {
      return (
        <Translation>
          {t =>
            <div className="container-fluid box">
              <BackButtonIcon
                LinkBack={"/accounting/report/account-receivable"}
                LinkText={i18next.t("receivable_dashboard:Security Deposit Movement Report")}
                boxHtmlText={
                  <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "report_receivable_print",
                    }) && (
                        <ExportGuaranteeMoneyReceiveMovement
                          state={this.state}
                          customerList={this.props.customerList}
                        />
                      )}
                  </div>
                }
              />
              <div className="content-inner">
                <div className="row justify-content-end mb-2">
                  <div className="col">
                    <div className="input-group float-right w-auto ml-2">
                      <input
                        type="text"
                        className="form-control search-input input-size"
                        placeholder={i18next.t("AllFilter:Search")}
                        name="search"
                        value={this.state.search_input}
                        onChange={(e) =>
                          this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleSearch(e)}
                      >
                        {i18next.t("AllFilter:Search")}
                      </button>
                    </div>

                    <div className="float-right ml-2">
                      <DatePickerAdapter
                        name="temp_end_date"
                        className="form-control"
                        selected={this.state.temp_end_date}
                        maxDate={this.state.current_date}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>

                    <div className="float-right ml-2">
                      <DatePickerAdapter
                        name="temp_start_date"
                        className="form-control"
                        selected={this.state.temp_start_date}
                        // minDate={this.state.min_date}
                        maxDate={this.state.end_date}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="row fade-up">
                  <div className="col">
                    <div className="card advance-search">
                      <div className="row">
                        {/*<div className="col-md-4 border-right">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>ประเภทลูกค้า</label>*/}
                        {/*        <select name="customer_type" onChange={this.handleChange}*/}
                        {/*                value={this.state.customer_type}*/}
                        {/*                className="form-control dropdown-custom-arrow">*/}
                        {/*            <option value="">ทั้งหมด</option>*/}
                        {/*            <option value="residential">เฉพาะเจ้าของร่วม/สมาชิก</option>*/}
                        {/*            <option value="supplier">เฉพาะลูกหนี้</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<ContactReportList handleChange={this.handleChange}*/}
                        {/*                   customer_type={this.state.customer_type}*/}
                        {/*                   contact={this.state.contact}/>*/}
                        <div className="col-md-4 border-right">
                          <div className="form-group">
                            <label>{i18next.t("GuaranteeMoneyReceiveMovementReport:Status")}</label>
                            <select
                              name="temp_status"
                              onChange={this.handleChange}
                              className="form-control dropdown-custom-arrow"
                              value={this.state.temp_status}
                            >
                              <option value="">{i18next.t("GuaranteeMoneyReceiveMovementReport:All")}</option>
                              <option value="in_process">{i18next.t("GuaranteeMoneyReceiveMovementReport:Pending Clearance")}</option>
                              <option value="completed">{i18next.t("GuaranteeMoneyReceiveMovementReport:Refund")}</option>
                              <option value="return_forfeit">
                              {i18next.t("GuaranteeMoneyReceiveMovementReport:Partial Seizure/ Partial Return")}
                              </option>
                              <option value="forfeit_all">{i18next.t("GuaranteeMoneyReceiveMovementReport:Seizure")}</option>
                              <option value="void">{i18next.t("GuaranteeMoneyReceiveMovementReport:Canceled Transaction")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{i18next.t("GuaranteeMoneyReceiveMovementReport:Payment Method")}</label>
                            <select
                              name="temp_payment_channel"
                              onChange={this.handleChange}
                              className="form-control dropdown-custom-arrow"
                              value={this.state.temp_payment_channel}
                            >
                              <option value="">{i18next.t("GuaranteeMoneyReceiveMovementReport:All")}</option>
                              <option value="cash">{i18next.t("GuaranteeMoneyReceiveMovementReport:Cash")}</option>
                              <option value="bank">{i18next.t("GuaranteeMoneyReceiveMovementReport:Bank Transfer")}</option>
                              <option value="cheque">{i18next.t("GuaranteeMoneyReceiveMovementReport:Cheque")}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <QueryRenderer
                  environment={environment}
                  query={query}
                  cacheConfig={{ use_cache: false }}
                  variables={{
                    first: this.state.first,
                    last: this.state.last,
                    start_date: this.state.start_date,
                    end_date: this.state.end_date,
                    search: this.state.search,
                    contact: this.props.customerList,
                    customerType: this.state.customer_type,
                    paymentChannel: this.state.payment_channel,
                    order: "doc_number",
                    status: this.state.status,
                  }}
                  render={({ error, props }) => {
                    if (props) {
                      return (
                        <React.Fragment>
                          <div className="row mt-3">
                            <div className="col-12">
                              <div className="card fade-up">
                                <div className="table-responsive">
                                  <table className="table table-hover">
                                    <thead className="thead-light">
                                      <tr>
                                        <th className="text-center">{i18next.t("GuaranteeMoneyReceiveMovementReport:Sequence")}</th>
                                        <th>{i18next.t("GuaranteeMoneyReceiveMovementReport:Deposit Guarantee Receipt Number")}</th>
                                        <th className="text-center">
                                        {i18next.t("GuaranteeMoneyReceiveMovementReport:Date of Deposit Guarantee Receipt")}
                                        </th>
                                        <th>{i18next.t("GuaranteeMoneyReceiveMovementReport:Transaction")}</th>
                                        <th>{i18next.t("GuaranteeMoneyReceiveMovementReport:Description")}</th>
                                        <th className="text-right">{i18next.t("GuaranteeMoneyReceiveMovementReport:Amount")}</th>
                                        <th className="text-right">
                                        {i18next.t("GuaranteeMoneyReceiveMovementReport:Amount Seized")}
                                        </th>
                                        <th className="text-right">
                                        {i18next.t("GuaranteeMoneyReceiveMovementReport:Amount Returned")}
                                        </th>
                                        <th className="text-right">
                                        {i18next.t("GuaranteeMoneyReceiveMovementReport:Remaining Amount")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <GuaranteeMoneyReceiveMovementTable
                                        props={props}
                                        state={this.state}
                                      />
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <Pagination
                              changePage={this.changePage}
                              first={this.state.first}
                              last={this.state.last}
                              totalCount={
                                props.allGuaranteeMoneyReceived.totalCount
                              }
                            />
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return <Loading />
                    }
                  }}
                />
              </div>
            </div>

          }
        </Translation>
      );
    }
    return (
      <Redirect to="/accounting/report/account-receivable/guarantee-money-received-movement/select-customer" />
    );
  }
}

export default GuaranteeMoneyReceiveMovementReport;
