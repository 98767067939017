import React, { Component } from "react";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "./numberWithComma";
import _ from 'lodash';
import i18next from "i18next";

class PaymentChannel extends Component {

    render() {
        let No_number = 0;

        // Combine and sort the three datasets
        const allTransactions = _.sortBy([
            ...this.props.cashTransaction,
            ...this.props.chequeTransaction,
            ...this.props.allBankAccountTransaction,
        ], (transaction) => transaction.node.date).reverse();

        return (
            <div className="card mt-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <p><strong>{i18next.t("pettyCashList:Paid by")}</strong></p>
                            {allTransactions.map((transaction) => {
                                No_number += 1;
                                let type_pay = atob(transaction.node.id)
                                // Render transaction based on its type
                                if (_.startsWith(type_pay, "CashTransactionNode")) {
                                    // Cash Transaction
                                    return (
                                        <p key={transaction.node.id}>{No_number}.เงินสด/Cash {numberWithComma(transaction.node.price)}   {transaction.node.date && "วันที่ "+ format(transaction.node.date, "DD/MM/YYYY")}</p>
                                    );
                                } else if (_.startsWith(type_pay, "ChequeTransactionNode")) {
                                    // Cheque Transaction
                                    return (
                                        <React.Fragment key={transaction.node.id}>
                                            <p>{No_number}.เช็คธนาคาร/Cheque Bank</p>
                                            <p key={transaction.node.id}>-
                                                เช็คเลขที่&nbsp;&nbsp;{transaction.node.chequeNumber}
                                                &emsp;ธนาคาร&nbsp;&nbsp;{transaction.node.bankAccount.accountName}
                                                &emsp;สาขา&nbsp;&nbsp;{transaction.node.bankAccount.branch}
                                                &emsp;วันที่&nbsp;&nbsp;{format(transaction.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                &emsp;จำนวนเงิน&nbsp;&nbsp;{numberWithComma(transaction.node.price)} บาท</p>
                                        </React.Fragment>
                                    );
                                } else if (_.startsWith(type_pay, "BankAccountTransactionNode")) {
                                    // Bank Transaction
                                    return (
                                        <React.Fragment key={transaction.node.id}>
                                            <p>{No_number}.เงินโอน/Bank Transfer {transaction.node.description}</p>
                                            <p>-
                                                ธนาคาร&nbsp;&nbsp;{transaction.node.bankAccount.bankName}
                                                &emsp;เลขที่บัญชี&nbsp;&nbsp;{transaction.node.bankAccount.accountNumber}
                                                &emsp;วันที่&nbsp;&nbsp;{format(transaction.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                &emsp;จำนวนเงิน&nbsp;&nbsp;{numberWithComma(transaction.node.price)} บาท</p>
                                        </React.Fragment>
                                    );
                                }

                                return null; // handle other transaction types if needed
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentChannel;
