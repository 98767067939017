import React, { useEffect,useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { Translation } from "react-i18next";
import environment from "../env/environment";
import { format } from 'date-fns';
import { fetchQuery } from "relay-runtime";
import thLocale from "date-fns/locale/th";
import { commitMutation } from "react-relay";
import Loading from "../libs/loading";
import "./style.scss";
import moment from 'moment';


const query = graphql` 
   query popupBannerQuery{
    allJuristicNews(latest:true,page:false){
        edges{
            node{
                id
                title
                titleEn
                detail
                detailEn
                status
                creator
                added
                scheduleTime
                highlight
                previewImage
                group{
                    id
                    name
                    nameEn
                }
            }
        }
    }
    }
`;

function PopupBanner(props) {

    const [visible, setVisible] = React.useState(false);
    const [newsPins, setNewsPins] = useState([])
    const [isloading, setIsloading] = useState(false);

    useEffect(()=>{
      var pop_status = localStorage.getItem('pop_status');
      var today = new Date()

      if(!pop_status){
        setVisible(true);
        localStorage.setItem('pop_status',1);
        localStorage.setItem('pop_date_start',today)
      }

      if(pop_status){
        checkPopupStatus()
      }
 
    },[])

    useEffect(() => {
      fetchQuery(environment, query).then((res) => {
        setNewsPins(res?.allJuristicNews?.edges[0]?.node ?? {})
        setIsloading(true)
        return
      })
    }, [])

    const checkPopupStatus = () => {
        var today = new Date()
        var pop_date_start = localStorage.getItem('pop_date_start');
        var now = moment();
        var Todaydate = now.date(); 
  
        var pop_date_start_date = new Date(pop_date_start).getDate()

        if(Todaydate > pop_date_start_date){
            localStorage.setItem('pop_date_start',today)
            setVisible(true)
        }else{
            setVisible(false)
        }
    }

    const countword = (detail) =>{
      let detail_new = null;
        if (detail?.length >= 150) {
          detail_new =
            detail?.replaceAll(/<[^>]+>/g, "").substring(0, 150) + "...";
          detail_new = detail_new.replaceAll(
            "&nbsp;",
            " "
          );
        } else {
          detail_new = detail?.replaceAll(
            /<[^>]+>/g,
            ""
          );
          detail_new = detail_new?.replaceAll(
            "&nbsp;",
            " "
          );
        }
        return detail_new
    }
     return (
      
      <>
      <div id="popupBanner">
        {
            !isloading ? <Loading/> :
            <Modal show={visible}  id="modal"  size="lg" className='modal-popup-banner'
            aria-labelledby="contained-modal-title-vcenter"
            centered >
                {/* <div style={{position:'revert'}}>               
                    <img
                    src={process.env.PUBLIC_URL + '/images/banner/foqus.png'}
                    alt="alert" className='img-fluid '/>
                    <img  onClick={() => setVisible(false) }
                    src={process.env.PUBLIC_URL + '/images/banner/Union.png'}
                    alt="alert" 
                    style={{width:'20px',position:'absolute',right:'8%',top:'-5%',cursor:'pointer'}}
                    /> 
                </div> */}
                    <div className='row pt-5'>
                        <div className='col-md-6'>
                          {
                            newsPins.previewImage ? 
                            <Link to={"/announce-dashboard/news/detail/"+newsPins.id}>
                              <img src={newsPins.previewImage}
                              alt="announce-main" className='dashboard-img'/>
                            </Link> : 
                            <img src={process.env.PUBLIC_URL + '/images/dashboard/announce-main.png'}
                            alt="announce-main" className='img-fluid'/>
                          }
                            
                        </div>
                        <div className='col-md-6'>
                                <Translation>{t => <h4 className='text-primary mt-2'>{t('announceDashboard:last')}</h4>}</Translation>
                                <Link to={"/announce-dashboard/news/detail/"+newsPins.id}>
                                  <h2 style={{color:'#000000'}}>{newsPins.title}</h2>
                                </Link>
                                <div style={{width:'100%'}}>
                                  <div
                                  className="content"
                                  dangerouslySetInnerHTML={{
                                    __html: countword(newsPins.detail),
                                  }}/>
                                </div>
                                <p className='pt-8'> 
                                  <span className="font-italic mr-2">{format(newsPins.added,  "DD/MM/YYYY - HH:mm น.",
                                        { locale: thLocale }) || '-'}</span>    
                                  <Translation>{t => <strong className='pr-2'>{t('announceDashboard:by')}</strong>}</Translation>
                                    {newsPins.creator || '-'} </p>
                                    <img  onClick={() => setVisible(false) }
                                    src={process.env.PUBLIC_URL + '/images/banner/Union.png'}
                                    alt="alert" 
                                    style={{width:'20px',position:'absolute',right:'8%',top:'-5%',cursor:'pointer'}}/>
                        </div>
                       </div>
         </Modal>
        }
 
      </div>
      </>
     )

}

export default PopupBanner;