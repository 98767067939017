import React, { Component} from 'react';
import Swal from 'sweetalert2';
import Apising from '../../../api/sing'
import _ from "lodash";
import i18n from 'i18next'
import { Translation } from 'react-i18next';
import i18next from 'i18next';


class Modalcancle extends Component {
    constructor(props) {
        super(props); 
        this.state ={
            redirect: false,
            detailCancle: ""
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e){
        this.setState({[e.target.name] : e.target.value})
    }

    onSubmit(e){
        e.preventDefault(); 

        let dataCancle = {
            id: this.props.dataID,
            detail: this.state.detailCancle
        }  
   
        Apising.patchrepairs_room_view(dataCancle).then((res)=> {
            Swal.fire(i18n.t("Allaction:successcompleate"), "", "success").then(()=> {
                window.location.reload()
            })
        },()=> Swal.fire(i18next.t("Allaction:Unsuccessful "),i18next.t("settingToolandConnect:Unable to connect to the API at this time."),"error"))
        
    }



    render() { 

        return (
           <Translation>
               { t=>
                   <div className={"modal fade bd-example-modal-lg cancleFixRequest"+this.props.indexID} tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog  " role="document" style={{ marginTop: "15%" }}>
                        <div className="modal-content">
                            <form onSubmit={this.onSubmit}>
                                <div className="modal-header" style={{ borderBottom: '0', padding: "15px 30px" }}>
                                    <h2 className="modal-title text-center" style={{ fontSize: "24px" }}>{t("fixRequestRejectModal:Please specify the reason for rejecting this list")}</h2>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ padding: "10px 30px", marginTop: "-10px" }}>
                                    <textarea  onKeyPress={this.onChange} onChange={this.onChange} name='detailCancle'
                                        defaultValue={this.state.detailCancle} className='form-control' 
                                        style={{ resize: 'none', height: "121px" }}
                                    /> 
                                </div>
                                {
                                       _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "repairs_room_delete" }) &&
                                       <div className="modal-footer" style={{ borderTop: '0', justifyContent: 'center' }}>
                                         <button type="submit" className="btn btn-primary" style={{ width: "419px" }} >{t("fixRequestRejectModal:Reject")}</button>
                                       </div>
                                }
                               

                            </form>
                        </div>
                    </div>
                 </div>
               }
                

           </Translation>
        )
    }
}
 

export default Modalcancle