import React, { Component } from 'react'
import _ from "lodash"
import numberWithComma from '../../../libs/numberWithComma'
import "../style/manageOrder.scss"
import i18next from 'i18next'
import { Translation } from 'react-i18next'

export default class formSummarize extends Component {

  constructor(props) {
    super(props)

    this.state = {
      sumItemsTotal: "",
      sumVat: "",
      sumTotalAmount: ""
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.procurementForm.procurementList !== prevProps.procurementForm.procurementList) {
      this.calSummary()
    }
  }

  calSummary() {
    let sumItemsTotal = 0
    let sumVat = 0
    let sumTotalAmount = 0

    _.forEach(this.props.procurementForm.procurementList, (procurement) => {
      //let totalItem = procurement.node.price * procurement.node.unitItems;
      let totalItem = procurement.node.preTaxAmount
      sumItemsTotal += totalItem
      
      sumVat += totalItem * procurement.node.vat / 100
      sumVat = Math.round((sumVat+ Number.EPSILON) * 100) / 100;
    })

    sumTotalAmount = sumItemsTotal + sumVat

    this.setState({ 
      sumItemsTotal: parseFloat(sumItemsTotal).toFixed(2),
      sumVat: parseFloat(sumVat).toFixed(2),
      sumTotalAmount: parseFloat(sumTotalAmount).toFixed(2)
     })
  }

  render() {
    return (<Translation>{t =>
      <div id="formSummarize">
        <div className="row">
          <div className="col">
            <h4>{i18next.t("formSummarize:Summary")}</h4>
            <div className="lineBottom mb-5" />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <h6>{i18next.t("formSummarize:Note")}</h6>
            <div className="input-group">
              <textarea
                disabled={true}
                className="bodyTextArea-disibled"
                value={this.props.procurementForm.remark}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="frameSummary">
              <div className="textSummary">
                <h5 className="headItems">{i18next.t("formSummarize:Amount")}</h5>
                <p className="detailItems">{numberWithComma(this.state.sumItemsTotal)}</p>
              </div>
              <div className="textSummary">
                <h5 className="headItems">{i18next.t("formSummarize:Value Added Tax (VAT)")}</h5>
                <p className="detailItems">{numberWithComma(this.state.sumVat || "0.00")}</p>
              </div>
              <div className="lineBetweenSummary" />
              <div className="textSummary">
                <h5 className="headSummary">{i18next.t("formSummarize:Total Amount (THB)")}</h5>
                <p className="detailSummary">{numberWithComma(this.state.sumTotalAmount)}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    }</Translation>
    )
  }
}
