import React, {Component} from 'react';
import ReactQuill from 'react-quill';

class ReactQuillAdapter extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange(value) {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: value,
            }
        });
    }

    render() {
        return (
            <ReactQuill 
            style={this.props.readOnly?{backgroundColor:'#EAECEF'}:{}} 
            readOnly = {this.props.readOnly} 
            value={this.props.value} 
            onChange={this.handleInputChange}/>
        );
    }

}

export default ReactQuillAdapter;