import getApiRoot from "../../../libs/getAPIRoot";
import axios from "axios";

async function postFilesCrossBilling(data){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };

    return axios.post(`${getApiRoot()}scb/scbbillpayment`, data, {
        headers: header,
    });
}

export default {postFilesCrossBilling}