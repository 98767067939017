import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import thLocale from "date-fns/locale/th";
import _ from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Dropdown } from 'react-bootstrap';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNewBold.ttf',
        italics: 'THSarabunNewItalic.ttf',
        bolditalics: 'THSarabunNewBoldItalic.ttf'
    },
    Sarabun: {
        normal: 'Sarabun-Light.ttf',
        bold: 'Sarabun-Bold.ttf',
        italics: 'Sarabun-Italic.ttf',
        bolditalics: 'Sarabun-BoldItalic.ttf'
    }
}

const ExportSvmGuardManageReportPDF = (state) => {
    const getKeyCardName = (dataKey) => {
        let text = ""
        dataKey.map((keyeCard) => {
            text = keyeCard.node.keyCardActive ? text + " " + keyeCard.node.keyCardCode : text
        })
        return text;
    }

    const generatePdf = () => {
        let startItem = 0
        let dataMapExport = []
        _.map(state.dataExport, (item) => {
            startItem++
            let typeTrackandCar = ""
            switch (item.typeTrackandCar) {
                case "sedan": typeTrackandCar = "รถยนต์นั่งส่วนบุคคล \n ไม่เกิน 7 คน";
                    break;
                case "pickup": typeTrackandCar = "รถยนต์บรรทุก\nส่วนบุคคล";
                    break;
                case "van": typeTrackandCar = "รถยนต์นั่งส่วนบุคคล\nเกิน 7 คน";
                    break;
                case "taxi": typeTrackandCar = "รถแท็กซี่";
                    break;
                case "motorcy": typeTrackandCar = "รถจักรยานยนต์\nไม่เกิน 150 cc";
                    break;
                case "motorcycle": typeTrackandCar = "รถจักรยานยนต์\n 150 cc ขึ้นไป";
                    break;
                default: typeTrackandCar = "รถยนต์นั่งส่วนบุคคล\nไม่เกิน 7 คน";
                    break;
            }
            return dataMapExport.push(
                [
                    { text: item.codeVisit, style: 'tdmargin' },
                    { text: item.dateUsingSt + "\n" + item.timeUsingSt, style: 'td' },
                    { text: item.outDate ? format(new Date(item.outDate), "DD/MM/YYYY - HH:mm") : '-', style: 'td' },
                    { text: item.fullnameVisit ? item.fullnameVisit : "-", style: 'td' },
                    { text: item.idCard ? item.idCard : "-", style: 'tdmargin' },
                    { text: item.carRegistration + "\n" + item.province, style: 'tdmargin' },
                    // { text: item.carRegistration, style: 'tdmargin' },
                    // { text: item.province, style: 'tdmargin' },
                    { text: typeTrackandCar, style: item.typeTrackandCar === "taxi" ? 'tdmargin' : 'td' },
                    { text: item.brandCar ? item.brandCar : "-", style: 'td' },
                    { text: item.colorCar ? item.colorCar : "-", style: 'td' },
                    { text: item.codeSticker ? item.codeSticker : "-", style: 'td' },
                    {
                        text: item.typePerson === "tenant" ? "ผู้เช่า/ลูกบ้าน" :
                            item.typePerson === "contact" ? "ติดต่อสำนักงานขาย" :
                                item.typePerson === "appointment" ? "ผู้ติดต่อนัดหมายล่วงหน้า\n(ผู้เยี่ยมเยือน)" : "บุคคลทั่วไป", style: 'td'
                    },
                    { text: item.reason ? item.reason : "-", style: 'td' },
                    { text: item.calPacking ? "คิดค่าที่จอด" : "ไม่คิดค่าที่จอด", style: 'tdmargin' },
                    { text: item.typeRole === "temp" ? "ครั้งเดียว" : "หลายครั้ง", style: 'tdmargin' },
                    { text: item.tool, style: 'tdmargin' },
                    { text: item.ceateBy, style: 'td' },
                    {
                        text: item.status === "coming" ? "กำลังเข้า" :
                            item.status === "over" ? "จอดเกินเวลา" :
                                item.status === "cancel" ? "ยกเลิก" :
                                    item.status === "park" ? "อยู่ในที่จอด" : "สำเร็จ", style: 'tdmargin'
                    },
                    // { text: item.dateUsingEn + "\n" + item.timeUsingEn, style: 'tdmargin' },
                ]
            )
        })

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape', //portrait ตั้ง  || landscape นอน
            pageMargins: [20, 20, 20, 20],
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [0, 0],
                        },
                        {
                            text: `ผู้พิมพ์ ${state.namePrint} วันที่พิมพ์ ${format(new Date(), "DD/MM/YYYY")}`,
                            alignment: 'right',
                            fontSize: 10,
                            margin: [20, 0],
                        },
                    ],
                };
            },
            content: [
                { text: state.project_name, style: 'header' },
                { text: 'รายงานผู้มาติดต่อ', style: 'header' },
                { text: format(new Date(), "ณ วันที่ DD MMMM YYYY", { locale: thLocale }), style: 'header' },
                { text: "", margin: [0, 0, 0, 10] },
                {
                    table: {
                        headerRows: 1,
                        widths: [50, 40, 40, 40, 45, 45, 50, 30, 30, 35, 50, 50, 35, 30, 30, 40, 30],
                        dontBreakRows: true, // ใช้สำหรับ ให้แถวสุดท้ายที่ตกบรรทัดให้ ขึ้นหน้าใหม่แทน
                        body: [
                            [
                                { text: 'เลขที่', style: 'thMargin' },
                                { text: 'วันที่-เวลาเข้าใช้', style: 'thMargin' },
                                { text: 'วันที่-เวลารถออก', style: 'thMargin' },
                                { text: 'ชื่อผู้มาติดต่อ', style: 'thMargin' },
                                { text: 'เลขประจำตัว\nประชาชน', style: 'th' },
                                { text: 'เลขทะเบียน', style: 'thMargin' },
                                // { text: 'จังหวัด', style: 'th' },
                                { text: 'ประเภทรถ', style: 'thMargin' },
                                { text: 'ยี่ห้อรถ', style: 'thMargin' },
                                { text: 'สีรถ', style: 'thMargin' },
                                { text: 'เลขสติ๊กเกอร์', style: 'thMargin' },
                                { text: 'ประเภทบุคคล', style: 'thMargin' },
                                { text: 'วัตถุประสงค์', style: 'thMargin' },
                                { text: 'การคิดค่า\nที่จอด', style: 'th' },
                                { text: 'ประเภท\nสิทธิ์', style: 'th' },
                                { text: 'เครื่องมือ', style: 'thMargin' },
                                { text: 'ออกบัตรโดย', style: 'thMargin' },
                                { text: 'สถานะ', style: 'thMargin' },
                                // { text: 'วันที่สิ้นสุดอนุญาต\nใช้บัตรจอดรถ', style: 'th' },

                            ],
                            ...dataMapExport
                        ],
                    },
                    layout: 'auto',
                },
            ],
            defaultStyle: {
                font: 'Sarabun'
            },
            styles: {
                header: {
                    fontSize: 10,
                    bold: true
                },
                th: {
                    alignment: 'center', bold: true, fontSize: 6
                },
                thMargin: {
                    alignment: 'center', bold: true, fontSize: 6,
                    margin: [0, 5, 0, 0]
                },
                td: {
                    alignment: 'center', fontSize: 6
                },
                tdmargin: {
                    alignment: 'center', fontSize: 6,
                    margin: [0, 4, 0, 0]
                }

            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBlob((blob) => {
            let preview = URL.createObjectURL(blob)
            window.open(preview)
        });
    };

    return (
        <Link
            to="route"
            target="blank"
            onClick={(event) => {
                event.preventDefault();
                generatePdf();
            }}>
            <Dropdown.Item>
                PDF
            </Dropdown.Item>
        </Link>
    );


}

export default ExportSvmGuardManageReportPDF;