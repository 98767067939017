/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialConveyance = "ALREADY_TRANSFERRED" | "NOT_TRANSFERRED" | "%future added value";
export type selectContactReceivableQueryVariables = {|
  search?: ?string,
  contact_type?: ?string,
  payment_type?: ?string,
  conveyance?: ?string,
  receivable_filter?: ?boolean,
|};
export type selectContactReceivableQueryResponse = {|
  +contactViewer: ?{|
    +id: string,
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
          +residential: ?{|
            +id: string,
            +conveyance: ResidentialConveyance,
          |},
          +ownerName: ?string,
          +mailingAddress: string,
          +mailingDistrict: string,
          +mailingCity: string,
          +mailingProvince: string,
          +mailingPostalCode: string,
          +renterName: ?string,
          +renterAddress: ?string,
          +renterDistrict: string,
          +renterCity: string,
          +renterProvince: string,
          +renterPostalCode: string,
          +agentName: ?string,
          +agentAddress: ?string,
          +agentDistrict: string,
          +agentCity: string,
          +agentProvince: string,
          +agentPostalCode: string,
          +allAmountNotPaid: ?number,
        |}
      |}>
    |},
  |}
|};
export type selectContactReceivableQuery = {|
  variables: selectContactReceivableQueryVariables,
  response: selectContactReceivableQueryResponse,
|};
*/


/*
query selectContactReceivableQuery(
  $search: String
  $contact_type: String
  $payment_type: String
  $conveyance: String
  $receivable_filter: Boolean
) {
  contactViewer {
    id
    allContact(search: $search, typeOfContact: $contact_type, typeOfPayment: $payment_type, conveyance: $conveyance, isActive: true, receivableFilter: $receivable_filter) {
      edges {
        node {
          id
          refNumber
          name
          firstName
          lastName
          residential {
            id
            conveyance
          }
          ownerName
          mailingAddress
          mailingDistrict
          mailingCity
          mailingProvince
          mailingPostalCode
          renterName
          renterAddress
          renterDistrict
          renterCity
          renterProvince
          renterPostalCode
          agentName
          agentAddress
          agentDistrict
          agentCity
          agentProvince
          agentPostalCode
          allAmountNotPaid
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact_type"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conveyance"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payment_type"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "receivable_filter"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactViewer",
    "kind": "LinkedField",
    "name": "contactViewer",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "conveyance",
            "variableName": "conveyance"
          },
          {
            "kind": "Literal",
            "name": "isActive",
            "value": true
          },
          {
            "kind": "Variable",
            "name": "receivableFilter",
            "variableName": "receivable_filter"
          },
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "search"
          },
          {
            "kind": "Variable",
            "name": "typeOfContact",
            "variableName": "contact_type"
          },
          {
            "kind": "Variable",
            "name": "typeOfPayment",
            "variableName": "payment_type"
          }
        ],
        "concreteType": "ContactNodeConnection",
        "kind": "LinkedField",
        "name": "allContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "conveyance",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownerName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allAmountNotPaid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectContactReceivableQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "selectContactReceivableQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "7ae063429508e6ee2ee52148d02a9f4d",
    "id": null,
    "metadata": {},
    "name": "selectContactReceivableQuery",
    "operationKind": "query",
    "text": "query selectContactReceivableQuery(\n  $search: String\n  $contact_type: String\n  $payment_type: String\n  $conveyance: String\n  $receivable_filter: Boolean\n) {\n  contactViewer {\n    id\n    allContact(search: $search, typeOfContact: $contact_type, typeOfPayment: $payment_type, conveyance: $conveyance, isActive: true, receivableFilter: $receivable_filter) {\n      edges {\n        node {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n          residential {\n            id\n            conveyance\n          }\n          ownerName\n          mailingAddress\n          mailingDistrict\n          mailingCity\n          mailingProvince\n          mailingPostalCode\n          renterName\n          renterAddress\n          renterDistrict\n          renterCity\n          renterProvince\n          renterPostalCode\n          agentName\n          agentAddress\n          agentDistrict\n          agentCity\n          agentProvince\n          agentPostalCode\n          allAmountNotPaid\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb95612ec49149d16e009bc44ee05f67';

module.exports = node;
