import '../../report.scss';
import React  from 'react';
import _ from "lodash";
import { format, lastDayOfMonth } from "date-fns";
import thLocale from "date-fns/locale/th";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import { summaryReportOutstandingReceivable } from './query/summaryReportOutstandingReceivable';
import { Dropdown } from "react-bootstrap";
import numberWithComma from "../../../../libs/numberWithCommaReport";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const query = graphql`
  query bankDepositExcelExportsQuery($lastDate: Date) {
    selfProject {
      name
  }
    allBankAccountReport (lastDate : $lastDate ) {
      edges{
        node{
            bankName
            branch
            accountType
            accountNumber
            summaryBank
            bankUnit{
                edges{
                    node{
                         issuedDate
                         dueDate
                         price
                    }
                }
          }
        }
      }
    }
    summaryBankReportPurchaseRecordGroup(lastDate:$lastDate){
      numRow
      sumBalance
    }
  }
`;

const BankDepositExcelExports = ({ date }) => {
  const getData = async () => {
    let residential
    let supplier
    await summaryReportOutstandingReceivable({ dueDate: format(date, "YYYY-MM-DD") || new Date(), typeOfContact: 'residential' }, (data) => residential = data.summaryReportOutstandingReceivable)
    await summaryReportOutstandingReceivable({ dueDate: format(date, "YYYY-MM-DD") || new Date(), typeOfContact: 'supplier' }, (data) => supplier = data.summaryReportOutstandingReceivable)
    await fetchQuery(environment, query, { lastDate: format(date, "YYYY-MM-DD") || new Date() }).then((data) => {
      let sum = _.sumBy(data.allBankAccountReport.edges, function (o) { return parseFloat(JSON.parse(o.node.summaryBank).balance) });
      downloadExcel(data.allBankAccountReport.edges, residential, supplier, data.summaryBankReportPurchaseRecordGroup, sum, data.selfProject.name)
    });
  }

  const handleSummary = (sumBalance, summary_balance) => ((parseFloat(sumBalance) / parseFloat(summary_balance)) * 100)

  const downloadExcel = async (all_bank, summary_report_residential, summary_report_supplier, summary_bank_report, summary_balance, project_name) => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');
    // setWidthcolumns
    let columns = [{ width: 10 }, { width: 30 }, { width: 30 }, { width: 30 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]
    // setBorder
    let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    let fontSizes = { size: 11.5 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let textRight = { vertical: 'middle', horizontal: 'right' };
    let textLeft = { vertical: 'middle', horizontal: 'left' };
    let fontBold = { size: 11.5, bold: true };

    worksheet.getCell('A1').value = project_name
    worksheet.getCell('A1').font = { size: 11.5, bold: true };
    worksheet.getCell('A2').value = "รายงานเงินฝากธนาคาร"
    worksheet.getCell('A2').font = { size: 11.5, bold: true };
    worksheet.getCell('A3').value = `ประจำเดือน ${format(date, "  MMMM YYYY ", { locale: thLocale, })}`
    worksheet.getCell('A3').font = { size: 11.5, bold: true };
    worksheet.addRow();
    var headerRow = worksheet.addRow();
    worksheet.getRow(4).font = { bold: true };
    let headers = ['ลำดับ', 'สถาบันการเงิน', 'สาขา', 'ประเภทบัญชี', 'เลขที่บัญชี', 'ยอดยกมา', 'เงินรับ', 'เงินจ่าย', 'จำนวนเงินคงเหลือ']
    for (let i = 0; i < headers.length; i++) {
      let cell = headerRow.getCell(i + 1);
      cell.value = headers[i];
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
    }
    let data = { data: [], style: [] }

    // "deposit_interest" : 'บัญชีเงินฝากประจำ',
    //         "current_account": "บัญชีกระแส",
    //         "saving_account": "บัญชีออมทรัพย์",
    //         "fixed_deposit": "บัญชีประจำ",
    //         "saving_deposit": "บัญชีฝากเผื่อเรียก",

    const accountType = (key) => {
      switch (key) {
        case 'SAVING_ACCOUNT':
          return 'บัญชีออมทรัพย์'
        case 'CURRENT_ACCOUNT':
          return 'บัญชีกระแส'
        case 'FIXED_DEPOSIT':
          return 'บัญชีประจำ'
        case 'SAVING_DEPOSIT':
          return 'บัญชีฝากเผื่อเรียก'
        case 'DEPOSIT_INTEREST':
          return 'บัญชีเงินฝากประจำ'

        default:
          break;
      }

    }
    await all_bank.map(async (item, index) => {
      let summary_bank = await JSON.parse(_.get(item, "node.summaryBank"))
      var dataRow = worksheet.addRow();
      data = {
        data: [index + 1, _.get(item, "node.bankName"), _.get(item, "node.branch"), accountType(_.get(item, "node.accountType")), _.get(item, "node.accountNumber"),
        numberWithComma(summary_bank.previous_balance) !== '0.00' ? numberWithComma(summary_bank.previous_balance) : '-',
        numberWithComma(summary_bank.total_debit) !== '0.00' ? numberWithComma(summary_bank.total_debit) : '-',
        numberWithComma(summary_bank.total_credit) !== '0.00' ? numberWithComma(summary_bank.total_credit) : '-',
        numberWithComma(summary_bank.balance) !== '0.00' ? numberWithComma(summary_bank.balance) : '-'],
        style: [{ alignment: textCenter }, { alignment: textLeft }, { alignment: textLeft }, { alignment: textLeft }, { alignment: textCenter },
        { alignment: textRight }, { alignment: textRight }, { alignment: textRight }, { alignment: textRight }]
      }
      headers.map((header, index_header) => {
        let cell = dataRow.getCell(index_header + 1);
        cell.value = data.data[index_header];
        cell.alignment = data.style[index_header]?.alignment;
        cell.border = borders
        cell.font = fontSizes

      })
      if (all_bank.length === index + 1) {
        // let cell = dataRow.getCell(index_header + 1);
        const rowCount = worksheet.rowCount + 1;
        worksheet.mergeCells(`A${rowCount}:H${rowCount}`);
        worksheet.getCell(`A${rowCount}`).value = "รวมจำนวนเงิน";
        worksheet.getCell(`A${rowCount}`).border = borders
        worksheet.getCell(`A${rowCount}`).alignment = textRight
        worksheet.getCell(`A${rowCount}`).font = fontBold
        worksheet.getCell(`I${rowCount}`).value = numberWithComma(summary_balance);
        worksheet.getCell(`I${rowCount}`).border = borders
        worksheet.getCell(`I${rowCount}`).alignment = textRight
        worksheet.getCell(`I${rowCount}`).font = fontBold

      }
    })
    worksheet.addRow();
    var headerRow = worksheet.addRow();
    const rowCount = worksheet.rowCount;
    worksheet.mergeCells(`A${rowCount}:I${rowCount}`);
    // headerRow.getCell();
    let cell = headerRow.getCell(1);
    // worksheet.mergeCells(`A${rowCount}:H${rowCount}`);
    cell.value = `สรุปยอดลูกหนี้และเจ้าหนี้คงค้าง ณ วันที่ ${format(lastDayOfMonth(date), " DD MMMM YYYY ", { locale: thLocale })}`
    cell.font = fontBold

    worksheet.addRow();
    var headerRow = worksheet.addRow();
    let header2 = ['ประเภทลูกหนี้', 'จำนวนราย', 'จำนวนเงิน', 'คิดเป็น% จำนวนเงินคงเหลือ']
    for (let i = 0; i < header2.length; i++) {
      let cell = headerRow.getCell(i + 1);
      cell.value = header2[i];
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
    }
    let pre_data = [
      ['เจ้าของร่วม/สมาชิก', numberWithComma(_.get(summary_report_residential, "numRow")), numberWithComma(_.get(summary_report_residential, "sumBalance")), numberWithComma(handleSummary(_.get(summary_report_residential, "sumBalance"), summary_balance))],
      ['ลูกหนี้', numberWithComma(_.get(summary_report_supplier, "numRow")), numberWithComma(_.get(summary_report_supplier, "sumBalance")), numberWithComma(handleSummary(_.get(summary_report_supplier, "sumBalance"), summary_balance))]]
    for (let index = 0; index < 2; index++) {
      var dataRow = worksheet.addRow();
      data = {
        data: pre_data[index],
        style: [{ alignment: textLeft }, { alignment: textCenter }, { alignment: textCenter }, { alignment: textCenter }]
      }
      header2.map((header, index_header) => {
        let cell = dataRow.getCell(index_header + 1);
        cell.value = data.data[index_header];
        cell.alignment = data.style[index_header]?.alignment;
        cell.border = borders
        cell.font = fontSizes
      })
      if (pre_data.length === index + 1) {
        const rowCount = worksheet.rowCount + 1;
        worksheet.mergeCells(`A${rowCount}:B${rowCount}`);
        worksheet.getCell(`A${rowCount}`).value = "รวมจำนวนเงิน";
        worksheet.getCell(`A${rowCount}`).border = borders
        worksheet.getCell(`A${rowCount}`).alignment = textRight
        worksheet.getCell(`A${rowCount}`).font = fontBold

        worksheet.getCell(`C${rowCount}`).value = numberWithComma(_.get(summary_report_supplier, "sumBalance") + _.get(summary_report_residential, "sumBalance"));
        worksheet.getCell(`C${rowCount}`).border = borders
        worksheet.getCell(`C${rowCount}`).alignment = textCenter
        worksheet.getCell(`C${rowCount}`).font = fontBold

        worksheet.getCell(`D${rowCount}`).value = (numberWithComma(handleSummary(_.get(summary_report_residential, "sumBalance"), summary_balance) + handleSummary(_.get(summary_report_supplier, "sumBalance"), summary_balance)));
        worksheet.getCell(`D${rowCount}`).border = borders
        worksheet.getCell(`D${rowCount}`).alignment = textCenter
        worksheet.getCell(`D${rowCount}`).font = fontBold
      }
    }
    worksheet.addRow();
    var headerRow = worksheet.addRow();
    let header3 = ['ประเภทเจ้าหนี้', 'จำนวนราย', 'จำนวนเงิน', 'คิดเป็น% จำนวนเงินคงเหลือ']
    for (let i = 0; i < header2.length; i++) {
      let cell = headerRow.getCell(i + 1);
      cell.value = header3[i];
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
    }
    for (let index = 0; index < 1; index++) {
      var dataRow = worksheet.addRow();
      data = {
        data: ['เจ้าหนี้', numberWithComma(_.get(summary_bank_report, "numRow")), numberWithComma(_.get(summary_bank_report, "sumBalance")), numberWithComma(handleSummary(_.get(summary_bank_report, "sumBalance"), summary_balance))],
        style: [{ alignment: textLeft }, { alignment: textCenter }, { alignment: textCenter }, { alignment: textCenter }]
      }
      header3.map((header, index_header) => {
        let cell = dataRow.getCell(index_header + 1);
        cell.value = data.data[index_header];
        cell.alignment = data.style[index_header]?.alignment;
        cell.border = borders
        cell.font = fontSizes
      })
      const rowCount = worksheet.rowCount + 1;
      worksheet.mergeCells(`A${rowCount}:B${rowCount}`);
      worksheet.getCell(`A${rowCount}`).value = "รวมจำนวนเงิน";
      worksheet.getCell(`A${rowCount}`).border = borders
      worksheet.getCell(`A${rowCount}`).alignment = textRight
      worksheet.getCell(`A${rowCount}`).font = fontBold

      worksheet.getCell(`C${rowCount}`).value = numberWithComma(_.get(summary_bank_report, "sumBalance"));
      worksheet.getCell(`C${rowCount}`).border = borders
      worksheet.getCell(`C${rowCount}`).alignment = textCenter
      worksheet.getCell(`C${rowCount}`).font = fontBold
      worksheet.getCell(`D${rowCount}`).value = numberWithComma(handleSummary(_.get(summary_bank_report, "sumBalance"), summary_balance));
      worksheet.getCell(`D${rowCount}`).border = borders
      worksheet.getCell(`D${rowCount}`).alignment = textCenter
      worksheet.getCell(`D${rowCount}`).font = fontBold
    }


    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = format(new Date(), 'DD/MM/YYYY', { locale: thLocale })
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = nameFile;
        a.click();
      });
  }
  return (
    <Dropdown.Item  ><p className="text-black" onClick={getData} >Excel</p></Dropdown.Item>
  );
};

export default BankDepositExcelExports;