/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type selectProjectQueryVariables = {|
  typeOfProject?: ?string,
  search?: ?string,
  order?: ?string,
|};
export type selectProjectQueryResponse = {|
  +allSelectJuristic: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +userOrder: {|
          +id: string,
          +username: string,
          +lastLogin: ?any,
        |},
        +site: {|
          +id: string,
          +domain: string,
          +siteselectProjectManager: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +onlineSite: boolean,
                +onlineApp: boolean,
              |}
            |}>
          |},
          +project: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +nameTh: ?string,
                +nameEn: ?string,
                +typeOfProject: ?ProjectTypeOfProject,
                +logo: ?string,
                +added: any,
              |}
            |}>
          |},
        |},
      |}
    |}>
  |}
|};
export type selectProjectQuery = {|
  variables: selectProjectQueryVariables,
  response: selectProjectQueryResponse,
|};
*/


/*
query selectProjectQuery(
  $typeOfProject: String
  $search: String
  $order: String
) {
  allSelectJuristic(typeOfProject: $typeOfProject, search: $search, order: $order) {
    edges {
      node {
        id
        userOrder {
          id
          username
          lastLogin
        }
        site {
          id
          domain
          siteselectProjectManager {
            edges {
              node {
                onlineSite
                onlineApp
                id
              }
            }
          }
          project {
            edges {
              node {
                id
                nameTh
                nameEn
                typeOfProject
                logo
                added
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfProject"
},
v3 = [
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "typeOfProject",
    "variableName": "typeOfProject"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "userOrder",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastLogin",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onlineSite",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onlineApp",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNodeConnection",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameTh",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameEn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeOfProject",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "added",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SelectJuristicNodeConnection",
        "kind": "LinkedField",
        "name": "allSelectJuristic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SelectJuristicNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SelectJuristicNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteType",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteSelectNodeConnection",
                        "kind": "LinkedField",
                        "name": "siteselectProjectManager",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteSelectNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SiteSelectNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "selectProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SelectJuristicNodeConnection",
        "kind": "LinkedField",
        "name": "allSelectJuristic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SelectJuristicNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SelectJuristicNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteType",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteSelectNodeConnection",
                        "kind": "LinkedField",
                        "name": "siteselectProjectManager",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteSelectNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SiteSelectNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60286690d7863b71b740f3b978b31ac6",
    "id": null,
    "metadata": {},
    "name": "selectProjectQuery",
    "operationKind": "query",
    "text": "query selectProjectQuery(\n  $typeOfProject: String\n  $search: String\n  $order: String\n) {\n  allSelectJuristic(typeOfProject: $typeOfProject, search: $search, order: $order) {\n    edges {\n      node {\n        id\n        userOrder {\n          id\n          username\n          lastLogin\n        }\n        site {\n          id\n          domain\n          siteselectProjectManager {\n            edges {\n              node {\n                onlineSite\n                onlineApp\n                id\n              }\n            }\n          }\n          project {\n            edges {\n              node {\n                id\n                nameTh\n                nameEn\n                typeOfProject\n                logo\n                added\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b54633db0c6ef5ce4e9ea7aed8e03cea';

module.exports = node;
