import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import WithholdingTaxList from './withholdingTaxList';
import Account from "../../../document";

class WithholdingTax extends Component {
    render() {

        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={WithholdingTaxList}/>
                <PrivateRoute path={`${this.props.match.url}/account`} component={Account}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default WithholdingTax;