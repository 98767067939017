import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";
import './style/settingJuristicList.scss'
const _ = require('lodash');


class Navigation extends Component {


    render() {
        return (
            <div className="row" id="navigation-tab">
                <Translation>
                    {t =>
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink to={"/setting/juristic-setting/permission/management/" + this.props.id}>
                                        {t("set_permission:Management")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/setting/juristic-setting/permission/accounting/" + this.props.id}>
                                        {t("set_permission:Accounting and Finance")}
                                    </NavLink>
                                </li>
                                {
                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'silverman_guard'])?.menu_on && 
                                    <li>
                                        <NavLink to={"/setting/juristic-setting/permission/silvermanguard/" + this.props.id}>
                                            {t("set_permission:Siverman guard")}
                                        </NavLink>
                                    </li>
                                }
                               
                            </ul>
                        </div>
                    }
                </Translation>
            </div>
        )
    }
}

export default Navigation;