import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import ModalSumVote from "./modalSumVoteAndResult";

const query = graphql`
    query modalResultVoteQuery($id: ID!, $residential_vote_id: ID) {
        allVote(residentialVote_Survey_Id: $id, residentialVote_Id: $residential_vote_id){
            edges{
                node{
                    id
                    choice{
                        id
                    }
                }
            }
        }
    }
`;

class ModalResultVote extends Component {

    render() {
        return (
            <ModalSumVote survey_id={this.props.survey_id}
                          residential={this.props.residential}
                          type={this.props.type}
                          status={this.props.status}
                          query={query}
                          queryResidentialVoteList={this.props.queryResidentialVoteList}
            />
        );
    }
}

export default ModalResultVote;