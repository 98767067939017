import React from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { commitMutation, QueryRenderer, fetchQuery } from "react-relay";
import Swal from "sweetalert2";
import "./style/settingJuristicList.scss";
import Pagination from "../../libs/newPagination";
import ComponentPagination from "../../libs/componentPagination";
import _ from "lodash";
import i18n from "i18next";
import jwt from 'jwt-decode'
import Loading from "../../libs/loading";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import SeacrhDetail from '../../components/search';
import ExportDeleteUser from './export/server_export_dele_juristic'
import { encode } from "base-64";


const query = graphql`
  query settingJuristicListQuery($search: String, $first: Int, $last: Int) {
    allJuristic(search: $search, first: $first, last: $last) {
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          role
          image
          creator
          added
          adminStatus
          allsiteStatus
          user {
            id
            username
            lastLogin
          }
        }
      }
    }

    allJuristicLog{
    edges {
      node {
        id
        firstName
        lastName
        username
        role
        added
        creator
      }
    }
    }

    allJuristicControlMultipleSite(search: $search) {
      totalCount
      edges {
        node {
          id
          # siteJuristc
          juristic{
            id
            firstName
            lastName
            role
            image
            creator
            added
            adminStatus
            allsiteStatus
            user {
              id
              username
              lastLogin
            }
          }
        }
      }
    }

    checkUserAdmin
    current {
      user {
        id
      }
    }
  }
`;

const mutation = graphql`
  mutation settingJuristicListMutation($input: DeleteJuristicInput!) {
    deleteJuristic(input: $input) {
      ok
    }
  }
`;

class SettingJuristicList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.search = "";
    this.state.search_input = "";
    this.state.reQuery = false;
    this.state.checkUserAdmin = false;
    this.deleteJuristic = this.deleteJuristic.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    fetchQuery(environment, query, {
      search: this.state.search,
      first: this.state.first,
      last: this.state.last,
      reQuery: this.state.reQuery,
    }).then((data) => {
      if (data) {
        this.setState({ checkUserAdmin: data.checkUserAdmin });
      }
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleChange(e);
    }
  }

  deleteJuristic(id) {
    Swal.fire({
      title: i18n.t("juristicManager1:Are you sure to remove this data")+"?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18n.t("premission:Yes"),
      cancelButtonText: i18n.t("premission:No"),
    }).then((result) => {
      if (result.value) {
        let variables = {
          input: {
            id: id,
          },
        };
        commitMutation(environment, {
          mutation,
          variables,
          onCompleted: (response) => {
            if (response.deleteJuristic.ok) {
              Swal.fire(i18n.t("premission:Remove successfully!"), "", "success").then(() => {
                this.setState({ reQuery: !this.state.reQuery });
              });
            } else {
              Swal.fire(i18n.t("premission:Remove unsuccessfully!"), "", "warning");
            }
          },
          onError: (err) => {
            Swal.fire("Error!", i18n.t("premission:Please try again"), "warning");
          },
        });
      }
    });
  }

  replaceSiteshare(data) {
    let new_data = data.split(".");
    let new_string_domain = `https:\//${new_data[0]}-admin.${new_data[1]}.${new_data[2]}`
    return new_string_domain
  }

  onChangeSearch = (e) => {
    if (e[0].name === 'search') {
      this.setState({
        search: e[0].value
      })
    }
  }

  render() {
    //ยกเลิก user ตัวเองไม่ได้
    const user = jwt(localStorage.getItem('token'))
    const checkFirstPage = Math.ceil(this.state.first / localStorage.getItem('pageLimit')) - 1;
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>

          <Translation>
            {t =>
              <div className="container-fluid box">
                <div className="col">
                  <ExportDeleteUser/>
                  <BackButtonIcon
                    addLinkChk={this.state.checkUserAdmin ? true : false}
                    addLinkButton={"/setting/juristic-setting/permissions-juristic/"}
                    addLinkText={t("premission:Add job title permissions")}

                    addLinkChk2={this.state.checkUserAdmin ? true : false}
                    addLinkButton2={localStorage.getItem("selPr") === "true" ? "/setting/juristic-setting/form-juristic-muli/" : "/setting/juristic-setting/form-juristic/"}
                    addLinkText2={t("premission:Add staff")}
                    
                    LinkBack={"/setting"}
                    LinkText={t("setting:permission_setting")}
                  />
                </div>

                <div className="col mt-7">
                  <SeacrhDetail
                    search={this.state.search_input}
                    searchBoxId={true}
                    handlePropsChange={this.onChangeSearch}
                  />
                </div>
                <div className="row pl-10 cown-detail">
                  <div className="col-2">
                    <img className="pr-1 pb-1" src={process.env.PUBLIC_URL + "/images/icons/crown-hight-admin.png"} style={{width:'25px'}} />  สัญลักษณ์นี้คือ User สูงสุด
                  </div>
                  <div className="col-3">
                    <img className="pr-1 pb-1" src={process.env.PUBLIC_URL + "/images/icons/crown-allsite.png"} style={{width:'25px'}} />สัญลักษณ์นี้คือ User สูงสุดที่สามารถเข้าถึงได้หลายโครงการ
                  </div>
                </div>
                <div className="content-inner">
                  <div className="row mt-3">
                    {/* <div className="col-2">
                      <ListGroupSetting page={"permission_setting"} />
                    </div> */}
                    <div className="col-12">
                      <QueryRenderer
                        environment={environment}
                        query={query}
                        cacheConfig={{ use_cache: false }}
                        variables={{
                          search: this.state.search,
                          first: this.state.first,
                          last: this.state.last,
                          reQuery: this.state.reQuery,
                        }}

                        render={({ error, props }) => {
                          if (error) {
                            return (
                              <div className="alert alert-danger" role="alert">
                                {error.message}
                              </div>
                            );
                          } else if (props) {
                            return (
                              <React.Fragment>
                                <div className="card fade-up mr-3">
                                  <div className="table-responsive">
                                    <table className="table table-hover">
                                      <thead className="thead-light">
                                        <tr>
                                          <th />
                                          <th>{t("premission:Name - Surname")}</th>
                                          <th>{t("premission:Position")}</th>
                                          <th>ID </th>
                                          <th>{t("premission:Created By")}</th>
                                          <th>{t("premission:Lastest online")}</th>
                                          <th />
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {checkFirstPage === 0 && props.allJuristicControlMultipleSite.edges.map((juristic_control_multiple_site) => (
                                          <tr className="show" key={juristic_control_multiple_site.node.id} >
                                            <td width="120" className="text-center">
                                              {juristic_control_multiple_site.node.juristic.image ? (
                                                <div
                                                  className="rounded-circle avatar-img juristic profile-img"
                                                  style={{
                                                    backgroundImage: `url(${juristic_control_multiple_site.node.juristic.image})`,
                                                  }}
                                                ></div>
                                              ) : (
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/profile-upload-icon.png"
                                                  }
                                                  className="rounded-circle avatar-img juristic profile-img"
                                                  alt="juristic-profile"
                                                />
                                              )}
                                            </td>
                                            <td>
                                              {juristic_control_multiple_site.node.juristic.firstName}{" "}
                                              {juristic_control_multiple_site.node.juristic.lastName}
                                             
                                            </td>
                                            <td>{juristic_control_multiple_site.node.juristic.role}</td>
                                            <td>
                                              {juristic_control_multiple_site.node.juristic.user.username} 
                                              {juristic_control_multiple_site.node.allsiteStatus ? 
                                              <>
                                                <div className="tooltipImg" style={{top:'-3px'}}>
                                                <img src={process.env.PUBLIC_URL + "/images/icons/crown-allsite.png"} 
                                                style={{width:'20px'}}
                                                alt="question" className="question ml-2"/>
                                                <span className="tooltiptext">
                                                  กรุณาพิจารณาผู้ถือและรักษารหัสผ่านอย่างเป็นความลับ
                                                </span>
                                                </div>
                                              </> :''}
                                            </td>
                                            <td width="180">
                                              {juristic_control_multiple_site.node.juristic.user.lastLogin
                                                ? format(
                                                  juristic_control_multiple_site.node.juristic.user.lastLogin,
                                                  "DD/MM/YYYY - HH:mm น.",
                                                  { locale: thLocale }
                                                )
                                                : "-"}
                                            </td>
                                            <td width="180" className="text-danger">
                                              {
                                                <a href={this.replaceSiteshare(juristic_control_multiple_site.node.siteJuristc)}
                                                  target={"_blank"}
                                                >
                                                  {"Share"}
                                                </a>
                                              }
                                            </td>
                                          </tr>
                                        ))}


                                        {props.allJuristic.edges.map((juristic) => (
                                          <tr
                                            className="show"
                                            key={juristic.node.id}
                                          >
                                            <td width="120" className="text-center">
                                              {juristic.node.image ? (
                                                <div
                                                  className="rounded-circle avatar-img juristic profile-img"
                                                  style={{
                                                    backgroundImage: `url(${juristic.node.image})`,
                                                  }}
                                                ></div>
                                              ) : (
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/profile-upload-icon.png"
                                                  }
                                                  className="rounded-circle avatar-img juristic profile-img"
                                                  alt="juristic-profile"
                                                />
                                              )}
                                            </td>
                                            <td>
                                              {juristic.node.firstName}{" "}
                                              {juristic.node.lastName}
                                            </td>
                                            <td>{juristic.node.role}</td>
                                            <td>{juristic.node.user.username}
                                               {juristic.node.adminStatus === true && juristic.node.allsiteStatus === false ? 
                                               <>
                                                <div className="tooltipImg" style={{top:'-3px'}}>
                                                  <img src={process.env.PUBLIC_URL + "/images/icons/crown-hight-admin.png"} 
                                                  style={{width:'20px'}}
                                                  alt="question" className="question ml-2"/>
                                                  <span className="tooltiptext">
                                                    สัญลักษณ์นี้คือ User สูงสุด
                                                  </span>
                                                </div>
                                               </>
                                               : ''}
                                              {juristic.node.allsiteStatus === true && juristic.node.adminStatus === false ? 
                                               <>
                                                <div className="tooltipImg" style={{top:'-3px'}}>
                                                  <img src={process.env.PUBLIC_URL + "/images/icons/crown-allsite.png"} 
                                                  style={{width:'20px'}}
                                                  alt="question" className="question ml-2"/>
                                                  <span className="tooltiptext">
                                                    สัญลักษณ์นี้คือ User สูงสุดที่สามารถเข้าถึงได้หลายโครงการ
                                                  </span>
                                                </div>
                                               </>
                                               : ''}
                                               {juristic.node.allsiteStatus === true && juristic.node.adminStatus === true ? 
                                               <>
                                                <div className="tooltipImg" style={{top:'-3px'}}>
                                                  <img src={process.env.PUBLIC_URL + "/images/icons/crown-allsite.png"} 
                                                  style={{width:'20px'}}
                                                  alt="question" className="question ml-2"/>
                                                  <span className="tooltiptext">
                                                    สัญลักษณ์นี้คือ User สูงสุดที่สามารถเข้าถึงได้หลายโครงการ
                                                  </span>
                                                </div>
                                               </>
                                               : ''}

                                            </td>
                                            <td>{juristic.node.creator ? juristic.node.creator :'-'} {juristic.node.added
                                                ? format(
                                                  juristic.node.added                                                  ,
                                                  "DD/MM/YYYY - HH:mm น.",
                                                  { locale: thLocale }
                                                )
                                                : "-"}</td>
                                            <td width="180">
                                              {juristic.node.user.lastLogin
                                                ? format(
                                                  juristic.node.user.lastLogin,
                                                  "DD/MM/YYYY - HH:mm น.",
                                                  { locale: thLocale }
                                                )
                                                : "-"}
                                            </td>
                                            <td width="180">
                                              {(props.checkUserAdmin ||
                                                props.current.user.id ===
                                                juristic.node.user.id) && (
                                                  <React.Fragment>
                                                    <button
                                                      className="btn show-icon"
                                                      id="dropdownMenuButton"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img
                                                        src={
                                                          process.env.PUBLIC_URL +
                                                          "/images/icons/choice.png"
                                                        }
                                                        alt="choice"
                                                      />
                                                    </button>
                                                    <div
                                                      className="dropdown-menu"
                                                      aria-labelledby="dropdownMenuButton"
                                                    >
                                                      {props.checkUserAdmin && (
                                                        <Link
                                                          to={
                                                            "/setting/juristic-setting/permission/management/" +
                                                            juristic.node.id
                                                          }
                                                        >
                                                          <span className="dropdown-item cursor">
                                                            {t("premission:Access rights setting")}
                                                          </span>
                                                        </Link>
                                                      )}
                                                      {(props.current.user.id ===
                                                        juristic.node.user.id ||
                                                        _.some(
                                                          JSON.parse(
                                                            localStorage.getItem(
                                                              "permission_list"
                                                            )
                                                          ),
                                                          {
                                                            codename:
                                                              "project_juristic_edit",
                                                          }
                                                        )) && (
                                                          //เช็คว่า UserName ไหน ที่มีหลายโครงการ "-order" ให้วิ่งไปอีกไฟล์ที่แก้ แบบหลายโครงการ ได้ ถ้าไม่มีก็วิ่ง เปิดไฟล์เก่า
                                                          <Link
                                                            to={
                                                              `/setting/juristic-setting/form-juristic${localStorage.getItem("selPr") === "true" &&
                                                                juristic.node.user.username.includes("-order") ? "-muli/" +
                                                              encode(`UserNode:${juristic.node.user.id}`) : "/" + juristic.node.id}`
                                                            }
                                                          >
                                                            <span className="dropdown-item cursor">
                                                              {t("premission:Edit")}
                                                            </span>
                                                          </Link>
                                                        )}

                                                      {user.username !== juristic.node.user.username && _.some(JSON.parse(localStorage.getItem("permission_list")),
                                                        { codename: "project_juristic_delete", }) && (
                                                          <span
                                                            className="dropdown-item cursor"
                                                            onClick={() => this.deleteJuristic(juristic.node.id)}
                                                          >
                                                            {t("premission:Remove")}
                                                          </span>
                                                        )}
                                                    </div>
                                                  </React.Fragment>
                                                )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="row">
                                  <Pagination
                                    changePage={this.changePage}
                                    first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.allJuristic.totalCount + props.allJuristicControlMultipleSite.totalCount}
                                  />
                                </div>
                              </React.Fragment>
                            );
                          }
                          return <Loading />
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default SettingJuristicList;
