import React from "react";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import PettyCashReportPaymentTable from "./pettyCashReportPaymentTable"
import PettyCashReportRecordTable from "./pettyCashReportRecordTable";
import numberWithComma from "../../../../libs/numberWithComma";
import { formatDateISO } from "../../../../utils";
import _ from "lodash";
import i18next from "i18next";

class PettyCashReportTable extends React.Component {

    getUnique(arr, index) {
        const unique = arr
            .map(e => e[index])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }

    render() {
        return (
            <React.Fragment>
                {this.props.props.allSetPettyCash.edges.map((set_petty_cash, index) => {

                    let total = set_petty_cash.node.withdrawAmount;
                    let price_record = 0.0;
                    let total_payment = 0.0;

                    //แยก PCP ออกมา PC OE PS ออกมา
                    let pettyCashRecord_list = {}; // PC PS OE
                    let pettyCashPayment = []; // PCP

                    _.orderBy(set_petty_cash.node.pettyCashRecord.edges, "node.docNumber").forEach((pettyCashRecord) => {

                        //Push PC
                        const pettyCashRecord_detail = {
                            chartOfAccount: pettyCashRecord.node.chartOfAccount.chartOfAccountCode,
                            description: pettyCashRecord.node.description,
                            docNumber: pettyCashRecord.node.docNumber,
                            id: pettyCashRecord.node.id,
                            issuedDate: pettyCashRecord.node.issuedDate,
                            price: pettyCashRecord.node.price,
                            type: 'petty_cash'
                        }
                        if (!pettyCashRecord_list[pettyCashRecord.node.issuedDate]) {
                            pettyCashRecord_list[pettyCashRecord.node.issuedDate] = [];
                        }
                        pettyCashRecord_list[pettyCashRecord.node.issuedDate].push(pettyCashRecord_detail);

                        //Push PCP
                        if (pettyCashRecord.node.pettyCashPayment) {
                            if(pettyCashRecord.node.pettyCashPayment.issuedDate <= formatDateISO(this.props.state.end_date)){
                                pettyCashPayment.push(pettyCashRecord.node.pettyCashPayment);
                            }
                        }

                    })

                    set_petty_cash.node.paymentChannel.edges.forEach((pettyCashRecord) => {
                        //Push PS
                        _.orderBy(pettyCashRecord.node.payRecordGroup.payRecord.edges, "node.payRecordGroup.docNumber").forEach((pay_petty, index_last) => {
                            let last_index = index_last + 1 === pettyCashRecord.node.payRecordGroup.payRecord.edges.length ? true : false
                            let code = "";
                            let name_code = "";
                            if (pay_petty.node.purchaseRecord.productAndService) {
                                code = pay_petty.node.purchaseRecord.productAndService.productCode;
                                name_code = pay_petty.node.purchaseRecord.productAndService.name;
                            } else {
                                code = pay_petty.node.purchaseRecord.chartOfAccount.chartOfAccountCode;
                                name_code = pay_petty.node.purchaseRecord.chartOfAccount.name;
                            }
                            const pettyCashRecord_detail = {
                                chartOfAccount: code,
                                description: name_code,
                                docNumber: pettyCashRecord.node.payRecordGroup.docNumber,
                                id: pay_petty.node.id,
                                issuedDate: pettyCashRecord.node.payRecordGroup.issuedDate,
                                price: last_index ? pettyCashRecord.node.price : 0,
                                type: 'payRecord'
                            }
                            if (!pettyCashRecord_list[pettyCashRecord.node.payRecordGroup.issuedDate]) {
                                pettyCashRecord_list[pettyCashRecord.node.payRecordGroup.issuedDate] = [];
                            }
                            pettyCashRecord_list[pettyCashRecord.node.payRecordGroup.issuedDate].push(pettyCashRecord_detail);
                        })

                        //Push PCP
                        if (pettyCashRecord.node.pettyCashPayment) {
                            if(pettyCashRecord.node.pettyCashPayment.issuedDate <= formatDateISO(this.props.state.end_date)){
                                pettyCashPayment.push(pettyCashRecord.node.pettyCashPayment);
                            }
                        }

                    })

                    set_petty_cash.node.otherExpensePaymentChannel.edges.forEach((pettyCashRecord) => {

                        //Push OE
                        _.orderBy(pettyCashRecord.node.otherExpense.otherExpenseTransaction.edges, "node.otherExpense.docNumber").forEach((oe_petty, index_last) => {
                            let last_index = index_last + 1 === pettyCashRecord.node.otherExpense.otherExpenseTransaction.edges.length ? true : false
                            let code = "";
                            let name_code = "";
                            if (oe_petty.node.productAndService) {
                                code = oe_petty.node.productAndService.productCode;
                                name_code = oe_petty.node.productAndService.name;
                            } else {
                                code = oe_petty.node.chartOfAccount.chartOfAccountCode;
                                name_code = oe_petty.node.chartOfAccount.name;
                            }
                            const pettyCashRecord_detail = {
                                chartOfAccount: code,
                                description: name_code,
                                docNumber: pettyCashRecord.node.otherExpense.docNumber,
                                id: oe_petty.node.id,
                                issuedDate: pettyCashRecord.node.otherExpense.issuedDate,
                                price: last_index ? pettyCashRecord.node.price : 0,
                                type: 'oeRecord'
                            }
                            if (!pettyCashRecord_list[pettyCashRecord.node.otherExpense.issuedDate]) {
                                pettyCashRecord_list[pettyCashRecord.node.otherExpense.issuedDate] = [];
                            }
                            pettyCashRecord_list[pettyCashRecord.node.otherExpense.issuedDate].push(pettyCashRecord_detail);
                        })
                        //Push PCP
                        if (pettyCashRecord.node.pettyCashPayment) {
                            if(pettyCashRecord.node.pettyCashPayment.issuedDate <= formatDateISO(this.props.state.end_date)){
                                pettyCashPayment.push(pettyCashRecord.node.pettyCashPayment);
                            }
                        }
                    })

                    set_petty_cash.node.advancePettyCashChannel.edges.forEach((pettyCashRecord) => {

                        //Push AD
                        if(pettyCashRecord.node.advance){
                            let code = "";
                            let name_code = "";
                            code = pettyCashRecord.node.advance.chartOfAccount.chartOfAccountCode;
                            name_code = pettyCashRecord.node.advance.chartOfAccount.name;
                            
                            const pettyCashRecord_detail = {
                                chartOfAccount: code,
                                description: name_code,
                                docNumber: pettyCashRecord.node.advance.docNumber,
                                id: pettyCashRecord.node.advance.id,
                                issuedDate: pettyCashRecord.node.advance.issuedDate,
                                price: pettyCashRecord.node.price,
                                type: 'adRecord'
                            }
                            if (!pettyCashRecord_list[pettyCashRecord.node.advance.issuedDate]) {
                                pettyCashRecord_list[pettyCashRecord.node.advance.issuedDate] = [];
                            }
                            pettyCashRecord_list[pettyCashRecord.node.advance.issuedDate].push(pettyCashRecord_detail);                            
                        }

                        //Push CAD
                        _.orderBy(pettyCashRecord.node.clearAdvance?.clearAdvanceTransaction?.edges, "node.clearAdvance.docNumber").forEach((cad_petty, index_last) => {
                            let last_index = index_last + 1 === pettyCashRecord.node.clearAdvance.clearAdvanceTransaction.edges.length ? true : false
                            let code = "";
                            let name_code = "";
                            code = cad_petty.node.chartOfAccount.chartOfAccountCode;
                            name_code = cad_petty.node.chartOfAccount.name;
                            
                            const pettyCashRecord_detail = {
                                chartOfAccount: code,
                                description: name_code,
                                docNumber: pettyCashRecord.node.clearAdvance.docNumber,
                                id: cad_petty.node.id,
                                issuedDate: pettyCashRecord.node.clearAdvance.issuedDate,
                                price: last_index ? pettyCashRecord.node.price : 0,
                                type: 'cadRecord'
                            }
                            if (!pettyCashRecord_list[pettyCashRecord.node.clearAdvance.issuedDate]) {
                                pettyCashRecord_list[pettyCashRecord.node.clearAdvance.issuedDate] = [];
                            }
                            pettyCashRecord_list[pettyCashRecord.node.clearAdvance.issuedDate].push(pettyCashRecord_detail);
                        })

                        
                        //Push PCP
                        if (pettyCashRecord.node.pettyCashPayment) {
                            if(pettyCashRecord.node.pettyCashPayment.issuedDate <= formatDateISO(this.props.state.end_date)){
                                pettyCashPayment.push(pettyCashRecord.node.pettyCashPayment);
                            }
                        }
                    })

                    set_petty_cash.node.clearGuaranteeMoneyReceivedPettyCashChannel.edges.forEach((pettyCashRecord) => {

                        //Push CGR
                        if(pettyCashRecord.node.clearGuaranteeMoneyReceived){
                            let code = "";
                            let name_code = "";
                            code = pettyCashRecord.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.chartOfAccountCode;
                            name_code = pettyCashRecord.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.name;
                            
                            const pettyCashRecord_detail = {
                                chartOfAccount: code,
                                description: name_code,
                                docNumber: pettyCashRecord.node.clearGuaranteeMoneyReceived.docNumber,
                                id: pettyCashRecord.node.clearGuaranteeMoneyReceived.id,
                                issuedDate: pettyCashRecord.node.clearGuaranteeMoneyReceived.issuedDate,
                                price: pettyCashRecord.node.price,
                                type: 'cgrRecord'
                            }
                            if (!pettyCashRecord_list[pettyCashRecord.node.clearGuaranteeMoneyReceived.issuedDate]) {
                                pettyCashRecord_list[pettyCashRecord.node.clearGuaranteeMoneyReceived.issuedDate] = [];
                            }
                            pettyCashRecord_list[pettyCashRecord.node.clearGuaranteeMoneyReceived.issuedDate].push(pettyCashRecord_detail);                            
                        }

                        //Push PCP
                        if (pettyCashRecord.node.pettyCashPayment) {
                            if(pettyCashRecord.node.pettyCashPayment.issuedDate <= formatDateISO(this.props.state.end_date)){
                                pettyCashPayment.push(pettyCashRecord.node.pettyCashPayment);
                            }
                        }
                    })

                    //Unique
                    pettyCashPayment = this.getUnique(pettyCashPayment, 'id');
                    pettyCashPayment.forEach((e) => {
                        if (!pettyCashRecord_list[e.issuedDate]) {
                            pettyCashRecord_list[e.issuedDate] = [];
                        }
                        pettyCashRecord_list[e.issuedDate].push(e);
                    })
                    //Sort Date
                    let pettyCashRecord_list_sort = Object.keys(pettyCashRecord_list).sort().reduce(function (acc, key) { 
                        acc[key] = pettyCashRecord_list[key];
                        return acc;
                    }, {});
                    
                    let status_void = set_petty_cash.node.status === 'VOID'?true:false
                    let color = status_void?{color:'red'}:null

                    return (
                        <React.Fragment key={set_petty_cash.node.id}>
                            <tr>
                                <td style={color} className="text-center">{format(set_petty_cash.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                <td style={color} >{set_petty_cash.node.docNumber}</td>
                                <td style={color} >{set_petty_cash.node.withdrawer} {set_petty_cash.node.description}</td>
                                <td style={color} className="text-right">{set_petty_cash.node.status !== 'VOID' ?
                                    numberWithComma(set_petty_cash.node.withdrawAmount) : '-'}</td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td style={color} className="text-right">{set_petty_cash.node.status !== 'VOID' ? numberWithComma(total) : '-'}</td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                            </tr>

                            { set_petty_cash.node.status !== 'VOID' && Object.entries(pettyCashRecord_list_sort).map(([key, pettyCashRecord_list], index_stack) => {
                                return(
                                    pettyCashRecord_list.map((petty_cash_record,r_index) =>{
                                        if (petty_cash_record.type === 'petty_cash' || petty_cash_record.type === 'payRecord' || 
                                        petty_cash_record.type === 'oeRecord' || petty_cash_record.type === 'adRecord' || petty_cash_record.type === 'cadRecord' ||
                                        petty_cash_record.type === 'cgrRecord') {
                                            total = total - petty_cash_record.price;
                                            price_record += petty_cash_record.price;
                                            return (
                                                <PettyCashReportRecordTable petty_cash_record={petty_cash_record}
                                                                            key={'petty_cash-'+r_index} total={total}/>
                                            )
                                        } 
                                        else {
                                            total = total + petty_cash_record.total;
                                            total_payment += petty_cash_record.total;
                                            return (
                                                <PettyCashReportPaymentTable payment={petty_cash_record}
                                                                             key={'petty_cash-'+r_index} total={total}/>
                                            )
                                        }
                                    })
                                )
                            })}

                            {/* { set_petty_cash.node.status === 'VOID' && 
                                    <tr key={'-'}>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td className="text-center">{'-'}</td>
                                    <td className="text-center">{'-'}</td>
                                    <td className="text-center">{'-'}</td>
                                    <td>{'-'}</td>
                                    <td className="text-right">{'-'}</td>
                                    <td className="text-right">{'-'}</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    </tr>
                             } */}

                            <tr>
                                <td style={color} colSpan={3} className="text-right"><strong>{i18next.t("PettyCashReport:Total")}</strong></td>
                                <td style={color} className="text-right"><strong>{numberWithComma(set_petty_cash.node.status !== 'VOID' ? set_petty_cash.node.withdrawAmount : '-')}</strong></td>
                                <td style={color} className="text-right" colSpan={5}><strong>{numberWithComma(price_record)}</strong></td>
                                <td style={color} className="text-right" colSpan={5}><strong>{numberWithComma(total_payment)}</strong></td>
                                <td />
                            </tr>
                        </React.Fragment>

                    )
                })}
            </React.Fragment>
        )
    }
}

export default PettyCashReportTable;
