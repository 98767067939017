import { graphql } from "babel-plugin-relay/macro";

const queryReportProductTransaction = graphql`
    query reportProductTransactionQuery($productAndServiceId: String){
        reportProductTransaction(productAndServiceId: $productAndServiceId){
            edges{
                node{
                    date
                }
            }
        }
    }
`

export default queryReportProductTransaction;