/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OtherExpensePaymentChannelStatus = "ACTIVE" | "PAID" | "%future added value";
export type PaymentChannelStatus = "ACTIVE" | "PAID" | "%future added value";
export type PettyCashRecordStatus = "VOID" | "WAIT" | "WITHDRAW" | "%future added value";
export type SetPettyCashStatus = "ACTIVE" | "VOID" | "%future added value";
export type pettyCashSetListTableQueryVariables = {|
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type pettyCashSetListTableQueryResponse = {|
  +allSetPettyCash: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +added: any,
        +withdrawer: ?string,
        +description: ?string,
        +withdrawAmount: number,
        +issuedDate: ?any,
        +status: SetPettyCashStatus,
        +voidRemark: ?string,
        +pettyCashRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +docNumber: string,
              +description: ?string,
              +price: number,
              +status: PettyCashRecordStatus,
            |}
          |}>
        |},
        +otherExpensePaymentChannel: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +description: string,
              +price: number,
              +status: OtherExpensePaymentChannelStatus,
            |}
          |}>
        |},
        +paymentChannel: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +description: string,
              +price: number,
              +status: PaymentChannelStatus,
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type pettyCashSetListTableQuery = {|
  variables: pettyCashSetListTableQueryVariables,
  response: pettyCashSetListTableQueryResponse,
|};
*/


/*
query pettyCashSetListTableQuery(
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
) {
  allSetPettyCash(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        docNumber
        added
        withdrawer
        description
        withdrawAmount
        issuedDate
        status
        voidRemark
        pettyCashRecord(status: "wait") {
          edges {
            node {
              id
              docNumber
              description
              price
              status
            }
          }
        }
        otherExpensePaymentChannel(status: "paid", slug: "petty_cash", statusSetPettyCash: "wait") {
          edges {
            node {
              id
              description
              price
              status
            }
          }
        }
        paymentChannel(status: "paid", slug: "petty_cash", statusSetPettyCash: "wait") {
          edges {
            node {
              id
              description
              price
              status
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "slug",
    "value": "petty_cash"
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": "paid"
  },
  {
    "kind": "Literal",
    "name": "statusSetPettyCash",
    "value": "wait"
  }
],
v11 = [
  (v5/*: any*/),
  (v7/*: any*/),
  (v9/*: any*/),
  (v8/*: any*/)
],
v12 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      }
    ],
    "concreteType": "SetPettyCashNodeConnection",
    "kind": "LinkedField",
    "name": "allSetPettyCash",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SetPettyCashNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SetPettyCashNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "withdrawer",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "withdrawAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidRemark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "status",
                    "value": "wait"
                  }
                ],
                "concreteType": "PettyCashRecordNodeConnection",
                "kind": "LinkedField",
                "name": "pettyCashRecord",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PettyCashRecordNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PettyCashRecordNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "pettyCashRecord(status:\"wait\")"
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "OtherExpensePaymentChannelNodeConnection",
                "kind": "LinkedField",
                "name": "otherExpensePaymentChannel",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OtherExpensePaymentChannelNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherExpensePaymentChannelNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "otherExpensePaymentChannel(slug:\"petty_cash\",status:\"paid\",statusSetPettyCash:\"wait\")"
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "PaymentChannelNodeConnection",
                "kind": "LinkedField",
                "name": "paymentChannel",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaymentChannelNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaymentChannelNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "paymentChannel(slug:\"petty_cash\",status:\"paid\",statusSetPettyCash:\"wait\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashSetListTableQuery",
    "selections": (v12/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "pettyCashSetListTableQuery",
    "selections": (v12/*: any*/)
  },
  "params": {
    "cacheID": "7a7ea4ae0738692b9b3ab4092e8d703d",
    "id": null,
    "metadata": {},
    "name": "pettyCashSetListTableQuery",
    "operationKind": "query",
    "text": "query pettyCashSetListTableQuery(\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n) {\n  allSetPettyCash(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        added\n        withdrawer\n        description\n        withdrawAmount\n        issuedDate\n        status\n        voidRemark\n        pettyCashRecord(status: \"wait\") {\n          edges {\n            node {\n              id\n              docNumber\n              description\n              price\n              status\n            }\n          }\n        }\n        otherExpensePaymentChannel(status: \"paid\", slug: \"petty_cash\", statusSetPettyCash: \"wait\") {\n          edges {\n            node {\n              id\n              description\n              price\n              status\n            }\n          }\n        }\n        paymentChannel(status: \"paid\", slug: \"petty_cash\", statusSetPettyCash: \"wait\") {\n          edges {\n            node {\n              id\n              description\n              price\n              status\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d02016dd2b7fbe15a53b5f2ef0f84d5';

module.exports = node;
