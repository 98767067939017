/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type ChequeDepositStatus = "DEPOSIT" | "NOT_DEPOSIT" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type chequeDepositQueryVariables = {|
  id: string
|};
export type chequeDepositQueryResponse = {|
  +chequeDeposit: ?{|
    +id: string,
    +chequeNumber: string,
    +refNumber: ?string,
    +image: ?string,
    +contact: ?{|
      +name: string,
      +firstName: string,
      +lastName: string,
      +typeOfContact: ContactTypeOfContact,
    |},
    +bankName: string,
    +branchNumber: string,
    +date: any,
    +depositDate: ?any,
    +price: number,
    +status: ChequeDepositStatus,
    +unknownContact: ?string,
    +depositBank: ?{|
      +id: string
    |},
    +note: ?string,
  |},
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +accountNumber: string,
          +accountName: string,
          +bankName: string,
          +accountType: BankAccountAccountType,
        |}
      |}>
    |}
  |},
|};
export type chequeDepositQuery = {|
  variables: chequeDepositQueryVariables,
  response: chequeDepositQueryResponse,
|};
*/


/*
query chequeDepositQuery(
  $id: ID!
) {
  chequeDeposit(id: $id) {
    id
    chequeNumber
    refNumber
    image
    contact {
      name
      firstName
      lastName
      typeOfContact
      id
    }
    bankName
    branchNumber
    date
    depositDate
    price
    status
    unknownContact
    depositBank {
      id
    }
    note
  }
  bankAccountViewer {
    allBankAccount(status: true, accountType_In: "current_account,saving_account,saving_deposit,fixed_deposit") {
      edges {
        node {
          id
          accountNumber
          accountName
          bankName
          accountType
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chequeNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "branchNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unknownContact",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccountNode",
  "kind": "LinkedField",
  "name": "depositBank",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "accountType_In",
      "value": "current_account,saving_account,saving_deposit,fixed_deposit"
    },
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountName",
              "storageKey": null
            },
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allBankAccount(accountType_In:\"current_account,saving_account,saving_deposit,fixed_deposit\",status:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "chequeDepositQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChequeDepositNode",
        "kind": "LinkedField",
        "name": "chequeDeposit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "chequeDepositQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChequeDepositNode",
        "kind": "LinkedField",
        "name": "chequeDeposit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v19/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43a8a5651e8f3fa377de421e1fa32d31",
    "id": null,
    "metadata": {},
    "name": "chequeDepositQuery",
    "operationKind": "query",
    "text": "query chequeDepositQuery(\n  $id: ID!\n) {\n  chequeDeposit(id: $id) {\n    id\n    chequeNumber\n    refNumber\n    image\n    contact {\n      name\n      firstName\n      lastName\n      typeOfContact\n      id\n    }\n    bankName\n    branchNumber\n    date\n    depositDate\n    price\n    status\n    unknownContact\n    depositBank {\n      id\n    }\n    note\n  }\n  bankAccountViewer {\n    allBankAccount(status: true, accountType_In: \"current_account,saving_account,saving_deposit,fixed_deposit\") {\n      edges {\n        node {\n          id\n          accountNumber\n          accountName\n          bankName\n          accountType\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e55465288af872a23e54b413598d76cf';

module.exports = node;
