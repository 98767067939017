import i18next from "i18next";
import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import "../../report.scss";

class PayableMovementSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseRecordGroupTotal: 0,
            payRecordTotal: 0,
            creditNoteTotal: 0,
            balance: 0,
            sumAll: 0,
            prepaidDeposit: 0,
        };
        this.summary = this.summary.bind(this);
    }

    componentWillMount() {
        this.summary();
    }

    summary() {
        let purchaseRecordGroupTotal = 0;
        let payRecordTotal = 0;
        let creditNoteTotal = 0;
        let prepaidDeposit = 0;
        let balance = 0;
        let sumAll = 0;

        this.props.query.allPurchaseRecordGroup.edges.forEach((purchase_record_group) => {
            purchase_record_group.node.purchaseRecord.edges.forEach((purchase_record) => {

                balance = purchase_record.node.total;
                purchaseRecordGroupTotal += purchase_record.node.total;
                sumAll += balance;

                purchase_record.node.payRecord.edges.forEach((pay_record) => {
                    balance -= (pay_record.node.amount + pay_record.node.whtAmount);
                    payRecordTotal += (pay_record.node.amount + pay_record.node.whtAmount);
                    sumAll -= (pay_record.node.amount + pay_record.node.whtAmount);
                });

                purchase_record.node.acceptCreditNoteTransaction.edges.forEach((cn_transaction) => {
                    balance -= cn_transaction.node.total;
                    creditNoteTotal += cn_transaction.node.total;
                    sumAll -= cn_transaction.node.total;
                });

                purchase_record.node.prepaidDepositTransactionUsed.edges.forEach((pd_transaction) => {
                        balance -= pd_transaction.node.amount;
                        prepaidDeposit += pd_transaction.node.amount;
                        sumAll -= pd_transaction.node.amount;
                });

            });
        });

        this.setState({
            purchaseRecordGroupTotal: purchaseRecordGroupTotal.toFixed(2),
            payRecordTotal: payRecordTotal.toFixed(2),
            creditNoteTotal: creditNoteTotal.toFixed(2),
            sumAll: sumAll.toFixed(2),
            prepaidDeposit: prepaidDeposit.toFixed(2),
        });
    }

    render() {
        return (
            <tr>
                <td className="text-center font-weight-bold" colSpan={4}>
                    {i18next.t("PayableMovement:Total")}
                </td>
                <td className="text-right font-weight-bold">
                    {numberWithComma(this.state.purchaseRecordGroupTotal, "-")}
                </td>
                <td className="text-right font-weight-bold" colSpan={3}>
                    {numberWithComma(this.state.payRecordTotal, "-")}
                </td>
                <td className="text-right font-weight-bold" colSpan={3}>
                    {numberWithComma(this.state.prepaidDeposit, "-")}
                </td>
                <td className="text-right font-weight-bold" colSpan={3}>
                    {numberWithComma(this.state.creditNoteTotal, "-")}
                </td>
                <td className="text-right font-weight-bold">
                    {numberWithComma(this.state.sumAll, "-")}
                </td>
            </tr>
        )
    }
}

export default PayableMovementSummary;
