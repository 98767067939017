/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountRecordGroupCategory = "GENERAL" | "HISTORICAL" | "PAYMENT" | "PURCHASE" | "RECEIVE" | "SALES" | "VOIDED" | "%future added value";
export type queryReportRegisterQueryVariables = {|
  yearId?: ?string,
  chartOfAccountId_In?: ?$ReadOnlyArray<?string>,
  group_IssuedDate_Gte?: ?any,
  group_IssuedDate_Lte?: ?any,
|};
export type queryReportRegisterQueryResponse = {|
  +allChartOfAccountBudget: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +totalBudget: ?number,
        +chartOfAccount: ?{|
          +id: string,
          +name: string,
          +nameEn: ?string,
          +chartOfAccountCode: string,
          +accountRecord: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: string,
                +debit: number,
                +credit: number,
                +added: any,
                +group: ?{|
                  +id: string,
                  +name: string,
                  +issuedDate: ?any,
                  +refNumber: ?string,
                  +totalDebit: number,
                  +totalCredit: number,
                  +refTransaction: ?string,
                  +category: AccountRecordGroupCategory,
                |},
              |}
            |}>
          |},
        |},
        +monthBudget: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +months: any,
              +budget: ?number,
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type queryReportRegisterQuery = {|
  variables: queryReportRegisterQueryVariables,
  response: queryReportRegisterQueryResponse,
|};
*/


/*
query queryReportRegisterQuery(
  $yearId: String
  $chartOfAccountId_In: [String]
  $group_IssuedDate_Gte: Date
  $group_IssuedDate_Lte: Date
) {
  allChartOfAccountBudget(yearId: $yearId, chartOfAccountId_In: $chartOfAccountId_In) {
    edges {
      node {
        totalBudget
        chartOfAccount {
          id
          name
          nameEn
          chartOfAccountCode
          accountRecord(group_IssuedDate_Gte: $group_IssuedDate_Gte, group_IssuedDate_Lte: $group_IssuedDate_Lte) {
            edges {
              node {
                id
                name
                debit
                credit
                added
                group {
                  id
                  name
                  issuedDate
                  refNumber
                  totalDebit
                  totalCredit
                  refTransaction
                  category
                }
              }
            }
          }
        }
        monthBudget {
          edges {
            node {
              months
              budget
              id
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chartOfAccountId_In"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "group_IssuedDate_Gte"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "group_IssuedDate_Lte"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yearId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "chartOfAccountId_In",
    "variableName": "chartOfAccountId_In"
  },
  {
    "kind": "Variable",
    "name": "yearId",
    "variableName": "yearId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalBudget",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chartOfAccountCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "group_IssuedDate_Gte",
          "variableName": "group_IssuedDate_Gte"
        },
        {
          "kind": "Variable",
          "name": "group_IssuedDate_Lte",
          "variableName": "group_IssuedDate_Lte"
        }
      ],
      "concreteType": "AccountRecordNodeConnection",
      "kind": "LinkedField",
      "name": "accountRecord",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountRecordNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountRecordNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "debit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "credit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "added",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountRecordGroupNode",
                  "kind": "LinkedField",
                  "name": "group",
                  "plural": false,
                  "selections": [
                    (v6/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "issuedDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "refNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalDebit",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCredit",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "refTransaction",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "months",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budget",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryReportRegisterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ChartOfAccountBudgetNodeConnection",
        "kind": "LinkedField",
        "name": "allChartOfAccountBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountBudgetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountBudgetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MonthBudgetNodeConnection",
                    "kind": "LinkedField",
                    "name": "monthBudget",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MonthBudgetNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MonthBudgetNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryReportRegisterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ChartOfAccountBudgetNodeConnection",
        "kind": "LinkedField",
        "name": "allChartOfAccountBudget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountBudgetNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChartOfAccountBudgetNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MonthBudgetNodeConnection",
                    "kind": "LinkedField",
                    "name": "monthBudget",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MonthBudgetNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MonthBudgetNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "142cf8a06c7eb168ec14876def6305f9",
    "id": null,
    "metadata": {},
    "name": "queryReportRegisterQuery",
    "operationKind": "query",
    "text": "query queryReportRegisterQuery(\n  $yearId: String\n  $chartOfAccountId_In: [String]\n  $group_IssuedDate_Gte: Date\n  $group_IssuedDate_Lte: Date\n) {\n  allChartOfAccountBudget(yearId: $yearId, chartOfAccountId_In: $chartOfAccountId_In) {\n    edges {\n      node {\n        totalBudget\n        chartOfAccount {\n          id\n          name\n          nameEn\n          chartOfAccountCode\n          accountRecord(group_IssuedDate_Gte: $group_IssuedDate_Gte, group_IssuedDate_Lte: $group_IssuedDate_Lte) {\n            edges {\n              node {\n                id\n                name\n                debit\n                credit\n                added\n                group {\n                  id\n                  name\n                  issuedDate\n                  refNumber\n                  totalDebit\n                  totalCredit\n                  refTransaction\n                  category\n                }\n              }\n            }\n          }\n        }\n        monthBudget {\n          edges {\n            node {\n              months\n              budget\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '87d1fa62c050e2092876e6464c7ff6eb';

module.exports = node;
