import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import { QueryRenderer } from "react-relay";
import SearchSelect from "../../../libs/searchSelect";
import i18next from "i18next";

const query = graphql`
  query contactPayableReportListQuery($typeOfContact: String) {
    contactViewer {
      allContact(
        typeOfPayment: "payable"
        typeOfContact: $typeOfContact
        order: "ref_number"
      ) {
        edges {
          node {
            id
            refNumber
            name
            firstName
            lastName
          }
        }
      }
    }
  }
`;

class ContactPayableReportList extends React.Component {
  render() {
    return (
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{ typeOfContact: this.props.customer_type }}
        render={({ error, props }) => {
          if (props) {
            let all_contact = [];
            if (this.props.contact_other) {
              let other_object = [
                  {
                      node: {
                          firstName: '',
                          id: 'on_contact',
                          lastName: '',
                          name: '',
                          refNumber: 'บุคคลภายนอก',
                      }
                  },
              ];
                all_contact = [...props.contactViewer.allContact.edges, ...other_object];
            } else {
                all_contact = props.contactViewer.allContact.edges
            }
            return (
              <div className="col-md-4 border-right">
                <div className="form-group">
                  <label>{i18next.t("FilterPayable:Contact Person")}</label>
                  <SearchSelect
                    onChange={this.props.handleChange}
                    value={this.props.contact}
                    name="temp_contact"
                    placeholder="กรุณาเลือกผู้ติดต่อ"
                    queryObject={all_contact}
                    isClearable={true}
                    optionAll={true}
                    keyOfValue="id"
                    keyOfLabel="refNumber:name"
                  />
                </div>
              </div>
            );
          }
        }}
      />
    );
  }
}

export default ContactPayableReportList;
