import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

// var token_id = localStorage.getItem("token");

function getToken() {
    var token_ids = localStorage.getItem("token");
    var header = {
        authorization: `JWT ${token_ids}`,
        "Content-Type": "application/json",
    };
    // token_id = token_ids
    return header
}

// SettingFastConnect
function get_All_project() {
    return axios.get(`${getApiRoot()}icon/icon_project`, { headers: getToken(), },)
}

function get_icon_log(data) {
    return axios.get(`${getApiRoot()}icon/icon_log`, {
        headers: getToken(),
        params: {
            search: data.search,
            indexFirst: data.indexFirst,
            indexLast: data.indexLast,
            startDate: data.startDate,
            endDate: data.endDate,
            projectId: data.projectId
        }
    })
}




export default {
    get_All_project, get_icon_log
}