import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import UploadImageInput from '../../libs/uploadImageInput';
import _ from 'lodash'
import generalAPI from './generalAPI';
import Swal from 'sweetalert2';
import i18n from 'i18next';
import upload from '../../libs/upload';
import i18next from 'i18next';

const newContact={
    id : '',
    name: '',
    tel : '',
    email: '',
    status : '',
}

const newDocumentFile = {
    nameFile : '',
    documentUpload : '',
    status : '',
    id : ''
}


class CompanyDetail extends Component{
    constructor(props){
        super(props);
        this.state = {
            generalEdit : false,
            developerName : '',
            image : '',
            imageUpload : '',
            generalData : this.props.generalData,
            token : localStorage.getItem("token"),
        }

        this.onEdit = this.onEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputDocument = this.handleInputDocument.bind(this);
        this.handleContactData = this.handleContactData.bind(this);
    }

    componentDidMount(){
        this.setState({
            generalData : this.props.generalData
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.generalData !== this.props.generalData){
            this.setState({
                generalData : this.props.generalData
            })
        }
    }

    handleChange(e){
        let generalData = _.cloneDeep(this.state.generalData)
        if(e.target.name==='image.upload_image'){
            this.setState({
                imageUpload : e.target.value
            })
        }else{
            generalData[e.target.name] = e.target.value
            this.setState({
                generalData : generalData
            })
        }
        
    }

    handleContactData(e){
        let generalData = _.clone(this.state.generalData)
        if(e.target.dataset.key === 'contactData'){
            let id = e.target.dataset.id
            generalData.contactData[id][e.target.name] = e.target.value
            this.setState({
                generalData : generalData
            })
        }
    }

    handleInputDocument(e){
        let id = parseInt(e.currentTarget.dataset.id)
        let generalData = _.cloneDeep(this.state.generalData)
        generalData.documentData[id]["documentUpload"] = e.currentTarget.files[0]
        this.setState({
            generalData : generalData
        })
    }

    onEdit(){
        this.setState({generalEdit : !this.state.generalEdit})
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/developer/data" + path, file);
          resolve(res);
        });
    };

    async onSubmit(){
        let image = ''
        let isError = false
        if(_.findIndex(this.state.generalData.contactData,{name : ''}) !== -1){
            Swal.fire('กรุณากรอกชื่อให้ครบถ้วน!', '', 'warning');
            isError = true
        }else if(_.findIndex(this.state.generalData.contactData,{tel : ''}) !== -1){
            Swal.fire('กรุณากรอกเบอร์ติดต่อให้ครบถ้วน!', '', 'warning');
            isError = true
        }
        if(_.findIndex(this.state.generalData.documentData,{nameFile : ''}) !== -1){
            Swal.fire('กรุณากรอกชื่อไฟล์ให้ครบถ้วน!', '', 'warning');
            isError = true
        }else if(_.findIndex(this.state.generalData.documentData,{documentUpload : ''}) !== -1){
            Swal.fire('กรุณาแนบไฟล์ให้ครบถ้วน!', '', 'warning');
            isError = true
        }

        let contactData = []
        this.state.generalData?.contactData.length > 0 &&
         this.state.generalData.contactData.forEach((transaction) => {
            contactData.push({
                id : transaction.id,
                name : transaction.name,
                tel : transaction.tel,
                email : transaction.email,
                status : transaction.status ? transaction.status : ''
            })
        })
        let data = {
            contactData: contactData,
            documentData : [],
            image : image
        }

        
        if(this.state.imageUpload !== ''){
            await this.callUpload('', this.state.imageUpload).then((data) => {
                image = data.key.replace("silverman-storage/private/", "");
            });
            data['image'] = image
        }

        if(!isError){
            generalAPI.createDeveloper(data,this.state.token).then((response) => {
                if(response.status === 200){
                    Swal.fire(i18n.t('developer:Save success!'), '', 'success').then(() => this.props.fetchData());
                    this.onEdit()
                }else{
                    Swal.fire(i18n.t('developer:Fail to save!'), '', 'warning').then(() => this.props.fetchData());
                    this.onEdit()
                }
            })
        }
    }

    onDelete = (data,id,name) => {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(data)
                let index = _.findIndex(_.cloneDeep(getData),{id : id})
                if(index === -1){
                    getData.splice(id, 1)
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name,
                            getData
                        );
                    });
                }else{
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name+'['+index+'].status',
                            'delete'
                        );
                    });
                }
            }
        })
    }

    addContact = () => {
        let contactData = [...this.state.generalData.contactData , {...newContact}]
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'generalData.contactData',
                contactData
            );
        });
    }

    addDocument = () => {
        let documentData = [...this.state.generalData.documentData , {...newDocumentFile}]
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'generalData.documentData',
                documentData
            );
        });
    }
    
    render() {
        return (
            <Translation>
                {t =>
                    <div className="general-box p-4">
                         <div className="row col-12">
                            <div className="col-12 p-1">
                                {this.state.generalEdit ?
                                    <button className="btn add-button add float-right" type="button" onClick={() => this.onSubmit()}>
                                        <span className="text-blue"> {t("Allaction:cancel")}</span>
                                    </button>
                                    : 
                                    _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'ownership_developer_edit'}) &&
                                    <button onClick={() => this.onEdit()} type="button" className="btn add-button add float-right">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/edit-blue-color.png'} alt="edit"/>
                                        <span className="text-blue">{t("Allaction:edit")}</span>
                                    </button>
                                    
                                }
                            </div>
                        </div>
                        <div className="row col-12">
                            <div className="6"/>
                            <div className="col d-flex float-right justify-content-end mr-2 mt-3">
                                {this.state.generalEdit && <div className="upload-file">
                                    <button className="btn btn-outline-primary">
                                        <label htmlFor="uploadProfileImages">{t('projectManager:Change profile image')}</label>
                                    </button>
                                    <div className="upload-input">
                                        <UploadImageInput
                                            id="uploadProfileImages"
                                            setField={"image.upload_image:image"}
                                            onChange={this.handleChange}
                                            />
                                    </div>
                                </div>
                                }
                            </div>
                            
                            <div className="col-3 float-right justify-content-end d-flex mt-3">
                                <img
                                    src={this.state.generalData.image !== '' ? this.state.generalData.image: process.env.PUBLIC_URL + '/images/icons/profile-manager-icon.png'}
                                    alt="project-manager-profile"
                                    className="rounded-circle avatar-img profile-img float-right"/>
                            </div>
                            
                        </div>
                        

                        <div className="row col-12">
                            <div className="col-6 p-3">
                                <h4>{t("generalInfo:Contact information")}</h4>
                            </div>
                        </div>
                        {this.state.generalData.contactData &&
                         this.state.generalData.contactData.map((contact,index) => {
                            if((contact.status && contact.status !== 'delete') || !contact.status){
                                if(!this.state.generalEdit){
                                    return(
                                        <div className="row col-12 d-flex align-items-center" key={index}>
                                            <div className="input-group col ml-1 mb-2 form-control is-span ">
                                                <strong><span className="h5">{contact.name}</span></strong>
                                            </div>
                                            <div className="input-group col ml-1 mb-2 form-control is-span ">
                                                <strong><span className="h5">{contact.tel}</span></strong>
                                            </div>
                                            <div className="input-group col ml-1 mb-2 form-control is-span ">
                                                <strong><span className="h5">{contact.email}</span></strong>
                                            </div>
                                            
                                        </div>
                                    )
                                
                            }else{
                                return (
                                    <div className="row col-12 mb-2" key={index + 'edit'}>
                                        <div className="input-group col-4 align-self-center">
                                            <input type="text" className="form-control" value={contact.name} 
                                            name="name" onChange={this.handleContactData}
                                            placeholder="ชื่อ-นามสกุล"
                                            data-key = "contactData" data-id={index} required={true}/>
                                        </div>
                                        <div className="input-group col-3 align-self-center">
                                            <input type="text" className="form-control" value={contact.tel} 
                                            name="tel" onChange={this.handleContactData}
                                            placeholder="เบอร์โทร"
                                            data-key = "contactData" data-id={index} required={true}/>
                                        </div>
                                        <div className="input-group col-4 align-self-center">
                                            <input type="text" className="form-control" value={contact.email} 
                                            name="email" onChange={this.handleContactData}
                                            placeholder="E-mail"
                                            data-key = "contactData" data-id={index} required={true}/>
                                        </div>
                                        <div className="col-1 align-self-center">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                            alt="delete" className="btn-delete cursor"
                                            onClick={() => this.onDelete(this.state.generalData.contactData,contact.id !== '' ? contact.id : index , 'generalData.contactData')}/>
                                        </div>
                                    </div>
                                )
                            }}
                        })
                        }
                        {this.state.generalEdit &&
                            <div className="row col-12">
                                <button
                                    type="button"
                                    className="btn add-button add"
                                    onClick={this.addContact}
                                >
                                    <span className="text-blue" >+ {t('createUpdateForm:add_list')}</span>
                                </button>  
                            </div>   
                        }
                        {/* <div className="row col-12 mt-3">
                            <div className="col-12 p-3">
                                <h4>เอกสารประกอบ</h4>
                            </div>
                            {this.state.generalData.documentData.map((document,index) => {
                                if(!this.state.generalEdit){
                                    return(
                                        <div className="row col-12 d-flex align-items-center" key={index}>
                                            <div className="input-group col-5 ml-3 mb-2 form-control is-span ">
                                                <strong><span className="h5">{document.nameFile}</span></strong>
                                            </div>
                                            <div className="col">
                                                {document.documentUpload !== '' &&
                                                    <a download="name_of_downloaded_file"
                                                    href={document.documentUpload}
                                                    target={"_blank"}>
                                                        <span className="text-blue">ดาวน์โหลด</span>
                                                    </a>
                                                }
                                            </div>
                                        </div>)
                                }else{
                                    return (
                                        <div className="row col-12 mb-2" key={index + 'edit'}>
                                            <div className="input-group col-5 align-self-center">
                                                <div className="input-group-prepend">
                                                        <strong><span className="input-group-text">ชื่อเอกสาร</span></strong>
                                                    </div>
                                                <input type="text" className="form-control" value={document.nameFile} 
                                                name="nameFile" onChange={this.handleChange}
                                                data-key = "documentFile" data-id={index} required={true}/>
                                            </div>
                                            <div className="col ">
                                                <UploadFileInput
                                                    handleInputDocument={this.handleInputDocument}
                                                    documentUpload={document.documentUpload}
                                                    dataId={index} dataKey="documentFile"
                                                    disableHeaderText={true}
                                                /> 
                                            </div>
                                            <div className="col-1 align-self-center">
                                                <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                alt="delete" className="btn-delete cursor"
                                                onClick={() => this.onDelete(this.state.generalData.documentData,document.id !== '' ? document.id : index , 'generalData.documentData')}/>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                            {this.state.generalEdit &&
                                <div className="row col-12">
                                    <button
                                        type="button"
                                        className="btn add-button add"
                                        onClick={this.addDocument}
                                    >
                                        <span className="text-blue" >+ {t('createUpdateForm:add_list')}</span>
                                    </button>  
                                </div>   
                            }
                            
                        </div> */}
                    </div>
                }
            </Translation>
        );
    }



}export default CompanyDetail;