import React, { useState,useEffect } from 'react';
import { Translation } from "react-i18next";
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import i18next from 'i18next';
import _ from "lodash"

const customStyles = {
  container: provided => ({
    ...provided,
    width: 150
  })
};

const customStyles1 = {
  container: provided => ({
    ...provided,
    width: 200
  })
};

const SeacrhDetails = (props) => {
  const [searchBoxId, setSearchBoxId] = useState(props.search);
  const [startDateIs, setStartDateIs] = useState(props.start_date);
  const [endDateIs, setEndDateIs] = useState(props.end_date);
  const [searchType, setSearchTypePerson] = useState(props.searchType);
  const [status, setStatus] = useState(props.status);
  const [searchFileType, setSearchFileType] = useState(props.listSelectBoxTypeFile);
  const [searchMenuName, setMenuName] = useState(props.listSelectMenuNameType);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSreach()
    }
  }



  const onSreach = () => {
    let event = []
    let data_search = { name: 'search', value: searchBoxId, };
    event.push(data_search)
    let data_startDateIs = { name: 'start_date', value: startDateIs, };
    event.push(data_startDateIs)

    let data_endDateIs = { name: 'end_date', value: endDateIs, };
    event.push(data_endDateIs)

    let data_status = { name: 'status', value: status, };
    event.push(data_status)

    let data_searchType = { name: 'searchType', value: searchType, }
    event.push(data_searchType)

    let data_searchFileType = { name: 'searchFileType', value: searchFileType, }
    event.push(data_searchFileType)
    
    let data_searchMenuName = { name: 'searchMenuName', value: searchMenuName, }
    event.push(data_searchMenuName)

    if (props.handlePropsChange) {
      props.handlePropsChange(event);
    }
  }

  // const onChange = (e) => {
  //   let { name, value } = e.target
  //   let dataEvent = []

  //   let data_search = { name: 'search', value: value, }
  //   dataEvent.push(data_search)

  //   if(props.onChangeSearch){
  //     props.onChangeSearch(dataEvent);
  //   }
  // }

  useEffect(() => {
    onSreach();
  }, [searchFileType,searchMenuName])

  

  const seleteDetail = props.seleteDetail || [{ value: "", label: i18next.t("residentialHistory:All") }, { value: "liveing", label: i18next.t("residentialHistory:Live") }, { value: "quit", label: i18next.t("residentialHistory:Move out") }]
  return (
    <Translation >
      {
        t =>
          <div className='row searchFunction mb-5 mt-5'>
            <div className='col-12 text-right' style={{ display: 'inline' }}>
              {
                props.selectIs &&
                <div className='floatLeft'>
                  {
                    props.text &&
                    <label className='floatLeft labelInline'>{props.text[0]}: &nbsp;&nbsp;</label>
                  }

                  <select
                    name='searchTypePerson'
                    className='form-control searchDate'
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}>
                    {
                      seleteDetail.map((n, ind) => {
                        return <option value={n.value} key={ind}>{n.label}</option>
                      })
                    }
                  </select>
                </div>
              }

              {
                props.selectAndSearchIs &&
                <div className='floatLeft'>
                  <label className='floatLeft labelInline'>{props.text[0]}: &nbsp;&nbsp;</label>
                  <div style={{ position: 'relative', textAlign: 'left' }}>
                    <Select
                      defaultValue={""}
                      classNamePrefix='inputBoxList'
                      options={seleteDetail}
                      onChange={(e) => setSearchTypePerson(e.value)}
                      required={true}
                      isSearchable={true}
                    />
                    <input name="searchType" type="text" value={searchType}
                      style={{ position: "absolute", top: 0, left: 180, width: 5, zIndex: -1 }}
                      // onChange={() => setFocus.value = ""} ref={el => setFocus = el}
                      required={true} />
                  </div>
                </div>
              }

              {
                props.selectDropDown &&
                <div className='floatLeft'>
                  <div className="btn-group dropdown" id="product-DropDown">
                    <button type="button" className="btn btn-light dropdown-toggle"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {props.selectDropDownText}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <React.Fragment>
                        <span className="dropdown-item cursor"
                          onClick={() => props.changeActiveStatus(props.printList, true)}>{t("contact_list:Enable")}</span>
                        <span className="dropdown-item cursor"
                          onClick={() => props.changeActiveStatus(props.printList, false)}>{t("contact_list:Disable")}</span>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              }


              {
                props.buttonDownload && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'download_file_print' }) &&
                <div className='floatLeft'>
                  <button className='btn btn-outline-secondary m-1 w42 h42' onClick={props.handlePropsMultiDownload}
                  >
                    <img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/downloadFile/download.png'} />
                  </button>
                </div>
              }

              {
                props.buttonDeleted && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'download_file_delete' }) &&
                <div className='floatLeft'>
                  <button className='btn btn-outline-secondary m-1 w42 h42' onClick={props.handlePropsOnVoid}>
                    <img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/downloadFile/delete.png'} />
                  </button>
                </div>
              }

              {
                props.buttonShare &&
                <div className='floatLeft'>
                  <button className='btn btn-outline-secondary m-1 w42 h42' onClick={props.handlePropsMultiShare}>
                    <img style={{ width: "16px", height: "20px" }} src={process.env.PUBLIC_URL + '/images/icons/icons8-share.png'} />
                  </button>
                </div>
              }

              {
                props.searchBoxId &&
                <div className='floatRight'>
                  <input type="text" name='search' value={searchBoxId} className='form-control searchBox'
                    placeholder={props.searchBoxText ? `${t("silvermanguard:search")}${props.searchBoxText}` : t("silvermanguard:search")}
                    // onKeyUp={ (e) => setSearchBoxId(e.target.value)} 
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setSearchBoxId(e.target.value)} />
                  <span className='spanINBox' onClick={onSreach}>
                    <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                  </span>
                </div>
              }

              {
                props.IsPrint &&
                <div className='floatRight '>
                  {
                    props.printData ?
                      <div className="btn-group dropdown" id="product-DropDown">
                        <button type="button" className="btn-primary-scale-outline dropdown-toggle"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {props.printText ? props.printText : t("PageList:Print")}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          {
                            props.printData &&
                            props.printData?.map((item, index_Item) => {
                              return (
                                <Link
                                  className="dropdown-item"
                                  key={index_Item}
                                  target={"_blank"}
                                  to={{ pathname: item.link }}>
                                  {t(item.text)}
                                </Link>
                              )
                            })
                          }
                        </div>
                      </div>
                      :
                      <Link target={"_blank"} to={props.printLink}>
                        <button type="button" className="btn-primary-scale-outline">
                          {props.printText ? props.printText : t("PageList:Print")}
                        </button>
                      </Link>
                  }

                </div>
              }
              <div className='floatRight'>
                {
                  props.listSelectMenuName &&
                  <div style={{ position: 'relative', textAlign: 'left'}}>
                    <Select
                      styles={customStyles1}
                      placeholder={t("uploadFileList:Docs Type")}
                      classNamePrefix='inputBoxList'
                      options={props.listSelectMenuNameOption}
                      onChange={(e) => setMenuName(e.value)}
                      required={true}
                      isSearchable={true}
                    />
                    <input name="searchMenuName" type="text" value={searchMenuName}
                      style={{ position: "absolute", top: 0, left: 180, width: 5, zIndex: -1 }}
                      />
                  </div>
                }
              </div>

              <div className='floatRight mr-6'>
                {
                  props.listSelectBox &&
                  <div style={{ position: 'relative', textAlign: 'left'}}>
                  <Select
                    styles={customStyles}
                    placeholder={t('uploadFileList:File Type')}
                    classNamePrefix='inputBoxList'
                    options={props.listSelectBoxOption}
                    onChange={(e) => setSearchFileType(e.value)}
                    required={true}
                    isSearchable={true}
                  />
                  <input name="searchFileType" type="text" value={searchFileType}
                    style={{ position: "absolute", top: 0, left: 180, width: 5, zIndex: -1 }}
                     />
                  </div>
                }
              </div>
              <div className='floatRight'>
                {
                  props.startDateIs &&
                  <React.Fragment>
                    <div className='datePicker' style={{ display: 'inline-flex' }}>
                      <label className='floatLeft labelInline'>{props?.startDateText ? props?.startDateText : t("silvermanguard:date")}</label>
                      <DatePicker
                        name='startDate'
                        className={`floatLeft form-control searchDate ${props?.iconDate}`}
                        selected={startDateIs}
                        onChange={(e) => setStartDateIs(e)}
                        dateFormat={props?.dateFormat ? props?.dateFormat : "dd/MM/yyyy"}
                        showMonthDropdown={props?.showMonth}
                        showYearDropdown={props?.showYear}
                        placeholderText={props?.dateText ? props?.dateText : 'dd/mm/yyyy'}
                        showMonthYearPicker={props?.showYear && props?.showMonth}
                      />
                    </div>

                    {/* <input type="date" name='startDate' value={startDateIs} className='floatLeft form-control searchDate'
                      placeholder='01/07/2021' onChange={(e) => setStartDateIs(e.target.value)} /> */}
                  </React.Fragment>
                }

                {
                  props.endDateIs &&
                  <React.Fragment>
                    <div className='datePicker' style={{ display: 'inline-flex' }}>
                      <label className='floatLeft labelInline'>{t("silvermanguard:dateTo")}</label>
                      {
                        props.autoChange ?
                          <DatePicker
                            name='endDate'
                            className={`floatLeft form-control searchDate ${props?.iconDate}`}
                            selected={endDateIs}
                            onChange={(e) => setEndDateIs(e)}
                            onCalendarClose={onSreach}
                            dateFormat="dd/MM/yyyy"
                            placeholderText='dd/mm/yyyy'
                          // minDate={this.props.minDate}
                          // maxDate={this.props.maxDate}
                          />
                          :
                          <DatePicker
                            name='endDate'
                            className={`floatLeft form-control searchDate ${props?.iconDate}`}
                            selected={endDateIs}
                            onChange={(e) => setEndDateIs(e)}
                            dateFormat={props?.dateFormat ? props?.dateFormat : "dd/MM/yyyy"}
                            showMonthDropdown={props?.showMonth}
                            showYearDropdown={props?.showYear}
                            showMonthYearPicker={props?.showYear && props?.showMonth}
                            placeholderText={props?.dateText ? props?.dateText : 'dd/mm/yyyy'}
                          />
                      }

                      {/* {
                        props.autoChange &&
                        <span className='spanINBox' onClick={onSreach}>
                          <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                        </span>
                      } */}

                    </div>
                  </React.Fragment>
                }

              </div>
            </div >
          </div >
      }
    </Translation >

  );
}

export default SeacrhDetails;