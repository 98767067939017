import React, { Component } from 'react';
import '../styles/dailyJournal.scss';
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from 'react-relay';
import environment from "../../env/environment";
import $ from "jquery";
import { Helmet } from "react-helmet";
import numberWithComma from "../../libs/numberWithComma";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import i18n from 'i18next'
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

const bankTransfer_print = graphql`
    query bankTransferQuery($iddoc: String) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
       
        allAccountRecordGroup(refTransaction: $iddoc) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }

        userConfig {
            id languages
        }

    }
`;

class BankTransfer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        };
    }
    componentDidMount() {        
        setTimeout(() => {
            let page = $('.print-daily-journal-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            $('tbody tr:last-child td').css({paddingBottom: diff});
        }, 2000);
    }


    print() {
        window.print();
    }

    render() {
        let keyname = Math.random().toString(36).substring(7);
        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>BankTransfer</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={bankTransfer_print}
                    cacheConfig={{ use_cache: false }}
                    variables={
                        {
                            iddoc: this.props.match.params.id,
                        }
                    }
                    render={({ error, props }) => {
                        if (error) {
                            return <div className="alert alert-danger" role="alert">{error.message}</div>;
                        } else if (props) {

                            return (
                                <div className="print-daily-journal-a4" key={keyname}>
                                    <div className="subpage">

                                        <div className="head">
                                            <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"1fr 5fr"}}>
                                                <div style={{minWidth:100,minHeight:100}}>
                                                    <img src={props.selfProject.logo} alt="silverman" />
                                                </div>
                                                <div>
                                                    <span className="text-center" style={{ position: "absolute" }}>
                                                        <div><strong style={{ fontSize: 12 }}>{props.userConfig.languages === "en" ? (props.selfProject.nameEn || props.selfProject.name): props.selfProject.name}</strong></div>
                                                        <div>{props.userConfig.languages === "en" ? (props.selfProject.addressEn || props.selfProject.address) : props.selfProject.address} โทร {props.selfProject.juristicContactNumber || '-'}</div>
                                                        <div>เลขประจำตัวผู้เสียภาษี {props.selfProject.taxIdNumber || '-'}</div>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="detail">
                                            <div className="customer-detail">
                                                <strong style={{ fontSize: 12 }}>สมุดรายวันทั่วไป</strong>
                                                <br />
                                                <div>รายละเอียด {props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.name}</div>
                                            </div>
                                            <div className="document-detail">
                                                เลขที่เอกสาร&emsp;{props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.refNumber}<br />
                                                {props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.refTransaction !== props.allAccountRecordGroup.edges[0].node.refNumber &&
                                                    <React.Fragment>
                                                        เลขที่อ้างอิง &emsp;{props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.refTransaction}<br />
                                                    </React.Fragment>
                                                }
                                                วันที่&emsp;&nbsp;{format(props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                                            </div>
                                        </div>
       

                                        <div className="invoice-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th width={80} className="text-center">
                                                            <strong>รหัสบัญชี</strong>
                                                        </th>
                                                        <th className="text-center">
                                                            <strong>ชื่อบัญชี</strong>
                                                        </th>
                                                        <th className="text-center">
                                                            <strong>คำอธิบาย</strong>
                                                        </th>
                                                        <th width={90} className="text-center">
                                                            <strong>เดบิต</strong>
                                                        </th>
                                                        <th width={90} className="text-center">
                                                            <strong>เครดิต</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.allAccountRecordGroup.edges.length > 0 &&
                                                        <React.Fragment>
                                                            {props.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((account_reccord) =>
                                                                account_reccord.node.debit > 0 &&
                                                                <tr key={account_reccord.node.id}>
                                                                    <td className="text-center">{account_reccord.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                                    <td>{account_reccord.node.chartOfAccountCode.name}</td>
                                                                    {/*<td>{account_reccord.node.name}</td>*/}
                                                                    <td>{props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.name}</td>
                                                                    <td className="text-right">{numberWithComma(account_reccord.node.debit)}</td>
                                                                    <td className="text-right">{numberWithComma(account_reccord.node.credit)}</td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    }

                                                    {props.allAccountRecordGroup.edges.length > 0 &&
                                                        <React.Fragment>
                                                            {props.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((account_reccord) =>
                                                                account_reccord.node.credit > 0 &&
                                                                <tr key={account_reccord.node.id}>
                                                                    <td className="text-center">{account_reccord.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                                    <td>{account_reccord.node.chartOfAccountCode.name}</td>
                                                                    {/*<td>{account_reccord.node.name}</td>*/}
                                                                    <td>{props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.name}</td>
                                                                    <td className="text-right">{numberWithComma(account_reccord.node.debit)}</td>
                                                                    <td className="text-right">{numberWithComma(account_reccord.node.credit)}</td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td className="text-center" style={{ borderRight: "none" }}>
                                                            <strong>รวม</strong>
                                                        </td>
                                                        <td colSpan={2} className="text-left" style={{ borderLeft: "none" }}>
                                                            <strong style={{ fontSize: 12 }}>                                                                
                                                                {props.userConfig.languages === 'th' ? 
                                                                (ThaiBaht(props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.totalDebit)):
                                                                (ConvertNumberToTextEng(props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.totalDebit))}    
                                                            </strong>
                                                        </td>
                                                        <td className="text-right">
                                                            <strong style={{ fontSize: 12 }}>{numberWithComma(props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.totalDebit)}</strong>
                                                        </td>
                                                        <td className="text-right">
                                                            <strong style={{ fontSize: 12 }}>{numberWithComma(props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.totalCredit)}</strong>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        

                                        <div className="footer">
                                            <div className="pt-5">
                                                <span className="box-left">
                                                    ..................................................<br />
                                                    ผู้จัดทำ
                                                </span>
                                                <span className="box-left">
                                                    ..................................................<br />
                                                    ผู้ตรวจสอบ
                                                </span>
                                                <span className="box-left">
                                                    ..................................................<br />
                                                    ผู้อนุมัติ
                                                </span>
                                            </div>
                                            <div>
                                                <span className="box-left">
                                                    วันที่ ................................
                                                </span>
                                                <span className="box-left">
                                                    วันที่ ................................
                                                </span>
                                                <span className="box-left">
                                                    วันที่ ................................
                                                </span>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            )
                        }
                    }}
                />
            </React.Fragment>

        );

    }
}

export default BankTransfer;
