import React from 'react';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from 'i18next';
import ComponentPagination from "../../libs/componentPagination";
import {graphql} from "babel-plugin-relay/macro";
import Pagination from "../../libs/newPagination";
import {format} from "date-fns";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import _ from "lodash";
import thLocale from "date-fns/locale/th";
import Swal from "sweetalert2";
import {commitMutation} from "react-relay";
import ServerExportElectricHistory from "./serverExportElectricMeterHistory.js"
import {Redirect} from 'react-router';
import Loading from "../../libs/loading"

const query = graphql`
    query electricMeterListHistoryQuery($first: Int, $last: Int) {
        allElectricMeterUnit(first: $first, last: $last, changeElectricMeter: true){
            totalCount
            edges{
                node{
                    id
                    issuedDate
                    meterValue
                    residential{
                        name
                    }
                    remark
                    added
                    editDate
                }
            }
        }
        

    }
`;

const mutation = graphql`
    mutation electricMeterListHistoryMutation($input: DeleteChangeElectricMeterInput!){
        deleteChangeElectricMeter(input: $input){
            ok
        }
    }
`;


class ElectricMeterListHistory extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state.data_list = false;
        this.state.electric_meter_id = false;
        this.onDeleteItem = this.onDeleteItem.bind(this);
    }

    componentDidMount() {
        this.getMeterList();
    }

    getMeterList() {
        this.setState({data_list: false}, () => {
            let variables = {
                first: this.state.first,
                last: this.state.last,
            };

            fetchQuery(environment, query, variables).then(results => {
                if(_.isObject(results.allElectricMeterUnit)){
                    this.setState({data_list: results.allElectricMeterUnit})
                }

            });

        });

    }

    onEditMeter(item) {
        this.setState({electric_meter_id: item.node.id})
    }

    onDeleteItem(item) {

        Swal.fire({
            title: i18n.t("electric_meter_record:Are you sure to delete this note?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("electric_meter_record:Yes"),
            cancelButtonText: i18n.t("electric_meter_record:Cancel")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        id: item.node.id
                    }
                };
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.setState({loading: false});
                            if (response.deleteChangeElectricMeter.ok) {
                                Swal.fire(i18n.t("electric_meter_record:Save successfully"), '', 'success').then(() => {
                                   this.getMeterList();
                                });
                            } else {
                                Swal.fire(i18n.t("electric_meter_record:Failed to save"), '', 'warning');
                            }
                        },
                        onError: (err) => {
                            this.setState({loading: false});
                            Swal.fire('Error!', i18n.t("electric_meter_record:Please try again"), 'warning')
                        },
                    },
                );
            }
        });

    }

    render() {
        if (this.state.electric_meter_id) {
            return <Redirect to={"/accounting/electric-meter/create_history/" + this.state.electric_meter_id}/>
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-10">
                        <h3 className="mb-4">
                            <Link to="/accounting">
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                     alt="back" className="back"/>
                            </Link>
                            <Translation>
                                {
                                    t => <span>{t('accounting:electric meter history')}</span>
                                }
                            </Translation>
                        </h3>
                    </div>
                    <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_electric_meter_print'}) &&
                        <ServerExportElectricHistory state={this.state}/>
                    }
                    </div>
                </div>
                <div className="content-inner">
                    <div className="row">
                         {this.state.data_list &&
                        <React.Fragment>
                            <div className="col-12">
                                <form className="card">
                                    <table className="table table-bordered">
                                        <thead>
                                        <Translation>
                                            {t=>
                                            <tr className="bg-light text-center">
                                                <th width="60">{t("electric_meter_record:Room No.")}</th>
                                                <th width="60">{t("electric_meter_record:Recording date")}</th>
                                                <th width="60">{t("electric_meter_record:Year/month")}</th>
                                                <th width="60">{t("electric_meter_record:Number of record")}</th>
                                                <th width="100">{t("electric_meter_record:Note")}</th>
                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_electric_meter_edit'}) &&
                                                    <th width="20"/>
                                                }
                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_electric_meter_delete'}) &&
                                                <th width="20"/>
                                                }
                                            </tr>}
                                        </Translation>
                                        </thead>
                                        <tbody>
                                        {this.state.data_list.edges.map((data)=>{
                                            return(
                                                <tr key={data.node.id}>
                                                    <td>{data.node.residential.name}</td>
                                                    <td className="text-center">{format(data.node.editDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                    <td className="text-center">{format(data.node.issuedDate, 'MM/YYYY', {locale: thLocale})}</td>
                                                    <td className="text-right">{data.node.meterValue}</td>
                                                    <td>{data.node.remark}</td>
                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_electric_meter_edit'}) &&
                                                    <td className="text-center">
                                                        <React.Fragment>
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                alt="delete-icon" className="show-icon"
                                                            />
                                                            <Translation>
                                                                {t=>
                                                                <span
                                                                    className="cursor grey-color show-icon ml-1 underline hover"
                                                                    onClick={() => this.onEditMeter(data)}>{t("electric_meter_record:Edit")}</span>}
                                                            </Translation>
                                                        </React.Fragment>
                                                    </td>
                                                    }
                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_electric_meter_delete'}) &&
                                                    <td className="text-center">
                                                        <React.Fragment>
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                alt="delete-icon" className="show-icon"
                                                            />
                                                            <Translation>
                                                                {t=>
                                                                <span
                                                                    className="cursor grey-color show-icon ml-1 underline hover"
                                                                    onClick={() => this.onDeleteItem(data)}>{t("electric_meter_record:Delete")}</span>}
                                                            </Translation>
                                                        </React.Fragment>

                                                    </td>
                                                    }
                                                </tr>
                                            )
                                        })}

                                        </tbody>
                                    </table>
                                </form>
                            </div>

                            <Pagination changePage={this.changePage} first={this.state.first}
                                        last={this.state.last}
                                        totalCount={this.state.data_list.totalCount}/>

                            <div style={{height: 200}}/>
                        </React.Fragment>

                        }
                        {!this.state.data_list &&
                        <Loading/>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ElectricMeterListHistory;
