import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {commitMutation} from "react-relay";

const mutation = graphql`
    mutation otherReceiverMutationMutation($input: CreateOtherReceiveAndOtherReceiveTransactionInput!){
        createOtherReceiveAndOtherReceiveTransaction(input:$input){
            ok
            warningText
            clientMutationId
            newOr{
                id
            }
        }
    }`;

export default function createOtherReceive(variables, callback, error_callback, uploadables) {
    commitMutation(
        environment,
        {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback();
            },
        },
    )
}
// export default {createOtherReceive};
