import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import JuristicManagerList from './juristicManagerList';
import NoMatch from "../../components/noMatch";
import JuristicManagerChange from './juristicManagerChange';
import JuristicManagerEdit from './juristicManagerEdit';

class JuristicManager extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={JuristicManagerList}/>
                <PrivateRoute exact path={`${this.props.match.url}/change-manager/:id?`} component={JuristicManagerChange}/>
                <PrivateRoute exact path={`${this.props.match.url}/edit/:id`} component={JuristicManagerEdit}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default JuristicManager;