import { graphql } from "babel-plugin-relay/macro";

const query = graphql`
   query chooseTenantQuery($tenantid: String,$site_Id: ID ) {
        chooseTenant (tenantid: $tenantid) {
            edges{
                node{
                    id
                    firstName
                    lastName
                    phone
                    email
                    householder(residential_Project_Site_Id:$site_Id){
                        edges {
                            node {
                                residential {
                                    name
                                    floor
                                    size
                                    status
                                    address
                                    ownershipNumber
                                    homeNumber
                                    villageBuilding
                                    villageNumber
                                    lane
                                    soi
                                    postalCode
                                    province
                                    district
                                    subDistrict
                                    mailingVillageBuilding
                                    mailingVillageNumber
                                    mailingLane
                                    mailingPostalCode
                                    mailingProvince
                                    mailingDistrict
                                    mailingSubDistrict
                                }
                            }
                        }

                    }
                    boadPersonal(site_Id:$site_Id){
                    edges{
                        node{
                            id
                            image
                            signature
                            position
                            authorization
                            financial
                            status
                            note
                            residential{
                                        name
                                        floor
                                        size
                                        status
                                        address
                                        ownershipNumber
                                        homeNumber
                                        villageBuilding
                                        villageNumber
                                        lane
                                        soi
                                        postalCode
                                        province
                                        district
                                        subDistrict
                                        mailingVillageBuilding
                                        mailingVillageNumber
                                        mailingLane
                                        mailingPostalCode
                                        mailingProvince
                                        mailingDistrict
                                        mailingSubDistrict
                            }
                        }
                    }
                    }
                    boadWork(site_Id:$site_Id){
                    edges{
                        node{
                            id
                            nameOfCompany
                            position
                            dateOfPosition
                            dateOfEndPosition
                            state
                        } 
                    } 
                    }
                    boadEducation(site_Id:$site_Id){
                    edges{
                        node{
                            id
                            name
                            degree
                            state
                        } 
                    } 
                    }
                    boadFilepdf(site_Id:$site_Id){
                    edges{
                        node{
                            id
                            nameFile
                            fileUpload
                            state
                        } 
                    } 
                    }
                    boadTerm(site_Id:$site_Id){
                    edges{
                        node{
                            id
                            startDate
                            expDate  
                        } 
                    } 
                    }
                }
            }
        }
        
    }
`;

export default function getQueryTenant() {
    return query
}