import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import ChequeDepositListTable from "./chequeControlListTable";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Search from "../../SearchInput";
import "./style/chequeListTable.scss";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from "i18next";

const chequeControl = graphql`
  query chequeControlListAllQuery($bankAccountId: String) {
    chequeControl(status:true, bankAccountId: $bankAccountId) {
      totalCount
      edges{
          node{
              id
              docNumber
              bankName
              branch
              bankLogo
              accountType
              accountName
              accountNameEn
              accountNumber
              updated
              reorderPoint
              chequeDetails(status:true){
                  edges{
                      node{
                          id
                          status
                          noNumber
                          startCheque
                          endCheque
                          lastCheque
                          chequeNo(status:true){
                              totalCount
                              edges{
                                  node{
                                      id
                                      status
                                      noNumber
                                  }
                              }
                          }
                      }
                  }
              }
              
          }
      }          
  }
  }
`;

class ChequeDepositListAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      temp_start_date: null,
      temp_end_date: null,
      start_date: null,
      end_date: null,
    };
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onChangeSearch(text) { this.setState({ search: text }); }


  handleChange(e) { this.setState({ [e.target.name]: e.target.value }) }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box" id="invoice-list">
                <BackButtonIcon
                  LinkBack={"/accounting"}
                  LinkText={i18next.t("finance:cheque_control")}
                />

                <div className="content-inner">
                  <div className="row">
                    <div className="col-3" />
                    <div className="col-6" />
                    <div className="col-3">
                      <Search
                        callback_function={this.onChangeSearch}
                        search={this.state.search}
                      />
                      {/* <div className="calendars-wrapper">
                    <form>
                      <input
                        type="text"
                        placeholder="ค้นหา"
                        // value={this.state.textSearch}
                        // onChange={(event) => this.setState({ textSearch: event.target.value })}
                        onKeyDown={this.handleKeyDown}
                        className="form-control inputSearch"
                      />
                    </form>
                  </div> */}
                    </div>
                  </div>
                  <div className="mt-2 mb-2">
                    <ChequeDepositListTable
                      query={chequeControl}
                      status={this.props.match.params.status}
                      search={this.state.search}
                      start_date={this.state.start_date}
                      end_date={this.state.end_date}
                    />
                  </div>
                </div>
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ChequeDepositListAll;
