import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import OtherExpenseCreateUpdateForm from "./core/otherExpenseCreateUpdateForm"
import { graphql } from "babel-plugin-relay/macro";
import { Redirect } from 'react-router';
import Loading from '../../../libs/loading';
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from 'i18next';

const allOtherExpense = graphql`
    query otherExpenseCreateFormQuery($id: ID!) {
        contact(id: $id) {
            id
            refNumber
            name
            firstName
            lastName
            vat

            registeredAddress
            registeredCountry
            registeredProvince
            registeredDistrict
            registeredCity
            registeredPostalCode
            typeOfContact
            typeOfSupplier
        }
        productViewer{
            allProduct (type_In: "expense",isActive: true){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        totalLeftInStock
                        taxRate
                        typeOfIncomeOther
                    }
                }
            }
        }
        viewer{
            allChartOfAccount(codeList: ["1111","1151", "1152","1153", "1154", "1210", "1230-01", "2121", "2122", "2123", "2124-01", "2124-02", "2124-03", "2124-04", "2125", "2126", "2210-09", "2124-05","2410", "51", "52", "53", "54", "55", "56", "58"]){
                edges{
                    node{
                        id
                        chartOfAccountCode
                        name

                    }
                }
            }
        }
          selfProject{
            haveVat
        }
        allSettingAsset(settingAssetId: "", typeAsset: ""){
            edges{
                node{
                    id
                    typeAsset
                    depreciationRatePercent
                    depreciationRate
                    lifeTimeYear
                }
            }
        } 
    }
`;

class OtherExpenseCreateForm extends Component {

    render() {
        if (this.props.contactList.length > 0) {
            return (
                <Translation>
                    {t =>
                        <div className="container-fluid box">
                            <BackButtonIcon
                                LinkBack={"/accounting/expense/other_expense/form/select_customer"}
                                LinkText={i18next.t('expense:add_other_expense')}
                            />
                            <QueryRenderer
                                environment={environment}
                                query={allOtherExpense}
                                variables={{ id: this.props.contactList[0] }}
                                cacheConfig={{ use_cache: false }}
                                render={({ error, props }) => {
                                    if (props) {
                                        // set cost to price
                                        const whtopen = props.contact?.typeOfSupplier === "FOUNDATION" || props.contact?.typeOfSupplier === "ASSOCIATION" ? false : true;
                                        const vatopen = props.contact?.typeOfSupplier === "FOUNDATION" || props.contact?.typeOfSupplier === "ASSOCIATION" ? false : true;
                                        const contactWho = props.contact ? false : true;
                                        let no_setting_asset = true
                                        if (props.allSettingAsset?.edges.length === 11) {
                                            no_setting_asset = false
                                        }
                                        return <OtherExpenseCreateUpdateForm contactList={this.props.contactList}
                                            contact={props.contact} invoiceQuery={props}
                                            query={allOtherExpense}
                                            category={this.props.accountType}
                                            product_and_service={props.productViewer.allProduct.edges}
                                            chart_of_account={props.viewer.allChartOfAccount.edges}
                                            next_page={'/accounting/expense/other_expense/list/all'}
                                            back_page={'/accounting/expense/other_expense/form/select_customer'}
                                            other_expense={true}
                                            not_other_channel={true}
                                            project_have_vat={props.selfProject.haveVat}
                                            disableNewRow={false}
                                            openVat={vatopen}
                                            openWht={whtopen}
                                            contactWho={contactWho}
                                            no_setting_asset={no_setting_asset}
                                        />;
                                    } else {
                                        return <Loading />
                                    }
                                }}
                            />
                        </div>
                    }
                </Translation>
            )
        }
        return <Redirect to="/accounting/expense/other_expense/form/select_customer" />;
    }
}

export default OtherExpenseCreateForm;
