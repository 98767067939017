/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DebsFreeCertificationRequestStatus = "ACTIVE" | "APPROVE" | "DRAFT" | "EDIT" | "PAID" | "VOID" | "%future added value";
export type DebsFreeCertificationStatus = "ACTIVE" | "DRAFT" | "VOID" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type wrapperPrintDebtFreeQueryVariables = {|
  debsFreeCertificationId?: ?$ReadOnlyArray<?string>
|};
export type wrapperPrintDebtFreeQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +bankCompCode: ?string,
    +bankServiceCode: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
    +postcodeProject: ?string,
    +provinceProject: ?string,
    +districtProject: ?string,
    +subDistrictProject: ?string,
  |},
  +allJuristicCompany: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +currentManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +image: ?string,
        +signature: ?string,
        +prefix: ?string,
        +firstName: ?string,
        +lastName: ?string,
      |}
    |}>,
    +totalCount: ?number,
  |},
  +allDebsFreeCertification: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +status: DebsFreeCertificationStatus,
        +statusPrint: boolean,
        +dateStamp: boolean,
        +printDate: ?any,
        +dueDate: ?any,
        +creatorVoid: ?string,
        +voidDate: ?any,
        +voidNote: ?string,
        +debsFreeCertificationRequest: {|
          +id: string,
          +docNumber: string,
          +added: any,
          +updated: any,
          +contact: {|
            +id: string,
            +name: string,
            +firstName: string,
            +lastName: string,
          |},
          +requestingDate: ?any,
          +creatorApprove: ?string,
          +approvalDate: ?any,
          +transactionDebtType: ?string,
          +tenant: {|
            +firstName: string,
            +lastName: ?string,
          |},
          +receive: ?{|
            +id: string,
            +docNumber: string,
          |},
          +otherReceive: ?{|
            +id: string,
            +docNumber: string,
          |},
          +status: DebsFreeCertificationRequestStatus,
        |},
      |}
    |}>,
  |},
|};
export type wrapperPrintDebtFreeQuery = {|
  variables: wrapperPrintDebtFreeQueryVariables,
  response: wrapperPrintDebtFreeQueryResponse,
|};
*/


/*
query wrapperPrintDebtFreeQuery(
  $debsFreeCertificationId: [String]
) {
  selfProject {
    id
    name
    address
    logo
    juristicContactNumber
    keyProjectQr
    bankCompCode
    bankServiceCode
    taxIdNumber
    typeOfProject
    postcodeProject
    provinceProject
    districtProject
    subDistrictProject
  }
  allJuristicCompany {
    edges {
      node {
        id
        name
      }
    }
  }
  currentManager {
    edges {
      node {
        id
        image
        signature
        prefix
        firstName
        lastName
      }
    }
    totalCount
  }
  allDebsFreeCertification(debsFreeCertificationId: $debsFreeCertificationId) {
    totalCount
    edges {
      node {
        id
        docNumber
        status
        statusPrint
        dateStamp
        printDate
        dueDate
        creatorVoid
        voidDate
        voidNote
        debsFreeCertificationRequest {
          id
          docNumber
          added
          updated
          contact {
            id
            name
            firstName
            lastName
          }
          requestingDate
          creatorApprove
          approvalDate
          transactionDebtType
          tenant {
            firstName
            lastName
            id
          }
          receive {
            id
            docNumber
          }
          otherReceive {
            id
            docNumber
          }
          status
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "debsFreeCertificationId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyProjectQr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankCompCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankServiceCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postcodeProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provinceProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "districtProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subDistrictProject",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicCompanyNodeConnection",
  "kind": "LinkedField",
  "name": "allJuristicCompany",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicCompanyNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JuristicCompanyNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicManagerNodeConnection",
  "kind": "LinkedField",
  "name": "currentManager",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicManagerNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JuristicManagerNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signature",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prefix",
              "storageKey": null
            },
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "debsFreeCertificationId",
    "variableName": "debsFreeCertificationId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusPrint",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateStamp",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorVoid",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidNote",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestingDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorApprove",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvalDate",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionDebtType",
  "storageKey": null
},
v26 = [
  (v1/*: any*/),
  (v10/*: any*/)
],
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "ReceiveNode",
  "kind": "LinkedField",
  "name": "receive",
  "plural": false,
  "selections": (v26/*: any*/),
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "OtherReceiveNode",
  "kind": "LinkedField",
  "name": "otherReceive",
  "plural": false,
  "selections": (v26/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperPrintDebtFreeQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "DebsFreeCertificationNodeConnection",
        "kind": "LinkedField",
        "name": "allDebsFreeCertification",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DebsFreeCertificationNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DebsFreeCertificationNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DebsFreeCertificationRequestNode",
                    "kind": "LinkedField",
                    "name": "debsFreeCertificationRequest",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v10/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperPrintDebtFreeQuery",
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "DebsFreeCertificationNodeConnection",
        "kind": "LinkedField",
        "name": "allDebsFreeCertification",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DebsFreeCertificationNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DebsFreeCertificationNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DebsFreeCertificationRequestNode",
                    "kind": "LinkedField",
                    "name": "debsFreeCertificationRequest",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v10/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "77c28e5f4f146d62ca533c5c83e0bd39",
    "id": null,
    "metadata": {},
    "name": "wrapperPrintDebtFreeQuery",
    "operationKind": "query",
    "text": "query wrapperPrintDebtFreeQuery(\n  $debsFreeCertificationId: [String]\n) {\n  selfProject {\n    id\n    name\n    address\n    logo\n    juristicContactNumber\n    keyProjectQr\n    bankCompCode\n    bankServiceCode\n    taxIdNumber\n    typeOfProject\n    postcodeProject\n    provinceProject\n    districtProject\n    subDistrictProject\n  }\n  allJuristicCompany {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  currentManager {\n    edges {\n      node {\n        id\n        image\n        signature\n        prefix\n        firstName\n        lastName\n      }\n    }\n    totalCount\n  }\n  allDebsFreeCertification(debsFreeCertificationId: $debsFreeCertificationId) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        status\n        statusPrint\n        dateStamp\n        printDate\n        dueDate\n        creatorVoid\n        voidDate\n        voidNote\n        debsFreeCertificationRequest {\n          id\n          docNumber\n          added\n          updated\n          contact {\n            id\n            name\n            firstName\n            lastName\n          }\n          requestingDate\n          creatorApprove\n          approvalDate\n          transactionDebtType\n          tenant {\n            firstName\n            lastName\n            id\n          }\n          receive {\n            id\n            docNumber\n          }\n          otherReceive {\n            id\n            docNumber\n          }\n          status\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e27e55702065880e0afcc8b2988362c';

module.exports = node;
