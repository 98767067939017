/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteHouseHolderInput = {|
  id: string,
  note?: ?string,
  dateOut?: ?string,
  clientMutationId?: ?string,
|};
export type ownerDetailMutationVariables = {|
  input: DeleteHouseHolderInput
|};
export type ownerDetailMutationResponse = {|
  +deleteHouseHolder: ?{|
    +ok: ?boolean
  |}
|};
export type ownerDetailMutation = {|
  variables: ownerDetailMutationVariables,
  response: ownerDetailMutationResponse,
|};
*/


/*
mutation ownerDetailMutation(
  $input: DeleteHouseHolderInput!
) {
  deleteHouseHolder(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteHouseHolderPayload",
    "kind": "LinkedField",
    "name": "deleteHouseHolder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ownerDetailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ownerDetailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "99dc7024663c82e61a4915974c847ba8",
    "id": null,
    "metadata": {},
    "name": "ownerDetailMutation",
    "operationKind": "mutation",
    "text": "mutation ownerDetailMutation(\n  $input: DeleteHouseHolderInput!\n) {\n  deleteHouseHolder(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a2e8865293bd7fe55570527020a979a9';

module.exports = node;
