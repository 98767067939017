/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type pettyCashBringForwardSetCreateQueryVariables = {|
  id: string
|};
export type pettyCashBringForwardSetCreateQueryResponse = {|
  +setPettyCashDocNumberNext: ?string,
  +setPettyCash: ?{|
    +id: string,
    +docNumber: string,
    +added: any,
    +withdrawer: ?string,
    +description: ?string,
    +withdrawAmount: number,
    +issuedDate: ?any,
    +bankAccountTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
          +date: ?any,
          +imageSlip: ?string,
          +bankAccount: {|
            +bankName: string,
            +accountNumber: string,
          |},
        |}
      |}>
    |},
    +cashTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
        |}
      |}>
    |},
    +chequeTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +date: any,
          +price: number,
          +chequeNumber: string,
          +bankAccount: ?{|
            +id: string,
            +accountName: string,
            +branch: string,
            +bankName: string,
          |},
        |}
      |}>
    |},
  |},
  +allProjects: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +periodCreate: string
      |}
    |}>
  |},
|};
export type pettyCashBringForwardSetCreateQuery = {|
  variables: pettyCashBringForwardSetCreateQueryVariables,
  response: pettyCashBringForwardSetCreateQueryResponse,
|};
*/


/*
query pettyCashBringForwardSetCreateQuery(
  $id: ID!
) {
  setPettyCashDocNumberNext
  setPettyCash(id: $id) {
    id
    docNumber
    added
    withdrawer
    description
    withdrawAmount
    issuedDate
    bankAccountTransaction {
      edges {
        node {
          id
          price
          date
          imageSlip
          bankAccount {
            bankName
            accountNumber
            id
          }
        }
      }
    }
    cashTransaction {
      edges {
        node {
          id
          price
        }
      }
    }
    chequeTransaction {
      edges {
        node {
          id
          date
          price
          chequeNumber
          bankAccount {
            id
            accountName
            branch
            bankName
          }
        }
      }
    }
  }
  allProjects {
    edges {
      node {
        periodCreate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "setPettyCashDocNumberNext",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "withdrawer",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "withdrawAmount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageSlip",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "CashTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "cashTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CashTransactionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CashTransactionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "chequeTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChequeTransactionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChequeTransactionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v11/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chequeNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "bankAccount",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accountName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "branch",
                  "storageKey": null
                },
                (v13/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodCreate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashBringForwardSetCreateQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SetPettyCashNode",
        "kind": "LinkedField",
        "name": "setPettyCash",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "bankAccountTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pettyCashBringForwardSetCreateQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SetPettyCashNode",
        "kind": "LinkedField",
        "name": "setPettyCash",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "bankAccountTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab5491e33d35fd306dbf9933df8ee914",
    "id": null,
    "metadata": {},
    "name": "pettyCashBringForwardSetCreateQuery",
    "operationKind": "query",
    "text": "query pettyCashBringForwardSetCreateQuery(\n  $id: ID!\n) {\n  setPettyCashDocNumberNext\n  setPettyCash(id: $id) {\n    id\n    docNumber\n    added\n    withdrawer\n    description\n    withdrawAmount\n    issuedDate\n    bankAccountTransaction {\n      edges {\n        node {\n          id\n          price\n          date\n          imageSlip\n          bankAccount {\n            bankName\n            accountNumber\n            id\n          }\n        }\n      }\n    }\n    cashTransaction {\n      edges {\n        node {\n          id\n          price\n        }\n      }\n    }\n    chequeTransaction {\n      edges {\n        node {\n          id\n          date\n          price\n          chequeNumber\n          bankAccount {\n            id\n            accountName\n            branch\n            bankName\n          }\n        }\n      }\n    }\n  }\n  allProjects {\n    edges {\n      node {\n        periodCreate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '357aa8a65f26b364fbd08ffd63c9b47c';

module.exports = node;
