/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChequeDepositStatus = "DEPOSIT" | "NOT_DEPOSIT" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type chequeDepositListAllQueryVariables = {|
  status?: ?string,
  first?: ?number,
  last?: ?number,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
|};
export type chequeDepositListAllQueryResponse = {|
  +chequeDepositViewer: ?{|
    +allChequeDeposit: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chequeNumber: string,
          +refNumber: ?string,
          +contact: ?{|
            +name: string,
            +firstName: string,
            +lastName: string,
            +typeOfContact: ContactTypeOfContact,
          |},
          +unknownContact: ?string,
          +date: any,
          +price: number,
          +status: ChequeDepositStatus,
          +description: ?string,
          +docNumber: ?string,
          +depositDate: ?any,
        |}
      |}>,
    |}
  |}
|};
export type chequeDepositListAllQuery = {|
  variables: chequeDepositListAllQueryVariables,
  response: chequeDepositListAllQueryResponse,
|};
*/


/*
query chequeDepositListAllQuery(
  $status: String
  $first: Int
  $last: Int
  $search: String
  $start_date: DateTime
  $end_date: DateTime
) {
  chequeDepositViewer {
    allChequeDeposit(status: $status, first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date) {
      totalCount
      edges {
        node {
          id
          chequeNumber
          refNumber
          contact {
            name
            firstName
            lastName
            typeOfContact
            id
          }
          unknownContact
          date
          price
          status
          description
          docNumber
          depositDate
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chequeNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unknownContact",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "chequeDepositListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChequeDepositViewer",
        "kind": "LinkedField",
        "name": "chequeDepositViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ChequeDepositNodeConnection",
            "kind": "LinkedField",
            "name": "allChequeDeposit",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeDepositNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChequeDepositNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "chequeDepositListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChequeDepositViewer",
        "kind": "LinkedField",
        "name": "chequeDepositViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ChequeDepositNodeConnection",
            "kind": "LinkedField",
            "name": "allChequeDeposit",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChequeDepositNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChequeDepositNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cf09be51c5355f3b49680889d6d5df2",
    "id": null,
    "metadata": {},
    "name": "chequeDepositListAllQuery",
    "operationKind": "query",
    "text": "query chequeDepositListAllQuery(\n  $status: String\n  $first: Int\n  $last: Int\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n) {\n  chequeDepositViewer {\n    allChequeDeposit(status: $status, first: $first, last: $last, search: $search, startDate: $start_date, endDate: $end_date) {\n      totalCount\n      edges {\n        node {\n          id\n          chequeNumber\n          refNumber\n          contact {\n            name\n            firstName\n            lastName\n            typeOfContact\n            id\n          }\n          unknownContact\n          date\n          price\n          status\n          description\n          docNumber\n          depositDate\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bcb20b86bf5264b7ad030a0d23969abc';

module.exports = node;
