import React, {Component} from 'react';
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import Wrapper from "../../../../components/wrapper";
import SelectContact from "../../../income/selectContact";
import ReceiptDepositMovementReport from "./receiptDepositMovementReport";
import ExportReceiptDepositMovementAll from "./server_export_all";

class SelectRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_list: [],
            accountType: "receipt_deposit_movement",
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <div id="wrapper-content" >
                    <AccountingTopMenuNavigation mini={true}/>
              
                   
                    {
                        this.props.match.params.status === "select-customer" &&
                             <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                            initialContactType="residential"
                                            nextLink="/accounting/report/account-receivable/receipt_deposit_movement/list"
                                            backLink="/accounting/report/account-receivable"/>
                    }
                    {this.props.match.params.status === 'select-customer' &&  <ExportReceiptDepositMovementAll />}
                    {this.props.match.params.status === 'list' &&
                        <ReceiptDepositMovementReport customerList={this.state.customer_list}/>
                    }

                </div>
            </Wrapper>
        );
    }
}

export default SelectRoom;