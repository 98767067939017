import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";

class Navigation extends Component {
    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink to="/accounting/income/credit-note/list/all">
                                        {t('creditNote:All')} 
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/income/credit-note/list/wait">
                                        {t('creditNote:Unpaid')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/income/credit-note/list/paid">
                                        {t('creditNote:Paid')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/income/credit-note/list/void">
                                        {t('creditNote:Canceled list')}
                                    </NavLink>
                                </li>
        
        
                            </ul>
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

export default Navigation;