import React from 'react';
import Switch from "react-switch";


const SwitchComponents = ({
    onColor = '#1567B4',
    offColor = '#D9D9D9',
    color = '#FFF',
    fontSize = 15,
    labelOff = 'off',
    labelOn = 'on',
    onChange = () => { },
    id = '',
    checked = false,
    disabled = false }) => {
    return (
        <Switch
            id={id}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            onColor={onColor}
            offColor={offColor}
            uncheckedIcon={false}
            checkedIcon={false}
        // uncheckedIcon={
        //     <div
        //         style={{
        //             display: "flex",
        //             justifyContent: "center",
        //             alignItems: "center",
        //             height: "100%",
        //             fontSize: fontSize,
        //             color: color,
        //             paddingRight: 2
        //         }}
        //     >
        //         {labelOff}
        //     </div>}
        // checkedIcon={
        //     <div
        //         style={{
        //             display: "flex",
        //             justifyContent: "center",
        //             alignItems: "center",
        //             height: "100%",
        //             fontSize: fontSize,
        //             color: color,
        //             paddingLeft: 2
        //         }}
        //     >
        //         {labelOn}
        //     </div>
        // }
        />
    );
};

export default SwitchComponents;