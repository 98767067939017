import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import ResidentialhistoryList from './residentialhistoryList';
import NoMatch from "../../components/noMatch";

class ResidentialHistory extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list`} component={ResidentialhistoryList}/>
                {/* <PrivateRoute exact path={`${this.props.match.url}/create/:id?`} component={CreateOwnerShip}/> */}
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default ResidentialHistory;