import React, { Component } from "react";
import { Translation } from "react-i18next";
import { NavLink } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <div className="row" id="navigation-tab">
        <div className="col">
          <Translation>
            {t =>
              <ul>
                <li>
                  <NavLink exact={true} to="/contact/committee/pending">{t("chatGroup:pending")}</NavLink>
                </li>
                <li>
                  <NavLink to="/contact/committee/finished">{t("chatGroup:finished")}</NavLink>
                </li>
                <li>
                  <NavLink to="/contact/committee/cancled">{t("chatGroup:cancled")}</NavLink>
                </li>
              </ul>}
          </Translation>
        </div>
      </div>
    );
  }
}

export default Navigation;
