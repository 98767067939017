import React from "react";
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import ComponentPagination from "../../libs/componentPagination";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Pagination from "../../libs/newPagination";
import RegisterTopMenuNavigation from "../registerTopMenuNavigation";
import CoownerList from './coownerList'
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from "./navigation";
import "../styles/register.scss";

import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import numberWithCommas from "../../libs/numberWithComma";
import ExportResidential from "./server_export_residential";
import localStoragePage from "../../libs/localstorage";
import _ from "lodash";
import QuerySumaryOwnershipRatio from "./querySumaryOwnershipRatio";
import Loading from "../../libs/loading";
import SeacrhDetail from "../../components/search";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import SortOrderAsc from "../../components/FucntionLib/sortAsc";


const allResidential = graphql`
  query residentialListQuery(
    $status: String
    $search: String
    $first: Int
    $last: Int
    $typeDownload: String
    $order: String
  ) {
    allResidential(
      status: $status
      search: $search
      first: $first
      last: $last
      typeDownload: $typeDownload
      residentialList: true
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          floor
          size
          status
          otherStatus
          isUserActivate
          ownershipRatio
          ownershipRatioShow
          unitNumber
          residentialHouseholder {
            edges {
              node {
                type
                id
                tenant {
                  firstName
                  lastName
                  countries
                  nameTitle
                }
              }
            }
          }
          type {
            id
            name
          }
          ownershipArea
        }
      }
    }
    areaSummary(
      typeDownload: $typeDownload
      residentialType: $status
      search: $search
    )
    ownershipProportionSummary(
      typeDownload: $typeDownload
      residentialType: $status
      search: $search
    )
    residentialCountAll(residentialType: $status, search: $search)
    allProjects {
      edges {
        node {
          id
          typeOfProject
          site{
            domain
          }
        }
      }
    }    
  }
`;

class ResidentialList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.search = "";
    this.state.filter_download = "all";
    this.state.temp_filter_download = "all";

    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePropsChange = this.handlePropsChange.bind(this);

  }

  componentDidMount() {
    fetchQuery(environment, QuerySumaryOwnershipRatio).then((data) => {
      let sumOwnershipRatio = _.sumBy(data.allResidential.edges, (o) => o.node.ownershipRatio)
      let sumOwnershipRatioShow = _.sumBy(data.allResidential.edges, (o) => o.node.ownershipRatioShow)
      this.setState({ sumaryOwnershipRatio: sumOwnershipRatio })
      this.setState({ sumaryOwnershipRatioShow: sumOwnershipRatioShow })
    })
  }

  componentDidUpdate() {
    if (this.state.status !== this.props.status) {
      this.setState(
        {
          status: this.props.status,
        },
        () => {
          this.goFirst();
        }
      );
    }
  }

  onChangeSearch(text) {
    this.setState({ search: text, filter_download: this.state.temp_filter_download });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSearch(event) {
    this.setState({
      search: event.target.value,
      filter_download: this.state.temp_filter_download,
    });
  }
  handlePropsChange(channel_list) {
    let search = ""
    let start_date = null
    let end_date = null
    channel_list.forEach((e, index) => {
      if (e.name === "search") {
        search = e.value
      }
      else if (e.name === "start_date" && e.value !== '') {
        start_date = e.value
      }
      else if (e.name === "end_date" && e.value !== '') {
        end_date = e.value
      }

    });


    this.setState({
      search: search,
      start_date: start_date,
      end_date: end_date,
      filter_download: this.state.temp_filter_download,
    })
  }


  render() {
    let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <RegisterTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="residential">
            <Translation>
              {t =>
                <BackButtonIcon
                  LinkBack={"/register"}
                  LinkText={_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ? textData?.data?.registerList.residential : t("register:residential")}
                  boxHtmlText={
                    <div className="col-md-8">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "tenant_tenant_create",
                      }) && (
                          <Link to="/register/residential/create">
                            <button style={{ backgroundColor: '#1567B4', color: "#fff" }}
                              type="button"
                              className="btn add float-right"
                            >
                              <img
                                src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                                alt="plus"
                              />
                              <Translation>
                                {(t) =>
                                  _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                    <span>{textData?.data?.registerList.addResidential}</span>
                                    :
                                    <span>{t("register:add_residential")}</span>
                                }
                              </Translation>
                            </button>
                          </Link>
                        )}
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "tenant_tenant_print",
                      }) && (
                          <Link
                            to={
                              "/register/residential/account/gen-code-a4/" +
                              this.state.temp_filter_download
                            }
                          >
                            <button style={{ border: '1px solid #1567B4', color: ' #1567B4' }}
                              type="button"
                              className="add btn btn-outline-primary float-right mr-3"
                            >
                              <Translation>
                                {(t) => (
                                  <span>{t("register:create_register_code")}</span>
                                )}
                              </Translation>
                            </button>
                          </Link>
                        )}
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "tenant_tenant_print",
                      }) && (
                          <ExportResidential
                            state={this.state}
                            status={this.props.match.params.status}
                          />
                        )}
                    </div>
                  }
                />
              }
            </Translation>

            <div className="content-inner">
              <Navigation />
              <div className="row justify-content-end mb-2">
                <div className="col">
                  <Translation>
                    {t =>
                      this.props.match.params.status !== 'coowner' &&
                      <div className="form-group">
                        <select
                          name="temp_filter_download"
                          onChange={this.handleChange}
                          value={this.state.temp_filter_download}
                          className="form-control dropdown-custom-arrow"
                        >
                          <option value="all">
                            {t("residential:All")}
                          </option>
                          <option value="download_app">
                            {t("residential:Used the application")}
                          </option>
                          <option value="non_download_app">
                            {t("residential:Not use the application")}
                          </option>
                        </select>
                      </div>
                    }
                  </Translation>
                </div>
                <div className="col-3">
                  {/* <Search
                    callback_function={this.onChangeSearch}
                    search={this.state.search}
                    handleSearch={this.handleSearch}
                  /> */}

                  {
                    this.props.match.params.status !== 'coowner' &&
                    <SeacrhDetail
                      selectIs={false}
                      startDateIs={false}
                      endDateIs={false}
                      searchBoxId={true}
                      search={this.state.search}
                      handlePropsChange={this.handlePropsChange}
                    />
                  }
                </div>
              </div>

              <QueryRenderer
                environment={environment}
                query={allResidential}
                cacheConfig={{ use_cache: false }}
                variables={{
                  search: this.state.search,
                  first: this.state.first,
                  last: this.state.last,
                  status: this.props.match.params.status,
                  typeDownload: this.state.filter_download,
                  order: localStorage.getItem('site_id') && localStorage.getItem('site_id') !== '295' ? "added" : "name"
                }}
                render={({ error, props }) => {
                  if (error) {
                    return (
                      <div className="alert alert-danger" role="alert">
                        {error.message}
                      </div>
                    );
                  } else if (props) {
                    let size_all = 0;
                    let total_ownership = 0;
                    let total_ownershipRatio = 0;
                    let order_id =
                      this.state.first - localStoragePage.getPageLimit();
                    let count_all = JSON.parse(props.residentialCountAll);
                    const copyOfResidentialEdges = [...props.allResidential.edges];
                    let sortedResidentialEdges = []
                    if (localStorage.getItem("site_id") === '636') {
                      sortedResidentialEdges = SortOrderAsc(copyOfResidentialEdges)
                    } else {
                      sortedResidentialEdges = copyOfResidentialEdges
                    }


                    return (
                      <React.Fragment>
                        {props.allProjects.edges.map((project) => {
                          return (
                            <React.Fragment>
                              {this.state.filter_download === "all" && this.props.match.params.status !== 'coowner' && (
                                <Translation>
                                  {t =>
                                    <div>
                                      <div className="green-dot" /> {t("residential:Used the application")}{" "}
                                      {count_all.count_load_app}{" "}
                                      {project.node.typeOfProject === "CONDOMINIUM"
                                        ? t("residential:Unit")
                                        : t("residential:Units")}
                                      &emsp;
                                      <div className="grey-dot" />{" "}
                                      {t("residential:Not use the application")}{" "}
                                      {count_all.count_not_load_app}{" "}
                                      {project.node.typeOfProject === "CONDOMINIUM"
                                        ? t("residential:Unit")
                                        : t("residential:Units")}
                                    </div>}
                                </Translation>
                              )}
                              {
                                this.props.match.params.status === 'coowner' ?
                                  <CoownerList />
                                  :
                                  <div
                                    className="card fade-up"
                                    key={project.node.id}
                                  >
                                    <div className="table-responsive">
                                      <table className="table table-hover">
                                        <thead className="thead-light">
                                          <Translation>
                                            {t =>
                                              <tr>
                                                <th>{t("residential:No.")}</th>
                                                <th>
                                                  {
                                                    project.node.typeOfProject === "CONDOMINIUM" || !project.node?.site?.domain?.includes("sklasmanagement") ?
                                                      <>{t("residential:Room")}</> :
                                                      <>{t("residential:House No.")}</>
                                                  }
                                                </th>
                                                {!_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on &&
                                                  <th className="text-center">{t("residential:Unit No")}</th>}

                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                  <th>{textData?.data?.registerList.ownership}</th>
                                                  :
                                                  <th>{t("residential:Ownership")}</th>}

                                                <th className="text-center">{t("residential:Nationality")}</th>
                                                <th>{t("residential:Type")}</th>
                                                <th className="text-center">
                                                  {t("residential:Area")}{" "}
                                                  {project.node.typeOfProject ===
                                                    "CONDOMINIUM"
                                                    ? <>({t("residential:Sq m")})</>
                                                    : <>({t("residential:Sq wa")})</>}
                                                </th>
                                                <th className="text-center">%</th>
                                                <th className="text-center">
                                                  {t("residential:Ownership ratio")}
                                                </th>
                                                <th className="text-center">
                                                  {project.node.typeOfProject ===
                                                    "CONDOMINIUM"
                                                    ? <>{t("residential:Floor")}</>
                                                    : <>{t("residential:Number of floor")}</>}
                                                </th>

                                                <th>{t("residential:Status")}</th>
                                                <th className="text-center">
                                                  {t("residential:Number of residents")}
                                                </th>
                                              </tr>}
                                          </Translation>
                                        </thead>
                                        <tbody>
                                          {sortedResidentialEdges.map(
                                            (residential, index) => {
                                              let row_number = order_id + index + 1;
                                              let owner = [];
                                              let countries = [];
                                              let count = 0;
                                              size_all += residential.node.size;
                                              total_ownershipRatio += residential.node.ownershipRatio;

                                              residential.node.residentialHouseholder.edges.forEach(
                                                (resident) => {

                                                  if (
                                                    resident.node.type === "OWNER"
                                                  ) {
                                                    let nameTitle = resident.node
                                                      .tenant.nameTitle
                                                      ? resident.node.tenant
                                                        .nameTitle
                                                      : "คุณ";
                                                    owner.push(
                                                      nameTitle +
                                                      resident.node.tenant
                                                        .firstName +
                                                      " " +
                                                      (resident.node.tenant
                                                        .lastName || "")
                                                    );
                                                    countries.push(
                                                      resident.node.tenant.countries
                                                    );
                                                  }
                                                  count = count + 1;
                                                }
                                              );
                                              total_ownership +=
                                                (residential.node.size /
                                                  props.areaSummary) *
                                                100;

                                              return (
                                                <tr
                                                  key={residential.node.id}
                                                  className="show"
                                                >
                                                  <td>{row_number}</td>
                                                  <td>
                                                    <Link
                                                      to={{
                                                        pathname:
                                                          "/register/residential/detail/room-detail/" +
                                                          residential.node.id,
                                                      }}
                                                    >
                                                      {residential.node.name}
                                                    </Link>
                                                    {residential.node
                                                      .isUserActivate && (
                                                        <div className="green-dot" />
                                                      )}
                                                  </td>
                                                  {!_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on &&
                                                    <td className="text-center">{residential?.node?.unitNumber ? residential?.node?.unitNumber : "-"}</td>}
                                                  {owner.length > 0 ? (
                                                    <td>
                                                      {owner.map(
                                                        (owner_name, index) => {
                                                          return (
                                                            <p
                                                              className="p-margin"
                                                              key={index}
                                                            >
                                                              {owner_name}
                                                            </p>
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                  ) : (
                                                    <td>-</td>
                                                  )}
                                                  {countries.length > 0 ? (
                                                    <td className="text-center">
                                                      {countries.map(
                                                        (countries_name, index) => {
                                                          return (
                                                            <p
                                                              className="p-margin"
                                                              key={index}
                                                            >
                                                              {countries_name}
                                                            </p>
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                  ) : (
                                                    <td className="text-center">
                                                      -
                                                    </td>
                                                  )}
                                                  <td>
                                                    {residential.node.type.name}
                                                  </td>
                                                  <td className="text-center">
                                                    {numberWithCommas(
                                                      residential.node.size
                                                    )}
                                                  </td>
                                                  <td className="text-center">
                                                    {numberWithCommas(
                                                      residential.node.ownershipArea
                                                    )}
                                                    %
                                                  </td>
                                                  <td className="text-center">
                                                    {residential.node.ownershipRatioShow ? residential.node.ownershipRatioShow : numberWithCommas(residential.node.ownershipRatio)}
                                                  </td>
                                                  <td className="text-center">
                                                    {residential.node.floor}
                                                  </td>

                                                  {
                                                    residential.node.status ===
                                                      "FOR_RENT" ? (
                                                      <Translation>{t => <td>{t("residential:For rent")}</td>}</Translation>
                                                    )
                                                      : residential.node.status === "LIVE" ? (
                                                        <Translation>{t => <td>{t("residential:Live")}</td>}</Translation>
                                                      )
                                                        : residential.node.status === "SEIZURE" ? (
                                                          <Translation>{t => <td>{t("residential:Seizure")}</td>}</Translation>
                                                        )
                                                          : residential.node.status === "AVAILABILITY" ? (
                                                            <Translation>{t => <td>{t("residential:Availability")}</td>}</Translation>
                                                          )
                                                            : residential.node.status === "OTHER" && (
                                                              <td>{residential.node.otherStatus}</td>
                                                            )
                                                  }
                                                  <td className="text-center">
                                                    {count}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                          {(props.allResidential.pageInfo
                                            .hasNextPage ||
                                            props.allResidential.pageInfo
                                              .hasPreviousPage) && (
                                              <tr>
                                                <td colSpan={5} />
                                                <Translation>{t => <td className="text-left">{t("residential:Total")}</td>}</Translation>
                                                <td className="text-center">
                                                  {numberWithCommas(size_all)}
                                                </td>
                                                <td className="text-center">
                                                  {numberWithCommas(total_ownership)}%
                                                </td>
                                                <td className="text-center">
                                                  {numberWithCommas(total_ownershipRatio)}
                                                </td>
                                                <td colSpan={3} />
                                              </tr>
                                            )}
                                          {!props.allResidential.pageInfo
                                            .hasNextPage && (
                                              <tr>
                                                <td colSpan={5} />
                                                <Translation>
                                                  {t =>
                                                    <td className="text-left">
                                                      {t("residential:All total")}
                                                    </td>}
                                                </Translation>
                                                <td className="text-center">
                                                  {numberWithCommas(
                                                    props.areaSummary
                                                  )}
                                                  {props.ownershipProportionSummary &&
                                                    "%"}
                                                </td>
                                                <td className="text-center">
                                                  {numberWithCommas(
                                                    props.ownershipProportionSummary
                                                  )}
                                                  {props.ownershipProportionSummary &&
                                                    "%"}
                                                </td>
                                                <td className="text-center">
                                                  {this.state.sumaryOwnershipRatioShow ? numberWithCommas(this.state.sumaryOwnershipRatioShow) : numberWithCommas(this.state.sumaryOwnershipRatio)}
                                                </td>
                                                <td colSpan={3} />
                                              </tr>
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                              }
                            </React.Fragment>
                          );
                        })}
                        {
                          this.props.match.params.status !== 'coowner' &&
                          <div className="row">
                            <Pagination
                              changePage={this.changePage}
                              first={this.state.first}
                              last={this.state.last}
                              totalCount={props.allResidential.totalCount}
                            />
                          </div>
                        }

                      </React.Fragment>
                    );
                  }
                  return <Loading />
                }
                }
              />
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ResidentialList;
