import './styles/index.scss';
import React, {Component} from 'react';
import ChangeProject from "./changeProject";
// import SearchMenu from "./searchMenu";
import Profile from "./profile";
import Menu from "./menu";
import ToggleMenuSwitch from './toggleMenuSwitch';
import Notification from './notification';

class Home extends Component {

    render() {
        return (
            <div id="header">
                <div className="logoHeader">
                    <ToggleMenuSwitch/>
                </div>
                <div className="top-menu">
                    <ChangeProject/>
                    {/* <SearchMenu/> */}
                    <Menu/>
                    <Profile/>
                    <Notification />
                </div>
            </div>
        );
    }
}

export default Home;