/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateAddressQueryVariables = {|
  id: string
|};
export type updateAddressQueryResponse = {|
  +residential: ?{|
    +id: string,
    +name: string,
    +homeNumber: ?string,
    +villageBuilding: ?string,
    +villageNumber: ?string,
    +lane: ?string,
    +soi: ?string,
    +postalCode: ?string,
    +province: ?string,
    +district: ?string,
    +subDistrict: ?string,
  |}
|};
export type updateAddressQuery = {|
  variables: updateAddressQueryVariables,
  response: updateAddressQueryResponse,
|};
*/


/*
query updateAddressQuery(
  $id: ID!
) {
  residential(id: $id) {
    id
    name
    homeNumber
    villageBuilding
    villageNumber
    lane
    soi
    postalCode
    province
    district
    subDistrict
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ResidentialNode",
    "kind": "LinkedField",
    "name": "residential",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "homeNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "villageBuilding",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "villageNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lane",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "soi",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "province",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "district",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subDistrict",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateAddressQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateAddressQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "866b1f4349defa78ad6ea5b7257f8f0e",
    "id": null,
    "metadata": {},
    "name": "updateAddressQuery",
    "operationKind": "query",
    "text": "query updateAddressQuery(\n  $id: ID!\n) {\n  residential(id: $id) {\n    id\n    name\n    homeNumber\n    villageBuilding\n    villageNumber\n    lane\n    soi\n    postalCode\n    province\n    district\n    subDistrict\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a7986e650fbed7bcaa31336121e91a61';

module.exports = node;
