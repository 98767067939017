
import React, {Component} from 'react';
import './style/bankEditModal.scss'
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import UpdateBankAccountMutation from './mutations/UpdateBankAccountMutation'
import _ from "lodash";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import upload from "../../../libs/upload";
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import API from '../../../api/optService'

const bankAccountDetail = graphql`
    query bankEditModalQuery($id : ID!){
        bankAccount(id: $id){
            id
            docNumber
            accountNumber
            accountName
            bankName
            branch
            bankLogo
            accountType
            receivePayment
            makePayment
            crossBilling
            status
            carryingBalance
            hasCheque
            description
            closeRemark
            numberOfMonth
            mobilePayment
            issuedDate
            dueDate
            taxRate
            accountNameEn
            numberOfUnit
            unit
            notiStatus
            prompay
        }
        selfProject {
            prompayQr
            prompayQrShow
            keyProjectQr
        }
        lastBankAccount {
            edges{
                node{
                    notiStatus
                    numberOfUnit
                    unit
                }
            }          
        }
        myUser {
            id username
            juristic {
                id firstName lastName image email phone
            }
        }
    }
`;

const $ = window.jQuery;

class BankEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cheque: false,
            account_number: '',
            account_name: '',
            description: '',
            carrying_balance: '',
            receive_payment: true,
            make_payment: true,
            crossBilling: false,
            status: true,
            account_type: 'saving_account',
            bank_name: '',
            branch:'',
            new_code: 'Loading',
            image_upload: '',
            image: '',
            close_remark:'',
            number_of_month:'',
            notiStatus : false,
            unit : 'day',
            numberOfUnitMonth : 1,
            numberOfUnitDay : '',
            disableInterest : false,

            loading: false,
            error: false,
            success: false,
            edit_loading: false,
            edit_error: false,
            edit_success: false,
            show_in_app:false,
            issued_date:null,
            due_date:null,
            tax_rate:null,
            mobile_payment:false,
            isPrompay:false,
            billerId:'',
            prompayQrShow:'',
            optionPayment:'',
            prompayQr:'',
            other:'',
            channelName:'',
            email:'',
            sms:'',
            radioType:''
            
        };

        this.onClickOpenModal = this.onClickOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.toggle_checkbox = this.toggle_checkbox.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputImage = this.handleInputImage.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.clearState = this.clearState.bind(this);
        this.handleInputChangeRadio = this.handleInputChangeRadio.bind(this);
        this.resizeImg = this.resizeImg.bind(this)
    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal', () => {
            this.clearState();
            if(this.state.edit_success || this.state.edit_error){
                this.props.refreshQuery();
            }
        });
    }

    onClickOpenModal() {
        $(this.modal).modal('show');
        this.setState({loading: true});
        fetchQuery(environment, bankAccountDetail, {id: this.props.id}).then(data => {
            if (data) {
                this.setState({
                    cheque: data.bankAccount.hasCheque,
                    account_number: data.bankAccount.accountNumber,
                    account_name: data.bankAccount.accountName,
                    description: data.bankAccount.description,
                    carrying_balance: data.bankAccount.carryingBalance === null ? '' : data.bankAccount.carryingBalance,
                    receive_payment: data.bankAccount.receivePayment,
                    make_payment: data.bankAccount.makePayment,
                    crossBilling: data.bankAccount.crossBilling,
                    status: data.bankAccount.status,
                    account_type: data.bankAccount.accountType.toLowerCase(),
                    bank_name: data.bankAccount.bankName,
                    branch:data.bankAccount.branch,
                    new_code: data.bankAccount.docNumber,
                    image: data.bankAccount.bankLogo,
                    close_remark: data.bankAccount.closeRemark,
                    number_of_month: data.bankAccount.numberOfMonth,
                    issued_date:data.bankAccount.issuedDate !== null?new Date(data.bankAccount.issuedDate):null,
                    due_date:data.bankAccount.dueDate !== null?new Date(data.bankAccount.dueDate):null,
                    tax_rate:data.bankAccount.taxRate,
                    mobile_payment:data.bankAccount.mobilePayment,
                    account_name_en:data.bankAccount.accountNameEn,
                    unit:_.lowerCase(data.bankAccount.unit),
                    numberOfUnitDay:_.lowerCase(data.bankAccount.unit) === 'day' ? data.bankAccount.numberOfUnit : 1, 
                    numberOfUnitMonth:_.lowerCase(data.bankAccount.unit) === 'month' ? data.bankAccount.numberOfUnit : 1, 
                    notiStatus:data.bankAccount.notiStatus,
                    disableInterest : data.bankAccount.notiStatus,
                    billerId: data.selfProject.keyProjectQr,
                    isPrompay: data.bankAccount.prompay,
                    prompayQrShow: data.selfProject.prompayQrShow,
                    optionPayment: data.bankAccount.crossBilling ? 'crossBilling' : data.bankAccount.prompay ? 'QRCode' : 'other'
                });
            }
            if(data.lastBankAccount.edges.length > 0 && !data.bankAccount.notiStatus ){
                if(_.lowerCase(data.lastBankAccount.edges[0].node.unit) === 'day'){
                    this.setState({
                        unit : _.lowerCase(data.lastBankAccount.edges[0].node.unit) ,
                        numberOfUnitDay : data.lastBankAccount.edges[0].node.numberOfUnit
                    })
                }else{
                    this.setState({
                        unit : _.lowerCase(data.lastBankAccount.edges[0].node.unit),
                        numberOfUnitMonth : data.lastBankAccount.edges[0].node.numberOfUnit
                    })
                }
            }
            if(data.myUser.juristic){
                this.setState({
                    email: data.myUser.juristic.email,
                    sms: data.myUser.juristic.phone
                })
            }
            this.setState({loading: false, success: true});
        }).catch(err => {
            this.setState({loading: false, error: true});
        });

    }

    onCloseModal() {
        document.querySelector('#bank-edit-model').removeAttribute('inert');
        $(this.modal).modal('hide');
    }

    toggle_checkbox(e) {
        let name = e.currentTarget.name;
        let value = e.currentTarget.checked;
        this.setState({[name]: value});
        if(name === 'status'){
            this.setState({close_remark: ''});
        }
        if(e.currentTarget.checked){
            // this.setState({account_type: 'current_account'});
        }

        let state = {}
        if (e.currentTarget.name === 'mobile_payment') {
            if (e.currentTarget.checked) {
                state['receive_payment'] = true
                state[e.currentTarget.name] = e.currentTarget.checked
            }else{
                state['receive_payment'] = false
                state[e.currentTarget.name] = e.currentTarget.checked
            }
        }else{
            state[e.currentTarget.name] = e.currentTarget.checked
        }
        this.setState({...state})
    }

    handleChange = (event,name) => {
        if (event.target.name === 'tax_rate') {
            var t = event.target.value
            this.setState({ [event.target.name]: (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 4)) : t });            
        }else{
            this.setState({ [event.target.name]: event.target.value });
        }
      }

    toggle_noti_box = () => {
        this.setState({
            notiStatus : !this.state.notiStatus
        })
    }

    toggle_unit = (e) => {
        if(e.target.value !== this.state.unit){
            this.setState({
                unit : e.target.value
            })
        }
    }

    handleInputChange(e) {

        if (e.currentTarget.name === 'account_type' && e.currentTarget.value === 'current_account') {
            this.setState({cheque: true});
        }
        this.setState({[e.currentTarget.name]: e.currentTarget.value});
    }

    handleInputImage(e) {
        if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
            this.setState({
                image_upload: e.currentTarget.files[0],
                image: URL.createObjectURL(e.target.files[0])
            });
        }
    }

    onSubmit(e) {
        
        e.preventDefault();
        document.querySelector('#bank-edit-model').setAttribute('inert', 'true');
        // if(this.state.optionPayment  ===  'QRCode' && this.state.prompayQr.length <= 0){
        //     Swal.fire('Error!',"กรุณาใส่ QRCODE",'warning')
        //     return
        // }else{
            //old one
        //     this.setState({edit_loading: true, edit_success: false, edit_error: false});
        //     let input = {
        //         id: this.props.id,
        //         bankName: this.state.bank_name,
        //         accountType: this.state.account_type,
        //         branch: this.state.branch,
        //         receivePayment: this.state.receive_payment,
        //         makePayment: this.state.make_payment,
        //         crossBilling: this.state.optionPayment  ===  'crossBilling' ? true : false,
        //         status: this.state.status,
        //         carryingBalance: this.state.carrying_balance === '' ? 0.0 : this.state.carrying_balance,
        //         hasCheque: this.state.cheque,
        //         description: this.state.description,
        //         closeRemark: this.state.status ?  '' : this.state.close_remark,
        //         mobilePayment: this.state.mobile_payment,
        //         issuedDate: this.state.issued_date,
        //         dueDate: this.state.due_date,
        //         taxRate: this.state.tax_rate,
        //         notiStatus: this.state.notiStatus,
        //         numberOfUnit: this.state.unit === 'day' ? this.state.numberOfUnitDay : this.state.numberOfUnitMonth,
        //         unit: this.state.unit,
        //         prompay: this.state.optionPayment  ===  'QRCode' ? true : false,
        //         prompayQr: this.state.prompayQr[0]?.fileUpload || null,
        //         billerId: this.state.billerId || null
        //     };

        //    const uploadables = {bank_logo: this.state.image_upload};
        //    UpdateBankAccountMutation(input, uploadables, this.onSuccess, this.onError)
        //    this.onCloseModal()

           Swal.fire({
            title: 'ช่องทางการรับ OTP',
            input: 'radio',
            inputOptions: {
              email: 'Email',
              sms: 'SMS',
            },
            showCancelButton: true,
            html: '<input type="text" class="form-control" id="selectedOption" style="position:absolute; bottom:115px;width:200px;left:150px" disabled/><div id="optionAlert" style="position:absolute; bottom:82px;width:258px;left:138px;font-size:12px;font-family:Kanit;"></div>', 
            confirmButtonText: 'ตกลง',
            cancelButtonText:'ยกเลิก',
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  if (!value) {
                    Swal.showValidationMessage('Please enter your name');
                  }
                  resolve();
                }, 4000);
              });
            }
            }).then((result) => {
                if (result.value) {
                    const channel = result.value;
                    this.setState({
                    channelName: channel
                    })
                    var bodyFormData = new FormData();
                    bodyFormData.append('channel', channel);
                    API.postOTP(bodyFormData).then((res) => {
                    let reference = res.data.message;
                    if (res.data.statusMessage === "SUCCESS") {
                        Swal.fire({
                        title: 'กรุณาใส่รหัส OTP',
                        input: 'text',
                        inputAttributes: {
                            maxlength: 6 // Set the maximum length of text input to 6 characters
                        },
                        inputPlaceholder: 'รหัส OTP',
                        showCancelButton: true,
                        confirmButtonText: 'ตกลง',
                        cancelButtonText: 'ขอรหัสใหม่',
                        }).then((result) => {
                        if (result.value) {
                            const otpValue = result.value;
                            API.checkOTP(this.state.channelName,otpValue,reference).then((res) => {
                            if(res.data.ok){
                                this.setState({edit_loading: true, edit_success: false, edit_error: false});
                                let input = {
                                    id: this.props.id,
                                    bankName: this.state.bank_name,
                                    accountType: this.state.account_type,
                                    branch: this.state.branch,
                                    receivePayment: this.state.receive_payment,
                                    makePayment: this.state.make_payment,
                                    crossBilling: this.state.optionPayment  ===  'crossBilling' ? true : false,
                                    status: this.state.status,
                                    carryingBalance: this.state.carrying_balance === '' ? 0.0 : this.state.carrying_balance,
                                    hasCheque: this.state.cheque,
                                    description: this.state.description,
                                    closeRemark: this.state.status ?  '' : this.state.close_remark,
                                    mobilePayment: this.state.mobile_payment,
                                    issuedDate: this.state.issued_date,
                                    dueDate: this.state.due_date,
                                    taxRate: this.state.tax_rate,
                                    notiStatus: this.state.notiStatus,
                                    numberOfUnit: this.state.unit === 'day' ? this.state.numberOfUnitDay : this.state.numberOfUnitMonth,
                                    unit: this.state.unit,
                                    prompay: this.state.optionPayment  ===  'QRCode' ? true : false,
                                    prompayQr: this.state.prompayQr[0]?.fileUpload || null,
                                    billerId: this.state.billerId || null
                                };
                    
                            const uploadables = {bank_logo: this.state.image_upload};
                            UpdateBankAccountMutation(input, uploadables, this.onSuccess, this.onError)
                            this.onCloseModal()
                            }else if(res.data.ok === false){
                                Swal.fire(i18next.t('settingAccount:Failed to save'),res.data.message, 'warning').then(() => {
                                    return
                                })
                            }
                            }, () => {
                            Swal.fire(i18next.t('settingAccount:Failed to save'), '', 'warning').then(() => {
                                return
                            })
                            })
                        }else if (result.dismiss === Swal.DismissReason.cancel) {
                            // Handle Resend action
                            // Call API to resend OTP
                            API.postOTP(bodyFormData).then((res) => {
                                let reference = res.data.message;
                                if (res.data.statusMessage === "SUCCESS") {
                                    Swal.fire({
                                        title: 'กรุณาใส่รหัส OTP',
                                        input: 'text',
                                        inputAttributes: {
                                            maxlength: 6 // Set the maximum length of text input to 6 characters
                                        },
                                        inputPlaceholder: 'รหัส OTP',
                                        showCancelButton: true,
                                        confirmButtonText: 'ตกลง',
                                        cancelButtonText: 'ขอรหัสใหม่',
                                        }).then((result) => {
                                        if (result.value) {
                                            const otpValue = result.value;
                                            API.checkOTP(this.state.channelName,otpValue,reference).then((res) => {
                                            if(res.data.ok === true){
                                                this.setState({edit_loading: true, edit_success: false, edit_error: false});
                                                let input = {
                                                    id: this.props.id,
                                                    bankName: this.state.bank_name,
                                                    accountType: this.state.account_type,
                                                    branch: this.state.branch,
                                                    receivePayment: this.state.receive_payment,
                                                    makePayment: this.state.make_payment,
                                                    crossBilling: this.state.optionPayment  ===  'crossBilling' ? true : false,
                                                    status: this.state.status,
                                                    carryingBalance: this.state.carrying_balance === '' ? 0.0 : this.state.carrying_balance,
                                                    hasCheque: this.state.cheque,
                                                    description: this.state.description,
                                                    closeRemark: this.state.status ?  '' : this.state.close_remark,
                                                    mobilePayment: this.state.mobile_payment,
                                                    issuedDate: this.state.issued_date,
                                                    dueDate: this.state.due_date,
                                                    taxRate: this.state.tax_rate,
                                                    notiStatus: this.state.notiStatus,
                                                    numberOfUnit: this.state.unit === 'day' ? this.state.numberOfUnitDay : this.state.numberOfUnitMonth,
                                                    unit: this.state.unit,
                                                    prompay: this.state.optionPayment  ===  'QRCode' ? true : false,
                                                    prompayQr: this.state.prompayQr[0]?.fileUpload || null,
                                                    billerId: this.state.billerId || null
                                                };
                                    
                                            const uploadables = {bank_logo: this.state.image_upload};
                                            UpdateBankAccountMutation(input, uploadables, this.onSuccess, this.onError)
                                            this.onCloseModal()
                                            }else if(res.data.ok === false){
                                                Swal.fire(i18next.t('settingAccount:Failed to save'),res.data.message, 'warning').then(() => {
                                                    return
                                                })
                                            }
                                            }, () => {
                                            Swal.fire(i18next.t('settingAccount:Failed to save'), '', 'warning').then(() => {
                                                return
                                            })
                                            })
                                        }
                                        })


                                  }else if(res.data.ok === false){
                                    Swal.fire(i18next.t('settingAccount:Failed to save'),i18next.t(`settingAccount:${res.data.message}`), 'warning').then(() => {
                                        return
                                    })
                                  }
                            })
                          }
                        });
                    }else if(res.data.statusMessage === "FAIL"){
                        Swal.fire(i18next.t('settingAccount:Failed to save'),i18next.t(`settingAccount:${res.data.message}`), 'warning').then(() => {
                            return
                        })
                    }
                        
                    }, () => {
                    Swal.fire(i18next.t('settingAccount:Failed to save'), '', 'warning').then(() => {
                        return
                    })
                })
                }
            });


        // }

        document.addEventListener('input', function (event) {
            var target = event.target;
            if (target && target.matches('.swal2-radio input[type="radio"]')) {
                document.getElementById('selectedOption').value = this.state[target.value]
                if(target.value === 'email'){
                    if(this.state.email === ''){
                      document.getElementById('optionAlert').innerHTML = `
                        <span class='text-danger'>กรุณาตั้งค่าอีเมล <b>เมนูตั้งค่า > ตั้งค่าสิทธิ์ผู้ใช้งาน </b></span>`;
                    }else{
                        document.getElementById('optionAlert').textContent = "" 
                    }
                }

                if(target.value === 'sms'){
                    if(this.state.sms === ''){
                        document.getElementById('optionAlert').innerHTML = `
                        <span class='text-danger'>กรุณาตั้งค่าเบอร์โทร <b>เมนูตั้งค่า > ตั้งค่าสิทธิ์ผู้ใช้งาน </b></span>`;
                    }else{
                        document.getElementById('optionAlert').textContent = "" 
                    }
                }
          
                 
            }
        }.bind(this)); // Bind 'this' to ensure the correct context
     
    }

    onSuccess(response) {
        if(response.updateBankAccount.newBankAccount.id){
            Swal.fire(i18next.t("add_bank:Save successfully"), '', 'success')
            this.setState({edit_loading: false, edit_success: true});
            this.clearState();
        }else{
            this.setState({edit_loading: false, edit_error: true});
        }
       
    }

    onError() {
        this.setState({edit_loading: false, edit_error: true});
        alert('server error')
    }

    resizeImg(file) {
        let resizeImg;
         const resizeFile = (file) =>
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                file,
                160,
                160,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
                );
            });

           resizeImg =  resizeFile(file);

         return resizeImg
    }

    clearState() {
        this.setState({
            cheque: false,
            description: '',
            carrying_balance: '',
            receive_payment: true,
            make_payment: true,
            crossBilling: false,
            status: true,
            account_type: 'saving_account',
            bank_name: '',
            branch:'',
            new_code: 'Loading',
            image_upload: '',
            image: '',
            close_remark: '',
            issued_date:null,
            due_date:null,
            tax_rate:null,
        });
    }

    handleInputChangeRadio(e) {
        if(e.target.value === 'QRCode'){
            this.setState({
               billerId : '',
            })
        }
        this.setState({
             optionPayment: e.target.value,
            [e.target.name]: e.target.value
        });
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/bankQRcode" + path, file);
          resolve(res);
        });
    };

    handleInputFile = async (e) => {
        
        let file = e.currentTarget.files[0];
        let uploadFile = [];
        if (file) {
            let totalBytes = file.size;
             if (totalBytes >  10485760) {
                Swal.fire('ขนาดไฟล์ใหญ่เกินไป!', 'กรุณาเลือกไฟล์ใหม่', 'warning');
                return
            } else {
                let imgResize ;
                await this.resizeImg(file).then((data) => {
                    imgResize = data
                })
                this.callUpload('', imgResize).then((data) => {
                    uploadFile.push({
                          fileUpload : data.key.replace("silverman-storage/private/", ""), 
                          fileName :  file.name,
                          fileShow: data.location
                    })
                    this.setState({
                        prompayQr : uploadFile,
                        prompayQrShow:''
                    })
                });
         
            }
        }
    }

    deleteImage() {
        this.setState({ 
            prompayQr: '',
            prompayQrShow:''
         })
    }


    render() {
        return (
            <React.Fragment>
                <div id="bank-edit-model">
                    <span onClick={this.onClickOpenModal} className="cursor"><u>{this.props.code}</u></span>
                    <div className="modal fade bd-example-modal-lg" role="dialog"
                         aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal => this.modal = modal}>
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="col">
                                        <div className="row">
                                            <Translation>
                                                {t=>
                                                <h5 className="modal-title">
                                                    {t("finance:Modify bank")} {this.state.account_number}
                                                </h5>}
                                            </Translation>
                                        </div>
                                    </div>
                                    <div className="col form-inline bank-code">
                                        <div className="row">
                                            <input type="text" className="form-control text-center"
                                                   value={this.state.new_code} id="bank_code"
                                                   disabled/>
                                        </div>
                                    </div>
                                    <button type="button" className="close" onClick={this.onCloseModal}
                                            aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {this.state.edit_error &&
                                    <Translation>
                                        {t=>
                                        <div className="alert alert-danger text-left" role="alert">
                                            {t("finance:Unable to save bank information")}</div>}
                                    </Translation>
                                    }
                                    {this.state.edit_loading &&
                                    <Translation>
                                        {t=>
                                        <div className="alert alert-primary text-left" role="alert">
                                            {t("finance:Saving")}</div>}
                                    </Translation>
                                    }
                                    {this.state.edit_success &&
                                    <Translation>
                                        {t=>
                                        <div className="alert alert-primary text-left" role="alert">
                                            {t("finance:Save successfully")}</div>}
                                    </Translation>
                                    }

                                    {this.state.loading &&
                                        <Translation>{t=><div className="text-center">{t("finance:Fetching")}</div>}</Translation>
                                    }
                                    {this.state.error &&
                                        <Translation>{t=><div className="text-center">{t("finance:Failed to retrieve bank information")}</div>}</Translation>
                                    }
                                    {this.state.success &&
                                    <React.Fragment>

                                        <form id="form-edit" onSubmit={this.onSubmit}>


                                            <div className="row ">
                                                <div className="col-2">
                                                    <Translation>
                                                        {t=>
                                                        <label htmlFor="InputEditBankName"
                                                               className="col-form-label">
                                                            {t("finance:bank_name")}
                                                        </label>}
                                                    </Translation>
                                                </div>
                                                <div className="col-5">
                                                    <input type="text" className="form-control width-bank-name"
                                                           id="InputEditBankName"
                                                           placeholder="ชื่อธนาคาร" value={this.state.bank_name}
                                                           onChange={this.handleInputChange} name="bank_name" required/>
                                                </div>
                                                {this.state.account_type === 'current_account' &&
                                                    <div className="col-4 check_middle">
                                                        <div className="text-left custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                id="customEditCheckCheque" checked={this.state.cheque}
                                                                onChange={this.toggle_checkbox} name="cheque" disabled/>
                                                            <Translation>
                                                                {t=><label className="custom-control-label"
                                                                    htmlFor="customEditCheckCheque">{t("finance:Cheque")}</label>}
                                                            </Translation>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-1"/>
                                            </div>

                                            <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="branch"
                                                           className="col-2 col-form-label">
                                                        {t("finance:bank_branch")}
                                                    </label>}
                                                </Translation>
                                                <div className="col">
                                                    <input type="text" className="form-control" id="branch"
                                                           placeholder="สาขา" value={this.state.branch}
                                                           onChange={this.handleInputChange} name="branch" required/>
                                                </div>
                                                <div className="col-1"/>
                                            </div>

                                            <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="bankLogo"
                                                           className="col-2 col-form-label">
                                                        {t("finance:Logo bank")}
                                                    </label>}
                                                </Translation>
                                                <div className="col-6">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="customFile"
                                                               name="image_bank" value={this.state.image_bank}
                                                               onChange={this.handleInputImage}/>
                                                        <label className="custom-file-label text-left"
                                                               htmlFor="customFile">{this.state.image === '' ? 'Choose file...' :  this.state.image.length > 34 ? this.state.image.substr(0, 35) + '...' : this.state.image.name}</label>
                                                    </div>

                                                </div>
                                                <div className="col-3 text-left">
                                                <small className='text-red' style={{padding:'0 5px'}}>{i18next.t("Allaction:Each file should not exceed 10 MB")}</small>
                                                    {this.state.image &&
                                                    <img src={this.state.image} alt="member"
                                                         style={{width: 40}}/>
                                                    }
                                                  
                                                  
                                                </div>
                                                <div className="col-1"/>
                                            </div>


                                            <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="inputEmail3"
                                                           className="col-2 col-form-label">
                                                        {t('finance:account_type')}
                                                    </label>}
                                                </Translation>
                                                <div className="col">
                                                    <select className="form-control" name="account_type"
                                                            value={this.state.account_type}
                                                            onChange={this.handleInputChange} disabled>
                                                        <option value="current_account">บัญชีเงินฝากกระแสรายวัน หรือบัญชีเดินสะพัด (Current Account)</option>
                                                        <option value="saving_account">บัญชีเงินฝากออมทรัพย์ (Saving Deposit Account)</option>
                                                        <option value="fixed_deposit">บัญชีเงินฝากประจํา (Fixed Deposit Account)</option>
                                                        <option value="saving_deposit">บัญชีเงินฝากเผื่อเรียก (Saving Deposit Account)</option>
                                                    </select>
                                                </div>
                                                <div className="col-1"/>
                                            </div>

                                            {this.state.account_type === 'fixed_deposit' &&
                                            <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="InputBankMonthNum"
                                                           className="col-2 col-form-label">
                                                        {t("finance:Number of months")}
                                                    </label>}
                                                </Translation>
                                                <div className="col">
                                                    <input type="number" className="form-control" id="InputBankMonthNum"
                                                           min={1} name="number_of_month"
                                                           onChange={this.handleInputChange}
                                                           value={this.state.number_of_month} disabled/>
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                            }

                                        {this.state.account_type === "fixed_deposit"&&<div className="row mt-3">
                                            <Translation>
                                                {t=>
                                                <label htmlFor="issued_date"  className="col-2 col-form-label">
                                                    {t("finance:Start date")}
                                                </label>}
                                            </Translation>
                                            <div className="col-2 ">
                                                <DatePickerAdapter
                                                    
                                                    id="issued_date"
                                                    className="form-control"
                                                    name="issued_date"
                                                    selected={this.state.issued_date}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-1"/>
                                            <Translation>
                                                {t=>
                                                <label htmlFor="due_date" className="col-2 col-form-label  " >
                                                    {t("finance:Due date")}
                                                </label>}
                                            </Translation>
                                            <div className="col">
                                                <DatePickerAdapter
                                                    id="due_date"
                                                    name="due_date"
                                                    className="form-control"
                                                    selected={this.state.due_date}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>}

                                        { this.state.account_type === "fixed_deposit"&& <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="tax_rate"
                                                        className="col-2 col-form-label">
                                                        {t("finance:Interest")}
                                                    </label>}
                                                </Translation>
                                                <div className="col" style={{display:'flex'}} >
                                                <input  type="text" className="form-control" id="tax_rate" placeholder="ดอกเบี้ย" value={this.state.tax_rate}
                                                    name="tax_rate" onInput={this.handleChange} 
                                                    style={{borderRightWidth:0,borderTopRightRadius:0,borderBottomRightRadius:0,width:200}}
                                                    >
                                                    </input>
                                                        <span  className="form-control" style={{borderLeftWidth:0,width:35,borderTopLeftRadius:0,borderBottomLeftRadius:0,display:'flex',alignItems:'center'}} >%</span>
                                                </div>
                                                <div className="col-1"/>
                                        </div>}

                                            <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="InputBankAccountName"
                                                           className="col-2 col-form-label">
                                                        {t("finance:account_name")}
                                                    </label>}
                                                </Translation>
                                                <div className="col">
                                                    <input type="text" className="form-control"
                                                           id="InputBankAccountName"
                                                           placeholder="ชื่อบัญชี" value={this.state.account_name}
                                                           name="account_name" disabled={true}/>
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                            <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="InputBankAccountName"
                                                           className="col-2 col-form-label">
                                                        {t("finance:account name (English)")}
                                                    </label>}
                                                </Translation>
                                                <div className="col">
                                                    <input type="text" className="form-control"
                                                           id="InputBankAccountName"
                                                           value={this.state.account_name_en}
                                                           name="account_name" disabled={true}/>
                                                </div>
                                                <div className="col-1"/>
                                            </div>

                                            <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="InputBankAccountNum"
                                                           className="col-2 col-form-label">
                                                        {t("finance:account_no")}
                                                    </label>}
                                                </Translation>
                                                <div className="col">
                                                    <input type="text" className="form-control" id="InputBankAccountNum"
                                                           placeholder="เลขที่บัญชี" value={this.state.account_number}
                                                           name="account_number"
                                                           disabled={true}/>
                                                </div>
                                                <div className="col-1"/>
                                            </div>

                                            
                                            <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="InputBankAccountNum"
                                                           className="col-2 col-form-label">
                                                        {t("finance:Note")}
                                                    </label>}
                                                </Translation>
                                                <div className="col">
                                           <textarea className="form-control"
                                                     rows="3" name="description" value={this.state.description}
                                                     onChange={this.handleInputChange}/>
                                                </div>
                                                <div className="col-1"/>
                                            </div>

                                            {/* <div className="row mt-3">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="InputBankAccountNum"
                                                           className="col-2 col-form-label">
                                                        {t("finance:Bring_forward")}
                                                    </label>}
                                                </Translation>
                                                <div className="col">
                                                    <input type="number" className="form-control"
                                                           id="InputBankAccountNum"
                                                           step="0.01" name="carrying_balance"
                                                           onChange={this.handleInputChange}
                                                           value={this.state.carrying_balance}/>
                                                </div>
                                                <div className="col-1"/>
                                            </div> */}

                                            <div className="row mt-4">
                                                <div className="col-2"/>
                                                <div className="col-3 text-left">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                               id="customEditCheckOpen" checked={this.state.status}
                                                               onChange={this.toggle_checkbox} name="status"/>
                                                        <Translation>
                                                            {t=>
                                                            <label className="custom-control-label"
                                                                   htmlFor="customEditCheckOpen">{t("finance:Enable")}</label>}
                                                            </Translation>
                                                    </div>
                                                </div>
                                                <div className="col-3 text-left">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                               id="customEditShowInApp"
                                                               checked={this.state.mobile_payment}
                                                               onChange={this.toggle_checkbox} name="mobile_payment"/>
                                                        <Translation>
                                                            {t=><label className="custom-control-label"
                                                                   htmlFor="customEditShowInApp">{t("finance:Show in Silverman application")}</label>}
                                                            </Translation>
                                                    </div>
                                                </div>
                                                <div className="col-1"/>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-2"/>
                                                <div className="col-3 text-left">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                               id="customEditCheckReceive"
                                                               checked={this.state.receive_payment}
                                                               onChange={this.toggle_checkbox} name="receive_payment"/>
                                                        <Translation>
                                                            {t=><label className="custom-control-label"
                                                                   htmlFor="customEditCheckReceive">{t("finance:For receive money")}</label>}
                                                            </Translation>
                                                    </div>
                                                </div>
                                                <div className="col-3 text-left">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                               id="customEditCheckPayBank"
                                                               checked={this.state.make_payment}
                                                               onChange={this.toggle_checkbox} name="make_payment"/>
                                                        <Translation>
                                                            {t=><label className="custom-control-label"
                                                                   htmlFor="customEditCheckPayBank">{t("finance:For spend")}</label>}
                                                            </Translation>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-2"/>
                                                {!this.state.status &&
                                                    <div className="col-6 text-left">
                                                        <Translation>{t=><label htmlFor="closeRemark">{t("finance:Specify a note to close the account")}</label>}</Translation>
                                                        <textarea className="form-control" id="closeRemark"
                                                                    rows="2" name="close_remark"
                                                                    value={this.state.close_remark}
                                                                    onChange={this.handleInputChange} maxLength={256}
                                                                    required={true}/>
                                                </div> }
                                            </div>

                                            <div className="row mt-4">
                                            <div className="col-2"/>
                                            <div className="col-3 text-left">
                                                <div className="">
                                                {/* <input type="checkbox" className="custom-control-input"
                                                        id="customCheckCrossBilling" checked={this.state.crossBilling}
                                                        onChange={this.toggle_checkbox} name="crossBilling"/>
                                                <label className="custom-control-label"
                                                        htmlFor="customCheckCrossBilling">{t("add_bank:Use Cross Billing")}</label> */}
                                                <input type="radio" id="radioOption1" name="radioGroup" value="crossBilling" 
                                                 onChange={this.handleInputChangeRadio}
                                                 checked={this.state.optionPayment  ===  'crossBilling'  ? 'checked' :''}
                                                   />
                                                <label htmlFor="radioOption1" className='pl-2'>Cross Billing</label>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                {
                                                this.state.crossBilling ? 
                                                 <input type='text' name="billerId" className='form-control form-input' placeholder='กรุณาระบุเลข Biller ID'
                                                 disabled={this.state.optionPayment === 'QRCode'}
                                                 onChange={this.handleInputChange} value={this.state.billerId || ''} maxlength="15"
                                                // required
                                                 /> :''
                                                }
                                                {
                                                this.state.optionPayment  ===  'crossBilling' && !this.state.crossBilling ? 
                                                <input type='text' name="billerId"  onChange={this.handleChange} value={this.state.billerId || ''}  
                                                disabled={this.state.optionPayment === 'QRCode'}  placeholder='กรุณาระบุเลข Biller ID' 
                                                className='form-control form-input'  maxlength="15"
                                               // required
                                                />
                                                :''
                                                }
                                            </div> 
                                            <div className='col-4 pl-0'>                                                                                                
                                                {this.state.optionPayment  ===  'crossBilling' &&  
                                                <Translation>
                                                    {t=><span className='text-red '>*{t("finance:Please complete the number to be 15 digits.")}</span>}
                                                </Translation>}                                                                                                                    
                                            </div>  

                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-2"/>
                                                <div className="col-3 text-left">
                                                    <div>
                                                        <input type="radio" id="radioOption2" name="radioGroup" value="QRCode"
                                                        onChange={this.handleInputChangeRadio} 
                                                        checked={this.state.optionPayment  ===  'QRCode'  ? 'checked' :''}
                                                        />
                                                        <label htmlFor="radioOption2" className='pl-2'>QRCode</label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    {
                                                    <div className="uploadBTN" style={{ display: 'inline-flex'}}>
                                                    <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                        <button className={"btn"} disabled={this.state.optionPayment === 'crossBilling' || this.state.optionPayment === 'other'} >
                                                            <Translation>{t=><label>{t("finance:QR Code upload")}</label>}</Translation>
                                                        </button>
                                                        <input type="file" name='file' id="uploadFile" className='form-control uploadFile'
                                                            disabled={this.state.optionPayment === 'crossBilling' || this.state.optionPayment === 'other'} accept='.pdf,.png,.jpg' onChange={(e) => this.handleInputFile(e)}
                                                            />
                                                    </label>
                                                    </div> 
                                                    }
                                                <div>
                                                {
                                                this.state.prompayQr && _.map(this.state.prompayQr, (budget, index) =>{
                                                    return(
                                                        <div style={{position:'relative'}}>
                                                            <button className='btnDeleteImage' type={"button"} onClick={() => this.deleteImage(index)}>
                                                            <img src={process.env.PUBLIC_URL + "/images/feedback/closeImg.png"}  className="img-pre"/>
                                                            </button>
                                                            <img src={budget.fileShow} className='tt' style={{width:'150px'}}/>
                                                        </div>
                                                    )
                                                })
                                                }   
                                                </div> 
                                                {
                                                this.state.optionPayment  ===  'QRCode' && this.state.prompayQrShow ?
                                                <div style={{position:'relative'}}>
                                                    <button className='btnDeleteImage' type={"button"} onClick={() => this.deleteImage()}>
                                                    <img src={process.env.PUBLIC_URL + "/images/feedback/closeImg.png"}  className="img-pre"/>
                                                    </button>
                                                    <img src={this.state.prompayQrShow} className='tt' style={{width:'150px'}}/>
                                                </div>
                                                    : ''
                                                }
                                                </div>
                                                <div className='col-2'>
                                                <div className="tooltipImg">
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                            alt="question" className="question ml-1 mr-1" style={{width:'12px'}}/>
                                                    <span className="tooltiptext">
                                                            <Translation>{t=><label htmlFor="radioOption3" className='pl-2'>{t("finance:Example")}</label>}</Translation> <br/>
                                                            <Translation>{t=><label htmlFor="radioOption3" className='pl-2'>{t("finance:Please upload only image")}</label>}</Translation>
                                                            <img className='pl-2' src={process.env.PUBLIC_URL + '/images/tooltip/exp-qrcode.png'} style={{width:'80px'}}/>
                                                    </span>
                                                </div>
                                                </div>

                                            </div>

                                            <div className="row mt-4">
                                            <div className="col-2"/>
                                            <div className="col-3 text-left">
                                                <div className="">
                                                <input type="radio" id="radioOption3" name="radioGroup" value="other" 
                                                onChange={this.handleInputChangeRadio} checked={this.state.optionPayment  ===  'other'  ? 'checked' :''} />
                                                <Translation>{t=><label htmlFor="radioOption3" className='pl-2'>{t("finance:Other")}</label>}</Translation>
                                                </div>
                                            </div>
                                            
                                        </div>
                                            {this.state.account_type === 'fixed_deposit'&&
                                                <React.Fragment>
                                                    <div className="row mt-4">
                                                        <div className="col-2"/>
                                                        <div className="col-4 text-left">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input"
                                                                    id="notiStatus" checked={this.state.notiStatus}
                                                                    disabled={this.state.disableInterest} 
                                                                    onChange={this.toggle_noti_box} name="notiStatus"/>
                                                                <label className="custom-control-label"
                                                                    htmlFor="notiStatus">แจ้งเตือนก่อนถึงวันที่ครบกำหนด</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.notiStatus && 
                                                        <React.Fragment>
                                                            <div className="row mt-4 text-left">
                                                                <div className="col-2"/>
                                                                <div className="col-10 form-inline">
                                                                    <input type="radio" className="custom-radio" id="unit" onChange = {(e) => this.toggle_unit(e)}
                                                                        checked={this.state.unit === 'day'} name="unit" value="day" disabled={this.state.disableInterest} />
                                                                    <span className="ml-2 mr-2"> 
                                                                        ล่วงหน้า
                                                                    </span>
                                                                    <input type= "text"className="form-control col-3" name="numberOfUnitDay" disabled={this.state.disableInterest} 
                                                                    onChange={this.handleInputChange} value={this.state.numberOfUnitDay || ''} />
                                                                    <span className="ml-2 mr-2">วัน</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4 text-left">
                                                                <div className="col-2"/>
                                                                <div className="col-10 form-inline">
                                                                    <input type="radio" className="custom-radio" id="unit" onChange = {(e) => this.toggle_unit(e)}
                                                                        checked={this.state.unit === 'month'} disabled={this.state.disableInterest}   name="unit" value="month"/>
                                                                    <span className="ml-2 mr-2"> 
                                                                        ล่วงหน้า
                                                                    </span>
                                                                        <select className="form-control" name="numberOfUnitMonth"
                                                                            value={this.state.numberOfUnitMonth} 
                                                                            onChange={this.handleInputChange}
                                                                            disabled={this.state.disableInterest} 
                                                                            >
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                    </select>
                                                                    <span className="ml-2 mr-2">เดือน</span>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                        </form>
                                    </React.Fragment>
                                    }
                                </div>
                                <div className="modal-footer">
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_bank_edit'}) &&
                                    <React.Fragment>
                                        <Translation>
                                            {t=>
                                            <button type="button" className="btn btn-secondary add"
                                                    onClick={this.onCloseModal}>{t("finance:Cancel")}
                                            </button>}
                                        </Translation>
                                        <Translation>
                                            {t=>
                                            <button type="submit" className="btn btn-primary add" form="form-edit"
                                                    disabled={this.state.edit_loading || 
                                                    (this.state.optionPayment  ===  'crossBilling') && this.state.billerId?.length < 15}>
                                                {this.state.edit_loading &&
                                                <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                {t("finance:Save")}
                                            </button>}
                                        </Translation>
                                    </React.Fragment>

                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        )
    }
}

export default BankEditModal;
