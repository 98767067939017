import React, {Component} from 'react';
import ModalResultVote from "./modalResultVote";
import ModalPrototype from "./modalPrototype";

class ModalRoomVote extends Component {

    render() {
        return (
            <React.Fragment>
                {this.props.type === "prototype" &&
                <ModalPrototype survey_id={this.props.survey_id} type={this.props.type} status={this.props.status}/>
                }

                {this.props.type === "result" &&
                <ModalResultVote survey_id={this.props.survey_id} residential={this.props.residential}
                                 status={this.props.status} type={this.props.type} queryResidentialVoteList={this.props.queryResidentialVoteList}/>
                }
            </React.Fragment>
        );
    }
}

export default ModalRoomVote;