import React from "react";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import Wrapper from "../../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import ComponentPagination from "../../../libs/componentPagination";
import Pagination from "../../../libs/newPagination";
import environment from "../../../env/environment";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import FineCreateModal from "./fineCreateModal";
import { fetchQuery } from "relay-runtime";
import _ from "lodash";
import { Translation } from "react-i18next";
// import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";

const query = graphql`
  query fineListQuery(
    $search: String
    $start_date: DateTime
    $end_date: DateTime
    $first: Int
    $last: Int
  ) {
    allFineGroup(
      search: $search
      startDate: $start_date
      endDate: $end_date
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node {
          id
          service {
            id
            productCode
            name
          }
          fineDate
          fineCount
          fineTotal
          creator {
            id
            firstName
            lastName
          }
          added
        }
      }
    }
  }
`;

class FineList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.start_date = null;
    this.state.end_date = null;
    this.state.temp_start_date = null;
    this.state.temp_end_date = null;
    this.state.search = "";
    this.state.search_input = "";
    this.state.re_query = false;

    this.state.fine_list = [];
    this.state.fine_list_count = 0;

    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.refreshQuery = this.refreshQuery.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {
    this.getData();
    this.interval = setInterval(() => {
      this.getData();
    }, 10000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }

  handleSearch(event) {
    this.setState(
      {
        // start_date: this.state.temp_start_date,
        // end_date: this.state.temp_end_date,
        search: event.target.value,
      },
      () => this.getData()
    );
  }

  refreshQuery() {
    this.getData();
  }

  getData() {
    fetchQuery(environment, query, {
      type: "service",
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      search: this.state.search,
      first: this.state.first,
      last: this.state.last,
    }).then((data) => {
      if (data) {
        this.setState({
          fine_list: data.allFineGroup.edges,
          fine_list_count: data.allFineGroup.totalCount,
        });
      }
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box" id="fine-list">
                <div className="row justify-content-between">
                  <div className="col">
                    <h3 className="mb-4">
                      <Link to="/accounting">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/wrapperContent/back.png"
                          }
                          alt="back"
                          className="back"
                        />
                      </Link>
                      {t('fine:Fines')}
                    </h3>
                  </div>
                  <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "accounting_fine_create",
                    }) && <FineCreateModal refreshQuery={this.refreshQuery} />}
                  </div>
                </div>
    
                <div className="content-inner">
                  <div className="row mb-2 mt-1">
                    <div className="col-12 text-right">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size col-8"
                          placeholder={t('filterFunction:search')}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {t('filterFunction:search')}
                        </button>
                      </div>
                      {/* <button
                        type="button"
                        className="btn btn-outline-secondary float-right"
                        onClick={() =>
                          this.setState({
                            advance_search: !this.state.advance_search,
                          })
                        }
                      >
                        <img
                          src="/images/icons/filter-icon.png"
                          alt="filter-icon"
                        />
                      </button> */}
                      {/* <InlineAdvanceFilter
                        start_date={this.state.temp_start_date}
                        end_date={this.state.temp_end_date}
                        handleChange={this.handleChange}
                      /> */}
                    </div>
                  </div>
    
                  <div className="row">
                    <div className="col">
                      <div className="table-responsive fade-up">
                        <table className="table table-hover mt-2">
                          <thead className="thead-light">
                            <tr>
                              <th>{t('fine:Services')}</th>
                              <th>{t('fine:Fines date')}</th>
                              <th>{t('fine:Number of entries')}</th>
                              <th>{t('fine:By')}</th>
                              <th>{t('fine:Date')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.fine_list.map((fine_group) => (
                              <tr key={fine_group.node.id}>
                                <td>
                                  <Link
                                    to={
                                      "/accounting/income/fine/detail/" +
                                      fine_group.node.id +
                                      "/" +
                                      fine_group.node.service.productCode
                                    }
                                    >
                                    ({fine_group.node.service.productCode}){" "}
                                    {fine_group.node.service.name}
                                  </Link>
                                </td>
                                <td>
                                  {format(fine_group.node.fineDate, "DD/MM/YYYY", {
                                    locale: thLocale,
                                  })}
                                </td>
                                <td>
                                  ({fine_group.node.fineCount}/
                                  {fine_group.node.fineTotal})
                                </td>
                                <td>
                                  {fine_group.node.creator.firstName}{" "}
                                  {fine_group.node.creator.lastName}
                                </td>
                                <td>
                                  {format(fine_group.node.added, "DD/MM/YYYY", {
                                    locale: thLocale,
                                  })}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={this.state.fine_list_count}
                        />
                      </div>
    
                      {/*<QueryRenderer
                                            environment={environment}
                                            query={query}
                                            fetchPolicy="no-cache"
                                            variables={{
                                                first: this.state.first,
                                                last: this.state.last,
                                                search: this.state.search,
                                                start_date: this.state.start_date,
                                                end_date: this.state.end_date,
                                                re_query: this.state.re_query,
                                            }}
    
                                            render={({error, props}) => {
                                                if (error) {
                                                    return <div className="alert alert-danger"
                                                                role="alert">{error.message}</div>;
                                                } else if (props) {
                                                    return <React.Fragment>
    
                                                    </React.Fragment>
                                                }
                                                return <div>loading ...</div>
                                            }}
                                        />*/}
                    </div>
                  </div>
                </div>
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default FineList;
