import React from 'react';
import {Link} from "react-router-dom";
import { Translation } from 'react-i18next';
import _ from "lodash";
import ComponentPagination from '../../../../../libs/componentPagination';
import Pagination from '../../../../../libs/newPagination';
import './batchDetail.scss';
import Swal from 'sweetalert2';
import ExportBatchReportPDF from './batchPrintPDF';
import {graphql} from "babel-plugin-relay/macro";
import environment from '../../../../../env/environment';
import { commitMutation } from "react-relay";
import BatchPrintExcel from './batchPrintExcel';
import {fetchQuery} from "relay-runtime";
import numberWithComma from '../../../../../libs/numberWithComma';
import format from 'date-fns/format';
import Loading from '../../../../../libs/loading';
import i18next from 'i18next';
import ExportReceiveDetailPDF from '../../receive_detail/receiveDetailPDF';
import LoadingModal from '../modalBatch/loadingModal';
import localStorageLib from "../../../../../libs/localstorage";

const mutation = graphql`
    mutation batchDetailMutation ($input:VoidBatchReceiveInput!){
        voidBatchReceive(input:$input) 
        {
            ok
            message
        }
    }
`;


const query = graphql`
    query batchDetailQuery($id: ID!) {
        selfProject{
            name
            nameEn
            address
            addressEn
            logoBase64
            juristicContactNumber
            keyProjectQr
            taxIdNumber
        }    
        batchReceive(id: $id) {
                id
                docNumber
                batchDate
                startRt
                endRt
                creator
                voider
                remark
                status
                added
                receiveCount
                receiveBatch{
                    edges{
                        node{
                            receive{
                                id
                        docNumber
                        firstName lastName
                        payGroup
                        contact {
                            id
                            name
                            refNumber
                            firstName
                            lastName
                            typeOfContact
                        }
                        issuedDate
                        added
                        total
                        status
                        voidRemark
                        creator
                        updated
                        receiveTransaction{
                            edges{
                                node{
                                        id
                                        amount
                                        receiver
                                        transaction{
                                            id
                                            productAndService {
                                                id
                                            }
                                            description
                                            total
                                            paid
                                            invoice{
                                                firstName
                                                lastName
                                                payGroup
                                            }
                                        }
                                        }
                                    
                                        }
                                    }
                                }
                            }
                        },
                }
            },
            selfProject {
                name
                logo
            }
}`;

class BatchDetail extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state.loading = false;
        this.state.listPrintRTId = [];
        this.state.listPrintRTDoc = [];
        this.state.batchId = this.props.batchId;
        this.state.allBatchDetail = null;
        this.state.transactionCount = 0;
        this.state.projectName = "";
        this.state.sliceItems = null;

        this.onCancelBatch = this.onCancelBatch.bind(this);
        this.calBatchRange = this.calBatchRange.bind(this);
    }

    componentDidMount() {
        this.fetchData()           
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.first !== prevState.first) {
            let sliceItems = this.state.allBatchDetail.receiveBatch.edges.slice(this.state.first - localStorageLib.getPageLimit(),this.state.first);
            this.setState({sliceItems: sliceItems},()=>{window.scroll({top: 0, left: 0, behavior: 'smooth' })})
        }
    }

    fetchData() {
        this.setState({
            loading: true,
        })

        let variables = {
            id : this.state.batchId,
        };
        fetchQuery(environment, query, variables).then(data => {
            let listPrintRTDoc = [];
            let listPrintRTId = [];
            data.batchReceive.receiveBatch.edges.forEach((rt)=>{
                listPrintRTDoc.push(rt.node.receive.docNumber);
                listPrintRTId.push(rt.node.receive.id);
            })
            let sliceItems = data.batchReceive.receiveBatch.edges.slice(this.state.first - localStorageLib.getPageLimit(),this.state.last);
            this.setState({
                allBatchDetail: data.batchReceive,
                listPrintRTDoc: listPrintRTDoc,
                listPrintRTId: listPrintRTId,
                transactionCount: data.batchReceive.receiveCount,
                loading: false,
                projectName: data.selfProject?.name,
                selfProject: data.selfProject,
                sliceItems: sliceItems
            })
        })
    }
    
    onCancelBatch() {
        return Swal.fire({
            title: `${i18next.t("batchRt:Confirm delete")} ${this.state.transactionCount} ${i18next.t("batchRt:Transaction")}`,
            type: 'warning',
            iconColor: '#E14558',
            showCancelButton: true,
            confirmButtonText: `${i18next.t("batchRt:Agree")}`,
            cancelButtonText: `${i18next.t("batchRt:Cancel")}`,
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: `${i18next.t("batchRt:Reason for deleting document")}`,
                    html: `<input type="text" class="swal2-input" id="remarkVoidBatch">`,
                    showCancelButton: true,
                    confirmButtonText: `${i18next.t("batchRt:Confirm")}`,
                    cancelButtonText:  `${i18next.t("batchRt:Cancel")}`,
                    cancelButtonColor: "#E14558",
                    reverseButtons: true,
                })
                .then((result) => {
                    if (result.value) {
                    let remark = document.getElementById('remarkVoidBatch').value;
                        let variables = 
                            {
                                "input": {
                                    "batchId": this.state.batchId,
                                    "remark":remark
                                }
                            }
                            this.openModal();
                            commitMutation(environment, {
                                mutation,
                                variables,
                                onCompleted: (response) => {          
                                  if (response.voidBatchReceive.ok) {    
                                    this.closeModal();             
                                    Swal.fire(`${i18next.t("batchRt:Save Successful")}`, '', 'success').then(() => {
                                      this.setState({ loading: false });
                                      const redirect = document.createElement('a');
                                      redirect.href = '/accounting/income/receive/form/manage_batch/all';
                                      redirect.click();
                                    });
                                  } else {
                                    this.closeModal(); 
                                    Swal.fire(`${i18next.t("batchRt:Save Unsuccessful")}`, '', 'warning').then(() => {
                                        this.setState({ loading: false });
                                        window.location.reload();

                                    });
                                  }
                                },
                                onError: (err) => {
                                    this.closeModal(); 
                                    Swal.fire(`${i18next.t("batchRt:Save Unsuccessful")}`, err, 'warning').then(() => {
                                        this.setState({ loading: false });
                                    });
                                },
                            });
                    }else{
                        this.setState({loading: false});
                    }
                  })
            } else{
                this.setState({loading: false});
            }
          });  
    }

    getColorAndText(receive) {
        let text = "";
        let color = "text-center";
        switch (receive) {
          case "WAIT":
            text = "Waiting for review";
            break;
          case "PAID":
            text = "paid_info";
            break;
          default:
            text = "Canceled list";
            color = "text-center text-danger";
        }
        return <Translation>{t => <p className={color}>{t('receive:'+text)}</p>}</Translation>;
      }

    calBatchRange(startRt, endRt) {            
        const startData = startRt?.split(",");
        const endData = endRt?.split(",");            
        const results = startRt && startData.map((start, index) => `${start} - ${endData[index]}`);
  
        return results
    }
    
    openModal() {
        this.setState({ isModalOpen: true });
    }

    closeModal() {
        this.setState({ isModalOpen: false });
    }

    render () {
        return (
            <Translation>
            {
            t => <>
            <div className="container-fluid box" id="batchDetail">
                <div className='col'>
                    <div className="row justify-content-between">        
                        <h3 className="mb-4">
                            <Link to="/accounting/income/receive/form/manage_batch/all">
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                    alt="back" className="back"/>
                            </Link>
                                <span>{t(`batchRt:Batch management`)}</span>
                        </h3>
                        <div style={{display: 'inline-flex'}}>
                            <button className='cancelBtn mr-2' onClick={this.onCancelBatch}>{t(`batchRt:Cancel`)}</button>

                            <ExportReceiveDetailPDF
                                selfProject={this.state.selfProject}
                                btnInDetail={true}
                                batch={true}
                                checkList={{checkList: [...this.state.listPrintRTId] ,checkListdoc: [...this.state.listPrintRTDoc] }}
                                loading={this.state.loading}
                            />
                            {/* <Link
                                to={{
                                    pathname: `/document/receiveSiriraj/${this.state.batchId}`,
                                    }}
                            >
                                <button className='printBtn mr-2'>                                       
                                    {t(`batchRt:Print Receipt`)}
                                </button>
                            </Link> */}
                            {this.state.loading ?
                            <>
                                <button className='dropdown printBtn' data-toggle="dropdown">
                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                    <Translation>{t => t("meter:Preparing information")}</Translation>
                                </button>
                            </>
                                :
                            <>
                                <button className='dropdown printBtn' data-toggle="dropdown">
                                    {t(`batchRt:Print Batch Report`)}
                                    <img src={process.env.PUBLIC_URL + '/images/icons/vectorDown.svg'}
                                        alt="vectorDown"
                                        className='ml-2'
                                    />
                                </button>
                                <div className="dropdown-menu printItemContainer">
                                    <ExportBatchReportPDF 
                                        projectName={this.state.projectName}
                                        rtDetail={this.state.allBatchDetail?.receiveBatch?.edges}
                                        allBatchDetail={this.state.allBatchDetail}
                                        transactionCount={this.state.transactionCount}
                                    />
                                    <BatchPrintExcel 
                                        projectName={this.state.projectName}
                                        rtDetail={this.state.allBatchDetail?.receiveBatch?.edges}
                                        allBatchDetail={this.state.allBatchDetail}
                                        transactionCount={this.state.transactionCount}
                                    />                      
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="content-inner">
                    <div className="row mt-2">      
                        <div className='col'>          
                            <div className="table-responsive fade-up card">
                                    { this.state.loading ?
                                        <Loading />
                                            :
                                        <>
                                            <table className="table table-hover">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>{t(`batchRt:Batch number`)}</th>
                                                        <th>{t(`batchRt:Date`)}</th>
                                                        <th>{t(`batchRt:Time`)}</th>
                                                        <th>{t(`batchRt:Quantity`)}</th>
                                                        <th>{t(`batchRt:Document number`)}</th>
                                                        <th>{t(`batchRt:Reason for Cancellation`)}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><a>{this.state.allBatchDetail?.docNumber}</a></td>
                                                        <td>{format(this.state.allBatchDetail?.batchDate, "DD/MM/YYYY")}</td>
                                                        <td>{format(this.state.allBatchDetail?.added,"HH.mm")}</td>
                                                        <td>{this.state.transactionCount}</td>
                                                        <td>
                                                            {this.calBatchRange(this.state.allBatchDetail?.startRt, this.state.allBatchDetail?.endRt)?.map((rtRange, index) => (
                                                                <React.Fragment key={index}>
                                                                    {rtRange}<br />
                                                                </React.Fragment>
                                                            ))}    
                                                        </td>                                               
                                                        <td>{this.state.allBatchDetail?.remark}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="table table-hover">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th style={{width:"10%"}}>{t(`batchRt:No.`)}</th>
                                                        <th style={{width:"10%"}}>{t(`batchRt:Date`)}</th>
                                                        <th style={{width:"5%"}}>{t(`batchRt:Room No.`)}</th>
                                                        <th style={{width:"10%"}}>{t(`batchRt:Name`)}</th>
                                                        <th style={{width:"10%"}}>{t(`batchRt:Description`)}</th>
                                                        <th style={{width:"10%"}}>{t(`batchRt:Amount`)}</th>
                                                        <th style={{width:"10%"}}>{t(`batchRt:Total Amount`)}</th>
                                                        <th style={{width:"5%"}}>{t(`batchRt:Payment Method`)}</th>
                                                        <th style={{width:"10%"}}>{t(`batchRt:Status`)}</th>
                                                        <th style={{width:"10%"}}>{t(`batchRt:Create Date`)}</th>
                                                        <th style={{width:"10%"}}>{t(`batchRt:Create By`)}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {                                            
                                                         this.state.sliceItems?.map((rtDetail)=>{

                                                            let rtData = rtDetail.node.receive.receiveTransaction.edges
                                                            let total = 0;

                                                            return rtData.map((rt, indexRt)=>{
                                                                total += rt.node.amount;

                                                                
                                                                return (
                                                                    <tr key={indexRt}>
                                                                        <td>
                                                                            <Link                                                             
                                                                                to={
                                                                                    "/accounting/income/receive/detail/" +
                                                                                    rtDetail.node.receive.id +
                                                                                    "/" +
                                                                                    rtDetail.node.receive.docNumber
                                                                                }>
                                                                                {indexRt === 0 && rtDetail.node.receive.docNumber}
                                                                            </Link>
                                                                        </td>
                                                                        <td>{indexRt === 0 && format(rtDetail.node.receive.issuedDate, "DD/MM/YYYY")}</td>
                                                                        <td>{indexRt === 0 && rtDetail.node.receive.contact.name}</td>
                                                                        <td >{indexRt === 0 && rtDetail.node.receive.firstName}{" "}{indexRt === 0 && rtDetail.node.receive.lastName}</td>
                                                                        <td >{rt.node.transaction.description}</td>
                                                                        <td>{numberWithComma(rt.node.amount)}</td>
                                                                        <td>{indexRt === rtData.length - 1 && numberWithComma(total)}</td>
                                                                        <td>{t(`batchRt:Transfer Cash`)}</td>
                                                                        <td>{this.getColorAndText(rtDetail.node.receive.status)}</td>
                                                                        <td>{format(rtDetail.node.receive.added, "DD/MM/YYYY")}</td>
                                                                        <td>{rtDetail.node.receive.creator}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        })
                                                    }                                            
                                                </tbody>
                                            </table>
                                        </>
                                    }
                            </div>      
                        </div>                         
                    </div>
                    <div className="row">  
                        <Pagination
                            changePage={this.changePage}
                            first={this.state.first}
                            last={this.state.last}
                            totalCount={this.state.transactionCount}
                        />
                    </div> 
                </div>
            </div>
            <LoadingModal isOpen={this.state.isModalOpen} onClose={this.closeModal}>
                <Loading />
                <div className="text-center p-2 m-2">
                    <h2>กรุณารอสักครู่ . . .</h2>
                </div>
            </LoadingModal> 
            </>
            }
            </Translation>
        )
    }
}

export default BatchDetail