import React from "react";
import ReactExport from "react-data-export";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithCommas from "../../libs/numberWithComma";
// import _ from "lodash";
import environment from "../../env/environment";
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import i18next from "i18next";


const query = graphql`
  query exportBSQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ExportBalanceSheetAccountStatements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiDataSet: [],
            projectName: '',
            period: this.props.state.period,
        };
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            this.setState({
                projectName: res.selfProject.name,
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.state.balanceSheetAccountStatementsList && (this.props.state.balanceSheetAccountStatementsList !== prevProps.state.balanceSheetAccountStatementsList)){
            this.setData(this.props.state.balanceSheetAccountStatementsList)
        }
        if(this.props.state.period !== prevProps.state.period) {
            this.setState({period: this.props.state.period})
        }
    }

    setData(data_query) {
        let data = [];
        let period = '';
        if(this.state.period === 'month') {
            period = 'รายเดือน';
        } else if(this.state.period === 'month3') {
            period = 'ราย 3 เดือน';
        } else if(this.state.period === 'month6') {
            period = 'ราย 6 เดือน';
        } else if(this.state.period === 'month9') {
            period = 'ราย 9 เดือน';
        } else {
            period = 'รายปี'
        }
        let endDateFirst = format(this.props.state.end_date_first, 'MMM YYYY', {locale: thLocale});
        let endDataSecond = format(this.props.state.end_date_second, 'MMM YYYY', {locale: thLocale});

        //(หน่วย:บาท)
        data.push(
        [
            {value: "หมายเหตุประกอบงบการเงิน / Notes To Financial Statement" ,style:{ font: {bold: true} ,alignment: {horizontal: "left"}}},
            {value: ""},
            {value: ""},
        ],
        [
            {value: period ,style:{ font: {bold: true} ,alignment: {horizontal: "left"}}},
            {value: ""},
            {value: ""},
        ],
        [
            {value: endDataSecond + "  ถึง  " + endDateFirst ,style:{ font: {bold: true} ,alignment: {horizontal: "left"}}},
            {value: ""},
            {value: ""},
        ],
        [
            {value: ""},
            {value: "(หน่วย:บาท)" ,style:{ font: {bold: true} , alignment: {horizontal: "right"}} },
            {value: "(หน่วย:บาท)" ,style:{ font: {bold: true} , alignment: {horizontal: "right"}} },
        ]
        );

        Object.entries(data_query).forEach(([text_index, record], index_obj) => {

            let summary1_total = 0
            let summary2_total = 0

            //หัวข้อของหมวดต่างๆ
            if(record.length > 0){
                data.push([
                    {value: text_index ,style:{font: {bold: true}} },
                    {value: format(this.props.state.end_date_first, 'MMM YYYY', {locale: thLocale}), style:{font: {bold: true} , alignment: {horizontal: "right"}} },
                    {value: format(this.props.state.end_date_second, 'MMM YYYY', {locale: thLocale}), style:{font: {bold: true} , alignment: {horizontal: "right"}} },
                ]);
            

                record.map((result, index_question) => {
                    //รายละเอียดข้อมูล
                    if(result.summary1 !== 0 || result.summary2 !== 0){       
                        summary1_total += result.summary1
                        summary2_total += result.summary2
                        data.push([
                            // {value: result.chartOfAccountCode +" "+result.name },
                            {value: result.name},
                            {value: numberWithCommas(result.summary1,"-",false,true) ,style:{ alignment: {horizontal: "right"}} },
                            {value: numberWithCommas(result.summary2,"-",false,true) ,style:{ alignment: {horizontal: "right"}} },
                        ]);
                    }
                })

                //รวม
                if(summary1_total !== 0 || summary2_total !== 0){                
                    data.push([
                        {value: "รวม" ,style:{font: {bold: true}}},
                        {value: numberWithCommas(summary1_total,"-",false,true) ,style:{ font: {bold: true}, alignment: {horizontal: "right"}} },
                        {value: numberWithCommas(summary2_total,"-",false,true) ,style:{ font: {bold: true}, alignment: {horizontal: "right"}} },
                    ]);
                }

                //เว้นวรรค
                data.push([
                    {value: "" },
                    {value: "" },
                    {value: "" },
                ]);
            }
        })

        let multiDataSet = [
            {
                columns: [
                    {title: this.state.projectName,font: { bold: true }, width: {wpx: 600}},
                    {title: "", width:{wpx: 200} },
                    {title: "", width:{wpx: 200} },
                ],
                data: data
            },
        ];

        this.setState({multiDataSet})
    }

    buttonElement() {
        return (
            <button type="button" className="btn print float-right">
                <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                {i18next.t("Allaction:Print")}
            </button>
        );
    }

    render() {
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"balance_sheet_account_statements" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}>
                <ExcelSheet
                    dataSet={this.state.multiDataSet}
                    name="BalanceSheetAccountStatements"
                />
            </ExcelFile>
        )
    }
}

export default ExportBalanceSheetAccountStatements