import React, {Component} from 'react';

import './lottery.scss'

class ResultLotteryAwardFalse extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        let myLang = this.props.match?.params?.lang
        return (
            <>
            <div className='text-center mt-6'>
                <img src={process.env.PUBLIC_URL + '/images/lottery/false.png'}
                  alt="revenue" className={this.props.mini && 'd-none'} />
             </div>
            <div className='row  p-4'>
                    <div className='col text-center'>
                    <span>{this.props.number}</span>
                    <p> {myLang === 'en' ? 'Not get reward' : 'ไม่ถูกรางวัล'}</p>
                    <div className='alert-noti' style={{backgroundColor:'#FDE5E8'}}>
                        <p>{myLang === 'en' ? 'Sorry, you dont get reward' : 'เสียใจด้วย...คุณไม่ถูกรางวัล'}</p>
                    </div>
                    </div>
            </div>
            </>
        );
    }
}

export default ResultLotteryAwardFalse;