import { graphql } from "babel-plugin-relay/macro";

const queryGetCollectionLawFirmLog = graphql`
  query queryGetCollectionLawFirmLogQuery($id:String){
    getCollectionLawFirmLog(id:$id)
  }          
`;


export default function getqueryGetCollectionLawFirmLog() {
    return queryGetCollectionLawFirmLog
}