/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type QuickMassageTypeNote = "INVOICE" | "PAYMENT_QUOTATION" | "QUOTATION" | "RECEIVED" | "%future added value";
export type receiveCreateFormQueryVariables = {|
  contact_id: string,
  type?: ?string,
|};
export type receiveCreateFormQueryResponse = {|
  +contact: ?{|
    +id: string,
    +typeOfContact: ContactTypeOfContact,
  |},
  +quickMassageViewer: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +massageNote: ?string,
        +typeNote: QuickMassageTypeNote,
        +creator: ?string,
      |}
    |}>
  |},
  +selfProject: ?{|
    +keyProjectQr: ?string
  |},
|};
export type receiveCreateFormQuery = {|
  variables: receiveCreateFormQueryVariables,
  response: receiveCreateFormQueryResponse,
|};
*/


/*
query receiveCreateFormQuery(
  $contact_id: ID!
  $type: String
) {
  contact(id: $contact_id) {
    id
    typeOfContact
  }
  quickMassageViewer(typeNote: $type) {
    edges {
      node {
        id
        massageNote
        typeNote
        creator
      }
    }
  }
  selfProject {
    keyProjectQr
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contact_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "contact_id"
    }
  ],
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "typeNote",
      "variableName": "type"
    }
  ],
  "concreteType": "QuickMassageNodeConnection",
  "kind": "LinkedField",
  "name": "quickMassageViewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickMassageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuickMassageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "massageNote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeNote",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyProjectQr",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "receiveCreateFormQuery",
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "receiveCreateFormQuery",
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d39743ece02f313a5aa9034b98705947",
    "id": null,
    "metadata": {},
    "name": "receiveCreateFormQuery",
    "operationKind": "query",
    "text": "query receiveCreateFormQuery(\n  $contact_id: ID!\n  $type: String\n) {\n  contact(id: $contact_id) {\n    id\n    typeOfContact\n  }\n  quickMassageViewer(typeNote: $type) {\n    edges {\n      node {\n        id\n        massageNote\n        typeNote\n        creator\n      }\n    }\n  }\n  selfProject {\n    keyProjectQr\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a9d9d47aeb1a4697da45301ee40a8401';

module.exports = node;
