import React, { Component } from 'react';
import Modal from 'react-modal';
import '../styles/modalComponentProjectList.scss'
import UploadImageInput from '../../../libs/uploadImageInput';
import _ from 'lodash'
import BoxImage from './boxImage';
import Slider from "react-slick";
import Swal from 'sweetalert2';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

const styleModal = {
    content: { 
        width : "80%",
        height: "85%",
        top : '50%',
        left : '50%',
        right : 'auto',
        bottom : 'auto',
        marginRight : '-50%',
        transform : 'translate(-50%, -50%)',
        borderRadius : '15px',
        padding : '2%'
    }
}


const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrow : true
    prevArrow : <img src={process.env.PUBLIC_URL + '/images/icons/prev-image.png'} alt="prev" className="text-right"/>,
    nextArrow : <img src={process.env.PUBLIC_URL + '/images/icons/next-image.png'} alt="next" className="text-right"/>
};



class SelectPicModal extends Component {

    constructor(props){
        super(props);
        this.state ={
            openModal : false,
            item : this.props.item.length > 0 ? this.props.item : [],
            selectedItem : [],
            uploadImage : [],
        }
    }
    
    componentWillMount(){
        Modal.setAppElement('body');
    }

    setModalClose = () => {
        this.setState({
            openModal : false,
            selectedItem : [],
            item : this.props.item.length > 0 ? this.props.item : [],
            uploadImage : [],
        })
    }

    handleSelectAll = () => {
        let selectAll = []
        this.state.item.forEach((item) => {
            selectAll.push(item.node)
        })
        this.setState({selectedItem: selectAll})
    }

    handleCancel = () => {
        this.setState({selectedItem: []})
    }

    swapBoxes = (fromBox, toBox) => {
        let boxes = this.state.item.slice();
        let fromIndex = -1;
        let toIndex = -1;
    
        for (let i = 0; i < boxes.length; i++) {
            if (boxes[i].node.id === fromBox.id) {
                fromIndex = i;
            }
            if (boxes[i].node.id === toBox.id) {
                toIndex = i;
            }
        }
    
        if (fromIndex !== -1 && toIndex !== -1) {
            let { fromId, ...fromRest } = boxes[fromIndex];
            let { toId, ...toRest } = boxes[toIndex];
            boxes[fromIndex] = { id: fromBox.id, ...toRest };
            boxes[toIndex] = { id: toBox.id, ...fromRest };

            this.setState({item: boxes})
        }
    };

    handleDelete = () => {
        Swal.fire({
            title: `${i18next.t("Allaction:Do you want to cancel the selected")}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(this.state.item)
                this.state.selectedItem.forEach((selected) => {
                    let index = _.findIndex(_.cloneDeep(getData),{node : {id : selected.id}})
                    if(index !== -1){
                        if(_.endsWith(selected.id,'add')){
                            getData.splice(index, 1)
                            this.setState(prevState => {
                                return _.set(
                                    _.cloneDeep(prevState),
                                    'item',
                                    getData
                                );
                            });
                        }else{
                            this.setState(prevState => {
                                return _.set(
                                    _.cloneDeep(prevState),
                                    'item['+index+'].node.status',
                                    'delete'
                                );
                            });
                        }
                    }
                })
                this.setState({
                    selectedItem : []
                })
                
            }
        })
    }

    handleSelect  = data => event => {
        let index = _.findIndex(this.state.selectedItem,['id',data.datas.id])
        if(index === -1){
            let select = _.clone(this.state.selectedItem)
            this.setState({
                selectedItem: [...select, {...data.datas}]
            })
        }else{
            let deleteItem = _.filter(this.state.selectedItem, (d) => d.id !==  data.datas.id)
            this.setState({
                selectedItem : deleteItem
            })
        }
    }

    handleDragStart = data => event => {
        let fromBox = JSON.stringify({ id: data.id });
        event.dataTransfer.setData("dragContent", fromBox);
    };

    handleDragOver = data => event => {
        event.preventDefault();
        return false;
    };

    handleDrop = data => event => {
        event.preventDefault();
      
        let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
        let toBox = { id: data.id };
      
        this.swapBoxes(fromBox, toBox);
        return false;
    };

    appendImageModal = (e) => {
        let name = e.target.name
        let value = e.target.value
        if(name === 'upload_files.item'){
            this.setState({
                uploadImage : [...this.state.uploadImage , value]
            })
        }else{
            let object = {node : {id : Date.now()+'add' , fileUpload : value , status : ''}}
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), name, 
                [...this.state.item, {...object}]);
            });
        }
    } 

    onSubmit = () => {
        this.props.getData(this.state.uploadImage,this.state.item)
    }

    handleChangInput = (e) => {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), event.target.name , value);
        });
    }



    render(){
        return(
            <Translation>
                {t =>
            <React.Fragment>
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_info_edit'}) &&
                    <span className="cursor text-primary mr-3 text-right float-right" onClick={() => this.setState({openModal : true})}>
                        <img
                            src={process.env.PUBLIC_URL + '/images/icons/edit-blue-color.png'}
                            alt="project-info-icon"
                        />
                        <span className="ml-1 text-right float-right">{t('project_detail:Edit')}</span>
                    </span>
                }
                <Modal 
                    isOpen={this.state.openModal}
                    onRequestClose={this.setModalClose}
                    style={styleModal}
                    >
                    <div id="modalPlanProject" className="outer">
                        <div className="inner">
                            <div className="row title">
                                <span 
                                    className="col-12 d-flex justify-content-end h3 m-0" 
                                    type="button" 
                                    onClick={this.setModalClose}
                                >x</span>
                                <div className="col-12">
                                    <h3>{t('project_detail:Album (Only .jpg files are supported, and their size must not exceed 10 MB.)')}</h3>  
                                    {/* RQ147 */}
                                </div>
                                <div className="col-12 d-flex justify-content-end">
                                    <div className="upload-file">
                                        <label 
                                            type="button" 
                                            className="btn btn-primary mr-3"
                                            htmlFor="uploadImage"
                                            >+{t('project_detail:Add image')}</label>
                                        <div className="upload-input">
                                            <UploadImageInput
                                                id="uploadImage"
                                                setField="upload_files.item:item"
                                                onChange={this.appendImageModal}
                                                required={true}
                                                />
                                        </div>
                                    </div>
                                    
                                </div>  
                            </div>
                            <div className="mt-3" style={{width: "100%", height: "1px", background: "#dfdfdf"}} />
                            <div className="row content" >
                                <div className="col-9">
                                    {this.state.item.length === 0 
                                        ?
                                            <React.Fragment>
                                                <p className="mt-3">เพิ่มภาพลงอัลบั้มของคุณ</p> 
                                                <div className="scrollBar"/>
                                            </React.Fragment>
                                        : 
                                            <React.Fragment>
                                                <nav>
                                                    <ul className="p-0 d-flex" style={{listStyleType: "none"}}>
                                                        <li className="py-2 pr-3">{t('project_detail:Select')} {this.state.selectedItem.length} {t('project_detail:items')}</li>
                                                        <li 
                                                            className="py-2 pr-3 pl-3 border-left text-primary" 
                                                            type="button" 
                                                            onClick={this.handleSelectAll}
                                                            >{t('project_detail:Select all')}</li>
                                                        <li 
                                                            className="py-2 pr-3 pl-3 border-left text-primary" 
                                                            type="button" 
                                                            onClick={this.handleCancel}
                                                            >{t('project_detail:Deselect')}</li>
                                                        <li 
                                                            className="py-2 pl-3 border-left text-danger" 
                                                            type="button" 
                                                            onClick={this.handleDelete}
                                                            >{t('project_detail:Remove')}</li>
                                                    </ul>
                                                </nav>
                                                
                                                <div className="scrollBar">
                                                    <div className="row">
                                                        {this.state.item.map((data, index) => {
                                                            if((data.node.status && data.node.status!== 'delete') || !data.node.status){
                                                                return (
                                                                    <BoxImage 
                                                                        box={data}
                                                                        key={index}
                                                                        draggable="true"
                                                                        onDragStart={this.handleDragStart}
                                                                        onDragOver={this.handleDragOver}
                                                                        onDrop={this.handleDrop} 
                                                                        index={index}
                                                                        onSelect={this.handleSelect}
                                                                        selectedItem ={this.state.selectedItem}
                                                                        />
                                                                    )
                                                                }
                                                            }) 
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                    }
                                </div>
                                <div className="col-3 border-left p-0" >
                                    
                                    {this.state.selectedItem.length > 0 
                                        ?
                                            <React.Fragment>
                                                <div className="row p-0 m-0" style={{width: "100%", background: "#fafafa"}}>
                                                    <div className="col-12 reactSlick">
                                                        <Slider {...settings2} afterChange={index => this.setState({index: index})}>
                                                            {this.state.selectedItem.map((img, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <div 
                                                                            className="d-flex justify-content-center" 
                                                                            >
                                                                            <img style={{width: "85%", maxHeight: "300px" , height:'auto'}} src={img.fileUploads ? img.fileUploads : img.fileUpload} alt="select Image"/>
                                                                        </div>
                                                                        {/* <div className="row mt-2 p-0 m-0">
                                                                            <div className="col-12">
                                                                                <div className="form-group p-2">
                                                                                    <label >คำอธิบายรูปภาพ</label>
                                                                                    <textarea 
                                                                                        value={img.description}
                                                                                        name={`item[${index}].node.description`}
                                                                                        onChange={this.handleChangInput}
                                                                                        className="form-control description" />
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </Slider>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        :
                                            <div style={{width: "100%", height: "100%", background: "#dddddd"}}/>
                                    }
                                </div>
                            </div>
                            <div className="mb-3" style={{width: "100%", height: "1px", background: "#dfdfdf"}} />
                            <div className="row footer float-right text-right">
                                <div className="col-12 d-flex justify-content-between align-items-center float-right text-right">
                                    {/* <input type="range" min="1" max="100" /> */}
                                    <button type="button" className="btn btn-primary mr-3" onClick={this.onSubmit}>{t('project_detail:Save')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
                }
            </Translation>
        );
    }
}
export default SelectPicModal;