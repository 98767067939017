import React, { Component } from "react";
import Header from "../../components/header/";
import Sidebar from "../../components/sidebar/";
import Wrapper from "../../components/wrapper/";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import ReactQuillAdapter from "../../libs/reactQuillAdapter";
import UploadImageInput from "../../libs/uploadImageInput";
import NewsImageRender from "./newsimageRender";
import { commitMutation } from "react-relay";
import DeleteNewsImage from "./mutations/DeleteNewsImage";
import DeleteNews from "./mutations/DeleteNews";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import i18n from "i18next";
import onUploadFile from "../../components/Action/onUploadFile";
import thLocale from "date-fns/locale/th";
import enLocale from "date-fns/locale/en";
import Loading from '../../libs/loading';
import LightBox from "../../libs/lightBox";

const _ = require("lodash");

const query = graphql`
  query createUpdateNewsQuery($id: ID!) {
    news(id: $id) {
      id
      newsType
      title
      titleTh
      titleEn
      previewImage
      detail
      detailTh
      detailEn
      status
      scheduleTime
      expTime
      newsImages {
        edges {
          node {
            id
            title
            source
          }
        }
      }
      newsFile{
          edges{
            node{
              fileName
              fileUpload
              fileUrl
            }
          }
      }
    }
    allProjects {
            edges {
            node {
            periodCreate
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation createUpdateNewsMutation($input: CreateUpdateNewsInput!) {
    createUpdateNews(input: $input) {
      newNews {
        id
      }
    }
  }
`;

class CreateUpdateNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: localStorage.getItem("language") || "th",
      redirect_to_list: false,
      post_now_loading: false,
      save_draft_loading: false,
      status: false,
      newCreate: true,
      news: {
        id: "",
        newsType: "INFO",
        title: "",
        titleEn: "",
        previewImage: "",
        detail: "",
        detailEn: "",
        status: "",
        newsImages: {
          edges: [],
        },
        newsFile: [],
        newsImageUrl: [],
      },
      upload_preview: {
        preview_image: "",
      },
      upload_image_list: [],
      schedule_status: false,
      schedule_expire_status: false,
      temp_schedule_date: new Date(),
      temp_schedule_time: new Date(),
      temp_expire_schedule_date: new Date(),
      temp_expire_schedule_time: new Date(),
      schedule_date: "",
      schedule_time: "",
      schedule_expire_date: "",
      schedule_expire_time: "",
      status_old: "",
      fileMore: [],
      temp_month_before : '',
      temp_month_after : '',
      startImageUrl: [],
      startAdditionImageUrl: [],
      loading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitFrom = this.submitFrom.bind(this);
    this.resetPreviewImage = this.resetPreviewImage.bind(this);
    this.uploadFile = this.uploadFile.bind(this)
    this.callBackUploadFile = this.callBackUploadFile.bind(this)
    this.dateFile = this.dateFile.bind(this)
  }

  componentWillMount() {
    this.queryNews();
    this.setState({ status: this.props.match.params.status === "deleted" });
  }

  queryNews() {
    if (this.props.match.params.id) {
      this.setState({
        loading: true,
      })
      const formatNews = (response, action) => ({
        title: response.news.titleTh,
        detail: response.news.detailTh,
        id: action !== "duplicate" ? response.news.id : "",
        newsType: response.news.newsType,
        titleEn: response.news.titleEn,
        detailEn: response.news.detailEn,
        previewImage: response.news.previewImage,
        status: response.news.status,
        newsImages: response.news.newsImages,
        newsFile: response.news.newsFile?.edges?.map((n) => ({ ...n.node })),
        newsImageUrl: [
          { "type": "preview_image", "image_url": response.news.previewImage },
          ...(response.news.newsImages?.edges?.map(n => ({ "type": "addition_image", "image_url": n.node.source })) || [])
        ]
      });
      
      fetchQuery(environment, query, { id: this.props.match.params.id }).then(
        (response) => {
          if (response) {                            
            // ถ้ามี scheduleTime
            if (response.news.scheduleTime || response.news.expTime) {              
              let date = new Date();
              let scheduleTimeConvert = new Date(response.news.scheduleTime);
              let scheduleTimeExpireConvert = new Date(response.news.expTime);

              this.setState({
                loading: false,
                fileMore: response.news.newsFile?.edges?.map((n) => ({ ...n.node })),   

                startImageUrl: [
                  { "type": "preview_image", "image_url": response.news.previewImage },
                  ...(response.news.newsImages?.edges?.map(n => ({ "type": "addition_image", "image_url": n.node.source })) || [])
                ],

                startAdditionImageUrl: [
                  ...(response.news.newsImages?.edges?.map(n => ({ "type": "addition_image", "image_url": n.node.source })) || [])
                ],

                news: formatNews(response, this.props.match.params.action),
                temp_schedule_date: response.news.scheduleTime ? scheduleTimeConvert : date.setDate(date.getDate() + 1),
                temp_schedule_time: response.news.scheduleTime ? scheduleTimeConvert : date.setDate(date.getDate() + 1),

                temp_expire_schedule_date: response.news.expTime ?  scheduleTimeExpireConvert : date.setDate(date.getDate() + 1),
                temp_expire_schedule_time: response.news.expTime ?  scheduleTimeExpireConvert : date.setDate(date.getDate() + 1),

                schedule_status: response.news.scheduleTime ? true : false,
                schedule_expire_status: response.news.expTime ? true : false,

                newCreate: false,
                status_old: response.news.status,
              });            
            } else {
              let date = new Date();
              this.setState({     
                loading: false,           
                news: formatNews(response, this.props.match.params.action),
                temp_schedule_time: date.setDate(date.getDate() + 1), 
                newCreate: false, 
                status_old: response.news.status,
                fileMore: response.news.newsFile?.edges?.map((n) => ({ ...n.node })),

                startImageUrl: [
                  { "type": "preview_image", "image_url": response.news.previewImage },
                  ...(response.news.newsImages?.edges?.map(n => ({ "type": "addition_image", "image_url": n.node.source })) || [])
                ],

                startAdditionImageUrl: [
                  ...(response.news.newsImages?.edges?.map(n => ({ "type": "addition_image", "image_url": n.node.source })) || [])
                ],
              });
            }
          }
        }
      );

    // ถ้าไม่มี ID ==> หน้าสร้างใหม่
    }else{
        fetchQuery(environment, query,{id:''}).then(response_data => {
          if(response_data){
            let data = _.cloneDeep(response_data);
            this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
          }
      })      
      }
  }

  calPeriodMouth(val){
    let month_before = new Date();
    let month_after= new Date();

    month_before.setMonth(month_before.getMonth() -  parseInt(val));
    month_after.setMonth(month_after.getMonth() + parseInt(val));

    this.setState({
        temp_month_before : month_before,
        temp_month_after : month_after,
    })
}

  handleInputChange(e) {
    let event = _.cloneDeep(e);
    let value = event.target.value;
    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), event.target.name, value);
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  deleteNewsImage(news_image_id, index, type) {
    if (news_image_id && this.props.match.params.action !== "duplicate" && type !== 'upload') {
      // mutation delete
      let variables = {
        input: {
          clientMutationId: news_image_id,
        },
      };

      // ถ้าเป็นหน้า duplicate ไม่ต้องเรียก fn นี้
      DeleteNewsImage(
        variables,
        () => this.queryNews(),
        () => Swal.fire(i18n.t("press_release:cannot_delete_image"), "", "error")
      );
    } else if(type === 'upload'){      
      // delete upload_image_list
      let upload_image_list = [...this.state.upload_image_list];
      upload_image_list.splice(index, 1);
      this.setState({ upload_image_list: upload_image_list });

    } else {
      const { news } = this.state;
      const newsImages = news?.newsImages?.edges;
      let upload_image_list = [...newsImages];

      let newsImageUrl = [news.newsImageUrl[0]]
      let newsImageUrlTese = news.newsImageUrl.filter(image => image.type === "addition_image")
      
      if (newsImageUrlTese[index]?.type === "addition_image") {
        newsImageUrlTese.splice(index, 1);
      }

      newsImageUrlTese.map((urlAddtion)=>(
        newsImageUrl.push(urlAddtion)
      ))
      
      upload_image_list.splice(index, 1);      
    
      this.setState({
        news: {
          ...news,
          newsImages: {
            ...news.newsImages,
            edges: upload_image_list
          },
          newsImageUrl: newsImageUrl
        }
      });
    }
  }

  deleteNews(id_list) {
    if (id_list.length !== 0) {
      // mutation delete
      let variables = {
        input: {
          idList: id_list,
        }
      };
      DeleteNews(
        variables,
        () => this.props.history.push('/contact/news/notice'),
        () => Swal.fire(i18n.t("press_release:cannot_delete_image"), "", "error")
      );
    }
  }

  convertTimeToCompare(date, time) {
    let newDate = this.formatDate(date)
    let newTime = this.formatTime(time)
    let result = `${newDate}T${newTime}`
    return  new Date(result)
  }
  
  formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; // Months are zero indexed
    let year = date.getFullYear();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }


  formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  submitFrom(e) {
    e.preventDefault();
    if (this.state.news.detail === "") {
      // เช็คเนื้อหาว่าง
      Swal.fire(i18n.t("press_release:fail_to_create"), i18n.t("press_release:please_enter_content"), "warning");
      return;
    }  

    let schedulePostDateTime = this.state.schedule_status &&  this.convertTimeToCompare(this.state.temp_schedule_date, this.state.temp_schedule_time)
    let scheduleExpDateTime = this.state.schedule_expire_status &&  this.convertTimeToCompare(this.state.temp_expire_schedule_date, this.state.temp_expire_schedule_time)
    // ถ้าติ๊กอย่างใดอย่างนึง แล้ววันที่ปัจจุบันน้อยกว่า 
    // จะไม่สามารถทำได้
    if ((this.state.schedule_status && (new Date(Date.now()) > schedulePostDateTime)) || 
        (this.state.schedule_expire_status && (new Date(Date.now()) > scheduleExpDateTime)) ||        
        // ถ้าติ๊กสองอย่าง แล้ว 
        // วันที่หมดอายุล่วงหน้า น้อย วันที่โพส
        // วันที่ปัจจุบัน มากกว่า วันที่หมดอายุล่วงหน้า
        // วันที่ปัจจุบัน มากกว่า วันที่โพส
        // จะไม่สามารถทำได้
        (this.state.schedule_status && this.state.schedule_expire_status &&
          ((scheduleExpDateTime < schedulePostDateTime) || (new Date(Date.now()) > schedulePostDateTime) || (new Date(Date.now()) > scheduleExpDateTime))
        ) 
        ) 
      {     
          Swal.fire({
            title: i18n.t("press_release:unable_to_process_transaction"),
            text: i18n.t("press_release:posting_timeout"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: i18n.t("press_release:confirm"),
            cancelButtonText: i18n.t("press_release:cancel"),
          }).then((result) => {
            if (result.value) {
              this.deleteNews(this.state.news.id)
              return;
            } else {
              return;
            }
          })
          return;          
             
    }


    if (this.state.news.status === "publish" || this.state.news.status === "PUBLISH") {
      this.setState({
        post_now_loading: true,
      });
    } else {
      this.setState({
        save_draft_loading: true,
      });
    }
 
    let variables = {
        input: {
          news: JSON.stringify(this.state.news),

          scheduleDate: this.state.temp_schedule_date === "" ? this.state.temp_schedule_date : format(this.state.temp_schedule_date, "YYYY-MM-DD"),
          scheduleTime: this.state.temp_schedule_time === "" ? this.state.temp_schedule_date :  format(this.state.temp_schedule_time, "HH:mm:ss"),
          scheduleStatus: this.state.schedule_status,

          expDate: this.state.temp_expire_schedule_date === "" ? this.state.temp_expire_schedule_date : format(this.state.temp_expire_schedule_date, "YYYY-MM-DD"),
          expTime: this.state.temp_expire_schedule_time === "" ?  this.state.temp_expire_schedule_time : format(this.state.temp_expire_schedule_time, "HH:mm:ss"),
          expStatus: this.state.schedule_expire_status,
        },
      };
    

    let uploadables = Object.assign(
      this.state.upload_preview,
      this.state.upload_image_list
    );

    let alertTextAndStyle = `<style>
              .w-55 {
                width: 55%;
                font-size: 15px;
              }
              .w-40 {
                width: 40%;
                font-size: 15px;
              }
              .textRight {
                text-align: right
              }
              .textLeft {
                text-align: left
              }
              .flex-container {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
                  width: 80%;
                  margin-left: auto;
                  margin-right: auto;
              }
              .swal2-actions-custom {
                  padding-top: 0;
                  margin-top; 0;
              }
          </style>
          <h4>${i18n.t("press_release:Announcement date setting details")}</h4>
          <div class="flex-container">
            <div class="w-40 textRight">
              <p>${i18n.t("press_release:Announcement start date")} :</p>
            </div>
            <div class="w-55 textLeft">
              <p>${this.state.schedule_status ? 
                `${format(this.state.temp_schedule_date, 'DD/MM/YYYY', { locale: thLocale })} - ${format(this.state.temp_schedule_time, 'HH:mm น.', { locale: thLocale })}`
                :
                `${i18n.t("press_release:No announcement start date set")}`
              }</p>
            </div>
          </div>
          <div class="flex-container">
            <div class="w-40 textRight">
              <p>${i18n.t("press_release:Announcement expiration date")}:</p>
            </div>
            <div class="w-55 textLeft">
              <p>${this.state.schedule_expire_status ? 
                `${format(this.state.temp_expire_schedule_date, 'DD/MM/YYYY', { locale: thLocale })} - ${format(this.state.temp_expire_schedule_time, 'HH:mm น.', { locale: thLocale })}`
                :
                `${i18n.t("press_release:No announcement expiration date set")}`
              }</p>                
            </div>
          </div>
    `;

    if(this.state.news.status === 'draft'){                  
      commitMutation(environment, {
        mutation,
        variables,
        uploadables,
        onCompleted: (response) => {
          this.setState({ post_now_loading: false, save_draft_loading: false });
          Swal.fire(i18n.t("press_release:success"), "", "success").then(() => {
            this.setState({ redirect_to_list: true });
          });
        },
        onError: (err) => {
          this.setState({ post_now_loading: false, save_draft_loading: false });
          Swal.fire(i18n.t("press_release:unsuccess"), "", "error");
        },
      });
    }     
    // duplicate case
    else if(this.props.match.params.action === "duplicate"){     
      
      // แก้ทั้ง preview และ upload_image_list
      if(this.state.upload_preview.preview_image && this.state.upload_image_list.length > 0){                              
        const { news } = this.state;
        const { newsImageUrl, ...newsWithoutImagesUrl } = news;
        let updatedNews = {}

        // กรณีไม่ลบ และเพิ่ม addtion
        if(this.state.startAdditionImageUrl?.length === this.state.news.newsImageUrl.filter(image => image.type === "addition_image")?.length){                    
          updatedNews = {
            ...news,
            newsImageUrl: news.newsImageUrl.filter(image => image.type === "addition_image")
          };
        // กรณีลบ และเพิ่ม addtion
        }else {
          updatedNews = newsWithoutImagesUrl; 
        }
                        
        variables = {
          input: {
            news: JSON.stringify(newsWithoutImagesUrl),

            scheduleDate: this.state.temp_schedule_date === "" ? this.state.temp_schedule_date : format(this.state.temp_schedule_date, "YYYY-MM-DD"),
            scheduleTime: this.state.temp_schedule_time === "" ? this.state.temp_schedule_date :  format(this.state.temp_schedule_time, "HH:mm:ss"),
            scheduleStatus: this.state.schedule_status,

            expDate: this.state.temp_expire_schedule_date === "" ? this.state.temp_expire_schedule_date : format(this.state.temp_expire_schedule_date, "YYYY-MM-DD"),
            expTime: this.state.temp_expire_schedule_time === "" ?  this.state.temp_expire_schedule_time : format(this.state.temp_expire_schedule_time, "HH:mm:ss"),
            expStatus: this.state.schedule_expire_status,
          },
        };
        
      } 
       // แก้เฉพาะ preview_image 
      else if(this.state.upload_preview.preview_image && !(this.state.upload_image_list.length > 0)){        
        const { news } = this.state;
        const { newsImageUrl, ...newsWithoutImagesUrl } = news;
        
        const filteredNewsImageUrl = newsImageUrl
          .filter(image => image.type === "addition_image")
          .map(image => ({ type: image.type, image_url: image.image_url }));
        
        const updatedNews = {
          ...news,
          newsImageUrl: filteredNewsImageUrl
        };

        variables = {
          input: {
            news: JSON.stringify(updatedNews),

            scheduleDate: this.state.temp_schedule_date === "" ? this.state.temp_schedule_date : format(this.state.temp_schedule_date, "YYYY-MM-DD"),
            scheduleTime: this.state.temp_schedule_time === "" ? this.state.temp_schedule_date :  format(this.state.temp_schedule_time, "HH:mm:ss"),
            scheduleStatus: this.state.schedule_status,

            expDate: this.state.temp_expire_schedule_date === "" ? this.state.temp_expire_schedule_date : format(this.state.temp_expire_schedule_date, "YYYY-MM-DD"),
            expTime: this.state.temp_expire_schedule_time === "" ?  this.state.temp_expire_schedule_time : format(this.state.temp_expire_schedule_time, "HH:mm:ss"),
            expStatus: this.state.schedule_expire_status,
          },
        };

      } 
      // แก้เฉพาะ upload_image_list หรือ เคสลบอย่างเดียว หรือ // เคสไม่ลบ และเพิ่มด้วย      
      else if(!this.state.upload_preview.preview_image && this.state.upload_image_list.length > 0 ||         
        this.state.startImageUrl !== this.state.news.newsImageUrl){       
        const { news } = this.state;
        const { newsImageUrl, ...newsWithoutImagesUrl } = news;
        let updatedNews = {}

        // เคสไม่ลบ และเพิ่มด้วย
        if(this.state.startAdditionImageUrl?.length === this.state.news.newsImageUrl.filter(image => image.type === "addition_image")?.length){          
          updatedNews = {
            ...news,
            newsImageUrl: news.newsImageUrl
          };
        }else {
          const filteredNewsImageUrl = newsImageUrl
          .filter(image => image.type === "preview_image")
          .map(image => ({ type: image.type, image_url: image.image_url }));
        
          updatedNews = {
            ...news,
            newsImageUrl: filteredNewsImageUrl
          };
        }
        
        variables = {
          input: {
            news: JSON.stringify(updatedNews),

            scheduleDate: this.state.temp_schedule_date === "" ? this.state.temp_schedule_date : format(this.state.temp_schedule_date, "YYYY-MM-DD"),
            scheduleTime: this.state.temp_schedule_time === "" ? this.state.temp_schedule_date :  format(this.state.temp_schedule_time, "HH:mm:ss"),
            scheduleStatus: this.state.schedule_status,

            expDate: this.state.temp_expire_schedule_date === "" ? this.state.temp_expire_schedule_date : format(this.state.temp_expire_schedule_date, "YYYY-MM-DD"),
            expTime: this.state.temp_expire_schedule_time === "" ?  this.state.temp_expire_schedule_time : format(this.state.temp_expire_schedule_time, "HH:mm:ss"),
            expStatus: this.state.schedule_expire_status,
          },
        };
      }
      // ไม่แก้อะไรเลย
      else {        
        uploadables = null
      }   
    
      Swal.fire({
        imageUrl: `/images/iconAlerts/iconAlert-WarningRed.png`,
        imageWidth: 100,
        html: alertTextAndStyle,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: i18n.t('Allaction:Confirm'),
        cancelButtonText: i18n.t('Allaction:cancel'),
        customClass: {
            actions: 'swal2-actions-custom'
        }
      }).then((result) => {
          if (result.value) {                 
            commitMutation(environment, {
              mutation,
              variables,  
              uploadables,                          
              onCompleted: (response) => {
                this.setState({ post_now_loading: false, save_draft_loading: false });
                Swal.fire(i18n.t("press_release:success"), "", "success").then(() => {
                  this.setState({ redirect_to_list: true });
                });
              },
              onError: (err) => {
                this.setState({ post_now_loading: false, save_draft_loading: false });
                Swal.fire(i18n.t("press_release:unsuccess"), "", "error");
              },
            });
          } else {
            this.setState({ post_now_loading: false, save_draft_loading: false });
          }
      });
    }    
    // normal case
    else {
      Swal.fire({
        imageUrl: `/images/iconAlerts/iconAlert-WarningRed.png`,
        imageWidth: 100,
        html: alertTextAndStyle,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: i18n.t('Allaction:cancel'),
        customClass: {
            actions: 'swal2-actions-custom'
        }
      }).then((result) => {
          if (result.value) {                 
            commitMutation(environment, {
              mutation,
              variables,
              uploadables,
              onCompleted: (response) => {                
                Swal.fire(i18n.t("press_release:success"), "", "success").then(() => {
                  setTimeout(() => {
                    this.setState({ post_now_loading: false, save_draft_loading: false });
                    this.props.history.push('/contact/news/all');
                  }, 500); 
                });
              },
              onError: (err) => {
                this.setState({ post_now_loading: false, save_draft_loading: false });
                Swal.fire(i18n.t("press_release:unsuccess"), "", "error");
              },
            });
          } else {
            this.setState({ post_now_loading: false, save_draft_loading: false });
          }
      });
    }
  }

  resetPreviewImage() {
    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "news.previewImage", "");
    });
    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "upload_preview.preview_image", "");
    });
  }

  changeStatus(status) {
    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "news.status", status);
    });
  }

  async changeStatusDate(status) {
    await this.setState({ schedule_status: true, temp_schedule_date: "", temp_schedule_time: "" })
    await this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "news.status", status);
    });
  }

  async switchLanguage(language) {
    this.setState({ language });
    await localStorage.setItem("language", language);
    this.queryNews();
  }


  //uploadFileMore
  callBackUploadFile(data) {
    let cloneNews = _.cloneDeep(this.state.news)
    _.set(cloneNews, `newsFile`, data)
    this.setState({ news: cloneNews, fileMore: data })
  }
  uploadFile(e) {
    let pathname = "news/files"
    onUploadFile(e, this.state.fileMore, pathname, this.callBackUploadFile);
  }

  dateFile(index, file) {
    let cloneData = _.cloneDeep(this.state.fileMore)
    let cloneNewsData = _.cloneDeep(this.state.news)
    cloneData.splice(index, 1)
    _.set(cloneNewsData, `newsFile`, cloneData)
    this.setState({ news: cloneNewsData, fileMore: cloneData })
  }

  render() {

    if (this.state.redirect_to_list) {
      return <Redirect to="/contact/news/all" />;
    }
    
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        {
          this.state.loading ? 
          <Loading />
            :        
        <WrapperContent>
          <form onSubmit={this.submitFrom}>
            <div className="container-fluid box" id="news">
              <div className="row justify-content-between">
                <div className="col">
                  <h3>
                    <Link to="/contact/news/all">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/wrapperContent/back.png"
                        }
                        alt="back"
                        className="back"
                      />
                    </Link>
                    {
                    this.props.match.params.action === 'edit' ?
                      <span>แก้ไขข่าวสาร</span>
                        :
                      <Translation>
                        {(t) => <span>{t("contact_menu:add news")}</span>}
                      </Translation>
                    }
                  </h3>
                </div>
              </div>

              <div className="content-inner mt-4 fade-up">
                <div className="row">
                  <div className="col-md-7 col-xl-6">
                    <ul className="switch-languages">
                      <li
                        onClick={() => this.switchLanguage("th")}
                        className={
                          this.state.language === "th" ? "active" : "in-active"
                        }
                      >
                        TH
                      </li>
                      <li
                        onClick={() => this.switchLanguage("en")}
                        className={
                          this.state.language === "en" ? "active" : "in-active"
                        }
                      >
                        EN
                      </li>
                    </ul>
                    <div className="card p-4">
                      <div className="row">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:news_type")}</span>}
                            </Translation>
                          </h6>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="info"
                              name="news.newsType"
                              value="INFO"
                              onChange={this.handleInputChange}
                              checked={this.state.news.newsType === "INFO"}
                              disabled={
                                this.state.status ||
                                this.props.match.params.action === 'view' ||
                                this.state.post_now_loading || 
                                this.state.save_draft_loading
                              }
                            />
                            <label
                              className="custom-control-label cursor"
                              htmlFor="info"
                            >
                              <Translation>
                                {(t) => <span>{t("press_release:pressRelease")}</span>}
                              </Translation>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="notice"
                              name="news.newsType"
                              value="NOTICE"
                              onChange={this.handleInputChange}
                              checked={this.state.news.newsType === "NOTICE"}
                              disabled={
                                this.state.status ||
                                this.props.match.params.action === 'view' ||
                                this.state.post_now_loading || 
                                this.state.save_draft_loading
                              }
                            />
                            <label
                              className="custom-control-label cursor"
                              htmlFor="notice"
                            >
                              <Translation>
                                {(t) => <span>{t("press_release:breaking_new")}</span>}
                              </Translation>
                            </label>
                          </div>
                        </div>
                      </div>
                      {
                      this.state.language === 'th' &&
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:topic")} <span style={{color:'red'}}>*</span> </span>}
                            </Translation>
                          </h6>
                          <input
                            type="text"
                            className="form-control"
                            name="news.title"
                            value={this.state.news.title}
                            onChange={this.handleInputChange}
                            required
                            disabled={
                              this.state.status ||
                              this.props.match.params.action === 'view' ||
                              this.state.post_now_loading ||
                              this.state.save_draft_loading
                            }
                          />
                        </div>
                      </div>
                      }
                      {
                      this.state.language === 'en' &&
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:topic_eng")} <span style={{color:'red'}}>*</span></span>}
                            </Translation>
                          </h6>
                          <input
                            type="text"
                            className="form-control"
                            name="news.titleEn"
                            value={this.state.news?.titleEn}
                            onChange={this.handleInputChange}
                            required
                            disabled={
                              this.state.status ||
                              this.props.match.params.action === 'view' ||
                              this.state.post_now_loading ||
                              this.state.save_draft_loading
                            }
                          />
                        </div>
                      </div>
                      }
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:title_image")}</span>}
                            </Translation> {" "} <span className="text-danger">*</span>
                            <span className="ml-2 color thin red-color">
                              <Translation>
                                {(t) => <span>{t("press_release:title_image_description")}</span>}
                              </Translation>
                            </span>
                          </h6>
                          {this.state.news.previewImage ? (
                            <React.Fragment>                              
                              <LightBox 
                                className="img-fluid card-img"
                                alt="news"
                                width={'100%'}
                                height={'100%'}
                                image={this.state.news.previewImage}
                              />
                              {this.props.match.params.action !== 'view' && !this.state.post_now_loading && !this.state.save_draft_loading && (
                                <div
                                  className="change-preview-image"
                                  onClick={this.resetPreviewImage}
                                >
                                  <Translation>
                                    {(t) => <span>{t("press_release:change_image")}</span>}
                                  </Translation>
                                </div>
                              )}
                            </React.Fragment>
                          ) : (
                            <div className="btn card-wrapper border-wrapper">
                              <label htmlFor="uploadImage">
                                <div className="card-body">
                                  <div className="col text-center">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/icons/select-photo.png"
                                      }
                                      alt="select-icon"
                                    />
                                  </div>
                                  <div className="text-center mt-4">
                                    <div className="upload-file">
                                      <Translation>
                                        {(t) => <span>{t("press_release:click_hear")}</span>}
                                      </Translation>
                                      <div className="upload-input">
                                        <UploadImageInput
                                          id="uploadImage"
                                          setField="upload_preview.preview_image:news.previewImage"
                                          onChange={this.handleInputChange}
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          )}
                        </div>
                      </div>

                     {
                      this.state?.language === 'th' &&
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:content")} <span style={{color:'red'}}>*</span></span>}
                            </Translation>
                          </h6>
                          <ReactQuillAdapter
                            name="news.detail"
                            value={this.state.news.detail}
                            onChange={this.handleInputChange}
                            readOnly={
                              this.props.match.params.action === 'view' ||  
                              this.props.match.params.status === "deleted" ||
                              this.state.post_now_loading ||
                              this.state.save_draft_loading
                            }
                          />
                        </div>
                      </div>
                    }
                        {
                      this.state?.language === 'en' &&
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:content_eng")}<span style={{color:'red'}}>*</span></span>}
                            </Translation>
                          </h6>
                          <ReactQuillAdapter
                            name="news.detailEn"
                            value={this.state.news?.detailEn}
                            onChange={this.handleInputChange}
                            readOnly={
                              this.props.match.params.action === 'view' ||  
                              this.props.match.params.status === "deleted" ||
                              this.state.post_now_loading ||
                              this.state.save_draft_loading
                            }
                          />
                        </div>
                      </div>
                    }
                      <div className="row mt-4">
                        <div className="col">
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:attach_more_pictures")}</span>}
                            </Translation>
                            <span className="ml-2 color thin red-color">
                              <Translation>
                                {(t) => <span>{t("press_release:title_image_description")}</span>}
                              </Translation>
                            </span>
                          </h6>

                          <div className="card-columns">
                            {this.state.news.newsImages?.edges.map(
                              (news_image, index) => (
                                <NewsImageRender
                                  key={index}
                                  src={news_image.node.source}
                                  onClickDelete={() =>
                                    !this.state.status && this.props.match.params.action !== 'view' && !this.state.post_now_loading && !this.state.save_draft_loading &&
                                    this.deleteNewsImage(
                                      news_image.node.id,
                                      index
                                    )
                                  }
                                />
                              )
                            )}
                                                        
                            {this.state.upload_image_list.map(
                              (news_image, index) =>                                                                                             
                                (
                                <NewsImageRender
                                  key={index}
                                  src={URL.createObjectURL(news_image)}
                                  onClickDelete={
                                    () =>
                                    !this.state.status && this.props.match.params.action !== 'view' && !this.state.post_now_loading && !this.state.save_draft_loading &&
                                    this.deleteNewsImage(false, index, 'upload')
                                  }
                                />
                              )
                            )}
                            {!this.state.status && this.props.match.params.action !== 'view' && !this.state.post_now_loading && !this.state.save_draft_loading &&(
                              <div className="upload-file">
                                <div>
                                  <label
                                    htmlFor="uploadNewsImages"
                                    className="create news-button-image btn btn-light mt-2"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/icons/plus-icon.png"
                                      }
                                      alt="plus-icon"
                                      className="img-fluid plus news-image"
                                    />
                                  </label>
                                </div>
                                <div className="upload-input">                                  
                                  <UploadImageInput
                                    id="uploadNewsImages"
                                    setField={
                                      "upload_image_list[" +
                                      this.state.upload_image_list.length +
                                      "]"
                                    }
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>



                      {/* แนนไฟล์เพิ่มเติม */}                      
                      <div className="row mt-4">
                      
                        <div className="col ">
                        {this.props.match.params.action !== 'view' && !this.state.post_now_loading && !this.state.save_draft_loading &&
                          <>
                          <h6>
                            <Translation>
                              {(t) => <span>{t("press_release:Attachment")}</span>}
                            </Translation>
                          </h6>                           
                          <div className="staff-create">
                            <Translation>
                              {t =>
                                <div className="uploadBTN" style={{ display: 'inline-flex', pointerEvents: this.state.fileMore.length >= 3 ? 'none' : 'all' }}>
                                  <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                    <button className={this.state.fileMore.length >= 3 ? "btn disabled" : "btn"}>
                                      <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")}
                                    </button>
                                    <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                      {t("PageCreate:Support File Just .PDF Only and File Not Over 10 MB (Max 3 Image Only)")}
                                    </span>

                                    <input type="file" name='file' id="uploadFile" className='form-control uploadFile' accept='application/pdf' onChange={this.uploadFile} />
                                  </label>
                                </div>
                              }
                            </Translation>
                          </div>
                          </>
                        }
                          {/* FileMore ไฟล์เพิ่มเติม*/}
                          <div className="mt-2">
                            {
                              this.state.fileMore?.map((file, inx) => {
                                let typeFile = file?.fileName?.split(".") || file?.node?.fileName?.split(".")
                                let typeICONFile = typeFile.length >= 3 ? typeFile[typeFile.length-1] : typeFile[1]

                                return (

                                  <p key={inx}>
                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeICONFile + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                    <Link
                                      to="route"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        window.open(
                                          typeof file?.viewPtath === "string" || file?.node?.fileUpload
                                            ? file.viewPtath || file.node.fileUpload
                                            : URL.createObjectURL(file?.viewPtath ? file?.viewPtath : file.node.fileUpload)
                                        )
                                      }}
                                      target="blank">{file?.fileName ? file?.fileName : file?.node?.fileName}</Link>
                                      &nbsp;&nbsp; 
                                      {this.props.match.params.action !== 'view' && <label onClick={() => this.dateFile(inx, file)}>X</label>}
                                  </p>

                                )
                              })
                            }
                          </div>
                        </div>
                      </div>                      

                    </div>
                  </div>
                  <div className="col-md-4 col-xl-5 mt-4">
 
                  {/* Schedule */}
                  <div className="card p-4 mt-2">
                        <h6>
                          <Translation>
                            {(t) => <span>{t("press_release:posting_option")}</span>}
                          </Translation>
                        </h6>                            
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="tdCheckbox"
                                checked={this.state.schedule_status}
                                disabled={this.props.match.params.action === 'view' || this.state.post_now_loading || this.state.save_draft_loading || this.state.status_old?.toLowerCase() === "publish"}
                                onClick={() => this.setState({ schedule_status: !this.state.schedule_status })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="tdCheckbox"
                              >
                                <Translation>
                                  {(t) => <span>{t("press_release:Set the announcement date")}                                  
                                  </span>}
                                </Translation>
                              </label>
                              <div className="tooltipImg" style={{top:'-2px'}}>
                                <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                alt="question" className="question ml-1 mr-1" style={{width:'15px'}}/>
                                <span className="tooltiptext">
                                <Translation>
                                  {(t) => <span>{t("press_release:Select when you want to schedule a post in advance")}                                  
                                  </span>}
                                </Translation>
                                </span>
                              </div>                            
                              

                              {this.state.schedule_status && (
                                <div className="mt-3 time-selected">
                                  <div className="row">
                                    <div className="col-3 noti-date" style={{ zIndex: 100 }}>
                                      <p className="labelText">
                                        <Translation>
                                          {(t) => <span>{t("press_release:Announcement start date")}</span>}
                                        </Translation>
                                      </p>
                            
                                      {
                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                                            <DatePickerAdapter
                                                name="temp_schedule_date" className="form-control" id="temp_schedule_date"
                                                selected={this.state.temp_schedule_date} 
                                                minDate={this.state.temp_month_before}
                                                // maxDate={this.state.temp_month_after}
                                                onChange={this.handleChange}
                                                disable={this.props.match.params.action === 'view' || this.state.post_now_loading || this.state.save_draft_loading || this.state.status_old?.toLowerCase() === "publish"}
                                            />:
                                            <DatePickerAdapter
                                                id="temp_schedule_date"
                                                className="form-control"
                                                name="temp_schedule_date"
                                                selected={this.state.temp_schedule_date}
                                                onChange={this.handleChange}  
                                                disable={this.props.match.params.action === 'view' || this.state.post_now_loading || this.state.save_draft_loading || this.state.status_old?.toLowerCase() === "publish"}
                                          />
                                  
                                        }
                                    </div>
                                      <div className="col-4">
                                        <div className="calendar-wrapper time-selected-input">
                                          <p className="labelText">
                                            <Translation>
                                              {(t) => <span>{t("press_release:time")}</span>}
                                            </Translation>
                                          </p>
                                          <DatePicker
                                            className="form-control"
                                            selected={this.state.temp_schedule_time}
                                            onChange={(date) =>
                                              this.setState({ temp_schedule_time: date })
                                            }
                                            timeInputLabel="Time:"
                                            dateFormat="h:mm aa"
                                            showTimeInput
                                            disabled={this.props.match.params.action === 'view' || this.state.post_now_loading || this.state.save_draft_loading || this.state.status_old?.toLowerCase() === "publish"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              )}
                              
                              {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
                              <hr />

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="expCheckbox"
                                checked={this.state.schedule_expire_status}
                                disabled={this.props.match.params.action === 'view' || this.state.post_now_loading || this.state.save_draft_loading}
                                onClick={() => this.setState({ schedule_expire_status: !this.state.schedule_expire_status })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="expCheckbox"
                              >
                                <Translation>
                                  {(t) => <span>{t("press_release:Set the announcement expiration date")}</span>}
                                </Translation>
                                <div className="tooltipImg" style={{top:'-2px'}}>
                                  <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                  alt="question" className="question ml-1 mr-1" style={{width:'15px'}}/>
                                  <span className="tooltiptext">
                                  <Translation>
                                    {(t) => <span>{t("press_release:Select when you want to set the end date for this announcement. The announcement will move to Inactive status on the specified date")}</span>}
                                  </Translation>
                                  </span>
                                </div>        
                              </label>
                              {this.state.schedule_expire_status && (
                                <div className="mt-3 time-selected">
                                  <div className="row">
                                    <div className="col-3 noti-date" style={{ zIndex: 99 }}>
                                      <p className="labelText">
                                        <Translation>
                                          {(t) => <span>{t("press_release:Announcement expiration date")}</span>}
                                        </Translation>
                                      </p>
                            
                                      {
                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                                            <DatePickerAdapter
                                                name="temp_expire_schedule_date" className="form-control" id="temp_expire_schedule_date"
                                                selected={this.state.temp_expire_schedule_date} 
                                                minDate={this.state.temp_schedule_date}                                                
                                                onChange={this.handleChange}
                                                disable={this.props.match.params.action === 'view' || this.state.post_now_loading || this.state.save_draft_loading}
                                            />:
                                            <DatePickerAdapter
                                                id="temp_expire_schedule_date"
                                                className="form-control"
                                                name="temp_expire_schedule_date"
                                                selected={this.state.temp_expire_schedule_date}
                                                onChange={this.handleChange}
                                                disable={this.props.match.params.action === 'view' || this.state.post_now_loading || this.state.save_draft_loading}
                                          />
                                  
                                        }
                                    </div>
                                      <div className="col-4">
                                        <div className="calendar-wrapper time-selected-input">
                                          <p className="labelText">
                                            <Translation>
                                              {(t) => <span>{t("press_release:time")}</span>}
                                            </Translation>
                                          </p>
                                          <DatePicker
                                            className="form-control"
                                            selected={this.state.temp_expire_schedule_time}
                                            minTime={this.state.temp_schedule_time}
                                            onChange={(date) =>
                                              this.setState({ temp_expire_schedule_time: date })
                                            }
                                            timeInputLabel="Time:"
                                            dateFormat="h:mm aa"
                                            showTimeInput
                                            disabled={this.props.match.params.action === 'view' || this.state.post_now_loading || this.state.save_draft_loading}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              )}
                            </div>                                                                                                         

                        <div className="row mt-3">
                          <div className="col">
                            {((this.props.match.params.id &&
                              _.some(
                                JSON.parse(
                                  localStorage.getItem("permission_list")
                                ),
                                { codename: "contact_news_edit" }
                              )) ||
                              (!this.props.match.params.id &&
                                _.some(
                                  JSON.parse(
                                    localStorage.getItem("permission_list")
                                  ),
                                  { codename: "contact_news_create" }
                                ))) && this.props.match.params.action !== 'view' && ( 
                                <button
                                  type="submit"
                                  className="btn btn-block btn-outline-primary"
                                  onClick={() => this.changeStatus("draft")}
                                  disabled={this.state.status_old?.toLowerCase() === "publish"}
                                >
                                  {this.state.save_draft_loading && (
                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                  )}
                                  <Translation>
                                    {(t) => (
                                      <span>{t("contact_menu:save_draft")}</span>
                                    )}
                                  </Translation>
                                </button>
                              )}
                          </div>

                              <div className="col">
                              {_.some(
                                JSON.parse(localStorage.getItem("permission_list")),
                                {
                                  codename: "contact_news_approve",
                                }
                              ) && this.props.match.params.action !== 'view' && (
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-primary"
                                    onClick={() => this.changeStatus("publish")}
                                  >
                                    {this.state.post_now_loading && (
                                      <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                    )}
                                    <Translation>
                                      {(t) => <span>{t("press_release:confirm")}</span>}
                                    </Translation>
                                  </button>
                                )}
                              </div>                        
                        </div>
                  </div> 
                    
                    {/* PHONE VIEW */}
                    <h6 className="text-center mt-5">
                      <Translation>
                        {(t) => <span>{t("press_release:display_ex")}</span>}
                      </Translation>
                    </h6>
                    <div className="phone-preview">
                      <div className="inner" >
                        {this.state.news.previewImage && (
                          <img
                            src={this.state.news.previewImage}
                            alt="news"
                            className="img-fluid mt-2 mb-2"
                          />
                        )}
                        
                       {this.state.language === 'th' && 
                        <div className='innerText'>
                           <h6 className="mb-0">{this.state.news?.title}</h6>
                           {this.state.news?.title &&
                            <div className="d-flex justify-content-between mb-3 dateText">                          
                              <span><img className="mr-1" src={process.env.PUBLIC_URL + "/images/icons/docIcon.svg"} />{format(this.state.temp_schedule_time, "DD/MM/YYYY - HH:mm น.", { locale: thLocale })}</span>
                              <div className="d-flex flex-row justify-content-end dateText">                            
                                <span><img className="mr-1" src={process.env.PUBLIC_URL + "/images/icons/timeIcon.svg"} alt="time" />4 
                                <Translation>{(t) => <span>{t("press_release:Minutes")}</span>}</Translation></span>
                                <span><img className="mr-1 ml-1" src={process.env.PUBLIC_URL + "/images/icons/eyeSmallIcon.svg"} alt="eye" />15</span>
                              </div>
                            </div>
                            }
                        <div                        
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: this.state.news.detail,
                          }}
                          />
                          <div>                                                    
                          {(this.state.news?.newsImages.edges || this.state.upload_image_list) &&  <Translation>{(t) => <p>{t("press_release:Additional attachment")}</p>}</Translation>}
                          </div>
                          <div className="d-flex flex-row flex-wrap addtionImgContainer">
                            {this.state.news.newsImages.edges?.map(
                                (news_image, index) => (
                                  <img
                                    className="addtionImage"                                    
                                    key={index}
                                    src={news_image.node.source}
                                  />
                                )
                              )}        
                              {this.state.upload_image_list?.map(
                              (news_image, index) =>                                                                                             
                                (
                                <img
                                  className="addtionImage"      
                                  key={index}
                                  src={URL.createObjectURL(news_image)}
                                />
                              )
                              )}                                                
                          </div>
                        </div>
                        }
                      
                        {this.state.language === 'en' && 
                        <div className='innerText'>
                           <h6>{this.state.news?.titleEn}</h6>
                           {this.state.news?.title &&
                           <div className="d-flex justify-content-between mb-3 dateText">                          
                            <span><img className="mr-1" src={process.env.PUBLIC_URL + "/images/icons/docIcon.svg"} />{format(this.state.temp_schedule_time, "DD/MM/YYYY - HH:mm น.", { locale: enLocale })}</span>
                            <div className="d-flex flex-row justify-content-end dateText">                            
                              <span><img className="mr-1" src={process.env.PUBLIC_URL + "/images/icons/timeIcon.svg"} alt="time" />4 min</span>
                              <span><img className="mr-1 ml-1" src={process.env.PUBLIC_URL + "/images/icons/eyeSmallIcon.svg"} alt="eye" />15</span>
                            </div>
                           </div>}
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: this.state.news.detailEn,
                          }}/>
                          <div>
                          {(this.state.news.newsImages.edges || this.state.upload_image_list) &&  <p>Addition attach File</p>}
                          </div>
                          <div className="d-flex flex-row flex-wrap addtionImgContainer">
                            {this.state.news.newsImages.edges?.map(
                                (news_image, index) => (
                                  <img
                                    className="addtionImage"                                    
                                    key={index}
                                    src={news_image.node.source}
                                  />
                                )
                              )}        
                              {this.state.upload_image_list?.map(
                              (news_image, index) =>                                                                                             
                                (
                                <img
                                  className="addtionImage"
                                  key={index}
                                  src={URL.createObjectURL(news_image)}
                                />
                              )
                              )}                                                
                          </div>
                        </div>
                        } 
                        

                        <div className="mt-2">
                          {this.state.fileMore?.map((file, inx) => {
                            let typeFile = file?.fileName?.split(".")
                            let typeICONFile = typeFile?.length ? typeFile[typeFile.length - 1] : ""
                    
                            return (
                              <p key={inx} className="fileAttach"> 
                                <img
                                  src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeICONFile + "Icon.png"}
                                  style={{ width: '30px' , float: 'left' , marginRight: 8 }}
                                /> &nbsp;
                                <Link
                                  to="route"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    window.open(
                                      typeof file?.viewPtath === "string" || file?.fileUrl
                                        ? file.viewPtath || file.fileUrl
                                        : URL.createObjectURL(file?.viewPtath ? file?.viewPtath : file.fileUrl)
                                    )
                                  }}
                                  target="blank">{file?.fileName}</Link>
                              </p>

                            )
                          })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </WrapperContent>
        }
      </Wrapper>
    );
  }
}

export default CreateUpdateNews;
