import React, { Component } from 'react';
import CollapsibleTable from './collapsibleTable';
import Header from '../../../../components/header/index';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from '../../../accountingTopMenuNavigation';
import SeacrhDetails from '../../../../components/search/indexSearch';
import './slip.scss'
import { format } from 'date-fns';
import ReactPaginate from "react-paginate";
import Loading from '../../../../libs/loading';
import API from '../../../../api/logTransfer'

class LogTransferList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
            startDate: new Date(),
            endDate: new Date(),
            searchProject: "",
            loading: true,
            indexFirst: 0,
            indexLast: 10,
            countData: 0,

            AllProject: null,
            TransferLogData: null
        }
        this.handlePropsChange = this.handlePropsChange.bind(this)
        this.getLog = this.getLog.bind(this)
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    }

    componentDidMount() {
        this.getProjectAll()
        this.getLog(0, 10)
    }

    getProjectAll() {
        API.get_All_project().then((res) => {
            let proSetMapData = res.data.iconProject.map((item) => ({ ...item, label: item.fullName, value: item.projectId }))
            this.setState({
                AllProject: proSetMapData
            })
        })
    }

    getLog(index_f, index_l) {
        API.get_icon_log({
            search: this.state.search,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            projectId: this.state.searchProject,
            indexFirst: index_f,
            indexLast: index_l,
        }).then((res_pons) => {
            this.setState({
                TransferLogData: res_pons?.data?.iconTransferLog ? res_pons?.data?.iconTransferLog : null,
                loading: false,
                countData: res_pons?.data?.countData ? res_pons?.data?.countData : 0
            })
        })
    }

    handlePropsChange(channel_list) {
        let search = ""
        let start_date = null
        let end_date = null
        let searchProject = ""

        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            }
            else if (e.name === "start_date" && e.value !== '') {
                start_date = e.value
            }
            else if (e.name === "end_date" && e.value !== '') {
                end_date = e.value
            }
            else if (e.name === "searchType" && e.value !== '') {
                searchProject = e.value
            }
        });

        this.setState({
            search: search,
            startDate: format(start_date, "DD/MM/YYYY") != "Invalid Date" ? new Date(start_date) : start_date,
            endDate: format(end_date, "DD/MM/YYYY") != "Invalid Date" ? new Date(end_date) : end_date,
            searchProject: searchProject,
            loading: true
        }, () => {
            this.getLog(this.state.indexFirst, this.state.indexLast)
        })
    }

    handlePageClick = (event) => {
        let page = event.selected + 1;
        // page * last, (page + 1) * last

        let index_f = event.selected * this.state.pageSize
        let index_l = page * this.state.pageSize
        this.setState({ page: page, indexFirst: index_f, indexLast: index_l, loading: true })
        document.body.style.cursor = "wait";
        this.getLog(index_f, index_l);
    };
    handlePageSizeChange(e) {
        let pageSizes = e.target.value
        this.setState({ indexLast: pageSizes }, () => {
            if (parseInt(pageSizes) === this.state.indexLast) {
                document.body.style.cursor = "wait";
                this.getLog(0, pageSizes);
            }
        })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                    {t =>
                        !this.state.loading ?
                            <div id="wrapper-content">
                                <AccountingTopMenuNavigation mini={true} />

                                <div className="container-fluid box slip-verification-list" >
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4" style={{ display: 'flex' }}>
                                                <Link to="/accounting">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <p>
                                                    {t("report:Icon Transfer Log")} (Interface)
                                                </p>
                                            </h3>
                                        </div>
                                    </div>

                                    <div className='filterSearch' style={{ zoom: '90%' }}>
                                        <SeacrhDetails
                                            text={[t("PageList:Search")]}
                                            selectAndSearchIs={true}
                                            seleteDetail={this.state.AllProject}
                                            startDateIs={true}
                                            endDateIs={true}
                                            searchBoxId={true}
                                            search={this.state.search}
                                            start_date={this.state.startDate}
                                            end_date={this.state.endDate}
                                            handlePropsChange={this.handlePropsChange}
                                        />
                                    </div>

                                    <div className="content-inner">
                                        <CollapsibleTable
                                            filter={{
                                                searchProject: this.state.searchProject,
                                                start_date: this.state.startDate,
                                                end_date: this.state.endDate,
                                            }}
                                            TransferLogData={this.state.TransferLogData}
                                            AllProjectData={this.state.AllProject}
                                            countData={this.state.countData}
                                        />

                                        <div className='row fixRequestFast'>
                                            <div className='col-6'>
                                                <select className='page' onChange={this.handlePageSizeChange}>
                                                    <option value="10">{t("silvermanguard:show")} 10 {t("silvermanguard:items")}</option>
                                                    <option value="20">{t("silvermanguard:show")} 20 {t("silvermanguard:items")}</option>
                                                    <option value="50">{t("silvermanguard:show")} 50 {t("silvermanguard:items")}</option>
                                                    <option value="100">{t("silvermanguard:show")} 100 {t("silvermanguard:items")}</option>
                                                </select>
                                            </div>
                                            <div className='col-6'>
                                                <div className='text-right' style={{ marginTop: '22px' }}>{t("silvermanguard:listAll")} {this.state.totalCount} &nbsp;

                                                    {this.state.pageAll > 0 && (
                                                        <ReactPaginate
                                                            previousLabel={"previous"}
                                                            nextLabel={"next"}
                                                            breakLabel={
                                                                <span className="btn page-link">...</span>
                                                            }
                                                            breakClassName={"break-me"}
                                                            pageClassName="page-item"
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={4}
                                                            previousClassName="sr-only"
                                                            containerClassName={"pagination float-right"}
                                                            subContainerClassName={"pagination-page"}
                                                            nextLinkClassName="sr-only"
                                                            pageLinkClassName="page-link"
                                                            activeClassName={"active"}
                                                            pageCount={this.state.pageAll}
                                                            onPageChange={this.handlePageClick}
                                                        />
                                                    )}

                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            :
                            <Loading />
                    }
                </Translation>

            </Wrapper>

        )
    }
}

export default LogTransferList;