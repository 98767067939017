import React from "react";
import getApiRoot from "../../../../libs/getAPIRoot";
import Helpers from '../../../../libs/file_download_helper';
import { format } from "date-fns";
import { Modal } from 'react-bootstrap';
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import i18next from "i18next";
import { Translation } from "react-i18next";

class ExportReceiptDepositMovementReportAll extends React.Component {

    constructor(props) {
        super(props);
        let month_before = new Date();
        month_before.setMonth(month_before.getMonth() - 1);

        this.state = {
            loading: false,
            showModal: false,
            temp_start_date: month_before,
            temp_end_date: new Date(),
            end_date: new Date()
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    submit(e) {
        this.setState({
            loading: true,
        }, () => {
            Helpers.httpRequest(
                getApiRoot() + 'export/all_receipt_deposit_movement?start_date='
                + format(this.state.temp_start_date, 'DD/MM/YYYY')
                + '&end_date=' + format(this.state.temp_end_date, 'DD/MM/YYYY'),
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "receipt_deposit.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });

        e.preventDefault();



    }
    handleSubmit = (event) => {
        this.setState({ showModal: true });
    };

    render() {

        return (

            <Translation>
                {t =>
                    <React.Fragment>'
                        <Modal show={this.state.showModal} dialogClassName="modal-approve" id="modal" >
                            <Modal.Body style={{ fontFamily: 'Kanit', borderBottom: 'none' }}>
                                <div onSubmit={this.submit}>
                                    <h3 className="pt-2 pb-2">{i18next.t("AllFilter:Select date range")}</h3>
                                    <form >
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="surname">{i18next.t("AllFilter:Sort by date")}</label>
                                                <DatePickerAdapter
                                                    name="temp_start_date"
                                                    className="form-control"
                                                    selected={this.state.temp_start_date}
                                                    // minDate={this.state.min_date}
                                                    maxDate={this.state.end_date}
                                                    onChange={this.handleInputChange}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="name">{i18next.t("AllFilter:to")}</label>
                                                <DatePickerAdapter
                                                    name="temp_end_date"
                                                    className="form-control"
                                                    selected={this.state.temp_end_date}
                                                    // maxDate={this.state.current_date}
                                                    onChange={this.handleInputChange}
                                                    required={true}
                                                />

                                            </div>
                                        </div>



                                        <div className="col-12  mt-3 text-right">
                                            <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({ showModal: false })}>
                                                <span>{i18next.t("Allaction:cancel")}</span>
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                disabled={this.state.loading} type="submit"
                                            >
                                                {i18next.t("AllFilter:Print all")}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Modal.Body>

                        </Modal>
                        <div style={{ position: 'absolute', top: '130px', right: '22px' }}>
                            <button type="submit" className="btn print float-right" onClick={this.handleSubmit}>
                            {i18next.t("AllFilter:Print all")}
                            </button>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default ExportReceiptDepositMovementReportAll;
