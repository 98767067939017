import React, { Component } from 'react';
import { Translation } from "react-i18next";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import Swal from "sweetalert2";
import BackButtonIcon from '../components/BackBtn/indexBack';
import Navigation from './navigation';
import SeacrhDetail from '../components/search';
import SeacrhDetails from '../components/search/indexSearch';
import UploadFileTable from './uploadFileTable';
import i18n from 'i18next';
import axios from "axios";
import getApiRoot from "../libs/getAPIRoot";


const selectData =[
    {
        label:"ทั้งหมด",
        value:""
    },
    {
        label:"รูปภาพ",
        value:"image"
    },
    {
        label:"เอกสาร",
        value:"document"
    }
   
]

const selectDataMenuName =[
    {
        labelEN:"All",
        label:"ทั้งหมด",
        value:""
    },
    {
        labelEN:"carvms",
        label:"งานจัดการผู้มาติดต่อ",
        value:"carvms"
    },
    {
        labelEN:"carfinished",
        label:"รายงานสรุปรายรับค่าที่จอดรถ",
        value:"carfinished"

    },
    // {
    //     labelEN:"invoice",
    //     label:"ใบแจ้งหนี้",
    //     value:"invoice"
    // },
    {
        labelEN:"receive",
        label:"ใบเสร็จรับเงิน",
        value:"receive"
    },
    // {
    //     labelEN:"withheld_tax",
    //     label:"ภาษีถูกหัก ณ ที่จ่าย",
    //     value:"withheld_tax"
    // },
    // {
    //     labelEN:"withholding_tax",
    //     label:"ภาษีหัก ณ ที่จ่าย",
    //     value:"withholding_tax"
    // },
    {
        labelEN:"cash_deposit",
        label:"รายการนำฝากเงินสด",
        value:"cash_deposit"
    },
    {
        labelEN:"receipt_deposit",
        label:"รับล่วงหน้า",
        value:"receipt_deposit"
    },
    {
        labelEN:"credit_note",
        label:"ใบลดหนี้",
        value:"credit_note"
    },
    {
        labelEN:"purchase_record_group",
        label:"ตั้งค่าใช้จ่าย",
        value:"purchase_record_group"
    },
    // {
    //     labelEN:"payment_voucher",
    //     label:"ใบสำคัญจ่าย",
    //     value:"payment_voucher"
    // },
    {
        labelEN:"pay_record_group",
        label:"บันทึกจ่ายค่าใช้จ่าย",
        value:"pay_record_group"
    },
    {
        labelEN:"petty_cash_record",
        label:"ตั้งเงินสดย่อย",
        value:"petty_cash_record"
    },
    {
        labelEN:"petty_cash_payment",
        label:"เบิกชดเชยเงินสดย่อย",
        value:"petty_cash_payment"
    },
    {
        labelEN:"advance",
        label:"เงินทดรองจ่าย",
        value:"advance"
    },
    {
        labelEN:"clear_advance",
        label:"เคลียร์เงินทดรองจ่าย",
        value:"clear_advance"
    },
    {
        labelEN:"guarantee_money_receive",
        label:"เงินค้ำประกัน",
        value:"guarantee_money_receive"
    },
    {
        labelEN:"other_expense",
        label:"ค่าใช้จ่ายอื่นๆ",
        value:"other_expense"
    },
    {
        labelEN:"other_receive",
        label:"รายรับอื่นๆ",
        value:"other_receive"
    },
    {
        labelEN:"unknown_receive",
        label:"เงินรับรอการตรวจสอบ",
        value:"unknown_receive"
    },
    {
        labelEN:"accept_credit_note",
        label:"รับใบลดหนี้",
        value:"accept_credit_note"
    },
    {
        labelEN:"prepaid_deposit",
        label:"จ่ายล่วงหน้า",
        value:"prepaid_deposit"
    },
    {
        labelEN:"",
        label:"ค่าเสื่อม",
        value:"depreciate"
    },
    {
        labelEN:"",
        label:"ลูกหนี้บัตรเครดิต",
        value:"credit_card_deposit_group"
    },
    {
        labelEN:"",
        label:"เพย์เมนท์เกตเวย",
        value:"payment_gateway_group"
    },
    {
        labelEN:"",
        label:"เคลียร์เงินค้ำประกัน",
        value:"clear_guarantee"
    },
    {
        labelEN:"",
        label:"เช็ครับ",
        value:"cheque_deposit"
    },
    {
        labelEN:"",
        label:"เช็คจ่าย",
        value:"cheque_transaction"
    },
    // {
    //     labelEN:"",
    //     label:"โอนระหว่างบัญชี",
    //     value:"bank_transfer"
    // },
    {
        labelEN:"",
        label:"พื้นที่ส่วนกลาง",
        value:"booking"
    },{
        labelEN:"",
        label:"แจ้งซ่อม",
        value:"fix_request"
    },{
        labelEN:"",
        label:"คำร้องขอหนังสือรับรองการปลอดหนี้",
        value:"debs_free_certification_request"
    },{
        labelEN:"",
        label:"หนังสือรับรองการปลอดหนี้",
        value:"debs_free_certification"
    },{
        labelEN:"",
        label:"หนังสือทวงถาม",
        value:"collection"
    },{
        labelEN:"",
        label:"ใบขอซื้อ",
        value:"procurement_form"
    },{
        labelEN:"",
        label:"ใบสั่งซื้อ",
        value:"purchase_orderes"
    },{
        labelEN:"",
        label:"บันทึกสัญญารายจ่าย",
        value:"contract_expense"
    },
    {
        labelEN:"",
        label:"บันทึกสัญญาบริการพื้นที่ส่วนกลาง",
        value:"contract_revenue"
    },
    {
        labelEN:"",
        label:"การจัดการสต็อคและวัสดุสิ้นเปลือง",
        value:"requisition_note"
    },
    {
        labelEN:"",
        label:"แจ้งซ่อม",
        value:"PersonalNiti"
    },
    {
        labelEN:"",
        label:"แจ้งซ่อม",
        value:"Personal-Homecare"
    },
    {
        labelEN:"",
        label:"แจ้งซ่อม",
        value:"commonfeeS-Homecare"
    },
    {
        labelEN:"",
        label:"แจ้งซ่อม",
        value:"commonfeeS-Niti"
    },
    {
        labelEN:"",
        label:"แจ้งซ่อม",
        value:"compensation-Resident"
    },
    {
        labelEN:"",
        label:"แจ้งซ่อม",
        value:"compensation-Niti"
    },
    {
        labelEN:"DOC",
        label:"DOC",
        value:"documents"
    },
    {
        labelEN:"",
        label:"ติดตามทวงถามโดยทนายความ",
        value:"collection_law_firm"
    },
    {
        labelEN:"",
        label:"ค่าใช้จ่ายออกเอกสารติดตามทวงถามโดยทนายความ",
        value:"receive_collection_law_firm"
    },
    {
        labelEN:"",
        label:"หนังสือติดตามโดยทนายความ",
        value:"fmcf_collection_law_firm"
    }

]

class uploadFileList extends Component {
    constructor(props) {
        super(props);
        // this.state = getSettingMenuData() 
        this.state = {
            useAccount: false,
            status: "",

            startDate: null,
            endDate: null,
            search: "",
            typeReportRef: "",


            temp_startDate: null,
            temp_endDate: null,
            temp_search: "",
            temp_typeReportRef: "",

            select1: "",
            select2: "",

            selectItem: [],
            selectAll: false,
            re_query: false,
            getLinkToShareMail: "",
            getLinkToShareLine: "",
            showModalShare: false,
            typeFile:"",
            menuName:""
        }
    }


    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }


    onChangeSearch = (e) => {
        if (e[0].name === 'start_date') {
            this.setState({ temp_startDate: e[0].value })
        }else if (e[0].name === 'end_date') {
            this.setState({ temp_endDate: e[0].value })
        }else if (e[0].name === 'search') {
            this.setState({
                search: e[0].value,
                startDate: this.state.temp_startDate ,
                endDate: this.state.temp_endDate,
            })
        }
        if(e[5]){
            this.setState({typeFile: e[5].value})
        }
        if(e[6]){
            this.setState({menuName: e[6].value})
        }
        
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div className="container-fluid box colorUse">
                        <BackButtonIcon
                            LinkBack={"/home"}
                            LinkText={t("uploadFileList:File Upload")}
                        />
                        <div className="content-inner">
                            <Navigation />
                            <SeacrhDetail
                                startDateIs={true} //วันที่้ค้นหาเริ่มต้น
                                endDateIs={true} //วันที่ค้นหาสิ้นสุด ใช่ หรือ ไม่
                                start_date={this.state.startDate}//วันที่้ค้นหาเริ่มต้น Input
                                end_date={this.state.endDate}//วันที่ค้นหาสิ้นสุด Input
                                onChangeSearch={this.onChangeSearch}
                            />
                            <div className='clearfix' />
                            <SeacrhDetails
                                searchBoxId={"dsds"}
                                listSelectBox={true}
                                listSelectBoxOption={selectData}
                                listSelectBoxTypeFile={this.state.typeFile}
                                listSelectMenuName={true}
                                listSelectMenuNameOption={selectDataMenuName}
                                listSelectMenuNameType={this.state.menuName}
                                handlePropsMultiDownload={this.handlePropsMultiDownload}
                                handlePropsChange={this.onChangeSearch}
                            />
                            <UploadFileTable selectItem={this.selectItem} 
                            status={this.props.status} state={this.state} 
                            onSuccess={this.onSuccess} onError={this.onError} />
                        </div>
                    </div>
                }
            </Translation>

        );
    }
}

export default uploadFileList;
