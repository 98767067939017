import React, {Component} from "react";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import ClearAdvanceCreateAndViewForm from "./clearAdvanceCreateAndViewForm"
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {QueryRenderer} from "react-relay";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Loading from '../../../libs/loading';

const query = graphql`
    query clearAdvanceCreateQuery($id: ID!, $status: String){
        allAdvance(status_Icontains: $status){
            totalCount
            edges{
                node{
                    id
                    issuedDate
                    docNumber
                    description
                    withdrawer
                    amount
                 
                }
            }
        }
        advance(id: $id){
            id
            amount
        }
        viewer {
            allChartOfAccount(codeList: ["5", "1151", "1152", "1154", "1210", "1230", "2121", "2122" ,"2123", "2124" ,"2125", "2126"]) {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
        allSettingAsset(settingAssetId: "", typeAsset: ""){
            edges{
                node{
                    id
                    typeAsset
                    depreciationRatePercent
                    depreciationRate
                    lifeTimeYear
                }
            }
        }
        allSetPettyCash(status:"active") {
            totalCount
            edges {
              node {
                id
                docNumber
                description
                withdrawer
                withdrawAmount
                totalPettyCashRecord
              }
            }
          }
    }
`;


class ClearAdvance extends Component {
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">
                        <QueryRenderer
                            environment={environment}
                            query={query}
                            cacheConfig={{use_cache: false}}
                            variables={{
                                id: this.props.match.params.id || "" ,
                                // status: this.props.match.params.id ? "" : "WITHDRAW",
                                status: "WITHDRAW",
                            }}
                            render={({error, props}) => {
                                if (props) {
                                    let no_setting_asset = true
                                    if(props.allSettingAsset?.edges.length === 11){
                                        no_setting_asset = false
                                    }
                                    return <ClearAdvanceCreateAndViewForm
                                        data={props}
                                        clearAdvanceType={'create'}
                                        isViewPage={false}
                                        no_setting_asset={no_setting_asset}
                                    />
                                } else {
                                 return <Loading/>
                                }
                            }}
                        />
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ClearAdvance;
