/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractRevenueStatus = "IN_PROGRESS" | "NEAR_END" | "NOT_RENEW" | "RENEW" | "VOID" | "WAIT" | "%future added value";
export type contractServiceListQueryVariables = {|
  status?: ?string,
  search?: ?string,
  first?: ?number,
  last?: ?number,
  startDate?: ?any,
  endDate?: ?any,
|};
export type contractServiceListQueryResponse = {|
  +allContractRevenue: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +voidDate: ?any,
        +voider: ?string,
        +voidRemark: string,
        +issuedDate: any,
        +debtor: {|
          +id: string,
          +refNumber: string,
          +name: string,
        |},
        +contractNumber: ?string,
        +status: ContractRevenueStatus,
        +total: ?number,
        +chartOfAccount: ?{|
          +name: string
        |},
        +startContractDate: any,
        +endContractDate: any,
        +creator: ?string,
        +added: any,
      |}
    |}>,
  |}
|};
export type contractServiceListQuery = {|
  variables: contractServiceListQueryVariables,
  response: contractServiceListQueryResponse,
|};
*/


/*
query contractServiceListQuery(
  $status: String
  $search: String
  $first: Int
  $last: Int
  $startDate: Date
  $endDate: Date
) {
  allContractRevenue(status: $status, search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        docNumber
        voidDate
        voider
        voidRemark
        issuedDate
        debtor {
          id
          refNumber
          name
        }
        contractNumber
        status
        total
        chartOfAccount {
          name
          id
        }
        startContractDate
        endContractDate
        creator
        added
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voider",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "debtor",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumber",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startContractDate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endContractDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "contractServiceListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ContractRevenueNodeConnection",
        "kind": "LinkedField",
        "name": "allContractRevenue",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractRevenueNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractRevenueNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "contractServiceListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ContractRevenueNodeConnection",
        "kind": "LinkedField",
        "name": "allContractRevenue",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractRevenueNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractRevenueNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f2276cfa99e0e82aabd600112423857",
    "id": null,
    "metadata": {},
    "name": "contractServiceListQuery",
    "operationKind": "query",
    "text": "query contractServiceListQuery(\n  $status: String\n  $search: String\n  $first: Int\n  $last: Int\n  $startDate: Date\n  $endDate: Date\n) {\n  allContractRevenue(status: $status, search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        voidDate\n        voider\n        voidRemark\n        issuedDate\n        debtor {\n          id\n          refNumber\n          name\n        }\n        contractNumber\n        status\n        total\n        chartOfAccount {\n          name\n          id\n        }\n        startContractDate\n        endContractDate\n        creator\n        added\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f489438a627402ce1f1b62a33fb354d3';

module.exports = node;
