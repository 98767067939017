import React, { Component } from 'react' 
import { Translation } from "react-i18next";
import { NavLink } from "react-router-dom"; 

export default class ClaimNavigation extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink exact={true} to={`/purchasing/claim/all`}>
                                        {t("claimRequst:all")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/claim/draft`}>
                                        {t("claimRequst:draf")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/claim/new`}>
                                        {t("claimRequst:new")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/claim/pending_approve`}>
                                        {t("claimRequst:peding")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/claim/approve`}>
                                        {t("claimRequst:inprocess")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/claim/finish`}>
                                        {t("claimRequst:finish")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/purchasing/claim/void`}>
                                        {t("claimRequst:reject")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                } 
            </Translation> 
        )
    }
}
