import React, {Component} from 'react';
import numberWithComma from "../../../../libs/numberWithComma";
import { Translation } from "react-i18next";
class ReceiveDetailTable extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="table-responsive fade-up">
                            <table className="table table-hover mt-2">
                                <thead className="thead-light">
                                <tr>
                                    <th width="80">{t('createReceive:List')}</th>
                                    <th width="200">{t('createReceive:Description')}</th>
                                    <th className="text-right" width="80">{t('createReceive:Total amount')}</th>
                                    <th className="text-right" width="80">{t('createReceive:Discount')}</th>
                                    <th className="text-right" width="80">{t('createReceive:Outstanding balance')}</th>
                                    <th className="text-right" width="100">{t('createReceive:Tax')}</th>
                                    <th className="text-right" width="100">{t('createReceive:Withholding tax')} ({t('createReceive:Baht')})</th>
                                    <th className="text-right" width="80">{t('createReceive:The selected amount')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.props.receive_transactions.map((receive_transactions) => (
        
                                        <tr key={receive_transactions.node.id}>
                                            <td>
                                                ({receive_transactions.node.transaction.invoice.docNumber}) {receive_transactions.node.transaction.productAndService ? receive_transactions.node.transaction.productAndService.productCode : receive_transactions.node.transaction.chartOfAccount.chartOfAccountCode}
                                            </td>
                                            <td>
                                                {receive_transactions.node.transaction.description.replace(/ *\[[^)]*\] */g, " ")}
                                            </td>
                                            <td className="text-right">
                                                {numberWithComma(receive_transactions.node.transaction.total)}
                                            </td>
                                            <td className="text-right">
                                                {numberWithComma(receive_transactions.node.discount)}
                                            </td>
                                            
                                            <td className="text-right">

                                                {receive_transactions.node.receive.status === 'WAIT' ? 
                                                    numberWithComma( (receive_transactions.node.transaction.total - receive_transactions.node.transaction.paid) + (receive_transactions.node.amount - receive_transactions.node.discount) )
                                                    :
                                                    numberWithComma( receive_transactions.node.transaction.total - receive_transactions.node.transaction.paid )
                                                }
                                                {/* { numberWithComma( receive_transactions.node.transaction.total - receive_transactions.node.transaction.paid ) } */}
                                                
                                            </td>
                                            <td className="text-right">
                                                {numberWithComma(receive_transactions.node.amount - (receive_transactions.node.amount / (1 + (receive_transactions.node.transaction.vat / 100))))}
                                            </td>
                                            {/* ภาษีหัก ณ ที่จ่าย (บาท) */}
                                            {/* <td className="text-right">
                                                {numberWithComma((receive_transactions.node.amount / (1 + (receive_transactions.node.transaction.vat / 100))) * (receive_transactions.node.transaction.whtRatePercent / 100))}
                                            </td> */}
                                            <td className="text-right">
                                                {numberWithComma(receive_transactions.node.transaction.whtRate)}
                                            </td>
                                            <td className="text-right">
                                                {numberWithComma(receive_transactions.node.amount - receive_transactions.node.discount)}</td>
                                        </tr>
        
                                    ))
        
                                }
                                {
                                    this.props.credit_note.map((credit_note) =>
                                        <tr key={credit_note.node.id}>
                                            <td>
                                                ({credit_note.node.docNumber})
                                            </td>
                                            <td>
                                                {credit_note.node.description}
                                            </td>
                                            <td className="text-right">
                                                - {numberWithComma(credit_note.node.price)}
                                            </td>
                                            <td className="text-right">
                                                -
                                            </td>
                                            <td className="text-right">
                                                -
                                            </td>
                                            <td className="text-right">
                                                -
                                            </td>
                                            <td className="text-right">
                                                -
                                            </td>
                                            <td className="text-right">
                                                - {numberWithComma(credit_note.node.price)}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default ReceiveDetailTable;