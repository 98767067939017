import '../styles/withholdingAttachmentTaxPrint.scss';
import React, {Component} from 'react';
import _ from "lodash";
import {graphql} from "babel-plugin-relay/macro";
import numberWithComma from "../../libs/numberWithComma";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import { getTotalpayRecord } from "../../libs/getTotalpayRecord";
const text_title = {
    "pnd-53": "ภ.ง.ด.53",
    "pnd-3": "ภ.ง.ด.3",
};

const query = graphql`
    query withholdingAttachmentTaxPrintQuery($type_tax:String, $start_date: DateTime, $end_date: DateTime) {
        selfProject {
            taxIdNumber
        }
        withholdingTaxViewer{
            allWithholdingTax(pnd: $type_tax,status: "active" ,startDate: $start_date, endDate: $end_date){
                edges{
                    node{
                        id
                        totalPrepaidDeposit
                        payRecordGroup {
                            id
                            docNumber
                            payRecord {
                                edges {
                                    node {
                                      id
                                      whtAmount
                                      whtRatePercent
                                      purchaseRecord {
                                        id
                                        preTaxAmount
                                        prepaidDepositTransactionUsed {
                                          edges {
                                              node {
                                                  amount
                                                  prepaidDepositTransaction{
                                                      whtRatePercent
                                                      vat
                                                  }
                                              }
                                          }
                                      }
                                      acceptCreditNoteTransaction(status: "paid"){
                                          edges {
                                              node {
                                                  price
                                              }
                                          }
                                      }
                                    }
                                  }
                                }
                              }
                          }
                        otherExpense{
                            otherExpenseTransaction{
                                edges{
                                    node{
                                        preTaxAmount
                                        whtRate
                                    }
                                }
                            }
                        }
                    }
                }
            }

            summary(status: "active",typeTax: $type_tax, startDate: $start_date, endDate: $end_date)
        }
    }
`;

class WithholdingAttachmentTaxPrint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count_all: null,
            all_transaction_total : null,
            all_tax_total : null,
            tax_id: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
            branch_list: [0,0,0,0,0]
        };
    }

    componentDidMount() {

        if (this.props.page_type === "with-data") {
            fetchQuery(
                environment,
                query,
                {
                    type_tax: this.props.type_tax,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                }).then(data => {

                if (_.isObject(data.selfProject)) {
                    if (data.selfProject.taxIdNumber) {
                        this.setState({tax_id: data.selfProject.taxIdNumber.split('')})
                    }
                }
                if (_.isObject(data.withholdingTaxViewer)) {
                    let summary = JSON.parse(data.withholdingTaxViewer.summary);
                    this.setState({
                        all_transaction_total: this.getTotal(data),
                        all_tax_total: summary.sum_all_tax,
                        count_all: summary.count
                    })
                }
            });

        }
    }
    getTotal(date) {
        let payRecordGroupSum = 0
        let otherExpenseSum = 0
        let totalPrepaidDepositSum = 0
        //PS
        date.withholdingTaxViewer.allWithholdingTax.edges.forEach((withholdingTax) => {
                return (

                    withholdingTax.node.payRecordGroup?.payRecord?.edges.map((payRecord) => {
                        payRecordGroupSum += getTotalpayRecord(payRecord)
                        return null;
                    })
                );
            }
        )
        //OE PD
        date.withholdingTaxViewer.allWithholdingTax.edges.forEach((withholdingTax) => {
                otherExpenseSum += _.sumBy(withholdingTax.node.otherExpense?.otherExpenseTransaction?.edges, function (otherExpenses) {
                    //ถ้าไม่มีหัก ณ ที่จ่าย ไม่ต้องนำยอดมารวม
                    return otherExpenses.node.whtRate > 0 ? (parseFloat(otherExpenses.node.preTaxAmount) || 0) : 0;
                })
                totalPrepaidDepositSum += (parseFloat(withholdingTax.node.totalPrepaidDeposit) || 0);

            }
        )
        const totalAmount = payRecordGroupSum + otherExpenseSum + totalPrepaidDepositSum;
        return totalAmount;

    }
    render() {
        return (
            <div id="withholding-attachment-tax-print">
                <div className="print-page-withholding-a4">
                    <div className="subpage">
                        <div className="withholding-tax-body">
                            <div className="text-center">
                                <span className="text-title">สรุปรายการภาษีที่นำส่ง&nbsp;&nbsp;<span className="text-title-pnd">{_.get(text_title,this.props.type_tax)}</span></span>
                            </div>
                            <div className="text-center mt-3 row">
                                <div className="col-1"/>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2"/>
                                        <div className="col text-left">
                                            <span className="text-header">เลขประจำตัวผู้เสียภาษีอาการ(13หลัก)</span>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-2"/>
                                        <div className="col text-left">(ของผู้มีหน้าที่หักภาษี ณ ที่จ่าย)</div>
                                    </div>
                                </div>
                                <div className="col pl-0 pr-0">
                                    {this.state.tax_id.map((value, index) => {
                                       let value_show = "\u00a0\u00a0";
                                        if(this.props.page_type === 'with-data'){
                                            value_show = value
                                        }
                                        if (index === 1 || index === 5 || index === 10 || index === 12) {
                                           return (
                                               <React.Fragment key={'taxNum' + index}>
                                                   <span>-</span>
                                                   <span className="border-id-number">{value_show}</span>
                                               </React.Fragment>
                                           )
                                       }
                                       return (
                                           <span className="border-id-number"
                                                 key={'taxNum' + index}>{value_show}</span>
                                       )
                                   })}
                               </div>
                               <div className="col text-left">
                                   <span className="text-header mr-2">สาขาที่</span>
                                   {this.state.branch_list.map((value, index) => {
                                       let value_show = "\u00a0\u00a0";
                                       if(this.props.page_type === 'with-data'){
                                            value_show = value
                                        }
                                    return (
                                        <span key={'branchNum' + index}
                                              className="border-branch-number">{value_show}</span>
                                    )
                                })}
                               </div>
                               <div className="col-1"/>
                            </div>
                            <div className="mt-3 row">
                                <div className="col"/>
                                <div className="col">
                                    นำส่งภาษีตาม
                                </div>
                                <div className="col">
                                    <span className="icon-correct mr-1">&#9745;</span>
                                    (1) มาตรา 3 เตรส
                                </div>
                                <div className="col">
                                    <span className="icon-correct mr-1">&#9744;</span>
                                    (2) มาตรา 65 จัตวา
                                </div>
                                <div className="col">
                                    <span className="icon-correct mr-1">&#9744;</span>
                                    (3) มาตรา 69 ทวิ
                                </div>
                                <div className="col"/>

                            </div>
                            <div className="mt-4">
                                <span>มีรายละเอียดการหักเป็นรายผู้มีเงินได้ ปรากฏตามใบแนบ {_.get(text_title,this.props.type_tax)} ที่แนบมาพร้อมนี้ : จำนวน {this.state.count_all ? numberWithComma(this.state.count_all,'-',0): "\u00a0\u00a0"} ราย</span>
                            </div>

                            <table className="table table-bordered mt-2">
                                <thead>
                                <tr>
                                   <th className="pl-5 text-header-table">รายการ</th>
                                   <th className="text-center text-header-table">จำนวนเงิน</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="pl-5">1. รวมยอดเงินได้ทั้งสิ้น</td>
                                    <td className="text-right">{this.state.all_transaction_total ? numberWithComma(this.state.all_transaction_total) : ""}</td>
                                </tr>
                                <tr>
                                    <td className="pl-5">2. รวมยอดภาษีที่นำส่งทั้งสิ้น</td>
                                    <td className="text-right">{this.state.all_tax_total ? numberWithComma(this.state.all_tax_total) : ""}</td>
                                </tr>
                                <tr>
                                    <td className="pl-5">3. เงินเพิ่ม (ถ้ามี)</td>
                                    <td className="text-right">{this.props.page_type === 'with-data' ? "0.00" : ""}</td>
                                </tr>
                                <tr>
                                    <td className="pl-5">4. รวมยอดภาษีที่นำส่งทั้งสิ้น และเงินเพิ่ม(2+3)</td>
                                    <td className="text-right">{this.state.all_tax_total ? numberWithComma(this.state.all_tax_total) : ""}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithholdingAttachmentTaxPrint;
