import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import '../../style/claim.scss'


class InsurranceViewInfo extends Component {
    
    functionCurrentMoneyFormat(money) {
        let formatMoney = 0
        if (money) {
            formatMoney = parseFloat(money).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return formatMoney ? formatMoney : "-"
    }

    render() {

        let { data, file_insurance } = this.props

        return (
            <Translation>
                {
                    t =>
                        <div className='row'>
                            <div className='col-8'>
                                <label className='headerLabel28'>{t("claim:Policy No")} : {this.props.data.policy_number}</label>


                                <p></p>
                                <label className='headerLabel18'>{t("claim:Insured name")}</label><br />
                                <label className='label16 colorGray text300'>
                                    {this.props.data?.beneficiary_first_name} {this.props.data?.beneficiary_last_name}</label>

                                <p></p>
                                <label className='headerLabel18'>{t("claim:Building insurance")}</label><br />
                                <label className='label16 colorGray text300'>{this.functionCurrentMoneyFormat(this.props.data?.building_insurance)} {t("claim:bath")}</label>

                                <p></p>
                                <label className='headerLabel18'>{t("claim:expand apartment")}</label><br />
                                <label className='label16 colorGray text300'>{this.functionCurrentMoneyFormat(this.props.data?.unit_number)}</label>

                                <p></p>
                                <label className='headerLabel18 labelW150'>{t("claim:all suites")}</label>
                                <label className='pl-5 mr-5'>:</label>
                                <label className='label16 colorGray labelW150'>{this.functionCurrentMoneyFormat(this.props.data?.unit_number)} {t("claim:unit")}</label>
                                <label className='headerLabel18'>{t("claim:per suite")}</label>
                                <label className='pl-5 mr-5'>:</label>
                                <label className='label16 colorGray'>{this.functionCurrentMoneyFormat(this.props.data?.insurance_per_unit)} {t("claim:bath")}</label>


                                <p></p>
                                <label className='headerLabel18 labelW150'>{t("claim:Total sum insured")}</label>
                                <label className='pl-5 mr-5'>:</label>
                                <label className='label16 colorGray'>{this.functionCurrentMoneyFormat(this.props.data?.sum_insurance_unit)} {t("claim:bath")}</label>

                                <p></p>
                                <label className='headerLabel18 labelW150'>{t("claim:ProtectionPeriod")}</label>
                                <label className='pl-5 mr-5'>:</label>
                                <label className='label16 colorGray'>
                                    {format(this.props.data?.insurance_start_date, "DD/MM/YYYY")} - {format(this.props.data?.insurance_end_date, "DD/MM/YYYY")}
                                </label>

                                <p></p>
                                <label className='headerLabel18 labelW150'>{t("claim:premium")}</label>
                                <label className='pl-5 mr-5'>:</label>
                                <label className='label16 colorGray'>{this.functionCurrentMoneyFormat(this.props.data?.insurance_premium)} {t("claim:bath")}</label>

                                <hr />
                            </div>
                            <div className='col-4'>

                                {
                                    file_insurance?.map((file, index) => {
                                        let typeFile = file.file_name.split(".")
                                        let typeFiles = typeFile[1] === "jpeg" && typeFile[1] === "jpg" ? "jpg" : typeFile[1]
                                        return (
                                            <div className='row'>
                                                <div className='col-2 mt-1'>
                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFiles + "Icon.png"} style={{ width: '30px', float: 'left' }} />
                                                </div>
                                                <div className='col-10'>
                                                    <Link
                                                        to="route"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            window.open(
                                                                typeof file.file_upload === "string" || file.viewPtath
                                                                    ? file.viewPtath ? file.viewPtath : file.file_upload
                                                                    : URL.createObjectURL(file.viewPtath ? file.viewPtath : file.file_upload)
                                                            )
                                                        }}
                                                        target="blank">

                                                        {file.file_name}
                                                    </Link>
                                                </div>
                                            </div>

                                        )
                                    })
                                }

                            </div>
                        </div>
                }

            </Translation>

        );
    }
}

export default withRouter(InsurranceViewInfo);
