import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import SelectContact from '../selectContact';
import ReceiptDepositContactCreateForm from './receiptDepositContactCreateForm';
import ReceiptDepositContactEditForm from './receiptDepositContactEditForm';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";


class ReceiptDepositCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer_list: [],
            accountType: "receipt_deposit",
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({customer_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContact updateCustomerList={this.updateCustomerList}
                                   initialContactType="residential"
                                   accountType={this.state.accountType}
                                   nextLink="/accounting/income/receipt_deposit/form/create"
                                   backLink="/accounting/income/receipt_deposit/list/all"/>
                    }

                    {this.props.match.params.status === 'create' &&
                        <ReceiptDepositContactCreateForm customerList={this.state.customer_list} category={this.state.accountType}
                        headerWord='receiptDeposit:add_receipt_deposit' backLink="/accounting/income/receipt_deposit/list/all"
                        chartOfAccountCode='2210'/>
                    }

                    {this.props.match.params.status === 'edit' &&
                        <ReceiptDepositContactEditForm receiptDepositId={this.props.match.params.id}/>
                    }
                </WrapperContent>
            </Wrapper>


        )
    }
}

export default ReceiptDepositCreate;