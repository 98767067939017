/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type summaryReportOutstandingReceivableQueryVariables = {|
  dueDate: any,
  typeOfContact?: ?string,
|};
export type summaryReportOutstandingReceivableQueryResponse = {|
  +summaryReportOutstandingReceivable: ?{|
    +numRow: number,
    +sumBalance: number,
  |}
|};
export type summaryReportOutstandingReceivableQuery = {|
  variables: summaryReportOutstandingReceivableQueryVariables,
  response: summaryReportOutstandingReceivableQueryResponse,
|};
*/


/*
query summaryReportOutstandingReceivableQuery(
  $dueDate: Date!
  $typeOfContact: String
) {
  summaryReportOutstandingReceivable(dueDate: $dueDate, typeOfContact: $typeOfContact) {
    numRow
    sumBalance
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dueDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "typeOfContact"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dueDate",
        "variableName": "dueDate"
      },
      {
        "kind": "Variable",
        "name": "typeOfContact",
        "variableName": "typeOfContact"
      }
    ],
    "concreteType": "summaryReportOutstandingReceivableObject",
    "kind": "LinkedField",
    "name": "summaryReportOutstandingReceivable",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numRow",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sumBalance",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "summaryReportOutstandingReceivableQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "summaryReportOutstandingReceivableQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0353105601da39567e8bfec018e87992",
    "id": null,
    "metadata": {},
    "name": "summaryReportOutstandingReceivableQuery",
    "operationKind": "query",
    "text": "query summaryReportOutstandingReceivableQuery(\n  $dueDate: Date!\n  $typeOfContact: String\n) {\n  summaryReportOutstandingReceivable(dueDate: $dueDate, typeOfContact: $typeOfContact) {\n    numRow\n    sumBalance\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f89ff96bc54b37bd40b5a822bf189a7d';

module.exports = node;
