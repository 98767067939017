import React, { Component } from 'react'
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import BudgetDraftCreateForm from './budgetDraftCreateForm';
import BudgetDraftEditForm from './budgetDraftEditForm';


class budgetDraftCreateUpdate extends Component {

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          {this.props.match.params.status === "create" && this.props.match.params.id === "noID" &&
            <BudgetDraftCreateForm />
          }
          {this.props.match.params.status === "APPROVE" &&
            <BudgetDraftEditForm
              status={this.props.match.params.status}
              id={this.props.match.params.id}
            />
          }
          {this.props.match.params.status === "NOT_APPROVE" &&
            <BudgetDraftEditForm
              status={this.props.match.params.status}
              id={this.props.match.params.id}
            />
          }
        </div>
      </Wrapper>
    )
  }
}
export default budgetDraftCreateUpdate;
