/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RegisterCollectionLawFirmStatus = "ACCEPT" | "FINISH" | "PDPA_ACCEPT" | "REVIEW" | "UNACCEPT" | "WAIT_DOC" | "%future added value";
export type regisCollectionLawFrimCreateQueryVariables = {||};
export type regisCollectionLawFrimCreateQueryResponse = {|
  +allRegisterCollectionLawFirm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +serviceContract: boolean,
        +email: ?string,
        +linkDowaloadFile: ?string,
        +status: RegisterCollectionLawFirmStatus,
        +accept: boolean,
        +remark: ?string,
        +creator: ?string,
        +creatorEdit: ?string,
        +creatorApproval: ?string,
        +language: string,
        +pdpaAccept: boolean,
        +pdpaAcceptMarketing: boolean,
        +documentRegisterCollectionLawFirm: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +fileName: string,
              +fileUpload: string,
              +fileUrl: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +allJuristic: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
        +role: string,
        +image: ?string,
        +user: ?{|
          +id: string,
          +username: string,
          +lastLogin: ?any,
        |},
      |}
    |}>,
  |},
|};
export type regisCollectionLawFrimCreateQuery = {|
  variables: regisCollectionLawFrimCreateQueryVariables,
  response: regisCollectionLawFrimCreateQueryResponse,
|};
*/


/*
query regisCollectionLawFrimCreateQuery {
  allRegisterCollectionLawFirm {
    edges {
      node {
        id
        serviceContract
        email
        linkDowaloadFile
        status
        accept
        remark
        creator
        creatorEdit
        creatorApproval
        language
        pdpaAccept
        pdpaAcceptMarketing
        documentRegisterCollectionLawFirm {
          edges {
            node {
              fileName
              fileUpload
              fileUrl
              id
            }
          }
        }
      }
    }
  }
  allJuristic {
    totalCount
    edges {
      node {
        id
        firstName
        lastName
        role
        image
        user {
          id
          username
          lastLogin
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceContract",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkDowaloadFile",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accept",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorEdit",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorApproval",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdpaAccept",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdpaAcceptMarketing",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUpload",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUrl",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicNodeConnection",
  "kind": "LinkedField",
  "name": "allJuristic",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JuristicNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserNode",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastLogin",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "regisCollectionLawFrimCreateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegisterCollectionLawFirmNodeConnection",
        "kind": "LinkedField",
        "name": "allRegisterCollectionLawFirm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegisterCollectionLawFirmNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RegisterCollectionLawFirmNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocumentRegisterCollectionLawFirmNodeConnection",
                    "kind": "LinkedField",
                    "name": "documentRegisterCollectionLawFirm",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DocumentRegisterCollectionLawFirmNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentRegisterCollectionLawFirmNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v16/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "regisCollectionLawFrimCreateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RegisterCollectionLawFirmNodeConnection",
        "kind": "LinkedField",
        "name": "allRegisterCollectionLawFirm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegisterCollectionLawFirmNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RegisterCollectionLawFirmNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocumentRegisterCollectionLawFirmNodeConnection",
                    "kind": "LinkedField",
                    "name": "documentRegisterCollectionLawFirm",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DocumentRegisterCollectionLawFirmNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentRegisterCollectionLawFirmNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v16/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c8c402b1cdcf5e0e5a6fa8fb3182887e",
    "id": null,
    "metadata": {},
    "name": "regisCollectionLawFrimCreateQuery",
    "operationKind": "query",
    "text": "query regisCollectionLawFrimCreateQuery {\n  allRegisterCollectionLawFirm {\n    edges {\n      node {\n        id\n        serviceContract\n        email\n        linkDowaloadFile\n        status\n        accept\n        remark\n        creator\n        creatorEdit\n        creatorApproval\n        language\n        pdpaAccept\n        pdpaAcceptMarketing\n        documentRegisterCollectionLawFirm {\n          edges {\n            node {\n              fileName\n              fileUpload\n              fileUrl\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  allJuristic {\n    totalCount\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        role\n        image\n        user {\n          id\n          username\n          lastLogin\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dc21a9c6e48783af89374180a42636a0';

module.exports = node;
