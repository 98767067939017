/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteChoiceInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type deleteSurveyChoiceMutationVariables = {|
  input: DeleteChoiceInput
|};
export type deleteSurveyChoiceMutationResponse = {|
  +deleteChoice: ?{|
    +ok: ?boolean
  |}
|};
export type deleteSurveyChoiceMutation = {|
  variables: deleteSurveyChoiceMutationVariables,
  response: deleteSurveyChoiceMutationResponse,
|};
*/


/*
mutation deleteSurveyChoiceMutation(
  $input: DeleteChoiceInput!
) {
  deleteChoice(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteChoicePayload",
    "kind": "LinkedField",
    "name": "deleteChoice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteSurveyChoiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteSurveyChoiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e6d191b4745feb8ffca450cae6acabab",
    "id": null,
    "metadata": {},
    "name": "deleteSurveyChoiceMutation",
    "operationKind": "mutation",
    "text": "mutation deleteSurveyChoiceMutation(\n  $input: DeleteChoiceInput!\n) {\n  deleteChoice(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7275df5c65afd7d5e2766aaff0e4182';

module.exports = node;
