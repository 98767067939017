import React, { Component } from 'react';
import './scanToRepair.scss';
import { format } from "date-fns";
import API from '../api/sing'
import Swal from 'sweetalert2';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
class scanToRepair extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                "qr": "",
                "id": "",
                "docNumber": "",
                "name": "",
                "type": "",
                "storage": "",
                "purchase_date": "",
                "remark": "",
                "image": "",
                "approve_status": "",
            },
            urlPath: "",
            redidect: false,
            loading: true,
            Getimage: ""
        }
        this.approveStatus = this.approveStatus.bind(this)
        this.unApproveStatus = this.unApproveStatus.bind(this);

    }

    componentWillMount() {
        this.getDataId()
    }

    getDataId() {
        let Code = this.props.match.params.QRID
        API.getQR(Code).then((res) => {
            if (res.data.asset) {
                this.setState({ data: res.data.asset[0], loading: false, Getimage: res.data.asset[0].image })
            } else {
                Swal.fire(i18next.t("scanToRepair:No data found"), i18next.t("scanToRepair:Please try again"), 'error').then(() => {
                    this.setState({ redidect: true, loading: false })
                })
            }
        }, () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Unable to Connect to API at this Time"), 'error').then(() => this.setState({ loading: false })))
    }

    approveStatus() {
        let data = { "qr": this.props.match.params.QRID, "remark": "", "name": "", "status": 'approve' }
        let title = `${i18next.t("scanToRepair:Are you sure you want to inspect the assets")}?`;
        let haveBeforeApprove = "";

        if (this.state.data.approve_history.approve_term >= 1) {
            title = `${i18next.t("scanToRepair:Are you sure you want to inspect the assets again")}?`;
        }

        if (this.state.data.approve_status) {
            haveBeforeApprove = `<span style="color: red;">${i18next.t("scanToRepair:Because this item has already been inspected for assets")} </br> 
            ${i18next.t("scanToRepair:On the date")} ${format(this.state.data.approve_history.approve_date, 'DD/MM/YYYY')} ${i18next.t("scanToRepair:By")} ${this.state.data.approve_history.approver}
            </span>`;
        }

        Swal.fire({
            title: title,
            html: haveBeforeApprove,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    html:
                        `
                    <label class="swal2-title">${i18next.t("scanToRepair:Inspector")}<span class="textStarRedQrCode">*</span></label>
                    <input type="text" class="swal2-input" id="assetApprover">
                    <label class="swal2-title">${i18next.t("scanToRepair:Comment")}</label>
                    <input type="text" class="swal2-input" id="assetApproveRemark">
                    `,
                    showCancelButton: true,
                    confirmButtonText: i18next.t("Allaction:Yes"),
                    cancelButtonText: i18next.t("Allaction:cancel"),
                    reverseButtons: true,
                    preConfirm: () => {
                        let assetApprover = Swal.getPopup().querySelector('#assetApprover').value;
                        let assetApproveRemark = Swal.getPopup().querySelector('#assetApproveRemark').value;
                        data.name = assetApprover;
                        data.remark = assetApproveRemark;
                    }

                }).then((result) => {
                    if (result.value && data.name && data.name.trim() !== "") {
                        API.approveStatus(data).then((res) => {
                            if (res.data.statusMessage !== "FAIL") {
                                Swal.fire(i18next.t("scanToRepair:successcompleate"), i18next.t("scanToRepair:Asset inspection record completed"), "success").then(() => {
                                    // Refresh the page
                                    window.location.reload()
                                });
                            } else {
                                Swal.fire(i18next.t("scanToRepair:Saved Unsuccessful"), i18next.t("scanToRepair:Please try again."), "error")
                            }
                        })
                    } else if (!result.dismiss && data.name.trim() === "") {
                        Swal.fire(i18next.t("scanToRepair:Please enter the inspector's name"), i18next.t("scanToRepair:Please try again."), "error")
                        this.setState({ loading: false });
                    }
                })
            }
        })
    }

    unApproveStatus() {
        let data = { "qr": this.props.match.params.QRID, "remark": '', "name": '', "status": 'not_approve' }
        let haveBeforeApprove =
            `<span style="color: red;">${i18next.t("scanToRepair:Because this item has already been inspected for assets")} </br> 
            ${i18next.t("scanToRepair:On the date")} ${format(this.state.data.approve_history.approve_date, 'DD/MM/YYYY')} ${i18next.t("scanToRepair:By")} ${this.state.data.approve_history.approver}
        </span>`;

        Swal.fire({
            title: `${i18next.t("scanToRepair:Are you sure you want to cancel the asset inspection")}?`,
            html: haveBeforeApprove,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    html:
                        `
                    <label class="swal2-title">${i18next.t("scanToRepair:Inspector")}</label>
                    <input type="text" class="swal2-input" id="assetApprover">
                    <label class="swal2-title">${i18next.t("scanToRepair:Comment")}</label>
                    <input type="text" class="swal2-input" id="assetApproveRemark">
                    `,

                    showCancelButton: true,
                    confirmButtonText: i18next.t("Allaction:Yes"),
                    cancelButtonText: i18next.t("Allaction:cancel"),
                    reverseButtons: true,
                    preConfirm: () => {
                        let assetApprover = Swal.getPopup().querySelector('#assetApprover').value;
                        let assetApproveRemark = Swal.getPopup().querySelector('#assetApproveRemark').value;
                        data.name = assetApprover;
                        data.remark = assetApproveRemark;
                    }

                }).then((result) => {
                    if (result.value && data.name && data.name.trim() !== "") {
                        API.approveStatus(data).then((res) => {
                            if (res.data.statusMessage !== "FAIL") {
                                Swal.fire(i18next.t("Allaction:successcompleate"), i18next.t("scanToRepair:Asset inspection has been cancelled."), "success").then(() => {
                                    // Refresh the page
                                    window.location.reload()
                                });
                            } else {
                                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please try again."), "error")
                            }
                        })
                    } else if (!result.dismiss && data.name.trim() === "") {
                        Swal.fire(i18next.t("Allaction:Please enter the inspector's name"), i18next.t("Allaction:Please try again."), "error")
                        this.setState({ loading: false });
                    }
                })
            }
        })
    }

    render() {
        let AssetType = this.state.data.type === "land" ? i18next.t("scanToRepair:Land") :
            this.state.data.type === "building" ? i18next.t("scanToRepair:Building") :
                this.state.data.type === "building_renovation" ? i18next.t("scanToRepair:Building additions and improvements") :
                    this.state.data.type === "office_decoration" ? i18next.t("scanToRepair:Office decorations") :
                        this.state.data.type === "office_equipment" ? i18next.t("scanToRepair:Office equipment and supplies") :
                            this.state.data.type === "tools" ? i18next.t("scanToRepair:Tools / Mechanical equipment") :
                                this.state.data.type === "training_equipment" ? i18next.t("scanToRepair:Exercise / Recreational equipment") :
                                    this.state.data.type === "vehicle" ? i18next.t("scanToRepair:Vehicle") :
                                        this.state.data.type === "common_property" ? i18next.t("scanToRepair:Common property") :
                                            this.state.data.type === "intangible_asset" ? i18next.t("scanToRepair:Intangible assets") :
                                                this.state.data.type === "other_asset" ? i18next.t("scanToRepair:Other assets") : "-";

        let Location = this.state.data.storage === "" ? "-" : this.state.data.storage

        return (
            <Translation>
                {t=>
                    // localStorage.getItem('site_id') === '331' &&
                    this.state.loading === false &&
                    <div className='row' id='scanToRepair'>
                        <div className='col-xs-12 mx-auto col-sm-4' >
                            <h3 className='text-center'>{t("scanToRepair:Asset Information")}</h3>
                            <div style={{ padding: '10px 25px' }}>
                                <h4>Code : {this.state.data.docNumber}</h4>
                                <table className='table table-striped'>
                                    <tbody>
                                        <tr>
                                            <th>{t("scanToRepair:Asset Category")}</th>
                                            <td>{AssetType}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("scanToRepair:Asset name")}</th>
                                            <td>{this.state.data.name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("scanToRepair:Description")}</th>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <th>{t("scanToRepair:Storage source")}</th>
                                            <td>{Location}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("scanToRepair:Note")}</th>
                                            <td>{this.state.data.remark ? this.state.data.remark : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>{t("scanToRepair:Asset Reception Date")}</th>
                                            <td>{this.state.data.purchase_date}</td>
                                        </tr>
                                    </tbody>

                                </table>
                                <p className='text-center'>
                                    {
                                        this.state.Getimage !== "" ?
                                            <img src={process.env.PUBLIC_URL + this.state.Getimage} alt='img' /> :
                                            <img src={process.env.PUBLIC_URL + "/images/icons/defualt.png"} alt='defualt' />
                                    }
                                </p>
                                {this.state.data.approve_status ?
                                    <React.Fragment>
                                        <button onClick={this.approveStatus}>{i18next.t("scanToRepair:Double inspect the asset")}</button>
                                        <button className='mt-2' onClick={this.unApproveStatus} style={{ backgroundColor: 'red', color: 'white' }}>{i18next.t("scanToRepair:Cancel the asset inspection")}</button>
                                    </React.Fragment>
                                    :
                                    <button onClick={this.approveStatus}>{i18next.t("scanToRepair:Inspect the asset")}</button>
                                }
                                <p></p>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}


export default scanToRepair;
