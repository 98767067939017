import React, {Component} from 'react';
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Wrapper from "../../components/wrapper";
import Navigation from "./navigation";
import ElectricMeterListHistory from "./electricMeterListHistory";
import ElectricMeterListCompare from "./electricMeterListCompare";
import ElectricMeterListRecord from "./electricMeterListRecord";

class ElectricMeterList extends Component {

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>

                    <div className="container-fluid box">
                        <Navigation/>
                        {/*component*/}
                        {this.props.match.params.status === "record" &&
                            <ElectricMeterListRecord/>
                        }
                        {this.props.match.params.status === "compare" &&
                            <ElectricMeterListCompare/>
                        }
                        {this.props.match.params.status === "history" &&
                            <ElectricMeterListHistory/>
                        }
                    </div>

                </WrapperContent>
            </Wrapper>
        );
    }
}

export default ElectricMeterList;
