import React, { Component } from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import environment from "../../env/environment";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import getNameResidential from "../../libs/getNameResidential";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import PayChannel from "../expense/pay/payChannel";
import Swal from "sweetalert2";
import ClearGuaranteeMoneyReceivedUpdateMutation from "./mutations/clearGuaranteeMoneyReceivedUpdate";
import Redirect from "react-router-dom/es/Redirect";
import _ from "lodash";
import { Translation } from "react-i18next";
import i18n from 'i18next'
import Loading from '../../libs/loading';
import InputAddress from "../../libs/autoCompleteAddress";
import { Modal} from 'react-bootstrap';
import i18next from "i18next";

const guarantee = graphql`
  query clearGuaranteeMoneyReceivedViewQuery($id: ID!) {
    clearGuaranteeMoneyReceived(id: $id) {
      id
      docNumber
      issuedDate
      forfeitRemark
      firstName
      lastName
      name
      taxIdNumber
      phone
      email
      address
      country
      province
      district
      city
      postalCode
      payGroup
      contact{
        typeOfContact
      }
      guarantee {
        docNumber
        description
        contact {
          refNumber
          firstName
          lastName
          registeredName
          registeredAddress
          registeredCountry
          registeredProvince
          registeredCity
          registeredDistrict
          registeredPostalCode
          typeOfContact
          name
        }
        amount
        chartOfAccount {
          id
          chartOfAccountCode
          name
        }
        note
      }
      guaranteeTransaction {
        totalCount
        
        edges {
          node {
            id
            amount
            typeOfTransaction

            chartOfAccount {
              id
              chartOfAccountCode
              name
            }
          }
        }
      }
      cashTransaction {
        edges {
          node {
            id
            price
          }
        }
      }
      bankAccountTransaction {
        edges {
          node {
            id
            price
            date
            imageSlip
            bankAccount {
              bankName
              accountNumber
            }
          }
        }
      }
      chequeTransaction {
        edges {
          node {
            id
            date
            price
            chequeNumber
            bankAccount {
              id
              accountName
              branch
              bankName
            }
          }
        }
      }

      clearGuaranteeMoneyReceivedPettyCashChannel{
        edges{
            node{
                id
                price
                date
                setPettyCash{
                    docNumber
                    description
                    withdrawer
                }

            }
        }
      }

    }
  }
`;

const clearGuaranteeKey = [ "firstName", "lastName", "taxIdNumber","address","city","province","district","postalCode","email","phone" ];
class ClearGuaranteeMoneyReceivedView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sum_total: 0,
      loading: false,
      redirect: false,
      showModal:false,
      clearGuaranteeNameAddress:[]
    };
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getDataForEdit = this.getDataForEdit.bind(this);
    this.submit = this.submit.bind(this)
  }

  onChangeStatus() {
    let input = {
      clientMutationId: this.props.match.params.id,
      status: "void",
    };

    Swal.fire({
      title: i18n.t("guarantee_money_received:Are you sure to cancel this guarantee receipt?"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18n.t("guarantee_money_received:Cancel"),
      confirmButtonText: i18n.t("guarantee_money_received:Yes"),
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18n.t('guarantee_money_received:Note'),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18n.t("guarantee_money_received:Cancel"),
          confirmButtonText: i18n.t("guarantee_money_received:Yes"),
          inputValidator: (value) => {
            if (!value) {
              return "กรุณากรอกค่า";
            }
          },
        });
        if (void_remark) {
          this.setState({ loading: true });
          let input = {
            clientMutationId: this.props.match.params.id,
            status: "void",
            cancelRemark: void_remark,
          }
        ClearGuaranteeMoneyReceivedUpdateMutation(
          input,
          (response) => {
            if (response.updateClearGuaranteeMoneyReceived.ok) {
              Swal.fire(
                i18n.t("guarantee_money_received:Success!"),
                i18n.t("guarantee_money_received:The guarantee receipt has been cancelled"),
                "success"
              ).then(() => this.setState({ redirect: true, loading: false }));
            } else {
              Swal.fire(
                i18n.t("guarantee_money_received:Unsuccess Save!"),
                response.updateClearGuaranteeMoneyReceived.warningText,
                "warning"
              ).then(() => this.setState({ loading: false }));
            }
          },
          () => {
            this.setState({ loading: false });
            Swal.fire(
              i18n.t("guarantee_money_received:Unsuccess"),
              i18n.t("guarantee_money_received:Failed to cancel the guarantee receipt"),
              "error"
            );
          }
        );
      }
    } else {
        this.setState({ loading: false });
      }
    });
  }

  showCodeAccount (data) {
    let filter = _.filter(data, (d) => d.node.typeOfTransaction ===  "FORFEIT" )
    return _.map(filter, (key)=>{
        return (
            <span>{key.node.chartOfAccount.chartOfAccountCode} {key.node.chartOfAccount.name}</span>
        )
    })
}

  onChangeEdit() {
    Swal.fire({
      title: i18n.t('invoiceDetail:Are you sure to edit this invoice?'),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: i18n.t('invoiceDetail:No'),
      confirmButtonText: i18n.t('invoiceDetail:Yes'),
    }).then(async (result) => {
      if (result.value) {
        this.setState({ showModal: true });
      }
    });
  }

  componentWillMount() {
    this.getDataForEdit()
  }
  

  getDataForEdit(){
  ///edit clearGuarantee address
  let state = { ...this.state};
  if (this.props.location.state) {
      let key = Object.keys(this.props.location.state);
      key.forEach((key) => {
        state[key] = this.props.location.state[key];
        state['address'] = this.props.location.state['address'];
        state['registeredCity'] = this.props.location.state['city'];
        state['registeredDistrict'] = this.props.location.state['district'];
        state['registeredProvince'] = this.props.location.state['province'];
        state['registeredPostalCode'] = this.props.location.state['postalCode'];
      });
  } else {
    clearGuaranteeKey.forEach((key) => {
      state[key] = '';
    });
  }

  let dataState = state

  this.setState({
    clearGuaranteeNameAddress:dataState
  })

  }

  handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    
    let state = _.set(_.cloneDeep(this.state), name, value);
    this.setState(state);
  }

  onSelect(fullAddress) {

    const { registeredDistrict, registeredCity, registeredProvince, registeredPostalCode } = fullAddress

    let value = _.cloneDeep(this.state.clearGuaranteeNameAddress);
    _.set(value,'registeredDistrict',registeredDistrict)
    _.set(value,'registeredCity',registeredCity)
    _.set(value,'registeredProvince',registeredProvince)
    _.set(value,'registeredPostalCode',registeredPostalCode)

    this.setState({
      clearGuaranteeNameAddress:value
    })

  }
  submit(e) {

    e.preventDefault();

    this.props.history.push({
      pathname:`/document/clear-guarantee-money-received/` +
      this.props.match.params.id,
      state: {
        firstName : this.state.clearGuaranteeNameAddress.firstName,
        lastName : this.state.clearGuaranteeNameAddress.lastName,
        taxIdNumber : this.state.clearGuaranteeNameAddress.taxIdNumber,
        address : this.state.clearGuaranteeNameAddress.address,
        city : this.state.clearGuaranteeNameAddress.registeredCity,
        province : this.state.clearGuaranteeNameAddress.registeredProvince,
        district : this.state.clearGuaranteeNameAddress.registeredDistrict,
        postalCode : this.state.clearGuaranteeNameAddress.registeredPostalCode,
      },
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/accounting/guarantee-money-received/list/clear" />;
    }

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={guarantee}
            variables={{ id: this.props.match.params.id }}
            render={({ error, props }) => {
              if (error) {
                return <div>Error :(</div>;
              } else if (props) {

                return (
                  <div
                    className="container-fluid box"
                    id="receipt_deposit-view"
                  >
                    <div className="row justify-content-between">
                      <div className="col">
                        <Translation>
                          {t=>
                          <h3 className="mb-4">
                            <Link to="/accounting/guarantee-money-received/list/clear">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/wrapperContent/back.png"
                                }
                                alt="back"
                                className="back"
                              />
                            </Link>
                            {t("guarantee_money_received:Guarantee receipt")+" "+"(CGR)"}
                          </h3>}
                        </Translation>
                      </div>
                      <div className="col text-right mb-2">
                        {_.some(
                          JSON.parse(localStorage.getItem("permission_list")),
                          { codename: "accounting_guarantee_receive_delete" }
                        ) && (
                          <Translation>
                            {t=>
                            <button
                              className="btn btn-danger add mr-3"
                              disabled={this.state.loading}
                              onClick={this.onChangeStatus}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                              )}
                              {t("guarantee_money_received:Cancel the guarantee receipt")}
                            </button>}
                          </Translation>
                        )}
                      <Translation>
                            {t=>
                        <button
                          className="btn btn-primary add mr-3"
                          onClick={this.onChangeEdit}
                        >
                          {t("other_received:Edit Name/Address")}
                        </button>
                        }
                        </Translation>
                        {_.find(
                          props.clearGuaranteeMoneyReceived.guaranteeTransaction
                            .edges,
                          { node: { typeOfTransaction: "RETURN" } }
                        ) &&
                          _.some(
                            JSON.parse(localStorage.getItem("permission_list")),
                            { codename: "accounting_guarantee_receive_print" }
                          ) && (
                            <Link
                              to={
                                "/document/clear-guarantee-money-received/" +
                                props.clearGuaranteeMoneyReceived.id
                              }
                              target={"_blank"}>
                              <Translation>
                                {t=>
                                <button className="btn btn-primary add">
                                  {t("guarantee_money_received:Print payment voucher")}
                                </button>}
                              </Translation>
                            </Link>
                          )}
                      </div>
                    </div>

                    <Modal show={this.state.showModal} dialogClassName="modal-approve" id="modal" >
                        <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                          <div > 
                            <h3 className="pt-2 pb-2">{i18next.t("editAddress:Edit customer name-surname / address")}</h3>

                            <form onSubmit={this.submit}>
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <label htmlFor="name">{i18next.t("editAddress:Name")}</label>
                                <input type="text" class="form-control" id="firstName" name="clearGuaranteeNameAddress.firstName" 
                                  value={this.state.clearGuaranteeNameAddress.firstName}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div class="form-group col-md-6">
                                <label htmlFor="surname">{i18next.t("editAddress:Surname")}</label>
                                <input type="text" class="form-control" id="lastName" name="clearGuaranteeNameAddress.lastName" 
                                  value={this.state.clearGuaranteeNameAddress.lastName}
                                onChange={this.handleInputChange}/>
                              </div>
                            </div>
                              <div class="form-group">
                                <label htmlFor="taxIdNumber">{i18next.t("editAddress:Tax payer identification/Identification number")}</label>
                                <input type="text" class="form-control" id="taxIdNumber" name="clearGuaranteeNameAddress.taxIdNumber" value={this.state?.clearGuaranteeNameAddress?.taxIdNumber?.replace('--', '')}
                                onChange={this.handleInputChange}/>
                              </div>
                              <div class="form-group">
                                <label htmlFor="inputAddress2">{i18next.t("editAddress:Address")}</label>
                                <textarea cols="" rows="3" className="form-control" id="address"
                                name="clearGuaranteeNameAddress.address" maxLength={256} value={this.state.clearGuaranteeNameAddress.address} onChange={this.handleInputChange}/>
                              </div>
                              <div class="form-row">
                              <div class="form-group col-md-6">
                                  <label htmlFor="city">{i18next.t("editAddress:Sub-district")}</label>
                                  <InputAddress
                                      address="clearGuaranteeNameAddress.registeredDistrict"
                                      value={this.state.clearGuaranteeNameAddress.registeredDistrict}
                                      onChange={this.handleInputChange}
                                      onSelect={this.onSelect}
                                      mailing={false}
                                      className="form-control"    
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label htmlFor="city">{i18next.t("editAddress:District")}</label>
                                  <InputAddress
                                      address="clearGuaranteeNameAddress.registeredCity"
                                      value={this.state.clearGuaranteeNameAddress.registeredCity}
                                      onChange={this.handleInputChange}
                                      onSelect={this.onSelect}
                                      mailing={false}
                                      className="form-control"    
                                  />
                                </div>
                              </div>
                              <div class="form-row">
                                <div class="form-group col-md-6">
                                  <label htmlFor="inputState">{i18next.t("editAddress:Province")}</label>
                                  <InputAddress
                                      address="clearGuaranteeNameAddress.registeredProvince"
                                      value={this.state.clearGuaranteeNameAddress.registeredProvince}
                                      onChange={this.handleInputChange}
                                      onSelect={this.onSelect}
                                      mailing={false}
                                      className="form-control"    
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label htmlFor="inputZip">{i18next.t("editAddress:Zip code")}</label>
                                  <InputAddress
                                      address="clearGuaranteeNameAddress.registeredPostalCode"
                                      value={this.state.clearGuaranteeNameAddress.registeredPostalCode}
                                      onChange={this.handleInputChange}
                                      onSelect={this.onSelect}
                                      mailing={false}
                                      className="form-control"    
                                  />
                                </div>
                              </div>
      
                              <div className="col-12  mt-3 text-right">
                            
                              <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({showModal : false})}>
                                  <span>{i18next.t("editAddress:Cancel")}</span>
                              </button>
                              <button
                                    className="btn btn-primary"
                                    disabled={this.state.loading} type="submit"
                                  >
                                    {i18next.t("editAddress:Print")}{localStorage.getItem("language") === 'th' && "ใบสำคัญจ่าย"}
                              </button>
                            </div>
                         </form>
                          </div>
                        </Modal.Body>
                    </Modal>

                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <Translation>{t=><p>{t("guarantee_money_received:Customer")}</p>}</Translation>
                            <p>
                              {
                                props.clearGuaranteeMoneyReceived.guarantee
                                  .contact.refNumber
                              }{" "}
                              {
                               props.clearGuaranteeMoneyReceived.contact?.typeOfContact ? 
                                props.clearGuaranteeMoneyReceived.contact?.typeOfContact !== 'SUPPLIER' ? 
                                getNameResidential(
                                  props.clearGuaranteeMoneyReceived.firstName,
                                  props.clearGuaranteeMoneyReceived.lastName) :
                                  props.clearGuaranteeMoneyReceived.name
                               :
                                props.clearGuaranteeMoneyReceived.guarantee.contact?.typeOfContact !== 'SUPPLIER' ?
                                getNameResidential(
                                  props.clearGuaranteeMoneyReceived.guarantee
                                    .contact.firstName,
                                  props.clearGuaranteeMoneyReceived.guarantee
                                    .contact.lastName
                                ) : props.clearGuaranteeMoneyReceived.guarantee
                                .contact.name 
                              }
                              {" "}
                               <span style={{color: '#F43853'}}>
                              {  
                                  props.clearGuaranteeMoneyReceived.payGroup === "OTHER" ? `(${i18next.t("AgentRole:External Person")})` : 
                                  props.clearGuaranteeMoneyReceived.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : 
                                  props.clearGuaranteeMoneyReceived.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})`:
                                    ""
                              }
                            </span>
                            </p>
                          </div>
                          <div className="col">
                            <Translation>{t=><p>{t("guarantee_money_received:No.")}</p>}</Translation>
                            <p>
                              {props.clearGuaranteeMoneyReceived.docNumber}{" "}
                            </p>
                          </div>
                          <div className="col">
                            <Translation>{t=><p>{t("guarantee_money_received:Refer")}</p>}</Translation>
                            <p>
                              {
                                props.clearGuaranteeMoneyReceived.guarantee
                                  .docNumber
                              }{" "}
                            </p>
                          </div>
                          <div className="col">
                            <Translation>{t=><p>{t("guarantee_money_received:Date of issue")}</p>}</Translation>
                            <p>
                              {format(
                                props.clearGuaranteeMoneyReceived.issuedDate,
                                "DD/MM/YYYY",
                                { locale: thLocale }
                              )}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col">
                            <Translation>{t=><p>{t("guarantee_money_received:Address")}</p>}</Translation>
                            <p>
                              {
                              props.clearGuaranteeMoneyReceived.province ? 
                              props.clearGuaranteeMoneyReceived.province.includes("กรุงเทพ") || props.clearGuaranteeMoneyReceived.guarantee?.contact.registeredProvince.includes("กทม") ? (
                                <>
                                {props.clearGuaranteeMoneyReceived.address}
                                {props.clearGuaranteeMoneyReceived.district && ` แขวง${props.clearGuaranteeMoneyReceived.district}`}
                                {props.clearGuaranteeMoneyReceived.city && ` เขต${props.clearGuaranteeMoneyReceived.city}`}
                                {props.clearGuaranteeMoneyReceived.province && ` ${props.clearGuaranteeMoneyReceived.province} `}
                                {props.clearGuaranteeMoneyReceived.postalCode}{" "}
                                {props.clearGuaranteeMoneyReceived.country}
                                </>
                              ) : (
                                <>
                              {props.clearGuaranteeMoneyReceived.address}
                              {props.clearGuaranteeMoneyReceived.district && ` ตำบล${props.clearGuaranteeMoneyReceived.district}`}
                              {props.clearGuaranteeMoneyReceived.city && ` อำเภอ${props.clearGuaranteeMoneyReceived.city}`}
                              {props.clearGuaranteeMoneyReceived.province && ` จังหวัด${props.clearGuaranteeMoneyReceived.province} `}
                              {props.clearGuaranteeMoneyReceived.postalCode}{" "}
                              {props.clearGuaranteeMoneyReceived.country}
                                </>
                              )
                            :
                              props.clearGuaranteeMoneyReceived.guarantee?.contact.registeredProvince.includes("กรุงเทพ") || props.clearGuaranteeMoneyReceived.guarantee?.contact.registeredProvince.includes("กทม") ? (
                              <>
                                {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredAddress}
                                {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredDistrict && ` แขวง${props.clearGuaranteeMoneyReceived.guarantee.contact.registeredDistrict}`}
                                {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredCity && ` เขต${props.clearGuaranteeMoneyReceived.guarantee.contact.registeredCity}`}
                                {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredProvince && ` ${props.clearGuaranteeMoneyReceived.guarantee.contact.registeredProvince} `}
                                {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredPostalCode}{" "}
                                {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredCountry}
                              </>
                            ) : (
                              <>
                              {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredAddress}
                              {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredDistrict && ` ตำบล${props.clearGuaranteeMoneyReceived.guarantee.contact.registeredDistrict}`}
                              {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredCity && ` อำเภอ${props.clearGuaranteeMoneyReceived.guarantee.contact.registeredCity}`}
                              {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredProvince && ` จังหวัด${props.clearGuaranteeMoneyReceived.guarantee.contact.registeredProvince} `}
                              {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredPostalCode}{" "}
                              {props.clearGuaranteeMoneyReceived.guarantee.contact.registeredCountry}
                            </>
                            ) }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mt-3">
                          <div className="table-responsive fade-up">
                            <table className="table table-hover mt-2">
                              <thead className="thead-light">
                                <Translation>
                                  {t=>
                                  <tr>
                                    <th width="80">{t("guarantee_money_received:List")}</th>
                                    <th width="200">{t("guarantee_money_received:Description")}</th>
                                    <th width="80" className="text-right">
                                      {t("guarantee_money_received:Amounts")}
                                    </th>
                                  </tr>}
                                </Translation>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {props.clearGuaranteeMoneyReceived.guarantee
                                      .chartOfAccount.chartOfAccountCode +
                                      " " +
                                      props.clearGuaranteeMoneyReceived
                                        .guarantee.chartOfAccount.name}
                                  </td>
                                  <td>
                                    {
                                      props.clearGuaranteeMoneyReceived
                                        .guarantee.description
                                    }
                                  </td>
                                  <td className="text-right">
                                    {numberWithComma(
                                      props.clearGuaranteeMoneyReceived
                                        .guarantee.amount
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mt-5">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <Translation>{t=><h6>{t("guarantee_money_received:Guarantee clearing list")}</h6>}</Translation>
                          </div>
                        </div>
                        {props.clearGuaranteeMoneyReceived.guaranteeTransaction.edges.map(
                          (gr_transaction) => {
                            let text_return = "Return all";
                            let text_forfeit = "Seize all";
                            if (
                              props.clearGuaranteeMoneyReceived
                                .guaranteeTransaction.totalCount > 1
                            ) {
                              text_return = "Return";
                              text_forfeit = "Seize";
                            }
                            return (
                              <React.Fragment key={gr_transaction.node.id}>
                                {gr_transaction.node.typeOfTransaction ===
                                  "RETURN" && (
                                  <React.Fragment>
                                    <div className="row mt-3">
                                      <Translation>{t=><div className="col-1">{t(`guarantee_money_received:${text_return}`)}</div>}</Translation>
                                      <div className="col">
                                        <PayChannel
                                          total={gr_transaction.node.amount}
                                          cashTransaction={
                                            props.clearGuaranteeMoneyReceived
                                              .cashTransaction.edges
                                          }
                                          bankTransaction={
                                            props.clearGuaranteeMoneyReceived
                                              .bankAccountTransaction.edges
                                          }
                                          chequeTransaction={
                                            props.clearGuaranteeMoneyReceived
                                              .chequeTransaction.edges
                                          }
                                          paymentChannel={
                                            props.clearGuaranteeMoneyReceived.clearGuaranteeMoneyReceivedPettyCashChannel.edges
                                          }
                                          account_type="pay"
                                          clear_guarantee={true}
                                        />
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}
                                {gr_transaction.node.typeOfTransaction ===
                                  "FORFEIT" && (
                                  <React.Fragment>
                                    <div className="row mt-3">
                                      <Translation>
                                        {t=>
                                        <div className="col-1">
                                          {t(`guarantee_money_received:${text_forfeit}`)}
                                        </div>}
                                      </Translation>
                                      <Translation>
                                        {t=>
                                        <div className="col-2">
                                          {numberWithComma(
                                            gr_transaction.node.amount
                                          )}{" "+t("guarantee_money_received:Baht")}
                                        </div>}
                                      </Translation>
                                      <Translation>{t=><div className="col-1">{t("guarantee_money_received:Seize to")}</div>}</Translation>
                                      <Translation>
                                        {t=>
                                        <div className="col">
                                          {/* 4300-02 {t("guarantee_money_received:Revenue - Guarantee income")} */}
                                          {
                                            props.clearGuaranteeMoneyReceived.guaranteeTransaction.edges && this.showCodeAccount(props.clearGuaranteeMoneyReceived.guaranteeTransaction.edges)
                                          }
                                        </div>}
                                      </Translation>
                                    </div>
                                    <div className="row mt-3">
                                      <Translation>{t=><div className="col-1">{t("guarantee_money_received:Reason for seizure")}</div>}</Translation>
                                      <div className="col">
                                        {
                                          props.clearGuaranteeMoneyReceived
                                            .forfeitRemark
                                        }
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
              return <Loading/>
            }}
          />
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ClearGuaranteeMoneyReceivedView;
