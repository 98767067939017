import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import AdvanceReportTable from "./advanceReportTable";
import ExportAdvanceReport from "./server_export";
import SearchSelect from "../../../../libs/searchSelect";
import { fetchQuery } from "relay-runtime";
import _ from "lodash";
import numberWithComma from "../../../../libs/numberWithComma";
import Loading from "../../../../libs/loading";
import Swal from "sweetalert2";
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
  query advanceReportQuery(
    $search: String
    $start_date: DateTime
    $end_date: DateTime
    $first: Int
    $last: Int
    $advance_id: String
    $status: String
  ) {
    allAdvance(
      search: $search
      startDate: $start_date
      endDate: $end_date
      first: $first
      last: $last
      order: "doc_number"
      advanceId: $advance_id
      status: $status
      report: true
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          issuedDate
          docNumber
          description
          withdrawer
          amount
          status

          clearAdvance (status:"clear"){
            edges {
              node {
                id
                issuedDate
                docNumber
                returnPrice
                overPrice
                
                bankAccountTransaction {
                  edges {
                    node {
                      id
                      bankAccount {
                        id
                        bankName
                        accountNumber
                      }
                    }
                  }
                }

                cashTransaction {
                  edges {
                    node {
                      id
                    }
                  }
                }

                chequeTransaction {
                  edges {
                    node {
                      id
                      date
                      chequeNumber
                      bankAccount {
                        id
                        bankName
                        accountNumber
                      }
                    }
                  }
                }

                advancePettyCashChannel{
                  edges{
                      node{
                          id
                          price
                          date
                          setPettyCash{
                              docNumber
                              description
                              withdrawer
                          }

                      }
                    }
                  }

                clearAdvanceTransaction {
                  edges {
                    node {
                      id
                      chartOfAccount {
                        id
                        chartOfAccountCode
                      }
                      description
                      price
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
    advance_list: allAdvance(order: "doc_number",report: true, startDate: $start_date, endDate: $end_date) {
      edges {
        node {
          id
          docNumber
        }
      }
    }
    summaryAllAdvance(
      startDate: $start_date
      endDate: $end_date
      search: $search
      report: true
      advanceId: $advance_id
      status: $status
    ){
      advanceAmount
      clearTranPrice
      amountPrice
    }
  }
`;

class AdvanceReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let first_date = new Date();
    first_date.setDate(1);

    this.state.start_date = first_date;
    this.state.end_date = new Date();
    this.state.temp_start_date = first_date;
    this.state.temp_end_date = new Date();
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.status = "";
    this.state.advance_list = [];
    this.state.advance_id = "";
    this.state.temp_advance_id = "";
    this.state.status = "all";
    this.state.temp_status = "all";

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "advanceReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });

  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      advance_id: this.state.temp_advance_id,
      status: this.state.temp_status
    }, () => {
      fetchQuery(environment, query, {
        start_date: this.state.start_date,
        end_date: this.state.end_date,
      }).then((response) => {
        this.setState({ advance_list: response.advance_list.edges });
      }).catch(error => {
        Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
      });
    });

  }

  componentDidMount() {
    fetchQuery(environment, query, {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }).then((response) => {
      this.setState({ advance_list: response.advance_list.edges });
    });
  }




  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-payable"}
                  LinkText={i18next.t("DashboardPayable:Advance Cash Movement Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_payable_print",
                      }) && <ExportAdvanceReport state={this.state} />}
                    </div>
                  }
                />


                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                          minDate={this.state.temp_start_date}
                        />
                      </div>
                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Document Number")}</label>
                              <SearchSelect
                                onChange={this.handleChange}
                                value={this.state.temp_advance_id}
                                name={"temp_advance_id"}
                                optionAll={true}
                                queryObject={this.state.advance_list}
                                keyOfValue="id"
                                keyOfLabel="docNumber"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Status")}</label>
                              <select className="form-control dropdown-custom-arrow" name="temp_status"
                                value={this.state.temp_status}
                                onChange={this.handleChange}>
                                <option value="all">{i18next.t("FilterPayable:All")}</option>
                                <option value="clear">{i18next.t("unknown_receive:Cleared")}</option>
                                <option value="withdraw">{i18next.t("GuaranteeMoneyReceiveMovementReport:Pending Clearance")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <QueryRenderer
                    environment={environment}
                    query={query}
                    variables={{
                      search: this.state.search,
                      start_date: this.state.start_date,
                      end_date: this.state.end_date,
                      first: this.state.first,
                      last: this.state.last,
                      advance_id: this.state.advance_id,
                      status: this.state.status,
                    }}
                    render={({ error, props }) => {
                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover table-bordered"
                                      style={{ fontSize: 12 }}
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Order")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Date of Withdrawal")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            width={110}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Withdrawal Number")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Transaction / Requester")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Amount")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Clearance Date")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            width={120}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Clearance Number")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            width={70}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Account Number")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Transaction")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Amount")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Remaining Amount")}
                                          </th>

                                          <th
                                            colSpan={4}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("AdvanceReport:Details of Refund/ Payment")}
                                          </th>
                                        </tr>
                                        <tr className="border-top">
                                          <th className="text-center">
                                          {i18next.t("AdvanceReport:Refund")}
                                          </th>
                                          <th className="text-center">
                                          {i18next.t("AdvanceReport:Refund Method")}
                                          </th>
                                          <th className="text-center">{i18next.t("AdvanceReport:Additional Withdrawal")}</th>
                                          <th className="text-center">
                                          {i18next.t("AdvanceReport:Payment Method")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <AdvanceReportTable props={props} />
                                        <tr>
                                          <td colSpan={4} className="text-right" style={{ borderRight: 'none' }}><strong>{i18next.t("AdvanceReport:Total")}</strong></td>
                                          <td className="text-right" style={{ borderLeft: 'none' }}><strong>{numberWithComma(props.summaryAllAdvance.advanceAmount)}</strong></td>
                                          <td className="text-right" colSpan={5}><strong>{numberWithComma(props.summaryAllAdvance.clearTranPrice)}</strong></td>
                                          <td className="text-right"><strong>{numberWithComma(props.summaryAllAdvance.amountPrice)}</strong></td>
                                          <td colSpan={4} className="text-right"><strong></strong></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.allAdvance.totalCount}
                              />
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default AdvanceReport;
