/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type allJuristicObjectQueryVariables = {|
  userId?: ?string
|};
export type allJuristicObjectQueryResponse = {|
  +allJuristicObject: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +image: ?string,
        +signature: string,
        +firstName: string,
        +firstNameEn: ?string,
        +lastName: string,
        +lastNameEn: ?string,
        +phone: string,
        +email: string,
        +siteId: ?string,
        +roles: ?string,
        +project: ?string,
        +user: ?{|
          +id: string,
          +username: string,
        |},
      |}
    |}>
  |}
|};
export type allJuristicObjectQuery = {|
  variables: allJuristicObjectQueryVariables,
  response: allJuristicObjectQueryResponse,
|};
*/


/*
query allJuristicObjectQuery(
  $userId: String
) {
  allJuristicObject(userId: $userId) {
    edges {
      node {
        id
        image
        signature
        firstName
        firstNameEn
        lastName
        lastNameEn
        phone
        email
        siteId
        roles
        project
        user {
          id
          username
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "JuristicNodeConnection",
    "kind": "LinkedField",
    "name": "allJuristicObject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signature",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstNameEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastNameEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "siteId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "project",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "allJuristicObjectQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "allJuristicObjectQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cf70f2a7f84af293d2559a1c280ae8ac",
    "id": null,
    "metadata": {},
    "name": "allJuristicObjectQuery",
    "operationKind": "query",
    "text": "query allJuristicObjectQuery(\n  $userId: String\n) {\n  allJuristicObject(userId: $userId) {\n    edges {\n      node {\n        id\n        image\n        signature\n        firstName\n        firstNameEn\n        lastName\n        lastNameEn\n        phone\n        email\n        siteId\n        roles\n        project\n        user {\n          id\n          username\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8ad8dcb23132a710f34c32b3c2ba14f0';

module.exports = node;
