import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import getAccountingMenuData from "../libs/accountingMenuData";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../env/environment";
import Swal from "sweetalert2";
import BankAccountQuery from './finance/depositInterest/bankAccount';
import jwtDecode from 'jwt-decode'
import Loading from '../libs/loading';
const query = graphql`
    query accountingTopMenuNavigationQuery{
        getNotificationAccounting {
            type
            count
        }
        getNotificationAdvance {
            type
            count
        }
        getNotificationContractExpense {
            type
            count
        }
        selfProject {
            useAccount
        }
        myUser {
            juristic {
                id
            }
        }
        countNotiCollection{
            countNoti
        }
        allRegisterCollectionLawFirm {
            edges{
                node{
                    id
                    serviceContract
                    email
                    linkDowaloadFile
                    status
                    accept
                    pdpaAccept
                    pdpaAcceptMarketing
                }
        }}
    }
`;

const _ = require('lodash');

class AccountingTopMenuNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = getAccountingMenuData();
        this.state.count_notification = window.localStorage.getItem('count_notification') || 0;
        this.state.countNotificationAdvance = window.localStorage.getItem('num_due') || "0";
        this.state.useAccount = true;
        this.state.notificationInterest = 0;
        this.state.countNotiExpense = window.localStorage.getItem('countNotiExpense') || "0"
        this.state.countNotiContractExpense = window.localStorage.getItem("countNotiContractExpense") || "0";
        this.state.chkSite = ""
        this.state.chkSuperAdmin = ""
        this.state.collectLetterNoti = 0
        this.state.lawFrimAccept = false
        this.state.Loading = true
    }

    componentWillMount() {
        let chkSite = jwtDecode(localStorage.getItem("token"))
        let chkSuperAdmin = chkSite?.user_super_admin
        let chkSites = chkSite?.site?.includes("port80") || chkSite?.site?.includes("victorymanagement") ? true : false


        fetchQuery(environment, query).then(response_data => {
            let count = 0;
            let countExpense = 0;
            let countContractExpense = 0;
            let countNotiCollection = 0;
            let useAccount = true;
  
            if (response_data.getNotificationAccounting) {
                response_data.getNotificationAccounting.forEach((notification) => {
                    count += notification.count
                });
                window.localStorage.setItem('count_notification', count);
                this.setState({ count_notification: count, chkSite: chkSites, chkSuperAdmin: chkSuperAdmin })
            }
            if (response_data.getNotificationAdvance) {
                response_data.getNotificationAdvance.forEach((notificationAdvance) => {
                    if (notificationAdvance.type === "num_due") {
                        countExpense += notificationAdvance.count
                        this.setState({ countNotificationAdvance: `${notificationAdvance.count}`, chkSite: chkSites, chkSuperAdmin: chkSuperAdmin })
                        window.localStorage.setItem([notificationAdvance.type], notificationAdvance.count);
                    }
                });
            }
            if (response_data.getNotificationContractExpense) {
                response_data.getNotificationContractExpense.forEach((notification) => {
                    countExpense += notification.count
                    countContractExpense += notification.count
                })
            }
            
            // if (response_data.selfProject.useAccount) {
            // useAccount = response_data.selfProject.useAccount
            this.setState({ useAccount: response_data.selfProject.useAccount, chkSite: chkSites, chkSuperAdmin: chkSuperAdmin })
            // }

            if (response_data.countNotiCollection?.countNoti) {
                countNotiCollection = response_data?.countNotiCollection?.countNoti ?? 0
            }
            window.localStorage.setItem("countNotiContractExpense", countContractExpense);
            window.localStorage.setItem("countNotiExpense", countExpense);
            this.setState({
                countNotiExpense: `${countExpense}`,
                countNotiContractExpense: `${countContractExpense}`,
                chkSite: chkSites, chkSuperAdmin: chkSuperAdmin,
                collectLetterNoti: countNotiCollection,
            })
            if(response_data.allRegisterCollectionLawFirm?.edges){
                this.setState({
                    lawFrimAccept : response_data?.allRegisterCollectionLawFirm?.edges[0]?.node?.accept ? true : false
                })
            }
            this.fetchQueryBank()
        });
    }

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }

    fetchQueryBank = async () => {
        fetchQuery(environment, query, {}).then(async (data) => {
            if (data.myUser.juristic.id) {
                BankAccountQuery(data.myUser.juristic.id, null, null, 'during').then((data_list) => {
                    let count_noti_interest = 0
                    if (data_list.depositBank.length > 0) {
                        _.forEach(data_list.depositBank, bank => {
                            if (bank.node.bankUnit.edges.length > 0) {
                                count_noti_interest += this.getNotificationNum(bank.node.bankUnit.edges)
                            }
                        })
                    }
                    this.setState({
                        notificationInterest: count_noti_interest
                    })
                })
            }
            this.setState({loading:false})
        });
    }

    getNotificationNum = (notificationInterest) => {
        let count = 0
        if (notificationInterest && notificationInterest.length > 0) {
            _.forEach(notificationInterest, bank => {
                if (bank.node.bankNotification?.edges[0]?.node.status === true) {
                    count++
                }
            })
            return count
        } else {
            return count
        }
    }

    checkUseAccount(useAccount) {
        if (useAccount === false) {
            Swal.fire({
                html:
                    '<p style="color: #2D2D30; font-weight: 500; font-size: 48px; line-height: 30px; margin-top: 40px">เพราะเรื่องเงิน</p>' +
                    '<br><p style="color: #2D2D30; font-weight: 600; font-size: 48px; line-height: 20px;">คือเรื่องใหญ่</p>' +
                    '<br><br><p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">หมดปัญหาเก็บเงินค่าส่วนกลางไม่ได้</p>' +
                    '<p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">ตรวจสอบยาก - เงินสูญหาย - หนี้ค้างชำระเยอะ</p>' +
                    '<br><p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">ขับเคลื่อนงานนิติบุคคลด้วยระบบ AI</p>' +
                    '<p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">จัดการทุกขั้นตอนเกี่ยวกับการเงินได้อย่างมีประสิทธิภาพ</p>' +
                    '<br><br><p style="color: #1578D7; font-weight: 500; font-size: 16px; line-height: 5px;">"ขณะนี้นิติบุคคลโครงการท่าน ยังไม่ได้เปิดใช้บริการฟังก์ชันสำหรับบัญชีและการเงิน</p>' +
                    '<p style="color: #1578D7; font-weight: 500; font-size: 16px; line-height: 10px;">กรุณาติดต่อทีมงาน Silverman Support เพื่อเปิดใช้บริการฟังก์ชันนี้"</p>' +
                    '<br><a href="https://silverman.app/" style="background-color:#1578D7; color: #FFFFFF; text-decoration: none; border: none; padding: 12px 24px; border-radius: 4px;">สมัครใช้บริการ</a>' +
                    '<br><br><br><p style="color: #B3B3B3; font-weight: 300; font-size: 12px; line-height: 5px;">ติดต่อสอบถาม www.silverman.app / E-mail: hello@silverman.app</p>' +
                    '<p style="color: #B3B3B3; font-weight: 300; font-size: 12px; line-height: 5px;">Tel: 081-4426888 / Line: @Silvermanapp / Facebook: Silverman App</p>',
                showConfirmButton: false,
                showCloseButton: true,
                width: 860,
            }).then(() => {
                // Reload the Page
                window.location.reload(false);
            });
        }
    }

    render() {
        return (
            <div className="container-fluid bg-gray colorUse">
                {this.state.loading ?
                <Loading />
                :
                <div className={"row "
                    + (this.props.mini ? "mini " : "")
                    + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                    {this.props.center &&
                        <div className="col-12 mt-5 mb-5">
                            <Translation>
                                {
                                    t => <h3 className="text-center">{t('set_permission:Accounting and Finance')}</h3>
                                }
                            </Translation>
                        </div>
                    }

                    <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                        <ul onClick={() => this.checkUseAccount(this.state.useAccount)}>

                            <li>
                                {
                                    this.state.revenue.some((revenue, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: revenue.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/revenue-icon.png'}
                                            alt="revenue" className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <span>
                                                    {t('accounting:revenue')}
                                                    {this.state.count_notification != 0 &&
                                                        <span className="badge position-static" style={{ width: 24, marginLeft: 4 }} >{this.state.count_notification}</span>

                                                    }
                                                    {/* style={{marginRight:-2}} */}
                                                </span>
                                            }
                                        </Translation>
                                    </a>
                                }
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.revenue.map((revenue, index) => {
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: revenue.codename })
                                            if (revenue.codename === 'accounting_contract') {// remove if and else for cancel close site
                                                // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') && 
                                                return _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: revenue.codename }) && (
                                                    <React.Fragment key={revenue.codename}>
                                                        <Link key={index} to={revenue.path}>
                                                            <Translation>
                                                                {t => `${t('accounting:' + revenue.name)} ${ revenue.shortName ? '('+revenue.shortName+')' : '' || ''}`}
                                                            </Translation>
                                                            {(revenue.codename === "accounting_receive" && this.state.count_notification > 0) ?
                                                                <p className="float-right font-weight-bold badge text-light bg-danger" style={{right:'3px'}}>{this.state.count_notification}</p> : false}</Link>
                                                    </React.Fragment>
                                                )
                                            } else {
                                                return _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: revenue.codename }) && (
                                                    <React.Fragment key={revenue.codename}>
                                                        <Link key={index} to={revenue.path}>
                                                            <Translation>
                                                                {t => `${t('accounting:' + revenue.name)} ${ revenue.shortName ? '('+revenue.shortName+')' : '' || ''}`}
                                                            </Translation>
                                                            {(revenue.codename === "accounting_receive" && this.state.count_notification > 0) ?
                                                                <p className="float-right font-weight-bold badge text-light bg-danger" style={{right:'3px'}}>{this.state.count_notification}</p> : false}</Link>
                                                    </React.Fragment>
                                                )
                                            }
                                        }
                                        )
                                    }
                                </div>
                            </li>

                            <li>
                                {
                                    this.state.expense.some((expense, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: expense.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/expense-icon.png'}
                                            alt="expense" className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <span>
                                                    {t('accounting:expense')}
                                                    {this.state.countNotiExpense != "0" &&
                                                        <span className="badge position-static" style={{ width: 24, marginLeft: 4 }} >{this.state.countNotiExpense}</span>
                                                    }
                                                </span>
                                            }
                                        </Translation>
                                    </a>
                                }

                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.expense.map((expense, index) => {
                                            // && expense.name === "contract_expense" && localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') 
                                            if (expense.name === "contract_expense" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: expense.codename })) {
                                                return (<Link key={index} to={expense.path}>
                                                        <Translation>
                                                            {t => `${t('accounting:' + expense.name)} ${ expense.shortName ? '('+expense.shortName+')' : '' || ''}`}
                                                        </Translation>
                                                        {this.state.countNotiContractExpense !== "0" &&
                                                            <p className="float-right font-weight-bold badge text-light bg-danger" style={{right:'10px'}}>{this.state.countNotiContractExpense}</p>}
                                                        </Link>)
                                            } else {
                                                return (_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: expense.codename }) &&
                                                    <Link key={index} to={expense.path}>
                                                         <Translation>
                                                                {t => `${t('accounting:' + expense.name)} ${ expense.shortName ? '('+expense.shortName+')' : '' || ''}`}
                                                         </Translation>
                                                        {(expense.name === "advance" && this.state.countNotificationAdvance !== "0") &&
                                                            <p className="float-right font-weight-bold badge text-light bg-danger" style={{right:'10px'}}>{this.state.countNotificationAdvance}</p>}
                                                    </Link>)

                                            }
                                        })
                                    }
                                </div>
                            </li>

                            <li>
                                {
                                    this.state.contact.some((contact, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: contact.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/contact-icon.png'}
                                            alt="contact" className={this.props.mini && 'd-none'} />
                                            <Translation>
                                                {
                                                    t => <span>{t('accounting:contact')}</span>
                                                }
                                            </Translation>
                                    </a>
                                }
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.contact.map((contact, index) =>
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: contact.codename }) &&
                                            <Link key={index} to={contact.path}>
                                                <Translation>
                                                    {
                                                        t => t('accounting:' + contact.name)
                                                    }
                                                </Translation>
                                            </Link>
                                        )
                                    }
                                </div>
                            </li>

                            <li>
                                {
                                    this.state.inventory.some((inventory, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: inventory.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/product-service-icon.png'}
                                            alt="product-service"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:inventory')}</span>
                                            }
                                        </Translation>
                                    </a>
                                }
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.inventory.map((inventory, index) =>
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: inventory.codename }) &&
                                            <Link key={index} to={inventory.path}>
                                                <Translation>
                                                    {
                                                        t => t('inventory:' + inventory.name)
                                                    }
                                                </Translation>
                                            </Link>
                                        )
                                    }
                                </div>

                            </li>

                            <li>
                                {
                                    this.state.finance.some((finance, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: finance.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/finance-icon.png'}
                                            alt="finance" className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:finance')}
                                                    {
                                                        // localStorage.getItem('site_id') && 
                                                        // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=')  &&
                                                        this.state.notificationInterest != 0 &&
                                                        <span className="badge position-static" style={{ width: 24, marginLeft: 4 }} >{this.state.notificationInterest}</span>

                                                    }</span>
                                            }

                                        </Translation>
                                    </a>
                                }
                                {/* finance.codename === 'accounting_credit_card' && _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on ? */}
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.finance.map((finance, index) => {
                                            if (_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: finance.codename }) && finance.codename === 'accounting_tax') {
                                                if (localStorage.getItem('project_have_vat') === "true") {
                                                    return (
                                                        <Link key={index} to={finance.path}>
                                                            {/* <Translation>
                                                                {
                                                                    t => t('finance:' + finance.name)
                                                                }
                                                            </Translation> */}
                                                             <Translation>
                                                                {t => `${t('finance:' + finance.name)} ${ finance.shortName ? '('+finance.shortName+')' : '' || ''}`}
                                                            </Translation>
                                                        </Link>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }
                                            else if (finance.codename === 'accounting_deposit_interest' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: finance.codename })) {
                                                return (
                                                    <Link key={index} to={finance.path}>
                                                        <Translation>
                                                            {
                                                                t => <React.Fragment>{t('finance:' + finance.name)} 
                                                                    {this.state.notificationInterest > 0 ? <p className="float-right font-weight-bold badge text-light bg-danger">{this.state.notificationInterest} </p> : ''}</React.Fragment>
                                                            }
                                                        </Translation>
                                                    </Link>
                                                )
                                            }
                                            else if (finance.name === 'credit_card') {
                                                if (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on) {
                                                    return (
                                                        <Link key={index} to={finance.path}>
                                                            <Translation>
                                                                {
                                                                    t => t('finance:' + finance.name) +" "+ "(CL)"
                                                                }
                                                            </Translation>
                                                        </Link>
                                                    )
                                                }
                                                else {
                                                    return null
                                                }
                                            }
                                            else if (finance.name === 'payment_gateway') {
                                                if (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on) {
                                                    return (
                                                        <Link key={index} to={finance.path}>
                                                            <Translation>
                                                                {
                                                                    t => t('finance:' + finance.name) +" "+ "(PG)"
                                                                }
                                                            </Translation>
                                                        </Link>
                                                    )
                                                }
                                                else {
                                                    return null
                                                }
                                            }
                                            else {
                                                return (
                                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: finance.codename }) &&
                                                    <Link key={index} to={finance.path}>
                                                        {/* <Translation>
                                                            {
                                                                t => t('finance:' + finance.name)
                                                            }
                                                        </Translation> */}
                                                         <Translation>
                                                                {t => `${t('finance:' + finance.name)} ${ finance.shortName ? '('+finance.shortName+')' : '' || ''}`}
                                                        </Translation>
                                                    </Link>
                                                )
                                            }

                                        }

                                        )
                                    }
                                </div>
                            </li>

                            <li>
                                {
                                    this.state.journal.some((journal, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: journal.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/daily-journal-icon.png'}
                                            alt="daily-journal"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:daily journal')}</span>
                                            }
                                        </Translation>
                                    </a>
                                }
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.journal.map((journal, index) =>
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: journal.codename }) &&
                                            <Link key={index} to={journal.path}>
                                                <Translation>
                                                    {t => `${t('dailyjournal:' + journal.name)} ${ journal.shortName ? '('+journal.shortName+')' : '' || ''}`}
                                                </Translation>
                                            </Link>
                                        )
                                    }
                                </div>
                            </li>
                            <li>
                                {
                                    this.state.report.some((report, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: report.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/report-icon.png'}
                                            alt="daily-journal"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:report')}</span>
                                            }
                                        </Translation>
                                    </a>
                                }
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.report.map((report, index) =>
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: report.codename }) &&
                                            report.codename === 'log_repairs_room' &&  report.codename === 'vms_log' ?
                                            localStorage.getItem("site_id") === "331" && localStorage.getItem("site_id") === "168" 
                                            && 
                                            <Link key={index} to={report.path}>
                                            <Translation>
                                                {
                                                    t => t('report:' + report.name)
                                                }
                                            </Translation>
                                            </Link> :
                                                report.codename === 'report_finance' ?
                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: report.codename })
                                                // && localStorage.getItem('site_id') && localStorage.getItem('execute')
                                                //  && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') 
                                                &&
                                                <Link key={index} to={report.path}>
                                                    <Translation>
                                                        {
                                                            t => t('report:' + report.name)
                                                        }
                                                    </Translation>
                                                </Link>
                                                :
                                                localStorage.getItem("site_id") === "331" || (this.state.chkSite && this.state.chkSuperAdmin) ?
                                                    <Link key={index} to={report.path}>
                                                        <Translation>
                                                            {
                                                                t => t('report:' + report.name)
                                                            }
                                                        </Translation>

                                                    </Link>
                                                    :
                                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: report.codename }) &&
                                                    <Link key={index} to={report.path}>
                                                        <Translation>
                                                            {
                                                                t => t('report:' + report.name)
                                                            }
                                                        </Translation>

                                                    </Link>

                                        )
                                    }
                                </div>
                            </li>
                            <li>
                                {
                                    this.state.account.some((account, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: account.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/account-icon.png'}
                                            alt="account" className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:account')}</span>
                                            }
                                        </Translation>
                                    </a>
                                }
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.account.map((account, index) => {
                                            if (_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: account.codename }) && account.name === "budget"
                                                // && localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=')
                                            ) {
                                                return (<Link key={index} to={account.path}>
                                                    <Translation>
                                                        {
                                                            t => t('accounting:' + account.name)
                                                        }
                                                    </Translation>
                                                </Link>)
                                            } else if (account.name === "work sheet") {
                                                return (<Link key={index} to={account.path}>
                                                    <Translation>
                                                        {
                                                            t => t('accounting:' + account.name)
                                                        }
                                                    </Translation>
                                                </Link>)
                                            }
                                            else
                                                return _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: account.codename }) &&
                                                    // (account.name === "cash flow statement" ?
                                                    //     <Link key={index} to={account.path}>
                                                    //         <Translation>
                                                    //             {
                                                    //                 t => t('accounting:' + account.name)
                                                    //             }
                                                    //         </Translation>
                                                    //         {/* <p style={{ color: "#e56070", fontWeight: "bold", display: "inline" }}>(Beta)</p> */}
                                                    //     </Link> :

                                                    //     <Link key={index} to={account.path}>
                                                    //         <Translation>
                                                    //             {
                                                    //                 t => t('accounting:' + account.name)
                                                    //             }
                                                    //         </Translation>
                                                    //     </Link>
                                                    // )
                                                    (account.name === "balance sheet" || account.name === "summary of income expense" || account.name === "accrued revenue expenditure" ?

                                                        <Link key={index} to={account.path}>
                                                            <div className="row">
                                                                <div className="col-sm-10">
                                                                    <Translation>
                                                                        {
                                                                            t => t('accounting:' + account.name)
                                                                        }
                                                                    </Translation>
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    {
                                                                        account.name === "balance sheet" ?
                                                                            <img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/piechart.png'}
                                                                                style={{ width: '18px', margin: 'auto', display: 'block', position: 'absolute', right: '-2px' }} className={this.props.mini && 'd-none'} />
                                                                            : account.name === "summary of income expense" ?
                                                                                <>
                                                                                    <div className='d-flex'>
                                                                                        <img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/piechart.png'} style={{ width: '18px', margin: 'auto', display: 'block' }} className={this.props.mini && 'd-none'} />
                                                                                        <img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/barchart.png'} style={{ width: '18px', margin: 'auto', display: 'block' }} className={this.props.mini && 'd-none'} />
                                                                                    </div>
                                                                                </>
                                                                                : <img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/barchart.png'} style={{ width: '18px', margin: 'auto', display: 'block', position: 'absolute', right: '-5px' }} className={this.props.mini && 'd-none'} />

                                                                    }
                                                                </div>
                                                            </div>

                                                            {/* <p style={{ color: "#e56070", fontWeight: "bold", display: "inline" }}>(Beta)</p> */}
                                                        </Link>
                                                        :

                                                        <Link key={index} to={account.path}>
                                                            <Translation>
                                                                {
                                                                    t => t('accounting:' + account.name)
                                                                }
                                                            </Translation>
                                                        </Link>
                                                    )
                                        })
                                    }
                                </div>
                            </li>
                            <li>
                                {
                                    // this.state.letter.some((letter, index) => _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: letter.codename })) &&
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/letter-icon.png'}
                                            alt="letter"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <span>{t("MenuName:Book of inquiries and Credit note")}</span>
                                            }
                                        </Translation>
                                        {
                                            this.state.collectLetterNoti !== "" && this.state.collectLetterNoti !== 0 ?
                                            <span className="badge position-static" style={{ marginTop: -18 }}>{this.state.collectLetterNoti}</span>
                                            :
                                            ""
                                        }
                                    </a>
                                }
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    {
                                        this.state.letter.map((letter, index) =>
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: letter.codename }) &&
                                            <Link key={index} to={letter.path}>
                                                <Translation>
                                                    {t => `${t('MenuName:' + letter.name)} ${ letter.shortName ? '('+letter.shortName+')' : '' || ''}`}
                                                </Translation>
                                                {
                                                    letter.codename === "accounting_collectionletters" && this.state.collectLetterNoti !== "" && this.state.collectLetterNoti !== 0 ?
                                                    <p className="float-right font-weight-bold badge text-light bg-danger">{this.state.collectLetterNoti}</p>
                                                    :
                                                    <></>
                                                }
                                            </Link>

                                        )
                                    }
                                </div>
                            </li>                            
                            <li>

                                {
                               // (localStorage.getItem('site_id') && (localStorage.getItem('site_id') === '331') || (localStorage.getItem('site_id') === '163'))  && 
                                    <a className="dropdown-toggle" href="/" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/crf-icon.png'}
                                            alt="letter"
                                            className={this.props.mini && 'd-none'} />
                                        <span><img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/house-condo-lawyer.png'} 
                                        style={{margin:'0',float:'right',width:'60px'}}/> 
                                        <Translation>
                                            {
                                                t => t('collectionLawFirm:Debt Collection Attorney')
                                            }
                                        </Translation>    
                                    </span>
                                    </a>
                                }
                                {
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <Link to={this.state.lawFrimAccept ?  '/accounting/collection-law-firm/list/law' : "/accounting/collection-law-firm/regis"}>
                                        <Translation>
                                            {
                                                t => t('collectionLawFirm:Debt Collection Attorney') + " " + "(CLF)"
                                            }
                                        </Translation>    
                                    </Link>
                                    {/* <Link to={"/accounting/collection-law-firm/report/all"}>รายงานสรุปค่าใช้จ่ายออกเอกสารติดตามทวงถามโดยทนายความ</Link>
                                    <Link to={"/accounting/collection-law-firm/setting"}> ตั้งค่าติดตามทวงถามโดยทนายความ</Link> */}
                                    <Link to={this.state.lawFrimAccept ?  '/accounting/collection-law-firm/report/all' : "/accounting/collection-law-firm/regis"}>   
                                        <Translation>
                                            {
                                                t => t('collectionLawFirm:Debt Collection Attorney Summary Report')
                                            }
                                        </Translation>       
                                    </Link>
                                    <Link to={this.state.lawFrimAccept ?  '/accounting/collection-law-firm/setting' : "/accounting/collection-law-firm/regis"}>
                                    <Translation>
                                            {
                                                t => t('collectionLawFirm:Setting Debt Collection Attorney')
                                            }
                                        </Translation>           
                                    </Link>
                                </div>
                                }
                            </li>




                        </ul>
                    </div>
                </div>}
            </div>
        );
    }
}

export default AccountingTopMenuNavigation;
