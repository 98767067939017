import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import "../styles/commonArea.scss";
import ComponentPagination from "../../../libs/componentPagination";
import _ from "lodash";
import { format } from "date-fns";
import apii from './services'
import i18n from "i18next";
import Swal from "sweetalert2";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import upload from "../../../libs/upload";
import CommonAreaCreateStep1 from "./create/commonAreaCreateStep1";
import CommonAreaCreateStep2 from "./create/commonAreaCreateStep2";
import CommonAreaCreateStep3 from "./create/commonAreaCreateStep3";
import { Translation } from "react-i18next";
import Loading from "../../../libs/loading";
import i18next from "i18next";

const allDay = new Date().setHours(0, 0, 0, 0);
const endDay = new Date().setHours(23, 59, 59);

class EditNewFacility extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      //path 1
      status: "open",
      name: "",
      name_en: "",
      location: "",
      location_en: "",
      size: "",
      max_people: "",
      remark: "",
      //path2
      equipment_status: false,
      equipment: [],
      //path3
      rule_status: false,
      rule: [],
      public_private: "private", //public
      rule_pdf: "",
      //path4
      openOnEveryday: false,
      openOnEverydaySt: "",
      openOnEverydayEn: "",
      openonEveryday24: false,
      every_day_all_day: {
        status: false,
      },
      open_status: "set_once",
      holiday: [],
      holiday_status: "false",
      //path 5
      round: [],
      right_per_round: "",
      right_per_round_unit: "hours", //time round
      max_right: "3",
      people_per_right: "1",
      reservation_form: "default",
      advance_payment_value: "1",
      advance_payment_unit: "minute",
      price: 0,
      //path 6
      limit_time_reservation: {
        start_reservation_day: "",
        end_reservation_day: "",
        start_reservation_time: "",
        end_reservation_time: "",
      },
      limit_time_status: "true",
      redirectToList: false,
      image_cover: "",
      image_cover_status: "old",
      facility_image: [],
      change_image_upload: false,
      open_check: [false, false, false, false, false, false, false],
      button_load: false,

      setDateToOpen: [
        { label: "Moday", startTime: "08:00:00", endTime: "22:00:00", setOpen24: false },
        { label: "Tuesday", startTime: "08:00:00", endTime: "22:00:00", setOpen24: false },
        { label: "Wesnesday", startTime: "08:00:00", endTime: "22:00:00", setOpen24: false },
        { label: "Thurday", startTime: "08:00:00", endTime: "22:00:00", setOpen24: false },
        { label: "Friday", startTime: "08:00:00", endTime: "22:00:00", setOpen24: false },
        { label: "Saturday", startTime: null, endTime: null, setOpen24: false },
        { label: "Sunday", startTime: null, endTime: null, setOpen24: false },
      ],
      step1: true,
      step2: false,
      step3: false,

      // Step2 Path1
      typeTobooking: true,
      timeToBooking: 15,
      typeTobookingCustom: false,
      timeToBookingCustomList: [],
      timeToBreck: false,
      timeToBreckStartTime: "11:00:00",
      timeToBreckEndTime: "12:00:00",

      // Step2 Path2
      allowBookingInTime: false,
      allowBookingStartTime: "",
      allowBookingEndTime: "",

      // Step2 Path3
      timeToPreLocation: 'none',

      // Step2 Path4
      settingLimitTime: true,
      settingLimitMinTime: "30",
      settingLimitMaxTime: "60",

      // Step2 Path5
      allowLimitPeopleToUseChk: true,
      allowLimitPeopleToUse: "2",

      // Step2 Path6
      scoreType: "per_booking",
      pointValue: 2, // ไม่คิดแต้ม 0
      onChageScore: false,

      loading: true


    };
    this.onClickNext = this.onClickNext.bind(this)
    this.onClickPrev = this.onClickPrev.bind(this)
  }

  handleOpenNew = (name, value, date) => {
    this.setState(
      {
        new_open: {
          ...this.state.new_open,
          [name]: value,
        },
      },
      () => {
        if (date) {
          this.handleCheck24h(date, this.state.new_open[`${date}_24h`]);
        }
      }
    );
  };

  handleCheck24h = (name, status) => {
    if (status) {
      this.setState({
        new_open: {
          ...this.state.new_open,
          [`${name}_start`]: allDay,
          [`${name}_end`]: endDay,
        },
      });
    } else {
      this.setState({
        new_open: {
          ...this.state.new_open,
          [`${name}_start`]: "",
          [`${name}_end`]: "",
        },
      });
    }
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      let id = this.props.match.params.id
      apii.getOneFacility(id).then((res) => {
        let rule = res.facility_rule?.filter((ex) => ex.rule_description !== "pdf files")
        let rule_pdf = res.facility_rule?.filter((ex) => ex.rule_description === "pdf files")
        rule_pdf = rule_pdf?.map((itemFile) => (
          { 
          ...itemFile, name: res.facility_rule[0]?.pdf_path.split("booking/rule_pdf/")[1] ? res.facility_rule[0]?.pdf_path.split("booking/rule_pdf/")[1] : ''
        }
        ))
        this.setState({
          status: res.facility.status,

          name: res.facility.name,
          name_en: res.facility.name_en,
          location: res.facility.location,
          location_en: res.facility.location_en,
          size: res.facility.size,
          max_people: res.facility.max_people,
          remark: res.facility.remark,

          equipment_status: res.facility_equipment?.length > 0 ? true : false,
          equipment: res.facility_equipment?.length > 0 ? res.facility_equipment : [],

          public_private: res.facility.public_private,
          rule: res.facility_rule?.length > 0 ? rule : [],
          rule_pdf: rule_pdf?.length > 0 ? rule_pdf[0] : "",

          openOnEveryday: res.facility_open["every_day"],
          openOnEverydaySt: res.facility_open["monday_start"],
          openOnEverydayEn: res.facility_open["monday_end"],
          openonEveryday24: res.facility_open["monday_start"] === "00:00:00" && res.facility_open["monday_end"] === "23:59:59",


          setDateToOpen: [
            { label: "Monday", startTime: res.facility_open["monday_start"], endTime: res.facility_open["monday_end"], setOpen24: res.facility_open["monday_start"] === "00:00:00" && res.facility_open["monday_end"] === "23:59:59" },
            { label: "Tuesday", startTime: res.facility_open["tuesday_start"], endTime: res.facility_open["tuesday_end"], setOpen24: res.facility_open["tuesday_start"] === "00:00:00" && res.facility_open["tuesday_end"] === "23:59:59" },
            { label: "Wesnesday", startTime: res.facility_open["wednesday_start"], endTime: res.facility_open["wednesday_end"], setOpen24: res.facility_open["wednesday_start"] === "00:00:00" && res.facility_open["wednesday_end"] === "23:59:59" },
            { label: "Thurday", startTime: res.facility_open["thursday_start"], endTime: res.facility_open["thursday_end"], setOpen24: res.facility_open["thursday_start"] === "00:00:00" && res.facility_open["thursday_end"] === "23:59:59" },
            { label: "Friday", startTime: res.facility_open["friday_start"], endTime: res.facility_open["friday_end"], setOpen24: res.facility_open["friday_start"] === "00:00:00" && res.facility_open["friday_end"] === "23:59:59" },
            { label: "Saturday", startTime: res.facility_open["saturday_start"], endTime: res.facility_open["saturday_end"], setOpen24: res.facility_open["saturday_start"] === "00:00:00" && res.facility_open["saturday_end"] === "23:59:59" },
            { label: "Sunday", startTime: res.facility_open["sunday_start"], endTime: res.facility_open["sunday_end"], setOpen24: res.facility_open["sunday_start"] === "00:00:00" && res.facility_open["sunday_end"] === "23:59:59" },
          ],

          holiday_status: res?.facility_holiday?.length > 0 ? true : false,
          holiday: res?.facility_holiday?.length > 0 ? res?.facility_holiday : [],

          limit_time_reservation: {
            start_reservation_day: res.limit_time_reservation?.start_reservation_day ? new Date(res.limit_time_reservation?.start_reservation_day) : "",
            end_reservation_day: res.limit_time_reservation?.end_reservation_day ? new Date(res.limit_time_reservation?.end_reservation_day) : "",
            start_reservation_time: res.limit_time_reservation?.start_reservation_time ? new Date(`2023-01-01T${res.limit_time_reservation?.start_reservation_time}`) : "",
            end_reservation_time: res.limit_time_reservation?.end_reservation_time ? new Date(`2023-01-01T${res.limit_time_reservation?.end_reservation_time}`) : "",
          },
          limit_time_status: res.limit_time_reservation?.start_reservation_day ? "false" : "true",

          //Step2
          typeTobooking: res.facility.reservation_form === "default" ? true : false,
          timeToBooking: res.facility.slot_time,
          timeToBookingCustomList: res.facility_round ? res.facility_round : [],

          timeToBreck: res.facility.start_break_time ? true : false,
          timeToBreckStartTime: res.facility.start_break_time,
          timeToBreckEndTime: res.facility.end_break_time,

          allowBookingInTime: res.facility.start_booking_time ? true : false,
          allowBookingStartTime: res.facility.start_booking_time,
          allowBookingEndTime: res.facility.end_booking_time,

          timeToPreLocation: res.facility.prepare_time,

          settingLimitTime: res.facility.min_booking_time ? true : false,
          settingLimitMinTime: res.facility.min_booking_time,
          settingLimitMaxTime: res.facility.max_booking_time,

          allowLimitPeopleToUseChk: res.facility.people_per_booking ? true : false,
          allowLimitPeopleToUse: res.facility.people_per_booking,

          scoreType: res.facility.score_type,
          pointValue: res.facility.score_value,

          //Step3
          image_cover: res.facility.image_cover,
          facility_image: res.facility_image?.length > 0 ? res.facility_image : [],

          loading: false
        })
      })
    }


  }
  handleInputImage = (e) => {
    let value = e.target.value;
    if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === "image") {
      if (e.target.name === "image_upload") {
        this.setState({
          image_cover: e.currentTarget.files[0],
          change_image_upload: true,
          image_cover_status: "new",
        });
      } else {
        let idMax = _.maxBy(this.state.facility_image, ex => ex.id)
        this.setState({
          facility_image: [
            ...this.state.facility_image,
            {
              id: idMax ? (parseInt(idMax.id) + 1) : 0,
              facility_image: e.currentTarget.files[0],
              status_image: "new",
            },
          ],
        });

      }
    } else {
      Swal.fire(i18next.t("Allaction:Invalid file type"), i18next.t("Allaction:Please check again"), "warning");
    }
  };

  onDelete = (index) => {
    this.state.facility_image[index].status_image = "delete";
    this.state.facility_image[index].facility_image = "";
    this.setState({ facility_image: this.state.facility_image });
  };

  handleLimitTime = (name, date) => {
    this.setState({
      limit_time_reservation: {
        ...this.state.limit_time_reservation,
        [name]: date,
      },
    });
  };

  handleOpen = (e) => {
    const { name, value } = e.target;
    if (name === "open_status") {
      if (value === "set_once") {
        this.setDefaultOpen();
      } else if (value === "custom") {
        this.setState({
          new_open: {
            ...this.state.new_open,
            every_start: "",
            every_end: "",
            every_24h: false,
          },
        });
        document.getElementById("every_24h").checked = false;
      }
    }
  };



  handleChange = (e, index) => {
    const { name, value } = e.target;
    if ("right_per_round_unit" === name) {
      if (value === "round") {
        document.getElementById("reservation_form3").checked = true;
        this.setState({ reservation_form: "round" });
      }
    }
    if (name === "equipment" || name === "unit_items") {
      let equipment_list = this.state.equipment;
      equipment_list[index][name] = value;
      this.setState({ equipment: equipment_list });
    } else if (name === "rule_description") {
      let rule_list = this.state.rule;
      rule_list[index][name] = value;
      this.setState({ rule: rule_list });
    } else if (name === "holiday_status") {
      this.setState({ holiday_status: !this.state.holiday_status });
    } else if (name === "rule_pdf") {
      this.setState({ rule_pdf: e.currentTarget.files[0], rule_status: true });
    } else if (name === "equipment_status") {
      this.setState({
        equipment_status: e.target.checked,
        equipment: [{ equipment: "", unit_items: "" }]
      })
    } else if (name === "public_private") {
      this.setState({ public_private: e.target.checked ? "public" : "private" })
    } else if (name === "openOnEveryday") {
      this.setState({ openOnEveryday: e.target.checked })
    }

    else this.setState({ [name]: value });
  };

  addEquipment = (status, index_equipment) => {
    let equipment_list = this.state.equipment;

    if (status === "add") {
      equipment_list.push({ equipment: "", unit_items: "" });
    } else {
      var evens = _.remove(equipment_list, function (n, index) {
        return index !== index_equipment;
      });
      equipment_list = evens;
    }
    this.setState({ equipment: equipment_list });
  };

  addRule = () => {
    let rule_list = this.state.rule;
    rule_list.push({
      rule_no: this.state.rule.length + 1,
      rule_description: "",
    });
    this.setState({ rule: rule_list });
  };

  subRule = (status) => {
    var evens = _.remove(this.state.rule, function (n, index) {
      return index !== status;
    });
    this.setState({ rule: evens });
  };

  delePDF = (id) => {
    var evens = _.remove(this.state.rule_pdf, function (n, index) {
      return index !== id;
    });
    this.setState({ rule_pdf: evens });
  }

  addHoliday = (date, status) => {
    let holiday_list = this.state.holiday;
    if (status === "add") {
      holiday_list.push({
        holidays: date,
        desciption: "หยุดเอง",
      });
      this.setState({ holiday: holiday_list });
    } else {
      var evens = _.remove(this.state.holiday, function (n, index) {
        return index !== status;
      });
      holiday_list = evens;
    }
    this.setState({ holiday: holiday_list });
  };

  addRound = (start, end) => {
    let round_list = this.state.timeToBookingCustomList;
    round_list.push({
      start_round_time: format(new Date(start), "HH:mm"),
      end_round_time: format(new Date(end), "HH:mm"),
    });
    this.setState({ timeToBookingCustomList: round_list });
  };

  subRound = (status) => {
    let round_list = this.state.rotimeToBookingCustomListund;
    var evens = _.remove(this.state.timeToBookingCustomList, function (n, index) {
      return index !== status;
    });
    round_list = evens;
    this.setState({ timeToBookingCustomList: round_list });
  };

  sendApi = () => { };
  callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload("/booking/" + path, file);
      resolve(res);
    });
  };

  hundleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ button_load: true }, async () => {
      let {

        // Step1
        name,
        name_en,
        location,
        location_en,
        size,
        max_people,
        remark,

        equipment_status,
        equipment,

        rule_status,
        rule,
        rule_pdf,
        public_private,

        openOnEveryday,
        openOnEverydaySt,
        openOnEverydayEn,
        openonEveryday24,
        setDateToOpen,

        holiday_status,
        holiday,

        round,
        right_per_round,
        right_per_round_unit,
        max_right,
        people_per_right,
        reservation_form,
        advance_payment_value,
        advance_payment_unit,
        price,

        limit_time_status,
        limit_time_reservation,

        // Step2
        typeTobooking,
        timeToBooking,
        typeTobookingCustom,
        timeToBookingCustomList,
        timeToBreck,
        timeToBreckStartTime,
        timeToBreckEndTime,

        allowBookingInTime,
        allowBookingStartTime,
        allowBookingEndTime,
        timeToPreLocation,

        settingLimitTime,
        settingLimitMinTime,
        settingLimitMaxTime,

        allowLimitPeopleToUseChk,
        allowLimitPeopleToUse,

        scoreType,
        pointValue,
        facility_image,
        image_cover,
        status,
        image_cover_status,
        onChageScore
      } = this.state;

      let facility_open = {
        "monday_start": openOnEveryday ? openOnEverydaySt : setDateToOpen[0].setOpen24 ? "00:00:00" : setDateToOpen[0].startTime,
        "monday_end": openOnEveryday ? openOnEverydayEn : setDateToOpen[0].setOpen24 ? "23:59:59" : setDateToOpen[0].endTime,

        "tuesday_start": openOnEveryday ? openOnEverydaySt : setDateToOpen[1].setOpen24 ? "00:00:00" : setDateToOpen[1].startTime,
        "tuesday_end": openOnEveryday ? openOnEverydayEn : setDateToOpen[1].setOpen24 ? "23:59:59" : setDateToOpen[1].endTime,

        "wednesday_start": openOnEveryday ? openOnEverydaySt : setDateToOpen[2].setOpen24 ? "00:00:00" : setDateToOpen[2].startTime,
        "wednesday_end": openOnEveryday ? openOnEverydayEn : setDateToOpen[2].setOpen24 ? "23:59:59" : setDateToOpen[2].endTime,

        "thursday_start": openOnEveryday ? openOnEverydaySt : setDateToOpen[3].setOpen24 ? "00:00:00" : setDateToOpen[3].startTime,
        "thursday_end": openOnEveryday ? openOnEverydayEn : setDateToOpen[3].setOpen24 ? "23:59:59" : setDateToOpen[3].endTime,

        "friday_start": openOnEveryday ? openOnEverydaySt : setDateToOpen[4].setOpen24 ? "00:00:00" : setDateToOpen[4].startTime,
        "friday_end": openOnEveryday ? openOnEverydayEn : setDateToOpen[4].setOpen24 ? "23:59:59" : setDateToOpen[4].endTime,

        "saturday_start": openOnEveryday ? openOnEverydaySt : setDateToOpen[5].setOpen24 ? "00:00:00" : setDateToOpen[5].startTime,
        "saturday_end": openOnEveryday ? openOnEverydayEn : setDateToOpen[5].setOpen24 ? "23:59:59" : setDateToOpen[5].endTime,

        "sunday_start": openOnEveryday ? openOnEverydaySt : setDateToOpen[6].setOpen24 ? "00:00:00" : setDateToOpen[6].startTime,
        "sunday_end": openOnEveryday ? openOnEverydayEn : setDateToOpen[6].setOpen24 ? "23:59:59" : setDateToOpen[6].endTime,

        "every_day": openOnEveryday ? "True" : "False"
      }

      var bodyFormData = new FormData();
      bodyFormData.append('name', name);
      bodyFormData.append('name_en', name_en);
      bodyFormData.append('location', location);
      bodyFormData.append('location_en', location_en);
      size && bodyFormData.append('size', size);
      bodyFormData.append('max_people', max_people);
      remark && bodyFormData.append('remark', remark);
      bodyFormData.append('reservation_form', typeTobooking ? "default" : "round");
      bodyFormData.append('price', 0);
      bodyFormData.append('facility_open', JSON.stringify(facility_open));

      equipment.length > 0 && equipment.map((equipmentsItem) => {
        bodyFormData.append('facility_equipment', JSON.stringify(equipmentsItem))
      })

      //จำกัดช่วงเวลาในการเปิดจองพื้นที่
      if (limit_time_status == "false") {
        let limitTime = {
          start_reservation_day: format(new Date(limit_time_reservation.start_reservation_day), "YYYY-MM-DD"),
          end_reservation_day: format(new Date(limit_time_reservation.end_reservation_day), "YYYY-MM-DD"),
          start_reservation_time: format(new Date(limit_time_reservation.start_reservation_time), "HH:mm:ss"),
          end_reservation_time: format(new Date(limit_time_reservation.end_reservation_time), "HH:mm:ss"),
        }
        bodyFormData.append('limit_time_reservation', JSON.stringify(limitTime));
      }

      // ประเภทการจอง แบบกำหนดเอง
      if (typeTobooking == false) {
        for (let index = 0; index < timeToBookingCustomList.length; index++) {
          let timeRound = {
            "start_round_time": timeToBookingCustomList[index].start_round_time + ":00",
            "end_round_time": timeToBookingCustomList[index].end_round_time + ":00"
          }
          bodyFormData.append("facility_round", JSON.stringify(timeRound));
        }
      }

      // ประเภทการจอง เพิ่มเวลาพักเบรค
      bodyFormData.append('start_break_time', timeToBreck ? timeToBreckStartTime : null);
      bodyFormData.append('end_break_time', timeToBreck ? timeToBreckEndTime : null);

      if (holiday_status) {
        for (let index = 0; index < holiday.length; index++) {
          let holidays = {
            desciption: holiday[index].desciption,
            holidays: format(new Date(holiday[index].holidays), "YYYY-MM-DD")
          }
          bodyFormData.append('facility_holiday', JSON.stringify(holidays));
        }
      }

      bodyFormData.append('public_private', public_private);


      // ข้อกำหนดการใช้พื้นที่
        rule && rule.map((items_rules) => {
          bodyFormData.append('facility_rule', JSON.stringify(items_rules))
        })
    
      // กฏระเบียบการใช้พื้นที่ส่วนกลาง PDF
      if(rule_pdf){
        if (rule_status == true && rule_pdf) {
          await this.callUpload("rule_pdf", rule_pdf).then((data) => {
            let pdf_rule = { "rule_no": 0, "rule_description": "pdf files", "rule_pdf": data.key.replace("silverman-storage/private/", "") }
            bodyFormData.append('facility_rule', JSON.stringify(pdf_rule))
          });
        } else if (rule_status == false && rule_pdf && rule_pdf.pdf_path) {
          let updateFile = {
            facility: rule_pdf.facility ? rule_pdf.facility : null,
            id: rule_pdf.id ? rule_pdf.id : null ,
            name: rule_pdf.name ? rule_pdf.name : null,
            pdf_path: rule_pdf.pdf_path ? rule_pdf.pdf_path : null,
            rule_description: rule_pdf.rule_description ? rule_pdf.rule_description : null,
            rule_description_en: rule_pdf.rule_description_en ? rule_pdf.rule_description_en : null,
            rule_no: 0,
            rule_pdf: rule_pdf.pdf_path ? rule_pdf.pdf_path : null,
            site: rule_pdf.site
          }
          bodyFormData.append('facility_rule', JSON.stringify(updateFile))
        }
      }

      bodyFormData.append('status', status);
      // ประเภทการจอง ค่าปกติของระบบ
      bodyFormData.append('slot_time', timeToBooking);

      // ระยะเวลาที่ต้องใช้ในการเตรียมสถานที่
      bodyFormData.append('prepare_time', timeToPreLocation > 0 ? timeToPreLocation : null);
      // ตั้งค่าเวลาขั้นต่ำ - สูงสุด ที่ให้ใช้บริการ
      bodyFormData.append('max_booking_time', settingLimitTime ? settingLimitMaxTime : null);
      bodyFormData.append('min_booking_time', settingLimitTime ? settingLimitMinTime : null);
      // จำนวนผู้ใช้บริการที่อนุญาตให้จอง
      bodyFormData.append('people_per_booking', allowLimitPeopleToUseChk ? allowLimitPeopleToUse : null);

      // อนุญาตให้จองเฉพาะเวลาทำการ
      bodyFormData.append('start_booking_time', allowBookingInTime ? allowBookingStartTime : null);
      bodyFormData.append('end_booking_time', allowBookingInTime ? allowBookingEndTime : null);

      // การจัดการแต้ม
      bodyFormData.append('score_type', scoreType);
      bodyFormData.append('score_value', scoreType === "free" ? 0 : pointValue);


      if (image_cover && image_cover_status === "new") {
        await this.callUpload("image_cover", image_cover).then((data) => {
          bodyFormData.append("image_cover", data.key.replace("silverman-storage/private/", ""));
        });
      }
      // รูปอัลบัม

      if (facility_image.length > 0) {
        let findStatusDelete = facility_image.filter((item) => item.status_image === "delete")
        let findStatusNoDelete = facility_image.filter((item) => item.status_image !== "delete")

        if (findStatusDelete.length) {
          // กรณีมีการ ลบ รูปออก
          for (let index = 0; index < findStatusDelete.length; index++) {
            let ele = _.get(findStatusDelete , `[${index}]`)
            _.set(ele, `facility_image`, null)
      
            bodyFormData.append("facility_image", JSON.stringify(ele));
          }
        } 
        if(findStatusNoDelete) {
          // มีรูป ก็บันทึก ปกติ
          for (const image of findStatusNoDelete) {
            await upload("/booking/facility_image", image.facility_image).then((res) => {
              let imageList = {
                facility_image: res.key.replace("silverman-storage/private/", ""),
                id: image.id,
                status_image: image.status_image
              }
              bodyFormData.append("facility_image", JSON.stringify(imageList));
            });
          }
        }

      }

      apii.editBookingFacility(bodyFormData, this.props.match.params.id).then((res) => {
        if (res?.data?.message === "edit_successful") {
          Swal.fire(i18n.t("Allaction:Saved Successfully"), i18n.t("Allaction:Saved Successfully"), 'success').then(() => {
            this.props.history.push("/contact/global-area-singha/booking/common-area/all")
          })
        }
      }).catch((err) => {

      })
    })
  };

  handleImage = (image) => {
    this.setState({
      image_cover: image.image_upload,
      facility_image: image.image,
    });
  };

  //Function New
  HandleOnChange = (e) => {
    let { checked, name, value } = e.target
    if (name === "timeToBreck" || name === "allowBookingInTime") {
      this.setState({ [name]: checked })
    } else if (name === "typeTobooking" || name === "settingLimitTime" || name === "allowLimitPeopleToUseChk") {
      this.setState({ [name]: value === "true" ? true : false })
    } else if (name === 'scoreType') {
      this.setState({ [name]: value, pointValue: 2 })
    } else {
      this.setState({ [name]: value })
    }
  }

  onClickNext() {
    if (this.state.step1) {
      this.setState({ step1: false, step2: true })
    } else if (this.state.step2) {
      this.setState({ step2: false, step3: true })
    }
  }

  onClickPrev() {
    if (this.state.step2) {
      this.setState({ step2: false, step1: true })
    } else if (this.state.step3) {
      this.setState({ step3: false, step2: true })
    }
  }

  onChangeTime = (type, name, value, date) => {
    let hour = parseInt(new Date(value).getHours()) > 9 ? new Date(value).getHours().toString() : "0" + new Date(value).getHours()
    let minu = parseInt(new Date(value).getMinutes()) > 9 ? new Date(value).getMinutes().toString() : "0" + new Date(value).getMinutes()
    let seco = parseInt(new Date(value).getSeconds()) > 9 ? new Date(value).getSeconds().toString() : "0" + new Date(value).getSeconds()
    let findName = _.findIndex(this.state.setDateToOpen, ex => ex.label === name)
    if (findName >= 0) {
      let clone = this.state.setDateToOpen
      if (type === "startTime") {
        _.set(clone, `[${findName}].startTime`, hour + ":" + minu + ":" + seco)
      } else if (type === "endTime") {
        _.set(clone, `[${findName}].endTime`, hour + ":" + minu + ":" + seco)
      } else if (type === "deleted") {
        _.set(clone, `[${findName}].startTime`, null)
        _.set(clone, `[${findName}].endTime`, null)
        _.set(clone, `[${findName}].setOpen24`, false)
      } else if (type === "add") {
        _.set(clone, `[${findName}].startTime`, "08:00:00")
        _.set(clone, `[${findName}].endTime`, "22:00:00")
        _.set(clone, `[${findName}].setOpen24`, false)
      } else {
        _.set(clone, `[${findName}].startTime`, "00:00:00")
        _.set(clone, `[${findName}].endTime`, "23:59:59")
        _.set(clone, `[${findName}].setOpen24`, value)
      }
      this.setState({ setDateToOpen: clone, });
    } else if (type === "openonEveryday24") {
      this.setState({ [name]: value, openOnEverydaySt: "00:00:00", openOnEverydayEn: "23:59:59" })
    } else {
      this.setState({ [name]: hour + ":" + minu + ":" + seco })
    }


  }

  onClickSelectBtn = (name, value) => {
    this.setState({ [name]: value })
  }

  onSubmitChk = (e) => {
    e.preventDefault()

    if (this.state.step1 && this.state.name && this.state.name_en && this.state.location && this.state.location_en && this.state.max_people) {
      this.onClickNext()
    } else if (this.state.step2) {
      if (this.state.reservation_form === "round" && this.state.timeToBookingCustomList?.length > 0) {
        return
      } else {
        this.onClickNext()
      }
    } else if (this.state.step3) {
      this.hundleSubmit(e)
    }
  }



  render() {
    if (this.state.redirectToList) {
      return <Redirect to={"/contact/global-area-singha/booking/common-area/all"} />;
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <Translation>
            {
              t =>
                this.state.loading == false ?
                  <div id="booking-form ">
                    <div className="row justify-content-between p-4">
                      <div className="col">
                        <h3>
                          <Link to="/contact/global-area-singha/booking/common-area/all">
                            <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"} alt="back" className="back" style={{ width: 10, height: 20 }} />
                          </Link>
                          {/* <Translation>
                      {(t) => ( */}
                          <span className="header">{t("newBookingSingha_Booking:Edit Facility")}</span>
                          {/* )}
                    </Translation> */}
                        </h3>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="col-8">
                        <form id="form-transfer-owner"
                          onSubmit={this.onSubmitChk}
                        >
                          {
                            this.state.step1 ?
                              <CommonAreaCreateStep1
                                create
                                state={this.state}
                                handleChange={this.handleChange}
                                addHoliday={this.addHoliday}
                                handleOpen={this.handleOpen}
                                handleOpenNew={this.handleOpenNew}
                                handleCheck24h={this.handleCheck24h}
                                addRule={this.addRule}
                                subRule={this.subRule}
                                delePDF={this.delePDF}
                                addEquipment={this.addEquipment}
                                handleLimitTime={this.handleLimitTime}
                                onClickNext={this.onClickNext}
                                onClickPrev={this.onClickPrev}
                                onChangeTime={this.onChangeTime}
                              />
                              :
                              this.state.step2 ?
                                <CommonAreaCreateStep2
                                  state={this.state}
                                  onClickSelectBtn={this.onClickSelectBtn}
                                  onChangeTime={this.onChangeTime}
                                  HandleOnChange={this.HandleOnChange}
                                  onClickNext={this.onClickNext}
                                  onClickPrev={this.onClickPrev}
                                  addRound={this.addRound}
                                  subRound={this.subRound}
                                />
                                :
                                <CommonAreaCreateStep3
                                  state={this.state}
                                  handleChange={this.handleChange}
                                  onClickNext={this.onClickNext}
                                  onClickPrev={this.onClickPrev}
                                  handleInputImage={this.handleInputImage}
                                  handleImage={this.handleImage}
                                  onDelete={this.onDelete}
                                />
                          }
                        </form>
                      </div>
                    </div>
                  </div>
                  :
                  <Loading />
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default EditNewFacility;
