import {commitMutation} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const mutation = graphql`
    mutation createRegulationMutation ($input: CreateRegulationInput!) {
    createRegulation (input: $input) {
        ok
        message
    }
}
`

export default function createMutation(variables, callback, error_callback) {
    commitMutation(
        environment,
        {
          mutation,
          variables,
          onCompleted: (response) => {

            callback(response.createRegulation.ok)
          },
          onError: (err) => {
            console.log(err);
              error_callback(err);
          },
        },
      )
}