import React from 'react'
import { Component } from 'react';
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import SelectResident from './selectResident';
import "../../developer.scss";
import unitStatusAPI from '../unitStatusAPI';

class TransferOfOwnershipSelect extends Component {

    constructor(props){
        super(props);
        this.state ={
            residentialList : [],
            loading : false,
            search : '',
            token : localStorage.getItem("token")
        }
        this.updateContactList = this.updateContactList.bind(this);
        this.fetchQuery = this.fetchQuery.bind(this);
    }

    updateContactList(contactList) {
        this.props.updateContactList(contactList)
    }

    componentDidMount(){
        this.fetchQuery('')
    }

    fetchQuery(search){
        this.setState({search:search})
        this.setState({loading:true})
        unitStatusAPI.getSelectResident(search,this.state.token)
            .then((response) => {
                if(response.data.residential_list !== this.state.residentialList){
                    this.setState({
                        residentialList : response.data.residential_list
                    },() => this.setState({loading:false}))
                }
            })
    }
    

    render(){

        return(
            <Translation>
                {t =>
                    <div className="container-fluid box" id="transfer-select">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/developer/unit-status/list/all">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('developer:Transfer of ownership')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>    
                        <div className="content-inner">
                            <div className="mt-1">
                                {/* {this.state.loading ? 
                                    <Loading />
                                    : */}
                                    <SelectResident 
                                        updateContactList={this.updateContactList}
                                        fetchQuery = {this.fetchQuery}
                                        nextLink="/developer/unit-status/create/form"
                                        residentialList = {this.state.residentialList}
                                        search = {this.state.search}
                                        loading = {this.state.loading}
                                        />
                                {/* } */}
                               
                            </div>
                        </div>

                        
                    </div>
                }
            </Translation>
            
        )
    }


}
export default TransferOfOwnershipSelect;