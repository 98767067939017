import React, {Component} from "react";
import jwtDecode from 'jwt-decode';
import {format} from "date-fns";

class WaterElectricUtilityReceiveReportPDFRenderTable extends Component {
    constructor(props) {
        super(props);        
    }

    render() {    
       let dataChunked = [];
       let newArr = [];
       let rowPerpage = 16 ;

       let allData = this.props.allData.allContactUnitTransaction

       allData.map((data, index)=>{
            let newData = "";
            newData = JSON.parse(data)
            newArr.push(newData)
       })

       for (let i = 0; i < newArr.length; i += rowPerpage) {
           let chunk = newArr.slice(i, i + rowPerpage);        
           dataChunked.push(chunk);            
       }

       let jwtDecodes = jwtDecode(window.localStorage.getItem('token'));
       let printBy = `${jwtDecodes.first_name} ${jwtDecodes.last_name}`;
       let projectName = this.props.allData.selfProject.name;
       let contact = this.props.contact;
       let productAndService = this.props.productAndService;
       let monthDateHeader = this.props.monthDateHeader

       return ( 
            <React.Fragment>
                {
                    dataChunked.map((data, pageIndex)=>{                                         
                        return (                        
                            <div className='print-page-a4 hoziA4' key={pageIndex}>
                            <div>
                            <p className='f12' style={{ lineHeight: 1.5 }}>{projectName}</p>
                            <p className='f10'>รายงานลูกหนี้ค้างชำระค่าน้ำประปาและค่าไฟฟ้า</p>
                            <p className='f10'>เรียงตามผู้ติดต่อ - {contact}</p>
                            <p className='f10'>สินค้าและบริการ - {productAndService}</p>
                            
                            <p className='f10'>{monthDateHeader}</p>                   
                            </div>    
                            <table className="table tebleBorder">
                                <thead className='tableHead'>
                                    <tr>
                                        <th className="text-center width10">ลำดับ</th>
                                        <th className="text-center width10">บ้านเลขที่/เลขที่ห้อง</th>
                                        <th className="text-center width28">ชื่อ</th>  
                                        <th className="text-center width10">จำนวน (หน่วย)</th>
                                        <th className="text-center width7">อัตรา</th>
                                        <th className="text-center width7">แจ้งหนี้</th>
                                        <th className="text-center width7">ชำระ</th>
                                        <th className="text-center width7">ค้างชำระ</th>
                                        <th className="text-center width7">% ค้างชำระ</th>
                                    </tr>
                                </thead>                 
                                <tbody className="tableBody">
                                    {
                                        data.map((data, index)=>{
                                            if(data.name === "รวม"){
                                                return <tr key={index}>
                                                <td className="text-center"><b>{data.no}</b></td>
                                                <td className="text-center"><b>{data.room}</b></td>
                                                <td className="text-center"><b>รวมทั้งหมด</b></td>  
                                                <td className="text-right"><b>{data.count}</b></td>
                                                <td className="text-right"><b>{data.rate}</b></td>
                                                <td className="text-right"><b>{data.total}</b></td>
                                                <td className="text-right"><b>{data.paid}</b></td>
                                                <td className="text-right"><b>{data.balance}</b></td>
                                                <td className="text-right"><b>{data.percent_balance}</b></td>
                                            </tr>  
                                            } else {
                                                return <tr key={index}>
                                                <td className="text-center">{data.no}</td>
                                                <td className="text-center">{data.room}</td>
                                                <td className="text-left">{data.name}</td>  
                                                <td className="text-right">{data.count}</td>
                                                <td className="text-right">{data.rate}</td>
                                                <td className="text-right">{data.total}</td>
                                                <td className="text-right">{data.paid}</td>
                                                <td className="text-right">{data.balance}</td>
                                                <td className="text-right">{data.percent_balance}</td>
                                            </tr>  
                                            }
                                        })
                                    }                           
                                </tbody>       
                            </table>
                            <div className="footer">
                                <div className="row">
                                    <div className="col-5" />
                                        <div className="col-2 text-center">
                                            <p className="f10 pb-0 mb-0">{pageIndex + 1}/{dataChunked.length}</p>
                                        </div>
                                        <div className="col-5 text-right">
                                            <p className="f10 pb-0 mb-0">
                                                ผู้พิมพ์ {printBy} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                            </p>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        )})               
                }
            </React.Fragment>
        )
    }
}

export default WaterElectricUtilityReceiveReportPDFRenderTable;