import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import enLocale from "date-fns/locale/en";
import i18next from "i18next";

class AccrualBasisChart extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.processData();
  }

  componentDidUpdate() {
    this.processData();
  }

  processData() {
    const { index, totalRevenue, totalExpenditure } = this.props;
    let month = this.formatDateRange(this.props.startPeriod,this.props.endPeriod)
    let data = [];
    for (let i = 0; i < index.length - 1; i++) {
      let dataEachMonth = {
        month: month[i],
        totalRevenue: totalRevenue[i],
        totalExpense: totalExpenditure[i],
        surplusDeficit: totalRevenue[i] - totalExpenditure[i],
      };
      data.push(dataEachMonth);
    }
    this.genChart(data);
  }

  formatDateRange(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    const result = [];
    let locale = `${i18next.t("accrualBasisChart:Locale")}`;
    let localeUse = locale === 'thLocale'? thLocale : enLocale;
    for (let currentDate = start; currentDate <= end; currentDate.setMonth(currentDate.getMonth() + 1)) {
      const formattedDate = format(currentDate, "MMM YY", { locale: localeUse });
      result.push(formattedDate);
    }
    return result;
  }

  genChart(chartData) {
    if (this.root) {
      // Dispose of the old root and chart
      this.root.dispose();
      this.chart.dispose();
    }
    let locale = `${i18next.t("accrualBasisChart:Locale")}`;
    let localeUse = locale === 'thLocale'? thLocale : enLocale;
    let monthStart = format(this.props.startPeriod, "MMM YY", { locale: localeUse });
    let monthEnd =  format(this.props.endPeriod, "MMM YY", { locale: localeUse });

    let data = chartData;
    let root = am5.Root.new("accrualBasisChart");
    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 40,
        paddingLeft: 30,
      })
    );


    chart.children.unshift(
      am5.Label.new(root, {
        text: `${i18next.t("accrualBasisChart:Income statement - expenses (accrual basis) comparing")} ${monthStart} ${i18next.t("accrualBasisChart:to")} ${monthEnd}`,
        fontSize: 16,
        fontWeight: 600,
        textAlign: "left",
        paddingTop: 0,
        paddingBottom: 80,
      })
    );

    chart.get("colors").set("colors", [am5.color(0x1567B4), am5.color(0xE77490), am5.color(0x2EC193)]);

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        paddingTop: 30, 
      })
    );
    
    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8
    })

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "month",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));
    
    xRenderer.grid.template.setAll({
      location: 1
    })

    xAxis.data.setAll(data);

    let label = am5.Label.new(root, {
      rotation: 0,
      text: `(${i18next.t("accrualBasisChart:baht")})`,
      y: -60,
      x: 78,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    yAxis.children.unshift(label);

    // Add series
    function makeSeries(name, fieldValue) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldValue,
          categoryXField: "month",
          clustered: true,
          tooltip: am5.Tooltip.new(root, {
            // ทำให้ตอน hover สามารถเลื่อนไปคลิ๊กได้
            keepTargetHover: true,
          }),
        })
      );
      // ทำให้สามารถ click link ได้
      series.get("tooltip").label.set("interactive", true);

      series.columns.template.setAll({
        tooltipHTML: `<b style="color: black;">{valueY.formatNumber('#,###.00')}</b>`,
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(data);

      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true
          }),
        });
      });

      legend.data.push(series);
    }

    makeSeries(`${i18next.t("accrualBasisChart:Total Revenue")}`, "totalRevenue");
    makeSeries(`${i18next.t("accrualBasisChart:Total Expense")}`, "totalExpense");
    makeSeries(`${i18next.t("accrualBasisChart:Surplus (Deficit)")}`, "surplusDeficit");

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data,
    });
    let annotator = am5plugins_exporting.Annotator.new(root, {});

    exporting.get("menu").set("items", [{
      type: "format",
      format: "jpg",
      label: "Export Image"
    },{
      type: "format",
      format: "print",
      label: "Print"
    },{
      type: "custom",
      label: "Remark",
      callback: function() {
        this.close();
        annotator.toggle();
      }
    }]);

    chart.appear(1000, 100);
    root._logo.dispose();

    this.chart = chart;
    this.root = root;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return <div id="accrualBasisChart"></div>;
  }
}

export default AccrualBasisChart;
