import React from "react";
import { QueryRenderer } from "react-relay";
import { Translation } from "react-i18next";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import ComponentPagination from "../../../libs/componentPagination";
import Pagination from "../../../libs/newPagination";
import BankTransferCreateModal from "./bankTransferCreateModal";
import numberWithCommas from "../../../libs/numberWithComma";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import Navigation from "./navigation";
import _ from "lodash";
import { format } from "date-fns";
import Loading from "../../../libs/loading"

class BankTransferListTable extends ComponentPagination {
  constructor(props) {
    super(props);
    // let month_before = new Date();
    // month_before.setMonth(month_before.getMonth() - 1);

    // this.state.start_date = month_before;
    // this.state.end_date = new Date();
    this.state.start_date = null;
    this.state.end_date = null;
    this.state.temp_start_date = null;
    this.state.temp_end_date = null;
    this.state.search = "";
    this.state.search_input = "";

    this.state.re_query = false;
    this.refreshQuery = this.refreshQuery.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }

  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  refreshQuery() {
    this.setState({ re_query: !this.state.re_query });
  }

  render() {

    return (
      <React.Fragment>
        <Translation>
          {(t) => 
          <>
          <div className="row justify-content-between">
            <div className="col">
              <h3 className="mb-4">
                <Link to="/accounting">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                    }
                    alt="back"
                    className="back"
                  />
                </Link>
                <span>{t("bankTransfer:bank transfer")+ " "+"(BT)"}</span>
              </h3>
            </div>
            <div className="col text-right">
              {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                codename: "accounting_bank_transfer_create",
              }) && (
                <BankTransferCreateModal
                  refreshQuery={this.refreshQuery}
                  changePage={this.goFirst}
                />
              )}
            </div>
          </div>
          <div className="content-inner">
          <div className='row  mb-2'>
                                <div className='col-12'>
                                    <Navigation/>
                                </div>
             </div>
            <div className="input-group float-right w-auto ml-2 mb-5">
              <input
                type="text"
                className="form-control input-size"
                placeholder={t("bank_transfer:Search")}
                value={this.state.search_input}
                name="search"
                onChange={(e) => this.setState({ search_input: e.target.value })}
                onKeyDown={this.handleKeyDown}
              />
              <button
                type="submit"
                className="btn btn-primary form-control search-button"
                name="search"
                value={this.state.search_input}
                onClick={(e) => this.handleSearch(e)}
              >
                {t("bank_transfer:Search")}
              </button>
            </div>
            <div className="float-right ml-2">
              <DatePickerAdapter
                name="end_date"
                className="form-control"
                selected={this.state.temp_end_date}
                maxDate={this.state.current_date}
                onChange={(e) => this.setState({ temp_end_date: e.target.value })}
                required={true}
              />
            </div>

            <div className="float-right ml-2">
              <DatePickerAdapter
                name="start_date"
                className="form-control"
                selected={this.state.temp_start_date}
                // minDate={this.state.min_date}
                maxDate={this.state.temp_end_date}
                onChange={(e) =>
                  this.setState({ temp_start_date: e.target.value })
                }
                required={true}
              />
            </div>
            <QueryRenderer
              environment={environment}
              query={this.props.query}
              cacheConfig={{ use_cache: false }}
              variables={{
                first: this.state.first,
                last: this.state.last,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                search: this.state.search,
                status: this.props.status === 'all' ? null : this.props.status,
                order: "-doc_number",
                re_query: this.state.re_query,
              }}
              render={({ error, props }) => {
                if (error) {
                  return (
                    <div className="alert alert-danger" role="alert">
                      {error.message}
                    </div>
                  );
                } else if (props) {
                  return (
                    <React.Fragment>
                      <div
                        className="table-responsive fade-up card"
                        id="bank-transfer-list-table"
                      >
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th>{t("bank_transfer:No.")}</th>
                              <th>{t("bank_transfer:Date")}</th>
                              <th>{t("bank_transfer:Transfer from account")}</th>
                              <th>{t("bank_transfer:Transfer to account")}</th>
                              <th>{t("bank_transfer:Note")}</th>
                              <th className="text-right">{t("bank_transfer:Amount")}</th>
                              <th className="text-right">{t("bank_transfer:Fees")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.bankTransferViewer.allBankTransfer.edges.map(
                              (bank_transfer) => (
                                <tr key={bank_transfer.node.id}>
                                  {/* <td>{bank_transfer.node.docNumber}</td> */}
                                  <td>
                                    {bank_transfer.node.status !== "VOID" &&
                                    _.some(
                                      JSON.parse(
                                        localStorage.getItem("permission_list")
                                      ),
                                      {
                                        codename:
                                          "accounting_bank_transfer_create",
                                      }
                                    ) ? (
                                      <Link
                                        to={
                                          "/accounting/finance/bank-transfer/transfer-detail/" +
                                          bank_transfer.node.docNumber
                                        }
                                        >
                                        {bank_transfer.node.docNumber}
                                      </Link>
                                    ) : (
                                      bank_transfer.node.docNumber
                                    )}
                                  </td>
                                  <td>
                                    {format(
                                      bank_transfer.node.date,
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {bank_transfer.node.transferOut.bankName}
                                    {bank_transfer.node.transferOut
                                      .accountType === "SAVING_ACCOUNT"
                                      ? <>&nbsp;{t("bank_transfer:saving_account")}</>
                                      : bank_transfer.node.transferOut
                                          .accountType === "CURRENT_ACCOUNT"
                                      ? <>&nbsp;{t("bank_transfer:current_account")}</>
                                      : <>&nbsp;{t("bank_transfer:fixed_deposit")}</>}
                                    {" " +
                                      bank_transfer.node.transferOut
                                        .accountNumber}
                                  </td>
                                  <td>
                                    {bank_transfer.node.transferIn.bankName}
                                    {bank_transfer.node.transferIn.accountType ===
                                    "SAVING_ACCOUNT"
                                      ? <>&nbsp;{t("bank_transfer:saving_account")}</>
                                      : bank_transfer.node.transferIn
                                          .accountType === "CURRENT_ACCOUNT"
                                      ? <>&nbsp;{t("bank_transfer:current_account")}</>
                                      : <>&nbsp;{t("bank_transfer:fixed_deposit")}</>}
                                    {" " +
                                      bank_transfer.node.transferIn.accountNumber}
                                  </td>
                                  {/* <td>{bank_transfer.node.transferOut.accountNumber}</td> */}
                                  {/* <td>{bank_transfer.node.transferIn.accountNumber}</td> */}
                                  <td>
                                    {bank_transfer.node.description
                                      ? bank_transfer.node.description
                                      : "-"}
                                  </td>
                                  <td className="text-right">
                                    {numberWithCommas(bank_transfer.node.amount)}
                                  </td>
                                  <td className="text-right">
                                    {numberWithCommas(bank_transfer.node.fee)}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={
                            props.bankTransferViewer.allBankTransfer.totalCount
                          }
                        />
                      </div>
                  </React.Fragment>
                );
              }
              return <Loading/>
            }}
          />
        </div>
        </>
        }
        </Translation>
      </React.Fragment>
    );
  }
}

export default BankTransferListTable;
