import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {fetchQuery} from "relay-runtime";


const query = graphql`
    query updateNotificationQuery($notificationType: String) {
        updateNotification(notificationType: $notificationType)
    }

`;

export default function UpdateNotification(notification_type) {
    fetchQuery(environment, query, {notificationType: notification_type});
}
