import React, { Component } from 'react'
import _ from "lodash"
import { Link } from 'react-router-dom'
import { format } from 'date-fns';
import numberWithCommas from '../../../libs/numberWithComma';
import { Translation } from 'react-i18next';
import i18n from "i18next";
import i18next from 'i18next';

export default class tableAll extends Component {

  checkType = (type) => {

    let valueResult = ""

    switch (type) {
      case "product":
        valueResult = i18next.t("purchaseRequisition:Product")
        break;
      case "service":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(General work)")
        break;
      case "service_contact":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(Contract)")
        break;
      case "asset":
        valueResult = i18next.t("purchaseRequisition:Asset")
        break;
    }
    return valueResult
  }

  checkStatus = (status) => {
    let valueResult = ""
    let colorResult = ""

    switch (status) {
      case "wait_open":
        valueResult = i18n.t("PageListPRPO:Wait PO")
        colorResult = "statusGray"
        break;
      case "wait": //รออนุมัติ
        valueResult = i18n.t("PageListPRPO:Pending")
        colorResult = "statusYellow"
        break;
      case "approve": 
        valueResult = i18n.t("PageListPRPO:Approve")
        colorResult = "statusGreen"
        break;
      case "void": 
        valueResult = i18n.t("PageListPRPO:Cancled")
        colorResult = "statusRed"
        break;
    }

    return (
      <div className={`${colorResult}`}>
        <span>{valueResult}</span>
      </div>
    )
  }

  render() {
    return (
      <Translation>
        {t=>
        
        <React.Fragment>

          <div className="row">
            <div className="col">
              <div className="fede-up">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th>{t("PageListPRPO:No. PO")}</th>
                        <th>{t("manageOrderList:Date")}</th>
                        <th>{t("PageListPRPO:Creditors/Partners")}</th>
                        <th>{t("PageListPRPO:Category")}</th>
                        <th>{t("PageListPRPO:Type")}</th>
                        <th className="text-center">{t("PageListPRPO:Amount")}</th>
                        <th>{t("PageListPRPO:Status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(this.props.dataManageOrderListAll, (item, indexItem) => {
                        return (
                          <tr key={indexItem}>
                            <td>
                              <Link to={`/purchasing/manageOrder/updateFormManageOrder/${item.type}/${item.status}/${btoa(`ProcurementFormNode:${item.id}`)}`}>{item.doc_number}</Link>
                            </td>
                            <td>{format(item.issued_date, "DD/MM/YYYY")}</td>
                            <td>{item.creditor || "-"}</td>
                            <td>{item.category}</td>
                            <td>{this.checkType(item.type)}</td>
                            <td className="text-center">{numberWithCommas(item.total)}</td>
                            <td>
                              {this.checkStatus(item.status)}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        }
      </Translation>
    )
  }
}
