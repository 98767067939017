/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type projectManagerListQueryVariables = {|
  status?: ?string
|};
export type projectManagerListQueryResponse = {|
  +chooseTerm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +note: ?string,
        +startDate: ?any,
        +expDate: ?any,
        +numberOfPersons: ?number,
      |}
    |}>
  |}
|};
export type projectManagerListQuery = {|
  variables: projectManagerListQueryVariables,
  response: projectManagerListQueryResponse,
|};
*/


/*
query projectManagerListQuery(
  $status: String
) {
  chooseTerm(status: $status) {
    edges {
      node {
        id
        note
        startDate
        expDate
        numberOfPersons
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "SettingTermNodeConnection",
    "kind": "LinkedField",
    "name": "chooseTerm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingTermNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingTermNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfPersons",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "projectManagerListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "projectManagerListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "057110b9d08d200a7f7b0e2f74affbb7",
    "id": null,
    "metadata": {},
    "name": "projectManagerListQuery",
    "operationKind": "query",
    "text": "query projectManagerListQuery(\n  $status: String\n) {\n  chooseTerm(status: $status) {\n    edges {\n      node {\n        id\n        note\n        startDate\n        expDate\n        numberOfPersons\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26ec3c1c32323ad18f484ddc5f92fa67';

module.exports = node;
