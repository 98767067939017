/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type GuaranteeMoneyReceivedPayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type OtherReceivePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type ReceiptDepositPayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type ReceivePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type UnknownReceiveStatus = "CLEAR" | "VOID" | "WAIT" | "%future added value";
export type unknownReceiveDetailQueryVariables = {|
  id: string
|};
export type unknownReceiveDetailQueryResponse = {|
  +unknownReceive: ?{|
    +id: string,
    +issuedDate: any,
    +docNumber: string,
    +description: string,
    +refNumber: ?string,
    +amount: number,
    +status: UnknownReceiveStatus,
    +receive: ?{|
      +id: string,
      +docNumber: string,
      +issuedDate: any,
      +total: ?number,
      +firstName: string,
      +lastName: string,
      +payGroup: ReceivePayGroup,
    |},
    +otherReceive: ?{|
      +id: string,
      +docNumber: string,
      +issuedDate: any,
      +total: number,
      +firstName: string,
      +lastName: string,
      +payGroup: OtherReceivePayGroup,
    |},
    +receiptDeposit: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +total: ?number,
          +issuedDate: any,
          +firstName: string,
          +lastName: string,
          +payGroup: ReceiptDepositPayGroup,
        |}
      |}>
    |},
    +guaranteeMoneyReceived: ?{|
      +id: string,
      +docNumber: string,
      +issuedDate: any,
      +firstName: string,
      +lastName: string,
      +payGroup: GuaranteeMoneyReceivedPayGroup,
      +amount: ?number,
    |},
    +receiptDepositUsed: ?{|
      +id: string,
      +docNumber: string,
      +issuedDate: any,
      +firstName: string,
      +lastName: string,
      +payGroup: ReceiptDepositPayGroup,
      +total: ?number,
    |},
    +chartOfAccount: ?{|
      +chartOfAccountCode: string,
      +name: string,
    |},
    +receiveChannel: ?string,
    +bankAccount: ?{|
      +bankName: string,
      +accountNumber: string,
      +accountType: BankAccountAccountType,
    |},
    +contact: ?{|
      +id: string,
      +name: string,
      +refNumber: string,
    |},
    +bankAccountTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +imageSlip: ?string,
        |}
      |}>
    |},
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
|};
export type unknownReceiveDetailQuery = {|
  variables: unknownReceiveDetailQueryVariables,
  response: unknownReceiveDetailQueryResponse,
|};
*/


/*
query unknownReceiveDetailQuery(
  $id: ID!
) {
  unknownReceive(id: $id) {
    id
    issuedDate
    docNumber
    description
    refNumber
    amount
    status
    receive {
      id
      docNumber
      issuedDate
      total
      firstName
      lastName
      payGroup
    }
    otherReceive {
      id
      docNumber
      issuedDate
      total
      firstName
      lastName
      payGroup
    }
    receiptDeposit {
      edges {
        node {
          id
          docNumber
          total
          issuedDate
          firstName
          lastName
          payGroup
        }
      }
    }
    guaranteeMoneyReceived {
      id
      docNumber
      issuedDate
      firstName
      lastName
      payGroup
      amount
    }
    receiptDepositUsed {
      id
      docNumber
      issuedDate
      firstName
      lastName
      payGroup
      total
    }
    chartOfAccount {
      chartOfAccountCode
      name
      id
    }
    receiveChannel
    bankAccount {
      bankName
      accountNumber
      accountType
      id
    }
    contact {
      id
      name
      refNumber
    }
    bankAccountTransaction {
      edges {
        node {
          id
          imageSlip
        }
      }
    }
  }
  viewer {
    allChartOfAccount(chartOfAccountCode_Istartswith: "2210") {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payGroup",
  "storageKey": null
},
v13 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v3/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ReceiveNode",
  "kind": "LinkedField",
  "name": "receive",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "OtherReceiveNode",
  "kind": "LinkedField",
  "name": "otherReceive",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ReceiptDepositNodeConnection",
  "kind": "LinkedField",
  "name": "receiptDeposit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiptDepositNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceiptDepositNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            (v9/*: any*/),
            (v3/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "GuaranteeMoneyReceivedNode",
  "kind": "LinkedField",
  "name": "guaranteeMoneyReceived",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    (v3/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ReceiptDepositNode",
  "kind": "LinkedField",
  "name": "receiptDepositUsed",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    (v3/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiveChannel",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v20/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccountTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "bankAccountTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountTransactionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountTransactionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageSlip",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "2210"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v20/*: any*/),
            (v19/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"2210\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "unknownReceiveDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnknownReceiveNode",
        "kind": "LinkedField",
        "name": "unknownReceive",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "chartOfAccount",
            "plural": false,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "kind": "LinkedField",
            "name": "bankAccount",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/)
            ],
            "storageKey": null
          },
          (v25/*: any*/),
          (v26/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v27/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "unknownReceiveDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnknownReceiveNode",
        "kind": "LinkedField",
        "name": "unknownReceive",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "chartOfAccount",
            "plural": false,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "kind": "LinkedField",
            "name": "bankAccount",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v25/*: any*/),
          (v26/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v27/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1215f93195c0349996886d42a37b812d",
    "id": null,
    "metadata": {},
    "name": "unknownReceiveDetailQuery",
    "operationKind": "query",
    "text": "query unknownReceiveDetailQuery(\n  $id: ID!\n) {\n  unknownReceive(id: $id) {\n    id\n    issuedDate\n    docNumber\n    description\n    refNumber\n    amount\n    status\n    receive {\n      id\n      docNumber\n      issuedDate\n      total\n      firstName\n      lastName\n      payGroup\n    }\n    otherReceive {\n      id\n      docNumber\n      issuedDate\n      total\n      firstName\n      lastName\n      payGroup\n    }\n    receiptDeposit {\n      edges {\n        node {\n          id\n          docNumber\n          total\n          issuedDate\n          firstName\n          lastName\n          payGroup\n        }\n      }\n    }\n    guaranteeMoneyReceived {\n      id\n      docNumber\n      issuedDate\n      firstName\n      lastName\n      payGroup\n      amount\n    }\n    receiptDepositUsed {\n      id\n      docNumber\n      issuedDate\n      firstName\n      lastName\n      payGroup\n      total\n    }\n    chartOfAccount {\n      chartOfAccountCode\n      name\n      id\n    }\n    receiveChannel\n    bankAccount {\n      bankName\n      accountNumber\n      accountType\n      id\n    }\n    contact {\n      id\n      name\n      refNumber\n    }\n    bankAccountTransaction {\n      edges {\n        node {\n          id\n          imageSlip\n        }\n      }\n    }\n  }\n  viewer {\n    allChartOfAccount(chartOfAccountCode_Istartswith: \"2210\") {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfdefc54df43d625e3d4829a6ce9528c';

module.exports = node;
