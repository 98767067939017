/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCrossBillingPaymentInput = {|
  date?: ?any,
  crossBillingList?: ?any,
  clientMutationId?: ?string,
|};
export type crossBillingPaymentCreateMutationVariables = {|
  input: CreateCrossBillingPaymentInput
|};
export type crossBillingPaymentCreateMutationResponse = {|
  +createCrossBillingPayment: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type crossBillingPaymentCreateMutation = {|
  variables: crossBillingPaymentCreateMutationVariables,
  response: crossBillingPaymentCreateMutationResponse,
|};
*/


/*
mutation crossBillingPaymentCreateMutation(
  $input: CreateCrossBillingPaymentInput!
) {
  createCrossBillingPayment(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCrossBillingPaymentPayload",
    "kind": "LinkedField",
    "name": "createCrossBillingPayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "crossBillingPaymentCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "crossBillingPaymentCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "16bfb1417b6f51ed7991d8e081e93056",
    "id": null,
    "metadata": {},
    "name": "crossBillingPaymentCreateMutation",
    "operationKind": "mutation",
    "text": "mutation crossBillingPaymentCreateMutation(\n  $input: CreateCrossBillingPaymentInput!\n) {\n  createCrossBillingPayment(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9f148b799ebc18635d14341fa6d3e59';

module.exports = node;
