import React, { Component } from 'react';
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import SettingForeignOwnershipView from './settingForeignOwnershipView';
import Swal from 'sweetalert2';
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import SearchSelect from '../../libs/searchSelect';
import _ from 'lodash';
import Loading from '../../libs/loading';
import CreateAndUpdateDocument from './createUpdateDocumentForeignOwnership';
import i18next from 'i18next';

const query = graphql`
query foreignOwnershipDocumentQuery{
    selfProject{
        id
        name
        provinceProject
        typeOfProject
    }
    allJuristic{
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          role
          image
          user {
            id
            username
            lastLogin
          }
        }
      }
    }
    allDocumentationForeignOwnership {
            totalCount
            edges {
                node {
                id
                subject
                dearYou
                descriptionSubpage1
                descriptionSubpage2
                remark
                juristicNoUse  
                juristicSignatureUse  
                signerName
                signerRole
                juristic{
                    id
                    firstName
                    lastName
                    role
                }
                }
            }
        }
}`;

class SettingDocument extends Component {
    constructor(props){
        super(props)
        this.state = {
            selfProject : "",
            settingInfo : "",
            settingDocument : "",
            loading : false,
            permissionEdit : true,
            juristicName : [],
            juristicNoUse : false,
            showCheckBox : false,
            juristicSignatureUse : false,
            typeOfProject : "",
        }
        this.onSubmit = this.onSubmit.bind(this);

    }

    async componentDidMount() {
        this.fetchQuery()
    }

    fetchQuery = () => {
        this.setState({loading : true})
        fetchQuery(environment, query).then((data) => {
            const allDocumentationForeignOwnership = data.allDocumentationForeignOwnership?.edges[0];
            const updatedSettingInfo = { ...this.state.settingInfo };
            updatedSettingInfo.id = allDocumentationForeignOwnership?.node.id;
            updatedSettingInfo.subject = allDocumentationForeignOwnership?.node.subject? allDocumentationForeignOwnership?.node.subject : "แจ้งรายชื่อ และอัตราส่วนกรรมสิทธิ์ชาวต่างชาติ";
            updatedSettingInfo.dear = allDocumentationForeignOwnership?.node.dearYou? allDocumentationForeignOwnership?.node.dearYou : `เจ้าพนักงานที่ดิน${data.selfProject.provinceProject}`;
            updatedSettingInfo.description1 = allDocumentationForeignOwnership?.node.descriptionSubpage1;
            updatedSettingInfo.description2 = allDocumentationForeignOwnership?.node.descriptionSubpage2? allDocumentationForeignOwnership?.node.descriptionSubpage2 : "จะมีการโอนกรรมสิทธิ์ห้องชุดให้แก่บุคคลต่างชาติ ซึ่งขอแจ้งอัตราส่วนพื้นที่ของบุคคลต่างชาติ ดังต่อไปนี้";
            updatedSettingInfo.remark = allDocumentationForeignOwnership?.node.remark;
            updatedSettingInfo.signatureNameID = allDocumentationForeignOwnership?.node.juristic?.id;
            updatedSettingInfo.signatureName = allDocumentationForeignOwnership?.node.signerName
            updatedSettingInfo.signaturePosition = allDocumentationForeignOwnership?.node.signerRole;
            updatedSettingInfo.juristicNoUse = allDocumentationForeignOwnership?.node.juristicNoUse;
            updatedSettingInfo.juristicSignatureUse = allDocumentationForeignOwnership?.node.juristicSignatureUse;
            this.setState({ 
                selfProject: data.selfProject.name,
                juristicName : data.allJuristic.edges,
                loading : false,
                settingDocument : data.allDocumentationForeignOwnership.edges[0]?.node,
                settingInfo : updatedSettingInfo,
                disCheckBox : allDocumentationForeignOwnership?.node.juristicNoUse? true : false,
                typeOfProject : data.selfProject.typeOfProject
            });

        })
    }

    handleChange = (event) => {
        let e = _.cloneDeep(event)
        if (e.target.name === 'settingInfo.signatureNameID') {
            this.state.juristicName.forEach((name) => {
                  if (name.node.id === e.target.value) {
                      this.setState(prevState => {
                          return _.set(
                              _.cloneDeep(prevState),
                              "settingInfo.signaturePosition",
                              name.node.role
                          );
                      });
                      this.setState(prevState => {
                          return _.set(
                              _.cloneDeep(prevState),
                              "settingInfo.signatureName",
                              `${name.node.firstName}${" "}${name.node.lastName}`
                          );
                      });
                  }
              })
        } 
        
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                e.target.name,
                e.target.value
            )
        });
    }

    onSubmit = () => {
        let variables ={
            "input":{
                "subject": this.state.settingInfo.subject || "",
                "dearYou": this.state.settingInfo.dear || "",
                "descriptionSubpage1": this.state.settingInfo.description1 || "",
                "descriptionSubpage2": this.state.settingInfo.description2 || "",
                "remark": this.state.settingInfo.remark || "",
                "juristicNoUse": this.state.settingInfo.juristicNoUse || false,
                "signerName": this.state.settingInfo.signatureName || "",
                "signerRole": this.state.settingInfo.signaturePosition || "",
                "juristic": this.state.settingInfo.signatureNameID || "",
                "juristicSignatureUse" : this.state.settingInfo.juristicSignatureUse || ""
                }
            }
            CreateAndUpdateDocument(variables,
                () => Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                    window.location.reload()
                    })
                , () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error').then(() => {
                    window.location.reload()
                })
            );
    }

    checkJuristicNoUse = () => {
        this.setState(prevState => ({
          settingInfo: {
            ...prevState.settingInfo,
            juristicNoUse: !prevState.settingInfo.juristicNoUse,
            juristicSignatureUse: prevState.settingInfo.juristicNoUse
          },
          disCheckBox: !prevState.settingInfo.juristicNoUse,
        }));
    }

    checkShowSignature = () => {
        this.setState(prevState => ({
        settingInfo: {
            ...prevState.settingInfo,
            juristicSignatureUse: !prevState.settingInfo.juristicSignatureUse
        }
        }));  
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    {this.state.loading ? 
                    <Loading />
                    :
                    <div className="container-fluid box" id="foreignOwnershipDocument">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>ตั้งค่าเอกสารกรรมสิทธิ์ชาวต่างชาติ</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <div className="content-inner">
                            <div className="row" >
                                        <div className="col-md-5 input-form">
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <h5>เรื่อง</h5>
                                                </div>
                                                <div className="col-12">
                                                    <input  className="form-control" type="text" width={'100%'} 
                                                        onChange= {this.handleChange}
                                                        name="settingInfo.subject"
                                                        value={this.state.settingInfo.subject}
                                                        maxLength={100}
                                                    />
                                                     <p className="text-danger text-count text-right">{this.state.settingInfo.subject?.length} / 100</p>
                                                </div>                                            
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <h5>เรียน</h5>
                                                </div>
                                                <div className="col-12">
                                                    <input  className="form-control" type="text" width={'100%'} 
                                                        onChange= {this.handleChange}
                                                        name="settingInfo.dear"
                                                        value={this.state.settingInfo.dear}
                                                        maxLength={100}
                                                    />
                                                    <p className="text-danger text-count text-right">{this.state.settingInfo.dear?.length} / 100</p>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-6">
                                                    <h5>คำอธิบายเอกสาร ย่อหน้าที่ 1</h5>
                                                </div>
                                                {/* <div className="col-6 float-right">
                                                    <span className="reset cursor float-right" onClick={() => this.undoDescription1()}>ค่าเริ่มต้น</span>
                                                </div> */}
                                                <div className="col-12">
                                                    <textarea 
                                                        className="form-control text-area mt-2" 
                                                        rows="1"
                                                        onChange= {this.handleChange}
                                                        name="settingInfo.description1"
                                                        value={this.state.settingInfo.description1}
                                                        maxLength={800}
                                                    />
                                                    <p className="text-danger text-count text-right">{this.state.settingInfo.description1?.length} / 800</p>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <h5>คำอธิบายเอกสาร ย่อหน้าที่ 2</h5>
                                                </div>
                                                {/* <div className="col-6 float-right">
                                                    <span className="reset cursor float-right" onClick={() => this.undoDescription2()}>ค่าเริ่มต้น</span>
                                                </div> */}
                                                <div className="col-12">
                                                    <textarea 
                                                        className="form-control text-area mt-2" 
                                                        rows="1"
                                                        onChange= {this.handleChange}
                                                        name="settingInfo.description2"
                                                        value={this.state.settingInfo.description2}
                                                        maxLength={800}
                                                    />
                                                    <p className="text-danger text-count text-right">{this.state.settingInfo.description2?.length} / 800</p>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <h5>หมายเหตุ</h5>
                                                </div>
                                                <div className="col-12">
                                                    <textarea 
                                                        className="form-control text-area mt-2" 
                                                        rows="1"
                                                        onChange= {this.handleChange}
                                                        name="settingInfo.remark"
                                                        value={this.state.settingInfo.remark}
                                                        maxLength={254}
                                                    />
                                                    <p className="text-danger text-count text-right">{this.state.settingInfo.remark?.length} / 254</p>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <h5>ผู้ลงนาม</h5>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.settingInfo.juristicNoUse}
                                                        onChange={this.checkJuristicNoUse}
                                                        />
                                                    <span className="ml-3 mr-4">ระบุเอง</span>
                                                    <input                                                        
                                                        type="checkbox"
                                                        checked={this.state.settingInfo.juristicSignatureUse}
                                                        onChange={this.checkShowSignature}
                                                        disabled={this.state.disCheckBox}
                                                        />
                                                    <span className="ml-3">แสดงลายเซ็น</span>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <h5>ชื่อผู้ลงนาม</h5>
                                                </div>
                                                <div className="col-12">
                                                    {this.state.settingInfo.juristicNoUse ?
                                                    <input 
                                                    type="text" 
                                                        className="form-control"
                                                        name="settingInfo.signatureName"
                                                        id="settingInfo.signatureName" 
                                                        value={this.state.settingInfo.signatureName} 
                                                        onChange={this.handleChange}
                                                    />
                                                    :
                                                    <SearchSelect 
                                                        onChange={this.handleChange}
                                                        value={this.state.settingInfo.signatureNameID}
                                                        name="settingInfo.signatureNameID"
                                                        placeholder="ชื่อผู้ลงนาม"
                                                        queryObject={this.state.juristicName}
                                                        keyOfValue="id"
                                                        keyOfLabel="firstName:lastName"
                                                        require={true}
                                                        // disabled={!this.state.permissionEdit}
                                                    />
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <h5>ตำแหน่งผู้ลงนาม</h5>
                                                </div>
                                                <div className="col-12">
                                                    {this.state.settingInfo.juristicNoUse ?
                                                    <input 
                                                        type="text" 
                                                        className="form-control"
                                                        name="settingInfo.signaturePosition"
                                                        id="settingInfo.signaturePosition" 
                                                        value={this.state.settingInfo.signaturePosition} 
                                                        onChange={this.handleChange}
                                                    />
                                                    :
                                                    <input type="text" 
                                                        className="form-control"
                                                        name="settingInfo.signaturePosition"
                                                        id="settingInfo.signaturePosition" 
                                                        value={this.state.settingInfo.signaturePosition} 
                                                        onChange={this.handleChange}
                                                        disabled={true} 
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <SettingForeignOwnershipView 
                                                selfProject = {this.state.selfProject}
                                                settingInfo = {this.state.settingInfo}
                                                typeOfProject = {this.state.typeOfProject}
                                            />
                                        </div>
                                        <div className="col-md-5"/>
                                        <div className="col-md-6">
                                            <div className='btnContainer'>
                                                <button className='btnCancel'>ยกเลิก</button>
                                                <button className='btnConfirm' onClick={()=>this.onSubmit()}>บันทึก</button>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                    }
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default SettingDocument;