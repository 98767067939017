/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountRecordGroupCategory = "GENERAL" | "HISTORICAL" | "PAYMENT" | "PURCHASE" | "RECEIVE" | "SALES" | "VOIDED" | "%future added value";
export type dailyJournalCreateQueryVariables = {|
  id: string,
  glOffSite?: ?boolean,
|};
export type dailyJournalCreateQueryResponse = {|
  +contactViewer: ?{|
    +id: string,
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
        |}
      |}>
    |},
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +tableAccount: ?any,
        +startDateAccountPeriod: ?any,
        +endDateAccountPeriod: ?any,
      |}
    |}>
  |},
  +accountRecordGroup: ?{|
    +id: string,
    +refNumber: ?string,
    +refTransaction: ?string,
    +name: string,
    +issuedDate: ?any,
    +category: AccountRecordGroupCategory,
    +accountRecord: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +debit: number,
          +credit: number,
          +chartOfAccountCode: ?{|
            +id: string,
            +chartOfAccountCode: string,
          |},
        |}
      |}>
    |},
  |},
  +allActiveChartOfAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +chartOfAccountCode: string,
        +name: string,
        +totalDebit: ?number,
        +totalCredit: ?number,
        +totalHistoricalDebit: ?number,
        +totalHistoricalCredit: ?number,
        +totalPeriodDebit: ?number,
        +totalPeriodCredit: ?number,
      |}
    |}>
  |},
|};
export type dailyJournalCreateQuery = {|
  variables: dailyJournalCreateQueryVariables,
  response: dailyJournalCreateQueryResponse,
|};
*/


/*
query dailyJournalCreateQuery(
  $id: ID!
  $glOffSite: Boolean
) {
  contactViewer {
    id
    allContact {
      edges {
        node {
          id
          name
        }
      }
    }
  }
  viewer {
    allChartOfAccount {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
  allAccountProjectManager {
    edges {
      node {
        id
        tableAccount
        startDateAccountPeriod
        endDateAccountPeriod
      }
    }
  }
  accountRecordGroup(id: $id) {
    id
    refNumber
    refTransaction
    name
    issuedDate
    category
    accountRecord {
      edges {
        node {
          id
          name
          debit
          credit
          chartOfAccountCode {
            id
            chartOfAccountCode
          }
        }
      }
    }
  }
  allActiveChartOfAccount(glOffSite: $glOffSite) {
    edges {
      node {
        id
        chartOfAccountCode
        name
        totalDebit
        totalCredit
        totalHistoricalDebit
        totalHistoricalCredit
        totalPeriodDebit
        totalPeriodCredit
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "glOffSite"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactViewer",
  "kind": "LinkedField",
  "name": "contactViewer",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactNodeConnection",
      "kind": "LinkedField",
      "name": "allContact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContactNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountProjectManagerNodeConnection",
  "kind": "LinkedField",
  "name": "allAccountProjectManager",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountProjectManagerNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountProjectManagerNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tableAccount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDateAccountPeriod",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDateAccountPeriod",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "AccountRecordGroupNode",
  "kind": "LinkedField",
  "name": "accountRecordGroup",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refTransaction",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountRecordNodeConnection",
      "kind": "LinkedField",
      "name": "accountRecord",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountRecordNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountRecordNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "debit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "credit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChartOfAccountNode",
                  "kind": "LinkedField",
                  "name": "chartOfAccountCode",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "glOffSite",
      "variableName": "glOffSite"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allActiveChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v5/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPeriodDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPeriodCredit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "dailyJournalCreateQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "dailyJournalCreateQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "6a7f3d9bf36560688a2264588da0c668",
    "id": null,
    "metadata": {},
    "name": "dailyJournalCreateQuery",
    "operationKind": "query",
    "text": "query dailyJournalCreateQuery(\n  $id: ID!\n  $glOffSite: Boolean\n) {\n  contactViewer {\n    id\n    allContact {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n  viewer {\n    allChartOfAccount {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n  allAccountProjectManager {\n    edges {\n      node {\n        id\n        tableAccount\n        startDateAccountPeriod\n        endDateAccountPeriod\n      }\n    }\n  }\n  accountRecordGroup(id: $id) {\n    id\n    refNumber\n    refTransaction\n    name\n    issuedDate\n    category\n    accountRecord {\n      edges {\n        node {\n          id\n          name\n          debit\n          credit\n          chartOfAccountCode {\n            id\n            chartOfAccountCode\n          }\n        }\n      }\n    }\n  }\n  allActiveChartOfAccount(glOffSite: $glOffSite) {\n    edges {\n      node {\n        id\n        chartOfAccountCode\n        name\n        totalDebit\n        totalCredit\n        totalHistoricalDebit\n        totalHistoricalCredit\n        totalPeriodDebit\n        totalPeriodCredit\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b68167cd9c29b4f4a9f9e7256a421c6';

module.exports = node;
