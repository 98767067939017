import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { Helmet } from 'react-helmet';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../../env/environment';
import { format } from 'date-fns';
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import Loading from '../../../libs/loading';
import CollectionLawFirmENoticeCondoTh from './collectionLawFirmENoticeCondoTh';
import CollectionLawFirmENoticeCondoEn from './collectionLawFirmENoticeCondoEn';
import { encode } from 'base-64';
import CollectionLawFirmENoticeVillageTh from './collectionLawFirmENoticeVilageTh';
import logCollectionLawFrimMutation from "../mutation/logCollectionLawFrim";
const ThaiBaht = require('thai-baht-text');
const query = graphql`
    query collectionLawFirmENoticeDocumentQuery($idList: [String], $siteId: ID) {
        selfProject{
            typeOfProject
            nameTh
            nameEn
        }
        allCollectionLawFirmFromSetting(siteId: $siteId){
            edges{
                node{
                    id
                    feeRateTh
                    feeRateOther
                    lawyerSignature
                    lawyerName
                    regulations
                    rulesRegulationsjuristic
                    regulationsStatus
                    dueDateDays
                    telJuristic
                    telOffice
                }
            } 
        }
        allCollectionLawFirm(idList:$idList) {
            edges{
                node{
                id
                docNumber
                issuedDate
                status
                dueDate
                contact{
                    id
                    refNumber
                    name
                }
                expenseList
                finalParagraphIndentation
                language

                lawyerName
                lawyerSignature
                debtName
                telJuristic
                telOffice

                totalDebt
                totalFine
                creator

                fmcfCollectionLawFirm{
                    edges{
                        node{
                            id
                            docNumber
                            issuedDate
                            projectName
                            contactName
                            contactRoom
                            sizeRoom
                            balconyRoom
                            dueDateDays
                            dueDate
                            total
                            fine
                            paid

                            lawyerSignature
                            lawyerName
                            rulesRegulationsjuristic
                            telOffice
                            telJuristic
                            status
                            documentType
                            voidNote
                            creator
                            creatorEdit
                            creatorDelete
                            creatorApproval
                        }
                    }
                }
            }
        } 
    }
}
`;



const CollectionLawFirmENoticeDocument = (props) => {
    const [loading, setLoading] = useState(true)
    const [setting, setSetting] = useState({})
    const [dataTable, setDataTable] = useState([])
    const [forPrint, setFor] = useState("all")
    const [pageOther, setPageTaotalOther] = useState(0)
    const [selfProject, SetSelfProject] = useState(null)

    const generatePdf = async () => {
        // const zip = new JSZip();
        // const pdfPromises = [];

        // for (let index = 0; index < dataTable.length; index++) {
        //     const pdf = new jsPDF('p', 'mm', 'a4');
        //     const contentDivs = document.getElementById('colorUseDocTh' + index);
        //     const contentDivsEn = document.getElementById('colorUseDocEn' + index);
        //     const addEnglishContent = parseInt(dataTable[index].node.totalFine) === parseInt(setting.feeRateOther);
        //     const addContentToPdf = (element) => {
        //         return new Promise((resolve) => {
        //             html2canvas(element).then((canvas) => {
        //                 const imgData = canvas.toDataURL('image/png');
        //                 pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 dimensions
        //                 resolve(pdf);
        //             });
        //         });
        //     };

        //     const pdfPromise = new Promise(async (resolve) => {
        //         // Add contentDivs
        //         await addContentToPdf(contentDivs);
        //         // Check if you need to add a new page
        //         if (addEnglishContent) {
        //             await pdf.addPage(); // Add a new page
        //             await addContentToPdf(contentDivsEn);
        //         }
        //         resolve(pdf.output('blob'));
        //     });
        //     pdfPromises.push(pdfPromise);
        // }
        // Promise.all(pdfPromises).then((pdfBlobs) => {
        //     for (let i = 0; i < pdfBlobs.length; i++) {
        //         zip.file(`ENotice_${dataTable[i].node.contact.name.replace("/", "")}_${dataTable[i].node.docNumber}.pdf`, pdfBlobs[i]);
        //     }
        //     zip.generateAsync({ type: 'blob' }).then((content) => {
        //         const url = window.URL.createObjectURL(content);
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = 'ENotices' + format(new Date(), "YYYYMMDD") + '.zip';
        //         a.click();
        //         window.URL.revokeObjectURL(url);
        //     });
        // });
    };

    useEffect(() => {
        fetchQuery(environment, query, {
            idList: props?.location?.state?.checkList ?? JSON.parse(localStorage.getItem("allCollectionLawFirm")) ?? props?.match?.params?.id,
            siteId: encode("SiteNode:" + localStorage.getItem("site_id"))
        }).then((res) => {
            setDataTable(res?.allCollectionLawFirm?.edges ?? [])
            let sett = res.allCollectionLawFirmFromSetting?.edges
            let data = res?.allCollectionLawFirm?.edges
            let rateOther = sett?.find(ex => ex?.node?.feeRateOther > 0)
            let ratefee = sett?.find(ex => ex?.node?.feeRateTh > 0)
            let lawyerName = sett?.find(ex => ex?.node?.lawyerSignature || ex?.node.lawyerName)
            let lawyerSignature = sett?.find(ex => ex?.node?.lawyerSignature)
            let dueDateDays = sett?.find(ex => ex?.node?.dueDateDays)
            let telJuristic = sett?.find(ex => ex?.node?.telJuristic)

            let dataSetting = {
                lawyerName: lawyerName?.node?.lawyerName || '.........................................................',
                lawyerSignature: lawyerSignature?.node?.lawyerSignature || '',
                feeRateOther: rateOther?.node?.feeRateOther || 0,
                feeRateTh: ratefee?.node?.feeRateTh || 0,
                ruleStatus: lawyerName?.node?.regulationsStatus || false,
                ruleName: lawyerName?.node?.regulations || "",
                ruleNo: lawyerName?.node?.rulesRegulationsjuristic || null,
                dueDateDays: dueDateDays?.node?.dueDateDays || null,
                telJuristic: telJuristic?.node?.telJuristic || null
            }


            let findTotalPage = 0

            data.map((ex) => {
                findTotalPage += ex.node.totalFine === dataSetting.feeRateOther ? 2 : 1
            })

            setSetting(dataSetting)
            setPageTaotalOther(findTotalPage)
            SetSelfProject(res?.selfProject)
            setLoading(false)
        })
    }, [])

    const savePdfAndPrint = () => {

        let variables = {
            "input": {
              "id":props?.match?.params?.id
            }
          }
          logCollectionLawFrimMutation(variables)
        
        if (forPrint === "all") {
            window.print()
        } else {
            generatePdf()
        }
        
    }

    return (
        !loading ?
            <div>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>E-Notice</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>
                <div className="print-top-menu colorUse">
                    <div className="logo pt-5">
                        <div className='' >
                            <BackButtonIcon
                                document={true}
                                LinkBack={props?.location?.state?.LinkBack ?? "/accounting/collection-law-firm/list/all"}
                                LinkText={"กลับไปหน้ารายการ"}
                                style={{ position: 'absolute', left: 12, display: 'flex' }}
                            />
                        </div>

                        <img src={process.env.PUBLIC_URL + '/images/iconAction/E-notice/silXE-notice.png'} alt="silverman" className='w475' />
                        <div className="d-flex justify-content-center mt-3">
                            <p className='fs-20 fw-400 radioInput'>เลือกปริ้นเอกสาร :
                                <label className="containerRadio fw-300 ml-3 pl-8" style={{ display: 'inline' }}> ทั้งหมด <input type="radio" name="typePrint" defaultChecked="true" onChange={() => setFor("all")} /><span className="checkmark" style={{ marginTop: 6 }}></span></label>
                                {/* <label className="containerRadio fw-300 ml-3 pl-8" style={{ display: 'inline' }}>เลือกแยกหลายยูนิต <input type="radio" name="typePrint" defaultChecked="" onChange={() => setFor("unit")} /><span className="checkmark" style={{ marginTop: 6 }}></span></label> */}
                            </p>
                        </div>
                        <p className='fs-20 text_808080 text-center'>พิมพ์หนังสือติดตามทั้งหมด
                            ({
                                forPrint === "all" ?
                                    "1 ไฟล์ " + pageOther + " หน้า" : dataTable.length + " ไฟล์ " + pageOther + " หน้า"
                            })
                        </p>
                    </div>

                    <div className="text-center mt-2 mb-2 searchFunction">
                        <button className="btn-primary ml-3 w300" onClick={savePdfAndPrint}>{forPrint === "all" ? "Print" : "Download PDF"}</button>
                    </div>
                </div>

                <div className=''>
                    <div id="content">
                        {
                            dataTable?.map((items, indexS) => {
                                let sett = items.node.fmcfCollectionLawFirm.edges[0].node
                                let nameProject = selfProject?.nameTh
                                let nameProjectEn = selfProject?.nameEn

                                let typeCheckHome = selfProject?.typeOfProject?.toLowerCase() === "village" || selfProject?.typeOfProject?.toLowerCase() === "building" || selfProject?.typeOfProject?.toLowerCase() === "single_home" || selfProject?.typeOfProject?.toLowerCase() === "town_house" ? true : false
                                if (selfProject?.nameTh?.includes("นิติบุคคลหมู่บ้านจัดสรร")) {
                                    nameProject = selfProject?.nameTh.replace("นิติบุคคลหมู่บ้านจัดสรร", "")
                                }


                                return (
                                    <div key={"dataTable::Dodcurmynt::" + indexS}>
                                        {
                                            typeCheckHome ?
                                                <CollectionLawFirmENoticeVillageTh data={sett} indexS={indexS} setting={setting} nameProject={nameProject} /> :
                                                <CollectionLawFirmENoticeCondoTh data={sett} indexS={indexS} setting={setting} nameProject={nameProject} />
                                        }
                                        {
                                            parseInt(items.node.totalFine) === parseInt(setting.feeRateOther) &&
                                            <CollectionLawFirmENoticeCondoEn data={sett} indexS={indexS} setting={setting} nameProject={nameProjectEn} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            :
            <Loading />
    );

}


export default CollectionLawFirmENoticeDocument