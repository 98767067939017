/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankUnitDepositStatus = "DURING" | "FINISHED" | "%future added value";
export type interestDashboardQueryVariables = {||};
export type interestDashboardQueryResponse = {|
  +bankUnitAutomaticCal: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +automaticCal: boolean,
        +issuedDate: ?any,
        +dueDate: ?any,
        +interestPerYear: number,
        +price: number,
        +calculationDate: ?any,
        +depositStatus: BankUnitDepositStatus,
      |}
    |}>
  |},
  +selfProject: ?{|
    +id: string,
    +name: string,
    +nameEn: ?string,
  |},
|};
export type interestDashboardQuery = {|
  variables: interestDashboardQueryVariables,
  response: interestDashboardQueryResponse,
|};
*/


/*
query interestDashboardQuery {
  bankUnitAutomaticCal {
    edges {
      node {
        id
        automaticCal
        issuedDate
        dueDate
        interestPerYear
        price
        calculationDate
        depositStatus
      }
    }
  }
  selfProject {
    id
    name
    nameEn
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BankUnitNodeConnection",
    "kind": "LinkedField",
    "name": "bankUnitAutomaticCal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankUnitNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankUnitNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "automaticCal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "issuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "interestPerYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calculationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depositStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameEn",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "interestDashboardQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "interestDashboardQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "61e8be2c5f5eb0a86cb2b93776357e00",
    "id": null,
    "metadata": {},
    "name": "interestDashboardQuery",
    "operationKind": "query",
    "text": "query interestDashboardQuery {\n  bankUnitAutomaticCal {\n    edges {\n      node {\n        id\n        automaticCal\n        issuedDate\n        dueDate\n        interestPerYear\n        price\n        calculationDate\n        depositStatus\n      }\n    }\n  }\n  selfProject {\n    id\n    name\n    nameEn\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dce9127b6a4cebb16bdf1f1f3b14950';

module.exports = node;
