import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import SilvermanAgentAccept from "./silvermanAgentAccept"
import SilvermanAgentStatus from "./silvermanAgentStatus"
import silvermanAgentCreate from "./silvermanAgentCreate"
import DecodeUrl from "./decodeUrl"

class SilvermanAgent extends Component {

    render() {
    return (
        <Switch>
            <Route path={`${this.props.match.url}/silvermanagent-accept`} component={SilvermanAgentAccept}/>
            <Route path={`${this.props.match.url}/silvermanagent-status`} component={SilvermanAgentStatus}/>
            <Route path={`${this.props.match.url}/silvermanagent-view/:id?`} component={DecodeUrl}/>
            <Route path={`${this.props.match.url}/silvermanagent-create/:token?/:id?/:lang?`} component={silvermanAgentCreate}/>
        </Switch>
    )
    }
}

export default SilvermanAgent;