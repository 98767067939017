import '../styles/dailyJournal.scss';
import React, { Component } from 'react';

import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import TransactionChannel from "../../libs/transactionChannel";
import _ from 'lodash';
import i18n from 'i18next'
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class DailyJournalAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allAccountRecordGroupDaily: {},
            languages: "th",
        };

    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
        })
        i18n.changeLanguage(this.props.languages);
        this.groupRecord()
    }

    gedDPDF(data) {
        setTimeout(() => {
            let page = $('.print_' + data);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150 - signature - head - detail - invoice;
            let diff = page_height;

            $('.print_' + data + ' table').css({ borderBottom: "1px solid" });
            if (diff < 400) {
                $('.print_' + data + ' tbody tr:last-child td').css({ paddingBottom: diff });
            } else {
                if (diff > 500) {
                    $('.print_' + data + ' tbody tr:last-child td').css({ paddingBottom: diff });
                } else {
                    $('.print_' + data + ' tbody tr:last-child td').css({ paddingBottom: diff });
                }
            }
        }, 200);
    }

    //ใช้ชั่วคราวเพราะยังไม่ได้แก้ที่ backed ให้ส่งข้อมูล transaction มาเป็น group
    async groupRecord() {
        let account = [];
        let allAccount = {};

        await _.orderBy(this.props.query.allAccountRecordGroup.edges, 'node.refNumber', 'asc').map((allAccountRecordGroup, index) => {
            let allAccountGroup = { allAccountRecordGroup: { edges: [allAccountRecordGroup] } }
            //allBankAccountTransaction
            let bank = [];
            this.props.query.allBankAccountTransaction.edges.length > 0 ? this.props.query.allBankAccountTransaction.edges.map((allBankAccountTransaction) => {
                if (allAccountRecordGroup.node.refTransaction === allBankAccountTransaction.node.refTransaction) {
                    bank.push(allBankAccountTransaction)
                    allAccountGroup = { ...allAccountGroup, allBankAccountTransaction: { edges: bank } }
                } else {
                    allAccountGroup = { ...allAccountGroup, allBankAccountTransaction: { edges: bank } }
                }
                return allBankAccountTransaction
            }) : allAccountGroup = { ...allAccountGroup, allBankAccountTransaction: { edges: [] } }

            //allPaymentGatewayMethod
            let paymentGateway = [];
            this.props.query.allPaymentGatewayMethod.edges.length > 0 ? this.props.query.allPaymentGatewayMethod.edges.map((allPaymentGatewayMethod) => {
                if (allAccountRecordGroup.node.refTransaction === allPaymentGatewayMethod.node.receive.docNumber) {
                    paymentGateway.push(allPaymentGatewayMethod)
                    allAccountGroup = { ...allAccountGroup, allPaymentGatewayMethod: { edges: paymentGateway } }
                } else {
                    allAccountGroup = { ...allAccountGroup, allPaymentGatewayMethod: { edges: paymentGateway } }
                }
                return allPaymentGatewayMethod
            }) : allAccountGroup = { ...allAccountGroup, allPaymentGatewayMethod: { edges: [] } }

            let credit = [];
            this.props.query.allCreditCardTransaction.edges.length > 0 ? this.props.query.allCreditCardTransaction.edges.map((allCreditCardTransaction) => {
                if (allAccountRecordGroup.node.refTransaction === allCreditCardTransaction.node.refNumber) {
                    credit.push(allCreditCardTransaction)
                    allAccountGroup = { ...allAccountGroup, allCreditCardTransaction: { edges: credit } }
                } else {
                    allAccountGroup = { ...allAccountGroup, allCreditCardTransaction: { edges: credit } }
                }
                return allCreditCardTransaction
            }) : allAccountGroup = { ...allAccountGroup, allCreditCardTransaction: { edges: [] } }

            //cashTransactionViewer
            let cash = [];
            this.props.query.cashTransactionViewer.allCashTransaction.edges.length > 0 ? this.props.query.cashTransactionViewer.allCashTransaction.edges.map((allCashTransaction) => {
                if (allAccountRecordGroup.node.refTransaction === allCashTransaction.node.refNumber) {
                    cash.push(allCashTransaction)
                    allAccountGroup = { ...allAccountGroup, cashTransactionViewer: { allCashTransaction: { edges: cash } } }
                } else {
                    allAccountGroup = { ...allAccountGroup, cashTransactionViewer: { allCashTransaction: { edges: cash } } }
                }
                return allCashTransaction
            }) : allAccountGroup = { ...allAccountGroup, cashTransactionViewer: { allCashTransaction: { edges: [] } } }

            //chequeDepositViewer
            let chequeDeposit = [];
            this.props.query.chequeDepositViewer.allChequeDeposit.edges.length > 0 ? this.props.query.chequeDepositViewer.allChequeDeposit.edges.map((allChequeDeposit) => {
                if (allAccountRecordGroup.node.refTransaction === allChequeDeposit.node.refNumber) {
                    chequeDeposit.push(allChequeDeposit)
                    allAccountGroup = { ...allAccountGroup, chequeDepositViewer: { allChequeDeposit: { edges: chequeDeposit } } }
                } else {
                    allAccountGroup = { ...allAccountGroup, chequeDepositViewer: { allChequeDeposit: { edges: chequeDeposit } } }
                }
                return allChequeDeposit
            }) : allAccountGroup = { ...allAccountGroup, chequeDepositViewer: { allChequeDeposit: { edges: [] } } }

            //chequeTransactionViewer
            let chequeTransaction = [];
            this.props.query.chequeTransactionViewer.allChequeTransaction.edges.length > 0 ? this.props.query.chequeTransactionViewer.allChequeTransaction.edges.map((allChequeTransaction) => {
                if (allAccountRecordGroup.node.refTransaction === allChequeTransaction.node.refNumber) {
                    chequeTransaction.push(allChequeTransaction)
                    allAccountGroup = { ...allAccountGroup, chequeTransactionViewer: { allChequeTransaction: { edges: chequeTransaction } } }
                } else {
                    allAccountGroup = { ...allAccountGroup, chequeTransactionViewer: { allChequeTransaction: { edges: chequeTransaction } } }
                }
                return allChequeTransaction
            }) : allAccountGroup = { ...allAccountGroup, chequeTransactionViewer: { allChequeTransaction: { edges: [] } } }

            //allPaymentChannelTransaction
            let allPaymentChannelTransaction = [];
            this.props.query.paymentChannelViewer.allPaymentChannel.edges.length > 0 ? this.props.query.paymentChannelViewer.allPaymentChannel.edges.map((allPaymentChannel) => {
                if (allAccountRecordGroup.node.refTransaction === allPaymentChannel.node.payRecordGroup.docNumber) {
                    allPaymentChannelTransaction.push(allPaymentChannel)
                    allAccountGroup = { ...allAccountGroup, paymentChannelViewer: { allPaymentChannel: { edges: allPaymentChannelTransaction } } }
                } else {
                    allAccountGroup = { ...allAccountGroup, paymentChannelViewer: { allPaymentChannel: { edges: allPaymentChannelTransaction } } }
                }
                return allPaymentChannel
            }) : allAccountGroup = { ...allAccountGroup, paymentChannelViewer: { allPaymentChannel: { edges: [] } } }

            //allOtherExpensePaymentChannelTransaction
            let allOtherExpensePaymentChannelTransaction = [];
            this.props.query.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel.edges.length > 0 ? this.props.query.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel.edges.map((allOtherExpensePaymentChannel) => {
                if (allAccountRecordGroup.node.refTransaction === allOtherExpensePaymentChannel.node.otherExpense.docNumber) {
                    allOtherExpensePaymentChannelTransaction.push(allOtherExpensePaymentChannel)
                    allAccountGroup = { ...allAccountGroup, otherExpensePaymentChannelViewer: { allOtherExpensePaymentChannel: { edges: allOtherExpensePaymentChannelTransaction } } }
                } else {
                    allAccountGroup = { ...allAccountGroup, otherExpensePaymentChannelViewer: { allOtherExpensePaymentChannel: { edges: allOtherExpensePaymentChannelTransaction } } }
                }
                return allOtherExpensePaymentChannel
            }) : allAccountGroup = { ...allAccountGroup, otherExpensePaymentChannelViewer: { allOtherExpensePaymentChannel: { edges: [] } } }

            //allAdvancePettyCashChannelTransaction
            let allAdvancePettyCashChannelTransaction = [];
            this.props.query.allAdvancePettyCashChannel.edges.length > 0 ? this.props.query.allAdvancePettyCashChannel.edges.map((allAdvancePettyCashChannel) => {
                if (allAccountRecordGroup.node.refTransaction === allAdvancePettyCashChannel.node.advance.docNumber) {
                    allAdvancePettyCashChannelTransaction.push(allAdvancePettyCashChannel)
                    allAccountGroup = { ...allAccountGroup, allAdvancePettyCashChannel: { edges: allAdvancePettyCashChannelTransaction } } 
                } else {
                    allAccountGroup = { ...allAccountGroup, allAdvancePettyCashChannel: { edges: allAdvancePettyCashChannelTransaction } } 
                }
                return allAdvancePettyCashChannelTransaction
            }) : allAccountGroup = { ...allAccountGroup, allAdvancePettyCashChannel: { edges: [] } } 
            //allClearAdvancePettyCashChannelTransaction
            let allClearAdvancePettyCashChannelTransaction = [];
            this.props.query.allClearAdvancePettyCashChannel.edges.length > 0 ? this.props.query.allClearAdvancePettyCashChannel.edges.map((allClearAdvancePettyCashChannel) => {
                if (allAccountRecordGroup.node.refTransaction === allClearAdvancePettyCashChannel.node.clearAdvance.docNumber) {
                    allClearAdvancePettyCashChannelTransaction.push(allClearAdvancePettyCashChannel)
                    allAccountGroup = { ...allAccountGroup, allClearAdvancePettyCashChannel: { edges: allClearAdvancePettyCashChannelTransaction } } 
                } else {
                    allAccountGroup = { ...allAccountGroup, allClearAdvancePettyCashChannel: { edges: allClearAdvancePettyCashChannelTransaction } } 
                }
                return allClearAdvancePettyCashChannel
            }) : allAccountGroup = { ...allAccountGroup, allClearAdvancePettyCashChannel: { edges: [] } } 

            //allClearAdvancePettyCashChannelTransaction
            let allClearGuaranteeMoneyReceivedPettyCashChannelTransaction = [];
            this.props.query.allClearGuaranteeMoneyReceivedPettyCashChannel.edges.length > 0 ? this.props.query.allClearGuaranteeMoneyReceivedPettyCashChannel.edges.map((allClearGuaranteeMoneyReceivedPettyCashChannel) => {
                if (allAccountRecordGroup.node.refTransaction === allClearGuaranteeMoneyReceivedPettyCashChannel.node.clearGuaranteeMoneyReceived.docNumber) {
                    allClearGuaranteeMoneyReceivedPettyCashChannelTransaction.push(allClearGuaranteeMoneyReceivedPettyCashChannel)
                    allAccountGroup = { ...allAccountGroup, allClearGuaranteeMoneyReceivedPettyCashChannel: { edges: allClearGuaranteeMoneyReceivedPettyCashChannelTransaction } } 
                } else {
                    allAccountGroup = { ...allAccountGroup, allClearGuaranteeMoneyReceivedPettyCashChannel: { edges: allClearGuaranteeMoneyReceivedPettyCashChannelTransaction } } 
                }
                return allClearGuaranteeMoneyReceivedPettyCashChannel
            }) : allAccountGroup = { ...allAccountGroup, allClearGuaranteeMoneyReceivedPettyCashChannel: { edges: [] } } 

            account.push(allAccountGroup)
            allAccount = { edges: account }
            return allAccountRecordGroup
        }
        )
        this.setState({ allAccountRecordGroupDaily: allAccount })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.allAccountRecordGroupDaily.edges && this.state.allAccountRecordGroupDaily.edges.length > 0 && this.state.allAccountRecordGroupDaily.edges.map((allRecordGroup, index) => {
                    this.genDocPDF = this.gedDPDF(index)
                    return (
                        <div className={`print-daily-journal-a4 pt-6 print_${index}`}
                            key={index} style={{ height: 'auto', position: 'relative' }}>
                            <div className="subpage" style={{ overflow: 'hidden' }}>
                                <div className="head">
                                    <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "1fr 5fr" }}>
                                        <div style={{ minHeight: 100 }}>
                                            <img src={this.props.query.selfProject.logo} alt="silverman" />
                                        </div>
                                        <div>
                                            <span className="text-center" style={{ position: "absolute" }}>
                                                <div><strong style={{ fontSize: 12 }}>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name): this.props.query.selfProject.name}</strong></div>
                                                <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                                <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                            </span>
                                        </div>

                                    </div>
                                </div>

                                <div className="detail">
                                    <div className="customer-detail">
                                        {
                                            allRecordGroup.allAccountRecordGroup.edges.length > 0 &&
                                                allRecordGroup.allAccountRecordGroup.edges[0].node.category === "PURCHASE" ?
                                                <strong style={{ fontSize: 12 }}>สมุดรายวันซื้อ (เจ้าหนี้)</strong>
                                                : allRecordGroup.allAccountRecordGroup.edges[0].node.category === "SALES" ?
                                                    <strong style={{ fontSize: 12 }}>สมุดรายวันขาย (เจ้าของร่วม/สมาชิก, ลูกหนี้)</strong>
                                                    : allRecordGroup.allAccountRecordGroup.edges[0].node.category === "PAYMENT" ?
                                                        <strong style={{ fontSize: 12 }}>สมุดรายวันจ่าย</strong>
                                                        : allRecordGroup.allAccountRecordGroup.edges[0].node.category === "RECEIVE" ?
                                                            <strong style={{ fontSize: 12 }}>สมุดรายวันรับ</strong>
                                                            : allRecordGroup.allAccountRecordGroup.edges[0].node.category === "GENERAL" &&
                                                            <strong style={{ fontSize: 12 }}>สมุดรายวันทั่วไป</strong>
                                        }
                                        <br />
                                        <div>รายละเอียด {allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.name}</div>
                                    </div>
                                    <div className="document-detail">
                                        เลขที่เอกสาร&emsp;{allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.refNumber}<br />
                                        {allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.refTransaction !== allRecordGroup.allAccountRecordGroup.edges[0].node.refNumber &&
                                            <React.Fragment>
                                                เลขที่อ้างอิง &emsp;{allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.refTransaction}<br />
                                            </React.Fragment>
                                        }
                                        วันที่&emsp;&nbsp;{format(allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                                    </div>
                                </div>

                                <div className="invoice-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width={80} className="text-center">
                                                    <strong>รหัสบัญชี</strong>
                                                </th>
                                                <th className="text-center">
                                                    <strong>ชื่อบัญชี</strong>
                                                </th>
                                                <th className="text-center">
                                                    <strong>คำอธิบาย</strong>
                                                </th>
                                                <th width={90} className="text-center">
                                                    <strong>เดบิต</strong>
                                                </th>
                                                <th width={90} className="text-center">
                                                    <strong>เครดิต</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allRecordGroup.allAccountRecordGroup.edges.length > 0 &&
                                                <React.Fragment>
                                                    {allRecordGroup.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((account_reccord) =>
                                                        account_reccord.node.debit > 0 &&
                                                        <tr key={account_reccord.node.id}>
                                                            <td className="text-center">{account_reccord.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                            <td>{account_reccord.node.chartOfAccountCode.name}</td>
                                                            {/*<td>{account_reccord.node.name}</td>*/}
                                                            <td>{
                                                                allRecordGroup.allAccountRecordGroup.edges.length > 0
                                                                    && allRecordGroup.allAccountRecordGroup.edges[0].node.refTransaction ?
                                                                    account_reccord.node.name :
                                                                    account_reccord.node.name
                                                            }</td>
                                                            <td className="text-right">{numberWithComma(account_reccord.node.debit)}</td>
                                                            <td className="text-right">{numberWithComma(account_reccord.node.credit)}</td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            }

                                            {allRecordGroup.allAccountRecordGroup.edges.length > 0 &&
                                                <React.Fragment>
                                                    {allRecordGroup.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((account_record) =>
                                                        account_record.node.credit > 0 &&
                                                        <tr key={account_record.node.id}>
                                                            <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                            <td>{account_record.node.chartOfAccountCode.name}</td>
                                                            {/*<td>{account_reccord.node.name}</td>*/}
                                                            <td>{allRecordGroup.allAccountRecordGroup.edges.length > 0 && account_record.node.name}</td>
                                                            <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                            <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className="text-center" style={{ borderRight: "none" }}>
                                                    <strong>รวม</strong>
                                                </td>
                                                <td colSpan={2} className="text-left" style={{ borderLeft: "none" }}>
                                                    <strong style={{ fontSize: 12 }}>                                                        
                                                        {i18n.languages[0] === 'th' ? 
                                                        (ThaiBaht(allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.totalDebit)):
                                                        (ConvertNumberToTextEng(allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.totalDebit))}    
                                                    </strong>
                                                </td>
                                                <td className="text-right">
                                                    <strong style={{ fontSize: 12 }}>{numberWithComma(allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.totalDebit)}</strong>
                                                </td>
                                                <td className="text-right">
                                                    <strong style={{ fontSize: 12 }}>{numberWithComma(allRecordGroup.allAccountRecordGroup.edges.length > 0 && allRecordGroup.allAccountRecordGroup.edges[0].node.totalCredit)}</strong>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    {/*<div className="remark">*/}
                                    {/*    หมายเหตุ/Remarks: <br/>*/}

                                    {/*</div>*/}
                                </div>
                                <div className="footer" style={{ position: 'absolute', bottom: 0 }}>
                                    {/*ช่องทางชำระเงิน*/}
                                    <TransactionChannel
                                        allAccountRecordGroup={allRecordGroup.allAccountRecordGroup}
                                        cashTransactionViewer={allRecordGroup.cashTransactionViewer}
                                        chequeDepositViewer={allRecordGroup.chequeDepositViewer}
                                        chequeTransactionViewer={allRecordGroup.chequeTransactionViewer}
                                        allBankAccountTransaction={allRecordGroup.allBankAccountTransaction}
                                        allCreditCardTransaction={allRecordGroup.allCreditCardTransaction}
                                        allPaymentChannel={allRecordGroup.paymentChannelViewer.allPaymentChannel}
                                        allPaymentGatewayMethod={allRecordGroup.allPaymentGatewayMethod}
                                        allOtherExpensePaymentChannel={allRecordGroup.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel}
                                        allAdvancePettyCashChannel={allRecordGroup.allAdvancePettyCashChannel}
                                        allClearAdvancePettyCashChannel={allRecordGroup.allClearAdvancePettyCashChannel}
                                        allClearGuaranteeMoneyReceivedPettyCashChannel={allRecordGroup.allClearGuaranteeMoneyReceivedPettyCashChannel}
                                    />
                                    <div className="pt-5">
                                        <span className="box-left">
                                            ..................................................<br />
                                            ผู้จัดทำ
                                        </span>
                                        <span className="box-left">
                                            ..................................................<br />
                                            ผู้ตรวจสอบ
                                        </span>
                                        <span className="box-left">
                                            ..................................................<br />
                                            ผู้อนุมัติ
                                        </span>
                                    </div>
                                    <div>
                                        <span className="box-left">
                                            วันที่ ................................
                                        </span>
                                        <span className="box-left">
                                            วันที่ ................................
                                        </span>
                                        <span className="box-left">
                                            วันที่ ................................
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }



                )
                }
            </React.Fragment>

        );
    }
}

export default DailyJournalAll;
