import getApiRoot from "../../libs/getAPIRoot";
import { format } from "date-fns";
import axios from "axios";

async function getViewRevenueContract(token,search,obligation,time_period,indexFirst,indexLast){
    let timePeriod = time_period === '' ? '' : format(time_period,'YYYY-MM-DD')
    return axios.get(`${getApiRoot()}ownership/obligation/view`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            search: search,
            obligation : obligation,
            time_period : timePeriod,
            indexFirst : indexFirst,
            indexLast : indexLast
        },
    });
}

async function getObligationRoom(token,search,obligation,productAndServiceID = ''){
    return axios.get(`${getApiRoot()}ownership/obligation/choose`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            search: search,
            obligation : obligation,
            productAndServiceID : productAndServiceID
        },
    });
}

async function getService(token){
    return axios.get(`${getApiRoot()}ownership/obligation/service`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
    });
}

// freebies
async function createFreebies(data,token){
    let variables = {
        "obligationDeveloperData":  data
    };
    let header = {
        authorization: `JWT ${token}`,
        "Content-Type": "application/json",
    };

    return axios.post(`${getApiRoot()}ownership/obligation/create/developer/`, variables, {
        headers: header,
    });
}

// freebies
async function getFreebies(token,residentialID){
    return axios.get(`${getApiRoot()}ownership/obligation/create/developer`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            residentialID: residentialID,
        },
    });
}

//condition
async function getCondition(token,residentialID){
    return axios.get(`${getApiRoot()}ownership/obligation/create/tenant`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            residentialID: residentialID,
        },
    });
}


//condition
async function createCondition(data,token){
    let variables = {
        "obligationTenantData":  data
    };
    let header = {
        authorization: `JWT ${token}`,
        "Content-Type": "application/json",
    };

    return axios.post(`${getApiRoot()}ownership/obligation/create/tenant/`, variables, {
        headers: header,
    });
}

async function createFreebiesItem(data, token){
    let variables = {
        "ownerGift":  data
    };
    let header = {
        authorization: `JWT ${token}`,
        "Content-Type": "application/json",
    };

    return axios.post(`${getApiRoot()}ownership/obligation/create/gift/`, variables, {
        headers: header,
    });
}

async function getFreebiesItem(token,residentialID){
    return axios.get(`${getApiRoot()}ownership/obligation/create/gift/`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            residentialID: residentialID,
        },
    });
}

async function editFreebiesItem(data, token, residentialID){
    let variables = {
        "ownerGift":  data
    };
    let header = {
        authorization: `JWT ${token}`,
        "Content-Type": "application/json",
    };

    return axios.post(`${getApiRoot()}ownership/obligation/create/gift/`, variables, {
        headers: header,
        params: {
            residentialID: residentialID,
        },
    });
}

export default {getViewRevenueContract , getObligationRoom , getService , createCondition , createFreebies , createFreebiesItem , getFreebiesItem , editFreebiesItem , getFreebies , getCondition}