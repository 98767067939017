/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type setupChartofaccountQueryVariables = {||};
export type setupChartofaccountQueryResponse = {|
  +allChartOfAccountSettingUsing: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +nameTitle: string,
        +nameTitleTh: string,
        +chartOfAccount: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |},
      |}
    |}>
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +nameEn: ?string,
          +chartOfAccountCode: string,
          +totalHistoricalDebit: ?number,
          +totalHistoricalCredit: ?number,
        |}
      |}>,
    |}
  |},
|};
export type setupChartofaccountQuery = {|
  variables: setupChartofaccountQueryVariables,
  response: setupChartofaccountQueryResponse,
|};
*/


/*
query setupChartofaccountQuery {
  allChartOfAccountSettingUsing {
    edges {
      node {
        id
        nameTitle
        nameTitleTh
        chartOfAccount {
          id
          name
          chartOfAccountCode
        }
      }
    }
  }
  viewer {
    allChartOfAccount {
      totalCount
      edges {
        node {
          id
          name
          nameEn
          chartOfAccountCode
          totalHistoricalDebit
          totalHistoricalCredit
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountSettingUsingNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccountSettingUsing",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountSettingUsingNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountSettingUsingNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameTitleTh",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChartOfAccountNode",
              "kind": "LinkedField",
              "name": "chartOfAccount",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameEn",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalCredit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "setupChartofaccountQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "setupChartofaccountQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67094cdad2ae2c451816267aba83e501",
    "id": null,
    "metadata": {},
    "name": "setupChartofaccountQuery",
    "operationKind": "query",
    "text": "query setupChartofaccountQuery {\n  allChartOfAccountSettingUsing {\n    edges {\n      node {\n        id\n        nameTitle\n        nameTitleTh\n        chartOfAccount {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n  }\n  viewer {\n    allChartOfAccount {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          nameEn\n          chartOfAccountCode\n          totalHistoricalDebit\n          totalHistoricalCredit\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a2cee0e385318579ff1daa9a98557670';

module.exports = node;
