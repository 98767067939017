import React, { Component, forwardRef } from 'react'
import DatePicker from "react-datepicker";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import "../styles/reportRegister.scss"
import _ from "lodash"
import ReportRegitterDetailTable from './reportRegitterDetailTable';
import Select from 'react-select';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import QueryReportRegister from './queryGraphql/queryReportRegister';
import Loading from '../../../libs/loading'
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import AccountingTopMenuNavigation from '../../accountingTopMenuNavigation';
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import BackButtonIcon from '../../../components/BackBtn/indexBack';

const query = graphql`
  query reportRegisterDetailsQuery($yearId: String){
    viewer {
      allChartOfAccount(getHistoricalValue: true) {
        totalCount
        edges {
            node {
                id name nameEn chartOfAccountCode totalHistoricalDebit totalHistoricalCredit
            }
        }
      }
    }
    oneYearBudget(yearId: $yearId) {
      edges {
        node {
          years
          startDate
          endDate
          project{
            name
          }
        }
      }
    }
  }
`

// const customStyles = {
//   control: (style) => ({
//     ...style,
//   }),
//   option: (style, { isFocused, isSelected }) => {
//     return {
//       ...style,
//       backgroundColor: isSelected ? "#E6F2FE" : "#FFFFFF",
//       // color: isSelected ? "#0382FA" : "#000000",
//       color: isFocused && "#0382FA",
//       ':hover': {
//         backgroundColor: "#E6F2FE",
//       }
//     }
//   }
// };

class reportRegisterDetails extends Component {

  constructor() {
    super()
    this.state = {
      date: new Date(),
      textListShow: [],
      textSelect: [],
      queryDate: [],
      queryDataReport: null,
      loadFetch: false,
      loadingSelect: false,
    }
  }

  componentWillMount() {
    this.queryDataSelect()
  }

  queryDataSelect() {
    let values = { id: "", label: "", value: "" }
    let inputData = []
    this.setState({ loadingSelect: true })
    fetchQuery(environment, query, { yearId: this.props.match.params.id }).then((data) => {
      _.forIn(data.viewer.allChartOfAccount.edges, (o) => {
        if (o.node.chartOfAccountCode.substring(0, 1) === "4" || o.node.chartOfAccountCode.substring(0, 1) === "5") {
          values = _.cloneDeep(values)
          _.set(values, "id", o.node.id)
          _.set(values, "label", `${o.node.chartOfAccountCode} ${o.node.name}`)
          _.set(values, "value", `${o.node.chartOfAccountCode} ${o.node.name}`)

          inputData.push(values)
        }
      })
      this.setState({ textListShow: inputData, queryDate: data.oneYearBudget?.edges[0]?.node, loadingSelect: false })
    })
  }

  onChangeWordSelect = async (text) => {
    let value = []
    await _.forIn(text, (textSelected) => {
      value.push({
        id: textSelected.id,
        value: textSelected.value
      })
    })
    await this.setState({ textSelect: value });
  }

  handleRemoveTextSelect = (textRemove) => {
    let value = _.filter(this.state.textSelect, (o) => o.value !== textRemove)
    this.setState({ textSelect: value });
  }

  handleSearch = () => {
    let chartOfAccountId = []
    _.forIn(this.state.textSelect, (o) => {
      chartOfAccountId.push(o.id)
    })
    if (chartOfAccountId.length > 0) {
      this.setState({ loadFetch: true })
      fetchQuery(
        environment,
        QueryReportRegister,
        {
          yearId: this.props.match.params.id,
          chartOfAccountId_In: chartOfAccountId,
          group_IssuedDate_Gte: this.state.queryDate.startDate,
          group_IssuedDate_Lte: format(this.state.date, "YYYY-MM-DD")
        }
      ).then((res) => {
        window.localStorage.setItem("reportRegisterPaper", JSON.stringify(
          { id: this.props.match.params.id, queryDate: this.state.queryDate, queryDataReport: res.allChartOfAccountBudget.edges }
        ))

        this.setState({ loadFetch: false, queryDataReport: res.allChartOfAccountBudget })
      }).catch(() => {
        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning').then(() => { this.setState({ loadFetch: false }) })
      })
    }
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box booking-form p-4 mt-3" id="reportRegister" >
                <BackButtonIcon
                  LinkBack={"/accounting/budget/reportRegister/list"}
                  LinkText={i18next.t("menuListBudget:Budget Usage Control Register")}
                  boxHtmlText={
                    <div className="col-6 d-flex align-items-center">
                      <h5>{i18next.t("AllFilter:to")}</h5>
                      <div className="customDatePickerWidth col-6 ml-3">
                        <DatePicker
                          className="form-control colorborder"
                          selected={this.state.date}
                          onChange={(date) => this.setState({ date: date })}
                          dateFormat="dd/MM/yyyy"
                          customInput={<ButtonDate date />}
                        />
                      </div>
                    </div>
                  }
                />

                {this.state.loadingSelect ? <Loading />
                  : <React.Fragment>
                    <div className="row mt-3">
                      <div className="col-5">
                        <div className="mb-2">{i18next.t("reportRegisterDetails:Select Budget Items")}</div>
                        <Select
                          hideSelectedOptions={false}
                          isClearable={false}
                          options={this.state.textListShow}
                          isMulti
                          closeMenuOnSelect={false}
                          placeholder=""
                          // styles={customStyles}
                          value={this.state.textSelect}
                          onChange={(value) => this.onChangeWordSelect(value)}
                        />
                      </div>
                      <div className="col-5">
                        <div className="mb-2">{i18next.t("reportRegisterDetails:Selected Budget Items")}</div>
                        <div
                          className="slide"
                        >
                          <div style={{ padding: "10px 20px" }}>
                            {_.map(this.state.textSelect, (item, index) => {
                              return (
                                <div className="item mb-3 show" key={index}>
                                  <div className="title-detail">{item.value}</div>
                                  <img
                                    className="show-icon"
                                    src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'}
                                    alt="cancel-icon"
                                    style={{ width: "10px", height: "10px" }}
                                    onClick={() => this.handleRemoveTextSelect(item.value)} />
                                </div>
                              )
                            })
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <button
                          type="button"
                          className="btn button-search"
                          style={{ height: 48, marginTop: "28px" }}
                          onClick={this.handleSearch}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                }
                {this.state.loadFetch
                  ? <Loading />
                  : this.state.queryDataReport && this.state.queryDataReport.edges.length > 0
                    ? <React.Fragment>
                      <div className="my-4" style={{ width: "100%", height: "1px", background: "#E2E2E2" }} />
                      <ReportRegitterDetailTable
                        queryDataReport={this.state.queryDataReport}
                        queryDate={this.state.queryDate}
                        date={this.state.date}
                        yearId={this.props.match.params.id}
                      />
                    </React.Fragment>
                    : ""
                }
              </div>

            }
          </Translation>
        </div>
      </Wrapper>
    )
  }
}


const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
  <button
    type="button"
    className="date form-control mt-2 booking-form"
    style={{ width: "100%" }}
    onClick={onClick}
    ref={ref}
    disabled={disabled}
  >
    {value}
    {date && (
      <div
        className="calendars"
        style={{
          position: "absolute",
          right: 0,
          marginRight: 8,
        }}
      >
        <img
          className="icon"
          src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
          alt="calendars"
        />
      </div>
    )}
  </button>
));

export default reportRegisterDetails;
