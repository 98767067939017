import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import BackButtonIcon from "../../components/BackBtn/indexBack";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import { graphql } from "babel-plugin-relay/macro";
import UploadFiles from '../../components/FucntionLib/uploadFile';
import _ from "lodash";
import { Link, Redirect } from "react-router-dom";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import environment from "../../env/environment";

const mutation = graphql`
    mutation collectionLawFrimUploadFileMutation ($input: UploadDocumentCollectionLawFirmInput!) {
        uploadDocumentCollectionLawFirm(input:$input){
            ok
            massage
        }
    }
`;

const CollectionLawFrimUploadFile = (props) => {
    const [detaTable, setDataTable] = useState(props?.location?.state?.FileData ?? [
        { fileName: '', fileUpload: '', viewPtath: '' }
    ])

    const [uploadFile, setUpload] = useState([])
    const [page, setPage] = useState(false)

    useEffect(() => {
        setDataTable([{ fileName: '', fileUpload: '', viewPtath: '' }])
    }, [props?.location?.state?.FileData])

    // บันทึก ข้อมูลการอัพโหลด
    const onSumbit = () => {
        let oldFile = _.cloneDeep(props.location.state.FileData)

        oldFile = oldFile?.map((ex)=> ({
            fileName: ex.node.fileName,
            fileUpload: ex.node.fileUpload 
        }))

        let oldData = [...oldFile, ...detaTable]
        let variables = {
            "input": {
                "jsonObject": {
                    "Id": props.match.params.id ?? "",
                    "DocumentList": oldData
                }
            }
        }

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response.uploadDocumentCollectionLawFirm.ok) {
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success');
                        setPage(true)
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.uploadDocumentCollectionLawFirm.massage, 'warning');
                    }
                },
                onError: (err) => {
                    Swal.fire('Error!', i18next.t("Allaction:Please try again"), 'error');
                },
            },
        )
    }

    // Callback จาก Function Upload
    const onUpload = (file, index) => {

        let uploadSet = _.cloneDeep(file)
        let laastItem = uploadSet.slice(-1)[0]

        let dataSet = _.cloneDeep(detaTable)
        _.set(dataSet, `[${index}].fileUpload`, laastItem.fileUpload)
        _.set(dataSet, `[${index}].viewPtath`, laastItem.viewPtath)
        setDataTable(dataSet)
        setUpload(uploadSet)
    }

    // ข้อมูลมีการเปลี่ยนแปลง
    const onChangeData = (e) => {
        let event = _.cloneDeep(e)
        let { name, value } = event.target
        setDataTable(_.set(_.cloneDeep(detaTable), name, value))
    }

    // ลบไฟล์
    const dateFile = (index, items) => {
        let dataSet = _.cloneDeep(detaTable)
        let uploadSet = _.cloneDeep(uploadFile)
        _.set(dataSet, `[${index}].fileUpload`, "")
        uploadSet.splice(index, 1)
        setDataTable(dataSet)
    }

    //เพิ่ม Row ไฟล์แนบ
    const addRoww = () => {
        let dataSet = _.cloneDeep(detaTable)
        dataSet.push({ fileName: '', fileUpload: '', viewPtath: '' })
        setDataTable(dataSet)
    }

    if (page) {
        return <Redirect to={"/accounting/collection-law-firm/detail/" + props.match.params.id} />
    }

    return (
        <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent>
                <AccountingTopMenuNavigation mini={true} />
                <Translation>
                    {(t) =>
                        <div className="container-fluid box colorUse mt-5">
                            <BackButtonIcon
                                LinkBack={props?.location?.state?.linkBack ? props?.location?.state?.linkBack : "/accounting/collection-law-firm/list/all"}
                                LinkText={`${props?.location?.state?.docNumber} ${i18next.t('collectionLawFirm:Attached documents')}`}
                            />

                            <div className="row colorUse claim mt-5">
                                <div className="col-12">
                                    <div className="card card-body">

                                        {
                                            detaTable?.map((items, index) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label className="fs-18 fw-400">{t('collectionLawFirm:Attach document no.')} {props?.location?.state?.totalCount + index + 1}</label>
                                                            <div className="border-bottom" />
                                                        </div>

                                                        <div className="col-12 mt-3"></div>

                                                        <div className="col-8">
                                                            <label className="fs-18 fw-400">{t('collectionLawFirm:Document Name')}</label>
                                                            <input type={'text'} name={`[${index}].fileName`} onKeyUp={onChangeData} onChange={onChangeData} placeholder={t('collectionLawFirm:Document Name')} className="form-control h48 mt-2" />
                                                        </div>
                                                        {/* <div className="col-4">
                                                            <label className="fs-18 fw-400">จำนวนเอกสาร (แผ่น)</label>
                                                            <input type={'text'} name={`[${index}].filePage`} onKeyUp={onChangeData} onChange={onChangeData} placeholder="จำนวนเอกสาร (แผ่น)" className="form-control h48 mt-2" />
                                                        </div> */}
                                                        <div className="col-4 hoverShowa create">
                                                            <label className="fs-18 fw-400">{t('collectionLawFirm:Attached documents')}</label>
                                                            <label className="tooltipsSpan mb-0"> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={18} />
                                                                <span className='tooltiptext text_FFFFFF bg-000000 w350 text-left' >{i18next.t("collectionLawFirm:only PDF ,DOC ,PNG, JPG and file 10 MB")}</span>
                                                            </label>
                                                            <br />

                                                            <UploadFiles
                                                                fileList={uploadFile}
                                                                accept={'application/.jpeg,.png,.jpg,.pdf'}
                                                                className={"mt--6 h48"}
                                                                imgHeight={"h18"}
                                                                onUpload={(file) => onUpload(file, index)}
                                                                pathname={"collectionLawFrim"}
                                                            />
                                                            {
                                                                items?.fileUpload ?
                                                                    <label className="floatRight fs-16 mt-1 w200" key={index}>
                                                                        <Link
                                                                            to="route"
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                window.open(
                                                                                    typeof items.viewPtath === "string"
                                                                                        ? items.viewPtath
                                                                                        : items.fileShow
                                                                                )
                                                                            }}
                                                                            target="blank">{"Upload:::" + index + 1}</Link>
                                                                        &nbsp;&nbsp; <label onClick={() => dateFile(index, items)}>X</label>
                                                                    </label> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>

                                    <label className="floatLeft mt-3">
                                        <button className="btn bg-1567B4 h48 mr-3" onClick={addRoww}>+ {i18next.t("collectionLawFirm:Add Attach document")}</button>
                                    </label>

                                    <label className="floatRight mt-3">
                                        <Link to={props?.location?.state?.linkBack ? props?.location?.state?.linkBack : "/accounting/collection-law-firm/list/all"}>
                                            <button className="btn bg-E9ECEF h48 mr-3">{i18next.t("Allaction:cancel")}</button>
                                        </Link>
                                        <button className="btn bg1567B4-textfff h48" onClick={onSumbit}>{i18next.t("Allaction:save")}</button>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                </Translation>
            </WrapperContent>
        </Wrapper>
    );
}
export default CollectionLawFrimUploadFile;