import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import AdvanceList from './advanceList';
import AdvanceCreate from './advanceCreateAndViewForm';
import ClearAdvance from './clearAdvance';
import ClearAdvanceCreate from './clearAdvanceCreate';

class Purchase extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:page`} component={AdvanceList}/>
                <PrivateRoute exact path={`${this.props.match.url}/withdraw/:id?/:ref_transaction?`} component={AdvanceCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/clear-advance/create/:id?`} component={ClearAdvanceCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/clear-advance/edit/:id`} component={ClearAdvance}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Purchase;