import React, { Component } from 'react'
import _ from "lodash"
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next'

export default class selectProduct extends Component {
  render() {
    let selectProductList = []
    selectProductList = _.filter(this.props.allProductList.edges, (o) => this.props.selectedId.includes(o.node.id))

    return (<Translation>{t=>
      <React.Fragment>
        <div className="row mt-4">
          <div className="col-6">
            <h4>{t("stockSelectRoom:Choose item")}</h4>
            <div className="cardTable">
              <div className="bglight">
                <div className="row items">
                  <div className="col-1">
                    <input
                      type="checkbox"
                      checked={this.props.checkAll}
                      onChange={() => this.props.onCheckAll(this.props.allProductList.edges)}
                      />
                  </div>
                  <div className="col-2 text-center textHead">{t("stockSelectRoom:Code")}</div>
                  <div className="col-4 textHead">{t("stockSelectRoom:Item")}</div>
                  <div className="col-4 text-right textHead">
                  {t("stockSelectRoom:Choose item")}
                  </div>
                </div>
              </div>
              <div className="slide">
                {_.map(this.props.allProductList.edges, (item, index) => {
                  let total_stock = 0;

                  if(item.node.stock.edges.length > 0){
                    item.node.stock.edges.forEach((stock, t_index) => {
                        total_stock = total_stock + stock.node.leftInStock;
                      }
                    )
                  }

                  return (
                    <React.Fragment key={index}>
                      <div className="lineTop"/>
                      <div className="row items">
                        <div className="col-1">
                          <input 
                            type="checkbox" 
                            onChange={() => this.props.onCheck(item.node.id)} 
                            checked={this.props.selectedId.includes(item.node.id)}
                            />
                        </div>
                        <div className="col-2 text-center textDetail">{item.node.productCode}</div>
                        <div className="col-4 textDetail">{item.node.name}</div>
                        <div className="col-4 text-right textDetail">{total_stock}</div>
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="col-6">
            <h4>{t("stockSelectRoom:Selected item")}</h4>
            <div className="cardTable">
              <div className="bglight">
                <div className="row items">
                  <div className="col-2 text-center textHead">{t("stockSelectRoom:Code")}</div>
                  <div className="col-8 textHead">{t("stockSelectRoom:Item")}</div>
                  <div className="col-2 textHead"/>
                </div>
              </div>
              <div className="slideSelect">
                {_.map(selectProductList, (itemSelected, indexSelected) => {
                  return (
                    <React.Fragment key={indexSelected}>
                      <div className="lineTop"/>
                      <div className="row items">
                        <div className="col-2 text-center textDetail">{itemSelected.node.productCode}</div>
                        <div className="col-8 textDetail">{itemSelected.node.name}</div>
                        <div className="col-2 text-right textDetail btnClose" type="button" onClick={() => this.props.onCheck(itemSelected.node.id)}>x</div>
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="float-right">
                <span type="button" className="clearList" onClick={() => this.props.handleResetList("selectedId")}>{t("stockSelectRoom:Clear all")}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-4 float-right">
          <div className="col-12">
            <Link to={{
              pathname: selectProductList.length > 0
                ? "/stockandsupplies/stockManagement/createUpdateForm/create/juristic"
                : '/stockandsupplies/stockManagement/list/all',
              state: { typeRequisition: "project", productList: selectProductList }
              }}>
              <button className="btnNext">{t("stockSelectRoom:Next")}</button>
            </Link>
          </div>
          <div style={{marginBottom: 50}}/>
        </div>

      </React.Fragment>
    }</Translation>
    )
  }
}
