/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SurveyStatus = "DELETE" | "DRAFT" | "INACTIVE" | "PUBLISH" | "%future added value";
export type surveyListQueryVariables = {|
  first?: ?number,
  last?: ?number,
  search?: ?string,
|};
export type surveyListQueryResponse = {|
  +allSurvey: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
        +status: SurveyStatus,
        +creator: ?string,
        +juristicPost: ?string,
        +dueDate: ?any,
        +postDate: ?any,
      |}
    |}>,
  |},
  +countSurveyByStatus: ?$ReadOnlyArray<?string>,
|};
export type surveyListQuery = {|
  variables: surveyListQueryVariables,
  response: surveyListQueryResponse,
|};
*/


/*
query surveyListQuery(
  $first: Int
  $last: Int
  $search: String
) {
  allSurvey(first: $first, last: $last, search: $search) {
    totalCount
    edges {
      node {
        id
        title
        status
        creator
        juristicPost
        dueDate
        postDate
      }
    }
  }
  countSurveyByStatus(search: $search)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      (v1/*: any*/)
    ],
    "concreteType": "SurveyNodeConnection",
    "kind": "LinkedField",
    "name": "allSurvey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SurveyNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "juristicPost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v1/*: any*/)
    ],
    "kind": "ScalarField",
    "name": "countSurveyByStatus",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "surveyListQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "surveyListQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "72efc98c89a1fcce8d35c872d63d02c8",
    "id": null,
    "metadata": {},
    "name": "surveyListQuery",
    "operationKind": "query",
    "text": "query surveyListQuery(\n  $first: Int\n  $last: Int\n  $search: String\n) {\n  allSurvey(first: $first, last: $last, search: $search) {\n    totalCount\n    edges {\n      node {\n        id\n        title\n        status\n        creator\n        juristicPost\n        dueDate\n        postDate\n      }\n    }\n  }\n  countSurveyByStatus(search: $search)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d192729c35fe00667c1ca77e191b938';

module.exports = node;
