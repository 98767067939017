import i18next from 'i18next';
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class Navigation extends Component {

    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/collection/first" >
                                {i18next.t("settingCollectionDashBoard:Form of Inquiry Document Number")} 1
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection/second" >
                                {i18next.t("settingCollectionDashBoard:Form of Inquiry Document Number")} 2
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection/third" >
                                {i18next.t("settingCollectionDashBoard:Form of Inquiry Document Number")} 3
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection/legal-process" >
                                {i18next.t("settingCollectionDashBoard:Form of Legal Proceeding While in Progress")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/setting/collection/settings" >
                                {i18next.t("settingCollectionDashBoard:Setting up Inquiry Letters")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;