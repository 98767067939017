import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import getQueryTenant from './graphQL/chooseTenant';
import environment from "../../env/environment";
import {fetchQuery} from "relay-runtime";
import {Redirect} from 'react-router';
import { format } from 'date-fns';
import numberWithComma from "../../libs/numberWithComma"
import ModalChangeCommittee from './modalChangeCommittee';
import { encode } from 'base-64';

const _ = require('lodash');


class UpdateProjectManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loading: false,
            redirectToList: false,
            projectManager : {},
            boardId : '',
            siteId: localStorage?.getItem('site_id')
        };
        this.onDelete = this.onDelete.bind(this)
        this.getAddress = this.getAddress.bind(this)
    }

    async componentWillMount(){
        let mySiteId = encode("SiteNode:"+this.state.siteId) 
        await fetchQuery(environment, getQueryTenant() ,{tenantid:this.state.id,site_Id:mySiteId}).then(response_data => {
            this.setState({
                projectManager : response_data.chooseTenant.edges[0].node,
                boardId : response_data.chooseTenant.edges[0].node.boadPersonal.edges[0].node.id
            })
        });
    }

    onDelete(){
        this.setState({redirectToList : true})
    }

    getAddress(props){
        if(props){
            return (
                <span className="ml-2">
                    {props.residential?.homeNumber && (props.residential.homeNumber || "-") + " "}
                    {props.residential?.villageBuilding && " " + props.residential.villageBuilding + " "}
                    {props.residential?.villageNumber && "หมู่ที่ " + props.residential.villageNumber + " "}
                    {props.residential?.soi && this.getPrefix("soi") + props.residential.soi + " "}
                    {props.residential?.lane && this.getPrefix("lane") + props.residential.lane + " "}
                    {props.residential?.subDistrict && this.getPrefix("subdistrict", props.residential.postalCode) + props.residential.subDistrict + " "}
                    {props.residential?.district && this.getPrefix("district", props.residential.postalCode) + props.residential.district + " "}
                    {props.residential?.province && this.getPrefix("province", props.residential.postalCode) + props.residential.province + " "}
                    {props.residential?.postalCode}
                </span>)
        }else{
            return <span className="ml-2">-</span>
        }
    }

    isBangkok(code) {
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];
        if (_.indexOf(bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        if (type === "subdistrict" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "district" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "subdistrict") {
            return "ต."
        } else if (type === "district") {
            return "อ."
        } else if (type === "lane") {
            return "ถ."
        } else if (type === "soi") {
            return "ซ."
        }
    }

    getPosition(position){
        switch(position){
            case "president": 
                return "president";
            case "vice_president" :
                return "vice_president";
            case "committee":
                return "committee";
            case "secretary" :
                return "secretary";
            case "treasurer" :
                return "treasurer";
            default :
                return "";
        }
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/project/project-manager/list/current-board"/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                <Translation>
                    {t =>
                    <div className="container-fluid box" id="projectDashboard">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/project/project-manager/list/current-board">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <span>{t('project_manager:edit_project_manager')}</span>
                                </h3>
                            </div>
                            <div className="col">
                                {this.state.projectManager.boadPersonal?.edges[0].node.status === 'current' && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_manager_edit'}) &&
                                    <ModalChangeCommittee status="change" id={this.state.boardId} onDelete = {this.onDelete}/>
                                }
                            </div>
                        </div>
                        <div className="content-inner personal-info mt-2">
                            <div className="row mb-3" >
                                <div className="col-7">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <img
                                                            src={this.state.projectManager.boadPersonal?.edges[0].node.image ? this.state.projectManager.boadPersonal.edges[0].node.image  : process.env.PUBLIC_URL + '/images/icons/profile-manager-icon.png'}
                                                            alt="project-manager-profile"
                                                            className="rounded-circle avatar-img profile-img"/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5 className="ml-2">{this.state.projectManager.firstName} {this.state.projectManager.lastName}</h5>
                                                        </div>
                                                        <div className="col-12">
                                                            {this.getAddress(this.state.projectManager.boadPersonal?.edges[0].node)}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            
                                                            <div className={this.state.projectManager.boadPersonal?.edges[0].node.status === 'current' ? "round-status-on p-1" : "round-status-off p-1"}>
                                                                <span className="ml-3 mr-3">{t(`projectManager:${this.state.projectManager.boadPersonal?.edges[0].node.status}`)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-6">
                                                            <div className="col-12">
                                                                <span className="head">{t('projectManager:Unit')}</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <span>{this.state.projectManager.boadPersonal?.edges[0]?.node?.residential?.name}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-12">
                                                                <span className="head">{t('projectManager:Position')}</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <span>{t('project_manager:'+_.lowerCase(this.getPosition(this.state.projectManager.boadPersonal?.edges[0].node.position)))}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-6">
                                                            <div className="col-12">
                                                                <span className="head">{t('projectManager:Authorization Right')}</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <span>
                                                                    {this.state.projectManager.boadPersonal?.edges[0].node.authorization ? 
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'} alt="correct-signal" className="icon" /> 
                                                                        : ''
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-12">
                                                                <span className="head">{t('projectManager:Maximum approval limit')}</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <span>{numberWithComma(this.state.projectManager.boadPersonal?.edges[0].node.financial)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <div className="col-12">
                                                                <span className="head">{t('projectManager:Work experience')}</span>
                                                            </div>
                                                            {this.state.projectManager.boadWork?.edges?.length > 0 ?
                                                                this.state.projectManager.boadWork.edges.map((work,index) => {
                                                                    return (
                                                                        <React.Fragment key={index+'work'}>
                                                                            <div className="col-12">
                                                                                <span>{work.node.nameOfCompany}</span>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <span>{work.node.position}</span>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <span>{format(work.node.dateOfPosition,'DD/MM/YYYY')} - {format(work.node.dateOfEndPosition,'DD/MM/YYYY')}</span>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                                : 
                                                                <div className="col-12">
                                                                    <span>-</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <div className="col-12">
                                                                <span className="head">{t('projectManager:Education')}</span>
                                                            </div>
                                                            {this.state.projectManager.boadEducation?.edges?.length > 0 ?
                                                                this.state.projectManager.boadEducation.edges?.map((education,index) => {
                                                                    return (
                                                                        <React.Fragment key={index+'work'}>
                                                                            <div className="col-12">
                                                                                <span>{education.node.name}</span>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <span>{education.node.degree}</span>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                                : 
                                                                <div className="col-12">
                                                                    <span>-</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-4 d-flex felx-row projectDashboardToolTip">
                                                    <h5>{t('projectManager:Contact')}</h5>     
                                                    <div className="tooltipImg">
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                                alt="question" className="question ml-1" style={{width:'14px',marginBottom:'10px'}}/>
                                                        <span className="tooltiptext">
                                                            {t("projectManager:If you want to edit contact number and email information, you must edit them in the menu. Register of co-owners/members > Co-owners/members")}
                                                        </span>
                                                    </div>                                               
                                                </div>
                                                <div className="col-8"> 
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/phone-manager-icon.png'} alt="phone" className="icon"/>
                                                    <span className="ml-2">{this.state.projectManager.phone}</span>
                                                </div>
                                                <div className="col-4">                                                    
                                                    <Link to={'/register/residential/list/all'}><h5>{t('projectManager:ทะเบียนเจ้าของร่วม/สมาชิก')}</h5></Link>
                                                </div>
                                                <div className="col-8 mt-2"> 
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/mail-icon.png'} alt="mail" className="icon" />
                                                    <span className="ml-2">{this.state.projectManager.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card p-1 ">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-10">
                                                    <h5>{t('projectManager:Number of term')} : </h5>
                                                </div>
                                                <div className="col-2 text-right">
                                                    <h5>{this.state.projectManager.boadTerm?.edges.length}</h5>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    {
                                    
                                    _.orderBy(this.state.projectManager.boadTerm?.edges,'node.expDate','asc').map((term,index) => {
                                        
                                        return (
                                            <div className="card mt-3" key={index}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5>{t('projectManager:Agenda')} {index+1}</h5>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{t('projectManager:Date to start position')}</span>
                                                        </div>
                                                        <div className="col-6">{format(term.node.startDate,'DD/MM/YYYY')}</div >
                                                        <div className="col-6">
                                                            <span>{t('projectManager:Date to end position')}</span>
                                                        </div>
                                                        <div className="col-6">{format(term.node.expDate,'DD/MM/YYYY')}</div >
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })

                                    }
                                    
                                    <div className="card p-1 mt-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h5>{t('projectManager:Signature')} </h5>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <img
                                                    src={this.state.projectManager.boadPersonal?.edges[0].node.signature ? this.state.projectManager.boadPersonal?.edges[0].node.signature: process.env.PUBLIC_URL + '/images/icons/signature-manager-icon.png'}
                                                    alt="project-manager-signature"
                                                    className="avatar-img profile-img"/>
                                                </div>
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card p-1 mt-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h5>{t('projectManager:Documentation')}</h5>
                                                </div>
                                                {this.state.projectManager.boadFilepdf?.edges.length > 0 &&
                                                    this.state.projectManager.boadFilepdf?.edges.map((file) => {
                                                        return(
                                                            <React.Fragment key={file.node.id}>
                                                                <div className="col-8">
                                                                    <span>{file.node.nameFile}</span>
                                                                </div>
                                                                <div className="col-4">
                                                                    <a download={file.node.nameFile}
                                                                        href={file.node.fileUpload}>
                                                                        <span className="cursor text-blue">ดาวน์โหลด</span>
                                                                    </a>
                                                                    
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.projectManager.boadPersonal?.edges[0].node.status !== 'current' &&
                                        <div className="card p-1 mt-3">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h5>{t('projectManager:Note')} : </h5>
                                                    </div>
                                                    <div className="col-12">
                                                        <span>{this.state.projectManager.boadPersonal?.edges[0].node.note}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>      
                            </div>
                        </div>
                    </div>
                    }
                </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default UpdateProjectManager;
