import React from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Navigation from './navigationDebts';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer, fetchQuery } from "react-relay";
import format from 'date-fns/format';
import _ from 'lodash';
import DatePickerNoti from '../finance/depositInterest/datePickerNoti';
import SearchProject from '../../project/searchProject';
import ComponentPagination from '../../libs/componentPagination';
import Pagination from '../../libs/newPagination';
import Swal from 'sweetalert2';
import Loading from "../../libs/loading"
import BackButtonIcon from "../../components/BackBtn/indexBack";
import i18next from 'i18next';

const query = graphql`
query debtFreeRequestListQuery($search: String,$status: [String],$first: Int ,$last:Int,$startDate: DateTime,$endDate: DateTime){
    allDebsFreeCertificationRequest(search: $search, status: $status,first: $first,last: $last, startDate: $startDate, endDate: $endDate, orderBy: "-doc_number"){
        totalCount
        edges{
            node{
                id
                docNumber
                added
                updated
                contact{
                    id
                    name
                    firstName
                    lastName
                }
                requestingDate
                waitingDate
                approvalDate
                cancellationDate
                price
                tenant {
                    id 
                    firstName
                    lastName
                }
                phone
                addressContact
                address
                copyIdCard
                copyHouse
                copyApartment
                copySurnameChange
                otherDocument
                otherDocumentDescription
                attorney
                attorneyBook
                waterAndElectric
                startSuspend
                endSuspend
                transactionDebtType
                receive{
                    id
                    docNumber
                }
                otherReceive{
                    id
                    docNumber
                }
                    
                status  
                debsFreeCertificationRequestDocument{
                    edges {
                        node {
                            id
                            fileUpload
                        }
                    }
                }
                noteEdit
                noteVoid
                creatorApprove
                creatorVoid
                creatorEdit
                editDate
            }
        }
    }
    allSettingDebsFreeCertification {
        edges{
            node{
                id
                fee
                charge
                waiting
                lifetime
            }
        }
    }
}`;

class DebtFreeRequestList extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            status: this.props.match.params.status,
            loading: false,
            search: '',
            startDate: null,
            endDate: null,
            checkAll: false,
            selectedId: [],
            tempStartDate: null,
            tempEndDate: null,
            have_setting: false,
        }
    }

    componentDidMount() {
        this.goFirst();
        this.getSettingData()
    }

    componentDidUpdate() {
        if (this.props.match.params.status !== this.state.status) {
            this.setState({ status: this.props.match.params.status })
        }
    }

    getSettingData = () => {
        fetchQuery(environment, query, {
            search: this.state.search,
            status: this.switchStatus(),
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            first: this.state.first,
            last: this.state.last,
        }).then(data => {
            if (data) {
                if (data.allSettingDebsFreeCertification && data.allSettingDebsFreeCertification.edges.length > 0) {
                    this.setState({
                        have_setting: true
                    })
                }
            }
        })
            .catch(err => {
                alert(err)
            })
    }

    checkSetting = () => {
        if (!this.state.have_setting) {
            Swal.fire('ไม่สามารถเพิ่มคำร้องได้', 'กรุณาตั้งค่าค่าธรรมเนียมเอกสาร', 'warning');
        }
    }

    switchStatus = () => {
        if (this.state.status === 'all') {
            return ['edit', 'approve', 'void']
        } else if (this.state.status !== 'draft' && this.state.status !== 'all') {
            return [this.state.status]
        } else {
            return ['active', 'edit', 'paid', 'approve', 'void']
        }

    }

    handleSearch = (text) => {
        this.setState({
            search: text,
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onCheckAll = (props) => {
        let selected = []
        this.setState({ checkAll: !this.state.checkAll }, () => {
            if (this.state.checkAll) {
                _.forEach(props.allDebsFreeCertificationRequest.edges, debtFree => {
                    selected.push(debtFree.node.id)
                })
                this.setState({
                    selectedId: selected
                })
            } else {
                this.setState({
                    selectedId: []
                })
            }
        })

    }

    onCheck = (id) => {
        let index = this.state.selectedId.indexOf(id);
        let selected = _.cloneDeep(this.state.selectedId)
        if (index === -1) {
            selected.push(id)
        } else {
            selected.splice(index, 1)
        }
        this.setState({
            selectedId: selected
        })
    }

    onClickIcon = () => {
        this.setState({
            startDate: this.state.tempStartDate !== null ? new Date(new Date(this.state.tempStartDate).setHours(0, 0, 0, 0)) : null,
            endDate: this.state.tempEndDate !== null ? new Date(new Date(this.state.tempEndDate).setHours(24, 0, 0, 0)) : null
        })
    }



    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {(t) =>
                            <div className="container-fluid box" id="debt-free-request">
                                <BackButtonIcon
                                    LinkBack={this.props?.location?.state?.backLink ? this.props?.location?.state?.backLink : "/accounting"}
                                    LinkText={t("MenuName:Request for Debt-Free Certification")+" "+"(RDFC)"}
                                    addLinkChk={this.state.have_setting && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertificationrequest_create' }) ? true : false}
                                    addLinkButton={this.state.have_setting && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertificationrequest_create' }) ? "/accounting/debt_free_request/form/create" : ""}
                                    addLinkText={t("debt_free_request:Create")}

                                    addOnClickChk={!this.state.have_setting && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertificationrequest_create' }) ? t("debt_free_request:Create") : false}
                                    addOnClick={!this.state.have_setting && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertificationrequest_create' }) ? this.checkSetting : false}
                                />
                                <div className="content-inner">
                                    <Navigation />
                                    <div className="row mt-1 mb-3">
                                        {this.state.status === 'paid' ?
                                            <div className="col-2">
                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertificationrequest_print' }) &&
                                                    <Link
                                                        to={{
                                                            pathname: "/accounting/debt_free_request/print/all",
                                                            state: {
                                                                selected: this.state.selectedId,
                                                            },
                                                        }}>
                                                        <button type="button" className="btn-list cursor mr-3">
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/printer-black.png'} alt="print" style={{ height: '14px', width: '14px' }} />
                                                        </button>
                                                    </Link>
                                                }

                                            </div>
                                            :
                                            <div className="col-2" />
                                        }

                                        <div className="col-10 float-right form-inline">
                                            <div className="col d-flex justify-content-end align-items-center">
                                                <div className="ml-2"><span className="mr-2">{i18next.t("debt_free_request:Date")}</span></div>
                                                <div className="ml-2">
                                                    <DatePickerNoti
                                                        selected={this.state.tempStartDate}
                                                        name='tempStartDate'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="ml-2">
                                                    <span className="mr-1 ml-1 mr-2">{i18next.t("debt_free_request:To")}</span>
                                                </div>
                                                <div className="ml-2">
                                                    <DatePickerNoti
                                                        selected={this.state.tempEndDate}
                                                        name='tempEndDate'
                                                        className="mr-2"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="ml-2 mt-1">
                                                    <SearchProject callback_function={this.handleSearch} search={this.state.search} callback_function_click={this.onClickIcon} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <QueryRenderer
                                        environment={environment}
                                        query={query}
                                        variables={{
                                            search: this.state.search,
                                            status: this.switchStatus(),
                                            startDate: this.state.startDate,
                                            endDate: this.state.endDate,
                                            first: this.state.first,
                                            last: this.state.last,
                                        }}
                                        render={({ error, props }) => {
                                            if (error) {
                                                return <div>{error.message}</div>;
                                            } else if (props) {
                                                return (
                                                    <Translation>
                                                        {t =>
                                                            <React.Fragment>
                                                                <div className="table-responsive fade-up card mt-3">
                                                                    <table className="table table-hover">
                                                                        <thead className="thead-light headTable">
                                                                            <tr className="headTable">
                                                                                {this.state.status === 'paid' &&
                                                                                    <th className="text-center">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={this.state.checkAll}
                                                                                            onClick={() => this.onCheckAll(props)}
                                                                                        />
                                                                                    </th>
                                                                                }
                                                                                <th className="text-center" width={160}>
                                                                                    {i18next.t("debt_free_request:Request number")}
                                                                                </th>
                                                                                <th className="text-center">{i18next.t("debt_free_request:Creation date")}</th>
                                                                                <th className="text-center">{i18next.t("debt_free_request:Date requested")}</th>
                                                                                <th className="text-center">{i18next.t("debt_free_request:Room number")}</th>
                                                                                <th className="text-left">{i18next.t("debt_free_request:Owner's name")}</th>
                                                                                {this.state.status === 'all' && <th className="text-center">{i18next.t("debt_free_request:Status")} </th>}
                                                                                {(this.state.status === 'active' || this.state.status === 'paid') && <th className="text-left">{i18next.t("debt_free_request:Room number")}</th>}
                                                                                {this.state.status === 'approve' && <th className="text-left">{i18next.t("debt_free_request:Room number")}</th>}
                                                                                {this.state.status === 'approve' && <th className="text-center">{i18next.t("debt_free_request:Approval Date")} </th>}
                                                                                {(this.state.status !== 'void' && this.state.status !== 'active') && <th className="text-center" width={160}>{i18next.t("debt_free_request:Receipt number")}</th>}
                                                                                {this.state.status === 'paid' && <th className="text-center">{i18next.t("debt_free_request:Last Modified Date")}</th>}
                                                                                {this.state.status === 'void' && <th className="text-left">{i18next.t("debt_free_request:Canceled by")} </th>}
                                                                                {this.state.status === 'void' && <th className="text-left">{i18next.t("debt_free_request:Cancellation Date")} </th>}
                                                                                {this.state.status === 'void' && <th className="text-left">{i18next.t("debt_free_request:Reason for Cancellation")}</th>}
                                                                                {this.state.status === 'edit' && <th className="text-left">{i18next.t("debt_free_request:Rejected by")} </th>}
                                                                                {this.state.status === 'edit' && <th className="text-left">{i18next.t("debt_free_request:Rejection Date")} </th>}
                                                                                {this.state.status === 'edit' && <th className="text-left">{i18next.t("debt_free_request:Reason for Rejection")} </th>}

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {props.allDebsFreeCertificationRequest?.edges.length > 0 && props.allDebsFreeCertificationRequest.edges.map((debtFree, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        {this.state.status === 'paid' &&
                                                                                            <td className="text-center">
                                                                                                <input type="checkbox" onClick={() => this.onCheck(debtFree.node.id)} checked={this.state.selectedId.includes(debtFree.node.id)}></input>
                                                                                            </td>
                                                                                        }
                                                                                        <td className="text-center">
                                                                                            <Link to={"/accounting/debt_free_request/form/" + _.lowerCase(debtFree.node.status) + '/' + debtFree.node.id}>{debtFree.node.docNumber}</Link>
                                                                                        </td>
                                                                                        <td className="text-center">{debtFree.node.added ? format(debtFree.node.added, "DD/MM/YYYY") : '-'}</td>
                                                                                        <td className="text-center">{debtFree.node.requestingDate ? format(debtFree.node.requestingDate, "DD/MM/YYYY") : '-'}</td>
                                                                                        <td className="text-center">{debtFree.node.contact.name}</td>
                                                                                        <td className="text-left">{debtFree.node.contact.firstName} {debtFree.node.contact.lastName}</td>
                                                                                        {this.state.status === 'all' &&
                                                                                            <td className="text-center d-flex justify-content-center">
                                                                                                <div className={"status text-center " + _.lowerCase(debtFree.node.status)}><span>{t('debtFreeRequest:' + _.lowerCase(debtFree.node.status))}</span></div>
                                                                                            </td>
                                                                                        }
                                                                                        {(this.state.status === 'active' || this.state.status === 'paid') && <td className="text-left">{t('debtFreeRequest:' + _.lowerCase(debtFree.node.addressContact))}</td>}
                                                                                        {this.state.status === 'approve' && <td className="text-left">{debtFree.node.creatorApprove}</td>}
                                                                                        {this.state.status === 'approve' && <td className="text-center">{debtFree.node.approvalDate ? format(debtFree.node.approvalDate, "DD/MM/YYYY") : '-'}</td>}
                                                                                        {(this.state.status !== 'void' && this.state.status !== 'active') && <td className="text-center">{debtFree.node.otherReceive !== null && debtFree.node.otherReceive.docNumber} {debtFree.node.receive !== null && debtFree.node.receive.docNumber}</td>}
                                                                                        {this.state.status === 'paid' && <td className="text-center">{debtFree.node.updated ? format(debtFree.node.updated, "DD/MM/YYYY") : '-'}</td>}
                                                                                        {this.state.status === 'void' && <td className="text-left">{debtFree.node.creatorVoid}</td>}
                                                                                        {this.state.status === 'void' && <td className="text-left">{format(debtFree.node.cancellationDate, 'DD/MM/YYYY')}</td>}
                                                                                        {this.state.status === 'void' && <td className="text-left">{debtFree.node.noteVoid}</td>}
                                                                                        {this.state.status === 'edit' && <td className="text-left">{debtFree.node.creatorEdit}</td>}
                                                                                        {this.state.status === 'edit' && <td className="text-left">{format(debtFree.node.editDate, 'DD/MM/YYYY')}</td>}
                                                                                        {this.state.status === 'edit' && <td className="text-left">{debtFree.node.noteEdit}</td>}
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="row col-12" id="">
                                                                    <Pagination
                                                                        changePage={this.changePage}
                                                                        first={this.state.first}
                                                                        last={this.state.last}
                                                                        totalCount={props.allDebsFreeCertificationRequest.totalCount}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </Translation>
                                                );
                                            }
                                            return <Loading />
                                        }}
                                    />

                                </div>
                            </div>
                        }

                    </Translation>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default DebtFreeRequestList;
