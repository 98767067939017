import React, {Component} from 'react';
import {QueryRenderer} from 'react-relay';
import environment from "../../../env/environment";
import {graphql} from 'babel-plugin-relay/macro';
import numberWithComma from "../../../libs/numberWithComma";
import { Translation } from 'react-i18next';
import Loading from "../../../libs/loading";

const productDetail = graphql`
    query productDetailQuery($id:ID!) {
        product(id:$id){
            id
            name
            productCode
            cost
            inputTax
            type
            price
            outputTax
            typeOfIncome
            typeOfIncomeOther
            typeOfTax
            typeOfTaxOther
            typeOfWithholdingTax
            taxRate
            taxRateOther
            chartOfAccount {
                id
                name
                chartOfAccountCode
            }
            description
        }
    }

`;

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id
        };
    }

    getStatusList(key){
        let type = {
            PRODUCT: "Products",
            SERVICE: "Services",
            EXPENSE: "Expense",
        };

        return type[key]
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <QueryRenderer
                        environment={environment}
                        query={productDetail}
                        fetchPolicy="no-cache"
                        variables={{"id": this.state.id}}
                        render={({error, props}) => {
                            if (error) {
                                return <div>{error.message}</div>;
                            } else if (props) {
                                return (
                                    <React.Fragment>
                                        <Translation>{t=><h5 className="card-title">{t(`inventory_list:${this.getStatusList(props.product.type)}`) +' '+props.product.productCode+' '+ props.product.name}</h5>}</Translation>

                                        <div className="row mt-4">
                                            <div className="col-md-6 col-12">
                                                {
                                                    this.props.product_type === 'product' &&
                                                        <React.Fragment>
                                                            <Translation>{t=><p className="card-text">{t("inventory_list:Purchase price per unit")}: {props.product.cost != null ? numberWithComma(props.product.cost) : '-'}</p>}</Translation>
                                                            <Translation>{t=><p className="card-text">{t("inventory_list:Selling price per unit")}: {numberWithComma(props.product.price)}</p>}</Translation>
                                                        </React.Fragment>
                                                }

                                                <Translation>{t=><p>{t("inventory_list:Account type")}: {props.product.chartOfAccount != null ? props.product.chartOfAccount.chartOfAccountCode + ' - ' + props.product.chartOfAccount.name : '-'}</p>}</Translation>
                                                {/*<p className="card-text">
                                                    ภาษี: {props.product.inputTax!= null ? props.product.inputTax === 'no_vat' ? 'ไม่มีภาษี': props.product.inputTax+ '%': '-'}
                                                </p>*/}
                                            </div>
                                            {/*<div className="col-md-6 col-12">
                                                <p className="card-text">ราคาขายต่อหน่วย: {props.product.price}</p>
                                                <p className="card-text">ภาษี: {props.product.outputTax === 'no_vat' ? 'ไม่มีภาษี' : props.product.outputTax + '%'}</p>
                                                <p>ประเภทบัญชี: {props.product.chartOfAccount != null ? props.product.chartOfAccount.chartOfAccountCode + ' - ' + props.product.chartOfAccount.name : '-'}</p>
                                            </div>*/}
                                        </div>

                                        <Translation>{t=><p>{t("inventory_list:Description")}: {props.product.description}</p>}</Translation>
                                        <br/>

                                        {
                                            props.product.typeOfIncome === "1" ?
                                                <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:1. Salary, wages, allowances, bonuses, etc. under Section 40 (1)")}</p>}</Translation>
                                                : props.product.typeOfIncome === "2" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:2. Fees, brokerage fees, etc. under Section 40 (2)")}</p>}</Translation>
                                                : props.product.typeOfIncome === "3" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:3. Royalty fees, etc. under Section 40 (3)")}</p>}</Translation>
                                                : props.product.typeOfIncome === "4" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(a) Interest charges, etc. under Section 40 (4) (a)")}</p>}</Translation>
                                                : props.product.typeOfIncome === "4.1.1" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (1.1) 30% of net profit")}</p>}</Translation>
                                                : props.product.typeOfIncome === "4.1.2" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (1.2) 25% of net profit")}</p>}</Translation>
                                                : props.product.typeOfIncome === "4.1.3" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (1.3) 20% of net profit")}</p>}</Translation>
                                                : props.product.typeOfIncome === "4.1.4" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (1.4) Other rates (specify)")} {props.product.typeOfIncomeOther}</p>}</Translation>
                                                : props.product.typeOfIncome === "4.2.1" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (2.1) Net profit of the exempted business")}</p>}</Translation>
                                                : props.product.typeOfIncome === "4.2.2" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (2.2) Dividends or share of profits that are exempt from being included in the calculation of corporate income tax")}</p>}</Translation>
                                                : props.product.typeOfIncome === "4.2.3" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (2.3) the portion of net profit that has been deducted from net loss for not more than 5 years prior to the current accounting period")}</p>}</Translation>
                                                : props.product.typeOfIncome === "4.2.4" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (2.4) Profit recognized by equity method")} (equity method)</p>}</Translation>
                                                : props.product.typeOfIncome === "4.2.5" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:4(b) (2.5) other (specify)")} {props.product.typeOfIncomeOther}</p>}</Translation>
                                                : props.product.typeOfIncome === "5" ?
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:5. Payment of income subject to withholding tax according to the Revenue Department's order issued under Section 3 Tres")} {props.product.typeOfIncomeOther}</p>}</Translation>
                                                : props.product.typeOfIncome === "6" &&
                                                    <Translation>{t=><p>{t("inventory_list:Type of income paid")}: {t("inventory_list:6. Other (specify)")} {props.product.typeOfIncomeOther}</p>}</Translation>
                                        }

                                        {
                                            props.product.taxRate === "1" ?
                                                <Translation>{t=><p>{t("inventory_list:Tax rate")}: 1%</p>}</Translation>
                                                : props.product.taxRate === "2" ?
                                                    <Translation>{t=><p>{t("inventory_list:Tax rate")}: 2%</p>}</Translation>
                                                : props.product.taxRate === "3" ?
                                                    <Translation>{t=><p>{t("inventory_list:Tax rate")}: 3%</p>}</Translation>
                                                : props.product.taxRate === "5" ?
                                                    <Translation>{t=><p>{t("inventory_list:Tax rate")}: 5%</p>}</Translation>
                                                : props.product.taxRate === "other" &&
                                                    <Translation>{t=><p>{t("inventory_list:Tax rate")}: {props.product.taxRateOther}</p>}</Translation>
                                        }

                                        {
                                            props.product.typeOfWithholdingTax === "1" ?
                                                <Translation>{t=><p>{t("inventory_list:Witholding tax category")}: {t("inventory_list:P.N.D.1")}</p>}</Translation>
                                                : props.product.typeOfWithholdingTax === "3" ?
                                                    <Translation>{t=><p>{t("inventory_list:Witholding tax category")}: {t("inventory_list:P.N.D.3")}</p>}</Translation>
                                                : props.product.typeOfWithholdingTax === "53" &&
                                                    <Translation>{t=><p>{t("inventory_list:Witholding tax category")}: {t("inventory_list:P.N.D.53")}</p>}</Translation>
                                        }

                                        {
                                            props.product.typeOfTax === "1" ?
                                                <Translation>{t=><p>{t("inventory_list:Deduction Conditions")}: {t("inventory_list:Withholding tax")}</p>}</Translation>
                                                : props.product.taxRate === "2" ?
                                                    <Translation>{t=><p>{t("inventory_list:Deduction Conditions")}: {t("inventory_list:To pay taxes forever")}</p>}</Translation>
                                                : props.product.taxRate === "3" ?
                                                    <Translation>{t=><p>{t("inventory_list:Deduction Conditions")}: {t("inventory_list:One time tax")}</p>}</Translation>
                                                : props.product.taxRate === "other" &&
                                                    <Translation>{t=><p>{t("inventory_list:Deduction Conditions")}: {t("inventory_list:Other")} {props.product.typeOfTaxOther}</p>}</Translation>
                                        }


                                    </React.Fragment>
                                );
                            }
                            return <Loading/>
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default ProductDetail;
