import React, { Component } from 'react'
import PrivateRoute from '../libs/privateRoute';
import DashboardPurchasing from './dashboardPurchasing'
import ManageRequest from './manageRequest';
import NoMatch from '../components/noMatch';
import { Route , Switch} from "react-router-dom";
import manageOrder from './manageOrder';
import Claim from './claim/indexClaim';
import Insurance from './claim/indexInsurrance';

class Purchasing extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}`} component={DashboardPurchasing}/>
        <PrivateRoute path={`${this.props.match.url}/manageRequest`} component={ManageRequest}/>
        <PrivateRoute path={`${this.props.match.url}/manageOrder`} component={manageOrder}/>
        <PrivateRoute path={`${this.props.match.url}/claim`} component={Claim}/>
        <PrivateRoute path={`${this.props.match.url}/insurrance`} component={Insurance}/>

        <Route component={NoMatch} />
      </Switch>
    )
  }
}
export default Purchasing;
