import React, { useEffect, useState } from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Swal from "sweetalert2";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import _ from "lodash";
// import UpdateCollectionMutation from "./updateCollection";
import Loading from "../../libs/loading";
import CollectionLawFrimInvoiceTable from './collectionLawFrimInvoiceTable'
import i18next from "i18next";
import './collectionLawFirm.scss'
import BackButtonIcon from "../../components/BackBtn/indexBack";
import { VoidCollectionLawFirm } from "./query/voidCollectionLawFirm";
import logCollectionLawFrimMutation from "./mutation/logCollectionLawFrim";
import { fetchQuery } from "relay-runtime";
import { Translation } from "react-i18next";
import StatusLawDebt from '../../accounting/collectionLawFirm/status';
import { commitMutation } from "react-relay";
import Lightbox from "react-images";

const query = graphql`
  query collectionLawFirmDetailQuery ( $id: ID){
    allCollectionLawFirm(id:$id) {
          edges{
              node{
                id
                added
                docNumber
                issuedDate
                status
                dueDate
                contact{
                  id
                  refNumber
                  name
                  registeredName
                  registeredAddress
                  registeredCountry
                  registeredProvince
                  registeredCity
                  registeredDistrict
                  registeredPostalCode
                }
                expenseList
                finalParagraphIndentation
                language

                lawyerName
                lawyerSignature
                debtName
                telJuristic
                telOffice

                totalDebt
                totalFine
                creator

                collectionLawFirmRecord{
                    edges{
                        node{
                            id
                            debt
                            fine
                            receiptName
                            receiptUpload
                            collectionLetters{
                                id
                                refDocNumber
                                docNumber
                                issuedDate
                                status
                                numberOfTimes
                                documentCollectionLetters{
                                    edges{
                                        node{
                                            id
                                            fileName
                                            fileUpload
                                            numPage
                                        }
                                    }
                                }
                                # ใช้ตอนเป็นหน้า View Data
                                recordCollectionLetters{
                                    edges {
                                        node {
                                            id
                                            sumPaidBeforeLegalclaim
                                            sumPaidAfterLegalclaim
                                            transaction {
                                                id
                                                description
                                                total
                                                paid
                                                invoice{
                                                    id
                                                    docNumber
                                                    dueDate
                                                    total
                                                }
                                                productAndService {
                                                    id
                                                    productCode
                                                    name
                                                }
                                                chartOfAccount {
                                                    chartOfAccountCode
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                # ใช้ตอนเป็นหน้า View Data
                documentCollectionLawFirm{
                    edges{
                        node{
                            id
                            fileName
                            fileUpload
                            fileUrl
                        }
                    }
                }
                # receiveCollectionLawFirmRecord{
                #     edges{
                #         node{
                #           id
                #           total
                #           paid
                #           typePaid
                #           status
                #           receiveCollectionLawFirm{
                #               id
                #               docNumber
                #               status
                #           }  
                #         }
                #     }
                # }
                fmcfCollectionLawFirm{
                    edges{
                        node{
                            id
                            docNumber
                            issuedDate
                            projectName
                            contactName
                            contactRoom
                            sizeRoom
                            balconyRoom
                            dueDateDays
                            dueDate
                            total
                            fine
                            paid

                            lawyerSignature
                            lawyerName
                            rulesRegulationsjuristic
                            telOffice
                            telJuristic
                            status
                            documentType
                            voidNote
                            creator
                            creatorEdit
                            creatorDelete
                            creatorApproval
                        }
                    }
                }
            }
        } 
    }
  }
`;

const mutation = graphql`
    mutation collectionLawFirmDetailMutation ($input: DeleteDocumentCollectionLawFirmInput!) {
      deleteDocumentCollectionLawFirm(input:$input){
            ok
            massage
        }
    }
`;

const CollectionLawFirmDetail = (props) => {
  const [loading, setLoading] = useState(true)
  const [detail, setDetail] = useState(props?.location?.state?.detail ?? [])
  const [detailTable, setDetailTable] = useState([])
  const [documentTable, setDocumentTable] = useState([])
  const [status, SetStatus] = useState("")
  const [statusReal, SetStatusReal] = useState("")
  const [statusColor, SetStatusColor] = useState("")
  const [viewFile, setViewFile] = useState("")


  useEffect(() => {
    fetchQuery(environment, query, { id: props?.match?.params?.id ?? "" }).then((ress) => {
      setDetail(ress.allCollectionLawFirm.edges[0].node)
      setDetailTable(ress.allCollectionLawFirm.edges[0].node.collectionLawFirmRecord.edges[0].node.collectionLetters.recordCollectionLetters.edges)
      setDocumentTable(ress.allCollectionLawFirm.edges[0].node.documentCollectionLawFirm.edges)
      getStatus(ress.allCollectionLawFirm?.edges[0].node?.status)
      setLoading(false)
    })
  }, [])


  const onCancelLetter = (id) => {
    if (detail?.status.toLowerCase() !== "follow") {
      Swal.fire("ไม่สามารถทำการยกเลิกได้", "เนื่องจากมีการชำระเงิน PR ไปแล้ว", "warning")
      return;
    } else {
      Swal.fire({
        customClass: {
          title: 'swal2-title-custom',
          header: 'swal2-header-custom p-3',
          container: 'swal2-container-custom colorUse',
          content: 'swal2-content-custom border-top pt-3 p-3',
          footer: 'text-right',
          popup: 'swal-width-820',
          closeButton: 'text_0F0F0F pt-5 pr-5 h100',
          confirmButton: 'swal2-confirmToRight bg1567B4-textfff',
          cancelButton: 'fw-300',
          actions: 'justify-content-right'
        },
        title: i18next.t("collectionLawFirm:Canceling a follow-up document by an attorney"),
        showCloseButton: true,
        input: 'text',
        inputPlaceholder: i18next.t("collectionLawFirm:Reasons for cancellation"),
        // html:
        //   '<p class="row col-12"><label class="fs-18 mt-2 mr-3">เหตุผลทยกเลิก </label> <input type="text" id="reasonPleaseRequire" class="form-control h48 w500" placeholder="เหตุผลที่ยกเลิก" /></p>',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: `${i18next.t("Allaction:Yes")}`,
        cancelButtonText: `${i18next.t("invoiceDetail:Cancel")}`,
        inputValidator: (remark) => {
          if (!remark) {
            return i18next.t("Allaction:Please fill input before cancel")
          }
        }
      }).then((res) => {
        if (res?.value) {
          let valiueVoid = res?.value
          Swal.fire({
            customClass: {
              container: 'swal2-container-custom colorUse',
              content: 'swal2-content-custom',
              footer: 'text-right',
              confirmButton: 'swal2-confirmToRight bg1567B4-textfff',
            },
            imageUrl: '/images/iconAction/question.png',
            imageWidth: 120,
            html: `<p class="text-center fs-18 fw-400"><label>
            ${i18next.t("collectionLawFirm:Confirm to cancel")}</label><br/><label>
            ${i18next.t("collectionLawFirm:The system will automatically process the debt reduction in the credit note menu")}</label></p>`,
            showCloseButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: `${i18next.t("Allaction:Yes")}`,
            cancelButtonText: `${i18next.t("invoiceDetail:Cancel")}`,
          }).then((result2) => {
            if (result2.value) {
              let variables = {
                "input": {
                  "jsonObject": {
                    "issuedDate": "{{current_timestamp}}",
                    "collectionLawFirmList": [{ "Id": props?.match?.params?.id }],
                    "voidRemark": valiueVoid
                  }
                }
              }


              VoidCollectionLawFirm(variables, res => {
                if (res.ok) {
                  Swal.fire(i18next.t("Allaction:Canceled Successfully"), "", "success")
                  window.location.reload()
                } else {
                  Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), res.massage, "error")
                }
              })
            }

          })
        } else {
          return;
        }
      })
    }
  }
  const attachFile = (docNumberAttachFile, indexAttachFileCount, DataFile) => {
    props.history.push({
      pathname: `/accounting/collection-law-firm/detail/${props.match.params.id}/attach`,
      state: {
        linkBack: window.location.pathname,
        docNumber: docNumberAttachFile,
        totalCount: indexAttachFileCount,
        FileData: DataFile
      }
    });
  }

  const getStatus = (status) => {
    let stu = status.toLowerCase()
    switch (stu) {
      case "follow":
        SetStatusReal("follow")
        SetStatus(`${i18next.t("collectionLawFirm:Issued Notice")}`)
        SetStatusColor("bg_678ED7")
        break;
      case "overdue":
        SetStatusReal("overdue")
        SetStatus(`${i18next.t("collectionLawFirm:Due Completed")}`)
        SetStatusColor("bg_E14558")
        break;
      case "partial_payment":
        SetStatusReal("partial_payment")
        SetStatus(`${i18next.t("collectionLawFirm:Partially Paid")}`)
        SetStatusColor("bg_FF922D")
        break;
      case "paid":
        SetStatusReal("paid")
        SetStatus(`${i18next.t("collectionLawFirm:Paid")}`)
        SetStatusColor("bg_68DD6E")
        break;
      case "void":
        SetStatusReal("void")
        SetStatus(`${i18next.t("invoiceDetail:Cancel")}`)
        SetStatusColor("bg_0F0F0F")
        break;
    }
  }

  const sendLog = () => {
    let variables = {
      "input": {
        "id":props?.match?.params?.id
      }
    }
    logCollectionLawFrimMutation(variables)
  }

  const onDeleteFile = (index) => {
    Swal.fire({
      customClass: {
        container: 'swal2-container-custom colorUse',
        content: 'swal2-content-custom',
        confirmButton: 'swal2-confirmToRight bg1567B4-textfff',
      },
      imageUrl: '/images/iconAlerts/iconAlert-WarningRed.png',
      imageWidth: 120,
      html: `<label class="text-center fs-18 fw-400">ลบรายการแนบเอกสารนี้</label>`,
      showCloseButton: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: i18next.t("Allaction:Confirm"),
      cancelButtonText: i18next.t("Allaction:cancel"),
    }).then((res) => {
      if (res.value) {
        let fileClone = _.cloneDeep(documentTable)

        let findId = fileClone.find((ex) => ex.node.id, index)

        fileClone.splice(index, 1)

        if (findId?.node?.id) {
          let variables = {
            "input": {
              "jsonObject": {
                "Id": findId.node.id,
                "Mod": ""
              }
            }
          }

          commitMutation(
            environment,
            {
              mutation,
              variables,
              onCompleted: (response) => {
                if (response?.deleteDocumentCollectionLawFirm?.ok) {
                  Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success');
                  setDocumentTable(fileClone)
                } else {
                  Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.deleteDocumentCollectionLawFirm.massage, 'warning');
                }
              },
              onError: (err) => {
                Swal.fire('Error!', i18next.t("Allaction:Please try again"), 'warning');
              },
            },
          )
        }
      }

    })
  }

  return (
    <Wrapper>
      <Header />
      <Sidebar />
      <WrapperContent>
        <AccountingTopMenuNavigation mini={true} />
        <Translation>
          {(t) =>

            !loading ?
              <div className="container-fluid box colorUse" id="collection-law-firm">
               
                
                  <div className="col text-right searchFunction">
                    {
                       props.match.params.status !== 'law' || _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'collection_law_frim_cancel' }) && 
                        <button className={`btn bg-D9D9D9 h48 text_4D4D4D`} onClick={() => onCancelLetter()}>{i18next.t("Allaction:cancel")}</button>
                    }
                    {
                        props.match.params.status !== 'law' &&  _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'collection_law_frim_print' }) && 
                        <Link to={{ pathname: "/accounting/collection-law-firm/printDocument/" + props?.match?.params?.id, state: { linkBack: window.location.pathname } }}>
                          <button className="btn-primary ml-3 float-right" onClick={() => sendLog()}>
                            <img src={process.env.PUBLIC_URL + '/images/iconAction/E-notice/print.png'} className="w18 mr-3" />{i18next.t('FixRequestReport:Print')} </button>
                      </Link>
                    }
                   
                  </div>
                <div className="row mt-3 pl-4 pr-4">
                  <div className="col-2">
                    <h6>{t('collectionLawFirm:Document Date')}</h6>
                  </div>
                  <div className="col-3">
                    <h6>{t('collectionLawFirm:Reference from the last debt collection letter number')}</h6>
                  </div>
                  <div className="col-2">
                    <h6>{t('collectionLawFirm:Time (s)')}</h6>
                  </div>
                </div>

                <div className="row pl-4 mb-3 pr-4 allow">
                  <div className="col-2">
                    <div className="arrowInSelect" style={{ position: "relative" }}>
                      <input type={'text'} className="form-control h48 w200" value={format(new Date(detail?.added), 'DD/MM/YYYY')} readOnly />
                      <img src={`${process.env.PUBLIC_URL}/images/icons/calendar.png`} className="w18 IConImgOnInput" />
                    </div>
                  </div>
                  <div className="col-3">
                    <Link to={{ pathname: "/accounting/collection_letters/detail/ACTIVE/" + detail?.collectionLawFirmRecord?.edges[0]?.node?.collectionLetters?.id, state: { linkBack: window.location.pathname } }}>
                      <input type={'text'} className="form-control bg-1567B4 h48 w200" value={detail?.collectionLawFirmRecord?.edges[0]?.node?.collectionLetters?.docNumber} readOnly />
                    </Link>
                  </div>
                  <div className="col-2">
                    <input type={'text'} className="form-control h48 w200" value={detail?.collectionLawFirmRecord?.edges[0]?.node?.collectionLetters?.numberOfTimes} readOnly />
                  </div>
                </div>


                <div className="row mt-4 pl-4 pr-4">
                  <div className="col-12 mt-4">
                    <h4>{t('collectionLawFirm:Debt Collection Recipient')}</h4>
                    <hr />
                  </div>
                </div>
                <div className="row pl-4 mt-2 pr-4">
                  <div className="col-2">
                    <h6>{t('collectionLawFirm:Resident Code')}</h6>
                  </div>
                  <div className="col-4">
                    <h6>{t('collectionLawFirm:Name')}</h6>
                  </div>
                  <div className="col-6">
                    <h6>{t('asset:Address')}</h6>
                  </div>
                </div>
                <div className="row pl-4 pr-4 mb-2">
                  <div className="col-2">
                    <input type="text" className="form-control" disabled={true} value={detail?.contact?.refNumber} />
                  </div>
                  <div className="col-4">
                    <input type="text" className="form-control" disabled={true} value={detail?.debtName} />
                  </div>
                  <div className="col-5">
                    <input type="text" className="form-control" disabled={true}
                      value={`${detail?.contact?.registeredAddress} ${detail?.contact?.registeredDistrict} ${detail?.contact?.registeredCity} ${detail?.contact?.registeredProvince} ${detail?.contact?.registeredPostalCode}`} />
                  </div>
                </div>


                <CollectionLawFrimInvoiceTable status={statusReal} data={detailTable} date={detail?.added} sumTotal={detail?.totalDebt} sumFine={detail?.totalFine} />

                {/* ถ้ามีเอกสารแนบจะแสดงตารางนี้ */}
                <div className="row pl-4 pr-4 mb-2 mt-4">
                  {
                    //   props.allCollectionLetters.edges[0].node.documentCollectionLetters.edges.length > 0 &&
                    <div className="row col-12 mb-3" style={{ marginTop: '48px' }}>
                      <div className="attachText">
                        <h4>{t('collectionLawFirm:Attached documents')}</h4>
                        <hr />
                      </div>
                      <div className="row mt-3 col-4 col-sm-8 ml-2">
                        <div className="table-responsive">
                          <table className="table table-collection tableAttach">
                            <thead className="thead-light headTable">
                              <tr>
                                <th className="text-left">{t('collectionLawFirm:Document Name')}</th>
                                <th className="text-center">{t('collectionLawFirm:Number of documents (sheets)')}</th>
                                <th className="text-right" />
                              </tr>
                            </thead>
                            <tbody className="tableList">
                              {
                                documentTable?.map((attach, indexAttach) => {
                                  let typeDoc = attach.node.fileUrl.includes(".pdf" || ".doc" || ".docx")
                                  return (
                                    <tr key={"documentTable_collectionLawFirmDetail:: " + indexAttach}>
                                      <td className="text-left">
                                        {
                                          typeDoc ?
                                            <a href={attach.node.fileUrl} target="_blank">{indexAttach + 1}. {attach.node.fileName}</a>
                                            :
                                            <span className="cursor text-blue" onClick={() => setViewFile(attach.node.fileUrl)}>{indexAttach + 1}. {attach.node.fileName}</span>
                                        }

                                      </td>
                                      <td className="text-center">-</td>
                                      <td className="text-right">
                                        {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_delete' }) && */}
                                        <button
                                          type="button"
                                          className="btn btn-noHover btn-outline-secondary ml-2 delBin"
                                          onClick={() => onDeleteFile(indexAttach)}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/images/icons/bin-outline.png"
                                            }
                                            alt="delete-icon"
                                          />
                                        </button>
                                        {/* } */}

                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="col-12 mt-3 ml-1">
                        <button
                          disabled={detail?.status.toLowerCase() === "void" ? true : false}
                          className={detail?.status?.toLowerCase() === "void" ? "btn h48 bg-E2E2E2 border_808080 text_808080" : "attachBtnCollectionDetail"}
                          onClick={() => attachFile(detail?.docNumber, documentTable.length, documentTable)}>
                          + {t('collectionLawFirm:Attached documents')}
                        </button>
                      </div>
                    </div>
                  }

                  <Lightbox
                    images={[{ src: viewFile }]}
                    onClose={() => setViewFile("")}
                    isOpen={viewFile ? true : false}
                    backdropClosesModal={true}
                  />
                </div>
              </div>
              :
              <Loading />
          }
        </Translation>

      </WrapperContent>
    </Wrapper>
  );
}

export default CollectionLawFirmDetail;
