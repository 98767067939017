import React, { Component } from 'react'
import SearchSelect from '../../../../libs/searchSelect'

const selectNotiDate = [
  { node: { id :'30', label : '30'}},
  { node: { id :'60', label : '60'}},
  { node: { id :'90', label : '90'}},
  { node: { id :'120', label : '120'}},
  { node: { id :'มากกว่า 120', label : 'มากกว่า 120'}},
]

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF'
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

export default class selectNotidate extends Component {
  render() {
    return (
      <SearchSelect 
        onChange={this.props.handleChangeInput} 
        disabled={this.props.disibleInput}
        value={this.props.notiDay}
        name={this.props.name} 
        placeholder="รายการ"
        queryObject={selectNotiDate}
        keyOfValue="id" require={true}
        keyOfLabel="label"
        style={customStyles}
        />
    )
  }
}
