

import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import FirstLotteryAward from "./firstLotteryAward"
import './lottery.scss'


class viewLotteryAward extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

     getawardDetail(data){
        return(
            <>
                <p style={{color:'#808080'}}>รางวัลละ {data.price} บาท</p>
                <div className='number-list'>
                    {data.number.map((val) => {
                    return (
                        <div>
                             <p style={{padding:'0 2px'}}>{val.value}</p>
                        </div>
                    )})
                    }
                </div>
            </>
        )
    }
    
    render(){
        let myLang = this.props.match?.params?.lang;
        return (
            <div id="wrapper-content">
                   <div id='svm-lottery' className='p-3'>
                    <Link to="/lottery-award/">
                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'} alt="back" className="back" />
                    </Link>
                        <h4 className='text-center'>{myLang === 'en' ? 'All award' : 'ผลรางวัลทั้งหมด'}</h4>
                        <div className='row'>
                            <div className='col text-center'>
                                <h4> {myLang === 'en' ? 'Results' : 'ผลการตรวจสลากหมายเลข'} <p>{myLang === 'en' ? 'Date' : 'งวดประจำวันที่ '} {this.props.location.state.dateLotteryAward && format(new Date(this.props.location.state.dateLotteryAward && this.props.location.state.dateLotteryAward ).setFullYear(parseInt(new Date(this.props.location.state.dateLotteryAward && this.props.location.state.dateLotteryAward ).getFullYear()) + 543), "DD MMM YYYY", { locale: thLocale})}</p></h4>
                            </div>
                        </div>
                        <FirstLotteryAward lotteryAward={this.props.location.state.lotteryAward} isShowDetail={true}/>
                        <div className='lottery-detail'>
                        <h6>{myLang === 'en' ? 'the numbers are closest to 1st prize' : 'รางวัลข้างเคียงรางวัลที่ 1'}</h6>
                            {this.getawardDetail(this.props.location.state.lotteryAward.near1)}
                        </div>
                        <div className='lottery-detail'>
                            <h6> {myLang === 'en' ? '2nd' : 'รางวัลที่ 2'}</h6>
                            {this.getawardDetail(this.props.location.state.lotteryAward.second)}
                        </div>
                        <div className='lottery-detail'>
                            <h6>{myLang === 'en' ? '3rd' : 'รางวัลที่ 3'} </h6>
                            {this.getawardDetail(this.props.location.state.lotteryAward.third)}
                        </div>
                        <div className='lottery-detail'>
                            <h6>{myLang === 'en' ? '4th' : 'รางวัลที่ 4'}</h6>
                            {this.getawardDetail(this.props.location.state.lotteryAward.fourth)}
                        </div>
                        <div className='lottery-detail'>
                            <h6>{myLang === 'en' ? '5th' : 'รางวัลที่ 5'}</h6>
                            {this.getawardDetail(this.props.location.state.lotteryAward.fifth)}
                        </div>
                   </div>
            </div>
        );

    }
    
}
export default viewLotteryAward;