import React from "react";
import ReactExport from "react-data-export";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
import numberWithCommas from "../../../../libs/numberWithComma";
import "./interestReport.scss"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const _ = require('lodash');

const style = {
    alignment: {horizontal: "center"} ,font: {bold: true}
};

const styleNumber = {
    border: {
        left: {style: "thin", color: "FF0000FF"},
        right: {style: "thin", color: "FF0000FF"},
        top: {style: "thin", color: "FF0000FF"},
        bottom: {style: "thin", color: "FF0000FF"}
    },
    alignment: {
        vertical: "bottom",
        horizontal: "right"
    }
};

class InterestExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            developerName : '',
            startDate : '',
            endDate : '',
            head : [],
            multiDataSet : []
        };
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
    }

    componentDidMount(){
        this.setData()
    }

    setData(){
        let column = []
        let head_column = [
            {value: 'เลขที่',  style: { alignment: {horizontal: "center"} , font: { bold: true}}},
            {value: 'เดือน' ,  style: { alignment: {horizontal: "center"} , font: { bold: true}}},
        ]
        let head_column2 = [
            {value: ''},
            {value: ''},
        ]

        _.forEach(this.props.bankUnit,(bank,index) => {
            head_column.push({value:'ประมาณการดอกเบี้ย' ,  style: { alignment: {horizontal: "center"} , font: { bold: true}}})
            head_column2.push({value: bank ,  style: { alignment: {horizontal: "center"} , font: { bold: true}}})
            
        })

        head_column.push({value:'รวม (บาท)', style: { alignment: {horizontal: "center"} , font: { bold: true}}})
        head_column2.push({value: '' ,  style: { alignment: {horizontal: "center"} , font: { bold: true}}})
        

        head_column.forEach((head,index) => {
            column.push({title: "", width: {wpx:150}})
        })

        let data = [
            [{value: this.props.projectName,  style: { font: { bold: true}}}],
            [{value: "รายงานรายละเอียดประมาณการดอกเบี้ยรับบัญชีเงินฝากประจำ",  style: {font: { bold: true}}}],
            [{value: "ตั้งแต่เดือน" + new Date(this.props.firstDate).toLocaleDateString('th-TH' , { year: 'numeric', month: 'long'}) + " ถึง " + 
                    new Date(this.props.lastDate).toLocaleDateString('th-TH' , { year: 'numeric', month: 'long'}),  style: {font: { bold: true}}} ],
            [{value: ""}],
            head_column ,
            head_column2 ,
        ]

        let sumBankUnit = []

        for (const transaction of this.props.allBankReport){
            let dataList = []
            let bankUnit = []
            let sumJV = 0

            for(const [index,id] of this.props.headerBankUnitID.entries() ){
                let interestDebit = _.find(transaction.node.accountRecord.edges,['node.bankUnit.id',id])
                if(sumBankUnit[index]){  
                    sumBankUnit[index] = sumBankUnit[index] + (interestDebit ? interestDebit.node.debit : 0)
                }else{
                    sumBankUnit[index] = interestDebit? interestDebit.node.debit : 0
                }
                if(interestDebit){
                    sumJV += interestDebit.node.debit
                    bankUnit.push({value : numberWithCommas(interestDebit.node.debit) , style: {alignment: {horizontal: "right"}}})
                }else{
                    bankUnit.push('')
                }
            }
            dataList.push(
                {value : transaction.node.refNumber , style: {alignment: {horizontal: "center"}}} , 
                {value : format(transaction.node.issuedDate,'DD/MM/YYYY') ,style: {alignment: {horizontal: "center"}}} ,
                 ...bankUnit , {value : numberWithCommas(sumJV),  style: {alignment: {horizontal: "right"}}})
            data.push(dataList)
        }

        let summary = ['' , 'รวม']
        for (const summaryUnit of sumBankUnit){
            summary.push({value : numberWithCommas(summaryUnit) ,  style: {alignment: {horizontal: "right"}}})
        }
        summary.push({value : numberWithCommas(_.sum(sumBankUnit)) ,  style: {alignment: {horizontal: "right"}}})

        data.push(summary)
        let multiDataSet = [
            {
                columns: column,
                data: data,
            }
        ];
        this.setState({multiDataSet: multiDataSet});

    }
    buttonElement() {
        return (
            <Dropdown.Item><p className="text-black">Excel</p></Dropdown.Item>
        );
    }

    render() {
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"report_" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}>
                <ExcelSheet dataSet={this.state.multiDataSet}  name="Organization" />
            </ExcelFile>
        )
    }



}
export default InterestExcel ;