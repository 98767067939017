import React, {Component} from 'react';
import $ from "jquery";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import jwtDecode from "jwt-decode";
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';
const ThaiBaht = require('thai-baht-text');
class PettyCash extends Component {

    constructor(props){
        super(props);
        this.state = {
            now_date: new Date(),
            pettyCashRecord:[],
            accountRecord:[],
            token: jwtDecode(window.localStorage.getItem('token')),
            languages: "th",
        }
    }
    
    componentDidMount() {
        let list =[]
        let accountRecord =[]
        this.props.query.pettyCashRecord.edges.forEach((val,index)=>{
            if (index<17) {
                if (index === 0) {
                    list.push([])
                }
                list[0] =  [...list[0],val]
            }else if(index<34){
                if (index === 17) {
                    list.push([])
                }
                list[1] = [...list[1],val]
            }else if(index<68){
                    if (index === 34) {
                        list.push([])
                    }
                    list[2] = [...list[2],val]
            }
        })
        this.props.query.pettyCashRecordAccountRecordGroup.edges[0].node.accountRecord.edges.forEach((val,index)=>{
            if (index<30) {
                if (index === 0) {
                    accountRecord.push([])
                }
                accountRecord[0] =  [...accountRecord[0],val]
            }else if(index<60){
                if (index===30) {
                    accountRecord.push([])
                }
                accountRecord[1] = [...accountRecord[1],val]
            }else if(index<90){
                    if (index===60) {
                        accountRecord.push([])
                    }
                    accountRecord[2] = [...accountRecord[2],val]
            }
        })

        this.setState({pettyCashRecord:list,accountRecord:accountRecord, languages: this.props.languages})

        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height() || 0;
            let detail = page.find('.detail').height() || 0;
            let invoice = page.find('.invoice-body').height() || 0;
            let chart = page.find('.chart-of-account').height() || 0;
            let signature = page.find('.footer').height() || 0;
            let page_height
            page_height = 1125 - 150    
            let diff = (page_height - (head + detail + invoice + chart + signature)) / 2;
            diff = Math.abs(diff)
            this.setState({ diff: diff })
            if (diff < 100) {
                $('tbody tr:last-child td').css({ paddingBottom: 20 });
            }
            else if(this.props.query.pettyCashRecord.edges.length <= 10) {
                $('tbody tr:last-child td').css({ paddingBottom: diff })
            }
            else{
                $('tbody tr:last-child td').css({ paddingBottom: 20 })
            }

        }, 100);
    }
    
    render() {        
        let total_price = 0
        let keyname = Math.random().toString(36).substring(7);

        return (
            <React.Fragment>
                {this.props.query.pettyCashRecord.edges.length<=10&& <div className="print-page-a4" id="style-modify" style={{ position: 'relative' }}>
                <div className="subpage" style={{ overflow: 'hidden' }}>
                    <div className="head">
                        <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minHeight:100,minWidth:100}}>
                                <img src={this.props.query.selfProject.logo} alt="silverman"/>
                            </div>
                            <div>
                                <div style={{minWidth:'115%'}}>
                                    <strong>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong> 
                                </div>
                                <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                            </div>
                        </div>
                        <div className="document-name">
                            {
                                <React.Fragment>
                                    <div className="title">ใบบันทึกจ่ายเงินสดย่อย</div>
                                    <div className="title">Petty Cash Voucher</div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="detail mt-2">
                        <div className="row">
                            <div className="col">
                                <span className="font-weight-bold">รายการตั้งเบิก</span><br/>
                                {this.props.query.pettyCashRecord.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashRecord.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashRecord.edges[0].node.setPettyCash.description}
                            </div>
                            <div className="col">
                                <span className="font-weight-bold">เลขที่</span><br/>
                                {this.props.query.pettyCashRecord.edges[0].node.docNumber}
                            </div>
                            <div className="col">
                                <span className="font-weight-bold">วันที่ออก</span><br/>
                                {format(this.props.query.pettyCashRecord.edges[0].node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}
                            </div>
                        </div>
                    </div>

                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                            <th width={100} className="text-center">
                                    เลขที่
                                </th>
                                <th className="text-center">
                                    วันที่
                                </th>
                                <th width={60} className="text-center">
                                    รหัสบัญชี
                                </th>
                                <th className="text-center">
                                    ชื่อบัญชี
                                </th>
                                <th className="text-center">
                                    คำอธิบาย
                                </th>
                                <th width={70} className="text-right">
                                    จำนวนเงิน
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.query.pettyCashRecord.edges.map((petty_cash_record) => {
                                total_price += petty_cash_record.node.price
                                return(
                                <React.Fragment key={petty_cash_record.node.id}>
                                    <tr>
                                        <td className="text-center">
                                            {petty_cash_record.node.docNumber}
                                        </td>
                                        <td className="text-center"> {format(petty_cash_record.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                        <td className="text-center">{petty_cash_record.node.chartOfAccount.chartOfAccountCode}</td>
                                        <td>{petty_cash_record.node.chartOfAccount.name}</td>
                                        <td>{petty_cash_record.node.description}</td>
                                        <td className="text-right">{numberWithComma(petty_cash_record.node.price)}</td>
                                    </tr>
                                </React.Fragment>
                                )}
                            )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td className="text-center" style={{borderRight: "none"}}>
                                        รวม
                                    </td>
                                    <td colSpan={4} className="text-left" style={{borderLeft:"none"}}>
                                        {this.state.languages === 'th' ?
                                        (ThaiBaht(total_price)):
                                        ConvertNumberToTextEng(total_price)}
                                    </td>
                                    <td className="text-right">
                                        {numberWithComma(total_price)}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div className="chart-of-account">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th width={100} className="text-center">
                                    รหัสบัญชี
                                </th>
                                <th className="text-center">
                                    ชื่อบัญชี
                                </th>
                                <th width={100} className="text-center">
                                    เดบิต
                                </th>
                                <th width={100} className="text-center">
                                    เครดิต
                                </th>
                            </tr>
                            </thead>
                            {this.props.query.pettyCashRecordAccountRecordGroup.edges.map((petty_cash_record) =>
                            
                                <React.Fragment key={petty_cash_record.node.id}>
                                    <tbody>
                                    {petty_cash_record.node.accountRecord.edges.map((account_record) => 
                                        account_record.node.debit > 0 &&
                                        <tr key={account_record.node.id}>
                                            <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                            <td> {account_record.node.chartOfAccountCode.name} {account_record.node.name}</td>
                                            <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                            <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                        </tr>
                                    )}
                                    {petty_cash_record.node.accountRecord.edges.map((account_record) =>
                                        account_record.node.credit > 0 &&
                                        <tr key={account_record.node.id}>
                                            <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                            <td> {account_record.node.chartOfAccountCode.name} {account_record.node.name}</td>
                                            <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                            <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                        </tr>
                                    )}
                                    </tbody>

                                    <tfoot className="font-weight-bold">
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="row">
                                                <div className="col-1">
                                                    รวม/Total
                                                </div>
                                                <div className="col-10 text-center">                                                                                           
                                                    {this.state.languages === 'th' ?
                                                    (ThaiBaht(petty_cash_record.node.totalDebit)):
                                                    ConvertNumberToTextEng(petty_cash_record.node.totalDebit)}
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            {numberWithComma(petty_cash_record.node.totalDebit.toFixed(2))}
                                        </td>
                                        <td className="text-right">
                                            {numberWithComma(petty_cash_record.node.totalCredit.toFixed(2))}
                                        </td>
                                    </tr>
                                    </tfoot>
                                </React.Fragment>
                            )}
                        </table>
                    </div>

                    <div className="footer">
                        <div className="signature-large">
                            <div className="column-foursign">
                                ..................................................<br/>
                                <span className="font-weight-bold">ผู้จัดทำ / Orgenizer</span><br/><br/>
                                {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                            </div>
                            <div className="column-foursign">
                                ..................................................<br/>
                                <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span><br/><br/>
                                {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                            </div>
                            <div className="column-foursign">
                                ..................................................<br/>
                                <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span><br/><br/>
                                {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                            </div>
                            <div className="column-foursign">
                                ..................................................<br/>
                                <span className="font-weight-bold">ผู้รับเงิน / Receiver</span><br/><br/>
                                {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>}
            {this.props.query.pettyCashRecord.edges.length>10 &&this.state.pettyCashRecord.length> 0 && this.state.pettyCashRecord.map((val,index)=>{
                return(
                    <div className="print-page-a4" key={keyname+index} id="style-modify" style={{ position: 'relative' }}>
                <div className="subpage" style={{ overflow: 'hidden' }}>
                    <div className="head">
                        <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minHeight:100,minWidth:100}}>
                                <img src={this.props.query.selfProject.logo} alt="silverman"/>
                            </div>
                            <div>
                                <div style={{minWidth:'115%'}}>
                                    <strong>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong> 
                                </div>
                                <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                            </div>
                        </div>
                        <div className="document-name">
                            {
                                    <React.Fragment>
                                        <div className="title">ใบบันทึกจ่ายเงินสดย่อย</div>
                                        <div className="title">Petty Cash Voucher</div>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="detail mt-2">
                        <div className="row">
                            <div className="col">
                                <span className="font-weight-bold">รายการตั้งเบิก</span><br/>
                                {this.props.query.pettyCashRecord.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashRecord.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashRecord.edges[0].node.setPettyCash.description}
                            </div>
                            <div className="col">
                                <span className="font-weight-bold">เลขที่</span><br/>
                                {this.props.query.pettyCashRecord.edges[0].node.docNumber}
                            </div>
                            <div className="col">
                                <span className="font-weight-bold">วันที่ออก</span><br/>
                                {format(this.props.query.pettyCashRecord.edges[0].node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}
                            </div>
                        </div>
                    </div>

                    <div className="invoice-body">
                        <table className="table table-bordered"  >
                            <thead>
                            <tr>
                                <th width={100} className="text-center">
                                    เลขที่
                                </th>
                                <th className="text-center">
                                    วันที่
                                </th>
                                <th width={60} className="text-center">
                                    รหัสบัญชี
                                </th>
                                <th className="text-center">
                                    ชื่อบัญชี
                                </th>
                                <th className="text-center">
                                    คำอธิบาย
                                </th>
                                <th width={70} className="text-right">
                                    จำนวนเงิน
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {val.map((petty_cash_record,index) => {
                                total_price += petty_cash_record.node.price
                                    return(
                                    <React.Fragment key={petty_cash_record.node.id}>
                                        <tr>
                                            <td className="text-center">
                                                {petty_cash_record.node.docNumber}
                                            </td>
                                            <td className="text-center"> {format(petty_cash_record.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                            <td className="text-center">{petty_cash_record.node.chartOfAccount.chartOfAccountCode}</td>
                                            <td>{petty_cash_record.node.chartOfAccount.name}</td>
                                            <td>{petty_cash_record.node.description}</td>
                                            <td className="text-right">{numberWithComma(petty_cash_record.node.price)}</td>
                                        </tr>
                                    </React.Fragment>
                                    )
                            }
                            )}
                            </tbody>
                            {index === this.state.pettyCashRecord.length-1 ?
                            <tfoot className="font-weight-bold">
                                <tr>
                                    <td className="text-center" style={{borderRight: "none"}}>
                                        รวม
                                    </td>
                                    <td colSpan={4} className="text-left" style={{borderLeft:"none"}}>                                        
                                        {this.state.languages === 'th' ?
                                        (ThaiBaht(total_price)):
                                        ConvertNumberToTextEng(total_price)}
                                    </td>
                                    <td className="text-right">
                                        {numberWithComma(total_price)}
                                    </td>
                                </tr>
                            </tfoot>:
                            <tfoot>
                            <tr>
                                <td className="text-center" style={{borderTop:"none"}}></td>
                                <td  className="text-left" style={{borderTop:"none"}}></td>
                                <td className="text-right" style={{borderTop:"none"}} ></td>
                                <td className="text-right" style={{borderTop:"none"}} ></td>
                                <td className="text-right" style={{borderTop:"none"}} ></td>
                                <td className="text-right" style={{borderTop:"none"}} ></td>
                            </tr>
                        </tfoot>
                            }
                        </table>
                    </div>
                </div>
            </div>)
        })}
        {this.props.query.pettyCashRecord.edges.length>10&&this.state.accountRecord.length>0&&this.state.accountRecord.map((val,index)=>{
            return(
                <div className="print-page-a4" key={keyname+index} id="style-modify" style={{ position: 'relative' }}>
                <div className="subpage" style={{ overflow: 'hidden' }}>
                    <div className="head">
                        <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minHeight:100,minWidth:100}}>
                                <img src={this.props.query.selfProject.logo} alt="silverman"/>
                            </div>
                            <div>
                                <strong>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong>
                                <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                            </div>
                        </div>
                        <div className="document-name">
                            {
                                    <React.Fragment>
                                        <div className="title">ใบบันทึกจ่ายเงินสดย่อย</div>
                                        <div className="title">Petty Cash Voucher</div>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="detail mt-2">
                        <div className="row">
                            <div className="col">
                                <span className="font-weight-bold">รายการตั้งเบิก</span><br/>
                                {this.props.query.pettyCashRecord.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashRecord.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashRecord.edges[0].node.setPettyCash.description}
                            </div>
                            <div className="col">
                                <span className="font-weight-bold">เลขที่</span><br/>
                                {this.props.query.pettyCashRecord.edges[0].node.docNumber}
                            </div>
                            <div className="col">
                                <span className="font-weight-bold">วันที่ออก</span><br/>
                                {format(this.props.query.pettyCashRecord.edges[0].node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}
                            </div>
                        </div>
                    </div>

                    <div className="chart-of-account">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th width={100} className="text-center">
                                    รหัสบัญชี
                                </th>
                                <th className="text-center">
                                    ชื่อบัญชี
                                </th>
                                <th width={100} className="text-center">
                                    เดบิต
                                </th>
                                <th width={100} className="text-center">
                                    เครดิต
                                </th>
                            </tr>
                            </thead>
                                <React.Fragment>
                                    <tbody>
                                    {val.map((account_record) =>{
                                        return(
                                        account_record.node.debit > 0 &&
                                        <tr key={account_record.node.id}>
                                            <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                            <td>{account_record.node.name}</td>
                                            <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                            <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                        </tr>
                                        )
                                        }
                                    )}
                                    {this.state.accountRecord.length-1 === index && this.props.query.pettyCashRecordAccountRecordGroup.edges[0].node.accountRecord.edges.map((account_record) =>
                                        account_record.node.credit > 0 &&
                                        <tr key={account_record.node.id}>
                                            <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                            <td>{account_record.node.name}</td>
                                            <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                            <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                    {index === this.state.accountRecord.length-1 ?
                                    <tfoot>
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="row">
                                                <div className="col-1">
                                                    รวม/Total
                                                </div>
                                                <div className="col-10 text-center">
                                                {this.state.languages === 'th' ?
                                                (ThaiBaht(this.props.query.pettyCashRecordAccountRecordGroup.edges[0].node.totalDebit)):
                                                ConvertNumberToTextEng(this.props.query.pettyCashRecordAccountRecordGroup.edges[0].node.totalDebit)}                                                    
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            {numberWithComma(this.props.query.pettyCashRecordAccountRecordGroup.edges[0].node.totalDebit.toFixed(2))}
                                        </td>
                                        <td className="text-right">
                                            {numberWithComma(this.props.query.pettyCashRecordAccountRecordGroup.edges[0].node.totalCredit.toFixed(2))}
                                        </td>
                                    </tr>
                                    </tfoot>:
                                    
                            <tfoot>
                            <tr>
                                <td className="text-center" style={{borderTop:"none"}}></td>
                                <td  className="text-left" style={{borderTop:"none"}}></td>
                                <td className="text-right" style={{borderTop:"none"}} ></td>
                                <td className="text-right" style={{borderTop:"none"}} ></td>
                            </tr>
                        </tfoot>}
                                </React.Fragment>
                        </table>
                    </div>


                    {index === this.state.accountRecord.length-1 &&<div className="footer" style={{position:'absolute',bottom:0}} >
                        <div className="signature-large">
                            <div className="column-foursign">
                                ..................................................<br/>
                                <span className="font-weight-bold">ผู้จัดทำ / Orgenizer</span><br/><br/>
                            </div>
                            <div className="column-foursign">
                                ..................................................<br/>
                                <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span><br/><br/>
                            </div>
                            <div className="column-foursign">
                                ..................................................<br/>
                                <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span><br/><br/>
                            </div>
                            <div className="column-foursign">
                                ..................................................<br/>
                                <span className="font-weight-bold">ผู้รับเงิน / Receiver</span><br/><br/>
                            </div>
                        </div>
                    </div>
                    }

                </div>
            </div>)
        })}
    
    </React.Fragment>
    );
    }
}

export default PettyCash;
