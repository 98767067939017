

import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import '../styles/register.scss'

import { Translation } from 'react-i18next';
import Navigation from "./navigation";
import environment from "../../env/environment";
import {  QueryRenderer } from "react-relay";
import "./styles/petDetail.scss"
import Loading from "../../libs/loading";
import SeacrhDetail from '../../components/search';
import Pagination from "../../libs/newPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import localStorage from "../../libs/localstorage";
class HistoryDetailView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reQuery: false,
            search: "",
            start_date: null,
            end_date: null,
            order: "Descending" 
        }
        // Descending 

        this.handlePropsChange = this.handlePropsChange.bind(this);
    }

    getColorAndText(object) {
        let text = "";
        let color = "";
        switch (object.node.type) {
            case "OWNER":
                text = "Owner"; 
                break;
            case "RESIDENT":
                text = "Resident";
                color = "tag_dark";
                break;
            case "RENTER":
                text = "Renter";
                color = "tag_grey_04";
                break;
            case "AGENT":
                text = "Agent";
                color = "tag_agent";
                break;
            default:
                text = "Others";
                color = "";
        }
        return (<Translation>
                    {t=>
                        <td className={"text-center tag"}><span className={color}>{t(`residentialHistory:${text}`)}</span></td>
                    }
                </Translation>
         )
    }

    handlePropsChange(channel_list) {
        let search = ""
        let start_date = null
        let end_date = null
        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            } 
            else if (e.name === "start_date" && e.value !== '') {
                start_date = e.value
            } 
            else if (e.name === "end_date" && e.value !== '') {
                end_date = e.value
            }

        });
        this.setState({
            search: search,
            start_date: start_date,
            end_date: end_date,
        })
    }

    render() {
        let order_id = this.props.first - localStorage.getPageLimit();
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t=>
                            <QueryRenderer
                                environment={environment}
                                query={this.props.historyDetail}
                                variables={{
                                    id: this.props.id,
                                    search: this.state.search,
                                    start_date: this.state.start_date,
                                    end_date: this.state.end_date,
                                    first: this.props.first,
                                    last: this.props.last,
                                    order: this.state.order,
                                    reQuery: this.state.reQuery
                                }}
                                render={({ error, props }) => {
                                    if (error) {
                                        return <div>{error.message}</div>;
                                    } else if (props) {
                                        
                                        // หาคนล่าสุดที่เข้ามา
                                        let index_most = null
                                        let added_most = null
                                        props.allResidentialHistory.edges.forEach((all_residential_history,index) => {
                                            if(index === 0){
                                                index_most = index
                                                added_most = all_residential_history.node.added
                                            }
                                            else if ( added_most < all_residential_history.node.added ){
                                                index_most = index
                                                added_most = all_residential_history.node.added
                                            }
                                        })
        
                                        return (
                                            <div className="container-fluid mt-5 staff-list" id="detail">
                                                <div className="row justify-content-between">
                                                    <div className="col header-color">
                                                        <h3 className="mb-4">
                                                            <Link to="/register/residential/list/all">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                    alt="back" className="back" />
                                                            </Link>
                                                            {props.allResidentialHistory.edges[0]?.node.residential.name ? props.allResidentialHistory.edges[0]?.node.residential.name : t("residentialHistory:Residential History")}
                                                        </h3>
                                                        <div>
                                                            <Navigation id={this.props.id} />
                                                            <SeacrhDetail
                                                                selectIs={false}
                                                                startDateIs={true}
                                                                endDateIs={true}
                                                                searchBoxId={true}
                                                                search={this.state.search}
                                                                start_date={this.state.start_date}
                                                                end_date={this.state.end_date}
                                                                handlePropsChange={this.handlePropsChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
        
                                                <div className='mt-5'>
                                                    <div className="table-responsive fade-up card">
                                                        <table className="table">
                                                            <thead className="thead-light">
                                                                <tr className='text-center thFontKanit'>
                                                                    {
                                                                        this.state.order !== 'Descending' ?
                                                                            <th className='cursor' onClick={() => this.setState({order:"Descending"}) } >▼</th>
                                                                        :
                                                                            <th className='cursor' onClick={() => this.setState({order:"Ascending"}) }>▲</th> 
                                                                    }
                                                                    <th>{t("residentialHistory:NO.")}</th>
                                                                    <th>{t("residentialHistory:First Name - Last Name")}</th>
                                                                    <th>{t("residentialHistory:Nationality")}</th>
                                                                    <th>{t("residentialHistory:Date In")}</th>
                                                                    <th>{t("residentialHistory:Date Out")}</th>
                                                                    <th>{t("residentialHistory:Reason")}</th>
                                                                    <th>{t("residentialHistory:Phone")}</th>
                                                                    <th>E-mail</th>
                                                                    <th>{t("residentialHistory:Type")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {props.allResidentialHistory.totalCount > 0 ?
                                                                    props.allResidentialHistory.edges.map((all_residential_history, t_index) => {
                                                                        let row_number = 0
                                                                        //นับเลขแบบ Ascending น้อยไปมาก
                                                                        if(this.state.order === 'Ascending'){
                                                                            row_number = t_index + order_id + 1;
                                                                        }
                                                                        else{
                                                                            row_number = props.allResidentialHistory.totalCount - (t_index + order_id);
                                                                        }
                                                                        return (
                                                                            <tr key={all_residential_history.node.id}>
                                                                                <td className='text-center tdFontKanit tag'><span className={t_index === index_most ?"tags_econdary_red_red_base": ''}>{t_index === index_most && 'ล่าสุด'}</span></td>
                                                                                <td className='text-center tdFontKanit'>{row_number}</td>
                                                                                <td className='text-center tdFontKanit'>
                                                                                <Link to={{
                                                                                        pathname:
                                                                                        "/register/residential/detail/view-owner-history-detail/" +
                                                                                        all_residential_history.node.id,
                                                                                    }}
                                                                                    >    {all_residential_history.node.firstName + ' ' + all_residential_history.node.lastName} 
                                                                                </Link>
                                                                                </td>
                                                                                <td className='text-center tdFontKanit'>{all_residential_history.node.countries ? all_residential_history.node.countries : '-'}</td>
                                                                                <td className='text-center tdFontKanit'>{all_residential_history.node.liveing ? format(all_residential_history.node.liveing, "DD/MM/YYYY", { locale: thLocale }) : '-'}</td>
                                                                                <td className='text-center tdFontKanit'>{all_residential_history.node.quit ? format(all_residential_history.node.quit, "DD/MM/YYYY", { locale: thLocale }) : '-'}</td>
                                                                                <td className='text-center tdFontKanit'>{all_residential_history.node.remark ? all_residential_history.node.remark : '-'}</td>
                                                                                <td className='text-center tdFontKanit'>{all_residential_history.node.phone ? all_residential_history.node.phone : '-'}</td>
                                                                                <td className='text-center tdFontKanit'>{all_residential_history.node.email ? all_residential_history.node.email : '-'}</td>
                                                                                {/* <td className='text-center'>{all_residential_history.node.type}</td> */}
                                                                                {this.getColorAndText(all_residential_history)}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <th colSpan={10} className='text-center'>{t("residentialHistory:There is no history of resident in this room.")}</th>
                                                                    </tr>
                                                                }
        
                                                            </tbody>
                                                        </table>
                                                    </div>
        
                                                    <div className="row">
                                                        <Pagination changePage={this.props.changePage}
                                                            first={this.props.first}
                                                            last={this.props.last}
                                                            totalCount={props.allResidentialHistory.totalCount} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return <Loading />
                                }}
                            />
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default HistoryDetailView;


