import React from "react";
import "../../styles/commonArea.scss";
import './commonArea.scss'
import { Translation } from "react-i18next";

const SettingLimitTime = ({
  HandleOnChange,
  state: {
    settingLimitTime,
    settingLimitMinTime,
    settingLimitMaxTime,

    allowLimitPeopleToUseChk,
    allowLimitPeopleToUse,

    scoreType,
    pointValue,
    typeTobooking,
  },
}) => {
  return (
    <Translation>
      {t =>
        <React.Fragment>
          {/* ตั้งค่าเวลาขั้นต่ำ - สูงสุด ที่ให้ใช้บริการ */}
          <div>
            {typeTobooking &&
              <div className="booking-form mb-3 mt-8 claim">
                <div className="switch-languages-div d-flex justify-content-between create usedAll">
                  <p className="headerLabel24 fw-400">{t("newBookingSingha_Create_step2:Set the minimum and maximum time for using the service.")}</p>
                </div>

                <div className="usedAll mt-3">
                  <label className="containerRadio fw-400 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:Time limit")}
                    <input type="radio" name="settingLimitTime" value={true} defaultChecked={settingLimitTime == true}
                      onChange={HandleOnChange} />
                    <span className="checkmark checkmarks_dot_10" style={{ top: -3, width: 20, height: 20 }}></span>
                  </label>

                  {/* IF settingLimitTime */}
                  <div>
                    {
                      settingLimitTime &&
                      <div className="row ml-5">
                        <div className="col-3">
                          <label className="label16 fw-300">{t("newBookingSingha_Create_step2:Minimum")}</label>
                          <div>
                            <input type={"number"} className="form-control headerLabel16 pl-5" defaultValue={settingLimitMinTime} name="settingLimitMinTime" pattern="[0-9]+$" onChange={HandleOnChange} />
                            <label className="label16 fw-300 textInBoxInput">{t("newBookingSingha_Create_step2:Minutes")}</label>
                          </div>
                        </div>

                        <div className="col-3">
                          <label className="label16 fw-300">{t("newBookingSingha_Create_step2:Maximum")}</label>
                          <div>
                            <input type={"number"} className="form-control headerLabel16 pl-5" defaultValue={settingLimitMaxTime} name="settingLimitMaxTime" onChange={HandleOnChange} pattern="[0-9]+$" />
                            <label className="label16 fw-300 textInBoxInput">{t("newBookingSingha_Create_step2:Minutes")}</label>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <div className="usedAll flex mt-3">
                  <label className="containerRadio fw-400 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:Unlimited use")}
                    <input type="radio" name="settingLimitTime" value={false} defaultChecked={settingLimitTime == false}
                      onChange={HandleOnChange} />
                    <span className="checkmark checkmarks_dot_10" style={{ top: -4, width: 20, height: 20 }}></span>
                  </label>
                </div>
              </div>
            }
          </div>

          {/* จำนวนผู้ใช้บริการที่อนุญาตให้จอง */}
          <div className="booking-form mb-3 mt-8 claim">
            <div className="switch-languages-div d-flex justify-content-between create usedAll">
              <p className="headerLabel24 fw-400">{t("newBookingSingha_Create_step2:Number of users permitted to make reservations")}</p>
            </div>

            <div className="usedAll flex mt-3">
              <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:Maximum")}
                <input type="radio" name="allowLimitPeopleToUseChk" value={true} defaultChecked={allowLimitPeopleToUseChk == true}
                  onChange={HandleOnChange} />
                <span className="checkmark checkmarks_dot_10" style={{ top: -4, width: 20, height: 20 }}></span>
              </label>

              <input type={"number"} className="form-control ml-3 mr-3 headerLabel16 text-center"
                onChange={HandleOnChange} name="allowLimitPeopleToUse"
                style={{ width: 102 }} defaultValue={allowLimitPeopleToUse} />
              <label className="label16 fw-300">{t("newBookingSingha_Create_step1:People")}</label>
            </div>

            <div className="usedAll flex mt-3">
              <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:Unlimited use")}
                <input type="radio" name="allowLimitPeopleToUseChk" value={false} defaultChecked={allowLimitPeopleToUseChk == false}
                  onChange={HandleOnChange} />
                <span className="checkmark checkmarks_dot_10" style={{ top: -4, width: 20, height: 20 }}></span>
              </label>
            </div>

          </div>

          {/* การจัดการแต้ม */}
          <div className="booking-form mb-3 mt-8 claim">
            <div className="switch-languages-div d-flex justify-content-between create usedAll">
              <p className="headerLabel24 fw-400">{t("newBookingSingha_Create_step2:Point management")}</p>
            </div>

            <div>
              <div className="usedAll flex mt-3">
                <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:This reservation uses")}
                  <input type="radio" name="scoreType" value={"per_booking"} defaultChecked={scoreType === "per_booking"}
                    onChange={HandleOnChange} />
                  <span className="checkmark checkmarks_dot_10" style={{ top: -4, width: 20, height: 20 }}></span>
                </label>

                <input type={"number"} onChange={HandleOnChange} name="pointValue"
                  className="form-control ml-3 mr-3 text-center headerLabel16" style={{ width: 102 }}
                  defaultValue={scoreType !== "per_booking" ? 2 : pointValue}
                  disabled={scoreType !== "per_booking" } />
                <label className="label16 fw-300">{t("newBookingSingha_Create_step2:points / times")}</label>
              </div>

              <div className="usedAll flex mt-3">
                <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:This reservation uses")}
                  <input type="radio" name="scoreType" value={"per_time"} defaultChecked={scoreType === "per_time"}
                    onChange={HandleOnChange} />
                  <span className="checkmark checkmarks_dot_10" style={{ top: -4, width: 20, height: 20 }}></span>
                </label>

                <input type={"number"} onChange={HandleOnChange} name="pointValue"
                  className="form-control ml-3 mr-3 text-center headerLabel16" style={{ width: 102 }}
                  defaultValue={scoreType !== "per_time" ? 2 : pointValue} 
                  disabled={scoreType !== "per_time" } />
                <label className="label16 fw-300">{t("newBookingSingha_Create_step2:points / 30 minutes / person")}</label>
              </div>

              <div className="usedAll flex mt-3">
                <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:This reservation uses")}
                  <input type="radio" name="scoreType" value={"per_people"} defaultChecked={scoreType === "per_people"}
                    onChange={HandleOnChange} />
                  <span className="checkmark checkmarks_dot_10" style={{ top: -4, width: 20, height: 20 }}></span>
                </label>

                <input type={"number"} className="form-control ml-3 mr-3 text-center headerLabel16"
                  onChange={HandleOnChange} name="pointValue"
                  style={{ width: 102 }} defaultValue={scoreType !== "per_people" ? 2 : pointValue}
                  disabled={scoreType !== "per_people" } />
                <label className="label16 fw-300">{t("newBookingSingha_Create_step2:points / person")}</label>
              </div>

              <div className="usedAll flex mt-3">
                <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:This reservation uses")}
                  <input type="radio" name="scoreType" defaultChecked={scoreType === "per_half"}
                    onChange={HandleOnChange} value={"per_half"} />
                  <span className="checkmark"></span>
                </label>

                <input type={"number"}
                  defaultValue={scoreType !== "per_half" ? 2 : pointValue}
                  onChange={HandleOnChange} className="form-control ml-3 mr-3 text-center headerLabel16"
                  name="pointValue" style={{ width: 102 }}
                  disabled={scoreType !== "per_half" } />
                <label className="label16 fw-300">{t("newBookingSingha_Create_step2:points / 30 minutes")}</label>
              </div>

              <div className="usedAll flex mt-3">
                <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Create_step2:Free")}
                  <input type="radio" name="scoreType" value={"free"} defaultChecked={scoreType === "free"}
                    onChange={HandleOnChange} />
                  <span className="checkmark checkmarks_dot_10" style={{ top: -4, width: 20, height: 20 }}></span>
                </label>
              </div>

            </div>
          </div>
        </React.Fragment>
      }
    </Translation>

  );
};

export default SettingLimitTime;
