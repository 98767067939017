const getNameResidential = (first_name, last_name) => {
    let name = '';
    
    // replace ., to "."
    first_name = first_name.replace(".,",".") //Fix B382 การเรียงชื่อ-นามสกุล ในหน้าที่เลือกรายชื่อของการสร้างรายการเอกสารทางการเงิน

    let first_name_list = first_name.split(",");
    let last_name_list = last_name.split(",");

    first_name_list.forEach((value, index) => {
        if (index === 0) {
            name = name + value + ' ' + last_name_list[index]
        } else {
            if(last_name_list[index]){
                name = name + ',' + value + ' ' + last_name_list[index]
            }else{
                name = name + ',' + value + ' '
            }
        }
    });

    //CO. ,LTD To CO.,LTD
    if(name.includes("CO.")){
        name = name.replace("CO.","CO.,")
    }
    if(name.includes("Co.")){
        name = name.replace("Co.","Co.,")
    }
    if(name.includes("co.")){
        name = name.replace("co.","co.,")
    }

    if(name.includes(". ,")){
        name = name.replace(". ,",".,")
    }

    return name
};

export default getNameResidential;