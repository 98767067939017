import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";

class Navigation extends Component {
    render() {
        return (
            <Translation>
                {t =>
                    <div className='colorUse'>
                        <div className="row" id="navigation-tab">
                            <div className="col">
                                <ul>
                                    <li>
                                        <NavLink to="/download_file/all">
                                            {t('downloadFile:All')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/download_file/pending">
                                            {t('downloadFile:Pending')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/download_file/succeed">
                                            {t('downloadFile:Succeed')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/download_file/incomplete">
                                            {t('downloadFile:Incomplete')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/download_file/fail">
                                            {t('downloadFile:Fail')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/download_file/cancel">
                                            {t('downloadFile:Cancel')}
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                }
            </Translation>
        )
    }
}

export default Navigation;