import React from 'react';
import { QueryRenderer, commitMutation } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import _ from "lodash";
import Loading from '../../../libs/loading';
import EditPettyCashRecord from './pettyCashRecordEditDescription';
import i18next from 'i18next';

const allPettyCashRecord = graphql`
    query pettyCashRecordListTablePettyCashQuery($search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int, $setPettyCash_Id: ID) {
        allPettyCashRecord(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id){
            totalCount
            edges{
                node{
                    id
                    issuedDate
                    docNumber
                    chartOfAccount {
                        id
                        chartOfAccountCode
                        name
                    }
                    description
                    price
                    status
                    refPettyCashRecordDoc
                    voidRemark
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation pettyCashRecordListTablePettyCashMutation($input: DeletePettyCashPaymentInput!){
      deletePettyCashPayment(input: $input){
        ok
        warningText
      }
    }
`;

class PettyCashRecordListTablePettyCash extends ComponentPagination {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            redirect: false,
            reQuery: false,
            first: this.state.first,
            last: this.state.last
        };
    }

    handleChange() {
        this.setState({ reQuery: true });
    }

    pettyvoid(id) {
        // ยกเลิกจ่ายเงินสดย่อย
        Swal.fire({
            title: i18next.t("pettyCashList:Are you sure to cancel this petty cash?"),
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("pettyCashList:Confirm"),
            cancelButtonText: i18next.t("pettyCashList:Cancel")
        }).then(async (result) => {
            if (result.value) {

                let { value: void_remark } = await Swal.fire({
                    title: i18next.t("pettyCashList:Note"),
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: i18next.t("pettyCashList:Cancel"),
                    confirmButtonText: i18next.t("pettyCashList:Confirm"),
                    inputValidator: (value) => {
                        if (!value) {
                            return i18next.t("pettyCashList:Please enter reason to cancel")
                        }
                    }
                });
                if (void_remark) {
                    let variables = {
                        input: {
                            clientMutationId: id,
                            voidRemark: void_remark
                        }
                    };
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted: (response) => {

                                if (response) {
                                    if (response.deletePettyCashPayment.ok) {
                                        Swal.fire(
                                            i18next.t("Allaction:Saved Successfully"),
                                            '',
                                            'success'
                                        )
                                        this.handleChange()
                                    }
                                    else {
                                        let numberDoc = response.deletePettyCashPayment.warningText.split("เลขที่")
                                        Swal.fire(
                                            i18next.t("pettyCashList:Cancellation unsuccessful because this item has already been reimbursed."),
                                            localStorage.getItem("language") === 'th' ?
                                                response.deletePettyCashPayment.warningText :
                                                "Reimbursement of petty cash with number" + numberDoc[1] + "has been approved and processed.",
                                            'warning'
                                        )
                                    }
                                }
                            },
                            onError: (err) => {
                                Swal.fire(
                                    i18next.t("Allaction:Canceled Unsuccessful"),
                                    '',
                                    'warning'
                                );
                            },
                        },
                    )
                }
            } else {
                this.setState({ loading: false });
            }
        })
    }
    append_list(valuse) {
        let list_return = {};
        let docNumber = null;
        valuse.edges.map((result, index) => {
            if (docNumber === null) {
                docNumber = result.node.docNumber
                list_return[result.node.docNumber] = [];
                list_return[result.node.docNumber].push(result)
            } else {
                if (docNumber !== result.node.docNumber) {
                    docNumber = result.node.docNumber
                    list_return[result.node.docNumber] = [];
                    list_return[result.node.docNumber].push(result)
                } else {
                    list_return[result.node.docNumber].push(result)
                }
            }
        })
        return list_return
    }

    editDescription(id, oldDescription) {
        this.setState({ loading: true });
        let title = i18next.t("Allaction:Edit description");
        let title2 = i18next.t("purchaseCreate:*Enter text not over 80 character characters");
        Swal.fire({
            title: title,
            html: `<span class="text-danger">${title2}</span>
            <input type="text" class="swal2-input" id="editDescription" value="${oldDescription}" maxLength={80}>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            reverseButtons: true,
            preConfirm: () => {
                let editDescription = Swal.getPopup().querySelector('#editDescription').value
                return { editDescription: editDescription }
            }
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        id: id,
                        description: `${result.value.editDescription}`
                    }
                };
                EditPettyCashRecord(variables,
                    () => Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                        this.setState({
                            loading: false,
                            redirect: true
                        })
                    })
                    , () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error').then(() => {
                        this.setState({ loading: false });
                    })
                );
            } else {
                this.setState({ loading: false });
            }
        })


    }

    render() {
        if (this.state.redirect) {
            window.location.reload();
        }
        return (
            <QueryRenderer
                environment={environment}
                query={allPettyCashRecord}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status,
                    setPettyCash_Id: this.props.set_petty_cash_id,
                    reQuery: this.state.reQuery
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        let _allPettyCashRecord = this.append_list(props.allPettyCashRecord)
                        return (<React.Fragment>
                            <div className="table-responsive fade-up card" id="pettyCashRecordListTablePettyCash">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center">{i18next.t("pettyCashView:Document No.")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Date ")}</th>
                                            <th>{i18next.t("pettyCashView:List of petty cash")}</th>
                                            <th>{i18next.t("pettyCashView:Code")}</th>
                                            <th>{i18next.t("pettyCashView:Account")}</th>
                                            <th>{i18next.t("pettyCashView:List")}</th>
                                            <th className="text-right" style={{ whiteSpace: 'nowrap' }}>{i18next.t("pettyCashList:Amount withdrawal")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Status")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Note")}</th>
                                            <th />
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.entries(_allPettyCashRecord).map(([index, petty_cash_record], index_stack) => {

                                            return (
                                                petty_cash_record.map((petty, index_question) => {
                                                    return (
                                                        <tr key={petty.node.id}>
                                                            <td style={{ whiteSpace: 'nowrap' }} className={petty.node.status === "VOID" ? "text-center text-danger" : "text-center"}>
                                                                {index_question === 0 ? petty.node.docNumber : "-"}
                                                            </td>
                                                            <td className="text-center">{index_question === 0 ? format(petty.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale }) : "-"}</td>
                                                            <td>{petty.node.setPettyCash.docNumber} {petty.node.setPettyCash.withdrawer} {petty.node.setPettyCash.description}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{petty.node.chartOfAccount.chartOfAccountCode}</td>
                                                            <td>{petty.node.chartOfAccount.name}</td>
                                                            <td>
                                                                <span className='mr-4'>
                                                                    {petty.node.description}
                                                                </span>
                                                                <span className="grey-color ml-1 editDescription" onClick={() => this.editDescription(petty.node.id, petty.node.description)}>
                                                                    <img
                                                                        src={
                                                                            process.env.PUBLIC_URL +
                                                                            "/images/icons/edit.png"
                                                                        }
                                                                        alt="product-edit-icon"
                                                                        className="show-icon mr-1" />
                                                                    {i18next.t("pettyCashView:Edit")}
                                                                </span>
                                                            </td>
                                                            <td className="text-right">{numberWithComma(petty.node.price)}</td>
                                                            <td className={petty.node.status === "VOID" ? "text-center text-danger" : "text-center"}>{petty.node.status === 'WAIT' ? 'รอเบิก' : petty.node.status === "VOID" ? 'ยกเลิก' : 'เบิกแล้ว'}</td>
                                                            <td className="text-center text-danger"> {petty.node.status === "VOID" ? " (" + petty.node.voidRemark + ")" : null}</td>
                                                            <td className="text-center">
                                                                {petty.node.refPettyCashRecordDoc ?
                                                                     <a download="name_of_downloaded_file"
                                                                        href={petty.node.refPettyCashRecordDoc}
                                                                        target={"_blank"}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                            alt="back"
                                                                            className="download-file-icon" />
                                                                    </a>
                                                                    :
                                                                    "-"
                                                                }
                                                            </td>
                                                            <td>
                                                                {index_question === 0 && petty.node.status !== "VOID" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_print' }) &&

                                                                    <Link to={"/document/petty-cash/" + petty.node.id}
                                                                        target={"_blank"}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/print.png'}
                                                                            alt="print" className="print" />
                                                                    </Link>
                                                                }
                                                            </td>
                                                            <td>
                                                                {index_question === 0 && petty.node.status !== "VOID" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_delete' }) &&
                                                                    <button className="btn" onClick={() => this.pettyvoid(petty.node.id)}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                            alt="print" className="print" />
                                                                    </button>
                                                                }
                                                            </td>

                                                        </tr>
                                                    )
                                                })

                                            )
                                        }
                                        )}

                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col" />
                                <div className="col">
                                    <div className="form-inline float-right p-3 mb-2 mt-2 bg-light text-dark">
                                        <div className="text-left">{i18next.t("pettyCashView:Balance")}</div>
                                        <div className="text-right ml-5">{
                                            this.props.all_set_petty_cash_list.map((petty) => petty.node.id === this.props.set_petty_cash_id &&
                                                numberWithComma(petty.node.withdrawAmount - petty.node.totalPettyCashRecord)
                                            )
                                        } {i18next.t("pettyCashView:Baht")}</div>
                                    </div>
                                    <div className="form-inline float-right p-3 mb-2 mt-2 bg-light mr-4 text-dark">
                                        <div className="text-left">{i18next.t("pettyCashView:Total amount withdrawal")}</div>
                                        <div className="text-right ml-5">{
                                            this.props.all_set_petty_cash_list.map((petty) => petty.node.id === this.props.set_petty_cash_id &&
                                                numberWithComma(petty.node.totalPettyCashRecord)
                                            )
                                        } {i18next.t("pettyCashView:Baht")}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.allPettyCashRecord.totalCount} />
                            </div>
                        </React.Fragment>
                        )
                    }
                    return <Loading />
                }}
            />
        )
    }
}

export default PettyCashRecordListTablePettyCash;