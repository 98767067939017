import React, {Component} from 'react';
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {Redirect} from 'react-router';
import SearchSelect from "../../../libs/searchSelect";
import environment from "../../../env/environment";
import {Link} from "react-router-dom";
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import CreateCashDepositMutation from "./mutations/CreateCashDepositMutation";
import Swal from "sweetalert2";
import numberWithComma from "../../../libs/numberWithComma";
import i18n from 'i18next'
import { Translation } from 'react-i18next';
import Loading from "../../../libs/loading"

const allBankAccount = graphql`
   query cashDepositCreateFormQuery{
     bankAccountViewer{
      allBankAccount(status: true){
        edges{
          node{
            id
            accountNumber
            accountName
            bankName
            accountType
          }
        }
      }
    }
   }
`;

class CashDepositCreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cash_list: this.props.select_cash_transaction,
            cash_deposit_total: 0,
            bank_select: '',
            depositDate: new Date(),
            depositTime: "00:00",
            description: '',
            image_upload: '',
            loading: false,
            error: false,
            success: false,

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentWillMount() {
        let cash_deposit_total = 0.0;
        this.state.cash_list.forEach((cash)=>{
            cash_deposit_total = cash_deposit_total + cash.price
        });
        this.setState({cash_deposit_total: cash_deposit_total})
    }

    handleInputChange(e) {
        if (e.target.name === 'image_upload') {
            if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
                this.setState({image_upload: e.currentTarget.files[0]});
            }
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true, success: false, error: false});
        let input = {
            cashDepositGroup: {
                depositDateTime: new Date(`${this.state.depositDate.toDateString()} ${this.state.depositTime} +07:00`),
                bankAccountId: this.state.bank_select,
                total: this.state.cash_deposit_total,
                transactions: JSON.stringify(this.state.cash_list),
            }
        };
        const uploadables = {image: this.state.image_upload};
        CreateCashDepositMutation(input, uploadables, this.onSuccess, this.onError)

    }

    onSuccess(response) {
        this.setState({loading: false});
        if (response.cashDepositGroupCreate.ok) {
            Swal.fire(i18n.t("cash:Save successfully"), '', 'success').then(()=>{
                this.setState({success: true});
            });

        } else {
            Swal.fire(i18n.t("cash:Failed to save"), response.cashDepositGroupCreate.warningText, 'warning');
            this.setState({error: false});
        }

    }

    onError() {
        this.setState({loading: false, error: true});
         Swal.fire(i18n.t("cash:Failed to save"), '', 'error');
    }


    render() {
        if (this.props.select_cash_transaction.length > 0 && !this.state.success) {
            return (
                <React.Fragment>
                    <div className="table-responsive fade-up">
                        <table className="table table-hover mt-2">
                            <thead className="thead-light">
                            <Translation>
                                {t=>
                                <tr>
                                    <th>{t("cash:No.")}</th>
                                    <th>{t("cash:Date")}</th>
                                    <th>{t("cash:Description")}</th>
                                    <th className="text-right">{t("cash:Amount")}</th>
                                </tr>}
                            </Translation>
                            </thead>
                            <tbody>
                            {this.state.cash_list.map((select_cash_transaction) => {
                                let doc_number = '';
                                let added = '';

                                if (select_cash_transaction.receive) {
                                    doc_number = select_cash_transaction.receive.docNumber;
                                    added = select_cash_transaction.receive.issuedDate;
                                } else if (select_cash_transaction.invoice) {
                                    doc_number = select_cash_transaction.invoice.docNumber;
                                    added = select_cash_transaction.invoice.issuedDate;
                                } else if (select_cash_transaction.receiptDeposit) {
                                    doc_number = select_cash_transaction.receiptDeposit.docNumber;
                                    added = select_cash_transaction.receiptDeposit.issuedDate;
                                } else if (select_cash_transaction.guaranteeMoneyReceive) {
                                    doc_number = select_cash_transaction.guaranteeMoneyReceive.docNumber;
                                    added = select_cash_transaction.guaranteeMoneyReceive.issuedDate;
                                } else if (select_cash_transaction.clearAdvance) {
                                    doc_number = select_cash_transaction.clearAdvance.docNumber;
                                    added = select_cash_transaction.clearAdvance.issuedDate;
                                }
                                else if (select_cash_transaction.otherReceive) {
                                    doc_number = select_cash_transaction.otherReceive.docNumber;
                                    added = select_cash_transaction.otherReceive.issuedDate;
                                }
                                return (
                                    <tr key={select_cash_transaction.id}>
                                        <td>
                                            {doc_number}
                                        </td>
                                        <td>{format(added, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                        <td>{select_cash_transaction.description}</td>
                                        <Translation>{t=><td className="text-right">{numberWithComma(select_cash_transaction.price)} {t("cash:Baht")}</td>}</Translation>
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </table>
                    </div>

                    <div className="row mt-2 fade-up">
                        <div className="col-9"/>
                        <div className="col-3">
                            <div className="col">
                                <div className="row p-3 mb-2 bg-light text-dark">
                                    <Translation>{t=><div className="col text-left"><strong>{t("cash:Total deposit")}</strong></div>}</Translation>
                                    <Translation>{t=><div className="col text-right"><strong>{numberWithComma(this.state.cash_deposit_total)} {t("cash:Baht")}</strong></div>}</Translation>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-2 fade-up">
                        <form id="create-cash-deposit" onSubmit={this.onSubmit}>
                            <div className="card-body">

                                <div className="row mt-3">
                                    <div className="col-6">
                                        <Translation>{t=><p>{t("cash:Deposit method")}</p>}</Translation>
                                        <QueryRenderer
                                            environment={environment}
                                            query={allBankAccount}
                                            variables={{}}
                                            render={({error, props}) => {
                                                if (error) {
                                                    return <div>{error.message}</div>;
                                                } else if (props) {
                                                    return <React.Fragment>
                                                        <SearchSelect onChange={this.handleInputChange}
                                                                      value={this.state.bank_select}
                                                                      name="bank_select" require={true}
                                                                      placeholder="กรุณาเลือกธนาคาร"
                                                                      queryObject={props.bankAccountViewer.allBankAccount.edges}
                                                                      keyOfValue="id"
                                                                      keyOfLabel="bankName:accountType:accountNumber"/>

                                                    </React.Fragment>
                                                }
                                                return <Loading/>
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="row mt-4">
                                    <div className="col-2" >
                                        <Translation>{t=><p>{t("cash:Deposit date")}</p>}</Translation>
                                        <div className="input-group">
                                            <DatePickerAdapter
                                                name="depositDate" className="form-control"
                                                selected={this.state.depositDate} /*maxDate={this.state.dueDate}*/
                                                onChange={this.handleInputChange}
                                                required={true}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <Translation>{t=><p>{t("cash:Deposit time")}</p>}</Translation>
                                        <div className="input-group" >
                                            <input type="time" className="form-control"
                                                   name="depositTime" value={this.state.depositTime}
                                                   onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col bottom">
                                        <Translation>
                                            {t=>
                                            <label
                                                className={this.state.image_upload.name ? "btn btn-success mb-0 cursor font-medium mr-3" : "btn btn-secondary mb-0 cursor font-medium mr-3"}>
                                                {this.state.image_upload.name ? t("cash:Done") : t("cash:Attach file")}
                                                <input type="file" name="image_upload"
                                                       onChange={this.handleInputChange}
                                                       hidden/>
                                            </label>}
                                        </Translation>
                                        {this.state.image_upload.name}
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="row mt-3">
                        <div className="col text-right">
                            <div className="btn-group mr-2">
                                <Link to="/accounting/finance/cash/list">
                                    <Translation>
                                        {t=>
                                        <button type="button" className="btn btn-secondary add">
                                            {t("cash:Cancel")}
                                        </button>}
                                    </Translation>
                                </Link>
                            </div>
                            <div className="btn-group mr-2">
                                <Translation>
                                    {t=>
                                    <button type="submit" className="btn btn-primary add" form="create-cash-deposit" disabled={this.state.loading}>
                                        {this.state.loading &&
                                        <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                        {t("cash:Create cash deposit")}
                                    </button>}
                                </Translation>
                            </div>
                        </div>
                    </div>

                </React.Fragment>

            )
        }
        // return <Redirect to={{
        //     pathname: "/document/cash-deposit-print/`",
        //     state: {select_cash_transaction: this.state.cash_list}
        // }}/>;
        return <Redirect to="/accounting/finance/cash/list" />;
    }
}

export default CashDepositCreateForm;
