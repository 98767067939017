/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type ProjectEstablishmentStatus = "ALLOCATED" | "OTHER" | "UNALLOCATED" | "%future added value";
export type ProjectTypeOfCompany = "COMMERCIAL" | "DEVELOPER" | "MANAGE_YOURSELF" | "PROPERTY_MANAGEMENT" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type settingFormQueryVariables = {|
  formDebit?: ?number
|};
export type settingFormQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +bankCompCode: ?string,
    +bankServiceCode: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
    +typeOfCompany: ?ProjectTypeOfCompany,
    +establishmentStatus: ?ProjectEstablishmentStatus,
  |},
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountNameEn: ?string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +mobilePayment: boolean,
          +crossBilling: boolean,
          +bankName: string,
          +branch: string,
          +status: boolean,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |},
  +allSettingFormDebit: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +description: ?string,
        +descriptionEng: ?string,
        +descriptionSecond: ?string,
        +descriptionSecondEng: ?string,
        +paymentCheck: boolean,
        +paymentTimeStatus: boolean,
        +paymentTime1: ?any,
        +paymentTime2: ?any,
        +bankAccountStatus: boolean,
        +numberOfDays: number,
        +bankAccount: ?{|
          +id: string,
          +accountNumber: string,
          +bankName: string,
          +accountName: string,
          +accountNameEn: ?string,
          +branch: string,
        |},
        +channelDescription: ?string,
        +contactName: ?string,
        +contactPhone: ?string,
        +creatorName: ?string,
        +creatorNameEng: ?string,
        +signerName: ?string,
        +signerPosition: ?string,
        +formDebit: number,
        +refDocument: boolean,
        +refReport: boolean,
        +refCollection1: boolean,
        +refCollection2: boolean,
        +refCollection3: boolean,
      |}
    |}>
  |},
|};
export type settingFormQuery = {|
  variables: settingFormQueryVariables,
  response: settingFormQueryResponse,
|};
*/


/*
query settingFormQuery(
  $formDebit: Int
) {
  selfProject {
    id
    name
    address
    logo
    juristicContactNumber
    keyProjectQr
    bankCompCode
    bankServiceCode
    taxIdNumber
    typeOfProject
    typeOfCompany
    establishmentStatus
  }
  bankAccountViewer {
    allBankAccount(status: true) {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountNameEn
          accountType
          receivePayment
          makePayment
          mobilePayment
          crossBilling
          bankName
          branch
          status
        }
      }
      totalCount
    }
    id
  }
  allSettingFormDebit(formDebit: $formDebit) {
    edges {
      node {
        id
        description
        descriptionEng
        descriptionSecond
        descriptionSecondEng
        paymentCheck
        paymentTimeStatus
        paymentTime1
        paymentTime2
        bankAccountStatus
        numberOfDays
        bankAccount {
          id
          accountNumber
          bankName
          accountName
          accountNameEn
          branch
        }
        channelDescription
        contactName
        contactPhone
        creatorName
        creatorNameEng
        signerName
        signerPosition
        formDebit
        refDocument
        refReport
        refCollection1
        refCollection2
        refCollection3
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "formDebit"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyProjectQr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankCompCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankServiceCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfCompany",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "establishmentStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNameEn",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "branch",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "receivePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "makePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mobilePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "crossBilling",
              "storageKey": null
            },
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "allBankAccount(status:true)"
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "formDebit",
      "variableName": "formDebit"
    }
  ],
  "concreteType": "SettingFormDebitNodeConnection",
  "kind": "LinkedField",
  "name": "allSettingFormDebit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SettingFormDebitNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SettingFormDebitNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "descriptionEng",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "descriptionSecond",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "descriptionSecondEng",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentCheck",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentTimeStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentTime1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentTime2",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bankAccountStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfDays",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "bankAccount",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/),
                (v6/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "channelDescription",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactPhone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creatorName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creatorNameEng",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signerName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signerPosition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refDocument",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refReport",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refCollection1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refCollection2",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refCollection3",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingFormQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingFormQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "0cd1f059be4363117c95bc90b1126622",
    "id": null,
    "metadata": {},
    "name": "settingFormQuery",
    "operationKind": "query",
    "text": "query settingFormQuery(\n  $formDebit: Int\n) {\n  selfProject {\n    id\n    name\n    address\n    logo\n    juristicContactNumber\n    keyProjectQr\n    bankCompCode\n    bankServiceCode\n    taxIdNumber\n    typeOfProject\n    typeOfCompany\n    establishmentStatus\n  }\n  bankAccountViewer {\n    allBankAccount(status: true) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountNameEn\n          accountType\n          receivePayment\n          makePayment\n          mobilePayment\n          crossBilling\n          bankName\n          branch\n          status\n        }\n      }\n      totalCount\n    }\n    id\n  }\n  allSettingFormDebit(formDebit: $formDebit) {\n    edges {\n      node {\n        id\n        description\n        descriptionEng\n        descriptionSecond\n        descriptionSecondEng\n        paymentCheck\n        paymentTimeStatus\n        paymentTime1\n        paymentTime2\n        bankAccountStatus\n        numberOfDays\n        bankAccount {\n          id\n          accountNumber\n          bankName\n          accountName\n          accountNameEn\n          branch\n        }\n        channelDescription\n        contactName\n        contactPhone\n        creatorName\n        creatorNameEng\n        signerName\n        signerPosition\n        formDebit\n        refDocument\n        refReport\n        refCollection1\n        refCollection2\n        refCollection3\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '335ac3fb82f92c6017da492390e8f583';

module.exports = node;
