import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import Receivable from "./receivable/index";
import Payable from "./payable/index";
import Asset from "./asset/index";
import Slip from "./slip/index";
import NoMatch from "../../components/noMatch";
import Finance from "./finance/index";
import Logs from './logs';
class Report extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute path={`${this.props.match.url}/account-receivable`} component={Receivable}/>
                <PrivateRoute path={`${this.props.match.url}/account-payable`} component={Payable}/>
                {/* <PrivateRoute path={`${this.props.match.url}/finance`} component={Receivable}/> */}
                <PrivateRoute path={`${this.props.match.url}/finance`} component={Finance}/>
                <PrivateRoute path={`${this.props.match.url}/asset`} component={Asset}/>
                <PrivateRoute path={`${this.props.match.url}/slip`} component={Slip}/>
                <PrivateRoute path={`${this.props.match.url}/log`} component={Logs}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Report;
