import React from "react";
import getDocumentNumber from "../../../../libs/getDocumentNumber";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import numberWithComma from "../../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import getNameResidential from "../../../../libs/getNameResidential";
import i18next from "i18next";

const _ = require("lodash");

const query = graphql`
  query payTransactionListQuery($id_in: [String]!, $contact_id: ID!) {
    allPurchaseRecord(id_In: $id_in) {
      totalCount
      edges {
        node {
          id
          description
          unitItems
          price
          preTaxAmount
          vat
          vatAmount
          paid
          whtRate
          whtRatePercent
          total

          prepaidDepositTransactionUsed {
            edges {
                node {
                    amount
                    prepaidDepositTransaction{
                        whtRatePercent
                        whtAmount
                    }
                }
            }
        }

          chartOfAccount {
            name
            chartOfAccountCode
          }

          productAndService {
            productCode
          }

          purchaseRecordGroup {
            id
            docNumber
            issuedDate
          }

          acceptCreditNoteTransaction(status: "paid") {
            edges {
              node {
                id
                docNumber
                issuedDate
                price
                total
                description
                purchaseRecord {
                  chartOfAccount {
                    chartOfAccountCode
                    name
                  }
                }
              }
            }
          }
          
        }
      }
    }
    contact(id: $contact_id) {
      id
      refNumber
      name
      firstName
      lastName

      registeredAddress
      registeredCountry
      registeredProvince
      registeredDistrict
      registeredCity
      registeredPostalCode
      typeOfContact
    }
    allProjects {
      edges {
        node {
            periodCreate
        }
      }
    }

  }
`;

class PayTransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "pay",
      issuedDate: new Date(),
      transactions: false,
      temp_month_before: '',
      temp_month_after: '',
      period_create: '',
      min_date: new Date(),
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    let event = _.cloneDeep(e);
    let name = event.target.name;
    this.setState(
      (prevState) => {
        return _.set(
          _.cloneDeep(prevState),
          event.target.name,
          event.target.value
        );
      },
      () => this.props.updateState(name, event.target.value)
    );
  }

  componentWillMount() {
    let transactions = [];
    this.props.transactions.forEach((transaction) => {
      transactions.push(transaction.transaction_id);
    });

    fetchQuery(environment, query, {
      id_in: transactions,
      contact_id: this.props.contactList,
    }).then((response_data) => {
      if (_.isObject(response_data.allPurchaseRecord)) {
        let data_set = _.cloneDeep(response_data);
        let total = 0;
        let wht = 0;
        let transactions_list = [];
        data_set.allPurchaseRecord.edges.forEach((transaction) => {
          this.props.transactions.forEach((selected_transaction) => {
            if (transaction.node.id === selected_transaction.transaction_id) {
              _.set(transaction, "node.amount_pay", selected_transaction.amount_pay);
              _.set(transaction, "node.whtRate", selected_transaction.whtRate);
              total += parseFloat(selected_transaction.amount_pay);
              wht += parseFloat(selected_transaction.whtRate);
              transactions_list.push({ type: "transaction", object: _.get(transaction, "node"), });
            }
          });
        });
        wht = parseFloat(wht.toFixed(2));
        total = parseFloat(total.toFixed(2));

        // หาวันที่ไม่ให้ทำจ่ายได้ก่อนวันที่ตั้งค่าใช้จ่าย
        let allDate = [];
        if (data_set.allPurchaseRecord.edges.length > 1) {
          data_set.allPurchaseRecord.edges.forEach((value) => {
            allDate.push(new Date(value.node.purchaseRecordGroup.issuedDate))
          })
        } else {
          allDate.push(new Date(data_set.allPurchaseRecord.edges[0].node.purchaseRecordGroup.issuedDate))
        }
        let sortDate = allDate.sort((date1, date2) => {
          return date1 - date2
        })
        let max_issuedDate = 0
        if (sortDate.length > 0) {
          max_issuedDate = sortDate.length - 1
        }

        this.setState({
          transactions: { transactions_list: transactions_list, contact: data_set.contact, }, total: total,
          min_date: new Date(sortDate[max_issuedDate])
        });
        this.props.updateState("total", parseFloat((total + wht).toFixed(2)));
        this.props.updateState("wht", wht || 0);
        this.props.updateState("transactions_list", transactions_list);
      }
      if (response_data) {
        let data = _.cloneDeep(response_data);
        this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
      }

    });
  }

  calPeriodMouth(val) {
    let month_before = new Date();
    let month_after = new Date();

    month_before.setMonth(month_before.getMonth() - parseInt(val));
    month_after.setMonth(month_after.getMonth() + parseInt(val));

    this.setState({
      temp_month_before: month_before,
      temp_month_after: month_after,
    })
  }
  getTotalAmount(purchaseRecord) {
    let sum_prepaid_deposit = purchaseRecord.prepaidDepositTransactionUsed.edges.reduce(function (total, obj) {
      return total + parseFloat(obj.node.amount);
    }, 0);
    return (
      parseFloat(purchaseRecord.preTaxAmount) - parseFloat(sum_prepaid_deposit)
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.transactions && (
          <React.Fragment>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <p>
                      <strong>{i18next.t("payCreateAccrued:Accounts Payable")}</strong>
                    </p>
                    <div className="input-group">
                      {this.state.transactions && (
                        <input
                          type="text"
                          className="form-control"
                          value={
                            this.state.transactions.contact.typeOfContact === "RESIDENTIAL" ?
                              this.state.transactions.contact.refNumber +
                              " " + getNameResidential(
                                this.state.transactions.contact.firstName,
                                this.state.transactions.contact.lastName
                              )
                              : this.state.transactions.contact.refNumber +
                              " " +
                              this.state.transactions.contact.name
                          }
                          disabled
                        />
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <p>
                      <strong>{i18next.t("payCreateAccrued:Document number")}</strong>
                    </p>
                    <div className="input-group">
                      <input
                        value={getDocumentNumber.get(
                          this.state.category + '-draft',
                          "XXX",
                          this.state.issued_date
                        )}
                        type="text"
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col">
                    <p>
                      <strong>{i18next.t("payCreateAccrued:Date")}</strong>
                    </p>
                    <div className="input-group">
                      {
                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                          <DatePickerAdapter
                            name="issuedDate"
                            className="form-control"
                            selected={this.state.issuedDate}
                            minDate={this.state.temp_month_before}
                            // maxDate={this.state.temp_month_after}
                            onChange={this.handleInputChange}
                            required={true}
                          /> :
                          <DatePickerAdapter
                            name="issuedDate"
                            className="form-control"
                            selected={
                              this.state.issuedDate
                            } /*maxDate={this.state.dueDate}*/
                            onChange={this.handleInputChange}
                            minDate={this.state.min_date && this.state.min_date}
                            required={true}
                          />
                      }
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col">
                    <p>
                      <strong>{i18next.t("payCreateAccrued:Address")}</strong>
                    </p>
                    {this.state.transactions?.contact.registeredProvince.includes("กรุงเทพ")
                      || this.state.transactions?.contact.registeredProvince.includes("กทม") ? (
                      <>
                        {this.state.transactions.contact.registeredAddress}
                        {this.state.transactions.contact.registeredDistrict &&
                          ` แขวง${this.state.transactions.contact.registeredDistrict}`}
                        {this.state.transactions.contact.registeredCity &&
                          ` เขต${this.state.transactions.contact.registeredCity}`}
                        {this.state.transactions.contact.registeredProvince &&
                          ` ${this.state.transactions.contact.registeredProvince} `}
                        {this.state.transactions.contact.registeredPostalCode}
                      </>
                    ) : (
                      <>
                        {this.state.transactions.contact.registeredAddress}
                        {this.state.transactions.contact.registeredDistrict &&
                          ` ตำบล${this.state.transactions.contact.registeredDistrict}`}
                        {this.state.transactions.contact.registeredCity &&
                          ` อำเภอ${this.state.transactions.contact.registeredCity}`}
                        {this.state.transactions.contact.registeredProvince &&
                          ` จังหวัด${this.state.transactions.contact.registeredProvince} `}
                        {this.state.transactions.contact.registeredPostalCode}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive fade-up">
              <table className="table table-hover mt-5">
                <thead className="thead-light">
                  <tr>
                    <th width="80">{i18next.t("payCreateAccrued:Account")}</th>
                    <th width="80">{i18next.t("payCreateAccrued:List")}</th>
                    <th width="200">{i18next.t("payCreateAccrued:Description")}</th>
                    <th className="text-right" width="80">
                    {i18next.t("payCreateAccrued:Amount ")}
                    </th>
                    {/* <th className="text-right" width="100">
                      ภาษี
                    </th> */}
                    <th className="text-right" width="100">
                    {i18next.t("payCreateAccrued:Withholding tax (Baht)")}
                    </th>
                    <th className="text-right" width="80">
                    {i18next.t("payCreateAccrued:Total Amount")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.transactions &&
                    this.state.transactions.transactions_list.map((transaction) => {
                      if (transaction.type === "transaction") {
                        const whtAmount = transaction.object.whtRate

                        let acnsums = 0
                        let sumpd = 0
                        transaction.object.acceptCreditNoteTransaction.edges.forEach((acnsum) => {
                          acnsums = + acnsum.node.total
                        }
                        )
                        if (_.find(transaction.object.prepaidDepositTransactionUsed.edges, { node: { prepaidDepositTransaction: { whtRatePercent: null } } }) === undefined) {
                          transaction.object.prepaidDepositTransactionUsed.edges.forEach((pdsum) => {
                            if (pdsum.node.prepaidDepositTransaction.whtRatePercent) {
                              sumpd = sumpd + Math.round((parseFloat(pdsum.node.amount) / (1 + parseFloat(transaction.object.vat || 0) / 100) + Number.EPSILON) * 100) / 100;
                            }
                          }
                          )
                        }

                        return (
                          <tr key={transaction.object.id}>
                            <td>
                              {
                                transaction.object.chartOfAccount.chartOfAccountCode
                              }{" "}
                              {transaction.object.chartOfAccount.name}
                            </td>
                            <td>
                              ({transaction.object.purchaseRecordGroup.docNumber}){" "}
                              {transaction.object.productAndService && transaction.object.productAndService.productCode}
                            </td>
                            <td>{transaction.object.description}</td>
                            <td className="text-right">
                              {numberWithComma(transaction.object.total - acnsums - sumpd, 0)}
                              {/* {numberWithComma(this.getTotalAmount(transaction.object))} */}
                            </td>
                            {/* <td className="text-right">
                                {numberWithComma(vatAmount,0)}
                              </td> */}
                            <td className="text-right">
                              {numberWithComma(whtAmount, 0)}
                            </td>
                            <td className="text-right">
                              {numberWithComma(transaction.object.amount_pay)}
                            </td>
                          </tr>
                        );
                      }
                      return (
                        <tr key={transaction.object.id}>
                          <td>
                            {transaction.object.purchaseRecord.chartOfAccount.chartOfAccountCode}{" "}
                            {transaction.object.purchaseRecord.chartOfAccount.name}
                          </td>
                          <td>({transaction.object.docNumber})</td>
                          <td>{transaction.object.description}</td>
                          <td className="text-right">
                            - {numberWithComma(transaction.object.price)}
                          </td>
                          <td className="text-right">-</td>

                          <td className="text-right">-</td>
                          <td className="text-right">
                            - {numberWithComma(transaction.object.price)}
                          </td>
                        </tr>
                      );
                    }
                    )}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default PayTransactionList;
