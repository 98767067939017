import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import UploadImageInput from '../../../libs/uploadImageInput';
import SelectNamePrefix from '../../../libs/selectNamePrefix'
import DatePickerAdapter from '../../../libs/datePickerAdapter';
import _ from 'lodash';
import Swal from 'sweetalert2';
import i18n from 'i18next';
import i18next from 'i18next';


const newTenant = {
    imageUpload : '',
    image : '',
    typeResidential : '',
    nameTitle : '',
    gender : 'other',
    firstName : '',
    lastName  : '',
    dateOfBirth : '',
    idCardNumber : '',
    countries : 'TH',
    country_list : [],
    phone : '',
    email : '',
    firstNameEng : '',
    lastNameEng : '',
    occupation : '',
    tenantStatus : 'unspecified',
    ethnicity : 'TH',
    cardType : 'unspecified',
    phone2 : '',
    namePrefixOther : ''
}
class OwnershipInformation extends Component {
    constructor(props){
        super(props);
        this.state = {
            country_list : [],
            tenant : this.props.state.tenant ? this.props.state.tenant.length > 0 ? this.props.state.tenant : [newTenant] : [newTenant]

        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(){

        let country_nationality = require('../../../api/Json/countryNationalityList.json');
        country_nationality = country_nationality.sort((a,b) => (a.nationality.toLowerCase() < b.nationality.toLowerCase()) ? -1 : ((b.nationality.toLowerCase() > a.nationality.toLowerCase()) ? 1 : 0));
        this.setState({
            // country_list: require('country-list').getData(),
            country_list: country_nationality
        });
    }

    addNewTenant = () => {
        let tenant = [...this.state.tenant , newTenant]
        this.setState({
            tenant : tenant
        })
    }

    handleChange (e) {
        let event = _.cloneDeep(e)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        },() => this.props.callBack(this.state.tenant));
    }

    onDelete = (index) => {
        Swal.fire({
            title: i18next.t("ownerShipTransfer:Are you sure to delete this transferee")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t('settingNote:Yes'),
            cancelButtonText: i18n.t('settingNote:Cancel')
        }).then((result) => {
            if (result.value) {
                let tenant = _.cloneDeep(this.state.tenant)
                tenant.splice(index,1)
                this.setState({
                    tenant : tenant
                })
            }
         })  
    }

    render(){
        return(
            <Translation>
                {
                    t => 
                        <React.Fragment>
                            {this.state.tenant.map((tenant,index) => {
                                return (
                                    <React.Fragment key ={index}>
                                        <div className="row col-12 mt-4">
                                            <div className="col-2 d-flex justify-content-center">
                                                <img
                                                    src={tenant.image ? tenant.image: process.env.PUBLIC_URL + '/images/icons/profile-manager-icon.png'}
                                                    alt="project-manager-profile"
                                                    className="rounded-circle avatar-img profile-img"/>
                                            </div>
                                            <div className="col-10 d-flex  mb-2 justify-content-left mt-3">
                                                <div className="upload-file">
                                                    <button className="btn btn-outline-primary" type="button">
                                                        <label htmlFor={"tenant["+index+"]uploadProfileImages"}>{t('projectManager:Change profile image')}</label>
                                                    </button>
                                                    <div className="upload-input">
                                                        <UploadImageInput
                                                            id={"tenant["+index+"]uploadProfileImages"}
                                                            setField={"tenant[" + index + "].imageUpload:tenant[" + index + "].image"}
                                                            onChange={this.handleChange}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3"><h5>{t("changeOwnership:Name prefix")} <span className="text-danger">*</span></h5></div>
                                            <div className="col-3">
                                                <SelectNamePrefix  callBack={this.handleChange} 
                                                    name={"tenant[" + index + "].nameTitle"}
                                                    value={tenant.nameTitle} required={true} residential={true} index={index}/>
                                            </div>
                                            <div className="col-9" />

                                            {tenant.nameTitle === 'other_input' &&
                                                <React.Fragment>
                                                    <div className="col-12 mt-3">
                                                        <h5>{t("changeOwnership:Specifies ame prefix")} <span className="text-danger">*</span></h5>
                                                    </div>
                                                    <div className="col-6">
                                                        <input type='text' onChange={this.handleChange} 
                                                        name={"tenant[" + index + "].namePrefixOther"}  
                                                        index={index}
                                                        required={tenant.nameTitle === 'other_input'}
                                                        className="form-control" value={tenant.namePrefixOther} />
                                                    </div>
                                                    <div className="col-6" />
                                                </React.Fragment>
                                            }
    
                                            <React.Fragment>
                                                <div className={tenant.nameTitle === 'บริษัท' ? "col-12 mt-3" : "col-6 mt-3"}><h5>{t("changeOwnership:Name (Thai)")} <span className="text-danger">*</span></h5></div>
                                                {tenant.nameTitle !== 'บริษัท' && <div className="col-6 mt-3"><h5>{t("changeOwnership:Surname (Thai)")} <span className="text-danger">*</span></h5></div>}
                                                <div className="col-6">
                                                    <input type='text' onChange={this.handleChange} 
                                                    name={"tenant[" + index + "].firstName"} 
                                                    required={true} className="form-control"
                                                    index={index}
                                                    value={tenant.firstName || ''} />
                                                </div>
                                                {tenant.nameTitle !== 'บริษัท' &&
                                                    <div className="col-6">
                                                        <input type='text' onChange={this.handleChange} 
                                                        index={index} required={tenant.nameTitle !== 'บริษัท'}
                                                        name={"tenant[" + index + "].lastName"}  className="form-control" value={tenant.lastName} />
                                                    </div>
                                                }
                                            </React.Fragment>
    
                                            <React.Fragment>
                                                <div className={tenant.nameTitle === 'บริษัท' ? "col-12 mt-3" : "col-6 mt-3"}><h5>{t("changeOwnership:Name (Eng)")}</h5></div>
                                                {tenant.nameTitle !== 'บริษัท' && <div className="col-6 mt-3"><h5>{t("changeOwnership:Surname (Eng)")}</h5></div>}
                                                <div className="col-6">
                                                    <input type='text' onChange={this.handleChange} 
                                                    name={"tenant[" + index + "].firstNameEng"}  index={index}
                                                    className="form-control" value={tenant.firstNameEng || ''} />
                                                </div>
                                                {tenant.nameTitle !== 'บริษัท' &&
                                                    <div className="col-6">
                                                        <input type='text' onChange={this.handleChange} index={index} 
                                                        name={"tenant[" + index + "].lastNameEng"}  className="form-control" value={tenant.lastNameEng} />
                                                    </div>
                                                }
                                            </React.Fragment>
    
                                            {tenant.nameTitle !== 'บริษัท' && 
                                                <React.Fragment>
                                                    <div className="col-6 mt-3"><h5>{t("changeOwnership:Occupation")}</h5></div>
                                                    <div className="col-2 mt-3"><h5>{t("changeOwnership:Date of birth")}</h5></div>
                                                    <div className="col-2 mt-3"><h5>{t("changeOwnership:Nationality")}</h5></div>
                                                    <div className="col-2 mt-3"><h5>{t("changeOwnership:Ethnicity")}</h5></div>
                                                    <div className="col-6">
                                                        <input type='text' onChange={this.handleChange}  index={index}
                                                        name={"tenant[" + index + "].occupation"} className="form-control" value={tenant.occupation} />
                                                    </div>
                                                    <div className="col-2">
                                                        <DatePickerAdapter 
                                                        selected={tenant.dateOfBirth} onChange={this.handleChange} 
                                                        className="form-control col-12"
                                                        index={index}
                                                        name={"tenant[" + index + "].dateOfBirth"} />
                                                    </div>
                                                    <div className="col-2">
                                                        <select className="form-control mt-1 dropdown-custom-arrow"
                                                                value={tenant.countries}
                                                                onChange={this.handleChange}
                                                                required={tenant.nameTitle !== 'บริษัท'}
                                                                index={index}
                                                                name={"tenant[" + index + "].countries"}
                                                        >
                                                            {this.state.country_list.map((country) =>
                                                                <option value={country.alpha_2_code}
                                                                        key={country.alpha_2_code}>{country.nationality}</option>
                                                            )}
                
                                                        </select>
                                                    </div> 
                                                    <div className="col-2">
                                                        <select className="form-control mt-1 dropdown-custom-arrow"
                                                                value={tenant.ethnicity}
                                                                onChange={this.handleChange}
                                                                index={index}
                                                                required={tenant.nameTitle !== 'บริษัท'}
                                                                name={"tenant[" + index + "].ethnicity"}
                                                        >
                                                            {this.state.country_list.map((country) =>
                                                                <option value={country.alpha_2_code}
                                                                        key={country.alpha_2_code}>{country.nationality}</option>
                                                            )}
                
                                                        </select>
                                                    </div> 
                                                </React.Fragment>
                                            }
    
                                            {tenant.nameTitle !== 'บริษัท' && 
                                            <React.Fragment>
                                                <div className="col-3 mt-3"><h5>{t("changeOwnership:Sex")}</h5></div>
                                                <div className="col-3 mt-3"><h5>{t("changeOwnership:Status")}</h5></div>
                                                <div className="col-6" />
                                                <div className="col-3">
                                                    <select className="form-control mt-1 dropdown-custom-arrow"
                                                            name={"tenant[" + index + "].gender"}
                                                            onChange={this.handleChange}
                                                            index={index}
                                                            required={tenant.nameTitle !== 'บริษัท'}
                                                            value={tenant.gender}>
                                                        <option value="other">{t("settingDeveloper:na")}</option>
                                                        <option value="female">{t("resident:Female")}</option>
                                                        <option value="male">{t("resident:Male")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-3">
                                                    <select className="form-control mt-1 dropdown-custom-arrow"
                                                            name={"tenant[" + index + "].tenantStatus"}
                                                            onChange={this.handleChange}
                                                            index={index}
                                                            value={_.lowerCase(tenant.tenantStatus)}>
                                                        <option value="unspecified">{t("settingDeveloper:na")}</option>
                                                        <option value="single">{t("resident:Single")}</option>
                                                        <option value="married">{t("resident:Married")}</option>
                                                        <option value="divorce">{t("resident:Divorce")}</option>
                                                        <option value="widow">{t("resident:Widowed")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-6" />
                                            </React.Fragment>}
    
                                            <div className="col-12 mt-3">
                                                <h5>{t("changeOwnership:Card type")}</h5>
                                            </div>
                                            <div className="col-6">
                                                <select className="form-control mt-1 dropdown-custom-arrow"
                                                    value={tenant.cardType} index={index}
                                                    onChange={this.handleChange} name={"tenant[" + index + "].cardType"}
                                                >
                                                    <option value="unspecified">{t("settingDeveloper:na")}</option>
                                                    <option value="id_card">{t("changeOwnership:ID card")}</option>
                                                    <option value="government_officer">{t("changeOwnership:Civil servant card")}</option>
                                                    <option value="driving">{t("changeOwnership:Driving license Passport")}</option>
                                                    <option value="passport">{t("changeOwnership:Passport")}</option>
                                                    <option value="other">{t("changeOwnership:etc.")}</option>
                                                </select>
                                            </div>
                                            <div className="col-6"/>
                                            <div className= "col-12 mt-3">
                                                <h5>{t("changeOwnership:Card number")}</h5>
                                            </div>
                                            <div className="col-6">
                                                <input type='text' onChange={this.handleChange} 
                                                placeholder={t("changeOwnership:Specify card number / taxpayer number")} index={index}
                                                name={"tenant[" + index + "].idCardNumber"} className="form-control" value={tenant.idCardNumber} />
                                            </div>
                                            <div className="col-6" />
                                            
                                            
                                            <div className="col-6 mt-3"><h5>{t("changeOwnership:Contact phone number")} <span className="text-danger">*</span></h5> </div>
                                            <div className="col-6 mt-3"><h5>{t("changeOwnership:2nd Phone number")}</h5></div>
                                            <div className="col-6">
                                                <input type='text' onChange={this.handleChange} pattern='^[0-9-  \t+#()]*$'
                                                title="กรุณาใส่แค่ตัวเลข เครื่องหมาย (- , # , ( ) , + หรือ กด spacebar) เท่านั้น"
                                                required={true} index={index}
                                                name={"tenant[" + index + "].phone"} className="form-control" value={tenant.phone} />
                                            </div>
                                            <div className="col-6">
                                                <input type='text' onChange={this.handleChange} pattern='^[0-9-  \t+#()]*$'
                                                title="กรุณาใส่แค่ตัวเลข เครื่องหมาย (- , # , ( ) , + หรือ กด spacebar) เท่านั้น"
                                                index={index}
                                                name={"tenant[" + index + "].phone2"} className="form-control" value={tenant.phone2} />
                                            </div>
    
                                            <div className="col-12 mt-3"><h5>{t("changeOwnership:Email")}</h5></div>
                                            <div className="col-6">
                                                <input type='text' onChange={this.handleChange} index={index}
                                                name={"tenant[" + index + "].email"} className="form-control" value={tenant.email} />
                                            </div>
                                            <div className="col-6" />
    
                                            {this.state.tenant.length > 1 &&
                                                <div className="col-12 float-right mt-3">
                                                    <button className="btn btn-danger float-right" type="button" onClick={() => this.onDelete(index)}>
                                                    {t("PageDetailProjectInfo:Delete")}
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        <hr/>
                                    </React.Fragment>
    
                                );
                            }) 
                        
                        }
                         <div className="row col-12">
                             <button
                                type="button"
                                className="btn add-button add mb-4 "
                                onClick={() => this.addNewTenant()}
                                >
                                <span className="text-blue">+ {t("changeOwnership:Add Ownership")}</span>
                            </button> 
                         </div>
                        </React.Fragment>
                }
            </Translation>

        );
    }



}
export default OwnershipInformation;