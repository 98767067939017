import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import ProjectInfo from "./projectInfo";
import ProjectDashboard from './projectDashboard'
import ProjectManager from './projectManager'
import Emergency from './emergency'
import Staff from './staff'
import JuristicManager from './juristicManager';
import NoMatch from "../components/noMatch";
import JuristicCompany from './juristicCompany';
import Regulation from './regulation';
import Notification from './notification';
class Project extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={ProjectDashboard}/>
                <PrivateRoute path={`${this.props.match.url}/info`} component={ProjectInfo}/>
                <PrivateRoute path={`${this.props.match.url}/project-manager`} component={ProjectManager}/>
                <PrivateRoute path={`${this.props.match.url}/emergency`} component={Emergency}/>
                <PrivateRoute path={`${this.props.match.url}/staff`} component={Staff}/>
                <PrivateRoute path={`${this.props.match.url}/juristic-manager`} component={JuristicManager}/>
                <PrivateRoute path={`${this.props.match.url}/juristic-company`} component={JuristicCompany}/>
                <PrivateRoute path={`${this.props.match.url}/regulation`} component={Regulation}/>
                <PrivateRoute exact path={`${this.props.match.url}/view-all-notification`} component={Notification}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Project;