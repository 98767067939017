import React, { Component, forwardRef } from "react";
import "./styles/commonArea.scss";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { Dropdown } from "reactjs-dropdown-component";
import { bookingService } from "./services";
import _ from "lodash";
import moment from "moment";
import "./styles/dropdawn.scss"
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import SearchSelect from "../../libs/searchSelect";
import Loading from "../../libs/loading"
import i18n from 'i18next';

const allResidential = graphql`
    query bookingCustomerQuery {
        allResidential {
            totalCount
            edges {
                node {
                    id
                    name
                    floor
                    size
                    status
                    residentialHouseholder{
                        edges{
                            node{
                                id
                                tenant{
                                    firstName
                                    lastName
                                    countries
                                    user{
                                        id
                                    }
                                }
                            }
                        }
                    }
                    type{
                        id
                        name
                    }
                }
            }
        }
    }
`;
class BookingCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_facility: [],
      all_residential: [],
      search_residential: [],
      data: [],
      filteredData: [],
      locations: [],
      facility_id: 0,
      num_record: 0,
      query: "",
      text: "",
      selectLocation: "",
      textRight: "",
      start_time: "",
      end_time: "",
      residential_id: "",
      type_user: "",
      use_right: "",
      booking_people: "",
      total_price: "",
      textModal: "",
      booking_name: "",
      booking_tel: "",
      booking_remark: "",
      tel: "",
      flgModal: false,
      openDate: false,
      openTimeStart: false,
      openTimeEnd: false,
      startDate: new Date(),
      value: new Date(),
      flgClickIcon: false,
      residential_list: [],
      residentialHouseholder: [],
      residential_house_holder: '',
      loading: false,
    };
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    let token = localStorage.getItem("token");

    fetchQuery(environment, allResidential)
      .then(response_data => {
        this.setState({
          residential_list: response_data.allResidential.edges,
        });
      });

    await bookingService
      .booking_facility(token, null)
      .then((res) => {
        const data = res.data;

        let all_residential = data.all_residential;
        let location = [];
        let locations = [];
        let all_facility = data.all_facility;

        if (all_facility.length > 0) {
          all_facility.map((item) => {
            location.push({
              facility: item
            });
          });

          location.map((value) => {
            locations.push({
              label: _.get(value.facility, "name"),
              value: _.get(value.facility, "name"),
              id: _.get(value.facility, "id")
            });
          });
        } else {
          locations = [{}];
        }

        this.setState({
          num_record: data.num_record,
          locations: locations,
          all_residential: all_residential,
          search_residential: all_residential,
          loading: true
        });

      })
      .catch((e) => {
        console.log(e);
      });
  }

  onChange = (evt) => this.setState({ text: evt.target.value });

  onChangeLocation(event) {

    this.setState({
      selectLocation: event.value,
      facility_id: event.id
    });

  }

  onChangeDate(date) {
    this.setState({
      startDate: date,
      openDate: !this.state.openDate
    });
  }

  onChangeStart() {
    this.setState({
      openTimeStart: !this.state.openTimeStart
    });
  }

  onChangeEnd() {
    this.setState({
      openTimeEnd: !this.state.openTimeEnd
    });
  }

  onClickCalendar() {
    this.setState({
      openDate: !this.state.openDate
    });
  }

  onChangeTimeStart(time) {
    this.setState({
      start_time: time,
      openTimeStart: false
    });
  }

  onChangeTimeEnd(time) {
    this.setState({
      end_time: time,
      openTimeEnd: false
    });
  }

  handleFilter = (event) => {
    const searchWord = event.target.value;
    this.setState({
      textRight: searchWord,
      flgClickIcon: true
    });

    let top = this.state.all_residential.filter(topfive => {
      return !!topfive.name.match(RegExp(event.target.value, 'gim'));
    })

    if (searchWord === "") {
      this.setState({
        search_residential: []
      });
      this.getData();
    } else {
      if (top.length === 0) {
        this.setState({
          flgClickIcon: false
        });
      } else {
        this.setState({
          search_residential: top
        });
      }
    }
  };

  handleFilterSearch = () => {
    const { textRight } = this.state
    let top = this.state.all_residential.filter(topfive => {
      return !!topfive.name.match(RegExp(textRight, 'gim'));
    })

    if (top.length === 0) {
      this.setState({
        flgClickIcon: false
      });
    } else {
      this.setState({
        search_residential: top
      });
    }

    this.setState({
      flgClickIcon: !this.state.flgClickIcon,
      textRight: this.state.textRight
    });
  }

  clearInput = () => {
    this.setState({
      textRight: "",
      search_residential: []
    });
  };

  onSetText = (event, id) => {
    this.setState({
      textRight: event,
      search_residential: [],
      residential_id: id,
      flgClickIcon: false,
      residentialHouseholder: _.find(this.state.residential_list, ['node.id', window.btoa(`ResidentialNode:${id}`)]).node.residentialHouseholder.edges || []
    });
  };

  handleInputRoom = (e) => {
    let event = _.cloneDeep(e);
    let value = event.target.value;
    this.setState({
      textRight: event,
      search_residential: [],
      residential_id: value,
      flgClickIcon: false,
      residentialHouseholder: _.find(this.state.residential_list, ['node.id', value]).node.residentialHouseholder.edges || [],
      textRight: _.find(this.state.residential_list, ['node.id', value]).node.name,
    });
  }

  handleInputHouseholder = async (e) => {
    let event = _.cloneDeep(e);
    let value = event.target.value;
    let householder = await _.find(this.state.residentialHouseholder, ['node.id', value]).node.tenant
    this.setState({ booking_name: `${householder.firstName} ${householder.lastName}` })
  }

  onChangeBookingPeople = (event) => {
    this.setState({
      booking_people: event.target.value
    });
  };

  onChangeBookingName = (event) => {
    this.setState({
      booking_name: event.target.value
    });
  };

  onChangeBookingTel = (event) => {
    this.setState({
      booking_tel: event.target.value,
      tel: event.target.value
    });
  };

  onChangeBookingRemark = (event) => {
    this.setState({
      booking_remark: event.target.value
    });
  };

  async onSave() {
    let token = localStorage.getItem("token");
    const {
      facility_id,
      start_time,
      end_time,
      residential_id,
      booking_people,
      booking_name,
      tel,
      startDate,
      all_residential,
      textRight
    } = this.state;

    const { textArea, path } = this.props.location;
    let dateSelect = format(new Date(startDate), "DD/MM/YYYY")
    let dateNow = format(new Date(), "DD/MM/YYYY")
    let timeSelect = format(new Date(start_time), "HH:mm")
    let timeNow = format(new Date(), "HH:mm")

    let DateCurrent = format(new Date(), "YYYY-MM-DDT00:00:00")

    let selectDate = new Date(startDate).getTime()
    let DateNow = new Date(DateCurrent).getTime()
    let selectTime = new Date(timeSelect).getTime()
    let StartTime = new Date(start_time).getTime()
    let EndTime = new Date(end_time).getTime()
    let TimeNow = new Date().getTime()
    let start =
      moment(startDate).utc().format("DD/MM/YYYY") +
      " " +
      format(start_time, "HH:mm:ss");
    let end =
      moment(startDate).utc().format("DD/MM/YYYY") +
      " " +
      format(end_time, "HH:mm:ss");

    let check_residential = all_residential.filter(item => { return item.name == textRight })
    if ((selectDate < DateNow) || (StartTime < TimeNow && selectDate < DateNow)) {
      this.setState({
        flgModal: true,
        textModal: "วันและเวลาเริ่มต้นที่ต้องการใช้สิทธิ์ ต้องมากกว่า เวลาปัจจุบัน"
      });
      window.scrollTo(0, 0);
    } else if (StartTime < TimeNow && selectDate <= TimeNow) {
      this.setState({
        flgModal: true,
        textModal: "เวลาเริ่มต้นที่ต้องการใช้สิทธิ์ ต้องมากกว่า เวลาปัจจุบัน"
      });
      window.scrollTo(0, 0);
    } else {
      // console.log("Saved");
      if (start_time < end_time) {
        if (check_residential.length > 0) {
          await bookingService
            .create_booking_facility(
              token,
              start,
              end,
              path === "customer" ? parseInt(window.atob(residential_id).slice(16)) : "",
              path === "guest" ? "outsider" : path === "niti" ? "juristic" : "",
              0,
              parseInt(booking_people),
              0.0,
              booking_name,
              tel,
              textArea,
              facility_id
            )
            .then((res) => {
              const data = res.data;
              if (
                data.check_booking === true &&
                data.message === "booking successful"
              ) {
                this.props.history.push("/contact/global-area/all");
              } else if (
                data.check_booking === false &&
                data.message === "room are private"
              ) {
                this.setState({
                  flgModal: true,
                  textModal: "ไม่สามารถจองได้เนื่องจากถูกจองแล้ว"
                });
                window.scrollTo(0, 0);
              } else if (
                data.check_booking === false &&
                data.message === "room are full"
              ) {
                this.setState({
                  flgModal: true,
                  textModal: "ไม่สามารถจองได้เนื่องจากเวลาที่เลือกไม่ว่าง"
                });
                window.scrollTo(0, 0);
              }
              else if (data.message === "booking_people more than max people") {
                this.setState({
                  flgModal: true,
                  textModal: "Booking people more than max people"
                });
              } else {
                this.setState({
                  flgModal: true,
                  textModal: data.message
                });
                window.scrollTo(0, 0);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          // console.log('path ',path);
          if (path !== "customer") {
            await bookingService
              .create_booking_facility(
                token,
                start,
                end,
                path === "customer" ? residential_id : "",
                path === "guest" ? "outsider" : path === "niti" ? "juristic" : "",
                0,
                parseInt(booking_people),
                0.0,
                booking_name,
                tel,
                textArea,
                facility_id
              )
              .then((res) => {
                const data = res.data;
                if (
                  data.check_booking === true &&
                  data.message === "booking successful"
                ) {
                  this.props.history.push("/contact/global-area/all");
                } else if (
                  data.check_booking === false &&
                  data.message === "room are private"
                ) {
                  this.setState({
                    flgModal: true,
                    textModal: "ไม่สามารถจองได้เนื่องจากถูกจองแล้ว"
                  });
                  window.scrollTo(0, 0);
                } else if (
                  data.check_booking === false &&
                  data.message === "room are full"
                ) {
                  this.setState({
                    flgModal: true,
                    textModal: "ไม่สามารถจองได้เนื่องจากเวลาที่เลือกไม่ว่าง"
                  });
                  window.scrollTo(0, 0);
                }else if (data.message === "booking_people more than max people") {
                  this.setState({
                    flgModal: true,
                    textModal: "Booking people more than max people"
                  });
                } 
                else {
                  this.setState({
                    flgModal: true,
                    textModal: data.message
                  });
                  window.scrollTo(0, 0);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            this.setState({
              flgModal: true,
              textModal: "ผู้ใช้สิทธิ์การจองไม่ถูกต้อง"
            });
            window.scrollTo(0, 0);
          }
        }
      } else {
        this.setState({
          flgModal: true,
          textModal: "เวลาเริ่มต้นที่ต้องการใช้สิทธิ์ ต้องน้อยกว่า เวลาสิ้นสุดที่ต้องการใช้สิทธิ์"
        });
        window.scrollTo(0, 0);
      }
    }
  }

  onCloseModal() {
    this.setState({
      flgModal: false
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    try {
      this.onSave();
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { path } = this.props.location;
    const {
      locations,
      startDate,
      flgModal,
      textModal,
      search_residential,
      start_time,
      end_time,
      loading
    } = this.state;

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        {flgModal ? (
           <Translation>
           {
             t =>
          <div className="modall-overlay">
            <div className="modall">
              <button
                type="button"
                className="close"
                data-dismiss="modall"
                aria-label="Close"
                onClick={() => this.onCloseModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-footer box-button-modal"></div>
              <h5 className="modal-title" style={{ color: "#CE4646" }}>
              {t("newBookingSingha_Booking:"+textModal)}
               
              </h5>
            </div>
          </div>            
          }
          </Translation>
        ) : null}
        <WrapperContent>
          <ContactTopMenuNavigation mini={true} />
          <div className="container-fluid box">
            <div className="row justify-content-between">
              <div className="col">
                <h3>
                  <Link to="/contact/global-area/booking/add">
                    <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"}
                      alt="back"
                      className="back-booking"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span className="text-header-booking ml-3">{t("newBookingSingha_Booking:Add new booking facility")}</span>}
                  </Translation>
                </h3>
              </div>
            </div>
          </div>

          {loading && locations ?
            <Translation>
              {
                t =>
                  <form className="container-type" onSubmit={this.onSubmit}>
                    <>
                      <h3 className="text-header-booking">{t("newBookingSingha_Booking:Booking deatil")}</h3>
                      <div className="line" />
                      <div className="pr">
                        <div>
                          <h5 className="text-subheader-booking">{t("newBookingSingha_Booking:Select the facility")}</h5>
                          {
                            locations &&
                            <Dropdown
                              name="location"
                              required
                              title={t("newBookingSingha_Booking:Select the facility")}
                              key={_.get(locations, "value")}
                              className="mt"
                              list={locations}
                              onChange={(text) => this.onChangeLocation(text)}
                              styles={{
                                headerTitle: { fontSize: "14px", fontFamily: 'Kanit' },
                                header: { border: "1px solid #8A8A8A" },
                                wrapper: {
                                  width: "100%"
                                },
                                listItem: { fontSize: "14px" }
                              }}
                            />
                          }

                        </div>
                        <div className="mtdiv row">
                          <div className="col-6 pdr">
                            <h5 className="text-subheader-booking">{t("newBookingSingha_Booking:Using date")}</h5>
                            <div className="calendars-wrapper mt">
                              <DatePicker
                                className="form-control float-left colorborder"
                                selected={startDate}
                                dateFormat='dd/MM/yyyy'
                                onChange={(date) => this.setState({ startDate: date })}
                                required
                                customInput={
                                  <ButtonDate
                                    date
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mtdiv row">
                          <div className="col-6 pdr">
                            <h5 className="text-subheader-booking">{t("newBookingSingha_Booking:Using time")}</h5>
                            <div className="calendars-wrapper mt" onClick={() => this.onChangeStart()}>
                              <DatePicker
                                selected={start_time}
                                onChange={(date) => this.onChangeTimeStart(date)}
                                customInput={
                                  <ButtonDate
                                  />
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                required
                                timeIntervals={1}
                                timeCaption="Time"
                                timeFormat="HH:mm น."
                                dateFormat="HH:mm น."
                                open={this.state.openTimeStart}
                                onClickOutside={() => this.onChangeStart()}
                                className="form-control float-left colorborder"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <h5 className="text-subheader-booking">{t("newBookingSingha_Booking:Using time")}</h5>
                            <div className="calendars-wrapper mt" onClick={() => this.onChangeEnd()}>
                              <DatePicker
                                selected={end_time}
                                onChange={(date) => this.onChangeTimeEnd(date)}
                                customInput={
                                  <ButtonDate
                                  />
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                required
                                timeIntervals={1}
                                timeCaption="Time"
                                timeFormat="HH:mm น."
                                dateFormat="HH:mm น."
                                open={this.state.openTimeEnd}
                                onClickOutside={() => this.onChangeEnd()}
                                className="form-control float-left colorborder"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mtdiv">
                          <h5 className="text-subheader-booking">{t("newBookingSingha_Booking:Number of participants")}</h5>
                          <input
                            type="number"
                            required
                            className="inputNumber col-6"
                            value={this.state.booking_people}
                            onChange={(event) => this.onChangeBookingPeople(event)}
                          ></input>
                        </div>
                      </div>
                    </>
                    <div className="mtheader">
                      <h3 className="text-header-booking">{t("newBookingSingha_Booking:Booking deatil")}</h3>
                      <div className="line" />
                      <div className="pr">
                        {path === "customer" && (
                          <div>
                            <h5 className="text-subheader-booking">{t("newBookingSingha_Booking:Reservation rights user")}</h5>
                            <SearchSelect onChange={this.handleInputRoom}
                              value={this.state.residential_id}
                              name={"residential_id"}
                              placeholder="รายการ"
                              queryObject={this.state.residential_list}
                              keyOfValue="id"
                              require={true}
                              keyOfLabel="name"
                              style={customStyles}
                            />

                          </div>
                        )}
                        <div className="mtdiv">
                          <h5 className="text-subheader-booking">{t("newBookingSingha_Booking:Booking name")}</h5>
                          {path === "customer" ?
                            <SearchSelect onChange={this.handleInputHouseholder}
                              value={this.state.residential_house_holder}
                              // value={this.state.booking_name}
                              name={"residential_house_holder"}
                              placeholder="รายการ"
                              queryObject={this.state.residentialHouseholder}
                              keyOfValue="id"
                              require={false}
                              keyOfLabel="tenant.firstName:tenant.lastName"
                              style={customStyles}
                            /> :
                            <input
                              type="text"
                              className="inputText mt"
                              required
                              value={this.state.booking_name}
                              onChange={(event) => this.onChangeBookingName(event)}
                            ></input>}
                        </div>
                        <div className="mtdiv">
                          <h5 className="text-subheader-booking">{t("newBookingSingha_Booking:Contact number")}</h5>
                          <input
                            className="inputText mt"
                            required
                            type="text"
                            value={this.state.booking_tel}
                            onChange={(event) => this.onChangeBookingTel(event)}
                          ></input>
                        </div>
                      </div>
                    </div>
                    {path !== "niti" && (
                      <div className="mtheader pr">
                        <h3 className="text-header-booking mb-5">{t("newBookingSingha_Booking:Service charge summart")}</h3>
                        <div className="cardblue box-cardblue">
                          <div className="d-flex justify-content-between">
                            <span>{t("newBookingSingha_Booking:Facility fee")}</span>
                            <span>0 {t("newBookingSingha_Booking:Baht")}</span>
                          </div>
                          <div className="d-flex justify-content-between mtSub">
                            <span>{t("newBookingSingha_Booking:Cleaning fee")}</span>
                            <span>0 {t("newBookingSingha_Booking:Baht")}</span>
                          </div>
                          <div className="d-flex justify-content-between mtSub">
                            <span>{t("newBookingSingha_Booking:VAT")}</span>
                            <span>0 {t("newBookingSingha_Booking:Baht")}</span>
                          </div>
                          <div className="lineTotal mtSub" />
                          <div className="d-flex justify-content-between mtSub">
                            <span className="text-header-booking">{t("newBookingSingha_Booking:Total Amount")}</span>
                            <h3 className="text-header-booking">0 {t("newBookingSingha_Booking:Baht")}</h3>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row float-right pt">
                      <button type="submit" className="btn booking mr-3 mb-10 mtButton">
                        {t("Allaction:save")}
                      </button>
                      <Link to="/contact/global-area/all">
                        <button type="submit" className="btn cancel mr-3 mb-10 mtButton">
                          {t("Allaction:cancel")}
                        </button>
                      </Link>
                    </div>
                  </form> 
              }
            </Translation>

            : <Loading />

          }

        </WrapperContent>
      </Wrapper>
    );
  }
}

const customStyles = {
  control: base => ({
    ...base,
    height: 48,
    minHeight: 35,
    border: '1px solid #8a8a8a'
  })
};

const ButtonDate = forwardRef(({ value, onClick, date }, ref) => (
  <button
    type="button"
    className="button-date"
    style={{ textAlignLast: 'start' }}
    onClick={onClick}
    ref={ref}
  >
    {value}
    {!date && (
      <div
        className="calendars"
      >
        <span
          style={{
            color: "#0382FA",
            fontFamily: "medium",
          }}
        >
          {i18n.t("newBookingSingha_Booking:Choose time")}
        </span>
      </div>
    )}
    {date && (
      <div
        className="calendars float-right"
      >
        <img
          className="icon"
          src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
          alt="calendars"
        />
      </div>
    )}
  </button>
));

export default BookingCustomer;
