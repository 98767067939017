

import React, { Component } from 'react';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import API from '../api/silverGraund2'
import Loading from "../libs/loading"
import settingAPI from '../api/setting'
import {format, setHours,setMinutes} from "date-fns";
import './vms.scss'
import i18next from 'i18next';

class VmsAcceptQRcode extends Component {

    constructor(props) {
        super(props)
        this.state = {
          code:'',
          domain:'',
          loading:false,
          settingCalu: null || [],
          amountCarPerDay: null || "",
          etampTimerVistor: null,
          etampTimerVistorHH: null,
          etampTimerVistorMM: null,
          hours:null,
          minutes:null,
          toPayCal: null || "",
          toCalOverTime: null || "",
          inDate: null,
          lang:false
        }

        this.downloadQR = this.downloadQR.bind(this)
        this.getMaxTime = this.getMaxTime.bind(this)
    }

    componentDidMount(){
        this.postQRCodeForWalkIn();
        this.getDataPackingCal()
    }


    getDataPackingCal() {
        settingAPI.getcaluletePackingSetting().then((res) => {
            if (res.data.settingCalu) {
                this.setState({
                    etampTimerVistor: res.data.settingCalu.etampTimerVistor !== null ? res.data.settingCalu.etampTimerVistor : null,
                    etampTimerVistorHH: res.data.settingCalu.etampTimerVistorHH !== null ? res.data.settingCalu.etampTimerVistorHH : '',
                    etampTimerVistorMM: res.data.settingCalu.etampTimerVistorMM,
                    toPayCal: res.data.settingCalu.toPayCal,
                    toCalOverTime: res.data.settingCalu.toCalOverTime,
                    hours:  res.data.settingCalu.etampTimerVistor ?  res.data.settingCalu.etampTimerVistor :  res.data.settingCalu.etampTimerVistorHH, 
                    minutes:  res.data.settingCalu.etampTimerVistor ? 0 :  res.data.settingCalu.etampTimerVistorMM
                })
            }
        }).finally(() => {
            this.setState({ loading: true })
        })
    }

    postQRCodeForWalkIn() {
        this.setState({ 
            loading:true
        })
            let data = {
                "qrcode" : this.props.match.params.qrcode
            }
            API.postQRCodeForWalkIn(data).then((res) => {
                if(res.data.domain){
                    this.setState({ 
                        domain: res.data.domain,
                        code: res.data.code,
                        loading:false,
                        inDate: res.data.inDate
                    })
                }
            }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getMaxTime(){
        let timeHour = 0;
        let timeMinute = 0;
        if (this.state.etampTimerVistor !== null) {
            timeHour =  this.state.etampTimerVistor
        } else {
            timeHour =  this.state.etampTimerVistorHH;
            timeMinute = this.state.etampTimerVistorMM;
        }

        let maxTime = this.state.inDate
        maxTime = setHours(new Date(maxTime), new Date(maxTime).getHours() + timeHour)
        maxTime = setMinutes(new Date(maxTime), new Date(maxTime).getMinutes() + timeMinute)
       
        return maxTime
    }

     downloadQR = () => {
        const canvas = document.getElementById("react-qrcode-logo");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "qrcode1.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    render() {
      
        let urlfullPath = window.location.protocol + '//'+window.location.hostname;
        let getTimeOut = this.getMaxTime()
        
        return (
            <>
                <div id="wrapper-content">
                    {
                        !this.state.loading ? 
                        <div id='vms-wrapper'>
                        <div className='pl-10 pr-10 pt-2'>
                                <div>
                                    <span onClick={ () => this.setState({lang : !this.state.lang})} className='btn-lang'>
                                        <img src={this.state.lang ? (process.env.PUBLIC_URL + '/images/flags/en.png') : (process.env.PUBLIC_URL + '/images/flags/th.png')} 
                                        alt="flag-icon" className='flag'/>
                                        <h6><small>{this.state.lang ? 'EN':'ไทย'}</small></h6>
                                    </span>
                                </div>
                               <div className='text-center pt-6'>
                                   <p style={{fontSize:'16px'}}>
                                        {this.state.lang ? 'This QR code is used for scanning out of the project.' :'QR Code นี้ใช้สำหรับแสกนออกจากโครงการ'}
                                     </p>
                                 
                                   <div className=''>
                                       <QRCode 
                                           value={urlfullPath + "/vms/vms-create/" + this.state?.code }
                                           logoImage='/images/logo/logo.svg'
                                           size={266}
                                           logoWidth={50}
                                           logoHeight={50}
                                       />
                                   </div>
                                   {/* <a onClick={this.downloadQR}>
                                       <button className="btn btn-add-slip" style={{width:'100%'}}>
                                           <img src={process.env.PUBLIC_URL + '/images/setting-walkin/download.png'} /> บันทึก QR Code 
                                       </button>
                                   </a> */}
                                    
                               </div>
                         
                        </div>
                        <div className='pl-5 pr-5 vms-timer-vistor'>
                        <h3 className="text-center" style={{letterSpacing:'14px',color:'#0F0F0F'}}>{this.state.code}</h3>
                               <div className='row'>
                                   <div className='col-5 text-center'> 
                                       <p>{this.state.lang ? 'Parking time' :'เวลาสิทธิ์เข้าจอด'} </p>
                                       <h4 style={{color:'#1567B4'}}>{format(this.state.inDate, "HH:mm A")}</h4>
                                       <span>{format(this.state.inDate, "DD/MM/YYYY")}</span>
                                   </div>
                                   <div className='col'>
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconArrowRight.png'}
                                            style={{ height: '30px', marginTop: "30px" }} />
                                   </div>
                                   <div className='col-5 text-center'> 
                                       <p>{this.state.lang ? 'Exit time' :'สิ้นสุดสิทธิ์จอดรถ'} </p>
                                       <h4 style={{color:'#CA0824'}}>{format(getTimeOut, "HH:mm A")}</h4> 
                                       <span>{format(this.state.inDate, "DD/MM/YYYY")}</span>
                                   </div>
                                </div>
                                <hr/>
                               <div className='row pb-2'> 
                                    <div className='col'>{this.state.lang ? 'Free' :'จอดฟรี'}</div>
                                    <div className='col'></div>
                                    <div className='col'> 
                                        {/* {this.state.etampTimerVistor ? this.state.etampTimerVistor + " ชั่วโมง ": ''} 
                                        {this.state.etampTimerVistorHH === '' || this.state.etampTimerVistorHH === null ? '' : this.state.etampTimerVistorHH +" ชั่วโมง"} 
                                        {this.state.etampTimerVistorMM ? this.state.etampTimerVistorMM +" นาที": ''}  */}
                                        { `${this.state.hours || 0} ชั่วโมง ${this.state.minutes || 0} นาที`}
                                     </div>
                                </div>
                                <div className='row pb-2'> 
                                    <div className='col'>{this.state.lang ? 'Parking Fee' :'อัตราค่าจอดรถ'}</div>
                                    <div className='col'></div>
                                    <div className='col'> {this.state.toPayCal && this.state.toPayCal} {this.state.lang ? 'Baht / Hours ' :'บาท/ชม.'}</div>
                                </div>
                                <p className='pt-2'>{this.state.lang ? 'Remarks: Parking more than' :'หมายเหตุ : จอดเกิน '}{this.state.toCalOverTime && this.state.toCalOverTime} {this.state.lang ? 'minutes will be charged as 1 hours.' :'นาทีขึ้นไป คิดเป็น 1 ชั่วโมง'}</p>
                                <div style={{}}>
                                       <a onClick={this.downloadQR}>
                                           <button className="btn btn-add-new-slip" style={{width:'100%'}}>
                                               {this.state.lang ? 'Save QR Code' :'บันทึก QR Code'}
                                           </button>
                                       </a>
                               </div>
                                <div style={{paddingTop:'5%',paddingBottom:'3%'}}>
                                <Link to={{ pathname:"/vms/vms-accept/"+this.state?.code,
                                                state:  this.state,
                                               }} >
                                           <button className="btn btn-primary add mr-2" style={{width:'100%'}}>
                                               {this.state.lang ? 'Next' :'ถัดไป'}
                                           </button>
                                   </Link>
                               </div>
                               <div>
                                   <p className='text-danger'>
                                       {this.state.lang ? '*Please keep this QR Code for confirmation when leaving the project. If the QR Code is lost, there may be additional fines from the juristic person.' :'*กรุณาเก็บ QR Code นี้ไว้ เพื่อยืนยันตอนออกจากโครงการ หากทำ QR Code สูญหายอาจมีค่าปรับเพิ่มเติมจากนิติบุคคล'}</p>
                               </div>
                        </div>
                       </div> : <Loading/>
                    }
             
                </div>
            </>
                
        );
    }
}

export default VmsAcceptQRcode;
