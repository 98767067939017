import React from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Wrapper from "../components/wrapper/index";
import Header from "../components/header/index";
import Sidebar from "../components/sidebar/index";
import ComponentPagination from "../libs/componentPagination";
import WrapperContent from "../components/wrapper/wrapperContent";
import Pagination from "../libs/newPagination";
import FeedbackTopMenuNavigation from "./feedbackTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from "./navigation";
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../env/environment";
import {format} from "date-fns";
import queryAvgFeedback from "./avgFeedback"
import queryGetAllSite from "./getAllSite"
import _ from "lodash";
import Loading from "../libs/loading";
import SeacrhDetail from "../components/search";
import localStoragePage from "../libs/localstorage";
import Select from 'react-select';
import './feedback.scss'

const allResidential = graphql`
  query feedbackListQuery($search: String, $award: Float, $startDate: DateTime, $endDate: DateTime,$first: Int
    $last: Int, $userType:String, $site:ID){
    allFeedback(search: $search, award:$award, startDate:$startDate, endDate:$endDate, first:$first
      last: $last, userType:$userType, site:$site) {
        totalCount
        edges{
            node{
                id
                award
                note
                added
                feedbackImage{
                    edges{
                        node{
                            id
                            fileName
                            fileUpload
                        }
                    }
                }
                user{
                    id
                    tenant{
                        id
                        firstName
                        lastName
                        phone
                        email
                    }
                    juristic{
                         id
                         firstName
                         lastName
                         phone
                    }
      
                }
                site{
                    id
                    name  
                    project{
                      edges{
                          node{
                            name
                          }
                      }
                    }
                }
            }
        }    
    }
    rattingFeedback :  allFeedback(search: null, award: null, startDate: null, endDate: null, first: null
      last: null, userType: null, site: null) {
        totalCount
        edges{
            node{
                id
                award
                note
                added
                site{
                    id
                    name
                    project{
                      edges{
                          node{
                            name
                          }
                      }
                    }
                }
            }
        }    
    }
  }          

`;

const customStyles = {
  control: provided => ({
    ...provided,
    fontFamily: 'kanit'
  })
}
class FeedbackList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      start_date:"",
      end_date:"",
      search:"",
      getAllSite: [],
      rattingFeedback: [],
      SiteSelected: {"value": null, "label": "ทั้งหมด"},
      allFeedback:[],
      allFeedbackTotal:null,
      userType:"",
      loading: true,
      defaultSelete:[{ "value": null, "label": "ทั้งหมด"}],
      rattingFeedbackFive:null,
      rattingFeedbackFour:null,
      rattingFeedbackThree:null,
      rattingFeedbackTwo:null,
      rattingFeedbackOne:null,
      rattingFeedbackTotal:null,
      avgAward:null,
      allTotalCount:null
    };

    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePropsChange = this.handlePropsChange.bind(this);
    this.onChangeSite = this.onChangeSite.bind(this);
    this.getAllSite = this.getAllSite.bind(this)

  }

  onChangeSearch(text) {
    this.setState({ search: text });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSearch(event) {
    this.setState({
      search: event.target.value,
    });
  }

  handlePropsChange(channel_list) {

    let search = ""
    let start_date = null
    let end_date = null
    channel_list.forEach((e, index) => {
      if (e.name === "search") {
        search = e.value
      }
      else if (e.name === "start_date" && e.value !== '') {
        start_date = e.value
      }
      else if (e.name === "end_date" && e.value !== '') {
        end_date = e.value
      }
    });
  
    this.setState({
      search: search,
      start_date: new Date(start_date).toISOString(),
      end_date: new Date(end_date).toISOString(),
    })
  }

  getStar(num) {
    return (
      <div className="star-rating">
            <label htmlFor="star-5" title="5 stars" className={num >= 5 ? 'star-active' :''}>
                <FontAwesomeIcon icon={faStar} /> 
            </label>
            <label htmlFor="star-4" title="4 stars" className={num >= 4 ? 'star-active' :''}>
                  <FontAwesomeIcon icon={faStar} />
            </label>
            <label htmlFor="star-3" title="3 stars" className={num >= 3 ? 'star-active' :''}>
                    <FontAwesomeIcon icon={faStar} />
            </label>
             <label htmlFor="star-2" title="2 stars" className={num >= 2 ? 'star-active' :''}>
                    <FontAwesomeIcon icon={faStar} />
             </label>
             <label htmlFor="star-1" title="1 star" className={num >= 1 ? 'star-active' :''}>
                <FontAwesomeIcon icon={faStar} />
             </label>
      </div>

    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.first !== this.state.first || prevState.last !== this.state.last || prevState.start_date !== this.state.start_date 
      || prevState.end_date !== this.state.end_date || prevState.search !== this.state.search || prevState.userType !== this.state.userType
      || prevProps.match.params.status !== this.props.match.params.status){
        this.getData()
        this.getAvgFeedback()
    }
  }

   componentDidMount() {
    this.getData();
    this.getAvgFeedback()
    this.getAllSite()
  }

  getAvgFeedback() {
    fetchQuery(environment, queryAvgFeedback,{  
      search: this.state.search,
      first: this.state.first,
      last: this.state.last,
      startDate: this.state.start_date ? this.state.start_date : null,
      endDate: this.state.end_date ? this.state.end_date : null,
      award: this.props.match.params.status === 'all' ? null : this.props.match.params.status,
      userType: this.state.userType,
      //site: siteid ? siteid : null
      }
      ).then(data => {
       const avgFeedbackData = JSON.parse(data.avgFeedback);
       this.setState({
        avgAward : avgFeedbackData.avgAward,
        allTotalCount: avgFeedbackData.totalCount,
       })
    });
  }

  getData = async(siteid) => {
      this.setState({
       loading: true,
      // SiteSelected: { value: null, label: 'ทั้งหมด' }
      })

      await fetchQuery(environment, allResidential,{  
        search: this.state.search,
        first: this.state.first,
        last: this.state.last,
        startDate: this.state.start_date ? this.state.start_date : null,
        endDate: this.state.end_date ? this.state.end_date : null,
        award: this.props.match.params.status === 'all' ? null : this.props.match.params.status,
        userType: this.state.userType,
        site: siteid ? siteid : null
        }
        ).then(data => {
         //let selectSite = data?.getAllSite?.edges?.filter((n) => n.node.id === siteid)
         let data_clone = _.cloneDeep(data);

          this.setState({
              allFeedback: data_clone?.allFeedback.edges,
              allFeedbackTotal: data_clone?.allFeedback.totalCount,
              loading: false,
             // SiteSelected: { value: selectSite[0]?.node?.id, label: selectSite[0]?.node?.name },
              rattingFeedbackFive: data_clone?.rattingFeedback?.edges.filter(item => { 
                return item.node?.site?.id !== "331" && item.node?.award === 5
               }),
              rattingFeedbackFour: data_clone?.rattingFeedback?.edges.filter(item => { 
                return item.node?.site?.id !== "331" && item.node?.award === 4
               }),
              rattingFeedbackThree: data_clone?.rattingFeedback?.edges.filter(item => { 
                return item.node?.site?.id !== "331" && item.node?.award === 3
               }),
              rattingFeedbackTwo: data_clone?.rattingFeedback?.edges.filter(item => { 
                return item.node?.site?.id !== "331" && item.node?.award === 2
               }),
              rattingFeedbackOne: data_clone?.rattingFeedback?.edges.filter(item => { 
                return item.node?.site?.id !== "331" && item.node?.award === 1
               })
          })
      });
  }


  getAllSite(siteid) {
    fetchQuery(environment, queryGetAllSite
      ).then(data => {
       //let selectSite = data?.getAllSite?.edges?.filter((n) => n.node.id === siteid)
       let data_clone = _.cloneDeep(data);
       let getAllSiteData = data_clone?.getAllSite?.edges?.map((n) => (
        { ...n.node, value: n.node.id, label: n.node.project?.edges[0]?.node?.name }))
       let optionSearch = [{ "value": null, "label": "ทั้งหมด"}, ...getAllSiteData]
  
        this.setState({
            getAllSite: optionSearch,
        })
    });
  }

  onChangeSite(site) {
   this.getAllSite(site.id)
 }


  render() {
    let order_id = 0
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <FeedbackTopMenuNavigation mini={true}  />
          <div className="container-fluid box" id="feedback-customer-list">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <h3>
                  <Link to="/register">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("feedback:feedback title")} </span>}
                  </Translation>
                </h3>
              </div>
            </div>

            <div className="content-inner">
              <div className="row  mb-2">
                <div className="col-12">
                  <Navigation sumRatting={this.state.avgAward} allFeedback={this.state.allTotalCount}
                    rattingFeedbackFour={this.state.rattingFeedbackFour && this.state.rattingFeedbackFour.length}
                    rattingFeedbackFive={this.state.rattingFeedbackFive && this.state.rattingFeedbackFive.length}
                    rattingFeedbackThree={this.state.rattingFeedbackThree && this.state.rattingFeedbackThree.length}
                    rattingFeedbackTwo={this.state.rattingFeedbackTwo && this.state.rattingFeedbackTwo.length}
                    rattingFeedbackOne={this.state.rattingFeedbackOne && this.state.rattingFeedbackOne.length}
                  />
                  {
                     <Translation>
                      {(t) =>
                      <div className="row justify-content-end mb-4 ">
                          <div className="col-3">
                            <select className="form-control select-feedback" 
                              name="userType" style={{fontFamily:'kanit'}}
                              value={this.state.userType} 
                              onChange={this.handleChange}
                              >
                                  {/* <option value="0" selected data-default>{t("PageListHistoryResident:Type")}</option> */}
                                  <option value="">{t("PageList:All")}</option>
                                  <option value="tenant">{t("PageList:Tanant")}</option>
                                  <option value="juristic">{t("PageList:Juristic Personal")}</option>
                            </select>
                          </div>
                          <div className="col-4 select-feedback">
                            <Select
                                className="select"
                                options={this.state.getAllSite}
                               // value={this.state.SiteSelected}
                                isSearchable={true}
                                onChange={this.onChangeSite}
                                placeholder ={t("project_info:Project name")}
                                styles={customStyles}
                            />
                          </div>
                      </div>
                       }
                     </Translation>
                    
                  }
                  {
                    <SeacrhDetail
                      selectIs={false}
                      handlePropsChange={this.handlePropsChange}
                      startDateIs={true} //วันที่้ค้นหาเริ่มต้น
                      endDateIs={true} //วันที่ค้นหาสิ้นสุด ใช่ หรือ ไม่
                      searchBoxId={true} //กล่องค้นหา  ใช่ หรือ ไม่
                      search={this.state.search} //กล่องค้นหา Input
                      start_date={this.state.start_date}//วันที่้ค้นหาเริ่มต้น Input
                      end_date={this.state.end_date}//วันที่ค้นหาสิ้นสุด Input
                    />
                  }
                </div>
              </div>
                  {!this.state.loading ? (
                     <React.Fragment>
                              <div className="card fade-up">
                                <div className="tbodyDiv">
                                  <table className="table table-hover" >
                                    <thead className="thead-light">
                                      <Translation>
                                        {t =>
                                          <tr>
                                            <th className="text-center" style={{width:'40px'}}>{t("feedback:No.")}</th>
                                            <th className="text-center" style={{width:'400px'}}>{t("feedback:Note")}</th>
                                            <th className="text-center" style={{width:'400px'}}></th>
                                            <th className="text-center" style={{width:'100px'}}>{t("feedback:Image")}</th>
                                            <th className="text-center">{t("PageListHistoryResident:Type")}</th>
                                            <th className="text-center" style={{width:'100px'}}>{t("feedback:Feedback Date")}</th>
                                            <th className="text-center" style={{width:'300px'}}>{t("project_info:Project name")}</th>
                                            <th className="text-center" style={{width:'100px'}}>{t("feedback:Name")}</th>
                                            <th className="text-center" style={{width:'80px'}}>{t("feedback:Email")}</th>
                                          </tr>}
                                      </Translation>
                                    </thead>
                                    <tbody>
                                      {this.state.allFeedback?.map(
                                        (feedback, index) => {   
                                          
                                          // let id = this.state.first ? this.state.first : "10"

                                          // let order_id = id - localStoragePage.getPageLimit();  

                                          // let row_number = order_id + index + 1;
                                          return (
                                            <>
                                            <tr>
                                              <td className="text-center" style={{width:'40px'}}>{order_id+ index + 1}</td>
                                              <td style={{width:'400px'}}>{feedback.node.note}</td>
                                              <td className="text-center"  style={{width:'400px'}}>
                                                    {this.getStar(feedback.node.award)}
                                              </td>
                                              <td className="text-center" >
                                                {
                                                feedback?.node?.feedbackImage?.edges.map(
                                                    (docs, index) => {  
                                                      return (
                                                        <>
                                                            <Link to="route"  onClick={(event) => {
                                                                      event.preventDefault();
                                                                      window.open(
                                                                         typeof docs.node.fileUpload === "string"
                                                                          ? docs.node.fileUpload
                                                                          : URL.createObjectURL(docs.node.fileUpload))
                                                                      }}
                                                                          target="blank">
                                                                       <img src={docs.node.fileUpload} alt="feedback-img" 
                                                                       style={{ width:'50px',height:'50px',margin:'5px 5px'}}/>
                                                           </Link>
                                                        </>
                                                      )
                                                  })
                                                }
                                              </td>
                                              <td className="text-center">
                                                <Translation>
                                                   {
                                                    t =>
                                                     <span>{feedback.node.user.juristic?.firstName ? t("PageList:Juristic Personal") : t("PageList:Tanant")}</span>
                                                   }
                                                </Translation>
                                              </td>
                                              <td className="text-center">
                                                {format(new Date(feedback.node.added), "DD/MM/YY HH:mm:ss")}
                                              </td>
                                              <td className="text-center" style={{width:'300px'}}>{feedback.node.site.project?.edges[0]?.node?.name}</td>
                                              <td>{feedback.node.user.juristic?.firstName ? feedback.node.user.juristic?.firstName +" "+ feedback.node.user.juristic?.lastName  : feedback.node.user.tenant?.firstName +" "+ feedback.node.user.tenant?.lastName }</td>
                                              <td style={{width:'80px'}}>{feedback.node.user.juristic?.email ? feedback.node.user.juristic?.email  : feedback.node.user.tenant?.email}</td>
                                            </tr>
                                            </>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div> 
                           <div className="row">
                            <Pagination
                              changePage={this.changePage}
                              first={this.state.first}
                              last={this.state.last}
                            //  totalCount={this.state.allFeedbackTotal}
                            />
                           </div>
                      </React.Fragment> ) : (
                   <Loading />
               )}     
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default FeedbackList;
