import React, { Component } from "react";
import { Translation } from "react-i18next";

class SearchProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  handleSearchChange(e) {
    this.setState({ search: e.currentTarget.value });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.props.callback_function(this.state.search);
      this.props.callback_function_click()  
    }
  }
  onSearch() {
    if(this.props.callback_function_click !== undefined){
      this.props.callback_function(this.state.search);
      this.props.callback_function_click()  
    }else{
      this.props.callback_function(this.state.search);
    }
    
  }


  render() {
    return (
      <Translation>
        {t =>
          <React.Fragment>
            <div className="input-group">
              <input
                type="text"
                className="form-control input-size"
                placeholder={this.props.placeholder ? this.props.placeholder : t('filterFunction:search')}
                value={this.state.search}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleKeyDown}
                style={{height:this.props.height ? this.props.height : ''}}
              />
              <div className="input-group-append">
                  <button className="btn btn-outline-secondary form-control" onClick={() => this.onSearch()} type="button" 
                  style={{borderColor : '#BFBFBF', borderLeft:'none',height:this.props.height ? this.props.height : ''}}>
                  <img
                      src={
                      process.env.PUBLIC_URL +
                      "/images/icons/search-outline.png"
                      }
                      alt="calendars"
                      style={{ width: "17.81px", height: "17.81px" }}
                  />
                  </button>
              </div>
            </div>
          </React.Fragment>
        }
      </Translation>
    );
  }
}

export default SearchProject;