import i18next from 'i18next';
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/expense/prepaid-deposit/list/all">
                                {i18next.t("PrepaidDeposit:All")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/prepaid-deposit/list/active">
                                {i18next.t("PrepaidDeposit:Unpaid")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/prepaid-deposit/list/use_all">
                                {i18next.t("PrepaidDeposit:Full amount paid")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/prepaid-deposit/list/use_partial">
                                {i18next.t("PrepaidDeposit:Partial pay off")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/prepaid-deposit/list/void">
                                {i18next.t("PrepaidDeposit:Cancelled")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;