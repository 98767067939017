import React from "react";
import ReactExport from "react-data-export";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
import numberWithCommas from "../../libs/numberWithComma";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const _ = require('lodash');

const style = {
    alignment: {horizontal: "center"} ,font: {bold: true}
};

const styleNumber = {
    border: {
        left: {style: "thin", color: "FF0000FF"},
        right: {style: "thin", color: "FF0000FF"},
        top: {style: "thin", color: "FF0000FF"},
        bottom: {style: "thin", color: "FF0000FF"}
    },
    alignment: {
        vertical: "bottom",
        horizontal: "right"
    }
};

class UnitReportExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            developerName : '',
            startDate : '',
            endDate : '',
            head : [],
            multiDataSet : []
        };
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
    }

    componentDidMount(){
        this.setData()
        this.setState({
            developerName : this.props.state.developerName,
            startDate : this.props.state.startDate,
            endDate : this.props.state.endDate,
        }, () => this.setData())
    }

    componentDidUpdate(prevProps){
        if(prevProps.state!== this.props.state){
            this.setState({
                developerName : this.props.state.developerName,
                startDate : this.props.state.startDate,
                endDate : this.props.state.endDate,
            }, () => this.setData())
        }
    }

    setData(){
        let startDate = this.state.startDate !== '' ? format(this.state.startDate,'DD/MM/YYYY') : '-';
        let endDate = this.state.endDate !== '' ? format(this.state.endDate , 'DD/MM/YYYY') : '-'
        let column = []
        let head_column = []

        this.props.head_table.forEach((head,index) => {
            column.push({title: "", width: {wpx: index === 0 ? 50 : 150}})
            head_column.push({value: head.label, style: { alignment: {horizontal: "center"} , font: { bold: true}}})
        })

        let data = [
            [{value: "รายงานยูนิตสมทบเรียกเก็บ", style: {font: {sz: "24", bold: true}}}],
            [{value: "วันที่ " + startDate + " ถึง " + endDate }],
            [{value: "บริษัทผู้พัฒนาโครงการ : " + this.props.state.developerName}],
            [{value: ""}],
            head_column ,
        ]

        let dataList = []
        
        for (const [index,transaction] of this.props.state.unitReportList.entries()){
            let productList = []
            dataList = []
            for (const product  of transaction.productAndService){
                productList.push({ value: numberWithCommas(product) , style: {alignment: {horizontal: "right"}}})
            }
            dataList.push(
                index+1, transaction.plan ,transaction.roomNo ,transaction.size,
                ...productList,
                 {value : numberWithCommas(transaction.total) , style: {alignment: {horizontal: "right"}}}
                )
            data.push(dataList)
        }
        let summary = []
        summary.push(
            '', '' ,'' ,{value: 'ยอดรวม (บาท)' , style: {font: { bold: true} , alignment: {horizontal: "right"}} } ,
            {value:numberWithCommas(this.props.state.totalProduct1),style: {font: { bold: true} , alignment: {horizontal: "right"}}},
            {value:numberWithCommas(this.props.state.totalProduct2),style: {font: { bold: true} ,alignment: {horizontal: "right"}}},
            {value:numberWithCommas(this.props.state.totalProduct3),style: {font: { bold: true} ,alignment: {horizontal: "right"}}},
            {value:numberWithCommas(this.props.state.totalOther) ,style: {font: { bold: true} , alignment: {horizontal: "right"}}},
            {value:numberWithCommas(this.props.state.totalUnit) ,style: {font: { bold: true} ,alignment: {horizontal: "right"}}}
        )
        data.push(summary)
        let multiDataSet = [
            {
                columns: column,
                data: data,
            }
        ];
        this.setState({multiDataSet: multiDataSet});

    }
    buttonElement() {
        return (
            <Dropdown.Item>Excel</Dropdown.Item>
        );
    }

    render() {
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"report_" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}>
                <ExcelSheet dataSet={this.state.multiDataSet}  name="Organization" />
            </ExcelFile>
        )
    }



}
export default UnitReportExcel ;