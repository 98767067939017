
import React from "react";
import "../../styles/commonArea.scss";
import Gallery from "./gallery";
import { Translation } from "react-i18next";
import iconBooking from "./iconBooking";
const CommonAreaCreateStep3 = ({
    state,
    handleImage,
    handleInputImage,
    onDelete,
    onClickPrev,
}) => {
    return (
        <Translation>
            {t =>
                <div className="row claim mt-10">

                    <React.Fragment>
                        <div className="claim">
                            <div className="create" style={{ zoom: '80%' }}>
                                <label>
                                    <p className="headerLabel18 fw-600 ml-2">01 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:General information")}</span></p>
                                    <iconBooking.iconStep1 />
                                </label>
                                <label className="ml-2 mr-2">
                                    <p className="headerLabel18 fw-600 ml-2">02 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:Application reservation setting")}</span></p>
                                    <iconBooking.iconStep2_1 />
                                </label>
                                <label>
                                    <p className="headerLabel18 fw-600 ml-2">03 <span className="label16 ml-2">{t("newBookingSingha_Create_step1:Facility picture")}</span></p>
                                    <iconBooking.iconStep3 />
                                </label>
                            </div>
                        </div>
                        <Gallery handleChange={handleImage} handleInputImage={handleInputImage} onDelete={onDelete} state={state} />

                        <div className=" col-6">
                            <button className="btn-primary-outline" type="button" onClick={onClickPrev}>
                                {t("Allaction:back")}
                            </button>
                        </div>
                        <div className="text-right col-6">
                            {
                                state.button_load ?
                                    <span className="text-red">{t("Allaction:waitingSaving")}</span>
                                    :
                                    <button className="btn-primary" type={"submit"}>
                                        {t("Allaction:save")}
                                    </button>
                            }

                        </div>
                    </React.Fragment>
                </div>
            }
        </Translation>
    );
};

export default CommonAreaCreateStep3;