import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Redirect from "react-router-dom/es/Redirect";
import CreateAndUpdateProductMutation from "./mutations/CreateAndUpdateProductMutation";
import SettingsServiceFine from "./settingsServiceFine";
import SettingsServiceType from "./settingsServiceType";
import Swal from "sweetalert2";
import { Translation } from 'react-i18next';
import i18n from 'i18next';

const _ = require('lodash');

class ServiceCreateAndUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            product: {
                id: '',
                name: '',
                productCode: 'SXXX',
                cost: 0,
                inputTax: '',
                type: 'service',
                price: 0,
                outputTax: '',
                chartOfAccount: {
                    id: ''
                },
                customerChartOfAccount: {
                    id: ''
                },
                description: '',
                servicePricing: {edges: [{node: {pricingType: 'STATIC'}}]}
            },
            redirectToIndividual: false,

            chart_of_account: [],
            customer_chart_of_account: [],
            isValid : true,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentWillMount() {
        if (this.props.product) {
            // edit
            this.setState({product: this.props.product})
        }
        if (this.props.chart_of_account.group4.edges.length > 0) {
            let data = _.cloneDeep(this.props.chart_of_account.group4);
            let chart_of_account_list = [];
            data.edges.forEach((chart_of_account) => {
                if ((_.startsWith(chart_of_account.node.chartOfAccountCode, "4100") || _.startsWith(chart_of_account.node.chartOfAccountCode, "4200")) && (chart_of_account.node.chartOfAccountCode !== "4100-04" && chart_of_account.node.chartOfAccountCode !== "4100-05")) {
                    chart_of_account_list.push(chart_of_account)
                }
            });
            this.setState({
                chart_of_account: chart_of_account_list
            })
        }

    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        value = (value === 'false') ? false : value;
        value = (value === 'true') ? true : value;

        if(event.target.name === 'product.price'){
            parseFloat(value) === 0 ? this.setState({isValid : false}) : this.setState({isValid : true})
        }
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }


    onSubmit(e) {
        e.preventDefault();
        
        if(this.state.product.servicePricing.edges[0].node.periodAdjustment){
            if(!this.state.product.servicePricing.edges[0].node.deferredIncome){ 
                Swal.fire(i18n.t("revenue_setting:Input DeferredIncome"),"",'warning')
                return;
            }
        }

        if(((parseFloat(this.state.product.price) === 0 ) && (this.state.product.servicePricing.edges[0].node.pricingType !== "STATIC" && this.state.product.servicePricing.edges[0].node.pricingType !== "INDIVIDUAL" ))){
            Swal.fire('Error',i18n.t("revenue_setting:Incorrect price amount"),'warning')
        }
        else {
            this.setState({loading: true});
            let input = {
            product: JSON.stringify(this.state.product),
            };
            CreateAndUpdateProductMutation(input, (response) => this.onCompleted(response), (response) => this.onError(response))
        }
    }

    onCompleted(response) {
        this.setState({loading: false});
        if (response.createAndUpdateProduct.newProduct.id) {
            Swal.fire(i18n.t("revenue_setting:Save successfully"), '', 'success').then(() => {
                if((!this.state.product.id) && _.get(this.state.product, 'servicePricing.edges.0.node.pricingType') === 'INDIVIDUAL') {
                    // product.servicePricing.edges.0.node.pricingType
                    // if create new individual redirect to add pricing each room
                    this.setState({redirectToIndividual: response.createAndUpdateProduct.newProduct.id})
                } else {
                    this.setState({redirectToList: true})
                }
            });
        }
    }

    onError(response) {
        this.setState({loading: false});
        Swal.fire('Error!', i18n.t("revenue_setting:Please try again"), 'warning')
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/inventory/product/list/service"/>
        }
        else if (this.state.redirectToIndividual) {
            return <Redirect to={"/accounting/inventory/product/individual-price-list/all/"+this.state.redirectToIndividual}/>
        }

        return (
            <React.Fragment>

                <Translation>
                    {t=>
                    <>
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="mb-4 content-inner">
                                <Link to="/accounting/inventory/product/list/service">
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                         alt="back" className="back"/>
                                </Link>
                                {t("revenue_setting:Revenue setting")}
                            </h3>
                        </div>
                    </div>
                    <div className="content-inner">
                        <div className="row">
                            <div className="col-xl-8">
    
                                <form onSubmit={this.onSubmit}>
                                    <div className="form-group row">
                                        <div className="col-md-3 col-sm-6">
                                            <label>{t("revenue_setting:No.")}</label>
                                            <input type="text" className="form-control"
                                                   value={this.state.product.productCode} disabled/>
                                        </div>
                                    </div>
    
                                    <div className="form-group">
                                        <div className="row ml-1" style={{display:'flex' , alignItems:'baseline'}}>
                                            <label>{t("revenue_setting:Revenue name")}</label>
                                            <p className="text-danger ml-1" style={{fontSize:'11px'}}> {t("revenue_setting:*Enter text not over 80 characters")} {this.state.product.name.length}/80 </p>
                                        </div >
                                        <input type="text" name="product.name" className="form-control"
                                               onChange={this.handleInputChange}
                                               value={this.state.product.name} required maxLength={80}/>
                                    </div>
    
                                    <div className="form-group">
                                        <label>{t("revenue_setting:Description")}</label>
                                        <textarea className="form-control"
                                                  rows="3" name="product.description"
                                                  onChange={this.handleInputChange}
                                                  value={this.state.product.description}/>
                                    </div>
    
                                    <h2 className="header-with-line mt-5">{t("revenue_setting:Bind the chart of account")}</h2>
                                    <SettingsServiceType handleInputChange={this.handleInputChange} state={this.state}
                                                         chart_of_account={this.props.chart_of_account} project_not_edit_account={this.props.project_not_edit_account} />
    
                                    <h2 className="header-with-line mt-5">{t("revenue_setting:Fine")}</h2>
                                    <SettingsServiceFine handleInputChange={this.handleInputChange} state={this.state}/>
    
                                    {this.props.project_have_vat &&
                                    <React.Fragment>
                                        <h2 className="header-with-line mt-5">{t("revenue_setting:Tax Computation")}</h2>
                                        <div className="form-group row">
                                            <div className="col-md-4">
                                                <label>{t("revenue_setting:Tax")}</label>
                                                <select className="form-control" id="output_tax"
                                                        name="product.outputTax" service_id={this.state.product.id}
                                                        value={this.state.product.outputTax}
                                                        onChange={this.handleInputChange}>
                                                    <option value="no_vat">{t("revenue_setting:none")}</option>
                                                    <option value="0">VAT 0%</option>
                                                    <option value="7">VAT 7%</option>
                                                </select>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    }
                                    {((this.props.product && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_service_edit'})) || (!this.props.product && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_service_create'}))) &&
                                    <div className="row mt-3 fade-up">
                                        <div className="col text-right">
                                            <div className="btn-group mr-2">
                                                <Link to="/accounting/inventory/product/list/service">
                                                    <button type="button"
                                                            className="btn btn-secondary add">
                                                            {t("revenue_setting:Cancel")}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="btn-group mr-2">
                                                <button type="submit" className="btn btn-primary add"
                                                        disabled={this.state.loading}>
                                                    {this.state.loading && <span
                                                        className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                        {t("revenue_setting:Save")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    </>
                    }
                </Translation>
            </React.Fragment>
        )
    }
}

export default ServiceCreateAndUpdate;
