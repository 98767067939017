/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AllowReceiveCollectionLawFirmInput = {|
  jsonObject: any,
  clientMutationId?: ?string,
|};
export type settingDocumentPaidTableMutationVariables = {|
  input: AllowReceiveCollectionLawFirmInput
|};
export type settingDocumentPaidTableMutationResponse = {|
  +allowReceiveCollectionLawFirm: ?{|
    +ok: ?boolean,
    +massage: ?string,
  |}
|};
export type settingDocumentPaidTableMutation = {|
  variables: settingDocumentPaidTableMutationVariables,
  response: settingDocumentPaidTableMutationResponse,
|};
*/


/*
mutation settingDocumentPaidTableMutation(
  $input: AllowReceiveCollectionLawFirmInput!
) {
  allowReceiveCollectionLawFirm(input: $input) {
    ok
    massage
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AllowReceiveCollectionLawFirmPayload",
    "kind": "LinkedField",
    "name": "allowReceiveCollectionLawFirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "massage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingDocumentPaidTableMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingDocumentPaidTableMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9c2edddba2c954f33472461a7b58c4c7",
    "id": null,
    "metadata": {},
    "name": "settingDocumentPaidTableMutation",
    "operationKind": "mutation",
    "text": "mutation settingDocumentPaidTableMutation(\n  $input: AllowReceiveCollectionLawFirmInput!\n) {\n  allowReceiveCollectionLawFirm(input: $input) {\n    ok\n    massage\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4854561c258acb9bdecadf19ef7bf93e';

module.exports = node;
