import React, { Component } from "react";
import Header from "../../components/header/";
import Sidebar from "../../components/sidebar/";
import Wrapper from "../../components/wrapper/";
import WrapperContent from "../../components/wrapper/wrapperContent";
import ProjectTopMenuNavigation from "./menuListBudget";

class MenuBudget extends Component {
  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <ProjectTopMenuNavigation center={true} />
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default MenuBudget;
