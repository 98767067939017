/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type PaymentGatewayPaymentType = "CROSSBILL" | "DEEPLINK" | "ETC" | "KSHER" | "SLIP_VERIFICATION" | "TWOCTWOP" | "%future added value";
export type PaymentGatewayTypeCal = "MONNEY_FAIT" | "PERCENT" | "%future added value";
export type receiveFormQueryVariables = {|
  makePayment?: ?boolean,
  receivePayment?: ?boolean,
|};
export type receiveFormQueryResponse = {|
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
  +otherExpense: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
  +unknownReceiveViewer: ?{|
    +allUnknownReceive: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +description: string,
          +amount: number,
        |}
      |}>
    |}
  |},
  +paymentGatewaySettingList: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +paymentType: PaymentGatewayPaymentType,
        +fee: number,
        +typeCal: PaymentGatewayTypeCal,
        +bankAccount: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |},
      |}
    |}>
  |},
|};
export type receiveFormQuery = {|
  variables: receiveFormQueryVariables,
  response: receiveFormQueryResponse,
|};
*/


/*
query receiveFormQuery(
  $makePayment: Boolean
  $receivePayment: Boolean
) {
  bankAccountViewer {
    allBankAccount(status: true, accountType_In: "current_account, saving_account, saving_deposit, fixed_deposit", makePayment: $makePayment, receivePayment: $receivePayment) {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
      totalCount
    }
    id
  }
  viewer {
    allChartOfAccount(chartOfAccountCode_Istartswith: "2210") {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
  otherExpense: viewer {
    allChartOfAccount(chartOfAccountCode_Istartswith: "5830") {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
  unknownReceiveViewer {
    allUnknownReceive(status: "wait") {
      edges {
        node {
          id
          docNumber
          description
          amount
        }
      }
    }
    id
  }
  paymentGatewaySettingList {
    edges {
      node {
        id
        paymentType
        fee
        typeCal
        bankAccount {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "makePayment"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "receivePayment"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "receivePayment",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "makePayment",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bankName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "accountType_In",
      "value": "current_account, saving_account, saving_deposit, fixed_deposit"
    },
    {
      "kind": "Variable",
      "name": "makePayment",
      "variableName": "makePayment"
    },
    {
      "kind": "Variable",
      "name": "receivePayment",
      "variableName": "receivePayment"
    },
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ChartOfAccountNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chartOfAccountCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "2210"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"2210\")"
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "5830"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"5830\")"
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": "wait"
    }
  ],
  "concreteType": "UnknownReceiveNodeConnection",
  "kind": "LinkedField",
  "name": "allUnknownReceive",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UnknownReceiveNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnknownReceiveNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allUnknownReceive(status:\"wait\")"
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentGatewayNodeConnection",
  "kind": "LinkedField",
  "name": "paymentGatewaySettingList",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentGatewayNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentGatewayNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeCal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "bankAccount",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "receiveFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "otherExpense",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UnknownReceiveViewer",
        "kind": "LinkedField",
        "name": "unknownReceiveViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "receiveFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "otherExpense",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UnknownReceiveViewer",
        "kind": "LinkedField",
        "name": "unknownReceiveViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c00bab5bdd0afe1c02d5688bbf346c41",
    "id": null,
    "metadata": {},
    "name": "receiveFormQuery",
    "operationKind": "query",
    "text": "query receiveFormQuery(\n  $makePayment: Boolean\n  $receivePayment: Boolean\n) {\n  bankAccountViewer {\n    allBankAccount(status: true, accountType_In: \"current_account, saving_account, saving_deposit, fixed_deposit\", makePayment: $makePayment, receivePayment: $receivePayment) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n      totalCount\n    }\n    id\n  }\n  viewer {\n    allChartOfAccount(chartOfAccountCode_Istartswith: \"2210\") {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n  otherExpense: viewer {\n    allChartOfAccount(chartOfAccountCode_Istartswith: \"5830\") {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n  unknownReceiveViewer {\n    allUnknownReceive(status: \"wait\") {\n      edges {\n        node {\n          id\n          docNumber\n          description\n          amount\n        }\n      }\n    }\n    id\n  }\n  paymentGatewaySettingList {\n    edges {\n      node {\n        id\n        paymentType\n        fee\n        typeCal\n        bankAccount {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48fdd394244639a4c6ec5f7af5935cf6';

module.exports = node;
