import React from "react";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import SelectGlobalBank from "../../libs/selectGlobalBank";
import {Translation} from "react-i18next";

const cheque = {
    chequeIssuedDate: new Date(),
    chequeNumber: '',
    branchNumber: '',
    bankName: '',
    // accountName: '',
    // amount: '',
    // accountNumber: '',
};

class ReceiveCheque extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cheque: cheque,
            chequeImageName: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        
        if(event.target.type === 'file'){

            let file = event.currentTarget?.files[0];

            if(file === undefined){
                this.setState({chequeImageName: ""})
            }else{
                this.setState({chequeImageName: event.target.files[0].name})
            }

            this.props.handleInputChange({
                target: {
                    name: event.target.name,
                    value: file,
                }
            });
        }
    }

    render() {
        let index = [this.props.index];
        let receive_channel = this.props.receive_channel[this.props.index];
        return (

            <Translation>
                {t => 
                    <div className="mt-4 border p-4">
                        <div className="row">
                            <div className="col">
                                <h6>{t('createReceive:Detail of cheque')}</h6>
                            </div>
                        </div>
        
                        <div className="row mt-3">
                            {/*<div className="col-4">*/}
                            {/*    <label htmlFor="accountName"*/}
                            {/*           className="mr-3">จ่าย</label>*/}
                            {/*    <input type="text" name={"receive_channel["+index+".]cheque.accountName"} className="form-control"*/}
                            {/*           id="accountName" value={receive_channel.cheque.accountName}*/}
                            {/*           onChange={this.props.handleInputChange}*/}
                            {/*           required/>*/}
                            {/*</div>*/}
                            <div className="col">
                                <p>{t('createReceive:Cheque No.')}</p>
                                <input type="text" name={"receive_channel["+index+".]cheque.chequeNumber"} className="form-control"
                                    value={receive_channel.cheque.chequeNumber}
                                    onChange={this.props.handleInputChange}
                                    maxLength={8}
                                    minLength={8}
                                    required/>
                            </div>
                            <SelectGlobalBank
                                name={"receive_channel[" + index + ".]cheque.bankName"}
                                value={receive_channel.cheque.bankName}
                                handleInputChange={this.props.handleInputChange}
                            />
                            <div className="col">
                                <p>{t('createReceive:Branch')}</p>
                                <input type="text" name={"receive_channel["+index+".]cheque.branchNumber"} className="form-control"
                                    value={receive_channel.cheque.branchNumber}
                                    onChange={this.props.handleInputChange}
                                    maxLength={200}
                                    required/>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-4">
                                <p>{t('createReceive:date')}</p>
                                <DatePickerAdapter id="chequeIssuedDate"
                                                className="form-control"
                                                name={"receive_channel["+index+"].cheque.chequeIssuedDate"}
                                                data-key="cheque"
                                                selected={receive_channel.cheque.chequeIssuedDate}
                                                onChange={this.props.handleInputChange}
        
                                                required={true}/>
                            </div>
                            {(this.props.page === "receive" || this.props.page === "other_receiver") &&
                              <div className="col">
                                <label>{t('createReceive:Receipt')}</label> <br/>
                                <label className={
                                    this.props.receive_channel[this.props.index].cheque.chequeImage ?
                                    "btn btn-success cursor mr-1" : "btn btn-secondary cursor"
                                    }>
                                    {this.props.receive_channel[this.props.index].cheque.chequeImage ? t('createReceive:Done') : t('createReceive:Upload receipt')}
                                    <input type="file" 
                                        name={"receive_channel["+index+"].cheque.chequeImage"}
                                        data-key="payment_list"
                                        onChange={this.handleInputChange}
                                        hidden/>
                                </label>
                                {(this.state.chequeImageName && this.state.chequeImageName.length >= 30) ?
                                    this.state.chequeImageName.substring(0, 30) + '...' : this.state.chequeImageName
                                }
                                </div>
                            }
                            {/*<div className="col-5">*/}
                            {/*    <p>จำนวนเงิน (baht)</p>*/}
                            {/*    <input type="text" name={"receive_channel["+index+".]cheque.amount"} className="form-control text-right"*/}
                            {/*           id="amount" value={receive_channel.cheque.amount}*/}
                            {/*           onChange={this.props.handleInputChange}*/}
                            {/*           required/>*/}
                            {/*</div>*/}
                            {/*<div className="col">*/}
                            {/*    <p>เลขบัญชี</p>*/}
                            {/*    <input type="text" name={"receive_channel["+index+".]cheque.accountNumber"} className="form-control"*/}
                            {/*           value={receive_channel.cheque.accountNumber}*/}
                            {/*           onChange={this.props.handleInputChange}*/}
                            {/*           required/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                }
            </Translation>

        )
    }
}

export default ReceiveCheque;
