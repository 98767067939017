/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type waterMeterCreateHistoryQueryVariables = {|
  id: string
|};
export type waterMeterCreateHistoryQueryResponse = {|
  +allResidential: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +waterMeterUnit: ?{|
    +id: string,
    +issuedDate: any,
    +meterValue: ?number,
    +initialChangeMeterValue: ?number,
    +residential: {|
      +id: string
    |},
    +remark: string,
    +added: any,
    +editDate: ?any,
  |},
|};
export type waterMeterCreateHistoryQuery = {|
  variables: waterMeterCreateHistoryQueryVariables,
  response: waterMeterCreateHistoryQueryResponse,
|};
*/


/*
query waterMeterCreateHistoryQuery(
  $id: ID!
) {
  allResidential {
    edges {
      node {
        id
        name
      }
    }
  }
  waterMeterUnit(id: $id) {
    id
    issuedDate
    meterValue
    initialChangeMeterValue
    residential {
      id
    }
    remark
    added
    editDate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ResidentialNodeConnection",
    "kind": "LinkedField",
    "name": "allResidential",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "WaterMeterUnitNode",
    "kind": "LinkedField",
    "name": "waterMeterUnit",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "issuedDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "meterValue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initialChangeMeterValue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remark",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "added",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "editDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "waterMeterCreateHistoryQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "waterMeterCreateHistoryQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4efc44f9cc0e38873e7d582a55481467",
    "id": null,
    "metadata": {},
    "name": "waterMeterCreateHistoryQuery",
    "operationKind": "query",
    "text": "query waterMeterCreateHistoryQuery(\n  $id: ID!\n) {\n  allResidential {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  waterMeterUnit(id: $id) {\n    id\n    issuedDate\n    meterValue\n    initialChangeMeterValue\n    residential {\n      id\n    }\n    remark\n    added\n    editDate\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '721c64911eee1c3a13c2d258d4ef2005';

module.exports = node;
