import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink exact={true} to="/contact/complaint/open">
                                <div>
                                    <Translation>
                                        {t => <span>{t("complaint:request")}</span>}
                                    </Translation>
                                    {/*<span className="badge navigation">8</span>*/}
                                </div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact/complaint/finished">
                                <Translation>
                                    {t => <span>{t("complaint:finish")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;