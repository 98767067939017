/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CarCarType = "CAR" | "MOTORCYCLE" | "%future added value";
export type vehicleEditQueryVariables = {|
  id: string,
  residential_id: string,
|};
export type vehicleEditQueryResponse = {|
  +residential: ?{|
    +name: string
  |},
  +car: ?{|
    +id: string,
    +licencePlate: string,
    +carDescription: string,
    +province: ?string,
    +carBrand: ?string,
    +carModel: ?string,
    +carYear: ?string,
    +startDate: any,
    +carType: CarCarType,
    +chargedFee: boolean,
    +carSticker: ?string,
    +carSize: ?string,
    +floor: ?string,
    +space: ?string,
    +carImage: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +image: ?string,
          +description: ?string,
        |}
      |}>
    |},
  |},
|};
export type vehicleEditQuery = {|
  variables: vehicleEditQueryVariables,
  response: vehicleEditQueryResponse,
|};
*/


/*
query vehicleEditQuery(
  $id: ID!
  $residential_id: ID!
) {
  residential(id: $residential_id) {
    name
    id
  }
  car(id: $id) {
    id
    licencePlate
    carDescription
    province
    carBrand
    carModel
    carYear
    startDate
    carType
    chargedFee
    carSticker
    carSize
    floor
    space
    carImage {
      edges {
        node {
          id
          image
          description
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "residential_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "residential_id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "CarNode",
  "kind": "LinkedField",
  "name": "car",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "licencePlate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "province",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carBrand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carModel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chargedFee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carSticker",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "space",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CarImageNodeConnection",
      "kind": "LinkedField",
      "name": "carImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarImageNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CarImageNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "vehicleEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "vehicleEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "553d8cf3809ab7c1a1516d9bdb55213d",
    "id": null,
    "metadata": {},
    "name": "vehicleEditQuery",
    "operationKind": "query",
    "text": "query vehicleEditQuery(\n  $id: ID!\n  $residential_id: ID!\n) {\n  residential(id: $residential_id) {\n    name\n    id\n  }\n  car(id: $id) {\n    id\n    licencePlate\n    carDescription\n    province\n    carBrand\n    carModel\n    carYear\n    startDate\n    carType\n    chargedFee\n    carSticker\n    carSize\n    floor\n    space\n    carImage {\n      edges {\n        node {\n          id\n          image\n          description\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd95d4c5f56da85e93894aef64af2fc34';

module.exports = node;
