import React from 'react';
import ComponentPagination from "../../libs/componentPagination";
import {Link} from "react-router-dom";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Pagination from "../../libs/newPagination";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import "./style/guaranteeMoneyReceived.scss"
import getNameResidential from "../../libs/getNameResidential";
import numberWithComma from "../../libs/numberWithComma";
import { Translation } from 'react-i18next';
import Loading from "../../libs/loading"
import i18next from 'i18next';

const query = graphql`
    query clearGuaranteeMoneyReceivedListTableQuery($first: Int, $last: Int,  $start_date: DateTime, $end_date: DateTime, $search: String){
        allClearGuaranteeMoneyReceived(first: $first, last: $last,search:$search, startDate:$start_date, endDate:$end_date){
            totalCount
           
            edges{
                node{
                    id
                    docNumber
                    issuedDate
                    updated
                    cancelRemark
                    status
                    firstName
                    lastName
                    name
                    taxIdNumber
                    phone
                    email
                    address
                    country
                    province
                    district
                    city
                    postalCode
                    payGroup
                    contact{
                        typeOfContact
                    }
                    contact{
                        id
                        name
                        firstName
                        lastName
                        typeOfContact
                        refNumber
                        registeredName
                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode
                    }
                    guarantee{
                        id
                        docNumber
                        amount
                        firstName
                        lastName
                        contact{
                            id
                            name
                            firstName
                            lastName
                            typeOfContact
                            refNumber
                            registeredName
                            registeredAddress
                            registeredCountry
                            registeredProvince
                            registeredCity
                            registeredDistrict
                            registeredPostalCode
                        }
                        issuedDate
                        status
                        creator
                        description
                    }
                }

            }
        }
    }
`;

class ClearGuaranteeMoneyReceivedListTable extends ComponentPagination {
    render() {
        return (

            <QueryRenderer
                environment={environment}
                query={query}
                variables={{
                    first: this.state.first,
                    last: this.state.last,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    search: this.props.search
                }}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="table-responsive fade-up">
                                <table className="table table-hover mt-2">
                                    <thead className="thead-light">
                                    <Translation>
                                        {t=>
                                        <tr>
                                            <th className="text-center">{t("guarantee_money_received:No.")}</th>
                                            <th className="text-center">{t("guarantee_money_received:Date of issue")}</th>
                                            <th className="text-center">{t("guarantee_money_received:Code")}</th>
                                            <th>{t("guarantee_money_received:Room No.")}</th>
                                            <th>{t("guarantee_money_received:Name")}</th>
                                            <th>{t("guarantee_money_received:Detail")}</th>
                                            <th className="text-right">{t("guarantee_money_received:Amount")}</th>
    
                                            <th className="text-center">{t("guarantee_money_received:Refer")}</th>
                                            <th className="text-center">{t("guarantee_money_received:By")}</th>
                                            <th className="text-center">{t("guarantee_money_received:Cancellation date")}</th>
                                            <th className="text-center">{t("guarantee_money_received:Cause of damage")}</th>

                                        </tr>}
                                    </Translation>
                                    </thead>
                                    <tbody>
                                    {props.allClearGuaranteeMoneyReceived.edges.map((clear_guarantee) => {
                                        return  (
                                            <tr key={clear_guarantee.node.id}>
                                                {clear_guarantee.node.status !== "VOID" ?
                                                    <td className="text-center">
                                                        <Link
                                                            to={{
                                                                pathname:"/accounting/guarantee-money-received/view-clear/" + clear_guarantee.node.id,
                                                                state:clear_guarantee.node
                                                            }} 
                                                            >
                                                            {clear_guarantee.node.docNumber}
                                                        </Link>
                                                    </td>:
                                                    <td className="text-center text-danger">
                                                        {clear_guarantee.node.docNumber}
                                                    </td>
                                                 }
                                                {clear_guarantee.node.status === "VOID" ?
                                                    <React.Fragment>
                                                        <td className="text-center text-danger">{format(clear_guarantee.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                        <td className="text-center text-danger">{clear_guarantee.node.guarantee.contact.refNumber}</td>
                                                        <td className="text-danger">
                                                                {
                                                                clear_guarantee.node?.contact?.typeOfContact ? 
                                                                    clear_guarantee.node?.contact?.typeOfContact === 'RESIDENTIAL' ?
                                                                    clear_guarantee.node?.contact?.name : '-'
                                                                :
                                                                    clear_guarantee.node.guarantee.contact.typeOfContact === 'RESIDENTIAL' ?
                                                                            clear_guarantee.node.guarantee.contact.name : '-'
                                                                }{" "}
                                                        </td>
                                                        <td className="text-danger">{
                                                             clear_guarantee.node?.contact?.typeOfContact ?
                                                                clear_guarantee.node?.contact?.typeOfContact === 'RESIDENTIAL' ?
                                                                    getNameResidential(clear_guarantee?.node?.firstName, clear_guarantee.node?.lastName)
                                                                : clear_guarantee?.node?.contact.name
                                                            :
                                                            clear_guarantee.node.guarantee.contact.typeOfContact === 'RESIDENTIAL' ?
                                                            getNameResidential(clear_guarantee.node.guarantee.firstName, clear_guarantee.node.guarantee.lastName) 
                                                            : clear_guarantee.node.guarantee.contact.name} <span style={{color: '#F43853'}}>{" "}
                                                            {  
                                                                clear_guarantee.node.payGroup === "OTHER" ? `(${i18next.t("AgentRole:External Person")})` : 
                                                                clear_guarantee.node.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : 
                                                                clear_guarantee.node.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})`:""
                                                            }
                                                        </span>
                                                           </td>
                                                        <td className="text-danger">{clear_guarantee.node.guarantee.description} </td>
                                                        <td className="text-right text-danger">{numberWithComma(clear_guarantee.node.guarantee.amount)}</td>
                                                        <td className="text-center text-danger">{clear_guarantee.node.guarantee.docNumber}</td>
                                                        <td className="text-center text-danger">{clear_guarantee.node.guarantee.creator}</td>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <td className="text-center">{format(clear_guarantee.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                        <td className="text-center">{clear_guarantee.node.guarantee.contact.refNumber}</td>
                                                        <td>{
                                                                clear_guarantee.node?.contact?.typeOfContact ? 
                                                                    clear_guarantee.node?.contact?.typeOfContact === 'RESIDENTIAL' ?
                                                                    clear_guarantee.node?.name : clear_guarantee.node?.contact?.name 
                                                                :
                                                                    clear_guarantee.node.guarantee.contact.typeOfContact === 'RESIDENTIAL' ?
                                                                            clear_guarantee.node.guarantee.contact.name : '-'
                                                                } 
                                                                </td>
                                                        <td>{
                                                             clear_guarantee.node?.contact?.typeOfContact ?
                                                                clear_guarantee.node?.contact?.typeOfContact === 'RESIDENTIAL' ?
                                                                    getNameResidential(clear_guarantee?.node?.firstName, clear_guarantee.node?.lastName)
                                                                : clear_guarantee?.node?.contact.name
                                                            :
                                                                clear_guarantee.node.guarantee.contact.typeOfContact === 'RESIDENTIAL' ?
                                                                getNameResidential(clear_guarantee.node.guarantee.firstName, clear_guarantee.node.guarantee.lastName) 
                                                                : clear_guarantee.node.guarantee.contact.name
                                                            }{" "}
                                                            <span style={{color: '#F43853'}}>
                                                                    {  
                                                                        clear_guarantee.node.payGroup === "OTHER" ? `(${i18next.t("AgentRole:External Person")})` : 
                                                                        clear_guarantee.node.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : 
                                                                        clear_guarantee.node.payGroup === "AGENT" ? `(${i18next.t("AgentRole:Agent")})`:""
                                                                    }
                                                                </span>
                                                           </td>
                                                        <td>{clear_guarantee.node.guarantee.description} </td>
                                                        <td className="text-right">{numberWithComma(clear_guarantee.node.guarantee.amount)}</td>
                                                        <td className="text-center">{clear_guarantee.node.guarantee.docNumber}</td>
                                                        <td className="text-center">{clear_guarantee.node.guarantee.creator}</td>
                                                    </React.Fragment>
                                                
                                                }
                                                
                                                {clear_guarantee.node.status ==="VOID" ?
                                                    <React.Fragment>
                                                        <td className="text-center text-danger">{format(clear_guarantee.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                        <td className="text-center text-danger">{clear_guarantee.node.cancelRemark}</td>
                                                    </React.Fragment> :
                                                    <React.Fragment>
                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                    </React.Fragment>
                                                }
                                            </tr>
                                        )
                                    }
                                   )}

                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage}
                                            first={this.state.first}
                                            last={this.state.last}
                                            totalCount={props.allClearGuaranteeMoneyReceived.totalCount}/>
                            </div>
                        </React.Fragment>

                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default ClearGuaranteeMoneyReceivedListTable;
