import {
    commitMutation,
} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";


const mutation = graphql`
    mutation clearGuaranteeMoneyReceivedUpdateMutation($input: UpdateClearGuaranteeMoneyReceivedInput!){
        updateClearGuaranteeMoneyReceived(input:$input){
            ok
            warningText
        }
    }
`;

export default function ClearGuaranteeMoneyReceivedUpdate(input, callback, error_callback) {
    const variables = {
        input: input
    };

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
