import React from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import ComponentPagination from "../../libs/componentPagination";
import { Prompt } from "react-router";
import Pagination from "../../libs/newPagination";
import { fetchQuery } from "relay-runtime";
import { commitMutation } from "react-relay";
import _ from "lodash";
import ExportElectricMeter from "./serverExportElectricMeter";
import Swal from "sweetalert2";
import Loading from "../../libs/loading"
import i18n from "i18next";
import i18next from "i18next";
import SortOrderAsc from "../../components/FucntionLib/sortAsc";

var time;
const query = graphql`
  query electricMeterListRecordQuery(
    $first: Int
    $last: Int
    $year: Float
    $before_year: Float
    $before_month: Float
    $search: String
  ) {
    allResidential(
      first: $first
      last: $last
      order: "added"
      search: $search
    ) {
      edges {
        node {
          id
          name
          electricMeterUnit(year: $year) {
            edges {
              node {
                id
                month
                year
                meterValue
                changeElectricMeter
              }
            }
          }
          beforeMeterUnit: electricMeterUnit(
            year: $before_year
            month: $before_month
          ) {
            edges {
              node {
                id
                month
                year
                meterValue
                changeElectricMeter
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

const mutation = graphql`
  mutation electricMeterListRecordMutation(
    $input: UpdateElectricMeterRecordInput!
  ) {
    updateElectricMeterRecord(input: $input) {
      electricMeterList {
        edges {
          node {
            id
            month
            year
            meterValue
          }
        }
      }
    }
  }
`;

class ElectricMeterListRecord extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.meter_data = false;
    this.state.search = "";
    this.state.search_input = "";
    this.state.temp_selected_year = "";
    this.inputHandle = this.inputHandle.bind(this);
    this.checkDisableInput = this.checkDisableInput.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getMeterList = this.getMeterList.bind(this);
    this.meterInputHandle = this.meterInputHandle.bind(this);
    this.getMeterValue = this.getMeterValue.bind(this);
    this.compareMeterValue = this.compareMeterValue.bind(this);
    this.blurChecker = this.blurChecker.bind(this);
    this.save = this.save.bind(this);
    this.onCheckClassName = this.onCheckClassName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {
    this.date = new Date();
    let current_year = this.date.getFullYear();
    let current_month = this.date.getMonth() + 1;
    this.setState(
      {
        loading: false,
        list_of_year: [current_year + 1, current_year, current_year - 1, current_year - 2, current_year - 3],
        selected_year: current_year,
        temp_selected_year: current_year,
        current_year: current_year,
        current_month: current_month,
        disabled_input_list: {},
        table_data_list: {}, // meter data for render table
      },
      () => {
        this.checkDisableInput();
      }
    );
  }

  componentDidMount() {
    this.getMeterList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.first !== this.state.first ||
      prevState.last !== this.state.last
    ) {
      this.getMeterList();
    }

    if (prevState.search !== this.state.search) {
      clearTimeout(time);
      time = setTimeout(() => {
        this.getMeterList();
      }, 500);
    }
  }

  getMeterValue(residential, month) {
    if (month === 0) {
      for (let i = 0; i < residential.node.beforeMeterUnit.edges.length; i++) {
        if (residential.node.beforeMeterUnit.edges[i].node.month === 12) {
          return {
            value: residential.node.beforeMeterUnit.edges[i].node.meterValue,
            is_change:
              residential.node.beforeMeterUnit.edges[i].node
                .changeElectricMeter,
          };
        }
      }
      return "";
    } else {
      for (
        let i = 0;
        i < residential.node.electricMeterUnit.edges.length;
        i++
      ) {
        if (residential.node.electricMeterUnit.edges[i].node.month === month) {
          return {
            value: residential.node.electricMeterUnit.edges[i].node.meterValue,
            is_change:
              residential.node.electricMeterUnit.edges[i].node
                .changeElectricMeter,
          };
        }
      }
      return "";
    }
  }

  getMeterList() {
    this.setState({ meter_data: false }, () => {
      let variables = {
        first: this.state.first,
        last: this.state.last,
        year: this.state.selected_year,
        before_year: this.state.selected_year - 1,
        before_month: 12,
        search: this.state.search,
      };
      fetchQuery(environment, query, variables).then((results) => {
        let data = _.cloneDeep(results);
        let table_data_list = {};
        data.allResidential.edges.forEach((residential, index) => {
          table_data_list[residential.node.id] = [];
          Array.from(new Array(13)).forEach((value, month) => {
            let meter_value = this.getMeterValue(residential, month);
            let data_value = "";
            let warning_change_meter = false;
            if (_.isObject(meter_value)) {
              data_value = meter_value.value;
              if (meter_value.is_change) {
                warning_change_meter = true;
              }
            }

            table_data_list[residential.node.id].push({
              value: data_value,
              edit: false,
              warning: false,
              warning_gte: false,
              warning_change_meter: warning_change_meter,
            });
          });
        });

        const copyOfResidentialEdges = [...data.allResidential.edges];
        let sortedResidentialEdges = []
        if (localStorage.getItem("site_id") === '636') {
          sortedResidentialEdges = SortOrderAsc(copyOfResidentialEdges)
        } else {
          sortedResidentialEdges = copyOfResidentialEdges
        }

        this.setState({
          meter_data: data,
          table_data_list: table_data_list,
          sortedResidentialEdges: sortedResidentialEdges
        });
      });
    });
  }


  meterInputHandle(e) {
    let event = _.cloneDeep(e);
    let input_name = event.target.name.split(".");
    let edit_field_name = _.concat(_.dropRight(input_name), "edit");
    let table = _.cloneDeep(this.state.table_data_list);
    _.set(table, input_name, e.target.value);
    _.set(table, edit_field_name, true);

    //เช็คห้ามกรอกค่าเดือนเว้นเดือน (ห้ามเว้น)
    _.forIn(table, (room, key) => {
      //เดือนแรกที่ถูกบันทึก
      let first_index_value = _.findIndex(_.get(table, key), "value");
      //เดือนสุดท้ายที่ถูกบันทึก
      let last_index_value = _.findLastIndex(_.get(table, key), "value");
      _.forIn(room, (meter_input, index) => {
        if (parseInt(first_index_value) !== -1) {
          if (
            parseInt(index) === parseInt(first_index_value) ||
            (parseInt(index) < parseInt(first_index_value) &&
              !meter_input.value)
          ) {
            _.set(table, [key, index, "warning_null_before_month"], false);
          } else if (
            parseInt(index) > parseInt(first_index_value) &&
            parseInt(index) < parseInt(last_index_value)
          ) {
            meter_input.value
              ? _.set(table, [key, index, "warning_null_before_month"], false)
              : _.set(table, [key, index, "warning_null_before_month"], true);
          } else if (parseInt(index) === parseInt(last_index_value)) {
            if (_.get(table, [key, parseInt(index) - 1, "value"])) {
              _.set(table, [key, index, "warning_null_before_month"], false);
            }
          } else if (
            parseInt(index) > parseInt(last_index_value) &&
            !meter_input.value
          ) {
            _.set(table, [key, index, "warning_null_before_month"], false);
          }
        }
      });
    });
    this.setState({ table_data_list: table });
  }

  checkDisableInput() {
    let disabled_input_list = {};
    this.state.list_of_year.forEach((year, index) => {
      let each_year = { [year]: {} };
      let input_index = {};

      // generate list of disabled
      Array.from(new Array(13)).forEach((month, index) => {
        _.assign(input_index, { [index]: true });
        if (
          index > this.state.current_month - 3 &&
          index < this.state.current_month + 2 &&
          year === this.state.current_year
        ) {
          _.assign(input_index, { [index]: false });
        }
      });
      _.assign(each_year, { [year]: input_index });

      // assign new disable value for january and december month
      if (year === this.state.current_year && this.state.current_month === 12) {
        disabled_input_list[year + 1][0] = false;
        disabled_input_list[year + 1][1] = false;
      }
      disabled_input_list = _.assign(disabled_input_list, each_year);
    });

    this.setState({ disabled_input_list: disabled_input_list });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (_.get(form.elements, [index + 13])) {
        form.elements[index + 13].focus();
      }
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  inputHandle(e) {
    this.setState({
      temp_selected_year: e.target.value
    })
  }

  handleSearch(event) {
    let call_back = () => { };
    call_back = this.getMeterList;

    this.setState(
      {
        selected_year: this.state.temp_selected_year,
        search: event.target.value
      },
      () => {
        call_back();
      }
    );
  }

  blurChecker(e) {
    let event = _.cloneDeep(e);
    let input_current_month = event.target.name.split(".");
    let residential_id = input_current_month[0];
    let before_month = input_current_month[1] - 1 || 0;
    let index_before_month = [residential_id, before_month, "value"];
    this.compareMeterValue(input_current_month, index_before_month);
  }

  compareMeterValue(input_current_month, index_before_month) {
    let warning_field_name = _.concat(
      _.dropRight(input_current_month),
      "warning"
    );
    let warning_gte_field_name = _.concat(
      _.dropRight(input_current_month),
      "warning_gte"
    );
    let warning_change_meter_field_name = _.concat(
      _.dropRight(input_current_month),
      "warning_change_meter"
    );
    let table = _.cloneDeep(this.state.table_data_list);

    if (!_.get(table, warning_change_meter_field_name)) {
      if (
        parseInt(_.get(this.state.table_data_list, input_current_month)) <
        parseInt(_.get(this.state.table_data_list, index_before_month))
      ) {
        _.set(table, warning_field_name, true);
        _.set(table, warning_gte_field_name, false);
      } else if (
        parseInt(_.get(this.state.table_data_list, index_before_month)) !== 0 &&
        parseInt(_.get(this.state.table_data_list, index_before_month)) * 3 <
        parseInt(_.get(this.state.table_data_list, input_current_month))
      ) {
        _.set(table, warning_gte_field_name, true);
        _.set(table, warning_field_name, false);
      } else {
        _.set(table, warning_field_name, false);
        _.set(table, warning_gte_field_name, false);
      }
      this.setState({ table_data_list: table });
    }
  }

  save() {
    let update_meter_list = [];
    let table = _.cloneDeep(this.state.table_data_list);
    let null_list = [];
    // let isNull = false;
    _.forIn(table, (room, key) => {
      _.forIn(room, (value, index) => {
        value.warning_null_before_month && null_list.push(value);
      });
    });
    if (null_list.length > 0) {
      // isNull = true;
      return Swal.fire(
        "ไม่สามารถบันทึกค่าได้",
        "กรุณาบันทึกค่าให้ถูกต้อง",
        "warning"
      );
    }

    // eslint-disable-next-line
    for (let room_id in this.state.table_data_list) {
      // eslint-disable-next-line
      for (let month in this.state.table_data_list[room_id]) {
        let meter = this.state.table_data_list[room_id][month];
        if (meter["edit"]) {
          update_meter_list.push({
            room_id: room_id,
            month: month,
            value: meter["value"],
          });
        }
      }
    }

    if (update_meter_list.length > 0 && this.state.loading === false) {
      let variables = {
        input: {
          year: this.state.selected_year,
          meterStack: JSON.stringify(update_meter_list),
        },
      };

      this.setState({ loading: true });

      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response) => {
          if (response.updateElectricMeterRecord) {
            Swal.fire(i18n.t('meter:Save successfully'), '', 'success').then(() => {
              this.getMeterList();
            });
          } else {
            Swal.fire(i18n.t('meter:Failed to save'), response.updateElectricMeterRecord, 'warning').then(() => {
              this.getMeterList();
            });
          }
          this.setState({ loading: false });
        },
        onError: (err) => {
          Swal.fire(i18n.t('meter:Failed to save'), err, 'warning');
          this.setState({ loading: false });
        },
      });
    }
  }

  onCheckClassName(previous_meter, value, is_change = false) {
    if (is_change) {
      return "border border-warning meter form-control";
    } else if (
      (previous_meter > parseInt(value) && previous_meter && value) ||
      (previous_meter * 3 < parseInt(value) && previous_meter && value)
    ) {
      return "border border-danger meter form-control";
    } else {
      return "meter form-control";
    }
  }

  render() {
    return (
      <Translation>
        {t =>
          <React.Fragment>
            <Prompt
              message={i18next.t("Allaction:waitingSaving")}
              when={this.state.loading}
            />
            <div className="row">
              <div className="col-4">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("accounting:electric meter record")}</span>}
                  </Translation>
                </h3>
              </div>
              <div className="col-4">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_electric_meter_print",
                }) && <ExportElectricMeter state={this.state} />}
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_electric_meter_edit",
                }) && (
                    <Link to="/accounting/electric-meter/create_history">
                      <Translation>
                        {t =>
                          <button
                            type="button"
                            className="btn print float-right mr-1"
                            disabled={this.state.loading}
                          >
                            {t("electric_meter_record:Electric meter change record")}
                          </button>}
                      </Translation>
                    </Link>
                  )}
              </div>

              <div className="col">
                <form action="">
                  <div className="form-group">
                    <select
                      name="selected_year"
                      className="form-control dropdown-custom-arrow"
                      value={this.state.temp_selected_year}
                      onChange={this.inputHandle}
                    >
                      {this.state.list_of_year.map((value) => {
                        return (
                          <Translation>
                            {t =>
                              <option value={value} key={value}>
                                {t("electric_meter_record:Record sheet (year)")} {value}
                              </option>}
                          </Translation>
                        );
                      })}
                    </select>
                  </div>
                </form>
              </div>
              <Translation>
                {t =>
                  <div className="col input-group">
                    <input
                      type="text"
                      className="form-control input-size"
                      placeholder={t("electric_meter_record:Search")}
                      value={this.state.search_input}
                      name="search"
                      onChange={(e) => this.setState({ search_input: e.target.value })}
                      onKeyDown={this.handleKeyDown}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary form-control search-button w-25"
                      name="search"
                      value={this.state.search_input}
                      onClick={(e) => this.handleSearch(e)}
                    >
                      {t("electric_meter_record:Search")}
                    </button>
                  </div>}
              </Translation>
            </div>
            <div className="content-inner">
              <div className="row">
                {this.state.meter_data && (
                  <React.Fragment>
                    <div className="col-12">
                      <form className="card">
                        <table className="table table-bordered">
                          <thead>
                            <Translation>
                              {t =>
                                <tr className="bg-light text-center">
                                  <th>{t("electric_meter_record:Room No.")}</th>
                                  <th>
                                    {t("electric_meter_record:Dec")}{" "}
                                    {(parseInt(this.state.selected_year) - 1)
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Jan")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Feb")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Mar")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Apr")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:May")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Jun")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Jul")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Aug")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Sep")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Oct")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Nov")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                  <th>
                                    {t("electric_meter_record:Dec")}{" "}
                                    {(parseInt(this.state.selected_year))
                                      .toString()
                                      .substr(-2)}
                                  </th>
                                </tr>}
                            </Translation>
                          </thead>
                          <tbody>
                            {this.state.sortedResidentialEdges?.map(
                              (residential, residential_index) => (
                                <tr key={residential.node.id}>
                                  <td className="bg-light">
                                    {residential.node.name}
                                  </td>
                                  {this.state.table_data_list[
                                    residential.node.id
                                  ].map((meter, index) => {
                                    let previous_index =
                                      index - 1 < 0 ? 0 : index - 1;
                                    let previous_meter = parseInt(
                                      this.state.table_data_list[
                                      residential.node.id
                                      ][previous_index]["value"]
                                    );
                                    return (
                                      <td
                                        key={"table-" + index}
                                        className="table-meter"
                                      >
                                        <input
                                          name={
                                            residential.node.id +
                                            "." +
                                            index +
                                            ".value"
                                          }
                                          value={meter["value"]}
                                          type="number"
                                          onChange={this.meterInputHandle}
                                          onKeyPress={this.handleKeyPress}
                                          onBlur={this.blurChecker}
                                          tabIndex={
                                            residential_index +
                                            this.state.meter_data.allResidential
                                              .edges.length *
                                            index
                                          }
                                          // disabled={_.get(this.state.disabled_input_list, [this.state.selected_year, index])}
                                          className={this.onCheckClassName(
                                            previous_meter,
                                            meter["value"],
                                            meter["warning_change_meter"]
                                          )}
                                        />

                                        {meter["warning"] && (
                                          <Translation>
                                            {t => <div className="warning">
                                              {t("electric_meter_record:Record number less than the previous month")}
                                            </div>}
                                          </Translation>
                                        )}
                                        {meter["warning_null_before_month"] && (
                                          <Translation>
                                            {t => <div className="warning">
                                              {t("electric_meter_record:Please fill out this field")}
                                            </div>}
                                          </Translation>
                                        )}
                                        {/*{meter["warning_change_meter"] &&*/}
                                        {/*<div className="warning">*/}
                                        {/* รายการเปลี่ยนมิเตอร์น้ำ*/}
                                        {/*</div>*/}
                                        {/*}*/}
                                        {meter["warning_gte"] && (
                                          <Translation>
                                            {t => <div className="warning">
                                              {t("electric_meter_record:Record number over 3 times of the previous month")}
                                            </div>}
                                          </Translation>
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </form>
                    </div>

                    {!this.state.loading && (
                      <Pagination
                        changePage={this.changePage}
                        first={this.state.first}
                        last={this.state.last}
                        totalCount={this.state.meter_data.allResidential.totalCount}
                      />
                    )}
                    <div style={{ height: 200 }} />
                  </React.Fragment>
                )}
                {!this.state.meter_data &&
                  <Loading />
                }
              </div>
            </div>

            <div className="float-save row">
              <Translation>
                {t =>
                  <div className="col-6">
                    <p className="p-0 m-0">{t("electric_meter_record:Last edited")} </p>
                    <p className="p-0 m-0">{t("electric_meter_record:By")} </p>
                  </div>}
              </Translation>
              <div className="col-6 text-right">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "accounting_electric_meter_edit",
                }) && (
                    <React.Fragment>
                      <Link to="/accounting">
                        <Translation>{t => <button className="btn btn-secondary mr-1">{t("electric_meter_record:Cancel")}</button>}</Translation>
                      </Link>
                      <Translation>
                        {t =>
                          <button
                            className="btn btn-primary"
                            onClick={this.save}
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <span
                                className="spinner-border spinner-border-sm mr-1 mb-1"
                                role="status"
                              >
                                <Loading />
                              </span>
                            )}
                            {t("electric_meter_record:Electric meter record")}
                          </button>}
                      </Translation>
                    </React.Fragment>
                  )}
              </div>
            </div>
          </React.Fragment>
        }
      </Translation>
    );
  }
}

export default ElectricMeterListRecord;
