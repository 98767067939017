import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import '../style/claim.scss'
import APIInsur from '../../../api/insurrance'
import InsuranceNavigation from './insuranceNav';
import InsurranceViewInfo from './ViewDetail/viewInfo';
import InsurranceViewPolicy from './ViewDetail/viewPolicy';
import InsurranceViewCoInsur from './ViewDetail/viewCoInsurrance';
import InsurranceViewBroker from './ViewDetail/viewBroker';


class DetailInsur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            image_upload: '',
            image: '',
            name_title: '',
            name_staff: '',
            last_name_staff: '',
            role: '',
            department: '',
            date_work: new Date(),
            document: '',
            document_upload: '',
            reQuery: false,
            loading: true,

            data: {},
            file_insurance: [],
            co_insurance: []
        };
        this.functionCurrentMoneyFormat = this.functionCurrentMoneyFormat.bind(this)
    }

    componentWillMount() {
        this.getCompanyAll()
        if (this.props.match.params.id) {
            APIInsur.getDataInsuranceById(this.props.match.params.id).then((res) => {
                if (res.data) {
                    this.setState({
                        data: res.data?.insurance.insurance_detail,
                        file_insurance: res.data?.insurance.file_insurance,
                        co_insurance: res.data?.insurance.co_insurance,
                        loading: false
                    })
                } else {
                    Swal.fire("ผิดพลาด", "ส่งข้อมูลผิดพลาด", 'warning')
                }
            }).catch((err) => {
                Swal.fire("ผิดพลาด", "ไม่สามารถเชื่อมต่อหลังบ้านได้", 'error')
            })
        }
    }

    getCompanyAll() {
        APIInsur.getCompanyAll().then((res) => {
            if (res.data) {
                let data = res.data.company_list;
                this.setState({ dataInsuranceCompany: data.map((n) => ({ ...n, value: n.id, label: n.name, insurance_ratio: 0 })) })
            }
        })
    }

    functionCurrentMoneyFormat(money) {
        let formatMoney = 0
        if (money) {
            formatMoney = parseFloat(money).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return formatMoney ? formatMoney : "-"
    }

    render() {

        let { data, file_insurance,co_insurance, loading } = this.state

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            loading == false ?
                                <div className="container-fluid box claim" id="staff-create">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/purchasing/insurrance">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t('claim:Insurance')}</span>
                                            </h3>
                                        </div>
                                    </div>


                                    <div className="content-inner mt-5 create">
                                        <div className="row mb-12 mr-5 fade-up">
                                            <div className="col-12">
                                                {/* main_insurance_company_id */}
                                                <div className='col-12 mt-5 mb-1'>
                                                    <div style={{ display: 'inline-flex' }}>
                                                        {
                                                            this.state.data?.main_insurance_company ?
                                                                <img src={this.state.data?.main_insurance_company?.logo_link}
                                                                    style={{ width: 124, height: 124, borderRadius: "50%", marginTop: 12 }}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src = process.env.PUBLIC_URL + '/images/iconAction/claim/logoCompany.png';
                                                                    }} />
                                                                :
                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/claim/logoCompany.png'}
                                                                    style={{ width: 124, height: 124 }} />
                                                        }

                                                        <label className='headerLabel28 mt-7 ml-5'>
                                                            บริษัท {this.state.data?.main_insurance_company?.name}</label>
                                                    </div>
                                                </div>
                                                <InsuranceNavigation paramsId={this.props.match.params.id} status={this.props.match.params.status} />
                                                
                                                {
                                                    this.props.match.params.status === 'Info' ?
                                                    <InsurranceViewInfo data={data} file_insurance={file_insurance} />
                                                    : 
                                                    this.props.match.params.status === 'Policy' ?
                                                    <InsurranceViewPolicy data={data} />
                                                    :
                                                    this.props.match.params.status === 'Insurance' ?
                                                    <InsurranceViewCoInsur data={data} co_insurance={co_insurance} /> 
                                                    : 
                                                    <InsurranceViewBroker  data={data}  />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container-fluid box fixRequestFast text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default DetailInsur;
