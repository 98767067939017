import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import '../styles/register.scss'

import Navigation from "./navigation";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { commitMutation, QueryRenderer } from "react-relay";
import Swal from "sweetalert2";
import _ from "lodash";
import { Translation } from 'react-i18next';
import i18n from 'i18next'
import { format } from 'date-fns';
import Loading from "../../libs/loading";
import thLocale from "date-fns/locale/th";

const allResidential = graphql`
    query ownerDetailQuery($id: ID!) {
        currentTenants{
            totalCount
            edges{
                node{
                    id
                    firstName
                    lastName
                    householder {
                        edges {
                            node {
                                residential {
                                    name
                                }
                            }
                        }
                    }
                    boadPersonal{
                    edges{
                        node{
                            id
                            position
                            status
                        } 
                    } 
                    }
                }
            }
        }
        residential(id: $id) {
            id
            name
            residentialHouseholder {
                totalCount
                edges {
                    node {
                        id
                        type
                        active
                        added
                        detailTenantPerResidential{
                            edges 
                            { 
                                node { 
                                    id
                                    dateIn
                                    remark
                                } 
                            }
                        }
                        tenant {
                            id
                            firstName
                            lastName
                            idCardNumber
                            image
                            countries
                            phone
                            email
                            nameTitle
                            channel
                            user{
                                id
                                username
                            }

                            boadPersonal(status:"current"){
                                edges{
                                    node{
                                        id
                                        position 
                                        status
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation ownerDetailMutation($input: DeleteHouseHolderInput!) {
        deleteHouseHolder(input: $input) {
            ok
        }
    }
`;

class OwnerDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            reQuery: false
        };
        this.onDeleteItem = this.onDeleteItem.bind(this); 
    }

    onChkUserBeforeDelete(id , user){
        if(user !== null){
            Swal.fire({
                type: 'warning',
                title: i18n.t("residentialOwnerDetailModal:Are you sure to remove this resident?"),
                text: i18n.t("residentialOwnerDetailModal:Because this user has already registered in Silverman application for residents. The resident cannot access the application if this information has been deleted."),
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: i18n.t("Allaction:Delete"),
                cancelButtonText: i18n.t("Allaction:cancel"),
            }).then((action)=> {
                if(action.value){
                    this.onDeleteItem(id)
                }
            })
        }else{
           this.onDeleteItem(id)
        }
        
    }

    onDeleteItem(id) { 
        
        Swal.fire({  
            customClass: {
                title: 'swal2-title-custom',
                header:'swal2-header-custom',
                container: 'swal2-container-custom',
                content: 'swal2-content-custom', 
            },
            title: i18n.t("residentialOwnerDetailModal:Specify the reason for removing the resident"),
            html:  
                '<div class="row text-left mt-3">' +
                    `<div class="col-12 datePicker"> ${i18n.t("residentialOwnerDetailModal:Leaving date")} </div>  ` +
                    `<div class="col-5"><input type="date" id="endDate" class="form-control inputBoxDate"  /></div>`+
                '</div>'+
                '<div class="row  text-left">' +
                    `<div class="col-1 text-left mt-3"><input type="radio" id="expairMonthly" name="statusDelete" checked /> </div><div class="col-11 mt-3">${i18n.t("residentialOwnerDetailModal:Expired Contract - Monthly/Yearly")}</div>` +
                    `<div class="col-1 text-left mt-3"><input type="radio" id="expairDaily" name="statusDelete"  /> </div><div class="col-11 mt-3">${i18n.t("residentialOwnerDetailModal:Expiry of rental (daily)")}</div>` + 
                    '<div class="col-1 mt-3"><input type="radio" id="otherStatusIs" name="statusDelete"  /></div>' +
                    `<div class="col-2 mt-3"> ${i18n.t("residentialOwnerDetailModal:etc.")} </div>` +
                    '<div class="col-9 mt-3"><input type="text" id="otherStatusName" class="form-control" /></div>' +
                '</div>',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: i18n.t("residentialOwnerDetailModal:Remove the resident"),
            onOpen: function () {
                document.getElementById('endDate').setAttribute["required"] = ""
            },

            preConfirm: () => ({  
                endDate: document.getElementById("endDate").value || format(new Date() , "YYYY-MM-DD"),
                expairMonthly: document.getElementById("expairMonthly").checked,
                expairDaily: document.getElementById("expairDaily").checked, 
                otherStatusIs: document.getElementById("otherStatusIs").checked,
                otherStatusName: document.getElementById("otherStatusName").value,
            }),
            
        }).then((c) => {
            if(c.value ){ 
                let variables = {
                    "input": {
                        "id": id,
                        "note":  c.value.expairMonthly ? "หมดสัญญารายเดือน / ปี" : c.value.expairDaily ? "หมดอายุเช่า (รายวัน)" : c.value.otherStatusName,
                        "dateOut": c.value.endDate
                    }
                }

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.setState({ loading: false });
                            if (response.deleteHouseHolder.ok) {
                                Swal.fire(i18n.t("resident:Save successfully!"), '', 'success').then(() => {
                                    this.setState({reQuery: !this.state.reQuery})

                                });
                            } else {
                                Swal.fire(i18n.t("resident:Save unsuccessfully!"), '', 'warning');
                            }
        
                        },
                        onError: (err) => {
                            this.setState({ loading: false });
                            Swal.fire('Error!', i18n.t("resident:Please try again"), 'warning')
                        },
                    },
                );
                // CreateUpdateSTenant(data ,onSubmitSuccess , onSubmitError)
                this.props.history.push("/register/residential/detail/owner-detail/"+ this.props.match.params.id) 
            }

        }) 
    }

    getHighlightType(type) { 
    let result = ""
    switch (type) {
        case "RESIDENT":
          result = "resident"
          break;
        case "RENTER":
          result = "renter"
          break;
        case "OWNER":
          result = "owner"
          break;
        case "AGENT":
          result = "agent"
          break;
        default:
          result = ""
        break;
      }
      return result
    }

    handleClick = (event,card_detail) => {
        // ให้เลือกได้แค่บางข้อความเท่านั้น ชื่อ username เท่านั้น
        if (event.target.tagName.toLowerCase() === 'p' || event.target.tagName.toLowerCase() === 'h6') {
            return;
        }
        // Proceed with the desired action
        this.props.history.push("/register/residential/detail/preview-owner-detail/" + this.props.match.params.id + "/" + card_detail.node.id)
    };

    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <QueryRenderer
                        environment={environment}
                        query={allResidential}
                        fetchPolicy="no-cache"
                        variables={{
                            "id": this.state.id,
                            reQuery: this.state.reQuery
                        }}
                        render={({ error, props }) => {
                            if (error) {
                                return <div>{error.message}</div>;
                            } else if (props) {
                                return (
                                    <div className="container-fluid p-4" id="detail">
                                        <div className="row justify-content-between">
                                            <div className="col header-color">
                                                <h3 className="mb-4">
                                                    <Link to="/register/residential/list/all">
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back" />
                                                    </Link>
                                                    {props.residential.name}
                                                </h3>
                                                <div className="content-inner">
                                                    <Navigation id={this.props.match.params.id} />
                                                </div>
                                            </div>
                                        </div>
                                        <React.Fragment>

                                            <div className="content-inner" key={props.residential.id}>
                                                <div className="row mb-4 fade-up">
                                                    { 
                                                        props.residential.residentialHouseholder.edges.map((card_detail) =>
                                                         {
                                                            let chkCommunitee = props.currentTenants?.edges?.find((ex)=> ex?.node?.id === card_detail?.node?.tenant?.id && ex.node.boadPersonal?.edges[0]?.node.status === "current")
                                                            let statusCommunitee = chkCommunitee ? true : false
                                                            
                                                            let dateIn = ""
                                                            let remark = ""
                                                            if(card_detail.node.detailTenantPerResidential.edges.length > 0){
                                                                dateIn = card_detail.node.detailTenantPerResidential?.edges[0].node.dateIn;
                                                                remark = card_detail.node.detailTenantPerResidential?.edges[0].node.remark;
                                                            }
                                                            return (
                                                                <div className="col-md-6 col-sm-6 col-xl-4 mb-4"
                                                                key={card_detail.node.id}>
                                                                <div className="card p-2 show cursor">
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col text-left padding-hover" />
                                                                            <div
                                                                                className="col text-right show-icon">

                                                                                {_.get(props.residential, "residentialHouseholder.totalCount") > 1 && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'tenant_tenant_delete' }) &&
                                                                                    <React.Fragment>
                                                                                        <img
                                                                                            src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                            alt="delete-icon" />
                                                                                        <Translation>
                                                                                            {t => <span
                                                                                                className="grey-color ml-1 underline hover"
                                                                                                onClick={() => this.onChkUserBeforeDelete(card_detail.node.id , card_detail.node.tenant.user)}>{t("resident:Remove")}</span>}
                                                                                        </Translation>
                                                                                    </React.Fragment>
                                                                                }
                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'tenant_tenant_edit' }) &&
                                                                                    <Link
                                                                                        to={{ pathname: "/register/residential/detail/form-owner-detail/" + this.props.match.params.id + "/" + card_detail?.node?.id }}>
                                                                                        <img
                                                                                            src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                                            alt="edit-icon"
                                                                                            className="ml-4" />
                                                                                        <Translation>
                                                                                            {t => <span
                                                                                                className="grey-color ml-1">{t("resident:Edit")}</span>}
                                                                                        </Translation>
                                                                                    </Link>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="row"  onClick={(e)=> this.handleClick(e,card_detail)}
                                                                            key={card_detail.node.id}>
                                                                            <div className="col-md-4">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col text-center">

                                                                                        {card_detail.node.tenant.image ?
                                                                                            <div
                                                                                                className="rounded-circle avatar-img profile-img img-border"
                                                                                                style={{ backgroundImage: `url(${card_detail.node.tenant.image})` }}>
                                                                                            </div>
                                                                                            :
                                                                                            <div
                                                                                                className="rounded-circle avatar-img profile-img"
                                                                                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'})` }}>
                                                                                            </div>
                                                                                        }


                                                                                        {
                                                                                            card_detail.node.active ?
                                                                                                <img
                                                                                                    src={process.env.PUBLIC_URL + '/images/icons/online-icon.png'}
                                                                                                    alt="online-icon"
                                                                                                    className="status" />
                                                                                                :
                                                                                                <img
                                                                                                    src={process.env.PUBLIC_URL + '/images/icons/offline-icon.png'}
                                                                                                    alt="offline-icon"
                                                                                                    className="status" />
                                                                                        }

                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-3">
                                                                                    <div
                                                                                        className="col text-center">
                                                                                        {/* <button
                                                                                                                className="btn btn-outline-secondary add">
                                                                                                                <img
                                                                                                                    src={process.env.PUBLIC_URL + '/images/icons/chat-icon.png'}
                                                                                                                    alt="chat-icon"/>
                                                                                                                ส่งข้อความ
                                                                                                            </button> */}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-2">
                                                                                    <div
                                                                                        className="col text-center">
                                                                                        {/*<p className="grey-color">*/}
                                                                                        {/*    เข้าใช้งานล่าสุดเมื่อ <br/> 23*/}
                                                                                        {/*    นาทีก่อน*/}
                                                                                        {/*    (ยังไม่ได้ใส่)*/}
                                                                                        {/*</p>*/}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-8">
                                                                                <div className="row mt-3">
                                                                                    <div className="col">
                                                                                        {
                                                                                            card_detail.node.type === "RESIDENT" ?
                                                                                                <React.Fragment>
                                                                                                    <Translation>{t => 
                                                                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                                    <h6>{textData?.data?.ownerDetail.resident}</h6>
                                                                                                       :                                                                                                    
                                                                                                    <h6 className="highlight resident">{t("resident:Resident")}</h6>}</Translation>
                                                                                                </React.Fragment>
                                                                                                :
                                                                                                card_detail.node.type === "OWNER" ?
                                                                                                 <Translation>{t => 
                                                                                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                                 <h6>{textData?.data?.ownerDetail.owner}</h6>
                                                                                                    :
                                                                                                 <h6 className="highlight owner">{t("resident:Owner")}</h6>
                                                                                                 }</Translation>
                                                                                                :
                                                                                                card_detail.node.type === "RENTER" ? 
                                                                                                 <Translation>{t => <h6 className="highlight renter">{t("resident:Tenant")}</h6>}</Translation>   
                                                                                                : <Translation>{t =>  <h6 className="highlight agent">{t("resident:Agent")}</h6>}</Translation>  
                                                                                                
                                                                                        }

                                                                                        {
                                                                                            // card_detail.node.tenant.boadPersonal?.edges[0]?.node.status === "current" &&
                                                                                            // <Translation>{t => <button style={{ backgroundColor: '#40A3FF', padding: 8, borderRadius: 20, borderWidth: 0, color: '#fff' }}
                                                                                            (statusCommunitee) &&
                                                                                            // card_detail.node.tenant.boadPersonal?.edges[0]?.node.status === "current" &&
                                                                                            <Translation>{t => <button style={{ backgroundColor: '#40A3FF', padding: 8, borderRadius: 20, borderWidth: 0, color: '#fff', width: 90 }}
                                                                                            >{t("resident:Commuttees")}</button>}</Translation>
                                                                                        }

                                                                                        {
                                                                                            card_detail.node.tenant.channel == 'facebook' ?
                                                                                                <img
                                                                                                    src={process.env.PUBLIC_URL + '/images/icons/facebook.png'}
                                                                                                    alt="facebook-icon"
                                                                                                    width="30" height="30" style={{ marginLeft: '10px' }} />
                                                                                                :
                                                                                                card_detail.node.tenant.channel == 'google' ?
                                                                                                    <img
                                                                                                        src={process.env.PUBLIC_URL + '/images/icons/google.png'}
                                                                                                        alt="google-icon"
                                                                                                        width="30" height="30" style={{ marginLeft: '10px' }} />
                                                                                                    :
                                                                                                    card_detail.node.tenant.channel == 'apple' ?
                                                                                                        <img
                                                                                                            src={process.env.PUBLIC_URL + '/images/icons/apple.png'}
                                                                                                            alt="apple-icon"
                                                                                                            width="30" height="30" style={{ marginLeft: '10px' }} />
                                                                                                        :
                                                                                                        <div></div>
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-3">
                                                                                    <div className="col">
                                                                                        <h6>
                                                                                            {card_detail.node.tenant.nameTitle || 'คุณ'}{card_detail.node.tenant.firstName} {card_detail.node.tenant.lastName}
                                                                                        </h6>
                                                                                        {
                                                                                            card_detail.node.tenant.user && (card_detail.node.tenant.channel == 'facebook' || card_detail.node.tenant.channel == 'google' || card_detail.node.tenant.channel == 'apple') ?
                                                                                                <p className="grey-color"> Username :</p> :
                                                                                                card_detail.node.tenant.user && (card_detail.node.tenant.channel != 'facebook' && card_detail.node.tenant.channel != 'google'  && card_detail.node.tenant.channel != 'apple') ?
                                                                                                <p className="grey-color"> Username : {card_detail.node.tenant.user.username}</p> : <p className="grey-color">Username : -</p>
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <p className='mb-0'>
                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/fluent-flag-residential.png'}
                                                                                                alt="flag-icon"
                                                                                                />
                                                                                            <span
                                                                                                className="ml-2">{card_detail.node.tenant.countries ? card_detail.node.tenant.countries : "-"}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-2">
                                                                                    <div className="col">
                                                                                        <p className='mb-0'>
                                                                                            <img
                                                                                                    src={process.env.PUBLIC_URL + '/images/icons/id-card-residential.png'}
                                                                                                    alt="id-card-icon"
                                                                                                    />
                                                                                                <span
                                                                                                    className="ml-2">{card_detail.node.tenant.idCardNumber ? card_detail.node.tenant.idCardNumber : "-"}</span>
                                                                                        </p>
                                                                                        
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-2">
                                                                                    <div className="col">
                                                                                        <p className='mb-0'>
                                                                                            <img
                                                                                                    src={process.env.PUBLIC_URL + '/images/icons/vector-phone.png'}
                                                                                                    alt="phone-icon" />
                                                                                                <span className="ml-4">{card_detail.node.tenant.phone ? card_detail.node.tenant.phone : " -"}</span>
                                                                                        </p>
                                                                                        
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-2">
                                                                                    <div className="col">
                                                                                        <p className='mb-0'>
                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/icon-mail-residential.png'}
                                                                                                alt="mail-icon"  />
                                                                                            <span
                                                                                                className="ml-2">{card_detail.node.tenant.email ? card_detail.node.tenant.email : "-"}</span>
                                                                                        </p>
                                                                                       
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-2">
                                                                                    <div className="col">
                                                                                        <p className='mb-0'>
                                                                                        <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/calendar-check-icon.png'}
                                                                                                alt="date-icon" 
                                                                                                />
                                                                                            <Translation>{t =>
                                                                                                <span className="ml-2">{t("resident:Stay date")} : 
                                                                                                    {
                                                                                                        dateIn ? card_detail.node.type !== 'AGENT' ?  format(dateIn,"DD/MM/YYYY ",{ locale: thLocale }) : ' -' : ' -'
                                                                                                    } 
                                                                                                </span>
                                                                                            }</Translation>
                                                                                        </p>
                                                                                       
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mt-2">
                                                                                    <div className="col">
                                                                                    <p className='mb-0'>
                                                                                        <Translation>{t =>
                                                                                        <div
                                                                                            className="text-red-ownder-detail remarkshortline">{t("resident:Note")} : {remark ? remark : ''}</div>
                                                                                        }</Translation>
                                                                                    </p>
                                                                                    
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )})
                                                    }

                                                    <div className="col-md-6 col-sm-6 col-xl-4 mb-4">
                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'tenant_tenant_create' }) &&
                                                            <Link
                                                                to={{ pathname: "/register/residential/detail/form-owner-detail/" + this.props.match.params.id }}>
                                                                <div className="card card-add">
                                                                    <div
                                                                        className="card-body d-flex align-middle text-center">
                                                                        <div className="col">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/plus-icon.png'}
                                                                                alt="plus-icon" />
                                                                            <Translation>{t => <h5 className="mt-3">{t("resident:Add residents")}</h5>}</Translation>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                        </React.Fragment>

                                    </div>
                                );
                            }
                            return <Loading/>
                        }}
                    />
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default OwnerDetail;
