import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { graphql } from 'babel-plugin-relay/macro';
import { fetchQuery } from 'react-relay';
import environment from '../env/environment';
import Header from './headerLogin';
import Footer from './footer';
import Loading from '../libs/loading';
import api from '../contact/services';
import Swal from 'sweetalert2';
import UpdatePDPA from './fetchQueryPolicy/updatePDPALogin';
import getApiRoot from '../libs/getAPIRoot';
import GetSelfProject from './fetchQueryPolicy/getSelfProject';
const query = graphql`
  query indexLoginHookQuery($username: String, $password: String, $role: String) {
    auth(username: $username, password: $password, role: $role) {
        token
        permission
        result
        haveVat
        url
        projectName
        tenant
        notification
        acceptPolicy
        acceptTermOfUse
        acceptMarketing
        acceptPartner
        message
        messageTh
        projectSelection
    }
    selfProject {
      id
      name
      logo
      address
      useAccount
      typeOfProject
    }
    checkOnline
    checkSpecialSite
    allExtraMenu
  }
`;

let logotest = {
    address: '',
    id: '',
    logo: '',
    name: '',
    typeOfProject: '',
};

const LoginNew = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);
    const [detail, setDetail] = useState({});
    const [borderColor, setBorderColor] = useState(false);
    const [forgetPass, setForgetPass] = useState(false);
    const [checkPolicyAndTerm, setCheckPolicyAndTerm] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('language') === 'en' ? 'en' : 'th');
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [messageTh, setMessageTh] = useState('');
    const [name, setName] = useState("")
    const [arrayTextAddress, setarrayTextAddress] = useState("")
    const [arrayTextAddress2, setarrayTextAddress2] = useState("")

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                if (props?.location?.state) {
                    setCheckPolicyAndTerm(props.location.state.status || localStorage.getItem('checkStatusPolicyAndTerms'));
                } else {
                    setCheckPolicyAndTerm(localStorage.getItem('checkStatusPolicyAndTerms') || false);
                }
                localStorage.removeItem('token');
                localStorage.removeItem('site_id');
                localStorage.removeItem('execute');

                const data = await GetSelfProject();
                if (data?.selfProject) {
                    logotest = {
                        address: data.selfProject.address,
                        id: data.selfProject.id,
                        logo: data.selfProject.logo,
                        name: data.selfProject.name,
                        typeOfProject: data.selfProject.typeOfProject,
                    };
                    localStorage.setItem('useAccounts', data.selfProject.useAccount || false);
                    setDetail(logotest);
                    setLoading(false);
                    setName(logotest?.name?.split('นิติบุคคลอาคารชุด' || 'นิติบุคคลหมู่บ้านจัดสรร' || 'นิติบุคคลหมู่บ้าน'))
                    setarrayTextAddress(logotest?.address?.split('เขต'))
                    setarrayTextAddress2(logotest?.address?.split('เขต'))
                } else {
                    setLoading(false);
                    Swal.fire("Can't connect api at this time. Please try again", "", 'error');
                }
            } catch (err) {
                setLoading(false);
                Swal.fire("Can't connect api at this time. Please try again", "", 'error');
            }
        };

        fetchProjectData();
    }, [props?.location?.state]);

    const forgetPassword = () => {
        setForgetPass(true);
    };

    const back = () => {
        setForgetPass(false);
    };

    const changeLanguage = (langBoon, lang) => {
        setLanguage(lang);
    };

    const handleInputChange = (e) => {
        setBorderColor(false);
        if (e.target.name === 'username') {
            setUsername(e.target.value);
        } else if (e.target.name === 'password') {
            setPassword(e.target.value);
        }
    };

    const login = (e) => {
        e.preventDefault();

        fetchQuery(environment, query, {
            username: username,
            password: password,
            role: 'admin',
        }).then(async (data) => {
            if (data.auth.result) {
                let res = await api.getSiteId();
                localStorage.setItem('allExtraMenu', data.allExtraMenu);
                localStorage.setItem('site_id', res.site_id);
                localStorage.setItem('checkSpecialSite', data.checkSpecialSite);
                if (data.checkSpecialSite) {
                    localStorage.setItem('execute', 'dmljdG9yeW1hbmFnZW1lbnQ='); // victorymanagement
                } else {
                    localStorage.setItem('execute', 'c2lsdmVybWFu'); // silverman
                }

                logotest = data;
                localStorage.setItem('token', data.auth.token);
                localStorage.setItem('selPr', data.auth.projectSelection);
                localStorage.setItem('checkStatusPolicyAndTerms', checkPolicyAndTerm);
                await localStorage.setItem('permission_list', data.auth.permission);
                await localStorage.setItem('project_have_vat', data.auth.haveVat);

                let checkStatusPolicyAndTerms = localStorage.getItem('checkStatusPolicyAndTerms');
                let checkStatusAcceptForMarketing = localStorage.getItem('checkStatusAcceptForMarketing');

                if (data.auth.acceptTermOfUse === true && checkStatusAcceptForMarketing === null) {
                    // setRedirectTo('/home');
                    if (data?.auth?.projectSelection) {
                        setRedirectTo('/select-project')
                    } else {
                        setRedirectTo('/home');
                    }


                } else if (checkStatusAcceptForMarketing !== null && checkStatusPolicyAndTerms === 'true') {
                    let variables = {
                        input: {
                            acceptPolicy: localStorage.getItem('checkStatusAcceptForPolicy') === 'true' ? true : false,
                            acceptTermOfUse: localStorage.getItem('checkStatusPolicyAndTerms') === 'true' ? true : false,
                            acceptMarketing: localStorage.getItem('checkStatusAcceptForMarketing') === 'true' ? true : false,
                            acceptPartner: localStorage.getItem('checkStatusAcceptForPartner') === 'true' ? true : false,
                        },
                    };
                    UpdatePDPA(variables);
                    localStorage.removeItem('checkStatusAcceptForPolicy');
                    localStorage.removeItem('checkStatusAcceptForMarketing');
                    localStorage.removeItem('checkStatusAcceptForPartner');
                    // setRedirectTo('/home');
                    if (data?.auth?.projectSelection) {
                        setRedirectTo('/select-project')
                    } else {
                        setRedirectTo('/home');
                    }
                } else {
                    setRedirectTo('/term-of-user');
                }
                getTextForExtraMenu();
            } else {
                if (!data.checkOnline && username !== '' && password !== '') {
                    Swal.fire({
                        html:
                            '<img src="/images/icons/popup-danger.svg" style="margin: 20px 0px; width: 120px;" />' +
                            '<p style="color: #E80627; font-family: Kanit; font-weight: 400; font-size: 28px; line-height: 24px;">ระบบของคุณถูกระงับการใช้งานชั่วคราว</p>' +
                            '<p style="color: #E80627; font-family: Kanit; font-weight: 400; font-size: 28px; line-height: 24px;">กรุณาชำระค่าบริการ เพื่อเปิดใช้งานต่อ</p>' +
                            '<p style="color: #555555; font-family: Kanit; font-weight: 400; font-size: 18px; line-height: 12px; margin-top: 24px;">สอบถามข้อมูลเพิ่มเติมได้ที่</p>' +
                            '<p style="color: #555555; font-family: Kanit; font-weight: 400; font-size: 18px; line-height: 12px;">08-1442-6888</p>' +
                            '<p style="color: #555555; font-family: Kanit; font-weight: 400; font-size: 18px; line-height: 12px;">06-5579-6844</p>' +
                            '<p style="color: #555555; font-family: Kanit; font-weight: 400; font-size: 18px; line-height: 12px;">หรือ @silvermansupport</p>',
                        showConfirmButton: true,
                        showCloseButton: false,
                        confirmButtonText: language === 'th' ? 'ตกลง' : 'Yes',
                        confirmButtonColor: '#1567B4',
                        width: 760,
                    });
                } else {
                    setBorderColor(true);
                    setMessage(data.auth.message);
                    setMessageTh(data.auth.messageTh);
                }
            }
        }).catch((err) => {
            Swal.fire("Can't login at this time. Please try agian", "", 'error').then((es) => {
                return
            })
        });
    };

    const getTextForExtraMenu = () => {
        localStorage.removeItem('dataTextExtraMenu');
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const fetchUrl = `${getApiRoot()}icon/project_menu`;

         fetch(fetchUrl, requestOptions)
            .then(response => response.text())
            .then(result => {
                const parsedResult = JSON.parse(result);
                localStorage.setItem('dataTextExtraMenu', JSON.stringify(parsedResult))
            })
            .catch(error => console.log('error', error));
    }

    if (redirectTo) {
        return <Redirect to={{ pathname: redirectTo }} />;
    }


    return (
        <div className="height-100">
            <Header lang={changeLanguage} />
            <div className="container-fluid box">
                <div className="row justify-content-center">
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className="col-md-3" id="login-style">
                            <div className="welcome-box">
                                <h3>
                                    {language === 'th' ? 'ยินดีต้อนรับเข้าสู่' : 'Welcome'}
                                    <br />
                                    {detail.typeOfProject === 'BUILDING' || detail.typeOfProject === 'CONDOMINIUM'
                                        ? 'นิติบุคคลอาคารชุด'
                                        : 'นิติบุคคลหมู่บ้านจัดสรร'}
                                    <br />
                                    {name && name[name.length - 1]}
                                </h3>
                                <h6>{arrayTextAddress && arrayTextAddress[0]}</h6>
                                {arrayTextAddress2[1] && <h6>เขต{arrayTextAddress2 && arrayTextAddress2[1]}</h6>}

                                <div className="justify-content-center mt-3">
                                    {forgetPass === false ? (
                                        detail.logo !== '' ? (
                                            <img
                                                src={detail.logo}
                                                style={{ width: '180px', height: '180px', justifyContent: 'center' }}
                                                alt="logoProject"
                                            />
                                        ) : (
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/logo/silverman.jpeg'}
                                                style={{ width: '180px', height: '180px', justifyContent: 'center' }}
                                                alt="logoProject"
                                            />
                                        )
                                    ) : null}
                                </div>
                            </div>
                            {forgetPass === false ? (
                                <div>
                                    <form onSubmit={login}>
                                        <div className="form-group mt-3">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/icons/accout-icon.png'}
                                                alt="account-icon"
                                                style={{ width: '15px', height: '17px', position: 'absolute', marginTop: '9px', marginLeft: '8px' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control pl-4"
                                                id="userName"
                                                autoComplete="username"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter username"
                                                required
                                                name="username"
                                                value={username}
                                                onChange={handleInputChange}
                                                style={{ borderColor: borderColor ? 'red' : null, textIndent: '10px' }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/icons/lock.png'}
                                                alt="account-icon"
                                                style={{ width: '15px', height: '17px', position: 'absolute', marginTop: '9px', marginLeft: '8px' }}
                                            />
                                            <input
                                                type="password"
                                                className="form-control pl-4"
                                                id="password"
                                                required
                                                placeholder="Password"
                                                autoComplete="current-password"
                                                name="password"
                                                value={password}
                                                onChange={handleInputChange}
                                                style={{ borderColor: borderColor ? 'red' : null, textIndent: '10px' }}
                                            />

                                            {borderColor ? (
                                                <h6 style={{ color: 'red' }}>
                                                    <small>
                                                        {' '}
                                                        {language === 'th' ? messageTh : message}
                                                        &nbsp;
                                                        {message === 'expiration' ? <a href="/reset-password">Reset password</a> : null}
                                                    </small>
                                                </h6>
                                            ) : null}
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <Link
                                                to={{
                                                    pathname: '/term-of-user',
                                                    state: { status: true },
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="checkPolicy"
                                                    style={{ cursor: 'pointer' }}
                                                    defaultChecked={checkPolicyAndTerm}
                                                />
                                                <label className="custom-control-label">
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <h6 style={{ color: '#818181', marginTop: '2px' }}>
                                                            {language === 'th' ? <small>ยินยอมตาม<u>ข้อกำหนด</u> และ<u>เงื่อนไข</u></small> : <small>Accept <u>the above terms</u> and <u>conditions</u></small>}
                                                        </h6>
                                                    </div>
                                                </label>
                                            </Link>
                                        </div>
                                        <button type="submit" className="btn btn-primary col mt-2" disabled={checkPolicyAndTerm ? false : true}>
                                            LOG IN
                                        </button>
                                    </form>
                                </div>
                            ) : (
                                <div className="forget-text-box">
                                    {language === 'th' ? (
                                        <div className="forget-password">
                                            <h4 className="text-forget-pass">กรุณาติดต่อผู้จัดการนิติบุคคล</h4>
                                            <h4 className="text-forget-pass">หรือผู้มีสิทธิ์สูงสุด</h4>
                                            <h4 className="text-forget-pass">เพื่อเปลี่ยนรหัส</h4>
                                        </div>
                                    ) : (
                                        <div className="forget-password">
                                            <h4 className="text-forget-pass">Please contact the Juristic Person Manager.</h4>
                                            <h4 className="text-forget-pass">or the highest authority</h4>
                                            <h4 className="text-forget-pass">to change the code</h4>
                                        </div>
                                    )}
                                </div>
                            )}
                            {forgetPass === false ? (
                                <div className="welcome-box mt-4 mb-5">
                                    <h6>
                                        <small onClick={forgetPassword} style={{ color: '#818181', marginTop: '15px', cursor: 'pointer' }}>
                                            {language === 'th' ? 'ลืมรหัสผ่าน' : 'Forget Password'}
                                        </small>
                                    </h6>
                                </div>
                            ) : (
                                <div className="welcome-box mt-4 mb-5">
                                    <h6>
                                        <small onClick={back} style={{ color: '#818181', marginTop: '15px', cursor: 'pointer' }}>
                                            {language === 'th' ? 'กลับสู่หน้าล็อคอิน' : 'Back to login'}
                                        </small>
                                    </h6>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="row justify-content-center mt-10">
                    <div className="col-md-10">
                        <Footer langauge={language} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginNew;
