/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialConveyance = "ALREADY_TRANSFERRED" | "NOT_TRANSFERRED" | "%future added value";
export type contractServiceCreateQueryVariables = {||};
export type contractServiceCreateQueryResponse = {|
  +contactViewer: ?{|
    +id: string,
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
          +residential: ?{|
            +id: string,
            +conveyance: ResidentialConveyance,
          |},
        |}
      |}>
    |},
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
  +contractRevenueDocNumber: ?string,
|};
export type contractServiceCreateQuery = {|
  variables: contractServiceCreateQueryVariables,
  response: contractServiceCreateQueryResponse,
|};
*/


/*
query contractServiceCreateQuery {
  contactViewer {
    id
    allContact(typeOfContact: "supplier", typeOfPayment: "receivable", isActive: true) {
      edges {
        node {
          id
          refNumber
          name
          firstName
          lastName
          residential {
            id
            conveyance
          }
        }
      }
    }
  }
  viewer {
    allChartOfAccount(chartOfAccountCode_Istartswith: "4200") {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
  contractRevenueDocNumber
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactViewer",
  "kind": "LinkedField",
  "name": "contactViewer",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "isActive",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "typeOfContact",
          "value": "supplier"
        },
        {
          "kind": "Literal",
          "name": "typeOfPayment",
          "value": "receivable"
        }
      ],
      "concreteType": "ContactNodeConnection",
      "kind": "LinkedField",
      "name": "allContact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContactNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "refNumber",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ResidentialNode",
                  "kind": "LinkedField",
                  "name": "residential",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "conveyance",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "allContact(isActive:true,typeOfContact:\"supplier\",typeOfPayment:\"receivable\")"
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "4200"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chartOfAccountCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"4200\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractRevenueDocNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "contractServiceCreateQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "contractServiceCreateQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "2a1cf4d93db1bd162e1206f6cbd6c121",
    "id": null,
    "metadata": {},
    "name": "contractServiceCreateQuery",
    "operationKind": "query",
    "text": "query contractServiceCreateQuery {\n  contactViewer {\n    id\n    allContact(typeOfContact: \"supplier\", typeOfPayment: \"receivable\", isActive: true) {\n      edges {\n        node {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n          residential {\n            id\n            conveyance\n          }\n        }\n      }\n    }\n  }\n  viewer {\n    allChartOfAccount(chartOfAccountCode_Istartswith: \"4200\") {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n  contractRevenueDocNumber\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6e47d03623aa7fa3bcd0845fa0ed17d';

module.exports = node;
