import React , {Component} from 'react'
import _ from 'lodash'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import Select from 'react-select';



const query = graphql`
  query modalSelectNumberQuery($productAndServiceId: String,$noNumber: String){
    allKeycard(productAndServiceId: $productAndServiceId, noNumber: $noNumber){
        edges{
            node{
                id
                noNumber
                keycardPerInvoice{
                    totalCount
                }
                keycardPerOtherReceiveTransaction{
                    totalCount
                }
            }
        }
    }      
  }
`

const customStyles = {
    control: (style, { isDisabled }) => ({
      ...style,
      height: 48,
      minHeight: 48,
    //   backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF',
    }),
    indicatorSeparator: (style) => ({
      ...style,
    //   display: 'none'
    }),
  };
  
  export default class ModalSelectNumber extends Component {

    constructor(props){
      super(props)
      this.state = {
        listNumber : [],
        loading : false,
        selected : [],
        listItem : this.props.list
      }
    }
  
    componentWillMount = () => {
      this.fetchData()
    }
    componentDidUpdate(prevProps){
        if(prevProps.list !== this.props.list){
            this.setState({
                listItem : this.props.list
            })
        }
    }
  
    handleChange = (event) => {
        let e = {
            target : {
                name : `requisitionNote.requisitionList[${this.props.index}].noNumber`,
                value : event.value
            },
            target1 : {
                name : `requisitionNote.requisitionList[${this.props.index}].numberOfPieces`,
                value : 1
            }
        }
        this.props.handleChange(e)
        this.fetchData()
      
    }

    fetchData = async() => {
        this.setState({
            loading : true
        })
        let list = []
        _.forEach((this.state.listItem), item => {
            if(item.noNumber !== ''){
                list.push(item.noNumber)
            }
        }
        )
        this.setState({
            selected : list
        })
        await fetchQuery(environment, query,{productAndServiceId : this.props.item.productAndServiceId,noNumber: ''}).then(res => {
            if(res.allKeycard.edges.length > 0){
                let itemList = []
                for (const item of res.allKeycard.edges) {
                    if(item.node.keycardPerInvoice.totalCount  === 0 && item.node.keycardPerOtherReceiveTransaction.totalCount === 0){
                        if(!this.state.selected.includes(item.node.noNumber)){
                            itemList.push({
                                value: item.node.noNumber,
                                label: item.node.noNumber,
                            })
                        }else{
                            itemList.push({
                                value: item.node.noNumber,
                                label: item.node.noNumber,
                                isDisabled: true  ,
                            })
                        }
                    }
                }
                this.setState({
                    listNumber : itemList
                }, () => {
                    this.setState({
                        loading : true
                    })
                })
            }
        })
    }
  
    render() {
      return (
        <React.Fragment>
            <Select 
                name="noNumber"
                value={this.props.item.noNumber&&[{label:this.props.item.noNumber,value:this.props.item.noNumber}]||''}
                placeholder="ค้นหา"
                className='text-left'
                onChange={this.handleChange}
                options={this.state.listNumber}
                styles={customStyles}
                onMenuOpen={() => this.fetchData()}
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            />
        </React.Fragment>
      )
    }
  }