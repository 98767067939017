import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "react-relay";
import WaterAndElectricUtilityReceiveReportTable from "./waterAndElectricUtilityReceiveReportTable";
import WaterElectricProductServiceList from "./waterElectricProductServiceList";
import { format } from "date-fns";

import ContactReportList from "../contactReportList";
import Loading from '../../../../libs/loading';

import Swal from "sweetalert2";
import WaterAndElectricUtilityReceiveReportExports from "./waterAndElectricUtilityReceiveReportExportData";

const query = graphql`
query waterAndElectricUtilityReceiveReportQuery
  {
      productViewer{
            allProduct (type_In: "product, service, fine",servicePricing_PricingType_In:"water_meter, electric_meter"){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        totalLeftInStock
                    }
                }
            }
        }
}`

class WaterElectricUtilityReceiveReport extends ComponentPagination {
  constructor(props) {
    super(props); 
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);


    this.state = {
      dataProductFilter: "",
      productAndServiceInit : "",
      productAndService : "",
      loading: false,

      current_date: new Date(),
      startDate: month_before,
      temp_start_date: month_before,
      endDate: new Date(),
      temp_end_date: new Date(),

      temp_product_and_service: "",
      temp_customer_type : "",
      search: "",

      temp_at_date: "",
      temp_month_year: "",
      temp_month: "",
      temp_year: "",
      temp_min_at_date: "",
      temp_max_at_date: "",

      selectedMonth: "",
      selectedYear: "",
      selectedAtDate: "",

      loadingBtn: false, 
      temp_contact: "",
      contact: "",
      firstInit: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleLoadingBtn = this.handleLoadingBtn.bind(this);
    this.onClickGetData = this.onClickGetData.bind(this);
    this.handleFirstInit = this.handleFirstInit.bind(this);
  }

  componentWillMount = () => {
    this.queryDataFilterFirstInit()
  }

  queryDataFilterFirstInit = () => {
    this.setState({ loading: true})
    fetchQuery(environment, query,{
    }).then(data => {
      this.setState({ 
        data: data,
        productAndServiceInit: data.productViewer.allProduct.edges[0].node.id ,
        loading: false,
        // firstInit: false
       })
    })
    .catch(error => {
      Swal.fire("Error!", "", "error"); 
    })

  }

  handleSearch(event) {
    this.setState({
      selectedMonth: this.state.temp_month + 1,
      selectedYear: this.state.temp_year,
      selectedAtDate: format(this.state.temp_at_date, "YYYY-MM-DD"),
      search: event.target.value,
      productAndService: this.state.temp_product_and_service? this.state.temp_product_and_service : this.state.productAndServiceInit,
      contact: this.state.temp_contact,
    })    
  }

  handleChange(e) {
    if(e.target.name === 'temp_month_year'){
      let dateMax = this.getMaxDate(e.target.value)      
      this.setState({
        [e.target.name]: e.target.value,
        temp_month: new Date(e.target.value).getMonth(),
        temp_year: new Date(e.target.value).getFullYear(),
        temp_max_at_date: dateMax,
      },()=>{
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  }
  
  getMaxDate(dateSelected){
    let maxDate = ""
    const dateMin = new Date(dateSelected);
    maxDate = new Date(dateMin.setMonth(dateMin.getMonth() + 1, 0));

    return maxDate
  }

  handleLoadingBtn(loading){
    this.setState({
      loadingBtn : loading
    })
  }

  onClickGetData() {
    this.props.history.push(`/accounting/report/account-receivable/waterAndElectricUtilityReceiveReport/PDF`,{ 
      state: {
        selectedMonth: this.state.selectedMonth,
        selectedYear: this.state.selectedYear,
        selectedAtDate: this.state.selectedAtDate,
        search: this.state.search,
        productAndServiceInit: this.state.productAndServiceInit,
        productAndServiceFilter: this.state.productAndService,
        contact: this.state.temp_contact,
      }    
    })
  }

  handleFirstInit(init) {
    this.setState({
      firstInit: init,
    })
  }
 
  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting/report/account-receivable">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {
                    (t) => <span>{t('utilityReceivable:electricityAndWaterReceivableReport')}</span>
                    }
                  </Translation>
                </h3>
              </div>              
              {
                !this.state.firstInit &&            
              <div className="col fixRequestFast">
                <div className={`${this.state.checkFetchState ? "dropdown" : ''} printDoc`}>
                  {
                    this.state.loadingBtn ?
                    (
                        <React.Fragment>
                        <button type="button" className="btn-primary-scale-outline floatRight">
                          <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          <Translation>{t => t("meter:Preparing information")}</Translation>
                        </button>
                      </React.Fragment>
                    )
                      :
                      <React.Fragment>
                        <button type="button" className="btn-primary-scale-outline floatRight dropdown-toggle" data-toggle="dropdown">
                          <Translation>{t => t("PageList:Print")}</Translation>
                        </button>
                      </React.Fragment>
                  }
                  <div className={`dropdown-menu`}>
                    <a className="dropdown-item" onClick={this.onClickGetData} target={"_blank"}>PDF</a>        
                    <WaterAndElectricUtilityReceiveReportExports 
                      selectedMonth={this.state.selectedMonth}
                      selectedYear={this.state.selectedYear}
                      selectedAtDate={this.state.selectedAtDate}
                      search={this.state.search}
                      productAndServiceInit={this.state.productAndServiceInit}
                      productAndServiceFilter={this.state.productAndService}
                      contact={this.state.contact}
                      handleLoadingBtn={this.handleLoadingBtn}
                    />       
                  </div>
                </div>
              </div>  
              }
            </div>
            <Translation>
                {
                (t) =>
                <div className="content-inner">
                    <div className="row justify-content-end mb-2">
                        <div className="col">
                        <div className="input-group float-right w-auto ml-2">
                            <input
                            type="text"
                            className="form-control input-size"
                            placeholder={t('individualReceipt:search')}
                            value={this.state.search_input}
                            name="search"
                            onChange={(e) =>
                                this.setState({ search_input: e.target.value })
                            }
                            onKeyDown={this.handleKeyDown}
                            />
                            <button
                            type="submit"
                            className="btn btn-primary form-control search-button"
                            name="search"
                            value={this.state.search_input}
                            onClick={(e) => this.handleSearch(e)}
                            >
                            {t('utilityReceivable:search')}
                            </button>
                        </div>

                        {this.state.temp_month_year && 
                        <div className="float-right ml-2 d-flex align-items-center">
                          <span className="mr-2">{t('utilityReceivable:asAt')}</span>
                          <DatePickerAdapter
                            name="temp_at_date"
                            className="form-control"
                            selected={this.state.temp_at_date}
                            minDate={this.state.temp_month_year}
                            maxDate={this.state.current_date}
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>}

                        <div className="float-right ml-2 d-flex align-items-center">
                          <span className="mr-2">{t('utilityReceivable:forMonthYear')}</span>
                          <DatePickerAdapter
                            name="temp_month_year"
                            className="form-control"
                            selected={this.state.temp_month_year}
                            maxDate={this.state.current_date}
                            onChange={this.handleChange}
                            required={true}
                            dateFormat="MM/yyyy" showMonthYearPicker
                          />
                        </div>
                        </div>
                    </div>

                    <div className="row fade-up">
                        <div className="col">
                        <div className="card advance-search">
                            <div className="row">

                            
                            <ContactReportList
                                handleChange={this.handleChange}
                                contact={this.state.temp_contact}
                            />
                            {this.state.data &&  !this.state.loading &&
                            <WaterElectricProductServiceList  
                                  handleChange={this.handleChange}
                                  dataFilter={this.state.data.productViewer.allProduct}
                                  product_and_service={
                                  this.state.temp_product_and_service
                                  }
                            />}

                            </div>
                        </div>
                        </div>
                    </div>

                    {this.state.productAndServiceInit &&  !this.state.loading ?
                    <React.Fragment>
                    <div className="row mt-3">
                        <div className="col-12">
                        <div className="card fade-up">
                            <div className="table-responsive">                                                          
                                <WaterAndElectricUtilityReceiveReportTable
                                    selectedMonth={this.state.selectedMonth}
                                    selectedYear={this.state.selectedYear}
                                    selectedAtDate={this.state.selectedAtDate}
                                    search={this.state.search}
                                    productAndServiceInit={this.state.productAndServiceInit}
                                    productAndServiceFilter={this.state.productAndService}
                                    contact={this.state.contact}    
                                    handleFirstInit={this.handleFirstInit}      
                                    handleLoadingBtn={this.handleLoadingBtn}                          
                                />                           
                            </div>
                        </div>
                        </div>
                    </div>

                    </React.Fragment>
                    :
                    <Loading />
                    }
                </div>
                }
            </Translation>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default WaterElectricUtilityReceiveReport;
