

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './silvermanAgent.scss'
class SilvermanAgentAccept extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ids:'',
            lang:''
        }
    }


    render() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const token = urlParams.get('token')
        const residential_id = urlParams.get('selected_unit')
        const lang = urlParams.get('lang')
        const substr = residential_id.split("?")[0];
        window.localStorage.setItem("token", token);
        const myLang = lang.split("?")[0];
       // const myLang = "th"


        return (
            <>
                <div id="wrapper-content">
                    <div id='svm-agent'>
                        <div style={{ position: 'relative' }} >
                            <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/tower.png"} alt="tower" className='silver-agent-img' />
                            <div className="centered">
                                <div className='filter-agent-container' >
                                    <div className='pb-7'>
                                    <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/silverman-agent-logo.png"} alt="svm-agent-logo"/>
                                    </div>
                                    <div className='filter-agent-text' >
                                        Let Silverman help you search about
                                    </div>
                                    <div className='filter-agent-text' >
                                        real property and close the deal faster!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div className='p-4'>
                            <h6>{myLang === 'en' ? 'Terms and Conditions Silverman Agent' : 'ข้อกำหนดและเงื่อนไขการใช้งาน Silverman Agent'} </h6>
                            <div>
                                <ol>
                                    <li className='pb-2'>เราขอสงวนสิทธิ์ในการเปลี่ยนแปลง ลบ แก้ไข ข้อตกลงต่างๆ ภายในข้อกำหนดและเงื่อนไขการใช้บริการนี้ได้ตลอดเวลา</li>
                                    <li className='pb-2'>ทรัพย์สินทางปัญญาทั้งหมดบนเว็บไซต์นี้เป็นของ บริษัท พอร์ต 80 จำกัด ซึ่งข้อมูลและสื่อทั้งหมดได้รับการคุ้มครองตามกฎหมายทรัพย์สินทางปัญญา และไม่อนุญาตให้ทำการตีพิมพ์ แก้ไข คัดลอก ทำซ้ำ ทำสำเนาหรือเปลี่ยนแปลงในทางใดก็ตาม ภายนอกพื้นที่ของเว็บไซต์นี้โดยไม่ได้รับการอนุญาตเป็นลายลักษณ์อักษรจากของ บริษัท พอร์ต 80 จำกัด</li>
                                    <li className='pb-2'>เว็บไซต์ของเรามีหน้าที่เป็นสื่อกลางในการโฆษณา, แนะนำ Agent, และแนะนำข้อมูลข่าวสาร คอนโด อสังหาริมทรัพย์ ประกาศขายคอนโด ขายบ้าน เช่าสำนักงาน เช่าบ้าน เช่าคอนโด อาคารพาณิชย์  เพื่อให้คุณได้ใช้บริการได้อย่างสะดวกและปลอดภัย</li>
                                </ol>
                               <div className='pb-5'>
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;โดยข้อมูลทุกชนิดที่ส่งมาให้เราถือว่าไม่เป็นความลับและไม่เป็นกรรมสิทธิ์แต่อย่างใด คุณยินยอมให้เราใช้ข้อมูลดังกล่าวโดยไม่มีเงื่อนไขใดๆ ทั้งสิ้น และคุณจะใช้งานเว็บไซต์นี้ตามวัตถุประสงค์ที่ถูกต้องตามกฎหมาย และไม่กระทำการใดๆ อาทิ
                               การล่วงละเมิดสิทธิส่วนบุคคล หรือสิทธิ์ในทรัพย์สินทางปัญญา ทั้งบุคคลภายนอกและของเราไม่ส่งเนื้อหา ข้อความ รูปภาพและภาพเคลื่อนไหวที่ไม่เหมาะสม รวมทั้งข้อความและรูปภาพที่มีลักษณะขัดต่อกฎหมาย กระทบถึงสถาบันอันเป็นที่เคารพ หรือศีลธรรม 
                               เผยแพร่ผ่านบริการของเว็บไซต์เรา อันก่อให้เกิดความสูญเสีย หรือเสียหายต่อบริษัท พอร์ต 80 จำกัด และบุคคลภายนอก เช่น
                               </div>
                               <ul>
                                <li className='pb-2'>ใช้ภาพหรือข้อมูลผู้อื่นโดยไม่ได้รับอนุญาติ กรณีนี้ถือว่าค่อนข้างมีความผิดตาม พ.ร.บ. คอมพิวเตอร์ซึ่งเจ้าของข้อมูลสามารถฟ้องได้เป็นคดีอาญาได้</li>
                                <li className='pb-2'>ส่งข้อมูลที่ไม่เกี่ยวข้องกับอสังหาริมทรัพย์ การลงโฆษณาอื่นๆที่ไม่ได้ประกาศขายเช่า บ้าน คอนโด ที่ดิน</li>
                                <li className='pb-2'>ขอให้ลงทะเบียนใช้บัญชี User เพียงชื่อเดียวเท่านั้น หากระบบตรวจการใช้ข้อมูล User ซ้ำซ้อนกัน หรือจงใจลงทะเบียนไว้หลายชื่อ User ดังกล่าวจะถูกระงับการใช้บริการโดยทันที</li>
                               </ul>

                            </div>
                            <div className='footer-svm-agent'>
                            <Link to={{ pathname:`/silvermanagent/silvermanagent-create/`+token+"/"+substr+"/"+myLang}}>
                                    <button className="btn btn-primary" style={{width:'320px'}}>
                                       {myLang === 'en' ? 'Accept and Continue' : 'ตกลงและถัดไป'} 
                                    </button>
                            </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </>
                
        );
    }
}

export default SilvermanAgentAccept;
