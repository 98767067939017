import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

// var token_id = localStorage.getItem("token");

function getToken() {
    var token_ids = localStorage.getItem("token");
    var header = {
        authorization: `JWT ${token_ids}`,
        "Content-Type": "application/json",
    };
    // token_id = token_ids
    return header
}

async function getAllSettingCheck() {
    let siteId = localStorage.getItem('site_id')

    return axios.get(`${getApiRoot()}guard/all_setting_guard`,
        {
            headers: getToken(),
            params: {
                site: siteId                
            }
        },
    );
}

async function getWalkInSetting() {
    return axios.get(`${getApiRoot()}guard/guard_setting_management`, {headers: getToken()});
}

async function addGuardSettingManagement(body) {
    return axios.post(`${getApiRoot()}guard/guard_setting_management`, body, { headers: getToken() });
}

async function addCar(body) {
    return axios.post(`${getApiRoot()}guard/car_transaction`, body, { headers: getToken() });
}

async function getQRCodePerSite() {
    return axios.get(`${getApiRoot()}guard/qrcode`, {headers: getToken() });
}


async function postQRCodeForWalkIn(body) {
    return axios.post(`${getApiRoot()}guard/code_guard`, body, { headers: getToken() });
}

async function getCarDetail(codeVisit) {
    return axios.get(`${getApiRoot()}vms/car_vms`,
        {
            headers: getToken(),
            params: {
                code: codeVisit                
            }
        },
    );
}
//vms payment
async function getPayment(code) {
    return axios.get(`${getApiRoot()}guard/payment`,
        {
            headers: getToken(),
            params: {
                code: code                
            }
        },
    );
}

async function postPayment(body) {
    return axios.post(`${getApiRoot()}guard/payment_webview`, body, { headers: getToken() });
}
// vms setting parking fee account
async function getBank() {
    return axios.get(`${getApiRoot()}guard/bank`,{headers: getToken()});
}

async function postSettingParkingFeeAccount(body) {
    return axios.post(`${getApiRoot()}vms/setting_parking_fee_account`, body, { headers: getToken() });
}

async function getSettingParkingFeeAccount() {
    return axios.get(`${getApiRoot()}vms/setting_parking_fee_account`,{headers: getToken()});
}

async function getAllResidential() {
    return axios.get(`${getApiRoot()}vms/all_residential`,{headers: getToken()});
}

//send email
async function postSentEmail(body) {
    return axios.post(`${getApiRoot()}guard/send_email`, body, { headers: getToken() });
}
// post OR 
async function postOtherReceive(body) {
    return axios.post(`${getApiRoot()}guard/other_receive`, body, { headers: getToken() });
}

async function getAllSettingGuard(siteId) {
    return axios.get(`${getApiRoot()}guard/all_setting_guard`,{
        headers: getToken(),
        // params: {
        //     site: siteId                
        // }
    },
    );
}

export default {
    addGuardSettingManagement,
    getWalkInSetting,
    getAllSettingCheck,
    getQRCodePerSite,
    postQRCodeForWalkIn,
    addCar,
    getCarDetail,
    getPayment,
    postPayment,
    getBank,
    postSettingParkingFeeAccount,
    getSettingParkingFeeAccount,
    getAllResidential,
    postSentEmail,
    postOtherReceive,
    getAllSettingGuard
}