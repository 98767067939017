/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SettingAutomaticDebitDebtStatus = "CUSTOM" | "DUEDATE" | "%future added value";
export type SettingAutomaticDebitSetDateMethod = "END_OF_MONTH" | "THE_EIGHTEENTH" | "THE_EIGHTH" | "THE_ELEVENTH" | "THE_FIFTEENTH" | "THE_FIFTH" | "THE_FIRST" | "THE_FOURTEENTH" | "THE_FOURTH" | "THE_NINETEENTH" | "THE_NINTH" | "THE_SECOND" | "THE_SEVENTEENTH" | "THE_SEVENTH" | "THE_SIXTEENTH" | "THE_SIXTH" | "THE_TENTH" | "THE_THIRD" | "THE_THIRTEENTH" | "THE_TWELFTH" | "THE_TWENTIETH" | "THE_TWENTY_EIGHTH" | "THE_TWENTY_FIFTH" | "THE_TWENTY_FIRST" | "THE_TWENTY_FOURTH" | "THE_TWENTY_SECOND" | "THE_TWENTY_SEVENTH" | "THE_TWENTY_SIXTH" | "THE_TWENTY_THIRD" | "%future added value";
export type SettingAutomaticDebitSetMethod = "CUSTOM" | "DATE" | "DUEDATE" | "%future added value";
export type CreateAndUpdateSettingAutomaticDebitInput = {|
  settingAutomatic: any,
  clientMutationId?: ?string,
|};
export type settingAutomaticMutationVariables = {|
  input: CreateAndUpdateSettingAutomaticDebitInput
|};
export type settingAutomaticMutationResponse = {|
  +createAndUpdateSettingAutomaticDebit: ?{|
    +ok: ?boolean,
    +message: ?string,
    +newSettingAutomaticDebit: ?{|
      +id: string,
      +automaticStatus: boolean,
      +setMethod: SettingAutomaticDebitSetMethod,
      +setDateMethod: ?SettingAutomaticDebitSetDateMethod,
      +setDuedateMethod: ?number,
      +setCustomMethod1: ?number,
      +setCustomMethod2: ?number,
      +setCustomMethod3: ?number,
      +setCustomMethod4: ?number,
      +debtStatus: SettingAutomaticDebitDebtStatus,
      +receivableOutstanding: boolean,
    |},
  |}
|};
export type settingAutomaticMutation = {|
  variables: settingAutomaticMutationVariables,
  response: settingAutomaticMutationResponse,
|};
*/


/*
mutation settingAutomaticMutation(
  $input: CreateAndUpdateSettingAutomaticDebitInput!
) {
  createAndUpdateSettingAutomaticDebit(input: $input) {
    ok
    message
    newSettingAutomaticDebit {
      id
      automaticStatus
      setMethod
      setDateMethod
      setDuedateMethod
      setCustomMethod1
      setCustomMethod2
      setCustomMethod3
      setCustomMethod4
      debtStatus
      receivableOutstanding
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAndUpdateSettingAutomaticDebitPayload",
    "kind": "LinkedField",
    "name": "createAndUpdateSettingAutomaticDebit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingAutomaticDebitNode",
        "kind": "LinkedField",
        "name": "newSettingAutomaticDebit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "automaticStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setDateMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setDuedateMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setCustomMethod1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setCustomMethod2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setCustomMethod3",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setCustomMethod4",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "debtStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receivableOutstanding",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingAutomaticMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingAutomaticMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ceec969ef257c2c74315b3bc7a89c1e8",
    "id": null,
    "metadata": {},
    "name": "settingAutomaticMutation",
    "operationKind": "mutation",
    "text": "mutation settingAutomaticMutation(\n  $input: CreateAndUpdateSettingAutomaticDebitInput!\n) {\n  createAndUpdateSettingAutomaticDebit(input: $input) {\n    ok\n    message\n    newSettingAutomaticDebit {\n      id\n      automaticStatus\n      setMethod\n      setDateMethod\n      setDuedateMethod\n      setCustomMethod1\n      setCustomMethod2\n      setCustomMethod3\n      setCustomMethod4\n      debtStatus\n      receivableOutstanding\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '119d9c0c650613411c8182c847af1e7f';

module.exports = node;
