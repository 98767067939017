import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import ElectricMeterList from "./electricMeterList";
import ElectricMeterCreateHistory from "./electricMeterCreateHistory"
import NoMatch from "../../components/noMatch";

class ElectricMeter extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/create_history/:id?`} component={ElectricMeterCreateHistory}/>
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={ElectricMeterList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default ElectricMeter;