import React from 'react';
import { Component } from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "../../developer.scss"
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import _ from 'lodash';
import Swal from "sweetalert2";
import i18n from 'i18next';
import AddEditList from './addEditList';
import unitStatusAPI from '../unitStatusAPI';
import { format } from 'date-fns';
import {Redirect} from 'react-router';
import ModalResidential from './modalResidential';
import OwnershipInformation from './ownershipInformation';
import upload from '../../../libs/upload';
import i18next from 'i18next';

const newInsuranceData = 
    {
        id: '',
        name: '',
        description: '',
        expDate: new Date(),
        isInsure: false
    }
class TransferForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            timeOfGuarantee : 12,
            insuranceData : [],
            notInsurance : false,
            status : 'add',
            selectedInsuranceList: [],
            contactList: [],
            dateConveyance: new Date(),
            token: localStorage.getItem("token"),
            redirectToList : false,
            tenant : []
        }
        this.handleChange = this.handleChange.bind(this);
        this.appendNewRow = this.appendNewRow.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.editTransaction = this.editTransaction.bind(this);
        this.selectedInsuranceList = this.selectedInsuranceList.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount(){
        this.fetchQuery()
        this.setState({
            contactList : this.props.contactList
        })
    }

    fetchQuery = () => {
        unitStatusAPI.getInsurance(this.state.token)
            .then((response) => {
                if(response.data.insures_list){
                    let insuranceData = []
                    _.forEach(response.data.insures_list , insure => {
                        insuranceData.push({...insure.insuranceData , expireDate : new Date() , isInsure : false})
                    })
                    this.setState({
                        insuranceData : insuranceData
                    })
                }
            })
    }

    handleInputChange(e){
        if (e.target.type === 'radio') {
            let name = 'gender';
            let value = e.target.name
            this.setState({
                [name] : value
            })
        }else if(e.target.name === 'image.upload_image'){
            this.setState({
                imageUpload : e.target.value
            })
        }else{
            this.setState({
                [e.target.name] : e.target.value
            })
        }

    }


    handleChange(e){
        if(e.target.dataset.key === 'data-insurance'){
            let insuranceData = _.cloneDeep(this.state.insuranceData)
            if(e.target.name === "isInsure"){
                insuranceData[e.target.dataset.id][e.target.name] = !insuranceData[e.target.dataset.id][e.target.name]
            }else{
                insuranceData[e.target.dataset.id][e.target.name] = e.target.value
            }
            this.setState({
                insuranceData : [...insuranceData]
            }, () => this.selectedInsuranceList())
        }else if(e.target.name=== 'notInsurance'){
            this.setState({
                [e.target.name] : !this.state.notInsurance
            })
        }else{
            this.setState({
                [e.target.name] : e.target.value
            })
        }
    }

    appendNewRow(type){
        if(type=== 'insurance'){
            this.fetchQuery()
            
        }
    }

    editTransaction(index,type,transaction){
        if(type === 'insurance'){
            let insuranceData = _.cloneDeep(this.state.insuranceData)
            insuranceData[index] = transaction
            this.setState({
                insuranceData : insuranceData
            }, () => this.selectedInsuranceList())
        }
    }


    onDelete(type,index){
        Swal.fire({
            title: i18n.t('settingNote:Are you sure to delete this note?'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t('settingNote:Yes'),
            cancelButtonText: i18n.t('settingNote:Cancel')
        }).then((result) => {
            if (result.value) {
                if(type === 'insurance'){
                    let insuranceData = _.cloneDeep(this.state.insuranceData)
                    insuranceData.splice(index,1)
                    this.setState({
                        insuranceData : insuranceData
                    })
                }
            }
         })  
    }

    selectedInsuranceList(){
        let selectedInsuranceList =  _.filter(_.cloneDeep(this.state.insuranceData),{isInsure:true})
        this.setState({
            selectedInsuranceList : selectedInsuranceList
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/developer/unitStatus" + path, file);
          resolve(res);
        });
    };


    async onSubmit(e){
        e.preventDefault()
        this.setState({ loading: true});
        let insures = [];
        let validDateConveyance = false
        if(this.state.dateConveyance === '' || this.state.dateConveyance === null){
            validDateConveyance = true
            Swal.fire("กรุณาใส่วันที่โอนกรรมสิทธิ์!", "", "warning").then(() => {
                this.setState({
                    loading : false
                })
            });
        }
        if(!validDateConveyance){
            if(!this.state.notInsurance){
                _.forEach(this.state.selectedInsuranceList , data => {
                    insures.push({id : '', insuresID : data.id , expireDate : format(data.expireDate,'YYYY-MM-DD') , status :''})
                })
            }
            
    
            let ownership = [];
            for (const contact of this.state.contactList) {
                ownership.push({
                    residentialID : `${contact.unitStatusList.residentialID}`,
                    dateConveyance : format(this.state.dateConveyance,'YYYY-MM-DD'),
                    repairInsurance : this.state.notInsurance ? 0 : this.state.timeOfGuarantee,
                    status : '',
                    insures : insures,
                })
            }
            let tenantList = [];
            for (const tenant of this.state.tenant) {
                let image = ''
                if(tenant.imageUpload !== ''){
                    await this.callUpload('', tenant.imageUpload).then((data) => {
                        image = data.key.replace("silverman-storage/private/", "");
                    });
                }
                tenantList.push({
                    image:image,
                    nameTitle: tenant.nameTitle !== 'other_input' ? tenant.nameTitle : tenant.namePrefixOther,
                    firstName: tenant.firstName,
                    lastName: tenant.nameTitle !== 'บริษัท' ? tenant.lastName : '',
                    firstNameEng : tenant.firstNameEng,
                    lastNameEng : tenant.nameTitle !== 'บริษัท' ? tenant.lastNameEng : '',
                    occupation : tenant.nameTitle !== 'บริษัท' ? tenant.occupation : '',
                    tenantStatus : tenant.tenantStatus,
                    ethnicity : tenant.ethnicity,
                    cardType : tenant.cardType,
                    idCardNumber:tenant.idCardNumber,
                    dateOfBirth: (tenant.nameTitle !== 'บริษัท' && tenant.dateOfBirth !== '')? format(tenant.dateOfBirth,'YYYY-MM-DD') : null,
                    gender:tenant.gender,
                    countries: tenant.countries !== '' ? tenant.countries : 'TH',
                    phone: tenant.phone,
                    email: tenant.email,
                    phone2 : tenant.phone2
    
                })
            }
    
            let variables = {
               tenant: tenantList,
                ownership:  ownership 
            };
            
            await unitStatusAPI.createOwnership(variables,this.state.token).then((response) => {
                if(response.statusMessage === 'SUCCESS'){
                    this.onSuccess()
                }else {
                    this.onError()
                }
            })
        }
    }

    onSuccess = () => {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => { 
            this.setState({ redirectToList: true });
        });
      }
    
      onError = () => {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
      }

    getTenant = (tenant) => {
        this.setState({
            tenant : tenant
        })
    }


    render(){
        if (this.state.redirectToList) {
            return <Redirect to="/developer/unit-status/list/all"/>;
        }
        let contactName = ''
        if(this.state.contactList.length === 1){
            contactName = this.state.contactList[0].unitStatusList.refNumber + ' '
            this.state.contactList[0].unitStatusList.ownershipName.forEach((name) => {
                contactName = contactName +' ' + name
            })
        }
        return(
            <Translation>
                {t => 
                <div className="container-fluid box" id="developer-form">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="mb-4">
                                <Link to="/developer/unit-status/create/select_customer">
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back"/>
                                </Link>
                                {t('developer:Transfer of ownership')}
                            </h4>
                        </div>
                    </div>
                        <div className="content-inner content-inner-padding">
                            <form onSubmit={this.onSubmit} id="transfer"> 
                                <div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col form-inline">
                                            <h4 className="mr-4">รายชื่อห้องที่เลือก</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="box-detail">
                                        <div className="row col-9 mb-3">
                                            <div className="col-md-7 col-sm-8">
                                                <br/>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control input-size"
                                                        value={this.state.contactList.length === 1 ? contactName : 'แบบหลายห้อง'}
                                                        disabled={this.state.contactList.length === 1}
                                                        readOnly={true}
                                                        style= {{ borderRight: this.state.contactList.length > 1 ?'none' : ''}}
                                                    />
                                                    {this.state.contactList.length > 1 &&
                                                        <ModalResidential contact={this.state.contactList}/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col">
                                                วันที่โอนกรรมสิทธิ์ <span className="text-danger">*</span>
                                                <DatePickerAdapter 
                                                    selected={this.state.dateConveyance}
                                                    name="dateConveyance" className="form-control w-100"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex mt-3 align-items-center">
                                        <div className="col form-inline">
                                            <h4 className="mr-4">ข้อมูลผู้รับโอนกรรมสิทธิ์</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-2">
                                        <OwnershipInformation handleChange={this.handleInputChange} callBack={this.getTenant} state= {this.state} />
                                    </div>
                                    <br/>
                                    <div className="row mt-3">
    
                                    </div>
                                    <div className ="row d-flex align-items-center">
                                        <div className="col form-inline">
                                            <h4 className="mr-4 mt-3">งานประกัน</h4>
                                            <input type="checkbox" className="mr-2 align-items-center" checked={this.state.notInsurance} name="notInsurance" onChange={this.handleChange}></input>
                                            <span className="align-items-center">ไม่มีประกัน</span>
                                        </div>
                                        <div className="col-3 d-flex justify-content-end ">
                                            <span className="text-blue">นโยบายการรับประกัน</span>
                
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="box-detail">
                                        <div className="row col-9 mb-3">
                                            <div className="col-md-8 col-sm-12">
                                                ระยะเวลารับประกันงานซ่อมจากบริษัทผู้พัฒนาโครงการ (เดือน) <br/>
                                                <input type="number" value ={this.state.timeOfGuarantee} name="timeOfGuarantee" 
                                                    className="form-control" onChange={this.handleChange}
                                                    disabled={this.state.notInsurance}/>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row col-12 mb-3 mt-3">
                                            <div className="col-12"><h5>ประเภทประกัน</h5></div>
                                            <div className ={this.state.notInsurance ? "disable col-6" : "col-6"}>
                                                {this.state.insuranceData.map((transaction,index) => {
                                                    return(
                                                        <div className="input-group mt-2" key={index}>
                                                            <div className="col-7 box-transaction">
                                                                <input type="checkbox" className="mr-2" 
                                                                checked={transaction.isInsure} 
                                                                data-id={index} 
                                                                data-key="data-insurance" 
                                                                name="isInsure" onChange={this.handleChange}
                                                                disabled={this.state.notInsurance}/> 
                                                                <span>{transaction.name}</span> <br/>
                                                                <div className="ml-4" ><span className="text-detail">{transaction.description}</span></div>   
                                                            </div>
                                                            <div className="col-4">
                                                                วันที่หมดประกัน
                                                                <DatePickerAdapter
                                                                    name="expireDate" className="form-control w-100"
                                                                    data-key="data-insurance"
                                                                    data-id={index}
                                                                    selected={transaction.expireDate}
                                                                    onChange={this.handleChange}
                                                                    disable={(this.state.notInsurance || transaction.id === "" || transaction.id === undefined)}
                                                                />
                                                                
                                                            </div>
                                                            {/* {(transaction.id === "" || transaction.id === undefined) &&
                                                                <div className="col d-flex justify-content-center align-items-center">
                                                                    <span className="btn cursor btn-dropdown" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                                        <img src={ process.env.PUBLIC_URL +"/images/icons/choice_noborder.png"} alt="choice"/>
                                                                    </span>
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
                                                                        <AddEditList type='insurance' status="edit" transaction={transaction} index={index} editTransaction={this.editTransaction}/>
                                                                        <span className="dropdown-item cursor" onClick={() => this.onDelete('insurance',index)} >
                                                                            <img src={process.env.PUBLIC_URL +"/images/icons/bin.png"} alt="bin"/>
                                                                            <span className="ml-3">{t("unitStatus:Delete")}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            } */}
                                                            <hr className="mt-2 insurance"/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <AddEditList type='insurance' status="add" transaction={newInsuranceData} appendNewRow = {this.appendNewRow}/>
                                    </div>
                                </div>
                            </form>
                            
                            <div className="row justify-content-end mb-2">
                                <div className="col mt-3">
                                    <button type="submit" form="transfer" className="btn btn-blue add  float-right">
                                        {this.state.loading &&
                                        <span className="spinner-border spinner-border-sm align-middle ml-2"/>
                                        }
                                       <span>{t('developer:Transfer of ownership')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>}
            </Translation>
            
        );
    }


}
export default TransferForm;