import React from 'react';
import { Translation } from 'react-i18next';
import _ from "lodash";
import {Link} from "react-router-dom";
import Navigation from './navigation'
import UploadFile from './uploadFile';
import Pagination from '../../../../../libs/newPagination';
import ComponentPagination from '../../../../../libs/componentPagination';
import { graphql } from "babel-plugin-relay/macro";
import Swal from 'sweetalert2';
import i18n from 'i18next';
import { commitMutation } from "react-relay";
import {fetchQuery} from "relay-runtime";
import environment from '../../../../../env/environment';
import Loading from '../../../../../libs/loading';
import InlineAdvanceFilter from '../../../../../libs/inlineAdvanceFilter';
import getApiRoot from '../../../../../libs/getAPIRoot';
import { format } from 'date-fns';
import numberWithComma from '../../../../../libs/numberWithComma';
import './fileImportBatch.scss';
import LoadingModal from '../modalBatch/loadingModal';

const query = graphql`
    query fileImportBatchQuery($startDate: Date, $endDate: Date, $search: String, $first: Int, $last: Int,){
        allImportReceive(startDate: $startDate, endDate: $endDate, search: $search, first: $first, last: $last){
            edges{
                node{
                    id
                    allRow
                    successRow
                    failRow
                    added
                    batch{
                        docNumber
                        batchDate
                        startRt
                        endRt
                        creator
                        voider
                        remark
                        status
                    }
                }
            }
            totalCount
        }        
    }
`;

const mutation = graphql`
  mutation fileImportBatchMutation($input: ImportCreateReceiveInput!) {
    importCreateReceive(input: $input) {
      ok     
      message
    }
  }
`;

class FileImportBatch extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state.documentUpload = "";
        this.state.saveDisable = "";
        this.state.allImportReceive = [];
        this.state.loading = false;
        this.state.temp_start_date = null;
        this.state.temp_end_date = null;
        this.state.advance_search = false;
        this.state.search = "";
        this.state.totalCount = 0;
        this.state.loadingImportViaEmail = false;
        this.state.resultImportViaEmail = "";
        this.state.isModalOpen = false;
        this.handleInputDocument = this.handleInputDocument.bind(this);
        this.fetchImportData = this.fetchImportData.bind(this);
        this.calBatchRange = this.calBatchRange.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.getImportResult = this.getImportResult.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.checkStatusImportViaEmail = this.checkStatusImportViaEmail.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.fetchImportData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
             prevState.first !== this.state.first ||
             prevState.last !== this.state.last
           ) {
             this.fetchImportData();
           }
    }

    fetchImportData() {        
        this.setState({ 
            loading: true 
        });
        const { temp_start_date, temp_end_date, search } = this.state;
        const variables = {
          startDate: temp_start_date ? format(temp_start_date, "YYYY-MM-DD") : null,
          endDate: temp_end_date ? format(temp_end_date, "YYYY-MM-DD") : null,
          search: search ? search : "",
          first: this.state.first,
          last: this.state.last,
        };
        fetchQuery(environment, query, variables).then(res => { 
            this.setState({
                allImportReceive: res.allImportReceive.edges,
                loading: false,
                totalCount: res.allImportReceive.totalCount
            })                
        })
    }

    handleKeyDown(event) {
        if (event.key === "Enter") {
          this.handleSearch(event);
        }
    }

    handleInputDocument(e) {
        this.setState({
          documentUpload: e.currentTarget.files[0],
        },()=>{
        let uploadables = { document_upload: this.state.documentUpload };

        let variables = {
            input: {},            
        };
        

        this.setState({ loading: true });

        commitMutation(environment, {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {           
              if (response.importCreateReceive.ok) {                
                Swal.fire(i18n.t('batchRt:Import Successful'), '', 'success').then(() => {
                    window.location.reload()
                });
              } else {
                Swal.fire(i18n.t('meter:Failed to import'), '', 'warning').then(() => {
                    window.location.reload()
                });
              }
            },
            onError: (err) => {
                Swal.fire(i18n.t('meter:Failed to import'), err, 'warning').then(() => {
                    window.location.reload()
                });
            },
        });
    });


    }

    calBatchRange(startRt, endRt) {      
        let results = "";
        if(startRt === endRt) {
            results = startRt;
        } else {
            const startData = startRt?.split(",");
            const endData = endRt?.split(",");            
            results = startData.map((start, index) => `${start} - ${endData[index]}`);
        }

        return results
    }

    handleChangeFilter(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSearch(e) {
        this.setState({
          start_date: this.state.temp_start_date,
          end_date: this.state.temp_end_date,
          search: e.target.value,
        }, ()=>this.fetchImportData());
    }

    getImportResult(batchId, resultType) {
        let myHeaders = new Headers();
        let token_id = localStorage.getItem("token");
        
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", `JWT ${token_id}`);
        
        let requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        this.openModal();
        const apiUrl = `${getApiRoot()}export/receive_import_result?import_id=${batchId}${resultType ? `&type=${resultType}` : ""}`
        fetch(apiUrl, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `import-receive-result-${resultType}.xls`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.closeModal();
        })
        .catch(error => console.error('Error downloading file:', error));
    }

    async checkStatusImportViaEmail() {        
        this.setState({
            loadingImportViaEmail: true
        });
    
        try {
            const response = await fetch(`${getApiRoot()}account/gmail/get-attachment`);
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();            
            this.setState({
                resultImportViaEmail: data.statusMessage
            })            
    
        } catch (error) {
            console.error('Error:', error);
        } finally {
            this.setState({
                loadingImportViaEmail: false
            });
        }
    }

    openModal() {
        this.setState({ isModalOpen: true });
    }

    closeModal() {
        this.setState({ isModalOpen: false });
    }
    

    render() {

    return (
        <Translation>{t => 
            <div className="container-fluid box" id="fileImportBatch">
                <div className="row justify-content-between">
                    <div className="col">
                        <h3 className="mb-4">
                            <Link to="/accounting/income/receive/list/all">
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                     alt="back" className="back"/>
                            </Link>
                            <span>{t('batchRt:Import file')}</span>
                        </h3>
                    </div>
                </div>
                <div className="row">
                        <div className="col-9"></div>
                        <div className="col-3">
                                <UploadFile 
                                    handleInputDocument={this.handleInputDocument}
                                    documentUpload={this.state.documentUpload}
                                />
                        </div>
                </div>
                <div className="content-inner">
                    <Navigation />
                    <div className="row">
                        <div className='col'>
                        <button type="button" class="btn btn-outline-secondary" onClick={this.checkStatusImportViaEmail}>
                            <img 
                                className={`${this.state.loadingImportViaEmail ? "spin-animation":""}`}
                                src={process.env.PUBLIC_URL + '/images/icons/updating2.png'} 
                                width={'15px'}
                                height={'15px'}
                                alt="updating"                             
                            />  
                        </button>
                        {
                            this.state.resultImportViaEmail && 
                            <p className={`mt-2 mb-0 ${this.state.resultImportViaEmail === "success" ?"text-green":"text-red"}`}>
                                {this.state.resultImportViaEmail === "success" ? 
                                    t('batchRt:The import file via email was successful.') : 
                                    t('batchRt:The import file via email failed.')}
                            </p>
                        }
                        </div>
                    </div>
                    <div className="row">
                        <div className='col'>           
                            <div className="input-group float-right w-auto ml-2">
                                <input  
                                    type="text"
                                    className="form-control input-size col-8"
                                    placeholder="ค้นหา"
                                    value={this.state.search_input}
                                    name="search"
                                    onChange={(e) =>
                                    this.setState({ search_input: e.target.value })
                                    }
                                    onKeyDown={this.handleKeyDown}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary form-control search-button"
                                    name="search"
                                    value={this.state.search_input}
                                    onClick={(e) => this.handleSearch(e)}                                    
                                >
                                    {t('batchRt:Search')}
                                </button>
                            </div>
                            <InlineAdvanceFilter
                                show_filter={this.state.advance_search}
                                start_date={this.state.temp_start_date}
                                end_date={this.state.temp_end_date}
                                handleChange={this.handleChangeFilter}
                            />
                        </div>
                    </div>           
                    {this.state.loading ?
                    <Loading />
                        :
                    <div className="table-responsive fade-up card">
                        <table className="table table-hover">
                            <thead className="thead-light">
                                <tr>
                                <th className="text-left">{t('batchRt:Import date')}</th>
                                <th className="text-left">{t('batchRt:Time')}</th>
                                <th className="text-left">{t('batchRt:Quantity')}</th>
                                <th></th>
                                <th className="text-left">{t('batchRt:Success')}</th>
                                <th></th>
                                <th className="text-left">{t('batchRt:Unsuccessful')}</th>
                                <th></th>
                                <th className="text-left">{t('batchRt:Batch number')}</th>
                                <th className="text-left">{t('batchRt:Document number')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.allImportReceive.map((importBatch, index)=>(                                    
                                    <tr key={index}>
                                        <td className='text-left'>{format(importBatch.node.added, 'DD/MM/YYYY')}</td>
                                        <td className='text-left'>{format(importBatch.node.added, 'HH.mm')}</td>
                                        <td className='text-left numberTransaction pr-0'>
                                            {importBatch.node.allRow ? numberWithComma(importBatch.node.allRow,_,_,0,_) : 0} {t('batchRt:Transaction')}
                                        </td>
                                        <td className='pr-5 pl-0'>
                                            <button type="button" className="btn btn-light border btn-outline-secondary" onClick={()=>this.getImportResult(importBatch.node.id,'all')}>           
                                                    <img
                                                        src={
                                                        process.env.PUBLIC_URL + "/images/icons/printIcon.svg"
                                                        }
                                                        alt="print-icon-all"
                                                    />
                                            </button>     
                                        </td>
                                        
                                        <td className='text-left numberTransaction pr-0'>
                                            {importBatch.node.successRow ? numberWithComma(importBatch.node.successRow,_,_,0,_) : 0} {t('batchRt:Transaction')}                                                                                          
                                        </td>
                                        <td className='pr-5 pl-0'>
                                                <button type="button" className="btn btn-light border" onClick={()=>this.getImportResult(importBatch.node.id,'success')}>           
                                                    <img
                                                        src={
                                                        process.env.PUBLIC_URL + "/images/icons/printIcon.svg"
                                                        }
                                                        alt="print-icon-success"                                                
                                                    />
                                                </button>   
                                        </td>
                                        <td className='text-left numberTransaction pr-0'>
                                            {importBatch.node.failRow ? numberWithComma(importBatch.node.failRow,_,_,0,_) : 0} {t('batchRt:Transaction')}                                            
                                               
                                        </td>
                                        <td className='pr-5 pl-0'>
                                            <button type="button" className="btn btn-light border" onClick={()=>this.getImportResult(importBatch.node.id,'fail')}>           
                                                <img
                                                    src={
                                                    process.env.PUBLIC_URL + "/images/icons/printIcon.svg"
                                                    }
                                                    alt="print-icon-fail"
                                                />
                                            </button>   
                                        </td>

                                        <td className='text-left'>{importBatch.node.batch.docNumber}</td>
                                            <td className='text-left'>
                                            {importBatch.node.batch.startRt && 
                                                Array.isArray(this.calBatchRange(importBatch.node.batch.startRt, importBatch.node.batch.endRt)) ?                                            
                                                this.calBatchRange(importBatch.node.batch.startRt, importBatch.node.batch.endRt).map((rtRange, index) => (
                                                    <React.Fragment key={index}>
                                                        {rtRange}<br />
                                                    </React.Fragment>
                                                ))
                                                :
                                                this.calBatchRange(importBatch.node.batch.startRt, importBatch.node.batch.endRt)                                            
                                            }                                             
                                            </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    <div className="row">
                        <Pagination 
                            changePage={this.changePage}
                            first={this.state.first}
                            last={this.state.last}
                            totalCount={this.state.totalCount}
                        />
                    </div>
                </div>

                <LoadingModal isOpen={this.state.isModalOpen} onClose={this.closeModal}>
                    <Loading />
                    <div className="text-center p-2 m-2">
                        <h2>กรุณารอสักครู่ . . .</h2>
                    </div>
                </LoadingModal>

            </div>
        }</Translation>
    )}
}

export default FileImportBatch