import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from 'react-i18next';
import _ from "lodash"
import Wrapper from '../../../components/wrapper';
import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header';
import Api from '../../../api/sing'
import Swal from 'sweetalert2';
import i18n from 'i18next';
import i18next from 'i18next';


class SettingInSur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { repairs_fastinspect: "" }
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentWillMount() {
        this.getDataSetitng();
    }

    getDataSetitng() {
        Api.getRepairs_setting().then((res) => {
            let data = []
            if (res.data.data) {
                data = res.data.data[0]
                this.setState({ loading: false, data: data })
            } else {
                this.setState({ loading: false })
            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')).then(() => {
            this.setState({ loading: false })
        })
    }

    async onSubmit(e) {
        e.preventDefault();

        let repairs_fastinspect = this.state.data.repairs_fastinspect  ? "True" : "False" 
        Api.postRepairs_setting(repairs_fastinspect).then((res) => {  
            Swal.fire(i18n.t("Allaction:Saved Successfully"), i18n.t("Allaction:Saved Successfully"), 'success')
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')).then(() => {
            this.setState({ loading: false })
        })

    }

    onChange(e) { 
        let data = _.cloneDeep(this.state.data)
        let repairs_fastinspect =  e.target.value === "true" ? true : false
        if (data) {
            data.repairs_fastinspect = repairs_fastinspect
            this.setState({ data: data })
        }else{
            this.setState({ data: {repairs_fastinspect : repairs_fastinspect} })
        }


    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content" >

                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="settingInsutanceFastinSpect">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/contact">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link> <span style={{ fontWeight: '400' }}>{t("settingInsured:Fix Request Insurrance Setting")}</span>
                                        </h3>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-7 mx-auto'>
                                        <div >
                                            <form onSubmit={this.onSubmit}>
                                                <div className="content-inner" id="projectDashboard">
                                                    <h4 className='text-left '>{t("settingInsured:Would you like to connect the fix request menu with Fastinspect (Construction Problem Management Service System)")}?</h4>

                                                    <label className="containerRadio" style={{ marginTop: '26px' }}>
                                                        {t('settingSecurity:yes')}
                                                        <input type="radio" name="repairs_fastinspect" value={true}
                                                            checked={this.state.data?.repairs_fastinspect == true}
                                                            onChange={this.onChange} />
                                                        <span className="checkmark"></span>
                                                    </label>

                                                    <label className="containerRadio" style={{ marginTop: '16px' }}>
                                                        {t('settingSecurity:no')}
                                                        <input type="radio" name="repairs_fastinspect" value={false}
                                                            checked={this.state.data?.repairs_fastinspect == false}
                                                            onChange={this.onChange} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                            </form>

                                        </div>
                                    </div>
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'repairs_room_create'}) &&
                                    <div className='col-12' style={{ marginTop: '10%' }}>
                                        <div className='pullright' style={{ marginRight: "10%" }}>
                                            <button type="submit" style={{ marginRight: "8%" }}
                                                onClick={this.onSubmit}
                                                className="btn btn-primary add mr-1">{t('Allaction:save')}</button>
                                            <button type="button"
                                                className="btn btn-primary-outline mr-1">{t('Allaction:cancel')}</button>
                                        </div>
                                    </div>
                                    }



                                </div>

                                {/* <Navigation />  */}
                            </div>

                        }
                    </Translation>



                </div>
            </Wrapper>
        )
    }
}

export default SettingInSur;