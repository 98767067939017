import { graphql } from "babel-plugin-relay/macro";

const query = graphql`
    query postRegulationQuery($search: String, $order: String $startdate: DateTime, $enddate: DateTime,$first: Int, $last: Int, $regulationid: String){
        postRegulation(search: $search, order:$order, startdate: $startdate, enddate: $enddate, first: $first, last: $last ,regulationid: $regulationid) {
            edges{
                node{
                    id
                    subject
                    subjectEn
                    description
                    descriptionEn
                    creator
                    datePosted
                    dateSchedule
                    status
                    type
                    previewImage
                    regulationFile{
                        edges{
                            node{
                                id
                                fileName
                                fileUpload
                                fileUrl
                            }
                        }
                    }
                }
            }
            totalCount
        }
    }
`

export default function getQueryPost() {
    return query
}