import React, { Component } from 'react'
import _ from "lodash"
import { format } from 'date-fns'
import getNameResidential from '../../../libs/getNameResidential'
import "./reportStockPaper.scss"

export default class reportStockPaper extends Component {

  getStatusList = (inputStatusList) => {
    let result = ""

    switch (inputStatusList) {
      case "SELL":
        result = "ขาย"
        break;
      case "BUY":
        result = "ซื้อ"
        break;
      case "CONVERSION_BALANCE":
        result = "ยอดยกมา"
        break;
      case "UNIT":
        result = "สิทธิ์"
        break;
      case "PROJECT":
        result = "เบิก"
        break;
    }

    return <td width="120">{result}</td>
  }

  render() {

    let status = "";

    if(this.props.statusTable === 'all') {
      status = "ทั้งหมด"
    }
    else if(this.props.statusTable === 'buy'){
      status = "ซื้อ"
    }
    else if(this.props.statusTable === 'unit'){
      status = "สิทธิ์"
    }
    else if(this.props.statusTable === 'sell'){
      status = "ขาย"
    }
    else if(this.props.statusTable === 'project'){
      status = "เบิก"
    }

    return (
     _.map(this.props.keycardTransactionExport, (page, indexPage) => {
       return (
         <React.Fragment key={indexPage}>
           <div id="reportStockPaper" className="page">
            <div className="print-interest-report mb-5">
              <div className="subpage">
                <div className="head">
                  <div className="font-weight-bold">
                    <div>{this.props.selfProject.name}</div>
                    <div>รายงานสต็อคและวัสดุสิ้นเปลืองเรียงตามเลขที่</div>
                    <div>สถานะ {status}</div>
                    <div>{`ช่วงเวลา: ${this.props.startDate ? format(this.props.startDate, "DD/MM/YYYY") : "-"} ถึง ${this.props.endDate ? format(this.props.endDate, "DD/MM/YYYY") : "-"}`}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="reportStockPaperBody">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">เลขNo.</th>
                    {this.props.statusTable === "all" && 
                      <th className="text-center">ประเภทรายการ</th>
                    }
                    <th className="text-center">วันที่</th>
                    <th className="text-center">เอกสาร</th>
                    <th className="text-center">วัตถุประสงค์ในการเบิก</th>
                    <th className="text-center">เลขห้อง</th>
                    <th className="text-center">ชื่อผู้รับสินค้า</th>
                    <th className="text-center">เบิกโดย</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(page, (item, index) => {
                    return (
                      <tr key={index}>
                        <td width="100">{item.node.noNumber || "-"}</td>
                        {this.props.statusTable === "all" &&  this.getStatusList(item.node.list)}
                        <td width="100">{format(item.node.date, "DD/MM/YYYY")}</td>
                        <td>{item.node.ref}</td>
                        <td>
                          {item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT"
                            ? "เบิกตามสิทธิ์"
                            : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                              ? "เบิกใช้งานลูกบ้าน"
                              : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                                ? "เบิกใช้งานส่วนกลาง"
                                : "-"
                          }
                        </td>
                        <td>
                          {(item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
                            ? item.node.contact.name
                            :  item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.name || "-"
                          }
                        </td>
                        <td>
                          {item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT" && (item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient  || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient)
                            ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient || "")
                            : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                              ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.lastName || "")
                              : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                                ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                                : (item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
                                  ? getNameResidential(item.node.contact.firstName || "", item.node.contact.lastName || "")
                                  : "-"
                          }
                        </td>
                        <td>{(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName) 
                          ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName|| "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                          : "-"
                        }</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div className="footer d-flex">
              <div className="numberPage">{`${indexPage+1}/${this.props.keycardTransactionExport.length}`}</div>
              <div className="dateFooter">ผู้พิมพ์ {this.props.myUser} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY")} เวลา {format(new Date(), "HH:mm น.")}</div>
            </div>
           </div>
         </React.Fragment>
       )
     })
    )
  }
}
