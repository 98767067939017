import React, { forwardRef, useState } from "react";
import "../../styles/commonArea.scss";
import { format, setHours, setMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import "../../../../libs/styles/customDatePickerWidth.scss";
import i18n from "i18next";
const Rights = ({
  state: {
    right_per_round,
    right_per_round_unit,
    max_right,
    people_per_right,
    round,
    price,
    advance_payment_value,
    advance_payment_unit,
    advance_payment_check,
    reservation_form,
  },
  addRound,
  handleChange,
  subRound,
}) => {
  const [state, setstate] = useState({
    start_round_time: new Date(),
    end_round_time: new Date(),
  });
  const [show, setshow] = useState(false);
  const [tempPeoplePerRight, setTempPeoplePerRight] = useState()

  const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
    <button
      type="button"
      className="date form-control mt-2 booking-form"
      style={{ width: "100%" }}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    >
      {value}
      {!date && (
        <div
          className="calendars"
          style={{
            position: "absolute",
            right: 0,
            marginRight: 8,
          }}
        >
          <span
            style={{
              color: "#0382FA",
              fontFamily: "medium",
            }}
          >
            {i18n.t("newBookingSingha_Create_step1:Choose time")}
          </span>
        </div>
      )}
      {date && (
        <div
          className="calendars"
          style={{
            position: "absolute",
            right: 0,
            marginRight: 8,
          }}
        >
          <img
            className="icon"
            src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
            alt="calendars"
          />
        </div>
      )}
    </button>
  ));

    const onChangeTemp = (e) => {
      const value = e.target.value;
      setTempPeoplePerRight(value);
    };
  
  return (
    <div className="booking-form mb-3  mt-5">
      <div className="switch-languages-div">
        <h2>{i18n.t("newBookingSingha_Create_step1:Rights to book the facility and service fee")}</h2>
      </div>
      <div className="form-row mt-3 ">
        <div className="col-6">
          <label htmlFor="right_per_round">{i18n.t("newBookingSingha_Create_step1:Rights per booking")} <span style={{ color: '#E14558' }} >*</span></label>
          <input
            type="number"
            className="form-control"
            placeholder={i18n.t("newBookingSingha_Create_step1:Rights per booking")}
            id="right_per_round"
            name="right_per_round"
            value={right_per_round}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-3">
          <label htmlFor="right_per_round_unit">{i18n.t("newBookingSingha_Create_step1:Unit")}</label>
          <select
            className="form-control "
            id="right_per_round_unit"
            onChange={handleChange}
            name="right_per_round_unit"
            defaultValue={right_per_round_unit || "1"}
            required
          >
            <option value="hours" disabled={reservation_form === "round"} >{i18n.t("newBookingSingha_Create_step1:Hours")}</option>
            <option value="round" disabled={reservation_form === 'default' || reservation_form === 'free'} >รอบ</option>
            <option value="time"> สิทธิ์การจอง</option>
          </select>
        </div>
      </div>

      <label className="form-check-label mt-3 " htmlFor="max_right">
        {i18n.t("newBookingSingha_Setting:The maximum number of rights for booking this facility")} ({i18n.t("newBookingSingha_Setting:Rights")})
      </label>
      <select
        className="form-control col-6 "
        id="max_right"
        name="max_right"
        onChange={handleChange}
        defaultValue={max_right}
        required
      >
        {/* <option value="3">3 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="5">5 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="7">7 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="10">10 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="15">15 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="20">20 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="30">30 {i18n.t("newBookingSingha_Setting:Rights")}</option> */}
        <option value="1">1 {i18n.t("newBookingSingha_Setting:Right")}</option>
        <option value="2">2 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="3">3 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="4">4 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="5">5 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="6">6 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="7">7 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="8">8 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="9">9 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="10">10 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="11">11 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="12">12 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="13">13 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="14">14 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="15">15 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="16">16 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="17">17 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="18">18 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="19">19 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="20">20 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="21">21 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="22">22 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="23">23 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="24">24 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="25">25 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="26">26 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="27">27 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="28">28 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="29">29 {i18n.t("newBookingSingha_Setting:Rights")}</option>
        <option value="30">30 {i18n.t("newBookingSingha_Setting:Rights")}</option>
      </select>
      <label className="form-check-label mt-3 " htmlFor="people_per_right">
        {i18n.t("newBookingSingha_Create_step1:Number of participants per 1 right")}
      </label>      
      <select
        className="form-control col-6 "
        id="people_per_right"
        name="people_per_right"
        value={!["1", "2", "3", "4", "5", 1, 2, 3, 4, 5].includes(people_per_right) ? "ระบุเอง" : people_per_right}        
        onChange={handleChange}
        required
      >
        <option value="1">1 {i18n.t("newBookingSingha_Setting:Person")}</option>
        <option value="2">2 {i18n.t("newBookingSingha_Setting:Persons")}</option>
        <option value="3">3 {i18n.t("newBookingSingha_Setting:Persons")}</option>
        <option value="4">4 {i18n.t("newBookingSingha_Setting:Persons")}</option>
        <option value="5">5 {i18n.t("newBookingSingha_Setting:Persons")}</option>
        <option value="ระบุเอง">ระบุเอง</option>
      </select>
      {
        (!["1", "2", "3", "4", "5", 1, 2, 3, 4, 5].includes(people_per_right)) &&
        <input 
          type="number"
          name="people_per_right"
          className="form-control col-6 "
          value={tempPeoplePerRight ?? people_per_right}
          onChange={onChangeTemp}
          onBlur={handleChange}
        />
      }

      
      <h3 className="mt-3">{i18n.t("newBookingSingha_Create_step1:Booking period")}</h3>
      <div className="form-check mt-2">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reservation_form"
            id="reservation_form1"
            value="default"
            defaultChecked={reservation_form == "default"}
            onChange={handleChange}
            disabled={right_per_round_unit === "round"}
            required
          />
          <label className="form-check-label" htmlFor="reservation_form1">
            {i18n.t("newBookingSingha_Create_step1:Based on the system default,")}
          </label>
        </div>
        <div className="text-grey mt-1">
          {i18n.t("newBookingSingha_Create_step1:the system default depends on the number of rights per booking.")}
        </div>
      </div>
      <div className="form-check mt-2">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reservation_form"
            id="reservation_form2"
            value="free"
            defaultChecked={reservation_form === "free"}
            onChange={handleChange}
            disabled={right_per_round_unit === "round"}
          />
          <label className="form-check-label text" htmlFor="reservation_form2">
            {i18n.t("newBookingSingha_Create_step1:Independent booking,")}
          </label>
        </div>
        <div className="text-grey mt-1">
          {i18n.t("newBookingSingha_Create_step1:for participants who would like to use their rights to book independently.")}
        </div>
      </div>

      <div className="form-check mt-2">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reservation_form"
            id="reservation_form3"
            value="round"
            defaultChecked={reservation_form === "round"}
            onChange={handleChange}
          />
          <label className="form-check-label text" htmlFor="reservation_form3">
            {i18n.t("newBookingSingha_Create_step2:Custom")}          </label>
        </div>
        <div className="text-grey mt-1">{i18n.t("newBookingSingha_Create_step2:Manually schedule a reservation period")}</div>
        <div className="d-flex  mt-3 ">
          <div>
            <h3>{i18n.t("newBookingSingha_Create_step2:Reservation cycle")}</h3>
          </div>
        </div>
        {show && reservation_form === "round" && (
          <div className="d-flex align-items-end">
            <div className="col-3 customDatePickerWidth">
              <div>{i18n.t("newBookingSingha_Create_step1:Opening time")}</div>
              <DatePicker
                selected={state.start_round_time}
                onChange={(date) =>
                  setstate({ ...state, start_round_time: date })
                }
                // disabled={use_limit_time_check == "true" ? true : false}
                showTimeSelect
                showTimeSelectOnly
                required
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="form-control float-left colorborder"
                customInput={
                  <ButtonDate
                  // disabled={limit_time_status == "true" ? true : false}
                  />
                }
                minTime={setHours(setMinutes(state.end_round_time, 0), 0)}
                maxTime={setHours(
                  setMinutes(
                    state.end_round_time,
                    new Date(state.end_round_time).getMinutes()
                  ),
                  new Date(state.end_round_time).getHours()
                )}
              />
            </div>
            <div className="col-3 customDatePickerWidth">
              <div>{i18n.t("newBookingSingha_Create_step1:Closing time")}</div>
              <DatePicker
                selected={state.end_round_time}
                onChange={(date) =>
                  setstate({ ...state, end_round_time: date })
                }
                // disabled={use_limit_time_check == "true" ? true : false}
                showTimeSelect
                showTimeSelectOnly
                required
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="form-control float-left colorborder"
                customInput={
                  <ButtonDate
                  // disabled={limit_time_status == "true" ? true : false}
                  />
                }
                minTime={setHours(
                  setMinutes(
                    state.start_round_time,
                    new Date(state.start_round_time).getMinutes()
                  ),
                  new Date(state.start_round_time).getHours()
                )}
                maxTime={setHours(setMinutes(state.start_round_time, 59), 23)}
              />
            </div>
            <div className="col-2">
              <button
                type="button"
                className="btn btn-outline-primary form-control"
                onClick={() => {
                  addRound(state.start_round_time, state.end_round_time);
                  setshow(!show);
                }}
              >
                {i18n.t("newBookingSingha_Create_step2:Add")}
              </button>
            </div>
            <div
              className="cursor"
              disabled={reservation_form !== "round"}
              onClick={() => setshow(!show)}
              style={{
                color: "#B3B3B3",
                fontSize: 28,
                marginBottom: 16,
                marginLeft: 16,
              }}
            >
              X
            </div>
          </div>
        )}

        {reservation_form === "round" && (
          <div>
            <ul className="list-group list-group-flush col-md-9 ">
              {round.map((item, index) => {
                return (
                  <li
                    className="list-group-item d-flex justify-content-between col-6 "
                    key={index}
                  >
                    <div className="text-grey">
                      รอบจองที่ {index + 1}:
                      {format(item.start_round_time, " HH:mm - ") +
                        format(item.end_round_time, "HH:mm")}
                    </div>
                    <div
                      className="cursor"
                      disabled={reservation_form !== "round"}
                      onClick={() => subRound(index)}
                      style={{ color: "#B3B3B3" }}
                    >
                      X
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="mt-3">
          <button
            type="button"
            className="btn btn-link button-add"
            style={
              reservation_form !== "round"
                ? { color: "#B3B3B3" }
                : { color: "#0382FA" }
            }
            disabled={reservation_form !== "round"}
            onClick={() => setshow(!show)}
          >
            + {i18n.t("newBookingSingha_Create_step2:Add booking period")}
          </button>
        </div>
      </div>

      <h3 className="mt-3 disabled">{i18n.t("contract_service:Service fee/month")}</h3>
      <div className="form-check mt-2">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="price_check"
            id="price_check1"
            value="true"
            defaultChecked
            onChange={handleChange}
            disabled
          />
          <label className="form-check-label" htmlFor="price_check1">
            {i18n.t("newBookingSingha_Booking:No charge")}
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="price_check"
            id="price_check2"
            value="false"
            onChange={handleChange}
            disabled
          />
          <label className="form-check-label disabled" htmlFor="price_check2">
            {i18n.t("newBookingSingha_Booking:Service charge")}
          </label>
          <div>
            <label htmlFor="price disabled" className="mt-2">
              {i18n.t("newBookingSingha_Create_step2:Service fee per unit (Baht)")}
            </label>
            <input
              // disabled={price_check === "true" ? true : false}
              disabled
              type="number"
              className="form-control col-4 "
              id="price"
              name="price"
              value={price}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>

      <h3 className="mt-3 disabled">{i18n.t("newBookingSingha_Create_step2:Advance payment for confirmation the booking")}</h3>
      <div className="form-check mt-2 ">
        <div>
          <input
            disabled
            className="form-check-input"
            type="radio"
            name="advance_payment_check"
            id="advance_payment_check2"
            value="true"
            defaultChecked
            onChange={handleChange}
          />
          <label
            className="form-check-label disabled"
            htmlFor="advance_payment_check2"
          >
            {i18n.t("newBookingSingha_Create_step2:Advance payment is not required to confirm the booking.")}
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="advance_payment_check"
            id="advance_payment_check2"
            value="false"
            onChange={handleChange}
            disabled
          />
          <label
            className="form-check-label disabled"
            htmlFor="advance_payment_check2"
          >
            {i18n.t("newBookingSingha_Create_step2:Advance payment is required to confirm the booking.")}
          </label>
        </div>
      </div>

      <div className="d-flex flex-row mt-3 ">
        <div className="col-">
          <label className="form-check-label" htmlFor="advance_payment_value">
            <h3 className="disabled" style={{ fontSize: "1rem" }}>
              {i18n.t("newBookingSingha_Create_step2:Date/time for advance payment to confirm the booking")}
            </h3>
          </label>
          <select
            className="form-control "
            id="advance_payment_value"
            name="advance_payment_value"
            // disabled={advance_payment_check === "true" ? true : false}
            defaultValue={advance_payment_value}
            onChange={handleChange}
            disabled
            required
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div className="col-3">
          <label className="form-check-label" htmlFor="advance_payment_unit">
            <h3 className="disabled" style={{ fontSize: "1rem" }}>
              {i18n.t("newBookingSingha_Create_step1:Unit")}
            </h3>
          </label>
          <select
            className="form-control "
            id="advance_payment_unit"
            name="advance_payment_unit"
            defaultValue={advance_payment_unit}
            onChange={handleChange}
            // disabled={advance_payment_check === "true" ? true : false}
            disabled
            required
          >
            <option value="minute"> {i18n.t("newBookingSingha_Create_step1:Minute")}</option>
            <option value="hour"> {i18n.t("newBookingSingha_Create_step1:Hours")}</option>
            <option value="day"> {i18n.t("PageList:Daily")}</option>
            <option value="week"> {i18n.t("developer:Week")}</option>
            <option value="month">{i18n.t("developer:Month")}</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Rights;
