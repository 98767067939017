import React from 'react';
import { Translation } from "react-i18next";
import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../env/environment";
import Swal from "sweetalert2";
import Loading from '../libs/loading'
import Pagination from '../libs/newPagination';
import { QueryRenderer } from "react-relay";
import ComponentPagination from "../libs/componentPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import i18n from 'i18next';
import EmptyTable from '../libs/emptyTable';
import axios from "axios";
import getApiRoot from "../libs/getAPIRoot";

import ReDownloadFileMutation from "./mutation/ReDownloadFile";
import {
    EmailShareButton,
    EmailIcon,
    LineShareButton,
    LineIcon
} from "react-share";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const query = graphql`
    query downloadFileTableQuery(
        $search: String
        $startDate: Date
        $endDate: Date
        $typeReportRef: String
        $status:String
        $first: Int
        $last: Int
    ) {
      allDownloadJob(
        search: $search
        startDate: $startDate
        endDate: $endDate
        typeReportRef: $typeReportRef
        status:$status
        first: $first
        last: $last
      ){
        totalCount
        edges{
            node{
                id
                docNumber
                status
                typeReportRef
                downloadJobRecord{
                    edges{
                        node{
                            id
                            idFile
                            fileName
                            pathFile
                            sizeFile
                            typeFile
                            status
                            downloadWho
                            downloadDate
                            forecastTime
                            added
                            updated
                          }
                      }
                  }
              }
          }
      }
    }

`;
class DownloadFileTable extends ComponentPagination {
    constructor(props) {
        super(props);
        // this.state = getSettingMenuData()
        this.state = {
            useAccount: false,

            status: "",
            startDate: new Date(),
            endDate: new Date(),
            currentDate: new Date(),
            search: "",
            typeReportRef: "",
            re_query: false,

            select1: "",
            select2: "",

            loading: false,

            check_list_all: [],
            check_list_all_status: false,
            re_query: false,
            getLinkToShareMail: "",
            getLinkToShareLine: "",
            showModalShare: false,
        }

        this.getAllListDownloadJob = this.getAllListDownloadJob.bind(this);
        this.selectItemOne = this.selectItemOne.bind(this);
        this.reDownloadFile = this.reDownloadFile.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.state.status !== this.props.status || this.state.re_query !== this.props.state.re_query) {
            this.setState(
                {
                    re_query: this.props.state.re_query,
                    status: this.props.status,
                    loading: false,
                    check_list_all: [],
                    check_list_all_status: false,
                }, () => {
                    this.goFirst();
                }
            );
        }
    }

    formatSize(sizeBytes) {
        const kilo = 1024;
        const mega = kilo * 1024;
        const giga = mega * 1024;

        if (sizeBytes < kilo) {
            return sizeBytes + ' B';
        } else if (sizeBytes < mega) {
            return (sizeBytes / kilo).toFixed(2) + ' KB';
        } else if (sizeBytes < giga) {
            return (sizeBytes / mega).toFixed(2) + ' MB';
        } else {
            return (sizeBytes / giga).toFixed(2) + ' GB';
        }
    }
    formatStatus(status) {
        let text = '-'
        if (status === 'PENDING') {
            text = i18n.t('downloadFile:Pending')
        } else if (status === 'SUCCEED') {
            text = i18n.t('downloadFile:Succeed')
        }
        else if (status === 'FAIL') {
            text = i18n.t('downloadFile:Fail')
        }
        else if (status === 'CANCEL') {
            text = i18n.t('downloadFile:Cancel')
        }
        return text;
    }

    loadingStatusClass(status) {
        let text = "success"
        if (status === 'PENDING') {
            text = 'loading'
        } else if (status === 'SUCCEED') {
            text = 'success'
        }
        else if (status === 'FAIL') {
            text = 'danger'
        }
        return text;
    }


    downloadFile(download_job_record) {

        var token_id = localStorage.getItem("token");
        var header = {
            authorization: `JWT ${token_id}`,
            "Content-Type": "application/json",
        };

        axios.get(`${getApiRoot()}taskdownload/download`, {
            headers: header,
            params: {
                id_download_record: download_job_record.node.id,
            }
        })
            .then((response) => {
                axios.get(response.data.data.s3_path_url_link, {
                    responseType: 'blob'
                }).then((response) => {
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(response.data);
                    a.download = download_job_record.node.fileName
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
            })
            .catch(error => {
                console.log(error);
            })

    };

    shareDocument(download_job_record) {
        let token_id = localStorage.getItem("token");
        let header = {
            authorization: `JWT ${token_id}`,
            "Content-Type": "application/json",
        };

        axios.get(`${getApiRoot()}taskdownload/download`, {
            headers: header,
            params: {
                id_download_record: download_job_record.node.id,
            }
        })
            .then((response) => {
                axios.get(response.data.data.s3_path_url_link).then((res) => {
                    this.setState({
                        getLinkToShareMail: `${response.data.data.filename}\n${res.config.url}\n`,
                        getLinkToShareLine: `${res.config.url}\n`,
                    }, () => this.handleToggleModal(true))
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    getAllListDownloadJob(props) {
        let check_list = _.clone(this.state.check_list_all)
        if (this.state.check_list_all_status) {
            this.setState({
                check_list_all: [],
                check_list_all_status: false,
            }, () => {
                this.props.selectItem(check_list);
            })
        } else {
            props.forEach(element => {
                if (_.find(check_list, (e) => e === element.node.id) === undefined) {
                    check_list.push(element.node.id)
                }
            });

            this.setState({
                check_list_all: check_list,
                check_list_all_status: true,
            }, () => {
                this.props.selectItem(check_list);
            })
        }
    }
    selectItemOne(id) {
        let check_list = _.clone(this.state.check_list_all)

        if (_.find(check_list, (e) => e === id) === undefined) {
            check_list.push(id)
        } else {
            check_list.pop(id)
        }

        this.setState({ check_list_all: check_list }, () => {
            this.props.selectItem(check_list);
        })
    }

    reDownloadFile(download_job_record) {
        if (download_job_record) {
            Swal.fire({
                imageUrl: `/images/iconAlerts/iconAlert-Blue4x.png`,
                imageWidth: 100,
                html: `
                    <label className='swal2-title-custom text-center'>${i18n.t('downloadFile:Do you want to reDownload document againe')}</label>
                `,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: i18n.t('Allaction:yes'),
                cancelButtonText: i18n.t('Allaction:cancel'),
                customClass: {
                    cancelButton: 'swal-btn-gray-noborder',
                    confirmButton: 'swal-btn-primary-noborder',
                    container: 'swal2-container-custom',
                    content: 'swal2-content-custom',
                }
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        loading: true,
                    }, async () => {

                        let input = {
                            downloadRecordId: download_job_record.node.id,
                        };
                        await ReDownloadFileMutation(
                            input,
                            this.props.onSuccess,
                            this.props.onError
                        );

                    });
                }
            });
        }

    }

    handleToggleModal(statusModal) {
        this.setState({
            showModalShare: statusModal
        })
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{ use_cache: false }}
                variables={{
                    search: this.props.state.search,
                    startDate: this.props.state.startDate ? format(this.props.state.startDate, "YYYY-MM-DD") : null,
                    endDate: this.props.state.startDate ? format(this.props.state.endDate, "YYYY-MM-DD") : null,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status === 'all' ? '' : this.props.status,
                    re_query: this.props.state.re_query,
                }}

                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return (
                            props?.allDownloadJob?.edges ?
                                <React.Fragment>
                                    <Translation>
                                        {t =>
                                                <div className="">
                                                    <div className="col">
                                                        <div className="table-responsive fade-up card">
                                                            <table className="table table-hover">
                                                                <thead className='thead-light text-center'>
                                                                    <tr>

                                                                        <th>
                                                                            <input type={"checkbox"}
                                                                                onChange={() => this.getAllListDownloadJob(props.allDownloadJob.edges)}
                                                                                checked={this.state.check_list_all_status}
                                                                            />
                                                                        </th>

                                                                        <th>{t('downloadFile:File name')}</th>
                                                                        <th>{t('downloadFile:Number Document name')}</th>
                                                                        <th>{t('downloadFile:File size')}</th>
                                                                        <th>{t('downloadFile:Report type')}</th>
                                                                        <th>{t('downloadFile:File type')}</th>
                                                                        <th>{t('downloadFile:Download date')}</th>
                                                                        <th>{t('downloadFile:ForecastTime Finished')}</th>
                                                                        <th>{t('downloadFile:Download status')}</th>
                                                                        <th>{t('downloadFile:Download by')}</th>
                                                                        <th />

                                                                    </tr>
                                                                </thead>

                                                                <tbody className='text-center useAll colorUse'>
                                                                    {props.allDownloadJob.edges.map((all_download_job, index) => {
                                                                        return all_download_job.node.downloadJobRecord.edges.map(
                                                                            (download_job_record, index2) => {
                                                                                let timeDiff = new Date().getTime() - new Date(download_job_record.node.downloadDate).getTime()
                                                                                let daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

                                                                                let doc_number = "-"
                                                                                let forecastTime = new Date(download_job_record.node.forecastTime).getTime() + 2 * 60000
                                                                                forecastTime = new Date(forecastTime)

                                                                                // let mytime = new Date(forecastTime.getTime() + 5 * 60000)
                                                                                if (all_download_job.node.typeReportRef === 'invoice') {
                                                                                    doc_number = download_job_record.node?.fileName?.split("_");
                                                                                }
                                                                                // console.log(doc_number);
                                                                                return (
                                                                                    <tr key={download_job_record.node.id}>
                                                                                        <td>
                                                                                            {index2 === 0 && all_download_job.node.status === 'SUCCEED' &&
                                                                                                < input type={"checkbox"}
                                                                                                    onChange={() => this.selectItemOne(all_download_job.node.id)}
                                                                                                    checked={this.state?.check_list_all?.includes(all_download_job.node.id)}
                                                                                                />
                                                                                            }

                                                                                            {/* { index2 === 0 &&
                                                                                                        <input type="checkbox"
                                                                                                        onChange={() => this.appendToCheckList(all_download_job.node.id)}
                                                                                                        checked={this.checkList(all_download_job.node.id)}
                                                                                                        />
                                                                                                    } */}
                                                                                        </td>

                                                                                        <td>
                                                                                            <span>{all_download_job.node.docNumber}</span>
                                                                                        </td>

                                                                                        <td>
                                                                                            <span>{doc_number && doc_number !== '-' ? doc_number[0] + " - " + doc_number[1] : "-"}</span>
                                                                                        </td>

                                                                                        <td>{download_job_record.node.sizeFile ? this.formatSize(download_job_record.node.sizeFile) : "-"}</td>

                                                                                        <td>{t(`downloadFile:${all_download_job.node.typeReportRef}`)}</td>

                                                                                        <td className='img'>
                                                                                            {
                                                                                                download_job_record.node.typeFile === 'pdf' &&
                                                                                                <img className='imgW24 mt--4'
                                                                                                    src={process.env.PUBLIC_URL + `/images/typeFiles/${download_job_record.node.typeFile}Icon.png`} />
                                                                                            }
                                                                                            {
                                                                                                download_job_record.node.typeFile === 'excel' &&
                                                                                                <img className='imgW24 mt--4'
                                                                                                    src={process.env.PUBLIC_URL + `/images/typeFiles/xlsIcon.png`} />
                                                                                            }
                                                                                            {download_job_record.node.typeFile?.toUpperCase()}</td>

                                                                                        <td>{download_job_record.node.downloadDate ? format(download_job_record.node.downloadDate, 'DD/MM/YYYY HH:mm:ss', { locale: thLocale }) : '-'}</td>
                                                                                        <td>{download_job_record.node.forecastTime ? format(forecastTime, 'DD/MM/YYYY HH:mm:ss', { locale: thLocale }) : '-'}</td>
                                                                                        <td>
                                                                                            <div>
                                                                                                <label className={`badge badge-${download_job_record.node.status !== "CANCEL" ? this.loadingStatusClass(download_job_record.node.status) : 'pening'}`} style={{ width: 162 }} >
                                                                                                    {download_job_record.node.status !== "PENDING" && <span className={download_job_record.node.status === "CANCEL" ? `text_808080` : ""}>{this.formatStatus(download_job_record.node.status)}</span>}
                                                                                                    {download_job_record.node.status === "PENDING" && <img src={process.env.PUBLIC_URL + '/images/icons/alert/loadinggif.gif'} style={{ width: 162, height: 26, marginLeft: -17 }} />}
                                                                                                </label>
                                                                                                {download_job_record.node.status === "PENDING" && <br />}
                                                                                                {download_job_record.node.status === "PENDING" && <span>{this.formatStatus(download_job_record.node.status)}</span>}
                                                                                            </div>
                                                                                        </td>

                                                                                        <td>{download_job_record.node.downloadWho ? download_job_record.node.downloadWho : '-'}</td>

                                                                                        <td className="d-flex flex-row">
                                                                                            {download_job_record.node.status === "SUCCEED" &&
                                                                                                <>
                                                                                                    <button className="btn" onClick={() => this.downloadFile(download_job_record)}>
                                                                                                        <img
                                                                                                            src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                                                            alt="back"
                                                                                                            className="download-file-icon" />
                                                                                                    </button>
                                                                                                    <button className="btn" onClick={() => this.shareDocument(download_job_record)}>
                                                                                                        <img
                                                                                                            src={process.env.PUBLIC_URL + '/images/icons/icons8-share.png'}
                                                                                                            alt="back"
                                                                                                            className="download-file-icon" />
                                                                                                    </button>
                                                                                                </>
                                                                                            }

                                                                                            {download_job_record.node.status === "FAIL" &&
                                                                                                <button className="btn" onClick={() => this.reDownloadFile(download_job_record)}
                                                                                                    disabled={this.state.loading}
                                                                                                >
                                                                                                    {this.state.loading ?
                                                                                                        <span>
                                                                                                            <span className="spinner-border spinner-border-sm align-middle mr-2" /></span>
                                                                                                        :
                                                                                                        <span>
                                                                                                            <img
                                                                                                                src={process.env.PUBLIC_URL + '/images/icons/updating2.png'}
                                                                                                                alt="back"
                                                                                                                className="download-file-icon" />
                                                                                                        </span>
                                                                                                    }
                                                                                                </button>
                                                                                            }

                                                                                            {download_job_record.node.status === "PENDING" && daysDiff > 0 &&
                                                                                                <button className="btn" onClick={() => this.reDownloadFile(download_job_record)}
                                                                                                    disabled={this.state.loading}
                                                                                                >
                                                                                                    {this.state.loading ?
                                                                                                        <span>
                                                                                                            <span className="spinner-border spinner-border-sm align-middle mr-2" /></span>
                                                                                                        :
                                                                                                        <span>
                                                                                                            <img
                                                                                                                src={process.env.PUBLIC_URL + '/images/icons/updating2.png'}
                                                                                                                alt="back"
                                                                                                                className="download-file-icon" />
                                                                                                        </span>
                                                                                                    }
                                                                                                </button>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        )
                                                                    })

                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {/* Modal share document */}
                                                        <Modal show={this.state.showModalShare} onHide={() => this.handleToggleModal(false)}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>{t('downloadFile:Share Document')}</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <EmailShareButton className='mr-2' url={this.state.getLinkToShareMail}>
                                                                    <EmailIcon size={40} onClick={() => this.handleToggleModal(false)} />
                                                                </EmailShareButton>
                                                                <LineShareButton className='mr-2' url={this.state.getLinkToShareLine}>
                                                                    <LineIcon size={40} onClick={() => this.handleToggleModal(false)} />
                                                                </LineShareButton>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={() => this.handleToggleModal(false)}>
                                                                    {t('downloadFile:Close')}
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                        <div className="row">
                                                            <Pagination
                                                                changePage={this.changePage}
                                                                first={this.state.first}
                                                                last={this.state.last}
                                                                totalCount={props.allDownloadJob.totalCount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </Translation>
                                </React.Fragment>
                                :
                                props?.allDownloadJob?.edges.length === 0 ?
                                    <EmptyTable />
                                    :
                                    <Loading />
                        )
                    }
                }
                } />

        );
    }
}

export default DownloadFileTable;
