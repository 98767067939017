import { format } from 'date-fns';
import i18next from 'i18next';
import _ from 'lodash';
import Swal from "sweetalert2";
import upload from '../../libs/upload';

const callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
        let res = await upload("/" + path, file);
        resolve(res);
    });
};


export default function onUploadFile(e, fileState, pathname, callback, postToAllSite) {
    let event = _.cloneDeep(e);
    // let value = event.target.value
    let files = event.currentTarget.files[0];
    if (files) {
        // 20 MB
        if (files?.size > 20971520) {
            Swal.fire(i18next.t("Allaction:Unsuccessful "), `${i18next.t("Allaction:Please upload a new file as it exceeds the specified size of")} 20 MB`, 'error')
        }
        // else if (files?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        //     Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .xlsx ${i18next.t("Allaction:Only")}`, 'error')
        // }
        else {
            let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
            let fileOldList = _.cloneDeep(fileState);

            if (postToAllSite === "postToAllSite") {
                fileOldList.push({ id: "", fileName: new_file.name, fileUpload: files, viewPtath: files, status: "", file: files })
                callback(fileOldList)
            } else {
                callUpload(pathname, new_file).then((data) => {
                    let dese = pathname + "/" + new_file.name
                    fileOldList.push({ id: "", fileName: new_file.name, fileUpload: dese, viewPtath: data.location, status: "" })
                    callback(fileOldList)
                });
            }


        }
    }
}


