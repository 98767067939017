/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialHistoryCardType = "DRIVING" | "GOVERNMENT_OFFICER" | "ID_CARD" | "OTHER" | "PASSPORT" | "UNSPECIFIED" | "%future added value";
export type ResidentialHistoryGender = "FEMALE" | "MALE" | "OTHER" | "UNSPECIFIED" | "%future added value";
export type ResidentialHistoryStatus = "LIVEING" | "QUIT" | "%future added value";
export type ResidentialHistoryType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type viewResidentialHistoryDetailQueryVariables = {|
  id: string
|};
export type viewResidentialHistoryDetailQueryResponse = {|
  +residentialHistory: ?{|
    +nameResidential: string,
    +residential: ?{|
      +id: string,
      +name: string,
    |},
    +tenant: ?{|
      +id: string,
      +firstName: string,
      +lastName: ?string,
    |},
    +nameTitle: ?string,
    +firstName: ?string,
    +lastName: ?string,
    +idCardNumber: ?string,
    +dateOfBirth: ?any,
    +gender: ResidentialHistoryGender,
    +image: string,
    +email: ?string,
    +countries: string,
    +phone: ?string,
    +phone2: ?string,
    +channel: ?string,
    +acceptPolicy: boolean,
    +acceptTermOfUse: boolean,
    +acceptMarketing: boolean,
    +acceptPartner: boolean,
    +acceptOtp: boolean,
    +firstNameEng: ?string,
    +lastNameEng: ?string,
    +occupation: ?string,
    +tenantStatus: ?string,
    +ethnicity: string,
    +cardType: ResidentialHistoryCardType,
    +note: ?string,
    +dateIn: ?any,
    +dateOut: ?any,
    +type: ResidentialHistoryType,
    +status: ResidentialHistoryStatus,
    +liveing: ?any,
    +quit: ?any,
    +remark: ?string,
    +logVerifyIdentityDocument: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileName: string,
          +fileUpload: ?string,
        |}
      |}>
    |},
    +logPassport: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileName: string,
          +fileUpload: ?string,
        |}
      |}>
    |},
    +logLease: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileName: string,
          +fileUpload: ?string,
        |}
      |}>
    |},
    +logRentOwnerLease: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileName: string,
          +fileUpload: ?string,
        |}
      |}>
    |},
    +logDailyForm: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileName: string,
          +fileUpload: ?string,
        |}
      |}>
    |},
  |}
|};
export type viewResidentialHistoryDetailQuery = {|
  variables: viewResidentialHistoryDetailQueryVariables,
  response: viewResidentialHistoryDetailQueryResponse,
|};
*/


/*
query viewResidentialHistoryDetailQuery(
  $id: ID!
) {
  residentialHistory(id: $id) {
    nameResidential
    residential {
      id
      name
    }
    tenant {
      id
      firstName
      lastName
    }
    nameTitle
    firstName
    lastName
    idCardNumber
    dateOfBirth
    gender
    image
    email
    countries
    phone
    phone2
    channel
    acceptPolicy
    acceptTermOfUse
    acceptMarketing
    acceptPartner
    acceptOtp
    firstNameEng
    lastNameEng
    occupation
    tenantStatus
    ethnicity
    cardType
    note
    dateIn
    dateOut
    type
    status
    liveing
    quit
    remark
    logVerifyIdentityDocument {
      edges {
        node {
          id
          fileName
          fileUpload
        }
      }
    }
    logPassport {
      edges {
        node {
          id
          fileName
          fileUpload
        }
      }
    }
    logLease {
      edges {
        node {
          id
          fileName
          fileUpload
        }
      }
    }
    logRentOwnerLease {
      edges {
        node {
          id
          fileName
          fileUpload
        }
      }
    }
    logDailyForm {
      edges {
        node {
          id
          fileName
          fileUpload
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameResidential",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ResidentialNode",
  "kind": "LinkedField",
  "name": "residential",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantNode",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameTitle",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "idCardNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateOfBirth",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gender",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countries",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone2",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "channel",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptPolicy",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptTermOfUse",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptMarketing",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptPartner",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptOtp",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstNameEng",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastNameEng",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "occupation",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tenantStatus",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ethnicity",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardType",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateIn",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateOut",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "liveing",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quit",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v37 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fileName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fileUpload",
    "storageKey": null
  }
],
v38 = {
  "alias": null,
  "args": null,
  "concreteType": "LogVerifyIdentityDocumentNodeConnection",
  "kind": "LinkedField",
  "name": "logVerifyIdentityDocument",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LogVerifyIdentityDocumentNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LogVerifyIdentityDocumentNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v37/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "concreteType": "LogPassportNodeConnection",
  "kind": "LinkedField",
  "name": "logPassport",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LogPassportNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LogPassportNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v37/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "concreteType": "LogLeaseNodeConnection",
  "kind": "LinkedField",
  "name": "logLease",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LogLeaseNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LogLeaseNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v37/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "concreteType": "LogRentOwnerLeaseNodeConnection",
  "kind": "LinkedField",
  "name": "logRentOwnerLease",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LogRentOwnerLeaseNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LogRentOwnerLeaseNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v37/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "concreteType": "LogDailyFormNodeConnection",
  "kind": "LinkedField",
  "name": "logDailyForm",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LogDailyFormNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LogDailyFormNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v37/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "viewResidentialHistoryDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialHistoryNode",
        "kind": "LinkedField",
        "name": "residentialHistory",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "viewResidentialHistoryDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialHistoryNode",
        "kind": "LinkedField",
        "name": "residentialHistory",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9bb6ab3e8c08b72161d3e47f34688f02",
    "id": null,
    "metadata": {},
    "name": "viewResidentialHistoryDetailQuery",
    "operationKind": "query",
    "text": "query viewResidentialHistoryDetailQuery(\n  $id: ID!\n) {\n  residentialHistory(id: $id) {\n    nameResidential\n    residential {\n      id\n      name\n    }\n    tenant {\n      id\n      firstName\n      lastName\n    }\n    nameTitle\n    firstName\n    lastName\n    idCardNumber\n    dateOfBirth\n    gender\n    image\n    email\n    countries\n    phone\n    phone2\n    channel\n    acceptPolicy\n    acceptTermOfUse\n    acceptMarketing\n    acceptPartner\n    acceptOtp\n    firstNameEng\n    lastNameEng\n    occupation\n    tenantStatus\n    ethnicity\n    cardType\n    note\n    dateIn\n    dateOut\n    type\n    status\n    liveing\n    quit\n    remark\n    logVerifyIdentityDocument {\n      edges {\n        node {\n          id\n          fileName\n          fileUpload\n        }\n      }\n    }\n    logPassport {\n      edges {\n        node {\n          id\n          fileName\n          fileUpload\n        }\n      }\n    }\n    logLease {\n      edges {\n        node {\n          id\n          fileName\n          fileUpload\n        }\n      }\n    }\n    logRentOwnerLease {\n      edges {\n        node {\n          id\n          fileName\n          fileUpload\n        }\n      }\n    }\n    logDailyForm {\n      edges {\n        node {\n          id\n          fileName\n          fileUpload\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3df70214f446da5db918eea5481343d6';

module.exports = node;
