import React, { Component } from 'react';
import './style/creditNoteShowMultiInvoice.scss'

const $ = window.jQuery;

class CreditNoteShowMultiInvoice extends Component {

    constructor(props) {
        super(props);
        this.onClickOpenModal = this.onClickOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal');
    }

    onClickOpenModal() {
        $(this.modal).modal('show');

    }

    onCloseModal() {
        $(this.modal).modal('hide');

    }

    render() {
        return (
            <React.Fragment>
                <div id="creditNote_detail_contact">

                    <div className="input-group mb-3 colorUse notKanit">
                        {this.props.on_text ?
                            // แก้ตรงนี้นะ
                            <React.Fragment>
                                <div style={{ float: 'left' }}>แบบหลายรายการ</div>
                                <div className='ml-2 cursor text_1567B4' style={{ float: 'right' }} onClick={this.onClickOpenModal}>
                                    <u>ดูข้อมูล</u>
                                </div>


                            </React.Fragment>
                            :
                            <React.Fragment>
                                <input type="text" className="form-control inputDetailContactLeft" value={"แบบหลายรายการ"}
                                    disabled />
                                <div className="input-group-append inputDetailContactRight">
                                    <span className="input-group-text" id="basic-addon2"><u onClick={this.onClickOpenModal}
                                        className="textDetail">ดูข้อมูล</u></span>
                                </div>
                            </React.Fragment>
                        }

                    </div>

                    <div className="modal fade bd-example-modal-lg" id="modal_create_product" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal => this.modal = modal}>
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="col" style={{ paddingLeft: 0 }}>
                                        <h5 className="modal-title">
                                            เลขที่ใบแจ้งหนี้
                                        </h5>
                                    </div>

                                    <button type="button" className="close" onClick={this.onCloseModal}
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="col-12">
                                        <div className="table-responsive fade-up">
                                            <table className="table table-hover mt-2">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th className="text-center">No.</th>
                                                        <th className="text-center">วันที่ใบแจ้งหนี้</th>
                                                        <th className="text-center">เลขที่ใบแจ้งหนี้</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.invoice_object.map((invoice, index) => {
                                                        return (
                                                            <tr key={index + 1}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">{invoice.issuedDate}</td>
                                                                <td className="text-center">{invoice.docNumber}</td>
                                                            </tr>
                                                        )

                                                    })

                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default CreditNoteShowMultiInvoice
