import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import PayList from './payList';
import PayCreate from './pay_create/payCreate';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import PayDetail from "./payDetail";

class Purchase extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={PayList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status`} component={PayCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id?/:ref_transaction?`} component={PayDetail}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Purchase;