import {
    commitMutation,

} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";


const mutation = graphql`
    mutation deleteAssetImageMutation($input: DeleteAssetImageInput!) {
        deleteAssetImage(input: $input) {
            deleteAssetImage{
                id
            }
        }
    }
`;

export default function DeleteAssetImage(variables, callback, error_callback) {

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
