import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";

const query = graphql`
    query queryAssetQuery {
        viewer {
            allChartOfAccount {
                edges {
                    node {
                        id chartOfAccountCode name
                    }
                }
            }
        }
    }
`;


const queryAsset = () => {
    return new Promise((resolve, reject) => {
        fetchQuery(environment, query).then(data => {
            resolve(data)
        });
    });
  
  };
  
  export default queryAsset;