import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Translation} from "react-i18next";



class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/finance/cheque-deposit/list/all">
                               <Translation>{t=><>{t("cheque:All")}</>}</Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/finance/cheque-deposit/list/not_deposit">
                                <Translation>
                                    {
                                        t => <span>{t('cheque:cheque_not_deposit')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/accounting/finance/cheque-deposit/list/deposit">
                                <Translation>
                                    {
                                        t => <span>{t('cheque:cheque_deposit')}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;