/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type addonTopMenuNavigationQueryVariables = {||};
export type addonTopMenuNavigationQueryResponse = {|
  +selfProject: ?{|
    +useAccount: boolean
  |}
|};
export type addonTopMenuNavigationQuery = {|
  variables: addonTopMenuNavigationQueryVariables,
  response: addonTopMenuNavigationQueryResponse,
|};
*/


/*
query addonTopMenuNavigationQuery {
  selfProject {
    useAccount
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useAccount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "addonTopMenuNavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "addonTopMenuNavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ba65e3e3e598838e405ea5729c54aa4",
    "id": null,
    "metadata": {},
    "name": "addonTopMenuNavigationQuery",
    "operationKind": "query",
    "text": "query addonTopMenuNavigationQuery {\n  selfProject {\n    useAccount\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b76c02c47eb4f880533e8417a42d827';

module.exports = node;
