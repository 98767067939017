/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type navigationAdvanceQueryVariables = {||};
export type navigationAdvanceQueryResponse = {|
  +getNotificationAdvance: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>
|};
export type navigationAdvanceQuery = {|
  variables: navigationAdvanceQueryVariables,
  response: navigationAdvanceQueryResponse,
|};
*/


/*
query navigationAdvanceQuery {
  getNotificationAdvance {
    type
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationType",
    "kind": "LinkedField",
    "name": "getNotificationAdvance",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "navigationAdvanceQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "navigationAdvanceQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3ab7512d23a59059325be7d80daa2a8a",
    "id": null,
    "metadata": {},
    "name": "navigationAdvanceQuery",
    "operationKind": "query",
    "text": "query navigationAdvanceQuery {\n  getNotificationAdvance {\n    type\n    count\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e223781b0b5bd4c47ba2ba48e069b8e1';

module.exports = node;
