import React, {Component} from 'react';
import './style/bankTransferCreateModal.scss'
import {fetchQuery, commitMutation} from "relay-runtime";
import environment from "../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import SearchSelect from "../../../libs/searchSelect";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import {format} from "date-fns";
import { Translation } from 'react-i18next';
import i18n from 'i18next';
import i18next from 'i18next';

const $ = window.jQuery;
const query = graphql`
    query bankTransferCreateModalQuery{
        bankAccountViewer {
            allBankAccount(status:true) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        branch
                        status
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation bankTransferCreateModalMutation($input: CreateBankTransferInput!){
        createBankTransfer(input: $input){
            ok
            warningText
        }
    }
`;

class BankTransferCreateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading : false,
            error: false,
            success: false,
            transfer_out: '',
            transfer_in: '',
            amount:'',
            fee: '',
            date: new Date(),
            description: '',
            allBankAccountList:[],
            selectTransferBank:[],
            redirectToList: false,
            signreq:Math.random().toString(36)
        };

        this.onClickOpenModal = this.onClickOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.clearState = this.clearState.bind(this);
        this.queryBankAccount = this.queryBankAccount.bind(this);
    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal',()=>{
            this.clearState();
            if (this.state.redirectToList) {
                this.props.changePage();
                this.props.refreshQuery();
            }
             this.setState({loading: false, redirectToList: false});
        });
    }

    onClickOpenModal() {
        $(this.modal).modal('show');
        this.queryBankAccount();
    }

    queryBankAccount() {
        fetchQuery(environment, query, {}).then(data => {
            if (data.bankAccountViewer.allBankAccount) {
                this.setState({
                    allBankAccountList: data.bankAccountViewer.allBankAccount.edges,
                    selectTransferBank: data.bankAccountViewer.allBankAccount.edges,
                })
            }
        });
    }

    onCloseModal() {
        $(this.modal).modal('hide');
        this.setState({redirectToList: true});
    }

    handleInputChange(e) {
        if(e.target.name === "transfer_out"){
            this.setState({
                selectTransferBank: this.state.allBankAccountList.filter(re => re.node.id !== e.target.value),
                transfer_in: '',
            })
        }
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.state.transfer_in !== this.state.transfer_out) {
            this.setState({loading: true});
            let variables = {
                input: {
                    transferOut: this.state.transfer_out,
                    transferIn: this.state.transfer_in,
                    amount: this.state.amount,
                    fee: this.state.fee,
                    date: format(this.state.date, 'YYYY-MM-DD'),
                    description: this.state.description,
                    clientMutationId:"BankTC"+this.state.signreq,
                }
            };
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        this.setState({loading: false});
                        if (response.createBankTransfer.ok) {
                            Swal.fire(i18n.t("bank_transfer:Save successfully"), '', 'success');
                            this.clearState();
                            this.onCloseModal();
                        } else {
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createBankTransfer.warningText, 'warning')
                        }
                    },
                    onError: (err) => {
                        Swal.fire('Error!', i18n.t("bank_transfer:Please try again"), 'warning')
                        // Swal.fire('Error!', response.createBankTransfer.warningText, 'warning')
                    },
                },
            )
        } else {
            Swal.fire(i18n.t("bank_transfer:Save unsuccessfully"), i18n.t("bank_transfer:Please check your account again"), 'warning')
        }
    }

    clearState() {
        this.setState({
            transfer_out: '',
            transfer_in: '',
            amount:'',
            fee: '',
            date: new Date(),
            description: '',
        });
    }

    render() {
        return (
            <React.Fragment>
                <Translation>
                    {t=>
                    <button type="button" className="btn btn-primary add" style={{float: "right"}}
                            onClick={this.onClickOpenModal}>
                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                        {t("bank_transfer:Create transfer between account")}
                    </button>}
                </Translation>

                <div className="modal fade bd-example-modal-lg" id="bank-transfer-create-model" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal => this.modal = modal}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="col">
                                    <div className="row">
                                        <Translation>
                                            {t=>
                                            <h5 className="modal-title">
                                                {t("bank_transfer:Create transfer between account")}
                                            </h5>}
                                        </Translation>
                                    </div>
                                </div>

                                <button type="button" className="close" onClick={this.onCloseModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <form id="form-add" onSubmit={this.onSubmit}>
                                    {this.state.error && <div className="alert alert-danger text-left" role="alert">
                                        ไม่สามารถทำรายการได้</div>
                                    }
                                    {this.state.loading && <div className="alert alert-primary text-left" role="alert">
                                        กำลังบันทึก</div>
                                    }
                                    {this.state.success && <div className="alert alert-primary text-left" role="alert">
                                        บันทึกเรียบร้อย</div>
                                    }

                                    <div className="row">
                                        <div className="col-2">
                                            <Translation>
                                                {t=>
                                                <label htmlFor="InputBankTransferOut"
                                                       className="col-form-label">
                                                    {t("bank_transfer:Transfer from account")}
                                                </label>}
                                            </Translation>
                                        </div>
                                        <div className="col text-left" >
                                            <Translation>
                                                {t=>
                                                <SearchSelect onChange={this.handleInputChange}
                                                              value={this.state.transfer_out}
                                                              name="transfer_out"
                                                              placeholder={t("bank_transfer:Transfer from account")}
                                                              queryObject={this.state.allBankAccountList}
                                                              keyOfValue="id" require={true}
                                                              keyOfLabel="bankName:accountType:accountNumber"/>}
                                            </Translation>
                                        </div>
                                        <div className="col-3"/>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <Translation>
                                                {t=>
                                                <label htmlFor="InputBankTransferIn"
                                                       className="col-form-label">
                                                    {t("bank_transfer:Transfer to account")}
                                                </label>}
                                            </Translation>
                                        </div>
                                        <div className="col text-left" >
                                            <Translation>
                                                {t=>
                                                <SearchSelect onChange={this.handleInputChange}
                                                              value={this.state.transfer_in}
                                                              name="transfer_in"
                                                              placeholder={t("bank_transfer:Transfer to account")}
                                                              queryObject={this.state.selectTransferBank}
                                                              keyOfValue="id" require={true}
                                                              keyOfLabel="bankName:accountType:accountNumber"/>}
                                            </Translation>
                                        </div>
                                        <div className="col-3"/>
                                    </div>

                                    <div className="row mt-3">
                                        <Translation>
                                            {t=>
                                            <label htmlFor="InputBankTransferAmount"
                                                   className="col-2 col-form-label">
                                                {t("bank_transfer:Amount")}
                                            </label>}
                                        </Translation>
                                        <div className="col">
                                            <input type="number" className="form-control" id="InputBankTransferAmount"
                                                   value={this.state.amount} onChange={this.handleInputChange}
                                                   name="amount" required/>
                                        </div>
                                        <div className="col-6"/>
                                    </div>

                                    <div className="row mt-3">
                                        <Translation>
                                            {t=>
                                            <label htmlFor="InputBankTransferFee"
                                                   className="col-2 col-form-label">
                                                {t("bank_transfer:Fees")}
                                            </label>}
                                        </Translation>
                                        <div className="col">
                                            <input type="number" className="form-control" id="InputBankTransferFee"
                                                   value={this.state.fee} onChange={this.handleInputChange}
                                                   name="fee"/>
                                        </div>
                                        <div className="col-6"/>
                                    </div>

                                    <div className="row mt-3">
                                        <Translation>
                                            {t=>
                                            <label htmlFor="InputBankTransferDate"
                                                   className="col-2 col-form-label">
                                                {t("bank_transfer:Date")}
                                            </label>}
                                        </Translation>
                                        <div className="col" >
                                            <DatePickerAdapter
                                                name="date" className="form-control"
                                                selected={this.state.date}
                                                onChange={this.handleInputChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-3"/>
                                    </div>

                                    <div className="row mt-3">
                                        <Translation>
                                            {t=>
                                            <label htmlFor="InputBankTransferDescription"
                                                   className="col-2 col-form-label">
                                                {t("bank_transfer:Note")}
                                            </label>}
                                        </Translation>
                                        <div className="col" >
                                           <textarea className="form-control"
                                                     rows="3" name="description" value={this.state.description}
                                                     onChange={this.handleInputChange}/>
                                        </div>
                                        <div className="col-3"/>
                                    </div>
                                </form>
                            </div>

                            <Translation>
                                {t=>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary add" onClick={this.onCloseModal}>{t("bank_transfer:Cancel")}</button>
                                    <button type="submit" className="btn btn-primary add" form="form-add" disabled={this.state.loading}>
                                        {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                        {t("bank_transfer:Save")}
                                    </button>
                                </div>}
                            </Translation>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default BankTransferCreateModal;
