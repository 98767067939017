import React from "react";
import DatePicker from "react-datepicker";
import "../../styles/commonArea.scss";
import './commonArea.scss'
import { Translation } from "react-i18next";

const AllowToBooking = ({
  HandleOnChange,
  state: {
    allowBookingInTime,
    allowBookingStartTime,
    allowBookingEndTime,
  },
  onChangeTime
}) => {
  return (
    <Translation>
      {t =>
        <div className="booking-form mt-4 mb-5 claim">
          <div className="switch-languages-div d-flex justify-content-between create usedAll">
            <p className="headerLabel24 fw-400">{t("newBookingSingha_Create_step2:Reservations are only allowed during business hours")}</p>
          </div>

          <div className="claim">
            <div className="create btnSwitch mt-3" style={{ display: 'flex' , alignItems: 'center'}}>
              <label className="switch mt-2 mr-3" style={{ width: 43, height: 20 }}>
                <input type="checkbox"
                  name="allowBookingInTime"
                  defaultChecked={allowBookingInTime}
                  onChange={HandleOnChange}
                />
                <span className={allowBookingInTime ? "sliderNew round selected" : "sliderNew round"}></span>
              </label>
              <label className="headerLabel18 fw-400" style={{width: '80%'}}>{t("newBookingSingha_Create_step2:Unable to make reservations outside of the designated business hours. Please only make reservations during the business hours.")}</label>
            </div>
          </div>

          {
            allowBookingInTime &&
            <div style={{ marginLeft: 50 }} >
              <div className="text-grey label16 fw-300 mt-1 ml-1">{t("newBookingSingha_Create_step2:Specify the start and end period of this facility")}</div>
              <div>
                {/* // Time Start */}
                <div style={{ width: 192 }} className="float-left usedAll">
                  <DatePicker
                    value={new Date("2023-01-01T" + allowBookingStartTime).getTime()}
                    selected={new Date("2023-01-01T" + allowBookingStartTime).getTime()}
                    onChange={(time) => onChangeTime(`startTime`, `allowBookingStartTime`, time)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-control searchDate gray"
                  />
                </div>
                <div className="float-left label16 fw-400">-</div>
                {/* //  Time End */}
                <div style={{ width: 210 }} className="float-left usedAll">
                  <DatePicker
                    value={new Date("2023-01-01T" + allowBookingEndTime).getTime()}
                    selected={new Date("2023-01-01T" + allowBookingEndTime).getTime()}
                    onChange={(time) => onChangeTime(`endTime`, `allowBookingEndTime`, time)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-control searchDate gray"
                  />
                </div>
              </div>
            </div>
          }

          <div style={{ clear: 'both' }} />


        </div>
      }
    </Translation>
  );
};

export default AllowToBooking;
