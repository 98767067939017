/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfPayment = "PAYABLE" | "RECEIVABLE" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type contactDetailQueryVariables = {|
  id: string
|};
export type contactDetailQueryResponse = {|
  +contact: ?{|
    +id: string,
    +refNumber: string,
    +name: string,
    +firstName: string,
    +lastName: string,
    +phone: string,
    +email: ?string,
    +webSite: string,
    +registeredName: string,
    +registeredAddress: string,
    +registeredCountry: string,
    +registeredProvince: string,
    +registeredCity: string,
    +registeredDistrict: string,
    +registeredPostalCode: string,
    +mailingName: string,
    +mailingAddress: string,
    +mailingCountry: string,
    +mailingDistrict: string,
    +mailingProvince: string,
    +mailingCity: string,
    +mailingPostalCode: string,
    +typeOfSupplier: ContactTypeOfSupplier,
    +totalAmount: number,
    +typeOfPayment: ContactTypeOfPayment,
    +renterAddress: ?string,
    +renterCountry: string,
    +renterProvince: string,
    +renterDistrict: string,
    +renterCity: string,
    +renterPostalCode: string,
    +agentAddress: ?string,
    +agentCountry: string,
    +agentProvince: string,
    +agentDistrict: string,
    +agentCity: string,
    +agentPostalCode: string,
  |}
|};
export type contactDetailQuery = {|
  variables: contactDetailQueryVariables,
  response: contactDetailQueryResponse,
|};
*/


/*
query contactDetailQuery(
  $id: ID!
) {
  contact(id: $id) {
    id
    refNumber
    name
    firstName
    lastName
    phone
    email
    webSite
    registeredName
    registeredAddress
    registeredCountry
    registeredProvince
    registeredCity
    registeredDistrict
    registeredPostalCode
    mailingName
    mailingAddress
    mailingCountry
    mailingDistrict
    mailingProvince
    mailingCity
    mailingPostalCode
    typeOfSupplier
    totalAmount
    typeOfPayment
    renterAddress
    renterCountry
    renterProvince
    renterDistrict
    renterCity
    renterPostalCode
    agentAddress
    agentCountry
    agentProvince
    agentDistrict
    agentCity
    agentPostalCode
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ContactNode",
    "kind": "LinkedField",
    "name": "contact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "webSite",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredCountry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredProvince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredDistrict",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredPostalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingCountry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingDistrict",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingProvince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingPostalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfSupplier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfPayment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "renterAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "renterCountry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "renterProvince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "renterDistrict",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "renterCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "renterPostalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "agentAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "agentCountry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "agentProvince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "agentDistrict",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "agentCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "agentPostalCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contactDetailQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contactDetailQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "25ded806a33e545abbdf18714168368e",
    "id": null,
    "metadata": {},
    "name": "contactDetailQuery",
    "operationKind": "query",
    "text": "query contactDetailQuery(\n  $id: ID!\n) {\n  contact(id: $id) {\n    id\n    refNumber\n    name\n    firstName\n    lastName\n    phone\n    email\n    webSite\n    registeredName\n    registeredAddress\n    registeredCountry\n    registeredProvince\n    registeredCity\n    registeredDistrict\n    registeredPostalCode\n    mailingName\n    mailingAddress\n    mailingCountry\n    mailingDistrict\n    mailingProvince\n    mailingCity\n    mailingPostalCode\n    typeOfSupplier\n    totalAmount\n    typeOfPayment\n    renterAddress\n    renterCountry\n    renterProvince\n    renterDistrict\n    renterCity\n    renterPostalCode\n    agentAddress\n    agentCountry\n    agentProvince\n    agentDistrict\n    agentCity\n    agentPostalCode\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '839a0a25bf22b4b3767b8916b8bffaef';

module.exports = node;
