import React from 'react';
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { Helmet } from "react-helmet";
// import QRCode from "qrcode.react";
import { QRCode } from 'react-qrcode-logo';

const query = graphql`
    query waterMeterListPrintQrcodeMeterWaterQuery($search: String, $year: Float, $month: Float, $issuedDate_Gte: Date, $issuedDate_Lte: Date) {
        allResidential (search: $search) {
        edges {
            node {
                id
                name
                residentialHouseholder{
                    edges{
                        node{
                            id
                            tenant{
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
                waterMeterUnit(year: $year, month: $month, issuedDate_Gte:$issuedDate_Gte, issuedDate_Lte:$issuedDate_Lte) {
                        edges {
                        node {
                            id
                            invoiceStatus
                            month
                            year
                            issuedDate
                            meterValue
                            changeWaterMeter
                            initialChangeMeterValue
                            remark
                            editDate
                            creatorApp
                            creator
                            editCreatorDate
                            waterMeterImage{
                                edges {
                                    node{
                                        id
                                        fileName
                                        fileUpload
                                    }
                                }
                            }
                            waterMeterTag{
                                edges {
                                    node{
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }  
            }
        }
    }

    }
`;


class WaterMeterListPrintQrcodeMeterWater extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            selectNOwNumber: 0,
            selectNow: [],
            allResidential: [],
            data: [],
            page_objects: {},

        }
    }

    componentDidMount() {
        let data = this.props.location?.state?.data || []
        let page_objects = {}
        let page_name = 1
        let count_next_objects = 0
        data.forEach((n, index) => {
            if (count_next_objects >= 20) {
                page_name++;
                count_next_objects = 0;
            }

            if (page_name in page_objects) {
                page_objects[page_name].push(n);
            } else {
                //นับรอบละ 20
                page_objects[page_name] = [];
                page_objects[page_name].push(n);
            }
            count_next_objects++;

        });

        this.setState({ data, page_objects })
        // this.getMeterList();
    }
    render() {
        let pageAll = Math.ceil((this.state.data?.length / 4) / 5)
        let pageNow = null

        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <Helmet
                            style={[{
                                "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                            }]}>
                            <meta charSet="utf-8" />
                            <title>GenCode</title>
                            <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div className="print-top-menu">
                            <div className="logo">
                                <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                            </div>
                            <div className="print" onClick={() => window.print()}>
                                PRINT
                            </div>
                        </div>

                        {/* QRCODE */}
                        {
                            Object.entries(this.state.page_objects).map(([index_group, data], index_obj) => {

                                return (
                                    <div className='print-page-a4 text-center' key={index_obj + index_group}>
                                        {
                                            data.map((n, index) => {
                                                let indexx = index
                                                let pageFloor = Math.floor(parseFloat(indexx / 20))
                                                pageNow = pageFloor !== pageNow ? pageFloor : null
                                                return (
                                                    <div className='pl-0 pr-0 pt-0 pb-0' key={n?.data?.id}
                                                        style={{ float: 'left', border: '1px dashed #E2E2E2', position: 'relative' }}>
                                                        {/* <QRCode
                                                            value={JSON.stringify({
                                                                room_id: n?.data?.id,
                                                                residential_name: n?.data?.name,
                                                                type: "water_meter",
                                                            })}
                                                            size={148}
                                                            bgColor={"#ffffff"}
                                                            fgColor={"#000000"}
                                                            level={"L"}
                                                            includeMargin={false}
                                                            imagesettings={{
                                                                src: '../../../public/images/logo/logo.svg',
                                                                x: undefined,
                                                                y: undefined,
                                                                height: 24,
                                                                width: 24,
                                                                excavate: true,
                                                            }}
                                                        /> */}

                                                        {/* เก่า */}
                                                        <QRCode
                                                            value={JSON.stringify({
                                                                room_id: n?.data?.id,
                                                                residential_name: n?.data?.name,
                                                                type: "water_meter",
                                                            })}
                                                            logoImage='/images/logo/logo.svg'
                                                            size={156}
                                                            logoWidth={40}
                                                            logoHeight={40}
                                                            ecLevel={"H"}
                                                            qrStyle={"squares"}
                                                        />
                                                        <p style={{ fontSize: 14, fontWeight: 400 , marginBottom: '0.5rem' }}>
                                                            <b>{n?.data?.name}</b>
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }

                    </React.Fragment>
                }
            </Translation>
        );
    }
}

export default withRouter(WaterMeterListPrintQrcodeMeterWater);
