/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateNotificationQueryVariables = {|
  notificationType?: ?string
|};
export type updateNotificationQueryResponse = {|
  +updateNotification: ?boolean
|};
export type updateNotificationQuery = {|
  variables: updateNotificationQueryVariables,
  response: updateNotificationQueryResponse,
|};
*/


/*
query updateNotificationQuery(
  $notificationType: String
) {
  updateNotification(notificationType: $notificationType)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notificationType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "notificationType",
        "variableName": "notificationType"
      }
    ],
    "kind": "ScalarField",
    "name": "updateNotification",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateNotificationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateNotificationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c450d43f041810c186a4177daff74817",
    "id": null,
    "metadata": {},
    "name": "updateNotificationQuery",
    "operationKind": "query",
    "text": "query updateNotificationQuery(\n  $notificationType: String\n) {\n  updateNotification(notificationType: $notificationType)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c52e1544f2f8c593d3e821870f5f973e';

module.exports = node;
