import React from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Translation } from "react-i18next";
import ClaimTopMenuNavigation from "../claimTopMenuNavigation";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import "../../../project/staff/styles/staffList.scss"
import Swal from "sweetalert2";
import _ from "lodash"
import i18n from "i18next";
import { Link } from 'react-router-dom';
import SeacrhDetails from '../../../components/search/indexSearch';
import APICliam from '../../../api/claim'
import ReactPaginate from 'react-paginate';
import ClaimNavigation from './claimNav';
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import ModalTagInList from '../../../components/ModalTag/tag';
import { encode } from 'base-64';
import StatusCliam from './statusClaim';
import i18next from 'i18next';


class ClaimList extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            search: "", end_date: "", start_date: "", claim_type: '',
            image_light_box: "",
            open_light_box: false,
            redirect: false,
            status: "all",

            index_first: 0,
            index_last: 10,
            pageSize: 10,
            pageNow: 1,
            pageAll: 0,
            listTag: [],

            selectedShow: [],
            data: [],
            loading: true,
            loadingTagRepair: false,
            totalCountRecord: 0,

            inSuranceCurrent: []
        }
        this.onDelete = this.onDelete.bind(this);
        this.functionCurrentMoneyFormat = this.functionCurrentMoneyFormat.bind(this)
        this.viewMore = this.viewMore.bind(this)
        this.on_consider_fail = this.on_consider_fail.bind(this)
        this.handlePropsChange = this.handlePropsChange.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.getListTags = this.getListTags.bind(this)
        this.AddNewTagClaim = this.AddNewTagClaim.bind(this)
        this.removeTagFromGlobal = this.removeTagFromGlobal.bind(this)
        this.removeTagFromClaim = this.removeTagFromClaim.bind(this)
        this.interval = ""
    }

    componentDidMount() {
        if (this.state.loading == true) {
            this.getData()
            this.getListTags()
            this.getInsur()
        }

    }
    componentDidUpdate(prevState) {
        if (prevState.match.params.status !== this.props.match.params.status) {
            this.getData()
        }
    }

    getData() {
        let dataParams = {
            index_first: this.state.index_first,
            index_last: this.state.index_last,
            status: this.props.match.params.status === "all" ? "" : this.props.match.params.status,
            claim_type: this.state.claim_type,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            search: this.state.search,
        }
        APICliam.GetCliamList(dataParams).then((res) => {
            if (res.data.claim_list) {
                APICliam.inserranceCurrent().then((resIns) => {
                    if (resIns.data) {
                        this.setState({
                            data: res.data.claim_list.map((n) => ({ ...n, openCkh: false })),
                            totalCountRecord: res.data.num_record,
                            pageAll: Math.ceil(res.data.num_record / this.state.pageSize),
                            loading: false,
                            status: this.props.match.params.status,

                        })
                    }
                })

            }
        }, (err) => {
            Swal.fire({
                title: i18next.t("Allaction:Unable to Connect to API at this Time"),
                text: i18next.t("Allaction:Please try again."),
                type: 'error',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: i18next.t("Allaction:Please try again."),
                cancelButtonText: i18next.t("Allaction:back")
            }).then((data) => {
                if (data.value) {
                    window.location.reload();
                } else {
                    this.props.history.goBack();
                }
            })
        })
    }

    getInsur() {
        APICliam.inserranceCurrent().then((res) => {
            if (res.data?.statusCode === "0000") {
                this.setState({ inSuranceCurrent: res.data?.insurance_list })
            }
        })
    }

    handlePageSize = (e) => {
        this.setState({ pageSize: parseInt(e.target.value), pageNow: 1, index_first: 0, index_last: parseInt(e.target.value), data: [] }, () => {
            this.getData()
        })
    }

    handlePageClick = (event) => {
        let pageNow = event.selected + 1;
        let index_first = this.state.pageSize * event.selected
        let index_last = this.state.pageSize * pageNow
        this.setState({ pageNow, index_first, index_last, data: [] }, () => {
            this.getData()
        });
    };

    onDelete(insurId) {
        Swal.fire({
            title: i18n.t("claim:Are you sure to remove this draft data?"),
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("Allaction:yes"),
            cancelButtonText: i18n.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let data = {
                    "status": "delete"
                };
                APICliam.UpdateCliam(data, insurId).then((resDelete) => {
                    if (resDelete.data.statusCode === "0000") {
                        this.getData()
                    }
                })
            }
        })
    }

    handlePropsChange(channel_list) {
        let search = ""
        let start_date = null
        let end_date = null
        let claim_type = ""
        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            }
            else if (e.name === "start_date" && e.value !== '') {
                start_date = e.value
            }
            else if (e.name === "end_date" && e.value !== '') {
                end_date = e.value
            } else if (e.name === "status" && e.value !== '') {
                claim_type = e.value
            }

        });
        this.setState({
            search: search,
            start_date: start_date,
            end_date: end_date,
            claim_type: claim_type
        }, () => this.getData())
    }

    functionCurrentMoneyFormat(money) {
        let formatMoney = 0
        if (money) {
            formatMoney = parseFloat(money).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return formatMoney ? formatMoney : "-"
    }

    viewMore(index) {
        let status = _.cloneDeep(this.state.data)
        status.forEach(ele => ele.openCkh = false)
        status[index].openCkh = this.state.data[index].openCkh ? false : true;
        this.setState({ data: status })
    }
    on_consider_fail(id) {
        Swal.fire({
            customClass: {
                title: 'swal2-title-custom',
                header: 'swal2-header-custom',
                container: 'swal2-container-custom',
                content: 'swal2-content-custom',
            },
            title: i18next.t("claimAction:PleaseEnterReasonToReject"),
            html:
                '<div class="row  text-left">' +
                `<div class="col-1 text-left mt-3"><input type="radio" id="notreach" name="statusDelete" checked /> </div><div class="col-11 mt-3">${i18next.t("claimAction:The claim amount does not reach the minimum of the first portion of liability.")}</div>` +
                '<div class="col-1 text-left mt-3"><input type="radio" id="expair" name="statusDelete"  /> </div><div class="col-11 mt-3">' + i18next.t("claimAction:Caused by deterioration in use") + '</div>' +
                '<div class="col-1 text-left mt-3"><input type="radio" id="otherStatusIs" name="statusDelete"  /> </div>' +
                '<div class="col-11 mt-3"><input type="text" id="otherStatusName" class="form-control" /></div>' +
                '</div>',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: i18next.t("claimAction:Reject"),
            preConfirm: () => ({
                expair: document.getElementById("expair").checked,
                notreach: document.getElementById("notreach").checked,
                otherStatusIs: document.getElementById("otherStatusIs").checked,
                otherStatusName: document.getElementById("otherStatusName").value,
            }),
        }).then((c) => {
            if (c.value) {
                // let data = {
                //     id: staff_id,
                //     endWork: format(new Date(), 'YYYY-MM-DD'),
                //     note: c.value.death ? "เสียชีวิต" : c.value.expair ? "หมดสัญญาจ้าง" : c.value.retire ? "เกษียณอายุ" : c.value.otherStatusName
                // }
                let data = {
                    "status": "consider_fail",
                    "void_remark": c.value.notreach ? "The claim amount does not reach the minimum of the first portion of liability." : c.value.expair ? "Caused by deterioration in use" : c.value.otherStatusName
                }
                Swal.fire({
                    title: i18n.t("Allaction:This will only take a moment, please wait"),
                    type: 'info',
                    showLoaderOnConfirm: true,
                    showCloseButton: false,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then(() => {
                    APICliam.UpdateCliam(data, id).then((res) => {
                        if (res.data.statusCode === "0000") {
                            Swal.fire(i18n.t('claimAction:Void'), '', 'success').then(() => {
                                this.getData()
                                // this.props.history.push(`/purchasing/claim/summarize/${encode(this.state.data.claim_detail.id)}/${encode("consider_fail")}`)
                            })
                        } else {
                            Swal.fire(i18n.t('settingAccount:Failed to save', "", "warning"))
                        }
                    })
                })

                // CreateUpdateStaff(data, onSubmitSuccess, onSubmitError)
                // this.props.history.push("/project/staff/loading")
            }
        })

    }


    //TAG
    getListTags() {
        APICliam.getListTag().then((res) => {
            if (res.data.tag_global_claim) {
                this.setState({ listTag: res.data.tag_global_claim })
            }
        })
    }

    selectTag(TagId, claim_id, datas, tagName) {
        let findIndex = _.filter(datas, (nnn) => nnn.tag_claim.id === TagId)

        if (findIndex.length < 1) {
            let data = {
                "tag_claim_id": TagId,
                "claim_id": claim_id
            }
            APICliam.create_tag_claim(data).then((res) => {
                this.getData();
            })
        } else {
            Swal.fire(i18next.t("Allaction:Unsuccessful "), "ไม่สามารถเพิ่มข้อมูลซ้ำได้", "error")
        }

    }



    AddNewTagClaim() {
        Swal.fire({
            customClass: {
                title: 'swal2-title-custom',
                header: 'swal2-header-custom',
                container: 'swal2-container-custom',
                content: 'swal2-content-custom',
            },
            title: "ระบุชื่อแท็กใหม่",
            input: 'text',
            inputPlaceholder: 'ระบุชื่อแท็ก',
            inputAttributes: {
                required: 'true'
            }


        }).then((data) => {
            if (data.value !== "") {
                let tagNameNew = {
                    tagName: data.value
                }
                APICliam.create_tag_global(tagNameNew).then((res) => {
                    if (res.data.statusCode === "200") {
                        this.getListTags()
                    }

                    // if(res.data.status === "200"){

                    // }else if(res.data.status === "401"){
                    //     Swal.fire("ผิดพลาด","ระบบผิดพลาด โปรดล็อคอินใหม่และลองอีกครั้ง" ,"error")
                    // }else{
                    //     Swal.fire("ผิดพลาด","ข้อมูลที่ส่งไป ผิดรูปแบบ ติดต่อ DEV หน้าบ้าน" ,"error")
                    // }

                })
            }
        })
    }

    removeTagFromClaim(id, cliamID) {
        let data = {
            id: parseInt(id),
        }
        APICliam.removeTagFromClaim(data).then((res) => {
            if (res.data.statusCode === "200") {
                this.getData();
            }

        })
    }

    removeTagFromGlobal(TagId) {
        let data = {
            "id": TagId
        }
        APICliam.removeTagFromGlobal(data).then((res) => {
            this.getListTags()
            this.getData();
        })
    }

    render() {
        let { inSuranceCurrent } = this.state
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <ClaimTopMenuNavigation mini={true} />
                    <Translation >
                        {
                            t =>
                                <div className="container-fluid box claim">
                                    {/* ปุ่มกลับและหัวข้อ */}
                                    {
                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'claim_create' }) &&
                                    <BackButtonIcon
                                        addLinkChk={true}
                                        addLinkButton={"/purchasing/claim/create"}
                                        addLinkText={t("claimRequst:crete")}
                                        LinkBack={"/purchasing"}
                                        LinkText={t('claimRequst:headerClaimRequest')+" "+"(CPR)"}
                                    />
                                    }
                                    {
                                        inSuranceCurrent[0] &&
                                        <div className='boxBlue mt-5 text-right'>
                                            <p>
                                                {t('claimRequst:WarrantyBy')} {inSuranceCurrent[0]?.insurance_detail.main_insurance_company.name} {t('claimRequst:Ltd')}
                                                <br />
                                                {t('claimRequst:WarrantyWhen')}
                                                {format(inSuranceCurrent[0]?.insurance_detail.insurance_start_date, "DD/MM/YYYY")} -
                                                {format(inSuranceCurrent[0]?.insurance_detail.insurance_end_date, "DD/MM/YYYY")}
                                            </p>
                                            {/* <span className='ml-2'>
                                             <img src={process.env.PUBLIC_URL+ "/image/icons/close-Blue.png"} style={{ width: 20 ,height: 20}} />
                                            </span> */}
                                        </div>
                                    }
                                    {/* ปุ่มแท็บ */}
                                    <ClaimNavigation />
                                    {/* ส่วนค้นหา */}
                                    <SeacrhDetails
                                        text={[t("claimRequst:chooseShow")]}
                                        seleteDetail={[
                                            { value: "", label: i18next.t("claimRequst:all") },
                                            { value: "private", label: i18next.t("claimRequst:claimPrivate") },
                                            { value: "public", label: i18next.t("claimRequst:claimPublic") }
                                        ]} //Select ชื่อว่า
                                        selectIs={true} //Select ใช้ไหม
                                        startDateIs={true} //วันที่้ค้นหาเริ่มต้น
                                        endDateIs={true} //วันที่ค้นหาสิ้นสุด ใช้ หรือ ไม่
                                        searchBoxId={true} //กล่องค้นหา  ใช้ หรือ ไม่
                                        search={this.state.search} //กล่องค้นหา Input
                                        start_date={this.state.start_date}//วันที่้ค้นหาเริ่มต้น Input
                                        end_date={this.state.end_date}//วันที่ค้นหาสิ้นสุด Input
                                        handlePropsChange={this.handlePropsChange}  //Function เมื่อมันมีการ Change ใน Input
                                    />


                                    <div className="content-inner">

                                        <div className='row create'>
                                            {
                                                this.state.loading == false ?
                                                    this.state.data.length > 0 ?
                                                        this.state.data?.map((n, index) => {
                                                            let first_name = n.claim_detail.claim_type === "private" ? n.claim_detail.tenant_contact?.first_name : n.claim_detail.juristic_contact.first_name;
                                                            let lasts_name = n.claim_detail.claim_type === "private" ? n.claim_detail.tenant_contact?.last_name : n.claim_detail.juristic_contact.last_name;
                                                            return (
                                                                <div className='col-12' key={index}>
                                                                    <div className=' card show mt-2'>
                                                                        {/* {index + 1} */}
                                                                        <div className="card-body p-4">
                                                                            <div className="row">
                                                                                <div className="col-md-3">
                                                                                    <p className='headerLabel28 mb-0'>{n.claim_detail.claim_type === "private" ? n.claim_detail.residential?.name : ""}</p>
                                                                                    <p className='headerLabel18 mt-0 text300'>
                                                                                        {
                                                                                            n.claim_detail.other_contact ? n.claim_detail.other_contact : first_name + " " + lasts_name
                                                                                        }</p>
                                                                                    <br />
                                                                                    <button className={n.claim_detail.claim_type === "private" ? ' btnprimaryblue' : 'btnDrakGray'}>
                                                                                        {n.claim_detail.claim_type === "private" ? t("claimRequst:claimPrivate") : t("claimRequst:claimPublic")}
                                                                                    </button>
                                                                                </div>

                                                                                <div className="col-md-6">

                                                                                    {/* HEADER */}
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            n.claim_detail.status !== "draft" ?
                                                                                                <Link to={"/purchasing/claim/chat/" + encode(n.claim_detail.id) + "/" + encode(n.claim_detail.status)}>
                                                                                                    <h6 className="mt-1">
                                                                                                        {n.claim_detail.header}
                                                                                                    </h6>
                                                                                                </Link>
                                                                                                :
                                                                                                <h6 className="mt-1">
                                                                                                    {n.claim_detail.header}
                                                                                                </h6>
                                                                                        }

                                                                                    </React.Fragment>
                                                                                    <div style={{ position: 'relative', display: 'inline-flex', flexWrap: 'wrap', }}>
                                                                                        {
                                                                                            this.state.loadingTagRepair == false &&
                                                                                            n.tag?.map((tag, index) => {
                                                                                                return (
                                                                                                    <React.Fragment key={index}>
                                                                                                        <button className={n.claim_detail.status === "cancled" ? "pl-2 taginList tagBtnGray" : 'pl-2 taginList tagBtnBlue'}>
                                                                                                            {tag.tag_claim.tag_name}
                                                                                                            {
                                                                                                                n.claim_detail.status !== "cancled" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'claim_delete' }) 
                                                                                                                && <span onClick={() => this.removeTagFromClaim(tag.id, n.claim_detail.id)}
                                                                                                                    style={{ marginLeft: 10 }}>
                                                                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/close.svg"} style={{ width: 8, height: 8 }} />
                                                                                                                </span>
                                                                                                            }


                                                                                                        </button>
                                                                                                    </React.Fragment>

                                                                                                )
                                                                                            })
                                                                                        }

                                                                                        {
                                                                                            n.claim_detail.status !== "cancled" && n.claim_detail.status !== "finish" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "claim_create" }) &&
                                                                                            <div className="dropdown">
                                                                                                <button className="taginList tagBtnGray dropdown-toggle" data-toggle="dropdown">
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/add.svg"} className="ml-1" style={{ width: 8, height: 8 }} />
                                                                                                    <span className="ml-1">{t('claimRequst:addTag')}</span>
                                                                                                </button>
                                                                                                <ul className="dropdown-menu">
                                                                                                    {
                                                                                                        this.state.listTag?.sort((a, b) => a.id - b.id).map((tagLi, index) => {
                                                                                                            return (

                                                                                                                <React.Fragment key={index} >
                                                                                                                    {
                                                                                                                        tagLi.id > 3 ?
                                                                                                                            <li className='dropdown-item tagLi' onClick={() => this.selectTag(tagLi.id, n.claim_detail.id, n.tag, tagLi.tag_name)}
                                                                                                                                style={{ cursor: 'pointer', borderBottom: '#BFBFBF 1px silid' }}>{tagLi.tag_name}
                                                                                                                                <span style={{ float: 'right' }} onClick={() => this.removeTagFromGlobal(tagLi.id)}>
                                                                                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} style={{ width: 8, height: 8 }} />
                                                                                                                                </span></li>
                                                                                                                            :
                                                                                                                            <li key={index} style={{ cursor: 'pointer' }} className='dropdown-item tagLi'
                                                                                                                                onClick={() => this.selectTag(tagLi.id, n.claim_detail.id, n.tag, tagLi.tag_name)}>{tagLi.tag_name}</li>
                                                                                                                    }
                                                                                                                </React.Fragment>
                                                                                                            )
                                                                                                        })
                                                                                                    }


                                                                                                    <li className='dropdown-item' onClick={this.AddNewTagClaim} style={{ color: "#BFBFBF" }}>
                                                                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/add.svg"} style={{ width: 8, height: 8 }} />
                                                                                                        <span className="ml-2">{t('claimRequst:addTag')}</span>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <br />
                                                                                    <ModalTagInList indexID={index} />
                                                                                    {
                                                                                        n.claim_detail.status === "draft" ?
                                                                                            <p>{t('claimRequst:draf')} <Link to={`/purchasing/claim/cliam-draft/${encode(n.claim_detail.id)}/${encode(n.claim_detail.id + n.claim_detail.status)}`}>
                                                                                                {n.claim_detail.doc_number}</Link>
                                                                                            </p>
                                                                                            :
                                                                                            n.claim_detail.status === 'new' ?
                                                                                                <p>{t('claimRequst:claimNo')} <Link to={`/purchasing/claim/cliam-detail/${encode(n.claim_detail.id)}/${encode(n.claim_detail.status)}`}>{n.claim_detail.doc_number}</Link></p>
                                                                                                :
                                                                                                <p>{t('claimRequst:claimNo')} <Link to={`/purchasing/claim/summarize/${encode(n.claim_detail.id)}/${encode(n.claim_detail.status)}`}>{n.claim_detail.doc_number}</Link></p>
                                                                                    }



                                                                                    <p></p>

                                                                                    <React.Fragment>
                                                                                        {
                                                                                            n.openCkh == true &&
                                                                                            n.claim_detail.status !== "draft" &&
                                                                                            <div className='statusClass' style={{ flexDirection: 'row', }} >
                                                                                                <StatusCliam status={n.claim_detail.status} bottom={36} line={0} />
                                                                                            </div>
                                                                                        }

                                                                                    </React.Fragment>




                                                                                </div>

                                                                                <div className='col-3 text-right'>


                                                                                    <span className="grey-color clickable">
                                                                                        <div style={{ float: 'right', width: 25 }}>
                                                                                            {
                                                                                                n.claim_detail.status === "new" &&
                                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/choiceNoBG.png'}
                                                                                                    style={{ width: 7, height: 23 }}

                                                                                                    className='cursur dropdown-toggle' data-toggle="dropdown"
                                                                                                />
                                                                                            }

                                                                                            {
                                                                                                n.claim_detail.status === "draft" &&
                                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/choiceNoBG.png'}
                                                                                                    style={{ width: 7, height: 23 }}
                                                                                                    className='cursur dropdown-toggle' data-toggle="dropdown"
                                                                                                />
                                                                                            }

                                                                                            <ul className="dropdown-menu">
                                                                                                {
                                                                                                    n.claim_detail.status === "new" &&
                                                                                                    <li className=' dropdown-item' onClick={() => this.on_consider_fail(n.claim_detail.id)} >{t('claimRequst:reject')}</li>
                                                                                                }
                                                                                                {
                                                                                                    n.claim_detail.status === "draft" &&
                                                                                                    <React.Fragment>
                                                                                                        <li className=' dropdown-item' onClick={() => this.onDelete(n.claim_detail.id)} >{t('claimRequst:Delete')}</li>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            </ul>

                                                                                        </div>
                                                                                        <div style={{ float: 'right' }}>
                                                                                            {format(n.claim_detail.added, "DD/MM/YYYY - HH:mm น.")}
                                                                                            <br />
                                                                                            {
                                                                                                n.claim_detail.status !== "draft" &&
                                                                                                <React.Fragment>
                                                                                                    <img className='cursur' onClick={() => this.viewMore(index, "")}
                                                                                                        style={{ float: 'right', width: 24, height: 24, marginTop: 6 }}
                                                                                                        src={
                                                                                                            n.openCkh == true ? process.env.PUBLIC_URL + '/images/iconAction/arrowDown.png' :
                                                                                                                process.env.PUBLIC_URL + '/images/iconAction/arrowUp.png'
                                                                                                        }
                                                                                                    />
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        </div>

                                                                                        <div style={{ clear: 'both' }} />



                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className='col-sm-12 text-center'>
                                                            <div className=' card show mt-2'>
                                                                <div className="card-body p-4">
                                                                    <div className="row">
                                                                        <div className='col-12 text-center'>
                                                                            <p>{t('claimRequst:NotFound')}</p>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    :
                                                    <div className='col-sm-12 text-center'>
                                                        <div className=' card show mt-2'>
                                                            <div className="card-body p-4">
                                                                <div className="row">
                                                                    <div className='col-12 text-center'>
                                                                        <p>{t('claimRequst:Loading')}</p>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                            }

                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-6'>
                                                <select className='page'
                                                    onChange={this.handlePageSize}>
                                                    <option value="10">{t("silvermanguard:show")} 10 {t("silvermanguard:items")}</option>
                                                    <option value="20">{t("silvermanguard:show")} 20 {t("silvermanguard:items")}</option>
                                                    <option value="50">{t("silvermanguard:show")} 50 {t("silvermanguard:items")}</option>
                                                    <option value="100">{t("silvermanguard:show")} 100 {t("silvermanguard:items")}</option>
                                                </select>
                                            </div>
                                            <div className='col-6 text-right'>
                                                <div className='pullright' style={{ marginTop: '22px' }}>
                                                    {t("silvermanguard:listAll")} {this.state.totalCountRecord} &nbsp;

                                                    {this.state.pageAll > 0 && (
                                                        <ReactPaginate style={{ marginTop: '-7px' }}
                                                            previousLabel={"previous"}
                                                            nextLabel={"next"}
                                                            breakLabel={<span className="btn page-link">...</span>}
                                                            breakClassName={"break-me"}
                                                            pageClassName="page-item"
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={4}
                                                            previousClassName="sr-only"
                                                            containerClassName={"pagination float-right"}
                                                            subContainerClassName={"pagination-page"}
                                                            nextLinkClassName="sr-only"
                                                            pageLinkClassName="page-link"
                                                            activeClassName={"active"}
                                                            pageCount={this.state.pageAll}
                                                            onPageChange={this.handlePageClick}
                                                        />
                                                    )}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default ClaimList;
