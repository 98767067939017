/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeletePetInput = {|
  id?: ?string,
  clientMutationId?: ?string,
|};
export type petDetailMutationVariables = {|
  input: DeletePetInput
|};
export type petDetailMutationResponse = {|
  +deletePet: ?{|
    +ok: ?boolean,
    +petViewer: ?{|
      +id: string,
      +allPet: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +petType: string,
            +description: string,
            +imagePet: string,
          |}
        |}>
      |},
    |},
  |}
|};
export type petDetailMutation = {|
  variables: petDetailMutationVariables,
  response: petDetailMutationResponse,
|};
*/


/*
mutation petDetailMutation(
  $input: DeletePetInput!
) {
  deletePet(input: $input) {
    ok
    petViewer {
      id
      allPet {
        edges {
          node {
            id
            petType
            description
            imagePet
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeletePetPayload",
    "kind": "LinkedField",
    "name": "deletePet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PetViewer",
        "kind": "LinkedField",
        "name": "petViewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PetNodeConnection",
            "kind": "LinkedField",
            "name": "allPet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PetNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PetNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "petType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imagePet",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "petDetailMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "petDetailMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a27e97d8ab4bbbe7ff73ad442f4f8d8b",
    "id": null,
    "metadata": {},
    "name": "petDetailMutation",
    "operationKind": "mutation",
    "text": "mutation petDetailMutation(\n  $input: DeletePetInput!\n) {\n  deletePet(input: $input) {\n    ok\n    petViewer {\n      id\n      allPet {\n        edges {\n          node {\n            id\n            petType\n            description\n            imagePet\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1379d6051d8bbcbfd90363b7a393964e';

module.exports = node;
