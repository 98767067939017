import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import ReceiveDetail from "./receiveDetail";

const query = graphql`
    query receiveDetailQuery($id: ID!,$ref_transaction: String){
        selfProject{
            name
            logoBase64
            address
            taxIdNumber
            juristicContactNumber
        }
        receive(id: $id){
            id
            status
            payGroup
            firstName
            lastName
            contact{
                refNumber
                firstName
                lastName
                registeredName
                registeredAddress
                registeredCountry
                registeredProvince
                registeredCity
                registeredDistrict
                registeredPostalCode
                typeOfContact
                residential{
                    ownershipRatio
                    size
                }
                name
                taxIdNumber
                phone
                email
            }
            issuedDate
            docNumber
            receiveTransaction{
                edges{
                    node{
                        id
                        amount
                        discount
                        receive{
                            status
                        }
                        receiptDepositTransactionUsed{
                            edges{
                                node{
                                    id
                                    amount
                                    receiptDepositTransaction{
                                        description
                                        chartOfAccount{
                                            name
                                            chartOfAccountCode
                                        }
                                        receiptDeposit{
                                            docNumber
                                        }
                                    }
                                }
                            }
                        }
                        transaction{
                            id
                            description
                            unitItems
                            price
                            preTaxAmount
                            vat
                            paid
                            whtRate
                            whtRatePercent
                            total
                            invoice{docNumber issuedDate firstName lastName}
                            productAndService{productCode}
                            chartOfAccount{chartOfAccountCode}
                        }
                    }
                }
            }
            total
            remark
            unknownReceive{
                edges{
                    node{
                        id description docNumber amount refNumber
                        chartOfAccount{
                            chartOfAccountCode name
                        }
                        imageSlip
                    }
                }
            }
            creditNoteReceive{
                edges{
                    node{
                        id
                        docNumber
                        description
                        price
                        returnChartOfAccount{
                            chartOfAccountCode name
                        }
                    }
                }
            }
            bankAccountTransactionReceive{
                edges{
                    node{
                        id
                        price
                        date
                        imageSlip
                        imageSlipAdditional
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashDepositReceiveTransaction{
                edges{
                    node{
                        id
                        price
                    }
                }
            }
            chequeDeposit{
                edges{
                    node{
                        id
                        date
                        bankName
                        price
                        chequeNumber
                        branchNumber
                        chequeImage
                    }
                }
            }
            creditCardTransaction{
                edges{
                    node{
                        id
                        amount
                    }
                }
            }
            paymentGatewayMethod{
                edges{
                    node{
                        id
                        paymentType
                        paymentGateway{
                            id
                            paymentType
                        }
                        amount
                        feeAmount
                        total
                        depositDate
                        image
                    }
                }
            }
            # smallChange{
            #     edges{
            #         node{
            #             id
            #             amount
            #         }
            #     }
            # }

            # feeAccounting{
            #     edges{
            #         node{
            #             id
            #             amount
            #         }
            #     }
            # }

        }
        allAccountRecordGroup(refTransaction: $ref_transaction) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }

    }
`;

class ReceiveDetailQuery extends Component {
    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                variables={{"id": this.props.match.params.id,"ref_transaction": this.props.match.params.ref_transaction?this.props.match.params.ref_transaction:'none'
            }}
                render={({error, props}) => {
                    if (error) {
                        return <div>Error :(</div>;
                    } else if (props) {
                        return <ReceiveDetail detail={props}
                                              contact={props.receive.contact}
                                              transactions={props.receive.receiveTransaction.edges}
                                              credit_note={props.receive.creditNoteReceive.edges}
                                              unknown_receive={props.receive.unknownReceive}
                        />
                    }
                }}
            />
        )
    }
}

export default ReceiveDetailQuery;