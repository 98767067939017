import React from "react";
import { QueryRenderer } from "react-relay";
import numberWithComma from "../../libs/numberWithComma";
import environment from "../../env/environment";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Pagination from "../../libs/newPagination";
import ComponentPagination from "../../libs/componentPagination";
import Navigation from "./navigation";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import DatePickerNoti from '../finance/depositInterest/datePickerNoti'
import SearchProject from "../../project/searchProject";
import UpdateCollectionMutation from "./updateCollection";
import Swal from "sweetalert2";
import i18n from 'i18next';
import Loading from "../../libs/loading";
import { fetchQuery } from "relay-runtime";
import i18next from "i18next";
import CountNotiCollection from "./countNotiCollection";
import { Translation } from "react-i18next";
const query = graphql`
    query collectionLettersListTableQuery( $status: [String], $numberOfTimes: Int,$startDate: DateTime,$endDate: DateTime, $search: String,$first: Int, $last: Int) {
        allCollectionLetters( status: $status,numberOfTimes:$numberOfTimes, startDate:$startDate, endDate:$endDate, search: $search,first: $first,last: $last){
            edges{
                node{
                    id
                    numberOfTimes
                    docNumber
                    added
                    updated
                    issuedDate
                    creator
                    status
                    creatorDelete
                    voidNote
                    refDocNumber
                    contactName
                    sumTotal
                    creatorApproval
                    contact {
                        id
                        name
                        typeOfContact
                    }
                }
            }
            totalCount
        }
    }
    
`;




class CollectionLettersListTable extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            search: "",
            selectedId: [],
            reQuery: false,
            checkAll: false,
            tempStartDate: null,
            tempEndDate: null,
            noti: 0
        }
        this.handleChange = this.handleChange.bind(this)
        this.getCountNotiCollection = this.getCountNotiCollection.bind(this)
    }

    componentDidMount() {
        this.goFirst()
        this.getCountNotiCollection();
    }

    async getCountNotiCollection() {
        await fetchQuery(environment, CountNotiCollection).then(response => {
            this.setState({
                noti: response.countNotiCollection.countNoti,
            })
        })
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleKeyDown(event) {
        if (event.key === "Enter") {
            this.handleSearch(event);
            this.setState({
                startDate: new Date(new Date(this.state.tempStartDate).setHours(0, 0, 0, 0)),
                endDate: new Date(new Date(this.state.tempEndDate).setHours(24, 0, 0, 0)),
            })
        }
    }


    handleSearch = (text) => {
        this.setState({
            search: text,
            startDate: this.state.tempStartDate ? new Date(new Date(this.state.tempStartDate).setHours(0, 0, 0, 0)) : null,
            endDate: this.state.tempEndDate ? new Date(new Date(this.state.tempEndDate).setHours(24, 0, 0, 0)) : null,
        })
    }

    onCheck = (id) => {
        let index = this.state.selectedId.indexOf(id);
        let selected = _.cloneDeep(this.state.selectedId)
        if (index === -1) {
            selected.push(id)
        } else {
            selected.splice(index, 1)
        }
        this.setState({
            selectedId: selected
        })
    }

    onDelete = async () => {
        let updateDebitManual = []
        let { value: void_remark } = await Swal.fire({
            title: `${i18next.t("Allaction:Do you want to cancel")}?`,
            type: 'warning',
            text: i18next.t("Allaction:Please enter a cancellation note."),
            input: 'text',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Confirm"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            inputValidator: (value) => {
                if (!value) {
                    return i18next.t("Allaction:Please enter a cancellation note.")
                }
            }
        })
        if (void_remark) {
            _.forEach(this.state.selectedId, id => {
                updateDebitManual.push({
                    collectionLettersId: id,
                    status: 'void',
                    delete: false,
                    voidNote: void_remark
                })
            })
            let variables = {
                input: {
                    updateDebitManual: updateDebitManual
                }
            }
            UpdateCollectionMutation(variables, this.onCancelSuccess, this.onCancelError)
        }

    }

    onDeleteDraft = async () => {
        let updateDebitManual = []
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data") + "?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                _.forEach(this.state.selectedId, id => {
                    updateDebitManual.push({
                        collectionLettersId: id,
                        status: 'void',
                        delete: true,
                    })
                })
                let variables = {
                    input: {
                        updateDebitManual: updateDebitManual
                    }
                }
                UpdateCollectionMutation(variables, this.onCancelSuccess, this.onCancelError)
            }
        })
    }


    onCancelError = (err) => {
        this.setState({ reQuery: !this.state.reQuery });
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    onCancelSuccess = (response) => {
        if (response) {
            Swal.fire(i18next.t("Allaction:Canceled Successfully"), '', 'success').then(() => {
                this.setState({ reQuery: !this.state.reQuery, selectedId: [] });
            });
        } else {
            Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), '', 'warning').then(() => {

                this.setState({ reQuery: !this.state.reQuery });
            })
        }
    }

    onConfirmLetter = () => {
        let updateDebitManual = []
        _.forEach(this.state.selectedId, id => {
            updateDebitManual.push({
                collectionLettersId: id,
                status: 'active',
                delete: false
            })
        })
        let variables = {
            input: {
                updateDebitManual: updateDebitManual
            }
        }
        UpdateCollectionMutation(variables, this.onSubmitSuccess, this.onSubmitError)
    }

    onSubmitSuccess = (response) => {
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.setState({ reQuery: !this.state.reQuery, selectedId: [] });
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({ reQuery: !this.state.reQuery });
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    onClickIcon = () => {
        this.setState({
            startDate: this.state.tempStartDate ? new Date(new Date(this.state.tempStartDate).setHours(0, 0, 0, 0)) : null,
            endDate: this.state.tempEndDate ? new Date(new Date(this.state.tempEndDate).setHours(24, 0, 0, 0)) : null,
        })
    }
    
    getStatus = (status, numberOfTimes) => {
        if (status === 'ACTIVE' || status === 'DRAFT') {
            if (numberOfTimes < 4) {
                return 'Collection Letter No.' + numberOfTimes
            } else {
                return 'Legal Proceedings'
            }
        } else if (status === 'PAID') {
            return 'Paid'
        } else if (status === 'VOID') {
            return 'Canceled'
        }
    }

    getClassName = (status, numberOfTimes) => {
        if (status === 'ACTIVE' || status === 'DRAFT') {
            if (numberOfTimes < 4) {
                return 'status-yellow'
            } else {
                return 'status-red'
            }
        } else if (status === 'PAID') {
            return 'status-green'
        } else if (status === 'VOID') {
            return 'status-void'
        }
    }

    onCheckAll = (props) => {
        let selected = []
        this.setState({ checkAll: !this.state.checkAll }, () => {
            if (this.state.checkAll) {
                _.forEach(props.allCollectionLetters.edges, collection => {
                    selected.push(collection.node.id)
                })
                this.setState({
                    selectedId: selected
                })
            } else {
                this.setState({
                    selectedId: []
                })
            }
        })

    }

    render() {
        return (
            <Translation>
                {
                    (t) =>
                        <React.Fragment>
                            <Navigation noti={this.state.noti} />
                            <div className="row mb-2 mt-1">
                                {this.props.status !== 'void' ?
                                    (this.props.status !== 'draft') ?
                                        <div className="col-3 d-flex align-items-center">
                                            {this.props.status !== 'all' && this.props.status !== 'paid' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_print' }) &&
                                                <Link
                                                    to={{
                                                        pathname: "/document/collection-letter-print/all/" + this.props.status,
                                                        state: {
                                                            selectedId: this.state.selectedId,
                                                        },
                                                    }}
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-noHover btn-outline-secondary"
                                                        onClick={this.onClickPrintAll}
                                                    >
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/images/icons/print-icon.png"
                                                            }
                                                            alt="print-icon"
                                                        />
                                                    </button>
                                                </Link>

                                            }
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_delete' }) &&
                                                <button
                                                    type="button"
                                                    className="btn btn-noHover btn-outline-secondary ml-2"
                                                    onClick={this.onDelete}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/icons/bin-outline.png"
                                                        }
                                                        alt="delete-icon"
                                                    />
                                                </button>
                                            }
                                        </div>
                                        :
                                        <div className="col-3">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_approve' }) &&
                                                <button type="button" className="btn-list cursor mr-3" onClick={() => this.onConfirmLetter()}>
                                                    <span>{i18next.t("collectionLettersListTable:Approve")}</span>
                                                </button>
                                            }
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_collectionletters_delete' }) &&
                                                <button type="button" className="btn-list cursor" onClick={() => this.onDeleteDraft()}>
                                                    <span>{i18next.t("collectionLettersListTable:Delete")}</span>
                                                </button>
                                            }
                                        </div>
                                    :
                                    <div className="col-3" />
                                }

                                <div className="col-9 float-right form-inline">
                                    <div className="col d-flex justify-content-end align-items-center">
                                        <div className="ml-2"><span className="mr-2">{i18next.t("collectionLettersListTable:Date")}</span></div>
                                        <div className="ml-2">
                                            <DatePickerNoti
                                                selected={this.state.tempStartDate}
                                                name='tempStartDate'
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="ml-2">
                                            <span className="mr-1 ml-1 mr-2">{i18next.t("collectionLettersListTable:To")}</span>
                                        </div>
                                        <div className="ml-2">
                                            <DatePickerNoti
                                                selected={this.state.tempEndDate}
                                                name='tempEndDate'
                                                className="mr-2"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="ml-2 mt-1">
                                            <SearchProject callback_function={this.handleSearch} search={this.state.search} callback_function_click={this.onClickIcon}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <QueryRenderer
                                        environment={environment}
                                        query={query}
                                        variables={{
                                            status: this.props.statusCollection,
                                            numberOfTimes: this.props.numberOfTimes,
                                            startDate: this.state.startDate,
                                            endDate: this.state.endDate,
                                            search: this.state.search,
                                            reQuery: this.state.reQuery,
                                            first: this.state.first,
                                            last: this.state.last
                                        }}
                                        render={({ error, props }) => {
                                            if (error) {
                                                return <div>{error.message}</div>;
                                            } else if (props) {
                                                return (
                                                    <React.Fragment>
                                                        <div className="table-responsive fade-up card mt-3">
                                                            <table className="table table-hover table-collection">
                                                                <thead className="thead-light headTable">
                                                                    <tr>
                                                                        {this.props.status !== 'void' &&
                                                                            <th className="text-center">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={this.state.checkAll}
                                                                                    onChange={() => this.onCheckAll(props)}
                                                                                />
                                                                            </th>
                                                                        }
                                                                        <th className="text-center" width={130}>
                                                                            {i18next.t("collectionLettersListTable:Number")}
                                                                        </th>
                                                                        <th className="text-center">{i18next.t("collectionLettersListTable:Document Date")}</th>
                                                                        <th className="text-center">{i18next.t("collectionLettersListTable:Room Number")}</th>
                                                                        <th className="text-left" width={550}>{i18next.t("collectionLettersListTable:Name")}</th>
                                                                        <th className="text-right" width={150}>{i18next.t("collectionLettersListTable:Total Outstanding Balance (THB)")}</th>
                                                                        {(this.props.status === 'all' || this.props.status === 'draft') && <th className="text-center" width={130}>{i18next.t("collectionLettersListTable:Status")}</th>}
                                                                        <th className="text-center">{i18next.t("collectionLettersListTable:Creation Date")}</th>
                                                                        {this.props.status === 'paid' && <th className="text-center">{i18next.t("collectionLettersListTable:Paid Date")}</th>}
                                                                        <th className="text-left">{i18next.t("collectionLettersListTable:Issued By")}</th>

                                                                        {(this.props.status === '1' || this.props.status === '2' || this.props.status === '3' || this.props.status === '4') &&
                                                                            <th className="text-left">{i18next.t("collectionLettersListTable:Approved By")}</th>
                                                                        }

                                                                        {(this.props.status === '2' || this.props.status === '3' || this.props.status === '4') && <th className="text-center">{i18next.t("collectionLettersListTable:Ref. Document")}</th>}
                                                                        {this.props.status === "void" &&
                                                                            <React.Fragment>
                                                                                <th className="text-left">{i18next.t("collectionLettersListTable:Canceled By")}</th>
                                                                                <th className="text-center">{i18next.t("collectionLettersListTable:Cancellation Date")}</th>
                                                                                <th className="text-center">{i18next.t("collectionLettersListTable:Reason for Cancellation")}</th>
                                                                            </React.Fragment>
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {props.allCollectionLetters.edges.length > 0 && props.allCollectionLetters.edges.map((collection, index) => {
                                                                        let sum = collection?.node?.sumTotal ?? 0
                                                                        let total = sum;

                                                                        if (!(_.endsWith(collection.node.docNumber, 'XXX') && collection.node.status === 'VOID')) {
                                                                            let name = collection?.node?.contactName ?? ""

                                                                            return (
                                                                                <tr key={index}>
                                                                                    {this.props.status !== 'void' &&
                                                                                        <td className="text-center">
                                                                                            {collection.node.status !== 'VOID' && <input type="checkbox" onChange={() => this.onCheck(collection.node.id)} checked={this.state.selectedId.includes(collection.node.id)}></input>}
                                                                                        </td>
                                                                                    }
                                                                                    <td className="text-center">
                                                                                        <Link to={"/accounting/collection_letters/detail/" + collection.node.status + '/' + collection.node.id}>{collection.node.docNumber}</Link>
                                                                                    </td>
                                                                                    <td className="text-center">{collection.node.updated ? format(collection.node.updated, "DD/MM/YYYY") : '-'}</td>
                                                                                    <td className="text-center">{collection.node.contact.typeOfContact === 'RESIDENTIAL' ? collection.node.contact.name : '-'}</td>
                                                                                    <td className="text-left">{name}</td>
                                                                                    <td className="text-right">{numberWithComma(total, 0)}</td>
                                                                                    {(this.props.status === 'all' || this.props.status === 'draft') &&
                                                                                        <td className="text-center">
                                                                                            <div className={this.getClassName(collection.node.status, collection.node.numberOfTimes) + " text-center"}>
                                                                                                <span>{i18next.t("collectionLettersListTable:"+this.getStatus(collection.node.status
                                                                                                    , collection.node.numberOfTimes))}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                    }
                                                                                    <td className="text-center">{collection.node.added ? format(collection.node.added, "DD/MM/YYYY") : '-'}</td>
                                                                                    {this.props.status === 'paid' && <td className="text-center">{collection.node.issuedDate ? format(collection.node.issuedDate, "DD/MM/YYYY") : '-'}</td>}
                                                                                    <td className="text-left">{collection.node.creator}</td>
                                                                                    {(this.props.status === '1' || this.props.status === '2' || this.props.status === '3' || this.props.status === '4') &&
                                                                                        <td className="text-left">{collection.node.creatorApproval ?? "-"}</td>
                                                                                    }

                                                                                    {(this.props.status === '2' || this.props.status === '3' || this.props.status === '4') &&
                                                                                        <td className="text-center">{collection.node.refDocNumber}</td>
                                                                                    }
                                                                                    {this.props.status === "void" &&
                                                                                        <React.Fragment>
                                                                                            <td className="text-left">{collection.node.creatorDelete}</td>
                                                                                            <td className="text-center">{format(collection.node.issuedDate, "DD/MM/YYYY")}</td>
                                                                                            <td className="text-left">{collection.node.voidNote}</td>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="row">
                                                            <Pagination
                                                                changePage={this.changePage}
                                                                first={this.state.first}
                                                                last={this.state.last}
                                                                totalCount={props.allCollectionLetters.totalCount}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            }
                                            return <Loading />
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>

                }

            </Translation>
        );
    }
} export default CollectionLettersListTable;