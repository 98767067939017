import React, { useState } from 'react';
import SearchSelect from "../../libs/searchSelect";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import revenueContractAPI from '../../developer/revenueContract/revenueContractAPI';
import environment from "../../env/environment";
import { commitMutation } from "react-relay";
import format from 'date-fns/format';
import Swal from "sweetalert2";
import { graphql } from "babel-plugin-relay/macro";
import { billingperiodlistObjects, invoiceAdvanceObjects, billingPeriodMonthObjects, pricingTypesObjects } from './dataFilter'
import { Translation } from 'react-i18next';
import '../../developer/developer.scss'
import i18next from 'i18next';

const mutation = graphql`
  mutation settingInvoiceDetailTableMutation($input: VoidPreInvoiceGroupInput!) {
    voidPreInvoiceGroup(input: $input) {
      ok
    }
  }
`;
const SettingInvoiceTermsDetailTable = (props) => {
    const [flgModal, setFlgModal] = useState(false)
    const [activeModal, setActiveModal] = useState(false)
    const [preinvoiceID, setproinvoiceID] = useState(null)
    const [transactionClose, setTransactionClose] = useState(null)
    const advance = [
        { value: 0, label: i18next.t("settingAll:No") },
        { value: 1, label: i18next.t("settingAll:1 month in advance") },
        { value: 2, label: i18next.t("settingAll:2 months in advance") },
        { value: 3, label: i18next.t("settingAll:3 months in advance") },
        { value: 4, label: i18next.t("settingAll:4 months in advance") },
        { value: 5, label: i18next.t("settingAll:5 months in advance") },
        { value: 6, label: i18next.t("settingAll:6 months in advance") },
        { value: 7, label: i18next.t("settingAll:7 months in advance") },
        { value: 8, label: i18next.t("settingAll:8 months in advance") },
        { value: 9, label: i18next.t("settingAll:9 months in advance") },
        { value: 10, label: i18next.t("settingAll:10 months in advance") },
    ]
    const onOpenModal = (e) => {
        setFlgModal(true)
        setproinvoiceID(e)
    }

    const onActiveModal = (transaction) => {
        setActiveModal(true)
        setTransactionClose(transaction)
    }

    const changeActive = () => {
        setActiveModal(false)
        let condition = [];
        condition.push({
            obligationTenantID: transactionClose.obligationTenantID ? transactionClose.obligationTenantID : '',
            residentialID: atob(props.residentialID).split(":")[1],
            productAndServiceID: transactionClose.productAndServiceID,
            charged: transactionClose.charged,
            billingMonth: transactionClose.billingMonth ? transactionClose.billingMonth : '',
            advance: transactionClose.advance,
            issuedDate: format(transactionClose.issuedDate, 'YYYY-MM-DD'),
            burdenStatus: transactionClose.burdenStatus,
            dueDate: transactionClose.dueDate ? format(transactionClose.dueDate, 'YYYY-MM-DD') : null,
            activeStatus: false,
            status: '',
        })

        revenueContractAPI.createCondition(condition, localStorage.getItem("token")).then((response) => {
            if (response.status === 200) {
                Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => { props.queryPreInvoiceGroup() })
            } else {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
            }
        })

    }
    const onCloseModal = () => {
        setFlgModal(false)
    }

    const onVoidModal = () => {
        let variables = {
            input: {
                id: preinvoiceID,
                status: "void"
            },
        };
        //void preinvoice
        commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
                if (response.voidPreInvoiceGroup.ok) {
                    Swal.fire(i18next.t("settingAll:Cancel completed"), "", "success").then(() => {
                        props.queryPreInvoiceGroup()
                    })
                } else {
                    Swal.fire(i18next.t("settingAll:Invoice has using. Please cancel invoice."), "", "warning");
                }
            },
            onError: (err) => {
                Swal.fire("Error!", i18next.t("settingAll:Please try again."), "warning");
            },
        });
        setFlgModal(false)
    }

    return (
    <Translation>
    {t =>
        <React.Fragment>
            {flgModal ?
                <div className="modall-overlay">
                    <div className="modall pb-4" style={{ width: 480 }}>
                        <div className="row col-12 px-0 m-0">
                            <p className="col-11 text-headline-5 pl-0 mb-0">{t("settingAll:Disabled condition")}</p>
                            <button
                                type="button"
                                className="close col-1"
                                data-dismiss="modall"
                                aria-label="Close"
                                onClick={onCloseModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer box-button-modal"></div>
                        <h5 className="modal-title text-table-select">{t("settingAll:If disabled. This will close the item permanently. Unable to re-open or edit information again Do you want to disable this item?")}</h5>
                        <div className="modal-footer box-button-modal p-0 mt-10">
                            <button
                                className="btn cancel-modal"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={onVoidModal}
                                style={{ width: 119, color: '#F43853', fontSize: 16 }}
                            >
                                {t("contact_list:Disable")}
                            </button>
                            <button
                                className="btn btn-event-modal text-second-button"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={onCloseModal}
                            >
                                {t("Allaction:cancel")}
                            </button>
                        </div>
                    </div>
                </div>
                : null}

            {activeModal ?
                <div className="modall-overlay">
                    <div className="modall pb-4" style={{ width: 480 }}>
                        <div className="row col-12 px-0 m-0">
                            <p className="col-11 text-headline-5 pl-0 mb-0">{t("settingAll:Disabled condition")}</p>
                            <button
                                type="button"
                                className="close col-1"
                                data-dismiss="modall"
                                aria-label="Close"
                                onClick={() => setActiveModal(false)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer box-button-modal"></div>
                        <h5 className="modal-title text-table-select">{t("settingAll:If disabled. This will close the item permanently. Unable to re-open or edit information again Do you want to disable this item?")}</h5>
                        <div className="modal-footer box-button-modal p-0 mt-10">
                            <button
                                className="btn cancel-modal"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={changeActive}
                                style={{ width: 119, color: '#F43853', fontSize: 16 }}
                            >
                                {t("contact_list:Disable")}
                            </button>
                            <button
                                className="btn btn-event-modal text-second-button"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setActiveModal(false)}
                            >
                                {t("Allaction:cancel")}
                            </button>
                        </div>
                    </div>
                </div>
                : null}

            {props.status === "not" &&
                <div className="card-table">
                    <div className="table-responsive fade-up" >
                        <table className="table table-hover table-layout" style={{ fontFamily: 'Kanit' }}>
                            <thead className="table-setting text-left">
                                <tr>
                                    <th>{t("settingAll:Services code")}</th>
                                    <th>{t("settingAll:Income account")}</th>
                                    <th>{t("settingAll:Account receivables")}</th>
                                    <th>{t("settingAll:Average income")}</th>
                                    <th>{t("settingInvoiceTerms:deferred")}</th>
                                    <th>{t("settingAll:Calculation method")}</th>
                                    <th>{t("revenue_setting:Price")}</th>
                                    <th>{t("settingAll:Billing period")}</th>
                                    <th>{t("settingAll:Billing month")}</th>
                                    <th>{t("settingAll:Advance notice")}</th>
                                    <th>{t("settingInvoiceTerms:price/BillingPeriod")}</th>
                                    <th>{t("settingAll:Tenants participate")}</th>
                                    <th>{t("settingAll:Agent participate")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody key={props.status}>
                                {props.preInvoiceGroup.map((pre_invoice_group) => {

                                    return pre_invoice_group.node.preInvoice.edges.map((pre_invoice) => {
                                        const pricingTypes = pre_invoice.node.productAndService.servicePricing?.edges[0]?.node.pricingType | null;
                                        let receiveDeposit_code = "";
                                        const periodAdjustment = pre_invoice.node.incomeAvg | false;
                                        const rentPay = pre_invoice.node.rentPay | false;
                                        const agentPay = pre_invoice.node.agentPay | false;

                                        if (pre_invoice.node.productAndService.servicePricing.edges[0]?.node.deferredIncome) {
                                            receiveDeposit_code = pre_invoice.node.productAndService.servicePricing.edges[0].node.deferredIncome.chartOfAccountCode + " " + pre_invoice.node.productAndService.servicePricing.edges[0].node.deferredIncome.name
                                        }

                                        let price = 0
                                        if (pricingTypes === "INDIVIDUAL") {
                                            let Individual = pre_invoice.node.productAndService.servicePricingEachUnit.edges
                                            const Individual_find = _.find(Individual, { 'node': { 'unit': { 'id': pre_invoice_group.node.contact.residential.id } } })
                                            price = Individual_find.node.pricing | 0
                                        }
                                        else {
                                            price = pre_invoice.node.productAndService.price
                                        }

                                        let pricingType = pricingTypesObjects(pricingTypes)
                                        let billing_period_month = billingPeriodMonthObjects(pre_invoice.node.billingPeriod.toLowerCase())

                                        const color = pre_invoice.node.status === "VOID" ? { color: 'red' } : null
                                        return <tr key={pre_invoice.node.id}>
                                            <td style={color}>{pre_invoice.node.productAndService.productCode + " " + pre_invoice.node.productAndService.name}</td>
                                            <td style={color}>{pre_invoice.node.productAndService.chartOfAccount.chartOfAccountCode + " " + pre_invoice.node.productAndService.chartOfAccount.name}</td>
                                            <td style={color}>{pre_invoice.node.productAndService.customerChartOfAccount.chartOfAccountCode + " " + pre_invoice.node.productAndService.customerChartOfAccount.name}</td>
                                            <td style={color}>
                                                <input
                                                    // className="checkbox-select"
                                                    type="checkbox"
                                                    checked={periodAdjustment}
                                                    disabled />
                                            </td>
                                            <td style={color}>{periodAdjustment ? receiveDeposit_code : null}</td>
                                            <td style={color}>{pricingType}</td>
                                            <td style={color}>{numberWithComma(price)}</td>
                                            <td >
                                                <SearchSelect
                                                    value={pre_invoice.node.billingPeriod.toLowerCase()}
                                                    name="BillingPeriodList"
                                                    placeholder={t("settingAll:List")}
                                                    queryObject={billingperiodlistObjects()}
                                                    keyOfValue="value"
                                                    keyOfLabel="label"
                                                    disabled={true}
                                                />
                                            </td>
                                            <td>
                                                <SearchSelect
                                                    value={pre_invoice.node.billingPeriodList}
                                                    name="BillingPeriodMonth"
                                                    placeholder={t("settingAll:Choose month")}
                                                    queryObject={billing_period_month}
                                                    keyOfValue="value"
                                                    keyOfLabel="label"
                                                    disabled={true}
                                                /></td>
                                            <td>
                                                <SearchSelect
                                                    value={pre_invoice.node.invoiceAdvance.toLowerCase()}
                                                    name="invoiceAdvance"
                                                    placeholder={t("settingAll:Chosee advance notice")}
                                                    queryObject={invoiceAdvanceObjects()}
                                                    keyOfValue="value"
                                                    keyOfLabel="label"
                                                    disabled={true}
                                                />
                                            </td>
                                            <td style={color}>
                                                {numberWithComma(pre_invoice.node.amount)}
                                            </td>
                                            <td style={color}>
                                                <input className="checkbox-select"
                                                    type="checkbox"
                                                    checked={rentPay}
                                                    disabled />
                                            </td>
                                            <td style={color}>
                                                <input className="checkbox-select"
                                                    type="checkbox"
                                                    checked={agentPay}
                                                    disabled />
                                            </td>
                                            <td style={color}>
                                                {pre_invoice.node.status != "VOID" &&
                                                    <button onClick={() => onOpenModal(pre_invoice.node.id)} type="button" className="btn inactive-button" disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'preinvoicegroup_edit' })}>
                                                        {t("contact_list:Disable")}
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    })
                                })

                                }
                            </tbody>
                        </table>
                    </div>
                </div>}

            {props.status === "developer" &&
                <div className="card-table">
                    <div className="table-responsive fade-up" style={{ fontFamily: 'Kanit' }}>
                        <table className="table table table-hover">
                            <thead className="table-setting">
                                <tr >
                                    <th className="text-left" width={200}>{t("settingAll:Services code")}</th>
                                    <th className="text-left" >{t("settingAll:Account receivables")}</th>
                                    <th className="text-left " >{t("settingInvoiceTerms:deferred")}</th>
                                    <th className="text-right" >{t("settingAll:Advance received")}</th>
                                    <th className="text-center" >{t("settingAll:Income allocation start month")}</th>
                                    <th className="text-center">{t("settingAll:Income allocation end month")}</th>
                                    <th className="text-right" >{t("settingAll:Average")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.freebies && props.freebies.map((freebies, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{freebies.productCode + " " + freebies.name}</td>
                                            <td>{freebies.customerChartOfAccountCode + ' ' + freebies.customerChartOfAccount}</td>
                                            <td>{freebies.periodAdjustment && (freebies.receiveDepositCode + ' ' + freebies.receiveDeposit)}</td>
                                            <td className="text-right">{freebies.price}</td>
                                            <td className="text-center">{format(freebies.issuedDate, 'MM/YYYY')}</td>
                                            <td className="text-center">{format(freebies.dueDate, 'MM/YYYY')}</td>
                                            <td className="text-right">{numberWithComma(freebies.averagePerMonths)}</td>
                                            <td></td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {props.status === "owner" &&
                <div className="card-table">
                    <div className="table-responsive fade-up" style={{ fontFamily: 'Kanit' }}>
                        <table className="table table-hover table-layout">
                            <thead className="table-setting">
                                <tr >
                                    <th className="text-left" width={200}>{t("settingAll:Services code")}</th>
                                    <th className="text-left" >{t("settingAll:Income account")}</th>
                                    <th className="text-left" >{t("settingAll:Account receivables")}</th>
                                    <th className="text-center" >{t("settingAll:Calculation method")}</th>
                                    <th className="text-center">{t("revenue_setting:Price")}</th>
                                    <th className="text-center" >{t("settingAll:Average income")}</th>
                                    <th className="text-left" >{t("settingInvoiceTerms:deferred")}</th>
                                    <th className="text-left" width={130} >{t("settingAll:Billing period")}</th>
                                    <th className="text-left" width={150}>{t("settingAll:Billing month")}</th>
                                    <th className="text-center" >{t("settingAll:Advance notice(month)")}</th>
                                    <th className="text-right" >{t("settingAll:Price/Billing period")}</th>
                                    <th className="text-center" >{t("settingAll:Invoiced start date")}</th>
                                    <th className="text-center" >{t("settingAll:Invoiced due date")}</th>
                                    <th className="text-center" >{t("settingAll:Tenants participate")}</th>
                                    <th className="text-center" >{t("settingAll:Agent participate")}</th>
                                    <th width={100} />
                                </tr>
                            </thead>
                            <tbody>
                                {props.condition && props.condition.map((condition, index) => {
                                    let month = ''
                                    _.forEach(condition.billingMonth, (months, index) => {
                                        month = index === 0 ? months : month + " , " + months
                                    })
                                    return (
                                        <Translation key={index + 'trans'}>
                                            {t =>
                                                <tr key={index}>
                                                    <td>{condition.productCode + " " + condition.name}</td>
                                                    <td>{condition.chartOfAccountCode + ' ' + condition.chartOfAccount}</td>
                                                    <td>{condition.customerChartOfAccountCode + ' ' + condition.customerChartOfAccount}</td>
                                                    <td className="text-left">
                                                        <span>{t(`revenueContract:${condition.pricingType}`)}</span>
                                                    </td>
                                                    <td>{condition.price}</td>
                                                    <td className="text-center">
                                                        <input type="checkbox" disabled={true} checked={condition.periodAdjustment} />
                                                    </td>
                                                    <td>{condition.periodAdjustment && (condition.receiveDepositCode + ' ' + condition.receiveDeposit)}</td>
                                                    <td>{t(`revenueContract:${condition.charged}`)}</td>
                                                    <td>{month}</td>
                                                    <td>{_.filter(advance, ['value', condition.advance])[0].label}</td>
                                                    <td className="text-right">{condition.totalPrice}</td>
                                                    <td className="text-center"> {format(condition.issuedDate, 'DD/MM/YYYY')}</td>
                                                    <td className="text-center"> {condition.dueDate ? format(condition.dueDate, 'DD/MM/YYYY') : '-'}</td>
                                                    <td className="text-center">
                                                        <input type="checkbox" disabled={true} checked={condition.burdenStatus} />
                                                    </td>
                                                    <td id="developer-form">
                                                        <button onClick={() => onActiveModal(condition)} type="button" disabled={!condition.activeStatus || !_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'preinvoicegroup_edit' })} className={condition.activeStatus ? "close-status text-center" : "open-status text-center"}>
                                                            {condition.activeStatus ? t("contact_list:Disable") : t("settingAll:Disabled")}
                                                        </button>
                                                    </td>

                                                </tr>
                                            }
                                        </Translation>


                                    );
                                })

                                }
                            </tbody>
                        </table>
                    </div>
                </div>}

            {/* <div className="row">
        <Pagination/>
    </div> */}
        </React.Fragment>

    }

    </Translation>
    )
}

export default SettingInvoiceTermsDetailTable