import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from "react-i18next";
import i18n from 'i18next';
import _ from 'lodash';
import BackButtonIcon from '../../components/BackBtn/indexBack';
import './menuSidebar.scss'
import Select from 'react-select';
import { fetchQuery } from "relay-runtime";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { encode } from 'base-64'
import SetUpButton from './data/SetUpButton';
import onSubmitSuccess from '../../contact/chatgroup/action/onSubmitSuccess';
import onSubmitError from '../../contact/chatgroup/action/onSubmitError';
import DeleteKeyButton from './data/DeleteKeyButton';
import i18next from 'i18next';

const query = graphql`
    query settingRoleQuery($siteId: String) {
        getSiteButtonNode(siteId: $siteId){
            edges{
                node{
                    key{
                        keyName
                    }
                    show
                }
            }
        }
        getAllSite{
            edges{
                node{
                    id 
                    name
                }
            }
        },
        getAllKey{
            edges{
                node{
                    id 
                    keyName
                    description
                }
            }
        }
    }
`;
const addMenu = graphql`
    mutation settingRoleMutation($input:CreateButtonKeyInput!){
        createButtonKey(input:$input){
            ok
            message
        }
    }
`;



class SettingRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            loading: true,
            ircActive: false,
            selected: {},
            getAllSite: [],
            SiteSelected: {},
            SiteSetting: [],
            dataChange: [],
            AllKey: []
        }
        this.onAddData = this.onAddData.bind(this)
        this.onChangeData = this.onChangeData.bind(this)
        this.saveRoleButtton = this.saveRoleButtton.bind(this)
        this.onChangeSite = this.onChangeSite.bind(this)
    }

    componentWillMount() {
        this.getData(encode("SiteNode:" + localStorage.getItem("site_id")));
    }

    getData(site) {
        fetchQuery(environment, query, { siteId: site }).then(data => {
            let selectSite = data?.getAllSite?.edges?.filter((n) => n.node.id === site)
            this.setState({
                loading: false,
                getAllSite: data?.getAllSite?.edges?.map((n) => ({ ...n.node, value: n.node.id, label: n.node.name })),
                AllKey: data?.getAllKey?.edges?.map((n) => ({ ...n.node, key: data?.getSiteButtonNode?.edges?.filter((nn) => nn.node.key.keyName === n.node.keyName)[0]?.node?.show })),
                SiteSelected: { value: selectSite[0]?.node?.id, label: selectSite[0]?.node?.name },
                // dataChange: data?.getSiteButtonNode.edges.map((nn)=> ({ ...nn,[nn.node.key.keyName]: nn.node.show }))
            })
        });
    }

    onAddData() {
        Swal.fire({
            customClass: {
                title: 'swal2-title-custom',
                header: 'swal2-header-custom',
                container: 'swal2-container-custom',
                content: 'swal2-content-custom',
            },
            title: `${i18next.t("settingRoleList:Add new menu")}`,
            html:
                '<div class="row  text-left">' +
                `<div class="col-3 mt-3">${i18next.t("settingRoleList:Key name")}</div>` +
                '<div class="col-9 mt-3"><input type="text" id="keyMenu" class="form-control" placeholder="Ex. account_income" /></div>' +
                `<div class="col-3 mt-3">${i18next.t("settingRoleList:Menu name")}</div>` +
                '<div class="col-9 mt-3"><input type="text" id="nameMenu" class="form-control" placeholder="Ex. รายรับ" /></div>' +
                '</div>',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: `${i18next.t("settingRoleList:Add menu")}`,
            onOpen: function () {
                document.getElementById('keyMenu').setAttribute["required"] = ""
                document.getElementById('nameMenu').setAttribute["required"] = ""
            },

            preConfirm: () => ({
                keyMenu: document.getElementById("keyMenu").value,
                nameMenu: document.getElementById("nameMenu").value,
            }),
        }).then((c) => {
            if (c.value) {
                let variables = {
                    "input": {
                        "keyName": c?.value?.keyMenu,
                        "description": c?.value?.nameMenu
                    }
                }
                let mutation = addMenu
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.setState({ loading: false });
                            if (response.createButtonKey.ok) {
                                Swal.fire(i18n.t("resident:Save successfully!"), '', 'success').then(() => {
                                    this.getData(this.state.SiteSelected)
                                });
                            } else {
                                Swal.fire(i18n.t("resident:Save unsuccessfully!"), '', 'warning');
                            }

                        },
                        onError: (err) => {
                            this.setState({ loading: false });
                            Swal.fire('Error!', i18n.t("resident:Please try again"), 'warning')
                        },
                    },
                );
            }
        })
    }

    saveRoleButtton() {
        let data = {}
        this.state.AllKey.forEach(ele => {
            let name = ele.keyName
            let value = ele.key
            _.set(data, `${name}`, value)
        })


        let variables = {
            "input": {
                "siteId": this.state.SiteSelected.value,
                "siteButtonKey": data
            }
        }

        SetUpButton(variables, onSubmitSuccess, onSubmitError)
        this.getData(this.state.SiteSelected)
    }

    onChangeData(inx, keyName, value) {
        let AllKey = _.cloneDeep(this.state.AllKey)
        let SetUpKey = _.set(AllKey, `[${inx}].key`, value)
        this.setState({ AllKey: SetUpKey })
    }

    onChangeSite(site) {
        this.getData(site.id)
        this.setState({ SiteSelected: site, loading: true })
    }

    onDeleteButton(valueID) {
        let variables = {
            "input": {
                "keyId": valueID
            }
        }

        Swal.fire({
            title: `${i18next.t("settingRoleList:Do you want to delete this menu")}?`,
            text: `${i18next.t("settingRoleList:If this menu is deleted, it may affect the code's ability to locate it, especially if it is currently in use.")}`,
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            showCancelButton: true,
            showConfirmButton: true,
        }).then((c) => {
            if (c.value) {
                DeleteKeyButton(variables, onSubmitSuccess, onSubmitError)
                this.getData(this.state.SiteSelected)
            }
        })
    }

    render() {
        let { SiteSetting, dataChange, AllKey } = this.state;

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box claim">
                        <BackButtonIcon
                            LinkBack={"/setting"}
                            LinkText={i18n.t('MenuName:Role Setting For Menu')}
                            addLinkChk={false}
                            addLinkTest={i18next.t("settingRoleList:Create menu")}
                            addLinkButton={"/setting/SettingRole/create"}

                            addOnClickChk={localStorage.getItem("role") === "true" ? true : false}
                            addOnClickText={i18next.t("settingRoleList:Create menu")}
                            addOnClick={this.onAddData}
                        />

                        <br />
                        <br />
                        <form method='post' onSubmit={this.onSubmit}>
                            <Translation>
                                {t =>
                                    this.state.loading == false ?
                                        <React.Fragment>
                                            <div>
                                                <div className="content-inner create">
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <h4 className='text-left seletedInputBox' style={{ display: 'inline-flex' }}>
                                                                <Select
                                                                    options={this.state.getAllSite}
                                                                    value={this.state.SiteSelected}
                                                                    isSearchable={true}
                                                                    onChange={this.onChangeSite}
                                                                />
                                                            </h4>
                                                        </div>

                                                        <div className='col-12'>
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr className='bg-gray'>
                                                                        <th>{t('settingRole:Key Menu')}</th>
                                                                        <th>{t('settingRole:Key Menu Name')}</th>
                                                                        <th className='text-center'>{t('settingRole:status')}</th>
                                                                        <th />
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.AllKey?.map((n, inx) => {
                                                                            return (
                                                                                <tr key={inx} style={{ verticalAlign: 'center', alignItems: 'center' }}>
                                                                                    <th>{n.keyName}</th>
                                                                                    {/* <th>{n.description}</th> n.description ชื่อเมนูในระบบเป็นภาษาไทยทั้งหมด */}
                                                                                    <th>{t("settingAll:"+n.keyName)}</th>
                                                                                    <td className='btnSwitch text-center'>

                                                                                        <label className="switch mt-0">
                                                                                            <input type="checkbox"
                                                                                                defaultChecked={n.key}
                                                                                                onChange={() => this.onChangeData(inx, n.keyName, n.key ? false : true)}
                                                                                            />
                                                                                            <span className="slider round"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                    <td className='p-0'>
                                                                                        {
                                                                                            localStorage.getItem("role") === "true" &&
                                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/deleteRed.png'}
                                                                                                onClick={() => this.onDeleteButton(n.id)}
                                                                                                style={{ width: 20, height: 25 }} />
                                                                                        }

                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>


                                                        <div className='col-12 text-right mt-5'>
                                                            <button type="button"
                                                                onClick={this.saveRoleButtton}
                                                                className="btn btn-primary mr-1">{t('Allaction:save')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <div className="container-fluid box fixRequestFast text-center" >
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert/loading.gif'} />
                                        </div>

                                }
                            </Translation>

                        </form>


                    </div>


                </WrapperContent>
            </Wrapper >
        )
    }
}

export default SettingRole;
