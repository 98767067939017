import '../styles/main.scss';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import Prepaid from './prepaid';
import PrepaidAccountRecord from './prepaidAccountRecord'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import WithholdingTaxPrint from "../witholding_tax/withholdingTaxPrint";
import { QueryRenderer } from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperPrepaidQuery($id: ID!, $ref_transaction: String) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        prepaidDeposit(id: $id) {
            id
            contact {
                refNumber
                name
                taxIdNumber
                firstName
                lastName
                phone
                email
                typeOfCompany
                typeOfSupplier
                typeOfContact
                nameBranch
                registeredAddress
                registeredCountry
                registeredProvince
                registeredDistrict
                registeredCity
                registeredPostalCode
                residential {
                    id
                    size
                }
            }
            docNumber
            issuedDate
            status
            creator
            remark
            total
            refNumber
            taxType
            taxInvoiceNumber
            taxInvoiceDate

            prepaidDepositTransaction {
                totalCount
                edges{
                    node{
                        id
                        chartOfAccount{
                            id name chartOfAccountCode
                        }
                        prepaidDeposit{
                            docNumber
                            issuedDate
                            taxType
                        }
                        vat
                        whtRatePercent
                        price
                        description
                        vatAmount
                        whtAmount
                        prepaidDepositTransactionUsed{
                            edges{
                                node{
                                    id
                                    amount
                                    purchaseRecord{
                                        purchaseRecordGroup{
                                            docNumber
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            withholdingTax{
                edges {
                    node {
                        id
                        docNumber
                        total
                        unknownContact
                        prepaidDeposit{
                            id
                            docNumber
                            issuedDate
                            status
                            creator
                            remark
                            total
                            refNumber
                            taxType
            
                            prepaidDepositTransaction {
                                totalCount
                                edges{
                                    node{
                                        id
                                        chartOfAccount{
                                            id name chartOfAccountCode
                                        }
                                        total
                                        vat
                                        whtRatePercent
                                        price
                                        description
                                        vatAmount
                                        whtAmount
                                    }
                                }
                            }
                        }
                        contact {
                            id
                            taxIdNumber
                            typeOfSupplier
                            name
                            refNumber
                            firstName
                            lastName
                            registeredAddress
                            registeredCity
                            registeredCountry
                            registeredDistrict
                            registeredName
                            registeredPostalCode
                            registeredProvince
                        }
                        issuedDate
                        added
                        total
                        remark
                    }
                }
            }

            bankAccountTransaction {
                edges {
                    node {
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction {
                edges {
                    node {
                        id
                        price
                    }
                }
            }
            chequeTransaction {
                edges {
                    node {
                        id
                        date
                        bankAccount {
                            id
                            bankName
                            branch
                        }
                        chequeNumber
                        price
                    }
                }
            }
        }

        allAccountRecordGroup(refTransaction: $ref_transaction) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator issuedDate
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
        userConfig {
            id languages
        }

    }
`;

class WrapperPrepaid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type: 'all'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Prepaid</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>
                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeAll"
                                    onChange={this.handleInputChange}
                                    value="all"
                                    checked={this.state.check_type === 'all'} />
                                <label className="form-check-label" htmlFor="checkTypeAll">
                                    ทั้งหมด
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeManuscript"
                                    value="manuscript"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_type === 'manuscript'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    ต้นฉบับ
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeCopy"
                                    value="copy"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_type === 'copy'} />
                                <label className="form-check-label" htmlFor="checkTypeCopy">
                                    สำเนา
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{ id: this.props.match.params.id,ref_transaction: this.props.match.params.ref_transaction }}
                    render={({ error, props }) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                role="alert">{error.message}</div>;
                        } else if (props) {
                            let count_page = Math.ceil((props.prepaidDeposit.prepaidDepositTransaction.totalCount) / 20);
                            let arrX = Array.from(new Array(count_page));
                            let transaction_per_page = 20;
                            if (this.state.check_type === "all") {

                                return (
                                    <React.Fragment>
                                        {arrX.map((value, index) => {
                                            // original
                                            return (
                                                <Prepaid selfProject={props.selfProject}
                                                    prepaidDeposit={props.prepaidDeposit}
                                                    prepaidDepositTransaction={props.prepaidDeposit.prepaidDepositTransaction.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                    check_type={"manuscript"}
                                                    pageNumber={index + "manuscript" + atob(props.prepaidDeposit.id).split(':')[1]}
                                                    end_page={count_page - 1}
                                                    current_page={index}
                                                    key={index}
                                                    languages={props.userConfig.languages}
                                                />
                                            )
                                        }
                                        )}
                                        {arrX.map((value, index) => {
                                            // GL
                                            return (
                                                <PrepaidAccountRecord selfProject={props.selfProject}
                                                    prepaidDeposit={props.prepaidDeposit}
                                                    allAccountRecordGroup={props.allAccountRecordGroup}
                                                    pageNumber={index + "copy" + atob(props.prepaidDeposit.id).split(':')[1]}
                                                    end_page={count_page - 1}
                                                    current_page={index}
                                                    key={index}
                                                    languages={props.userConfig.languages}
                                                />
                                            )
                                         })
                                        }

                                        {props.prepaidDeposit.withholdingTax?.edges[0]?.node && [1, 2, 3, 4].map((count_paper, index) => {
                                            return (<WithholdingTaxPrint query={props} withholdingTax={props.prepaidDeposit.withholdingTax.edges[0].node} count_paper={count_paper} key={index}/>)
                                        })}
                                    </React.Fragment>
                                )

                            } else {
                                return (
                                    <React.Fragment>
                                        {arrX.map((value, index) => {
                                            return (
                                                <Prepaid selfProject={props.selfProject}
                                                    prepaidDeposit={props.prepaidDeposit}
                                                    prepaidDepositTransaction={props.prepaidDeposit.prepaidDepositTransaction.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                    check_type={this.state.check_type}
                                                    pageNumber={index + "receive" + atob(props.prepaidDeposit.id).split(':')[1]}
                                                    end_page={count_page - 1}
                                                    current_page={index}
                                                    key={index}
                                                    languages={props.userConfig.languages}
                                                />
                                            )
                                        })}

                                    </React.Fragment>
                                )
                            }

                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperPrepaid;
