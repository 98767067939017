import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import WithholdingAttachmentTax53Print from "./withholdingAttachmentTax53Print"
import WithholdingAttachmentTax53WithDataPrint from "./withholdingAttachmentTax53WithDataPrint"
import WithholdingAttachmentTaxPrint from "./withholdingAttachmentTaxPrint"
import _ from "lodash";
import Loading from '../../libs/loading';


const query = graphql`
    query wrapperAttachmentWithholdingTax53Query($start_date: DateTime, $end_date: DateTime,){
        selfProject {
            taxIdNumber
        }
        withholdingTaxViewer{
            allWithholdingTax(pnd: "pnd-53",status: "active" ,startDate: $start_date, endDate: $end_date){
                totalCount
                edges{
                    node{
                        id
                        docNumber
                        unknownContact
                        contact {
                            id
                            name
                            refNumber
                            firstName
                            lastName
                            taxIdNumber
                            typeOfSupplier
                            nameBranch
                            registeredName
                            registeredAddress
                            registeredCountry
                            registeredProvince
                            registeredCity
                            registeredDistrict
                            registeredPostalCode
                        }

                        totalOtherExpense
                        totalPayRecord
                        totalPrepaidDeposit

                        payRecordGroup{
                            docNumber
                            payRecord{
                                edges{
                                    node{
                                        whtAmount
                                        whtRatePercent
                                        purchaseRecord{
                                            id
                                            preTaxAmount
                                            whtRate
                                            description
                                            whtRatePercent

                                            typeOfIncome
                                            typeOfIncomeOther
                                            typeOfTax
                                            typeOfTaxOther
                                            taxRate
                                            
                                            productAndService{
                                                taxRate
                                                taxRateOther
                                                typeOfTax
                                            }
                                            prepaidDepositTransactionUsed {
                                                edges {
                                                    node {
                                                        amount
                                                        prepaidDepositTransaction{
                                                            whtRatePercent
                                                            vat
                                                        }
                                                    }
                                                }
                                            }
                                            acceptCreditNoteTransaction(status: "paid"){
                                                edges {
                                                    node {
                                                        price
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        otherExpense{
                            otherExpenseTransaction{
                                edges{
                                    node{
                                        preTaxAmount
                                        whtRate
                                        description
                                        whtRatePercent

                                        typeOfIncome
                                        typeOfIncomeOther
                                        typeOfTax
                                        typeOfTaxOther
                                        taxRate

                                        productAndService{
                                                taxRate
                                                taxRateOther
                                                typeOfTax
                                            }

                                    }
                                }
                            }
                        }
                        prepaidDeposit{
                            prepaidDepositTransaction{
                                edges{
                                    node{
                                        whtRatePercent
                                        whtAmount
                                        description
                                        vat
                                        price
                                        total
                                    }
                                }
                            }
                        }
                        issuedDate
                        added
                        total
                        remark
                    }
                }
            }
        }
    }
`;


class WrapperAttachmentWithholdingTax53 extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

     handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                            background-color: #FAFAFA;
                          }
                        
                          * {
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                          }
                        
                          .page {
                            width: 297mm;
                            min-height: 210mm;
                            padding: 5mm;
                            margin: 10mm auto;
                            border: 1px #D3D3D3 solid;
                            border-radius: 2px;
                            background: white;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                          }
                        
                          @page {
                            size: landscape;
                            margin: 0;
                          }
                          @media print {
                            html, body {
                              width: 297mm;
                              height: 210mm;
                            }
                            .page {
                              margin: 0;
                              border: initial;
                              border-radius: initial;
                              width: initial;
                              min-height: initial;
                              box-shadow: initial;
                              background: initial;
                              page-break-after: always;
                            }
                          }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>AttachmentWithholdingTax53</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>
                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>

                <div>
                    <div className="page">
                        <WithholdingAttachmentTaxPrint
                            type_tax={"pnd-53"}
                            page_type={this.props.match.params.type}
                            start_date={this.props.location.state ? this.props.location.state.start_date : ""}
                            end_date={this.props.location.state ? this.props.location.state.end_date : ""}
                        />
                    </div>
                </div>

                {this.props.match.params.type === 'not-data' ?
                    <div className="page">
                        <WithholdingAttachmentTax53Print/>
                    </div>
                    :
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{
                            start_date: this.props.location.state.start_date,
                            end_date: this.props.location.state.end_date,
                        }}
                        render={({error, props}) => {
                            if (error) {
                                return <div>{error.message}</div>;
                            } else if (props) {

                                let data_group_array = [];
                                let data = [];
                                let trim_data = [];
                                let record = {}
                                let order = 1;
                                let maxLength = 0
                                props.withholdingTaxViewer.allWithholdingTax.edges.map(value => {
                                    if (value.node?.otherExpense) {
                                        let otherExpenseDescription = 
                                            value.node?.otherExpense.otherExpenseTransaction.edges[0].node?.typeOfIncomeOther?
                                            value.node?.otherExpense.otherExpenseTransaction.edges[0].node?.typeOfIncomeOther.length:
                                            value.node?.otherExpense.otherExpenseTransaction.edges[0].node?.description.length
                                        maxLength = maxLength < otherExpenseDescription ? otherExpenseDescription : maxLength
                                    }else if(value.node?.payRecordGroup){
                                        let payRecordGroupDescription = 
                                            value.node?.payRecordGroup?.payRecord?.edges[0].node.purchaseRecord?.typeOfIncomeOther?
                                            value.node?.payRecordGroup?.payRecord?.edges[0].node.purchaseRecord?.typeOfIncomeOther.length:
                                            value.node?.payRecordGroup?.payRecord?.edges[0].node.purchaseRecord?.description.length
                                        maxLength = maxLength < payRecordGroupDescription ? payRecordGroupDescription : maxLength
                                    }
                                    //OE
                                    if(value.node.otherExpense){
                                        if(value.node.otherExpense.otherExpenseTransaction.edges.length > 3){
                                            trim_data = _.chunk(value.node.otherExpense.otherExpenseTransaction.edges,3)
                                            trim_data.map((trim_data,index) => {
                                                let isSliceData = false;
                                                if(index !== 0){
                                                    isSliceData = true;
                                                }
                                                let total = _.sumBy(trim_data,'node.whtRate')
                                                let totalOtherExpense = _.sumBy(trim_data,'node.preTaxAmount')
                                                record = {node : {added : value.node.added, contact : value.node.contact, docNumber:value.node.docNumber, id : value.node.id, issuedDate:value.node.issuedDate, payRecordGroup:value.node.payRecordGroup, 
                                                    prepaidDeposit:value.node.prepaidDeposit, remark:value.node.remark, total:total, totalOtherExpense:totalOtherExpense,totalPayRecord:value.node.totalPayRecord, totalPrepaidDeposit:value.node.totalPrepaidDeposit,
                                                    unknownContact:value.node.unknownContact,
                                                    otherExpense : {otherExpenseTransaction : {edges : trim_data}}},
                                                    order: order , isSliceData : isSliceData}
                                                    data.push(record)
                                            })
                                            order += 1
                                        }else{
                                            record = _.clone(value)
                                            record = {...record,order:order, isSliceData : false}
                                            data.push(record)
                                            order += 1
                                        }
                                        //PS
                                    }else if(value.node.payRecordGroup){
                                        if(value.node.payRecordGroup.payRecord.edges.length > 3){
                                            trim_data = _.chunk(value.node.payRecordGroup.payRecord.edges,3)
                                            trim_data.map((trim_data,index) => {
                                                let isSliceData = false;
                                                if(index !== 0){
                                                    isSliceData = true;
                                                }
                                                let total = _.sumBy(trim_data,'node.purchaseRecord.whtRate')
                                                let totalPayRecord = _.sumBy(trim_data,'node.purchaseRecord.preTaxAmount')
                                                record = {node : {added : value.node.added, contact : value.node.contact, docNumber:value.node.docNumber, id : value.node.id, issuedDate:value.node.issuedDate, otherExpense:value.node.otherExpense, 
                                                    prepaidDeposit:value.node.prepaidDeposit, remark:value.node.remark, total:total, totalOtherExpense:value.node.totalOtherExpense,totalPayRecord:totalPayRecord, totalPrepaidDeposit:value.node.totalPrepaidDeposit,
                                                    unknownContact:value.node.unknownContact,
                                                    payRecordGroup : {payRecord : {edges : trim_data}}},
                                                    order: order , isSliceData : isSliceData}
                                                    data.push(record)
                                            })
                                            order += 1
                                        }else{
                                            record = _.clone(value)
                                            record = {...record,order:order, isSliceData : false}
                                            data.push(record)
                                            order += 1
                                        }
                                    }else{
                                        record = _.clone(value)
                                        record = {...record,order:order, isSliceData : false}
                                        data.push(record)
                                        order += 1
                                    }
                                })
                                if (data <= 6) {
                                    data_group_array = [...data]
                                // if (props.withholdingTaxViewer.allWithholdingTax.edges.lenght <= 6) {
                                //     data_group_array = [...props.withholdingTaxViewer.allWithholdingTax.edges]
                                } else {
                                    data_group_array = _.chunk(data, 6)
                                }
                                return <React.Fragment>
                                    {data_group_array.map((data_array, index) => {
                                            return (
                                                <div className="page" key={index}>
                                                    <WithholdingAttachmentTax53WithDataPrint
                                                        key={'page-' + index}
                                                        page={index + 1}
                                                        total_page={data_group_array.length}
                                                        project={props.selfProject}
                                                        data_array={data_array}
                                                        start_page={index*6+1}
                                                        maxLength={maxLength}
                                                    />
                                                </div>
                                            )
                                        }
                                    )}
                                </React.Fragment>
                            }
                            return <div className="text-center"><Loading/></div>;
                        }}
                    />
                }


            </React.Fragment>
        );
    }
}

export default WrapperAttachmentWithholdingTax53;
