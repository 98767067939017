import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import ReceiptDepositCreateForm from "./receiptDepositCreateForm";
import Loading from '../../../libs/loading';

const receiptDepositContactEditForm = graphql`
    query receiptDepositContactEditFormQuery($id: ID!) {
        receiptDeposit(id: $id){
            id
            issuedDate
            contact{
                id
                refNumber
                name
                firstName
                lastName

                registeredAddress
                registeredCountry
                registeredProvince
                registeredDistrict
                registeredCity
                registeredPostalCode
                typeOfContact
            }
            docNumber
            remark
            total
            receiptDepositTransaction{
                edges{
                    node{
                        id
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        price
                    }
                }
            }
        }
        viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: "2123") {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
    }
`;

class ReceiptDepositContactEditForm extends Component {

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={receiptDepositContactEditForm}
                variables={{id: this.props.receiptDepositId}}
                cacheConfig={{use_cache: false}}
                render={({error, props}) => {
                    if (props) {
                        return <ReceiptDepositCreateForm customerList={[props.receiptDeposit.contact.id]}
                                                         contact={props.receiptDeposit.contact}
                                                         receiptDepositQuery={props}
                                                         query={receiptDepositContactEditForm}
                                                         isHasAgentRole={true}
                        />
                    } else {
                        return <Loading/>
                    }
                }}
            />
        )
    }
}

export default ReceiptDepositContactEditForm;
