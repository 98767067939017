import React, { Component } from 'react'
import { Translation } from 'react-i18next';
import FileInput from './fileInput';
import _ from 'lodash'

export default class contactInfomation extends Component {

    handleChangInput = (e) => {
        this.props.onChange({
            target: {
                name: e.target.name,
                value: e.target.value,
            }
        });
    }

    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return (
            <Translation>
                {t=>
                    <React.Fragment>
                        {this.props.editProjectInfo 
                            ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col">
                                                <h6>{t("PageDetailProjectInfo:ChannelContact")}</h6>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-4">
                                                <p>{t("PageDetailProjectInfo:JuristicPersonal")}</p>
                                            </div>
                                            <div className="col-8">
                                                <input type="text" 
                                                    className="form-control form-control-sm col-8" 
                                                    name="projectInfo.juristicContactNumber" 
                                                    value={this.props.projectInfo.juristicContactNumber}
                                                    onChange={this.handleChangInput}
                                                    />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-4">
                                                <p>{t("PageDetailProjectInfo:SecurityGuard")}</p>
                                            </div>
                                            <div className="col-8">
                                                <input type="text" 
                                                    className="form-control form-control-sm col-8" 
                                                    name="projectInfo.securityContactNumber" 
                                                    value={this.props.projectInfo.securityContactNumber}
                                                    onChange={this.handleChangInput}
                                                    />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-4">
                                                <p>{t("PageDetailProjectInfo:Employee")}</p>
                                            </div>
                                            <div className="col-8">
                                                <input type="text" 
                                                    className="form-control form-control-sm col-8" 
                                                    name="projectInfo.technicianContactNumber" 
                                                    value={this.props.projectInfo.technicianContactNumber}
                                                    onChange={this.handleChangInput}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            :
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col">
                                                <h6>{t("PageDetailProjectInfo:ChannelContact")}</h6>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-5">
                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                    <p>{textData?.data?.contactInformation.develoementManager}</p>
                                                        :
                                                    <p>{t("PageDetailProjectInfo:JuristicPersonal")}</p>
                                                }
                                            </div>
                                            <div className="col-7">
                                                <p>{this.props.projectInfo.juristicContactNumber}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-5">
                                                <p>{t("PageDetailProjectInfo:SecurityGuard")}</p>
                                            </div>
                                            <div className="col-7">
                                                <p>{this.props.projectInfo.securityContactNumber}</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-5">
                                                <p>{t("PageDetailProjectInfo:Employee")}</p>
                                            </div>
                                            <div className="col-7">
                                                <p>{this.props.projectInfo.technicianContactNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className="row my-3">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col d-flex align-items-end mb-3">
                                        <h6 className="m-0 col-4">{t("PageDetailProjectInfo:Document")}</h6>
                                        {this.props.editProjectInfo && 
                                            <React.Fragment>
                                                <div className="upload-file mx-2 col-3">
                                                    <label className="btn btn-sm btn-outline-primary mb-0" htmlFor="CustomFile">@ {t("PageDetailProjectInfo:ChooseFile")}</label>
                                                    <div className="upload-input">
                                                        <FileInput
                                                            id="CustomFile"
                                                            name="projectInfo.fileBuilding.edges"
                                                            appendFileInputRow={this.props.appendFileInputRow}
                                                            />
                                                    </div>
                                                </div>
                                                <span className="text-secondary">{t("PageDetailProjectInfo:SupportJust")}</span>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                                {this.props.projectInfo.fileBuilding?.edges.length > 0 && this.props.projectInfo.fileBuilding?.edges.map((doc, index) => {
                                    if((doc.node.status && doc.node.status !== 'delete') || !doc.node.status){return (
                                        <div className="row" key={index}>
                                            <div className="col d-flex align-items-center">
                                                <img src={process.env.PUBLIC_URL + "/images/icons/pdf-icon.png"} alt="Document"/>
                                                <a download={doc.node.nameFile}
                                                    href={doc.node.fileUpload}>
                                                    <span className="cursor text-blue">{doc.node.nameFile}</span>
                                                </a>
                                                {this.props.editProjectInfo && 
                                                    <div className="btn-group dropdown pb-2">
                                                        <span className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                            <img src={process.env.PUBLIC_URL + "/images/icons/choice.png"} alt="choice"/>
                                                        </span>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {/* <span className="dropdown-item">
                                                                <img src={process.env.PUBLIC_URL + "/images/icons/edit.png"} alt="edit"/>
                                                                <span className="ml-3">แก้ไขชื่อไฟล์</span>
                                                            </span> */}
                                                            {/* <div className="dropdown-divider" /> */}
                                                            <span className="dropdown-item" onClick={() => this.props.deleteRow(this.props.projectInfo.fileBuilding.edges,doc.node.id !== '' ? doc.node.id : index ,"projectInfo.fileBuilding.edges")}>
                                                                    <img src={process.env.PUBLIC_URL + "/images/icons/bin.png"} alt="bin"/>
                                                                    <span className="ml-3">{t("PageDetailProjectInfo:Delete")}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        )}
                                    })
                                }
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}
