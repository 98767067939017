import { commitMutation} from 'react-relay'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment"; 


const mutation = graphql`
mutation updateReadMessageMutation ($input: CreateRoomGroupMessageReadInput!) {
    createRoomGroupMessageRead (input: $input) {
        ok
        message
    }
}
`; 

export default function UpdateReadMessage(id) {
    let variables ={ 
        "input": {
            "messageRead": {
                "roomGroupId": id
            }
        }
    }

    commitMutation(
        environment,
        {
            mutation,
            variables
        },
    )
}
