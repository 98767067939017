import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import PurchaseDetail from "./purchaseDetail";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import Wrapper from "../../../components/wrapper";

const query = graphql`
    query purchaseDetailQuery($id: ID!) {
        purchaseRecordGroup(id: $id) {
            id
            docNumber
            issuedDate
            dueDate
            taxType
            remark
            status
            contact {
                name
                refNumber
                firstName
                lastName
                registeredName
                registeredAddress
                registeredCountry
                registeredProvince
                registeredCity
                registeredDistrict
                registeredPostalCode
                typeOfContact
            }

            firstName 
            lastName
            name
            phone
            email
            taxIdNumber
            address
            district
            city
            province
            postalCode
            country

            purchaseOrderes{
                id
                docNumber
                procurementForm {
                    type
                }
            }
            taxInvoiceNumber
            taxInvoiceDate
            taxInvoicePeriod
            refNumber
            refPurchaseDoc
            purchaseRecord {
                edges {
                    node {
                        id
                        chartOfAccount {
                            chartOfAccountCode
                            name
                        }
                        productAndService {
                            productCode
                            name
                            noNumber
                        }
                        stockPurchaseRecord{
                            edges {
                                node {
                                    id
                                    stockName
                                }
                            }
                        }
                        description
                        unitItems
                        price
                        discount
                        vat
                        vatAmount
                        preTaxAmount
                        whtRate
                        whtRatePercent
                        prepaidDepositTransactionUsed{
                            edges{
                                node{
                                    id
                                    amount
                                    prepaidDepositTransaction{
                                        description
                                        vat
                                        whtRatePercent
                                        chartOfAccount{
                                            chartOfAccountCode
                                            name
                                        }
                                        prepaidDeposit{
                                            docNumber
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

class PurchaseDetailQuery extends Component {
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{"id": this.props.match.params.id}}
                    render={({error, props}) => {
                        if (error) {
                            return <div>Error :(</div>;
                        } else if (props) {
                            return <PurchaseDetail purchaseQuery={props}
                                                   contact={props.purchaseRecordGroup.contact}
                                                   purchaseRecordGroup={props.purchaseRecordGroup}
                                                   transactions={props.purchaseRecordGroup.purchaseRecord.edges}
                            />
                        }
                    }}
                />
            </Wrapper>
        )
    }
}

export default PurchaseDetailQuery;
