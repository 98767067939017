import React from "react";
import SearchSelect from "../../../../libs/searchSelect";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import PayPaymentChannelChequeX from "./payPaymentChannelChequeInPay";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import i18next from "i18next";

const _ = require('lodash');
const $ = window.jQuery;


const query = graphql`
    query payPaymentChannelFormInPayQuery($makePayment: Boolean, $receivePayment: Boolean, $accountType: String) {
        bankAccountViewer {
            allBankAccount(status: true, accountType_In:$accountType, makePayment:$makePayment, receivePayment:$receivePayment) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                        hasCheque
                        branch
                    }
                }
                totalCount
            }
            allBankAccountCheque(status: true, accountType_In:$accountType, makePayment:$makePayment, receivePayment:$receivePayment) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                        hasCheque
                        branch
                    }
                }
                totalCount
            }
        }
        
        allSetPettyCash(status:"active") {
            totalCount
            edges {
              node {
                id
                docNumber
                description
                withdrawer
                withdrawAmount
                totalPettyCashRecord
              }
            }
          }
    }
`;


const pay_channel = {
    slug: "",
    pay: "",
    image: false,
    petty_cash: "",
    cheque: {
        chequeNumber: ''
    },
    date: new Date(),
    type_other_pay: false,
};


const cash_channel_choice = [
    {
        node: {
            id: '1',
            slug: "cash",
            accountName: 'เงินสด',
        }
    },
];

const petty_cash_channel_choice = [
    {
        node: {
            id: '3',
            slug: "petty_cash",
            accountName: 'เงินสดย่อย',
        }
    },

];

const cheque_channel_choice = [
    {
        node: {
            id: '2',
            slug: "cheque",
            accountName: 'เช็ค',
        }
    },

];

const other_chanel = [
    {
        node: {
            id: '4',
            slug: "fee",
            accountName: 'Bank Fees',
        }
    },
    {
        node: {
            id: '3',
            slug: "small-change",
            accountName: 'Expenses incurred due to the fractional penny difference',
        }
    },
    {
        node: {
            id: '5',
            slug: "small-change-income",
            accountName: 'Income generated from the fractional penny difference',
        }
    },
    {
        node: {
            id: '6',
            slug: "retention",
            accountName: 'Performance insurance',
        }
    },
];

const list_expense = ['petty_cash', 'pay'];

class PayPaymentChannelFormInPay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pay_channel: [],
            channel_choice: [],
            cheque_choice: [],
            auto_suggest: '',
            channel_other_choice: [],
            petty_cash: [],
        };
        this.state.single_payment = props.single_payment ? props.single_payment : false;
        this.state.makePayment = list_expense.includes(props.payment_type) ? true : null;
        this.state.receivePayment = list_expense.includes(props.payment_type) ? null : true;
        this.addPayChannel = this.addPayChannel.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteChannel = this.deleteChannel.bind(this);
        this.changeDateFromIssuedDate = this.changeDateFromIssuedDate.bind(this);

        this.handleModalShowClick = this.handleModalShowClick.bind(this);
        this.handleModalCloseClick = this.handleModalCloseClick.bind(this);
        this.onAddOtherChannel = this.onAddOtherChannel.bind(this);
        this.calDiffPay = this.calDiffPay.bind(this);
        this.onCheckShowButton = this.onCheckShowButton.bind(this);
    }

    componentWillMount() {
        fetchQuery(environment, query, {
            makePayment: this.state.makePayment,
            receivePayment: this.state.receivePayment,
            accountType: "current_account,saving_account,saving_deposit"
        }).then(response_data => {
            let bank_list = [];
            let cheque_choice = [];
            let petty_case = [];
            if (_.isObject(response_data.bankAccountViewer)) {
                let query_bank_data = _.cloneDeep(response_data);
                query_bank_data.bankAccountViewer.allBankAccount.edges.forEach((bank) => {
                    _.set(bank, 'node.slug', 'bank:' + bank.node.id);
                    _.set(bank, 'node.type', 'bank');

                    let account_type = 'ออมทรัพย์';
                    if (bank.node.accountType === 'CURRENT_ACCOUNT') {
                        account_type = 'กระแสรายวัน'
                    } else if (bank.node.accountType === 'FIXED_DEPOSIT') {
                        account_type = 'ฝากประจำ'
                    }
                    _.set(bank, 'node.account_type', account_type);
                    bank_list.push(bank)
                });
                query_bank_data.bankAccountViewer.allBankAccountCheque.edges.forEach((bank_cheque) => {
                    if (bank_cheque.node.accountType === 'CURRENT_ACCOUNT') {
                        if (bank_cheque.node.hasCheque) {
                            cheque_choice.push(bank_cheque)
                        }
                    }
                });
            }
            if (_.isObject(response_data.allSetPettyCash)) {
                let query_petty_case_date = _.cloneDeep(response_data);
                query_petty_case_date.allSetPettyCash.edges.forEach((petty) => {
                    const total = Math.round(((petty.node.withdrawAmount - petty.node.totalPettyCashRecord) + Number.EPSILON) * 100) / 100;
                    _.set(petty, 'node.total', total);
                    petty_case.push(petty)
                });
            }
            let update_channel_choice = [];
            let channel_other_choice = [];

            if (this.props.not_other_channel) {
                update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice,]
            } else {
                update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...other_chanel,]
            }

            if (this.props.pay_form) {
                update_channel_choice = [...cash_channel_choice, ...petty_cash_channel_choice, ...bank_list, ...cheque_channel_choice];
                channel_other_choice = [...other_chanel];
            }

            this.setState(
                {
                    pay_channel: [pay_channel],
                    channel_choice: update_channel_choice,
                    cheque_choice: cheque_choice,
                    channel_other_choice: channel_other_choice,
                    petty_cash: petty_case,
                }, () => this.changeDateFromIssuedDate('pay'))
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.issued_date !== this.props.issued_date) {
            this.changeDateFromIssuedDate('date')
        }
        if (prevProps.auto_suggest !== this.props.auto_suggest && this.state.pay_channel.length > 0) {
            this.changeDateFromIssuedDate('pay');
        }
    }

    changeDateFromIssuedDate(name) {
        let channel_list = _.cloneDeep(this.state.pay_channel);
        let value = '';
        if (name === 'date') {
            if (this.props.page === "clear_advance" && channel_list.length > 0) {
                value = channel_list[0].date || this.props.issued_date
            } else {
                value = this.props.issued_date
            }
        } else {
            value = this.props.auto_suggest
        }

        channel_list.forEach((channel, index) => {
            if ((name === 'pay' && index === 0) || name === 'date') {
                channel[name] = value;
            }

        });
        this.setState({ pay_channel: channel_list }, () => this.props.updatePayChannel(this.state.pay_channel));
        this.setState({ pay_channel: channel_list });

    }

    addPayChannel() {
        let channel = _.cloneDeep(this.state.pay_channel);
        channel.push(pay_channel);
        this.setState({ pay_channel: channel });
    }

    deleteChannel(index) {
        let channel = _.cloneDeep(this.state.pay_channel);
        channel.splice(index, 1);
        if (channel.length > 0) {
            this.setState({ pay_channel: channel }, () => {
                this.props.updatePayChannel(this.state.pay_channel);
            });
        }
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        let state_group = event.target.name.split('.')[0];
        let state_key = event.target.name.split('.')[1];
        if (event.target.type === 'file') {
            if (event.currentTarget.files[0].type.split("/")[0] === 'image') {
                value = e.currentTarget.files[0];
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        event.target.name,
                        value
                    );
                }, () => {
                    this.props.updatePayChannel(this.state.pay_channel);
                });
            } else {
                Swal.fire(i18next.t("Allaction:Invalid file type"), i18next.t("Allaction:Please check again"), 'warning');
            }

        } else {
            if (state_key === 'petty_cash') {
                let requiredKeys = [event.target.name, state_group + '.pay'];
                let value_set = [value];
                this.state.petty_cash.forEach((petty_cash) => {
                    if (e.target.dataset.id === 0) {
                        if (value === petty_cash.node.id) {
                            let find_petty_cash = _.find(this.state.pay_channel, { petty_cash: petty_cash.node.id });
                            if (!find_petty_cash) {
                                value_set = [...value_set, this.props.auto_suggest]
                                // if ((petty_cash.node.total - (this.props.auto_suggest)) > 0) {
                                //     value_set = [...value_set, this.props.auto_suggest]
                                // }
                                // else{
                                //     // value_set = [...value_set, petty_cash.node.total]
                                //     value_set = [...value_set, this.props.auto_suggest]
                                // }
                            } else {
                                Swal.fire(i18next.t("payChannelInPay:Duplicate petty cash items were selected."), i18next.t("Allaction:Please check again"), 'warning');
                                return;
                            }
                        }
                    } else {
                        if (value === petty_cash.node.id) {
                            let find_petty_cash = _.find(this.state.pay_channel, { petty_cash: petty_cash.node.id });
                            if (!find_petty_cash) {
                                const value_petty = this.props.auto_suggest - this.props.pay;
                                value_set = [...value_set, value_petty]
                                // if ((petty_cash.node.total - (this.props.auto_suggest - this.props.pay)) > 0) {
                                //     const value_petty = this.props.auto_suggest - this.props.pay;
                                //     value_set = [...value_set, value_petty]
                                // }
                                // else{
                                //     value_set = [...value_set, petty_cash.node.total]
                                // }
                            } else {
                                Swal.fire(i18next.t("payChannelInPay:Duplicate petty cash items were selected."), i18next.t("Allaction:Please check again"), 'warning');
                                return;
                            }
                        }
                    }
                });

                this.setState(prevState => {
                    let cloneDeep = _.cloneDeep(prevState);
                    return _.set(cloneDeep, '', requiredKeys.forEach(function (requiredKeys, index) {
                        return _.set(cloneDeep, requiredKeys, value_set[index]);
                    }));
                }, () => {
                    this.props.updatePayChannel(this.state.pay_channel)
                });
                return;

            }

            //เงินสดย่อยจ่ายไม่เกินจำนวนของเงินสดย่อยที่เลือก
            // if(state_key === 'pay' && this.state.pay_channel[e.target.dataset.id].slug === "petty_cash"){
            //     let find_petty_cash = _.find(this.state.petty_cash, { node: { id: this.state.pay_channel[e.target.dataset.id].petty_cash } });
            //     if(parseFloat(find_petty_cash.node.total) < parseFloat(value)){
            //         Swal.fire('ใช้จำนวนเงินสดย่อยเกินรายการเงินสดย่อย!', i18next.t("Allaction:Please check again"), 'warning');
            //         return;
            //     }
            // }

            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    event.target.name,
                    value
                );
            }, () => {
                this.props.updatePayChannel(this.state.pay_channel);
            });
        }
    }

    onAddOtherChannel(type) {
        let amount_pay = this.calDiffPay('value');
        let channel = _.cloneDeep(this.state.pay_channel);
        let channel_object = { ...pay_channel };
        channel_object.date = this.props.issued_date;
        channel_object.slug = type;
        channel_object.pay = amount_pay;
        channel_object.type_other_pay = true;
        channel.push(channel_object);
        this.setState({ pay_channel: channel }, () => {
            this.props.updatePayChannel(this.state.pay_channel);
            this.handleModalCloseClick()
        });

    }

    handleModalShowClick() {
        $(this.modal).modal('show');
    }

    handleModalCloseClick() {
        $(this.modal).modal('hide');

    }

    calDiffPay(type) {
        let value = this.props.pay - this.props.auto_suggest;
        if (type === 'value') {
            return Math.abs(value).toFixed(2)
        } else if (value > 0) {
            return true
        } else {
            return false
        }

    }

    onCheckShowButton(index) {
        // console.log(this.props.pay_form,this.state.pay_channel.length,index,this.props.auto_suggest,this.props.pay)
        // if (this.props.pay_form && (this.state.pay_channel.length === (index + 1)) && this.props.auto_suggest !== 0 && this.props.pay && this.props.pay !== 0 && (this.props.auto_suggest !== this.props.pay)) {
        if (this.props.pay_form && (this.state.pay_channel.length === (index + 1)) && this.props.auto_suggest !== 0 && this.props.pay && this.props.pay !== 0 && (this.props.auto_suggest !== this.props.pay)) {
            // console.log("this True");
            return true
        } else {
            // console.log("this False");
            return false
        }

    }
    returnAbsoluteValue(value) {
        let return_value = value ? Math.abs(_.cloneDeep(value)) : '';
        if (!(return_value || return_value === 0)) {
            return_value = "";
        }
        return return_value;
    }

    render() {
        return (
            <React.Fragment>
                <div className="card mt-4">
                    <div className="card-body">

                        <div className="row">
                            <div className="col">
                                <h5>{this.props.payment_type === 'petty_cash' ? i18next.t("payPaymentChannel:Payment details") : i18next.t("payPaymentChannel:Withdrawal details")}</h5>
                            </div>
                        </div>

                        {this.state.pay_channel.map((payment_channel, index) =>
                            <React.Fragment key={index}>
                                <div className="row mt-2 mb-1">

                                    <div className="col-12">
                                        <h6>
                                            {
                                                !this.props.single_payment && <span>{i18next.t("payPaymentChannel:Channel No")} {index + 1}</span>
                                            }

                                            {index !== 0 &&
                                                <button className="btn btn-danger btn-sm ml-1" type="button"
                                                    onClick={() => this.deleteChannel(index)}>
                                                    {i18next.t("payPaymentChannel:delete")}
                                                </button>
                                            }
                                        </h6>
                                    </div>

                                    <div className="col-4">
                                        <label>{i18next.t("payPaymentChannel:By")}</label>
                                        <SearchSelect onChange={this.handleInputChange}
                                            value={this.state.pay_channel[index].slug}
                                            name={"pay_channel[" + index + "].slug"} placeholder="รายการ"
                                            queryObject={payment_channel.type_other_pay ? this.state.channel_other_choice : this.state.channel_choice}
                                            keyOfValue="slug" require={true}
                                            disabled={payment_channel.type_other_pay ? "disabled" : false}
                                            keyOfLabel="bankName:account_type:accountName:accountNumber"
                                        />
                                    </div>

                                    <div className="col-2">
                                        <label>{i18next.t("payPaymentChannel:Amount")}</label>
                                        <input type="number" className="form-control text-right"
                                            onChange={this.handleInputChange}
                                            value={this.returnAbsoluteValue(this.state.pay_channel[index].pay ? parseFloat(parseFloat(this.state.pay_channel[index].pay).toFixed(2)) : '')}
                                            name={"pay_channel[" + index + "].pay"}
                                            required={true}
                                            data-id={index}
                                            step="0.01"
                                        // disabled={this.state.pay_channel[index].slug === 'petty_cash' ? "disabled" : false}
                                        />
                                    </div>

                                    {_.startsWith(this.state.pay_channel[index].slug, "bank") &&
                                        <div className="col-2">
                                            <label>{i18next.t("payPaymentChannel:Date")}</label><br />
                                            <DatePickerAdapter
                                                className="form-control" name={"pay_channel[" + index + "].date"}
                                                selected={this.state.pay_channel[index].date}
                                                onChange={this.handleInputChange}
                                                required={true}
                                                data-key="payment_list"
                                                minDate={this.props.minDate && this.props.minDate}
                                            />

                                        </div>
                                    }

                                    {_.startsWith(this.state.pay_channel[index].slug, "bank") &&
                                        <div className="col-3">
                                            <label>{i18next.t("payPaymentChannel:Proof of payment")}</label> <br />
                                            <label className={
                                                this.state.pay_channel[index].image ?
                                                    "btn btn-success cursor" : "btn btn-secondary cursor"
                                            }>
                                                {this.state.pay_channel[index].image ? "เรียบร้อย" : "แนบหลักฐาน"}
                                                <input type="file" name={"pay_channel[" + index + "].image"}
                                                    data-key="payment_list"
                                                    onChange={this.handleInputChange}
                                                    hidden />
                                            </label>
                                            {payment_channel.image.name}
                                        </div>
                                    }

                                    {
                                        this.state.pay_channel[index].slug === 'cheque' &&
                                        <div className="col-8 clearfix">
                                            <PayPaymentChannelChequeX index={index} pay_channel={this.state.pay_channel}
                                                cheque_choice={this.state.cheque_choice}
                                                handleInputChange={this.handleInputChange} />
                                        </div>
                                    }

                                    {_.startsWith(this.state.pay_channel[index].slug, "petty_cash") &&
                                        <div className="col-3">
                                            <label>{i18next.t("payPaymentChannel:Petitive cash list")}</label> <br />
                                            <SearchSelect onChange={this.handleInputChange}
                                                value={this.state.pay_channel[index].petty_cash}
                                                name={"pay_channel[" + index + "].petty_cash"}
                                                placeholder="รายการ"
                                                queryObject={this.state.petty_cash}
                                                data-id={index}
                                                keyOfValue="id" require={true}
                                                keyOfLabel="docNumber:description:total"
                                            // disabled={this.state.pay_channel[index].petty_cash ? "disabled" : false}
                                            />
                                        </div>
                                    }

                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                                {this.onCheckShowButton(index) &&
                                    <div className="row">
                                        <div className="col">
                                            <button type="button" className="btn btn-danger"
                                                onClick={this.handleModalShowClick}>
                                                {i18next.t("payPaymentChannel:Excess/deficiency")}
                                            </button>
                                            <span
                                                className="text-red ml-3">{i18next.t("payPaymentChannel:Has part")}{this.calDiffPay() ? i18next.t("payPaymentChannel:different") : i18next.t("payPaymentChannel:broken")} {this.calDiffPay('value')} บาท</span>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        )}


                    </div>
                </div>
                {!this.props.single_payment &&
                    <div className="row mt-3">
                        <div className="col">
                            <button type="button" className="btn btn-success add"
                                onClick={this.addPayChannel}>{i18next.t("payPaymentChannel:increase")}
                            </button>
                        </div>
                    </div>
                }

                <div className="modal fade" id="modalPayOtherChannel" tabIndex="-1" role="dialog"
                    ref={modal => this.modal = modal}
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{i18next.t("payPaymentChannel:Excess/Missing Note Options")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row mt-2">
                                    <div className="col pl-5 pr-5">
                                    {i18next.t("payPaymentChannel:Select items to reconcile differences")}
                                    </div>
                                </div>
                                <div className="row mt-2 mb-5">
                                    <div className="col pl-5 pr-5">
                                        {this.props.pay < this.props.auto_suggest &&
                                            <React.Fragment>
                                                <button type="button" className="btn btn-light buttonMenu buttonBorder"
                                                    onClick={() => this.onAddOtherChannel('small-change-income')}>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/money.png'}
                                                                alt="hand" className="center" />
                                                        </div>
                                                        <div className="col text-left textButton">
                                                            <div className="row">
                                                                <div className="col">{i18next.t("payPaymentChannel:Income from the fraction of a penny difference")}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col textDescription">{i18next.t("payPaymentChannel:Caused by receiving - paying from rounding")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button type="button" className="btn btn-light buttonMenu buttonBorder"
                                                    onClick={() => this.onAddOtherChannel('retention')}>
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/money.png'}
                                                                alt="hand" className="center" />
                                                        </div>
                                                        <div className="col text-left textButton">
                                                            <div className="row">
                                                                <div className="col">เงินประกันผลงาน 2125-03</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col textDescription">{i18next.t("payPaymentChannel:Caused by receiving - paying from rounding")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </React.Fragment>
                                        }

                                        {this.props.pay > this.props.auto_suggest &&
                                            <button type="button" className="btn btn-light buttonMenu buttonBorder"
                                                onClick={() => this.onAddOtherChannel('small-change')}>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/images/icons/money.png'}
                                                            alt="hand" className="center" />
                                                    </div>
                                                    <div className="col text-left textButton">
                                                        <div className="row">
                                                            <div className="col">{i18next.t("payPaymentChannel:Expenses from the fraction of a penny difference")}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col textDescription">{i18next.t("payPaymentChannel:Caused by receiving - paying from rounding")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        }
                                        {this.props.pay > this.props.auto_suggest &&
                                            <button type="button" className="btn btn-light buttonMenu buttonBorder"
                                                onClick={() => this.onAddOtherChannel('fee')}>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/images/icons/hand.png'}
                                                            alt="hand" className="center" />
                                                    </div>
                                                    <div className="col text-left textButton">
                                                        <div className="row">
                                                            <div className="col">{i18next.t("payPaymentChannel:Bank Fees")}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                className="col textDescription">{i18next.t("payPaymentChannel:Select this difference as bank fee")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>


        )
    }
}

export default PayPaymentChannelFormInPay;
