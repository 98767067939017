import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import './interestReport.scss'
import format from 'date-fns/format';
import numberWithCommas from '../../../../libs/numberWithComma';
import _ from 'lodash'
import jwtDecode from 'jwt-decode'

class InterestReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printBy: "",
            jwtDecodes: "",
        };
    }

    componentDidMount() {
        this.setState({
            jwtDecodes: jwtDecode(window.localStorage.getItem('token')),
        }, () => {
            this.setState({
                printBy: `${this.state.jwtDecodes.first_name} ${this.state.jwtDecodes.last_name}`,
            });
        });
    }

    render(){
        return(
            <Translation>
                {
                    t =>
                        <div  id="interest-print">
                            <div className="print-interest-report">
                                <div className="subpage">
                                    <div className="row ">
                                        <div className="logo ml-1" style={{position:'absolute'}}>
                                            {this.props.logo !== '' &&
                                                <img src={this.props.logo} alt="silverman" style={{width:'80px' , marginLeft:'5px'}}/>
                                            }
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <span>{this.props.projectName} <br/>
                                                รายงานรายละเอียดประมาณการดอกเบี้ยรับบัญชีเงินฝากประจำ <br/>
                                                ตั้งแต่เดือน {this.props.firstDate === '' ? ' - ' : new Date(this.props.firstDate).toLocaleDateString('th-TH' , { year: 'numeric', month: 'long'}) }  
                                                {" "}ถึง {this.props.lastDate === '' ? ' - ' : new Date(this.props.lastDate).toLocaleDateString('th-TH' , { year: 'numeric', month: 'long'})}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-4 col-12">
                                        <div className="table-responsive">
                                            <table className="table interest-report table-bordered" style={{fontSize : '13px'}}>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" rowSpan={2}>เลขที่</th>
                                                        <th className="text-center" rowSpan={2}>เดือน</th>
                                                        <th className="text-center" colSpan={this.props.id.length > 0 ? this.props.id.length : 1}>ประมาณการดอกเบี้ย</th>
                                                        <th className="text-center" rowSpan={2}>รวม (บาท)</th>
                                                    </tr>
                                                    <tr>
                                                        {this.props.header.length > 0 ?
                                                            this.props.header.map((head,index) => {
                                                            return (
                                                                        <th className="text-right" key={index} style={{borderTop : '1px solid #dee2e6 '}}>{head}</th>
                                                                )
        
                                                            })
                                                            :
                                                            <th></th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.data.map((transaction,index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td className="text-center grey-bg">{transaction.refNumber}</td>
                                                                <td className="text-center grey-bg">{transaction.issuedDate}</td>
                                                                {transaction.bankUnit.map((debit,index) => {
                                                                    return(
                                                                        <td key ={index} className="text-right">{debit}</td>
                                                                    )
                                                                })
                                                                }
                                                                <td className="text-right blue-bg">{transaction.sumJV}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                                {this.props.lastPage &&
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td className="text-center">รวม</td>
                                                            {this.props.sumBankUnit.length > 0 ?
                                                                this.props.sumBankUnit.map((sum,index) => {
                                                                    return(
                                                                        <td key={index} className="text-right">{numberWithCommas(sum)}</td>
                                                                    )
                                                                })
                                                                :
                                                                <td>-</td>
                                                            }
                                                            <td className="text-right">{numberWithCommas(_.sum(this.props.sumBankUnit))}</td>
                                                        </tr>
                                                    </tfoot>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='footer spaceTopInteresTableFooter'>
                                            <div className='row'>
                                                <div className='col-5' />
                                                <div className='col-2 text-center'>
                                                    <p className='f14 pb-0 mb-0'>{this.props.page}/{this.props.lastPageNumber}</p>
                                                </div>
                                                <div className='col-5 text-right'>
                                                    <p className='f14 pb-0 mb-0'>ผู้พิมพ์ {this.state.printBy} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
                                                </div>
                                            </div>
                            </div>
                        </div>
                }
            </Translation>
        )
    }
}
export default InterestReport;