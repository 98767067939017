/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateOtherReceiveAndOtherReceiveTransactionInput = {|
  contactList?: ?$ReadOnlyArray<?string>,
  invoice: any,
  payChannel: any,
  transactionList: any,
  assetId?: ?string,
  disappearAssetId?: ?string,
  payGroup?: ?string,
  clientMutationId?: ?string,
|};
export type createOtherReceiveMutationVariables = {|
  input: CreateOtherReceiveAndOtherReceiveTransactionInput
|};
export type createOtherReceiveMutationResponse = {|
  +createOtherReceiveAndOtherReceiveTransaction: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type createOtherReceiveMutation = {|
  variables: createOtherReceiveMutationVariables,
  response: createOtherReceiveMutationResponse,
|};
*/


/*
mutation createOtherReceiveMutation(
  $input: CreateOtherReceiveAndOtherReceiveTransactionInput!
) {
  createOtherReceiveAndOtherReceiveTransaction(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOtherReceiveAndOtherReceiveTransactionPayload",
    "kind": "LinkedField",
    "name": "createOtherReceiveAndOtherReceiveTransaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createOtherReceiveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createOtherReceiveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6dc58e02f70fffb5cd3ebb0c3880165e",
    "id": null,
    "metadata": {},
    "name": "createOtherReceiveMutation",
    "operationKind": "mutation",
    "text": "mutation createOtherReceiveMutation(\n  $input: CreateOtherReceiveAndOtherReceiveTransactionInput!\n) {\n  createOtherReceiveAndOtherReceiveTransaction(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2c15e2804fcb27dc58c5e86bd7962038';

module.exports = node;
