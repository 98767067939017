import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {Redirect} from 'react-router'
import CreditNoteCreateFormOnlyCn from './creditNoteCreateFormOnlyCn'
import Loading from '../../../libs/loading';

const CreditNoteContactCreate = graphql`
   query creditNoteContactCreateFormQuery($id: ID!) {
        contact(id: $id) {
            id
            refNumber
            name
            firstName
            lastName

            registeredAddress
            registeredCountry
            registeredProvince
            registeredDistrict
            registeredCity
            registeredPostalCode
            typeOfContact
            
            ownerName
            mailingAddress
            mailingDistrict
            mailingCity
            mailingProvince
            mailingPostalCode
            
            renterName
            renterAddress
            renterDistrict
            renterCity
            renterProvince
            renterPostalCode

            agentName
            agentAddress
            agentDistrict
            agentCity
            agentProvince
            agentPostalCode
        }
  		# invoiceTransaction(id:$transaction_id){
        #     id
        #     price
        #     total
        #     paid
        #     invoice{
        #       docNumber
        #     }
        #     productAndService{
        #       productCode
        #        chartOfAccount{
        #         id
        #         chartOfAccountCode
        #        }
        #     }
        #     description
            
        # }

          viewer {
            allChartOfAccount(codeList: ["41", "42", "43","2210","2220"]){
            totalCount
            edges{
                node {
                    id
                    chartOfAccountCode
                    name
                }
            }
        }
       }  
       
    }
`;

class CreditNoteContactCreateForm extends Component {

    render() {
        if (this.props.customerList.length > 0) {
            return (
                <QueryRenderer
                    environment={environment}
                    query={CreditNoteContactCreate}
                    variables={{id: this.props.customerList[0]}}
                    cacheConfig={{use_cache: false}}
                    render={({error, props}) => {
                        if (props) {
                            return <CreditNoteCreateFormOnlyCn customerList={this.props.customerList}
                                                         reason_create={this.props.reason_create}
                                                         contact={props.contact}
                                                         category={'credit_note'}
                                                         transaction_object={this.props.transaction}
                                                         invoice_object={this.props.invoice}
                                                         allChartOfAccount={props.viewer.allChartOfAccount.edges}
                                                         backLink="/accounting/income/credit-note/form/select_customer"
                                                         nextLink="/accounting/income/credit-note/list/all"
                            />
                        } else {
                            return <Loading/>
                        }
                    }}
                />
            )
        }
        return <Redirect to="/accounting/income/credit-note/form/select_customer"/>;
    }
}

export default CreditNoteContactCreateForm;
