import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";
async function getRightSetting() {
  let token_id = await localStorage.getItem("token");
  const response = await fetch(
    getApiRoot() + "booking_facility/right_setting/",
    {
      method: "POST",
      headers: {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
      },
      body: {},
    }
  ).catch((error) => {
    console.error("Error:", error);
  });

  return response.json();
}
async function setRightSetting(data) {
  let token_id = await localStorage.getItem("token");
  let formBody = [];
  for (let property in data) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const response = await fetch(
    getApiRoot() + "booking_facility/right_setting/",
    {
      method: "POST",
      headers: {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    }
  ).catch((error) => {
    console.error("Error:", error);
  });

  return response.json();
}

async function getAllFacility(status, search, timePeriod, frist, last) {
  let token_id = await localStorage.getItem("token");
  const response = await fetch(
    `${getApiRoot()}booking_facility/all_facility?status=${status}&search=${search}&time_period=${timePeriod}&index_first=${frist}&index_last=${last}`,
    {
      method: "get",
      headers: {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
      },
    }
  ).catch((error) => {
    console.error("Error:", error);
  });

  return response.json();
}

const create_booking_facility = async (data) => {
  let token_id = await localStorage.getItem("token");
  let header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
  };

  return axios.post(`${getApiRoot()}booking_facility/create_facility/`, data, {
    headers: header,
  });
};

const score_setting = async (data) => {
  let token_id = await localStorage.getItem("token");
  let header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
  }

  return axios.get(`${getApiRoot()}booking_facility/score_setting`, {
    headers: header,
    params: data
  });
}

const getscore = async (data) => {
  let token_id = await localStorage.getItem("token");
  let header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
  }

  return axios.post(`${getApiRoot()}booking_facility/score_get`, data ,{
    headers: header,
  });
}

const editscore = async (data) => {
  let token_id = await localStorage.getItem("token");
  let header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
  }

  return axios.post(`${getApiRoot()}booking_facility/score`, data ,{
    headers: header,
  });
}

export default {
  getRightSetting,
  setRightSetting,
  getAllFacility,
  create_booking_facility,
  score_setting,
  getscore,editscore
};
