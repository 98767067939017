import React, { Component } from 'react';
import { Translation } from 'react-i18next';

class OwnerAddressDetail extends Component {

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                <h6>{t("residential:no.")}</h6>
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.homeNumber}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="homeNumber" />
                            </div>
                            <div className="col">
                                <h6>{t("residential:Village/Building")}</h6>
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.villageBuilding}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="villageBuilding" />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Moo")}</h6>
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.villageNumber}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="villageNumber" />
                            </div>

                            <div className="col">
                                <h6>{t("residential:Soi")}</h6>
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.soi}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="soi" />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-6">
                                <h6>{t("residential:Road")}</h6>
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.lane}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="lane" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Sub-district")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุแขวง --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.subDistrict}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="subDistrict" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:District")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุเขต --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.district}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="district" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Province")}</h6>
                                {/*<select className="custom-select">*/}
                                {/*    <option>-- ระบุจังหวัด --</option>*/}
                                {/*</select>*/}
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.province}
                                    onChange={this.props.handleChangeInputAddress}
                                    name="province" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <h6>{t("residential:Zip code")}</h6>
                                <input type="text"
                                    className="form-control"
                                    value={this.props.state.postalCode}
                                    onChange={this.props.handleChangeInputAddress}
                                    maxLength="5"
                                    name="postalCode" />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default OwnerAddressDetail; 