/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NewsNewsType = "INFO" | "NOTICE" | "%future added value";
export type NewsStatus = "DRAFT" | "EXPIRE" | "PUBLISH" | "VOID" | "%future added value";
export type createUpdateNewsQueryVariables = {|
  id: string
|};
export type createUpdateNewsQueryResponse = {|
  +news: ?{|
    +id: string,
    +newsType: NewsNewsType,
    +title: ?string,
    +titleTh: ?string,
    +titleEn: ?string,
    +previewImage: string,
    +detail: string,
    +detailTh: ?string,
    +detailEn: ?string,
    +status: NewsStatus,
    +scheduleTime: ?any,
    +expTime: ?any,
    +newsImages: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: string,
          +source: string,
        |}
      |}>
    |},
    +newsFile: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +fileName: ?string,
          +fileUpload: string,
          +fileUrl: ?string,
        |}
      |}>
    |},
  |},
  +allProjects: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +periodCreate: string
      |}
    |}>
  |},
|};
export type createUpdateNewsQuery = {|
  variables: createUpdateNewsQueryVariables,
  response: createUpdateNewsQueryResponse,
|};
*/


/*
query createUpdateNewsQuery(
  $id: ID!
) {
  news(id: $id) {
    id
    newsType
    title
    titleTh
    titleEn
    previewImage
    detail
    detailTh
    detailEn
    status
    scheduleTime
    expTime
    newsImages {
      edges {
        node {
          id
          title
          source
        }
      }
    }
    newsFile {
      edges {
        node {
          fileName
          fileUpload
          fileUrl
          id
        }
      }
    }
  }
  allProjects {
    edges {
      node {
        periodCreate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newsType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "titleTh",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "titleEn",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previewImage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detail",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detailTh",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detailEn",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduleTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expTime",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "NewsImageNodeConnection",
  "kind": "LinkedField",
  "name": "newsImages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NewsImageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NewsImageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "source",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUpload",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUrl",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodCreate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUpdateNewsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NewsNode",
        "kind": "LinkedField",
        "name": "news",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NewsFileNodeConnection",
            "kind": "LinkedField",
            "name": "newsFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewsFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NewsFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUpdateNewsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NewsNode",
        "kind": "LinkedField",
        "name": "news",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NewsFileNodeConnection",
            "kind": "LinkedField",
            "name": "newsFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewsFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NewsFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d60d718e7e5fc8050909942d5f90185f",
    "id": null,
    "metadata": {},
    "name": "createUpdateNewsQuery",
    "operationKind": "query",
    "text": "query createUpdateNewsQuery(\n  $id: ID!\n) {\n  news(id: $id) {\n    id\n    newsType\n    title\n    titleTh\n    titleEn\n    previewImage\n    detail\n    detailTh\n    detailEn\n    status\n    scheduleTime\n    expTime\n    newsImages {\n      edges {\n        node {\n          id\n          title\n          source\n        }\n      }\n    }\n    newsFile {\n      edges {\n        node {\n          fileName\n          fileUpload\n          fileUrl\n          id\n        }\n      }\n    }\n  }\n  allProjects {\n    edges {\n      node {\n        periodCreate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'da051f760d5e05eb77c8b245ec11d6be';

module.exports = node;
