import React from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from 'i18next'
import "../styles/index.scss";

import { graphql } from "babel-plugin-relay/macro";
import Navigation from "./navigation";

import environment from "../../env/environment";
import { format } from "date-fns";
import LightBox from "../../libs/lightBox";
import ComponentPagination from "../../libs/componentPagination";
import Swal from "sweetalert2";
import _ from "lodash";
import i18next from "i18next";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import "./styles/chatGroup.scss";
import { fetchQuery } from "relay-runtime";
import UpdateCreateChatMeeting from "./mutations/updateCreateChatMeeting";
import onSubmitSuccess from "./action/onSubmitSuccess";
import onSubmitError from "./action/onSubmitError";
import jwtDecode from "jwt-decode"

const query = graphql`
  query chatListQuery($status: String,$roomGroupId: String ,$search: String, $tag: String, $startDate: DateTime, $endDate: DateTime , $first: Int ,$last: Int){
    allRoomGroup (status: $status,roomGroupId: $roomGroupId , search : $search ,tag:$tag, startDate: $startDate , endDate: $endDate , first: $first , last: $last) {
      totalCount
      edges{
          node{
              totalCountRead
              id
              name
              detail
              status
              note
              updated
              added
              user{
                id
              }
              roomGroupNumberRead{
                  edges{
                      node{
                          id
                          count
                          user{
                              id
                              juristic{
                                  id
                                  firstName
                                  lastName
                              }
                              tenant{
                                  id
                                  firstName
                                  lastName
                              }
                          }
                      }
                  }           
              } 
              roomGroupTag{
                  edges{
                      node{
                          id
                          tag
                      }
                  }           
              }  
              roomGroupDocument{
                  edges{
                      node{
                          id
                          fileName
                          fileUpload
                      }
                  }           
              }    
              roomGroupImage{
                  edges{
                      node{
                          id
                          fileName
                          fileUpload
                      }
                  }           
              }    
              roomGroupMessage{
                  edges{
                      node{
                          id
                          message
                          image
                          added
                          updated
                          user{
                              id
                               
                          }
                      }
                  }           
              }            
              
          }
      }
  }
  
    
  }
`

class ChatList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      optionAction: false,
      data: [],
      wroksheetView: [],
      allResidential: [],
      page: 1,
      pageAll: 0,
      pageSize: 10,
      totalCount: 0,
      index_first: 0,
      index_last: 10,

      searchTypePerson: "",
      searchBox: "",
      searchStartDate: "",
      searchEndDate: "",
      optionSearch: [{ "value": 0, "id": 0, "label": "ทั้งหมด", "lableEn": "All", "selected": false }, ...require("./ListTag.json")],

      listTag: [],

    };
    this.viewMore = this.viewMore.bind(this)
    this.Cancled = this.Cancled.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    this.updatePage = this.updatePage.bind(this)
    this.SearchChangeFilter = this.SearchChangeFilter.bind(this)
    this.SearchClick = this.SearchClick.bind(this)
    this.SearchChange = this.SearchChange.bind(this)
    this.dateFile = this.dateFile.bind(this)

  }
  SearchChangeFilter(e) {
    this.setState({ searchTypePerson: e.value }, () => {
      this.SearchClick()
    })

  }
  SearchClick() {
    this.fetchQuery(this.state.page, this.state.pageSize)
  }

  SearchChange(e) {
    if (e.target.name === "searchTypePerson") { this.setState({ searchTypePerson: e.target.value }) }
    if (e.target.name === "search") { this.setState({ searchBox: e.target.value }) }
    if (e.target.name === "startDate") { this.setState({ searchStartDate: e.target.value + "T00:00" }) }
    if (e.target.name === "endDate") { this.setState({ searchEndDate: e.target.value + "T23:59" }) }

    if (e.key === "Enter") {
      this.SearchClick()
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.status !== prevProps.match.params.status) {
      this.fetchQuery(this.state.page, this.state.pageSize)
    }
  }

  componentWillMount() {
    this.fetchQuery(this.state.page, this.state.pageSize)
  }

  fetchQuery(pageSleect, pageShowSize) {
    fetchQuery(environment, query, {
      status: this.props.match.params.status || "",
      search: this.state.searchBox || "",
      tag: this.state.searchTypePerson || "",
      startDate: this.state.searchStartDate || null,
      endDate: this.state.searchEndDate || null,
      // first: pageShowSize * (pageSleect - 1) || this.state.pageSize,
      // last: pageSleect * pageShowSize
    }).then((data) => {
      let dataallRoomGroup = []
      data.allRoomGroup.edges.map((n, index) => {
        _.set(dataallRoomGroup, "[" + index + "].node.seleted", false);
        _.set(dataallRoomGroup, "[" + index + "].node.added", n.node.added);
        _.set(dataallRoomGroup, "[" + index + "].node.detail", n.node.detail);
        _.set(dataallRoomGroup, "[" + index + "].node.id", n.node.id);
        _.set(dataallRoomGroup, "[" + index + "].node.name", n.node.name);
        _.set(dataallRoomGroup, "[" + index + "].node.note", n.node.note);
        _.set(dataallRoomGroup, "[" + index + "].node.roomGroupDocument", n.node.roomGroupDocument);
        _.set(dataallRoomGroup, "[" + index + "].node.roomGroupImage", n.node.roomGroupImage);
        _.set(dataallRoomGroup, "[" + index + "].node.roomGroupMessage", n.node.roomGroupMessage);
        _.set(dataallRoomGroup, "[" + index + "].node.roomGroupNumberRead", n.node.roomGroupNumberRead);
        _.set(dataallRoomGroup, "[" + index + "].node.roomGroupTag", n.node.roomGroupTag);
        _.set(dataallRoomGroup, "[" + index + "].node.status", n.node.status);
        _.set(dataallRoomGroup, "[" + index + "].node.totalCountRead", n.node.totalCountRead);
        _.set(dataallRoomGroup, "[" + index + "].node.updated", n.node.updated);
      })

      let totalRow = data.allRoomGroup.totalCount
      let pageAll = Math.ceil(totalRow / pageShowSize)
      let items = data.allRoomGroup.edges.slice(pageShowSize * (pageSleect - 1), pageSleect * pageShowSize);
      this.setState({
        data: items, loading: false,
        pageSize: pageShowSize,
        page: items.length === 0 ? 1 : pageSleect,
        pageAll: pageAll,
        totalCount: parseFloat(data.allRoomGroup.totalCount),
      })
      document.body.style.cursor = "auto";
    })
  }

  getParcelStatus(status) {
    // if(status == "NOT_RECEIVE") return "Nor received"
    // if(status == "RECEIVE") return "Received success"
    // if(status == "DELETED") return "Deleted item"
    // if(status == "RETURN") return "Return list"
  }

  handlePageClick(e) {
    document.body.style.cursor = "wait";
    let page = e.selected + 1
    let pageSize = this.state.pageSize
    this.fetchQuery(page, pageSize)

  }

  Cancled(data) {
    Swal.fire({
      title: "กรุณาระบุหมายเหตุยกเลิก",
      input: "text",
      inputPlaceholder: "ระบุหมายเหตุยกเลิก...",
      confirmButtonText: i18next.t("Allaction:Confirm"),
      cancelButtonText: i18next.t("Allaction:cancel"),
      showCancelButton: true,
      showCloseButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'กรุณาใส่หมายเหตุการยกเลิก'
        }
      },
    }).then((result) => {
      if (result.value) {
        let input = {
          "input": {
            "roomGroup": {
              "roomGroupId": data.node.id,
              "name": data.node.name,
              "status": "cancled",
              "detail": data.node.detail,
              "note": result.value,
              "group": [],
              "tag": [],
              "image": [],
              "document": []
            }
          }
        }
        UpdateCreateChatMeeting(input, onSubmitSuccess, onSubmitError)
        this.setState({ data: [] })
        this.fetchQuery(this.state.page, this.state.pageSize)
      }
    })
  }

  handlePageSizeChange(e) {
    document.body.style.cursor = "wait";
    let pageSelect = e.target.value
    this.fetchQuery(1, pageSelect)
  }
  updatePage(n) {
    if (n) {
      this.props.history.push(`/contact/committee/update/${n}`)
    }
  }


  onSuccess(success_text) {
    this.setState({ loading: false });
    Swal.fire(success_text, "", "success").then(() => {
      this.clearCheckList();
      this.setState({ reQuery: !this.state.reQuery, loading: false });
    });
  }

  onError() {
    this.setState({ loading: false });
    Swal.fire(i18n.t("parcel_list:Failed"), i18n.t("parcel_list:Please try again"), "error");
  }
  activeOther() {

  }

  viewMore(e, i) {
    let clondeData = _.cloneDeep(this.state.data)
    _.set(clondeData, "[" + i + "].node.seleted", e.node.seleted ? false : true)
    this.setState({ data: clondeData })
  }

  dateFile = (index, data) => {
    // let cloneDataFile = _.cloneDeep(this.state.fileUpload)
    let DataFile = _.cloneDeep(this.state.chooseFile)
    let fileupload = []

    // const index_data_file = DataFile.findIndex(ex => ex.fileId === data.fileId)
    _.set(DataFile, "[" + index + "].status", "delete")
    fileupload = _.get(DataFile, "[" + index + "]")

    if (DataFile.findIndex(ex => ex.fileId === fileupload.fileId) < 0) {
      DataFile.push(fileupload)
    }

    if (data.status === '') {
      this.setState({ chooseFile: DataFile })
    }
  }

  render() {
    let iAm = jwtDecode(localStorage.getItem("token"))
    // let optionSelect = 
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}> 
          <ContactTopMenuNavigation mini={true} />
          <Translation>
            {
              t =>

                this.state.loading == false ?
                  <div className="container-fluid box meetingChat" >
                    {/* BACK AND HEADER */}
                    <div className="row justify-content-between">
                      <div className="col">
                        <h3 className="mb-4">
                          <Link to="/contact">
                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                              alt="back" className="back" />
                          </Link>
                          <span style={{ fontWeight: '400' }}>{t("chatGroup:chatCommittee")}</span>
                        </h3>
                      </div>
                    </div>

                    {/* BUTTON CREATE */}
                    {
                         _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'committee_chat_create' }) &&
                         <section className='btnCreate'>
                         <Link to={"/contact/committee/create"}>
                           <button className='btn btn-primary floatRight'>
                             <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} /> {t("chatGroup:create")}</button>
                         </Link>
                       </section>
   
                    }
                   
                    <Navigation />

                    {/* Filter And Search */}
                    <div className='row filterSearch' >
                      <div className='col-12 text-right' style={{ display: 'inline', zoom: "90%" }}>

                        <div className='floatLeft'>
                          <label className='floatLeft labelInline'>{t("chatGroup:Tag")} : &nbsp;&nbsp;</label>
                          <div style={{ position: 'relative', textAlign: 'left' }}>
                            <Select
                              isSearchable={true}
                              defaultValue={
                                { 
                                  "value": 0, 
                                  "id": 0, 
                                  "label": t('pdpaPrivacyPolicy:PrivacyPolicy') === "th" ? "ทั้งหมด" : "All", 
                                  "lableEn": "All", "selected": false 
                                }
                              }
                              classNamePrefix='inputBoxList'
                              options={this.state.optionSearch.map((n) =>
                                ({ ...n, label: t('pdpaPrivacyPolicy:PrivacyPolicy') === "th" ? n.label : n.lableEn })
                              )}
                              onChange={this.SearchChangeFilter}
                              required={true}
                              placeholder={"PageList:Short By"}
                            />
                            <input name="searchTypePerson" type="text" value={this.state.details}
                              style={{ position: "absolute", top: 0, left: 160, width: 5, zIndex: -1 }}
                              onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                              required={true} />
                          </div>

                        </div>
                        <div className='floatRight'>
                          <input type="text" name='search' className='form-control searchBox' placeholder={t("chatGroup:search")}
                            onKeyUp={this.SearchChange} onChange={this.SearchChange} defaultValue={this.state.searchBox} />
                          <span className='spanINBox' onClick={this.SearchClick}>
                            <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                          </span>
                        </div>

                        <div className='floatRight'>
                          <label className='floatLeft labelInline'>{t("chatGroup:Date")}</label>
                          <input type="date" name='startDate' className='floatLeft form-control searchDate'
                            placeholder='01/07/2021' onChange={this.SearchChange} defaultValue={this.state.searchStartDate} />

                          <label className='floatLeft labelInline'>{t("chatGroup:To")}</label>
                          <input type="date" name='endDate' className='floatLeft form-control searchDate'
                            placeholder='01/07/2021' onChange={this.SearchChange} defaultValue={this.state.searchEndDate} />

                        </div>
                      </div>
                    </div>

                    {/* LIST DATA */}
                    <div className='row'>
                      {
                        this.state.loading == false ?
                          this.state.data?.map((n, index) => {
                            return (
                              <div className="col-12" key={"data"+index}>
                                <div className=' card show mt-2'>
                                  <div className="card-body p-4 listData">

                                    <div className="row">
                                      <div className="col-8">
                                        <Link to={"/contact/committee/chatGroup/" + n.node.id}>
                                          <p className="H3header">{n.node.name}</p>
                                        </Link>

                                        {
                                          n.node.roomGroupTag.edges.map((tag, tagIndex) => {
                                            let selectTag = this.state.searchTypePerson === tag.node.tag ? true :
                                              this.state.searchTypePerson === "" ? true : false
                                            return (
                                              <label className="tag tagBtnBlue" style={{ opacity: selectTag ? 1 : 0.5 }}
                                                key={"roomGroupTag"+tagIndex}>{tag.node.tag}</label>
                                            )
                                          })
                                        }


                                      </div>
                                      <div className="col-4 grey-color text-right">
                                        {format(n.node.updated, "DD/MM/YYYY HH:mm น.")}
                                        {
                                          iAm.user_id === parseInt(n.node.user.id) &&
                                          this.props.match.params.status === "pending" &&
                                          <div className="dropdown floatRight">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/choice_noborder.png'} className=' dropdown-toggle' data-toggle="dropdown"
                                              alt="choice-icon" style={{ cursor: "pointer" }} />
                                            <ul className="dropdown-menu">
                                              {
                                                 _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'committee_chat_edit' }) &&
                                                 <li className=' dropdown-item' onClick={() => this.updatePage(n.node.id)}>{t("Allaction:edit")}</li>
                                              }
                                              {
                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'committee_chat_delete' }) &&
                                                  <li className=' dropdown-item' onClick={() => this.Cancled(n)}>{t("Allaction:cancel")}</li>
                                              }
                                            </ul>

                                          </div>
                                        }

                                      </div>

                                      <div className="col-12"  >
                                        <React.Fragment>
                                          {
                                            n.node.roomGroupImage?.edges?.length > 0 || n.node.detail?.length > 300 ?
                                              n.node.seleted ?
                                                <div className=" card-body rounded" style={{ background: "#F4F4F8" }}>
                                                  <div className="row">
                                                    <div className="col-10 ml-0 pl-0 detail-text-newLine">{n.node.detail}  </div>
                                                    <div className="col-10 ml-0 pl-0 mt-5 detail-text-newLine">
                                                      <div style={{ display: 'inline-flex' }}>
                                                        {
                                                          n.node.roomGroupImage?.edges?.map((file, inx) => {
                                                            return <div style={{ marginRight: 10 }}>
                                                              <LightBox height={80} width={"auto"}
                                                                image={file.node.fileUpload}
                                                                className='pImg' key={"roomGroupImage"+inx} />
                                                            </div>
                                                          })
                                                        }
                                                      </div>
                                                    </div>

                                                    <div className="col-12">
                                                      <p className="text-right mb-0 mt-5" onClick={() => this.viewMore(n, index)}>
                                                        <span style={{ marginRight: 12 }}>{t("chatGroup:Hide")}  </span>
                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/arrowUp.png"}
                                                          width={20} height={20} />
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                :
                                                <div className=" card-body rounded pl-5 pr-5 pt-3 pb-4" style={{ background: "#F4F4F8", overflow: "hidden" }}>
                                                  <div className="row">
                                                    <div className="col-10 ml-0 pl-3 pr-3 detail-text-newLine active" style={{ maxHeight: 62, overflow: 'hidden', }}>
                                                      {n.node.detail.substr(0, 300)}...
                                                    </div>
                                                    <div className="col-12">
                                                      <p className="text-right mb-0 mt-2" onClick={() => this.viewMore(n, index)}>
                                                        <span style={{ marginRight: 12 }}>{t("chatGroup:Read more")}  </span>
                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/arrowDown.png"}
                                                          width={20} height={20} />
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              :
                                              <div className=" card-body rounded pl-5 pr-5 pt-3 pb-4" style={{ background: "#F4F4F8" }}>
                                                <p className="col-10 ml-0 pl-0 detail-text-newLine active">{n.node.detail}</p>
                                              </div>
                                          }
                                        </React.Fragment>
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            )
                          })
                          :
                          <div className=' card show mt-2'>
                            <div className="card-body p-4">
                              <div className="row">
                                <div className='col-sm-12 text-center'>
                                  สถานะนี้ไม่มีข้อมูล
                                </div>
                              </div>
                            </div>
                          </div>
                      }
                    </div>

                    {/* FOOTER AND PAGING */}
                    <div className='row'>
                      <div className='col-6'>
                        <select className='page' onChange={this.handlePageSizeChange}>
                          <option value="10">{t("silvermanguard:show")} 10 {t("silvermanguard:items")}</option>
                          <option value="20">{t("silvermanguard:show")} 20 {t("silvermanguard:items")}</option>
                          <option value="50">{t("silvermanguard:show")} 50 {t("silvermanguard:items")}</option>
                          <option value="100">{t("silvermanguard:show")} 100 {t("silvermanguard:items")}</option>
                        </select>
                      </div>
                      <div className='col-6'>
                        <div className='text-right' style={{ marginTop: '22px' }}>{t("silvermanguard:listAll")} {this.state.totalCount} &nbsp;

                          {this.state.pageAll > 0 && (
                            <ReactPaginate
                              previousLabel={"previous"}
                              nextLabel={"next"}
                              breakLabel={
                                <span className="btn page-link">...</span>
                              }
                              breakClassName={"break-me"}
                              pageClassName="page-item"
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={4}
                              previousClassName="sr-only"
                              containerClassName={"pagination float-right"}
                              subContainerClassName={"pagination-page"}
                              nextLinkClassName="sr-only"
                              pageLinkClassName="page-link"
                              activeClassName={"active"}
                              pageCount={this.state.pageAll}
                              onPageChange={this.handlePageClick}
                            />
                          )}

                        </div>

                      </div>
                    </div>

                  </div>
                  :
                  <div className="container-fluid box fixRequestFast text-center" >
                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                  </div>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ChatList;