import React, { Component } from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import PaymentGatewayCreateSelectTransactionList from "./paymentGatewayCreateSelectTransactionList"
import PaymentGatewayCreateForm from "./paymentGatewayCreateForm"
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";

const query = graphql`
    query paymentGatewayDepositCreateQuery($paymentGatewaySettingListType:String){
        allPaymentGatewayMethod(paymentGatewayId:$paymentGatewaySettingListType,status:"not_pass",paymentGateway_PaymentType_In:"twoctwop,deeplink,slip_verification,ksher"){
            edges{
                node{
                    id
                    paymentType
                    paymentGateway{
                        id
                        paymentType
                        fee
                        typeCal
                    }
                    receive{
                        id
                        docNumber
                        issuedDate
                        contact{
                            id
                            refNumber
                            name
                            firstName
                            taxIdNumber
                            lastName
                            typeOfCompany
                            nameBranch
                        }
                    }
                    bankAccount{
                            id
                            docNumber
                            accountNumber
                            accountName
                            accountType
                            receivePayment
                            makePayment
                            bankName
                            status
                        }
                    description
                    amount
                    feeAmount
                    total
                    depositDate
                    image
                }
            }
        }

        paymentGatewaySettingList(paymentType_In:"twoctwop,deeplink,ksher"){
            edges{
              node{
                id
                paymentType
                fee
                typeCal
                bankAccount{
                    id
                    docNumber
                    accountNumber
                    accountName
                    accountType
                    receivePayment
                    makePayment
                    bankName
                    status
                }
              }
            }
        }
        
        bankAccountViewer{
            allBankAccount{
                edges{
                    node{
                        id
                        accountNumber
                        accountName
                        bankName

                    }
                }
            }
        }
    }
`;

class PaymentGatewayCreate extends Component {

    constructor() {
        super();
        this.state = {
            select_transaction: []
        };
        this.updateSelectTransaction = this.updateSelectTransaction.bind(this);

    }

    updateSelectTransaction(select_transaction) {
        this.setState({ select_transaction: select_transaction })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="invoice-list">

                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/finance/payment-gateway/list">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('credit_card:add_credit_card')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <div className="content-inner">
                            {this.props.match.params.status === 'select-receive' &&
                                <PaymentGatewayCreateSelectTransactionList query={query} updateSelectTransaction={this.updateSelectTransaction} />
                            }
                            {this.props.match.params.status === 'form' &&
                                <PaymentGatewayCreateForm select_transaction={this.state.select_transaction} />
                            }

                        </div>

                    </div>
                </WrapperContent>

            </Wrapper>

        )

    }
}

export default PaymentGatewayCreate;