import React, { Component } from "react";
import "./styles/commonArea.scss";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { bookingService } from "./services";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import i18n from "i18next";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

class BookingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      transaction: [],
      num_people: 0,
      query: "",
      text: "",
      doc_number: "",
      status: "",
      name: "",
      start_time: "",
      time: "",
      remark: "",
      booking_name: "",
      residential_right_name: "",
      tel: "",
      total: "",
      void_remark: "",
      type_booker: "",
      end_time: "",
      booking_date: "",
      use_date: "",
      void_remark_text: ''
    };
  }

  async calcel() {
    const state = this.props.match.params.id;
    let token = localStorage.getItem("token");

    await bookingService
      .niti_cancel_booking(token, state, this.state.void_remark)
      .then((res) => {
        const data = res.data.message;
        if (data === "void successful") {
          this.props.history.push("/contact/global-area/all");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onSubmit = (evt) => {
    evt.preventDefault();
    if (this.state.text === "") {
      alert("Please enter something!");
    } else {
      alert(this.state.text);
      this.setState({
        text: ""
      });
    }
  };

  onChange = (evt) => this.setState({ text: evt.target.value });

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    const state = this.props.match.params.id;
    let token = localStorage.getItem("token");

    await bookingService
      .one_niti_booking(token, state)
      .then((res) => {
        const data = res.data;
        const all_booking_bill = data.all_booking_bill;
        const booking_detail = data.booking_detail;
        let doc_number;
        let transaction;
        let total;
        all_booking_bill.map((item) => {
          doc_number = item.doc_number;
          transaction = item.booking_transaction;
          total = item.total;
        });

        this.setState({
          doc_number: doc_number,
          status: _.get(booking_detail, "status"),
          void_remark_text: _.get(booking_detail, "void_remark"),
          name: _.get(data.facility_detail, "name"),
          start_time: moment(_.get(booking_detail, "start_time"))
            .utc()
            .format("DD/MM/YYYY"),
          time: moment(_.get(booking_detail, "start_time"))
            .utc()
            .format("HH:mm"),
          end_time: moment(_.get(booking_detail, "end_time"))
            .utc()
            .format("HH:mm"),
          num_people: _.get(booking_detail, "num_people"),
          remark: _.get(booking_detail, "remark"),
          booking_name: _.get(booking_detail, "booking_name"),
          residential_right_name: _.get(
            booking_detail,
            "residential_right_name"
          ),
          tel: _.get(booking_detail, "tel"),
          transaction: transaction,
          total: total,
          type_booker: _.get(booking_detail, "type_user"),
          use_date:
            moment(new Date(_.get(booking_detail, "use_date"))).format(
              "DD"
            ) +
            " " +
            monthNames[
            new Date(_.get(booking_detail, "use_date")).getMonth()
            ] +
            " " +
            moment(new Date(_.get(booking_detail, "use_date"))).add(543, 'year').format(
              "YYYY"
            ),
          booking_date:
            moment(new Date(_.get(booking_detail, "booking_date"))).format(
              "DD"
            ) +
            " " +
            monthNames[
            new Date(_.get(booking_detail, "booking_date")).getMonth()
            ] +
            " " +
            moment(new Date(_.get(booking_detail, "booking_date"))).add(543, 'year').format(
              "YYYY"
            )
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onChangeVoidRemark(event) {
    this.setState({
      void_remark: event.target.value
    });
  }

  render() {
    const {
      doc_number,
      status,
      name,
      start_time,
      end_time,
      time,
      num_people,
      remark,
      booking_name,
      residential_right_name,
      tel,
      transaction,
      total,
      type_booker,
      booking_date,
      use_date,
      void_remark_text
    } = this.state;

    const { calendar } = this.props.location;

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div className="modal" tabindex="-1" role="dialog" id="exampleModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header header-border-unset">
                <h5 className="modal-title">{i18n.t("newBookingSingha_Booking:Cancel this booking")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row mt-2">
                  <label className="col-6 font-label-cancel">
                    {i18n.t("newBookingSingha_Booking:Reason to Cancled")}
                  </label>
                </div>
                <div className="align-items-center d-flex">
                  <label className="col-form-label mr-2 font-note">
                    {i18n.t("newBookingSingha_Booking:Note")}
                  </label>
                  <input
                    type="text"
                    name="text"
                    placeholder={i18n.t("newBookingSingha_Booking:Remark")}
                    value={this.void_remark}
                    onChange={(value) => this.onChangeVoidRemark(value)}
                    className="calendars-wrapper colorborder form-control float-left"
                  />
                </div>
              </div>
              <div className="modal-footer box-button-modal">
                <button
                  className="btn cancel-modal"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.calcel()}
                >
                  {i18n.t("newBookingSingha_Booking:Comfirm")}
                </button>
                <button
                  className="btn close-modal"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {i18n.t("unitStatus:Close")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <WrapperContent>
          <div className="container-fluid box">
            <div className="row justify-content-between">
              <div className="col">
                <h3>
                  {calendar ?
                    <Link to="/contact/global-area/calendar/month">
                      <img src={process.env.PUBLIC_URL + "/images/wrapperContent/back.png"}
                        alt="back"
                      />
                    </Link> :
                    <Link to="/contact/global-area/all">
                      <img src={
                        process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                      }
                        alt="back"

                      />
                    </Link>
                  }
                  {calendar !== true ? (
                    <Translation>
                      {(t) => <span className="text-header-booking ml-3">{doc_number}</span>}
                    </Translation>
                  ) : (
                    <Translation>
                      {(t) => <span className="text-header-booking ml-3">{use_date}</span>}
                    </Translation>
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="container-type">
            {void_remark_text !== '' && <div className="mb-5" style={{ width: '100%', height: '', backgroundColor: '#FDE5E8', padding: 24, borderRadius: 4 }} >
              <div className="text-header-booking" style={{ color: '#E14558', fontSize: 22 }} >{i18n.t("newBookingSingha_Booking:Reason to Cancled")}</div>
              <div className="mt-4 text-subheader-booking" style={{ color: '#E14558', fontSize: 16 }} >{void_remark_text}</div>
            </div>}
            <div className="d-flex justify-content-between">
              <h3 className="text-header-booking">{i18n.t("newBookingSingha_Booking:Booking deatil")}</h3>
              <h3 className="text-header-booking">
                {i18n.t("newBookingSingha_Booking:Status")}:
                {status === "void" ? (
                  <span className="tag-void"> {i18n.t("Allaction:cancel")}</span>
                ) : status === "confirmed" ? (
                  <span className="tag-confirmed">{i18n.t("newBookingSingha_Booking:Confirm")}</span>
                ) : status === "unconfirmed" ? (
                  <span className="tag-unconfirmed">{i18n.t("newBookingSingha_Booking:Not Approve")}</span>
                ) : status === "used" ? (
                  <span className="tag-used"> {i18n.t("newBookingSingha_Booking:Used")}</span>
                ) : status === "overdue" && (
                  <span className="tag-overdue "> {i18n.t("newBookingSingha_Booking:Over Due")}</span>
                )}
              </h3>
            </div>
            <div className="line" />
            <div style={{ paddingRight: "286px" }}>
              <div style={{ paddingRight: "70px" }}>
                <h5 className="mt-4 text-subheader-booking">{i18n.t("newBookingSingha_Booking:Date")}</h5>
                <input
                  className="custom-text-input"
                  type="text"
                  disabled={true}
                  value={booking_date}
                ></input>
              </div>
              <h5 className="mt-4 text-subheader-booking">{i18n.t("newBookingSingha_Booking:Common Area")}</h5>
              <input
                className="custom-text-input"
                type="text"
                disabled={true}
                value={name}
              ></input>
              <div className="row mr-0 ml-0 mt-4">
                <div>
                  <h5 className="text-subheader-booking">{i18n.t("newBookingSingha_Booking:Using date")}</h5>
                  <input
                    className="custom-text-input time-right"
                    type="text"
                    disabled={true}
                    value={start_time}
                  ></input>
                </div>
              </div>
              <div className="row mr-0 ml-0 mt-4">
                <div>
                  <h5 className="text-subheader-booking">
                    {i18n.t("newBookingSingha_Booking:Using time")} {i18n.t("newBookingSingha_Booking:start")}
                  </h5>
                  <input
                    className="custom-text-input time-right"
                    type="text"
                    disabled={true}
                    value={time}
                  ></input>
                </div>
                <div className="mt-0 ml-3">
                  <h5 className="text-subheader-booking">{i18n.t("newBookingSingha_Booking:Using time")} {i18n.t("newBookingSingha_Booking:end")}</h5>
                  <input
                    className="custom-text-input time-right"
                    type="text"
                    disabled={true}
                    value={end_time}
                  ></input>
                </div>
              </div>
              <h5 className="mt-4 text-subheader-booking">{i18n.t("newBookingSingha_Booking:Number of participants")}</h5>
              <input className="custom-text-input" type="text" disabled={true} value={num_people} />
              <h5 className="mt-4 text-subheader-booking">{i18n.t("newBookingSingha_Booking:Note")}</h5>
              <input style={{ height: "96px" }} className="custom-text-input" type="text" disabled={true} value={remark} />
              <h3 className="mt-5 text-header-booking pt-5">{i18n.t("newBookingSingha_Booking:Saved By")}</h3>
              <div className="line" />
              <h5 className="mt-4 text-subheader-booking">{i18n.t("newBookingSingha_Booking:Booking name")}</h5>
              <input className="custom-text-input" type="text" disabled={true} value={booking_name} />
              {type_booker === "tenant" &&
                <>
                  <h5 className="mt-4 text-subheader-booking">{i18n.t("newBookingSingha_Booking:Reservation rights user")}</h5>
                  <input className="custom-text-input" type="text" disabled={true} value={residential_right_name} />
                </>
              }
              <h5 className="mt-4 text-subheader-booking">{i18n.t("newBookingSingha_Booking:Contact number")}</h5>
              <input className="custom-text-input" type="text" disabled={true} value={tel} />
            </div>
            {type_booker !== "juristic" && (
              <>
                <h3 className="mt-5 text-header-booking">{i18n.t("newBookingSingha_Booking:Service charge summart")}</h3>
                <div className="line" />
                <div className="cardblue" style={{ color: "#0243AE" }}>
                  {transaction ? (
                    transaction.map((item) => (
                      <div className="d-flex justify-content-between">
                        <span>
                          {i18n.t(`newBookingSingha_Booking:${item.description === "ค่าบริการส่วนกลาง" ? "Facility fee" :
                              item.description === "ค่าทำความสะอาด" ? "Cleaning fee" :
                                item.description === "ภาษีมูลค่าเพิ่ม" ? "VAT" : item.description
                            }`)}</span>
                        <span>{item.price} {i18n.t("newBookingSingha_Booking:Baht")}</span>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-between">
                      <span>-</span>
                      <span>0 {i18n.t("newBookingSingha_Booking:Baht")}</span>
                    </div>
                  )}
                  <div className="lineTotal mtSub" />
                  <div className="d-flex justify-content-between mtSub">
                    <span className="text-header-booking">{i18n.t("newBookingSingha_Booking:Total Amount")}</span>
                    <h3 className="text-header-booking">{total} {i18n.t("newBookingSingha_Booking:Baht")}</h3>
                  </div>
                </div>
              </>
            )}
            {status !== "void" && status !== "used" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'booking_list_delete' }) &&
              <div className="row float-right pt">
                <button data-toggle="modal" data-target="#exampleModal" type="submit" className="btn cancel mr-3 mb-10 mtButton">
                  {i18n.t("Allaction:cancel")}
                </button>
              </div>
            }
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default BookingDetail;
