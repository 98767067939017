/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAdvertiseInput = {|
  title: string,
  detail: string,
  link?: ?string,
  image?: ?string,
  logo?: ?string,
  customerName: string,
  clientMutationId?: ?string,
|};
export type createAdvertiseMutationVariables = {|
  input: CreateAdvertiseInput
|};
export type createAdvertiseMutationResponse = {|
  +createAdvertise: ?{|
    +ok: ?boolean
  |}
|};
export type createAdvertiseMutation = {|
  variables: createAdvertiseMutationVariables,
  response: createAdvertiseMutationResponse,
|};
*/


/*
mutation createAdvertiseMutation(
  $input: CreateAdvertiseInput!
) {
  createAdvertise(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAdvertisePayload",
    "kind": "LinkedField",
    "name": "createAdvertise",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAdvertiseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAdvertiseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c52490b27b744b73e71bd95100565910",
    "id": null,
    "metadata": {},
    "name": "createAdvertiseMutation",
    "operationKind": "mutation",
    "text": "mutation createAdvertiseMutation(\n  $input: CreateAdvertiseInput!\n) {\n  createAdvertise(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc07d463025a6e924321c350cc60293f';

module.exports = node;
