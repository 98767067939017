import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import getNameResidential from "../../../../libs/getNameResidential";
import {format, differenceInCalendarDays} from "date-fns";
import Link from "react-router-dom/es/Link";

class IndividualReceiptReportTable extends React.Component {
    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    getAmountOwed(transaction) {

        var _this = this

        const sumRecive = transaction.node.receiveTransaction.edges.reduce((total, obj) => {
            if(differenceInCalendarDays(_this.props.state.end_date, obj.node.receive.issuedDate) >= 0) {
                return total + obj.node.amount
            } else {
                return total
            }
        }, 0);

        const sumReceiptDeposit = transaction.node.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
            if (obj.node.receiveTransaction) {
                return total
            } else {
                return total + obj.node.amount
            }
        }, 0);

        const sumCreditNote = transaction.node.creditNoteTransaction.edges.reduce((total, obj) => {
            if(obj){
                if (obj.node.receive) {
                    return total
                }else if(differenceInCalendarDays(_this.props.state.end_date, obj.node.issuedDate) >= 0){
                    return total + obj.node.price
                }else{
                    return total
                }
            }
        }, 0)

        const sumCreditNotev2 = transaction.node.creditNoteTransactionRecord.edges.reduce((total, obj) => {
            if(obj){
                 if(differenceInCalendarDays(_this.props.state.end_date, obj.node.creditNote.issuedDate) >= 0){
                    return total + obj.node.price
                }else{
                    return total
                }
            }
        }, 0)
        return parseFloat(transaction.node.total - transaction.node.whtRate - sumRecive - sumReceiptDeposit - sumCreditNote - sumCreditNotev2)
    }

    getAmountPaid(transaction) {

        var _this = this

        const sumRecive = transaction.node.receiveTransaction.edges.reduce((total, obj) => {
            if(differenceInCalendarDays(_this.props.state.end_date, obj.node.receive.issuedDate) >= 0) {
                return total + obj.node.amount
            } else {
                return total
            }
        }, 0);

        const sumReceiptDeposit = transaction.node.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
            if (obj.node.receiveTransaction) {
                return total
            } else {
                return total + obj.node.amount
            }
        }, 0);

        const sumCreditNote = transaction.node.creditNoteTransaction.edges.reduce((total, obj) => {
            if(obj){
                if (obj.node.receive) {
                    return total
                }else if(differenceInCalendarDays(_this.props.state.end_date, obj.node.issuedDate) >= 0){
                    return total + obj.node.price
                }else{
                    return total
                }
            }
        }, 0)

        const sumCreditNotev2 = transaction.node.creditNoteTransactionRecord.edges.reduce((total, obj) => {
            if(obj){
                 if(differenceInCalendarDays(_this.props.state.end_date, obj.node.creditNote.issuedDate) >= 0){
                    return total + obj.node.price
                }else{
                    return total
                }
            }
        }, 0)
        return parseFloat(-transaction.node.whtRate + sumRecive + sumReceiptDeposit + sumCreditNote + sumCreditNotev2)
    }

    getNumberDay(invoice) {
        if (differenceInCalendarDays(this.props.state.end_date, invoice.node.dueDate) < 0) {
            return 0
        } else {
            return differenceInCalendarDays(this.props.state.end_date, invoice.node.dueDate)
        }
    }

    between(value, min, max) {
        return value >= min && value <= max;
    }

    calOverdueDate(issueDate) {
        let overdueDate = Math.ceil((new Date() - new Date(issueDate)) / (1000 * 60 * 60 * 24))
        if(overdueDate < 0) {
            return ""
        }
        return overdueDate
    }

    render() {
        let sum_totals = [0, 0, 0, 0, 0, 0, 0, 0];
        let sum_total_contact = 0;
        let outstandingBalanceOfCustomer = 0;
        let summaryAmoutBeforePaid = 0;
        let summaryAmountPaid = 0;

        // ใช้ในกรณีที่ ลูกหนี้เป็นรายการสุดท้ายที่อยู่ในหน้าที่ render มา
        let outstandingBalanceForThelastLine = 0;
        
        let summaryAmoutBeforePaidAll = this.props.state.summaryIndividualReceivableReport.total;
        let summaryBalanceAll = this.props.state.summaryIndividualReceivableReport.balance;
        let summaryAmountPaidAll = summaryAmoutBeforePaidAll - summaryBalanceAll;


        return (
            <React.Fragment>
                {
                    this.props.props.invoiceViewer.allInvoice.edges.map((invoice, index) => {                                                              
                        let first = true
                        let invoice_select = null
                        // let invoiceSelectNextItem = [];                        
                        let firstLineOfCustomer = false;
                        let invoiceName = "";
                        let firstName = "";
                        let lastName = "";
                        let sameNextItem = true;                                                        
                        
                        if(index > 0 && invoice.node.contact?.id !== this.props.props.invoiceViewer.allInvoice.edges[index-1]?.node?.contact?.id){                            
                            outstandingBalanceOfCustomer = 0;
                            if(invoice.node.contact.typeOfContact === "RESIDENTIAL") {
                                invoiceName = invoice.node.contact.residential.name
                                firstName = invoice.node.contact.firstName
                                lastName = invoice.node.contact.lastName
                                firstLineOfCustomer = true
                            } else if(invoice.node.contact.typeOfContact === "SUPPLIER") {
                                invoiceName = invoice.node.contact.refNumber
                                firstName = invoice.node.contact.name
                                lastName = ""
                                firstLineOfCustomer = true
                            }

                        } else if(index === 0){                            
                            if(invoice.node.contact.typeOfContact === "RESIDENTIAL") {
                                invoiceName = invoice.node.contact.residential.name
                                firstName = invoice.node.contact.firstName
                                lastName = invoice.node.contact.lastName
                                firstLineOfCustomer = true
                            } else if(invoice.node.contact.typeOfContact === "SUPPLIER") {
                                invoiceName = invoice.node.contact.refNumber
                                firstName = invoice.node.contact.name
                                lastName = ""
                                firstLineOfCustomer = true
                            }
                        }

                        if(this.props.props.invoiceViewer.allInvoice.edges[index+1] && invoice.node.contact?.id !== this.props.props.invoiceViewer.allInvoice.edges[index+1]?.node?.contact?.id){                            
                            sameNextItem = false                            
                        } else if(!this.props.props.invoiceViewer.allInvoice.edges[index+1]) {
                            sameNextItem = false
                        } else {
                            sameNextItem = true
                        }

                        if (this.props.state.product_and_service === "") {
                            invoice_select = invoice.node.transaction.edges
                        }
                        else {
                            invoice_select = invoice.node.transaction.edges.filter(element => {
                                if(element.node.productAndService){
                                    if(element.node.productAndService.id === this.props.state.product_and_service){
                                        return element
                                    }
                                }
                            });

                            // invoice_select ตัวถัดไป
                            // invoiceSelectNextItem = this.props.props.invoiceViewer.allInvoice.edges[index +1]?.node.transaction.edges.filter(element => {
                            //     if(element.node.productAndService){
                            //         if(element.node.productAndService.id === this.props.state.product_and_service){
                            //             return element
                            //         }
                            //     }
                            // });
                        }                        
                        return (                          
                            invoice_select.map((transaction, t_index) => {                                
                                const amountOwed = -0.01 <this.getAmountOwed(transaction) && this.getAmountOwed(transaction) < 0.01 ? 0 : this.getAmountOwed(transaction) 
                                const amountOwedWithComma = numberWithComma(amountOwed, "")
                                const amountPaid = this.getAmountPaid(transaction)
                                const amountPaidWithComma  = numberWithComma(amountPaid, "")  

                                    if (amountOwed !== 0) {                                                                                
                                        first = false

                                        outstandingBalanceOfCustomer += amountOwed
                                        

                                        let total_contact = 0
                                        sum_total_contact += amountOwed
                                        // หา outstading balance customer โดยการเช็คว่า customer รายการล่าสุดกับรายการถัดไปเป็นตัวเดียวกันมั้ย
                                        if(invoice.node.contact.id !== this.props.props.invoiceViewer.allInvoice.edges[index +1]?.node.contact.id 
                                            && this.props.props.invoiceViewer.allInvoice.edges[index+1]
                                            ){
                                                outstandingBalanceForThelastLine = 0;
                                        } else {
                                            outstandingBalanceForThelastLine += amountOwed
                                        }

                                        if (!invoice.node.transaction.edges[t_index + 1]) {
                                            total_contact = sum_total_contact
                                            sum_total_contact = 0
                                        }     
                                        if (this.getNumberDay(invoice) < 1) {
                                            sum_totals[0] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 1, 30)) {
                                            sum_totals[1] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 31, 60)) {
                                            sum_totals[2] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 61, 90)) {
                                            sum_totals[3] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 91, 120)) {
                                            sum_totals[4] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 121, 150)) {
                                            sum_totals[5] += amountOwed
                                        } else if (this.between(this.getNumberDay(invoice), 151, 180)) {
                                            sum_totals[6] += amountOwed
                                        } else {
                                            sum_totals[7] += amountOwed
                                        }                        
                                        summaryAmoutBeforePaid += transaction.node.total
                                        summaryAmountPaid += amountPaid


                                        // กรณีที่บรรทัดสุดท้าย มี amountOwed เป็น 0 จะให้แสดงยอด รวมของ customer ที่มียอดเป็นบรรทัดสุดท้าย
                                        let haveNextAmountOwed = true
                                        if(t_index !== invoice_select.length - 1){
                                            for (let i = t_index + 1; i < invoice_select.length; i++) {                                                
                                                const nextTransaction = invoice_select[i];                                                       
                                                if(nextTransaction && this.getAmountOwed(nextTransaction)) {
                                                    haveNextAmountOwed = true
                                                    break;
                                                } else {
                                                    haveNextAmountOwed = false
                                                }                                                                                                                  
                                            }   
                                        } else {
                                            haveNextAmountOwed = false
                                        }



                                        return (
                                            (
                                                <React.Fragment key={transaction.node.id}>
                                                    {firstLineOfCustomer && t_index === 0 && 
                                                        <tr>
                                                            <td>            
                                                                {invoiceName}                                                
                                                            </td>
                                                            <td colSpan={11}>
                                                            {getNameResidential(firstName,lastName)} 
                                                            </td>
                                                        </tr>
                                                    }
                                                    <tr>                                                    
                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                        <td className="text-center">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                        <td className="text-center">{format(invoice.node.dueDate, 'DD/MM/YYYY')}</td>
                                                        <td className="text-center">
                                                            <Link
                                                                to={"/accounting/income/invoice/invoice-detail/" + invoice.node.id}
                                                                target={"_blank"}>
                                                                {invoice.node.docNumber}
                                                            </Link>
                                                        </td>
                                                        <td>{transaction.node.productAndService ? transaction.node.productAndService?.productCode : (transaction.node.chartOfAccount && transaction.node.chartOfAccount?.chartOfAccountCode === '1131-12' || transaction.node.chartOfAccount?.chartOfAccountCode === '1131-10' ? 'S000' : '')}</td>
                                                        <td>{
                                                            this.removeTrailingZeros(transaction.node.description)
                                                            }</td>
                                                        <td className="text-center">         
                                                            {this.calOverdueDate(invoice.node.dueDate)}                                                                 
                                                        </td>
                                                        <td className="text-right">{numberWithComma(transaction.node.total, "")}</td>
                                                        <td className="text-right">{amountPaidWithComma}</td>
                                                        <td className="text-right">{amountOwedWithComma}</td>                                                    
                                                        {
                                                            !haveNextAmountOwed && this.props.haveNoFilterContact ?
                                                                (!sameNextItem?                                                           
                                                                    this.props.props.invoiceViewer.allInvoice.edges[index+1] ? 
                                                                    <td className="text-right">{numberWithComma(outstandingBalanceOfCustomer, "")}</td>
                                                                    :
                                                                    <td className="text-right">{numberWithComma(outstandingBalanceForThelastLine, "")}</td>
                                                                :<td>{" "}</td> )
                                                                    :
                                                                this.props.haveNoFilterContact && <td>{" "}</td>
                                                        }                                  
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        )
                                
                                    } else if(t_index === 0 && firstLineOfCustomer){
                                        // กรณีที่ data ที่ query มามี transaction transsaction แรกมี AmountOwed เป็น 0 แต่ transaction อื่นมีปกติจะให้แถวแรกแสดงชื่อ
                                        for (let index = 0; index < invoice_select.length; index++) {
                                            const currentTransaction = invoice_select[index];                                            
                                            let thisCustomerHaveAmountOwned = currentTransaction && this.getAmountOwed(currentTransaction)
                                            if (thisCustomerHaveAmountOwned){
                                                return (
                                                    <tr key={currentTransaction.node.id + index}>
                                                        <td>{invoiceName}</td>
                                                        <td colSpan={11}>{firstName + " " + lastName}</td>
                                                    </tr>
                                                );
                                            }
                                        }
                                    }
                            })
                        )
                    })
                }
                {
                    (this.props.state.contact || this.props.state.product_and_service || this.props.props.invoiceViewer.allInvoice.pageInfo.hasNextPage || this.props.props.invoiceViewer.allInvoice.pageInfo.hasPreviousPage) &&
                    
                    <tr>
                        <td colSpan={6} className="text-right"><strong>รวม</strong></td>
                        <td className="text-right"><strong>{numberWithComma(summaryAmoutBeforePaid, "")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(summaryAmountPaid, "")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(sum_totals.reduce((a, b) => a + b, 0), "")}</strong></td>
                        {this.props.haveNoFilterContact && <td className="text-right"><strong>{numberWithComma(sum_totals.reduce((a, b) => a + b, 0), "")}</strong></td>}
                    </tr>
                }

                {!(this.props.state.contact || this.props.state.product_and_service) &&
                <tr>
                    <td colSpan={6} className="text-right">
                        {
                            this.props.state.summaryReceivableOutstandingLoading && <span className="spinner-border spinner-border-sm align-middle mr-3"></span> 
                        }
                        <strong>รวมทั้งหมด</strong>
                    </td>
                    {
                        !this.props.state.summaryReceivableOutstandingLoading &&
                        (
                            <>
                                <td className="text-right"><strong>{numberWithComma(summaryAmoutBeforePaidAll, "")}</strong></td>
                                <td className="text-right"><strong>{numberWithComma(summaryAmountPaidAll, "")}</strong></td>
                                <td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding?.reduce((a, b) => a + b, 0), "")}</strong></td>
                                {this.props.haveNoFilterContact && <td className="text-right"><strong>{numberWithComma(this.props.state.summaryReceivableOutstanding?.reduce((a, b) => a + b, 0), "")}</strong></td>}
                            </>
                        )}
                </tr>}
            </React.Fragment>
        )
    }
}

export default IndividualReceiptReportTable;
