// import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import CollectionLetter from '../document/collection_letter/collectionLetter'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../env/environment";
import {QueryRenderer} from "react-relay";
import _ from 'lodash';
import OutStandingCollectionLetter from '../document/collection_letter/OutstandingCollectionLetter';
import Loading from "../libs/loading";
import WrapperCollectionLetterOldTime from '../document/collection_letter/wrapperCollectionLetter'

const query = graphql`
   query wrapperCollectionLetterEmailQuery($collectionLettersId: [String] , $formDebit: Int){
    selfProjectAuth{
            id
            name
            address
            logo
            juristicContactNumber
            keyProjectQr
            bankCompCode
            bankServiceCode
            taxIdNumber
            typeOfProject
            typeOfCompany
            establishmentStatus
    }
    allSettingAutomaticDebitAuth{
            edges{
                node{
                    receivableOutstanding
                }
            }
        }
    allSettingFormDebitAuth(formDebit: $formDebit) {
            edges{
                node{
                    id
                    description
                    paymentCheck
                    paymentTimeStatus
                    paymentTime1
                    paymentTime2
                    bankAccountStatus
                    numberOfDays
                    bankAccount{
                        id
                        accountNumber
                        bankName
                        accountName
                        branch
                    }
                    channelDescription
                    contactName
                    contactPhone
                    signerName
                    signerPosition
                    formDebit
                    refDocument
                    refReport
                    refCollection1
                    refCollection2
                    refCollection3
                }
            }
        }
    allCollectionLettersAuth(collectionLettersId:$collectionLettersId){
            edges{
                node{
                    id
                    numberOfTimes
                    refDocNumber
                    docNumber
                    added
                    issuedDate
                    creator
                    receivableOutstanding
                    debtStatus
                    status
                    updated
                    contact {
                        id
                        name
                        refNumber
                        firstName
                        lastName
                        registeredName
                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode
                        typeOfContact
                        residential {
                            type {
                                name
                                slug
                            }
                        }
                    }
                    documentCollectionLetters{
                                edges{
                                    node{
                                        id
                                        fileName
                                        numPage
                                    }
                                }
                    }
                    recordCollectionLetters{
                        edges {
                            node {
                                id
                                transaction {
                                            id
                                            description
                                            total
                                            invoice{
                                                id
                                                docNumber
                                                dueDate
                                                total
                                                issuedDate
                                            }
                                            productAndService {
                                                id
                                                productCode
                                                name
                                            }
                                            chartOfAccount {
                                                chartOfAccountCode
                                            }
                                            receiveTransaction(receive_Status_In: "paid") {
                                                edges {
                                                node {
                                                    amount
                                                    added
                                                    receive {
                                                    issuedDate

                                                    }
                                                }
                                                }
                                            }
                                            creditNoteTransaction(status: "paid") {
                                                edges {
                                                node {
                                                    price
                                                    issuedDate
                                                    receive {
                                                    id
                                                    }
                                                }
                                                }
                                            }
                                            creditNoteTransactionRecord(status: "paid"){
                                                edges{
                                                    node{
                                                        id
                                                        price
                                                        status
                                                    }
                                                }
                                            }
                                            receiptDepositTransactionUsed {
                                                edges {
                                                node {
                                                    amount
                                                    receiveTransaction {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }`;

class WrapperCollectionLetterEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type : 'all',
            indexOutStanding : 1,
        }
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    updateIndexOutStanding = (value) => {
        this.setState({
            indexOutStanding : value
        })
    }

    render() {
        
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Collection Letter Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{collectionLettersId:[this.props.match.params.id],formDebit:parseInt(this.props.match.params.numberOfTimes) > 4 ? 4 : parseInt(this.props.match.params.numberOfTimes)}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let group_transaction = _(props.allCollectionLettersAuth.edges[0].node.recordCollectionLetters.edges).groupBy('node.transaction.productAndService.name').map((value,name)=> {

                                let sumTotal = _.sumBy(value,'node.transaction.total')
                                _.forEach(value, transaction => {
                                    if(transaction.node.transaction.creditNoteTransaction.edges.length > 0){
                                        let sumCredit = _.sumBy(transaction.node.transaction.creditNoteTransaction.edges, 'node.price') 
                                        sumTotal -= sumCredit
                                    }
                                    if(transaction.node.transaction.creditNoteTransactionRecord.edges.length > 0){
                                        let sumCredit = _.sumBy(transaction.node.transaction.creditNoteTransactionRecord.edges, 'node.price') 
                                        sumTotal -= sumCredit
                                    }
                                    if(transaction.node.transaction.receiptDepositTransactionUsed.edges.length > 0){
                                        // let sumReceiveUse = _.sumBy(transaction.node.transaction.receiptDepositTransactionUsed.edges, 'node.amount') 
                                        // sumTotal -= sumReceiveUse
                                        const sumReceiveUse = transaction.node.transaction.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
                        
                                            if (obj.node.receiveTransaction) {
                                                return total
                                            } else {
                                                return total + obj.node.amount
                                            }
                                        }, 0);

                                        sumTotal -= sumReceiveUse
                                    }
                                    if(transaction.node.transaction.receiveTransaction.edges.length > 0){
                                        let sumReceive = _.sumBy(transaction.node.transaction.receiveTransaction.edges, 'node.amount') 
                                        sumTotal -= sumReceive
                                    }
                                })
                                return({
                                    name: name,
                                    sum_transaction: sumTotal,
                                })
                            }).value()

                            group_transaction = _.filter(group_transaction,transaction => {
                                return transaction.sum_transaction > 0
                            })

                            let recordCollectionLetters = []
                            let countList = 20
                            
                            //พิเศษ
                            if(localStorage.getItem('site_id') === '340' || localStorage.getItem('site_id') === '468' || localStorage.getItem('site_id') === '402' || localStorage.getItem('site_id') === '490' || localStorage.getItem('site_id') === '456'){
                                countList = 10
                            }

                            // theteak39 - CLL-2022070001
                            if(localStorage.getItem('site_id') === '340' && this.props.match.params.id === 'Q29sbGVjdGlvbkxldHRlcnNOb2RlOjQ1ODI1MQ=='){
                                countList = 9
                            }

                            let sumLine = 0;
                            let dataMapLine = []

                            if(localStorage.getItem('site_id') !== '340' && localStorage.getItem('site_id') !== '468' && 
                                localStorage.getItem('site_id') !== '402' && localStorage.getItem('site_id') !== '490' && 
                                localStorage.getItem('site_id') !== '456' && localStorage.getItem('site_id') !== '340'){
                                    let mapData = props.allCollectionLettersAuth.edges[0].node.recordCollectionLetters.edges.map((n,ins)=> ({...n, no : ins+1}))
                                    
                                    _.forEach(mapData , (record , index) => {
                                 
                                        dataMapLine.push(record)
                                        if(record.node.transaction.description.length >= 145){
                                            sumLine = sumLine + 5;  //cal row description per page
                                        }
                                        else if(record.node.transaction.description.length >= 115){
                                            sumLine = sumLine + 4; 
                                        }
                                        else if(record.node.transaction.description.length >= 95){
                                            sumLine = sumLine + 3;
                                        }
                                        else if(record.node.transaction.description.length >= 75){
                                            sumLine = sumLine + 2.7;
                                        }
                                        else if(record.node.transaction.description.length >= 40){
                                            sumLine = sumLine + 2.1; 
                                        }
                                        else if(record.node.transaction.description.length > 0) {
                                            sumLine = sumLine + 1.1; 
                                        }
                                        
                                        if(sumLine >= 37 || props.allCollectionLettersAuth.edges[0].node.recordCollectionLetters.edges.length === (index+1)){
                                            recordCollectionLetters.push(dataMapLine)
                                            dataMapLine = []
                                            sumLine = 0
                                        }
                                    })

                            }else{
                                if(props.allCollectionLettersAuth.edges[0].node.recordCollectionLetters.edges.length > countList){
                                    recordCollectionLetters = _.chunk(props.allCollectionLettersAuth.edges[0].node.recordCollectionLetters.edges,countList)
                                }else{
                                    recordCollectionLetters = [props.allCollectionLettersAuth.edges[0].node.recordCollectionLetters.edges]
                                }

                            } 
                            
                            let totalRecordOutStanding = _.sumBy(props.allCollectionLettersAuth.edges[0].node.recordCollectionLetters.edges,'node.transaction.total')
                            _.forEach(props.allCollectionLettersAuth.edges[0].node.recordCollectionLetters.edges , record => {
                                if(record.node.transaction.creditNoteTransaction.edges.length > 0){
                                    let sumCredit = _.sumBy(record.node.transaction.creditNoteTransaction.edges, 'node.price') 
                                    totalRecordOutStanding -= sumCredit
                                }
                                if(record.node.transaction.creditNoteTransactionRecord.edges.length > 0){
                                    let sumCredit = _.sumBy(record.node.transaction.creditNoteTransactionRecord.edges, 'node.price') 
                                    totalRecordOutStanding -= sumCredit
                                }
                                if(record.node.transaction.receiptDepositTransactionUsed.edges.length > 0){
                                    // let sumReceiveUse = _.sumBy(record.node.transaction.receiptDepositTransactionUsed.edges, 'node.amount') 
                                    // totalRecordOutStanding -= sumReceiveUse
                                    const sumReceiveUse = record.node.transaction.receiptDepositTransactionUsed.edges.reduce((total, obj) => {
                        
                                        if (obj.node.receiveTransaction) {
                                            return total
                                        } else {
                                            return total + obj.node.amount
                                        }
                                    }, 0);

                                    totalRecordOutStanding -= sumReceiveUse
                                }
                                if(record.node.transaction.receiveTransaction.edges.length > 0){
                                    let sumReceive = _.sumBy(record.node.transaction.receiveTransaction.edges, 'node.amount') 
                                    totalRecordOutStanding -= sumReceive
                                }
                            } )
                            
                            return(
                                <React.Fragment>
                                    <CollectionLetter 
                                        selfProject={props.selfProjectAuth}
                                        allSettingFormDebit = {props.allSettingFormDebitAuth?.edges[0]}
                                        allCollectionLetters = {props.allCollectionLettersAuth?.edges[0]}
                                        group_transaction = {group_transaction}
                                        receivablePage = {recordCollectionLetters?.length}
                                    />
                                    
                                    {/* แนบทวงภามครั้งที่ 1 */}
                                    {(this.props.match.params.numberOfTimes >= 2 && props.allSettingFormDebitAuth?.edges[0]?.node.refCollection1) &&
                                    <WrapperCollectionLetterOldTime 
                                        collectionLettersId = {this.props.match.params.id}  
                                        receivablePage = {recordCollectionLetters?.length}       
                                        formDebit = {1}          
                                        searchOld = {props.allCollectionLettersAuth?.edges[0].node.refDocNumber}    
                                        numberOftime = {this.props.match.params.numberOfTimes}
                                        ref2 = {props.allSettingFormDebitAuth?.edges[0]?.node.refCollection2}                   
                                        ref3 = {props.allSettingFormDebitAuth?.edges[0]?.node.refCollection3}                                    
                                    />}
                                    

                                    {/* props.allCollectionLetters.edges[0].node.receivableOutstanding && */}
                                    {props.allSettingFormDebitAuth?.edges[0]?.node?.refReport &&
                                        recordCollectionLetters.map((record,index) => {
                                            return (
                                                <OutStandingCollectionLetter selfProject={props.selfProjectAuth} 
                                                    allCollectionLetters = {props.allCollectionLettersAuth.edges[0]} 
                                                    recordCollectionLetters = {record}
                                                    lastPage = {index+1 === recordCollectionLetters.length ? true : false}
                                                    key = {index}
                                                    indexRecord = {index}
                                                    countList ={countList}
                                                    sumRecord = {totalRecordOutStanding}
                                                    updateIndexOutStanding = {this.updateIndexOutStanding}
                                                    indexOutStanding = {this.state.indexOutStanding}
                                                />  
                                            )
                                        })
                                    }
                                </React.Fragment>   
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                /> 
            </React.Fragment>
        );
    }
}

export default WrapperCollectionLetterEmail;
