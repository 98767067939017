
import React, { Component } from 'react';
import '../debtFreeCertification.scss'


export default class editForm extends Component {

    constructor(props) {
        super(props);
        const name = this.props.name;
        this.state = {
              inputName: name,
            };
 
        this.handleNameChange = this.handleNameChange.bind(this);
      }

    handleNameChange(e){
        this.setState({ inputName: e.target.value });
    }

    handleAddClick = () => {
        localStorage.setItem("nameDebtFreeCertification", this.state.inputName)
        this.props.onAddName(this.state.inputName);
        this.handleCloseForm();
      };

    handleCloseForm = () => {
        this.props.onHide()
    }

    render(){
        return (
            <div className='editForm'>
                <form>
                    <label><h5>แก้ไขผู้รับ</h5></label><br/>
                    <textarea style={{ resize: 'none' }} type='text' className='form-control' value={this.state.inputName} onChange={this.handleNameChange} />
                </form>
                <div className='btnContainer mt-3'>
                    <button className="editFormBtn cancel" onClick={this.handleCloseForm} >ยกเลิก</button>
                    <button className="editFormBtn confirm" onClick={this.handleAddClick} >ตกลง</button>
                </div>
            </div>
        )
    }
}