import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from "i18next";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import DatePicker from "react-datepicker";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import './settingReceiptIssueAndHoliday.scss';
import holidayData from './settingHolidayJSON.json';
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import Loading from "../../libs/loading";
import { commitMutation } from "react-relay";
import format from "date-fns/format";


const query = graphql`
    query settingReceiptIssueAndHolidayQuery($year: Int!, $receiveDate: Int) {
        calendarPage(year: $year, receiveDate: $receiveDate)
    }
`;

const mutation = graphql`
    mutation settingReceiptIssueAndHolidayMutation($input:UpdateCalendarInput!){
    updateCalendar(input:$input) 
    {
      ok
      message
    }
}
`;

const SettingReceiptIssueAndHoliday = () => {
    const monthsOfYear = [
      { monthName: "January" },
      { monthName: "February" },
      { monthName: "March" },
      {  monthName: "April" },
      { monthName: "May" },
      {  monthName: "June" },
      { monthName: "July" },
      {  monthName: "August" },
      { monthName: "September" },
      { monthName: "October" },
      { monthName: "November" },
      {  monthName: "December" }
    ];

    const [holidayDates, setHolidayDates] = useState(holidayData.HOLIDAYS)
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectReceiptDate, setSelectReceiptDate] = useState(null);
    const [loading, setLoading] = useState(false)
    const currentYear = new Date().getFullYear();
    const minDate = new Date(currentYear, 0, 1); 
    const maxDate = new Date(currentYear, 11, 31); 

    const handleRemoveHoliday = (monthIndex, holidayIndex) => {
      let month = monthIndex + 1;
      let newHolidayDates = { ...holidayDates };

      setHolidayDates(prevState => {
        const updatedHolidays = [...prevState[month].holiday]; 
        updatedHolidays[holidayIndex] = {
          ...updatedHolidays[holidayIndex],
          status: false // Set status to false
        };
    
        return {
          ...prevState,
          [month]: {
            ...prevState[month],
            holiday: updatedHolidays 
          }
        };
      });

    };
    
    const showCalendarHandler = ()=> {
      setShowCalendar(!showCalendar)
    }

    const fetchHolidayData = (update) => {
      setLoading(true)      
      fetchQuery(environment, query, {
        year: currentYear,
        receiveDate: null  
      }).then(data=>{
          setSelectReceiptDate(data.calendarPage?.day_month)
          setHolidayDates(data.calendarPage)
          setLoading(false)
      })
    }

    const fetchNewReceiptDate = () => {
      fetchQuery(environment, query, {
        year: 2024,
        receiveDate: selectReceiptDate 
        }).then(data=>{
          setHolidayDates(prev => {
            const updatedHolidayDates = { ...prev }; 
            const updatedMonths = {};
            for (let i = 1; i <= 12; i++) {
              updatedMonths[i] = { ...updatedHolidayDates[i], receive_date: data.calendarPage[i].receive_date };
            }
            return updatedMonths;
          });       
        })
    }

    useEffect(
      ()=>fetchHolidayData()      
    ,[])

    useEffect(
      ()=>handleAddHoliday()      
    ,[selectedDate])

    const handleAddHoliday = () => {
      if (selectedDate !== null) {
        const date = new Date(selectedDate);
        const monthNumber = (date.getMonth() + 1);
        const formattedDate = format(date,"YYYY-MM-DD")
        let newHolidayDates = { ...holidayDates };
      
        const dateAdded = {
          holidays: formattedDate,
          description_th: "",
          description_en: "",
          status: true,
        }      
        const isDuplicate = newHolidayDates[monthNumber].holiday.some(holiday => holiday.holidays === formattedDate);

        if (!isDuplicate) {
          const updatedHolidays = [
            ...newHolidayDates[monthNumber].holiday,
            dateAdded
          ].sort((a, b) => {
            return new Date(a.holidays) - new Date(b.holidays);
          });
          
          setHolidayDates(prevState => ({
            ...prevState,
            [monthNumber]: {
              ...prevState[monthNumber],
              holiday: updatedHolidays
            }
          }));
        } else {
          const existingHolidayIndex = newHolidayDates[monthNumber].holiday.findIndex(holiday => holiday.holidays === formattedDate);

          if (existingHolidayIndex !== -1 && !newHolidayDates[monthNumber].holiday[existingHolidayIndex].status) {
            const updatedHolidays = newHolidayDates[monthNumber].holiday.map((holiday, index) => {
              if (index === existingHolidayIndex) {
                return {
                  ...holiday,
                  status: true
                };
              }
              return holiday;
            });
        
            setHolidayDates(prevState => ({
              ...prevState,
              [monthNumber]: {
                ...prevState[monthNumber],
                holiday: updatedHolidays
              }
            }));      
          } 
        }
      }
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date);           
        setShowCalendar(false)
    };

    const onChangeReceiptDate = (e) => {
      setSelectReceiptDate(e.target.value)
    }

    const handleKeyPressChangeAllReciptDate = (event) => {
      if (event.key === 'Enter') {
        fetchNewReceiptDate()
      }
    };

    const formatDate = (dateString) => {
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

      const months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


      const date = dateString.split("-");

      const year = parseInt(date[0]);
      const month = parseInt(date[1]);
      const day = parseInt(date[2]);
    
      const newDate = new Date(year, month - 1, day);
      const dayOfWeek = newDate.getDay();    
      const resultDate = `${i18next.t('batchRt:' + daysOfWeek[dayOfWeek])} ` + " " + day + " " + `${i18next.t('batchRt:' + months[month])}` + " " + year;;
      
      return resultDate;
    }   
    
    const saveHoliday = () => {
      let variables = {
        "input":{
          "year":currentYear,
          "calendarPayload": {
            ...holidayDates,
            day_month: parseFloat(selectReceiptDate)
          }
        }
      }      
      commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {  
              if(response.updateCalendar.ok) {
                let update = true;
                fetchHolidayData(update);
              }
            },
        }
      );
    }
    
    const handleChangeReceiptDate = (e, indexMonth) => {
        let month = indexMonth + 1;     
        const date = new Date(e.target.value);
        const formattedDate = format(date,"YYYY-MM-DD")
        setHolidayDates(prevState => ({
          ...prevState,
          [month]: {
            ...prevState[month],
            receive_date: formattedDate
          }
        }));
    }

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>   
        <Translation>
            {(t) =>   
              <div className="container-fluid box" id="setting-receiptissue-holiday">
                <div className="row justify-content-between">
                  <div className="col">
                    <h3 className="mb-4">
                      <Link to="/home">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/wrapperContent/back.png"
                          }
                          alt="back"
                          className="back"
                        />
                      </Link>
                      <span>{t("setting:Date of receipt issue and annual holidays Setting")}</span>
                    </h3>
                  </div>
                </div>
                <div className="d-flex justify-content-between  mb-3  mt-5">
                  <div>
                    <div className="d-flex flex-row">
                    <h4>{t("batchRt:receipt Date")}</h4>
                    <label>
                      <div className="tooltipImg">
                        <img src={process.env.PUBLIC_URL + "/images/tooltip/light.png"} alt="question" className="question ml-2" />
                          <span className="tooltiptext tooltipPositionInput">
                            {t("batchRt:After changing the date, press Enter to update the monthly receipt date.")}                                                  
                          </span>
                          <span className="tooltipTriangle tooltipTriangleInput">
                            <img src={process.env.PUBLIC_URL + '/images/tooltip/tooltipTriangle.png'} alt="tooltipTriangle" />
                          </span>
                      </div>
                    </label>  
                    </div>                  
                    <div className="issueDate">
                      <div className="date">
                        <input 
                          type='number'
                          onChange={(e)=>onChangeReceiptDate(e)}
                          onKeyDown={handleKeyPressChangeAllReciptDate}
                          value={selectReceiptDate || ""}
                        />
                      </div>
                      <div className="text">
                        <p>{t("batchRt:of every month")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="addHolidayContainer d-flex flex-column">
                  <button className="addHolidayBtn" onClick={showCalendarHandler}> <img src={process.env.PUBLIC_URL + '/images/icons/plusIcon.svg'} alt="plus icon"/> 
                  {t("batchRt:add holiday")}
                  </button>
                  {showCalendar && (
                    <>
                      <DatePicker
                        open={true}
                        className="datePicker"
                        popperPlacement="bottom-end"
                        selected={selectedDate}
                        onChange={(e)=>handleDateSelect(e)}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    </>
                    )}
                  </div>
                </div>

                {
                  loading?
                  <Loading />
                    :     
                  <>
                    <div className="holidaySettingTable">
                        <div className="tableHead">
                        <div className="tableHeadText firstColumn"><h3>{t("batchRt:month")}</h3></div>
                        <div className="tableHeadText secondColumn"><h3>{t("batchRt:annual holiday")}</h3></div>
                        <div className="tableHeadText thirdColumn" ><h3>{t("batchRt:monthly receipt date")}</h3></div>
                      </div>
                        {monthsOfYear.map((month,indexMonth)=>{
                        let holidayForMonth = holidayDates[indexMonth+1]?.holiday || [];
                        let receiveDate = holidayDates[indexMonth+1]?.receive_date || null;

                        return (
                          <div className="d-flex flex-row tableContainer" key={indexMonth}>
                            <div className="d-flex flex-column firstColumn">
                              <div className="month"><p>{`${indexMonth + 1} ${t("batchRt:month of")} ${t(`batchRt:${month.monthName}`)}`}</p></div>
                            </div>
                            <div className="d-flex flex-column secondColumn dateContainer">
                            {
                              holidayForMonth?.map((holiday, indexHoliday)=>{
                                return (
                                  holiday.status && <div className={`d-flex flex-row justify-content-between ${(indexHoliday !== holidayForMonth.length -1) ? `underLineDate` : ''} dateHoliday`} key={indexHoliday}>
                                    <p>{formatDate(holiday.holidays)}</p>
                                    <img className="crossIcon" src={process.env.PUBLIC_URL + '/images/icons/crossIcon.svg'} alt="cross icon"                                      
                                    onClick={() => handleRemoveHoliday(indexMonth, indexHoliday)} /> 
                                  </div>
                                )
                              })
                            }
                            </div>
                            <div className="d-flex flex-column thirdColumn receiptDateContainer">
                              <div className="datePickerContainer">
                                <DatePickerAdapter onChange={(e)=>handleChangeReceiptDate(e, indexMonth)} 
                                selected={new Date(receiveDate)} 
                                minDate={new Date(currentYear, indexMonth, 1)}
                                maxDate={new Date(currentYear, indexMonth + 1, 0) }
                                /></div>
                            </div>
                          </div>
                        )})}
                    </div>
                    <div className="confirmSettingContainer">
                      <button className="confirmSettingBtn" onClick={saveHoliday}>{t("batchRt:save")}</button>
                    </div>    
                  </>              
                }        
              </div>  
            }
            </Translation>      
        </WrapperContent>
      </Wrapper>
    );
}

export default SettingReceiptIssueAndHoliday;