import React from 'react';
import { Translation } from 'react-i18next';
import ComponentPagination from '../libs/componentPagination';

class SelectDate extends ComponentPagination {
    constructor(props){
        super(props);
        this.state = {
            date : 'All'
        }
    }

    handleChange = (e) => {
        let startDate = ''
        this.setState({
            date : e.target.value
        })
        let date = new Date()
        if(e.target.value === 'Three-Day'){
            startDate = new Date(date.setDate(date.getDate() - 3))
        }else if(e.target.value === 'Three-Month'){
            startDate = new Date(date.setMonth(date.getMonth() - 3))
        }else if(e.target.value === 'Month'){
            startDate = new Date(date.setMonth(date.getMonth() - 1))
        }else if(e.target.value==='Week'){
            startDate = new Date(date.setDate(date.getDate() - 6))
        }else if(e.target.value === 'Now'){
            startDate = new Date()
        }else{
            startDate = ''
        }
        this.props.callBackStartDate(startDate)
    }



    render(){
        return (
            <Translation>
                {t =>
                    <div className ="col row w-auto d-flex justify-content-end w-auto">
                        <label className="mr-2 align-self-center">{t('developer:Show time')}</label>
                        <select className="form-control dropdown-custom-arrow col-4" name="date"
                                        value={this.state.date}
                                        onChange={this.handleChange}>
                            <option value="All">{t('developer:All')}</option>
                            <option value="Now">{t('developer:Today')}</option>
                            <option value="Three-Day">{t('developer:3 days')}</option>
                            <option value="Week">{t('developer:Week')} / 7 วัน</option>
                            <option value="Month">{t('developer:Month')}</option>
                            <option value="Three-Month">{t('developer:3 months')}</option>
                        </select>
                    </div>
                }
            </Translation>
        );
    }



}
export default SelectDate;