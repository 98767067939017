import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import getNameResidential from "../../libs/getNameResidential";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import '../styles/withholdingTaxPrint.scss';
import i18n  from 'i18next';
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class OtherExpenseAccountRecord extends Component {

    constructor(props) {
        super(props);
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];
        this.state = {
            bangkokCode: bangkokCode,
            project: atob(this.props.query.selfProject.id).split(":")[1],
            tax_list: {},
            token: jwtDecode(window.localStorage.getItem('token')),
            languages: "th",
        };

        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        
        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "district") {
            return "ต."
        } else if (type === "city") {
            return "อ."
        }
    }

    getPn53() {
        let bool = false
        this.props.query.otherExpense.otherExpenseTransaction.edges.forEach((Transaction) => {
            if (Transaction.node.whtRate !== null && Transaction.node.whtRate !== 0) {
                bool = true;
            }
        });
        return bool;
    }

    getProductAndService() {
        if (this.props.query.otherExpense.otherExpenseTransaction.edges.length > 0) {
            return this.props.query.otherExpense.otherExpenseTransaction.edges[0].node.productAndService
        }
    }

    componentWillMount() {
        i18n.changeLanguage(this.props.languages);
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
        })
        setTimeout(() => {
            let page = $('.' + this.props.other_expense_page);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.chart-of-account').height();
            let signature = page.find('.footer').height();

            // let tbody = page.find('tbody').height()
            // let diff = 720 - tbody


            let page_height = 1125 - 150;
            // let sizetoPrint = this.props.size === "a4" ? 800 : 300
            // let page_height = sizetoPrint - 150;
            let diff = page_height - (head + detail + invoice + (signature || 0));
            $('.' + this.props.other_expense_page + ' table').css({ borderBottom: "1px solid" });
            if (this.props.current_page !== this.props.end_page) {
                $('.' + this.props.other_expense_page + ' tbody tr:last-child td').css({ paddingBottom: diff });
            } else {
                $('.' + this.props.other_expense_page + ' tbody tr:last-child td').css({ paddingBottom: diff });
            }
        }, 200);

        let data ={}
        if (this.props.other_expense) {
            data = this.setData(this.props.other_expense)
        }
        this.setState({tax_list:data})

    }

    setData = (data) =>{
        let payRecord 
        let list = {
            issuedDate:'',
            id:'',
            payRecord_list:{type1:[],type2:[],type3:[],type4:[],type5:[],type6:[]}
        }
        if(data.otherExpense){
            payRecord = data.otherExpense.otherExpenseTransaction
            list.issuedDate = data.otherExpense.issuedDate
            list.id = data.otherExpense.id
            payRecord.edges.forEach((val,index)=>{
                let payRecord_list= {}
                payRecord_list['description'] = val.node.description
                payRecord_list['preTaxAmount'] = val.node.preTaxAmount
                payRecord_list['total'] = val.node.total
                payRecord_list['whtRate'] = val.node.whtRate
                payRecord_list['whtRatePercent'] = val.node.whtRatePercent
                payRecord_list['productAndService'] = val.node.productAndService
                if (val.node.productAndService !== null&&val.node.productAndService.typeOfIncome) {
                    payRecord_list['type'] = val.node.productAndService.typeOfIncome
                }else{
                    payRecord_list['type'] = '6'
                }
                if (val.node.whtRate) {
                    // list.payRecord_list[`type${payRecord_list.type}`].push(payRecord_list)
                    if(payRecord_list.type === "1" || payRecord_list.type === "2" || payRecord_list.type === "3" ||  payRecord_list.type === "5" || payRecord_list.type === "6"){
                        list.payRecord_list[`type${payRecord_list.type}`].push(payRecord_list)
                    } else {
                        list.payRecord_list[`type4`].push(payRecord_list)
                    }
                }
            })
        }
        return list
    }

    getType6(description){
        return description.substring(0,60);
    }


    render() {

        // let peper = ['ฉบับที่ 1. (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)', 'ฉบับที่ 2. (สำหรับผู้ถูกหักภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)', 'ฉบับที่ 3. (สำหรับผู้หักภาษีเก็บไว้เป็นหลักฐาน)', 'ฉบับที่ 4. (สำหรับผู้หักภาษีเก็บไว้เป็นหลักฐาน)']
        let tex = 0
        // let total = 0
        this.props.other_expense_account_record_list.forEach(account_record => {
            if (account_record.node.credit > 0) {
                if (account_record.node.name.search(/ภาษี/) >= 0) tex = account_record.node.credit
            }
        })
        return (

            <React.Fragment>
                <div className={"print-page-a4 " + this.props.other_expense_page} id="receive" style={{position: 'relative'}}>
                    <div className="subpage" style={{overflow: 'hidden'}}>
                        <div className="head">
                            <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                                <div style={{minHeight:100,minWidth:100}}>
                                    <img src={this.props.query.selfProject.logo} alt="silverman" />
                                </div>
                                <div>
                                    <div className="title">                                        
                                        <strong>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name): this.props.query.selfProject.name}</strong>
                                    </div>
                                    <div
                                        className="title">{
                                            this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}
                                    </div>
                                    <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                </div>

                            </div>

                            <div className="document-name font-weight-bold">
                                <div className="title">ใบบันทึกค่าใช้จ่ายอื่นๆ</div>
                                <div className="title">Other Expense</div>
                                {/* <div className="title small">(ต้นฉบับ/Original)</div> */}
                            </div>
                        </div>

                        <div className="detail">
                            <div className="customer-detail">
                                {this.props.query.otherExpense.contact ?
                                    <React.Fragment>

                                        <strong style={{width:"138px"}}>รหัสเจ้าหนี้/ID:</strong> {this.props.query.otherExpense.contact.refNumber}
                                        <br />
                                        {this.props.query.otherExpense.contact.typeOfContact === 'RESIDENTIAL' ?
                                            <React.Fragment>
                                                <strong style={{width:"138px"}}>ชื่อเจ้าหนี้/Creditor:</strong>
                                                {getNameResidential(this.props.query.otherExpense.contact.firstName, this.props.query.otherExpense.contact.lastName)}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <strong style={{width:"138px"}}>ชื่อเจ้าหนี้/Creditor:</strong> {this.props.query.otherExpense.contact.name}
                                            </React.Fragment>
                                        }
                                        <br />
                                        <strong style={{width:"138px"}}>ที่อยู่/Address:</strong>

                                        <div className="customer-detail address-space">
                                            {/* {this.props.query.otherExpense.contact.registeredAddress + " "}
                                            {this.props.query.otherExpense.contact.registeredDistrict && this.getPrefix("district", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredDistrict + " "}
                                            {this.props.query.otherExpense.contact.registeredCity && this.getPrefix("city", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredCity + " "}
                                            {this.props.query.otherExpense.contact.registeredProvince && this.getPrefix("province", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredProvince + " "}
                                            {this.props.query.otherExpense.contact.registeredPostalCode + " "} */}

                                             {/* BTA685 new rq */}
                                             {this.props.query.otherExpense?.address + " "}
                                            {this.props.query.otherExpense.district && this.getPrefix("district", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.district + " "}
                                            {this.props.query.otherExpense.city && this.getPrefix("city", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.city + " "}
                                            {this.props.query.otherExpense.province && this.getPrefix("province", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.province + " "}
                                            {this.props.query.otherExpense.postalCode + " "}


                                            {
                                                (this.props.query.otherExpense.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.query.otherExpense.contact.typeOfSupplier === "COMPANY") &&
                                                <React.Fragment>
                                                    {
                                                        this.props.query.otherExpense.contact.typeOfCompany === "HEADQUARTERS" ?
                                                            "สำนักงานใหญ่"
                                                            : this.props.query.otherExpense.contact.typeOfCompany === "BRANCH" &&
                                                            "สาขา " + this.props.query.otherExpense.contact.nameBranch
                                                    }
                                                </React.Fragment>
                                            }

                                        </div>
                                        <br />
                                        <strong style={{width:"138px"}}>เลขผู้เสียภาษี/Tax
                                            ID:</strong> {this.props.query.otherExpense.contact.taxIdNumber}
                                        <br />
                                        <strong style={{width:"138px"}}>เลขที่ใบกำกับ/Attention:</strong> {this.props.query.otherExpense.taxInvoiceNumber}
                                        <span
                                            className="tax-invoice-date">{this.props.query.otherExpense.taxInvoiceNumber ? 'ลวท.' + format(this.props.query.otherExpense.taxInvoiceDate, 'DD/MM/YYYY', { locale: thLocale }) : null}</span>

                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <strong style={{width:"138px"}}>ชื่อเจ้าหนี้/Creditor:</strong> {this.props.query.otherExpense.unknownContact || '-'}
                                    </React.Fragment>
                                }
                                <br />
                            </div>
                            <div className="document-detail">
                                <strong>เลขที่/No:</strong> {this.props.query.otherExpense.docNumber} <br />
                                <strong>วันที่/Date:</strong> {format(this.props.query.otherExpense.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}
                                <br />
                            </div>
                        </div>
                        <div className="chart-of-account">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th width={100} className="text-center">
                                            รหัสบัญชี
                                    </th>
                                        <th className="text-center">
                                            ชื่อบัญชี
                                    </th>
                                        <th width={100} className="text-center">
                                            เดบิต
                                    </th>
                                        <th width={100} className="text-center">
                                            เครดิต
                                    </th>
                                    </tr>
                                </thead>

                                <React.Fragment>
                                    <tbody>
                                        {/* {this.props.other_expense_account_record_list.map((account_record) =>
                                            account_record.node.debit > 0 &&
                                            <tr key={account_record.node.id}>
                                                <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                <td>{account_record.node.name}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                            </tr>
                                        )} */}
                                        {_.orderBy(this.props.other_expense_account_record_list, 'node.id' , 'asc').map((account_record) =>
                                            account_record.node.debit > 0 &&
                                            <tr key={account_record.node.id}>
                                                <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                <td>{account_record.node.chartOfAccountCode.name} {account_record.node.name}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                            </tr>
                                        )}
                                        {this.props.other_expense_account_record_list.map((account_record) =>
                                            account_record.node.credit > 0 &&
                                            <tr key={account_record.node.id}>
                                                <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                <td>{account_record.node.chartOfAccountCode.name} {account_record.node.name}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                            </tr>
                                        )}
                                    </tbody>

                                    <tfoot className="font-weight-bold">
                                        {(this.props.current_page === this.props.end_page) &&
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row">
                                                        <div className="col-1">
                                                            รวม/Total
                                                    </div>
                                                        <div className="col-10 text-center">                                                            
                                                            {i18n.languages[0] === 'th' ?
                                                            (ThaiBaht(this.props.account_record_group.totalDebit)):
                                                            (ConvertNumberToTextEng(this.props.account_record_group.totalDebit))}
                                                    </div>
                                                        <div className="col-1" />
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(this.props.account_record_group.totalDebit.toFixed(2))}
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(this.props.account_record_group.totalCredit.toFixed(2))}
                                                </td>
                                            </tr>
                                        }
                                    </tfoot>
                                </React.Fragment>
                            </table>
                        </div>

                        {/* <div className="footer"> */}
                            {/* {(this.props.current_page === this.props.end_page) &&
                                <div className="signature-large">
                                    <div className="column-foursign" >
                                        .........................................................<br />
                                        <span className="font-weight-bold">ผู้รับเงิน / Receiver</span> 
                                    </div >
                                    <div className="column-foursign">
                                        .........................................................<br />
                                        <span className="font-weight-bold">ผู้จ่ายเงิน / Paid by</span>
                                    </div>
                                    <div  className="column-foursign">
                                        .........................................................<br />
                                        <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                    </div>
                                    <div className="column-foursign">
                                        .........................................................<br />
                                        <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                    </div>
                                </div>
                            } */}
                        {/* </div> */}
                        {/* <div className="number-page">
                            <br />
                            {this.props.print_current_page}/{this.props.all_page}
                        </div> */}

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default OtherExpenseAccountRecord;
