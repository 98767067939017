import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import ResidentialList from "./residentialList";
import CoownerList from "./coownerList"
import CreateResidential from "./createResidential";
import ResidentialDetail from "../detail";
import NoMatch from "../../components/noMatch";
import Account from "../../document";

class Residential extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ResidentialList}/>
                <PrivateRoute exact path={`${this.props.match.url}/coowner-list`} component={CoownerList}/>
                <PrivateRoute exact path={`${this.props.match.url}/create`} component={CreateResidential}/>
                <PrivateRoute path={`${this.props.match.url}/detail`} component={ResidentialDetail}/>
                <PrivateRoute path={`${this.props.match.url}/account`} component={Account}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Residential;