import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import _ from "lodash";
import "./style/settingInvoiceTermsList.scss"
import DatePicker from "react-datepicker";

import { graphql } from "babel-plugin-relay/macro";
import environment from '../../env/environment';
import { fetchQuery, commitMutation } from 'relay-runtime';
import SearchSelect from "../../libs/searchSelect";
import numberWithComma from "../../libs/numberWithComma";
import AccountingTopMenuNavigation from "../../accounting/accountingTopMenuNavigation";

import Swal from "sweetalert2";
import { billingperiodlistObjects, invoiceAdvanceObjects, billingPeriodMonthObjects, pricingTypesObjects, PeriodAdjust } from './dataFilter'
import i18next from 'i18next';

const query = graphql`
    query settingInvoiceEditQuery($id: ID!) {
        allPreInvoiceGroup(id:$id){
            totalCount
            edges{
                node{
                    id
                    contactCode
                    status
                    effectDate
                    creator
                    updated
                    contact{
                        id
                        name
                        firstName
                        lastName
                        residential {
                            id
                            conveyance
                            size
                            ownershipRatio
                        }
                    }
                    preInvoice{
                        edges{
                            node{
                                id
                                amount
                                unitItems
                                incomeAvg
                                invoiceAdvance
                                billingPeriod
                                billingPeriodList
                                pricingType
                                rounding
                                rentPay
                                agentPay
                                status
                                edite
                                productAndService{
                                    id
                                    productCode
                                    name
                                    description
                                    price
                                    type
                                    chartOfAccount{
                                        id
                                        chartOfAccountCode
                                        name
                                    }
                                    customerChartOfAccount{
                                        id
                                        chartOfAccountCode
                                        name
                                    }
                                    creditorChartOfAccount{
                                        id
                                        chartOfAccountCode
                                        name           
                                    }
                                    servicePricing {
                                        edges {
                                            node {
                                                id
                                                periodAdjustment
                                                pricingType
                                                rounding
                                                deferredIncome{
                                                    chartOfAccountCode
                                                    name
                                                }
                                                receiveDeposit{
                                                    chartOfAccountCode
                                                    name
                                                }
                                            }
                                        }
                                    }
                                    servicePricingEachUnit{
                                        edges {
                                            node {
                                                id
                                                pricing
                                                unit{
                                                    id
                                                }
                                            }
                                        }                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        productViewer{
            allProduct (type_In: "service,fine",isActive: true){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        customerChartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        creditorChartOfAccount{
                            id
                            chartOfAccountCode
                            name           
                        }
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        servicePricing {
                            edges {
                                node {
                                    id
                                    periodAdjustment
                                    pricingType
                                    rounding
                                }
                            }
                        }
                        servicePricingEachUnit{
                            edges {
                                node {
                                    id
                                    pricing
                                    unit{
                                        id
                                    }
                                }
                            }                        
                        }
                        description
                        totalLeftInStock
                    }
                }
            }
        }
        group1131: viewer {
            allChartOfAccount(chartOfAccountCode: "1131-12"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                        
                    }
                }
            }
        }

        group113110: viewer {
            allChartOfAccount(chartOfAccountCode: "1131-10"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                        
                    }
                }
            }
        }

        group4300: viewer {
            allChartOfAccount(chartOfAccountCode: "4300-04"){
                totalCount
                edges{
                    node {
                        id
                        chartOfAccountCode
                        name
                        
                    }
                }
            }
        }
        
    }
`;

const mutation = graphql`
  mutation settingInvoiceEditMutation($input: UpdatePreInvoiceInput!) {
    updatePreInvoice(input: $input) {
      ok
    }
  }
`;

const type = [
    {
        label: "ทั้งหมด",
        value: "ทั้งหมด",
    }
];

let residentListData = []

const preInvoice_object = {
    amount: 0, //ราคา / งวดเรียกเก็บ
    unit_items: 1, //จำนวนยูนิต
    income_avg: null, //รายได้เฉลี่ย
    pricing_type: null,
    rounding: null,

    billing_period: null, //งวดการเรียกเก็บ
    billing_period_month: "no", //เดือนที่เรียกเก็บ

    advance_invoice: "no", //แจ้งหนี้ล้วงหน้า
    rent_pay: false, //ผู้เช่ารับภาระ
    agent_pay: false, //ตัวแทนรับภาระ
    //รหัสบัญชี
    productAndService: null,
    status_check: "Create",
    edit: true,
    edit_now: false
}

const SettingInvoiceEdit = (props) => {
    const [redirectToList, setredirectToList] = useState(false);
    const [flgModal, setFlgModal] = useState(false)

    const [PreInvoiceGroup, setPreInvoiceGroup] = useState(null);
    const [allProduct, setAllProduct] = useState(null);
    const [RoomName, setRoomName] = useState(null)


    const [contactData, setcontactData] = useState(null);
    const [residentList, setResidentList] = useState(residentListData);
    const [PreInvoiceList, setPreInvoiceObject] = useState(preInvoice_object);
    const [DeleteRowId, setDeleteRowId] = useState([]);
    const [BillingPeriodList, setPricingTypeList] = useState(billingperiodlistObjects());
    const [invoiceAdvance, setInvoiceAdvance] = useState(invoiceAdvanceObjects());
    const [count, setCount] = useState(false)

    useEffect(() => {
        setResidentList([...residentList, { ...PreInvoiceList },])
        queryPreInvoiceGroup() 
    }, [count])

    const queryPreInvoiceGroup = () => {
        fetchQuery(environment, query, {
            id: props.match.params.id
        }).then(data => {
            //Key main
            setPreInvoiceGroup(data.allPreInvoiceGroup.edges)

            //S000 Check
            if (!data.productViewer.allProduct.edges.some((item) => item.node.productCode === "S000")) {
                let fine_object_node = {
                  chartOfAccount: data.group4300.allChartOfAccount.edges[0].node,
                  customerChartOfAccount: _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'chart_of_account_sena_v2'])?.menu_on ? data.group113110.allChartOfAccount.edges[0].node : data.group1131.allChartOfAccount.edges[0].node,
                  description: "",
                  id: "fine",
                  name: i18next.t("settngAll:Automatically calculate fines")+`.`,
                  price: 0,
                  productCode: "S000",
                  servicePricing: { edges: [] },
                  totalLeftInStock: null,
                  type: "FINE",
                };
                let update_product_and_service = [...data.productViewer.allProduct.edges,{ node: fine_object_node } ];
                setAllProduct(update_product_and_service);
            }else{
                setAllProduct(data.productViewer.allProduct.edges)
            }

            setRoomName(data.allPreInvoiceGroup.edges[0].node.contactCode);
            if (data.allPreInvoiceGroup.edges[0].node.effectDate) setcontactData(new Date(data.allPreInvoiceGroup.edges[0].node.effectDate))

            //Add Date in ResidentList
            let pre_invoice_objects = []
            data.allPreInvoiceGroup.edges[0].node.preInvoice.edges.forEach(pre_invoice => {
                const preInvoice_object_id = {
                    id: pre_invoice.node.id,
                    amount: pre_invoice.node.amount,
                    unit_items: 1,
                    income_avg: pre_invoice.node.incomeAvg,

                    billing_period: pre_invoice.node.billingPeriod.toLowerCase(),
                    billing_period_month: pre_invoice.node.billingPeriodList,
                    // pricing_type: pre_invoice.node.pricingType,
                    pricing_type: pre_invoice.node.productAndService.servicePricing?.edges[0]?.node.pricingType ? 
                                pre_invoice.node.productAndService.servicePricing?.edges[0]?.node.pricingType.toUpperCase()
                                : 'FINE',
                    rounding: pre_invoice.node.productAndService.servicePricing?.edges[0]?.node.rounding.toUpperCase() | null,

                    advance_invoice: pre_invoice.node.invoiceAdvance.toLowerCase(),
                    rent_pay: pre_invoice.node.rentPay,
                    agent_pay: pre_invoice.node.agentPay,
                    //รหัสบัญชี
                    productAndService: pre_invoice.node.productAndService,
                    status_check: "edit",
                    status: pre_invoice.node.status,
                    edit: pre_invoice.node.edite === true && pre_invoice.node.status !== "VOID" ? true : false,
                    edit_now: false
                }
                pre_invoice_objects.push(preInvoice_object_id)
            });
            setResidentList([...residentList, ...pre_invoice_objects,])
        })
    }

    const contactDataInputChange = (e) => {
        setcontactData(e)
    }

    const appendNewRow = () => {
        setResidentList([...residentList, { ...preInvoice_object },])
    }

    const RemoveRow = (e) => {
        let transaction_list = _.cloneDeep(residentList);
        if (transaction_list[e].status_check === "edit") {
            let delete_row_id_objects = _.cloneDeep(DeleteRowId)
            delete_row_id_objects.push(transaction_list[e].id)
            setDeleteRowId(delete_row_id_objects)
        }
        transaction_list.splice(e, 1)
        setResidentList(transaction_list);
    }

    function handleInputAmount(value, index) {
        let transaction_list = _.cloneDeep(residentList);
        if (transaction_list[index].amount != value) {
            transaction_list[index].amount = value;
            setResidentList(transaction_list);
        }
    }

    const handleInputChange = (e) => {
        let transaction_list = _.cloneDeep(residentList);
        if (e.target.name === "productAndService") {

            let checking = false
            const transaction_list_check = _.filter(transaction_list, { 'productAndService': { 'id': e.target.value } })
            transaction_list_check.forEach(element => {
                if (transaction_list_check !== undefined) {

                    //ตั้งครั้งเดียวและยังไม่เคยใช้จะแจ้งซ้ำ
                    if ( (element.billing_period === "oy1" && element.edit === true) ) {
                        checking = true;
                    }
                    //รายการที่ปิดการใช้งานจะไม่แจ้งซ้ำ สามารถทำรายการใหม่ได้
                    if (element.status !== "VOID" && element.billing_period !== "oy1") {
                        checking = true
                    }
                }
            });
            if (checking) {
                transaction_list[e.target.dataset.id].productAndService = null;
                // Swal.fire("มีรายการซ้ำ!", "", "warning");
                Swal.fire({
                    html: `<h1>${i18next.t("settingAll:There are duplicates.")}!</h1>`,
                    type : 'warning',
                    showConfirmButton: true,
                    confirmButtonText: i18next.t("Allaction:Accept"),
                })
                // setResidentList(transaction_list);
                return;
            }

            let product_and_service = _.find(allProduct, { 'node': { 'id': e.target.value } });
            transaction_list[e.target.dataset.id].productAndService = product_and_service.node
            if(product_and_service.node.type !== 'FINE'){ 
                transaction_list[e.target.dataset.id].income_avg = product_and_service.node.servicePricing?.edges[0]?.node.periodAdjustment;
                transaction_list[e.target.dataset.id].pricing_type = product_and_service.node.servicePricing?.edges[0]?.node.pricingType;
                transaction_list[e.target.dataset.id].rounding = product_and_service.node.servicePricing?.edges[0]?.node.rounding
                if (product_and_service.node.servicePricing.edges[0].node.pricingType === "STATIC" ||
                    product_and_service.node.servicePricing.edges[0].node.pricingType === "WATER_METER" ||
                    product_and_service.node.servicePricing.edges[0].node.pricingType === "ELECTRIC_METER" ||
                    product_and_service.node.servicePricing.edges[0].node.pricingType === "CAR_PARK" ||
                    product_and_service.node.servicePricing.edges[0].node.pricingType === "MOTORCYCLE_PARK") {
                    transaction_list[e.target.dataset.id].advance_invoice = 'no';
                }
            }else{
                transaction_list[e.target.dataset.id].billing_period = 'm'
            }

            // transaction_list[e.target.dataset.id].amount = product_and_service.node.price;
        }
        else if (e.target.name === "BillingPeriodList") {
            transaction_list[e.target.dataset.id].billing_period = e.target.value;
            if (e.target.value === "oy1" || e.target.value === "m")
                transaction_list[e.target.dataset.id].billing_period_month = "no";
            else {
                transaction_list[e.target.dataset.id].billing_period_month = null;
            }

        } else if (e.target.name === "BillingPeriodMonth") {
            transaction_list[e.target.dataset.id].billing_period_month = e.target.value;
        } else if (e.target.name === "rentPay") {
            if (transaction_list[e.target.dataset.id].rent_pay) {
                transaction_list[e.target.dataset.id].rent_pay = false;
            }
            else {
                transaction_list[e.target.dataset.id].agent_pay = false;
                transaction_list[e.target.dataset.id].rent_pay = e.target.value;
            }
        }else if (e.target.name === "agentPay") {
            if (transaction_list[e.target.dataset.id].agent_pay){
                transaction_list[e.target.dataset.id].agent_pay = false;
            }
            else{ 
                transaction_list[e.target.dataset.id].agent_pay = e.target.value;
                transaction_list[e.target.dataset.id].rent_pay = false;
            }
        }
        else if (e.target.name === "invoiceAdvance") {
            transaction_list[e.target.dataset.id].advance_invoice = e.target.value;
        }
        transaction_list[e.target.dataset.id].edit_now = true
        setResidentList(transaction_list);

    }

    const SubmitEdit = (e) => {
        e.preventDefault();
        let variables = {
            input: {
                preInvoiceGroup: {
                    id: props.match.params.id,
                    preInvoice: JSON.stringify(residentList),
                    effect_date: contactData,
                    delete_row_id: DeleteRowId,
                }
            },
        };
        //create preinvoice
        commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
                setredirectToList(false);
                if (response.updatePreInvoice.ok) {
                    Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
                        setredirectToList(true);
                    });
                } else {
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
                }
            },
            onError: (err) => {
                setredirectToList(false);
                Swal.fire("Error!", i18next.t("Allaction:Please try again."), "warning");
            },
        });
    }
    const onOpenModal = () => {
        setFlgModal(true)
    }

    const onCloseModal = () => {
        setFlgModal(false)
    }

    const change = () => {
    }

    const path = props.match.params.status

    if (redirectToList) {
        return <Redirect to={"/setting/invoice-setting-terms/detail/" + props.match.params.status + "/" + props.match.params.id} />;
    }
    return (
        <Wrapper>
            <Header />
            <Sidebar />
            {flgModal ?
                <div className="modall-overlay">
                    <div className="modall pb-4" style={{ width: 480 }}>
                        <div className="row col-12 px-0 m-0">
                            <p className="col-11 text-headline-5 pl-0 mb-0">{i18next.t("settingAll:Remove code revenue")}</p>
                            <button
                                type="button"
                                className="close col-1"
                                data-dismiss="modall"
                                aria-label="Close"
                                onClick={onCloseModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-footer box-button-modal"></div>
                        <h5 class="modal-title text-table-select">{i18next.t("settingAll:Do you remove code this revenue?")}</h5>
                        <div class="modal-footer box-button-modal p-0 mt-10">
                            <button
                                className="btn cancel-modal"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={onCloseModal}
                                style={{ width: 119, color: '#F43853', fontSize: 16 }}
                            >
                                {i18next.t("settingAll:Remove")}
                            </button>
                            <button
                                className="btn btn-event-modal text-second-button"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={onCloseModal}
                            >
                                {i18next.t("Allaction:cancel")}
                            </button>
                        </div>
                    </div>
                </div>
                : null}

            <Translation>
                {(t) => (
                    <WrapperContent>
                      <AccountingTopMenuNavigation mini={true}/>
                        {/* <div className="div-setting-invoice" /> */}
                        <div className="container-fluid box">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to={"/setting/invoice-setting-terms/detail/" + props.match.params.status + "/" + props.match.params.id}>
                                            <img
                                                src={process.env.PUBLIC_URL + "/images/icons/back.png"}
                                                alt="back"
                                                className="back"
                                            />
                                        </Link>
                                        <span>{RoomName && RoomName}</span>
                                    </h3>
                                </div>
                            </div>

                            <div className="content-inner mx-2">
                                <form onSubmit={SubmitEdit}>
                                    <div className="grid">
                                        <div className="mt-6">
                                            <p className="text-headline-5">{t("settingAll:Co-owner's information")}</p>
                                            <hr />
                                        </div>
                                        <div>
                                            <div className="row">
                                                <p className="col-1 text-headline-4">{t("settingAll:Room")}</p>
                                                <p className="col-2 text-headline-4">{t("settingAll:Name")}</p>
                                                {path !== "owner" &&
                                                    <p className="col-2 text-headline-4 px-0">{t("settingAll:Billing start date")}</p>}
                                            </div>
                                            <div className="row align-items-center">
                                                <p className="col-1 text-disabled">{PreInvoiceGroup && PreInvoiceGroup[0].node.contactCode}</p>
                                                <p className="col-2 text-disabled">{PreInvoiceGroup && PreInvoiceGroup[0].node.contact.firstName + " " + PreInvoiceGroup[0].node.contact.lastName}</p>
                                                {path !== "owner" && PreInvoiceGroup &&
                                                    <div className="calendars-wrapper col-2 px-0">
                                                        <DatePicker
                                                            id="calDepreciationDate"
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control float-left colorborder"
                                                            selected={contactData}
                                                            onChange={contactDataInputChange}
                                                            required
                                                        />
                                                        <div className="calendars float-right">
                                                            <img className="icon"
                                                                src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
                                                                alt="calendars"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {path === "developer" ?
                                        <div className="mt-6">
                                            <div className="row d-flex align-items-center mb-4">
                                                <p className="mb-0 mx-4 text-headline-5">{t("settingAll:List gifts")}</p>
                                                <form className="d-flex align-items-center justify-content-center">
                                                    <input className="checkbox-select" type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>
                                                </form>
                                                <p className="mb-0 mx-4 text-global-table">{t("settingAll:Giveaway")}</p>
                                                <form className="d-flex align-items-center justify-content-center">
                                                    <input className="checkbox-select" type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>
                                                </form>
                                                <p className="mb-0 mx-4 text-global-table">{t("settingAll:Advance expenses on ownership transfer day")}</p>
                                            </div>
                                        </div> :
                                        <div className="mt-6">
                                            <div className="row d-flex align-items-center mb-4">
                                                <p className="mb-0 mx-4 text-headline-5">{t("settingAll:Condition lists")}</p>
                                            </div>
                                        </div>}


                                    <React.Fragment>
                                        <div className="card-table fade-up">
                                            <table className="table table-hover">
                                                <thead className="table-list">
                                                    <tr>
                                                        <th width="200" className="text-global-table">{t("settingAll:Services code")}</th>
                                                        <th width="120" className="text-global-table">{t("settingAll:Income account")}</th>
                                                        <th width="120" className="text-global-table">{t("settingInvoiceTerms:accountsReceivableRecord")}</th>
                                                        <th width="80" className="text-global-table">{t("settingAll:Average income")}</th>
                                                        <th width="120" className="text-global-table">{t("settingInvoiceTerms:deferred")}</th>
                                                        <th width="60" className="text-global-table">{t("settingAll:Calculation method")}</th>
                                                        <th width="60" className="text-global-table">{t("revenue_setting:Price")}</th>
                                                        <th width="120" className="text-global-table">{t("settingAll:Billing period")}</th>
                                                        <th width="120" className="text-global-table">{t("settingAll:Billing month")}</th>
                                                        <th width="100" className="text-global-table">{t("settingAll:Advance notice(month)")}</th>
                                                        <th width="60" className="text-global-table">{t("settingInvoiceTerms:price/BillingPeriod")}</th>
                                                        <th width="60" className="text-global-table">{t("settingAll:Tenants participate")}</th>
                                                        <th width="60" className="text-global-table">{t("settingAll:Agent participate")}</th>
                                                        <th width="80" className="text-global-table"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {allProduct && PreInvoiceGroup && residentList.map((translation, index) => {
                                                        let productAndServiceOrChartOfAccount = "";
                                                        let chartOfAccountName = "";
                                                        let customerChartOfAccount = "";
                                                        let periodAdjustment = false;

                                                        let price = 0;
                                                        let rounding = "";
                                                        let pricingType_cal = "";
                                                        let pricingType = "";
                                                        let receiveDeposit_code = "";
                                                        let fine = false;
                                                        if (translation.productAndService) {
                                                            productAndServiceOrChartOfAccount = translation.productAndService;
                                                            if(translation.productAndService.id !== 'fine' && translation.productAndService.type !== 'FINE'){
                                                                chartOfAccountName = translation.productAndService.chartOfAccount.chartOfAccountCode + " " + translation.productAndService.chartOfAccount.name;
                                                                customerChartOfAccount = translation.productAndService.customerChartOfAccount.chartOfAccountCode + " " + translation.productAndService.customerChartOfAccount.name;
                                                                if (translation.productAndService.servicePricing.edges[0]?.node.deferredIncome) {
                                                                    receiveDeposit_code = translation.productAndService.servicePricing.edges[0].node.deferredIncome.chartOfAccountCode + " " + translation.productAndService.servicePricing.edges[0].node.deferredIncome.name
                                                                }
                                                                periodAdjustment = translation.productAndService.servicePricing.edges[0].node.periodAdjustment;
                                                                rounding = translation.productAndService.servicePricing.edges[0].node.rounding;

                                                                pricingType = pricingTypesObjects(translation.productAndService.servicePricing.edges[0].node.pricingType);
                                                                pricingType_cal = translation.productAndService.servicePricing.edges[0].node.pricingType;
                                                            }
                                                            else if(translation.productAndService.id === 'fine' || translation.productAndService.type === 'FINE'){
                                                                fine = true
                                                                customerChartOfAccount = translation.productAndService.customerChartOfAccount.chartOfAccountCode + " " + translation.productAndService.customerChartOfAccount.name;
                                                                chartOfAccountName = translation.productAndService.chartOfAccount.chartOfAccountCode + " " + translation.productAndService.chartOfAccount.name;
                                                            }

                                                            if (pricingType_cal === "INDIVIDUAL") {
                                                                let Individual = translation.productAndService.servicePricingEachUnit.edges
                                                                const Individual_find = _.find(Individual, { 'node': { 'unit': { 'id': PreInvoiceGroup[0].node.contact.residential.id } } })
                                                                price = Individual_find?.node.pricing | 0
                                                            }
                                                            else {
                                                                price = translation.productAndService.price
                                                            }

                                                            //ถ้าเป็นใบเก่าๆไม่จำเป้นต้อง call graphql ทุกครั้ง
                                                            if(translation.edit_now){
                                                                if(pricingType_cal !== "RATIO"){
                                                                    PeriodAdjust(pricingType_cal,
                                                                        price,
                                                                        rounding,
                                                                        translation.billing_period,
                                                                        PreInvoiceGroup[0].node.contact.residential.size,
                                                                        translation.productAndService.id,
                                                                        PreInvoiceGroup[0].node.contact.id
                                                                    ).then((value) => {
                                                                        handleInputAmount(value, index)
                                                                    })
                                                                }else{
                                                                    PeriodAdjust(pricingType_cal,
                                                                        price,
                                                                        rounding,
                                                                        translation.billing_period,
                                                                        PreInvoiceGroup[0].node.contact.residential.ownershipRatio,
                                                                        translation.productAndService.id,
                                                                        PreInvoiceGroup[0].node.contact.id
                                                                    ).then((value) => {
                                                                        handleInputAmount(value, index)
                                                                    })
                                                                }
                                                            }
                                                            
                                                        }

                                                        let billing_period_month = billingPeriodMonthObjects(translation.billing_period)
                                                        return (
                                                            <tr key={"transaction" + index}>
                                                                <td width="200">
                                                                    <SearchSelect
                                                                        onChange={handleInputChange}
                                                                        value={productAndServiceOrChartOfAccount.id}
                                                                        name="productAndService"
                                                                        placeholder={t("settigAll:List")}
                                                                        data-id={index}
                                                                        data-key="transaction_list"
                                                                        queryObject={allProduct}
                                                                        keyOfValue="id"
                                                                        require={true}
                                                                        keyOfLabel={"productCode:name"}
                                                                        disabled={translation.edit ? false : true}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <span>
                                                                        {
                                                                            chartOfAccountName
                                                                        }
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span>
                                                                        {
                                                                            customerChartOfAccount
                                                                        }
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <input className="checkbox-select"
                                                                        type="checkbox"
                                                                        checked={periodAdjustment}
                                                                        disabled />
                                                                </td>

                                                                <td>
                                                                    <span>{
                                                                        periodAdjustment ? receiveDeposit_code : null
                                                                    }
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span>
                                                                        {
                                                                            pricingType
                                                                        }
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span>
                                                                        {
                                                                            numberWithComma(price)
                                                                        }
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <SearchSelect
                                                                        onChange={handleInputChange}
                                                                        value={translation.billing_period}
                                                                        name="BillingPeriodList"
                                                                        placeholder={t("settigAll:List")}
                                                                        data-id={index}
                                                                        data-key="transaction_list"
                                                                        queryObject={BillingPeriodList}
                                                                        keyOfValue="value"
                                                                        require={true}
                                                                        keyOfLabel="label"
                                                                        disabled={translation.edit  && !fine ? false : true}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <SearchSelect
                                                                        onChange={handleInputChange}
                                                                        value={translation.billing_period_month}
                                                                        name="BillingPeriodMonth"
                                                                        placeholder={t("settigAll:Choose month")}
                                                                        data-id={index}
                                                                        data-key="transaction_list"
                                                                        queryObject={billing_period_month}
                                                                        keyOfValue="value"
                                                                        require={true}
                                                                        keyOfLabel="label"
                                                                        disabled={ ((translation.billing_period === "oy1" || translation.billing_period === "m") || translation.edit) && !fine ? false : true}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <SearchSelect
                                                                        onChange={handleInputChange}
                                                                        value={translation.advance_invoice}
                                                                        name="invoiceAdvance"
                                                                        placeholder={t("settigAll:Chosee advance notice")}
                                                                        data-id={index}
                                                                        data-key="transaction_list"
                                                                        queryObject={invoiceAdvance}
                                                                        keyOfValue="value"
                                                                        require={true}
                                                                        keyOfLabel="label"
                                                                        disabled={ !(translation.edit) ||
                                                                            translation.billing_period === "oy1" ||
                                                                            pricingType_cal === "STATIC" || pricingType_cal === "WATER_METER" ||
                                                                            pricingType_cal === "ELECTRIC_METER" || pricingType_cal === "CAR_PARK" ||
                                                                            pricingType_cal === "MOTORCYCLE_PARK" || fine ? true : false
                                                                        }
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <span>
                                                                        {
                                                                            numberWithComma(translation.amount)
                                                                        }
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <input className="checkbox-select"
                                                                        onChange={handleInputChange}
                                                                        type="checkbox"
                                                                        name="rentPay"
                                                                        data-id={index}
                                                                        data-key="transaction_list"
                                                                        checked={translation.rent_pay}
                                                                        disabled={translation.status === 'VOID' ? true : false}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <input className="checkbox-select"
                                                                        onChange={handleInputChange}
                                                                        type="checkbox"
                                                                        name="agentPay"
                                                                        data-id={index}
                                                                        data-key="transaction_list"
                                                                        checked={translation.agent_pay}
                                                                        disabled={translation.status === 'VOID' ? true : false}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    {residentList.length > 1 && translation.edit &&
                                                                        <button onClick={() => RemoveRow(index)} className="btn-event" type="button">

                                                                            <img className="icon-bin-outline"
                                                                                src={process.env.PUBLIC_URL + "/images/icons/bin-outline.png"}
                                                                                alt="bin"
                                                                            />

                                                                        </button>
                                                                    }
                                                                </td>

                                                            </tr>
                                                        )
                                                    }
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>

                                        <button type="button" className="btn add-button add mt-3" onClick={appendNewRow}>
                                            +{i18next.t("settingAll:Add list")}
                                        </button>
                                    </React.Fragment>

                                    <div className="row">
                                        <div className="col text-right mt-2 button-grp">
                                            {/* <Link to={"/setting/invoice-setting-terms/detail/" + props.match.params.status + "/" + props.match.params.id}> */}
                                            {/* <button type="button" onClick={SubmitEdit} className="btn btn-new-search add mr-3 text-primary-button"> */}
                                            <button type="submit" className="btn btn-new-search add mr-3 text-primary-button">
                                                <span>{t("Allaction:save")}</span>
                                            </button>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </WrapperContent>
                )}
            </Translation>
        </Wrapper>
    )
}

export default SettingInvoiceEdit