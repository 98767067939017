/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type pdpaAndManagementAcceptPartnerQueryVariables = {||};
export type pdpaAndManagementAcceptPartnerQueryResponse = {|
  +myUser: ?{|
    +juristic: ?{|
      +acceptPolicy: boolean,
      +acceptTermOfUse: boolean,
      +acceptMarketing: boolean,
      +acceptPartner: boolean,
    |}
  |}
|};
export type pdpaAndManagementAcceptPartnerQuery = {|
  variables: pdpaAndManagementAcceptPartnerQueryVariables,
  response: pdpaAndManagementAcceptPartnerQueryResponse,
|};
*/


/*
query pdpaAndManagementAcceptPartnerQuery {
  myUser {
    juristic {
      acceptPolicy
      acceptTermOfUse
      acceptMarketing
      acceptPartner
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptPolicy",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptTermOfUse",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptMarketing",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acceptPartner",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pdpaAndManagementAcceptPartnerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNode",
            "kind": "LinkedField",
            "name": "juristic",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pdpaAndManagementAcceptPartnerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNode",
            "kind": "LinkedField",
            "name": "juristic",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b7f080215364d9eabf6501857d3ebc9",
    "id": null,
    "metadata": {},
    "name": "pdpaAndManagementAcceptPartnerQuery",
    "operationKind": "query",
    "text": "query pdpaAndManagementAcceptPartnerQuery {\n  myUser {\n    juristic {\n      acceptPolicy\n      acceptTermOfUse\n      acceptMarketing\n      acceptPartner\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d323f0936ed0ba4cad8937fac663ea6';

module.exports = node;
