import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash"
import $ from "jquery"
import "./manageRequest.scss"

const ThaiBaht = require('thai-baht-text');

export default class manageRequest extends Component {

  constructor(props) {
    super(props)

    this.state = {
      procurementList: []
    }
  }

  componentDidMount() {
    this.setPage()
  }

  setPage = () => {

    let itemPerOnePage = 10
    let itemPerPages = 22
    let list = []
    let listItemArr = []

    listItemArr = _.chunk(this.props.oneProcurementForm.procurementList.edges, itemPerPages)

    listItemArr.forEach((item, index, indexlast) => {
      list.push(item)
      if (indexlast.length - 1 === index) {
        if (item.length > itemPerOnePage) {
          list.push([])
        }
      }
    })

    this.setState({
      procurementList: list
    })
  }

  isBangkok(code) {
    let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
      10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
      10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
      10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
      10160, 10210, 10310];
    if (_.indexOf(bangkokCode, parseInt(code)) >= 0) {
      return true;
    }
    return false;
  }

  getPrefix(type, code) {
    if (type === "district" && this.isBangkok(code)) {
      return "แขวง"
    }
    else if (type === "city" && this.isBangkok(code)) {
      return "เขต"
    }
    else if (type === "province" && this.isBangkok(code)) {
      return ""
    }
    else if (type === "province") {
      return "จ."
    }
    else if (type === "district") {
      return "ต."
    }
    else if (type === "city") {
      return "อ."
    }
  }

  getType = (type) => {

    let valueResult = ""

    switch (type) {
      case "PRODUCT":
        valueResult = "สินค้า"
        break;
      case "SERVICE":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (งานทั่วไป)"
        break;
      case "SERVICE_CONTACT":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (สัญญาจ้าง)"
        break;
      case "ASSET":
        valueResult = "สินทรัพย์"
        break;
        
      default:
        break;
    }
    return valueResult
  }

  getPreTaxAmount = () => {
    let sumPreTaxAmount = _.sumBy(this.props.oneProcurementForm.procurementList.edges, (o) => o.node.preTaxAmount)

    return numberWithComma(sumPreTaxAmount)
  }

  getVat = () => {
    //แบบนี้คือการปัดเศษ 262.0751 มันปัดเป็น 262.08 ให้
    // let sumVat = _.sumBy(this.props.oneProcurementForm.procurementList.edges, (o) => o.node.preTaxAmount * o.node.vat / 100)
    // แบบนี้คือ เอา ผลรวมทั้งหมด  -  sumPreTaxAmount เพื่อให้ได้ Vat
    let sum = _.sumBy(this.props.oneProcurementForm.procurementList.edges, (o) => o.node.preTaxAmount) 
    let Vats= this.props.oneProcurementForm.total - sum 
    return numberWithComma(Vats || "0.00")
  }

  getDiff(indexProcurementList, paper_type) {

    setTimeout(() => {
      let page = $('.' + indexProcurementList + '-' + paper_type);
      let head = page.find('.head').height();
      let detail = page.find('.detail').height();
      let manageRequestBody = page.find('.manageRequest-body table').height();
      let manageRequestBodyBottom = page.find('.manageRequest-body-bottom').height();
      let footer = page.find('.footer').height();
      let page_height = 1125 - 150;
      let diff = 0
      if (head && detail && manageRequestBody && manageRequestBodyBottom && footer) {

        diff = page_height - (head + detail + manageRequestBody + manageRequestBodyBottom + footer);
        $('.' + indexProcurementList + '-' + paper_type + ' .lastRow td').css({ paddingBottom: diff });

      } else {
        diff = page_height - (head + detail + manageRequestBody);
        $('.' + indexProcurementList + '-' + paper_type + ' .lastRow td').css({ paddingBottom: diff });
      }

    }, 200);
  }

  render() {
    return (
      <React.Fragment>
        {_.map(["original", "copy"], (paper_type, indexPaperType) => {
          let index = 0
          return (
            <React.Fragment key={indexPaperType}>
              {_.map(this.state.procurementList, (procurementList, indexProcurementList, indexProcurementListLast) => {
                this.getDiff(indexProcurementList, paper_type);
                return (
                  <div className={"print-page-a4 " + indexProcurementList + "-" + paper_type} id="manageRequest" key={indexPaperType}>
                    <div className="subpage">

                      <div className="head">
                        <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                          <div style={{ minHeight: 100, minWidth: 100 }}>
                            <img src={this.props.selfProject.logo} alt="silverman" />
                          </div>
                          <div>
                            <strong>{this.props.selfProject.name}</strong>
                            {
                              this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                                <div>
                                  <Translation>{t => <div className="address-header">{this.nextLine(this.props.selfProject.address)}</div>}</Translation>
                                  <Translation>{t => <div className="address-header">{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                </div>
                                :
                                <Translation>{t => <div className="address-header">{this.props.selfProject.address} {t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                            }
                            <Translation>{t => <div>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                          </div>
                        </div>

                        <div className="document-name">
                          <Translation>
                            {t =>
                              <React.Fragment>
                                <div className="title-document-name">ใบขอซื้อ</div>
                                <div className="title-document-name">Purchase Request Form</div>
                              </React.Fragment>
                            }
                          </Translation>
                          <React.Fragment>
                            {
                              paper_type === "original"
                                ? <Translation>{t => <div className="title-document-name">({t("document:Original")})</div>}</Translation>
                                : <Translation>{t => <div className="title-document-name">({t("document:Copy")})</div>}</Translation>
                            }
                          </React.Fragment>
                        </div>
                      </div>

                      <div className="detail">
                        <div className="customer-detail">
                          <React.Fragment>
                            <strong className="sub-customer-detail">รหัสเจ้าหนี้/ID:</strong>{this.props.oneProcurementForm.creditor.refNumber}<br />
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-customer-detail">ชื่อลูกค้า/Customer:</strong>{this.props.oneProcurementForm.creditor.name}<br />
                          </React.Fragment>
                          <div className="d-flex">
                            <Translation>{t => <strong className="sub-customer-detail">{t("document:Address")}:</strong>}</Translation>
                            <div className="customer-detail">
                              {this.props.oneProcurementForm.creditor.registeredAddress + " "}
                              {this.props.oneProcurementForm.creditor.registeredDistrict && this.getPrefix("district", this.props.oneProcurementForm.creditor.registeredPostalCode) + this.props.oneProcurementForm.creditor.registeredDistrict + " "}
                              {this.props.oneProcurementForm.creditor.registeredCity && this.getPrefix("city", this.props.oneProcurementForm.creditor.registeredPostalCode) + this.props.oneProcurementForm.creditor.registeredCity + " "}
                              {this.props.oneProcurementForm.creditor.registeredProvince && this.getPrefix("province", this.props.oneProcurementForm.creditor.registeredPostalCode) + this.props.oneProcurementForm.creditor.registeredProvince + " "}
                              {this.props.oneProcurementForm.creditor.registeredPostalCode + " "}

                              {
                                (this.props.oneProcurementForm.creditor.typeOfSupplier === "PUBLIC_COMPANY" || this.props.oneProcurementForm.creditor.typeOfSupplier === "COMPANY") &&
                                <React.Fragment>
                                  {
                                    this.props.oneProcurementForm.creditor.creditortypeOfCompany === "HEADQUARTERS" ?
                                      "สำนักงานใหญ่"
                                      : this.props.oneProcurementForm.creditor.typeOfCompany === "BRANCH" &&
                                      "สาขา " + this.props.oneProcurementForm.creditor.nameBranch
                                  }
                                </React.Fragment>
                              }
                            </div><br />
                          </div>
                          <React.Fragment>
                            <strong className="sub-customer-detail">เลขประจำตัวผู้เสียภาษี/Tax ID:</strong>{this.props.oneProcurementForm.creditor.taxIdNumber}<br />
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-customer-detail">เลขที่สัญญา/เอกสารอ้างอิง/Reference ID: </strong>{this.props.oneProcurementForm.contractNumber}<br />
                          </React.Fragment>
                          {this.props.oneProcurementForm.contractNumber &&
                            <React.Fragment>
                              <strong className="sub-customer-detail">วันที่เอกสาร/Document Date:</strong>
                              {format(this.props.oneProcurementForm.contractDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                            </React.Fragment>
                          }
                          <React.Fragment>
                            <strong className="sub-customer-detail">วันที่ขอซื้อ/Request Date:</strong>
                            {format(this.props.oneProcurementForm.buyDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-customer-detail">เงื่อนไขการชำระ/Condition:</strong>

                            {
                              this.props.oneProcurementForm.paymentTerms === "OTHER" && this.props.oneProcurementForm.otherPaymentTerms !== null ?
                                `อื่นๆ ระบุเอง ${this.props.oneProcurementForm.otherPaymentTerms}` :
                                this.props.oneProcurementForm.paymentTerms === "OTHER" && this.props.oneProcurementForm.otherPaymentTerms === null ?
                                  "" : `${this.props.oneProcurementForm.paymentTerms.substring(2)} วันหลังจากวันที่วางบิล`
                            }
                            <br />
                          </React.Fragment>
                        </div>
                        <div className="document-detail">
                          <div className="d-flex">
                            <strong className="sub-document-detail">ประเภท/Type:</strong>
                            <div className="type-detail">
                              {this.getType(this.props.oneProcurementForm.type)}
                            </div><br />
                          </div>
                          <React.Fragment>
                            <strong className="sub-document-detail">เลขที่/No.:</strong>{this.props.oneProcurementForm.docNumber}<br />
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-document-detail">วันที่/Date:</strong>
                            {format(this.props.oneProcurementForm.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                          </React.Fragment>
                        </div>
                      </div>

                      <div className="manageRequest-body">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="th-dark">
                              <th>ลำดับที่<br />No.</th>
                              <th>ชื่อรายการ<br />Name</th>
                              <th>รายละเอียด<br />Description</th>
                              <th>จำนวน<br />Quantity</th>
                              <th>ราคาต่อหน่วย<br />Sale price</th>
                              <th>ราคารวม<br />Total price</th>
                              <th>งบประมาณ<br />Funds</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(procurementList, (item, indexItem) => {
                              index += 1
                              let productAndService = item.node.productAndService  ? item.node.productAndService?.name : item.node.chartOfAccount?.name;                              
                              return (
                                <tr key={indexItem}>
                                  <td width="50" className="text-center tdBodyNone">{index}</td>
                                  <td className={`tdBodyNone ${productAndService?.length > 18 ? "f10" : ""}`}>
                                    {
                                      productAndService
                                    }
                                  </td>
                                  <td className="tdBodyNone">{item.node.description}</td>
                                  <td width="50" className="text-center tdBodyNone">{item.node.unitItems}</td>
                                  <td width="80" className="text-center tdBodyNone">{numberWithComma(item.node.price)}</td>
                                  <td width="80" className="text-center tdBodyNone">{numberWithComma(item.node.preTaxAmount)}</td>
                                  <td width="80" className="text-center tdBodyNone">{numberWithComma(item.node.budget)}</td>
                                </tr>
                              )
                            })}
                            <tr className="lastRow">
                              <td className="lasttd tdBodyTopNone" />
                              <td className="lasttd tdBodyTopNone" />
                              <td className="lasttd tdBodyTopNone" />
                              <td className="lasttd tdBodyTopNone" />
                              <td className="lasttd tdBodyTopNone" />
                              <td className="lasttd tdBodyTopNone" />
                              <td className="lasttd tdBodyTopNone" />
                            </tr>
                          </tbody>
                        </table>
                      </div>



                      {indexProcurementListLast.length - 1 === indexProcurementList &&

                        <React.Fragment>
                          <div className="manageRequest-body-bottom">
                            <div className="moreInformation">
                              <div className="comparativeCompany">
                                <div className='row'>
                                  <div className="col-3">
                                    <div><strong>เงื่อนไขอื่นๆ:</strong></div>
                                  </div>
                                  <div className='col-6'>
                                    <p>
                                      {
                                        this.props.oneProcurementForm.warrantyDay === 0 ?
                                          " ไม่มีประกัน" :
                                          "การรับประกัน " + this.props.oneProcurementForm.warrantyDay + " วัน"
                                      }
                                    </p>

                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-3">
                                    <div><strong>บริษัทคู่เทียบ</strong><br />
                                      <span className="comparativeCompanyDetail">{this.props.oneProcurementForm.comparativeCompany}</span>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div><strong>ราคาต่ำสุด</strong><br />
                                      <span className="comparativeCompanyDetail">
                                        {numberWithComma(this.props.oneProcurementForm.lowestOffer)}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div><strong>ราคาสูงสุด</strong><br />
                                      <span className="comparativeCompanyDetail">
                                        {numberWithComma(this.props.oneProcurementForm.highestOffer)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="remark">
                                <div className="mb-2"><strong>เหตุผลประกอบการซื้อ:</strong><br />
                                  <span className="remarkDetail">{this.props.oneProcurementForm.reason}</span>
                                </div>
                                <div className="mb-2"><strong>ข้อเสนอแนะ:</strong><br />
                                  <span className="remarkDetail">{this.props.oneProcurementForm.feedback}</span>
                                </div>
                                <div className="mb-2"><strong>หมายเหตุ:</strong><br />
                                  <span className="remarkDetail">{this.props.oneProcurementForm.remark}</span>
                                </div>
                              </div>
                            </div>

                            <div className="summarizeDetail">
                              <div className="summarizeAmount mb-2">
                                <div className="d-flex justify-content-between">
                                  <div className="itemTitle">จำนวนเงิน</div>
                                  <div className="itemDetail">{this.getPreTaxAmount()}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="itemTitle">ภาษีมูลค่าเพิ่ม</div>
                                  <div className="itemDetail">{this.getVat()}</div>
                                </div>
                              </div>

                              <div className="summarizeTotal">
                                <div className="d-flex justify-content-between">
                                  <div className="summarizeTotalDetail">จำนวนเงินรวมทั้งสิ้น (บาท)</div>
                                  <div className="summarizeTotalDetail">{numberWithComma(this.props.oneProcurementForm.total)}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="summarizeTotalDetail">{ThaiBaht(this.props.oneProcurementForm.total)}</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="footer">
                            <div className="row">
                              <div className="col-4">
                                <div className="footerTitle text-center"><strong>ผู้ยื่นอนุมัติ</strong></div>
                                <div className="footerSignatureDot">
                                  ...........................................................<br />
                                  (..........................................................)
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="footerTitle text-center"><strong>ผู้ตรวจสอบ</strong></div>
                                <div className="footerSignatureDot">
                                  ...........................................................<br />
                                  (..........................................................)
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="footerTitle text-center"><strong>ผู้อนุมัติ</strong></div>
                                <div className="footerSignatureDot">
                                  ...........................................................<br />
                                  (..........................................................)
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      }

                    </div>
                  </div>
                )
              })}
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }
}
