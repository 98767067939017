import React from 'react'
import { Component } from 'react'
import { Translation } from 'react-i18next';
import "./selectResident.scss";
import { Link } from "react-router-dom";
import _ from 'lodash'



class SelectResident extends Component{
    constructor(props){
        super(props)
        this.state = {
            search : this.props.search,
            selectedContact : [],
            selectedContactID : [],
            loading : this.props.loading ? this.props.loading : false,
            residentialList : this.props.residentialList,
            checkAll : false,
        }

        // this.handleChange = this.handleChange.bind(this);
        this.clearList = this.clearList.bind(this);
        this.selectContact = this.selectContact.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.loading !== this.props.loading){
            this.setState({
                loading : this.props.loading
            })
        }
        if(prevProps.residentialList !== this.props.residentialList){
            this.setState({
                residentialList : this.props.residentialList
            })
        }
    }

    handleSearchChange(e) {
        this.setState({ search: e.target.value });
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.props.fetchQuery(this.state.search)
        }
    }

    onSearch = () => {
        this.props.fetchQuery(this.state.search)
    }

    clearList(){
        this.setState({
            selectedContact : [],
            selectedContactID : [],
            checkAll : false,
        },() => this.props.updateContactList(this.state.selectedContact))
    }

    allList = () =>{
        let selectedContact = _.cloneDeep(this.state.residentialList)
        let selectedContactID = []
        _.forEach(this.state.residentialList , (transaction) => {
            selectedContactID.push(transaction.unitStatusList.residentialID)
        })
        this.setState({
            selectedContact : selectedContact,
            selectedContactID : selectedContactID
        },() => this.props.updateContactList(this.state.selectedContact))
    }

    selectContact(param, detail) {
        let selectedContact = _.cloneDeep(this.state.selectedContact)
        let selectedContactID = _.cloneDeep(this.state.selectedContactID)
        if(!selectedContactID.includes(param)){
            selectedContactID.push(param)
            selectedContact.push(detail)
        }
        this.setState({
            selectedContact: selectedContact,
            selectedContactID:selectedContactID
        },() => this.props.updateContactList(this.state.selectedContact));
    }

    onDelete(param){
        let selectedContactID = _.cloneDeep(this.state.selectedContactID);
        let selectedContact = _.cloneDeep(this.state.selectedContact)
        let index_id = selectedContactID.indexOf(param);
        if (index_id !== -1) {
            selectedContactID.splice(index_id, 1)
        }
        let index_contact = _.findIndex(selectedContact, {'unitStatusList' : {'residentialID' : param}})
        if(index_contact !== -1) {
            selectedContact.splice(index_contact, 1)
        }
        this.setState({
            selectedContact: selectedContact,
            selectedContactID:selectedContactID
        },() => this.props.updateContactList(this.state.selectedContact));
    }

    handleCheck = () => {
        this.setState({
            checkAll : !this.state.checkAll
        }, () => {
            if(this.state.checkAll){
                this.allList()
            }else{
                this.clearList()
            }
        })
    }


    render(){
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="row pr-4 pl-4 mt-2" id="select-resident">
                            <div className="col-12 col-md-6 mr-1">
                                <h5>{t("select_customer:Search room")}</h5>
                            </div>
                            <div className="col-12 col-md-6 mr-1">
                                <div className="input-group">
                                    <input type="text" className="form-control border-right-none"
                                            placeholder={t('filterFunction:Input Room')}
                                            name="search"
                                            value={this.state.search}
                                            onChange={this.handleSearchChange}
                                            onKeyDown={this.handleKeyDown}
                                            style={{fontSize:'16px'}}
                                    />
                                    <div className="input-group-append">    
                                        <button className="btn btn-outline-secondary form-control" type="button" style={{fontSize:'16px',borderColor : '#BFBFBF', borderLeft:'none'}}>
                                            <img
                                                src={
                                                process.env.PUBLIC_URL +
                                                "/images/icons/search-outline.png"
                                                }
                                                alt="search"
                                                style={{ width: "17.81px", height: "17.81px" }}
                                                onClick={() => this.onSearch()}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pr-4 pl-4 mt-1 " id="select-resident">
                            <div className="col-12 col-md-6 mr-1 ">
                                <div className="row p-3 col-12 form-inline d-flex align-items-center">
                                    <h4>{t("select_customer:Select room")}</h4>
                                    <input type="checkbox" checked={this.state.checkAll} id="checkall" className="ml-4 " onChange={this.handleCheck}/> 
                                    <label htmlFor="checkall" className="ml-2" style={{fontSize:'16px'}}>{t("select_customer:Select all items")}</label>
                                </div>
                                <div className="col card colSelect">
                                    <div className="col-12">
                                        <div className="row scrollTable" >
                                            <div className="col" >
                                                <div id="list-contact" >
                                                    {this.state.loading ?
                                                    <span className="mt-4 justify-content-center">Loading...</span>
                                                    :
                                                    <table className="table table-hover mt-4" style={{fontSize:'16px'}}>
                                                        <thead className="grey-head">
                                                            <tr>
                                                                <th width={100}>{t("select_customer:Code")}</th>
                                                                <th width={100}>{t("select_customer:Room No.")}</th>
                                                                <th width={200}>{t("select_customer:Owner")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.residentialList.length > 0 &&
                                                            this.state.residentialList.map((contact,index) => {
                                                                return(
                                                                    <tr key={contact.unitStatusList.residentialID}
                                                                        id={contact.unitStatusList.residentialID}
                                                                        onClick={() => this.selectContact(contact.unitStatusList.residentialID, contact)}
                                                                        className={this.state.selectedContactID.includes(contact.unitStatusList.residentialID) ? 'table-primary' : ''}>
                                                                        <td>{contact.unitStatusList.refNumber}</td>
                                                                        <td>{contact.unitStatusList.roomNo}</td>
                                                                        <td>
                                                                            {contact.unitStatusList.ownershipName.map((name,index_name) => {
                                                                                return <div key={index_name}><span>{name}</span><br/></div>
                                                                            })}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                })}
                                                        </tbody>
                                                    </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <div className="row p-3 col-12"><h4>{t("select_customer:Selected items")}</h4></div>
                                    <div className="col card colSelect">
                                        <div className="col-12">
                                            <div className="row scrollTable">
                                                <div className="col">
                                                    <div id="list-contact">
                                                        <table className="table table-hover mt-4"  style={{fontSize:'16px'}}>
                                                            <thead className="grey-head">
                                                                <tr>
                                                                    <th width={100}>{t("select_customer:Code")}</th>
                                                                    <th width={100}>{t("select_customer:Room No.")}</th>
                                                                    <th width={200}>{t("select_customer:Owner")}</th>
                                                                    <th width={100} />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.selectedContact.length > 0 &&
                                                                   this.state.selectedContact.map((contact,index) => {
                                                                    return(
                                                                        <tr key={contact.unitStatusList.residentialID + 'selected'}>
                                                                            <td>{contact.unitStatusList.refNumber}</td>
                                                                            <td>{contact.unitStatusList.roomNo}</td>
                                                                            <td>{contact.unitStatusList.ownershipName.map((name,index_name) => {
                                                                                return <div key={index_name}><span>{name}</span><br/></div>
                                                                                })}
                                                                            </td>
                                                                            <td> 
                                                                                <img className="cursor"  src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="plus"
                                                                                        onClick={() => this.onDelete(contact.unitStatusList.residentialID)}/>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4 justify-content-end mb-2">
                                                <div className="col">
                                                    <button type="button" className="btn btn-clear float-right" onClick={this.clearList}>
                                                        <span>{t("select_customer:Clear items")}</span>
                                                    </button>
                                                </div>
                                            </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-end mb-2 p-4">
                            <div className="col mt-3">
                                <Link to={this.props.nextLink}>
                                    <button type="button"  
                                    disabled={this.state.selectedContact.length === 0} className="btn btn-blue add  float-right">
                                        <span>{t("select_customer:Next")}</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </React.Fragment>

                }
            </Translation>
        )
    }


}
export default SelectResident;