import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from 'react-relay';
import environment from "../../env/environment";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import numberWithComma from "../../libs/numberWithComma";
import Header from '../../components/header/';
import Wrapper from "../../components/wrapper";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import TransactionChannel from "../../libs/transactionChannel";
import Loading from "../../libs/loading"
import i18next from 'i18next';
import BackButtonIcon from '../../components/BackBtn/indexBack';

const query = graphql`
    query dailyJournalDetailQuery($ref_number: String!, $ref_transaction: String) {
        allAccountRecordGroup(refNumber: $ref_number) {
            edges {
                node {
                    id refNumber name added issuedDate refPayExpenseDate category totalDebit totalCredit refTransaction creator workingStatus status
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
        cashTransactionViewer{
            allCashTransaction(refNumber: $ref_transaction){
                edges{
                    node{
                        id
                        description
                        price
                    }
                }
            }
        }

        chequeDepositViewer{
            allChequeDeposit(refNumber: $ref_transaction){
                edges{
                    node{
                        id
                        price
                        date
                        chequeNumber
                        bankName
                        branchNumber
                    }
                }
            }
        }

        chequeTransactionViewer{
            allChequeTransaction(refNumber: $ref_transaction,chequeFilter:true){
                edges{
                    node{
                        id
                        price
                        date
                        chequeNumber
                        bankAccount {
                            accountName
                            branch
                            bankName
                        }
                    }
                }
            }
        }

        allBankAccountTransaction(refTransaction: $ref_transaction) {
            edges{
                node{
                    id
                    price
                    date
                    bankAccount {
                        bankName
                        accountNumber
                    }
                    description
                }
            }
        }

        allCreditCardTransaction(refNumber: $ref_transaction){
            edges{
                node{
                    id
                    amount
                }
            }
        }

        allPaymentGatewayMethod(receive_DocNumber: $ref_transaction){
            edges{
                node{
                    id
                    paymentType
                    paymentGateway{
                        id
                        paymentType
                    }
                    amount
                    feeAmount
                    total
                    depositDate
                    image
                }
            }
        }

        paymentChannelViewer{
            allPaymentChannel(slug:"petty_cash",payRecordGroup_DocNumber:$ref_transaction){
                edges{
                    node{
                        id
                        date
                        payRecordGroup{
                            id
                            docNumber
                            issuedDate
                        }
                        price
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
        }
        otherExpensePaymentChannelViewer{
            allOtherExpensePaymentChannel(otherExpense_DocNumber:$ref_transaction){
                edges{
                    node{
                        id
                        price
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                        rdCash{
                            docNumber 
                        }
                    }
                }
            }
        }

        allAdvancePettyCashChannel(advance_DocNumber:$ref_transaction){
            edges{
                node{
                    id
                    price
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
        allClearAdvancePettyCashChannel(clearAdvance_DocNumber:$ref_transaction){
            edges{
                node{
                    id
                    price
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }

        allClearGuaranteeMoneyReceivedPettyCashChannel(clearGuaranteeMoneyReceived_DocNumber:$ref_transaction){
            edges{
                node{
                    id
                    price
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }

    }
`;

const _ = require('lodash');

class DailyJournalDetail extends Component {
    showBtnEdit(category) {
        if (category === 'PAYMENT' || category === 'RECEIVE' || category === 'GENERAL') {
            if (category === 'PAYMENT' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_payment_edit' })) {
                return true
            }
            else if (category === 'RECEIVE' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_receive_edit' })) {
                return true
            }
            else if (category === 'GENERAL' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_general_edit' })) {
                return true
            }
            return false
        }
        return false
    }

    checkTypeTitle = (value) => {
        let text = ''
        if (value.includes('RR')) {
            text = 'RR'
        } else if (value.includes('SV'))  {
            text = 'SV'
        }else if (value.includes('PV'))  {
            text = 'PV'
        }else if (value.includes('RV'))  {
            text = 'RV'
        }else if (value.includes('JV'))  {
            text = 'JV'
        }
        return "("+text+")"
    };


    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />

                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={`/accounting/daily-journal/${this.props.match.params.category}/list/all`}
                                    LinkText={`${i18next.t('dailyjournal:account record')} #${_.startsWith(this.props.match.params.ref_transaction, "FA") ? this.props.match.params.ref_transaction : this.props.match.params.ref_number}`+" "+this.checkTypeTitle(this.props.match.params.ref_number) }
                                />

                                <div className="content-inner">

                                    <QueryRenderer
                                        environment={environment}
                                        query={query}
                                        variables={{
                                            ref_number: this.props.match.params.ref_number,
                                            ref_transaction: this.props.match.params.ref_transaction
                                        }}
                                        render={({ error, props }) => {
                                            if (props && props.allAccountRecordGroup.edges.length > 0) { 
                                                return (
                                                    <React.Fragment>
                                                        <div className="row mb-3">
                                                            <div
                                                                className="col">{i18next.t("dailyjournalCreate:Account Description")}: {props.allAccountRecordGroup.edges[0].node.name}</div>
                                                            <div className="col text-right">
                                                                {this.showBtnEdit(props.allAccountRecordGroup.edges[0].node.category) && !props.allAccountRecordGroup.edges[0].node.refTransaction && props.allAccountRecordGroup.edges[0].node.status !== 'VOID' &&
                                                                    <Link
                                                                        to={"/accounting/daily-journal/create/" + props.allAccountRecordGroup.edges[0].node.category.toLowerCase() + "/" + props.allAccountRecordGroup.edges[0].node.id}>
                                                                        <button type="button" className="btn btn-secondary add">
                                                                            {i18next.t("AllFilter:Edit")}
                                                                        </button>
                                                                    </Link>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">{i18next.t("dailyjournalDetail:Contact person")}:&nbsp;
                                                                {props.allAccountRecordGroup.edges[0].node.contact ?
                                                                    props.allAccountRecordGroup.edges[0].node.contact.name : ' -'
                                                                }
                                                            </div>
                                                            <div
                                                                className="col"> {i18next.t("dailyjournalDetail:On date")}: {format(props.allAccountRecordGroup.edges[0].node.refPayExpenseDate || props.allAccountRecordGroup.edges[0].node.issuedDate, "DD/MM/YYYY")}</div>
                                                            <div
                                                                className="col"> {i18next.t("dailyjournalDetail:Referenced from")}: {_.startsWith(this.props.match.params.ref_transaction, "FA")
                                                                    ? props.allAccountRecordGroup.edges[0].node.refNumber
                                                                    : !props.allAccountRecordGroup.edges[0].node.refTransaction?.startsWith(
                                                                        props.allAccountRecordGroup.edges[0].node.refNumber?.substring(0, 2)
                                                                    ) ? props.allAccountRecordGroup.edges[0].node.refTransaction || " -"
                                                                        : " -"}
                                                            </div>
                                                            <div className="col">
                                                                <span> {i18next.t("dailyjournalDetail:Account book")}: {t('dailyjournal:' + props.allAccountRecordGroup.edges[0].node.category.toLowerCase())}</span>
                                                            </div>
                                                            <div
                                                                className="col"> {i18next.t("dailyjournalDetail:Created by")}: {props.allAccountRecordGroup.edges[0].node.workingStatus !== 'AUTOMATIC' && props.allAccountRecordGroup.edges[0].node.creator || "-"}</div>
                                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_purchase_print' }) ||
                                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_sales_print' }) ||
                                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_payment_print' }) ||
                                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_receive_print' }) ||
                                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_general_print' }) ?
                                                                <Link
                                                                    to={"/accounting/daily-journal/account/daily-journal/" + props.allAccountRecordGroup.edges[0].node.refTransaction + "/" + props.allAccountRecordGroup.edges[0].node.id}
                                                                    target={"_blank"}
                                                                >
                                                                    {/*<Link to={"/accounting/daily-journal/account/daily-journal/"+"SW52b2ljZU5vZGU6MjI="}>*/}
                                                                    <div className="col text-right">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/print.png'}
                                                                            alt="print" className="print" />
                                                                    </div>
                                                                </Link> : null
                                                            }
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="table-responsive fade-up card mt-3">
                                                                    <table className="table table-hover">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th width={100}> {i18next.t("dailyjournalDetail:Number")}</th>
                                                                                <th width={250}> {i18next.t("dailyjournalDetail:Account name")}</th>
                                                                                <th> {i18next.t("dailyjournalDetail:Transaction description")}</th>
                                                                                <th className="text-right" width={200}>{i18next.t("dailyjournalDetail:Debit")}</th>
                                                                                <th className="text-right" width={200}>{i18next.t("dailyjournalDetail:Credit")}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {props.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((record) => (
                                                                                record.node.debit !== 0 &&
                                                                                <tr key={record.node.id}>
                                                                                    <td>{record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                                                    <td>{record.node.chartOfAccountCode.name}</td>
                                                                                    {/* <td>{record.node.name} {props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.name}</td> */}
                                                                                    <td>{record.node.name}</td>
                                                                                    <td className="text-right">{numberWithComma(record.node.debit)}</td>
                                                                                    <td className="text-right">{numberWithComma(record.node.credit)}</td>
                                                                                </tr>
                                                                            ))}
                                                                            {props.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((record) => (
                                                                                record.node.credit !== 0 &&
                                                                                <tr key={record.node.id}>
                                                                                    <td>{record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                                                    <td>{record.node.chartOfAccountCode.name}</td>
                                                                                    {/* <td>{record.node.name} {props.allAccountRecordGroup.edges.length > 0 && props.allAccountRecordGroup.edges[0].node.name}</td> */}
                                                                                    <td>{record.node.name}</td>
                                                                                    <td className="text-right">{numberWithComma(record.node.debit)}</td>
                                                                                    <td className="text-right">{numberWithComma(record.node.credit)}</td>
                                                                                </tr>
                                                                            ))}

                                                                            <tr>
                                                                                <td colSpan="3" className="text-right">{i18next.t("dailyjournalDetail:Sum")}</td>
                                                                                <td className="text-right">{numberWithComma(props.allAccountRecordGroup.edges[0].node.totalDebit)}</td>
                                                                                <td className="text-right">{numberWithComma(props.allAccountRecordGroup.edges[0].node.totalCredit)}</td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        parseFloat(props.allAccountRecordGroup.edges[0].node.totalDebit) !== parseFloat(props.allAccountRecordGroup.edges[0].node.totalCredit) &&
                                                                        <h6 className="text-center mt-3 text-danger"> {i18next.t("dailyjournalDetail:Updating transaction")}</h6>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*ช่องทางชำระเงิน*/}
                                                        <TransactionChannel
                                                            ref_docNumber={props.allAccountRecordGroup.edges[0].node.refTransaction || false}
                                                            cashTransactionViewer={props.cashTransactionViewer}
                                                            chequeDepositViewer={props.chequeDepositViewer}
                                                            chequeTransactionViewer={props.chequeTransactionViewer}
                                                            allBankAccountTransaction={props.allBankAccountTransaction}
                                                            allCreditCardTransaction={props.allCreditCardTransaction}
                                                            allPaymentChannel={props.paymentChannelViewer.allPaymentChannel}
                                                            allOtherExpensePaymentChannel={props.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel}
                                                            allAdvancePettyCashChannel={props.allAdvancePettyCashChannel}
                                                            allClearAdvancePettyCashChannel={props.allClearAdvancePettyCashChannel}
                                                            allClearGuaranteeMoneyReceivedPettyCashChannel={props.allClearGuaranteeMoneyReceivedPettyCashChannel}
                                                            allAccountRecordGroup={props.allAccountRecordGroup}
                                                            allPaymentGatewayMethod={props.allPaymentGatewayMethod}
                                                            total={props.allAccountRecordGroup.edges[0].node.totalDebit}
                                                        />

                                                    </React.Fragment>
                                                )
                                            } else {
                                                return <Loading />;
                                            }
                                        }}
                                    />

                                </div>

                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default DailyJournalDetail;
