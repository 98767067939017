import React from "react";
import {format} from "date-fns";
import ReactExport from "react-data-export";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import jwtDecode from 'jwt-decode'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const _ = require('lodash');


class ExportReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiDataSet: [],
            projectName: "",
            checkFetchState:false
        };

        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
        this.setDataOR = this.setDataOR.bind(this)
        this.getMethodPayment = this.getMethodPayment.bind(this)
        this.getRTStatus = this.getRTStatus.bind(this)
        this.getDate = this.getDate.bind(this)
    }

    
    getMethodPayment(value){
        let result
        if(value === 'cash'){
            result = 'เงินสด'
        }else{
            result = 'QR Code'
        }
        return result
    }

    getTime (type,paymentDate,startDate) {
        if (type === 'hours') {
           return parseInt(Math.abs(new Date(paymentDate) - new Date(startDate)) / (1000 * 60 * 60))
        } else {
           return parseInt(Math.abs(new Date(paymentDate).getTime() - new Date(startDate).getTime()) / (1000 * 60) % 60)
        }
    }

    getRTStatus(value){
        let result;
        if(value === 'request'){
            result = "ออกใบเสร็จ"
        }else if(value === 'finished'){
            result = "ออกใบเสร็จแล้ว"
        }else{
            result = 'ไม่มีการขอใบเสร็จ' 
        }
        return result
    }

    getPersonType(type){
        let result; 
        switch (type) {
            case "person":
                result = 'บุคคลทั่วไป'
                break;
            case "contact":
                result = 'ติดต่อสำนักงานขาย'
                break;
            case "appointment":
                result = 'ผู้ติดต่อนัดหมายล่วงหน้า'
                break;
            default:
            result = 'ผู้เช่า/ลูกบ้าน'
        }
        return result; 
    }

    
  

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.state.data !== this.props.state.data) {
            !this.props.state.isUseAccount ? this.setData(this.props.state?.data) : this.setDataOR(this.props.state?.data)
        }
    }

    componentDidMount() {
        !this.props.state.isUseAccount ? this.setData(this.props.state.data) : this.setDataOR(this.props.state.data)
    }    

    getDate(){
        return format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")
    }


    funCSubstring(value) {
        let idCard = value !== "" && value !== null ? value.substring(0, 1) + "-XXXX-XXXXX-" + value.substring(10, 12) + "-" + value.substring(12) : "-"
        return idCard
    }

    setDataOR(props) {
        let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
        let printBy = `${jwtDecodes.first_name} ${jwtDecodes.last_name}`
        let data = [];

        //title head
        data.push([
            {
                value: "เลขที่", 
                style: {
                    font: {bold: true} , 
                    alignment: {horizontal: "center"} , 
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่-เวลาเข้าใช้",
                style: {
                    font: {bold: true}, 
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่-เวลารถออก",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ชื่อผู้มาติดต่อ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "เลขประจำตัวประชาชน",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ประเภทบุคคล",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ประเภทสิทธิ์",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "เครื่องมือ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ออกบัตรโดย",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "สถานะ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            }
        ])

        //detail 
        props && props.map((item,index) => {
                data.push([
                    {
                        value: item.reportCode ? item.reportCode : "-",
                        style: {
                            alignment: {horizontal: "center"} ,
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.dateUsingSt ? item.dateUsingSt :'-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.dateCheckOut ?  item.dateCheckOut : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value:  item.fullnameVisit ?  item.fullnameVisit : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.idCard ? this.funCSubstring(item.idCard) : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.typePerson ? this.getPersonType(item.typePerson) : "-",
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.typeRole == "temp" ? "ครั้งเดียว" : "หลายครั้ง",
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }, 
                    {
                        value: item.typeRole == "perm" ? 'LPR' : 'QR CODE',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.ceateBy ? item.ceateBy : '-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }
                ])
        })
     
        let multiDataSet = [
            {  
                columns: [
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 250}},
                    {title: "", width: {wpx: 100}},
                ],
                data
            },
            {
                columns: [                
                        {title: "", style: { font: { sz: "11.5" } }},
                ],    
                data: [
                    [
                        {
                            value: "ผู้พิมพ์ : "+printBy,
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ],
                    [   {
                            value: "วันที่พิมพ์ : " + this.getDate(),
                            style: {font: {sz: "11.5", bold: true}}
                        },
                        
                    ]
                ]
            },
        ];

        this.setState({multiDataSet})
    }

    setData(props) {
        let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
        let printBy = `${jwtDecodes.first_name} ${jwtDecodes.last_name}`
        let data = [];
        
        //title head
        data.push([
            {
                value: "เลขที่", 
                style: {
                    font: {bold: true} , 
                    alignment: {horizontal: "center"} , 
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่-เวลาเข้าใช้",
                style: {
                    font: {bold: true}, 
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่-เวลารถออก",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ชื่อผู้มาติดต่อ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "เลขประจำตัวประชาชน",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ประเภทบุคคล",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ประเภทสิทธิ์",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "เครื่องมือ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ออกบัตรโดย",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "สถานะ",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            }
        ])

        //detail 
        props && props.map((item,index) => {
                data.push([
                    {
                        value: item.reportCode ? item.reportCode : "-",
                        style: {
                            alignment: {horizontal: "center"} ,
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.dateUsingSt ? item.dateUsingSt +"-"+ item.timeUsingSt :'-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.dateCheckOut ?  item.dateCheckOut +"-"+item.timeCheckOut : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value:  item.fullnameVisit ?  item.fullnameVisit : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.idCard ? this.funCSubstring(item.idCard) : "-" ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.typePerson ? this.getPersonType(item.typePerson) : "-",
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }, 
                    {
                        value: item.typeRole == "temp" ? "ครั้งเดียว" : "หลายครั้ง",
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.typeRole == "perm" ? 'LPR' : 'QR CODE',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: item.ceateBy ? item.ceateBy : '-' ,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: parseInt(item.totalPacking || 0) < 1 && item.estampStatus == false ? "ไม่ต้องชำระ" :"ชำระแล้ว",
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    }
                ])
        })
     
        let multiDataSet = [
            {  
                columns: [
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 250}},
                    {title: "", width: {wpx: 100}},
                ],
                data
            },
            {
                columns: [                
                        {title: "", style: { font: { sz: "11.5" } }},
                ],    
                data: [
                    [
                        {
                            value: "ผู้พิมพ์ : "+printBy,
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ],
                    [   {
                            value: "วันที่พิมพ์ : " + this.getDate(),
                            style: {font: {sz: "11.5", bold: true}}
                        },
                        
                    ]
                ]
            },
        ];

        this.setState({multiDataSet})
    }

    buttonElement() {
        return (
            <a className="dropdown-item" target={"_blank"} >Excel</a>
      
            // <span style={{cursor:'pointer'}}>Excel </span>
            // <button type="button" className="btn" disabled={this.state.loading} style={{padding:'0'}}>
               
            //  this.state.loading ?
            // <span className="spinner-border spinner-border-sm align-middle mr-2"> {i18next.t("Allaction:Preparing information")} </span>
            //              :
                            
            //     } 
            // </button>
        );
    }

    render() {
  
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"guard_report_" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}
                >
                <ExcelSheet
                    dataSet={this.state.multiDataSet}
                    name="guardReport"
                />
            </ExcelFile>
        )
    }

}

export default ExportReport;
