import React from "react";
import { Helmet } from "react-helmet";
class PrintA4horizontal extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
          style={[{
            "cssText": `
              body {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  background-color: #FAFAFA;
                }
              
                * {
                  box-sizing: border-box;
                  -moz-box-sizing: border-box;
                }
              
                .page {
                  width: 297mm;
                  max-hight:210mm;
                  min-height: 210mm;
                  padding: 10mm 5mm 5mm 5mm ;
                  border: 1px #000 solid;
                  border-radius: 2px;
                  background: white;
                  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                  position: relative;
                }
                .pageNumber{
                  position: fixed;
                  bottom: 0;
                }
              
                @page {
                  size: a4 landscape;
                  margin: 0;
                }
                @media print {
                  html, body {
                    width: 297mm;
                    height: 210mm;
                  }

                  @page: last{
                      .pageNumber {
                          display: block;
                      }
                  }
                  .bg-gray{
                    background-color: #D3D3D3;
                  }
                  .page {
                    margin: 0;
                    border: initial;
                    border-radius: initial;
                    width: initial;
                    min-height: initial;
                    box-shadow: initial;
                    background: initial;
                    page-break-after: always;
                    position: relative;
                  }
                  .pageNumber{
                      position: fixed;
                      bottom: 0;
                  }
                }
          `
          }]}>
          <meta charSet="utf-8" />
          <title>Interest Print</title>
          <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PrintA4horizontal;
