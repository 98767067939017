

import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Loading from '../libs/loading';
import API from '../api/silverGraund2'
import './vms.scss'
import i18next from 'i18next';

class VMSView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            carData : [],
            loading: false,
            lang:false,
        }

    
    }

    componentDidMount(){
        this.getCarById()
    }

    getCarById(){
        this.setState({
            loading : true
        })
        let idCode = this.props.match.params.id
        API.getCarDetail(idCode).then((res) => {
           if(res.data.data){
                this.setState({
                    carData : res.data.data[0],
                    loading : false
                })
           }
        }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    render() {
        return (
            <>
                <div id="wrapper-content">
                    {
                    !this.state.loading ? 
                        <div id='vms-wrapper'>
                        <div style={{ position: 'relative' }} >
                            <img src={process.env.PUBLIC_URL + '/images/setting-walkin/walkin.png'} style={{width:'100%'}}/> 
                            <div className="centered">
                                <div className='vms-logo-container' >
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/images/logo/SVM-full-dark.png'} alt="svm-agent-logo"/>
                                    </div>
                                </div>
                                <div>
                                    <span onClick={ () => this.setState({lang : !this.state.lang})} className='btn-lang'>
                                        <img src={this.state.lang ? (process.env.PUBLIC_URL + '/images/flags/en.png') : (process.env.PUBLIC_URL + '/images/flags/th.png')} 
                                        alt="flag-icon" className='flag'/>
                                        <h6><small>{this.state.lang ? 'EN':'ไทย'}</small></h6>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                             
                        <div className='vms-create-wrapper p-3'>
                        <form onSubmit={this.onSubmit}>
                            <div className='vms-create '>
                                <h4> 1. {this.state.lang ?  'Visitor Information' : 'ข้อมูลของผู้เข้าโครงการ'} </h4>
                                <p className='pb-4'>{this.state.lang ? `Please take a photo of ID card or driver's license before leaving the project.` :'กรุณาถ่ายรูปบัตรประชาชนหรือใบขับขี่ก่อนออกจากโครงการ'}</p>
                                {
                                    this.state.carData.idCardImage && 
                                    <div className='camera-box'>
                                        <img src={this.state.carData.idCardImage} style={{ width: '100%', height: '246px',filter:'blur(10px)'}} />
                                    </div>
                                }
                            </div>
                            <div className='vms-create pt-5'>
                                <h4>2. {this.state.lang ? 'Vehicle information' :'ข้อมูลรถ'}</h4>
                                <p className='pb-4'>{this.state.lang ? `Please take a photo of the vehicle or enter the vehicle information before leaving the project.` :'กรุณาถ่ายรูปรถหรือกรอกข้อมูลรถก่อนออกจากโครงการ'}</p>
                                {
                                    this.state.carData.img && 
                                    <div className='camera-box'>
                                        <img src={this.state.carData.img} style={{ width: '100%', height: '246px'}} />
                                    </div>
                                }
                            </div>
                            <div className='vms-create pt-5'>
                                <p className='fw-bolder pt-2'>{this.state.lang ? `Vehicle registration` :'ทะเบียนรถ'}</p>
                                    <input type='text' className='form-control' name='carRegistration' value={this.state.carData.carRegistration && this.state.carData.carRegistration} disabled/>
                                <p className='fw-bolder pt-2'>{this.state.lang ? `Province` :'จังหวัด'}</p>
                                <div className="input-group">
                                  <input type='text' className='form-control' name='carRegistration' value={this.state.carData.province ? this.state.carData.province : '-'} disabled/>
                                </div>
                            </div>
                            <hr />
                            <div className='vms-create'>
                                <p className='fw-bolder pt-2'> {this.state.lang ? 'Visitor type' :'ประเภทผู้ติดต่อ'}</p>
                                <div className="form-check m-2">
                                     <input className="form-check-input checkType" type="radio"
                                        name="check_type" checked={this.state.carData.typePerson === 'tenant' ? true : false}
                                        value="tenant"  disabled
                                    />
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                         {this.state.lang ? 'Contact juristic' :'ติดต่อนิติ/สำนักงานขาย'}
                                    </label>
                                </div>
                                <div className="form-check m-2">
                                    <input className="form-check-input checkType" type="radio"
                                        name="check_type"
                                        value="appointment" checked={this.state.carData.typePerson === 'appointment' ? true : false}
                                        disabled
                                    />
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                        {this.state.lang ? 'Advance appointment' :'ผู้ติดต่อนัดหมายล่วงหน้า (ผู้เยี่ยมเยือน)'}
                                    </label>
                                    {
                                        this.state.carData.typePerson === 'appointment' ?
                                        <div className='vms-create'>
                                         <p className='fw-bolder pt-2'>{this.state.lang ? 'Room number' :'บ้านเลขที่ /ห้องที่ติดต่อ'} </p>
                                            <input type='text' className='form-control' name='homeId' value={this.state.carData.homeId} disabled />
                                         </div> 
                                         : ''
                                    }
                                    
                                </div>
                                <div className="form-check m-2">
                                    <input className="form-check-input checkType" type="radio"
                                        name="check_type"
                                        value="contact" checked={this.state.carData.typePerson === 'contact' ? true : false}
                                        disabled
                                    />
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                         {this.state.lang ? 'Person' :'บุคคลทั่วไป'} 
                                    </label>
                                </div>
                            </div>                            
                        </form>
                        </div>
                    </div>
                     :  <Loading/>
                    }
                </div>
            </>

        );
    }
}

export default VMSView;
