import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import ExportProduct from "./server_export_product";
import _ from "lodash";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import { Translation } from "react-i18next";
import i18next from "i18next";

const productTransactionList = graphql`
  query productTransactionListQuery(
    $id: ID!
    $start_date: DateTime
    $end_date: DateTime
  ) {
    allProductTransaction(
      product_Id: $id
      startDate: $start_date
      endDate: $end_date
    ) {
      edges {
        node {
          id
          list
          ref
          amount
          price
          totalValue
          added
          date
          balance
          valueReal
          totalValueReal

          product {
            cost
            # stock {
            #   edges {
            #     node {
            #       cost
            #     }
            #   }
            # }
          }
          
        }
      }
    }
  }
`;

class ProductTransactionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      start_date: new Date(),
      end_date: new Date(),
      temp_start_date: new Date(),
      temp_end_date: new Date(),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getStatus = (typeList) => {
    let textResult = ''

    switch (typeList) {
      case "SELL":
        textResult = i18next.t("stockAndReport:Sell")
        break;
      case "BUY":
        textResult = i18next.t("stockAndReport:Buy")
        break;
      case "CONVERSION_BALANCE":
        textResult = i18next.t("keyofProduct:Total brought forward")
        break;
      case "UNIT":
        textResult = i18next.t("stockAndReport:Rights")
        break;
      case "PROJECT":
        textResult = i18next.t("stockAndReport:Requisition")
        break;
    }

    return <td>{textResult}</td>
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSearch() {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="py-0 d-flex">
          {_.some(JSON.parse(localStorage.getItem("permission_list")), {
            codename: "accounting_product_print",
          }) && <ExportProduct state={this.state} />}
          <div className="mt-3 ml-auto mr-0">
            <InlineAdvanceFilter
              start_date={this.state.temp_start_date}
              end_date={this.state.temp_end_date}
              handleChange={this.handleChange}
            />
          </div>
          <div className="col-2 mr-0 ml-2 mt-3">
            <Translation>
              {t =>
                <button
                  type="submit"
                  className="btn btn-primary form-control search-button"
                  name="search"
                  value={this.state.search_input}
                  onClick={(e) => this.handleSearch(e)}
                >
                  {t("inventory_list:Search")}
                </button>}
            </Translation>
          </div>
        </div>
        <div
          className="tab-pane fade show active table-responsive card mt-3"
          id="all"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <table className="table table-hover table-striped mt-2">
            <thead>
              <tr className="text-center">
                <Translation>{t => <th className="text-left">{t("inventory_list:Date")}</th>}</Translation>
                <Translation>{t => <th className="text-left">{t("inventory_list:List")}</th>}</Translation>
                <Translation>{t => <th className="text-left">{t("inventory_list:Document")}</th>}</Translation>
                <Translation>{t => <th className="text-right">{t("inventory_list:Amount_")}</th>}</Translation>
                <Translation>{t => <th className="text-right">{t("inventory_list:Total value")}</th>}</Translation>
                <Translation>{t => <th className="text-right">{t("inventory_list:Unit price")}</th>}</Translation>
                <Translation>{t => <th className="text-right">{t("inventory_list:Balances")}</th>}</Translation>
                <Translation>{t => <th className="text-right">{t("inventory_list:Total reidual vlalue")}</th>}</Translation>
              </tr>
            </thead>
            <tbody>
              <QueryRenderer
                environment={environment}
                query={productTransactionList}
                variables={{
                  id: this.state.id,
                  start_date: this.state.start_date,
                  end_date: this.state.end_date,
                }}
                render={({ error, props }) => {
                  if (error) {
                    return (
                      <tr>
                        <td>Error :(</td>
                      </tr>
                    );
                  } else if (props) {
                    // let totalAmount = 0;
                    // let totalValue = 0;
                    // let selectStock = 0;
                    // let cost = 0;
                    // let stockAmountList = [];
                    return _.sortBy(props.allProductTransaction?.edges, "node.date" ? "node.date" : "node.added").map((transaction) => {

                      // const amount = transaction.node.amount;
                      // if (transaction.node.list === "SELL") {
                      //   let balance = amount;
                      //   while (selectStock < stockAmountList.length) {
                      //     cost = transaction.node.product.stock.edges[selectStock]?.node?.cost;
                      //     if (stockAmountList[selectStock] - balance >= 0) {
                      //       stockAmountList[selectStock] -= balance;
                      //       totalValue -= cost * balance;
                      //       break;
                      //     }
                      //     balance -= stockAmountList[selectStock];
                      //     totalValue -= cost * stockAmountList[selectStock];
                      //     stockAmountList[selectStock] = 0;
                      //     selectStock += 1;
                      //   }
                      //   totalAmount -= amount;
                      // }else if(transaction.node.list === "UNIT" || transaction.node.list === "PROJECT"){
                      //   cost = transaction.node.price;
                      //   totalValue += cost * amount;
                      //   totalAmount -= amount;
                      //   stockAmountList.push(amount);
                      // } else {
                      //   cost = transaction.node.price;
                      //   totalValue += cost * amount;
                      //   totalAmount += amount;
                      //   stockAmountList.push(amount);
                      // }

                      return (
                        <tr className="text-left" key={transaction.node.id}>
                          <td>
                            {transaction.node.date
                              ? format(transaction.node.date, "DD/MM/YYYY")
                              : format(transaction.node.added, "DD/MM/YYYY")}
                          </td>

                          {this.getStatus(transaction.node.list)}
                          <td>{transaction.node.ref}</td>
                          <td className="text-right">
                            {((transaction.node.list === "SELL" || transaction.node.list === "UNIT" || transaction.node.list === "PROJECT") ? "-" : "") +
                              numberWithComma(transaction.node.amount)}
                          </td>

                          <td className="text-right">
                            {(transaction.node.list === "UNIT" || transaction.node.list === "PROJECT") ?
                              numberWithComma(transaction.node.product.cost)
                              :
                              numberWithComma(transaction.node.price)
                            }
                            {/* {numberWithComma(cost)} */}
                          </td>

                          <td className="text-right">
                            {numberWithComma(transaction.node.valueReal)}
                            {/* {numberWithComma(cost)} */}
                          </td>
                          <td className="text-right">
                            {numberWithComma(transaction.node.balance)}
                          </td>
                          <td className="text-right">
                            {numberWithComma(transaction.node.totalValueReal)}
                            {/* {numberWithComma(totalValue)} */}
                          </td>
                        </tr>
                      );
                    });
                  }
                  return (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  );
                }}
              />
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductTransactionList;
