import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import CashListAll from './cashListAll';
import CashDepositCreate from './cashDepositCreate';
import CashDetail from './cashDetail';


class Cash extends Component {

    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={CashListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/list`} component={CashListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/create/:status`} component={CashDepositCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={CashDetail}/>
            </React.Fragment>
        );
    }
}

export default Cash;