import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import GeneralLedger from './generalLedgerList';
import GeneralLedgerPDF from './generalLedgerPDF';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";

class Account extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/PDF`} component={GeneralLedgerPDF}/>
                <PrivateRoute exact path={`${this.props.match.url}/:code?/:start_date?/:end_date?`} component={GeneralLedger}/>
                {/* <PrivateRoute exact path={`${this.props.match.url}/PDF`} component={GeneralLedgerPDF}/> */}
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Account;
