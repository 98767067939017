/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type acceptCreditNoteContactCreateFormQueryVariables = {|
  id: string,
  transaction_id: string,
|};
export type acceptCreditNoteContactCreateFormQueryResponse = {|
  +contact: ?{|
    +id: string,
    +refNumber: string,
    +name: string,
    +firstName: string,
    +lastName: string,
    +typeOfSupplier: ContactTypeOfSupplier,
    +vat: ?number,
    +registeredAddress: string,
    +registeredCountry: string,
    +registeredProvince: string,
    +registeredDistrict: string,
    +registeredCity: string,
    +registeredPostalCode: string,
    +typeOfContact: ContactTypeOfContact,
  |},
  +purchaseRecord: ?{|
    +id: string,
    +vat: ?number,
    +total: number,
    +price: number,
    +paid: number,
    +description: string,
    +purchaseRecordGroup: {|
      +docNumber: string
    |},
    +productAndService: ?{|
      +productCode: string
    |},
    +chartOfAccount: {|
      +chartOfAccountCode: string,
      +name: string,
    |},
  |},
|};
export type acceptCreditNoteContactCreateFormQuery = {|
  variables: acceptCreditNoteContactCreateFormQueryVariables,
  response: acceptCreditNoteContactCreateFormQueryResponse,
|};
*/


/*
query acceptCreditNoteContactCreateFormQuery(
  $id: ID!
  $transaction_id: ID!
) {
  contact(id: $id) {
    id
    refNumber
    name
    firstName
    lastName
    typeOfSupplier
    vat
    registeredAddress
    registeredCountry
    registeredProvince
    registeredDistrict
    registeredCity
    registeredPostalCode
    typeOfContact
  }
  purchaseRecord(id: $transaction_id) {
    id
    vat
    total
    price
    paid
    description
    purchaseRecordGroup {
      docNumber
      id
    }
    productAndService {
      productCode
      id
    }
    chartOfAccount {
      chartOfAccountCode
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transaction_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfSupplier",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredPostalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "transaction_id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "acceptCreditNoteContactCreateFormQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PurchaseRecordNode",
        "kind": "LinkedField",
        "name": "purchaseRecord",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordGroupNode",
            "kind": "LinkedField",
            "name": "purchaseRecordGroup",
            "plural": false,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductAndServiceNode",
            "kind": "LinkedField",
            "name": "productAndService",
            "plural": false,
            "selections": [
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "chartOfAccount",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "acceptCreditNoteContactCreateFormQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PurchaseRecordNode",
        "kind": "LinkedField",
        "name": "purchaseRecord",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordGroupNode",
            "kind": "LinkedField",
            "name": "purchaseRecordGroup",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductAndServiceNode",
            "kind": "LinkedField",
            "name": "productAndService",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "chartOfAccount",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v2/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eefe0ec841a90ec8a9a3b0d961a35086",
    "id": null,
    "metadata": {},
    "name": "acceptCreditNoteContactCreateFormQuery",
    "operationKind": "query",
    "text": "query acceptCreditNoteContactCreateFormQuery(\n  $id: ID!\n  $transaction_id: ID!\n) {\n  contact(id: $id) {\n    id\n    refNumber\n    name\n    firstName\n    lastName\n    typeOfSupplier\n    vat\n    registeredAddress\n    registeredCountry\n    registeredProvince\n    registeredDistrict\n    registeredCity\n    registeredPostalCode\n    typeOfContact\n  }\n  purchaseRecord(id: $transaction_id) {\n    id\n    vat\n    total\n    price\n    paid\n    description\n    purchaseRecordGroup {\n      docNumber\n      id\n    }\n    productAndService {\n      productCode\n      id\n    }\n    chartOfAccount {\n      chartOfAccountCode\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5bb41ad1278cb03f507542fd63b95491';

module.exports = node;
