/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type contactTopMenuNavigationQueryVariables = {|
  repairType?: ?string
|};
export type contactTopMenuNavigationQueryResponse = {|
  +getNotification: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>,
  +sumRepairsJuristic: ?number,
|};
export type contactTopMenuNavigationQuery = {|
  variables: contactTopMenuNavigationQueryVariables,
  response: contactTopMenuNavigationQueryResponse,
|};
*/


/*
query contactTopMenuNavigationQuery(
  $repairType: String
) {
  getNotification {
    type
    count
  }
  sumRepairsJuristic(repairType: $repairType)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "repairType"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationType",
    "kind": "LinkedField",
    "name": "getNotification",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "repairType",
        "variableName": "repairType"
      }
    ],
    "kind": "ScalarField",
    "name": "sumRepairsJuristic",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contactTopMenuNavigationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contactTopMenuNavigationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6787d5ad427e0d2230520fd1a9bcc4c3",
    "id": null,
    "metadata": {},
    "name": "contactTopMenuNavigationQuery",
    "operationKind": "query",
    "text": "query contactTopMenuNavigationQuery(\n  $repairType: String\n) {\n  getNotification {\n    type\n    count\n  }\n  sumRepairsJuristic(repairType: $repairType)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '035909b67855219cdb561cc084dfe548';

module.exports = node;
