import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import '../debtFreeRequest.scss'
import { format } from 'date-fns';
import numberWithCommas from '../../../libs/numberWithComma';
import SearchSelect from '../../../libs/searchSelect'
import i18next from 'i18next';
const ThaiBaht = require('thai-baht-text');

class ContactInput extends Component{


    render(){
        return(
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        <div className="row mt-4  margin-top pl-3"> 
                            <div className="col-12">
                                <h4>{i18next.t("debt_free_request:Details of the Requester for Debt-Free Certification")}</h4>
                            </div>
                            <hr/>
                        </div>
                        <div className="row col-12 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Number")}</h6></div>
                            <div className="col-10">
                                <SearchSelect 
                                    onChange={(e) => this.props.handleChange(e)}
                                    value={this.props.state.contactId}
                                    name="contactId"
                                    placeholder="ค้นหาโดยระบุเลขห้อง"
                                    queryObject={this.props.state.contactList}
                                    keyOfValue="id" require={true}
                                    keyOfLabel="name"
                                    disabled={this.props.status !== 'create' }
                                />
                            </div>
                        </div>

                        <div className="row col-8 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Owner's Name")}</h6></div>
                            <div className="col-12">
                                {/* <SearchSelect 
                                    onChange={(e) => this.props.handleChange(e)}
                                    value={this.props.state.contactNameId}
                                    name="contactNameId"
                                    placeholder="ค้นหาชื่อเจ้าของกรรมสิทธิ์"
                                    queryObject={this.props.state.ownerList}
                                    keyOfValue="id" require={true}
                                    keyOfLabel="fullName"
                                    disabled={this.props.status !== 'create'  }
                                    get_only_name={true}
                                /> */}
                                <input type="text" disabled={true} 
                                    value={this.props.state.nameContact || ''} className="form-control form-control-border"
                                />
                            </div>
                        </div>


                        <div className="row col-8 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Address")}</h6></div>
                            <div className="col-12">
                                <textarea type="text" disabled={true} 
                                    value={this.props.state.addressContactField || ''} className="form-control  height-area form-control-border"
                                />
                            </div>
                        </div>
                        <div className="row col-8 mt-4">
                            <div className="col-6">
                                <h6>{i18next.t("debt_free_request:Area Size (sq.m.)")}</h6>
                            </div>
                            <div className="col-6">
                                <h6>{i18next.t("debt_free_request:Ownership Ratio")}</h6>
                            </div>
                            <div className="col-6">
                                <input type="text" disabled={true} 
                                    value={this.props.state.sizeContact || ''} className="form-control form-control-border"
                                />
                            </div>
                            <div className="col-6">
                                <input type="text" disabled={true} 
                                    value={this.props.state.ownershipRatio || ''} className="form-control form-control-border"
                                />
                            </div>
                        </div>
                        <div className="row col-8 mt-4">
                            <div className="col-6">
                                <h6>{i18next.t("debt_free_request:Current Outstanding Balance as of Date")}</h6>
                            </div>
                            <div className="col-6">
                                <h6>{i18next.t("debt_free_request:Outstanding Balance (Baht)")}</h6>
                            </div>
                            <div className="col-6">
                                <input type="text" disabled={true} 
                                    value={this.props.state.statusDoc === 'APPROVE' ? 
                                        format(new Date(this.props.state.approvalDate), 'DD/MM/YYYY') 
                                        : 
                                        this.props.state.statusDoc === 'VOID' ?  
                                            format(new Date(this.props.state.cancellationDate), 'DD/MM/YYYY') 
                                            : 
                                            format(new Date(), 'DD/MM/YYYY') } 
                                    className="form-control form-control-border"
                                />
                            </div>
                            <div className="col-6">
                                <input type="text" disabled={true} 
                                    value={numberWithCommas(this.props.state.price,0)} className="form-control form-control-border"
                                />
                            </div>
                        </div>
                        <div className="row col-8 mt-4">
                            <div className="col-12"><h6>{i18next.t("debt_free_request:Outstanding Balance (in Words)")}</h6></div>
                            <div className="col-12">
                                <input type="text" disabled={true} 
                                    value={this.props.state.price === 0 ? i18next.t("debt_free_request:Zero Baht") : ThaiBaht(this.props.state.price)}className="form-control form-control-border"
                                />
                            </div>
                        </div>
                    </React.Fragment>

                }

            </Translation>

        );
    }

}
export default ContactInput;