import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import "./style/manageRequest.scss"
import { Translation } from 'react-i18next';

const query = graphql`
    query manageRequestTopMenuNavigationQuery{
        getNotificationProcurementForm {
            type
            count
        }
    }
`;

class manageRequestTopMenuNavigation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeProcurementForm: window.localStorage.getItem("active_procurement_form") || "0"
        }
    }

    componentDidMount() {
        fetchQuery(environment, query).then((response) => {
            if (response.getNotificationProcurementForm[0].type === "active_procurement_form") {
                window.localStorage.setItem(`${response.getNotificationProcurementForm[0].type}`, response.getNotificationProcurementForm[0].count)
                this.setState({ activeProcurementForm: `${response.getNotificationProcurementForm[0].count}` })
            }
        })
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="manageRequestTopMenuNavigation">
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink to="/purchasing/manageRequest/list/all" >
                                        {t("PageListPRPO:All")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/purchasing/manageRequest/list/draft" >
                                        {t("PageListPRPO:Draft")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/purchasing/manageRequest/list/active" >
                                        {t("PageListPRPO:Present")}
                                        {this.state.activeProcurementForm !== "0" &&
                                            <span
                                                className="badge badge-pill badge-danger"
                                                style={{ width: 24, marginLeft: 4 }}
                                            >
                                                {this.state.activeProcurementForm}
                                            </span>
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/purchasing/manageRequest/list/approve" >
                                        {t("PageListPRPO:Approve")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/purchasing/manageRequest/list/edit" >
                                        {t("PageListPRPO:Wait Edit")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/purchasing/manageRequest/list/void" >
                                        {t("PageListPRPO:Cancled")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                }
            </Translation>
        )
    }
}
export default manageRequestTopMenuNavigation;
