import React, { Component } from 'react'
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import _ from "lodash"
import environment from "../../../../env/environment";
import { fetchQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";

import getNameResidential from "../../../../libs/getNameResidential";
import getNameResidentialRent from "../../../../libs/getNameResidentialRent";

import Swal from 'sweetalert2';
import i18next from 'i18next';
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const allInvoice = graphql`
    query invoiceExportsDraftExcelQuery($status: String, $search: String, $start_date : DateTime, $end_date : DateTime, $first: Int, $last: Int, $is_contact_online: Boolean){
        
        invoiceViewer{
            allInvoice(status:$status, search:$search, startDate:$start_date, endDate:$end_date, first: $first, last: $last, isContactOnline: $is_contact_online){
                totalCount
                edges{
                    node{
                        id
                        docNumber
                        name
                        firstName
                        lastName
                        payGroup
                        historyShow
                        remark
                        contact{
                            id
                            refNumber
                            name
                            firstName
                            lastName
                            typeOfContact
                            residential{
                                residentialHouseholder(type:"renter"){
                                    edges{
                                        node{
                                            id
                                            tenant{
                                                nameTitle
                                                firstName
                                                lastName
                                                phone
                                                email
                                            }
                                        }
                                    }
                                }
                            }
                            
                        }
                        total
                        issuedDate
                        dueDate
                        scheduleTime
                        status
                        approver
                        creator
                        updater
                        added
                        updated
                        voidRemark
                        transaction {
                        edges {
                          node {
                              id
                              productAndService {
                                  id
                                  productCode
                              }
                              receiptDepositTransactionUsed{
                                        edges{
                                            node{
                                                id
                                                coupleUse
                                                amount
                                      }
                                  }
                                }
                              description
                              total
                              paid 
                            }
                          }
                        }
                    }
                }
            }
        }
    }
`;


export default class InvoiceExportsDraftExcel extends Component {

  getRowColCode = (indexRow, indexCol) => {
    let value = ""
    switch (indexCol + 1) {
      case 1:
        value = "A"
        break;
      case 2:
        value = "B"
        break;
      case 3:
        value = "C"
        break;
      case 4:
        value = "D"
        break;
      case 5:
        value = "E"
        break;
      case 6:
        value = "F"
        break;
      case 7:
        value = "G"
        break;
      case 8:
        value = "H"
        break;
      case 9:
        value = "I"
        break;
      case 10:
        value = "J"
        break;
      case 11:
        value = "K"
        break;
      case 12:
        value = "L"
        break;
      case 13:
        value = "M"
        break;
      case 14:
        value = "N"
        break;
      case 15:
        value = "O"
        break;
      case 16:
        value = "P"
        break;
      case 17:
        value = "Q"
        break;
      case 18:
        value = "R"
        break;
      case 19:
        value = "S"
        break;
    }

    return `${value}${indexRow}`
  }

  getData = (datalist) => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');

    // setWidthcolumns
    let columns = [
      { width: 10 },
      { width: 12 },
      { width: 12 },
      { width: 24 },
      { width: 20 },
      { width: 34 },
      { width: 10 },
      { width: 34 },
      { width: 20 },
      { width: 20 }, 
      { width: 20 }, 
      { width: 20 },
      { width: 20 },
      { width: 15 }, 
      { width: 15 }, 
      { width: 26 },
      { width: 10 }
    ]

    // setBorder
    // let borders = {
    //   top: { style: 'thin' },
    //   left: { style: 'thin' },
    //   bottom: { style: 'thin' },
    //   right: { style: 'thin' }
    // }

    // let fontSizes = { size: 12 }
    // let textCenter = { vertical: 'middle', horizontal: 'center' };
    let fontBold = { size: 12, bold: true };

    worksheet.getCell('A1').value = this.props.state.projectName
    worksheet.getCell('A1').font = { size: 16, bold: true };
    worksheet.getCell('A2').value = "รายงานแบบร่างใบแจ้งหนี้"
    worksheet.getCell('A2').font = { size: 16, bold: true };

    worksheet.getCell('A3').value =
      `เรียงตามวันที่ ${this.props.state.start_date ? format(this.props.state.start_date, "DD/MM/YYYY") : "-"} ถึง ${this.props.state.end_date ? format(this.props.state.end_date, "DD/MM/YYYY") : "-"} `


    worksheet.getCell('A3').font = fontBold;

    worksheet.getCell('A1').height = 20;
    worksheet.getCell('A2').height = 20;
    worksheet.getCell('A3').height = 20;

    let head_table2_column1 = [
      'ลำดับ',
      'วันที่ออก',
      'วันที่ครบกำหนด',
      'วันที่ตั้งเวลาโพสต์',
      'เลขที่ห้อง/บ้านเลขที่',
      'ชื่อ',
      'รหัส',
      'รายละเอียด',
      'จำนวนเงิน',
      'หักรับล่วงหน้า',
      'จำนวนเงินหลังหักรับล่วงหน้า',
      'จำนวนเงินรวมทั้งสิ้น',
      'ออกโดย',
      'วันที่สร้าง',
      'ใช้รับล่วงหน้า',
      'แสดงรายการค้างชำระ',
      'หมายเหตุ'
    ]

    let head_table2 = [
      head_table2_column1
    ]

    _.map(head_table2, (value, index) => {
      worksheet.getRow(5 + index).values = value
      worksheet.getRow(5 + index).font = { bold: true };
      worksheet.getRow(5 + index).alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getRow(5 + index).height = 20;

    })

    let itemData = []
    let SumTotal = 0
    let count = 0
    _.map(datalist, (dataTable, index_data) => {
      // let name = dataTable.node.firstName.split(",")
      // let lastname = dataTable.node.lastName.split(",")
      let dataTabletotal = dataTable.node.total
      let accumTotalTransaction = 0;

      let transaction = dataTable.node.transaction?.edges.map((item, transaction_index) => {
        let productCode = item.node.productAndService.productCode
        let description = item.node.description
        let receiptDepositAmount = 0;

        let receiptDepositTransactionUsed = item.node.receiptDepositTransactionUsed?.edges
        receiptDepositTransactionUsed.map((receiptDeposit)=>(
          receiptDepositAmount += receiptDeposit.node.amount
        ))

        // let paid = item.node.paid
        let total = item.node.total

        let netTransaction = total - receiptDepositAmount;
        accumTotalTransaction += netTransaction;
        SumTotal = SumTotal + netTransaction;

        dataTabletotal = parseFloat(parseFloat(dataTabletotal) - parseFloat(total))
        let payable = dataTabletotal > 0 ? '⎷' : '-'
        count = index_data + 1


        let firstName_lastName = dataTable.node.contact.typeOfContact === "RESIDENTIAL"
            ? dataTable.node.payGroup === "RENT"
            ? getNameResidentialRent(dataTable.node.contact.residential.residentialHouseholder?.edges)
            : getNameResidential(dataTable.node.firstName, dataTable.node.lastName)
            : dataTable.node.name
        firstName_lastName += dataTable.node.payGroup === "RENT" ? `(${i18next.t("AgentRole:Renter")})` : " "

        return (
          itemData.push([
            transaction_index === 0 ? parseInt(count) : "",
            format(new Date(dataTable.node.issuedDate), "DD/MM/YYYY"),
            format(new Date(dataTable.node.dueDate), "DD/MM/YYYY"),
            `${dataTable.node.scheduleTime ? format(new Date(dataTable.node.scheduleTime), "DD/MM/YYYY - HH.mmน.") : '-'}`,
            dataTable.node.name,
            firstName_lastName,
            productCode,
            description,
            parseFloat(total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            receiptDepositAmount === 0 ? "-" : parseFloat(receiptDepositAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            netTransaction === 0 ? "-" : parseFloat(netTransaction).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            dataTable.node.transaction.edges[transaction_index+1]? "" : (accumTotalTransaction === 0 ? "-" : parseFloat(accumTotalTransaction).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')),
            dataTable.node.creator,
            format(new Date(dataTable.node.added), "DD/MM/YYYY"),
            receiptDepositAmount? '/' : '-',
            dataTable.node.historyShow ? '/' : '-',
            dataTable.node.remark ? '/' : '-'
          ])
        )
      })
    })

    itemData.push([
      "", "", "", "", "",
      "", "", "", "", "", 
      'รวมทั้งหมด', SumTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),"", "", ""
    ])

    // CSS Format Table
    _.map(itemData, (value, index) => {
      worksheet.getRow(6 + index).values = value
      worksheet.getRow(6 + index).height = 20;

      worksheet.getRow(6 + index).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getRow(6 + index).getCell(2).alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getRow(6 + index).getCell(3).alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getRow(6 + index).getCell(4).alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getRow(6 + index).getCell(5).alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getRow(6 + index).getCell(6).alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getRow(6 + index).getCell(7).alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getRow(6 + index).getCell(8).alignment = { vertical: 'middle', horizontal: 'left' }
      worksheet.getRow(6 + index).getCell(9).alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getRow(6 + index).getCell(11).alignment = { vertical: 'middle', horizontal: 'right' } 
      worksheet.getRow(6 + index).getCell(10).alignment = { vertical: 'middle', horizontal: 'right' } 
      worksheet.getRow(6 + index).getCell(12).alignment = { vertical: 'middle', horizontal: 'right' }
      worksheet.getRow(6 + index).getCell(13).alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getRow(6 + index).getCell(14).alignment = { vertical: 'middle', horizontal: 'center' } 
      worksheet.getRow(6 + index).getCell(15).alignment = { vertical: 'middle', horizontal: 'center' } 
      worksheet.getRow(6 + index).getCell(16).alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getRow(6 + index).getCell(17).alignment = { vertical: 'middle', horizontal: 'center' }

      if (itemData.length === (index + 1)) {
        worksheet.getRow(6 + index).getCell(11).alignment = { vertical: 'middle', horizontal: 'right' }
        worksheet.getRow(6 + index).getCell(12).alignment = { vertical: 'middle', horizontal: 'right' }
      }
    })

    worksheet.columns = columns

    // Create Excel File
    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = !this.props.state.start_date 
        ? "draft_" + format(new Date(), 'DD/MM/YYYY', { locale: thLocale })
        : format(this.props.state.start_date, 'DD/MM/YYYY') === format(this.props.state.end_date, 'DD/MM/YYYY')
        ? "draft_" + format(this.props.state.start_date, 'DD/MM/YYYY', { locale: thLocale })
        : "draft_" + format(this.props.state.start_date, 'DD/MM/YYYY', { locale: thLocale }) + "-" + format(this.props.state.end_date, 'DD/MM/YYYY', { locale: thLocale })

        // let nameFile = format(this.props.state.start_date, 'DD/MM/YYYY') === format(this.props.state.end_date, 'DD/MM/YYYY')
        //   ? "report_" + format(this.props.state.start_date, 'DD/MM/YYYY', { locale: thLocale })
        //   : "report_" + format(this.props.state.start_date, 'DD/MM/YYYY', { locale: thLocale }) + "-" + format(this.props.state.end_date, 'DD/MM/YYYY', { locale: thLocale });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = nameFile;
        a.click();
      });

      Swal.close()
  }

  downloadExcel = () => {
    let datalist = this.props.listAllExportToExcel

    if (!this.props.listAllExportToExcel) {
      fetchQuery(environment, allInvoice, {
        search: this.props.state.search,
        start_date: this.props.state.start_date,
        end_date: this.props.state.end_date,
        // first: this.props.state.first,
        // last: this.props.state.last,
        status: "draft",
        re_query: this.props.state.re_query,
        is_contact_online:
          this.props.state.app_status === "all"
            ? null
            : this.props.state.app_status === "active",
      }).then((data) => {
        this.getData(data.invoiceViewer.allInvoice.edges)
      })
      Swal.fire({
        title: "โปรดรอสักครู่กำลังจัดเตรียมข้อมูล",
        text: 'ห้ามทำรายการใดๆ ทั้งสิ้นในหน้านี้',
        type: 'warning',
        timer: 60000,
        showCancelButton: false,
        showConfirmButton: false,
      })
    } else {
      this.getData(datalist)
    }


  }

  render() {
    return (
      // <a className="dropdown-item" target={"_blank"} onClick={this.downloadExcel}>Excel</a>
      <button
        type="button"
        className="btn btn-outline-secondary mr-2"
        onClick={this.downloadExcel}
      >
        <img
          src={
            process.env.PUBLIC_URL +
            "/images/icons/print-icon.png"
          }
          alt="print-icon"
        />
      </button>
    )
  }
}
