import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Translation} from "react-i18next";
import i18next from 'i18next';

class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/dataAnalytic/trackingMenu/tenantapp">
                               <Translation>
                                    {
                                        t => <span>{i18next.t("dataAnalyticTrackingMenu:Silverman App")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dataAnalytic/trackingMenu/juristicapp">
                                <Translation>
                                    {
                                        t => <span>{i18next.t("dataAnalyticTrackingMenu:Silverman for Niti App")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                             <NavLink to="/dataAnalytic/trackingMenu/juristicweb">
                                <Translation>
                                    {
                                        t => <span>{i18next.t("dataAnalyticTrackingMenu:Niti Web")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;