/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateWaterMeterRecordInput = {|
  year?: ?string,
  meterStack?: ?any,
  channel?: ?string,
  clientMutationId?: ?string,
|};
export type waterMeterListRecordMutationVariables = {|
  input: UpdateWaterMeterRecordInput
|};
export type waterMeterListRecordMutationResponse = {|
  +updateWaterMeterRecord: ?{|
    +ok: ?boolean,
    +warningText: ?string,
    +waterMeterList: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +month: number,
          +year: number,
          +meterValue: ?number,
        |}
      |}>
    |},
  |}
|};
export type waterMeterListRecordMutation = {|
  variables: waterMeterListRecordMutationVariables,
  response: waterMeterListRecordMutationResponse,
|};
*/


/*
mutation waterMeterListRecordMutation(
  $input: UpdateWaterMeterRecordInput!
) {
  updateWaterMeterRecord(input: $input) {
    ok
    warningText
    waterMeterList {
      edges {
        node {
          id
          month
          year
          meterValue
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWaterMeterRecordPayload",
    "kind": "LinkedField",
    "name": "updateWaterMeterRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WaterMeterUnitNodeConnection",
        "kind": "LinkedField",
        "name": "waterMeterList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WaterMeterUnitNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WaterMeterUnitNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "month",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "year",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "meterValue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "waterMeterListRecordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "waterMeterListRecordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "295e2f92c4da8b7cde60a962dd0162db",
    "id": null,
    "metadata": {},
    "name": "waterMeterListRecordMutation",
    "operationKind": "mutation",
    "text": "mutation waterMeterListRecordMutation(\n  $input: UpdateWaterMeterRecordInput!\n) {\n  updateWaterMeterRecord(input: $input) {\n    ok\n    warningText\n    waterMeterList {\n      edges {\n        node {\n          id\n          month\n          year\n          meterValue\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7768ac90aa470d7889239944d9e527e2';

module.exports = node;
