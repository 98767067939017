import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import Wrapper from '../../components/wrapper';
import WrapperContent from '../../components/wrapper/wrapperContent';
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import { format } from 'date-fns';
import {Link} from "react-router-dom";
import {fetchQuery} from "relay-runtime";
import {graphql} from "babel-plugin-relay/macro";
import environment from '../../env/environment';


const query = graphql `
query juristicManagerEditQuery($id: String){
    personeManager (id: $id) {
        edges{
            node{
                id
                image
                signature
                prefix
                firstName
                lastName
                address
                department
                position
                affiliation
                phone
                email
                state
                note
                online
                workExperience{
                   edges{
                      node{
                          id
                          company
                          position
                          startDate
                          expDate
                          state
                          
                      } 
                   } 
                }
                education{
                   edges{
                      node{
                          id
                          name
                          degree
                          state
                      } 
                   } 
                }
                term{
                   edges{
                      node{
                          startDate
                          expDate
                      } 
                   } 
                }
                filepdf{
                   edges{
                      node{
                          id
                          name
                          pdf
                          state
                      } 
                   } 
                }
            }
        }
    }

}
`
class JuristicManagerEdit extends Component{
    constructor(props){
        super(props);
        this.state = {
            juristicManager : {},

        }
    }

    componentWillMount(){
        fetchQuery(environment, query, {
            id: this.props.match.params.id,
        }).then( data => {
            if(data.personeManager.edges.length > 0){
                this.setState({
                    juristicManager : data.personeManager.edges[0].node
                })
            }
        })

    }
    render(){
        return(
            <Wrapper>
                <Header />
                <Sidebar/>
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true}/>
                    <Translation>
                        {t => 
                            <div className="container-fluid box" id="projectDashboard">
                                <div className="row">
                                    <div className="col-md-4 col-xl-4">
                                        <h3 className="mb-4">
                                            <Link to="/project/juristic-manager/list/current">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <span>{t('juristicManager:Juristic Manager')}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner personal-info">
                                    <div className="row mb-3" >
                                        <div className="col-7">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="col-12 d-flex justify-content-center">
                                                                <img
                                                                    src={this.state.juristicManager.image ? this.state.juristicManager.image : process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                    alt="project-manager-profile"
                                                                    className="rounded-circle avatar-img profile-img"/>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h5 className="ml-2">{this.state.juristicManager.prefix} {this.state.juristicManager.firstName} {this.state.juristicManager.lastName}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <span className="ml-2">{this.state.juristicManager.address}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-12">
                                                                    {/* status on / off */}
                                                                    <div className={this.state.juristicManager.state === 'current' ? "round-status-on p-1" : "round-status-off p-1"}>
                                                                        <span className="ml-3 mr-3">{this.state.juristicManager.state === 'current' ? t('juristicManager:Current juristic manager') : t('juristicManager:Former juristic manager')}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-12">
                                                                    <div className="col-12">
                                                                        <span className="head">{t('juristicManager:Position')}</span> <span> {this.state.juristicManager.position}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-12">
                                                                    <div className="col-12">
                                                                        <span className="head">{t('projectManager:Work experience')}</span>
                                                                    </div>
                                                                    {this.state.juristicManager.workExperience?.edges.length > 0 ?
                                                                        this.state.juristicManager.workExperience?.edges.map((work) => {
                                                                            return (
                                                                                <div className="mt-1 mb-1 ml-2" key={work.node.id}>
                                                                                    <div className="col-12">
                                                                                        <span>{work.node.company}</span>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <span>{work.node.position}</span>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <span>{format(work.node.startDate,'DD/MM/YYYY')} - {format(work.node.expDate,'DD/MM/YYYY')}</span>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                        : 
                                                                        <div className="col-12">
                                                                            <span>-</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-12">
                                                                    <div className="col-12">
                                                                        <span className="head">{t('projectManager:Education')}</span>
                                                                    </div>
                                                                    {this.state.juristicManager.education?.edges.length > 0 ?
                                                                        this.state.juristicManager.education.edges.map((education) => {
                                                                            return (
                                                                                <div className="mt-1 mb-1 ml-2" key={education.node.id}>
                                                                                    <div className="col-12">
                                                                                        <span>{education.node.name}</span>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <span>{education.node.degree}</span>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                        : 
                                                                        <div className="col-12">
                                                                            <span>-</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-4"><h5>{t('juristicManager:Contact')}</h5></div>
                                                        <div className="col-8"> 
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/phone-icon.png'} alt="phone"/>
                                                            <span className="ml-2">{this.state.juristicManager.phone}</span>
                                                        </div>
                                                        <div className="col-4" />
                                                        <div className="col-8"> 
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/mail-icon.png'} alt="mail"/>
                                                            <span className="ml-2">{this.state.juristicManager.email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            {this.state.juristicManager.term?.edges.length  > 0 && this.state.juristicManager.term.edges.map((term,index) => {
                                                return(
                                                    <div className="card" key={index}>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h5>{t('juristicManager:Term')} {index+1}</h5>
                                                                </div>
                                                                <div className="col-6">
                                                                    <span>{t('juristicManager:Date of position')}</span>
                                                                </div>
                                                                <div className="col-6">{format(term.node.startDate,'DD/MM/YYYY')}</div >
                                                                <div className="col-6">
                                                                    <span>{t('juristicManager:Date of end position')}</span>
                                                                </div>
                                                                <div className="col-6">{term.node.expDate ? format(term.node.expDate,'DD/MM/YYYY') : ''}</div >
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                })
                                            }
                                            
                                            
                                            <div className="card p-1 mt-2">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5>{t('juristicManager:Sign')} </h5>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <img
                                                            src={this.state.juristicManager.signature ? this.state.juristicManager.signature: process.env.PUBLIC_URL + '/images/icons/signature-upload-icon.png'}
                                                            alt="project-manager-signature"
                                                            className="avatar-img profile-img"/>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card p-1 mt-2">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5>{t('juristicManager:Note')} : </h5>
                                                        </div>
                                                        <div className="col-12">
                                                            <span>{this.state.juristicManager.note}</span>
                                                        </div>
                                                        <div className="col-12 mt-4">
                                                            <h5>{t('juristicManager:Attachment')}</h5>
                                                        </div>
                                                        {this.state.juristicManager.filepdf?.edges.length > 0 && 
                                                        this.state.juristicManager.filepdf?.edges.map((file) =>{
                                                            return (
                                                                <React.Fragment key={file.node.id}>
                                                                    <div className="col-8">
                                                                        {file.node.name}
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <a download="name_of_downloaded_file"
                                                                            href={file.node.pdf}>
                                                                            <span className="cursor">ดาวน์โหลด</span>
                                                                        </a>
                                                                        
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        
                                        
                                    </div>

                                </div>
                            </div>
                        
                        } 
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }


}export default JuristicManagerEdit;