
import React, {Component} from 'react';
import {fetchQuery} from "relay-runtime";
import environment from '../../env/environment';
import {graphql} from "babel-plugin-relay/macro";
import thLocale from "date-fns/locale/th";
import { format } from "date-fns";

const query = graphql`
    query settingForeignOwnershipViewQuery($id: ID!) {
        juristic(id: $id) {
            signature
        } 

    }
`;
class SettingForeignOwnershipView extends Component {

    constructor(props){
        super(props);
        this.state = {
            signature : ""
        }
    }

    componentDidMount() {
        this.fetchQuery()
    }

    fetchQuery = () => {
        if(this.props.settingInfo.signatureNameID) {
            fetchQuery(environment, query, {
                id: this.props.settingInfo.signatureNameID,
            }).then((data) => {
                this.setState({ 
                    signature: data.juristic.signature,
                });
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.settingInfo.signatureNameID !== prevProps.settingInfo.signatureNameID) {
            this.fetchQuery();
        }
    }

    persentDate(){
        const currentDate = new Date();
        const buddhistYear = currentDate.getFullYear() + 543;
        const formattedDate = format(currentDate, `วันที่ DD MMM ${buddhistYear}`, { locale: thLocale });
        return formattedDate
    }

    render() {
        return (
            <React.Fragment>
                <div className="print-page-a4" id="settingForeignOwnershipView" >
                    <div className='detailForeignOwnershipContainer'>
                        <p className='companyHeader'>{this.props.selfProject}</p>
                        <p className='date'>{this.persentDate()}</p>
                        <div className='subjectHeader'>
                            <div className="subTitle">
                                <strong>เรื่อง</strong>
                            </div>
                            <div className="descriptionHeader">
                                {this.props.settingInfo.subject}
                            </div>
                        </div>
                        <div className='subjectHeader'>
                            <div className="subTitle">
                                <strong>เรียน</strong>
                            </div>
                            <div className="descriptionHeader">
                                {this.props.settingInfo.dear}
                            </div>
                        </div>
                        <p className='tab-description mt-4'>{this.props.settingInfo.description1}</p>
                        <p className='tab-description mt-4'>{this.props.settingInfo.description2}</p>
                        <table className='tableDetailOwnership'>
                            <thead>
                                <tr>
                                    <th className="text-center">ลำดับ</th>
                                    <th className="text-center">{this.props.typeOfProject === "VILLAGE"? "บ้านเลขที่": "ห้อง"}</th>
                                    <th className="text-center">เจ้าของกรรมสิทธิ์</th>
                                    <th className="text-center">สัญชาติ</th>
                                    <th className="text-center">พื้นที่ (ตร.ม.)</th>
                                    <th className="text-center">%</th>
                                    <th className="text-center">สถานะ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">1</td>
                                    <td className="text-center">100/1</td>
                                    <td className="text-center">Liam</td>
                                    <td className="text-center">USA</td>
                                    <td className="text-center">27.00</td>
                                    <td className="text-center">0.77%</td>
                                    <td className="text-center">อยู่อาศัยเอง</td>
                                </tr>
                                <tr>
                                    <td className="text-center">2</td>
                                    <td className="text-center">100/2</td>
                                    <td className="text-center">John</td>
                                    <td className="text-center">USA</td>
                                    <td className="text-center">27.00</td>
                                    <td className="text-center">0.77%</td>
                                    <td className="text-center">อยู่อาศัยเอง</td>
                                </tr>
                                <tr>
                                    <td className="text-center">3</td>
                                    <td className="text-center">100/3</td>
                                    <td className="text-center">Roger</td>
                                    <td className="text-center">USA</td>
                                    <td className="text-center">27.00</td>
                                    <td className="text-center">0.77%</td>
                                    <td className="text-center">อยู่อาศัยเอง</td>
                                </tr>
                                <tr>
                                    <td className="text-center">4</td>
                                    <td className="text-center">100/4</td>
                                    <td className="text-center">Yu Yin</td>
                                    <td className="text-center">CN</td>
                                    <td className="text-center">27.00</td>
                                    <td className="text-center">0.77%</td>
                                    <td className="text-center">อยู่อาศัยเอง</td>
                                </tr>
                                <tr>
                                    <td className="text-center">5</td>
                                    <td className="text-center">100/5</td>
                                    <td className="text-center">Kurumi</td>
                                    <td className="text-center">JP</td>
                                    <td className="text-center">27.00</td>
                                    <td className="text-center">0.77%</td>
                                    <td className="text-center">อยู่อาศัยเอง</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-center">รวมทั้งสิ้น</td>
                                    <td className="text-center borderedCell">135.00</td>
                                    <td className="text-center borderedCell">3.85%</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                        <p className='remarkOwnership'>หมายเหตุ : {this.props.settingInfo.remark}</p>
                    </div>
                    <div className='signatureOwnershipContainer'>
                            <div className='signatureSigned'>
                                {this.props.settingInfo.juristicSignatureUse ? 
                                <>
                                    {this.state.signature ?
                                    <img src={this.state.signature} />
                                        :
                                    <p>.........................................</p>} 
                                    <p>({this.props.settingInfo.signatureName})</p>
                                </>
                                    :
                                <>    
                                    <p>.........................................</p>
                                    <p>({this.props.settingInfo.signatureName})</p>
                                </>
                                }
                                <p>{this.props.settingInfo.signaturePosition}</p>
                            </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }                   
}

export default SettingForeignOwnershipView;