/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HouseHolderType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type QuestionType = "CHOICE" | "RADIO" | "TEXT" | "%future added value";
export type SurveyStatus = "DELETE" | "DRAFT" | "INACTIVE" | "PUBLISH" | "%future added value";
export type surveyQueryVariables = {|
  id: string,
  residential_vote?: ?string,
|};
export type surveyQueryResponse = {|
  +survey: ?{|
    +id: string,
    +title: string,
    +detail: string,
    +status: SurveyStatus,
    +residentialVoteSurvey: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +residential: {|
            +id: string,
            +name: string,
            +size: number,
            +residentialHouseholder: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +type: HouseHolderType,
                  +id: string,
                  +tenant: {|
                    +firstName: string,
                    +lastName: ?string,
                  |},
                |}
              |}>
            |},
          |}
        |}
      |}>
    |},
    +surveyQuestion: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +title: string,
          +type: QuestionType,
          +questionChoice: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +title: string,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +residentialSizeAll: ?{|
    +sizeAll: ?number
  |},
|};
export type surveyQuery = {|
  variables: surveyQueryVariables,
  response: surveyQueryResponse,
|};
*/


/*
query surveyQuery(
  $id: ID!
  $residential_vote: ID
) {
  survey(id: $id) {
    id
    title
    detail
    status
    residentialVoteSurvey(id: $residential_vote) {
      edges {
        node {
          residential {
            id
            name
            size
            residentialHouseholder {
              edges {
                node {
                  type
                  id
                  tenant {
                    firstName
                    lastName
                    id
                  }
                }
              }
            }
          }
          id
        }
      }
    }
    surveyQuestion {
      edges {
        node {
          id
          title
          type
          questionChoice {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      }
    }
  }
  residentialSizeAll {
    sizeAll
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "residential_vote"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detail",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "residential_vote"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionNodeConnection",
  "kind": "LinkedField",
  "name": "surveyQuestion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ChoiceNodeConnection",
              "kind": "LinkedField",
              "name": "questionChoice",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChoiceNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ChoiceNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveyResidentialSizeAll",
  "kind": "LinkedField",
  "name": "residentialSizeAll",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sizeAll",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "surveyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SurveyNode",
        "kind": "LinkedField",
        "name": "survey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ResidentialVoteNodeConnection",
            "kind": "LinkedField",
            "name": "residentialVoteSurvey",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialVoteNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialVoteNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNodeConnection",
                            "kind": "LinkedField",
                            "name": "residentialHouseholder",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HouseHolderNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "HouseHolderNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TenantNode",
                                        "kind": "LinkedField",
                                        "name": "tenant",
                                        "plural": false,
                                        "selections": [
                                          (v10/*: any*/),
                                          (v11/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      (v13/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "surveyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SurveyNode",
        "kind": "LinkedField",
        "name": "survey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ResidentialVoteNodeConnection",
            "kind": "LinkedField",
            "name": "residentialVoteSurvey",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialVoteNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialVoteNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNodeConnection",
                            "kind": "LinkedField",
                            "name": "residentialHouseholder",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HouseHolderNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "HouseHolderNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TenantNode",
                                        "kind": "LinkedField",
                                        "name": "tenant",
                                        "plural": false,
                                        "selections": [
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      (v13/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a7fe023bd62dc80bd65f6b3399aa52c6",
    "id": null,
    "metadata": {},
    "name": "surveyQuery",
    "operationKind": "query",
    "text": "query surveyQuery(\n  $id: ID!\n  $residential_vote: ID\n) {\n  survey(id: $id) {\n    id\n    title\n    detail\n    status\n    residentialVoteSurvey(id: $residential_vote) {\n      edges {\n        node {\n          residential {\n            id\n            name\n            size\n            residentialHouseholder {\n              edges {\n                node {\n                  type\n                  id\n                  tenant {\n                    firstName\n                    lastName\n                    id\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    surveyQuestion {\n      edges {\n        node {\n          id\n          title\n          type\n          questionChoice {\n            edges {\n              node {\n                id\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  residentialSizeAll {\n    sizeAll\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1922abeb793cccee27ffb454e221117d';

module.exports = node;
