import React from 'react';
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from "./navigation";
import Pagination from "../../libs/newPagination";
import { commitMutation, fetchQuery } from "relay-runtime";

import Search from "../SearchInput";
import ComponentPagination from "../../libs/componentPagination";
import RegisterTopMenuNavigation from "../registerTopMenuNavigation";
import "../styles/register.scss"
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Loading from "../../libs/loading";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import ExportVehicleExcel from './vehicleExcel';
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import i18next from 'i18next';
import _ from 'lodash'
import ExportVehiclePDF from './vehiclePDF';
import jwtDecode from 'jwt-decode'
import Lightbox from 'lightbox-react';
import ImportFile from '../../components/FucntionLib/import';
import Swal from 'sweetalert2';

const residentialVehicle = graphql`
    query vehicleListQuery ($carType: String, $search: String, $first: Int, $last: Int){
        selfProject{
            name
            address
            logo
        }
        residentialVehicle(carType: $carType, search: $search, first: $first, last: $last){
            totalCount
            edges{
                node{
                    id
                    name
                    car(carType: $carType){
                        edges{
                            node{
                                id
                                licencePlate
                                carBrand
                                carModel
                                carDescription
                                carType
                                carSticker
                                chargedFee
                                floor
                                space
                                carImage{
                                    edges{
                                        node{
                                            id
                                            image
                                            description
                                        } 
                                    } 
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation vehicleListMutation($input: CreateAndUpdateCarInput!){
      createAndUpdateCar(input: $input){
        ok
        message
      }
    }
`;

class VehicleList extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state.search = '';
        this.state.dataExport = [];
        this.state.project_name = "";
        this.state.namePrint = "";
        this.state.open_light_box = false;
        this.state.image_light_box = "";
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.toggleLightBox = this.toggleLightBox.bind(this);
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        fetchQuery(environment, residentialVehicle, {
            carType: this.props.match.params.carType,
            search: this.state.search
        }).then(response => {
            if (response?.residentialVehicle) {
                let nameToken = jwtDecode(localStorage.getItem("token"))
                this.setState({
                    dataExport: response?.residentialVehicle?.edges,
                    project_name: response?.selfProject?.name,
                    namePrint: nameToken.first_name + " " + nameToken.last_name,
                    fileImport: ''
                })
            }
        })
    }

    componentDidUpdate() {
        if (this.state.carType !== this.props.carType) {
            this.setState({
                carType: this.props.carType
            }, () => {
                this.goFirst();
            })
        }
    }

    onChangeSearch(text) {
        this.setState({ search: text }, () => {
            this.getData()
        });
    }

    toggleLightBox(open_light_box, image) {
        let data = []
        let images = image?.map((n) => { return data.push(n.node.image) })
        this.setState({ open_light_box: open_light_box, image_light_box: data });
    }

    onSubmit = (file) => {
        let uploadables = { document_upload: file };
        let variables = { input: {} }
        Swal.fire({
            imageUrl: '/images/iconAlerts/loading.gif',
            imageWidth: 150,
            showConfirmButton: false,
            titleText: i18next.t("Allaction:waitingSaving"),
            timer: 9999999999,
            onOpen: () => { Swal.isLoading() },
            allowOutsideClick: () => !Swal.isLoading(),
            onRender: () => {
                try {
                    commitMutation(environment, {
                        mutation,
                        variables,
                        uploadables,
                        onCompleted: (response, errors) => {
                            this.setState({ loading: false, messageImport: response.createAndUpdateCar.message });
                            if (response.createAndUpdateCar.ok) {
                                Swal.stopTimer()
                                Swal.fire(i18next.t("venhicle:Save successfully!"), '', 'success').then((ress) => {
                                    return this.props.history.go(0)
                                });
                            } else {
                                let wordMessage = response.createAndUpdateCar.message?.split(" ")
                                let wordError1 = wordMessage[0] === "ประเภทไม่ถูกต้อง" ?
                                    i18next.t("importPreviewVehicle:Type") + i18next.t("Allaction:Incorrect") :
                                    wordMessage[0] === "จังหวัด" ? i18next.t("importPreviewVehicle:Province") :
                                        wordMessage[0] === "ยี่ห้อ" ? i18next.t("importPreviewVehicle:Brand") :
                                            wordMessage[0] === "รุ่น" ? i18next.t("importPreviewVehicle:Model") :
                                                wordMessage[0] === "วันที่เริ่มใช้งาน" ? i18next.t("importPreviewVehicle:Start Date") : i18next.t("importPreviewVehicle:Room number not found");
                                let wordError2 = wordMessage[1] === "(แถวที่" && i18next.t("importPreviewVehicle:Row(s)")
                                let wordError3 = wordMessage[3] === "หลักที่" && i18next.t("importPreviewVehicle:Col(s)")

                                let caseA = wordError1+" "+wordError2+" "+ wordMessage[2]+" "+ wordError3+" "+wordMessage[4]
                                let caseB = response.createAndUpdateCar.message
                                let result = wordMessage[0] === "ประเภทไม่ถูกต้อง" ? caseA : caseB 
                                Swal.stopTimer()
                                Swal.fire(i18next.t("venhicle:Save unsuccessfully!"),`${result}`, 'warning');
                                return ;
                            }
                        },
                        onError: error => {
                            alert('server error')
                        }
                    });
                } catch (error) {
                    Swal.stopTimer()
                    Swal.fire(`Request failed: ${error}`, '', 'error');
                }
            }

        })
    }
    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <RegisterTopMenuNavigation mini={true} />
                    <div className="container-fluid box colorUse" id="vehicle">
                        <Translation>
                            {t =>
                                <BackButtonIcon
                                    LinkBack={"/register"}
                                    LinkText={t('register:vehicle')}
                                    boxHtmlText={
                                        <React.Fragment>
                                            <div className='w150'>
                                                {
                                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_venhicles_create' }) &&
                                                    <ImportFile
                                                        file={!this.state.fileImport ? '' : this.state.fileImport}
                                                        ImportModalText={"vehicleImport"}
                                                        onSubmit={(uploadables) => this.onSubmit(uploadables)}
                                                        messageImport={this.state.messageImport}
                                                        importType={"excel"}
                                                        preview={true}
                                                        tooltipText={t("importPreviewVehicle:How to Import")}
                                                        dangerText={t("importPreviewVehicle:Please choose file just Excel only, and size not over 10 MB")}
                                                        LinkDocEx={process.env.PUBLIC_URL + "/images/Excel/import_vehicle.xlsx"} // ฟอร์มตัวอย่าง
                                                        LinkDocHowto={"https://docs.google.com/presentation/d/1QxnJ78E23sM2tZ2jGRCUgxM0Vq2UfTSLM3mLmDl1sy8/edit#slide=id.g2bde1d652ba_0_240"} // คู่มือการ Import
                                                    />

                                                }

                                            </div>
                                            &nbsp;&nbsp;

                                            <Dropdown >
                                                <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>{i18next.t("Allaction:Print")}</DropdownToggle>
                                                <DropdownMenu>
                                                    <ExportVehiclePDF
                                                        project_name={this.state.project_name}
                                                        dataExport={this.state.dataExport}
                                                        namePrint={this.state.namePrint}
                                                    />
                                                    <ExportVehicleExcel
                                                        state={this.state}
                                                    />
                                                </DropdownMenu>
                                            </Dropdown>
                                        </React.Fragment>
                                    }
                                />
                            }
                        </Translation>

                        <div className="content-inner">

                            <Navigation />

                            <div className="row justify-content-end mb-2">
                                <div className="col-3">
                                    <Search callback_function={this.onChangeSearch} search={this.state.search} />
                                </div>
                            </div>

                            <QueryRenderer
                                environment={environment}
                                query={residentialVehicle}
                                cacheConfig={{ use_cache: false }}
                                variables={{
                                    search: this.state.search,
                                    first: this.state.first,
                                    last: this.state.last,
                                    carType: this.props.match.params.carType,
                                }}
                                render={({ error, props }) => {
                                    if (error) {
                                        return <div className="alert alert-danger"
                                            role="alert">{error.message}</div>;
                                    } else if (props) {
                                        return <React.Fragment>
                                            <div className="card fade-up">
                                                <div className="table-responsive">
                                                    <table className="table table-hover colorUse">
                                                        <thead className="thead-light">
                                                            <Translation>
                                                                {t =>
                                                                    <tr>
                                                                        <th>{t("venhicle:Room")}</th>
                                                                        <th>{t("venhicle:Registration No.")}</th>
                                                                        <th>{t("venhicle:Type")}</th>
                                                                        <th>{t("venhicle:Brand/Model")}</th>
                                                                        <th>{t("venhicle:Image")}</th>
                                                                        <th>{t("venhicle:Venhicle feature")}</th>
                                                                        <th>{t("venhicle:Sticker number")}</th>
                                                                        <th className="text-center">{t("venhicle:Parking lot sticker")}</th>
                                                                        <th>{t("venhicle:Floor")}</th>
                                                                        <th>{t("venhicle:Space")}</th>
                                                                    </tr>}
                                                            </Translation>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                props.residentialVehicle.edges.map((residential_car) => {
                                                                    return (
                                                                        residential_car.node.car.edges.map((itemCar, CarIndex) => {
                                                                            return (
                                                                                <tr key={`row-${CarIndex}`} className={`${residential_car.node.car.edges.length - 1 === CarIndex ? 'borderLastIndex-2' : ''}`}>
                                                                                    <td>
                                                                                        {
                                                                                            CarIndex === 0 ?
                                                                                                <Link to={{ pathname: "/register/residential/detail/vehicle-detail/" + residential_car.node.id }}>
                                                                                                    {residential_car.node.name}
                                                                                                </Link> :
                                                                                                ""
                                                                                        }

                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="margin-text-table" key={"licencePlate::" + itemCar.node.id}>{itemCar.node.licencePlate}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span key={"carType::" + itemCar.node.id}>
                                                                                            {
                                                                                                itemCar.node.carType === "MOTORCYCLE" ?
                                                                                                    <Translation>{t => <p className="margin-text-table">{t("venhicle:Motorcycle")}</p>}</Translation>
                                                                                                    :
                                                                                                    itemCar.node.carType === "CAR" &&
                                                                                                    <Translation>{t => <p className="margin-text-table">{t("venhicle:Car")}</p>}</Translation>
                                                                                            }
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="margin-text-table" key={"carBrand::" + itemCar.node.id}>{itemCar.node.carBrand} {itemCar.node.carModel}</p>
                                                                                    </td>
                                                                                    <td className='test-center'>
                                                                                        {
                                                                                            itemCar.node.carImage?.edges[0]?.node?.image ?
                                                                                                <img
                                                                                                    src={itemCar.node.carImage?.edges[0]?.node?.image}
                                                                                                    alt="vehiclePhotoView"
                                                                                                    className="img-fluid cursor parcel"
                                                                                                    onClick={() =>
                                                                                                        this.toggleLightBox(
                                                                                                            true,
                                                                                                            itemCar.node.carImage?.edges
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                :
                                                                                                "-"
                                                                                        }

                                                                                    </td>
                                                                                    <td>
                                                                                        <p className={`margin-text-table`} key={"carDescription::" + itemCar.node.id}>
                                                                                            {itemCar.node.carDescription ? itemCar.node.carDescription : "-"}
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="margin-text-table" key={"carSticker::" + itemCar.node.id}>{itemCar.node.carSticker || '-'}</p>
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <p className="margin-text-table" key={"chargedFee::" + itemCar.node.id}>
                                                                                            {itemCar.node.chargedFee ? <span>&#10003;</span> : <span>&ensp;</span>}
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="margin-text-table" key={"floor::" + itemCar.node.id}>{itemCar.node.floor ? itemCar.node.floor : '-'}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="margin-text-table" key={"space::" + itemCar.node.id}>{itemCar.node.space ? itemCar.node.space : '-'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })

                                                                    )
                                                                })
                                                            }
                                                        </tbody>

                                                    </table>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <Pagination changePage={this.changePage}
                                                    first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={props.residentialVehicle.totalCount} />
                                            </div>
                                            {
                                                this.state.open_light_box &&
                                                <Lightbox
                                                    mainSrc={this.state.image_light_box[0]}
                                                    onCloseRequest={() => this.toggleLightBox(false)}
                                                    isOpen={this.state.open_light_box}
                                                    backdropClosesModal={true}
                                                    showImageCount={false}
                                                />
                                            }

                                        </React.Fragment>
                                    }
                                    return <Loading />
                                }}
                            />
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default withRouter(VehicleList);
