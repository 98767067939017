import React, { Component } from 'react';
import BudgetDraftForm from './budgetDraftForm';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import { QueryRenderer } from "react-relay";
import _ from "lodash";
import i18next from 'i18next';
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import { Translation } from 'react-i18next';

const query = graphql`
  query budgetDraftEditFormQuery{
    allYearBudget{
      edges {
          node {
              id
              years
              startDate
              endDate
              creator
              status    
              project{
                  name
              }
          }
      }
    }
  }
`

class budgetDraftEditForm extends Component {
  render() {
    return (
      <Translation>
        {t =>
          <div className="container-fluid box budget p-3" id="budget">
            <BackButtonIcon
              LinkBack={"/accounting/budget/budgetDraft/list/all"}
              LinkText={i18next.t("budgetDraftForm:Drafting a Budget")}
            />
            <QueryRenderer
              environment={environment}
              query={query}
              render={({ error, props }) => {
                if (props) {
                  let query = _.filter(props.allYearBudget.edges, (o) => o.node.id === this.props.id)
                  return <BudgetDraftForm
                    mode="edit"
                    status={this.props.status}
                    id={this.props.id}
                    query={query[0].node}
                    listStatusBudget={props.allYearBudget.edges}
                  />;
                }
              }}
            />
          </div>

        }
      </Translation>
    )
  }
}
export default budgetDraftEditForm;
