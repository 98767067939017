/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialStatus = "AVAILABILITY" | "FOR_RENT" | "LIVE" | "OTHER" | "SEIZURE" | "%future added value";
export type bookingCustomerSinghaQueryVariables = {||};
export type bookingCustomerSinghaQueryResponse = {|
  +allResidential: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +floor: ?string,
        +size: number,
        +status: ResidentialStatus,
        +residentialHouseholder: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +tenant: {|
                +firstName: string,
                +lastName: ?string,
                +countries: string,
                +user: ?{|
                  +id: string
                |},
              |},
            |}
          |}>
        |},
        +type: {|
          +id: string,
          +name: string,
        |},
      |}
    |}>,
  |}
|};
export type bookingCustomerSinghaQuery = {|
  variables: bookingCustomerSinghaQueryVariables,
  response: bookingCustomerSinghaQueryResponse,
|};
*/


/*
query bookingCustomerSinghaQuery {
  allResidential {
    totalCount
    edges {
      node {
        id
        name
        floor
        size
        status
        residentialHouseholder {
          edges {
            node {
              id
              tenant {
                firstName
                lastName
                countries
                user {
                  id
                }
                id
              }
            }
          }
        }
        type {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "floor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countries",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ResidentialTypeNode",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "bookingCustomerSinghaQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeConnection",
        "kind": "LinkedField",
        "name": "allResidential",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "residentialHouseholder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TenantNode",
                                "kind": "LinkedField",
                                "name": "tenant",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "bookingCustomerSinghaQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeConnection",
        "kind": "LinkedField",
        "name": "allResidential",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResidentialNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "residentialHouseholder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TenantNode",
                                "kind": "LinkedField",
                                "name": "tenant",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ed102a96730000fa1961bcad5c16ad6",
    "id": null,
    "metadata": {},
    "name": "bookingCustomerSinghaQuery",
    "operationKind": "query",
    "text": "query bookingCustomerSinghaQuery {\n  allResidential {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        floor\n        size\n        status\n        residentialHouseholder {\n          edges {\n            node {\n              id\n              tenant {\n                firstName\n                lastName\n                countries\n                user {\n                  id\n                }\n                id\n              }\n            }\n          }\n        }\n        type {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26e82bcb16ced313844b96d439a39484';

module.exports = node;
