import React, { Component } from "react";
import Wrapper from "../../components/wrapper/index";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import DeveloperTopMenuNavigation from "../developerTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import {getTime, lastDayOfMonth, setDate } from "date-fns";
import "../developer.scss"
import UnitReportListTable from "./unitReportListTable";
import unitReportAPI from "./unitReportAPI";
import SearchProject from "../../project/searchProject";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import generalAPI from "../generalInformation/generalAPI";
import { Dropdown  } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import UnitReportExcel from "./unitReportExcel";
import revenueAPI from '../revenueContract/revenueContractAPI'
import _ from 'lodash';
import ReactPaginate from "../../libs/ReactPaginate";

const head_table = [
    {value:'no', label : "No."},
    {value : 'plan' , label : "Plan"},
    {value: 'roomNo' , label : "Room No."},
    {value : 'size' , label : 'Area (Sq m)'},
    {value : 'S031' , label : 'S031'},
    {value : 'S032' , label : 'S032'},
    {value : 'S033' , label : 'S033'},
    {value : 'other' , label : 'Other'},
    {value : 'total' , label : 'Amount'}
]


class UnitReportList extends Component {
    constructor(props){
        super(props);
        this.state={
            date : 'All',
            unitReportList : [],
            developerName : '',
            search : '',
            startDate : setDate(new Date(),1),
            endDate : lastDayOfMonth(new Date()),
            token: localStorage.getItem("token"),
            totalUnit : '',
            head_table : head_table,
            loading : false,
            totalCount : 0,
            first: 0,
            last: 10,
            page_count: 0,
            num_record: 0,
            all_item: 10,
            page_select: 0,
            index_pages: {},
            indexFirst : 0

        }
        this.handleChange = this.handleChange.bind(this)
    }


    handleChange(e){
        this.setState({
            [e.target.name] : e.target.value,
            first : 0,
            last : 10,
            page_count: 0,
            num_record: 0,
            all_item: 10,
            page_select: 0,
        },() => this.fetchData())
    }

    componentDidMount(){
        generalAPI.getProfile(this.state.token).then((response => {
            this.setState({
                developerName : response.data.generalData.developer
            })
        }))
        this.fetchData()
    }

    componentDidUpdate(prevProps,prevState,snapshot){
        if(prevState.head_table !== this.state.head_table){
            this.setState({
                head_table : this.state.head_table
            })
        }
        if (prevState.page_select !== this.state.page_select) {
            this.fetchData(true);
        }
    }

    getPriceType = (price,type) => {
        switch (_.lowerCase(type)) {
            case 'ratio' :
                return price + ' บาท / อัตราส่วนกรรมสิทธิ์ / เดือน';
            case 'individual' : 
                return 'คิดตามยูนิต / เดือน';
            case 'area' :
                return  price + ' บาท / ตร.ม. / เดือน';
            case 'static' :
                return  price + ' บาท / เดือน';
            default :
                return  price + ' บาท / หน่วย / เดือน';
        }
    }

    changePageLimit = (value) => {
        this.setState({
          first: 0,
          last: value,
          all_item: value,
        },() => this.fetchData());
    }

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page_select: page });
    };

    fetchData = (update) => {
        this.setState({loading:true})
        unitReportAPI.getUnitReport(this.state.token,this.state.startDate,this.state.endDate,this.state.search,
            update ? this.state.index_pages[this.state.page_select].first : this.state.first,
            update ? this.state.index_pages[this.state.page_select].last : this.state.last)
            .then((response) => {
                if(response.data.totalCount !== 0){
                    let first_index = _.cloneDeep(this.state.first);
                    let last_index = _.cloneDeep(this.state.last);
                    let index_pages = {};
                    let page_count = Math.ceil(response.data.totalCount / this.state.all_item);
                    for (let index = 0; index < page_count; index++) {
                        index_pages[index] = {
                        first: first_index,
                        last: last_index,
                        };
                        if (index == page_count - 2) {
                        first_index += this.state.all_item;
                        last_index +=
                            response.data.totalCount % this.state.all_item != 0
                            ? response.data.totalCount % this.state.all_item
                            : this.state.all_item;
                        } else {
                        first_index += this.state.all_item;
                        last_index += this.state.all_item;
                        }
                    }
                    this.setState({
                        page_count: page_count,
                        num_record: response.data.totalCount,
                        index_pages: index_pages,
                    
                    },() => this.setState({
                        indexFirst : this.state.index_pages[this.state.page_select].first
                    }))
                }else{
                    this.setState({
                        page_count: 0,
                        num_record: response.data.totalCount,
                        index_pages: {},
                    },() => this.setState({
                        indexFirst : 0
                    }))
                }
                if(this.state.unitReportList !== response.data.unitReportList){
                    this.setState({
                        unitReportList : response.data.unitReportList,
                        totalUnit : response.data.totalUnit,
                        totalOther : response.data.totalOther,
                        totalProduct1 : response.data.totalProduct1,
                        totalProduct2 : response.data.totalProduct2,
                        totalProduct3 : response.data.totalProduct3
                    },() => this.setState({loading:false}))
                }
            })
        revenueAPI.getService(this.state.token)
            .then((response) => {
                if(response.data.productAndService){
                    let serviceDefaultCode = ['S031','S032' ,'S033']
                    _.forEach(serviceDefaultCode, (code,index) => {
                        if(_.find(response.data.productAndService , ['productCode',code])){
                            let product = _.find(response.data.productAndService , ['productCode',code])
                            this.setState( prevState => {
                                return _.set(
                                    _.cloneDeep(prevState),
                                    'head_table['+(index+4)+'].label',
                                    product.name + ' ' + this.getPriceType(product.price , product.pricingType)
                                )
                            })
                        }
                    })
                    this.setState({
                        productAndService : response.data.productAndService,
                    })
                }
            })
    }

    handleSearch = (text) => {
        this.setState({
            search: text,
            first : 0,
            last : 10,
            page_count: 0,
            num_record: 0,
            all_item: 10,
            page_select: 0,
        } ,() => this.fetchData())
    }

    render() {
        return (
        <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent disabledOverflowX={true}>
            <DeveloperTopMenuNavigation mini={true} />
            <div className="container-fluid box" id="developer-form">
                <div className="row justify-content-between">
                    <div className="col-md-4">
                        <Translation>
                            {t =>
                                <h4>
                                    <Link to="/developer">
                                        <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/wrapperContent/back.png"
                                        }
                                        alt="back"
                                        className="back"
                                        />
                                    </Link>
                                    {t("developer:Unit Report")}
                                </h4>
                            }
                        </Translation>
                    </div>
                    <div className="col-md-8 d-flex justify-content-end">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'ownership_unitreport_print'}) &&
                    <Dropdown>
                        <DropdownToggle id="dropdown-item-button" bsPrefix = 'dropdown-export dropdown-toggle'>
                            EXPORT
                        </DropdownToggle>
                        <DropdownMenu>
                            <UnitReportExcel head_table={this.state.head_table} state={this.state}/>
                            <Dropdown.Item>
                                <Link to={"/developer/unit-report/unit-report-pdf/" + 
                                (this.state.startDate === '' ? this.state.startDate : getTime(this.state.startDate)) + "/"+(this.state.endDate === '' ? '' : getTime(this.state.endDate)) + "/" + this.state.search} className="text-black">
                                    <p className="text-black">PDF</p>
                                </Link>
                            </Dropdown.Item>
                        </DropdownMenu>
                    </Dropdown>
                    }
                        
                    </div>
                </div>

                <Translation>
                    {t => 
                    <div className="content-inner mt-4">
                        <div className="row justify-content-end mb-2">
                            <div className="col row align-self-center">
                                <span className="h4 ml-3">{t('developer:Name of developer')} : </span>
                                <span className="h4 ml-2">{this.state.developerName}</span>

                            </div>
                            <div className ="col row w-auto d-flex justify-content-end text-right float-right w-auto">
                                <span className="mr-2 align-self-center">{t("unitReport:Date")}</span>
                                <DatePickerAdapter 
                                    selected={this.state.startDate} name="startDate" 
                                    onChange={this.handleChange}
                                    className="form-control"
                                />
                                <span className="mr-2 align-self-center ml-2">{t("unitReport:to")}</span>
                                <DatePickerAdapter 
                                    selected={this.state.endDate} name="endDate" 
                                    onChange={this.handleChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-3 input-group float-right w-auto mb-2">   
                                <SearchProject callback_function={this.handleSearch} search={this.state.search} />
                            </div>
                        </div>
                        <React.Fragment>
                            <div className="card fade-up card-bd-none" >
                                <div className="table-responsive" >
                                    <table className="table table-hover">
                                        <thead className="thead-light ">
                                            <tr>
                                                {this.state.head_table.map((head,index) => {
                                                    return <th className= "text-center" width={index <= 3 ? 100 : index > 3 ? 200 : ''} key={index}>{t("unitReport:"+head.label)}</th>
                                                })}
                                            </tr>
                                        </thead>
                                        {this.state.loading ? 
                                            <tbody>
                                                <tr>
                                                    <td className="text-center" colSpan={9}> 
                                                    <span className="d-flex justify-content-center align-item-center">
                                                        <span
                                                        className="spinner-border spinner-border-sm align-middle mr-2"/> 
                                                        {t("unitReport:Processing")}
                                                    </span>
                                                </td>
                                                </tr>
                                            </tbody>
                                            :
                                            <UnitReportListTable unitReportList={this.state.unitReportList} state ={this.state}/>
                                        }
                                        
                                        
                                    </table>
                                </div>
                            </div>
                            </React.Fragment>
                            <ReactPaginate
                                state={this.state}
                                changePageLimit={this.changePageLimit}
                                handlePageClick={this.handlePageClick}
                            />
    
                        
                    </div>}
                </Translation>
            </div>
            </WrapperContent>
        </Wrapper>
        );
    }
}

export default UnitReportList;
