import React from 'react';
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Wrapper from "../../components/wrapper";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import AccountingTopMenuNavigation from '../accountingTopMenuNavigation';


const query = graphql`
    query waterMeterListHistoryByRoomViewQuery($search: String, $year: Float, $month: Float, $issuedDate_Gte: Date, $issuedDate_Lte: Date,) {
        allResidential (search: $search) {
            edges {
                node {
                    id
                    name 
                    logWaterMeterUnit(year: $year, month: $month, issuedDate_Gte:$issuedDate_Gte, issuedDate_Lte:$issuedDate_Lte){
                        edges {
                            node {
                                id
                                meterValue
                                creator
                                remark
                                changeWaterMeter
                                updated 
                                initialChangeMeterValue
                                editDate
                                creatorApp
                                editCreatorDate
                                editCreatorDateApp
                                unitUse
                                logWaterMeterImage{
                                    edges {
                                        node {
                                            id
                                            fileName
                                            fileUpload
                                        }
                                    } 
                                }
                                logWaterMeterTag{
                                    edges{
                                        node{
                                            name
                                        }
                                    }

                                }
                            }
                        }
                    }  
                }
            } 
        }
    }
`;

const seleteMonthDetail = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
]
const seleteYearDetail = [
    { value: new Date().getFullYear() - 3, label: new Date().getFullYear() - 3 },
    { value: new Date().getFullYear() - 2, label: new Date().getFullYear() - 2 },
    { value: new Date().getFullYear() - 1, label: new Date().getFullYear() - 1 },
    { value: new Date().getFullYear(), label: new Date().getFullYear() },
    // { value: new Date().getFullYear() + 1, label: new Date().getFullYear() + 1 },

]

const selectYear = new Date().getFullYear()
const selectMonth = parseInt(new Date().getMonth() + 1)

class WaterMeterListHistoryByRoomView extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            idDataResident: [],
            unitPriceMeter: null,
            searchYear: selectYear,
            searchMonth: selectMonth,
            searchBox: "",
            loading: true,
            notFound: false
        }
        // this.selectNow = this.selectNow.bind(this)
        // this.removeSelectAll = this.removeSelectAll.bind(this)
        this.onSreach = this.onSreach.bind(this)
    }

    componentDidMount() {
        let data = this.props.location?.state?.data || []
        let unitPriceMeter = this.props.location?.state?.unitPriceMeter

        let idDataResident = []
        let dataMap = data?.map((n) => {
            idDataResident.push(n?.id)
        })
        this.setState({ idDataResident: idDataResident, unitPriceMeter: unitPriceMeter }, () => {
            return (
                this.getMeterList()
            )
        })
    }

    getMeterList() {
        let variables = {
            "search": this.state.searchBox,
            "month": this.state.searchMonth,
            "year": this.state.searchYear,
            "residentialId": this.state.idDataResident
        }

        let dataa = []
        let se = false
        fetchQuery(environment, query, variables).then(results => {
            if (this.state.searchBox !== "") {
                let dataFileter = results.allResidential.edges.filter((nn) => nn.node.name === this.state.searchBox)
                if (dataFileter[0]) {
                    dataa.push(dataFileter[0]?.node)
                } else {
                    se = true
                }

            } else { 
                let idddta = this.state.idDataResident.map((n) => {
                    let dataFileter = results.allResidential.edges.filter((nn) => nn.node.id === n)
                    dataa.push(dataFileter[0]?.node)
                })
            }

            // console.log(dataa);

            this.setState({
                data: dataa, loading: false,
                searchBox: variables.search,
                searchMonth: variables.month,
                selectYear: variables.year,
                notFound: se
            })
        });
    }

    onSreach() {
        this.setState({ loading: true }, () => {
            return this.getMeterList()
        })

    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>

                            <React.Fragment>
                                <div className="container-fluid box">
                                    <BackButtonIcon
                                        LinkBack={"/accounting/water-meter/viewHistory"}
                                        LinkText={t("materWater:Revision history of meter number")}
                                    />
                                    <div className="content-inner claim">
                                        <div className='row meterWaterFunction mb-5 mt-5 claim'>
                                            <div className='col-4 floatLeft' style={{ display: 'inline' }}>
                                                <div className='row'>
                                                    <label className='col labelInline ml-0' >{t("materWater:Search unit No")}: &nbsp;&nbsp;</label>
                                                </div>
                                                <div className='row' style={{ position: 'relative' }}>
                                                    <div className='col'>
                                                        <input
                                                            type="text"
                                                            name='searchBox'
                                                            defaultValue={this.state.searchBox}
                                                            className='form-control searchBoxs'
                                                            placeholder={t("silvermanguard:search")}
                                                            onKeyUp={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                            onKeyPress={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                            onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                        />
                                                        <span className='spanINBox cursor' onClick={this.onSreach}>
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-8 ' style={{ display: 'inline' }}>
                                                <div className='row'>
                                                    <label className='col labelInline' >{t("materWater:Water charges ofs")}: &nbsp;&nbsp;</label>
                                                </div>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className=''>
                                                            <select
                                                                name='searchYear'
                                                                className='form-control searchDate floatLeft mr-5'
                                                                onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                                style={{ width: 150 }}
                                                                defaultValue={selectYear}>
                                                                {
                                                                    seleteYearDetail.map((n, ind) => {
                                                                        return <option value={n.value} key={ind}>{n.label}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            <select
                                                                name='searchMonth'
                                                                className='form-control searchDate'
                                                                onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                                defaultValue={selectMonth}
                                                                style={{ width: 150 }}
                                                            >
                                                                {
                                                                    seleteMonthDetail.map((n, ind) => {
                                                                        return <option value={n.value} key={ind}>{t("materWater:"+n.label)}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>

                                        <div className='row'>
                                            {/* <label className='labelInline mt-4'>หมายเหตุ :</label>
                                            <div className='col-8'>
                                                <div style={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: 'rgb(255, 189, 45)',
                                                    position: 'absolute',
                                                    top: 18,

                                                }}></div>
                                                <label style={{ position: 'relative', marginLeft: 36 }}>เปลี่ยนมิเตอร์</label>
                                                <div style={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: '#EC2340',
                                                    position: 'absolute',
                                                    top: 18,
                                                    marginLeft: 160
                                                }}></div>
                                                <label style={{ position: 'relative', marginLeft: 80 }}>การใช้น้ำผิดปกติ</label>
                                            </div> */}

                                        </div>
                                        {
                                            !this.state.loading ?
                                                this.state.data?.map((n, indexResident) => {
                                                    return (
                                                        <div className='card mt-5 mb-5' key={"indexResident", indexResident}>
                                                            <label className='headerLabel28 m-3'>{n?.name}</label>
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr className='gray text-center' style={{fontSize: 11}}>
                                                                        {/* วันที่แก้ไขข้อมูล */}
                                                                        <th>{t("materWater:Revised on")}</th>
                                                                        {/* เวลา */}
                                                                        <th>{t("materWater:Time")}</th>
                                                                        {/* เลขมิเตอร์เดือนปัจจุบัน */}
                                                                        <th>{t("materWater:Meter number of current month")}</th>
                                                                        <th>{t("materWater:Number of units used")}</th>
                                                                        <th>{t("materWater:Inspector by")}</th>
                                                                        <th>{t("materWater:Last edited bys")}</th>
                                                                        <th>{t("materWater:Amount charged")} <br/>
                                                                        {
                                                                            t("materWater:am") === "En" ?
                                                                            `(${this.state.unitPriceMeter} ${t("materWater:Bath per unit")})`
                                                                             :
                                                                            `(${t("materWater:Bath per unit")} ${this.state.unitPriceMeter})`
                                                                        }
                                                                       </th>
                                                                        <th>{t("materWater:Meter malfunction")} </th>
                                                                        <th>{t("materWater:Additional picture")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        n?.logWaterMeterUnit?.edges?.map((datas, indexLog) => {

                                                                            let unitUse = datas?.node?.unitUse ? datas?.node?.unitUse : 0
                                                                            let updateDate = datas?.node?.creatorApp ? datas?.node?.editCreatorDateApp : datas?.node?.editCreatorDate
                                                                            return (
                                                                                <tr className='text-center' key={"indexLog", indexLog} style={{fontSize: 11}}>
                                                                                    <td>{updateDate ? format(new Date(updateDate), "DD/MM/YYYY") : "-"}</td>
                                                                                    <td>
                                                                                        {
                                                                                            t("materWater:am") === "En" ?
                                                                                            (updateDate ? format(new Date(updateDate), "hh:mm A") : "-")
                                                                                            :
                                                                                            (updateDate ? format(new Date(updateDate), "HH:mm น.") : "-")
                                                                                        }
                                                                                       
                                                                                    </td>
                                                                                    <td>{datas?.node?.meterValue || '-'}</td>
                                                                                    <td>{unitUse || '-'}</td>
                                                                                    <td>{datas?.node?.creatorApp || '-'}</td>
                                                                                    <td>{datas?.node?.creator || '-'}</td>
                                                                                    <td>{parseInt(unitUse) * parseInt(this.state.unitPriceMeter)}</td>
                                                                                    <td className='text-left'>
                                                                                        {
                                                                                            datas?.node?.logWaterMeterTag?.edges?.map((MeterTag, indexTagMeter) => {
                                                                                                return (
                                                                                                    <p className='pb-0 mb-0' key={"indexTagMeter", indexTagMeter}>- {MeterTag?.node.name}</p>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            datas?.node?.logWaterMeterImage?.edges[0]?.node?.fileUpload ?
                                                                                                <img src={datas?.node?.logWaterMeterImage?.edges[0]?.node?.fileUpload}
                                                                                                    style={{ width: 104, height: 90, objectFit: 'cover' }} />
                                                                                                :
                                                                                                "-"
                                                                                        }
                                                                                    </td>
                                                                                </tr>

                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        n?.logWaterMeterUnit?.edges?.length === 0 &&
                                                                        <tr className='text-center'>
                                                                            <td>{'-'}</td>
                                                                            <td>{'-'}</td>
                                                                            <td>{'-'}</td>
                                                                            <td>{'-'}</td>
                                                                            <td>{'-'}</td>
                                                                            <td>{'-'}</td>
                                                                            <td>{'-'}</td>
                                                                            <td>{'-'}</td>
                                                                            <td>{'-'}</td>
                                                                        </tr>
                                                                    }
                                                                    <tr></tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="text-center" >
                                                    <img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/loading.gif'} />
                                                </div>
                                        }

                                        {
                                            !this.state.loading && this.state.data.length === 0 && 
                                            <div className='card mt-5 mb-5 text-center p-10'>
                                                <h6>{t("meterWater:NotFoundToFind")}</h6>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </React.Fragment>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>




        );
    }
}

export default WaterMeterListHistoryByRoomView;
