/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type waterMeterListCreateQrCodeQueryVariables = {|
  search?: ?string
|};
export type waterMeterListCreateQrCodeQueryResponse = {|
  +allResidential: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +residentialHouseholder: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +tenant: {|
                +id: string,
                +firstName: string,
                +lastName: ?string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type waterMeterListCreateQrCodeQuery = {|
  variables: waterMeterListCreateQrCodeQueryVariables,
  response: waterMeterListCreateQrCodeQueryResponse,
|};
*/


/*
query waterMeterListCreateQrCodeQuery(
  $search: String
) {
  allResidential(search: $search) {
    edges {
      node {
        id
        name
        residentialHouseholder {
          edges {
            node {
              id
              tenant {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "ResidentialNodeConnection",
    "kind": "LinkedField",
    "name": "allResidential",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HouseHolderNodeConnection",
                "kind": "LinkedField",
                "name": "residentialHouseholder",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "kind": "LinkedField",
                            "name": "tenant",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "waterMeterListCreateQrCodeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "waterMeterListCreateQrCodeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "96c0454a21087feb5ff623021e0e30bb",
    "id": null,
    "metadata": {},
    "name": "waterMeterListCreateQrCodeQuery",
    "operationKind": "query",
    "text": "query waterMeterListCreateQrCodeQuery(\n  $search: String\n) {\n  allResidential(search: $search) {\n    edges {\n      node {\n        id\n        name\n        residentialHouseholder {\n          edges {\n            node {\n              id\n              tenant {\n                id\n                firstName\n                lastName\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa064d71c063e581f4654af148f29dd1';

module.exports = node;
