import './styles/index.scss';
import React, {Component} from 'react';
import Navigation from './navigation';

const $ = window.$;

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {mouseHover: "not-hover"};
        this.goToTop = this.goToTop.bind(this);
        this.mouseHover = this.mouseHover.bind(this);
    }

    componentWillMount() {
        this.setState({mouseHover: localStorage.getItem("is_hover")});
    }

    goToTop() {
        $('html, body').animate({ scrollTop: 0 }, 500);
    }

    mouseHover(is_hover) {
        this.setState({mouseHover: is_hover}, () => {
            localStorage.setItem("is_hover", is_hover);
        });
    }

    render() {
        return (
            <div id="sidebar" className={this.state.mouseHover}
                 onMouseEnter={() => this.mouseHover("hover")}
                 onMouseLeave={() => this.mouseHover("not-hover")}>
                <div className="top" onClick={this.goToTop}>SILVERMAN</div>
                <Navigation/>
            </div>
        );
    }
}

export default Sidebar;