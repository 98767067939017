import React from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import ComponentPagination from "../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Loading from "../../../libs/loading";
import { format } from "date-fns";
import { Button } from "../../../components/Button/Button";
import Pagination from "../../../libs/newPagination";
import localStorage from "../../../libs/localstorage";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from "i18next";
const query = graphql`
  query budgetReportListQuery($first: Int, $last: Int) {
    allYearBudget (status : "approve",first: $first, last: $last ) {
      edges {
        node {
          id
          years
          startDate
          endDate
          creator
          project {
            name
          }
        }
      }
      totalCount
    }
  }
`;

class BudgetReportListList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      all_budget: [],
      menu: this.props.match.params.menu,
      first: this.state.first,
      last: this.state.last,
      totalCount: 10
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.first !== this.state.first || prevState.last !== this.state.last) {
      this.getData()
    }
  }

  async componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({ loading: true })
    fetchQuery(environment, query, { first: this.state.first, last: this.state.last }).then((data) => {
      let data_clone = _.cloneDeep(data);
      this.setState({
        all_budget: data_clone.allYearBudget.edges,
        totalCount: data_clone.allYearBudget.totalCount,
        loading: false,
      });
    });

  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const text = {
      'report-seting': 'Annual Budget Setting Report',
      'report-usage-summary': 'Summary Budget Usage Report',
      'report-month-budget-usage': 'Monthly Budget Usage Report vs. Budget Setting',
      'report-month-budget-usage-summary': 'Monthly Budget Usage Report Summary Remaining Balance',

    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box booking-form p-4">
                <BackButtonIcon
                  LinkBack={"/accounting/budget/"}
                  LinkText={i18next.t("menuListBudget:"+text[this.state.menu])}
                />
                {!this.state.loading ? (
                  <React.Fragment>
                    <div className="card fade-up mt-4 " id="parcel">
                      <div className="table-responsive">
                        <table className="table table-hover budget ">
                          <React.Fragment>
                            <thead>
                              <tr>
                                <th className="text-center">{i18next.t("budgetAll:Sequence")}</th>
                                <th className="text-center">{i18next.t("budgetAll:Corporate name")}</th>
                                <th className="text-center">{i18next.t("budgetAll:Budgeting year")}</th>
                                <th className="text-center">{i18next.t("budgetAll:Budgeting year start date")}</th>
                                <th className="text-center">{i18next.t("budgetAll:Budgeting year end date")}</th>
                                <th className="text-center"></th>
                              </tr>
                            </thead>

                            <tbody>
                              {_.map(this.state.all_budget, (budget, index) => {
                                let order_id = this.state.first - localStorage.getPageLimit();
                                return (<React.Fragment key={index}>
                                  <tr className="show">
                                    <td className="text-center">{order_id + index + 1}</td>
                                    <td className="text-center">{budget.node.project?.name}</td>
                                    <td className="text-center">{budget.node.years}</td>
                                    <td className="text-center">{format(budget.node.startDate, "DD/MM/YYYY")}</td>
                                    <td className="text-center">{format(budget.node.endDate, "DD/MM/YYYY")}</td>
                                    <td className="text-center">
                                      <Link to={`/accounting/budget/${this.state.menu}/${budget.node.id}/${budget.node.endDate}`}>
                                        <Button>{i18next.t("receivable_dashboard:View")}</Button>
                                      </Link>
                                    </td>
                                  </tr>
                                </React.Fragment>
                                )
                              })}
                            </tbody>
                          </React.Fragment>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <Pagination
                        changePage={this.changePage}
                        first={this.state.first}
                        last={this.state.last}
                        totalCount={this.state.totalCount}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <Loading />
                )}

              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default BudgetReportListList;
