/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type payTransactionListQueryVariables = {|
  id_in: $ReadOnlyArray<?string>,
  contact_id: string,
|};
export type payTransactionListQueryResponse = {|
  +allPurchaseRecord: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +description: string,
        +unitItems: number,
        +price: number,
        +preTaxAmount: number,
        +vat: ?number,
        +vatAmount: number,
        +paid: number,
        +whtRate: number,
        +whtRatePercent: ?number,
        +total: number,
        +prepaidDepositTransactionUsed: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +amount: ?number,
              +prepaidDepositTransaction: {|
                +whtRatePercent: ?number,
                +whtAmount: number,
              |},
            |}
          |}>
        |},
        +chartOfAccount: {|
          +name: string,
          +chartOfAccountCode: string,
        |},
        +productAndService: ?{|
          +productCode: string
        |},
        +purchaseRecordGroup: {|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
        |},
        +acceptCreditNoteTransaction: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +docNumber: string,
              +issuedDate: any,
              +price: number,
              +total: number,
              +description: ?string,
              +purchaseRecord: {|
                +chartOfAccount: {|
                  +chartOfAccountCode: string,
                  +name: string,
                |}
              |},
            |}
          |}>
        |},
      |}
    |}>,
  |},
  +contact: ?{|
    +id: string,
    +refNumber: string,
    +name: string,
    +firstName: string,
    +lastName: string,
    +registeredAddress: string,
    +registeredCountry: string,
    +registeredProvince: string,
    +registeredDistrict: string,
    +registeredCity: string,
    +registeredPostalCode: string,
    +typeOfContact: ContactTypeOfContact,
  |},
  +allProjects: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +periodCreate: string
      |}
    |}>
  |},
|};
export type payTransactionListQuery = {|
  variables: payTransactionListQueryVariables,
  response: payTransactionListQueryResponse,
|};
*/


/*
query payTransactionListQuery(
  $id_in: [String]!
  $contact_id: ID!
) {
  allPurchaseRecord(id_In: $id_in) {
    totalCount
    edges {
      node {
        id
        description
        unitItems
        price
        preTaxAmount
        vat
        vatAmount
        paid
        whtRate
        whtRatePercent
        total
        prepaidDepositTransactionUsed {
          edges {
            node {
              amount
              prepaidDepositTransaction {
                whtRatePercent
                whtAmount
                id
              }
              id
            }
          }
        }
        chartOfAccount {
          name
          chartOfAccountCode
          id
        }
        productAndService {
          productCode
          id
        }
        purchaseRecordGroup {
          id
          docNumber
          issuedDate
        }
        acceptCreditNoteTransaction(status: "paid") {
          edges {
            node {
              id
              docNumber
              issuedDate
              price
              total
              description
              purchaseRecord {
                chartOfAccount {
                  chartOfAccountCode
                  name
                  id
                }
                id
              }
            }
          }
        }
      }
    }
  }
  contact(id: $contact_id) {
    id
    refNumber
    name
    firstName
    lastName
    registeredAddress
    registeredCountry
    registeredProvince
    registeredDistrict
    registeredCity
    registeredPostalCode
    typeOfContact
  }
  allProjects {
    edges {
      node {
        periodCreate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id_in"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id_In",
    "variableName": "id_in"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitItems",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preTaxAmount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vatAmount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRatePercent",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtAmount",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "PurchaseRecordGroupNode",
  "kind": "LinkedField",
  "name": "purchaseRecordGroup",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v20/*: any*/),
    (v21/*: any*/)
  ],
  "storageKey": null
},
v23 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "paid"
  }
],
v24 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "contact_id"
    }
  ],
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredPostalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodCreate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "payTransactionListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PurchaseRecordNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseRecord",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                    "kind": "LinkedField",
                    "name": "prepaidDepositTransactionUsed",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PrepaidDepositTransactionUsedNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionNode",
                                "kind": "LinkedField",
                                "name": "prepaidDepositTransaction",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v16/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAndServiceNode",
                    "kind": "LinkedField",
                    "name": "productAndService",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": (v23/*: any*/),
                    "concreteType": "AcceptCreditNoteNodeConnection",
                    "kind": "LinkedField",
                    "name": "acceptCreditNoteTransaction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AcceptCreditNoteNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AcceptCreditNoteNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v7/*: any*/),
                              (v14/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PurchaseRecordNode",
                                "kind": "LinkedField",
                                "name": "purchaseRecord",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ChartOfAccountNode",
                                    "kind": "LinkedField",
                                    "name": "chartOfAccount",
                                    "plural": false,
                                    "selections": [
                                      (v18/*: any*/),
                                      (v17/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v24/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v25/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "payTransactionListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PurchaseRecordNodeConnection",
        "kind": "LinkedField",
        "name": "allPurchaseRecord",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseRecordNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PurchaseRecordNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                    "kind": "LinkedField",
                    "name": "prepaidDepositTransactionUsed",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PrepaidDepositTransactionUsedNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionNode",
                                "kind": "LinkedField",
                                "name": "prepaidDepositTransaction",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v16/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAndServiceNode",
                    "kind": "LinkedField",
                    "name": "productAndService",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": (v23/*: any*/),
                    "concreteType": "AcceptCreditNoteNodeConnection",
                    "kind": "LinkedField",
                    "name": "acceptCreditNoteTransaction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AcceptCreditNoteNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AcceptCreditNoteNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v7/*: any*/),
                              (v14/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PurchaseRecordNode",
                                "kind": "LinkedField",
                                "name": "purchaseRecord",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ChartOfAccountNode",
                                    "kind": "LinkedField",
                                    "name": "chartOfAccount",
                                    "plural": false,
                                    "selections": [
                                      (v18/*: any*/),
                                      (v17/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "acceptCreditNoteTransaction(status:\"paid\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v24/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90adc944df64d598f74dd5514cbcb346",
    "id": null,
    "metadata": {},
    "name": "payTransactionListQuery",
    "operationKind": "query",
    "text": "query payTransactionListQuery(\n  $id_in: [String]!\n  $contact_id: ID!\n) {\n  allPurchaseRecord(id_In: $id_in) {\n    totalCount\n    edges {\n      node {\n        id\n        description\n        unitItems\n        price\n        preTaxAmount\n        vat\n        vatAmount\n        paid\n        whtRate\n        whtRatePercent\n        total\n        prepaidDepositTransactionUsed {\n          edges {\n            node {\n              amount\n              prepaidDepositTransaction {\n                whtRatePercent\n                whtAmount\n                id\n              }\n              id\n            }\n          }\n        }\n        chartOfAccount {\n          name\n          chartOfAccountCode\n          id\n        }\n        productAndService {\n          productCode\n          id\n        }\n        purchaseRecordGroup {\n          id\n          docNumber\n          issuedDate\n        }\n        acceptCreditNoteTransaction(status: \"paid\") {\n          edges {\n            node {\n              id\n              docNumber\n              issuedDate\n              price\n              total\n              description\n              purchaseRecord {\n                chartOfAccount {\n                  chartOfAccountCode\n                  name\n                  id\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  contact(id: $contact_id) {\n    id\n    refNumber\n    name\n    firstName\n    lastName\n    registeredAddress\n    registeredCountry\n    registeredProvince\n    registeredDistrict\n    registeredCity\n    registeredPostalCode\n    typeOfContact\n  }\n  allProjects {\n    edges {\n      node {\n        periodCreate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '96e56ed69c2ed3a869d93579b3c6f8c4';

module.exports = node;
