import React from "react";
import "../styles/commonArea.scss";
import iconBooking from "../CommonAreaList/create/iconBooking";
import { Translation } from "react-i18next";
import { format } from "date-fns";
const SettingBookingStep2 = ({
    state: {
        resident_score,
        rent_score,
        agent_score,
        expire_per_year,
        expire_number,
        expire_numberDay,
        expire_numberYear,
    },
    selectChange,
    data,
    onClickPrev,
    onClickNext
}) => {
    let year = new Date().getFullYear() + 543
    // let date = new Date(new Date().setFullYear(year))
    return (
        <Translation>
            {t =>
                <React.Fragment>
                    <div className="d-flex justify-content-center  mb-3  mt-5 claim">
                        <div className="create col-8" style={{zoom: '80%'}}>
                            <label>
                                <p className="headerLabel18 fw-600 ml-2">01 <span className="label16 ml-2">{t("newBookingSingha_Setting:Advance booking")}</span></p>
                                <iconBooking.iconStep1 />
                            </label>
                            <label className="ml-2 mr-2">
                                <p className="headerLabel18 fw-600 ml-2">02 <span className="label16 ml-2">{t("newBookingSingha_Setting:Point setup")}</span></p>
                                <iconBooking.iconStep2_1 />
                            </label>
                            <label>
                                <p className="headerLabel18 fw-600 ml-2">03 <span className="label16 ml-2">Money to Point</span></p>
                                <iconBooking.iconStep1_3 />
                            </label>
                        </div>
                    </div>

                    {/* จำนวนแต้มที่มอบให้ */}
                    <div className="d-flex justify-content-center  mb-3  mt-5 ">
                        <div className="col-8">
                            <div className="line">
                                <div className="header">{t("newBookingSingha_Setting:Initial points")}</div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <label className="label16 fw-400">{t("newBookingSingha_Setting:Owner - Resident")} </label>
                                    <div className="usedAll">
                                        <input type={"number"}
                                            name="resident_score"
                                            className="form-control headerLabel16 pl-5"
                                            onChange={selectChange}
                                            defaultValue={resident_score} pattern="[0-9]+$" />
                                        <label className="label16 fw-300 textInBoxInput" style={{ top: 28 }}>{t("newBookingSingha_Setting:Point")}</label>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <label className="label16 fw-400">{t("newBookingSingha_Setting:Tenant")}</label>
                                    <div className="usedAll">
                                        <input type={"number"}
                                            onChange={selectChange}
                                            name="rent_score"
                                            className="form-control headerLabel16 pl-5" defaultValue={rent_score} pattern="[0-9]+$" />
                                        <label className="label16 fw-300 textInBoxInput" style={{ top: 28 }}>{t("newBookingSingha_Setting:Point")}</label>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <label className="label16 fw-400">{t("newBookingSingha_Setting:Agent")}</label>
                                    <div className="usedAll">
                                        <input type={"number"}
                                            onChange={selectChange}
                                            name="agent_score"
                                            className="form-control headerLabel16 pl-5" defaultValue={agent_score} pattern="[0-9]+$" />
                                        <label className="label16 fw-300 textInBoxInput" style={{ top: 28 }}>{t("newBookingSingha_Setting:Point")}</label>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                    {/* อายุของแต้ม */}
                    <div className="d-flex justify-content-center  mb-3  mt-5 ">
                        <div className="col-8">
                            <div className="line">
                                <div className="header">{t("newBookingSingha_Setting:Duration of points")}</div>
                            </div>

                            <label className="label16 fw-400">{t("newBookingSingha_Setting:Points will expire when")}</label>

                            <div className="usedAll flex mt-3">
                                <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Setting:The last day of the next")}
                                    <input type="radio" name="expire_per_year" value={true} defaultChecked={expire_per_year == true}
                                        onChange={selectChange}
                                    />
                                    <span className="checkmark checkmarks_dot_10" style={{ left: 3, top: -4, width: 20, height: 20 }}></span>
                                </label>

                                <input type={"number"} name="expire_numberYear"
                                    onChange={selectChange}
                                    disabled={expire_per_year == false}
                                    className="form-control text-center ml-3 mr-3 headerLabel16"
                                    style={{ width: 102 }} defaultValue={expire_per_year ? expire_number : 1} />
                                <label className="label16 fw-300">{t("newBookingSingha_Setting:year(s)")}</label>
                            </div>

                            <label>{t("newBookingSingha_Setting:For example, points earned today will expire on")} {format(new Date(new Date().setFullYear(parseInt(new Date().getFullYear()) + 543 + (expire_per_year ? expire_numberYear : 1))), "DD MMM YYYY")}</label>

                            <div className="usedAll flex mt-3">
                                <label className="containerRadio fw-300 ml-0" style={{ marginLeft: '35px', fontSize: 18 }}>{t("newBookingSingha_Setting:Next")}
                                    <input type="radio" name="expire_per_year" value={false} defaultChecked={expire_per_year == false}
                                        onChange={selectChange}
                                    />
                                    <span className="checkmark checkmarks_dot_10" style={{ left: 3, top: -3, width: 20, height: 20 }}></span>
                                </label>

                                <input type={"number"} name="expire_numberDay"
                                    onChange={selectChange}
                                    disabled={expire_per_year == true}
                                    className="form-control text-center ml-3 mr-3 headerLabel16" style={{ width: 102 }} defaultValue={expire_per_year == false ? expire_number : 100} />
                                <label className="label16 fw-300">{t("newBookingSingha_Setting:Day")}</label>
                            </div>
                            <label>{t("newBookingSingha_Setting:For example, points earned today will expire on")} {format(new Date(new Date().setFullYear(year)).setDate(expire_numberDay), "DD MMM YYYY")}</label>



                            {/* BTN Next AnD Back */}
                            <div style={{ clear: 'both' }} />
                            <div className="row claim mt-10">
                                <div className="col-5">
                                    <button className="btn-primary-outline" type="button" onClick={onClickPrev}>
                                        {t("Allaction:back")}
                                    </button>
                                </div>
                                <div className="col-5 text-right">
                                    <button className="btn-primary" type="button"
                                        onClick={onClickNext}
                                    >
                                        {t("Allaction:next")}
                                    </button>

                                </div>
                                <div className="mt-10 mb-10" />
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            }
        </Translation>
    )
};

export default SettingBookingStep2;