/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type contactReportListQueryVariables = {|
  typeOfContact?: ?string
|};
export type contactReportListQueryResponse = {|
  +contactViewer: ?{|
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |}
      |}>
    |}
  |}
|};
export type contactReportListQuery = {|
  variables: contactReportListQueryVariables,
  response: contactReportListQueryResponse,
|};
*/


/*
query contactReportListQuery(
  $typeOfContact: String
) {
  contactViewer {
    allContact(typeOfPayment: "receivable", typeOfContact: $typeOfContact, order: "ref_number") {
      edges {
        node {
          id
          refNumber
          name
          firstName
          lastName
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "typeOfContact"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": "ref_number"
    },
    {
      "kind": "Variable",
      "name": "typeOfContact",
      "variableName": "typeOfContact"
    },
    {
      "kind": "Literal",
      "name": "typeOfPayment",
      "value": "receivable"
    }
  ],
  "concreteType": "ContactNodeConnection",
  "kind": "LinkedField",
  "name": "allContact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contactReportListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactViewer",
        "kind": "LinkedField",
        "name": "contactViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contactReportListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactViewer",
        "kind": "LinkedField",
        "name": "contactViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "008de9baf9c38a6ac0e3e2c31342180f",
    "id": null,
    "metadata": {},
    "name": "contactReportListQuery",
    "operationKind": "query",
    "text": "query contactReportListQuery(\n  $typeOfContact: String\n) {\n  contactViewer {\n    allContact(typeOfPayment: \"receivable\", typeOfContact: $typeOfContact, order: \"ref_number\") {\n      edges {\n        node {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eba8e158f3e2387b63872697733132c4';

module.exports = node;
