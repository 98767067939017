import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import DailyJournal from './dailyJournal';
import DailyJournalDetail from './dailyJournalDetail';
import DailyJournalCreate from './dailyJournalCreate';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import WrapperDailyJournal from "../../document/index";
import WrapperDailyJournalAll from '../../document/daily_journal/wrapperDailyJournalAll';
class Account extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/:category/list/:status`} component={DailyJournal}/>
                <PrivateRoute exact path={`${this.props.match.url}/:category/detail/:ref_number/:ref_transaction?`} component={DailyJournalDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/create/:category?/:account_record_group?`} component={DailyJournalCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/account/daily-journal/all/`} component={WrapperDailyJournalAll}/>
                <PrivateRoute path={`${this.props.match.url}/account`} component={WrapperDailyJournal}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Account;