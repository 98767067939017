import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import numberWithCommas from "../../libs/numberWithComma";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { fetchQuery } from "relay-runtime";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import enLocale from "date-fns/locale/en";
import i18next from "i18next";

const query = graphql`
    query incomePieChartsQuery ($startDateFirst: DateTime, $endDateFirst: DateTime, $startDateSecond: DateTime, $endDateSecond: DateTime) {
        chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "4", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
            edges {
                node {
                    chartOfAccountCode
                    yearFirst
                    yearSecond
                    totalHistoricalDiffFirst
                    totalHistoricalDiffSecond
                }
            }
        }
    }
`;

class IncomePieCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],  
    };
    this.setDataRender = this.setDataRender.bind(this)
  }
  
  componentDidMount() {
    this.queryIncomeData()
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state) {
        this.queryIncomeData(); 
    }
  }

  setDataRender(data_render, chart_of_account, key, code) {
    let year_first = chart_of_account.node.yearFirst;
    let year_second = chart_of_account.node.yearSecond;
    let total_historical_diff_first = chart_of_account.node.totalHistoricalDiffFirst;
    let total_historical_diff_second = chart_of_account.node.totalHistoricalDiffSecond;
    Object.assign(data_render, {
        [key]: {
            year_first: year_first + data_render[key].year_first + total_historical_diff_first,
            year_second: year_second + data_render[key].year_second + total_historical_diff_second,
            code: code,
        }
    })
  }

  queryIncomeData() {
    fetchQuery(environment,query,
        {
            startDateFirst: this.props.state.start_date_first,
            endDateFirst: this.props.state.end_date_first,
            startDateSecond: this.props.state.start_date_second,
            endDateSecond: this.props.state.end_date_second,
        }
    ).then(props => {
        if (props) {
            let data_render = {
                [`${i18next.t("incomeChart:Common area fees")}`]: {year_first: 0, year_second: 0, code:"4100-01"},
                [`${i18next.t("incomeChart:Water supply")}`]: {year_first: 0, year_second: 0, code:"4100-04"},
                [`${i18next.t("incomeChart:Common area utilization")}`]: {year_first: 0, year_second: 0, code:"4200"},
                [`${i18next.t("incomeChart:Other Income")}`]: {year_first: 0, year_second: 0, code:"other_income"},
            };
            // let year_first_total = 0;
            // let year_second_total = 0;
            props.chartOfAccountSummaryIncomeExpense.edges.forEach((chart_of_account => {
                let code = chart_of_account.node.chartOfAccountCode;
                // year_first_total += (chart_of_account.node.yearFirst + chart_of_account.node.totalHistoricalDiffFirst);
                // year_second_total += (chart_of_account.node.yearSecond + chart_of_account.node.totalHistoricalDiffSecond);

                if (code === '4100-01') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("incomeChart:Common area fees")}`, code)

                }else if (code === '4100-04') {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("incomeChart:Water supply")}`, code)

                }else if (code.startsWith('4200')) {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("incomeChart:Common area utilization")}`, "4200")

                }else {
                    this.setDataRender(data_render, chart_of_account, `${i18next.t("incomeChart:Other Income")}`, "other_income")

                }
            }))
            const result = [];
            for (const key in data_render) {
                result.push({ revenueGroup: key, totalAmount: data_render[key].year_first });
            }
            let resultFilter = result.filter(item => item.totalAmount > 0);
            this.genChart(resultFilter)
    }})
  }
  
  genChart(chartData) {
    if (this.root) {
        // Dispose of the old root and chart
        this.root.dispose(); 
        this.chart.dispose(); 
    }   
    // PIE
    let data = chartData;

    let sumTotalAmount = 0;
    for (let i = 0; i < data.length; i++) {
      sumTotalAmount += data[i].totalAmount;
    }

    let locale = `${i18next.t("incomeChart:Locale")}`;
    let localeUse = locale === 'thLocale'? thLocale : enLocale;
    let monthCurrent =  `${!this.props.state.showMonth && this.props.state.period !== 'month' ? format(this.props.state.start_date_first, 'MMM YY', {locale: localeUse}) + " -" :'' } ${format(this.props.state.end_date_first, 'MMM YY', {locale: localeUse})}`
    let root = am5.Root.new("incomePieCharts");

    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: 120,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
      })
    );
    chart.children.unshift(am5.Label.new(root, {
      text: `${i18next.t("incomeChart:Income for")} ${monthCurrent} = ${numberWithCommas(sumTotalAmount)} ${i18next.t("incomeChart:baht")}`,
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 0,
      paddingBottom: 0
    }));

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "totalAmount",
        categoryField: "revenueGroup",
        tooltip: am5.Tooltip.new(root, {
          // ทำให้ตอน hover สามารถเลื่อนไปคลิ๊กได้
          keepTargetHover: true,
        })
      })
    );

    // hide ticks
    series.ticks.template.set("forceHidden", true);
    series.labels.template.set("forceHidden", true);

    series.get("tooltip").label.set("interactive", true);
          
    series.slices.template.setAll({
      tooltipHTML: `<b style="color: black;">{category}: {value.formatNumber('#,###.00')}</b>`,
      strokeWidth: 2,
    });

    series.get("colors").set("colors", [
      am5.color(0x1567B4),
      am5.color(0x008FCE),
      am5.color(0x00B2C8),
      am5.color(0x619AEC),
    ]);


    series.labels.template.set("tooltipText", "{revenueGroup}: {totalAmount.formatNumber('#,###.00')}");

    // Set data
    series.data.setAll(data);

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    });
    let annotator = am5plugins_exporting.Annotator.new(root, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);

    // Create legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: am5.GridLayout.new(root, {
          maxColumns: 2,
          fixedWidthGrid: true,
        }),
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    series.appear(1000, 100);
    root._logo.dispose();

    this.chart = chart;
    this.root = root;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    
    return (
      <React.Fragment>
          <div id="incomePieCharts"></div>
      </React.Fragment>
    );
  }
}

export default IncomePieCharts;
