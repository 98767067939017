/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReceiptDepositPayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type UnknownReceiveStatus = "CLEAR" | "VOID" | "WAIT" | "%future added value";
export type unknownReceiveListQueryVariables = {|
  status: string,
  first?: ?number,
  last?: ?number,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
|};
export type unknownReceiveListQueryResponse = {|
  +unknownReceiveViewer: ?{|
    +allUnknownReceive: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +updated: any,
          +amount: number,
          +status: UnknownReceiveStatus,
          +description: string,
          +voidRemark: ?string,
          +bankAccount: ?{|
            +accountNumber: string,
            +accountName: string,
            +bankName: string,
          |},
          +receiptDeposit: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +docNumber: string,
                +total: ?number,
                +issuedDate: any,
                +firstName: string,
                +lastName: string,
                +payGroup: ReceiptDepositPayGroup,
              |}
            |}>
          |},
          +receiveChannel: ?string,
          +creator: ?string,
          +refNumber: ?string,
          +contact: ?{|
            +refNumber: string,
            +name: string,
            +firstName: string,
            +lastName: string,
          |},
        |}
      |}>,
      +pageInfo: {|
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
      |},
    |},
    +summaryUnknownReceive: ?number,
  |},
  +selfProject: ?{|
    +name: string
  |},
  +myUser: ?{|
    +id: string,
    +juristic: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
    |},
  |},
|};
export type unknownReceiveListQuery = {|
  variables: unknownReceiveListQueryVariables,
  response: unknownReceiveListQueryResponse,
|};
*/


/*
query unknownReceiveListQuery(
  $status: String!
  $first: Int
  $last: Int
  $search: String
  $start_date: DateTime
  $end_date: DateTime
) {
  unknownReceiveViewer {
    allUnknownReceive(first: $first, last: $last, status: $status, search: $search, startDate: $start_date, endDate: $end_date) {
      totalCount
      edges {
        node {
          id
          docNumber
          issuedDate
          updated
          amount
          status
          description
          voidRemark
          bankAccount {
            accountNumber
            accountName
            bankName
            id
          }
          receiptDeposit {
            edges {
              node {
                id
                docNumber
                total
                issuedDate
                firstName
                lastName
                payGroup
              }
            }
          }
          receiveChannel
          creator
          refNumber
          contact {
            refNumber
            name
            firstName
            lastName
            id
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
    summaryUnknownReceive(status: $status, search: $search, startDate: $start_date, endDate: $end_date)
    id
  }
  selfProject {
    name
    id
  }
  myUser {
    id
    juristic {
      id
      firstName
      lastName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "end_date"
},
v7 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v8 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "start_date"
},
v9 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v10 = [
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "ReceiptDepositNodeConnection",
  "kind": "LinkedField",
  "name": "receiptDeposit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiptDepositNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceiptDepositNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v12/*: any*/),
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            (v14/*: any*/),
            (v23/*: any*/),
            (v24/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payGroup",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiveChannel",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "summaryUnknownReceive",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "myUser",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicNode",
      "kind": "LinkedField",
      "name": "juristic",
      "plural": false,
      "selections": [
        (v12/*: any*/),
        (v23/*: any*/),
        (v24/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "unknownReceiveListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnknownReceiveViewer",
        "kind": "LinkedField",
        "name": "unknownReceiveViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "UnknownReceiveNodeConnection",
            "kind": "LinkedField",
            "name": "allUnknownReceive",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UnknownReceiveNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnknownReceiveNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v28/*: any*/),
                          (v29/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v30/*: any*/)
            ],
            "storageKey": null
          },
          (v31/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v29/*: any*/)
        ],
        "storageKey": null
      },
      (v32/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "unknownReceiveListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnknownReceiveViewer",
        "kind": "LinkedField",
        "name": "unknownReceiveViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "UnknownReceiveNodeConnection",
            "kind": "LinkedField",
            "name": "allUnknownReceive",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UnknownReceiveNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnknownReceiveNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v28/*: any*/),
                          (v29/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v30/*: any*/)
            ],
            "storageKey": null
          },
          (v31/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v29/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      (v32/*: any*/)
    ]
  },
  "params": {
    "cacheID": "db0f6b675604d79a06c7a00c046d6b44",
    "id": null,
    "metadata": {},
    "name": "unknownReceiveListQuery",
    "operationKind": "query",
    "text": "query unknownReceiveListQuery(\n  $status: String!\n  $first: Int\n  $last: Int\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n) {\n  unknownReceiveViewer {\n    allUnknownReceive(first: $first, last: $last, status: $status, search: $search, startDate: $start_date, endDate: $end_date) {\n      totalCount\n      edges {\n        node {\n          id\n          docNumber\n          issuedDate\n          updated\n          amount\n          status\n          description\n          voidRemark\n          bankAccount {\n            accountNumber\n            accountName\n            bankName\n            id\n          }\n          receiptDeposit {\n            edges {\n              node {\n                id\n                docNumber\n                total\n                issuedDate\n                firstName\n                lastName\n                payGroup\n              }\n            }\n          }\n          receiveChannel\n          creator\n          refNumber\n          contact {\n            refNumber\n            name\n            firstName\n            lastName\n            id\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n      }\n    }\n    summaryUnknownReceive(status: $status, search: $search, startDate: $start_date, endDate: $end_date)\n    id\n  }\n  selfProject {\n    name\n    id\n  }\n  myUser {\n    id\n    juristic {\n      id\n      firstName\n      lastName\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c94a8c225b292d95f0ba6190f0e7f4ac';

module.exports = node;
