import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import {Translation} from "react-i18next";
import SearchSelect from "../../../libs/searchSelect";
import {commitMutation} from "react-relay";

const _ = require('lodash');

const $ = window.jQuery;

const query = graphql`
    query modalSettingQuery{
        selfCreditCardSetting{
            id
            bankAccount{
                id
                accountName
                accountNumber
                accountType
                bankName
            }
            fee
        }
        bankAccountViewer {
            allBankAccount(status: true, accountType_In:"current_account,saving_account,saving_deposit") {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                    }
                }
                totalCount
            }
        }
    }
`;

const mutation = graphql`
    mutation modalSettingMutation($input: CreateAndUpdateCreditCardSettingInput!){
        createAndUpdateCreditCardSetting(input: $input){
            ok
        }
    }    
`;

class ModalSettingCreditCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bank_list: [],
            id:'',
            bank:'',
            fee:'',

            loading: false,
            success:false,
            error: false,
        };
        this.handleModalShowClick = this.handleModalShowClick.bind(this);
        this.handleModalCloseClick = this.handleModalCloseClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal', () => {
            this.handleModalCloseClick();
        });
    }


    init() {
        fetchQuery(environment, query, {}).then((data) => {
            if (_.isObject(data.selfCreditCardSetting)) {
                this.setState({
                    id : data.selfCreditCardSetting.id,
                    bank: data.selfCreditCardSetting.bankAccount.id,
                    fee: data.selfCreditCardSetting.fee
                })
            }
            let bank_list = [];
            if (_.isObject(data.bankAccountViewer)) {
                let query_bank_data = _.cloneDeep(data);
                query_bank_data.bankAccountViewer.allBankAccount.edges.forEach((bank) => {

                    let account_type = 'ออมทรัพย์';
                    if (bank.node.accountType === 'CURRENT_ACCOUNT') {
                        account_type = 'กระแสรายวัน'
                    } else if (bank.node.accountType === 'FIXED_DEPOSIT') {
                        account_type = 'ฝากประจำ'
                    }
                    _.set(bank, 'node.type', 'bank');
                    _.set(bank, 'node.account_type', account_type);
                });
                bank_list = query_bank_data.bankAccountViewer.allBankAccount.edges;
            }
            this.setState({bank_list: bank_list})

        });
    }


    handleModalShowClick() {
        $(this.modal).modal('show', this.init());
    }

    handleModalCloseClick() {
        this.setState({loading: false, success:false, error:false});
        $(this.modal).modal('hide');

    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true, success:false, error:false});
        let variables = {
            input: {
                id: this.state.id,
                bankAccount: this.state.bank,
                fee: this.state.fee,
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createAndUpdateCreditCardSetting.ok) {
                        this.setState({success: true});
                    } else {
                       this.setState({error: true});
                    }

                },
                onError: (err) => {
                    this.setState({loading: false, error: true});
                },
            },
        );

    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    render() {
        return (
            <React.Fragment>

                <button type="button" className="btn btn-secondary add" style={{float: "right"}}
                        onClick={this.handleModalShowClick}>
                    <Translation>
                        {
                            t => <span>{t('credit_card:setting')}</span>
                        }
                    </Translation>
                </button>

                <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog"
                     ref={modal => this.modal = modal}
                     aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                         <form action="" onSubmit={this.onSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="col" style={{paddingLeft: 0}}>
                                    <Translation>
                                        {t=>
                                        <h5 className="modal-title">
                                            {t("cash_deposit:Credit card settings information")}
                                        </h5>}
                                    </Translation>
                                </div>
                                <button type="button" className="close" onClick={this.handleModalCloseClick}
                                        aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                            </div>
                            <div className="modal-body text-left">
                                {this.state.error &&
                                <div className="alert alert-danger text-left" role="alert">
                                    <Translation>{t=><h6>{t("credit_card:Unable to save settings data")}</h6>}</Translation>
                                </div>
                                }
                                {/*{this.state.loading && <div className="alert alert-primary text-left" role="alert">*/}
                                {/*    กำลังบันทึก</div>*/}
                                {/*}*/}
                                {this.state.success &&
                                <div className="alert alert-success text-left" role="alert">
                                    <Translation>{t=><h6>{t("cash_deposit:Save successfully")}</h6>}</Translation>
                                </div>
                                }
                                <div className="row mt-3">
                                    <Translation>{t=><label className="col-form-label col-3">{t("cash_deposit:Bank")}</label>}</Translation>
                                    <div className="col-7">
                                        <SearchSelect onChange={this.handleInputChange}
                                                  value={this.state.bank}
                                                  name={"bank"}
                                                  placeholder="รายการ"
                                                  queryObject={this.state.bank_list}
                                                  keyOfValue="id" require={true}
                                                  keyOfLabel="bankName:account_type:accountName:accountNumber"/>
                                    </div>
                                </div>
                                <div className="row mt-3 mb-3">
                                    <Translation>{t=><label className="col-form-label col-3">{t("cash_deposit:Bank fee")}</label>}</Translation>
                                    <div className="col-7">
                                        <div className="input-group mb-3">
                                            <input type="number" className="form-control"
                                                   name="fee" step="0.01"
                                                   onChange={this.handleInputChange}
                                                   value={this.state.fee}
                                                   required={true}
                                            />
                                            <div className="input-group-append">
                                                    <span className="input-group-text"
                                                          id="basic-addon2">%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Translation>
                            {t=>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={this.handleModalCloseClick}>{t("cash_deposit:Cancel")}</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.loading}>
                                        {this.state.loading && <span
                                            className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                        {t("cash_deposit:Save")}
                                    </button>
                            </div>
                            }
                            </Translation>
                        </div>
                         </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ModalSettingCreditCard;
