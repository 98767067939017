import React, { Component } from 'react'
import _ from "lodash"
import { format } from 'date-fns';
import numberWithComma from "../../../../libs/numberWithComma";
import i18next from 'i18next';


export default class dailyPaymentReceiptReportTable extends Component {

  getTotalAndListAll = (keyWord) => {
    let getKeyPayment = [
      { name: "เงินสด", num: keyWord["cash"].num, total: keyWord["cash"].total },
      { name: "เงินโอน", num: keyWord["bank_account"].num, total: keyWord["bank_account"].total },
      { name: "Cross bill", num: keyWord["cross_bank"].num, total: keyWord["cross_bank"].total },
      { name: "เช็ค", num: keyWord["cheque"].num, total: keyWord["cheque"].total },
      { name: "บัตรเครดิต", num: keyWord["credit_card"].num, total: keyWord["credit_card"].total },
      { name: "เพย์เมนท์เกตเวย์", num: keyWord["payment_gateway"].num, total: keyWord["payment_gateway"].total },
      { name: "อื่นๆ", num: keyWord["other"].num, total: keyWord["other"].total },
    ];

    let dataTotal = {
      total: 0,
      lists: 0
    }

    let sumTotals = 0
    let sumListss = 0

    for (let index = 0; index < getKeyPayment.length; index++) {
      sumTotals += parseFloat(getKeyPayment[index].total)
      sumListss += parseInt(getKeyPayment[index].num)
    }
    dataTotal = {
      total: sumTotals,
      lists: sumListss
    }

    return dataTotal

  }

  getKeyPaymentChannel = (keyWord, nameType) => {
    // เรียงลำดับใหม่ ตาม RQ175
    let getKeyPayment = [
      { name: "เงินสด", num: keyWord["cash"].num, total: keyWord["cash"].total },
      { name: "เงินโอน", num: keyWord["bank_account"].num, total: keyWord["bank_account"].total },
      { name: "Cross bill", num: keyWord["cross_bank"].num, total: keyWord["cross_bank"].total },
      { name: "เช็ค", num: keyWord["cheque"].num, total: keyWord["cheque"].total },
      { name: "บัตรเครดิต", num: keyWord["credit_card"].num, total: keyWord["credit_card"].total },
      { name: "เพย์เมนท์เกตเวย์", num: keyWord["payment_gateway"].num, total: keyWord["payment_gateway"].total },
      { name: "อื่นๆ", num: keyWord["other"].num, total: keyWord["other"].total },
    ];

    // ปรับแบบใหม่ให้เหมือน PDF
    let data = []

    for (let index = 0; index < getKeyPayment.length; index++) {
      if (nameType === "header") {
        data.push(<th key={"header1" + index} style={{ width: '10%' }}>{getKeyPayment[index].name}</th>)
      } else if (nameType === "num") {
        data.push(<td key={"header2" + index}>{parseInt(getKeyPayment[index].num) !== 0 ? getKeyPayment[index].num : "-"}</td>)
      } else {
        data.push(<td key={"header3" + index}>{parseFloat(getKeyPayment[index].total) !== 0 ? numberWithComma(getKeyPayment[index].total) : "-"}</td>)
      }
    }

    return data

    // แบบเก่า
    // let mapGetPaymentChannel = getKeyPayment.map((n, index_paymentChannelTable) => {
    //   sumLists += parseFloat(n.num)
    //   sumTotal += parseFloat(n.total)
    //   return (
    //     <tr key={index_paymentChannelTable} >
    //       <td>{n.name}</td>
    //       <td className="text-center">{parseFloat(n.num) !== 0 ? n.num : "-"}</td>
    //       <td className="text-right">
    //         {parseFloat(n.total) !== 0 ? numberWithComma(n.total) : "-"}
    //       </td>
    //     </tr>
    //   )
    // }) 
    // return mapGetPaymentChannel
  }

  render() {

    let summaryTables = JSON.parse(this.props.query.summaryTable)
    let paymentChannelTables = JSON.parse(this.props.query.paymentChannelTable)
    let getTotalAndListAll = this.getTotalAndListAll(paymentChannelTables)
    let sumOther = 0
    let sumAmount = 0
    let sumTotalListTable = []
    return (
      <React.Fragment>
        <div className="row mt-3" id="dailyPaymentReceiptReportTable">
          <div className="col-4" />
          <div className="col-8">
            <div className="card fade-up">
              {/* ปรับให้เหมือน PDF ที่สุด*/}
              <table className="table table-hover">
                <thead className="thead-light">

                  {/* แบบตาม PDF */}
                  <tr className="text-center">
                    <th style={{ width: '20%' }}>{i18next.t("receiveReport:Payment Type")}</th>
                    {this.getKeyPaymentChannel(paymentChannelTables, 'header')}
                    <th style={{ width: '10%' }}>{i18next.t("receiveReport:Total")}</th>
                  </tr>
                  <tr className="text-center">
                    <th>{i18next.t("receiveReport:Items")}</th>
                    {this.getKeyPaymentChannel(paymentChannelTables, 'num')}
                    <th>{parseInt(getTotalAndListAll.lists) !== 0 ? getTotalAndListAll.lists : "-"}</th>
                  </tr>
                  <tr className="text-center">
                    <th>{i18next.t("receiveReport:Amount")}</th>
                    {this.getKeyPaymentChannel(paymentChannelTables, 'total')}
                    <th>
                      {parseFloat(getTotalAndListAll.total) !== 0
                        ? numberWithComma(getTotalAndListAll.total.toFixed(2))
                        : "-"
                      }
                    </th>
                  </tr>

                  {/* แบบเก่า */}
                  {/* <tr>
                    <th>ประเภทการรับชำระเงิน</th>
                    <th className="text-center">รายการ</th>
                    <th className="text-right">จำนวนเงิน</th>
                  </tr>
                  // เรียกใช้ Function getKeyPaymentChannel เพื่อนำมาแสดง 
                  {this.getKeyPaymentChannel(paymentChannelTables)}
                  <tr>
                    <th className="text-center">รวม</th>
                    <th className="text-center">{parseFloat(sumListPayment) !== 0 ? sumListPayment : "-"}</th>
                    <th className="text-right">
                      {parseFloat(sumTotalPayment) !== 0
                        ? numberWithComma(sumTotalPayment.toFixed(2))
                        : "-"
                      }
                    </th>
                  </tr> */}
                </thead>
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="card fade-up">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr key={`header1`}>
                      <th rowSpan="3" className="text-center">{i18next.t("dailyPaymentReceiptReport:Order")}</th>
                      <th rowSpan="3" className="text-center">{i18next.t("dailyPaymentReceiptReport:Receipt date")}</th>
                      <th rowSpan="3" className="text-center">{i18next.t("dailyPaymentReceiptReport:Receipt number")}</th>
                      <th rowSpan="3" className="text-center">{i18next.t("dailyPaymentReceiptReport:Room/house number")}</th>
                      <th rowSpan="3" className="text-center">{i18next.t("dailyPaymentReceiptReport:Name")}</th>
                      <th colSpan={this.props.query.productAndServiceHeadTable.length + 2} className="text-center">{i18next.t("dailyPaymentReceiptReport:Type of income")}</th>
                      <th colSpan={3} className="text-center">{i18next.t("dailyPaymentReceiptReport:Payment receipt type")}</th>
                      <th rowSpan="3" className="text-right" style={{ minWidth: "110px" }}>{i18next.t("dailyPaymentReceiptReport:Total amount")}</th>
                    </tr>
                    <tr key={`header2`}>
                      {_.map(this.props.query.productAndServiceHeadTable, (productAndServiceHeadTable, index) => {
                        return (<th rowSpan="2" className="text-center" key={index}>{productAndServiceHeadTable}</th>)
                      })}
                      <th colSpan={2} className="text-center">{i18next.t("dailyPaymentReceiptReport:Other")}	</th>
                      <th rowSpan="2" className="text-center">{i18next.t("dailyPaymentReceiptReport:Type")}</th>
                      <th rowSpan="2" className="text-center">{i18next.t("dailyPaymentReceiptReport:Details")}</th>
                      <th rowSpan="2" className="text-center" style={{ minWidth: "100px" }}>{i18next.t("dailyPaymentReceiptReport:Amount")}</th>
                    </tr>
                    <tr key={`header3`}>
                      <th className="text-center">{i18next.t("dailyPaymentReceiptReport:Item")}</th>
                      <th className="text-center" style={{ minWidth: "100px" }}>{i18next.t("dailyPaymentReceiptReport:Amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(this.props.query.dataTable, (dataTable, index) => {
                      let dataTableParse = JSON.parse(dataTable);
                      return (
                        <tr key={index}>
                          <td className="text-center">{dataTableParse.no}</td>
                          <td className="text-center">{format(dataTableParse.issued_date, "DD/MM/YYYY")}</td>
                          <td className="text-center">{dataTableParse.doc_number}</td>
                          <td className="text-center">{dataTableParse.room}</td>
                          <td className="text-center">{dataTableParse.name}</td>
                          {_.map(dataTableParse.receive, (receive, index_receive) => {
                            if (index_receive === "other") {
                              return (
                                <React.Fragment key={index_receive}>
                                  <td className="text-center" style={{ width: "auto", minWidth: "300px" }}>
                                    {_.map(receive.detail, (otherDetail, index_otherDetail) => {
                                      return <div key={index_otherDetail}>{otherDetail}<br /></div>
                                    })}
                                  </td>
                                  <td className="text-center">
                                    {_.map(receive.price, (otherPrice, index_otherPrice) => {
                                      sumOther += parseFloat(otherPrice)
                                      return <span key={index_otherPrice}>{parseFloat(otherPrice || 0) !== 0 ? numberWithComma(parseFloat(otherPrice).toFixed(2)) : ""}<br /></span>
                                    })}
                                  </td>
                                </React.Fragment>
                              )
                            } else {
                              typeof (sumTotalListTable[index_receive]) !== "number"
                                ? sumTotalListTable[index_receive] = parseFloat(receive || 0)
                                : sumTotalListTable[index_receive] += parseFloat(receive || 0)
                              return (<td className="text-center" key={index_receive}>{parseFloat(receive || 0) !== 0 ? numberWithComma(parseFloat(receive).toFixed(2)) : ""}</td>)
                            }
                          })}
                          {_.map(dataTableParse.payment, (payment, index_payment) => {
                            return (
                              <React.Fragment key={index_payment}>
                                {index_payment === "type" && <td className="text-center" style={{ width: "80px", minWidth: "80px" }}>
                                  {_.map(payment, (type, index_type) => {
                                    return (<span key={index_type}>{type}<br /></span>)
                                  })}
                                </td>}
                                {index_payment === "detail" && <td className="text-center" style={{ width: "auto", minWidth: "300px" }}>
                                  {_.map(payment, (detail, index_detail) => {
                                    return (<span key={index_detail}>{detail}<br /></span>)
                                  })}
                                </td>}
                                {index_payment === "total" && <td className="text-center">
                                  {_.map(payment, (total, index_total) => {
                                    sumAmount += parseFloat(total || 0)
                                    return (
                                      <span key={index_total}>
                                        {parseFloat(total || 0) !== 0
                                          ? parseFloat(total || 0) >= 0
                                            ? numberWithComma(total) : `-${numberWithComma(parseFloat(total) * (-1))}`
                                          : ""}<br />
                                      </span>)
                                  })}
                                </td>}
                              </React.Fragment>
                            )
                          })}
                          <td className="text-center">{numberWithComma(dataTableParse.amount)}</td>
                        </tr>
                      )
                    })}
                    {this.props.state.all_item < this.props.state.num_record &&
                      <tr>
                        <td colSpan={5} className="font-weight-bold">{i18next.t("dailyPaymentReceiptReport:Sum")}</td>
                        {_.map(summaryTables, (summary, index_summaryTables) => {
                          return (
                            <React.Fragment key={index_summaryTables}>
                              {index_summaryTables === "other"
                                ? <React.Fragment>
                                  <td />
                                  <td className="text-center font-weight-bold">{sumOther !== 0 ? numberWithComma(sumOther.toFixed(2)) : "-"}</td>
                                </React.Fragment>
                                : index_summaryTables === "amount"
                                  ? <React.Fragment>
                                    <td colSpan={3} />
                                    <td className="text-center font-weight-bold">{sumAmount !== 0 ? numberWithComma(sumAmount.toFixed(2)) : "-"}</td>
                                  </React.Fragment> : <td className="text-center font-weight-bold">{sumTotalListTable[index_summaryTables] !== 0 ? numberWithComma(sumTotalListTable[index_summaryTables].toFixed(2)) : "-"}</td>
                              }
                            </React.Fragment>
                          )
                        })}
                      </tr>
                    }
                    <tr>
                      <td colSpan={5} className="font-weight-bold">{i18next.t("dailyPaymentReceiptReport:Total")}</td>
                      {_.map(summaryTables, (summary, index_summaryTables) => {
                        return (
                          <React.Fragment key={index_summaryTables}>
                            {index_summaryTables === "other"
                              ? <React.Fragment>
                                <td />
                                <td className="text-center font-weight-bold">{parseFloat(summary) !== 0 ? numberWithComma(parseFloat(summary).toFixed(2)) : "-"}</td>
                              </React.Fragment>
                              : index_summaryTables === "amount"
                                ? <React.Fragment>
                                  <td colSpan={3} />
                                  <td className="text-center font-weight-bold">{parseFloat(summary) !== 0 ? numberWithComma(parseFloat(summary).toFixed(2)) : "-"}</td>
                                </React.Fragment> : <td className="text-center font-weight-bold">{parseFloat(summary) !== 0 ? numberWithComma(parseFloat(summary).toFixed(2)) : "-"}</td>
                            }
                          </React.Fragment>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
