import React, { Component } from 'react';
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Wrapper from "../../components/wrapper";
import { Link } from "react-router-dom";
import _ from "lodash";
import { getMonth, getYear } from "date-fns";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import SearchSelect from "../../libs/searchSelect";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import { Redirect } from 'react-router';
import { Translation } from "react-i18next";
import i18n from "i18next";

const query = graphql`
    query waterMeterCreateHistoryQuery($id: ID!) {
        allResidential{
            edges{
                node{
                    id
                    name
                }
            }
        }
        
        waterMeterUnit(id: $id){
            id
            issuedDate
            meterValue
            initialChangeMeterValue
            residential{
                id
            }
            remark
            added
            editDate
        }
    }
`;

const mutation = graphql`
    mutation waterMeterCreateHistoryMutation($input: CreateChangeWaterMeterInput!){
        createChangeWaterMeter(input: $input){
            ok
            warningText
        }
    }
`;


class WaterMeterCreateHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectToList: false,
            residential_list: [],
            residential: "",
            date: new Date(),
            edit_date: new Date(),
            unit: 0,
            initialUnit: 0,
            remark: "",
            mode_edit: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        fetchQuery(environment, query, { id: this.props.match.params.id || false }).then(data => {
            if (_.isObject(data.allResidential)) {
                this.setState({ residential_list: data.allResidential.edges });
            }
            if (_.isObject(data.waterMeterUnit)) {
                this.setState({
                    residential: data.waterMeterUnit.residential.id,
                    date: new Date(data.waterMeterUnit.issuedDate),
                    edit_date: new Date(data.waterMeterUnit.editDate),
                    unit: data.waterMeterUnit.meterValue,
                    initialUnit: data.waterMeterUnit.initialChangeMeterValue,
                    remark: data.waterMeterUnit.remark,
                    mode_edit: true,
                });
            }
        })
    }

    handleChange(e) {

        if (e.target.name === "residential") {
            let data = _.cloneDeep(this.props.location?.state?.data?.allResidential?.edges)
            let filter = _.find(data, ex => ex?.node?.id === e.target.value)
            let dataMeterChkInVoice = _.find(filter?.node?.waterMeterUnit?.edges, ex => ex.node.month === parseInt(new Date().getMonth() + 1))
            if (dataMeterChkInVoice?.node?.invoiceStatus) {
                Swal.fire('ไม่สามารถเลือกห้องนี้ได้' ,  "เนื่องจากห้องนี้ได้ทำการออกใบแจ้งหนี้เดือนปัจจุบันไปแล้ว" ,'error').then((r)=> {
                    this.setState({
                        [e.target.name]: []
                    });
                })
            } else {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
 
    }
    onSubmit(e) {
        e.preventDefault();

        this.setState({ loading: true });
        let variables = {
            input: {
                residential: this.state.residential,
                year: getYear(this.state.date),
                month: getMonth(this.state.date) + 1,
                unit: this.state.unit,
                initialUnit: this.state.initialUnit,
                remark: this.state.remark,
                editDate: this.state.edit_date,
            }
        };

        if (parseFloat(this.state.initialUnit) > parseFloat(this.state.unit)) {
            this.setState({ loading: false });
            Swal.fire(i18n.t('meter:The initial meter reading is greater than the current reading. Unable to record.'), '', 'error')
            return;
        } 
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({ loading: false });
                    if (response.createChangeWaterMeter.ok) {
                        Swal.fire(i18n.t('meter:Save successfully'), '', 'success').then(() => {
                            this.setState({ redirectToList: true });
                        });
                    } else {
                        Swal.fire(i18n.t('meter:Failed to save'), response.createChangeWaterMeter.warningText, 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({ loading: false });
                    Swal.fire('Error!', i18n.t('meter:Please try again'), 'warning');
                },
            },
        )

    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to={this.props.match.params.id ? "/accounting/water-meter/history" : "/accounting/water-meter/record"} />;
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />

                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="invoice-list">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to={this.props.match.params.id ? "/accounting/water-meter/history" : "/accounting/water-meter/record"}>
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            {t('meter:Water meter change record')}
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <form onSubmit={this.onSubmit} id="create_change_water_meter">
                                                <div className="form-group row">
                                                    <div className="col-md-6">
                                                        <label>{t('meter:Room No.')}</label>
                                                        <SearchSelect onChange={this.handleChange}
                                                            value={this.state.residential}
                                                            name="residential" placeholder="รายการ"
                                                            queryObject={this.state.residential_list}
                                                            keyOfValue="id" require={true}
                                                            disabled={this.state.mode_edit}
                                                            keyOfLabel="name" />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>{t('meter:Recording date')}</label>
                                                        <DatePickerAdapter name="edit_date"
                                                            className="form-control"
                                                            selected={this.state.edit_date}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-md-3">
                                                        <label>{t('meter:Month/Year')}</label>
                                                        <DatePickerAdapter name="date"
                                                            showMonthYearPicker={true}
                                                            className="form-control"
                                                            dateFormat="MM/yyyy"
                                                            selected={this.state.date}
                                                            disable={this.state.mode_edit}
                                                            onChange={this.handleChange} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>{t('meter:New meter start')}</label>
                                                        <input type="number" className="form-control" name="initialUnit"
                                                            value={this.state.initialUnit} onChange={this.handleChange} required={true}/>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>{t('meter:New current meter')}</label>
                                                        <input type="number" className="form-control" name="unit"
                                                            value={this.state.unit} onChange={this.handleChange} required={true} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-md-8 col-sm-6">
                                                        <label>{t('meter:Note')}</label>
                                                        <textarea cols="" rows="4" className="form-control" name="remark"
                                                            value={this.state.remark} onChange={this.handleChange} required={true}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="row mt-3 fade-up">
                                                <div className="col text-right">
                                                    <div className="btn-group mr-2">
                                                        <Link
                                                            to="/accounting/water-meter/record">
                                                            <button type="button"
                                                                className="btn btn-secondary add">
                                                                {t('meter:Cancel')}
                                                            </button>
                                                        </Link>
                                                        <button type="submit" form="create_change_water_meter"
                                                            className="btn btn-primary add ml-3" disabled={this.state.loading}>
                                                            {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                            {t('meter:Record')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>
        );
    }
}

export default WaterMeterCreateHistory;
