import '../styles/main.scss';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import Invoice from './invoice';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { fetchQuery } from "relay-runtime";
import { QueryRenderer } from "react-relay";
import { Translation } from 'react-i18next';
import Loading from "../../libs/loading";
import InvoiceTempleteSingha from './invoiceTempleteSingha';
import InvoiceTemplete3 from './invoiceTemplete3';
import InvoiceTemplete4 from './invoiceTemplete4';
import InvoiceTemplete5 from "./invoiceTemplete5"

const query = graphql`
    query wrapperInvoiceQuery($id: ID!, $id_in: [String]) {
        selfProject{
            id
            name
            nameTh
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            bankCompCode
            bankServiceCode
            taxIdNumber
            typeOfProject
            prompayQr
            prompayQrShow
        }
        invoiceViewer{
            groupByInvoice(id_In: $id_in)
        }
        userConfig {
            id languages
        }
        invoice(id: $id) {
            id
            docNumber
            remark
            status
            total
            taxType
            topupCrossbill

            firstName
            lastName
            name
            taxIdNumber
            email
            phone
            
            address
            country
            province
            district
            city
            postalCode

            payGroup
            
            contact {
                refNumber
                name
                taxIdNumber
                firstName
                lastName
                phone
                email
                typeOfCompany
                typeOfSupplier
                nameBranch
                typeOfContact
                typeOfPayment
                
                residential {
                    id
                    name
                    size
                    ownershipRatio
                    unitNumber
                    residentialHouseholder(type:"renter"){ 
                        edges{
                            node{
                                id
                                tenant{
                                    nameTitle
                                    firstName
                                    lastName
                                    phone
                                    email
                                }
                            }
                        }
                    }
                }
            }
            docNumber
            issuedDate
            dueDate
            creator
            remark
            historyShow
            transaction{
                edges{
                    node{
                        id
                        find {
                            edges {
                                node{
                                    id
                                    group {
                                        fineDate
                                    }
                                }
                            }
                        }
                        productAndService {
                            productCode
                        }
                        chartOfAccount{
                            chartOfAccountCode
                        }
                        description
                        unitItems
                        price
                        preTaxAmount
                        discount
                        vat
                        whtRate
                        whtRatePercent
                        total
                        receiptDepositTransactionUsed{
                            edges{
                                node{
                                    id
                                    amount
                                    coupleUse
                                    receiptDepositTransaction{
                                        couple
                                    }
                                }
                            }
                        }
                        keycardPerInvoice{
                            edges{
                                node {
                                    id
                                    numberKey{
                                        id
                                        noNumber
                                        status
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        checkInvoiceTemplate{
        edges{
            node{
                id 
                templateFirst
                descriptionFirst
                topicThFirst
                topicEnFirst
                templateSecond
                descriptionSecond
                topicThSecond,
                topicEnSecond,
                templateThird,
                descriptionThird,
                topicThThird,
                topicEnThird,
                templateFourth
                descriptionFourth
                topicThFourth,
                topicEnFourth,
                templateFifth
                descriptionFifth
                topicThFifth
                topicEnFifth
                
            }
        }
    }
    }
`;

class WrapperInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type: 'all',
            template:'',
            templateSelected:'template1',//default,
            isMobileView: false,
            langTH1:'',
            langEN1:'',
            langTH2:'',
            langEN2:'',
            langTH3:'',
            langEN3:'',
            langTH4:'',
            langEN4:'',
            langTH5:'',
            langEN5:''
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getTempateName = this.getTempateName.bind(this)
    }
    componentDidMount() {
        this.getData()
        this.checkDeviceType()
    }

    getData() {
        fetchQuery(environment, query,{ id: this.props.match.params.id, id_in: this.props.match.params.id }).then(data => {
            let result = "";
            if(data?.checkInvoiceTemplate?.edges.length > 0){
                if(data?.checkInvoiceTemplate?.edges[0]?.node?.templateFirst === true){
                    result = 'template1'
                }else if(data?.checkInvoiceTemplate?.edges[0]?.node?.templateSecond === true){
                    result = 'template2'
                }else if(data?.checkInvoiceTemplate?.edges[0]?.node?.templateThird === true){
                    result =  'template3'
                }else if(data?.checkInvoiceTemplate?.edges[0]?.node?.templateFourth === true){
                    result =  'template4'
                }else if(data?.checkInvoiceTemplate?.edges[0]?.node?.templateFifth === true){
                    result =  'template5'
                }else{
                    result = 'template1' //default
                }
            }else{
                result = 'template1' //default
            }

           this.setState({
            templateSelected: result,
            langTH1: data.checkInvoiceTemplate?.edges[0]?.node?.topicThFirst,
            langEN1: data.checkInvoiceTemplate?.edges[0]?.node?.topicEnFirst,
            langTH2: data.checkInvoiceTemplate?.edges[0]?.node?.topicThSecond,
            langEN2: data.checkInvoiceTemplate?.edges[0]?.node?.topicEnSecond,
            langTH3: data.checkInvoiceTemplate?.edges[0]?.node?.topicThThird,
            langEN3: data.checkInvoiceTemplate?.edges[0]?.node?.topicEnThird,
            langTH4: data.checkInvoiceTemplate?.edges[0]?.node?.topicThFourth,
            langEN4: data.checkInvoiceTemplate?.edges[0]?.node?.topicEnFourth,
            langTH5: data.checkInvoiceTemplate?.edges[0]?.node?.topicThFifth,
            langEN5: data.checkInvoiceTemplate?.edges[0]?.node?.topicEnFifth
           })
        }).catch(err => {
            alert(err)
        })
    }

    getTempateName(val){
        let result; 
        switch (val) {
            case "template1":
              result = 'รูปแบบที่ 1'
              break;
            case "template2":
              result = 'รูปแบบที่ 2'
              break;
            case "template3":
              result = 'รูปแบบที่ 3'
              break;
            case "template4":
              result = 'รูปแบบที่ 4'
              break;
            case "template5":
              result = 'รูปแบบที่ 5'
              break;
            default:
              result = 'รูปแบบที่ 1'
          }
          return result;
    }
    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    checkDeviceType(){
        const checkForDevice =  window.innerWidth < 640;
        this.setState({
            isMobileView : checkForDevice
        })
    }

    render() {

        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Invoice Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeAll"
                                    onChange={this.handleInputChange}
                                    value="all"
                                    checked={this.state.check_type === 'all'} />
                                <Translation>
                                    {t => <label className="form-check-label" htmlFor="checkTypeAll">
                                        {t("document:All")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeManuscript"
                                    value="manuscript"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_type === 'manuscript'} />
                                <Translation>
                                    {t =>
                                        <label className="form-check-label" htmlFor="checkTypeManuscript">
                                            {t("document:Original_")}
                                        </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_type" id="checkTypeCopy"
                                    value="copy"
                                    onChange={this.handleInputChange}
                                    checked={this.state.check_type === 'copy'} />
                                <Translation>
                                    {t =>
                                        <label className="form-check-label" htmlFor="checkTypeCopy">
                                            {t("document:Copy_")}
                                        </label>}
                                </Translation>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            {
                            !this.state.isMobileView ? 
                            <>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate1"
                                    onChange={this.handleInputChange}
                                    value="template1" disabled
                                    checked={this.state.templateSelected === 'template1'} />
                                <label className="form-check-label" htmlFor="checkTypeAll">
                                    รูปแบบที่ 1
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate2"
                                    value="template2" disabled
                                    onChange={this.handleInputChange}
                                    checked={this.state.templateSelected === 'template2'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 2
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate3"
                                    value="template3" disabled
                                    onChange={this.handleInputChange}
                                    checked={this.state.templateSelected === 'template3'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 3
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate4"
                                    value="template4" disabled
                                    onChange={this.handleInputChange}
                                    checked={this.state.templateSelected === 'template4'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 4
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                    name="check_templete" id="handleChangeTemplate5"
                                    value="template5" disabled
                                    onChange={this.handleInputChange}
                                    checked={this.state.templateSelected === 'template5'} />
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    รูปแบบที่ 5
                                </label>
                            </div></> : 
                               <div className="form-check m-1">
                                   <input className="form-check-input" type="radio"
                                       name="check_templete" id="handleChangeTemplate1"
                                       value="" disabled
                                       checked={true} />
                                   <label className="form-check-label" htmlFor="checkTypeAll">
                                      {this.getTempateName(this.state.check_type)}
                                   </label>
                               </div>
                            }
                           
                            
                        </div>

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{ id: this.props.match.params.id, id_in: this.props.match.params.id }}
                    render={({ error, props }) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                role="alert">{error.message}</div>;
                        } else if (props) {
                            
                            let result = ""

                            if(props.checkInvoiceTemplate?.edges.length > 0){
                                if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateFirst === true){
                                    result = 'template1'
                                }else if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateSecond === true){
                                    result = 'template2'
                                }else if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateThird === true){
                                    result =  'template3'
                                }else if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateFourth === true){
                                    result =  'template4'
                                }else if(props?.checkInvoiceTemplate?.edges[0]?.node?.templateFifth === true){
                                    result =  'template5'
                                }else {
                                    result = 'template1' //default
                                }
                            }else{
                                result = 'template1'  
                            }
                            
                            return (
                                    result === "template1" ?
                                    <Invoice
                                        query={this.props.location.state}
                                        history={props.invoiceViewer.groupByInvoice}
                                        selfProject={props.selfProject} invoice={props.invoice}
                                        check_type={this.state.check_type} key={props.invoice.id + "-index"}
                                        invoiceNumber={atob(props.invoice.id).split(':')[1]}
                                        userConfig={props.userConfig}
                                        page='WrapperInvoice'
                                        langTitleTH = {this.state.langTH1}
                                        langTitleEN = {this.state.langEN1}
                                    />
                                    : result === "template2" ?
                                    <InvoiceTempleteSingha
                                        query={this.props.location.state}
                                        history={props.invoiceViewer.groupByInvoice}
                                        selfProject={props.selfProject} invoice={props.invoice}
                                        check_type={this.state.check_type} key={props.invoice.id + "-index"}
                                        invoiceNumber={atob(props.invoice.id).split(':')[1]}
                                        userConfig={props.userConfig}
                                        page='WrapperInvoice'
                                        findUniNo={props?.invoice?.contact?.residential?.unitNumber}
                                        langTitleTH = {this.state.langTH2}
                                        langTitleEN = {this.state.langEN2}
                                    />

                                    : result === "template3" ?
                                    <InvoiceTemplete3
                                        query={this.props.location.state}
                                        history={props.invoiceViewer.groupByInvoice}
                                        selfProject={props.selfProject} invoice={props.invoice}
                                        check_type={this.state.check_type} key={props.invoice.id + "-index"}
                                        invoiceNumber={atob(props.invoice.id).split(':')[1]}
                                        userConfig={props.userConfig}
                                        page='WrapperInvoice'
                                        langTitleTH = {this.state.langTH3}
                                        langTitleEN = {this.state.langEN3}
                                    />
                                    : result === "template4" ?
                                    <InvoiceTemplete4
                                        query={this.props.location.state}
                                        history={props.invoiceViewer.groupByInvoice}
                                        selfProject={props.selfProject} invoice={props.invoice}
                                        check_type={this.state.check_type} key={props.invoice.id + "-index"}
                                        invoiceNumber={atob(props.invoice.id).split(':')[1]}
                                        userConfig={props.userConfig}
                                        page='WrapperInvoice'
                                        langTitleTH = {this.state.langTH4}
                                        langTitleEN = {this.state.langEN4}
                                    /> 
                                    : result === "template5" ?
                                    <InvoiceTemplete5
                                        query={this.props.location.state}
                                        history={props.invoiceViewer.groupByInvoice}
                                        selfProject={props.selfProject} invoice={props.invoice}
                                        check_type={this.state.check_type} key={props.invoice.id + "-index"}
                                        invoiceNumber={atob(props.invoice.id).split(':')[1]}
                                        userConfig={props.userConfig}
                                        page='WrapperInvoice'
                                        langTitleTH = {this.state.langTH5}
                                        langTitleEN = {this.state.langEN5}
                                    /> 
                                    :
                                    <Invoice
                                        query={this.props.location.state}
                                        history={props.invoiceViewer.groupByInvoice}
                                        selfProject={props.selfProject} invoice={props.invoice}
                                        check_type={this.state.check_type} key={props.invoice.id + "-index"}
                                        invoiceNumber={atob(props.invoice.id).split(':')[1]}
                                        userConfig={props.userConfig}
                                        page='WrapperInvoice'
                                        langTitleTH = {this.state.langTH1}
                                        langTitleEN = {this.state.langEN1}
                                    />
                            )
                           
                        }
                        return <div className="text-center"><Loading /></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperInvoice;
