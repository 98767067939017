import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t=>
                        <ul>
                            <li>
                                <NavLink to="/accounting/electric-meter/record">
                                    {t("electric_meter_record:Record")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/electric-meter/compare">
                                    {t("electric_meter_record:Compare")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/electric-meter/history">
                                    {t("electric_meter_record:History")}
                                </NavLink>
                            </li>
                        </ul>}
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;