import React from 'react';
import HistoryDetailView from './historyDetailView';
import {graphql} from "babel-plugin-relay/macro";
import ComponentPagination from "../../libs/componentPagination";

const historyDetail = graphql`
    query historyDetailQuery($id: ID!,$search: String, $start_date : Date, $end_date : Date, $order:String,$first: Int, $last: Int) {
        allResidentialHistory(residential_Id:$id, search:$search, startDate:$start_date, endDate:$end_date, order:$order, first: $first, last: $last, type_In:"resident,renter,agent"){
            totalCount
            edges{
                node{
                    id
                    nameResidential
                    residential{
                        id
                        name
                    }
                    firstName
                    lastName
                    countries
                    phone
                    email
                    remark
                    type
                    status
                    liveing
                    quit
                    added
                }
            }
        }

    }
`;

class HistoryDetail extends ComponentPagination {
    render() {
        return (
            <React.Fragment>
                <HistoryDetailView
                        historyDetail={historyDetail}
                        id={this.props.match.params.id}
                        first={this.state.first}
                        last={this.state.last}
                        changePage={this.changePage}
                    />
            </React.Fragment>

        );
    }
}

export default HistoryDetail;
