/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type chartOfAccountFormQueryVariables = {|
  code?: ?string
|};
export type chartOfAccountFormQueryResponse = {|
  +nextChartOfAccount: ?string
|};
export type chartOfAccountFormQuery = {|
  variables: chartOfAccountFormQueryVariables,
  response: chartOfAccountFormQueryResponse,
|};
*/


/*
query chartOfAccountFormQuery(
  $code: String
) {
  nextChartOfAccount(code: $code)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      }
    ],
    "kind": "ScalarField",
    "name": "nextChartOfAccount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "chartOfAccountFormQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "chartOfAccountFormQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0ac96d61401b51b879300ae32a071561",
    "id": null,
    "metadata": {},
    "name": "chartOfAccountFormQuery",
    "operationKind": "query",
    "text": "query chartOfAccountFormQuery(\n  $code: String\n) {\n  nextChartOfAccount(code: $code)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1089f675043fa0a1cb58a358308b98dd';

module.exports = node;
