import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import ReceivableClassifiedPDFRenderTable from './receivableClassifiedReportPDFRenderTable';
import './receiveableClassifiedReportPDF.scss'

class ReceivableClassifiedReportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            list_product_code : "",
            summary_total : "",
            summary_paid : "",
            summary_not_pay : "",
            search_status : "",
            month_list : "",
            last_index : "",
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data,
            list_product_code: this.props.location?.state?.list_product_code,
            summary_total: this.props.location?.state?.summary_total,
            summary_paid: this.props.location?.state?.summary_paid,
            search_status: this.props.location?.state?.search_status,
            month_list: this.props.location?.state?.month_list,
            last_index: this.props.location?.state?.last_index,
        })
    }
    render() {
        return (
            <React.Fragment>
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }

                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Receivable Classified Report</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        
                        </Helmet>
                        <div id="receivableClassifiedPDF">
                            <div className="print-top-menu">
                                <div className="logo">
                                    <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                </div>
                                <div className="print" onClick={() => window.print()}>
                                    PRINT
                                </div>
                            </div>
                            <ReceivableClassifiedPDFRenderTable 
                                data = {this.state.data}
                                list_product_code = {this.state.list_product_code}
                                summary_total = {this.state.summary_total}
                                summary_paid = {this.state.summary_paid}
                                summary_not_pay = {this.state.summary_not_pay}
                                search_status = {this.state.search_status}  
                                month_list = {this.state.month_list}  
                                last_index = {this.state.last_index}                        
                            />
                        </div>
            </React.Fragment>
    
        )
    }
}
export default ReceivableClassifiedReportPDF;