import { graphql } from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../../env/environment";

const mutation = graphql`
    mutation createProjectMutation ($input: CreateProjectInfoInput!) {
    createProjectInfo (input: $input) {
        ok
        message
    }
}
`

export default function createProject(variables, callback, error_callback) {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          callback(response.createProjectInfo.ok)
        },
        onError: (err) => {
            error_callback(err);
        },
      },
    )
  }