import React, { Component } from 'react';
import TrackingDisplayBlock from './trackingDisplayBlock';
import TrackingDisplayBarchart from './trackingDisplayBarchart';
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import './trackingDisplay.scss';
import getApiRoot from '../libs/getAPIRoot';
import { format } from "date-fns";
import i18next from 'i18next';
import { Translation } from 'react-i18next';

class DataAnalyticTrackingMenuList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: [],
            menuList: [],
            currentDate: new Date(),
            filterList: [],
            mountFilterList: [],
            startDate: format(new Date(), "YYYY-MM-DD"),
            endDate: format(new Date(), "YYYY-MM-DD"),
            dataForDisplayBlock: [],
            dataForBarChart: "",
            monthDateSelected: "",
            menuName: "home",
            dateHeaderChart: "1 วัน",
            category: this.props.category,
        };
    }

    componentWillMount() {
        let endDate = format(this.state.currentDate, "YYYY-MM-DD");
        let startDate = this.getPreviousDay(endDate, 1)

        this.getMenuList();
        this.getFilterList();
        this.getDataBlock(startDate, endDate);
        this.getDataChart(startDate, endDate);
    }

    getServiceType(category) {
        switch (category) {
            case "tenantapp":
                return "all_tenant_app";
            case "juristicapp":
                return "all_juristic_app";
            case "juristicweb":
                return "all_juristic_web";
            default:
                return "all_tenant_app";
        }
    }

    getMenuList() {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${getApiRoot()}account/tracking_type`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({ menuList: result.data });
            })
            .catch(error => console.log('error', error));
    }

    getFilterList() {
        const monthYearArray = [];
        const currentDate = format(this.state.currentDate, "YYYY-MM-DD")
        const startMonth = new Date("2023-07-01").getMonth();
        const startYear = new Date("2023-07-01").getFullYear();
        const endMonth = new Date(currentDate).getMonth();
        const endYear = new Date(currentDate).getFullYear();
        for (let year = startYear; year <= endYear; year++) {
            const start = (year === startYear) ? startMonth : 0;
            const end = (year === endYear) ? endMonth : 11;

            for (let month = start; month <= end; month++) {
                const monthNameTh = new Date(year, month, 1).toLocaleString('th-TH', { month: 'long' });
                const monthNameEn = new Date(year, month, 1).toLocaleString('en-En', { month: 'long' });
                monthYearArray.push({ th: `${monthNameTh} ${year}`, en: `${monthNameEn} ${year}` });
            }
        }

        this.setState({ mountFilterList: monthYearArray })
    }

    filterDate(dayFilter, chartDateHeader) {
       let dateHeader = chartDateHeader
       if(dayFilter.en || dayFilter.th) {
            dateHeader = localStorage.getItem("language") === "en" ? chartDateHeader.en : chartDateHeader.th
       }
        const currentDate = format(this.state.currentDate, "YYYY-MM-DD");
        let startDate = "";
        let monthDateSelected = "";
        switch (dayFilter) {
            case "1day":
                startDate = this.getPreviousDay(currentDate, 1)
                break;
            case "3day":
                startDate = this.getPreviousDay(currentDate, 3)
                break;
            case "7day":
                startDate = this.getPreviousDay(currentDate, 7)
                break;
            case "30day":
                startDate = this.getPreviousDay(currentDate, 30)
                break;
            default:
                monthDateSelected = this.monthToDates(dayFilter)
        }

        this.setState({
            startDate: startDate,
            monthDateSelected: "",
            dateHeaderChart: dateHeader,
        }, () => {
            this.getDataBlock(this.state.startDate, this.state.endDate)
            this.getDataChart(this.state.startDate, this.state.endDate)
        })

        if (monthDateSelected) {
            this.setState({
                monthDateSelected: monthDateSelected,
            }, () => {
                this.getDataBlock(this.state.monthDateSelected[0], this.state.monthDateSelected[1])
                this.getDataChart(this.state.monthDateSelected[0], this.state.monthDateSelected[1])
            })
        }
    }

    filterButton(btn) {
        this.setState({
            menuName: btn,
            dateHeaderChart: "1 วัน",
        }, () => {
            let endDate = format(this.state.currentDate, "YYYY-MM-DD");
            let startDate = this.getPreviousDay(endDate, 1)

            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            let webUrl = "https://svmsupport.silverman.cloud/";

            let urlTracking1 = `${webUrl}account/tracking_botton?service=${this.getServiceType(this.props.category)}&start_date=${startDate}&end_date=${endDate}&menu=${btn}`;
            let urlTracking2 = `${webUrl}account/tracking_date?service=${this.getServiceType(this.props.category)}&start_date=${startDate}&end_date=${endDate}&menu=${btn}`;

            fetch(urlTracking1, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ dataForDisplayBlock: result.data })
                }
                )
                .catch(error => console.log('error', error));

            fetch(urlTracking2, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ dataForBarChart: result })
                }
                )
                .catch(error => console.log('error', error));
        })


    }

    getPreviousDay(dateString, dayFilter) {
        const currentDate = new Date(dateString);
        currentDate.setDate(currentDate.getDate() - dayFilter);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    monthToDates(monthStr) {
        const thaiMonthNames = [
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน",
            "กรกฎาคม",
            "สิงหาคม",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม"
        ];
        
        const englishMonthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        
        const [month, year] = localStorage.getItem("language") === "en" ? monthStr.th.split(' ')  : monthStr.th.split(' ');

        const monthIndex = localStorage.getItem("language") === "en" ? englishMonthNames.indexOf(month) : thaiMonthNames.indexOf(month);

        if (monthIndex === -1 || isNaN(year)) {
            return null;
        }

        const firstDate = new Date(year, monthIndex, 1);
        const nextMonthFirstDate = new Date(year, monthIndex + 1, 1);

        const lastDate = new Date(nextMonthFirstDate.getTime() - 86400000);

        const firstDateFormatted = format(firstDate, 'YYYY-MM-DD');
        const lastDateFormatted = format(lastDate, 'YYYY-MM-DD');

        return [firstDateFormatted, lastDateFormatted];
    }

    getDataBlock(startDate, endDate) {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        let webUrl = "https://svmsupport.silverman.cloud/";
        let urlTracking = `${webUrl}account/tracking_botton?service=${this.getServiceType(this.props.category)}&start_date=${startDate}&end_date=${endDate}&menu=${this.state.menuName}`;
        fetch(urlTracking, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({ dataForDisplayBlock: result.data })
            }
            )
            .catch(error => console.log('error', error));
    }

    getDataChart(startDate, endDate) {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        let webUrl = "https://svmsupport.silverman.cloud/";
        let urlTracking = `${webUrl}account/tracking_date?service=${this.getServiceType(this.props.category)}&start_date=${startDate}&end_date=${endDate}&menu=${this.state.menuName}`;
        
        fetch(urlTracking, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({ dataForBarChart: result })
            }
            )
            .catch(error => console.log('error', error));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.category !== prevProps.category) {
            this.setState({
                category: this.props.category,
                dateHeaderChart: "1 วัน",
            }, () => {
                const endDate = format(this.state.currentDate, "YYYY-MM-DD");
                const startDate = this.getPreviousDay(endDate, 1);
                this.getDataBlock(startDate, endDate);
                this.getDataChart(startDate, endDate);
            });
        }
    }

    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {

        return (
            <Translation>
                {t =>
                    <div id="dataAnalyticTrackingMenu">
                        <div className="btnMenu">
                            <p className='textMenu'>{i18next.t("dataAnalyticTrackingMenu:Menu")} {this.capitalizeFirstLetter(this.state.menuName)}</p>
                            <div className="btnMenuSelected">
                                <Dropdown>
                                    {/* dropdown-export  */}
                                    <DropdownToggle id="dropdown-item-button" className="mr-2" bsPrefix='dropdown-toggle'>
                                    {i18next.t("dataAnalyticTrackingMenu:Select menu")}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {this.state.menuList.map((menu, index) => (
                                            <Dropdown.Item key={index} onClick={() => this.filterButton(menu.botton)}>
                                                {menu.botton}
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown>
                                    <DropdownToggle id="dropdown-item-button" className="mr-2" bsPrefix='dropdown-toggle'>
                                    {i18next.t("dataAnalyticTrackingMenu:Select time range")}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <Dropdown.Item onClick={() => this.filterDate('1day', '1 วัน')}>
                                            1 {i18next.t("dataAnalyticTrackingMenu:Day(s)")}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.filterDate('3day', '3 วัน')}>
                                            3 {i18next.t("dataAnalyticTrackingMenu:Day(s)")}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.filterDate('7day', '7 วัน')}>
                                            7 {i18next.t("dataAnalyticTrackingMenu:Day(s)")}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.filterDate('30day', '30 วัน')}>
                                            30 {i18next.t("dataAnalyticTrackingMenu:Day(s)")}
                                        </Dropdown.Item>
                                        {this.state.mountFilterList.map((monthFilter, index) => (
                                            <Dropdown.Item key={index} onClick={() => this.filterDate(monthFilter, monthFilter)}>
                                                {
                                                    localStorage.getItem("language") === "en" ?
                                                        monthFilter.en :
                                                        monthFilter.th
                                                }
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                <input
                                    className='showSelectedDate'
                                    value={this.state.dateHeaderChart}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className='chartBox'>
                            {this.state.dataForBarChart.status ?
                                <div className="chartContainer">
                                    <TrackingDisplayBarchart data={this.state.dataForBarChart} dateHeaderChart={this.state.dateHeaderChart} />
                                </div>
                                :
                                <div className='noDataAtThisDate'>
                                    {i18next.t("dataAnalyticTrackingMenu:No data available for the selected time period.")}
                                </div>
                            }
                            <div className="topContainer">
                                <TrackingDisplayBlock data={this.state.dataForDisplayBlock} />
                            </div>
                        </div>
                    </div>
                }
            </Translation>

        )
    }
}

export default DataAnalyticTrackingMenuList;