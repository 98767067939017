import React, {Component} from 'react';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Wrapper from '../components/wrapper';
import WrapperContent from '../components/wrapper/wrapperContent';
import DeveloperTopMenuNavigation from "./developerTopMenuNavigation";
import {fetchQuery} from "relay-runtime";
import environment from "../env/environment";
import queryCheckSiteOnline from "../libs/checkSiteOnline"
import Loading from "../libs/loading"
import AlertWithholdPage from '../libs/alertWithholdPage';
import DeveloperPopup from "./developerPopup"

const _ = require('lodash');
class SettingDashboard extends Component {

    constructor(props){
        super(props)

        this.state = {
            loading: false,
            checkOnline: true,
            codename_developer: [
                "ownership_ownership",
                "ownership_unitreport",
                "ownership_obligation",
                "ownership_developer"
            ],
        }
    }

    componentWillMount(){
        this.setState({loading: true})
        fetchQuery(environment, queryCheckSiteOnline).then(data => {
            this.setState({checkOnline: data.checkOnline, loading: false})
        });
    }

    render() {

       let isdeveloperExtra =  _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on && _.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_developer, element.codename));

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    {this.state.loading ? <Loading/>
                        : !this.state.checkOnline ? <AlertWithholdPage/>
                            :  
                            isdeveloperExtra ? <DeveloperTopMenuNavigation center ={true}/> : <DeveloperPopup />
                    }
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default SettingDashboard;
