import React, { Component } from 'react'
import { format } from 'date-fns'
import "../style/manageOrder.scss"
import { Translation } from 'react-i18next'

export default class herderAlert extends Component {

  boxBodyHeader = (status) => {
    let valueResult = ""
    let valueResultTH = ""
    let boxColor = ""
    let iconStart = ""
    let iconEnd = ""
    let textUnderline = ""

    switch (status) {
      case "wait_open":
        valueResultTH = "รายการนี้ยังไม่ได้เปิด PO ต้องการยื่นเรื่องขอเปิด PO หรือไม่"
        valueResult = "This item has not been opened in a purchase order (PO). Do you want to submit a request to open a PO?"
        boxColor = "boxBlue"
        iconStart = "iconAlert-BlueSmall"
        iconEnd = "close-Blue"
        textUnderline = "Open a PO"
        break;
      case "wait": //รออนุมัติ
        valueResultTH = "อยู่ในระหว่างการนำเสนอเพื่อขออนุมัติ ไม่สามารถแก้ไขข้อมูลได้ จนกว่าผู้อนุมัติจะตีกลับ"
        valueResult = "It is currently being presented for approval, and data cannot be modified until it is approved and returned by the approver."
        boxColor = "boxYellow"
        iconStart = "iconAlert-Yellow"
        iconEnd = "close-Yellow"
        break;
      case "approve":
        valueResultTH = `อนุมัติการเปิด PO แล้ว วันที่ ${format(this.props.approveDate, "DD/MM/YYYY")} โดย ${this.props.approver}`
        valueResult = `The PO opening was approved on ${format(this.props.approveDate, "DD/MM/YYYY")} by ${this.props.approver}`
        boxColor = "boxGreen"
        iconStart = "iconAlert-SuccessGreen"
        iconEnd = "close-Green"
        break;
      case "void":
        valueResultTH = `ถูกยกเลิกเมื่อวันที่ ${format(this.props.voidDate, "DD/MM/YYYY")} โดย ${this.props.voider} เหตุผล: ${this.props.voidRemark}`
        valueResult = `It was canceled on ${format(this.props.voidDate, "DD/MM/YYYY")} by ${this.props.voider} with the reason: ${this.props.voidRemark}`
        boxColor = "boxRed"
        iconStart = "iconAlert-ErrorRed"
        iconEnd = "close-Red"
        break;
    }

    return (
      <Translation>
        {(t, language) =>
          <div className={`${boxColor}` + " bodyHeader"}>
            <div>
              <img
                className="mr-2"
                src={process.env.PUBLIC_URL + `/images/iconAlerts/${iconStart}.png`}
                alt={`${iconStart}`} />
              <span>{language.lng === "en" ? valueResult : valueResultTH}&nbsp;&nbsp;<u type="button" onClick={this.props.handleFiling}>{t("herderAlert:"+textUnderline)}</u></span>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + `/images/iconAlerts/${iconEnd}.png`} alt={`${iconEnd}`} onClick={this.props.handleCloseHeaderAlert} />
            </div>
          </div>
        }
      </Translation>
    )
  }

  render() {
    return (
      <div id="headerAlertManageOrder">
        {this.boxBodyHeader(this.props.status)}
      </div>
    )
  }
}
