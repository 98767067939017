import React from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import './chartForeignOwnership.scss';
import i18n from 'i18next';
import i18next from "i18next";

class ChartForeignOwnership extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
        };
    }

    componentDidMount() {
        this.genChartThaiAndForeign()
        this.genChartForeign()
    }

    genChartThaiAndForeign() {
        if (this.root) {
            // Dispose of the old root and chart
            this.root.dispose(); 
            this.chart.dispose(); 
          }
        // PIE
        let chartData = JSON.parse(this.props.dataOwnershipSummaryPercent)
        // Define the categories and keys  
        let locale = `${i18next.t("foreignOwnership:Locale")}`;
        const categories = locale === 'thLocale' ? ["คนไทย", "ชาวต่างชาติ"]:["Thai", "Foreign"];
        const keys = ["nationality_th", "nationality_not_th"];

        // Create an array of objects based on the categories and keys
        const result = categories.map((category, index) => ({
            "category": category,
            "value": chartData[keys[index]]
        }));

        let data = result;
    
        let root = am5.Root.new("thaiAndForeignChart");
    
        root.setThemes([am5themes_Animated.new(root)]);
    
        // Create chart
        let chart = root.container.children.push(
          am5percent.PieChart.new(root, {
            layout: root.verticalLayout,    
            radius: 120,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
          })
        );
    
        chart.children.unshift(am5.Label.new(root, {
          text: `${i18n.t("foreignOwnership:Ratio of foreign and Thai ownership")}  (${i18n.t("foreignOwnership:unit")}: ${this.props.typeOfProject === "VILLAGE" ? `${i18n.t("foreignOwnership:House")}` : `${i18n.t("foreignOwnership:unitType")}`})`,
          fontSize: 16,
          fontWeight: 600,
          fontFamily: 'kanit',
          textAlign: "center",
          x: am5.percent(50),
          centerX: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0
        }));
    
        // Create series
        let series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category",
            tooltip: am5.Tooltip.new(root, {
              // ทำให้ตอน hover สามารถเลื่อนไปคลิ๊กได้
              keepTargetHover: true,
            })
          })
        );
    
        // hide ticks
        series.ticks.template.set("forceHidden", true);
        series.labels.template.set("forceHidden", true);
    
        series.get("tooltip").label.set("interactive", true);
              
        series.slices.template.setAll({
          tooltipHTML: `<b style="color: black;">{category}: {value.formatNumber('#,###')}</b>`,
          strokeWidth: 2,
        });
    
        series.get("colors").set("colors", [
          am5.color(0x1567B4),
          am5.color(0x008FCE),
          am5.color(0x00B2C8),
        ]);
    
        series.labels.template.set("tooltipText", "{accountGroup}: {totalAmount.formatNumber('#,###')}");
    
        // Set data
        series.data.setAll(data);
    
        // Create legend
        let legend = chart.children.push(
          am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            layout: am5.GridLayout.new(root, {
              maxColumns: 2,
              fixedWidthGrid: true,
            }),
          })
        );
    
        legend.data.setAll(series.dataItems);
    
        // Play initial series animation
        series.appear(1000, 100);
        root._logo.dispose();
    
        this.chart = chart;
        this.root = root;
    }

    genChartForeign() {   
        if (this.root2) {
            // Dispose of the old root and chart
            this.root2.dispose(); 
            this.chart.dispose(); 
          }
        
        let chartData = "";
        let otherData = "";
        let data = "";

        // PIE
        if(this.props.dataForeignOwnershipPercent){
          chartData = this.props.dataForeignOwnershipPercent?.map(jsonString => JSON.parse(jsonString))
          otherData = chartData[4]? chartData[4]?.otherList: "";
          data = chartData;
        }
        let root2 = am5.Root.new("foreignChart");
    
        root2.setThemes([am5themes_Animated.new(root2)]);
    
        // Create chart
        let chart = root2.container.children.push(
          am5percent.PieChart.new(root2, {
            layout: root2.verticalLayout,    
            radius: 120,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
          })
        );
    
        chart.children.unshift(am5.Label.new(root2, {
          text: data? `${i18n.t("foreignOwnership:Ratio of foreign ownership in each country")}  (${i18n.t("foreignOwnership:unit")}: ${this.props.typeOfProject === "VILLAGE" ? `${i18n.t("foreignOwnership:House")}` : `${i18n.t("foreignOwnership:unitType")}`})`:`${i18n.t("foreignOwnership:No ownership of foreign nationals")}`,
          fontSize: 16,
          fontFamily: 'kanit',
          fontWeight: 600,
          textAlign: "center",
          x: am5.percent(50),
          centerX: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0
        }));
    
        // Create series
        let series = chart.series.push(
          am5percent.PieSeries.new(root2, {
            valueField: "value",
            categoryField: "category",
            tooltip: am5.Tooltip.new(root2, {
              // ทำให้ตอน hover สามารถเลื่อนไปคลิ๊กได้
              keepTargetHover: true,
            })
          })
        );
    
        // hide ticks
        series.ticks.template.set("forceHidden", true);
        series.labels.template.set("forceHidden", true);
    
        series.get("tooltip").label.set("interactive", true);
              
        series.slices.template.setAll({
          tooltipHTML: `<b style="color: black;">{category}: {value.formatNumber('#,###')}</b>`,
          strokeWidth: 2,
        });
    
        series.get("colors").set("colors", [
          am5.color(0x1567B4),
          am5.color(0x009CD6),
          am5.color(0x00CED9),
          am5.color(0x7CFACD),
          am5.color(0xE6F4F1),
        ]);
    
        series.labels.template.set("tooltipText", "{accountGroup}: {totalAmount.formatNumber('#,###')}");
        // Set data
        series.data.setAll(data);
    
        // Create legend
        let legend = chart.children.push(
          am5.Legend.new(root2, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            layout: am5.GridLayout.new(root2, {
              maxColumns: 5,
              fixedWidthGrid: true,
            }),
          })
        );

        legend.data.setAll(series.dataItems);
        // add tooltip สำหรับ hover ตรงอื่นๆ
        if(otherData) {
            let result = otherData.map((data) => `${data.category} ${data.value}%`).join("\n");
            let tooltip = am5.Tooltip.new(root2, {});        
            tooltip.get("background").setAll({
              fill: am5.color(0x000000)
            })
            legend.set("tooltip", tooltip);
            legend.itemContainers.values[4].set("tooltipText", result);
        }

        // Play initial series animation
        series.appear(1000, 100);
        root2._logo.dispose();
    
        this.chart = chart;
        this.root2 = root2;
    }

    componentWillUnmount() {
        if (this.root) {
          this.root.dispose();
        }
        if (this.root2) {
            this.root2.dispose();
          }
    }

    render() {
        return (
            <div id="foreignOwnershipChartContainer">
              <div className='divContainer'>
                  <div id="thaiAndForeignChart"></div>
                  <div className='alertTextContainer'>
                    {this.props.percenMessageAlert && <p>*{i18n.t("foreignOwnership:The foreign ownership ratio is close to the required threshold")} ({this.props.percenMessageAlert}%)</p>}
                  </div>
              </div>
              <div className='divContainer'>
                  <div id="foreignChart"></div>
              </div>              
            </div>
        )
    }
}

export default ChartForeignOwnership;