import {commitMutation,} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";


const mutation = graphql`
    mutation allowPdpaRegisterCollectionLawFirmMutation ($input:AllowPdpaRegisterCollectionLawFirmInput!) {
        allowPdpaRegisterCollectionLawFirm(input:$input){
        ok
        massage
    }
    }
`;


export default function AllowPdpaRegisterCollectionLawFirm(variables) {

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
              //  callback(response)
            }
           
        },
    )
}
