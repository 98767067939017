import React, { Component } from "react";
import SearchSelect from "../../../libs/searchSelect";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import getDocumentNumber from "../../../libs/getDocumentNumber";
import Link from "react-router-dom/es/Link";
import numberWithCommas from "../../../libs/numberWithComma";
import { parse, format } from "date-fns";
import ReceiveForm from "../../receive/receiveForm";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../../env/environment";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import { Translation } from "react-i18next";
import ClearAdvanceChangeStatus from './clearAdvanceChangeStatus'
import PayPaymentChannelForm from "../../pay/payPaymentChannelForm";
import PayChannel from "../pay/payChannel";
import UploadFileInput from "../../../libs/uploadFileInput";
import Asset from '../../../libs/autoAsset'
import "./styles/clearAdvanceCreateAndViewForm.scss"
import i18next from "i18next";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
const _ = require('lodash');

const mutation = graphql`
    mutation clearAdvanceCreateAndViewFormMutation($input: CreateClearAdvanceInput!) {
        createClearAdvance(input: $input){
            ok
            warningText
            newClearAdvance {
                id
            }
        }
    }`;

class ClearAdvanceCreateAndViewForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirectToList: false,
            receive_channel: [],
            chart_of_account: [],
            signreq: Math.random().toString(36),
            clearAdvance: {
                id: '',
                docNumber: '',
                issuedDate: format(new Date(), 'YYYY-MM-DD'),
                description: '',
                returnPrice: '',
                overPrice: '',
                status: '',
                clearAdvanceTransaction: {
                    edges: [
                        {
                            node: {
                                id: '',
                                chartOfAccount: {
                                    id: '',
                                    chartOfAccountCode: '',
                                    name: ''
                                },
                                description: '',
                                price: '',
                                document_upload: '',
                            }
                        }
                    ]
                },
                advance: {
                    id: '',
                    amount: ''
                },

            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.appendNewRow = this.appendNewRow.bind(this);
        this.onDeleteRow = this.onDeleteRow.bind(this);
        this.updateReceiveChannel = this.updateReceiveChannel.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentWillMount() {

        if (this.props.data.clearAdvance) {
            this.setState({ clearAdvance: this.props.data.clearAdvance });
        }

        if (this.props.clearAdvanceType === 'create' && this.props.data.advance) {
            let advance = {
                id: this.props.data.advance.id,
                amount: this.props.data.advance.amount
            };
            let clearAdvance = { ...this.state.clearAdvance };
            clearAdvance.advance = advance;
            this.setState({ clearAdvance: clearAdvance });
        }
    }

    async handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        if (event.target.dataset.key === 'advance_selected') {
            this.props.data.allAdvance.edges.forEach((advance) => {
                if (advance.node.id === value) {
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            event.target.name,
                            advance.node
                        );
                    });
                }
            });
            return
        }
        else if (event.target.dataset.key === 'chart_of_account') {


            let key_chart_of_account = event.target.name + '.node.chartOfAccount.id';
            let key_chart_of_account_code = event.target.name + '.node.chartOfAccount.chartOfAccountCode';
            let key_description = event.target.name + '.node.description';
            let requiredKeys = [key_chart_of_account, key_description, key_chart_of_account_code];
            let value_set = [value];

            this.props.data.viewer.allChartOfAccount.edges.forEach((chart) => {
                if (value === chart.node.id) {
                    if ((_.startsWith(chart.node.chartOfAccountCode, '1210') || _.startsWith(chart.node.chartOfAccountCode, '1230')) && this.props.no_setting_asset) {
                        Swal.fire(i18next.t("clearAdvanceCreate:Unable to select this item"), i18next.t("clearAdvanceCreate:Because assets are not configured. Please complete the asset configuration before proceeding with the transaction"), "warning");
                        value_set = []
                    } else {
                        value_set = [...value_set, chart.node.name, chart.node.chartOfAccountCode]
                    }
                }
            });

            this.setState(prevState => {
                let cloneDeep = _.cloneDeep(prevState);
                return _.set(cloneDeep, '', requiredKeys.forEach(function (requiredKeys, index) {
                    return _.set(cloneDeep, requiredKeys, value_set[index]);
                }));
            });
            return

        } else if (event.target.name.includes("document_upload")) {
            this.setState(prevState => {
                return _.set(
                    _.cloneDeep(prevState),
                    event.target.name,
                    event.currentTarget.files[0]
                );
            });
            return
        }

        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    imageFormat(data_list) {
        let upload = {};
        data_list.clearAdvanceTransaction.edges.forEach((data, index) => {
            if (data.node.document_upload) {
                Object.assign(upload, { [index]: data.node.document_upload });
            }
        });
        return upload
    }

    appendNewRow() {
        this.setState(prevState => {
            let new_object = {
                node: {
                    id: '',
                    chartOfAccount: {
                        id: '',
                        chartOfAccountCode: '',
                        name: ''
                    },
                    description: '',
                    price: '',
                    document_upload: '',
                }
            };

            return _.set(
                _.cloneDeep(prevState),
                'clearAdvance.clearAdvanceTransaction.edges',
                _.concat(prevState.clearAdvance.clearAdvanceTransaction.edges, new_object)
            );
        })
    }

    onDeleteRow(index) {
        this.setState(prevState => {
            let clear_advance_transaction_list = prevState.clearAdvance.clearAdvanceTransaction.edges;
            _.pullAt(clear_advance_transaction_list, [index]);

            return _.set(
                _.cloneDeep(prevState),
                'clearAdvance.clearAdvanceTransaction.edges',
                clear_advance_transaction_list
            );
        })
    }

    updateReceiveChannel(channel_list) {
        this.setState({ receive_channel: channel_list });
    }

    onSubmit(e) {
        e.preventDefault();
        let total_receive = _.sumBy(this.state.receive_channel,'pay') | 0
        let total_diff = this.getOverOrReturn();
        if( total_diff < 0 && total_receive < Math.abs(total_diff)){
            Swal.fire("รวมยอดขาดน้อยกว่าที่กำหนด!", "", "warning");
            return;
        }
        this.setState({loading: true});

        // set format issue date
        let clear_advance = _.cloneDeep(this.state.clearAdvance);
        _.set(clear_advance, "issuedDate", format(this.state.clearAdvance.issuedDate, 'YYYY-MM-DD'));
        let asset = []
        _.forEach(this.state.clearAdvance.clearAdvanceTransaction.edges, transaction => {
            if (_.startsWith(transaction.node.chartOfAccount.chartOfAccountCode, '1210') || _.startsWith(transaction.node.chartOfAccount.chartOfAccountCode, '1230')) {
                asset.push({
                    type: Asset.getTypeAsset(transaction.node.chartOfAccount.chartOfAccountCode),
                    nounClassifier: '',
                    historicalValue: 0,
                    purchaseDate: format(this.state.clearAdvance.issuedDate, 'YYYY-MM-DD'),
                    costPrice: parseFloat(transaction.node.price),
                    calDepreciationDate: transaction.node.chartOfAccount.chartOfAccountCode === '1210-01' ? null : format(this.state.clearAdvance.issuedDate, 'YYYY-MM-DD'),
                    carcassPrice: 1,
                    historicalAmount: 0,
                    name: transaction.node.description,
                    description: transaction.node.description,
                    storage: '',
                    remark: '',
                    seller: 'other_select',
                    sellerOther: '',
                    saleDate: null,
                    saleStatus: 'active',
                    refNumber: ''
                })
            }
        })
        let uploadables = this.imageFormat(clear_advance);
        let variables = {
            input: {
                clearAdvance: JSON.stringify(clear_advance),
                paymentChannel: JSON.stringify(this.checkEqPay() ? this.state.receive_channel : ""),
                overOrReturn: this.getOverOrReturn(),
                asset: asset,
                clientMutationId: "CAD" + Math.random().toString(36),
            }
        };
        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    if (response.createClearAdvance.ok) {
                        Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                            this.setState({ redirectToList: true, loading: false });
                        })
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createClearAdvance.warningText, 'warning');
                        this.setState({ loading: false });
                    }
                },
                onError: (err) => {
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                    this.setState({ loading: false });
                },
            },
        );
    }

    checkEqPay() {
        if (this.state.receive_channel.length !== 0) {
            return Math.abs(parseFloat(this.getOverOrReturn())) === parseFloat(this.state.receive_channel[0].pay)
        } else {
            return 0
        }
    }

    getOverOrReturn() {
        return this.state.clearAdvance.advance.amount - this.state.clearAdvance.clearAdvanceTransaction.edges.reduce(function (total, obj) { return total + parseFloat(obj.node.price) }, 0);
    }

    render() {
        let total_diff = this.getOverOrReturn();
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/expense/advance/list/clear-advance" />
        }

        return (
            <React.Fragment>
                <Translation>
                    {t =>
                        <React.Fragment>
                            <BackButtonIcon
                                LinkBack={"/accounting/expense/advance/list/clear-advance"}
                                LinkText={i18next.t('accounting:clear_advance')+" "+"(CAD)"}
                                boxHtmlText={
                                    this.props.isViewPage && this.state.clearAdvance.status === 'CLEAR' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_advance_delete' }) &&
                                    <ClearAdvanceChangeStatus id={this.state.clearAdvance.id} status="void"
                                        callBackChangeStatus={() => this.setState({ redirectToList: true })} />
                                }
                            />

                            <form action="" onSubmit={this.onSubmit}>
                                <div className="row mb-3 mt-1 ml-4">
                                    <div className="col-4 form-inline">
                                        <label htmlFor="date"
                                            className="col-form-label"><strong>{i18next.t("clearAdvanceCreate:Reference from")}</strong></label>
                                        <div className="col-8">
                                            {
                                                this.props.isViewPage ?

                                                    this.props.data.allAdvance.edges.map((advance, index) =>
                                                        this.state.clearAdvance.advance.id === advance.node.id &&
                                                        <span
                                                            key={index}>{advance.node.docNumber + ' ' + advance.node.withdrawer + ' ' + advance.node.description}</span>
                                                    )
                                                    :
                                                    <SearchSelect onChange={this.handleInputChange}
                                                        value={this.state.clearAdvance.advance.id}
                                                        name="clearAdvance.advance" placeholder={i18next.t("clearAdvanceCreate:Description")}
                                                        data-key="advance_selected"
                                                        queryObject={this.props.data.allAdvance.edges}
                                                        keyOfValue="id" require={true}
                                                        keyOfLabel="docNumber:withdrawer:description"
                                                    />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-3 form-inline">
                                        <label htmlFor="date"
                                            className="mr-3"><strong>{i18next.t("clearAdvanceCreate:Date")}</strong></label>
                                        <DatePickerAdapter id="date"
                                            className="form-control" name="clearAdvance.issuedDate"
                                            selected={parse(this.state.clearAdvance.issuedDate)}
                                            onChange={this.handleInputChange}
                                            disable={this.props.isViewPage} />
                                    </div>
                                    <div className="col-4 form-inline">
                                        <label htmlFor="start_date"
                                            className="startDate mr-3"><strong>{i18next.t("clearAdvanceCreate:Number")}</strong></label>
                                        <input type="text" className="form-control"
                                            value={this.props.isViewPage ? this.state.clearAdvance.docNumber : getDocumentNumber.get('clear_advance', 'XXX', new Date())}
                                            disabled />
                                    </div>
                                </div>
                                {this.props.isViewPage && this.state.clearAdvance.status === 'VOID' &&
                                    <React.Fragment>
                                        <div className="row mb-3 mt-1 ml-4">
                                            <label htmlFor="void_remark"
                                                className="mr-3 col col-form-label">{i18next.t("clearAdvanceCreate:Cancellation Reason")} {this.state.clearAdvance.voidRemark}</label>
                                        </div>
                                    </React.Fragment>
                                }


                                <div className="row">
                                    <div className="col">
                                        <div className="content-inner" id="payment-select-petty-cash">

                                            <React.Fragment>
                                                <div className="fade-up">
                                                    <table className="table table-hover mt-2">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th>{i18next.t("clearAdvanceCreate:Expense Account")}</th>
                                                                <th>{i18next.t("clearAdvanceCreate:Description")}</th>
                                                                <th className="text-right">{i18next.t("clearAdvanceCreate:Amount")}</th>
                                                                <th width={200} className="text-center">{i18next.t("clearAdvanceCreate:Reference Document")}</th>
                                                                {!this.props.isViewPage &&
                                                                    this.state.clearAdvance.clearAdvanceTransaction.edges.length !== 1 &&
                                                                    <th />
                                                                }
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                this.state.clearAdvance.clearAdvanceTransaction.edges.map((transaction, index) =>
                                                                    <tr key={index}>
                                                                        <td>{
                                                                            this.props.isViewPage ? transaction.node.chartOfAccount.chartOfAccountCode + ' ' + transaction.node.chartOfAccount.name :
                                                                                <SearchSelect onChange={this.handleInputChange}
                                                                                    value={transaction.node.chartOfAccount.id}
                                                                                    name={"clearAdvance.clearAdvanceTransaction.edges[" + index + "]"}
                                                                                    placeholder={i18next.t("clearAdvanceCreate:Description")}
                                                                                    data-key="chart_of_account"
                                                                                    queryObject={this.props.data.viewer.allChartOfAccount.edges}
                                                                                    keyOfValue="id" require={true}
                                                                                    keyOfLabel="chartOfAccountCode:name"
                                                                                />

                                                                        }</td>
                                                                        <td>
                                                                            {
                                                                                this.props.isViewPage ? transaction.node.description :
                                                                                    <input type="text" min={1} className="form-control"
                                                                                        name={"clearAdvance.clearAdvanceTransaction.edges[" + index + "].node.description"}
                                                                                        value={transaction.node.description || ""}
                                                                                        onChange={this.handleInputChange} required />
                                                                            }
                                                                        </td>
                                                                        <td className="text-right">
                                                                            {
                                                                                this.props.isViewPage ? numberWithCommas(transaction.node.price) :
                                                                                    <input type="number" min={0} step="0.01"
                                                                                        className="form-control text-right"
                                                                                        name={"clearAdvance.clearAdvanceTransaction.edges[" + index + "].node.price"}
                                                                                        value={transaction.node.price}
                                                                                        onChange={this.handleInputChange}
                                                                                        required />
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {this.props.isViewPage ?
                                                                                transaction.node.refClearAdvanceTransactionDoc ?
                                                                                    <a download="name_of_downloaded_file"
                                                                                        href={transaction.node.refClearAdvanceTransactionDoc}>
                                                                                        <img
                                                                                            src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                                            alt="back"
                                                                                            className="download-file-icon" />
                                                                                    </a>
                                                                                    :
                                                                                    "-"
                                                                                :
                                                                                <>
                                                                                    <UploadFileInput
                                                                                        handleInputDocument={this.handleInputChange}
                                                                                        documentUpload={transaction.node.document_upload}
                                                                                        name={"clearAdvance.clearAdvanceTransaction.edges[" + index + "].node.document_upload"}
                                                                                        disableHeaderText={true}
                                                                                    />
                                                                                    <strong className="text-danger font-small">{i18next.t("clearAdvanceCreate:Attach Files")} ({i18next.t("clearAdvanceCreate:JPG PNG PDF, not exceeding 10 MB")})</strong>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                        {!this.props.isViewPage &&
                                                                            this.state.clearAdvance.clearAdvanceTransaction.edges.length !== 1 &&
                                                                            <td>
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                    alt="delete" className="btn-delete cursor"
                                                                                    onClick={() => this.onDeleteRow(index)} />

                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            }

                                                        </tbody>
                                                    </table>
                                                    {
                                                        !this.props.isViewPage &&
                                                        <div className="row ">
                                                            <div className="col">
                                                                <button type="button" className="btn add-button add"
                                                                    onClick={this.appendNewRow}
                                                                    style={{ backgroundColor: '#f2f2f2' }}>
                                                                    +
                                                                    <span>{i18next.t('settingAll:Add list')}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <label htmlFor="description"><strong>{i18next.t("clearAdvanceCreate:Additional Details")}</strong></label>
                                                        {
                                                            this.props.isViewPage ?
                                                                <div>{this.state.clearAdvance.description || '-'}</div> :
                                                                <textarea id="description"
                                                                    className="form-control" rows="2"
                                                                    name="clearAdvance.description"
                                                                    value={this.state.clearAdvance.description}
                                                                    onChange={this.handleInputChange} />
                                                        }
                                                    </div>
                                                    <div className="col offset-5 bg-light">
                                                        <div className="row text-dark mt-2">
                                                            <div className="col text-left"> {i18next.t("clearAdvanceCreate:Total")}</div>
                                                            <div
                                                                className="col text-right">{numberWithCommas(this.state.clearAdvance.clearAdvanceTransaction.edges.reduce(function (total, obj) {
                                                                    return total + parseFloat(obj.node.price)
                                                                }, 0))} {i18next.t("clearAdvanceCreate:THB")}
                                                            </div>
                                                        </div>
                                                        <div className="row text-dark">
                                                            <div className="col text-left"> {i18next.t("clearAdvanceCreate:Withdrawal Amount")}</div>
                                                            <div
                                                                className="col text-right">{numberWithCommas(this.state.clearAdvance.advance.amount || 0)} {i18next.t("clearAdvanceCreate:THB")}
                                                            </div>
                                                        </div>
                                                        {(!isNaN(total_diff)) && total_diff !== 0 &&
                                                            <div className="row text-dark mb-2">
                                                                <div className="col text-left"> {total_diff > 0 ? i18next.t("clearAdvanceCreate:Remaining") : i18next.t("clearAdvanceCreate:Deficit")}</div>
                                                                <div
                                                                    className="col text-right">{numberWithCommas(Math.abs(total_diff))} {i18next.t("clearAdvanceCreate:THB")}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>


                                                {(total_diff > 0 && !this.props.isViewPage) &&
                                                    <div className="fade-up">
                                                        <ReceiveForm
                                                            updateReceiveChannel={this.updateReceiveChannel}
                                                            single_payment={true} not_other_and_cheque_channel={true}
                                                            page={"clear_advance"}
                                                            payment_type={'receive'}
                                                            issued_date={parse(this.state.clearAdvance.issuedDate)}
                                                            total={Math.abs(total_diff)}
                                                        />
                                                    </div>
                                                }
                                                {(total_diff < 0 && !this.props.isViewPage) &&
                                                    <div className="fade-up">
                                                        <PayPaymentChannelForm
                                                            updatePayChannel={this.updateReceiveChannel}
                                                            single_payment={true} not_other_channel={true}
                                                            page={"clear_advance"}
                                                            payment_type={'pay'}
                                                            pay_form={true}
                                                            issued_date={parse(this.state.clearAdvance.issuedDate)}
                                                            auto_suggest={Math.abs(total_diff)}
                                                        />
                                                    </div>
                                                }

                                            </React.Fragment>

                                            {this.props.isViewPage && (this.state.clearAdvance.returnPrice !== 0 || this.state.clearAdvance.overPrice !== 0) &&
                                                <PayChannel
                                                    total={this.state.clearAdvance.returnPrice > 0 ? this.state.clearAdvance.returnPrice : this.state.clearAdvance.overPrice}
                                                    bankTransaction={this.state.clearAdvance.bankAccountTransaction.edges}
                                                    cashTransaction={this.state.clearAdvance.cashTransaction.edges}
                                                    chequeTransaction={this.state.clearAdvance.chequeTransaction.edges}
                                                    paymentChannel={this.state.clearAdvance.advancePettyCashChannel.edges}
                                                    account_type={this.state.clearAdvance.returnPrice > 0 ? 'receive' : 'pay'}
                                                />
                                            }

                                            {
                                                !this.props.isViewPage &&
                                                <div className="row mt-3 fade-up">
                                                    <div className="col text-right">
                                                        <div className="btn-group mr-2">
                                                            <Link to="/accounting/expense/advance/list/clear-advance">
                                                                <button type="button"
                                                                    className="btn btn-secondary add">
                                                                    {i18next.t("Allaction:cancel")}
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div className="btn-group mr-2">
                                                            <button type="submit" className="btn btn-primary add"
                                                                disabled={this.state.loading}>
                                                                {this.state.loading && <span
                                                                    className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                                {i18next.t("Allaction:save")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </React.Fragment>
                    }
                </Translation>
            </React.Fragment>
        )
    }
}

export default ClearAdvanceCreateAndViewForm;
