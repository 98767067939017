/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingJuristicListQueryVariables = {|
  search?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type settingJuristicListQueryResponse = {|
  +allJuristic: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
        +role: string,
        +image: ?string,
        +creator: ?string,
        +added: any,
        +adminStatus: boolean,
        +allsiteStatus: boolean,
        +user: ?{|
          +id: string,
          +username: string,
          +lastLogin: ?any,
        |},
      |}
    |}>,
  |},
  +allJuristicLog: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
        +username: string,
        +role: string,
        +added: any,
        +creator: string,
      |}
    |}>
  |},
  +allJuristicControlMultipleSite: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +juristic: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +role: string,
          +image: ?string,
          +creator: ?string,
          +added: any,
          +adminStatus: boolean,
          +allsiteStatus: boolean,
          +user: ?{|
            +id: string,
            +username: string,
            +lastLogin: ?any,
          |},
        |},
      |}
    |}>,
  |},
  +checkUserAdmin: ?boolean,
  +current: ?{|
    +user: ?{|
      +id: string
    |}
  |},
|};
export type settingJuristicListQuery = {|
  variables: settingJuristicListQueryVariables,
  response: settingJuristicListQueryResponse,
|};
*/


/*
query settingJuristicListQuery(
  $search: String
  $first: Int
  $last: Int
) {
  allJuristic(search: $search, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        firstName
        lastName
        role
        image
        creator
        added
        adminStatus
        allsiteStatus
        user {
          id
          username
          lastLogin
        }
      }
    }
  }
  allJuristicLog {
    edges {
      node {
        id
        firstName
        lastName
        username
        role
        added
        creator
      }
    }
  }
  allJuristicControlMultipleSite(search: $search) {
    totalCount
    edges {
      node {
        id
        juristic {
          id
          firstName
          lastName
          role
          image
          creator
          added
          adminStatus
          allsiteStatus
          user {
            id
            username
            lastLogin
          }
        }
      }
    }
  }
  checkUserAdmin
  current {
    user {
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v12 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "image",
    "storageKey": null
  },
  (v9/*: any*/),
  (v10/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "adminStatus",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allsiteStatus",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastLogin",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    (v3/*: any*/)
  ],
  "concreteType": "JuristicNodeConnection",
  "kind": "LinkedField",
  "name": "allJuristic",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JuristicNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicLogNodeConnection",
  "kind": "LinkedField",
  "name": "allJuristicLog",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicLogNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JuristicLogNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v11/*: any*/),
            (v8/*: any*/),
            (v10/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": [
    (v3/*: any*/)
  ],
  "concreteType": "JuristicControlMultipleSiteNodeConnection",
  "kind": "LinkedField",
  "name": "allJuristicControlMultipleSite",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicControlMultipleSiteNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JuristicControlMultipleSiteNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "JuristicNode",
              "kind": "LinkedField",
              "name": "juristic",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkUserAdmin",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingJuristicListQuery",
    "selections": [
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNode",
        "kind": "LinkedField",
        "name": "current",
        "plural": false,
        "selections": [
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "settingJuristicListQuery",
    "selections": [
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNode",
        "kind": "LinkedField",
        "name": "current",
        "plural": false,
        "selections": [
          (v17/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50c4a6eda53f987d8406b0e74932d317",
    "id": null,
    "metadata": {},
    "name": "settingJuristicListQuery",
    "operationKind": "query",
    "text": "query settingJuristicListQuery(\n  $search: String\n  $first: Int\n  $last: Int\n) {\n  allJuristic(search: $search, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        role\n        image\n        creator\n        added\n        adminStatus\n        allsiteStatus\n        user {\n          id\n          username\n          lastLogin\n        }\n      }\n    }\n  }\n  allJuristicLog {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        username\n        role\n        added\n        creator\n      }\n    }\n  }\n  allJuristicControlMultipleSite(search: $search) {\n    totalCount\n    edges {\n      node {\n        id\n        juristic {\n          id\n          firstName\n          lastName\n          role\n          image\n          creator\n          added\n          adminStatus\n          allsiteStatus\n          user {\n            id\n            username\n            lastLogin\n          }\n        }\n      }\n    }\n  }\n  checkUserAdmin\n  current {\n    user {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '786511b33512ff042dec4846b4fea817';

module.exports = node;
