import React from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Navigation from './navigationDebtCertification';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer ,commitMutation  } from "react-relay";
import format from 'date-fns/format';
import _ from 'lodash';
import DatePickerNoti from '../finance/depositInterest/datePickerNoti';
import SearchProject from '../../project/searchProject';
import ComponentPagination from '../../libs/componentPagination';
import Pagination from '../../libs/newPagination';
import Swal from 'sweetalert2';
import DebtFreeCertificationTable from './debtFreeCertificationTable';
import Loading from '../../libs/loading';
import i18next from 'i18next';


const query = graphql`
query debtFreeCertificationListQuery( $status: [String], $startDate: DateTime,$endDate: DateTime, $search: String, $first: Int, $last: Int){
    allDebsFreeCertification(status: $status, startDate:$startDate, endDate:$endDate, search: $search,first: $first , last: $last){
        totalCount
        edges{
            node{
                id
                docNumber
                status
                statusPrint
                dateStamp
                printDate
                dueDate
                creatorVoid
                voidDate
                voidNote
                debsFreeCertificationRequest{
                    id
                    docNumber
                    added
                    updated
                    contact{
                        id
                        name
                        firstName
                        lastName
                    }
                    requestingDate
                    creatorApprove
                    approvalDate
                    transactionDebtType
                    tenant{
                        firstName
                        lastName
                    }
                    receive{
                        id
                        docNumber
                    }
                    otherReceive{
                        id
                        docNumber
                    } 
                    status 
                }
            }
        }
    }
}`;

const mutation = graphql`
    mutation debtFreeCertificationListMutation ($input: UpdateDebsFreeCertificationInput!) {
    updateDebsFreeCertification (input: $input) {
        ok
        message 
    }
}
`

class DebtFreeCertificationList extends ComponentPagination {
    constructor(props){
        super(props);
        this.state = {
            status : this.props.match.params.status,
            loading : false,
            search : '',
            startDate : null,
            endDate : null,
            checkAll : false,
            selectedId : [],
            reloading : false,
            tempStartDate : null,
            tempEndDate : null
        }
    }

    componentDidMount(){
        this.goFirst();
    }

    componentDidUpdate(){
        if(this.props.match.params.status !== this.state.status){
            this.setState({status : this.props.match.params.status,selectedId:[]})   
        }
    }

    switchStatus = () => {
        if(this.state.status === 'all'){
            return ['draft','active','void']
        }else if(this.state.status !== 'all'){
            return [this.state.status]
        }else{
            return ['draft','active','void']
        }
        
    }

    handleSearch = (text) => {
        this.setState({
            search: text,
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onCheckAll = (props) => {
        let selected = []
        this.setState({checkAll : !this.state.checkAll} , () => {
            if(this.state.checkAll){
                _.forEach(props.allDebsFreeCertification.edges , debtFree =>{
                    selected.push(debtFree.node.id)
                })
                this.setState({
                    selectedId : selected
                })
            }else{
                this.setState({
                    selectedId : []
                })
            }
        })

    }

    onCheck = (id) => {
        let index = this.state.selectedId.indexOf(id);
        let selected = _.cloneDeep(this.state.selectedId)
        if(index === -1){
            selected.push(id)
        }else{
            selected.splice(index, 1)
        }
        this.setState({
            selectedId : selected
        })
    }

    onClickIcon = () => {
        this.setState({
            startDate :  this.state.tempStartDate !== null ? new Date(new Date(this.state.tempStartDate).setHours(0,0,0,0)) : null,
            endDate : this.state.tempEndDate !== null ? new Date(new Date(this.state.tempEndDate).setHours(24,0,0,0)) : null
        })
    }

    onCancel = async() => {
        this.setState({
            loading : true
        })
        let { value: void_remark }  = await Swal.fire({
            title: `${i18next.t("Allaction:Do you want to cancel")}?`,
            type: 'warning',
            text: i18next.t("Allaction:Please enter a cancellation note."),
            input: 'text',
            showCancelButton: true,
            confirmButtonColor: '#1567B4',
            cancelButtonColor: '#E14558',
            confirmButtonText: i18next.t("Allaction:Confirm"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            inputValidator: (value) => {
              if (!value) {
                  return i18next.t("Allaction:Please enter a cancellation note.")
              }
            }
          })
          if (void_remark) {
            let count = 0;
            _.forEach(this.state.selectedId , id => {
                count += 1
                let variables = {
                    input : {
                      debsFreeCertification : {
                      debsFreeCertificationId : id,
                        status : 'void',
                        printDate : '',
                        voidDate : format(new Date(),'YYYY-MM-DDT00:00:00'),
                        voidNote : void_remark,
                        dateStamp : true
                      }
                    }
                  }
                  commitMutation(
                      environment,
                      {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                          if(count === this.state.selectedId.length){
                              if(response.updateDebsFreeCertification && response.updateDebsFreeCertification.ok){
                                  Swal.fire('ยกเลิกรายการสำเร็จ', '', 'success').then(() => {
                                      this.setState({
                                          reloading:!this.state.reloading,
                                          loading: false,
                                          selectedId: []
                                    })
                                  });
                              }else{
                                  Swal.fire('ยกเลิกรายการไม่สำเร็จ', '', 'warning').then(() => {
                                      this.setState({loading:false})
                                  });
                              }
                          }
                        },
                        onError: (err) => {
                          Swal.fire('ยกเลิกรายการไม่สำเร็จ', '', 'warning').then(() => {
                              this.setState({loading:false})
                          });
                        },
                      },
                  )

            })
            
        }
    }



    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="debt-free-certification">
                        <div className="row justify-content-between">
                            <div className="col-12">
                                <Translation>
                                    {
                                        t => 
                                        <h4 className="mb-4 form-inline">
                                            <Link to="/accounting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back-new.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <div className="mt-1"> {t("debtFree:Debt Free Certification")+" "+"(DFC)"}</div>
                                        </h4>
                                    }
                                </Translation>
                            </div>
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertification_create'}) &&
                            <div className="col-12">
                                <Translation>
                                    {t => 
                                        <Link to="/accounting/debt_free_certification/create">
                                            <button type="button" className="btn btn-add float-right mr-2">
                                                <img src={process.env.PUBLIC_URL + '/images/icons/printer-white.png'} alt="plus" className="mr-1" style={{width:'16px' ,height:'16px'}}/>
                                                พิมพ์หนังสือ
                                            </button>
                                        </Link>}
                                </Translation>
                            </div>}
                            
                        </div>
                        <div className="content-inner">
                            <Navigation />
                            <div className="row mt-1 mb-3">
                                
                            {this.props.match.params.status === 'active' ?
                                <div className="col-4">
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertification_delete'}) && 
                                    <button type="button" className="btn-list mr-1" onClick={() => this.onCancel()}>
                                    {this.state.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2"/>}
                                        <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'} alt="print" style={{height:'14px',width:'14px'}} />
                                    </button>
                                    }
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertification_print'}) && 
                                        <Link
                                        to={{
                                            pathname: "/accounting/debt_free_certification/print/all",
                                            state: {
                                                selectedId: this.state.selectedId,
                                            }
                                        }}>
                                            <button type="button" className="btn-list">
                                                <img src={process.env.PUBLIC_URL + '/images/icons/printer-black.png'} alt="print" style={{height:'14px',width:'14px'}} />
                                            </button>
                                        </Link>
                                    }
                                </div>
                                :

                                (this.props.match.params.status === 'draft' ) ?

                                <div className="col-4">
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_debfreecertification_delete'}) && 
                                        <button type="button" className="btn-list" onClick={() => this.onCancel()}>
                                        {this.state.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2"/>}
                                        <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'} alt="print" style={{height:'14px',width:'14px'}} />
                                        </button>
                                    }
                                </div>
                                :

                                <div className="col-4"/>
                            }

                            <div className="col-8 float-right form-inline">                        
                                <div className="col d-flex justify-content-end align-items-center">
                                    <div className="ml-2"><span className="mr-2">วันที่</span></div>
                                    <div className="ml-2">
                                        <DatePickerNoti 
                                            selected={this.state.tempStartDate}
                                            name='tempStartDate'
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <span className="mr-1 ml-1 mr-2">ถึง</span>
                                    </div>
                                    <div className="ml-2">
                                        <DatePickerNoti 
                                            selected={this.state.tempEndDate}
                                            name='tempEndDate'
                                            className="mr-2"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="ml-2 mt-1">
                                        <SearchProject callback_function={this.handleSearch} search={this.state.search} callback_function_click={this.onClickIcon}/>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                variables={{
                                    search: this.state.search,
                                    status: this.switchStatus(),
                                    startDate : this.state.startDate,
                                    endDate : this.state.endDate,
                                    first : this.state.first,
                                    last : this.state.last,
                                    reloading : this.state.reloading
                                }}
                                render={({ error, props }) => {
                                    if (error) {
                                    return <div>{error.message}</div>;
                                    } else if (props) {
                                        
                                    return (
                                        <Translation>
                                            { t =>
                                            <React.Fragment>
                                                <div className="table-responsive fade-up card mt-3">
                                                    <table className="table table-hover">
                                                    <thead className="thead-light">
                                                        <tr className="headTableList">
                                                            {(this.state.status === 'active' || this.state.status === 'draft') &&
                                                                <th className="text-center">
                                                                    { this.state.status === 'draft' &&
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={this.state.checkAll}
                                                                        onChange={() => this.onCheckAll(props)}
                                                                    />
                                                                    }
                                                                </th>
                                                            }
                                                            <th className="text-center" width={150}>
                                                             {t("debtFree:Document Number")}
                                                            </th>
                                                            <th className="text-center">{t("debtFree:Debt Cert Request Date")}</th>
                                                            <th className="text-center">{t("FixRequestReport:Room No.")}</th>
                                                            <th className="text-left">{t("debtFree:Requester's name")}</th>
                                                            <th className="text-left">{t("debtFree:Approval by")}</th>
                                                            <th className="text-center">{t("debtFree:Approval date")}</th>
                                                            <th className="text-center">{t("debtFree:Reference Document No.")}</th>
                                                            
                                                            {this.state.status === 'all' && <th className="text-center">{t("PageList:Status")}</th>}
                                                            {this.state.status === 'active' && <th className="text-center"> {t("collectionLawFirm:Document Date")}</th>}
                                                            {this.state.status === 'active' && <th className="text-center">วันหมดอายุ </th>}
                                                            {this.state.status === 'active' && <th className="text-center"> {t("settingPeriodLock:Remark")}</th>}
                                                            {this.state.status === 'void' && <th className="text-left">ผู้ทำการยกเลิก </th>}
                                                            {this.state.status === 'void' && <th className="text-center"> {t("PageListPRPO:Cancled date")}</th>}
                                                            {this.state.status === 'void' && <th className="text-left"> {t("fix_request:reason_for_cancellation")}</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {props.allDebsFreeCertification.edges.length > 0  && props.allDebsFreeCertification.edges.map((debtFreeCertification,index) => {
                                                            return(
                                                                <DebtFreeCertificationTable 
                                                                    index={index} status={this.state.status} 
                                                                    onCheck={this.onCheck} 
                                                                    key={index}
                                                                    selectedId={this.state.selectedId} 
                                                                    debtFreeCertification={debtFreeCertification}
                                                                />
                                                            )
                                                        })}
                                                    </tbody>
                                                    </table>
                                                </div>
                                                <div className="row">
                                                    <Pagination
                                                        changePage={this.changePage}
                                                        first= {this.state.first}
                                                        last={this.state.last}
                                                        totalCount={props.allDebsFreeCertification.totalCount}
                                                    />
                                                </div>
                                            </React.Fragment>
                                            }
                                        </Translation>
                                    );
                                    }
                                    return <Loading/>
                                }}
                            /> 
                            
                        </div>
                        
                    </div>
                </div>

            </Wrapper>

        )
    }
}

export default DebtFreeCertificationList;
