import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';
import _ from "lodash";


class Navigation extends Component {
    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t=>
                        <ul>
                            <li>
                                <NavLink exact={true}
                                    to={{pathname: "/register/residential/detail/room-detail/" + this.props.id}}>
                                    {t("resident:Room detail")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={{pathname: "/register/residential/detail/owner-detail/"+this.props.id}}>
                                    {
                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                    textData?.data?.roomDetail.ownerDetail    
                                        :                                          
                                    t("resident:Owner detail")
                                    }
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={{pathname: "/register/residential/detail/key-card-detail/"+this.props.id}}>
                                    {t("resident:Keycard detail")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={{pathname: "/register/residential/detail/vehicle-detail/"+this.props.id}}>
                                    {t("resident:Venhicle detail")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={{pathname: "/register/residential/detail/pet-detail/"+this.props.id}}>
                                    {t("resident:Pet detail")}
                                </NavLink>
                            </li>
                                <li>
                                    <NavLink
                                        to={{ pathname: "/register/residential/detail/ownership-detail/" + this.props.id}}>
                                        {
                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                        textData?.data?.roomDetail.ownership    
                                        :                                                   
                                        t("topMenuNavigation:ownership")
                                        }
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        to={{ pathname: "/register/residential/detail/history-detail/"  + this.props.id }}>
                                        {t("topMenuNavigation:ResidentialHistory")}
                                    </NavLink>
                                </li>
                        </ul>}
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;