import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
  query contactViewerQueryQuery{
    contactViewer{
        allContact(typeOfContact: "residential" , typeOfPayment: "receivable", isActive: true ,order: "name"){
            edges{
                node{
                    id
                    refNumber
                    name
                    firstName
                    lastName
                    residential {
                        id
                        conveyance
                        size
                        address
                        ownershipRatio
                        ownershipArea
                        homeNumber
                        villageBuilding
                        villageNumber
                        lane
                        soi
                        plan
                        postalCode
                        province
                        district
                        subDistrict
                        mailingVillageBuilding
                        mailingVillageNumber
                        mailingLane
                        mailingPostalCode
                        mailingProvince
                        mailingDistrict
                        mailingSubDistrict

                        residentialHouseholder {
                            edges {
                                node {
                                    id
                                    type
                                    tenant {
                                        id
                                        firstName
                                        lastName
                                        tenantStatus
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    allSettingDebsFreeCertification {
        edges{
            node{
                id
                fee
                charge
                waiting
                lifetime
            }
        }
    }  
  }
`;


const contactViewerQuery = (search) => {
    return new Promise((resolve, reject) => {
        fetchQuery(environment, query, {search : search}).then(data => {
            resolve(data)
        });
    });
  
  };
  
  export default contactViewerQuery;