import React from 'react';
import Select from 'react-select';

const _ = require('lodash');

const empty_value = {value: "", label: ""};


class SearchSelectChartOfAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            options: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.getLabel = this.getLabel.bind(this);
    }

    getLabel(data) {

        let label = '';
        let labels = this.props.keyOfLabel.split(':');
        labels.map(function (string) {
            return label = label + " " + _.get(data,string, "");
        });
        return label;
    }

    componentDidMount() {
        this.setDropDown()
    }

    setDropDown() {
        let options = [];
        let query = this.props.queryObject || [];
        let _this = this;
        query.forEach((data, index) => {
            options = [...options, {value: index, label: _this.getLabel(data)}]

        });

        this.setState({options: options}, () => {
            if (this.props.value || this.props.value  === 0) {
                this.state.options.forEach((option) => {
                    if (option.value === this.props.value) {
                        this.setState({selected: option});
                    }
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.queryObject !== this.props.queryObject) {
            this.setDropDown()
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

        if (nextProps.value !== this.props.value) {
            let found = false;
            this.state.options.forEach((option) => {
                if (option.value === nextProps.value) {
                    found = true;
                    this.setState({selected: {...option}});
                }
            });

            if (!found) {
                this.setState({selected: {...empty_value}})
            }
        }
    }

    handleChange(changed_value) {
        let value = changed_value || {value: ""};
        let event = {
            target: {
                name: this.props.name,
                value: value.value,
                className: this.props.className,
                dataset: {id: this.props['data-id'], key: this.props['data-key']}
            }
        };
        this.setState({selected: value});
        this.props.onChange(event);
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                <Select
                    value={this.state.selected}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                    options={this.state.options}
                    isDisabled={this.props.disabled}
                    isClearable={this.props.isClearable}
                />
                <input name="req" type="text" value={this.props.value || ""}
                       style={{position: "absolute", top: 5, left: 5, width: 5, zIndex: -1}}
                       onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                       required={this.props.require}/>
            </div>
        )
    }
}

export default SearchSelectChartOfAccount;

