import i18next from 'i18next';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { NavLink } from "react-router-dom";


class Navigation extends Component {
    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink to="/accounting/expense/accept-credit-note/list/all">
                                        {i18next.t("PurchaseCreditNote:All")}
                                    </NavLink>
                                </li>

                                {/* <li>
                            <NavLink to="/accounting/expense/accept-credit-note/list/wait">
                                ยังไม่ชำระ
                            </NavLink>
                        </li> */}

                                {/* <li>
                            <NavLink to="/accounting/expense/accept-credit-note/list/waiting_pay">
                                รอจ่ายชำระ
                            </NavLink>
                        </li> */}

                                <li>
                                    <NavLink to="/accounting/expense/accept-credit-note/list/paid">
                                        {i18next.t("PurchaseCreditNote:Paid")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/expense/accept-credit-note/list/void">
                                        {i18next.t("PurchaseCreditNote:Cancelled")}
                                    </NavLink>
                                </li>


                            </ul>
                        </div>
                    </div>
                }
            </Translation>

        )
    }
}

export default Navigation;