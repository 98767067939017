import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "react-relay";
import { format } from "date-fns";
import ReceivableOutstandingReportTable from "./receivableOutstandingReportTable";
import ExportReceivableOutstandingReportOnLoad from "./exportReceivableOutstandingReportOnLoad";
import GrahpAPI from "./receivableOutstandingReportSummary";
import ProductServiceList from "../productServiceList";
import ContactReportList from "../contactReportList";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import jwtDecode from 'jwt-decode'
import Swal from "sweetalert2";
import _ from "lodash";
import i18next from "i18next";

const query = graphql`
  query receivableOutstandingReportQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $endDateOnDate: Date
    $search: String
    $customerType: String
    $productAndService: String
    $productAndServiceID: ID
    $contact: String
    $overdueDate: String
    $rangeOverdueDate: [Int]
    # $dueDate: Date!
  ) {
    invoiceViewer {
      allInvoice(
        approveOnly: true
        receivableOutstanding: true
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        customerType: $customerType
        productAndService: $productAndService
        # transaction_ProductAndService_Id: $productAndServiceID
        contact: $contact
        order: "contact__residential__name"
        status_In: "active, overdue, partial_payment, paid"
        overdueDate: $overdueDate
        rangeOverdueDate: $rangeOverdueDate
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            payGroup
            firstName
            lastName
            name
            contact {
              id
              name
              firstName
              lastName
              refNumber
              typeOfContact
              residential {
                id
                name
              }
            }
            transaction(productAndService_Id: $productAndServiceID) {
              edges {
                node {
                  id
                  description
                  whtRate
                  total
                  productAndService {
                    id
                    productCode
                    name
                  }
                  chartOfAccount {
                    chartOfAccountCode
                  }
                  receiveTransaction(receive_Status_In: "paid" , receive_IssuedDate_Lte:$endDateOnDate) {
                    edges {
                      node {
                        amount
                        added
                        receive {
                          issuedDate

                        }
                      }
                    }
                  }
                  creditNoteTransaction(status: "paid",issuedDate_Lte:$endDateOnDate, receive_Isnull:true ) {
                    edges {
                      node {
                        price
                        issuedDate
                        receive {
                          id
                        }
                      }
                    }
                  }
                  creditNoteTransactionRecord(status: "paid",creditNote_IssuedDate_Lte:$endDateOnDate){
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              price
                              status
                          }
                      }
                  }
                  receiptDepositTransactionUsed(receiveTransaction_Isnull:true) {
                    edges {
                      node {
                        amount
                        receiveTransaction {
                          id

                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
      }


    }


  }
`;

class ReceivableOutstandingReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    let user = jwtDecode(localStorage.getItem("token"))

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.product_and_service = "";
    this.state.temp_product_and_service = "";
    this.state.customer_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.overdue_date = "";
    this.state.temp_overdue_date = "";
    this.state.range_overdue_date = [null, null];
    this.state.temp_range_overdue_date = [null, null]
    this.state.data = null
    this.state.loading = false
    this.state.summaryReceivableOutstanding = null
    this.state.summaryReceivableOutstandingLoading = false
    this.state.reportOutstandingReceivableSummary = null
    this.state.user = user

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.queryData = this.queryData.bind(this);
    this.queryDataSummary = this.queryDataSummary.bind(this);
  }

  componentDidMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "receivableOutstandingReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })
    this.queryData();
    this.queryDataSummary();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (
        (prevState.first === this.state.first || prevState.last === this.state.last) &
        (
          prevState.end_date !== this.state.end_date ||
          prevState.search !== this.state.search ||
          prevState.customer_type !== this.state.customer_type ||
          prevState.product_and_service !== this.state.product_and_service ||
          prevState.contact !== this.state.contact ||
          prevState.overdue_date !== this.state.overdue_date ||
          prevState.range_overdue_date !== this.state.range_overdue_date ||
          prevState.dueDate !== this.state.dueDate
        )
      )
    ) {
      this.queryData();
      this.queryDataSummary();
    }
    else if ((prevState.first !== this.state.first || prevState.last !== this.state.last) &
      (prevState.end_date === this.state.end_date &
        prevState.search === this.state.search &
        prevState.customer_type === this.state.customer_type &
        prevState.product_and_service === this.state.product_and_service &
        prevState.contact === this.state.contact &
        prevState.overdue_date === this.state.overdue_date &
        prevState.range_overdue_date === this.state.range_overdue_date &
        prevState.dueDate === this.state.dueDate
      )) {
      this.queryData()
    }

  }

  queryDataSummary = async () => {
    this.setState({ summaryReceivableOutstandingLoading: true })
    const grahpAPIres = await GrahpAPI.receivableOutstandingReportSummary(this.state)
    this.setState({
      summaryReceivableOutstanding: grahpAPIres.invoiceViewer.summaryReceivableOutstanding,
      reportOutstandingReceivableSummary: grahpAPIres.reportOutstandingReceivableSummary,
      summaryReceivableOutstandingLoading: false,
    })
  }

  queryData = () => {
    this.setState({ loading: true })


    fetchQuery(environment, query, {
      first: this.state.first,
      last: this.state.last,
      startDate: this.state.end_date,
      endDate: this.state.end_date,
      endDateOnDate: format(this.state.end_date, "YYYY-MM-DD"),
      search: this.state.search,
      customerType: this.state.customer_type,
      productAndService: this.state.product_and_service,
      productAndServiceID: this.state.product_and_service,
      contact: this.state.contact,
      overdueDate: this.state.overdue_date,
      rangeOverdueDate: this.state.range_overdue_date,
      dueDate: this.state.end_date.toISOString().slice(0, 10),
    }).then(data => {
      this.setState({
        data: data,
        loading: false,
      })
    })
      .catch(error => {
        Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
      })

  }

  handleChange(e) {
    if (e.target.name === "temp_overdue_date") {
      let min_date = null;
      let max_date = null;
      if (e.target.value === "1-30") {
        min_date = 1;
        max_date = 30;
      } else if (e.target.value === "31-60") {
        min_date = 31;
        max_date = 60;
      } else if (e.target.value === "61-90") {
        min_date = 61;
        max_date = 90;
      } else if (e.target.value === "91-120") {
        min_date = 91;
        max_date = 120;
      } else if (e.target.value === "121-150") {
        min_date = 121;
        max_date = 150;
      } else if (e.target.value === "151-180") {
        min_date = 151;
        max_date = 180;
      } else if (e.target.value === "over180") {
        min_date = 181;
        max_date = null;
      } else if (e.target.value === "not_overdue") {
        min_date = null;
        max_date = 0;
      }
      this.setState({
        temp_range_overdue_date: [min_date, max_date],
      })
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      product_and_service: this.state.temp_product_and_service,
      contact: this.state.temp_contact,
      range_overdue_date: this.state.temp_range_overdue_date,
      overdue_date: this.state.temp_overdue_date
    });
    super.goFirst()
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting/report/account-receivable">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{i18next.t("receivable_dashboard:Detailed Outstanding Receivables Report")}</span>}
                  </Translation>
                </h3>
              </div>
              {/* <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) && <ExportReceivableOutstanding state={this.state} />}
              </div> */}

              {/* {(this.state.user?.username.includes("admin-svm") || this.state.user?.username.includes("admin-devsilverman")) && */}
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) && <ExportReceivableOutstandingReportOnLoad state={this.state} />}
              </div>
              {/* } */}


            </div>

            <Translation>
              {t =>
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      {/* <div className="float-right ml-2">
                                        <DatePickerAdapter
                                            name="start_date" className="form-control"
                                            selected={this.state.start_date}
                                            // minDate={this.state.min_date}
                                            maxDate={this.state.end_date}
                                            onChange={this.handleChange}
                                            required={true}
                                        />
                                    </div> */}
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          {/* <div className="col-md-4 border-right">
                                                <div className="form-group">
                                                    <label>ประเภทลูกค้า</label>
                                                    <select name="customer_type" onChange={this.handleChange}
                                                            className="form-control dropdown-custom-arrow">
                                                        <option value="">ทั้งหมด</option>
                                                        <option value="residential">เฉพาะเจ้าของร่วม/สมาชิก</option>
                                                        <option value="supplier">เฉพาะลูกหนี้</option>
                                                    </select>
                                                </div>
                                            </div> */}

                          <ContactReportList
                            handleChange={this.handleChange}
                            contact={this.state.temp_contact}
                          />

                          <ProductServiceList
                            handleChange={this.handleChange}
                            product_and_service={
                              this.state.temp_product_and_service
                            }
                          />
                          <div className="col-md-4 border-right">
                            <div className="form-group">
                              <label>{i18next.t("receivableOutstandingReport:Due Date (Days)")}</label>
                              <select name="temp_overdue_date" onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow">
                                <option value="">{i18next.t("receivableOutstandingReport:All")}</option>
                                <option value="not_overdue">{i18next.t("receivableOutstandingReport:Not Exceeding")}</option>
                                <option value="1-30">1 - 30</option>
                                <option value="31-60">31 - 60</option>
                                <option value="61-90">61 - 90</option>
                                <option value="91-120">91 - 120</option>
                                <option value="121-150">121 - 150</option>
                                <option value="151-180">151 - 180</option>
                                <option value="over180">{i18next.t("receivableOutstandingReport:More Than")} 180</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.data && this.state.reportOutstandingReceivableSummary && this.state.reportOutstandingReceivableSummary && !this.state.loading ?
                    <React.Fragment>
                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="card fade-up">
                            <div className="table-responsive">
                              <table
                                className="table table-hover"
                                style={{ fontSize: 12 }}
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th
                                      rowSpan={2}
                                      className="align-middle text-center"
                                    >
                                      {i18next.t("receivableOutstandingReport:Sequence")}
                                    </th>
                                    <th rowSpan={2} className="align-middle">
                                      {i18next.t("receivableOutstandingReport:Room/House Number")}
                                    </th>
                                    <th rowSpan={2} className="align-middle">
                                      {i18next.t("receivableOutstandingReport:Name")}
                                    </th>
                                    <th
                                      rowSpan={2}
                                      width={130}
                                      className="align-middle text-center"
                                    >
                                      {i18next.t("receivableOutstandingReport:Invoice Number")}
                                    </th>
                                    <th rowSpan={2} className="align-middle">
                                      {i18next.t("receivableOutstandingReport:ID")}
                                    </th>
                                    <th rowSpan={2} className="align-middle">
                                      {i18next.t("receivableOutstandingReport:Details")}
                                    </th>
                                    <th
                                      rowSpan={2}
                                      className="align-middle text-center"
                                    >
                                      {i18next.t("receivableOutstandingReport:Issue Date")}
                                    </th>
                                    <th
                                      rowSpan={2}
                                      className="align-middle text-center"
                                    >
                                      {i18next.t("receivableOutstandingReport:Due Date")}
                                    </th>
                                    <th
                                      rowSpan={2}
                                      className="align-middle text-right"
                                    >
                                      {i18next.t("receivableOutstandingReport:Days")}
                                    </th>
                                    {(this.state.overdue_date === "" || this.state.overdue_date === "not_overdue") &&
                                      <th rowSpan={2}
                                        className="align-middle text-center">{i18next.t("receivableOutstandingReport:Not Exceeding")}
                                      </th>
                                    }
                                    {this.state.overdue_date !== "not_overdue" &&
                                      <th colSpan={this.state.overdue_date === "" ? 7 : 1}
                                        className="text-center">{i18next.t("receivableOutstandingReport:Due Date (Days)")}
                                      </th>
                                    }
                                    <th
                                      rowSpan={2}
                                      className="align-middle text-right"
                                    >
                                      {i18next.t("receivableOutstandingReport:Outstanding")}
                                    </th>
                                    <th
                                      rowSpan={2}
                                      className="align-middle text-right"
                                    >
                                      {i18next.t("receivableOutstandingReport:Net Outstanding")}
                                    </th>
                                  </tr>
                                  {this.state.overdue_date === "" ?
                                    <tr className="border-top">
                                      <th className="text-center" width={50}>1-30</th>
                                      <th className="text-center" width={60}>31-60</th>
                                      <th className="text-center" width={60}>61-90</th>
                                      <th className="text-center" width={60}>91-120</th>
                                      <th className="text-center" width={60}>121-150</th>
                                      <th className="text-center" width={60}>151-180</th>
                                      <th className="text-center">{i18next.t("receivableOutstandingReport:More Than")} 180</th>
                                    </tr> :
                                    this.state.overdue_date !== "not_overdue" &&
                                    <tr>
                                      <th className="text-center">{this.state.overdue_date === "over180" ? "มากกว่า 180" : this.state.overdue_date}</th>
                                    </tr>
                                  }
                                </thead>
                                <tbody>
                                  <ReceivableOutstandingReportTable
                                    props={this.state.data}
                                    state={this.state}
                                  />
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={
                            this.state.data.invoiceViewer.allInvoice.totalCount
                          }
                        />
                      </div>
                    </React.Fragment>
                    :
                    <Loading />
                  }


                </div>
              }
            </Translation>

          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ReceivableOutstandingReport;
