import React from "react";
import { Translation } from "react-i18next";
import ComponentPagination from "../../../../libs/componentPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import { Redirect } from "react-router";
import PayableMovementTable from "./payableMovementTable";
import PayableMovementSummary from "./payableMovementSummary";
import ServerExport from "./serverExport";
import _ from "lodash";
import Loading from "../../../../libs/loading";
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
  query payableMovementQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $search: String
    $contactId: ID
  ) {
    allPurchaseRecordGroup(
      first: $first
      last: $last
      startDate: $startDate
      endDate: $endDate
      search: $search
      contact_Id: $contactId
      order: "doc_number"
      status_In: "active, paid, partial_payment "
    ) {
      totalCount
      edges {
        node {
          id
          docNumber
          issuedDate
          dueDate
          contact {
            id
            name
            firstName
            lastName
            refNumber
            typeOfContact
          }

          purchaseRecord {
            edges {
              node {
                id
                description
                total

                payRecord(payRecordGroup_Status: "paid") {
                  edges {
                    node {
                      id
                      amount
                      whtRatePercent
                      whtAmount
                      payRecordGroup {
                        id
                        docNumber
                        issuedDate
                        status
                        total
                      }
                    }
                  }
                }

                acceptCreditNoteTransaction(status: "paid") {
                  edges {
                    node {
                      id
                      docNumber
                      issuedDate
                      price
                    }
                  }
                }

                prepaidDepositTransactionUsed {
                  edges {
                    node {
                        id
                        amount
                      prepaidDeposit {
                        id
                        docNumber
                        issuedDate
                        total
                      }
                      prepaidDepositTransaction {
                       id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class PayableMovement extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.search = "";
    this.state.search_input = "";
    this.state.customer_type = "";
    this.state.contact = props.customerList;

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "payableMovementQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  render() {
    if (this.props.customerList.length > 0) {
      return (
        <Translation>
          {t =>
            <div className="container-fluid box">
              <BackButtonIcon
                LinkBack={"/accounting/report/account-payable/payable_movement/select-customer"}
                LinkText={i18next.t("DashboardPayable:Payables Movement Report")}
                boxHtmlText={
                  <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "report_payable_print",
                    }) && <ServerExport state={this.state} />}
                  </div>
                }
              />

              <div className="content-inner">
                <div className="row justify-content-end mb-2">
                  <div className="col">
                    <div className="input-group float-right w-auto ml-2">
                      <input
                        type="text"
                        className="form-control search-input input-size"
                        placeholder={i18next.t("AllFilter:Search")}
                        name="search"
                        value={this.state.search_input}
                        onChange={(e) =>
                          this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleSearch(e)}
                      >
                        {i18next.t("AllFilter:Search")}
                      </button>
                    </div>
                    <div className="float-right ml-2">
                      <DatePickerAdapter
                        name="temp_end_date"
                        className="form-control"
                        selected={this.state.temp_end_date}
                        maxDate={this.state.current_date}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>

                    <div className="float-right ml-2">
                      <DatePickerAdapter
                        name="temp_start_date"
                        className="form-control"
                        selected={this.state.temp_start_date}
                        // minDate={this.state.min_date}
                        maxDate={this.state.temp_end_date}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                </div>

                <QueryRenderer
                  environment={environment}
                  query={query}
                  cacheConfig={{ use_cache: false }}
                  variables={{
                    first: this.state.first,
                    last: this.state.last,
                    startDate: this.state.start_date,
                    endDate: this.state.end_date,
                    search: this.state.search,
                    contactId: this.props.customerList,
                  }}
                  render={({ error, props }) => {
                    if (props) {
                      return (
                        <React.Fragment>
                          <div className="row mt-3">
                            <div className="col-12">
                              <div className="card fade-up">
                                <div className="table-responsive">
                                  <table className="table table-hover table-bordered">
                                    <thead className="thead-light">
                                      <tr>
                                        <th className="text-center">{i18next.t("PayableMovement:Order")}</th>
                                        <th className="text-center" width="80" >
                                          {i18next.t("PayableMovement:Invoice Date")}
                                        </th>
                                        <th className="text-center" width={130}>
                                          {i18next.t("PayableMovement:Invoice Number")}
                                        </th>
                                        <th className="text-center" width={130} >{i18next.t("PayableMovement:Description")}</th>
                                        <th className="text-center">{i18next.t("PayableMovement:Amount")}</th>
                                        <th className="text-center">
                                          {i18next.t("PayableMovement:Payment Date")}
                                        </th>
                                        <th className="text-center" width={130}>
                                          {i18next.t("PayableMovement:Payment Number")}
                                        </th>
                                        <th className="text-center">{i18next.t("PayableMovement:Received Amount")}</th>
                                        <th className="text-center">
                                          {i18next.t("PayableMovement:Advance Payment Date")}
                                        </th>
                                        <th className="text-center">
                                          {i18next.t("PayableMovement:Advance Payment")}
                                        </th>
                                        <th className="text-center">
                                          {i18next.t("PayableMovement:Advance Payment Amount")}
                                        </th>
                                        <th className="text-center">
                                          {i18next.t("PayableMovement:Credit Note Date")}
                                        </th>
                                        <th className="text-center">{i18next.t("PayableMovement:Credit Note")}</th>
                                        <th className="text-center">{i18next.t("PayableMovement:Received Amount")}</th>
                                        <th className="text-center">{i18next.t("PayableMovement:Remaining Amount")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <PayableMovementTable
                                        query={props}
                                        state={this.state}
                                      />
                                    </tbody>
                                    <tfoot>
                                      <PayableMovementSummary
                                        query={props}
                                        state={this.state}
                                      />
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return <Loading />
                    }
                  }}
                />
              </div>
            </div>
          }
        </Translation>

      );
    }
    return (
      <Redirect to="/accounting/report/account-payable/payable_movement/select-customer" />
    );
  }
}

export default PayableMovement;
