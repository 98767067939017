import React from 'react';
import Wrapper from "../../components/wrapper";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import { addMonths, differenceInCalendarDays, differenceInCalendarMonths, lastDayOfMonth } from "date-fns";
import ReceiveListTable from "./receiveListTable";
import PayListTable from "./payListTable";
import SummaryListTable from "../accrualBasis/summaryListTable";
import ChannelPayListTable from "./channelPayListTable";
import ExportCashBasis from "./serverExport";
import { graphql } from "babel-plugin-relay/macro";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import i18next from 'i18next';

const query = graphql`
    query cashBasisQuery ($typeOfReport: String, $start_date: DateTime, $end_date: DateTime, $month_count: Int) {
        chartOfAccountCashBasisPay(startDate: $start_date, endDate: $end_date, monthCount: $month_count, typeOfReport: $typeOfReport,typeOfPay: "other_pay") {
            edges {
                node {
                    id
                    name
                    chartOfAccountCode
                    col1Debit col2Debit col3Debit col4Debit col5Debit col6Debit col7Debit col8Debit col9Debit col10Debit col11Debit col12Debit
                }
            }
            
            totalCount
        }
    }
`;

const type_of_report = [
    {
        name: "2.1 ต้นทุนขายสุทธิ / Cost of Goods Sold",
        code: "5100",
        summary_name: "รวมรายจ่ายต้นทุนขายสุทธิ / Total Cost of Goods Sold",
        header: true
    },
    {
        name: "2.2 เงินเดือนเเละค่าจ้าง / Salaries and wages",
        code: "5200",
        summary_name: "รวมรายจ่ายเงินเดือนเเละค่าจ้าง / Total Salaries and wages",

    },
    {
        name: "2.3 รายจ่าย - ตามสัญญา / Expense - by agreement",
        code: "5300",
        summary_name: "รวมรายจ่าย - ตามสัญญา / Total Expense - by agreement",
    },
    {
        name: "2.4 รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office",
        code: "5400",
        summary_name: "รวมรายจ่ายดำเนินงานและสำนักงาน / Total Operation expense - Office",
    },
    {
        name: "2.5 รายจ่ายสาธารณูปโภค / Operation expense - Utility",
        code: "5500",
        summary_name: "รวมรายจ่ายสาธารณูปโภค / Total Operation expense - Utility",
    },
    {
        name: "2.6 รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair",
        code: "5600",
        summary_name: "รวมรายจ่าย - ซ่อมเเซมและบำรุงรักษา / Total Operation expense - Maintenance & Repair",
    },
    // {
    //     name:"2.7 รายจ่ายค่าเสื่อมราคา / Depreciation and Amortization expense",
    //     code:"5700",
    //     summary_name: "รวมรายจ่ายค่าเสื่อมราคา / Total Depreciation and Amortization expense",
    // },
    {
        name: "2.7 ค่าใช้จ่ายอื่น ๆ / Other expenses",
        code: "5800",
        summary_name: "รวมรายจ่ายค่าใช้จ่ายอื่น ๆ / Total Other expenses",
    },
    {
        name: "2.8 หมวดทรัพย์สิน / Asset",
        code: "asset",
        summary_name: "รวมหมวดทรัพย์สิน / Total Asset",
    },
    {
        name: "2.9 หมวดภาษีหัก ณ ที่จ่าย / Withholding Tax",
        code: "tax",
        summary_name: "รวมหมวดภาษีหัก ณ ที่จ่าย / Total Withholding Tax",
    },
    {
        name: "2.10 หมวดอื่น ๆ / Other",
        code: "other",
        summary_name: "รวมหมวดหมวดอื่น ๆ / Total Other",
    },
];
const channel_pay = [
    {
        code: "current_assets",
        header_name: "1. เงินสดและเงินฝากธนาคารหมุนเวียน",
        summary_name: "รวมเงินสดและเงินฝากธนาคารหมุนเวียน",
        header: true
    },
    {
        code: "non_current_bank",
        header_name: "2. เงินฝากธนาคารไม่หมุนเวียน",
        summary_name: "รวมเงินฝากธนาคารไม่หมุนเวียน",
        all_summary: true

    },

];

class CashBasis extends React.Component {
    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            start_period_before: new Date(now.getFullYear(), 0),
            end_period_before: lastDayOfMonth(new Date(now.getFullYear(), 11)),
            start_period: new Date(now.getFullYear(), 0),
            end_period: lastDayOfMonth(new Date(now.getFullYear(), 11)),
            total_receive: [],
            total_pay: [],
            total_cash_and_bank: [],
            total_cash_and_bank_historical: [],
            summary_index: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            month_count: 11,
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateSummary = this.updateSummary.bind(this);
        this.calSummary = this.calSummary.bind(this);
        this.calDate = this.calDate.bind(this)
    }

    handleChange(event) {
        let valuse = event.target.value
        // if (event.target.name === 'start_period_before') {
        //     let dif_month = differenceInCalendarMonths(this.state.end_period_before, event.target.value);
        //     let dif_day = differenceInCalendarDays(this.state.end_period_before, event.target.value);
        //     if ((dif_month < 0 && dif_month !== -0) || dif_month >= 12 || (dif_month === 0 && dif_day < 0)) {
        //         this.setState({
        //             end_period_before: lastDayOfMonth(addMonths(event.target.value, 11)),
        //             month_count: 11,
        //             summary_index: Array.from(new Array(13))
        //         })
        //     } else {
        //         this.setState({month_count: dif_month, summary_index: Array.from(new Array(dif_month + 2)) })
        //     }
        // }
        // if (event.target.name === 'end_period_before') {
        //     let dif = differenceInCalendarMonths(event.target.value, this.state.start_period_before);
        //     this.setState({month_count: dif, summary_index: Array.from(new Array(dif + 2))})
        // }

        if (event.target.name === 'start_period_before') {
            let dif_month = differenceInCalendarMonths(this.state.end_period_before, event.target.value);
            if (dif_month > 11) {
                let date_end = event.target.value
                this.setState({ end_period_before: lastDayOfMonth(new Date(date_end.getFullYear(), 11)) })
            }
        }
        if (event.target.name === 'end_period_before') {
            valuse = lastDayOfMonth(new Date(valuse))
        }

        this.setState({
            [event.target.name]: valuse,
            total_revenue: [],
            total_pay: [],
            total_expenditure: [],
            total_cash_and_bank: [],
            total_cash_and_bank_historical: []
        });
    }

    updateSummary(name, value) {
        if (name === 'total_pay') {
            let total = [...this.state.total_pay];
            value.summary.map((summary, index) => (
                _.set(total, index, _.get(total, index, 0) + summary)
            ));
            this.setState({ [name]: total })

        }
        else if (name === 'total_cash_and_bank') {
            let total_cash_and_bank = [...this.state.total_cash_and_bank];
            value.summary.map((summary, index) => (
                _.set(total_cash_and_bank, index, _.get(total_cash_and_bank, index, 0) + summary)
            ));
            this.setState({ [name]: total_cash_and_bank })

        } else if (name === 'total_cash_and_bank_historical') {
            this.setState({ [name]: value })
        } else {
            this.setState({ [name]: value.summary })
        }

    }

    calSummary(receive, pay, bank_and_cash = null) {
        if (bank_and_cash) {
            return bank_and_cash + (receive - pay)
        }
        else {
            return receive - pay
        }
    }

    calDate() {
        this.setState({
            loading: true,
        })
        let dif_month = differenceInCalendarMonths(this.state.end_period_before, this.state.start_period_before);
        let dif_day = differenceInCalendarDays(this.state.end_period_before, this.state.start_period_before);
        if ((dif_month < 0 && dif_month !== -0) || dif_month >= 12 || (dif_month === 0 && dif_day < 0)) {
            this.setState({
                start_period: this.state.start_period_before,
                end_period: this.state.end_period_before,
                month_count: 11,
                summary_index: Array.from(new Array(13))
            })
        } else {
            this.setState({
                start_period: this.state.start_period_before,
                end_period: this.state.end_period_before,
                month_count: dif_month,
                summary_index: Array.from(new Array(dif_month + 2)),
            });
        }
        this.setState({
            loading: false,
        })

    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />

                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t('accounting:cash flow statement')}
                                    boxHtmlText={
                                        <div className="col text-right">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_cash_flow_statement_print' }) &&
                                                <ExportCashBasis state={this.state} />
                                            }
                                        </div>
                                    }
                                /> 
                                <div className="content-inner">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <div className="form-inline float-left">
                                                <div className="form-group mb-2">
                                                    <label htmlFor="start_period_before" className="ml-2 mr-3">{i18next.t("AllFilter:Date")}</label>
                                                    <DatePickerAdapter id="start_period_before"
                                                        className="form-control" name="start_period_before"
                                                        selected={this.state.start_period_before}
                                                        onChange={this.handleChange}
                                                        showMonthYearPicker={true}
                                                        selectsStart={true}
                                                        dateFormat={"MM/yyyy"}
                                                        startDate={this.state.start_period_before}
                                                        endDate={this.state.end_period_before}
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label htmlFor="end_period_before" className="ml-3 mr-3">{i18next.t("AllFilter:to")}</label>
                                                    <DatePickerAdapter id="end_period_before" name="end_period_before"
                                                        className="form-control"
                                                        selected={this.state.end_period_before}
                                                        onChange={this.handleChange}
                                                        maxDate={lastDayOfMonth(addMonths(this.state.start_period_before, 11))}
                                                        minDate={this.state.start_period_before}
                                                        showMonthYearPicker={true}
                                                        selectsEnd={true}
                                                        dateFormat={"MM/yyyy"}
                                                        startDate={this.state.start_period_before}
                                                        endDate={this.state.end_period_before}
                                                    />
                                                </div>
                                                <div className="form-group ml-2 mb-2">
                                                    <button type="button"
                                                        className="btn btn-primary form-control"
                                                        name="search"
                                                        // value={this.state.search_input}
                                                        onClick={() => this.calDate()
                                                        }
                                                    >{i18next.t("AllFilter:Search")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="table-responsive fade-up card mb-4">
                                                <table className="table table-bordered small">
                                                    <ReceiveListTable state={this.state} updateSummary={this.updateSummary}
                                                        summary_index={this.state.summary_index} />
                                                    {type_of_report.map((expenditure, index) => {
                                                        return (
                                                            <PayListTable state={this.state} typeOfReport={expenditure}
                                                                key={expenditure.code}
                                                                updateSummary={this.updateSummary}
                                                                header={index === 0 ? "true" : false}
                                                                query_expense={query}
                                                            />
                                                        )
                                                    }
                                                    )}
                                                    <tbody>
                                                        <SummaryListTable text_summary="รวมรายจ่ายทั้งสิ้น / Total Expenditure"
                                                            summary={this.state.total_pay}
                                                            list_data_loop={this.state.summary_index} />
                                                        <tr>
                                                            <td width={360}><strong>คงเหลือเงินสดหมุนเวียนในการดำเนินงาน / Cash
                                                                Balance</strong></td>
                                                            {this.state.summary_index.map((value, index) => {
                                                                let summary = this.calSummary(parseFloat(this.state.total_receive[index] || 0), parseFloat(this.state.total_pay[index] || 0));
                                                                return (
                                                                    <td width={100} key={index * 1.2}
                                                                        className="text-right">
                                                                        <strong>{numberWithComma(summary)}</strong>
                                                                    </td>
                                                                );
                                                            })
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td width={360}><strong>คงเหลือเงินสดหมุนเวียนในการดำเนินงานยกไป / Cash /C/F</strong></td>
                                                            {this.state.summary_index.map((value, index) => {
                                                                let summary = this.calSummary(parseFloat(this.state.total_receive[index] || 0),
                                                                    parseFloat(this.state.total_pay[index] || 0),
                                                                    parseFloat(this.state.summary_index.length === (index + 1) ? this.state.total_cash_and_bank_historical[0] : this.state.total_cash_and_bank_historical[index] || 0));
                                                                return <td className="text-right" key={'cash-c-f-' + index}>
                                                                    <strong>{numberWithComma(summary)}</strong></td>
                                                            })
                                                            }
                                                        </tr>
                                                        <tr><td colSpan={14} className="text-center">&nbsp;</td></tr>
                                                    </tbody>
                                                    {channel_pay.map((channel) => (
                                                        <ChannelPayListTable state={this.state} channel={channel} key={channel.code}
                                                            updateSummary={this.updateSummary}
                                                            total_cash_and_bank={this.state.total_cash_and_bank} />
                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default CashBasis;
