import i18next from 'i18next';
import React, {Component} from 'react';
import {Translation} from "react-i18next";
import Select from 'react-select';
import "./interestReport.scss"
 
class selectInterest extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedID : [],
            selectList : [],
            default : []
        }
    }

    handleChange = (value) => {
        this.setState({
            selectList : value
        })
    }

    handleSearch = async () => {
        await this.props.onSearch(this.state.selectList)
    }

    render() {
        return (
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        <div className="row mt-2">
                            <div className="col-sm-12 col-md-6">
                                <Select
                                    hideSelectedOptions={false}
                                    options={this.props.selectOption}
                                    controlShouldRenderValue={false}
                                    // value = {this.state.selectList}
                                    isMulti
                                    // defaultInputValue ={[]}
                                    placeholder ={i18next.t("interestDashboard:Select Interest Income Items")}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="selected-box p-4">
                                    {this.state.selectList.map((item,index) => {
                                        return(
                                            <div key={index} className="mb-4">
                                                <span className="mt-4">{item.label}</span> <br/>
                                            </div>
                                        )
                                    })
                                    }
    
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 pr-4">
                            <div className=" col-12 float-right d-flex justify-content-end">
                                <button type="button" className="btn btn-search" onClick={() => this.handleSearch()}>{i18next.t("AllFilter:Search")}</button>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        );
    }
}

export default selectInterest;
