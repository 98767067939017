import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import CollectionLetter from './collectionLetter'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import _ from 'lodash';
import OutStandingCollectionLetter from './OutstandingCollectionLetter';
import Loading from "../../libs/loading";
import WrapperCollectionLetterOldTime from './wrapperCollectionLetterOldTime';

const query = graphql`
    query wrapperCollectionLetterQuery($collectionLettersId: [String] , $formDebit: Int) {
        selfProject{
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            bankCompCode
            bankServiceCode
            taxIdNumber
            typeOfProject
            typeOfCompany
            establishmentStatus
        }
        allSettingAutomaticDebit{
            edges{
                node{
                    receivableOutstanding
                }
            }
        }
        allSettingFormDebit(formDebit: $formDebit) {
            edges{
                node{
                    id
                    description
                    descriptionEng
                    descriptionSecond
                    descriptionSecondEng
                    paymentCheck
                    paymentTimeStatus
                    paymentTime1
                    paymentTime2
                    bankAccountStatus
                    numberOfDays
                    bankAccount{
                        id
                        accountNumber
                        bankName
                        accountName
                        accountNameEn
                        branch
                    }
                    channelDescription
                    contactName
                    creatorName
                    creatorNameEng
                    contactPhone
                    signerName
                    signerPosition
                    formDebit
                    refDocument
                    refReport
                    refCollection1
                    refCollection2
                    refCollection3
                }
            }
        }
        allCollectionLetters(collectionLettersId:$collectionLettersId){
            edges{
                node{
                    id
                    numberOfTimes
                    refDocNumber
                    docNumber
                    added
                    issuedDate
                    creator
                    receivableOutstanding
                    debtStatus
                    status
                    updated
                    sumTotal
                    contact {
                        id
                        name
                        refNumber
                        firstName
                        lastName
                        registeredName
                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode
                        typeOfContact
                        residential {
                            type {
                                name
                                slug
                            }
                        }
                    }
                    documentCollectionLetters{
                                edges{
                                    node{
                                        id
                                        fileName
                                        numPage
                                    }
                                }
                    }
                    recordCollectionLetters{
                        edges {
                            node {
                                id
                                sumTotalRecord
                                transaction {
                                            id
                                            description
                                            total
                                            status
                                            invoice{
                                                id
                                                docNumber
                                                dueDate
                                                total
                                                issuedDate
                                            }
                                            productAndService {
                                                id
                                                productCode
                                                name
                                            }
                                            chartOfAccount {
                                                chartOfAccountCode
                                            }

                                }
                            }
                        }
                    }
                }
            }
        }  
        userConfig {
            id languages
        }
    }`;

class WrapperCollectionLetter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type : 'all',
            indexOutStanding : 1,
        }
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    updateIndexOutStanding = (value) => {
        this.setState({
            indexOutStanding : value
        })
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Collection Letter Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{collectionLettersId: [this.props.match.params.id], formDebit: parseInt(this.props.match.params.numberOfTimes) > 4 ? 4 : parseInt(this.props.match.params.numberOfTimes)}}
                    render={({error, props}) => {                        
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let sumAll = props.allCollectionLetters.edges[0].node.sumTotal
                            let group_transaction = _(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges).groupBy('node.transaction.productAndService.name').map((value,name)=> {
                                let sumTotal = _.sumBy(value,'node.sumTotalRecord')
                                return({
                                    name: name,
                                    sum_transaction: sumTotal,
                                })
                            }).value()

                            group_transaction = _.filter(group_transaction,transaction => {
                                return transaction.sum_transaction > 0
                            })


                            let recordCollectionLetters = []
                            let countList = 20
                            
                            //พิเศษ
                            if(localStorage.getItem('site_id') === '340' || localStorage.getItem('site_id') === '468' || localStorage.getItem('site_id') === '402' || localStorage.getItem('site_id') === '490' || localStorage.getItem('site_id') === '456'){
                                countList = 10
                            }

                            // theteak39 - CLL-2022070001
                            if(localStorage.getItem('site_id') === '340' && this.props.match.params.id === 'Q29sbGVjdGlvbkxldHRlcnNOb2RlOjQ1ODI1MQ=='){
                                countList = 9
                            }

                            let sumLine = 0;
                            let dataMapLine = []
                            let filter_zero = _.filter(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges, e => e.node.sumTotalRecord > 0)

                            if(localStorage.getItem('site_id') !== '340' && localStorage.getItem('site_id') !== '468' && 
                                localStorage.getItem('site_id') !== '402' && localStorage.getItem('site_id') !== '490' && 
                                localStorage.getItem('site_id') !== '456' && localStorage.getItem('site_id') !== '340'){
                                   
                                    let docNumberChk = ""
                                    let orderNumber = 0
                                    _.forEach(filter_zero , (record , index) => {

                                        let duplicate = true
                                        if(docNumberChk !== record.node.transaction.invoice.docNumber){
                                            orderNumber += 1
                                            docNumberChk = record.node.transaction.invoice.docNumber 
                                            duplicate = false
                                        } 
                                        let chkOrder = !duplicate ? orderNumber : ""
                                        dataMapLine.push({...record, no: chkOrder})

                                        if(record.node.transaction.description.length >= 145){
                                            sumLine = sumLine + 5;  //cal row description per page
                                        }
                                        else if(record.node.transaction.description.length >= 115){
                                            sumLine = sumLine + 4; 
                                        }
                                        else if(record.node.transaction.description.length >= 95){
                                            sumLine = sumLine + 3;
                                        }
                                        else if(record.node.transaction.description.length >= 75){
                                            sumLine = sumLine + 2.7;
                                        }
                                        else if(record.node.transaction.description.length >= 40){
                                            sumLine = sumLine + 2.1; 
                                        }
                                        else if(record.node.transaction.description.length > 0) {
                                            sumLine = sumLine + 1.1; 
                                        }
                                        if(sumLine >= 33 || filter_zero.length === (index+1)){
                                            recordCollectionLetters.push(dataMapLine)
                                            dataMapLine = []
                                            sumLine = 0
                                        }else{
                                        }
                                      
                                    })

                            }else{

                                if(filter_zero.length > countList){
                                    recordCollectionLetters = _.chunk(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges,countList)
                                }else{
                                    recordCollectionLetters = [props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges]
                                }

                            }
                            let totalRecordOutStanding = _.sumBy(props.allCollectionLetters.edges[0].node.recordCollectionLetters.edges,'node.sumTotalRecord')
                           
                            return(
                                <React.Fragment>
                                    {/* Element นี้ แสดง ใบที่เลือก */}
                                    <CollectionLetter 
                                        languages={props?.userConfig?.languages}
                                        selfProject={props.selfProject}
                                        allSettingFormDebit = {props.allSettingFormDebit?.edges[0]}
                                        allCollectionLetters = {props.allCollectionLetters?.edges[0]}
                                        group_transaction = {group_transaction}
                                        receivablePage = {recordCollectionLetters?.length}
                                        sumAll= {sumAll}

                                    />


                                    {/* การเอาทวงถามครั้งก่อนหน้ามาแสดงจะเป็นการเอา ref ของใบปัจจุบันมามาเรียกครั้งก่อนหน้า 
                                        เช่น ถ้าใบปัจจุบันเป็นครั้งที่ 3 จะเอา ref ในครั้งที่ 3 ไปใช้เรียกครั้งที่สอง
                                        แล้วก็เอา 2 ไปเรียกครั้งที่ 1
                                    */}                                     
                                                                    
                                     {/* ถ้าใบที่เลือก เป็นครั้งที่ 4  */}
                                    {(this.props.match.params.numberOfTimes === '4') &&
                                    <WrapperCollectionLetterOldTime 
                                        collectionLettersId = {this.props.match.params.id}  
                                        receivablePage = {recordCollectionLetters?.length}       
                                        formDebit = {this.props.match.params.numberOfTimes - 1}          
                                        searchOld = {props.allCollectionLetters?.edges[0].node.refDocNumber}    
                                        numberOftime = {this.props.match.params.numberOfTimes}
                                        ref1 = {props.allSettingFormDebit?.edges[0]?.node.refCollection1}                   
                                        ref2 = {props.allSettingFormDebit?.edges[0]?.node.refCollection2}                   
                                        ref3 = {props.allSettingFormDebit?.edges[0]?.node.refCollection3}                                                                                          
                                    />}     

                                    {/* ถ้าใบที่เลือก เป็นครั้งที่ 3  */}
                                    {(this.props.match.params.numberOfTimes === '3') &&
                                    <WrapperCollectionLetterOldTime 
                                        collectionLettersId = {this.props.match.params.id}  
                                        receivablePage = {recordCollectionLetters?.length}       
                                        formDebit = {this.props.match.params.numberOfTimes - 1}          
                                        searchOld = {props.allCollectionLetters?.edges[0].node.refDocNumber}    
                                        numberOftime = {this.props.match.params.numberOfTimes}
                                        ref1 = {props.allSettingFormDebit?.edges[0]?.node.refCollection1}                   
                                        ref2 = {props.allSettingFormDebit?.edges[0]?.node.refCollection2}  
                                        ref3 = ""
                                    />}     

                                     {/* ถ้าใบที่เลือก เป็นครั้งที่ 2  */}
                                    {(this.props.match.params.numberOfTimes === '2') &&
                                    <WrapperCollectionLetterOldTime 
                                        collectionLettersId = {this.props.match.params.id}  
                                        receivablePage = {recordCollectionLetters?.length}       
                                        formDebit = {this.props.match.params.numberOfTimes - 1}          
                                        searchOld = {props.allCollectionLetters?.edges[0].node.refDocNumber}    
                                        numberOftime = {this.props.match.params.numberOfTimes}
                                        ref1 = {props.allSettingFormDebit?.edges[0]?.node.refCollection1}    
                                        ref2 = ""                                                                                         
                                        ref3 = ""                                                                                         
                                    />}                                                                                                        

                                    {/* props.allCollectionLetters.edges[0].node.receivableOutstanding && */}
                                    {props.allSettingFormDebit?.edges[0]?.node?.refReport &&
                                        recordCollectionLetters.map((record,index) => {
                                            return (
                                                <OutStandingCollectionLetter selfProject={props.selfProject} 
                                                    allCollectionLetters = {props.allCollectionLetters.edges[0]} 
                                                    recordCollectionLetters = {record}
                                                    lastPage = {index+1 === recordCollectionLetters.length ? true : false}
                                                    key = {index}
                                                    indexRecord = {index}
                                                    countList ={countList}
                                                    sumRecord = {totalRecordOutStanding}
                                                    updateIndexOutStanding = {this.updateIndexOutStanding}
                                                    indexOutStanding = {this.state.indexOutStanding}
                                                    languages={props.userConfig.languages}
                                                />  
                                            )
                                        })
                                    }
                                </React.Fragment>   
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                /> 
            </React.Fragment>
        );
    }
}

export default WrapperCollectionLetter;
