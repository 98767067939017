import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";

const mutation = graphql`
  mutation updatePDPAMutation($input: AcceptPDPAInput!){
    acceptPDPA(input: $input) 
    {
      ok
      message
    }
  }
`

export default function updatePDPA(variables, callback, error_callback) {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        callback(response.acceptPDPA.ok)
      },
      onError: (err) => {
        error_callback(err);
      },
    },
  )
}