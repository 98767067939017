import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";


async function postOTP(data) {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}open/otp`, data, { headers: header },);
}

async function checkOTP(channel,otp,reference) {
    var token_id = await localStorage.getItem("token");
    var header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    if(channel === 'email'){
        return axios.get(`${getApiRoot()}open/otp?channel=${channel}&otp=${otp}`, { headers: header },);
    }else{
        return axios.get(`${getApiRoot()}open/otp?channel=${channel}&otp=${otp}&reference=${reference}`, { headers: header },);
    }
}

export default {postOTP,checkOTP}