import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import { Translation } from 'react-i18next'
const query = graphql`
    query manegeTopMenuNavigationQuery{
      getNotificationWaitPurchaseOrderes {
        type
        count
      }
    }
`;

export default class manegeTopMenuNavigation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      countWaitPONoti: window.localStorage.getItem("wait_open_purchase_orderes") || "0"
    }
  }

  componentDidMount() {
    fetchQuery(environment, query).then((response) => {
      if (response.getNotificationWaitPurchaseOrderes[0].type === "wait_open_purchase_orderes") {
        window.localStorage.setItem(`${response.getNotificationWaitPurchaseOrderes[0].type}`, response.getNotificationWaitPurchaseOrderes[0].count)
        this.setState({
          countWaitPONoti: `${response.getNotificationWaitPurchaseOrderes[0].count}`
        })
      }
    })
  }


  render() {
    return (
      <Translation>
        {
          t =>
            <div className="row" id="navigation-tab">
              <div className="col">
                <ul>
                  <li>
                    <NavLink to="/purchasing/manageOrder/list/all" >
                      {t("PageListPRPO:All")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/purchasing/manageOrder/list/waitPO" >
                      {t("PageListPRPO:Wait PO")}
                      {this.state.countWaitPONoti !== "0" &&
                        <span
                          className="badge badge-pill badge-danger"
                          style={{ width: 24, marginLeft: 4 }}
                        >
                          {this.state.countWaitPONoti}
                        </span>
                      }
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/purchasing/manageOrder/list/wait" >
                      {t("PageListPRPO:Pending")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/purchasing/manageOrder/list/approve" >
                      {t("PageListPRPO:Approve")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/purchasing/manageOrder/list/void" >
                      {t("PageListPRPO:Cancled")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
        }
      </Translation>

    )
  }
}
