import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import InvoiceListAll from './invoice_list/invoiceListAll';
import InvoiceCreateUpdate from './invoiceCreateUpdate';
import InvoiceDetail from "./invoice_detail/invoiceDetailQuery";


class Invoice extends Component {

    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={InvoiceListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={InvoiceListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={InvoiceCreateUpdate}/>
                <PrivateRoute exact path={`${this.props.match.url}/invoice-detail/:id`} component={InvoiceDetail}/>
            </React.Fragment>
        );
    }
}

export default Invoice;