import '../styles/main.scss';
import React, { Component } from 'react';
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import './collectionLetter.scss';
import getDateTime from '../../libs/getDateTime';
import {getTypeProject,getProductTypeName} from "../../libs/getTypeOfProject"
import getNameResidential from "../../libs/getNameResidential";
import { Translation } from "react-i18next";
import i18n from "i18next"
const ThaiBaht = require('thai-baht-text');
const numberToText = require('number-to-text')
require('number-to-text/converters/en-us');

const example_letter = [
    { description: "ค่าส่วนกลาง", price: 300 },
    { description: "ค่าน้ำประปา", price: 430 },
    { description: "ค่าไฟฟ้า", price: 2500 },
    { description: "ค่ารักษาาตรน้ำ", price: 500 },
    { description: "ค่าเบี้ยประกันภัย", price: 500 },
]

const setting_detail = {
    id: '',
    numberOfDays: 0,
    description: "อ้างถึงข้อบังคับนิติบุคคลฯ กรณีเจ้าของร่วมผิดนัดไม่ชำระในเวลาที่กำหนดต้องเสียเงินเพิ่มและค่าปรับในอัตราร้อยละ 12 ต่อปี กรณีค้างชำระตั้งแต่" +
        "6 เดือนขึ้นไป ต้องเสียเงินเพิ่มและค่าปรับในอัตราร้อยละ 20 ต่อปีของจำนวนเงินที่ค้างชำระโดยไม่คิดทบต้น และอาจถูกระงับการให้" +
        "บริการส่วนรวม",
    descriptionEng:"",
    descriptionSecond: "",
    descriptionSecondEng:"",
    creatorName:"",
    creatorNameEng:"",
    payment_channel: {
        bank: {
            bankAccountStatus: false,
            bank_payment: {
                bankName: '',
                accountNumber: '',
                accountName: '',
                accountNameEn:'',
                accountNumberId: '',
                branch: ''
            },
        },
        cheque: {
            paymentCheck: false,
        },
        cash: {
            paymentTimeStatus: false,
            paymentTime1: new Date(),
            paymentTime2: new Date(),
        },
        channelDescription: "-",
    },
    contactName: "",
    contactPhone: "",
    signerName: "",
    signerPosition: "",
}
class CollectionLetter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setting_detail: setting_detail,
            attactDocument: [],
            languages:"th"
        }
    }

    queryLanguages(){
        this.setState({ languages : this.props.languages })
        i18n.changeLanguage(this.props.languages);
    }
    componentDidMount() {
        this.queryLanguages();
        if (this.props.allSettingFormDebit) {
            let desSplit = this.props.allSettingFormDebit.node.description?.split("<br>")
            let resultDesSplit = desSplit.join(" ");
            let setting = {
                description: resultDesSplit,
                descriptionEng: this.props.allSettingFormDebit.node.descriptionEng,
                descriptionSecond: this.props.allSettingFormDebit.node.descriptionSecond,
                descriptionSecondEng: this.props.allSettingFormDebit.node.descriptionSecondEng,
                payment_channel: {
                    bank: {
                        bankAccountStatus: this.props.allSettingFormDebit.node.bankAccountStatus,
                        bank_payment: {
                            bankName: this.props.allSettingFormDebit.node.bankAccount ? this.props.allSettingFormDebit.node.bankAccount.bankName : '',
                            accountNumber: this.props.allSettingFormDebit.node.bankAccount ? this.props.allSettingFormDebit.node.bankAccount.accountNumber : '',
                            accountName: this.props.allSettingFormDebit.node.bankAccount ? this.props.allSettingFormDebit.node.bankAccount.accountName : '',
                            accountNameEn: this.props.allSettingFormDebit.node.bankAccount ? this.props.allSettingFormDebit.node.bankAccount.accountNameEn : '',
                            accountNumberId: this.props.allSettingFormDebit.node.bankAccount ? this.props.allSettingFormDebit.node.bankAccount.id : '',
                            branch: this.props.allSettingFormDebit.node.bankAccount ? this.props.allSettingFormDebit.node.bankAccount.branch : ''
                        },
                    },
                    cheque: {
                        paymentCheck: this.props.allSettingFormDebit.node.paymentCheck,
                    },
                    cash: {
                        paymentTimeStatus: this.props.allSettingFormDebit.node.paymentTimeStatus,
                        paymentTime1: getDateTime(this.props.allSettingFormDebit.node.paymentTime1),
                        paymentTime2: getDateTime(this.props.allSettingFormDebit.node.paymentTime2),
                    },
                    channelDescription: this.props.allSettingFormDebit.node.channelDescription,
                },
                contactName: this.props.allSettingFormDebit.node.contactName,
                contactPhone: this.props.allSettingFormDebit.node.contactPhone,
                creatorName :this.props.allSettingFormDebit.node.creatorName,
                creatorNameEng :this.props.allSettingFormDebit.node.creatorNameEng,
                signerName: this.props.allSettingFormDebit.node.signerName,
                signerPosition: this.props.allSettingFormDebit.node.signerPosition,
                numberOfDays: this.props.allSettingFormDebit.node.numberOfDays,
                refDocument: this.props.allSettingFormDebit.node.refDocument,
                refReport: this.props.allSettingFormDebit.node.refReport,
                refCollection1: this.props.allSettingFormDebit.node.refCollection1,
                refCollection2: this.props.allSettingFormDebit.node.refCollection2,
                refCollection3: this.props.allSettingFormDebit.node.refCollection3,

            }
            this.setState({
                setting_detail: setting
            }, ()=>this.attachItem())
        } else {
            this.setState({
                setting_detail: setting_detail
            }, ()=>this.attachItem())
        }

    }

    componentDidUpdate() {
        if (this.state.description_letter !== this.props.description_letter) {
            this.setState({
                description_letter: this.props.description_letter,
            })
        }
    }

    // getType = (type_slug) => {
    //     const type_house = ["single_house", "twin_house", "townhome", "penthouse", "commercial-building", "commercial-building", "townhouse",
    //         "office", "store", "mall"]
    //     if (type_house.includes(type_slug)) {
    //         return "เจ้าของบ้านเลขที่:"
    //     } else {
    //         return "เจ้าของห้องชุดเลขที่:"
    //     }
    // }


    getProductType(type) {
        let typeName = ""
        let getType = getTypeProject(type)
        if (getType === 'lowType') {
            this.props.selfProject?.establishmentStatus === 'ALLOCATED' ?
            typeName = i18n.t("PageDetailProjectInfo:Village"):typeName = i18n.t("collectionLetter:Village")
            // typeName = 'หมู่บ้านจัดสรรฯ':typeName = 'หมู่บ้านฯ'
        } else {
            typeName = i18n.t("PageDetailProjectInfo:Condominium")
        }
        return typeName;
    }


    attachItem = () => {
        let attachItem = [];
        if(this.state.setting_detail.refReport) attachItem.push(`${i18n.t("downloadFile:receivable_outstanding_report")} (${i18n.t("collectionLetter:Quantity")}${this.props.receivablePage} ${i18n.t("collectionLetter:Page")})`)
        if(this.state.setting_detail.refCollection1) attachItem.push(`${i18n.t("collectionLetter:Colletion Letter No.")}1`)
        if(this.state.setting_detail.refCollection2) attachItem.push(`${i18n.t("collectionLetter:Colletion Letter No.")}2`)
        if(this.state.setting_detail.refCollection3) attachItem.push(`${i18n.t("collectionLetter:Colletion Letter No.")}3`)        
        if(this.state.setting_detail.refDocument) {
            if(this.props.allCollectionLetters.node.documentCollectionLetters.edges) {
                this.props.allCollectionLetters.node.documentCollectionLetters.edges.map((attach)=>{
                    let attachName = `${attach.node.fileName} (${i18n.t("collectionLetter:Quantity")} ${attach.node.numPage} ${i18n.t("collectionLetter:Page")})`
                    attachItem.push(attachName)
                })
            }
        }
        this.setState({attactDocument:attachItem})
    }

    render() {
        
        /* เช็คเงื่อนไข 
            1.กรณีที่รายการค่าใช้จ่ายเรียกเก็บเกิน 8 รายการ จะขึ้นหน้าใหม่ทันที
            กรณีคำอธิบายรวมกันมากกว่า 800 ตัวอักษร และ มี รายการมากกว่า 5 รายการ
            2.กรณีที่ มี 7 รายการ จำนวนรายละเอียดจะมีได้แค่ 1332
            3.กรณีที่ มี 6 รายการ จำนวนรายละเอียดจะมีได้แค่ 1466
            4.กรณีที่ มี 5 รายการ จำนวนรายละเอียดจะมีได้แค่ 1600 
            note: หน้านึงเต็มๆจะได้ max สุด 5 transaction กับ 1600 ตัวอักษร */  
            let condition1ForSeperatePage 
            let condition2ForSeperatePage
            let condition3ForSeperatePage 
            let condition4ForSeperatePage 
        // let checkSeparatePage = (condition1ForSeperatePage && (condition2ForSeperatePage || condition3ForSeperatePage || condition4ForSeperatePage))
            let checkSeparatePage 

            if(i18n.languages[0] === 'th'){
                condition1ForSeperatePage = this.state.attactDocument?.length + this.props.group_transaction.length < 8;
                condition2ForSeperatePage = (this.state.setting_detail?.description?.length || 0) + (this.state.setting_detail?.descriptionSecond?.length || 0) <= 1332 && this.state.attactDocument?.length + this.props.group_transaction.length <= 7;
                condition3ForSeperatePage = (this.state.setting_detail?.description?.length || 0) + (this.state.setting_detail?.descriptionSecond?.length || 0) <= 1466 && this.state.attactDocument?.length + this.props.group_transaction.length <= 6;
                condition4ForSeperatePage = (this.state.setting_detail?.description?.length || 0) + (this.state.setting_detail?.descriptionSecond?.length || 0) <= 1600 && this.state.attactDocument?.length + this.props.group_transaction.length <= 5;
                checkSeparatePage = (condition1ForSeperatePage && (condition2ForSeperatePage || condition3ForSeperatePage || condition4ForSeperatePage))
            }else if (i18n.languages[0] === 'en'){
                condition1ForSeperatePage = this.state.attactDocument?.length + this.props.group_transaction.length < 8;
                condition2ForSeperatePage = (this.state.setting_detail?.descriptionEng?.length || 0) + (this.state.setting_detail?.descriptionSecondEng?.length || 0) <= 1332 && this.state.attactDocument?.length + this.props.group_transaction.length <= 7;
                condition3ForSeperatePage = (this.state.setting_detail?.descriptionEng?.length || 0) + (this.state.setting_detail?.descriptionSecondEng?.length || 0) <= 1466 && this.state.attactDocument?.length + this.props.group_transaction.length <= 6;
                condition4ForSeperatePage = (this.state.setting_detail?.descriptionEng?.length || 0) + (this.state.setting_detail?.descriptionSecondEng?.length || 0) <= 1600 && this.state.attactDocument?.length + this.props.group_transaction.length <= 5;
                checkSeparatePage = (condition1ForSeperatePage && (condition2ForSeperatePage || condition3ForSeperatePage || condition4ForSeperatePage))
            }

        let getType = getTypeProject(this.props.selfProject?.typeOfProject)      

        return (
            <React.Fragment key={this.props.key}>
                <div className="print-page-a4" id="collection-letter" >
                    <div className="subpage" >
                        <div className="head">
                            <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                <div style={{ minHeight: 100, minWidth: 100 }}>
                                    <img src={this.props.selfProject.logo} alt="silverman" />
                                </div>
                                <div>
                                    <div style={{ minWidth: '115%' }}>                                        
                                        <strong>{i18n.languages[0] === "en" ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>
                                    </div>
                                    <div className="address-header">{i18n.languages[0] === "en" ? (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} {i18n.t("collectionLetter:Tel.")} {this.props.selfProject.juristicContactNumber || '-'}</div>
                                    <div>{i18n.t("collectionLetter:Tax ID")} {this.props.selfProject.taxIdNumber || '-'}</div>
                                </div>
                            </div>
                            {this.props.allCollectionLetters.node.numberOfTimes >= 4 ?
                            <div className="document-name">
                                <div className="title">{i18n.t("collectionLetter:Notice")}</div>
                            </div>
                            :
                            <div className="document-name">
                                <div className="title">{i18n.t("collectionLetter:Collection Letter")}</div>
                            </div>
                             }
                        </div>

                        {
                            <>
                            <div className="head">
                                <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "0.80fr 5.5fr" }}>
                                    <div className="sub-title">
                                    <Translation>{t=><strong>{t("collectionLetter:Subject")}</strong>}</Translation> 
                                    </div>
                                    <div>
                                        <div style={{ minWidth: '115%' }}>
                                        <div className="description-letter" style={{fontSize:'12px'}}>
                                         {this.props.allCollectionLetters.node.numberOfTimes >= 4 ? 
                                            i18n.t("collectionLetter:Please make the payment.") :  
                                            i18n.t("collectionLetter:Request for Payment of Maintenance and Public Utility Fees")}&nbsp;
                                        ({i18n.t("collectionLetter:No")}{this.props.timeOld? this.props.timeOld : this.props.allCollectionLetters.node.numberOfTimes})<br />
                                        {/* {i18n.t("collectionLetter:Office")}&nbsp;{getType === 'lowType' ?                        
                                        this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                        &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)} */}
                                         {i18n.languages[0] === 'th' ? this.state.setting_detail.creatorName : this.state.setting_detail.creatorNameEng}
                                        &nbsp;{i18n.t("collectionLetter:Request to follow up on the outstanding balance of expenses that overdue.")}<br/>
                                        {i18n.t("collectionLetter:The details are as follows.")}
                                         </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="document-name" style={{fontSize:'12px'}}>
                                    <div><strong>{i18n.t("collectionLetter:Date")}:</strong> {format(this.props.allCollectionLetters.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</div>
                                    <div><strong>{i18n.t("collectionLetter:No.")}:</strong>  {this.props.allCollectionLetters.node.docNumber}</div>
                                </div>
                            </div>
                            {/* <div className="sub-detail row">
                                 <div className="sub-title">
                                        <strong>เรื่อง</strong>
                                  </div>
                                  <div className="description-letter">
                                        ขอให้ชำระค่าใช้จ่ายที่เกินกำหนด (ฉบับที่ {this.props.allCollectionLetters.node.numberOfTimes})   <span>วันที่/Date:{format(this.props.allCollectionLetters.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</span> <br />
                                        นิติบุคคลฯ ขอติดตามยอดค้างชำระค่าใช้จ่ายที่เลยกำหนดแล้ว มีรายละเอียดดังนี้   <span className="">เลขที่/No.:  {this.props.allCollectionLetters.node.docNumber}</span>
                                   </div>
                            </div> */}

                            <div className="detail-main row" style={{fontSize:'12px'}}>
                                    <div className="dear">
                                    <Translation>{t=><strong>{t("collectionLetter:To")}</strong>}</Translation> 
                                    </div>
                                   <div className="customer-detail">
                                        {this.props.allCollectionLetters.node.contact.typeOfContact === 'RESIDENTIAL' ?
                                                            <React.Fragment>
                                                                {/* <strong className="mr-1"> {this.getType(this.props.allCollectionLetters.node.contact.residential.type.slug)}</strong> */}
                                                                <Translation>{t=><strong className="mr-1">{t(`collectionLetter:${getProductTypeName(getType)}`)}</strong>}</Translation> 
                                                                {/* <strong className="mr-1"> {getProductTypeName(getType)}</strong> */}
                                                                {this.props.allCollectionLetters.node.contact.name}
                                                                <br />
                                                                <React.Fragment >
                                                                    <strong className="mr-1">{i18n.t("collectionLetter:Customer")}:</strong>
                                                                    {/* {this.props.allCollectionLetters.node.contact.firstName} {this.props.allCollectionLetters.node.contact.lastName} */}

                                                                    {getNameResidential(this.props.allCollectionLetters.node.contact.firstName , this.props.allCollectionLetters.node.contact.lastName )}
                                                                    <br />
                                                                </React.Fragment>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment >
                                                                <strong className="mr-1">{i18n.t("collectionLetter:Customer")}:</strong>
                                                                {this.props.allCollectionLetters.node.contact.name}
                                                                <br />
                                                            </React.Fragment>
                                                        }
                                                        <strong className="mr-1">{i18n.t("collectionLetter:Address")}:</strong>
                                                        {this.props.allCollectionLetters?.node.contact.registeredProvince.includes("กรุงเทพ") || this.props.allCollectionLetters.node.contact.registeredProvince.includes("กทม") ? (
                                                            <>
                                                                {this.props.allCollectionLetters.node.contact.registeredAddress}
                                                                {this.props.allCollectionLetters.node.contact.registeredDistrict && ` แขวง${this.props.allCollectionLetters.node.contact.registeredDistrict}`}
                                                                {this.props.allCollectionLetters.node.contact.registeredCity && ` เขต${this.props.allCollectionLetters.node.contact.registeredCity}`}
                                                                {this.props.allCollectionLetters.node.contact.registeredProvince && ` ${this.props.allCollectionLetters.node.contact.registeredProvince} `}
                                                                {this.props.allCollectionLetters.node.contact.registeredPostalCode}{" "}
                                                                {this.props.allCollectionLetters.node.contact.registeredCountry}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {this.props.allCollectionLetters.node.contact.registeredAddress}
                                                                {this.props.allCollectionLetters.node.contact.registeredDistrict && ` ตำบล${this.props.allCollectionLetters.node.contact.registeredDistrict}`}
                                                                {this.props.allCollectionLetters.node.contact.registeredCity && ` อำเภอ${this.props.allCollectionLetters.node.contact.registeredCity}`}
                                                                {this.props.allCollectionLetters.node.contact.registeredProvince && ` จังหวัด${this.props.allCollectionLetters.node.contact.registeredProvince} `}
                                                                {this.props.allCollectionLetters.node.contact.registeredPostalCode}{" "}
                                                                {this.props.allCollectionLetters.node.contact.registeredCountry}
                                                            </>
                                                        )}
                                                        <br />
                                                    </div>
                                                    {/* new rq sena CR-sena-accounting-collection
                                                    <div className="document-detail">
                                                        <strong className="sub-document-detail mr-1">วันที่/Date:</strong> {format(this.props.allCollectionLetters.node.updated, 'DD/MM/YYYY', { locale: thLocale })} <br />
                                                        <strong className="sub-document-detail mr-1">เลขที่/No.:</strong> {this.props.allCollectionLetters.node.docNumber} <br />
                                                         <strong className="sub-document-detail mr-1">ครั้งที่/Number Of Time:</strong>  {this.props.allCollectionLetters.node.numberOfTimes}   <br />  
                                                    </div> */}
                            </div> 
                            {this.state.attactDocument?.length > 0 && 
                            <div className="sub-detail row">
                                    <div className="sub-title">
                                        <strong>{i18n.t("collectionLetter:Attached")}:</strong>
                                    </div>   
                                    <div className="description-letter attachDoc">
                                        {this.state.attactDocument?.map((attach, index)=>{
                                            return  <React.Fragment key={index}>{index+1}. {attach}<br/></React.Fragment>
                                        })}
                                    </div>                                
                            </div>}
                            </> 
                        }
                                            
              

                        <div className="outstanding-table">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th width={50} className="text-center">{i18n.t("collectionLetter:No.")} </th>
                                        <th className="text-center">
                                         {i18n.t("collectionLetter:Description")}  
                                        </th>
                                        <th width={180} className="text-center">
                                        {i18n.t("collectionLetter:Amount")} 
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.group_transaction.map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td>
                                                    {value.name}
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(value.sum_transaction, 0)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="row">
                                                <div className={this.props.example ? "col-2" : "col-1"}>
                                                    <strong> {i18n.t("collectionLetter:Total")} </strong>
                                                </div>
                                                <div className={this.props.example ? "col-8 text-center" : "col-10 text-center"}>
                                                    {
                                                         i18n.languages[0] === 'th' ? <strong>({ThaiBaht(this.props.sumAll.toFixed(2))})</strong> : 
                                                          <strong>({ numberToText.convertToText(parseInt(this.props.sumAll))})</strong>
                                                    }
                                                    
                                                </div>
                                                <div className="col-1" />
                                            </div>
                                        </td>
                                        <td className="text-right">                                        
                                            <strong>{numberWithComma(this.props.sumAll ?? 0)}</strong>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="collection-description">
                            <strong>{i18n.t("collectionLetter:I hereby request to please pay the overdue expenses.")}</strong> {i18n.t("collectionLetter:Total Amount")} {numberWithComma(this.props.sumAll ?? 0)} {i18n.t("newBookingSingha_Setting:Baht")} {i18n.t("collectionLetter:Within")}  {this.state.setting_detail.numberOfDays}  {i18n.t("collectionLetter:Days from the date of issuance.")}<br />
                            {/* ภายในวันที่ {format(new Date(), 'DD/MM/YYYY', {locale: thLocale})} */}
                            {this.props.group_transaction.length >= 10 && (this.state.setting_detail.description.length + this.state.setting_detail.descriptionSecond.length) > 1000 ? "" : <br/> }
                            <div className="tab-description">{i18n.languages[0] === 'th' ? this.state.setting_detail.description : this.state.setting_detail.descriptionEng}</div>
                            <div className="tab-description">{i18n.languages[0] === 'th' ? this.state.setting_detail?.descriptionSecond : this.state.setting_detail?.descriptionSecondEng}</div>
                            
                            {
                                (checkSeparatePage) && 
                                (this.props.group_transaction.length >= 7 &&
                                    i18n.languages[0] === 'th' ?  (this.state.setting_detail.description.length + this.state.setting_detail?.descriptionSecond?.length) : (this.state.setting_detail?.descriptionEng?.length + this.state.setting_detail?.descriptionSecondEng?.length)  > 900 ?
                                        <React.Fragment>
                                            {(this.state.setting_detail.payment_channel.cash.paymentTimeStatus || this.state.setting_detail.payment_channel.cheque.paymentCheck || this.state.setting_detail.payment_channel.bank.bankAccountStatus) &&
                                                <React.Fragment>
                                                    <div className="pay-channel" style={{ marginTop: this.props.group_transaction.length>= 10 ? 0 : 20 }}>
                                                        <strong>{i18n.t("collectionLetter:Payment method")}</strong><br />
                                                        {this.state.setting_detail.payment_channel.cash.paymentTimeStatus &&
                                                            <React.Fragment>
                                                                - {i18n.t("collectionLetter:Payment can be made in cash at the legal office.")} ({i18n.t("collectionLetter:Business hours")} {format(this.state.setting_detail.payment_channel.cash.paymentTime1, "HH:mm")} น. - {format(this.state.setting_detail.payment_channel.cash.paymentTime2, "HH:mm")} น)<br />
                                                            </React.Fragment>
                                                        }
                                                        {this.state.setting_detail.payment_channel.cheque.paymentCheck &&
                                                            <React.Fragment>
                                                                {/* - {i18n.t("collectionLetter:Pay by check made out to")} {this.props.selfProject.name}<br /> */}
                                                                - {i18n.t("collectionLetter:Pay by check made out to")}   {i18n.languages[0] === 'th' ? this.state.setting_detail.payment_channel.bank.bank_payment.accountName : this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn}<br/>
                                                            </React.Fragment>
                                                        }
                                                        {this.state.setting_detail.payment_channel.bank.bankAccountStatus &&
                                                            <React.Fragment>
                                                                - {i18n.t("collectionLetter:Transfer via bank")} {this.state.setting_detail.payment_channel.bank.bank_payment.bankName}&nbsp;{i18n.t("payChannel:Branch")}&nbsp;{this.state.setting_detail.payment_channel.bank.bank_payment.branch} {i18n.t("payChannel:Account number")}&nbsp;{i18n.languages[0] === 'th' ? this.state.setting_detail.payment_channel.bank.bank_payment.accountName : this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn}   <br />
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    <br />                                        
                                                </React.Fragment>
                                                
                                            }
                                            <div className="tab-description" style={{width:  this.props.group_transaction.length >= 10 ? 440 : 'auto'}}>
                                                {i18n.t("collectionLetter:Bring a copy of the evidence of deposit(PAY IN SLIP) contact to receive a receipt at the office")}&nbsp;{getType === 'lowType' ?
                                                 this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                                &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)} {this.state.setting_detail.payment_channel.channelDescription !== '' ? i18n.t("collectionLetter:Or send by") +" "+ this.state.setting_detail.payment_channel.channelDescription : ''}  {i18n.t("collectionLetter:All off this")}
                                                &nbsp;{i18n.t("collectionLetter:Office")}&nbsp;{getType === 'lowType' ?
                                                this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                                &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)} &nbsp;{i18n.t("collectionLetter:A receipt will be issued to you as evidence.")}
                                            </div>
                                            <div className={this.props.group_transaction.length >= 10? "" : "mt-4"} style={{width: 380}}>
                                                {i18n.t("collectionLetter:If you have any questions, please contact")}{this.state.setting_detail.contactName ? this.state.setting_detail.contactName + ' ' : "..............................................................."}
                                                {i18n.t("collectionLetter:Phone")} {this.state.setting_detail.contactPhone ? this.state.setting_detail.contactPhone : "..............................................................."} {i18n.t("collectionLetter:All of that")}
                                                &nbsp;{i18n.t("collectionLetter:Office")}&nbsp;{getType === 'lowType' ?                        
                                                this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                                &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)}
                                                &nbsp;{i18n.t("collectionLetter:We apologize if you have already paid the fees before receiving this letter")} 
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {(this.state.setting_detail.payment_channel.cash.paymentTimeStatus || this.state.setting_detail.payment_channel.cheque.paymentCheck || this.state.setting_detail.payment_channel.bank.bankAccountStatus) &&
                                                <React.Fragment>
                                                    <div className="pay-channel">
                                                        <strong>{i18n.t("collectionLetter:Payment method")}</strong><br />
                                                        {this.state.setting_detail.payment_channel.cash.paymentTimeStatus &&
                                                            <React.Fragment>
                                                                - {i18n.t("collectionLetter:Payment can be made in cash at the legal office.")}({i18n.t("collectionLetter:Business hours")} {format(this.state.setting_detail.payment_channel.cash.paymentTime1, "HH:mm")} น. - {format(this.state.setting_detail.payment_channel.cash.paymentTime2, "HH:mm")} น)<br />
                                                            </React.Fragment>
                                                        }
                                                        {this.state.setting_detail.payment_channel.cheque.paymentCheck &&
                                                            <React.Fragment>
                                                                {/* - {i18n.t("collectionLetter:Pay by check made out to")} {this.props.selfProject.name}<br /> */}
                                                                - {i18n.t("collectionLetter:Pay by check made out to")}   {i18n.languages[0] === 'th' ? this.state.setting_detail.payment_channel.bank.bank_payment.accountName : this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn}<br/>
                                                            </React.Fragment>
                                                        }
                                                        {this.state.setting_detail.payment_channel.bank.bankAccountStatus &&
                                                            <React.Fragment>
                                                                -  {i18n.t("collectionLetter:Transfer via bank")} {this.state.setting_detail.payment_channel.bank.bank_payment.bankName}&nbsp;{i18n.t("payChannel:Branch")}&nbsp;{this.state.setting_detail.payment_channel.bank.bank_payment.branch} {i18n.t("payChannel:Account number")}&nbsp;{this.state.setting_detail.payment_channel.bank.bank_payment.accountNumber} {i18n.t("payChannel:Account name")}&nbsp;{i18n.languages[0] === 'th' ? this.state.setting_detail.payment_channel.bank.bank_payment.accountName : this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn} <br />
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    <br />
                                                </React.Fragment>
                                            }
                                        <div className="tab-description">
                                            {i18n.t("collectionLetter:Bring a copy of the evidence of deposit(PAY IN SLIP) contact to receive a receipt at the office")}&nbsp;
                                            {getType === 'lowType' ? this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                            &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)} 
                                            {this.state.setting_detail.payment_channel.channelDescription !== '' ? i18n.t("collectionLetter:Or send by") +" "+ this.state.setting_detail.payment_channel.channelDescription : ''} {i18n.t("collectionLetter:In this regard,")}
                                            &nbsp;{i18n.t("collectionLetter:Office")}&nbsp;{getType === 'lowType' ?
                                            this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                            &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)}&nbsp;{i18n.t("collectionLetter:A receipt will be issued to you as evidence.")}
                                        </div>
                                        <div className="mt-4">
                                            {i18n.t("collectionLetter:If you have any questions, please contact")} {this.state.setting_detail.contactName ? this.state.setting_detail.contactName + ' ' : "..............................................................."}
                                            {i18n.t("collectionLetter:Phone")} {this.state.setting_detail.contactPhone ? this.state.setting_detail.contactPhone : "..............................................................."}{i18n.t("collectionLetter:In this regard,")}
                                            &nbsp;{i18n.t("collectionLetter:Office")}&nbsp;{getType === 'lowType' ?                        
                                            this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person") +" "}
                                            &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)}
                                            &nbsp;{i18n.t("collectionLetter:We apologize if you have already paid the fees before receiving this letter")} 
                                        </div>                                     
                                        </React.Fragment>
                                )
                            }
                        </div>

                        {
                        (checkSeparatePage) &&     
                            (<div className="footer">
                                <div className="signature"
                                style={{
                                    lineHeight: this.props.group_transaction.length >= 6 && (this.state.setting_detail.description && this.state.setting_detail.description.length) + (this.state.setting_detail.descriptionSecond && this.state.setting_detail.descriptionSecond.length) > 1000 ? 1.5 : "30px"
                                }}
                                >
                                     {i18n.t("collectionLetter:Sincerely,")}
                                    <br />
                                    <br />
                                    ............................................................... <br />
                                    ({this.state.setting_detail.signerName ? this.state.setting_detail.signerName : "..............................................................."})<br />
                                    {this.state.setting_detail.signerPosition ? this.state.setting_detail.signerPosition : "..............................................................."}
                                </div>
                            </div>)
                        }
                    </div>
                </div>

                 {                    
                (!checkSeparatePage) && 
                <div className="print-page-a4" id="collection-letter">
                    <div className="subpage" >
                        <div className="collection-description">                       
                                    <React.Fragment>
                                        {(this.state.setting_detail.payment_channel.cash.paymentTimeStatus || this.state.setting_detail.payment_channel.cheque.paymentCheck || this.state.setting_detail.payment_channel.bank.bankAccountStatus) &&
                                            <React.Fragment>
                                                <div className="pay-channel">
                                                    <strong>{i18n.t("collectionLetter:Payment method")}</strong><br />
                                                    {this.state.setting_detail.payment_channel.cash.paymentTimeStatus &&
                                                        <React.Fragment>
                                                            - {i18n.t("collectionLetter:Payment can be made in cash at the legal office.")}({i18n.t("collectionLetter:Business hours")} {format(this.state.setting_detail.payment_channel.cash.paymentTime1, "HH:mm")} น. - {format(this.state.setting_detail.payment_channel.cash.paymentTime2, "HH:mm")} น)<br />
                                                        </React.Fragment>
                                                    }
                                                    {this.state.setting_detail.payment_channel.cheque.paymentCheck &&
                                                        <React.Fragment>
                                                            {/* - {i18n.t("collectionLetter:Pay by check made out to")} {this.props.selfProject.name}<br /> */}
                                                            - {i18n.t("collectionLetter:Pay by check made out to")}   {i18n.languages[0] === 'th' ? this.state.setting_detail.payment_channel.bank.bank_payment.accountName : this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn}<br/>
                                                        </React.Fragment>
                                                    }
                                                    {this.state.setting_detail.payment_channel.bank.bankAccountStatus &&
                                                        <React.Fragment>
                                                            - {i18n.t("collectionLetter:Transfer via bank")} {this.state.setting_detail.payment_channel.bank.bank_payment.bankName}&nbsp;{i18n.t("payChannel:Branch")}&nbsp;{this.state.setting_detail.payment_channel.bank.bank_payment.branch} {i18n.t("payChannel:Account number")}&nbsp;{this.state.setting_detail.payment_channel.bank.bank_payment.accountNumber} {i18n.t("payChannel:Account name")}&nbsp;{i18n.languages[0] === 'th' ? this.state.setting_detail.payment_channel.bank.bank_payment.accountName : this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn}   <br />
                                                        </React.Fragment>
                                                    }
                                                </div>
                                                <br />
                                            </React.Fragment>
                                        }
                                        <div className="tab-description">
                                            {i18n.t("collectionLetter:Bring a copy of the evidence of deposit(PAY IN SLIP) contact to receive a receipt at the office")}&nbsp;{getType === 'lowType' ?
                                            this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                             &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)} {this.state.setting_detail.payment_channel.channelDescription !== '' ? i18n.t("collectionLetter:Or send by") +" "+  this.state.setting_detail.payment_channel.channelDescription : ''}&nbsp;{i18n.t("collectionLetter:In this regard,")}
                                            &nbsp;{i18n.t("collectionLetter:Office")}&nbsp;{getType === 'lowType' ?
                                            this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}&nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)}&nbsp;{i18n.t("collectionLetter:A receipt will be issued to you as evidence.")}
                                        </div>
                                        <div className="mt-4">
                                            {i18n.t("collectionLetter:If you have any questions, please contact")} {this.state.setting_detail.contactName ? this.state.setting_detail.contactName + ' ' : "..............................................................."}
                                            {i18n.t("collectionLetter:Phone")} {this.state.setting_detail.contactPhone ? this.state.setting_detail.contactPhone : "..............................................................."} {i18n.t("collectionLetter:In this regard,")}
                                            &nbsp;{i18n.t("collectionLetter:Office")}&nbsp;{getType === 'lowType' ?                        
                                            this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                            &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)}
                                            &nbsp;{i18n.t("collectionLetter:We apologize if you have already paid the fees before receiving this letter")} 
                                        </div>   
                                    </React.Fragment>                          
                        </div>                  
                        <div className="footerSecondPage">
                            <div className="signature" 
                            style={{
                                lineHeight: this.props.group_transaction.length >= 6 &&
                                (this.state.setting_detail.description && this.state.setting_detail.description.length) + (this.state.setting_detail.descriptionSecond && this.state.setting_detail.descriptionSecond.length) > 1000 ? 1.5 : "30px"
                            }}
                            >
                                {i18n.t("collectionLetter:Sincerely,")}
                                <br />
                                <br />
                                ............................................................... <br />
                                ({this.state.setting_detail.signerName ? this.state.setting_detail.signerName : "..............................................................."})<br />
                                {this.state.setting_detail.signerPosition ? this.state.setting_detail.signerPosition : "..............................................................."}
                            </div>
                        </div>
                        </div>
                </div>}
            </React.Fragment>
        )
    }
}

export default CollectionLetter;