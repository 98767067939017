/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type contactQueryVariables = {|
  id: string
|};
export type contactQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +taxIdNumber: ?string,
    +juristicContactNumber: ?string,
    +logo: ?string,
  |},
  +contact: ?{|
    +id: string,
    +firstName: string,
    +lastName: string,
    +phone: string,
    +mailingAddress: string,
    +mailingCity: string,
    +mailingCountry: string,
    +mailingDistrict: string,
    +mailingName: string,
    +mailingPostalCode: string,
    +mailingProvince: string,
    +mainEmail: ?string,
    +mainPhone: string,
    +registeredName: string,
    +registeredAddress: string,
    +registeredCountry: string,
    +registeredProvince: string,
    +registeredCity: string,
    +registeredDistrict: string,
    +registeredPostalCode: string,
    +typeOfContact: ContactTypeOfContact,
  |},
|};
export type contactQuery = {|
  variables: contactQueryVariables,
  response: contactQueryResponse,
|};
*/


/*
query contactQuery(
  $id: ID!
) {
  selfProject {
    id
    name
    address
    taxIdNumber
    juristicContactNumber
    logo
  }
  contact(id: $id) {
    id
    firstName
    lastName
    phone
    mailingAddress
    mailingCity
    mailingCountry
    mailingDistrict
    mailingName
    mailingPostalCode
    mailingProvince
    mainEmail
    mainPhone
    registeredName
    registeredAddress
    registeredCountry
    registeredProvince
    registeredCity
    registeredDistrict
    registeredPostalCode
    typeOfContact
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxIdNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "juristicContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ContactNode",
    "kind": "LinkedField",
    "name": "contact",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingCountry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingDistrict",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingPostalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailingProvince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mainEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mainPhone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredCountry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredProvince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredDistrict",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registeredPostalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfContact",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contactQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contactQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3ed2a29ca91b3cb88530bcc8b898e1bf",
    "id": null,
    "metadata": {},
    "name": "contactQuery",
    "operationKind": "query",
    "text": "query contactQuery(\n  $id: ID!\n) {\n  selfProject {\n    id\n    name\n    address\n    taxIdNumber\n    juristicContactNumber\n    logo\n  }\n  contact(id: $id) {\n    id\n    firstName\n    lastName\n    phone\n    mailingAddress\n    mailingCity\n    mailingCountry\n    mailingDistrict\n    mailingName\n    mailingPostalCode\n    mailingProvince\n    mainEmail\n    mainPhone\n    registeredName\n    registeredAddress\n    registeredCountry\n    registeredProvince\n    registeredCity\n    registeredDistrict\n    registeredPostalCode\n    typeOfContact\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ef14776355791f1950db18be400fb7f';

module.exports = node;
