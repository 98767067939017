import '../../../../document/styles/main.scss';
import '../../report.scss';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
// import InterestReport from './interestReport'
import format from 'date-fns/format';
import thLocale from "date-fns/locale/th";
import _ from 'lodash'
import { summaryReportOutstandingReceivable } from './query/summaryReportOutstandingReceivable';
import BankDepositReportTablePDF from './bankDepositReportTablePDF';
import { H5 } from '../../../../components/Text/Text';
import SummaryAccountsReceivableAndPayableTable from './summaryAccountsReceivableAndPayableTable';

const query = graphql`
  query wrapperBankDepositReportQuery($lastDate: Date) {
    selfProject {
      name
      logo
  }
    allBankAccountReport (lastDate : $lastDate ) {
      edges{
        node{
            bankName
            branch
            accountType
            accountNumber
            summaryBank
            bankUnit{
                edges{
                    node{
                         issuedDate
                         dueDate
                         price
                    }
                }
          }
        }
      }
    }
    summaryBankReportPurchaseRecordGroup(lastDate:$lastDate){
      numRow
      sumBalance
    }
  }
`;

class wrapperBankDepositReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      all_bank: '',
      summary_balance: '',
      summary_report_residential: '',
      summary_report_supplier: '',
      summary_bank_report: '',
      project_name: '',
      project_logo: '',
      loading: true,
      date: this.props.match.params.date || new Date()
    };
  }
  getData = async () => {
    this.setState({ loading: true })
    let lastDate = format(this.state.date, "YYYY-MM-DD")
    await summaryReportOutstandingReceivable({ dueDate: lastDate, typeOfContact: 'residential' }, (data) => this.setState({ summary_report_residential: data.summaryReportOutstandingReceivable }))
    await summaryReportOutstandingReceivable({ dueDate: lastDate, typeOfContact: 'supplier' }, (data) => this.setState({ summary_report_supplier: data.summaryReportOutstandingReceivable }))
    await fetchQuery(environment, query, { lastDate }).then((data) => {
      let sum = _.sumBy(data.allBankAccountReport.edges, function (o) { return parseFloat(JSON.parse(o.node.summaryBank).balance) });
      this.setState({
        project_name: data.selfProject.name,
        project_logo: data.selfProject.logo,
        summary_balance: sum,
        all_bank: data.allBankAccountReport.edges,
        summary_bank_report: data.summaryBankReportPurchaseRecordGroup,
        loading: false
      })
    });
  }
  handleSummary = (sumBalance) => (sumBalance / this.state.summary_balance) * 100
    async componentDidMount() { await this.getData() }

  print = () => {
    window.print();
  }
  
  render() {
    const { all_bank, summary_report_residential, summary_report_supplier, summary_bank_report, summary_balance, loading, date, project_name, project_logo } = this.state
    return (
      <React.Fragment>
        <Helmet
          style={[{
            "cssText": `
                        body {
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                            background-color: #FAFAFA;
                          }
                        
                          * {
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                          }
                        
                          .page {
                            width: 297mm;
                            min-height: 210mm;
                            padding: 5mm;
                            margin: 10mm auto;
                            border: 1px #D3D3D3 solid;
                            border-radius: 2px;
                            background: white;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                          }
                        
                          @page {
                            size: landscape;
                            margin: 0;
                          }
                          @media print {
                            html, body {
                              width: 297mm;
                              height: 210mm;
                            }
                            .page {
                              margin: 0;
                              border: initial;
                              border-radius: initial;
                              width: initial;
                              min-height: initial;
                              box-shadow: initial;
                              background: initial;
                              page-break-after: always;
                            }
                          }
                    `
          }]}>
          <meta charSet="utf-8" />
          <title>Interest Print</title>
          <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
        </Helmet>

        <div className="print-top-menu">
          <div className="logo"><img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" /></div>
          <div className="print" onClick={this.print}>PRINT</div>
        </div>
        <div>

          <div className="page">
            <div className="container-fluid box p-3" id='bank-deposit-report-main' >
              <div className="">
                <div className="logo ml-1 " style={{ position: 'absolute' }}>{project_logo !== '' && <img src={project_logo} alt="silverman" style={{ width: '90px' }} />}</div>
                <div className="mt-5 mb-4 ">
                  <H5 className="text-center">{project_name}</H5>
                  <H5 className="text-center">รายงานเงินฝากธนาคาร</H5>
                  <H5 className="text-center">ประจำเดือน {format(date, "  MMMM YYYY ", { locale: thLocale, })}</H5>
                </div>
                {_.chunk(all_bank, 12).map((transaction, index) => {
                  if (index === 0) {
                    return (
                      <React.Fragment>
                        <BankDepositReportTablePDF
                          key={index}
                          all_bank={transaction}
                          summary_report_residential={summary_report_residential}
                          summary_report_supplier={summary_report_supplier}
                          summary_bank_report={summary_bank_report}
                          summary_balance={summary_balance}
                          loading={loading}
                          date={date}
                          handleSummary={this.handleSummary}
                          report
                          logo={project_logo}
                          lastPage={(index + 1 === _.chunk(all_bank, 12).length || _.chunk(all_bank, 12).length === 1) ? true : false}
                          lastPageNumber={_.chunk(all_bank, 12).length}
                          reportType='pdf'
                        />

                        {(index + 1 === _.chunk(all_bank, 12).length || _.chunk(all_bank, 12).length === 1) &&
                          <SummaryAccountsReceivableAndPayableTable
                            key={index}
                            all_bank={all_bank}
                            summary_report_residential={summary_report_residential}
                            summary_report_supplier={summary_report_supplier}
                            summary_bank_report={summary_bank_report}
                            summary_balance={summary_balance}
                            loading={loading}
                            date={date}
                            report
                            handleSummary={this.handleSummary}
                            lastPageNumber={_.chunk(all_bank, 12).length}
                            reportType='pdf'
                          />}
                      </React.Fragment>
                    )
                  }
                })}
              </div>
            </div>
          </div>
          {_.chunk(all_bank, 12).map((transaction, index) => {
            if (index > 0) {
              return (
                <div className="page">
                  <BankDepositReportTablePDF
                    key={index}
                    all_bank={transaction}
                    summary_report_residential={summary_report_residential}
                    summary_report_supplier={summary_report_supplier}
                    summary_bank_report={summary_bank_report}
                    summary_balance={summary_balance}
                    loading={loading}
                    date={date}
                    handleSummary={this.handleSummary}
                    report
                    logo={project_logo}
                    lastPage={(index + 1 === _.chunk(all_bank, 12).length || _.chunk(all_bank, 12).length === 1) ? true : false}
                    lastPageNumber={_.chunk(all_bank, 12).length}
                    reportType='pdf'
                  />
                  {(index + 1 === _.chunk(all_bank, 12).length || _.chunk(all_bank, 12).length === 1) &&
                    <SummaryAccountsReceivableAndPayableTable
                      key={index}
                      all_bank={all_bank}
                      summary_report_residential={summary_report_residential}
                      summary_report_supplier={summary_report_supplier}
                      summary_bank_report={summary_bank_report}
                      summary_balance={summary_balance}
                      loading={loading}
                      date={date}
                      report
                      handleSummary={this.handleSummary}
                      lastPageNumber={_.chunk(all_bank, 12).length}
                      reportType='pdf'
                    />}
                </div>
              )
            }
          })}
          {/* <div className="page">
            <SummaryAccountsReceivableAndPayableTable
              all_bank={all_bank}
              summary_report_residential={summary_report_residential}
              summary_report_supplier={summary_report_supplier}
              summary_bank_report={summary_bank_report}
              summary_balance={summary_balance}
              loading={loading}
              date={date}
              report
              handleSummary={this.handleSummary}
            />
          </div> */}
        </div>

      </React.Fragment>
    );
  }
}

export default wrapperBankDepositReport;
