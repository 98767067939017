import React from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "./styles/fixRequest.scss";
import Navigation from "./navigation";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Pagination from "../../libs/newPagination";
import { format, subMonths } from "date-fns";
import thLocale from "date-fns/locale/th";
import FixListDetailApprove from "./fixListDetailApprove";
import FixListDetailFinish from "./fixListDetailFinish";
import UpdateNotification from "./updateNotification";
import getWSRoot from "../../libs/getWSRoot";
import * as Swal from "sweetalert2";
import ExportFixRequest from "./server_export_fix_request";
import _ from "lodash";
// import ModalCreateWork from "./modalCreateWork";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import Search from "../SearchInput";
import i18n from "i18next"
import Loading from "../../libs/loading";

let chatListSocket = {};

const query = graphql`
  query fixRequestListQuery(
    $first: Int
    $last: Int
    $status: String
    $fix_type: String
    $search: String
    $start_date: DateTime
    $end_date: DateTime
  ) {
    allRooms(
      roomType: "fix_request"
      first: $first
      last: $last
      status: $status
      fixRequestRoom_FixType: $fix_type
      search: $search
      startDate: $start_date
      endDate: $end_date
    ) {
      totalCount
      edges {
        node {
          id
          numberNotRead
          residential {
            name
          }
          name
          detail
          status
          phone
          fixRequestRoom {
            id
            fixType
            technician
            startDate
            startTime
            endTime
            contactPerson
            contactNumber
            technicianName
          }
          added
          note
          handle {
            tenant {
              firstName
              lastName
              phone
            }
          }
        }
      }
    }
  }
`;

class FixRequestList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.start_date = subMonths(new Date(), 1);
    this.state.end_date = new Date();
    this.state.temp_start_date = subMonths(new Date(), 1);
    this.state.temp_end_date = new Date();
    this.state.fix_request_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.reQuery = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleChange(e);
    }
  }
  onChangeSearch(text){
    this.setState({search : text});
  }
  handleSearch(event){
    this.setState({
      start_date : this.state.temp_start_date ,
      end_date : this.state.temp_end_date,
      search : event.target.value
    })
    
  }

  componentWillMount() {
    UpdateNotification("fix_request");

    // connect ws
    chatListSocket = new WebSocket(
      getWSRoot() + "/chat_list/",
      window.localStorage.getItem("token")
    );

    let _this = this;
    chatListSocket.onmessage = function (e) {
      let data = JSON.parse(e.data);
      if (data.room_detail.id) {
        _this.setState({ reQuery: !_this.state.reQuery });
      }
    };

    chatListSocket.onclose = function (event) {
      if (event.code !== 1000 && event.code !== 1006) {
        Swal.fire({
          title: i18n.t("fix_request:update_sys_fail"),
          text: i18n.t("fix_request:connection_fail"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: i18n.t("fix_request:try_again"),
          cancelButtonText: i18n.t("fix_request:close"),
        }).then((result) => {
          if (result.value) {
            window.location.reload();
          }
        });
      }
    };
  }

  componentWillUnmount() {
    chatListSocket.close();
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <ContactTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="fixRequest">
            <div className="row justify-content-between">
              <div className="col-8">
                <h3>
                  <Link to="/contact">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <>
                      <span>{t("fix_request:fix_request_list")}</span>
                      <div className="ml-8 text-danger fix_request_old">
                        ({i18n.t("fix_request:fix_request_old")})
                      </div>
                    </>}
                  </Translation>
                </h3>
              </div>
              <div className="col">
                {/*<Link to="/contact/fix-request">
                                    <button type="button" className="btn btn-primary add float-right">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('fix_request:add_fix_request')}</span>
                                            }
                                        </Translation>
                                    </button>

                                    <ModalCreateWork/>
                                </Link>*/}

                {/*<Link to="/contact/fix-request/schedule">
                                    <button type="button" className="btn btn-outline-primary add float-right mr-3">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/calendar-blue.png'}
                                             alt="calendar-blue"/>
                                        <Translation>
                                            {
                                                t => <span>{t('fix_request:mechanic_calendar')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>*/}
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "contact_fix_request_print",
                }) && (
                  <ExportFixRequest
                    state={this.state}
                    status={this.props.match.params.status}
                  />
                )}
              </div>
            </div>

            <div className="content-inner">
              <Navigation />
            <Translation>{
            t=>
              <div className="row mb-2 mt-4">
                <div className="col-2">
                  {/* <select
                    className="form-control"
                    name="fix_request_type"
                    value={this.state.fix_request_type}
                    onChange={this.handleChangeInput}
                  >
                    <option value="">{t("fix_request:all_works")}</option>
                    <option value="repair">{t("fix_request:repair_work")}</option>
                    <option value="renovate">{t("fix_request:renovation_and_decoration")}</option>
                  </select> */}
                </div>

                {/* <div className="col-3 offset-7 input-group"> */}
                  {/*<div className="form-inline float-right">*/}
                  {/*<button type="button" className="btn btn-outline-secondary float-right mr-2">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/filter-icon.png'}
                                                 alt="filter-icon"/>
                                        </button>*/}
                <div className="col-7">
                  <div className="form-inline float-right">
                    <div className="form-group mb-2">
                      <label htmlFor="end_date" className="ml-3 mr-3">
                        {t("filterFunction:sort_by_date")}
                      </label>
                      <DatePickerAdapter
                        id="temp_start_date"
                        className="form-control"
                        name="temp_start_date"
                        selected={this.state.temp_start_date}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label htmlFor="end_date" className="ml-3 mr-3">
                        {t("filterFunction:to")}
                      </label>
                      <DatePickerAdapter
                        id="temp_end_date"
                        name="temp_end_date"
                        className="form-control"
                        selected={this.state.temp_end_date}
                        onChange={this.handleChange}
                      />
                    </div>  
                  </div>  
                </div>
                <div className="col-3">
                  <Search
                    callback_function={this.onChangeSearch}
                    search={this.state.search}
                    handleSearch={this.handleSearch}
                    temp_end_date={this.state.temp_end_date}
                    temp_start_date={this.state.temp_start_date}
                  />
                </div>
              </div>
              }
              </Translation>

              <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{ use_cache: false }}
                variables={{
                  first: this.state.first,
                  last: this.state.last,
                  status: this.props.match.params.status,
                  fix_type: this.state.fix_request_type,
                  start_date: this.state.start_date,
                  end_date: this.state.end_date,
                  search: this.state.search,
                  reQuery: this.state.reQuery,
                }}
                render={({ error, props }) => {
                  if (error) {
                    return (
                      <div className="alert alert-danger" role="alert">
                        {error.message}
                      </div>
                    );
                  } else if (props) {
                    return (
                      <React.Fragment>
                        {props.allRooms.edges.map((room, index) => (
                          <Translation>{
                            t=>
                          
                          <div className="card show mt-2" key={room.node.id}>
                            <div className="card-body p-4">
                              <div className="row">
                                <div className="col-md-2">
                                  <h6>{room.node.residential.name}</h6>
                                  {room.node.handle.tenant !== null ? (
                                    <p>
                                      {room.node.handle.tenant.firstName}{" "}
                                      {room.node.handle.tenant.lastName}
                                    </p>
                                  ) : (
                                    <p>-</p>
                                  )}
                                  {this.props.match.params === "approve" && (
                                    <React.Fragment>
                                      <span className="grey-color">
                                        {t("fix_request:appointment_time")}
                                      </span>
                                      <p>
                                        {" "}
                                        {format(
                                          new Date(
                                            room.node.fixRequestRoom.startDate
                                          ),
                                          "DD/MM/YYYY",
                                          { locale: thLocale }
                                        )}{" "}
                                        |{" "}
                                        {format(
                                          new Date(
                                            room.node.fixRequestRoom.startTime
                                          ),
                                          "HH:mm น.  ",
                                          { locale: thLocale }
                                        )}{" "}
                                        -{" "}
                                        {format(
                                          new Date(
                                            room.node.fixRequestRoom.endTime
                                          ),
                                          "HH:mm น.",
                                          { locale: thLocale }
                                        )}
                                      </p>
                                    </React.Fragment>
                                  )}
                                </div>
                                <div className="col-md-7">
                                  <span className="grey-color">
                                    {room.node.fixRequestRoom &&
                                    room.node.fixRequestRoom.technician ===
                                      "IN_HOUSE"
                                      ? t("chat:in_house_handyman")
                                      : t("chat:commercial_handyman")}{" "}
                                    | {t("chat:inside_the_room")}
                                  </span>
                                  <Link
                                    to={
                                      "/contact/fix-request/chat/fix-request/" +
                                      room.node.id
                                    }
                                  >
                                    <h6 className="mt-1">
                                      {room.node.name.length >= 80
                                        ? room.node.name.substring(0, 80) +
                                          "..."
                                        : room.node.name}
                                    </h6>
                                  </Link>
                                </div>

                                <div className="col-md-3 text-right">
                                  <p className="grey-color">
                                    {format(
                                      room.node.added,
                                      "DD/MM/YYYY - HH:mm น.",
                                      { locale: thLocale }
                                    )}
                                  </p>
                                  {room.node.numberNotRead !== 0 &&
                                    room.node.numberNotRead !== null && (
                                      <p className="badge mr-4">
                                        {room.node.numberNotRead}
                                      </p>
                                    )}
                                  {this.props.match.params.status !==
                                    "not-approve" &&
                                    this.props.match.params.status !==
                                      "finished" &&
                                    room.node.numberNotRead === null && (
                                      <p className="badge mr-4">new</p>
                                    )}

                                  {/*<img*/}
                                  {/*    src={process.env.PUBLIC_URL + '/images/icons/choice.png'}*/}
                                  {/*    alt="choice-icon" className="show-icon"/>*/}
                                </div>
                              </div>
                              {this.props.match.params.status === "approve" && (
                                <FixListDetailApprove
                                  room={room}
                                  index={index}
                                />
                              )}
                              {this.props.match.params.status ===
                                "finished" && (
                                <FixListDetailFinish
                                  room={room}
                                  index={index}
                                />
                              )}
                              {this.props.match.params.status ===
                                "not-approve" && (
                                <div className="row">
                                  <div className="col-md-2" />
                                  <div className="col-md-10">
                                    <div className="card-wrapper ">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col">
                                            <span className="grey-color">
                                              {t("fix_request:reason_for_cancellation")}
                                            </span>
                                            <h6 className="mt-2">
                                              {room.node.note}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          }
                          </Translation>
                        ))}
                        <div className="row">
                          <Pagination
                            changePage={this.changePage}
                            first={this.state.first}
                            last={this.state.last}
                            totalCount={props.allRooms.totalCount}
                          />
                        </div>
                      </React.Fragment>
                    );
                  }
                  return <Loading/>
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default FixRequestList;
