/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type channelPayListTableQueryVariables = {|
  typeOfReport?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  month_count?: ?number,
|};
export type channelPayListTableQueryResponse = {|
  +chartOfAccountCashBasisChannel: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +nameEn: ?string,
        +chartOfAccountCode: string,
        +col1Debit: ?number,
        +col2Debit: ?number,
        +col3Debit: ?number,
        +col4Debit: ?number,
        +col5Debit: ?number,
        +col6Debit: ?number,
        +col7Debit: ?number,
        +col8Debit: ?number,
        +col9Debit: ?number,
        +col10Debit: ?number,
        +col11Debit: ?number,
        +col12Debit: ?number,
        +col1Credit: ?number,
        +col2Credit: ?number,
        +col3Credit: ?number,
        +col4Credit: ?number,
        +col5Credit: ?number,
        +col6Credit: ?number,
        +col7Credit: ?number,
        +col8Credit: ?number,
        +col9Credit: ?number,
        +col10Credit: ?number,
        +col11Credit: ?number,
        +col12Credit: ?number,
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type channelPayListTableQuery = {|
  variables: channelPayListTableQueryVariables,
  response: channelPayListTableQueryResponse,
|};
*/


/*
query channelPayListTableQuery(
  $typeOfReport: String
  $start_date: DateTime
  $end_date: DateTime
  $month_count: Int
) {
  chartOfAccountCashBasisChannel(startDate: $start_date, endDate: $end_date, monthCount: $month_count, typeOfReport: $typeOfReport) {
    edges {
      node {
        id
        name
        nameEn
        chartOfAccountCode
        col1Debit
        col2Debit
        col3Debit
        col4Debit
        col5Debit
        col6Debit
        col7Debit
        col8Debit
        col9Debit
        col10Debit
        col11Debit
        col12Debit
        col1Credit
        col2Credit
        col3Credit
        col4Credit
        col5Credit
        col6Credit
        col7Credit
        col8Credit
        col9Credit
        col10Credit
        col11Credit
        col12Credit
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month_count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfReport"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "monthCount",
        "variableName": "month_count"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      },
      {
        "kind": "Variable",
        "name": "typeOfReport",
        "variableName": "typeOfReport"
      }
    ],
    "concreteType": "ChartOfAccountNodeConnection",
    "kind": "LinkedField",
    "name": "chartOfAccountCashBasisChannel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chartOfAccountCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col1Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col2Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col3Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col4Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col5Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col6Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col7Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col8Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col9Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col10Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col11Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col12Debit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col1Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col2Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col3Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col4Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col5Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col6Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col7Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col8Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col9Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col10Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col11Credit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col12Credit",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "channelPayListTableQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "channelPayListTableQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "a2e12720f9aaf91477f8d766d80e94a0",
    "id": null,
    "metadata": {},
    "name": "channelPayListTableQuery",
    "operationKind": "query",
    "text": "query channelPayListTableQuery(\n  $typeOfReport: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $month_count: Int\n) {\n  chartOfAccountCashBasisChannel(startDate: $start_date, endDate: $end_date, monthCount: $month_count, typeOfReport: $typeOfReport) {\n    edges {\n      node {\n        id\n        name\n        nameEn\n        chartOfAccountCode\n        col1Debit\n        col2Debit\n        col3Debit\n        col4Debit\n        col5Debit\n        col6Debit\n        col7Debit\n        col8Debit\n        col9Debit\n        col10Debit\n        col11Debit\n        col12Debit\n        col1Credit\n        col2Credit\n        col3Credit\n        col4Credit\n        col5Credit\n        col6Credit\n        col7Credit\n        col8Credit\n        col9Credit\n        col10Credit\n        col11Credit\n        col12Credit\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa07c870c1898b490051a5ae57a5940e';

module.exports = node;
