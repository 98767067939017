import React, { Component } from 'react'
import _ from "lodash"
import { Dropdown } from "react-bootstrap";


require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const month_list_arr = ["-","มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฏาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"];

export default class ReportDashboardSummaryExcel extends Component {

    downloadExcel = () => {
        let workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet('StockMovementReportsAndSupplies');

        let columns = [
            { width: 16 },
            { width: 28 },
            { width: 22 },
            { width: 22 },
            { width: 22 },
            { width: 16 },
        ];

        let borders = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };

        let fontSizes = { size: 11.5 };
        let fontBold = { size: 11.5, bold: true };
        let textAlignCenter = { vertical: 'middle', horizontal: 'center' };
        let textAlignStart = { vertical: 'middle', horizontal: 'left' };
        let textAlignEnd = { vertical: 'middle', horizontal: 'right' };

        worksheet.getCell('A1').value = "สรุปยอดรวมการเรียกเก็บเงิน";
        worksheet.getCell('A1').font = fontBold;

        worksheet.getCell('A2').value = "ประจำปี ";
        worksheet.getCell('B2').value = this.props.year;
        worksheet.getCell('B2').alignment = textAlignStart;
        worksheet.getCell('A2').font = fontBold;

        worksheet.getCell('A3').value = "รอบเดือน ";
        worksheet.getCell('B3').value = month_list_arr[this.props.month];
        worksheet.getCell('B3').alignment = textAlignStart;
        worksheet.getCell('A3').font = fontBold;

        let summaryTopic = ["รหัส","ประเภทการเรียกเก็บ","ยอดรวม","ชำระแล้ว","ยังไม่ชำระ","ชำระแล้ว ( % )"]
        
        worksheet.getRow(5).values = summaryTopic;
        _.forEach(summaryTopic, (itemHead, indexHead) => {
          let cell = worksheet.getRow(5).getCell(1 + indexHead)
          cell.font = fontBold;
          cell.border = borders;
          cell.alignment = textAlignCenter;
        })

        let details = [];
        
        {_.map(this.props.data.productSummaryByStatusYearAndMonth,(item,index) => {
          let product = JSON.parse(item);
          let percentPaid =  product.col_all_paid===0?0:((product.col_all_paid / product.col_all_total * 100).toFixed(2));
          details.push([
            `${product.product_code}`,
            `${product.name}`,
            `${product.col_all_total}`,
            `${product.col_all_paid}`,
            `${product.col_all_not_pay}`,
            `${percentPaid} %`
          ])
        
        })}
        
        _.map(details, (value, indexHead) => {

          worksheet.getRow(6 + indexHead).values = value;
          
          _.map(value, (value2, indexValue2) => {
    
            let cell = worksheet.getRow(6 + indexHead).getCell(1 + indexValue2)
            
            cell.font = fontSizes;
            cell.border = borders;
            cell.alignment = textAlignEnd;
          })
        })


        worksheet.columns = columns;
        
        workbook.xlsx.writeBuffer()
        .then((data)=>{
            const blob = new Blob([data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let nameFile = `ExcelDashboardSummaryReport-${this.props.year}-${this.props.month}`
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = nameFile;
            a.click();
        });
    }

    render() {
        return (
          <Dropdown.Item onClick={this.downloadExcel}>
            Excel
          </Dropdown.Item>
        )
    }
}