import React, { useState, useEffect } from 'react';
// import { Redirect } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, commitMutation } from "react-relay";
import environment from "../env/environment";
import Header from './headerLogin';
import './styles/termOfuser.scss'
import { Translation } from 'react-i18next';
import { format } from 'date-fns';
import i18n from 'i18next';
import _ from 'lodash';

const query = graphql`
    query selectProjectQuery ($typeOfProject: String, $search: String, $order: String){ 
      allSelectJuristic(typeOfProject: $typeOfProject, search: $search, order:$order){
        edges{
            node{
                id
                userOrder{
                    id
                    username
                    lastLogin
                }
                site{
                    id
                    domain
                    siteselectProjectManager{
                        edges{
                            node{
                                onlineSite
                                onlineApp
                            }
                        }
                    }
                    project{
                        edges{
                            node{
                                id
                                nameTh
                                nameEn
                                typeOfProject
                                logo
                                added
                            }
                        }
                    }

                }
                
            }
        }
                
    }
    }
`;

const mutation = graphql`
    mutation selectProjectMutation($input: UpdateUserConfigInput!) {
        UpdateUserConfig(input: $input) {
            newUserConfig {
                id languages
            }
        }
    }
`;

const SelectProject = (props) => {
    const [langauge, setLangauge] = useState(localStorage.getItem("language") ?? 'th')
    const [checkLastProject, setLastProject] = useState()
    const [projects, setProjectAll] = useState([])
    const [projectsView, setProjectView] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState("")
    const [type, setType] = useState("")
    const [typeShow, setTypeOfShow] = useState("table")
    const [sort, setSort] = useState("online")
    const [seacrhs, SsetSerach] = useState("")

    useEffect(() => {
        langaugeSet()
        getProjectList()
    }, [])

    // languages เซตไปใน DataBase เก็บไว้
    const langaugeSet = () => {
        if (langauge) {
            let variables = {
                input: { languages: langauge }
            };
            commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response, errors) => {
                    localStorage.setItem("language", langauge)

                },
                onError: error => {
                    alert('server error')
                },
                optimisticUpdater: (store) => {
                    // store.get(props.userConfig.id).setValue(languages , 'languages');
                    i18n.changeLanguage(langauge);
                }
            });
        }
    }

    // ดึงค่ามาทั้งหมดแล้วมาเซตไว้
    const getProjectList = () => {
        try {
            fetchQuery(environment, query, {
                "typeOfProject": "",
                "search": "",
                "order": 'online'
            }).then(data => {
                let findLastLogin = data?.allSelectJuristic?.edges.filter((nn) => nn.node.userOrder.lastLogin !== null)
                let project = data?.allSelectJuristic?.edges
                // console.log(project);

                project.forEach((ele, index) => {
                    _.set(project, `[${index}].node.site.project.edges[0].node.nameEn`, ele.node.site.project.edges[0].node.nameEn ? ele.node.site.project.edges[0].node.nameEn : ele.node.site.project.edges[0].node.nameTh)
                })
                setProjectAll(project)
                // setProjectAll(data?.allSelectJuristic?.edges.map((datas)=> ({
                //     ...datas, nameProject: datas.node.site.project.edges[0].node.nameTh
                // })))
                setProjectView(project)
                setLoading(false)
                setLastProject(findLastLogin)
            })
        } catch (err) {
            console.log(err);
        }
    }

    // ดึงค่าเรียกใช้อีกครั้ง เรียงลำดับตามที่เซตไว้
    const sortCnage = (value) => {
        try {
            fetchQuery(environment, query, {
                "typeOfProject": "",
                "search": "",
                "order": value
            }).then(data => {
                setProjectView(data?.allSelectJuristic?.edges)
                sortNewDataName([...data?.allSelectJuristic?.edges], value, type, seacrhs)
            })
        }
        catch (err) { }
        finally {
            setLoading(false)
            setSort(value)
        }

    }


    // เปลี่ยนภาษาเซตไว้ เพราะ ดึงจาก User แล้วไม่มี เลยเซตไว้อีกรอบกันพลาด
    const changeLangauge = (lang) => {
        setLangauge(lang == false ? 'en' : 'th')
    }

    //หาประเภทโครงการ
    const changeTypeOfProject = (value) => {
        try {
            setLoading(true)
            let vlueUp = value.toUpperCase()
            sortNewDataName(projects, sort === "name" ? 'name' : '', vlueUp, seacrhs)
            setType(vlueUp)
            setLoading(false)
        } catch (err) { }

    }

    // ค้นหารวมๆ ใน function เดียว
    const sortNewDataName = (project, sorts, types, sreachName) => {
        let projectlist = [...project]
        let name = langauge === "th" ? 'nameTh' : "nameEn"
        let findSearchData = types !== "" ? projectlist.filter(s =>
            (s.node.site?.project.edges[0].node[name]?.toLowerCase().includes(sreachName) || s.node.site?.project.edges[0].node['nameTh']?.toLowerCase().includes(sreachName)) &&
            s.node.site?.project.edges[0]?.node.typeOfProject === types) :
            _.cloneDeep(projects)


        // findSearchData = findSearchData.length > 0 ? [...findSearchData] : [...projects]
        findSearchData = [...findSearchData]
        if (sorts === "name") {
            setProjectView(findSearchData?.sort((a, b) => {
                return a?.node?.site?.project?.edges[0].node[name]?.localeCompare(b.node.site.project.edges[0].node[name], undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            }))

        } else {
            setProjectView(findSearchData)
        }
    }

    //เมื่อกดค้นหา และปุ่มเคลียร์ค่าการค้นหาทั้งหมด
    const searchDataProjectNameClick = (clecer) => {
        try {
            if (clecer) {
                let findSearchData = projects
                setProjectView(findSearchData)
                setType("")
                setSearch("")
                SsetSerach("")
                document.getElementById("searchselectProject").value = ""
            } else {
                sortNewDataName(projects, sort, type, seacrhs)
            }
        } catch (err) { }
    }

    //ช่องค้นหาเมื่อพิมพ์คำที่ค้นหา จะถูกเซตค่าไว้ และเรียกใช้ฟังกก์ชั้นเมื่อกด Enter 
    const searchDataProjectName = (event) => {
        try {
            let eveClo = _.cloneDeep(event)
            let { value } = eveClo.target
            let valLower = value.toLowerCase()
            SsetSerach(valLower)
            if (eveClo.key === "Enter") {
                setSearch(valLower)
                sortNewDataName(projects, sort, type, valLower)
            }
        } catch (err) { }
    }

    const goToSite = async (siteName, userOrder) => {
        try {
            let siteDev = window.location.host.includes("port80") || window.location.host.includes("localhost")
            let hotname = "http://" + window.location.host
            let domain = siteName.domain.split(".")
            let checkSite = siteDev ? 'http://' + window.location.host + '/changeSite' : `https://${domain[0]}-admin.${domain[1]}.${domain[2]}/changeSite`
            let checkLocal = siteName.id === "331" && siteDev ? "http://" + window.location.host + "/changeSite" : siteName.id === "163" && siteDev ? hotname + "/changeSite" : checkSite

            window.location.href = checkLocal + `/${userOrder}/${siteName.domain}/${localStorage.getItem("token")}`
        } catch (err) { }
    }

    if (projects.length === 0 && !loading) {
        window.location.href = "/home"
    }

    return (
        <div className="height-100">
            <Header lang={changeLangauge} />
            <Translation>
                {t =>
                    <div className="container-fluid box colorUse" key="SelectProject">
                        <div className='mt-10 w925 mx-auto'>
                            <p className='fs-18 fw-400 hoverShowa'>{t("selectProject:Recently accessed project")}
                                <label className="tooltipsSpan mb-0"> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={18} />
                                    <span className='tooltiptext text_FFFFFF bg-000000 w250 text-left' >{i18n.t("selectProject:The user's last four accessed and managed projects.")}</span>
                                </label>

                            </p>

                            {/* โครงการที่คุณเข้าใช้งานล่าสุด */}
                            <div className='row mb-5 pb-5 p-2'>

                                {
                                    checkLastProject?.length > 0 ?
                                        checkLastProject?.slice(0, 4).map((itemProject, index) => {
                                            let projectName = itemProject.node.site?.project.edges[0]?.node
                                            let logo = itemProject.node.site?.project.edges[0]?.node.logo
                                            let userOrder = itemProject.node.userOrder.username
                                            let lastLogin = itemProject.node.userOrder.lastLogin
                                            let checkOnline = itemProject.node.site?.siteselectProjectManager.edges[0]?.node.onlineSite
                                            return (
                                                <div onClick={() => checkOnline ? goToSite(itemProject.node.site, userOrder) : null}
                                                    className='col-6 h100 p-2 mb-3 cursor' key={"itemProjectAllView" + index}>
                                                    <div className='card h100 colorUse' style={{ "position": "relative", "alignItems": "center", "flexDirection": "row" }}>
                                                        <div>
                                                            <img src={logo} width={70} style={{ position: 'absolute', top: 16, padding: 4 }} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/icons/defualt.png"; }} />

                                                            <p className='text_2D2D30 fs-16 fw-400' style={{ marginTop: 15, marginLeft: 75, marginBottom: 0 }}>
                                                                {
                                                                    langauge === 'th' ?
                                                                        projectName?.nameTh
                                                                        :
                                                                        projectName?.nameEn && projectName?.nameEn !== "" && projectName?.nameEn !== "-" ?
                                                                            projectName?.nameEn :
                                                                            projectName?.nameTh

                                                                }
                                                                {
                                                                    !checkOnline && <label className='text-red'>&nbsp;- {t("selectProject:Project suspended")}</label>
                                                                }
                                                            </p>
                                                            <p className='text_808080 fs-16 fw-400' style={{ "marginLeft": "75px" }}>
                                                                {t("selectProject:Activation date")} {format(new Date(projectName.added), "DD/MM/YYYY")}
                                                                <label className="text_808080 fw-400 fs-12" style={{ float: 'right', marginLeft: '16px', }}>
                                                                    {
                                                                        lastLogin && format(new Date(lastLogin), langauge === "th" ? "DD/MM/YYYY,  HH:mm น." : "DD/MM/YYYY,  hh:mm A")
                                                                    }
                                                                </label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div style={{ position: 'relative', textAlign: 'center' }} className='col'>
                                            <p className='text-center'>
                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/fileEmpty.png"} width={100} />
                                            </p>
                                            <p className='text-center'>
                                                {t("selectProject:Not found list is activated last")}
                                            </p>
                                        </div>
                                }

                            </div>

                            <div className='border-bottom mb-6'></div>



                            <p className='fs-18 fw-400 hoverShowa'>
                                {t("selectProject:List of all projects")}  ({projects.length} {t("selectProject:Project")})
                                <label className="tooltipsSpan mb-0"> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={18} />
                                    <span className='tooltiptext text_FFFFFF bg-000000 w250 text-left'>{i18n.t("selectProject:The list of all projects accessible to users.")}</span>
                                </label>
                            </p>
                            {/* FILTER */}
                            <div className='arrowInSelect' style={{ flexDirection: 'row', display: 'flex' }}>
                                <label className='mr-3 ml-1 fw-400 fs-16' style={{ marginTop: 12 }}>{t("selectProject:Project type")}</label>
                                <div className="btn-group">
                                    <div
                                        className='selectArrow fw-400 fs-16 btn h48 w150 mr-1 ml-1 text-left'
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ width: 130, display: 'flex', alignItems: 'center' }}>
                                        <span className='fw-400 fs-16'>
                                            {type === "BUILDING" && t(`selectProject:Building`)}
                                            {type === "CONDOMINIUM" && t(`selectProject:Condominium`)}
                                            {type === "VILLAGE" && t(`selectProject:Village`)}
                                            {type === "" && t(`selectProject:All`)}
                                        </span>
                                    </div>
                                    <div className='dropdown-menu w-auto colorUse border p-0'>
                                        <div onClick={() => changeTypeOfProject("")}
                                            className={`cursor dropdown-item text-capitalize m-0 pt-3 pb-3 p-3 border-bottom border ${type === "" ? 'bg-e9ecef text_808080' : ''}`}>
                                            {t("selectProject:All")}
                                        </div>
                                        <div onClick={() => changeTypeOfProject("condominium")}
                                            className={`cursor dropdown-item text-capitalize m-0 pt-3 pb-3 p-3 border-bottom border ${type === "CONDOMINIUM" ? 'bg-e9ecef text_808080' : ''}`}>
                                            {t("selectProject:Condominium")}
                                        </div>
                                        <div onClick={() => changeTypeOfProject("village")}
                                            className={`cursor dropdown-item text-capitalize m-0 pt-3 pb-3 p-3 border-bottom border ${type === "VILLAGE" ? 'bg-e9ecef text_808080' : ''}`}>
                                            {t("selectProject:Village")}
                                        </div>
                                        <div onClick={() => changeTypeOfProject("building")}
                                            className={`cursor dropdown-item text-capitalize m-0 pt-3 pb-3 p-3 border-bottom border ${type === "BUILDING" ? 'bg-e9ecef text_808080' : ''}`}>
                                            {t("selectProject:Building")}
                                        </div>
                                    </div>

                                </div>
                                {/* <select className='mr-1 ml-1 form-control h48 w150'
                                    defaultValue={type}
                                    onChange={(e) => changeTypeOfProject(e.target.value)}>
                                    <option value={""}>{t("selectProject:All")}</option>
                                    <option value={"CONDOMINIUM"}>{t("selectProject:Condominium")}</option>
                                    <option value={"village"}>{t("selectProject:Village")}</option>
                                    <option value={"building"}>{t("selectProject:Building")}</option>
                                </select> */}
                                <label style={{ position: 'relative', textAlign: 'end' }}>
                                    <input placeholder={t("selectProject:Search by project name")}
                                        type={"text"} name="search" className='h48 mr-1 ml-1 form-control' style={{ width: 336 }} defaultValue={search}
                                        onKeyUp={(e) => searchDataProjectName(e)} id='searchselectProject' />

                                    <div style={{ position: 'absolute', top: '6px', right: '18px' }}>
                                        <button type="submit" className='btn' onClick={() => searchDataProjectNameClick(false)}>
                                            <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} className="search-outline" alt="search" />
                                        </button>
                                    </div>
                                    <span className='cursor mr-2' onClick={() => searchDataProjectNameClick(true)}>เคลียร์ค่า</span>
                                </label>
                                <div className="btn-group">
                                    <label
                                        className='cursor btn btn-primary h48 mr-1 ml-1 '
                                        id="dropdownMenuButton1"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ width: 130, padding: 10, borderRadius: 4 }}>
                                        <img src={process.env.PUBLIC_URL + '/images/iconAction/sort.png'} width={24} />
                                        <span className='fw-300 fs-14'>{t("selectProject:Sort")}</span>
                                    </label>
                                    <div className='dropdown-menu w-auto colorUse border p-0'>
                                        <div onClick={() => sortCnage("online")}
                                            className={`cursor dropdown-item text-capitalize m-0 pt-3 pb-3 p-3 border-bottom border ${sort === "online" ? 'bg-e9ecef text_808080' : ''}`}>
                                            {t("selectProject:Sort by last accessed date.")}
                                        </div>
                                        <div onClick={() => sortCnage("create")}
                                            className={`cursor dropdown-item text-capitalize m-0 pt-3 pb-3 p-3 border-bottom border ${sort === "create" ? 'bg-e9ecef text_808080' : ''}`}>
                                            {t("selectProject:Sort by project opening date.")}
                                        </div>
                                        <div onClick={() => sortCnage("name")}
                                            className={`cursor dropdown-item text-capitalize m-0 pt-3 pb-3 p-3 border-bottom border ${sort === "name" ? 'bg-e9ecef text_808080' : ''}`}>
                                            {t("selectProject:Alphabetical.")}
                                        </div>
                                    </div>
                                </div>
                                <label onClick={() => setTypeOfShow(typeShow === "view" ? "table" : 'view')}
                                    className='cursor btn btn-primary h48 mr-1 ml-1'
                                    style={{ width: 155, padding: 10 }}><img src={process.env.PUBLIC_URL + `/images/iconAction/${typeShow !== "view" ? 'fillter2.png' : 'filter.png'}`}
                                        width={typeShow !== "view" ? 24 : 18} /> <span className='fw-300 fs-14'>{t("selectProject:View")}</span>
                                </label>
                            </div>



                            {/* รายชื่อโครงการทั้งหมดของคุณ */}
                            {/* รายชื่อโครงการที่ผู้ใช้งานมีสิทธิ์ในการเข้าถึงทั้งหมด */}
                            {
                                (type !== "" || search !== "") &&
                                <React.Fragment>
                                    <p className='mt-6'>{t("selectProject:Result")} ({projectsView?.length} {t(`selectProject:${projectsView?.length > 1 ? "Projects" : "Project"}`)})</p>
                                    {
                                        projectsView.length <= 0 &&
                                        <React.Fragment>
                                            <p className='text-center'>
                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/fileEmpty.png"} width={100} />
                                            </p>
                                            <p className='text-center'>
                                                {t("selectProject:Not found your's project")}
                                            </p>
                                        </React.Fragment>
                                    }

                                </React.Fragment>
                            }

                            {
                                loading == false && projectsView.length > 0 &&
                                (
                                    typeShow === "table" ?
                                        <table className='table mt-5 colorUse'>
                                            <thead>
                                                <tr className='text-center bg-gray'>
                                                    <th className='border-0'></th>
                                                    <th className='border-0 text-left'>{t("selectProject:Project Name")}</th>
                                                    <th className='border-0'>{t("selectProject:Activation date")}</th>
                                                    <th className='border-0'>{t("selectProject:Recently accessed date")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    projectsView.map((itemProject, index) => {
                                                        let projectName = itemProject.node.site?.project.edges[0]?.node
                                                        let logo = itemProject.node.site?.project.edges[0]?.node.logo
                                                        let lastLogin = itemProject.node.userOrder.lastLogin
                                                        let userOrder = itemProject.node.userOrder.username
                                                        let checkOnline = itemProject.node.site?.siteselectProjectManager.edges[0]?.node.onlineSite
                                                        return (
                                                            <tr className='cursor text-center border-bottom' key={"itemProjectAllTable" + index}
                                                                onClick={() => checkOnline ? goToSite(itemProject.node.site, userOrder) : null}
                                                            >
                                                                <td width={60} className='p-0'><img src={logo} width={60} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/icons/defualt.png"; }} /></td>
                                                                <td className='text-left' style={{ position: 'relative' }}>
                                                                    {
                                                                        langauge === 'th' ?
                                                                            projectName?.nameTh
                                                                            :
                                                                            projectName?.nameEn && projectName?.nameEn !== "" && projectName?.nameEn !== "-" ?
                                                                                projectName?.nameEn :
                                                                                projectName?.nameTh
                                                                    }
                                                                    {
                                                                        !checkOnline && <label className='text-red'>&nbsp;- {t("selectProject:Project suspended")}</label>
                                                                    }
                                                                </td>
                                                                <td className='fs-16 fw-400'>
                                                                    {format(new Date(projectName.added), "DD/MM/YYYY")}
                                                                </td>
                                                                <td className='fs-16 fw-400'>{lastLogin && format(new Date(lastLogin), langauge === "th" ? "DD/MM/YYYY,  HH:mm น." : "DD/MM/YYYY,  hh:mm A")}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>

                                        </table>
                                        :
                                        <div className='row p-2'>
                                            {/* // <div style={{ flexDirection: 'row', display: 'flex' }}> */}
                                            {
                                                projectsView.map((itemProject, index) => {
                                                    let projectName = itemProject.node.site?.project.edges[0]?.node
                                                    let logo = itemProject.node.site?.project.edges[0]?.node.logo
                                                    let lastLogin = itemProject.node.userOrder.lastLogin
                                                    let userOrder = itemProject.node.userOrder.username
                                                    let checkOnline = itemProject.node.site?.siteselectProjectManager.edges[0]?.node.onlineSite
                                                    return (
                                                        <div onClick={() => checkOnline ? goToSite(itemProject.node.site, userOrder) : null}
                                                            className='col-6 h100 p-2 mb-3 cursor' key={"itemProjectAllView" + index} >
                                                            <div className='card h100' style={{ "position": "relative", "alignItems": "center", "flexDirection": "row" }}>
                                                                <div>
                                                                    <img src={logo} width={70} style={{ position: 'absolute', top: 16, padding: 4 }} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = "/images/icons/defualt.png"; }} />

                                                                    <p className='text_2D2D30 fs-16 fw-400' style={{ marginTop: 15, marginLeft: 75, marginBottom: 0 }}>
                                                                        {
                                                                            langauge === 'th' ?
                                                                                projectName?.nameTh
                                                                                :
                                                                                projectName?.nameEn && projectName?.nameEn !== "" && projectName?.nameEn !== "-" ?
                                                                                    projectName?.nameEn :
                                                                                    projectName?.nameTh
                                                                        }
                                                                        {
                                                                            !checkOnline && <label className='text-red'>&nbsp;- {t("selectProject:Project suspended")}</label>
                                                                        }

                                                                    </p>
                                                                    <p className='text_808080 fs-16 fw-400' style={{ "marginLeft": "75px" }}>
                                                                        {t("selectProject:Activation date")} {format(new Date(projectName.added), "DD/MM/YYYY")}
                                                                        <label className="text_808080 fw-400 fs-12" style={{ float: 'right', marginLeft: '16px', }}>
                                                                            {
                                                                                lastLogin && format(new Date(lastLogin), langauge === "th" ? "DD/MM/YYYY,  HH:mm น." : "DD/MM/YYYY,  hh:mm A")
                                                                            }
                                                                        </label>
                                                                    </p>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                )

                            }






                        </div>
                    </div>
                }
            </Translation >
        </div >
    )
}
export default SelectProject