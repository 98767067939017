import React, {Component} from "react";
import Swal from "sweetalert2";
import './styles/uploadFileInput.scss'
import {Translation} from "react-i18next";

class UploadFileInput extends Component {
    constructor(props) {
        super(props);
        this.handleInputFile = this.handleInputFile.bind(this)

    }

    handleInputFile(e) {
        let file = e.currentTarget.files[0];
        if (file) {
            let totalBytes = file.size;
            let megaBytes = 10485760;
            if (totalBytes > megaBytes) {
                Swal.fire('ขนาดไฟล์ใหญ่เกินไป!', 'กรุณาเลือกไฟล์ใหม่', 'warning');
                return
            } else {
                this.props.handleInputDocument(e)
            }
        }
    }

    render() {
        let name = "document_upload";
        let id = "uploadFile";
        if (this.props.name) {
            name = this.props.name;
            id = this.props.name;
        }
        if (this.props.dataId || this.props.dataId === 0) {
            name = name + this.props.dataId;
            id = id + this.props.dataId;
        }

        return (
            <React.Fragment>
                {!this.props.disableHeaderText &&
                    <label htmlFor={id} className="mr-5">
                        <Translation>{ t => <strong className="text-danger font-small">{t('createUpdateForm:Attach files (JPG, PNG, PDF size not over 10 MB)')}</strong>}</Translation>
                    </label>
                }
                <div className="custom-file">
                    <input type="file" className="custom-file-input"
                           id={id} name={name} data-key={this.props.dataKey} data-id={this.props.dataId}
                           accept="application/pdf, image/*"
                           onChange={this.handleInputFile}/>

                    <label className="custom-file-label text-document text-secondary" htmlFor={id}>
                        {
                            this.props.documentUpload &&
                            this.props.documentUpload.name.length >= 20 ?
                                this.props.documentUpload.name.substr(0, 20)
                                :
                                this.props.documentUpload.name
                        }
                    </label>
                </div>
            </React.Fragment>
        )
    }
}

export default UploadFileInput