import React, {Component} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {graphql} from "babel-plugin-relay/macro";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import getDocumentNumber from "../../../libs/getDocumentNumber";
import SearchSelect from "../../../libs/searchSelect";
import "./styles/pettyCashRecordCreate.scss"
import InvoiceDelete from "../../income/invoice/invoiceDelete";
import {Link} from "react-router-dom";
import {format} from "date-fns";
import {commitMutation} from "react-relay";
import environment from "../../../env/environment";
import Swal from "sweetalert2";
import {Redirect} from 'react-router';
import numberWithCommas from "../../../libs/numberWithComma";
import UploadFileInput from "../../../libs/uploadFileInput";
import _ from 'lodash'
import Asset from '../../../libs/autoAsset'
import { Translation } from 'react-i18next';
import i18next from 'i18next';



const mutation = graphql`
    mutation pettyCashRecordCreateMutation($input: CreatePettyCashRecordInput!){
        createPettyCashRecord(input:$input){
            ok
            warningText
        }
    }`;

let transaction_object = {
    id: "",
    description: "",
    price: 0,
    chartOfAccount: {},
    document_upload: "",
};

class PettyCashRecordCreate extends Component {

    constructor(props) {
        super(props);
        let chart_of_account_sum = [];
        let group1_filter = props.query.viewer.group1.edges.filter(e => 
            e.node.chartOfAccountCode.slice("-",4) === "1151" || 
            e.node.chartOfAccountCode.slice("-",4) === "1152" || 
            e.node.chartOfAccountCode.slice("-",4) === "1154" || 
            e.node.chartOfAccountCode.slice("-",4) === "1210" ||
            e.node.chartOfAccountCode.slice("-",4) === "1230" 
            );
        let group2_filter = props.query.viewer.group2.edges.filter(e => 
            e.node.chartOfAccountCode.slice("-",4) === "2120" || 
            e.node.chartOfAccountCode.slice("-",4) === "2121" || 
            e.node.chartOfAccountCode.slice("-",4) === "2122" || 
            e.node.chartOfAccountCode.slice("-",4) === "2123" || 
            e.node.chartOfAccountCode.slice("-",4) === "2124" ||
            e.node.chartOfAccountCode.slice("-",4) === "2125" ||
            e.node.chartOfAccountCode.slice("-",4) === "2126" ||
            e.node.chartOfAccountCode.slice("-",4) === "2230" 
            );

        group1_filter.map((g1) => {
            return chart_of_account_sum.push(g1);
        })
        group2_filter.map((g2) => {
            return chart_of_account_sum.push(g2);
        })
        props.query.viewer.group5.edges.map((g5) =>{
            return chart_of_account_sum.push(g5);
        })
        this.state = {
            issued_date: new Date(),
            transaction_list: [],
            chart_of_account: chart_of_account_sum,
            set_petty_cash: this.props.id_parms,
            total_petty_cash:0,
            redirectToList: false,
            loading: false,
            signreq:Math.random().toString(36),

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.appendNewRow = this.appendNewRow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setTransactionList = this.setTransactionList.bind(this);
        this.calSummary = this.calSummary.bind(this);

    }

    handleInputChange(e) {
        if (e.target.dataset.key === 'transaction_list') {
            let transaction_list = [...this.state.transaction_list];
            if (e.target.name === 'chartOfAccount') {
                let chart_of_account_list = [...this.state.chart_of_account];

                chart_of_account_list.forEach((ps) => {
                    if (ps.node.id === e.target.value) {
                        if((_.startsWith(ps.node.chartOfAccountCode,'1210') || _.startsWith(ps.node.chartOfAccountCode,'1230')) && this.props.no_setting_asset){
                            Swal.fire(i18next.t("pettyCashCreate:Can't choose this item."), i18next.t("pettyCashCreate:Because the asset is not set. Please set asset is complete before making a transaction."), "warning");
                            transaction_list[e.target.dataset.id]['chartOfAccount'] = '';
                        }else{
                            transaction_list[e.target.dataset.id]['chartOfAccount'] = ps.node;
                            transaction_list[e.target.dataset.id]['price'] = 0;
                            transaction_list[e.target.dataset.id]['description'] = ps.node.name;
                        }   
                    }
                });

            } else if(e.target.name.includes('document_upload')) {
                transaction_list[e.target.dataset.id]['document_upload'] = e.currentTarget.files[0];
            } else {
                transaction_list[e.target.dataset.id][e.target.name] = e.target.value;
                if(e.target.name === 'price'){
                    this.calSummary();
                }
            }

            this.setState({transaction_list: [...transaction_list]});
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    }

    componentWillMount() {

        this.setState({
            transaction_list: [...this.state.transaction_list, {...transaction_object}],
        });

    }

    appendNewRow() {
        this.setState({transaction_list: [...this.state.transaction_list, {...transaction_object}]});
    }

    setTransactionList(new_transaction) {
        this.setState({transaction_list: new_transaction})
    }

    imageFormat(data_list) {
        let upload = {};
        data_list.forEach((data, index) => {
            if (data.document_upload) {
                Object.assign(upload, {[index]: data.document_upload});
            }
        });
        return upload
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let asset = []
        _.forEach(this.state.transaction_list , transaction => {
            if(_.startsWith(transaction.chartOfAccount.chartOfAccountCode,'1210') || _.startsWith(transaction.chartOfAccount.chartOfAccountCode,'1230')){
                asset.push({
                    type: Asset.getTypeAsset(transaction.chartOfAccount.chartOfAccountCode),
                    nounClassifier:'',
                    historicalValue:0,
                    purchaseDate:format(this.state.issued_date, 'YYYY-MM-DD'),
                    costPrice: parseFloat(transaction.price) ,
                    calDepreciationDate: transaction.chartOfAccount.chartOfAccountCode === '1210-01' ? null : format(this.state.issued_date, 'YYYY-MM-DD'),
                    carcassPrice:1,
                    historicalAmount:0,
                    name: transaction.description,
                    description:transaction.description,
                    storage:'',
                    remark:'',
                    seller: 'other_select',
                    sellerOther: '',
                    saleDate:null,
                    saleStatus:'active',
                    refNumber:''
                })
            }
        })

        let variables = {
            input: {
                issuedDate: format(this.state.issued_date, 'YYYY-MM-DD'),
                setPettyCash: this.state.set_petty_cash,
                transactionList: JSON.stringify(this.state.transaction_list),
                asset:asset,
                clientMutationId:"PC"+this.state.signreq,
            }
        };
        let uploadables = this.imageFormat(this.state.transaction_list);
        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    if (response.createPettyCashRecord.ok) {
                        Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(()=>{
                            this.setState({redirectToList: true, loading: false});
                        })
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createPettyCashRecord.warningText, 'warning');
                        this.setState({loading: false});

                    }
                },
                onError: (err) => {
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                    this.setState({loading: false});
                },
            },
        );




    }

    calSummary(){
        let summary = 0;
        this.state.transaction_list.forEach((transaction)=>{
           summary += parseFloat(transaction.price);
        });
        this.setState({total_petty_cash:summary})
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/expense/petty_cash/list/record"/>
        }

        return (<Translation>{t=> 
         <form onSubmit={this.onSubmit}>
                <div className="row mb-3 mt-1">
                    <div className="col-4 form-inline">
                        <label htmlFor="date"
                               className="col-form-label"><strong>{t("pettyCashView:List of petty cash")}</strong></label>
                        <div className="col-8">
                            <SearchSelect onChange={this.handleInputChange}
                                          value={this.state.set_petty_cash}
                                          name="set_petty_cash" placeholder={t("pettyCashView:List")}
                                          data-key="set_petty_cash"
                                          queryObject={this.props.query.allSetPettyCash.edges}
                                          keyOfValue="id" require={true}
                                          keyOfLabel="docNumber:withdrawer:description"/>
                        </div>
                    </div>
                    <div className="col-3 form-inline">
                        <label htmlFor="date"
                               className="mr-3"><strong>{t("pettyCashView:Date ")}</strong></label>
                        <DatePickerAdapter id="issued_date"
                                           className="form-control" name="issued_date"
                                           selected={this.state.issued_date}
                                           onChange={this.handleInputChange}
                                           />
                    </div>
                    <div className="col-4 form-inline">
                        <label htmlFor="start_date"
                               className="startDate mr-3"><strong>{t("pettyCashView:Document No.")}</strong></label>
                        <input type="text" className="form-control"
                               value={getDocumentNumber.get('petty_cash', 'XXXX', this.state.issued_date)}
                               disabled/>
                    </div>
                </div>

                <div className="fade-up" id="petty-cash-record-create">
                    <table className="table table-hover mt-2">
                        <thead className="thead-light">
                        <tr>
                            <th width="200">{t("pettyCashView:List")}</th>
                            <th width="80">{t("pettyCashView:Account")}</th>
                            <th width="300">{t("receipt_deposit:Description")} <small className='text-danger'>{t("purchaseCreate:*Enter text not over 80 character characters")}</small> </th>
                            <th width="60" className="text-right">{t("pettyCashView:Amount")}</th>
                            <th width="50" className="text-center">{t("cheque:Reference document")}</th>
                            <th width="20"/>
                        </tr>
                        </thead>
                        <tbody>
                        { 
                            this.state.transaction_list.map((transaction, index) => (
                                <tr key={"transaction-" + index}>
                                    <td>
                                        <SearchSelect onChange={this.handleInputChange}
                                                      value={transaction.chartOfAccount.id}
                                                      name="chartOfAccount" placeholder="รายการ"
                                                      data-id={index} data-key="transaction_list"
                                                      queryObject={this.state.chart_of_account}
                                                      keyOfValue="id" require={true}
                                                      keyOfLabel="chartOfAccountCode:name"/>
                                    </td>
                                    <td>
                                        {this.state.chart_of_account.map((chart_of_account) => (
                                            chart_of_account.node.id === transaction.chartOfAccount.id &&
                                            <span
                                                key={chart_of_account.node.id}>{chart_of_account.node.chartOfAccountCode}</span>

                                        ))}
                                    </td>
                                    <td>
                                        <input type="text" min={1} className="form-control"
                                               data-id={index} data-key="transaction_list"
                                               name="description"
                                               value={transaction.description || ""}
                                               onChange={this.handleInputChange} 
                                               maxLength={80}
                                               required/>
                                    </td>
                                    <td>
                                        <input type="number"
                                               min={0.01} step="0.01"
                                               className="form-control text-right"
                                               data-id={index} data-key="transaction_list"
                                               name="price" value={transaction.price}
                                               onChange={this.handleInputChange}
                                               required/>

                                    </td>
                                    <td className="text-center">
                                        <UploadFileInput
                                            handleInputDocument={this.handleInputChange}
                                            documentUpload={transaction.document_upload} dataKey="transaction_list"
                                            dataId={index}
                                            name={"document_upload"}
                                            disableHeaderText={true}
                                        />
                                        <strong className="text-danger font-small">{t("stockCreateAndUpdate:Attach file")} (JPG PNG PDF {t("fileType:size not over 10 MB")})</strong>
                                    </td>
                                    <td width="20" className="align-middle text-center">
                                        <InvoiceDelete transaction={transaction}
                                                       transaction_list={this.state.transaction_list}
                                                       setTransactionList={this.setTransactionList}/>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn add-button add" onClick={this.appendNewRow}>
                                +{t("settingAll:Add list")}
                            </button>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12 text-right">
                            <div className="col-4 total">
                                <div className="row p-3 mb-2 bg-light text-dark">
                                    <div className="col text-left"> {t("pettyCashView:Total")}</div>
                                    <div
                                        className="col text-right">{numberWithCommas(this.state.total_petty_cash)} {t("pettyCashView:Baht")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right mt-2 button-grp">
                            <Link to="/accounting/expense/petty_cash/list/record">
                                <button type="button" className="btn btn-secondary add">
                                {t("Allaction:cancel")}
                                </button>
                            </Link>
                            {this.state.total_petty_cash !== 0?
                                <button type="submit" className="btn btn-primary add" disabled={this.state.loading}>
                                    {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                    {t("Allaction:save")}
                                </button>
                                :
                                <button type="button" className="btn btn-light disabled add">
                                    {t("Allaction:save")}
                                </button>
                            }

                        </div>
                    </div>


                </div>
            </form>
        
        }
        </Translation>

        )
    }
}

export default PettyCashRecordCreate;
