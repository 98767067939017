import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import PurchaseList from './purchaseList';
import PurchaseCreate from './purchaseCreate';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import PurchaseDetail from "./purchaseDetailQuery";

class Purchase extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={PurchaseList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status`} component={PurchaseCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/purchase-detail/:id`} component={PurchaseDetail}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Purchase;