/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type checkRangeYearBudgetQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
|};
export type checkRangeYearBudgetQueryResponse = {|
  +checkRangeYearBudget: ?boolean
|};
export type checkRangeYearBudgetQuery = {|
  variables: checkRangeYearBudgetQueryVariables,
  response: checkRangeYearBudgetQueryResponse,
|};
*/


/*
query checkRangeYearBudgetQuery(
  $startDate: Date
  $endDate: Date
) {
  checkRangeYearBudget(startDate: $startDate, endDate: $endDate)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "kind": "ScalarField",
    "name": "checkRangeYearBudget",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "checkRangeYearBudgetQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "checkRangeYearBudgetQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "06e5884741823e76329514b987407c68",
    "id": null,
    "metadata": {},
    "name": "checkRangeYearBudgetQuery",
    "operationKind": "query",
    "text": "query checkRangeYearBudgetQuery(\n  $startDate: Date\n  $endDate: Date\n) {\n  checkRangeYearBudget(startDate: $startDate, endDate: $endDate)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9006f4999fa8c24aad59984d12d91d9d';

module.exports = node;
