import React, { useEffect, useState } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import UserNameInput from "./userNameInput";
import PasswordValidation from './passwordValidity'
import UploadImageInput from "../../libs/uploadImageInput";
import "./style/createUpdateSettingJuristic.scss"
import Redirect from "react-router-dom/es/Redirect";
import i18n from "i18next";
import { Translation } from "react-i18next";
import jwtDecode from 'jwt-decode'
import { allJuristicObject } from './query/allJuristicObject';
import { AllSiteBySuperAdmin } from './query/allSiteBySuperAdmin';
import Select from 'react-select';
import { GetAllJobPermission } from './query/allJobPermission';
import Loading from '../../libs/loading';
import { GetPositionPermissionSite } from './query/positionPermissionSite';
import { encode } from 'base-64';
const _ = require('lodash');
const $ = window.jQuery;

const mutation = graphql`
    mutation createUpdateSettingJuristicMuliMutation($input: CreateUpdateJuristicInput!) {
        createUpdateJuristic(input: $input) {
            newJuristic {
                id
            }
        }


    }
`;

const CreateUpdateSettingJuristicMuli = (props) => {
    const [siteAllSelected, SetsiteAllSelected] = useState([])
    const [loading, setLoading] = useState(false);
    const [loadingJobBySite, setLoadingJobBySite] = useState(true);
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingJob, setLoadingJob] = useState(true);
    const [existUserName, setexistUserName] = useState(false)
    const [passwordResult, setpasswordResult] = useState(false)
    const [redirectToList, setredirectToList] = useState(false)
    const [checkConfirmPassword, setcheckConfirmPassword] = useState(false)
    const [confirmPassword, setconfirmPassword] = useState("")
    const [token] = useState(jwtDecode(window.localStorage.getItem('token')))
    const [projectList, setProjectList] = useState([])
    const [selectJuristic, setselectJuristic] = useState([{ site: '', role: '' }])
    const [juristic, setjuristic] = useState({
        id: '',
        user: {
            id: '',
            username: '',
        },
        firstName: '',
        lastName: '',
        image: '',
        signature: '',
        selectJuristic: [],
        phone:'',
        email:''
    })

    useEffect(() => {
        allJuristicObjects()
        getAllJob()
        getProjectAllName()
    }, [])

    const getAllJob = () => {
        GetAllJobPermission({}, (res) => {
            // let mapDataJob = res.map((item) => ({ ...item, label: item.node.positionName, value: item.node.positionName }))
            // setAllJob(mapDataJob)
            setLoadingJob(false)
        })
    }

    const getProjectAllName = () => {
        AllSiteBySuperAdmin({}, (res) => {
            let mapDataPro = res
            mapDataPro = _.map(_.cloneDeep(mapDataPro), ex => ({
                value: ex.node.site.id,
                label: ex.node.site.project.edges[0].node.nameTh,
                status: siteAllSelected.includes(ex.node.site.id),
                indexKeep: null
            }))
            setProjectList(mapDataPro)
        })
    }

    const allJuristicObjects = () => {
        if (props.match.params.id) {
            setLoadingPage(true)
            // update
            allJuristicObject({ userId: props.match.params.id }, (res) => {
                if (res) {
                    SetsiteAllSelected(res.edges[0].node.siteId.slice(1, -1).split(", "))
                    setjuristic(res?.edges[0]?.node)
                    let mapdata = []
                    let dataArray = res.edges[0].node.project.slice(1, -1).split(", ");
                    let dataArrayRole = res.edges[0].node.roles.slice(1, -1).split(", ");
                    let dataArraySiteId = res.edges[0].node.siteId.slice(1, -1).split(", ");
                    let cleanedData = dataArray.map(item => item.replace(/'/g, ''));
                    let cleanedDataRole = dataArrayRole.map(item => item.replace(/'/g, ''));
                    cleanedData.forEach((element, index) => {
                        GetPositionPermissionSite({ siteId: encode("SiteNode:" + dataArraySiteId[index]) }, (res_pons) => {
                            let res_ponss = res_pons?.map((item) => ({ value: item.node.positionName, label: item.node.positionName }))
                            mapdata.push({ site: dataArraySiteId[index], nameSite: element, role: cleanedDataRole[index], jobAll: res_ponss })
                            if (mapdata.length === dataArraySiteId.length) {
                                setselectJuristic(mapdata)
                                setLoadingJobBySite(false)
                            }
                        })
                    });
                    setLoadingPage(false)
                } else {
                    setLoadingPage(false)
                    setLoadingJobBySite(false)
                }
            })
        } else {
            setLoadingJobBySite(false)
            setLoadingPage(false)
        }
    }

    const handleInputChange = (e) => {
        let event = _.cloneDeep(e);

        setjuristic(
            _.set(
                _.cloneDeep(juristic),
                event.target.name,
                event.target.value
            )
        )
        // if (token?.site?.includes('sklasmanagement') || token?.site?.includes('victorymanagement') || token?.site?.includes('port80')) {
        if (token?.site?.includes('sklasmanagement') || token?.site?.includes('victorymanagement')) {
            var pass = document.getElementById("password").value;
            var rpass = document.getElementById("confirmPassword").value;
            setconfirmPassword(rpass)
            if (pass !== rpass) {
                setcheckConfirmPassword(true)
            } else {
                setcheckConfirmPassword(false)
            }
            if (event.target.name === 'user.password') {
                setpasswordResult(event.target.dataset.passwordResult)
            }
        }

        if (event.target.name === 'user.username') { //check if username is exist
            setexistUserName(event.target.dataset.existUserName)
        }
    }

    const showHidePassword = () => {
        var password = document.getElementById("password");
        var confirmPassword = document.getElementById("confirmPassword");

        if (password.type === "password" && confirmPassword.type === "password") {
            password.type = "text";
            confirmPassword.type = "text";
        } else {
            password.type = "password";
            confirmPassword.type = "password";
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        try {
            if (existUserName) {
                $('#username').focus();
                return
            }

            //only sena and sklasmanagement
            // if (token?.site?.includes('sklasmanagement') || token?.site?.includes('victorymanagement') || token?.site?.includes('port80')) {
            if (token?.site?.includes('sklasmanagement') || token?.site?.includes('victorymanagement')) {
                if (!passwordResult) {
                    $('#password').focus();
                    return
                }

                if (checkConfirmPassword) {
                    $('#confirmPassword').focus();
                    return
                }
            }

            setLoading(true)
            let variables = {
                input: {
                    juristic: JSON.stringify(juristic)
                }
            };
            let uploadables = juristic.upload_image;
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        setLoading(false)
                        if (response) {
                            Swal.fire(i18n.t("premission:Save successfully!"), '', 'success').then(() => {
                                setredirectToList(true)
                            });
                        } else {
                            Swal.fire(i18n.t("premission:Save unsuccessfully!"), i18n.t("premission:Please check again"), 'warning');
                        }
                    },
                    onError: (err) => {
                        setLoading(false)
                        Swal.fire('Error!', i18n.t("premission:Please try again"), 'warning')
                    },
                },
            )
        } catch (err) {
            console.log(err);
        }

    }

    const addSite = (type, index, stieId) => {
        let cloneselectJuristic = _.cloneDeep(selectJuristic)
        try {
            if (type === "add") {
                cloneselectJuristic.push({ site: '', role: '' })
            } else if (type === "delete") {
                cloneselectJuristic.splice(index, 1)
                // ถ้าเลือกโครงการแล้ว ลบโครงการออก จะต้องแสดงโครงการให้เลือกได้เสมอ
                let findIndexProject = projectList.findIndex(ex => ex.value === stieId)
                setProjectList(
                    _.set(
                        _.cloneDeep(projectList),
                        `[${findIndexProject}].status`, false
                    )
                )
            }
        } catch (err) {
            console.log(err);
        } finally {
            setselectJuristic(cloneselectJuristic)
        }
    }

    const handleChangeSite = (target, index) => {
        // // รายการ projectList ทั้งหมด 
        // let cloneProjectList = _.cloneDeep(projectList)

        // // เซต ไซต์ที่ถูกเลือกให้เป็น True และไซต์นั้นจะไม่แสดงให้เห็นถ้าไซต์ที่เลือกยังไม่ได้ลบ
        // _.set(cloneProjectList, target.name, true)
        // setProjectList(cloneProjectList)
        let selectJus = _.cloneDeep(selectJuristic)

        let nameFor = target.name.split(".")
        if (nameFor[1] === "site") {
            GetPositionPermissionSite({ siteId: encode("SiteNode:" + target.value) }, (res_pons) => {
                if (res_pons !== null) {
                    let res_pons_map = res_pons?.map((item) => ({ value: item.node.positionName, label: item.node.positionName }))
                    _.set(selectJus, target.name, target.value)
                    _.set(selectJus, `${nameFor[0]}.jobAll`, res_pons_map)

                    setselectJuristic(selectJus)
                } else {
                    Swal.fire(i18n.t("receipt_deposit:Please try again", "", 'error'))
                }
            })
        } else {
            setselectJuristic(
                _.set(
                    selectJus,
                    target.name,
                    target.value
                )
            )
        }

        setjuristic(
            _.set(
                _.cloneDeep(juristic),
                "selectJuristic", selectJus
            )
        )
        let findIndexProject = projectList.findIndex(ex => ex.value === target.value)
        let findIndexKeep = projectList.findIndex(ex => ex.indexKeep === index)
        let projectLists = _.cloneDeep(projectList)
        _.set(projectLists, `[${findIndexKeep}].status`, false)
        _.set(projectLists, `[${findIndexKeep}].indexKeep`, null)
        _.set(projectLists, `[${findIndexProject}].status`, findIndexProject < 0 ? false : true)
        _.set(projectLists, `[${findIndexProject}].indexKeep`, index)

        setProjectList(projectLists)
    }

    if (redirectToList) {
        return <Redirect to="/setting/juristic-setting" />
    }

    return (!loadingPage && !loadingJob ?
        <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent>
                <Translation>
                    {t =>
                        <div className="container-fluid box colorUse" id="project-manager-list">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/setting/juristic-setting">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back" />
                                        </Link>
                                        {
                                            props.match.params.id ? t("premission:Edit juristic person") : t("premission:Add juristic person")
                                        }
                                    </h3>
                                </div>
                            </div>
                            <form onSubmit={onSubmit} style={{ zoom: '80%' }}>
                                <div className="content-inner">
                                    <div className="row mb-4">
                                        <div className="col-6">
                                            <div className="card p-3 fade-up">
                                                <p className='fs-18 fw-400 mt-2 mb-2 hoverShowa'>
                                                    {t("selectProject:User Information")}
                                                    <label className="tooltipsSpan mb-0"> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={18} />
                                                        <span className='tooltiptext text_FFFFFF bg-000000 w350 text-left' >{i18n.t("selectProject:ข้อมูลของผู้ใช้งานทั้งหมดเช่น รูปภาพ, ชื่อและรหัสผ่านสำหรับเข้าใช้งานระบบและชื่อนามสกุลจริงของผู้ใช้งาน")}</span>
                                                    </label>
                                                </p>
                                                <div className='border-top mt-2' />
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className='col-6 mb-5'>
                                                            <div className='row'>
                                                                <div className="col-5">
                                                                    {juristic.image ?
                                                                        <img
                                                                            src={juristic.image}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="juristic-profile" />
                                                                        :
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="juristic-profile" />
                                                                    }
                                                                </div>
                                                                <div className="col-7">
                                                                    <h6 className="mt-4">{t("premission:Picture")}</h6> <small className="text-red">
                                                                        {t("premission:Attach files (JPG 10 MB)")}
                                                                    </small>
                                                                    <div className="upload-file">
                                                                        <div>
                                                                            <label className="btn-link"
                                                                                htmlFor="uploadImage">{t("premission:Change picture")}</label>
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <UploadImageInput id="uploadImage"
                                                                                setField="upload_image.image:image"
                                                                                onChange={handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 mb-5'>
                                                            <div className="row">
                                                                <div className="col-5">
                                                                    {juristic.signature ?
                                                                        <img
                                                                            src={juristic.signature}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="juristic-signature" />
                                                                        :
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/signature-upload-icon.png'}
                                                                            className="rounded-circle avatar-img-juristic profile-img"
                                                                            alt="juristic-signature" />
                                                                    }
                                                                </div>
                                                                <div className="col-7">
                                                                    <h6 className="mt-4">{t("premission:Signature")}</h6><small className="text-red">
                                                                        {t("premission:Attach files (JPG 10 MB)")}
                                                                    </small>
                                                                    <div className="upload-file">
                                                                        <div>
                                                                            <label className="btn-link"
                                                                                htmlFor="uploadSignature">{t("premission:Change picture")}</label>
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <UploadImageInput id="uploadSignature"
                                                                                setField="upload_image.signature:signature"
                                                                                onChange={handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <UserNameInput
                                                            tooltip={
                                                                <label className="tooltipsSpan mb-0"> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={18} />
                                                                    <span className='tooltiptext text_FFFFFF bg-000000 w350 text-left' >{i18n.t("selectProject:ชื่อเข้าใช้งานระบบ ใส่ได้เฉพาะ a-z ทั้งพิมพ์ใหญ่และพิมพ์เล็ก ตัวเลข 0-9 และตัวอักษรพิเศษ - เท่านั้น")}</span>
                                                                </label>
                                                            }
                                                            name="user.username"
                                                            value={juristic.user.username}
                                                            handleInputChange={handleInputChange}
                                                            disabled={props.match.params.id ? true : false}
                                                        />

                                                        <div className='mt-3 col-12 p-0'>
                                                            <p className='fw-400 fs-16 mb-0 hoverShowa'>
                                                                {props.match.params.id ? t("premission:New password") : t("premission:Password")}
                                                                <label className="tooltipsSpan mb-0"> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={18} />
                                                                    <span className='tooltiptext text_FFFFFF bg-000000 w350 text-left h40' style={{ top: -45 }}>{i18n.t("selectProject:Password have to min length 6 or more characters")}.</span>
                                                                </label>
                                                            </p>
                                                            {
                                                                // token?.site?.includes('sklasmanagement') || token?.site?.includes('victorymanagement') || token?.site?.includes('port80') ?
                                                                token?.site?.includes('sklasmanagement') || token?.site?.includes('victorymanagement') ?
                                                                    <PasswordValidation
                                                                        name="user.password"
                                                                        username={juristic.user.username}
                                                                        value={juristic.user.password || ''}
                                                                        params={props.match.params.id}
                                                                        handleInputChange={handleInputChange}
                                                                        id="password"
                                                                    />
                                                                    :
                                                                    <input type="text" className="form-control h48"
                                                                        name="user.password"
                                                                        value={juristic.user.password || ''}
                                                                        params={props.match.params.id}
                                                                        // handleInputChange={handleInputChange}
                                                                        onChange={handleInputChange}
                                                                        required={props.match.params.id ? false : true}
                                                                    />
                                                            }
                                                        </div>
                                                        <div className='mt-3 col-12 p-0'>
                                                            {
                                                                token?.site?.includes('sklasmanagement') || token?.site?.includes('victorymanagement') ?
                                                                    // token?.site?.includes('sklasmanagement') || token?.site?.includes('victorymanagement') || token?.site?.includes('port80') ?
                                                                    <div className="row mt-4">
                                                                        <div className="col">
                                                                            <p className='fs-16 fw-400 mb-0'>
                                                                                {t("premission:Confirm Password")}
                                                                            </p>
                                                                            <input type="password" className="form-control h48"
                                                                                name="confirmPassword"
                                                                                id="confirmPassword"
                                                                                value={confirmPassword}
                                                                                required={props.match.params.id ? false : true}
                                                                                onChange={handleInputChange}
                                                                            />
                                                                            {
                                                                                checkConfirmPassword ? <h6 style={{ color: '#DC3545' }}><small> {t("premission:new password is same with the old password")}</small></h6> : null
                                                                            }
                                                                            <div style={{ display: 'inline-flex' }}>
                                                                                <input type="checkbox" onClick={showHidePassword} /> <h6> <small
                                                                                    style={{ padding: '2px 5px 5px 5px', position: 'relative', bottom: '-4px' }}>{t("premission:Show Password")}</small></h6>
                                                                            </div>
                                                                        </div>
                                                                    </div> : null
                                                            }

                                                        </div>

                                                        <div className='mt-3 col-12 p-0'>
                                                            <p className='fs-16 fw-400 mb-0'>
                                                                {t("premission:Name")}
                                                            </p>
                                                            <input type="text" className="form-control h48"
                                                                name="firstName"
                                                                value={juristic.firstName}
                                                                onChange={handleInputChange} required={props.match.params.id ? false : true} />
                                                        </div>

                                                        <div className='mt-3 col-12 p-0'>
                                                            <p className='fw-400 fs-16 mb-0'>
                                                                {t("premission:Surname")}
                                                            </p>
                                                            <input type="text" className="form-control h48"
                                                                name="lastName"
                                                                value={juristic.lastName}
                                                                onChange={handleInputChange} required={props.match.params.id ? false : true} />
                                                        </div>
                                                        <div className='mt-3 col-12 p-0'>
                                                            <p className='fw-400 fs-16 mb-0'>
                                                                {t("projectManager:Phone")}
                                                            </p>
                                                            <input type="text" className="form-control h48"
                                                              name="phone"
                                                              value={juristic.phone}
                                                              onChange={handleInputChange} required={props.match.params.id ? false : true}/>
                                                        </div>
                                                        <div className='mt-3 col-12 p-0'>
                                                            <p className='fw-400 fs-16 mb-0'>
                                                             {t("projectManager:Email")}
                                                            </p>
                                                            <input type="text" className="form-control h48"
                                                                name="email"
                                                                value={juristic.email}
                                                                onChange={handleInputChange} required={props.match.params.id ? false : true}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='card p-3 fade-up arrowInSelect'>
                                                <p className='fs-18 fw-400 mt-2 mb-2 hoverShowa'>
                                                    {t("selectProject:Project maintained by users.")}
                                                    <label className="tooltipsSpan mb-0"> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={18} />
                                                        <span className='tooltiptext text_FFFFFF bg-000000 w350 text-left'>{i18n.t("selectProject:ข้อมูลโครงการที่ผู้ใช้งานดูแลทั้งหมดโดยระบุชื่อโครงการและตำแหน่งงานของผู้ใช้งาน")}</span>
                                                    </label>
                                                </p>
                                                <div className='border-top mt-2' />

                                                {
                                                    !loadingJobBySite ?
                                                        (selectJuristic?.map((ite, selectJuristicinx) => {
                                                            // console.log(siteAllSelected);
                                                            let projectList_filter = _.filter(projectList, (o) => !siteAllSelected.includes(o.value))
                                                            let findIndexRole = ite?.jobAll ? ite?.jobAll?.findIndex((e) => e.value === ite.role) : null
                                                            let findIndexSiteId = projectList_filter?.findIndex((e) => e.value === ite.site)
                                                            return (
                                                                <div key={`selectJuristicinx${selectJuristicinx}`}>
                                                                    <div className='mt-5'>
                                                                        <p className='fs-16 fw-400 mt-2 mb-0'>{t("selectProject:Project")}</p>

                                                                        {
                                                                            ite.nameSite ?
                                                                                <input type="text" className="form-control h48 selectArrow bg-eaecef"
                                                                                    value={ite.nameSite}
                                                                                    onChange={(e) => handleChangeSite(e.target, selectJuristicinx)} disabled={true}
                                                                                />
                                                                                :
                                                                                <div style={{ position: 'relative', textAlign: 'left' }}>
                                                                                    <Select
                                                                                        value={projectList_filter[findIndexSiteId]}
                                                                                        classNamePrefix='inputBoxList'
                                                                                        options={projectList_filter.filter((ex) => ex.status == false)}
                                                                                        onChange={(e) => handleChangeSite({ value: e.value, name: `[${selectJuristicinx}].site` }, selectJuristicinx)}
                                                                                        isSearchable={true}
                                                                                    />
                                                                                    <input name={`[${selectJuristicinx}].site`} type="text" value={ite.site}
                                                                                        onInvalid={e => e.target.setCustomValidity('โปรดเลือกโครงการ')}
                                                                                        onInput={e => e.target.setCustomValidity('')}
                                                                                        style={{ position: "absolute", top: 0, left: 180, width: 5, zIndex: -1 }}
                                                                                        readOnly required={true} />
                                                                                </div>
                                                                        }

                                                                    </div>

                                                                    <div className='mt-5'>
                                                                        <p className='fs-16 fw-400 mt-2 mb-0 hoverShowa'>{t("premission:Position")}
                                                                            <label className="tooltipsSpan mb-0"> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={18} />
                                                                                <span className='tooltiptext text_FFFFFF bg-000000 w350 text-left' >{i18n.t("selectProject:ตำแหน่งจะแสดงตามสิทธิ์ ของตำแหน่งงานที่ตั้งค่าไว้")}</span>
                                                                            </label>
                                                                        </p>
                                                                        <div style={{ position: 'relative', textAlign: 'left' }}>
                                                                            <Select
                                                                                value={findIndexRole !== null ? ite?.jobAll[findIndexRole] : ""}
                                                                                classNamePrefix='inputBoxList'
                                                                                options={ite?.jobAll}
                                                                                onChange={(e) => handleChangeSite({ value: e.value, name: `[${selectJuristicinx}].role` })}
                                                                                required={true}
                                                                                isSearchable={true}
                                                                            />
                                                                            <input name={`[${selectJuristicinx}].role`} type="text" value={ite.role}
                                                                                style={{ position: "absolute", top: 0, left: 180, width: 5, zIndex: -1 }}
                                                                                // onChange={() => setFocus.value = ""} ref={el => setFocus = el}
                                                                                readOnly required={true} />
                                                                        </div>
                                                                    </div>

                                                                    <div className='mt-5'>
                                                                        {
                                                                            // ปุ่มลบจะต้องไม่สามารถลบอันแรกได้ แต่อันที่ 2 เป็นต้นไปเพิ่มได้ลบได้ และถ้าบันทึกไปแล้วจะลบออกไม่ได้
                                                                            selectJuristicinx !== 0 && !ite.nameSite &&
                                                                            <button type='button' className='mr-1 w250 h48 btn btn-outline text_CA0824 bg-CA0824-outline mt-5' onClick={() => addSite("delete", selectJuristicinx, ite.site)}>
                                                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/claim/delete.png"} width={24} /> {t("selectProject:Delete this project")}
                                                                            </button>
                                                                        }
                                                                        {
                                                                            //เช็คว่า ปุ่มเพิ่มจะต้องอยู่ลำดับที่ 2 ตาม Design และ projectList มีเท่าไหร่ ให้เพิ่มได้สูงสุดเท่านั้น
                                                                            (selectJuristicinx === selectJuristic?.lastIndex) && selectJuristic?.length !== projectList?.length ?
                                                                                <button type='button' className='ml-1 w300 h48 btn btn-outline bg-1567B4 mt-5 fs-14' onClick={() => addSite("add")}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/add.png"} width={24} /> {t("selectProject:Add project")}
                                                                                </button>
                                                                                : ""

                                                                        }
                                                                    </div>

                                                                    <div className='mt-3 border-bottom'></div>
                                                                </div>
                                                            )
                                                        }))
                                                        :
                                                        <Loading />
                                                }

                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col">
                                                    <button type="submit" className="btn btn-primary bt-style"
                                                        disabled={loading}>
                                                        {loading &&
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                        {t("premission:Confirm")}
                                                    </button>
                                                </div>
                                                <div className="col">

                                                    <Link to="/setting/juristic-setting">
                                                        <button type="button"
                                                            className="btn btn-secondary bt-style">
                                                            {t("premission:Cancel")}
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                </Translation>
            </WrapperContent>
        </Wrapper>
        :
        <Loading />

    );
}


export default CreateUpdateSettingJuristicMuli;
