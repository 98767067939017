import React, {Component} from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Wrapper from '../../../components/wrapper';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import SelectContact from '../../income/selectContact';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import PayCreateForm from "./otherExpenseCreateForm";


class OtherCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact_list: [],
            accountType: "other_expense",
            transactions: [],
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({contact_list: contact_list})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContact updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                   nextLink="/accounting/expense/other_expense/form/create"
                                   backLink="/accounting/expense/other_expense/list/all"
                                   disable_select_type={false}
                    />
                    }
                    {this.props.match.params.status === 'create' &&
                    <PayCreateForm contactList={this.state.contact_list}
                                   accountType={this.state.accountType}/>
                    }

                </WrapperContent>
            </Wrapper>


        )
    }
}

export default OtherCreate;