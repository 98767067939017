/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type avgFeedbackQueryVariables = {|
  search?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  userType?: ?string,
  award?: ?number,
  site?: ?string,
|};
export type avgFeedbackQueryResponse = {|
  +avgFeedback: ?any
|};
export type avgFeedbackQuery = {|
  variables: avgFeedbackQueryVariables,
  response: avgFeedbackQueryResponse,
|};
*/


/*
query avgFeedbackQuery(
  $search: String
  $startDate: DateTime
  $endDate: DateTime
  $userType: String
  $award: Float
  $site: ID
) {
  avgFeedback(search: $search, startDate: $startDate, endDate: $endDate, userType: $userType, award: $award, site: $site)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "award"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "site"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userType"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "award",
        "variableName": "award"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "site",
        "variableName": "site"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "userType",
        "variableName": "userType"
      }
    ],
    "kind": "ScalarField",
    "name": "avgFeedback",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "avgFeedbackQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "avgFeedbackQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "de8cf2dd2b290aaa425905d5cde5f37d",
    "id": null,
    "metadata": {},
    "name": "avgFeedbackQuery",
    "operationKind": "query",
    "text": "query avgFeedbackQuery(\n  $search: String\n  $startDate: DateTime\n  $endDate: DateTime\n  $userType: String\n  $award: Float\n  $site: ID\n) {\n  avgFeedback(search: $search, startDate: $startDate, endDate: $endDate, userType: $userType, award: $award, site: $site)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f466301ff3ee3ce9bcd049330daa9252';

module.exports = node;
