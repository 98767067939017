function pad(number, length) {
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
}

class GetDocumentNumber {

    static get(document_type, number, date) {
        let prefix = '';
        if (!date) {
            date = new Date();
        }

        switch (document_type) {
            case 'dailyjournal:purchase':
                prefix = 'UV';
                break;
            case 'dailyjournal:sales':
                prefix = 'SV';
                break;
            case 'dailyjournal:payment':
                prefix = 'PV';
                break;
            case 'dailyjournal:receive':
                prefix = 'RV';
                break;
            case 'dailyjournal:general':
                prefix = 'JV';
                break;
            case 'invoice':
                prefix = 'IV';
                break;
            case 'receive':
                prefix = 'RT';
                break;
            case 'receipt_deposit':
                prefix = 'RD';
                break;
            case 'credit_note':
                prefix = 'CN';
                break;
            case 'purchase':
                prefix = 'PR';
                break;
            case 'pay':
                prefix = 'PV';
                break;
            case 'pay-draft':
                prefix = 'PP';
                break;
            case 'petty_cash_set':
                prefix = 'SPC';
                break;
            case 'petty_cash':
                prefix = 'PC';
                break;
            case 'petty_cash_payment':
                prefix = 'PCP';
                break;
            case 'advance':
                prefix = 'AD';
                break;
            case 'guarantee_money_receive':
                prefix = 'GR';
                break;
            case 'clear_advance':
                prefix = 'CAD';
                break;
            case 'other_expense':
                prefix = 'OE';
                break;
            case 'unknown_receive':
                prefix = 'UR';
                break;
            case 'prepaid_deposit':
                prefix = 'PD';
                break;
            case 'accept_credit_note':
                prefix = 'ACN';
                break;
            case 'other_receiver':
                prefix = 'OR';
                break;
            case 'clear_guarantee_money_receive':
                prefix = 'CGR';
                break;
            case 'pay_record':
                prefix = 'PS';
                break;
            default:
                return ''
        }

        // return prefix + '-' + date.getFullYear().toString() + pad(date.getMonth() + 1, 2) + pad(number, 6);
        return prefix + '-' + date.getFullYear().toString() + pad(date.getMonth() + 1, 2) + number;
    }
}

export default GetDocumentNumber;