import { graphql } from "babel-plugin-relay/macro";

const query = graphql`
    query currentBoardQuery($search: String,$first: Int, $last: Int,$site_Id: ID){
        currentTenants (search: $search,first: $first, last: $last) {
            totalCount
            edges{
            node{
                id
                firstName
                lastName
                householder(residential_Project_Site_Id:$site_Id) {
                        edges {
                            node {
                                id
                                residential {
                                    name
                                }
                            }
                        }
                    }
                boadPersonal(site_Id:$site_Id){
                   edges{
                      node{
                          id
                          image
                          signature
                          position
                          authorization
                          financial
                          status
                          note
                          residential{
                                        name
                                        floor
                                        size
                                        status
                                        address
                                        ownershipNumber
                                        homeNumber
                                        villageBuilding
                                        villageNumber
                                        lane
                                        soi
                                        postalCode
                                        province
                                        district
                                        subDistrict
                                        mailingVillageBuilding
                                        mailingVillageNumber
                                        mailingLane
                                        mailingPostalCode
                                        mailingProvince
                                        mailingDistrict
                                        mailingSubDistrict
                            }
                      }
                   }
                }
                boadWork(site_Id:$site_Id){
                   edges{
                      node{
                          id
                          nameOfCompany
                          position
                          dateOfPosition
                          dateOfEndPosition
                      }
                   }
                }
                boadEducation(site_Id:$site_Id){
                   edges{
                      node{
                          id
                          name
                          degree
                          state
                      }
                   }
                }
                boadFilepdf(site_Id:$site_Id){
                   edges{
                      node{
                          id
                          nameFile
                          fileUpload
                      }
                   }
                }
                boadTerm(site_Id:$site_Id){
                   edges{
                      node{
                          id
                          startDate
                          expDate
                      }
                   }
                }
            }
        }
    }
}`


export default function getQueryCurrentBoard() {
    return query
}