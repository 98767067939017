import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";

import getNameResidential from "../../../libs/getNameResidential";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Swal from "sweetalert2";
import { fetchQuery } from "relay-runtime";
import { Redirect } from "react-router";
import _ from "lodash";
import i18n from "i18next";
import UploadFiles from '../../../components/FucntionLib/uploadFile';
import Loading from '../../../libs/loading';
import AddressDataShow from "../../../components/FucntionLib/addressDataShow";
import CreditNoteShowMultiInvoice from "./creditNoteShowMultiInvoice"

const query = graphql`
  query creditNoteViewQuery($id: ID!) {
    creditNote(id: $id) {
      id
      docNumber
      issuedDate
      status
      payGroup
      firstName lastName
      address district city province postalCode
      creditNoteFile{
        edges{
          node{
              fileName
              fileUpload
              fileShow
          }
        }
      }
     
      contact {
        refNumber
        firstName
        lastName
        registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        typeOfContact
        name
      }
      returnChartOfAccount {
        id
        chartOfAccountCode
        name
      }
      transaction {
        id
        productAndService {
          productCode
          name
          chartOfAccount {
            chartOfAccountCode
            name
          }
        }
        invoice {
          docNumber
        }
      }
      reason
      description
      price
      remark
      version
      receive {
        id
        docNumber
        total
      }
      creditNoteTransactionRecord{
        edges{
          node{
            id
            price
            status
            description
            returnChartOfAccount {
              id
              chartOfAccountCode
              name
            }
            transaction {
              id
              productAndService {
                productCode
                name
                chartOfAccount {
                  chartOfAccountCode
                  name
                }
              }
              invoice {
                docNumber
              }
            }
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation creditNoteViewMutation($input: UpdateCreditNoteInput!) {
    updateCreditNote(input: $input) {
      ok
      warningText
    }
  }
`;

class creditNoteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirectToList: false,
      contactFile: []
    };

    this.onChangeStatusToVoid = this.onChangeStatusToVoid.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.onUpdateFile = this.onUpdateFile.bind(this)
  
  }

  onChangeStatusToVoid() {
    Swal.fire({
      title: i18n.t('creditNoteView:Are you sure to cancel this credit note?'),
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18n.t('creditNoteView:Yes'),
      cancelButtonText: i18n.t('creditNoteView:Cancel'),
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18n.t('creditNoteCreate:Note'),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18n.t('creditNoteView:Cancel'),
          confirmButtonText: i18n.t('creditNoteView:Confirm'),
          inputValidator: (value) => {
            if (!value) {
              return i18n.t('creditNoteView:Please enter a cancellation note.');
            }
          },
        });

        if (void_remark) {
          this.setState({ loading: true });
          let variables = {
            input: {
              status: "void",
              id: this.props.match.params.id,
              voidRemark: void_remark,
            },
          };
          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              if (response) {
                if (response.updateCreditNote.ok) {
                  Swal.fire(i18n.t('creditNoteView:Successfully'), "", "success").then(() =>
                    this.setState({ redirectToList: true })
                  );
                } else {
                  if (response.updateCreditNote.warningText) {
                    Swal.fire(i18n.t('creditNoteView:Failed to cancel this credit note'), response.updateCreditNote.warningText, 'warning');
                  } else {
                    Swal.fire(i18n.t('creditNoteView:Failed to cancel this credit note'), "", 'warning');
                  }
                }
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire(i18n.t('creditNoteView:Failed to cancel this credit note'), "", "warning");
            },
          });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }

  onUpdateFile(){
    let contactFileUpload = []
    let contactFile = _.cloneDeep(this.state.contactFile);
    contactFile.forEach(element => {
        let datasFile = element.node ? element.node : element
        contactFileUpload.push(JSON.stringify(datasFile))
    });

    let variables = {
      input: {
        id: this.props.match.params.id,
        creditNoteFile: this.state.contactFile.length > 0 ? contactFileUpload : null
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response) => {
        if (response) {
          if (response.updateCreditNote.ok) {
            Swal.fire(i18n.t('Allaction:Saved Successfully'), "", "success").then(() =>
              this.setState({ redirectToList: true })
            );
          } else {
            if (response.updateCreditNote.warningText) {
              Swal.fire(i18n.t('creditNoteView:Failed to cancel this credit note'), response.updateCreditNote.warningText, 'warning');
            } else {
              Swal.fire(i18n.t('creditNoteView:Failed to cancel this credit note'), "", 'warning');
            }
          }
        }
      },
      onError: (err) => {
        this.setState({ loading: false });
        Swal.fire(i18n.t('creditNoteView:Failed to cancel this credit note'), "", "warning");
      },
    });

  }

  componentDidMount() {
      this.refreshData()
  }

  refreshData() {
    fetchQuery(environment, query, { id: this.props.match.params.id }).then(response => {
        if(response.creditNote){
          this.setState({ 
            loading: false,
            contactFile: response.creditNote.creditNoteFile.edges
          });
        }
     })
   
  }

  dateFile = (index, data, dataBy) => {
    let DataFile = _.cloneDeep(this.state.contactFile)
    DataFile.splice(index, 1)
    this.setState({ [dataBy]: DataFile })
  }

  render() {
    if (this.state.redirectToList) {
      return <Redirect to="/accounting/income/credit-note/list/all" />;
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{ id: this.props.match.params.id }}
            render={({ error, props }) => {
              if (error) {
                return <div>Error :(</div>;
              } else if (props) {
                let invoice_object = [];
                let total = 0
                if(props.creditNote.version === 'V1'){
                  invoice_object.push(props.creditNote.transaction.invoice.docNumber)
                  total += props.creditNote.price
                  
                }else{
                  props.creditNote.creditNoteTransactionRecord.edges.forEach((credit_note_transaction) => {
                    total += credit_note_transaction.node.price
                    if(!invoice_object.includes(credit_note_transaction.node.transaction.invoice.docNumber) ){
                      invoice_object.push(credit_note_transaction.node.transaction.invoice.docNumber)
                  }
                  })
                    
                }
                return (
                  <div
                    className="container-fluid box"
                    id="receipt_deposit-view"
                    key={1}
                  >
                    <div className="row justify-content-between">
                      <div className="col">
                        <h3 className="mb-4">
                          <Link to="/accounting/income/credit-note/list/all">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/wrapperContent/back.png"
                              }
                              alt="back"
                              className="back"
                            />
                          </Link>
                          <Translation>
                            {(t) => <span>{t("credit_note:credit_note")+ " " + "(CN)"}</span>}
                          </Translation>
                        </h3>
                      </div>
                      <div className="col text-right">
                        {/* {props.creditNote.status === 'WAIT' && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_credit_note_delete'}) && */}
                        {props.creditNote.status !== "VOID" &&
                          _.some(
                            JSON.parse(localStorage.getItem("permission_list")),
                            { codename: "accounting_credit_note_delete" }
                          ) && (
                            <button
                              className="btn btn-danger add mr-3"
                              disabled={this.state.loading}
                              onClick={this.onChangeStatusToVoid}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                              )}
                              <Translation>
                                {(t) => <span>{t("creditNoteView:Cancel this credit note")}</span>}
                              </Translation>
                            </button>
                          )}
                        {_.some(
                          JSON.parse(localStorage.getItem("permission_list")),
                          { codename: "accounting_credit_note_print" }
                        ) && (
                            <Link
                              to={"/document/credit-note/" + props.creditNote.id}
                              target={"_blank"}
                            >
                              <button className="btn btn-primary add">
                                <Translation>
                                  {(t) => <span>{t("creditNoteView:Print")}</span>}
                                </Translation>
                              </button>
                            </Link>
                          )}
                      </div>
                    </div>

                    <Translation>
                      {t =>
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <p>
                                  <strong>{t('creditNoteCreate:Customer')}</strong>
                                </p>
                                <p>
                                  {props.creditNote.contact.refNumber}{" "}
                                  {
                                    props.creditNote.contact.typeOfContact === 'RESIDENTIAL' &&
                                      props.creditNote.firstName && props.creditNote.lastName ?
                                      getNameResidential(props.creditNote.firstName, props.creditNote.lastName) :
                                      props.creditNote.contact.typeOfContact === 'RESIDENTIAL' &&
                                        props.creditNote.contact.firstName && props.creditNote.contact.firstName !== '' ?
                                        getNameResidential(props.creditNote.contact.firstName, props.creditNote.contact.lastName) :
                                        props.creditNote.contact.name
                                  }
                                </p>
                              </div>
                              <div className="col">
                                <p>
                                  <strong>{t('creditNoteCreate:No.')}</strong>
                                </p>
                                <p>{props.creditNote.docNumber} </p>
                              </div>

                              {invoice_object?.length > 0 && (
                                    <React.Fragment>
                                        <div className="col-3">
                                            <p>
                                                <Translation>
                                                    {t => <strong>
                                                        {t('creditNoteCreate:Refer')}
                                                    </strong>
                                                    }
                                                </Translation>
                                            </p>
                                            <div className="input-group">
                                                {invoice_object.length === 1 ? (
                                                  <p>{invoice_object[0]} </p>
                                                ) : (
                                                    <CreditNoteShowMultiInvoice
                                                        invoice_object={invoice_object}
                                                        on_text={true}
                                                    />
                                                )}
                                            </div>

                                        </div>
                                    </React.Fragment>
                              )}

                              <div className="col">
                                <p>
                                  <strong>{t('creditNoteCreate:Date of issue')}</strong>
                                </p>
                                <p>
                                  {format(props.creditNote.issuedDate,"DD/MM/YYYY",{ locale: thLocale }
                                  )}
                                </p>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col">
                                <p>
                                  <strong>{t('creditNoteCreate:Address')}</strong>
                                </p>
                                <p>
                                  {
                                    (props.creditNote?.address || props.creditNote.contact.registeredAddress) &&
                                    <AddressDataShow
                                      from={true}
                                      address={
                                        props.creditNote?.address ?
                                          {
                                            address: props.creditNote.address,
                                            district: props.creditNote.district,
                                            city: props.creditNote.city,
                                            province: props.creditNote.province,
                                            postalCode: props.creditNote.postalCode,
                                          } :
                                          {
                                            address: props.creditNote.contact.registeredAddress,
                                            district: props.creditNote.contact.registeredDistrict,
                                            city: props.creditNote.contact.registeredCity,
                                            province: props.creditNote.contact.registeredProvince,
                                            postalCode: props.creditNote.contact.registeredPostalCode,
                                            registeredCountry: props.creditNote.contact.registeredCountry,
                                          }
                                      }
                                    />
                                  }
                                </p>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col">
                                <p>
                                  <strong>{t('creditNoteCreate:The reason of debt reduction')}</strong>
                                </p>
                                <p>{props.creditNote.reason}</p>
                              </div>
                            </div>
                          </div>
                        </div>}
                    </Translation>

                    <Translation>
                      {t =>
                        <div className="row">
                          <div className="col">
                            <div className="mt-3">
                              <div className="table-responsive fade-up">
                                <table className="table table-hover mt-2">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>{t('creditNoteCreate:Products/Services')}</th>
                                      <th>{t('creditNoteCreate:Account')}</th>
                                      <th>{t('creditNoteCreate:Take back')}</th>
                                      <th>{t('creditNoteCreate:Description')}</th>
                                      <th className="text-right">{t('creditNoteCreate:Debt reduction amount')}</th>
                                      {/* <th className="text-right">
                                        {t('creditNoteView:Discount used')}
                                      </th>
                                      <th className="text-right">
                                        {t('creditNoteView:Remaining discount')}
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      props.creditNote.version === 'V1' ?
                                        <React.Fragment>
                                          <tr>
                                            <td>
                                              {
                                                props.creditNote.transaction
                                                  .productAndService.productCode
                                              }{" "}
                                              {
                                                props.creditNote.transaction
                                                  .productAndService.name
                                              }
                                            </td>
                                            <td>
                                              {
                                                props.creditNote.transaction
                                                  .productAndService.chartOfAccount
                                                  .chartOfAccountCode
                                              }
                                            </td>
                                            <td>
                                              {
                                                props.creditNote.returnChartOfAccount
                                                  .chartOfAccountCode
                                              }{" "}
                                              {props.creditNote.returnChartOfAccount.name}
                                            </td>
                                            <td>{props.creditNote.description}</td>
                                            <td className="text-right">
                                              {numberWithComma(props.creditNote.price)}
                                            </td>
                                            {/* <td className="text-right">-</td>
                                            <td className="text-right">
                                              {numberWithComma(props.creditNote.price)}
                                            </td> */}
                                          </tr>

                                          {props.creditNote.receive && (
                                            <tr>
                                              <td colSpan={4}>
                                                {props.creditNote.receive.docNumber}
                                              </td>
                                              <td className="text-right">-</td>
                                              <td className="text-right">
                                                {numberWithComma( props.creditNote.receive.total)}
                                              </td>
                                              <td className="text-right">-</td>
                                            </tr>
                                          )}
                                        </React.Fragment>
                                        :
                                        props.creditNote.creditNoteTransactionRecord.edges.map((credit_note_transaction, index) => {
                                          let description = "";
                                          if(invoice_object.length > 1){
                                              description = credit_note_transaction.node.description +" #"+ credit_note_transaction.node.transaction.invoice.docNumber
                                          }else{
                                              description = credit_note_transaction.node.description
                                          }
                                          return (
                                            <React.Fragment key={credit_note_transaction.node.id}>
                                              <tr>
                                                <td>
                                                  {
                                                    credit_note_transaction.node.transaction.productAndService.productCode
                                                  }{" "}
                                                  {
                                                    credit_note_transaction.node.transaction.productAndService.name
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                   credit_note_transaction.node.transaction.productAndService.chartOfAccount.chartOfAccountCode
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    credit_note_transaction.node.returnChartOfAccount.chartOfAccountCode
                                                  }{" "}
                                                  {credit_note_transaction.node.returnChartOfAccount.name}
                                                </td>
                                                <td>{description}</td>
                                                <td className="text-right">
                                                  {numberWithComma(credit_note_transaction.node.price)}
                                                </td>
                                                {/* <td className="text-right">-</td>
                                                <td className="text-right">
                                                  {numberWithComma(credit_note_transaction.node.price)}
                                                </td> */}
                                              </tr>
                                            </React.Fragment>
                                          )
                                        })

                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>}
                    </Translation>

                    <Translation>
                      {t =>
                        <div className="row mt-4">
                          <div className="col">
                            <label htmlFor="note">
                              <strong>{t('creditNoteCreate:Note')}</strong>
                            </label>
                            <textarea
                              className="form-control"
                              rows="5"
                              name="remark"
                              value={props.creditNote.remark}
                              disabled={true}
                            />
                          </div>
                          <div className="col offset-6 bg-light">
                            <div className="row p-3 mb-2 text-dark">
                              <div className="col text-left">{t('creditNoteCreate:Debt reduction amount')}</div>
                              <div className="col text-right">
                                {numberWithComma(total || 0)} {t('creditNoteCreate:Baht')}
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </Translation>

                    <Translation>
                        {t =>
                        <div className='row'>
                            {/* แนบไฟล์ */}
                            <div className='col-8 mt-3 meterWaterFunction'>
                                <strong className='fs-16'>{t("PageDetailProjectInfo:ChooseFile")}</strong>
                                <p className='text-red fw-300'>
                                    {`${t('fileType:Support',)} ' PDF, DOC, JPG, PNG' ${t('fileType:and')} ${t('fileType:size not over 20 MB')} ${t('fileType:only')}`} ไม่เกิน 5 ไฟล์เท่านั้น
                                </p>

                                {
                                  this.state.contactFile?.length <= 4 &&  
                                  <UploadFiles
                                    fileList={this.state.contactFile}
                                    tagP={true}
                                    accept={'application/.jpeg,.png,.jpg,.pdf,.doc,.docx'}
                                    // length
                                    onUpload={(file) => this.setState({ contactFile: file })} />
                                }
                               
                                {
                                    this.state.contactFile?.length > 0 &&
                                    this.state.contactFile?.map((fi, inx) => {
                                        let file = fi.node ? fi.node : fi
                                        let typeFile = file.fileName?.split(".")

                                        let typeFileLength = typeFile && typeFile.length
                                        let typeICONFile = typeFile && typeFile[typeFileLength - 1]

                                        return (
                                            <p key={inx}>
                                                {typeof (typeFile) === 'object' &&
                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeICONFile + "Icon.png"} style={{ width: '30px' }} />}&nbsp;
                                                <Link
                                                    to="route"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        window.open(
                                                            typeof file.viewPtath === "string"
                                                                ? file.viewPtath
                                                                : file.fileShow
                                                        )
                                                    }}
                                                    target="blank">{file.fileName}</Link>

                                                &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "contactFile")}>X</label>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        }
                    </Translation>

                    <Translation>
                        {t =>
                            <div className="row mt-3 fade-up">
                                <div className="col text-right">
                                    <div className="btn-group mr-2">
                                        <Link to="">
                                            <button type="button"
                                                className="btn btn-secondary add">
                                                {t('creditNoteCreate:Cancel')} 
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="btn-group mr-2">
                                        <button className="btn btn-primary add" onClick={this.onUpdateFile}
                                           disabled={this.state.loading}
                                            >
                                            {this.state.loading &&
                                                <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                            {t('creditNoteCreate:Save')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                  </div>
                );
              }
              return <Loading />
            }}
          />
        </WrapperContent >
      </Wrapper >
    );
  }
}

export default creditNoteView;
