import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { Translation } from "react-i18next";

const query = graphql`
    query userNameInputQuery($userName: String) {
        checkJuristicUserExisting(userName: $userName)
    }
`;

class UserNameInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            existUserName: true,
            checkUsername: false,
        };

        this.checkUserName = this.checkUserName.bind(this);
        this.queryCheckUserName = this.queryCheckUserName.bind(this);
    }



    checkUserName(event) {
        let custom_event = {
            target: {
                name: event.target.name,
                value: event.target.value,
                dataset: { existUserName: true },
            }
        };

        this.props.handleInputChange(custom_event)
        this.queryCheckUserName(event.target.name, event.target.value)
    }

    queryCheckUserName(name, value) {
        fetchQuery(environment, query, { userName: value }).then(response => {
            if (response) {
                // value.includes("") 
                if (value.match(/^[a-zA-Z0-9-]*$/)) {
                    // console.log("value:: ", value.match(/^[a-zA-Z0-9-]*$/));
                    this.setState({ existUserName: response.checkJuristicUserExisting, checkUsername: false });
                } else {
                    this.setState({ existUserName: true, checkUsername: true });
                }


                let custom_event = {
                    target: {
                        name: this.props.name,
                        value: this.props.value,
                        dataset: { existUserName: response.checkJuristicUserExisting },
                    }
                };

                this.props.handleInputChange(custom_event)
            }
        });
    }
    render() {

        return (
            <React.Fragment>
                <Translation>
                    {t =>
                        <h6 className='fw-400 fs-16 mb-0 hoverShowa'>
                            {t("premission:Username")}
                            {this.props.tooltip}
                            <br />
                            {
                                !this.props.tooltip &&
                                <span style={{ color: '#DC3545' }}>
                                    {t("premission:only")}
                                </span>
                            }

                        </h6>
                    }
                </Translation>
                <input type="text" id="username"
                    className={"form-control h48 "
                        + (!this.props.disabled && (this.props.value !== '' && (this.state.existUserName ? "is-invalid" : "is-valid")))
                    }
                    // className={this.state.existUserName ? "form-control is-invalid" : "form-control is-valid"}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.checkUserName}
                    disabled={this.props.disabled}
                    pattern="[a-zA-Z0-9-]*"
                    onInvalid={e => e.target.setCustomValidity('only')}
                    onInput={e => e.target.setCustomValidity('')}

                    required />
                <Translation>
                    {t =>
                        <div className="invalid-feedback">
                            {
                                this.state.existUserName && !this.state.checkUsername ?
                                    t("premission:That username is taken") :
                                    this.state.checkUsername ?
                                        t("premission:only")
                                        :
                                        ""
                            }
                        </div>
                    }
                </Translation>
            </React.Fragment>
        );
    }

}

export default UserNameInput;
