import React from "react";
import "../../styles/commonArea.scss";
import i18n from "i18next";
const Information = ({
  handleChange,
  create,
  state: {
    name,
    name_en,
    location,
    location_en,
    size,
    max_people,
    remark,
    status,
  },
}) => {
  return (
    <div className="booking-form mb  mt-4">
      <div className="switch-languages-div d-flex justify-content-between ">
        <h2>{i18n.t("newBookingSingha_Create_step1:General information")}</h2>
        <div className="col-6 d-flex justify-content-end">
          <div className="form-group row align-items-center ">
            <label htmlFor="status" className="col-sm-3 col-form-label">
              {i18n.t("newBookingSingha_FacilityList:Status")}:
            </label>
            <div className="col-sm-9">
              <select
                className="form-control "
                id="status"
                onChange={handleChange}
                name="status"
                defaultValue={status}
              >
                <option value="open"> {i18n.t("newBookingSingha_FacilityList:Available to use")}</option>
                <option value="temporarily_open">
                {i18n.t("newBookingSingha_FacilityList:Temporarily booking in advance")}
                </option>
                {!create && <option value="renovate">  {i18n.t("newBookingSingha_FacilityList:Under maintenance")}</option>}
                {!create && <option value="closed">  {i18n.t("newBookingSingha_FacilityList:Permanently closed")}</option>}
              </select>
            </div>
          </div>
          {/* <div className="form-group row align-items-center ml-3">
            <button
              type="button"
              className="btn btn-outline-primary form-control"
            >
              ตัวอย่างมุมมองลูกบ้าน
            </button>
          </div> */}
        </div>
      </div>
      <div className="form-row mt-4 ">
        <div className="col-6">
          <label htmlFor="name" className="bold">
            {i18n.t("newBookingSingha_Create_step1:Facility name (TH)")} <span style={{ color: '#E14558' }} >*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder={i18n.t("newBookingSingha_Create_step1:Facility name (TH)")}
            id="name"
            name="name"
            value={name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-6">
          <label htmlFor="name_en" className="bold">
            {i18n.t("newBookingSingha_Create_step1:Facility name (Eng)")}
          </label>
          <input
            type="text"
            className="form-control"
            placeholder={i18n.t("newBookingSingha_Create_step1:Facility name (Eng)")}
            id="name_en"
            name="name_en"
            value={name_en}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-6">
          <label htmlFor="location" className="bold">
            {i18n.t("newBookingSingha_Create_step1:Location (TH)")}
          </label>
          <textarea
            placeholder={i18n.t("newBookingSingha_Create_step1:Location (TH)")}
            className="form-control"
            id="location"
            name="location"
            value={location}
            rows="3"
            onChange={handleChange}
          />
        </div>
        <div className="col-6">
          <label htmlFor="locationEn" className="bold">
            {i18n.t("newBookingSingha_Create_step1:Location (Eng)")}
          </label>
          <textarea
            placeholder={i18n.t("newBookingSingha_Create_step1:Location (Eng)")}
            className="form-control"
            id="location_en"
            name="location_en"
            value={location_en}
            rows="3"
            onChange={handleChange}
          />
        </div>
      </div>

      <label htmlFor="size" className="mt-3 bold">
        {i18n.t("newBookingSingha_Create_step1:Area size (sq.m.)")}
      </label>
      <input
        type="number"
        className="form-control col-6"
        placeholder={i18n.t("newBookingSingha_Create_step1:Area size (sq.m.)")}
        id="size"
        name="size"
        value={size}
        onChange={handleChange}
      />

      <label htmlFor="max_people" className="mt-3 bold">
        {i18n.t("newBookingSingha_Create_step1:Maximum participants")} <span style={{ color: '#E14558' }} >*</span>
      </label>
      <input
        type="number"
        className="form-control col-6"
        placeholder={i18n.t("newBookingSingha_Create_step1:Maximum participants")}
        id="max_people"
        name="max_people"
        value={max_people}
        onChange={handleChange}
        required
      />

      <label htmlFor="remark" className="mt-3 bold">
        {i18n.t("newBookingSingha_Create_step1:Note")}
      </label>
      <textarea
        placeholder={i18n.t("newBookingSingha_Booking:Note")}
        className="form-control col-6"
        id="remark"
        name="remark"
        rows="3"
        value={remark}
        onChange={handleChange}
      />
    </div>
  );
};

export default Information;
