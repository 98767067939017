/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type creditCardDepositCreateFormQueryVariables = {||};
export type creditCardDepositCreateFormQueryResponse = {|
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +accountNumber: string,
          +accountName: string,
          +bankName: string,
        |}
      |}>
    |}
  |},
  +selfCreditCardSetting: ?{|
    +id: string,
    +bankAccount: {|
      +id: string,
      +accountName: string,
      +accountNumber: string,
      +accountType: BankAccountAccountType,
      +bankName: string,
    |},
    +fee: number,
  |},
|};
export type creditCardDepositCreateFormQuery = {|
  variables: creditCardDepositCreateFormQueryVariables,
  response: creditCardDepositCreateFormQueryResponse,
|};
*/


/*
query creditCardDepositCreateFormQuery {
  bankAccountViewer {
    allBankAccount(status: true) {
      edges {
        node {
          id
          accountNumber
          accountName
          bankName
        }
      }
    }
    id
  }
  selfCreditCardSetting {
    id
    bankAccount {
      id
      accountName
      accountNumber
      accountType
      bankName
    }
    fee
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allBankAccount(status:true)"
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CreditCardSettingNode",
  "kind": "LinkedField",
  "name": "selfCreditCardSetting",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNode",
      "kind": "LinkedField",
      "name": "bankAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountType",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fee",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "creditCardDepositCreateFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "creditCardDepositCreateFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "38ab47b9d9102e0eff19ce22df7198c7",
    "id": null,
    "metadata": {},
    "name": "creditCardDepositCreateFormQuery",
    "operationKind": "query",
    "text": "query creditCardDepositCreateFormQuery {\n  bankAccountViewer {\n    allBankAccount(status: true) {\n      edges {\n        node {\n          id\n          accountNumber\n          accountName\n          bankName\n        }\n      }\n    }\n    id\n  }\n  selfCreditCardSetting {\n    id\n    bankAccount {\n      id\n      accountName\n      accountNumber\n      accountType\n      bankName\n    }\n    fee\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39ee248f0ecd86a331cb5551092148bf';

module.exports = node;
