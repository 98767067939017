import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import "./style/creditNoteSelectTransaction.scss"
import { Translation } from "react-i18next";
import i18n from "i18next";


class CreditNoteRemark extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: this.props.customerList[0],
            on_submit: false,
            other_input: false,
            select_transaction: '',
            reason_create_credit_note: i18n.t('creditNoteSelectTransaction:Please select an item'),
            reason_text: '',
            text_header: props.category === 'credit_note' ? 'Credit Note' : 'Accept Credit Note',

        };


        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
        this.props.updateReasonCreate('transaction', this.props.transaction_list)
        this.props.updateReasonCreate('invoice', this.props.invoice_list)
    }

    handleInputChange(e) {
        if (e.currentTarget.dataset.name) {
            if (e.currentTarget.dataset.key === 'other') {
                this.setState({
                    other_input: true,
                    reason_create_credit_note: e.currentTarget.dataset.name,
                    reason_text: ""
                })
            } else {
                this.setState({
                    other_input: false,
                    reason_create_credit_note: e.currentTarget.dataset.name,
                    reason_text: e.currentTarget.dataset.name
                }, () => this.props.updateReasonCreate('reason_create', this.state.reason_text))
            }

        } else if (e.target.name === 'reason_text') {
            this.setState({ reason_text: e.target.value }, () => this.props.updateReasonCreate('reason_create', this.state.reason_text))
        }
    }

    render() {

        if (this.props.customerList.length > 0) {
            return (

                <Translation>
                    {t =>
                        <div className="container-fluid box" id="credit-note-select-transaction">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to={this.props.back_page}>
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back" />
                                        </Link>
                                        {t('creditNoteSelectTransaction:Create')}{t('creditNoteSelectTransaction:' + this.state.text_header)}
                                    </h3>
                                </div>
                            </div>
                            <div className="content-inner" id="receive-select-invoice">

                                {this.props.transaction_list &&
                                    <div className="container-fluid card fade-up mt-5">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h4 className="inline">{t('creditNoteSelectTransaction:Please specify the reason for the debt reduction')}</h4>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-2">
                                                    <label htmlFor="reason_create_credit_note"
                                                        className="col-form-label">{t('creditNoteSelectTransaction:Type of reasons for debt reduction')}</label>
                                                </div>
                                                <div className="col-6">
                                                    <div className="dropdown mb-4">
                                                        <button className="btn btn-light dropdown-toggle dropdown-select" type="button"
                                                            id="dropdownMenuButton" data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false">
                                                            {this.state.reason_create_credit_note}
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <span className="btn dropdown-item"
                                                                data-name={'(1) ' + t('creditNoteSelectTransaction:Debt reduction') + t('creditNoteSelectTransaction:due to wrong invoice')}
                                                                data-key=""
                                                                onClick={this.handleInputChange}>
                                                                (1) <strong><u>{t('creditNoteSelectTransaction:Debt reduction')}</u></strong> {t('creditNoteSelectTransaction:due to wrong invoice')}
                                                            </span>
                                                            <span className="btn dropdown-item"
                                                                data-name={'(2) ' + t('creditNoteSelectTransaction:Others') + ', ' + t('creditNoteSelectTransaction:please specify')}
                                                                data-key="other"
                                                                onClick={this.handleInputChange}>
                                                                (2) <strong><u>{t('creditNoteSelectTransaction:Others')}</u></strong>
                                                            </span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.other_input &&
                                                <div className="row">
                                                    <div className="col-2" />
                                                    <div className="col-5">
                                                        <input type="text"
                                                            className="form-control"
                                                            value={this.state.reason_text}
                                                            onChange={this.handleInputChange}
                                                            name="reason_text" required />
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                    </div>

                                }
                                <div className="row mt-4">
                                    <div className="col-12 text-right">
                                        <div className="btn-group mr-2">
                                            <Link to={this.props.back_page}>
                                                <button type="button" className="btn btn-secondary add">
                                                    {t('creditNoteSelectTransaction:Cancel')}
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="btn-group mr-2">
                                            {(this.state.reason_create_credit_note !== i18n.t('creditNoteSelectTransaction:Please select an item') && this.props.transaction_list.length > 0 && this.state.reason_text !== '') ?
                                                <Link to={this.props.next_page}>
                                                    <button type="button" className="btn btn-primary add">
                                                        {t('creditNoteSelectTransaction:Next')}
                                                    </button>
                                                </Link>
                                                :
                                                <button type="button" className="btn btn-light disabled add">
                                                    {t('creditNoteSelectTransaction:Next')}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </Translation>
            )
        }
        return <Redirect to={this.props.back_page} />;
    }
}

export default CreditNoteRemark;