/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionLawFirmStatus = "ACTIVE" | "DRAFT" | "FINISH" | "FOLLOW" | "OVERDUE" | "PAID" | "PARTIAL_PAYMENT" | "VOID" | "WAIT" | "%future added value";
export type FMCFCollectionLawFirmDocumentType = "FM_CF_01" | "FM_CF_02" | "FM_CF_03" | "FM_CF_04" | "FM_CF_05" | "FM_CF_12" | "%future added value";
export type FMCFCollectionLawFirmStatus = "DRAFT" | "PAID" | "VOID" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type collectionLawFirmENoticeDocumentQueryVariables = {|
  idList?: ?$ReadOnlyArray<?string>,
  siteId?: ?string,
|};
export type collectionLawFirmENoticeDocumentQueryResponse = {|
  +selfProject: ?{|
    +typeOfProject: ?ProjectTypeOfProject,
    +nameTh: ?string,
    +nameEn: ?string,
  |},
  +allCollectionLawFirmFromSetting: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +feeRateTh: number,
        +feeRateOther: number,
        +lawyerSignature: ?string,
        +lawyerName: ?string,
        +regulations: ?string,
        +rulesRegulationsjuristic: ?string,
        +regulationsStatus: boolean,
        +dueDateDays: number,
        +telJuristic: ?string,
        +telOffice: ?string,
      |}
    |}>
  |},
  +allCollectionLawFirm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: ?any,
        +status: CollectionLawFirmStatus,
        +dueDate: any,
        +contact: {|
          +id: string,
          +refNumber: string,
          +name: string,
        |},
        +expenseList: ?string,
        +finalParagraphIndentation: ?string,
        +language: string,
        +lawyerName: ?string,
        +lawyerSignature: ?string,
        +debtName: ?string,
        +telJuristic: string,
        +telOffice: string,
        +totalDebt: number,
        +totalFine: number,
        +creator: ?string,
        +fmcfCollectionLawFirm: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +docNumber: string,
              +issuedDate: ?any,
              +projectName: ?string,
              +contactName: ?string,
              +contactRoom: ?string,
              +sizeRoom: ?string,
              +balconyRoom: ?string,
              +dueDateDays: number,
              +dueDate: any,
              +total: number,
              +fine: number,
              +paid: number,
              +lawyerSignature: ?string,
              +lawyerName: ?string,
              +rulesRegulationsjuristic: ?string,
              +telOffice: string,
              +telJuristic: string,
              +status: FMCFCollectionLawFirmStatus,
              +documentType: FMCFCollectionLawFirmDocumentType,
              +voidNote: ?string,
              +creator: ?string,
              +creatorEdit: ?string,
              +creatorDelete: ?string,
              +creatorApproval: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
|};
export type collectionLawFirmENoticeDocumentQuery = {|
  variables: collectionLawFirmENoticeDocumentQueryVariables,
  response: collectionLawFirmENoticeDocumentQueryResponse,
|};
*/


/*
query collectionLawFirmENoticeDocumentQuery(
  $idList: [String]
  $siteId: ID
) {
  selfProject {
    typeOfProject
    nameTh
    nameEn
    id
  }
  allCollectionLawFirmFromSetting(siteId: $siteId) {
    edges {
      node {
        id
        feeRateTh
        feeRateOther
        lawyerSignature
        lawyerName
        regulations
        rulesRegulationsjuristic
        regulationsStatus
        dueDateDays
        telJuristic
        telOffice
      }
    }
  }
  allCollectionLawFirm(idList: $idList) {
    edges {
      node {
        id
        docNumber
        issuedDate
        status
        dueDate
        contact {
          id
          refNumber
          name
        }
        expenseList
        finalParagraphIndentation
        language
        lawyerName
        lawyerSignature
        debtName
        telJuristic
        telOffice
        totalDebt
        totalFine
        creator
        fmcfCollectionLawFirm {
          edges {
            node {
              id
              docNumber
              issuedDate
              projectName
              contactName
              contactRoom
              sizeRoom
              balconyRoom
              dueDateDays
              dueDate
              total
              fine
              paid
              lawyerSignature
              lawyerName
              rulesRegulationsjuristic
              telOffice
              telJuristic
              status
              documentType
              voidNote
              creator
              creatorEdit
              creatorDelete
              creatorApproval
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "idList"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfProject",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameTh",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameEn",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lawyerSignature",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lawyerName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rulesRegulationsjuristic",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDateDays",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "telJuristic",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "telOffice",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "siteId",
      "variableName": "siteId"
    }
  ],
  "concreteType": "CollectionLawFirmFromSettingNodeConnection",
  "kind": "LinkedField",
  "name": "allCollectionLawFirmFromSetting",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionLawFirmFromSettingNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionLawFirmFromSettingNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeRateTh",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeRateOther",
              "storageKey": null
            },
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regulations",
              "storageKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regulationsStatus",
              "storageKey": null
            },
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "idList",
      "variableName": "idList"
    }
  ],
  "concreteType": "CollectionLawFirmNodeConnection",
  "kind": "LinkedField",
  "name": "allCollectionLawFirm",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionLawFirmNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionLawFirmNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ContactNode",
              "kind": "LinkedField",
              "name": "contact",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "refNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expenseList",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "finalParagraphIndentation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "language",
              "storageKey": null
            },
            (v6/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "debtName",
              "storageKey": null
            },
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDebt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalFine",
              "storageKey": null
            },
            (v16/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "FMCFCollectionLawFirmmNodeConnection",
              "kind": "LinkedField",
              "name": "fmcfCollectionLawFirm",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FMCFCollectionLawFirmmNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FMCFCollectionLawFirmmNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contactName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contactRoom",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sizeRoom",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "balconyRoom",
                          "storageKey": null
                        },
                        (v8/*: any*/),
                        (v15/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "total",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fine",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "paid",
                          "storageKey": null
                        },
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v10/*: any*/),
                        (v9/*: any*/),
                        (v14/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "documentType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "voidNote",
                          "storageKey": null
                        },
                        (v16/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "creatorEdit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "creatorDelete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "creatorApproval",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLawFirmENoticeDocumentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      (v17/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionLawFirmENoticeDocumentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      (v17/*: any*/)
    ]
  },
  "params": {
    "cacheID": "4fffdbdc204797f85b78e8747377123a",
    "id": null,
    "metadata": {},
    "name": "collectionLawFirmENoticeDocumentQuery",
    "operationKind": "query",
    "text": "query collectionLawFirmENoticeDocumentQuery(\n  $idList: [String]\n  $siteId: ID\n) {\n  selfProject {\n    typeOfProject\n    nameTh\n    nameEn\n    id\n  }\n  allCollectionLawFirmFromSetting(siteId: $siteId) {\n    edges {\n      node {\n        id\n        feeRateTh\n        feeRateOther\n        lawyerSignature\n        lawyerName\n        regulations\n        rulesRegulationsjuristic\n        regulationsStatus\n        dueDateDays\n        telJuristic\n        telOffice\n      }\n    }\n  }\n  allCollectionLawFirm(idList: $idList) {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        status\n        dueDate\n        contact {\n          id\n          refNumber\n          name\n        }\n        expenseList\n        finalParagraphIndentation\n        language\n        lawyerName\n        lawyerSignature\n        debtName\n        telJuristic\n        telOffice\n        totalDebt\n        totalFine\n        creator\n        fmcfCollectionLawFirm {\n          edges {\n            node {\n              id\n              docNumber\n              issuedDate\n              projectName\n              contactName\n              contactRoom\n              sizeRoom\n              balconyRoom\n              dueDateDays\n              dueDate\n              total\n              fine\n              paid\n              lawyerSignature\n              lawyerName\n              rulesRegulationsjuristic\n              telOffice\n              telJuristic\n              status\n              documentType\n              voidNote\n              creator\n              creatorEdit\n              creatorDelete\n              creatorApproval\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b72a29b673c58f4ce111d5d3ce86b245';

module.exports = node;
