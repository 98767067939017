import React from "react";
import numberWithCommas from "../../libs/numberWithComma";
import _ from "lodash";
import { Link } from "react-router-dom";



class BalanceSheetAccountAssets extends React.Component {

    render() {
        return (
            <React.Fragment>
                <tbody>
                <tr>
                    <td className="pl-3" width={700}>เงินสดและเงินฝากสถาบันการเงิน / Cash & deposits with financial
                        institutions
                    </td>
                    <td className="text-center">
                        <Link key={1} to={"balance-sheet-statements/111-112/"+this.props.state.period} target='_blank'>1</Link>
                    </td>
                    <td/>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_1"))}</td>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_1"))}</td>
                </tr>
                <tr>
                    <td className="pl-3">ลูกหนี้-เจ้าของร่วม / Account Receivable</td>
                    {/* <td className="text-center">2</td> */}
                    <td className="text-center">
                        <Link key={2} to={"balance-sheet-statements/1131/"+this.props.state.period} target='_blank'>2</Link>
                    </td>
                    <td/>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_2"))}</td>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_2"))}</td>
                </tr>
                <tr>
                    <td className="pl-3">สินทรัพย์หมุนเวียนอื่นๆ / Other Current Assets</td>
                    <td className="text-center">
                        <Link key={3} to={"balance-sheet-statements/1132-1140-115/"+this.props.state.period} target='_blank'>3</Link>
                    </td>
                    <td/>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_3"))}</td>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_3"))}</td>
                </tr>
                <tr className="">
                    <td className="pl-3"><strong>รวมสินทรัพย์หมุนเวียน / Total Current Assets</strong></td>
                    <td className="text-center"/>
                    <td/>
                    <td className="text-right">
                        <hr/>
                        {numberWithCommas(
                            _.get(this.props.state.beforeSet, "total_ref_1") +
                            _.get(this.props.state.beforeSet, "total_ref_2") +
                            _.get(this.props.state.beforeSet, "total_ref_3")
                        )}
                        <hr/>
                    </td>
                    <td className="text-right">
                        <hr/>
                        {numberWithCommas(
                            _.get(this.props.state.afterSet, "total_ref_1") +
                            _.get(this.props.state.afterSet, "total_ref_2") +
                            _.get(this.props.state.afterSet, "total_ref_3")
                        )}
                        <hr/>
                    </td>
                </tr>
                </tbody>

                <thead>
                <tr>
                    <td colSpan={4}>
                        <h6 className="mt-2">สินทรัพย์ไม่หมุนเวียน / Non Current Assets</h6>
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="pl-3">
                        ที่ดิน อาคาร และอุปกรณ์ / Property, plant and equipment
                    </td>
                    <td className="text-center">
                        <Link key={4} to={"balance-sheet-statements/1210-1220/"+this.props.state.period} target='_blank'>4</Link>
                    </td>
                    <td/>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_4"))}</td>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_4"))}</td>
                </tr>
                <tr>
                    <td className="pl-3">
                        สินทรัพย์ไม่หมุนเวียนอื่น / Other Non Current Assets
                    </td>
                    <td className="text-center">
                        <Link key={5} to={"balance-sheet-statements/1230-1240-1310/"+this.props.state.period} target='_blank'>5</Link>
                    </td>
                    <td/>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.beforeSet, "total_ref_5"))}</td>
                    <td className="text-right">{numberWithCommas(_.get(this.props.state.afterSet, "total_ref_5"))}</td>
                </tr>
                <tr className="">
                    <td className="pl-3"><strong>รวมสินทรัพย์ไม่หมุนเวียน / Total Non Current Assets</strong></td>
                    <td className="text-center"/>
                    <td/>
                    <td className="text-right">
                        <hr/>
                        {numberWithCommas(
                            _.get(this.props.state.beforeSet, "total_ref_4") +
                            _.get(this.props.state.beforeSet, "total_ref_5")
                        )}
                        <hr/>
                    </td>
                    <td className="text-right">
                        <hr/>
                        {numberWithCommas(
                            _.get(this.props.state.afterSet, "total_ref_4") +
                            _.get(this.props.state.afterSet, "total_ref_5")
                        )}
                        <hr/>
                    </td>
                </tr>

                <tr>
                    <td>
                        <h6 className="mt-2">รวมสินทรัพย์ / Total Assets</h6>
                    </td>
                    <td className="text-center"/>
                    <td/>
                    <td className="text-right">
                        {numberWithCommas(
                            _.get(this.props.state.beforeSet, "total_ref_1") +
                            _.get(this.props.state.beforeSet, "total_ref_2") +
                            _.get(this.props.state.beforeSet, "total_ref_3") +
                            _.get(this.props.state.beforeSet, "total_ref_4") +
                            _.get(this.props.state.beforeSet, "total_ref_5")
                        )}
                        <hr className="double"/>
                    </td>
                    <td className="text-right">
                        {numberWithCommas(
                            _.get(this.props.state.afterSet, "total_ref_1") +
                            _.get(this.props.state.afterSet, "total_ref_2") +
                            _.get(this.props.state.afterSet, "total_ref_3") +
                            _.get(this.props.state.afterSet, "total_ref_4") +
                            _.get(this.props.state.afterSet, "total_ref_5")
                        )}
                        <hr className="double"/>
                    </td>
                </tr>
                </tbody>
            </React.Fragment>
        )
    }

}

export default BalanceSheetAccountAssets;
