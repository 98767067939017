import React from 'react';
import { QueryRenderer } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import Loading from '../../../libs/loading';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

class OtherExpenseListTable extends ComponentPagination {
    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.state.status !== this.props.status) {
            this.setState({
                status: this.props.status,
                checkList: [],
                check_all: false,
            }, () => {
                this.goFirst();
            })
        }
    }

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'ACTIVE':
                text = i18next.t("otherExpenseList:Unpaid")
                break;
            case 'PAID':
                text = i18next.t("otherExpenseList:Paid")
                break;
            case 'VOID':
                text = i18next.t("otherExpenseList:Canceled Transactions")
                color = 'text-center text-danger';
                break;
            default:
                text = i18next.t("otherExpenseList:Partial Payment Received")
        }
        return <td className={color}>{text}</td>
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={this.props.query}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <Translation>{t => <React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover ">
                                    <thead className="thead-light">
                                    <tr>
                                        <th>
                                            <input type="checkbox"
                                                   onChange={() => this.getAllList(props.allOtherExpense.edges)}
                                                   checked={this.state.check_all}/>
                                        </th>
                                        <th className="text-center" width={130}>{i18next.t("otherExpenseList:Number")}</th>
                                        <th className="text-center">{i18next.t("otherExpenseList:Date")}</th>
                                        <th className="text-center">{i18next.t("otherExpenseList:Creditor Code")}</th>
                                        <th>{i18next.t("otherExpenseList:Name")}</th>
                                        {/*<th>เลขที่เอกสาร</th>*/}
                                        {/*<th>เอกสารอ้างอิง</th>*/}
                                        <th>{i18next.t("otherExpenseList:Details")}</th>
                                        {/*<th className="text-center">งวด</th>*/}
                                        <th className="text-right">{i18next.t("otherExpenseList:Amount")}</th>
                                        <th className="text-center">{i18next.t("otherExpenseList:Status")}</th>
                                        <th>{i18next.t("otherExpenseList:Created by")}</th>
                                        <th className="text-center">{i18next.t("otherExpenseList:Creation Date")}</th>
                                        {this.props.status  === 'void' &&
                                        <th className="text-center">{i18next.t("otherExpenseList:Date of Cancellation")}</th>
                                        }
                                        <th className="text-center">{i18next.t("otherExpenseList:Reference Document")}</th>
                                        {this.props.status  === 'void' &&
                                        <th className="text-center">{i18next.t("otherExpenseList:Cancellation Reason")}</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>

                                        {props.allOtherExpense.edges.map((other) => {

                                            return (
                                                other.node.otherExpenseTransaction.edges.map((otherExpenseTransaction, index) => {
                                                    return (
                                                        <tr key={other.node.id + index}>
                                                            <td>{index === 0 &&
                                                                <input type="checkbox"
                                                                    onChange={() => this.appendToCheckList(other.node.id)}
                                                                    checked={this.checkList(other.node.id)} />
                                                            }
                                                            </td>
                                                            <td className="text-center">
                                                                {other.node.status === "VOID" ?
                                                                    other.node.docNumber :
                                                                    <Link
                                                                        to={"/accounting/expense/other_expense/view/" + other.node.id + "/" + other.node.docNumber}
                                                                    >{other.node.docNumber}
                                                                    </Link>
                                                                }
                                                            </td>
                                                            <td className="text-center">{format(other.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            <td className="text-center">{other.node.contact ? other.node.contact.refNumber : '-'}</td>
                                                            <td>{other.node.contact ? other.node.contact.name : other.node.unknownContact || '-'}</td>
                                                            <td>{otherExpenseTransaction.node.description.length >= 80 ? otherExpenseTransaction.node.description.substring(0, 80) + '...' : otherExpenseTransaction.node.description}</td>
                                                            <td className="text-right">{numberWithComma(otherExpenseTransaction.node.total - otherExpenseTransaction.node.whtRate)}</td>
                                                            <React.Fragment>
                                                                {this.getColorAndText(other)}
                                                            </React.Fragment>
                                                            <td>{other.node.creator || '-'}</td>
                                                            <td className="text-center">{format(other.node.added, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            {this.props.status === 'void' &&
                                                                <td className="text-center">{format(other.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                            }
                                                            <td className="text-center">
                                                                {other.node.refOtherExpenseDoc ?
                                                                    <a download="name_of_downloaded_file"
                                                                        href={other.node.refOtherExpenseDoc}
                                                                        target={"_blank"}>
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                            alt="back" className="download-file-icon" />
                                                                    </a>
                                                                    :
                                                                    <span>-</span>
                                                                }
                                                            </td>
                                                            {this.props.status === 'void' &&
                                                                <td className="text-center">{other.node.voidRemark}</td>
                                                            }
                                                        </tr>
                                                    )
                                                }
                                                )
                                            )
                                        }
                                        )
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.allOtherExpense.totalCount} />
                            </div>

                        </React.Fragment>}</Translation>
                    }
                    return <Loading />
                }}
            />
        )
    }
}

export default OtherExpenseListTable;
