import React from 'react';
import {format} from "date-fns";
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import numberWithCommas from "../../../../libs/numberWithComma";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import './styles/outputTax.scss'

import Loading from "../../../../libs/loading"

const query = graphql`
    query outputTaxListQuery($search: String, $start_date : DateTime, $end_date : DateTime){
        allValueAddedTax(search:$search, startDate:$start_date, endDate:$end_date, typeVat: "output"){
            totalCount
            edges {
                node {
                    purchaseRecordGroup {
                        id
                        docNumber
                        issuedDate
                        total
                        contact{
                            refNumber
                            name
                            firstName
                            taxIdNumber
                            lastName
                            typeOfCompany
                            nameBranch
                        }
                        purchaseRecord{
                            edges{
                                node{
                                    total
                                    preTaxAmount
                                    whtRate
                                    productAndService{
                                        type
                                    }
                                }
                            }
                        }
                        taxInvoiceNumber
                    }
                    payRecordGroup{
                        id
                        docNumber
                        issuedDate
                        total
                        contact{
                            refNumber
                            name
                            firstName
                            taxIdNumber
                            lastName
                            typeOfCompany
                            nameBranch
                        }
                        payRecord{
                            edges{
                                node{
                                    purchaseRecord{
                                        total
                                        preTaxAmount
                                        whtRate
                                        productAndService{
                                            type
                                        }
                                        purchaseRecordGroup{
                                            taxInvoiceNumber
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                    otherExpense {
                        id
                        docNumber
                        issuedDate
                        total
                        unknownContact
                        contact{
                            refNumber
                            name
                            firstName
                            taxIdNumber
                            lastName
                            typeOfCompany
                            nameBranch
                        }
                        otherExpenseTransaction {
                            edges{
                                node{
                                    total
                                    preTaxAmount
                                }
                            }
                        }
                        taxInvoiceNumber
                    }
                    prepaidDeposit{
                        id
                        docNumber
                        taxInvoiceNumber
                        issuedDate
                        total
                        contact{
                            refNumber
                            name
                            firstName
                            taxIdNumber
                            lastName
                            typeOfCompany
                            nameBranch
                        }
                        prepaidDepositTransaction{
                            edges{
                                node{
                                    price
                                    vat
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`;


class OutputTaxList extends ComponentPagination {
    render() {
        return (
            <div className="row">
                <div className="col">
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{
                            search: this.props.search,
                            start_date: this.props.start_date,
                            end_date: this.props.end_date,
                        }}
                        render={({error, props}) => {
                            if (error) {
                                return <div className="alert alert-danger" role="alert">
                                    {error.message}</div>;
                            } else if (props) {
                                let totalPreTaxAmount = 0;
                                let totalVatAmount = 0;

                                return <React.Fragment>
                                    <div className="table-responsive fade-up">
                                        <table className="table table-hover mt-2 table-bordered">
                                            <thead className="thead-light">
                                            <tr>
                                                <th className="text-center align-middle" rowSpan={2}>ลำดับ</th>
                                                <th className="text-center align-middle" rowSpan={2}>วันที่</th>
                                                <th className="text-center align-middle" rowSpan={2}>เลขที่ใบกำกับ</th>
                                                <th className="align-middle" rowSpan={2}>เลขที่</th>
                                                <th className="align-middle" rowSpan={2}>ชื่อผู้ซื้อสินค้า/ผู้รับบริการ</th>
                                                <th className="text-center align-middle" rowSpan={2}>เลขประจำตัวผู้เสียภาษี</th>
                                                <th className="text-center" colSpan={2}>สถานประกอบการ</th>
                                                <th className="text-right align-middle" rowSpan={2}>มูลค่าสินค้าหรือบริการ</th>
                                                <th className="text-right align-middle" rowSpan={2}>จำนวนเงินภาษีมูลค่าเพิ่ม</th>
                                            </tr>
                                            <tr>
                                                <th className="text-center">สนญ.</th>
                                                <th>สาขาที่</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {props.allValueAddedTax.edges.map((vat, index) => {
                                                let outputTax = '';
                                                let preTaxAmount = 0;
                                                let vatAmount = 0;
                                                let taxInvoiceNumber ='';
                                                if (vat.node.purchaseRecordGroup) {
                                                    outputTax = vat.node.purchaseRecordGroup;
                                                    taxInvoiceNumber = vat.node.purchaseRecordGroup.taxInvoiceNumber;
                                                    vat.node.purchaseRecordGroup.purchaseRecord.edges.forEach((transaction) => {
                                                        if (transaction.node.productAndService && transaction.node.productAndService.type === 'PRODUCT') {
                                                            preTaxAmount = preTaxAmount + transaction.node.preTaxAmount;
                                                            vatAmount = vatAmount + transaction.node.total - transaction.node.preTaxAmount
                                                        }
                                                    });
                                                } else if (vat.node.payRecordGroup) {
                                                    outputTax = vat.node.payRecordGroup;
                                                    vat.node.payRecordGroup.payRecord.edges.forEach((transaction,index) => {
                                                        if (transaction.node.purchaseRecord.productAndService) {
                                                            preTaxAmount = preTaxAmount + transaction.node.purchaseRecord.preTaxAmount;
                                                            vatAmount = vatAmount + transaction.node.purchaseRecord.total - transaction.node.purchaseRecord.preTaxAmount + (transaction.node.purchaseRecord.whtRate || 0)
                                                        }
                                                        if (index === 0){
                                                             taxInvoiceNumber = transaction.node.purchaseRecord.purchaseRecordGroup.taxInvoiceNumber;
                                                        }

                                                    });
                                                } else if (vat.node.prepaidDeposit) {
                                                    outputTax = vat.node.prepaidDeposit;
                                                    taxInvoiceNumber = vat.node.prepaidDeposit.taxInvoiceNumber;
                                                    preTaxAmount = vat.node.prepaidDeposit.prepaidDepositTransaction.edges[0].node.price;
                                                    vatAmount = vat.node.prepaidDeposit.prepaidDepositTransaction.edges[0].node.price * (vat.node.prepaidDeposit.prepaidDepositTransaction.edges[0].node.vat || 0) / 100;
                                                } else {
                                                    outputTax = vat.node.otherExpense;
                                                    taxInvoiceNumber = vat.node.otherExpense.taxInvoiceNumber;
                                                    preTaxAmount = vat.node.otherExpense.otherExpenseTransaction.edges[0].node.preTaxAmount;
                                                    vatAmount = vat.node.otherExpense.otherExpenseTransaction.edges[0].node.total - vat.node.otherExpense.otherExpenseTransaction.edges[0].node.preTaxAmount;
                                                }

                                                totalPreTaxAmount += preTaxAmount;
                                                totalVatAmount += vatAmount;

                                                return (
                                                    <tr key={outputTax.id}>
                                                        <td className="text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-center">
                                                            {format(outputTax.issuedDate, 'DD/MM/YYYY')}
                                                        </td>
                                                        <td>{taxInvoiceNumber}</td>
                                                        <td>{outputTax.docNumber}</td>
                                                        {outputTax.contact ?
                                                            <td>{outputTax.contact.name} {outputTax.contact.firstName} {outputTax.contact.lastName}</td> : <td>{outputTax.unknownContact}</td>
                                                        }
                                                        <td className="text-center">{outputTax.contact ? outputTax.contact.taxIdNumber : '-'}</td>
                                                        {outputTax.contact &&
                                                            outputTax.contact.typeOfCompany === 'HEADQUARTERS' ?
                                                                <td className="text-center">✓</td> : <td/>
                                                        }
                                                        <td>{outputTax.contact ? outputTax.contact.nameBranch : ''}</td>
                                                        <td className="text-right">
                                                            {numberWithCommas(preTaxAmount)}
                                                        </td>
                                                        <td className="text-right">
                                                            {numberWithCommas(vatAmount)}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }

                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colSpan={8} className="text-right">รวม</td>
                                                <td className="text-right">{numberWithCommas(totalPreTaxAmount)}</td>
                                                <td className="text-right">{numberWithCommas(totalVatAmount)}</td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <Pagination changePage={this.changePage}
                                                    first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={props.allValueAddedTax.totalCount}/>
                                    </div>
                                </React.Fragment>
                            }
                            return <Loading/>;
                        }}
                    />
                </div>
            </div>

        )
    }
}

export default OutputTaxList;
