import React from "react";
import "../../styles/commonArea.scss";
import { Translation } from "react-i18next";

const Information = ({
  handleChange,
  state: {
    name,
    name_en,
    location,
    location_en,
    size,
    max_people,
    remark,
    status,
  },
}) => {
  return (
    <Translation>
      {t =>
        <div className="booking-form mb  mt-4">
          <div className="switch-languages-div d-flex justify-content-between ">
            <h2>{t("newBookingSingha_Create_step1:General information")}</h2>
            <div className="col-6 d-flex justify-content-end">
              <div className="form-group row align-items-center ">
                <label htmlFor="status" className="col-sm-3 col-form-label">
                  {t("newBookingSingha_Create_step1:Status")}:
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control "
                    id="status"
                    onChange={handleChange}
                    name="status"
                    defaultValue={status}
                  >
                    <option value="renovate">{t("newBookingSingha_Create_step1:Temporarily closed")}</option>
                    <option value="closed">{t("newBookingSingha_Create_step1:Closed")}</option>
                    <option value="open">{t("newBookingSingha_Create_step1:Open")}</option>
                    <option value="temporarily_open">{t("newBookingSingha_Create_step1:Allow to advance reserve")}</option>
                    <option value="no_booking">{t("newBookingSingha_Create_step1:Without reservation")}</option>
                    {/* {!create && <option value="renovate"> ปิดปรับปรุง</option>}
                {!create && <option value="closed"> ปิดถาวร/นำออก</option>} */}
                  </select>
                </div>
              </div>
              {/* <div className="form-group row align-items-center ml-3">
            <button
              type="button"
              className="btn btn-outline-primary form-control"
            >
              ตัวอย่างมุมมองลูกบ้าน
            </button>
          </div> */}
            </div>
          </div>

          <div className="form-row mt-4 ">
            {/* {i18n.t("newBookingSingha_Create_step1:Facility name (TH)")} */}
            <div className="col-6">
              <label htmlFor="name" className="bold">
                {t("newBookingSingha_Create_step1:Facility name (TH)")} <span style={{ color: '#E14558' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("newBookingSingha_Create_step1:Facility name (TH)")}
                id="name"
                name="name"
                value={name}
                onChange={handleChange}
                required
                // onInvalid={e => e.target.setCustomValidity(`${t("newBookingSingha_Create_step1:Please fill out this field.")}`)}
                // onInput={e => e.target.setCustomValidity('')}
              />
            </div>
            {/*  {i18n.t("newBookingSingha_Create_step1:Facility name (Eng)")} */}
            <div className="col-6">
              <label htmlFor="name_en" className="bold">
                {t("newBookingSingha_Create_step1:Facility name (Eng)")} <span style={{ color: '#E14558' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("newBookingSingha_Create_step1:Facility name (Eng)")}
                id="name_en"
                name="name_en"
                value={name_en}
                onChange={handleChange}
                required
                // onInvalid={e => e.target.setCustomValidity(`โปรดระบุ${t("newBookingSingha_Create_step1:Facility name (Eng)")}`)}
                // onInput={e => e.target.setCustomValidity('')}
              />
            </div>
          </div>

          <div className="form-row mt-3">
            {/* ตำแหน่งที่ตั้ง (ไทย) */}
            <div className="col-6">
              <label htmlFor="location" className="bold">
                {t("newBookingSingha_Create_step1:Location (TH)")} <span style={{ color: '#E14558' }}>*</span>
              </label>
              <textarea
                placeholder={t("newBookingSingha_Create_step1:Location (TH)")}
                className="form-control"
                id="location"
                name="location"
                value={location}
                rows="3"
                onChange={handleChange}
                required
                // onInvalid={e => e.target.setCustomValidity(`โปรดระบุ${t("newBookingSingha_Create_step1:Location (TH)")}`)}
                // onInput={e => e.target.setCustomValidity('')}
              />
            </div>

            {/*  ตำแหน่งที่ตั้ง (ENG) */}
            <div className="col-6">
              <label htmlFor="locationEn" className="bold">
                {t("newBookingSingha_Create_step1:Location (Eng)")}  <span style={{ color: '#E14558' }}>*</span>
              </label>
              <textarea
                placeholder={t("newBookingSingha_Create_step1:Location (Eng)")}
                className="form-control"
                id="location_en"
                name="location_en"
                defaultValue={location_en}
                rows="3"
                onChange={handleChange}
                required
                // onInvalid={e => e.target.setCustomValidity(`โปรดระบุ${t("newBookingSingha_Create_step1:Location (Eng)")}`)}
                // onInput={e => e.target.setCustomValidity('')}
              />
            </div>
          </div>

          {/* ขนาด (ตร.ม.) */}
          <label htmlFor="size" className="mt-3 bold">
            {t("newBookingSingha_Create_step1:Area size (sq.m.)")}
          </label>
          <input
            type="number"
            className="form-control col-6"
            placeholder={t("newBookingSingha_Create_step1:Area size (sq.m.)")}
            id="size"
            name="size"
            defaultValue={size}
            onChange={handleChange}
          />


          {/*  จำนวนผู้ใช้งานที่รับรอง (คน) */}
          <label htmlFor="max_people" className="mt-3 bold">
            {t("newBookingSingha_Create_step1:Maximum participants")} <span style={{ color: '#E14558' }} >*</span>
          </label>
          <input
            type="number"
            className="form-control col-6"
            placeholder={t("newBookingSingha_Create_step1:Maximum participants")}
            id="max_people"
            name="max_people"
            defaultValue={max_people}
            onChange={handleChange}
            required
            pattern="[0-9]+$"
            // onInvalid={e => e.target.setCustomValidity(`โปรดระบุ${t("newBookingSingha_Create_step1:Maximum participants")} และเป็นตัวเลขเท่านั้น`)}
            // onInput={e => e.target.setCustomValidity('')}
          />


          {/* หมายเหตุ */}
          <label htmlFor="remark" className="mt-3 bold">
            {t("newBookingSingha_Create_step1:Note")}
          </label>
          <textarea
            placeholder={t("newBookingSingha_Create_step1:Note")}
            className="form-control col-6"
            id="remark"
            name="remark"
            rows="3"
            defaultValue={remark}
            onChange={handleChange}
          />
        </div>
      }
    </Translation>

  );
};

export default Information;
