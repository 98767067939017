import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {QueryRenderer} from "react-relay";
import environment from "../../../env/environment";
import InvoiceCreateUpdateForm from "../../income/invoice/invoiceCreateUpdateForm";
import {graphql} from "babel-plugin-relay/macro";
import {Redirect} from 'react-router';
import { Translation } from 'react-i18next';
import Loading from '../../../libs/loading';
import './otherReceiverCreateForm.scss'

const query = graphql`
    query otherReceiverCreateFormQuery($id: ID!) {
        contact(id: $id) {
            id
            refNumber
            name
            firstName
            lastName

            registeredAddress
            registeredCountry
            registeredProvince
            registeredDistrict
            registeredCity
            registeredPostalCode
            typeOfContact

            ownerName
            mailingAddress
            mailingDistrict
            mailingCity
            mailingProvince
            mailingPostalCode
            
            renterName
            renterAddress
            renterDistrict
            renterCity
            renterProvince
            renterPostalCode

            agentName
            agentAddress
            agentDistrict
            agentCity
            agentProvince
            agentPostalCode
        }
        productViewer{
            allProduct(type_In: "product, service",isActive: true, excludePeriodAdjustment: true) {
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        noNumber
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        servicePricing {
                            edges {
                                node {
                                    id
                                    periodAdjustment
                                    pricingType
                                }
                            }
                        }
                        numberKey(status:true){
                            edges {
                                node {
                                    id
                                    noNumber
                                    status
                                    keycardPerInvoice{
                                        totalCount
                                    }
                                    keycardPerOtherReceiveTransaction{
                                        totalCount
                                    }
                                }
                            }
                        }
                        description
                        totalLeftInStock
                    }
                }
            }
        }
        viewer{
            group41: allChartOfAccount(chartOfAccountCode_Istartswith:"41"){
                edges{
                    node{
                        id
                        chartOfAccountCode
                        name

                    }
                }
            }
            group42: allChartOfAccount(chartOfAccountCode_Istartswith:"42"){
                edges{
                    node{
                        id
                        chartOfAccountCode
                        name

                    }
                }
            }
            group43: allChartOfAccount (chartOfAccountCode_Istartswith: "43"){
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            group58: allChartOfAccount (chartOfAccountCode_Istartswith: "5830"){
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
            group21: allChartOfAccount (chartOfAccountCode_Istartswith: "2126"){
                edges {
                    node {
                        id
                        chartOfAccountCode
                        name
                    }
                }
            }
        }
          selfProject{
            haveVat
        }
    }
`;

class OtherReceiverCreateForm extends Component {

  

    render() {

        if (this.props.contactList.length > 0) {
            return (
                <div className="container-fluid box">
                    <div className="row">
                        <div className="col">
                            <Translation>
                                {t=>
                                <h3 className="mb-4">
                                    <Link to="/accounting/income/other_receiver/list/all">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    {t("other_received:Other received")}
                                </h3>}
                                    
                            </Translation>
                            {
                                this.props.carReportDocList && this.props.carReportDocList.length >= 1 ? 
                                <div className="tooltipImg" style={{left:'17%',top:'-38px'}}>
                                    <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                            alt="question" className="question ml-1" style={{width:'12px',marginBottom:'10px'}}/>
                                    <span className="tooltiptext">
                                    เมื่อสร้างรายการบันทึกรายรับอื่นๆ <br/> ลูกค้าจะต้องเลือกรายการที่ต้องการบันทึก <br/>
                                    และใส่จำนวนเงินทั้งหมดที่ได้รับลงไปอีกครั้ง
                                    </span>
                                </div>   : '' 
                            }
                            
                        </div>
                    </div>
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{id: this.props.contactList[0]}}
                        cacheConfig={{use_cache: false}}
                        render={({error, props}) => {
                            if (props) {
                                return <InvoiceCreateUpdateForm contactList={this.props.contactList}
                                                                contact={props.contact} invoiceQuery={props}
                                                                query={query}
                                                                category={this.props.accountType}
                                                                product_and_service={props.productViewer.allProduct.edges}
                                                                chart_of_account={[...props.viewer.group21.edges, ...props.viewer.group41.edges, ...props.viewer.group42.edges, ...props.viewer.group43.edges, ...props.viewer.group58.edges]}
                                                                next_page={'/accounting/income/other_receiver/list/all'}
                                                                back_page={'/accounting/income/other_receiver/form/select_customer'}
                                                                credit_card_channel={true}
                                                                not_other_channel={true}
                                                                project_have_vat={props.selfProject.haveVat}
                                                                contactType={this.props.contactType}
                                                                carReportDocList={this.props.carReportDocList ? this.props.carReportDocList :'' }
                                                                carTransactionIdList={this.props.carTransactionIdList ? this.props.carTransactionIdList :'' }
                                />;
                            } else {
                                return <Loading/>
                            }
                        }}
                    />
                </div>
            )
        }
        return <Redirect to="/accounting/income/other_receiver/form/select_customer"/>;
    }
}

export default OtherReceiverCreateForm;
