/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ReceiptDepositPayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type ReceiptDepositStatus = "ACTIVE" | "USE_ALL" | "USE_PARTIAL" | "VOID" | "%future added value";
export type receiptDepositListQueryVariables = {|
  status: string,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type receiptDepositListQueryResponse = {|
  +receiptDepositViewer: ?{|
    +allReceiptDeposit: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +firstName: string,
          +lastName: string,
          +address: string,
          +district: string,
          +city: string,
          +province: string,
          +postalCode: string,
          +payGroup: ReceiptDepositPayGroup,
          +contact: {|
            +id: string,
            +name: string,
            +refNumber: string,
            +firstName: string,
            +lastName: string,
            +typeOfContact: ContactTypeOfContact,
          |},
          +issuedDate: any,
          +added: any,
          +total: ?number,
          +status: ReceiptDepositStatus,
          +voidRemark: ?string,
          +updated: any,
          +creator: ?string,
          +receiptDepositTransaction: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +description: string,
                +price: ?number,
                +balance: number,
              |}
            |}>
          |},
        |}
      |}>,
    |}
  |}
|};
export type receiptDepositListQuery = {|
  variables: receiptDepositListQueryVariables,
  response: receiptDepositListQueryResponse,
|};
*/


/*
query receiptDepositListQuery(
  $status: String!
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
) {
  receiptDepositViewer {
    allReceiptDeposit(status: $status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
      totalCount
      edges {
        node {
          id
          docNumber
          firstName
          lastName
          address
          district
          city
          province
          postalCode
          payGroup
          contact {
            id
            name
            refNumber
            firstName
            lastName
            typeOfContact
          }
          issuedDate
          added
          total
          status
          voidRemark
          updated
          creator
          receiptDepositTransaction {
            edges {
              node {
                id
                description
                price
                balance
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "end_date"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "start_date"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    }
  ],
  "concreteType": "ReceiptDepositNodeConnection",
  "kind": "LinkedField",
  "name": "allReceiptDeposit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiptDepositNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceiptDepositNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "district",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "province",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payGroup",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContactNode",
              "kind": "LinkedField",
              "name": "contact",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "refNumber",
                  "storageKey": null
                },
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "typeOfContact",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "issuedDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "added",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voidRemark",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ReceiptDepositTransactionNodeConnection",
              "kind": "LinkedField",
              "name": "receiptDepositTransaction",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReceiptDepositTransactionNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReceiptDepositTransactionNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "price",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "balance",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "receiptDepositListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReceiptDepositViewer",
        "kind": "LinkedField",
        "name": "receiptDepositViewer",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "receiptDepositListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReceiptDepositViewer",
        "kind": "LinkedField",
        "name": "receiptDepositViewer",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad3bc47b08816014edef3ad967263f64",
    "id": null,
    "metadata": {},
    "name": "receiptDepositListQuery",
    "operationKind": "query",
    "text": "query receiptDepositListQuery(\n  $status: String!\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n) {\n  receiptDepositViewer {\n    allReceiptDeposit(status: $status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {\n      totalCount\n      edges {\n        node {\n          id\n          docNumber\n          firstName\n          lastName\n          address\n          district\n          city\n          province\n          postalCode\n          payGroup\n          contact {\n            id\n            name\n            refNumber\n            firstName\n            lastName\n            typeOfContact\n          }\n          issuedDate\n          added\n          total\n          status\n          voidRemark\n          updated\n          creator\n          receiptDepositTransaction {\n            edges {\n              node {\n                id\n                description\n                price\n                balance\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1bd1b4bbde486fcc7222ef5ce7f813b9';

module.exports = node;
