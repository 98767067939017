/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type allInvoicesQueryVariables = {|
  search?: ?string
|};
export type allInvoicesQueryResponse = {|
  +invoiceViewer: ?{|
    +allInvoice: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +contact: {|
            +name: string,
            +firstName: string,
            +lastName: string,
          |},
        |}
      |}>
    |}
  |}
|};
export type allInvoicesQuery = {|
  variables: allInvoicesQueryVariables,
  response: allInvoicesQueryResponse,
|};
*/


/*
query allInvoicesQuery(
  $search: String
) {
  invoiceViewer {
    allInvoice(search: $search) {
      edges {
        node {
          id
          contact {
            name
            firstName
            lastName
            id
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "allInvoicesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InvoiceNodeConnection",
            "kind": "LinkedField",
            "name": "allInvoice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "allInvoicesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InvoiceNodeConnection",
            "kind": "LinkedField",
            "name": "allInvoice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "550ff19e11a9d90b4046315e59fcbfe4",
    "id": null,
    "metadata": {},
    "name": "allInvoicesQuery",
    "operationKind": "query",
    "text": "query allInvoicesQuery(\n  $search: String\n) {\n  invoiceViewer {\n    allInvoice(search: $search) {\n      edges {\n        node {\n          id\n          contact {\n            name\n            firstName\n            lastName\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d80038871f9f92388182cfd58512475';

module.exports = node;
