import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import BankListTable from './bankListTable';
import {graphql} from "babel-plugin-relay/macro";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";

const bankList = graphql`
    query bankListQuery($first: Int, $last: Int) {
        bankAccountViewer {
            allBankAccount(first: $first, last: $last) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountNameEn
                        accountType
                        receivePayment
                        makePayment
                        mobilePayment
                        crossBilling
                        bankName
                        branch
                        status
                        prompay
                    }
                }
                totalCount
            }
        }
    }
`;

class ListBank extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">
                        <BankListTable query={bankList}/>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ListBank;