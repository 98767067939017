import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import ReceiveListTable from "./receiveListTable"
import {subMonths} from "date-fns";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import _ from "lodash";

const query = graphql`
    query receiveListQuery($status: String!, $search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int) {
        selfProject{
            name
            logoBase64
            address
            taxIdNumber
            juristicContactNumber
        }
        receiveViewer {
            allReceive(status:$status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
                totalCount
                edges {
                    node {
                        id
                        docNumber
                        firstName lastName
                        payGroup
                        contact {
                            id
                            name
                            refNumber
                            firstName
                            lastName
                            typeOfContact
                        }
                        issuedDate
                        added
                        total
                        status
                        voidRemark
                        creator
                        updated
                        receiveTransaction{
                            edges{
                                node{
                                    id
                                    amount
                                    receiver
                                    transaction{
                                        id
                                        productAndService {
                                            id
                                        }
                                        description
                                        total
                                        paid
                                        invoice{
                                            firstName
                                            lastName
                                            payGroup
                                        }
                                    }
                                }
                                
                            }
                        }
                        creditCardTransaction {
                            totalCount
                        }
                        cashDepositReceiveTransaction {
                            totalCount
                        }
                        bankAccountTransactionReceive {
                            totalCount
                        }
                        crossBank: bankAccountTransactionReceive (transferMethod: "cross_bank"){
                            totalCount
                        }
                        chequeDeposit {
                            totalCount
                        }
                        chequeDeposit {
                            totalCount
                        }
                        
                        paymentGatewayMethod{
                            totalCount
                            edges{
                                node{
                                    id
                                    paymentType
                                }
                            }
                        }

                    }
                }
            }
            fixReceiveNoTransaction
        }
    }
`;

class ReceiveList extends Component {

    constructor() {
        super();
        this.state = {
            start_date: subMonths(new Date(), 1),
            end_date: new Date(),
            search: '',
            local_storage:null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            status: this.props.match.params.status,
            local_storage:localStorage.getItem("permission_list")
        })
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="receive-list">
                        <div className="row justify-content-between">
                            <div className="col-4">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('accounting:receive') + " "+"(RT)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>


                            {/* check site ศิริราช */}
                            {localStorage.getItem('site_id') === '199' ? 
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_receive_create'}) &&
                                
                                <>
                                    <button className="btn btn-primary add float-right mr-2 dropdown printBatchDoc" data-toggle="dropdown">      
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>                              
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:add_receive')}</span>
                                            }
                                        </Translation>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/vectorDown.svg'}
                                            alt="vectorDown"
                                            className='ml-2'
                                        />
                                    </button>  
                                    <div className="dropdown-menu printItemContainer">
                                        <Link to="/accounting/income/receive/form/select_customer"  className="dropdown-item p-3">
                                                <Translation>
                                                    {
                                                        t => <span style={{fontFamily:'kanit'}}>{t('batchRt:Create a single payment receipt list')}</span>
                                                    }
                                                </Translation>                                            
                                        </Link>
                                        <Link to="/accounting/income/receive/form/select_room_siriraj" className="dropdown-item p-3">
                                                <Translation>
                                                    {
                                                        t => <span style={{fontFamily:'kanit'}}>{t('batchRt:Create a batch payment receipt list')}</span>
                                                    }
                                                </Translation>                                            
                                        </Link>
                                    </div>
                                </>
                                }

                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_receive_create'}) &&
                                <Link to="/accounting/income/receive/form/import_batch/all">
                                    <button type="button" className="btn btn-primary add float-right mr-2">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/fileImport.svg'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('batchRt:Import file')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                                }

                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_receive_create'}) &&
                                <Link to="/accounting/income/receive/form/manage_batch/all">
                                    <button type="button" className="btn btn-primary add float-right mr-2">
                                        <Translation>
                                            {
                                                t => <span>{t('batchRt:Batch management')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                                }
                            </div>
                                : 
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_receive_create'}) &&
                                <Link to="/accounting/income/receive/form/select_customer">
                                    <button type="button" className="btn btn-primary add float-right">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:add_receive')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                                }
                            </div>
                            }

                        </div>
                        <div className="content-inner">
                            <ReceiveListTable query={query}
                                              status={this.props.match.params.status}
                                              local_storage={this.state.local_storage}
                            />
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ReceiveList;