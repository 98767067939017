/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type foreignOwnershipNotificationsQueryVariables = {||};
export type foreignOwnershipNotificationsQueryResponse = {|
  +allNoticeForeignOwnership: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +percentNoti: number,
      |}
    |}>,
  |}
|};
export type foreignOwnershipNotificationsQuery = {|
  variables: foreignOwnershipNotificationsQueryVariables,
  response: foreignOwnershipNotificationsQueryResponse,
|};
*/


/*
query foreignOwnershipNotificationsQuery {
  allNoticeForeignOwnership {
    totalCount
    edges {
      node {
        id
        percentNoti
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NoticeForeignOwnershipNodeConnection",
    "kind": "LinkedField",
    "name": "allNoticeForeignOwnership",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NoticeForeignOwnershipNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NoticeForeignOwnershipNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percentNoti",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "foreignOwnershipNotificationsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "foreignOwnershipNotificationsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b7c7d239bfb6dc80d04af39b52362da0",
    "id": null,
    "metadata": {},
    "name": "foreignOwnershipNotificationsQuery",
    "operationKind": "query",
    "text": "query foreignOwnershipNotificationsQuery {\n  allNoticeForeignOwnership {\n    totalCount\n    edges {\n      node {\n        id\n        percentNoti\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b7a04a923539221a26bff7e07a30944';

module.exports = node;
