import React, { Component } from 'react';
import './slip.scss'
import { format } from 'date-fns';
import API from '../../../../api/logHistory'
import {Translation} from "react-i18next";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from '@mui/material/Paper';

import _ from 'lodash';
import i18next from 'i18next';

// const Div = styled('div')(({ theme }) => ({
//     ...theme.typography.button,
//     backgroundColor: theme.palette.background.paper,
//     padding: theme.spacing(1),
// }));

class LogsHistoryTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logs: this.props.TransferLogData,
            token: this.props.token_id,
            countLog: this.props.countLog,
            domain: this.props.domain
        }
        this.getByIdDetail = this.getByIdDetail.bind(this)
        this.setOpen2 = this.setOpen2.bind(this)
    }

    getByIdDetail(id, token_Id, index_row, openChk) {
        let logs = _.cloneDeep(this.state.logs)
        API.getLogById(id, token_Id, this.state.domain).then((res) => {
            if (res.data) {
                let logSet = res.data.log.map((itemData) => ({
                    ...itemData, open: false
                }))

                _.set(logs, `[${index_row}].log`, logSet)
                _.set(logs, `[${index_row}].open`, openChk ? false : true)
                this.setState({ logs: logs }, () => console.log())
            }
        })
    }

    setOpen2(statusOpen2, rowIndex, indexLogs) {
        let logs = _.cloneDeep(this.state.logs)
        _.set(logs, `[${rowIndex}].log[${indexLogs}].open`, statusOpen2 ? false : true)

        logs[rowIndex].log[indexLogs].logs.map((item, index_logs) => {
            _.set(logs, `[${rowIndex}].log[${indexLogs}].logs[${index_logs}].open`, false)

        })

        this.setState({ logs: logs }, () => console.log(logs[rowIndex].log[indexLogs]))
    }

    setOpenObject(OpenChk, index, indexLogs, index_logsData) {
        let logs = _.cloneDeep(this.state.logs)
        _.set(logs, `[${index}].log[${indexLogs}].logs[${index_logsData}].open`, OpenChk ? false : true)
        this.setState({ logs: logs })
    }


    render() {
        let logs = this.state.logs
        let countLog = this.state.countLog
        return (
            <Translation>{t =>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        {countLog < 1 && <caption>{i18next.t("logsHistory:Number of Data Found")} {countLog}</caption>}
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontFamily: 'medium', backgroundColor: '#e9ecef' }}> {i18next.t("logsHistory:Subject")} </TableCell>
                                <TableCell style={{ width: '20%', fontFamily: 'medium', backgroundColor: '#e9ecef' }} align="left">{i18next.t("logsHistory:Date and Time of Edit")}</TableCell>
                                <TableCell style={{ fontFamily: 'medium', backgroundColor: '#e9ecef' }} align="center">{i18next.t("logsHistory:Edited by")}</TableCell>
                                <TableCell style={{ fontFamily: 'medium', backgroundColor: '#e9ecef' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                logs.length > 0 ? logs.map((row, index) => (
                                    // <Row key={index} row={row} token_id={token_Id} index_row={index} />
                                    <React.Fragment key={index}>
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ fontFamily: 'medium' }}>
                                            <TableCell component="th" scope="row">{row.type}</TableCell>
                                            <TableCell component="th" scope="row">{format(new Date(row.action_time).toLocaleString("en-US", { timeZone: 'UTC' }), "DD/MM/YYYY hh:mm A")}</TableCell>
                                            <TableCell component="th" scope="row" align="center">{`${row.user?.name} - (${row.user?.username})`}</TableCell>
                                            {/* Hide btn ultil it work */}
                                            {/* <TableCell>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => this.getByIdDetail(row._id, this.state.token, index, row.open)}
                                                >
                                                    {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell> */}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: 0 }} colSpan={6}>
                                                <Collapse in={row.open} timeout="auto" unmountOnExit>
                                                    {
                                                        row.log?.map((itme, indexLogs) => {
                                                            return (
                                                                <Box sx={{ margin: 1 }}>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                            onClick={() => this.setOpen2(itme.open, index, indexLogs)}
                                                                        >
                                                                            {itme.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} Log {itme.name}
                                                                        </IconButton>
                                                                        <Collapse in={itme.open} timeout="auto" unmountOnExit>
                                                                            {
                                                                                itme.logs?.map((logsData, index_logsData) => {
                                                                                    return (
                                                                                        <Box sx={{ margin: 1 }}>
                                                                                            <TableCell>
                                                                                                <IconButton
                                                                                                    aria-label="expand row"
                                                                                                    size="small"
                                                                                                    onClick={() => this.setOpenObject(logsData.open, index, indexLogs, index_logsData)}
                                                                                                >
                                                                                                    {itme.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} No. {index_logsData + 1}
                                                                                                </IconButton>
                                                                                                <Collapse in={logsData.open} timeout="auto" unmountOnExit>
                                                                                                    <TableRow>
                                                                                                        <TableRow>
                                                                                                            <TableCell>
                                                                                                                <div className='colorUse'>
                                                                                                                    <label className='fw-300 fs-18'>
                                                                                                                        <pre>
                                                                                                                            <code>
                                                                                                                                {JSON.stringify(logsData, undefined, 4)}
                                                                                                                            </code>
                                                                                                                        </pre>

                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </TableRow>
                                                                                                </Collapse>
                                                                                            </TableCell>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )) : null
                            }
                        </TableBody>
                    </Table>

                </TableContainer>
            }</Translation>

        )
    }
}

export default LogsHistoryTable;