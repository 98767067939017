import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import getDocumentNumber from "../../../libs/getDocumentNumber";
import { format, parse } from "date-fns";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, fetchQuery } from "react-relay";
import environment from "../../../env/environment";
import Swal from "sweetalert2";
import { Redirect } from 'react-router'
import AdvanceChangeStatus from "./advanceChangeStatus";
import PayPaymentChannelForm from "../../pay/payPaymentChannelForm";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import numberWithComma from "../../../libs/numberWithComma";
import PayChannel from "../pay/payChannel";
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import i18next from 'i18next';


const mutation = graphql`
    mutation advanceCreateAndViewFormMutation($input: CreateAdvanceInput!){
        createAdvance(input: $input){
            ok
            warningText
        }
    }`;

const query = graphql`
    query advanceCreateAndViewFormQuery($id : ID!){
        advance(id: $id) {
            id
            issuedDate
            dueDate
            docNumber
            description
            withdrawer
            amount
            status
            voidRemark
            cashTransaction {
                edges {
                    node {
                        id
                        price
                    }
                }
            }
            bankAccountTransaction {
                edges {
                    node {
                        id
                        price
                        date
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            chequeTransaction {
                edges {
                    node {
                        id
                        date
                        chequeNumber
                        bankAccount {
                            id
                            branch
                            accountNumber
                            accountName
                            bankName
                        }
                        price
                    }
                }
            }
            advancePettyCashChannel{
                edges{
                    node{
                        id
                        price
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }

                    }
                }
            }
        }
        allProjects {
            edges {
                node {
                    periodCreate
                }
            }
       }
    }
`;

const _ = require('lodash');


class AdvanceCreateAndViewForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            category: 'advance',
            issued_date: new Date(),
            doc_number: '',
            withdrawer: '',
            description: '',
            voidRemark: '',
            receive_channel: [],
            redirectToList: false,
            loading: false,
            status: '',
            isView: false,
            upload: {},
            type_small_change: false,
            total_receive: 0.0,
            amount: 0,
            cashTransaction: [],
            bankAccountTransaction: [],
            chequeTransaction: [],
            advancePettyCashChannel:[],
            dueDate: null,
            signreq: Math.random().toString(36),
            temp_month_before: '',
            temp_month_after: '',
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.updateReceiveChannel = this.updateReceiveChannel.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentWillMount() {

        if (this.props.match.params.id) {
            this.setState({ isView: true });

            fetchQuery(environment, query, { id: this.props.match.params.id })
                .then(response_data => {
                    if (response_data.advance) {
                        this.setState({
                            issued_date: parse(response_data.advance.issuedDate),
                            dueDate: response_data.advance.dueDate ? parse(response_data.advance.dueDate) : null,
                            doc_number: response_data.advance.docNumber,
                            description: response_data.advance.description,
                            withdrawer: response_data.advance.withdrawer,
                            status: response_data.advance.status,
                            amount: response_data.advance.amount,
                            voidRemark: response_data.advance.voidRemark,
                        })
                    }
                    if (_.isObject(response_data.advance.cashTransaction)) {
                        this.setState({ cashTransaction: response_data.advance.cashTransaction.edges });
                    }
                    if (_.isObject(response_data.advance.bankAccountTransaction)) {
                        this.setState({ bankAccountTransaction: response_data.advance.bankAccountTransaction.edges });
                    }
                    if (_.isObject(response_data.advance.chequeTransaction)) {
                        this.setState({ chequeTransaction: response_data.advance.chequeTransaction.edges });
                    }
                    if(_.isObject(response_data.advance.advancePettyCashChannel)){
                        this.setState({advancePettyCashChannel: response_data.advance.advancePettyCashChannel.edges});
                   }
                })
        } else {
            fetchQuery(environment, query, { id: '' }).then(response_data => {
                if (response_data) {
                    let data = _.cloneDeep(response_data);
                    this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
                }
            })
            this.setState({
                doc_number: getDocumentNumber.get(this.state.category, 'XXXX', this.state.issued_date)
            })
        }
    }


    calPeriodMouth(val) {
        let month_before = new Date();
        let month_after = new Date();

        month_before.setMonth(month_before.getMonth() - parseInt(val));
        month_after.setMonth(month_after.getMonth() + parseInt(val));

        this.setState({
            temp_month_before: month_before,
            temp_month_after: month_after,
        })
    }

    onSubmit(e) {
        e.preventDefault();
        let submit = false;
        if (this.state.type_small_change || this.state.total_receive <= 0) {
            Swal.fire({
                title: i18next.t("advance_withdraw_create:Are you sure you want to create a petty cash transaction")+'?',
                text:i18next.t("advance_withdraw_create:Please double-check the payment.") ,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: i18next.t("advance_withdraw_create:Create Petty Cash Transaction") ,
                cancelButtonText: i18next.t("Allaction:cancel")
            }).then((result) => {
                if (result.value) {
                    submit = true;
                }
            })

        } else {
            submit = true;
        }

        if (submit) {
            this.setState({ loading: true });
            let variables = {
                input: {
                    issuedDate: format(this.state.issued_date, 'YYYY-MM-DD'),
                    dueDate: this.state.dueDate ? format(this.state.dueDate, 'YYYY-MM-DD') : null,
                    description: this.state.description,
                    withdrawer: this.state.withdrawer,
                    payChannel: JSON.stringify(this.state.receive_channel),
                    clientMutationId: "AD" + this.state.signreq,
                }
            };

            let uploadables = this.state.upload;
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        this.setState({ loading: false });
                        if (response.createAdvance.ok) {
                            Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                                this.setState({redirectToList: true});
                            });
                        } else {
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createAdvance.warningText, 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({ loading: false });
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                    },
                },
            )
        }

    }

    updateReceiveChannel(channel_list) {
        let total_receive = 0;
        let upload = {};
        let type_small_change = false;
        channel_list.forEach(channel => {
            if (channel.slug === "small-change") {
                total_receive -= parseFloat(channel.pay);
                type_small_change = true;
            } else {
                total_receive += parseFloat(channel.pay);
            }
            if (channel.image) {
                Object.assign(upload, { [channel.slug]: channel.image });
            }
        });
        this.setState({ receive_channel: channel_list, upload: upload, type_small_change: type_small_change, total_receive: total_receive });
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/expense/advance/list/withdraw" />
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={"/accounting/expense/advance/list/withdraw"}
                                    LinkText={i18next.t('accounting:add_advance')+" "+"(AD)"}
                                    boxHtmlText={
                                        <div className="row col text-right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {
                                                this.state.isView && this.state.status === 'WITHDRAW' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_advance_delete' }) &&
                                                <AdvanceChangeStatus id={this.props.match.params.id} status="void"
                                                    callBackChangeStatus={() => this.setState({ redirectToList: true })} />
                                            }
                                            {
                                                (this.state.status === 'WITHDRAW' || this.state.status === 'CLEAR') && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_advance_create' }) &&
                                                <Link to={"/document/advance/" + this.props.match.params.id + "/" + this.props.match.params.ref_transaction} target="_blank">
                                                    <button className="btn btn-primary add mr-3"
                                                        disabled={this.state.loading}>
                                                        {i18next.t("advanceCreateAndViewForm:Print Payment Voucher")}
                                                    </button>
                                                </Link>
                                            }
                                        </div>
                                    }

                                />
                                <div className="content-inner pt-2">
                                    <form id="form-add" onSubmit={this.onSubmit}>

                                        <div className="card">
                                            <div className="card-body">

                                                <div className="form-group row">
                                                    <label htmlFor="inputEmail3"
                                                        className="col-2 col-form-label">
                                                        <strong>{i18next.t("advanceCreateAndViewForm:Recording Date")}</strong>
                                                    </label>
                                                    <div className="col-3">
                                                        {
                                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                                                                <DatePickerAdapter
                                                                    name="issued_date" className="form-control" id="date"
                                                                    selected={this.state.issued_date}
                                                                    minDate={this.state.temp_month_before}
                                                                    // maxDate={this.state.temp_month_after}
                                                                    onChange={this.handleInputChange}
                                                                    required={true}
                                                                /> :
                                                                <DatePickerAdapter id="date"
                                                                    className="form-control" name="issued_date"
                                                                    selected={this.state.issued_date}
                                                                    onChange={this.handleInputChange}
                                                                    disable={this.state.isView} />

                                                        }
                                                    </div>
                                                    <label htmlFor="inputEmail3"
                                                        className=" col-form-label">
                                                        <strong>{i18next.t("advanceCreateAndViewForm:Number")}</strong>
                                                    </label>
                                                    <div className="col-3">
                                                        <input type="text" className="form-control"
                                                            value={this.state.doc_number}
                                                            disabled />
                                                    </div>
                                                </div>

                                                <div className="row mt-4 mb-2">
                                                    <label htmlFor="date"
                                                        className="mr-3 col-2 col-form-label"><strong>{i18next.t("advanceCreateAndViewForm:Clearance Due Date")}</strong></label>
                                                    <DatePickerAdapter id="date"
                                                        className="form-control" name="dueDate"
                                                        selected={this.state.dueDate}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>

                                                <div className="row mt-4 mb-2">
                                                    <label htmlFor="date"
                                                        className="mr-3 col-2 col-form-label"><strong>{i18next.t("advanceCreateAndViewForm:Description")}</strong></label>
                                                    {
                                                        this.state.isView ?
                                                            <label htmlFor="description" className="col-form-label">{this.state.description}</label>
                                                            :
                                                            <textarea className="form-control col-6" maxLength={256}
                                                                rows="3" name="description" value={this.state.description}
                                                                onChange={this.handleInputChange} required />
                                                    }
                                                </div>
                                                <div className="row mt-4 mb-2">
                                                    <label htmlFor="date"
                                                        className="mr-3 col-2 col-form-label"><strong>{i18next.t("advanceCreateAndViewForm:Requester")}</strong></label>
                                                    {
                                                        this.state.isView ?
                                                            <label htmlFor="withdrawer" className="col-form-label">{this.state.withdrawer}</label>
                                                            :
                                                            <input type="text" className="form-control col-3" id="withdrawer"
                                                                value={this.state.withdrawer}
                                                                name="withdrawer" onChange={this.handleInputChange}
                                                                required />
                                                    }
                                                    <label htmlFor="date"
                                                        className="col-4 col-form-label text-muted">({i18next.t("advanceCreateAndViewForm:The requester's name will be included in the payment documentation, on the check page.")})</label>
                                                </div>
                                                {this.state.isView &&
                                                    <div className="row mt-4 mb-2">
                                                        <label htmlFor="date"
                                                            className="mr-3 col-2 col-form-label">{i18next.t("advanceCreateAndViewForm:Amount")}</label>
                                                        <label htmlFor="withdrawer"
                                                            className="col-form-label">{numberWithComma(this.state.amount)} {i18next.t("advanceCreateAndViewForm:THB")}</label>
                                                    </div>
                                                }
                                                {this.state.isView && this.state.status === 'VOID' &&
                                                    <div className="row mt-4 mb-2">
                                                        <label htmlFor="void_remark"
                                                            className="mr-3 col-2 col-form-label">{i18next.t("advanceCreateAndViewForm:Reason")}</label>
                                                        <label htmlFor="void_remark"
                                                            className="col-form-label">{this.state.voidRemark}</label>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        {
                                            !this.state.isView &&
                                            <React.Fragment>
                                                <div className="fade-up">
                                                    <PayPaymentChannelForm updatePayChannel={this.updateReceiveChannel}
                                                        single_payment={true}
                                                        payment_type={'pay'} not_other_channel={true}
                                                        issued_date={this.state.issued_date}
                                                        pay_form={true}
                                                        auto_petty_cash_fix={true}
                                                    />
                                                </div>
                                                <div className="row mt-3 fade-up">
                                                    <div className="col text-right">
                                                        <div className="btn-group mr-2">
                                                            <Link
                                                                to="/accounting/expense/advance/list/withdraw">
                                                                <button type="button"
                                                                    className="btn btn-secondary add">
                                                                    {i18next.t("graudvisitmange:cancel")}
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div className="btn-group mr-2">
                                                            <button type="submit" className="btn btn-primary add"
                                                                disabled={this.state.loading}>
                                                                {this.state.loading &&
                                                                    <span
                                                                        className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                                {i18next.t("advanceCreateAndViewForm:Create Petty Cash Transaction")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {this.state.isView &&
                                            <PayChannel
                                                total={this.state.amount}
                                                bankTransaction={this.state.bankAccountTransaction}
                                                cashTransaction={this.state.cashTransaction}
                                                chequeTransaction={this.state.chequeTransaction}
                                                paymentChannel={this.state.advancePettyCashChannel}
                                                account_type="pay"
                                            />
                                        }
                                    </form>
                                </div>
                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default AdvanceCreateAndViewForm;
