import React, { Component } from 'react'
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import _ from "lodash"
import numberWithComma from "../../../../libs/numberWithComma";
import axios from 'axios';
import getApiRoot from "../../../../libs/getAPIRoot";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

export default class dailyPaymentReceiptExportsNewAll extends Component {

  getKeyPaymentChannel(keyWord) {
    let valueWord = ""

    switch (keyWord) {
      case "cash":
        valueWord = "เงินสด"
        break;
      case "bank":
        valueWord = "เงินโอน"
        break;
      case "bank_account":
        valueWord = "เงินโอน"
        break;
      case "cross_bill":
        valueWord = "Cross bill"
        break;
      case "payment_gateway":
        valueWord = "เพย์เมนท์เกตเวย์"
        break;
      case "credit_card":
        valueWord = "บัตรเครดิต"
        break;
      case "cheque":
        valueWord = "เช็ค"
        break;
      case "other":
        valueWord = "อื่นๆ"
        break;
      default:
        valueWord = "ทั้งหมด"
        break;
    }
    return valueWord;
  }

  // ประเภทวิธีรับชำระเงิน
  getTypeKeyPaymentChannel(keyWord) {
    // เรียงลำดับใหม่ ตาม RQ175 ให้เหมือนกับหน้าเว็บ
    let getKeyPayment = [
      { name: "เงินสด", num: keyWord["cash"].num, total: keyWord["cash"].total },
      { name: "เงินโอน", num: keyWord["bank_account"].num, total: keyWord["bank_account"].total },
      { name: "Cross bill", num: keyWord["cross_bank"].num, total: keyWord["cross_bank"].total },
      { name: "เช็ค", num: keyWord["cheque"].num, total: keyWord["cheque"].total },
      { name: "บัตรเครดิต", num: keyWord["credit_card"].num, total: keyWord["credit_card"].total },
      { name: "เพย์เมนท์เกตเวย์", num: keyWord["payment_gateway"].num, total: keyWord["payment_gateway"].total },
      { name: "อื่นๆ", num: keyWord["other"].num, total: keyWord["other"].total },
    ];

    let dataPush = []

    getKeyPayment.map((n) => {
      return (
        dataPush.push([
          "",
          n.name,
          parseFloat(n.num) !== 0 ? parseFloat(n.num) : "-",
          parseFloat(n.total) !== 0
            ? parseFloat(n.total).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "-"
        ])
      )
    })

    return dataPush
  }

  getProductList(temp_product_and_service) {
    let value = ""
    let listProductAndService = []

    listProductAndService = _.filter(this.props.state.product_list, (o) => o.node.id === temp_product_and_service)

    if (listProductAndService.length > 0) {
      value = `${listProductAndService[0].node?.productCode} ${listProductAndService[0].node?.name}`
    } else {
      value = "ทั้งหมด"
    }

    return value
  }

  getRowColCode = (indexRow, indexCol) => {
    let value = ""
    switch (indexCol + 1) {
      case 1:
        value = "A"
        break;
      case 2:
        value = "B"
        break;
      case 3:
        value = "C"
        break;
      case 4:
        value = "D"
        break;
      case 5:
        value = "E"
        break;
      case 6:
        value = "F"
        break;
      case 7:
        value = "G"
        break;
      case 8:
        value = "H"
        break;
      case 9:
        value = "I"
        break;
      case 10:
        value = "J"
        break;
      case 11:
        value = "K"
        break;
      case 12:
        value = "L"
        break;
      case 13:
        value = "M"
        break;
      case 14:
        value = "N"
        break;
      case 15:
        value = "O"
        break;
      case 16:
        value = "P"
        break;
      case 17:
        value = "Q"
        break;
      case 18:
        value = "R"
        break;
      case 19:
        value = "S"
        break;

      default:
        break;
    }

    return `${value}${indexRow}`
  }


  downloadExcel = () => {
    let token_id = localStorage.getItem("token");
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');

    // setWidthcolumns
    let columns = [
      { width: 10 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
    ]

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let fontSizes = { size: 11.5 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let fontBold = { size: 11.5, bold: true };

    worksheet.getCell('A1').value = this.props.state.projectName
    worksheet.getCell('A1').font = fontBold
    worksheet.getCell('A2').value = "รายงานรวมรับชำระเงินประจำวัน"
    worksheet.getCell('A2').font = fontBold

    worksheet.getCell('A3').value = `ประเภทสินค้าและบริการ - ${this.getProductList(this.props.state.temp_product_and_service)}`
    worksheet.getCell('A3').font = fontBold;

    worksheet.getCell('A4').value = `วิธีชำระเงิน - ${this.getKeyPaymentChannel(this.props.state.temp_payment_channel)}`
    worksheet.getCell('A4').font = fontBold;

    worksheet.getCell('A5').value = "เรียงตามวันที่ " + format(this.props.state.start_date, "DD/MM/YYYY") + " ถึง " + format(this.props.state.end_date, "DD/MM/YYYY")
    worksheet.getCell('A5').font = fontBold;

    //Table 1

    worksheet.getCell('A7').value = "ประเภทการรับชำระเงิน"
    worksheet.getCell('A8').value = "รายการ"
    worksheet.getCell('A9').value = "จำนวนเงิน"

    //border
    worksheet.getCell('A7').border = borders;
    worksheet.getCell('A8').border = borders;
    worksheet.getCell('A9').border = borders;

    //font
    worksheet.getCell('A7').font = fontBold;
    worksheet.getCell('A8').font = fontBold;
    worksheet.getCell('A9').font = fontBold;

    worksheet.getCell('A7').alignment = textCenter;
    worksheet.getCell('A8').alignment = textCenter;
    worksheet.getCell('A9').alignment = textCenter;

    //bgColor
    // let hightlight = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFFB00' },
    //   bgColor: { argb: 'FFFB00' }
    // };
    // worksheet.getCell('A7').fill = hightlight
    // worksheet.getCell('A8').fill = hightlight
    // worksheet.getCell('A9').fill = hightlight

    //mergeCells
    worksheet.mergeCells('A7', 'B7')
    worksheet.mergeCells('A8', 'B8')
    worksheet.mergeCells('A9', 'B9')

    // End Table 1

    // let table1 = []
    // table1.push(['', 'ประเภทการรับชำระเงิน', 'รายการ', 'จำนวนเงิน'])

    if (this.props.state.queryList.paymentChannelTable) {
      let paymentChannelTables = JSON.parse(this.props.state.queryList.paymentChannelTable)
      let sumListPayment = 0
      let sumTotalPayment = 0

      let getKeyPayment = [
        { cols: "C", name: "เงินสด", num: parseInt(paymentChannelTables["cash"].num), total: parseFloat(paymentChannelTables["cash"].total) },
        { cols: "D", name: "เงินโอน", num: parseInt(paymentChannelTables["bank_account"].num), total: parseFloat(paymentChannelTables["bank_account"].total) },
        { cols: "E", name: "Cross bill", num: parseInt(paymentChannelTables["cross_bank"].num), total: parseFloat(paymentChannelTables["cross_bank"].total) },
        { cols: "F", name: "เช็ค", num: parseInt(paymentChannelTables["cheque"].num), total: parseFloat(paymentChannelTables["cheque"].total) },
        { cols: "G", name: "บัตรเครดิต", num: parseInt(paymentChannelTables["credit_card"].num), total: parseFloat(paymentChannelTables["credit_card"].total) },
        { cols: "H", name: "เพย์เมนท์เกตเวย์", num: parseInt(paymentChannelTables["payment_gateway"].num), total: parseFloat(paymentChannelTables["payment_gateway"].total) },
        { cols: "I", name: "อื่นๆ", num: parseInt(paymentChannelTables["other"].num), total: parseFloat(paymentChannelTables["other"].total) },
      ];

      for (let i = 0; i < getKeyPayment.length; i++) {
        worksheet.getCell(`${getKeyPayment[i].cols}7`).value = getKeyPayment[i].name
        worksheet.getCell(`${getKeyPayment[i].cols}7`).font = fontBold;
        worksheet.getCell(`${getKeyPayment[i].cols}8`).value = getKeyPayment[i]?.num ? getKeyPayment[i].num : "-"
        worksheet.getCell(`${getKeyPayment[i].cols}9`).value = getKeyPayment[i]?.total ? parseFloat(getKeyPayment[i].total).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "-"
        worksheet.getCell(`${getKeyPayment[i].cols}7`).border = borders;
        worksheet.getCell(`${getKeyPayment[i].cols}8`).border = borders;
        worksheet.getCell(`${getKeyPayment[i].cols}9`).border = borders;
        worksheet.getCell(`${getKeyPayment[i].cols}7`).alignment = textCenter
        worksheet.getCell(`${getKeyPayment[i].cols}8`).alignment = textCenter
        worksheet.getCell(`${getKeyPayment[i].cols}9`).alignment = textCenter

        sumListPayment += parseInt(getKeyPayment[i].num)
        sumTotalPayment += parseFloat(getKeyPayment[i].total)
      }

      worksheet.getCell(`J7`).value = "รวม"
      worksheet.getCell(`J7`).font = fontBold;
      worksheet.getCell(`J7`).border = borders;
      worksheet.getCell(`J7`).alignment = textCenter

      worksheet.getCell(`J8`).value = sumListPayment ? sumListPayment : "-"
      worksheet.getCell(`J8`).font = fontBold;
      worksheet.getCell(`J8`).border = borders;
      worksheet.getCell(`J8`).alignment = textCenter

      worksheet.getCell(`J9`).value = sumTotalPayment ? parseFloat(sumTotalPayment).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "-"
      worksheet.getCell(`J9`).font = fontBold;
      worksheet.getCell(`J9`).border = borders;
      worksheet.getCell(`J9`).alignment = textCenter

      // worksheet.getCell('J7').fill = hightlight
      // worksheet.getCell('J8').fill = hightlight
      // worksheet.getCell('J9').fill = hightlight

    }

    let head_table2_column1 = ['ลำดับ', 'วันที่ออกใบเสร็จ', 'เลขที่ใบเสร็จ', 'เลขที่ห้อง/บ้านเลขที่', 'ชื่อ', 'ประเภทรายรับ', '']
    let head_table2_column2 = ['', '', '', '', '']
    let head_table2_column3 = ['', '', '', '', '']

    let countIncomeType = 1

    _.map(this.props.state.queryList.productAndServiceHeadTable, (productAndServiceHeadTable, index) => {
      columns.push({ width: 15, height: 20 })
      head_table2_column1.push('')
      head_table2_column2.push(productAndServiceHeadTable)
      head_table2_column3.push('')
      countIncomeType += 1
    })

    columns.push(
      { width: 30 },
      { width: 15 },
      { width: 15 },
      { width: 30 },
      { width: 15 },
      { width: 20 },
    )

    head_table2_column1 = head_table2_column1.concat(['ประเภทการรับชำระ', '', '', 'จำนวนเงินรวม'])
    head_table2_column2 = head_table2_column2.concat(['อื่นๆ', '', '', '', '', ''])
    head_table2_column3 = head_table2_column3.concat(['รายการ', 'จำนวนเงิน', 'ประเภท', 'รายละเอียด', 'จำนวนเงิน', ''])

    let head_table2 = [
      head_table2_column1,
      head_table2_column2,
      head_table2_column3
    ]

    _.map(head_table2, (value, index) => {
      worksheet.getRow(12 + index).values = value
    })

    _.map(head_table2, (value, index) => {
      _.map(value, (value2, indexValue) => {
        worksheet.getRow(12 + index).getCell(1 + indexValue).font = fontBold;
        worksheet.getRow(12 + index).getCell(1 + indexValue).border = borders;
        worksheet.getRow(12 + index).getCell(1 + indexValue).alignment = textCenter

        if (index === 1 && indexValue > 4 && indexValue < 13 && value2 !== "" && value2 !== "อื่นๆ") {
          worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(13 + index, indexValue));
        }

        switch (value2) {
          case 'ลำดับ':
            worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(14 + index, indexValue));
            break;
          case 'วันที่ออกใบเสร็จ':
            worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(14 + index, indexValue));
            break;
          case 'เลขที่ใบเสร็จ':
            worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(14 + index, indexValue));
            break;
          case 'เลขที่ห้อง/บ้านเลขที่':
            worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(14 + index, indexValue));
            break;
          case 'ชื่อ':
            worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(14 + index, indexValue));
            break;
          case 'จำนวนเงินรวม':
            worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(14 + index, indexValue));
            break;
          case 'ประเภทการรับชำระ':
            worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(13 + index, indexValue + 2));
            break;
          case 'อื่นๆ':
            worksheet.mergeCells(this.getRowColCode(12 + index, indexValue), this.getRowColCode(12 + index, indexValue + 1));
            break;
          case 'ประเภทรายรับ':
            worksheet.mergeCells(this.getRowColCode(12, indexValue), this.getRowColCode(12, indexValue + countIncomeType));
            break;
          default:
            break;
        }
      })
    })

    let table2 = []

    _.map(this.props.state.queryList.dataTable, (dataTable, index) => {
      let dataTableParse = JSON.parse(dataTable);
      let itemDataTable2 = []
      let itemListData = []

      itemDataTable2.push([
        dataTableParse.no,
        format(dataTableParse.issued_date, "DD/MM/YYYY"),
        dataTableParse.doc_number,
        dataTableParse.room,
        dataTableParse.name,
      ])

      itemListData.push(["", "", "", "", ""])

      _.map(dataTableParse.receive, (receive, index_receive) => {
        if (index_receive === "other") {
          _.map(receive.detail, (otherDetail, index_otherDetail, arr_last) => {
            if (itemDataTable2[index_otherDetail]) {
              itemDataTable2[index_otherDetail].push(otherDetail)

            } else {
              if (index_otherDetail) {
                itemDataTable2.push([
                  ...itemListData[0],
                  otherDetail
                ])

              } else {
                itemDataTable2.push([
                  ...itemListData[0],
                  ""
                ])
              }
            }
          })
          _.map(receive.price, (otherPrice, index_otherPrice, arr_last) => {
            if (itemDataTable2[index_otherPrice]) {
              itemDataTable2[index_otherPrice].push(
                numberWithComma(otherPrice) !== "-"
                  // ? numberWithComma(otherPrice) 
                  ? parseFloat(otherPrice).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                  : ""
              )
            }
          })
          if (receive.detail.length === 0) {
            itemDataTable2[0].push("", "")
          }
        } else {
          itemListData[0].push("")
          itemDataTable2[0].push(numberWithComma(receive) !== "-"
            // ? numberWithComma(receive) 
            ? parseFloat(receive).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            :
            "")
        }
      })

      let count = itemDataTable2.length

      _.map(dataTableParse.payment, (payment, index_payment) => {

        {
          index_payment === "type" && _.map(payment, (type, index_type, arr_last) => {
            count -= 1
            if (itemDataTable2[index_type]) {
              itemDataTable2[index_type].push(type)

            } else {
              if (index_type) {
                itemDataTable2.push([
                  ...itemListData[0],
                  "",
                  "",
                  type || "",
                ])

              } else {
                itemDataTable2.push([
                  ...itemListData[0],
                  "",
                  "",
                  "",
                ])
              }
            }
          })
        }
        {
          index_payment === "detail" && _.map(payment, (detail, index_detail, arr_last) => {
            if (itemDataTable2[index_detail]) {
              itemDataTable2[index_detail].push(detail)

            } else {
              itemDataTable2.push([
                "",
              ])
            }
          })
        }
        {
          index_payment === "total" && _.map(payment, (total, index_total, arr_last) => {
            if (itemDataTable2[index_total]) {
              itemDataTable2[index_total].push(
                (total) >= 0
                  ? numberWithComma(total)
                  : `-${numberWithComma(parseFloat(total) * (-1))}`
              )

            } else {
              itemDataTable2.push([""])
            }
          })
        }

      })

      if (count > 0) {
        for (let i = (itemDataTable2.length - count); i <= count; i++) {
          itemDataTable2[i].push("", "", "")
        }
      }

      _.map(itemDataTable2, (dataItemDataTable2, indexDataItemDataTable2, arr_last) => {

        if ((arr_last.length - 1) === indexDataItemDataTable2) {
          itemDataTable2[indexDataItemDataTable2].push(
            // numberWithComma(dataTableParse.amount)
            parseFloat(dataTableParse.amount).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          )
        } else {
          itemDataTable2[indexDataItemDataTable2].push("")
        }
      })

      Object.entries(itemDataTable2).map((value) => {
        table2.push(value[1])
        return value
      });
    })

    if (this.props.state.queryList.summaryTable) {
      let itemDataTableTotal = ["รวมทั้งหมด", "", "", "", ""]
      let summaryTables = JSON.parse(this.props.state.queryList.summaryTable)

      _.map(summaryTables, (summary, index_summaryTables) => {

        // {
        //   index_summaryTables === "other"
        //   ? itemDataTableTotal.push("", parseFloat(summary) !== 0 ? numberWithComma(parseFloat(summary).toFixed(2)) : "-")
        //   : index_summaryTables === "amount"
        //     ? itemDataTableTotal.push("", "", "", parseFloat(summary) !== 0 ? numberWithComma(parseFloat(summary).toFixed(2)) : "-")
        //     : itemDataTableTotal.push(parseFloat(summary) !== 0 ? numberWithComma(parseFloat(summary).toFixed(2)) : "")
        // }
        {
          index_summaryTables === "other"
            ? itemDataTableTotal.push("", parseFloat(summary) !== 0 ? parseFloat(summary).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "-")
            : index_summaryTables === "amount"
              ? itemDataTableTotal.push("", "", "", parseFloat(summary) !== 0 ? parseFloat(summary).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "-")
              : itemDataTableTotal.push(parseFloat(summary) !== 0 ? parseFloat(summary).toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "")
        }
      })

      table2.push(itemDataTableTotal)
    }

    _.map(table2, (value, index) => {
      worksheet.getRow(15 + index).values = value
    })

    _.map(table2, (value, index, arrIndex) => {
      _.map(value, (value2, indexValue) => {

        worksheet.getRow(15 + index).getCell(1 + indexValue).font = fontSizes;
        worksheet.getRow(15 + index).getCell(1 + indexValue).border = borders;

        if ((arrIndex.length - 1) === index) {
          worksheet.getRow(15 + index).getCell(1 + indexValue).font = fontBold;
          worksheet.getRow(15 + index).getCell(1 + indexValue).alignment = textCenter;

          if (value2 === "รวมทั้งหมด") {

            worksheet.mergeCells(this.getRowColCode(15 + index, 0), this.getRowColCode(15 + index, 4));

          } else if (value2 !== "รวมทั้งหมด" && value2 !== "") {
            worksheet.getRow(15 + index).getCell(1 + indexValue).alignment = { vertical: 'middle', horizontal: 'right' };
          }
        } else {
          worksheet.getRow(15 + index).getCell(1 + indexValue).alignment = { vertical: 'middle', horizontal: 'left' };

          if (((countIncomeType - 1) + 4) > 4) {
            if (indexValue > 4 && indexValue < ((countIncomeType - 1) + 5)) {
              worksheet.getRow(15 + index).getCell(1 + indexValue).alignment = { vertical: 'middle', horizontal: 'right' };
            }
          }
          if (indexValue >= 0 && indexValue < 2) {
            worksheet.getRow(15 + index).getCell(1 + indexValue).alignment = textCenter;
          }
          if (((countIncomeType - 1) + 5 + 1) <= indexValue && indexValue < ((countIncomeType - 1) + 5 + 2)) {
            worksheet.getRow(15 + index).getCell(1 + indexValue).alignment = { vertical: 'middle', horizontal: 'right' };
          }
          if (((countIncomeType - 1) + 5 + 4) <= indexValue && indexValue < ((countIncomeType - 1) + 5 + 6)) {
            worksheet.getRow(15 + index).getCell(1 + indexValue).alignment = { vertical: 'middle', horizontal: 'right' };
          }
        }
      })
    })

    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = format(this.props.state.start_date, 'DD/MM/YYYY') === format(this.props.state.end_date, 'DD/MM/YYYY')
          ? "report_" + format(this.props.state.start_date, 'DD/MM/YYYY', { locale: thLocale })
          : "report_" + format(this.props.state.start_date, 'DD/MM/YYYY', { locale: thLocale }) + "-" + format(this.props.state.end_date, 'DD/MM/YYYY', { locale: thLocale });

        let formData = new FormData();
        formData.append('file', blob , "report.xlsx");
        formData.append('type', "excel");
        formData.append('type_report_ref', "daily_payment_receipt");
        axios.post(`${getApiRoot()}taskdownload/add_download`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `JWT ${token_id}`,
          }
        })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
        })
        .catch(error => {
          console.error('There was an error sending the file to the server:', error);
        })
        .finally(() => {
          // Download the file after sending it to the API
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = nameFile;
          a.click();
        });

      });
  }

  render() {
    return (
      <a className="dropdown-item" target={"_blank"} onClick={this.downloadExcel}>Excel</a>
    )
  }
}
