import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {fetchQuery } from "relay-runtime";

const query = graphql`
    query getAdvertiseQuery($first:Int, $last:Int){
        allAdvertise(first:$first,last:$last){
            edges{
                node{
                    id
                    title
                    detail
                    link
                    image
                    customerName
                    types
                    updated
                }
            }
        }
    }
`;


const getAdvertise = (index) => {
    return new Promise((resolve, reject) => {
        fetchQuery(environment, query, {first : index,last:index}).then(data => {
            resolve(data.allAdvertise.edges)
        });
    });
  
  };

export default getAdvertise;