import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import { commitMutation } from 'react-relay'
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import { Redirect } from 'react-router';
import getDocumentNumber from "../../../libs/getDocumentNumber";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import SearchSelect from "../../../libs/searchSelect";
import getNameResidential from "../../../libs/getNameResidential";
import CreditNoteShowMultiInvoice from "./creditNoteShowMultiInvoice"
import i18n from "i18next";
import i18next from 'i18next';
import _ from "lodash"
import SelectRoleAccounting from '../../../components/FucntionLib/selectRoleAccounting';
import UploadFiles from '../../../components/FucntionLib/uploadFile';
import queryCheckHouseHolderPaygroup from "../query/queryCheckHouseHolderPaygroup"

const mutation = graphql`
    mutation creditNoteCreateFormOnlyCnMutation($input: CreateCreditNoteInput!){
        createCreditNote(input: $input){
            ok
            warningText
        }
    }`;


const query = graphql`
    query creditNoteCreateFormOnlyCnQuery{
      allProjects {
            edges {
            node {
            periodCreate
        }
      }
    }
    }
`;

class CreditNoteCreateFormOnlyCn extends Component {

    constructor(props) {
        super(props);
        let allDate = [];
        if (props.transaction_object.length > 1) {
            props.transaction_object.forEach((value) => {
                allDate.push(new Date(value.transaction.invoice.issuedDate))
            })
        } else {
            allDate.push(new Date(props.transaction_object[0].transaction.invoice.issuedDate))
        }
        let sortDate = allDate.sort((date1, date2) => {
            return date1 - date2
        })
        let max_issuedDate = 0
        if (sortDate.length > 0) {
            max_issuedDate = sortDate.length - 1
        }
        this.state = {
            issuedDate: new Date(),
            remark: '',
            // price: (props.transaction_object.total - props.transaction_object.paid).toFixed(2),
            // max: (props.transaction_object.total - props.transaction_object.paid).toFixed(2),
            transaction_object: props.transaction_object,
            invoice_object: props.invoice_object,
            // chartOfAccount: props.transaction_object.productAndService?.chartOfAccount?.id,
            redirectToList: false,
            category: props.category,
            loading: false,
            tax_invoice_number: "",
            createInvoiceDate: new Date(sortDate[max_issuedDate]), //เช็ควันที่ของออก invoice เลือกเอาวันที่มากที่สุด
            ref_number: '',
            tax_invoice_date: new Date(),
            tax_invoice_period: new Date(),
            document_upload: '',
            signreq: Math.random().toString(36),
            temp_month_before: '',
            temp_month_after: '',
            period_create: '',
            pay_group: "owner",
            name: "",
            address: "",
            contactFile: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputDocument = this.handleInputDocument.bind(this);
        this.submitFrom = this.submitFrom.bind(this);
        this.creditNoteSubmit = this.creditNoteSubmit.bind(this);
        this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
        this.onSubmitError = this.onSubmitError.bind(this);
    }

    handleInputChange(e) {

        if (e.target.dataset.key === "transaction_list") {
            let transaction_list = _.cloneDeep(this.state.transaction_object);
            if (e.target.name == "chartOfAccountReceive") {
                // let get_all_chart_of_account = _.find(this.props.allChartOfAccount, { node: { id: e.target.value } })
                transaction_list[e.target.dataset.id][e.target.name] = e.target.value;
            } else {
                transaction_list[e.target.dataset.id]["transaction"][e.target.name] = e.target.value;
            }

            this.setState({ transaction_object: [...transaction_list] });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleInputDocument(e) {
        this.setState({
            document_upload: e.currentTarget.files[0],
        });
    }

    submitFrom(e) {
        e.preventDefault();
        if (this.state.price <= 0) {
            Swal.fire('ยอดชำระไม่ถูกต้อง', 'กรุณาใส่จำนวนยอดส่วนลด', 'warning')
                .then(() => this.setState({ loading: false }))
        }
        else {
            this.setState({ loading: true });
            this.creditNoteSubmit()
        }
    }

    creditNoteSubmit() {
        let contactFileUpload = []
            let contactFile = _.cloneDeep(this.state.contactFile);
            contactFile.forEach(element => {
                let datasFile = element.node ? element.node : element
                contactFileUpload.push(JSON.stringify(datasFile))
            });


        let variables = {
            input: {
                "issuedDate": format(this.state.issuedDate, 'YYYY-MM-DD'),
                "contact": this.props.customerList[0],
                "reason": this.props.reason_create,
                "transaction": JSON.stringify(this.state.transaction_object),
                "invoiceList": this.state.invoice_object,
                "remark": this.state.remark,
                clientMutationId: "CN" + this.state.signreq,
                payGroup: this.state.pay_group,
                creditNoteFile: this.state.contactFile.length > 0 ? contactFileUpload : null
            }
        };

        fetchQuery(environment, queryCheckHouseHolderPaygroup, {
            contactId: this.props.customerList[0],
            payGroup: this.state.pay_group
        }).then((data) => {

            let checkHouseHolderPaygroup = JSON.parse(data.checkHouseHolderPaygroup)

            if (checkHouseHolderPaygroup.ok) {
                // create credit Note
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.onSubmitSuccess(response);
                        },
                        onError: (err) => {
                            this.onSubmitError(err)
                        },
                    },
                );

            } else if (checkHouseHolderPaygroup.ok === false && this.state.pay_group === 'owner') {
                Swal.fire({
                    //    title: "ออกได้แต่ชื่อจะเป็นค่าช่องว่าง",
                    type: 'warning',
                    showCancelButton: true,
                    text: checkHouseHolderPaygroup.message,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: i18next.t("Allaction:Yes"),
                    cancelButtonText: i18next.t("Allaction:cancel")
                }).then((result) => {
                    if (result.value) {
                        // create credit Note
                        commitMutation(
                            environment,
                            {
                                mutation,
                                variables,
                                onCompleted: (response) => {
                                    this.onSubmitSuccess(response);
                                },
                                onError: (err) => {
                                    this.onSubmitError(err)
                                },
                            },
                        );

                    } else {
                        this.setState({ loading: false });
                    }
                })
            } else {
                Swal.fire({
                    title: "บันทึกไม่สำเร็จ",
                    type: 'warning',
                    text: checkHouseHolderPaygroup.message,
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'ปิด',
                })

                this.setState({ loading: false });
            }

        })

    }

    onSubmitSuccess(response) {
        this.setState({ loading: false });
        if (response.createCreditNote.ok) {
            Swal.fire(i18n.t('creditNoteCreate:Save successfully'), '', 'success').then(() => {
                this.setState({ redirectToList: true })
            });
        } else {
            Swal.fire(i18n.t('creditNoteCreate:Failed to save'), response.createCreditNote.warningText, 'warning');
        }
    }

    onSubmitError(err) {
        this.setState({ loading: false });
        Swal.fire('Error!', i18n.t('creditNoteCreate:Please try again'), 'warning')
    }

    componentDidMount() {
        let addrrss = this.addressData()
        let name = this.props.contact.typeOfContact === "RESIDENTIAL" ? this.props.contact.refNumber + " " + getNameResidential(this.props.contact.firstName, this.props.contact.lastName) : this.props.contact.refNumber + " " + this.props.contact.name
        this.setState({ address: addrrss, name: name });
        fetchQuery(environment, query).then((response_data) => {
            if (response_data) {
                let data = _.cloneDeep(response_data);
                this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
            }

        })
    }

    calPeriodMouth(val) {
        let month_before = new Date();
        let month_after = new Date();

        month_before.setMonth(month_before.getMonth() - parseInt(val));
        month_after.setMonth(month_after.getMonth() + parseInt(val));

        this.setState({
            temp_month_before: month_before,
            temp_month_after: month_after,
        })
    }

    calAmount(transaction) {
        let total = 0.0
        total = _.sumBy(transaction, (e) => parseFloat(e.amount_pay))
        return total;
    }

    getData = (pay_group) => {
        let name = "";
        let address = "";
        if (pay_group === "agent") {
            name = this.props.contact.refNumber + " " + this.props.contact.agentName
            address = this.props.contact.agentAddress +
                ` ${this.props.contact.agentDistrict}` +
                ` ${this.props.contact.agentCity}` +
                ` ${this.props.contact.agentProvince}` +
                ` ${this.props.contact.agentPostalCode}`
        } else if (pay_group === "rent") {
            name = this.props.contact.refNumber + " " + this.props.contact.renterName
            address = this.props.contact.renterAddress +
                ` ${this.props.contact.renterDistrict}` +
                ` ${this.props.contact.renterCity}` +
                ` ${this.props.contact.renterProvince}` +
                ` ${this.props.contact.renterPostalCode}`
        } else {
            name = this.props.contact.refNumber + " " + this.props.contact.ownerName
            address = this.props.contact.mailingAddress +
                ` ${this.props.contact.mailingDistrict}` +
                ` ${this.props.contact.mailingCity}` +
                ` ${this.props.contact.mailingProvince}` +
                ` ${this.props.contact.mailingPostalCode}`
        }

        this.setState({ pay_group: pay_group, name: name, address: address })
    }

    addressData = (address) => {
        return (
            this.props.contact.registeredProvince.includes('กรุงเทพ') || this.props.contact.registeredProvince.includes('กทม') ? (
                <>
                    {this.props.contact.registeredAddress}
                    {this.props.contact.registeredDistrict && ` แขวง${this.props.contact.registeredDistrict}`}
                    {this.props.contact.registeredCity && ` เขต${this.props.contact.registeredCity}`}
                    {this.props.contact.registeredProvince && ` ${this.props.contact.registeredProvince} `}
                    {this.props.contact.registeredPostalCode}
                </>
            ) : (
                <>
                    {this.props.contact.registeredAddress}
                    {this.props.contact.registeredDistrict && ` ตำบล${this.props.contact.registeredDistrict}`}
                    {this.props.contact.registeredCity && ` อำเภอ${this.props.contact.registeredCity}`}
                    {this.props.contact.registeredProvince && ` จังหวัด${this.props.contact.registeredProvince} `}
                    {this.props.contact.registeredPostalCode}
                </>
            )
        )
    }

    dateFile = (index, data, dataBy) => {
        let DataFile = _.cloneDeep(this.state.contactFile)
        DataFile.splice(index, 1)

        this.setState({ [dataBy]: DataFile })
    }
    

    render() {
        if (this.state.redirectToList) {
            return <Redirect to={this.props.nextLink} />
        }

        return (
            <form onSubmit={this.submitFrom} id="credit-note-create-form">
                <div className="container-fluid box">
                    <div className="row">
                        <div className="col">
                            <h3 className="mb-4">
                                <Link to={this.props.backLink}>
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                        alt="back" className="back" />
                                </Link>
                                <Translation>
                                    {
                                        t => <span>{t(this.state.category + ':create_' + this.state.category)}</span>
                                    }
                                </Translation>
                            </h3>
                        </div>
                    </div>

                    <Translation>
                        {t =>
                            <div className="row">
                                <SelectRoleAccounting
                                    handleChangeSelect={(value) => this.getData(value)}
                                    value={this.state.pay_group}
                                />
                                <div className="col">
                                    <p><strong>{t('creditNoteCreate:Customer')}</strong></p>
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={this.state.name}
                                            disabled />
                                    </div>
                                </div>
                                <div className="col">
                                    <p><strong>{t('creditNoteCreate:No.')}</strong></p>
                                    <div className="input-group">

                                        <input type="text" className="form-control"
                                            value={getDocumentNumber.get(this.state.category, 'XXXX', this.state.issued_date)}
                                            disabled />
                                    </div>
                                </div>


                                {this.state.invoice_object?.length > 0 && (
                                    <React.Fragment>
                                        <div className="col-3">
                                            <p>
                                                <Translation>
                                                    {t => <strong>
                                                        {t('creditNoteCreate:Refer')}
                                                    </strong>
                                                    }
                                                </Translation>
                                            </p>
                                            <div className="input-group">
                                                {this.state.invoice_object.length === 1 ? (
                                                    <input type="text" className="form-control"
                                                        value={this.state.invoice_object[0].docNumber}
                                                        disabled />
                                                ) : (
                                                    <CreditNoteShowMultiInvoice
                                                        invoice_object={this.state.invoice_object}
                                                    />
                                                )}
                                            </div>

                                        </div>
                                    </React.Fragment>
                                )}

                                {/* <div className="col">
                                    <p><strong>{t('creditNoteCreate:Refer')}</strong></p>
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={this.state.invoice_object[0]}
                                            disabled />
                                    </div>
                                </div> */}

                                <div className="col">
                                    <p><strong>{t('creditNoteCreate:Date of issue')}</strong></p>
                                    <div className="input-group">
                                        {
                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ?
                                                <DatePickerAdapter
                                                    name="issuedDate" className="form-control"
                                                    selected={this.state.issuedDate}
                                                    minDate={this.state.temp_month_before}
                                                    onChange={this.handleInputChange}
                                                    required={true}
                                                />
                                                :
                                                <DatePickerAdapter
                                                    name="issuedDate" className="form-control"
                                                    selected={this.state.issuedDate}
                                                    minDate={this.state.createInvoiceDate}
                                                    onChange={this.handleInputChange}
                                                    required={true}
                                                />
                                        }


                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>

                    <hr />
                    <Translation>
                        {t =>
                            <div className="row">
                                <div className="col">
                                    <p><strong>{t('creditNoteCreate:Address')}</strong></p>
                                    <p>{this.state.address}</p>
                                </div>
                            </div>
                        }
                    </Translation>

                    <Translation>
                        {t =>
                            <div className="fade-up">
                                <table className="table table-hover mt-2">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>{t('creditNoteCreate:Products/Services')}</th>
                                            <th>{t('creditNoteCreate:Account')}</th>
                                            {this.state.category === 'credit_note' &&
                                                <th>{t('creditNoteCreate:Take back')}</th>
                                            }
                                            <th>{t('creditNoteCreate:Description')}</th>
                                            <th className="text-right">{t('creditNoteCreate:Debt reduction amount')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.transaction_object.map((transaction_list, index) => {
                                                return (
                                                    <tr key={"transaction-" + transaction_list.transaction.id}>
                                                        <td width="60">
                                                            <input type="text" className="form-control"
                                                                value={transaction_list.transaction.productAndService?.productCode || "-"}
                                                                disabled />
                                                        </td>
                                                        <td width="90">
                                                            <input type="text" className="form-control"
                                                                value={transaction_list.transaction.productAndService?.chartOfAccount?.chartOfAccountCode
                                                                    || transaction_list.transaction.chartOfAccount?.chartOfAccountCode
                                                                    || "-"}
                                                                disabled />
                                                        </td>
                                                        {this.state.category === 'credit_note' &&
                                                            <td width="120">
                                                                <SearchSelect onChange={this.handleInputChange}
                                                                    value={transaction_list.chartOfAccountReceive}
                                                                    data-id={index}
                                                                    data-key="transaction_list"
                                                                    name="chartOfAccountReceive" placeholder="รายการ"
                                                                    queryObject={this.props.allChartOfAccount}
                                                                    keyOfValue="id" require={true}
                                                                    keyOfLabel="chartOfAccountCode:name" />
                                                            </td>
                                                        }
                                                        <td width="200">
                                                            <input type="text" className="form-control"
                                                                data-key="transaction_list" name="description"
                                                                data-id={index}
                                                                value={transaction_list.transaction.description.replace(/ *\[[^)]*\] */g, " ") || ""}
                                                                onChange={this.handleInputChange} required />
                                                        </td>
                                                        <td width="50">
                                                            <input type="number" min={0} className="form-control text-right"
                                                                step="0.01" name="price" value={transaction_list.amount_pay}
                                                                // onChange={this.handleInputChange}
                                                                disabled />
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        }
                    </Translation>

                    <Translation>
                        {t =>
                            <div className="row mt-4">
                                <div className="col">
                                    <label htmlFor="note"><strong>{t('creditNoteCreate:Note')}</strong></label>
                                    <textarea className="form-control" rows="5" name="remark" value={this.state.remark} required
                                        onChange={this.handleInputChange} />
                                </div>
                                <div className="col offset-6 bg-light">
                                    <div className="row p-3 mb-2 text-dark">
                                        <div className="col text-left">{t('creditNoteCreate:Debt reduction amount')}</div>
                                        <div
                                            className="col text-right"> {numberWithComma(this.calAmount(this.state.transaction_object))} {t('creditNoteCreate:Baht')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>

                    <Translation>
                        {t =>
                        <div className='row'>
                            {/* แนบไฟล์ */}
                            <div className='col-8 mt-3 meterWaterFunction'>
                                <strong className='fs-16'>{t("PageDetailProjectInfo:ChooseFile")}</strong>
                                <p className='text-red fw-300'>
                                    {`${t('fileType:Support',)} ' PDF, DOC, JPG, PNG' ${t('fileType:and')} ${t('fileType:size not over 20 MB')} ${t('fileType:only')}`} ไม่เกิน 5 ไฟล์เท่านั้น
                                </p>
                                {
                                     this.state.contactFile?.length <= 4 &&  
                                        <UploadFiles
                                        fileList={this.state.contactFile}
                                        tagP={true}
                                        accept={'application/.jpeg,.png,.jpg,.pdf,.doc,.docx'}
                                        // length
                                        onUpload={(file) => this.setState({ contactFile: file })} />
                                }
                                {
                                    this.state.contactFile?.length > 0 &&
                                    this.state.contactFile?.map((fi, inx) => {
                                        let file = fi.node ? fi.node : fi
                                        let typeFile = file.fileName?.split(".")
                                        return (
                                            <p key={inx}>
                                                {typeof (typeFile) === 'object' &&
                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} />}&nbsp;
                                                <Link
                                                    to="route"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        window.open(
                                                            typeof file.viewPtath === "string"
                                                                ? file.viewPtath
                                                                : file.fileShow
                                                        )
                                                    }}
                                                    target="blank">{file.fileName}</Link>

                                                &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "contactFile")}>X</label>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        }
                    </Translation>

                    <Translation>
                        {t =>
                            <div className="row mt-3 fade-up">
                                <div className="col text-right">
                                    <div className="btn-group mr-2">
                                        <Link to={this.props.backLink}>
                                            <button type="button"
                                                className="btn btn-secondary add">
                                                {t('creditNoteCreate:Cancel')} 
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="btn-group mr-2">
                                        <button type="submit" className="btn btn-primary add"
                                            disabled={this.state.loading}>
                                            {this.state.loading &&
                                                <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                            {t('creditNoteCreate:Save')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>
                </div>
            </form>
        )
    }
}

export default CreditNoteCreateFormOnlyCn;
