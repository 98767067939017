import React, {Component} from 'react';
import CustomerFilterReceivable from "./invoice/customerFilterReceivable";
import "./listContactResidential.scss";
import getNameResidential from '../../libs/getNameResidential';
import {Translation} from "react-i18next";
import Loading from '../../libs/loading';
import numberWithComma from "../../libs/numberWithComma";

class ListContactSiriraj extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conveyance: 'select',
            select_residential: true,
            all_residential: false,
            search: '',
            select_renter: false,
            select_agent: false,
            status_radioBox: "select",
        };
        this.checkSelect = this.checkSelect.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
    }
    componentDidUpdate(prevProps) {
        if(prevProps.loadingList !== this.props.loadingList) {
            this.setState({
                status_radioBox: "select",
            })
        }
    }
    checkSelect() {
        if (!this.state.select_residential) {
            this.setState({
                select_residential: true,
                all_residential: false,
                select_renter: false,
                select_agent: false,
            });
        }
    }

    handleChangeInput(e) {
        this.setState({
            [e.target.name]: e.target.value,
        })
        this.props.onContactTypeChange(e.target.value);
    }

    render() {

        return (
            <div>
                {
                this.props.loadingList ?
                <Loading />
                        :        
                <React.Fragment> 
                    <div className="row mt-5">
                        <div className="col-md-3 col-12">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="statusAll" name="status_radioBox"
                                    value="all" checked={this.state.status_radioBox === 'all'}
                                    onChange={this.handleChangeInput}/>
                                <label className="custom-control-label"
                                    htmlFor="statusAll">
                                    <Translation>{t => t('selectContact:all')}</Translation>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="statusSelect" name="status_radioBox"
                                    value="select" checked={this.state.status_radioBox === 'select'}
                                    onChange={this.handleChangeInput}/>
                                <label className="custom-control-label"
                                    htmlFor="statusSelect">
                                    <Translation>{t => t('selectContact:select_room')}</Translation>
                                </label>
                            </div>
                        </div>
                    </div>                           
                    <div className="row p-3 mt-1 ">
                                        <div className="col-12 col-md-5 card p-3 colSearch">
                                            <CustomerFilterReceivable queryObject={this.props.allContact}
                                                        contactSelected={this.props.contactSelected}
                                                        checkSelect={this.checkSelect}
                                                        selectContact={this.props.selectContact}
                                                        activeRowSelectedContact={this.activeRowSelectedContact}
                                                        queryField="name:refNumber:firstName:lastName"
                                                        list_type={"residential"}
                                                        select_renter={this.state.select_renter}
                                                        select_agent={this.state.select_agent}
                                            />
                                        </div>
                                        <div className="col-12 col-md-7 card colSearch ">
                                                <Translation>{t => 
                                                    <div className="row p-3">{t('selectContact:selected_item')+' '}{this.props.accountType !== "report_history" && this.props.contact_count !== 0 && '(' + this.props.contact_count + t('selectContact:items')+ ')'}<span className='text-red'>&nbsp; &nbsp;{this.props.role ? `*ชื่อที่แสดงเป็นชื่อ${t(`selectContact:${this.props.role}`)}` : ""}</span></div>
                                                }
                                                </Translation>
                                            <div className="row scrollList">
                                                <div className="col-12">                                            
                                                    {this.props.contactResidentialSelected.map((select_contact) => {
                                                        let nameRole = "";                                                    
                                                        if(this.props.role === 'agent'){
                                                            nameRole = select_contact.agentName
                                                        } else if(this.props.role === 'rent'){
                                                            nameRole = select_contact.renterName
                                                        }
                                                        return (
                                                            <div className="row p-2"
                                                                    key={'select_residential-' + select_contact.id}>
                                                                <div className="col-2 colResidentialRef">{select_contact.refNumber}</div>
                                                                <div className="col-2 colResidential">{select_contact.name}</div>
                                                                <div className="col colResidential">{this.props.role? nameRole : getNameResidential(select_contact.firstName,select_contact.lastName)}</div>
                                                                <div className="col colResidential">{numberWithComma(select_contact.allAmountNotPaid)}</div>
                                                        </div>
                                                    )})}
                                                </div>
                                            </div>
                                        </div>
                    </div>
                </React.Fragment>
                }
            </div>
        )
    }
}

export default ListContactSiriraj;