import '../../report.scss';
import React, { useState, useEffect } from 'react';
import Header from "../../../../components/header/index";
import Sidebar from "../../../../components/sidebar/index";
import Wrapper from "../../../../components/wrapper/index";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import AccountingTopMenuNavigation from '../../../accountingTopMenuNavigation';
import i18next from 'i18next';
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import { formatDateISO } from "../../../../utils";
import accountTypeText from '../../../../libs/accountTypeText'
import SeacrhDetails from '../../../../components/search/indexSearch';
import BankImpactReportExcelExportsDetail from './Doc/Detail/bankImpactReportDetailExcel';
const query = graphql`
  query bankDepositImpactReportDetailQuery($bankAccountId:String, $startDate: Date,$endDate: Date,$search: String) {
    selfProject {
      name
    }
    allBankAccountTransactionReport(bankAccountId:$bankAccountId,startDate: $startDate,endDate:$endDate,search: $search)
  }
`;

const BankDepositImpactReportDetail = (props) => {
  let dateFilter = props?.match?.params.monthyear
  const [month, year] = dateFilter.split('-').map(Number);
  const firstDateOfMonth = new Date(year, month - 1, 1); // month is 0-based, so we subtract 1
  const lastDateOfMonth = new Date(year, month, 0); // day 0 of the next month is the last day of the current month
  
  const [date, setDate] = useState(new Date())
  const [startdate, setStartDate] = useState(firstDateOfMonth)
  const [enddate, setEndDate] = useState(lastDateOfMonth)
  const [search, setSearch] = useState("")
  const [selfProject, setselfProject] = useState(null)
  const [table, settable] = useState([])
  const [bankName, setBankName] = useState(null)
  const [type, setType] = useState(null)

  useEffect(() => {
    setBankName(props?.match?.params?.namebank + " " + props?.match?.params?.accountnumber)
    setType(i18next.t(accountTypeText(props?.match?.params?.type)))
    getData(startdate, enddate, search)

  }, [])

  const getData = (startDate, endDate, searcH) => {
    fetchQuery(environment, query, {
      bankAccountId: props?.match?.params?.id || "",
      startDate: formatDateISO(startDate),
      endDate: formatDateISO(endDate),
      search: searcH
    }).then(data => {
      if (data) {
        setselfProject(data?.selfProject?.name)
        let data_list = [];
        if (data?.allBankAccountTransactionReport) {
          data.allBankAccountTransactionReport.forEach(element => {
            data_list.push(JSON.parse(element))
          });
          settable(data_list)
        }
      }
    })
  }

  const handlePropsChange = (e) => {
    let start_date = e.find(ex => ex.name === "start_date")
    let end_date = e.find(ex => ex.name === "end_date")
    let searchs = e.find(ex => ex.name === "search")


    if (start_date?.value) {
      setStartDate(start_date?.value)
    } if (end_date?.value) {
      setEndDate(end_date.value)
    }
    setSearch(searchs?.value ? searchs?.value : "")
    getData(start_date?.value, end_date.value, searchs?.value)
  }
  
  return (
    <Wrapper>
      <Header />
      <Sidebar />
      <WrapperContent disabledOverflowX={true}>
        <AccountingTopMenuNavigation mini={true} />
        <div className="container-fluid box" id='bank-deposit-report-main' >
          <Translation>
            {t =>
              <React.Fragment>
                <div className="d-flex flex-row">
                  <div style={{width:'50%'}}>
                    <BackButtonIcon
                      LinkBack={"/accounting/report/finance/bank-deposit-impact-report"}
                      LinkText={i18next.t("report_finance:Bank Deposit Impact Report")}
                    />
                  </div>

                  <div className='d-flex justify-content-end' style={{width:'50%'}}>
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'report_financial_print' }) &&
                      <Dropdown >
                        <DropdownToggle disabled={false} id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>พิมพ์เอกสาร</DropdownToggle>
                        <DropdownMenu>
                          <Dropdown.Item>
                            <Link to={{
                              pathname: `/accounting/report/finance/wrapper-bank-deposit-impact-report/${props?.match?.params?.id}/${startdate}/${enddate}/${bankName}/${type}`,
                              query: { lastDate: date }
                            }}
                              className="text-black">
                              <p className="text-black">PDF</p>
                            </Link>
                          </Dropdown.Item>
                          <BankImpactReportExcelExportsDetail
                            bank_data={table}
                            type={type}
                            bankName={bankName}
                            date={`${format(new Date(startdate), "D MMMM YYYY", { locale: thLocale, })} - ${format(new Date(enddate), "D MMMM YYYY", { locale: thLocale, })}`}
                            selfProject={selfProject}
                          />
                        </DropdownMenu>
                      </Dropdown>}
                  </div>
                </div>

                <div className='row colorUse'>
                  <div className='col'>
                    <label className='fs-16 mr-3'>{type}</label>
                    <label className='fs-16 mr-3'>{bankName}</label>
                  </div>
                </div>

                <div className='claim'>
                  <SeacrhDetails
                    endDateIs={true}
                    end_date={enddate}
                    startDateIs={true}
                    start_date={startdate}
                    searchBoxId={true}
                    search={search}
                    startDateText={i18next.t("report_finance:Date")}
                    handlePropsChange={handlePropsChange}
                  />
                </div>


                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center">{i18next.t("report_finance_Impact_Report:Date")}</th>
                        <th className="text-center">{i18next.t("report_finance_Impact_Report:No.")}</th>
                        <th className="text-center">{i18next.t("report_finance_Impact_Report:Ref No.")}</th>
                        <th className="text-center">{i18next.t("report_finance_Impact_Report:Description")}</th>
                        <th className="text-center">{i18next.t("report_finance_Impact_Report:Opening Balance")}</th>
                        <th className="text-center">{i18next.t("report_finance_Impact_Report:Revenue")}</th>
                        <th className="text-center">{i18next.t("report_finance_Impact_Report:Expense")}</th>
                        <th className="text-center">{i18next.t("report_finance_Impact_Report:Remaining Balance")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        table?.map((item, index) => {
                          return (
                            <tr key={"bankDepositImpactReportDetail::" + index}>
                              <td className='text-center'>{_.get(item, 'date')}</td>
                              <td className='text-center'>{_.get(item, 'ref_number')}</td>
                              <td className='text-center'>{_.get(item, 'ref_transaction')}</td>
                              <td>{_.get(item, 'description')}</td>
                              <td className='text-right'>{_.get(item, 'previous_balance') !== "" ? parseFloat(_.get(item, 'previous_balance')).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                              <td className='text-right'>{_.get(item, 'cash_in') >= 0 && _.get(item, 'cash_in') !== "" ? parseFloat(_.get(item, 'cash_in')).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                              <td className='text-right'>{_.get(item, 'cash_out') >= 0 && _.get(item, 'cash_out') !== "" ? parseFloat(_.get(item, 'cash_out')).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                              <td className='text-right'>{_.get(item, 'balance') ? parseFloat(_.get(item, 'balance')).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00"}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>


              </React.Fragment>
            }
          </Translation>
        </div>
      </WrapperContent>
    </Wrapper >
  );
};

export default BankDepositImpactReportDetail;