import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import ReceiptDepositList from './receiptDepositList';
import ReceiptDepositCreate from './receiptDepositCreate';
import ReceiptDepositView from './receiptDepositView';


class ReceiptDeposit extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ReceiptDepositList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={ReceiptDepositCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/view/:id`} component={ReceiptDepositView}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default ReceiptDeposit;