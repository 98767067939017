import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";

class Navigation extends Component {

    render() {
        return (
            <Translation>
            { 
                (t) => (
                <div className="row" id="navigation-tab">
                    <div className="col">
                        <ul>
                            <li>
                                <NavLink to="/accounting/collection-law-firm/setting" >
                                    {t("collectionLawFirm:Register For Debt Collection Attorney Service")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            </Translation>
        )
    }
}

export default Navigation;