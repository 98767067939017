import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import FineList from './fineList';
import FineDetail from './fineDetail';

class CreditNote extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/`} component={FineList}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id/:product_code?`} component={FineDetail}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default CreditNote;