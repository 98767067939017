import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import numberWithComma from "../../libs/numberWithComma";
import {Link} from "react-router-dom";
import Loading from "../../libs/loading";

const query = graphql`
    query expenseTableQuery ($startDateFirst: DateTime, $endDateFirst: DateTime, $startDateSecond: DateTime, $endDateSecond: DateTime) {
        chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "5", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
            edges {
                node {
                    chartOfAccountCode
                    yearFirst
                    yearSecond
                    totalHistoricalDiffFirst
                    totalHistoricalDiffSecond
                }
            }
        }
    }
`;

class ExpenseTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.setDataRender = this.setDataRender.bind(this)
    }

    setDataRender(data_render, chart_of_account, key, code) {
        let year_first = chart_of_account.node.yearFirst;
        let year_second = chart_of_account.node.yearSecond;
        let total_historical_diff_first = chart_of_account.node.totalHistoricalDiffFirst;
        let total_historical_diff_second = chart_of_account.node.totalHistoricalDiffSecond;
        Object.assign(data_render, {
            [key]: {
                year_first: year_first + data_render[key].year_first + total_historical_diff_first,
                year_second: year_second + data_render[key].year_second + total_historical_diff_second,
                code: code,
            }
        })
    }

    renderRow(name, year_first, year_second,code) {
        return (
            (year_first !== 0 || year_second !== 0) &&
            <tr>
                {/* <td>&nbsp;&nbsp;&nbsp;&nbsp;{name}</td> */}
                <td>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link key={code} to={"balance-sheet-statements/"+code+"/"+this.props.state.period} target='_blank'>{name}</Link>
                </td>
                <td className="text-right">{numberWithComma(year_first, '0.00')}</td>
                <td className="text-right">{numberWithComma(year_second, '0.00')}</td>
            </tr>
        )
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{use_cache: false}}
                variables={{
                    startDateFirst: this.props.state.start_date_first,
                    endDateFirst: this.props.state.end_date_first,
                    startDateSecond: this.props.state.start_date_second,
                    endDateSecond: this.props.state.end_date_second,
                }}
                render={({error, props}) => {
                    if (props) {
                        let data_render = {
                            net_cost: {year_first: 0, year_second: 0, code: "5100"},
                            wage: {year_first: 0, year_second: 0, code: "5200"},
                            promised: {year_first: 0, year_second: 0, code: "5300"},
                            office: {year_first: 0, year_second: 0, code: "5400"},
                            utility_bills: {year_first: 0, year_second: 0, code: "5500"},
                            repair: {year_first: 0, year_second: 0, code: "5600"},
                            depreciation: {year_first: 0, year_second: 0, code: "5700"},
                            other: {year_first: 0, year_second: 0, code: "other_expense"},

                        };

                        let year_first_total = 0;
                        let year_second_total = 0;

                        props.chartOfAccountSummaryIncomeExpense.edges.forEach((chart_of_account => {

                            let code = chart_of_account.node.chartOfAccountCode;
                            year_first_total += (chart_of_account.node.yearFirst + chart_of_account.node.totalHistoricalDiffFirst);
                            year_second_total += (chart_of_account.node.yearSecond + chart_of_account.node.totalHistoricalDiffSecond);

                            if (code.substring(0, 4) === '5100') {
                                this.setDataRender(data_render, chart_of_account, 'net_cost', code.substring(0, 4))

                            }else if (code.substring(0, 4) === '5200') {
                                this.setDataRender(data_render, chart_of_account, 'wage', code.substring(0, 4))

                            }else if (code.substring(0, 4) === '5300') {
                                this.setDataRender(data_render, chart_of_account, 'promised', code.substring(0, 4))

                            }else if (code.substring(0, 4) === '5400') {
                                this.setDataRender(data_render, chart_of_account, 'office', code.substring(0, 4))

                            }else if (code.substring(0, 4) === '5500') {
                                this.setDataRender(data_render, chart_of_account, 'utility_bills', code.substring(0, 4))

                            }else if (code.substring(0, 4) === '5600') {
                                this.setDataRender(data_render, chart_of_account, 'repair', code.substring(0, 4))

                            } else if (code.substring(0, 4) === '5700') {
                                this.setDataRender(data_render, chart_of_account, 'depreciation', code.substring(0, 4))

                            }else {
                                this.setDataRender(data_render, chart_of_account, 'other', "other_expense")

                            }

                        }));

                        return (
                            <React.Fragment>
                                <tr>
                                    <td>
                                        <strong>รายจ่าย / Expense</strong>
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>

                                {this.renderRow("ต้นทุนขายสุทธิ / Cost of Goods Sold", data_render.net_cost.year_first, data_render.net_cost.year_second, data_render.net_cost.code)}
                                {this.renderRow("เงินเดือนเเละค่าจ้าง / Salaries and wages", data_render.wage.year_first, data_render.wage.year_second, data_render.wage.code)}
                                {this.renderRow("รายจ่าย - ตามสัญญา / Expense - by agreement", data_render.promised.year_first, data_render.promised.year_second, data_render.promised.code)}
                                {this.renderRow("รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office", data_render.office.year_first, data_render.office.year_second, data_render.office.code)}
                                {this.renderRow("รายจ่ายสาธารณูปโภค / Operation expense - Utility", data_render.utility_bills.year_first, data_render.utility_bills.year_second, data_render.utility_bills.code)}
                                {this.renderRow("รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair", data_render.repair.year_first, data_render.repair.year_second, data_render.repair.code)}
                                {this.renderRow("รายจ่ายค่าเสื่อมราคาและค่าตัดจำหน่าย / Depreciation and Amortization expense", data_render.depreciation.year_first, data_render.depreciation.year_second, data_render.depreciation.code)}
                                {this.renderRow("ค่าใช้จ่ายอื่น ๆ / Other expenses", data_render.other.year_first, data_render.other.year_second, data_render.other.code)}

                                <tr>
                                    <td>
                                        <strong>รวมรายจ่าย / Total Expenditure</strong>
                                    </td>
                                    <td className="text-right"><strong>{numberWithComma(year_first_total, '0.00')}</strong></td>
                                    <td className="text-right"><strong>{numberWithComma(year_second_total, '0.00')}</strong></td>
                                </tr>
                            </React.Fragment>
                        )
                    } else {
                        return <Loading />
                    }
                }}
            />

        )
    }
}

export default ExpenseTable;