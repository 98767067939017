import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import _ from "lodash"
import Swal from 'sweetalert2'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment'
import queryContact from './queryGraphql/queryContact'
import upload from '../../libs/upload'
import { format, differenceInCalendarMonths } from 'date-fns'
import { commitMutation } from "react-relay";
import { Redirect } from "react-router-dom";
import ApproveManageRequest from './mutation/approveManageRequest'
import "./style/manageRequest.scss"

// component
import FormHeadCheckType from './formHeadCheckType/formHeadCheckType'
import FormRequisition from './formRequisition/formRequisition'
import FormCompanyComparative from './formCompanyComparative/formCompanyComparative'
import FormContactDetails from './formContactDetails/formContactDetails'
import FormSummaryAndAdditional from './formSummaryAndAdditional/formSummaryAndAdditional'
import HeaderAlert from './headerAlert/headerAlert'
import NotConfirmManageRequest from './modalManageRequest/notConfirmManageRequest'
import FormCondiotionWaranty from './formCondiotionWaranty/formCondiotionWaranty'
import ClaimTopMenuNavigation from '../claim/claimTopMenuNavigation'
import i18next from 'i18next'


const query = graphql`
  query updateFormManageRequestQuery($prfId: String,$type_In: String){
    productViewer{
      allProduct (type_In: $type_In,isActive: true){
        edges{
          node{
            id
            name
            productCode
            type
            price
            chartOfAccount{
                id
                chartOfAccountCode
                name
            }
            creditorChartOfAccount{
                id
                chartOfAccountCode
                name
            }
            description
            taxRate
            inputTax
            outputTax
          }
        }
      }
    }
    viewer{
      allChartOfAccount(codeList: ["12"]){
        edges{
          node{
            id
            chartOfAccountCode
            name
          }
        }
      }
    }
    myUser {
      juristic {
          id firstName lastName image role
      }
    }
    oneProcurementForm(prfId: $prfId){
      edges {
          node {
              added
              id
              docNumber
              issuedDate
              creditor{
                id
                name
              }
              buyDate
              contractNumber
              contractDate
              paymentTerms
              otherPaymentTerms
              status
              type
              comparativeCompany
              lowestOffer
              highestOffer
              contractor
              contractorTel
              contractorEmail
              deliveryAddress
              deliveryDate
              reason
              feedback
              remark
              presenter
              presenterRole
              voidRemark
              editRemark
              urgent
              total
              approver
              approveDate
              voidDate
              voider
              warrantyDay
              productAndService{
                id name
              }
              facilityUse
              chartOfAccount{
                id name nameEn chartOfAccountCode 
              }
              startContractDate
              endContractDate
              contractPeriod
              purchasing
              procurementList{
                  edges{
                      node{
                          id
                          productAndService{
                              id
                              name
                              productCode
                          }
                          chartOfAccount{
                              id
                              chartOfAccountCode
                              name
                          }
                          description
                          unitItems
                          price
                          unit
                          discount
                          budget
                          vat
                          whtRate
                          whtRatePercent
                          preTaxAmount
                          total
                      }
                  }
              }
              procurementFormImage{
                  edges{
                      node{
                        fileUpload
                        pathName
                      }
                  }
              }
              procurementFormContract{
                  edges{
                      node{
                        fileUpload
                        pathName
                        fileName
                      }
                  }
              }
              procurementFormComparative{
                  edges{
                      node{
                        fileUpload
                        pathName
                        fileName
                      }
                  }
              }
          }
      }
    }
    checkUserAdmin
    limitMoney(limitType : "procurement_form")
  }
`

const mutation = graphql`
  mutation updateFormManageRequestMutation($input: EditProcurementFormInput!) {
    editProcurementForm(input:$input) 
    {
      ok
      message
    }
  }
`;

let transaction = {
  list_id: "",
  description: "",
  unit_items: "1",
  price: "",
  unit: "",
  budget: "",
  output_tax: "",
  wht_rate: ""
}

class updateFormManageRequest extends Component {

  constructor(props) {
    super(props)

    this.state = {
      purchaseRequisition: {
        creationDate: new Date(),
        addPurchasing: {
          title: "",
          detail: ""
        },
        creditorId: "",
        buyDate: new Date(),
        contractNumber: "",
        contractDate: new Date(),
        paymentTerms: "",
        otherPaymentTerms: "",
        productAndServiceId: "",
        chartOfAccountId: "",
        facilityUse: "",
        procurementFormContract: [],
        urgent: false,
        startContractDate: new Date(),
        endContractDate: new Date(),
        contractPeriod: "",
        procurementFormList: [transaction],
        comparativeCompany: "",
        lowestOffer: "",
        highestOffer: "",
        procurementFormComparative: [],
        contractor: "",
        contractorTel: "",
        contractorEmail: "",
        deliveryAddress: "",
        deliveryDate: new Date(),
        procurementFormImage: [],
        reason: "",
        feedback: "",
        remark: "",
        total: "",
        added: "",
        warrantyDay: ""
      },
      productAndServiceList: [],
      vat: "",
      presenterName: "",
      presenterRole: "",

      closeHaederAlert: "d-block",
      disibleInput: false,
      limitMoney: 0,
      approveDate: "",
      approver: "",
      voidDate: "",
      voidRemark: "",
      voider: "",
      statusSubmit: "",
      redirectTo: false,
      creditorIdBefore: ""
    }
  }

  componentWillMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.purchaseRequisition.creditorId !== this.state.purchaseRequisition.creditorId && this.state.purchaseRequisition.creditorId !== this.state.creditorIdBefore) {
      fetchQuery(environment, queryContact, { id: this.state.purchaseRequisition.creditorId }).then((response) => {

        this.setState(
          ({ purchaseRequisition }) => ({
            purchaseRequisition: {
              ...purchaseRequisition,
              contractor: `${response.contact.firstName} ${response.contact.lastName}`,
              contractorTel: response.contact.phone,
              contractorEmail: response.contact.email,
              deliveryAddress: "",
            }
          }), () => this.setState({ vat: response.contact.vat })
        );

      })
    }
  }

  getData = () => {

    let vilableType = ""

    if (this.props.match.params.type === "product") {
      vilableType = "product"
    } else if (this.props.match.params.type === "service" || this.props.match.params.type === "service_contact") {
      vilableType = "expense"
    }

    fetchQuery(environment, query, { prfId: this.props.match.params.id, type_In: vilableType }).then((response) => {

      let productAndServiceLists = [];

      if (response.productViewer && (this.props.match.params.type === "product" || this.props.match.params.type === "service" || this.props.match.params.type === "service_contact")) {
        _.map(response.productViewer.allProduct.edges, (product) => {
          productAndServiceLists.push(product)
        })
      }
      if (response.viewer && this.props.match.params.type === "asset") {

        _.map(response.viewer.allChartOfAccount.edges, (chart_of_account) => {
          let list_object = {
            id: chart_of_account.node.id,
            name: chart_of_account.node.name,
            productCode: chart_of_account.node.chartOfAccountCode,
            price: "",
            description: "",
            taxRate: "",
            chartOfAccount: {
              id: chart_of_account.node.id,
              chartOfAccountCode: chart_of_account.node.chartOfAccountCode,
              name: chart_of_account.node.name,
            },
            type: "chart_of_account",
          };

          productAndServiceLists.push({ node: list_object })
        })
      }
      if (response.oneProcurementForm) {
        let itemDocument = response.oneProcurementForm.edges[0].node

        let newProcurementFormList = []
        _.map(response.oneProcurementForm.edges[0].node.procurementList.edges, (o) => {
          newProcurementFormList.push({
            list_id: o.node.productAndService ? o.node.productAndService.id : o.node.chartOfAccount.id,
            description: o.node.description,
            unit_items: o.node.unitItems,
            price: o.node.price,
            unit: o.node.unit,
            budget: o.node.budget,
            output_tax: o.node.vat,
            wht_rate: o.node.whtRate,
            pre_tax_amount:o.node.preTaxAmount
          })
        })

        let newPurchaseRequisition = {
          creationDate: new Date(itemDocument.issuedDate),
          addPurchasing: {
            title: itemDocument.purchasing === "ซื้อเพิ่ม" ? "buyMore" : "buyReplaceAssetsNo",
            detail: itemDocument.purchasing
          },
          creditorId: itemDocument.creditor.id,
          buyDate: new Date(itemDocument.buyDate),
          contractNumber: itemDocument.contractNumber,
          contractDate: itemDocument.contractDate ? new Date(itemDocument.contractDate) : new Date(),
          paymentTerms: itemDocument.paymentTerms === "OTHER" ? "other" : itemDocument.paymentTerms.substring(2),
          otherPaymentTerms: itemDocument.otherPaymentTerms,
          // productAndServiceId: (itemDocument.productAndService?.id || null),
          chartOfAccountId: itemDocument.chartOfAccount?.id,
          facilityUse: (itemDocument.facilityUse || null),
          procurementFormContract: itemDocument.procurementFormContract.edges,
          urgent: itemDocument.urgent,
          startContractDate: new Date(itemDocument.startContractDate),
          endContractDate: new Date(itemDocument.endContractDate),
          contractPeriod: itemDocument.contractPeriod,
          procurementFormList: newProcurementFormList,
          comparativeCompany: itemDocument.comparativeCompany,
          lowestOffer: itemDocument.lowestOffer,
          highestOffer: itemDocument.highestOffer,
          procurementFormComparative: itemDocument.procurementFormComparative.edges,
          contractor: itemDocument.contractor,
          contractorTel: itemDocument.contractorTel,
          contractorEmail: itemDocument.contractorEmail,
          deliveryAddress: itemDocument.deliveryAddress,
          deliveryDate: new Date(itemDocument.deliveryDate),
          procurementFormImage: itemDocument.procurementFormImage.edges,
          reason: itemDocument.reason,
          feedback: itemDocument.feedback,
          remark: itemDocument.remark,
          total: itemDocument.total,
          added: itemDocument.added,
          warrantyDay: itemDocument.warrantyDay,
          presenter: itemDocument.presenter,
          presenterRole: itemDocument.presenterRole
        }

        this.setState({
          purchaseRequisition: newPurchaseRequisition,
          approveDate: itemDocument.approveDate,
          voidDate: itemDocument.voidDate,
          voidRemark: itemDocument.voidRemark,
          approver: itemDocument.approver,
          voider: itemDocument.voider,
          creditorIdBefore: itemDocument.creditor.id,
          vat: newProcurementFormList[0].output_tax,
        })
      }

      this.setState({
        productAndServiceList: productAndServiceLists,
        limitMoney: response.limitMoney,
        checkUserAdmin: response.checkUserAdmin,
      })

    })
  }

  componentDidMount() {
    this.checkDisibleInput()
  }

  checkPurchaseRequisitionType = (type) => {

    let valueResult = ""

    switch (type) {
      case "product":
        valueResult = i18next.t("purchaseRequisition:Product")
        break;
      case "service":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(General work)")
        break;
      case "service_contact":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(Contract)")
        break;
      case "asset":
        valueResult = i18next.t("purchaseRequisition:Asset")
        break;

      default:
        break;
    }
    return valueResult
  }

  getStatus = (text) => {

    let valueResult = ""
    let colorValue = ""

    switch (text) {
      case "draft":
        valueResult = "Draft"
        colorValue = "statusGrey"
        break;
      case "active":
        valueResult = "Present"
        colorValue = "statusYellow"
        break;
      case "approve":
        valueResult = "Approve"
        colorValue = "statusGreen"
        break;
      case "edit":
        valueResult = "Wait Edit"
        colorValue = "statusYellow"
        break;
      case "void":
        valueResult = "Cancled"
        colorValue = "statusRed"
        break;

      default:
        break;
    }

    return (
      <Translation>
        {t =>
          <div className={`${colorValue}` + " ml-3"}>
            <span>{i18next.t("PageListPRPO:"+valueResult)}</span>
          </div>
        }
      </Translation>

    )
  }

  appendFileInputRow = (name, fileInput) => {
    let file = { node: { fileName: fileInput.node.nameFile, fileUpload: fileInput.node.fileUpload } }

    let newFile = []

    if (name === "purchaseRequisition.procurementFormContract") {
      newFile = [...this.state.purchaseRequisition.procurementFormContract, { ...file }]
    } else if (name === "purchaseRequisition.procurementFormComparative") {
      newFile = [...this.state.purchaseRequisition.procurementFormComparative, { ...file }]
    }

    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), name, newFile);
    });
  }

  appendRequisitionListRow = (name) => {
    let transactionItem = _.cloneDeep(transaction)
    let newTransaction = []

    newTransaction = [...this.state.purchaseRequisition.procurementFormList, { ...transactionItem }]

    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState), "purchaseRequisition.procurementFormList", newTransaction);
    });
  }

  handleRemoveRequisitionListRow = (rowIndex) => {
    let procurementFormList = [...this.state.purchaseRequisition.procurementFormList];
    procurementFormList.splice(rowIndex, 1);

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), "purchaseRequisition.procurementFormList", procurementFormList)
    })
  }

  handleRemoveImgSelect = (name, index) => {

    let listsSplice = []

    if (name === "purchaseRequisition.procurementFormImage") {
      listsSplice = [...this.state.purchaseRequisition.procurementFormImage]
      listsSplice.splice(index, 1)
    } else if (name === "purchaseRequisition.procurementFormComparative") {
      listsSplice = [...this.state.purchaseRequisition.procurementFormComparative]
      listsSplice.splice(index, 1)
    } else if (name === "purchaseRequisition.procurementFormContract") {
      listsSplice = [...this.state.purchaseRequisition.procurementFormContract]
      listsSplice.splice(index, 1)
    }

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, listsSplice)
    })

  }

  handleChangeInput = (e) => {
    let { name, value } = e.target;

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  handleChangeDate = (name, date) => {

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, date)
    });
  }

  handleInputImage = (even) => {
    if (even.currentTarget.files[0] && even.currentTarget.files[0].type.split("/")[0] === "image") {
      let file = { node: { fileUpload: even.currentTarget.files[0] } }

      let newFile = [...this.state.purchaseRequisition.procurementFormImage, { ...file }]

      this.setState((prevState) => {
        return _.set(_.cloneDeep(prevState), "purchaseRequisition.procurementFormImage", newFile);
      });

    } else {
      Swal.fire(i18next.t("Allaction:Invalid file type"), i18next.t("Allaction:Please check again"), "warning");
    }
  }

  handleCloseHeaderAlert = () => {
    this.setState({ closeHaederAlert: "d-none" })
  }

  checkDisibleInput = () => {
    let valueDisible = false;

    if (this.props.match.params.status === "active" ||
      this.props.match.params.status === "approve" ||
      this.props.match.params.status === "void"
    ) { valueDisible = true; }

    this.setState({ disibleInput: valueDisible })
  }

  handleChangeProcurementFormList = (e) => {
    let { name, value } = e.target;
    let newTransaction = _.cloneDeep(transaction)

    let procurementFormListArr = name.split(":")
    let productAndServiceListArr = _.filter(this.state.productAndServiceList, (o) => o.node.id === value)

    _.map(productAndServiceListArr, (item, index) => {
      _.set(newTransaction, "list_id", value)
      _.set(newTransaction, "description", item.node.name || "")
      _.set(newTransaction, "unit_items", "1")
      // _.set(newTransaction, "unit", "1")
      // _.set(newTransaction, "output_tax", value)
      _.set(newTransaction, "wht_rate", item.node.taxRate || "")
    })

    let newName = `purchaseRequisition.procurementFormList[${parseInt(procurementFormListArr[1])}]`

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), newName, newTransaction)
    });
  }

  handleSubmit = async (e) => {

    e.preventDefault()

    const swalSaveFormManageRequest = Swal.mixin({
      customClass: {
        confirmButton: 'swal-BtnConfirm',
        cancelButton: 'swal-BtnCancel ml-3',
      },
      width: '640px',
      buttonsStyling: false
    })

    let newProcurementList = []
    let totalsAmount = 0
    let sumVat = 0
    let preTaxAmount = 0
    let totalItem = 0

    _.forEach(this.state.purchaseRequisition.procurementFormList, (item) => {

      preTaxAmount = parseFloat(item.unit_items) * parseFloat(item.price)
      sumVat = preTaxAmount * parseFloat(this.state.vat || 0) / 100;
      totalItem = preTaxAmount + sumVat

      let itemProcurementList = {
        list_id: item.list_id,
        description: item.description,
        unit_items: parseFloat(item.unit_items),
        price: parseFloat(item.price),
        unit: item.unit,
        budget: parseFloat(item.budget || 0),
        output_tax: parseFloat(this.state.vat || 0),
        wht_rate: parseFloat(item.wht_rate || 0),
        total: totalItem,
        discount: null,
        pre_tax_amount: preTaxAmount,
      }

      totalsAmount += totalItem
      newProcurementList.push(itemProcurementList)
    })

    let newProcurementFormContract = []

    if (this.state.purchaseRequisition.procurementFormContract.length > 0) {
      for (const item of this.state.purchaseRequisition.procurementFormContract) {
        if (typeof item.node.fileUpload === "string") { //file เดิม
          newProcurementFormContract.push({ file_upload: item.node.pathName, file_name: item.node.fileName })

        } else { //file ใหม่

          let new_file = new File([item.node.fileUpload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${item.node.fileName}`);
          let data = await upload(`/contract_expense/contract`, new_file)
          newProcurementFormContract.push({ file_upload: data.key.replace("silverman-storage/private/", ""), file_name: item.node.fileName })
        }
      }
    }

    let newProcurementFormComparative = []

    if (this.state.purchaseRequisition.procurementFormComparative.length > 0) {
      for (const item of this.state.purchaseRequisition.procurementFormComparative) {

        if (typeof item.node.fileUpload === "string") { //file เดิม
          newProcurementFormComparative.push({ file_upload: item.node.pathName, file_name: item.node.fileName })

        } else { //file ใหม่

          let new_file = new File([item.node.fileUpload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${item.node.fileName}`);
          let data = await upload(`/contract_expense/comparative`, new_file);
          newProcurementFormComparative.push({ file_upload: data.key.replace("silverman-storage/private/", ""), file_name: item.node.fileName })
        }
      }
    }

    let newProcurementFormImage = []

    if (this.state.purchaseRequisition.procurementFormImage.length > 0) {
      for (const item of this.state.purchaseRequisition.procurementFormImage) {

        if (typeof item.node.fileUpload === "string") { //file เดิม
          newProcurementFormImage.push(item.node.pathName)

        } else { //file ใหม่

          let new_file = new File([item.node.fileUpload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${item.node.fileUpload.name}`);
          let data = await upload(`/contract_expense/image`, new_file);
          newProcurementFormImage.push(data.key.replace("silverman-storage/private/", ""))
        }
      }
    }

    let variables = {
      input: {
        procurementFormId: this.props.match.params.id,
        purchasing: this.state.purchaseRequisition.addPurchasing.detail,
        creditorId: this.state.purchaseRequisition.creditorId,
        buyDate: format(this.state.purchaseRequisition.buyDate, "YYYY-MM-DD"),
        contractNumber: this.state.purchaseRequisition.contractNumber,
        contractDate: this.state.purchaseRequisition.contractNumber ? format(this.state.purchaseRequisition.contractDate, "YYYY-MM-DD") : null,
        paymentTerms: this.state.purchaseRequisition.paymentTerms,
        otherPaymentTerms: this.state.purchaseRequisition.paymentTerms === "other" ? this.state.purchaseRequisition.otherPaymentTerms : "",
        // productAndServiceId: this.state.purchaseRequisition.productAndServiceId,
        chartOfAccountId: this.state.purchaseRequisition.chartOfAccountId,
        facilityUse: this.state.purchaseRequisition.facilityUse,
        procurementFormContract: newProcurementFormContract,
        // 2
        urgent: this.state.purchaseRequisition.urgent,
        startContractDate: format(this.state.purchaseRequisition.startContractDate, "YYYY-MM-DD"),
        endContractDate: format(this.state.purchaseRequisition.endContractDate, "YYYY-MM-DD"),
        contractPeriod: differenceInCalendarMonths(this.state.purchaseRequisition.endContractDate, this.state.purchaseRequisition.startContractDate),
        procurementFormList: newProcurementList,
        // 3
        comparativeCompany: parseFloat(this.state.purchaseRequisition.comparativeCompany),
        lowestOffer: parseFloat(this.state.purchaseRequisition.lowestOffer),
        highestOffer: parseFloat(this.state.purchaseRequisition.highestOffer),
        procurementFormComparative: newProcurementFormComparative,
        // 4
        contractor: this.state.purchaseRequisition.contractor,
        contractorTel: this.state.purchaseRequisition.contractorTel,
        contractorEmail: this.state.purchaseRequisition.contractorEmail,
        deliveryAddress: this.state.purchaseRequisition.deliveryAddress,
        deliveryDate: format(this.state.purchaseRequisition.deliveryDate, "YYYY-MM-DD"),
        //5
        procurementFormImage: newProcurementFormImage,
        reason: this.state.purchaseRequisition.reason,
        feedback: this.state.purchaseRequisition.feedback,
        remark: this.state.purchaseRequisition.remark,

        status: this.state.statusSubmit,
        total: totalsAmount,
      },
    };

    if (this.state.statusSubmit === "active") {

      swalSaveFormManageRequest.fire({
        html:
          `<img class="my-4" src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue"/>
        <p><b>${i18next.t("createFromManageRequest:If data is saved, the system will automatically present it to the approver ")}<br/>
        ${i18next.t("createFromManageRequest:and cannot be edited until the approver rejects or approves the purchase requisition.")}<br/>
        ${i18next.t("createFromManageRequest:Do you want to continue the process")}?</b></p>`,
        showCancelButton: true,
        confirmButtonText: i18next.t("Allaction:Yes"),
        cancelButtonText: i18next.t("Allaction:cancel"),
      }).then(async (result) => {
        if (result.value) {

          commitMutation(
            environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              if (response.editProcurementForm.ok) {
                Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                  this.setState({
                    redirectTo: '/purchasing/manageRequest/list/all'
                  })
                })
              } else {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error");
              }
            },
            onError: (err) => {
              Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error");
            },
          }
          );

        }
      })
    } else if (this.state.statusSubmit === "draft") {
      commitMutation(
        environment, {
        mutation,
        variables,
        onCompleted: (response) => {
          if (response.editProcurementForm.ok) {
            Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
              this.setState({
                redirectTo: '/purchasing/manageRequest/list/all'
              })
            })
          } else {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), `${response.editProcurementForm.message}`, "error");
          }
        },
        onError: (err) => {
          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error");
        },
      }
      );
    }

  }

  handleApprove = () => {
    const swalApproveFormManageRequest = Swal.mixin({
      customClass: {
        confirmButton: 'swal-BtnConfirm',
        cancelButton: 'swal-BtnCancel ml-3',
      },
      width: '640px',
      buttonsStyling: false
    })

    if (this.state.purchaseRequisition.total <= this.state.limitMoney || this.state.checkUserAdmin) {

      swalApproveFormManageRequest.fire({
        html:
          `<img class="my-4" src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue"/>
        <p><b>${i18next.t("createFromManageRequest:Do you want to approve purchase requisition")}<br/>
        ${i18next.t("createFromManageRequest:No.")} ${this.props.match.params.docNumber} ?<br/></p>`,
        showCancelButton: true,
        confirmButtonText: i18next.t("createFromManageRequest:Approve"),
        cancelButtonText: i18next.t("Allaction:cancel"),
      }).then((result) => {
        if (result.value) {

          let variableApprove = {
            input: {
              procurementFormId: this.props.match.params.id
            }
          }

          ApproveManageRequest(
            variableApprove,
            (res) => {

              if (res.approveProcurementForm.ok) {
                Swal.fire(i18next.t("Allaction:Successful"), '', 'success').then(() => {
                  this.setState({
                    redirectTo: '/purchasing/manageRequest/list/all'
                  })
                })

              } else {
                Swal.fire(i18next.t("Allaction:Unsuccessful"), i18next.t("Allaction:Please try again."), 'warning')
              }
            },
            () => { Swal.fire(i18next.t("Allaction:Unsuccessful"), '', 'warning') }
          )
        }
      })
    } else {
      Swal.fire(i18next.t("createFromManageRequest:The authorized approval's credit limit is insufficient."), '', 'error')
    }
  }

  handleCloseModal = () => {
    this.setState({ notConfirm: false })
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <ClaimTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="updateFormManageRequest">
            <form action="submit" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col">
                  <Translation>
                    {
                      t =>
                        <h4 className="mb-4">
                          <Link to={`/purchasing/manageRequest/list/all`}>
                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                              alt="back" className="back" />
                          </Link>
                          {this.props.match.params.status === "draft"
                            ? <span>{t("purchaseRequisition:Detail")}</span>
                            : <span>{this.props.match.params.docNumber}</span>
                          }
                        </h4>
                    }
                  </Translation>
                </div>
              </div>

              <div className="content-inner">

                {(this.props.match.params.status === "active" || this.props.match.params.status === "approve" || this.props.match.params.status === "void") &&
                  <div className={`${this.state.closeHaederAlert} row mt-4`}>
                    <div className="col">
                      <HeaderAlert
                        approveDate={this.state.approveDate}
                        approver={this.state.approver}
                        voidDate={this.state.voidDate}
                        voidRemark={this.state.voidRemark}
                        voider={this.state.voider}
                        status={this.props.match.params.status}
                        handleCloseHeaderAlert={this.handleCloseHeaderAlert}
                      />
                    </div>
                  </div>
                }

                <div className="row spaceTop">
                  <div className="col">
                    <h5 className="headCheckType">
                      {i18next.t("purchaseRequisition:Purchase requisition type")}: {this.checkPurchaseRequisitionType(this.props.match.params.type)} {this.getStatus(this.props.match.params.status)}
                    </h5>
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormHeadCheckType
                      handleChangeDate={this.handleChangeDate}
                      handleChangeInput={this.handleChangeInput}
                      appendFileInputRow={this.appendFileInputRow}
                      handleRemoveImgSelect={this.handleRemoveImgSelect}
                      purchaseRequisition={this.state.purchaseRequisition}
                      purchaseType={this.props.match.params.type} //เช่น สินค้า สินทรัพย์
                      docNumber={this.props.match.params.docNumber}
                      disibleInput={this.state.disibleInput}
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormRequisition
                      urgent={this.state.purchaseRequisition.urgent}
                      procurementFormList={this.state.purchaseRequisition.procurementFormList}
                      purchaseRequisition={this.state.purchaseRequisition}
                      vat={this.state.vat}
                      handleChangeInput={this.handleChangeInput}
                      appendRequisitionListRow={this.appendRequisitionListRow}
                      handleRemoveRequisitionListRow={this.handleRemoveRequisitionListRow}
                      handleChangeDate={this.handleChangeDate}
                      purchaseType={this.props.match.params.type} //เช่น สินค้า สินทรัพย์
                      disibleInput={this.state.disibleInput}
                      handleChangeProcurementFormList={this.handleChangeProcurementFormList}
                      productAndServiceList={this.state.productAndServiceList}

                    />

                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormCondiotionWaranty
                      handleRemoveImgSelect={this.handleRemoveImgSelect}
                      handleChangeSelect={this.handleChangeSelect}
                      purchaseRequisition={this.state.purchaseRequisition}
                      disibleInput={this.state.disibleInput}
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormCompanyComparative
                      appendFileInputRow={this.appendFileInputRow}
                      handleRemoveImgSelect={this.handleRemoveImgSelect}
                      handleChangeInput={this.handleChangeInput}
                      purchaseRequisition={this.state.purchaseRequisition}
                      disibleInput={this.state.disibleInput}
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormContactDetails
                      handleChangeDate={this.handleChangeDate}
                      purchaseRequisition={this.state.purchaseRequisition}
                      handleChangeInput={this.handleChangeInput}
                      disibleInput={this.state.disibleInput}
                      type={this.props.match.params.type}
                    />
                  </div>
                </div>

                <div className="row spaceTop">
                  <div className="col">
                    <FormSummaryAndAdditional
                      vat={this.state.vat}
                      presenterName={this.state.purchaseRequisition.presenter}
                      purchaseRequisition={this.state.purchaseRequisition}
                      handleChangeInput={this.handleChangeInput}
                      handleInputImage={this.handleInputImage}
                      handleRemoveImgSelect={this.handleRemoveImgSelect}
                      disibleInput={this.state.disibleInput}
                      presenterRole={this.state.purchaseRequisition.presenterRole}
                    />
                  </div>
                </div>

                {!this.state.disibleInput &&
                  <div className="row spaceTop mb-5">
                    <div className="col d-flex justify-content-end">
                      {this.props.match.params.status !== "edit" &&
                        <button type="submit" className="btnDraft" onClick={() => this.setState({ statusSubmit: "draft" })}>
                          <span>{i18next.t("PurchaseCreate:Save a draft")}</span>
                        </button>
                      }
                      <button type="submit" className="ml-5 btnSave" onClick={() => this.setState({ statusSubmit: "active" })}>
                        <span>{i18next.t("Allaction:save")}</span>
                      </button>
                    </div>
                  </div>
                }

                {(this.props.match.params.status === "active" || this.props.match.params.status === "approve") &&
                  <React.Fragment>
                    <div className="row spaceTop mb-5">
                      <div className="col d-flex justify-content-end">
                        {(this.props.match.params.status === "active" || this.props.match.params.status === "approve") &&
                          <Link to={`/document/manageRequest/${this.props.match.params.id}`} target="_blank">
                            <button type="button" className="btnSave d-flex justify-content-center align-items-center">
                              <img src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'} alt="silverman" />
                              <span>{i18next.t("Allaction:Print")} PRF</span>
                            </button>
                          </Link>
                        }
                        {this.props.match.params.status === "active" &&
                          <React.Fragment>
                            <button type="button" className="ml-5 btnRed" onClick={() => this.setState({ notConfirm: true })}>
                              <span>{i18next.t("Allaction:Not Approved")}</span>
                            </button>
                            <button type="button" className="ml-5 btnSave" onClick={this.handleApprove}>
                              <span>{i18next.t("Allaction:Approve")}</span>
                            </button>
                          </React.Fragment>
                        }
                      </div>
                    </div>
                  </React.Fragment>

                }

                {this.state.notConfirm
                  && <NotConfirmManageRequest
                    open={this.state.notConfirm}
                    handleCloseModal={this.handleCloseModal}
                    procurementFormId={this.props.match.params.id}
                    handleChangeInput={this.handleChangeInput}
                  />
                }

                <div className="mb-5" />

              </div>
            </form>
          </div>

        </WrapperContent>
      </Wrapper>
    )
  }
}

export default updateFormManageRequest;
