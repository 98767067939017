import React from "react";
import {format} from "date-fns";
import ReactExport from "react-data-export";
import thLocale from "date-fns/locale/th";
import numberWithCommas from "../../../../libs/numberWithComma";
import environment from "../../../../env/environment";
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import i18next from "i18next";

const query = graphql`
  query serverExportQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const _ = require('lodash');

const text_type = [
    {
        type: "land",
        text: "ที่ดิน",
    },
    {
        type: "building" ,
        text: "อาคาร",
    },
    {
        type: "building_renovation",
        text: "ส่วนต่อเติมและปรับปรุงอาคาร"
    },
    {
        type: "office_decoration" ,
        text: "เครื่องตกแต่งสำนักงาน"
    },
    {
        type: "office_equipment",
        text: "อุปกรณ์และเครื่องใช้สำนักงาน"
    },
    {
        type: "tools",
        text: "เครื่องมือ/อุปกรณ์งานช่าง"
    },
    {
        type: "training_equipment",
        text: "เครื่องออกกำลังกาย/สันทนาการ"
    },
    {
        type: "vehicle",
        text: "ยานพาหนะ"
    },
    {
        type: "common_property",
        text: "ทรัพย์สินส่วนกลาง"
    },
    {
        type: "intangible_asset",
        text: "สินทรัพย์ไม่มีตัวตน"
    },
    {
        type: "other_asset",
        text: "สินทรัพย์อื่นๆ"
    },
    {
        type: "",
        text: "ทั้งหมด"
    }
]
class ExportAssetRegisterReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiDataSet: [],
            projectName: "",
        };
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
        this.checkTypeAsset = this.checkTypeAsset.bind(this)
    }

    checkTypeAsset(type){
        switch (type) {
            case "LAND" :
                return "อาคาร";
            case "BUILDING" :
                return "อาคาร";
            case "BUILDING_RENOVATION" :
                return "ส่วนต่อเติมและปรับปรุงอาคาร";
            case "OFFICE_DECORATION" :
                return "เครื่องตกแต่งสำนักงาน";
            case "OFFICE_EQUIPMENT":
                return "อุปกรณ์และเครื่องใช้สำนักงาน";
            case "TOOLS" :
                return "เครื่องมือ/อุปกรณ์งานช่าง";
            case "TRAINING_EQUIPMENT" :
                return "เครื่องออกกำลังกาย/สันทนาการ";
            case "VEHICLE" :
                return "ยานพาหนะ";
            case "COMMON_PROPERTY" :
                return "ทรัพย์สินส่วนกลาง";
            case "OTHER_ASSET" :
                return "สินทรัพย์อื่นๆ";
            case "INTANGIBLE_ASSET" :
                return "สินทรัพย์ไม่มีตัวตน";

            default:
                break;

        }
    }

    getDate(date){
        if(date === null){
            return "-"
        }else{
            return format(date, 'DD/MM/YYYY')
        }
    }
    
    //เปลี่ยนมาใช้export excel จากหน้าบ้าน

    // getDate(date){
    //     if(date === null){
    //         return date
    //     }else{
    //         return format(date, 'DD/MM/YYYY HH:mm:ss')
    //     }
    // }
    // handleSubmit = (event) => {
    //     this.setState({
    //         errors: null,
    //         loading: true,
    //     }, () => {
    //         Helpers.httpRequest(
    //              getApiRoot() + 'export/asset?type=' + this.props.state.type
    //             + '&search=' + this.props.state.search + '&start_date=' + this.getDate(this.props.state.start_date)
    //             + '&end_date=' + this.getDate(this.props.state.end_date),
    //             'GET',
    //         )
    //             .then((response) => response.blob())
    //             .then((blob) => {
    //                 // create blob link
    //                 const url = window.URL.createObjectURL(new Blob([blob]));
    //                 const link = document.createElement('a');
    //                 link.href = url;
    //                 link.setAttribute('download', "asset.xls");

    //                 // append to html
    //                 document.body.appendChild(link);

    //                 // download
    //                 link.click();

    //                 // remove
    //                 link.parentNode.removeChild(link);

    //                 this.setState({
    //                     loading: false
    //                 });
    //             })
    //             .catch((error) => {
    //                 error.json().then((json) => {
    //                     this.setState({
    //                         errors: json,
    //                         loading: false
    //                     });
    //                 })
    //             });
    //     });

    //     event.preventDefault();
    // };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.asset !== this.props.asset) {
            this.setData(this.props.asset)
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            this.setState({projectName: res.selfProject.name,})
        })
    }    

    switchStatus = (status) => {
        if(status === 'sold'){
            return 'ขาย'
        }else if(status === 'deteriorate'){
            return 'เสื่อมสภาพ / ใช้งานไม่ได้'
        }else if(status === 'disappear'){
            return 'สูญหาย'
        }else{
            return '-'
        }
    }

    setData(props) {
        let data = [];
        data.push([
            {
                value: "ลำดับ", 
                style: {
                    font: {bold: true} , 
                    alignment: {horizontal: "center"} , 
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "หมวดหมู่สินทรัพย์",
                style: {
                    font: {bold: true}, 
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "รหัสสินทรัพย์",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "รหัสสินทรัพย์ภายใน",
                style: {
                    font: {bold: true} ,
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ชื่อสินทรัพย์",
                style: {
                    font: {bold: true}, 
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "คำอธิบาย",
                style: {
                    font: {bold: true}, 
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "แหล่งที่จัดเก็บ",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "รหัสผู้ขาย",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ผู้ขาย",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "จำนวน",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "หน่วย",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วิธีคิดค่าเสื่อม",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "อัตราค่าเสื่อม (%)",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "อายุการใช้งาน (ปี)",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่ซื้อ",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "มูลค่าสินทรัพย์ (บาท)",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ราคาซาก",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "มีอายุการใช้งานมาแล้ว (ปี)",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "มีอายุการใช้งานมาแล้ว (เดือน)",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่เริ่มคิดค่าเสื่อม",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "ค่าเสื่อมราคาสะสม (บาท) ยอด ณ วันที่" + format(this.props.date, 'DD-MM-YYYY'),
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "มูลค่าสินทรัพย์หลังหักค่าเสื่อมราคาสะสม (บาท)",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center" , wrapText : false},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วันที่ตัดจำหน่าย",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
            {
                value: "วิธีตัดจำหน่าย",
                style: {
                    font: {bold: true},
                    alignment: {horizontal: "center"},
                    border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}} ,
                }
            },
        ])
        let order_id = 0;
        //หาราคารวมของแต่ละชนิด
        let assetEachType = _.chain(props.allAsset.edges).groupBy('node.type').map((asset,index)=> {
            let total_sum_asset_depreciate = 0.0; 
            //คำนวณค่าเสื่อม
            asset.map((asset_depreciate_list) => {
                total_sum_asset_depreciate += asset_depreciate_list.node.historicalAmount; //ราคารวมค่าเสื่อมสะสม + ค่าเสื่อมยกมา
                total_sum_asset_depreciate += _.sumBy(asset_depreciate_list.node.assetDepreciate.edges,'node.depreciatePrice')

            })
            return ({  
                total_costPrice : _.sumBy(asset,'node.costPrice'), //สินทรัพย์
                total_assetDepreciate : total_sum_asset_depreciate, //ค่าเสื่อมสะสม
                total_assetAfterDepreciate : _.sumBy(asset,'node.costPrice') - total_sum_asset_depreciate, //สินทรัพย์หลังหักค่าเสื่อมสะสม
                asset : asset,
                type : _.sumBy(asset,'node.type'),
            })
        }).value();
        let asset = {
            //ราคารวมทั้งหมด
            sum_costPrice : _.sumBy(assetEachType,'total_costPrice') , //สินทรัพย์
            sum_assetDepreciate : _.sumBy(assetEachType,'total_assetDepreciate'), //ค่าเสื่อม
            sum_assetAfterDepreciate : _.sumBy(assetEachType,'total_assetAfterDepreciate'), //สินทรัพย์หลังหักค่าเสื่อมสะสม
            assetEachType : assetEachType,
            count_list : assetEachType.length
        }
        asset.assetEachType.map((asset_each_type,index_asset_type) => {
            order_id = 0;
            asset_each_type.asset.map((asset,index) => {
                order_id += 1;
                let sum_asset_depreciate = asset.node.historicalAmount; //ราคารวมค่าเสื่อมสะสม + ค่าเสื่อมยกมา
                sum_asset_depreciate += _.sumBy(asset.node.assetDepreciate?.edges,'node.depreciatePrice')
                let diffDate = new Date(new Date() -  new Date(asset.node?.purchaseDate))
                let year = (diffDate.toISOString().slice(0, 4) - 1970)
                let month = diffDate.getMonth()
                if(index === 0){
                    data.push([
                        {
                            value: _.find(this.props.asset_type,{code: asset.node?.type?.toLowerCase()}).header_name,
                            style : {
                                alignment : {readingOrder : 2},
                                border : {left : {style : "thin"}} ,
                                font: {bold: true}
                            }
                        },
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {value :""},
                        {
                            value :"",
                            style : {
                                border : {right : {style : "thin"}} ,
                            }
                        },
                    ])
                }

                let assetAtCost = Number(asset.node?.costPrice)
                let accumDepreciation = Number(sum_asset_depreciate)
                let residualValueOfAsset = assetAtCost - accumDepreciation
                data.push([
                    {
                        value: order_id ,
                        style: {
                            alignment: {horizontal: "center"} ,
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                        
                    },
                    {
                        value: this.checkTypeAsset(asset.node?.type),
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.docNumber, 
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.internalAssetCode ? asset.node?.internalAssetCode : '-',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.name,
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.description ? asset.node?.description :'-',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.storage ? asset.node?.storage : '-',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.seller ? asset.node?.seller?.refNumber : '-',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.seller ? asset.node?.seller?.name : asset.node.sellerOther ? asset.node?.sellerOther : '-',
                        style:{
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: "1",
                        style: {
                            alignment: {horizontal: "center"},
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.nounClassifier,
                        style: {
                            alignment: {horizontal: "center"},
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: "เส้นตรง",
                        style: {
                            alignment: {horizontal: "center"},
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: numberWithCommas(asset.node?.depreciationRatePercent, '0.00'),
                        style: {
                            alignment: {horizontal: "right"},
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.lifeTimeYear ? asset.node?.lifeTimeYear : '-',
                        style: {
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.purchaseDate ? format(asset.node?.purchaseDate, 'DD/MM/YYYY', {locale: thLocale}) : '-',
                        style: {
                            alignment: {horizontal: "center"} , 
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: numberWithCommas(asset.node?.costPrice,'0.00'),
                        style: {
                            alignment: {horizontal: "right"}, 
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: numberWithCommas(asset.node?.carcassPrice),
                        style: {
                            alignment: {horizontal: "right"}, 
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: year === 0 ? '-' : year,
                        style: {
                            alignment: {horizontal: "center"} , 
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: month === 0 ? '-' : month,
                        style: {
                            alignment: {horizontal: "center"} , 
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node?.calDepreciationDate ? format(asset.node?.calDepreciationDate, 'DD/MM/YYYY', {locale: thLocale}) : '-',
                        style: {
                            alignment: {horizontal: "center"} , 
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: numberWithCommas(sum_asset_depreciate,'0.00'),
                        style: {
                            alignment: {horizontal: "right"},
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: residualValueOfAsset?.toLocaleString(undefined, {maximumFractionDigits:2}),
                        style: {
                            alignment: {horizontal: "right"},
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: asset.node.saleDate ? format(asset.node?.saleDate, 'DD/MM/YYYY', {locale: thLocale}) : '-',
                        style: {
                            alignment: {horizontal: "center"} , 
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                    {
                        value: this.switchStatus(_.lowerCase(asset.node?.saleStatus)),
                        style: {
                            alignment: {horizontal: "center"} , 
                            border : {top : {style : "thin"} ,bottom : {style : "thin"} , left : {style : "thin"} , right : {style : "thin"}}
                        }
                    },
                ])
            })

            //last row
            data.push([
                {
                    value: "",
                    style: {
                        border : {left : {style : "thin"}, top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "รวม",
                    style: {
                        alignment: {horizontal: "right"},
                        border : {right : {style : "thin"} ,top : {style : "thin"} ,bottom : {style : "thin"}},
                        font: {bold: true}
                    }
                },
                {
                    value: numberWithCommas(asset_each_type.total_costPrice),
                    style: {
                        alignment: {horizontal: "right"},
                        border : {left : {style : "thin"} , right : {style : "thin"} ,top : {style : "thin"} ,bottom : {style : "thin"} },
                        font: {bold: true}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: numberWithCommas(asset_each_type.total_assetDepreciate),
                    style: {
                        alignment: {horizontal: "right"},
                        border : {left : {style : "thin"} , right : {style : "thin"},top : {style : "thin"} ,bottom : {style : "thin"} },
                        font: {bold: true}
                    }
                },
                {
                    value: numberWithCommas(asset_each_type.total_assetAfterDepreciate),
                    style: {
                        alignment: {horizontal: "right"},
                        border : {left : {style : "thin"} , right : {style : "thin"},top : {style : "thin"} ,bottom : {style : "thin"} },
                        font: {bold: true}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
            ]);

        })

        if(props.allAsset.edges.length > 0 && asset.count_list > 1){
            data.push([
                {
                    value: "",
                    style: {
                        border : {left : {style : "thin"}, top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "รวมทั้งหมด",
                    style: {
                        alignment: {horizontal: "right"},
                        border : {right : {style : "thin"}, top : {style : "thin"}, bottom : {style : "thin"}},
                        font: {bold: true}
                    }
                },
                {
                    value: numberWithCommas(asset.sum_costPrice),
                    style: {
                        alignment: {horizontal: "right"},
                        border : {left : {style : "thin"} , right : {style : "thin"}, top : {style : "thin"}, bottom : {style : "thin"}},
                        font: {bold: true}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: numberWithCommas(asset.sum_assetDepreciate),
                    style: {
                        alignment: {horizontal: "right"},
                        border : {left : {style : "thin"} , right : {style : "thin"}, top : {style : "thin"}, bottom : {style : "thin"}},
                        font: {bold: true}
                    }
                },
                {
                    value: numberWithCommas(asset.sum_assetAfterDepreciate),
                    style: {
                        alignment: {horizontal: "right"},
                        border : {left : {style : "thin"} , right : {style : "thin"}, top : {style : "thin"}, bottom : {style : "thin"}},
                        font: {bold: true}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
                {
                    value: "",
                    style: {
                        border : {top : {style : "thin"} , bottom : {style : "thin"}}
                    }
                },
            ]);
        }
        let multiDataSet = [
            {
                columns: [                
                        {title: this.state.projectName, style: { font: { sz: "11.5" } }},
                ],    
                data: [
                    [
                        {
                            value:  this.props.reportType === 'register' ? "รายงานทะเบียนสินทรัพย์":"รายงานทะเบียนสินทรัพย์ตัดจำหน่าย",
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ],
                    [   {
                            value: "เรียงตามหมวด : " +  _.find(text_type,{type: this.props.state.type}).text ,
                            style: {font: {sz: "11.5", bold: true}}
                        },
                        
                    ],
                    [
                        {
                            value: "ณ วันที่ " + this.getDate(this.props.state.end_date),
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ]
                ]

            },
            {  
                columns: [
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 250}},
                    {title: "", width: {wpx: 100}},
                    {title: "", width: {wpx: 150}},
                    {title: "", width: {wpx: 150}},
                    {title: "", width: {wpx: 100}},
                    {title: "", width: {wpx: 100}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 100}},
                    {title: "", width: {wpx: 120}},
                    {title: "", width: {wpx: 120}},
                    {title: "", width: {wpx: 100}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 200}},

                ],
                data
            },
        ];
        this.setState({multiDataSet})
    }

    buttonElement() {
        return (
            <button type="button" className="btn print float-right" disabled={this.state.loading}>
                {this.state.loading ?
                    <span>
                    <span
                        className="spinner-border spinner-border-sm align-middle mr-2"/> {i18next.t("Allaction:Preparing information")}</span> :
                    <span>
                    <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                    {i18next.t("Allaction:Print")}
                    </span>
                }
            </button>
        );
    }

    render() {

        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"asset_" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}
                >
                <ExcelSheet
                    dataSet={this.state.multiDataSet}
                    name="Asset"
                />
            </ExcelFile>
        )
    }

    // render() {

    //     return (
    //         <form onSubmit={this.handleSubmit}>
    //             <button type="submit" className="btn print float-right"
    //                     disabled={this.state.loading}>
    //                 {this.state.loading ?
    //                     <span>
    //                     <span
    //                         className="spinner-border spinner-border-sm align-middle mr-2"/> {i18next.t("Allaction:Preparing information")}</span> :
    //                     <span>
    //                     <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
    //                     {i18next.t("Allaction:Print")}
    //                     </span>
    //                 }
    //             </button>
    //         </form>
    //     )
    // }
}

export default ExportAssetRegisterReport;
