/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoomStatus = "APPROVE" | "FINISHED" | "NOT_APPROVE" | "OPEN" | "%future added value";
export type complaintListQueryVariables = {|
  first?: ?number,
  last?: ?number,
  status?: ?string,
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
|};
export type complaintListQueryResponse = {|
  +allRooms: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +numberNotRead: ?number,
        +residential: {|
          +isUserActivate: boolean,
          +name: string,
        |},
        +name: string,
        +detail: ?string,
        +status: RoomStatus,
        +updated: any,
        +note: ?string,
        +handle: {|
          +tenant: ?{|
            +firstName: string,
            +lastName: ?string,
            +phone: ?string,
          |},
          +juristic: ?{|
            +firstName: string,
            +lastName: string,
            +phone: string,
          |},
        |},
      |}
    |}>,
  |}
|};
export type complaintListQuery = {|
  variables: complaintListQueryVariables,
  response: complaintListQueryResponse,
|};
*/


/*
query complaintListQuery(
  $first: Int
  $last: Int
  $status: String
  $search: String
  $start_date: DateTime
  $end_date: DateTime
) {
  allRooms(roomType: "contact", first: $first, last: $last, status: $status, search: $search, startDate: $start_date, endDate: $end_date) {
    totalCount
    edges {
      node {
        id
        numberNotRead
        residential {
          isUserActivate
          name
          id
        }
        name
        detail
        status
        updated
        note
        handle {
          tenant {
            firstName
            lastName
            phone
            id
          }
          juristic {
            firstName
            lastName
            phone
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Literal",
    "name": "roomType",
    "value": "contact"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberNotRead",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isUserActivate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detail",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v19 = [
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/)
],
v20 = [
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "complaintListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "RoomNodeConnection",
        "kind": "LinkedField",
        "name": "allRooms",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "kind": "LinkedField",
                    "name": "handle",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": (v19/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JuristicNode",
                        "kind": "LinkedField",
                        "name": "juristic",
                        "plural": false,
                        "selections": (v19/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "complaintListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "RoomNodeConnection",
        "kind": "LinkedField",
        "name": "allRooms",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "kind": "LinkedField",
                    "name": "handle",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JuristicNode",
                        "kind": "LinkedField",
                        "name": "juristic",
                        "plural": false,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "917e17d4ce30d07d77e6d8631b740242",
    "id": null,
    "metadata": {},
    "name": "complaintListQuery",
    "operationKind": "query",
    "text": "query complaintListQuery(\n  $first: Int\n  $last: Int\n  $status: String\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n) {\n  allRooms(roomType: \"contact\", first: $first, last: $last, status: $status, search: $search, startDate: $start_date, endDate: $end_date) {\n    totalCount\n    edges {\n      node {\n        id\n        numberNotRead\n        residential {\n          isUserActivate\n          name\n          id\n        }\n        name\n        detail\n        status\n        updated\n        note\n        handle {\n          tenant {\n            firstName\n            lastName\n            phone\n            id\n          }\n          juristic {\n            firstName\n            lastName\n            phone\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '227b50bbbcd028aaa63f7cab05115bda';

module.exports = node;
