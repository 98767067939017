import "./style/creditNoteSelectTransaction.scss"
import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Redirect } from 'react-router';
import { fetchQuery } from 'relay-runtime';
import Swal from "sweetalert2";
import numberWithCommas from "../../../libs/numberWithComma";
import { Translation } from "react-i18next";
import fetchCnInvoice from './fetchCnInvoice'
import Loading from "../../../libs/loading"
import checkParseFloat from "../../../libs/checkParseFloat"
import i18next from "i18next";

const _ = require('lodash');

const query = graphql`
    query creditNoteSelectTransactionQuery($id: ID!) {
        invoiceViewer{
            allInvoiceByStatus(statusList:["active","overdue","partial_payment"],contact_Id: $id, order: "doc_number"){
                totalCount
                edges{
                    node{
                        id
                        docNumber
                        total
                        issuedDate
                        dueDate
                        status
                        taxType
                        transaction(status_In: "active, overdue, partial_payment"){
                            totalCount
                            edges{
                                node{
                                    id
                                    description
                                    unitItems
                                    discount
                                    price
                                    preTaxAmount
                                    vat
                                    paid
                                    whtRate
                                    whtRatePercent
                                    total
                                    productAndService{
                                    productCode
                                    chartOfAccount{
                                        id
                                        chartOfAccountCode
                                    }
                                    }
                                    invoice{
                                        docNumber
                                        issuedDate
                                    }
                                    receiptDepositTransactionUsed {
                                        edges{
                                            node{
                                                id
                                                amount
                                            }
                                        }
                                    }
                                    creditNoteTransaction{
                                        edges{
                                            node{
                                                id
                                                price
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
`;


class CreditNoteSelectTransaction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invoice: false,
            all_total: 0.0,
            loading: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.calSummaryAllTotal = this.calSummaryAllTotal.bind(this);
        this.rowSelect = this.rowSelect.bind(this);
    }

    componentWillMount() {
        fetchQuery(environment, query, { id: this.props.contactList })
            .then(response_data => {
                if (_.isObject(response_data.invoiceViewer)) {
                    let data_set = _.cloneDeep(response_data);
                    data_set.invoiceViewer.allInvoiceByStatus.edges.forEach(function (data) {
                        _.set(data, 'node.select', "");
                        data.node.transaction.edges.forEach(function (transaction) {
                            _.set(transaction, 'node.discount_transaction', transaction.node.discount);
                            _.set(transaction, 'node.select', "");
                            _.set(transaction, 'node.amount_pay', "");
                            _.set(transaction, 'node.discount', 0);

                            let amount = (parseFloat(_.get(transaction, 'node.total', 0))) + parseFloat(_.get(transaction, 'node.discount', 0));
                            let total = amount - parseFloat(_.get(transaction, 'node.paid', 0));
                            let before_vat = total;

                            if (data.node.taxType !== "NO_TAX") {
                                before_vat = total * 100 / (100 + (parseFloat(_.get(transaction, 'node.vat') || 0)))
                            }

                            let wht = before_vat * (parseFloat(_.get(transaction, 'node.whtRatePercent') || 0) / 100);
                            let pay = total - wht;

                            _.set(transaction, 'node.balance_vat_wht', parseFloat(pay.toFixed(2)));

                        });

                    });
                    this.setState({
                        invoice: data_set,
                        loading: true
                    });
                }

            });

    }

    handleInputChange(e) {

        let event = null;
        try {
            event = _.cloneDeep(e);
        } catch (error) {
            event = _.clone(e);
        }
        let value = event.target.value;



        let discount = event.target.dataset.discount;

        this.setState(prevState => {
            if (event.target.type === 'checkbox') {
                value = !_.get(prevState, event.target.name);
                let lastIndex = event.target.name.lastIndexOf(".select");
                let transaction_name = event.target.name.substring(0, lastIndex);
                let amount_pay_name = transaction_name + ".amount_pay";
                let amount_pay_value = "";
                let type_select = event.target.name.split(".")[1];
                if (value && type_select === "invoiceViewer") {
                   
                    if (_.get(prevState, amount_pay_name)) {
                        amount_pay_value = _.get(prevState, amount_pay_name);
                    } else {
                        amount_pay_value = _.get(prevState, transaction_name + ".balance_vat_wht");
                    }
                    _.set(prevState, amount_pay_name, amount_pay_value);
                }

                if (event.target.dataset.inputType) {
                    _.set(prevState, event.target.name, value);
                    _.forOwn(_.get(prevState, event.target.dataset.inputType), function (transaction, key) {
                        _.set(transaction, 'node.select', value);
                        if (value) {
                            if (_.get(transaction, "node.amount_pay")) {
                                _.set(transaction, 'node.amount_pay', _.get(transaction, "node.amount_pay"));
                            }
                            else if (type_select === "contact" && _.get(transaction, "node.balance")) {
                                if (_.get(transaction, "node.balance") > prevState.all_total) {
                                    _.set(transaction, 'node.amount_pay', prevState.all_total);
                                } else {
                                    _.set(transaction, 'node.amount_pay', _.get(transaction, "node.balance"));
                                }
                            }
                            else {
                                _.set(transaction, 'node.amount_pay', _.get(transaction, "node.balance_vat_wht"));
                            }
                        } else {
                            _.set(transaction, 'node.amount_pay', "");
                            _.set(transaction, 'node.discount', 0);
                        }
                    });
                    return _.set(_.cloneDeep(prevState), prevState)
                }
            }
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        }, () => {
            this.calSummaryAllTotal();

            if (discount) {
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        discount + ".amount_pay",
                        parseFloat((parseFloat(_.get(this.state, discount + ".balance_vat_wht")) - parseFloat(value)).toFixed(2))
                    );
                }, () => this.calSummaryAllTotal())
            }
        });
    }

    rowSelect(name) {
        let event = {
            target: {
                name: name,
                type: 'checkbox',
                dataset: {}
            }
        };
        this.handleInputChange(event)
    }

    calSummaryAllTotal() {
        let total = 0.0;

        this.state.invoice.invoiceViewer.allInvoiceByStatus.edges.forEach(function (data) {
            data.node.transaction.edges.forEach(function (transaction) {

                if (transaction.node.total >= transaction.node.balance_vat_wht && transaction.node.select === true) {
                    if (transaction.node.select && transaction.node.amount_pay) {
                        total += parseFloat(transaction.node.amount_pay)
                    }
                }
                else if (transaction.node.total < transaction.node.balance_vat_wht && transaction.node.select === true) {
                    total += parseFloat(transaction.node.total)
                }

            });
        });

        total = total < 0 ? 0 : total;
        total = Math.round(((total) + Number.EPSILON) * 100) / 100;
        this.setState({ all_total: total })



    }


    async onSubmit(e) {
        e.preventDefault();
        let selected_list = [];
        let invoice_list = [];
        let invoice_list_check = [];
        let invoicsum = 0;
        let invoicePaid = false; //มีการจ่าย iv ซ้ำ
        let allTransactionId = []

        await fetchQuery(environment, fetchCnInvoice, { id: this.props.contactList }).then((data) => {
            _.forEach(data.invoiceViewer.allInvoiceByStatus.edges, transaction => {
                if (transaction.node.transaction.edges.length > 0) {
                    _.forEach(transaction.node.transaction.edges, transaction_list => {
                        allTransactionId.push(transaction_list.node.id)
                    })
                }
            })
        })


        this.state.invoice.invoiceViewer.allInvoiceByStatus.edges.forEach(function (data) {
            data.node.transaction.edges.forEach(function (transaction) {
                if (transaction.node.select && (transaction.node.amount_pay || transaction.node.discount)) {

                    if (transaction.node.select === true && transaction.node.total >= transaction.node.balance_vat_wht) {

                        invoicsum += parseFloat(transaction.node.amount_pay)

                        selected_list.push({
                            transaction_id: transaction.node.id,
                            amount_pay: transaction.node.amount_pay,
                            discount: transaction.node.discount,
                            vat: transaction.node.vat,
                            transaction: transaction.node,
                            chartOfAccountReceive:transaction.node.productAndService?.chartOfAccount?.id
                        })

                        if(!invoice_list_check.includes(transaction.node.invoice.docNumber) ){
                            invoice_list_check.push(transaction.node.invoice.docNumber)
                            invoice_list.push({
                                "issuedDate": transaction.node.invoice.issuedDate,
                                "docNumber": transaction.node.invoice.docNumber
                            })
                        }

                    }
                    else if (transaction.node.select === true && transaction.node.total <= transaction.node.balance_vat_wht) {

                        invoicsum += parseFloat(transaction.node.amount_pay)


                        selected_list.push({
                            transaction_id: transaction.node.id,
                            amount_pay: transaction.node.total,
                            discount: transaction.node.discount,
                            vat: transaction.node.vat,
                            transaction: transaction.node,
                            chartOfAccountReceive:transaction.node.productAndService?.chartOfAccount?.id
                        })

                        if(!invoice_list_check.includes(transaction.node.invoice.docNumber) ){
                            invoice_list_check.push(transaction.node.invoice.docNumber)
                            invoice_list.push({
                                "issuedDate": transaction.node.invoice.issuedDate,
                                "docNumber": transaction.node.invoice.docNumber
                            })
                        }
                        
                    }
                }
            });

        });


        invoicsum = checkParseFloat(invoicsum)


        if (selected_list.length > 0 && this.state.all_total >= 0 && invoicsum > 0) {
            _.forEach(selected_list, select => {
                if (!allTransactionId.includes(select.transaction_id)) { //false  รายการถูกชำระแล้ว
                    invoicePaid = true
                }
            })
            if (this.checkVatRd(selected_list)) {
                let checkAmountPay = true
                selected_list.forEach((list) => {
                    let check = parseFloat(list.amount_pay)
                    if (check === "" || check === 0) {
                        checkAmountPay = false
                    }
                })
                if (checkAmountPay && !invoicePaid) {
                    this.props.updateSelectInvoiceList(selected_list , invoice_list);
                    this.setState({ on_submit: true })
                } else if (invoicePaid) {
                    Swal.fire('Error!', i18next.t("TransactionChannelSelect:Selected Paid Items"), 'warning')
                }
                else {
                    Swal.fire('Error!', i18next.t("TransactionChannelSelect:Specify Correct Amount"), 'warning')
                }

            } else {
                Swal.fire('Error!', i18next.t("TransactionChannelSelect:Invalid Selection"), 'warning')
            }
        } else if (this.state.all_total < 0) {
            Swal.fire('Error!', i18next.t("TransactionChannelSelect:Invalid Selection, Check Again"), 'warning')
        } else {
            Swal.fire('Error!', i18next.t("TransactionChannelSelect:Select Correct Item and Amount"), 'warning')
        }

    }

    checkVatRd(selected_list) {
        let have_use_rd = false;
        selected_list.forEach((transaction) => {
            if (atob(transaction.transaction_id).split(":")[0] === "ReceiptDepositTransactionNode") {
                have_use_rd = true
            }
        });

        let vat = '';
        let case_vat_match = true;

        if (have_use_rd) {
            selected_list.forEach((transaction) => {
                if (transaction.hasOwnProperty('vat')) {
                    if (vat === '') {
                        vat = transaction.vat
                    } else {
                        if (vat !== transaction.vat) {
                            case_vat_match = false
                        }
                    }
                }
            });
        }

        return case_vat_match
    }

    calAmount(transaction, taxType) {
        let amount = parseFloat(transaction.total)
        if (taxType === "EXCLUDE_TAX") {
            amount = amount * 1.07
        }

        return numberWithCommas(amount)
    }

    render() {

        if (this.state.on_submit) {
            return <Redirect to="/accounting/income/receive/form/create" />;
        }

        if (this.props.contactList.length > 0) {
            return (
                <form onSubmit={this.onSubmit}>
                    <div className="container-fluid box">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/income/receive/form/select_customer">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>{t => t('receiveSelectInvoice:Overdue list')}</Translation>
                                </h3>

                            </div>


                        </div>

                        {
                            this.state.invoice && this.state.loading ? (
                                <div className="content-inner" id="receive-select-invoice">
                                    {
                                        this.state.invoice &&
                                        this.state.invoice.invoiceViewer.allInvoiceByStatus.edges.map((invoice, invoice_index) => {
                                            return (
                                                <div className="table-responsive fade-up mt-3" key={invoice.node.id}>
                                                    <div>{invoice.id}</div>
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={5}>
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            name={"invoice.invoiceViewer.allInvoiceByStatus.edges[" + invoice_index + "].node.select"}
                                                                            data-input-type={"invoice.invoiceViewer.allInvoiceByStatus.edges[" + invoice_index + "].node.transaction.edges"}
                                                                            onChange={this.handleInputChange}
                                                                            checked={this.state.invoice.invoiceViewer.allInvoiceByStatus.edges[invoice_index].node.select}
                                                                            id={"invoice-" + invoice.node.id}
                                                                            className="mr-2"
                                                                            data-test={"invoice"}
                                                                        />
                                                                        {invoice.node.docNumber}
                                                                        <span className="ml-3">
                                                                            <Translation>{t => t('receiveSelectInvoice:Date of issue')}</Translation> {format(invoice.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}
                                                                        </span>
                                                                    </label>
                                                                </th>

                                                            </tr>
                                                            <tr className="bg-light">
                                                                <td width="50"><Translation>{t => t('receiveSelectInvoice:List')}</Translation></td>
                                                                <td width="150"><Translation>{t => t('receiveSelectInvoice:Description')}</Translation></td>
                                                                <td width="50" className="text-right"><Translation>{t => t('receiveSelectInvoice:Amount')}</Translation></td>
                                                                <td width="50" className="text-right"><Translation>{t => t('receiveSelectInvoice:Overdue')}</Translation></td>
                                                                <td width="50" className="text-right"><Translation>{t => t('creditNoteSelectTransaction:desired debt reduction amount')}</Translation></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {invoice.node.transaction.edges.map((transaction, transaction_index) => {

                                                                return (

                                                                    <tr key={transaction.node.id} onDoubleClick={() => this.rowSelect("invoice.invoiceViewer.allInvoiceByStatus.edges[" + invoice_index + "].node.transaction.edges[" + transaction_index + "].node.select")}>
                                                                        <td>
                                                                            <input type="checkbox"
                                                                                name={"invoice.invoiceViewer.allInvoiceByStatus.edges[" +
                                                                                    invoice_index + "].node.transaction.edges[" + transaction_index + "].node.select"}
                                                                                onChange={this.handleInputChange}
                                                                                checked={this.state.invoice.invoiceViewer.allInvoiceByStatus.edges[invoice_index].node.transaction.edges[transaction_index].node.select}
                                                                                data-test={"invoice"}
                                                                            />
                                                                        </td>
                                                                        <td>{transaction.node.description.replace(/ *\[[^)]*\] */g, " ")}</td>
                                                                        <td className="text-right">
                                                                            {this.calAmount(transaction.node, invoice.node.taxType)}
                                                                        </td>

                                                                        <td className="text-right text-paid">
                                                                            {/* ยอดค้างชำระ */}
                                                                            {numberWithCommas(transaction.node.total < transaction.node.balance_vat_wht ? transaction.node.total - transaction.node.discount : transaction.node.balance_vat_wht)}

                                                                        </td>

                                                                        <td className="text-right" style={{ width: 50 }}>
                                                                            {/* ยอดที่ต้องการชำระ */}
                                                                            <input type="number" onChange={this.handleInputChange}
                                                                                className="form-control text-right"
                                                                                step="0.01"
                                                                                max={
                                                                                    transaction.node.total > transaction.node.balance_vat_wht ?
                                                                                        parseFloat((transaction.node.total - transaction.node.paid - transaction.node.discount).toFixed(2)) :
                                                                                        parseFloat((transaction.node.total - transaction.node.discount).toFixed(2))
                                                                                }
                                                                                name={"invoice.invoiceViewer.allInvoiceByStatus.edges[" +
                                                                                    invoice_index + "].node.transaction.edges[" + transaction_index + "].node.amount_pay"}
                                                                                value={transaction.node.total < transaction.node.balance_vat_wht ?
                                                                                    parseFloat((transaction.node.total).toFixed(2) + (transaction.node.total * transaction.node.vat / 100 || 0)) : this.state.invoice.invoiceViewer.allInvoiceByStatus.edges[invoice_index].node.transaction.edges[transaction_index].node.amount_pay}
                                                                                
                                                                            />

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        }
                                        )
                                    }

                                    {
                                        this.state.invoice.length === 0 &&
                                        <Loading />
                                    }

                                    <div className="row mt-4">
                                        <div className="col text-right">
                                            <div className="float-right p-4 bg-light text-dark">
                                                <span className="float-left"><Translation>{t => t('creditNoteCreate:Debt Total reduction amount')}</Translation></span>
                                                <span
                                                    className="float-right ml-4">{numberWithCommas(this.state.all_total, '0.00')} <Translation>{t => t('receiveSelectInvoice:Baht')}</Translation></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">

                                        <div className="col text-right">
                                            <div className="btn-group mr-2">
                                                <Link to="/accounting/income/credit-note/form/select_customer">
                                                    <button type="button" className="btn btn-secondary add">
                                                        <Translation>{t => t('receiveSelectInvoice:Cancel')}</Translation>
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="btn-group mr-2">
                                                <button type="submit" className="btn btn-primary add">
                                                    <Translation>
                                                        {
                                                            t =>
                                                                <span>{t('accounting:add_credit_note_transaction')}</span>

                                                        }
                                                    </Translation>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                                <div><Loading /></div>
                        }

                    </div>
                </form>
            )
        }
        return <Redirect to="/accounting/income/receive/list/all" />;
    }
}

export default CreditNoteSelectTransaction;
