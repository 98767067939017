import React, {Component} from 'react'
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import Loading from '../../libs/loading';

const contactSummaryReceive = graphql`
query contactSummaryReceiveQuery($contactId: String, $status: String) {
    countReceiveByStatus(contactId:$contactId, status:$status)
    summaryReceiveByStatus(contactId:$contactId, status:$status)
  
}
`;

class ContactSummaryReceive extends Component{

    render(){
        return (
            <div className="card-body">
                <QueryRenderer
                    environment={environment}
                    query={contactSummaryReceive}
                    variables={{"contactId": this.props.contact_id, "status": 'active'}}
                    render={({error, props}) => {
                        if (error) {
                            return <div>{error.message}</div>;
                        } else if (props) {
                            return (
                                <React.Fragment>
                                    <p className="card-text">รอชำระ {props.countReceiveByStatus != null ? props.countReceiveByStatus : '0'} รายการ รวม</p>
                                    <h2 className="card-title text-right">{props.summaryReceiveByStatus != null ? props.summaryReceiveByStatus : '0'}</h2>
                                </React.Fragment>
                            );
                        }
                        return <Loading/>
                    }}
                />
                  <QueryRenderer
                    environment={environment}
                    query={contactSummaryReceive}
                    variables={{"contactId": this.props.contact_id, "status": 'overdue'}}
                    render={({error, props}) => {
                        if (error) {
                            return <div>{error.message}</div>;
                        } else if (props) {

                            return (
                                <React.Fragment>
                                     <p className="card-text">เกินเวลาชำระทั้งหมด
                                        <span className="color"> {props.countReceiveByStatus != null ? props.countReceiveByStatus: '0'} </span>รายการ รวม
                                    </p>
                                    <h2 className="card-title text-right color">{props.summaryReceiveByStatus != null ? props.summaryReceiveByStatus : '0'}</h2>
                                </React.Fragment>
                            );
                        }
                        return <Loading/>
                    }}
                />


            </div>
        )
}

}
export default ContactSummaryReceive
