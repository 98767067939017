import React, {Component} from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../env/environment";
import {QueryRenderer} from "react-relay";
import SearchSelect from "./searchSelect";
import _ from "lodash";
import {Translation} from "react-i18next";
import Loading from "./loading"

const query = graphql`
    query selectPaymentTypeQuery{
        paymentGatewaySettingList(paymentType_In:"twoctwop,deeplink"){
            edges{
              node{
                id
                paymentType
                fee
                bankAccount{
                    id
                    docNumber
                    accountNumber
                    accountName
                    accountType
                    receivePayment
                    makePayment
                    bankName
                    status
                }
              }
            }
        }
    }
`;

class SelectPaymentType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentName: ""
        };
        this.paymentType = this.paymentType.bind(this)

    }

    paymentType(e) {
        let value = e.target.value;

        this.setState({[e.target.name]: value});

        let event = {
            target: {
                name: this.props.name,
                value: value
            }
        };
        
        this.props.handleInputChange(event);
    }

    render() {
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="col">
                            <p>{t('paymentGateway:payment_type')}</p>
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                render={({error, props}) => {
                                    if (error) {
                                        return (
                                            <div className="alert alert-danger"
                                                role="alert">{error.message}</div>
                                        );
                                    } else if (props) {
    
                                        let payment_gateway = _.cloneDeep(props.paymentGatewaySettingList.edges);
                                         _.set(payment_gateway,_.findIndex(payment_gateway,{node:{paymentType:'TWOCTWOP'}}),{node:{paymentType:'2C2P'}})

                                        return <SearchSelect onChange={this.paymentType}
                                                            value={this.state.paymentName}
                                                            name="paymentName"
                                                            placeholder="เพย์เมนท์เกตเวย์"
                                                            queryObject={payment_gateway}
                                                            keyOfValue="paymentType" require={true}
                                                            keyOfLabel="paymentType:bankAccount.bankName:bankAccount.accountNumber"/>

                                    }
                                    return <Loading/>
                                }}
                            />
                        </div>
        
                        {/* {
                            this.state.bankName === 'อื่นๆ' &&
                            <div className="col align-self-end">
                                <input type="text" name={this.props.name} className="form-control"
                                    id="bankName" value={this.props.value}
                                    onChange={this.props.handleInputChange}
                                    placeholder="ระบุธนาคาร"
                                    required
                                />
                            </div>
                        } */}
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default SelectPaymentType