import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import AdvanceDetail from "./advanceDetail";
import AdvanceRecord from "./advanceRecord";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperAdvanceQuery($id: ID!,$ref_transaction: String) {
        advance(id: $id) {
            id
            issuedDate
            dueDate
            docNumber
            description
            withdrawer
            amount
            status
            voidRemark
            cashTransaction {
                edges {
                    node {
                        id
                        price
                    }
                }
            }
            bankAccountTransaction {
                edges {
                    node {
                        id
                        price
                        date
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            chequeTransaction {
                edges {
                    node {
                        id
                        date
                        chequeNumber
                        bankAccount {
                            id
                            branch
                            accountNumber
                            accountName
                            bankName
                        }
                        price
                    }
                }
            }
            advancePettyCashChannel{
                edges{
                    node{
                        id
                        price
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }

                    }
                }
            }
        }
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        allAccountRecordGroup(refTransaction: $ref_transaction) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator issuedDate
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
        userConfig {
            id languages
        }
    }
`;

class wrapperAdvance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type: 'manuscript'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Payment Voucher</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>
                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        <div className="d-flex justify-content-center mt-3">
                            {/* <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeAll"
                                       onChange={this.handleInputChange}
                                       value="all"
                                       checked={this.state.check_type === 'all'}/>
                                <label className="form-check-label" htmlFor="checkTypeAll">
                                    ทั้งหมด
                                </label>
                            </div> */}
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeManuscript"
                                       value="manuscript"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'manuscript'}/>
                                <label className="form-check-label" htmlFor="checkTypeManuscript">
                                    ต้นฉบับ
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeCopy"
                                       value="copy"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'copy'}/>
                                <label className="form-check-label" htmlFor="checkTypeCopy">
                                    สำเนา
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id: this.props.match.params.id,
                        ref_transaction: this.props.match.params.ref_transaction
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        }else if(props){
                            let count_page = props.allAccountRecordGroup.edges.length/10;
                            //ยังไม่ได้เขียนกรณี allaccount > ตารางหน้าสอง
                            return (
                                <React.Fragment>
                                    <AdvanceDetail selfProject = {props.selfProject}
                                        allAccountRecordGroup = {props.allAccountRecordGroup}
                                        check_type={"manuscript"}
                                        pageNumber={1 + "manuscript" + atob(props.advance.id).split(':')[1]}
                                        end_page={1}
                                        current_page={1}
                                        key= {'advance'+1}
                                        advance = {props.advance}
                                        count_page = {count_page}
                                        languages={props.userConfig.languages}
                                    />
                                    {count_page > 1 && 
                                        <AdvanceRecord selfProject = {props.selfProject}
                                            allAccountRecordGroup = {props.allAccountRecordGroup}
                                            check_type={"manuscript"}
                                            pageNumber={1 + "manuscript" + atob(props.allAccountRecordGroup.edges[0].node.id).split(':')[1]}
                                            end_page={2}
                                            current_page={2}
                                            key= {'advance'+ 2}
                                            advance = {props.advance}
                                            count_page = {count_page}
                                            languages={props.userConfig.languages}
                                        />
                                    }
                                </React.Fragment>
                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default wrapperAdvance;
