import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next'
import StockTopMenuNavigation from '../stockTopMenuNavigation'
import StockMovementReportTable from './stockMovementReportTable'

import './style/stockMovementReport.scss'

export default class stockMovementReportList extends Component {
  render() {
    return (
      <Wrapper>
        <Sidebar/>
        <Header/>
        <WrapperContent disabledOverflowX={true}>
          <StockTopMenuNavigation mini={true}/>
          <div className="container-fluid box" id="stockMovementReportList">
          <StockMovementReportTable/>
          </div>
        </WrapperContent>
      </Wrapper>
    )
  }
}
