import React, {Component} from "react";
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import Loading from '../../../../libs/loading';
import { QueryRenderer } from "react-relay";
import CreditNoteReportPDFRenderTable from "./creditNoteReportPDFRenderTable";
import './creditNoteReportPDF.scss';

const query = graphql`
  query creditNoteReportPDFQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $customer_type: String
    $status: String
    $contact: String
    $order: String
  ) {
    creditNoteViewer {
      allCreditNote(
        first: $first
        last: $last
        search: $search
        startDate: $start_date
        endDate: $end_date
        customerType: $customer_type
        status: $status
        contact: $contact
        order: $order
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }

        edges {
          node {
            id
            docNumber
            issuedDate
            price
            status
            version
            payGroup
            contact {
              refNumber
              name
              residential {
                name
              }
              firstName
              lastName
              typeOfContact
            }
            transaction {
              invoice {
                docNumber
                issuedDate
              }
              description
              total
            }
            receive {
              docNumber
            }
            creditNoteTransactionRecord{
              edges{
                node{
                  id
                  price
                  status
                  description
                  returnChartOfAccount {
                    id
                    chartOfAccountCode
                    name
                  }
                  transaction {
                    id
                    description
                    total
                    productAndService {
                      productCode
                      name
                      chartOfAccount {
                        chartOfAccountCode
                        name
                      }
                    }
                    invoice {
                      docNumber
                      issuedDate
                    }
                  }
                }
              }
            }
          }
        }
      }
      summaryCreditNote(
        search: $search
        startDate: $start_date
        endDate: $end_date
        customerType: $customer_type
        status: $status
        contact: $contact
      )
    }
  }
`;

class CreditNoteReportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data,
        })
    }

    render() {
        return (
            <React.Fragment>
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }

                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Credit Note Report</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div id="creditNotePDF">
                            <div className="print-top-menu">
                                    <div className="logo">
                                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                    </div>
                                    <div className="print" onClick={() => window.print()}>
                                        PRINT
                                    </div>
                            </div>                            
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                cacheConfig={{ use_cache: false }}
                                variables={{
                                first: null,
                                last: null,
                                start_date: this.state.data.start_date,
                                end_date: this.state.data.end_date,
                                search: this.state.data.search,
                                status: this.state.data.status,
                                customer_type: this.state.data.customer_type,
                                contact: this.state.data.contact,
                                order: "doc_number",
                                }}
                                render={({ error, props }) => {

                                if (props) {
                                    let contactName = 'ทั้งหมด';
                                    let customerType = 'ทั้งหมด';
                                    let status = 'ทั้งหมด';
                                    if(this.state.data.contact !== "") {
                                        if(props.creditNoteViewer?.allCreditNote.edges[0].node.contact.typeOfContact === "RESIDENTIAL"){
                                            contactName = props.creditNoteViewer.allCreditNote.edges[0].node.contact.refNumber
                                            +" "+props.creditNoteViewer.allCreditNote.edges[0].node.contact.firstName
                                            +" "+props.creditNoteViewer.allCreditNote.edges[0].node.contact.lastName
                                        } 
                                        if(props.creditNoteViewer?.allCreditNote.edges[0].node.contact.typeOfContact === "SUPPLIER"){
                                            contactName = props.creditNoteViewer.allCreditNote.edges[0].node.contact.refNumber
                                            +" "+props.creditNoteViewer.allCreditNote.edges[0].node.contact.name                                        
                                        }
                                    }
                                    switch (this.state.data.customer_type) {
                                        case "residential":
                                          customerType = "เฉพาะเจ้าของร่วม/สมาชิก";
                                          break;
                                        case "supplier":
                                          customerType = "เฉพาะลูกหนี้";
                                          break;      

                                        default:
                                          break;
                                    }    
                                    switch (this.state.data.status) {
                                        case "paid":
                                          status = "ชำระแล้ว";
                                          break;
                                        case "wait":
                                          status = "ยังไม่ชำระ";
                                          break;      
                                        case "void":
                                          status = "รายการที่ยกเลิก";
                                          break;      

                                        default:
                                          break;
                                    }    
                                    return (
                                        <CreditNoteReportPDFRenderTable 
                                            props={props}
                                            start_date={this.state.data.start_date}
                                            end_date={this.state.data.end_date}
                                            contactName={contactName}
                                            customerType={customerType}
                                            status={status}
                                        />
                                    );
                                } else {
                                    return <Loading/>
                                }
                                }}
                            />
                        </div>    
            </React.Fragment>
        )
    }
}

export default CreditNoteReportPDF;