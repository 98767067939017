import React, { forwardRef, useState } from "react";
import "../../styles/commonArea.scss";
import { format, setHours, setMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import "../../../../libs/styles/customDatePickerWidth.scss";
import { Translation } from "react-i18next";
const Rights = ({
  timeToBookingCustomList,
  typeTobooking,
  addRound,
  subRound,
  create,
}) => {
  const [state, setstate] = useState({
    start_round_time: new Date(),
    end_round_time: new Date(),
  });
  const [show, setshow] = useState(false);
  const ButtonDate = forwardRef(({ value, onClick, disabled, date }, ref) => (
    <button
      type="button"
      className="date form-control mt-2 booking-form"
      style={{ width: "100%" }}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    >
      {value}
      {!date && (
        <div
          className="calendars"
          style={{
            position: "absolute",
            right: 0,
            marginRight: 8,
          }}
        >
          <span
            style={{
              color: "#0382FA",
              fontFamily: "medium",
            }}
          >
            <Translation>{t=> t("newBookingSingha_Create_step1:Choose time")}</Translation>
          </span>
        </div>
      )}
      {date && (
        <div
          className="calendars"
          style={{
            position: "absolute",
            right: 0,
            marginRight: 8,
          }}
        >
          <img
            className="icon"
            src={process.env.PUBLIC_URL + "/images/icons/calendar-outline.png"}
            alt="calendars"
          />
        </div>
      )}
    </button>
  ));
  // console.log(timeToBookingCustomList.length);
  let letng = timeToBookingCustomList.length - 1
  // console.log(timeToBookingCustomList);
  // let StartTime = create ? timeToBookingCustomList[letng]?.start_round_time : new Date(`2023-01-01T${timeToBookingCustomList[letng]?.start_round_time}`)
  let EndTime = create ? timeToBookingCustomList[letng]?.end_round_time : new Date(`2023-01-01T${timeToBookingCustomList[letng]?.end_round_time}`)
  // console.log(state.start_round_time);
  return (
    <Translation>
      {t =>
        <div className="booking-form mb-3  mt-2">
          <div className="">
            {show && typeTobooking == false && (
              <div className="d-flex align-items-end">
                <div className="col-4 customDatePickerWidth ml-0 pl-0">
                  <div>{t("newBookingSingha_Create_step1:Opening time")}</div>
                  {
                    timeToBookingCustomList?.length > 0 ?
                      <DatePicker
                        selected={state.start_round_time}
                        onChange={(date) =>
                          setstate({ ...state, start_round_time: date, end_round_time: date > state.end_round_time ? date : state.end_round_time })
                        }
                        // disabled={use_limit_time_check == "true" ? true : false}
                        showTimeSelect
                        showTimeSelectOnly
                        required
                        timeIntervals={5}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control float-left colorborder"
                        customInput={
                          <ButtonDate
                          // disabled={limit_time_status == "true" ? true : false}
                          />
                        }
                        minTime={EndTime}
                        maxTime={new Date(`2023-01-01T23:59:59`)}
                      />
                      :
                      <DatePicker
                        selected={state.start_round_time}
                        onChange={(date) =>
                          setstate({ ...state, start_round_time: date })
                        }
                        // disabled={use_limit_time_check == "true" ? true : false}
                        showTimeSelect
                        showTimeSelectOnly
                        required
                        timeIntervals={5}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control float-left colorborder"
                        customInput={
                          <ButtonDate
                          // disabled={limit_time_status == "true" ? true : false}
                          />
                        }

                        minTime={new Date(`2023-01-01T00:00:00`)}
                        maxTime={new Date(`2023-01-23:59:59`)}
                      />
                  }

                </div>
                <div className="col-4 customDatePickerWidth">
                  <div>{t("newBookingSingha_Create_step1:Closing time")}</div>
                  <DatePicker
                    selected={state.end_round_time}
                    onChange={(date) =>
                      setstate({ ...state, end_round_time: date })
                    }
                    // disabled={ state.start_round_time === state.end_round_time  ? true : false}
                    showTimeSelect
                    showTimeSelectOnly
                    required
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-control float-left colorborder"
                    customInput={
                      <ButtonDate
                      // disabled={limit_time_status == "true" ? true : false}
                      />
                    }
                    minTime={state.start_round_time}
                    maxTime={setHours(setMinutes(state.start_round_time, 59), 23)}
                  />
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn btn-outline-primary form-control"
                    onClick={() => {
                      addRound(state.start_round_time, state.end_round_time);
                      setstate({ ...state, start_round_time: state.end_round_time })
                      setshow(!show);
                    }}
                  >
                    {t("newBookingSingha_Create_step1:Comfirm")}
                  </button>
                </div>
                <div
                  className="cursor"
                  disabled={typeTobooking == true}
                  onClick={() => setshow(!show)}
                  style={{
                    color: "#B3B3B3",
                    fontSize: 28,
                    marginBottom: 16,
                    marginLeft: 16,
                  }}
                >
                  X
                </div>
              </div>
            )}

            {typeTobooking == false && (
              <div>
                <ul className="list-group list-group-flush col-md-9 ">
                  {timeToBookingCustomList?.map((item, index) => {
                    return (
                      <li
                        className="list-group-item d-flex justify-content-between col-10 "
                        key={index}
                      >
                        <div className="text-grey">
                          {t("newBookingSingha_Create_step2:Reservation cycle")} {t("newBookingSingha_Create_step1:No")}  {index + 1}: {
                            create ?
                              `${format(new Date(item.start_round_time), "HH:mm")} - ${format(new Date(item.end_round_time), "HH:mm")}`
                              :
                              `${format("2023-01-01T" + item.start_round_time, "HH:mm")} - ${format("2023-01-01T" + item.end_round_time, "HH:mm")}`
                          }
                        </div>
                        <div
                          className="cursor"
                          disabled={typeTobooking == false}
                          onClick={() => subRound(index)}
                          style={{ color: "#B3B3B3" }}
                        >
                          X
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="mt-3">
              <span className="fs-16 fw-400 text_1567B4" onClick={() => setshow(!show)}>+ {t("newBookingSingha_Create_step2:Add a reservation cycle")}</span>
            </div>
          </div>
        </div>

      }
    </Translation>

  );
};

export default Rights;
