import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        {/*<li>*/}
                        {/*    <NavLink to="/accounting/finance/withholdingTax/pnd-1">*/}
                        {/*        ภ.ง.ด.1*/}
                        {/*    </NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/accounting/finance/withholdingTax/pnd-3">
                                    {t("withholding_tax:P.N.D.3")}
                                </NavLink>}
                            </Translation>
                        </li>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/accounting/finance/withholdingTax/pnd-53">
                                    {t("withholding_tax:P.N.D.53")}
                                </NavLink>}
                            </Translation>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;