import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from 'react-i18next';
import i18n from 'i18next'
import '../styles/register.scss'

import Navigation from "./navigation";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { commitMutation, QueryRenderer } from "react-relay";
import Swal from "sweetalert2";
import _ from "lodash";

import Loading from "../../libs/loading";

const allResidential = graphql`
    query vehicleDetailQuery($id: ID!) {
        residential(id: $id){
            name
            car{

                edges{
                    node{
                        id
                        licencePlate
                        carBrand
                        carModel
                        carDescription
                        carType
                        chargedFee
                        province
                        carSticker
                        floor
                        space
                        carImage{
                            edges{
                                node{
                                    id
                                    image
                                    description
                                } 
                            } 
                        }
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation vehicleDetailMutation($input: DeleteCarInput!){
        deleteCar(input: $input){
            ok
        }
    }
`;

class VehicleDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reQuery: false,
        };
        this.onDeleteItem = this.onDeleteItem.bind(this);
    }

    onDeleteItem(id) {
        Swal.fire({
            title: i18n.t("venhicle:Are you sure to remove data?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("venhicle:Yes"),
            cancelButtonText: i18n.t("venhicle:Cancel")
        }).then((result) => {
            if (result.value) {
                const variables = {
                    input: {
                        id: id
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.deleteCar.ok) {
                                Swal.fire(i18n.t("venhicle:Remove successfully!"), '', 'success').then(() => {
                                    this.setState({ reQuery: !this.state.reQuery })
                                });

                            } else {
                                Swal.fire(i18n.t("venhicle:Remove unsuccessfully!"), '', 'warning'
                                );
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18n.t("venhicle:Please try again"), 'warning')
                        },
                    },
                )

            }
        })
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <QueryRenderer
                        environment={environment}
                        query={allResidential}
                        variables={{
                            "id": this.props.match.params.id,
                            reQuery: this.state.reQuery
                        }}
                        render={({ error, props }) => {
                            if (error) {
                                return <div>{error.message}</div>;
                            } else if (props) {
                                return (
                                    <div className="container-fluid p-4" id="detail">
                                        <div className="row justify-content-between">
                                            <div className="col header-color">
                                                <h3 className="mb-4">
                                                    <Link to="/register/residential/list/all">
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back" />
                                                    </Link>
                                                    {props.residential.name}
                                                </h3>
                                                <div className="content-inner">
                                                    <Navigation id={this.props.match.params.id} />
                                                </div>
                                            </div>
                                        </div>

                                        <React.Fragment>
                                            <div className="content-inner">
                                                <div className="row mb-4 fade-up">
                                                    {
                                                        props.residential.car.edges.map((vehicle) =>
                                                            <div className="col-md-6 col-xl-4 mb-4"
                                                                key={vehicle.node.id}>
                                                                <div className="card p-2 show">
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col text-left padding-hover" />
                                                                            <div className="col text-right show-icon">
                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_venhicles_delete' }) &&
                                                                                    <React.Fragment>
                                                                                        <img
                                                                                            src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                            alt="delete-icon" />
                                                                                        <Translation>
                                                                                            {t => <span
                                                                                                className="grey-color ml-1 underline hover"
                                                                                                onClick={() => this.onDeleteItem(vehicle.node.id)}>{t("venhicle:Remove")}</span>}
                                                                                        </Translation>
                                                                                    </React.Fragment>
                                                                                }

                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_venhicles_edit' }) &&
                                                                                    <React.Fragment>
                                                                                        <Link
                                                                                            to={{
                                                                                                pathname: "/register/residential/detail/form-vehicle/" + this.props.match.params.id + "/" + vehicle.node.id
                                                                                            }}>
                                                                                            <img
                                                                                                src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                                                alt="edit-icon"
                                                                                                className="ml-4" />
                                                                                            <Translation>
                                                                                                {t => <span
                                                                                                    className="grey-color ml-1">{t("venhicle:Edit")}</span>}
                                                                                            </Translation>
                                                                                        </Link>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col">
                                                                                {
                                                                                    vehicle.node.carType === "CAR" ?
                                                                                        <Translation>{t => <h6 className="highlight owner">{t("venhicle:Car")}</h6>}</Translation>
                                                                                        :
                                                                                        vehicle.node.carType === "MOTORCYCLE" &&
                                                                                        <Translation>{t => <h6 className="highlight resident">{t("venhicle:Motorcycle")}</h6>}</Translation>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-md-4 col-4 mt-4">
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <h4>{vehicle.node.licencePlate}</h4>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <h6>{vehicle.node.province}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    vehicle.node.carImage?.edges[0]?.node?.image &&
                                                                                    <div className='row'>
                                                                                        <div className='col'>
                                                                                            <img
                                                                                                className='rounded-circle avatar-img profile-img img-border'
                                                                                                src={vehicle.node.carImage?.edges[0]?.node.image} />
                                                                                        </div>

                                                                                    </div>
                                                                                }


                                                                            </div>

                                                                            <div
                                                                                className="col-md-8 col-8 mt-4">
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <Translation>{t => <h6>{t("venhicle:Car feature")}</h6>}</Translation>
                                                                                        <p>
                                                                                            {vehicle.node.carBrand} {vehicle.node.carModel} {vehicle.node.carDescription}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-3">
                                                                                    <div className="col">
                                                                                        <Translation>{t => <h6>{t("venhicle:Parking lot sticker")}</h6>}</Translation>
                                                                                        <p>{vehicle.node.carSticker || '-'}</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-3">
                                                                                    <div className="col">
                                                                                        <Translation>{t => <h6>{t("venhicle:Charge for parking")}</h6>}</Translation>
                                                                                        <Translation>{t => <p>{vehicle.node.chargedFee ? <>{t("venhicle:Have")}</> : <>{t("venhicle:No have")}</>}</p>}</Translation>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mt-3">
                                                                                    <div className="col">
                                                                                        <Translation>{t => <h6>{t("venhicle:Floor")}</h6>}</Translation>
                                                                                        <Translation>{t => <p>{vehicle.node.floor ? vehicle.node.floor : '-'}</p>}</Translation>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <Translation>{t => <h6>{t("venhicle:Space")}</h6>}</Translation>
                                                                                        <Translation>{t => <p>{vehicle.node.space ? vehicle.node.space : '-'}</p>}</Translation>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_venhicles_create' }) &&

                                                        <div className="col-md-6 col-xl-4 mb-4">
                                                            <Link
                                                                to={{
                                                                    pathname: "/register/residential/detail/form-vehicle/" + this.props.match.params.id
                                                                }}>
                                                                <div className="card card-add">
                                                                    <div
                                                                        className="card-body d-flex align-middle text-center">
                                                                        <div className="col">

                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/plus-icon.png'}
                                                                                alt="plus-icon" />
                                                                            <Translation>{t => <h4>{t("venhicle:Add venhicle")}</h4>}</Translation>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>

                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </React.Fragment>

                                    </div>
                                );
                            }
                            return <Loading />
                        }}
                    />
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default VehicleDetail;
