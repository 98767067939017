import React, { useState } from 'react';
import Header from './headerLogin';
import './styles/termOfuser.scss'
import { Link } from "react-router-dom";
import UpdatePDPA from './fetchQueryPolicy/updatePDPALogin';

const AcceptForPartner = () => {

    const [langauge, setLangauge] = useState(localStorage.getItem("language") ?? 'th')
    const [forPartner, checkAcceptForPartner] = useState(true)

    const nextPage = async () => {
        localStorage.setItem('checkStatusAcceptForPartner', forPartner)

        let variables = {
            input: {
                acceptPolicy: localStorage.getItem("checkStatusAcceptForPolicy") === "true" ? true : false,
                acceptTermOfUse: localStorage.getItem("checkStatusPolicyAndTerms") === "true" ? true : false,
                acceptMarketing: localStorage.getItem("checkStatusAcceptForMarketing") === "true" ? true : false,
                acceptPartner: localStorage.getItem("checkStatusAcceptForPartner") === "true" ? true : false
            }
        }
        UpdatePDPA(variables)

    }

    const changeLangauge = (langBoon, lang) => {
        setLangauge(lang)
    }

    const acceptForPartner = (e) => {
        if (e.target.value === "true") {
            checkAcceptForPartner(true)
        } else if (e.target.value === "false") {
            checkAcceptForPartner(false)
        }
    }

    return (
        <div className="height-100">
            <Header lang={changeLangauge} />
            <div className="col-md-10 container-fluid box " id='pdpa-menu'>
                {langauge === "th"
                    ?
                    <div className="height-80 justify-content-center" id="accept-for-marketing">
                        <div className="col-11 ml-8 selectManagementAndPolicy">
                            <h3>ความยินยอมสำหรับพันธมิตรของ Silverman</h3>

                            <p>พันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman ได้แก่</p>
                            <ul>
                                <li>การให้บริการจัดประชุมใหญ่ผ่านสื่ออิเล็กทรอนิกส์ (E-AGM) ซึ่งขณะนี้คือ บริษัท ควิดแลบ จำกัด</li>
                                <li>การให้บริการระบบปล่อยเช่าที่จอดรถที่สามารถเชื่อมโยงหรือส่งข้อมูลถึงกันได้ด้วยอินเทอร์เน็ตโดยให้เจ้าของที่จอดรถสามารถปล่อยเช่าที่จอดรถของตนเองผ่านแพลตฟอร์ม <br />(Parking Sharing)  ซึ่งขณะนี้คือ บริษัท อินฟิไลท์ จำกัด</li>
                                <li>การให้บริการที่ปรึกษาทางด้านกฎหมาย (Law Consultant) ซึ่งขณะนี้คือ บริษัท ทนายบ้านและคอนโด</li>
                            </ul>

                            <p>คุณมีสิทธิขอถอนความยินยอมเมื่อใดก็ได้ ผ่าน Silverman Application, สำนักงานใหญ่ของบริษัท พอร์ต 80 จำกัด, Silverman Call Center โทร. 081-442-6888 และ/
                                <br />หรือช่องทางที่บริษัทกำหนดในภายหน้า โดยสามารถดูรายละเอียดช่องทางการยกเลิกการให้ความยินยอมได้ที่ประกาศนโยบายความเป็นส่วนตัวของ Silverman
                                <br />ทั้งนี้ Silverman จะพิจารณาดำเนินการภายใน 7 วัน นับแต่วันที่ได้รับการแจ้งถอนความยินยอม และหลังจากนั้น Silverman จะไม่เปิดเผยข้อมูลส่วนบุคคลและข้อมูลใดๆ
                                <br />ของคุณให้แก่บุคคลดังกล่าวอีกต่อไป</p>
                            <p>คุณสามารถอ่านวิธีที่ Silverman เก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคลและเข้าใจสิทธิของคุณที่ประกาศนโยบายความเป็นส่วนตัวที่เว็บไซต์ https://silverman.app/privacy
                                <br />ทั้งนี้ โปรดอ่านประกาศนโยบายความเป็นส่วนตัวของบริษัทและพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman
                                <br />ที่เว็บไชต์หรือช่องทางที่บริษัทและพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman กำหนด</p>


                            <p className='mt-10'>ยินยอมในการเปิดเผยข้อมูลสำหรับพันธมิตรของ Silverman</p>

                            <div style={{ display: 'inline-flex' }}>
                                <label className="containerRadio">ยินยอม
                                    <input type={"radio"}
                                        defaultChecked={forPartner == true}
                                        value={true}
                                        name='acceptPolicy' onChange={acceptForPartner} />
                                    <span className="checkmark"></span></label>

                                <label className="containerRadio">ปฏิเสธ
                                    <input type={"radio"}
                                        defaultChecked={forPartner == false}
                                        value={false}
                                        name='acceptPolicy' onChange={acceptForPartner} />
                                    <span className="checkmark"></span></label>
                            </div>




                            <hr className='mt-10' />
                            <div className='apcentPolicy'>
                                <p>กรณีคุณไม่ให้ความยินยอมหรือขอถอนความยินยอมในภายหน้า คุณอาจจะพลาดโอกาสในการรับข้อเสนอผลิตภัณฑ์ บริการ
                                    <br />และสิทธิประโยชน์ที่เหมาะสมกับความต้องการของคุณโดยเฉพาะจากบริษัทและ/หรือพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman</p>

                                <p>หมายเหตุ : กรณีเพิ่มเติมวัตถุประสงค์หรือเพิ่มเติมผู้รับข้อมูลในภายหน้า Silverman จะดำเนินการให้เป็นไปตามวิธีการที่บริษัทประกาศกำหนดและ/หรือให้เป็นไปตามกฎหมายอื่นๆ ที่เกี่ยวข้อง</p>
                            </div>
                        </div>

                    </div>
                    :
                    <div className="height-80 justify-content-center" id="accept-for-marketing">
                        <div className="col-11 ml-8 selectManagementAndPolicy">
                            <h3>Consent of Silverman affiliation</h3>

                            <p>Reliable affiliations of Silverman include :</p>
                            <ul>
                                <li>Extraordinary & Annual general meeting (E-AGM) via electronic meeting by QUIDLAB Co,.Ltd.</li>
                                <li>Parking space rental service by the owner of parking able to rent out their free space parking via “Jodsabuy Platform” by INFILIGHT Co,.Ltd</li>
                                <li>Legal consulting services provided by the current company, which is House & Condo Lawyer,  a law firm specializing in real estate and condominiums.</li>
                            </ul>

                            <p>You can withdraw your consent through the Silverman Application, Headquarters of Port 80 Co,.Ltd, Silverman call center, Tel: 081-4426888 and/or future channels defined by the company. And you can see details on how to withdraw consent at the privacy policy of Silverman.  However, Silverman will consider and actualize within 7 days from the date of requested consent withdrawal. After that, Silverman will not disclose your personal information to others anymore.</p>
                            <p>You can read how Silverman collects, uses, discloses personal information, and understands your rights in the privacy policy on the website “https://silverman.app/privacy”. However, please read the privacy policy of Silverman and affiliations of Silverman at their websites or channels defined by Silverman and affiliations of Silverman.</p>


                            <p className='mt-10'>Consent of Silverman affiliation</p>

                            <div style={{ display: 'inline-flex' }}>
                                <label className="containerRadio">Accept
                                    <input type={"radio"}
                                        defaultChecked={forPartner == true}
                                        value={true}
                                        name='acceptPolicy' onChange={acceptForPartner} />
                                    <span className="checkmark"></span></label>

                                <label className="containerRadio">Reject
                                    <input type={"radio"}
                                        defaultChecked={forPartner == false}
                                        value={false}
                                        name='acceptPolicy' onChange={acceptForPartner} />
                                    <span className="checkmark"></span></label>
                            </div>




                            <hr className='mt-10' />
                            <div className='apcentPolicy'>
                                <p>If you don't give consent or later request to withdraw your consent, You may miss the opportunity to receive offers of products, services, and specific benefits for your needs from Silverman and affiliations of Silverman.</p>

                                <p>Note: In case of additional purposes or recipients of information in the future, Silverman will proceed according to the methods announced by the company and/or other laws related.</p>
                            </div>
                        </div>
                    </div>
                }
                <div id="term-of-user">
                    <div className="col-mt-10 btnLogin text-right">
                        {/* <button className='nextStep1'>เข้าสู่ระบบ / ลงทะเบียน</button> */}
                        <Link to={localStorage.getItem("selPr") === "true" && localStorage.getItem("token") ? '/select-project' : '/home'} >
                            <button className='nextStep1' onClick={nextPage}
                                disabled={forPartner == null}

                            >{langauge === 'th' ? "เข้าสู่ระบบ/ลงทะเบียน" : 'Login/Registor'}</button>
                        </Link>

                    </div>
                </div>

            </div>
        </div>
    )
}
export default AcceptForPartner