import { graphql } from "babel-plugin-relay/macro";

const querySilvermanCountNotiCollectionLawFirmQuery = graphql`
    query silvermanCountNotiCollectionLawFirmQuery($siteId:String){
        silvermanCountNotiCollectionLawFirm(siteId: $siteId){
          countFollow
          countPaid
     }
    }
`;

  
export default querySilvermanCountNotiCollectionLawFirmQuery;
