import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import "./style/stockMovementReport.scss"
import i18next from 'i18next';

export default class stockMovementReportTopMenu extends Component {
    render() {
        return (
            <div className="row" id="stockMovementReportTopMenu">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to={`/stockandsupplies/stockMovementReport/stockMovementReportDetail/${this.props.id}/ready/all`} >
                                {i18next.t("stockAndReport:All")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/stockandsupplies/stockMovementReport/stockMovementReportDetail/${this.props.id}/ready/buy`} >
                                {i18next.t("stockAndReport:Buy")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/stockandsupplies/stockMovementReport/stockMovementReportDetail/${this.props.id}/ready/unit`} >
                                {i18next.t("stockAndReport:Rights")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/stockandsupplies/stockMovementReport/stockMovementReportDetail/${this.props.id}/ready/sell`} >
                                {i18next.t("stockAndReport:Sell")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/stockandsupplies/stockMovementReport/stockMovementReportDetail/${this.props.id}/ready/project`} >
                                {i18next.t("stockAndReport:Requisition")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
