import React from "react";
import Header from "../../components/header/";
import Sidebar from "../../components/sidebar/";
import Wrapper from "../../components/wrapper/";
import WrapperContent from "../../components/wrapper/wrapperContent";
import ComponentPagination from "../../libs/componentPagination";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Navigation from "./guaranteeMoneyReceivedNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "./style/guaranteeMoneyReceived.scss";
import GuaranteeMoneyReceivedListTable from "./guaranteeMoneyReceivedListTable";
import ClearGuaranteeMoneyReceivedListTable from "./clearGuaranteeMoneyReceivedListTable";
import _ from "lodash";
import InlineAdvanceFilter from "../../libs/inlineAdvanceFilter";

const status_list = [
  { value: "", text: "All" },
  { value: "in_process", text: "Wating to clear" },
  { value: "completed", text: "Make a return" },
  { value: "return_forfeit", text: "Partial seizure/return" },
  { value: "forfeit_all", text: "Seizure" },
  { value: "void", text: "List of cancellation" },
];
class GuaranteeMoneyReceived extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      temp_start_date: null,
      temp_end_date: null,
      advance_search: false,
      search: "",
      search_input: "",
      status: "",
      temp_status: "",
      type: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      status: this.state.temp_status,
    });
  }

  componentDidMount() {
    this.setState({
      type: this.props.match.params.page,
    });
  }

  componentDidUpdate() {
    if (this.props.match.params.page !== this.state.type) {
      this.setState({
        type: this.props.match.params.page,
      });
      if (this.state.status !== "") {
        this.setState({
          status: "",
        });
      }
    }
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div
            className="container-fluid box"
            id="guarantee-money-receive-list"
          >
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => (
                      <span>{t("accounting:guarantee money received")+ " "+"(GR/CGR)"}</span>
                    )}
                  </Translation>
                </h3>
              </div>
              <div className="col text-right">
                {this.props.match.params.page === "received" &&
                  _.some(JSON.parse(localStorage.getItem("permission_list")), {
                    codename: "accounting_guarantee_receive_create",
                  }) && (
                    <Link to="/accounting/guarantee-money-received/form/select-contact">
                      <Translation>
                        {t=>
                        <button
                          type="button"
                          className="btn btn-primary add"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/icons/plus.png"
                            }
                            alt="plus"
                          />
                          {t("guarantee_money_received:Create a guarantee receipt")}
                        </button>}
                      </Translation>
                    </Link>
                  )}
              </div>
            </div>

            <div className="content-inner">
              <Navigation />
              <div className="row">
                <div className="col text-right">
                  <Translation>
                    {t=>
                    <div className="input-group float-right w-auto ml-2">
                      <input
                        type="text"
                        className="form-control input-size"
                        placeholder={t("guarantee_money_received:Search")}
                        value={this.state.search_input}
                        name="search"
                        onChange={(e) =>
                          this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleSearch(e)}
                      >
                        {t("guarantee_money_received:Search")}
                      </button>
                    </div>}
                  </Translation>
                  {/* <button
                    type="button"
                    className="btn btn-outline-secondary float-right"
                    onClick={() =>
                      this.setState({
                        advance_search: !this.state.advance_search,
                      })
                    }
                  >
                    <img
                      src="/images/icons/filter-icon.png"
                      alt="filter-icon"
                    />
                  </button> */}
                  <InlineAdvanceFilter
                    start_date={this.state.temp_start_date}
                    end_date={this.state.temp_end_date}
                    handleChange={this.handleChange}
                    status={this.props.match.params.page === "received" && true}
                    status_list={
                      this.props.match.params.page === "received" && status_list
                    }
                    status_value={this.state.temp_status}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  {this.props.match.params.page === "received" && (
                    <GuaranteeMoneyReceivedListTable
                      start_date={this.state.start_date}
                      end_date={this.state.end_date}
                      status={this.state.status}
                      search={this.state.search}
                    />
                  )}
                  {this.props.match.params.page === "clear" && (
                    <ClearGuaranteeMoneyReceivedListTable
                      start_date={this.state.start_date}
                      end_date={this.state.end_date}
                      search={this.state.search}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default GuaranteeMoneyReceived;
