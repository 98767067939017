import React from 'react';
import {QueryRenderer} from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {Link} from "react-router-dom";
import getNameResidential from "../../../libs/getNameResidential";
import Loading from "../../../libs/loading";
import { Translation } from 'react-i18next';
import i18n from 'i18next'


class UnknownReceiveListTable extends ComponentPagination {

    isStatusNotVoid(status){
        if(status === 'void'){
            return false
        }else {
            return true
        }

    }

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'WAIT':
                text = 'Wating to clear';
                color = 'text-center text-danger';
                break;
            case 'CLEAR':
                text = 'Cleared';
                break;
            default:
                text = 'List of cancellation';
                color = 'text-center text-danger';
        }
        return <Translation>{t=><td className={color}>{t(`unknown_receive:${text}`)}</td>}</Translation>
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={this.props.query}
                variables={{
                    search: this.props.search,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                }}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        let amount_perpage = 0.0
                        props.unknownReceiveViewer.allUnknownReceive.edges.map(value => {
                            amount_perpage += parseFloat(value.node.amount)
                            return value
                        })
                        return <React.Fragment>
                            <div className="table-responsive fade-up">
                                <table className="table table-hover mt-2">
                                    <thead className="thead-light">
                                    <Translation>
                                        {t=>
                                        <tr>
                                            <th className="text-center" width={130}>{t("unknown_receive:Document No.")}</th>
                                            <th className="text-center">{t("unknown_receive:Date")}</th>
                                            <th>{t("unknown_receive:Detail")}</th>
                                            {this.props.status === 'clear' &&
                                            <React.Fragment>
                                                <th>{t("unknown_receive:Contact by")}</th>
                                                <th>{t("unknown_receive:Refer")}</th>
                                            </React.Fragment>
                                            }
                                            <th>{t("unknown_receive:Bank")}</th>
                                            <th className="text-right">{t("unknown_receive:Amount")}</th>
                                            {this.props.status === 'void' &&
                                            <th className="text-center">{t("unknown_receive:Date of cancellation")}</th>
                                            }
                                            {this.props.status === 'all' &&
                                            <th className="text-center">{t("unknown_receive:Status")}</th>
                                            }
                                            <th className="text-center" width={120}>{t("unknown_receive:By")}</th>
                                            {this.props.status === 'void' &&
                                            <th className="text-center" width={150}>{t("unknown_receive:Reason of cancellation")}</th>
                                            }
                                        </tr>}
                                    </Translation>
                                    </thead>
                                    <tbody>

                                    {props.unknownReceiveViewer.allUnknownReceive.edges.map((unknown_receive) => {
                                        let firName = unknown_receive?.node?.receiptDeposit?.edges[0]?.node?.firstName
                                        let lastName = unknown_receive?.node?.receiptDeposit?.edges[0]?.node?.lastName
                                        let payGroup = unknown_receive?.node?.receiptDeposit?.edges[0]?.node?.payGroup

                                        return (
                                            <tr key={unknown_receive.node.id}>
                                                <td className="text-center">
                                                    {
                                                        unknown_receive.node.status === "VOID" ? unknown_receive.node.docNumber :
                                                            <Link
                                                                to={"/accounting/income/unknown-receive/detail/" + unknown_receive.node.id}
                                                                >
                                                                {unknown_receive.node.docNumber}
                                                            </Link>
                                                    }
                                                </td>
                                                <td className="text-center">{format(unknown_receive.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                <td>{unknown_receive.node.description.length >= 80 ? unknown_receive.node.description.substring(0, 80)+'...' : unknown_receive.node.description} </td>
                                                {this.props.status === 'clear' &&
                                                <React.Fragment>
                                                    {
                                                        firName && lastName?
                                                        <td className='colorUse notKanit'>
                                                            {`${unknown_receive.node?.contact?.refNumber} ${unknown_receive.node?.contact?.name} ${getNameResidential(firName || "", lastName || "")}`} 
                                                            {
                                                                payGroup === "RENT" || payGroup === "AGENT" &&
                                                                <label className={`text_CA0824`}>
                                                                ({payGroup === "RENT" || payGroup === "AGENT" ? i18n.t("AgentRole:"+payGroup): ""})
                                                                </label>
                                                            }
                                                            
                                                        </td>
                                                        :
                                                        <td>{unknown_receive.node?.contact?.refNumber} {unknown_receive.node?.contact?.name} {getNameResidential(unknown_receive.node?.contact?.firstName || "", unknown_receive.node?.contact?.lastName || "")}</td>
                                                    }
                                                    <td>{unknown_receive.node.refNumber}</td>
                                                </React.Fragment>
                                                }
                                                {unknown_receive.node.bankAccount  ? 
                                                    <td>{unknown_receive.node.bankAccount.bankName} {unknown_receive.node.bankAccount.accountName} {unknown_receive.node.bankAccount.accountNumber}</td>
                                                    :
                                                    <td>{unknown_receive.node.receiveChannel}</td>
                                                }
                                                <td className="text-right">{numberWithComma(unknown_receive.node.amount)}</td>
                                                {this.props.status === 'all' && this.getColorAndText(unknown_receive)}
                                                {this.props.status === 'void' &&
                                                    <td className="text-center">{format(unknown_receive.node.updated, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                }
                                                <td>{unknown_receive.node.creator}</td>
                                                
                                                {this.props.status === 'void' &&
                                                <td >{unknown_receive.node.voidRemark || '-'}</td>
                                                }
                                            </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                    {this.isStatusNotVoid(this.props.status) && <React.Fragment>
                                        {(!props.unknownReceiveViewer.allUnknownReceive.pageInfo.hasNextPage) &&
                                            <tfoot>
                                                <tr>
                                                    <Translation>{t=><th colSpan={this.props.status === 'clear'? 6 : 4} className = "thead-light text-right">{t("unknown_receive:All total")}</th>}</Translation>
                                                    <th className = "text-right"> {numberWithComma(props.unknownReceiveViewer.summaryUnknownReceive)} </th>
                                                    {this.props.status === 'all' && <td />}
                                                    <td/>
                                                </tr>
                                            </tfoot>
                                        }
                                        {(props.unknownReceiveViewer.allUnknownReceive.pageInfo.hasNextPage || props.unknownReceiveViewer.allUnknownReceive.pageInfo.hasPreviousPage) &&
                                            <tfoot>
                                                <tr>
                                                    <Translation>{t=><th colSpan={this.props.status === 'clear'? 6 : 4} className = "thead-light text-right">{t("unknown_receive:Total")}</th>}</Translation>
                                                    <th className = "text-right">{numberWithComma(amount_perpage)}</th>
                                                    {this.props.status === 'all' && <td />}
                                                    <td/>
                                                </tr>
                                            </tfoot>
                                        }
                                    </React.Fragment>}
                                    
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                            last={this.state.last}
                                            totalCount={props.unknownReceiveViewer.allUnknownReceive.totalCount}/>
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default UnknownReceiveListTable;
