import React, {Component} from 'react';
import "../styles/genCodeA5.scss"
import {Helmet} from "react-helmet";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import enLocale from "date-fns/locale/en";
import Loading from "../../libs/loading";
import jwtDecode from "jwt-decode";
import {Translation} from "react-i18next";
import i18n from 'i18next';
import _ from 'lodash';

const genInviteCode = graphql`
    query genCodeA5Query($id:String, $status:String){
        genInviteCode(residential: $id, status:$status)
        selfProject {
            juristicContactNumber
        }
    }
`;

class GenCodeA5 extends Component {

    constructor(props) {
        super(props);
        this.state={
            token: jwtDecode(window.localStorage.getItem('token')),
            language: i18n.language
        }
        this.print = this.print.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    print() {
        window.print();
    }

    handleLanguageChange(e) {
        const language = e.target.value;
        this.setState({ language });
        i18n.changeLanguage(language);
    }

    render() {
        const languageNow = this.state.language;
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return (
            <div id="gen-code-a5">
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>GenCode</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                    <div className='btn-changeln'>
                            <label>
                                <input
                                    type="radio"
                                    value="th"
                                    checked={languageNow === 'th'}
                                    onChange={this.handleLanguageChange}
                                />
                                ไทย
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="en"
                                    checked={languageNow === 'en'}
                                    onChange={this.handleLanguageChange}
                                />
                                English
                            </label>
                    </div>
                </div>

                <QueryRenderer
                    environment={environment}
                    query={genInviteCode}
                    variables={{"id": this.props.match.params.id ? this.props.match.params.id: "",status : this.props.match.params.status }}
                    cacheConfig={{use_cache: false}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return (
                                <Translation>
                                    {t=>
                                <div className="print-page-a5 text-center">
                                    {
                                        props.genInviteCode.map((gen_code) => {
                                            gen_code = JSON.parse(gen_code.replace(/'/g, '"'));
                                            return (
                                                <div className="box-body" key={gen_code.residential}>
                                                    
                                                    {
                                                        this.state.token?.site?.includes('sklasmanagement') ? 

                                                            <img
                                                            src="https://silverman-storage.sgp1.digitaloceanspaces.com/3partysource/images/singha_logo.png"
                                                            alt="silverman-logo" style={{width: 75, height: 75}}/> 
                                                        
                                                        :

                                                            <img
                                                            src="https://silverman-storage.sgp1.digitaloceanspaces.com/3partysource/images/silvetman-logo.png"
                                                            alt="silverman-logo" style={{width: 75, height: 75}}/>
                                            
                                                    }

                                                    <p style={{fontSize: 14, fontWeight: 400}}>
                                                        <b>{t("residential:Room_No.")} {gen_code.residential}</b>
                                                        <br/>
                                                        {t("residential:The invitation code to use for your subscription.")}
                                                    </p>

                                                    <h3 style={{letterSpacing: 14}} className="pb-2">{gen_code.code}</h3>

                                                    <p style={{fontSize: 12}}>
                                                        {t("residential:The code is valid until")} {format(gen_code.deadline, 'DD MMMM YYYY - h:mm A', {locale: i18n.language === 'th' ? thLocale : enLocale})} 
                                                        <br/>
                                                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                        textData?.data?.registerGencode.pleaseContact
                                                        :
                                                        t("residential:If there is a problem, please contact with juristic of your project.")}
                                                        <br/>
                                                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on &&
                                                         `${textData?.data?.registerGencode.tel} ${props.selfProject.juristicContactNumber}`}
                                                    </p>

                                                    <p className="pt-2">{t("residential:Download the application at")}</p>

                                                    <div className="download">
                                                        <img
                                                            src="https://silverman-storage.sgp1.digitaloceanspaces.com/3partysource/images/magnify.png"
                                                            alt="magnify" style={{
                                                            position: "relative",
                                                            width: 15,
                                                            height: 15,
                                                            right: 120,
                                                            top: 5
                                                        }}/>

                                                        <p style={{
                                                            position: "relative",
                                                            right: 0,
                                                            bottom: 15
                                                        }}> {this.state.token?.site?.includes('sklasmanagement') ? 'S KLAS' : 'Silverman'}</p>
                                                    </div>

                                                    {
                                                    this.state.token?.site?.includes('sklasmanagement') ? 
                                                        <img src={process.env.PUBLIC_URL + "/images/qrcode/qr-SKLAS.png"}
                                                        alt="sklas-qr-code"
                                                        className="m-3" style={{width: 127, height: 127}}/> 
                                                    :
                                                        <img src="https://silverman-storage.sgp1.digitaloceanspaces.com/3partysource/images/qr.png"
                                                            alt="silverman-qr-code"
                                                        className="m-3" style={{width: 127, height: 127}}/> 
                                                    }
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                                    }
                                </Translation>
                            )
                        }
                        return <Loading/>
                    }}/>
            </div>
        )
    }
}

export default GenCodeA5;