import React, { Component } from 'react';
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import '../styles/withholdingTaxPrint.scss';

const ThaiBaht = require('thai-baht-text');

class OtherExpenseWithholdingTax extends Component {

    constructor(props) {
        super(props);
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];
        this.state = {
            bangkokCode: bangkokCode,
            project: atob(this.props.query.selfProject.id).split(":")[1],
            tax_list: {}
        };

        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {

        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "district") {
            return "ต."
        } else if (type === "city") {
            return "อ."
        }
    }

    getPn53() {
        let bool = false
        this.props.query.otherExpense.otherExpenseTransaction.edges.forEach((Transaction) => {
            if (Transaction.node.whtRate !== null && Transaction.node.whtRate !== 0) {
                bool = true;
            }
        });
        return bool;
    }

    getProductAndService() {
        if(this.props.query.otherExpense.otherExpenseTransaction.edges[0].node.typeOfTax){
            return this.props.query.otherExpense.otherExpenseTransaction.edges[0].node
        }else if(this.props.query.otherExpense.otherExpenseTransaction.edges.length > 0){
            return this.props.query.otherExpense.otherExpenseTransaction.edges[0].node.productAndService
        }
    }

    getType6(description) {
        return description.substring(0, 60);
    }

    componentDidMount() {
        let data ={}
        if (this.props.other_expense) {
            data = this.setData(this.props.other_expense)
        }
        this.setState({tax_list:data})

    }

    setData = (data) =>{
        let payRecord 
        let list = {
            issuedDate:'',
            id:'',
            payRecord_list:{type1:[],type2:[],type3:[],type4:[],type5:[],type6:[]}
        }
        if(data.otherExpense){
            
            payRecord = data.otherExpense.otherExpenseTransaction
            list.issuedDate = data.otherExpense.issuedDate
            list.id = data.otherExpense.id
            payRecord.edges.forEach((val,index)=>{
                let payRecord_list= {}
                payRecord_list['id'] = val.node.id
                // payRecord_list['description'] = val.node.description
                payRecord_list['description'] = val.node.typeOfIncomeOther ? val.node.typeOfIncomeOther : val.node.description
                payRecord_list['preTaxAmount'] = val.node.preTaxAmount
                payRecord_list['total'] = val.node.total
                payRecord_list['whtRate'] = val.node.whtRate
                payRecord_list['whtRatePercent'] = val.node.whtRatePercent
                payRecord_list['productAndService'] = val.node.productAndService
                if (val.node.productAndService !== null&&val.node.productAndService.typeOfIncome) {
                    payRecord_list['type'] = val.node.productAndService.typeOfIncome
                }else{
                    payRecord_list['type'] = '6'
                }
                if (val.node.whtRate) {
                    // list.payRecord_list[`type${payRecord_list.type}`].push(payRecord_list)
                    if(payRecord_list.type === "1" || payRecord_list.type === "2" || payRecord_list.type === "3" ||  payRecord_list.type === "5" || payRecord_list.type === "6"){
                        list.payRecord_list[`type${payRecord_list.type}`].push(payRecord_list)
                    } else {
                        list.payRecord_list[`type4`].push(payRecord_list)
                    }
                }
            })
        }
        return list
    }

    renderRow(typeOfIncome){
        let data_object = '';
        let product_and_service = '';
        let total = '';
        let withholdingTaxTotal = 0;

        if(this.props.query.otherExpense){
            data_object = this.props.query.otherExpense;
            let totals = 0
            if((typeOfIncome === "1" || typeOfIncome === "2" || typeOfIncome === "3" ||  typeOfIncome === "5" || typeOfIncome === "6") && this.state.tax_list.payRecord_list){
                for (const [key, value] of Object.entries(this.state.tax_list.payRecord_list)) {
                    if(key === `type${typeOfIncome}` && value.length !== 0){
                        totals += _.sumBy(value, function(o) { return o.preTaxAmount; })
                        withholdingTaxTotal += _.sumBy(value, function(o) { return o.whtRate; })
                        product_and_service = value[0].productAndService;
                    }
                }
                total = totals.toString()
            } else {
                let type4 = _.filter(this.state.tax_list?.payRecord_list?.type4, (o) => o.type === typeOfIncome)
                if(type4.length > 0){
                    product_and_service = type4[0].productAndService;
                    totals += _.sumBy(type4, function(o) { return o.preTaxAmount; })
                    withholdingTaxTotal += _.sumBy(type4, function(o) { return o.whtRate; })
                }
                total = totals.toString()
            }
        }

        return (
            data_object && product_and_service !== null && product_and_service.typeOfIncome === typeOfIncome ?
                <React.Fragment>
                    <td style={{ border: "none", borderBottom: "none" }}
                        className="text-right">{format(data_object.issuedDate, 'DD MMM YYYY', { locale: thLocale })}
                    </td>
                    <td style={{ border: "none", borderBottom: "none" }}
                        className="text-right">{numberWithComma(total)}</td>
                    <td style={{
                        borderLeft: "none",
                        borderTop: "none",
                        borderBottom: "none",
                        paddingRight: 14
                    }} className="text-right">{numberWithComma(withholdingTaxTotal)}
                    </td>
                </React.Fragment>
                :
                <React.Fragment>
                    <td style={{ border: "none", borderBottom: "none" }} />
                    <td style={{ border: "none", borderBottom: "none" }} />
                    <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} />
                </React.Fragment>
        )
    }

    calculatorTotal = (payRecord_list) => {
        let total = 0;
        if(payRecord_list !== undefined){
            for (const [key, value] of Object.entries(payRecord_list)) {
                total += _.sumBy(value, function(o) { return o.preTaxAmount })
            }
        }
        return total;
    }

    render() {
        const index = this.props.current_page;
        let tex = 0
        let total = 0

        tex = _.sumBy(this.props.query.otherExpense?.otherExpenseTransaction?.edges, function (otherExpenses) {
            return parseFloat(otherExpenses.node.whtRate) || 0;
        })

        return(
            this.getPn53() &&
            <React.Fragment key={index + "fn53"}>
                <div id="withholding-tax-print" key={index + "pn53"}>
                    <div className="print-page-withholding-a4" key={index + "pn535"}  >
                        <div className="subpage" >
                            <div className="withholding-tax-body" >
                                <div>{this.props.haedervalue}</div>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colSpan="4">
                                                <div className="text-center" style={{ position: "relative" }}>
                                                    <strong style={{ fontSize: 14 }}>หนังสือรับรองการหักภาษี ณ ที่จ่าย</strong>
                                                    <span style={{ position: "absolute", right: 10, fontSize: 12 }}>
                                                        เลขที่ {this.props.query.otherExpense.withholdingTax.edges[0].node.docNumber}
                                                    </span>
                                                </div>
                                                <div className="text-center">ตามมาตรา 50 ทวิ แห่งประมวลรัษฎากร</div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody key={index + "149"}>
                                        <tr key={index + "150"}>
                                            <td colSpan="4">
                                                <div className="detail">
                                                    <div className="customer-detail">
                                                        <strong>&emsp;ผู้มีหน้าที่หักภาษี ณ ที่จ่าย :</strong>

                                                        <div style={{ paddingTop: 5 }}>
                                                            <strong>&emsp;ชื่อ
                                                                <span style={{ paddingLeft: 20 }}>{this.props.query.selfProject.name}</span>
                                                            </strong>
                                                            &emsp;<div style={{ marginTop: 2, marginLeft: 45 }}>(ให้ระบุว่าเป็นบุคคล นิติบุคคล บริษัท สมาคม หรือคณะบุคคล)</div>
                                                        </div>
                                                    </div>
                                                    <div className="customer-detail-right text-right">
                                                        <strong>เลขประจำตัวผู้เสียภาษีอากร</strong>
                                                        <span
                                                            className="border-id-number">{this.props.query.selfProject.taxIdNumber}</span><br />
                                                    </div>
                                                </div>

                                                <div className="detail" style={{ paddingTop: 8 }}>
                                                    <strong>&emsp;ที่อยู่
                                                        <span
                                                            style={{ paddingLeft: 13 }}>{this.props.query.selfProject.address}</span>
                                                    </strong>
                                                    &emsp;<div style={{ marginTop: 2, marginLeft: 45 }}>(ให้ระบุชื่ออาคาร/หมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต จังหวัด)</div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr key={index + "180"}>
                                            <td colSpan="4">
                                                <div className="detail">
                                                    <div className="customer-detail">
                                                        <strong>&emsp;ผู้ถูกหักภาษี ณ ที่จ่าย :</strong>

                                                        <div style={{ paddingTop: 5 }}>
                                                            <strong>&emsp;ชื่อ
                                                                <span style={{ paddingLeft: 20 }}>{this.props.query.otherExpense.contact ? this.props.query.otherExpense.contact.name : this.props.query.otherExpense.unknownContact}</span>
                                                            </strong>
                                                            &emsp;<div style={{ marginTop: 2, marginLeft: 45 }}>(ให้ระบุเป็นบุคคล นิติบุคคล บริษัท สมาคม หรือคณะนิติบุคคล)</div>
                                                        </div>
                                                    </div>

                                                    <div className="customer-detail-right text-right">
                                                        <strong>เลขประจำตัวผู้เสียภาษีอากร</strong>
                                                        <span
                                                            className="border-id-number">{this.props.query.otherExpense.contact ? this.props.query.otherExpense.contact.taxIdNumber : ''}</span>
                                                    </div>
                                                </div>

                                                <div className="detail" style={{ paddingTop: 8 }}>
                                                    <strong>&emsp;ที่อยู่ 
                                                        {this.props.query.otherExpense.address ?
                                                            <span style={{ paddingLeft: 13 }}>
                                                                {/* {this.props.query.otherExpense.contact.registeredAddress + " "}
                                                                {this.props.query.otherExpense.contact.registeredDistrict && this.getPrefix("district", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredDistrict + " "}
                                                                {this.props.query.otherExpense.contact.registeredCity && this.getPrefix("city", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredCity + " "}
                                                                {this.props.query.otherExpense.contact.registeredProvince && this.getPrefix("province", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredProvince + " "}
                                                                {this.props.query.otherExpense.contact.registeredPostalCode + " "} */}

                                                                 {/* BTA685 new rq */}
                                                                {this.props.query.otherExpense?.address + " "}
                                                                {this.props.query.otherExpense?.district && this.getPrefix("district", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.district + " "}
                                                                {this.props.query.otherExpense?.city && this.getPrefix("city", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.city + " "}
                                                                {this.props.query.otherExpense?.province && this.getPrefix("province", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.province + " "}
                                                                {this.props.query.otherExpense?.postalCode + " "}

                                                            </span>
                                                            :
                                                            <span style={{ paddingLeft: 13 }}>-</span>
                                                        }
                                                    </strong>
                                                    &emsp;
                                                    <div style={{ marginTop: 2, marginLeft: 45 }}>(ให้ระบุชื่ออาคารหมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต จังหวัด)</div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr key={index + "221"}>
                                            <td colSpan="4">
                                                <div className="detail">
                                                    <div>
                                                        &emsp;<span className="font-weight-bold">ลำดับที่</span>
                                                        <span className="border-id-number number" />
                                                        <span className="font-weight-bold">ในแบบ</span>

                                                        <span style={{ marginLeft: 31 }}>
                                                            (1) ภ.ง.ด.1ก
                                                        </span>

                                                        <span style={{ marginLeft: 60 }}>
                                                            (2) ภ.ง.ด.1ก พิเศษ
                                                        </span>

                                                        <span style={{ marginLeft: 55 }}>
                                                            (3) ภ.ง.ด.2
                                                        </span>
                                                        {this.props.query.otherExpense.contact && ((this.props.query.otherExpense.contact.typeOfPeople === "GENERAL_PEOPLE" &&  this.props.query.otherExpense.contact.typeOfSupplier === "OTHER") || this.props.query.otherExpense.contact.typeOfSupplier === "GROUP_OF_PERSON" || this.props.query.otherExpense.contact.typeOfSupplier === "ORDINARY_PARTNERSHIP" || this.props.query.otherExpense.contact.typeOfSupplier === "MERCHANT" || this.props.query.otherExpense.contact.typeOfSupplier === "PERSON") ?
                                                            <span style={{ marginLeft: 45 }}>
                                                                <img src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                                    alt="correct-signal" className="collect" />
                                                                (4) ภ.ง.ด.3
                                                            </span>
                                                            :
                                                            <span style={{ marginLeft: 60 }}>
                                                                (4) ภ.ง.ด.3
                                                            </span>
                                                        }
                                                    </div>

                                                    <div style={{ marginLeft: 185, marginTop: 5 }}>
                                                        <span>
                                                            (5) ภ.ง.ด.2ก
                                                        </span>

                                                        <span style={{ marginLeft: 60 }}>
                                                            (6) ภ.ง.ด.3ก
                                                        </span>

                                                        {this.props.query.otherExpense.contact && ((this.props.query.otherExpense.contact.typeOfPeople === "GENERAL_PEOPLE" &&  this.props.query.otherExpense.contact.typeOfSupplier === "OTHER") || this.props.query.otherExpense.contact.typeOfSupplier === "GROUP_OF_PERSON" || this.props.query.otherExpense.contact.typeOfSupplier === "ORDINARY_PARTNERSHIP" || this.props.query.otherExpense.contact.typeOfSupplier === "MERCHANT" || this.props.query.otherExpense.contact.typeOfSupplier === "PERSON") ?
                                                            <span style={{ marginLeft: 86 }}>
                                                                (7) ภ.ง.ด.53
                                                            </span>
                                                            :
                                                            <span style={{ marginLeft: 50 }}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                                    alt="correct-signal" className="collect" />
                                                                (7) ภ.ง.ด.53
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr className="top-table" key={index + "279"}>
                                            <td className="text-center" width="485"><strong>ประเภทเงินได้พึงประเมินที่จ่าย</strong></td>
                                            <td className="text-center" width="78"><strong>วัน เดือน ปี <br />ที่จ่าย</strong></td>
                                            <td className="text-center" width="86"><strong>จำนวนเงินที่จ่าย</strong></td>
                                            <td className="text-center" width="86"><strong>ภาษีหัก ณ ที่จ่าย</strong></td>
                                        </tr>

                                        <tr key={index + "286"}>
                                            <td style={{ borderRight: "none", borderBottom: "none" }}>
                                                <div>&emsp;1.<span style={{ marginLeft: 10 }}>เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40(1)</span></div>
                                            </td>
                                            {
                                                this.renderRow("1")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "295"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;2.<span style={{ marginLeft: 10 }}>ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40(2)</span></div>
                                            </td>
                                            {
                                                this.renderRow("2")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "304"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;3.<span style={{ marginLeft: 10 }}>ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40(3)</span></div>
                                            </td>
                                            {
                                                this.renderRow("3")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "313"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;4.<span style={{ marginLeft: 10 }}>(ก)&emsp;ค่าดอกเบี้ย ฯลฯ ตามมาตรา 40(4) (ก)</span></div>
                                            </td>
                                            {
                                                this.renderRow("4")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "322"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;
                                                    <span style={{ marginLeft: 19 }}>(ข)&emsp;เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ ตามมาตรา 40(4) (ข)</span>
                                                </div>
                                            </td>
                                            <td style={{ border: "none" }} />
                                            <td style={{ border: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} />
                                        </tr>

                                        <tr key={index + "333"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 44 }}>(1)&emsp;กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี โดยจ่ายจาก กำไรสุทธิของกิจการที่</span><br />
                                                </div>
                                                <div style={{ marginLeft: 80, marginTop: 3 }}>ต้องเสียภาษีเงินได้บุคคลในอัตรา ดังนี้</div>
                                            </td>
                                            <td style={{ border: "none" }} />
                                            <td style={{ border: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} />
                                        </tr>

                                        <tr key={index + "345"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 69 }}>(1.1)&emsp;อัตราร้อยละ 30 ของกำไรสุทธิ</span>
                                                </div>
                                            </td>
                                            {
                                                this.renderRow("4.1.1")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "356"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 69 }}>(1.2)&emsp;อัตราร้อยละ 25 ของกำไรสุทธิ</span>
                                                </div>
                                            </td>
                                            {
                                                this.renderRow("4.1.2")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "367"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 69 }}>(1.3)&emsp;อัตราร้อยละ 20 ของกำไรสุทธิ</span>
                                                </div>
                                            </td>
                                            {
                                                this.renderRow("4.1.3")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "378"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 69 }}>(1.4)&emsp;อัตราอื่นๆ ระบุของกำไรสุทธิ</span>
                                                </div>
                                            </td>
                                            {
                                                this.renderRow("4.1.4")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "389"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 45 }}>(2)&emsp;กรณีผู้ได้รับเงินปันผลไม่ได้รับเครดิตภาษี เนื่องจากจ่ายจาก</span>
                                                </div>
                                            </td>
                                            <td style={{ border: "none" }} />
                                            <td style={{ border: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} />
                                        </tr>

                                        <tr key={index + "400"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 69 }}>(2.1)&emsp;กำไรสุทธิของกิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคล</span>
                                                </div>
                                            </td>
                                            {
                                                this.renderRow("4.2.1")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "411"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 69 }}>(2.2)&emsp;เงินปันผลหรือเงินส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้องนำมารวม</span>
                                                </div>
                                                <div style={{ marginLeft: 113, marginTop: 3 }}>คำนวณเป็นรายได้เพื่อเสียภาษีเงินได้นิติบุคคล</div>
                                            </td>
                                            {
                                                this.renderRow("4.2.2")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "423"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;
                                                    <span style={{ marginLeft: 69 }}>(2.3)&emsp;กำไรสุทธิส่วนที่ได้หักผลขาดทุนสุทธิยกมาไม่เกิน 5 ปี ก่อนรอบระยะ</span>
                                                </div>
                                                <div><span style={{ marginLeft: 113 }}>บัญชีปีปัจจุบัน</span></div>
                                            </td>
                                            {
                                                this.renderRow("4.2.3")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        <tr key={index + "435"}>
                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div>&emsp;<span
                                                    style={{ marginLeft: 69 }}>(2.4)&emsp;กำไรที่รับรู้ทางบัญชีโดยวิธีส่วนได้เสีย (equity method)</span>
                                                </div>
                                            </td>
                                            {
                                                this.renderRow("4.2.4")
                                            }
                                            {/* <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ border: "none", borderBottom: "none" }} />
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} /> */}
                                        </tr>

                                        {
                                            this.state.tax_list.payRecord_list &&  _.filter(this.state.tax_list.payRecord_list.type4, (o) => o.type === "4.2.5").length > 0 ? _.filter(this.state.tax_list.payRecord_list.type4, (o) => o.type === "4.2.5").map((Transaction, index) => {
                                                total = total + Transaction.preTaxAmount;
                                                let whtRatetax = Transaction.preTaxAmount * ((Transaction.whtRatePercent / 100) || 0.00)
                                                return (
                                                    <tr key={Transaction.id + "471"}>
                                                        <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                            {index === 0 ?
                                                                <div style={{ wordBreak: "break-word" }}>&emsp;
                                                                    <span style={{ marginLeft: 69 }}>(2.5)</span>
                                                                    <span
                                                                        style={{ marginLeft: 11 }}>อื่นๆ (ระบุ) .....{Transaction.description}..{Transaction.whtRatePercent > 0 ? Transaction.whtRatePercent + '%' : null}.....</span>
                                                                </div>
                                                                :
                                                                <div>&emsp;<span style={{ marginLeft: 70 }}>........{Transaction.description}..{Transaction.whtRatePercent > 0 ? Transaction.whtRatePercent + '%' : null}..........</span></div>
                                                            }
                                                        </td>
                                                        <td style={{ border: "none" }} className="text-right" > {format(this.state.tax_list.issuedDate, 'DD MMM YYYY', { locale: thLocale })} </td>
                                                        <td style={{ border: "none" }} className="text-right"> {numberWithComma(Transaction.preTaxAmount)} </td>
                                                        <td style={{
                                                            borderLeft: "none",
                                                            borderTop: "none",
                                                            borderBottom: "none",
                                                            paddingRight: 14
                                                        }} className="text-right" > {numberWithComma(whtRatetax)} </td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr key={index + "474"}>
                                                    <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                        <div>&emsp;
                                                            <span style={{ marginLeft: 69 }}>(2.5)</span>
                                                            <span
                                                                style={{ marginLeft: 11 }}>อื่นๆ (ระบุ) ........................</span>
                                                        </div>
                                                    </td>
                                                    <React.Fragment key={index + "482"}>
                                                        <td style={{ border: "none", borderBottom: "none" }} />
                                                        <td style={{ border: "none", borderBottom: "none" }} />
                                                        <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} />
                                                    </React.Fragment>
                                                </tr>
                                        }



                                        {
                                            this.state.tax_list.payRecord_list && this.state.tax_list.payRecord_list?.type5.length > 0 ? this.state.tax_list.payRecord_list.type5.map((Transaction, index) => {
                                                total = total + Transaction.preTaxAmount;
                                                let whtRatetax = Transaction.preTaxAmount * ((Transaction.whtRatePercent / 100) || 0.00)
                                                let last = this.state.tax_list.payRecord_list.type5.length - 1
                                                return (
                                                    <>
                                                        <tr key={Transaction.id+"519"} >
                                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                                {index === 0 && <div>&emsp;5.<span style={{ marginLeft: 10 }}>การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่าย ตามคำสั่งกรมสรรพากรที่ออกตามในมาตรา 3 เตรส</span></div>}
                                                                {index === 0 ?
                                                                    <div style={{ marginLeft: 18, marginTop: 3 }}>&emsp;(ระบุ)
                                                                        .........{Transaction.description}..{Transaction.whtRatePercent > 0 ? Transaction.whtRatePercent + '%' : null}.........</div>
                                                                    :
                                                                    <div>&emsp;<span style={{ marginLeft: 45, paddingTop: -20 }}>........{Transaction.description}..{Transaction.whtRatePercent > 0 ? Transaction.whtRatePercent + '%' : null}..........</span></div>
                                                                }

                                                            </td>
                                                            <td style={{ border: "none", position: 'relative' }} className="text-right" >
                                                                <div style={{ position: 'absolute', bottom: 0, right: 0 }} >{format(this.state.tax_list.issuedDate, 'DD MMM YYYY', { locale: thLocale })}</div>
                                                            </td>
                                                            <td style={{ border: "none", position: 'relative' }} className="text-right" >
                                                                <div style={{ position: 'absolute', bottom: 0, right: 0 }} >{numberWithComma(Transaction.preTaxAmount)}</div>
                                                            </td>
                                                            <td style={{
                                                                borderLeft: "none",
                                                                borderTop: "none",
                                                                borderBottom: "none",
                                                                position: 'relative'
                                                            }}>
                                                                <div style={{ position: 'absolute', bottom: 0, right: 0, paddingRight: 14 }} className="text-right"  >{numberWithComma(whtRatetax)}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                                {index === last && <div style={{ marginLeft: 18, marginTop: 3 }}>&emsp;(เช่น รางวัล ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขาย รางวัลใน</div>}
                                                                {index === last && <div style={{ marginLeft: 18, marginTop: 3 }}>&emsp;การประกวด การแข่งขัน การชิงโชค ค่าแสดงของนักแสดงสาธารณะ ค่าจ้างทำของ </div>}
                                                                {index === last && <div style={{ marginLeft: 18, marginTop: 3 }}>&emsp;ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย ฯลฯ)</div>}
                                                            </td>
                                                            <td style={{ border: "none", borderBottom: "none" }} />
                                                            <td style={{ border: "none", borderBottom: "none" }} />
                                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} />
                                                        </tr>
                                                    </>
                                                )
                                            }) :
                                                <tr key={index + "538"}>
                                                    <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                        <div>&emsp;5.<span style={{ marginLeft: 10 }}>การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่าย ตามคำสั่งกรมสรรพากรที่ออกตามใน</span></div>
                                                        <div style={{ marginLeft: 18, marginTop: 3 }}>&emsp;มาตรา 3 เตรส (ระบุ)..................</div>
                                                        <div style={{ marginLeft: 18, marginTop: 3 }}>&emsp;(เช่น รางวัล ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขาย รางวัลใน</div>
                                                        <div style={{ marginLeft: 18, marginTop: 3 }}>&emsp;การประกวด การแข่งขัน การชิงโชค ค่าแสดงของนักแสดงสาธารณะ ค่าจ้างทำของ </div>
                                                        <div style={{ marginLeft: 18, marginTop: 3 }}>&emsp;ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย ฯลฯ)</div>
                                                    </td>
                                                    <React.Fragment key={index + "546"}>
                                                        <td style={{ border: "none", borderBottom: "none" }} />
                                                        <td style={{ border: "none", borderBottom: "none" }} />
                                                        <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} />
                                                    </React.Fragment>

                                                </tr>
                                        }


                                        {
                                            this.state.tax_list.payRecord_list && this.state.tax_list.payRecord_list?.type6.length > 0 ? this.state.tax_list.payRecord_list.type6.map((Transaction, index) => {
                                                total = total + Transaction.preTaxAmount;
                                                let whtRatetax = Transaction.preTaxAmount * ((Transaction.whtRatePercent / 100) || 0.00)
                                                return (
                                                    <tr key={Transaction.id +"581"}>
                                                        {index === 0 ?
                                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                                <div>&emsp;6.<span style={{ marginLeft: 10 }}>อื่นๆ (ระบุ) .....{this.state.tax_list.payRecord_list?.type6.length > 2 ? this.getType6(Transaction.description) : Transaction.description} ...{Transaction.whtRatePercent > 0 ? Transaction.whtRatePercent + '%' : null}.......</span></div>
                                                            </td>
                                                            :
                                                            <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                                <div>&emsp;<span style={{ marginLeft: 70 }}>.....{this.state.tax_list.payRecord_list?.type6.length > 2 ? this.getType6(Transaction.description) : Transaction.description} ...{Transaction.whtRatePercent > 0 ? Transaction.whtRatePercent + '%' : null}.......</span></div>
                                                            </td>
                                                        }
                                                        <td style={{ border: "none" }} className="text-right" > {format(this.state.tax_list.issuedDate, 'DD MMM YYYY', { locale: thLocale })} </td>
                                                        <td style={{ border: "none" }} className="text-right"> {numberWithComma(Transaction.preTaxAmount)} </td>
                                                        <td style={{
                                                            borderLeft: "none",
                                                            borderTop: "none",
                                                            borderBottom: "none",
                                                            paddingRight: 14
                                                        }} className="text-right" > {numberWithComma(whtRatetax)} </td>
                                                    </tr>
                                                )
                                            }) : <tr key={index + "581"}>
                                                <td style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                    <div>&emsp;6.<span style={{ marginLeft: 10 }}>อื่นๆ (ระบุ) ..................</span></div>
                                                </td>
                                                <React.Fragment key={index + "585"}>
                                                    <td style={{ border: "none", borderBottom: "none" }} />
                                                    <td style={{ border: "none", borderBottom: "none" }} />
                                                    <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none" }} />
                                                </React.Fragment>
                                            </tr>
                                        }

                                        <tr key={index + "593"}>
                                            <td className="text-right" colSpan="2" style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}>
                                                <div><strong>&emsp;รวมเงินที่จ่ายและภาษีที่หักนำส่ง</strong></div>
                                            </td>
                                            <td style={{ border: "none" }} className="text-right">{
                                                numberWithComma(this.calculatorTotal(this.state.tax_list.payRecord_list))
                                                // total !== 0 ? numberWithComma(total)
                                                    // : numberWithComma(this.props.other_expense.otherExpense.otherExpenseTransaction.edges[0].node.preTaxAmount)
                                            }</td>
                                            <td style={{ borderLeft: "none", borderTop: "none", borderBottom: "none", paddingRight: 14 }} className="text-right">{numberWithComma(tex)}
                                            </td>
                                        </tr>

                                        <tr key={index + "605"}>
                                            <td colSpan="4" style={{ borderTop: "none", borderBottom: "none" }}>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <strong>&emsp;รวมเงินภาษีที่หักนำส่ง</strong> (ตัวอักษร)
                                                    </div>
                                                    <strong>
                                                        ({ThaiBaht(tex)})
                                                    </strong>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr key={index + "618"}>
                                            <td colSpan="4">
                                                <div className="detail">
                                                    <td style={{ border: "none", paddingLeft: 11, width:"60px" }} >
                                                        <strong>ผู้จ่ายเงิน</strong>
                                                    </td>

                                                    {
                                                        !this.getProductAndService() ?
                                                        <React.Fragment key={index + "pn53subA"}>
                                                            <td style={{ marginLeft: 10, border: "none", paddingLeft: 10, paddingRight: 10, width: "145px" }}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                                    alt="delete-icon"
                                                                    className="collect type-of-tax" />
                                                                (1) ภาษีหัก ณ ที่จ่าย</td>
                                                            <td style={{ marginLeft: 10, border: "none", paddingLeft: 10, paddingRight: 10, width: "145px" }}>(2) ออกภาษีให้ตลอดไป</td>
                                                            <td style={{ marginLeft: 10, border: "none", paddingLeft: 10, paddingRight: 10, width: "145px" }}>(3) ออกภาษีให้ครั้งเดียว</td>
                                                            <td style={{ marginLeft: 10, border: "none", paddingLeft: 10, paddingRight: 10, width: "145px" }}>(4) อื่นๆ (ระบุ) ...........................</td>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment key={index + "pn53subB"}>
                                                            {
                                                                this.getProductAndService().typeOfTax === "1" ||
                                                                    (this.getProductAndService().typeOfTax  !== "1" &&
                                                                        this.getProductAndService().typeOfTax  !== "2" &&
                                                                        this.getProductAndService().typeOfTax  !== "3" &&
                                                                        this.getProductAndService().typeOfTax  !== "other") ?
                                                                    <td style={{
                                                                        marginLeft: 10,
                                                                        border: "none",
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10, 
                                                                        width: "150px"
                                                                    }}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                                            alt="delete-icon"
                                                                            className="collect type-of-tax" />
                                                                        (1) ภาษีหัก ณ ที่จ่าย
                                                                    </td> : <td style={{
                                                                        marginLeft: 10,
                                                                        border: "none",
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10, 
                                                                        width: "145px"
                                                                    }}>(1) ภาษีหัก ณ ที่จ่าย</td>
                                                            }

                                                            {
                                                                this.getProductAndService().typeOfTax  === "2" ?
                                                                    <td style={{
                                                                        marginLeft: 10,
                                                                        border: "none",
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10, 
                                                                        width: "150px"
                                                                    }}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                                            alt="delete-icon"
                                                                            className="collect type-of-tax" />
                                                                        (2) ออกภาษีให้ตลอดไป
                                                                    </td> : <td style={{
                                                                        marginLeft: 10,
                                                                        border: "none",
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10, 
                                                                        width: "145px"
                                                                    }}>(2) ออกภาษีให้ตลอดไป</td>
                                                            }

                                                            {
                                                                this.getProductAndService().typeOfTax  === "3" ?
                                                                    <td style={{
                                                                        marginLeft: 10,
                                                                        border: "none",
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10, 
                                                                        width: "150px"
                                                                    }}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                                            alt="delete-icon"
                                                                            className="collect type-of-tax" />
                                                                        (3) ออกภาษีให้ครั้งเดียว
                                                                    </td> : <td style={{
                                                                        marginLeft: 10,
                                                                        border: "none",
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10, 
                                                                        width: "145px"
                                                                    }}>(3) ออกภาษีให้ครั้งเดียว</td>
                                                            }

                                                            {
                                                                this.getProductAndService().typeOfTax  === "other" ?
                                                                    <td width="200" style={{
                                                                        border: "none",
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10
                                                                    }}>
                                                                        <div style={{ wordBreak: "break-word" }}>
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/correct-signal.png'}
                                                                                alt="delete-icon"
                                                                                className="collect type-of-tax" />
                                                                            (4) อื่นๆ (ระบุ)
                                                                            .....{this.getProductAndService().typeOfTaxOther}.....
                                                                        </div>
                                                                    </td> : <td style={{
                                                                        marginLeft: 10,
                                                                        border: "none",
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10, 
                                                                        width: "200"
                                                                    }}>(4) อื่นๆ (ระบุ) ...........................</td>
                                                            }

                                                        </React.Fragment>
                                                    }
                                                </div>
                                                {/*}*/}
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tfoot key={index + "736"}>
                                        <tr key={index + "737"}>
                                            <td colSpan={4}>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <strong>&emsp;คำเตือน</strong>
                                                    </div>
                                                    <div className="col" style={{ marginLeft: 50 }}>
                                                        ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้างต้นถูกต้องตรงกับความจริงทุกประการ
                                                    </div>
                                                </div>

                                                <div className="row pt-2">
                                                    <div className="col-4" style={{ paddingLeft: 25, paddingTop: this.state.tax_list.payRecord_list?.type6.length > 2 ? 0 : 8, lineHeight: this.state.tax_list.payRecord_list?.type6.length > 2 ? "16px" : "18px" }}>
                                                        ผู้มีหน้าที่ออกหนังสือรับรองการหักภาษี ณ ที่จ่าย
                                                        ฝ่าฝืนไม่ปฏิบัติตามมาตรา 50 ทวิ แห่งประมวลรัษฎากร
                                                        ต้องรับโทษทางอาญาตามมาตรา 35 แห่งประมวลรัษฎากร
                                                    </div>
                                                    <div className="col-5 text-center" style={{ paddingTop: this.state.tax_list.payRecord_list?.type6.length > 2 ? 10 : 23 }}>
                                                        <span>ลงชื่อ</span>
                                                        <span style={{ paddingLeft: 140 }}>ผู้จ่ายเงิน</span>
                                                        <div style={{ marginTop: -7, marginRight: 15 }}>................................................</div>
                                                        <div style={{ paddingRight: 15, paddingTop: 4 }}></div>
                                                        <div style={{ paddingRight: 15, paddingTop: 4 }}>{this.props.query.otherExpense && format(this.props.query.otherExpense.issuedDate, 'DD MMM YYYY', { locale: thLocale })}</div>
                                                        <div style={{ paddingRight: 15, paddingTop: 4 }}>วันที่ออกหนังสือรับรอง</div>
                                                    </div>

                                                    <div className="col text-center">
                                                        {
                                                            this.props.query.selfProject.stamp ?
                                                                <img style={{ maxHeight: this.state.tax_list.payRecord_list?.type6.length > 2 ? 65 : 80, maxWidth: this.state.tax_list.payRecord_list?.type6.length > 2 ? 65 : 80 }}
                                                                    src={this.props.query.selfProject.stamp} alt="stamp" />
                                                                :
                                                                <img style={{ maxHeight: this.state.tax_list.payRecord_list?.type6.length > 2 ? 65 : 80, maxWidth: this.state.tax_list.payRecord_list?.type6.length > 2 ? 65 : 80 }}
                                                                    src={this.props.query.selfProject.logo} alt="logo" />
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default OtherExpenseWithholdingTax;
