import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import JuristicList from "./settingJuristicList";
import NoMatch from "../../components/noMatch";
import CreateUpdateSettingJuristic from "./createUpdateSettingJuristic";
import Permission from "./permission";
import SettingJuristicListJob from './SettingJuristicListJob';
import PermissionForJob from './permisionForJob';
import CreateUpdateSettingJuristicMuli from './createUpdateSettingJuristicMuli';


class Juristic extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={JuristicList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-juristic/:id?`} component={CreateUpdateSettingJuristic}/>
                <PrivateRoute exact path={`${this.props.match.url}/form-juristic-muli/:id?`} component={CreateUpdateSettingJuristicMuli}/>
                <PrivateRoute exact path={`${this.props.match.url}/permission/:tab/:id`} component={Permission}/>
                <PrivateRoute exact path={`${this.props.match.url}/permissions-juristic`} component={SettingJuristicListJob}/>
                <PrivateRoute exact path={`${this.props.match.url}/permissions-juristic/:tab/:id?`} component={PermissionForJob}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Juristic;