import React from 'react';
import { QueryRenderer } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import getNameResidential from "../../../libs/getNameResidential";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Loading from "../../../libs/loading";
import i18next from 'i18next';

class CreditNoteListTable extends ComponentPagination {

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'WAIT':
                text = 'Unpaid';
                break;
            case 'PAID':
                text = 'Paid';
                break;
            default:
                text = 'Canceled list';
                color = 'text-center text-danger';
        }
        return <Translation>{t => <td className={color}>{t('creditNote:' + text)}</td>}</Translation>
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={this.props.query}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return (
                            <Translation>
                                {t =>
                                    <React.Fragment>
                                        <div className="table-responsive fade-up">
                                            <table className="table table-hover mt-2">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th className="text-center">{t('creditNote:No.')}</th>
                                                        <th className="text-center">{t('creditNote:Date')}</th>
                                                        <th className="text-center">{t('creditNote:Code')}</th>
                                                        <th className="text-center">{t('creditNote:Room No.')}</th>
                                                        <th className="text-center">{t('creditNote:Name')}</th>
                                                        <th className="text-center">{t('creditNote:Description')}</th>
                                                        <th className="text-right">{t('creditNote:Amount')}</th>
                                                        {this.props.status === 'all' &&
                                                            <th className="text-center">{t('creditNote:Status')}</th>
                                                        }
                                                        <th className="text-center">{t('creditNote:Date of creation')}</th>
                                                        {this.props.status === 'void' &&
                                                            <th className="text-center">{t('creditNote:Date of cancellation')}</th>
                                                        }
                                                        <th className="text-center">{t('creditNote:By')}</th>
                                                        {this.props.status === 'void' &&
                                                            <th className="text-center" width={100}>{t('creditNote:Reason of cancellation')}</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {props.creditNoteViewer.allCreditNote.edges.map((credit_note) => {

                                                        if (credit_note.node.version === 'V1') {
                                                            return (
                                                                <tr key={credit_note.node.id}>
                                                                    <td className="text-center">
                                                                        {credit_note.node.status === 'VOID' ?
                                                                            credit_note.node.docNumber
                                                                            :
                                                                            <Link to={"/accounting/income/credit-note/detail/" + credit_note.node.id}>
                                                                                {credit_note.node.docNumber}
                                                                            </Link>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">{format(credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                    <td className="text-center">{credit_note.node.contact.refNumber}</td>
                                                                    <td>{credit_note.node.contact.typeOfContact === 'RESIDENTIAL' ? credit_note.node.contact.name : '-'} </td>
                                                                    <td className="colorUse notKanit">{
                                                                        credit_note.node.contact.typeOfContact === 'RESIDENTIAL' && credit_note.node.firstName && credit_note.node.lastName ?
                                                                            getNameResidential(credit_note.node.firstName, credit_note.node.lastName) :
                                                                            credit_note.node.contact.typeOfContact === 'RESIDENTIAL' ?
                                                                                getNameResidential(credit_note.node.contact.firstName, credit_note.node.contact.lastName) :
                                                                                credit_note.node.contact.name}
                                                                        {
                                                                            credit_note?.node?.payGroup === "AGENT" || credit_note?.node?.payGroup === "RENT" ?
                                                                                <label className={`text_CA0824`}>({i18next.t("AgentRole:" + credit_note.node.payGroup)})</label>
                                                                                :
                                                                                ""
                                                                        }
                                                                    </td>

                                                                    <td>{credit_note.node.description.replace(/ *\[[^)]*\] */g, " ")}</td>
                                                                    <td className="text-right">{numberWithComma(credit_note.node.price)}</td>
                                                                    {this.props.status === 'all' &&
                                                                        this.getColorAndText(credit_note)
                                                                    }
                                                                    <td className="text-center">{format(credit_note.node.added, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                    {this.props.status === 'void' &&
                                                                        <td className="text-center">{format(credit_note.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                    }
                                                                    <td className={credit_note.node.creator ? '' : 'text-center'}>{credit_note.node.creator ? credit_note.node.creator : '-'}</td>
                                                                    {this.props.status === 'void' &&
                                                                        <td className="text-center">{credit_note.node.voidRemark || '-'}</td>
                                                                    }
                                                                </tr>
                                                            )
                                                        } else {
                                                            return credit_note.node.creditNoteTransactionRecord.edges.map((credit_note_transaction, index) => {
                                                                return (
                                                                    <tr key={credit_note_transaction.node.id}>
                                                                        <td className="text-center">
                                                                            {credit_note_transaction.node.status === 'VOID' ?
                                                                                credit_note.node.docNumber
                                                                                :
                                                                                <Link to={"/accounting/income/credit-note/detail/" + credit_note.node.id}>
                                                                                    {credit_note.node.docNumber}
                                                                                </Link>
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">{format(credit_note.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        <td className="text-center">{credit_note.node.contact.refNumber}</td>
                                                                        <td>{credit_note.node.contact.typeOfContact === 'RESIDENTIAL' ? credit_note.node.contact.name : '-'} </td>
                                                                        <td className="colorUse notKanit">{
                                                                            credit_note.node.contact.typeOfContact === 'RESIDENTIAL' && credit_note.node.firstName && credit_note.node.lastName ?
                                                                                getNameResidential(credit_note.node.firstName, credit_note.node.lastName) :
                                                                                credit_note.node.contact.typeOfContact === 'RESIDENTIAL' ?
                                                                                    getNameResidential(credit_note.node.contact.firstName, credit_note.node.contact.lastName) :
                                                                                    credit_note.node.contact.name}
                                                                            {
                                                                                credit_note?.node?.payGroup === "AGENT" || credit_note?.node?.payGroup === "RENT" ?
                                                                                    <label className={`text_CA0824`}>({i18next.t("AgentRole:" + credit_note.node.payGroup)})</label>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </td>

                                                                        <td>{credit_note_transaction.node.description.replace(/ *\[[^)]*\] */g, " ")}</td>
                                                                        <td className="text-right">{numberWithComma(credit_note_transaction.node.price)}</td>
                                                                        {this.props.status === 'all' &&
                                                                            this.getColorAndText(credit_note)
                                                                        }
                                                                        <td className="text-center">{format(credit_note.node.added, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        {this.props.status === 'void' &&
                                                                            <td className="text-center">{format(credit_note.node.updated, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        }
                                                                        <td className={credit_note.node.creator ? '' : 'text-center'}>{credit_note.node.creator ? credit_note.node.creator : '-'}</td>
                                                                        {this.props.status === 'void' &&
                                                                            <td className="text-center">{credit_note.node.voidRemark || '-'}</td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            })

                                                        }

                                                    }
                                                    )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <Pagination changePage={this.changePage} first={this.state.first}
                                                last={this.state.last}
                                                totalCount={props.creditNoteViewer.allCreditNote.totalCount} />
                                        </div>
                                    </React.Fragment>
                                }
                            </Translation>
                        )
                    }
                    return <Loading />
                }}
            />
        )
    }
}

export default CreditNoteListTable;
