import React, { useEffect, useState } from 'react'
import _ from "lodash"
import { graphql } from "babel-plugin-relay/macro";
import environment from '../../../../../env/environment';
import { fetchQuery } from "react-relay";
import { Translation } from 'react-i18next';
import format from 'date-fns/format';
import i18next from 'i18next';

require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const query = graphql`
    query batchPrintExcelAllQuery($startDate: Date, $endDate: Date, $search: String, $status: String) {    
        allBatchReceive(startDate: $startDate, endDate: $endDate, search: $search, status: $status){
            edges{
                node{
                    id
                    docNumber
                    batchDate
                    startRt
                    endRt
                    creator
                    voider
                    remark
                    status
                    added
                    receiveCount
                    importReceive
                    {
                        id
                        added
                        allRow
                    }
                }
            }
            totalCount
        }
}`;

const BatchPrintExcelAll = (props) => {
  const [batchAll, setBatchAll] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
      fetchBatchPDFData();
  }, [props]);
 
  const fetchBatchPDFData = () => {
      setLoading(true)
      const variables = {
          startDate: props.startDate ? format(props.startDate, "YYYY-MM-DD") : null,
          endDate: props.endDate ? format(props.endDate, "YYYY-MM-DD") : null,
          search: props.search,
          status: props.status
        };
      fetchQuery(environment, query, variables).then(data => {
          getRtInBatch(data.allBatchReceive?.edges)
      })
  }

  const addHeaders = (headerRow, headers, borders, fontBold, textCenter) => {
    let count = 1;
    headers.forEach((item) => {
        let cell = headerRow.getCell(count);
        cell.value = item;
        cell.border = borders;
        cell.font = fontBold;
        cell.alignment = textCenter;
        count += 1;
    });  
  } 

  const addData = (dataRow, data, borders, textCenter) => {
    let count = 1;
    data.forEach((item, index) => {
        let cell = dataRow.getCell(count);
        cell.value = item;
        cell.border = borders;
        cell.alignment = (index === 4 || index === 5) ? { vertical: 'middle', horizontal: 'left' } : { vertical: 'middle', horizontal: 'center' };;
        count += 1;
    });
  } 

  const calBatchRange = (startRt, endRt) =>{            
    const containsComma = startRt ? startRt.includes(',') : "";
    let results = ""

    if(containsComma) {
        const startData = startRt.split(",");
        const endData = endRt.split(",");            
        let resultConcat = startData.map((start, index) => `${start} - ${endData[index]}`);
        results = resultConcat.join(', ')
    } else if(startRt === endRt){
      results = startRt
    } else {
        results = `${startRt} - ${endRt}`
    }
    return results
}

  const getRtInBatch = (data) => {
    let allRtData = []
      data.map((rt)=>{
        let dataRt = [
            `${rt.node.docNumber}`,
            `${format(rt.node.batchDate, "DD/MM/YYYY")}`,
            `${format(rt.node.added, "HH.mm")}`,
            `${rt.node.receiveCount}`,
            `${calBatchRange(rt.node.startRt, rt.node.endRt)}`, 
            `${rt.node.remark || ""}`,
          ]

          allRtData.push(dataRt)
      })

      setBatchAll(allRtData)
      setLoading(false)
  }
  

  const downloadExcel = () => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('Batch RT');

    let columns = [
      { width: 20 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 30 },
      { width: 40 },
    ];

    // Set column widths
    worksheet.columns = columns.map((col, index) => {
      return { width: col.width, key: String.fromCharCode(65 + index) };
    });

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let fontBold = { size: 12, bold: true };
    let projectName = props.projectName
    let reportName = i18next.t(`batchRt:Batch Report`);
    let sortDate  = `${i18next.t('batchRt:Sort by')} ${props.startDate ? format(props.startDate,"DD/MM/YYYY"): "-"} ${i18next.t('batchRt:To')} ${props.endDate ? format(props.endDate,"DD/MM/YYYY") : "-"}`


    worksheet.getCell('A1').value = projectName
    worksheet.getCell('A1').font = { size: 16, bold: true };
    
    worksheet.getCell('A2').value = reportName
    worksheet.getCell('A2').font = { size: 16, bold: true };

    worksheet.getCell('A3').value = sortDate
    worksheet.getCell('A3').font = { size: 16, bold: true };

   
    worksheet.addRow();

    let headers = [
      `${i18next.t(`batchRt:Batch number`)}`, 
      `${i18next.t(`batchRt:Date`)}	`,
      `${i18next.t(`batchRt:Time`)}`, 
      `${i18next.t(`batchRt:Quantity`)}`, 
      `${i18next.t(`batchRt:Document number`)}`, 
      `${i18next.t(`batchRt:Reason for Cancellation`)}`
    ]      
    
    let headerRow = worksheet.addRow();

    addHeaders(headerRow, headers, borders, fontBold, textCenter);

    batchAll.map((data) => {
      let dataRow = worksheet.addRow(); 
      addData(dataRow, data, borders, fontBold, textCenter);
    });
  


      workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = `${i18next.t(`batchRt:Batch Report`)}`;  

        let formData = new FormData();
        formData.append('file', blob , `${nameFile}.xlsx`);
        formData.append('type', "excel");
        formData.append('type_report_ref', "batch_receive_report");
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = nameFile;
        a.click();
      });    
  }
       

  return (
        <a
            to="/"
            className="dropdown-item printItem"
            target={"_blank"}
            onClick={()=> downloadExcel()}>
                {loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                {i18next.t(`batchRt:Print Excel`)}
        </a>
  );


}

export default BatchPrintExcelAll;