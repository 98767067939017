import React from "react";
import "../styles/commonArea.scss";
import iconBooking from "../CommonAreaList/create/iconBooking";
import { Translation } from "react-i18next";
import i18next from "i18next";
const SettingBookingStep1 = ({
    state: {
        advanceDay,
    },
    selectChange,
    data,
    onClickNext,
}) => {
    return (
        <Translation>
            {
                t =>
                    <React.Fragment>
                        <div className="d-flex justify-content-center mb-3  mt-5 claim">
                            <div className="create col-8" style={{ zoom: '80%' }}>
                                <label>
                                    <p className="headerLabel18 fw-600 ml-2">01 <span className="label16 ml-2">{t("newBookingSingha_Setting:Advance booking")}</span></p>
                                    <iconBooking.iconStep1 />
                                </label>
                                <label className="ml-2 mr-2">
                                    <p className="headerLabel18 fw-600 ml-2">02 <span className="label16 ml-2">{t("newBookingSingha_Setting:Point setup")}</span></p>
                                    <iconBooking.iconStep1_2 />
                                </label>
                                <label>
                                    <p className="headerLabel18 fw-600 ml-2">03 <span className="label16 ml-2">Money to Point</span></p>
                                    <iconBooking.iconStep1_3 />
                                </label>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center  mb-3  mt-5">
                            <div className="col-8">
                                <div className="line">
                                    <div className="header">{t("newBookingSingha_Setting:Advance booking setup")}</div>
                                </div>

                                <Select
                                    data={data.setingCommonArea.advanceDay}
                                    text={t("newBookingSingha_Setting:Days available for advance booking (Days)")}
                                    unit={t("newBookingSingha_Setting:Days")}
                                    value={advanceDay}
                                    onChange={selectChange}
                                    name={"advanceDay"}
                                // disabled={!this.state.permissionEdit}
                                />


                                {/* BTN Next AnD Back */}
                                <div style={{ clear: 'both' }} />
                                <div className="row claim mt-10">
                                    <div className="col-12 text-right">
                                        <button className="btn-primary" type="button"
                                            onClick={onClickNext}
                                        >
                                            {t("Allaction:next")}
                                        </button>

                                    </div>
                                    <div className="mt-10 mb-10" />
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
            }
        </Translation>

    );
};

const Select = ({ text, unit, name, data, onChange, value }) => {
    return (
        <div className="mt-2 booking-form">
            <span className="bold">{text}</span>
            <select className="form-control mt-1 col-8 " id="select_right"
                defaultValue={value}
                onChange={onChange}
                name={name}
            >
                <option className="bold" value={""} key={-1}>{i18next.t("Allaction:choose")}</option>
                {data?.map((item, index) => {
                    return (
                        <option className="bold" value={item} key={index}>
                            {item} {unit}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default SettingBookingStep1;