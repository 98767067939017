/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NewsNewsType = "INFO" | "NOTICE" | "%future added value";
export type NewsStatus = "DRAFT" | "EXPIRE" | "PUBLISH" | "VOID" | "%future added value";
export type newsListQueryVariables = {|
  first?: ?number,
  last?: ?number,
  search?: ?string,
  order?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  news_types?: ?string,
|};
export type newsListQueryResponse = {|
  +allNews: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +detail: string,
        +status: NewsStatus,
        +creator: ?string,
        +added: any,
        +updated: any,
        +newsType: NewsNewsType,
        +scheduleTime: ?any,
        +expTime: ?any,
        +view: number,
      |}
    |}>,
  |}
|};
export type newsListQuery = {|
  variables: newsListQueryVariables,
  response: newsListQueryResponse,
|};
*/


/*
query newsListQuery(
  $first: Int
  $last: Int
  $search: String
  $order: String
  $start_date: DateTime
  $end_date: DateTime
  $news_types: String
) {
  allNews(first: $first, last: $last, search: $search, order: $order, startDate: $start_date, endDate: $end_date, newsTypes: $news_types) {
    totalCount
    edges {
      node {
        id
        title
        detail
        status
        creator
        added
        updated
        newsType
        scheduleTime
        expTime
        view
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "news_types"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "newsTypes",
        "variableName": "news_types"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      }
    ],
    "concreteType": "NewsNodeConnection",
    "kind": "LinkedField",
    "name": "allNews",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NewsNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewsNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newsType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "view",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "newsListQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "newsListQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "2c976be602c342eebe39a9db4c40bd08",
    "id": null,
    "metadata": {},
    "name": "newsListQuery",
    "operationKind": "query",
    "text": "query newsListQuery(\n  $first: Int\n  $last: Int\n  $search: String\n  $order: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $news_types: String\n) {\n  allNews(first: $first, last: $last, search: $search, order: $order, startDate: $start_date, endDate: $end_date, newsTypes: $news_types) {\n    totalCount\n    edges {\n      node {\n        id\n        title\n        detail\n        status\n        creator\n        added\n        updated\n        newsType\n        scheduleTime\n        expTime\n        view\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '973b6a72b62ada79d6d4c74557418f22';

module.exports = node;
