import React, { Component } from "react";
import { Translation } from "react-i18next";
import { NavLink } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <div className="row" id="navigation-tab">
        <div className="col">
          <Translation>
            {(t) => (
              <ul>
                <li>
                  <NavLink exact={true} to="/contact/global-area/booking/common-area/all">
                    {t("premission:All items")} 
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area/booking/common-area/open">
                    {t("newBookingSingha_FacilityList:Open")}
                    
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area/booking/common-area/temporarily_open"> 
                    {t("newBookingSingha_FacilityList:Temporarily reservation")}
                    
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area/booking/common-area/renovate">
                    {t("newBookingSingha_FacilityList:Under maintenance")}
                    
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact/global-area/booking/common-area/closed">
                    {t("newBookingSingha_FacilityList:Permanently closed")}
                    
                  </NavLink>
                </li>
              </ul>
            )}
          </Translation>
        </div>
      </div>
    );
  }
}

export default Navigation;
