import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Translation} from "react-i18next";
import './style/settingJuristicList.scss'
const _ = require('lodash');
class NavigationTest extends Component {

   
    render() {
        return (
            <div className="row" id="navigation-tab">
                <Translation>
                    {t=>
                    <div className="col">
                        <ul>
                            <li>
                                {
                                    this.props.id ? 
                                    <NavLink to={"/setting/juristic-setting/permissions-juristic/management/"+this.props.id}>
                                    {t("set_permission:Management")} 
                                    </NavLink>:
                                      <NavLink to={"/setting/juristic-setting/permissions-juristic/management/"}>
                                      {t("set_permission:Management")} 
                                    </NavLink>
                                }
                            </li>
                            <li>
                                {
                                    this.props.id ? 
                                    <NavLink to={"/setting/juristic-setting/permissions-juristic/accounting/"+this.props.id}>
                                    {t("set_permission:Accounting and Finance")}
                                    </NavLink> :
                                    <NavLink to={"/setting/juristic-setting/permissions-juristic/accounting/"}>
                                    {t("set_permission:Accounting and Finance")}
                                     </NavLink>  
                                }
                            </li>
                            <li>
                                {
                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'silverman_guard'])?.menu_on ?  
                                    this.props.id ? 
                                    <NavLink to={"/setting/juristic-setting/permissions-juristic/silvermanguard/"+this.props.id}>
                                    {t("set_permission:Siverman guard")}
                                    </NavLink> :
                                    <NavLink to={"/setting/juristic-setting/permissions-juristic/silvermanguard/"}>
                                    {t("set_permission:Siverman guard")}
                                     </NavLink>  
                                :''
                                }
                            </li>
                        </ul>
                    </div>
                    }
                </Translation>
            </div>
        )
    }
}

export default NavigationTest;