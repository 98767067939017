import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import i18next from 'i18next';

class Navigation extends Component {

    render() {
        return (
            <div className="row" id="collection-letter">
                <div className="col">
                    <ul className="navigate">
                        <li>
                            <NavLink to="/accounting/collection-law-firm/list/all" >
                                <span>{i18next.t('collectionLawFirm:All')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection-law-firm/list/law" >
                                <span>{i18next.t('collectionLawFirm:Legal Pending')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection-law-firm/list/follow" >
                                <span>{i18next.t('collectionLawFirm:Issued Notice')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection-law-firm/list/overdue">
                                <span>{i18next.t('collectionLawFirm:Due Completed')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection-law-firm/list/partial_payment">
                                <span>{i18next.t('collectionLawFirm:Partially Paid')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection-law-firm/list/paid">
                                <span>{i18next.t('collectionLawFirm:Paid')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection-law-firm/list/void">
                                <span>{i18next.t('collectionLawFirm:Void')}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
    )}
}

export default Navigation;