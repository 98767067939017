/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductAndServiceType = "EXPENSE" | "FINE" | "PRODUCT" | "SERVICE" | "%future added value";
export type CreateAndUpdateProductInput = {|
  product?: ?any,
  bringForwardList?: ?any,
  bringEditList?: ?any,
  clientMutationId?: ?string,
|};
export type CreateAndUpdateProductMutationVariables = {|
  input: CreateAndUpdateProductInput
|};
export type CreateAndUpdateProductMutationResponse = {|
  +createAndUpdateProduct: ?{|
    +newProduct: ?{|
      +id: string,
      +name: string,
      +productCode: string,
      +type: ProductAndServiceType,
      +collect: boolean,
      +chartOfAccount: ?{|
        +id: string,
        +chartOfAccountCode: string,
        +name: string,
        +nameTh: ?string,
        +nameEn: ?string,
      |},
    |}
  |}
|};
export type CreateAndUpdateProductMutation = {|
  variables: CreateAndUpdateProductMutationVariables,
  response: CreateAndUpdateProductMutationResponse,
|};
*/


/*
mutation CreateAndUpdateProductMutation(
  $input: CreateAndUpdateProductInput!
) {
  createAndUpdateProduct(input: $input) {
    newProduct {
      id
      name
      productCode
      type
      collect
      chartOfAccount {
        id
        chartOfAccountCode
        name
        nameTh
        nameEn
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAndUpdateProductPayload",
    "kind": "LinkedField",
    "name": "createAndUpdateProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceNode",
        "kind": "LinkedField",
        "name": "newProduct",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collect",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "chartOfAccount",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chartOfAccountCode",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameTh",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameEn",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAndUpdateProductMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAndUpdateProductMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "947d966ffc34557edaaf2d99b6100033",
    "id": null,
    "metadata": {},
    "name": "CreateAndUpdateProductMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAndUpdateProductMutation(\n  $input: CreateAndUpdateProductInput!\n) {\n  createAndUpdateProduct(input: $input) {\n    newProduct {\n      id\n      name\n      productCode\n      type\n      collect\n      chartOfAccount {\n        id\n        chartOfAccountCode\n        name\n        nameTh\n        nameEn\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48e1fb7bdf3eaaadb61e298b7e1937cd';

module.exports = node;
