/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CarCarType = "CAR" | "MOTORCYCLE" | "%future added value";
export type vehicleDetailQueryVariables = {|
  id: string
|};
export type vehicleDetailQueryResponse = {|
  +residential: ?{|
    +name: string,
    +car: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +licencePlate: string,
          +carBrand: ?string,
          +carModel: ?string,
          +carDescription: string,
          +carType: CarCarType,
          +chargedFee: boolean,
          +province: ?string,
          +carSticker: ?string,
          +floor: ?string,
          +space: ?string,
          +carImage: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +image: ?string,
                +description: ?string,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |}
|};
export type vehicleDetailQuery = {|
  variables: vehicleDetailQueryVariables,
  response: vehicleDetailQueryResponse,
|};
*/


/*
query vehicleDetailQuery(
  $id: ID!
) {
  residential(id: $id) {
    name
    car {
      edges {
        node {
          id
          licencePlate
          carBrand
          carModel
          carDescription
          carType
          chargedFee
          province
          carSticker
          floor
          space
          carImage {
            edges {
              node {
                id
                image
                description
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CarNodeConnection",
  "kind": "LinkedField",
  "name": "car",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CarNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CarNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "licencePlate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "carBrand",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "carModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "carDescription",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "carType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chargedFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "province",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "carSticker",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "floor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "space",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CarImageNodeConnection",
              "kind": "LinkedField",
              "name": "carImage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CarImageNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CarImageNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "image",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "vehicleDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "vehicleDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResidentialNode",
        "kind": "LinkedField",
        "name": "residential",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b42767a590f8724ee209d8aeca42117",
    "id": null,
    "metadata": {},
    "name": "vehicleDetailQuery",
    "operationKind": "query",
    "text": "query vehicleDetailQuery(\n  $id: ID!\n) {\n  residential(id: $id) {\n    name\n    car {\n      edges {\n        node {\n          id\n          licencePlate\n          carBrand\n          carModel\n          carDescription\n          carType\n          chargedFee\n          province\n          carSticker\n          floor\n          space\n          carImage {\n            edges {\n              node {\n                id\n                image\n                description\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8402d5e0025d78a292b0a2a0f7bb3c7b';

module.exports = node;
