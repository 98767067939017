import React, { Component } from 'react';
import Swal from "sweetalert2";
import API from "../../api/claim"
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import ClaimTopMenuNavigation from '../../purchasing/claim/claimTopMenuNavigation'
import i18next from 'i18next';
const _ = require('lodash');

class SettingClaim extends Component {
    constructor(props) {
        super(props);

        this.state = {
            juristic_consider: "",
            insure_approve: "",
            receive_compensation: "",
            loading: true,
            ircActive: false
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    alertActive() {
        Swal.fire({
            icon: 'error',
            title: 'ไม่สามารถบันทึกได้',
            text: 'โปรดระบุการเชื่อมต่อฐานข้อมูลจากกล้องกับระบบรักษาปลอดภัยและผู้มาติดต่อ ใช่ หรือ ไม่'
        })
    }

    componentWillMount() {
        this.getData();
    }

    getData() {
        API.settingClaim().then((res) => {
            if (res.data) {
                this.setState({
                    juristic_consider: res.data.juristic_consider,
                    insure_approve: res.data.insure_approve,
                    receive_compensation: res.data.receive_compensation,
                    loading: false,
                    ircActive: res.data.receive_compensation && res.data.insure_approve && res.data.juristic_consider ? true : false
                })
            }
        })
    }
    SettingClaim
    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true })
        let Data = {
            "juristic_consider": parseInt(this.state.juristic_consider),
            "insure_approve": parseInt(this.state.insure_approve),
            "receive_compensation": parseInt(this.state.receive_compensation),
            "button": "True",
        }

        API.settingClaim(Data).then((res) => {
            if (res.data) {
                Swal.fire(i18next.t("Allaction:Saved Successfully"), i18next.t("Allaction:Saved Successfully"), 'success').then(() => {
                    this.setState({ loading: false })
                })
            } else {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error')
            }

        }, () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Unable to Connect to API at this"), 'error'))
    }

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                <ClaimTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="settingConnectLPR">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/purchasing">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        { 
                                            t => <span>{t('settingClaim:Compensation Receiving Setting')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <br />
                        <br />
                        <form method='post' onSubmit={this.onSubmit}>
                            <Translation>
                                {t =>
                                    this.state.loading == false ?
                                        <React.Fragment>
                                            <div style={{ width: "804px", marginLeft: "15%" }}>
                                                <div className="content-inner claim">

                                                    <h4 className='text-left '>{i18next.t("settingClaim:Setting the number of days for receiving compensation")}</h4>
                                                    {/* <h4 className='text-left '>{t('claimSetting:LPRYesOrNo')}</h4> */}

                                                    <p className='mt-8' style={{ display: 'inline-flex' }}>
                                                        <label className='w222'>{i18next.t("settingClaim:Waiting for juristic consideration of the claim")}</label>
                                                        {/* <label>{t('claimSetting:LPRYesOrNo')}</label> */}
                                                        <label className='w100'>{""}</label>
                                                        {/* <label>{t('claimSetting:LPRYesOrNo')}</label> */}

                                                        <input type={"text"}
                                                            onChange={(e) => this.setState({ juristic_consider: e.target.value })}
                                                            onKeyDown={(e) => this.setState({ juristic_consider: e.target.value })}
                                                            onInvalid={e => e.target.setCustomValidity(i18next.t("settingClaim:Please enter number"))}
                                                            onInput={e => e.target.setCustomValidity('')}
                                                            pattern='[0-9]+$' className='form-control inputBox w150 text-right'
                                                            defaultValue={this.state.juristic_consider}
                                                        />

                                                        <label className='ml-5'>{i18next.t("settingClaim:Days")}</label>

                                                    </p>

                                                    <p style={{ display: 'inline-flex' }}>
                                                        <label className='w222'>{i18next.t("settingClaim:Waiting for insurance approval")}</label>
                                                        {/* <label>{t('claimSetting:LPRYesOrNo')}</label> */}
                                                        <label className='w100'>{""}</label>
                                                        {/* <label>{t('claimSetting:LPRYesOrNo')}</label> */}
                                                        <input type={"text"}
                                                            onChange={(e) => this.setState({ insure_approve: e.target.value })}
                                                            onKeyDown={(e) => this.setState({ juristic_consider: e.target.value })}
                                                            onInvalid={e => e.target.setCustomValidity(i18next.t("settingClaim:Please enter number"))}
                                                            onInput={e => e.target.setCustomValidity('')}
                                                            pattern='[0-9]+$' className='form-control inputBox w150 text-right'
                                                            defaultValue={this.state.insure_approve}
                                                        />
                                                        <label className='ml-5'>{i18next.t("settingClaim:Days")}</label>

                                                    </p>

                                                    <p style={{ display: 'inline-flex' }}>
                                                        <label className='w222'>{i18next.t("settingClaim:Waiting for resident to receive compensation")}</label>
                                                        {/* <label>{t('claimSetting:LPRYesOrNo')}</label> */}
                                                        <label className='w100'>{""}</label>
                                                        {/* <label>{t('claimSetting:LPRYesOrNo')}</label> */}
                                                        <input type={"text"}
                                                            onChange={(e) => this.setState({ receive_compensation: e.target.value })}
                                                            onKeyDown={(e) => this.setState({ juristic_consider: e.target.value })}
                                                            onInvalid={e => e.target.setCustomValidity(i18next.t("settingClaim:Please enter number"))}
                                                            onInput={e => e.target.setCustomValidity('')}
                                                            pattern='[0-9]+$' className='form-control inputBox w150 text-right'
                                                            defaultValue={this.state.receive_compensation}
                                                        />
                                                        <label className='ml-5'>{i18next.t("settingClaim:Days")}</label>
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'claim_create' }) &&
                                                <div className='pullright' style={{ marginRight: "10%" }}>
                                                <button type="submit" style={{ marginRight: "8%" }}
                                                    className="btn btn-primary add mr-1"
                                                    disabled={this.state.ircActive == null}>{t('Allaction:save')}</button>
                                                <button type="button"
                                                    onClick={() => this.props.history.push("/purchasing")}
                                                    className="btn btn-primary-outline mr-1">{t('Allaction:cancel')}</button>
                                                </div>
                                            }
                                          
                                        </React.Fragment>
                                        :
                                        <div className="container-fluid box fixRequestFast text-center" >
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert/loading.gif'} />
                                        </div>

                                }
                            </Translation>

                        </form>


                    </div>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default SettingClaim;
