/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FixRequestDetailTechnician = "IN_HOUSE" | "OUT_SOURCE" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type RoomStatus = "APPROVE" | "FINISHED" | "NOT_APPROVE" | "OPEN" | "%future added value";
export type fixRequestQueryVariables = {|
  id: string
|};
export type fixRequestQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +juristicContactNumber: ?string,
    +logo: ?string,
  |},
  +room: ?{|
    +id: string,
    +residential: {|
      +name: string,
      +project: {|
        +typeOfProject: ?ProjectTypeOfProject
      |},
    |},
    +name: string,
    +detail: ?string,
    +status: RoomStatus,
    +image: string,
    +added: any,
    +note: ?string,
    +docNumber: ?string,
    +phone: string,
    +fixRequestRoom: ?{|
      +id: string,
      +technician: FixRequestDetailTechnician,
      +technicianName: ?string,
    |},
    +handle: {|
      +tenant: ?{|
        +firstName: string,
        +lastName: ?string,
        +phone: ?string,
        +image: string,
      |}
    |},
  |},
|};
export type fixRequestQuery = {|
  variables: fixRequestQueryVariables,
  response: fixRequestQueryResponse,
|};
*/


/*
query fixRequestQuery(
  $id: ID!
) {
  selfProject {
    id
    name
    address
    juristicContactNumber
    logo
  }
  room(id: $id) {
    id
    residential {
      name
      project {
        typeOfProject
        id
      }
      id
    }
    name
    detail
    status
    image
    added
    note
    docNumber
    phone
    fixRequestRoom {
      id
      technician
      technicianName
    }
    handle {
      tenant {
        firstName
        lastName
        phone
        image
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfProject",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detail",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "FixRequestDetailNode",
  "kind": "LinkedField",
  "name": "fixRequestRoom",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "technician",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "technicianName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fixRequestQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "RoomNode",
        "kind": "LinkedField",
        "name": "room",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "residential",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "handle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v12/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fixRequestQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "RoomNode",
        "kind": "LinkedField",
        "name": "room",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "residential",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "handle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v12/*: any*/),
                  (v8/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d91636a9429de365641cef4579b3e29c",
    "id": null,
    "metadata": {},
    "name": "fixRequestQuery",
    "operationKind": "query",
    "text": "query fixRequestQuery(\n  $id: ID!\n) {\n  selfProject {\n    id\n    name\n    address\n    juristicContactNumber\n    logo\n  }\n  room(id: $id) {\n    id\n    residential {\n      name\n      project {\n        typeOfProject\n        id\n      }\n      id\n    }\n    name\n    detail\n    status\n    image\n    added\n    note\n    docNumber\n    phone\n    fixRequestRoom {\n      id\n      technician\n      technicianName\n    }\n    handle {\n      tenant {\n        firstName\n        lastName\n        phone\n        image\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '990d73885f2029c3e9e8e0a9c208adfd';

module.exports = node;
