import '../styles/withholdingTax53Print.scss';
import React, {Component} from 'react';

class WithholdingAttachmentTax53Print extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    componentDidMount() {
    }

    print() {
        window.print();
    }

    render() {
        return (
            <div id="withholding-tax-53-print">
                <div className="print-page-withholding-a4">
                    <div className="subpage">
                        <div className="withholding-tax-body">
                            <div>
                                <span className="text-header">ใบแนบ <span className="text-header-53">ภ.ง.ด.53</span> เลขประจำตัวผู้เสียภาษีอากร (ของผู้มีหน้าที่หักภาษี ณ ที่จ่าย )</span>
                                <span className="ml-3 mr-3">
                                       {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((value, index) => {
                                           if (index === 1 || index === 5 || index === 10 || index === 12) {
                                               return (
                                                   <React.Fragment key={'taxNum' + index}>
                                                       <span>-</span>
                                                       <span className="border-id-number">&nbsp;&nbsp;</span>
                                                   </React.Fragment>
                                               )
                                           }
                                           return (
                                               <span className="border-id-number"
                                                     key={'taxNum' + index}>&nbsp;&nbsp;</span>
                                           )
                                       })}
                                   </span>
                                <span className="text-header mr-2">สาขาที่</span>
                                {[1, 1, 1, 1, 1].map((value, index) => {
                                    return (
                                        <span key={'branchNum' + index}
                                              className="border-branch-number">&nbsp;&nbsp;</span>
                                    )
                                })}
                            </div>
                            <div className="header-right">แผ่นที่........ใบจำนวน.......แผ่น</div>

                            <table className="table table-bordered mt-1">
                                 <thead>
                                <tr>
                                    <th rowSpan="2" className="text-header-center" width={"4%"}>ลำดับที่</th>
                                    <th colSpan="4" className="text-center" width={"35%"}>
                                        <span className="text-th-bold">เลขประจำตัวผู้เสียภาษีอากร</span> (ของผู้มีเงินได้)
                                    </th>
                                    <th rowSpan="2" className="text-header-center" width={"8%"}>
                                        <span className="text-th-bold">สาขาที่</span>
                                    </th>
                                    <th colSpan="6" className="text-center" width={"40%"}>
                                        <strong>รายละเอียดเกี่ยวกับการจ่ายเงิน</strong>
                                    </th>
                                    <th colSpan="2" rowSpan="2" className="text-header-center" width={"10%"}>
                                        <span className="text-th-bold">จำนวนเงินภาษีที่หักและนำส่งในครั้งนี้</span>
                                    </th>
                                    <th rowSpan="2" className="text-header-center text-land p-0" width={"4%"}>
                                        เงื่อนไข <img src={process.env.PUBLIC_URL + '/images/print/number-two.png'}
                                                      alt="correct-signal" className="number"/></th>
                                </tr>
                                <tr>
                                    <th colSpan="4" className="text-center"><span
                                        className="text-th-bold">ชื่อและที่อยู่ของผู้มีเงินได้</span>
                                        <br/> (ให้ระบุให้ชัดเจนว่าเป็น บริษัทจำกัด
                                        ห้างหุ้นส่วนจำกัด หรือห้างหุ้นส่วนสามัญนิติบุคคล<br/>
                                        และให้ระบุเลขที่ ตรอก/ซอย ถนน ตำ บล/แขวง อำ เภอ/เขต จังหวัด)
                                    </th>
                                    <th className="text-header-center" width={"7%"}>
                                        <span className="text-th-bold">วัน เดือน ปี <br/>ที่จ่าย</span>
                                    </th>
                                    <th colSpan="2" className="text-header-center text-th-number" width={"13%"}>
                                        <img src={process.env.PUBLIC_URL + '/images/print/number-one.png'}
                                             alt="correct-signal" className="number"/>
                                        <span className="text-th-bold"> ประเภทเงินได้ <br/>พึงประเมินที่จ่าย</span>
                                    </th>
                                    <th className="text-header-center" width={"4%"}>อัตราภาษีร้อยละ</th>
                                    <th colSpan="2" className="text-header-center" width={"11%"}><span
                                        className="text-th-bold">จำนวนเงินที่จ่ายในครั้งนี้</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                {[1, 2, 3, 4, 5, 6].map((row_count) => {
                                    return (
                                        <React.Fragment>

                                            <tr>
                                                <td rowSpan="3"/>
                                                <td colSpan={4} className="no-border-bottom">
                                                    {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((value, index) => {
                                                        if (index === 1 || index === 5 || index === 10 || index === 12) {
                                                            return (
                                                                <React.Fragment key={'rowTaxNum' + index}>
                                                                    <span>-</span>
                                                                    <span
                                                                        className="border-id-number">&nbsp;&nbsp;</span>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        return (
                                                            <span className="border-id-number"
                                                                  key={'rowTaxNum' + index}>&nbsp;&nbsp;</span>
                                                        )
                                                    })}                                                    </td>
                                                <td className="no-border-bottom">
                                                    {[1, 1, 1, 1, 1].map((value, v_index) => {
                                                        return (
                                                            <span key={'rowBranchNum' + v_index}
                                                                  className="border-branch-number">&nbsp;&nbsp;</span>
                                                        )
                                                    })}                                                    </td>
                                                <td className="no-border-bottom text-center">
                                                    {/*วัน เดือน ปี*/}
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan={2} className="no-border-bottom">
                                                    {/*ประเภทเงินได้พึงประเมินที่จ่าย*/}
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-center">
                                                    {/*อัตราภาษีร้อยละ*/}
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-right">
                                                    {/*จำนวนเงินที่จ่ายในครั้งนี้*/}
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-right">
                                                    {/*ทศนิยม*/}
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-right">
                                                    {/*จำนวนเงินภาษีที่หักและนำส่งในครั้งนี้*/}
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-right">
                                                    {/*ทศนิยม*/}
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom text-center">
                                                    {/*เงื่อนไข*/}
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="no-border-bottom">
                                                    <p className="border-dot">ชื่อ {"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom"/>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan={2} className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td className="no-border-bottom">
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <p className="border-dot">
                                                        ที่อยู่ {"\u00a0"}
                                                    </p>
                                                </td>
                                                <td/>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td colSpan={2}>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                                <td>
                                                    <p className="border-dot">{"\u00a0"}</p>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                                <tr>
                                    <td colSpan="10" className="text-right mt-2">
                                        <strong>รวมยอดเงินได้และภาษีที่นำส่ง</strong> <span className="font-italic">(นำ
                                        ไปรวมกับ</span> <strong>ใบแนบ ภ.ง.ด.53</strong> <span className="font-italic">แผ่นอื่น (ถ้ามี))</span>
                                    </td>
                                    <td>
                                        <p className="border-dot">{"\u00a0"}</p>
                                    </td>
                                    <td>
                                        <p className="border-dot">{"\u00a0"}</p>
                                    </td>
                                    <td>
                                        <p className="border-dot">{"\u00a0"}</p>
                                    </td>
                                    <td>
                                        <p className="border-dot">{"\u00a0"}</p>
                                    </td>
                                    <td/>
                                </tr>

                                </tbody>

                                <tfoot>
                                <tr>
                                    <td colSpan={7}>
                                        <div className="row mt-1">
                                            <div className="col"><i>(ให้กรอกลำดับที่ต่อเนื่องกันไปทุกแผ่น)</i></div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-1">
                                                <strong><u>หมายเหตุ</u></strong>
                                            </div>
                                            <img src={process.env.PUBLIC_URL + '/images/print/number-one.png'}
                                                 alt="correct-signal" className="number-foot ml-4"/>
                                            <div className="col">
                                                ให้ระบุว่าจ่ายเป็นค่าอะไร เช่น ค่านายหน้า ค่าแห่งกู๊ดวิลล์
                                                ดอกเบี้ยเงินฝาก ดอกเบี้ยตั๋วเงิน เงินปันผล เงินส่วนแบ่งกำไร
                                                ค่าเช่าอาคาร ค่าสอบบัญชี ค่าออกแบบ ค่าก่อสร้างโรงเรียน
                                                ค่าซื้อเครื่องพิมพ์ดีด ค่าซื้อพืชผลทางการเกษตร (ยางพารา
                                                มันสำ ปะหลัง ปอ ข้าว ฯลฯ) ค่าจ้างทำของ ค่าจ้างโฆษณา รางวัล
                                                ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขาย
                                                รางวัลในการประกวด การแข่งขัน การชิงโชค ค่าขนส่งสินค้า
                                                ค่าเบี้ยประกันวินาศภัย
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-1"/>
                                            <img src={process.env.PUBLIC_URL + '/images/print/number-two.png'}
                                                 alt="correct-signal" className="number-foot ml-4"/>
                                            <div className="col">
                                                เงื่อนไขการหักภาษี ณ ที่จ่ายให้กรอกดังนี้
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9744;</span> หัก ณ ที่จ่าย กรอก 1</p>
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9744;</span> ออกภาษีให้ กรอก 2</p>
                                                {/*<p className="mb-0 mt-1"><span className="ballot-box">&#9744;</span> ออกให้ครั้งเดียว กรอก 3</p>*/}
                                            </div>
                                        </div>
                                    </td>
                                    <td colSpan={8}>
                                        <div className="row mt-5">
                                            <div className="col-3 div-seal pr-0">
                                                <div className="border-seal">
                                                    <span className="text-center">ประทับตรานิติบุคคล (ถ้ามี)</span>
                                                </div>
                                            </div>
                                            <div className="col-9 pl-0">
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <p className="mb-2">ลงชื่อ..................................................................................ผู้จ่ายเงิน</p>
                                                        <p className="mb-2">(..........................................................................)</p>
                                                        <p className="mb-2">ตำแหน่ง........................................................................................</p>
                                                        <p className="mb-2">ยื่นวันที่.......................เดือน...................................พ.ศ....................</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            <div className="row mt-1">
                                <div className="col">
                                    <strong><i>สอบถามข้อมูลเพิ่มเติมได้ที่ศูนย์สารนิเทศสรรพากร RD Intelligence Center
                                        โทร. 1161</i></strong>
                                </div>
                                <div className="col text-right">
                                    <strong>พิมพ์ มี.ค. 2560</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithholdingAttachmentTax53Print;
