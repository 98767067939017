/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type collectionTotalQueryVariables = {|
  contactId?: ?string,
  startDate?: ?string,
|};
export type collectionTotalQueryResponse = {|
  +collectionTotal: ?number
|};
export type collectionTotalQuery = {|
  variables: collectionTotalQueryVariables,
  response: collectionTotalQueryResponse,
|};
*/


/*
query collectionTotalQuery(
  $contactId: String
  $startDate: String
) {
  collectionTotal(contactId: $contactId, startDate: $startDate)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contactId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDate"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contactId",
        "variableName": "contactId"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "kind": "ScalarField",
    "name": "collectionTotal",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionTotalQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionTotalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "95e3f46894d8d3224d62214881a62172",
    "id": null,
    "metadata": {},
    "name": "collectionTotalQuery",
    "operationKind": "query",
    "text": "query collectionTotalQuery(\n  $contactId: String\n  $startDate: String\n) {\n  collectionTotal(contactId: $contactId, startDate: $startDate)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9c30208bfd7935f03d06ab0b084347b';

module.exports = node;
