/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OwnershipStatus = "MOVE_IN" | "MOVE_OUT" | "%future added value";
export type ownerChangeQueryVariables = {||};
export type ownerChangeQueryResponse = {|
  +residentialOwnership: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +ownership: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +status: OwnershipStatus,
              +ownershipDate: any,
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type ownerChangeQuery = {|
  variables: ownerChangeQueryVariables,
  response: ownerChangeQueryResponse,
|};
*/


/*
query ownerChangeQuery {
  residentialOwnership(dashboard: true, first: 4) {
    totalCount
    edges {
      node {
        id
        name
        ownership {
          edges {
            node {
              id
              name
              status
              ownershipDate
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "dashboard",
        "value": true
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 4
      }
    ],
    "concreteType": "ResidentialNodeConnection",
    "kind": "LinkedField",
    "name": "residentialOwnership",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OwnershipNodeConnection",
                "kind": "LinkedField",
                "name": "ownership",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OwnershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OwnershipNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ownershipDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "residentialOwnership(dashboard:true,first:4)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ownerChangeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ownerChangeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d867c41a358f6c22860d62a15e6680b5",
    "id": null,
    "metadata": {},
    "name": "ownerChangeQuery",
    "operationKind": "query",
    "text": "query ownerChangeQuery {\n  residentialOwnership(dashboard: true, first: 4) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        ownership {\n          edges {\n            node {\n              id\n              name\n              status\n              ownershipDate\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5f2c17317a507f8e533f8d4161baf4af';

module.exports = node;
