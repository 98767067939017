/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateServicePricingEachUnitInput = {|
  data?: ?any,
  serviceId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateServicePricingEachUnitMutationVariables = {|
  input: CreateServicePricingEachUnitInput
|};
export type CreateServicePricingEachUnitMutationResponse = {|
  +createServicePricingEachUnit: ?{|
    +totalServicePricingEachUnit: ?number
  |}
|};
export type CreateServicePricingEachUnitMutation = {|
  variables: CreateServicePricingEachUnitMutationVariables,
  response: CreateServicePricingEachUnitMutationResponse,
|};
*/


/*
mutation CreateServicePricingEachUnitMutation(
  $input: CreateServicePricingEachUnitInput!
) {
  createServicePricingEachUnit(input: $input) {
    totalServicePricingEachUnit
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateServicePricingEachUnitPayload",
    "kind": "LinkedField",
    "name": "createServicePricingEachUnit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalServicePricingEachUnit",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateServicePricingEachUnitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateServicePricingEachUnitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80b22637777396b2d17c4289a06501f8",
    "id": null,
    "metadata": {},
    "name": "CreateServicePricingEachUnitMutation",
    "operationKind": "mutation",
    "text": "mutation CreateServicePricingEachUnitMutation(\n  $input: CreateServicePricingEachUnitInput!\n) {\n  createServicePricingEachUnit(input: $input) {\n    totalServicePricingEachUnit\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65554bf56d01c39eed72a7091d01840f';

module.exports = node;
