import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import {graphql} from "babel-plugin-relay/macro";
import BankTransferListTable from "./bankTransferListTable";

const bankTransferList = graphql`
    query bankTransferListQuery($first: Int, $last: Int,$start_date: DateTime, $end_date: DateTime,$order: String, $search: String,$status:String) {
        bankTransferViewer {
            allBankTransfer(first: $first, last: $last, startDate: $start_date, endDate: $end_date, order: $order, search: $search,status:$status) {
                totalCount
                pageInfo{
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        id
                        docNumber
                        transferOut {
                            id
                            bankName
                            accountNumber
                            accountType
                        }
                        transferIn {
                            id
                            bankName
                            accountNumber
                            accountType
                        }
                        fee
                        amount
                        date
                        description
                        added
                        status
                    }
                }
            }
        }
    }
`;

class BankTransferList extends Component {
    
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">
                        <BankTransferListTable query={bankTransferList}
                        status={this.props.match.params.status} />
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}
export default BankTransferList;