/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUnknownReceiveInput = {|
  status?: ?string,
  voidRemark?: ?string,
  imageSlip?: ?string,
  clientMutationId?: ?string,
|};
export type unknownReceiveVoidMutationVariables = {|
  input: UpdateUnknownReceiveInput
|};
export type unknownReceiveVoidMutationResponse = {|
  +updateUnknownReceive: ?{|
    +newUnknownReceive: ?{|
      +id: string
    |},
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type unknownReceiveVoidMutation = {|
  variables: unknownReceiveVoidMutationVariables,
  response: unknownReceiveVoidMutationResponse,
|};
*/


/*
mutation unknownReceiveVoidMutation(
  $input: UpdateUnknownReceiveInput!
) {
  updateUnknownReceive(input: $input) {
    newUnknownReceive {
      id
    }
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUnknownReceivePayload",
    "kind": "LinkedField",
    "name": "updateUnknownReceive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnknownReceiveNode",
        "kind": "LinkedField",
        "name": "newUnknownReceive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "unknownReceiveVoidMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "unknownReceiveVoidMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0a43eafa1e5eb0f79f9ba96db1f5bf94",
    "id": null,
    "metadata": {},
    "name": "unknownReceiveVoidMutation",
    "operationKind": "mutation",
    "text": "mutation unknownReceiveVoidMutation(\n  $input: UpdateUnknownReceiveInput!\n) {\n  updateUnknownReceive(input: $input) {\n    newUnknownReceive {\n      id\n    }\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f216afaa4a0c2c2f99f5e2fe5b29efc7';

module.exports = node;
