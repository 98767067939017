import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t =>
                            <ul>
                                <li>
                                    <NavLink to={"/contact/survey/detail/all/" + this.props.id}>
                                        {t('survey:all')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/contact/survey/detail/not-approve/" + this.props.id}>
                                        {t('survey:vote_success')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/contact/survey/detail/not-vote/" + this.props.id}>
                                        {t('survey:not_voted')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/contact/survey/detail/approve/" + this.props.id}>
                                        {t('survey:submit_document')}
                                    </NavLink>
                                </li>
                            </ul>
                        }
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;