import React from 'react';
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import ChartForeignOwnership from './foreignOwnershipChart/chartForeignOwnerships';
import ForeignOwnershipTable from './foreignOwnershipTable';
import SeacrhDetail from '../../components/search';
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from '../../env/environment';
import Loading from '../../libs/loading';
import ExportForeignOwnership from './foreignOwnershipExportExcel';
import PDFForeignOwnership from './foreignOwnershipExportPDF';
import Pagination from '../../libs/newPagination';
import ComponentPagination from '../../libs/componentPagination';
import RegisterTopMenuNavigation from '../registerTopMenuNavigation';

const query = graphql`
    query foreignOwnershipQuery($search: String $first: Int $last: Int) {
        selfProject{
            typeOfProject
        }
        ownershipSummaryPercent
        foreignOwnershipPercent
        allForeignOwnership(search: $search residentialList: true first: $first last: $last) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                id
                name
                floor
                size
                status
                ownershipRatio
                ownershipRatioShow
                unitNumber
                residentialHouseholder(type:"OWNER") {
                    edges {
                    node {
                        type
                        id
                        tenant {
                        firstName
                        lastName
                        countries
                        nameTitle
                        }
                    }
                    }
                }
                type {
                    id
                    name
                }
                ownershipArea
                }
            }
        }
        allNoticeForeignOwnership {
        totalCount
        edges {
            node {
            id
            percentNoti
            }
        }
        }
        areaSummary(
            search: $search
        )
    }
`;
class ForeignOwnership extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          checkFetchState: true,
          contentItems: [],
          search: "",
          dataOwnershipSummaryPercent: "",
          dataForeignOwnershipPercent: "",
          dataAllForeignOwnership: "",
          allNoti: "",
          percenMessageAlert: "",
          first: this.state.first,
          last: this.state.last,
          typeOfProject : "",
        };
        this.handlePropsChange = this.handlePropsChange.bind(this);
        this.queryData = this.queryData.bind(this);
    }

    componentWillMount(){
        this.queryData()
    }

    queryData() {
        let variables = {
            search: this.state.search,
            first: this.state.first,
            last: this.state.last,
        }
        this.setState({loading: true})
        fetchQuery(environment, query, variables).then(response => {
            if (response) {
                this.setState({
                    dataForeignOwnershipPercent: response.foreignOwnershipPercent,
                    dataOwnershipSummaryPercent: response.ownershipSummaryPercent,
                    dataAllForeignOwnership: response.allForeignOwnership,
                    loading: false,
                    allNoti : response.allNoticeForeignOwnership,
                    typeOfProject : response.selfProject.typeOfProject
                },()=>{
                    let messageAlert = "";
                    let nationNoti = JSON.parse(this.state.dataOwnershipSummaryPercent)?.nationality_not_th_percent

                    for (const noti of this.state.allNoti?.edges) {
                        messageAlert = noti.node.percentNoti;
                        if (nationNoti - noti.node.percentNoti < 0) {
                            break; 
                        }
                    }
                    this.setState({
                        percenMessageAlert:messageAlert
                    })
                })
            } 
        }).catch(error => {
            console.error('Error:', error);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.first !== this.state.first ||
            prevState.last !== this.state.last ||
            prevState.search !== this.state.search
          ) {
            this.queryData()
          }
    }

    handlePropsChange(channelList) {
        let search = ""
        channelList.forEach((e) => {
          if (e.name === "search") {
            search = e.value
          }
        })
        this.setState({
            search: search,
        })
    }    
    
    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <RegisterTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="foreignOwnershipNotifications">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/register">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <span>
                                    <Translation>{t => t("foreignOwnership:foreignOwnership")}</Translation>
                                    </span>
                                </h3>
                            </div>
                            <div className="col">
                                {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: "report_receivable_print",}) && */}
                                    <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                                        {this.state.loading ?
                                            <React.Fragment>
                                            <button type="button" className="btnPrint">
                                            <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                            <Translation>{t => t("meter:Preparing information")}</Translation>
                                            </button>
                                        </React.Fragment>
                                        :                  
                                        <React.Fragment>
                                            <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                                            <Translation>{t => t("PageList:Print")}</Translation>
                                            </button>
                                        </React.Fragment>
                                        }
                                        <div className="dropdown-menu">
                                            <ExportForeignOwnership 
                                                search = {this.state.search}
                                            />
                                            <PDFForeignOwnership
                                                search = {this.state.search}
                                            />
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="content-inner">
                            {this.state.loading ?
                                <Loading/>
                                    :
                                <React.Fragment>
                                    <ChartForeignOwnership 
                                        dataForeignOwnershipPercent = {this.state.dataForeignOwnershipPercent}
                                        dataOwnershipSummaryPercent = {this.state.dataOwnershipSummaryPercent}
                                        percenMessageAlert = {this.state.percenMessageAlert}
                                        typeOfProject = {this.state.typeOfProject}
                                    /> 
                                    <SeacrhDetail
                                        selectIs={false}
                                        startDateIs={false}
                                        endDateIs={false}
                                        searchBoxId={true}
                                        search={this.state.search}
                                        handlePropsChange={this.handlePropsChange}
                                    />       
                                    <ForeignOwnershipTable
                                        dataAllForeignOwnership = {this.state.dataAllForeignOwnership}
                                    />
                                    <div className='row'>
                                    <Pagination
                                        changePage={this.changePage}
                                        first={this.state.first}
                                        last={this.state.last}
                                        totalCount={this.state.dataAllForeignOwnership.totalCount}
                                    />
                                    </div>
                                </React.Fragment>
                            }
                              

                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ForeignOwnership;