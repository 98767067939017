import React, { Component } from "react";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import ClaimReportStatus from "./claimReportStatus";
import DatePicker from "react-datepicker";
import APIClaimReport from "../../../api/claimReport";
import "../style/claimReport.scss";
import "../style/claim.scss";
import i18next from "i18next";
import BackButtonIcon from "../../../components/BackBtn/indexBack";

export default class ClaimReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claimReportDetails: [],
      claimReportAssets: [],
      claimReportImgs: [],
      file: [],
    };
  }

  getStatus(status) {
    let valueResult = "";
    let colorResult = "";

    switch (status) {
      case "consider_fail":
        valueResult = "Canceled";
        colorResult = "statusRed";
        break;
      case "not_approve":
        valueResult = "Canceled";
        colorResult = "statusRed";
        break;
      case "finish":
        valueResult = "Finished";
        colorResult = "statusGreen";
        break;
    }

    return (
      <Translation>
        {t =>
          <div className={`${colorResult}`}>
            <span>{i18next.t("claimRequstList:"+valueResult)}</span>
          </div>
        }
      </Translation>

    );
  }

  getData() {
    let dataParams = {
      id: this.state.first,
      last: this.state.last,
    };
    APIClaimReport.getClaimReportById(this.props.match.params.id).then(
      (res) => {
        if (res.data) {
          this.setState({
            claimReportDetails: res.data.claim.claim_detail,
            claimReportAssets: res.data.claim.asset_claim,
            claimReportImgs: res.data.claim.image_claim,
            file: res.data.claim.file_claim,
          });
        }
      }
    );
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <Translation>
            {(t) => (
              <div className="container-fluid box claim-report-detail">
                <BackButtonIcon
                  LinkBack={"/purchasing/claim/report"}
                  LinkText={this.state.claimReportDetails.doc_number && this.state.claimReportDetails.doc_number}
                />
                <div className="content-inner pt-6">
                  {
                    <React.Fragment>
                      <div className="row">
                        <div className="statusClass status-wrapper">
                          <ClaimReportStatus
                            status={this.state.claimReportDetails.status}
                          />
                        </div>
                        <div className="col-10 mx-auto">
                          <form>
                            <div className="pt-12">
                              <section className="HeaderForCommon">
                                <React.Fragment>
                                  <div style={{ display: "inline" }}>
                                    <label
                                      className="headerLabel24"
                                      style={{ color: "#808080" }}
                                    >
                                      <input
                                        type={"radio"}
                                        name="claim_type"
                                        checked={true}
                                        disabled={true}
                                        className="mr-2"
                                      />
                                      {this.state.claimReportDetails
                                        .claim_type === "private"
                                        ? i18next.t("claimReportDetail:Personal")
                                        : i18next.t("claimReportDetail:Common")}
                                    </label>
                                    <label
                                      style={{ marginLeft: "190px" }}
                                    ></label>
                                  </div>
                                </React.Fragment>
                              </section>

                              <section className="GeneralInformation mt-10">
                                <div className="GeneralInformation">
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      <h3>{i18next.t("claimReportDetail:General Information")}</h3>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "80%",
                                      }}
                                    >
                                      <h3>{i18next.t("claimReportDetail:Status")}:</h3>
                                      <div className="pl-3">
                                        {this.getStatus(
                                          this.state.claimReportDetails.status
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  {
                                    /*แจ้งเคลมส่วนตัว */
                                    this.state.claimReportDetails.claim_type ===
                                      "private" ? (
                                      <div>
                                        <section className="findUnit">
                                          <label className="headerLabel16">
                                          {i18next.t("claimReportDetail:Room Search")}
                                            <span className="text-red">*</span>
                                          </label>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "50%",
                                            }}
                                          >
                                            <input
                                              className="form-control inputBox"
                                              value={
                                                this.state.claimReportDetails
                                                  .claim_type === "public"
                                                  ? i18next.t("claimReportDetail:Common Area")
                                                  : this.state
                                                    .claimReportDetails
                                                    .residential &&
                                                  this.state
                                                    .claimReportDetails
                                                    .residential.name
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </section>

                                        <section className="findNameRequest">
                                          <label className="headerLabel16">
                                          {i18next.t("claimReportDetail:Select the notifier")}
                                            <span className="text-red">*</span>
                                          </label>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "50%",
                                            }}
                                          >
                                            <input
                                              className="form-control inputBox"
                                              type="text"
                                              value={
                                                this.state.claimReportDetails
                                                  .other_contact
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </section>

                                        <section className="phone_contract">
                                          <label className="headerLabel16">
                                          {i18next.t("claimReportDetail:Notifier's contact number")}
                                          </label>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "50%",
                                            }}
                                          >
                                            <input
                                              className="form-control inputBox"
                                              type={"text"}
                                              value={
                                                this.state.claimReportDetails
                                                  .phone_contact
                                              }
                                              name=""
                                              disabled={true}
                                            />
                                          </div>
                                        </section>
                                      </div>
                                    ) : (
                                      /*แจ้งเคลมส่วนกลาง */
                                      <div>
                                        <section className="findUnit">
                                          <label className="headerLabel16">
                                          {i18next.t("claimReportDetail:Select Legal Officer (Notifier)")}
                                            <span className="text-red">*</span>
                                          </label>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "50%",
                                            }}
                                          >
                                            <input
                                              className="form-control inputBox"
                                              value={
                                                this.state.claimReportDetails
                                                  .juristic_contact &&
                                                this.state.claimReportDetails
                                                  .juristic_contact.first_name
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </section>

                                        <section className="findNameRequest">
                                          <label className="headerLabel16">
                                          {i18next.t("claimReportDetail:Notifier's contact number")}
                                            <span className="text-red">*</span>
                                          </label>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "50%",
                                            }}
                                          >
                                            <input
                                              className="form-control inputBox"
                                              type="text"
                                              value={
                                                this.state.claimReportDetails
                                                  .phone_contact
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </section>
                                      </div>
                                    )
                                  }
                                </div>
                              </section>

                              <div className="detailCliam">
                                <h3 className="mt-12">{i18next.t("claimReportDetail:Claim Details")}</h3>
                                <hr />

                                <section className="header">
                                  <label>
                                  {i18next.t("claimReportDetail:Claim subject")}
                                    <span className="text-red">*</span>
                                  </label>
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "50%",
                                    }}
                                  >
                                    <input
                                      className="form-control inputBox"
                                      type={"text"}
                                      name=""
                                      value={
                                        this.state.claimReportDetails.header
                                      }
                                      disabled={true}
                                    />
                                  </div>
                                </section>

                                <section className="description">
                                  <label>
                                  {i18next.t("claimReportDetail:Nature of the incident")}
                                    <span className="text-red"></span>
                                  </label>
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "50%",
                                    }}
                                  >
                                    <textarea
                                      cols="10"
                                      value={
                                        this.state.claimReportDetails
                                          .description
                                      }
                                      style={{ height: "auto" }}
                                      className="form-control textareaBox inputBox"
                                      disabled={true}
                                    />
                                  </div>
                                </section>

                                <section className="incident_date">
                                  <label>
                                  {i18next.t("claimReportDetail:Date of the incident")}
                                    <span className="text-red">*</span>
                                  </label>
                                  <br />
                                  <DatePicker
                                    name="incident_date"
                                    className="form-control inputBox"
                                    readOnly={true}
                                    value={
                                      this.state.claimReportDetails
                                        .incident_date
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/yyyy"
                                  />
                                </section>

                                <section className="issued_date">
                                  <label>
                                  {i18next.t("claimReportDetail:Date of claim notification")}
                                    <span className="text-red"></span>
                                  </label>
                                  <br />
                                  <DatePicker
                                    name="issued_dateDate"
                                    className="form-control inputBox"
                                    value={
                                      this.state.claimReportDetails.issued_date
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/yyyy"
                                    readOnly={true}
                                  />
                                </section>
                              </div>

                              <div className="detailAsset">
                                <h3 className="mt-12">
                                {i18next.t("claimReportDetail:Related Property Information")}
                                </h3>
                                <hr />

                                <label className="label16 mt-2 mb-5">
                                {i18next.t("claimReportDetail:List of Damaged Assets")}{" "}
                                  <span className="text-red">*</span>
                                </label>

                                <table className="table table-border">
                                  <thead className="bg-gray">
                                    <tr>
                                      <th style={{ width: "30%" }}>{i18next.t("claimReportDetail:Item")}</th>
                                      <th style={{ width: "70%" }}>{i18next.t("claimReportDetail:Description")}</th>
                                      <th width={50}></th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <React.Fragment>
                                      {this.state.claimReportAssets.length >
                                        0 ? (
                                        this.state.claimReportAssets?.map(
                                          (n, assetsIndex) => {
                                            return (
                                              <tr
                                                key={assetsIndex}
                                                className=""
                                              >
                                                <td>
                                                  <input
                                                    type={"text"}
                                                    name=""
                                                    className="form-control"
                                                    disabled={true}
                                                    defaultValue={n.assets}
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type={"text"}
                                                    name=""
                                                    className="form-control"
                                                    disabled={true}
                                                    defaultValue={n.description}
                                                  />
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <tr className="text-center">
                                          <td colSpan={3}>
                                          {i18next.t("claimReportDetail:List of Damaged Assets")}
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  </tbody>
                                </table>
                                <hr />
                              </div>

                              <div className="UploadImage mt-12">
                                <label className="headerLabel18">
                                {i18next.t("claimReportDetail:Photos of Damage")}
                                  <span className="text-red"></span>
                                </label>
                                <div className="mt-5">
                                  <label
                                    htmlFor="UploadImage"
                                    className="upload-btn-wrapper"
                                  >
                                    <button
                                      disabled={true}
                                      className={"btn width110 disabled"}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/images/icons/defualt.png"
                                        }
                                        style={{ height: "10px" }}
                                      />
                                      {i18next.t("claimReportDetail:Attach photos")}{" "}
                                    </button>
                                    <span
                                      style={{
                                        color: "#BFBFBF",
                                        fontSize: "12px",
                                        marginLeft: "16px",
                                      }}
                                    >
                                      {i18next.t("Allaction:TypeJust")} .png, .jpg, .jpeg {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                    </span>

                                    <div className="pt-4">
                                      {this.state.claimReportImgs?.map(
                                        (n, inxx) => {
                                          return (
                                            // <LightBox image={n.file_upload} height={'auto'} />
                                            <img src={n.file_upload} style={{ width: 100, height: 100, marginRight: 5 }}
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "/images/icons/defualt.png";
                                              }} key={"image" + inxx} />
                                          );
                                        }
                                      )}
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <section className="UploadFile mt-12">
                                <label className="headerLabel24">
                                {i18next.t("claimReportDetail:Attach Supporting Documents for Claim Request")}{" "}
                                  <span className="text-red"></span>
                                </label>
                                <hr /> <br />
                                {/* 1 */}
                                <div>
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                      1. {i18next.t("claimReportDetail:Attach the form for claiming compensation")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile1"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className="btn disabled"
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >
                                        {i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile1"
                                        className="form-control uploadFile"
                                        accept="application/.pdf,.png,.jpg"
                                      />
                                    </label>
                                  </div>
                                  <div className={"pb-8"}>
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "1" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                {/* 2 */}
                                <div className="pt-4">
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                      2. {i18next.t("claimReportDetail:Attach a copy of your ID card")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile2"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className="btn disabled"
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >
                                        {i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile2"
                                        className="form-control uploadFile"
                                        accept="application/.pdf,.png,.jpg"
                                      />
                                    </label>
                                  </div>
                                  <div className={"pb-8"}>
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "2" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                {/* 3 */}
                                <div className="pt-4">
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                      3. {i18next.t("claimReportDetail:Attach a letter claiming damages")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile3"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className="btn disabled"
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >{i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile3"
                                        className="form-control uploadFile"
                                        accept="application/.pdf,.png,.jpg"
                                      //onChange={(e) => this.uploadFile(e, "3")}
                                      />
                                    </label>
                                  </div>
                                  <div className="pb-8">
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "3" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                {/* 4 */}
                                <div className="pt-4">
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                    4. {i18next.t("claimReportDetail:Attach a copy of your ID card")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile4"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className="btn disabled"
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >{i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile4"
                                        className="form-control uploadFile"
                                        placeholder="ระบุเบอร์โทร"
                                        accept="application/.pdf,.png,.jpg"
                                      // onChange={(e) => this.uploadFile(e, "4")}
                                      />
                                    </label>
                                  </div>
                                  <div className="pb-8">
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "4" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                {/* 5 */}
                                <div className="pt-4">
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                      5. {i18next.t("claimReportDetail:Attach receipts or quotations for property repair or purchase")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile5"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className="btn disabled"
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >{i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile5"
                                        className="form-control uploadFile"
                                        placeholder="ระบุเบอร์โทร"
                                        accept="application/.pdf,.png,.jpg"
                                      />
                                    </label>
                                  </div>
                                  <div className="pb-8">
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "5" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                {/* 6 */}
                                <div className="pt-4">
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                     6. {i18next.t("claimReportDetail:Attach daily records")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile6"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className={"btn disabled"}
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >{i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile6"
                                        className="form-control uploadFile"
                                        placeholder="ระบุเบอร์โทร"
                                        accept="application/.pdf,.png,.jpg"
                                      //onChange={(e) => this.uploadFile(e, "6")}
                                      />
                                    </label>
                                  </div>
                                  <div className="pb-8">
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "6" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                {/* 7 */}
                                <div className="pt-4">
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                     7. {i18next.t("claimReportDetail:Attach a copy of the land deed")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile7"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className={"btn disabled"}
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >{i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile7"
                                        className="form-control uploadFile"
                                        placeholder="ระบุเบอร์โทร"
                                        accept="application/.pdf,.png,.jpg"
                                      />
                                    </label>
                                  </div>
                                  <div className="pb-8">
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "7" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                {/* 8 */}
                                <div className="pt-4">
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                     8.{i18next.t("claimReportDetail:Attach copies of various relevant contracts")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile8"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className={"btn disabled"}
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >{i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile8"
                                        className="form-control uploadFile"
                                        placeholder="ระบุเบอร์โทร"
                                        accept="application/.pdf,.png,.jpg"
                                        onChange={(e) =>
                                          this.uploadFile(e, "8")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="pb-8">
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "8" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                {/* 9 */}
                                <div className="pt-4">
                                  <div style={{ display: "inline-flex" }}>
                                    <label className="headerLabel18 w370">
                                     9.{i18next.t("claimReportDetail:Attach other relevant documents")}
                                    </label>
                                    <label
                                      htmlFor="uploadFile9 "
                                      className="upload-btn-wrapper"
                                    >
                                      <button className={"btn disabled"}>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/fileUpload.png"
                                          }
                                        />
                                        {t("silvermanguard:Choose File")}{" "}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >{i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="uploadFile9"
                                        className="form-control uploadFile"
                                        placeholder="ระบุเบอร์โทร"
                                        accept="application/.pdf,.png,.jpg"
                                      // onChange={(e) => this.uploadFile(e, "9")}
                                      />
                                    </label>
                                  </div>
                                  <div className="pb-8">
                                    {this.state.file.length > 0 &&
                                      this.state.file?.map((n, index) => {
                                        return (
                                          <div>
                                            {n.file_type === "9" && (
                                              <a
                                                download="name_of_downloaded_file"
                                                href={n.file_upload}
                                                target={"_blank"}
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/pdf-icon.png"
                                                  }
                                                  alt="back"
                                                  className="download-file-icon"
                                                />
                                                <span className="text-blue">
                                                  {n.file_name}
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </section>

                              <div className="ResultClaim mt-12 mb-12">
                                <label className="headerLabel24">
                                {i18next.t("claimReportDetail:Summary of the claimed amount")}
                                </label>
                                <br /> <hr /> <br />
                                {this.state.claimReportDetails.status ===
                                  "finish" && (
                                    <div>
                                      <div className="row">
                                        <div className="col-3">
                                          <label className="headerLabel16">
                                          {i18next.t("claimRequst:Amount requested for compensation (THB)")}
                                          </label>
                                          <br />
                                          <input
                                            type={"number"}
                                            name="amountCliam"
                                            defaultValue={
                                              this.state.claimReportDetails
                                                .claim_amount &&
                                              this.state.claimReportDetails
                                                .claim_amount
                                            }
                                            className="form-control inputBox text-right"
                                            style={{ width: 222 }}
                                            disabled={true}
                                          />
                                        </div>
                                        <div className="col-3">
                                          <label className="headerLabel16">
                                            จำนวนเงินเคลมที่อนุมัติ (บาท)
                                          </label>
                                          <br />
                                          <input
                                            type={"number"}
                                            name="amountCliamApproved"
                                            value={
                                              this.state.claimReportDetails
                                                .approved_amount &&
                                              this.state.claimReportDetails
                                                .approved_amount
                                            }
                                            placeholder="0.00"
                                            className="form-control inputBox text-right"
                                            style={{ width: 222 }}
                                            disabled={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-3">
                                          <label className="headerLabel16">
                                          {i18next.t("claimReportDetail:Amount requested for compensation (THB)")}
                                          </label>
                                          <br />
                                          <input
                                            type={"number"}
                                            name="amountCliam"
                                            value={Math.abs(
                                              this.state.claimReportDetails
                                                .approved_amount -
                                              this.state.claimReportDetails
                                                .claim_amount
                                            )}
                                            placeholder="0.00"
                                            className="form-control inputBox text-right"
                                            style={{ width: 222 }}
                                            disabled={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <label className="headerLabel16">
                                          {i18next.t("claimRequst:Claim Closure Date")}
                                          </label>
                                          <br />
                                          <DatePicker
                                            name="finish_date"
                                            className="form-control inputBox"
                                            value={
                                              this.state.claimReportDetails
                                                .finish_date
                                            }
                                            maxDate={new Date()}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {this.state.claimReportDetails.status ===
                                  "consider_fail" ||
                                  this.state.claimReportDetails.status ===
                                  "not_approve" ? (
                                  <>
                                    <div className="row">
                                      <div className="col-3">
                                        <label className="headerLabel16">
                                        {i18next.t("claimReportDetail:Amount requested for compensation (THB)")}
                                        </label>
                                        <br />
                                        <input
                                          type={"number"}
                                          name="amountCliam"
                                          value={
                                            this.state.claimReportDetails
                                              .claim_amount &&
                                            this.state.claimReportDetails
                                              .claim_amount
                                          }
                                          className="form-control inputBox text-right"
                                          style={{ width: 222 }}
                                          disabled={true}
                                        />
                                      </div>
                                      {this.state.claimReportDetails
                                        .claim_type === "public" && (
                                          <div className="col-3">
                                            <label className="headerLabel16">
                                            {i18next.t("claimRequst:Approved Claim Amount (THB)")}
                                            </label>
                                            <br />
                                            <input
                                              type={"number"}
                                              name="amountCliamApproved"
                                              value={
                                                this.state.claimReportDetails
                                                  .approved_amount &&
                                                this.state.claimReportDetails
                                                  .approved_amount
                                              }
                                              className="form-control inputBox text-right"
                                              style={{ width: 222 }}
                                              disabled={true}
                                            />
                                          </div>
                                        )}
                                    </div>
                                    {this.state.claimReportDetails
                                      .claim_type === "public" && (
                                        <div className="row">
                                          <div className="col-3">
                                            <label className="headerLabel16">
                                            {i18next.t("claimRequst:Difference in Property Value (THB)")}
                                            </label>
                                            <br />
                                            <input
                                              type={"number"}
                                              name="amountCliam"
                                              value={Math.abs(
                                                this.state.claimReportDetails
                                                  .approved_amount -
                                                this.state.claimReportDetails
                                                  .claim_amount
                                              )}
                                              className="form-control inputBox text-right"
                                              style={{ width: 222 }}
                                              disabled={true}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    <div>
                                      {this.state.claimReportDetails.status ===
                                        "consider_fail" && (
                                          <label className="headerLabel16">
                                            {i18next.t("claimRequst:Reasons for rejecting the claim job")}:{" "}
                                            {this.state.claimReportDetails
                                              .void_remark &&
                                              this.state.claimReportDetails
                                                .void_remark}{" "}
                                          </label>
                                        )}
                                      {this.state.claimReportDetails.status ===
                                        "not_approve" && (
                                          <label className="headerLabel16">
                                            {i18next.t("claimRequst:Cancel Claim Job")} : {i18next.t("claimRequstList:Warranty disapproved")}{" "}
                                          </label>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>

                              {this.state.claimReportDetails.claim_type ===
                                "private" &&
                                this.state.claimReportDetails.status ===
                                "finish" ? (
                                <div className="ResultClaim mt-12 mb-12">
                                  <label className="headerLabel28">
                                  {i18next.t("claimRequst:Cash Payment Evidence")}
                                  </label>
                                  <hr /> <br />
                                  <label className="headerLabel18">
                                  {i18next.t("claimRequst:Photo Assets Broken")} {" "}
                                    <span className="text-red"></span>
                                  </label>
                                  <br />
                                  <div className="UploadImageReport mt-5">
                                    <label
                                      htmlFor="UploadImage"
                                      className="upload-btn-wrapper"
                                    >
                                      <button
                                        className="btn width110 disabled"
                                        disabled={true}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/icons/defualt.png"
                                          }
                                          style={{ height: "10px" }}
                                        />
                                        {i18next.t("claimRequst:Attach photos")}
                                      </button>
                                      <span
                                        style={{
                                          color: "#BFBFBF",
                                          fontSize: "12px",
                                          marginLeft: "16px",
                                        }}
                                      >
                                        {i18next.t("Allaction:TypeJust")} PDF ,DOC ,PNG ,JPG {i18next.t("Allaction:only")} {i18next.t("Allaction:and each file should not exceed")} 2MB {" "}
                                      </span>
                                      <div className="pt-8">
                                        {this.state.claimReportDetails
                                          .finish_image_path && (
                                            <img
                                              src={
                                                this.state.claimReportDetails
                                                  .finish_image_path
                                              }
                                              style={{
                                                width: "263px",
                                                height: "171px",
                                              }}
                                            />
                                          )}
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>
            )}
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}
