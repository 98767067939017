import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import { format } from "date-fns";
import Link from "react-router-dom/es/Link";
import getNameResidential from "../../../../libs/getNameResidential";
import localStorage from "../../../../libs/localstorage";
import i18next from "i18next";

class GuaranteeMoneyReceivedReportTable extends React.Component {
    paymentChannel(guarantee) {
        let text = '-';
        if (guarantee.cashDepositReceiveTransaction.totalCount > 0) {
            text = "เงินสด";
        } else if (guarantee.bankAccountTransaction.totalCount > 0) {
            text = "เงินโอน";
        } else if (guarantee.chequeDeposit.totalCount > 0) {
            text = "เช็ค";
        }
        return text
    }

    getStatus(status) {
        let list = {
            "IN_PROCESS": "อยู่ระหว่างดำเนินการ",
            "FORFEIT_ALL": "ทำการยึด",
            "RETURN_FORFEIT": "ยึดบางส่วน/คืนบางส่วน",
            "COMPLETED": "ทำการคืน",
            "VOID": "รายการที่ยกเลิก",
        };
        return list[status]
    }

    render() {
        let total = 0;
        return (
            <React.Fragment>
                {this.props.props.allGuaranteeMoneyReceived.edges.map((guarantee, index) => {
                    let row_number = index + 1;
                    let order_id = this.props.state.first - localStorage.getPageLimit();
                    if (guarantee.node.status !== "VOID") {
                        total += guarantee.node.amount;
                        return (
                            <tr key={guarantee.node.id}>
                                <td className="text-center">{row_number + order_id}</td>
                                <td className="text-center">
                                    <Link to={"/accounting/guarantee-money-received/view/" + guarantee.node.id}
                                        target={"_blank"}>
                                        {guarantee.node.docNumber}
                                    </Link>
                                </td>
                                <td className="text-center">{format(guarantee.node.issuedDate, 'DD/MM/YYYY')}</td>
                                <td>
                                    {guarantee.node.contact.residential ? guarantee.node.contact.residential.name : "-"}
                                </td>
                                <td>
                                    {guarantee.node.contact.typeOfContact === "RESIDENTIAL" &&
                                        getNameResidential(guarantee.node.contact.firstName, guarantee.node.contact.lastName)
                                    }

                                    {guarantee.node.contact.typeOfContact === "SUPPLIER" &&
                                        guarantee.node.contact.name
                                    }
                                    {" "}                                            
                                    {
                                    (guarantee.node.payGroup === "RENT" || guarantee.node.payGroup === "AGENT" || guarantee.node.payGroup === "OWNER" || guarantee.node.payGroup === "DEVELOPER") &&
                                    <label className="text-danger">
                                    ({guarantee.node.payGroup === "RENT" || guarantee.node.payGroup === "AGENT" || guarantee.node.payGroup === "OWNER" || guarantee.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${guarantee.node.payGroup}`) : ""})
                                    </label>
                                    }    
                                </td>
                                <td>{guarantee.node.description}</td>
                                <td className="text-right">{numberWithComma(guarantee.node.amount)}</td>
                                <td className="text-center">{this.paymentChannel(guarantee.node)}</td>
                                <td className="text-center">{this.getStatus(guarantee.node.status)}</td>
                            </tr>
                        )
                    }
                    else {
                        return (
                            <tr key={guarantee.node.id}>
                                <td className="text-center text-danger">{row_number + order_id}</td>
                                <td className="text-center text-danger">
                                        {guarantee.node.docNumber}
                                </td>
                                <td className="text-center text-danger">{format(guarantee.node.issuedDate, 'DD/MM/YYYY')}</td>
                                <td className="text-danger">
                                    {guarantee.node.contact.residential ? guarantee.node.contact.residential.name : "-"}
                                </td>
                                <td className="text-danger">
                                    {guarantee.node.contact.typeOfContact === "RESIDENTIAL" &&
                                        getNameResidential(guarantee.node.contact.firstName, guarantee.node.contact.lastName)
                                    }

                                    {guarantee.node.contact.typeOfContact === "SUPPLIER" &&
                                        guarantee.node.contact.name
                                    }
                                    {" "}                                            
                                    {
                                    (guarantee.node.payGroup === "RENT" || guarantee.node.payGroup === "AGENT" || guarantee.node.payGroup === "OWNER" || guarantee.node.payGroup === "DEVELOPER") &&
                                    <label className="text-danger">
                                    ({guarantee.node.payGroup === "RENT" || guarantee.node.payGroup === "AGENT" || guarantee.node.payGroup === "OWNER" || guarantee.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${guarantee.node.payGroup}`) : ""})
                                    </label>
                                    }                                   
                                </td>
                                <td className="text-danger">{guarantee.node.description}</td>
                                <td className="text-right text-danger">{numberWithComma(0)}</td>
                                <td className="text-center text-danger">{this.paymentChannel(guarantee.node)}</td>
                                <td className="text-center text-danger">{this.getStatus(guarantee.node.status)}</td>
                            </tr>
                        )
                    }
                })
                }
                {(this.props.props.allGuaranteeMoneyReceived.pageInfo.hasNextPage || this.props.props.allGuaranteeMoneyReceived.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={6} className="text-right"><strong>รวม</strong></td>
                        <td className="text-right"><strong>{numberWithComma(total)}</strong></td>
                        <td colSpan={9} />
                    </tr>
                }
                {/* {!this.props.props.allGuaranteeMoneyReceived.pageInfo.hasNextPage && */}
                    <tr>
                        <td colSpan={6} className="text-right"><strong>{i18next.t("guaranteeMoneyReceivedReport:Total")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(this.props.props.summaryGuaranteeMoneyReceived)}</strong></td>
                        <td colSpan={9} />
                    </tr>
                {/* } */}
            </React.Fragment>
        )
    }
}

export default GuaranteeMoneyReceivedReportTable;
