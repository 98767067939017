import { graphql } from "babel-plugin-relay/macro";
const query = graphql`
query cancleRegulationQuery($search: String,$order: String, $startdate: DateTime, $enddate: DateTime,$first: Int, $last: Int , $regulationid: String){
    cancelRegulation(search: $search, order:$order, startdate: $startdate, enddate: $enddate, first: $first, last: $last, regulationid: $regulationid) {
        edges{
            node{
                id
                subject
                description
                creator
                datePosted
                dateSchedule
                status
                type
                previewImage
            }
        }
        totalCount
    }
}

`

export default function getQueryCancel() {
    return query
}