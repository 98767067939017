import React, { Component } from 'react';
import CollapsibleTable from './collapsibleTable';
import Header from '../../../../components/header/index';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import AccountingTopMenuNavigation from '../../../accountingTopMenuNavigation';
import BackButtonIcon from "../../../../components/BackBtn/indexBack"; 
import i18next from "i18next";
import { Translation } from 'react-i18next';
import './slip.scss'
class SlipVerificationList extends Component {

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true} />

                    <Translation>
                        {t =>
                            <div className="container-fluid box slip-verification-list" >
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t("slipVerificationList:IV Report displays results from the bank")}
                                />
                                <div className='row justify-content-between filterSearch' style={{ zoom: '90%' }}>
                                    {/* <div className='col-4' style={{margin:'20px 0 20px'}}>
                                <div>
                                <label className='floatLeft labelInline'>เลือกแสดง :</label>
                                    <div style={{ position: 'relative', textAlign: 'left' }}>
                                            <Select
                                                classNamePrefix='inputBoxList'
                                                // options={this.state.workingTypeAll}
                                                // onChange={this.onSelectWorkingType}
                                                required={true}
                                                isSearchable={false}
                                                placeholder={"เลือกแสดง"}
                                            />
                                            <input name="searchWorkingType" type="text" 
                                            // value={this.state.details}
                                                style={{ position: "absolute", top: 0, left: 162, width: 5, zIndex: -1 }}
                                                // onChange={() => this.inputTitle.value = ""}
                                                 ref={el => this.inputTitle = el}
                                                required={true} />
                                        </div>
                                </div>
                                </div> */}

                                    {/* <SeacrhDetails
                                    text="ค้นหา"
                                    seleteDetail=""
                                    selectIs={false}
                                    startDateIs={true}
                                    endDateIs={true}
                                    searchBoxId={true}
                                    search=""
                                    start_date=""
                                    end_date=""
                                    handlePropsChange=""
                                    /> */}
                                </div>

                                <div className="content-inner">
                                    <CollapsibleTable />
                                </div>
                            </div>
                        }
                    </Translation>
                </div>

            </Wrapper>

        )
    }
}

export default SlipVerificationList;