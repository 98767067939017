import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import Wrapper from '../../../components/wrapper';
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from '../../accountingTopMenuNavigation';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/asset.scss';
import { fetchQuery , commitMutation} from "relay-runtime";
import environment from '../../../env/environment';
import _ from 'lodash'
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';
import Swal from 'sweetalert2';
import {format, setDate, setMonth, lastDayOfMonth, setYear} from "date-fns";
import {Redirect} from 'react-router';
import numberWithComma from '../../../libs/numberWithComma'
import upload from '../../../libs/upload';
import UpdateAssetMutation from './updateAsset';
import Loading from "../../../libs/loading";
import i18next from 'i18next';


const mutation = graphql`
    mutation assetDeteriorateMutation($input: CreateAndUpdateDeteriorateAssetInput!) {
        createAndUpdateDeteriorateAsset(input: $input) {
            ok
        }
    }
`;


const query = graphql`
    query assetDeteriorateQuery($id: ID!,$date:Date) {
        asset(id: $id) {
            id
            type
            docNumber
            nounClassifier
            chartOfAccountType {
                chartOfAccountCode
                name
                id
            }
            chartOfAccountDepreciation {
                id
                chartOfAccountCode
                name
            }
            chartOfAccountDepreciationCumulative {
                id
                chartOfAccountCode
                name
            }
            name
            description
            storage
            remark
            internalAssetCode
            seller{
                id
            }
            sellerOther
            calDepreciation
            depreciationRatePercent
            depreciationRate
            lifeTimeYear
            purchaseDate
            saleDate
            calDepreciationDate
            costPrice
            carcassPrice
            calSchedule
            historicalValue
            historicalAmount
            saleStatus
            assetDepreciate(date_Lte:$date) {
                edges {
                    node {
                        id
                        depreciatePrice
                    }
                }
            }
            deteriorateAsset {
                edges{
                    node {
                        saleDate
                        approver
                        deteriorateDocument {
                            edges {
                                node {
                                    fileName fileUpload id
                                }
                            }
                        }
                    }
                }
            }
        }
        viewer {
            allChartOfAccount(codeList:["4300"]) {
                edges {
                    node {
                        id chartOfAccountCode name
                    }
                }
            }
        }
    }
`;

class AssetDeteriorate extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading : false,
            redirect : false,
            last_date : lastDayOfMonth(setDate(setMonth(new Date(), new Date().getMonth() - 1), 1)),
            sum_asset_depreciate : 0,
            asset: {
                id: '',
                type: 'LAND',
                nounClassifier: '',
                chartOfAccountDepreciation: {id: ''},
                chartOfAccountType: {id: ''},
                chartOfAccountDepreciationCumulative: {id: ''},

                saleStatus: '',

                docNumber: '',
                name: '',
                description: '',
                storage: '',
                seller: {id: '',},
                sellerOther: "",
                remark:"",

                calDepreciation: false,
                formula: 'STRAIGHT_LINE',
                depreciationRatePercent: 5,
                depreciationRate: 0.05,
                lifeTimeYear: 20,
                purchaseDate: new Date(),
                calDepreciationDate: new Date(),
                costPrice: 0,
                historicalValue: 0,
                carcassPrice: 1,
                calSchedule: "MONTHLY",
                historicalAmount: 0,
                saleDate: new Date(),
                internalAssetCode : ''
            },
            payChannel: [],
            upload: {},
            totalPay: 0,
            nameContact : '',
            adressContact : '',
            saleDate: new Date(),
            remark : '',
            chartOfAccount: '',
            chartOfAccountId : '',
            deteriorateDocument : [],
            approver : '',
            deteriorateAssetId : '',
            asset_amount : 0 //มูลค่าทางบัญชี
        }
       
    }

    componentDidMount(){
        this.fetchData()

    }


    fetchData = async() => {
        this.setState({loadingData : true})
        await fetchQuery(environment , query , {
            id : this.props.match.params.id,
            date: format(this.state.last_date, 'YYYY-MM-DD')
        }).then(response => {
            if(response?.viewer.allChartOfAccount?.edges.length > 0){
                _.forEach(response.viewer.allChartOfAccount.edges , chartOfAccount => {
                    if(chartOfAccount.node.name === 'กำไร(ขาดทุน)จากการตัดจำหน่ายสินทรัพย์'){
                        this.setState({
                            chartOfAccount : chartOfAccount.node.chartOfAccountCode + ' ' + chartOfAccount.node.name,
                            chartOfAccountId : chartOfAccount.node.id
                        })
                    }
                })
                
            }

            let data = _.cloneDeep(response);
            _.set(data.asset, 'purchaseDate', data.asset.purchaseDate ? new Date(data.asset.purchaseDate) : null);
            _.set(data.asset, 'saleStatus', 'deteriorate');
            _.set(data.asset, 'calDepreciationDate', data.asset.calDepreciationDate ? new Date(data.asset.calDepreciationDate) : null);
            _.set(data.asset, 'saleDate', data.asset.saleDate ? new Date(data.asset.saleDate) : new Date());
            

            if (!_.get(data.asset, "seller") && !_.get(data.asset, "sellerOther")) {
                _.set(data.asset, 'seller', {id: "on_contact"});
            } else if (!_.get(data.asset, "seller") && _.get(data.asset, "sellerOther")) {
                _.set(data.asset, 'seller', {id: "other_select"});
            }
            this.setState({
                asset: data.asset,
                approver : response.asset.deteriorateAsset?.edges.length > 0 ? response.asset.deteriorateAsset.edges[0].node.approver : '',
                deteriorateDocument : response.asset.deteriorateAsset?.edges.length > 0 ? response.asset.deteriorateAsset.edges[0].node.deteriorateDocument?.edges : [],
                saleDate : response.asset.deteriorateAsset?.edges.length > 0 ? new Date(response.asset.deteriorateAsset.edges[0].node.saleDate) : new Date(),
                deteriorateAssetId : response.asset.deteriorateAsset?.edges.length > 0 ? response.asset.deteriorateAsset.edges[0].node.id : '',
            },() => {
                this.calDepreciation()
                this.setState({
                    loadingData:false,
                })
            });

        }).catch(err => {
            alert(err)
        })

    }


    calDepreciation = () => {
        let amount = (this.state.asset.costPrice - this.state.asset.historicalAmount - this.state.asset.carcassPrice)
        let total_day = setYear(new Date(this.state.asset.purchaseDate) ,(new Date(this.state.asset.purchaseDate).getFullYear() + this.state.asset.lifeTimeYear))  - new Date(this.state.asset.calDepreciationDate)
        let total_day_date = Math.ceil(total_day / (1000 * 60 * 60 * 24));
        let amount_per_day = amount/total_day_date
        let numberOfDays = new Date(this.state.asset.saleDate).getDate()
        let asset_amount = amount_per_day*numberOfDays
        let sum_asset_depreciate = _.sumBy(this.state.asset.assetDepreciate.edges,'node.depreciatePrice')
        let total_asset = this.state.asset.costPrice - this.state.asset.historicalAmount - (sum_asset_depreciate + asset_amount)
        this.setState({
            asset_amount : total_asset
        })

    }

    handleChange = (e) => {
        let event = _.cloneDeep(e)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/asset/data" + path, file);
          resolve(res);
        });
    };

    onSubmit = async () => {
        this.setState({
            loading : true
        })
        let input = {
                asset: JSON.stringify(this.state.asset)
        };
        
        UpdateAssetMutation(input, this.onSuccess, this.onError)
        
    }

    onSuccess = async(response) => {
        let deteriorateDocument = []
        if(this.state.deteriorateDocument.length > 0){
            for (const file of this.state.deteriorateDocument){
                if(file.node.deteriorateDocumentId === ""){
                    await this.callUpload('', file.node.fileUpload).then((data) => {
                        deteriorateDocument.push({
                            deteriorateDocumentId : "" , 
                            fileUpload : data.key.replace("silverman-storage/private/", "") , 
                            status : "",
                            fileName : file.node.fileName
                        })
                    });
                }
            }
        }
        let variables = {
            input: {
                deteriorateAsset : {
                    deteriorateAssetId : this.state.deteriorateAssetId,
                    assetId : this.props.match.params.id,
                    saleDate : format(this.state.asset.saleDate , 'YYYY-MM-DD'),
                    chartOfAccountBookId : this.state.chartOfAccountId,
                    approver : this.state.approver,
                    total : parseFloat(this.state.asset.costPrice),
                    deteriorateDocument : deteriorateDocument
                }
            }
        };
        if (response.createAsset.ok) {
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        if (response.createAndUpdateDeteriorateAsset.ok) {
                            Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                this.setState({redirect: true});
                            })
                        } else {
                            this.setState({loading: false});
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({loading: false});
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                    },
                },
            )

        } else {
            this.setState({loading: false});
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');

        }
    }

    onError = () => {
        this.setState({loading: false});
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
    }

    handleInputFile = (e) => {
        let file = e.currentTarget.files[0];
        let defaultFile = _.cloneDeep(this.state.deteriorateDocument)
        if (file) {
            let totalBytes = file.size;
             // check gt 5 mb 5000000 from 10 mb RQ147
             if (totalBytes >  10485760) {
                Swal.fire('ขนาดไฟล์ใหญ่เกินไป!', 'กรุณาเลือกไฟล์ใหม่', 'warning');
                return
            } else {
                defaultFile.push(
                    { node : {
                        deteriorateDocumentId:"", 
                        fileUpload:file,
                        status:"",
                        fileName: file.name
                    }})
                this.setState({
                    deteriorateDocument : defaultFile
                })
            }
        }
    }

    deleteFile = (id) => {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(this.state.deteriorateDocument)
                let index_list = _.findIndex(_.cloneDeep(getData),{node : {deteriorateDocumentId : id}})
                if(index_list === -1){
                    getData.splice(id ,1)
                    this.setState({
                        deteriorateDocument : getData
                    })
                }
                else{
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            'deteriorateDocument'+'['+index_list+'].node.status',
                            'delete'
                        );
                    });
                }
            }
        })
    }



    render() {
        if(this.state.redirect){
            return <Redirect to={"/accounting/asset/all/form/"+this.state.asset.docNumber+"/"+this.props.match.params.id}/>;
        }
        return (
           <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className='container-fluid box' id="asset-deteriorate">
                        <div className="row justify-content-between d-flex align-items-center">
                            <div className="col-12">
                                <Translation>
                                    {
                                        t => 
                                        <h4 className="mb-4 form-inline">
                                            <Link to={"/accounting/asset/all/form/"+this.state.asset.docNumber+"/"+this.props.match.params.id}>
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back-new.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <div className="mt-1">เสื่อมสภาพ / ใช้การไม่ได้</div>
                                        </h4>
                                    }
                                </Translation>
                            </div>
                        </div>
                        <Translation>
                            { t=> 
                                <div className='content-inner pr-4'>
                                    {this.state.loadingData ?
                                    <Loading/>
                                    :
                                    <React.Fragment>
                                        <form id="asset" onSubmit={this.onSubmit}>
                                            <div className='row'>
                                                <div className='col-2' />
                                                <div className='col-8'>
                                                    <div className='row mt-44'>
                                                        <div className='col-4'>
                                                            <h6>ตัดเป็นค่าใช้จ่ายทั้งหมด ณ วันที่</h6>
                                                            <DatePickerNoti 
                                                                minDate={new Date(setDate(new Date,1))} 
                                                                className='form-control form-input-asset'
                                                                selected={this.state.asset.saleDate}
                                                                name='asset.saleDate'
                                                                onChange={this.handleChange}
                                                                width={20}
                                                                height={20}
                                                                disabled={this.props.match.params.status === 'view'}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-24'>
                                                        <div className='col-6'>
                                                            <h6>บัญชี</h6>
                                                            <input type='text' readOnly={true} value={this.state.chartOfAccount} className='form-control form-input' />
                                                        </div>
                                                    </div>
        
                                                    <h5 className='mt-24'>แนบหลักฐานการเสื่อมสภาพ / ใช้การไม่ได้</h5>
                                                    {this.props.match.params.status === 'create' &&
                                                    <div className='row col-12 mt-24 form-inline'>
                                                        <div className='attachFile form-inline' >
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/attachIcon.png'}
                                                                alt="attach"/>
                                                            <label htmlFor="CustomFile" className='cursor'>แนบไฟล์</label>
                                                            <input type="file" 
                                                                id="CustomFile" 
                                                                accept="application/pdf, image/*" value=""
                                                                hidden={true}
                                                                onChange={(e) => this.handleInputFile(e)}
                                                            />
                                                        </div>
                                                        <span className='attach-warn ml-3'>แนบไฟล์ .jpg หรือ .pdf ขนาดไม่เกิน 2 MB</span>
                                                    </div>}
                                                    <div className='mt-24'>
                                                        {this.state.deteriorateDocument.map((file,index) => {
                                                            if(file.node.deteriorateDocumentId !== ""){
                                                                return(
                                                                    <div className="col-12 mt-3 form-inline attach-file" key={index}>
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/pdf-icon.png'} alt="pdf" className="mr-3" style={{width:'24px',height:'24px'}}/>
                                                                        <a download="Download"
                                                                            href={file.node.fileUpload}>
                                                                            <span className="cursor mr-2 file-text">{file.node.fileName}</span>
                                                                        </a>
                                                                        {/* <img style={{width:'12.5px',height:'12px'}}
                                                                            src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="cancel" className="close-icon cursor" 
                                                                            onClick={() => this.deleteFile(file.node.deteriorateDocumentId === "" ? index : file.node.deteriorateDocumentId)}
                                                                        /> */}
                                                                        
                                                                    </div>
                                                                );
                                                            }else{
                                                                return(
                                                                    <div className="col-12 mt-3 form-inline attach-file" key={index}>
                                                                        <img style={{width:'24px',height:'24px'}}
                                                                            src={process.env.PUBLIC_URL + '/images/icons/pdf-icon.png'} alt="pdf" className="mr-3"/>
                                                                        <span className="mr-2 file-text">{file.node.fileName}</span>
                                                                        
                                                                            <img style={{width:'12.5px',height:'12px'}}
                                                                                src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="cancel" className="close-icon cursor" 
                                                                                onClick={() => this.deleteFile(file.node.deteriorateDocumentId === "" ? index : file.node.deteriorateDocumentId)}
                                                                            />
                                                                        
                                                                    </div>
                                                                );
                                                            }
                                                        })
                                                        }
                                                    </div>
        
                                                    <h6 className='mt-48'>ผู้อนุมัติการตัดจำหน่ายสินทรัพย์</h6>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <input type='text' className='form-control form-input' 
                                                            onChange={this.handleChange} name='approver' value={this.state.approver} 
                                                            disabled={this.props.match.params.status === 'view'} />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-48 cost-box pr-4'>
                                                        <div className='col-4 mt-3 mb-3'>
                                                            <h5>มูลค่าทางบัญชี</h5>
                                                        </div>
                                                        <div className='col-8 float-right mt-3 mb-3'>
                                                            <h5 className='text-right float-right'>{this.state.asset_amount >= this.state.asset.carcassPrice ? numberWithComma(this.state.asset_amount) : this.state.asset.carcassPrice}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-2' />
                                            </div>
                                        </form>

                                        {this.props.match.params.status === 'create' &&
                                        <div className='row col-12 mt-48 mb-48 d-flex justify-content-end'>
                                            <button type='button' onClick={() => this.onSubmit()} className='btn-confirm cursor'>
                                                {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                <span>บันทึก</span>
                                            </button>
                                        </div >
                                        }
                                       
                                    </React.Fragment>
                                    }
                                </div>
                            }
                        </Translation>
                    </div>
                </WrapperContent>

           </Wrapper>
        )
    }
}

export default AssetDeteriorate;