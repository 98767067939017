import {commitMutation} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";


const mutation = graphql`
    mutation updateChequeReceiveMutation($input: UpdateImageChequeDepositInput!) {
        updateImageChequeDeposit (input: $input) {
            ok
        }
    }
`;

export default function UpdateChequeReceiveMutation(input,uploadables, callback, error_callback) {
    const variables = {
        input: input
    };
    commitMutation(
        environment,
        {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
