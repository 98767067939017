import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import ChequeDepositListAll from './chequeDepositListAll'
import ChequeDepositCreate from './chequeDeposit'
import CreateChequeDeposit from './createChequeDeposit'


class ChequeDeposit extends Component {

    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={ChequeDepositListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ChequeDepositListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/deposit/create`} component={CreateChequeDeposit}/>
                <PrivateRoute exact path={`${this.props.match.url}/deposit/detail/:id`} component={ChequeDepositCreate}/>
            </React.Fragment>
        );
    }
}

export default ChequeDeposit;