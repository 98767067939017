import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import '../styles/register.scss'

import Navigation from "./navigation";
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {commitMutation} from "react-relay";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import i18next from 'i18next';
const _ = require('lodash');

const mutation = graphql`
    mutation updateDocumentAddressMutation($input: UpdateDocumentAddressInput!){
        updateDocumentAddress(input: $input){
            ok
        }
    }
`;

const query = graphql`
    query updateDocumentAddressQuery($id: ID!) {
        mailing: residential(id: $id) {
            id
            address
            mailingVillageBuilding
            mailingVillageNumber
            mailingLane
            mailingPostalCode
            mailingProvince
            mailingDistrict
            mailingSubDistrict
        }
        register: residential(id: $id){
            id
            name
            homeNumber
            villageBuilding
            villageNumber
            lane
            postalCode
            province
            district
            subDistrict
        }
    }
`;

class UpdateDocumentAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            use_mailing: false,
            mailing: {
                address:'',
                mailingVillageBuilding:'',
                mailingVillageNumber:'',
                mailingLane:'',
                mailingPostalCode:'',
                mailingProvince:'',
                mailingDistrict:'',
                mailingSubDistrict:'',
            },
            register: {},
        };
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCopyToMailing = this.onCopyToMailing.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.room_id) {
            fetchQuery(environment, query, {id: this.props.match.params.room_id}).then(data => {
                if (data) {
                    this.setState({mailing: data.mailing, register: data.register, })
                }
            });
        }
    }

    handleChangeInput(e) {
        let event = _.cloneDeep(e);
        this.setState(prevState => {
            return _.set(_.cloneDeep(prevState), event.target.name, event.target.value);
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let variables = {
            input: {
                documentAddressObject: JSON.stringify(this.state.mailing)
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.updateDocumentAddress.ok) {
                        Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                            this.setState({redirectToList: true})
                        });
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning');
                    }

                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error');
                },
            },
        );
    }

    onCopyToMailing(e) {
        if (e.target.checked) {
            let mailing_object = {...this.state.mailing};
            mailing_object.address = this.state.register.homeNumber;
            mailing_object.mailingVillageBuilding = this.state.register.villageBuilding;
            mailing_object.mailingVillageNumber = this.state.register.villageNumber;
            mailing_object.mailingLane = this.state.register.lane;
            mailing_object.mailingPostalCode = this.state.register.postalCode;
            mailing_object.mailingProvince = this.state.register.province;
            mailing_object.mailingDistrict = this.state.register.district;
            mailing_object.mailingSubDistrict = this.state.register.subDistrict;

            this.setState({mailing: mailing_object})
        }
        this.setState({use_mailing: e.target.checked});
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect
                to={{
                    pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id}}/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid p-4" id="form-detail">
                        <div className="row justify-content-between">
                            <div className="col header-color">
                                <h3 className="mb-4">
                                    <Link
                                        to={{pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id}}>
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    {this.state.register.name}
                                </h3>
                                <div className="content-inner">
                                    <Navigation id={this.props.match.params.id} name={this.state.register.name}/>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <div className="content-inner">
                                <div className="row mb-4 fade-up">
                                    <div className="col-md-6">
                                        <div className="card p-3">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h4 className="mb-3">
                                                            <Translation>
                                                                {
                                                                    t => <span>{t('register:document_address')}</span>
                                                                }
                                                            </Translation>
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="custom-control custom-checkbox mr-sm-2">
                                                            <input type="checkbox" className="custom-control-input"
                                                                   id="customControlAutosizing" checked={this.state.use_mailing}
                                                                   onChange={this.onCopyToMailing}/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="customControlAutosizing">
                                                                <strong>ใช้ที่อยู่เดียวกับที่อยู่ในการออกเอกสาร</strong>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <h6>เลขที่</h6>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.mailing.address}
                                                               onChange={this.handleChangeInput}
                                                               name="mailing.address"/>
                                                    </div>
                                                    <div className="col">
                                                        <h6>หมู่บ้าน/อาคาร</h6>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.mailing.mailingVillageBuilding}
                                                               onChange={this.handleChangeInput}
                                                               name="mailing.mailingVillageBuilding"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <h6>หมู่ที่</h6>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.mailing.mailingVillageNumber}
                                                               onChange={this.handleChangeInput}
                                                               name="mailing.mailingVillageNumber"/>
                                                    </div>

                                                    <div className="col">
                                                        <h6>ซอย</h6>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.mailing.mailingLane}
                                                               onChange={this.handleChangeInput}
                                                               name="mailing.mailingLane"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <h6>รหัสไปรษณีย์</h6>
                                                        <input type="text"
                                                               className="form-control"
                                                               maxLength="5"
                                                               value={this.state.mailing.mailingPostalCode}
                                                               onChange={this.handleChangeInput}
                                                               name="mailing.mailingPostalCode"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <h6>จังหวัด</h6>
                                                        {/*<select className="custom-select">*/}
                                                        {/*    <option>-- ระบุจังหวัด --</option>*/}
                                                        {/*</select>*/}
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.mailing.mailingProvince}
                                                               onChange={this.handleChangeInput}
                                                               name="mailing.mailingProvince"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <h6>เขต/อำเภอ</h6>
                                                        {/*<select className="custom-select">*/}
                                                        {/*    <option>-- ระบุเขต --</option>*/}
                                                        {/*</select>*/}
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.mailing.mailingDistrict}
                                                               onChange={this.handleChangeInput}
                                                               name="mailing.mailingDistrict"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <h6>แขวง/ตำบล</h6>
                                                        {/*<select className="custom-select">*/}
                                                        {/*    <option>-- ระบุแขวง --</option>*/}
                                                        {/*</select>*/}
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.mailing.mailingSubDistrict}
                                                               onChange={this.handleChangeInput}
                                                               name="mailing.mailingSubDistrict"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <button type="submit" disabled={this.state.loading}
                                                                className="btn btn-primary bt-style form-update">
                                                            บันทึกข้อมูล
                                                            {
                                                                this.state.loading &&
                                                                <span
                                                                    className="spinner-border spinner-border-sm align-middle mr-2"/>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="col">
                                                        <Link
                                                            to={{
                                                                pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id}}>
                                                            <button className="btn btn-secondary bt-style form-update">
                                                                ยกเลิก
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default UpdateDocumentAddress;
