import {graphql} from 'babel-plugin-relay/macro';
import React from 'react';
import Header from '../../components/header/';
import Sidebar from '../../components/sidebar/';
import Wrapper from '../../components/wrapper/';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import ChartOfAccountForm from "./chartOfAccountForm";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Loading from "../../libs/loading"

const query = graphql`
    query chartOfAccountCreateUpdateQuery($id: ID!) {
        chartOfAccount(id: $id) {
            id name nameEn chartOfAccountCode
            accountRecord(typeOfRecord: "historical") {
                edges {
                    node {
                        id name debit credit
                    }
                }
            }
        }
    }
`;

class ChartOfAccountCreateUpdate extends React.Component {

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">

                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/chart-of-account/all">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    {this.props.match.params.id ?
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:edit chart of accounting')}</span>
                                            }
                                        </Translation>
                                        :
                                        <Translation>
                                            {
                                                t => <span>{t('accounting:create chart of accounting')}</span>
                                            }
                                        </Translation>
                                    }

                                </h3>
                            </div>
                        </div>

                        <div className="content-inner">
                            <div className="row">
                                {this.props.match.params.id ?
                                    <QueryRenderer
                                        environment={environment}
                                        variables={{id: this.props.match.params.id}}
                                        query={query}
                                        render={({error, props}) => {
                                            if (error) {
                                                return (
                                                    <div className="alert alert-danger"
                                                         role="alert">{error.message}</div>
                                                );
                                            } else if (props) {
                                                return (
                                                    <ChartOfAccountForm chart={props}/>
                                                );
                                            }
                                            return <Loading/>
                                        }}
                                    /> :
                                    <ChartOfAccountForm/>
                                }
                            </div>
                        </div>

                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ChartOfAccountCreateUpdate;
