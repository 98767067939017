import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import settingPaymentgatewayList from './settingPaymentgateway'



class SettingPaymentgateway extends Component {
    
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={settingPaymentgatewayList}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default SettingPaymentgateway;