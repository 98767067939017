import React from 'react';
import { Link } from "react-router-dom";
import { QueryRenderer, commitMutation } from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import Swal from "sweetalert2";
import _ from "lodash";
import Loading from '../../../libs/loading';
import i18next from 'i18next';

const allPettyCashPayment = graphql`
    query pettyCashPaymentListTableQuery($search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int) {
        allPettyCashPayment(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last){
            totalCount
            edges{
                node{
                    id
                    docNumber
                    description
                    total
                    status
                    issuedDate
                    voidRemark
                    updated
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation pettyCashPaymentListTableMutation($input: UpdatePettyCashPaymentStatusInput!){
        updatePettyCashPaymentStatus(input:$input){
            warningText
            pettyCashPayment{
                id
            }
        }
    }
`;
class PettyCashPaymentListTable extends ComponentPagination {

    constructor(props) {
        super(props)
        this.state = {
            reQuery: false,
            first:this.state.first,
            last:this.state.last
        };
    }

    handleChange() {
        this.setState({ reQuery: true });
    }

    showDocNumberByStatus(petty_cash_payment) {
        if (petty_cash_payment.node.status === "VOID") {
            return petty_cash_payment.node.docNumber
        } else {
            return <Link to={"/accounting/expense/petty_cash/payment/detail/" + petty_cash_payment.node.id}
                        >{petty_cash_payment.node.docNumber}</Link>
        }
    }
    pettymentvoid(id) {
        Swal.fire({
            title: i18next.t("pettyCashView:Do you cancel a petty cash reimbursement")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:  i18next.t("Allaction:cancel"),
            confirmButtonText:  i18next.t("Allaction:yes")
        }).then(async (result) => {
            if (result.value) {

                let { value: void_remark } = await Swal.fire({
                    title: i18next.t("Allaction:Note"),
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: i18next.t("Allaction:cancel"),
                    confirmButtonText: i18next.t("Allaction:Confirm"),
                    inputValidator: (value) => {
                        if (!value) {
                            return i18next.t("Allaction:Please fill input before cancel")
                        }
                    }
                });

                if (void_remark) {
                    let variables = {
                        input: {
                            clientMutationId: id,
                            status: "void",
                            voidRemark: void_remark
                        }
                    };
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted: (response) => {
                                if (response.updatePettyCashPaymentStatus.pettyCashPayment) {
                                    Swal.fire(i18next.t("Allaction:Canceled Successfully"), '', 'success').then(() => {
                                    })
                                    this.handleChange()
                                } else {

                                    Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), response.updatePettyCashPaymentStatus.warningText, 'warning');
                                }
                            },
                            onError: (err) => {
                                Swal.fire('Error!',i18next.t("Allaction:Please try again."), 'warning');
                            },
                        },
                    );
                }

            } else {
                this.setState({ loading: false });
            }
        })
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={allPettyCashPayment}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status,
                    reQuery: this.state.reQuery
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center">{i18next.t("pettyCashView:Document No.")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Date ")}</th>
                                            <th>{i18next.t("pettyCashView:List")}</th>
                                            <th className="text-right">{i18next.t("pettyCashList:Amount withdrawal")}</th>
                                            <th className="text-center">{i18next.t("pettyCashView:Canceled Date")}</th>
                                            {/* <th className="text-center">ออกโดย</th> */}
                                            <th className="text-right">{i18next.t("pettyCashView:Note")}</th>
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.allPettyCashPayment.edges.map((petty_cash_payment) => (
                                            <tr key={petty_cash_payment.node.id}>
                                                <td className="text-center">
                                                    {this.showDocNumberByStatus(petty_cash_payment)}
                                                </td>
                                                <td className="text-center">{format(petty_cash_payment.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                <td>{petty_cash_payment.node.description}</td>
                                                <td className="text-right">{numberWithComma(petty_cash_payment.node.total)}</td>
                                                {petty_cash_payment.node.status === "VOID" ?
                                                    <td className="text-center">{format(petty_cash_payment.node.updated, 'DD/MM/YYYY', { locale: thLocale }) || '-'}</td>
                                                    :
                                                    <td/>
                                                }
                                                {/* <td className="text-right">ออกโดย</td> */}
                                                <td className="text-right">{petty_cash_payment.node.voidRemark}</td>
                                                <td className="text-right">
                                                    {petty_cash_payment.node.status !== "VOID" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_print' }) &&
                                                        <Link to={"/document/petty-cash-payment/" + petty_cash_payment.node.id}
                                                            target="_blank">
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/print.png'}
                                                                alt="print" className="print" />
                                                        </Link>
                                                    }
                                                </td>
                                                <td className="text-right">
                                                    {petty_cash_payment.node.status !== "VOID" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_delete' }) &&
                                                        <button className="btn" onClick={() => this.pettymentvoid(petty_cash_payment.node.id)}>
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                alt="print" className="print" />
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.allPettyCashPayment.totalCount} />
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default PettyCashPaymentListTable;
