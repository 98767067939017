import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";

import getNameResidential from "../../../libs/getNameResidential";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Swal from "sweetalert2";
import { fetchQuery } from "relay-runtime";
import { Redirect } from "react-router";
import _ from "lodash";
import Loading from '../../../libs/loading';
import LightBox from "../../../libs/lightBox";
import i18next from "i18next";
import BackButtonIcon from "../../../components/BackBtn/indexBack";

const query = graphql`
  query acceptCreditNoteViewQuery($id: ID!) {
    acceptCreditNote(id: $id) {
      id
      docNumber
      issuedDate
      status
      contact {
        refNumber
        firstName
        lastName
        name
        registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        typeOfContact
      }
      purchaseRecord {
        id
        productAndService {
          productCode
          name
          chartOfAccount {
            chartOfAccountCode
            name
          }
        }
        chartOfAccount {
          name
          chartOfAccountCode
        }
        purchaseRecordGroup {
          docNumber
        }
      }
      reason
      description
      price
      vat
      vatAmount
      total
      remark
      acceptCreditNoteNumber
      taxInvoiceDate
      taxInvoiceNumber
      taxInvoicePeriod
      refAcceptCreditNoteDoc
      refAcceptCreditNoteNumber
    }
  }
`;

const mutation = graphql`
  mutation acceptCreditNoteViewMutation($input: UpdateAcceptCreditNoteInput!) {
    updateAcceptCreditNote(input: $input) {
      ok
      warningText
    }
  }
`;

class AcceptCreditNoteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirectToList: false,
    };
    this.onChangeStatusToVoid = this.onChangeStatusToVoid.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  onChangeStatusToVoid() {
    Swal.fire({
      title: `${i18next.t("Allaction:Do you want to cancel")}?`,
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18next.t("Allaction:Yes"),
      cancelButtonText: i18next.t("Allaction:cancel"),
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18next.t("Allaction:Note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18next.t("Allaction:cancel"),
          confirmButtonText: i18next.t("Allaction:Confirm"),
          inputValidator: (value) => {
            if (!value) {
              return i18next.t("Allaction:Please enter a cancellation note.");
            }
          },
        });
        if (void_remark) {
          this.setState({ loading: true });
          let variables = {
            input: {
              status: "void",
              id: this.props.match.params.id,
              voidRemark: void_remark,
            },
          };
          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              if (response) {

                if (response.updateAcceptCreditNote.ok) {
                  Swal.fire(i18next.t("Allaction:Canceled Successfully"), "", "success").then(() =>
                    this.setState({ redirectToList: true })
                  );
                } else {
                  Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), response.updateAcceptCreditNote.warningText, "warning");
                }

              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), "", "warning");
            },
          });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }

  refreshData() {
    fetchQuery(environment, query, { id: this.props.match.params.id });
    this.setState({ loading: false });
  }

  render() {
    if (this.state.redirectToList) {
      return <Redirect to="/accounting/expense/accept-credit-note/list/all" />;
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <Translation>
            {t =>
              <React.Fragment>
                <AccountingTopMenuNavigation mini={true} />

                <QueryRenderer
                  environment={environment}
                  query={query}
                  variables={{ id: this.props.match.params.id }}
                  render={({ error, props }) => {
                    if (error) {
                      return <div>Error :(</div>;
                    } else if (props) {
                      return (

                        <div
                          className="container-fluid box"
                          id="receipt_deposit-view"
                          key={1}
                        >
                          <BackButtonIcon
                            LinkBack={"/accounting/expense/accept-credit-note/list/all"}
                            LinkText={t("accept_credit_note:accept_credit_note")+" "+"(ACN)"}
                            boxHtmlText={
                              <div className="col text-right">
                                {props.acceptCreditNote.status === "WAIT" || props.acceptCreditNote.status === "PAID" &&
                                  _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_accept_credit_note_delete" })
                                  && (
                                    <button
                                      className="btn btn-danger add mr-3"
                                      disabled={props.loading}
                                      onClick={this.onChangeStatusToVoid}
                                    >
                                      {props.loading && (
                                        <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                      )}
                                      {t("creditNoteSelectTransaction:Cancel Debt Reduction")}
                                    </button>
                                  )}

                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_accept_credit_note_print" }) && (
                                  <Link
                                    to={"/document/acceptcredit/" + props.acceptCreditNote.id}
                                    target="_blank"
                                  >
                                    <button className="btn btn-primary add">
                                      {t("Allaction:Print")} {t("creditNoteSelectTransaction:Accept Credit Note")}
                                    </button>
                                  </Link>
                                )}
                              </div>
                            }
                          />

                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:Creditor")}</strong>
                                  </p>
                                  <p>
                                    {props.acceptCreditNote.contact.typeOfContact === "RESIDENTIAL" ?
                                      getNameResidential(props.acceptCreditNote.contact.firstName, props.acceptCreditNote.contact.lastName)
                                      : props.acceptCreditNote.contact.name}{" "}
                                  </p>
                                </div>
                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:No.")}</strong>
                                  </p>
                                  <p>{props.acceptCreditNote.docNumber} </p>
                                </div>
                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:Refer")}</strong>
                                  </p>
                                  <p>
                                    {
                                      props.acceptCreditNote.purchaseRecord
                                        .purchaseRecordGroup.docNumber
                                    }
                                  </p>
                                </div>
                                {/*<div className="col">*/}
                                {/*    <p><strong>เลขที่ใบลดหนี้ที่รับมา</strong></p>*/}
                                {/*    <p>{props.acceptCreditNote.acceptCreditNoteNumber || '-'}</p>*/}
                                {/*</div>*/}
                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:Date of issue")}</strong>
                                  </p>
                                  <p>
                                    {format(props.acceptCreditNote.issuedDate, "DD/MM/YYYY", { locale: thLocale }
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:Tax number")}</strong>
                                  </p>
                                  {props.acceptCreditNote.taxInvoiceNumber ?
                                    <p>{props.acceptCreditNote.taxInvoiceNumber}</p>
                                    :
                                    "-"
                                  }
                                </div>

                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:Dated")}</strong>
                                  </p>
                                  {props.acceptCreditNote.taxInvoiceNumber ?
                                    <p>
                                      {format(props.acceptCreditNote.taxInvoiceDate, "DD/MM/YYYY", { locale: thLocale })}
                                    </p>
                                    :
                                    "-"
                                  }
                                </div>

                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:Reference number")}</strong>
                                  </p>

                                  {props.acceptCreditNote.refAcceptCreditNoteNumber ||
                                    props.acceptCreditNote.refAcceptCreditNoteDoc ? (
                                    <React.Fragment>
                                      <span>
                                        {
                                          props.acceptCreditNote.refAcceptCreditNoteNumber
                                        }
                                      </span>
                                      <span className="ml-2">
                                        {props.acceptCreditNote.refAcceptCreditNoteDoc && (
                                          <a
                                            download="name_of_downloaded_file"
                                            href={
                                              props.acceptCreditNote.refAcceptCreditNoteDoc
                                            }
                                          >
                                            <div className="p-2">
                                              <LightBox
                                                image={
                                                  props.acceptCreditNote.refAcceptCreditNoteDoc
                                                }
                                              />
                                            </div>
                                          </a>
                                        )}
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <p>-</p>
                                  )}
                                </div>

                                <div className="col" />
                                {/* <p>
                    <strong>งวด</strong>
                  </p>
                  <p>
                    {format(props.acceptCreditNote.taxInvoicePeriod, "MM/YYYY", { locale: thLocale })}
                  </p> 
                </div> */}

                              </div>
                              <hr />
                              <div className="row">
                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:Address")}</strong>
                                  </p>
                                  <p>
                                    {props.acceptCreditNote.contact?.registeredProvince.includes("กรุงเทพ") || props.acceptCreditNote.contact?.registeredProvince.includes("กทม") ? (
                                      <>
                                        {props.acceptCreditNote.contact.registeredAddress}
                                        {props.acceptCreditNote.contact.registeredDistrict && ` แขวง${props.acceptCreditNote.contact.registeredDistrict}`}
                                        {props.acceptCreditNote.contact.registeredCity && ` เขต${props.acceptCreditNote.contact.registeredCity}`}
                                        {props.acceptCreditNote.contact.registeredProvince && ` ${props.acceptCreditNote.contact.registeredProvince} `}
                                        {props.acceptCreditNote.contact.registeredPostalCode}{" "}
                                        {props.acceptCreditNote.contact.registeredCountry}
                                      </>
                                    ) : (
                                      <>
                                        {props.acceptCreditNote.contact.registeredAddress}
                                        {props.acceptCreditNote.contact.registeredDistrict && ` ตำบล${props.acceptCreditNote.contact.registeredDistrict}`}
                                        {props.acceptCreditNote.contact.registeredCity && ` อำเภอ${props.acceptCreditNote.contact.registeredCity}`}
                                        {props.acceptCreditNote.contact.registeredProvince && ` จังหวัด${props.acceptCreditNote.contact.registeredProvince} `}
                                        {props.acceptCreditNote.contact.registeredPostalCode}{" "}
                                        {props.acceptCreditNote.contact.registeredCountry}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col">
                                  <p>
                                    <strong>{t("creditNoteCreate:The reason of debt reduction")}</strong>
                                  </p>
                                  <p>{props.acceptCreditNote.reason}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="mt-3">
                                <div className="table-responsive fade-up">
                                  <table className="table table-hover mt-2">
                                    <thead className="thead-light">
                                      <tr>
                                        <th>{t("creditNoteCreate:Products/Services")}</th>
                                        <th>{t("creditNoteCreate:Account")}</th>
                                        <th>{t("creditNoteCreate:Description")}</th>
                                        <th className="text-right">{props.acceptCreditNote.vat > 0 ? t("receipt_deposit:Balance before tax") : t("receipt_deposit:Total")}</th>
                                        {props.acceptCreditNote.vat > 0 &&
                                          <th className="text-right">{t("receipt_deposit:Tax")}</th>
                                        }
                                        {props.acceptCreditNote.vat > 0 &&
                                          <th className="text-right">{t("receipt_deposit:Tax amount")}</th>
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        {props.acceptCreditNote.purchaseRecord.productAndService ? (
                                          <td>
                                            {
                                              props.acceptCreditNote.purchaseRecord.productAndService.productCode
                                            }{" "}
                                            {
                                              props.acceptCreditNote.purchaseRecord.productAndService.name
                                            }
                                          </td>
                                        ) : (
                                          <td>
                                            {
                                              props.acceptCreditNote.purchaseRecord.chartOfAccount.chartOfAccountCode
                                            }{" "}
                                            {
                                              props.acceptCreditNote.purchaseRecord.chartOfAccount.name
                                            }
                                          </td>
                                        )}
                                        <td>
                                          {
                                            props.acceptCreditNote.purchaseRecord.chartOfAccount.chartOfAccountCode
                                          }
                                        </td>
                                        <td>{props.acceptCreditNote.description}</td>
                                        <td className="text-right">
                                          {numberWithComma(props.acceptCreditNote.price)}
                                        </td>
                                        {props.acceptCreditNote.vat > 0 &&
                                          <td className="text-right">
                                            {numberWithComma(props.acceptCreditNote.vat)}
                                          </td>
                                        }
                                        {props.acceptCreditNote.vat > 0 &&
                                          <td className="text-right">
                                            {numberWithComma(props.acceptCreditNote.vatAmount)}
                                          </td>
                                        }
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <label htmlFor="note">
                                <strong>{t("creditNoteCreate:Note")}</strong>
                              </label>
                              <textarea
                                className="form-control"
                                rows="5"
                                name="remark"
                                value={props.acceptCreditNote.remark}
                                disabled={true}
                              />
                            </div>
                            <div className="col offset-6 bg-light">

                              <div className="row p-3 mb-2 text-dark">
                                <div className="col text-left">{props.acceptCreditNote.vat > 0 ? "ยอดก่อนภาษี" : "ยอด"}</div>
                                <div className="col text-right">
                                  {numberWithComma(props.acceptCreditNote.price || 0)}{" "}
                                  บาท
                                </div>
                              </div>
                              {props.acceptCreditNote.vat > 0 &&
                                <div className="row p-3 mb-2 text-dark">
                                  <div className="col text-left">{t("finance:tax")}</div>
                                  <div className="col text-right">
                                    {numberWithComma(props.acceptCreditNote.vatAmount || 0)}{" "}
                                    บาท
                                  </div>
                                </div>
                              }

                              <div className="row p-3 mb-2 text-dark">
                                <div className="col text-left">{t("historyReport:Credit Note Amount")}</div>
                                <div className="col text-right">
                                  {numberWithComma(props.acceptCreditNote.total || 0)}{" "}
                                  บาท
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      );
                    }
                    return <Loading />
                  }}
                />
              </React.Fragment>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default AcceptCreditNoteView;
