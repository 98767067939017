export default function getSettingMenuData() {
    return {
        document : [
            {
                name: 'Note Invoice Setting', 
                codename : 'accounting_settingnoteinvoice' , 
                path: '/setting/note-invoice-setting'
            },
            {
                name: 'Note Receive Setting', 
                codename : 'accounting_settingnotereceive' , 
                path: '/setting/note-receive-setting'
            },
            {
                name: 'licenseSettings',
                codename: 'license_settings',
                path: '/setting/juristic-setting'
            },
        ],

        account : [
            {
                name: 'System Start Date',
                codename : 'setting_account_start_date',
                path : '/setting/account-setting'
            },
            {
                name: 'set period lock',
                codename: 'period_lock',
                path : '/setting/period-lock'
            },
            {
                name: 'Bring Forward Setting',
                codename: 'accounting_accountrecord',
                path : '/setting/bring-forward-setting'
            },
            {
                name: 'settingInvoiceTerms',
                codename: 'preinvoicegroup',
                path: '/setting/invoice-setting-terms'
            },
            {
                name: 'chartOfAccount',
                codename: 'accounting_chartofaccount',
                path: '/accounting/chart-of-account/all'
            },
            {
                name: 'contact',
                codename: 'contact',
                path: '/accounting/contact/all'
            },
            {
                name: 'product',
                codename: 'product',
                path: '/accounting/inventory/product/list/all'
            },
            {
                name: 'bank',
                codename: 'accounting_bank',
                path: '/accounting/finance/bank'
            },
            {
                name: 'setuppaymentgateway',
                codename: 'accounting_gateway',
                path: '/setting/setuppaymentgateway'
            },
            {
                name: 'setupReceiptDepositUse',
                codename: 'rd_use',
                path: '/setting/setting-receiptdeposit-use'
            },
            // { ปิดไว้ไม่ให้ใช้
            //     name: 'setupchartofaccount',
            //     codename: 'accounting_setup_chartofaccount',
            //     path: '/setting/setupchartofaccount'
            // },
        ],

        securityVister: [
            {
                name: 'ToolandConnection',
                codename : 'tool_and_connection',
                path : '/setting/tool-and-connection'
            },{
                name: 'settingKeepPacking',
                codename : 'setting_keep_packing',
                path : '/setting/setting-keep-packing/companyDetail'
            },{
                name: 'Setting Open Walkin',
                codename : 'setting_open_walkin',
                path : '/setting/setting-walkin'
            }
        ],
        foreignOwnership: [
            {
                name: 'foreignOwnershipDocument',
                codename : 'foreign_ownship_document',
                path : '/setting/foreign-ownship-document'
            },
            {
                name: 'foreignOwnershipNotifications',
                codename : 'foreign_ownship_notifications',
                path : '/setting/foreign-ownship-notifications'
            }
        ],
        otherMenuMiniSetting:[
            {
                name: 'permissionSetting',
                codename : 'permission_setting',
                path : '/setting/juristic-setting'
            },
            {
                name: 'autoMessageSetting',
                codename : 'auto_message_setting',
                path : '/setting/SettingReplyAutoChat'
            },
            {
                name: 'settingParcel',
                codename : 'parcel_setting',
                path : '/setting/settingParcel'
            },
            {
                name: 'settingChat',
                codename : 'setting_chat',
                path : '/setting/settingChat'
            },
            {
                name: 'invoiceTemplateSetting',
                codename : 'invoice_template_setting',
                path : '/setting/setting-invoice-template'
            },
        ]

    }
};