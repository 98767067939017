import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import _ from "lodash";
import UploadFileXlsxInputCustomText from "./uploadFileXlsx";
import * as XLSX from 'xlsx'
import ImportFileCheckPreviewDataVehicel from "../../register/vehicle/importFileCheckPreviewData";

const ImportFile = (props) => {
    const [file, SetFile] = useState('')
    const [excelData, SetExcelData] = useState(null)
    const [showModal, setShowModal] = useState(false);

    const handleToggleModal = () => {
        setShowModal(!showModal);
    };

    const handleInputDocument = (e) => {
        SetFile(e.currentTarget.files[0])

        if (props.importType === "excel" && props.preview) {
            let reader = new FileReader();
            reader.readAsArrayBuffer(e.currentTarget.files[0])
            reader.onload = (ex) => {
                try {
                    const workbook = XLSX.read(ex.target.result, { type: 'buffer' })
                    let worksheetName = null
                    for (let index = 0; index < 20; index++) {
                        if (workbook.SheetNames[index] === "ยานพาหนะ") {
                            worksheetName = workbook.SheetNames[index]
                            break;
                        }
                    }
                    const worksheet = workbook.Sheets[worksheetName]
                    const data = XLSX.utils.sheet_to_json(worksheet, { raw: false })
                    SetExcelData(data)


                } catch (error) {
                    console.error('Error loading Excel file:', error);
                }
            }
        }
    }

    const ModalInport = () => {
        return (
            <Translation>
                {t =>
                    <div className="colorUse">
                        <button className='btn bg1567B4-textfff h48 w150' onClick={handleToggleModal}>
                            Import
                        </button>

                        <Modal show={showModal} onHide={handleToggleModal} size="xl" dialogClassName="w925">
                            <Modal.Header closeButton>
                                <Modal.Title>Import File</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <UploadFileXlsxInputCustomText
                                    handleInputDocument={handleInputDocument}
                                    documentUpload={file}
                                    tooltipText={props.tooltipText}
                                    dangerText={props.dangerText}
                                    LinkDocEx={props.LinkDocEx}
                                    LinkDocHowto={props.LinkDocHowto}
                                />

                                <React.Fragment>
                                    {
                                        props.ImportModalText === "vehicleImport" &&
                                        <ImportFileCheckPreviewDataVehicel
                                            excelData={excelData}
                                        />
                                    }
                                </React.Fragment>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleToggleModal}>
                                    {t("Allaction:cancel")}
                                </Button>
                                <Button variant="primary" onClick={() => props.onSubmit(file)}>
                                    {t("Allaction:Confirm")}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                }
            </Translation>
        )
    }



    return (
        <React.Fragment>
            <ModalInport text={props.ImportModalText} />
        </React.Fragment>

    )
}

export default ImportFile