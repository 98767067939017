import React from 'react';
import { Translation } from "react-i18next"; 
// import getSettingMenuData from "../libs/settingMenuData";
import ComponentPagination from "./componentPagination";

class EmptyTable extends ComponentPagination {

    render() {
        return (
            <Translation>
                {t=> 
                    <div className='colorUse'>
                        <label className='fs-20 fw-600'> 
                            {t("downloadFile:Not Found Data")}
                        </label>
                    </div>
                }
            </Translation>
        );
    }
}

export default EmptyTable;
