import React from "react";
import numberWithCommas from "../../libs/numberWithComma";

class BalanceSheetAccountAssetsStatements extends React.Component {

    render() {
        return (
            <React.Fragment>
                <tbody>

                    { (this.props.total_1 !== 0 || this.props.total_2 !== 0) &&
                        <tr>
                            <td className="pl-3" width={700}>{this.props.name}</td>
                            <td />
                            <td className="text-right">{numberWithCommas(this.props.total_1)}</td>
                            <td className="text-right">{numberWithCommas(this.props.total_2)}</td>
                        </tr>
                    }
                    {/* <tr>
                        <td className="pl-3" width={700}>{this.props.name}</td>
                        <td />
                        <td className="text-right">{numberWithCommas(this.props.total_1)}</td>
                        <td className="text-right">{numberWithCommas(this.props.total_2)}</td>
                    </tr> */}

                    {this.props.fine_last_Record &&
                        <tr>
                            <td>
                                <h6 className="mt-2">รวมทั้งสิ้น / Total</h6>
                            </td>
                            <td />
                            <td className="text-right">
                                <hr/>
                                {numberWithCommas(this.props.summary_1)}
                                <hr/>
                                {/* <hr className="double" /> */}
                            </td>
                            <td className="text-right">
                                <hr/>
                                {numberWithCommas(this.props.summary_2)}
                                <hr/>
                                {/* <hr className="double" /> */}
                            </td>
                        </tr>
                    }
                </tbody>
            </React.Fragment>
        )
    }

}

export default BalanceSheetAccountAssetsStatements;
