import React, { Component } from 'react';
import PrivateRoute from '../../libs/privateRoute';
import { Route, Switch } from "react-router-dom";
import WorkSheet from "./workSheet";
import WrapperWorkSheet from "./wrapperWorkSheet";
import NoMatch from "../../components/noMatch";

class WorkSheetIndex extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={WorkSheet} />
                <PrivateRoute exact path={`${this.props.match.url}/print-report-worksheet/:start_date/:end_date/:chart_of_account_start/:chart_of_account_end`} component={WrapperWorkSheet} />
                <Route component={NoMatch} />
            </Switch>
        );
    }
}

export default WorkSheetIndex;