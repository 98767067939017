import React, { Component } from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import ChequeDepositListAll from './chequeControlListAll'
import ChequeControlView from './chequeControlView'
// import CreateChequeDeposit from './createChequeControl'


class ChequeControl extends Component {

    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={ChequeDepositListAll} />
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={ChequeDepositListAll} />
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={ChequeControlView} />
                {/* <PrivateRoute exact path={`${this.props.match.url}/deposit/create`} component={CreateChequeDeposit}/>
                <PrivateRoute exact path={`${this.props.match.url}/deposit/detail/:id`} component={ChequeDepositCreate}/> */}
            </React.Fragment>
        );
    }
}

export default ChequeControl;