/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type navigationDebtsQueryVariables = {||};
export type navigationDebtsQueryResponse = {|
  +totalCountObject: ?{|
    +totalCountActive: ?number,
    +totalCountPaid: ?number,
  |}
|};
export type navigationDebtsQuery = {|
  variables: navigationDebtsQueryVariables,
  response: navigationDebtsQueryResponse,
|};
*/


/*
query navigationDebtsQuery {
  totalCountObject {
    totalCountActive
    totalCountPaid
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TotalObject",
    "kind": "LinkedField",
    "name": "totalCountObject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCountActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCountPaid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "navigationDebtsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "navigationDebtsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "59090a2acfa6d901960cc16d394bb798",
    "id": null,
    "metadata": {},
    "name": "navigationDebtsQuery",
    "operationKind": "query",
    "text": "query navigationDebtsQuery {\n  totalCountObject {\n    totalCountActive\n    totalCountPaid\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12b19d10f0a795f735bef377d0fc7fff';

module.exports = node;
