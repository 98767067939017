
import { graphql } from "babel-plugin-relay/macro";

const queryAvgFeedback = graphql`
  query avgFeedbackQuery($search: String, $startDate: DateTime, $endDate: DateTime, $userType: String, $award: Float, $site: ID){
    avgFeedback(search: $search, startDate: $startDate, endDate: $endDate, userType: $userType, award: $award, site: $site) 
  }          
`;


export default queryAvgFeedback;
