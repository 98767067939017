import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import DebtFreeRequestList from './debtFreeRequestList';
import DebtFreeForm from './createRequest/debtFreeForm';
import WrapperDebtFreeRequest from './document/wrapperDebtFreeRequest';


class DebtFreeRequest extends Component {
    
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={DebtFreeRequestList}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={DebtFreeRequestList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={DebtFreeForm}/>
                <PrivateRoute path={`${this.props.match.url}/print/all`} component={WrapperDebtFreeRequest}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default DebtFreeRequest;