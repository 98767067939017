import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { format } from "date-fns";
import { encode } from 'base-64'
import API from '../../api/silverGraund'
import ReactPaginate from "react-paginate";
import Loading from "../../libs/loading";
import settingAPI from '../../api/setting';
import numberWithComma from "../../libs/numberWithComma";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import SettingTopMenuNavigation from '../settingTopMenuNavigation';
import ExportParkingFeeReport from '../reportParkingFee/exportParkingFeeReport'
import _ from 'lodash';
import './parkingfee.scss'
import i18next from 'i18next';

let search = ''
let startDate = ''
let endDate = ''

const query = graphql`
    query reportQuery{
        selfProject {
            name
            logo
            typeOfAccount
        }  
}`;
class ParkingFeeReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            allRow: 4,
            LimitStRow: 1,
            LimitEnRow: 4,
            flg: "",
            flgTime: "",
            flgService: "",
            status: "",
            textSearch: "",
            num_record: 0,
            loading: true,
            first: "",
            last: 10,
            perPage: 10,
            page: 0,
            pages: 0,
            startDate: "",
            endDate: "",
            settingCalu:[],
            projectSelfDate:[],
            isUseAccount:false,
            checkList: [], 
            carReportDocList:[],
            check_all: false, 
            isShowOR: false,
            checkFetchState:false
        }

        this.SearchChange = this.SearchChange.bind(this)
        this.onSreach = this.onSreach.bind(this)
        this.getMethodPayment = this.getMethodPayment.bind(this)
        this.getRTStatus = this.getRTStatus.bind(this)
        this.getTime = this.getTime.bind(this);
        this.getTotalPrice = this.getTotalPrice.bind(this)
        this.getList = this.getList.bind(this)
        this.checkList = this.checkList.bind(this)
        this.getPersonType = this.getPersonType.bind(this)
        this.checkSelect = this.checkSelect.bind(this)
        this.printPDF = this.printPDF.bind(this)
        this.checkLoadingExcel = this.checkLoadingExcel.bind(this)
    }
    componentWillMount() {
        this.getDataVisitor();
        this.getcaluletePackingSetting();
        this.getSelfProject()

   
        if(!this.state.check_all){
            this.setState({
                check_all:false
            })
        }
    }

    getSelfProject(){
        fetchQuery(environment, query).then(data => {
            if(data.selfProject){
                this.setState({
                    projectSelfDate:data.selfProject,
                    isUseAccount:data.selfProject.typeOfAccount === 'USE_ACCOUNT' ? true : false
                    //isUseAccount: false
                })
            }
        })
    }

    getDataVisitor() {
        this.setState({ 
            checkFetchState: false
          })
        API.getVisitorVMSReport().then((res) => {
            if (res.data.data) {
                 let data_clone = _.cloneDeep(res.data.data);
                 this.setState({ 
                    data: res.data.data, 
                    allRow: res.data.allRow,
                    allCarid: data_clone?.map((n) => 
                    ({ carTransactionId: n.carTransactionId}))
                 })
                const { flgService, textSearch, first, last, startDate, endDate } = this.state;
                this.setState({
                    num_record: res.data.data.allRow, 
                    pages: Math.ceil(res.data.data.length / last),
                    checkFetchState: true
                });
            }
        }).catch(err => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error')).finally((
            this.setState({loading : false})
        ))
    }

    checkLoadingExcel(status) {
        this.setState({ checkFetchState: status });
    }
    getcaluletePackingSetting(){
        settingAPI.getcaluletePackingSetting().then((res) => {
            if(res.data.settingCalu){
                 this.setState({
                    settingCalu: res.data.settingCalu,
                    hours:  res.data.settingCalu.etampTimerVistor ?  res.data.settingCalu.etampTimerVistor :  res.data.settingCalu.etampTimerVistorHH, 
                    minutes:  res.data.settingCalu.etampTimerVistor ? 0 :  res.data.settingCalu.etampTimerVistorMM
                 })
            }
         }).catch(err => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error'))
    }

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page });
    };

    onSreach() {
        API.getSearchReportVisitmange(search, startDate, endDate).then((res) => {
            if (res.data.data) {
                let datas = []
                datas = res.data.data
                this.setState({ data: datas })
            }
        }).catch(err => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error'))
    }

    SearchChange(e) {
        if (e.target.name === "search") {
            search = e.target.value
        } else if (e.target.name.includes("startDate")) {
            startDate = format(e.target.value, "YYYY-MM-DD")
        } else if (e.target.name === "endDate") {
            endDate = format(e.target.value, "YYYY-MM-DD")
        }

        if (e.key === "Enter") {
            API.getSearchReportVisitmange(search, startDate, endDate).then((res) => {
                if (res.data.data) {
                    let datas = []
                    datas = res.data.data
                    this.setState({ data: datas })
                }
            }).catch(err => Swal.fire(i18next.t("manageVisitor:Unable to search"), i18next.t("manageVisitor:Unable to search at this time"), 'error'))
        }
    }


    getMethodPayment(value){
        let result
        if(value === 'cash'){
            //result = 'เงินสด'
            result = i18next.t(`receive:cash`)
        }else{
            result = 'QR Code'
        }
        return result
    }

    getRTStatus(value,carId){
        if(value === 'request'){
            return <Link to={'/silvermanguard/report-parking-fee/receipt/'+carId}>
                       <button className='btn btn-primary add'>{i18next.t(`paymentGateway:receipt`)}</button> 
                    </Link>
        }else if(value === 'finished'){
            return <button className='btn print' disabled={true}>{i18next.t(`paymentGateway:Receipt issued`)}</button> 
        }else{
          //  return 'ไม่มีการขอใบเสร็จ' 
          return i18next.t(`paymentGateway:No receipt requested`)
        }
    }

    getTime (type,paymentDate,startDate) {
        if (type === 'hours') {
            return parseInt(Math.abs(new Date(paymentDate) - new Date(startDate)) / (1000 * 60 * 60))
        } else {
           return parseInt(Math.abs(new Date(paymentDate).getTime() - new Date(startDate).getTime()) / (1000 * 60) % 60)
        }
    }

    getList(props) {

        let checklist = [...this.state.checkList];
        let current_checkListdoc = [...this.state.carReportDocList]
       // if(this.state.checkListdoc)current_checkListdoc = [...this.state.checkListdoc];
       
        let check_all = false;

        if (!this.state.check_all) check_all = true;

        if ((check_all && (checklist.length === props.length)) || !check_all ) {
            checklist = [];
            current_checkListdoc=[];
           // if(this.state.checkListdoc)current_checkListdoc = [];
        } else {
            if (checklist.length !== 0) {
                checklist = [];
                current_checkListdoc = [];
            }

            const { data, page,last } = this.state;


            let dataItems = data.slice(page * last, (page + 1) * last);

            let filterOR = _.filter(dataItems, (n) => n.orID === "")

            filterOR.forEach((item) => {
                 checklist.push(item.carTransactionId);
                 current_checkListdoc.push(item.reportCode)
            });
        }

        if(this.state.checkList) {
            this.setState({checkList: checklist, check_all: check_all, carReportDocList:current_checkListdoc})
        }else {
            this.setState({checkList: checklist, check_all: check_all}) 
        }
             
    }

    checkList(id) {
        return this.state.checkList.includes(id); 
    }

    appendToCheckList(id,doc) {
        //id
        let current_checklist = [...this.state.checkList];

        if (current_checklist.includes(id)) {
            //out
            current_checklist.splice(current_checklist.indexOf(id), 1)
           
        } else {
            //in
            current_checklist.push(id);
        }
        //doc rt
    
        if(doc !== null){
            let current_carReportDocList = [...this.state.carReportDocList]
            if (current_carReportDocList.includes(doc)) {
                current_carReportDocList.splice(current_carReportDocList.indexOf(doc), 1)
            } else {
                current_carReportDocList.push(doc);
            }
            this.setState({carReportDocList: current_carReportDocList})
        }
        this.setState({checkList: current_checklist})
    }

    getTotalPrice(paymentDate,startDate){

        const {estampStatus,etampTimer,etampTimerHH,etampTimerMM,etampTimerVistor,etampTimerVistorHH,etampTimerVistorMM} = this.state.settingCalu
        let A = (this.getTime('hours',paymentDate,startDate) * 3600) + (this.getTime('',paymentDate,startDate) * 60)
        let B = estampStatus ? etampTimer ? (etampTimer * 3600) : (etampTimerHH * 3600 + etampTimerMM * 60) : etampTimerVistor ? (etampTimerVistor * 3600) : (etampTimerVistorHH * 3600 + etampTimerVistorMM * 60)
     

        let hours;
        let minutes;
        let price;

        if (Math.sign(A - B) === 1) {
             hours = Math.floor((A - B) / 3600); // get hours
             minutes = Math.floor(((A - B) - (hours * 3600)) / 60); // get minutes
             price = (hours * parseFloat(this.state.settingCalu.toPayCal) + (minutes >= this.state.settingCalu.toCalOverTime ? parseFloat(this.state.settingCalu.toPayCal) : 0))
             return numberWithComma(price || 0, "0.00")
           // setOverTime({ hours, minutes, price: (hours * parseFloat(toPayCal) + (minutes >= toCalOverTime ? parseFloat(toPayCal) : 0)) })
        } else {
            //setOverTime(false)
        }
    }

    getPersonType(val){
        let result;
        switch (val) {
            case "tenant":
              result = "ผู้เช่า/ลูกบ้าน"
              break;
            case "contact":
              result = "ติดต่อสำนักงานขาย"
              break;
            case "appointment":
              result = "ผู้ติดต่อนัดหมายล่วงหน้า"
            case "person":
                result = "บุคคลทั่วไป"
              break;
          }
          return result;
    }

    checkSelect(){
        if(this.state.checkList.length <= 0){
            Swal.fire("กรุณาเลือกรายการ", "", "warning")
        }else{
            this.props.history.push({
                pathname: "/accounting/income/other_receiver/form/select_customer/",
                state: this.state,
            })
        }
    }

    printPDF(){
        this.props.history.push({
            pathname: "/silvermanguard/report-parking-fee/reportPDF",
            state: this.state,
        })
    }

    render() {
        const { data, num_record, loading, page, pages, last, startDate, endDate } = this.state;
        let items = []
        let lists = data
        let pageA = 0
            items = data.slice(page * last, (page + 1) * last);
            pageA = Math.ceil(data.length / last)

        let vmsOR = true

        return (

            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                <SettingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="reportGraund">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/silvermanguard">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('graud:reportReceipt')}</span>
                                        }
                                    </Translation>
                                    <div className="tooltipImg">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                alt="question" className="question ml-1" style={{width:'12px',marginBottom:'10px'}}/>
                                        <span className="tooltiptext">
                                         <Translation>{t => t("vmsDashboard:Automatically display only items that require parking payment and have already been paid.")}</Translation>
                                        </span>
                                    </div>
                                </h3>
                            </div>
                            <div className='col text-right pb-6'>
                                <div style={{display:'inline-flex'}}>
                                {
                                    _.some(JSON.parse(localStorage.getItem("permission_list")), { codename:'vms_packing_income_print' }) && 
                                    <>
                                        {/* <React.Fragment>
                                            <button type="button" className="dropdown-toggle btnPrint" data-toggle="dropdown">
                                                <Translation>{t => t("PageList:Print")}</Translation>
                                            </button>
                                        </React.Fragment>
                                            <div className={`dropdown-menu`} style={{left:'68px'}}>
                                                <Link to={{pathname:"/silvermanguard/report-parking-fee/reportPDF",
                                                            state:this.state,
                                                }} >
                                                    <span className="dropdown-item">PDF</span>
                                                </Link>
                                                <ExportParkingFeeReport state={this.state} />
                                            </div> */}
                                        <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                                            {!this.state.checkFetchState ?
                                                <React.Fragment>
                                                <button type="button" className="btnPrint">
                                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                                    <Translation>{t => t("meter:Preparing information")}</Translation>
                                                </button>
                                                </React.Fragment>
                                                :                  
                                                <React.Fragment>
                                                <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                                                    <Translation>{t => t("PageList:Print")}</Translation>
                                                </button>
                                                </React.Fragment>
                                            }
                                            <div className={`dropdown-menu`}>
                                                <a className="dropdown-item" target={"_blank"} onClick={() => this.printPDF()}>PDF</a>
                                                <ExportParkingFeeReport state={this.state} />                                                
                                            </div>
                                        </div>
                                        {/* <Dropdown >
                                         <DropdownToggle  id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>
                                         <Translation>{t => t("PageList:Print")}</Translation>
                                        </DropdownToggle>
                                           <DropdownMenu>
                                            <Dropdown.Item onClick={()=>this.printPDF()}>
                                                    PDF
                                            </Dropdown.Item>
                                            <Dropdown.Item >
                                                <ExportParkingFeeReport state={this.state} />
                                            </Dropdown.Item>
                                            </DropdownMenu>
                                         </Dropdown> */}
                                    </>   
                                }
                               
                                {
                                this.state.isUseAccount && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename:'vms_packing_income_create' }) ? 
                                  <>
                                  {
                                    !this.state.isShowOR ? 
                                    <button className='btn btn-primary add ml-2' onClick={ () => this.setState({isShowOR : !this.state.isShowOR})}>
                                        <span> <Translation>{t => t("vmsDashboard:Select items to create receipt (OR)")}</Translation></span>
                                       
                                    </button>
                                    :
                                    // <Link to={{ pathname: "/accounting/income/other_receiver/form/select_customer/",
                                    //             state: this.state,vmsOR
                                    //         }}> 
                                        <button className='btn btn-primary add ml-2' onClick={this.checkSelect}>
                                            <img src="/images/icons/plus.png" alt="plus"/>
                                            <span> <Translation>{t => t("accounting:add_other_receiver")}</Translation></span>
                                        </button>
                                    // </Link>   
                                  }
                                </>
                                :''
                                    
                                }
                                </div>
                            </div>
                        </div>

                        {
                            this.state.loading == false &&
                            <React.Fragment>
                                <div className='row'>
                                    <div className='col-12 text-right' style={{ display: 'inline' }}>
                                        <div className='floatRight'>
                                            <input type="text" name='search' className='form-control searchBox' placeholder='ค้นหา'
                                                onChange={this.SearchChange} onKeyUp={this.SearchChange} />
                                            <span className='spanINBox' onClick={this.onSreach}><img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} /></span>
                                        </div>
                                        <div className='floatRight'>
                                            <label className='floatLeft labelInline'><Translation>{t => t("vmsDashboard:Date")}</Translation>:</label>
                                            <input type="date" name='startDate' className='floatLeft form-control searchDate' placeholder='01/07/2021' onChange={this.SearchChange} />

                                            <label className='floatLeft labelInline'><Translation>{t => t("PageList:To")}</Translation></label>
                                            <input type="date" name='endDate' className='floatLeft form-control searchDate' placeholder='01/07/2021' onChange={this.SearchChange} />

                                        </div>

                                    </div>
                                </div>

                                <div className="table-responsive fade-up card" style={{ marginTop: '24px' }}>
                                    <table className="table table-hover">
                                        <thead className="thead-light">
                                            <tr className='text-center'>
                                            {
                                              this.state.isUseAccount ? 
                                                <th>
                                                    {
                                                        this.state.isShowOR ? 
                                                         <input type="checkbox"
                                                         checked={this.state.check_all}
                                                         onChange={() => this.getList(this.state.data)}
                                                        />  :''
                                                    }
                                                </th>
                                                :''}
                                                <th><Translation>{t => t("vmsDashboard:Document No.")}</Translation></th>
                                                <th><Translation>{t => t("vmsDashboard:Parking Payment Date & Time")}</Translation></th>
                                                <th><Translation>{t => t("vmsDashboard:Vehicle Registration No.")}</Translation></th>
                                                <th><Translation>{t => t("vmsDashboard:Amount (Baht)")}</Translation></th>
                                                {
                                                    !this.state.isUseAccount ?  <th><Translation>{t => t("vmsDashboard:Visitors Type")}</Translation></th> :''
                                                }
                                                <th><Translation>{t => t("vmsDashboard:Paid by")}</Translation></th>
                                                {
                                                    !this.state.isUseAccount ?  <th><Translation>{t => t("vmsDashboard:The payment evidence")}</Translation></th> :''
                                                }
                                                <th><Translation>{t => t("vmsDashboard:Receipt Status")}</Translation></th>
                                                {
                                                    this.state.isUseAccount ? 
                                                    <>
                                                        <th><Translation>{t => t("vmsDashboard:Other Received Document No.")}</Translation></th>
                                                        <th><Translation>{t => t("vmsDashboard:Exit Date")}</Translation></th>
                                                        <th><Translation>{t => t("vmsDashboard:Issued By")}</Translation></th>
                                                    </>
                                                    :''
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                items.map((n) => {
                                                    return (
                                                        <tr className='text-center' key={n.carTransactionId}>
                                                             {
                                                                this.state.isUseAccount ? 
                                                                <td>
                                                                    {
                                                                        this.state.isShowOR ? 
                                                                        <input type="checkbox"
                                                                        onChange={() => this.appendToCheckList(n.carTransactionId,n.reportCode)}
                                                                        checked={this.checkList(n.carTransactionId)} disabled={n.orID ? true : false}
                                                                        /> :''
                                                                    }
                                                                </td> :''
                                                            }
                                                            <td><Link to={"/silvermanguard/report-parking-fee/detail/" + n.reportCode}>{n.reportCode !== "" ? n.reportCode : "-"}</Link></td>
                                                            <td>{n.dateUsingSt}-{n.timeUsingSt}</td>
                                                            <td>{n.carRegistration ? n.carRegistration : '-'}</td>
                                                            <td>{this.getTotalPrice(n?.outDate,n?.startDate) || 0}</td>
                                                            {
                                                                !this.state.isUseAccount ? 
                                                                 <td>
                                                                    {
                                                                        n.typePerson ?  this.getPersonType(n.typePerson) :''
                                                                    }
                                                                </td> :''
                                                            }
                                                            <td>{n.methodPayment ? this.getMethodPayment(n.methodPayment) : '-'}</td>
                                                            {
                                                                !this.state.isUseAccount ? 
                                                                 <td>
                                                                    {
                                                                        n.refPayment ?  <img src={n.refPayment} 
                                                                        style={{width:'120px'}}
                                                                        alt="receipt"/> :'-'
                                                                    }
                                                                </td> :''
                                                            }
                                                            <td>
                                                                {n.rtStatus ? this.getRTStatus(n.rtStatus,n.carTransactionId) : '-'}
                                                            </td>
                                                            {
                                                                this.state.isUseAccount ? 
                                                                <> 
                                                                <td>  
                                                                    <Link
                                                                    to={{
                                                                        pathname:"/accounting/income/other_receiver/view/"+ encode("OtherReceiveNode:"+n.orID)+"/"+n.orDocnumber
                                                                    }} 
                                                                        >
                                                                        {n.orDocnumber ? n.orDocnumber : '-'}
                                                                    </Link>
                                                                </td>
                                                                <td>{n.orIssuedDate ? n.orIssuedDate : '-'}</td>
                                                                <td>{n.orCreator ? n.orCreator : '-'}</td>
                                                                </> :''
                                                            }
                                                            
                                                        </tr>
                                                    )

                                                })
                                            }

                                            {
                                                items.length === 0 &&
                                                <tr className='text-center'>
                                                    <td colSpan="11">
                                                        <Loading/>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='row'>
                                    <div className='col-6'>
                                        <select className='page' onChange={(e) => this.setState({ last: parseInt(e.target.value) })}>
                                            <option value="10">แสดง 10 รายการ / หน้า</option>
                                            <option value="20">แสดง 20 รายการ / หน้า</option>
                                            <option value="50">แสดง 50 รายการ / หน้า</option>
                                            <option value="100">แสดง 100 รายการ / หน้า</option>
                                        </select>
                                    </div>
                                    <div className='col-6 text-right'>
                                        <div className='pullright' style={{ marginTop: '22px' }}><Translation>{t => t("chatGroup:List All")}</Translation> {lists.length} &nbsp;

                                            {pages > 1 && (
                                                <ReactPaginate style={{ marginTop: '-7px' }}
                                                    previousLabel={"previous"}
                                                    nextLabel={"next"}
                                                    breakLabel={
                                                        <span className="btn page-link">...</span>
                                                    }
                                                    breakClassName={"break-me"}
                                                    pageClassName="page-item"
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={4}
                                                    previousClassName="sr-only"
                                                    containerClassName={"pagination float-right"}
                                                    subContainerClassName={"pagination-page"}
                                                    nextLinkClassName="sr-only"
                                                    pageLinkClassName="page-link"
                                                    activeClassName={"active"}
                                                    pageCount={pageA}
                                                    onPageChange={this.handlePageClick}
                                                />
                                            )}

                                        </div>

                                    </div>
                                </div>
                            </React.Fragment>

                        }
                        {
                            this.state.loading == true && <Loading/>
                        }

                    </div>
               
                </WrapperContent>
            </Wrapper>

        )
    }
}

export default ParkingFeeReport;
