import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {Redirect} from 'react-router'
import AcceptCreditNoteCreateFormCore from './core/acceptCreditNoteCreateFormCore'
import Loading from '../../../libs/loading';

const CreditNoteContactCreate = graphql`
   query acceptCreditNoteContactCreateFormQuery($id: ID!, $transaction_id: ID!) {  
      contact(id: $id) {
        id
        refNumber
        name
        firstName
        lastName
        typeOfSupplier
        vat

        registeredAddress
        registeredCountry
        registeredProvince
        registeredDistrict
        registeredCity
        registeredPostalCode
        typeOfContact
      }
      purchaseRecord(id:$transaction_id){
        id
        vat
        total
        price
        paid
        description
        purchaseRecordGroup{
          docNumber
        }
        productAndService{
          productCode
        }
        chartOfAccount{
          chartOfAccountCode
          name
        }
      }  
    }
`;

class AcceptCreditNoteContactCreateForm extends Component {

    render() {
        if (this.props.customerList.length > 0) {
            return (
                <QueryRenderer
                    environment={environment}
                    query={CreditNoteContactCreate}
                    variables={{id: this.props.customerList[0],transaction_id: this.props.transaction_id}}
                    cacheConfig={{use_cache: false}}
                    render={({error, props}) => {
                        if (props) {
                            return <AcceptCreditNoteCreateFormCore customerList={this.props.customerList}
                                                         reason_create={this.props.reason_create}
                                                         contact={props.contact}
                                                         category={'accept_credit_note'}
                                                         transaction_object={props.purchaseRecord}
                                                         backLink="/accounting/expense/accept-credit-note/form/select_customer"
                                                         nextLink="/accounting/expense/accept-credit-note/list/all"
                                                         accept_credit_note={true}
                            />
                        } else {
                            return <Loading/>
                        }
                    }}
                />
            )
        }
        return <Redirect to="/accounting/expense/accept-credit-note/form/select_purchase"/>;
    }
}

export default AcceptCreditNoteContactCreateForm;
