import React from "react";
import { Component } from "react";
import "./style/settingInvoiceTermsList.scss";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";

class SelectResident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectedContact: [],
      selectedContactID: [],
      conveyance: "all",
      contactList: this.props.queryObject,
      contactListUse: this.props.queryObject,
      select_all:"",
    };

    this.clearList = this.clearList.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search) {
      const search = this.props.search
      const allContactPreInvoice = _.filter(this.state.contactList, function (e) {
        if (e.node.refNumber.toLowerCase().includes(search) || e.node.name.toLowerCase().includes(search)) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({ contactListUse: allContactPreInvoice })
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  clearList() {
    this.setState(
      {
        selectedContact: [],
        selectedContactID: [],
        select_all:""
      },
      () => this.props.updateContactList(this.state.selectedContactID,this.state.selectedContact)
    );
  }

  onSelectAll(type) {
    //push
    if(type === ""){
      const list_all = [...this.state.contactListUse]
      const list_all_id = list_all.map((e) => (e.node.id))

      this.setState(
        {
          selectedContact:list_all,
          selectedContactID:list_all_id,
          select_all:"all"
        },
        () => this.props.updateContactList(this.state.selectedContactID,this.state.selectedContact)
      );
    }else{ //remove
      this.setState(
        {
          selectedContact: [],
          selectedContactID: [],
          select_all:""
        },
        () => this.props.updateContactList(this.state.selectedContactID,this.state.selectedContact)
      );
    }
  }

  onSelect(contact) {
    let list_contact_select = this.state.selectedContact;
    let list_contact_select_id = this.state.selectedContactID;
    const index = _.findIndex(list_contact_select,{ node: { id : contact.node.id } })
    //push
    if( index >= 0 ){
      list_contact_select.splice(index,1)
      list_contact_select_id.splice(index,1)
    }
    //-1 
    else{
      list_contact_select.push(contact)
      list_contact_select_id.push(contact.node.id)
    }

    this.setState({
      selectedContact: list_contact_select,
      selectedContactID: list_contact_select_id
    },
      () => this.props.updateContactList(this.state.selectedContactID,this.state.selectedContact)
    );
  }

  render() {

    return (
      <>
      <Translation>
        {t => (
          <React.Fragment>
            <div className="row pr-4" id="select-resident">
              <div className="col-12 col-md-6 mr-1 mt-6">

                <div className="row col-12 align-items-center d-flex mb-4">
                  <p className="mb-0 text-headline-5">{t("settingInvoiceTerms:selectRoom")}</p>

                  <form className="ml-4 align-items-center d-flex">
                    <input
                      className="checkbox-select"
                      type="checkbox"
                      name="select_all"
                      checked={this.state.select_all}
                      onChange={() => this.onSelectAll(this.state.select_all)} ></input>
                    <label className="ml-2 mb-0 text-global-table" htmlFor="vehicle1">
                      {t("settingInvoiceTerms:selectAll")}
                    </label>
                    <br></br>

                  </form>

                </div>

                <div className="col card colSelect">
                  <div className="col-12 ">
                    <div className="row scrollTable">
                      <div className="col">
                        <div id="list-contact">
                          <table className="table table-hover line">
                            <thead className="table-list">
                              <tr>
                                <th className="text-table-select">{t("settingInvoiceTerms:code")}</th>
                                <th className="text-table-select">{t("settingInvoiceTerms:roomno")}</th>
                                <th className="text-table-select">{t("settingInvoiceTerms:name")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.contactListUse.map((contact,index) => {
                                return (
                                  <tr key={index} 
                                    className={this.state.selectedContactID.includes(contact.node.id) ? 'table-primary' : ''}
                                    onClick={() => this.onSelect(contact)}
                                  >
                                    <td>{contact.node.refNumber}</td>
                                    <td>{contact.node.name}</td>
                                    <td>{contact.node.firstName}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-12 col-md mt-6">

                <div className="row col-12 align-items-center d-flex mb-4">
                  <p className="mb-0 text-headline-5">{t("settingInvoiceTerms:selectedItem")}</p>
                </div>

                <div className="col card colSelect">
                  <div className="col-12">
                    <div className="row col-12" />
                    
                    <div className="row scrollTable">
                      <div className="col">
                        <div id="list-contact">
                          <table className="table table-hover line">
                            <thead className="table-list">
                              <tr>
                                <th className="text-table-select">{t("settingInvoiceTerms:code")}</th>
                                <th className="text-table-select">{t("settingInvoiceTerms:roomno")}</th>
                                <th className="text-table-select">{t("settingInvoiceTerms:name")}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.selectedContact.map((contact_select,index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{contact_select.node.refNumber}</td>
                                      <td>{contact_select.node.name}</td>
                                      <td>{contact_select.node.firstName}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-end mb-2">
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-clear float-right"
                          onClick={this.clearList}
                        >
                          <span>{t("settingInvoiceTerms:clear")}</span>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <div className="row float-right pt mr-2">
              <Link
                to={{
                  pathname: this.props.nextLink,
                  state: this.props.listResident
                }}
              >
                <button type="submit" className="btn primary mr-3 mb-10 mtButton text-primary-button">
                {t("settingInvoiceTerms:next")}
                </button>
              </Link>
            </div>
          </React.Fragment>
        )}
      </Translation>
      </>
    );
  }
}
export default SelectResident;
