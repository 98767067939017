/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type individualPriceListQueryVariables = {|
  first?: ?number,
  last?: ?number,
  service_id: string,
  service_status?: ?string,
  search?: ?string,
|};
export type individualPriceListQueryResponse = {|
  +allResidential: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +size: number,
        +contact: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +firstName: string,
              +lastName: string,
            |}
          |}>
        |},
        +servicePricing: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +pricing: number,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
  +areaSummary: ?number,
  +totalServicePricingEachUnit: ?number,
|};
export type individualPriceListQuery = {|
  variables: individualPriceListQueryVariables,
  response: individualPriceListQueryResponse,
|};
*/


/*
query individualPriceListQuery(
  $first: Int
  $last: Int
  $service_id: ID!
  $service_status: String
  $search: String
) {
  allResidential(first: $first, last: $last, servicePricingStatus: $service_status, serviceId: $service_id, name_Icontains: $search) {
    edges {
      node {
        id
        name
        size
        contact {
          edges {
            node {
              id
              firstName
              lastName
            }
          }
        }
        servicePricing(service_Id: $service_id) {
          edges {
            node {
              id
              pricing
            }
          }
        }
      }
    }
    totalCount
  }
  areaSummary
  totalServicePricingEachUnit(serviceId: $service_id)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service_id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service_status"
},
v5 = {
  "kind": "Variable",
  "name": "serviceId",
  "variableName": "service_id"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "name_Icontains",
        "variableName": "search"
      },
      (v5/*: any*/),
      {
        "kind": "Variable",
        "name": "servicePricingStatus",
        "variableName": "service_status"
      }
    ],
    "concreteType": "ResidentialNodeConnection",
    "kind": "LinkedField",
    "name": "allResidential",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNodeConnection",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "service_Id",
                    "variableName": "service_id"
                  }
                ],
                "concreteType": "ServicePricingEachUnitNodeConnection",
                "kind": "LinkedField",
                "name": "servicePricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServicePricingEachUnitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ServicePricingEachUnitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pricing",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "areaSummary",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v5/*: any*/)
    ],
    "kind": "ScalarField",
    "name": "totalServicePricingEachUnit",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "individualPriceListQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "individualPriceListQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "7dd62d395d03b936ff9dcea280f99c18",
    "id": null,
    "metadata": {},
    "name": "individualPriceListQuery",
    "operationKind": "query",
    "text": "query individualPriceListQuery(\n  $first: Int\n  $last: Int\n  $service_id: ID!\n  $service_status: String\n  $search: String\n) {\n  allResidential(first: $first, last: $last, servicePricingStatus: $service_status, serviceId: $service_id, name_Icontains: $search) {\n    edges {\n      node {\n        id\n        name\n        size\n        contact {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n            }\n          }\n        }\n        servicePricing(service_Id: $service_id) {\n          edges {\n            node {\n              id\n              pricing\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n  areaSummary\n  totalServicePricingEachUnit(serviceId: $service_id)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '417a7c930327d6983c1771abeb8c4155';

module.exports = node;
