import '../../../../document/styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import {QueryRenderer} from "react-relay";
import InterestReport from './interestReport'
import format from 'date-fns/format';
import _ from 'lodash'
import numberWithCommas from '../../../../libs/numberWithComma';
import {Redirect} from 'react-router';
import Loading from "../../../../libs/loading"

const query = graphql`
    query wrapperInterestReportQuery($accountunitid: [String]) {
        selfProject{
            id
            name
            address
            logo
            juristicContactNumber
            keyProjectQr
            bankCompCode
            bankServiceCode
            taxIdNumber
            typeOfProject
        }
        allBankReport (accountunitid: $accountunitid) {
            edges{
                node{
                    id
                    refNumber
                    issuedDate
                    accountRecord{
                    edges{
                        node{
                            id
                            debit
                            bankUnit{
                                id
                            }
                        } 
                    } 
                    }
                    
                }
                    
            }
        }
    }
`;

class WrapperInterestReport extends Component {

    constructor(props) {
        super(props);
         this.state = {
            check_type: 'all'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        if (!this.props.location.query) {
            return <Redirect to="/accounting/report/finance"/>;
        }
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                            background-color: #FAFAFA;
                          }
                        
                          * {
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                          }
                        
                          .page {
                            width: 297mm;
                            min-height: 210mm;
                            padding: 5mm;
                            margin: 10mm auto;
                            border: 1px #D3D3D3 solid;
                            border-radius: 2px;
                            background: white;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                          }
                        
                          @page {
                            size: landscape;
                            margin: 0;
                          }
                          @media print {
                            html, body {
                              width: 297mm;
                              height: 210mm;
                            }
                            .page {
                              margin: 0;
                              border: initial;
                              border-radius: initial;
                              width: initial;
                              min-height: initial;
                              box-shadow: initial;
                              background: initial;
                              page-break-after: always;
                            }
                          }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Interest Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{accountunitid:this.props.location.query.id}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let sumBankUnit = []
                            let data = []

                            for (const transaction of props.allBankReport.edges){
                                let bankUnit = []
                                let sumJV = 0

                                for(const [index,id] of this.props.location.query.id.entries() ){
                                    let interestDebit = _.find(transaction.node.accountRecord.edges,['node.bankUnit.id',id])
                                    if(sumBankUnit[index]){  
                                        sumBankUnit[index] = sumBankUnit[index] + (interestDebit ? interestDebit.node.debit : 0)
                                    }else{
                                        sumBankUnit[index] = interestDebit? interestDebit.node.debit : 0
                                    }
                                    if(interestDebit){
                                        sumJV += interestDebit.node.debit
                                        bankUnit.push(numberWithCommas(interestDebit.node.debit))
                                    }else{
                                        bankUnit.push('')
                                    }
                                }
                                data.push({refNumber: transaction.node.refNumber , issuedDate : format(transaction.node.issuedDate,'DD/MM/YYYY'),bankUnit : [...bankUnit] ,sumJV : numberWithCommas(sumJV)})
                            }

                            return(
                                _.chunk(data,10).map((transaction,index) => {
                                    return(
                                        <div>
                                            <div className="page">
                                            <InterestReport projectName={props.selfProject.name} 
                                            firstDate={this.props.location.query.firstDate}
                                            lastDate={this.props.location.query.lastDate}
                                            header = {this.props.location.query.header}
                                            id = {this.props.location.query.id}
                                            data = {transaction}
                                            sumBankUnit ={sumBankUnit}
                                            logo = {props.selfProject.logo}
                                            lastPage = {(index +1  ===  _.chunk(data,10).length || _.chunk(data,10).length === 1)  ? true : false}
                                            lastPageNumber = {_.chunk(data,10).length}
                                            page= {index + 1}
                                        />
                                            </div>
                                        </div>
                                    )
                                })  
                            )
                        }

                         return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperInterestReport;
