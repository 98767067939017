import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import _ from "lodash";

class ProjectTopMenuNavigation extends Component {

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }

    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return (
            <div className="container-fluid bg-gray">
                <div className={"row "
                    + (this.props.mini ? "mini " : "")
                    + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                    {this.props.center &&
                        <div className="col-12 mt-5 mb-5"><h3 className="text-center">{
                            <Translation>
                                {
                                    t =>
                                        <div>{t('project_detail:Project detail')}</div>
                                }
                            </Translation>

                        }</h3></div>
                    }

                    <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                        <ul>
                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_info' }) &&
                                <li>
                                    <Link to="/project/info" style={{ height: '60px' }}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/project/project-icon.png'}
                                            alt="project-info-icon"
                                            className={this.props.mini && 'd-none'}
                                            style={{ width: '20px' }}

                                        />
                                        <Translation>
                                            {
                                                t =>
                                                    <div className="align-center">{t('topMenuNavigation:project info')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }

                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_emergency' }) &&
                                <li >
                                    <Link to="/project/emergency" style={{ height: '60px' }}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/project/call-icon.png'}
                                            alt="emergency-icon"
                                            className={this.props.mini && 'd-none'}
                                            style={{ maxWidth: '25px' }}

                                        />
                                        <Translation>
                                            {
                                                t =>
                                                    <div>{t('topMenuNavigation:emergency contact')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }

                            { 
                            // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_regulation' }) &&
                                <li>
                                    <Link to="/project/regulation/list/effective" style={{ height: '60px' }}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/project/rule-icon.png'}
                                            alt="regulation-icon"
                                            className={this.props.mini && 'd-none'}

                                        />
                                        <Translation>
                                            {
                                                t =>
                                                    <div>{t('topMenuNavigation:Regulation / Rule of resident')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }

                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_manager' }) &&
                                <li >
                                    <Link to="/project/project-manager/list/current-board" style={{ height: '60px' }}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/project/manager-icon.png'}
                                            alt="manager-icon"
                                            className={this.props.mini && 'd-none'}

                                        />
                                        <Translation>
                                            {
                                                t => <div>
                                                    {t('topMenuNavigation:project-manager')}
                                                </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }

                            {
                            // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristicmanager' }) &&
                                <li >
                                    <Link to="/project/juristic-manager/list/current" style={{ height: '60px' }}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/project/juristic-icon.png'}
                                            alt="juristic-manager-icon"
                                            className={this.props.mini && 'd-none'}
                                        />
                                        <Translation>
                                            {
                                                t => <div>
                                                    {
                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                    textData?.data?.projectNav.juristicManager
                                                    :
                                                    t('topMenuNavigation:Juristic Manager')
                                                    }
                                                </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }

                            {
                            // localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristiccompany' }) &&
                                <li>
                                    <Link to="/project/juristic-company/dashboard/general-information" style={{ height: '60px' }}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/project/company-icon.png'}
                                            alt="company-icon"
                                            className={this.props.mini && 'd-none'}

                                        />
                                        <Translation>
                                            {
                                                t => <div>
                                                    {
                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                    textData?.data?.projectNav.juristicManagementCompany
                                                    :                                                                                                       
                                                    t('topMenuNavigation:Juristic management company')}
                                                </div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }

                            {
                                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_projectstaff' }) &&
                                <li>
                                    <Link to="/project/staff/current" style={{ height: '60px' }}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/project/personal-icon.png'}
                                            alt="staff-icon"
                                            className={this.props.mini && 'd-none'}

                                        />
                                        <Translation>
                                            {
                                                t => <div>{t('topMenuNavigation:staff')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                            }

                        </ul>
                    </div>
                </div>
            </div>


        );
    }
}

export default ProjectTopMenuNavigation;
