import React, { Component } from 'react';
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import './foreignOwnership.scss'
import CreateUpdateNotice from './createUpdateNoticeForeignOwnership';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {fetchQuery} from "relay-runtime";
import environment from '../../env/environment';
import {graphql} from "babel-plugin-relay/macro";
import Loading from '../../libs/loading';
import i18next from 'i18next';

const query = graphql`
  query foreignOwnershipNotificationsQuery {
    allNoticeForeignOwnership {
        totalCount
        edges {
            node {
            id
            percentNoti
            }
        }
    }
}`

class SettingForeignOwnwershipNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          redirect: false,
          reQuery: false,
          contentItems: [{node:{"id":'',"percentNoti":40,"delete":false}},{node:{"id":'',"percentNoti":'49.99',"delete":false}}],
          // ["",""],
          allNoticeForeignOwnership: [],
          notiInput: []
        };
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleDelItem = this.handleDelItem.bind(this);
        this.fetchQuery = this.fetchQuery.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

      componentWillMount() {
        this.fetchQuery();
      }

      fetchQuery = () => {
        this.setState({loading: true})
        fetchQuery(environment, query).then((data) => {
          if(data){
            this.setState({
              allNoticeForeignOwnership : data.allNoticeForeignOwnership.edges,
              contentItems : data.allNoticeForeignOwnership.edges.map(item => ({
                node: {
                  ...item.node,
                  delete: false
                }
              })),
              loading : false,
            })}
        }).catch(error => {
          console.error('Error', error);
        });
      }
    
      handleAddItem = (e) => {
        const { contentItems } = this.state;
        const newEmptyItem = {node :{
          id: "",
          percentNoti: "",
          delete: false
        }};
        
        this.setState({
          contentItems: [...contentItems, newEmptyItem],
        });
      };

      handleDelItem = (indexDelItem) => {
        const { contentItems } = this.state;
        const updatedDelItems = _.cloneDeep(contentItems); 
        updatedDelItems[indexDelItem].node.delete = true
        
        // ถ้าไม่มี id จะถือเป็นตัวใหม่ให้ splice ได้เลย
        if(!updatedDelItems[indexDelItem].node.id) {
          updatedDelItems.splice(indexDelItem, 1);
        }

        this.setState({
            contentItems: updatedDelItems,
        });
      };

      handleChange = (index, value) => {
        const { contentItems } = this.state;
        const updatedChangeItems = _.cloneDeep(contentItems); 
        updatedChangeItems[index].node.percentNoti = value;
        this.setState({
          contentItems: updatedChangeItems,
          notiInput: updatedChangeItems
        });
      };

      onSubmit(e) {
        e.preventDefault(); 
        let notiInput = [];
        let percentEachTime = ""

        this.state.contentItems.map((noti) => {
          percentEachTime = {
            "id":noti.node.id,
            "percentNoti":noti.node.percentNoti,
            "delete": noti.node.delete
          }
            notiInput.push(percentEachTime)
        })
        let variables = {
          "input":{
           "noticeForeignOwnership": notiInput
           }
       }
       CreateUpdateNotice(variables,
              () => Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                  window.location.reload()
                  })
              , () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error').then(() => {
                  window.location.reload()
              })
          );
      }

      handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); 
        }
      }

    render() {
        let countTimeNoti = 0;
        let minimumArray = [0.01]
        this.state.contentItems[0] && this.state.contentItems.map((noti)=>{
          if (!noti.node?.delete) {
            noti.node.percentNoti && minimumArray.push(Number(noti.node.percentNoti)+0.01);
          }
        })
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                <div className="container-fluid box" id="foreignOwnershipNotifications">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('setting:foreignOwnershipNotificationsSetting')}
                                                    <div className="tooltipImg">
                                                        <img src={process.env.PUBLIC_URL + "/images/tooltip/light.png"} alt="question" className="question ml-2"/>
                                                        <span className="tooltiptext tooltipPositionMenu">
                                                        ใช้สำหรับกำหนดเปอร์เซ็นของอัตรา<br/>
                                                        กรรมสิทธิ์ชาวต่างชาติเมื่อใกล้ถึงเกณฑ์ที่กำหนด
                                                        </span>
                                                        <span className="tooltipTriangle tooltipTriangleMenu">
                                                        <img 
                                                        src={process.env.PUBLIC_URL + '/images/tooltip/tooltipTriangle.png'} alt="tooltipTriangle"/>
                                                        </span>
                                                    </div>
                                                </span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        
                            {this.state.loading?
                            <Loading/>
                            :
                            <div className="content-inner">
                                <div className="containerForeignOwnership">
                                    {/* เดะมาแก้ */}
                                    <form className="notificationForm" onSubmit={this.onSubmit} onKeyPress={this.handleKeyPress}>
                                        <div>
                                        <h6>
                                            ระบบจะแจ้งเตือนไปยัง User เพื่อให้ทราบว่า ณ ปัจจุบันมีชาวต่างชาติถือครองกรรมสิทธิ์ภายในโครงการเป็นสัดส่วนกี่ % จากเจ้าของกรรมสิทธิ์ทั้งหมด เพื่อแจ้งเตือนล่วงหน้าก่อนเกินโควต้า
                                        </h6>

                                        {this.state.contentItems.map((noti, index) => {
                                          if (!noti.node.delete) {
                                            countTimeNoti++; 
                                            return (
                                              <div className='notificationContainer' key={index}>
                                              <label>
                                                  เกณฑ์การแจ้งเตือนลำดับที่ {countTimeNoti}
                                                  <div className="tooltipImg">
                                                  <img src={process.env.PUBLIC_URL + "/images/tooltip/light.png"} alt="question" className="question ml-2" />
                                                  <span className="tooltiptext tooltipPositionInput">
                                                      กำหนดค่า % ที่ต้องการให้ระบบส่งแจ้งเตือน<br />
                                                      เมื่อถึง % ที่ต้องการ แต่ต้องไม่เกิน 49.99%
                                                  </span>
                                                  <span className="tooltipTriangle tooltipTriangleInput">
                                                      <img src={process.env.PUBLIC_URL + '/images/tooltip/tooltipTriangle.png'} alt="tooltipTriangle" />
                                                  </span>
                                                  </div>
                                              </label>


                                              <div className='percenInputContainer'>
                                                  <input className="percenInput" type="number" min={minimumArray[countTimeNoti-1]} max="49.99" step={0.01}
                                                    value={noti?.node?.percentNoti}
                                                    onChange={(e) => this.handleChange(index, e.target.value)}
                                                  />
                                                  <div className='middleContainer'>%</div>
                                                  {index > 1 &&
                                                  
                                                  <button type="button" className="deleteBtn" onClick={() => this.handleDelItem(index)}>
                                                      <img src={process.env.PUBLIC_URL + "/images/icons/bin-outline.png"} alt="delete-icon" />
                                                      &nbsp;ลบ
                                                  </button>}
                                              </div>
                                              </div>
                                              );
                                            }

                                            return null;
                                          })}
                                        <button type="button" className="attachBtn" onClick={() => this.handleAddItem()}>+ เพิ่มการแจ้งเตือน</button>
                                        </div>
                                        <div className='btnConfirmContainer'>
                                            <button type="button" className='btnCancel'>ยกเลิก</button>
                                            <button type="submit" className='btnConfirm'>บันทึก</button>
                                        </div>
                                    </form>
                                </div>
                            </div>}
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default SettingForeignOwnwershipNotifications;