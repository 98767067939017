import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './feedback.scss'

class Navigation extends Component {

    getStar(num) {
        return (
          <div className="star-rating">
                <label htmlFor="star-5" title="5 stars" className={num >= 5 ? 'star-active' :''}>
                    <FontAwesomeIcon icon={faStar} /> 
                </label>
                <label htmlFor="star-4" title="4 stars" className={num >= 4 ? 'star-active' :''}>
                      <FontAwesomeIcon icon={faStar} />
                </label>
                <label htmlFor="star-3" title="3 stars" className={num >= 3 ? 'star-active' :''}>
                        <FontAwesomeIcon icon={faStar} />
                </label>
                 <label htmlFor="star-2" title="2 stars" className={num >= 2 ? 'star-active' :''}>
                        <FontAwesomeIcon icon={faStar} />
                 </label>
                 <label htmlFor="star-1" title="1 star" className={num >= 1 ? 'star-active' :''}>
                    <FontAwesomeIcon icon={faStar} />
                 </label>
          </div>
    
        )
    }

    render() {
        let sumRatting = this.props.sumRatting

        return (
            <div className="row" id="navigation-tab">
                <div className="col-9">
                    <ul style={{borderBottom:'0'}}>
                        <li>
                            <NavLink exact={true} to={`/feedback/feedback-dashboard/all`}>
                                <Translation>
                                    {(t) => <span>{t("feedback:All")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink  exact={true} to={`/feedback/feedback-dashboard/5`}>
                                <Translation>
                                    {(t) => <span>5 {t("feedback:Star")} {"(" +this.props.rattingFeedbackFive +")"} </span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact={true} to="/feedback/feedback-dashboard/4">
                                <Translation>
                                    {(t) => <span>4 {t("feedback:Star")} {"(" +this.props.rattingFeedbackFour +")"} </span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact={true} to="/feedback/feedback-dashboard/3">
                                <Translation>
                                    {(t) => <span>3 {t("feedback:Star")} {"(" +this.props.rattingFeedbackThree +")"} </span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact={true} to="/feedback/feedback-dashboard/2">
                                <Translation>
                                    {(t) => <span>2 {t("feedback:Star")} {"(" +this.props.rattingFeedbackTwo +")"} </span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact={true} to="/feedback/feedback-dashboard/1">
                                <Translation>
                                    {(t) => <span>1 {t("feedback:Star")} {"(" +this.props.rattingFeedbackOne +")"}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="col-3 d-flex" style={{margin:'20px 0 20px',fontFamily:'kanit'}}>
                        <span className='pr-2'><h3>
                         {sumRatting && sumRatting}
                        </h3></span>
                        {this.getStar(this.props.sumRatting)}
                        <Translation>
                            {(t) =>   <span className='pl-2' style={{color:'#BFBFBF'}}>{this.props.allFeedback} {t("feedback:Reviews")} </span> }
                        </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;