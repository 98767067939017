/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type stockMenagementFormInputQueryVariables = {|
  search?: ?string
|};
export type stockMenagementFormInputQueryResponse = {|
  +contactViewer: ?{|
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |}
      |}>
    |}
  |},
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +productCode: string,
          +noNumber: boolean,
          +stock: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +cost: number,
                +leftInStock: number,
                +added: any,
                +transaction: ?{|
                  +date: ?any
                |},
              |}
            |}>
          |},
        |}
      |}>
    |}
  |},
|};
export type stockMenagementFormInputQuery = {|
  variables: stockMenagementFormInputQueryVariables,
  response: stockMenagementFormInputQueryResponse,
|};
*/


/*
query stockMenagementFormInputQuery(
  $search: String
) {
  contactViewer {
    allContact(typeOfContact: "residential", isActive: true, search: $search) {
      edges {
        node {
          id
          refNumber
          name
          firstName
          lastName
        }
      }
    }
    id
  }
  productViewer {
    allProduct(type_In: "product", isActive: true, search: $search) {
      edges {
        node {
          id
          name
          productCode
          noNumber
          stock {
            edges {
              node {
                id
                cost
                leftInStock
                added
                transaction {
                  date
                  id
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Literal",
  "name": "isActive",
  "value": true
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "Literal",
      "name": "typeOfContact",
      "value": "residential"
    }
  ],
  "concreteType": "ContactNodeConnection",
  "kind": "LinkedField",
  "name": "allContact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refNumber",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "type_In",
    "value": "product"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leftInStock",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stockMenagementFormInputQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactViewer",
        "kind": "LinkedField",
        "name": "contactViewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ProductAndServiceNodeConnection",
            "kind": "LinkedField",
            "name": "allProduct",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductAndServiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAndServiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductStockNodeConnection",
                        "kind": "LinkedField",
                        "name": "stock",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductStockNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductStockNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "transaction",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stockMenagementFormInputQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactViewer",
        "kind": "LinkedField",
        "name": "contactViewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ProductAndServiceNodeConnection",
            "kind": "LinkedField",
            "name": "allProduct",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductAndServiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAndServiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductStockNodeConnection",
                        "kind": "LinkedField",
                        "name": "stock",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductStockNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductStockNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "transaction",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0192ea21412570edd439de5a62dfec2",
    "id": null,
    "metadata": {},
    "name": "stockMenagementFormInputQuery",
    "operationKind": "query",
    "text": "query stockMenagementFormInputQuery(\n  $search: String\n) {\n  contactViewer {\n    allContact(typeOfContact: \"residential\", isActive: true, search: $search) {\n      edges {\n        node {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n        }\n      }\n    }\n    id\n  }\n  productViewer {\n    allProduct(type_In: \"product\", isActive: true, search: $search) {\n      edges {\n        node {\n          id\n          name\n          productCode\n          noNumber\n          stock {\n            edges {\n              node {\n                id\n                cost\n                leftInStock\n                added\n                transaction {\n                  date\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '31d57aacc363bee7b5c8a185dd78660e';

module.exports = node;
