import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import Loading from '../../../../libs/loading';
import { QueryRenderer } from "react-relay";
import HistoryReportPDFRenderTable from "./historyReportPDFRenderTable";
import ComponentPagination from "../../../../libs/componentPagination";

const query = graphql`
  query historyReportPDFQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $newEndDate: Date
    $search: String
    $contactId: ID
    $id: ID!
  ) {
    contact(id: $id) {
      id
      refNumber
      name
      firstName
      lastName
      vat

      registeredAddress
      registeredCountry
      registeredProvince
      registeredDistrict
      registeredCity
      registeredPostalCode
      typeOfContact
      typeOfSupplier
    }
    invoiceViewer {
      allInvoice(
        first: $first
        last: $last
        startDate: $startDate
        endDate: $endDate
        search: $search
        contact_Id: $contactId
        order: "doc_number"
        suborder: "issued_date"
        status_In: "active, overdue, paid, partial_payment"
      ) {
        edges {
          node {
            id
            docNumber
            issuedDate
            dueDate
            payGroup
            contact {
              id
              name
              firstName
              lastName
              refNumber
              typeOfContact
            }

            transaction {
              edges {
                node {
                  id
                  description
                  total
                  creditNoteTransaction(issuedDate_Lte: $newEndDate, status: "paid") {
                    edges {
                      node {
                        id
                        docNumber
                        issuedDate
                        price
                        status
                        receive{
                          id
                        }
                      }
                    }
                  }
                  
                  ##Cn V2
                  creditNoteTransactionRecord(creditNote_IssuedDate_Lte: $newEndDate , status: "paid") {
                      edges{
                          node{
                              id
                              price
                              creditNote{
                                  id
                                  docNumber
                                  issuedDate
                                  status
                              }
                              status
                          }
                      }
                  }
                  
                  receiptDepositTransactionUsed(receiptDeposit_IssuedDate_Lte: $newEndDate) {
                    edges {
                      node {
                        id
                        amount
                        coupleUse
                        receiptDeposit {
                          id
                          docNumber
                          issuedDate
                          total
                        }
                        receiptDepositTransaction {
                          couple
                        }
                      }
                    }
                  }

                  receiveTransaction(
                    receive_Status_In: "paid, partial_payment",
                    receive_IssuedDate_Lte: $newEndDate
                  ) {
                    edges {
                      node {
                        id
                        amount
                        receiptDepositTransactionUsed {
                          edges {
                            node {
                              id
                              amount
                            }
                          }
                        }
                        receive {
                          id
                          docNumber
                          issuedDate
                          status
                          total
                          creditNoteReceive {
                            edges {
                              node {
                                id
                                docNumber
                                price
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;
class HistoryReportPDF extends ComponentPagination {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            contactId: null,
            id: null,
            typePrint: "",
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data,
            contactId: this.props.location?.state?.contactId,
            id: this.props.location?.state?.id,
            typePrint: this.props.location?.state?.typePrint,
        })
    }
    
    render() {
        return (
            <React.Fragment>
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }

                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Accounts Receivable Movement</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div id="historyReportPDF">
                            <div className="print-top-menu">
                                    <div className="logo">
                                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                    </div>
                                    <div className="print" onClick={() => window.print()}>
                                        PRINT
                                    </div>
                            </div>     
                            {/* From selection */}
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                cacheConfig={{ use_cache: false }}
                                variables={{
                                    // first: this.state.first,
                                    // last: this.state.last,
                                    startDate: this.state.typePrint === 'all'? this.state.data.temp_start_date : this.state.data.start_date,
                                    endDate: this.state.typePrint === 'all'? this.state.data.temp_end_date : this.state.data.end_date,
                                    newEndDate: this.state.typePrint === 'all'? new Date(this.state.data.temp_end_date).toISOString().slice(0,10) : new Date(this.state.data.end_date).toISOString().slice(0,10),
                                    search: this.state.data.search,
                                    contactId: this.state.typePrint === 'all'? "" : this.state.contactId[0],
                                    id: this.state.typePrint === 'all'? "" : this.state.id,
                                }}
                                render={({ error, props }) => {
                                    if (props) {
                                           return <HistoryReportPDFRenderTable 
                                                        props={props}
                                                        start_date={this.state.typePrint === 'all' ? this.state.data.temp_start_date : this.state.data.start_date}
                                                        end_date={this.state.typePrint === 'all' ? this.state.data.temp_end_date : this.state.data.end_date}
                                                        dataFirst={this.state.data.first}
                                                        typePrint={this.state.typePrint}
                                                   />
                                      } else {
                                        return <Loading/>;
                                      }
                                    }
                                }     
                            />
                        </div>    
            </React.Fragment>
        )
    }
}
export default HistoryReportPDF;



