import React, { Component } from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from 'i18next'
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import SearchSelect from "../../../libs/searchSelect";
import Swal from "sweetalert2";
import { Redirect } from 'react-router'
import { H4, H5, Line } from '../../../components/Text/Text'
import './style/createChequeTransaction.scss'
import upload from '../../../libs/upload';
import { format } from "date-fns";
import Select from 'react-select'
import createAndUpdateChequeTransaction from './mutations/createAndUpdateChequeTransaction'
import LightBox from '../../../libs/lightBox';
import { formatDateISO } from "../../../libs/date"
import i18next from 'i18next';
const _ = require('lodash');

const query = graphql`
query viewChequeTransactionQuery($status: String, $first: Int, $last: Int, $chequeTransactionId:String ,$bankAccountId:String){
    allChequeTransactions(chequeTransactionId: $chequeTransactionId, status: $status,first :$first, last:$last) {
        totalCount
        edges{
            node{
                id
                date
                refNumber
                depositDate
                chequeDetails{
                    id
                    noNumber
                }
                chequeNo{
                    id
                    noNumber
                }
                chequeDate
                bankAccount{
                    id
                    bankName
                }
                chequeNumber
                chequeNoCustom
                chequeDetailsCustom
                payName
                docNumber
                description
                price
                status
                chequeDocuments{
                    edges{
                        node{
                            id
                            fileName
                            fileUpload
                        }
                    }
                }
                historical
            }  
        }          
    }
    chequeControl(bankAccountId: $bankAccountId) {
        totalCount
        edges{
            node{
                id
                docNumber
                bankName
                branch
                bankLogo
                accountType
                accountName
                accountNameEn
                accountNumber
                updated
                chequeDetails(status:true){
                    edges{
                        node{
                            id
                            status
                            noNumber
                            startCheque
                            endCheque
                            lastCheque
                            chequeNo(status:true){
                                totalCount
                                edges{
                                    node{
                                        id
                                        status
                                        noNumber
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }          
    }
}
  `
const mutation = graphql`
 mutation viewChequeTransactionMutation($input: CreateAndUpdateChequeTransactionInput!){
    createAndUpdateChequeTransaction(input: $input){
        ok
        }
    }
`;

const cheque_detail = {
    id: '',
    contact: { id: '' },
    chequeDate: new Date(),
    bankAccountId: '',
    date: new Date(),
    ChequeDetailsId: '',
    ChequeDetailsCustom: '',
    ChequeNoId: '',
    ChequeNoCustom: '',
    price: '',
    contactId: "",
    refNumber: "",
    unknownContact: '',
    depositDate: '',
    note: "",
    status: "",
    chequeDetails: [],
    chequeNo: [],
    bankName: '',
    branchNumber: '',
    accountNumber: '',
    payName: '',
    chequeDocuments: [],
    historical: '',
};

class ViewChequeTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cheque_transaction: cheque_detail,
            bank_list: [],
            loading: false,
            redirectToList: false,
            image_upload: '',
            options: [],
            selected: {},
            signreq: Math.random().toString(36),
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.docInputChange = this.docInputChange.bind(this);
    }

    componentWillMount() {
        fetchQuery(environment, query, { chequeTransactionId: this.props.match.params.id, bankAccountId: '' }).then(response_data => {
            let { status, date, chequeDate, depositDate, bankAccount, chequeDetails, chequeNo, price, refNumber, payName, chequeDocuments, chequeNoCustom, chequeDetailsCustom, id ,historical} = _.get(response_data, 'allChequeTransactions.edges[0].node')
            let options = []
                switch (status.toLocaleLowerCase()) {
                    case 'wait':
                        options = [
                            // { value: 'wait', label: 'รอเคลียริ่ง/พิมพ์เช็ค' },
                            { value: 'clearing', label: 'ผ่านเช็คจ่าย' },
                        ]
                        break;
                    case 'clearing':
                        options = [
                            { value: 'clearing', label: 'ผ่านเช็คจ่าย' },
                            { value: 'wait', label: 'ยกเลิกผ่านเช็คจ่าย' },
                        ]
                        break;
                    case 'void':
                        options = [
                           { value: 'void', label: 'เช็คเสีย/เช็คคืน' },
                        ]
                        break;
                    case 'bide':
                        options = [
                            { value: 'bide', label: 'เช็คเสียรอเคลียร์' },
                            { value: 'delete', label: 'นำกลับไปใช้ใหม่' },
                            { value: 'void', label: 'เช็คเสีย/เช็คคืน' },
                        ]
                        break;
                    case 'void_historical':
                        !chequeNoCustom ? 
                        options = [
                                { value: 'delete', label: 'นำกลับมาใช้ใหม่' },
                                { value: 'void', label: 'เช็คเสีย/เช็คคืน' },
                            ]
                            :
                        options = [
                            { value: 'void', label: 'เช็คเสีย/เช็คคืน' },
                            ]
                        break;
                    default:
                        break;
                }

                if(historical && status.toLocaleLowerCase() === 'wait') {
                    options = [
                        { value: 'clearing', label: 'ผ่านเช็คจ่าย' },
                        { value: 'void_historical', label: 'ยกเลิกเช็คจ่ายยกมา' },
                    ]
                } else if(historical && this.state.cheque_transaction.status === 'clearing'){
                    options = [
                        { value: 'clearing', label: 'ผ่านเช็คจ่าย' },
                        { value: 'void', label: 'ยกเลิกผ่านเช็คจ่าย' },
                    ]
                } 
            



            this.setState({
                // bank_list: response_data.chequeControl.edges, options, selected: status.toLocaleLowerCase(),
                bank_list: response_data.chequeControl.edges, options, selected: status.toLocaleLowerCase() === 'wait' ? 'clearing' : status.toLocaleLowerCase(),
                image_upload: chequeDocuments?.edges.length > 0 ? { name: chequeDocuments.edges[0]?.node?.fileName, fileUpload: chequeDocuments.edges[0]?.node?.fileUpload } : ''
            }, () => {
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.id", id));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.date", new Date(date)));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.chequeDate", new Date(chequeDate)));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.depositDate", depositDate ? new Date(depositDate) : null));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.bankAccountId", bankAccount['id']));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.chequeDetails", { edges: [{ node: chequeDetails || { id: "", noNumber: "" } }] }));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeDetailsId", chequeDetails ? chequeDetails['id'] : ''));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.chequeNo", { edges: [{ node: chequeNo || { id: "", noNumber: "" } }] }));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeNoId", chequeNo ? chequeNo['id'] : ''));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeDetailsCustom", chequeDetailsCustom));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeNoCustom", chequeNoCustom));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.price", price));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.refNumber", refNumber));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.payName", payName));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.chequeDocuments", chequeDocuments));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.status", status.toLocaleLowerCase()));
                this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.historical", historical));
            })
        });
    }

    getValueSelect = (status) => {
        if(this.state.cheque_transaction.historical && this.state.cheque_transaction.status === 'wait' ){
            switch (status) {
                case 'clearing':
                    return { value: 'clearing', label: 'ผ่านเช็คจ่าย' }
                case 'void_historical':
                    return { value: 'void_historical', label: 'ยกเลิกเช็คจ่ายยกมา' }
                default:
                return []
            }
        } else if(this.state.cheque_transaction.historical && this.state.cheque_transaction.status === 'void_historical'){
            switch (status) {
                case 'delete':
                    return { value: 'delete', label: 'นำกลับไปใช้ใหม่' }
                case 'void_historical':
                    return { value: 'void', label: 'เช็คเสีย/เช็คคืน' }
                case 'void':
                    return { value: 'void', label: 'เช็คเสีย/เช็คคืน' }
                default:
                return []
            }
        } else if(this.state.cheque_transaction.historical && this.state.cheque_transaction.status === 'clearing'){
            switch (status) {
                case 'clearing':
                    return { value: 'clearing', label: 'ผ่านเช็คจ่าย' }
                case 'wait':
                    return { value: 'wait', label: 'ยกเลิกผ่านเช็คจ่าย' }
                default:
                return []
            }
        }else {
            switch (status) {
            case 'wait':
                return { value: 'wait', label: this.state.cheque_transaction.status !== 'clearing' ? 'รอเคลียริ่ง/พิมพ์เช็ค' : 'ยกเลิกผ่านเช็คจ่าย' }
            case 'clearing':
                return { value: 'clearing', label: 'ผ่านเช็คจ่าย' }
            case 'void':
                return { value: 'void', label: 'เช็คเสีย/เช็คคืน' }
            case 'bide':
                return { value: 'bide', label: 'เช็คเสียรอเคลียร์' }
            case 'delete':
                return { value: 'delete', label: 'นำกลับไปใช้ใหม่' }
            default:
                return []
            }
        }
    }

    docInputChange(e) {
        if (e.target.name === 'image_upload') {
            this.setState({ image_upload: e.currentTarget.files[0], });
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e), { cheque_transaction } = this.state
        if (event.target.name === 'cheque_transaction.bankAccountId') {
            this.state.bank_list.forEach((bank) => {
                if (bank.node.id === event.target.value) {
                    this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.bankName", bank.node.bankName));
                    this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.branchNumber", bank.node.branch));
                    this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.accountNumber", bank.node.accountNumber));
                    this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.chequeDetails", bank.node.chequeDetails), () => {
                        if (bank.node.chequeDetails.edges.length >= 0) {
                            this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeDetailsId", ''));
                        }
                    });
                }

            })
        }

        if (event.target.name === 'cheque_transaction.chequeDetails.ChequeDetailsId') {
            if (cheque_transaction.chequeDetails.edges.length > 0) {
                cheque_transaction.chequeDetails.edges.forEach((cheque) => {
                    if (cheque.node.id === event.target.value) {
                        this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeDetailsId", cheque.node.id));
                        this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque_transaction.chequeNo", cheque.node.chequeNo));
                    }

                })
            }
        }

        if (event.target.name === 'cheque_transaction.chequeNo.ChequeNoId') {
            if (cheque_transaction.chequeNo.edges.length > 0) {
                cheque_transaction.chequeNo.edges.forEach((cheque) => {
                    if (cheque.node.id === event.target.value) {
                        this.setState(prevState => { return _.set(_.cloneDeep(prevState), "cheque_transaction.ChequeNoId", cheque.node.id); });
                    }

                })
            }
        }

        this.setState(prevState => { return _.set(_.cloneDeep(prevState), event.target.name, event.target.value); })
    }


    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload(path, file);
            resolve(res);
        });
    };

    onSubmit(e) {
        this.setState({ loading: true });
        e.preventDefault();

        if (this.state.cheque_transaction.status === 'wait') {
            if (this.state.selected === 'wait') {
                this.handleWait()
            } else if (this.state.selected === 'void') {
                this.handleReturnCheck()
            } else if (this.state.selected !== 'wait') {
                this.handleUpdate()
            }
        } else {
            if (this.state.selected === 'wait') {
                this.handleVoid()
            } else if (this.state.selected === 'void') {
                if(this.state.cheque_transaction.status === 'void_historical'){
                    this.handleDesReturnCheck()
                } else {
                    this.handleReturnCheck()
                }
            } else if (this.state.selected !== 'wait') {
                this.handleUpdate()
            }
        }

    }

    handleWait = () => {
        this.setState({ loading: true })
        Swal.fire({
            title: i18n.t("cheque_transaction:You are save without changing the status. please confirm correctness?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18n.t("cheque_transaction:Cancel"),
            confirmButtonText: i18n.t("cheque_transaction:Yes")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        chequeTransaction: JSON.stringify({
                            'chequeTransactionId': this.state.cheque_transaction.id,
                            "chequeDate": this.state.cheque_transaction.chequeDate,
                            "bankAccountId": this.state.cheque_transaction.bankAccountId,
                            "date": this.state.cheque_transaction.date,
                            "ChequeDetailsId": this.state.cheque_transaction.ChequeDetailsId,
                            "ChequeNoId": this.state.cheque_transaction.ChequeNoId,
                            "price": this.state.cheque_transaction.price,
                            "refNumber": this.state.cheque_transaction.refNumber,
                            // "depositDate": this.state.cheque_transaction.depositDate,
                            "depositDate": formatDateISO(this.state.cheque_transaction.depositDate),
                            "contactId": "",
                            "note": i18next.t("Allaction:cancel"),
                            "status": 'wait',
                            "chequeDocuments": [],
                            "clientMutationId": "CT" + Math.random().toString(36),
                        }),
                    }
                };
                createAndUpdateChequeTransaction(variables, (res) => {
                    this.setState({ loading: false });
                    if (res.createAndUpdateChequeTransaction.ok) {
                        Swal.fire(i18n.t("cheque_transaction:Save successfully!"), '', 'success').then(() => {
                            this.setState({ redirectToList: true })
                        });
                    } else {
                        Swal.fire(i18n.t("cheque_transaction:Save unsuccessfully!"), '', 'warning');
                    }
                })
            } else {
                this.setState({ loading: false });
            }
        })

    }

    handleUpdate = async () => {
        //เฉพาะสถานะ รอเคลียริ่งแล้วต้องการ ผ่านเช็คเท่านั้น
        if(this.state.cheque_transaction.status === 'wait' && this.state.selected === 'clearing' || this.state.cheque_transaction.status === 'wait' && this.state.selected === 'void_historical'){
            Swal.fire({
                title: 
                this.state.selected === 'void_historical' ?
                `${i18next.t("viewChequeTransaction:Cancel the carried-over payment cheque")}?` 
                :
                this.state.cheque_transaction.historical ?  `${i18next.t("viewChequeTransaction:Cancel the payment cheque")}?`  : i18n.t("cheque_transaction:Are you sure to pass this cheque transaction?"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: i18n.t("cheque_transaction:Cancel"),
                confirmButtonText: i18n.t("cheque_transaction:Yes")
            }).then(async (result) => {
                if (result.value) {
                    let fileUpload = '', { image_upload } = this.state
                    if (image_upload) {
                        await this.callUpload("/cheque_transaction",
                            new File([this.state.image_upload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${image_upload['name']}`)
                        ).then((data) => fileUpload = data.key.replace("silverman-storage/private/", ""));
                    }
                    let variables = {
                        input: {
                            chequeTransaction: JSON.stringify({
                                'chequeTransactionId': this.state.cheque_transaction.id,
                                "chequeDate": this.state.cheque_transaction.chequeDate,
                                "bankAccountId": this.state.cheque_transaction.bankAccountId,
                                "date": this.state.cheque_transaction.date,
                                "ChequeDetailsId": this.state.cheque_transaction.ChequeDetailsId,
                                "ChequeNoId": this.state.cheque_transaction.ChequeNoId,
                                "price": this.state.cheque_transaction.price,
                                "refNumber": this.state.cheque_transaction.refNumber,
                                "unknownContact": this.state.cheque_transaction.unknownContact,
                                // "depositDate": this.state.cheque_transaction.depositDate || null,
                                "depositDate": formatDateISO(this.state.cheque_transaction.depositDate) || null,
                                "note": this.state.cheque_transaction.note,
                                "status": this.state.selected,
                                "chequeDocuments": image_upload ? [
                                    {
                                        "chequeDocumentsId": "",
                                        "fileName": image_upload['name'],
                                        "fileUpload": fileUpload,
                                        "status": ""
                                    }
                                ] : []
                            }),
                        }
                    };
                    createAndUpdateChequeTransaction(variables, (res) => {
                        this.setState({ loading: false });
                        if (res.createAndUpdateChequeTransaction.ok) {
                            Swal.fire(i18n.t("cheque_transaction:Save successfully!"), '', 'success').then(() => { this.setState({ redirectToList: true }) });
                        } else {
                            Swal.fire(i18n.t("cheque_transaction:Save unsuccessfully!"), '', 'warning');
                        }
                    }, (err) => {
                        this.setState({ loading: false });
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                    })
                }else{
                    this.setState({ loading: false });
                }
            })
        }else{
            let fileUpload = '', { image_upload } = this.state
            if (image_upload) {
                await this.callUpload("/cheque_transaction",
                    new File([this.state.image_upload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${image_upload['name']}`)
                ).then((data) => fileUpload = data.key.replace("silverman-storage/private/", ""));
            }
            let variables = {
                input: {
                    chequeTransaction: JSON.stringify({
                        'chequeTransactionId': this.state.cheque_transaction.id,
                        "chequeDate": this.state.cheque_transaction.chequeDate,
                        "bankAccountId": this.state.cheque_transaction.bankAccountId,
                        "date": this.state.cheque_transaction.date,
                        "ChequeDetailsId": this.state.cheque_transaction.ChequeDetailsId,
                        "ChequeNoId": this.state.cheque_transaction.ChequeNoId,
                        "price": this.state.cheque_transaction.price,
                        "refNumber": this.state.cheque_transaction.refNumber,
                        "unknownContact": this.state.cheque_transaction.unknownContact,
                        // "depositDate": this.state.cheque_transaction.depositDate || null,
                        "depositDate": formatDateISO(this.state.cheque_transaction.depositDate) || null,
                        "note": this.state.cheque_transaction.note,
                        "status": (this.state.selected === 'void_historical' && this.state.cheque_transaction.status === 'void_historical')?  'void' : this.state.selected,
                        "chequeDocuments": image_upload ? [
                            {
                                "chequeDocumentsId": "",
                                "fileName": image_upload['name'],
                                "fileUpload": fileUpload,
                                "status": ""
                            }
                        ] : []
                    }),
                }
            };
            createAndUpdateChequeTransaction(variables, (res) => {
                this.setState({ loading: false });
                if (res.createAndUpdateChequeTransaction.ok) {
                    Swal.fire(i18n.t("cheque_transaction:Save successfully!"), '', 'success').then(() => { this.setState({ redirectToList: true }) });
                } else {
                    Swal.fire(i18n.t("cheque_transaction:Save unsuccessfully!"), '', 'warning');
                }
            }, (err) => {
                this.setState({ loading: false });
                Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
            })
        }

    }

    handleVoid = () => {
        this.setState({ loading: true })
        Swal.fire({
            title: i18n.t("cheque_transaction:Are you sure to cancel this cheque transaction?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18n.t("cheque_transaction:Cancel"),
            confirmButtonText: i18n.t("cheque_transaction:Yes")
        }).then(async (result) => {
            if (result.value) {
                let { value: void_remark } = await Swal.fire({
                    title: i18next.t("Allaction:Note"),
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: i18next.t("Allaction:cancel"),
                    confirmButtonText: i18next.t("Allaction:Confirm"),
                    inputValidator: (value) => {
                        if (!value) {
                            return i18next.t("Allaction:Please enter a cancellation note.")
                        }
                    }
                });
                if (void_remark) {
                    let variables = {
                        input: {
                            chequeTransaction: JSON.stringify({
                                'chequeTransactionId': this.state.cheque_transaction.id,
                                "chequeDate": this.state.cheque_transaction.chequeDate,
                                "bankAccountId": this.state.cheque_transaction.bankAccountId,
                                "date": this.state.cheque_transaction.date,
                                "ChequeDetailsId": this.state.cheque_transaction.ChequeDetailsId,
                                "ChequeNoId": this.state.cheque_transaction.ChequeNoId,
                                "price": this.state.cheque_transaction.price,
                                "refNumber": this.state.cheque_transaction.refNumber,
                                // "depositDate": this.state.cheque_transaction.depositDate,
                                "depositDate": formatDateISO(this.state.cheque_transaction.depositDate),
                                "contactId": "",
                                "note": void_remark,
                                "status": 'wait',
                                "chequeDocuments": []
                            }),
                        }
                    };
                    createAndUpdateChequeTransaction(variables, (res) => {
                        this.setState({ loading: false });
                        if (res.createAndUpdateChequeTransaction.ok) {
                            Swal.fire(i18n.t("cheque_transaction:Save successfully!"), '', 'success').then(() => {
                                this.setState({ redirectToList: true })
                            });
                        } else {
                            Swal.fire(i18n.t("cheque_transaction:Save unsuccessfully!"), '', 'warning');
                        }
                    })
                }
            } else {
                this.setState({ loading: false });
            }
        })
    }

    handleReturnCheck = () => {
        Swal.fire({
            title: this.state.cheque_transaction.historical ? 'ต้องการยกเลิกเช็คจ่ายยกมา ?':'ต้องการยกเลิกเช็คจ่าย ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18next.t("Allaction:cancel"),
            confirmButtonText: i18next.t("Allaction:yes")
        }).then(async (result) => {
            if (result.value) {

                let { value: void_remark } = await Swal.fire({
                    title: i18next.t("Allaction:Note"),
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: i18next.t("Allaction:cancel"),
                    confirmButtonText: i18next.t("Allaction:Confirm"),
                    inputValidator: (value) => {
                        if (!value) {
                            return i18next.t("Allaction:Please enter a cancellation note.")
                        }
                    }
                });

                if (void_remark) {
                    this.setState({ loading: true });
                    let variables = {
                        input: {
                            chequeTransaction: JSON.stringify({
                                'chequeTransactionId': this.state.cheque_transaction.id,
                                "chequeDate": this.state.cheque_transaction.chequeDate,
                                "bankAccountId": this.state.cheque_transaction.bankAccountId,
                                "date": this.state.cheque_transaction.date,
                                "ChequeDetailsId": this.state.cheque_transaction.ChequeDetailsId,
                                "ChequeNoId": this.state.cheque_transaction.ChequeNoId,
                                "price": this.state.cheque_transaction.price,
                                "refNumber": this.state.cheque_transaction.refNumber,
                                // "depositDate": this.state.cheque_transaction.depositDate,
                                "depositDate": formatDateISO(this.state.cheque_transaction.depositDate),
                                "contactId": "",
                                "note": void_remark,
                                "status": 'void',
                                "chequeDocuments": []
                            }),
                        }
                    };
                    createAndUpdateChequeTransaction(variables, (res) => {
                        this.setState({ loading: false });
                        if (res.createAndUpdateChequeTransaction.ok) {
                            Swal.fire(i18n.t("cheque_transaction:Save successfully!"), '', 'success').then(() => {
                                this.setState({ redirectToList: true })
                            });
                        } else {
                            Swal.fire(i18n.t("cheque_transaction:Save unsuccessfully!"), '', 'warning');
                        }
                    }, (err) => {
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                        this.setState({ loading: false });
                    })
                }

            } else {
                this.setState({ loading: false });
            }
        })
    }

    handleDesReturnCheck = () => {
        let variables = {
                        input: {
                            chequeTransaction: JSON.stringify({
                                'chequeTransactionId': this.state.cheque_transaction.id,
                                "chequeDate": this.state.cheque_transaction.chequeDate,
                                "bankAccountId": this.state.cheque_transaction.bankAccountId,
                                "date": this.state.cheque_transaction.date,
                                "ChequeDetailsId": this.state.cheque_transaction.ChequeDetailsId,
                                "ChequeNoId": this.state.cheque_transaction.ChequeNoId,
                                "price": this.state.cheque_transaction.price,
                                "refNumber": this.state.cheque_transaction.refNumber,
                                // "depositDate": this.state.cheque_transaction.depositDate,
                                "depositDate": formatDateISO(this.state.cheque_transaction.depositDate),
                                "contactId": "",
                                "status": 'void',
                                "chequeDocuments": []
                            }),
                        }
        };
        createAndUpdateChequeTransaction(variables, (res) => {
                            this.setState({ loading: false });
                            if (res.createAndUpdateChequeTransaction.ok) {
                                Swal.fire(i18n.t("cheque_transaction:Save successfully!"), '', 'success').then(() => {
                                    this.setState({ redirectToList: true })
                                });
                            } else {
                                Swal.fire(i18n.t("cheque_transaction:Save unsuccessfully!"), '', 'warning');
                            }
                        }, (err) => {
                            Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                            this.setState({ loading: false });
        })
    }
    
    
    handleChange = (changed_value) => {
        this.setState({ selected: changed_value['value'] });
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/finance/cheque/list/all" />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <form id="createChequeTransaction" onSubmit={this.onSubmit}>
                        <div className="container-fluid box">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/accounting/finance/cheque/list/all">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back" />
                                        </Link>
                                        <Translation>
                                            {
                                                t => <span>{t('cheque:cheque_transaction')+" "+"(QP)"}</span>
                                            }
                                        </Translation>
                                    </h3>
                                </div>
                            </div>
                            <div className="content-inner">
                                <div className="d-flex justify-content-center">
                                    <div className="col-8" >
                                        <div className="d-flex justify-content-end align-items-center mt-6">
                                            <H5 className='mb-2'>สถานะ:</H5>
                                            <div className='col-2' >
                                                <Select
                                                    options={this.state.options}
                                                    value={this.getValueSelect(this.state.selected)}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <H5 className='mb-2'>วันที่บันทึก</H5>
                                             <DatePickerAdapter id="cheque_transaction.chequeDate"
                                                className="form-control" name="cheque_transaction.chequeDate"
                                                selected={this.state.cheque_transaction.chequeDate? this.state.cheque_transaction.chequeDate : ""}
                                                onChange={this.handleInputChange}
                                                disable
                                                require />
                                        </div>
                                        <div className="col-6 mt-6">
                                            <H5 className='mb-2'>เลือกทะเบียนคุมเช็ค</H5>
                                            <SearchSelect onChange={this.handleInputChange}
                                                value={this.state.cheque_transaction.bankAccountId}
                                                name="cheque_transaction.bankAccountId"
                                                placeholder="กรุณาเลือกธนาคาร"
                                                queryObject={this.state.bank_list}
                                                keyOfValue="id"
                                                require
                                                disabled
                                                keyOfLabel="docNumber" />
                                        </div>
                                        <div className="col-6 mt-6">
                                            <H5 className='mb-2'>ธนาคาร</H5>
                                            <SearchSelect onChange={this.handleInputChange}
                                                value={this.state.cheque_transaction.bankAccountId}
                                                name="cheque_transaction.bankName"
                                                placeholder="กรุณาเลือกธนาคาร"
                                                queryObject={this.state.bank_list}
                                                keyOfValue="id"
                                                require
                                                disabled
                                                keyOfLabel="bankName" />
                                        </div>
                                        <div className="col-6 mt-6">
                                            <H5 className='mb-2'>เลขบัญชี</H5>
                                            <SearchSelect onChange={this.handleInputChange}
                                                value={this.state.cheque_transaction.bankAccountId}
                                                name="cheque_transaction.bankAccountId"
                                                placeholder="กรุณาเลือกธนาคาร"
                                                queryObject={this.state.bank_list}
                                                keyOfValue="id"
                                                require
                                                disabled
                                                keyOfLabel="accountNumber" />
                                        </div>
                                        <div className="col-6 mt-6">
                                            <H5 className='mb-2'>สาขา</H5>
                                            <SearchSelect onChange={this.handleInputChange}
                                                value={this.state.cheque_transaction.bankAccountId}
                                                name="cheque_transaction.bankAccountId"
                                                placeholder="กรุณาเลือกธนาคาร"
                                                queryObject={this.state.bank_list}
                                                keyOfValue="id"
                                                require
                                                disabled
                                                keyOfLabel="branch" />
                                        </div>

                                        <div className="col-12 mt-6">
                                            <H4 className='mb-6' >ข้อมูลเช็ค</H4>
                                            <Line />
                                        </div>

                                        <div className="col-3 mt-6">
                                            <H5>เช็คลงวันที่</H5>
                                            <DatePickerAdapter
                                                id="cheque_transaction.date"
                                                className="form-control mt-2" name="cheque_transaction.date"
                                                selected={this.state.cheque_transaction.date}
                                                onChange={this.handleInputChange}
                                                require disable />
                                        </div>

                                        <div className="d-flex justify-content-between mt-6 col-6 ">
                                            {this.state.cheque_transaction.ChequeDetailsId && this.state.cheque_transaction.ChequeNoId ?
                                                <React.Fragment>
                                                    <div className="col-6 mt-6" style={{ paddingLeft: 0, marginLeft: 0 }} >
                                                        <H5 className='mb-2'>เล่มเช็ค</H5>
                                                        <SearchSelect onChange={this.handleInputChange}
                                                            value={this.state.cheque_transaction.ChequeDetailsId}
                                                            name="cheque_transaction.chequeDetails.ChequeDetailsId"
                                                            placeholder="กรุณาเลือกธนาคาร"
                                                            queryObject={this.state.cheque_transaction.chequeDetails.edges || []}
                                                            keyOfValue="id"
                                                            // require
                                                            disabled
                                                            keyOfLabel="noNumber" />
                                                    </div>


                                                    <div className="col-6 mt-6" style={{ paddingRight: 0, marginRight: 0 }}>
                                                        <H5 className='mb-2'>เลขที่เช็ค</H5>
                                                        <SearchSelect onChange={this.handleInputChange}
                                                            value={this.state.cheque_transaction.ChequeNoId}
                                                            name="cheque_transaction.chequeNo.ChequeNoId"
                                                            placeholder="กรุณาเลือกธนาคาร"
                                                            queryObject={this.state.cheque_transaction.chequeNo.edges || []}
                                                            keyOfValue="id"
                                                            disabled
                                                            // require
                                                            keyOfLabel="noNumber" />
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className="col-6 mt-6" style={{ paddingLeft: 0, marginLeft: 0 }} >
                                                        <H5 className='mb-2'>เล่มเช็ค</H5>
                                                        <input type="text" className="form-control mt-2" name={"cheque_transaction.ChequeDetailsCustom"}
                                                            id="cheque_transaction.ChequeDetailsCustom" value={this.state.cheque_transaction.ChequeDetailsCustom}
                                                            onChange={this.handleInputChange} required disabled />
                                                    </div>

                                                    <div className="col-6 mt-6" style={{ paddingRight: 0, marginRight: 0 }}>
                                                        <H5 className='mb-2'>เลขที่เช็ค</H5>
                                                        <input type="text" className="form-control mt-2" name={"cheque_transaction.ChequeNoCustom"}
                                                            id="cheque_transaction.ChequeNoCustom" value={this.state.cheque_transaction.ChequeNoCustom}
                                                            onChange={this.handleInputChange} required disabled />
                                                    </div>
                                                </React.Fragment>
                                            }


                                        </div>

                                        <div className="col-6 mt-6">
                                            <H5>มูลค่า (บาท)</H5>
                                            <input type="text" className="form-control mt-2" name={"cheque_transaction.price"}
                                                id="cheque_transaction.price" value={this.state.cheque_transaction.price}
                                                onChange={this.handleInputChange} required disabled />
                                        </div>

                                        <div className="col-6 mt-6">
                                            <H5 className='mb-2'>ชื่อเจ้าหนี้</H5>
                                            <input type="text"
                                                name={"cheque_transaction.payName"}
                                                className="form-control mt-2"
                                                id="cheque_transaction.payName"
                                                value={this.state.cheque_transaction.payName}
                                                onChange={this.handleInputChange}
                                                disabled
                                                required />
                                        </div>

                                        <div className="col-6 mt-6">
                                            <H5>เลขที่เอกสารอ้างอิง</H5>
                                            <input type="text"
                                                name={"cheque_transaction.refNumber"}
                                                className="form-control mt-2"
                                                id="cheque_transaction.refNumber"
                                                value={this.state.cheque_transaction.refNumber}
                                                onChange={this.handleInputChange}
                                                disabled
                                                required />
                                        </div>

                                        <div className="col-6 mt-6">
                                            <H5 className='mb-2'>วันที่ผ่านเช็ค</H5>
                                            <DatePickerAdapter id="cheque_transaction.depositDate"
                                                className="form-control"
                                                name="cheque_transaction.depositDate"
                                                selected={(this.state.cheque_transaction.historical && this.state.cheque_transaction.status === 'void' || this.state.cheque_transaction.status === 'void_historical')? "" :this.state.cheque_transaction.depositDate}
                                                onChange={this.handleInputChange}
                                                disable={(this.state.cheque_transaction.historical && this.state.cheque_transaction.status === 'void')  || this.state.cheque_transaction.status === 'void_historical'}
                                                required={(this.state.selected === 'void' || this.state.selected === 'bide' || this.state.selected === 'delete' || this.state.selected === 'void_historical' ? false : true)} />
                                                
                                        </div>
                                        <div className="col-6 mt-6">
                                            <Translation>
                                                {t =>
                                                    <label
                                                        className={this.state.image_upload.name ? "btn btn-success mb-0 cursor font-medium mr-3" : "btn btn-secondary mb-0 cursor font-medium mr-3"}>
                                                        {this.state.image_upload.name ? <>แก้ไข</> : <>{t("cheque_transaction:Attach file")}</>}
                                                        <input type="file" name="image_upload" onChange={this.docInputChange} hidden />
                                                    </label>}
                                            </Translation>
                                            <div className='mt-2' style={{fontFamily:'medium'}}>
                                                <small className='text-red'>{i18next.t("Allaction:Each file should not exceed 10 MB")} {i18next.t("Allaction:and just")} .pdf .jpg</small>
                                            </div>
                                            {this.state.image_upload.name}
                                        </div>
                                        <div className="p-2">
                                            {this.state.image_upload?.fileUpload ? <LightBox image={this.state.image_upload?.fileUpload} /> : <div></div>}
                                        </div>
                                        <div className="d-flex justify-content-end mt-12 col-12">

                                            {this.state.cheque_transaction.status === 'wait' &&
                                                <Link
                                                    to={{
                                                        pathname: "/accounting/finance/cheque/list/print-cheque/all/",
                                                        state: { checkList: [this.props.match.params.id] }
                                                    }}>
                                                    <Translation>
                                                        {t => <button className="btn btn-primary add mr-2 " disabled={this.state.loading}> {t("cheque_transaction:Print this cheque")}</button>}
                                                    </Translation>
                                                </Link>
                                            }
                                            {/* {this.state.cheque_transaction.status === 'clearing' && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_cheque_delete' }) &&
                                                <Translation>
                                                    {t =>
                                                        <button className="btn btn-danger add mr-3" disabled={this.state.loading}
                                                            onClick={this.handleVoid}>
                                                            {this.state.loading &&
                                                                <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                            {t("cheque_transaction:Cancel this cheque transaction")}
                                                        </button>}
                                                </Translation>
                                            } */}
                                            <button type="submit" className="btn add" style={{ backgroundColor: '#1567B4', color: '#FFFFFF' }} >บันทึก</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </WrapperContent>

            </Wrapper >

        )
    }
}

export default ViewChequeTransaction;