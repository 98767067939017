import React from "react";
import getApiRoot from "../../../../libs/getAPIRoot";
import Helpers from '../../../../libs/file_download_helper';
import {format} from "date-fns";
import i18next from "i18next";


class ExportGuaranteeMoneyReceiveMovement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event) => {
        this.setState({
            errors: null,
            loading: true,
        }, () => {
            Helpers.httpRequest(
                getApiRoot() + 'export/guarantee_money_received_movement?start_date='
                + format(this.props.state.start_date, 'DD/MM/YYYY')
                + '&end_date=' + format(this.props.state.end_date, 'DD/MM/YYYY')
                + '&payment_channel=' + this.props.state.payment_channel
                + '&customer_type=' + this.props.state.customer_type
                + '&status=' + this.props.state.status
                + '&contact=' + this.props.customerList
                + '&search=' + this.props.state.search,
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "guarantee-money-received-movement.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });

        event.preventDefault();
    };

    render() {

        return (
            <form onSubmit={this.handleSubmit}>
                <button type="submit" className="btn print float-right"
                        disabled={this.state.loading}>
                    {this.state.loading ?
                        <span>
                        <span
                            className="spinner-border spinner-border-sm align-middle mr-2"/> {i18next.t("Allaction:Preparing information")}</span> :
                        <span>
                        <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon"/>
                        {i18next.t("Allaction:Print")}
                        </span>
                    }
                </button>
            </form>
        )
    }
}

export default ExportGuaranteeMoneyReceiveMovement;
