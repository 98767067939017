import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import '../debtFreeRequest.scss'
import receiveQuery from './receiveQuery';
import otherReceiveQuery from './otherReceiveQuery';
import i18next from 'i18next';

class PaymentInput extends Component{
    constructor(props){
        super(props)
        this.state = {
            typePayment : this.props.state.transactionDebtType ? this.props.state.transactionDebtType : 'receive_transaction',
            docNumber : this.props.state.docNumber,
            hasDocNumber :  null,
            showAlertRed : this.props.state.alertInputPaymentReceive,
        }
    }


    handleChange = (e) => {
        if(e.target.name === 'typePayment' && (this.state.typePayment !== e.target.value)){
            this.setState({
                [e.target.name] : e.target.value,
                docNumber : '',
                hasDocNumber : null
            },() => {
                this.props.handleValueByName('transactionDebtType',this.state.typePayment)
            })
        }else{
            this.setState({
                [e.target.name] : e.target.value
            },() => {
                this.checkDocNumber()
            })
        }
    }


    checkDocNumber = async() => {
        if(this.state.docNumber.length >= 10){
            if(this.state.typePayment === 'receive_transaction'){
                let receive = await receiveQuery('RT-'+this.state.docNumber)
                if(receive.receiveViewer.allReceive.totalCount > 0){
                    this.setState({
                        hasDocNumber : true
                    },() => {
                        this.props.handleValueByName('receiveId' , receive.receiveViewer.allReceive.edges[0].node.id)
                        this.props.handleValueByName('otherReceiveId' , '')
                        this.props.checkAlertInputPaymentReceive(false)
                    })
                }else{
                    this.setState({
                        hasDocNumber : false
                    })
                    this.props.checkAlertInputPaymentReceive(true)
                }
            }else{
                let otherReceive = await otherReceiveQuery('OR-'+this.state.docNumber)
                if(otherReceive.allOtherReceive.totalCount > 0){
                    this.setState({
                        hasDocNumber : true
                    },() => {
                        this.props.handleValueByName('otherReceiveId' , otherReceive.allOtherReceive.edges[0].node.id)
                        this.props.handleValueByName('receiveId' , '')
                        this.props.checkAlertInputPaymentReceive(false)
                    })
                }else{
                    this.setState({
                        hasDocNumber : false
                    })
                    this.props.checkAlertInputPaymentReceive(true)
                }
            }
        }else{
            this.setState({
                hasDocNumber : null
            },() => {
                this.props.checkAlertInputPaymentReceive(true)
            })
        }
    }


    render(){
        return(
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        <div className="row mt-4  margin-top pl-3"> 
                            <div className="col-12">
                                <h4>{i18next.t("debt_free_request:Payment evidence")}</h4>
                            </div>
                            <hr/>
                        </div>
                        {(this.props.status === 'active' || this.props.status === 'create') && this.state.showAlertRed && 
                            <div className="row col-12 mt-4 d-flex justify-content-center">
                                <div className=" alert-tab tab-red form-inline">
                                    <img src={process.env.PUBLIC_URL + '/images/icons/alert-red.png'} alt="alert" className="mr-3"/>
                                    <span>{i18next.t("debt_free_request:Please add payment receipts in either OR format or RT format to proceed")}</span>
                                    <img src={process.env.PUBLIC_URL + '/images/icons/close-red.png'} alt="alert" className="close-icon cursor"
                                        onClick={() => this.setState({showAlertRed : false})}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row col-12 mt-4 mb-4">
                            <div className="col-12">
                                <h6>{i18next.t("debt_free_request:Certification fee rate")} {this.props.charge} {i18next.t("debt_free_request:Baht/set")}</h6>
                            </div>
                        </div>
                        <div className="row col-12">
                            <div className='row col-6'>
                                <div className="col-12 mt-3">
                                    <h5>{i18next.t("debt_free_request:Document format")}</h5>
                                </div>
                                {!this.props.disabledInput &&
                                    <div className="col-3 mt-3">
                                        <select className="form-control dropdown-custom-arrow"  name="typePayment"
                                                value={this.state.typePayment}
                                                onChange={this.handleChange}
                                                disabled={this.props.disabledInput ||(this.props.status === 'active' && !this.props.state.permissionEdit)}
                                        >
                                            <option value='receive_transaction'>RT</option>
                                            <option value='other_receive'>OR</option>
                                        </select>  
                                    </div>
                                }
                                {!this.props.disabledInput ?
                                    <div className="input-group mb-3 col-9 mt-3">
                                        <div className={this.props.disabledInput  ? "input-group-prepend disabled" : "input-group-prepend"} >
                                            <span className={this.state.hasDocNumber !== null ? this.state.hasDocNumber ? 'input-group-text active' : 'input-group-text fail' : 'input-group-text'} >
                                                {this.state.typePayment === 'receive_transaction' ? 'RT' : 'OR'} -
                                            </span>
                                        </div>
                                        <input type="text" 
                                            className={this.state.hasDocNumber !== null ? this.state.hasDocNumber ? 'form-control input-docNumber active' : 'form-control input-docNumber fail' : 'form-control input-docNumber'} 
                                            onChange={this.handleChange} name="docNumber" value={this.state.docNumber} 
                                            disabled={this.props.disabledInput ||  (this.props.status === 'active' && !this.props.state.permissionEdit)}
                                            />
                                    </div>
                                    :
                                    <div className="mb-3 col-9 mt-3">
                                        <input type="text" 
                                            className='form-control'
                                            onChange={this.handleChange} name="docNumber" value={this.state.docNumber} 
                                            disabled={this.props.disabledInput ||  (this.props.status === 'active' && !this.props.state.permissionEdit)}
                                            />
                                    </div>
    
                                }
                                {this.state.hasDocNumber !== null &&
                                    <React.Fragment>
                                        <div className="col-3"/>
                                        <div className="col-9 mt-1">
                                            <span className={this.state.hasDocNumber ? 'active' : 'fail'}>{this.state.hasDocNumber ? i18next.t("debt_free_request:The payment receipt is valid") : i18next.t("debt_free_request:The number of this receipt is not in the system")}</span>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            
                            {this.props.state.payment && this.props.state.payment !== '' &&
                                <div className="col-6">
                                    <div className='col-12 mt-3'>
                                        <h5>{i18next.t("debt_free_request:Attached files")}</h5>
                                    </div>
                                    <div className="col-12 mt-3 form-inline attach-file">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/pdf-icon.png'} alt="pdf" className="mr-3"/>
                                        <a download="Download"
                                            href={this.props.state.payment}>
                                            <span className="cursor mr-2">{i18next.t("debt_free_request:Payment evidence")}</span>
                                        </a>
                                    </div>
                                </div>
                            }
                        </div> 
                    </React.Fragment>
                }
            </Translation>
        );
    }
}
export default PaymentInput;