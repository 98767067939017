import React, { Component } from 'react'
import _ from "lodash"
import { Helmet } from "react-helmet";
import StockMovementReportPaper from './stockMovementReportPaper';

export default class wrapperStockMovementReport extends Component {

  print() {
    window.print();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet
          style={[{
            "cssText": `
              body {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                background-color: #FAFAFA;
              }
              
              * {
                box-sizing: border-box;
                -moz-box-sizing: border-box;
              }
            
              .page {
                width: 297mm;
                height: 210mm;
                padding: 5mm;
                margin: 10mm auto;
                border: 1px #D3D3D3 solid;
                border-radius: 2px;
                background: white;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
              }
              
              @page {
                size: landscape;
                margin: 0;
              }
              @media print {
                html, body {
                  width: 297mm;
                  height: 210mm;
                }
                .page {
                  margin: 0;
                  border: initial;
                  border-radius: initial;
                  width: initial;
                  min-height: initial;
                  box-shadow: initial;
                  background: initial;
                  page-break-after: always;
                }
              }
            `
          }]}>
          <meta charSet="utf-8" />
          <title>stockMovementReport</title>
          <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
        </Helmet>

        <div className="print-top-menu">
          <div className="logo">
            <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />

          </div>
          <div className="print" onClick={this.print}>
            PRINT
          </div>
        </div>
        
        <StockMovementReportPaper
          reportProductTransactionExport={_.chunk(this.props.location.state.reportProductTransactionExport, 12)}
          statusTable={this.props.location.state.statusTable}
          selfProject={this.props.location.state.selfProject}
          startDate={this.props.location.state.startDate}
          endDate={this.props.location.state.endDate}
          myUser={this.props.location.state.myUser}
          />
      </React.Fragment>
    )
  }
}
