import React from 'react';
import DatePicker from "react-datepicker";

class DatePickerAdapter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMonthDropdown: !props.showMonthYearPicker,
            showYearDropdown:!props.showMonthYearPicker,
            showMonthYearPicker: props.showMonthYearPicker,
            dateFormat: props.dateFormat ? props.dateFormat : "dd/MM/yyyy",
            disable: props.disable ? props.disable : false,
            page:props.page === 'FineCreateModal' ? {zIndex:0} : {}
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.disable !== this.props.disable) {
            this.setState({disable: nextProps.disable})
        }
    }

    handleChange(value) {
        let event = {
            target: {
                name: this.props.name,
                value: value,
                className: this.props.className,
                dataset: {id: this.props['data-id'], key: this.props['data-key']},
            }
        };
        this.props.onChange(event);
    }

    render() {
        return (
            <div className="calendar-wrapper" style = {this.state.page} >
                <DatePicker type="text" className="form-control float-left"
                            dateFormat={this.state.dateFormat}
                            name={this.props.name}
                            selected={this.props.selected}
                            onChange={this.handleChange}
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            showMonthDropdown={this.state.showMonthDropdown}
                            showYearDropdown={this.state.showYearDropdown}
                            showMonthYearPicker={this.state.showMonthYearPicker}
                            dropdownMode="select" required={this.props.required}
                            disabled={this.state.disable}
                            autoComplete="off"
                            popperPlacement={this.props.popperPlacement}
                            selectsStart={this.props.selectsStart}
                            selectsEnd={this.props.selectsEnd}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            showTimeSelect={this.props.showTimeSelect}
                            showTimeSelectOnly= {this.props.showTimeSelectOnly}


                />
                <div className="calendar float-right">
                    <img src={process.env.PUBLIC_URL + '/images/icons/calendar.png'} alt="calendar"/>
                </div>
            </div>
        )
    }
}

DatePickerAdapter.defaultProps = {
    popperPlacement: "bottom-start",
};

export default DatePickerAdapter;
