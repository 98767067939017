import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import { Translation } from 'react-i18next';
import '../styles/asset.scss';
import { fetchQuery} from "relay-runtime";
import environment from '../../../env/environment';
import _ from 'lodash'
import Swal from 'sweetalert2';
import i18n from 'i18next';
import ReceiveCheque from '../../receive/receiveCheque';
import SearchSelect from '../../../libs/searchSelect';
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';

const query = graphql`
    query paymentChannelQuery{
        bankAccountViewer {
            allBankAccount(status: true, accountType_In:"current_account, saving_account, saving_deposit, fixed_deposit", makePayment: null, receivePayment:true) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                    }
                }
                totalCount
            }
        }
        viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: "2210") {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
        otherExpense : viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: "5830") {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
        unknownReceiveViewer{
            allUnknownReceive(status:"wait"){
                edges{
                    node{
                        id
                        docNumber
                        description
                        amount
                    }
                }
            }
        }
    }
`;


const receive_channel = {
    slug: "",
    // pay: "",
    pay: 0,
    image: false,
    cheque: {},
    ref: "",
    unknown_ref: "",
    date: new Date(),
    type_other_pay: false,
};

const cash_channel_choice = [
    {
        node: {
            id: '1',
            slug: "cash",
            accountName: 'เงินสด',
        }
    },
];

const cheque_channel_choice = [
    {
        node: {
            id: '2',
            slug: "cheque",
            accountName: 'เช็ค',
        }
    },

];

const credit_card_channel_choice = [
    {
        node: {
            id: '7',
            slug: "credit-card",
            accountName: 'บัตรเครดิต',
        }
    },

];

const unknown_receive_channel_choice = [

    {
        node: {
            id: '7',
            slug: "unknown-receive",
            accountName: 'เงินรับรอการตรวจสอบ',
        }
    }

];

class PaymentChannel extends Component {
    constructor(props){
        super(props)
        this.state = {
            receive_channel: [],
            channel_choice: [],
            receipt_deposit: [],
            other_expense: [],
            unknown_receive: [],
            channel_other_choice: [],
        }
       
    }

    componentDidMount(){
        this.queryBank()
    }

    queryBank = () => {
        fetchQuery(environment, query, {})
            .then(response_data => {
                let bank_list = [];
                if (_.isObject(response_data.bankAccountViewer)) {
                    let query_bank_data = _.cloneDeep(response_data);
                    query_bank_data.bankAccountViewer.allBankAccount.edges.forEach((bank) => {
                        _.set(bank, 'node.slug', 'bank:' + bank.node.id);
                        _.set(bank, 'node.type', 'bank');
                        let account_type = 'ออมทรัพย์';
                        if (bank.node.accountType === 'CURRENT_ACCOUNT') {
                            account_type = 'กระแสรายวัน'
                        } else if (bank.node.accountType === 'FIXED_DEPOSIT') {
                            account_type = 'ฝากประจำ'
                        }
                        _.set(bank, 'node.account_type', account_type);
                    });
                    bank_list = query_bank_data.bankAccountViewer.allBankAccount.edges;
                }

                let update_channel_choice = [];
                let channel_other_choice = [];

                update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice];
                update_channel_choice = [...update_channel_choice, ...credit_card_channel_choice]
               

                // -------------------------------------------------------- //

                update_channel_choice = [...cash_channel_choice, ...bank_list, ...cheque_channel_choice, ...credit_card_channel_choice, ...unknown_receive_channel_choice]


                let receive_channel_object = {...receive_channel};
                receive_channel_object.pay = this.props.total || "";

                this.setState(
                    {
                        receive_channel: [receive_channel_object],
                        channel_choice: update_channel_choice,
                        channel_other_choice: channel_other_choice,
                        receipt_deposit: response_data.viewer.allChartOfAccount.edges,
                        other_expense: response_data.otherExpense.allChartOfAccount.edges,
                        unknown_receive: response_data.unknownReceiveViewer.allUnknownReceive.edges
                    })
            });


    }

    addReceiveChannel = () => {
        let channel = _.cloneDeep(this.state.receive_channel);
        let channel_object = {...receive_channel};
        channel_object.date = this.props.issued_date;
        let value =  this.props.total - this.props.pay ;
        if (value !== 0) {
            channel_object.pay = value;
        } 
        channel.push(channel_object);
        this.setState({receive_channel: channel});
    }

    deleteChannel = (index) => {
        let channel = _.cloneDeep(this.state.receive_channel);
        channel.splice(index, 1);
        if (channel.length > 0) {
            this.setState({receive_channel: channel}, () => {
                this.props.updateReceiveChannel(this.state.receive_channel);
            });
        }
    }

    handleInputChange = (e) => {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        let state_group = event.target.name.split('.')[0];
        let state_key = event.target.name.split('.')[1];

        if (event.target.type === 'file') {
            if (event.currentTarget.files.length === 0) {
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        event.target.name,
                        false
                    );
                }, () => {
                    this.props.updateReceiveChannel(this.state.receive_channel);
                });

            } else if (event.currentTarget.files.length > 0 && event.currentTarget.files[0].type.split("/")[0] === 'image') {
                value = e.currentTarget.files[0];
                this.setState(prevState => {
                    return _.set(
                        _.cloneDeep(prevState),
                        event.target.name,
                        value
                    );
                }, () => {
                    this.props.updateReceiveChannel(this.state.receive_channel);
                });
            } else {
                Swal.fire(i18n.t('createReceive:Invalid file type!'), i18n.t('createReceive:Please check the validity of this file again.'), 'warning');
            }

        } else {
            if (state_key === 'unknown_ref' || (state_key === 'slug' && value === 'unknown-receive')) {
                let requiredKeys = [event.target.name, state_group + '.pay'];
                let value_set = [value];

                if (state_key === 'slug') {
                    requiredKeys = [...requiredKeys, state_group + '.unknown_ref'];
                    value_set = [...value_set, '', ''];

                } else {
                    this.state.unknown_receive.forEach((unknown_receive) => {
                        if (value === unknown_receive.node.id) {
                            value_set = [...value_set, unknown_receive.node.amount]
                        }
                    });
                }

                this.setState(prevState => {
                    let cloneDeep = _.cloneDeep(prevState);
                    return _.set(cloneDeep, '', requiredKeys.forEach(function (requiredKeys, index) {
                        return _.set(cloneDeep, requiredKeys, value_set[index]);
                    }));
                }, () => {
                    this.props.updateReceiveChannel(this.state.receive_channel)
                });
                return;

            }

            this.setState(prevState => {
                if (state_key === 'pay') {
                    value = parseFloat(value)
                }
                return _.set(
                    _.cloneDeep(prevState),
                    event.target.name,
                    value
                );
            }, () => {
                this.props.updateReceiveChannel(this.state.receive_channel);
            });
        }

    }

    onAddOtherChannel = (type) => {
        let amount_pay = this.calDiffPay('value');
        let channel = _.cloneDeep(this.state.receive_channel);
        let channel_object = {...receive_channel};
        channel_object.date = this.props.issued_date;
        channel_object.slug = type;
        channel_object.pay = amount_pay;
        channel_object.type_other_pay = true;
        channel.push(channel_object);
        this.setState({receive_channel: channel}, () => {
            this.props.updateReceiveChannel(this.state.receive_channel);
        });
    }
    
    returnAbsoluteValue = (value) => {
        let return_value = value ? Math.abs(_.cloneDeep(value)): ' ';
        if (!(return_value || return_value === 0)) {
            return_value = "";
        }
        return return_value;
    }


    render() {
        // let  total = Math.round(((this.props.total) + Number.EPSILON) * 100) / 100;
        // let  pay = Math.round(((this.props.pay) + Number.EPSILON) * 100) / 100; 
        return (
           <Translation>
               {
                   t => 
                   <React.Fragment>
                        {this.state.receive_channel.map((payment_channel, index) =>
                            <div className="fade-up mb-3" key={index} id="asset-sold">
                                <div className="card-body">
                                    <div className="row">
        
                                        <div className="col-12">
                                            <h6>
                                                {
                                                    !this.props.single_payment && <span>{t('createReceive:Method')} {index + 1}</span>
                                                }
        
                                                {index !== 0 &&
                                                <button className="btn btn-danger btn-sm ml-1" type="button"
                                                        onClick={() => this.deleteChannel(index)}>
                                                    {t('createReceive:Delete')}
                                                </button>
                                                }
                                            </h6>
                                        </div>
        
                                        <div className="col-4">
                                            <label>{t('createReceive:Received by')}</label>
                                            <SearchSelect onChange={this.handleInputChange}
                                                        value={this.state.receive_channel[index].slug}
                                                        name={"receive_channel[" + index + "].slug"} placeholder="รายการ"
                                                        queryObject={payment_channel.type_other_pay ? this.state.channel_other_choice : this.state.channel_choice}
                                                        disabled={payment_channel.type_other_pay ? "disabled" : false}
                                                        keyOfValue="slug" require={true}
                                                        keyOfLabel="bankName:account_type:accountName:accountNumber"
                                                        className='form-input-table'
        
                                            />
                                        </div>
        
                                        <div className="col-2">
                                            <label>{t('createReceive:Amount')}</label>
                                            <input type="number" className="form-input-table text-right" style={{opacity: 1}}
                                                onChange={this.handleInputChange}
                                                step=".01"
                                                value={this.returnAbsoluteValue(this.state.receive_channel[index].pay ? parseFloat(parseFloat(this.state.receive_channel[index].pay).toFixed(2)): '')}
                                                name={"receive_channel[" + index + "].pay"}
                                                required={true}
                                                disabled={this.state.receive_channel[index].slug === 'unknown-receive' ? "disabled" : false}
                                            />
                                        </div>
        
                                        {_.startsWith(this.state.receive_channel[index].slug, "bank") &&
                                        <div className="col-2 ml-2 mr-2">
                                            <label>{t('createReceive:date')}</label><br/>
                                            <DatePickerNoti
                                                className="form-input-table" name={"receive_channel[" + index + "].date"}
                                                selected={this.state.receive_channel[index].date}
                                                onChange={this.handleInputChange}
                                                required={true}
                                                data-key="payment_list"
                                                minDate={this.props.min_date && this.props.min_date}
                                                width={20}
                                                height={20}
                                            />
        
                                        </div>
                                        }
        
                                        {_.startsWith(this.state.receive_channel[index].slug, "bank") &&
                                        <div className="col-2">
                                            <label>{t('createReceive:Receipt')}</label> <br/>
                                            <label className={
                                                this.state.receive_channel[index].image ?
                                                    "btn btn-success cursor mr-1 ml-1" : "btn btn-secondary cursor ml-1 mr-1"
                                            }>
                                            {this.state.receive_channel[index].image ? t('createReceive:Done') : t('createReceive:Upload receipt')}
                                            <input type="file" name={"receive_channel[" + index + "].image"}
                                                data-key="payment_list"
                                                onChange={this.handleInputChange}
                                                hidden/>
                                            </label>
                                            {(payment_channel.image.name && payment_channel.image.name.length >= 30) ?
                                                payment_channel.image.name.substring(0, 30) + '...' : payment_channel.image.name
                                            }
                                        </div>
                                        }
        
                                        {
                                            this.state.receive_channel[index].slug === 'receipt-deposit' &&
                                            <div className="col-2 clearfix">
                                                <label>{t('createReceive:Select the Receipt deposit')}</label> <br/>
                                                <SearchSelect onChange={this.handleInputChange}
                                                            value={this.state.receive_channel[index].ref}
                                                            name={"receive_channel[" + index + "].ref"} placeholder="รายการ"
                                                            queryObject={this.state.receipt_deposit}
                                                            keyOfValue="id" require={true}
                                                            keyOfLabel="chartOfAccountCode:name"
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.receive_channel[index].slug === 'unknown-receive' &&
                                            <div className="col-4 clearfix">
                                                <label>{t('createReceive:Cash/receipt pending review')}</label> <br/>
                                                <SearchSelect onChange={this.handleInputChange}
                                                            value={this.state.receive_channel[index].unknown_ref}
                                                            name={"receive_channel[" + index + "].unknown_ref"}
                                                            placeholder="รายการ"
                                                            queryObject={this.state.unknown_receive}
                                                            keyOfValue="id" require={true}
                                                            keyOfLabel="docNumber:description"
                                                />
                                            </div>
                                        }
        
                                    </div>
                                    {
                                        this.state.receive_channel[index].slug === 'cheque' &&
                                        <div className="row">
                                            <div className="col-8 clearfix">
                                                <ReceiveCheque 
                                                    index={index} 
                                                    receive_channel={this.state.receive_channel}
                                                    handleInputChange={this.handleInputChange}
                                                    page="other_receiver"
                                                    />
                                            </div>
                                        </div>
        
                                    }
        
        
                                </div>
                            </div>
                        )}
        
                        <div className="row mt-3 rowAdd">
                            <div className="col">
                                <span className='cursor addChannel'   onClick={() => this.addReceiveChannel()}>
                                    + เพิ่มช่องทาง
                                </span>
                            </div>
                        </div>

                   </React.Fragment>
               }
           </Translation>
        )
    }
}

export default PaymentChannel;