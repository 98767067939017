import React,{Component} from 'react'
import { Translation } from 'react-i18next';
import Wrapper from '../../components/wrapper';
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from 'react-router-dom';
import './settingAsset.scss'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import numberWithCommas from '../../libs/numberWithComma';
import SettingTopMenuNavigation from '../settingTopMenuNavigation';
import Loading from '../../libs/loading';
import _ from 'lodash'


const query = graphql`
    query settingAssetFormQuery {
        allSettingAsset(settingAssetId: "", typeAsset: ""){
            edges{
                node{
                    id
                    typeAsset
                    depreciationRatePercent
                    depreciationRate
                    lifeTimeYear
                    chartOfAccountType {
                        id
                        chartOfAccountCode
                        name
                        nameEn
                    }
                    chartOfAccountDepreciation {
                        id
                        chartOfAccountCode
                        name
                        nameEn
                    }
                    chartOfAccountDepreciationCumulative {
                        id
                        chartOfAccountCode
                        name
                        nameEn
                    }
                }
            }
        } 
}`;

const assetList = 
    [
        {
            id: '',
            typeAsset : 'land',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        {
            id: '',
            typeAsset : 'building',
            depreciationRatePercent : null,
            lifeTimeYear : null,
        
        },
        {
            id: '',
            typeAsset : 'building_renovation',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        {
            id: '',
            typeAsset : 'office_decoration',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        {
            id: '',
            typeAsset : 'office_equipment',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        {
            id: '',
            typeAsset : 'tools',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        {
            id: '',
            typeAsset : 'training_equipment',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        {
            id: '',
            typeAsset : 'vehicle',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        {
            id: '',
            typeAsset : 'common_property',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        {
            id: '',
            typeAsset : 'other_asset',
            depreciationRatePercent : null,
            lifeTimeYear : null,
        
        },
        {
            id: '',
            typeAsset : 'intangible_asset',
            depreciationRatePercent : null,
            lifeTimeYear : null,
            
        },
        
    ]

class SettingAssetForm extends Component{
    constructor(props){
        super(props)
        this.state = {
            assetList : assetList,
            loading : false
        }
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = () => {
        this.setState({loading : true})
        fetchQuery(environment, query).then(response_data => {
            if(response_data){
                if(response_data.allSettingAsset.edges.length > 0){
                    let assetList = _.cloneDeep(this.state.assetList)
                    _.forEach(response_data.allSettingAsset.edges, asset => {
                        let index = _.findIndex(assetList,  {'typeAsset' : _.lowerCase(asset.node.typeAsset).replace(' ','_')})
                        if(index !== -1){
                            this.setState(prevState => {
                                return _.set(
                                    _.cloneDeep(prevState),
                                    "assetList["+index+"]depreciationRatePercent",
                                    asset.node.depreciationRatePercent
                                )}
                            );
                            this.setState(prevState => {
                                return _.set(
                                    _.cloneDeep(prevState),
                                    "assetList["+index+"]lifeTimeYear",
                                    asset.node.lifeTimeYear
                                )}
                            );
                            this.setState(prevState => {
                                return _.set(
                                    _.cloneDeep(prevState),
                                    "assetList["+index+"]id",
                                    asset.node.id
                                )}
                            );
                            this.setState({
                                loading : false
                            })
                        }
                    })
                }else{
                    this.setState({loading : false})
                }
            }
        })
        .catch(err => {
            alert('ขออภัย ระบบขัดข้องในการดึงข้อมูล')
            console.log(err);
        })
    }

    render(){
        return(
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <SettingTopMenuNavigation mini={true} shortMenuOther={true}/>
                    <div style={{paddingTop:'5%',paddingLeft:'20px'}}>
                    <Translation>
                        {
                            t => <h4 className="mb-4" style={{fontSize:'24px'}}>
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back"/>
                                    </Link>{t('setting:Asset setting')}
                                </h4>
                        }
                    </Translation>
                    </div>
                    <div className="container-fluid box" id="asset-setting">
                        <div className="row justify-content-between">
                            <div className="col">
                                   
                            </div>
                        </div>
                        <div className="content-inner padding" >
                            <div className="row col-12  mb-24">
                                <Translation>
                                        {
                                            t => <h4>
                                                   {t('settingAsset:Default setting of asset category')}
                                                </h4>
                                        }
                                    </Translation>
                            </div>
                            <hr/>
                            {this.state.loading ? 
                             <Loading/>
                                // <span>
                                //     <span className="spinner-border spinner-border-sm align-middle mr-2"/> {i18next.t("Allaction:Preparing information")}</span> 
                                :
                                <div className="row col-12 table-responsive mt-24 mb-24">
                                    <Translation>
                                        {t => 
                                            <table className="table">
                                                <thead>
                                                    <tr className="head" >
                                                        <th width={150} className="text-center" > {t('settingAsset:No')}</th>
                                                        <th >{t('settingAsset:Asset')}</th>
                                                        <th  width={150} className="text-center"> {t('settingAsset:Useful life')}</th>
                                                        <th width={150} className="text-center"> {t('settingAsset:Annual rate')}</th>
                                                        <th  width={100} />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.assetList.map((asset,index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td>{t('assetSetting:'+asset.typeAsset)}</td>
                                                                <td className="text-center">{asset.lifeTimeYear ? asset.lifeTimeYear + t('settingAsset:Year')  : '-'}</td>
                                                                <td className="text-center">{asset.depreciationRatePercent ?numberWithCommas(asset.depreciationRatePercent,'-')+'%' : '-'}</td>
                                                                <td className="edit"><Link to={"/setting/asset/edit/"+asset.typeAsset+"/"+asset.id}>
                                                                    <span className="edit cursor">{_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'asset_settingasset_edit'}) 
                                                                ? t('settingAsset:Edit') : t('settingAsset:View')}</span></Link></td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                    </Translation>
                                </div>
                            }
                            <div className="row col-12 mt-24 mb-24">
                                <Translation>
                                        {
                                            t => <h4>
                                                   {t('settingAsset:Depreciation')}
                                                </h4>
                                        }
                                </Translation>
                            </div >
                            <hr />
                            
                            <div className="row col-12 form-inline mt-24 mb-24">
                                <input type ="radio" disabled={true} checked={true} className="mr-3"/>
                                <Translation>
                                        {
                                            t => <span className="disable">
                                                   {t('settingAsset:The System will be automatically configure every month')}
                                                </span>
                                        }
                                </Translation>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>

        );
    }

}
export default SettingAssetForm;