import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'
import _ from "lodash"
import Swal from 'sweetalert2'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment'
import { Redirect } from "react-router-dom";
import CreatePurchaseOrderes from "./mutation/createPurchaseOrderes"
import ApprovePurchaseOrderes from "./mutation/approvePurchaseOrderes"

//component
import HerderAlert from "./headerAlert/herderAlert"
import FormHeadOrderType from './formHeadOrderType/formHeadOrderType'
import FormOrderList from './formOrderList/formOrderList'
import FormContactDetails from './formContactDetails/formContactDetails'
import FormSummarize from './formSummarize/formSummarize'
import NotConfirmManageOrder from './modalManageOrder/notConfirmManageOrder'
import ClaimTopMenuNavigation from '../claim/claimTopMenuNavigation'
import i18next from 'i18next'

const query = graphql`
  query updateFormManageOrderQuery($poId: String, $prfId: String){
    productViewer{
      allProduct (type_In: "product, expense",isActive: true){
        edges{
          node{
            id
            name
            productCode
            type
            price
            chartOfAccount{
                id
                chartOfAccountCode
                name
            }
            creditorChartOfAccount{
                id
                chartOfAccountCode
                name
            }
            description
            taxRate
            inputTax
            outputTax
          }
        }
      }
    }
    viewer{
      allChartOfAccount(codeList: ["12"]){
        edges{
          node{
            id
            chartOfAccountCode
            name
          }
        }
      }
    }
    onePurchaseOrderes(poId: $poId){
      edges {
        node {
          id
          docNumber
          issuedDate
          status
          voidRemark
          voidDate
          voider
          approver
          approveDate
          procurementForm {
            id
            docNumber
            issuedDate
            approveDate
            creditor{
              id
              name
            }
            purchasing
            buyDate
            contractNumber
            contractDate
            paymentTerms
            otherPaymentTerms
            chartOfAccount{
              id name nameEn chartOfAccountCode 
            }
            contractor
            contractorTel
            contractorEmail
            deliveryAddress
            deliveryDate
            remark
            total
            startContractDate
            endContractDate
            contractPeriod
            facilityUse
            procurementList{
              edges{
                node{
                  id
                  productAndService{
                    id
                    name
                    productCode
                  }
                  chartOfAccount{
                    id
                    chartOfAccountCode
                    name
                  }
                  description
                  unitItems
                  price
                  unit
                  discount
                  budget
                  vat
                  whtRate
                  whtRatePercent
                  preTaxAmount
                  total
                }
              }
            }
            procurementFormImage{
              edges{
                node{
                  fileUpload
                  pathName
                }
              }
            }
            procurementFormContract{
              edges{
                node{
                  fileUpload
                  pathName
                  fileName
                }
              }
            }
          }
        }
      }
    }
    oneProcurementForm(prfId: $prfId){
      edges {
        node {
          id
          docNumber
          issuedDate
          approveDate
          creditor{
            id
            name
          }
          purchasing
          buyDate
          contractNumber
          contractDate
          paymentTerms
          otherPaymentTerms
          chartOfAccount{
            id name nameEn chartOfAccountCode 
          }
          contractor
          contractorTel
          contractorEmail
          deliveryAddress
          deliveryDate
          startContractDate
          endContractDate
          contractPeriod
          facilityUse
          remark
          procurementList{
            edges{
              node{
                id
                productAndService{
                  id
                  name
                  productCode
                }
                chartOfAccount{
                  id
                  chartOfAccountCode
                  name
                }
                description
                unitItems
                price
                unit
                discount
                budget
                vat
                whtRate
                whtRatePercent
                preTaxAmount
                total
              }
            }
          }
          procurementFormImage{
            edges{
              node{
                fileUpload
                pathName
              }
            }
          }
          procurementFormContract{
            edges{
              node{
                fileUpload
                pathName
                fileName
              }
            }
          }
        }
      }
    }
    checkUserAdmin
    limitMoney( limitType : "purchase_orderes")
  }
` 

const swalSaveFormManageRequest = Swal.mixin({
  customClass: {
    confirmButton: 'swal-BtnConfirm',
    cancelButton: 'swal-BtnCancel ml-3',
  },
  width: '640px',
  buttonsStyling: false
})

export default class updateFormManageOrder extends Component {

  constructor(){
    super()

    this.state = {
      closeHaederAlert: "d-block",
      docNumber: "",
      id: "",
      addPurchasing: {
        title: "",
        detail: ""
      },
      issuedDate: null,
      voidRemark: "",
      voidDate: "",
      voider: "",
      approver: "",
      approveDate: "",
      approvePODate: new Date(),
      procurementForm: {
        creditor: "",
        buyDate: new Date(),
        contractNumber: "",
        contractDate: new Date(),
        contractor: "",
        contractorEmail: "",
        contractorTel: "",
        deliveryAddress: "",
        deliveryDate: new Date(),
        docNumber: "",
        id: "",
        issuedDate: null,
        approveDate: null,
        approvePODate:null,
        paymentTerms: "",
        otherPaymentTerms: "",
        chartOfAccountId: "",
        facilityUse: "",
        startContractDate: new Date(),
        endContractDate: new Date(),
        contractPeriod: "",
        procurementFormContract: [],
        procurementFormImage: [],
        procurementList: [],
        remark: "",
        total: ""
      },
      productAndServiceList: [],
      redirectTo: "",
      notConfirm: false,
      checkUserAdmin: false,
      signreq:Math.random().toString(36),
    }
  }

  componentDidMount(){
    this.getData()
  }

  getData = () => {
    fetchQuery(environment, query, { poId: this.props.match.params.id, prfId: this.props.match.params.id }).then((response) => {

      let productAndServiceLists = [];

      if(response.productViewer){
        _.map(response.productViewer.allProduct.edges, (product) => {
          productAndServiceLists.push(product)
        })
      }
      if(response.viewer){

        _.map(response.viewer.allChartOfAccount.edges, (chart_of_account) => {
          let list_object = {
            id: chart_of_account.node.id,
            name: chart_of_account.node.name,
            productCode: chart_of_account.node.chartOfAccountCode,
            price: "",
            description: "",
            taxRate: "",
            chartOfAccount: {
              id: chart_of_account.node.id,
              chartOfAccountCode: chart_of_account.node.chartOfAccountCode,
              name: chart_of_account.node.name,
            },
            type: "chart_of_account",
          };

          productAndServiceLists.push({ node: list_object })
        })
      }

      if(response.onePurchaseOrderes.edges.length > 0 && this.props.match.params.status !== "wait_open"){
        let item = response.onePurchaseOrderes.edges[0].node

        let newProcurementForm = {
          issuedDate: new Date(item.procurementForm.issuedDate),
          approveDate: new Date(item.procurementForm.approveDate),
          creditor: item.procurementForm.creditor.id,
          addPurchasing: {
            title: item.procurementForm.purchasing  === "ซื้อเพิ่ม" ? "buyMore" : "buyReplaceAssetsNo",
            detail: item.procurementForm.purchasing
          },
          buyDate: new Date(item.procurementForm.buyDate),
          contractNumber: item.procurementForm.contractNumber,
          contractDate: new Date(item.procurementForm.contractDate),
          paymentTerms: item.procurementForm.paymentTerms === "OTHER" ? "other" : item.procurementForm.paymentTerms.substring(2),
          otherPaymentTerms: item.procurementForm.otherPaymentTerms,
          chartOfAccountId: item.procurementForm.chartOfAccount?.id,
          facilityUse: item.procurementForm.facilityUse,
          contractor: item.procurementForm.contractor,
          contractorTel: item.procurementForm.contractorTel,
          contractorEmail: item.procurementForm.contractorEmail,
          deliveryAddress: item.procurementForm.deliveryAddress,
          deliveryDate: new Date(item.procurementForm.deliveryDate),
          docNumber: item.procurementForm.docNumber,
          id: item.procurementForm.id,
          startContractDate: new Date(item.procurementForm.startContractDate),
          endContractDate: new Date(item.procurementForm.endContractDate),
          contractPeriod: item.procurementForm.contractPeriod,
          procurementFormContract: item.procurementForm.procurementFormContract.edges,
          procurementFormImage: item.procurementForm.procurementFormImage.edges,
          procurementList: item.procurementForm.procurementList.edges,
          remark: item.procurementForm.remark,
          total: item.procurementForm.total,

        }
        this.setState({
          docNumber: item.docNumber,
          id: item.id,
          issuedDate: new Date(item.issuedDate),
          voidRemark: item.voidRemark,
          voidDate: item.voidDate,
          voider: item.voider,
          approver: item.approver,
          approveDate: item.approveDate,
          procurementForm: newProcurementForm,
        })
      }
      if(response.onePurchaseOrderes.edges.length > 0 && this.props.match.params.status === "wait"){
        this.setState({
          approvePODate: new Date(),
        })
      }
      
      if(response.onePurchaseOrderes.edges.length > 0 && this.props.match.params.status === "approve"){
        let item = response.onePurchaseOrderes.edges[0].node
        this.setState({
          approvePODate: new Date(item.approveDate),
        })
      }

      if(response.oneProcurementForm.edges.length > 0 && this.props.match.params.status === "wait_open"){
        let item = response.oneProcurementForm.edges[0].node

        let newProcurementForm = {
          issuedDate: new Date(item.issuedDate),
          approveDate: new Date(item.approveDate),
          creditor: item.creditor.id,
          addPurchasing: {
            title: item.purchasing  === "ซื้อเพิ่ม" ? "buyMore" : "buyReplaceAssetsNo",
            detail: item.purchasing
          },
          buyDate: new Date(item.buyDate),
          contractNumber: item.contractNumber,
          contractDate: new Date(item.contractDate),
          paymentTerms: item.paymentTerms === "OTHER" ? "other" : item.paymentTerms.substring(2),
          otherPaymentTerms: item.otherPaymentTerms,
          chartOfAccountId: item.chartOfAccount?.id,
          facilityUse: item.facilityUse,
          contractor: item.contractor,
          contractorTel: item.contractorTel,
          contractorEmail: item.contractorEmail,
          deliveryAddress: item.deliveryAddress,
          deliveryDate: new Date(item.deliveryDate),
          docNumber: item.docNumber,
          id: item.id,
          startContractDate: new Date(item.startContractDate),
          endContractDate: new Date(item.endContractDate),
          contractPeriod: item.contractPeriod,
          procurementFormContract: item.procurementFormContract.edges,
          procurementFormImage: item.procurementFormImage.edges,
          procurementList: item.procurementList.edges,
          remark: item.remark,
        }
        this.setState({
          issuedDate: null,
          procurementForm: newProcurementForm,
        })

      }

      this.setState({ productAndServiceList:  productAndServiceLists, limitMoney:  response.limitMoney , checkUserAdmin:response.checkUserAdmin })
      
    })
  }

  checkPurchaseRequisitionType = (type) => {

    let valueResult = ""

    switch (type) {
      case "PRODUCT":
        valueResult = i18next.t("purchaseRequisition:Product")
        break;
      case "SERVICE":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(General work)")
        break;
      case "SERVICE_CONTACT":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(Contract)")
        break;
      case "ASSET":
        valueResult = i18next.t("purchaseRequisition:Asset")
        break;
      case "product":
        valueResult = i18next.t("purchaseRequisition:Product")
        break;
      case "service":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(General work)")
        break;
      case "service_contact":
        valueResult = i18next.t("purchaseRequisition:Contract Service / Expenses(Contract)")
        break;
      case "asset":
        valueResult = i18next.t("purchaseRequisition:Asset")
        break;
      
      default:
        break;
    }
    return valueResult
  }

  getStatus = (text) => {

    let valueResult = ""
    let colorResult = ""

    switch (text) {
      case "wait_open":
        valueResult = "Wait PO"
        colorResult = "statusGray"
        break;
      case "wait": //รออนุมัติ
        valueResult = "Pending"
        colorResult = "statusYellow"
        break;
      case "approve": 
        valueResult = "Approve"
        colorResult = "statusGreen"
        break;
      case "void": 
        valueResult = "Cancled"
        colorResult = "statusRed"
        break;

      default:
        break;
    }

    return (
      <Translation>
        {t =>
          <div className={`${colorResult}` + " ml-3"}>
            <span>{i18next.t("PageListPRPO:"+valueResult)}</span>
          </div>
        }
      </Translation>
    )
  }

  handleChangeAppreveSate = (e) => {
    let {value} = e.target;

    this.setState({
      approvePODate:value
    })
  }

  handleChangeInput = (e) => {
    let { name, value } = e.target;

    this.setState(prevState => {
      return _.set(_.cloneDeep(prevState), name, value)
    });
  }

  handleCloseHeaderAlert = () => {
    this.setState({closeHaederAlert: "d-none"})
  }

  handleFiling = () => {

    swalSaveFormManageRequest.fire({
      html: 
      `<img class="my-4" src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue"/>
      <p><b>${i18next.t("manageOrderTable:Do you open purchase order")}?</b></p>`,
      showCancelButton: true,
      confirmButtonText: i18next.t("Allaction:Yes"),
      cancelButtonText: i18next.t("Allaction:cancel"),
    }).then(async (response) => {
      if(response.value){
        let variables = {
          input: {
            procurementFormId: [this.props.match.params.id],
            clientMutationId:"PO"+this.state.signreq,
          }
        }

        CreatePurchaseOrderes(
          variables, 
          (res) => { 

            if(res.createPurchaseOrderes.ok){
              Swal.fire(i18next.t("manageOrderTable:Successfully submit a purchase order"), '', 'success').then(() => {
                this.setState({
                  redirectTo: '/purchasing/manageOrder/list/all'
                })
              })

            }else{
              Swal.fire(i18next.t("manageOrderTable:Failed to submit a purchase order"), i18next.t("Allaction:Please try again."), 'warning')
            }
          }, 
          () => { Swal.fire(i18next.t("manageOrderTable:Failed to submit a purchase order"), '', 'warning')}
        )
      }
    })
  }

  handleApprove = () => {
    if(this.state.procurementForm.total <= this.state.limitMoney || this.state.checkUserAdmin){
      swalSaveFormManageRequest.fire({
        html: 
        `<img class="my-4" src="/images/iconAlerts/iconAlert-Blue.png" alt="icon-blue"/>
        <p><b>${i18next.t("manageOrderTable:Do you want to approve the purchase order")}<br/>
        ${i18next.t("manageOrderTable:number")} ${this.state.docNumber} ?</b></p>`,
        showCancelButton: true,
        confirmButtonText: i18next.t("Allaction:Approve"),
        cancelButtonText: i18next.t("Allaction:cancel"),
      }).then(async (respone) => {

        if(respone.value){
          let variables = {
            input: {
              purchaseOrderesId: this.props.match.params.id,
              approveDate:this.state.approvePODate,
            }
          }

          ApprovePurchaseOrderes(
            variables, 
            (res) => { 
              if(res.approvePurchaseOrderes.ok){
                Swal.fire(i18next.t("Allaction:Successful"), '', 'success').then(() => {
                  this.setState({
                    redirectTo: '/purchasing/manageOrder/list/all'
                  })
                })
  
              }else{
                Swal.fire(i18next.t("manageOrderTable:Failed to approve"), i18next.t("Allaction:Please try again."), 'warning')
              }
            }, 
            () => { Swal.fire(i18next.t("manageOrderTable:Failed to approve"), '', 'warning')}
          )
        }
      })
      
    }else {
      Swal.fire(i18next.t("manageOrderTable:The credit limit for those authorized to approve is insufficient."), '', 'error')
    }

  }

  handleCloseModal = () => {
    this.setState({ notConfirm: false })
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />
    }

    return (
      <Wrapper>
        <Header/>
        <Sidebar/>
        <WrapperContent>
          <ClaimTopMenuNavigation mini="true"/>

          <div className="container-fluid box" id="updateFormManageOrder">
            <div className="row">
              <div className="col">
                <Translation>
                  {
                    t =>
                      <h4 className="mb-4">
                        <Link to={"/purchasing/manageOrder/list/all"}
                          >
                          <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                            alt="back" className="back" />
                        </Link>
                        {t("manageOrderTable:Purchase order")}
                      </h4>
                  }
                </Translation>
              </div>
            </div>

            <div className="content-inner">

              <div className={`${this.state.closeHaederAlert} row mt-4`}>
                <div className="col">
                  <HerderAlert 
                    status={this.props.match.params.status}
                    voidRemark={this.state.voidRemark}
                    voidDate={this.state.voidDate}
                    voider={this.state.voider}
                    approver={this.state.approver}
                    approveDate={this.state.approveDate}
                    handleCloseHeaderAlert={this.handleCloseHeaderAlert}
                    handleFiling={this.handleFiling}
                    />
                </div>
              </div>

              <div className="row spaceTop">
                <div className="col">
                  <h5 className="headCheckType">
                  <Translation>
                  {
                    t => t("manageOrderTable:Purchase order")
                  }
                  </Translation>
                 : {this.checkPurchaseRequisitionType(this.props.match.params.type)} {this.getStatus(this.props.match.params.status)}
                  </h5>
                </div>
              </div>

              <div className="row spaceTop">
                <div className="col">
                  <FormHeadOrderType
                    docNumber={this.state.docNumber}
                    issuedDate={this.state.issuedDate}
                    approvePODate={this.state.approvePODate}
                    handleChangeAppreveSate={this.handleChangeAppreveSate}
                    procurementForm={this.state.procurementForm}
                    type={this.props.match.params.type}
                    status={this.props.match.params.status}
                    />
                </div>
              </div>

              <div className="row spaceTop">
                <div className="col">
                  <FormOrderList 
                    productAndServiceList={this.state.productAndServiceList}
                    procurementList={this.state.procurementForm.procurementList}
                    procurementForm={this.state.procurementForm}
                    type={this.props.match.params.type}
                    />
                </div>
              </div>

              <div className="row spaceTop">
                <div className="col">
                  <FormContactDetails
                    procurementForm={this.state.procurementForm}
                    // handleChangeInput={this.handleChangeInput}
                    />
                </div>
              </div>
              
              <div className="row spaceTop">
                <div className="col">
                  <FormSummarize 
                    procurementForm={this.state.procurementForm}
                    />
                </div>
              </div>

              { this.props.match.params.status === "wait_open" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_purchase_orderes_create' }) &&
                <div className="row spaceTop mb-5">
                  <div className="col d-flex justify-content-end">
                    <button className="btnPrimary" onClick={this.handleFiling}>
                      <span><Translation>{t=> t("manageOrderTable:Submit PO")}</Translation></span>
                    </button>
                  </div>
                </div>
              }

              { this.state.notConfirm && 
                <NotConfirmManageOrder
                  open={this.state.notConfirm}
                  purchaseOrderesId={this.props.match.params.id}
                  handleCloseModal={this.handleCloseModal}
                  handleChangeInput={this.handleChangeInput}
                  />
              }

              { this.props.match.params.status === "wait" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_purchase_orderes_approve' }) &&
                <div className="row spaceTop mb-5">
                  <div className="col d-flex justify-content-end">
                    <button type="button" className="btnRed" onClick={() => this.setState({ notConfirm: true })}>
                      <span><Translation>{t=> t("manageOrderTable:Not Approved")}</Translation></span>
                    </button>
                    <button type="button" className="ml-5 btnPrimary" onClick={this.handleApprove}>
                      <span><Translation>{t=> t("manageOrderTable:Approve")}</Translation></span>
                    </button>
                  </div>
                </div>
              }

              { this.props.match.params.status === "approve" &&_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_purchase_orderes_print' }) &&
                <div className="row spaceTop mb-5">
                  <div className="col d-flex justify-content-end">
                    <Link to={`/document/manageOrder/${this.props.match.params.id}`}
                      target="_blank">
                      <button className="btnPrimary">
                        <span><Translation>{t=> t("manageOrderTable:Print this PO")}</Translation></span>
                      </button>
                    </Link>
                  </div>
                </div>
              }

              <div className="mb-5"/>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    )
  }
}
