import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from 'i18next'
import '../styles/register.scss'

import Navigation from "./navigation";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {commitMutation} from "react-relay";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import {graphql} from "babel-plugin-relay/macro";

const mutation = graphql`
    mutation updateAddressMutation($input: UpdateAddressInput!){
        updateAddress(input: $input){
            ok
        }
    }
`;

const query = graphql`
    query updateAddressQuery($id: ID!) {
        residential(id: $id) {
            id
            name
            homeNumber
            villageBuilding
            villageNumber
            lane
            soi
            postalCode
            province
            district
            subDistrict
        }
    }
`;

class UpdateAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            name: '',
            home_number: '',
            village_building: '',
            village_number: '',
            lane: '',
            soi: '',
            postal_code: '',
            province: '',
            district: '',
            sub_district: '',
            loading: false,
            redirectToList:false,
        };
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.room_id) {
            fetchQuery(environment, query, {id: this.props.match.params.room_id}).then(data => {
                if (data.residential) {
                    this.setState({
                        id: data.residential.id,
                        name: data.residential.name,
                        home_number: data.residential.homeNumber,
                        village_building: data.residential.villageBuilding,
                        village_number: data.residential.villageNumber,
                        lane: data.residential.lane,
                        soi: data.residential.soi,
                        postal_code: data.residential.postalCode,
                        province: data.residential.province,
                        district: data.residential.district,
                        sub_district: data.residential.subDistrict,
                    });
                }
            });
        }
    }

    handleChangeInput(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let addressObject = {
            id: this.state.id,
            home_number: this.state.home_number,
            village_building: this.state.village_building,
            village_number: this.state.village_number,
            lane: this.state.lane,
            soi: this.state.soi,
            postal_code: this.state.postal_code,
            province: this.state.province,
            district: this.state.district,
            sub_district: this.state.sub_district,
        };
        let variables = {
            input: {
                addressObject: JSON.stringify(addressObject)
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.updateAddress.ok) {
                        Swal.fire(i18n.t("residential:Save successfully!"), '', 'success').then(() => {
                            this.setState({redirectToList: true})
                        });
                    } else {
                        Swal.fire(i18n.t("residential:Save unsuccessfully!"), '', 'warning');
                    }

                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire(i18n.t("residential:Save unsuccessfully!"), '', 'error');
                },
            },
        );
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect
                to={{pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id}}/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid p-4" id="form-detail">
                        <div className="row justify-content-between">
                            <div className="col header-color">
                                <h3 className="mb-4">
                                    <Link
                                        to={{pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id}}>
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    {this.state.name}
                                </h3>
                                <div className="content-inner">
                                    <Navigation id={this.props.match.params.id} name={this.state.name}/>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={this.onSubmit}>
                            <div className="content-inner">
                                <div className="row mb-4 fade-up">
                                    <div className="col-md-6 col-xl-6">
                                        <div className="card p-3">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h4 className="mb-3">
                                                            <Translation>
                                                                {
                                                                    t => <span>{t('register:register_address')}</span>
                                                                }
                                                            </Translation>
                                                        </h4>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:no.")}</h6>}</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.home_number}
                                                               onChange={this.handleChangeInput}
                                                               name="home_number"/>
                                                    </div>
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Village/Building")}</h6>}</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.village_building}
                                                               onChange={this.handleChangeInput}
                                                               name="village_building"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Moo")}</h6>}</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.village_number}
                                                               onChange={this.handleChangeInput}
                                                               name="village_number"/>
                                                    </div>

                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Soi")}</h6>}</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.soi}
                                                               onChange={this.handleChangeInput}
                                                               name="soi"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <Translation>{t=><h6>{t("residential:Road")}</h6>}</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.lane}
                                                               onChange={this.handleChangeInput}
                                                               name="lane"/>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Sub-district")}</h6>}</Translation>
                                                        {/*<select className="custom-select">*/}
                                                        {/*    <option>-- ระบุแขวง --</option>*/}
                                                        {/*</select>*/}
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.sub_district}
                                                               onChange={this.handleChangeInput}
                                                               name="sub_district"/>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:District")}</h6>}</Translation>
                                                        {/*<select className="custom-select">*/}
                                                        {/*    <option>-- ระบุเขต --</option>*/}
                                                        {/*</select>*/}
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.district}
                                                               onChange={this.handleChangeInput}
                                                               name="district"/>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Province")}</h6>}</Translation>
                                                        {/*<select className="custom-select">*/}
                                                        {/*    <option>-- ระบุจังหวัด --</option>*/}
                                                        {/*</select>*/}
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.province}
                                                               onChange={this.handleChangeInput}
                                                               name="province"/>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Zip code")}</h6>}</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.postal_code}
                                                               onChange={this.handleChangeInput}
                                                               maxLength="5"
                                                               name="postal_code"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>
                                                            {t=>
                                                            <button type="submit" disabled={this.state.loading}
                                                                    className="btn btn-primary bt-style form-update">
                                                                    {t("residential:Save")}
                                                                {
                                                                    this.state.loading &&
                                                                    <span
                                                                        className="spinner-border spinner-border-sm align-middle mr-2"/>
                                                                }
                                                            </button>}
                                                        </Translation>
                                                    </div>
                                                    <div className="col">
                                                         <Link
                                                             to={{
                                                                 pathname: "/register/residential/detail/room-detail/" + this.props.match.params.id}}>
                                                            <Translation>
                                                                {t=>
                                                                <button className="btn btn-secondary bt-style form-update">
                                                                    {t("residential:Cancel")}
                                                                </button>}
                                                            </Translation>
                                                         </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default UpdateAddress;
