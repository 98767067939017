import React, { Component } from "react";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "./numberWithComma";
import i18n from 'i18next';
import paymentGatewayText from "./paymentGatewayText";
import i18next from "i18next";

const _ = require('lodash');

class TransactionChannel extends Component {

    validatePaymentEmpty() {
        // เช็ค array ว่าว่างหมดรึป่าว
        if (_.isEmpty(this.props.cashTransactionViewer.allCashTransaction.edges) && _.isEmpty(this.props.chequeDepositViewer.allChequeDeposit.edges) && _.isEmpty(this.props.chequeTransactionViewer.allChequeTransaction.edges) && _.isEmpty(this.props.allBankAccountTransaction.edges) && _.isEmpty(this.props.allCreditCardTransaction.edges)
            && _.isEmpty(this.props.allOtherExpensePaymentChannel.edges) && _.isEmpty(this.props.allAdvancePettyCashChannel.edges) && _.isEmpty(this.props.allClearAdvancePettyCashChannel.edges)  && _.isEmpty(this.props.allClearGuaranteeMoneyReceivedPettyCashChannel.edges)  && _.isEmpty(this.props.allPaymentChannel.edges) && _.isEmpty(this.props.allPaymentGatewayMethod.edges)) {
            return true
        } else {
            return false
        }
    }

    validatePaymentPettyCash(price) {
        if (this.props.total && _.startsWith(this.props.ref_docNumber, "SPC")) {
            if (this.props.total !== price) {
                return true
            }
        }
        return false
    }

    render() {
        if (this.validatePaymentEmpty()) {
            return ''
        }
        return (
            <div className="card mt-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <p><strong>{i18next.t("dailyjournalDetail:Detail")}</strong></p>
                            {this.props.cashTransactionViewer.allCashTransaction.edges.map((cash) => {
                                if (this.validatePaymentPettyCash(cash.node.price)) {
                                    return ""
                                }
                                return (
                                    <p key={cash.node.id}>เงินสด/Cash {numberWithComma(cash.node.price)} {i18next.t("TransactionChannel:THB")}</p>
                                )
                            })}
                            {this.props.chequeDepositViewer.allChequeDeposit.edges.map((cheque_deposit, indexCheque) =>
                                <React.Fragment key={cheque_deposit.node.id}>
                                    {indexCheque === 0 && <p>เช็คธนาคาร/Cheque Bank</p>}
                                    <p key={cheque_deposit.node.id}>-
                                        {i18next.t("TransactionChannel:Bank Check Number")}&nbsp;&nbsp;{cheque_deposit.node.chequeNumber}
                                        &emsp;{cheque_deposit.node.bankName}
                                        &emsp;{i18next.t("TransactionChannel:Branch")}&nbsp;&nbsp;{cheque_deposit.node.branchNumber}
                                        &emsp;{i18next.t("TransactionChannel:Date")}&nbsp;&nbsp;{format(cheque_deposit.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                        &emsp;{i18next.t("TransactionChannel:Amount")}&nbsp;&nbsp;{numberWithComma(cheque_deposit.node.price)} {i18next.t("TransactionChannel:THB")}</p>

                                </React.Fragment>
                            )}

                            {this.props.allPaymentChannel.edges.map((pettycash, indexPettycash) =>
                                <React.Fragment key={pettycash.node.id}>
                                    {indexPettycash === 0 && <p>เงินสดย่อย/Petty Cash</p>}

                                    <p key={pettycash.node.id}>- {i18next.t("TransactionChannel:Number")}&nbsp;&nbsp;{pettycash?.node?.setPettyCash?.docNumber}
                                        &emsp;{i18next.t("TransactionChannel:Date")}&nbsp;&nbsp;{format(pettycash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                        &emsp;{i18next.t("TransactionChannel:Amount")}&nbsp;&nbsp;{numberWithComma(pettycash.node.price)} {i18next.t("TransactionChannel:THB")}</p>
                                </React.Fragment>
                               
                            )}
                            {this.props.allOtherExpensePaymentChannel.edges.map((othercash, index) => {
                                if(othercash.node?.setPettyCash===null && othercash.node?.rdCash===null) {}
                                else if(othercash.node?.setPettyCash===null)
                                {
                                    return(
                                        <React.Fragment key={othercash.node.id}>
                                            <p>รายรับล่วงหน้า /Receipt Deposit Cash</p>
                                            <p key={othercash.node.id}>- {i18next.t("TransactionChannel:Number")}&nbsp;&nbsp;{othercash.node.rdCash.docNumber}
                                                &emsp;{i18next.t("TransactionChannel:Date")}&nbsp;&nbsp;{format(othercash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                &emsp;{i18next.t("TransactionChannel:Amount")}&nbsp;&nbsp;{numberWithComma(othercash.node.price)} {i18next.t("TransactionChannel:THB")}</p>
                                        </React.Fragment>
                                    )
                                }else{
                                    return(
                                        <React.Fragment key={othercash.node.id}>
                                            <p>เงินสดย่อย/Petty Cash</p>
                                            <p key={othercash.node.id}>- {i18next.t("TransactionChannel:Number")}&nbsp;&nbsp;{othercash.node.setPettyCash.docNumber}
                                                &emsp;{i18next.t("TransactionChannel:Date")}&nbsp;&nbsp;{format(othercash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                &emsp;{i18next.t("TransactionChannel:Amount")}&nbsp;&nbsp;{numberWithComma(othercash.node.price)} {i18next.t("TransactionChannel:THB")}</p>
                                        </React.Fragment>
                                    )
                                }                                                        
                            })}
                            {this.props.allAdvancePettyCashChannel.edges.map((pettycash , index_pettycash) =>
                                <React.Fragment key={pettycash.node.id}>
                                    {index_pettycash === 0 && <p>เงินสดย่อย/Petty Cash</p>}
                                    <p key={pettycash.node.id}>- เลขที่&nbsp;&nbsp;{pettycash.node.setPettyCash.docNumber}
                                        &emsp;วันที่&nbsp;&nbsp;{format(pettycash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                        &emsp;จำนวนเงิน&nbsp;&nbsp;{numberWithComma(pettycash.node.price)} บาท</p>
                                </React.Fragment>
                            )}
                            {this.props.allClearGuaranteeMoneyReceivedPettyCashChannel.edges.map((pettycash , index_pettycash) =>
                                <React.Fragment key={pettycash.node.id}>
                                    {index_pettycash === 0 && <p>เงินสดย่อย/Petty Cash</p>}
                                    <p key={pettycash.node.id}>- เลขที่&nbsp;&nbsp;{pettycash.node.setPettyCash.docNumber}
                                        &emsp;วันที่&nbsp;&nbsp;{format(pettycash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                        &emsp;จำนวนเงิน&nbsp;&nbsp;{numberWithComma(pettycash.node.price)} บาท</p>
                                </React.Fragment>
                            )}
                            {this.props.allClearAdvancePettyCashChannel.edges.map((pettycash , index_pettycash) =>
                                <React.Fragment key={pettycash.node.id}>
                                    {index_pettycash === 0 && <p>เงินสดย่อย/Petty Cash</p>}
                                    <p key={pettycash.node.id}>- เลขที่&nbsp;&nbsp;{pettycash.node.setPettyCash.docNumber}
                                        &emsp;วันที่&nbsp;&nbsp;{format(pettycash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                        &emsp;จำนวนเงิน&nbsp;&nbsp;{numberWithComma(pettycash.node.price)} บาท</p>
                                </React.Fragment>
                            )}
                            {this.props.chequeTransactionViewer.allChequeTransaction.edges.map((cheque_transaction, indexChequeBank) => {
                                if (this.validatePaymentPettyCash(cheque_transaction.node.price)) {
                                    return ""
                                }
                                return (
                                    <React.Fragment key={cheque_transaction.node.id}>
                                        {indexChequeBank === 0 && <p>เช็คธนาคาร/Cheque Bank</p>}
                                        <p key={cheque_transaction.node.id}>-
                                            {i18next.t("TransactionChannel:Check Number")}&nbsp;&nbsp;{cheque_transaction.node.chequeNumber}
                                            &emsp;{i18next.t("TransactionChannel:Bank")}&nbsp;&nbsp;{cheque_transaction.node.bankAccount.bankName}
                                            &emsp;{i18next.t("TransactionChannel:Branch")}&nbsp;&nbsp;{cheque_transaction.node.bankAccount.branch}
                                            &emsp;{i18next.t("TransactionChannel:Date")}&nbsp;&nbsp;{format(cheque_transaction.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                            &emsp;{i18next.t("TransactionChannel:Amount")}&nbsp;&nbsp;{numberWithComma(cheque_transaction.node.price)} {i18next.t("TransactionChannel:THB")}</p>

                                    </React.Fragment>
                                )
                            }
                            )}
                            {this.props.allBankAccountTransaction.edges.map((bank_transaction, indexBankTrans) => {
                                if (this.validatePaymentPettyCash(bank_transaction.node.price)) {
                                    return ""
                                }
                                return (
                                    <React.Fragment key={bank_transaction.node.id}>
                                        {indexBankTrans === 0 && <p>เงินโอน/Bank Transfer {bank_transaction.node.description}</p>}

                                        <p>-
                                            {i18next.t("TransactionChannel:Bank")}&nbsp;&nbsp;{bank_transaction.node.bankAccount.bankName}
                                            &emsp; {i18next.t("TransactionChannel:Account Number")}&nbsp;&nbsp;{bank_transaction.node.bankAccount.accountNumber}
                                            &emsp;{i18next.t("TransactionChannel:Date")}&nbsp;&nbsp;{format(bank_transaction.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                            &emsp;{i18next.t("TransactionChannel:Amount")}&nbsp;&nbsp;{numberWithComma(bank_transaction.node.price)} {i18next.t("TransactionChannel:THB")}</p>
                                    </React.Fragment>
                                )
                            }
                            )}
                            {this.props.allCreditCardTransaction.edges.map((credit_card) =>
                                <p key={credit_card.node.id}>บัตรเครดิต/Credit
                                    Card {numberWithComma(credit_card.node.amount)} {i18next.t("TransactionChannel:THB")}</p>
                            )}

                            {/* เพย์เมนท์เกตเวย์ */}
                            {
                                this.props.allPaymentGatewayMethod.edges.map((payment_gateway) => (
                                    <React.Fragment key={payment_gateway.node.id}>
                                        <p>{i18next.t("TransactionChannel:Payment Gateway")}</p>
                                        <p>{i18next.t("TransactionChannel:Date") + format(payment_gateway.node.depositDate, "DD/MM/YYYY")}</p>
                                        <p>-&emsp;
                                            {paymentGatewayText(payment_gateway.node.paymentType)}&nbsp;&nbsp;{numberWithComma(payment_gateway.node.amount)}
                                        </p>
                                        {payment_gateway.node.feeAmount > 0 &&
                                            <p>-&emsp;
                                                {i18n.t('paymentGateway:Payment Fee')}&nbsp;&nbsp;{numberWithComma(payment_gateway.node.feeAmount)}
                                            </p>
                                        }
                                    </React.Fragment>
                                ))
                            }


                            {this.props.allAccountRecordGroup &&
                                <React.Fragment>
                                    {
                                        this.props.allAccountRecordGroup.edges.map((Other) => (
                                            Other.node.accountRecord.edges.map((accountRecord) => {
                                                if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                                                    accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-14" ||
                                                    accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07" ||
                                                    accountRecord.node.chartOfAccountCode.chartOfAccountCode === "2125-03" ||
                                                    this.props.allAccountRecordGroup.edges[0]?.node.category !== 'RECEIVE' && accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("2210") ||
                                                    this.props.allAccountRecordGroup.edges[0]?.node.category !== 'RECEIVE' && accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("5830") ){
                                                    let name = accountRecord.node.chartOfAccountCode.name 

                                                    if(this.props.allOtherExpensePaymentChannel.edges[0]?.node.rdCash===null)
                                                    {
                                                        if (name === "รายรับ - อื่น ๆ") {
                                                            name = name.replace("รายรับ - อื่น ๆ", i18next.t("TransactionChannel:Fractional Difference Income"))
                                                        }

                                                        if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" && accountRecord.node.debit > 0) {
                                                            name = name.replace("รายรับ", i18next.t("TransactionChannel:Expense"))
                                                        }
                                                        return (
                                                            <p key={accountRecord.node.id}>{name} {numberWithComma(accountRecord.node.credit > 0 ?
                                                                accountRecord.node.credit : accountRecord.node.debit)} {i18next.t("TransactionChannel:THB")}</p>
                                                        )
                                                    }
                                                    else { 
                                                        return null; 
                                                    }
                                                }
                                                else {
                                                    return null;
                                                }
                                            })

                                        )
                                        )
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TransactionChannel;