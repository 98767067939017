import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

// company
async function getCompanySetting(){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/setting_security_system`,{headers: header});
}
async function saveCompanySetting(body){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}vms/setting_security_system`,body ,{headers: header});
}


//Camera
async function getDataLPR () {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/setting_license_plate_recognition` ,{headers: header});
}

async function saveLPR(body) {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}vms/setting_license_plate_recognition`, body ,{headers: header});
}

// Account
async function getAccountSettingPacking(){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/setting_parking_fee_account` ,{headers: header});
}


async function getAccount(){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/all_chart_of_account` ,{headers: header});
}
async function saveAccountSettingPacking(body){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}vms/setting_parking_fee_account`,body ,{headers: header});
}

// Estamp
async function getEstampSetting(){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/setting_parking_fee_estamp`,{headers: header});
}
async function saveEstampSetting(body){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}vms/setting_parking_fee_estamp`,body ,{headers: header});
}


// caluletePacking
async function getcaluletePackingSetting(){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/setting_parking_fee_calculate`,{headers: header});
}
async function savecaluletePackingSetting(body){
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}vms/setting_parking_fee_calculate`,body ,{headers: header});
}


export default {
    getDataLPR,
    saveLPR,
    getAccountSettingPacking,
    getAccount,
    saveAccountSettingPacking,
    getEstampSetting,
    saveEstampSetting,
    getcaluletePackingSetting,
    savecaluletePackingSetting,
    getCompanySetting,
    saveCompanySetting
}