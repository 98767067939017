import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import Navigation from "./navigation";

import {Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";

import ReceiptDepositListTable from "./receiptDepositListTable"
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import _ from "lodash";

const allReceiptDeposit = graphql`
    query receiptDepositListQuery($status: String!, $search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int) {
        receiptDepositViewer {
            allReceiptDeposit(status:$status, search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last) {
                totalCount
                edges {
                    node {
                        id
                        docNumber
                        firstName
                        lastName
                        address district city province postalCode
                        payGroup
                        contact {
                            id
                            name
                            refNumber
                            firstName
                            lastName
                            typeOfContact
                        }
                        issuedDate
                        added
                        total
                        status
                        voidRemark
                        updated
                        creator
                        receiptDepositTransaction {
                            edges {
                                node {
                                    id
                                    description
                                    price
                                    balance
                                }
                            }
                        }
                    }
                }
            }

            
        }
    }
`;

class ReceiptDepositList extends Component {

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="receive-list">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('receiptDeposit:receipt_deposit')+ " "+"(RD)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_receipt_deposit_create'}) &&
                                <Link to="/accounting/income/receipt_deposit/form/select_customer">
                                    <button type="button" className="btn btn-primary add float-right">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('receiptDeposit:add_receipt_deposit')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                                }
                            </div>
                        </div>
                        <div className="content-inner">
                            <Navigation/>
                            <ReceiptDepositListTable query={allReceiptDeposit} status={this.props.match.params.status}/>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ReceiptDepositList;