import React, { Component } from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import WrapperContent from '../../../../components/wrapper/wrapperContent';
import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import i18n from "i18next";
import '../../style/claim.scss'
import APIInsur from '../../../../api/insurrance'
import _ from 'lodash';

class EditInsuranceBroker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            co_insurance: [],
            file_insurance: [],
            data: null,
            insurance_start_date: "",
            insurance_end_date: ""
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChageData = this.onChageData.bind(this)
        this.addListInsur = this.addListInsur.bind(this)
        this.SeleteCompanyName = this.SeleteCompanyName.bind(this)
        this.co_insuranceUpdate = this.co_insuranceUpdate.bind(this)
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            APIInsur.getDataInsuranceById(this.props.match.params.id).then((res) => {
                if (res.data) {
                    this.setState({
                        data: res.data?.insurance.insurance_detail,
                        file_insurance: res.data?.insurance.file_insurance,
                        co_insurance: res.data?.insurance.co_insurance,
                        loading: false
                    })
                } else {
                    Swal.fire("ผิดพลาด", "ส่งข้อมูลผิดพลาด", 'warning')
                }
            }, () => {
                Swal.fire("ผิดพลาด", "ไม่สามารถเชื่อมต่อหลังบ้านได้", 'error')
            })
        }
    }

    SeleteCompanyName(e, index, main) {

        let data = _.cloneDeep(this.state.data)
        let NewData = _.set(data, `main_insurance_company.id`, e)
        this.setState({ data: NewData })
    }

    co_insuranceUpdate(data, index, e) {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        _.set(co_insurance, `[${index}].insurance_ratio`, e.target.value)

        this.setState({ co_insurance: co_insurance })

    }

    addListInsur() {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        let co_insurancechk = co_insurance.filter((n) => n.name !== "")

        if (co_insurancechk.length >= 0) {
            co_insurance.push({ co_insurance_company_id: "", name: "", insurance_ratio: 0 })
            this.setState({ co_insurance: co_insurance })
        }

    }

    onChageData(e) {
        let data = _.cloneDeep(this.state.data)
        let NewData = _.set(data, `${e.target.name}`, e.target.value)
        this.setState({ data: NewData })
    }


    onSubmit(e) {
        e.preventDefault();
    

        let {
            broker_first_name,
            broker_last_name,
            broker_company,
            broker_company_address,
            broker_tax_number,
            broker_tel,
            broker_email
        } = this.state.data;

        let data = {
            "broker_first_name": broker_first_name,
            "broker_last_name": broker_last_name,
            "broker_company": broker_company,
            "broker_company_address": broker_company_address,
            "broker_tax_number": broker_tax_number,
            "broker_tel": broker_tel,
            "broker_email": broker_email,
            co_insurance: this.state.co_insurance,
            file_insurance: this.state.file_insurance
        }

        APIInsur.updateDataInsurance(this.props.match.params.id, data).then((res) => {
            if (res.data?.statusCode === "0000") {
                Swal.fire({
                    type: 'success',
                    title: i18n.t("Allaction:successcompleate"),
                }).then(() => {
                    this.props.history.push(`/purchasing/insurrance/insur-detail/${this.props.match.params.id}/Broker`)
                })
            }
        })
    }


    render() {
        let { data, loading, file_insurance } = this.state

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            loading == false ?
                                <div className="container-fluid box claim" id="staff-create">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/purchasing/insurrance">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t('claim:Insurance')}</span>
                                            </h3>
                                        </div>
                                    </div>


                                    <div className="content-inner mt-5 create">
                                        <div className="row mb-12 mr-5 fade-up ">
                                            <div className='mx-auto' style={{ width: 744 }}>

                                                <React.Fragment>
                                                    <form onSubmit={this.onSubmit}>
                                                        <div className='onSubmit'>

                                                            <div className='col-12'>
                                                                <label className='headerLabel'>{t("claim:insurance broker")}</label>
                                                            </div>

                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div style={{ display: 'inline-flex' }}>
                                                                    <div style={{ marginRight: 32 }}>
                                                                        <label className='label16'>{t("claim:FirsthName")}</label>
                                                                        <input type={"text"} name='broker_first_name'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:FirsthName")}
                                                                            className='form-control inputBox' style={{ width: 332 }}
                                                                            defaultValue={this.state.data.broker_first_name} />
                                                                    </div>

                                                                    <div>
                                                                        <label className='label16'>{t("claim:LastName")}</label>
                                                                        <input type={"text"} name='broker_last_name'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:LastName")}
                                                                            className='form-control inputBox' style={{ width: 332 }}
                                                                            defaultValue={this.state.data.broker_last_name} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* insurance_company_address */}

                                                            <div className='col-12 mt-2 mb-1'>
                                                                <label className='label16 mt-5'>{t("claim:CompanyAddress")}</label>
                                                                <textarea
                                                                    onChange={this.onChageData}
                                                                    name='broker_company_address'
                                                                    className='form-control textareaBox'
                                                                    placeholder={t("claim:CompanyAddress")}
                                                                    defaultValue={this.state.data.broker_company_address}
                                                                    style={{ resize: 'none' }}
                                                                />
                                                            </div>


                                                            {/* main_insurance_company_tax_number */}
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:TaxId")}</label>
                                                                    </div>

                                                                    <div>
                                                                        <input type={"text"} name='broker_tax_number'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:TaxId")}
                                                                            maxLength={13} minLength={13}
                                                                            pattern='[0-9]{13}'
                                                                            onInvalid={e => e.target.setCustomValidity('โปรดกรอกตะวเลข 13 หลักเท่านั้น')}
                                                                            onInput={e => e.target.setCustomValidity('')}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            defaultValue={this.state.data.broker_tax_number} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Phone */}
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <img
                                                                            className='imgIcon'
                                                                            src={process.env.PUBLIC_URL + '/images/iconAction/claim/phone.png'} />
                                                                        <label className='headerLabel18'>{t("claim:Phone")}</label>
                                                                    </div>
                                                                    <div>
                                                                        {/* <span>{t("claim:bath")}</span> */}
                                                                        <input type={"text"} name='broker_tel'
                                                                            onChange={this.onChageData}
                                                                            pattern='[0-9]+$'
                                                                            onInvalid={e => e.target.setCustomValidity('โปรดกรอกตัวเลขเท่านั้น')}
                                                                            onInput={e => e.target.setCustomValidity('')}
                                                                            placeholder={t("claim:Phone")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            defaultValue={this.state.data.broker_tel} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Email */}
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <img
                                                                            className='imgIcon'
                                                                            src={process.env.PUBLIC_URL + '/images/iconAction/claim/mail.png'} />
                                                                        <label className='headerLabel18'>{t("claim:Email")}</label>
                                                                    </div>
                                                                    <div>
                                                                        {/* <span>{t("claim:bath")}</span> */}
                                                                        <input type={"email"} name='main_insurance_company_email'
                                                                            onChange={this.onChageData}
                                                                            onInvalid={e => e.target.setCustomValidity('โปรดกรอกเป็นลิงค์ Email ให้ถูกต้อง')}
                                                                            onInput={e => e.target.setCustomValidity('')}
                                                                            placeholder={t("claim:Email")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            defaultValue={this.state.data.broker_email} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className='col-11 text-right mt-10 pr-1'>
                                                            <Link to={`/purchasing/insurrance/insur-detail/${this.props.match.params.id}/Broker`}>
                                                                <button type='button' className='btn-outline-blue' style={{ width: 128, height: 48 }}>{t("Allaction:cancel")}</button>
                                                            </Link>

                                                            <button type='submit' className='btn-blue-primary' style={{ width: 128, height: 48 }}>{t("Allaction:save")}</button>
                                                        </div>
                                                    </form>
                                                </React.Fragment>




                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container-fluid box fixRequestFast text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default withRouter(EditInsuranceBroker);
