/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractExpenseStatus = "IN_PROGRESS" | "NEAR_END" | "NOT_RENEW" | "RENEW" | "VOID" | "WAIT" | "%future added value";
export type PurchaseOrderesStatus = "APPROVE" | "VOID" | "WAIT" | "%future added value";
export type contractExpenseTableQueryVariables = {|
  status?: ?string,
  search?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type contractExpenseTableQueryResponse = {|
  +allContractExpense: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +status: ContractExpenseStatus,
        +issuedDate: any,
        +renewer: string,
        +renewDate: ?any,
        +voidRemark: string,
        +voidDate: ?any,
        +voider: ?string,
        +added: any,
        +purchaseOrderes: {|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +status: PurchaseOrderesStatus,
          +approver: ?string,
          +procurementForm: {|
            +id: string,
            +docNumber: string,
            +issuedDate: any,
            +creditor: {|
              +refNumber: string,
              +name: string,
            |},
            +chartOfAccount: ?{|
              +id: string,
              +name: string,
              +nameEn: ?string,
              +chartOfAccountCode: string,
            |},
            +startContractDate: ?any,
            +endContractDate: ?any,
            +total: ?number,
          |},
        |},
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type contractExpenseTableQuery = {|
  variables: contractExpenseTableQueryVariables,
  response: contractExpenseTableQueryResponse,
|};
*/


/*
query contractExpenseTableQuery(
  $status: String
  $search: String
  $startDate: Date
  $endDate: Date
  $first: Int
  $last: Int
) {
  allContractExpense(status: $status, search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {
    edges {
      node {
        id
        docNumber
        status
        issuedDate
        renewer
        renewDate
        voidRemark
        voidDate
        voider
        added
        purchaseOrderes {
          id
          docNumber
          issuedDate
          status
          approver
          procurementForm {
            id
            docNumber
            issuedDate
            creditor {
              refNumber
              name
              id
            }
            chartOfAccount {
              id
              name
              nameEn
              chartOfAccountCode
            }
            startContractDate
            endContractDate
            total
          }
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renewer",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renewDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voider",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approver",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v19/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chartOfAccountCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startContractDate",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endContractDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "contractExpenseTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ContractExpenseNodeConnection",
        "kind": "LinkedField",
        "name": "allContractExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractExpenseNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractExpenseNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PurchaseOrderesNode",
                    "kind": "LinkedField",
                    "name": "purchaseOrderes",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v9/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProcurementFormNode",
                        "kind": "LinkedField",
                        "name": "procurementForm",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContactNode",
                            "kind": "LinkedField",
                            "name": "creditor",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              (v19/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v24/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "contractExpenseTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ContractExpenseNodeConnection",
        "kind": "LinkedField",
        "name": "allContractExpense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractExpenseNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractExpenseNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PurchaseOrderesNode",
                    "kind": "LinkedField",
                    "name": "purchaseOrderes",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v9/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProcurementFormNode",
                        "kind": "LinkedField",
                        "name": "procurementForm",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContactNode",
                            "kind": "LinkedField",
                            "name": "creditor",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v24/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f1de34bd92277d7acb802eb8b05cc2d",
    "id": null,
    "metadata": {},
    "name": "contractExpenseTableQuery",
    "operationKind": "query",
    "text": "query contractExpenseTableQuery(\n  $status: String\n  $search: String\n  $startDate: Date\n  $endDate: Date\n  $first: Int\n  $last: Int\n) {\n  allContractExpense(status: $status, search: $search, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {\n    edges {\n      node {\n        id\n        docNumber\n        status\n        issuedDate\n        renewer\n        renewDate\n        voidRemark\n        voidDate\n        voider\n        added\n        purchaseOrderes {\n          id\n          docNumber\n          issuedDate\n          status\n          approver\n          procurementForm {\n            id\n            docNumber\n            issuedDate\n            creditor {\n              refNumber\n              name\n              id\n            }\n            chartOfAccount {\n              id\n              name\n              nameEn\n              chartOfAccountCode\n            }\n            startContractDate\n            endContractDate\n            total\n          }\n        }\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75a183e2e897f118cdf95aac84cab32c';

module.exports = node;
