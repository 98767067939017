import React, {Component} from 'react';
import './css/PurchaseCreateDetailContact.scss'
import {graphql} from "babel-plugin-relay/macro";
import {QueryRenderer} from "react-relay";
import environment from "../../../../env/environment";
import i18next from 'i18next';

const $ = window.jQuery;
const allContactCreateInvoice = graphql`
    query PurchaseCreateDetailContactQuery($list_contact: [String]){
        contactViewer {
            id
            allContact(listContact: $list_contact){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

class PurchaseCreateDetailContact extends Component {

    constructor(props) {
        super(props);
        this.onClickOpenModal = this.onClickOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal');
    }

    onClickOpenModal() {
        $(this.modal).modal('show');

    }

    onCloseModal() {
        $(this.modal).modal('hide');

    }

    render() {
        return (
            <React.Fragment>
                <div id="invoice_detail_contact">

                    <div className="input-group mb-3">
                        <input type="text" className="form-control inputDetailContactLeft" value={i18next.t("AgentRole:Multi-room")}
                               disabled/>
                        <div className="input-group-append inputDetailContactRight">
                            <span className="input-group-text" id="basic-addon2"><u onClick={this.onClickOpenModal}
                                                                                    className="textDetail">ดูข้อมูล</u></span>
                        </div>
                    </div>

                    <div className="modal fade bd-example-modal-lg" id="modal_create_product" role="dialog"
                         aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal => this.modal = modal}>
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="col" style={{paddingLeft: 0}}>
                                        <h5 className="modal-title">
                                            ข้อมูลลูกค้า
                                        </h5>
                                    </div>

                                    <button type="button" className="close" onClick={this.onCloseModal}
                                            aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="col-12">
                                        <div className="table-responsive fade-up">
                                            <table className="table table-hover mt-2">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th>รหัสลูกค้า</th>
                                                    <th>เลขที่ห้อง/บ้านเลขที่/บริษัท</th>
                                                    {/*อันนี้ให้เช็คด้วยว่า type ที่กดเข้ามาเป็นแบบไหนแล้วโชว์แบบนั้น*/}
                                                    <th>ชื่อ</th>
                                                    <th>นามสกุล</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                <QueryRenderer
                                                    environment={environment}
                                                    query={allContactCreateInvoice}
                                                    variables={{list_contact: this.props.Contact}}
                                                    cacheConfig={{use_cache: false}}
                                                    render={({error, props}) => {
                                                        if (error) {
                                                            return <tr><td colSpan="4">{error.message}</td></tr>;
                                                        } else if (props) {
                                                            return props.contactViewer.allContact.edges.map((contact) => {
                                                                let first_name_list = contact.node.firstName.split(",");
                                                                let last_name_list = contact.node.lastName.split(",");

                                                                if (first_name_list.length !== 1) {
                                                                    return first_name_list.map((value, index) =>
                                                                        <tr key={'owner' + index}>
                                                                            <td>{index === 0 ? contact.node.refNumber : ''}</td>
                                                                            <td>{index === 0 ? contact.node.name : ''}</td>
                                                                            <td>{value}</td>
                                                                            <td>{last_name_list[index]}</td>
                                                                        </tr>
                                                                    )
                                                                }

                                                                return (
                                                                    <tr key={contact.node.id}>
                                                                        <td>{contact.node.refNumber}</td>
                                                                        <td>{contact.node.name}</td>
                                                                        <td>{first_name_list[0]}</td>
                                                                        <td>{last_name_list[0]}</td>
                                                                    </tr>
                                                                )

                                                            })
                                                        }
                                                        return <tr><td colSpan="4">Loading...</td></tr>;
                                                    }}
                                                />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default PurchaseCreateDetailContact
