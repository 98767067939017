import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import AcceptCreditNote from './acceptCreditNote';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperAcceptCreditNoteQuery($id: ID!){
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        acceptCreditNote(id: $id) {
            id
            docNumber
            issuedDate
            status
            contact {
              refNumber
              firstName
              lastName
              name
              taxIdNumber
              registeredName
              registeredAddress
              registeredCountry
              registeredProvince
              registeredCity
              registeredDistrict
              registeredPostalCode
              typeOfContact
            }
            purchaseRecord {
              id
              total
              productAndService {
                productCode
                name
                chartOfAccount {
                  chartOfAccountCode
                  name
                }
              }
              chartOfAccount {
                name
                chartOfAccountCode
              }
              purchaseRecordGroup {
                docNumber
              }
            }
            reason
            description
            price
            vat
            vatAmount
            total
            remark
            acceptCreditNoteNumber
            taxInvoiceDate
            taxInvoiceNumber
            taxInvoicePeriod
            refAcceptCreditNoteDoc
            refAcceptCreditNoteNumber
          }
          userConfig {
            id languages
        }
    }
`;

class WrapperAcceptCreditNote extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    print() {
        window.print();
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>AcceptCreditNote</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{
                        id: this.props.match.params.id,
                    }}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return <AcceptCreditNote 
                                query={props}
                                languages={props.userConfig.languages}                                    
                            />
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperAcceptCreditNote;
