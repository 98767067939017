import i18next from 'i18next';
import React, { Component } from 'react';
import numberWithComma from "../../../libs/numberWithComma";
class PurchaseDetailTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Modal Use
            runNoType: "",
            item_select: [],
            index_select: 0,
        };

    }
    render() {
        return (
            <React.Fragment>
                <div className="table-responsive fade-up">
                    <table className="table table-hover mt-2">
                        <thead className="thead-light">
                            <tr>
                                <th>{i18next.t("invoiceDetail:Products/Services")}</th>
                                <th>{i18next.t("invoiceDetail:Account")}</th>
                                <th>{i18next.t("invoiceDetail:Description")}</th>
                                <th className="text-center">{i18next.t("invoiceDetail:Amount")}</th>
                                <th className="text-right">{i18next.t("invoiceDetail:Price")}</th>
                                {/*<th className="text-right">ส่วนลด</th>*/}
                                <th className="text-right">{i18next.t("invoiceDetail:Tax")}</th>
                                <th className="text-right">{i18next.t("receipt_deposit:Tax amount")}</th>
                                {/* <th /> */}
                                {/*<th className="text-right">ยอดก่อนภาษี</th>*/}
                                {/* <th className="text-right">หัก ณ จ่าย</th> */}
                            </tr>
                        </thead>
                        {
                            this.props.purchase_group.purchaseRecord.edges.map((transactions, index) => (
                                <tbody key={transactions.node.id}>
                                    <tr>
                                        <td>{transactions.node.productAndService ? transactions.node.productAndService.productCode +" "+transactions.node.productAndService.name : transactions.node.chartOfAccount.name}</td>
                                        <td>{transactions.node.chartOfAccount.chartOfAccountCode}</td>
                                        <td>{transactions.node.description}</td>
                                        <td className="text-center">{transactions.node.unitItems}</td>
                                        <td className="text-right">{numberWithComma(transactions.node.price)}</td>
                                        {/*<td className="text-right">{numberWithComma(transactions.node.discount)}</td>*/}

                                        {/* % VAT */}
                                        {
                                            transactions.node.vat === 0 ?
                                                <td className="text-right">0%</td>
                                                :
                                                transactions.node.vat === 7 ?
                                                    <td className="text-right">7%</td>
                                                    :
                                                    <td className="text-right">{i18next.t("receipt_deposit:None")}</td>
                                        }

                                        {/* จำนวน VAT */}
                                        {
                                            transactions.node.vat === 0 ?
                                                <td className="text-right">0</td>
                                                :
                                                transactions.node.vat === 7 ?
                                                    <td className="text-right">{numberWithComma(transactions.node.vatAmount)}</td>
                                                    :
                                                    <td className="text-right">{i18next.t("receipt_deposit:None")}</td>
                                        }
                                        {transactions.node.productAndService?.noNumber === true && transactions.node.stockPurchaseRecord?.edges.length > 0 && <td className="text-right">
                                            <span data-toggle="modal" data-target="#run-no-label" className="link float-right" onClick={() => { this.setState({ item_select: transactions.node, index_select: index }) }} >
                                                <React.Fragment>
                                                    <span>
                                                    {i18next.t("stockSelectRoom:Inventory")}
                                                    </span>
                                                </React.Fragment>
                                            </span>
                                        </td>}
                                        {/*<td className="text-right">{transactions.node.preTaxAmount}</td>*/}
                                        {/* <td className="text-right">{transactions.node.whtRate ? transactions.node.whtRate.toFixed(2) : '-'}</td> */}
                                    </tr>
                                </tbody>
                            ))
                        }
                    </table>
                </div>
                {/* modal */}
                <div ref={modal => this.modal = modal} data-backdrop="static" data-keyboard="false"
                    className="modal fade" id="run-no-label" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            {/* modal-header */}
                            <div className="modal-header">
                                <div className="modal-title" id="run-no-label">
                                    <h3>
                                    {i18next.t("purchaseDetail:Product Number")}
                                    </h3>
                                </div>
                            </div>
                            {/* modal-body */}
                            <div className="modal-body">
                                {this.state.item_select.stockPurchaseRecord?.edges.length > 0 &&
                                    <React.Fragment>
                                        <div className="mt-3 table-responsive card">
                                            <table className="table">
                                                <thead>
                                                    <tr className="bg-light">
                                                        <th className="text-center">{i18next.t("stockSelectRoom:No.")}</th>
                                                        <th className="text-left">Run No.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.item_select.stockPurchaseRecord.edges.map((e, index_item_select) => {
                                    
                                                        let num_row = index_item_select + 1
                                                        return (
                                                            <tr key={"resident." + index_item_select}>
                                                                <td className="text-center">{num_row}</td>
                                                                <td className="text-left">
                                                                    <input type="text" className="form-control text-left"
                                                                        name={"unitNameproduct[" + index_item_select + "]"}
                                                                        value={e.node.stockName}
                                                                        disabled
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    )
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                }
                                <div className="mt-3 float-right">
                                    <button type="button" className="btn btn-secondary mr-1" data-dismiss="modal">{i18next.t("Allaction:Close")}</button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PurchaseDetailTable;