/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReceiveCollectionLawFirmLawyerApproval = "APPROVAL" | "OTHER" | "UNAPPROVAL" | "WAIT" | "%future added value";
export type ReceiveCollectionLawFirmRecordStatus = "PAID" | "UNACCEPT" | "VOID" | "WAIT" | "%future added value";
export type ReceiveCollectionLawFirmRecordType = "FEE" | "OTHER" | "%future added value";
export type ReceiveCollectionLawFirmStatus = "CANCEL_SOME_PORTIONS" | "PAID" | "PAID_SOME_PORTIONS" | "UNACCEPT" | "VOID" | "WAIT" | "%future added value";
export type ReceiveCollectionLawFirmType = "FEE" | "OTHER" | "%future added value";
export type ReceiveCollectionLawFirmTypePaid = "BANK" | "CASH" | "OTHER" | "PAYMENT_GATEWAY_KHECH" | "QRCODE" | "%future added value";
export type settingDocumentPaidTableQueryVariables = {|
  id?: ?string,
  status?: ?string,
  first?: ?number,
  last?: ?number,
  search?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  siteId?: ?string,
|};
export type settingDocumentPaidTableQueryResponse = {|
  +silvermanReceiveCollectionLawFirm: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: ?any,
        +status: ReceiveCollectionLawFirmStatus,
        +type: ReceiveCollectionLawFirmType,
        +total: number,
        +paid: number,
        +typePaid: ReceiveCollectionLawFirmTypePaid,
        +lawyerApproval: ReceiveCollectionLawFirmLawyerApproval,
        +fileName: ?string,
        +fileUpload: ?string,
        +billingName: ?string,
        +billingUpload: ?string,
        +receiptName: ?string,
        +receiptUpload: ?string,
        +language: string,
        +creator: ?string,
        +creatorDelete: ?string,
        +creatorApproval: ?string,
        +receiveCollectionLawFirmRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +status: ReceiveCollectionLawFirmRecordStatus,
              +type: ReceiveCollectionLawFirmRecordType,
              +total: number,
              +paid: number,
              +creator: ?string,
              +collectionLawFirm: {|
                +id: string,
                +docNumber: string,
                +totalDebt: number,
                +totalFine: number,
                +contact: {|
                  +id: string,
                  +refNumber: string,
                  +name: string,
                  +firstName: string,
                  +lastName: string,
                |},
                +purchaseRecordGroupLawFirm: ?{|
                  +totalCount: ?number,
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +purchaseRecordGroup: {|
                        +id: string,
                        +docNumber: string,
                      |}
                    |}
                  |}>,
                |},
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type settingDocumentPaidTableQuery = {|
  variables: settingDocumentPaidTableQueryVariables,
  response: settingDocumentPaidTableQueryResponse,
|};
*/


/*
query settingDocumentPaidTableQuery(
  $id: ID
  $status: String
  $first: Int
  $last: Int
  $search: String
  $startDate: DateTime
  $endDate: DateTime
  $siteId: ID
) {
  silvermanReceiveCollectionLawFirm(id: $id, status: $status, first: $first, last: $last, search: $search, startDate: $startDate, endDate: $endDate, siteId: $siteId) {
    edges {
      node {
        id
        docNumber
        issuedDate
        status
        type
        total
        paid
        typePaid
        lawyerApproval
        fileName
        fileUpload
        billingName
        billingUpload
        receiptName
        receiptUpload
        language
        creator
        creatorDelete
        creatorApproval
        receiveCollectionLawFirmRecord {
          edges {
            node {
              id
              status
              type
              total
              paid
              creator
              collectionLawFirm {
                id
                docNumber
                totalDebt
                totalFine
                contact {
                  id
                  refNumber
                  name
                  firstName
                  lastName
                }
                purchaseRecordGroupLawFirm {
                  totalCount
                  edges {
                    node {
                      purchaseRecordGroup {
                        id
                        docNumber
                      }
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "siteId",
    "variableName": "siteId"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typePaid",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lawyerApproval",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUpload",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingUpload",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiptName",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiptUpload",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorDelete",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorApproval",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDebt",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalFine",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "PurchaseRecordGroupNode",
  "kind": "LinkedField",
  "name": "purchaseRecordGroup",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingDocumentPaidTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ReceiveCollectionLawFirmNodeConnection",
        "kind": "LinkedField",
        "name": "silvermanReceiveCollectionLawFirm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReceiveCollectionLawFirmNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveCollectionLawFirmNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveCollectionLawFirmRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "receiveCollectionLawFirmRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveCollectionLawFirmRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReceiveCollectionLawFirmRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v25/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CollectionLawFirmNode",
                                "kind": "LinkedField",
                                "name": "collectionLawFirm",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v28/*: any*/),
                                  (v29/*: any*/),
                                  (v30/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PurchaseRecordGroupLawFirmNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "purchaseRecordGroupLawFirm",
                                    "plural": false,
                                    "selections": [
                                      (v31/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PurchaseRecordGroupLawFirmNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PurchaseRecordGroupLawFirmNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v32/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "settingDocumentPaidTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ReceiveCollectionLawFirmNodeConnection",
        "kind": "LinkedField",
        "name": "silvermanReceiveCollectionLawFirm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReceiveCollectionLawFirmNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveCollectionLawFirmNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveCollectionLawFirmRecordNodeConnection",
                    "kind": "LinkedField",
                    "name": "receiveCollectionLawFirmRecord",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveCollectionLawFirmRecordNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReceiveCollectionLawFirmRecordNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v25/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CollectionLawFirmNode",
                                "kind": "LinkedField",
                                "name": "collectionLawFirm",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v28/*: any*/),
                                  (v29/*: any*/),
                                  (v30/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PurchaseRecordGroupLawFirmNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "purchaseRecordGroupLawFirm",
                                    "plural": false,
                                    "selections": [
                                      (v31/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PurchaseRecordGroupLawFirmNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PurchaseRecordGroupLawFirmNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v32/*: any*/),
                                              (v9/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f59901916365b10acd8e881e8de4c6ce",
    "id": null,
    "metadata": {},
    "name": "settingDocumentPaidTableQuery",
    "operationKind": "query",
    "text": "query settingDocumentPaidTableQuery(\n  $id: ID\n  $status: String\n  $first: Int\n  $last: Int\n  $search: String\n  $startDate: DateTime\n  $endDate: DateTime\n  $siteId: ID\n) {\n  silvermanReceiveCollectionLawFirm(id: $id, status: $status, first: $first, last: $last, search: $search, startDate: $startDate, endDate: $endDate, siteId: $siteId) {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        status\n        type\n        total\n        paid\n        typePaid\n        lawyerApproval\n        fileName\n        fileUpload\n        billingName\n        billingUpload\n        receiptName\n        receiptUpload\n        language\n        creator\n        creatorDelete\n        creatorApproval\n        receiveCollectionLawFirmRecord {\n          edges {\n            node {\n              id\n              status\n              type\n              total\n              paid\n              creator\n              collectionLawFirm {\n                id\n                docNumber\n                totalDebt\n                totalFine\n                contact {\n                  id\n                  refNumber\n                  name\n                  firstName\n                  lastName\n                }\n                purchaseRecordGroupLawFirm {\n                  totalCount\n                  edges {\n                    node {\n                      purchaseRecordGroup {\n                        id\n                        docNumber\n                      }\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c5f9e9c58bf6c26f9fa6df3d25d6020';

module.exports = node;
