import React from "react";
import localStorage from "../../../../libs/localstorage";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import Link from "react-router-dom/es/Link";
import "../../report.scss";

class HistoryTable extends React.Component {

    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    render() {
        return (
            this.props.query.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                let order_id = this.props.state.first - localStorage.getPageLimit();
                return (
                    invoice.node.transaction.edges.map((transaction, t_index) => {
                        let row_number = (t_index === 0 && order_id + index + 1);
                        let balance = transaction.node.total;

                        let rt_cn_keep = [];
                        let rt_info = [];
                        let cn_info = [];
                        let cn_infoV2 = [];
                        let rd_info = [];
                        //order by date cn
                        rt_cn_keep = [];
                        transaction.node.creditNoteTransaction.edges.map((cn_transaction_id) => {
                            return rt_cn_keep.push(cn_transaction_id.node.issuedDate);
                        });
                        rt_cn_keep.sort()
                        rt_cn_keep.forEach((element) => {
                            transaction.node.creditNoteTransaction.edges.map((e,index) => {
                                if(element === e.node.issuedDate ){
                                    let check_cn = cn_info.find(en => en.node.receive?.id === e.node.receive?.id)
                                    if(!check_cn && e.node.status !== "VOID")cn_info.push(e)
                                }
                                return null
                            });
                        });

                        //order by date cn
                        rt_cn_keep = [];
                        transaction.node.creditNoteTransactionRecord.edges.map((cn_transaction_id) => {
                            return rt_cn_keep.push(cn_transaction_id.node.creditNote.issuedDate);
                        });
                        rt_cn_keep.sort()
                        rt_cn_keep.forEach((element) => {
                            transaction.node.creditNoteTransactionRecord.edges.map((e,index) => {
                                if(element === e.node.creditNote.issuedDate ){
                                    if(e.node.status !== "VOID")cn_infoV2.push(e)
                                }
                                return null
                            });
                        });
                        //order by date RT
                        transaction.node.receiveTransaction.edges.map((rt_transaction_id,index) => {
                            return rt_cn_keep.push(rt_transaction_id.node.receive.issuedDate);
                        });
                        rt_cn_keep.sort();
                        rt_cn_keep.forEach((element) => {
                            transaction.node.receiveTransaction.edges.map((e,index) => {
                                if(element === e.node.receive.issuedDate ){
                                    let check_rt = rt_info.find(en => en.node.receive?.id === e.node.receive?.id )
                                    if(!check_rt)rt_info.push(e)
                                }
                                return null
                            });
                        });
                        // check RT duplicate with CN 
                        rt_info.forEach(en => {
                            let check_cn = cn_info.find(cn => cn.node.receive && cn.node.receive.id === en.node.receive.id);
                            if (check_cn) {
                                rt_info.pop()
                            }
                        });

                        //order by date rd
                        transaction.node.receiptDepositTransactionUsed.edges.map((rd_transaction_id) => {
                            return rd_info.push(rd_transaction_id);
                        });
                        return (
                            <React.Fragment key={transaction.node.id}>
                                <tr>
                                    <td className="text-center">{row_number}</td>
                                    <td className="text-center">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                    <td className="text-center">
                                        <Link to={"/accounting/income/invoice/invoice-detail/"+invoice.node.id}
                                            target={"_blank"}>
                                            {invoice.node.docNumber}
                                        </Link>
                                    </td>
                                    <td>{
                                        this.removeTrailingZeros(transaction.node.description)
                                        }</td>
                                    <td className="text-right">{numberWithComma(transaction.node.total)}</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td className="text-right">{numberWithComma(balance)}</td>
                                </tr>
                                {
                                    rt_info.map((rt_transaction,index) => {
                                        let amount_total = rt_transaction.node.amount
                                        // let rd_total = 0
                                        //rd
                                        // rt_transaction.node.receiptDepositTransactionUsed.edges.map((rd) => {
                                        //     rd_total += rd.node.amount
                                        //     return amount_total +=  Math.abs(rd.node.amount)
                                        // })
                                        //cn
                                        rt_transaction.node.receive.creditNoteReceive.edges.map((cd) => {
                                            if(amount_total === cd.node.price) return;
                                            return amount_total
                                        })
                                        balance -= amount_total;
                                        if(balance < 0.00)balance=0.00;
                                        return (
                                            <tr key={rt_transaction.node.id}>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-center">
                                                    {format(rt_transaction.node.receive.issuedDate, 'DD/MM/YYYY')}
                                                </td>
                                                <td className="text-center">
                                                    <Link to={"/accounting/income/receive/detail/" + rt_transaction.node.receive.id}
                                                        target={'_blank'}>
                                                        {rt_transaction.node.receive.docNumber}
                                                    </Link>
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma((rt_transaction.node.amount),"0.00")}
                                                    {/* {numberWithComma((amount_total),"0.00")} */}
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-right">{numberWithComma(balance, "0.00")}</td>
                                            </tr>
                                        )
                                    })
                                }
                                
                                {
                                    cn_info.map((cn_transaction) => {
                                        balance -= cn_transaction.node.price;
                                        if(Math.round(balance) === -0){
                                            balance = 0
                                        }
                                        return (
                                            <tr key={cn_transaction.node.id} id="history">
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-center">{format(cn_transaction.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-center">
                                                    <Link to={"/accounting/income/credit-note/detail/" + cn_transaction.node.id}>
                                                        {cn_transaction.node.docNumber}
                                                    </Link>
                                                </td>
                                                <td className="text-right red">
                                                    ({numberWithComma(cn_transaction.node.price)})
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-right">{numberWithComma(balance, "0.00")}</td>
                                            </tr>
                                            )
                                        
                                    })
                                }

{
                                    cn_infoV2.map((cn_transaction) => {
                                        balance -= cn_transaction.node.price;
                                        
                                        if(balance.toFixed(2) < 0 || balance.toFixed(2) === "-0.00"){
                                            balance = 0
                                        }                                        
                                        
                                        return (
                                            <tr key={cn_transaction.node.id} id="history">
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-center">{format(cn_transaction.node.creditNote.issuedDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-center">
                                                    <Link to={"/accounting/income/credit-note/detail/" + cn_transaction.node.id}>
                                                        {cn_transaction.node.creditNote.docNumber}
                                                    </Link>
                                                </td>
                                                <td className="text-right red">
                                                    ({numberWithComma(cn_transaction.node.price)})
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td className="text-right">{numberWithComma(balance, "0.00")}</td>
                                            </tr>
                                            )
                                        
                                    })
                                }

                                {
                                    rd_info.map((rt_transaction) => {
                                        if(rt_transaction.node.receiptDepositTransaction.couple === "HAVE" && rt_transaction.node.coupleUse === "HAVE"){
                                            balance -= rt_transaction.node.amount;

                                            if(Math.round(balance) === -0){
                                                balance = 0
                                            }
                                            return (
                                                <tr key={rt_transaction.node.receiptDeposit.id} id="history">
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                    <td className="text-center">{format(rt_transaction.node.receiptDeposit.issuedDate, 'DD/MM/YYYY')}</td>
                                                    <td className="text-center">
                                                        <Link to={"/accounting/income/receipt_deposit/view/" + rt_transaction.node.receiptDeposit.id}
                                                            target={"_blank"}>
                                                            {rt_transaction.node.receiptDeposit.docNumber}
                                                        </Link>
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(rt_transaction.node.amount)}
                                                    </td>
                                                    <td className="text-right">{numberWithComma(balance, "0.00")}</td>
                                                </tr>
                                                )
                                            
                                            }else{
                                                return (null)
                                            }
                                        }
                                        
                                    )
                                }
                            </React.Fragment>
                        )
                    })
                )
            })
        )
    }
}

export default HistoryTable;
