import React, { Component } from 'react';
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import { QueryRenderer } from "react-relay";
import numberWithComma from "../../libs/numberWithComma";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import Loading from '../../libs/loading';
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import ChartSelect from "./chartSelect";
import ExportTrailBalance from "./server_export";
import _ from "lodash";
import i18next from 'i18next';
import BackButtonIcon from "../../components/BackBtn/indexBack";



const query = graphql`
    query trialBalanceListQuery(
        $startDate: DateTime, 
        $endDate: DateTime,
        $betweenStartId: String, 
        $betweenEndId: String
        $before_start_date_year: DateTime,
        $before_end_date_year: DateTime
        ) {
        viewer {
            allChart: allChartOfAccount {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
        
        allActiveChartOfAccount(startDate: $startDate, endDate: $endDate, beforeStartDate: $before_start_date_year, beforeEndDate: $before_end_date_year,betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, trialBalanceSelect:true) {
            edges {
              node {
                id
                chartOfAccountCode
                name
                totalDebit
                totalCredit
                totalHistoricalDebit
                totalHistoricalCredit
                totalPeriodDebit
                totalPeriodCredit

                beforeTotalDebit
                beforeTotalCredit
                beforeTotalHistoricalDebit
                beforeTotalHistoricalCredit
                beforeTotalPeriodDebit
                beforeTotalPeriodCredit
              }
            }
        }
        sumAllActiveChartOfAccount(startDate: $startDate, endDate: $endDate, beforeStartDate: $before_start_date_year, beforeEndDate: $before_end_date_year,betweenStartId: $betweenStartId, betweenEndId: $betweenEndId, trialBalanceSelect:true) 
        allAccountProjectManager{
            edges{
              node{
                id
                tableAccount
              }
            }
        }
    }
`;

class TrialBalanceList extends Component {

    constructor(props) {
        super(props);
        let month_before = new Date();
        month_before.setMonth(month_before.getMonth() - 1);

        let year_before = new Date();
        year_before.setFullYear(year_before.getFullYear() - 1);

        this.state = {
            start_date: month_before,
            end_date: new Date(),
            chart_of_account_start: "",
            chart_of_account_end: "",

            start_date_before: month_before,
            end_date_before: new Date(),
            chart_of_account_start_before: "",
            chart_of_account_end_before: "",

            before_start_date_year: year_before,
            before_end_date_year: year_before,

            before_year_data: [],
            current_data: [],
            all_data: []

        };
        this.handleChange = this.handleChange.bind(this);
        this.calDateCheck = this.calDateCheck.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    calDate() {

        let lastStartYear = _.cloneDeep(this.state.start_date_before)
        lastStartYear.setFullYear(lastStartYear.getFullYear() - 1)

        let lastEndDate = _.cloneDeep(this.state.end_date_before)
        lastEndDate.setFullYear(lastEndDate.getFullYear() - 1)

        this.setState({
            start_date: this.state.start_date_before,
            end_date: this.state.end_date_before,
            chart_of_account_start: this.state.chart_of_account_start_before,
            chart_of_account_end: this.state.chart_of_account_end_before,
            before_start_date_year: lastStartYear,
            before_end_date_year: lastEndDate
        }, () => {
            this.setState({
                start_date: this.state.start_date_before,
                end_date: this.state.end_date_before,
                chart_of_account_start: this.state.chart_of_account_start_before,
                chart_of_account_end: this.state.chart_of_account_end_before,
                before_start_date_year: lastStartYear,
                before_end_date_year: lastEndDate
            });

        });


    }

    calDateCheck(data) {

        let start_date = format(new Date(this.state.start_date), "YYYY-MM-DD")
        let end_date = format(new Date(this.state.end_date), "YYYY-MM-DD");

        let tableAccount = null;
        let tableAccountisNull = null;

        tableAccount = format(new Date(data?.node?.tableAccount), "YYYY-MM-DD")
        tableAccountisNull = data?.node?.tableAccount ? true : false;

        if (tableAccount >= start_date && tableAccount <= end_date && tableAccountisNull != false) {
            return true;
        }
        return false;
    }

    render() {


        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t('accounting:trial balance')}
                                    boxHtmlText={
                                        <div className="col">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_trial_balance_print' }) &&
                                                <ExportTrailBalance state={this.state} />
                                            }
                                        </div>
                                    }
                                />
                                <div className="content-inner">

                                    <div className="row mb-4">
                                        <div className="col-6">
                                            <div className="float-left mr-3">
                                                <label>{i18next.t("AllFilter:Date")} </label> <br />
                                                <DatePickerAdapter name="start_date_before"
                                                    id="start_date_before"
                                                    className="form-control"
                                                    //    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.start_date_before}
                                                    maxDate={this.state.end_date_before}
                                                    onChange={this.handleChange} />
                                            </div>
                                            <div className="float-left">
                                                <label>{i18next.t("AllFilter:to")}</label> <br />
                                                <DatePickerAdapter name="end_date_before"
                                                    id="end_date_before"
                                                    className="form-control"
                                                    //    dateFormat="dd/MM/yyyy"
                                                    minDate={this.state.start_date_before}
                                                    selected={this.state.end_date_before}
                                                    onChange={this.handleChange} />
                                            </div>
                                            <div className="float-left">
                                                <div className='pt-7 ml-3'>
                                                    <button type="button"
                                                        className="btn btn-primary form-control"
                                                        name="search"
                                                        onClick={() => this.calDate()
                                                        }
                                                    >{i18next.t("AllFilter:Search")}
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <label>{i18next.t("AllFilter:Specify the account number.")}</label>
                                            <ChartSelect handleChange={this.handleChange}
                                                name={"chart_of_account_start_before"}
                                                value={this.state.chart_of_account_start_before} />
                                        </div>
                                        <div className="col-3">
                                            <label>{i18next.t("AllFilter:to")}</label>
                                            <ChartSelect handleChange={this.handleChange}
                                                name={"chart_of_account_end_before"}
                                                value={this.state.chart_of_account_end_before} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">{i18next.t("AllFilter:Date")} {format(this.state.end_date, 'DD/MM/YYYY', { locale: thLocale })} </div>
                                    </div>
                                    <hr />

                                    <div className="row">
                                        <div className="col">
                                            <QueryRenderer
                                                environment={environment}
                                                query={query}
                                                fetchPolicy="no-cache"
                                                variables={{
                                                    startDate: this.state.start_date,
                                                    endDate: this.state.end_date,
                                                    betweenStartId: this.state.chart_of_account_start,
                                                    betweenEndId: this.state.chart_of_account_end,
                                                    beforeStartDate: this.state.before_start_date_year,
                                                    beforeEndDate: this.state.before_end_date_year
                                                }}
                                                render={({ error, props }) => {
                                                    if (error) {
                                                        return <div>{error.message}</div>;
                                                    } else if (props) {

                                                        let sumAllActiveChartOfAccount = props.sumAllActiveChartOfAccount;
                                                        let obj_summary_before_total_credit_sum = JSON.parse(sumAllActiveChartOfAccount)
                                                        let obj_summary_before_total_debit_sum = JSON.parse(sumAllActiveChartOfAccount)
                                                        let summary_before_total_credit_sum_ = obj_summary_before_total_credit_sum.summary_before_total_credit_sum;
                                                        let summary_before_total_debit_sum_ = obj_summary_before_total_debit_sum.summary_before_total_debit_sum;


                                                        let summary_debit = 0;
                                                        let summary_credit = 0;
                                                        let summary_current_debit = 0;
                                                        let summary_current_credit = 0;



                                                        let before_total_debit = 0;
                                                        let before_total_credit = 0;

                                                        return (
                                                            <div className="table-responsive card">
                                                                <table className="table table-bordered">
                                                                    <thead className=" table-dark">
                                                                        <tr>
                                                                            <td className="font-weight-bold text-center" colSpan="2">{i18next.t("trialBalanceList:Trial Balance")}</td>
                                                                            <td colSpan="2" className="font-weight-bold text-center">{i18next.t("trialBalanceList:Opening Balance")}</td>
                                                                            <td colSpan="2" className="font-weight-bold text-center">{i18next.t("trialBalanceList:Movement")}</td>
                                                                            <td colSpan="2" className="font-weight-bold text-center">{i18next.t("trialBalanceList:Balance")}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-weight-bold" width="120">{i18next.t("trialBalanceList:Account Code")}</td>
                                                                            <td className="font-weight-bold">{i18next.t("trialBalanceList:Account Name")}</td>
                                                                            <td className="font-weight-bold text-center" width="120">{i18next.t("trialBalanceList:Debit")}</td>
                                                                            <td className="font-weight-bold text-center" width="120">{i18next.t("trialBalanceList:Credit")}</td>
                                                                            <td className="font-weight-bold text-center" width="120">{i18next.t("trialBalanceList:Debit")}</td>
                                                                            <td className="font-weight-bold text-center" width="120">{i18next.t("trialBalanceList:Credit")}</td>
                                                                            <td className="font-weight-bold text-center" width="120">{i18next.t("trialBalanceList:Debit")}</td>
                                                                            <td className="font-weight-bold text-center" width="120">{i18next.t("trialBalanceList:Credit")}</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {props.allActiveChartOfAccount.edges.map((chart, index) => {

                                                                            let total_debit = chart.node.totalHistoricalDebit + chart.node.totalDebit + chart.node.totalPeriodDebit;
                                                                            let total_credit = chart.node.totalHistoricalCredit + chart.node.totalCredit + chart.node.totalPeriodCredit;

                                                                            let total_historical_debit_between = 0
                                                                            let total_historical_credit_between = 0

                                                                            let sum_total_debit = total_debit - total_credit;

                                                                            //sum all เคลื่อนไหว
                                                                            summary_current_debit += chart.node.totalDebit;
                                                                            summary_current_credit += chart.node.totalCredit;

                                                                            if (this.calDateCheck(props.allAccountProjectManager.edges[0])) {

                                                                                summary_current_debit += chart.node.totalHistoricalDebit;
                                                                                summary_current_credit += chart.node.totalHistoricalCredit;
                                                                                total_historical_debit_between += chart.node.totalHistoricalDebit;
                                                                                total_historical_credit_between += chart.node.totalHistoricalCredit;
                                                                            }


                                                                            //คำนวนยอดคงเหลือจากปีที่แล้ว มาเป็นยกยอดมาปจบ
                                                                            before_total_debit = chart.node.beforeTotalHistoricalDebit + chart.node.beforeTotalPeriodDebit;
                                                                            before_total_credit = chart.node.beforeTotalHistoricalCredit + chart.node.beforeTotalPeriodCredit;

                                                                            let sum_before_total_debit = before_total_debit - before_total_credit

                                                                            if (total_debit > total_credit) {
                                                                                summary_debit += total_debit - total_credit;
                                                                            } else {
                                                                                summary_credit += total_credit - total_debit;
                                                                            }

                                                                            return (

                                                                                <React.Fragment>
                                                                                    {
                                                                                        // total_debit !== 0 || total_credit !== 0 
                                                                                        // || chart.node.totalDebit !== 0 || chart.node.totalCredit !== 0 
                                                                                        // || chart.node.totalPeriodCredit !== 0 ||  chart.node.totalHistoricalDebit !== 0 ? 

                                                                                        // total_debit !== 0 || 
                                                                                        sum_total_debit !== 0 || sum_before_total_debit !== 0 ||
                                                                                            chart.node.totalDebit !== 0 || chart.node.totalCredit !== 0 ?

                                                                                            <tr key={chart.node.id}>
                                                                                                <td>{chart.node.chartOfAccountCode}</td>
                                                                                                <td>{chart.node.name}</td>

                                                                                                {/* ยอดยกมา */}
                                                                                                <td className="text-right">
                                                                                                    {/* {numberWithComma(
                                                                        (chart.node.totalHistoricalDebit+ chart.node.totalPeriodDebit) 
                                                                        - (chart.node.totalHistoricalCredit+chart.node.totalPeriodCredit)
                                                                        )} */}
                                                                                                    {
                                                                                                        before_total_debit >= before_total_credit ?
                                                                                                            numberWithComma(before_total_debit - before_total_credit) !== '0.00' ?
                                                                                                                numberWithComma(parseFloat(before_total_debit - before_total_credit)) : '-'
                                                                                                            : "-"

                                                                                                    }
                                                                                                </td>
                                                                                                <td className="text-right">
                                                                                                    {
                                                                                                        before_total_credit >= before_total_debit ?
                                                                                                            numberWithComma(before_total_credit - before_total_debit) !== '0.00' ?
                                                                                                                numberWithComma(parseFloat(before_total_credit - before_total_debit)) : '-'
                                                                                                            : "-"
                                                                                                    }
                                                                                                    {/* {numberWithComma(
                                                                            chart.node.totalHistoricalCredit 
                                                                            // +chart.node.totalPeriodCredit
                                                                        )} */}
                                                                                                </td>

                                                                                                {/* เคลื่อนไหว */}
                                                                                                {/* debit */}
                                                                                                <td className="text-right">
                                                                                                    <Link to={"/accounting/general-ledger/"
                                                                                                        + chart.node.id + "/"
                                                                                                        + format(this.state.start_date, "YYYY-MM-DD") + "/"
                                                                                                        + format(this.state.end_date, "YYYY-MM-DD")}
                                                                                                        target={"_blank"}>
                                                                                                        {numberWithComma(chart.node.totalDebit + total_historical_debit_between)}


                                                                                                    </Link>
                                                                                                </td>
                                                                                                {/* credit */}
                                                                                                <td className="text-right">
                                                                                                    <Link to={"/accounting/general-ledger/"
                                                                                                        + chart.node.id + "/"
                                                                                                        + format(this.state.start_date, "YYYY-MM-DD") + "/"
                                                                                                        + format(this.state.end_date, "YYYY-MM-DD")}
                                                                                                        target={"_blank"}>
                                                                                                        {numberWithComma(chart.node.totalCredit + total_historical_credit_between)}

                                                                                                    </Link>
                                                                                                </td>

                                                                                                {/* คงเหลือ */}
                                                                                                <td className="text-right">
                                                                                                    {total_debit > total_credit ?
                                                                                                        numberWithComma(total_debit - total_credit) !== '0.00' ?
                                                                                                            numberWithComma(parseFloat(total_debit - total_credit)) : "-" :
                                                                                                        "-"
                                                                                                    }

                                                                                                </td>
                                                                                                <td className="text-right">
                                                                                                    {total_credit > total_debit ?
                                                                                                        numberWithComma(total_credit - total_debit) !== '0.00' ?
                                                                                                            numberWithComma(parseFloat(total_credit - total_debit)) : "-" :
                                                                                                        "-"
                                                                                                    }

                                                                                                </td>
                                                                                            </tr>

                                                                                            : ''
                                                                                    }

                                                                                </React.Fragment>

                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                    {/* ยอดรวม */}
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td colSpan="2" className="font-weight-bold text-center">{i18next.t("trialBalanceList:Total")} </td>
                                                                            {/* ยอดยกมา เดบิต*/}
                                                                            <td className="font-weight-bold text-right">
                                                                                {
                                                                                    numberWithComma(summary_before_total_debit_sum_) !== '0.00' ?
                                                                                        numberWithComma(summary_before_total_debit_sum_) : '-'
                                                                                }
                                                                            </td>
                                                                            {/* ยอดยกมา เครดิต*/}
                                                                            <td className="font-weight-bold text-right">
                                                                                {
                                                                                    numberWithComma(summary_before_total_credit_sum_) !== '0.00' ?
                                                                                        numberWithComma(summary_before_total_credit_sum_) : '-'
                                                                                }
                                                                            </td>
                                                                            {/* เคลื่อนไหว เดบิต */}
                                                                            <td className="font-weight-bold text-right">
                                                                                {
                                                                                    numberWithComma(summary_current_debit) !== '0.00' ?
                                                                                        numberWithComma(summary_current_debit) : '-'
                                                                                }
                                                                            </td>
                                                                            {/* เคลื่อนไหว เครดิต */}
                                                                            <td className="font-weight-bold text-right">
                                                                                {
                                                                                    numberWithComma(summary_current_credit) !== '0.00' ?
                                                                                        numberWithComma(summary_current_credit) : '-'
                                                                                }

                                                                            </td>

                                                                            {/* <td className="font-weight-bold text-right">
                                                                                {
                                                                                    parseFloat(summary_before_total_debit_sum_) + parseFloat(summary_current_debit) > 0 ?
                                                                                        numberWithComma(parseFloat(summary_before_total_debit_sum_) + parseFloat(summary_current_debit)) : '-'
                                                                                }
                                                                            </td>
                                                                            <td className="font-weight-bold text-right">
                                                                                {
                                                                                    parseFloat(summary_before_total_credit_sum_) + parseFloat(summary_current_credit) > 0 ?
                                                                                    numberWithComma(parseFloat(summary_before_total_credit_sum_) + parseFloat(summary_current_credit)) : '-'
                                                                                }
                                                                            </td> */}
                                                                            
                                                                            <td className="font-weight-bold text-right">
                                                                                {
                                                                                    numberWithComma(summary_debit) !== '0.00' ?
                                                                                        numberWithComma(summary_debit) : '-'
                                                                                }
                                                                            </td>
                                                                            <td className="font-weight-bold text-right">
                                                                                {
                                                                                    numberWithComma(summary_credit) !== '0.00' ?
                                                                                        numberWithComma(summary_credit) : '-'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        )
                                                    }
                                                    return <div><Loading /></div>
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </Translation>

                </div>
            </Wrapper>
        );
    }
}

export default TrialBalanceList;
