import i18next from 'i18next';
import React, { Component } from 'react'
import Modal from 'react-modal';

export default class modalVoidStockManagement extends Component {

  componentDidMount() {    
    if(this.props.open){
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.open}
          onRequestClose={this.props.handleCloseModal}
          ariaHideApp={false}
          style={{
            content: { 
              width : "448px",
              height: "352px",
              top : '50%',
              left : '50%',
              right : 'auto',
              bottom : 'auto',
              marginRight : '-50%',
              transform : 'translate(-50%, -50%)',
              padding : '2%'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }
          }}
          >
            <div id="modalVoidStockManagement">
              <div className="d-flex justify-content-center">
                <img className="my-4 " src="/images/iconAlerts/question.png" alt="question"/>
              </div>

              <div className="text-center textContentHead my-5">
                <p>
                  {i18next.t("stockCreateAndUpdate:Do you will cancel")}<br/>
                  {i18next.t("stockCreateAndUpdate:document requisition?")}
                </p>
              </div>

              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-between w-75">
                  <button type="button" className="btnClose" onClick={this.props.handleCloseModal}>
                    <span>{i18next.t("Allaction:cancel")}</span>
                  </button>
                  <button type="button" className="btnConfirm" onClick={this.props.handleVoid}>
                    <span>{i18next.t("Allaction:Confirm")}</span>
                  </button>
                </div>
              </div>
            </div>
        </Modal>
      </React.Fragment>
    )
  }
}
