/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type cashDetailQueryVariables = {|
  id: string
|};
export type cashDetailQueryResponse = {|
  +cashDepositGroup: ?{|
    +id: string,
    +docNumber: string,
    +depositDate: any,
    +bank: ?{|
      +accountNumber: string,
      +accountName: string,
      +bankName: string,
    |},
    +image: ?string,
    +total: ?number,
    +cashDepositCashDepositGroup: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +receive: ?{|
            +docNumber: string,
            +issuedDate: any,
            +total: ?number,
          |},
          +invoice: ?{|
            +docNumber: string,
            +total: number,
            +issuedDate: any,
          |},
          +receiptDeposit: ?{|
            +docNumber: string,
            +total: ?number,
            +issuedDate: any,
          |},
          +guaranteeMoneyReceive: ?{|
            +docNumber: string,
            +issuedDate: any,
            +amount: ?number,
          |},
          +clearAdvance: ?{|
            +docNumber: string,
            +issuedDate: any,
            +returnPrice: number,
          |},
          +otherReceive: ?{|
            +docNumber: string,
            +issuedDate: any,
            +total: number,
          |},
          +description: ?string,
          +price: number,
        |}
      |}>
    |},
  |},
  +selfProject: ?{|
    +timeZone: ?string
  |},
|};
export type cashDetailQuery = {|
  variables: cashDetailQueryVariables,
  response: cashDetailQueryResponse,
|};
*/


/*
query cashDetailQuery(
  $id: ID!
) {
  cashDepositGroup(id: $id) {
    id
    docNumber
    depositDate
    bank {
      accountNumber
      accountName
      bankName
      id
    }
    image
    total
    cashDepositCashDepositGroup {
      edges {
        node {
          id
          receive {
            docNumber
            issuedDate
            total
            id
          }
          invoice {
            docNumber
            total
            issuedDate
            id
          }
          receiptDeposit {
            docNumber
            total
            issuedDate
            id
          }
          guaranteeMoneyReceive {
            docNumber
            issuedDate
            amount
            id
          }
          clearAdvance {
            docNumber
            issuedDate
            returnPrice
            id
          }
          otherReceive {
            docNumber
            issuedDate
            total
            id
          }
          description
          price
        }
      }
    }
  }
  selfProject {
    timeZone
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "depositDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v10/*: any*/),
  (v9/*: any*/)
],
v12 = [
  (v3/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returnPrice",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
},
v18 = [
  (v3/*: any*/),
  (v10/*: any*/),
  (v9/*: any*/),
  (v2/*: any*/)
],
v19 = [
  (v3/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cashDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CashDepositGroup",
        "kind": "LinkedField",
        "name": "cashDepositGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "kind": "LinkedField",
            "name": "bank",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CashTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "cashDepositCashDepositGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CashTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CashTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceNode",
                        "kind": "LinkedField",
                        "name": "invoice",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiptDepositNode",
                        "kind": "LinkedField",
                        "name": "receiptDeposit",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuaranteeMoneyReceivedNode",
                        "kind": "LinkedField",
                        "name": "guaranteeMoneyReceive",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v10/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClearAdvanceNode",
                        "kind": "LinkedField",
                        "name": "clearAdvance",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v10/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherReceiveNode",
                        "kind": "LinkedField",
                        "name": "otherReceive",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cashDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CashDepositGroup",
        "kind": "LinkedField",
        "name": "cashDepositGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "kind": "LinkedField",
            "name": "bank",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CashTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "cashDepositCashDepositGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CashTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CashTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceNode",
                        "kind": "LinkedField",
                        "name": "invoice",
                        "plural": false,
                        "selections": (v19/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiptDepositNode",
                        "kind": "LinkedField",
                        "name": "receiptDeposit",
                        "plural": false,
                        "selections": (v19/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuaranteeMoneyReceivedNode",
                        "kind": "LinkedField",
                        "name": "guaranteeMoneyReceive",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v10/*: any*/),
                          (v13/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClearAdvanceNode",
                        "kind": "LinkedField",
                        "name": "clearAdvance",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v10/*: any*/),
                          (v14/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OtherReceiveNode",
                        "kind": "LinkedField",
                        "name": "otherReceive",
                        "plural": false,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v17/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0eb5511072f9e1d4a8ee7d469e9ee19",
    "id": null,
    "metadata": {},
    "name": "cashDetailQuery",
    "operationKind": "query",
    "text": "query cashDetailQuery(\n  $id: ID!\n) {\n  cashDepositGroup(id: $id) {\n    id\n    docNumber\n    depositDate\n    bank {\n      accountNumber\n      accountName\n      bankName\n      id\n    }\n    image\n    total\n    cashDepositCashDepositGroup {\n      edges {\n        node {\n          id\n          receive {\n            docNumber\n            issuedDate\n            total\n            id\n          }\n          invoice {\n            docNumber\n            total\n            issuedDate\n            id\n          }\n          receiptDeposit {\n            docNumber\n            total\n            issuedDate\n            id\n          }\n          guaranteeMoneyReceive {\n            docNumber\n            issuedDate\n            amount\n            id\n          }\n          clearAdvance {\n            docNumber\n            issuedDate\n            returnPrice\n            id\n          }\n          otherReceive {\n            docNumber\n            issuedDate\n            total\n            id\n          }\n          description\n          price\n        }\n      }\n    }\n  }\n  selfProject {\n    timeZone\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7465443550679ef6ace41a41e453893';

module.exports = node;
