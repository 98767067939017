import React, { Component } from 'react';
import Swal from "sweetalert2";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Translation } from "react-i18next";
import _ from 'lodash';
import ClaimTopMenuNavigation from '../claimTopMenuNavigation';
import jwtDecode from "jwt-decode";
import { decode } from 'base-64';
import getWSRoot from '../../../libs/getWSRoot';
import claim from '../../../api/claim'
import { format } from 'date-fns';
import upload from '../../../libs/upload'
import LightBox from '../../../libs/lightBox';
import i18next from 'i18next';

let chatSocket = {};


class ClaimChat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedId: "",
            account: [],
            mapAccount: [],
            redirect: false,
            homeAll: [],
            message_list: [],
            message: "",

            repairDetail: [],

            typeFix: "1",
            RequestBy: "",
            RequestKey: "",
            isRequestKey: false,
            imgProject: "/images/typeIcon/sing/imgProject.png",
            floor: "",
            pageX: "0px",
            pageY: "0px",
            display: "none",
            file: [],


            keyGen: "",
            workingSheet: [],
            sendStatus: false,

            listTag: [],
            tag_repairs_room: [],
            loadingTag: false,
            loading: true,
            oleWork: [],
            openTime: '',
            endTime: '',


            data: { claim_detail: {}, image_claim: [], asset_claim: [], file_claim: [] }

        }
        this.sendMessage = this.sendMessage.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onkeyUp = this.onkeyUp.bind(this)
        this.selectTag = this.selectTag.bind(this)
        this.removeTagFromClaim = this.removeTagFromClaim.bind(this)
        this.getListTag = this.getListTag.bind(this)
        this.removeTagFromGlobal = this.removeTagFromGlobal.bind(this)
        this.uploadFile = this.uploadFile.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentWillMount() {
        this.readHistoryChat()
        this.getListTag()
    }

    componentWillUnmount() {
        chatSocket.close();
    }

    fetchData = () => {
        // fetchQuery(environment, query).then(data => {
        //     console.log(data);
        //     this.setState({openTime: data.selfProject?.openTime , endTime: data.selfProject?.endTime})
        // });
    }

    connectSocket() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        // let keyGen = this.setState({ keyGen :   })
        chatSocket = new WebSocket(getWSRoot() + '/insure_chat/' + decode(this.props.match.params.id) + '/', window.localStorage.getItem('token'));
        let _this = this;
        chatSocket.onmessage = function (e) {
            let data = JSON.parse(e.data);

            let message_list = [..._this.state.message_list];

            let update = false;
            message_list.forEach((message) => {
                if (data['message_id'] === message['message_id']) {
                    message['updated'] = data['updated'];
                    update = true;
                }
            });

            if (update) {
                // update updated time
                _this.setState({ message_list: [..._this.state.message_list, data] }, () => {
                    _this.scrollToBottom();
                })
            } else {
                // receive from web socket
                _this.setState({ message_list: [..._this.state.message_list, data] }, () => {
                    _this.scrollToBottom();
                })
            }
        };

        chatSocket.onclose = function (event) {
            if (event.code !== 1000 && event.code !== 1006) {
                Swal.fire({
                    title: i18n.t("fix_request:Chat system crashes"),
                    text: i18n.t("fix_request:connection_fail"),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: i18n.t("fix_request:Try again"),
                    cancelButtonText: i18n.t("fix_request:close")
                }).then((result) => {
                    if (result.value) {
                        window.location.reload();
                    }
                })
            }
        };
    }
    scrollToBottom() {
        let objDiv = document.getElementById("chatMessageFixRequest");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    readHistoryChat() {
        // claim.readChat(data);
        claim.GetCliamID(decode(this.props.match.params.id)).then((res) => {
            if (res.data.claim) {
                this.setState({
                    data: res.data.claim,
                    loading: false,
                    message_list: res.data.claim?.room_insurance_message
                }, () => {
                    this.connectSocket()
                })
                this.scrollToBottom()
                // this.getOldWork() 
            }
        })
    }

    onkeyUp(e) {

        if (e.key === "Enter") {
            this.setState({ message: e.target.value, sendStatus: true })
            this.sendMessage()
            e.target.value = ""
        }
    }

    onChange(e) {
        this.setState({ message: e.target.value, sendStatus: true })
    }

    sendMessage() {
        this.scrollToBottom();
        document.getElementsByClassName("boxSendChatMessage").value = ""
        let stautSend = 1
        if (this.state.sendStatus) {
            let keyGen = new Date().getTime() + Math.floor(Math.random() * 10000000)
            let sendData = {
                "claimId": decode(this.props.match.params.id),
                "key": "WEB_" + keyGen,
                "message": this.state.message,
                "image": "",
                "profile": "",
                "role": "juristic",
                "token": window.localStorage.getItem('token') || null
            }
            claim.readChat(decode(this.props.match.params.id));
            claim.SendChatMessage(sendData).then((res) => {
                if (res.data) {

                    this.setState({ keyGen: keyGen, message: '', sendStatus: false }, () => {
                        chatSocket.send(JSON.stringify(sendData));
                        sendData = {}
                    })




                }
            })
        }

    }

    getListTag() {
        claim.getListTag().then((res) => {
            if (res.data.tag_global_claim) {
                this.setState({ listTag: res.data.tag_global_claim })
            }
        })
    }

    selectTag(TagId, claim_id) {
        let data = {
            "tag_claim_id": TagId,
            "claim_id": claim_id
        }
        claim.create_tag_claim(data).then((res) => {
            this.getData();
        })
    }



    AddNewTagClaim() {
        Swal.fire({
            customClass: {
                title: 'swal2-title-custom',
                header: 'swal2-header-custom',
                container: 'swal2-container-custom',
                content: 'swal2-content-custom',
            },
            title: "ระบุชื่อแท็กใหม่",
            input: 'text',
            inputPlaceholder: 'ระบุชื่อแท็ก',
            inputAttributes: {
                required: 'true'
            }


        }).then((data) => {
            if (data.value !== "") {
                let tagNameNew = {
                    tagName: data.value
                }
                claim.create_tag_global(tagNameNew).then((res) => {
                    if (res.data.statusCode === "200") {
                        this.getListTag()
                    }

                    // if(res.data.status === "200"){

                    // }else if(res.data.status === "401"){
                    //     Swal.fire("ผิดพลาด","ระบบผิดพลาด โปรดล็อคอินใหม่และลองอีกครั้ง" ,"error")
                    // }else{
                    //     Swal.fire("ผิดพลาด","ข้อมูลที่ส่งไป ผิดรูปแบบ ติดต่อ DEV หน้าบ้าน" ,"error")
                    // }

                })
            }
        })
    }

    removeTagFromClaim(id, cliamID) {
        let data = {
            "id": id,
        }
        claim.removeTagFromClaim(data).then((res) => {
            this.getData();
        })
    }

    removeTagFromGlobal(TagId) {
        let data = {
            "id": TagId
        }
        claim.removeTagFromGlobal(data).then((res) => {
            this.getListTag()
            this.getData();
        })
    }

    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/claim/image_chat" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 2097152) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 2 MB (${i18next.t("Allaction:maximum of")} 3 ${i18next.t("Allaction:images")} )`, 'error')
            }

            else {
                let userID = jwtDecode(localStorage.getItem("token"))
                let new_file = new File([files], `claim_web_chat_${userID?.user_id?userID?.user_id: "" }_${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                let fileOldList = _.cloneDeep(this.state.file);
                this.callUpload('', new_file).then((data) => {
                    let dese = "claim/image_chat/" + new_file.name
                    // fileOldList.push({ fileId: "", fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location })
                    let keyGen = new Date().getTime() + Math.floor(Math.random() * 10000000)
                    let sendData = {
                        "claimId": decode(this.props.match.params.id),
                        "key": keyGen,
                        "message": this.state.message,
                        "image": dese,
                        "profile": "",
                        "role": "juristic",
                        "token": window.localStorage.getItem('token') || null
                    }
                    claim.SendChatMessage(sendData).then((res) => {
                        if (res.data) {
                            this.setState({ keyGen: keyGen, message: '', sendStatus: false, view: data.location })
                            chatSocket.send(JSON.stringify(sendData));

                            sendData = {}
                        }
                    })
                });
            }
        }
    }
    // END UPLOAD


    goBack() {
        this.props.history.goBack();
    }


    render() {
        let data = []
        data = this.state.repairDetail
        let workingSheet = this.state.workingSheet

        let tag_repairs_room = this.state.tag_repairs_room
        let olDWork = this.state.oleWork
        let iAm = jwtDecode(localStorage.getItem("token"))
        let namePost = ""
        let ChkPost = ""

        let { claim_detail, asset_claim, file_claim, image_claim } = this.state.data
        // const { go, goBack, goForward } = useHistory();
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ClaimTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            this.state.loading == false ?
                                <div className="container-fluid meetingChat" style={{ marginTop: '15px' }}>
                                    <div className="row justify-content-between chatGoupt claim">
                                        <div className="col-12 mt-3" style={{ borderBottom: '2px solid #E9ECEF' }}>
                                            <h3>
                                                <label className='cursor' onClick={this.goBack}
                                                    style={{ lineHeight: 1 }}>
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </label>
                                                {/* {this.state.data?.name} */}
                                                <div>
                                                    {/* {
                                                                claim_detail.tenant_contact. ?
                                                                    <img className='imgTenant'
                                                                        src={data.tenantContactImage} />
                                                                    :
                                                                    
                                                            } */}
                                                    <img className='imgTenant' src={process.env.PUBLIC_URL + "/images/icons/profileNull.png"} />


                                                    <div style={{ marginTop: "10px", paddingLeft: "60px" }}>
                                                        {
                                                            claim_detail?.tenant_contact ?
                                                                <React.Fragment>
                                                                    <p className='roomHeaderchat'>{claim_detail?.tenant_contact?.first_name + " " + claim_detail?.tenant_contact?.last_name}</p>
                                                                    <p className='roomHeaderchat'>{claim_detail?.residential?.name}</p>
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    <p className='roomHeaderchat'>{claim_detail?.juristic_contact?.first_name + " " + claim_detail?.juristic_contact?.last_name}</p>
                                                                    {/* <p className='roomHeaderchat'>{claim_detail?.juristic_contact?.name}</p> */}
                                                                </React.Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            </h3>
                                        </div>


                                        <div className='col-8' style={{
                                            backgroundColor: "rgb(244,244,248)",
                                            paddingBottom: '30px'
                                        }}>
                                            {/* <p className='text-center'>
                                                        {
                                                            format(n.node.updated, "DD/MM/YYYY") === format(new Date(), "DD/MM/YYYY")?
                                                            "วันนี้" : format(n.node.updated, "DD/MM/YYYY") 
                                                        }
                                                    </p> */}

                                            <div className='chatMessage' id="chatMessageFixRequest" >
                                                {
                                                    this.state.message_list?.map((n, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {
                                                                    n.role === "juristic" ?
                                                                        <div key={index} className='inlineChat active' style={{ float: 'right', textAlign: 'end' }}>

                                                                            <div style={{ margin: "1vw 2vw 0 0", flexDirection: 'row' }}>
                                                                                {n.image ?
                                                                                    <div style={{ verticalAlign: 'bottom' }}>
                                                                                        <LightBox image={n.image}   width={300} height={"auto"}  key={index} />
                                                                                        {/* <img src={n.node.image} style={{ maxWidth: '300px' }} /><br /> */}
                                                                                        <span className='timeChatSend'>{format(n.added, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                                    </div> : ""
                                                                                }
                                                                                {
                                                                                    n.message &&
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            n.message.length >= 100 ?
                                                                                                <div className='ChatP active' style={{
                                                                                                    textAlign: "left",
                                                                                                    wordBreak: 'break-word'
                                                                                                }}>{n.message || "-"}</div>
                                                                                                :
                                                                                                <React.Fragment>
                                                                                                    <span className='ChatP active'>{n.message || "-"}</span> <br />
                                                                                                </React.Fragment>

                                                                                        }
                                                                                        <span className='timeChatSend'>{format(n.added, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                                    </React.Fragment>
                                                                                }


                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className='inlineChat' >
                                                                            {/* <div className='' style={{ width: 60 }}>
                                                                                        {
                                                                                            show_new_login &&
                                                                                            (
                                                                                                user.image || n.message ?
                                                                                                    <img src={user.image} width={50} height={50} className="rounded-circle" />
                                                                                                    :
                                                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/profileNull.png'}
                                                                                                        className="rounded-circle" width={50} height={50} />
                                                                                            )
                                                                                        }
                                                                                    </div> */}
                                                                            <div className='' style={{ width: 600 }}>
                                                                                <div style={{ margin: "1vw 4vw 0 0", flexDirection: 'row' }}>
                                                                                    {n.image ?
                                                                                        <div style={{ verticalAlign: 'bottom' }}>
                                                                                            <LightBox image={n.image} width={300} height={"auto"} key={index} />
                                                                                            {/* <img src={n.node.image} style={{ maxWidth: '300px' }} /><br /> */}
                                                                                            <span className='timeChatSend'>{format(n.added, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                                        </div> : ""
                                                                                    }

                                                                                    {
                                                                                        n.message &&
                                                                                        <React.Fragment>
                                                                                            {
                                                                                                n.message.length >= 100 ?
                                                                                                    <div style={{ wordBreak: 'break-word' }}
                                                                                                        className='ChatP'>{n.message || "-"}</div>
                                                                                                    :
                                                                                                    <React.Fragment>
                                                                                                        <span className='ChatP'>{n.message || "-"}</span>
                                                                                                        <br />
                                                                                                    </React.Fragment>
                                                                                            }

                                                                                            <span className='timeChatSend'>{format(n.added, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                                        </React.Fragment>

                                                                                    }


                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                }

                                                                <br />
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <br />
                                                <div style={{ clear: 'both' }}></div>
                                            </div>

                                            <div style={{ clear: 'both' }}></div>

                                            {/* BOXCHAT */}


                                            <div style={{ marginTop: '3%' }}>
                                                {
                                                    this.state.data?.claim_detail?.status !== "finish"
                                                        && this.state.data?.claim_detail?.status !== "consider_fail"
                                                        && this.state.data?.claim_detail?.status !== "not_approve"
                                                        && this.state.data?.claim_detail.claim_type !== "public" ?
                                                        <div className='row boxMessageChat'>
                                                            <div className='col-12 text-center' style={{ display: 'inline-flex' }}>
                                                                <div style={{ display: 'inline-flex' }}>
                                                                    <label htmlFor="uploadFile" className="upload-btn-wrapper"
                                                                    >
                                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/image.png"} width={25} height={25} />
                                                                        <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร'
                                                                            accept='application/.pdf,.png,.jpg' onChange={this.uploadFile}
                                                                        />

                                                                    </label>
                                                                </div>

                                                                <input type={"text"} className='form-control boxSendChatMessage' 
                                                                    style={{ borderRadius: "24px", width: '95%', margin: '0 10px' }}
                                                                    onChange={this.onChange} onKeyPress={this.onkeyUp} 
                                                                    value={this.state.message}
                                                                />
                                                                <button style={{ backgroundColor: "transparent", border: 'transparent' }} onClick={this.sendMessage}>
                                                                    <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/send.png"} width={25} height={25} /></button>
                                                            </div>
                                                        </div>
                                                        :

                                                        <div className='endChat'>{t("claimRequst:FinishChat")}</div>
                                                }

                                            </div>

                                        </div>

                                        <div className='col-4' style={{ height: '82vh', overflowY: 'auto' }}>
                                            <div className='boxWorkCurrent'>
                                                <p className='fw-300 text_808080 fs-16'>
                                                    {format(claim_detail?.added, "DD/MM/YYYY - HH:mm น.")}
                                                </p>
                                                {/* {
                                                            this.state.data?.node?.roomGroupTag?.edges?.map((n, Tagindx) => {
                                                                return (
                                                                    <label className='tag tagBtnBlue' key={Tagindx}>{n.node.name}</label>
                                                                )
                                                            })
                                                        } */}

                                                <p className='pHeaderBox'>{claim_detail?.header}</p>
                                                <p className='fw-300 text_000000 fs-14 detail-text-newLine'>{claim_detail?.description}</p>

                                                <p className='fw-500 text_808080 fs-14'>{t("claimRequst:claimNo")}
                                                    <span className='text_1567B4'> {claim_detail?.doc_number}</span></p>

                                                <div style={{ display: 'inline-flex' }}>
                                                    <div className='w100 fw-300 text_808080'>{t("claimRequst:Incident Date")}</div>
                                                    <div className='w250 fw-300 text_000000'>{format(claim_detail?.incident_date, "DD/MM/YYYY")}</div>
                                                </div>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <div className='w100 fw-300 text_808080'>{t("claimRequst:Claim Request Date")}</div>
                                                    <div className='w250 fw-300 text_000000'>{format(claim_detail?.issued_date, "DD/MM/YYYY")}</div>
                                                </div>

                                                <div className='mt-5' style={{ display: 'inline-flex', flexWrap: 'wrap' }}>

                                                    {
                                                        image_claim.map((names, inxImg) => {

                                                            return (
                                                                inxImg <= 2 &&
                                                                <div className='p-1 rounded' key={inxImg}>
                                                                    <LightBox image={names.file_upload} className='pImg m-2 p-2' key={inxImg} />
                                                                </div>
                                                                // <LightBox image={n.file_upload} className='pImg' key={inxImg} />
                                                                // <Lightbox
                                                                //     images={[{ src: image_claim.file_upload }]}
                                                                //     // onClose={() => this.toggleLightBox(false)}
                                                                //     // isOpen={this.state.open_light_box}
                                                                //     backdropClosesModal={true}
                                                                //     showImageCount={false}
                                                                // />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <p className='pWorkAgo'>{t("claimRequst:ClaimHistory")}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container-fluid box text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/icons/alert/loading.gif'} />
                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ClaimChat;
