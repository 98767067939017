/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type pettyCashPaymentFormQueryVariables = {||};
export type pettyCashPaymentFormQueryResponse = {|
  +allSetPettyCash: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +description: ?string,
        +withdrawer: ?string,
      |}
    |}>,
  |}
|};
export type pettyCashPaymentFormQuery = {|
  variables: pettyCashPaymentFormQueryVariables,
  response: pettyCashPaymentFormQueryResponse,
|};
*/


/*
query pettyCashPaymentFormQuery {
  allSetPettyCash(status: "active") {
    totalCount
    edges {
      node {
        id
        docNumber
        description
        withdrawer
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "status",
        "value": "active"
      }
    ],
    "concreteType": "SetPettyCashNodeConnection",
    "kind": "LinkedField",
    "name": "allSetPettyCash",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SetPettyCashNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SetPettyCashNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "withdrawer",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "allSetPettyCash(status:\"active\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashPaymentFormQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pettyCashPaymentFormQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "29304f621d7e7885e2da09cc04da0ef3",
    "id": null,
    "metadata": {},
    "name": "pettyCashPaymentFormQuery",
    "operationKind": "query",
    "text": "query pettyCashPaymentFormQuery {\n  allSetPettyCash(status: \"active\") {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        description\n        withdrawer\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '909aea630df039c0cc03fec4ca37c77d';

module.exports = node;
