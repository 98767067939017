import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import Wrapper from '../../components/wrapper';
import WrapperContent from '../../components/wrapper/wrapperContent';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import {Link} from "react-router-dom";
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import Navigation from './navigation';
import GeneralInformation from './generalInformation';
import DetailTeam from './detailTeam';
import _ from 'lodash'

class JuristicCompanyDashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            status : this.props.match.params.status,
        }
    }


    componentDidUpdate(){
        if(this.state.status !== this.props.match.params.status){
            this.setState({
                status : this.props.match.params.status
            })
        }
    }

    render(){
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return (
            <Wrapper>
                <Header />
                <Sidebar/>
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true}/>
                    <Translation>
                        {t=>
                            <div className="container-fluid box" id="projectDashboard">
                                <div className="row">
                                    <div className="col-md-5 col-xl-5">
                                        <h3 className="mb-4">
                                            <Link to="/project">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <span>{
                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                            textData?.data?.juristicCompany.juristicManagementCompnay
                                                :                                                    
                                            t('juristicCompany:Juristic Management Company')
                                            }</span>
                                        </h3>
                                    </div>
                                    {(this.state.status === 'project-management' || this.state.status === 'support') &&
                                        <div className="col-7 float-right ">
                                           {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_juristiccompany_create' }) &&
                                                <Link to={"/project/juristic-company/form/" + this.state.status + "/create"}>
                                                    <button type="button" className="add btn btn-primary float-right" onClick={this.onChangeJuristic}>
                                                        <span>+ {t('juristicCompany:Add Personnel')}</span>
                                                    </button>
                                                </Link>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="content-inner">
                                    <Navigation status={this.state.status}/>
                                    {this.state.status === 'general-information' ? <GeneralInformation/> : <DetailTeam status = {this.state.status}/>}
                                </div>
                            </div>
                            
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
            
        );
    }

}
export default JuristicCompanyDashboard;