import React, {Component} from 'react';
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper/index";
import {Translation} from "react-i18next";
import './style/contactDetail.scss';

import {Link} from "react-router-dom";

import {graphql} from 'babel-plugin-relay/macro';
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import ContactSummaryInvoice from './contactSummaryInvoice'
import ContactSummaryReceive from './contactSummaryReceive'
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Loading from '../../libs/loading';

const contactDetail = graphql`
query contactDetailQuery($id: ID!) {
    contact(id: $id) {
        id
        refNumber
        name
    	firstName
    	lastName
    	phone
    	email
    	webSite
    	registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        mailingName
        mailingAddress
        mailingCountry
        mailingDistrict
        mailingProvince
        mailingCity
        mailingPostalCode
        typeOfSupplier
        totalAmount
        typeOfPayment

        renterAddress
        renterCountry
        renterProvince
        renterDistrict
        renterCity
        renterPostalCode
        
        agentAddress
        agentCountry
        agentProvince
        agentDistrict
        agentCity
        agentPostalCode
    }
}
`;

class ContactDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            back_page:""
        };
    }

    componentWillMount() {
        let back_page= "/accounting/contact/";
        if(this.props.match.params.page === 'residential' || this.props.match.params.page === 'all'){
            back_page += this.props.match.params.page
        }else {
             back_page += "supplier/"+this.props.match.params.page
        }
        this.setState({id: this.props.match.params.id, back_page: back_page});
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="contactDetail">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to={this.state.back_page}>
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('contact:contact detail')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <QueryRenderer
                            environment={environment}
                            query={contactDetail}
                            cacheConfig={{use_cache: false}}
                            variables={{"id": this.state.id}}
                            render={({error, props}) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    return (
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h5 className="card-title">{props.contact.refNumber} {props.contact.name}</h5>

                                                                    {
                                                                        props.contact.typeOfSupplier === "COMPANY" ?
                                                                            <p className="card-text">ประเภท : บริษัทจำกัด</p>
                                                                            : props.contact.typeOfSupplier === "PUBLIC_COMPANY" ?
                                                                            <p className="card-text">ประเภท : บริษัทมหาชนจำกัด</p>
                                                                            : props.contact.typeOfSupplier === "PARTNERSHIP" ?
                                                                            <p className="card-text">ประเภท : ห้างหุ้นส่วนจำกัด</p>
                                                                            : props.contact.typeOfSupplier === "ORDINARY_PARTNERSHIP" ?
                                                                            <p className="card-text">ประเภท : ห้างหุ้นส่วนสามัญ</p>
                                                                            : props.contact.typeOfSupplier === "MERCHANT" ?
                                                                            <p className="card-text">ประเภท : ร้านค้า</p>
                                                                            : props.contact.typeOfSupplier === "PERSON" ?
                                                                            <p className="card-text">ประเภท : บุคคลธรรมดา</p>
                                                                            : props.contact.typeOfSupplier === "GROUP_OF_PERSON" ?
                                                                            <p className="card-text">ประเภท : คณะบุคคล</p>
                                                                            : props.contact.typeOfSupplier === "FOUNDATION" ?
                                                                            <p className="card-text">ประเภท : มูลนิธิ</p>
                                                                            : props.contact.typeOfSupplier === "ASSOCIATION" ?
                                                                            <p className="card-text">ประเภท : สมาคม</p>
                                                                            : props.contact.typeOfSupplier === "JOINT_VENTURE" ?
                                                                            <p className="card-text">ประเภท : กิจการร่วมค้า</p>
                                                                            : props.contact.typeOfSupplier === "OTHER" &&
                                                                            <p className="card-text">ประเภท : อื่นๆ</p>
                                                                    }

                                                                </div>
                                                                <div className="col text-right">
                                                                    <Link to={"/accounting/contact/form/"+props.contact.typeOfPayment.toLowerCase()+"/"+props.contact.id}>
                                                                    <button type="button"
                                                                            className="btn btn-primary add">
                                                                        <Translation>
                                                                            {
                                                                                t => <span>{t('contact:edit')}</span>
                                                                            }
                                                                        </Translation>
                                                                    </button>
                                                                    </Link>
                                                                </div>
                                                            </div>


                                                            <hr/>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <p className="card-text">เว็บไซต์</p>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <p className="card-text">{props.contact.webSite}</p>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <p className="card-text">ที่อยู่</p>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <p className="card-text">{props.contact.registeredName}</p>
                                                                    <p className="card-text">{props.contact.registeredAddress}</p>
                                                                    <p className="card-text">{props.contact.registeredDistrict}</p>
                                                                    <p className="card-text">{props.contact.registeredCity}</p>
                                                                    <p className="card-text">{props.contact.registeredProvince}</p>
                                                                    <p className="card-text">{props.contact.registeredCountry}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="card">
                                                        {this.props.typeOfPayment === 'PAYABLE' ?
                                                            <ContactSummaryInvoice typeOfPayment={props.typeOfPayment}
                                                                                   contact_id={this.state.id}/> :
                                                            <ContactSummaryReceive typeOfPayment={props.typeOfPayment}
                                                                                   contact_id={this.state.id}/>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                }
                                return <Loading/>
                            }}
                        />

                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ContactDetail;
