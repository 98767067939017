import React from "react";
import getApiRoot from "../../../libs/getAPIRoot";
import Helpers from "../../../libs/file_download_helper";
import {format } from "date-fns";
import { Dropdown  } from "react-bootstrap";

class ExportUnknownReceive extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event) => {
        let path = ""
        if(this.props.state.start_date && this.props.state.end_date){
            path = 'export/unknown_receive_list?start_date=' + format(this.props.state.start_date, 'DD/MM/YYYY HH:mm:ss')
            + '&end_date=' + format(this.props.state.end_date, 'DD/MM/YYYY HH:mm:ss')
            + '&status=' + this.props.status
            + '&search=' + this.props.state.search
        }else{
            path = 'export/unknown_receive_list?start_date=null'
            + '&end_date=null'
            + '&status=' + this.props.status
            + '&search=' + this.props.state.search
        }

        this.setState({
            errors: null,
            loading: true,
        }, () => {
            Helpers.httpRequest(
                
                getApiRoot() + path ,
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "unknownReceive.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });

        event.preventDefault();
    };

    render() {

        return (
            <Dropdown.Item>
                <div onClick={this.handleSubmit}>
                    Excel
                </div>
            </Dropdown.Item>
        )
    }
}

export default ExportUnknownReceive;
