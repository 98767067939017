import React, { Component } from "react";
import { Translation } from "react-i18next";
import { NavLink } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <div className="row" id="navigation-tab">
        <div className="col">
          <Translation>
            {t=>
            <ul>
              <li>
                <NavLink exact={true} to="/contact/parcel/all">
                  {t("parcel_list:All")}
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact/parcel/not_receive">{t("parcel_list:Nor received")}</NavLink>
              </li>
              <li>
                <NavLink to="/contact/parcel/receive">{t("parcel_list:Received success")}</NavLink>
              </li>
              <li>
                <NavLink to="/contact/parcel/deleted">{t("parcel_list:Deleted item")}</NavLink>
              </li>
              <li>
                <NavLink to="/contact/parcel/return">{t("parcel_list:Return list")}</NavLink>
              </li>
            </ul>}
          </Translation>
        </div>
      </div>
    );
  }
}

export default Navigation;
