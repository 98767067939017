import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import NoMatch from "../components/noMatch"; 
import meetingAndScoreDashboard from './meetingAndScore';

class meetingAndScore extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={meetingAndScoreDashboard}/>
 
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default meetingAndScore;