import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";
import "./styles/commonArea.scss";

class Navigation extends Component {
  render() {
    return (
      <div className="row" id="navigation">
        <div className="col">
          <Translation>
            {(t) => (
              <ul>
                <li>
                  <NavLink key="ทั้งหมด" exact={true} activeClassName="current" to="/contact/global-area-singha/services/all">
                    <div>ทั้งหมด</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink key="สินค้า" activeClassName="current" to="/contact/global-area-singha/services/product">
                        สินค้า
                  </NavLink>
                </li>
                <li>
                  <NavLink key="บริการส่วนกลาง" activeClassName="current" to="/contact/global-area-singha/services/central-service">
                        บริการส่วนกลาง
                  </NavLink>
                </li>
                <li>
                  <NavLink key="รายรับ" activeClassName="current" to="/contact/global-area-singha/services/revenue">
                        รายรับ
                  </NavLink>
                </li>
                <li>
                  <NavLink key="รายจ่าย" activeClassName="current" to="/contact/global-area-singha/services/expenses">
                        รายจ่าย
                  </NavLink>
                </li>
              </ul>
            )}
          </Translation> 
        </div>
      </div>
    );
  }
}

export default Navigation;