/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdvertiseStatus = "NOT_SHOW" | "SHOW" | "%future added value";
export type settingAdvertiseListQueryVariables = {||};
export type settingAdvertiseListQueryResponse = {|
  +allAdvertiseJuristic: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +detail: ?string,
        +link: ?string,
        +image: ?string,
        +customerName: ?string,
        +types: ?string,
        +click: number,
        +status: AdvertiseStatus,
        +added: any,
      |}
    |}>
  |}
|};
export type settingAdvertiseListQuery = {|
  variables: settingAdvertiseListQueryVariables,
  response: settingAdvertiseListQueryResponse,
|};
*/


/*
query settingAdvertiseListQuery {
  allAdvertiseJuristic {
    edges {
      node {
        id
        title
        detail
        link
        image
        customerName
        types
        click
        status
        added
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdvertiseNodeConnection",
    "kind": "LinkedField",
    "name": "allAdvertiseJuristic",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdvertiseNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiseNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "types",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "click",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingAdvertiseListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingAdvertiseListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "86521ab478fcd1d4f7dbe2f61ac9b08b",
    "id": null,
    "metadata": {},
    "name": "settingAdvertiseListQuery",
    "operationKind": "query",
    "text": "query settingAdvertiseListQuery {\n  allAdvertiseJuristic {\n    edges {\n      node {\n        id\n        title\n        detail\n        link\n        image\n        customerName\n        types\n        click\n        status\n        added\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e12f62851724eb3ac7b10c50fc3f4b95';

module.exports = node;
