/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type queryCheckHouseHolderPaygroupQueryVariables = {|
  contactList?: ?$ReadOnlyArray<?string>,
  contactId?: ?string,
  payGroup?: ?string,
|};
export type queryCheckHouseHolderPaygroupQueryResponse = {|
  +checkHouseHolderPaygroup: ?any
|};
export type queryCheckHouseHolderPaygroupQuery = {|
  variables: queryCheckHouseHolderPaygroupQueryVariables,
  response: queryCheckHouseHolderPaygroupQueryResponse,
|};
*/


/*
query queryCheckHouseHolderPaygroupQuery(
  $contactList: [String]
  $contactId: String
  $payGroup: String
) {
  checkHouseHolderPaygroup(contactList: $contactList, contactId: $contactId, payGroup: $payGroup)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactList"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payGroup"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contactId",
        "variableName": "contactId"
      },
      {
        "kind": "Variable",
        "name": "contactList",
        "variableName": "contactList"
      },
      {
        "kind": "Variable",
        "name": "payGroup",
        "variableName": "payGroup"
      }
    ],
    "kind": "ScalarField",
    "name": "checkHouseHolderPaygroup",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queryCheckHouseHolderPaygroupQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "queryCheckHouseHolderPaygroupQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "64ac6ef8657f9321794ef5e7b86a4ae1",
    "id": null,
    "metadata": {},
    "name": "queryCheckHouseHolderPaygroupQuery",
    "operationKind": "query",
    "text": "query queryCheckHouseHolderPaygroupQuery(\n  $contactList: [String]\n  $contactId: String\n  $payGroup: String\n) {\n  checkHouseHolderPaygroup(contactList: $contactList, contactId: $contactId, payGroup: $payGroup)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f3351065851fc685a61f1dc20993df58';

module.exports = node;
