// import '../../report.scss';
import React from 'react';
import _ from "lodash";
import { format, subHours } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";

require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const ExportCommonAreaExcel = ({ state }) => {
    const getData = () => downloadExcel()
    const downloadExcel = async (data) => {
        let workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet('total receipt');
        let columns = [{ width: 20 }, { width: 10 }, { width: 10 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 10 }, { width: 30 }, { width: 20 }, { width: 20 }]
        // setBorder
        let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        // let fontSizes = { size: 11.5 }
        let textCenter = { vertical: 'middle', horizontal: 'center' };
        let textRight = { vertical: 'middle', horizontal: 'right' };
        let textLeft = { vertical: 'middle', horizontal: 'left' };
        let fontBold = { size: 11.5, bold: true };

        //textHeader 
        worksheet.getCell('A1').value = state.project_name
        worksheet.getCell('A1').font = { size: 11.5, bold: true };
        worksheet.getCell('A2').value = "สรุปการจองพื้นที่ส่วนกลาง"
        worksheet.getCell('A2').font = { size: 11.5, bold: true };
        worksheet.getCell('A3').value = `${format(new Date(), " DD MMMM YYYY", { locale: thLocale, })}`
        worksheet.getCell('A3').font = { size: 11.5, bold: true };

        // TABLE
        let headerRow, cell, last_index = 0

        // HEADER 
        headerRow = worksheet.getRow(5);
        const headerName = ['เลขที่การจอง', 'วันที่ใช้สิทธิ์', 'เวลาที่จองสิทธิ์', 'ชื่อผู้จอง', 'เบอร์ติดต่อ', 'พื้นที่ส่วนกลาง', 'สิทธิ์การจอง', 'จำนวนผู้เข้าใช้บริการ (คน)', 'ผู้ทำรายการ', 'วัน/เวลาทำรายการ', 'สถานะ']
        _.map(headerName, (keyName, index) => {
            cell = headerRow.getCell(index + 1);
            cell.value = keyName;
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textCenter
        })

        //BODY
        _.map(state.dataExport, (item, index_code) => {
            last_index++

            var dataRow = worksheet.addRow();
            cell = dataRow.getCell(1);
            cell.value = item.all_booking_bill[0].doc_number
            cell.alignment = textCenter

            cell = dataRow.getCell(2);
            cell.value = format(new Date(subHours(item.booking_detail.use_date, 7)), "DD/MM/YYYY")
            cell.alignment = textCenter

            cell = dataRow.getCell(3);
            cell.value = format(new Date(subHours(item.booking_detail.start_time, 7)), "HH:mm") + "-" + format(new Date(subHours(item.booking_detail.end_time, 7)), "HH:mm")
            cell.alignment = textCenter

            cell = dataRow.getCell(4);
            cell.value = item.booking_detail.booking_name
            cell.alignment = textCenter
            cell.wrapText = true;

            cell = dataRow.getCell(5);
            cell.value = item.booking_detail.tel
            cell.alignment = textCenter

            cell = dataRow.getCell(6);
            cell.value = item.facility_detail.name
            cell.alignment = textCenter

            cell = dataRow.getCell(7);
            cell.value = item.booking_detail.use_right
            cell.alignment = textCenter

            cell = dataRow.getCell(8);
            cell.value = item.booking_detail.num_people
            cell.alignment = textCenter


            cell = dataRow.getCell(9);
            cell.value = item.booking_detail.booker_detail.name
            cell.alignment = textCenter

            cell = dataRow.getCell(10);
            cell.value = format(new Date(subHours(item.booking_detail.added, 7)), "DD/MM/YYYY HH:mm")
            cell.alignment = textCenter

            cell = dataRow.getCell(11);
            cell.value = item.booking_detail.status === "used" ? "ใช้ไปแล้ว" : item.booking_detail.status === "confirmed" ? "ยืนยันแล้ว" : "ยกเลิก"
            cell.alignment = textCenter

        })

        worksheet.columns = columns;
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let nameFile = 'Common Area Booking Report'
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = nameFile;
            a.click();
        });
    }
    return (
        <Dropdown.Item  ><div className="text-black" onClick={getData} style={{ display: 'flex', justifyItems: 'center' }} >Excel</div></Dropdown.Item>
    );
};

export default ExportCommonAreaExcel;