import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";

import SurveyList from "./surveyList";
import SurveyCreateOrUpdate from "./surveyCreateOrUpdate";
import SurveyDetailList from "./detail/surveyDetailList";
import Document from "../../document";
import NoMatch from "../../components/noMatch";

class Survey extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={SurveyList}/>
                <PrivateRoute exact path={`${this.props.match.url}/form/:status/:id?`} component={SurveyCreateOrUpdate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:status/:id`} component={SurveyDetailList}/>
                <PrivateRoute path={`${this.props.match.url}/document`} component={Document}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Survey;