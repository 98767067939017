import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";

async function getResidential(token,residentialID){
    return axios.get(`${getApiRoot()}ownership/last`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            residentialID: residentialID,
        },
    });
}

export default {getResidential}