import React, { Component } from 'react';
import { Redirect } from 'react-router'
import CreditNoteRemark from "./creditNoteRemark";
import CreditNoteSelectTransaction from "./creditNoteSelectTransaction";

class CreditNoteCreateFormReason extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accountType: "credit_note",
      customer: "",
      transactions: [],
      invoice: [],
    };
    this.updateCustomerList = this.updateCustomerList.bind(this);
    this.updateSelectInvoiceList = this.updateSelectInvoiceList.bind(this);
  }
  updateCustomerList(contact_list) {
    this.setState({ customer: contact_list })
  }

  updateSelectInvoiceList(transactions , invoice) {
    this.setState({ 
      transactions: transactions,
      invoice: invoice,
    })
  }

  render() {
    if (this.props.customerList.length > 0 && this.state.transactions.length == 0) {

      return <CreditNoteSelectTransaction contactList={this.props.customerList[0]}
        updateSelectInvoiceList={this.updateSelectInvoiceList} />
      
    }else if (this.props.customerList.length > 0 && this.state.transactions.length > 0){
        return <CreditNoteRemark customerList={this.props.customerList}
          updateReasonCreate={this.props.updateReasonCreate}
          transaction_list={this.state.transactions}
          invoice_list={this.state.invoice}
          category={'credit_note'}
          multi_select={true}
          next_page={'/accounting/income/credit-note/form/create'}
          back_page={'/accounting/income/credit-note/form/select_customer'}
        />
    }
    return <Redirect to="/accounting/income/credit-note/form/select_customer" />;
  }
}

export default CreditNoteCreateFormReason;
