import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";

import NoMatch from "../../components/noMatch"; 
import CheckSite from './checkSite';
import CheckSiteAdminSvm from './checkSiteAdminSvm';

class CheckSites extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={`${this.props.match.url}/:user`} component={CheckSiteAdminSvm}/>
                <Route exact path={`${this.props.match.url}/:user/:site/:token`} component={CheckSite}/>

                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default CheckSites;