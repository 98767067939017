import React from 'react';
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";

class SummaryListTable extends React.Component {

    render() {
        return (
                <tr>
                    <td><strong>{this.props.text_summary}</strong></td>
                    {this.props.list_data_loop.map((value,index) => {
                        let summary = this.props.summary[index];
                        if (this.props.empty_last_col && index === (this.props.list_data_loop.length - 1)) {
                            return (
                                <td key={_.random(0, 1000000)}
                                    className="text-right"/>
                            );
                        }
                        if (summary < 0) {
                            return (
                                <td key={_.random(0, 1000000)}
                                    className="text-right" style={{color: 'red'}}>
                                    <strong>({numberWithComma(Math.abs(summary))})</strong>
                                </td>
                            );

                        } else {
                            return (
                                <td key={_.random(0, 1000000)}
                                    className="text-right">
                                    <strong>{numberWithComma(summary)}</strong>
                                </td>
                            );
                        }
                    })
                    }
                </tr>
        )
    }
}

export default SummaryListTable;
