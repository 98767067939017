import React, { Component } from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import WrapperContent from '../../../../components/wrapper/wrapperContent';
import { Link, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import i18n from "i18next";
import Select from 'react-select';
import '../../style/claim.scss'
import APIInsur from '../../../../api/insurrance'
import _ from 'lodash';
class EditInsuranceCoInsurrance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            co_insurance: [],
            file_insurance: [],
            data: null,
            insurance_start_date: "",
            insurance_end_date: "",
            dataInsuranceCompany: []
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChageData = this.onChageData.bind(this)
        this.addListInsur = this.addListInsur.bind(this)
        this.SeleteCompanyName = this.SeleteCompanyName.bind(this)
        this.co_insuranceUpdate = this.co_insuranceUpdate.bind(this)
    }

    getCompanyAll() {
        APIInsur.getCompanyAll().then((res) => {
            if (res.data) {
                let data = res.data.company_list;
                this.setState({ dataInsuranceCompany: data.map((n) => ({ ...n, value: n.id, label: n.name, insurance_ratio: 0 })) })
            }
        })
    }
    componentWillMount() {
        this.getCompanyAll()
        if (this.props.match.params.id) {
            APIInsur.getDataInsuranceById(this.props.match.params.id).then((res) => {
                if (res.data) {
                    this.setState({
                        data: res.data?.insurance.insurance_detail,
                        file_insurance: res.data?.insurance.file_insurance,
                        co_insurance: res.data?.insurance.co_insurance,
                        loading: false,

                    })
                } else {
                    Swal.fire("ผิดพลาด", "ส่งข้อมูลผิดพลาด", 'warning')
                }
            }, () => {
                Swal.fire("ผิดพลาด", "ไม่สามารถเชื่อมต่อหลังบ้านได้", 'error')
            })
        }
    }

    SeleteCompanyName(e, index, main) {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        const index_f = _.findIndex(co_insurance, { co_insurance_company: { id: e.id } })
        if (index_f >= 0) {
            Swal.fire("บริษัทประกันต้องไม่ซ้ำกัน", "โปรดระบุใหม่", 'warning')
            co_insurance[index].co_insurance_company = {}
            co_insurance[index].co_insurance_company_id = ""
        } else {
            co_insurance[index].co_insurance_company = e
            co_insurance[index].co_insurance_company_id = e.id
        }
        this.setState({ co_insurance: co_insurance })
    }

    co_insuranceUpdate(data, index, e) {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        const insurance_ratio_old = co_insurance[index].insurance_ratio

        co_insurance[index].insurance_ratio = parseFloat(e.target.value)

        let number_per = _.sumBy(co_insurance, 'insurance_ratio') | 0
        number_per = parseFloat(number_per)

        if (number_per > 100) {
            Swal.fire("ระบุต้องไม่เกิน 100 % เท่านั้น", "โปรดระบุใหม่", 'warning')
            co_insurance[index].insurance_ratio = insurance_ratio_old
            e.target.value = insurance_ratio_old
        }

        this.setState({ co_insurance: co_insurance })

    }

    addListInsur() {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        let co_insurancechk = co_insurance.filter((n) => n.name !== "")

        if (co_insurancechk.length >= 0) {
            co_insurance.push({ co_insurance_company_id: "", co_insurance_company: {}, name: "", insurance_ratio: 0, status: "co" })
            this.setState({ co_insurance: co_insurance })
        }

    }

    onChageData(e) {
        let data = _.cloneDeep(this.state.data)
        let NewData = _.set(data, `${e.target.name}`, e.target.value)
        this.setState({ data: NewData })
    }

    deleteListInsur(index) {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        co_insurance.splice(index, 1)
        this.setState({ co_insurance })
    }

    onSubmit(e) {
        e.preventDefault();

        let data = {
            co_insurance: this.state.co_insurance,
            file_insurance: this.state.file_insurance
        }

        APIInsur.updateDataInsurance(this.props.match.params.id, data).then((res) => {
            if (res.data?.statusCode === "0000") {
                Swal.fire({
                    type: 'success',
                    title: i18n.t("Allaction:successcompleate"),
                }).then(() => {
                    this.props.history.push(`/purchasing/insurrance/insur-detail/${this.props.match.params.id}/Insurance`)
                })
            }
        })

    }


    render() {
        let { data, loading, file_insurance } = this.state

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            loading == false ?
                                <div className="container-fluid box claim" id="staff-create">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/purchasing/insurrance">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t('claim:Insurance')}</span>
                                            </h3>
                                        </div>
                                    </div>


                                    <div className="content-inner mt-5 create">
                                        <div className="row mb-12 mr-5 fade-up ">
                                            <div className='mx-auto col-12'>

                                                <React.Fragment>
                                                    <form onSubmit={this.onSubmit}>
                                                        <div className='onFinishTh'>
                                                            <div className='col-12'>
                                                                <label className='headerLabel18'>{t("claim:listMainCompany")}</label>
                                                            </div>

                                                            <div className='col-12 mt-5'>
                                                                <table className='table table-hover'>
                                                                    <thead className='thead-light'>
                                                                        <tr>
                                                                            <th style={{ width: 120 }} />
                                                                            <th style={{ width: 50 }} className='text-center'>{t("claim:thNo")}</th>
                                                                            <th className='text-left'>{t("claim:thCompany")}</th>
                                                                            <th className='text-center'>{t("claim:thPercent")}</th>
                                                                            <th />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {
                                                                            this.state.co_insurance.map((n, inx) => {
                                                                                let selectedOption = ''
                                                                                if (n.co_insurance_company) {
                                                                                    selectedOption = _.find(this.state.dataInsuranceCompany, { id: n.co_insurance_company.id })
                                                                                }

                                                                                return (
                                                                                    <tr key={inx}>
                                                                                        <td className='tag text-left'>
                                                                                            {
                                                                                                inx === 0 &&
                                                                                                <span className='tagDanger'>{t("claim:thMain")}</span>
                                                                                            }
                                                                                        </td>
                                                                                        <td className='text-center'>
                                                                                            <span className='text-black label16'>

                                                                                            </span>
                                                                                        </td>
                                                                                        <td  >
                                                                                            <div style={{ position: 'relative' }}>
                                                                                                <div className='seletedInputBox'>
                                                                                                    <Select
                                                                                                        placeholder={t("claim:CompanyName")}
                                                                                                        options={this.state.dataInsuranceCompany}
                                                                                                        value={selectedOption}
                                                                                                        isSearchable={false}
                                                                                                        onChange={(e) => this.SeleteCompanyName(e, inx, false)}
                                                                                                        isDisabled={n.status === 'main' ? true : false}
                                                                                                    />
                                                                                                    <input name="main_insurance_company_id" type="text" value={this.state.main_insurance_company_id || ""}
                                                                                                        style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                                        onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className='text-center' style={{ alignSelf: 'center' }}>
                                                                                            <input type={'number'} max={100}
                                                                                                onChange={(e) => this.co_insuranceUpdate(n, inx, e)}
                                                                                                defaultValue={n.insurance_ratio}
                                                                                                className='form-control text-center inputBoxPercent' placeholder='0.00' />
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                n.status !== "main" &&
                                                                                                <img src={process.env.PUBLIC_URL + "/images/icons/deleteRed.png"} sizes='16'
                                                                                                    onClick={() => this.deleteListInsur(inx)} />
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }

                                                                    </tbody>
                                                                </table>

                                                                <label className='label16 colorBlue mt-10'
                                                                    onClick={this.addListInsur}
                                                                >+ {t("claim:addList")}</label>
                                                            </div>

                                                        </div>

                                                        <div className='col-11 text-right mt-10 pr-1'>
                                                            <Link to={`/purchasing/insurrance/insur-detail/${this.props.match.params.id}/Insurance`}>
                                                                <button type='button' className='btn-outline-blue' style={{ width: 128, height: 48 }}>{t("Allaction:cancel")}</button>
                                                            </Link>
                                                            <button type='submit' className='btn-blue-primary' style={{ width: 128, height: 48 }}>{t("Allaction:save")}</button>
                                                        </div>
                                                    </form>
                                                </React.Fragment>




                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container-fluid box fixRequestFast text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default withRouter(EditInsuranceCoInsurrance);
