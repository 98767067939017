import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import localStorage from "../../../../libs/localstorage";
import i18next from "i18next";

const _ = require('lodash');


class ReceiptDepositMovementReportTable extends React.Component {

    render() {
        return (
            <React.Fragment>
                {this.props.props.receiptDepositViewer?.allReceiptDeposit?.edges.map((receipt_deposit, index) => {
                    let order_id = this.props.state.first - localStorage.getPageLimit();
                    let amount = receipt_deposit.node.total;
                    let rd_transaction_used = 0;
                    
                    let receiptDepositTransactionUsedSort = _.cloneDeep(receipt_deposit?.node?.receiptDepositTransactionUsed?.edges).sort(function compare(a, b) {
                        var dateA = new Date(a.node.transaction===null?a.node.otherExpense.issuedDate:a.node.transaction.invoice.issuedDate);
                        var dateB = new Date(b.node.transaction===null?b.node.otherExpense.issuedDate:b.node.transaction.invoice.issuedDate);
                        return dateA - dateB;
                    })
                    if (receipt_deposit.node.status !== "VOID") {
                        return (
                            <React.Fragment key={receipt_deposit.node.id}>
                                <tr>
                                    <td className="text-center">{order_id + index + 1}</td>
                                    <td className="text-center">
                                        {receipt_deposit.node.docNumber}
                                    </td>
                                    <td className="text-center">{format(receipt_deposit.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                    <td>{receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode + " " + receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.name}</td>
                                    <td>{receipt_deposit.node.receiptDepositTransaction.edges[0].node.description}</td>
                                    <td className="text-right">{numberWithComma(receipt_deposit.node.total)}</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">{numberWithComma(receipt_deposit.node.total)}</td>
                                </tr>

                            
                                {receiptDepositTransactionUsedSort.map((rd_used) => {
                  
                                    let useDocNumber = rd_used.node.transaction !== null ? rd_used.node.transaction?.invoice?.docNumber 
                                                        : rd_used.node.receiveTransaction !== null ? rd_used.node.receiveTransaction?.transaction?.invoice?.docNumber
                                                        : rd_used.node.otherExpense?.docNumber;
                                    let useIssuedDate = rd_used.node.transaction !== null ? rd_used.node.transaction?.invoice?.issuedDate 
                                                        : rd_used.node.receiveTransaction !== null ? rd_used.node.receiveTransaction?.transaction?.invoice?.issuedDate
                                                        : rd_used.node.otherExpense?.issuedDate;
                                    let description = rd_used.node.transaction !== null ? rd_used.node.transaction?.description 
                                                        : rd_used.node.receiveTransaction !== null ? rd_used.node.receiveTransaction?.transaction.description
                                                        : rd_used.node.otherExpense?.otherExpenseTransaction?.edges[0].node.description;
                                        
                                
                                    if (_.isObject(rd_used.node.receiveTransaction)) {
                                        useDocNumber = rd_used.node.receiveTransaction.receive?.docNumber;
                                        useIssuedDate = rd_used.node.receiveTransaction.receive?.issuedDate;
                                    }
                                    
                                    if ( useDocNumber.includes('IV-') || useDocNumber.includes('RT-') || useDocNumber.includes('OE-') ) {
                                        
                                        amount -= rd_used.node?.amount;
                                        rd_transaction_used += rd_used.node?.amount;
                                        
                                        amount = Math.abs(amount).toFixed(2)
                                        if(amount === "0.00"){
                                            amount = 0
                                        }
                                        
                                        return (
                                            <tr key={rd_used.node.id}>
                                                <td />
                                                <td className="text-center">{useDocNumber && useDocNumber}</td>
                                                <td className="text-center">{useIssuedDate && format(useIssuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                <td>{receipt_deposit.node?.receiptDepositTransaction?.edges[0].node.chartOfAccount?.chartOfAccountCode + " " + receipt_deposit.node?.receiptDepositTransaction?.edges[0].node?.chartOfAccount.name}</td>
                                                <td>{description && description}</td>
                                                <td className="text-right">-</td>
                                                <td className="text-right">{numberWithComma(rd_used.node?.amount)}</td>
                                                <td className="text-right">{numberWithComma(amount)}</td>
                                            </tr>
                                        )
                                    }else{
                                        return <></>
                                    }
                                }
                                )}
                                <tr>
                                    <td className="text-center font-weight-bold" colSpan={5}>{i18next.t("ReceiptDepositMovementReport:Sum")}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(receipt_deposit.node.total)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(rd_transaction_used)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(amount)}</td>
                                </tr>

                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={receipt_deposit.node.id}>
                                <tr>
                                    <td className="text-center text-danger">{order_id + index + 1}</td>
                                    <td className="text-center text-danger">
                                        {receipt_deposit.node.docNumber}
                                    </td>
                                    <td className="text-center text-danger">{format(receipt_deposit.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                    <td className="text-danger">{receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode + " " + receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.name}</td>
                                    <td className="text-danger">{receipt_deposit.node.receiptDepositTransaction.edges[0].node.description}</td>
                                    <td className="text-right text-danger">{numberWithComma(0)}</td>
                                    <td className="text-right text-danger">-</td>
                                    <td className="text-right text-danger">{numberWithComma(0)}</td>
                                </tr>


                                {receiptDepositTransactionUsedSort.map((rd_used) => {
                                    amount -= rd_used.node.amount;
                                    let useDocNumber = rd_used.node.transaction.invoice.docNumber;
                                    let useIssuedDate = rd_used.node.transaction.invoice.issuedDate;
                                    if (_.isObject(rd_used.node.receiveTransaction)) {
                                        useDocNumber = rd_used.node.receiveTransaction.receive.docNumber;
                                        useIssuedDate = rd_used.node.receiveTransaction.receive.issuedDate;
                                    }
                                    return (
                                        <tr key={rd_used.node.id}>
                                            <td />
                                            <td className="text-center text-danger">{useDocNumber}</td>
                                            <td className="text-center text-danger">{format(useIssuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                            <td className="text-danger">{receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode + " " + receipt_deposit.node.receiptDepositTransaction.edges[0].node.chartOfAccount.name}</td>
                                            <td className="text-danger">{rd_used.node.transaction.description}</td>
                                            <td className="text-right text-danger">-</td>
                                            <td className="text-right text-danger">{numberWithComma(0)}</td>
                                            <td className="text-righ text-dangert">{numberWithComma(0)}</td>
                                        </tr>
                                    )
                                }
                                )}
                                <tr>
                                    <td className="text-center font-weight-bold" colSpan={5}>รวม</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(0)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(0)}</td>
                                    <td className="text-right font-weight-bold">{numberWithComma(0)}</td>
                                </tr>

                            </React.Fragment>
                        )
                    }
                })
                }
            </React.Fragment>
        )
    }
}

export default ReceiptDepositMovementReportTable;
