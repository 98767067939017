import '../styles/main.scss';
import React, { Component } from 'react';
import InvoiceGetAll from './invoiceGetAll';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {fetchQuery} from "relay-runtime";
import _ from "lodash";
const query = graphql`
    query wrapperInvoiceGetAllProcessQuery($idRaw_In: [String] ) {

        invoiceViewer{
            allInvoiceOptimizer(idRaw_In: $idRaw_In,){
                edges{
                    node{
                        id
                        docNumber
                        remark
                        status
                        total
                        taxType
                        
                        firstName
                        lastName
                        name
                        taxIdNumber
                        email
                        phone
                        
                        address
                        country
                        province
                        district
                        city
                        postalCode
                        payGroup
                        
                        contact {
                            refNumber
                            name
                            taxIdNumber
                            firstName
                            lastName
                            phone
                            email
                            typeOfCompany
                            typeOfSupplier
                            nameBranch
                            typeOfContact
                            typeOfPayment
                            
                            residential {
                                id
                                name
                                size
                                ownershipRatio
                                unitNumber
                                residentialHouseholder{
                                    edges{
                                        node{
                                            id
                                            tenant{
                                                nameTitle
                                                firstName
                                                lastName
                                                phone
                                                email
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        docNumber
                        issuedDate
                        dueDate
                        creator
                        remark
                        historyShow
                        transaction{
                            edges{
                                node{
                                    id
                                    find {
                                        edges {
                                            node{
                                                id
                                                group {
                                                    fineDate
                                                }
                                            }
                                        }
                                    }
                                    productAndService {
                                        productCode
                                    }
                                    chartOfAccount{
                                        chartOfAccountCode
                                    }
                                    description
                                    unitItems
                                    price
                                    preTaxAmount
                                    discount
                                    vat
                                    whtRate
                                    whtRatePercent
                                    total
                                    receiptDepositTransactionUsed{
                                        edges{
                                            node{
                                                id
                                                amount
                                                coupleUse
                                                receiptDepositTransaction{
                                                    couple
                                                }
                                            }
                                        }
                                    }
                                    keycardPerInvoice{
                                        edges{
                                            node {
                                                id
                                                numberKey{
                                                    id
                                                    noNumber
                                                    status
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
`;


class WrapperInvoiceGetAllProcess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data_list: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    async componentDidMount() {
        let data_list = [];
        await fetchQuery(environment, query, {
            idRaw_In: this.props.data_id_list,
        }).then((data) => {
            let data_set = _.cloneDeep(data);
            data_set.invoiceViewer.allInvoiceOptimizer.edges.forEach((invoice, index) => {
                data_list.push(invoice)
            });

            this.setState({ data_list: data_list, }, () => {
                this.props.adddatalist(data_list);
            })

        });
    }

    
    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <React.Fragment key={this.props.keyindex}>
                {this.props.getAllInvoicePrintLength === this.props.data_list.length && this.props.data_list.length !== 0 &&
                    this.props.data_list.map((invoice, index) =>
                        <InvoiceGetAll 
                            invoice={invoice.node} key={invoice.node.id}
                            check_type={this.props.check_type}
                            invoiceNumber={atob(invoice.node.id).split(':')[1]}
                            history={null}
                            selfProject={this.props.selfProject}
                            userConfig={this.props.userConfig}
                        />
                    )
                }
            </React.Fragment>
            
        );
    }
}

export default WrapperInvoiceGetAllProcess;
