/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingAccountFormQueryVariables = {|
  id: string
|};
export type settingAccountFormQueryResponse = {|
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +startSite: ?any,
        +startAccount: ?any,
        +startDateAccountPeriod: ?any,
        +endDateAccountPeriod: ?any,
      |}
    |}>
  |}
|};
export type settingAccountFormQuery = {|
  variables: settingAccountFormQueryVariables,
  response: settingAccountFormQueryResponse,
|};
*/


/*
query settingAccountFormQuery(
  $id: ID!
) {
  allAccountProjectManager(id: $id) {
    edges {
      node {
        id
        startSite
        startAccount
        startDateAccountPeriod
        endDateAccountPeriod
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "AccountProjectManagerNodeConnection",
    "kind": "LinkedField",
    "name": "allAccountProjectManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountProjectManagerNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountProjectManagerNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startSite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startAccount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDateAccountPeriod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDateAccountPeriod",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingAccountFormQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingAccountFormQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "93c92c862c8bf39d14f2d8a15e975d5a",
    "id": null,
    "metadata": {},
    "name": "settingAccountFormQuery",
    "operationKind": "query",
    "text": "query settingAccountFormQuery(\n  $id: ID!\n) {\n  allAccountProjectManager(id: $id) {\n    edges {\n      node {\n        id\n        startSite\n        startAccount\n        startDateAccountPeriod\n        endDateAccountPeriod\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '730fc0655f6daaf522d9c02b5e5fb0b7';

module.exports = node;
