import React from "react";
import {format} from "date-fns";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import {fetchQuery} from "relay-runtime";
import SummaryListTable from "../accrualBasis/summaryListTable";
import { formatDateISO } from "../../libs/date"
import Loading from "../../libs/loading";
import i18next from "i18next";

const query = graphql`
    query receiveListTableQuery (
        $typeOfBank: String, 
        $start_date: DateTime, 
        $end_date: DateTime, 
        $month_count: Int,
        $dateRanges: [DateRange]!
    ) {
        chartOfAccountCashBasisChannel(startDate: $start_date, endDate: $end_date, monthCount: $month_count, typeOfReport: $typeOfBank){
            edges{
                node{
                    id
                    name nameEn
                    chartOfAccountCode
                    col1Debit col2Debit col3Debit col4Debit col5Debit col6Debit col7Debit col8Debit col9Debit col10Debit col11Debit col12Debit
                    col1Credit col2Credit col3Credit col4Credit col5Credit col6Credit col7Credit col8Credit col9Credit col10Credit col11Credit col12Credit
                }
            }
        }
        reportCashBasisRevenue(dateRanges: $dateRanges) {
            edges {
                node {
                    id
                    name 
                    nameEn
                    chartOfAccountCode
                    data
                }
            }
        }
    }
`;

class ReceiveListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            month_list: [],
            reportCashBasisRevenue: [],
            chartOfAccountChannelYearPeriod: [],
            summary:[],
            summary_all_bank_and_cash:[],
            total_row:[],
            list_data_loop: Array.from(new Array(this.props.state.month_count+1)),
            loading: true,
        };
        this.setPeriod = this.setPeriod.bind(this);
        this.queryChartOfAccount = this.queryChartOfAccount.bind(this);
    }

    componentWillMount() {
        this.queryChartOfAccount();
        this.setPeriod();
    }

    queryChartOfAccount() {
        this.setState({
            loading: true,
        })
        const dateRanges = [];
        for (let iter = this.props.state.start_period.getTime(), end = this.props.state.end_period.getTime(); iter <= end;) {
            const first = new Date(iter);
            const last = new Date(first.getFullYear(), first.getMonth() + 1, 0);
            dateRanges.push({
                start: formatDateISO(first),
                end: formatDateISO(last)
            })
            iter = last.getTime() + (24 * 60 * 60 * 1000);
        }

        fetchQuery(environment, query, {
            start_date: this.props.state.start_period,
            end_date: this.props.state.end_period,
            month_count: this.props.state.month_count,
            typeOfBank: "all_bank_and_cash",
            dateRanges: dateRanges
        }).then(data => {
            if (_.isObject(data.reportCashBasisRevenue)) {
                let summary = [];
                let total_row = [];
                let data_set = _.cloneDeep(data);
                data_set.reportCashBasisRevenue.edges.forEach((chart, index) => {
                    let node = chart.node;
                    let total = 0
                    let empty_value = true;
                    node.data.forEach((result) => {
                        if (result !== 0) {
                            empty_value = false
                        }
                        total += result
                    });

                    _.set(total_row, index, total);

                    _.set(chart, 'node.empty_value', empty_value);

                    for (let i = 0; i < this.props.state.month_count + 2; i++) {
                        if (i === this.props.state.month_count + 1) {
                            _.set(summary, i, _.get(summary, i, 0) + total)

                        } else {
                            _.set(summary, i, _.get(summary, i, 0) + (node.data[i] || 0))
                        }
                    }
                });

                this.setState({
                    reportCashBasisRevenue: data_set.reportCashBasisRevenue.edges,
                    summary: summary,
                    total_row: total_row,
                    loading: false
                }, () => this.props.updateSummary('total_receive', this.state))
            }

            if (_.isObject(data.chartOfAccountCashBasisChannel)) {
                let summary_all_bank_and_cash = [];
                let total_row = 0.0;

                data.chartOfAccountCashBasisChannel.edges.forEach((chart, index) => {
                    let node = chart.node;
                    let total = (node.col1Debit + node.col2Debit + node.col3Debit
                        + node.col4Debit + node.col5Debit + node.col6Debit
                        + node.col7Debit + node.col8Debit + node.col9Debit
                        + node.col10Debit + node.col11Debit + node.col12Debit) -
                        (node.col1Credit + node.col2Credit + node.col3Credit + node.col4Credit
                            + node.col5Credit + node.col6Credit + node.col7Credit + node.col8Credit
                            + node.col9Credit + node.col10Credit + node.col11Credit + node.col12Credit)
                    ;

                    _.set(total_row, total);

                    for (let i = 0; i < 13; i++) {
                        _.set(summary_all_bank_and_cash, i, _.get(summary_all_bank_and_cash, i, 0) + (node['col' + (i + 1) + 'Debit'] || 0) - (node['col' + (i + 1) + 'Credit'] || 0))
                    }
                });

                _.set(summary_all_bank_and_cash, 12, _.get(summary_all_bank_and_cash, 11, 0));

                this.setState({
                    summary_all_bank_and_cash: summary_all_bank_and_cash,
                    loading: false
                }, () => this.props.updateSummary('total_cash_and_bank_historical', this.state.summary_all_bank_and_cash))

            }
        })
    
    }


    componentDidMount() {
        this.setPeriod();
        this.queryChartOfAccount();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.start_period !== this.props.state.start_period || prevProps.state.end_period !== this.props.state.end_period) {
            this.setPeriod();
            this.queryChartOfAccount();
        }
    }

    setPeriod() {
        this.setState({
            load: true,
        })
        let start_month = this.props.state.start_period.getMonth();
        let start_year = this.props.state.start_period.getFullYear();
        let month_list = [this.props.state.start_period];
        let list = Array.from(new Array(this.props.state.month_count));
        let list_data_loop = Array.from(new Array(this.props.state.month_count+2));

        list.map((month,index) => {
            let new_date = new Date(start_year, start_month + (index+1) );
            if (index === this.props.state.month_count-1){
                new_date = this.props.state.end_period
            }
            return month_list.push(new_date);
        });
        this.setState({month_list: month_list, list_data_loop:list_data_loop,});
    }

    render() {
        return (
            this.state.loading ?
            <Loading/>
            :
            <React.Fragment>
                <thead className="text-center">
                <tr>
                    <th rowSpan={2}/>
                    <th colSpan={this.state.month_list.length}>{i18next.t("BalanceSheet:Cash Flow Statement")}
                        ({format(this.props.state.start_period, "DD/MM/YYYY")} - {format(this.props.state.end_period, "DD/MM/YYYY")})
                    </th>
                    <th>รวม</th>
                </tr>
                <tr>
                    {this.state.month_list.map((month, index) => {
                        let text_date='';
                        if(this.state.month_list.length === 1){
                            text_date = '('+format(month, "DD/MM/YY")+' - '+ format(this.props.state.end_period, "DD/MM/YY") + ')'
                        }else if((index === 0 || index === (this.state.month_list.length-1)) && this.state.month_list.length !== 1){
                            text_date = '('+format(month, "DD/MM/YY")+')'
                        }
                        return (
                            <th key={index}>
                                {`${i18next.t("monthNameShort:"+format(month, "MMM"))} ${format(month, "YY")}`} {text_date}
                            </th>
                        )
                    })}
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><strong>เงินสดและเงินฝากธนาคาร ยกมาต้นงวด / Cash & Bank / B/F</strong></td>
                    {this.state.list_data_loop.map((value, index) => {
                        let summary = this.state.summary_all_bank_and_cash[index];
                        if (this.props.summary_index.length === (index + 1)) {
                            summary = this.state.summary_all_bank_and_cash[0]
                        }
                        return (
                            <td width={100} key={index * 4.1} className="text-right">
                                {numberWithComma(summary)}
                            </td>
                        );
                    })
                    }
                </tr>

                <tr>
                     <td width={360}><strong>1. กระแสเงินสดรับ / Revenue</strong></td>
                    {this.state.month_list.map((month, index) => {
                        return (
                            <td width={100} key={'td-'+index}/>
                        )
                    })}
                    <td/>
                </tr>

                {this.state.reportCashBasisRevenue.map((chart, index) => {
                    if (chart.node.empty_value) {
                        return <React.Fragment key={index}/>;
                    }
                    return (
                        <React.Fragment key={chart.node.id}>
                            <tr>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{chart.node.name}{chart.node.nameEn ? " / "+ chart.node.nameEn: ""}</td>
                                {chart.node.data.map((revenue, index) => {
                                    return (
                                        <td className="text-right" key={'data-td-'+index}>
                                            {numberWithComma(revenue)}
                                        </td>
                                    )
                                })}
                                <td className="text-right">{numberWithComma(this.state.total_row[index])}</td>
                            </tr>
                        </React.Fragment>

                    )
                })}
                 <SummaryListTable text_summary="รวมรายรับทั้งสิ้น / Total Revenue" summary={this.state.summary}
                                  list_data_loop={this.state.list_data_loop}/>
                </tbody>
            </React.Fragment>

        )
    }
}

export default ReceiveListTable;
