/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateRoomGroupMessageSendInput = {|
  messageSend: any,
  clientMutationId?: ?string,
|};
export type ChatGroupMutationVariables = {|
  input: CreateRoomGroupMessageSendInput
|};
export type ChatGroupMutationResponse = {|
  +createRoomGroupMessageSend: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type ChatGroupMutation = {|
  variables: ChatGroupMutationVariables,
  response: ChatGroupMutationResponse,
|};
*/


/*
mutation ChatGroupMutation(
  $input: CreateRoomGroupMessageSendInput!
) {
  createRoomGroupMessageSend(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRoomGroupMessageSendPayload",
    "kind": "LinkedField",
    "name": "createRoomGroupMessageSend",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatGroupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatGroupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b0ca97f69ead77b48152abdeb01d2aca",
    "id": null,
    "metadata": {},
    "name": "ChatGroupMutation",
    "operationKind": "mutation",
    "text": "mutation ChatGroupMutation(\n  $input: CreateRoomGroupMessageSendInput!\n) {\n  createRoomGroupMessageSend(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a34503471848db24ddfac05a0b7f6406';

module.exports = node;
