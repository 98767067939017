import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from 'i18next'
import '../styles/register.scss'

import Navigation from "./navigation";
import Swal from "sweetalert2";
import {Redirect} from "react-router";
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {commitMutation} from "react-relay";

const mutation = graphql`    
mutation createUpdateKeyCardMutation($input: CreateUpdateKeyCardInput!) {
        createUpdateKeyCard(input: $input) {
            ok
        }
    }
`;

const query = graphql`
    query createUpdateKeyCardQuery($id :ID!, $residential_id: ID!){
        residential(id: $residential_id){
            name
        }
        keyCard(id: $id){
            id
            typeKeyCard  
            keyCardCode
        }
    }
`;

class CreateUpdateKeyCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            residential_name:"",
            type_key_card: '',
            key_card_code: '',
            redirect: false,
            loading: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillMount() {
        fetchQuery(environment, query, {
            id: this.props.match.params.key_card_id || "",
            residential_id: this.props.match.params.id
        }).then(data => {
            if (data.keyCard) {
                this.setState({
                    id: data.keyCard.id,
                    key_card_code: data.keyCard.keyCardCode,
                    type_key_card: data.keyCard.typeKeyCard,
                })
            }
            if (data.residential) {
                this.setState({
                    residential_name: data.residential.name,
                })
            }
        });
    }

    onSubmit(e) {
        e.preventDefault();

        this.setState({loading: true});

        let keyCardObject = {
            id: this.props.match.params.key_card_id,
            residential_id: this.props.match.params.id,
            type_key_card: this.state.type_key_card,
            key_card_code: this.state.key_card_code,
        };
        let variables = {
            input: {
                keyCardObject: JSON.stringify(keyCardObject)
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createUpdateKeyCard.ok) {
                        Swal.fire(i18n.t("keycard:Save successfully!"), '', 'success').then((result) => {
                            if (result.value) {
                                this.setState({
                                    redirect: true
                                })
                            }
                        });
                    } else {
                        Swal.fire(i18n.t("keycard:Save unsuccessfully!"), '', 'warning');
                    }

                },
                onError: (err) => {
                    this.setState({loading:false});
                    Swal.fire('Error!', i18n.t("keycard:Please try again"), 'warning')
                },
            },
        );
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/register/residential/detail/key-card-detail/" + this.props.match.params.id}}/>
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid p-4" id="form-detail">
                        <div className="row justify-content-between">
                            <div className="col header-color">
                                <h3 className="mb-4">
                                    <Link to={{
                                        pathname: "/register/residential/detail/key-card-detail/" + this.props.match.params.id}}>
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    {this.state.residential_name}
                                </h3>
                                <div className="content-inner">
                                    <Navigation id={this.props.match.params.id} />
                                </div>
                            </div>
                        </div>

                        <div className="content-inner">
                            <div className="row mb-4 fade-up">
                                <div className="col-md-4">
                                    <div className="card p-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h4 className="mb-3">
                                                        <Translation>
                                                            {
                                                                t => <span>{t('register:add_new_key_card')}</span>
                                                            }
                                                        </Translation>
                                                    </h4>
                                                </div>
                                            </div>

                                            <form action="" onSubmit={this.onSubmit} id="create-update-key-card">
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <Translation>{t=><h6>{t("keycard:Keycard type")}</h6>}</Translation>
                                                    <input type="text" className="form-control"
                                                           id="type_key_card"
                                                           value={this.state.type_key_card}
                                                           onChange={this.handleInputChange}
                                                           name="type_key_card" required/>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col">
                                                    <Translation>{t=><h6>{t("keycard:Keycard No.")}</h6>}</Translation>
                                                    <input type="text" className="form-control"
                                                           id="key_card_code"
                                                           value={this.state.key_card_code}
                                                           onChange={this.handleInputChange}
                                                           name="key_card_code" required/>
                                                </div>
                                            </div>
                                        </form>

                                            <div className="row mt-4">
                                                <div className="col">
                                                    <Translation>
                                                        {t=>
                                                        <button type="submit" form="create-update-key-card" disabled={this.state.loading}
                                                                className="btn btn-primary bt-style form-update">
                                                            {t("keycard:Save")}
                                                            {
                                                                this.state.loading &&
                                                                <span className="spinner-border spinner-border-sm align-middle mr-2"/>
                                                            }
                                                        </button>}
                                                    </Translation>
                                                </div>
                                                <div className="col">
                                                    <Link to={{
                                                        pathname: "/register/residential/detail/key-card-detail/" + this.props.match.params.id}}>
                                                        <Translation>
                                                            {t=><button className="btn btn-secondary bt-style form-update">
                                                                {t("keycard:Cancel")}
                                                            </button>}
                                                        </Translation>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default CreateUpdateKeyCard;
