import React, { Component } from 'react';
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";

class DataAnalyticMenuNavigaton extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }


    render() {
        return (
            <div className="container-fluid bg-gray">
                <div className={"row "
                    + (this.props.mini ? "mini " : "")
                    + (this.props.center ? "center-navigation" : "top-menu-navigation")}>
                                        {this.props.center &&
                        <Translation>{t => <div className="col-12 mt-5 mb-5"><h3 className="text-center">{t("navigation:dataAnalytic")}</h3></div>}</Translation>
                }
                <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                    <ul>
                    {
                                <li>
                                    <Link to="/dataAnalytic/trackingMenu/tenantapp">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/topMenuNavigation/contact/news-top-menu.png'}
                                            alt="news-top-menu"
                                            className={this.props.mini && 'd-none'} />
                                        <Translation>
                                            {
                                                t => <div >{t('topMenuNavigation:trackingDataAnalytic')}</div>
                                            }
                                        </Translation>
                                    </Link>
                                </li>
                    }
                    </ul>
                </div>
                </div>

            </div>
        );
    }
}

export default DataAnalyticMenuNavigaton;
