import React from "react";
import Chart from "react-google-charts";

const options = {
    is3D: false,
    chartArea: {left: 10, top: 10, bottom: 10, right: 10},
    fontSize: 10,
    colors: ["#1290DD", "#4EB4A9", "#F8B03C", "#F37748", "#D7263D", "#02182B",
        "#171D1C", "#FDCA40", "#8A716A", "#F26430", "#F37748", "#A2D729"]
};

class graphDummyPieChart extends React.Component {
    render() {
        return (
            <Chart
                chartType="PieChart"
                data={this.props.data}
                options={options}
                loader={<div>Loading Chart</div>}
            />
        )
    }
}

export default graphDummyPieChart;