import React, {Component} from 'react';
import AssetCreateUpdateForm from "./assetCreateAndUpdateForm";
import {graphql} from "babel-plugin-relay/macro";

const query = graphql`
    query assetEditQuery($id: ID!) {
        asset(id: $id) {
            id
            type
            docNumber
            nounClassifier
            chartOfAccountType {
                id
            }
            chartOfAccountDepreciation {
                id
            }
            chartOfAccountDepreciationCumulative {
                id
            }
            name
            description
            storage
            remark
            seller{
                id
            }
            sellerOther
            calDepreciation
            depreciationRatePercent
            depreciationRate
            lifeTimeYear
            purchaseDate
            saleDate
            calDepreciationDate
            costPrice
            carcassPrice
            calSchedule
            historicalValue
            historicalAmount
        }
        allAssetImage(asset_Id: $id){
            edges{
                node{
                    id
                    source
                }
            }
        }
    }
`;

class AssetEdit extends Component {

    render() {
        return (
            <AssetCreateUpdateForm asset_id={this.props.asset_id} query={query}/>
        )
    }
}

export default AssetEdit;