import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import DashboardfixAssetDepreciate from "./DashboardfixAssetDepreciate";
import AssetRegisterReport from "./register/assetRegisterReport";
import AssetDepreciateReport from "./depreciate/assetDepreciateReport";
import AssetAmortizedReport from './amortized/assetAmortizedReport'
import AssetCheckingReport from './checkingAsset/assetCheckingReport';

class Report extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={DashboardfixAssetDepreciate}/>
                <PrivateRoute path={`${this.props.match.url}/register`} component={AssetRegisterReport}/>
                <PrivateRoute path={`${this.props.match.url}/amortized`} component={AssetAmortizedReport}/>
                <PrivateRoute path={`${this.props.match.url}/depreciate`} component={AssetDepreciateReport}/>
                <PrivateRoute path={`${this.props.match.url}/checking`} component={AssetCheckingReport}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Report;
