import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import DataAnalyticMenu from './dataAnalytic';
import DataAnalyticTrackingMenu from './dataAnalyticTrackingMenu';

class DataAnalytic extends Component {
    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={DataAnalyticMenu}/>
                <PrivateRoute exact path={`${this.props.match.url}/trackingMenu/:category`} component={DataAnalyticTrackingMenu}/>
            </React.Fragment>
        );
    }
}

export default DataAnalytic;