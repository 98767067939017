//ตั้งค่าการตอบกลับข้อความ
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import "./settingAutoMessageForm.scss"
import BackButtonIcon from '../../components/BackBtn/indexBack';
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import Swal from 'sweetalert2';
import i18n from "i18next";

const query = graphql`
query settingAutoMessageFormQuery {
    allAutoReplayNitiSetting {
        edges{
            node{
                id
                autoReplay
                text
                timeStart
                timeEnd
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
            }
        }
    }
}
`;

const mutation = graphql`
    mutation settingAutoMessageFormMutation($input: CreateupdateAutoReplayMessagesInput!) {
        createupdateAutoReplayMessages(input:$input) {
            ok
            message
        }
    }
`;

class SettingAutoMessageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowMessageReply: false,
            textWord: 0,
            textMessageReply: '',
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            startTime: "",
            endTime: '',
            loading: true
        }
        this.onKeyUpTextMessageReply = this.onKeyUpTextMessageReply.bind(this)
        this.onChangeData = this.onChangeData.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.getData = this.getData.bind(this)
        this.onchangeSetDay = this.onchangeSetDay.bind(this)
        this.onChangeShowReplyMessage = this.onChangeShowReplyMessage.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        fetchQuery(environment, query).then(response_data => {
            if (response_data?.allAutoReplayNitiSetting) {
                let data = response_data?.allAutoReplayNitiSetting?.edges[0]
                this.setState({
                    loading: false,
                    ShowMessageReply: data?.node?.autoReplay || false,
                    textWord: data?.node?.text?.length || 0,
                    textMessageReply: data?.node?.text,
                    startTime: data?.node?.timeStart,
                    endTime: data?.node?.timeEnd,

                    monday: data?.node?.monday || false,
                    tuesday: data?.node?.tuesday || false,
                    wednesday: data?.node?.wednesday || false,
                    thursday: data?.node?.thursday || false,
                    friday: data?.node?.friday || false,
                    saturday: data?.node?.saturday || false,
                    sunday: data?.node?.sunday || false,
                })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    onKeyUpTextMessageReply(e) {
        let value = e.target.value
        this.setState({ [e.target.name]: value, textWord: value.length })
    }

    //Input
    onChangeData(e) {
        let value = e.target.value
        this.setState({ [e.target.name]: value })
    }

    //Checked
    onchangeSetDay(e) {
        this.setState({ [e.target.name]: e.target.checked })
    }

    onChangeShowReplyMessage() {
        this.setState({ ShowMessageReply: this.state.ShowMessageReply ? false : true }, () => {
            if (this.state.ShowMessageReply == false) {
                this.setState({ loading: true })
                let variables = {
                    "input": {
                        "autoReplay": this.state.ShowMessageReply,
                        "text": this.state.textMessageReply,
                        "timeStart": this.state.startTime,
                        "timeEnd": this.state.endTime,
                        "monday": this.state.monday,
                        "tuesday": this.state.tuesday,
                        "wednesday": this.state.wednesday,
                        "thursday": this.state.thursday,
                        "friday": this.state.friday,
                        "saturday": this.state.saturday,
                        "sunday": this.state.sunday
                    }
                }
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.createupdateAutoReplayMessages.ok) {
                                Swal.fire(i18n.t('settingAccount:Save successfully'), '', 'success').then(() => {
                                    this.getData()
                                })
                            } else {
                                Swal.fire(i18n.t('settingAccount:Failed to save'), '', 'warning');
                                this.setState({ loading: false });
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18n.t('settingAccount:Please try again'), 'warning')
                            this.setState({ loading: false });
                        },
                    },
                );
            }
        })
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true })
        let variables = {
            "input": {
                "autoReplay": this.state.ShowMessageReply,
                "text": this.state.textMessageReply,
                "timeStart": this.state.startTime,
                "timeEnd": this.state.endTime,
                "monday": this.state.monday,
                "tuesday": this.state.tuesday,
                "wednesday": this.state.wednesday,
                "thursday": this.state.thursday,
                "friday": this.state.friday,
                "saturday": this.state.saturday,
                "sunday": this.state.sunday
            }
        }
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response.createupdateAutoReplayMessages.ok) {
                        Swal.fire(i18n.t('settingAccount:Save successfully'), '', 'success').then(() => {
                            this.getData()
                        })
                    } else {
                        Swal.fire(i18n.t('settingAccount:Failed to save'), '', 'warning');
                        this.setState({ loading: false });
                    }
                },
                onError: (err) => {
                    Swal.fire('Error!', i18n.t('settingAccount:Please try again'), 'warning')
                    this.setState({ loading: false });
                },
            },
        );


    }


    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box" >
                                <BackButtonIcon
                                    LinkBack={"/setting"}
                                    LinkText={t('settingAutoMessage:settingAutoMessage')}
                                />

                                {
                                    this.state.loading == false ?
                                        <div className="content-inner claim">
                                            <div className="row mt-3">
                                                <div className="col-9 mx-auto">
                                                    <div>
                                                        <label className='headerLabel28 floatLeft'>{t("settingAutoMessage:showAutoMessage")}</label>
                                                        <label className='headerLabel28 floatRight btnSwitch'>
                                                            <span className='switch'>
                                                                <input
                                                                    type="checkbox"
                                                                    defaultChecked={this.state.ShowMessageReply}
                                                                    onChange={this.onChangeShowReplyMessage} />
                                                                <span className="slider round"></span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div style={{ clear: 'both', borderBottom: '1px solid #D9D9D9' }} />
                                                    <form onSubmit={this.onSubmit}>
                                                        {
                                                            this.state.ShowMessageReply == true &&
                                                            <div className='usedAllAction mt-5'>
                                                                <label className='floatLeft headerLabel18'>{t("settingAutoMessage:ReplyMessage")}</label>
                                                                <label className='floatRight headerLabel18'>{this.state.textWord}/500</label>
                                                                <div style={{ clear: 'both' }} />
                                                                <textarea
                                                                    style={{ padding: 8, }}
                                                                    defaultValue={this.state.textMessageReply}
                                                                    name={"textMessageReply"}
                                                                    onKeyUp={this.onKeyUpTextMessageReply}
                                                                    maxLength={500}
                                                                    // onChange={this.onKeyUpTextMessageReply}
                                                                    className='form-control'
                                                                    rows={20}
                                                                />

                                                                {/* TIME */}
                                                                <div>
                                                                    <label className={'headerLabel18 mt-5 mr-3 floatLeft'}
                                                                         > Working Hours <span className='text-red'>*</span> :
                                                                     </label>
                                                                    <input
                                                                        name='startTime'
                                                                        className='inputTime floatLeft'
                                                                        defaultValue={this.state.startTime}
                                                                        onChange={this.onChangeData}
                                                                        type={"time"}
                                                                    // onInvalid={e => e.target.setCustomValidity('โปรดระบุ เวลาเริ่มงานให้น้อยกว่า เวลาเลิกงาน')}
                                                                    // onInput={e => e.target.setCustomValidity('')}
                                                                    />
                                                                    <span className='floatLeft mt-5 ml-5 mr-5'>-</span>
                                                                    <input
                                                                        name='endTime'
                                                                        className='inputTime floatLeft'
                                                                        defaultValue={this.state.endTime}
                                                                        onChange={this.onChangeData}
                                                                        // min={this.state.startTime}
                                                                        type={"time"}
                                                                    // onInvalid={e => e.target.setCustomValidity('โปรดระบุ เวลาสิ้นสุดให้มากกว่าเวลาเข้างาน')}
                                                                    />
                                                                    <div style={{ clear: 'both' }} />
                                                                </div>

                                                                {/* DAY */}
                                                                <div>

                                                                    <label style={{ width: 125 }} className='floatLeft'></label>
                                                                    <label className='floatLeft p-2'>
                                                                        <input type={"checkbox"} className='mr-2'
                                                                            defaultChecked={this.state.monday}
                                                                            onChange={this.onchangeSetDay}
                                                                            name='monday' />
                                                                        {t("settingAutoMessage:Monday")}
                                                                    </label>
                                                                    <label className='floatLeft p-2'>
                                                                        <input type={"checkbox"} className='mr-2'
                                                                            defaultChecked={this.state.tuesday}
                                                                            onChange={this.onchangeSetDay}
                                                                            name='tuesday' />
                                                                        {t("settingAutoMessage:Tueday")}
                                                                    </label>
                                                                    <label className='floatLeft p-2'>
                                                                        <input type={"checkbox"} className='mr-2'
                                                                            defaultChecked={this.state.wednesday}
                                                                            onChange={this.onchangeSetDay}
                                                                            name='wednesday' />
                                                                        {t("settingAutoMessage:Wednesday")}
                                                                    </label>
                                                                    <label className='floatLeft p-2'>
                                                                        <input type={"checkbox"} className='mr-2'
                                                                            defaultChecked={this.state.thursday}
                                                                            onChange={this.onchangeSetDay}
                                                                            name='thursday' />
                                                                        {t("settingAutoMessage:Thursday")}
                                                                    </label>
                                                                    <label className='floatLeft p-2'>
                                                                        <input type={"checkbox"} className='mr-2'
                                                                            defaultChecked={this.state.friday}
                                                                            onChange={this.onchangeSetDay}
                                                                            name='friday' />
                                                                        {t("settingAutoMessage:Friday")}
                                                                    </label>
                                                                    <label className='floatLeft p-2 text-red'>
                                                                        <input type={"checkbox"} className='mr-2'
                                                                            defaultChecked={this.state.saturday}
                                                                            onChange={this.onchangeSetDay}
                                                                            name='saturday' />
                                                                        {t("settingAutoMessage:Saturday")}
                                                                    </label>
                                                                    <label className='floatLeft p-2 text-red'>
                                                                        <input type={"checkbox"} className='mr-2'
                                                                            defaultChecked={this.state.sunday}
                                                                            onChange={this.onchangeSetDay}
                                                                            name='sunday' />
                                                                        {t("settingAutoMessage:Sunday")}
                                                                    </label>
                                                                </div>

                                                                <div style={{ clear: 'both' }} />

                                                                <div className="row mt-4">
                                                                    <div className="col text-right">
                                                                        <button type="button" className="btn bg-D9D9D9 text_4D4D4D m-2"
                                                                            style={{ height: 49, width: 100 }}
                                                                            onClick={() => this.props.history.push("/setting")}>
                                                                            {t("Allaction:cancel")}</button>
                                                                        <button type="submit" className="btn-primary mr-0 m-2"
                                                                            style={{ height: 49, width: 100 }}
                                                                        >{t("Allaction:save")}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="text-center" >
                                            <img src={process.env.PUBLIC_URL + '/images/typeIcon/sing/loading.gif'} />
                                        </div>
                                }


                            </div>
                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>
        )
    }
}

export default withRouter(SettingAutoMessageForm);
