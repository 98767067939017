import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Header from './headerLogin';
import './styles/index.scss'
import Footer from './footer';
import {graphql} from "babel-plugin-relay/macro";
import PasswordValidation from '../setting/settingJuristic/passwordValidity'
import {commitMutation} from "react-relay";
import environment from "../env/environment";
import Swal from "sweetalert2";
import { Translation } from "react-i18next";
const _ = require('lodash');
const $ = window.jQuery;

const mutation = graphql`
    mutation juristicResetPasswordMutation ($input: JuristicResetPasswordInput!) {
    juristicResetPassword (input: $input) {
        result
        reason
        messageTh
    }
}
`;

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:'',
            passwordOld:'',
            passwordNew:'',
            confirmPassword:'',
            isDuplicatePassword:false,
            isDuplicatePassOldNew:false,
            redirectTo:false,
            passwordResult: false,
            langauge: localStorage.getItem('language') === 'en' ? "en" : 'th',
        };
        
        this.showHidePassword = this.showHidePassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.changeLangauge = this.changeLangauge.bind(this)
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        });

        var passwordOld = document.getElementById("passwordOld").value;
        var passwordNew  = document.getElementById("passwordNew").value;
        var confirmPassword  = document.getElementById("confirmPassword").value;

        if(passwordNew !== confirmPassword){
            this.setState({isDuplicatePassword : true})
        }else{
            this.setState({isDuplicatePassword : false})
        }

        if(passwordOld && passwordNew){
            if(passwordNew === passwordOld){
                this.setState({isDuplicatePassOldNew : true})
            }else{
                this.setState({isDuplicatePassOldNew : false})
            }
        }


        if (event.target.name === 'passwordNew'){
            this.setState({passwordResult: event.target.dataset.passwordResult})
        }
    }

    onSubmit(e) {
        e.preventDefault();

        if (!this.state.passwordResult){
            $('#passwordNew').focus();
            return
        }

        if(this.state.isDuplicatePassword){
            $('#confirmPassword').focus();
            return 
        }

        if(this.state.isDuplicatePassOldNew){
            $('#passwordNew').focus();
            return 
        }

        if(this.state.passwordNew !== this.state.confirmPassword){
            this.setState({
                isDuplicatePassword : true,
            })
            window.location.reload()
        }else if(this.state.passwordNew === this.state.passwordOld){
            this.setState({
                isDuplicatePassOldNew : true,
            })
            window.location.reload()
        }else{
        let variables = {
             input: {
                username: this.state.username,
                passwordOld: this.state.passwordOld,
                passwordNew: this.state.passwordNew
             }
         };
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if(response.juristicResetPassword.result){
                                Swal.fire(
                                    `${this.state.langauge === 'th' ? 'คุณได้เปลี่ยนรหัสผ่านสำเร็จ!' :'your password was changed'}`, '', 'success'
                                )
                                .then(() => {
                                    this.setState({ redirectTo: '/login' });
                                });
                            }else{
                                Swal.fire(
                                    `${this.state.langauge === 'th' ? 
                                    'คุณเปลี่ยนรหัสผ่านไม่สำเร็จ!' :'You have failed to change your password'}`,
                                     `<h6> ${this.state.langauge === 'th' ? 'เนื่องจาก' + response.juristicResetPassword.messageTh : response.juristicResetPassword.reason}</h6>`, 'warning'
                                )
                            }
                        }
                    },
                )
        }

        
    }

    showHidePassword() {
        var passwordOld = document.getElementById("passwordOld");
        var passwordNew = document.getElementById("passwordNew");
        var confirmPassword = document.getElementById("confirmPassword");

        if(passwordOld.type === "password" && passwordNew.type === "password" && confirmPassword.type === "password" ) {
            passwordOld.type = "text";
            passwordNew.type = "text";
            confirmPassword.type = "text";
        } else {
            passwordOld.type = "password";
            passwordNew.type = "password";
            confirmPassword.type = "password";
        }
    }
    changeLangauge = (langBoon,lang) => {
        this.setState({ langauge: lang })
    }
    render() {
        if (this.state.redirectTo) {
            return <Redirect to={{ pathname: this.state.redirectTo }} />
        } else {
            return (
                <div className="height-100">
                    <Header lang={this.changeLangauge} />
                    <div className="container-fluid box">
                        <div className="row justify-content-center" >
                        <Translation>
                            { t => 
                            <div className="col-md-3" id='login-style' style={{marginTop:'0'}}>
                                    <div className="welcome-box">
                                        <div className="justify-content-center mt-3">
                                            <img src={process.env.PUBLIC_URL + '/images/logo/silverman.jpeg'} 
                                            style={{ width: "180px", height: "180px", justifyContent: 'center' }} alt="logoProject" /> 
                                            <h4>{this.state.langauge === 'th' ? 'เปลี่ยนรหัสผ่าน' : 'Reset Password' }</h4>
                                        </div>
                                    </div>

                                        <div>
                                            <form onSubmit={this.onSubmit}>
                                                <div className="form-group mt-3">
                                                    <h6>{this.state.langauge === 'th' ? 'ชื่อเข้าใช้งาน' : 'Username' }</h6>
                                                    <input type="text" className="form-control pl-4" id="userName"
                                                        autoComplete="username"
                                                        aria-describedby="emailHelp" placeholder={this.state.langauge === 'th' ? 'ชื่อเข้าใช้งาน' : 'Username' }
                                                        required
                                                        name="username" onChange={this.handleInputChange}
                                                    />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <h6>{this.state.langauge === 'th' ? 'รหัสผ่าน (เก่า)' : 'Password (Old)' }</h6>
                                                    <input className="form-control pl-4" id="passwordOld"
                                                        autoComplete="passwordOld"
                                                        aria-describedby="emailHelp" placeholder={this.state.langauge === 'th' ? 'รหัสผ่าน (เก่า)' : 'Password (Old)' } 
                                                        required
                                                        name="passwordOld" type="password" onChange={this.handleInputChange}
                                                    />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <h6>{this.state.langauge === 'th' ? 'รหัสผ่าน (ใหม่)' : 'Password (์New)' }</h6>
                                                    <PasswordValidation  
                                                        name="passwordNew"  
                                                        value={this.state.passwordNew ||''}
                                                        username={this.state.username}
                                                        params = {null}
                                                        handleInputChange={this.handleInputChange}
                                                        id="passwordNew"
                                                        langauge={this.state.langauge}
                                                        placeholder={this.state.langauge === 'th' ? 'รหัสผ่าน (ใหม่)' : 'Password (New)'}
                                                    />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <h6>{this.state.langauge === 'th' ? 'ยืนยันรหัสผ่าน' : 'Confirm Password'}</h6>
                                                    <input className="form-control pl-4" id="confirmPassword"
                                                        autoComplete="confirmPassword"
                                                        aria-describedby="emailHelp" placeholder={this.state.langauge === 'th' ? 'ยืนยันรหัสผ่าน' : 'Confirm Password'}
                                                        required
                                                        name="confirmPassword" type="password" onChange={this.handleInputChange}
                                                    />
                                                    {
                                                      this.state.isDuplicatePassword ?  <h6 style={{color:'#DC3545'}}><small>
                                                        {this.state.langauge === 'th' ? 'กรุณาระบุรหัสผ่านให้ตรงกัน' : 'Please enter the same password'}
                                                    </small></h6> : null
                                                    }
                                                </div>
                                                <div style={{display:'inline-flex'}}>
                                                <input type="checkbox" onClick={this.showHidePassword}/> <h6> <small 
                                                style={{padding:'2px 5px 5px 5px',position:'relative',bottom:'-4px'}}>
                                                    {this.state.langauge === 'th' ? 'แสดงรหัสผ่าน' : 'Show Password' }</small></h6> 
                                                </div>
                                                <button type="submit" className="btn btn-primary col mt-2">{this.state.langauge === 'th' ? 'ตกลง' : 'Submit' }</button>
                                            </form>
                                        </div>
                            </div>
                             }
                            </Translation>
                        </div>
                        <div className="row justify-content-center mt-10">
                            <div className="col-md-10">
                                <Footer langauge={this.state.langauge} />
                            </div>
                        </div>
                    </div>

                </div>
          
            );
        }
    }
}

export default ResetPassword;