import React, { Component } from 'react'
import { format } from 'date-fns'
import { Translation } from 'react-i18next';
import { _ } from 'core-js';
import getNameResidential from '../../../libs/getNameResidential';
import thLocale from "date-fns/locale/th";
import numberWithComma from '../../../libs/numberWithComma';

export default class unknownReceivePaper extends Component {

  getStatusUnknownReceive(object){
    let text = '';

    switch (object.node.status) {
        case 'WAIT':
            text = 'Wating to clear';
            break;
        case 'CLEAR':
            text = 'Cleared';
            break;
        default:
            text = 'List of cancellation';
    }
    return <Translation>{t=><td className="text-center" style={{color:object.node.status === 'VOID' ? 'red' :'#000'}}>{t(`unknown_receive:${text}`)}</td>}</Translation>
  }

  getTotal(status){
    let amount_perpage = 0.0

    let totalALL; 
    if(status  === 'clear' || status  === 'wait'){
      totalALL =  _.filter(this.props.allUnknownReceive, n => n.node.status === `${status.toUpperCase()}`);
    }else{
      totalALL =  _.filter(this.props.allUnknownReceive, n => n.node.status !== "VOID");
    }

    totalALL.map(value => {
     amount_perpage += parseFloat(value.node.amount)
     return value
    })

    return amount_perpage
  }


  render() {

    let statusReport = ''
    switch (this.props.status) {
        case 'all':
          statusReport = 'ทั้งหมด';
          break;
        case 'wait':
          statusReport = 'ยังไม่เคลียร์';
          break;
        case 'clear':
          statusReport = 'เคลียร์แล้ว';
          break;          
        case 'void':
          statusReport = 'รายการที่ยกเลิก';
          break;          

        default:
          break;
    }
    // all, wait, clear, void

  
    return(
      
      this.props.allUnknownReceivePerPage.map((allUnknownReceiveArr, indexPage) => {
        return (
          <React.Fragment key={indexPage}>
            <div className="print-page-a4" id="unknownReceivePaper">
              <div className="subpage">
                <div className="head mb-5">
                  <div className="font-weight-bold">
                    <div>{this.props.selfProject.name}</div>
                    <div>เงินรับรอการตรวจสอบ</div>
                    <div>สถานะ:{" "}{statusReport}</div>                   
                    <div>{`ช่วงเวลา: ${this.props.startDate ? format(this.props.startDate, "DD/MM/YYYY") : "-"} ถึง ${this.props.endDate ? format(this.props.endDate, "DD/MM/YYYY") : "-"}`}</div>
                  </div>
                </div>
    
                <div className="unknownReceivePaperBody">
                  <table className="table table-bordered">
                    <thead>
                    <Translation>
                      {t=>
                      <tr>
                          <th className="text-center" width={100}>{t("unknown_receive:Document No.")}</th>
                          <th className="text-center">{t("unknown_receive:Date")}</th>
                          <th width={(this.props.status === "clear" || this.props.status === "void") ? 140 : 150}>{t("unknown_receive:Detail")}</th>
                          {this.props.status === 'clear' &&
                          <React.Fragment>
                              <th>{t("unknown_receive:Contact by")}</th>
                              <th>{t("unknown_receive:Refer")}</th>
                          </React.Fragment>
                          }
                          <th width={(this.props.status === "clear" || this.props.status === "void") ? 210 : 200}>{t("unknown_receive:Bank")}</th>
                          <th className="text-right">{t("unknown_receive:Amount")}</th>
                          {this.props.status === 'void' &&
                          <th className="text-center">{t("unknown_receive:Date of cancellation")}</th>
                          }
                          {this.props.status === 'all' &&
                          <th className="text-center">{t("unknown_receive:Status")}</th>
                          }
                          {this.props.status === 'void' &&
                          <th className="text-center" width={130}>{t("unknown_receive:Reason of cancellation")}</th>
                          }
                      </tr>}
                    </Translation>
                    </thead>
                    <tbody>
                      {_.map(allUnknownReceiveArr, (item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center" style={{color:item.node.status === 'VOID' ? 'red' :'#000'}}>{  item.node.docNumber}</td>
                            <td className="text-center" style={{color:item.node.status === 'VOID' ? 'red' :'#000'}}>{format(item.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                            <td style={{color:item.node.status === 'VOID' ? 'red' :'#000'}}>
                              {item.node.description.length >= 80 ? item.node.description.substring(0, 80)+'...' : item.node.description} 
                            </td>
                            {this.props.status === 'clear' &&
                            <React.Fragment>
                                <td>{item.node.contact.refNumber} {item.node.contact.name} {getNameResidential(item.node.contact.firstName, item.node.contact.lastName)}</td>
                                <td>{item.node.refNumber}</td>
                            </React.Fragment>
                            }
                            {item.node.bankAccount ?
                              <td style={{color:item.node.status === 'VOID' ? 'red' :'#000'}}>{item.node.bankAccount.bankName} {item.node.bankAccount.accountName} {item.node.bankAccount.accountNumber}</td>
                              :
                              <td style={{color:item.node.status === 'VOID' ? 'red' :'#000'}}>{item.node.receiveChannel}</td>
                             }
                            <td className="text-right" style={{color:item.node.status === 'VOID' ? 'red' :'#000'}}>{item.node.status !== 'VOID' ? numberWithComma(item.node.amount) : '-'}</td>
                            {this.props.status === 'all' && this.getStatusUnknownReceive(item)}
                            {this.props.status === 'void' &&
                                <td className="text-center" style={{color:item.node.status === 'VOID' ? 'red' :'#000'}}>{format(item.node.updated, 'DD/MM/YYYY', {locale: thLocale})}</td>
                            }
                            {this.props.status === 'void' &&
                            <td style={{color:item.node.status === 'VOID' ? 'red' :'#000'}}>{item.node.voidRemark || '-'}</td>
                            }
                          </tr>
                        )
                      })}
                      {
                        indexPage+1 === this.props.allUnknownReceivePerPage.length && this.props.status === 'all' &&
                        <tr>
                        <td className="text-center" colSpan={4}><strong>ยอดรวมทั้งหมด</strong></td>
                        <td className="text-right">{numberWithComma(this.getTotal(this.props.status))}</td>
                      </tr>
                      }
                      {
                        indexPage+1 === this.props.allUnknownReceivePerPage.length && this.props.status === 'wait' &&
                        <tr>
                        <td></td>
                        <td className="text-center" colSpan={3}><strong>ยอดรวมทั้งหมด</strong></td>
                        <td className="text-right">{numberWithComma(this.getTotal(this.props.status))}</td>
                      </tr>
                      }
                      {
                        indexPage+1 === this.props.allUnknownReceivePerPage.length && this.props.status === 'clear' &&
                        <tr>
                        <td className="text-center" colSpan={6}><strong>ยอดรวมทั้งหมด</strong></td>
                        <td className="text-right">{numberWithComma(this.getTotal(this.props.status))}</td>
                      </tr>
                      }
                      
                    </tbody>
                  </table>
                </div>
    
                <div className="footer">
                  <div className="row">
                    <div className="col-6 p-0"/>
                    <div className="col-6 p-0 d-flex align-items-center justify-content-between">
                      <div className="numberPage">{`${indexPage+1}/${this.props.allUnknownReceivePerPage.length}`}</div>
                      <div className="dateFooter">
                        ผู้พิมพ์ {this.props.myUser}<br/>
                        วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY")} เวลา {format(new Date(), "HH:mm น.")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      })
    )
  }
}
