import React, { Component } from 'react'
import _ from "lodash"
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import numberWithCommas from '../../../libs/numberWithComma';
import Pagination from '../../../libs/newPagination';
import i18next from 'i18next';

export default class tableWaitPO extends Component {

  checkType = (type) => {

    let valueResult = ""

    switch (type) {
      case "PRODUCT":
        valueResult = "สินค้า"
        break;
      case "SERVICE":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (งานทั่วไป)"
        break;
      case "SERVICE_CONTACT":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (สัญญาจ้าง)"
        break;
      case "ASSET":
        valueResult = "สินทรัพย์"
        break;
    }
    return valueResult
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="fede-up">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">
                        <input
                          type="checkbox"
                          checked={this.props.checkAll}
                          onChange={() => this.props.onCheckAll(this.props.dataManageOrderListWaitPO)}
                          />
                      </th>
                      <th>{i18next.t("manageOrderList:PRF Number")}</th>
                      <th>{i18next.t("manageOrderList:Date")}</th>
                      <th>{i18next.t("manageOrderList:Creditor/Trading Partner")}</th>
                      <th>{i18next.t("manageOrderList:Category")}</th>
                      <th>{i18next.t("manageOrderList:Type")}</th>
                      <th className="text-center">{i18next.t("manageOrderList:Amount (THB)")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(this.props.dataManageOrderListWaitPO, (item, indexItem) => {
                      return (
                        <tr key={indexItem}>
                          <td className="text-center">
                            <input 
                              type="checkbox" 
                              onChange={() => this.props.onCheck(item.id)} 
                              checked={this.props.selectedId.includes(item.id)}
                              />
                          </td>
                          <td>
                            <Link to={`/purchasing/manageOrder/updateFormManageOrder/${item.type}/wait_open/${item.id}`}>{item.docNumber}</Link>
                          </td>
                          <td>{format(item.approveDate, "DD/MM/YYYY")}</td>
                          <td>{item.creditor || "-"}</td>
                          <td>{item.catagory}</td>
                          <td>{this.checkType(item.type)}</td>
                          <td className="text-center">{numberWithCommas(item.total)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            changePage={this.props.changePage}
            first={this.props.first}
            last={this.props.last}
            totalCount={this.props.totalCount}
            />
        </div>
      </React.Fragment>
    )
  }
}
