/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateElectricMeterRecordInput = {|
  year?: ?string,
  meterStack?: ?any,
  clientMutationId?: ?string,
|};
export type electricMeterListRecordMutationVariables = {|
  input: UpdateElectricMeterRecordInput
|};
export type electricMeterListRecordMutationResponse = {|
  +updateElectricMeterRecord: ?{|
    +electricMeterList: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +month: number,
          +year: number,
          +meterValue: ?number,
        |}
      |}>
    |}
  |}
|};
export type electricMeterListRecordMutation = {|
  variables: electricMeterListRecordMutationVariables,
  response: electricMeterListRecordMutationResponse,
|};
*/


/*
mutation electricMeterListRecordMutation(
  $input: UpdateElectricMeterRecordInput!
) {
  updateElectricMeterRecord(input: $input) {
    electricMeterList {
      edges {
        node {
          id
          month
          year
          meterValue
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateElectricMeterRecordPayload",
    "kind": "LinkedField",
    "name": "updateElectricMeterRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ElectricMeterUnitNodeConnection",
        "kind": "LinkedField",
        "name": "electricMeterList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElectricMeterUnitNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ElectricMeterUnitNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "month",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "year",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "meterValue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "electricMeterListRecordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "electricMeterListRecordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "37a1b1fd641f9c44371e3c5edc550055",
    "id": null,
    "metadata": {},
    "name": "electricMeterListRecordMutation",
    "operationKind": "mutation",
    "text": "mutation electricMeterListRecordMutation(\n  $input: UpdateElectricMeterRecordInput!\n) {\n  updateElectricMeterRecord(input: $input) {\n    electricMeterList {\n      edges {\n        node {\n          id\n          month\n          year\n          meterValue\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48243d4a770f51782f6a7a1cf059b316';

module.exports = node;
