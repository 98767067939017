import React, { Component } from 'react'
import { format } from 'date-fns'

export default class headerAlert extends Component {

  boxBodyHeader = (status) => {
    let valueResult = ""
    let boxColor = ""
    let iconStart = ""
    let iconEnd = ""

    switch (status) {
      case "active":
        valueResult = "อยู่ในระหว่างการตรวจสอบการอนุมัติ ไม่สามารถแก้ไขข้อมูลได้ จนกว่าจะมีผู้อนุมัติ"
        boxColor = "boxYellow"
        iconStart = "iconAlert-Yellow"
        iconEnd = "close-Yellow"
        break;
      case "approve":
        valueResult = `อนุมัติแล้ว วันที่ ${format(this.props.approveDate, "DD/MM/YYYY")} โดย ${this.props.approver}`
        boxColor = "boxGreen"
        iconStart = "iconAlert-SuccessGreen"
        iconEnd = "close-Green"
        break;
      case "void":
        valueResult = `ถูกยกเลิกเมื่อวันที่ ${format(this.props.voidDate, "DD/MM/YYYY")} โดย ${this.props.voider} เหตุผล: ${this.props.voidRemark}`
        boxColor = "boxRed"
        iconStart = "iconAlert-ErrorRed"
        iconEnd = "close-Red"
        break;
    }

    return (
      <div className={`${boxColor}` + " bodyHeader"}>
        <div>
          <img 
            className="mr-2"
            src={process.env.PUBLIC_URL + `/images/iconAlerts/${iconStart}.png`} 
            alt={`${iconStart}`} />
          <span>{valueResult}</span>
        </div>
        <div>
          <img src={process.env.PUBLIC_URL + `/images/iconAlerts/${iconEnd}.png`} alt={`${iconEnd}`} onClick={this.props.handleCloseHeaderAlert}/>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div id="headerAlert">
        {this.boxBodyHeader(this.props.status)}
      </div>
    )
  }
}
