import React, { Component } from 'react';
import { format } from "date-fns";
import API from '../../api/silverGraund'
import Swal from 'sweetalert2';
import i18next from 'i18next';
class SilgruandreportPrint extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.state = {
            data: {
                "carTransactionId": 4,
                "img": "",
                "codeVisit": "XX-202201180004",
                "reportCode": "CP-2022010001",
                "fullnameVisit": "Pimpaka Omkaew",
                "idCard": "4444444444444",
                "idCardImage": "",
                "phone": "0877256685",
                "typePerson": "person",
                "typeRole": "temp",
                "reason": "ส่งอาหาร",
                "isPacking": false,
                "tool": "QR Code",
                "ceateBy": "silverman support",
                "status": "success",
                "startDate": "2019-01-22T00:00:00Z",
                "dateUsingSt": "22/01/2019",
                "timeUsingSt": "00:00",
                "endDate": "",
                "dateUsingEn": "",
                "timeUsingEn": "",
                "outDate": "2019-01-22T16:20:00Z",
                "dateCheckOut": "22/01/2019",
                "timeCheckOut ": "16:20",
                "parkingTime": 360,
                "etampTimer": 240,
                "overtime": 666,
                "toPayCal": "100.00",
                "createBy": "silverman support",
                "approveIn": true,
                "approveOut": true,
                "estampStatus": false,
                "tenant": "",
                "juristic": 1443,
                "homeId": 83314,
                "homeName": "1086/04",
                "totalPacking": "240.00",
                "methodPayment": "cash",
                "carId": 11978,
                "carRegistration": "1กก5525",
                "province": "สิงห์บุรี",
                "brandCar": "Civit",
                "versionCar": "sedan",
                "colorCar": "ดำ",
                "yearCar": "2012",
                "sizeCar": "7",
                "descriptionCar": "",
                "codeSticker": "",
                "calPacking": true,
                "file": [],
                "img": ''

            }
        }

    }

    componentWillMount() {
        this.getDataId()
    }

    getDataId() {
        let idCode = this.props.match.params.id
        API.getDataReportDetail(idCode).then((res) => {
            if (res.data.data) {
                this.setState({ data: res.data.data[0], allRow: res.data.allRow })
                // console.log(res.data.data);

            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }



    print() {
        window.print()
    }

    render() {
        return (
            <div id='silvermangrauadPrint'>

                <div className="barLogo" style={{ background: "#FFFFFF" }}>
                    <div className="logo" style={{ float: 'left' }}>
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="btn btn-primary" onClick={this.print} style={{ float: 'right' }}>
                        <img src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'} /> พิมพ์
                    </div>
                </div>

                <div style={{ background: '#F4F4F8', textAlign: 'center' }}>
                    <div className='row'>
                        <div className='mx-auto text-left divToPrint' style={{ width: '474px', background: '#FFF', marginTop: '35px', paddingLeft: '35px', paddingRight: '35px', marginBottom: '141px' }}>

                            <h2 className='text-primary-blue' style={{ marginTop: '50px' }}>Payment Details</h2>
                            <h6 className='h6'>{this.state.data.fullnameVisit}</h6>
                            <span className='label-300'> วัตถุประสงค์  :{this.state.data.reason}</span><br />
                            <span className='label-300'>บ้านเลขที่ / ห้องที่มาติดต่อ :{this.state.data.homeName}</span><br />
                            <h5 className='label-600'>{this.state.data.brandCar + ", " + this.state.data.colorCar}</h5>

                            <label className='label-blue-01 text-primary-blue'>
                                {
                                    this.state.data.typePerson === "tenant" ? "ผู้เช่า/ลูกบ้าน" :
                                        this.state.data.typePerson === "contact" ? "ติดต่อสำนักงานขาย  " :
                                            this.state.data.typePerson === "appointment" ? "ผู้ติดต่อนัดหมายล่วงหน้า" : "บุคคลทั่วไป"
                                }
                            </label> &nbsp;&nbsp;

                            {
                                this.state.data.overtime > 0 &&
                                <label className='label-pill text-reds'>เกินเวลา</label>
                            }

                            <div className='boxCarRegis border-grey-012 mx-auto text-center'>

                                {
                                    this.state.data.img !== "" && <img src={process.env.PUBLIC_URL + this.state.data.img} style={{height: "100%"}} />
                                }

                                {
                                    this.state.data.img === "" &&
                                    <React.Fragment>
                                        <h2 className='label-default labelCarRegis mx-auto'>
                                            {this.state.data.carRegistration}
                                        </h2>
                                        <label className='text-grey-05'>{this.state.data.province}</label>
                                    </React.Fragment>

                                }



                            </div>

                            <div className='row' style={{ marginTop: '20px' }}>
                                <div className='col-6'>
                                    <label style={{ color: '#808080' }}>เลขบัตรประชาชน</label><br />
                                    <h5 style={{ color: '#808080', fontSize: '17px' }}>
                                        {
                                            // this.state.data.idCard.substring(0, 1) + "-XXXX-XXXXX-" +
                                            // this.state.data.idCard.substring(10, 12) + "-" +
                                            // this.state.data.idCard.substring(12)
                                        }
                                    </h5>
                                </div>
                                <div className='col-6'>
                                    <label style={{ color: '#808080' }}>เบอร์โทรศัพท์</label><br />
                                    <h5 style={{ color: '#808080', fontSize: '18px' }}>
                                        {
                                            // this.state.data.phone !== "" &&
                                            // this.state.data.phone.substring(0, 3) + "-" +
                                            // this.state.data.phone.substring(3, 6) + "-" +
                                            // this.state.data.phone.substring(6)
                                            // ||
                                            // "ไม่ได้ระบุเบอร์โทรติดต่อ"
                                        }
                                    </h5>
                                </div>
                            </div>

                            <hr />

                            <div className='row' style={{ marginTop: '25px' }}>
                                <div className='col-5 text-left'>
                                    <label>เวลาเข้าจอด</label>
                                    <h4>{format(this.state.data.startDate, "HH:mm A")}</h4>
                                    <span>{format(this.state.data.startDate, "ddd,MMM D")}</span>
                                    {/* <h4>{format(this.state.data.dateUsingSt + "T" + this.state.data.timeUsingSt, "HH:mm A")}</h4> */}
                                    {/* <span>{format(this.state.data.dateUsingSt + "T" + this.state.data.timeUsingSt, "ddd,MMM D")}</span> */}
                                </div>
                                <div className='col-2'>
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconArrowRight.png'} style={{ height: '30px', marginTop: "30px" }} />
                                </div>
                                <div className='col-5 text-left'>
                                    <label>เวลาออก</label>
                                    <h4 style={{ color: '#d30000' }}>{format(this.state.data.outDate, "HH:mm A")}</h4>
                                    <span>{format(this.state.data.outDate, "ddd,MMM D")}</span>
                                    {/* <h4>{format(this.state.data.dateUsingEn + "T" + this.state.data.timeUsingEn, "HH:mm A")}</h4> */}
                                    {/* <span>{format(this.state.data.dateUsingEn + "T" + this.state.data.timeUsingEn, "ddd,MMM D")}</span> */}

                                </div>
                            </div>

                            <div style={{ marginTop: '42px', display: 'inline-flex' }}>
                                <div style={{ width: '254px' }}>
                                    <label className='greys-04'>ระยะเวลาที่เข้าจอด </label><br />
                                    <label className='greys-04'>จอดฟรี
                                        <span className='label-default' style={{ fontWeight: '600' }}>E-Stamp</span></label><br />
                                    <label className='greys-04'>ระยะเวลาเกิน </label><br />
                                    <label className='greys-04'>อัตราค่าจอดรถ </label><br />
                                </div>


                                <div style={{ width: '254px' }}>
                                    <label className='greys-044'>
                                        {
                                            this.state.data.parkingTime > 60 ?
                                                Math.floor(this.state.data.parkingTime / 60) + " ชั่วโมง " + this.state.data.parkingTime % 60 + " นาที" :
                                                this.state.data.parkingTime + " นาที"
                                        }
                                    </label><br />
                                    <label className='greys-044'>
                                        {
                                            this.state.data.etampTimer > 60 ? (parseInt(this.state.data.etampTimer) / 60) + " ชั่วโมง " :
                                                parseInt(this.state.data.etampTimer) + " นาที "
                                        }
                                    </label><br />
                                    <label className='greys-044'>
                                        {
                                            this.state.data.overtime > 60 ?
                                                Math.floor(this.state.data.overtime / 60) + " ชั่วโมง " + this.state.data.overtime % 60 + " นาที" :
                                                this.state.data.overtime + " นาที"
                                        }
                                    </label><br />
                                    <label className='greys-044'>{this.state.data.toPayCal} บาท/ชั่วโมง</label><br />
                                </div>

                            </div>
                            <br />

                            <label className='greys-05'>
                                หมายเหตุ :  จอดเกิน {parseInt(this.state.data.toCalOverTime || 0)} นาทีขึ้นไป คิดเป็น 1 ชั่วโมง
                            </label>
                            <hr />

                            <div style={{ marginTop: '20px', marginBottom: "20px", display: 'inline-flex' }}>
                                <div style={{ width: '254px' }}>
                                    <h4>รวมค่าที่จอดรถ</h4>
                                    {
                                        this.state.data.methodPayment !== "" ?
                                            <React.Fragment>
                                                <label>ช่องทางชำระ  :  {
                                                    this.state.data.methodPayment === 'cash' ? "เงินสด" : 'Scan QR Code'
                                                }</label>
                                            </React.Fragment>

                                            : ""
                                    }
                                </div>
                                <div style={{ width: '254px' }}>
                                    <h2>{parseFloat(this.state.data.totalPacking || 0)} บาท</h2>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default SilgruandreportPrint;
