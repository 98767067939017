import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../components/noMatch";
import WrapperInvoice from "./invoice/wrapperInvoice";
import Survey from "./survey/surveyQuery";
import SurveyAll from "./survey/surveyAll";
import WrapperReceive from "./receive/wrapperReceive";
import GenCodeA4 from "./gen_code/genCodeA4";
import GenParcelA4 from "./gen_code/genParcelA4";
import GenCodeA5 from "./gen_code/genCodeA5";
import WrapperDailyJournal from "./daily_journal/wrapperDailyJournal";
import WrapperWithholdingTax from "./witholding_tax/wrapperWithholdingTax";
import WrapperReceiveAll from "./receive/wrapperReceiveAll";
import WrapperReceiptDepositAll from './receipt_deposit/wrapperReceiptDepositAll';
import WrapperOtherReceiverAll from './other_receiver/wrapperOtherReceiverAll'
import WrapperCashDeposit from "./cash_deposit/wrapperCashDeposit";
import WrapperPurchase from "./purchase/wrapperPurchase";
import WrapperPay from "./pay/wrapperPay";
import WrapperPettyCashAll from "./petty_cash/wrapperPettyCashAll";
import WrapperPettyCash from "./petty_cash/wrapperPettyCash";
import WrapperPettyCashPayment from "./petty_cash/wrapperPettyCashPayment";
import WrapperInvoiceAll from "./invoice/wrapperInvoiceAll";
import WrapperInvoiceGetAll from "./invoice/wrapperInvoiceGetAll";
import WrapperOtherReceiver from "./other_receiver/wrapperOtherReceiver";
import WrapperOtherExpense from "./other_expense/wrapperOtherExpense";
import WrapperReceiptDeposit from "./receipt_deposit/wrapperReceiptDeposit";
import WrapperGuaranteeMoneyReceived from "./guarantee_money_received/wrapperGuaranteeMoneyReceived";
import WrapperCreditNote from "./credit_note/wrapperCreditNote";
import WrapperAcceptCreditNote from "./accept_credit_note/wrapperAcceptCreditNote";
import WrapperContact from "./contact/contact";
import WrapperAttachmentWithholdingTax53 from "./witholding_tax/wrapperAttachmentWithholdingTax53";
import WrapperAttachmentWithholdingTax3 from "./witholding_tax/wrapperAttachmentWithholdingTax3";
import WrapperFilingFormWithholdingTax53 from "./witholding_tax/wrapperFilingFormWithholdingTax53";
import WrapperFilingFormWithholdingTax3 from "./witholding_tax/wrapperFilingFormWithholdingTax3";
import WrapperFixRequest from "./fix_request/fix_request";
import WrapperClearGuaranteeMoneyReceived from "./guarantee_money_received/wrapperClearGuaranteeMoneyReceived"
import WrapperPrepaid from "./prepaid/wrapperPrepaid";
import BankTransfer from "./bank_transfer/bankTransfer";
import WrapperDailyJournalAll from "./daily_journal/wrapperDailyJournalAll";
import WrapperCashDepositPrint from "./cash_deposit/wrapperCashDepositPrint";
import WrapperPaymentGateway from "./payment_gateway/wrapperPaymentGateway";
import wrapperAdvance from "./advance/wrapperAdvance"
import WrapperCollectionLetter from './collection_letter/wrapperCollectionLetter';
import WrapperBudgetLastYearCompareWithYear from './budgetLastYearCompareWithYear/wrapperBudgetLastYearCompareWithYear';
import WrapperBudgetYearCompareAverageYear from './budgetYearCompareAverageYear/wrapperBudgetYearCompareAverageYear';
import WrapperBudgetLastYearCompareWithMonth from './budgetLastYearCompareWithMonth/wrapperBudgetLastYearCompareWithMonth';
import WrapperCollectionAll from './collection_letter/wrapperCollectionAll';
import WrapperManageRequest from './manageRequest/wrapperManageRequest';
import WrapperManageOrder from './manageOrder/wrapperManageOrder';
import WrapperStockMenagement from './stockMenagement/wrapperStockMenagement';
import ContactAll from './contact/contactAll';
import WrapperContactAllSelect from './contact/wrapperContactAllSelect';
import WrapperReceiveSiriraj from './receive/wrapperReciveForEmailSiriraj/wrapperReceiveSirirajData';

class Account extends Component {

    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.url}/invoice/all/`} component={WrapperInvoiceAll}/>
                <Route path={`${this.props.match.url}/invoice/getall/`} component={WrapperInvoiceGetAll}/>
                <Route path={`${this.props.match.url}/invoice/:id?`} component={WrapperInvoice}/>
                <Route path={`${this.props.match.url}/survey/:type/:id/:residential?/:size?`} component={Survey}/>
                <Route path={`${this.props.match.url}/survey-all/:id/`} component={SurveyAll}/>
                <Route path={`${this.props.match.url}/receive/all/`} component={WrapperReceiveAll}/>
                <Route path={`${this.props.match.url}/receipt_deposit/all/`} component={WrapperReceiptDepositAll}/>
                <Route path={`${this.props.match.url}/receive/:id?/:ref_transaction?`} component={WrapperReceive}/>
                <Route path={`${this.props.match.url}/other-receiver/:id?/:ref_transaction?`} component={WrapperOtherReceiver}/>
                <Route path={`${this.props.match.url}/other_receiver/all/`} component={WrapperOtherReceiverAll}/>
                <Route path={`${this.props.match.url}/receipt-deposit/:id?`} component={WrapperReceiptDeposit}/>
                <Route path={`${this.props.match.url}/purchase/:id?`} component={WrapperPurchase}/>
                <Route path={`${this.props.match.url}/pay/:id?/:ref_transaction?`} component={WrapperPay}/>
                <Route path={`${this.props.match.url}/advance/:id?/:ref_transaction?`} component={wrapperAdvance}/>
                <Route path={`${this.props.match.url}/gen-code-a4/:type`} component={GenCodeA4}/>
                <Route path={`${this.props.match.url}/gen-parcel-a4/`} component={GenParcelA4}/>
                <Route path={`${this.props.match.url}/gen-code-a5/:status/:id?`} component={GenCodeA5}/>
                <Route path={`${this.props.match.url}/withholding-tax/:id?`} component={WrapperWithholdingTax}/>
                <Route path={`${this.props.match.url}/attachment-withholding-tax-pnd-53/:type`} component={WrapperAttachmentWithholdingTax53}/>
                <Route path={`${this.props.match.url}/attachment-withholding-tax-pnd-3/:type`} component={WrapperAttachmentWithholdingTax3}/>
                <Route path={`${this.props.match.url}/filing-form-withholding-tax-pnd-53/:type`} component={WrapperFilingFormWithholdingTax53}/>
                <Route path={`${this.props.match.url}/filing-form-withholding-tax-pnd-3/:type`} component={WrapperFilingFormWithholdingTax3}/>
                <Route path={`${this.props.match.url}/daily-journal/:ref_transaction?/:id?`} component={WrapperDailyJournal}/>
                <Route path={`${this.props.match.url}/daily-journal/all/`} component={WrapperDailyJournalAll}/>
                <Route path={`${this.props.match.url}/cash-deposit/`} component={WrapperCashDeposit}/>
                <Route path={`${this.props.match.url}/cash-deposit-print/`} component={WrapperCashDepositPrint}/>
                <Route path={`${this.props.match.url}/payment-gateway-print/:id`} component={WrapperPaymentGateway}/>
                <Route path={`${this.props.match.url}/petty-cash/all/`} component={WrapperPettyCashAll}/>
                <Route path={`${this.props.match.url}/petty-cash/:id`} component={WrapperPettyCash}/>
                <Route path={`${this.props.match.url}/petty-cash-payment/:id`} component={WrapperPettyCashPayment}/>
                <Route path={`${this.props.match.url}/guarantee-money-received/:id`} component={WrapperGuaranteeMoneyReceived}/>
                <Route path={`${this.props.match.url}/credit-note/:id`} component={WrapperCreditNote}/>
                <Route path={`${this.props.match.url}/other-expense/:id?/:ref_transaction?`} component={WrapperOtherExpense}/>
                <Route path={`${this.props.match.url}/contact/all/:typeOfContact/:typeOfPayment?`} component={WrapperContactAllSelect}/>
                <Route path={`${this.props.match.url}/contact/all`} component={ContactAll}/>
                <Route path={`${this.props.match.url}/contact/:id?`} component={WrapperContact}/>
                <Route path={`${this.props.match.url}/fix-request/:id?`} component={WrapperFixRequest}/>
                <Route path={`${this.props.match.url}/clear-guarantee-money-received/:id?`} component={WrapperClearGuaranteeMoneyReceived}/>
                <Route path={`${this.props.match.url}/prepaid/:id?/:ref_transaction?`} component={WrapperPrepaid}/>
                <Route path={`${this.props.match.url}/bank-transfer/:id?`} component={BankTransfer}/>
                <Route path={`${this.props.match.url}/acceptcredit/:id?`} component={WrapperAcceptCreditNote}/>
                <Route path={`${this.props.match.url}/collection-letter/:numberOfTimes/:id?`} component={WrapperCollectionLetter}/>
                <Route path={`${this.props.match.url}/budgetDraftLastYearCompareWithYear/:id`} component={WrapperBudgetLastYearCompareWithYear}/>
                <Route path={`${this.props.match.url}/budgetDraftLastYearCompareAverageYear/:id`} component={WrapperBudgetYearCompareAverageYear}/>
                <Route path={`${this.props.match.url}/budgetDraftLastYearCompareWithMonth/:id`} component={WrapperBudgetLastYearCompareWithMonth}/>
                <Route path={`${this.props.match.url}/collection-letter-print/all/:numberOfTimes`} component={WrapperCollectionAll}/>
                <Route path={`${this.props.match.url}/manageRequest/:id?`} component={WrapperManageRequest}/>
                <Route path={`${this.props.match.url}/manageOrder/:id?`} component={WrapperManageOrder}/>
                <Route path={`${this.props.match.url}/stockMenagement/:id`} component={WrapperStockMenagement}/>                
                <Route path={`${this.props.match.url}/receiveSiriraj/:id`} component={WrapperReceiveSiriraj}/>

                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Account;
