import { format } from "date-fns";
import th from "date-fns/locale/th";
import React from "react";
import getNameResidential from "../libs/getNameResidential";

export function formatDateISO(date) {
  return date ? format(date, "YYYY-MM-DD") : null;
}

export function formatDateLocale(date) {
  return format(date, "DD/MM/YYYY", {
    locale: th,
  });
}

export function convertToTimeZone(date, timeZone) {
  return new Date(date).toLocaleString("en-US", { timeZone: timeZone });
}

export function getShortText(text, length = 80) {
  return text.length >= length ? text.substring(0, length) + "..." : text;
}

export function decimal(value) {
  return parseFloat(value.toFixed(2));
}

export function numberWithCommas(
  amount,
  blank_sign = "-",
  digit = false,
  number_only = false
) {
  let formatter = new Intl.NumberFormat("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (digit || digit === 0) {
    formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: digit,
      maximumFractionDigits: 2,
    });
  }

  if (
    amount !== undefined &&
    !isNaN(amount) &&
    amount !== 0 &&
    amount !== null
  ) {
    if (amount < 0) {
      if (!number_only) {
        amount = amount * -1;
        return (
          <strong className="text-red">({formatter.format(amount)})</strong>
        );
      }
      if (number_only) {
        return formatter.format(amount);
      }
    } else {
      return formatter.format(amount);
    }
  } else {
    return blank_sign;
  }
}

export function getContactName(contact) {
  return contact.typeOfContact === "RESIDENTIAL"
    ? contact.refNumber +
        " " +
        getNameResidential(contact.firstName, contact.lastName)
    : contact.refNumber + " " + contact.name;
}

export function getAddress(contact) {
  if (!contact) {
    return "";
  }
  const {
    registeredAddress,
    registeredDistrict,
    registeredCity,
    registeredProvince,
    registeredPostalCode,
  } = contact;

  let address = "";
  if (registeredAddress) {
    address += registeredAddress;
  }
  if (registeredDistrict) {
    address += ` ตำบล${registeredDistrict}`;
  }
  if (registeredCity) {
    address += ` อำเภอ${registeredCity}`;
  }
  if (registeredProvince) {
    address += ` จังหวัด${registeredProvince}`;
  }
  if (registeredPostalCode) {
    address += ` ${registeredPostalCode}`;
  }
  return address;
}

export function calculateAmount(price, quantity, vatRate = 0, whtRate = 0) {
  const preTaxAmount = decimal(price * quantity);
  const vatAmount = decimal((preTaxAmount * vatRate) / 100);
  const whtAmount = decimal((preTaxAmount + vatAmount) * (whtRate / 100));
  const amount = decimal(preTaxAmount + vatAmount);

  return [preTaxAmount, vatAmount, whtAmount, amount];
}
