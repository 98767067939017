/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateClearAdvanceInput = {|
  status?: ?string,
  voidRemark?: ?string,
  clientMutationId?: ?string,
|};
export type clearAdvanceChangeStatusMutationVariables = {|
  input: UpdateClearAdvanceInput
|};
export type clearAdvanceChangeStatusMutationResponse = {|
  +updateClearAdvance: ?{|
    +ok: ?boolean,
    +warningText: ?string,
    +newClearAdvance: ?{|
      +id: string
    |},
  |}
|};
export type clearAdvanceChangeStatusMutation = {|
  variables: clearAdvanceChangeStatusMutationVariables,
  response: clearAdvanceChangeStatusMutationResponse,
|};
*/


/*
mutation clearAdvanceChangeStatusMutation(
  $input: UpdateClearAdvanceInput!
) {
  updateClearAdvance(input: $input) {
    ok
    warningText
    newClearAdvance {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateClearAdvancePayload",
    "kind": "LinkedField",
    "name": "updateClearAdvance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClearAdvanceNode",
        "kind": "LinkedField",
        "name": "newClearAdvance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clearAdvanceChangeStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clearAdvanceChangeStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "43c6d71985e972cc39c27115e5fbbdc1",
    "id": null,
    "metadata": {},
    "name": "clearAdvanceChangeStatusMutation",
    "operationKind": "mutation",
    "text": "mutation clearAdvanceChangeStatusMutation(\n  $input: UpdateClearAdvanceInput!\n) {\n  updateClearAdvance(input: $input) {\n    ok\n    warningText\n    newClearAdvance {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46d7705b3243fe1f3f8885f01a122bda';

module.exports = node;
