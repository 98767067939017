import React from "react";
import ComponentPagination from "../../libs/componentPagination";
import Wrapper from "../../components/wrapper";
import Header from '../../components/header/index'
import Sidebar from '../../components/sidebar/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import SettingDocumentPaidLawyerDetailTable from "./settingDocumentPaidLawyerDetailTable";
import { Redirect } from 'react-router';
import Swal from "sweetalert2";
import format from "date-fns/format";
import { commitMutation } from "relay-runtime";
import upload from "../../libs/upload";
import _ from "lodash"
import './settingDocumentPaidLawyer.scss'
import i18next from "i18next";


const query = graphql`
 query settingDocumentPaidLawyerDetailQuery(
    $id:ID
) {
  allReceiveCollectionLawFirm(id:$id) {
          edges{
              node{
                id
                docNumber
                issuedDate
                status
                type
                total
                paid
                typePaid
                lawyerApproval

                voidNote

                fileName
                fileUpload

                billingName
                billingUpload

                receiptName
                receiptUpload

                language

                creator
                creatorDelete
                creatorApproval

                receiveCollectionLawFirmRecord{
                    edges{
                        node{
                            id
                            status
                            type
                            total
                            paid
                            creator
                            collectionLawFirm{
                                id
                                docNumber
                                totalDebt
                                totalFine
                                contact{
                                    id
                                    refNumber
                                    name
                                    firstName
                                    lastName
                                }
                                purchaseRecordGroupLawFirm{
                                    totalCount
                                    edges{
                                        node{
                                            purchaseRecordGroup{
                                                id
                                                docNumber
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
    summaryReceiveCollectionLawFirm
  }
`;

const mutation = graphql`
mutation settingDocumentPaidLawyerDetailMutation ($input: AllowReceiveCollectionLawFirmInput!) {
    allowReceiveCollectionLawFirm(input:$input){
        ok
        massage
    }
}`;

class SettingDocumentPaidLawyerDetail extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      checkAll: false,
      dataCollection: [],
      contact:[],
      billingUpload: [],
      receiptUpload: [],
      referUpload:[],
      remark: "",
      fileName:'',
      fileUpload:'',
      billingName:'',
      billingUploadData:'',
      receiptName:'',
      receiptUploadData:'',
      redirect:false,
      remarkVoid:''
    }
  }

  componentDidMount(){
    this.fetchQuery();
  }

  fetchQuery = async() => {
    fetchQuery(environment, query,{id:this.props.match.params.id}).then((response) => {
        if(response.allReceiveCollectionLawFirm) {
           this.setState({
            dataCollection: response.allReceiveCollectionLawFirm.edges[0].node,
            fileName:response.allReceiveCollectionLawFirm.edges[0].node.fileName,
            fileUpload:response.allReceiveCollectionLawFirm.edges[0].node.fileUpload,
            billingName:response.allReceiveCollectionLawFirm.edges[0].node.billingName,
            billingUploadData:response.allReceiveCollectionLawFirm.edges[0].node.billingUpload,
            receiptName:response.allReceiveCollectionLawFirm.edges[0].node.receiptName,
            receiptUploadData:response.allReceiveCollectionLawFirm.edges[0].node.receiptUpload,
            contact : response.allReceiveCollectionLawFirm.edges[0].node.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.contact,
            remark: response.allReceiveCollectionLawFirm.edges[0].node.voidNote || '',
            remarkVoid: response.allReceiveCollectionLawFirm.edges[0].node.voidNote || ''
           })
        }
    });
  }

  onSubmit = async(e, approve) => {
 
    e.preventDefault()

       let input = {
            "input":{
               "jsonObject":{
                   "ReceiveAccept":[
                       {
                         "Id": this.state.dataCollection.id,
                         "Accept": approve,
                         "voidNote": this.state.remark || null,
                         "fileName": this.state.referUpload[0]?.fileName || null,
                         "fileUpload": this.state.referUpload[0]?.fileUpload || null,
                         "billingName": this.state.billingUpload[0]?.fileName || null,
                         "billingUpload": this.state.billingUpload[0]?.fileUpload || null,
                         "receiptName": this.state.receiptUpload[0]?.fileName || null,
                         "receiptUpload": this.state.receiptUpload[0]?.fileUpload || null,
                       },
                   ]                  
                 }                  
             }
       }

      let receiveCollectionLawFirmRecordLength = this.state.dataCollection.receiveCollectionLawFirmRecord && this.state.dataCollection.receiveCollectionLawFirmRecord.edges.length;

      Swal.fire({
        imageUrl: '/images/iconAlerts/questionBlue.png',
        text: `ยืนยันการทำรายการอนุมัติการรับชำระค่าบริการออกหนังสือติดตามหนี้จำนวน ${receiveCollectionLawFirmRecordLength} รายการ`,
        showCancelButton: true,
        confirmButtonColor: '#1567B4',
        confirmButtonText: i18next.t("Allaction:Confirm"),
        cancelButtonText: i18next.t("Allaction:cancel"),
      }).then((result) => {
        if (result.value) {
          this.setState({ loading: true }); 
          commitMutation(environment, {
            mutation,
            variables: input,
            onCompleted: (response) => {
              this.setState({ loading: false });
              if (response.allowReceiveCollectionLawFirm?.ok) {
                Swal.fire(i18next.t("Allaction:successcompleate"), 'สำเร็จ', 'success').then(() => {
                  // Handle success case
                  this.setState({
                    redirect:true
                  })
                });
              } else {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() => {
                  // Handle failure case
                });
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
            },
          });
        }
      });      
  }

  callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload("/clf" + path, file);
      resolve(res);
    });
  };

  handleInputFile = (e, upload) => {
    let file = e.currentTarget.files[0];
    let uploadFile = [];
    if (file) {
        let totalBytes = file.size;
         if (totalBytes >  10485760) {
            Swal.fire('ขนาดไฟล์ใหญ่เกินไป!', 'กรุณาเลือกไฟล์ใหม่', 'warning');
            return
        } else {
            if(upload === 'billing') {
                   this.callUpload('', file).then((data) => {
                      uploadFile.push({
                            fileUpload : data.key.replace("silverman-storage/private/", ""), 
                            fileName :  file.name,
                            fileShow: data.location
                      })
                      this.setState({
                        billingUpload : uploadFile
                      })
                  });
            }
            if(upload === 'receipt') {
              this.callUpload('', file).then((data) => {
                uploadFile.push({
                      fileUpload : data.key.replace("silverman-storage/private/", ""), 
                      fileName :  file.name,
                      fileShow: data.location
                  })
                  this.setState({
                    receiptUpload : uploadFile
                  })
              });
            }
            if(upload === 'referUpload'){
              this.callUpload('', file).then((data) => {
                uploadFile.push({
                      fileUpload : data.key.replace("silverman-storage/private/", ""), 
                      fileName :  file.name,
                      fileShow: data.location
                  })
                  this.setState({
                    referUpload : uploadFile
                  })
              });
            }
     
        }
    }
  }

  handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    this.setState({ remark: newRemark });
  }

  calTotal(){
    let sumTotal = 0
    this.state.dataCollection?.receiveCollectionLawFirmRecord &&
    _.forEach(this.state.dataCollection?.receiveCollectionLawFirmRecord?.edges, (n) => {
       sumTotal += n.node.collectionLawFirm.totalFine
    })

    return sumTotal
   
  }

  render() {
    let receiveCollectionLawFirmRecordLength = this.state.dataCollection.receiveCollectionLawFirmRecord && this.state.dataCollection.receiveCollectionLawFirmRecord.edges.length;
    if (this.state.redirect) {
      return <Redirect to='/setting/lawyer-summary-report/paid'/>
    }

   let feeRateThai = this.state.dataCollection?.receiveCollectionLawFirmRecord &&
    _.filter(this.state.dataCollection?.receiveCollectionLawFirmRecord?.edges, (n) => {
      return n.node.collectionLawFirm.totalFine === 150;
    }).length;

    let feeRateOther = this.state.dataCollection?.receiveCollectionLawFirmRecord &&
    _.filter(this.state.dataCollection?.receiveCollectionLawFirmRecord?.edges, (n) => {
      return n.node.collectionLawFirm.totalFine >= 200;
    }).length;


    return (
        <Wrapper>
        <Header/>
        <Sidebar/>
        <WrapperContent>
            <React.Fragment>
                <Translation>
                    {
                        t => 
                        <div className="container-fluid box" id="lawyer-summary-report-detail">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/setting/lawyer-summary-report/all">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back"/>
                                        </Link>
                                        <Translation>
                                            {
                                                t => <span>{t('collectionLawFirm:Services Summary')}</span>
                                            }
                                        </Translation>                                           
                                    </h3>
                                </div>
                            </div>
                            <div className="content-inner">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                        {/* <div className="col">
                                            <p><strong>ลูกค้า</strong></p>
                                            <p>
                                            {
                                              this.state.contact.firstName +" "+this.state.contact.lastName || '-'
                                            }
                                            </p>
                                        </div> */}
                                        <div className="col">
                                            <p><strong>{t('collectionLawFirm:Document No.')}</strong></p>
                                            <p>{this.state.dataCollection.docNumber || '-'}</p>
                                        </div>
                                        <div className="col">
                                            <p><strong>{t('receiveSelectInvoice:Date')}</strong></p>
                                            <p>{format(this.state.dataCollection.issuedDate,'DD-MM-YYYY') || '-'}</p>
                                        </div>
                                        <div className="col">
                                            <p><strong>{t('collectionLawFirm:Creator')}</strong></p>
                                            <p>{this.state.dataCollection.creator || '-'}</p>
                                        </div>
                                        </div>
                                        {this.state.dataCollection.receiveCollectionLawFirmRecord &&
                                            <SettingDocumentPaidLawyerDetailTable 
                                                dataCollection={this.state.dataCollection}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="row mt-5" id="document-paid-summary">
                                  <div className="col-8">
                                    <p>{t('collectionLawFirm:Proof of payment')}</p>
                                    {
                                      this.state.fileUpload ? 
                                      <>
                                       <a href={this.state.fileUpload} target="_blank">{this.state.fileName}</a>
                                      </>
                                       : 
                                      <>
                                      <label className="mr-2 uploadBtn" htmlFor="referUpload">
                                       {this.state.referUpload.length >= 1  ? `+ ${i18next.t("collectionLawFirm:New Attach")}` : `+ ${i18next.t("collectionLawFirm:Attach")}`} </label>
                                      <input type="file" id="referUpload" 
                                                        accept="image/*" value=""
                                                        hidden={true}
                                                        onChange={(e) => this.handleInputFile(e, 'referUpload')}/>   
                                      <div>
                                        {
                                          this.state.referUpload && _.map(this.state.referUpload, (budget, index) =>{
                                            return(
                                              <img src={budget.fileShow} style={{width:'20%'}}/>
                                            )
                                          })
                                        }
                                      </div>  
                                      </>
                                    }
                                    
                                    <div className='mt-4'>
                                      <p>{t('collectionLawFirm:Invoice Upload')}</p>
                                      {
                                         this.state.billingUploadData ? 
                                         <>
                                          <a href={this.state.billingUploadData} >{this.state.billingName}</a>
                                         </>
                                          : <>
                                           <label className="mr-2 uploadBtn" htmlFor="uploadBilling">
                                           {this.state.billingUpload.length >= 1  ? `+ ${i18next.t("collectionLawFirm:Invoice Upload New")}` : `+ ${i18next.t("collectionLawFirm:Invoice Upload")}`} </label>
                                            <input type="file" id="uploadBilling" 
                                                accept="application/pdf, image/*" value=""
                                                hidden={true}
                                                onChange={(e) => this.handleInputFile(e, 'billing')}
                                            />    
                                            {this.state.billingUpload && _.map(this.state.billingUpload, (budget, index) =>{
                                                return(
                                                  <a href={budget.fileShow} target="_blank">{budget.fileName}</a>
                                                )
                                              })
                                            }
                                          </>
                                      }
                                    </div>      
                                    <div className='mt-4'>
                                      <p>{t('collectionLawFirm:Receipt Upload')}</p>
                                      {
                                          this.state.receiptUploadData ? 
                                          <>
                                           <a href={this.state.receiptUploadData} >{this.state.receiptName}</a>
                                          </>
                                           : 
                                           <>
                                            <label className="mr-2 uploadBtn" htmlFor="uploadReceive">
                                            {this.state.receiptUpload.length >= 1  ? `+ ${i18next.t("collectionLawFirm:Receipt Upload New")}` : `+ ${i18next.t("collectionLawFirm:Receipt Upload")}`} </label>
                                            <input type="file" id="uploadReceive" 
                                                accept="application/pdf, image/*" value=""
                                                hidden={true}
                                                onChange={(e) => this.handleInputFile(e, 'receipt')}
                                            />  
                                            {
                                             this.state.receiptUpload && _.map(this.state.receiptUpload, (budget, index) =>{
                                                return(
                                                  <a href={budget.fileShow} target="_blank">{budget.fileName}</a>
                                                )
                                              })
                                            }  
                                           </>
                                      }       
                                    </div>                               
                                    <div className='mt-4'>
                                        <p>{t('settingPeriodLock:Remark')}</p>
                                        <textarea className="form-control" rows="3"
                                                    value={this.state.remark}
                                                    name="remark"
                                                    id="remark"
                                                    onChange={this.handleRemarkChange}
                                                    maxLength={800}
                                                    style={{width:'50%'}}
                                                    disabled={this.state.remarkVoid ? true : false}
                                        />                
                                    </div>
                                  </div>
                                  <div className="col-4">
                                      <div className="box-summary">
                                          {
                                            feeRateThai >= 1 && <div className='d-flex justify-content-between'>
                                            <p>{t('collectionLawFirm:Amount of money per case for Thai people')}</p>
                                            <p>150.00</p>
                                            </div>
                                          }
                                          {
                                            feeRateOther >= 1 && 
                                            <>
                                             <hr/>
                                            <div className='d-flex justify-content-between'>
                                                 <p>{t('collectionLawFirm:Amount per case for foreign')}</p>
                                                 <p>200.00</p>
                                             </div>
                                            </>
                                          }
                                         
                                          <hr/>
                                          <div className='d-flex justify-content-between'>
                                              <p>{t('collectionLawFirm:Total case')}</p>
                                              <p>{receiveCollectionLawFirmRecordLength || '-'}</p>
                                          </div>
                                          <hr/>
                                          <div className='d-flex justify-content-between'>
                                              <p><b>{t('purchaseRequisition:Total Amount')}</b></p>
                                              <p>{this.calTotal()}</p>
                                          </div>
                                      </div>

                                  </div>
                                  {
                                    this.state.dataCollection.status !== 'PAID' && 
                                    <div className='d-flex justify-content-end mt-4 mr-4' style={{width: "100%"}}>
                                     
                                        <Link to={'/setting/lawyer-summary-report/wait'} style={{ textDecoration: 'none' }}>
                                        <button type="button" className='mr-2 rejectBtn'> {t('collectionLawFirm:Unapproved')}</button>
                                        </Link>
                                       
                                        <button type="button" className='approveBtn' onClick={(e)=>this.onSubmit(e, "True")}>{t('collectionLawFirm:Approved')}</button>
                                    </div>
                                  }
                                </div>
                            </div>
                        </div>
                    }
                </Translation>
            </React.Fragment>
        </WrapperContent>
    </Wrapper>
    );
  }
}

export default SettingDocumentPaidLawyerDetail;
