import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import { QueryRenderer } from "react-relay";
import SearchSelect from "../../../libs/searchSelect";
import i18next from "i18next";

const query = graphql`
  query productServicePayableListQuery {
    productViewer {
      allProduct(type_In: "product, expense", isActive: true) {
        edges {
          node {
            id
            name
            productCode
          }
        }
      }
    }
    viewer {
      allChartOfAccount(codeList: ["1210","1151","1154","2121","2122","2123","2124","2125","2126"]) {
        edges {
          node {
            id
            chartOfAccountCode
            name
          }
        }
      }
    }
  }
`;

class ProductServicePayableList extends React.Component {
  render() {
    return (
      <QueryRenderer
        environment={environment}
        query={query}
        variables={{}}
        render={({ error, props }) => {
          if (props) {
            let product_list = [];
            product_list = [...props.productViewer.allProduct.edges];
            props.viewer.allChartOfAccount.edges.forEach((chart_of_account) => {
              let chart_object = {
                id: chart_of_account.node.id,
                name: chart_of_account.node.name,
                productCode: chart_of_account.node.chartOfAccountCode,
              };
              product_list = [...product_list, { node: { ...chart_object } }];
            });
            return (
              <div className="col-md-4 border-right">
                <div className="form-group">
                  <label>{i18next.t("FilterPayable:Product and Service Types")}</label>
                  <SearchSelect
                    onChange={this.props.handleChange}
                    value={this.props.product_and_service}
                    name="temp_product_and_service"
                    queryObject={product_list}
                    isClearable={true}
                    optionAll={true}
                    keyOfValue="id"
                    keyOfLabel="productCode:name"
                  />
                </div>
              </div>
            );
          }
        }}
      />
    );
  }
}

export default ProductServicePayableList;
