import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaymentIcon from '@mui/icons-material/Payment';
import { styled } from '@mui/material/styles';

import dashboardApi from '../../../../api/dashboard';
import Loading from '../../../../libs/loading';

import './slip.scss'
import i18next from 'i18next';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));



function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{fontFamily:'medium'}}>
        <TableCell component="th" scope="row">
          {row.invoiceId}
        </TableCell>
        <TableCell align="left">{row.qrcodeData}</TableCell>
        <TableCell align="center">{row.slipVerifyLogsStatusMessage}</TableCell>
        <TableCell align="center">{row.resultCode}</TableCell>
        <TableCell align="center">{row.resultCompare == 'True' ? <Chip label="True" color="success" sx={{ bgcolor: '#4EB4A9', color: 'white', width:'65px' }} /> : <Chip color="error" label="False"  sx={{ bgcolor: '#E14558', color: 'white' ,width:'65px' }} />}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            
              <Table size="small" className='slip-dark-gray'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{borderBottom:"none"}}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AccountBalanceIcon/>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="ข้อมูลธนาคาร"/>
                      </ListItem>
                    </TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>Account Name</TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>Account Number</TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>Bank Name</TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}} align="center">Account Name Status</TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}} align="center">Account Number Status</TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}} align="center">Bank Name Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell style={{paddingBottom:"16px"}}></TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>{row.bank.accountName}</TableCell>
                  <TableCell style={{paddingBottom:"16px"}} >{row.bank.accountNumber}</TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>{row.bank.bankName}</TableCell>
                  <TableCell style={{paddingBottom:"16px"}} align="center">{row.bank.accountName_status ? 
                  <div className='statusCenter'><div className='statusTrueBtn'>True</div></div>
                  : <div className='statusCenter'><div className='statusFalseBtn'> False </div> </div> } </TableCell> 

                  <TableCell style={{paddingBottom:"16px"}} align="center">{row.bank.accountNumber_status ? 
                   <div className='statusCenter'><div className='statusTrueBtn'>True</div></div>
                  : <div className='statusCenter'><div className='statusFalseBtn'> False </div> </div> } </TableCell> 

                  <TableCell style={{paddingBottom:"16px"}} align="center">{row.bank.bankName_status ? 
                  <div className='statusCenter'><div className='statusTrueBtn'>True</div></div>
                  : <div className='statusCenter'><div className='statusFalseBtn'> False </div> </div> } </TableCell> 
                </TableBody>
              </Table>

              <Table size="small" className='slip-light-gray'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{width:'18%',borderBottom:"none"}}>              
                      <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <CalendarMonthIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="รายละเอียดวันที่"/>
                    </ListItem>
                    </TableCell>
                    <TableCell style={{width:'16%',borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>App</TableCell>
                    <TableCell style={{width:'16%',borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>Invoice</TableCell>
                    <TableCell style={{width:'16%',borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>Slip</TableCell>
                    <TableCell style={{width:'16%',borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>Receive Create</TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}></TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}} align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell style={{paddingBottom:"16px"}}></TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>{row.date.appDate}</TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>{row.date.invoiceDate}</TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>{row.date.slipDate}</TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>{row.date.receiveDate}</TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>  </TableCell>
                  <TableCell style={{paddingBottom:"16px"}} align="center">{row.amount.date_status ? 
                  <div className='statusCenter'><div className='statusTrueBtn'>True</div></div>
                  : <div className='statusCenter'><div className='statusFalseBtn'> False </div> </div> } </TableCell> 
                </TableBody>
              </Table>
             
              <Table size="small" className='slip-dark-gray'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{width:'18%',borderBottom:"none"}}>
                      <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <PaymentIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="ยอดชำระ"/>
                    </ListItem>
                    </TableCell>
                    <TableCell style={{width:'18%',borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>Slip</TableCell>
                    <TableCell style={{width:'18%',borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}>Invoice</TableCell>
                    <TableCell style={{width:'18%',borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}></TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}></TableCell>
                    <TableCell style={{width:'10%',borderBottom:"none",paddingBottom:'0',paddingTop:'0'}}></TableCell>
                    <TableCell style={{borderBottom:"none",paddingBottom:'0',paddingTop:'0'}} align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell style={{paddingBottom:"16px"}}></TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>{row.amount.currentPaid.toFixed(2)} บาท</TableCell>
                  <TableCell style={{paddingBottom:"16px"}}>{row.amount.transactionTotal.toFixed(2)} บาท</TableCell>
                  <TableCell style={{paddingBottom:"16px"}}></TableCell>
                  <TableCell style={{paddingBottom:"16px"}}></TableCell>
                  <TableCell style={{paddingBottom:"16px"}}></TableCell>
                  <TableCell style={{paddingBottom:"16px"}} align="center">{row.amount.amount_status ? 
                  <div className='statusCenter'><div className='statusTrueBtn'>True</div></div>
                  : <div className='statusCenter'><div className='statusFalseBtn'> False </div> </div> } </TableCell> 
                </TableBody>
              </Table>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen2(!open2)}
                >
                  {open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} Slip Verify Log Response
                </IconButton>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                  <Div>{JSON.stringify(row.slipVerifyLogResVerify)}</Div>
                </Collapse>
              </TableCell>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    invoiceId: PropTypes.string.isRequired,
    qrcodeData: PropTypes.string.isRequired,
    resultCompare: PropTypes.string.isRequired,
    resultCode: PropTypes.string.isRequired,
    resultText: PropTypes.string.isRequired,
    slipVerifyLogsStatusCode: PropTypes.string.isRequired,
    slipVerifyLogsStatusMessage: PropTypes.string.isRequired,
    amount: PropTypes.shape({
      currentPaid: PropTypes.number.isRequired,
      transactionTotal: PropTypes.number.isRequired,
      amount_status: PropTypes.bool.isRequired,
    }),
    date: PropTypes.shape({
      slipDate: PropTypes.string.isRequired,
      invoiceDate: PropTypes.string.isRequired,
      appDate: PropTypes.string.isRequired,
      date_status: PropTypes.bool.isRequired,
    }),
    bank: PropTypes.shape({
      bankName: PropTypes.string.isRequired,
      bankName_svm: PropTypes.string.isRequired,
      bankName_status: PropTypes.bool.isRequired,
      accountName: PropTypes.string.isRequired,
      accountName_svm: PropTypes.string.isRequired,
      accountName_status: PropTypes.bool.isRequired,
      accountNumber: PropTypes.string.isRequired,
      accountNumber_svm: PropTypes.string.isRequired,
      accountNumber_status: PropTypes.bool.isRequired
    }),
    slipVerifyLogResVerify: PropTypes.shape({
      amount: PropTypes.number,
      countryCode: PropTypes.string,
      language: PropTypes.string,
      paidLocalAmount: PropTypes.number,
      paidLocalCurrency: PropTypes.string,
      receiver: PropTypes.shape({
        account: PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.string,
        }),
        displayName: PropTypes.string,
        name: PropTypes.string,
        proxy: PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.string,
        }),
      }),
      receivingBank: PropTypes.string,
      ref1: PropTypes.string,
      ref2: PropTypes.string,
      ref3: PropTypes.string,
      sender: PropTypes.shape({
        account: PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.string,
        }),
        displayName: PropTypes.string,
        name: PropTypes.string,
        proxy: PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.string,
        }),
      }),
      sendingBank: PropTypes.string,
      toMerchantId: PropTypes.string,
      transDate: PropTypes.string,
      transFeeAmount: PropTypes.number,
      transRef: PropTypes.string,
      transTime: PropTypes.string,
    }),
  }).isRequired,
};

export default function CollapsibleTable() {
  const [logs, setLogs] = useState([])
  const [countLog, setCountLog] = useState()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      let dataParams = {
          qrcode: "",
          siteId: localStorage.getItem('site_id'),
          flagMonth: "5"
      }
      const data = await dashboardApi.getSlipVerifyLog(dataParams)
      if (isSubscribed) {
        if (data.data.statusCode === '0000'){
          let log = data.data.data.resultAutoReconcileLog
          let countLog = data.data.data.countLog
          setCountLog(countLog);
          setLogs(log);
          setLoading(false);
        }
      }
    }
    fetchData()
    .catch(console.error);;
    return () => isSubscribed = false;
  }, [])

  return (

    <TableContainer component={Paper}>
      { !loading ?
        <Table aria-label="collapsible table">
          <caption>{i18next.t("slipVerificationList:Number of Data Found")} {countLog}</caption>
          <TableHead>
            <TableRow>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}}> Invoice ID </TableCell>
              <TableCell style={{width: '20%',fontFamily:'medium',backgroundColor:'#e9ecef'}} align="center">QR Code</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}} align="center">{i18next.t("slipVerificationList:Response of Payslip")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}} align="center">{i18next.t("slipVerificationList:Result")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}} align="center">{i18next.t("slipVerificationList:Status")}</TableCell>
              <TableCell style={{fontFamily:'medium',backgroundColor:'#e9ecef'}}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((row) => (
              <Row key={row.invoiceId} row={row} />
            ))}
          </TableBody>
        </Table>
        :<Loading/>
      }
       <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={logs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      
    </TableContainer>

  );
}
