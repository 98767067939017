/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type modalPrototypeQueryVariables = {|
  id: string,
  status?: ?string,
|};
export type modalPrototypeQueryResponse = {|
  +allChoiceSummaryPercentage: ?$ReadOnlyArray<?{|
    +total: ?string,
    +choiceId: ?string,
    +countPerson: ?string,
  |}>
|};
export type modalPrototypeQuery = {|
  variables: modalPrototypeQueryVariables,
  response: modalPrototypeQueryResponse,
|};
*/


/*
query modalPrototypeQuery(
  $id: ID!
  $status: String
) {
  allChoiceSummaryPercentage(surveyId: $id, status: $status) {
    total
    choiceId
    countPerson
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "surveyId",
        "variableName": "id"
      }
    ],
    "concreteType": "SurveyVoteResult",
    "kind": "LinkedField",
    "name": "allChoiceSummaryPercentage",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "choiceId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countPerson",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "modalPrototypeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "modalPrototypeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6796c2b1bc979b451ce204a287b34146",
    "id": null,
    "metadata": {},
    "name": "modalPrototypeQuery",
    "operationKind": "query",
    "text": "query modalPrototypeQuery(\n  $id: ID!\n  $status: String\n) {\n  allChoiceSummaryPercentage(surveyId: $id, status: $status) {\n    total\n    choiceId\n    countPerson\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '22319d0c79315e70367c905b7a3de175';

module.exports = node;
