import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';
import _ from "lodash"


class Navigation extends Component {
    render() {
        return (
            <Translation>
                {t=>
                <div className="row" id="navigation-tab">
                    <div className="col">
                        <ul>
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_residential'}) && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_receivable'}) && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_payable'}) &&
                            <li>
                                <NavLink exact={true} to="/accounting/contact/all">
                                    {t("contact_list:All")}
                                </NavLink>
                            </li>
                            }
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_residential'}) &&
                            <li>
                                <NavLink to="/accounting/contact/residential">
                                    {t("contact_list:Tenant")}
                                </NavLink>
                            </li>
                            }
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_receivable'}) &&
                            <li>
                                <NavLink to="/accounting/contact/supplier/receivable">
                                    {t("contact_list:Receivable_setup")}
                                </NavLink>
                            </li>
                            }
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_payable'}) &&
                            <li>
                                <NavLink to="/accounting/contact/supplier/payable">
                                    {t("contact_list:Payable_setup")}
                                </NavLink>
                            </li>
                            }
                        </ul>
                    </div>
                </div>}
            </Translation>
        )
    }
}

export default Navigation;