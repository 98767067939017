import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import NoMatch from "../../../components/noMatch";
import {Route, Switch} from "react-router-dom";
import ProductList from './productList';
import CreateAndUpdate from './createAndUpdate';
import IndividualPriceList from './individualPriceList'

class Product extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:type`} component={ProductList}/>
                <PrivateRoute exact path={`${this.props.match.url}/individual-price-list/:status/:id?`} component={IndividualPriceList}/>
                <PrivateRoute exact path={`${this.props.match.url}/:page/:id?`} component={CreateAndUpdate}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Product;