import '../styles/otherExpense.scss';
import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import getNameResidential from "../../libs/getNameResidential";
import _ from "lodash";
import i18n  from 'i18next';
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class OtherExpense extends Component {

    constructor(props) {
        super(props);


        this.state = {
            // bangkokCode: bangkokCode,
            sum_pre_tax: 0,
            sum_vat: 0,
            sum_amount: 0,
            sum_all: 0,
            sum_wht_rate: 0,
            project: atob(this.props.query.selfProject.id).split(":")[1],

            have_vat: "",
            no_vat: "",
            have_wht_rate_percent: "",
            no_wht_rate_percent: "",
            discount_status: "",

            isOther: false,
            languages: "th",
        };
        this.calSummary = this.calSummary.bind(this);
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.allStatus = this.allStatus.bind(this);
        this.paymentChannel = this.paymentChannel.bind(this);
        this.isOther = this.isOther.bind(this);
    }

    componentWillMount() {
        i18n.changeLanguage(this.props.languages);
        this.calSummary();
        this.allStatus();
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
        })
        setTimeout(() => {
            let page = $('.oe-' + this.props.other_expense_page);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();

            // let tbody = page.find('tbody').height()
            // let sizetoPrint = this.props.size === "a4" ? 442 : 442
            // let diff = this.props.size  - tbody

            // let sizetoPrint = this.props.size === "a4" ? 800 : 300
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + (signature || 0));
            $('.' + this.props.other_expense_page + ' table').css({ borderBottom: "1px solid" });
            if (this.props.current_page !== this.props.end_page) {
                $('.oe-' + this.props.other_expense_page + ' tbody tr:last-child td').css({ paddingBottom: diff });
            } else {
                $('.oe-' + this.props.other_expense_page + ' tbody tr:last-child td').css({ paddingBottom: diff });
            }
        }, 200);
        this.isOther()
    }

    allStatus() {
        if (this.props.query.otherExpense.otherExpenseTransaction.edges.length > 0) {
            //หัก ณ ที่จ่าย
            const whtRatePercent_Check = _.find(this.props.query.otherExpense.otherExpenseTransaction.edges, (e) => e.node.whtRatePercent > 0);
            if (whtRatePercent_Check) {
                this.setState({
                    have_wht_rate_percent: true,
                    no_wht_rate_percent: false
                })
            } else {
                this.setState({
                    no_wht_rate_percent: true,
                    have_wht_rate_percent: false
                })
            }
            //หัก ณ ที่จ่าย

            //vat
            const vat_Check = _.find(this.props.query.otherExpense.otherExpenseTransaction.edges, (e) => e.node.vat != null);
            if (vat_Check) {
                this.setState({
                    have_vat: true,
                    no_vat: false
                })
            } else {
                this.setState({
                    no_vat: true,
                    have_vat: false
                })
            }
            //vat

            // discount
            const discount_Check = _.find(this.props.query.otherExpense.otherExpenseTransaction.edges, (e) => e.node.discount !== 0);
            if (discount_Check) {
                this.setState({ discount_status: true })
            } else {
                this.setState({ discount_status: true })
            }
            //discount
        }
    }

    calSummary() {
        let sumAmount = 0; // amountทั้งหมด
        let discount = 0; //ส่วนลด
        let sumWhtRate = 0; //หัก ณ ที่จ่ายทั้งหมด
        let sumVat = 0;//VAT ทั้งหมด
        let sumTotal = 0;// รวมหลังหักจ่ายล่วงหน้า + VAT ทั้งหมด

        let sumTotalAmount = 0;

        this.props.query.otherExpense.otherExpenseTransaction.edges.forEach((other_expense) => {
            discount += parseFloat(other_expense.node.discount);
            sumAmount += parseFloat(other_expense.node.preTaxAmount) + parseFloat(other_expense.node.discount);

            //คำนวนหัก ณ ที่จ่าย
            sumWhtRate += parseFloat(other_expense.node.whtRate);

            //Total
            sumTotalAmount = sumAmount;

            //คำนวนvat
            sumVat = sumVat + (other_expense.node.vatAmount);

            //คำนวนจำนวนเงินรวม
            sumTotal = sumVat + sumTotalAmount;

        });
        const sum_total = (sumTotal - discount);
        const total_sum = (sumTotal - discount) - sumWhtRate;
        const sumDiscount = (sumTotalAmount - discount)
        this.setState({
            sum_wht_rate: sumWhtRate.toFixed(2),
            sum_vat: sumVat.toFixed(2),
            sum_amount: sumAmount.toFixed(2),
            sum_total: sum_total.toFixed(2),
            sum_discount: sumDiscount.toFixed(2),
            discount: discount.toFixed(2),
            sum_total_amount: total_sum.toFixed(2),
        });
    }

    isBangkok(code) {
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];
        if (_.indexOf(bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "district") {
            return "ต."
        } else if (type === "city") {
            return "อ."
        }
    }

    paymentChannel() {
        let bank_detail = [{ bankName2: "", accountNumber: "", price: 0 }]
        let cheque_detail = [{ chequeNumber: "", bankName: "", branch: "", price: 0 }]
        let cash_detail = []
        if (this.props.query.cashTransactionViewer.allCashTransaction.edges.length) {
            let price_cash = 0
            this.props.query.cashTransactionViewer.allCashTransaction.edges.forEach((value, index) => {
                price_cash = value.node.price + price_cash
            })
            cash_detail = { price_cash: price_cash, channel: true, transaction: "เงินสด", name: "cash" }
        } else {
            cash_detail = { channel: false, transaction: "เงินสด", name: "cash" }
        }
        if (this.props.query.allBankAccountTransaction.edges.length) {
            this.props.query.allBankAccountTransaction.edges.forEach((val, index) => {
                let find_index = bank_detail.findIndex((i) => i?.bankName2 === val.node.bankAccount.bankName && i?.accountNumber === val.node.bankAccount.accountNumber)
                if (find_index !== -1) {
                    let new_price = val.node.price + bank_detail[find_index].price
                    bank_detail[find_index].price = new_price
                } else {
                    //bank_detail[0] = default value
                    if (index === 0) {
                        bank_detail[index] = { bankName2: val.node.bankAccount.bankName, accountNumber: val.node.bankAccount.accountNumber, price: val.node.price, date: val.node.date }
                    } else {
                        bank_detail.push({ bankName2: val.node.bankAccount.bankName, accountNumber: val.node.bankAccount.accountNumber, price: val.node.price, date: val.node.date })
                    }
                }
            })
            bank_detail = { channel: true, transaction: "เงินโอน", name: "bank", bank_list: bank_detail }
        } else {
            bank_detail = { channel: false, transaction: "เงินโอน", name: "bank" }
        }

        if (this.props.query.chequeTransactionViewer.allChequeTransaction.edges.length) {
            this.props.query.chequeTransactionViewer.allChequeTransaction.edges.forEach((val, index) => {
                let find_index = cheque_detail.findIndex((i) => i?.bankName === val.node.bankAccount.bankName && i?.chequeNumber === val.node.chequeNumber)
                if (find_index !== -1) {
                    let new_price = cheque_detail[find_index].price + val.node.price
                    cheque_detail[find_index].price = new_price
                } else {
                    if (index === 0) {
                        cheque_detail[index] = { chequeNumber: val.node.chequeNumber, bankName: val.node.bankAccount.accountName, branch: val.node.bankAccount.branch, price: val.node.price, date: val.node.date }
                    } else {
                        cheque_detail.push({ chequeNumber: val.node.chequeNumber, bankName: val.node.bankAccount.accountName, branch: val.node.bankAccount.branch, price: val.node.price, date: val.node.date })
                    }
                }
            })
            cheque_detail = { channel: true, transaction: "เช็ค", name: "cheque", cheque_list: cheque_detail }
        } else {
            cheque_detail = { channel: false, transaction: "เช็ค", name: "cheque" }
        }

        return { cash: { ...cash_detail }, bank: { ...bank_detail }, cheque: { ...cheque_detail } }
    }

    paymentView(transaction) {
        return (
            <React.Fragment>
                {transaction.name === 'cash' && transaction.channel &&
                    <div className="row col ml-1" key="cash">
                        <span className="align-middle"> เงินสด/Cash </span>
                        <div className="col">
                            <span>{transaction.price_cash + " บาท"} </span>
                        </div>
                    </div>
                }
                {transaction.name === 'bank' && transaction.channel &&
                    <div className="col ml-1" key="bank">
                        <span className="align-middle"> เงินโอน/Bank Transfer </span><br />
                        <div className="col ml-2">
                            {transaction.bank_list?.map((text, index_bank) => {
                                return <div className="row" key={text.accountNumber + index_bank}><span key={text.accountNumber}> - {text.bankName2}&emsp;{text.accountNumber}&emsp;{"วันที่ " + format(text.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;จำนวน&emsp;{numberWithComma(text.price, "0.00")} บาท<br /></span></div>
                            })}
                        </div>
                    </div>
                }
                {transaction.name === 'cheque' && transaction.channel &&
                    <div className="col ml-1" key="cheque">
                        <span className="align-middle"> เช็คธนาคาร/Cheque Bank </span><br />
                        <div className="col ml-2">
                            {transaction.cheque_list?.map((text, index_cheque) => {
                                return <div className="row" key={text.chequeNumber + index_cheque}><span key={text.chequeNumber}>- เช็คเลขที่ {text.chequeNumber}&emsp;{text.bankName}&emsp;{text.branch && "สาขา " + text.branch}&emsp;{"วันที่ " + format(text.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;จำนวน {numberWithComma(text.price, "0.00")} บาท <br /></span></div>
                            })}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }

    isOther() {
        //ถ้าเกิด OE มีการใช้ 5400-07 (ค่าธรรมเนียม) ในรายการจ่าย ต้องไม่แสดง ในช่องทางการชำระเงินด้านล่าง
        let Oe_5400_07 = _.findIndex(this.props.other_expense_list, {'node' : { 'chartOfAccount' : {'chartOfAccountCode' : '5400-07'}}}) // -1 คือไม่มี > 0 คือมีในรายการจ่าย

        this.props.query.allAccountRecordGroup.edges.length > 0 &&
            this.props.query.allAccountRecordGroup.edges.forEach((Other) => (
                Other.node.accountRecord.edges.forEach((accountRecord) => {
                    if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                        (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07" && Oe_5400_07 === -1 )) {
                        this.setState({
                            isOther: true
                        })
                    }
                })

            ))
    }

    render() {
        let paymentChannel = this.paymentChannel()

        return (
            <React.Fragment>

                <div className={"print-page-a4 oe-" + this.props.other_expense_page} id="style-modify" style={{position: 'relative'}}> {/* id="otherExpense" */}
                    <div className="subpage" style={{overflow: 'hidden'}}>
                        <div className="head">
                            <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                <div style={{ minWidth: 100, minHeight: 100 }}>
                                    <img
                                        src={this.props.query.selfProject.logo}
                                        alt="silverman" />
                                </div>
                                <div>
                                    <div className="title" style={{minWidth:'150%'}}>
                                        <strong>{this.state.languages === "en" ? this.props.query.selfProject.nameEn : this.props.query.selfProject.name}</strong>
                                    </div>
                                    <div
                                        className="title">{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}
                                    </div>
                                    <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                </div>
                            </div>

                            <div className="document-name">
                                <div className="title">ใบบันทึกค่าใช้จ่ายอื่นๆ</div>
                                <div className="title">Other Expense</div>
                                <div className="title small">(ต้นฉบับ/Original)</div>
                            </div>
                        </div>

                        <div className="detail">
                            <div className="customer-detail">
                                {this.props.query.otherExpense.contact ?
                                    <React.Fragment>

                                        <strong>รหัสเจ้าหนี้/ID:</strong> {this.props.query.otherExpense.contact.refNumber}
                                        <br />
                                        {this.props.query.otherExpense.contact.typeOfContact === 'RESIDENTIAL' ?
                                            <React.Fragment>
                                                <strong>ชื่อเจ้าหนี้/Creditor:</strong>
                                                {getNameResidential(this.props.query.otherExpense.contact.firstName, this.props.query.otherExpense.contact.lastName)}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <strong>ชื่อเจ้าหนี้/Creditor:</strong> {this.props.query.otherExpense.contact.name}
                                            </React.Fragment>
                                        }
                                        <br />
                                        <strong>ที่อยู่/Address:</strong>

                                        <div className="customer-detail address-space">
                                            {/* {this.props.query.otherExpense.contact.registeredAddress + " "}
                                            {this.props.query.otherExpense.contact.registeredDistrict && this.getPrefix("district", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredDistrict + " "}
                                            {this.props.query.otherExpense.contact.registeredCity && this.getPrefix("city", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredCity + " "}
                                            {this.props.query.otherExpense.contact.registeredProvince && this.getPrefix("province", this.props.query.otherExpense.contact.registeredPostalCode) + this.props.query.otherExpense.contact.registeredProvince + " "}
                                            {this.props.query.otherExpense.contact.registeredPostalCode + " "} */}

                                            {/* BTA685 new rq */}
                                            {this.props.query.otherExpense?.address + " "}
                                            {this.props.query.otherExpense.district && this.getPrefix("district", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.district + " "}
                                            {this.props.query.otherExpense.city && this.getPrefix("city", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.city + " "}
                                            {this.props.query.otherExpense.province && this.getPrefix("province", this.props.query.otherExpense.postalCode) + this.props.query.otherExpense.province + " "}
                                            {this.props.query.otherExpense.postalCode + " "}

                                            {
                                                (this.props.query.otherExpense.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.query.otherExpense.contact.typeOfSupplier === "COMPANY") &&
                                                <React.Fragment>
                                                    {
                                                        this.props.query.otherExpense.contact.typeOfCompany === "HEADQUARTERS" ?
                                                            "สำนักงานใหญ่"
                                                            : this.props.query.otherExpense.contact.typeOfCompany === "BRANCH" &&
                                                            "สาขา " + this.props.query.otherExpense.contact.nameBranch
                                                    }
                                                </React.Fragment>
                                            }

                                        </div>
                                        <br />
                                        <strong>เลขผู้เสียภาษี/Tax
                                            ID:</strong> {this.props.query.otherExpense.contact.taxIdNumber}
                                        <br />
                                        <strong>เลขที่ใบกำกับ/Attention:</strong> {this.props.query.otherExpense.taxInvoiceNumber}
                                        <span
                                            className="tax-invoice-date">{
                                                this.props.query.otherExpense.taxInvoiceNumber ? 'ลวท.' + format(this.props.query.otherExpense.taxInvoiceDate, 'DD/MM/YYYY', { locale: thLocale }) : null
                                            }</span>

                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <strong>ชื่อเจ้าหนี้/Creditor:</strong> {this.props.query.otherExpense.unknownContact || '-'}
                                    </React.Fragment>
                                }
                                <br />
                            </div>
                            <div className="document-detail">
                                <strong>เลขที่/No:</strong> {this.props.query.otherExpense.docNumber} <br />
                                <strong>วันที่/Date:</strong> {format(this.props.query.otherExpense.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}
                                <br />
                            </div>
                        </div>

                        <div className="invoice-body">
                            <table className="table table-bordered" style={{ borderBottom: '1px solid #212529' }}>
                                <thead>
                                    <tr>
                                        <th width={50} className="text-center">
                                            ลำดับ <br />
                                            No.
                                        </th>
                                        <th width={60} className="text-center">
                                            รหัส <br />
                                            Code
                                        </th>
                                        <th className="text-center">
                                            รายละเอียด <br />
                                            Description
                                        </th>
                                        <th width={100} className="text-center">
                                            จำนวนหน่วย <br />
                                            Unit
                                        </th>
                                        <th width={100} className="text-center">
                                            ราคาต่อหน่วย <br />
                                            Unit Price
                                        </th>
                                        <th width={100} className="text-center">
                                            จำนวนเงิน <br />
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.other_expense_list.map((other_expense, index) =>
                                        <tr key={other_expense.node.id}>
                                            <td className="text-center">{(this.props.current_page * 16) + index + 1}</td>
                                            <td className="text-center">{other_expense.node.productAndService?.productCode ?
                                                other_expense.node.productAndService && other_expense.node.productAndService.productCode
                                                :
                                                other_expense.node.chartOfAccount.chartOfAccountCode}</td>
                                            {/* <td>{ other_expense.node.productAndService ? other_expense.node.productAndService.name : other_expense.node.chartOfAccount.name}</td> */}
                                            <td>{other_expense.node.description}</td>
                                            <td className="text-right">{numberWithComma(other_expense.node.unitItems)}</td>
                                            <td className="text-right">{numberWithComma(other_expense.node.price)}</td>
                                            <td className="text-right">{numberWithComma(other_expense.node.price * other_expense.node.unitItems)}</td>
                                        </tr>
                                    )}

                                </tbody>
                                <tfoot className="font-weight-bold">
                                    {(this.props.current_page === this.props.end_page) &&
                                        <tr>
                                            <td colSpan={3}
                                                rowSpan={(this.state.no_vat && this.state.no_wht_rate_percent && !this.state.discount_status) ? 1 :
                                                    (
                                                        (this.state.no_vat && this.state.have_wht_rate_percent && !this.state.discount_status) ||
                                                        (this.state.have_vat && this.state.no_wht_rate_percent && !this.state.discount_status)
                                                    ) ? 2 :
                                                        (this.state.no_vat && this.state.no_wht_rate_percent && this.state.discount_status) ? 3 :
                                                            (
                                                                (this.state.have_vat && this.state.no_wht_rate_percent && this.state.discount_status) ||
                                                                (this.state.no_vat && this.state.have_wht_rate_percent && this.state.discount_status) ||
                                                                (this.state.have_vat && this.state.have_wht_rate_percent && !this.state.discount_status)
                                                            ) ? 4 :
                                                                this.state.have_vat && this.state.have_wht_rate_percent && this.state.discount_status && 6} />
                                            <td colSpan={2}>
                                                จำนวนเงิน /Amount
                                            </td>
                                            <td className="text-right">
                                                {numberWithComma(this.state.sum_amount, 0)}
                                            </td>
                                        </tr>
                                    }
                                    {(this.props.current_page === this.props.end_page) &&
                                        <React.Fragment>
                                            {
                                                this.state.discount_status &&
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <strong>หัก&nbsp;</strong>ส่วนลด/Discount
                                                        </td>
                                                        <td className="text-right">
                                                            {numberWithComma(this.state.discount, 0)}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan={2}>
                                                            จำนวนเงินหลังหักส่วนลด/Total Discount
                                                        </td>
                                                        <td className="text-right">
                                                            {numberWithComma(this.state.sum_discount, 0)}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            }
                                            {
                                                ((this.state.have_vat && this.state.no_wht_rate_percent) ||
                                                    (this.state.have_vat && this.state.have_wht_rate_percent)) &&
                                                <tr>
                                                    <td colSpan={2}>
                                                        ภาษีมูลค่าเพิ่ม (บาท) /VAT
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.state.sum_vat, 0)}
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                this.state.have_wht_rate_percent && this.state.have_vat &&
                                                <tr>
                                                    <td colSpan={2}>
                                                        จำนวนเงินรวม/Total cash
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.state.sum_total, 0)}
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                this.state.have_wht_rate_percent &&
                                                <tr>
                                                    <td colSpan={2}>
                                                        <strong>หัก&nbsp;</strong>ภาษีหัก ณ ที่จ่าย/With holding tax
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.state.sum_wht_rate, 0)}
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td colSpan={3} className="text-center">                                                    
                                                    {i18n.languages[0] === 'th' ?
                                                    (ThaiBaht(this.state.sum_total_amount)):
                                                    (ConvertNumberToTextEng(this.state.sum_total_amount))}
                                                </td>
                                                <td colSpan={2}>
                                                    จำนวนเงินรวมทั้งสิ้น/Grand Total
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(this.state.sum_total_amount, 0.00)}
                                                </td>
                                            </tr>
                                        </React.Fragment>

                                    }
                                </tfoot>
                            </table>
                            <div className="remark">
                                <span className="font-weight-bold">การชำระเงิน(Conditions of Payments)</span><br />
                                {this.paymentView(paymentChannel.cash)}
                                {this.paymentView(paymentChannel.bank)}
                                {this.paymentView(paymentChannel.cheque)}
                                {this.props.query.otherExpense.otherExpensePaymentChannel.edges.length > 0 &&
                                    <div className="col ml-1" key="bank">                                       
                                            {this.props.query.otherExpense.otherExpensePaymentChannel.edges.map((cash, index) => {
                                                if(cash.node?.setPettyCash === null && cash.node?.rdCash === null) {}
                                                else if(cash.node?.setPettyCash === null) 
                                                {
                                                    return  <>
                                                                <span className="align-middle">เงินรับล่วงหน้า/Receipt Deposit Cash </span><br />
                                                                <div className="col ml-2">
                                                                    <div className="row" key={cash.node.id + index}><span key={cash.node.id}> - เลขที่ <b>{cash.node.rdCash.docNumber}</b>&emsp;{"วันที่ " + format(cash.node.date, 'DD/MM/YYYY', { locale: thLocale })} จำนวน {cash.node.price > 0 ? numberWithComma(cash.node.price) : numberWithComma(cash.node.price,"0.00",false,true)} บาท</span></div>                                           
                                                                </div>
                                                            </>
                                                }
                                                else
                                                {
                                                    return  <>
                                                                <span className="align-middle">เงินสดย่อย/Petty Cash </span><br />
                                                                <div className="col ml-2">
                                                                    <div className="row" key={cash.node.id + index}><span key={cash.node.id}> - เลขที่ <b>{cash.node.setPettyCash.docNumber}</b>&emsp;{"วันที่ " + format(cash.node.date, 'DD/MM/YYYY', { locale: thLocale })} จำนวน {cash.node.price > 0 ? numberWithComma(cash.node.price) : numberWithComma(cash.node.price,"0.00",false,true)} บาท</span></div>
                                                                </div>
                                                            </>
                                                }      
                                            })}  
                                    </div>
                                }
                                {this.state.isOther &&
                                    <div className="col ml-1">
                                        <span className="align-middle"> อื่นๆ/Other </span>
                                        <div className="col ml-2">
                                            {this.props.query.allAccountRecordGroup.edges.map((Other) => (
                                                Other.node.accountRecord.edges.map((accountRecord, index_account) => {
                                                    if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                                                        accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07") {
                                                        let name = accountRecord.node.chartOfAccountCode.name
                                                        if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" && accountRecord.node.debit > 0) {
                                                            name = name.replace("รายรับ", "รายจ่าย")
                                                        }
                                                        return (
                                                            <div className="row" key={accountRecord.node.id + index_account}>
                                                                <span key={accountRecord.node.id} >- {name}&emsp;จำนวน&emsp;{numberWithComma(accountRecord.node.credit > 0 ? accountRecord.node.credit : accountRecord.node.debit)} บาท</span><br />
                                                            </div>

                                                        )
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })
                                            )
                                            )
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <br />



                            {(this.props.current_page === this.props.end_page) && this.props.query.otherExpense.remark.length > 0 &&
                                <div className="remark">
                                    <span className="font-weight-bold">หมายเหตุ/Remarks:</span><br />
                                    {this.props.query.otherExpense.remark}
                                </div>
                            }
                        </div>
                        {/*  */}
                        <div className="footer">
                            <div className="signature-large">
                                <div className="column-foursign" >
                                    .........................................................<br />
                                    <span className="font-weight-bold">ผู้จัดทำ / Orgenizer</span>
                                </div >
                                <div className="column-foursign">
                                    .........................................................<br />
                                    <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                </div>
                                <div className="column-foursign">
                                    .........................................................<br />
                                    <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                </div>
                                <div className="column-foursign">
                                    .........................................................<br />
                                    <span className="font-weight-bold">ผู้รับเงิน / Receiver</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="number-page">
                            <br />
                            {this.props.current_page + 1}/{this.props.all_page}
                        </div> */}
                    </div>
                </div>


            </React.Fragment>
        );
    }
}

export default OtherExpense;