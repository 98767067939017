import React, { Component } from 'react';
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import './settingSecurity.scss';
class SettingSecutiynotFoundLRC extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeLRC: false,
            contact: {
                tel1: '0814426888 ',
                tel2: '0655796844',
                email: '@silvermansupport',
            }
        }
    }


    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box" id="note-setting">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting/tool-and-connection">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('setting:ToolandConnection')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <br />
                        <br />

                        <div className='left_right100 text-center'>
                            <div className="content-inner" id="projectDashboard">
                                <img className='iconAlerts' src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueTriangle.png'} alt='BlueTriangle' />
                                <h3 className='text-center text-blue mt-3'>ต้องการเชื่อมต่อกล้อง LPR <br /> สมัครแพ็กเกจ เพื่อเปิดใช้งาน</h3>

                                <br />
                                <br />
                                <h4>สอบถามข้อมูลเพิ่มเติมได้ที่ <br />
                                    {
                                        this.state.contact.tel1.substring(0,3)+ "-" + 
                                        this.state.contact.tel1.substring(3,6)+ "-" + 
                                        this.state.contact.tel1.substring(6,10)
                                    }
                                    <br />
                                    {
                                        this.state.contact.tel2.substring(0,3)+ "-" + 
                                        this.state.contact.tel2.substring(3,6)+ "-" + 
                                        this.state.contact.tel2.substring(6,10)
                                    }
                                    <br /> หรือ { this.state.contact.email }

                                </h4>
                            </div>

                        </div>
                    </div>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default SettingSecutiynotFoundLRC;
