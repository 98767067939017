import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
query allJobPermissionQuery ($positionId: String) {
    allPositionPermission(positionId: $positionId){
        edges{
            node{
                id
                positionName
                permissionPerPosition{
                    edges{
                        node{
                            id
                            permission{
                                id
                                codename
                                name
                            }
                        }
                    }
                }
            }
        }
    }              
}
`

export const GetAllJobPermission = (variables, response) => {
    fetchQuery(environment, query, variables)
        .then((data) => {
            if (data) {
                response(data?.allPositionPermission?.edges);
            }
        })
        .catch((error) => {
            response(null);
        });
};
