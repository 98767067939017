import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";
import "./styles/commonArea.scss";

class Navigation extends Component {
  render() {
    return (
      <div className="row" id="navigation">
        <div className="col">
          <Translation>
            {(t) => (
              <ul>
                <li>
                  <NavLink key="ทั้งหมด" exact={true} activeClassName="current" to="/contact/global-area/accounting/all">
                    <div>ทั้งหมด</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink key="รอรับชำระ" activeClassName="current" to="/contact/global-area/accounting/waiting_for_payment">
                        รอรับชำระ
                  </NavLink>
                </li>
                <li>
                  <NavLink key="รับชำระแล้ว" activeClassName="current" to="/contact/global-area/accounting/paid">
                        รับชำระแล้ว
                  </NavLink>
                </li>
                <li>
                  <NavLink key="รายการยกเลิก" activeClassName="current" to="/contact/global-area/accounting/canceled_list">
                        รายการยกเลิก
                  </NavLink>
                </li>
              </ul>
            )}
          </Translation>
        </div>
      </div>
    );
  }
}

export default Navigation;
