import getApiRoot from "../../libs/getAPIRoot";
import Helpers from "../../libs/file_download_helper";
import { format } from "date-fns";
import axios from "axios";

async function getViewStatusUnit(conveyance,start_date,search,indexFirst = '',indexLast = ''){
    let startDate = start_date === '' ? '' : format(start_date,'YYYY-MM-DD')
    const response = await Helpers.httpRequest(
        getApiRoot() + 'ownership/view?conveyance='+conveyance +
        '&time_period='+ startDate +
        '&indexFirst='+ indexFirst +
        '&indexLast='+ indexLast +
        '&search='+ search,
       'GET',
   )
   return response
}

async function getSelectResident(search,token){
    return axios.get(`${getApiRoot()}ownership/choose`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            search: search,
        },
    });
}

async function getInsurance(token){
    return axios.get(`${getApiRoot()}ownership/insure`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
    });
}

async function createInsurance(data,token){
    let body = {
        name : data.name,
        description : data.description
    }
    const response = await fetch(
        getApiRoot() + 'ownership/insure',
        {
            method : 'POST',
            headers: {
                'authorization' : `JWT ${token}`,
                'Content-Type': 'application/json'
            },
            body : JSON.stringify(body)
        }
    ).then((response) => {
        // Check if the request is 200
        if (response.ok) {
            let data = response;
            if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
                data = response.json();
            }
            return {data,api:'ownership'};
        }
        return Promise.reject(response);
    });
   return response
}

async function createOwnership(data,token){
    const response = await fetch(
        getApiRoot() + 'ownership/create',
        {
            method : 'POST',
            headers: {
                'authorization' : `JWT ${token}`,
                'Content-Type': 'application/json'
            },
            body : JSON.stringify(data)
        }
    ).then((response) => {
        // Check if the request is 200
        if (response.ok) {
            let data = response;
            if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
                data = response.json();
            }
            return data;
        }
        return Promise.reject(response);
    });
   return response
}

async function getOwnershipDetail(token,residentialID){
    return axios.get(`${getApiRoot()}ownership/create`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            residentialID: residentialID,
        },
    });
}

export default {getViewStatusUnit , getSelectResident , getInsurance , createInsurance , createOwnership, getOwnershipDetail}