import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
const ThaiBaht = require('thai-baht-text');

const CollectionLawFirmENoticeCondoTh = (props) => {
    const [data] = useState(props?.data)
    const [indexS] = useState(props?.indexS)
    const [setting] = useState(props?.setting)
    const [fee, setFee] = useState(0)

    useEffect(() => {
        switch (data?.totalFine) {
            case setting?.feeRateOther: setFee(setting?.feeRateOther)
                break;
            case setting?.feeRateTh: setFee(setting?.feeRateTh)
                break;
            default: setFee(setting?.feeRateTh)
                break;
        }
    }, [props?.data])


    return (
        <div key={"fmcfCollectionLawFirm" + indexS} id={"colorUseDocTh" + indexS} className={"colorUseDoc colorUseDocTh print-page-a4 " + indexS} style={{ position: 'relative' }}>
            <div className="subpage pl-10 pr-10" id="style-document" style={{ overflow: 'hidden' }}>
                <div className="head fs-16 text-center" style={{ marginTop: -15 }}>
                    <img src={process.env.PUBLIC_URL + "/images/iconAction/E-notice/e-noticeIcon.jpeg"} width={100} />
                    <div style={{ position: 'relative' }}>
                        <span style={{ position: 'absolute', right: 269, top: -5 }}>{format(new Date(), "DD/MM/YYYY")}</span>
                        <p>วันที่..........................................</p>
                    </div>
                </div>

                <div className="detail fs-16">
                    <p style={{ fontSize: 14 }}>เรื่อง ขอให้ชำระค่าบำรุงรักษา และค่าจัดการสาธารณูปโภค (ค่าส่วนกลางนิติบุคคลอาคารชุด โครงการ{data?.projectName})</p>
                    <p style={{ fontSize: 14 }}>เรียน {data?.contactName}</p>
                    <p style={{ fontSize: 14 }}>เอกสารที่แนบมาด้วย : รายการสรุปรายการหนี้ค้างชำระ ห้องชุดเลขที่ {data?.contactRoom}</p>
                </div>

                <div className="invoice-body fs-16" style={{ position: 'relative' }}>

                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 14 }}>
                        ตามที่ท่านเป็นผู้ถือกรรมสิทธิ์ห้องชุดเลขที่ {data?.contactRoom} อาคารชุดโครงการ{props?.nameProject} ขนาดเนื้อที่ห้องชุด {data?.sizeRoom} ตารางเมตร
                        {data?.balconyRoom ? "และพื้นที่ระเบียง" : ""} {data?.balconyRoom ?? 0} {data?.balconyRoom ? "ตารางเมตร" : ""} {data?.balconyRoom ?? 0} รวมเนื้อที่ {data?.sizeRoom} ตารางเมตร เนื่องด้วยนิติบุคคลอาคารชุดโครงการ{data?.projectName} ซึ่งจดทะเบียนจัดตั้งเป็นนิติบุคคลอาคารชุด ตามพระราชบัญญัติ อาคารชุด พ.ศ.2522 ตามกฎหมาย และมีวัตถุประสงค์เพื่อจัดการ ดูแลบำรุงรักษาทรัพย์ส่วนกลาง อันเพื่อประโยชน์ร่วมกันของเจ้าร่วมภายในอาคารชุด และให้อำนาจกระทำการใด ๆ เพื่อประโยชน์ตามวัตถุประสงค์ดังกล่าว และตามมติของที่ประชุมใหญ่ได้กำหนดให้ท่านเจ้าของร่วมจะต้องชำระค่าบำรุงรักษา และการจัดการสาธารณูปโภค เบี้ยประกันอาคาร เงินกองทุน ตลอดจนค่าใช้จ่ายอื่นๆ ตามรายละเอียดที่ท่านได้ทราบดีอยู่แล้วนั้น
                    </p>
                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 14 }}>
                        ปรากฏว่า ปัจจุบัน ท่านผู้เป็นเจ้าของร่วมได้ค้างชำระค่าบำรุงรักษา และการจัดการสาธารณูปโภค (ค่าส่วนกลาง) เบี้ยประกันอาคาร เงินกองทุน ตลอดจนค่าใช้จ่ายอื่นๆ และเงินเพิ่มจากการชำระล่าช้า คิดเป็นจำนวนเงินทั้งสิ้น {data?.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท ( {ThaiBaht(data?.total)} )  <b>ดังรายการตามเอกสารที่แนบมาด้วย</b>
                    </p>
                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 14 }}>
                        ดังนั้น ข้าพเจ้าในฐานะทนายความผู้รับมอบอำนาจจากนิติบุคคลอาคารชุดโครงการ{data?.projectName} จึงขอบอกกล่าวมายังท่าน โปรดดำเนินการชำระเงินดังกล่าวที่ท่านได้ค้างชำระ ณ สำนักงานนิติบุคคลอาคารชุดโครงการ{data?.projectName} ในวันและเวลาทำการของนิติบุคคล ฯ ภายในระยะเวลา {setting?.dueDateDays} วัน <b>นับแต่วันที่ท่านได้รับหนังสือบอกกล่าวทวงถามฉบับนี้</b>
                    </p>
                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 14 }}>
                        หากท่านยังคงเพิกเฉยไม่ดำเนินการภายในระยะเวลาดังกล่าว ข้าพเจ้าในฐานะทนายความผู้รับมอบอำนาจมีความจำเป็นที่จะต้องดำเนินการตามกฎหมายต่อท่านต่อไป
                    </p>

                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 14 }}>
                        {
                            setting?.ruleStatus ?
                            `อนึ่ง เมื่อมีการยื่นคำร้องต่อศาล เพื่อดำเนินคดีตามกฎหมาย ตามระเบียบข้อบังคับนิติบุคคลอาคารชุดฯ ${setting?.ruleName} ${setting?.ruleNo ?? 1} ท่านต้องชำระค่าปรับเพิ่ม เป็นจำนวน ${setting?.feeRateOther === data?.totalFine ? setting?.feeRateOther.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : setting?.feeRateTh.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท (${ThaiBaht(fee)}) เพื่อเป็นค่าใช้จ่ายในการดำเนินการ ตลอดจนหากมีค่าใช้จ่ายอื่นใด เพื่อดำเนินการติดตามหนี้คงค้างที่เกิดขึ้นภายหลัง จะเป็นหน้าที่ความรับผิดชอบของท่านในฐานะเจ้าของร่วม ทั้งหมด` : ``
                        }
                        
                    </p>


                    <div className='row'>
                        <div className='col-7'></div>
                        <div className='col-5 text-center'>
                            <p style={{ fontSize: 14 }}>ขอแสดงความนับถือ</p>
                            <p>
                                {
                                    setting?.lawyerSignature ?
                                        <p>
                                            <img id={"signatureImage" + indexS} className='signature' src={setting?.lawyerSignature} />
                                        </p> : <label className='pt-5 pb-5'></label>
                                }
                            </p>
                            <p style={{ fontSize: 14 }}> ({setting?.lawyerName ?? "........................................................."}) </p>
                            <p style={{ fontSize: 14 }}>ทนายความผู้รับมอบอำนาจ</p>
                        </div>
                    </div>


                    <p style={{ fontSize: 14, position: 'absolute', bottom: -25 }}>
                        โทร. {data?.telOffice}, {data?.telJuristic}
                    </p>


                </div>
            </div>
        </div>
    );

}


export default CollectionLawFirmENoticeCondoTh