import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import environment from "../../../env/environment";
import i18n from "i18next";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import _ from 'lodash';
import ContactTopMenuNavigation from '../../contactTopMenuNavigation';
import jwtDecode from "jwt-decode";
import { decode } from 'base-64';
import getWSRoot from '../../../libs/getWSRoot';
import { format } from 'date-fns';

import upload from '../../../libs/upload'
import { fetchQuery, commitMutation } from "relay-runtime";
import UpdateReadMessage from '../mutations/updateReadMessage'
import LightBox from "../../../libs/lightBox";
import UpdateCreateChatMeeting from '../mutations/updateCreateChatMeeting';
import onSubmitSuccess from '../action/onSubmitSuccess';
import onSubmitError from '../action/onSubmitError';
import i18next from 'i18next';


const query = graphql`
    query ChatGroupQuery($status: String,$roomGroupId: String){
        allRoomGroup (status: $status,roomGroupId: $roomGroupId) {
            totalCount
            edges{
                node{
                    totalCountRead
                    id
                    name
                    detail
                    status
                    note
                    user{
                        id
                    }
                    roomGroupNumberRead{
                        edges{
                            node{
                                id
                                count
                                user{
                                    id
                                    juristic{
                                        id
                                        firstName
                                        lastName
                                        image
                                        role
                                    }
                                    tenant{
                                        id
                                        firstName
                                        lastName
                                        image
                                        householder {
                                            edges {
                                                node {
                                                    id
                                                    residential {
                                                        name
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }           
                    } 
                    roomGroupTag{
                        edges{
                            node{
                                id
                                tag
                            }
                        }           
                    }  
                    roomGroupDocument{
                        edges{
                            node{
                                id
                                fileName
                                fileUpload
                            }
                        }           
                    }    
                    roomGroupImage{
                        edges{
                            node{
                                id
                                fileName
                                fileUpload
                            }
                        }           
                    }    
                    roomGroupMessage{
                        edges{
                            node{
                                id
                                message
                                image
                                added
                                updated
                                user{
                                    id
                                    juristic{
                                        id
                                        image
                                        firstName
                                        lastName
                                    }
                                    tenant{
                                        id
                                        image
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }           
                    }            
                    
                }
            }
        }
    }
`;

const mutation = graphql`
mutation ChatGroupMutation ($input: CreateRoomGroupMessageSendInput!) {
    createRoomGroupMessageSend (input: $input) {
        ok
        message
    }
}
`;
let chatSocket = {};

class ChatGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ownCreate: 20279,
            member: [],

            loading: true,
            data: [],
            message_list: [],
            message: '',
            image_profile: {},
            keyGen: "",
            userCreateChk: false

        }
        this.sendMessage = this.sendMessage.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onkeyUp = this.onkeyUp.bind(this)
        this.uploadFile = this.uploadFile.bind(this);
    }

    componentWillMount() {
        this.connectSocket()
        this.readHistoryChat()
    }

    componentWillUnmount() {
        chatSocket.close();
    } 

    connectSocket() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let id = decode(this.props.match.params.id).split("RoomGroupNode:")
        chatSocket = new WebSocket(getWSRoot() + '/group_chat/' + id[1] + '/', window.localStorage.getItem('token'));
        let _this = this;
        chatSocket.onmessage = function (e) {
            let data = JSON.parse(e.data);

            let messageSocket = []
            let user = { firstName: data.first_name, id: "", image: data.profile, lastName: data.last_name }

            _.set(messageSocket, "node.added", data.added)
            _.set(messageSocket, "node.id", "")
            _.set(messageSocket, "node.image", data.image)
            _.set(messageSocket, "node.message", data.message)
            _.set(messageSocket, "node.updated", data.updated)
            _.set(messageSocket, "node.user.socket", user)
            _.set(messageSocket, "node.user.juristic", null)
            _.set(messageSocket, "node.user.tenant", null)
            _.set(messageSocket, "node.user.id", data.role)

            _this.setState({ message_list: [..._this.state.message_list, messageSocket] }, () => {
                _this.scrollToBottom();
            })
        };

        chatSocket.onclose = function (event) {
            if (event.code !== 1000 && event.code !== 1006) {
                Swal.fire({
                    title: i18n.t("fix_request:Chat system crashes"),
                    text: i18n.t("fix_request:connection_fail"),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: i18n.t("fix_request:Try again"),
                    cancelButtonText: i18n.t("fix_request:close")
                }).then((result) => {
                    if (result.value) {
                        window.location.reload();
                    }
                })
            }
        };
    }
    scrollToBottom() {
        let objDiv = document.getElementById("chatMessageFixRequest");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    readHistoryChat() {
        let data = {
            roomGroupId: this.props.match.params.id
        }
        let iAm = jwtDecode(localStorage.getItem("token"))

        fetchQuery(environment, query, { "roomGroupId": data.roomGroupId }).then(data => {

            let userId = data.allRoomGroup?.edges[0].node.user.id;

            let dataDet = data.allRoomGroup?.edges[0].node.roomGroupNumberRead?.edges.filter((n) => 
                parseInt(n.node.user.id) === iAm.user_id && parseInt(userId) === parseInt(iAm.user_id)
            ) 
            this.setState({
                data: data.allRoomGroup?.edges[0].node, loading : false ,
                message_list: data.allRoomGroup?.edges[0].node.roomGroupMessage?.edges,
                image_profile: dataDet[0]?.node?.user?.juristic?.image || dataDet[0]?.node?.user?.tenant?.image || "",
                userCreateChk: parseInt(userId) === iAm?.user_id ? true : false
            })
            this.scrollToBottom()
        })
    }

    onkeyUp(e) {

        if (e.key === "Enter") {
            this.setState({ message: e.target.value, sendStatus: true })
            this.sendMessage()
            e.target.value = ""
        }
    }

    onChange(e) {
        this.setState({ message: e.target.value, sendStatus: true })
    }

    sendMessage() {
        this.scrollToBottom();
        document.getElementsByClassName("boxSendChatMessage").value = ""
        let keyGen = new Date().getTime() + Math.floor(Math.random() * 10000000)
        if (this.state.message) {
            let variables = {
                "input": {
                    "messageSend": {
                        "roomGroupId": this.props.match.params.id,
                        "key": keyGen,
                        "message": this.state.message,
                        "image": ""
                    }
                }
            }
            let iAm = jwtDecode(localStorage.getItem("token"))
            let sendtoSocket = {
                "key": keyGen,
                "message": this.state.message,
                "image": "",
                "profile": this.state.image_profile,
                "role": iAm.user_id,
                "token": window.localStorage.getItem('token') || null
            }

            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        if (response.createRoomGroupMessageSend.ok) {
                            this.setState({
                                message: ''
                            },()=>{
                                chatSocket.send(JSON.stringify(sendtoSocket))
                                UpdateReadMessage(this.props.match.params.id)
                                variables = {}
                            })
                           
                        }

                    },
                },
            )

        }
    }

    onDelete(id) {
        Swal.fire({
            title: i18n.t("chatGroup:Are you sure to finish this chat?"), 
            imageUrl: process.env.PUBLIC_URL + "/images/iconAlerts/infoBlue.svg",
            // imageHeight: 1500,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: i18n.t("chatGroup:Cancel"), 
            confirmButtonText: i18n.t("chatGroup:Confirm"), 
        }).then((v)=> {
      
            let data = {
                "input": {
                    "roomGroup": {
                        "roomGroupId": this.props.match.params.id,
                        "name": this.state.data?.name,
                        "detail": this.state.data?.detail,
                        "status": "finished",
                        "note": "", 
                        "group": [],
                        "tag": {},
                        "image":[],
                        "document": []
                    }
                }
            }
            if(v.value){
                UpdateCreateChatMeeting(data, onSubmitSuccess, onSubmitError)
                this.props.history.push("/contact/committee/pending")
            }
             
        })
    }



    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/groupChat" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 2097152) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 2 MB (${i18next.t("Allaction:maximum of")} 3 ${i18next.t("Allaction:images")} )`, 'error')
            }

            else {

                let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                let fileOldList = _.cloneDeep(this.state.file);
                this.callUpload('', new_file).then((data) => {
                    let dese = "groupChat/" + new_file.name
                    // fileOldList.push({ fileId: "", fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location })
                    let keyGen = new Date().getTime() + Math.floor(Math.random() * 10000000)
                    let iAm = jwtDecode(localStorage.getItem("token"))
                    let variables = {
                        "input": {
                            "messageSend": {
                                "roomGroupId": this.props.match.params.id,
                                "key": keyGen,
                                "message": "",
                                "image": dese
                            }
                        }
                    }
                    let sendData = {
                        "repairsRoomId": this.props.match.params.id,
                        "key": keyGen,
                        "message": "",
                        "image": dese,
                        "profile": this.state.image_profile,
                        "role": iAm.user_id,
                        "token": window.localStorage.getItem('token') || null
                    }
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted: (response) => {
                                if (response.createRoomGroupMessageSend.ok) {
                                    chatSocket.send(JSON.stringify(sendData))
                                    UpdateReadMessage(this.props.match.params.id)

                                    variables = {}
                                }

                            },
                        },
                    )
                });
            }
        }
    }
    // END UPLOAD



    render() {

        let iAm = jwtDecode(localStorage.getItem("token"))
        let namePost = ""
        let ChkPost = ""
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ContactTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                        this.state.loading == false ?
                            <div className="container-fluid meetingChat" style={{ marginTop: '15px' }}>
                                {
                                    this.state.loading == false ?
                                        <div className="row justify-content-between chatGoupt">
                                            <div className="col-12" style={{ borderBottom: '2px solid #E9ECEF' }}>
                                                <h3>
                                                    <Link to={"/contact/committee/pending"}
                                                        style={{ lineHeight: 0.5 }}>
                                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back" />
                                                    </Link>
                                                    {this.state.data?.name}
                                                </h3>
                                            </div>


                                            <div className='col-8' style={{
                                                backgroundColor: "rgb(244,244,248)",
                                                paddingBottom: '30px'
                                            }}>
                                                {/* <p className='text-center'>
                                                                        {
                                                                            format(n.node.updated, "DD/MM/YYYY") === format(new Date(), "DD/MM/YYYY")?
                                                                            "วันนี้" : format(n.node.updated, "DD/MM/YYYY") 
                                                                        }
                                                                    </p> */}

                                                <div className='chatMessage' id="chatMessageFixRequest" >
                                                    {
                                                        this.state.message_list?.map((n, index) => {
                                                            let show_new_login = null
                                                            let user = n.node.user?.juristic || n.node.user?.tenant || n.node.user?.socket
                                                            // namePost = user.firstName + " " + user.lastName !== ChkPost ? user.firstName + " " + user.lastName : ChkPost
                                                            namePost = user.firstName + " " + user.lastName
                                                            if (namePost !== ChkPost) {
                                                                ChkPost = namePost
                                                                show_new_login = ChkPost
                                                            }

                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {
                                                                        parseFloat(iAm.user_id) === parseFloat(n.node.user.id) ?
                                                                            <div className='inlineChat active' style={{ float: 'right', textAlign: 'end' }}>

                                                                                <div style={{ margin: "1vw 2vw 0 0", flexDirection: 'row' }}>
                                                                                    {n.node.image ?
                                                                                        <div style={{ verticalAlign: 'bottom' }}>
                                                                                            <LightBox image={n.node.image}  width={200} height={"auto"} key={index} />
                                                                                            {/* <img src={n.node.image} style={{ maxWidth: '300px' }} /><br /> */}
                                                                                            <span className='timeChatSend'>{format(n.node.added, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                                        </div> : ""
                                                                                    }
                                                                                    {
                                                                                        n.node.message &&
                                                                                        <React.Fragment> 
                                                                                            {
                                                                                                n.node.message.length >= 100 ?
                                                                                                <div className='ChatP active' style={{ textAlign: "left"}}>{n.node.message || "-"}</div>
                                                                                                :
                                                                                                <React.Fragment>
                                                                                                    <span className='ChatP active'>{n.node.message || "-"}</span> <br/>
                                                                                                </React.Fragment>
                                                                                               
                                                                                            } 
                                                                                            <span className='timeChatSend'>{format(n.node.added, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                                        </React.Fragment>
                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='inlineChat' >
                                                                                <div className='' style={{ width: 60 }}>
                                                                                    {
                                                                                        show_new_login &&
                                                                                        (
                                                                                            user.image || n.node.message ?
                                                                                                <img src={user.image} width={50} height={50} className="rounded-circle" 
                                                                                                onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=process.env.PUBLIC_URL + "/images/icons/profileNull.png"; }}/>
                                                                                                :
                                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/profileNull.png'}
                                                                                                    className="rounded-circle" width={50} height={50} />
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                <div className='' style={{ width: 600 }}>
                                                                                    {show_new_login && namePost}
                                                                                    {show_new_login &&
                                                                                        <br />
                                                                                    }
                                                                                    <div style={{ margin: "1vw 4vw 0 0", flexDirection: 'row' }}>

                                                                                        {n.node.image ?
                                                                                            <div style={{ verticalAlign: 'bottom' }}>
                                                                                                <LightBox image={n.node.image}  style={{ maxWidth: '300px' }} key={index} />
                                                                                                {/* <img src={n.node.image} style={{ maxWidth: '300px' }} /><br /> */}
                                                                                                <span className='timeChatSend'>{format(n.node.added, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                                            </div> : ""
                                                                                        }

                                                                                        {
                                                                                            n.node.message &&
                                                                                            <React.Fragment>
                                                                                                {
                                                                                                    n.node.message.length >= 100 ?
                                                                                                    <div className='ChatP'>{n.node.message || "-"}</div>
                                                                                                    :
                                                                                                    <React.Fragment>
                                                                                                        <span className='ChatP'>{n.node.message || "-"}</span>
                                                                                                        <br/>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                               
                                                                                                <span className='timeChatSend'>{format(n.node.added, "DD/MM/YYYY - HH:mm น.")}</span>
                                                                                            </React.Fragment>

                                                                                        }


                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                    }

                                                                    <br />
                                                                    <div style={{ clear: 'both' }}></div>

                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }

                                                    <br />
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>

                                                <div style={{ clear: 'both' }}></div>

                                                {/* BOXCHAT */}


                                                <div style={{ marginTop: '3%' }}>
                                                    {
                                                        this.state.data?.status === "FINISHED" || this.state.data?.status === "CANCLED" ?
                                                            <div className='endChat'>จบการบทสนทนา</div>
                                                            :
                                                            <div className='row boxMessageChat'>
                                                                <div className='col-12 text-center' style={{ display: 'inline-flex' }}>
                                                                    <div style={{ display: 'inline-flex' }}>
                                                                        <label htmlFor="uploadFile" className="upload-btn-wrapper"
                                                                        >
                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/image.png"} width={25} height={25} />
                                                                            <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร'
                                                                                accept='application/.pdf,.png,.jpg' onChange={this.uploadFile}
                                                                            />

                                                                        </label>
                                                                    </div>

                                                                    <input type={"text"} className='form-control boxSendChatMessage' style={{ borderRadius: "24px", width: '95%', margin: '0 10px' }}
                                                                        onChange={this.onChange} onKeyPress={this.onkeyUp} value={this.state.message}
                                                                    />
                                                                    <button style={{ backgroundColor: "transparent", border: 'transparent' }} onClick={this.sendMessage}>
                                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/send.png"} width={25} height={25} /></button>
                                                                </div>
                                                            </div>
                                                    }

                                                </div>

                                            </div>
                                            <div className='col-4' style={{ height: '82vh', overflowY: 'auto' }}>
                                                <div className='boxWorkCurrent'>
                                                    {
                                                        this.state.data?.roomGroupTag?.edges?.map((n, Tagindx) => {
                                                            return (
                                                                <label className='tag tagBtnBlue' key={Tagindx}>{n.node.tag}</label>
                                                            )
                                                        })
                                                    }

                                                    <p className='pHeaderBox'>{this.state.data?.name}</p>
                                                    <p className='pDetailBox detail-text-newLine'>{this.state.data?.detail}</p>
                                                    <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                                        {
                                                            this.state.data?.roomGroupImage?.edges?.map((n, inxImg) => {
                                                                return (
                                                                    <LightBox image={n.node.fileUpload} className='pImg' key={inxImg} />
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                    {

                                                        (this.state.userCreateChk && this.state.data?.status === "PENDING" && this.state.data?.status !== "VOID" && this.state.data?.status !== "FINISHED") &&
                                                        // parseFloat(iAm.user_id) === parseFloat(this.state.data?.roomGroupNumberRead?.edges[0].node.user.id) &&
                                                        <React.Fragment>
                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/lineDash.png"} height={1} style={{ width: "100%" }} />
                                                            <div className='text-right' key={"dkjefe"}
                                                                onClick={() => this.onDelete(this.props.match.params.id)}>
                                                                <button className='pBTN'>{t("chatGroup:Close Meeting")}</button>
                                                            </div>
                                                        </React.Fragment>
                                                    } 
                                                </div>
                                                <p className='pWorkAgo'>{t("chatGroup:member")} ({this.state.data?.roomGroupNumberRead?.edges?.length})</p>
                                                {
                                                    this.state.data?.roomGroupNumberRead?.edges?.map((n, indexMember) => {
                                                        let firstName = n.node.user.juristic?.firstName || n.node.user.tenant?.firstName
                                                        let lastname =  n.node.user.juristic?.lastName || n.node.user.tenant?.lastName
                                                        let roleName = n.node.user.juristic?.role || n.node.user.tenant?.householder.edges[0].node.residential.name
                                                        return (
                                                            <div key={indexMember} className='row mb-5'>
                                                                <div className='col-2'>
                                                                    {
                                                                        n.node.user.juristic?.image || n.node.user.tenant?.image ?
                                                                            <img src={n.node.user.juristic?.image || n.node.user.tenant?.image} className="rounded-circle" width={50} height={50} />
                                                                            :
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/profileNull.png'}
                                                                                className="rounded-circle" width={50} height={50} />
                                                                    }
                                                                </div>
                                                                <div className='col-8  mt-1'>
                                                                    <p className='colorBlack mb-0'>{firstName} {lastname}</p>
                                                                    <p className='colorGray'>{roleName}</p>
                                                                </div>

                                                            </div>
                                                        )

                                                    })
                                                }



                                            </div>
                                        </div> :
                                        "LOADING.."
                                }



                            </div>

                            :
                            <div className="container-fluid box text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/icons/alert/loading.gif'} />
                                </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ChatGroup;
