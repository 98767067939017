import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import numberWithComma from "../../../libs/numberWithComma";
import Swal from "sweetalert2";
import { fetchQuery } from "relay-runtime";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import PayChannel from "../pay/payChannel";
import { Redirect } from "react-router";
import _ from "lodash";
import { formatDateLocale } from "../../../utils";
import LightBox from "../../../libs/lightBox";
import getNameResidential from "../../../libs/getNameResidential";
import Loading from "../../../libs/loading"
import i18next from "i18next";

const prepaidDepositView = graphql`
  query prepaidDepositViewQuery($id: ID!) {
    prepaidDeposit(id: $id) {
      id
      docNumber
      issuedDate
      status
      total
      taxType
      taxInvoiceNumber
      taxInvoiceDate
      taxInvoicePeriod
      refPrepaidDepositDoc
      refPrepaidDepositNumber
      contact {
        refNumber
        name
        firstName
        lastName
        registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        typeOfContact
      }
      remark
      prepaidDepositTransaction {
        edges {
          node {
            id
            chartOfAccount {
              id
              name
              chartOfAccountCode
            }
            vat
            whtRatePercent
            total
            price
            vatAmount
            whtAmount
            description
            prepaidDepositTransactionUsed {
              edges {
                node {
                  id
                  amount
                  prepaidDepositTransaction {
                    prepaidDeposit {
                      docNumber
                    }
                  }
                  purchaseRecord{
                    id
                    purchaseRecordGroup{
                      id
                      docNumber
                }
              }
                }
              }
            }
          }
        }
      }
      prepaidDepositTransactionUsed {
        edges {
          node {
            id
            amount
          }
        }
      }
      cashTransaction {
        edges {
          node {
            id
            price
          }
        }
      }
      chequeTransaction {
        edges {
          node {
            id
            date
            price
            chequeNumber
            bankAccount {
              id
              accountName
              branch
              bankName
            }
          }
        }
      }
      bankAccountTransaction {
        edges {
          node {
            id
            price
            date
            imageSlip
            bankAccount {
              bankName
              accountNumber
            }
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation prepaidDepositViewMutation(
    $input: UpdatePrepaidDepositAndTransactionStatusInput!
  ) {
    updatePrepaidDepositAndTransactionStatus(input: $input) {
      ok
      warningText
    }
  }
`;

class PrepaidDepositView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sum_total: 0,
      loading: false,
      warning_text: "",
      redirectToList: false,
    };

    this.onChangeStatusToVoid = this.onChangeStatusToVoid.bind(this);
  }

  onChangeStatusToVoid() {
    Swal.fire({
      title: i18next.t("PrepaidDepositView:Are you sure to cancel this document?"),
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18next.t("PrepaidDepositView:Confirm "),
      cancelButtonText: i18next.t("PrepaidDepositView:Cancel"),
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18next.t("PrepaidDepositView:Note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18next.t("PrepaidDepositView:Cancel"),
          confirmButtonText: i18next.t("PrepaidDepositView:Confirm "),
          inputValidator: (value) => {
            if (!value) {
              return i18next.t("PrepaidDepositView:Please enter reason to cancel");
            }
          },
        });

        if (void_remark) {
          this.setState({ loading: true });
          let variables = {
            input: {
              status: "void",
              prepaidDepositIdList: [this.props.match.params.id],
              voidRemark: void_remark,
            },
          };
          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              if (response) {
                if (response.updatePrepaidDepositAndTransactionStatus.ok) {
                  Swal.fire(
                    i18next.t("Allaction:Saved Successfully"),
                    "",
                    "success"
                  ).then(() => this.setState({ redirectToList: true }));
                } else {
                  Swal.fire(
                    i18next.t("Allaction:Canceled Unsuccessful"),
                    response.updatePrepaidDepositAndTransactionStatus
                      .warningText,
                    "warning"
                  );
                  this.setState({ loading: false });
                }
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), "", "warning");
            },
          });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }

  refreshData() {
    fetchQuery(environment, prepaidDepositView, {
      id: this.props.match.params.id,
    });
    this.setState({ loading: false });
  }

  getTaxType(key) {
    let tax = {
      NO_TAX: "ไม่มีภาษี",
      EXCLUDE_TAX: "แยกภาษี",
      INCLUDE_TAX: "รวมภาษี",
    };
    return tax[key];
  }

  calPriceVat(price, vat, taxType) {
    if (taxType === "EXCLUDE_TAX") {
      price = parseFloat(price) * (1 + parseFloat(vat || 0) / 100);
    }
    return price.toFixed(2);
  }

  getWht(transaction, taxType) {
    return (
      (this.calPriceVat(transaction.node.price, transaction.node.vat, taxType) *
        transaction.node.whtRatePercent) /
      100
    );
  }

  getVat(price, vat) {
    return parseFloat(price) * ((vat || 0) / 100);
  }

  render() {
    if (this.state.redirectToList) {
      return <Redirect to="/accounting/expense/prepaid-deposit/list/all" />;
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={prepaidDepositView}
            variables={{ id: this.props.match.params.id }}
            render={({ error, props }) => {
              if (error) {
                return <div>Error :(</div>;
              } else if (props) {
                return (
                  <div
                    className="container-fluid box"
                    key={props.prepaidDeposit.id}
                  >
                    <div className="row justify-content-between">
                      <div className="col">
                        <h3 className="mb-4">
                          <Link to="/accounting/expense/prepaid-deposit/list/all">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/wrapperContent/back.png"
                              }
                              alt="back"
                              className="back"
                            />
                          </Link>
                          <Translation>
                            {(t) => <span>{t("expense:prepaid_deposit")+" "+"(PD)"}</span>}
                          </Translation>
                        </h3>
                      </div>
                      <div className="col text-right">
                        {props.prepaidDeposit.status === "ACTIVE" &&
                          props.prepaidDeposit.prepaidDepositTransactionUsed
                            .edges.length === 0 &&
                          _.some(
                            JSON.parse(localStorage.getItem("permission_list")),
                            { codename: "accounting_prepaid_deposit_delete" }
                          ) && (
                            <button
                              className="btn btn-danger add mr-3"
                              disabled={this.state.loading}
                              onClick={this.onChangeStatusToVoid}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                              )}
                              {i18next.t("PrepaidDepositView:Cancel this document")}
                            </button>
                          )}
                        {_.some(
                          JSON.parse(localStorage.getItem("permission_list")),
                          { codename: "accounting_prepaid_deposit_print" }
                        ) && (
                            <Link
                              to={"/document/prepaid/" + props.prepaidDeposit.id + '/' + props.prepaidDeposit.docNumber}
                              target="_blank"
                            >
                              <button className="btn btn-primary add">
                                {i18next.t("PrepaidDepositView:Print")}
                              </button>
                            </Link>
                          )}
                      </div>
                    </div>
                    <Translation>
                      {(t) =>

                        <React.Fragment>
                          <div className="card">
                            <div className="card-body">

                              <div className="row">

                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("PrepaidDepositView:Accounts Payable")}</strong>
                                  </p>
                                  <p>
                                    {/* {props.prepaidDeposit.contact.refNumber}{" "}
                              {props.prepaidDeposit.contact.firstName}{" "}
                              {props.prepaidDeposit.contact.lastName} */}

                                    {props.prepaidDeposit.contact.typeOfContact === "RESIDENTIAL" ?
                                      getNameResidential(props.prepaidDeposit.contact.firstName, props.prepaidDeposit.contact.lastName)
                                      : props.prepaidDeposit.contact.name}{" "}
                                  </p>
                                </div>

                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("PrepaidDepositView:Document number")}</strong>
                                  </p>
                                  <p>{props.prepaidDeposit.docNumber} </p>
                                </div>

                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("PrepaidDepositView:Creation date")}</strong>
                                  </p>
                                  <p>
                                    {formatDateLocale(props.prepaidDeposit.issuedDate)}
                                  </p>
                                </div>

                              </div>

                              <div className="row">
                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("PrepaidDepositView:Invoice / Tax invoice number")}</strong>
                                  </p>
                                  <p>{props.prepaidDeposit.taxInvoiceNumber ? props.prepaidDeposit.taxInvoiceNumber : "-"}</p>
                                </div>

                                {/* <div className="col">
                            <p>
                              <strong>งวด</strong>
                            </p>
                            <p>
                              {formatDateLocale(props.prepaidDeposit.taxInvoicePeriod)}
                            </p>
                          </div> */}
                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("PrepaidDepositView:Date")}</strong>
                                  </p>
                                  <p>
                                    {props.prepaidDeposit.taxInvoiceNumber ? formatDateLocale(props.prepaidDeposit.taxInvoiceDate) : "-"}
                                  </p>
                                </div>

                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("PrepaidDepositView:Reference number")}</strong>
                                  </p>
                                  {props.prepaidDeposit.refPrepaidDepositNumber ||
                                    props.prepaidDeposit.refPrepaidDepositDoc ? (
                                    <React.Fragment>
                                      <span>
                                        {props.prepaidDeposit.refPrepaidDepositNumber}
                                      </span>
                                      <span className="ml-2">
                                        {props.prepaidDeposit
                                          .refPrepaidDepositDoc && (
                                            <a download="name_of_downloaded_file" href={props.prepaidDeposit.refPrepaidDepositDoc}>
                                              <div className="p-2">
                                                {props.prepaidDeposit.refPrepaidDepositDoc ? (
                                                  <LightBox
                                                    image={props.prepaidDeposit.refPrepaidDepositDoc}
                                                  />
                                                ) : (
                                                  <div></div>
                                                )}
                                                {/* <LightBox image={props.prepaidDeposit.refPrepaidDepositDoc} /> */}
                                              </div>
                                              {/* <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                    alt="back" className="download-file-icon"/> */}
                                            </a>
                                          )}
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <p>-</p>
                                  )}
                                </div>
                              </div>

                              <hr />

                              <div className="row">
                                <div className="col">
                                  <p>
                                    <strong>{i18next.t("PrepaidDepositView:Address")}</strong>
                                  </p>
                                  <p>
                                    {props.prepaidDeposit.contact?.registeredProvince.includes("กรุงเทพ") || props.prepaidDeposit.contact?.registeredProvince.includes("กทม") ? (
                                      <>
                                        {props.prepaidDeposit.contact.registeredAddress}
                                        {props.prepaidDeposit.contact.registeredDistrict && ` แขวง${props.prepaidDeposit.contact.registeredDistrict}`}
                                        {props.prepaidDeposit.contact.registeredCity && ` เขต${props.prepaidDeposit.contact.registeredCity}`}
                                        {props.prepaidDeposit.contact.registeredProvince && ` ${props.prepaidDeposit.contact.registeredProvince} `}
                                        {props.prepaidDeposit.contact.registeredPostalCode}{" "}
                                        {props.prepaidDeposit.contact.registeredCountry}
                                      </>
                                    ) : (
                                      <>
                                        {props.prepaidDeposit.contact.registeredAddress}
                                        {props.prepaidDeposit.contact.registeredDistrict && ` ตำบล${props.prepaidDeposit.contact.registeredDistrict}`}
                                        {props.prepaidDeposit.contact.registeredCity && ` อำเภอ${props.prepaidDeposit.contact.registeredCity}`}
                                        {props.prepaidDeposit.contact.registeredProvince && ` จังหวัด${props.prepaidDeposit.contact.registeredProvince} `}
                                        {props.prepaidDeposit.contact.registeredPostalCode}{" "}
                                        {props.prepaidDeposit.contact.registeredCountry}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              {props.prepaidDeposit.prepaidDepositTransaction.edges.map((transaction) => {
                                // let price = transaction.node.price;
                                // let total = transaction.node.total - this.getWht(transaction, props.prepaidDeposit.taxType);
                                let total = transaction.node.total;
                                return (
                                  <div className="mt-3" key={transaction.node.id}>
                                    {/*<h5>{this.getHeaderName(transaction.node.chartOfAccount.chartOfAccountCode)}</h5>*/}
                                    {/* <h5>{transaction.node.chartOfAccount.name}</h5> */}
                                    <div className="table-responsive fade-up">
                                      <table className="table table-hover mt-2">
                                        <thead className="thead-light">
                                          <tr>
                                            <th width="250">{i18next.t("PrepaidDepositView:List")}</th>
                                            <th width="150">{i18next.t("PrepaidDepositView:Description")}</th>
                                            <th width="80" className="text-right">
                                              {!(this.state.taxType === "NO_TAX") ? i18next.t("PrepaidDepositView:Sum before tax") : i18next.t("PrepaidDepositView:Sum Amount")}
                                            </th>
                                            <th width="80" className="text-right">
                                              {i18next.t("PrepaidDepositView:TAX")}
                                            </th>
                                            <th width="80" className="text-right">
                                              {i18next.t("PrepaidDepositView:Amount")}
                                            </th>
                                            <th width="80" className="text-right">
                                              {i18next.t("PrepaidDepositView:Amount spent")}
                                            </th>
                                            <th width="80" className="text-right">
                                              {i18next.t("PrepaidDepositView:Balance amount")}
                                            </th>
                                            <th width="80" className="text-right">
                                              {i18next.t("PrepaidDepositView:Withholding")}
                                            </th>
                                            <th width="80" className="text-right">
                                              {i18next.t("PrepaidDepositView:Total Amount")}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              {transaction.node.chartOfAccount.chartOfAccountCode + " " + transaction.node.chartOfAccount.name}
                                            </td>
                                            <td>{transaction.node.description}</td>
                                            {/*<td className="text-right">{numberWithComma(transaction.node.price)}</td>*/}
                                            <td className="text-right">
                                              {numberWithComma(transaction.node.price)}
                                            </td>
                                            <td className="text-right">
                                              {numberWithComma(transaction.node.vatAmount)}
                                            </td>
                                            <td className="text-right">
                                              {numberWithComma(transaction.node.total)}
                                            </td>
                                            <td className="text-right">
                                              {numberWithComma(transaction.node.amountUsed)}
                                            </td>
                                            <td className="text-right">
                                              {numberWithComma(0)}
                                            </td>
                                            <td className="text-right">
                                              {numberWithComma(transaction.node.whtAmount)}
                                            </td>
                                            <td className="text-right">
                                              {numberWithComma(transaction.node.total - this.getWht(transaction, props.prepaidDeposit.taxType))}
                                            </td>
                                          </tr>

                                          {transaction.node.prepaidDepositTransactionUsed.edges.map((used) => {
                                            // price = price - used.node.amount;
                                            // let amount_vat = parseFloat(used.node.amount) + parseFloat(used.node.amount * (transaction.node.vat | 0) / 100)
                                            // amount_vat_wht = amount_vat_wht + parseFloat(used.node.amount * (transaction.node.whtRatePercent | 0) / 100)
                                            // total = total - amount_vat_wht
                                            let amount_vat = used.node.amount;
                                            let pr_docnumber = used.node.purchaseRecord.purchaseRecordGroup.docNumber

                                            total = Math.round(((total - amount_vat) + Number.EPSILON) * 100) / 100;
                                            return (
                                              <tr key={used.node.id}>
                                                <td>
                                                  {transaction.node.chartOfAccount.chartOfAccountCode + " " + transaction.node.chartOfAccount.name + " (" + pr_docnumber + ")"}
                                                </td>
                                                <td>
                                                  {transaction.node.description}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(0)}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(0)}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(0)}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(amount_vat)}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(total)}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(0)}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithComma(0)}
                                                </td>
                                              </tr>
                                            );
                                          }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                );
                              }
                              )}
                            </div>
                          </div>

                          {props.prepaidDeposit.status !== "VOID" && (
                            <PayChannel
                              total={props.prepaidDeposit.total}
                              bankTransaction={
                                props.prepaidDeposit.bankAccountTransaction.edges
                              }
                              cashTransaction={
                                props.prepaidDeposit.cashTransaction.edges
                              }
                              chequeTransaction={
                                props.prepaidDeposit.chequeTransaction.edges
                              }
                              account_type="pay"
                            />
                          )}
                        </React.Fragment>
                      }
                    </Translation>


                  </div>
                );
              }
              return <Loading />
            }}
          />
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PrepaidDepositView;
