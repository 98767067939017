import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import ContactReportList from "../contactPayableReportList";
import PurchaseReportTable from "./purchaseReportTable";
import ExportPurchaseReport from "./server_export";
import _ from "lodash";
import ProductServiceList from "../productServicePayableList";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from '../../../../components/BackBtn/indexBack';
import i18next from "i18next";

const query = graphql`
  query purchaseReportQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $search: String
    $contact: String
    $status: String
    $productAndService: String
  ) {
    allPurchaseRecordGroup(
      productAndService: $productAndService
      startDate: $startDate
      endDate: $endDate
      first: $first
      last: $last
      search: $search
      contact: $contact
      status: $status
      order: "doc_number"
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          dueDate
          status
          contact {
            refNumber
            name
          }
          purchaseRecord {
            edges {
              node {
                id
                description
                total
                paid
                price
                preTaxAmount

                payRecord(payRecordGroup_Status: "paid") {
                  edges {
                    node {
                      id
                      payRecordGroup {
                        id
                        docNumber
                      }
                    }
                  }
                }

                acceptCreditNoteTransaction(status: "paid") {
                  edges {
                    node {
                      id
                      docNumber
                    }
                  }
                }

                prepaidDepositTransactionUsed {
                  edges {
                    node {
                        id
                      prepaidDeposit {
                        id
                        docNumber
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    summaryPurchaseRecordGroup(
      startDate: $startDate
      endDate: $endDate
      search: $search
      contact: $contact
      status: $status
      report: true
      productAndService: $productAndService
    )
  }
`;

class PurchaseReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.status = "";
    this.state.temp_status = "";
    this.state.temp_product_and_service = "";
    this.state.product_and_service = "";

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "purchaseReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      contact: this.state.temp_contact,
      status: this.state.temp_status,
      product_and_service: this.state.temp_product_and_service,
    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-payable"}
                  LinkText={i18next.t("DashboardPayable:Expense Configuration Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_payable_print",
                      }) && <ExportPurchaseReport state={this.state} />}
                    </div>
                  }

                />
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactReportList
                            handleChange={this.handleChange}
                            contact={this.state.temp_contact}
                          />

                          <ProductServiceList
                            handleChange={this.handleChange}
                            product_and_service={
                              this.state.temp_product_and_service
                            }
                          />

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Status")}</label>
                              <select
                                name="temp_status"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("FilterPayable:All")}</option>
                                <option value="active">{i18next.t("FilterPayable:Unpaid")}</option>
                                <option value="paid">{i18next.t("FilterPayable:Paid")}</option>
                                <option value="partial_payment">{i18next.t("FilterPayable:Partial Payment")}</option>
                                <option value="waiting_pay">{i18next.t("FilterPayable:Payment Pending")}</option>
                                <option value="void">{i18next.t("FilterPayable:Canceled Transaction")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <QueryRenderer
                    environment={environment}
                    query={query}
                    variables={{
                      first: this.state.first,
                      last: this.state.last,
                      startDate: this.state.start_date,
                      endDate: this.state.end_date,
                      contact: this.state.contact,
                      search: this.state.search,
                      status: this.state.status,
                      productAndService: this.state.product_and_service,
                    }}
                    render={({ error, props }) => {
                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover"
                                      style={{ fontSize: 12 }}
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th className="text-center">{i18next.t("PurchaseReport:Order")}</th>
                                          <th className="text-center">{i18next.t("PurchaseReport:Expense Setting Record Number")}</th>
                                          <th className="text-center">{i18next.t("PurchaseReport:Date Issued")}</th>
                                          <th className="text-center">{i18next.t("PurchaseReport:Due Date")}</th>
                                          <th>{i18next.t("PurchaseReport:Creditor Code")}</th>
                                          <th>{i18next.t("PurchaseReport:Name")}</th>
                                          <th>{i18next.t("PurchaseReport:Item")}</th>
                                          <th className="text-center">{i18next.t("PurchaseReport:Status")}</th>
                                          <th className="text-right">{i18next.t("PurchaseReport:Amount")}</th>
                                          {this.state.status === 'partial_payment' && <>
                                            <th className="text-right">{i18next.t("PurchaseReport:Paid")}</th>
                                            <th className="text-right">{i18next.t("PurchaseReport:Balance")}</th>
                                          </>}
                                          <th className="text-right">
                                          {i18next.t("PurchaseReport:Total Amount")}
                                          </th>
                                          <th className="text-right">
                                          {i18next.t("PurchaseReport:Payment Number")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <PurchaseReportTable
                                          props={props}
                                          state={this.state}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.allPurchaseRecordGroup.totalCount}
                              />
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PurchaseReport;
