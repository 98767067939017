import React, { Component } from 'react'
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import { format } from "date-fns";
import getDateTime from "../../../libs/getDateTime";
import _ from 'lodash'
import { Translation } from 'react-i18next';
export default class registrationDate extends Component {

    handleChangInput = (e) => {

        this.props.onChange({
            target: {
                name: e.target.name,
                value: e.target.value,
            }
        });
    }

    render() {
      
        return (
            <Translation>
                {
                    t =>
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-4">
                                            <p>{t("PageDetailProjectInfo:RegistrationDate")}</p>
                                        </div>
                                        <div className="col-8">
                                            {this.props.editProjectInfo
                                                ?
                                                <DatePickerAdapter
                                                    id="temp_schedule_date"
                                                    className="form-control"
                                                    name="projectInfo.registrationDate"
                                                    selected={this.props.projectInfo.registrationDate !== '' ? getDateTime(this.props.projectInfo.registrationDate) : ''}
                                                    onChange={this.handleChangInput}
                                                />
                                                :
                                                <span>{this.props.projectInfo.registrationDate !== '' && format(this.props.projectInfo.registrationDate, "DD/MM/YYYY")}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <p>{t("PageDetailProjectInfo:DateOfCompletion")}</p>
                                        </div>
                                        <div className="col-8">
                                            {this.props.editProjectInfo
                                                ?
                                                <DatePickerAdapter
                                                    id="temp_schedule_date"
                                                    className="form-control"
                                                    name="projectInfo.bringForwardStartDate"
                                                    selected={getDateTime(this.props.projectInfo.bringForwardStartDate)}
                                                    onChange={this.handleChangInput}
                                                />
                                                :
                                                <span >{format(this.props.projectInfo.bringForwardStartDate, "DD/MM/YYYY")}</span>
                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <p>{t("PageDetailProjectInfo:BuildingAge")}</p>
                                        </div>
                                        <div className="col-8">
                                            {this.props.editProjectInfo
                                                ?
                                                <input type="text"
                                                    className="form-control form-control-sm col-8"
                                                    name="projectInfo.registrationDates.projectLife"
                                                    value={this.props.projectInfo.projectLife}
                                                    onChange={this.handleChangInput}
                                                    disabled={true}
                                                />
                                                :
                                                <span>{this.props.projectInfo.projectLife}</span>

                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <p>{t("PageDetailProjectInfo:RegistrationNo")}</p>
                                        </div>
                                        <div className="col-8">
                                            {this.props.editProjectInfo
                                                ?
                                                <input type="text"
                                                    className="form-control form-control-sm col-8"
                                                    name="projectInfo.registrationNumber"
                                                    value={this.props.projectInfo.registrationNumber}
                                                    onChange={this.handleChangInput}
                                                />
                                                :
                                                <span >{this.props.projectInfo.registrationNumber}</span>

                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <p>{t("PageDetailProjectInfo:Tax")}</p>
                                        </div>
                                        <div className="col-8">
                                            {this.props.editProjectInfo
                                                ?
                                                <div className="custom-control custom-checkbox custom-control-inline" >
                                                    <input type="checkbox" className="custom-control-input"
                                                        id="customCheckCheque" checked={this.props.projectInfo.haveVat}
                                                        onChange={this.handleChangInput} name="projectInfo.haveVat"
                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="customCheckCheque">{t("PageDetailProjectInfo:HaveTax")}</label>
                                                </div>
                                                :
                                                <span>{this.props.projectInfo.haveVat ? t("PageDetailProjectInfo:Have") : t("PageDetailProjectInfo:No")}</span>

                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <p>{t("PageDetailProjectInfo:TaxId")}
                                            {this.props.editProjectInfo &&
                                                <label className="tooltipsSpan mb-0" style={{padding:'4px 2px',top:'-9px'}}> <img src={process.env.PUBLIC_URL + '/images/iconAction/light.png'} width={10} />
                                                    <span className='tooltiptext text_FFFFFF bg-000000 w350 text-left' >{t("project:If the information is not filled in, it will not appear in the document.")}</span>
                                                </label> 
                                             }
                                            </p>
                                        </div>
                                        <div className="col-8">
                                            {this.props.editProjectInfo
                                                ? 
                                                <>
                                                    <input type="text"
                                                        className="form-control form-control-sm col-8"
                                                        name="projectInfo.taxIdNumber"
                                                        required
                                                        value={this.props.projectInfo.taxIdNumber}
                                                        onChange={this.handleChangInput}
                                                    />
                                                </>
                                                
                                                :
                                                <span>{this.props.projectInfo.taxIdNumber}</span>

                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <p>{t("PageDetailProjectInfo:Type of Status")}</p>
                                        </div>
                                        <div className="col-8">
                                            {this.props.editProjectInfo
                                                ?
                                                <select className="form-control col-6" name="projectInfo.typeOfCompany"
                                                    value={_.toLower(this.props.projectInfo.typeOfCompany)}
                                                    onChange={this.handleChangInput}>
                                                    <option value="property_management">{t("PageDetailProjectInfo:LimitedCompanyThatManagesJuristicPersons")}</option>
                                                    <option value="developer">{t("PageDetailProjectInfo:ProjectDeveloper")}</option>
                                                    <option value="manage_yourself">{t("PageDetailProjectInfo:CommiteeInChargeOfProjectManagement")}</option>
                                                    <option value="commercial">{t("PageDetailProjectInfo:Commercial")}</option>
                                                </select>
                                                :
                                                <select className="form-control col-6" name="projectInfo.typeOfCompany"
                                                    value={_.toLower(this.props.projectInfo.typeOfCompany)}
                                                    onChange={this.handleChangInput}
                                                    disabled>
                                                    <option value="property_management">{t("PageDetailProjectInfo:LimitedCompanyThatManagesJuristicPersons")}</option>
                                                    <option value="developer">{t("PageDetailProjectInfo:ProjectDeveloper")}</option>
                                                    <option value="manage_yourself">{t("PageDetailProjectInfo:CommiteeInChargeOfProjectManagement")}</option>
                                                    <option value="commercial">{t("PageDetailProjectInfo:Commercial")}</option>
                                                </select>

                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <p>{t("PageDetailProjectInfo:EstablishmentOfaLegalEntity")} </p>
                                        </div>
                                        <div className="col-8">
                                            {this.props.editProjectInfo
                                                ?
                                                <select className="form-control col-6" name="projectInfo.establishmentStatus"
                                                    value={_.toLower(this.props.projectInfo.establishmentStatus)}
                                                    onChange={this.handleChangInput}>
                                                    <option value="allocated">{t("PageDetailProjectInfo:Allocated")}</option>
                                                    <option value="unallocated">{t("PageDetailProjectInfo:Unallocated")}</option>
                                                    <option value="other">{t("PageDetailProjectInfo:Other")}</option>
                                                </select>
                                                :
                                                <select className="form-control col-6" name="projectInfo.establishmentStatus"
                                                    value={_.toLower(this.props.projectInfo.establishmentStatus)}
                                                    onChange={this.handleChangInput}
                                                    disabled>
                                                    <option value="allocated">{t("PageDetailProjectInfo:Allocated")}</option>
                                                    <option value="unallocated">{t("PageDetailProjectInfo:Unallocated")}</option>
                                                    <option value="other">{t("PageDetailProjectInfo:Other")}</option>
                                                </select>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                }
            </Translation>

        )
    }
}
