import React from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import AssetListTable from "./assetListTable"
import "react-datepicker/dist/react-datepicker.css";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import _ from "lodash";
import Navigation from "./navigation";
import {format, setDate, setMonth, lastDayOfMonth} from "date-fns";
import SearchProject from '../../project/searchProject';
import { Dropdown  } from "react-bootstrap";
import environment from "../../env/environment";
import Pagination from "../../libs/newPagination";
import ComponentPagination from '../../libs/componentPagination';
import Loading from "../../libs/loading"
import { fetchQuery } from "relay-runtime";


const query = graphql`
    query assetListQuery($asset_type:String, $date:Date, $first: Int, $last: Int, $search: String) {
        allAsset(type: $asset_type, first: $first, last: $last, search: $search) {
            totalCount
            edges {
                node {
                    id
                    purchaseDate
                    docNumber
                    storage
                    saleStatus
                    seller{
                        firstName
                        lastName
                        
                        name
                    }
                    sellerOther
                    chartOfAccountType {
                        chartOfAccountCode
                        name
                    }
                    name
                    costPrice
                    carcassPrice
                    calDepreciation
                    description
                    historicalAmount
                    depreciationRatePercent
                    type
                    assetDepreciate(date_Lte:$date) {
                        edges {
                            node {
                                id
                                depreciatePrice
                            }
                        }
                    }
                    remark
                    internalAssetCode
                    approveAsset{ 
                        edges{
                            node{
                                id
                                approveStatus
                                approver
                                approveDate
                                approveTime
                                approveTerm
                                costPrice
                                remark
                            }
                        }
                    }
                }
            }
        }
        fixAssetDepreciate
    }
`;


class AssetList extends ComponentPagination {
    constructor(props){
        super(props);
        this.state = {
            search : '',
            end_date : new Date(),
            last_date : lastDayOfMonth(setDate(setMonth(new Date(), new Date().getMonth() - 1), 1)),
            checkListArray: '',
            first: this.state.first,
            last: this.state.last,
            data: "",
            loading: false,
        }
        this.handleSearch = this.handleSearch.bind(this)
        this.handleCheckListUpdate = this.handleCheckListUpdate.bind(this);
    }

    componentDidMount(){
        let last_day_date = lastDayOfMonth(setMonth(this.state.end_date,this.state.end_date.getMonth())).getDate()
        if(this.state.end_date.getDate() === last_day_date){
            let last_date = lastDayOfMonth(setMonth(this.state.end_date, this.state.end_date.getMonth()));
            this.setState({last_date:last_date})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.asset_type !== this.props.match.params.asset_type) {
          this.getData();
        } else if (
          prevState.first !== this.state.first ||
          prevState.last !== this.state.last ||
          prevState.search !== this.state.search
        ) {
          this.getData();
        }
      }

      async componentDidMount() {
        this.getData()
      }
    
      getData = () => {
        this.setState({ loading: true })
        const variables = {
            asset_type: this.props.match.params.asset_type,
            date: format(this.state.last_date, 'YYYY-MM-DD'),
            first: this.state.first,
            last: this.state.last,
            search: this.state.search,
        };
        fetchQuery(environment, query, variables).then((response_data) => {
            this.setState({ data: response_data, loading: false });
        });
    
      }  

    handleSearch(text){
        this.setState({search: text})
    }

    handleCheckListUpdate(checkList) {
        this.setState({ checkListArray: checkList });
    }


  onClickGetData(data) {
    this.props.history.push(`/accounting/asset/assetPrintQrCode/selected`,{ data: data})
  }

    render() {
        const { data } = this.state;
        const { loading } = this.state;
    
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="asset-style">
                        {!loading ? (
                            data && (
                                <React.Fragment>
                                {!data.error ? (
                                    <React.Fragment>
                                    <div className="row justify-content-between">
                                        <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/accounting">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back"
                                                className="back"
                                            />
                                            </Link>
                                            <Translation>{t => <span>{t('accounting:asset')}</span>}</Translation>
                                        </h3>
                                        </div>
                                        {/* Import Excel */}
                                        <div className="col btnContainer">
                                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'assetLegacy'])?.menu_on &&
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_asset_create' }) && (
                                            <React.Fragment>
                                                <Dropdown>
                                                <Dropdown.Toggle id="dropdown-item-button" className="mr-4" bsPrefix='dropdown-export dropdown-toggle'>
                                                    <Translation>{t => <span>{t("Allaction:Print")} QRCode</span>}</Translation>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item className="dropdownCancel" onClick={()=>this.onClickGetData("")}>
                                                    <div className="dropdown">
                                                    <Translation>{t => t("assetDetail:Choose item all")}</Translation>
                                                    </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="dropdownCancel" onClick={()=>this.onClickGetData(this.state.checkListArray)}>
                                                    <div className="dropdown">
                                                    <Translation>{t => t("assetDetail:Choose item(s)")}</Translation>
                                                    </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                                </Dropdown>
                                                <button type="button" className="btn btn-primary add">
                                                <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus" />
                                                <Link to={"/accounting/asset/" + this.props.match.params.asset_type + "/form"}>
                                                    <Translation>{t => <span style={{ color: "white" }}>{t('accounting:add asset')}</span>}</Translation>
                                                </Link>
                                                </button>
                                            </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                    <Translation>
                                        {(t)=> 
                                        <React.Fragment>
                                            <Navigation asset_type={this.props.match.params.asset_type} />
                                            <div className="row mb-2">
                                                <div className="col-9" />
                                                <div className="col-3 mt-1">
                                                <SearchProject callback_function={this.handleSearch} search={this.state.search} />
                                                </div>
                                            </div>

                                            <div className="content-inner">
                                                <AssetListTable
                                                props={data}
                                                query={query}
                                                asset_type={this.props.match.params.asset_type}
                                                search={this.state.search}
                                                last_date={this.state.last_date}
                                                onCheckListUpdate={this.handleCheckListUpdate}
                                                />
                                                <div className='row'>
                                                <Pagination
                                                    changePage={this.changePage}
                                                    first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={data.allAsset.totalCount}
                                                />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        
                                        
                                        }
                                    </Translation> 
                                    </React.Fragment>
                                ) : (
                                    <div>{data.error.message}</div>
                                )}
                                </React.Fragment>
                            )
                            ) : (
                            <Loading />
                            )}
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
    
}


export default AssetList;