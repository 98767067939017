import '../../report.scss';
import React from 'react';
import _ from "lodash";
import { Translation } from "react-i18next";
import Loading from "../../../../libs/loading";
import numberWithCommas from "../../../../libs/numberWithComma";
import accountTypeText from '../../../../libs/accountTypeText'
import { H6, P2 } from '../../../../components/Text/Text'
import { format } from 'date-fns';
import jwtDecode from 'jwt-decode'
import './bankDeposit.scss';
import i18next from 'i18next';
import { Link } from 'react-router-dom';




let page = 0;
const BankDepositImpactReportTable = ({ all_bank, summary_report_residential, summary_report_supplier, summary_bank_report, summary_balance, loading, date, handleSummary, lastPage, report, lastPageNumber, reportType }) => {
  const jwtDecodes = jwtDecode(window.localStorage.getItem('token'));
  const printBy = jwtDecodes.first_name + " " + jwtDecodes.last_name;

  if (lastPageNumber && page === lastPageNumber) {
    page = 0;
  }

  if (lastPageNumber !== undefined) {
    page++
  }

  let spaceTop = (page === 1 || page === lastPageNumber) ? 'spaceTop' : 'spaceTopNotFirstPage'
  let monthYear = format(date, "MM-YYYY")
  return (
    <React.Fragment>
      {!loading ?
        <div id='bank-deposit-report-main'>
          <div className="fade-up mt-5 tabel-main">
            <div className="table-responsive">
              <table className="table table-hover colorUse notKanit">
                <thead className="thead-light">
                  <Translation>{(t) =>
                    <tr>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:No.")}</H6></th>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:Financial Institution")}</H6></th>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:Branch")}</H6></th>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:Account Type")}</H6></th>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:Account Number")}</H6></th>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:Opening Balance")}</H6></th>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:Revenue")}</H6></th>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:Expense")}</H6></th>
                      <th className="text-center"><H6 fontSize={report ? '12px' : ''}>{i18next.t("report_finance:Remaining Balance")}</H6></th>
                    </tr>
                  }</Translation>
                </thead>
                <tbody>
                  {_.map(all_bank, (item, index) => {
                    return (
                      <Translation key={index}>
                        {(t) =>
                          <tr className='fs-16'>
                            <td className="text-center" width='60'><P2 fontSize={report ? '12px' : ''}>{index + 1}</P2></td>
                            <td className="text-left text_1567B4" style={{ minWidth: 170 }}>
                              <Link className='text_1567B4'
                                  to={`/accounting/report/finance/bank-deposit-impact-report/${_.get(item, "node.id")}/${monthYear}/${encodeURIComponent(_.get(item, "node.bankName"))}/${_.get(item, "node.accountType")}/${_.get(item, "node.accountNumber")}`}>
                                  {_.get(item, "node.bankName")}
                              </Link>
                            </td>
                            <td className="text-left" style={{ minWidth: 170 }}><P2 fontSize={report ? '12px' : ''}>{_.get(item, "node.branch")}</P2></td>
                            <td className="text-left" style={{ minWidth: 150 }}><P2 fontSize={report ? '12px' : ''}>{t(accountTypeText(_.get(item, "node.accountType")))}</P2></td>
                            <td className="text-center" style={{ minWidth: 150, width: 150 }}><P2 fontSize={report ? '12px' : ''}>{_.get(item, "node.accountNumber")}</P2></td>
                            <td className="text-right" width='120'><P2 fontSize={report ? '12px' : ''}>{numberWithCommas(JSON.parse(_.get(item, "node.summaryBankTransaction")).previous_balance)}</P2></td>
                            <td className="text-right" width='120'><P2 fontSize={report ? '12px' : ''}>{numberWithCommas(JSON.parse(_.get(item, "node.summaryBankTransaction")).total_debit)}</P2></td>
                            <td className="text-right" width='120'><P2 fontSize={report ? '12px' : ''}>{numberWithCommas(JSON.parse(_.get(item, "node.summaryBankTransaction")).total_credit)}</P2></td>
                            <td className="text-right" width='120'><P2 fontSize={report ? '12px' : ''}>{numberWithCommas(JSON.parse(_.get(item, "node.summaryBankTransaction")).balance)}</P2></td>
                          </tr>
                        }
                      </Translation>
                    )
                  })}
                  {lastPage && <tr className='td-sum' >
                    <td colSpan={8} className="text-right" ><H6 fontSize={report ? '12px' : ''}>รวมจำนวนเงิน</H6></td>
                    <td className="text-right" ><P2 fontSize={report ? '12px' : ''}>{numberWithCommas(summary_balance)}</P2></td>
                  </tr>}
                </tbody>
              </table>
            </div>
          </div>
          {reportType === 'pdf' && page !== lastPageNumber &&
            <div className={`footer ${spaceTop}`}>
              <div className='row'>
                <div className='col-5' />
                <div className='col-2 text-center'>
                  <p className='f14 pb-0 mb-0'>{page}  /  {lastPageNumber}</p>
                </div>
                <div className='col-5 text-right'>
                  <p className='f14 pb-0 mb-0'>ผู้พิมพ์ {printBy} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
                </div>
              </div>
            </div>
          }
        </div> : <Loading />}

    </React.Fragment>
  );
};

export default BankDepositImpactReportTable;