import React, { Component } from 'react';
import SilvermanAgentStep from './silvermanAgentStep'
import './silvermanAgent.scss'


class SilvermanAgentDetail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            autoCheckOther:true,
        }
    }
    
    render() {
        return (
            <>
            <div id="svm-agent-status">
                <div style={{backgroundColor:'#fff'}} className='p-4'>
                    <SilvermanAgentStep svmAgentStepFinish2={this.props.svmAgentStepFinish2} lang={this.props.lang}/>
                    {/* <div>
                        <div>
                            <h5>ชื่อประกาศ <span className='text-danger'>*</span></h5>
                            <div className='pb-3'>
                                <span>ชื่อหัวเรื่อง <span className='text-danger'>*</span></span>
                                <input type="text" className="form-control" placeholder='ชื่อโครงการ' id="topic" name='topic' onChange={this.props.handleInputChange}/>
                                {
                                    this.props.topicAlert ? <span className='text-red'>กรุณากรอกชื่อหัวเรื่อง</span> :''
                                }
                            </div>
                            <div>
                                <span>รายละเอียด</span>
                                <textarea type="text" className="form-control" row="5" placeholder='รายละเอียด' name='description'  onChange={this.props.handleInputChange} />
                            </div>
                        </div>
                    </div> */}
                {/* </div> */}
                {/* <div style={{backgroundColor:'#fff'}} className='p-4'> */}
                        <div>
                            <h5> {this.props.lang === 'en' ? 'Property information':'ข้อมูลทรัพย์'}<span className='text-danger'>*</span><small> {this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</small></h5>
                            {this.props.propertyType !== 'land' ?  
                                <div>
                                    <span>{this.props.lang === 'en' ? 'Size Area (sq.m.)':'ขนาดพื้นที่ (ตร.ม.)'} <span className='text-danger'>*</span></span>
                                    <input type="text" className="form-control" name='sizeArea' id="sizeArea" placeholder={this.props.lang === 'en' ? 'Size Area (sq.m.)':'ขนาดพื้นที่ (ตร.ม.)'}
                                     onChange={this.props.handleInputChange}/> 
                                   
                                </div>
                                :'' 
                            }
                            
                            <div>
                                <div className='row mt-3'>
                                {/* {this.props.propertyType !== 'land' ? */}
                                 <>
                                    <div className='col'>
                                        <span> {this.props.lang === 'en' ? 'Bedroom':'ห้องนอน'} <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" placeholder={this.props.lang === 'en' ? 'Bedroom':'ห้องนอน'} name="bedroom" onChange={this.props.handleInputChange} />
                                    </div>
                                    <div className='col'>
                                        <span> {this.props.lang === 'en' ? 'Bathroom':'ห้องน้ำ'}<span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" placeholder={this.props.lang === 'en' ? 'Bathroom':'ห้องน้ำ'}  name="bathroom" onChange={this.props.handleInputChange} />
                                    </div>
                                    {/* { 
                                    this.props.propertyType !== 'warehouse' ?
                                            <div className='col'>
                                            <span>{ this.props.propertyType === 'home' || this.props.propertyType === 'building' || this.props.propertyType === 'townhouse' || 
                                        this.props.propertyType === 'homeoffice' || this.props.propertyType === 'market' || this.props.propertyType === 'hotel'  ? 'จำนวนชั้น' :'ลำดับชั้น'} </span>
                                            <input type="text" className="form-control" placeholder={ this.props.propertyType === 'home' || this.props.propertyType === 'building' || this.props.propertyType === 'townhouse' || 
                                        this.props.propertyType === 'homeoffice' || this.props.propertyType === 'market' || this.props.propertyType === 'hotel'  ? 'จำนวนชั้น' :'ลำดับชั้น'}  
                                        name='floor' onChange={this.props.handleInputChange}  />
                                            </div>
                                        :''
                                    } */}

                               
                                    {
                                    this.props.userType === 'owner' ? 
                                        <div className='col'>
                                        <span>{this.props.lang === 'en' ? 'Floor':'จำนวนชั้น'}<span className='text-danger'>*</span> </span>
                                        <input type="text" className="form-control" placeholder={this.props.lang === 'en' ? 'Floor':'จำนวนชั้น'} name='floor' onChange={this.props.handleInputChange}  />
                                        </div>
                                        :''
                                    }
                                   
                                    
                                    </>
                                    {/* :''   
                                } */}
                                
          
                                {/* {this.props.propertyType === 'land' ?
                                    <>
                                    <div className='col'>
                                        <span>ไร่ <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" name='rai' placeholder='ไร่'  onChange={this.props.handleInputChange} />
                                    </div>
                                    <div className='col'>
                                        <span>งาน <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" name='ngan' placeholder='งาน' onChange={this.props.handleInputChange} />
                                    </div>
                                    <div className='col'>
                                        <span>ตารางวา <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" name='squareWa' placeholder='ตารางวา'  onChange={this.props.handleInputChange} />
                                    </div>
                                    </>
                                    :''
                                } */}
                                   
                                </div>
                                
                                <div className='mt-3'>
                                    <span>{this.props.lang === 'en' ? 'Floor Size(sq.m.)':'ขนาด (ตร.ม.)'}</span>
                                    <input type="text" className="form-control" placeholder={this.props.lang === 'en' ? 'Floor Size(sq.m.)':'ขนาด (ตร.ม.)'}
                                        name='floorSize' onChange={this.props.handleInputChange}  />
                                </div> 
                            </div>
                            <div>
                                {
                                    this.props.sizeAreaAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fil Property information':'กรุณากรอกข้อมูลทรัพย์ให้ครบ'}</span> :''
                                }
                                {
                                    this.props.onlyNumberAlert ? <span className='text-red'> {this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</span> :''
                                }
                                {
                                    this.props.onlyNumberBedroomBathroomFloorAlert ? <span className='text-red'> {this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</span> :''
                                }
                                {
                                    this.props.onlyNumberLandAlert  ? <span className='text-red'> {this.props.lang === 'en' ? 'Please fill only number':'กรุณากรอกเป็นตัวเลขเท่านั้น'}</span> :''
                                }
                                {
                                    this.props.landAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fil Property information':'กรุณากรอกข้อมูลทรัพย์ให้ครบ'}</span> :''
                                 }
                            </div>
                        </div>
                        {/* {this.props.propertyType !== 'land' ? */}
                            <div className='mt-4'>
                            <h5>{this.props.lang === 'en' ? 'Special conditions':'ลักษณะพิเศษ'}</h5>
                            <div className='ml-3 pb-2'>
                                <div><input type="checkbox" className='myRemark' name="remarkPet" onChange = {() => this.props.handleInputChangeCheckBox('remarkPet',!this.state.remarkPet)} /> <span className='pl-2'>{this.props.lang === 'en' ? 'Pets Allowed':'อนุญาตให้เลี้ยงสัตว์'}</span></div>
                                <div><input type="checkbox" className='myRemark' name="remarkForeignt" onChange = {() => this.props.handleInputChangeCheckBox('remarkForeignt',!this.state.remarkForeignt)}  /> <span className='pl-2'>{this.props.lang === 'en' ? 'Foreigner quota':'มีโควตาต่างชาติ'}</span></div>
                                <div><input type="checkbox" className='myRemark' name="remarkDuplex" onChange = {() => this.props.handleInputChangeCheckBox('remarkDuplex',!this.state.remarkDuplex)}  /> <span className='pl-2'>{this.props.lang === 'en' ? 'Duplex':'ห้องแบบ Duplex'}</span></div>
                                <div><input type="checkbox" className='myRemark' name="remarkPenthouse" onChange = {() => this.props.handleInputChangeCheckBox('remarkPenthouse',!this.state.remarkPenthouse)}  /> <span className='pl-2'>{this.props.lang === 'en' ? 'Penthouse':'ห้องแบบ Penthouse'}</span></div>
                               {
                                 this.props.userType === 'tenant' || this.props.userType === 'buyer' ? 
                                 <div><input type="checkbox" className='myRemark' name="remarkHighFloor" onChange = {() => this.props.handleInputChangeCheckBox('remarkHighFloor',!this.state.remarkHighFloor)}  /> <span className='pl-2'>HighFloor</span></div> :
                                 ''
                               }
                                
                                <div><input type="checkbox" name="autoCheckRemark" className='myRemark' onChange = {() => this.props.onSetAutoCheckRemark()}/> <span className='pl-2'>  {this.props.lang === 'en' ? 'Other':'อื่นๆ'}
                                {
                                    this.props.autoCheckRemark && <input type="text" className="form-control" placeholder={this.props.lang === 'en' ? 'Other':'อื่นๆ'} name='remark' onChange={this.props.handleInputChange}/>
                                }
                                
                                </span></div>
                            </div>
                            {
                                    this.props.remarkAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fil Property information':'กรุณาเลือกลักษณะพิเศษ'}</span> :''
                            }
                            {
                                    this.props.remarkTextAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Please fil the other':'กรุณากรอกอื่นๆ'}</span> :''
                            }
                            </div>
                            {/* :''
                        } */}
                        
                </div>
                <div style={{backgroundColor:'#fff'}} className='p-4 mt-4'>
                        <div className='row p-3'>
                            <div className='col3'><h5><span>{this.props.lang === 'en' ? 'Image':'รูปภาพ'}</span></h5></div>
                            <div className='col text-right'><p style={{color:'#808080'}}>{this.props.lang === 'en' ? 'Attach a maximum of 10 images':'แนบรูปสูงสุดได้ไม่เกิน 10 รูป'}</p></div>
                        </div>
                        
                        <div className='svm-agent-img'>
                             {
                            //    this.props.silvermanAgentImage?.length <= 10 &&
                                this.props.silvermanAgentImage?.map((n, inx) => {
                                    
                                        return (
                                         <div>
                                            <img src={n.viewPtath} style={{ width: 100, height: 100,borderRadius:'8px'}} />
                                             <button className='btnDeleteImage' type={"button"} onClick={() => this.props.deleteImage(n, inx)}>
                                                <img src={process.env.PUBLIC_URL + "/images/icons/closeImg.png"} style={{ width: 20, height: 20 ,position:'relative',top:'-12px',right:'-82px' }} />
                                            </button>
                                         </div>
                                     )
                                })
                            }
                        </div>
                        <div>
                            {this.props.silvermanAgentImage?.length <= 9 ?
                                <div className='btn-svmUploadImg'>
                                    <div> <img src={process.env.PUBLIC_URL + '/images/icons/camera.png'} style={{position:'relative',top:'28px',left:'28px'}} /></div>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/btn-svm-agent.png'} style={{position:'relative',top:'39px',left:'79px'}} />
                                        <input type="file" name='silvermanAgentImage' id="silvermanAgentImage"
                                                className='svmUploadImg'                          
                                                accept='application/.pdf,.png,.jpg'
                                                required={true}
                                                onChange={(e) => this.props.uploadFile(e)}
                                        />
                                    </div>
                                </div>
                                :<span className='text-danger'>{this.props.lang === 'en' ? 'Attach a maximum of 10 images':'แนบรูปสูงสุดได้ 10 รูปเท่านั้น '}</span>
                            }   
                       </div>
                </div>
                <div className='footer-svm-agent'>
                    <div className='pt-6 pb-6'>
                         <button onClick={() => this.props.onSetStepFinish(true)} className="btn btn-primary btn-svm-agent">
                         {this.props.lang === 'en' ? 'Next':'ถัดไป'}
                         </button>
                    </div>
                </div>
            </div>
            </>
           
        )}
}

export default SilvermanAgentDetail;