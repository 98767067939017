/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type depositInterestDashBoardQueryVariables = {|
  id?: ?string
|};
export type depositInterestDashBoardQueryResponse = {|
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +startSite: ?any,
      |}
    |}>
  |},
  +myUser: ?{|
    +juristic: ?{|
      +id: string
    |}
  |},
|};
export type depositInterestDashBoardQuery = {|
  variables: depositInterestDashBoardQueryVariables,
  response: depositInterestDashBoardQueryResponse,
|};
*/


/*
query depositInterestDashBoardQuery(
  $id: ID
) {
  allAccountProjectManager(id: $id) {
    edges {
      node {
        id
        startSite
      }
    }
  }
  myUser {
    juristic {
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "AccountProjectManagerNodeConnection",
  "kind": "LinkedField",
  "name": "allAccountProjectManager",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountProjectManagerNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountProjectManagerNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startSite",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicNode",
  "kind": "LinkedField",
  "name": "juristic",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "depositInterestDashBoardQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "depositInterestDashBoardQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f84633b084401ce19d3774d059e2fb0",
    "id": null,
    "metadata": {},
    "name": "depositInterestDashBoardQuery",
    "operationKind": "query",
    "text": "query depositInterestDashBoardQuery(\n  $id: ID\n) {\n  allAccountProjectManager(id: $id) {\n    edges {\n      node {\n        id\n        startSite\n      }\n    }\n  }\n  myUser {\n    juristic {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6bae2c67d9a59f5de8a21354be5957a7';

module.exports = node;
