import React, { useState } from 'react';
import { Translation } from "react-i18next";
import i18next from 'i18next';

const SeacrhDetail = (props) => {
  const [searchBoxId, setSearchBoxId] = useState(props.search);
  const [startDateIs, setStartDateIs] = useState(props.start_date);
  const [endDateIs, setEndDateIs] = useState(props.end_date);
  const [status, setStatus] = useState(props.status);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSreach()
    }
  }
  const onSreach = () => {

    let event = []

    let data_search = {
      name: 'search',
      value: searchBoxId,
    };
    event.push(data_search)

    let data_startDateIs = {
      name: 'start_date',
      value: startDateIs,
    };
    event.push(data_startDateIs)

    let data_endDateIs = {
      name: 'end_date',
      value: endDateIs,
    };
    event.push(data_endDateIs)

    let data_status = {
      name: 'status',
      value: status,
    };
    event.push(data_status)

    if (props.handlePropsChange) {
      props.handlePropsChange(event);
    }

  }

  const onChage = (e) => {

    let Data = []
    if(e.target.name === 'startDate'){
      setStartDateIs(e.target.value)

      let data_startDateIs = {
        name: 'start_date',
        value: e.target.value,
      };
      Data.push(data_startDateIs)
    }else{
      setEndDateIs(e.target.value)
      let data_endDateIs = {
        name: 'end_date',
        value: e.target.value,
      };
      Data.push(data_endDateIs)
    }
    if(props.onChangeSearch){
      props.onChangeSearch(Data)
    }
    

  }

  const seleteDetail = props.seleteDetail || [{ value: "", label: i18next.t("residentialHistory:All") }, { value: "liveing", label: i18next.t("residentialHistory:Live") }, { value: "quit", label: i18next.t("residentialHistory:Move out")  }]
  const selete2Detail = props.selete2Detail || []
  const selectIsClass = props.selectIsClass || "floatRight"
  const selectIsClassLabel = props.selectIsClassLabel || ""

  return (
    <Translation >
      {
        t =>
          <div className='row searchFunction mb-5'>
            {/* <div className='col-4'>
  </div> */}
            <div className='col-12 text-right' style={{ display: 'inline' }}>
              {
                props.searchBoxId &&
                <div className='floatRight'>
                  <input type="text" name='search' value={searchBoxId} className='form-control searchBox' placeholder={t("silvermanguard:search")}
                    // onKeyUp={ (e) => setSearchBoxId(e.target.value)} 
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setSearchBoxId(e.target.value)} />

                  <div style={{ position: 'absolute', top: '6px', right: '25px' }}>
                    <button type="submit" className='btn' onClick={onSreach}>
                      <img src={process.env.PUBLIC_URL + '/images/icons/iconSearch.png'} className="search-outline" alt="search" />
                    </button>
                  </div>

                </div>
              }

              {
                props.selectIs &&
                <div>
                  {
                    props.selectIsName &&
                    <label className={`floatLeft labelInline ${selectIsClassLabel}`}>{props.selectIsName}</label>
                  }

                  <div className={`${selectIsClass}`}>
                    <select
                      name='searchTypePerson'
                      className='form-control searchSelect'
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}>
                      {
                        seleteDetail.map((n, ind) => {
                          return <option value={n.value} key={ind}>{n.label}</option>
                        })
                      }
                    </select>

                  </div>
                </div>

              }

              {
                props.select2Is &&
                <div>
                  {
                    props.select2IsName &&
                    <label className='floatLeft labelInline'>{props.select2IsName}</label>
                  }
                  <div className={`${props.selectIsClass}`}>
                    <select
                      name='searchTypePerson'
                      className='form-control searchSelect'
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}>
                      {
                        selete2Detail.map((n, ind) => {
                          return <option value={n.value} key={ind}>{n.label}</option>
                        })
                      }
                    </select>

                  </div>
                </div>
              }



              <div className='floatRight'>
                {
                  props.startDateIs &&
                  <React.Fragment>
                    <label className='floatLeft labelInline'>{t("silvermanguard:date")}</label>
                    <input type="date" name='startDate' value={startDateIs} className='floatLeft form-control searchDate'
                      placeholder='01/07/2021' onChange={onChage} />
                  </React.Fragment>
                }
                {
                  props.endDateIs &&
                  <React.Fragment>
                    <label className='floatLeft labelInline'>{t("silvermanguard:dateTo")}</label>
                    <input type="date" name='endDate' value={endDateIs} className='floatLeft form-control searchDate'
                      placeholder='01/07/2021' onChange={onChage}
                      min={startDateIs}
                    />
                  </React.Fragment>
                }

              </div>
            </div>
          </div>
      }
    </Translation>

  );
}

export default SeacrhDetail;
