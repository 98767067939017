/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type electricMeterListCompareQueryVariables = {|
  search?: ?string,
  first?: ?number,
  last?: ?number,
  month?: ?number,
  year?: ?number,
  previous_month?: ?number,
  pre_year?: ?number,
|};
export type electricMeterListCompareQueryResponse = {|
  +current_month: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +electricMeterUnit: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +month: number,
              +year: number,
              +meterValue: ?number,
              +changeElectricMeter: boolean,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
  +previous_month: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +electricMeterUnit: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +month: number,
              +year: number,
              +meterValue: ?number,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +price: ?number,
        |}
      |}>
    |}
  |},
|};
export type electricMeterListCompareQuery = {|
  variables: electricMeterListCompareQueryVariables,
  response: electricMeterListCompareQueryResponse,
|};
*/


/*
query electricMeterListCompareQuery(
  $search: String
  $first: Int
  $last: Int
  $month: Float
  $year: Float
  $previous_month: Float
  $pre_year: Float
) {
  current_month: allResidential(search: $search, first: $first, last: $last, order: "added") {
    edges {
      node {
        id
        name
        electricMeterUnit(month: $month, year: $year) {
          edges {
            node {
              id
              month
              year
              meterValue
              changeElectricMeter
            }
          }
        }
      }
    }
    totalCount
  }
  previous_month: allResidential(first: $first, last: $last, order: "added") {
    edges {
      node {
        id
        name
        electricMeterUnit(month: $previous_month, year: $pre_year) {
          edges {
            node {
              id
              month
              year
              meterValue
            }
          }
        }
      }
    }
    totalCount
  }
  productViewer {
    allProduct(isActive: true, type: "service", servicePricing_PricingType: "electric_meter") {
      edges {
        node {
          id
          name
          price
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pre_year"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previous_month"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v9 = {
  "kind": "Literal",
  "name": "order",
  "value": "added"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meterValue",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v16 = {
  "alias": "current_month",
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    }
  ],
  "concreteType": "ResidentialNodeConnection",
  "kind": "LinkedField",
  "name": "allResidential",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResidentialNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResidentialNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "month",
                  "variableName": "month"
                },
                {
                  "kind": "Variable",
                  "name": "year",
                  "variableName": "year"
                }
              ],
              "concreteType": "ElectricMeterUnitNodeConnection",
              "kind": "LinkedField",
              "name": "electricMeterUnit",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ElectricMeterUnitNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ElectricMeterUnitNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v10/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "changeElectricMeter",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": "previous_month",
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "concreteType": "ResidentialNodeConnection",
  "kind": "LinkedField",
  "name": "allResidential",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResidentialNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResidentialNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "month",
                  "variableName": "previous_month"
                },
                {
                  "kind": "Variable",
                  "name": "year",
                  "variableName": "pre_year"
                }
              ],
              "concreteType": "ElectricMeterUnitNodeConnection",
              "kind": "LinkedField",
              "name": "electricMeterUnit",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ElectricMeterUnitNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ElectricMeterUnitNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v10/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v15/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "isActive",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "servicePricing_PricingType",
      "value": "electric_meter"
    },
    {
      "kind": "Literal",
      "name": "type",
      "value": "service"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "allProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allProduct(isActive:true,servicePricing_PricingType:\"electric_meter\",type:\"service\")"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "electricMeterListCompareQuery",
    "selections": [
      (v16/*: any*/),
      (v17/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "electricMeterListCompareQuery",
    "selections": [
      (v16/*: any*/),
      (v17/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6eb9292333f494358c38964a47e24f8e",
    "id": null,
    "metadata": {},
    "name": "electricMeterListCompareQuery",
    "operationKind": "query",
    "text": "query electricMeterListCompareQuery(\n  $search: String\n  $first: Int\n  $last: Int\n  $month: Float\n  $year: Float\n  $previous_month: Float\n  $pre_year: Float\n) {\n  current_month: allResidential(search: $search, first: $first, last: $last, order: \"added\") {\n    edges {\n      node {\n        id\n        name\n        electricMeterUnit(month: $month, year: $year) {\n          edges {\n            node {\n              id\n              month\n              year\n              meterValue\n              changeElectricMeter\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n  previous_month: allResidential(first: $first, last: $last, order: \"added\") {\n    edges {\n      node {\n        id\n        name\n        electricMeterUnit(month: $previous_month, year: $pre_year) {\n          edges {\n            node {\n              id\n              month\n              year\n              meterValue\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n  productViewer {\n    allProduct(isActive: true, type: \"service\", servicePricing_PricingType: \"electric_meter\") {\n      edges {\n        node {\n          id\n          name\n          price\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c1cb1d912bd556b948b4fcb4dbf3daa';

module.exports = node;
