import React from 'react';
import {QueryRenderer} from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import {graphql} from "babel-plugin-relay/macro";
import { format , differenceInDays } from "date-fns";
import {Link} from "react-router-dom";
import _ from "lodash"
import Loading from '../../../libs/loading';
import i18next from 'i18next';

const query = graphql`
    query advanceListTableQuery($search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int) {
        allAdvance(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last){
            totalCount
            edges{
                node{
                    id
                    issuedDate
                    dueDate
                    docNumber
                    description
                    withdrawer
                    amount
                    status
                }
            }
        }
    }
`;

class AdvanceListTable extends ComponentPagination {

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'WITHDRAW':
               //'รอเคลียร์';
                text = i18next.t(`guarantee_money_received:Wating to clear`)
                break;
            case 'VOID':
               // 'ยกเลิก';
                text = i18next.t(`PageDetailProjectInfo:Cancle`)
                color = 'text-center text-danger';
                break;
            default:
               //'เคลียร์แล้ว';
                text = i18next.t(`unknown_receive:Cleared`)
        }
        return <td className={color}>{text}</td>
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                }}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">{i18next.t("advanceWithdrawList:Number")}</th>
                                        <th className="text-center">{i18next.t("advanceWithdrawList:Recording Date")}</th>
                                        <th className="text-center">{i18next.t("advanceWithdrawList:Clearance Due Date")}</th>
                                        <th>{i18next.t("advanceWithdrawList:Transaction")}</th>
                                        <th>{i18next.t("advanceWithdrawList:Requester")}</th>
                                        <th className="text-right">{i18next.t("advanceWithdrawList:Amount")}</th>
                                        <th className="text-center">{i18next.t("advanceWithdrawList:Status")}</th>
                                        <th className="text-center">{i18next.t("advanceWithdrawList:Overdue")}</th>
                                        {/* <th className="text-center">ออกโดย</th> */}
                                        <th />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.allAdvance.edges.map((advance) => {
                                        return (
                                            <tr key={advance.node.id}>
                                                <td className="text-center">
                                                    {advance.node.status === "VOID" ?
                                                        advance.node.docNumber :
                                                        <Link
                                                            to={"/accounting/expense/advance/withdraw/" + advance.node.id + "/" + advance.node.docNumber}
                                                            >{advance.node.docNumber}
                                                        </Link>
                                                    }
                                                </td>
                                                <td className="text-center">{format(advance.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                <td className="text-center">{advance.node.dueDate ? format(advance.node.dueDate, 'DD/MM/YYYY') : ""}</td>
                                                <td>{advance.node.description}</td>
                                                <td>{advance.node.withdrawer}</td>
                                                <td className="text-right">{numberWithComma(advance.node.amount)}</td>
                                                    <React.Fragment>
                                                        {this.getColorAndText(advance)}
                                                    </React.Fragment>
                                                <td className="text-center">
                                                    {advance.node.status === 'WITHDRAW' && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_advance_create'}) && advance.node.dueDate
                                                        ? differenceInDays(new Date(), advance.node.dueDate) > 0 
                                                            ? differenceInDays(new Date(), advance.node.dueDate) 
                                                            : "0"  
                                                        : ""
                                                    }
                                                </td>
                                                {/* <td className="text-center"></td> */}
                                                <td width="150">
                                                    {advance.node.status === 'WITHDRAW' && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_advance_create'}) &&
                                                    <Link
                                                        to={"/accounting/expense/advance/clear-advance/create/" + advance.node.id}
                                                        >
                                                        <button type="button" className="btn btn-primary add">
                                                            {i18next.t("advanceWithdrawList:Clear")}
                                                        </button>
                                                    </Link>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                            last={this.state.last}
                                            totalCount={props.allAdvance.totalCount}/>
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default AdvanceListTable;
