import React from "react";
import {fetchQuery} from "relay-runtime";
import getMonth from "../../../../libs/getMonth";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";

const $ = window.jQuery;
const date = new Date();
const current_year = date.getFullYear();

const query = graphql`
    query otherExpensePeriodAdjustSettingQuery($service: String!, $contact: String!)  {
        periodServicePricing(service: $service, contact: $contact) {
            unit
            unitPrice
            totalPrice
            description
        }
    }
`;

class OtherExpenseWaterMeterSelectDate extends React.Component {

    constructor(props) {
        super(props);
        this.getYear = this.getYear.bind(this);
        this.submit = this.submit.bind(this);
        this.inputHandle = this.inputHandle.bind(this);
    }

    componentWillMount() {
        this.setState({
            periodMonth: 12,
            startMonth: 0,
            startYear: current_year,
            totalPrice: 0
        })
    }

    componentDidMount() {
        if (this.props.mode === 'create') {
            $(this.modal).modal('show');
        }
    }

    getYear() {
        return [current_year - 2, current_year - 1, current_year, current_year + 1];
    }

    inputHandle(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    submit() {
        let variables = {
            "contact": this.props.contact[0],
            "service": this.props.transaction.productAndService.id
        };
        fetchQuery(environment, query, variables)
            .then(data => {
                this.setState({totalPrice: data.periodServicePricing.totalPrice});
                this.props.setServicePeriod(this.props.transaction_index, this.state);
                $(this.modal).modal('hide');
            });
    }

    render() {
        return (
            <React.Fragment>
                <img src={process.env.PUBLIC_URL + "/images/icons/edit.png"} alt="edit" className="clock"
                     data-toggle="modal" data-target="#period-settings"/>
                <div
                    ref={modal => this.modal = modal} data-backdrop="static" data-keyboard="false"
                    className="modal fade" id="period-settings" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">กรุณากำหนดช่วงเวลา</h5>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">จำนวนงวด</label>
                                    <select className="form-control" id="period-month" name="periodMonth"
                                            value={this.state.periodMonth} onChange={this.inputHandle}>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => {
                                            return (
                                                <option key={'period-month-' + value} value={value}>
                                                    {value}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">เดือนเริ่มต้น</label>
                                    <select className="form-control" id="start-month" name="startMonth"
                                            value={this.state.startMonth} onChange={this.inputHandle}>
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((value, index) => {
                                            return (
                                                <option key={'start-month-' + index} value={value}>
                                                    {getMonth(value)}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">กำหนดปี </label>
                                    <select className="form-control" id="start-year" name="startYear"
                                            value={this.state.startYear} onChange={this.inputHandle}>
                                        {this.getYear().map((value, index) => {
                                            return (
                                                <option key={'start-year-' + index} value={value}>
                                                    {value}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.submit}>บันทึก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default OtherExpenseWaterMeterSelectDate;
