import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import i18n  from 'i18next';
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class PettyCashPayment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            now_date: new Date(),
            page_record: {}, //แบ่งหน้า table กรณีเกิน 25 record
            checked: 0,
            token: jwtDecode(window.localStorage.getItem('token')),
            languages: "th",
        }
        this.paymentChannel = this.paymentChannel.bind(this)
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
        })
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let chart = page.find('.chart-of-account').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = (page_height - (head + detail + invoice + chart + signature)) / 2;
            if (diff < 100) {
                $('tbody tr:last-child td').css({ paddingBottom: 10 });
            } else {
                $('tbody tr:last-child td').css({ paddingBottom: diff });
            }
        }, 200);

        this.setPage();

    }

    setPage() {
        let count_table = this.props.query.pettyCashPayment.pettyCashRecord.edges.length +
            this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges.length +
            this.props.query.pettyCashPayment.payPaymentChannel.edges.length + 
            this.props.query.pettyCashPayment.advancePettyCashChannel.edges.length + 
            this.props.query.pettyCashPayment.clearGuaranteeMoneyReceivedPettyCashChannel.edges.length 
        let check = 0
        let page = {};
        //table:25 record

       
        if (count_table > 0) {     
            let allsort;
            let IscountPayRecord = false
            if(this.props.query.pettyCashPayment.pettyCashRecord.edges.length > 1){                
                allsort = _.map(this.props.query?.pettyCashPayment?.pettyCashRecord.edges, (val) => {
                    return val.node.description.length
                });
            }else if(this.props.query.pettyCashPayment.payPaymentChannel.edges.length > 1){    
                let arr = []            
                allsort = _.map(this.props.query?.pettyCashPayment?.payPaymentChannel.edges, (paypayment) => {                                        
                   IscountPayRecord = paypayment.node.payRecordGroup.payRecord.edges.length > 0 ? true : false
                   const payRecordLength = paypayment.node.payRecordGroup.payRecord.edges.length;
                   arr.push(payRecordLength)
                   let countPurchaseRecordDesLength
                   _.map(paypayment.node.payRecordGroup.payRecord.edges,(val) => {
                        countPurchaseRecordDesLength = val.node.purchaseRecord.description.length
                   })
                   return countPurchaseRecordDesLength
                });
            }else if(this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges.length > 1){
                allsort = _.map(this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges, (val) => {
                    return val.node.description.length
                });
            }else if(this.props.query.pettyCashPayment.advancePettyCashChannel.edges.length > 1){
                allsort = _.map(this.props.query.pettyCashPayment.advancePettyCashChannel.edges, (val) => {
                    return val.node.description.length
                });
            }else if(this.props.query.pettyCashPayment.advancePettyCashChannel.edges.length > 1){
                allsort = _.map(this.props.query.pettyCashPayment.advancePettyCashChannel.edges, (val) => {
                    return val.node.description.length
                });
            }
            
            let descriptionMaxlength = _.max(allsort);
            let checkRow = 0;
            let getLine = 45; //45 per one line on description
            let rowPerLine = Math.ceil(descriptionMaxlength / getLine);
                rowPerLine = parseInt(rowPerLine);

            if(IscountPayRecord){
                if(descriptionMaxlength > 45){
                    checkRow = 6;
                }else{
                    checkRow = 10;
                }
            }else{
                if (descriptionMaxlength <= 43 && rowPerLine === 0) {                    
                    checkRow = 12;
                }else if (descriptionMaxlength > 45 && rowPerLine <= 2) {
                    checkRow = 12;
                }else if (descriptionMaxlength > 45 && rowPerLine <= 4 ) {
                    checkRow = 10;
                }else if(descriptionMaxlength > 50 && rowPerLine <= 2){
                    checkRow = 6;
                }else{
                    checkRow = 8;
                }
            }
            
            let index_page = 1;
            let count = 1;
            this.props.query.pettyCashPayment.pettyCashRecord.edges.forEach((value, index) => {
                if (count <= checkRow) {
                    if (!page[index_page]) {
                        page[index_page] = [];
                    }
                    page[index_page].push(value);
                    count += 1;
                } else {
                    count = 1;
                    index_page += 1;
                    if (!page[index_page]) {
                        page[index_page] = [];
                        page[index_page].push(value);
                    }
                }
                check = index_page
                return value
            })
            this.props.query.pettyCashPayment.payPaymentChannel.edges.map((value, index) => {
                if (count <= checkRow) {
                    if (!page[index_page]) {
                        page[index_page] = [];
                    }
                    page[index_page].push(value);
                    count += 1;
                } else {
                    count = 1;
                    index_page += 1;
                    if (!page[index_page]) {
                        page[index_page] = [];
                        page[index_page].push(value);
                    }
                }
                check = index_page
                return value
            })
            this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges.map((value, index) => {
                if (count <= checkRow) {
                    if (!page[index_page]) {
                        page[index_page] = [];
                    }
                    page[index_page].push(value);
                    count += 1;
                } else {
                    count = 1;
                    index_page += 1;
                    if (!page[index_page]) {
                        page[index_page] = [];
                        page[index_page].push(value);
                    }
                }
                check = index_page
                return value
            })
            this.props.query.pettyCashPayment.advancePettyCashChannel.edges.map((value, index) => {
                if (count <= checkRow) {
                    if (!page[index_page]) {
                        page[index_page] = [];
                    }
                    page[index_page].push(value);
                    count += 1;
                } else {
                    count = 1;
                    index_page += 1;
                    if (!page[index_page]) {
                        page[index_page] = [];
                        page[index_page].push(value);
                    }
                }
                check = index_page
                return value
            })

            this.props.query.pettyCashPayment.clearGuaranteeMoneyReceivedPettyCashChannel.edges.map((value, index) => {
                if (count <= checkRow) {
                    if (!page[index_page]) {
                        page[index_page] = [];
                    }
                    page[index_page].push(value);
                    count += 1;
                } else {
                    count = 1;
                    index_page += 1;
                    if (!page[index_page]) {
                        page[index_page] = [];
                        page[index_page].push(value);
                    }
                }
                check = index_page
                return value
            })

        } else {
            page[1] = [];
            check = 1
            this.props.query.pettyCashPayment.pettyCashRecord.edges.map(value => {
                page[1].push(value);
            })
            this.props.query.pettyCashPayment.payPaymentChannel.edges.map(value => {
                page[1].push(value);
            })
            this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges.map(value => {
                page[1].push(value);
            })
            this.props.query.pettyCashPayment.advancePettyCashChannel.edges.map(value => {
                page[1].push(value);
            })
            this.props.query.pettyCashPayment.clearGuaranteeMoneyReceivedPettyCashChannel.edges.map(value => {
                page[1].push(value);
            })
        }

        this.setState({
            page_record: page,
            checked: check
        })
    }




    paymentChannel() {
        let text = "";
        let channel = "";
        if (this.props.query.pettyCashPayment.cashTransaction.edges.length) {
            text = "เงินสด";
            channel = "cash";
        }
        if (this.props.query.pettyCashPayment.bankAccountTransaction.edges.length) {
            let bank = this.props.query.pettyCashPayment.bankAccountTransaction.edges[0];
            text = bank.node.bankAccount.bankName + " เลขที่บัญชี " + bank.node.bankAccount.accountNumber + " วันที่ " + format(bank.node.date, 'DD/MM/YYYY', { locale: thLocale });
            channel = "bank";
        }
        if (this.props.query.pettyCashPayment.chequeTransaction.edges.length) {
            let cheque = this.props.query.pettyCashPayment.chequeTransaction.edges[0];
            text = "เช็คเลขที่ " + cheque.node.chequeNumber + " " + cheque.node.bankAccount.bankName + " สาขา " + cheque.node.bankAccount.branch + " วันที่ " + format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale });
            channel = "cheque";

        }
        return { text, channel }
    }

    render() {
        let paymentChannel = this.paymentChannel()
        const numberAllPage = Object.keys(this.state.page_record).length;

        return (
            <React.Fragment>
                {Object.entries(this.state.page_record).map(([key, petty_record], index) => {
                    return (
                        <div className="print-page-a4" id="receive" key={index} style={{ position: 'relative' }}>
                        <div className="subpage" style={{ overflow: 'hidden'}}>
                            <div className="head">
                                <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                    <div style={{ minHeight: 100, minWidth: 100 }}>
                                        <img src={this.props.query.selfProject.logo}
                                            alt="silverman" />
                                    </div>
                                    <div>
                                        <div style={{ minWidth: '115%' }}>
                                            <strong>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong>
                                        </div>
                                        <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                        <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                    </div>
                                </div>
                                <div className="document-name">
                                    {
                                        <React.Fragment>
                                            <div className="title">เบิกชดเชยเงินสดย่อย </div>
                                            <div className="title">Petty Cash Payment</div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="detail mt-2">
                                <div className="row">
                                    <div className="col">
                                        <span className="font-weight-bold">รายการตั้งเบิก</span><br />
                                        {
                                            this.props.query.pettyCashPayment.pettyCashRecord.edges.length !== 0 ?
                                                this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.description
                                                :
                                                this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges.length !== 0 ?
                                                    this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges[0].node.setPettyCash.description
                                                    :
                                                    this.props.query.pettyCashPayment.clearGuaranteeMoneyReceivedPettyCashChannel.edges.length !== 0 ?
                                                        this.props.query.pettyCashPayment.clearGuaranteeMoneyReceivedPettyCashChannel.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.clearGuaranteeMoneyReceivedPettyCashChannel.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.clearGuaranteeMoneyReceivedPettyCashChannel.edges[0].node.setPettyCash.description
                                                        :
                                                        this.props.query.pettyCashPayment.advancePettyCashChannel.edges.length !== 0 ?
                                                            this.props.query.pettyCashPayment.advancePettyCashChannel.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.advancePettyCashChannel.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.advancePettyCashChannel.edges[0].node.setPettyCash.description
                                                            :
                                                            this.props.query.pettyCashPayment.payPaymentChannel.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.payPaymentChannel.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.payPaymentChannel.edges[0].node.setPettyCash.description
                                                            
                                        }
                                    </div>
                                    <div className="col">
                                        <span className="font-weight-bold">เลขที่</span><br />
                                        {this.props.query.pettyCashPayment.docNumber}
                                    </div>
                                    <div className="col">
                                        <span className="font-weight-bold">วันที่ออก</span><br />
                                        {format(this.props.query.pettyCashPayment.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}
                                    </div>
                                </div>
                            </div>

                            <div className="invoice-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width={100} className="text-center">
                                                เลขที่
                                            </th>
                                            <th className="text-center">
                                                วันที่
                                            </th>
                                            <th width={60} className="text-center">
                                                รหัสบัญชี
                                            </th>
                                            <th width={130} className="text-center">
                                                ชื่อบัญชี
                                            </th>
                                            <th className="text-center">
                                                คำอธิบาย
                                            </th>
                                            <th width={80} className="text-right">
                                                จำนวนเงิน
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {petty_record.map((record, index_oe) => {
                                            if (record.node.otherExpense) {
                                                return (
                                                    record.node.otherExpense.otherExpenseTransaction.edges.map((otherExpenseTransaction, index) => {
                                                        let code = "";
                                                        let name_code = "";
                                                        let last_row = record.node.otherExpense.otherExpenseTransaction.edges.length;
                                                        if (otherExpenseTransaction.node.productAndService) {
                                                            code = otherExpenseTransaction.node.productAndService.productCode;
                                                            name_code = otherExpenseTransaction.node.productAndService.name;
                                                        } else {
                                                            code = otherExpenseTransaction.node.chartOfAccount.chartOfAccountCode;
                                                            name_code = otherExpenseTransaction.node.chartOfAccount.name;
                                                        }
                                                        return (
                                                            <tr key={record.node.id}>
                                                                <td className="text-center">{index === 0 && record.node.otherExpense.docNumber}</td>
                                                                <td className="text-center"> {format(record.node.otherExpense.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td className="text-center">{code}</td>
                                                                <td>{name_code}</td>
                                                                <td>{record.node.description}</td>
                                                                <td className="text-right">{index + 1 === last_row ? numberWithComma(record.node.price) : "-"}</td>
                                                            </tr>
                                                        )
                                                    })

                                                )
                                            }
                                            else if (record.node.payRecordGroup) {
                                                return (
                                                    record.node.payRecordGroup.payRecord.edges.map((payRecord, index) => {
                                                        let code = "";
                                                        let name_code = "";
                                                        let last_row = record.node.payRecordGroup.payRecord.edges.length;
                                                        if (payRecord.node.purchaseRecord.productAndService) {
                                                            code = payRecord.node.purchaseRecord.productAndService.productCode;
                                                            name_code = payRecord.node.purchaseRecord.productAndService.name;
                                                        } else {
                                                            code = payRecord.node.purchaseRecord.chartOfAccount.chartOfAccountCode;
                                                            name_code = payRecord.node.purchaseRecord.chartOfAccount.name;
                                                        }
                                                        return (
                                                            <tr key={record.node.id}>
                                                                <td className="text-center">{index === 0 && record.node.payRecordGroup.docNumber}</td>
                                                                <td className="text-center"> {format(record.node.payRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td className="text-center">{code}</td>
                                                                <td>{name_code}</td>
                                                                <td>{payRecord.node.purchaseRecord.description}</td>
                                                                <td className="text-right">{index + 1 === last_row ? numberWithComma(record.node.price) : "-"}</td>
                                                            </tr>
                                                        )
                                                    })

                                                )
                                            }
                                            else if (record.node.advance && record.node.clearAdvance === null) {
                                                let code = "";
                                                let name_code = "";
                                                code = record.node.advance.chartOfAccount.chartOfAccountCode;
                                                name_code = record.node.advance.chartOfAccount.name;
                                                
                                                return (
                                                    <tr key={record.node.id}>
                                                        <td className="text-center">{index === 0 && record.node.advance.docNumber}</td>
                                                        <td className="text-center"> {format(record.node.advance.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                        <td className="text-center">{code}</td>
                                                        <td>{name_code}</td>
                                                        <td>{record.node.advance.description}</td>
                                                        <td className="text-right">{numberWithComma(record.node.price)}</td>
                                                    </tr>
                                                )
                                            }
                                            else if (record.node.advance === null && record.node.clearAdvance) {
                                                return (
                                                    record.node.clearAdvance.clearAdvanceTransaction.edges.map((clearAdvanceTransaction, index) => {
                                                        let code = "";
                                                        let name_code = "";
                                                        let last_row = record.node.clearAdvance.clearAdvanceTransaction.edges.length;
                                                        code = clearAdvanceTransaction.node.chartOfAccount.chartOfAccountCode;
                                                        name_code = clearAdvanceTransaction.node.chartOfAccount.name;
                                                        
                                                        return (
                                                            <tr key={record.node.id}>
                                                                <td>{index === 0 && record.node.clearAdvance.docNumber}</td>
                                                                <td> {format(record.node.clearAdvance.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td>{code}</td>
                                                                <td>{name_code}</td>
                                                                <td>{record.node.description}</td>
                                                                <td className="text-right">{index + 1 === last_row ? numberWithComma(record.node.price) : "-"}</td>
                                                            </tr>
                                                        )
                                                    })

                                                )
                                            }
                                            else if (record.node.clearGuaranteeMoneyReceived) {
                                                let code = "";
                                                let name_code = "";
                                                code = record.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.chartOfAccountCode;
                                                name_code = record.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.name;
                                                
                                                return (
                                                    <tr key={record.node.id}>
                                                        <td className="text-center">{index === 0 && record.node.clearGuaranteeMoneyReceived.docNumber}</td>
                                                        <td className="text-center"> {format(record.node.clearGuaranteeMoneyReceived.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                        <td className="text-center">{code}</td>
                                                        <td>{name_code}</td>
                                                        <td>{record.node.description}</td>
                                                        <td className="text-right">{numberWithComma(record.node.price)}</td>
                                                    </tr>
                                                )
                                            }
                                            else {
                                                return (
                                                    <tr key={record.node.id}>
                                                        <td className="text-center">
                                                            {record.node.docNumber}
                                                        </td>
                                                        <td className="text-center"> {format(record.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                        <td className="text-center">{record.node.chartOfAccount.chartOfAccountCode}</td>
                                                        <td>{record.node.chartOfAccount.name}</td>
                                                        <td>{record.node.description}</td>
                                                        <td className="text-right">{numberWithComma(record.node.price)}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                        }

                                    </tbody>
                                    {index + 1 === Object.keys(this.state.page_record).length ?
                                        <tfoot className="font-weight-bold">
                                            <tr>
                                                <td className="text-center" style={{ borderRight: "none" }}>
                                                    รวม
                                                </td>
                                                <td colSpan={4} className="text-left" style={{ borderLeft: "none" }}>
                                                    {i18n.languages[0] === 'th' ?
                                                    (ThaiBaht(this.props.query.pettyCashPayment.total)):
                                                    (ConvertNumberToTextEng(this.props.query.pettyCashPayment.total))}
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(this.props.query.pettyCashPayment.total)}
                                                </td>
                                            </tr>
                                        </tfoot> :
                                        <tfoot>
                                            <tr>
                                                <td style={{ borderTop: "none" }} />
                                                <td style={{ borderTop: "none" }} />
                                                <td style={{ borderTop: "none" }} />
                                                <td style={{ borderTop: "none" }} />
                                                <td style={{ borderTop: "none" }} />
                                                <td style={{ borderTop: "none" }} />

                                            </tr>
                                        </tfoot>
                                    }
                                </table>
                            </div>
                            {(index + 1 === Object.keys(this.state.page_record).length && petty_record.length <= 10) &&
                                <React.Fragment>
                                    <div className="chart-of-account">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width={100} className="text-center">
                                                        รหัสบัญชี
                                                    </th>
                                                    <th className="text-center">
                                                        ชื่อบัญชี
                                                    </th>
                                                    <th width={100} className="text-center">
                                                        เดบิต
                                                    </th>
                                                    <th width={100} className="text-center">
                                                        เครดิต
                                                    </th>
                                                </tr>
                                            </thead>
                                            {this.props.query.pettyCashPaymentAccountRecordGroup.edges.map((petty_cash_payment) =>
                                                <React.Fragment key={petty_cash_payment.node.id}>
                                                    <tbody>
                                                        {petty_cash_payment.node.accountRecord.edges.map((account_record) =>
                                                            account_record.node.debit > 0 &&
                                                            <tr key={account_record.node.id}>
                                                                <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                                <td> {account_record.node.chartOfAccountCode.name} {account_record.node.name}</td> 
                                                                <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                                <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                                            </tr>
                                                        )}
                                                        {petty_cash_payment.node.accountRecord.edges.map((account_record) =>
                                                            account_record.node.credit > 0 &&
                                                            <tr key={account_record.node.id}>
                                                                <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                                <td>{account_record.node.chartOfAccountCode.name} {account_record.node.name}</td>
                                                                <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                                <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>

                                                    <tfoot className="font-weight-bold">
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className="row">
                                                                    <div className="col-1">
                                                                        รวม/Total
                                                                    </div>
                                                                    <div className="col-10 text-center">                                                                        
                                                                        {i18n.languages[0] === 'th' ?
                                                                        (ThaiBaht(petty_cash_payment.node.totalDebit)):
                                                                        (ConvertNumberToTextEng(petty_cash_payment.node.totalDebit))}
                                                                    </div>
                                                                    <div className="col-1" />
                                                                </div>
                                                            </td>
                                                            <td className="text-right">
                                                                {numberWithComma(petty_cash_payment.node.totalDebit.toFixed(2))}
                                                            </td>
                                                            <td className="text-right">
                                                                {numberWithComma(petty_cash_payment.node.totalCredit.toFixed(2))}
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </React.Fragment>
                                            )}
                                        </table>
                                        <div className="remark">
                                            <span className="font-weight-bold">การชำระเงิน (Conditions of Payments)</span><br />
                                            {paymentChannel.channel === "cash" &&
                                                <div className="row col ml-1">
                                                    <span className=" align-middle"> เงินสด/Cash </span>
                                                    <div className="col">
                                                        <span>&emsp;จำนวน&emsp;{numberWithComma(this.props.query.pettyCashPayment.total, "0.00")} บาท</span>
                                                    </div>
                                                </div>
                                            }
                                            {paymentChannel.channel === "bank" &&
                                                <div className="col ml-1">
                                                    <span className="align-middle"> เงินโอน/Bank Transfer </span><br />
                                                    <div className="col ml-2">
                                                        <div className="row" key={paymentChannel.channel}><span> - {paymentChannel.text}&emsp;จำนวน&emsp;{numberWithComma(this.props.query.pettyCashPayment.total, "0.00")} บาท<br /></span></div>
                                                    </div>
                                                </div>
                                            }

                                            {paymentChannel.channel === 'cheque' &&
                                                <div className="col ml-1">
                                                    <span className="align-middle"> เช็คธนาคาร/Cheque Bank </span><br />
                                                    <div className="col ml-2">
                                                        <div className="row" key={paymentChannel.channel}><span>- {paymentChannel.text}&emsp;จำนวน {numberWithComma(this.props.query.pettyCashPayment.total, "0.00")} บาท <br /></span></div>
                                                    </div>
                                                </div>
                                            }
                                            <br />
                                        </div>
                                    </div>

                                    <div className="footer" style={{ position: 'absolute', bottom: 0 }} >
                                        <div className="signature-large">
                                            <div className="column-foursign">
                                                ..................................................<br />
                                                <span className="font-weight-bold">ผู้จัดทำ / Orgenizer</span><br /><br />
                                                {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                                            </div>
                                            <div className="column-foursign">
                                                ..................................................<br />
                                                <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span><br /><br />
                                                {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                                            </div>
                                            <div className="column-foursign">
                                                ..................................................<br />
                                                <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span><br /><br />
                                                {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                                            </div>
                                            <div className="column-foursign">
                                                ..................................................<br />
                                                <span className="font-weight-bold">ผู้รับเงิน / Receiver</span><br /><br />
                                                {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                                            </div>
                                        </div>
                                    </div>
                                    
                                </React.Fragment>


                            }
                                
                        </div>
                        <div style={{textAlign:'end',fontSize:'12px',position:'absolute',right:'5%'}}>
                             {index + 1 + "/" + numberAllPage}
                       </div>
                    </div>
                    )
                }
                )}

                {this.state.checked !== 0 && this.state.page_record[this.state.checked]?.length > 10 &&
                    <div className="print-page-a4" id="receive">
                        <div className="subpage">
                            <div className="head">
                                <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                    <div style={{ minHeight: 100, minWidth: 100 }}>
                                        <img src={this.props.query.selfProject.logo}
                                            alt="silverman" />
                                    </div>
                                    <div>                                        
                                        <strong>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong>
                                        <div>{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}</div>
                                        <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                    </div>
                                </div>
                                <div className="document-name">
                                    {
                                        <React.Fragment>
                                            <div className="title">เบิกชดเชยเงินสดย่อย</div>
                                            <div className="title">Petty Cash Payment</div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="detail mt-2">
                                <div className="row">
                                    <div className="col">
                                        <span className="font-weight-bold">รายการตั้งเบิก</span>
                                        {
                                            this.props.query.pettyCashPayment.pettyCashRecord.edges.length !== 0 ?
                                                this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.description
                                                :
                                                this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges.length !== 0 ?
                                                    this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.otherExpensePaymentChannel.edges[0].node.setPettyCash.description
                                                    :
                                                    this.props.query.pettyCashPayment.payPaymentChannel.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.payPaymentChannel.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.payPaymentChannel.edges[0].node.setPettyCash.description
                                        }
                                        <br />
                                        {/* {this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.docNumber + ' ' + this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.withdrawer + ' ' + this.props.query.pettyCashPayment.pettyCashRecord.edges[0].node.setPettyCash.description} */}
                                    </div>
                                    <div className="col">
                                        <span className="font-weight-bold">เลขที่</span><br />
                                        {this.props.query.pettyCashPayment.docNumber}
                                    </div>
                                    <div className="col">
                                        <span className="font-weight-bold">วันที่ออก</span><br />
                                        {format(this.props.query.pettyCashPayment.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}
                                    </div>
                                </div>
                            </div>
                            <div className="chart-of-account">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width={100} className="text-center">
                                                รหัสบัญชี
                                            </th>
                                            <th className="text-center">
                                                ชื่อบัญชี
                                            </th>
                                            <th width={100} className="text-center">
                                                เดบิต
                                            </th>
                                            <th width={100} className="text-center">
                                                เครดิต
                                            </th>
                                        </tr>
                                    </thead>
                                    {this.props.query.pettyCashPaymentAccountRecordGroup.edges.map((petty_cash_payment) =>
                                        <React.Fragment key={petty_cash_payment.node.id}>
                                            <tbody>
                                                {petty_cash_payment.node.accountRecord.edges.map((account_record) =>
                                                    account_record.node.debit > 0 &&
                                                    <tr key={account_record.node.id}>
                                                        <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                        <td>{account_record.node.name}</td>
                                                        <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                        <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                                    </tr>
                                                )}
                                                {petty_cash_payment.node.accountRecord.edges.map((account_record) =>
                                                    account_record.node.credit > 0 &&
                                                    <tr key={account_record.node.id}>
                                                        <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                        <td>{account_record.node.name}</td>
                                                        <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                        <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                                    </tr>
                                                )}
                                            </tbody>

                                            <tfoot className="font-weight-bold">
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div className="row">
                                                            <div className="col-1">
                                                                รวม/Total
                                                            </div>
                                                            <div className="col-10 text-center">                                                                
                                                                {i18n.languages[0] === 'th' ?
                                                                (ThaiBaht(petty_cash_payment.node.totalDebit)):
                                                                (ConvertNumberToTextEng(petty_cash_payment.node.totalDebit))}
                                                            </div>
                                                            <div className="col-1" />
                                                        </div>
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(petty_cash_payment.node.totalDebit.toFixed(2))}
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(petty_cash_payment.node.totalCredit.toFixed(2))}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </React.Fragment>
                                    )}
                                </table>
                                <div className="remark">
                                    <span className="font-weight-bold">การชำระเงิน(Conditions of Payments)</span> <br />
                                    <input
                                        type="radio"
                                        name={"cash"}
                                        checked={this.paymentChannel().channel === "cash" ? true : ""}
                                        disabled={false}
                                        onChange={() => {
                                        }}
                                        className="align-middle mr-1"
                                    />
                                    <span className="align-middle mr-3">เงินสด</span>
                                    <input
                                        type="radio"
                                        name={"bank_transaction"}
                                        className="align-middle mr-1"
                                        checked={this.paymentChannel().channel === "bank" ? true : ""}
                                        onChange={() => {
                                        }}
                                        disabled={false}
                                    />
                                    <span className="align-middle mr-3">เงินโอน</span>
                                    <input
                                        type="radio"
                                        name={"cheque"}
                                        className="align-middle mr-1"
                                        checked={this.paymentChannel().channel === "cheque" ? true : ""}
                                        onChange={() => {
                                        }}
                                        disabled={false}
                                    />
                                    <span className="align-middle">เช็ค</span> <br />

                                    <strong className="mt-2"><span className="font-weight-bold">รายละเอียด</span> {this.paymentChannel().text}</strong> <br />
                                    {this.paymentChannel().channel === "cheque" &&
                                        <strong><span className="font-weight-bold">วันที่</span> {format(this.props.query.pettyCashPayment.chequeTransaction.edges[0]?.node.date, 'DD/MM/YYYY', { locale: thLocale })} <span className="font-weight-bold">จำนวน</span> {numberWithComma(this.props.query.pettyCashPayment.total, "0.00")} บาท</strong>
                                    }
                                    {this.paymentChannel().channel === "cash" &&
                                        <strong><span className="font-weight-bold">จำนวน</span> {numberWithComma(this.props.query.pettyCashPayment.total, "0.00")} บาท</strong>
                                    }
                                    {this.paymentChannel().channel === "bank" &&
                                        <strong><span className="font-weight-bold">วันที่</span> {format(this.props.query.pettyCashPayment.bankAccountTransaction.edges[0]?.node.date, 'DD/MM/YYYY', { locale: thLocale })} <span className="font-weight-bold">จำนวน</span> {numberWithComma(this.props.query.pettyCashPayment.total, "0.00")} บาท</strong>
                                    }
                                </div>
                            </div>

                            <div className="footer" style={{ position: 'absolute', bottom: 0 }} >
                                <div className="signature-large">
                                    <div className="column-foursign">
                                        ..................................................<br />
                                        <span className="font-weight-bold">ผู้จัดทำ / Orgenizer</span><br /><br />
                                        {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                                    </div>
                                    <div className="column-foursign">
                                        ..................................................<br />
                                        <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span><br /><br />
                                        {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                                    </div>
                                    <div className="column-foursign">
                                        ..................................................<br />
                                        <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span><br /><br />
                                        {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                                    </div>
                                    <div className="column-foursign">
                                        ..................................................<br />
                                        <span className="font-weight-bold">ผู้รับเงิน / Receiver</span><br /><br />
                                        {/* <span className="font-weight-bold">วันที่</span> ................................ */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </React.Fragment>


        );
    }
}

export default PettyCashPayment;
