/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSiteButtonKeyInput = {|
  siteId?: ?string,
  siteButtonKey: any,
  clientMutationId?: ?string,
|};
export type SetUpButtonMutationVariables = {|
  input: UpdateSiteButtonKeyInput
|};
export type SetUpButtonMutationResponse = {|
  +updateSiteButtonKey: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type SetUpButtonMutation = {|
  variables: SetUpButtonMutationVariables,
  response: SetUpButtonMutationResponse,
|};
*/


/*
mutation SetUpButtonMutation(
  $input: UpdateSiteButtonKeyInput!
) {
  updateSiteButtonKey(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSiteButtonKeyPayload",
    "kind": "LinkedField",
    "name": "updateSiteButtonKey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetUpButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetUpButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d8511a66c909dfe3b54a95179543af20",
    "id": null,
    "metadata": {},
    "name": "SetUpButtonMutation",
    "operationKind": "mutation",
    "text": "mutation SetUpButtonMutation(\n  $input: UpdateSiteButtonKeyInput!\n) {\n  updateSiteButtonKey(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '316b69ba1f44f3acf49d61929c35e068';

module.exports = node;
