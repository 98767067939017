/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateRegulationInput = {|
  subject?: ?string,
  subjectEn?: ?string,
  description?: ?string,
  descriptionEn?: ?string,
  datePosted?: ?any,
  scheduleTime?: ?any,
  status: string,
  type: string,
  previewImage?: ?string,
  file?: ?$ReadOnlyArray<?any>,
  clientMutationId?: ?string,
|};
export type createRegulationMutationVariables = {|
  input: CreateRegulationInput
|};
export type createRegulationMutationResponse = {|
  +createRegulation: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type createRegulationMutation = {|
  variables: createRegulationMutationVariables,
  response: createRegulationMutationResponse,
|};
*/


/*
mutation createRegulationMutation(
  $input: CreateRegulationInput!
) {
  createRegulation(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRegulationPayload",
    "kind": "LinkedField",
    "name": "createRegulation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createRegulationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createRegulationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "793661365d50b1ae717f2972d22feb03",
    "id": null,
    "metadata": {},
    "name": "createRegulationMutation",
    "operationKind": "mutation",
    "text": "mutation createRegulationMutation(\n  $input: CreateRegulationInput!\n) {\n  createRegulation(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'af389a888d56c47a12bab34415d7e880';

module.exports = node;
