import i18next from 'i18next';
import _ from "lodash";

export default function getMonth(month_index) {
    let month =
        {
            en: [
                "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december",
            ],
            th: [
                "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",
            ]
        }
    ;

    return _.get(month, i18next.language+"."+month_index);
};
