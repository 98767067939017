import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import '../debtFreeRequest.scss'
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';
import _ from 'lodash'
import i18next from 'i18next';

class DocumentInput extends Component{
    constructor(props){
        super(props);
        this.state = {
            document : _.toLower(this.props.state.attorneyBook) === 'document' ? true : _.toLower(this.props.state.attorneyBook) === 'document_and_id_card' ? true : false,
            id_card : _.toLower(this.props.state.attorneyBook) === 'id_card' ? true : _.toLower(this.props.state.attorneyBook) === 'document_and_id_card' ? true : false,
            showAlertBlue : !this.props.disabledInput ? true : false
        }
    }

    switchAttorneyType = (name, type) => {
        let value = ''
        this.setState({
            [name] : type
        }, () => {
            if(this.state.document && !this.state.id_card){
                value = 'document'
            }else if(!this.state.document && this.state.id_card){
                value = 'id_card'
            }else if(this.state.document && this.state.id_card){
                value = 'document_and_id_card'
            }else{
                value = ''
            }
            this.props.handleValueByName('attorneyBook',value)
        } , () => {
            this.switchAttorneyTypeToBoolean()
        })
    }

    componentDidMount(){
        this.switchAttorneyTypeToBoolean()
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.state.attorneyBook !== this.props.state.attorneyBook){
            this.switchAttorneyTypeToBoolean()

        }
    }

    switchAttorneyTypeToBoolean = () => {
        if(_.toLower(this.props.state.attorneyBook) === 'document'){
            this.setState({
                document : true,
                id_card : false
            })
        }else if(_.toLower(this.props.state.attorneyBook) === 'id_card'){
            this.setState({
                document : false,
                id_card : true
            })
        }else if(_.toLower(this.props.state.attorneyBook) === 'id_card'){
            this.setState({
                document : true,
                id_card : true
            })
        }
    }
    
    render(){
        return(
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        <div className="row mt-4  margin-top pl-3 d-flex align-items-center"> 
                            <div className="col-3">
                                <h4>{i18next.t("debt_free_request:List of attached documents")}</h4>
                            </div>
                            {((!this.props.disabledInput || !this.props.state.editInput) && this.props.status !== 'active') &&
                                <div className="col-2">
                                    <div className="btn-attach btn" style={{height:'38px'}}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/attach-clip.png'} alt="plus" className="mr-1"/>
                                        <label htmlFor="CustomFile" className="cursor">{i18next.t("debt_free_request:Attach file")}</label>
                                        <div className="upload-input">
                                            <input type="file" 
                                                className="custom-file-input cursor"
                                                id="CustomFile" name={this.props.name}
                                                accept="application/pdf, image/*" value=""
                                                onChange={(e) => this.props.handleInputFile(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {((!this.props.disabledInput || !this.props.state.editInput) && this.props.status !== 'active') &&
                                <div className="col-7">
                                    <span className="warn-attach ml-3">{i18next.t("debt_free_request:File size not exceeding 10 MB or image size not exceeding 2000x2000 px")}</span> <br/>
                                    <span className="warn-attach ml-3">{i18next.t("debt_free_request:When files are stored, documents or images will have a lifespan of 7 days from the request date")}</span>
                                </div>
                            }
                            <hr/>
                        </div>
                        {this.state.showAlertBlue &&
                            <div className="row col-12 mt-4 d-flex justify-content-center">
                                <div className=" alert-tab tab-blue form-inline">
                                    <img src={process.env.PUBLIC_URL + '/images/icons/alert-blue.png'} alt="alert" className="mr-3"/>
                                    <span>{i18next.t("debt_free_request:Please check the attached document list to request the debt-free certification")}</span>
                                    <img src={process.env.PUBLIC_URL + '/images/icons/close-blue.png'} alt="alert" className="close-icon cursor"
                                        onClick={() => this.setState({showAlertBlue : false})}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row mt-4">
                            <div className="col-6">
                                <div className="col-12 mt-3">
                                    <h5>{i18next.t("debt_free_request:Document list")}</h5>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="checkbox"  
                                        className="check-box mr-3" 
                                        checked={this.props.state.copyIdCard} 
                                        name="copyIdCard"
                                        onChange = {() => this.props.handleValueByName("copyIdCard",!this.props.state.copyIdCard)}
                                        disabled={(this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active' }
                                    />
                                    <span className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>{i18next.t("debt_free_request:Copy of ID card of the unit owner / person receiving the transfer of ownership")}</span>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="checkbox"  className="check-box mr-3"
                                        checked={this.props.state.copyHouse} 
                                        name="copyHouse"
                                        onChange = {() => this.props.handleValueByName("copyHouse",!this.props.state.copyHouse)}
                                        disabled={(this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active' }
                                    />
                                    <span className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>{i18next.t("debt_free_request:Copy of house registration of the unit owner / person receiving the transfer of ownership")}</span>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="checkbox"  className="check-box mr-3"
                                     checked={this.props.state.copyApartment} 
                                     name="copyApartment"
                                     onChange = {() => this.props.handleValueByName("copyApartment",!this.props.state.copyApartment)}
                                     disabled={(this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                    <span className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>{i18next.t("debt_free_request:Copy of the condominium title deed (Chanote)")}</span>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="checkbox"  className="check-box mr-3"
                                        checked={this.props.state.copySurnameChange} 
                                        name="copySurnameChange"
                                        onChange = {() => this.props.handleValueByName("copySurnameChange",!this.props.state.copySurnameChange)}
                                        disabled={(this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                    <span className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>{i18next.t("debt_free_request:Copy of name change document (if any)")}</span>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="checkbox"  className="check-box mr-3"
                                        checked={this.props.state.otherDocument} 
                                        name="otherDocument"
                                        onChange = {() => this.props.handleValueByName("otherDocument",!this.props.state.otherDocument)}
                                        disabled={(this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                    <span className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>{i18next.t("debt_free_request:Other documents, please specify")}</span>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="text"  className="form-control form-control-border col-12" 
                                        placeholder={i18next.t("debt_free_request:Please specify the document")}
                                        value={this.props.state.otherDocumentDescription}
                                        name="otherDocumentDescription"
                                        onChange={(e) => this.props.handleChange(e)}
                                        disabled={(this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                </div>
                                <div className="col-12 mt-4">
                                    <h6>{i18next.t("debt_free_request:Information about the person authorized to receive")}</h6>
                                </div>
                                <div className="col-12 mt-3">
                                    <input type="radio" name="fee" 
                                        id="1"
                                        className="radio-request mr-3"
                                        name="attorney"
                                        onChange = {() => this.props.handleValueByName("attorney",false)}
                                        checked={!this.props.state.attorney}
                                        disabled={(this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                    <span htmlFor="1" className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>
                                    {i18next.t("debt_free_request:No authorized representative")}
                                    </span>
                                </div>
                                <div className="col-12 mt-3">
                                    <input type="radio" name="fee" 
                                        id="2"
                                        className="radio-request mr-3"
                                        name="attorney"
                                        onChange = {() => this.props.handleValueByName("attorney",true)}
                                        checked={this.props.state.attorney}
                                        disabled={(this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                    <span htmlFor="2" className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>
                                    {i18next.t("debt_free_request:Authorized representative")}
                                    </span>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="checkbox"  className="check-box mr-3 ml-4"
                                        onChange = {() => this.switchAttorneyType('document',!this.state.document)}
                                        checked ={this.state.document}
                                        disabled={!this.props.state.attorney || (this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                    <span className={(!this.props.state.attorney || (this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>{i18next.t("debt_free_request:Power of Attorney")}</span>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="checkbox"  className="check-box mr-3 ml-4"
                                        onChange = {() => this.switchAttorneyType('id_card',!this.state.id_card)}
                                        checked ={this.state.id_card}
                                        disabled={!this.props.state.attorney || (this.props.disabledInput && this.props.state.editInput) || this.props.status === 'active' }
                                    />
                                    <span className={(!this.props.state.attorney || (this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ?  'disable' :''}>{i18next.t("debt_free_request:Copy of ID card of the person authorized to receive")}</span>
                                </div>
                                <div className="col-12 form-inline mt-3">
                                    <input type="checkbox"  className="check-box mr-3"
                                        name="waterAndElectric"
                                        onChange = {() => this.props.handleValueByName("waterAndElectric",!this.props.state.waterAndElectric)}
                                        checked={this.props.state.waterAndElectric}
                                        disabled={(this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active'}
                                    />
                                    <span  className={((this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>{i18next.t("debt_free_request:Intending to request a suspension of water/electricity systems")}</span>
                                </div>
                                <div className="row col-12 mt-3 ">
                                    <div className="col-6  mt-3">
                                        <span className={(!this.props.state.waterAndElectric || (this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active')? 'disable' : ''}>{i18next.t("debt_free_request:From date")}</span>
                                    </div>
                                    <div className="col-6  mt-3">
                                        <span className={(!this.props.state.waterAndElectric || (this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active') ? 'disable' : ''}>{i18next.t("debt_free_request:To date")}</span> 
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-6  mt-1">
                                        <DatePickerNoti 
                                            selected={this.props.state.startSuspend}
                                            name="startSuspend"
                                            onChange={(e) => this.props.handleChange(e)}
                                            disabled={!this.props.state.waterAndElectric || (this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active'}
                                        />
                                    </div>
                                    <div className="col-6  mt-1">
                                        <DatePickerNoti 
                                            selected={this.props.state.endSuspend}
                                            name="endSuspend"
                                            onChange={(e) => this.props.handleChange(e)}
                                            disabled={!this.props.state.waterAndElectric || (this.props.disabledInput && !this.props.state.editInput) || this.props.status === 'active'}
                                            minDate={this.props.state.startSuspend ? this.props.state.startSuspend : new Date()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="col-12 mt-3">
                                    <h5>{i18next.t("debt_free_request:Attached files")}</h5>
                                </div>
                                {this.props.state.debsFreeCertificationRequestDocuments.map((file,index) => {
                                    if(file.node.id !== ""){
                                        return(
                                            <div className="col-12 mt-3 form-inline attach-file" key={index}>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/pdf-icon.png'} alt="pdf" className="mr-3"/>
                                                <a download="Download"
                                                    href={file.node.fileUpload}>
                                                    <span className="cursor mr-2">{file.node.nameFile}</span>
                                                </a>
                                                {/* {!this.props.disabledInput &&
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="cancel" className="close-icon cursor" 
                                                        onClick={() => this.props.deleteFile(file.node.debsFreeCertificationRequestDocumentsId === "" ? index : file.node.debsFreeCertificationRequestDocumentsId)}
                                                    />
                                                } */}
                                            </div>
                                        );
                                    }else{
                                        return(
                                            <div className="col-12 mt-3 form-inline attach-file" key={index}>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/pdf-icon.png'} alt="pdf" className="mr-3"/>
                                                <span className="mr-2">{file.node.nameFile}</span>
                                                
                                                    <img src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'} alt="cancel" className="close-icon cursor" 
                                                        onClick={() => this.props.deleteFile(file.node.id === "" ? index : file.node.id)}
                                                    />
                                                
                                            </div>
                                        );
                                    }
                                })
                                }
                                
                            </div>
                        </div>
                    </React.Fragment>

                }

            </Translation>

        );
    }

}
export default DocumentInput;