import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
    query checkPermisionJobQuery($positionId: String) {
        allPositionPermission(positionId: $positionId){
        edges{
            node{
                id
                positionName
                permissionPerPosition{
                    edges{
                        node{
                            id
                            permission{
                                id
                                codename
                                name
                            }
                        }
                    }
                }
            }
        }
    } 
    }
`;

export const checkPermisionJob = (variables, response) => {
  fetchQuery(environment, query, variables)
    .then((data) => {
      if (data) {
        response(data);
      }
    })
    .catch((error) => {
      response(null);
    });
};
