import React, { Component } from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import numberWithCommas from "../../libs/numberWithComma";

class ForeignOwnershipTable extends Component {
  render() {
    let size_all = 0;
    let total_ownership = 0;
    let total_ownershipRatio = 0;
    return (
      <div className="card fade-up">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className="thead-light">
              <Translation>
                {(t) => (
                  <tr>
                    <th>{t("residential:No.")}</th>
                    <th>{t("residential:Room")}</th>
                    <th className="text-center">{t("residential:Unit No")}</th>
                    <th>{t("residential:Ownership")}</th>
                    <th className="text-center">
                      {t("residential:Nationality")}
                    </th>
                    <th>{t("residential:Type")}</th>
                    <th className="text-center">{t("residential:Area")} </th>
                    <th className="text-center">%</th>
                    <th className="text-center">
                      {t("residential:Ownership ratio")}
                    </th>
                    <th className="text-center">
                      {t("residential:Number of floor")}
                    </th>

                    <th>{t("residential:Status")}</th>
                    <th className="text-center">
                      {t("residential:Number of residents")}
                    </th>
                  </tr>
                )}
              </Translation>
            </thead>
            <tbody>
              {this.props.dataAllForeignOwnership?.edges.map(
                (residential, index) => {
                  let owner = [];
                  let countries = [];
                  let count = 0;
                  size_all += residential.node.size;
                  total_ownershipRatio += residential.node.ownershipRatio;
                  residential.node.residentialHouseholder.edges.forEach(
                    (resident) => {
                      if (resident.node.type === "OWNER") {
                        let nameTitle = resident.node.tenant.nameTitle
                          ? resident.node.tenant.nameTitle
                          : "คุณ";
                        owner.push(
                          nameTitle +
                            resident.node.tenant.firstName +
                            " " +
                            resident.node.tenant.lastName
                        );
                        countries.push(resident.node.tenant.countries);
                      }
                      count = count + 1;
                    }
                  );

                  total_ownership += residential.node.ownershipArea;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={{
                            pathname:
                              "/register/residential/detail/room-detail/" +
                              residential.node.id,
                          }}
                        >
                          {residential.node.name}
                        </Link>
                      </td>
                      <td className="text-center">
                        {residential?.node?.unitNumber
                          ? residential?.node?.unitNumber
                          : "-"}
                      </td>
                      {owner?.length > 0 ? (
                        <td>
                          {owner.map((owner_name, index) => {
                            return (
                              <p className="p-margin" key={index}>
                                {owner_name}
                              </p>
                            );
                          })}
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                      {countries?.length > 0 ? (
                        <td className="text-center">
                          {countries.map((countries_name, index) => {
                            return (
                              <p className="p-margin" key={index}>
                                {countries_name}
                              </p>
                            );
                          })}
                        </td>
                      ) : (
                        <td className="text-center">-</td>
                      )}
                      <td>{residential.node.type.name}</td>
                      <td className="text-center">
                        {numberWithCommas(residential.node.size)}
                      </td>
                      <td className="text-center">
                        {" "}
                        {numberWithCommas(residential.node.ownershipArea)}%
                      </td>
                      <td className="text-center">
                        {residential.node.ownershipRatioShow
                          ? residential.node.ownershipRatioShow
                          : numberWithCommas(residential.node.ownershipRatio)}
                      </td>
                      <td className="text-center">{residential.node.floor}</td>
                      {residential.node.status === "FOR_RENT" ? (
                        <Translation>
                          {(t) => <td>{t("residential:For rent")}</td>}
                        </Translation>
                      ) : residential.node.status === "LIVE" ? (
                        <Translation>
                          {(t) => <td>{t("residential:Live")}</td>}
                        </Translation>
                      ) : residential.node.status === "SEIZURE" ? (
                        <Translation>
                          {(t) => <td>{t("residential:Seizure")}</td>}
                        </Translation>
                      ) : (
                        residential.node.status === "AVAILABILITY" && (
                          <Translation>
                            {(t) => <td>{t("residential:Availability")}</td>}
                          </Translation>
                        )
                      )}
                      <td className="text-center">
                        {count}
                      </td>
                    </tr>
                  );
                }
              )}
              <tr>
                <td colSpan={5} />
                <Translation>
                  {(t) => (
                    <td className="text-left">{t("residential:Total")}</td>
                  )}
                </Translation>
                <td className="text-center">{numberWithCommas(size_all)}</td>
                <td className="text-center">
                  {numberWithCommas(total_ownership)}%
                </td>
                <td className="text-center">
                  {numberWithCommas(total_ownershipRatio)}
                </td>
                <td colSpan={3} />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ForeignOwnershipTable;