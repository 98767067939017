import { graphql } from "babel-plugin-relay/macro";
import { commitMutation} from "relay-runtime";

import environment from '../../../env/environment';

const mutation = graphql`
    mutation voidCollectionLawFirmMutation ($input: VoidCollectionLawFirmInput!) {
        voidCollectionLawFirm(input:$input){
            ok
            massage
        }
    }
`

export const VoidCollectionLawFirm = (variables, responses) => {
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                if (response?.voidCollectionLawFirm?.ok) {
                    responses(response?.voidCollectionLawFirm)
                } else {
                    responses(response?.voidCollectionLawFirm)
                }
            },
            onError: (err) => {
                responses(false)
            },
        },
    )
};
