/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDebitManualInput = {|
  updateDebitManual: any,
  clientMutationId?: ?string,
|};
export type updateCollectionMutationVariables = {|
  input: UpdateDebitManualInput
|};
export type updateCollectionMutationResponse = {|
  +updateDebitManual: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type updateCollectionMutation = {|
  variables: updateCollectionMutationVariables,
  response: updateCollectionMutationResponse,
|};
*/


/*
mutation updateCollectionMutation(
  $input: UpdateDebitManualInput!
) {
  updateDebitManual(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDebitManualPayload",
    "kind": "LinkedField",
    "name": "updateDebitManual",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCollectionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCollectionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bf5afb93756a8445d46a9cb5b48365dd",
    "id": null,
    "metadata": {},
    "name": "updateCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation updateCollectionMutation(\n  $input: UpdateDebitManualInput!\n) {\n  updateDebitManual(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '027a401c6fdd64f3666a7849477723e1';

module.exports = node;
