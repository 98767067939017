import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {commitMutation} from "react-relay";

const mutation = graphql`
    mutation residentialVoteMutationMutation($input: UpdateResidentialVoteInput!){
        updateResidentialVote(input: $input){
            residentialVote{
              id
              status
            }
        }
    }`;


export function updateResidentialVote(variables, callback, error_callback) {
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
export default updateResidentialVote;
