/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type otherExpensePeriodAdjustSettingQueryVariables = {|
  service: string,
  contact: string,
|};
export type otherExpensePeriodAdjustSettingQueryResponse = {|
  +periodServicePricing: ?{|
    +unit: ?number,
    +unitPrice: ?number,
    +totalPrice: ?number,
    +description: ?string,
  |}
|};
export type otherExpensePeriodAdjustSettingQuery = {|
  variables: otherExpensePeriodAdjustSettingQueryVariables,
  response: otherExpensePeriodAdjustSettingQueryResponse,
|};
*/


/*
query otherExpensePeriodAdjustSettingQuery(
  $service: String!
  $contact: String!
) {
  periodServicePricing(service: $service, contact: $contact) {
    unit
    unitPrice
    totalPrice
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contact",
        "variableName": "contact"
      },
      {
        "kind": "Variable",
        "name": "service",
        "variableName": "service"
      }
    ],
    "concreteType": "PeriodServicePricing",
    "kind": "LinkedField",
    "name": "periodServicePricing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unitPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "otherExpensePeriodAdjustSettingQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "otherExpensePeriodAdjustSettingQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "925fec2c1a762a80ade48b52ecb3ab59",
    "id": null,
    "metadata": {},
    "name": "otherExpensePeriodAdjustSettingQuery",
    "operationKind": "query",
    "text": "query otherExpensePeriodAdjustSettingQuery(\n  $service: String!\n  $contact: String!\n) {\n  periodServicePricing(service: $service, contact: $contact) {\n    unit\n    unitPrice\n    totalPrice\n    description\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '30da74a9f5a03c87e241643dc9151ff8';

module.exports = node;
