import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import i18next from "i18next";

class PrepaidDepositReportTable extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.props.prepaidDepositViewer.allPrepaidDeposit.edges.map((prepaid_deposit, index) => {
                    let amount = prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.total;
                    let total_use = 0;
                    if(prepaid_deposit.node.status !== "VOID"){
                        return (
                            <React.Fragment key={prepaid_deposit.node.id}>
                                <tr>
                                    <td className="text-center">{index+1}</td>
                                    <td className="text-center">{prepaid_deposit.node.docNumber}</td>
                                    <td className="text-center">{format(prepaid_deposit.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                    <td className="text-left">
                                        {prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode + " "}
                                        {prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.chartOfAccount.name}
                                    </td>
                                    <td className="text-left">{prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.description}</td>
                                    <td className="text-right">{numberWithComma(prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.total)}</td>
                                    <td className="text-right">-</td>
                                    <td className="text-right">{numberWithComma(prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.total)}</td>
                                </tr>
                                {
                                    prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.prepaidDepositTransactionUsed.edges.length !== 0 &&
                                    prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.prepaidDepositTransactionUsed.edges.map((prepaid_deposit_use) => {
                                        amount = amount - prepaid_deposit_use.node.amount;
                                        total_use = total_use + prepaid_deposit_use.node.amount;
                                        return (
                                            <tr key={prepaid_deposit_use.node.id}>
                                                <td/>
                                                <td/>
                                                <td className="text-center">{format(prepaid_deposit_use.node.purchaseRecord.purchaseRecordGroup.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                <td className="text-left">
                                                    {prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode + " "}
                                                    {prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.chartOfAccount.name + " "}
                                                    ({prepaid_deposit_use.node.purchaseRecord.purchaseRecordGroup.docNumber})
                                                </td>
                                                <td className="text-left">{prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.description}</td>
                                                <td className="text-right">-</td>
                                                <td className="text-right">{numberWithComma(prepaid_deposit_use.node.amount)}</td>
                                                <td className="text-right">{numberWithComma(amount, "0.00")}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td className="text-right"><strong>รวม</strong></td>
                                    <td className="text-right"><strong>{numberWithComma(prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.total)}</strong></td>
                                    <td className="text-right"><strong>{numberWithComma(total_use, "0.00")}</strong></td>
                                    <td className="text-right"><strong>{numberWithComma(amount, "0.00")}</strong></td>
                                </tr>
                            </React.Fragment>
                        )
                    }else{
                        return (
                            <React.Fragment key={prepaid_deposit.node.id}>
                                <tr>
                                    <td className="text-center text-danger">{index+1}</td>
                                    <td className="text-center text-danger">{prepaid_deposit.node.docNumber}</td>
                                    <td className="text-center text-danger">{format(prepaid_deposit.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                    <td className="text-left text-danger">
                                        {prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.chartOfAccount.chartOfAccountCode + " "}
                                        {prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.chartOfAccount.name}
                                    </td>
                                    <td className="text-left text-danger">{prepaid_deposit.node.prepaidDepositTransaction.edges[0].node.description}</td>
                                    <td className="text-right text-danger">-</td>
                                    <td className="text-right text-danger">-</td>
                                    <td className="text-right text-danger">-</td>
                                </tr>
                                
                                <tr>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td className="text-right"><strong>{i18next.t("FlowOfPrepaidDepositReport:Total")}</strong></td>
                                    <td className="text-right"><strong>-</strong></td>
                                    <td className="text-right"><strong>-</strong></td>
                                    <td className="text-right"><strong>-</strong></td>
                                </tr>
                            </React.Fragment>
                        )
                    }
                })}
            </React.Fragment>
        )
    }
}

export default PrepaidDepositReportTable;
