import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import "../../contact/styles/index.scss"

class PreviewMobilePost extends Component{
    constructor(props){
        super(props);
        this.state = {
            subject : this.props.subject,
            subjectEn : this.props.subjectEn,
            previewImage : this.props.previewImage,
            detail : this.props.detail,
            detailEn : this.props.detailEn
        }
    }

    componentDidUpdate(){
        if(this.state.subject !== this.props.subject){
            this.setState({subject : this.props.subject})
        }
        if(this.state.subjectEn !== this.props.subjectEn){
            this.setState({subjectEn : this.props.subjectEn})
        }
        if(this.state.previewImage !== this.props.previewImage){
            this.setState({previewImage : this.props.previewImage})
        }
        if(this.state.detail !== this.props.detail){
            this.setState({detail : this.props.detail})
        }
        if(this.state.detailEn !== this.props.detailEn){
            this.setState({detailEn : this.props.detailEn})
        }
    }
    render(){

        let lang = localStorage.getItem("language") || "th"

        return(
            <Translation>
                {t =>
                    <div id="news">
                        <h6 className="text-center mt-5">{t("press_release:display_ex")}</h6>
                        <div className="phone-preview">
                            <div className="inner" >
                                {this.state.previewImage && (
                                <img
                                    src={this.state.previewImage}
                                    alt="news"
                                    className="img-fluid mt-2 mb-2"
                                />
                                )}
                                {
                                    lang === 'th' &&
                                    <>
                                    <h6>{this.state.subject}</h6>
                                        <div
                                        className="content"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.detail,
                                        }} />`
                                    </>
                                }
                                 {
                                    lang === 'en' &&
                                    <>
                                        <h6>{this.state.subjectEn}</h6>
                                        <div
                                        className="content"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.detailEn,
                                        }}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }
            </Translation>

        );
    }


}export default PreviewMobilePost;