import React, {Component} from 'react';
import {Translation} from "react-i18next";
import _ from "lodash";
class PermissionManagement extends Component {

     constructor(props){
        super(props);
        //มีชื่อใน approve print -> เลิก disable
        this.state = {
            create_input: ['project_info','contact_fix_request','project_boardpersonal','ownership_unitreport','ownership_developer',
                'accounting_settingnoteinvoice','accounting_settingnotereceive','project_settingproject','project_developerproject',
                'booking_calendar','coowner'
            ],
            delete_input: ['project_info','register_ownership','contact_chat','contact_fix_request','project_boardpersonal','ownership_ownership',
                'ownership_unitreport','ownership_obligation','ownership_developer','project_juristicmanager','project_settingproject','project_developerproject',
                'booking_calendar','booking_setting','accounting_gateway','coowner',
            ],
            edit_input: ['ownership_ownership','ownership_unitreport','register_ownership','booking_calendar','booking_setting','coowner'],
            approve_input: ['contact_news','contact_survey','contact_fix_request','repairs_room','contact_chat'],
            print_input: ['contact_survey','contact_fix_request','repairs_room','register_residential','contact_parcel','contact_chat','ownership_unitreport','tenant_tenant'],
            list_not_disable: this.props.manage_list_not_disable
        };
    }

    render() {
        return (
            <Translation>
                {t=>
                <div className="row">
                    <div className="col-6">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <tbody>
                                {this.props.management_list.map((value, index) => {
                                    //ซ่อนชั่วคราวเมนู Developer
                                    if (value.group_name.includes('Developer')) {
                                            if (_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on ) {
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr className="table-active">
                                                        <td><h5>{t(`set_permission:${value.group_name}`)}</h5></td>
                                                        <th className="text-center">{t("set_permission:All")}</th>
                                                        <th className="text-center">{t("set_permission:View")}</th>
                                                        <th className="text-center">{t("set_permission:Create")}</th>
                                                        <th className="text-center">{t("set_permission:Edit")}</th>
                                                        <th className="text-center">{t("set_permission:Approve")}</th>
                                                        <th className="text-center">{t("set_permission:Print")}</th>
                                                        <th className="text-center">{t("set_permission:Remove")}</th>
                                                    </tr>
                                                    {value.child.map((child, c_index) => {
                                                     
                                                        return (
                                                            <tr key={'child-'+c_index}>
                                                                <td>{t(`set_permission:${child.name}`)}</td>
                                                                <td className="text-center">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input"
                                                                            id={child.key+'_all'}
                                                                            checked={this.props.checkAllInput(child.key,this.state) || this.props.checkUserJuristicAdmin}
                                                                            onChange={(e)=> this.props.clickCheckAllList(child.key,this.state)}
                                                                        />
                                                                        <label className="custom-control-label"
                                                                            htmlFor={child.key+'_all'}/>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input"
                                                                            id={child.key}
                                                                            checked={this.props.permissions[child.key] || this.props.checkUserJuristicAdmin}
                                                                            onChange={(e)=> this.props.updatePermissionCheck(child.key,e)}
                                                                        />
                                                                        <label className="custom-control-label"
                                                                            htmlFor={child.key}/>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input"
                                                                            id={child.key + '_create'}
                                                                            checked={this.props.permissions[child.key + '_create'] || this.props.checkUserJuristicAdmin}
                                                                                disabled={this.state.create_input.includes(child.key) ? 'disabled': false}
                                                                            onChange={(e) => this.props.updatePermissionCheck(child.key + '_create', e)}
                                                                        />
                                                                        <label className="custom-control-label"
                                                                            htmlFor={child.key+'_create'}/>
                                                                    </div>
                                                                </td>
            
                                                                <td className="text-center">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input"
                                                                            id={child.key + '_edit'}
                                                                            checked={this.props.permissions[child.key + '_edit'] || this.props.checkUserJuristicAdmin}
                                                                            disabled={this.state.edit_input.includes(child.key) ? 'disabled': false}
                                                                            onChange={(e) => this.props.updatePermissionCheck(child.key + '_edit', e)}
                                                                        />
                                                                        <label className="custom-control-label"
                                                                            htmlFor={child.key + '_edit'}/>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input"
                                                                            id={child.key+'_approve'}
                                                                            disabled={this.state.approve_input.includes(child.key) ? false: 'disabled'}
                                                                            checked={this.props.permissions[child.key+'_approve'] || this.props.checkUserJuristicAdmin}
                                                                            onChange={(e) => this.props.updatePermissionCheck(child.key+'_approve', e)}
                                                                    />
                                                                        <label className="custom-control-label"
                                                                            htmlFor={child.key+'_approve'}/>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input"
                                                                            id={child.key+'_print'}
                                                                            disabled={this.state.print_input.includes(child.key) ? false: 'disabled'}
                                                                            checked={this.props.permissions[child.key+'_print'] || this.props.checkUserJuristicAdmin}
                                                                            onChange={(e) => this.props.updatePermissionCheck(child.key+'_print', e)}
                                                                    />
                                                                        <label className="custom-control-label"
                                                                            htmlFor={child.key+'_print'}/>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input"
                                                                            id={child.key + '_delete'}
                                                                            checked={this.props.permissions[child.key + '_delete'] || this.props.checkUserJuristicAdmin}
                                                                            disabled={this.state.delete_input.includes(child.key) ? 'disabled' : false}
                                                                            onChange={(e) => this.props.updatePermissionCheck(child.key + '_delete', e)}
                                                                        />
                                                                        <label className="custom-control-label"
                                                                            htmlFor={child.key+'_delete'}/>
                                                                    </div>
                                                                </td>
                                                                
                                                            </tr>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        }else{
                                            return <></>
                                        }
                                            
                                    }else {
                                        return (
                                            <React.Fragment key={index}>
                                                <tr className="table-active">
                                                    <td><h5>{t(`set_permission:${value.group_name}`)}</h5></td>
                                                    <th className="text-center">{t("set_permission:All")}</th>
                                                    <th className="text-center">{t("set_permission:View")}</th>
                                                    <th className="text-center">{t("set_permission:Create")}</th>
                                                    <th className="text-center">{t("set_permission:Edit")}</th>
                                                    <th className="text-center">{t("set_permission:Approve")}</th>
                                                    <th className="text-center">{t("set_permission:Print")}</th>
                                                    <th className="text-center">{t("set_permission:Remove")}</th>
                                                    
        
                                                </tr>
                                                {value.child.map((child, c_index) => {
                                                    return (
                                                        <tr key={'child-'+c_index}>
                                                            <td>{t(`set_permission:${child.name}`)}</td>
                                                            <td className="text-center">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        id={child.key+'_all'}
                                                                        checked={this.props.checkAllInput(child.key,this.state) || this.props.checkUserJuristicAdmin}
                                                                        onChange={(e)=> this.props.clickCheckAllList(child.key,this.state)}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                        htmlFor={child.key+'_all'}/>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        id={child.key}
                                                                        checked={this.props.permissions[child.key] || this.props.checkUserJuristicAdmin}
                                                                        onChange={(e)=> this.props.updatePermissionCheck(child.key,e)}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                        htmlFor={child.key}/>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        id={child.key + '_create'}
                                                                        checked={this.props.permissions[child.key + '_create'] || this.props.checkUserJuristicAdmin}
                                                                            disabled={this.state.create_input.includes(child.key) ? 'disabled': false}
                                                                        onChange={(e) => this.props.updatePermissionCheck(child.key + '_create', e)}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                        htmlFor={child.key+'_create'}/>
                                                                </div>
                                                            </td>
        
                                                            <td className="text-center">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        id={child.key + '_edit'}
                                                                        checked={this.props.permissions[child.key + '_edit'] || this.props.checkUserJuristicAdmin}
                                                                        disabled={this.state.edit_input.includes(child.key) ? 'disabled': false}
                                                                        onChange={(e) => this.props.updatePermissionCheck(child.key + '_edit', e)}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                        htmlFor={child.key + '_edit'}/>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        id={child.key+'_approve'}
                                                                        disabled={this.state.approve_input.includes(child.key) ? false: 'disabled'}
                                                                        checked={this.props.permissions[child.key+'_approve'] || this.props.checkUserJuristicAdmin}
                                                                        onChange={(e) => this.props.updatePermissionCheck(child.key+'_approve', e)}
                                                                />
                                                                    <label className="custom-control-label"
                                                                        htmlFor={child.key+'_approve'}/>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        id={child.key+'_print'}
                                                                        disabled={this.state.print_input.includes(child.key) ? false: 'disabled'}
                                                                        checked={this.props.permissions[child.key+'_print'] || this.props.checkUserJuristicAdmin}
                                                                        onChange={(e) => this.props.updatePermissionCheck(child.key+'_print', e)}
                                                                />
                                                                    <label className="custom-control-label"
                                                                        htmlFor={child.key+'_print'}/>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        id={child.key + '_delete'}
                                                                        checked={this.props.permissions[child.key + '_delete'] || this.props.checkUserJuristicAdmin}
                                                                        disabled={this.state.delete_input.includes(child.key) ? 'disabled' : false}
                                                                        onChange={(e) => this.props.updatePermissionCheck(child.key + '_delete', e)}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                        htmlFor={child.key+'_delete'}/>
                                                                </div>
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    }
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
            </Translation>
        )
    }
}

export default PermissionManagement;