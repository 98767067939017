import React, { Component } from 'react'
import SearchSelect from '../../libs/searchSelect';


const selectBillingDate = [
  { node: { id: '7', label: '7 วันหลังจากวันที่วางบิล' } },
  { node: { id: '15', label: '15 วันหลังจากวันที่วางบิล' } },
  { node: { id: '30', label: '30 วันหลังจากวันที่วางบิล' } },
  { node: { id: '45', label: '45 วันหลังจากวันที่วางบิล' } },
  { node: { id: 'other', label: 'อื่นๆ ระบุเอง' } },
]

// const valueSelect = [
//   { node: { id: 'other', label: '' } },
// ]

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF'
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

export default class selectPaymentTerms extends Component {
  render() {
    let paymentTerms = this.props.paymentTerms === "other" && this.props.otherPaymentTerms !== null ? "other" : 
        this.props.paymentTerms !== "other" ? this.props.paymentTerms : ""
    return (
      <SearchSelect
        onChange={this.props.handleChangeInput}
        disabled={this.props.disibleInput}
        value={paymentTerms}
        name={"purchaseRequisition.paymentTerms"}
        queryObject={selectBillingDate}
        keyOfValue="id"
        keyOfLabel="label"
        style={customStyles}
      />
    )
  }
}
