import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import './debtFreeCertification.scss'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {fetchQuery } from "react-relay";
import {Translation} from "react-i18next";

const query = graphql`
query navigationDebtCertificationQuery{
    totalCountObject {
        totalCountCertification
    } 
}`;


class NavigationDebtCertification extends Component {

    constructor(props){
        super(props);
        this.state ={
            totalCountCertification : 0,
        }
    }

    componentDidMount(){
        fetchQuery(environment, query).then((data) => {
            if(data.totalCountObject){
                this.setState({
                    totalCountCertification : data.totalCountObject.totalCountCertification,
                })
            }
        })
    }
    render() {
        return (
            
            <div className="row" id="debt-free-certification">
             <Translation>
                { t =>
                <div className="col navigate">
                    <ul>
                        <li>
                            <NavLink to="/accounting/debt_free_certification/list/all" >
                                <span> {t("PageList:All")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/debt_free_certification/list/draft" >
                                 <span> {t("invoice:draft")}</span>
                                {(this.state.totalCountCertification > 0) ? 
                                <p className="float-right font-weight-bold badge text-light bg-danger ml-2">{this.state.totalCountCertification}</p> : false}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/debt_free_certification/list/active" >
                                <span> {t("debtFreeCertification:active")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/debt_free_certification/list/void" >
                                <span> {t("debtFreeCertification:void")}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                }
            </Translation>
            </div>
        )
    }
}

export default NavigationDebtCertification;