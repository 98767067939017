/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClearGuaranteeMoneyReceivedPettyCashChannelStatusSetPettyCash = "VOID" | "WAIT" | "WITHDRAW" | "%future added value";
export type ClearGuaranteeMoneyReceivedStatus = "ACTIVE" | "VOID" | "%future added value";
export type pettyCashRecordListTableGuaranteeMoneyReceivedQueryVariables = {|
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
  setPettyCash_Id?: ?string,
|};
export type pettyCashRecordListTableGuaranteeMoneyReceivedQueryResponse = {|
  +allClearGuaranteeMoneyReceivedPettyCashChannel: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +clearGuaranteeMoneyReceived: ?{|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +status: ClearGuaranteeMoneyReceivedStatus,
          +contact: ?{|
            +refNumber: string,
            +firstName: string,
            +lastName: string,
            +name: string,
          |},
          +guarantee: {|
            +chartOfAccount: ?{|
              +id: string,
              +chartOfAccountCode: string,
              +name: string,
            |}
          |},
        |},
        +description: ?string,
        +price: number,
        +statusSetPettyCash: ?ClearGuaranteeMoneyReceivedPettyCashChannelStatusSetPettyCash,
        +setPettyCash: ?{|
          +docNumber: string,
          +description: ?string,
          +withdrawer: ?string,
        |},
      |}
    |}>,
  |}
|};
export type pettyCashRecordListTableGuaranteeMoneyReceivedQuery = {|
  variables: pettyCashRecordListTableGuaranteeMoneyReceivedQueryVariables,
  response: pettyCashRecordListTableGuaranteeMoneyReceivedQueryResponse,
|};
*/


/*
query pettyCashRecordListTableGuaranteeMoneyReceivedQuery(
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
  $setPettyCash_Id: ID
) {
  allClearGuaranteeMoneyReceivedPettyCashChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id) {
    totalCount
    edges {
      node {
        id
        clearGuaranteeMoneyReceived {
          id
          docNumber
          issuedDate
          status
          contact {
            refNumber
            firstName
            lastName
            name
            id
          }
          guarantee {
            chartOfAccount {
              id
              chartOfAccountCode
              name
            }
            id
          }
        }
        description
        price
        statusSetPettyCash
        setPettyCash {
          docNumber
          description
          withdrawer
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "setPettyCash_Id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "setPettyCash_Id",
    "variableName": "setPettyCash_Id"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chartOfAccountCode",
      "storageKey": null
    },
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusSetPettyCash",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "withdrawer",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashRecordListTableGuaranteeMoneyReceivedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ClearGuaranteeMoneyReceivedPettyCashChannelNodeConnection",
        "kind": "LinkedField",
        "name": "allClearGuaranteeMoneyReceivedPettyCashChannel",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ClearGuaranteeMoneyReceivedPettyCashChannelNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClearGuaranteeMoneyReceivedPettyCashChannelNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClearGuaranteeMoneyReceivedNode",
                    "kind": "LinkedField",
                    "name": "clearGuaranteeMoneyReceived",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuaranteeMoneyReceivedNode",
                        "kind": "LinkedField",
                        "name": "guarantee",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SetPettyCashNode",
                    "kind": "LinkedField",
                    "name": "setPettyCash",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v17/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "pettyCashRecordListTableGuaranteeMoneyReceivedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "ClearGuaranteeMoneyReceivedPettyCashChannelNodeConnection",
        "kind": "LinkedField",
        "name": "allClearGuaranteeMoneyReceivedPettyCashChannel",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ClearGuaranteeMoneyReceivedPettyCashChannelNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClearGuaranteeMoneyReceivedPettyCashChannelNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClearGuaranteeMoneyReceivedNode",
                    "kind": "LinkedField",
                    "name": "clearGuaranteeMoneyReceived",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "contact",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GuaranteeMoneyReceivedNode",
                        "kind": "LinkedField",
                        "name": "guarantee",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SetPettyCashNode",
                    "kind": "LinkedField",
                    "name": "setPettyCash",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v17/*: any*/),
                      (v20/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5900df0578bc0aecdccb1c56ce344b0",
    "id": null,
    "metadata": {},
    "name": "pettyCashRecordListTableGuaranteeMoneyReceivedQuery",
    "operationKind": "query",
    "text": "query pettyCashRecordListTableGuaranteeMoneyReceivedQuery(\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n  $setPettyCash_Id: ID\n) {\n  allClearGuaranteeMoneyReceivedPettyCashChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id) {\n    totalCount\n    edges {\n      node {\n        id\n        clearGuaranteeMoneyReceived {\n          id\n          docNumber\n          issuedDate\n          status\n          contact {\n            refNumber\n            firstName\n            lastName\n            name\n            id\n          }\n          guarantee {\n            chartOfAccount {\n              id\n              chartOfAccountCode\n              name\n            }\n            id\n          }\n        }\n        description\n        price\n        statusSetPettyCash\n        setPettyCash {\n          docNumber\n          description\n          withdrawer\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1eee76026445e071c8a60f683553a01';

module.exports = node;
