/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type queryGetCollectionLawFirmLogQueryVariables = {|
  id?: ?string
|};
export type queryGetCollectionLawFirmLogQueryResponse = {|
  +getCollectionLawFirmLog: ?any
|};
export type queryGetCollectionLawFirmLogQuery = {|
  variables: queryGetCollectionLawFirmLogQueryVariables,
  response: queryGetCollectionLawFirmLogQueryResponse,
|};
*/


/*
query queryGetCollectionLawFirmLogQuery(
  $id: String
) {
  getCollectionLawFirmLog(id: $id)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "getCollectionLawFirmLog",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queryGetCollectionLawFirmLogQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queryGetCollectionLawFirmLogQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7981c4f1717aa925efd7e11dcb565a56",
    "id": null,
    "metadata": {},
    "name": "queryGetCollectionLawFirmLogQuery",
    "operationKind": "query",
    "text": "query queryGetCollectionLawFirmLogQuery(\n  $id: String\n) {\n  getCollectionLawFirmLog(id: $id)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a2477a9697d1b846d3869fedad2269e1';

module.exports = node;
