import React, { forwardRef } from "react";
import "../../styles/commonArea.scss";
import { format, setHours, setMinutes } from "date-fns";
import thLocale from "date-fns/locale/th";
import DatePicker from "react-datepicker";
import "../../../../libs/styles/customDatePickerWidth.scss";
import i18n from "i18next";
const ButtonDate = forwardRef(
  ({ value, onClick, disabled_date }, ref) => (
    <button
      type="button"
      className="date form-control booking-form "
      style={{ width: "100%" }}
      onClick={onClick}
      ref={ref}
      disabled={disabled_date}
    >
      <div className="date-button">{value}</div>
    </button>
  )
);

const PublicServiceOpening = ({
  handleChange,
  addHoliday,
  handleOpen,
  state: {
    holiday,
    open_status,
    holiday_status,
    every_day_all_day: { status },
    new_open,
  },
  handleOpenNew,
}) => {
  const ExampleCustomInput = forwardRef(({ value, onClick, color }, ref) => (
    <div
      className="button-add cursor"
      onClick={onClick}
      ref={ref}
      style={{ color: color }}
    >
      + {i18n.t("newBookingSingha_Create_step1:Add a special holiday")}
    </div>
  ));

  return (
    <>
      <div className="booking-form mb-3  mt-5">
        <div className="switch-languages-div">
          <h2>{i18n.t("newBookingSingha_Create_step1:Date and opening hours")}</h2>
        </div>
        <h3 className="mt-3">{i18n.t("newBookingSingha_Create_step1:Select a day to set the day that the central service is closed. (Ex. 1 January is a New Year Day)")} <span style={{ color: '#E14558' }} >*</span></h3>
        <div className="form-check mt-4 ">
          <input
            className="form-check-input"
            type="radio"
            name="open_status"
            id="open_status1"
            value="set_once"
            onChange={(e) => {
              handleOpen(e);
              handleChange(e);
            }}
            defaultChecked={!status}
          />
          <label className="form-check-label" htmlFor="open_status1">
            <h4 className="regular">{i18n.t("newBookingSingha_Create_step1:Set one time")}</h4>
          </label>
          <div className="d-flex align-items-center mt-1  ">
            <div className="form-group col-md-3 customDatePickerWidth">
              <label htmlFor="every_day_start" className="text-select-time">
                {i18n.t("newBookingSingha_Create_step1:Open Time")}
              </label>
              <DatePicker
                selected={new_open[`every_start`]}
                onChange={(time) => handleOpenNew(`every_start`, time)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="form-control float-left colorborder"
                customInput={
                  <ButtonDate
                    disabled_date={
                      open_status !== "set_once" || new_open[`every_24h`]
                    }
                  />
                }
                minTime={setHours(setMinutes(new_open[`every_end`], 0), 0)}
                maxTime={setHours(
                  setMinutes(
                    new_open[`every_end`],
                    new Date(new_open[`every_end`]).getMinutes()
                  ),
                  new Date(new_open[`every_end`]).getHours()
                )}
              />
            </div>
            <div className="form-group col-md-3 customDatePickerWidth">
              <label htmlFor="every_day_end" className="text-select-time">
                {i18n.t("newBookingSingha_Create_step1:Closed Time")}
              </label>
              <DatePicker
                selected={new_open[`every_end`]}
                onChange={(time) => handleOpenNew(`every_end`, time)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="form-control float-left colorborder"
                customInput={
                  <ButtonDate
                    disabled_date={
                      open_status !== "set_once" || new_open[`every_24h`]
                    }
                  />
                }
                minTime={setHours(
                  setMinutes(
                    new_open[`every_start`],
                    new Date(new_open[`every_start`]).getMinutes()
                  ),
                  new Date(new_open[`every_start`]).getHours()
                )}
                maxTime={setHours(setMinutes(new_open[`every_start`], 59), 23)}
              />
            </div>
            <div className="form-check">
              <input
                disabled={open_status !== "set_once"}
                className="form-check-input"
                type="checkbox"
                value={new_open[`every_24h`]}
                id={`every_24h`}
                name={`every_24h`}
                onChange={() => {
                  handleOpenNew(`every_24h`, !new_open[`every_24h`], "every");
                }}
              />
              <label className="form-check-label" htmlFor={`every_24h`}>
                {i18n.t("newBookingSingha_Create_step1:Open 24 hours")}
              </label>
            </div>
          </div>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="open_status"
            id="open_status2"
            value="custom"
            onChange={(e) => {
              handleOpen(e);
              handleChange(e);
            }}
            defaultChecked={status}
          />
          <label className="form-check-label" htmlFor="open_status2">
            <h4 className="regular">{i18n.t("newBookingSingha_Create_step2:Custom")}</h4>
          </label>
          <SettingTime
            new_open={new_open}
            handleOpenNew={handleOpenNew}
            name="sunday"
            name_label={i18n.t("settingAutoMessage:Sunday")}
            disabled={open_status === "set_once"}
          />
          <SettingTime
            new_open={new_open}
            handleOpenNew={handleOpenNew}
            name="monday"
            name_label={i18n.t("settingAutoMessage:Monday")}
            disabled={open_status === "set_once"}
          />
          <SettingTime
            new_open={new_open}
            handleOpenNew={handleOpenNew}
            name="tuesday"
            name_label={i18n.t("settingAutoMessage:Tueday")}
            disabled={open_status === "set_once"}
          />
          <SettingTime
            new_open={new_open}
            handleOpenNew={handleOpenNew}
            name="wednesday"
            name_label={i18n.t("settingAutoMessage:Wednesday")}
            disabled={open_status === "set_once"}
          />
          <SettingTime
            new_open={new_open}
            handleOpenNew={handleOpenNew}
            name="thursday"
            name_label={i18n.t("settingAutoMessage:Thursday")}
            disabled={open_status === "set_once"}
          />
          <SettingTime
            new_open={new_open}
            handleOpenNew={handleOpenNew}
            name="friday"
            name_label={i18n.t("settingAutoMessage:Friday")}
            disabled={open_status === "set_once"}
          />
          <SettingTime
            new_open={new_open}
            handleOpenNew={handleOpenNew}
            name="saturday"
            name_label={i18n.t("settingAutoMessage:Saturday")}
            disabled={open_status === "set_once"}
          />
        </div>

        <h3 className="mt-3">{i18n.t("newBookingSingha_Create_step1:Option More")}</h3>
        <div className="form-check ">
          <input
            className="form-check-input"
            type="checkbox"
            name="holiday_status"
            id="specialHoliday"
            value="option2"
            onChange={handleChange}
            defaultChecked={holiday_status === false}
          />
          <label className="form-check-label text" htmlFor="specialHoliday">
            {i18n.t("newBookingSingha_Create_step1:special holiday")}
          </label>

          <div className="mt-2">
            <div className="d-flex flex-row align-items-center text "></div>
            <div>
              <ul className="list-group list-group-flush col-8 ">
                {holiday.map((item, index) => {
                  return (
                    <li
                      className="list-group-item  d-flex justify-content-between col-6 "
                      key={index}
                      style={{
                        borderBottomColor: "red",
                        borderBottomWidth: 1,
                        color: "#B3B3B3",
                      }}
                    >
                      {format(new Date(item.holidays), "DD MMMM YYYY", {
                        locale: thLocale,
                      })}
                      <div
                        className="cursor"
                        disabled={!holiday_status}
                        onClick={() => addHoliday("remove", index)}
                        style={{ color: "#B3B3B3" }}
                      >
                        X
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="mt-3">
              <DatePicker
                className=""
                disabled={holiday_status}
                selected={new Date()}
                onChange={(date) => addHoliday(date, "add")}
                customInput={
                  <ExampleCustomInput
                    color={!holiday_status ? "" : "#B3B3B3"}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SettingTime = ({
  new_open,
  handleOpenNew,
  name,
  name_label,
  disabled,
}) => {
  return (
    <div>
      <div className="form-check mt-2">
        <input
          disabled={disabled}
          className="form-check-input"
          type="checkbox"
          value={new_open[`status_${name}`]}
          name={`status_${name}`}
          id={`status_${name}`}
          onChange={() =>
            handleOpenNew(`status_${name}`, !new_open[`status_${name}`])
          }
        />
        <label className="form-check-label text " htmlFor={`status_${name}`}>
          {name_label}
        </label>
      </div>

      <div className="d-flex align-items-center mt-1 ">
        <div className="form-group col-md-3 customDatePickerWidth">
          <label
            htmlFor={new_open[`${name}_start`]}
            className="text-select-time"
          >
            {i18n.t("newBookingSingha_Create_step1:Open Time")}
          </label>
          <DatePicker
            selected={new_open[`${name}_start`]}
            onChange={(time) => handleOpenNew(`${name}_start`, time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="form-control float-left colorborder"
            customInput={
              <ButtonDate
                disabled_date={
                  !new_open[`status_${name}`] || new_open[`${name}_24h`]
                }
              />
            }
            minTime={setHours(setMinutes(new_open[`${name}_end`], 0), 0)}
            maxTime={setHours(
              setMinutes(
                new_open[`${name}_end`],
                new Date(new_open[`${name}_end`]).getMinutes()
              ),
              new Date(new_open[`${name}_end`]).getHours()
            )}
          />
        </div>
        <div className="form-group col-md-3 customDatePickerWidth">
          <label htmlFor={`${name}_end`} className="text-select-time">
            {i18n.t("newBookingSingha_Create_step1:Closed Time")}
          </label>
          <DatePicker
            selected={new_open[`${name}_end`]}
            onChange={(time) => handleOpenNew(`${name}_end`, time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="form-control float-left colorborder"
            customInput={
              <ButtonDate
                disabled_date={
                  !new_open[`status_${name}`] || new_open[`${name}_24h`]
                }
              />
            }
            minTime={setHours(
              setMinutes(
                new_open[`${name}_start`],
                new Date(new_open[`${name}_start`]).getMinutes()
              ),
              new Date(new_open[`${name}_start`]).getHours()
            )}
            maxTime={setHours(setMinutes(new_open[`${name}_start`], 59), 23)}
          />
        </div>
        <div className="form-check">
          <input
            disabled={!new_open[`status_${name}`]}
            className="form-check-input"
            type="checkbox"
            value={new_open[`${name}_24h`]}
            id={`${name}_24h`}
            name={`${name}_24h`}
            onChange={() => {
              handleOpenNew(`${name}_24h`, !new_open[`${name}_24h`], name);
            }}
          />
          <label className="form-check-label" htmlFor={`${name}_24h`}>
            {i18n.t("newBookingSingha_Create_step1:Open 24 hours")}
          </label>
        </div>
      </div>
    </div>
  );
};
export default PublicServiceOpening;
