import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";


class AdvanceReportTable extends React.Component {

    getReceiveChannel(clear_object) {
        // ธนาคาร
        if (clear_object.node.bankAccountTransaction.edges.length !== 0) {
            return `${clear_object.node.bankAccountTransaction.edges[0].node.bankAccount.bankName} 
                (${clear_object.node.bankAccountTransaction.edges[0].node.bankAccount.accountNumber})`
        }

        // เช็ค
        if (clear_object.node.chequeTransaction.edges.length !== 0) {
            return `เช็คเลขที่ ${clear_object.node.chequeTransaction.edges[0].node.chequeNumber} 
            วันที่ ${format(clear_object.node.chequeTransaction.edges[0].node.date, 'DD/MM/YYYY', {locale: thLocale})} 
            ${clear_object.node.chequeTransaction.edges[0].node.bankAccount.bankName} 
            (${clear_object.node.chequeTransaction.edges[0].node.bankAccount.accountNumber})`
        }

        // เงินสด
        if (clear_object.node.cashTransaction.edges.length !== 0) {
            return "เงินสด"
        }

        // เงินสดย่อย
        if (clear_object.node.advancePettyCashChannel.edges.length !== 0) {
            return "เงินสดย่อย"
        }
        
        return ""
    }

    render() {
        let amount_advance = 0.0;
        let amount_clear_advance = 0.0;
        let amount_price = 0.0;
        return (
            <React.Fragment>
                {this.props.props.allAdvance.edges.map((advance,index) => {
                    let amount = advance.node.amount;
                    amount_advance += advance.node.amount
                    return (
                        <React.Fragment key={advance.node.id}>
                            <tr key={advance.node.id}>
                                <td className="text-center">{index+1}</td>
                                <td className="text-center">{format(advance.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                <td className="text-center">{advance.node.docNumber}</td>
                                <td className="text-center">{advance.node.description + " / " + advance.node.withdrawer}</td>
                                <td className="text-right">{numberWithComma(advance.node.amount)}</td>
                                <td className="text-center"/>
                                <td className="text-center"/>
                                <td className="text-center"/>
                                <td className="text-center"/>
                                <td className="text-center"/>
                                <td className="text-center"/>
                                <td className="text-center"/>
                                <td className="text-center"/>
                                <td className="text-center"/>
                                <td className="text-center"/>
                            </tr>
                            {
                                (advance.node.clearAdvance.edges.length !== 0) &&
                                advance.node.clearAdvance.edges.map((clear) => {
                                    return (
                                        clear.node.clearAdvanceTransaction.edges.map((clear_trans, index) => {
                                            amount = amount - clear_trans.node.price;
                                            amount_clear_advance += clear_trans.node.price
                                            index === clear.node.clearAdvanceTransaction.edges.length - 1 ? amount_price += amount : amount_price = amount_price
                                            return (
                                                <tr key={clear_trans.node.id}>
                                                    <td className="text-center"/>
                                                    <td className="text-center"/>
                                                    <td className="text-center"/>
                                                    <td className="text-center"/>
                                                    <td className="text-center"/>
    
                                                    <td className="text-center">{format(clear.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                    <td className="text-center">{clear.node.docNumber}</td>
                                                    <td className="text-center">{clear_trans.node.chartOfAccount.chartOfAccountCode}</td>
                                                    <td className="text-center">{clear_trans.node.description}</td>
                                                    <td className="text-right">{numberWithComma(clear_trans.node.price)}</td>
                                                    <td className="text-right">{index === clear.node.clearAdvanceTransaction.edges.length - 1 && numberWithComma(amount, "0.00")}</td>
    
                                                    {
                                                        amount !== 0 && (index === clear.node.clearAdvanceTransaction.edges.length - 1) ?
                                                            <React.Fragment>
                                                                <td className="text-right">{clear.node.returnPrice !== 0 && numberWithComma(clear.node.returnPrice)}</td>
                                                                <td className="text-center">{clear.node.returnPrice !== 0 && this.getReceiveChannel(clear)}</td>
                                                                <td className="text-right">{clear.node.overPrice !== 0 && numberWithComma(clear.node.overPrice)}</td>
                                                                <td className="text-center">{clear.node.overPrice !== 0 && this.getReceiveChannel(clear)}</td>
                                                            </React.Fragment> :
                                                            <React.Fragment>
                                                                <td className="text-center"/>
                                                                <td className="text-center"/>
                                                                <td className="text-center"/>
                                                                <td className="text-center"/>
                                                            </React.Fragment>
                                                    }
                                                </tr>
                                            )
                                        })
                                    )
                                })
                            }
                            
                        </React.Fragment>
                    );
                })
                
                }
                {(this.props.props.allAdvance.pageInfo.hasNextPage || this.props.props.allAdvance.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={4} className="text-right" style={{borderRight:'none'}}><strong>รวม</strong></td>
                        <td className="text-right" style={{borderLeft:'none'}}><strong>{numberWithComma(amount_advance)}</strong></td>
                        <td className="text-right" colSpan={5} ><strong>{numberWithComma(amount_clear_advance)}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(amount_price)}</strong></td>
                        <td colSpan={4} className="text-right"><strong></strong></td>
                    </tr>
                }
                
                
            </React.Fragment>
        )
    }
}

export default AdvanceReportTable;
