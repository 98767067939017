import React from "react";
import { Translation } from "react-i18next";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import FlowOfPrepaidDepositReportTable from "./flowOfprepaidDepositReportTable";
import ExportFlowOfPrepaidDepositReport from "./server_export";
import { Redirect } from "react-router";
import BackButtonIcon from '../../../../components/BackBtn/indexBack';

import _ from "lodash";

import Loading from "../../../../libs/loading";

import postApiUseing from '../../../../libs/postApiUseing';
import i18next from "i18next";

const query = graphql`
  query flowOfPrepaidDepositReportQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $contact_id: String
    $status: String
  ) {
    prepaidDepositViewer {
      allPrepaidDeposit(
        first: $first
        last: $last
        startDate: $start_date
        endDate: $end_date
        search: $search
        status_In: "active, use_all, use_partial, void"
        order: "doc_number"
        contact: $contact_id
        status: $status
      ) {
        totalCount
        edges {
          node {
            id
            status
            docNumber
            issuedDate
            contact {
              name
              firstName
              lastName
              refNumber
              typeOfContact
            }
            prepaidDepositTransaction {
              edges {
                node {
                  id
                  chartOfAccount {
                    chartOfAccountCode
                    name
                  }
                  description
                  total
                  prepaidDepositTransactionUsed {
                    edges {
                      node {
                        id
                        amount
                        purchaseRecord {
                          purchaseRecordGroup {
                            docNumber 
                            issuedDate
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class FlowOfPrepaidDepositReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let first_date = new Date();
    first_date.setDate(1);

    this.state.start_date = first_date;
    this.state.end_date = new Date();
    this.state.temp_start_date = first_date;
    this.state.temp_end_date = new Date();
    this.state.search = "";
    this.state.search_input = "";
    this.state.status = "all"
    this.state.temp_status = "all"

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "flowOfPrepaidDepositReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      status: this.state.temp_status
    });
  }

  render() {
    if (this.props.customerList.length > 0) {
      return (
        <Translation>
          {t =>
            <div className="container-fluid box">
              <BackButtonIcon
                LinkBack={"/accounting/report/account-payable"}
                LinkText={i18next.t("DashboardPayable:Advance Payment Movement Report")}
                boxHtmlText={
                  <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "report_payable_print",
                    }) && (
                        <ExportFlowOfPrepaidDepositReport
                          state={this.state}
                          customerList={this.props.customerList}
                        />
                      )}
                  </div>
                }
              />

              <div className="content-inner">
                <div className="row justify-content-end mb-2">
                  <div className="col">
                    <div className="input-group float-right w-auto ml-2">
                      <input
                        type="text"
                        className="form-control search-input input-size"
                        placeholder={i18next.t("AllFilter:Search")}
                        name="search"
                        value={this.state.search_input}
                        onChange={(e) =>
                          this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleSearch(e)}
                      >
                        {i18next.t("AllFilter:Search")}
                      </button>
                    </div>

                    <div className="float-right ml-2">
                      <DatePickerAdapter
                        name="temp_end_date"
                        className="form-control"
                        selected={this.state.temp_end_date}
                        maxDate={this.state.current_date}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="float-right ml-2">
                      <DatePickerAdapter
                        name="temp_start_date"
                        className="form-control"
                        selected={this.state.temp_start_date}
                        onChange={this.handleChange}
                        required={true}
                      />
                    </div>

                    {/*<button type="button" className="btn btn-outline-secondary float-right"
                                            onClick={this.advanceSearchToggle}>
                                        <img src="/images/icons/filter-icon.png" alt="filter-icon"/>
                                    </button>*/}
                  </div>
                </div>

                <div className="row fade-up">
                  <div className="col">
                    <div className="card advance-search">
                      <div className="row">
                        {/* <ContactReportList handleChange={this.handleChange}
                                                contact={this.state.contact}/> */}
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{i18next.t("FilterPayable:Status")}</label>
                            <select name="temp_status" onChange={this.handleChange}
                              className="form-control dropdown-custom-arrow">
                              <option value="all">{i18next.t("FilterPayable:All")}</option>
                              <option value="active">{i18next.t("FilterPayable:Unpaid")}</option>
                              <option value="use_all">{i18next.t("FilterPayable:Paid")}</option>
                              <option value="use_partial">{i18next.t("FilterPayable:Partial Payment")}</option>
                              <option value="void">{i18next.t("FilterPayable:Canceled Transaction")}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <QueryRenderer
                  environment={environment}
                  query={query}
                  variables={{
                    first: this.state.first,
                    last: this.state.last,
                    start_date: this.state.start_date,
                    end_date: this.state.end_date,
                    search: this.state.search,
                    contact_id: this.props.customerList,
                    status: this.state.status
                  }}
                  render={({ error, props }) => {
                    if (props) {
                      return (
                        <React.Fragment>
                          <div className="row mt-3">
                            <div className="col-12">
                              <div className="card fade-up">
                                <div className="table-responsive">
                                  <table
                                    className="table table-hover"
                                    style={{ fontSize: 12 }}
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th className="text-center">{i18next.t("FlowOfPrepaidDepositReport:Order")}</th>
                                        <th className="text-center">
                                          {i18next.t("FlowOfPrepaidDepositReport:Advance Payment Number")}
                                        </th>
                                        <th className="text-center">{i18next.t("FlowOfPrepaidDepositReport:Date")}</th>
                                        <th className="text-left">{i18next.t("FlowOfPrepaidDepositReport:Item")}</th>
                                        <th className="text-left">{i18next.t("FlowOfPrepaidDepositReport:Description")}</th>
                                        <th className="text-right">{i18next.t("FlowOfPrepaidDepositReport:Amount")}</th>
                                        <th className="text-right">
                                          {i18next.t("FlowOfPrepaidDepositReport:Amount Received")}
                                        </th>
                                        <th className="text-right">
                                          {i18next.t("FlowOfPrepaidDepositReport:Remaining Amount")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FlowOfPrepaidDepositReportTable
                                        props={props}
                                        state={this.state}
                                      />
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <Pagination
                              changePage={this.changePage}
                              first={this.state.first}
                              last={this.state.last}
                              totalCount={
                                props.prepaidDepositViewer.allPrepaidDeposit
                                  .totalCount
                              }
                            />
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return <Loading />
                    }
                  }}
                />
              </div>
            </div>
          }
        </Translation>
      );
    }
    return (
      <Redirect to="/accounting/report/account-payable/flow_of_prepaid_deposit/select-customer" />
    );
  }
}

export default FlowOfPrepaidDepositReport;
