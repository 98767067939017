import React, {Component} from "react";
import LightBox from "../../libs/lightBox";

class NewsImageRender extends Component {
    render() {        
        return (
            <div className="container card">
                <LightBox 
                    className="img-fluid image card-img"
                    image={this.props.src}
                    width={'100%'}
                    height={'100%'}
                />
                <div className="middle">
                    <div onClick={this.props.onClickDelete}>
                        <img
                            src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                            alt="news_preview_delete"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsImageRender