/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type wrapperPaymentGatewayQueryVariables = {|
  id: string
|};
export type wrapperPaymentGatewayQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +taxIdNumber: ?string,
    +juristicContactNumber: ?string,
    +logo: ?string,
  |},
  +paymentGatewayGroup: ?{|
    +id: string,
    +docNumber: string,
    +image: ?string,
    +depositTotal: number,
    +feeTotal: number,
    +depositDate: any,
    +description: ?string,
    +paymentGatewayTransaction: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +description: ?string,
          +amount: number,
          +fee: number,
          +total: number,
          +refNumber: ?string,
          +issuedDate: ?any,
          +paymentType: ?string,
          +bank: {|
            +id: string,
            +bankName: string,
            +accountNumber: string,
          |},
        |}
      |}>,
    |},
  |},
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +accountNumber: string,
          +accountName: string,
          +bankName: string,
        |}
      |}>
    |}
  |},
  +userConfig: ?{|
    +id: string,
    +languages: string,
  |},
|};
export type wrapperPaymentGatewayQuery = {|
  variables: wrapperPaymentGatewayQueryVariables,
  response: wrapperPaymentGatewayQueryResponse,
|};
*/


/*
query wrapperPaymentGatewayQuery(
  $id: ID!
) {
  selfProject {
    id
    name
    address
    taxIdNumber
    juristicContactNumber
    logo
  }
  paymentGatewayGroup(id: $id) {
    id
    docNumber
    image
    depositTotal
    feeTotal
    depositDate
    description
    paymentGatewayTransaction {
      totalCount
      edges {
        node {
          id
          description
          amount
          fee
          total
          refNumber
          issuedDate
          paymentType
          bank {
            id
            bankName
            accountNumber
          }
        }
      }
    }
  }
  bankAccountViewer {
    allBankAccount(status: true) {
      edges {
        node {
          id
          accountNumber
          accountName
          bankName
        }
      }
    }
    id
  }
  userConfig {
    id
    languages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxIdNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "PaymentGatewayGroupNode",
  "kind": "LinkedField",
  "name": "paymentGatewayGroup",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "depositTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feeTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "depositDate",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentGatewayTransactionNodeConnection",
      "kind": "LinkedField",
      "name": "paymentGatewayTransaction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentGatewayTransactionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentGatewayTransactionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fee",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "refNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "issuedDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BankAccountNode",
                  "kind": "LinkedField",
                  "name": "bank",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountName",
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allBankAccount(status:true)"
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserConfigNode",
  "kind": "LinkedField",
  "name": "userConfig",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperPaymentGatewayQuery",
    "selections": [
      (v2/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperPaymentGatewayQuery",
    "selections": [
      (v2/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "26df8146b50c4f7e7b0db14bcfe116a9",
    "id": null,
    "metadata": {},
    "name": "wrapperPaymentGatewayQuery",
    "operationKind": "query",
    "text": "query wrapperPaymentGatewayQuery(\n  $id: ID!\n) {\n  selfProject {\n    id\n    name\n    address\n    taxIdNumber\n    juristicContactNumber\n    logo\n  }\n  paymentGatewayGroup(id: $id) {\n    id\n    docNumber\n    image\n    depositTotal\n    feeTotal\n    depositDate\n    description\n    paymentGatewayTransaction {\n      totalCount\n      edges {\n        node {\n          id\n          description\n          amount\n          fee\n          total\n          refNumber\n          issuedDate\n          paymentType\n          bank {\n            id\n            bankName\n            accountNumber\n          }\n        }\n      }\n    }\n  }\n  bankAccountViewer {\n    allBankAccount(status: true) {\n      edges {\n        node {\n          id\n          accountNumber\n          accountName\n          bankName\n        }\n      }\n    }\n    id\n  }\n  userConfig {\n    id\n    languages\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4e7dfd4f69e76cfa99fb225c88a0478';

module.exports = node;
