import React, {Component} from 'react';
import "../styles/survey.scss"
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import ResidentialVoteUpdate from "./residentialVoteMutation";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import { Translation } from 'react-i18next';
import i18next from 'i18next';

const _ = require('lodash');
const $ = window.jQuery;

const surveyQuery = graphql`
    query modalSumVoteAndResultQuery($id: ID!) {
        survey(id: $id){
            id
            title
            detail
            status
            surveyQuestion{
                edges{
                    node{
                        id
                        title
                        type
                        questionChoice{
                            edges{
                                node{
                                    id
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
        getResidentialVoteBySurvey(surveyId: $id) {
            surveyId
            countVoted
            countVoteApprove
            countAll
        }
    }
`;


class ModalSumVoteAndResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            detail: '',
            status: '',
            survey_question_answer: [],
            residential_voted: '',
            residential_approve: '',
            residential_all: '',
            disable_edit_vote: true,
            summary_vote: [],
            all_vote: [],
            only_approve: false,
            choice_list_max_vote: [],
            loading: false,
        };
        this.handleModalShowClick = this.handleModalShowClick.bind(this);
        this.handleModalCloseClick = this.handleModalCloseClick.bind(this);
        this.residentialVoteInit = this.residentialVoteInit.bind(this);
        this.summaryVoteInit = this.summaryVoteInit.bind(this);
        this.switchOnlyApprove = this.switchOnlyApprove.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal', () => {
            this.handleModalCloseClick();
        });
    }

    switchOnlyApprove() {
        this.setState({only_approve: !this.state.only_approve}, () => this.summaryVoteInit());
    }

    init() {
        if (this.props.survey_id) {
            fetchQuery(environment, surveyQuery, {id: this.props.survey_id}).then((data) => {
                if (_.isObject(data.survey)) {
                    let survey_question = [];
                    data.survey.surveyQuestion.edges.forEach((question) => {
                        let question_new = _.cloneDeep(question.node);
                        survey_question = [...survey_question, question_new];
                    });
                    this.setState({
                        title: data.survey.title,
                        detail: data.survey.detail,
                        status: data.survey.status,
                        survey_question_answer: survey_question,
                        residential_voted: data.getResidentialVoteBySurvey.countVoted,
                        residential_approve: data.getResidentialVoteBySurvey.countVoteApprove,
                        residential_all: data.getResidentialVoteBySurvey.countAll,
                    });
                }

                if (this.props.type === "prototype") {
                    this.summaryVoteInit();
                }
                if (this.props.type === "result") {
                    this.residentialVoteInit();
                }
            });
        }
    }

    residentialVoteInit() {
        fetchQuery(environment, this.props.query, {
            id: this.props.survey_id,
            residential_vote_id: this.props.residential.id
        }).then((data) => {
            if (data.allVote.edges.length > 0) {
                let all_vote = [];
                data.allVote.edges.forEach((vote) => {
                    let vote_new = _.cloneDeep(vote.node);
                    all_vote = [...all_vote, vote_new];
                });
                this.setState({
                    all_vote: all_vote,
                });
            }
        });
    }

    summaryVoteInit() {
        let status = this.state.only_approve ? "approve" : "";
        fetchQuery(environment, this.props.query, {
            id: this.props.survey_id,
            status: status
        }).then((data) => {
            this.setState({
                summary_vote: data.allChoiceSummaryPercentage,
            }, () => this.insertFieldGiveChoice());
        });
    }

    insertFieldGiveChoice() {
        let new_question_list = [...this.state.survey_question_answer];
        new_question_list.forEach((question) => {
            question.questionChoice.edges.forEach((choice) => {
                _.set(choice, 'node.total', 0);
                _.set(choice, 'node.countPerson', 0);
                this.state.summary_vote.forEach((summary_choice) => {
                    if (atob(choice.node.id).split(':').pop() === summary_choice.choiceId) {
                        _.set(choice, 'node.total', summary_choice.total);
                        _.set(choice, 'node.countPerson', summary_choice.countPerson);
                    }
                });
            })
        });

        let arr = [];
        new_question_list.forEach((question) => {
            let max = question.questionChoice.edges.reduce(function (prev, current) {
                return (prev.node.total > current.node.total) ? prev : current
            });
            arr.push(max);
        });
        new_question_list.forEach((question) => {
            question.questionChoice.edges.forEach((choice) => {
                _.set(choice, 'node.max', false);
                arr.forEach((max) => {
                    if (max.node.id === choice.node.id) {
                        _.set(choice, 'node.max', true);
                    }
                });
            })
        });

        this.setState({survey_question_answer: new_question_list});
    }

    handleModalShowClick() {
        $(this.modal).modal('show', this.init());
    }

    handleModalCloseClick() {
        $(this.modal).modal('hide');
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let variables = {
            input: {
                residentialVoteId: this.props.residential.id,
            }
        };

        Swal.fire({
            title: i18next.t('survey:are_you_sure_to_confirm'),
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t('survey:yes'),
            cancelButtonText: i18next.t('survey:cancel')
        }).then((result) => {
            if (result.value) {
                ResidentialVoteUpdate(variables, this.onSuccess, this.onError)
            }
        });
    }

    onError() {
        this.setState({loading:false});
        Swal.fire('Error!', i18next.t('survey:try_again'), 'warning');
    }

    onSuccess(response) {
        this.setState({loading:false});
        Swal.fire(i18next.t('survey:save_success'), '', 'success');
    }

    findOwnerResidential(householder_list) {
        let find = true;
        let name = '';
        householder_list.forEach((resident) => {
            if (resident.node.type === 'OWNER' && find) {
                find = false;
                name = resident.node.tenant.firstName + ' ' + resident.node.tenant.lastName
            }
        });
        return name
    }

    render() {
        return (
            <Translation>{t=>
            
            <React.Fragment>
                {this.props.type === "prototype" ?
                    <button className="btn btn-primary add" onClick={this.handleModalShowClick}>
                        <img src={process.env.PUBLIC_URL + '/images/icons/survey-icon.png'} alt="survey-icon"/>
                        {t('survey:view_survey')}
                    </button>
                    :
                    <u className="cursor"
                       onClick={this.handleModalShowClick}>{this.props.residential.residential.name}</u>
                }
                <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog"
                     ref={modal => this.modal = modal}
                     aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="col">
                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_print'})?
                                    this.props.type === "prototype" ?
                                        <Link
                                            to={"/contact/survey/document/survey/prototype/" + this.props.survey_id}
                                            onClick={this.handleModalCloseClick}>
                                            <button type="button" className="btn btn-light float-left">
                                                <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                                     alt="print-icon" className="pr-2"/>
                                                {t('survey:print')}
                                            </button>
                                        </Link>
                                        :
                                        <Link
                                            to={"/contact/survey/document/survey/result/" + this.props.survey_id + "/" + this.props.residential.id + "/" + this.props.residential.residential.size}
                                            onClick={this.handleModalCloseClick}>
                                            <button type="button" className="btn btn-light float-left">
                                                <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'}
                                                     alt="print-icon" className="pr-2"/>
                                                {t('survey:print')}
                                            </button>
                                        </Link>:null
                                    }
                                    {this.props.status && this.props.status === "NOT_APPROVE" && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_edit'}) &&
                                    <button
                                        className="btn btn-outline-secondary float-left ml-2" onClick={this.onSubmit}>
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/icons/correct-grey.png'}
                                            alt="correct-icon"
                                            className="pr-2"/>
                                        {t('survey:confirmation_document')}
                                    </button>
                                    }
                                </div>
                                {this.props.type === "prototype" &&
                                <div className="form-inline float-right">
                                    <span className="pr-3 small">{t('survey:show_sender_only')}</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={this.state.only_approve}
                                               onChange={this.switchOnlyApprove}/>
                                        <span className="slider round"/>
                                    </label>
                                </div>
                                }
                            </div>
                            <div className="modal-body text-left">
                                <h4 className="overflow-wrap">{this.state.title}</h4>
                                {this.props.type === "prototype" ?
                                    <span
                                        className="grey-color small"> {t('survey:total_of_voted')}{this.state.only_approve ? this.state.residential_approve : this.state.residential_voted}/{this.state.residential_all}</span>
                                    :
                                    <div className="highlight">
                                        <span className="small">{this.props.residential.residential.name} {
                                            this.findOwnerResidential(this.props.residential.residential.residentialHouseholder.edges)
                                        }
                                        </span>
                                    </div>
                                }
                                {this.state.survey_question_answer.map((question, index_question) =>
                                    <React.Fragment key={question.id}>
                                        <div className="row mt-5">
                                            <div className="col">
                                                <h6>
                                                    {index_question + 1}{". " + question.title}
                                                </h6>
                                            </div>
                                        </div>

                                        {question.questionChoice.edges.map((questionChoice, index_choice) =>
                                            <div className="row mt-3" key={questionChoice.node.id}>
                                                <div className="col-md-1">
                                                    <div
                                                        className={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "custom-control custom-radio custom-control-inline" : "custom-control custom-checkbox"}>
                                                        {this.state.all_vote.length > 0 ?
                                                            <React.Fragment>
                                                                <input
                                                                    type={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "radio" : "checkbox"}
                                                                    checked={this.state.all_vote.some(x => x.choice.id === questionChoice.node.id) ? true : false}
                                                                    className="custom-control-input"
                                                                    disabled={this.state.disable_edit_vote}
                                                                    id={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}
                                                                />
                                                                <label className="custom-control-label"
                                                                       htmlFor={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}/>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <input
                                                                    type={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "radio" : "checkbox"}
                                                                    checked={false}
                                                                    className="custom-control-input"
                                                                    disabled={this.state.disable_edit_vote}
                                                                    id={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}
                                                                />
                                                                <label className="custom-control-label"
                                                                       htmlFor={this.state.survey_question_answer[index_question].type.toLowerCase() === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}/>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    {questionChoice.node.title}
                                                </div>
                                                {this.props.type === "prototype" &&
                                                    <div className="col-md-6">
                                                        <span className={questionChoice.node.max ? "blue-color" : "grey-color"}>
                                                        {t('survey:score')} {questionChoice.node.total}% ({questionChoice.node.countPerson} {t('survey:person')})
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            }</Translation>
        );
    }
}

export default ModalSumVoteAndResult;
