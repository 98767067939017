import '../../../../report.scss';
import React from 'react';
import _ from "lodash";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');


const BankImpactReportExcelExportsDetail = ({bank_data, type, bankName, date, selfProject}) => {
  const getData = async () => {
    await downloadExcel(bank_data, type, bankName, date, selfProject)
  }

  const downloadExcel = async (bank_data, types, bankName, date, project_name) => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');
    // setWidthcolumns
    let columns = [{ width: 10 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 35 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]
    // setBorder
    let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    let fontSizes = { size: 11.5 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let textRight = { vertical: 'middle', horizontal: 'right' };
    let textLeft = { vertical: 'middle', horizontal: 'left' };
    let fontBold = { size: 11.5, bold: true };

    worksheet.getCell('A1').value = project_name
    worksheet.getCell('A1').font = { size: 11.5, bold: true };
    worksheet.getCell('A2').value = "รายงานกระทบยอดเงินฝากธนาคาร"
    worksheet.getCell('A2').font = { size: 11.5, bold: true };
    worksheet.getCell('A3').value = `ประจำวันที่ ${date}`
    worksheet.getCell('A3').font = { size: 11.5, bold: true };
    worksheet.getCell('A4').value = types + " " + bankName
    worksheet.getCell('A4').font = { size: 11.5, bold: true };
    worksheet.addRow();
    var headerRow = worksheet.addRow();
    worksheet.getRow(5).font = { bold: true };
    let headers = ['ลำดับ', 'วันที่', 'เลขที่', 'อ้างอิง', 'คำอธิบาย', 'ยอดยกมา', 'เงินรับ', 'เงินจ่าย', 'จำนวนเงินคงเหลือ']
    for (let i = 0; i < headers.length; i++) {
      let cell = headerRow.getCell(i + 1);
      cell.value = headers[i];
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
    }
    let data = { data: [], style: [] }
    await bank_data.map(async (item, index) => {
      var dataRow = worksheet.addRow();
      data = {
        data: [
          bank_data.length !== index + 1 ? index + 1 : "", 
          _.get(item, 'date'),
          _.get(item, 'ref_number'),
          _.get(item, 'ref_transaction'),
          _.get(item, 'description'),
          _.get(item, 'previous_balance') >= 0 && _.get(item, 'previous_balance') !== "" ? parseFloat(_.get(item, 'previous_balance')).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "",
          _.get(item, 'cash_in') >= 0 && _.get(item, 'cash_in') !== "" ? parseFloat(_.get(item, 'cash_in')).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "",
          _.get(item, 'cash_out') >= 0 && _.get(item, 'cash_out') !== "" ? parseFloat(_.get(item, 'cash_out')).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "",
          _.get(item, 'balance') ? parseFloat(_.get(item, 'balance')).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0.00"
        ],
        style: [{ alignment: textCenter }, { alignment: textCenter }, { alignment: textLeft }, { alignment: textLeft }, { alignment: textLeft },
        { alignment: textRight }, { alignment: textRight }, { alignment: textRight }, { alignment: textRight }]
      }
      headers.map((header, index_header) => {
        let cell = dataRow.getCell(index_header + 1);
        cell.value = data.data[index_header];
        cell.alignment = data.style[index_header]?.alignment;
        cell.border = borders
        cell.font = fontSizes
      })
    })


    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = format(new Date(), 'DD/MM/YYYY', { locale: thLocale })
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = nameFile;
        a.click();
      });
  }
  return (
    <Dropdown.Item  ><p className="text-black" onClick={getData} >Excel</p></Dropdown.Item>
  );
};

export default BankImpactReportExcelExportsDetail;