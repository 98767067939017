import React, { Component } from 'react'
import _ from "lodash"
import {format} from "date-fns";
import i18next from 'i18next';
import numberWithComma from '../../../../../libs/numberWithComma';
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

export default class BatchPrintExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        selfProjectName: props.projectName,
        productAndServiceList: [],
        contactList: [],
    }

    this.downloadExcel = this.downloadExcel.bind(this);
    this.calBatchRange = this.calBatchRange.bind(this);
  }  

  getColorAndText(receive) {
    let text = "";
    switch (receive) {
      case "WAIT":
        text = "Waiting for review";
        break;
      case "PAID":
        text = "paid_info";
        break;
      default:
        text = "Canceled list";        
    }
    return `${i18next.t(`receive:${text}`)}`
  }

  calBatchRange(startRt, endRt) {            
    const containsComma = startRt ? startRt.includes(',') : "";

    let results = ""
    if(containsComma) {
        const startData = startRt.split(",");
        const endData = endRt.split(",");            
        let resultConcat = startData.map((start, index) => `${start} - ${endData[index]}`);
        results = resultConcat.join(', ')
    } else if(startRt === endRt){
        results = startRt
    } else {
        results = `${startRt} - ${endRt}`
    }
    return results
  }

  downloadExcel() {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('Batch RT');

    let columns = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    
    // Set column widths
    worksheet.columns = columns.map((col, index) => {
      return { width: col.width, key: String.fromCharCode(65 + index) };
    });

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let textLeft = { vertical: 'middle', horizontal: 'left' };
    let textRight = { vertical: 'middle', horizontal: 'right' };
    let fontBold = { size: 12, bold: true };
    let projectName = this.state.selfProjectName;
    let reportName = i18next.t(`batchRt:Batch Report`)


    worksheet.getCell('A1').value = projectName
    worksheet.getCell('A1').font = { size: 16, bold: true };
    
    worksheet.getCell('A2').value = reportName
    worksheet.getCell('A2').font = { size: 16, bold: true };

    worksheet.addRow();
    let headerRow = worksheet.addRow();
    let headers = [
      `${i18next.t(`batchRt:Batch number`)}`, 
      `${i18next.t(`batchRt:Date`)}	`,
      `${i18next.t(`batchRt:Time`)}`, 
      `${i18next.t(`batchRt:Quantity`)}`, 
      `${i18next.t(`batchRt:Document number`)}`, 
      `${i18next.t(`batchRt:Reason for Cancellation`)}`
  ]
    let dataRow = worksheet.addRow();
    worksheet.addRow();

    let headerRow02 = worksheet.addRow();
    let headers02 = [
      `${i18next.t(`batchRt:No.`)}`,
      `${i18next.t(`batchRt:Date`)}`,
      `${i18next.t(`batchRt:Room No.`)}`,
      `${i18next.t(`batchRt:Name`)}`,
      `${i18next.t(`batchRt:Description`)}`,  
      `${i18next.t(`batchRt:Amount`)}`,
      `${i18next.t(`batchRt:Total Amount`)}`,
      `${i18next.t(`batchRt:Payment Method`)}`,
      `${i18next.t(`batchRt:Status`)}`,
      `${i18next.t(`batchRt:Create Date`)}`,
      `${i18next.t(`batchRt:Create By`)}`,
   ]
   
   
   let count = 1
    headers.forEach((item) => {
      let cell = headerRow.getCell(count);
      cell.value = item;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
      count += 1
    })

    let dataOfBatch = [
      `${this.props.allBatchDetail?.docNumber}`,
      `${format(this.props.allBatchDetail?.batchDate, "DD/MM/YYYY")}`,
      `${format(this.props.allBatchDetail?.added, 'HH.mm')}`,
      `${this.props.transactionCount} ${i18next.t(`batchRt:Transaction`)}`,
      `${this.calBatchRange(this.props.allBatchDetail?.startRt,this.props.allBatchDetail?.endRt)}` ,
      `${this.props.allBatchDetail?.remark || "-"}`,
    ]

    count = 1
    dataOfBatch.forEach((item) => {
      let cell = dataRow.getCell(count);
      cell.value = item;
      cell.border = borders
      cell.alignment = textCenter
      count += 1
    })

  
    count = 1
    headers02.forEach((item) => {
      let cell = headerRow02.getCell(count);
      cell.value = item;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter
      count += 1
    })


    let dataRows = [];
    for (let i = 0; i <  this.props.rtDetail.length; i++) {
        let data  = this.props.rtDetail[i].node.receive
        

        data.receiveTransaction.edges.forEach((item, index) => {
            let count = 1;
            let currentDataRow = worksheet.addRow();
            dataRows.push(currentDataRow);   
            
            let cell = currentDataRow.getCell(count);
            cell.value = index === 0 ? data.docNumber : "";
            cell.border = borders;
            cell.alignment = textCenter;
            count += 1;
  
            cell = currentDataRow.getCell(count);
            cell.value =  index === 0 ? format(data.issuedDate, "DD/MM/YYYY") : "";
            cell.border = borders;
            cell.alignment = textCenter;
            count += 1;
  
            cell = currentDataRow.getCell(count);
            cell.value =  index === 0 ? data.contact.name : "";
            cell.border = borders;
            cell.alignment = textCenter;
            count += 1;  
  
            cell = currentDataRow.getCell(count);
            cell.value =  index === 0 ? data.contact.firstName + " " + data.contact.lastName : "";
            cell.border = borders;
            cell.alignment = textLeft;
            count += 1;     
            
            cell = currentDataRow.getCell(count);
            cell.value = item.node.transaction.description
            cell.border = borders;
            cell.alignment = textLeft;
            count += 1;     
            
            cell = currentDataRow.getCell(count);
            cell.value = numberWithComma(item.node.amount)
            cell.border = borders;
            cell.alignment = textRight;
            count += 1;    

            cell = currentDataRow.getCell(count);
            cell.value = (index === data.receiveTransaction.edges.length - 1) ? numberWithComma(data.total) : "";
            cell.border = borders;
            cell.alignment = textRight;
            count += 1;                 
            
            cell = currentDataRow.getCell(count);
            cell.value = i18next.t(`batchRt:Transfer Cash`);
            cell.border = borders;
            cell.alignment = textCenter;
            count += 1;     

            cell = currentDataRow.getCell(count);
            cell.value = this.getColorAndText(data.status)
            cell.border = borders;
            cell.alignment = textCenter;
            count += 1;     
            
            cell = currentDataRow.getCell(count);
            cell.value = format(data.added, "DD/MM/YYYY")
            cell.border = borders;
            cell.alignment = textCenter;
            count += 1;     
            
            cell = currentDataRow.getCell(count);
            cell.value = data.creator
            cell.border = borders;
            cell.alignment = textCenter;
            count += 1;   
          });                 
    }
    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = `${i18next.t(`batchRt:Batch Report`)}`;    

        let formData = new FormData();
        formData.append('file', blob , `${nameFile}.xlsx`);
        formData.append('type', "excel");
        formData.append('type_report_ref', "batch_receive_report");

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = nameFile;
        a.click();
      });
      
      
  }

  render() {       
    return (
        <a
        to="/"
        className="dropdown-item printItem"
        target={"_blank"}
        onClick={()=> this.downloadExcel()}>
            {i18next.t(`batchRt:Print Excel`)}
        </a>
    )
  }
}