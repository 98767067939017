import React, {Component} from 'react';
import './fixRequest/styles/fixRequest.scss';
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {graphql} from "babel-plugin-relay/macro";
import {QueryRenderer} from "react-relay";
import environment from "../env/environment";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Loading from "../libs/loading";

const query = graphql`
    query chatDetailHistoryQuery($id: ID!) {
        allRooms(roomId: $id) {
            edges {
                node {
                    id
                    name
                    detail
                    added
                    fixRequestRoom {
                        id
                        fixType
                        technician
                        startDate
                        startTime
                        endTime
                        contactPerson
                        contactNumber
                        technicianName
                    }
                }
            }
        }
    }
`;

class ChatDetailHistory extends Component {

    render() {
        return (
            <Translation>
            {t =>
            <React.Fragment>

                <div className="row mt-4">
                    <div className=" col-5 d-flex align-items-center">
                        <h6>
                            {
                                this.props.page === 'fix-request'? t("chat:previous_work") : t("chat:past_complaints")
                            }
                        </h6>
                    </div>
                    <div className="col">
                        <hr/>
                    </div>
                </div>
                <div className="row mt-3 card-scroll">
                    <div className="col">
                        <QueryRenderer
                            environment={environment}
                            query={query}
                            variables={{id: this.props.id}}
                            render={({error, props}) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    return (
                                        props.allRooms.edges.map((history) =>
                                            <div className="card mb-2" key={history.node.id} onClick={() => this.props.loadHistory(history.node.id)}>
                                                <Link to={"/contact/complaint/chat/complaint/" + history.node.id}
                                                    target={"_blank"}>
                                                <div className="card-body">
                                                    <p className="grey-color">{format(history.node.added, 'DD/MM/YYYY - HH:mm น.', {locale: thLocale})}</p>
                                                    {
                                                        (history.node.fixRequestRoom && this.props.page === 'fix-request') &&
                                                        <span
                                                            className="grey-color">{history.node.fixRequestRoom.technician === 'IN_HOUSE' ? t("chat:in_house_handyman") : t("chat:commercial_handyman")} | {t("chat:inside_the_room")}</span>
                                                    }

                                                    <h6 className="mt-1">{history.node.name}</h6>
                                                </div>
                                                </Link>
                                            </div>
                                        )
                                    );
                                }
                                return <Loading/>
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
            }
            </Translation>

        )
    }
}

export default ChatDetailHistory;
