/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionLawFirmFromSettingDocumentType = "FM_CF_01" | "FM_CF_02" | "FM_CF_03" | "FM_CF_04" | "FM_CF_05" | "FM_CF_12" | "%future added value";
export type collectionLawFrimSettingFormQueryVariables = {|
  siteId?: ?string
|};
export type collectionLawFrimSettingFormQueryResponse = {|
  +allCollectionLawFirmFromSetting: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +documentType: CollectionLawFirmFromSettingDocumentType,
        +dueDateDays: number,
        +dueDateDaysOnsite: number,
        +feeRateTh: number,
        +feeRateOther: number,
        +lawyerSignature: ?string,
        +lawyerName: ?string,
        +rulesRegulationsjuristic: ?string,
        +telOffice: ?string,
        +telJuristic: ?string,
        +language: string,
        +creator: ?string,
        +creatorEdit: ?string,
        +site: {|
          +id: string,
          +project: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +nameTh: ?string,
              |}
            |}>
          |},
        |},
      |}
    |}>
  |}
|};
export type collectionLawFrimSettingFormQuery = {|
  variables: collectionLawFrimSettingFormQueryVariables,
  response: collectionLawFrimSettingFormQueryResponse,
|};
*/


/*
query collectionLawFrimSettingFormQuery(
  $siteId: ID
) {
  allCollectionLawFirmFromSetting(documentType: "FM_CF_01", siteId: $siteId) {
    edges {
      node {
        id
        documentType
        dueDateDays
        dueDateDaysOnsite
        feeRateTh
        feeRateOther
        lawyerSignature
        lawyerName
        rulesRegulationsjuristic
        telOffice
        telJuristic
        language
        creator
        creatorEdit
        site {
          id
          project {
            edges {
              node {
                id
                nameTh
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "documentType",
        "value": "FM_CF_01"
      },
      {
        "kind": "Variable",
        "name": "siteId",
        "variableName": "siteId"
      }
    ],
    "concreteType": "CollectionLawFirmFromSettingNodeConnection",
    "kind": "LinkedField",
    "name": "allCollectionLawFirmFromSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionLawFirmFromSettingNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionLawFirmFromSettingNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "documentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDateDays",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDateDaysOnsite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feeRateTh",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feeRateOther",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lawyerSignature",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lawyerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rulesRegulationsjuristic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "telOffice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "telJuristic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "language",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorEdit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteType",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectNodeConnection",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "nameTh",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLawFrimSettingFormQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionLawFrimSettingFormQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "dca7548c461f6d5984f4487ac9546a5e",
    "id": null,
    "metadata": {},
    "name": "collectionLawFrimSettingFormQuery",
    "operationKind": "query",
    "text": "query collectionLawFrimSettingFormQuery(\n  $siteId: ID\n) {\n  allCollectionLawFirmFromSetting(documentType: \"FM_CF_01\", siteId: $siteId) {\n    edges {\n      node {\n        id\n        documentType\n        dueDateDays\n        dueDateDaysOnsite\n        feeRateTh\n        feeRateOther\n        lawyerSignature\n        lawyerName\n        rulesRegulationsjuristic\n        telOffice\n        telJuristic\n        language\n        creator\n        creatorEdit\n        site {\n          id\n          project {\n            edges {\n              node {\n                id\n                nameTh\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9675ed8eb75367c6950710a3fc16cd6';

module.exports = node;
