import React from 'react';
import { Link } from "react-router-dom";
import numberWithCommas from "../../../libs/numberWithComma";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import ComponentPagination from "../../../libs/componentPagination";
import getDocumentNumber from "../../../libs/getDocumentNumber";
import SearchSelect from "../../../libs/searchSelect";
import './styles/pettyCashPaymentCreate.scss'
import { fetchQuery } from "relay-runtime";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import { format } from "date-fns";
import { Redirect } from 'react-router';
import PayPaymentChannelForm from "../../pay/payPaymentChannelForm";
import _ from 'lodash'
import i18next from 'i18next';

const allPettyCashRecord = graphql`
    query pettyCashPaymentCreateQuery($set_petty_cash : ID!){
        allPettyCashRecord(status: "wait", setPettyCash_Id: $set_petty_cash, order: "doc_number"){
            totalCount
            edges{
                node{
                    id
                    issuedDate
                    docNumber
                    chartOfAccount {
                        id
                        chartOfAccountCode
                        name
                    }
                    description
                    price
                    status
                }
            }
        }
        otherExpensePaymentChannelViewer{
            allOtherExpensePaymentChannel(setPettyCash_Id: $set_petty_cash , status:"paid" ,statusSetPettyCash: "wait"){
                totalCount
                edges{
                    node{
                        id
                        otherExpense{
                            id
                            docNumber
                            issuedDate
                        }
                        description
                        price
                        status
                        statusSetPettyCash
                    }
                }
            }
        }

        paymentChannelViewer{
            allPaymentChannel(setPettyCash_Id: $set_petty_cash , status:"paid" ,statusSetPettyCash: "wait"){
                totalCount
                edges{
                    node{
                        id
                        payRecordGroup{
                            id
                            docNumber
                            issuedDate
                        }
                        description
                        price
                        status
                        statusSetPettyCash
                    }
                }
            }
        }

        allAdvancePettyCashChannel(setPettyCash_Id: $set_petty_cash , advance_Status_In:"withdraw, clear" ,statusSetPettyCash: "wait"){
            totalCount
            edges{
                node{
                    id
                    advance{
                        id
                        docNumber
                        issuedDate
                        dueDate
                        withdrawer
                        description
                        amount
                        status
                        voidRemark
                        chartOfAccount{
                            id
                            name
                            chartOfAccountCode
                        }
                    }
                    description
                    price
                    statusSetPettyCash
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
        allClearAdvancePettyCashChannel(setPettyCash_Id: $set_petty_cash , clearAdvance_Status:"clear" ,statusSetPettyCash: "wait"){
            totalCount
            edges{
                node{
                    id
                    clearAdvance{
                        id
                        docNumber
                        description
                        issuedDate
                    }
                    description
                    price
                    statusSetPettyCash
                    date
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }

        allClearGuaranteeMoneyReceivedPettyCashChannel(setPettyCash_Id: $set_petty_cash , clearGuaranteeMoneyReceived_Status:"active" ,statusSetPettyCash: "wait"){
            totalCount
            edges{
                node{
                    id
                    clearGuaranteeMoneyReceived{
                        id
                        docNumber
                        issuedDate
                        status
                        contact{
                            refNumber
                            firstName
                            lastName
                            name
                        }
                        guarantee {
                            docNumber
                            chartOfAccount {
                                id
                                chartOfAccountCode
                                name
                            }
                        }
                    }
                    description
                    price
                    statusSetPettyCash
                    setPettyCash{
                        docNumber
                        description
                        withdrawer
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation pettyCashPaymentCreateMutation($input: CreatePettyCashPaymentInput!){
      createPettyCashPayment(input: $input){
        ok
        warningText
      }
    }
`;


class PettyCashPaymentCreate extends ComponentPagination {

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state.set_petty_cash = '';
        this.state.total_receive = 0.0;
        this.state.receive_channel = [];
        this.state.transaction_list = [];
        this.state.total_select_transaction = 0.0;
        this.state.redirectToList = false;
        this.state.loading = false;
        this.state.upload = {};
        this.state.issued_date = new Date();
        this.state.transaction_group = {}; // จัดกลุ่มใบรายการแยกตาม doc number
        this.state.transaction_record = []; //กระจายรายการเป็น node สำหรับ getAllList
        this.state.record = [];
        this.state.min_date = null;
        this.state.signreq = Math.random().toString(36);

        this.updateReceiveChannel = this.updateReceiveChannel.bind(this);
        this.fetchQueryAllPettyCashRecord = this.fetchQueryAllPettyCashRecord.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.compareDatePettyCash = this.compareDatePettyCash.bind(this);
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheckList = this.handleCheckList.bind(this);
    }

    componentDidMount() {
        if (this.props.query.allSetPettyCash.edges.length > 0) {
            this.setState({ set_petty_cash: this.props.query.allSetPettyCash.edges[0].node.id }, () => {
                this.fetchQueryAllPettyCashRecord();
            }
            )
        }
    }


    async fetchQueryAllPettyCashRecord() {
        await fetchQuery(environment, allPettyCashRecord, { set_petty_cash: this.state.set_petty_cash }).then(data => {
            let allPettyCashRecord_list = [];
            let allOtherExpensePaymentChannel_list = [];
            let allPaymentChannel_list = [];
            let allAdvanceChannel_list = [];
            let allClearAdvanceChannel_list = [];
            let allClearGuaranteeMoneyReceivedChannel_list = [];
            // Petty cash
            if (_.isObject(data.allPettyCashRecord)) {
                let allPettyCashRecord = _.cloneDeep(data.allPettyCashRecord.edges);
                allPettyCashRecord.forEach((petty) => {
                    allPettyCashRecord_list.push(petty)
                });
            }

            // Payment Channel
            if (_.isObject(data.paymentChannelViewer.allPaymentChannel)) {
                let allPaymentChannel = _.cloneDeep(data.paymentChannelViewer.allPaymentChannel.edges);
                allPaymentChannel.forEach((pay_petty) => {
                    _.set(pay_petty,"node.docNumber", pay_petty.node.payRecordGroup.docNumber);
                    _.set(pay_petty,"node.issuedDate", pay_petty.node.payRecordGroup.issuedDate);
                    allPaymentChannel_list.push(pay_petty)
                });
            }

            // Other Expense
            if (_.isObject(data.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel)) {
                let allOtherExpensePaymentChannel = _.cloneDeep(data.otherExpensePaymentChannelViewer.allOtherExpensePaymentChannel.edges);
                allOtherExpensePaymentChannel.forEach((other_petty) => {
                    _.set(other_petty,"node.docNumber", other_petty.node.otherExpense.docNumber);
                    _.set(other_petty,"node.issuedDate", other_petty.node.otherExpense.issuedDate);
                    allOtherExpensePaymentChannel_list.push(other_petty)
                });
            }
            // Advance
            if (_.isObject(data.allAdvancePettyCashChannel)) {
                let allAdvancePaymentChannel = _.cloneDeep(data.allAdvancePettyCashChannel.edges);
                allAdvancePaymentChannel.forEach((advance_petty) => {
                    _.set(advance_petty,"node.docNumber", advance_petty.node.advance.docNumber);
                    _.set(advance_petty,"node.issuedDate", advance_petty.node.advance.issuedDate);
                    allAdvanceChannel_list.push(advance_petty)
                });
            }

            // Clear Advance
            if (_.isObject(data.allClearAdvancePettyCashChannel)) {
                let allClearAdvancePaymentChannel = _.cloneDeep(data.allClearAdvancePettyCashChannel.edges);
                allClearAdvancePaymentChannel.forEach((clear_advance_petty) => {
                    _.set(clear_advance_petty,"node.docNumber", clear_advance_petty.node.clearAdvance.docNumber);
                    _.set(clear_advance_petty,"node.issuedDate", clear_advance_petty.node.clearAdvance.issuedDate);
                    allClearAdvanceChannel_list.push(clear_advance_petty)
                });
            }

            // Clear Advance
            if (_.isObject(data.allClearGuaranteeMoneyReceivedPettyCashChannel)) {
                let allClearGuaranteeMoneyReceivedPettyCashChannel = _.cloneDeep(data.allClearGuaranteeMoneyReceivedPettyCashChannel.edges);
                allClearGuaranteeMoneyReceivedPettyCashChannel.forEach((clear_guarantee_received) => {
                    _.set(clear_guarantee_received,"node.docNumber", clear_guarantee_received.node.clearGuaranteeMoneyReceived.docNumber);
                    _.set(clear_guarantee_received,"node.issuedDate", clear_guarantee_received.node.clearGuaranteeMoneyReceived.issuedDate);
                    allClearGuaranteeMoneyReceivedChannel_list.push(clear_guarantee_received)
                });
            }
            const merge_pay = [...allPettyCashRecord_list, ...allPaymentChannel_list, 
                ...allOtherExpensePaymentChannel_list, ...allAdvanceChannel_list, ...allClearAdvanceChannel_list, ...allClearGuaranteeMoneyReceivedChannel_list]

            this.setState({ transaction_list: merge_pay });
        });
        this.appendList(this.state.transaction_list)
    }

    appendList(transaction) {
        let transactionList = {};
        let doc_number = null;
        transaction.forEach((value) => {
            if (doc_number === null) {
                doc_number = value.node.docNumber
                transactionList[value.node.docNumber] = [];
                transactionList[value.node.docNumber].push(value)
            } else {
                if (doc_number !== value.node.docNumber) {
                    doc_number = value.node.docNumber
                    transactionList[value.node.docNumber] = [];
                    transactionList[value.node.docNumber].push(value)
                } else {
                    transactionList[value.node.docNumber].push(value)
                }
            }
        })
        this.setState({ transaction_group: transactionList })
        this.getAllRecord(this.state.transaction_group)
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (e.target.name === 'set_petty_cash') {
                this.setState({ checkList: [], check_all: false, checkRecord: [] });
                this.fetchQueryAllPettyCashRecord();
            }
        });

    }

    updateReceiveChannel(channel_list) {
        let total_receive = 0;
        let upload = {};

        channel_list.forEach((channel) => {
            if (channel.slug === "small-change") {
                total_receive -= parseFloat(channel.pay)
            } else {
                total_receive += parseFloat(channel.pay)
            }
            if (channel.image) {
                Object.assign(upload, { [channel.slug]: channel.image });
            }
        });
        this.setState({ receive_channel: channel_list, total_receive: total_receive, upload: upload });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        if (this.state.issued_date < this.state.min_date) {
            Swal.fire(i18next.t("pettyCashCreate:Invalid date"), i18next.t("pettyCashCreate:Please specifies a date after the petty cash payment recording date."), 'warning').then(() => {
                this.setState({ loading: false });
            });

        } else {
            let variables = {
                input: {
                    setPettyCash: this.state.set_petty_cash,
                    transactionList: JSON.stringify(this.state.checkList),
                    paymentChannel: JSON.stringify(this.state.receive_channel),
                    issuedDate: format(this.state.issued_date, 'YYYY-MM-DD'),
                    clientMutationId:"PCP"+this.state.signreq,
                }
            };
            let uploadables = this.state.upload;

            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        this.setState({ loading: false });
                        if (response.createPettyCashPayment.ok) {
                            Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                this.setState({ redirectToList: true });
                            });
                        } else {
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createPettyCashPayment.warningText, 'warning');
                            // Swal.fire('เบิกชดเชยเงินสดย่อยไม่สำเร็จ!', i18next.t("Allaction:Please check again"), 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({ loading: true });
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                    },
                },
            )

        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.checkList !== this.state.checkList) {
            let summary_price = 0.0;
            this.state.checkList.forEach((checked_id) => {
                this.state.transaction_list.forEach((petty_cash_record) => {
                    if (petty_cash_record.node.id === checked_id) {
                        summary_price += petty_cash_record.node.price
                    }
                })
            });
            this.setState({ total_select_transaction: summary_price })
        }
    }

    getAllRecord(transaction_all) {
        let transaction_record = [];
        let group_record = [];
        Object.entries(transaction_all).forEach(([index_group, record], index_obj) => {
            group_record.push(index_group)
            record.forEach((transaction, index) => {
                transaction_record.push(transaction)
            })
        })
        this.setState({
            transaction_record: transaction_record,
            record: group_record
        })
    }

    compareDatePettyCash() {
        let min_date = null;
        if (this.state.checkRecord.length > 0) {
            this.state.checkRecord.forEach(check_list => {
                let index = _.findIndex(this.state.transaction_record, { node: { docNumber: check_list } })
                if (min_date === null) {
                    min_date = new Date(this.state.transaction_record[index].node.issuedDate)
                } else {
                    if (min_date < new Date(this.state.transaction_record[index].node.issuedDate)) {
                        min_date = new Date(this.state.transaction_record[index].node.issuedDate)
                    }
                }
            })
        }

        this.setState({
            min_date: min_date
        })


    }

    async handleCheckAll() {
        await this.getAllListRecord()
        this.compareDatePettyCash()
    }

    async handleCheckList(record, index_group) {
        await this.appendToCheckListRecord(record, index_group)
        this.compareDatePettyCash()
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/expense/petty_cash/list/payment" />
        }

        return (
            <React.Fragment>
                <form action="" onSubmit={this.onSubmit}>
                    <div className="row mb-3 mt-1 ml-4">
                        <div className="col-4 form-inline">
                            <label htmlFor="date"
                                className="col-form-label"><strong>{i18next.t("pettyCashView:List of petty cash")}</strong></label>
                            <div className="col-8">
                                <SearchSelect onChange={this.handleInputChange}
                                    value={this.state.set_petty_cash}
                                    name="set_petty_cash" placeholder="รายการ"
                                    data-key="set_petty_cash"
                                    queryObject={this.props.query.allSetPettyCash.edges}
                                    keyOfValue="id" require={true}
                                    keyOfLabel="docNumber:withdrawer:description" />
                            </div>
                        </div>
                        <div className="col-3 form-inline">
                            <label htmlFor="issued_date"
                                className="mr-3"><strong>{i18next.t("pettyCashView:Date ")}</strong></label>
                            <DatePickerAdapter id="issued_date"
                                className="form-control" name="issued_date"
                                selected={this.state.issued_date}
                                onChange={this.handleInputChange}
                                minDate={this.state.min_date} />
                        </div>

                        <div className="col-3 form-inline">
                            <label htmlFor="start_date"
                                className="startDate mr-3"><strong>{i18next.t("pettyCashView:Document No.")}</strong></label>
                            <input type="text" className="form-control"
                                value={getDocumentNumber.get('petty_cash_payment', 'XXX', new Date())} disabled />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            <div className="content-inner" id="payment-select-petty-cash">
                                {this.state.set_petty_cash &&
                                    <React.Fragment>
                                        <div className="table-responsive fade-up">
                                            <table className="table table-hover mt-2">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th width={50}>
                                                            <input type="checkbox"
                                                                onChange={this.handleCheckAll}
                                                                checked={this.state.check_all} />
                                                        </th>
                                                        <th className="text-center" width={80}>{i18next.t("pettyCashView:No.")}</th>
                                                        <th>{i18next.t("pettyCashView:Document No.")}</th>
                                                        <th>{i18next.t("pettyCashView:List")}</th>
                                                        <th className="text-right">{i18next.t("pettyCashView:Amount")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.transaction_group?.length === 0 ?
                                                        <tr>
                                                            <td colSpan={5}>{i18next.t("Allaction:Not found")}</td>
                                                        </tr>
                                                        :
                                                        Object.entries(this.state.transaction_group).map(([index_group, record], index_obj) => {
                                                            return (record.map((transaction, index) => {
                                                                return (

                                                                    <tr key={transaction.node.id}>
                                                                        <td>
                                                                            {index === 0 &&
                                                                                <input type="checkbox"
                                                                                    onChange={() => this.handleCheckList(record, index_group)}
                                                                                    checked={this.checkRecord(index_group)}
                                                                                />
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">{index === 0 ? index_obj + 1 : ' '}</td>
                                                                        <td >{index === 0 && transaction.node.docNumber}</td>
                                                                        <td >{transaction.node.description}</td>
                                                                        <td className="text-right">{numberWithCommas(transaction.node.price)}</td>
                                                                    </tr>
                                                                )

                                                            }))

                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 text-right">
                                                <div className="col-4 total">
                                                    <div className="row p-3 mb-2 bg-light text-dark">
                                                        <div className="col text-left"> {i18next.t("pettyCashView:Total")}</div>
                                                        <div
                                                            className="col text-right">{numberWithCommas(this.state.total_select_transaction)} {i18next.t("pettyCashView:Baht")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>

                                }

                                <div className="fade-up">
                                    <PayPaymentChannelForm updatePayChannel={this.updateReceiveChannel}
                                        payment_type={'petty_cash'}
                                        single_payment={true}
                                        not_other_channel={true}
                                        issued_date={this.state.issued_date}
                                        auto_suggest={this.state.total_select_transaction}
                                    />
                                </div>

                                <div className="row mt-3 fade-up">
                                    <div className="col text-right">
                                        <div className="btn-group mr-2">
                                            <Link
                                                to="/accounting/expense/petty_cash/list/payment">
                                                <button type="button"
                                                    className="btn btn-secondary add">
                                                    {i18next.t("Allaction:cancel")}
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="btn-group mr-2">
                                            {this.state.checkList.length !== 0 && this.state.total_select_transaction === this.state.total_receive ?
                                                <button type="submit"
                                                    className="btn btn-primary add" disabled={this.state.loading}>
                                                    {this.state.loading &&
                                                        <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                    {i18next.t("pettyCashView:Create a petty cash reimbursement")}
                                                </button>
                                                :
                                                <button type="button" className="btn btn-light disabled add">
                                                    {i18next.t("pettyCashView:Create a petty cash reimbursement")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default PettyCashPaymentCreate;
