import React, { Component } from 'react';
import unitReportAPI  from './unitReportAPI';
import UnitReportpdf from './unitReportpdf';
import generalAPI from '../generalInformation/generalAPI';
import {Helmet} from "react-helmet";
import '../../document/styles/main.scss';
import i18n from 'i18next'
import revenueAPI from '../revenueContract/revenueContractAPI'
import _ from 'lodash';
import Loading from "../../libs/loading";

const head_table = [
    {value:'no', label : i18n.t("developer:No.")},
    {value : 'plan' , label : i18n.t("developer:Plan")},
    {value: 'roomNo' , label : i18n.t("developer:Unit")},
    {value : 'size' , label : 'พื้นที่ (ตร.ม)'},
    {value : 'S001' , label : 'S001'},
    {value : 'S002' , label : 'S002'},
    {value : 'S003' , label : 'S003'},
    {value : 'other' , label : 'อื่นๆ'},
    {value : 'total' , label : 'รวม'}
]

class WrapperUnitReport extends Component{
    constructor(props){
        super(props);
        this.state = {
            unitReportList : [],
            token: localStorage.getItem("token"),
            loading : false,
            developerName : '',
            head_table : head_table,
            projectName : '',
            projectLogo : '',
            totalUnit : '',
            startDate : this.props.match.params.startDate ? new Date(parseInt(this.props.match.params.startDate)) : '',
            endDate : this.props.match.params.endDate ? new Date(parseInt(this.props.match.params.endDate)) : '',
            search : this.props.match.params.search ?  this.props.match.params.search : '',
            lastIndex : 20
        }
    }

    print() {
        window.print();
    }

    componentDidMount(){
        generalAPI.getProfile(this.state.token).then((response => {
            this.setState({
                developerName : response.data.generalData.developer
            })
        }))
        this.fetchData()
    }

    componentDidUpdate(prevProps,prevState,snapshot){
        if(prevState.head_table !== this.state.head_table){
            this.setState({
                head_table : this.state.head_table
            })
        }
    }

    getPriceType = (price,type) => {
        switch (_.lowerCase(type)) {
            case 'ratio' :
                return price + ' บาท / อัตราส่วนกรรมสิทธิ์ / เดือน';
            case 'individual' : 
                return 'คิดตามยูนิต / เดือน';
            case 'area' :
                return  price + ' บาท / ตร.ม. / เดือน';
            case 'static' :
                return  price + ' บาท / เดือน';
            default :
                return  price + ' บาท / หน่วย / เดือน';
        }
    }
    
    fetchData = () => {
        this.setState({loading : true})
        unitReportAPI.getUnitReport(this.state.token,this.state.startDate,this.state.endDate,this.state.search,'','')
            .then((response) => {
                if(this.state.unitReportList !== response.data.unitReportList){
                    this.setState({
                        unitReportList : _.chunk(response.data.unitReportList,this.state.lastIndex),
                        projectName : response.data.projectName,
                        projectLogo : response.data.projectLogo,
                        totalUnit : response.data.totalUnit,
                        totalOther : response.data.totalOther,
                        totalProduct1 : response.data.totalProduct1,
                        totalProduct2 : response.data.totalProduct2,
                        totalProduct3 : response.data.totalProduct3
                        
                    } ,() =>this.setState({loading : false}))
                }
            })
        revenueAPI.getService(this.state.token)
            .then((response) => {
                if(response.data.productAndService){
                    let serviceDefaultCode = ['S001','S002' ,'S003']
                    _.forEach(serviceDefaultCode, (code,index) => {
                        if(_.find(response.data.productAndService , ['productCode',code])){
                            let product = _.find(response.data.productAndService , ['productCode',code])
                            this.setState( prevState => {
                                return _.set(
                                    _.cloneDeep(prevState),
                                    'head_table['+(index+4)+'].label',
                                    product.name + ' ' + this.getPriceType(product.price , product.pricingType)
                                )
                            })
                        }
                    })
                    this.setState({
                        productAndService : response.data.productAndService,
                    })
                }
            })
    }


    render(){
        return(
            
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Unit Report</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>
                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                {this.state.loading ?
                    <div className="text-center"><Loading/></div>
                    :
                    
                    this.state.unitReportList.map((unit,index) => {
                        let order = index === 0 ? 0 : this.state.lastIndex * index
                        return (
                            <UnitReportpdf unitReportList ={unit} 
                                developerName={this.state.developerName} head_table ={this.state.head_table} 
                                projectName ={this.state.projectName} projectLogo = {this.state.projectLogo}
                                state = {this.state} 
                                startDate = {this.state.startDate}
                                lastPage = {index+1 === this.state.unitReportList.length ? true : false}
                                endDate= {this.state.endDate} 
                                order = {order}
                                key ={index + '-report'}
                            />
                        );
                    }) 
                }
            
            </React.Fragment>
        );
    }
    

}
export default WrapperUnitReport;