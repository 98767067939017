import React from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import { format } from "date-fns";
import ComponentPagination from "../../../libs/componentPagination";
import thLocale from "date-fns/locale/th";
import Pagination from "../../../libs/newPagination";
import "./style/chequeListTable.scss";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Translation } from "react-i18next";
import { Tag, B1 } from '../../../components/Text/Text';
import Loading from "../../../libs/loading";
import i18next from "i18next";

class ChequeDepositListTable extends ComponentPagination {
  sortList(chequeList, status) {
    if (status === "deposit") {
      return _.sortBy(chequeList, "node.docNumber").reverse();
    } else return chequeList;
  }

  getBank = (bank) => {
    switch (bank) {
      case "ธนาคารกรุงศรีอยุธยา":
        return "BAY"
      case "ธนาคารกรุงเทพ":
        return "BBL"
      case "ธนาคารกรุงไทย":
        return "KTB"
      case "ธนาคารกสิกรไทย":
        return "KBANK"
      case "ธนาคารซีไอเอ็มบีไทย":
        return "CIMB"
      case "ธนาคารทหารไทย":
        return "TMB"
      case "ธนาคารทิสโก้":
        return "TISCO"
      case "ธนาคารธนชาต":
        return "TBANK"
      case "ธนาคารยูโอบี":
        return "UOB"
      case "ธนาคารเกียรตินาคิน":
        return "KKP"
      case "ธนาคารไทยพาณิชย์":
        return "SCB"
      case "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร":
        return "BAAC"
      case "ธนาคารแลนด์ แอนด์ เฮ้าส์":
        return "LHFG"
      case "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)":
        return "STAN"
      case "ธนาคารออมสิน":
        return "GSB"
      case "ธนาคารอาคารสงเคราะห์":
        return "GHB"
      case "ธนาคารอิสลามแห่งประเทศไทย":
        return "ISBT"
      default:
        return "OTHER"
    }
  }

  getStatus = (count, limit) => {
    if (count > 1) {
      if (count > limit) {
        return { text: 'Active', color: '#68DD6E' }
      } else if (count <= limit) {
        return { text: 'Almost', color: '#FFBD2D' }
      } else if (count <= 0 || count <= '0') {
        return { text: 'Expired', color: '#E14558' }
      } else {
        return { text: '', color: '#68DD6E' }
      }
    } else {
      return { text: 'Expired', color: '#E14558' }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="row" id="cheque-list">
          <div className="col">
            <QueryRenderer
              environment={environment}
              query={this.props.query}
              variables={{ bankAccountId: '' }}
              render={({ error, props }) => {
                if (error) {
                  return <div>{error.message}</div>;
                } else if (props) {
                  return (
                    <React.Fragment>
                      <Translation>
                        {t =>
                          <div className="table-responsive fade-up card">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                <tr>
                                  <th className="text-center" style={{ minWidth: 100 }} >{i18next.t("chequeControlListTable:Code")}</th>
                                  <th style={{ minWidth: 150 }} >{i18next.t("chequeControlListTable:Account Number")}</th>
                                  <th style={{ minWidth: 80 }} >{i18next.t("chequeControlListTable:Bank")}</th>
                                  <th style={{ minWidth: 150 }}>{i18next.t("chequeControlListTable:Branch")}</th>
                                  <th style={{ minWidth: 150 }} >{i18next.t("chequeControlListTable:Account Name (Thai)")}</th>
                                  <th style={{ minWidth: 150 }}>{i18next.t("chequeControlListTable:Account Name (ENG)")}</th>
                                  <th className="text-right" style={{ minWidth: 120 }}>{i18next.t("chequeControlListTable:Remaining Cheque (pieces)")}</th>
                                  <th style={{ minWidth: 120 }}>{i18next.t("chequeControlListTable:Last Update Date")}</th>
                                  <th style={{ minWidth: 100 }}>{i18next.t("chequeControlListTable:Status")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {_.get(props, 'chequeControl.edges') &&
                                  _.get(props, 'chequeControl.edges').map((cheque_Control) => {
                                    let count = _.sumBy(cheque_Control.node.chequeDetails.edges, 'node.chequeNo.totalCount')
                                    let reorderPoint = _.get(cheque_Control, 'node.reorderPoint')
                                    return (
                                      <tr key={cheque_Control.node.id}>
                                        <td className="text-center" >
                                          <Link to={"/accounting/finance/cheque-control/detail/" + cheque_Control.node.id} >
                                            <B1 color='#0382FA' >{cheque_Control.node.docNumber}</B1>
                                          </Link>
                                        </td>
                                        <td><B1>{cheque_Control.node.accountNumber}</B1></td>
                                        <td><B1>{this.getBank(cheque_Control.node.bankName)}</B1></td>
                                        <td><B1>{cheque_Control.node.branch}</B1></td>
                                        <td><B1>{cheque_Control.node.accountName}</B1></td>
                                        <td><B1>{cheque_Control.node.accountNameEn}</B1></td>
                                        <td className="text-right"><B1>{count}</B1></td>
                                        <td><B1>{format(cheque_Control.node.updated, "DD/MM/YYYY", { locale: thLocale })}</B1></td>
                                        <td><Tag color={() => this.getStatus(count, reorderPoint).color} >{i18next.t("chequeControlListTable:"+this.getStatus(count, reorderPoint).text)}</Tag></td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        }
                      </Translation>
                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={props.chequeControl.totalCount}
                        />
                      </div>
                    </React.Fragment>
                  );
                }
                return <Loading/>
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChequeDepositListTable;
