/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfCompany = "BRANCH" | "HEADQUARTERS" | "NOBRANCH" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type ProcurementFormPaymentTerms = "A_15" | "A_30" | "A_45" | "A_7" | "OTHER" | "%future added value";
export type ProcurementFormType = "ASSET" | "PRODUCT" | "SERVICE" | "SERVICE_CONTACT" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type PurchaseOrderesStatus = "APPROVE" | "VOID" | "WAIT" | "%future added value";
export type wrapperManageOrderQueryVariables = {|
  poId?: ?string
|};
export type wrapperManageOrderQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +bankCompCode: ?string,
    +bankServiceCode: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
  |},
  +onePurchaseOrderes: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +status: PurchaseOrderesStatus,
        +voidRemark: string,
        +voidDate: ?any,
        +voider: ?string,
        +approver: ?string,
        +approveDate: ?any,
        +procurementForm: {|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +creditor: {|
            +id: string,
            +name: string,
            +refNumber: string,
            +taxIdNumber: ?string,
            +registeredAddress: string,
            +registeredDistrict: string,
            +registeredPostalCode: string,
            +registeredCity: string,
            +registeredProvince: string,
            +typeOfSupplier: ContactTypeOfSupplier,
            +typeOfCompany: ?ContactTypeOfCompany,
            +nameBranch: string,
          |},
          +buyDate: any,
          +contractNumber: ?string,
          +contractDate: ?any,
          +paymentTerms: ProcurementFormPaymentTerms,
          +otherPaymentTerms: ?string,
          +contractor: ?string,
          +contractorTel: string,
          +contractorEmail: ?string,
          +deliveryAddress: ?string,
          +deliveryDate: any,
          +remark: string,
          +total: ?number,
          +type: ProcurementFormType,
          +procurementList: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +productAndService: ?{|
                  +id: string,
                  +name: string,
                  +productCode: string,
                |},
                +chartOfAccount: ?{|
                  +id: string,
                  +chartOfAccountCode: string,
                  +name: string,
                |},
                +description: string,
                +unitItems: number,
                +price: number,
                +unit: string,
                +discount: ?number,
                +budget: ?number,
                +vat: ?number,
                +whtRate: ?number,
                +whtRatePercent: ?number,
                +preTaxAmount: ?number,
                +total: ?number,
              |}
            |}>
          |},
        |},
      |}
    |}>
  |},
|};
export type wrapperManageOrderQuery = {|
  variables: wrapperManageOrderQueryVariables,
  response: wrapperManageOrderQueryResponse,
|};
*/


/*
query wrapperManageOrderQuery(
  $poId: String
) {
  selfProject {
    id
    name
    address
    logo
    juristicContactNumber
    keyProjectQr
    bankCompCode
    bankServiceCode
    taxIdNumber
    typeOfProject
  }
  onePurchaseOrderes(poId: $poId) {
    edges {
      node {
        id
        docNumber
        issuedDate
        status
        voidRemark
        voidDate
        voider
        approver
        approveDate
        procurementForm {
          id
          docNumber
          issuedDate
          creditor {
            id
            name
            refNumber
            taxIdNumber
            registeredAddress
            registeredDistrict
            registeredPostalCode
            registeredCity
            registeredProvince
            typeOfSupplier
            typeOfCompany
            nameBranch
          }
          buyDate
          contractNumber
          contractDate
          paymentTerms
          otherPaymentTerms
          contractor
          contractorTel
          contractorEmail
          deliveryAddress
          deliveryDate
          remark
          total
          type
          procurementList {
            edges {
              node {
                id
                productAndService {
                  id
                  name
                  productCode
                }
                chartOfAccount {
                  id
                  chartOfAccountCode
                  name
                }
                description
                unitItems
                price
                unit
                discount
                budget
                vat
                whtRate
                whtRatePercent
                preTaxAmount
                total
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "poId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxIdNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "juristicContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "keyProjectQr",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bankCompCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bankServiceCode",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfProject",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "poId",
        "variableName": "poId"
      }
    ],
    "concreteType": "PurchaseOrderesNodeConnection",
    "kind": "LinkedField",
    "name": "onePurchaseOrderes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PurchaseOrderesNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PurchaseOrderesNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidRemark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approver",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approveDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProcurementFormNode",
                "kind": "LinkedField",
                "name": "procurementForm",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "creditor",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "refNumber",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "registeredAddress",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "registeredDistrict",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "registeredPostalCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "registeredCity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "registeredProvince",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "typeOfSupplier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "typeOfCompany",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nameBranch",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buyDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contractNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contractDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentTerms",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "otherPaymentTerms",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contractor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contractorTel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contractorEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deliveryAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deliveryDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remark",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProcurementListNodeConnection",
                    "kind": "LinkedField",
                    "name": "procurementList",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProcurementListNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProcurementListNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "productCode",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChartOfAccountNode",
                                "kind": "LinkedField",
                                "name": "chartOfAccount",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "chartOfAccountCode",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "unitItems",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "price",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "unit",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "discount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "budget",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "vat",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "whtRate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "whtRatePercent",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "preTaxAmount",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperManageOrderQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperManageOrderQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "52194b9c6d8c6696d13b14cdb948def7",
    "id": null,
    "metadata": {},
    "name": "wrapperManageOrderQuery",
    "operationKind": "query",
    "text": "query wrapperManageOrderQuery(\n  $poId: String\n) {\n  selfProject {\n    id\n    name\n    address\n    logo\n    juristicContactNumber\n    keyProjectQr\n    bankCompCode\n    bankServiceCode\n    taxIdNumber\n    typeOfProject\n  }\n  onePurchaseOrderes(poId: $poId) {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        status\n        voidRemark\n        voidDate\n        voider\n        approver\n        approveDate\n        procurementForm {\n          id\n          docNumber\n          issuedDate\n          creditor {\n            id\n            name\n            refNumber\n            taxIdNumber\n            registeredAddress\n            registeredDistrict\n            registeredPostalCode\n            registeredCity\n            registeredProvince\n            typeOfSupplier\n            typeOfCompany\n            nameBranch\n          }\n          buyDate\n          contractNumber\n          contractDate\n          paymentTerms\n          otherPaymentTerms\n          contractor\n          contractorTel\n          contractorEmail\n          deliveryAddress\n          deliveryDate\n          remark\n          total\n          type\n          procurementList {\n            edges {\n              node {\n                id\n                productAndService {\n                  id\n                  name\n                  productCode\n                }\n                chartOfAccount {\n                  id\n                  chartOfAccountCode\n                  name\n                }\n                description\n                unitItems\n                price\n                unit\n                discount\n                budget\n                vat\n                whtRate\n                whtRatePercent\n                preTaxAmount\n                total\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac8b50b87f5fc48347819f3b512f3bfd';

module.exports = node;
