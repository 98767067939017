import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, commitMutation } from "relay-runtime";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import "./settingPaymentgateway.scss"
import _ from "lodash";
import i18n from "i18next";
import SearchSelect from "../../libs/searchSelect";
import jwtDecode from "jwt-decode";
import Loading from '../../libs/loading';

const query = graphql`
    query settingPaymentgatewayQuery {

        paymentGatewaySettingList{
            edges{
              node{
                id
                paymentType
                fee
                typeCal
                bankAccount{
                    id
                    docNumber
                    accountNumber
                    accountName
                    accountType
                    receivePayment
                    makePayment
                    bankName
                    status
                }
              }
            }
        }
        bankAccountViewer {
            allBankAccount(status: true, accountType_In:"current_account,saving_account,saving_deposit") {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                    }
                }
                totalCount
            }
        }

        crossBilling : bankAccountViewer {
            allBankAccount(status: true, crossBilling: true) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                        hasCheque
                        branch
                    }
                }
                totalCount
            }
        }

        deeplink : bankAccountViewer {
            allBankAccount(status: true, accountType_In:"current_account,saving_account,saving_deposit",bankName_Icontains:"ธนาคารไทยพาณิชย์",) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                        hasCheque
                        branch
                    }
                }
                totalCount
            }
        }

    }`;

const mutation = graphql`
    mutation settingPaymentgatewayMutation($input: CreatePaymentGatewaySettingInput!) {
        createPaymentGatewaySetting(input: $input) {
            ok
            warningText
        }
    }
`;

class settingPaymentgatewayList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            redirectToList: false,
            user_super_admin: false,
            paymentGatewaySettingList: null,
            allBankAccount: null,
            cross_billing_list: null,
            deeplink:null
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        let token = window.localStorage.getItem('token');
        let token_decode = jwtDecode(token)
        // if(token_decode.user_super_admin){
            this.query();
        // }
        // let token = window.localStorage.getItem('token');
        // let token_decode = jwtDecode(token)
        // this.setState({ user_super_admin: token_decode.user_super_admin })
    }

    query() {
        fetchQuery(environment, query).then(data => {
            let data_clone = _.cloneDeep(data);

            let bank_list = [];
            data_clone.bankAccountViewer.allBankAccount.edges.forEach((bank) => {
                let account_type = 'ออมทรัพย์';
                if (bank.node.accountType === 'CURRENT_ACCOUNT') {
                    account_type = 'กระแสรายวัน'
                } else if (bank.node.accountType === 'FIXED_DEPOSIT') {
                    account_type = 'ฝากประจำ'
                }
                _.set(bank, 'node.type', 'bank');
                _.set(bank, 'node.account_type', account_type);
            });
            bank_list = data_clone.bankAccountViewer.allBankAccount.edges;

            //crossBilling
            let cross_billing_list = [];
            data_clone.crossBilling.allBankAccount.edges.forEach((bank) => {
                let account_type = 'ออมทรัพย์';
                if (bank.node.accountType === 'CURRENT_ACCOUNT') {
                    account_type = 'กระแสรายวัน'
                } else if (bank.node.accountType === 'FIXED_DEPOSIT') {
                    account_type = 'ฝากประจำ'
                }
                _.set(bank, 'node.type', 'bank');
                _.set(bank, 'node.account_type', account_type);
            });
            cross_billing_list = data_clone.crossBilling.allBankAccount.edges;

            //deeplink
            let deeplink_list = [];
            data_clone.deeplink.allBankAccount.edges.forEach((bank) => {
                let account_type = 'ออมทรัพย์';
                if (bank.node.accountType === 'CURRENT_ACCOUNT') {
                    account_type = 'กระแสรายวัน'
                } else if (bank.node.accountType === 'FIXED_DEPOSIT') {
                    account_type = 'ฝากประจำ'
                }
                _.set(bank, 'node.type', 'bank');
                _.set(bank, 'node.account_type', account_type);
            });
            deeplink_list = data_clone.deeplink.allBankAccount.edges;
            
            this.setState({
                paymentGatewaySettingList: data_clone.paymentGatewaySettingList,
                allBankAccount: bank_list,
                cross_billing_list: cross_billing_list,
                deeplink: deeplink_list,
                loading: false,
            });
        })
    }

    handleInputChange(e) {
        let paymentGatewaySettingList = _.cloneDeep(this.state.paymentGatewaySettingList);
        if (e.target.name === "bankAccount") {
            let bank_select = _.find(this.state.allBankAccount, { node: { id: e.target.value } });
            _.set(paymentGatewaySettingList, "edges[" + e.target.dataset.id + "].node.bankAccount", bank_select.node);
            this.setState({ paymentGatewaySettingList: paymentGatewaySettingList });
        }
        else if (e.target.name === "fee") {
            const check_char = e.target.value.toString()
            if(check_char.length > 4){
                return;
            }

            let value_number = parseFloat(e.target.value)

            let typeCal = _.get(paymentGatewaySettingList, "edges[" + e.target.dataset.id + "].node.typeCal", '');
            
            if ( typeCal === "PERCENT" && value_number > 9.99){ 
                value_number = 9.99;
            }
            else if(typeCal === "MONNEY_FAIT" && value_number <= 99){
                value_number = parseInt(value_number)
            }
            else if ( typeCal === "MONNEY_FAIT" && value_number > 99) {
                value_number = 99;
            }

            _.set(paymentGatewaySettingList, "edges[" + e.target.dataset.id + "].node.fee", value_number);
            this.setState({ paymentGatewaySettingList: paymentGatewaySettingList });
        }
        else if (e.target.name === "typeCal") {
            _.set(paymentGatewaySettingList, "edges[" + e.target.dataset.id + "].node.typeCal", e.target.value);
            this.setState({ paymentGatewaySettingList: paymentGatewaySettingList });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }

    }

    onSubmit(e) {
        // this.setState({ loading: true });

        e.preventDefault();
        let variables = {
            input: {
                paymentGatewaySetting: JSON.stringify(this.state.paymentGatewaySettingList.edges)
            },
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                // uploadables,
                onCompleted: (response) => {
                    if (response.createPaymentGatewaySetting.ok) {
                        Swal.fire(i18n.t('setuppaymentgateway:Save successfully'), '', 'success').then(() => {
                            this.setState({ redirectToList: true, loading: false });
                            this.query();
                        })
                    } else {
                        Swal.fire(i18n.t('setuppaymentgateway:Failed to save'), '', 'warning');
                        this.setState({ loading: false });

                    }
                },
                onError: (err) => {
                    Swal.fire('Error!', i18n.t('setuppaymentgateway:Please try again'), 'warning')
                    this.setState({ loading: false });
                },
            },
        );

    }

    render() {
        let accounting_gateway =
            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_gateway_create" }) ||
            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_gateway_edit" })
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                    {
                    t => (
                        <div id="wrapper-content" >
                            <div className="container-fluid box" id='paymentgateway-setting' >
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            <span>{t('setuppaymentgateway:setupPaymentgatewaypayments')}</span>
                                        </h3>
                                    </div>
                                </div>


                                <div className="content-inner padding" >
                                    {this.state.loading ?
                                    <Loading/>
                                        :
                                        <div className="card-table fade-up">
                                            <form id="paymentgateway-setting" onSubmit={this.onSubmit}>
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr className="head" >
                                                            <th width={150} className="text-center" >{t('setuppaymentgateway:Payment method')}</th>
                                                            <th />
                                                            <th width={150} className="text-center">{t('setuppaymentgateway:Fee rate')}</th>
                                                            <th width={180} className="text-center">{t('setuppaymentgateway:Fee Calculation')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.paymentGatewaySettingList.edges.map((data, index) => {
                                                            let allBankAccount = []
                                                            if(data.node.paymentType === "CROSSBILL"){
                                                                allBankAccount = this.state.cross_billing_list
                                                            }else if(data.node.paymentType === "DEEPLINK"){
                                                                allBankAccount = this.state.deeplink
                                                            }
                                                            else{
                                                                allBankAccount = this.state.allBankAccount
                                                            }
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text-center">
                                                                    {
                                                                        data.node.paymentType === 'TWOCTWOP' ? '2C2P': data.node.paymentType
                                                                    }
                                                                    </td>
                                                                    {
                                                                        data.node.paymentType === "SLIP_VERIFICATION" ? <td/> 
                                                                        :
                                                                        <td className="text-left">
                                                                            <SearchSelect onChange={this.handleInputChange}
                                                                                value={data.node.bankAccount ? data.node.bankAccount.id : null}
                                                                                name="bankAccount" placeholder="รายการ"
                                                                                queryObject={allBankAccount}
                                                                                keyOfValue="id"
                                                                                // require={true}
                                                                                data-id={index}
                                                                                data-key="paymentGatewaySettingList"
                                                                                keyOfLabel="bankName:account_type:accountName:accountNumber"
                                                                                disabled={!accounting_gateway} />
                                                                        </td>
                                                                    }
                                                                    {
                                                                        data.node.paymentType === "KSHER" ? <td className="edit">
                                                                            <input type='number' className="form-control text-right" name="fee"
                                                                                data-id={index}
                                                                                data-key="paymentGatewaySettingList"
                                                                                value={data.node.fee}
                                                                                onChange={this.handleInputChange} required
                                                                                disabled
                                                                            />
                                                                        </td> : <td className="edit">
                                                                            <input type='number' className="form-control text-right" name="fee"
                                                                                data-id={index}
                                                                                data-key="paymentGatewaySettingList"
                                                                                value={data.node.fee}
                                                                                onChange={this.handleInputChange} required
                                                                                disabled={!accounting_gateway}
                                                                            />
                                                                        </td>
                                                                    } 
                                                                    {
                                                                        data.node.paymentType === "KSHER" ? <td className="text-right">
                                                                            <select name="typeCal"
                                                                                    value={data.node.typeCal}
                                                                                    onChange={this.handleInputChange}
                                                                                    data-id={index}
                                                                                    data-key="paymentGatewaySettingList"
                                                                                    className="form-control dropdown-custom-arrow"
                                                                                    disabled
                                                                                >
                                                                                <option value="PERCENT">อัตราร้อยละ / รายการ</option>
                                                                                <option value="MONNEY_FAIT">บาท / รายการ</option>
                                                                            </select>
                                                                        </td> : <td className="text-right">
                                                                            <select name="typeCal"
                                                                                    value={data.node.typeCal}
                                                                                    onChange={this.handleInputChange}
                                                                                    data-id={index}
                                                                                    data-key="paymentGatewaySettingList"
                                                                                    className="form-control dropdown-custom-arrow"
                                                                                    disabled={!accounting_gateway}
                                                                                >
                                                                                <option value="PERCENT">อัตราร้อยละ / รายการ</option>
                                                                                <option value="MONNEY_FAIT">บาท / รายการ</option>
                                                                            </select>
                                                                        </td>
                                                                    }   
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                                {accounting_gateway ?
                                                    <div className='row col-12 mt-48 mb-48 pb-3 d-flex justify-content-end'>
                                                        <button type='submit' className='btn mr-1 submit-btn'>
                                                            {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                            <span>{i18n.t('setuppaymentgateway:Save')}</span>
                                                        </button>
                                
                                                        <button type='button' className='btn submit-outline-btn'>
                                                            {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                            <span>{i18n.t('setuppaymentgateway:Un Save')}</span>
                                                        </button>
                                                    </div >
                                                :null}
                                            </form>
                                        </div>
                                    }
                                    <hr />
                                </div>

                            </div>

                        </div>
                    )}
                </Translation>
            </Wrapper >
        )
    }
}

export default settingPaymentgatewayList;
