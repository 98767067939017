import i18next from 'i18next';
import React from 'react';
import Select, { components } from 'react-select';
import getNameResidential from "./getNameResidential";

const _ = require('lodash');

const empty_value = {value: "", label: ""};

class  SearchSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            options: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.getLabel = this.getLabel.bind(this);
    }

    getLabel(data,bank=false) {
        let label = '';
        let labels = this.props.keyOfLabel.split(':');
        labels.map(function (string) {
            if (string === "accountType") {
                if (_.get(data, "node." + string, "") === "SAVING_ACCOUNT") {
                    label = label + " บัญชีออมทรัพย์";
                } else if (_.get(data, "node." + string, "") === "CURRENT_ACCOUNT") {
                    label = label + " บัญชีกระแสรายวัน";
                } else if(_.get(data, "node." + string, "") === "SAVING_DEPOSIT"){
                    label = label + " บัญชีฝากเผื่อเรียก";
                }
                else {
                    label = label + " บัญชีฝากประจำ";
                }
            } else {
                if (!(bank === 'bank' && string === 'accountName')) {
                    label = label + " " + _.get(data, "node." + string, "");
                }
            }

            return label;
        });
        if (this.props.contact_key) {
            let firstCharacter = label.charAt(1)
            if(firstCharacter ==='C' && !this.props.role){
               label = label
            }
            else if(this.props.role){
                label = label + ` ${_.get(data, "node.name")}`
            }
            else{
                // label = label + " " +getNameResidential(_.get(data, "node.firstName" , ""),_.get(data, "node.lastName" , ""))
                if(this.props.get_only_name){
                    label = label
                }else{
                    label = label + " " +getNameResidential(_.get(data, "node.firstName" , ""),_.get(data, "node.lastName" , ""))
                }
            }
        }

        return label;
    }

    componentDidMount() {
        this.setDropDown()
    }

    setDropDown() {
        let options = [];
        let query = this.props.queryObject;
        let value = this.props.keyOfValue;
        let _this = this;

        if(this.props.optionAll){
            options = [...options,{value: "", label: i18next.t("invoiceReport:All")}]
        }

        if(query){            
            query.forEach((data, index) => {
                options = [...options, {
                    value: data.node[value], 
                    label: `${_this.getLabel(data,data.node.type)} ${this.props.asset? data.node.name: ""}`, 
                    logo: data.node.logo,
                    isUserActivate: 
                    (this.props.showUserActive && data.node.isUserActivate) || 
                    (this.props.showTenantActive && data.node.tenant.householder.edges[0]?.node.active) || "",
                }]
            });
        }
        
        this.setState({options: options}, () => {

            if (this.props.value) {
                this.state.options.forEach((option) => {
                    if (option.value === this.props.value) {
                        this.setState({selected: option});
                    }
                });
            }
            else if(this.props.optionAll){
                 this.setState({selected: {value: "", label: i18next.t("invoiceReport:All")}});
            }
            else if(this.props.placeholder_default){
                 this.setState({selected: ""});
            }

        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.queryObject !== this.props.queryObject) {
            this.setDropDown()
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

        if (nextProps.value !== this.props.value) {
            let found = false;
            this.state.options.forEach((option) => {
                if (option.value === nextProps.value) {
                    found = true;
                    this.setState({selected: {...option}});
                }
            });

            if (!found) {
                this.setState({selected: {...empty_value}})
            }
        }
    }

    handleChange(changed_value) {
    
        let value = changed_value || {value: ""};
        let dataObject = _.find(this.props.queryObject , e=> e.node?.id === value?.value)
        
        let event = {
            target: {
                name: this.props.name,
                value: value.value,
                className: this.props.className,
                dataset: {id: this.props['data-id'], key: this.props['data-key']},
                role: value.label.charAt(1) === "H" ? true : false,
                nameObject: dataObject
            }
        };
        this.setState({selected: value});
        this.props.onChange(event);
    }

    

    render() {

        const { Option } = components
        const CustomSelectOption = props => (
        <Option {...props}>
            {props.data?.logo&&<img src={props.data?.logo}  style={{width: 40, height: 40}}/>}
            {props.data.label}
        </Option>
        )

        const CustomSelectValue = props => (
            <div>
              {props.data?.logo&&<img src={props.data?.logo} style={{width: 20, height: 20}}/>}
              {props.data.label}
            </div>
        )
        
        // เพิ่ม dot สำหรับ resisent Active
        const CustomSelectOptionForShowUserActive = props => (
        <Option {...props}>                        
            {props.data?.logo&&<img src={props.data?.logo}  style={{width: 40, height: 40}}/>}            
            {props.data.label}                        
            {props.data.isUserActivate ? <div className="green-dot" />:<div className="grey-dot" />}
        </Option>

            )            
            
        // เพิ่ม dot สำหรับ tenant active
        const CustomSelectOptionForShowTenantActive = props => (
            <Option {...props}>                            
                {props.data?.logo&&<img src={props.data?.logo}  style={{width: 40, height: 40}}/>}            
                {props.data.label}                        
                {props.data.isUserActivate ? <div className="green-dot" />:<div className="grey-dot" />}
            </Option>

        )
        
        // เพิ่ม dot สำหรับ resisent Active
        const CustomSelectValueForShowUserActive = props => (
            <div>
              {props.data?.logo&&<img src={props.data?.logo} style={{width: 20, height: 20}}/>}
              {props.data.label}                                          
              {props.data.label && (props.data.isUserActivate ? <div className="green-dot" />:<div className="grey-dot" />)}
            </div>
        )
        
        // เพิ่ม dot สำหรับ tenant active
        const CustomSelectValueForShowTenantActive = props => (
            <div>
              {props.data?.logo&&<img src={props.data?.logo} style={{width: 20, height: 20}}/>}
              {props.data.label}                                          
              {props.data.label && (props.data.isUserActivate ? <div className="green-dot" />:<div className="grey-dot" />)}
            </div>
        )

        return (
            this.props.logo ? 
            <div style={{position: 'relative'}}>
                <Select
                    value={this.state.selected}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                    options={this.state.options}
                    isDisabled={this.props.disabled}
                    isClearable={this.props.isClearable}
                    components = {{Option : CustomSelectOption, SingleValue : CustomSelectValue}}        
                />
                <input name="req" type="text" value={this.props.value || ""}
                       style={{position: "absolute", top: 5, left: 5, width: 5, zIndex: -1}}
                       onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                       required={this.props.require}/>
            </div>                 
                :
            // เพิ่ม dot สำหรับ userActive
            this.props.showUserActive ?
                <div style={{position: 'relative'}}>
                    <Select
                        value={this.state.selected}
                        placeholder={this.props.placeholder}
                        onChange={this.handleChange}
                        options={this.state.options}
                        isDisabled={this.props.disabled}
                        isClearable={this.props.isClearable}
                        components = {{Option : CustomSelectOptionForShowUserActive, SingleValue : CustomSelectValueForShowUserActive}}        
                    />
                    <input name="req" type="text" value={this.props.value || ""}
                        style={{position: "absolute", top: 5, left: 5, width: 5, zIndex: -1}}
                        onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                        required={this.props.require}/>
                </div> 
                    :
                this.props.showTenantActive ?
                <div style={{position: 'relative'}}>
                    <Select
                        value={this.state.selected}
                        placeholder={this.props.placeholder}
                        onChange={this.handleChange}
                        options={this.state.options}
                        isDisabled={this.props.disabled}
                        isClearable={this.props.isClearable}
                        components = {{Option : CustomSelectOptionForShowTenantActive, SingleValue : CustomSelectValueForShowTenantActive}}        
                    />
                    <input name="req" type="text" value={this.props.value || ""}
                        style={{position: "absolute", top: 5, left: 5, width: 5, zIndex: -1}}
                        onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                        required={this.props.require}/>
                </div> 
                    :
                    <div style={{position: 'relative', whiteSpace: this.props.pre_wrap ? 'pre-wrap' : ''}}>
                        <Select
                            value={this.state.selected}
                            placeholder={this.props.placeholder}
                            onChange={this.handleChange}
                            options={this.state.options}
                            isDisabled={this.props.disabled}
                            isClearable={this.props.isClearable}
                            styles={this.props.style}
                        />
                        <input name="req" type="text" value={this.props.value || ""}
                            style={{position: "absolute", top: 5, left: 5, width: 5, zIndex: -1}}
                            onChange={ 
                                () => {
                                    this.inputTitle.value = ""
                                }} 
                            ref={el =>
                                    this.inputTitle = el
                            }
                            required={this.props.require}/>
                    </div>
        )
    }
}

export default SearchSelect;

