import { format } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Translation } from "react-i18next";

const ImportFileCheckPreviewDataVehicel = (data) => {
    const [dataExcel, SetDataExcel] = useState([])

    useEffect(() => {
        let dataMapNew = _.cloneDeep(dataExcel)
        let see = data.excelData?.map((ex, indeexcelDatax) => {
            let datamapData = {
                'แถวที่': "",
                'ลำดับ': "",
                "เลขบ้านเลขที่/ห้องเลขที่ที่ห้อง": "",
                "ประเภท": "",
                "เลขทะเบียน": "",
                "จังหวัด": "",
                "ยี่ห้อ": "",
                "รุ่น": "",
                "ปี": "",
                "ขนาด (ที่นั่ง)": "",
                "ลักษณะรถ": "",
                "เลขสติ๊กเกอร์จอดรถ": "",
                "ชั้นจอด": "",
                "ช่องที่จอด": "",
                "วันที่เริ่มใช้งาน": "",
                "คิดค่าที่จอดรถ": ""
            }

            Object.keys(ex).map((itemExcel, index) => {
                datamapData[itemExcel] = ex[itemExcel].trim()
            })

            dataMapNew.push(datamapData)
            return SetDataExcel(dataMapNew)
        })
    }, [])


    return (
        <Translation>
            {t =>
                <div className="colorUse">
                    <p>
                        <button className="bg_E14558 w18 h18"></button> <label className="mt-0 pt-3">{t("importPreviewVehicle:Mandatory field and must enter accurate information")}</label>
                    </p>
                    <table className="table colorUse" style={{ zoom: "70%" }}>
                        <thead>
                            <tr className="thead-light text-center" style={{ verticalAlign: 'middle' }}>
                                <th>{t("importPreviewVehicle:Row No.")}</th>
                                <th>{t("importPreviewVehicle:No")}</th>
                                <th>{t("importPreviewVehicle:Home No/Room No")}<span className="text-red">*</span></th>
                                <th>{t("importPreviewVehicle:Type")}<span className="text-red">*</span></th>
                                <th>{t("importPreviewVehicle:Registration No")}<span className="text-red">*</span></th>
                                <th>{t("importPreviewVehicle:Province")}<span className="text-red">*</span></th>
                                <th>{t("importPreviewVehicle:Brand")}<span className="text-red">*</span></th>
                                <th>{t("importPreviewVehicle:Model")}<span className="text-red">*</span></th>
                                <th>{t("importPreviewVehicle:Year(s)")}</th>
                                <th>{t("importPreviewVehicle:Size (Seats)")}</th>
                                <th>{t("importPreviewVehicle:Car feature")}</th>
                                <th>{t("importPreviewVehicle:Parking lot sticker")}</th>
                                <th>{t("importPreviewVehicle:Floor")}</th>
                                <th>{t("importPreviewVehicle:Space")}</th>
                                <th>{t("importPreviewVehicle:Start Date")}<span className="text-red">*</span> <br /> YYYY-MM-DD {t("Allaction:only")}</th>
                                <th>{t("importPreviewVehicle:Charge for parking")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataExcel.map((ViewData, indexDataExcel) => {
                                    const dateObject = moment(ViewData["วันที่เริ่มใช้งาน"], "YYYY-MM-DD", true);
                                    return (
                                        <tr key={"vehiclePreviewBeforeImport" + indexDataExcel} className="text-center">
                                            <td className="">{indexDataExcel + 2}</td>
                                            <td className="">{ViewData["ลำดับ"]}</td>
                                            <td className={!ViewData["เลขบ้านเลขที่/ห้องเลขที่ที่ห้อง"] && "bg_E14558"}>{ViewData["เลขบ้านเลขที่/ห้องเลขที่ที่ห้อง"]}</td>
                                            <td className={!ViewData["ประเภท"] && "bg_E14558"}>{ViewData["ประเภท"]}</td>
                                            <td className={!ViewData["เลขทะเบียน"] && "bg_E14558"}>{ViewData["เลขทะเบียน"]}</td>
                                            <td className={!ViewData["จังหวัด"] && "bg_E14558"}>{ViewData["จังหวัด"]}</td>
                                            <td className={!ViewData["ยี่ห้อ"] && "bg_E14558"}>{ViewData["ยี่ห้อ"]}</td>
                                            <td className={!ViewData["รุ่น"] && "bg_E14558"}>{ViewData["รุ่น"]}</td>
                                            <td className="">{ViewData["ปี"]}</td>
                                            <td className="">{ViewData["ขนาด (ที่นั่ง)"]}</td>
                                            <td className="">{ViewData["ลักษณะรถ"]}</td>
                                            <td className="">{ViewData["เลขสติ๊กเกอร์จอดรถ"]}</td>
                                            <td className="">{ViewData["ชั้นจอด"]}</td>
                                            <td className="">{ViewData["ช่องที่จอด"]}</td>
                                            <td className={(!ViewData["วันที่เริ่มใช้งาน"] || (!dateObject.isValid() && dateObject.format("YYYY-MM-DD") !== ViewData["วันที่เริ่มใช้งาน"])) && "bg_E14558"}>
                                                {ViewData["วันที่เริ่มใช้งาน"]}
                                            </td>
                                            <td className="">{ViewData["คิดค่าที่จอดรถ"]}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </Translation>

    )
}

export default ImportFileCheckPreviewDataVehicel;