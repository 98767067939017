/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdvancePettyCashChannelStatusSetPettyCash = "VOID" | "WAIT" | "WITHDRAW" | "%future added value";
export type AdvanceStatus = "CLEAR" | "VOID" | "WITHDRAW" | "%future added value";
export type pettyCashRecordListTableAdvanceQueryVariables = {|
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  first?: ?number,
  last?: ?number,
  setPettyCash_Id?: ?string,
|};
export type pettyCashRecordListTableAdvanceQueryResponse = {|
  +allAdvancePettyCashChannel: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +advance: ?{|
          +id: string,
          +docNumber: string,
          +issuedDate: any,
          +dueDate: ?any,
          +withdrawer: string,
          +description: string,
          +amount: number,
          +status: AdvanceStatus,
          +voidRemark: ?string,
          +chartOfAccount: ?{|
            +id: string,
            +name: string,
            +chartOfAccountCode: string,
          |},
        |},
        +price: number,
        +statusSetPettyCash: ?AdvancePettyCashChannelStatusSetPettyCash,
        +date: ?any,
        +setPettyCash: ?{|
          +docNumber: string,
          +description: ?string,
          +withdrawer: ?string,
        |},
      |}
    |}>,
  |}
|};
export type pettyCashRecordListTableAdvanceQuery = {|
  variables: pettyCashRecordListTableAdvanceQueryVariables,
  response: pettyCashRecordListTableAdvanceQueryResponse,
|};
*/


/*
query pettyCashRecordListTableAdvanceQuery(
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $first: Int
  $last: Int
  $setPettyCash_Id: ID
) {
  allAdvancePettyCashChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id) {
    totalCount
    edges {
      node {
        id
        advance {
          id
          docNumber
          issuedDate
          dueDate
          withdrawer
          description
          amount
          status
          voidRemark
          chartOfAccount {
            id
            name
            chartOfAccountCode
          }
        }
        price
        statusSetPettyCash
        date
        setPettyCash {
          docNumber
          description
          withdrawer
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "setPettyCash_Id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "setPettyCash_Id",
    "variableName": "setPettyCash_Id"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "withdrawer",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "AdvanceNode",
  "kind": "LinkedField",
  "name": "advance",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    (v10/*: any*/),
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voidRemark",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNode",
      "kind": "LinkedField",
      "name": "chartOfAccount",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "chartOfAccountCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusSetPettyCash",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "pettyCashRecordListTableAdvanceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AdvancePettyCashChannelNodeConnection",
        "kind": "LinkedField",
        "name": "allAdvancePettyCashChannel",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvancePettyCashChannelNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvancePettyCashChannelNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SetPettyCashNode",
                    "kind": "LinkedField",
                    "name": "setPettyCash",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v11/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "pettyCashRecordListTableAdvanceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AdvancePettyCashChannelNodeConnection",
        "kind": "LinkedField",
        "name": "allAdvancePettyCashChannel",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvancePettyCashChannelNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvancePettyCashChannelNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SetPettyCashNode",
                    "kind": "LinkedField",
                    "name": "setPettyCash",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v11/*: any*/),
                      (v10/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e76ca013f7d0ef69a4e1973487d0b5af",
    "id": null,
    "metadata": {},
    "name": "pettyCashRecordListTableAdvanceQuery",
    "operationKind": "query",
    "text": "query pettyCashRecordListTableAdvanceQuery(\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $first: Int\n  $last: Int\n  $setPettyCash_Id: ID\n) {\n  allAdvancePettyCashChannel(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last, setPettyCash_Id: $setPettyCash_Id) {\n    totalCount\n    edges {\n      node {\n        id\n        advance {\n          id\n          docNumber\n          issuedDate\n          dueDate\n          withdrawer\n          description\n          amount\n          status\n          voidRemark\n          chartOfAccount {\n            id\n            name\n            chartOfAccountCode\n          }\n        }\n        price\n        statusSetPettyCash\n        date\n        setPettyCash {\n          docNumber\n          description\n          withdrawer\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fe512fc2cc3f95d9702e5ce3b0b6dd07';

module.exports = node;
