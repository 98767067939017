import React, { Component } from 'react';
import { Modal} from 'react-bootstrap';
import SilvermanAgentStep from './silvermanAgentStep'
import InputAddress from "../libs/autoCompleteAddressSvmAgent";
import './silvermanAgent.scss'

 
 const typeProperty = [
     {
        value:'condo',
        label:'คอนโดมิเนียม',
        labelEn:'Condo'
     },
     {
        value:'house',
        label:'บ้านเดี่ยว',
        labelEn:'House'
     },
     {
        value:'townhouse',
        label:'ทาวน์เฮ้าส์',
        labelEn:'Townhouse'
     },
     {
        value:'villa',
        label:'วิลล่า',
        labelEn:'Villa'
     },
     {
        value:'commercial',
        label:'อาคารพาณิชย์',
        labelEn:'Commercial'
     },
     {
        value:'other',
        label:'อื่นๆ',
        labelEn:'Other'
     },
    //  {
    //     value:'land',label:'ที่ดิน'
    //  },
    //  {
    //     value:'office_home',label:'โฮมออฟฟิศ'
    //  },
    //  {
    //     value:'office',label:'สำนักงาน'
    //  },
    //  {
    //     value:'store',label:'พื้นที่ขายของ'
    //  },
    //  {
    //     value:'hotel',label:'กิจการโรงแรม'
    //  },
    //  {
    //     value:'warehouse',label:'โกดัง'
    //  },
 ]

 class silvermanAgentLocation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            propertyType:'',
            propertyTypeLable:'',
            propertyTypeLableEn:'',
            showPropertyType:true,
            // autoCheckData:false
        }
    }

    handelItem(value,label,labelEn){
        this.setState({
            propertyType:value,
            propertyTypeLable:label,
            propertyTypeLableEn:labelEn,
            showPropertyType:false,
            visible:false
        })
        this.props.onSetPropertyType(value) 
    }


    render() {

        return (
            <>
                 <div id='svm-agent-status'>
                    <div style={{backgroundColor:'#fff'}} className='p-4'>
                          <SilvermanAgentStep svmAgentStepFinish={this.props.svmAgentStepFinish} lang={this.props.lang} />
                            <Modal show={this.state.visible} className="modal-property">
                                <div className='row'>
                                    <div className='col'> <h4 className='p-4'>{this.props.lang === 'en' ? 'Select your property type' : 'ประเภทอสังหาริมทรัพย์' }</h4></div>
                                    <div className='col-3'> <img className='p-4' src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/close.png`} 
                                    onClick={() => this.setState({visible:false})}
                                    /> </div>
                                </div>
                            
                                <div className='box-property p-4 text-center'>
                                {
                                    typeProperty.map((item,index) => {
                                        return(
                                            <>
                                            <div>
                                                <div 
                                                className={item.value ===  this.state.propertyType ? 'svm-agent-select-active' :'svm-agent-select ' }
                                                onClick={() => this.handelItem(item.value,item.label,item.labelEn)} >
                                                    <img src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/propertyIcon/${item.value}.png`} className="pt-3 pb-3"/>   
                                                </div>
                                                <p className='pt-2'>
                                                {this.props.lang === 'en' ? item.labelEn : item.label }
                                                    </p>
                                            </div>
                                            </>
                                        )
                                    })
                                }
                                </div>
                            </Modal>

                        <div>
                            <h5> {this.props.lang === 'en' ? 'Announcement type (Choose more than 1)': 'ประเภทประกาศ (เลือกได้มากกว่า 1 ตัวเลือก)'}<span className='text-danger'>*</span></h5>
                            <div className='box-property-typesale'>  

                                {
                                    this.props.userType === 'owner' ? 
                                    <>
                                    <div className='ml-3 pb-2'>
                                        <input type="checkbox" id="announceForRent" name="announceForRent" className='myCheckBox' 
                                            onChange = {() => this.props.handleInputChangeCheckBox('announceForRent',!this.props.announceForRent)}
                                            /> 
                                        <span className='pl-2'>{this.props.lang === 'en' ? 'Rent': 'เช่า'}</span>
                                    </div> 
                                    <div className='ml-3 pb-2'>
                                        <input type="checkbox" id="announceSell" name="announceSell" className='myCheckBox' 
                                        onChange = {() => this.props.handleInputChangeCheckBox('announceSell',!this.props.announceSell)}
                                        /> 
                                        <span className='pl-2'>{this.props.lang === 'en' ? 'Sell': 'ขาย'}</span>
                                    </div>
                                    </>
                                    :''
                                }
                                {
                                    this.props.userType === 'tenant' || this.props.userType === 'buyer' ?
                                    <>
                                     <div className='ml-3 pb-2'>
                                        <input type="checkbox" id="announceLookRent" name="announceLookRent" className='myCheckBox'
                                        onChange = {() => this.props.handleInputChangeCheckBox('announceLookRent',!this.props.announceLookRent)}
                                        /> 
                                        <span className='pl-2'>{this.props.lang === 'en' ? 'Rent': 'เช่า'}</span>
                                     </div>
                                      <div className='ml-3 pb-2'>
                                         <input type="checkbox" id="announceBuy" name="announceBuy" className='myCheckBox'
                                            onChange = {() => this.props.handleInputChangeCheckBox('announceBuy',!this.props.announceBuy)}
                                            /> 
                                        <span className='pl-2'>{this.props.lang === 'en' ? 'Buy': 'ซื้อ'}</span>
                                      </div>
                                     
                                     </>
                                    :''
                                }
                                    
                         
                               {/*  <div className='ml-3 pb-2'>
                                     <input type="checkbox" id="announceBuy" name="announceBuy" className='myCheckBox'
                                      onChange = {() => this.props.handleInputChangeCheckBox('announceBuy',!this.props.announceBuy)}
                                      /> 
                                     <span className='pl-2'>เช่า/ขาย</span>
                               </div>
                              <div className='ml-3 pb-2'>
                                     <input type="checkbox" id="announceDown" name="announceDown" className='myCheckBox'
                                      onChange = {() => this.props.handleInputChangeCheckBox('announceDown',!this.props.announceDown)}
                                      /> 
                                     <span className='pl-2'>ขายดาวน์</span>
                               </div>
                              
                               <div className='ml-3 pb-2'>
                                     <input type="checkbox" id="announceLease" name="announceLease" className='myCheckBox' 
                                      onChange = {() => this.props.handleInputChangeCheckBox('announceLease',!this.props.announceLease)}
                                      /> 
                                     <span className='pl-2'>เซ้ง</span>
                               </div>
                               <div className='ml-3 pb-2'>
                                     <input type="checkbox" id="announceBuy" name="announceBuy" className='myCheckBox'
                                      onChange = {() => this.props.handleInputChangeCheckBox('announceBuy',!this.props.announceBuy)}
                                      /> 
                                     <span className='pl-2'>ซื้อ</span>
                               </div>
                               <div className='ml-3 pb-2'>
                                     <input type="checkbox" id="announceLookRent" name="announceLookRent" className='myCheckBox'
                                      onChange = {() => this.props.handleInputChangeCheckBox('announceLookRent',!this.props.announceLookRent)}
                                      /> 
                                     <span className='pl-2'>หาเช่า</span>
                               </div> */}
                            </div>
                            {
                                    this.props.announceAlert ? <span className='text-red'>{this.props.lang === 'en' ? 'Announcement type':'กรุณาเลือกประเภทประกาศ'}</span> :''
                            }
                        </div>
                        <div className='pb-4'>
                            <h5>{this.props.lang === 'en' ? 'Property type':'ประเภทอสังหาริมทรัพย์'} <span className='text-danger'>*</span></h5>
                            {
                                this.state.showPropertyType ? 
                                // <div className={`${this.state.propertyType ? 'btn-select-property' : 'btn-select-property-alert'}`}   onClick={ () => this.setState({visible : true})} >
                                <div className={`btn-select-property`}  id="btn-select-property" onClick={ () => this.setState({visible : true})} > 
                                    {this.props.lang === 'en' ? 'Select your property type':'เลือกประเภทอสังหาริมทรัพย์'}
                                </div> :
                                 <div className='btn-select-property' onClick={ () => this.setState({visible : true})}>
                                    <img src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/propertyIcon/${this.state.propertyType}.png`} style={{width:'18px'}}
                                    /> 
                                     {this.props.lang === 'en' ? this.state.propertyTypeLableEn :this.state.propertyTypeLable}
                                </div>
                            }
                            {
                                this.state.propertyType === 'other' ? 
                                <>
                                <div className='pt-2'>
                                    <span>{this.props.lang === 'en' ? 'Other':'อื่นๆ'}</span>
                                    <input type='text' name='residentialOther' className='form-control' onChange={this.props.handleInputChange}/>
                                </div>
                                </>
                                :''
                            }
                            {
                                this.props.showAlertResidentialType ?
                                <span className='text-red'> {this.props.lang === 'en' ? 'Please Select your property type':'กรุณาเลือกประเภทอสังหาริมทรัพย์'}</span> :''
                            }
                           
                        </div>
                    </div>
                    <div style={{backgroundColor:'#fff'}} className='p-4 mt-4'>
                        <h5>{this.props.lang === 'en' ? 'Location':'ทำเล/ตำแหน่ง'} <span className='text-danger'>*</span></h5>
                        <input type="checkbox" name="autoCheckData" className='ml-3 autoCheckData' value={this.props.autoCheckData}
                        onChange = {() => this.props.onSetAutoCheckData()}
                        />        {this.props.lang === 'en' ? 'Auto fill':'กรอกฟอร์มโดยอัตโนมัติจากข้อมูลบัญชี'}
                   
                        <div>
                           
                            <div className='pt-2'>
                                <span>{this.props.lang === 'en' ? 'District':'อำเภอ / เขต'} </span>
                                {
                                    this.props.autoCheckData ? <div><input type="text" className="form-control" value={this.props.districtUser} disabled/></div> 
                                    :
                                    <InputAddress
                                        address="district"
                                        value={this.props.district}
                                        onChange={this.props.handleInputChangeReceive}
                                        onSelect={this.props.onSelect}
                                        className="form-control"
                                    />
                                }
                                {
                                    !this.props.autoCheckData && this.props.showLengthAlert ?
                                     <span className='text-red'>{this.props.lang === 'en' ? 'Please Select District':'กรุณากรอกชื่ออำเภอให้ครบ'}</span> :''
                                }
                            </div>
                            <div className='pt-4'>
                                <span>{this.props.lang === 'en' ? 'Province':'จังหวัด'} </span>
                                {
                                    this.props.autoCheckData ? <div><input type="text" className="form-control" value={this.props.provinceUser} disabled/></div> 
                                    :
                                    <InputAddress
                                        address="province"
                                        value={this.props.province}
                                        onChange={this.props.handleInputChangeReceive}
                                        onSelect={this.props.onSelect}
                                        className="form-control"
                                        />
                                }
                                {
                                   !this.props.autoCheckData && this.props.showLengthProvincAlert ? <span className='text-red'>
                                    {this.props.lang === 'en' ? 'Please Select Province':'กรุณากรอกชื่อจังหวัดให้ครบ'}</span> :''
                                }
                            </div>
                            <div className='pt-4'>
                            <span> {this.props.lang === 'en' ? 'Area (Near BTS,MRT)':'บริเวณ (เช่นใกล้ BTS, MRT)'}</span>
                            <textarea type="text" className="form-control" row="5" placeholder={this.props.lang === 'en' ? 'Area (Near BTS,MRT)':'บริเวณ'} name='area'  onChange={this.props.handleInputChange} />
                            </div>
                            <div className='pt-8'>
                                <h5>{this.props.lang === 'en' ? 'Project name':'ชื่อโครงการ'}<span className='text-danger'>*</span></h5>
                                <span className='mt-4'>{this.props.lang === 'en' ? 'Project name':'ชื่อโครงการ'}</span>
                                { this.props.autoCheckData ? <div><input type="text" className="form-control" value={this.props.projectNameUser} disabled/></div> 
                                    : <input type="text" className="form-control" id="projectName" name='projectName' placeholder={this.props.lang === 'en' ? 'Project name':'ชื่อโครงการ'}
                                    onChange={this.props.handleInputChange} required/>
                                }
                                {
                                   !this.props.autoCheckData && this.props.showLengthProjectNameAlert ? 
                                   <span className='text-red'>{this.props.lang === 'en' ? 'Please Fill Project name':'กรุณากรอกชื่อโครงการให้ครบ'}</span> :''
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className='footer-svm-agent'>
                            <div className='pt-6 pb-6'>
                                <button  onClick={ () => this.props.onSetStepFinish(true)} className="btn btn-primary btn-svm-agent">
                                         {this.props.lang === 'en' ? 'Next':'ถัดไป'}
                                </button>
                            </div>
                    </div>          
                </div>
            </>
        )
    }

 }

 export default silvermanAgentLocation;