import "./styles/styles.scss";
import React from 'react';
import Wrapper from "../../components/wrapper";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import { setDate, setMonth, lastDayOfMonth } from "date-fns";
import BalanceSheetAccountHeaderStatements from "./balanceSheetAccountHeaderStatements";
import BalanceSheetAccountAssetsStatements from "./balanceSheetAccountAssetsStatements";
import ExportBalanceSheetAccountStatements from "./export";
import { formatDateISO } from "../../utils";
import { listDataFilter } from "./dataFilter"
import Loading from "../../libs/loading";
import BackButtonIcon from "../../components/BackBtn/indexBack";
import i18next from "i18next";

const query = graphql`
    query balanceSheetAccountStatementsQuery($codeList: [String],$startDateFirst: DateTime, $endDateFirst: DateTime, $startDateSecond: DateTime, $endDateSecond: DateTime) {
        allAccountProjectManager{
            edges{
              node{
                id
                tableAccount
              }
            }
        }

        chartOfAccountBalanceSheetAccount(codeList: $codeList ,startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond){
            edges{
                node{
                    id
                    name
                    chartOfAccountCode
                    col1Debit
                    col1Credit
                    col2Debit
                    col2Credit
                    totalHistoricalDebit
                    totalHistoricalCredit
                }
            }
        }

        Income :chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "4", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
            edges {
                node {
                    chartOfAccountCode
                    name
                    yearFirst
                    yearSecond
                    totalHistoricalDiffFirst
                    totalHistoricalDiffSecond
                }
            }
        }

        Expense :chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "5", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
            edges {
                node {
                    chartOfAccountCode
                    name
                    yearFirst
                    yearSecond
                    totalHistoricalDiffFirst
                    totalHistoricalDiffSecond
                }
            }
        }
        
    }`;

class balanceSheetAccountStatements extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        // let before_date = setMonth(new Date(), new Date().getMonth() - 1);
        this.state = {
            start_date_first: new Date(),
            end_date_first: new Date(),

            start_date_second: new Date(),
            end_date_second: new Date(),

            listDataFilter: listDataFilter(),

            input_statements_start: "1",
            input_statements_end: "1",

            tableAccount_1: null,
            tableAccount_2: null,

            list_objects: {
                '1. เงินสดและเงินฝากสถาบันการเงิน / Cash & deposits with financial institutions': [],
                '2. ลูกหนี้-เจ้าของร่วม / Account Receivable': [],
                '3. สินทรัพย์หมุนเวียนอื่นๆ / Other Current Assets': [],
                '4. ที่ดิน อาคาร และอุปกรณ์ / Property, plant and equipment': [],
                '5. สินทรัพย์ไม่หมุนเวียนอื่น / Other Non Current Assets': [],
                '6. เจ้าหนี้และเช็คจ่ายรอตัดบัญชี / Cheque in Transit': [],
                '7. ค่าใช้จ่ายค้างจ่าย / Account Payable': [],
                '8. เงินรับรอการตรวจสอบ / Others collection': [],
                '9. รายรับรอตัดบัญชี / Deferred revenue': [],
                '10. เงินค้ำประกัน / Deposit': [],
                '11. รายได้รับล่วงหน้า / Advance Revenue': [],
                '12. หนี้สินหมุนเวียนอื่น / Other Current Liabilities': [],
                '13. หนี้สินไม่หมุนเวียนอื่น / Other Non Current Liabilities': [],
                'รายรับค่าส่วนกลาง / Revenue - Common area fees': [],
                'รายรับค่าน้ำประปา / Revenue - Waer supply': [],
                'รายรับค่าใช้ประโยชน์พื้นที่ส่วนกลาง / Revenue Common area utilization': [],
                'รายรับอื่นๆ / Other Revenue': [],
                'ต้นทุนขายสุทธิ / Cost of Goods Sold': [],
                'เงินเดือนเเละค่าจ้าง / Salaries and wages': [],
                'รายจ่าย - ตามสัญญา / Expense - by agreement': [],
                'รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office': [],
                'รายจ่ายสาธารณูปโภค / Operation expense - Utility': [],
                'รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair': [],
                'รายจ่ายค่าเสื่อมราคาและค่าตัดจำหน่าย / Depreciation and Amortization expense': [],
                'ค่าใช้จ่ายอื่น ๆ / Other expenses': [],
            },

            selected_date: localStorage.getItem('balance_end_date_first') ?
                new Date(localStorage.getItem('balance_end_date_first'))
                : new Date(),
            // selected_date: new Date(),
            period: (this.props.match.params.note && this.props.match.params.period) ? this.props.match.params.period : 'month',
            dateSet: [
                new Date(now.getFullYear(), now.getMonth()),
                new Date(now.getFullYear(), now.getMonth() + 1),
            ],
            balanceSheetAccountStatementsList: {
                '1. เงินสดและเงินฝากสถาบันการเงิน / Cash & deposits with financial institutions': [],
                '2. ลูกหนี้-เจ้าของร่วม / Account Receivable': [],
                '3. สินทรัพย์หมุนเวียนอื่นๆ / Other Current Assets': [],
                '4. ที่ดิน อาคาร และอุปกรณ์ / Property, plant and equipment': [],
                '5. สินทรัพย์ไม่หมุนเวียนอื่น / Other Non Current Assets': [],
                '6. เจ้าหนี้และเช็คจ่ายรอตัดบัญชี / Cheque in Transit': [],
                '7. ค่าใช้จ่ายค้างจ่าย / Account Payable': [],
                '8. เงินรับรอการตรวจสอบ / Others collection': [],
                '9. รายรับรอตัดบัญชี / Deferred revenue': [],
                '10. เงินค้ำประกัน / Deposit': [],
                '11. รายได้รับล่วงหน้า / Advance Revenue': [],
                '12. หนี้สินหมุนเวียนอื่น / Other Current Liabilities': [],
                '13. หนี้สินไม่หมุนเวียนอื่น / Other Non Current Liabilities': [],
                'รายรับค่าส่วนกลาง / Revenue - Common area fees': [],
                'รายรับค่าสาธารณูปโภค / Operation revenue - Utility': [],
                'รายรับค่าใช้ประโยชน์พื้นที่ส่วนกลาง / Revenue Common area utilization': [],
                'รายรับอื่นๆ / Other Revenue': [],
                'ต้นทุนขายสุทธิ / Cost of Goods Sold': [],
                'เงินเดือนเเละค่าจ้าง / Salaries and wages': [],
                'รายจ่าย - ตามสัญญา / Expense - by agreement': [],
                'รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office': [],
                'รายจ่ายสาธารณูปโภค / Operation expense - Utility': [],
                'รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair': [],
                'รายจ่ายค่าเสื่อมราคาและค่าตัดจำหน่าย / Depreciation and Amortization expense': [],
                'ค่าใช้จ่ายอื่น ๆ / Other expenses': [],
            },
            loading: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.calDate = this.calDate.bind(this);
        this.queryData = this.queryData.bind(this);

    }


    componentDidMount() {
        this.calDate();
        this.queryData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.period !== prevState.period) || (this.state.selected_date !== prevState.selected_date) || this.state.input_statements_start !== prevState.input_statements_start || this.state.input_statements_end !== prevState.input_statements_end) {
            // this.calDate();
            // this.queryData();
        }
    }

    queryData() {
        this.setState({
            loading: true,
        })
        let codeList = [];
        // let statements = false
        if (this.props.match.params.note) {
            // statements = true
            if (this.props.match.params.note === '4100-01') {
                codeList.push("4100-01")
            } else if (this.props.match.params.note === '4100-04') {
                codeList.push("4100-04")
            } else if (this.props.match.params.note === '4200') {
                codeList.push("4200")
            } else if (this.props.match.params.note === 'other_income') {
                codeList.push("other_income")
            }
            else if (this.props.match.params.note === 'other_expense') {
                codeList.push("other_expense")
            }
            else if (this.props.match.params.note.search("-") > 0) {
                this.props.match.params.note.split('-').forEach(e => {
                    codeList.push(e)
                });
            } else {
                codeList.push(this.props.match.params.note)
            }
        } else {
            this.state.listDataFilter.forEach((e) => {
                if (parseInt(this.state.input_statements_start) <= parseInt(e.node.number) && parseInt(e.node.number) <= parseInt(this.state.input_statements_end)) {

                    if (e.node.value === '4100-01') {
                        codeList.push("4100-01")
                    } else if (e.node.value === '4100-04') {
                        codeList.push("4100-04")
                    } else if (e.node.value === '4200') {
                        codeList.push("4200")
                    } else if (e.node.value === 'other_income') {
                        codeList.push("other_income")
                    }
                    else if (e.node.value === 'other_expense') {
                        codeList.push("other_expense")
                    }
                    else if (e.node.value.search("-") > 0) {
                        e.node.value.split('-').forEach(e => {
                            codeList.push(e)
                        });
                    } else {
                        codeList.push(e.node.value)
                    }

                }
            })
        }
        let now = new Date();
        this.setState({
            dateSet: [
                new Date(now.getFullYear(), now.getMonth()),
                new Date(now.getFullYear(), now.getMonth() + 1),
            ],
        }, () => {
            fetchQuery(environment, query, {
                startDateFirst: this.state.start_date_first,
                endDateFirst: this.state.end_date_first,
                startDateSecond: this.state.start_date_second,
                endDateSecond: this.state.end_date_second,
                codeList: codeList,
                // period: this.state.period,
            }).then((data) => {
                const tableAccount = data.allAccountProjectManager.edges[0]?.node.tableAccount;
                const end_date_first = formatDateISO(this.state.end_date_first);
                const end_date_second = formatDateISO(this.state.end_date_second);
                const tableAccount_1 = tableAccount <= end_date_first ? true : false
                const tableAccount_2 = tableAccount <= end_date_second ? true : false
                let dataSet = data.chartOfAccountBalanceSheetAccount;
                let list_objects = _.cloneDeep(this.state.list_objects)

                // 1-2
                // if (_.startsWith(codeList, "1") || _.startsWith(codeList, "2")) {
                if (_.find(codeList, (e) => e.substring(0, 1) === "1" || e.substring(0, 1) === "2")) {
                    list_objects = this._data_set(dataSet, tableAccount_1, tableAccount_2, codeList, list_objects);
                    // list_objects = this._data_set(dataSet, tableAccount_2, codeList, list_objects);
                }

                // 4
                // if (_.startsWith(codeList, "other_income") || _.startsWith(codeList, "4")) {
                if (_.find(codeList, (e) => e === "other_income" || e.substring(0, 1) === "4")) {
                    // console.log("// 4");
                    list_objects = this._data_set_Income_Expense(data.Income, tableAccount_1, tableAccount_2, codeList, list_objects);
                }
                // 5
                // if (_.startsWith(codeList, "other_expense") || _.startsWith(codeList, "5")) {
                if (_.find(codeList, (e) => e === "other_expense" || e.substring(0, 1) === "5")) {
                    // console.log("// 5");
                    list_objects = this._data_set_Income_Expense(data.Expense, tableAccount_1, tableAccount_2, codeList, list_objects);
                }

                this.setState({
                    tableAccount_1,
                    tableAccount_2,
                    balanceSheetAccountStatementsList: list_objects,
                    loading: false,
                })

            });
        })

    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    calDate() {

        //ตรวจสอบรหัสที่รับเข้ามา นำมาฟิลล์
        if (this.props.match.params.note && (this.props.match.params.period === 'month' || this.props.match.params.period === 'year')) {

            let now = new Date();
            let before_date = setMonth(new Date(), new Date().getMonth() - 1);

            const start_date_first = new Date(localStorage.getItem('balance_start_date_first'))
            const end_date_first = new Date(localStorage.getItem('balance_end_date_first'))
            const start_date_second = new Date(localStorage.getItem('balance_start_date_second'))
            const end_date_second = new Date(localStorage.getItem('balance_end_date_second'))

            this.setState({
                start_date_first: start_date_first,
                end_date_first: end_date_first,
                start_date_second: start_date_second,
                end_date_second: end_date_second,
                // selected_date: new Date(localStorage.getItem('balance_end_date_first')),
                period: this.props.match.params.period,
                dateSet: [
                    new Date(now.getFullYear(), now.getMonth()),
                    new Date(now.getFullYear(), now.getMonth() + 1),
                ],
            })

        } else {
            if (this.state.period === 'year') {

                // let before_date = setYear(this.state.selected_date, this.state.selected_date.getFullYear() - 1);
                // this.setState({
                //     start_date_first: before_date,
                //     end_date_first: this.state.selected_date,

                //     start_date_second: setYear(this.state.selected_date, this.state.selected_date.getFullYear() - 2),
                //     end_date_second: setDate(before_date, getDaysInMonth(before_date)),
                // })
                let start_date_first = new Date();
                let end_date_first = new Date();
                let start_date_second = new Date();
                let end_date_second = new Date();

                let month_minus = 11;

                let firstDay = new Date(this.state.selected_date.getFullYear(), this.state.selected_date.getMonth(), 1);
                start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), firstDay.getDate());
                end_date_first = this.state.selected_date;

                end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                // start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), end_date_second.getDate() + 1);
                start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - month_minus), firstDay.getDate());

                this.setState({
                    start_date_first: start_date_first,
                    end_date_first: end_date_first,
                    start_date_second: start_date_second,
                    end_date_second: end_date_second,
                })

            } else {
                let start_date_first = new Date();
                let end_date_first = new Date();
                let start_date_second = new Date();
                let end_date_second = new Date();
                const month_select = this.state.period

                let month_minus = month_select === 'month' && this.state.selected_date.getDate() !== lastDayOfMonth(this.state.selected_date).getDate() ? 1 : 0;
                if (month_select === 'month3') {
                    month_minus = 2
                }
                else if (month_select === 'month6') {
                    month_minus = 5
                }
                else if (month_select === 'month9') {
                    month_minus = 8
                }

                if (this.state.selected_date.getDate() === lastDayOfMonth(this.state.selected_date).getDate()) {
                    start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), 1);
                    end_date_first = this.state.selected_date;

                    end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                    start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), 1);
                } else {
                    // start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), this.state.selected_date.getDate() + 1);
                    // end_date_first = this.state.selected_date;

                    // end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                    // start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), end_date_second.getDate() + 1);

                    //start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), this.state.selected_date.getDate() + 1);
                    let firstDay = new Date(this.state.selected_date.getFullYear(), this.state.selected_date.getMonth(), 1);
                    let checkFirstDay = month_minus === 1 ? 0 : month_minus
                    start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - checkFirstDay), firstDay.getDate());
                    end_date_first = this.state.selected_date;

                    end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                    // start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), end_date_second.getDate() + 1);
                    start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - checkFirstDay), firstDay.getDate());
                }

                // month
                this.setState({
                    start_date_first: start_date_first,
                    end_date_first: end_date_first,
                    start_date_second: start_date_second,
                    end_date_second: end_date_second,
                })
            }
        }

        this.queryData();

    }

    _data_set(dataSet, tableAccount, tableAccount_2, codeList, list_objects) {

        for (let i = 0; i < dataSet.edges.length; i++) {
            let data = dataSet.edges[i].node;
            let date_dump = {}
            let name_keys = ''

            const total_1 = this._calRecord(data, "beforeSet", tableAccount);
            const total_2 = this._calRecord(data, "afterSet", tableAccount_2);

            date_dump = {
                chartOfAccountCode: data.chartOfAccountCode,
                col1Credit: data.col1Credit,
                col1Debit: data.col1Debit,
                col2Credit: data.col2Credit,
                col2Debit: data.col2Debit,
                id: data.id,
                name: data.name,
                totalHistoricalCredit: data.totalHistoricalCredit,
                totalHistoricalDebit: data.totalHistoricalDebit,
                summary1: total_1,
                summary2: total_2,
            }

            // ref 1
            // = เงินสดและเงินฝากธนาคาร
            if (data.chartOfAccountCode.substring(0, 3) === "111" || data.chartOfAccountCode.substring(0, 3) === "112") {
                name_keys = "1. เงินสดและเงินฝากสถาบันการเงิน / Cash & deposits with financial institutions"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }

                continue;
            }

            // ref 2
            // = ลูกหนี้เจ้าของร่วมและเช็ครับ
            if (data.chartOfAccountCode.substring(0, 4) === "1131") {
                name_keys = "2. ลูกหนี้-เจ้าของร่วม / Account Receivable"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 3
            // = สินทรัพย์หมุนเวียนอื่นๆ / Other Current Assets
            if (data.chartOfAccountCode.substring(0, 4) === "1132"
                || data.chartOfAccountCode.substring(0, 4) === "1140"
                || data.chartOfAccountCode.substring(0, 3) === "115") {

                name_keys = "3. สินทรัพย์หมุนเวียนอื่นๆ / Other Current Assets"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }

                continue;
            }

            // ref 4
            // = สินทรัพย์ไม่หมุนเวียน (สินทรัพย์ถาวร)
            if (data.chartOfAccountCode.substring(0, 4) === "1210" || data.chartOfAccountCode.substring(0, 4) === "1220") {
                name_keys = "4. ที่ดิน อาคาร และอุปกรณ์ / Property, plant and equipment"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }
            // ref 5
            // = สินทรัพย์อื่น ๆ
            if (data.chartOfAccountCode.substring(0, 4) === "1230" || data.chartOfAccountCode.substring(0, 4) === "1240" || data.chartOfAccountCode.substring(0, 4) === "1310") {
                name_keys = "5. สินทรัพย์ไม่หมุนเวียนอื่น / Other Non Current Assets"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 6
            // = เจ้าหนี้และเช็คจ่าย
            if (data.chartOfAccountCode.substring(0, 4) === "2110" || data.chartOfAccountCode.substring(0, 4) === "2111") {
                name_keys = "6. เจ้าหนี้และเช็คจ่ายรอตัดบัญชี / Cheque in Transit"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 7
            if (["2121", "2122", "2123"].includes(data.chartOfAccountCode.substring(0, 4))) {
                name_keys = "7. ค่าใช้จ่ายค้างจ่าย / Account Payable"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 8
            if (data.chartOfAccountCode.substring(0, 4) === "2230") {
                name_keys = "8. เงินรับรอการตรวจสอบ / Others collection"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 9
            if (data.chartOfAccountCode.substring(0, 4) === "2220") {
                name_keys = "9. รายรับรอตัดบัญชี / Deferred revenue"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 10
            if (data.chartOfAccountCode.substring(0, 4) === "2125") {
                name_keys = "10. เงินค้ำประกัน / Deposit"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 11
            if (data.chartOfAccountCode.substring(0, 4) === "2210") {
                name_keys = "11. รายได้รับล่วงหน้า / Advance Revenue"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 12
            if (data.chartOfAccountCode.substring(0, 4) === "2124" || data.chartOfAccountCode.substring(0, 4) === "2126") {
                name_keys = "12. หนี้สินหมุนเวียนอื่น / Other Current Liabilities"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ref 13
            if (data.chartOfAccountCode.substring(0, 4) === "2300" || data.chartOfAccountCode.substring(0, 4) === "2400" || data.chartOfAccountCode.substring(0, 4) === "2410") {
                name_keys = "13. หนี้สินไม่หมุนเวียนอื่น / Other Non Current Liabilities"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

        }
        return list_objects;
    }

    _data_set_Income_Expense(dataSet, tableAccount, tableAccount_2, codeList, list_objects) {

        for (let i = 0; i < dataSet.edges.length; i++) {
            let data = dataSet.edges[i].node;
            let date_dump = {}
            let name_keys = ''
            const total_1 = this._calRecord(data, "beforeSet", tableAccount);
            const total_2 = this._calRecord(data, "afterSet", tableAccount_2);
            date_dump = {
                chartOfAccountCode: data.chartOfAccountCode,
                id: data.id,
                name: data.name,
                summary1: total_1,
                summary2: total_2,
            }

            // รายรับค่าส่วนกลาง / Revenue - Common area fees 
            if (_.find(codeList, (e) => e === "4100-01") && data.chartOfAccountCode.substring(0, 7) === '4100-01') {
                name_keys = "รายรับค่าส่วนกลาง / Revenue - Common area fees"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }
            // รายรับค่าน้ำประปา / Revenue - Waer supply
            if (_.find(codeList, (e) => e === "4100-04") && data.chartOfAccountCode.substring(0, 7) === '4100-04') {
                name_keys = "รายรับค่าน้ำประปา / Revenue - Waer supply"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // รายรับค่าใช้ประโยชน์พื้นที่ส่วนกลาง / Revenue Common area utilization
            if (_.find(codeList, (e) => e === "4200") && (data.chartOfAccountCode.substring(0, 4) === '4200')) {
                name_keys = "รายรับค่าใช้ประโยชน์พื้นที่ส่วนกลาง / Revenue Common area utilization"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // รายรับอื่นๆ / Other Revenue 
            if (_.find(codeList, (e) => e === "other_income") && data.chartOfAccountCode.substring(0, 1) === '4'
                && data.chartOfAccountCode.substring(0, 4) !== '4200' && data.chartOfAccountCode.substring(0, 7) !== '4100-04'
                && data.chartOfAccountCode.substring(0, 7) !== '4100-01') {
                name_keys = "รายรับอื่นๆ / Other Revenue"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // ต้นทุนขายสุทธิ / Cost of Goods Sold
            if (_.find(codeList, (e) => e === "5100") && data.chartOfAccountCode.substring(0, 4) === '5100') {
                name_keys = "ต้นทุนขายสุทธิ / Cost of Goods Sold"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // เงินเดือนเเละค่าจ้าง / Salaries and wages
            if (_.find(codeList, (e) => e === "5200") && data.chartOfAccountCode.substring(0, 4) === '5200') {
                name_keys = "เงินเดือนเเละค่าจ้าง / Salaries and wages"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // รายจ่าย - ตามสัญญา / Expense - by agreement
            if (_.find(codeList, (e) => e === "5300") && data.chartOfAccountCode.substring(0, 4) === '5300') {
                name_keys = "รายจ่าย - ตามสัญญา / Expense - by agreement"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office
            if (_.find(codeList, (e) => e === "5400") && data.chartOfAccountCode.substring(0, 4) === '5400') {
                name_keys = "รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // รายจ่ายสาธารณูปโภค / Operation expense - Utility
            if (_.find(codeList, (e) => e === "5500") && data.chartOfAccountCode.substring(0, 4) === '5500') {
                name_keys = "รายจ่ายสาธารณูปโภค / Operation expense - Utility"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair
            if (_.find(codeList, (e) => e === "5600") && data.chartOfAccountCode.substring(0, 4) === '5600') {
                name_keys = "รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }

            // รายจ่ายค่าเสื่อมราคาและค่าตัดจำหน่าย / Depreciation and Amortization expense
            if (_.find(codeList, (e) => e === "5700") && data.chartOfAccountCode.substring(0, 4) === '5700') {
                name_keys = "รายจ่ายค่าเสื่อมราคาและค่าตัดจำหน่าย / Depreciation and Amortization expense"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }
            // ค่าใช้จ่ายอื่น ๆ / Other expenses
            if (_.find(codeList, (e) => e === "other_expense") && data.chartOfAccountCode.substring(0, 1) === "5" &&
                data.chartOfAccountCode.substring(0, 4) !== '5100' && data.chartOfAccountCode.substring(0, 4) !== '5200' &&
                data.chartOfAccountCode.substring(0, 4) !== '5300' && data.chartOfAccountCode.substring(0, 4) !== '5400' &&
                data.chartOfAccountCode.substring(0, 4) !== '5500' && data.chartOfAccountCode.substring(0, 4) !== '5600' &&
                data.chartOfAccountCode.substring(0, 4) !== '5700') {
                name_keys = "ค่าใช้จ่ายอื่น ๆ / Other expenses"
                if (Object.keys(list_objects).includes(name_keys)) {
                    list_objects[name_keys].push(date_dump)
                } else {
                    list_objects[name_keys] = [];
                    list_objects[name_keys].push(date_dump)
                }
                continue;
            }


        }

        return list_objects;
    }

    _calRecord(object, type, Htc) {
        const typeIndex = type === 'beforeSet' ? 1 : 2;
        let summary_record = 0
        // ref 1
        if (object.chartOfAccountCode.substring(0, 3) === "111" || object.chartOfAccountCode.substring(0, 3) === "112") {
            const totalHistoricalDebit = Htc ? object.totalHistoricalDebit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Debit') + totalHistoricalDebit - _.get(object, 'col' + typeIndex + 'Credit');
        }

        // ref 2
        if (object.chartOfAccountCode.substring(0, 4) === "1131") {
            const totalHistoricalDebit = Htc ? object.totalHistoricalDebit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Debit') + totalHistoricalDebit - _.get(object, 'col' + typeIndex + 'Credit');
        }

        // ref 3
        if (object.chartOfAccountCode.substring(0, 4) === "1132"
            || object.chartOfAccountCode.substring(0, 4) === "1140"
            || object.chartOfAccountCode.substring(0, 3) === "115") {
            const totalHistoricalDebit = Htc ? object.totalHistoricalDebit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Debit') + totalHistoricalDebit - _.get(object, 'col' + typeIndex + 'Credit');
        }

        // ref 4
        if (object.chartOfAccountCode.substring(0, 4) === "1210" || object.chartOfAccountCode.substring(0, 4) === "1220") {
            const totalHistoricalDebit = Htc ? object.totalHistoricalDebit : 0;
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += (_.get(object, 'col' + typeIndex + 'Debit') + totalHistoricalDebit) - (_.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit);
        }

        // ref 5
        if (object.chartOfAccountCode.substring(0, 4) === "1230" || object.chartOfAccountCode.substring(0, 4) === "1240" || object.chartOfAccountCode.substring(0, 4) === "1310") {
            const totalHistoricalDebit = Htc ? object.totalHistoricalDebit : 0;
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            if (object.chartOfAccountCode.substring(0, 4) === "1240") {
                summary_record += (_.get(object, 'col' + typeIndex + 'Debit') + totalHistoricalDebit) - (_.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit);
            }
            else {
                summary_record += _.get(object, 'col' + typeIndex + 'Debit') + totalHistoricalDebit - _.get(object, 'col' + typeIndex + 'Credit');
            }
        }

        // ref 6
        if (object.chartOfAccountCode.substring(0, 4) === "2110" || object.chartOfAccountCode.substring(0, 4) === "2111") {
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(object, 'col' + typeIndex + 'Debit');
        }

        // ref 7
        if (["2121", "2122", "2123"].includes(object.chartOfAccountCode.substring(0, 4))) {
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(object, 'col' + typeIndex + 'Debit');
        }

        // ref 8
        if (object.chartOfAccountCode.substring(0, 4) === "2230") {
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(object, 'col' + typeIndex + 'Debit');
        }

        // ref 9
        if (object.chartOfAccountCode.substring(0, 4) === "2220") {
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(object, 'col' + typeIndex + 'Debit');
        }

        // ref 10
        if (object.chartOfAccountCode.substring(0, 4) === "2125") {
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(object, 'col' + typeIndex + 'Debit');
        }

        // ref 11
        if (object.chartOfAccountCode.substring(0, 4) === "2210") {
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(object, 'col' + typeIndex + 'Debit');
        }

        // ref 12
        if (object.chartOfAccountCode.substring(0, 4) === "2124" || object.chartOfAccountCode.substring(0, 4) === "2126") {
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(object, 'col' + typeIndex + 'Debit');
        }

        // ref 13
        if (object.chartOfAccountCode.substring(0, 4) === "2300" || object.chartOfAccountCode.substring(0, 4) === "2400" || object.chartOfAccountCode.substring(0, 4) === "2410") {
            const totalHistoricalCredit = Htc ? object.totalHistoricalCredit : 0;
            summary_record += _.get(object, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(object, 'col' + typeIndex + 'Debit');
        }

        // รายรับค่าส่วนกลาง / Revenue - Common area fees 
        if (object.chartOfAccountCode.substring(0, 7) === '4100-01') {

            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // รายรับค่าสาธารณูปโภค / Operation revenue - Utility
        if (object.chartOfAccountCode.substring(0, 7) === '4100-04') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }
        // รายรับค่าใช้ประโยชน์พื้นที่ส่วนกลาง / Revenue Common area utilization
        if (object.chartOfAccountCode.substring(0, 7) === '4200-01' || object.chartOfAccountCode.substring(0, 7) === '4200-02') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // รายรับอื่นๆ / Other Revenue
        if (object.chartOfAccountCode.substring(0, 1) === '4' && object.chartOfAccountCode.substring(0, 7) !== '4200-01' && object.chartOfAccountCode.substring(0, 7) !== '4200-02' &&
            object.chartOfAccountCode.substring(0, 7) !== '4100-04' && object.chartOfAccountCode.substring(0, 7) !== '4100-01') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // ต้นทุนขายสุทธิ / Cost of Goods Sold
        if (object.chartOfAccountCode.substring(0, 4) === '5100') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // เงินเดือนเเละค่าจ้าง / Salaries and wages
        if (object.chartOfAccountCode.substring(0, 4) === '5200') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // รายจ่าย - ตามสัญญา / Expense - by agreement
        if (object.chartOfAccountCode.substring(0, 4) === '5300') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // รายจ่ายดำเนินงานและสำนักงาน / Operation expense - Office
        if (object.chartOfAccountCode.substring(0, 4) === '5400') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // รายจ่ายสาธารณูปโภค / Operation expense - Utility
        if (object.chartOfAccountCode.substring(0, 4) === '5500') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // รายจ่าย - ซ่อมเเซมและบำรุงรักษา / Operation expense - Maintenance & Repair
        if (object.chartOfAccountCode.substring(0, 4) === '5600') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // รายจ่ายค่าเสื่อมราคาและค่าตัดจำหน่าย / Depreciation and Amortization expense
        if (object.chartOfAccountCode.substring(0, 4) === '5700') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        // ค่าใช้จ่ายอื่น ๆ / Other expenses
        if (object.chartOfAccountCode.substring(0, 1) === "5" && object.chartOfAccountCode.substring(0, 4) !== '5100'
            && object.chartOfAccountCode.substring(0, 4) !== '5200' && object.chartOfAccountCode.substring(0, 4) !== '5300'
            && object.chartOfAccountCode.substring(0, 4) !== '5400' && object.chartOfAccountCode.substring(0, 4) !== '5500'
            && object.chartOfAccountCode.substring(0, 4) !== '5600' && object.chartOfAccountCode.substring(0, 4) !== '5700') {
            if (type === 'beforeSet') {
                const totalHistoricalCredit = object.totalHistoricalDiffFirst;
                summary_record += object.yearFirst + totalHistoricalCredit
            } else {
                const totalHistoricalDiffSecond = object.totalHistoricalDiffSecond;
                summary_record += object.yearSecond + totalHistoricalDiffSecond
            }
        }

        return summary_record;
    }
    render() {
        //Link สั่งกลับ
        let Link_Nav = '/accounting'
        if (this.props.match.params.note) {
            let codeList = [];
            if (this.props.match.params.note === '4100-01') {
                codeList.push("4100-01")
            } else if (this.props.match.params.note === '4100-04') {
                codeList.push("4100-04")
            } else if (this.props.match.params.note === '4200-01,4200-02') {
                codeList.push("4200-01", "4200-02")
            } else if (this.props.match.params.note === 'other_income') {
                codeList.push("other_income")
            }
            else if (this.props.match.params.note === 'other_expense') {
                codeList.push("other_expense")
            }
            else if (this.props.match.params.note.search("-") > 0) {
                this.props.match.params.note.split('-').forEach(e => {
                    codeList.push(e)
                });
            } else {
                codeList.push(this.props.match.params.note)
            }

            if (_.startsWith(codeList, "1") || _.startsWith(codeList, "2")) {
                Link_Nav = "/accounting/balance-sheet"
            }

            // 4
            if (_.startsWith(codeList, "other_income") || _.startsWith(codeList, "4") || _.startsWith(codeList, "other_expense") || _.startsWith(codeList, "5")) {
                Link_Nav = "/accounting/summary-income-expense"
            }

        }

        const month_select = this.state.period
        const { loading } = this.state
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={Link_Nav}
                                    LinkText={i18next.t('balanceSheet:balance sheet account statements')}
                                    boxHtmlText={
                                        <div className="col text-right">
                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_balance_sheet_print' }) &&
                                                <ExportBalanceSheetAccountStatements state={this.state} />
                                            }
                                        </div>
                                    }
                                />

                                <div className="content-inner">

                                    <div className="row mb-4">

                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group">
                                                <select className="form-control dropdown-custom-arrow" name="period" id="period" disabled={this.props.match.params.note && this.props.match.params.period}
                                                    onChange={this.handleChange} value={this.state.period}>
                                                    <option value="month">{i18next.t("BalanceSheet:Monthly")}</option>
                                                    <option value="month3">{i18next.t("BalanceSheet:With")} 3 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="month6">{i18next.t("BalanceSheet:With")} 6 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="month9">{i18next.t("BalanceSheet:With")} 9 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="year">{i18next.t("BalanceSheet:Yearly")}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-md-2">
                                            <div className="float-left mr-1">
                                                <DatePickerAdapter onChange={this.handleChange} className="form-control"
                                                    selected={this.state.selected_date} name="selected_date"
                                                    disable={this.props.match.params.note && this.props.match.params.period} />
                                            </div>
                                        </div>
                                        <div className="ml-n2">
                                            <button type="button" className="btn btn-primary form-control"
                                                name="search" onClick={() => this.calDate()}>{i18next.t("AllFilter:Search")}</button>
                                        </div>

                                        {!this.props.match.params.note && !this.props.match.params.period &&
                                            <React.Fragment>
                                                <div className="col-xl-2 col-md-2">
                                                    <label>{i18next.t("BalanceSheet:Remark")}</label>
                                                    <div className="form-group">
                                                        <select className="form-control dropdown-custom-arrow" name="input_statements_start" id="input_statements_start" disabled={this.props.match.params.note && this.props.match.params.period}
                                                            onChange={this.handleChange} value={this.state.input_statements_start}>
                                                            {
                                                                this.state.listDataFilter.map((e) => <option value={e.node.number} key={e.node.value} >{e.node.label}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-xl-2 col-md-2">
                                                    <label>{i18next.t("BalanceSheet:To")}</label>
                                                    <div className="form-group">
                                                        <select className="form-control dropdown-custom-arrow" name="input_statements_end" id="input_statements_end" disabled={this.props.match.params.note && this.props.match.params.period}
                                                            onChange={this.handleChange} value={this.state.input_statements_end}>
                                                            {
                                                                this.state.listDataFilter.map((e) => <option value={e.node.number} key={e.node.value}>{e.node.label}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }

                                    </div>

                                    <div className="row">
                                        <div className="col balance-sheet">
                                            {loading ?
                                                <Loading />
                                                :
                                                <div className="table-responsive fade-up">
                                                    <table className="table table-borderless mb-5">

                                                        {Object.entries(this.state.balanceSheetAccountStatementsList).map(([text_index, record], index_obj) => {
                                                            let summary_1 = 0
                                                            let summary_2 = 0
                                                            return (

                                                                record.map((value, index_question) => {
                                                                    const fine_last_Record = (index_question + 1) === record.length ? true : false;
                                                                    summary_1 += value.summary1
                                                                    summary_2 += value.summary2
                                                                    return (
                                                                        <React.Fragment key={index_question}>
                                                                            {/* หัวข้อ */}
                                                                            {index_question === 0 &&
                                                                                <BalanceSheetAccountHeaderStatements header_text={"หมายเหตุประกอบงบการเงิน / Notes To Financial Statement"}
                                                                                    state={this.state}
                                                                                    first_title={text_index}
                                                                                    first_statements={index_obj === 0 ? true : false}
                                                                                    month_select={month_select}
                                                                                />
                                                                            }
                                                                            {/* แกนกลาง */}
                                                                            <BalanceSheetAccountAssetsStatements
                                                                                // name={value.chartOfAccountCode + " " + value.name}
                                                                                name={value.name}
                                                                                total_1={value.summary1}
                                                                                total_2={value.summary2}
                                                                                fine_last_Record={fine_last_Record}
                                                                                summary_1={summary_1}
                                                                                summary_2={summary_2}
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                })

                                                            )
                                                        }
                                                        )}

                                                    </table>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default balanceSheetAccountStatements;