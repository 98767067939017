import {
    commitMutation,

} from 'react-relay'
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const mutation = graphql`
mutation updateCreateChatMeetingMutation ($input: CreateAndUpdateRoomGroupInput!) {
    createAndUpdateRoomGroup (input: $input) {
        ok
        message
    }
}
`;
// const mutation = graphql`
//     mutation UpdateParcelMutation($input: UpdateParcelStatusInput!) {
//         updateParcelStatus(input: $input) {
//             ok
//         }
//     }
// `;

export default function UpdateCreateChatMeeting(variables, callback, error_callback) {
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
