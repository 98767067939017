import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import '../styles/register.scss'

import { Translation } from 'react-i18next';
import i18n from 'i18next';
import Navigation from "./navigation";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {commitMutation, QueryRenderer} from "react-relay";
import Swal from "sweetalert2";
import "./styles/petDetail.scss"
import Loading from "../../libs/loading";
import _ from "lodash";


const allResidential = graphql`
    query petDetailQuery($id: ID!) {
        residential(id:$id){
            name
            pet{
                edges{
                    node{
                        id
                        petType
                        description
                        imagePet
                        healthCheckPath
                        healthCheckName
                    }
                }
            }
        }

    }
`;

const mutation = graphql`
    mutation petDetailMutation($input: DeletePetInput!){
        deletePet(input: $input){
            ok
            petViewer{
                id
                allPet{
                    edges{
                        node{
                            id
                            petType
                            description
                            imagePet
                        }
                    }
                }
            }
        }
    }
`;


class PetDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            reQuery: false,
        };
    }

    onDeleteItem(id) {
        Swal.fire({
            title: i18n.t("pats:Are you sure to remove data?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("pats:Yes"),
            cancelButtonText: i18n.t("pats:Cancel")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        id: id
                    }
                };
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.deletePet.ok) {
                                Swal.fire(i18n.t("pats:Remove successfully!"), '', 'success').then(() => {
                                    this.setState({reQuery: !this.state.reQuery})
                                });
                            } else {
                                Swal.fire(i18n.t("pats:Remove unsuccessfully!"), '', 'warning'
                                );
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18n.t("pats:Please try again"), 'warning')

                        },
                    },
                )
            }
        });

    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <QueryRenderer
                        environment={environment}
                        query={allResidential}
                        variables={{
                            "id": this.state.id,
                            reQuery: this.state.reQuery
                        }}
                        render={({error, props}) => {
                            if (error) {
                                return <div>{error.message}</div>;
                            } else if (props) {
                                return (
                                    <div className="container-fluid p-4" id="detail">
                                        <div className="row justify-content-between">
                                            <div className="col header-color">
                                                <h3 className="mb-4">
                                                    <Link to="/register/residential/list/all">
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                            alt="back" className="back"/>
                                                    </Link>
                                                    {props.residential.name}
                                                </h3>
                                                <div className="content-inner">
                                                    <Navigation id={this.props.match.params.id}/>
                                                </div>
                                            </div>
                                        </div>

                                        <React.Fragment>
                                            <div className="content-inner" id="pet-detail">
                                                <div className="row mb-4 fade-up">
                                                    {
                                                        props.residential.pet.edges.map((pet) => {
                                                            let opt2 =  pet.node?.healthCheckName && pet.node?.healthCheckName
                                                            let typeFile 
                                                                if(opt2){
                                                                    typeFile =  opt2.slice(opt2.lastIndexOf('.')+1)
                                                                }
                                                            return (
                                                                <div className="col-md-6 col-xl-4 mb-4" key={pet.node.id}>
                                                                <div className="card p-2 show">
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col text-left padding-hover"/>
                                                                            <div className="col text-right show-icon">
                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_pets_delete'}) &&
                                                                                <React.Fragment>
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                        alt="delete-icon"/>
                                                                                    <Translation>
                                                                                        {t=><span
                                                                                            className="grey-color ml-1 underline hover"
                                                                                            onClick={() => this.onDeleteItem(pet.node.id)}>{t("pats:Remove")}</span>}
                                                                                    </Translation>
                                                                                </React.Fragment>
                                                                                }

                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_pets_edit'}) &&

                                                                                <Link to={{
                                                                                    pathname: "/register/residential/detail/form-pet/" + this.state.id + "/" + pet.node.id
                                                                                }}>
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                                        alt="edit-icon"
                                                                                        className="ml-4"/>
                                                                                    <Translation>
                                                                                        {t=><span
                                                                                            className="grey-color ml-1">{t("pats:Edit")}</span>}
                                                                                    </Translation>
                                                                                </Link>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col text-center">
                                                                                        {pet.node.imagePet ?
                                                                                            <div
                                                                                                className="rounded-circle avatar-img profile-img img-border"
                                                                                                style={{backgroundImage: `url(${pet.node.imagePet})`}}>
                                                                                            </div>
                                                                                            :
                                                                                            <div
                                                                                                className="rounded-circle avatar-img profile-img"
                                                                                                style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/icons/pet-upload-icon.png'})`}}>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-8">
                                                                                <div className="row mt-3">
                                                                                    <div className="col">
                                                                                        <h6>{pet.node.petType}</h6>
                                                                                        <p>{pet.node.description}</p>
                                                                                       
                                                                                        <Translation>
                                                                                        {t=><h6 >{t("pats:Health check docs")}</h6>}
                                                                                        </Translation>
                                                                                        {
                                                                                        pet.node.healthCheckName ?  
                                                                                        <p>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                                                                                    <Link
                                                                                                        to="route"
                                                                                                        onClick={(event) => {
                                                                                                            event.preventDefault();
                                                                                                            window.open(
                                                                                                                pet.node.healthCheckPath
                                                                                                            )
                                                                                                        }} 
                                                                                                        target="blank">
                                                                                                            {pet.node.healthCheckName}
                                                                                                    </Link>
                                                                                            </p> : <p className='pb-2'> - </p>
                                                                                    }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )
                                                        }
                                                       
                                                        )
                                                    }

                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_pets_create'}) &&

                                                    <div className="col-md-6 col-xl-4 mb-4">
                                                        <Link to={{
                                                            pathname: "/register/residential/detail/form-pet/" + this.state.id
                                                        }}>
                                                            <div className="card card-add">
                                                                <div
                                                                    className="card-body d-flex align-middle text-center">
                                                                    <div className="col">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/plus-icon.png'}
                                                                            alt="plus-icon"/>
                                                                        <Translation>{t=><h4>{t("pats:Add pets")}</h4>}</Translation>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>

                                    </div>
                                );
                            }
                            return <Loading/>
                        }}
                    />
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default PetDetail;
