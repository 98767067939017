import React, { Component } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_th_TH from "@amcharts/amcharts5/locales/th_TH";
import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import i18next from 'i18next';
import { format } from "date-fns";
import "./fixRequestDashBroad.scss";

class FixRequestLinechart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      loading: false,
      checkOnline: true,
    };
  }

  componentDidMount() {
    this.genChart();
  }

  genChart() {
    if (this.root) {
      this.root.dispose();
      this.chart.dispose();
    }

    let root = am5.Root.new("fixRequestLineChart");
    let locale = `${i18next.t("fix_request_dashbroad:Locale")}`;
    locale === 'thLocale'? root.locale = am5locales_th_TH : root.locale = am5locales_en_US;
    
    let data = this.props.dataFixFinishJob

    root.setThemes([am5themes_Animated.new(root)]);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    
    
    // Create chart
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout,
      paddingRight: 20,
      paddingTop: 30,
      paddingBottom: 40,
      paddingLeft: 30,
    }));

    chart.children.unshift(
      am5.Label.new(root, {
        text: `${i18next.t("fix_request:Overview of repair work")} (${i18next.t("fix_request_dashbroad:Job")})`,
        fontSize: 16,
        fontWeight: 600,
        paddingBottom: 30,
      })
    );
        
    // Create axes        
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
    });

    xRenderer.labels.template.setAll({
        rotation: 30,
        centerX: am5.p50,
        fontSize: 12
    });

    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "day", count: 1 },
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));
    xAxis.get("dateFormats")["day"] = "dd-MMM";
    xAxis.get("periodChangeDateFormats")["day"] = "dd-MMM";

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));
    
    // Add series
    for (let i = 0; i < data.length; i++) {
      let dataType = "";
      if(i === 0){
        dataType = `${i18next.t("fix_request:Open")}`;
      } else if(i === 1){
        dataType = `${i18next.t("fix_request:Done")}`;
      } else {
        dataType = `${i18next.t("fix_request:Cancel")}`;
      }

      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: dataType,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: `${dataType} {valueY} ${i18next.t("fix_request_dashbroad:Job")}`
        })
      }));
      chart.series.each(function(chartSeries) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 2,
        });
      })
      series.data.setAll(data[i]);
    
      // Make stuff animate on load
      series.appear();
    }
    
    // Add cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);
    
    // Add legend
    let legend = chart.rightAxesContainer.children.push(am5.Legend.new(root, {
      width: 200,
      paddingLeft: 15,
      height: am5.percent(100)
    }));
    
    // When legend item container is hovered, dim all the series except the hovered one
    legend.itemContainers.template.events.on("pointerover", function(e) {
      let itemContainer = e.target;
    
      // As series list is data of a legend, dataContext is series
      let series = itemContainer.dataItem.dataContext;
    
      chart.series.each(function(chartSeries) {
        if (chartSeries != series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000),
            strokeWidth: 1,
          });
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 3,
          });
        }
      })
    })
    
    // When legend item container is unhovered, make all series as they are
    legend.itemContainers.template.events.on("pointerout", function(e) {
      let itemContainer = e.target;
      let series = itemContainer.dataItem.dataContext;
    
      chart.series.each(function(chartSeries) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 2,
          stroke: chartSeries.get("fill")
        });
      });
    })
    
    legend.itemContainers.template.set("width", am5.p100);
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "right"
    });
    
    const excelData = [];

    for (let i = 0; i < data[0].length; i++) {
      const dataForExcel = {};
      const dateExcel = new Date(data[0][i].date);
        dataForExcel[`date`] = format(dateExcel, "DD-MMM");
        dataForExcel[`เปิดแจ้งซ่อม`] = data[0][i].value;
        dataForExcel[`ปิดแจ้งซ่อม`] = data[1][i].value;
        dataForExcel[`ยกเลิกแจ้งซ่อม`] = data[2][i].value;
      excelData.push(dataForExcel);
    }

    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend.data.setAll(chart.series.values);
    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: excelData,
      numericFields: ["เปิดแจ้งซ่อม","ปิดแจ้งซ่อม","ยกเลิกแจ้งซ่อม"],
      numberFormat: "#,###",
      textFields: {
          text: {
            field: "วันที่",
          }
        },
      dataFields: {
        date: "วันที่",
        เปิดแจ้งซ่อม: "เปิดแจ้งซ่อม",
        ปิดแจ้งซ่อม: "ปิดแจ้งซ่อม",
        ยกเลิกแจ้งซ่อม: "ยกเลิกแจ้งซ่อม",
      },
      dataFieldsOrder: ["date","เปิดแจ้งซ่อม","ปิดแจ้งซ่อม","ยกเลิกแจ้งซ่อม"],
    });
    let annotator = am5plugins_exporting.Annotator.new(root, {});

    exporting.get("menu").set("items", [
      {
        type: "format",
        format: "jpg",
        label: "Export Image",
      },
      {
        type: "format",
        format: "print",
        label: "Print",
      },
      {
        type: "format",
        format: "xlsx",
        label: "Export Excel",
      },
      {
        type: "separator"
      },
      {
        type: "custom",
        label: "Remark",
        callback: function () {
          this.close();
          annotator.toggle();
        },
      },
    ]);
    
    chart.appear(1000, 100);
    root._logo.dispose();

    this.chart = chart;
    this.root = root;
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="fixRequestLineChartContainer">
            <div id="fixRequestLineChart"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default FixRequestLinechart;
