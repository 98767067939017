import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import SlipVerificationList from './slip_verification/slipVerificationList'



class Report extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute path={`${this.props.match.url}/slip-verification`} component={SlipVerificationList}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Report;