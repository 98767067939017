import React from "react";
import { Component } from "react";
import Wrapper from "../../../components/wrapper/index";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import TransferOfOwnershipForm from "./transferOfOwnershipForm";
import TransferOfOwnershipSelect from "./transferOfOwnershipSelect";

class TransferOfOwnershipCreate extends Component {

    constructor(props){
        super(props);
        this.state = {
            contactList : [],
        }

        this.updateContactList = this.updateContactList.bind(this)
    }

    updateContactList(contactList) {
        this.setState({
            contactList: contactList
        })
    }

    render(){
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    {this.props.match.params.status === 'form' &&
                        <TransferOfOwnershipForm contactList={this.state.contactList}/>
                    }
                    {this.props.match.params.status === 'select_customer' &&
                        <TransferOfOwnershipSelect updateContactList={this.updateContactList}/>
                    }
                </WrapperContent>
            </Wrapper>
        )
    }



}
export default TransferOfOwnershipCreate;