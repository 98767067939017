import "../styles/createBudget.scss";
import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import _ from "lodash";
import { addMonths, format } from "date-fns";
import thLocale from "date-fns/locale/th";
import Loading from "../../../libs/loading";
import numberWithCommas from "../../../libs/numberWithComma";
import code from "../json/chart_of_account.json";
// import '../../../../document/styles/main.scss';
// import '../../report.scss';
import { Helmet } from "react-helmet";
import { H5 } from '../../../components/Text/Text';
import BudgetSetingTable from "./budgetSetingTable";

const query = graphql`
  query wrapperBudgetSetingReportQuery($yearId: String) {
    selfProject {
      name
      logo
  }
    oneYearBudget(yearId: $yearId) {
      edges {
        node {
          id
          years
          startDate
          endDate
          project {
            name
          }
          chartOfAccountBudget {
            edges {
              node {
                totalBudget
                chartOfAccount {
                  id
                  name
                  nameEn
                  chartOfAccountCode
                }
                monthBudget {
                  edges {
                    node {
                      months
                      budget
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class WrapperBudgetSetingReport extends React.Component {
  constructor(pros) {
    super(pros);
    this.state = {
      lists: _.clone(code),
      loading: true,
      year: new Date(),
      start_date: new Date(2021, 0, 1),
      end_date: new Date(2021, 11, 1),
      list_month: [],
      value: [],
      save: false,
      redirectToList: false,
      disabled_table: true,
      id: "",
      year_search: this.props.match.params.id,
      project_name: "",
      sum_income: {},
      sum_expenses: {},
      total_sum_expenses: {},
      project_name: '',
      project_logo: '',
      date: new Date()
    };
  }

  async componentDidMount() {
    let budget;
    await fetchQuery(environment, query, { yearId: this.state.year_search }).then((data) => { budget = data; }).catch((error) => { });
    if (budget.oneYearBudget.edges.length > 0) {
      let start = new Date(budget.oneYearBudget.edges[0]?.node.startDate);
      let end = addMonths(new Date(budget.oneYearBudget.edges[0].node.endDate), 1);
      let list = _.cloneDeep(this.state.lists);
      let list_month = [];
      do {
        list_month.push(start);
        start = addMonths(start, 1);
      } while (format(start, "MM-YYYY") !== format(end, "MM-YYYY"));

      for (const item of budget.oneYearBudget.edges[0].node.chartOfAccountBudget.edges) {
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 1) === "4") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[4].push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "51") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][0].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "52") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][1].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "53" && item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) !== "531") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][2].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "531") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][3].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "54") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][4].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "55") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][5].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "56") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][6].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 2) === "57") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][7].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "581") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][8].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "582") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][8].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
        if (item.node.chartOfAccount.chartOfAccountCode.slice(0, 3) === "583") {
          let check_null = _.sumBy(item.node.monthBudget.edges, (o) => o.node.budget) !== 0 ? true : false;
          check_null &&
            list[5][8].child.push({
              ...item.node.chartOfAccount,
              month_budget: item.node.monthBudget.edges,
              total_budget: item.node.totalBudget || "",
            });
        }
      }
      let key = {
        sum_month_budget: [],
        sum_total_budget: 0,
      }
      let sum_income = _.cloneDeep(key)
      let sum_expenses = {}
      list[4].map((item) => {
        item.month_budget.map((month, index) => {
          sum_income.sum_month_budget[index] = parseFloat(sum_income.sum_month_budget[index] || 0) + parseFloat(month.node.budget || 0)
          return month
        })
        sum_income.sum_total_budget = parseFloat(sum_income.sum_total_budget || 0) + parseFloat(item.total_budget || 0)
        return item
      })
      list[5].map((item) => {
        let code = item.key
        if (!sum_expenses[code]) {
          sum_expenses[code] = _.cloneDeep(key)
        }
        item.child.map((item_child) => {
          item_child.month_budget.map((month, index) => {
            sum_expenses[code].sum_month_budget[index] = parseFloat(sum_expenses[code].sum_month_budget[index] || 0) + parseFloat(month.node.budget || 0)
            return month
          })
          sum_expenses[code].sum_total_budget = parseFloat(sum_expenses[code].sum_total_budget || 0) + parseFloat(item_child.total_budget || 0)
          return item_child
        })
        return item
      })

      let total_sum_expenses = _.cloneDeep(key)
      _.map(sum_expenses, (total_item, index) => {
        total_item.sum_month_budget.map((month, index) => {
          total_sum_expenses.sum_month_budget[index] = parseFloat(total_sum_expenses.sum_month_budget[index] || 0) + parseFloat(month || 0)
          return month
        })
        total_sum_expenses.sum_total_budget = parseFloat(total_sum_expenses.sum_total_budget || 0) + parseFloat(total_item.sum_total_budget || 0)
        return total_item
      });

      this.setState({
        lists: list,
        loading: false,
        list_month,
        year: new Date(budget.oneYearBudget.edges[0].node.years),
        start_date: new Date(budget.oneYearBudget.edges[0].node.startDate),
        end_date: new Date(budget.oneYearBudget.edges[0].node.endDate),
        id: budget.oneYearBudget.edges[0].node.id,
        sum_income,
        sum_expenses,
        total_sum_expenses,
        project_name: budget.selfProject.name,
        project_logo: budget.selfProject.logo,
      });
    } else {
      this.setState({ redirectToList: true })
    }

  }

  toFixedNumber = (value) => {
    let sub = parseFloat(value);
    sub = sub.toString();
    if (sub.indexOf(".") > 0) {
      return numberWithCommas(sub.substr(0, sub.indexOf(".")) + sub.substr(sub.indexOf("."), 3));
    } else if (value == 0) {
      return "-";
    } else if (value == "") {
      return "-";
    } else {
      return numberWithCommas(sub);
    }
  };

  render() {
    let last_index = 0;
    let { project_logo, project_name, date } = this.state
    if (this.state.loading) {
      return (
        <div><Loading /></div>
      );
    }
    return (
      <React.Fragment>
        <Helmet
          style={[{
            "cssText": `
                          body {
                              width: 100%;
                              height: 100%;
                              margin: 0;
                              padding: 0;
                              background-color: #FAFAFA;
                            }
                          
                            * {
                              box-sizing: border-box;
                              -moz-box-sizing: border-box;
                            }
                          
                            .page {
                              width: 297mm;
                              min-height: 210mm;
                              padding: 5mm;
                              margin: 10mm auto;
                              border: 1px #D3D3D3 solid;
                              border-radius: 2px;
                              background: white;
                              box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                            }
                          
                            @page {
                              size: landscape;
                              margin: 0;
                            }
                            @media print {
                              html, body {
                                width: 297mm;
                                height: 210mm;
                              }
                              .page {
                                margin: 0;
                                border: initial;
                                border-radius: initial;
                                width: initial;
                                min-height: initial;
                                box-shadow: initial;
                                background: initial;
                                page-break-after: always;
                              }
                            }
                      `
          }]}>
          <meta charSet="utf-8" />
          <title>Interest Print</title>
          <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
        </Helmet>

        <div className="print-top-menu">
          <div className="logo"><img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" /></div>
          <div className="print" onClick={this.print}>PRINT</div>
        </div>
        <div>

          <div className="page">
            <div className="container-fluid box p-3" id='bank-deposit-report-main' >
              <div className="">
                <div className="logo ml-1" style={{ position: 'absolute' }}>{project_logo !== '' && <img src={project_logo} alt="silverman" style={{ width: '100px' }} />}</div>
                <div className="mt-5">
                  <H5 className="text-center">{project_name}</H5>
                  <H5 className="text-center">รายงานเงินฝากธนาคาร</H5>
                  <H5 className="text-center">ประจำเดือน {format(date, "  MMMM YYYY ", { locale: thLocale, })}</H5>
                </div>
                <BudgetSetingTable state = {this.state} toFixedNumber = {this.toFixedNumber} last_index={last_index} />
                {/* {_.chunk(all_bank, 12).map((transaction, index) => {
                    if (index === 0) {
                      return (
                        <BankDepositReportTable
                          key={index}
                          all_bank={transaction}
                          summary_report_residential={summary_report_residential}
                          summary_report_supplier={summary_report_supplier}
                          summary_bank_report={summary_bank_report}
                          summary_balance={summary_balance}
                          loading={loading}
                          date={date}
                          handleSummary={this.handleSummary}
                          report
                          logo={project_logo}
                          lastPage={(index + 1 === _.chunk(all_bank, 12).length || _.chunk(all_bank, 12).length === 1) ? true : false}
                        />
                      )
                    }
                  })} */}

              </div>
            </div>
          </div>
          {/* {_.chunk(all_bank, 12).map((transaction, index) => {
              if (index > 0) {
                return (
                  <div className="page">
                    <BankDepositReportTable
                      key={index}
                      all_bank={transaction}
                      summary_report_residential={summary_report_residential}
                      summary_report_supplier={summary_report_supplier}
                      summary_bank_report={summary_bank_report}
                      summary_balance={summary_balance}
                      loading={loading}
                      date={date}
                      handleSummary={this.handleSummary}
                      report
                      logo={project_logo}
                      lastPage={(index + 1 === _.chunk(all_bank, 12).length || _.chunk(all_bank, 12).length === 1) ? true : false}
                    />
                  </div>
                )
              }
            })} */}
          {/* <div className="page">
              <SummaryAccountsReceivableAndPayableTable
                all_bank={all_bank}
                summary_report_residential={summary_report_residential}
                summary_report_supplier={summary_report_supplier}
                summary_bank_report={summary_bank_report}
                summary_balance={summary_balance}
                loading={loading}
                date={date}
                report
                handleSummary={this.handleSummary}
              />
            </div> */}
        </div>

      </React.Fragment>
    );
  }
}

export default WrapperBudgetSetingReport;
