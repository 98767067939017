import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Loading from '../../libs/loading'
import ManageOrder from './manageOrder';
import "./manageOrder.scss"

const query = graphql`
  query wrapperManageOrderQuery($poId: String){
    selfProject{
      id
      name
      address
      logo
      juristicContactNumber
      keyProjectQr
      bankCompCode
      bankServiceCode
      taxIdNumber
      typeOfProject
    }
    onePurchaseOrderes(poId: $poId){
      edges {
        node {
          id
          docNumber
          issuedDate
          status
          voidRemark
          voidDate
          voider
          approver
          approveDate
          procurementForm {
            id
            docNumber
            issuedDate
            creditor{
              id
              name
              refNumber
              taxIdNumber
              registeredAddress
              registeredDistrict
              registeredPostalCode
              registeredCity
              registeredPostalCode
              registeredCity
              registeredProvince
              registeredPostalCode
              registeredProvince
              registeredPostalCode
              typeOfSupplier
              typeOfCompany
              nameBranch
            }
            buyDate
            contractNumber
            contractDate
            paymentTerms
            otherPaymentTerms
            contractor
            contractorTel
            contractorEmail
            deliveryAddress
            deliveryDate
            remark
            total
            type
            procurementList{
              edges{
                node{
                  id
                  productAndService{
                    id
                    name
                    productCode
                  }
                  chartOfAccount{
                    id
                    chartOfAccountCode
                    name
                  }
                  description
                  unitItems
                  price
                  unit
                  discount
                  budget
                  vat
                  whtRate
                  whtRatePercent
                  preTaxAmount
                  total
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class wrapperManageOrder extends Component {

  print() {
    window.print();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet
          style={[{
              "cssText": `
              body {
                  background-color: #ececec;
              }
              @media print {
                body {
                  background-color: #FFFFFF;
                }
              }
          `
          }]}>
          <meta charSet="utf-8"/>
          <title>Manage Order Print</title>
          <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
        </Helmet>
        <div id="wrapperManageOrder">
          <div className="print-top-menu">
            <div className="logoManageOrder">
              <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
            </div>
            <button className="printManageOrder" onClick={this.print}>
              <img src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'} alt="silverman"/>
              <span>พิมพ์</span>
            </button>
          </div>
        </div>
        <QueryRenderer
          environment={environment}
          query={query}
          cacheConfig={{use_cache: false}}
          variables={{poId: this.props.match.params.id }}
          render={({error, props}) => {
              if (error) {
                  return <div className="alert alert-danger"
                              role="alert">{error.message}</div>;
              } else if (props) {
                  return(
                    <ManageOrder
                      selfProject={props.selfProject}
                      onePurchaseOrderes={props.onePurchaseOrderes.edges[0].node}
                      numberManageOrder={atob(props.onePurchaseOrderes.edges[0].node.id).split(':')[1]}
                      />
                  )
              }
              return <Loading/>;
          }}
        />
      </React.Fragment>
    )
  }
}
