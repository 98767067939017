// import '../../report.scss';
import React from 'react';
import _ from "lodash";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
import numberWithCommaReport from '../../../libs/numberWithCommaReport'
import axios from 'axios';
import getApiRoot from "../../../libs/getAPIRoot";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const BudgetSetingExcelExports = ({ state }) => {
  const getData = () => downloadExcel()
  const downloadExcel = async (data) => {
    var token_id = localStorage.getItem("token");
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('total receipt');
    let columns = [{ width: 10 }, { width: 50 }]
    // setBorder
    let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    // let fontSizes = { size: 11.5 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let textRight = { vertical: 'middle', horizontal: 'right' };
    let textLeft = { vertical: 'middle', horizontal: 'left' };
    let fontBold = { size: 11.5, bold: true };

    //textHeader 
    worksheet.getCell('A1').value = state.project_name
    worksheet.getCell('A1').font = { size: 11.5, bold: true };
    worksheet.getCell('A2').value = "สรุปรายงานการตั้งงบประมาณประจำปี"
    worksheet.getCell('A2').font = { size: 11.5, bold: true };
    worksheet.getCell('A3').value = `ตั้งแต่วันที่${format(new Date(state.start_date), " DD MMMM YYYY ", { locale: thLocale, })}ถึง${format(new Date(state.end_date), " DD MMMM YYYY", { locale: thLocale, })}`
    worksheet.getCell('A3').font = { size: 11.5, bold: true };

    //table
    let headerRow, cell, last_index = 0
    let month = state.list_month
    //header
    worksheet.mergeCells("A5", "A6");
    headerRow = worksheet.getRow(5);
    cell = headerRow.getCell(1);
    cell.value = 'ลำดับ';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    worksheet.mergeCells("B5", "B6");
    headerRow = worksheet.getRow(5);
    cell = headerRow.getCell(2);
    cell.value = 'รายการ';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    let row_number = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    worksheet.mergeCells(`C${5}:${row_number[month.length + 1]}${5}`);
    headerRow = worksheet.getRow(5);
    cell = headerRow.getCell(3);
    cell.value = 'งบประมาณรายเดือน';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    month.forEach((element, index) => {
      columns.push({ width: 15 })
      headerRow = worksheet.getRow(6);
      cell = headerRow.getCell(3 + index);
      cell.value = format(element, "MMMYY", { locale: thLocale, });
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter

    });

    worksheet.mergeCells(`${row_number[month.length + 2]}5`, `${row_number[month.length + 2]}6`);
    headerRow = worksheet.getRow(5);
    cell = headerRow.getCell(3 + month.length);
    cell.value = 'งบประมาณรวม';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    //body
    state.lists[4].forEach((element, index) => {
      last_index++
      if (index == 0) {
        var dataRow = worksheet.addRow();
        cell = dataRow.getCell(1);
        cell.value = '';
        cell.border = borders

        cell = dataRow.getCell(2);
        cell.value = 'รายรับ';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textLeft

        element.month_budget.forEach((element, index) => {
          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + index);
          cell.border = borders
        });

        cell = dataRow.getCell(3 + month.length);
        cell.border = borders
      }

      var dataRow = worksheet.addRow();
      cell = dataRow.getCell(1);
      cell.value = last_index;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textCenter

      cell = dataRow.getCell(2);
      cell.value = element.name;
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textLeft

      element.month_budget.forEach((element, index) => {
        headerRow = worksheet.getRow(worksheet.rowCount);
        cell = headerRow.getCell(3 + index);
        cell.value = numberWithCommaReport(_.get(element, 'node.budget'));
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight
      });

      cell = dataRow.getCell(3 + month.length);
      columns.push({ width: 30 })
      cell.value = numberWithCommaReport(element.total_budget);
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textRight

      if (state.lists[4].length === index + 1) {
        var dataRow = worksheet.addRow();
        cell = dataRow.getCell(1);
        cell.value = '';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        cell = dataRow.getCell(2);
        cell.value = 'รวมรายรับ';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textLeft

        state.sum_income.sum_month_budget.forEach((element, index) => {
          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + index);
          cell.value = numberWithCommaReport(element);
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight

        });

        cell = dataRow.getCell(3 + month.length);
        cell.value = numberWithCommaReport(state.sum_income.sum_total_budget);
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight

      }
    })

    _.map(state.lists[5], (item, index_code) => {
      _.map(item.child, (item_child, index) => {
        last_index++

        if (index == 0 && index_code == 0) {
          var dataRow = worksheet.addRow();
          cell = dataRow.getCell(1);
          cell.border = borders

          cell = dataRow.getCell(2);
          cell.value = 'รายจ่าย';
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textLeft

          item_child.month_budget.forEach((element, index) => {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + index);
            cell.border = borders
          });
  
          cell = dataRow.getCell(3 + month.length);
          cell.border = borders
        }

        if (index == 0) {
          var dataRow = worksheet.addRow();
          cell = dataRow.getCell(1);
          cell.border = borders

          cell = dataRow.getCell(2);
          cell.value = item.name;
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textLeft

          item_child.month_budget.forEach((element, index) => {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + index);
            cell.border = borders
          });
  
          cell = dataRow.getCell(3 + month.length);
          cell.border = borders
        }

        var dataRow = worksheet.addRow();
        cell = dataRow.getCell(1);
        cell.value = last_index;
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        cell = dataRow.getCell(2);
        cell.value = item_child.name;
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textLeft

        item_child.month_budget.forEach((element, index) => {
          headerRow = worksheet.getRow(worksheet.rowCount);
          cell = headerRow.getCell(3 + index);
          cell.value = numberWithCommaReport(_.get(element, 'node.budget'));
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight

        });

        cell = dataRow.getCell(3 + month.length);
        cell.value = numberWithCommaReport(item_child.total_budget);
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textRight



        if (item.child.length === index + 1) {
          var dataRow = worksheet.addRow();
          cell = dataRow.getCell(1);
          cell.value = '';
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textCenter

          cell = dataRow.getCell(2);
          cell.value = `รวม${item.name}`;
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textLeft

          state.sum_expenses[item.key].sum_month_budget.forEach((element, index) => {
            headerRow = worksheet.getRow(worksheet.rowCount);
            cell = headerRow.getCell(3 + index);
            cell.value = numberWithCommaReport(element);
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textRight

          });

          cell = dataRow.getCell(3 + month.length);
          cell.value = numberWithCommaReport(state.sum_expenses[item.key].sum_total_budget);
          cell.border = borders
          cell.font = fontBold
          cell.alignment = textRight

        }
      })
    })

    var dataRow = worksheet.addRow();
    cell = dataRow.getCell(1);
    cell.value = '';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    cell = dataRow.getCell(2);
    cell.value = 'รวมรายจ่าย';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textLeft

    state.total_sum_expenses.sum_month_budget.forEach((element, index) => {
      headerRow = worksheet.getRow(worksheet.rowCount);
      cell = headerRow.getCell(3 + index);
      cell.value = numberWithCommaReport(element);
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textRight

    });

    cell = dataRow.getCell(3 + month.length);
    cell.value = numberWithCommaReport(state.total_sum_expenses.sum_total_budget);
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textRight

    var dataRow = worksheet.addRow();
    cell = dataRow.getCell(1);
    cell.value = '';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textCenter

    cell = dataRow.getCell(2);
    cell.value = 'รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด';
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textLeft

    state.total_sum_expenses.sum_month_budget.forEach((element, index) => {
      headerRow = worksheet.getRow(worksheet.rowCount);
      cell = headerRow.getCell(3 + index);
      cell.value = numberWithCommaReport(state.sum_income.sum_month_budget[index] - element);
      cell.border = borders
      cell.font = fontBold
      cell.alignment = textRight

    });

    cell = dataRow.getCell(3 + month.length);
    cell.value = numberWithCommaReport(state.sum_income.sum_total_budget - state.total_sum_expenses.sum_total_budget);
    cell.border = borders
    cell.font = fontBold
    cell.alignment = textRight

    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        let nameFile = 'Annual Budget Report'
        let formData = new FormData();
        formData.append('file', blob , "report.xlsx");
        formData.append('type', "excel");
        formData.append('type_report_ref', "annual_budget_report");
        axios.post(`${getApiRoot()}taskdownload/add_download`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `JWT ${token_id}`,
          }
        })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
        })
        .catch(error => {
          console.error('There was an error sending the file to the server:', error);
        })
        .finally(() => {
          // Download the file after sending it to the API
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = nameFile;
          a.click();
        });
      });
  }
  return (
    <Dropdown.Item  ><div className="text-black" onClick={getData} style={{ display: 'flex', justifyItems: 'center' }} >Excel</div></Dropdown.Item>
  );
};

export default BudgetSetingExcelExports;