/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type BankTransferStatus = "ACTIVE" | "VOID" | "%future added value";
export type bankTransferListQueryVariables = {|
  first?: ?number,
  last?: ?number,
  start_date?: ?any,
  end_date?: ?any,
  order?: ?string,
  search?: ?string,
  status?: ?string,
|};
export type bankTransferListQueryResponse = {|
  +bankTransferViewer: ?{|
    +allBankTransfer: ?{|
      +totalCount: ?number,
      +pageInfo: {|
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
      |},
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +transferOut: ?{|
            +id: string,
            +bankName: string,
            +accountNumber: string,
            +accountType: BankAccountAccountType,
          |},
          +transferIn: ?{|
            +id: string,
            +bankName: string,
            +accountNumber: string,
            +accountType: BankAccountAccountType,
          |},
          +fee: ?number,
          +amount: number,
          +date: any,
          +description: ?string,
          +added: any,
          +status: BankTransferStatus,
        |}
      |}>,
    |}
  |}
|};
export type bankTransferListQuery = {|
  variables: bankTransferListQueryVariables,
  response: bankTransferListQueryResponse,
|};
*/


/*
query bankTransferListQuery(
  $first: Int
  $last: Int
  $start_date: DateTime
  $end_date: DateTime
  $order: String
  $search: String
  $status: String
) {
  bankTransferViewer {
    allBankTransfer(first: $first, last: $last, startDate: $start_date, endDate: $end_date, order: $order, search: $search, status: $status) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          transferOut {
            id
            bankName
            accountNumber
            accountType
          }
          transferIn {
            id
            bankName
            accountNumber
            accountType
          }
          fee
          amount
          date
          description
          added
          status
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bankName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountType",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "end_date"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Variable",
      "name": "order",
      "variableName": "order"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "start_date"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    }
  ],
  "concreteType": "BankTransferNodeConnection",
  "kind": "LinkedField",
  "name": "allBankTransfer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPreviousPage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BankTransferNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankTransferNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "transferOut",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "transferIn",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "added",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransferListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankTransferViewer",
        "kind": "LinkedField",
        "name": "bankTransferViewer",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "bankTransferListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankTransferViewer",
        "kind": "LinkedField",
        "name": "bankTransferViewer",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec7432c874a8053da486e2c71310a556",
    "id": null,
    "metadata": {},
    "name": "bankTransferListQuery",
    "operationKind": "query",
    "text": "query bankTransferListQuery(\n  $first: Int\n  $last: Int\n  $start_date: DateTime\n  $end_date: DateTime\n  $order: String\n  $search: String\n  $status: String\n) {\n  bankTransferViewer {\n    allBankTransfer(first: $first, last: $last, startDate: $start_date, endDate: $end_date, order: $order, search: $search, status: $status) {\n      totalCount\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n      }\n      edges {\n        node {\n          id\n          docNumber\n          transferOut {\n            id\n            bankName\n            accountNumber\n            accountType\n          }\n          transferIn {\n            id\n            bankName\n            accountNumber\n            accountType\n          }\n          fee\n          amount\n          date\n          description\n          added\n          status\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f862fc7396a361fbd3507fac1ed7650e';

module.exports = node;
