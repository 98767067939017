/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialConveyance = "ALREADY_TRANSFERRED" | "NOT_TRANSFERRED" | "%future added value";
export type settingInvoiceCreateQueryVariables = {|
  contact_type?: ?string,
  conveyance?: ?string,
|};
export type settingInvoiceCreateQueryResponse = {|
  +contactViewer: ?{|
    +id: string,
    +allContactPreInvoice: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
          +residential: ?{|
            +id: string,
            +conveyance: ResidentialConveyance,
            +size: number,
            +ownershipRatio: ?number,
          |},
        |}
      |}>
    |},
  |}
|};
export type settingInvoiceCreateQuery = {|
  variables: settingInvoiceCreateQueryVariables,
  response: settingInvoiceCreateQueryResponse,
|};
*/


/*
query settingInvoiceCreateQuery(
  $contact_type: String
  $conveyance: String
) {
  contactViewer {
    id
    allContactPreInvoice(typeOfContact: $contact_type, conveyance: $conveyance, typeOfPayment: "receivable") {
      edges {
        node {
          id
          refNumber
          name
          firstName
          lastName
          residential {
            id
            conveyance
            size
            ownershipRatio
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contact_type"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "conveyance"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactViewer",
    "kind": "LinkedField",
    "name": "contactViewer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "conveyance",
            "variableName": "conveyance"
          },
          {
            "kind": "Variable",
            "name": "typeOfContact",
            "variableName": "contact_type"
          },
          {
            "kind": "Literal",
            "name": "typeOfPayment",
            "value": "receivable"
          }
        ],
        "concreteType": "ContactNodeConnection",
        "kind": "LinkedField",
        "name": "allContactPreInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "conveyance",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "size",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ownershipRatio",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingInvoiceCreateQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingInvoiceCreateQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a7e8b07ecb3593e9b220e4ad62b95d98",
    "id": null,
    "metadata": {},
    "name": "settingInvoiceCreateQuery",
    "operationKind": "query",
    "text": "query settingInvoiceCreateQuery(\n  $contact_type: String\n  $conveyance: String\n) {\n  contactViewer {\n    id\n    allContactPreInvoice(typeOfContact: $contact_type, conveyance: $conveyance, typeOfPayment: \"receivable\") {\n      edges {\n        node {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n          residential {\n            id\n            conveyance\n            size\n            ownershipRatio\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a5569c39d23846e39f4297148e3b1414';

module.exports = node;
