import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Translation} from "react-i18next";
import i18next from 'i18next';



class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/finance/deposit-interest/list/during">
                               <Translation>{t=><>{i18next.t("depositInterestDashBoard:Currently in fixed deposit")}</>}</Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/finance/deposit-interest/list/finished">
                                <Translation>
                                    {
                                        t => <span>{i18next.t("depositInterestDashBoard:Matured")}</span>
                                    }
                                </Translation>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;