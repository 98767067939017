import ComponentPagination from "../../../libs/componentPagination";
import {graphql} from "babel-plugin-relay/macro";
import {QueryRenderer} from "react-relay";
import environment from "../../../env/environment";
import React from "react";
import {format} from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import Pagination from "../../../libs/newPagination";
import {Link} from "react-router-dom";
import Loading from "../../../libs/loading"
import i18next from "i18next";

const query = graphql`
    query clearAdvanceListTableQuery($search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int) {
        allClearAdvance(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last){
            totalCount
            edges {
                node {
                    id
                    docNumber
                    issuedDate
                    description
                    returnPrice
                    overPrice
                    status
                    advance {
                        id
                        amount
                        docNumber
                    }
                    clearAdvanceTransaction{
                        edges{
                            node{
                                id
                                price
                                description
                                refClearAdvanceTransactionDoc
                            }
                        }

                    }
                }
            }
        }
    }
`;

class ClearAdvanceListTable extends ComponentPagination {

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'VOID':
                text = 'Canceled';
                color = 'text-center text-danger';
                break;
            case 'CLEAR':
                text = 'Cleared';
                break;
            default:
                text = '';
        }
        return <td className={color}>{i18next.t("advance_withdraw_list:"+text)}</td>
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                variables={{
                    search: this.props.search,
                    start_date: this.props.start_date,
                    end_date: this.props.end_date,
                    first: this.state.first,
                    last: this.state.last,
                }}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">{i18next.t("ClearAdvanceList:Number")}</th>
                                        <th className="text-center">{i18next.t("ClearAdvanceList:Recording Date")}</th>
                                        <th>{i18next.t("ClearAdvanceList:Transaction")}</th>
                                        <th className="text-right">{i18next.t("ClearAdvanceList:Amount")}</th>
                                        <th className="text-right">{i18next.t("ClearAdvanceList:Total Amount")}</th>
                                        <th className="text-right">{i18next.t("ClearAdvanceList:Refund")}</th>
                                        <th className="text-right">{i18next.t("ClearAdvanceList:Additional Withdrawal")}</th>
                                        <th className="text-center">{i18next.t("ClearAdvanceList:Reference")}</th>
                                        <th className="text-center">{i18next.t("ClearAdvanceList:Status")}</th>
                                        {/* <th className="text-center">ออกโดย</th> */}
                                        <th className="text-center">{i18next.t("ClearAdvanceList:Document Reference")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.allClearAdvance.edges.map((clear_advance, index) => {
                                        return (
                                            clear_advance.node.clearAdvanceTransaction.edges.map((transaction, t_index) => {
                                                let number_of_transaction = clear_advance.node.clearAdvanceTransaction.edges.length;
                                                return (
                                                    <tr key={transaction.node.id}>
                                                        <td className="text-center">
                                                            {clear_advance.node.status === "VOID" ?
                                                                clear_advance.node.docNumber :
                                                                <Link
                                                                    to={"/accounting/expense/advance/clear-advance/edit/" + clear_advance.node.id}
                                                                    >{clear_advance.node.docNumber}
                                                                </Link>
                                                            }
                                                        </td>
                                                        <td className="text-center">{format(clear_advance.node.issuedDate, 'DD/MM/YYYY')}</td>
                                                        <td>{transaction.node.description}</td>
                                                        <td className="text-right">{numberWithComma(transaction.node.price)}</td>
                                                        <td className="text-right">{t_index === number_of_transaction - 1 && numberWithComma(clear_advance.node.advance.amount)}</td>
                                                        <td className="text-right">{numberWithComma(clear_advance.node.returnPrice)}</td>
                                                        <td className="text-right">{numberWithComma(clear_advance.node.overPrice)}</td>
                                                        <td className="text-center">{clear_advance.node.advance.docNumber}</td>
                                                        <React.Fragment>
                                                            {this.getColorAndText(clear_advance)}
                                                        </React.Fragment>
                                                        {/* <td className="text-center">ออกโดย</td> */}
                                                        <td className="text-center">
                                                            {transaction.node.refClearAdvanceTransactionDoc ?
                                                                <a download="name_of_downloaded_file"
                                                                   href={transaction.node.refClearAdvanceTransactionDoc}
                                                                   target={"_blank"}>
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + '/images/icons/download-file-icon.png'}
                                                                        alt="back"
                                                                        className="download-file-icon"/>
                                                                </a>
                                                                :
                                                                "-"
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                            last={this.state.last}
                                            totalCount={props.allClearAdvance.totalCount}/>
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}


export default ClearAdvanceListTable;
