import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import AssetList from "./assetList"
import AssetCreateUpdate from "./assetCreateOrUpdate"
import AssetDetail from './assetCreateUpdate/assetDetail';
import AssetSold from './assetCreateUpdate/assetSold'
import AssetDeteriorate from './assetCreateUpdate/assetDeteriorate';
import AssetDisappear from './assetCreateUpdate/assetDisappear';
import assetPrintQrCode from './assetPrintQrCode';
import AssetPrintQrCodeSelected from './assetPrintQrCodeSelected';

class Account extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/:asset_type/form/:docNumber/:id?`} component={AssetDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/:status?/sold/:id?`} component={AssetSold}/>
                <PrivateRoute exact path={`${this.props.match.url}/:status?/deteriorate/:id?`} component={AssetDeteriorate}/>
                <PrivateRoute exact path={`${this.props.match.url}/:status?/disappear/:id?`} component={AssetDisappear}/>
                <PrivateRoute exact path={`${this.props.match.url}/:asset_type/form/:id?`} component={AssetCreateUpdate}/>
                <PrivateRoute exact path={`${this.props.match.url}/:asset_type`} component={AssetList}/>
                <PrivateRoute exact path={`${this.props.match.url}/assetPrintQrCode/selected`} component={AssetPrintQrCodeSelected}/>
                <PrivateRoute exact path={`${this.props.match.url}/assetPrintQrCode/:assetPrintQrCode`} component={assetPrintQrCode}/>
                
                
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Account;