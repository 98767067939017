import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link, Redirect } from "react-router-dom";
import { Translation } from "react-i18next";
import _ from 'lodash';
import SearchSelect from "../../libs/searchSelect";
import API from '../../api/silverGraund'
import API2 from '../../api/silverGraund2'
import jwt from 'jwt-decode'
import { format } from "date-fns";
import upload from '../../libs/upload'
import SettingTopMenuNavigation from '../settingTopMenuNavigation';
import i18next from 'i18next';

let fileIsSel = false

class VisitMangeAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            brandAll: [],
            province: [],
            homeAll: [],
            codeVisit: null,
            fullnameVisit: "",
            idCard: "",
            phone: "",
            file: [],
            carRegistration: "",
            colorCar: "",
            yearCar: "",
            sizeCar: "",
            versionCar: "",
            provinceCar: "",
            typeTrackandCar: "sedan",
            typeperson: "person",
            isCarPacking: false,
            codeSticker: "",
            //  0=personal 1=contact 2=appointment 3=resident 4=tenant,
            typeRole: "temp", //perm=หลายครั้ง temp=ครั้งเดียว
            homeId: "",
            reason: "",
            reasonOther: "",
            dateUsingSt: "",
            timeUsingSt: "",
            dateUsingEn: "",
            timeUsingEn: "",
            calPacking: false,
            ceateBy: "",
            DateBooking: "",
            TimeBooking: "",
            brandCar: "",
            brandName: "",
            fileIpload: [],
            timeNows: new Date().getFullYear() + '-' +
                ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' +
                ('0' + new Date().getDate()).slice(-2),
            redirect: false,
            settingSecuritySystem: true,
            settingParkingFeeAccount: true,
            settingParkingFeeEstamp: true,
            settingParkingFeeCalculate: true
        }
        // this.setStates = this.setStates.bind(this)
        this.chkIspacking = this.chkIspacking.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.keyWord = this.keyWord.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.dropDownChanged = this.dropDownChanged.bind(this)
    }

    componentWillMount() {
        try {
            this.setState({ province: require('./json/provice.json') });
            this.setState({ brandAll: require('./json/brand.json') });
            this.getHomeAll()
        } catch (err) {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Unable to Connect to API at this Time"), 'error')
        }

    }

    componentDidMount(){
        this.getAllSettingGuard()
    }

    getHomeAll() {
        API.getHomeAll().then((ress) => {
            if (ress.data) {
                let mapHome = []
                ress.data.allResidential.map((result) => {
                    let homeIdS = {}
                    _.set(homeIdS, 'node.homeId', result.homeId);
                    _.set(homeIdS, 'node.name', result.name);
                    return mapHome.push(homeIdS)
                })
                this.setState({ homeAll: mapHome })
            }
        }, () => Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Unable to Connect to API at this Time"), 'error'))
    }

    getAllSettingGuard(){ 
        API2.getAllSettingGuard().then((res) => {
         if(res.data){
           this.setState({
             settingSecuritySystem: res.data.settingManagement.settingSecuritySystem,
             settingParkingFeeAccount: res.data.settingManagement.settingParkingFeeAccount,
             settingParkingFeeEstamp: res.data.settingManagement.settingParkingFeeEstamp,
             settingParkingFeeCalculate: res.data.settingManagement.settingParkingFeeCalculate
           })
         }}).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Saved Unsuccessful"), 'error'))
     }

    dropDownChanged(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    keyWord(e) {
        let event = _.cloneDeep(e)
        let dataName = ""
        let dataValu = ""
        if (event.target.name === "dateUsingSt" || event.target.name === "dateUsingEn") {
            let selctTime = new Date(event.target.value).getTime()
            let TimeNow = new Date(this.state.timeNows).getTime()
            let SelctTimeSt = new Date(this.state.dateUsingSt).getTime()
            if (selctTime < TimeNow) {
                event.target.value = ""
                dataName = event.target.name
                dataValu = ""
            } else if (selctTime < SelctTimeSt) {
                event.target.value = ""
                dataName = event.target.name
                dataValu = ""
            } else {
                dataName = event.target.name
                dataValu = event.target.value
            }
        } else {
            dataName = event.target.name
            dataValu = event.target.value
        }
        this.setState({ [dataName]: dataValu })

    }

    chkIspacking(e) {
        let event = _.cloneDeep(e)
        if (event.target.name == 'calPacking') {
            if (event.target.checked) {
                this.setState({ calPacking: true })
            } else {
                this.setState({ calPacking: false })
            }
        } else {
            if (event.target.checked) {
                this.setState({ isCarPacking: true })
            } else {
                this.setState({ isCarPacking: false })
            }
        }

    }

    dateFile = (index, data) => {
        // let cloneDataFile = _.cloneDeep(this.state.fileUpload)
        let DataFile = _.cloneDeep(this.state.file)
        let fileupload = []

        // const index_data_file = DataFile.findIndex(ex => ex.fileId === data.fileId)
        _.set(DataFile, "[" + index + "].status", "delete")
        fileupload = _.get(DataFile, "[" + index + "]")

        if (DataFile.findIndex(ex => ex.fileId === fileupload.fileId) < 0) {
            DataFile.push(fileupload)

        }

        if (data.status === '') {
            this.setState({ file: DataFile })
        }
    }

    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        this.state.fileIpload.push(files)
        if (files.size > 2097152) {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
        } else {
            fileIsSel = false
            // console.log(files);
            let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
            let fileOldList = _.cloneDeep(this.state.file);
            // console.log(fileOldList);
            this.callUpload('', new_file).then((data) => {
                let dese = "vms/file/" + new_file.name
                fileOldList.push({ fileId: "", fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location })
                this.setState({ file: fileOldList })
            });
        }
    }

    onError = () => {
        this.setState({
            src: process.env.PUBLIC_URL + "/images/icons/select-photo.png",
            errored: true,
        });
    }

    async onSubmit(e) {
        e.preventDefault()
        // console.log('this.state.file ',this.state.file[0]);
        // let fileData = []


        // let new_file = new File([item.node.fileUpload], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${item.node.fileName}`);
        // let data = await upload(`/contract_expense/contract`, new_file)
        const user = jwt(localStorage.getItem('token'))
        let data = {
            "creatorType": "juristic_wab",
            "tool": this.state.typeperson === "tenant" && this.state.typeRole === "perm" ? "LPR" : "QR Code",
            "carTransactionId": "",
            "carId": "",
            "fullnameVisit": this.state.fullnameVisit,
            "idCard": this.state.idCard,
            "idCardImage": "",
            "phone": this.state.phone,
            "carRegistration": this.state.carRegistration,
            "province": this.state.provinceCar,
            "typeTrackandCar": this.state.typeTrackandCar,
            "brandCar": this.state.brandCar === "other" ? this.state.brandName : this.state.brandCar,
            "versionCar": this.state.versionCar,
            "colorCar": this.state.colorCar,
            "isPacking": this.state.isCarPacking ? true : false,
            "typePerson": this.state.typeperson,
            "typeRole": this.state.typeRole,
            "homeId": parseInt(this.state.homeId),
            "dateUsingSt": format(this.state.dateUsingSt, "DD/MM/YYYY"),
            "timeUsingSt": this.state.timeUsingSt,
            "dateUsingEn": format(this.state.dateUsingEn, "DD/MM/YYYY"),
            "timeUsingEn": this.state.timeUsingEn,
            "codeSticker": this.state.codeSticker,
            "calPacking": this.state.calPacking ? true : false,
            "reason": this.state.reason === "other" ? this.state.reasonOther : this.state.reason,
            "descriptionCar": "",
            "yearCar": this.state.yearCar,
            "sizeCar": this.state.sizeCar,
            "status": this.state.isCarPacking == true ? "park" : "coming",
            "createBy": user.is_staff ? user.first_name + " " + user.last_name : this.state.homeId,
            "file": this.state.file
            //{ "fileId": "", "fileName": "คู่มือ", "filePath": "project/logo/Pimancondo-Park-logo.jpeg", "status": "" },
            //{ "fileId": "", "fileName": "เอกสาร", "filePath": "project/logo/Pimancondo-Park-logo.jpeg", "status": "" }
        }



        await API.addVisitorVMS(data).then((res) => {
            if (res.data.statusMessage === "SUCCESS") {
                Swal.fire(i18next.t("Allaction:Saved Successfully"), i18next.t("Allaction:Saved Successfully"), 'success').then(() => {
                    this.setState({ redirect: true })
                })
            }else if(res.data.statusMessage === "service error"){
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("settingToolandConnect:Technow is experiencing an issue. Please contact the administrator."), 'error') 
            } else if(res.data.statusMessage === "duplicate"){
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("silvermanguard:Cannot create a contact card because this vehicle is already registered"), 'error') 
            } else {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error')
            }
        }, () => {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/vms/file" + path, file);
            resolve(res);
        });
    };



    render() {
        const {settingSecuritySystem,settingParkingFeeAccount,settingParkingFeeEstamp,settingParkingFeeCalculate} = this.state
        if (!settingSecuritySystem && !settingParkingFeeAccount && !settingParkingFeeEstamp && !settingParkingFeeCalculate) {
             Swal.fire('ไม่ได้ตั้งค่า', 'กรุณาติดต่อนิติเพื่อตั้งค่างานจัดเก็บค่าที่จอดรถ', 'error') 
        }
        if (this.state.redirect) {
            return <Redirect to="/silvermanguard/visitormanage/all" />
        }


        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <SettingTopMenuNavigation mini={true}  />
                    <Translation>
                        {
                            t =>
                                <div className="container-fluid box" id="silvermanguard">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/silvermanguard/visitormanage/all">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t('graud:visitormanagecreate')}</span>
                                            </h3>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <form onSubmit={this.onSubmit}>
                                            <div className='col-10 mx-auto'>
                                                {/* ข้อมูลทั่วไป */}
                                                <div>
                                                    <br /><br />
                                                    <h3 >{t("silvermanguard:GeneralInformation")}</h3>
                                                    <hr />
                                                    <label style={{ marginTop: '24px' }}>{t("silvermanguard:nameVisitor")} <span className='text-red'>*</span></label>
                                                    <input type="text" name='fullnameVisit' className='form-control' placeholder={i18next.t("visitorManage:Name & Surname of visitor")}
                                                        defaultValue={this.state.fullnameVisit} required={true} onChange={this.keyWord}
                                                        onKeyUp={this.keyWord} pattern='[A-Za-zก-๏ ]+$'
                                                    />

                                                    <label>{t("silvermanguard:idCard")}<span className='text-red'>*</span></label>
                                                    <input type="text" name='idCard' className='form-control' placeholder='x-xx-xxx-xxxx-xx-x'
                                                        defaultValue={this.state.idCard} pattern='[0-9]{13}' maxLength={13} minLength={13}
                                                        required={true} onChange={this.keyWord} onKeyUp={this.keyWord} />

                                                    <label>{t("silvermanguard:phone")} <span className='text-red'>*</span></label>
                                                    <input type="text" name='phone' className='form-control' placeholder={i18next.t("visitorManage:Contact number")}
                                                        defaultValue={this.state.phone} required={true} onChange={this.keyWord} onKeyUp={this.keyWord}
                                                        pattern='[0-9]{10}' minLength={10} maxLength={10}
                                                    />


                                                    <div style={{ display: 'inline-flex' }}>
                                                        <h3 style={{ marginTop: '40px' }}>{t("silvermanguard:file")} </h3>
                                                        <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                            <button className="btn"><img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} alt="fileUpload"/>{t("silvermanguard:Choose File")} </button>
                                                            <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>{t("silvermanguard:Max2MB")} </span>

                                                            <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder={i18next.t("visitorManage:Contact number")} accept='.pdf,.png,.jpg'
                                                                onChange={this.uploadFile.bind(this)} />
                                                            {
                                                                fileIsSel &&
                                                                <p className='text-red'>{t("silvermanguard:fileUpload")}</p>
                                                            }
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.file.length > 0 &&
                                                        this.state.file.map((n, inx) => {
                                                            if (n.status === "") {
                                                                return (
                                                                    <p key={inx}>
                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} style={{ width: '30px' }} /> &nbsp;
                                                                        <Link
                                                                            to="route"
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                window.open(
                                                                                    typeof n.viewPtath === "string"
                                                                                        ? n.viewPtath
                                                                                        : URL.createObjectURL(n.viewPtath)
                                                                                )
                                                                            }}
                                                                            target="blank">{n.fileName}</Link>
                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, n)}>X</label>
                                                                    </p>
                                                                )
                                                            }

                                                        })
                                                    }
                                                </div>


                                                {/* ข้อมูลรถ */}
                                                <div>
                                                    <h3 style={{ marginTop: '58px' }}>{t("silvermanguard:CarInformation")} </h3>
                                                    <hr />

                                                    <label style={{ marginTop: '24px' }}>{t("silvermanguard:CarRegis")}  <span className='text-red'>*</span></label>
                                                    <input type="text" name='carRegistration' className='form-control'
                                                        defaultValue={this.state.carRegistration} required={true}
                                                        onChange={this.keyWord} onKeyUp={this.keyWord}
                                                        placeholder={`${i18next.t("visitorManage:For example")} 1กก5525 / กก1111 / 71-1111 / กกฟ952`} />

                                                    <label>{t("silvermanguard:Province")}  <span className='text-red'>*</span></label>
                                                    <select className='form-control' name='provinceCar' required={true}
                                                        value={this.state.provinceCar} onChange={this.dropDownChanged}>
                                                        <option disabled value={""}>-{t("Allaction:choose")}-</option>
                                                        {
                                                            this.state.province.map((n) => {
                                                                return (
                                                                    <React.Fragment key={n.node.PROVINCE_ID}>
                                                                        <option value={n.node.PROVINCE_NAME}>{n.node.PROVINCE_NAME}</option>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }

                                                    </select>


                                                    <label>{t("silvermanguard:TypeCar")}</label><br />
                                                    <div style={{ display: 'inline-flex' }}>
                                                        <div className='divTypeCar ' onClick={() => this.setState({ typeTrackandCar: "sedan" })}>
                                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -02.png'} alt="cars" />
                                                            {
                                                                this.state.typeTrackandCar === "sedan" &&
                                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Blues.png'} className='selected' alt="Blues"/>
                                                            }

                                                            <p>{t("silvermanguard:car")} </p>
                                                        </div>

                                                        <div className='divTypeCar' onClick={() => this.setState({ typeTrackandCar: "pickup" })}>
                                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -03.png'} alt="cars"/>
                                                            {
                                                                this.state.typeTrackandCar === "pickup" &&
                                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Blues.png'} className='selected' alt='blues'/>
                                                            }
                                                            <p>{t("silvermanguard:pickup")}</p>
                                                        </div>

                                                        <div className='divTypeCar' onClick={() => this.setState({ typeTrackandCar: "van" })}>
                                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -04.png'} />
                                                            {
                                                                this.state.typeTrackandCar === "van" &&
                                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Blues.png'} className='selected' />
                                                            }
                                                            <p>{t("silvermanguard:van")}</p>
                                                        </div>

                                                        <div className='divTypeCar' onClick={() => this.setState({ typeTrackandCar: "taxi" })}>
                                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -05.png'} />
                                                            {
                                                                this.state.typeTrackandCar === "taxi" &&
                                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Blues.png'} className='selected' />
                                                            }
                                                            <p>{t("silvermanguard:taxi")}</p>
                                                        </div>

                                                        <div className='divTypeCar' onClick={() => this.setState({ typeTrackandCar: "motorcy" })}>
                                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/motor.png'} />
                                                            {
                                                                this.state.typeTrackandCar === "motorcy" &&
                                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Blues.png'} className='selected' />
                                                            }
                                                            <p>{t("silvermanguard:motocy")}</p>
                                                        </div>

                                                        <div className='divTypeCar' onClick={() => this.setState({ typeTrackandCar: "motorcycle" })}>
                                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/motor - BB.png'} />
                                                            {
                                                                this.state.typeTrackandCar === "motorcycle" &&
                                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconSelected-Blues.png'} className='selected' />
                                                            }
                                                            <p>{t("silvermanguard:motocyBB")}</p>
                                                        </div>


                                                    </div>

                                                    <label style={{ marginTop: '60px' }}>{t("silvermanguard:brandCar")}<span className='text-red'>*</span></label>
                                                    <select className='form-control' required={true}
                                                        name='brandCar' value={this.state.brandCar}
                                                        onChange={this.dropDownChanged}>
                                                        <option disabled value={""}>-{t("Allaction:choose")}-</option>
                                                        {
                                                            this.state.brandAll.map((n, idx) => {
                                                                return (
                                                                    <React.Fragment key={idx}>
                                                                        <option value={n.brandCode} >{n.brandName}</option>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </select>

                                                    {
                                                        this.state.brandCar === "other" &&
                                                        <React.Fragment>
                                                            <label>{t("silvermanguard:InputBrandCar")}<span className='text-red'>*</span></label>
                                                            <input type="text" name='brandName' className='form-control'
                                                                defaultValue={this.state.brandName} onChange={this.keyWord} onKeyUp={this.keyWord}
                                                                required={true} />
                                                        </React.Fragment>
                                                    }

                                                    {/* <label>รุ่น <span className='text-red'>*</span></label>
                                        <input type="text" name='versionCar' className='form-control' placeholder='กรอก'
                                            defaultValue={this.state.versionCar} onChange={this.keyWord} onKeyUp={this.keyWord}
                                            required={true} /> */}


                                                    <label>{t("silvermanguard:ColorCar")}<span className='text-red'>*</span></label>
                                                    <input type="text" name='colorCar' className='form-control' placeholder={t("silvermanguard:ColorCar")}
                                                        defaultValue={this.state.colorCar} onChange={this.keyWord} onKeyUp={this.keyWord}
                                                        required={true} />


                                                    {/*

                                        <label>ปี</label>
                                        <input type="text" name='yearCar' className='form-control' placeholder='กรอก'
                                            defaultValue={this.state.yearCar} onChange={this.keyWord} onKeyUp={this.keyWord} />

                                        <label>ขนาด</label>
                                        <input type="text" name='sizeCar' className='form-control' placeholder='กรอก'
                                            defaultValue={this.state.sizeCar} onChange={this.keyWord} onKeyUp={this.keyWord} /> */}

                                                    {/* <br /> */}

                                                    {
                                                        this.state.typeperson === "tenant" &&
                                                        <React.Fragment>
                                                            <label>{t("silvermanguard:codeticket")} </label><br />
                                                            <input type="text" name='codeSticker' className='form-control'
                                                                placeholder={t("silvermanguard:codeticket")} defaultValue={this.state.codeSticker}
                                                                onChange={this.keyWord} onKeyUp={this.keyWord} />
                                                        </React.Fragment>

                                                    }

                                                </div>


                                                {/* สิทธิ์การใช้งาน */}
                                                <div>
                                                    <h3 style={{ marginTop: '36px' }}>{t("silvermanguard:role")}</h3>
                                                    <hr />

                                                    <label style={{ marginTop: '24px' }}>{t("silvermanguard:typePerson")} </label><br />
                                                    <label className="containerRadio">{t("silvermanguard:tenant")}
                                                        <input type="radio" checked={this.state.typeperson === "tenant"}
                                                            onChange={() => this.setState({ typeperson: "tenant", typeRole: "perm" })} name='typePerson' />
                                                        <span className="checkmark"></span>
                                                    </label>

                                                    <label style={{ marginTop: '13px', marginLeft: '35px' }}>{t("silvermanguard:FrisPacking")}</label>
                                                    <label className="containerRadio" style={{ marginLeft: '35px' }}>{t("silvermanguard:isPacking")}
                                                        <input type="checkbox" name="isCarPacking" onChange={this.chkIspacking}
                                                            checked={this.state.isCarPacking == true} disabled={this.state.typeperson !== "tenant"} />
                                                        <span className="checkmarkCheckBox"></span>
                                                    </label>

                                                    <label className="containerRadio">{t("silvermanguard:contact")}
                                                        <input type="radio" name='typePerson' checked={this.state.typeperson === "contact"}
                                                            onChange={() => this.setState({ typeperson: "contact", typeRole: "temp", isPacking: null })} />
                                                        <span className="checkmark"></span>
                                                    </label>

                                                    <label className="containerRadio">{t("silvermanguard:appointment")} {/*ผู้ติดต่อนัดหมายล่วงหน้า (ผู้เยี่ยมเยือน)*/}
                                                        <input type="radio" name='typePerson' checked={this.state.typeperson === "appointment"}
                                                            onChange={() => this.setState({ typeperson: "appointment", typeRole: "temp", isPacking: null })} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="containerRadio">{t("silvermanguard:person")}                                                         <input type="radio" name='typePerson' checked={this.state.typeperson === "person"}
                                                        onChange={() => this.setState({ typeperson: "person", typeRole: "temp", isPacking: null })} />
                                                        <span className="checkmark"></span>
                                                    </label>


                                                    <label style={{ marginTop: '24px' }}>{t("silvermanguard:typeRole")} </label><br />
                                                    <label className="containerRadio">{t("silvermanguard:temp")}
                                                        <input type="radio" name="typeRole"
                                                            checked={this.state.typeRole === "temp"}
                                                            onChange={() => this.setState({ typeRole: "temp" })} />
                                                        <span className="checkmark"></span>
                                                    </label>

                                                    <label className="containerRadio">{t("silvermanguard:perm")} {/*ใช้ได้หลายครั้ง (มีวันหมดอายุ)*/}
                                                        <input type="radio" name="typeRole"
                                                            checked={this.state.typeperson === "tenant" && this.state.typeRole === "perm"}
                                                            disabled={this.state.typeperson !== "tenant"}
                                                            onChange={() => this.setState({ typeRole: "perm" })} />
                                                        <span className="checkmark"></span>
                                                    </label>


                                                    {
                                                        this.state.typeperson === "contact" &&
                                                        <div className='boxAlert' style={{ width: '440px' }}>
                                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                            <span>{t("silvermanguard:contact")} {t("silvermanguard:oneTimeOnly")}</span>
                                                            &nbsp;
                                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                                        </div>

                                                        ||

                                                        this.state.typeperson === "appointment" &&
                                                        <div className='boxAlert' style={{ width: '561px' }}>
                                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                            <span>{t("silvermanguard:appointment")} {t("silvermanguard:oneTimeOnly")}</span>
                                                            &nbsp;
                                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                                        </div>

                                                        ||

                                                        this.state.typeperson === "person" &&
                                                        <div className='boxAlert' style={{ width: '561px' }}>
                                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                            <span>{t("silvermanguard:person")} {t("silvermanguard:oneTimeOnly")}</span>
                                                            &nbsp;
                                                            <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                                        </div>

                                                    }
                                                    {
                                                        this.state.typeperson === "tenant" &&

                                                        <React.Fragment>
                                                            <label style={{ marginTop: '42px' }}>{t("silvermanguard:HomeId")} <span className='text-red'>*</span>
                                                            </label><br />
                                                            <SearchSelect className="selectBox"
                                                                onChange={this.dropDownChanged}
                                                                value={this.state.homeId}
                                                                name="homeId" placeholder="รายการ"
                                                                queryObject={this.state.homeAll}
                                                                keyOfValue="homeId"
                                                                require={true}
                                                                keyOfLabel="name" />
                                                        </React.Fragment>

                                                    }
                                                    {
                                                        (this.state.typeperson === "appointment" || this.state.typeperson === "person") &&
                                                        <React.Fragment>
                                                            <label style={{ marginTop: '42px' }}>{t("silvermanguard:HomeIdContact")} <span className='text-red'>*</span></label><br />
                                                            <SearchSelect
                                                                onChange={this.dropDownChanged}
                                                                value={this.state.homeId}
                                                                name="homeId" placeholder="รายการ"
                                                                queryObject={this.state.homeAll}
                                                                keyOfValue="homeId"
                                                                require={true}
                                                                keyOfLabel="name" />
                                                        </React.Fragment>

                                                    }

                                                    {
                                                        (this.state.typeperson === "appointment" || this.state.typeperson === "person") &&
                                                        <React.Fragment>
                                                            <label>{t("silvermanguard:Object")} <span className='text-red'>*</span></label><br />
                                                            <input type="text" name='reason' required={true}
                                                                className='form-control' placeholder={t("silvermanguard:Object")}
                                                                defaultValue={this.state.reason}
                                                                onChange={this.keyWord} onKeyUp={this.keyWord} />
                                                        </React.Fragment>
                                                    }

                                                    {
                                                        this.state.typeperson === "contact" &&
                                                        <React.Fragment>
                                                            <label>{t("silvermanguard:Object")} <span className='text-red'>*</span></label><br />
                                                            <select name='reason' 
                                                                className='form-control' value={this.state.reason} onChange={this.dropDownChanged}
                                                                required={true}>
                                                                <option value={""} disabled={true}>-{t("Allaction:choose")}-</option> 
                                                                <option value={"เยี่ยมชมโครงการล่วงหน้า"}>{i18next.t("visitorManage:Visit the project in advance")}</option> 
                                                                <option value={"เยี่ยมชมโครงการ"}>{i18next.t("visitorManage:Visit the project without an appointment")}</option> 
                                                                <option value={"ผู้รับเหมา"}>{i18next.t("visitorManage:Contractor")}</option> 
                                                                <option value={"ติดต่อเจ้าหน้าที่"}>{i18next.t("visitorManage:Contact staff")}</option> 
                                                                <option value={"other"}>{i18next.t("visitorManage:Other (you can specify your own reason)")}</option> 
                                                            </select>
                                                        </React.Fragment>
                                                    }

                                                    {
                                                        this.state.reason === "other"  &&
                                                        <React.Fragment>
                                                            <label>{t("silvermanguard:InputObjects")} <span className='text-red'>*</span></label><br />
                                                            <input type="text" name='reasonOther' required={true}
                                                                className='form-control' placeholder={t("silvermanguard:InputObjects")}
                                                                defaultValue={this.state.reasonOther}
                                                                onChange={this.keyWord} onKeyUp={this.keyWord} />
                                                        </React.Fragment>
                                                    }
                                                </div>



                                                <div style={{ display: 'inline-flex' }}>
                                                    <div style={{ width: '254px' }}>
                                                        <label>{t("silvermanguard:DateUse")} <span className='text-red'>*</span></label><br />
                                                        <input type="date"
                                                            className='form-control' placeholder={t("silvermanguard:DateUse")}
                                                            min={this.state.timeNows} required={true}
                                                            defaultValue={this.state.dateUsingSt} name='dateUsingSt'
                                                            onChange={this.keyWord} onKeyUp={this.keyWord} />
                                                    </div>
                                                    <div style={{ width: '254px' }}>
                                                        <label>{t("silvermanguard:TimeUse")} <span className='text-red'>*</span></label><br />
                                                        <input type="time" className='form-control' placeholder={t("silvermanguard:TimeUse")}
                                                            min={this.state.dateUsingSt} required={true}
                                                            defaultValue={this.state.timeUsingSt} name='timeUsingSt'
                                                            onChange={this.keyWord} onKeyUp={this.keyWord} />
                                                    </div>
                                                </div>





                                                <br />
                                                <div style={{ display: 'inline-flex' }}>
                                                    <div style={{ width: '254px' }}>
                                                        <label>{t("silvermanguard:DateEnd")} <span className='text-red'>*</span></label><br />
                                                        <input type="date" className='form-control' min={this.state.dateUsingSt}
                                                            placeholder={t("silvermanguard:DateEnd")} defaultValue={this.state.dateUsingEn} name='dateUsingEn'
                                                            required={true} onChange={this.keyWord} onKeyUp={this.keyWord} />
                                                    </div>
                                                    <div style={{ width: '254px' }}>
                                                        <label>{t("silvermanguard:TimeEnd")} <span className='text-red'>*</span></label><br />
                                                        <input type="time" className='form-control' placeholder={t("silvermanguard:TimeEnd")}
                                                            defaultValue={this.state.timeUsingEn} name='timeUsingEn' required={true}
                                                            onChange={this.keyWord} onKeyUp={this.keyWord} />
                                                    </div>
                                                </div>



                                                <br />
                                                <label className="containerRadio">{t("silvermanguard:isCalculatePacking")}
                                                    <input type="checkbox" name="calPacking" checked onClick={this.chkIspacking} disabled />
                                                    <span className="checkmarkCheckBox" style={{ backgroundColor:'#D9D9D9' ,borderColor:'#D9D9D9'}} ></span>
                                                </label>


                                                <div className='row mt-2'>
                                                    <div className='col-12 text-right'>
                                                        <button type='submit' className='btn btn-primary'>{t("Allaction:save")}</button>
                                                    </div>
                                                </div>


                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />



                                            </div>
                                        </form>

                                    </div>
                                </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default VisitMangeAdd;
