import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from "i18next"
import API from '../../api/silverGraund2'
import Loading from '../../libs/loading';
import './settingWalkin.scss'
import i18next from 'i18next';

class settingWalkin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            check_type: false,
            typeWalkin:'',
            typeWalkinCheck:'',
            qrCode:'',
            showQRcode: false,
            showButton: true,
            hideLong: true,
            hideShort: true,
            loading: true
        }
       
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);        
    }

    handleInputChange(e) {
        this.setState({
            typeWalkin: e.target.value,
            [e.target.name]: e.target.value
          });
    }

    componentDidMount(){
         this.getWalkInSetting()
         this.getQRCodePerSite()
    }

    getQRCodePerSite() {
        API.getQRCodePerSite().then((res) => {
            if(res.data){
                this.setState({ 
                    qrCode: res.data.qrcode,
                })
            }
        })
    }

    
    

    
    getWalkInSetting() {

        API.getWalkInSetting().then((res) => {
            this.setState({loading: true})
           if(res.data.guardSettingManagement){
                if(res.data.guardSettingManagement?.management === 'short'){
                    this.setState({ 
                        showQRcode: true,
                        hideLong:false
                    })
                }else if(res.data.guardSettingManagement?.management === 'long'){
                    this.setState({ 
                        hideShort:false
                    })
                }
                this.setState({ 
                    typeWalkin: res.data.guardSettingManagement?.management,
                    typeWalkinCheck:res.data.guardSettingManagement?.management,
                    showButton:false,
                    loading: true
                })
           }
        }, () => {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error')
        })
    }

    submit(e) {
        e.preventDefault();
        let data = {
            "management" : this.state.typeWalkin
        }
        Swal.fire({
            title: i18n.t("settingWalking:Please verify that the information is correct because it will not be editable after saving"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18n.t("settingWalking:Cancel"),
            confirmButtonText:i18n.t("settingWalking:Save")
        }).then((result) => {
            if (result.value) {
                API.addGuardSettingManagement(data).then((res) => {
                    if (res.data.statusMessage === "SUCCESS") {
                        Swal.fire(i18n.t("settingAccount:Save successfully"), '', 'success').then(() => {
                            this.setState({
                                showQRcode: true,
                                showButton: false,
                                loading: false
                            },() =>{
                                window.location.reload()
                            });
                        });
                    }
                }, () => {
                    Swal.fire(i18n.t("Allaction:Saved Unsuccessful"), i18n.t("settingAccount:Failed to save"), 'error')
                })
            }
        })

       
    }

    render() {
        let lang = localStorage.getItem("language")
        return (
            <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent>
            <Translation>
                    {
                        t => 
                        <div className="container-fluid box" id="setting-walkin">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/setting">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back"/>
                                        </Link>
                                        <Translation>
                                            {
                                                t => <span style={{fontSize:'20px'}}>{t('settingWalking:Collection on visitor information setting')}</span>
                                            }
                                        </Translation>
                                    </h3>
                                </div>
                            </div>
                        {this.state.loading ? 
                        <div>                  
                            <div className='ml-8 mb-4'>
                                <Translation>
                                    {
                                        t => <h4>{t('settingWalking:Please select on how to collect visitor information for your project')}</h4> 
                                    }
                                </Translation>
                              <div className='row'>
                                
                                    {
                                        this.state.hideShort ? 
                                        <div className='col-4'>
                                        <div className="form-check m-2 pb-2">
                                            <input className="form-check-input" type="radio"
                                                name="typeWalkin" 
                                                value="short" checked={this.state.typeWalkin === 'short' ? 'checked' :''}
                                                onChange={this.handleInputChange} 
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeAll"> 
                                                <Translation>
                                                    {
                                                        t => <p>{t('settingWalking:Collection on visitor information in brief (Less information)')}</p> 
                                                    }
                                                </Translation>
                                            </label>
                                        </div>  </div> :''
                                    }
                                    {
                                        this.state.hideLong ? 
                                        <div className='col'>
                                         <div className="form-check m-2 pb-2">
                                         <input className="form-check-input" type="radio"
                                             name="typeWalkin" checked={this.state.typeWalkin === 'long' ? 'checked' :''}
                                             value="long" 
                                             onChange={this.handleInputChange} 
                                             />
                                             <label className="form-check-label" htmlFor="checkTypeAll"> 
                                             <Translation>
                                                    {
                                                        t => <p>{t('settingWalking:Collection on visitor information in full (More information)')}</p>
                                                    }
                                            </Translation>
                                         </label>
                                     </div> </div> :''
                                    }
                              </div>
                              <div className='row'>
                              <div className='col-12'>
                                 <span className='text-danger'>*</span>
                                 <Translation>
                                     {
                                        t => <span className='text-danger'>{t('settingWalking:If selecting the brief version, data will be recorded using pictures for added convenience and speed')}</span> 
                                     }
                                 </Translation>
                               </div>
                               <div className='col text-right'>
                                {
                                   this.state.typeWalkin === 'short' && this.state.showQRcode ? 
                                   <>
                                    <div className="tooltipImg" style={{left:'-1%'}}>
                                        <span className="tooltiptext" style={{width:'180px'}}>
                                            ใช้สำหรับให้ผู้มาติดต่อ<br/>
                                            สแกนเมื่อเข้าโครงการ
                                        </span>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                alt="question" className="question ml-1" style={{width:'12px',marginBottom:'10px'}}/>
                                    </div>
                                    <Link to={{ pathname:"/setting/setting-walkin/qrcode",
                                          state:this.state.qrCode}}
                                    >
                                        <button className="btn btn-primary add mr-2">
                                            <img src={process.env.PUBLIC_URL + '/images/icons/printer-white.png'} alt="print-icon" 
                                            className="mr-1" style={{width:'13px' ,height:'13px'}}/> 
                                            <Translation>
                                                {
                                                    t => <span>{t('settingWalking:Print QR Code')}</span> 
                                                }
                                            </Translation>
                                        </button>
                                    </Link> 
                                   </>:''
                                    
                                }
                                 
                               </div>
                              </div>
                            </div>
                            <div>
                                {
                                    this.state.typeWalkin === 'long' ? <img src={process.env.PUBLIC_URL + lang === 'th' ? '/images/setting-walkin/long-thai.png' : '/images/setting-walkin/long-en.png' } style={{width:'100%'}}/> 
                                    :<img src={process.env.PUBLIC_URL + lang === 'th' ? '/images/setting-walkin/short-thai.png' : '/images/setting-walkin/short-en.png' } style={{width:'100%'}}/>
                                }
                            </div>
                            {
                              this.state.showButton && 
                                <div className='row mt-8'>
                                    <div className='col text-right'>
                                        <Link to={{ pathname:"/setting/setting-keep-packing/companyDetail"}}>
                                            <button className="btn btn-cancel mr-5">
                                                <Translation>
                                                    {
                                                        t => <span>{t('settingWalking:Cancel')}</span> 
                                                    }
                                                </Translation>
                                            </button>
                                        </Link>
                                        <button className="btn btn-primary add mr-2" onClick={this.submit}>
                                            <Translation>
                                                {
                                                    t => <span>{t('settingWalking:Save')}</span> 
                                                }
                                            </Translation>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>: <Loading />}

                        </div>
                    }
            </Translation>
            </WrapperContent>
        </Wrapper>
        )
    }
}

export default settingWalkin;
