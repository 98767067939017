/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoomGroupStatus = "CANCLED" | "FINISHED" | "PENDING" | "%future added value";
export type updateMeetingQueryVariables = {|
  search?: ?string,
  roomGroupId?: ?string,
|};
export type updateMeetingQueryResponse = {|
  +allRoomGroup: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +detail: ?string,
        +status: RoomGroupStatus,
        +note: ?string,
        +roomGroupNumberRead: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +count: number,
              +user: {|
                +id: string,
                +juristic: ?{|
                  +id: string,
                  +firstName: string,
                  +lastName: string,
                |},
                +tenant: ?{|
                  +id: string,
                  +firstName: string,
                  +lastName: ?string,
                |},
              |},
            |}
          |}>
        |},
        +roomGroupTag: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +tag: string,
            |}
          |}>
        |},
        +roomGroupImage: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: string,
              +fileUpload: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +allJuristic: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +image: ?string,
        +firstName: string,
        +firstNameEn: ?string,
        +lastName: string,
        +lastNameEn: ?string,
        +role: string,
        +user: ?{|
          +id: string
        |},
      |}
    |}>
  |},
  +currentTenants: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +image: string,
        +firstName: string,
        +lastName: ?string,
        +user: ?{|
          +id: string
        |},
        +householder: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +residential: {|
                +name: string
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
|};
export type updateMeetingQuery = {|
  variables: updateMeetingQueryVariables,
  response: updateMeetingQueryResponse,
|};
*/


/*
query updateMeetingQuery(
  $search: String
  $roomGroupId: String
) {
  allRoomGroup(roomGroupId: $roomGroupId) {
    edges {
      node {
        id
        name
        detail
        status
        note
        roomGroupNumberRead {
          edges {
            node {
              id
              count
              user {
                id
                juristic {
                  id
                  firstName
                  lastName
                }
                tenant {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
        roomGroupTag {
          edges {
            node {
              id
              tag
            }
          }
        }
        roomGroupImage {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
      }
    }
  }
  allJuristic(search: $search) {
    edges {
      node {
        image
        firstName
        firstNameEn
        lastName
        lastNameEn
        role
        user {
          id
        }
        id
      }
    }
  }
  currentTenants(search: $search) {
    edges {
      node {
        id
        image
        firstName
        lastName
        user {
          id
        }
        householder {
          edges {
            node {
              id
              residential {
                name
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roomGroupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "roomGroupId",
      "variableName": "roomGroupId"
    }
  ],
  "concreteType": "RoomGroupNodeConnection",
  "kind": "LinkedField",
  "name": "allRoomGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoomGroupNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoomGroupNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "detail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "note",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RoomGroupNumberReadNodeConnection",
              "kind": "LinkedField",
              "name": "roomGroupNumberRead",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RoomGroupNumberReadNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RoomGroupNumberReadNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserNode",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "JuristicNode",
                              "kind": "LinkedField",
                              "name": "juristic",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TenantNode",
                              "kind": "LinkedField",
                              "name": "tenant",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RoomGroupTagNodeConnection",
              "kind": "LinkedField",
              "name": "roomGroupTag",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RoomGroupTagNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RoomGroupTagNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tag",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RoomGroupImageNodeConnection",
              "kind": "LinkedField",
              "name": "roomGroupImage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RoomGroupImageNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RoomGroupImageNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fileName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fileUpload",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstNameEn",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastNameEn",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateMeetingQuery",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "JuristicNodeConnection",
        "kind": "LinkedField",
        "name": "allJuristic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v4/*: any*/),
                  (v10/*: any*/),
                  (v5/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "TenantNodeConnection",
        "kind": "LinkedField",
        "name": "currentTenants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "householder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResidentialNode",
                                "kind": "LinkedField",
                                "name": "residential",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateMeetingQuery",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "JuristicNodeConnection",
        "kind": "LinkedField",
        "name": "allJuristic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v4/*: any*/),
                  (v10/*: any*/),
                  (v5/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "TenantNodeConnection",
        "kind": "LinkedField",
        "name": "currentTenants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "householder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResidentialNode",
                                "kind": "LinkedField",
                                "name": "residential",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5faa1fee9396bfd34134a708d09661bd",
    "id": null,
    "metadata": {},
    "name": "updateMeetingQuery",
    "operationKind": "query",
    "text": "query updateMeetingQuery(\n  $search: String\n  $roomGroupId: String\n) {\n  allRoomGroup(roomGroupId: $roomGroupId) {\n    edges {\n      node {\n        id\n        name\n        detail\n        status\n        note\n        roomGroupNumberRead {\n          edges {\n            node {\n              id\n              count\n              user {\n                id\n                juristic {\n                  id\n                  firstName\n                  lastName\n                }\n                tenant {\n                  id\n                  firstName\n                  lastName\n                }\n              }\n            }\n          }\n        }\n        roomGroupTag {\n          edges {\n            node {\n              id\n              tag\n            }\n          }\n        }\n        roomGroupImage {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n      }\n    }\n  }\n  allJuristic(search: $search) {\n    edges {\n      node {\n        image\n        firstName\n        firstNameEn\n        lastName\n        lastNameEn\n        role\n        user {\n          id\n        }\n        id\n      }\n    }\n  }\n  currentTenants(search: $search) {\n    edges {\n      node {\n        id\n        image\n        firstName\n        lastName\n        user {\n          id\n        }\n        householder {\n          edges {\n            node {\n              id\n              residential {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '93b55469e1938ff6dafd29f770172922';

module.exports = node;
