import React from "react";
import ReactExport from "react-data-export";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import i18next from "i18next";
import API from '../../api/sing';
import Swal from "sweetalert2";

const query = graphql`
  query fixRequestExceReportQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const multiDataSet = [
//     {
//         columns: ["Name", "Salary", "Sex"],
//         data: [
//             ["Johnson", 30000, "Male"],
//             ["Monika", 355000, "Female"],
//             ["Konstantina", 20000, "Female"],
//             ["John", 250000, "Male"],
//             ["Josef", 450500, "Male"],
//         ]
//     },
//     {
//         xSteps: 1, // Will start putting cell with 1 empty cell on left most
//         ySteps: 5, //will put space of 5 rows,
//         columns: ["Name", "Department"],
//         data: [
//             ["Johnson", "Finance"],
//             ["Monika", "IT"],
//             ["Konstantina", "IT Billing"],
//             ["John", "HR"],
//             ["Josef", "Testing"],
//         ]
//     }
// ];

class ExportfixRequestExcelReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiDataSet: [],
            projectName: '',
            index_first: 0,
            index_last: 100,
            loading: true,
            optionAction: false,
            data: [],
            dataReport: [],
            wroksheetView: [],
            allResidential: [],
            page: 1,
            pageAll: 0,
            pageSize: 100,
            totalCount: 0,
            startDate: "",
            endDate: "",
        };
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
        this.downLoad = this.downLoad.bind(this)
    }

    componentWillMount(){
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                this.setState({
                    projectName: res.selfProject.name,
                })
            }
        })    
        
    }
    

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.data && (this.props.data !== prevProps.data)) {
            this.getData(this.state.index_first, this.state.index_last)
        }
    }

    setData(data_query) {

        // console.log(data_query);
        let data = [];
        let header = this.props.for === "tenant" ? "รายงานแจ้งซ่อมของลูกบ้าน" : "รายงานแจ้งซ่อมของส่วนกลาง"
        // data.push([{value: header}])
        let status =
            this.props.status === "all" ? "เรียงตามสถานะประกัน - ทั้งหมด" :
                this.props.status === "warranty" ? "เรียงตามสถานะประกัน - มีประกัน" :
                    this.props.status === "expired" ? "เรียงตามสถานะประกัน - หมดประกัน" : "เรียงตามสถานะประกัน - ไม่มีประกัน"
        
                   

        let date = this.props.startDate && this.props.endDate ?
            "เรียงตามวันที่ " + format(this.props.startDate, "DD/MM/YYYY") + " ถึง " + format(this.props.endDate, "DD/MM/YYYY") :
            "เรียงตามวันที่ - ถึง -"
        // data.push([{ value: status }]);
        // data.push([{ value: date }]);
        data.push([
            { value: "ลำดับ", style: { alignment: { horizontal: "center", vertical: 'center'  }}, font: { bold: true } },
            { value: "เลขที่", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            { value: "วันที่แจ้ง", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            { value: "หมวดหมู่", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            { value: "ชนิดงาน/วัสดุ", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            { value: "เลขห้อง", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            { value: "ชื่อผู้แจ้ง", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            { value: "ชื่อช่าง/ผู้รับเหมา", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            { value: "วันหมดประกัน", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
            { value: "สถานะการซ่อม", style: { alignment: { horizontal: "center", vertical: 'center' } }, font: { bold: true } },
        ])

        data_query.map((n, index) => {
            let isStaff = n.tenant_contact?.substring(0, 2)
            isStaff = isStaff === "Y-" ? true : false
            data.push([
                { value: index + 1, style: { alignment: { horizontal: "center", vertical: 'center' } } },
                { value: n.doc_number, style: { alignment: { horizontal: "center", vertical: 'center' } } },
                { value: format(n.added, "DD/MM/YYYY"), style: { alignment: { horizontal: "center", vertical: 'center' } } },
                { value: n.working_type, style: { alignment: { horizontal: "center", vertical: 'center' } } },
                { value: n.material, style: { alignment: { vertical: 'center', wrapText: true } } },
                { value: n.room_number, style: { alignment: { horizontal: "center", vertical: 'center' } } },
                {
                    value:
                        n.room_number === "นิติบุคคล" && isStaff ?
                            n.juristic_contact : n.tenant_contact, style: { alignment: { horizontal: "center", vertical: 'center' } }
                },
                {
                    value: n.job_responsed_by_staff_name ? n.job_responsed_by_staff_name :
                        n.engineer_contact ? n.engineer_contact : '-', style: { alignment: { horizontal: "center", vertical: 'center', wrapText: true } }
                },
                { value: n.insure_status !== "not" ? format(n.due_date, "DD/MM/YYYY") : "-", style: { alignment: { horizontal: "center", vertical: 'center' } } },
                { value: n.status === "finish" ? "เสร็จสิ้น" : i18next.t("Allaction:cancel"), style: { alignment: { horizontal: "center", vertical: 'center' } } },


            ])
        })

        let multiDataSet = []

        multiDataSet = [
            {
                
                columns: [
                    { title: this.state.projectName,style: { font: { sz: "11.5" } }},
                ],
                data: [
                    [
                        {
                            value: header,
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ],
                    [
                        {
                            value: status,
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ],
                    [
                        {
                            value: date,
                            style: {font: {sz: "11.5", bold: true}}
                        }
                    ],
                ],
            },
            {  
                columns: [
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 250}},
                    {title: "", width: {wpx: 100}},
                    {title: "", width: {wpx: 150}},
                    {title: "", width: {wpx: 150}},
                    {title: "", width: {wpx: 100}},
                    {title: "", width: {wpx: 100}},
                    {title: "", width: {wpx: 200}},
                    {title: "", width: {wpx: 80}},
                    {title: "", width: {wpx: 80}},
                ],
                data
            },
        ]
        this.setState({ multiDataSet })
    }

    downLoad() {
        this.getData(this.state.index_first, this.state.index_last)
    }

    buttonElement() {
        return (
            <a className="dropdown-item" href="#" onClick={this.downLoad}>EXCEL</a>
        );
    }

    async getData(index_f, index_l) {
        let filter = JSON.parse(localStorage.getItem("filterReportFixRequest"))
        let forParam = "for"+this.props.for;

        let filterBy = forParam === "fortenant" ? filter.searchForReportTenent : filter.searchForReportCommon

        let datas = {
            index_first: index_f,
            index_last: index_l,
            page: this.state.page,
            status: '',

            working_type: filterBy?.searchWorkingType || "",
            id: "",
            person_working: filterBy?.searchTypePerson,
            search: filterBy?.searchBox,
            start_date: this.props.startDate !== "" ? this.props.startDate + "T00:00" : '',
            end_date: this.props.endDate !== "" ? this.props.endDate + "T23:59" : '',
            repair_type: forParam === "fortenant" ? "private" : 'public',
            insure_status_choices:
                this.props.status === "all" ? "" :
                    this.props.status === "warranty" ? "have" :
                        this.props.status === "expired" ? "out" : "not",
            pageSize: this.state.pageSize
        }

        API.getReport(datas).then((res) => {
                let data = []
                if (res.data.data) {
                    data = res.data.data
                    this.setState({
                        loading: false, 
                        dataReport: data,
                        totalCount: res.data.totalCount,
                        pageAll: res.data.pagesCount,
                        startDate: datas.start_date,
                        endDate: datas.end_date
                    },()=>this.setData(this.state.dataReport))
                }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error')).then(() => {
                this.setState({ loading: false })
        })
           
    }

    render() {
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"fixRequestExcel" + format(new Date(), 'DD/MM/YYYY', { locale: thLocale })}>
                <ExcelSheet dataSet={this.state.multiDataSet} name="fixRequestExcel"/>
            </ExcelFile>
        )
    }
}

export default ExportfixRequestExcelReport