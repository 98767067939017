import React, {Component} from 'react';
import {Redirect} from 'react-router'
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import PayTransactionList from "../../../expense/pay/pay_create/payTransactionList";
import numberWithComma from "../../../../libs/numberWithComma";
import {commitMutation} from "react-relay";
import environment from "../../../../env/environment";
import Swal from "sweetalert2";
import {graphql} from "babel-plugin-relay/macro";
import i18next from 'i18next';

const _ = require('lodash');


const mutation = graphql`
    mutation payCreateFormMutation($input: CreatePayRecordGroupAndPayRecordInput!){
        createPayRecordGroupAndPayRecord(input:$input){
            ok
            warningText
        }
    }`;


class PayCreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            totalPay: 0,
            remark: "",
            payChannel: [],
            redirectToList: false,
            issuedDate: new Date(),
            upload: {},
            wht: 0,
            transactions_list:[],
            signreq:Math.random().toString(36),
        };

        this.updatePayChannel = this.updatePayChannel.bind(this);
        this.submitPay = this.submitPay.bind(this);
        this.updateState = this.updateState.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    updateState(name, value) {
        this.setState({[name]: value});
    }

    updatePayChannel(channel_list) {
        let totalPay = 0;
        let upload = {};
        channel_list.forEach(channel => {
            if(channel.slug === "small-change" || channel.slug === "fee") {
                totalPay -= parseFloat(channel.pay);
            }
            else if(channel.slug === "petty_cash"){
                let petty_cash = parseFloat(0);
                if(parseFloat(channel.pay)) petty_cash = parseFloat(channel.pay);
                totalPay += petty_cash;
              }
            else {
                totalPay += parseFloat(channel.pay);
            }
            if (channel.image) {
                Object.assign(upload, {[channel.slug]: channel.image});
            }
        });
        this.setState({
            payChannel: channel_list,
            totalPay: parseFloat((totalPay).toFixed(2)),
            upload: upload
        });
    }

     handleInputChange(e) {
        let event = _.cloneDeep(e);
        let name = event.target.name;
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        }, () => this.updateState(name, event.target.value));
    }

    submitPay(e) {
        e.preventDefault();
        this.setState({loading: true});
        let variables = {
            input: {
                contactId: this.props.contactList,
                issuedDate: this.state.issuedDate,
                remark: this.state.remark,
                payChannel: JSON.stringify(this.state.payChannel),
                transactions: JSON.stringify(this.state.transactions_list),
                clientMutationId:"PP"+this.state.signreq,
            }
        };
        let uploadables = this.state.upload;
        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createPayRecordGroupAndPayRecord.ok) {
                        Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                            this.setState({redirectToList: true})
                        });
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createPayRecordGroupAndPayRecord.warningText, 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                },
            },
        );
    }

    render() {
        if (this.state.redirectToList){
             return <Redirect to="/accounting/expense/pay/list/draft"/>
        }
        if (this.props.contactList.length > 0 && !this.state.redirectToList) {
            return (
                <form onSubmit={this.submitPay}>
                    <div className="container-fluid box">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/expense/pay/form/select_purchase">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('accounting:add_pay')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <div className="content-inner">

                            <PayTransactionList
                                updateState={this.updateState}
                                transactions={this.props.transactions}
                                contactList={this.props.contactList}
                                />

                            {/* <PayPaymentChannelFormInPay updatePayChannel={this.updatePayChannel}
                                                   payment_type={'pay'}
                                                   pay_form={true}
                                                   issued_date={this.state.issuedDate}
                                                   auto_suggest={parseFloat((this.state.total - this.state.wht).toFixed(2))}
                                                   pay={this.state.totalPay}

                            /> */}

                            <div className="row mt-4">
                                {/* <div className="col">
                                    <label htmlFor="note"><strong>หมายเหตุ</strong></label>
                                    <textarea className="form-control" rows="2" name="remark" value={this.state.remark}
                                              onChange={this.handleInputChange}/>
                                </div> */}
                                <div className="col offset-6 bg-light">
                                    <div className="row p-3 mb-2 text-dark">
                                        <div className="col text-left"> {i18next.t("payCreateAccrued:Total Amount")}</div>
                                        <div className="col text-right"> {numberWithComma(parseFloat((this.state.total - this.state.wht).toFixed(2)))} บาท</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col text-right">
                                    <div className="btn-group mr-2">
                                        <Link to="/accounting/expense/pay/form/select_customer">
                                            <button type="button" className="btn btn-secondary add">
                                            {i18next.t("payCreateAccrued:Cancel")}
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="btn-group mr-2">
                                        <button type="submit" className="btn btn-primary add"
                                                disabled={this.state.loading}>
                                            {this.state.loading &&
                                            <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                            {i18next.t("payCreateAccrued:Save for pre - payment")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </form>
            )
        }
        return <Redirect to="/accounting/expense/pay/form/select_customer"/>;
    }
}

export default PayCreateForm;
