/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PurchaseCreateUpdateFormQueryVariables = {|
  contact: $ReadOnlyArray<?string>,
  taxType?: ?string,
  vat?: ?string,
|};
export type PurchaseCreateUpdateFormQueryResponse = {|
  +prepaidDepositViewer: ?{|
    +allPrepaidDeposit: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +prepaidDepositTransaction: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +total: number,
                +price: number,
                +balance: number,
                +vat: ?number,
                +vatAmount: number,
                +whtAmount: number,
                +whtRatePercent: ?number,
                +chartOfAccount: {|
                  +id: string,
                  +name: string,
                  +chartOfAccountCode: string,
                |},
                +prepaidDepositTransactionUsed: ?{|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +amount: ?number,
                      +purchaseRecord: ?{|
                        +purchaseRecordGroup: {|
                          +id: string
                        |}
                      |},
                    |}
                  |}>
                |},
              |}
            |}>
          |},
        |}
      |}>
    |}
  |},
  +allProjects: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +periodCreate: string
      |}
    |}>
  |},
|};
export type PurchaseCreateUpdateFormQuery = {|
  variables: PurchaseCreateUpdateFormQueryVariables,
  response: PurchaseCreateUpdateFormQueryResponse,
|};
*/


/*
query PurchaseCreateUpdateFormQuery(
  $contact: [String]!
  $taxType: String
  $vat: String
) {
  prepaidDepositViewer {
    allPrepaidDeposit(contactList: $contact, taxType: $taxType, vat: $vat, status_In: "active, use_partial") {
      edges {
        node {
          id
          docNumber
          prepaidDepositTransaction {
            edges {
              node {
                id
                total
                price
                balance
                vat
                vatAmount
                whtAmount
                whtRatePercent
                chartOfAccount {
                  id
                  name
                  chartOfAccountCode
                }
                prepaidDepositTransactionUsed {
                  edges {
                    node {
                      id
                      amount
                      purchaseRecord {
                        purchaseRecordGroup {
                          id
                        }
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    id
  }
  allProjects {
    edges {
      node {
        periodCreate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contact"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taxType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vat"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contactList",
    "variableName": "contact"
  },
  {
    "kind": "Literal",
    "name": "status_In",
    "value": "active, use_partial"
  },
  {
    "kind": "Variable",
    "name": "taxType",
    "variableName": "taxType"
  },
  {
    "kind": "Variable",
    "name": "vat",
    "variableName": "vat"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vatAmount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtAmount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRatePercent",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chartOfAccountCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "PurchaseRecordGroupNode",
  "kind": "LinkedField",
  "name": "purchaseRecordGroup",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodCreate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchaseCreateUpdateFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PrepaidDepositViewer",
        "kind": "LinkedField",
        "name": "prepaidDepositViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PrepaidDepositNodeConnection",
            "kind": "LinkedField",
            "name": "allPrepaidDeposit",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PrepaidDepositNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrepaidDepositNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrepaidDepositTransactionNodeConnection",
                        "kind": "LinkedField",
                        "name": "prepaidDepositTransaction",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PrepaidDepositTransactionNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "prepaidDepositTransactionUsed",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PrepaidDepositTransactionUsedNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              (v12/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PurchaseRecordNode",
                                                "kind": "LinkedField",
                                                "name": "purchaseRecord",
                                                "plural": false,
                                                "selections": [
                                                  (v13/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchaseCreateUpdateFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PrepaidDepositViewer",
        "kind": "LinkedField",
        "name": "prepaidDepositViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PrepaidDepositNodeConnection",
            "kind": "LinkedField",
            "name": "allPrepaidDeposit",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PrepaidDepositNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrepaidDepositNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrepaidDepositTransactionNodeConnection",
                        "kind": "LinkedField",
                        "name": "prepaidDepositTransaction",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PrepaidDepositTransactionNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PrepaidDepositTransactionNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PrepaidDepositTransactionUsedNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "prepaidDepositTransactionUsed",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PrepaidDepositTransactionUsedNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PrepaidDepositTransactionUsedNode",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              (v12/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PurchaseRecordNode",
                                                "kind": "LinkedField",
                                                "name": "purchaseRecord",
                                                "plural": false,
                                                "selections": [
                                                  (v13/*: any*/),
                                                  (v2/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f3ab6473b5c24e7f1eb7f32300e9ec6",
    "id": null,
    "metadata": {},
    "name": "PurchaseCreateUpdateFormQuery",
    "operationKind": "query",
    "text": "query PurchaseCreateUpdateFormQuery(\n  $contact: [String]!\n  $taxType: String\n  $vat: String\n) {\n  prepaidDepositViewer {\n    allPrepaidDeposit(contactList: $contact, taxType: $taxType, vat: $vat, status_In: \"active, use_partial\") {\n      edges {\n        node {\n          id\n          docNumber\n          prepaidDepositTransaction {\n            edges {\n              node {\n                id\n                total\n                price\n                balance\n                vat\n                vatAmount\n                whtAmount\n                whtRatePercent\n                chartOfAccount {\n                  id\n                  name\n                  chartOfAccountCode\n                }\n                prepaidDepositTransactionUsed {\n                  edges {\n                    node {\n                      id\n                      amount\n                      purchaseRecord {\n                        purchaseRecordGroup {\n                          id\n                        }\n                        id\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n  allProjects {\n    edges {\n      node {\n        periodCreate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd292bef16b142d0b2f262d426ca5030c';

module.exports = node;
