import React, { Component } from 'react';
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Wrapper from "../../../components/wrapper";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import i18next from 'i18next';

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listMenu: [
                {name: "Bank Deposit Reports", link: "/accounting/report/finance/bank-deposit-report"},
                {name: "Bank Deposit Impact Report", link: "/accounting/report/finance/bank-deposit-impact-report"},
                {name: "Fixed Deposit Interest Estimation Report", link: "/accounting/report/finance/interest-dashboard"},
            ]
        }
    }


    componentDidMount = () => {

    }

    render() {

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box">
                        <Translation>
                            {t =>
                                <React.Fragment>
                                    <BackButtonIcon
                                        LinkBack={"/accounting"}
                                        LinkText={i18next.t("report_finance:Financial Reports")}
                                    />

                                    <div className="content-inner">
                                        <div className="row fade-up">

                                            {
                                                this.state.listMenu?.map((item,index) => {
                                                    return (
                                                        <div className="col-md-4 col-xl-4 mb-2">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{index + 1}. {i18next.t("report_finance:"+item.name)}</h5>
                                                                </div>
                                                                <div className="card-footer bg-transparent">
                                                                    <Link to={item.link} className="btn btn-primary btn-sm">{i18next.t("report_finance:View Reports")}</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </Translation>
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default Dashboard;
