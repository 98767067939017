import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import DepositInterestDashBoard from './depositInterestDashBoard'


class DepositInterest extends Component {

    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={DepositInterestDashBoard}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={DepositInterestDashBoard}/>
            </React.Fragment>
        );
    }
}

export default DepositInterest;