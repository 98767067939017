import React, { Component } from 'react'
import { Switch } from "react-router-dom";
import PrivateRoute from "../../../libs/privateRoute";

// component
import ReportRegisterList from './reportRegisterList';
import ReportRegisterDetail from './reportRegisterDetails'
import WrapperReportRegisterList from './wrapperReportRegisterList';

class index extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list`} component={ReportRegisterList}/>
                <PrivateRoute path={`${this.props.match.url}/detail/:id`} component={ReportRegisterDetail}/>
                <PrivateRoute path={`${this.props.match.url}/document/reportRegister/:id`} component={WrapperReportRegisterList}/>
            </Switch>
        )
    }
}
export default index;
