import React, { Component } from 'react';
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import ChartSelect from "../trialBalance/chartSelect";
import _ from "lodash";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import WorkSheetTable from './workSheetTable';
import WorkSheetExcelExport from './workSheetExcelExport';
import i18next from 'i18next';


const query = graphql`
    query workSheetQuery($startDate: DateTime, $endDate: DateTime, $betweenStartId: String, $betweenEndId: String) {
        selfProject {
            name
        }
        allActiveChartOfAccount(startDate: $startDate, endDate: $endDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {
            edges {
              node {
                id
                chartOfAccountCode
                name
                totalDebit
                totalCredit
                totalHistoricalDebit
                totalHistoricalCredit
                totalPeriodDebit
                totalPeriodCredit
                balanceDebit
                balanceCredit
                balance
                update
              }
            }
        }
    }
`;

class WorkSheetList extends Component {

    constructor(props) {
        super(props);
        let month_before = new Date();
        month_before.setMonth(month_before.getMonth() - 1);

        this.state = {
            start_date: month_before,
            end_date: new Date(),
            chart_of_account_start: "",
            chart_of_account_end: "",
            project_name: '',
            allActiveChartOfAccount: [],
            loading: false,

            start_date_before: month_before,
            end_date_before: new Date(),
            chart_of_account_start_before: "",
            chart_of_account_end_before: "",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getData()
    }
    getData = async () => {
        this.setState({ loading: true })
        let { start_date, end_date, chart_of_account_end, chart_of_account_start } = this.state

        let res = await fetchQuery(environment, query, { startDate: start_date, endDate: end_date, betweenStartId: chart_of_account_start, betweenEndId: chart_of_account_end })
        if (res) {
            this.setState({ allActiveChartOfAccount: res.allActiveChartOfAccount?.edges || [], project_name: res.selfProject?.name, loading: false })
        } else {
            console.log('error ');
            this.setState({ loading: false })
        }

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        }
        );
    }

    calDate() {
        this.setState({
            start_date: this.state.start_date_before,
            end_date: this.state.end_date_before,
            chart_of_account_start: this.state.chart_of_account_start_before,
            chart_of_account_end: this.state.chart_of_account_end_before
        }, () => { this.getData() });
    }

    render() {
        let { start_date, end_date, chart_of_account_start, chart_of_account_end } = this.state
        let unadjusted_trial_balance_debit = 0,
            unadjusted_trial_balance_credit = 0,
            adjusted_entry_debit = 0,
            adjusted_entry_credit = 0,
            adjusted_trial_balance_debit = 0,
            adjusted_trial_balance_credit = 0,
            income_expenses_debit = 0,
            income_expenses_credit = 0,
            statement_of_financial_position_debit = 0,
            statement_of_financial_position_credit = 0

        let noManualJV = "{\"update_debit\": \"0.0\", \"update_credit\": \"0.0\", \"before_update_debit\": \"0\", \"before_update_credit\": \"0\"}";
        // let allActiveChartOfAccount = this.state.allActiveChartOfAccount.filter((n)=> n.node.balance !== 0)
        let allActiveChartOfAccount = this.state.allActiveChartOfAccount.filter((n) => {
            return ((n.node.balanceCredit !== 0 || n.node.balanceDebit !== 0) && n.node.update !== noManualJV)
        });
        // let allActiveChartOfAccount = this.state.allActiveChartOfAccount.filter((n)=> {return  n.node.balance !== 0 && n.node.update !== noManualJV})
        // let allActiveChartOfAccount = this.state.allActiveChartOfAccount;
        allActiveChartOfAccount.map((chart) => {
            let code = _.get(chart, "node.chartOfAccountCode").slice(0, 1)
            let balanceDebit = _.get(chart, "node.balanceDebit")
            let balanceCredit = _.get(chart, "node.balanceCredit")
            let balance = _.get(chart, "node.balance")
            let update = JSON.parse(_.get(chart, "node.update"))

            balanceDebit > balanceCredit ? adjusted_trial_balance_debit += balance : adjusted_trial_balance_credit += balance

            if (code === '4' || code === "5") {
                balanceDebit > balanceCredit ? income_expenses_debit += balance : income_expenses_credit += balance
            } else if (code === '1' || code === "2" || code === "3") {
                balanceDebit > balanceCredit ? statement_of_financial_position_debit += balance : statement_of_financial_position_credit += balance
            }

            adjusted_entry_debit += parseFloat(update.update_debit)
            adjusted_entry_credit += parseFloat(update.update_credit)
            unadjusted_trial_balance_debit += parseFloat(update.before_update_debit)
            unadjusted_trial_balance_credit += parseFloat(update.before_update_credit)
        })
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'} alt="back" className="back" />
                                    </Link>
                                    <Translation>{t => <span>{t('accounting:work sheet')}</span>}</Translation>
                                </h3>
                            </div>
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_work_sheet_print' }) &&
                                    <div className="row d-flex justify-content-end mr-2" >
                                        <Dropdown >
                                            <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'><Translation>{t => i18next.t("Allaction:Print") }</Translation></DropdownToggle>
                                            <DropdownMenu>
                                                <Dropdown.Item>
                                                    <Link to={`/accounting/work-sheet/print-report-worksheet/${format(start_date, 'YYYY-MM-DD', { locale: thLocale })}/${format(end_date, 'YYYY-MM-DD', { locale: thLocale })}/${chart_of_account_start || 'all'}/${chart_of_account_end || 'all'}`}
                                                        className="text-black">
                                                        <p className="text-black">PDF</p>
                                                    </Link>
                                                </Dropdown.Item>
                                                <WorkSheetExcelExport
                                                    start_date={start_date}
                                                    end_date={end_date}
                                                    chart_of_account_start={chart_of_account_start}
                                                    chart_of_account_end={chart_of_account_end}
                                                />
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>}
                            </div>
                        </div>

                        <Translation>
                            {t =>
                                <div className="content-inner">
                                    <div className="row mb-4">
                                        <div className="col-6">
                                            <div className="float-left mr-3">
                                                <label>{i18next.t("AllFilter:Date")} </label> <br />
                                                <DatePickerAdapter name="start_date_before" className="form-control" dateFormat="dd/MM/yyyy" selected={this.state.start_date_before} maxDate={this.state.end_date_before} onChange={this.handleChange} />
                                            </div>
                                            <div className="float-left">
                                                <label>{i18next.t("AllFilter:to")}</label> <br />
                                                <DatePickerAdapter name="end_date_before" className="form-control" dateFormat="dd/MM/yyyy" minDate={this.state.start_date_before} selected={this.state.end_date_before} onChange={this.handleChange} />
                                            </div>
                                            <div className="float-left">
                                                <div className='pt-7 ml-3'>
                                                    <button type="button"
                                                        className="btn btn-primary form-control"
                                                        name="search"
                                                        onClick={() => this.calDate()
                                                        }
                                                    >{i18next.t("AllFilter:Search")}
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <label>{i18next.t("workSheetTable:Specify the account number.")}</label>
                                            <ChartSelect handleChange={this.handleChange} name={"chart_of_account_start_before"} value={this.state.chart_of_account_start_before} disabled={this.state.loading} />
                                        </div>
                                        <div className="col-3">
                                            <label>{i18next.t("AllFilter:to")}</label>
                                            <ChartSelect handleChange={this.handleChange} name={"chart_of_account_end_before"} value={this.state.chart_of_account_end_before} disabled={this.state.loading} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">{i18next.t("AllFilter:Date")} {format(this.state.end_date, 'DD/MM/YYYY', { locale: thLocale })} </div>
                                    </div>
                                    <hr />
                                    <WorkSheetTable loading={this.state.loading} allActiveChartOfAccount={allActiveChartOfAccount}
                                        start_date={this.state.start_date} end_date={this.state.end_date}
                                        sum={{
                                            unadjusted_trial_balance_debit,
                                            unadjusted_trial_balance_credit,
                                            adjusted_entry_debit,
                                            adjusted_entry_credit,
                                            adjusted_trial_balance_debit,
                                            adjusted_trial_balance_credit,
                                            income_expenses_debit,
                                            income_expenses_credit,
                                            statement_of_financial_position_debit,
                                            statement_of_financial_position_credit
                                        }}
                                        lastPage
                                    />
                                </div>
                            }
                        </Translation>

                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default WorkSheetList;
