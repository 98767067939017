import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {QueryRenderer} from "react-relay";
import SearchSelect from "../../../libs/searchSelect";
import i18next from "i18next";


const query = graphql`
    query productServiceListQuery {
        productViewer{
            allProduct (type_In: "product, service, fine"){
                edges{
                    node{
                        id
                        name
                        productCode
                        type
                        price
                        chartOfAccount{
                            id
                            chartOfAccountCode
                            name
                        }
                        description
                        totalLeftInStock
                    }
                }
            }
        }
    }
`;


class ProductServiceList extends React.Component {
    render() {
        return (

            <QueryRenderer
                environment={environment}
                query={query}
                fetchPolicy="no-cache"
                variables={{}}
                render={({error, props}) => {
                    if (props) {
                        return (
                            <div className="col-md-4 border-right">
                                <div className="form-group">
                                    <label>{i18next.t("invoiceReport:Product and Service Type")}</label>
                                    <SearchSelect onChange={this.props.handleChange}
                                                  value={this.props.product_and_service}
                                                  name="temp_product_and_service"
                                                  placeholder={i18next.t("invoiceReport:Choose Product and Service Type")}
                                                  queryObject={props.productViewer.allProduct.edges}
                                                  isClearable={true}
                                                  optionAll={true}
                                                  keyOfValue="id"
                                                  keyOfLabel="productCode:name"/>                
                                </div>
                            </div>
                        )
                    }
                }}
            />

        )
    }
}

export default ProductServiceList;
