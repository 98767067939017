/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type creditCardDepositCreateQueryVariables = {||};
export type creditCardDepositCreateQueryResponse = {|
  +allCreditCardTransaction: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +receive: ?{|
          +docNumber: string,
          +issuedDate: any,
        |},
        +invoice: ?{|
          +docNumber: string,
          +issuedDate: any,
        |},
        +otherReceive: ?{|
          +docNumber: string,
          +issuedDate: any,
        |},
        +receiptDeposit: ?{|
          +docNumber: string,
          +issuedDate: any,
        |},
        +description: ?string,
        +amount: ?number,
      |}
    |}>
  |}
|};
export type creditCardDepositCreateQuery = {|
  variables: creditCardDepositCreateQueryVariables,
  response: creditCardDepositCreateQueryResponse,
|};
*/


/*
query creditCardDepositCreateQuery {
  allCreditCardTransaction(status: "not_pass") {
    edges {
      node {
        id
        receive {
          docNumber
          issuedDate
          id
        }
        invoice {
          docNumber
          issuedDate
          id
        }
        otherReceive {
          docNumber
          issuedDate
          id
        }
        receiptDeposit {
          docNumber
          issuedDate
          id
        }
        description
        amount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "not_pass"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "creditCardDepositCreateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CreditCardTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "allCreditCardTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreditCardTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreditCardTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveNode",
                    "kind": "LinkedField",
                    "name": "receive",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "invoice",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OtherReceiveNode",
                    "kind": "LinkedField",
                    "name": "otherReceive",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiptDepositNode",
                    "kind": "LinkedField",
                    "name": "receiptDeposit",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allCreditCardTransaction(status:\"not_pass\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "creditCardDepositCreateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CreditCardTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "allCreditCardTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreditCardTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreditCardTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveNode",
                    "kind": "LinkedField",
                    "name": "receive",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "invoice",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OtherReceiveNode",
                    "kind": "LinkedField",
                    "name": "otherReceive",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiptDepositNode",
                    "kind": "LinkedField",
                    "name": "receiptDeposit",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allCreditCardTransaction(status:\"not_pass\")"
      }
    ]
  },
  "params": {
    "cacheID": "9dac84cd45ccc6d536338a031d9a9a38",
    "id": null,
    "metadata": {},
    "name": "creditCardDepositCreateQuery",
    "operationKind": "query",
    "text": "query creditCardDepositCreateQuery {\n  allCreditCardTransaction(status: \"not_pass\") {\n    edges {\n      node {\n        id\n        receive {\n          docNumber\n          issuedDate\n          id\n        }\n        invoice {\n          docNumber\n          issuedDate\n          id\n        }\n        otherReceive {\n          docNumber\n          issuedDate\n          id\n        }\n        receiptDeposit {\n          docNumber\n          issuedDate\n          id\n        }\n        description\n        amount\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b160168cefbcaf161979ecb7d8e24100';

module.exports = node;
