import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import Wrapper from '../../../components/wrapper';
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import WrapperContent from '../../../components/wrapper/wrapperContent';
import AccountingTopMenuNavigation from '../../accountingTopMenuNavigation';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/asset.scss';
import { fetchQuery, commitMutation } from "relay-runtime";
import environment from '../../../env/environment';
import _ from 'lodash'
import DatePickerNoti from '../../finance/depositInterest/datePickerNoti';
import Swal from 'sweetalert2';
import { format, setDate, setMonth, lastDayOfMonth, setYear } from "date-fns";
import { Redirect } from 'react-router';
import PaymentChannel from './paymentChannel';
import numberWithComma from '../../../libs/numberWithComma';
import UpdateAssetMutation from './updateAsset';
import CreateOtherReceive from './createOtherReceive'
import PayChannel from './payChannel';
import upload from "../../../libs/upload";
import SearchSelect from "../../../libs/searchSelect";
import i18next from 'i18next';

const mutation = graphql`
    mutation assetSoldMutation($input: CreateAndUpdateSoldAssetInput!) {
        createAndUpdateSoldAsset(input: $input) {
            ok
        }
    }
`;


const query = graphql`
    query assetSoldQuery($id: ID!,$date:Date,$search: String, $contact_type: String, $payment_type: String) {
        asset(id: $id) {
            id
            type
            docNumber
            nounClassifier
            chartOfAccountType {
                chartOfAccountCode
                name
                id
            }
            chartOfAccountDepreciation {
                id
                chartOfAccountCode
                name
            }
            chartOfAccountDepreciationCumulative {
                id
                chartOfAccountCode
                name
            }
            name
            description
            storage
            remark
            internalAssetCode
            seller{
                id
            }
            sellerOther
            calDepreciation
            depreciationRatePercent
            depreciationRate
            lifeTimeYear
            purchaseDate
            saleDate
            calDepreciationDate
            costPrice
            carcassPrice
            calSchedule
            historicalValue
            historicalAmount
            saleStatus
            assetDepreciate(date_Lte:$date) {
                edges {
                    node {
                        id
                        depreciatePrice
                    }
                }
            }
            disappearAsset {
                edges{
                    node {
                        id
                    }
                }
            }
            soldAsset{
                edges{
                    node {
                        nameContact 
                        adressContact
                        saleDate
                        remark
                    }
                }
            }
            otherReceiveTransaction {
                edges {
                    node {
                        price
                        description
                        total
                        otherReceive{
                            docNumber 
                            unknownReceive {
                                edges {
                                    node {
                                        id
                                        docNumber
                                        refNumber
                                        description
                                        amount
                                        chartOfAccount {
                                        chartOfAccountCode
                                        name
                                        }
                                    }
                                }
                            }
                            bankAccountTransaction{
                                edges {
                                    node {
                                        id
                                        price
                                        date
                                        imageSlip
                                        bankAccount {
                                            bankName
                                            accountNumber
                                        }
                                    }
                                }
                            }
                            cashTransaction{
                                edges {
                                    node {
                                        id
                                        description
                                        price
                                    }
                                }
                            }
                            chequeDeposit{
                                edges {
                                    node {
                                        id
                                        price
                                        date
                                        chequeNumber
                                        bankName
                                        branchNumber
                                        chequeImage
                                    }
                                }
                            }
                            creditCardTransaction {
                                edges {
                                    node {
                                        id
                                        amount
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        contactViewer {
            id
            allContact(search: $search, typeOfContact: $contact_type , typeOfPayment: $payment_type, isActive: true){
                edges{
                    node{
                        id
                        name
                        refNumber
                        firstName
                        lastName
                        registeredName
                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode
                        typeOfContact
                    }
                }
            }
        }
    }
`;

class AssetSold extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            redirect: false,
            last_date: lastDayOfMonth(setDate(setMonth(new Date(), new Date().getMonth() - 1), 1)),
            sum_asset_depreciate: 0,
            soldAssetId: '',
            asset: {
                id: '',
                type: 'LAND',
                nounClassifier: '',
                chartOfAccountDepreciation: { id: '' },
                chartOfAccountType: { id: '' },
                chartOfAccountDepreciationCumulative: { id: '' },
                saleStatus: '',
                docNumber: '',
                name: '',
                description: '',
                storage: '',
                seller: { id: '', },
                sellerOther: "",
                remark: "",
                calDepreciation: false,
                formula: 'STRAIGHT_LINE',
                depreciationRatePercent: 5,
                depreciationRate: 0.05,
                lifeTimeYear: 20,
                purchaseDate: new Date(),
                calDepreciationDate: new Date(),
                costPrice: 0,
                historicalValue: 0,
                carcassPrice: 1,
                calSchedule: "MONTHLY",
                historicalAmount: 0,
                saleDate: new Date(),
                internalAssetCode: ''
            },
            payChannel: [],
            upload: {},
            totalPay: 0,
            nameContact: '',
            adressContact: '',
            saleDate: new Date(),
            remark: '',
            description: '',
            otherReceive: null,
            contact: null,
            contact_fisrt: null,
            contactId: null,
            asset_amount: 0 //มูลค่าทางบัญชี
        }

    }

    componentDidMount() {
        this.fetchData()

    }


    fetchData = async () => {
        this.setState({ loadingData: true })
        await fetchQuery(environment, query, {
            id: this.props.match.params.id,
            date: format(this.state.last_date, 'YYYY-MM-DD'),
            contact_type: 'supplier',
            payment_type: 'receivable',
        }).then(response => {
            let data = _.cloneDeep(response);
            _.set(data.asset, 'purchaseDate', data.asset.purchaseDate ? new Date(data.asset.purchaseDate) : null);
            _.set(data.asset, 'saleStatus', 'sold');
            _.set(data.asset, 'calDepreciationDate', data.asset.calDepreciationDate ? new Date(data.asset.calDepreciationDate) : null);
            _.set(data.asset, 'saleDate', data.asset.saleDate ? new Date(data.asset.saleDate) : new Date());


            if (!_.get(data.asset, "seller") && !_.get(data.asset, "sellerOther")) {
                _.set(data.asset, 'seller', { id: "on_contact" });
            } else if (!_.get(data.asset, "seller") && _.get(data.asset, "sellerOther")) {
                _.set(data.asset, 'seller', { id: "other_select" });
            }


            this.setState({
                asset: data.asset,
                otherReceive: response.asset.otherReceiveTransaction?.edges.length > 0 ? response.asset.otherReceiveTransaction.edges[0] : null,
                totalPay: response.asset.otherReceiveTransaction?.edges.length > 0 ? response.asset.otherReceiveTransaction.edges[0].node.price : 0,
                description: response.asset.otherReceiveTransaction?.edges.length > 0 ? response.asset.otherReceiveTransaction.edges[0].node.description : data.asset.name,
                //description: data.asset.name,
                adressContact: response.asset.soldAsset?.edges.length > 0 ? response.asset.soldAsset.edges[0].node.adressContact : '',
                nameContact: response.asset.soldAsset?.edges.length > 0 ? response.asset.soldAsset.edges[0].node.nameContact : '',
                remark: response.asset.soldAsset?.edges.length > 0 ? response.asset.soldAsset.edges[0].node.remark : '',
                soldAssetId: response.asset.soldAsset?.edges.length > 0 ? response.asset.soldAsset.edges[0].node.id : '',
                saleDate: response.asset.soldAsset?.edges.length > 0 ? new Date(response.asset.soldAsset.edges[0].node.saleDate) : new Date(),
                contact: response.contactViewer.allContact.edges
            }, () => {
                this.calDepreciation()
                this.setState({
                    loadingData: false,
                    // description: this.state.asset.chartOfAccountType.name,
                    description: response.asset.otherReceiveTransaction?.edges.length > 0 ? response.asset.otherReceiveTransaction.edges[0].node.description : data.asset.name,
                })
            });

        }).catch(err => {
            alert(err)
        })

    }

    calDepreciation = () => {
        let amount = (this.state.asset.costPrice - this.state.asset.historicalAmount - this.state.asset.carcassPrice)
        let total_day = setYear(new Date(this.state.asset.purchaseDate), (new Date(this.state.asset.purchaseDate).getFullYear() + this.state.asset.lifeTimeYear)) - new Date(this.state.asset.calDepreciationDate)
        let total_day_date = Math.ceil(total_day / (1000 * 60 * 60 * 24));
        let amount_per_day = amount / total_day_date
        let numberOfDays = new Date(this.state.asset.saleDate).getDate()
        let asset_amount = amount_per_day * numberOfDays
        let sum_asset_depreciate = _.sumBy(this.state.asset.assetDepreciate.edges, 'node.depreciatePrice')
        let total_asset = this.state.asset.costPrice - this.state.asset.historicalAmount - (sum_asset_depreciate + asset_amount)
        this.setState({
            asset_amount: total_asset
        })

    }

    handleChange = (e) => {
        let event = _.cloneDeep(e)
        if (event.target.name === 'asset.saleDate') {
            this.calDepreciation()
        }
        else if (event.target.name === 'nameContact') {
            let contact = _.find(this.state.contact, { node: { name: event.target.value } })
            let adress_contact = ''
            if (contact.node.registeredProvince.includes("กรุงเทพ") || contact.node.registeredProvince.includes("กทม")) {
                adress_contact = contact.node.registeredAddress +
                    " แขวง" + contact.node.registeredDistrict +
                    " เขต" + contact.node.registeredCity +
                    " " + contact.node.registeredProvince +
                    " " + contact.node.registeredPostalCode +
                    " " + contact.node.registeredCountry
            } else {
                adress_contact = contact.node.registeredAddress +
                    " ตำบล" + contact.node.registeredDistrict +
                    " อำเภอ" + contact.node.registeredCity +
                    " จังหวัด" + contact.node.registeredProvince +
                    " " + contact.node.registeredPostalCode +
                    " " + contact.node.registeredCountry
            }

            this.setState({
                contact_fisrt: contact,
                adressContact: adress_contact,
                contactId: contact.node.id
            })
        }
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                event.target.value
            );
        })
    }

    onSubmit = (e) => {
        e.preventDefault(); 

        Swal.fire({
            html:
                '<img src="/images/icons/alert-blue.png" width="60"><br/><br/>' +
                '<b>ระบบจะทำการเปิดใบเสร็จรับเงินแบบ OR <br/>' + 'และจะเกิดบัญชีรายวันทันทีที่บันทึกสำเร็จ</b>' +
                '<br/><br/>',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'บันทึก',
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((v) => {
            if (v.value) {

                let input = {
                    asset: JSON.stringify(this.state.asset),
                };

                UpdateAssetMutation(input, this.onSuccessAsset, this.onError)
            }
        })

        // this.setState({
        //     loading: true
        // }) 
    }

    onSuccessAsset = async (response) => {

        let variables = {
            input: {
                soldAsset: {
                    soldAssetId: this.state.soldAssetId,
                    assetId: this.props.match.params.id,
                    nameContact: this.state.nameContact,
                    contactId: this.state.contactId,
                    adressContact: this.state.adressContact,
                    saleDate: format(this.state.asset.saleDate, 'YYYY-MM-DD'),
                    dueDate: format(this.state.asset.saleDate, 'YYYY-MM-DD'),
                    productAndServiceId: this.state.asset.chartOfAccountType.id,
                    description: this.state.description,
                    numberOfAccount: 1,
                    total: parseFloat(this.state.totalPay),
                    payChannel: this.state.payChannel[0].slug,
                    remark: this.state.remark
                }
            }
        }

        let invoice = {
            id: '',
            issuedDate: format(this.state.asset.saleDate, 'YYYY-MM-DD'),
            dueDate: format(this.state.asset.saleDate, 'YYYY-MM-DD'),
            taxType: 'no_tax',
            remark: this.state.remark,
            chartOfAccountId: this.state.asset.chartOfAccountType.id,
            invoiceType: 'other',
            status: 'paid',
            approver: this.state.approver,
            updater: '',
            total: parseFloat(this.state.totalPay),
            historyShow: true
        }


        let transaction_list = [
            {
                description: this.state.description,
                discount: 0,
                id: "",
                preTaxAmount: parseFloat(this.state.totalPay),
                price: parseFloat(this.state.totalPay),
                productAndService: {
                    chartOfAccount: {
                        id: this.state.asset.chartOfAccountType.id,
                        chartOfAccountCode: this.state.asset.chartOfAccountType.chartOfAccountCode,
                        name: this.state.asset.chartOfAccountType.name,
                    },
                    id: this.state.asset.chartOfAccountType.id,
                    productCode: this.state.asset.chartOfAccountType.chartOfAccountCode,
                    name: this.state.asset.chartOfAccountType.name,
                    totalLeftInStock: null,
                    type: 'chart_of_account',
                    price: ''
                },
                totalLeftInStock: null,
                type: 'chart_of_account',
                unitItems: 1,
                vat: null,
                whtRate: 0,
                whtRatePercent: 0,
                price_type: ''
            }
        ]

        if (this.state.payChannel.length > 0) {
            for (const index in this.state.payChannel) {
                if (this.state.payChannel[`${index}`].cheque && this.state.payChannel[`${index}`].cheque.chequeImage) {

                    var new_file = new File([this.state.payChannel[`${index}`].cheque.chequeImage], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${this.state.payChannel[`${index}`].cheque.chequeImage.name}`);
                    let data = await upload(`/other_receive`, new_file);

                    await this.setState((prevState) => {
                        return _.set(_.cloneDeep(prevState), `payChannel[${index}].cheque.chequeImage`, data.key.replace("silverman-storage/private/", ""));
                    });
                }
            }
        }
        let input = {
            contactList: [this.state.contactId],
            invoice: JSON.stringify(invoice),
            transactionList: JSON.stringify(transaction_list),
            payChannel: JSON.stringify(this.state.payChannel),
            disappearAssetId: '',
            assetId: this.props.match.params.id,

        };


        const uploadables = this.state.upload;
        if (response.createAsset.ok) {
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        if (response?.createAndUpdateSoldAsset?.ok) {
                            CreateOtherReceive(input, uploadables, this.onSuccessOR, this.onError)
                        } else {
                            this.setState({ loading: false });
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({ loading: false });
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                    },
                },
            )
        } else {
            this.setState({ loading: false });
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');

        }
    }

    onSuccessOR = (response) => {

        this.setState({ loading: false });
        if (response?.createOtherReceiveAndOtherReceiveTransaction?.ok) {
            Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                this.setState({ redirect: true });
            });

        } else {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');

        }
    }

    onError = () => {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
    }

    updatePayChannel = (channel_list) => {
        let totalPay = 0;
        let upload = {};
        channel_list.forEach((channel, index) => {
            if (
                channel.slug === "small-change" ||
                channel.slug === "receipt-deposit" ||
                channel.slug === "other-expense"
            ) {
                if (
                    this.state.total - this.state.total_discount - this.state.totalPay >
                    0
                )
                    totalPay += parseFloat(channel.pay);
                else totalPay -= parseFloat(channel.pay);
            } else {
                totalPay += parseFloat(channel.pay);
            }
            if (channel.image) {
                upload[index] = channel.image;
            }
        });
        this.setState({
            payChannel: channel_list,
            totalPay: totalPay,
            upload: upload,
        });
    }



    render() {

        if (this.state.redirect) {
            // return <Redirect to={"/accounting/asset/all/form/"+this.state.asset.docNumber+"/"+this.props.match.params.id}/>;
            return <Redirect to='/accounting/asset/all' />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <div className='container-fluid box' id="asset-sold">
                        <div className="row justify-content-between d-flex align-items-center">
                            <div className="col-12">
                                <Translation>
                                    {
                                        t =>
                                            <h4 className="mb-4 form-inline">
                                                <Link to={"/accounting/asset/all/form/" + this.state.asset.docNumber + "/" + this.props.match.params.id}>
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back-new.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <div className="mt-1">การขาย</div>
                                            </h4>
                                    }
                                </Translation>
                            </div>
                        </div>
                        <Translation>
                            {t =>
                                <div className='content-inner pr-4'>
                                    {this.state.loadingData ?
                                        <span><span className="spinner-border spinner-border-sm align-middle mr-2" /> {i18next.t("Allaction:Preparing information")}</span>
                                        :
                                        <React.Fragment>
                                            <form id="asset" onSubmit={this.onSubmit}>
                                                <div className='row'>
                                                    <div className='col-2'>
                                                    <h6>{this.state.asset.docNumber && this.state.asset.docNumber} : {this.state.asset.name && this.state.asset.name}</h6>
                                                    </div>
                                                    <div className='col-2'>
                                                        <h6></h6>
                                                    </div>
                                                </div>
                                                <div className='row mt-24'>
                                                    <div className='col-6'>
                                                        <h6>ชื่อผู้ซื้อ</h6>
                                                        {this.state.contact &&
                                                            <SearchSelect onChange={this.handleChange}
                                                                value={this.state.nameContact}
                                                                name={"nameContact"} placeholder="รายการ"
                                                                queryObject={this.state.contact}
                                                                keyOfValue="name"
                                                                require={true}
                                                                keyOfLabel={"refNumber:name"}
                                                                contact_key={false}
                                                                disabled={this.props.match.params.status === 'view'}
                                                            />}
                                                    </div>
                                                    <div className='col-2'>
                                                        <h6>วันที่ตัดจำหน่าย</h6>
                                                        <DatePickerNoti
                                                            minDate={new Date(setDate(new Date, 1))}
                                                            className='form-control form-input-asset'
                                                            selected={this.state.asset.saleDate}
                                                            name='asset.saleDate'
                                                            onChange={this.handleChange}
                                                            width={20}
                                                            height={20}
                                                            disabled={this.props.match.params.status === 'view'}
                                                        />
                                                    </div>
                                                    <div className='col-2'>
                                                        {this.props.match.params.status === 'view' && this.state.otherReceive &&
                                                            <React.Fragment>
                                                                <h6>เลขที่เอกสารอ้างอิง</h6>
                                                                <input type='text' value={this.state.otherReceive.node.otherReceive.docNumber}
                                                                    readOnly={true}
                                                                    className='form-control form-input form-input-asset'
                                                                    disabled={this.props.match.params.status === 'view'}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    <div className='col-2' />
                                                </div>
                                                <hr />
                                                {this.state.contact_fisrt &&
                                                    <div className='row'>
                                                        <div className="col">
                                                            <Translation>{t => <h6>{t("asset:Address")}</h6>}</Translation>
                                                            <p>
                                                                {this.state.contact_fisrt?.node.registeredProvince.includes("กรุงเทพ") || this.state.contact_fisrt?.node.registeredProvince.includes("กทม") ? (
                                                                    <>
                                                                        {this.state.contact_fisrt.node.registeredAddress}
                                                                        {this.state.contact_fisrt.node.registeredDistrict && ` แขวง${this.state.contact_fisrt.node.registeredDistrict}`}
                                                                        {this.state.contact_fisrt.node.registeredCity && ` เขต${this.state.contact_fisrt.node.registeredCity}`}
                                                                        {this.state.contact_fisrt.node.registeredProvince && ` ${this.state.contact_fisrt.node.registeredProvince} `}
                                                                        {this.state.contact_fisrt.node.registeredPostalCode}{" "}
                                                                        {this.state.contact_fisrt.node.registeredCountry}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {this.state.contact_fisrt.node.registeredAddress}
                                                                        {this.state.contact_fisrt.node.registeredDistrict && ` ตำบล${this.state.contact_fisrt.node.registeredDistrict}`}
                                                                        {this.state.contact_fisrt.node.registeredCity && ` อำเภอ${this.state.contact_fisrt.node.registeredCity}`}
                                                                        {this.state.contact_fisrt.node.registeredProvince && ` จังหวัด${this.state.contact_fisrt.node.registeredProvince} `}
                                                                        {this.state.contact_fisrt.node.registeredPostalCode}{" "}
                                                                        {this.state.contact_fisrt.node.registeredCountry}
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                }



                                                {/* {this.state.asset.seller.id !== 'other_select' && 
                                            <div className='row mt-24'>
                                                <div className='col-6'>
                                                    <h6>ที่อยู่</h6>
                                                    <input type='text' 
                                                        name='adressContact'
                                                        onChange={this.handleChange}
                                                        value={this.state.adressContact}
                                                        className='form-control form-input form-input-asset'
                                                        disabled={this.props.match.params.status === 'view'}
                                                    />
                                                </div>
                                                <div className='col-6' />
                                            </div>} */}

                                                <h4 className='mt-48'>รายการ</h4>
                                                <div className='row'>
                                                    <div className='table-responsive col-12'>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th >รายการ</th>
                                                                    <th>บัญชี</th>
                                                                    <th >คำอธิบาย</th>
                                                                    <th>จำนวน</th>
                                                                    <th className='text-right'>ราคา/หน่วย (บาท)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {this.state.asset.chartOfAccountType.name || ''}
                                                                    </td>
                                                                    <td>
                                                                        {this.state.asset.chartOfAccountType.chartOfAccountCode || ''}
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" className='form-control'
                                                                            name='description'
                                                                            onChange={this.handleChange}
                                                                            value={this.state.description}
                                                                            disabled={this.props.match.params.status === 'view'}
                                                                        />
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        1
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" className='form-control text-right' disabled={true} value={isNaN(this.state.totalPay) ? 0.00 : numberWithComma(this.state.totalPay)} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                                <h4 className='mt-48'>ช่องทางการรับชำระ</h4>
                                                <hr />
                                                {this.props.match.params.status === 'create' ?
                                                    <PaymentChannel
                                                        updateReceiveChannel={this.updatePayChannel}
                                                        issued_date={new Date()}
                                                        receive_form={true}
                                                        pay={0}
                                                        total={0}
                                                        total_discount={0}
                                                        notReceiptDeposit={true}
                                                    />
                                                    :
                                                    this.state.otherReceive ?
                                                        <PayChannel
                                                            bankTransaction={
                                                                this.state.otherReceive.node.otherReceive.bankAccountTransaction.edges
                                                            }
                                                            cashTransaction={
                                                                this.state.otherReceive.node.otherReceive.cashTransaction.edges
                                                            }
                                                            chequeTransaction={
                                                                this.state.otherReceive.node.otherReceive.chequeDeposit.edges
                                                            }
                                                            creditCardTransaction={
                                                                this.state.otherReceive.node.otherReceive.creditCardTransaction.edges
                                                            }
                                                            unknownReceiveTransaction={this.state.otherReceive.node.otherReceive.unknownReceive.edges}

                                                        />
                                                        :
                                                        <div />

                                                }


                                                <h6 className='mt-48'>หมายเหตุ</h6>
                                                <div className='row pr-4'>
                                                    <div className='col-6'>
                                                        <textarea className='form-control' onChange={this.handleChange} name='remark' value={this.state.remark} disabled={this.props.match.params.status === 'view'} />
                                                    </div>
                                                    <div className='col-6 summary-box'>
                                                        <div className='row mt-3'>
                                                            <div className='col-4'>
                                                                <span>ราคาขาย</span>
                                                            </div>
                                                            <div className='col-8 float-right'>
                                                                <span className='text-right float-right'>{isNaN(this.state.totalPay) ? 0.00 : numberWithComma(this.state.totalPay)}</span>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className='row mb-3'>
                                                            <div className='col-4'>
                                                                <h5>มูลค่าทางบัญชี</h5>
                                                            </div>
                                                            <div className='col-8 float-right'>
                                                                <h5 className='text-right float-right'>{this.state.asset_amount >= 0 ? numberWithComma(this.state.asset_amount) : this.state.asset.carcassPrice}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {this.props.match.params.status === 'create' &&
                                                    <div className='row col-12 mt-48 mb-48 d-flex justify-content-end'>
                                                        {/* <ModalOR onSubmit={this.onSubmit} loading={this.state.loading} /> */}
                                                        <button type='submit' className='btn-confirm cursor'>
                                                            <span>บันทึก</span>
                                                        </button>
                                                    </div >
                                                }
                                            </form>

                                        </React.Fragment>
                                    }
                                </div>
                            }
                        </Translation>
                    </div>
                </WrapperContent>

            </Wrapper>
        )
    }
}

export default AssetSold;