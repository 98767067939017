/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type contactSummaryReceiveQueryVariables = {|
  contactId?: ?string,
  status?: ?string,
|};
export type contactSummaryReceiveQueryResponse = {|
  +countReceiveByStatus: ?number,
  +summaryReceiveByStatus: ?number,
|};
export type contactSummaryReceiveQuery = {|
  variables: contactSummaryReceiveQueryVariables,
  response: contactSummaryReceiveQueryResponse,
|};
*/


/*
query contactSummaryReceiveQuery(
  $contactId: String
  $status: String
) {
  countReceiveByStatus(contactId: $contactId, status: $status)
  summaryReceiveByStatus(contactId: $contactId, status: $status)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contactId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contactId",
    "variableName": "contactId"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "countReceiveByStatus",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "summaryReceiveByStatus",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contactSummaryReceiveQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contactSummaryReceiveQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5ef43b7105af432e4bdb44ee4a4f5748",
    "id": null,
    "metadata": {},
    "name": "contactSummaryReceiveQuery",
    "operationKind": "query",
    "text": "query contactSummaryReceiveQuery(\n  $contactId: String\n  $status: String\n) {\n  countReceiveByStatus(contactId: $contactId, status: $status)\n  summaryReceiveByStatus(contactId: $contactId, status: $status)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc32a0afc5f7cd83175b0a919ade00a3';

module.exports = node;
