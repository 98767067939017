import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { Redirect } from 'react-router'
import i18n from "i18next";
import Swal from "sweetalert2";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import API from '../../../api/setting'
import Navigation from './navigation'
// import SearchSelect from "../../../libs/searchSelect";
import Loading from "../../../libs/loading"
import '../settingSecurity.scss';
import _ from 'lodash';
// import { TRUE } from 'node-sass';
class settingPackingEstamp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectData: {
                amountCarofResident: [{ id: 1, value: 1 }, { id: 2, value: 2 }, { id: 3, value: 3 }, { id: 4, value: 4 }, { id: 0, value: "ไม่กำหนด" }],
                roleEstamp: [
                    { id: 1, value: 1 },
                    { id: 2, value: 2 },
                    { id: 3, value: 3 },
                    { id: 4, value: 4 },
                    { id: 6, value: 6 },
                    { id: 24, value: 24 },
                    { id: 0, value: i18n.t('settingSecurityCompany:Etc.') }],
            },
            backTab: false,
            redirect: false,
            role: null,
            account: null,

            amountCarofResident: 1,
            amountCarPerDay: 1,
            etampTimer: 1,
            etampTimerHH: null,
            etampTimerMM: null,
            etampTimerSS: null,

            loading: true
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.amountCarPerDay = this.amountCarPerDay.bind(this)
        this.amountCarPerResident = this.amountCarPerResident.bind(this)
        this.etampTimer = this.etampTimer.bind(this)
        this.ChangeDataInput = this.ChangeDataInput.bind(this)
    }

    componentWillMount() {
        this.getDataAccount();
        this.getDataEstamp();


    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    ChangeDataInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }


    getDataEstamp() {
        API.getEstampSetting().then((res) => {
            if (res.data.role) {
                this.setState({
                    role: res.data.role.settingParkingFeeEstampId,
                    amountCarofResident: res.data.role.amountCarofResident,
                    amountCarPerDay: res.data.role.amountCarPerDay,
                    etampTimer: res.data.role.etampTimer == null ? 0 : res.data.role.etampTimer,
                    etampTimerHH: res.data.role.etampTimer != null ? "" : res.data.role.etampTimerHH,
                    etampTimerMM: res.data.role.etampTimer != null ? "" : res.data.role.etampTimerMM,
                })
            } else {
                this.setState({ role: false })
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    getDataAccount() {
        API.getAccountSettingPacking().then((res) => {
            // console.log(res);
            if (res.data) {
                let account = []
                _.map(res.data, (n) => {
                    account.push(n)
                })
                this.setState({ account: account })
            }
        }, () => Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Unable to connect to the API at this time.'), 'error'))
    }

    amountCarPerDay(e) {
        let event = _.cloneDeep(e);
        this.setState({ amountCarPerDay: event.target.value })

    }
    etampTimer(e) {
        this.setState({ etampTimer: parseInt(e.target.value) })
    }
    amountCarPerResident(e) {
        let event = _.cloneDeep(e);
        this.setState({ amountCarofResident: event.target.value })
    }

    onSubmit(e) {
        e.preventDefault();

        let data = {
            "settingParkingFeeEstampId": this.state.role === null ? "" : this.state.role,
            "amountCarOfResident": this.state.amountCarofResident,
            "amountCarPerDay": this.state.amountCarPerDay,
            "etampTimer": this.state.etampTimer == 0 ? null : parseInt(this.state.etampTimer),
            "etampTimerHH": this.state.etampTimer != 0 ? null : parseInt(this.state.etampTimerHH) || 0,
            "etampTimerMM": this.state.etampTimer != 0 ? null : parseInt(this.state.etampTimerMM) || 0

        }

        API.saveEstampSetting(data).then((res) => {
            if (res.data.statusMessage === "SUCCESS") {
                Swal.fire(i18n.t('settingSecurityCompany:Successful'), i18n.t('settingSecurityCompany:Saved successful'), 'success').then(() => {
                    this.setState({ redirect: true })
                })
            } else {
                Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Save failed'), 'error')
            }
        }, () => {
            Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Unable to connect to the API at this time.'), 'error')
        })
    }


    render() {

        if (this.state.redirect) {
            return <Redirect to="/setting/setting-keep-packing/calculetepacking" />;
        }
        if (this.state.backTab) {
            return <Redirect to="setting/setting-keep-packing/all" />;
        }

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                   { t =>(
                    <WrapperContent>
                        <div className="container-fluid box" id="settingPacking">
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        <Link to="/setting">
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                alt="back" className="back" />
                                        </Link>
                                        <span style={{ fontWeight: 'bolder' }}>{t('setting:settingKeepPacking')}</span>
                                    </h3>
                                </div>
                            </div>

                            <Navigation />

                            {
                                this.state.loading == false &&
                                <div className='row'>
                                    <div className='col-sm-9 mx-auto'>
                                        <form onSubmit={this.onSubmit}>
                                            <div className="row mt-2">
                                                <div className="col-8 text-left">
                                                    <React.Fragment>
                                                        <div className='row' style={{ marginBottom: '29px' }}>
                                                            <div className='col'>
                                                                <label>{t('settingSecurityCompany:Permission')} &nbsp; <span className='badge badge-secondary'>E-Stamp</span></label>
                                                            </div>
                                                        </div>
                                                        <div className='row col' style={{ height: '48px', marginBottom: "17px" }}>
                                                            <label className='ml-2'>{t('settingSecurityCompany:Renter/Co-owner')}</label>
                                                            <span style={{ marginLeft: '140px' }}>{t('settingSecurityCompany:Total of cars')}</span>
                                                            <label style={{ marginLeft: '51px' }}>

                                                                <select name='amountCarsOfResident' value={this.state.amountCarofResident} required={true}
                                                                    className='form-control inline-input' style={{ width: '148px' }} onChange={this.amountCarPerResident}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="0">{t('settingSecurityCompany:Not specify')}</option>
                                                                </select>
                                                            </label>
                                                            <span style={{ marginLeft: '14px' }}>{t('settingSecurityCompany:Cars (rights)')}</span>
                                                        </div>
                                                        <div className='row col'>
                                                            <label className='ml-2'>{t('settingSecurityCompany:Visitor / Day')}</label>
                                                            <span style={{ marginLeft: '86px' }}>{t('settingSecurityCompany:Total of car')}</span>
                                                            <label style={{ marginLeft: '51px' }}>
                                                                <select name='amountCarPerDay' className='form-control inline-input' required={true} style={{ width: '148px' }}
                                                                    onChange={this.amountCarPerDay} value={this.state.amountCarPerDay}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="0">{t('settingSecurityCompany:Not specify')}</option>
                                                                </select>
                                                            </label>
                                                            <span style={{ marginLeft: '14px' }}>{t('settingSecurityCompany:Cars (rights)')}</span>
                                                        </div>
                                                        <div className='row' style={{ marginTop: '45px' }}>
                                                            <div className='col'>
                                                                <label>{t('settingSecurityCompany:Free parking period when using')}</label> <label className='tagLabel-blue'>E-Stamp</label>

                                                                <select name='etampTimer' value={this.state.etampTimer} required={true}
                                                                    className='form-control' onChange={this.etampTimer}>
                                                                    {
                                                                        this.state.selectData.roleEstamp.map((n) => {
                                                                            return (
                                                                                <option value={n.id} key={n.id}>{n.value}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </React.Fragment>


                                                    {
                                                        (this.state.etampTimer == 0 || this.state.etampTimer == null) &&
                                                        <React.Fragment>
                                                            <div className='row' style={{ marginTop: '24px' }}>
                                                                <div className='col'>
                                                                    <label>{t('settingSecurityCompany:Specify free parking time for')}</label> <label className='tagLabel-blue'>E-Stamp</label>
                                                                </div>
                                                            </div>
                                                            <div className='row col'>
                                                                <div style={{ display: 'inline-flex', width: '225px' }}>
                                                                    <input type="number" name='etampTimerHH' style={{ width: '118px' }} className='form-control text-right'
                                                                        placeholder='00'
                                                                        required={this.state.etampTimer === null} value={this.state.etampTimerHH || 0}
                                                                        minLength={2} maxLength={2} min={0} onChange={this.ChangeDataInput} />
                                                                    <span style={{ margin: '12px', marginRight: '26px' }}>{t('settingSecurityCompany:hour')}</span>
                                                                </div>

                                                                <div style={{ display: 'inline-flex', width: '225px' }}>
                                                                    <input type="number" style={{ width: '118px' }} className=' form-control text-right'
                                                                        placeholder='00' name='etampTimerMM'
                                                                        required={this.state.etampTimer === null} value={this.state.etampTimerMM || 0}
                                                                        minLength={2} maxLength={2} max={59} min={0} onChange={this.ChangeDataInput} />
                                                                    <span style={{ margin: '12px', marginRight: '26px' }}>{t('settingSecurityCompany:minute')}</span>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>

                                                    }

                                                </div>
                                            </div>


                                            <Translation>
                                                {t =>
                                                    <div className='row mt-2'>
                                                        <div className='col-12 text-right'>
                                                            <button type='submit' className='btn btn-primary'>{t('Allaction:save')}</button>
                                                        </div>
                                                    </div>

                                                }

                                            </Translation>


                                        </form>

                                    </div>
                                </div>

                            }

                            {
                                this.state.loading == true && <Loading/>
                            }
                        </div>
                    </WrapperContent>
                   )}
                </Translation>
            </Wrapper>
        )
    }
}

export default settingPackingEstamp;
