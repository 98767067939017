import React from "react";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import ComponentPagination from "../../libs/componentPagination";
import DatePickerNoti from "../../accounting/finance/depositInterest/datePickerNoti";
import SearchProject from "../../project/searchProject";
import format from "date-fns/format";
import {fetchQuery} from "relay-runtime";
import { QueryRenderer } from "react-relay";
import Loading from '../../libs/loading';
import { Translation } from "react-i18next";
import environment from "../../env/environment";
import { commitMutation } from "relay-runtime";
import { Redirect } from 'react-router';
import _ from 'lodash';
import Pagination from '../../libs/newPagination';
import numberWithComma from "../../libs/numberWithComma";
import Navigation from './navigation';
import JSZip from 'jszip';
import Swal from "sweetalert2";
import {encode} from 'base-64';
import i18next from 'i18next';
import querySilvermanCountNotiCollectionLawFirmQuery from "./query/silvermanCountNotiCollectionLawFirm"
import Select from 'react-select';
import getqueryAllRegisterCollectionLawFirmSite from "../settingCollectionLetterLaw/query/queryAllRegisterCollectionLawFirmSite"

const customStyles = {
  control: provided => ({
    ...provided,
    fontFamily: 'kanit',
    width:'300px'
  })
}

const query = graphql`
 query settingDocumentPaidTableQuery(
    $id:ID,
    $status:String,
    $first: Int, 
    $last: Int,
    $search: String,
    $startDate: DateTime,
    $endDate: DateTime,
    $siteId:ID
    ) {
  silvermanReceiveCollectionLawFirm
  (id:$id,status:$status,first: $first, last: $last, 
  search:$search,startDate:$startDate,endDate:$endDate,siteId:$siteId) {
          edges{
              node{
                id
                docNumber
                issuedDate
                status
                type
                total
                paid
                typePaid
                lawyerApproval

                fileName
                fileUpload

                billingName
                billingUpload

                receiptName
                receiptUpload

                language

                creator
                creatorDelete
                creatorApproval

                receiveCollectionLawFirmRecord{
                    edges{
                        node{
                            id
                            status
                            type
                            total
                            paid
                            creator
                            collectionLawFirm{
                                id
                                docNumber
                                totalDebt
                                totalFine
                                contact{
                                    id
                                    refNumber
                                    name
                                    firstName
                                    lastName
                                }
                                purchaseRecordGroupLawFirm{
                                    totalCount
                                    edges{
                                        node{
                                            purchaseRecordGroup{
                                                id
                                                docNumber
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }

  
  }
`;

const mutation = graphql`
mutation settingDocumentPaidTableMutation ($input: AllowReceiveCollectionLawFirmInput!) {
    allowReceiveCollectionLawFirm(input:$input){
        ok
        massage
    }
}`;
class settingDocumentPaidTable extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state = {
      checkAll: false,
      dataCollection: [],
      checkItem: "",
      status : this.props.status ? this.props.status : "",
      checkList: [],
      carReportDocList:[],
      check_all: false, 
      page: 0,
      last: 10,
      tempStartDate: null,
      tempEndDate: null,
      search : '',
      startDate : null,
      endDate : null,
      billingUploadList:[],
      redirect:false,
      countFollow:null,
      countPaid:null,
      getAllSite: [],
      siteID:null,
      mySideID:null,
      siteNameSelected: null
    }

    this.getStatusLabel = this.getStatusLabel.bind(this)
    this.appendToCheckList = this.appendToCheckList.bind(this)
    this.getList = this.getList.bind(this)
    this.checkList = this.checkList.bind(this)
    this.allowPRLF = this.allowPRLF.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.print = this.print.bind(this)
    this.onChangeSite = this.onChangeSite.bind(this);
    this.getAllSite = this.getAllSite.bind(this)
    this.callSettingBySite = this.callSettingBySite.bind(this)
  }


  componentDidUpdate(){
    if(this.props.status !== this.state.status){
        this.setState({status : this.props.status})
    }
  }

  componentDidMount(){
    this.queryCountNoti(localStorage.getItem("site_id"))
    this.getAllSite()
   }
   
  componentWillMount() {
    this.goFirst()
    if(!this.state.check_all){
      this.setState({
          check_all:false
      })
    }
  }

  onChangeSite = (e) => {
    let splitSiteName = e?.site?.name
    this.setState({
        siteID: e,
        siteNameSelected: splitSiteName,
    })
  }

  callSettingBySite(){
    this.queryCountNoti(this.state.siteID.value)
    if(this.state.siteID.value !== localStorage.getItem("site_id")){
      let domain = this.state.siteNameSelected.split(".")
      let testLink = this.state.siteID.value === "331" ?`http://primhome.port80.me:3000/changeSite`: this.state.siteID.value === "163" ? `http://primhome.port80.me:3001/changeSite` : ''
      let test = false
      let checkSite = test ? testLink :`https://${domain[0]}-admin.${domain[1]}.${domain[2]}/changeSite`
      let checkLocal = checkSite
  
      let toLinkUrl = checkLocal + `/${"admin-svmbackend"}`
      window.open(toLinkUrl, '_blank').focus()
    }else{
      this.setState({ mySideID : encode("SiteNode:"+this.state.siteID.value), })
    }
  }

  queryCountNoti(id){
    let idSite = encode("SiteNode:"+id)
      fetchQuery(environment,querySilvermanCountNotiCollectionLawFirmQuery,{siteId:idSite ? idSite : encode("SiteNode:" + localStorage.getItem("site_id"))}).then(response => { 
        if(response.silvermanCountNotiCollectionLawFirm){
          this.setState({
            countFollow: response.silvermanCountNotiCollectionLawFirm.countFollow || null,
            countPaid: response.silvermanCountNotiCollectionLawFirm.countPaid || null
        })
        }
    })
  }

  getAllSite() {
    fetchQuery(environment, getqueryAllRegisterCollectionLawFirmSite()).then(data => {
      let data_clone = _.cloneDeep(data);

      let getAllSiteData = data_clone?.allRegisterCollectionLawFirmSite?.edges?.map((n) => (
       { ...n.node, value: n.node.site.id, label: n.node.site.project?.edges[0]?.node?.nameTh }))

      let optionSearch = [...getAllSiteData]
 
       this.setState({
           getAllSite: optionSearch,
       })
   }
   );
  }

  handleChange(event){
    this.setState({ [event.target.name]: event.target.value });
  }

  handleKeyDown(event) {
      if (event.key === "Enter") {
        this.handleSearch(event);
        this.setState({
            startDate: new Date(new Date(this.state.tempStartDate).setHours(0,0,0,0)),
            endDate:new Date(new Date(this.state.tempEndDate).setHours(24,0,0,0)),
        })
      }
  }
    
  handleSearch = (text) => {
      this.setState({
          search: text,
          startDate:  this.state.tempStartDate ? new Date(new Date(this.state.tempStartDate).setHours(0,0,0,0)) : null,
          endDate: this.state.tempEndDate ?  new Date(new Date(this.state.tempEndDate).setHours(24,0,0,0)) : null,
      })
  }

  checkList(id) {
    const evenNumbers = this.state.checkList.filter(number => number.id === id);
    return evenNumbers.length >= 1 ? true : false
  }

  getList(props) {
   
    let checklist = [...this.state.checkList];

    let check_all = false;

    if (!this.state.check_all) check_all = true;

    if ((check_all && (checklist.length === props.length)) || !check_all ) {
        checklist = [];

    } else {
        if (checklist.length !== 0) {
            checklist = [];
        }
        const {page,last} = this.state;
        let dataItems = props.slice(page * last, (page + 1) * last);

        dataItems.forEach((item) => {
             checklist.push({
              id:item.node.id,
              linkrecived:item.node.receiptUpload,
              linkinvoice:item.node.billingUpload
             });
           //  checklist.push(item.node.id);
        });
    }

    if(this.state.checkList) {
        this.setState({checkList: checklist, check_all: check_all})
    }else {
        this.setState({checkList: checklist, check_all: check_all}) 
    }
         
  }

  appendToCheckList(data) {

    let id = data.id
    let linkrecived = data.receiptUpload
    let linkinvoice = data.billingUpload

      //id
        let current_checklist = [...this.state.checkList];
        const evenNumbers = current_checklist.filter(number => number.id === id);

        if(evenNumbers.length >= 1) {
            //out
             current_checklist.splice(current_checklist.indexOf(id), 1)
        }else{
            //in
            current_checklist.push({
              id:id,
              linkrecived:linkrecived,
              linkinvoice:linkinvoice
            });
        }

      this.setState({checkList: current_checklist})
  }
  

  regName(url){
    const regex = /^(.*?)(\?|$)/;
    const matches = url.match(regex);
    return matches[1]
  }

  async print (filterName){

    let filter = this.state.checkList.map(
      item =>  {if(item[filterName] !== ''){return item[filterName]}}
    );
    //TODO: check if no file will alert 

    const zip = new  JSZip(); 

    await Promise.all(filter.map(async (pdfUrl) => {
      const response = await fetch(pdfUrl);
      if (response.ok) {
        const pdfData = await response.arrayBuffer();
        const fileName = pdfUrl.substring(pdfUrl.lastIndexOf('/') + 1);
        let refileName = this.regName(fileName)
        zip.file(refileName, pdfData);
      } else {
        console.error(`Failed to fetch ${pdfUrl}`);
      }
    }));

    zip.generateAsync({ type: 'blob' }).then((content) => {
      const zipBlob = new Blob([content], { type: 'application/zip' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = `${filterName}.zip`;
      downloadLink.click();
    });
  }


  allowPRLF(){
    const input2 = _.map(this.state.checkList, (item) => {
      return {
        "Id": item.id, // Use 'item' here instead of 'this.state.checkList'
        "Accept": "True",
        "voidNote": "",
        "fileName": "",
        "fileUpload": "",
        "billingName": "",
        "billingUpload": "",
        "receiptName": "",
        "receiptUpload": ""
      };
    });

  let cloneData = [...input2]
       let input = {
            "input":{
               "jsonObject":{
                   "ReceiveAccept":cloneData                 
                 }                  
             }
       }
      Swal.fire({
        imageUrl: '/images/iconAlerts/questionBlue.png',
        text: `ยืนยันการทำรายการอนุมัติการรับชำระค่าบริการออกหนังสือติดตามหนี้จำนวน ${this.state.checkList.length} รายการ`,
        showCancelButton: true,
        confirmButtonColor: '#1567B4',
        cancelButtonText: i18next.t("Allaction:cancel"),
        confirmButtonText: i18next.t("Allaction:Confirm"),
        reverseButtons:true
      }).then((result) => {
        if (result.value) {
          commitMutation(environment, {
            mutation,
            variables: input,
            onCompleted: (response) => {
              this.setState({ loading: false });
              if (response.allowReceiveCollectionLawFirm?.ok) {
                Swal.fire(i18next.t("Allaction:successcompleate"), 'สำเร็จ', 'success').then(() => {
                  // Handle success case
                  this.setState({
                    redirect:true
                  })
                });
              } else {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() => {
                  // Handle failure case
                });
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
            },
          });
        }
      });  

  }

  getStatusLabel(status){
    let text = ""
    let getColor = ""
    
    switch (status) {
      case "WAIT":
        text = `${i18next.t("graudreport:complete")}`;
        getColor = 'clf-label-green'
        break;
      case "PAID":
        text = `${i18next.t("collectionLawFirm:Waiting to Paid")}`;
        getColor = 'clf-label-red';
        break;
    }
    return(
        <span className={`${getColor}`}>{text}</span>
    )
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/setting/lawyer-summary-report/paid'/>
    }

    return (
      <Translation>
      {
      (t) => (
      <React.Fragment>
         <div className="row col-12">
          <div className="col">
                <div className="d-flex justify-content-end p-2">
                  <Select
                        id="siteID"
                        name="siteID"
                        className="select pr-3"
                        options={this.state.getAllSite}
                        // value={this.state.siteID}
                        isSearchable={true}
                        onChange={(e) => this.onChangeSite(e)}
                        placeholder ={t("project_info:Project name")}
                        styles={customStyles}
                    />
                   <button type='button' onClick={this.callSettingBySite} className='btn btn-primary'>
                   {t("FixRequestReport:Search")}</button>
                </div>
            </div>
         </div>
          <div className="row col-12">
            <div className="col">
              <Navigation countFollow={this.state.countFollow} countPaid={this.state.countPaid}/>
            </div>
            <div className="col d-flex justify-content-end">
              <div className="">
              <div className="p-2">
                {
                  this.state.status !== 'paid' && 
                    <button type="button" className="approveLightBtn" onClick={this.allowPRLF}> 
                    {t("collectionLawFirm:Approved")} </button>
                }
                <button type="button" className="btn btn-primary ml-3" onClick={()=> this.print('linkinvoice')}>
                  <img src={process.env.PUBLIC_URL + '/images/icons/printer-white.png'} alt="plus" className="mr-1" style={{width:'16px' ,height:'16px'}}/>
                  {t("collectionLawFirm:Print Invoice")}
                </button>
                <button type="button" className="btn btn-primary ml-3" onClick={()=> this.print('linkrecived')}>
                  <img src={process.env.PUBLIC_URL + '/images/icons/printer-white.png'} alt="plus" className="mr-1" style={{width:'16px' ,height:'16px'}}/>
                  {t("collectionLawFirm:Print Receive")}
                </button>
              </div>
            </div> 
            </div>
          </div>  
          <div className="col-12">
            <div className="row d-flex justify-content-end">
                <div>
                <span className="mr-2">
                {t("Acccount_Purchase:Date")}</span>
                </div>
                <div className="ml-2">
                  <DatePickerNoti 
                    selected={this.state.tempStartDate}
                    name='tempStartDate'
                    height="20px"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="ml-2">
                    <span className="mr-1 ml-1 mr-2">
                    {t("PageList:To")}</span>
                </div>
                <div className="ml-2">
                  <DatePickerNoti 
                    selected={this.state.tempEndDate}
                    name='tempEndDate'
                    className="mr-2"
                    height="20px"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="ml-2">
                  <SearchProject callback_function={this.handleSearch} search={this.state.search} height={'44px'}/>
                </div>  
            </div>                 
          </div>

          <QueryRenderer
              environment={environment}
              query={query}
              fetchPolicy="no-cache"
              variables={{ 
                status: this.state.status,  
                first: this.state.first,
                last: this.state.last,
                search: this.state.search,
                startDate : this.state.startDate,
                endDate : this.state.endDate,
                siteId: this.state.mySideID ? this.state.mySideID  : encode("SiteNode:" + localStorage.getItem("site_id"))
              }}
              render={({ error, props }) => {
                if (props) {
                  let data = props.silvermanReceiveCollectionLawFirm && props.silvermanReceiveCollectionLawFirm.edges
                  return (
                    <React.Fragment>
                    <div className="table-responsive fade-up card mt-3 table-container">
                      <table id="table-collection" className="table table-hover">
                        <thead className="thead-light headTable">
                          <tr >
                              <th className="text-center" style={{width:'50px'}}>
                                <input type="checkbox" checked={this.state.check_all} 
                                onChange={() => this.getList(data)}
                                />
                              </th>

                            <th className="text-left" style={{width:'190px'}}>{t("collectionLawFirm:Document No.")}</th>
                            <th className="text-left" style={{width:'140px'}}>{t("collectionLawFirm:Payment Docs No.")}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:No.')}</th>
                            <th className="text-center" style={{width:'140px'}}>{t('collectionLawFirm:Unit No.')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t("collectionLawFirm:Document Date")}</th>
                            <th className="text-left" style={{width:'190px'}}>{t('collectionLawFirm:Name')}</th>
                            <th className="text-left" style={{width:'190px'}}>{t('collectionLawFirm:Total Due (Baht)')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:Create Date')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:Service charge (Baht)')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:Total Service charge (Baht)')}</th>
                            <th className="text-center" style={{width:'210px'}}>{t('collectionLawFirm:Status')}</th>
                            <th className="text-left" style={{width:'140px'}}>{t('collectionLawFirm:Creator')}</th>
                            <th className="text-left" style={{width:'150px'}}>{t('collectionLawFirm:Invoice Document')}</th>
                            <th className="text-left" style={{width:'150px'}}>{t('collectionLawFirm:Receipt Document')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          {
                             props?.silvermanReceiveCollectionLawFirm?.edges.length >= 1 ?
                            props?.silvermanReceiveCollectionLawFirm?.edges && _.map(props?.silvermanReceiveCollectionLawFirm?.edges,(item) => {
                                  return (
                                    <>
                                          <tr>
                                              <td className="text-center">
                                                <input
                                                type="checkbox"
                                                onChange={() => this.appendToCheckList(item.node)}
                                                checked={this.checkList(item.node.id)} 
                                                />
                                            </td>
                                              <td className="text-left">
                                                <Link to={`/setting/lawyer-summary-report/detail/${item.node.id}`}>{item.node.docNumber || '-'}</Link>
                                              </td>
                                              <td className="">
                                                <Link to={`/accounting/expense/purchase/purchase-detail/${item?.node?.receiveCollectionLawFirmRecord?.edges?.length > 0 ? 
                                                    item?.node?.receiveCollectionLawFirmRecord?.edges[0]?.node?.collectionLawFirm?.purchaseRecordGroupLawFirm?.edges[0]?.node?.purchaseRecordGroup?.id :''}`}>
                                                  {
                                                    item?.node?.receiveCollectionLawFirmRecord?.edges?.length > 0 && 
                                                    item?.node?.receiveCollectionLawFirmRecord.edges[0]?.node?.collectionLawFirm?.purchaseRecordGroupLawFirm?.edges[0]?.node?.purchaseRecordGroup?.docNumber || '-'
                                                  }
                                                  </Link>
                                              </td>
                                              <td className="">
                                                <Link to={`/accounting/collection-law-firm/detail/${
                                                        item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                        item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.id
                                                }`}>
                                                  {
                                                    item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                    item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.docNumber || '-'
                                                  }
                                                  </Link>
                                              </td>
                                              <td className="text-center">
                                                  {
                                                    item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                    item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.contact.name || '-'
                                                  }
                                              </td>
                                              <td className="text-left">{format(item.node.issuedDate,'DD-MM-YYYY') || '-'}</td>
                                              <td className="text-left">
                                                  {  
                                                    item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                    item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.contact.firstName + " " + item?.node?.receiveCollectionLawFirmRecord.edges[0].node.collectionLawFirm.contact.lastName || '-'
                                                  }  
                                              </td>
                                              <td className=""> 
                                                  {
                                                    item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                    numberWithComma(item?.node?.receiveCollectionLawFirmRecord.edges[0].node.total) || '-'
                                                  }
                                              </td>
                                              <td className="">{format(item.node.issuedDate,'DD-MM-YYYY') || '-'}</td>
                                              <td className="">
                                                  {
                                                    item?.node?.receiveCollectionLawFirmRecord.edges.length > 0 && 
                                                    numberWithComma(item?.node?.receiveCollectionLawFirmRecord.edges[0].node.paid) || '-'
                                                  }
                                              </td>
                                              <td className="text-left">-</td>
                                              <td className="text-center">{ this.getStatusLabel(item.node.status) || '-'}</td>
                                              <td className="">{item.node.creator || '-'}</td>
                                              <td className="">
                                                {
                                                  item.node.billingUpload && 
                                                  <a href={item.node.billingUpload || ''} target="_blank">{item.node.billingName || '-'}</a>
                                                }
                                              </td>
                                              <td className="">
                                                {
                                                  item.node.receiptUpload && 
                                                  <a href={item.node.receiptUpload || ''} target="_blank">{item.node.receiptName || '-'}</a>
                                                }
                                              </td>
                                          </tr>  
                                          {
                                            //
                                            item?.node?.receiveCollectionLawFirmRecord.edges &&
                                            _.map(item?.node?.receiveCollectionLawFirmRecord.edges, (itemReceiveCollection,index) => {
                                              if(index > 0){
                                                  let id = itemReceiveCollection.node.collectionLawFirm.id
                                                  let docNumber = itemReceiveCollection.node.collectionLawFirm.docNumber
                                                  let totalDebt = itemReceiveCollection.node.collectionLawFirm.totalDebt
                                                  let totalFine = itemReceiveCollection.node.collectionLawFirm.totalFine
                                                  let totalSum =  totalFine + totalDebt
                                                  let fullName =  itemReceiveCollection.node.collectionLawFirm.contact.firstName + " " + itemReceiveCollection.node.collectionLawFirm.contact.lastName
                                                  let numberOfRoom =  itemReceiveCollection.node.collectionLawFirm.contact.name
                                                  let to = `/accounting/collection-law-firm/detail/${id}`
                                                return(
                                                  <tr key={index}>
                                                      <td className=""></td>
                                                      <td className="text-left"> </td>
                                                      <td className="text-left"> </td>
                                                      <td className="">
                                                       {
                                                         <Link to={to}> {docNumber || '-'}</Link>                 
                                                        }
                                                      </td>
                                                      <td className="text-center">{numberOfRoom || '-'}</td>
                                                      <td className="">{format(item.node.issuedDate,'DD-MM-YYYY') || '-'}</td>
                                                      <td className="">{fullName}</td>
                                                      <td className="">{numberWithComma(totalDebt) || '-'}</td>
                                                      <td className="">{format(item.node.issuedDate,'DD-MM-YYYY') || '-'}</td>
                                                      <td className="">{numberWithComma(totalFine) || '-'}</td>
                                                      <td className="">{numberWithComma(totalSum) || '-'}</td>
                                                      <td className="text-center">{this.getStatusLabel(item.node.status) || '-'}</td>
                                                      <td className=""></td>
                                                      <td className="">
                                                        {
                                                          item.node.billingUpload && 
                                                          <a href={item.node.billingUpload || ''} target="_blank">{item.node.billingName || '-'}</a>
                                                        }
                                                      </td>
                                                      <td className="">
                                                        {
                                                          item.node.receiptUpload && 
                                                          <a href={item.node.receiptUpload || ''} target="_blank">{item.node.receiptName || '-'}</a>
                                                        }
                                                      </td>
                                                  </tr>  
                                               )
                                              }
                                            }) 
                                          }
                                    </>
                                  )
                            })  :<tr><td colSpan={15} className="text-center">{t('collectionLawFirm:No Data')}</td></tr>
                          }
                        </tbody>
                      </table>
                    </div>
                      {/* <div className="row d-flex justify-content-end col-12 pt-4">
                        <div>
                            <span className="rclf-sum">ยอดค่าบริการค้างชำระทั้งหมด : {props.summaryReceiveCollectionLawFirm} บาท</span>
                        </div>
                      </div> */}
                      <div className="row col-12">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={props?.silvermanReceiveCollectionLawFirm?.edges.length}
                        />
                      </div>
                    </React.Fragment>
                  )
                } else {
                  return <Loading/>
                }
              }}
            />   
      </React.Fragment>)}
      </Translation>
    );
  }
}

export default settingDocumentPaidTable;
