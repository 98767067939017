import {
    commitMutation,

} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";


const mutation = graphql`
 mutation ChequeDepositMutation($input: UpdateChequeDepositInput!){
     updateChequeDeposit(input: $input){
        ok
        warningText
        }
    }
`;


export default function ChequeDepositMutation(input, uploadables, callback, error_callback) {
    const variables = {
        input: input
    };


    commitMutation(
        environment,
        {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
