import React, { Component } from "react";
import numberWithComma from "../../../libs/numberWithComma";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import LightBox from "../../../libs/lightBox";
import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import i18n from 'i18next';
import paymentGatewayText from "../../../libs/paymentGatewayText";
import Swal from "sweetalert2";
import { commitMutation } from 'react-relay'
import accounting from "../../../api/accounting";
import i18next from "i18next";
import upload from "../../../libs/upload";
import UpdateChequeReceiveMutation from "./mutation/updateChequeReceive"

const query = graphql`
    query payChannelQuery($invoiceId: String) {
            resultNotAutoReconcileLog (invoiceId: $invoiceId){
                resultCompare
                resultText
            }
        }
        
`;

const mutation = graphql`
    mutation payChannelMutation($input: UpdateUnknownReceiveInput!){
        updateUnknownReceive(input: $input){
            newUnknownReceive {
                id
            }
            ok
            warningText
        }
    }`;
class PayChannel extends Component {
    constructor(props) {
        super(props);

        //รับล่วงหน้า 2210 รายรับจากการปรับเศษทศนิยม 4300 ค่าธรรมเนียมธนาคาร 5400 เงินคืนอื่นๆ 5830 2230 เงินรอตรวจสอบ
        let otherCode = ["2210-01", "2210-02", "2210-03", "4300-12", "4300-14", "5400-07", "5830-01", "2230-01", "2125-03"];
        // let otherCode = ["2210" , "4300-12"  , "5400-07" ,"5830-01","2230-01" , "2125-03"];
        this.state = {
            other_price: 0,
            otherCode: otherCode,
            payTransaction: {},
            resultCompare: 'True',
            resultText: '',
            fileUpload: [],
            fileName: '',
            editImagePayTransaction: [],
            fileUploadUnknown: [],
            fileNameUnknown: '',
            editImageUnknownTransaction: [],
            editImageChequeTransaction: [],
            fileNameCheque: '',
            fileUploadCheque:[],
            reloading: false,
        }
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
    }

    isOther(code) {
        //รับล่วงหน้ารหัส 2210 จะผ่านทุกรหัส
        if (_.startsWith(code, '2210')) {
            return true;
        }
        else if (_.indexOf(this.state.otherCode, code) >= 0) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        {
            this.props.account_type === 'draft' &&
                this.cutString(this.props.payType)
        }
        if (this.props.receive_transactions) {
            this.getData()
        }

    }

    getData = () => {
        let variables = { "invoiceId": this.props.receive_transactions[0].node.transaction.invoice.id ? this.props.receive_transactions[0].node.transaction.invoice.id : 'none' }
        fetchQuery(environment, query, variables).then(response_data => {
            if (response_data.resultNotAutoReconcileLog.resultCompare === 'False') {
                let resultCode = response_data.resultNotAutoReconcileLog.resultText
                let codes = resultCode.split("SE");
                let resultText = ''
                codes.forEach(element => {
                    if (element === '001') { resultText += 'SE001 ยอดเงินในสลิปต่ำกว่า 0 ' }
                    if (element === '002') { resultText += 'SE002 ยอดเงินใบแจ้งหนี้และสลิปไม่เท่ากัน ' }
                    if (element === '003') { resultText += 'SE003 วันที่ชำระเงินในสลิปเกิดก่อนวันที่ออกใบแจ้งหนี้ กรุณาสร้างรับล่วงหน้าก่อนตัดชำระ ' }
                    if (element === '004') { resultText += 'SE004 วันที่ในสลิปและวันที่แจ้งชำระเงินไม่อยู่ในช่วงเดือนเดียวกัน ' }
                    if (element === '005') { resultText += 'SE005 วันที่ในสลิปและวันที่ออกใบแจ้งหนี้ไม่อยู่ในช่วงเดือนเดียวกัน ' }
                    if (element === '006') { resultText += 'SE006 วันที่ใบแจ้งหนี้และวันที่ที่ลูกบ้านแจ้งชำระมาในแอปพลิเคชั่นไม่อยู่ในช่วงเดือนเดียวกัน ' }
                    if (element === '007') { resultText += 'SE007 ชื่อบัญชีหรือเลขบัญชีไม่ตรงกับในระบบ ' }
                    if (element === '008') { resultText += 'SE008 สลิปที่แนบซ้ำกับในระบบ ' }
                    if (element === '009') { resultText += 'SE009 อื่นๆ ' }
                });

                this.setState({ resultCompare: 'False', resultText: resultText, reloading: false })
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props) {
            this.setState({ other_price: 0 }, () => {
                this.calOtherPrice()
            });
        } 
    }

    calOtherPrice() {
        let sum_bank = this.props.bankTransaction.reduce(function (total, obj) {
            return total + parseFloat(obj.node.price)
        }, 0);
        let sum_cash = this.props.cashTransaction.reduce(function (total, obj) {
            return total + parseFloat(obj.node.price)
        }, 0);
        let sum_cheque = this.props.chequeTransaction.reduce(function (total, obj) {
            return total + parseFloat(obj.node.price)
        }, 0);
        let sum_credit_card = 0;
        if (this.props.creditCardTransaction && this.props.creditCardTransaction.length > 0) {
            sum_credit_card = this.props.creditCardTransaction.reduce(function (total, obj) {
                return total + parseFloat(obj.node.amount)
            }, 0);
        }

        let sum_pay = sum_bank + sum_cash + sum_cheque + sum_credit_card;

        this.setState({ other_price: Math.abs(this.props.total - sum_pay) });
    }

    cutString(payType) {
        let payTransactionBank = []
        let payTransactionCash = []
        let payTransactionCheque = []
        let payTransactionOther = []
        let total = 0

        payType.forEach((value) => {
            if (RegExp('ธนาคาร').test(value)) {
                if (RegExp('เลขที่เช็ค').test(value)) {
                    let transaction = value.split(",")
                    payTransactionCheque.push({ type: 'cheque', bankName: transaction[0].split(":")[1].replace("ธนาคารธนาคาร", "ธนาคาร"), bankAccount: transaction[1].split(":")[1], chequeNumber: transaction[2].split(":")[1], price: transaction[3].split(":")[1].replace("}", "") })
                } else {
                    let transaction = value.split(",")
                    payTransactionBank.push({ type: 'bank', bankName: transaction[0].split(":")[1].replace("ธนาคารธนาคาร", "ธนาคาร"), bankAccount: transaction[1].split(":")[1], price: transaction[2].split(":")[1].replace("}", "") })
                }
            } else if (RegExp('เงินสด').test(value)) {
                let transaction = value.split(",")
                total += parseFloat(transaction[1].split(":")[1].replace("}", ""))
            } else {
                let transaction = value.split(",")
                payTransactionOther.push({ type: 'other', detail: transaction[0].split(":")[1], price: transaction[1].split(":")[1].replace("}", "") })
            }
        })
        total !== 0 && payTransactionCash.push({ type: 'cash', price: total })
        this.setState({
            payTransaction: { payTransactionBank, payTransactionCheque, payTransactionOther, payTransactionCash },
        })
    }

    //uploadFileMore

    uploadFile = (e, type, index) => {
        let data = _.cloneDeep(e);
        if (type === 'unKnown') {
            this.setState({
                fileUploadUnknown: { ...this.state.fileUploadUnknown, [index]: data.target.files[0] },
                fileNameUnknown: { ...this.state.fileNameUnknown, [index]: data.target.files[0].name }
            });
        }else if (type === 'cheque') {
            this.setState({
                fileUploadCheque: { ...this.state.fileUploadCheque, [index]: data.target.files[0] },
                fileNameCheque: { ...this.state.fileNameCheque, [index]: data.target.files[0].name }
            });
        }
        
        else {
            this.setState({
                fileUpload: { ...this.state.fileUpload, [index]: data.target.files[0] },
                fileName: { ...this.state.fileName, [index]: data.target.files[0].name }
            });
        }
    }
    

    callUpload = async (file) => {
        var new_file = new File([file], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${file.name}`);
        return new Promise(async (resolve, reject) => {
            let res = await upload(`/receive`, new_file);
            resolve(res);
        });
    };

    saveSlipPayment = (id, index) => {
        let fileUpload = this.state.fileUpload[index];
        let variables = {
            "input": {
                "bankAccountTransactionId": id
            }
        }
        let imageSlip = fileUpload

        let dataForm = new FormData()

        dataForm.append('query', 'mutation updateBankAccountTransaction($input:UpdateBankAccountTransactionInput!){\n   updateBankAccountTransaction(input:$input)          {\n      ok\n      message\n    }\n}');
        dataForm.append('variables', '{"input":{"bankAccountTransactionId":"' + id + '"\n    }\n}');
        dataForm.append('imageSlip', imageSlip);

        accounting.income.editSlipPayMent(dataForm).then((res) => {
            Swal.fire({
                type: 'success',
                title: i18n.t("Allaction:Saved Successfully"),
                text: i18n.t("Allaction:successcompleate"),
            }).then((ress) => {
                window.location.reload()
            })
        })
    }

    updateUnknownReceive = async (id, index) => {
        let fileUpload = this.state.fileUploadUnknown[index];
        let imageSlip = "";
        if (fileUpload.size <= 10 * 1024 * 1024) {
            try {
                let data = await this.callUpload(fileUpload);
                await this.setState((prevState) => ({
                    fileUploadUnknown: data.key.replace("silverman-storage/private/", ""),
                }));
                imageSlip = this.state.fileUploadUnknown;
            } catch (error) {
                console.error('Error uploading file:', error);
            }
    
            let variables = {
                "input": {
                    "clientMutationId": id,
                    "imageSlip": imageSlip,
                }
            }
    
            commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response.updateUnknownReceive.ok) {
                        Swal.fire({
                            type: 'success',
                            title: i18n.t("Allaction:Saved Successfully"),
                            text: i18n.t("Allaction:successcompleate"),
                        }).then((ress) => {
                            window.location.reload();
                        });
                    }
                },
                onError: (err) => {
                    Swal.fire("Error!", i18n.t('createReceive:Please try again'), "warning");
                    this.setState({ loading: false });
                },
            });
        } else if(fileUpload.size > 10 * 1024 * 1024) {
            Swal.fire('Error!', i18n.t("unknown_receive:File size exceeds the limit of 10 MB. Please choose a smaller file"), 'warning')
        } else {
            Swal.fire("Error!", i18n.t('createReceive:Please try again'), "warning");
        }
    }

    updateChequeReceive = async (id, index) => {
        let fileUpload = this.state.fileUploadCheque[index];
        let imageSlip = "";
        if (fileUpload.size <= 10 * 1024 * 1024) {
            try {
                let data = await this.callUpload(fileUpload);
                await this.setState((prevState) => ({
                    fileUploadCheque: data.key.replace("silverman-storage/private/", ""),
                }));
                imageSlip = this.state.fileUploadCheque;
            } catch (error) {
                console.error('Error uploading file:', error);
            }
    
            let variables = {
                "id": id,
                "image": imageSlip,
            }

            UpdateChequeReceiveMutation(variables,'',this.onSuccess, this.onError)

        } else if(fileUpload.size > 10 * 1024 * 1024) {
            Swal.fire('Error!', i18n.t("unknown_receive:File size exceeds the limit of 10 MB. Please choose a smaller file"), 'warning')
        } else {
            Swal.fire("Error!", i18n.t('createReceive:Please try again'), "warning");
        }
    }

    onSuccess(response) {
        if (response.updateImageChequeDeposit.ok) {
            Swal.fire({
                type: 'success',
                title: i18n.t("Allaction:Saved Successfully"),
                text: i18n.t("Allaction:successcompleate"),
            }).then((ress) => {
                window.location.reload();
            });
        } else {
            Swal.fire("Error!", i18n.t('createReceive:Please try again'), "warning");
            this.setState({ loading: false });
        }
    }

    onError() {
        this.setState({loading: false});
        Swal.fire(i18n.t("cheque:Save unsuccessfully"), '', 'warning');
    }

    uniqueCheques() {
        const uniqueCheques = [];
        let isSameCheque = null;
        if (this.props.account_type === 'pay' && this.props.chequeTransaction.length !== 0) {
            // เช็ค id chequeNumber date และ price เหมือนกันมั้ย
            this.props.chequeTransaction.forEach((cheque) => {
                const key = `${cheque.node.bankAccount.id}-${cheque.node.chequeNumber}-${cheque.node.date}-${cheque.node.price}`;
                if (isSameCheque !== key) {
                    isSameCheque = key;
                    uniqueCheques.push(cheque);
                }
            });
        }
        return uniqueCheques;
    }

    delItemAttach(index, type) {
        
        if(type === "unKnow"){
            this.setState({
                editImageUnknownTransaction: {
                    ...this.state.editImageUnknownTransaction,
                    [index]: false,
                },
                fileNameUnknown: {
                    ...this.state.fileNameUnknown,
                    [index]: "",
                },
            })
        } else if(type === "cheque"){
            this.setState({
                editImageChequeTransaction: {
                    ...this.state.editImageChequeTransaction,
                    [index]: false,
                },
                fileNameCheque: {
                    ...this.state.fileNameCheque,
                    [index]: "",
                },
            })
        } else {
            this.setState({
                editImagePayTransaction: {
                    ...this.state.editImagePayTransaction,
                    [index]: false,
                },
                fileName: {
                    ...this.state.fileName,
                    [index]: "",
                },
            });
        }
    }

    toggleAttachBTN(index, type){
        if(type === "unKnow"){
            this.setState({
                editImageUnknownTransaction: {
                ...this.state.editImageUnknownTransaction,
                [index]: true,
                },
            })
        }else if(type === 'cheque'){
            this.setState({
                editImageChequeTransaction: {
                ...this.state.editImageChequeTransaction,
                [index]: true,
                },
            })  
        }else{
            this.setState({
                editImagePayTransaction: {
                ...this.state.editImagePayTransaction,
                [index]: true,
                },
            })
        }

    
    }

    render() {
        return (
            !this.state.reloading &&
            <div className={this.props.clear_guarantee ? "" : "card mt-3"}>
                <div className={this.props.clear_guarantee ? "" : "card-body"}>
                    <div className="row">
                        {this.props.account_type !== "draft" &&
                            <div className="col">
                                {!this.props.clear_guarantee &&
                                    <p><strong>{this.props.account_type === 'pay' ? i18next.t("createReceive:Paid by") : i18next.t("createReceive:Received_by")}</strong></p>
                                }
                                {
                                    this.props.cashTransaction.length !== 0 &&

                                    <p>เงินสด/Cash {numberWithComma(this.props.cashTransaction.reduce(function (total, obj) {
                                        return total + parseFloat(obj.node.price)
                                    }, 0))} {i18next.t("createReceive:Baht")}</p>}
                                {
                                    this.props.creditCardTransaction && this.props.creditCardTransaction.length !== 0 &&

                                    <p>บัตรเครดิต/Credit Card {numberWithComma(this.props.creditCardTransaction.reduce(function (total, obj) {
                                        return total + parseFloat(obj.node.amount)
                                    }, 0))} {i18next.t("createReceive:Baht")}</p>
                                }
                                {
                                    this.props.bankTransaction.length !== 0 &&
                                    <React.Fragment>
                                        <p>เงินโอน/Bank Transfer</p>
                                        {
                                            this.props.bankTransaction.map((bank, index) => {
                                                return (
                                                    <React.Fragment key={bank.node.id}>
                                                        <React.Fragment>
                                                            <p>-
                                                                {i18next.t("createReceive:Bank")}&nbsp;&nbsp;{bank.node.bankAccount.bankName}
                                                                &emsp;{i18next.t("createReceive:Account No.")}&nbsp;&nbsp;{bank.node.bankAccount.accountNumber}
                                                                &emsp;{i18next.t("createReceive:date")}&nbsp;&nbsp;{format(bank.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                                &emsp;{i18next.t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(bank.node.price)} {i18next.t("createReceive:Baht")}

                                                                {
                                                                    !this.state.editImagePayTransaction[index] && bank.node.id && this.props.receive_status === 'PAID' &&
                                                                    <label className="btn btn-primary ml-2" onClick={() => this.toggleAttachBTN(index)}>
                                                                        {i18next.t('createReceive:Upload receipt')}
                                                                    </label>
                                                                }
                                                            </p>

                                                            {this.state.editImagePayTransaction[index] &&
                                                                <div className="staff-create colorUse">
                                                                    <legend>
                                                                        <label className="fs-14 mr-2 fw-600">{i18next.t("createReceive:Please attach file")}</label>
                                                                        <div className="uploadBTN  mr-2 " style={{ display: 'inline-flex' }}>
                                                                            <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                                                <button className={"btn h34"}> {i18next.t('createReceive:Upload receipt')}</button>

                                                                                <input type="file" name='file' id="uploadFile"
                                                                                    className='form-control uploadFile'
                                                                                    placeholder='uploadFile'
                                                                                    accept="image/png, image/jpeg"
                                                                                    onChange={(e)=>this.uploadFile(e,"pay",index)} />
                                                                            </label>
                                                                        </div>
                                                                        {
                                                                            this.state.fileName[index] &&
                                                                            <React.Fragment>
                                                                                <label className="fs-14 mr-2">{this.state.fileName[index]}</label>
                                                                                <button className="btn btn-primary h34 mr-2" onClick={() => this.saveSlipPayment(bank.node.id, index)}>{i18next.t("Allaction:save")}</button>
                                                                            </React.Fragment>
                                                                        }
                                                                        <button className="btn btn-secondary h34 mr-2" onClick={() => this.delItemAttach(index)}>{i18next.t("Allaction:cancel")}</button>
                                                                    </legend>
                                                                </div>
                                                            }
                                                        </React.Fragment>

                                                        <div className="row pl-2">
                                                            {
                                                                bank.node.imageSlip &&
                                                                <div className="p-2">
                                                                    <LightBox image={bank.node.imageSlip} />
                                                                </div>
                                                            }
                                                            {
                                                                bank.node.imageSlipAdditional &&
                                                                <div className="p-2">
                                                                    <LightBox image={bank.node.imageSlipAdditional} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                }
                                {this.props.account_type === 'pay' && this.props.chequeTransaction.length !== 0 &&
                                    <React.Fragment>
                                        <p>เช็คธนาคาร/Cheque Bank</p>
                                        {
                                            this.uniqueCheques()?.map((cheque) => {
                                                return (
                                                    <p key={cheque.node.id}>- {i18next.t("createReceive:Cheque No.")}&nbsp;&nbsp;{cheque.node.chequeNumber}
                                                        &emsp;{i18next.t("createReceive:Bank")}&nbsp;&nbsp;{cheque.node.bankAccount.bankName}
                                                        &emsp;{i18next.t("createReceive:Branch")}&nbsp;&nbsp;{cheque.node.bankAccount.branch}
                                                        &emsp;{i18next.t("createReceive:date")}&nbsp;&nbsp;{format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                        &emsp;{i18next.t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {i18next.t("createReceive:Baht")}</p>

                                                )
                                            })
                                        }
                                    </React.Fragment>
                                }
                                {this.props.account_type === 'pay' && this.props.paymentChannel && this.props.paymentChannel?.length !== 0 &&
                                    <React.Fragment>
                                        <p>เงินสดย่อย/Petty Cash</p>
                                        {
                                            this.props.paymentChannel?.map((pettycash) => (
                                                <p key={pettycash.node.id}>- {i18next.t("payChannelView:No.")}&nbsp;&nbsp;{pettycash.node.setPettyCash.docNumber}
                                                    &emsp;{i18next.t("payChannelView:Date")}&nbsp;&nbsp;{format(pettycash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                    &emsp;{i18next.t("payChannelView:Amount")}&nbsp;&nbsp;{numberWithComma(pettycash.node.price)} {i18next.t("payChannelView:Baht")}</p>

                                            ))
                                        }
                                    </React.Fragment>
                                }

                                
                                <div className="row col d-flex flex-column">
                                {this.props.account_type === 'receive' && this.props.chequeTransaction.length !== 0 &&
                                    <React.Fragment>
                                        <p>เช็คธนาคาร/Cheque Bank</p>
                                        {
                                            this.props.chequeTransaction.map((cheque,index) => (
                                                <React.Fragment key={cheque.node.id}>
                                                    <p>- {i18next.t("createReceive:Cheque No.")}&nbsp;&nbsp;{cheque.node.chequeNumber}
                                                        &emsp;{cheque.node.bankName}
                                                        &emsp;{i18next.t("createReceive:Branch")}&nbsp;&nbsp;{cheque.node.branchNumber}
                                                        &emsp;{i18next.t("createReceive:date")}&nbsp;&nbsp;{format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                        &emsp;{i18next.t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {i18next.t("createReceive:Baht")}
                                                        {!this.state.editImageChequeTransaction[index] && 
                                                        <label className="btn btn-primary ml-2" onClick={() => this.toggleAttachBTN(index, "cheque")}>
                                                            {i18next.t('createReceive:Upload receipt')}
                                                        </label>
                                                        }
                                                    </p>
                                                    <div className="d-flex flex-row mt-2" key={index}>
                                                    {cheque.node.chequeImage && <LightBox image={cheque.node.chequeImage} />}
                                                    <div className="staff-create colorUse d-flex align-items-center ml-2">    
                                                    {this.state.editImageChequeTransaction[index] && (
                                                        <legend>
                                                        <label className="fs-14 mr-2 fw-600">{i18next.t("createReceive:Please attach file")}</label>
                                                        <div className="uploadBTN  mr-2 " style={{ display: 'inline-flex' }}>
                                                            <label htmlFor={`uploadFile-${index}`} className="upload-btn-wrapper mt-0">
                                                            <button className="btn h34">{i18next.t('createReceive:Upload receipt')}</button>
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                id={`uploadFile-${index}`}
                                                                className="form-control uploadFile"
                                                                placeholder="uploadFile"
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => this.uploadFile(e, "cheque", index)}
                                                            />
                                                            </label>
                                                        </div>
                                                        {this.state.fileNameCheque[index] && (
                                                            <React.Fragment>
                                                            <label className="fs-14 mr-2">{this.state.fileNameCheque[index]}</label>
                                                                <button className="btn btn-primary h34 mr-2" onClick={() => this.updateChequeReceive(this.props.chequeTransaction[index]?.node.id, index)}> 
                                                                {i18next.t("Allaction:save")}
                                                            </button>
                                                            </React.Fragment>
                                                        )}
                                                        <button className="btn btn-secondary h34 mr-2" onClick={() => this.delItemAttach(index, "cheque")}>
                                                            {i18next.t("Allaction:cancel")}
                                                        </button>
                                                        </legend>
                                                    )}
                                                    </div>
                                                </div>
                                                     
                                                    
                                                </React.Fragment>
                                            ))
                                        }
                                        
                                      
                                    </React.Fragment>
                                }</div>
                                

                                {/* เพย์เมนท์เกตเวย์ */}
                                {this.props.account_type === 'receive' && this.props.paymentGatewayTransaction && this.props.paymentGatewayTransaction.length !== 0 &&
                                    <React.Fragment>
                                        <p>เพย์เมนท์เกตเวย์</p>
                                        {
                                            this.props.paymentGatewayTransaction.map((payment_gateway) => (
                                                <React.Fragment key={payment_gateway.node.id}>
                                                    <p>{"วันที่ " + format(payment_gateway.node.depositDate, "DD/MM/YYYY")}</p>
                                                    <p>-&emsp;
                                                        {paymentGatewayText(payment_gateway.node.paymentType)}&nbsp;&nbsp;{numberWithComma(payment_gateway.node.amount)}
                                                    </p>

                                                    {payment_gateway.node.feeAmount > 0 &&
                                                        <p>-&emsp;
                                                            {i18n.t('paymentGateway:Payment Fee')}&nbsp;&nbsp;{numberWithComma(payment_gateway.node.feeAmount)}
                                                        </p>
                                                    }
                                                    {payment_gateway.node.image &&
                                                        <div className="row">
                                                            <div className="p-2">
                                                                <LightBox image={payment_gateway.node.image} />
                                                            </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                    </React.Fragment>
                                }


                                {
                                    // other
                                    this.state.other_price !== 0 && this.props.account_type !== "pay" && 
                                    this.props.receive_transactions && 
                                    (this.props.paymentGatewayTransaction && this.props.paymentGatewayTransaction.length === 0) &&
                                    <React.Fragment>
                                        <p>อื่นๆ/Other</p>
                                        {
                                            this.props.receive_transactions?.map((discount) => (
                                                discount.node?.discount > 0 && [
                                                    <p key={discount.node?.id}>&emsp;&emsp;&emsp;{i18next.t("createReceive:Discount")}&emsp;{i18next.t("createReceive:Amount_")} {numberWithComma(discount.node?.discount)} {i18next.t("createReceive:Baht")}</p>
                                                ]
                                            ))
                                        }
                                        {
                                            this.props.otherTransaction.edges[0]?.node.accountRecord.edges.map((other) => (
                                                this.isOther(other.node.chartOfAccountCode.chartOfAccountCode) && [
                                                    <p key={other.node.id}>&emsp;&emsp;&emsp;
                                                        {other.node.chartOfAccountCode.name === "รายรับ - จากการปรับเศษทศนิยม" | other.node.chartOfAccountCode.name === "รายรับ - อื่น ๆ" ? i18next.t("createReceive:The difference income from the penny") : other.node.chartOfAccountCode.name}
                                                        &emsp;{i18next.t("createReceive:Amount_")} {other.node.credit > 0 ? numberWithComma(other.node.credit) : numberWithComma(other.node.debit)} {i18next.t("createReceive:Baht")}</p>

                                                ]
                                            ))
                                        }

                                        <div className="row col d-flex flex-column">
                                            {this.props.unknownReceive.edges &&
                                                this.props.unknownReceive.edges.map((unKnowImage, index) => (
                                                <div className="d-flex flex-row mt-2" key={index}>
                                                    {unKnowImage.node.imageSlip && <LightBox image={unKnowImage.node.imageSlip} />}
                                                    <div className="staff-create colorUse d-flex align-items-center ml-2">
                                                    {!this.state.editImageUnknownTransaction[index] && 
                                                    <label className="btn btn-primary" onClick={() => this.toggleAttachBTN(index, "unKnow")}>
                                                        {i18next.t('createReceive:Upload receipt')}
                                                    </label>}
                                                    {this.state.editImageUnknownTransaction[index] && (
                                                        <legend>
                                                        <label className="fs-14 mr-2 fw-600">{i18next.t("createReceive:Please attach file")}</label>
                                                        <div className="uploadBTN  mr-2 " style={{ display: 'inline-flex' }}>
                                                            <label htmlFor={`uploadFile-${index}`} className="upload-btn-wrapper mt-0">
                                                            <button className="btn h34">{i18next.t('createReceive:Upload receipt')}</button>
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                id={`uploadFile-${index}`}
                                                                className="form-control uploadFile"
                                                                placeholder="uploadFile"
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => this.uploadFile(e, "unKnown", index)}
                                                            />
                                                            </label>
                                                        </div>
                                                        {this.state.fileNameUnknown[index] && (
                                                            <React.Fragment>
                                                            <label className="fs-14 mr-2">{this.state.fileNameUnknown[index]}</label>
                                                                <button className="btn btn-primary h34 mr-2" onClick={() => this.updateUnknownReceive(this.props.unknownReceive.edges[index]?.node.id, index)}> 
                                                                {i18next.t("Allaction:save")}
                                                            </button>
                                                            </React.Fragment>
                                                        )}
                                                        <button className="btn btn-secondary h34 mr-2" onClick={() => this.delItemAttach(index, "unKnow")}>
                                                            {i18next.t("Allaction:cancel")}
                                                        </button>
                                                        </legend>
                                                    )}
                                                    </div>
                                                </div>
                                                ))}
                                            </div>
                                                                                
                                    </React.Fragment>
                                }
                                {this.props.allAccountRecordGroup && (this.props.account_type === "pay" || this.props.account_type === "receive") &&
                                    <React.Fragment>
                                        {
                                            this.props.allAccountRecordGroup.map((Other) => (
                                                Other.node.accountRecord.edges.map((accountRecord) => {
                                                    if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                                                        accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07" ||
                                                        accountRecord.node.chartOfAccountCode.chartOfAccountCode === "2125-03" ||
                                                        accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("2210") ||
                                                        accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("5830")) {

                                                        let filterOTSum = 0;
                                                        let checkfilterOTSum = 0;

                                                        if (accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("5830")) {
                                                            let filterOT = _.filter(this.props.otherReceiveTransaction, (o) => o.node?.accountRecord?.id === accountRecord?.node?.id)[0]

                                                            if (filterOT) {
                                                                filterOTSum = accountRecord.node?.credit - filterOT.node?.total;
                                                                checkfilterOTSum = filterOT.node?.total;
                                                            }
                                                        }

                                                        let name = accountRecord.node.chartOfAccountCode.name;
                                                        if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" && accountRecord.node.debit > 0) {
                                                            name = name.replace("รายรับ", "รายจ่าย")
                                                        }
                                                        if (Other.node.refTransaction.startsWith("OR-") && accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("2210")
                                                            && accountRecord.node.name.includes("(unit")) {
                                                            return null;
                                                        }
                                                        else if (Other.node.refTransaction.startsWith("OE-") && accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("2210")) {
                                                            return null;
                                                        } else if (accountRecord.node.chartOfAccountCode.chartOfAccountCode.includes("5830") && accountRecord.node?.credit === checkfilterOTSum) {
                                                            return null;
                                                        }
                                                        else {
                                                            return (
                                                                filterOTSum ? <p key={accountRecord.node.id}>{name} {numberWithComma(accountRecord.node.credit > 0 ?
                                                                    filterOTSum : accountRecord.node.debit)} {i18next.t("payChannelView:Baht")}  </p> :
                                                                    <p key={accountRecord.node.id}>{name} {numberWithComma(accountRecord.node.credit > 0 ?
                                                                        accountRecord.node.credit : accountRecord.node.debit)} {i18next.t("payChannelView:Baht")}  </p>
                                                            )
                                                        }

                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })

                                            )
                                            )
                                        }
                                    </React.Fragment>
                                }

                                {this.props.allAccountRecordGroup && this.props.account_type === "receive" && this.props.isOther && this.props.otherReceive &&
                                    this.props.otherReceive?.edges.length > 0 &&
                                    <React.Fragment>
                                        <p>อื่นๆ/Other</p>
                                        {this.props.allAccountRecordGroup[0].node.accountRecord.edges.map((other) =>
                                        (other.node.chartOfAccountCode.chartOfAccountCode.includes("2230") &&
                                            [<p key={other.node.id}>&emsp;&emsp;&emsp; {i18next.t("payChannelView:Unknown receive")}&emsp;
                                                {i18next.t("payChannelView:Amount")} {other.node.credit > 0 ? numberWithComma(other.node.credit) : numberWithComma(other.node.debit)} {i18next.t("payChannelView:Baht")}

                                            </p>])
                                        )}

                                    </React.Fragment>
                                }

                                {/* unknow receive */}
                                {this.props.account_type === 'receive' && this.props.unknownReceive?.edges.length > 0 && !this.props.receive_transactions &&
                                    <React.Fragment>
                                        <p>อื่นๆ/Other</p>
                                        <p>&emsp;เงินรับรอการตรวจสอบ จำนวน {numberWithComma(this.props.unknownReceive?.edges[0].node.amount)} บาท</p>                                                                                
                                        <div className="row col d-flex flex-column">                                                                                        
                                            {this.props.unknownReceive?.edges &&
                                                this.props.unknownReceive?.edges.map((unKnowImage, index) => (
                                                <div className="d-flex flex-row mt-2" key={index}>
                                                    {unKnowImage.node.imageSlip && <LightBox image={unKnowImage.node.imageSlip} />}
                                                    <div className="staff-create colorUse d-flex align-items-center ml-2">
                                                    {!this.state.editImageUnknownTransaction[index] && 
                                                    <label className="btn btn-primary" onClick={() => this.toggleAttachBTN(index, "unKnow")}>
                                                        {i18next.t('createReceive:Upload receipt')}
                                                    </label>}
                                                    {this.state.editImageUnknownTransaction[index] && (
                                                        <legend>
                                                        <label className="fs-14 mr-2 fw-600">{i18next.t("createReceive:Please attach file")}</label>
                                                        <div className="uploadBTN  mr-2 " style={{ display: 'inline-flex' }}>
                                                            <label htmlFor={`uploadFile-${index}`} className="upload-btn-wrapper mt-0">
                                                            <button className="btn h34">{i18next.t('createReceive:Upload receipt')}</button>
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                id={`uploadFile-${index}`}
                                                                className="form-control uploadFile"
                                                                placeholder="uploadFile"
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => this.uploadFile(e, "unKnown", index)}
                                                            />
                                                            </label>
                                                        </div>
                                                        {this.state.fileNameUnknown[index] && (
                                                            <React.Fragment>
                                                            <label className="fs-14 mr-2">{this.state.fileNameUnknown[index]}</label>
                                                                <button className="btn btn-primary h34 mr-2" onClick={() => this.updateUnknownReceive(this.props.unknownReceive.edges[index]?.node.id, index)}> 
                                                                {i18next.t("Allaction:save")}
                                                            </button>
                                                            </React.Fragment>
                                                        )}
                                                        <button className="btn btn-secondary h34 mr-2" onClick={() => this.delItemAttach(index, "unKnow")}>
                                                            {i18next.t("Allaction:cancel")}
                                                        </button>
                                                        </legend>
                                                    )}
                                                    </div>
                                                </div>
                                                ))}
                                        </div>
                                    </React.Fragment>
                                }

                            </div>
                        }
                        {this.props.account_type === "draft" &&
                            <div className="col" >
                                <p><strong>{i18next.t("createReceive:Paid by")}</strong></p>
                                {Object.keys(this.state.payTransaction).length !== 0 &&
                                    <div>
                                        <div className="row col">
                                            {this.state.payTransaction.payTransactionCash !== 0 && this.state.payTransaction.payTransactionCash.map((value) => (
                                                <p key={value.price} > &emsp;<strong>{i18next.t("createReceive:Cash")}</strong>&emsp;&emsp;{i18next.t("createReceive:Amount")}&emsp;&emsp;{numberWithComma(value.price)} {i18next.t("createReceive:Baht")}</p>

                                            ))}
                                        </div>

                                        {this.state.payTransaction.payTransactionBank.length !== 0 && <p className="col"><strong>เงินโอน/Bank Transfer</strong></p>}
                                        <React.Fragment>
                                            {this.state.payTransaction.payTransactionBank.length !== 0 && this.state.payTransaction.payTransactionBank.map((value, index) => {
                                                return [
                                                    <div key={value.bankAccount + index} className="col ml-2">

                                                        <p key={value.bankAccount + index}>&emsp;&emsp;-&emsp;{value.bankName}
                                                            &emsp;{i18next.t("createReceive:Account No.")} {value.bankAccount}
                                                            &emsp;{i18next.t("createReceive:Amount")} {value.price} {i18next.t("createReceive:Baht")}</p>
                                                    </div>
                                                ]
                                            })}
                                            <div className="row col ml-4">{this.props.image && this.props.image.map((image, index) => <LightBox key={index} image={image} />)}</div>
                                        </React.Fragment>
                                        {this.state.payTransaction.payTransactionCheque.length !== 0 && <p className="col"><strong>เช็คธนาคาร/Cheque Bank</strong></p>}
                                        <div className="col ml-2">
                                            {this.state.payTransaction.payTransactionCheque.length !== 0 && this.state.payTransaction.payTransactionCheque.map((value) => (
                                                <p key={value.chequeNumber}>&emsp;&emsp;-&emsp;{i18next.t("createReceive:Cheque No.")}&nbsp;&nbsp;{value.chequeNumber}
                                                    &emsp;{value.bankName}
                                                    &emsp;{i18next.t("createReceive:Account No.")}{value.bankAccount}
                                                    &emsp;{i18next.t("createReceive:Amount_")} {value.price} {i18next.t("createReceive:Baht")}</p>
                                            ))}
                                        </div>
                                        {this.state.payTransaction.payTransactionOther.length !== 0 && <p className="col"><strong>อื่นๆ/Other</strong></p>}
                                        <div className="col ml-2">
                                            {this.state.payTransaction.payTransactionOther.length !== 0 && this.state.payTransaction.payTransactionOther.map((value) => (
                                                <p key={value.price}>&emsp;&emsp;-&emsp;{value.detail}
                                                    &emsp;{i18next.t("createReceive:Amount")} {value.price} {i18next.t("createReceive:Baht")}</p>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {this.state.resultCompare === 'False' &&
                        <p><br></br>*** <strong>{this.state.resultText}</strong></p>
                    }

                </div>
            </div>
        )
    }
}

export default PayChannel