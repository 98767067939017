import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link, Redirect, withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import _ from "lodash"
import upload from '../../libs/upload'
import format from 'date-fns/format';
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import jwtDecode from 'jwt-decode'
import UpdateCreateChatMeeting from './mutations/updateCreateChatMeeting';
import i18n from 'i18next';
import i18next from 'i18next';

const query = graphql`
    query updateMeetingQuery($search: String , $roomGroupId : String) { 
        allRoomGroup (roomGroupId: $roomGroupId) { 
            edges {
                node{ 
                    id
                    name
                    detail
                    status
                    note
                    roomGroupNumberRead{
                        edges{
                            node{
                                id
                                count
                                user{
                                    id
                                    juristic{
                                        id
                                        firstName
                                        lastName
                                    }
                                    tenant{
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }           
                    } 
                    roomGroupTag{
                        edges{
                            node{
                                id
                                tag
                            }
                        }           
                    }   
                    roomGroupImage{
                        edges{
                            node{
                                id
                                fileName
                                fileUpload
                            }
                        }           
                    } 
                }
            }
        }
        allJuristic (search: $search) {
            edges {
                node { 
                    image
                    firstName 
                    firstNameEn
                    lastName 
                    lastNameEn   
                    role 
                    user {
                        id 
                    }
                }
            }
        }
        currentTenants (search: $search) {
            edges{
                node{
                    id
                    image
                    firstName
                    lastName
                    user{
                        id
                    }
                    householder {
                        edges {
                            node {
                                id
                                residential {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        
        }
    } 
`;

class UpdateMeeting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: true,
            managerNiti: [],
            commuttee: [],
            selectNitiAll: false,
            selectcommAll: false,
            ownCreate: "",

            titlename: "",
            detail: "",
            tag: [],
            chooseFile: [],
            chooseMemberNiti: [],
            chooseMember: [],
            choose: [],

            id_niti: [],
            id_commuttee: [],


            listTag: require('./ListTag.json').map((n)=> ({...n, selected:false}))
        }
        this.selectTag = this.selectTag.bind(this)
        this.uploadFile = this.uploadFile.bind(this);
        this.selectMember = this.selectMember.bind(this)
        this.selectMemberComm = this.selectMemberComm.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.dateFile = this.dateFile.bind(this)


    }


    async componentDidMount() {
        await this.getManagerNitiandCommuttee()
    }


    async getDataById(id, allJuristic, currentTenants) {
        await fetchQuery(environment, query, { roomGroupId: id }).then(data => {
            let dataNode = data.allRoomGroup.edges[0].node
            let nodeTag = dataNode?.roomGroupTag?.edges
            let nodeMember = dataNode?.roomGroupNumberRead?.edges
            let listTag = this.state.listTag
            let id_niti = []
            let id_commuttee = []
            let Tag = []
            let dataTag = nodeTag.map((tag, inxx) => {
                let dataBack = {
                    roomGroupTagId: tag.node.id,
                    tag: tag.node.tag,
                    status: ""
                }
                Tag.push(dataBack)
                listTag.forEach((lTag, inx) => {
                    if (lTag.label === tag.node.tag && lTag.selected == false) {
                        lTag.selected = true
                    }
                });
            }) 
            let choosememver = []
            let dataMember = nodeMember.map((member, inxx) => {
                if (inxx !== 0) {
                    let dataBackmemver = {
                        "roomGroupNumberReadId": member.node.id,
                        "userId": parseInt(member.node.user.id),
                        "status": ""
                    }

                    choosememver.push(dataBackmemver)
                }

                this.state.managerNiti.forEach(ele => {
                    if (ele.userId === member.node.user.id) {
                        ele.selected = true
                        if (parseInt(nodeMember[0].node.user.id) !== parseInt(member.node.user.id)) {
                            id_niti.push({ roomGroupNumberReadID: member.node.id, userId: member.node.user.id })
                        }
                    }
                })



                this.state.commuttee.forEach(ele => {
                    if (ele.userId === member.node.user.id) {
                        ele.selected = true
                        if (parseInt(nodeMember[0].node.user.id) !== parseInt(member.node.user.id)) {
                            id_commuttee.push({ roomGroupNumberReadID: member.node.id, userId: member.node.user.id })
                        }
                    }
                })
            })

            // console.log(id_niti.length,this.state.managerNiti.length - 1);
            let choosemember = []
            //ดึงข้อมูลเข้ามาตอนแรกกับรายการที่ถูกเลือก
            if (id_niti.length === this.state.managerNiti.length - 1) {
                allJuristic.forEach((e) => {
                    if (parseInt(nodeMember[0].node.user.id) !== parseInt(e.userId)) {
                        let find_id_niti = _.find(id_niti, { 'userId': e.userId });
                        let id_check = find_id_niti ? find_id_niti.roomGroupNumberReadID : ""
                        let choose = {
                            roomGroupNumberReadId: id_check,
                            userId: parseInt(e.userId),
                            status: "",
                        }
                        choosemember.push(choose)
                    }
                })
            }
            let commuttee_list = []
            if (id_commuttee.length === this.state.commuttee.length) {
                currentTenants.forEach((e) => {
                    if (parseInt(nodeMember[0].node.user.id) !== parseInt(e.userId)) {
                        let find_id_currentTenants = _.find(id_commuttee, { 'userId': e.userId });
                        let id_check = find_id_currentTenants ? find_id_currentTenants.roomGroupNumberReadID : ""
                        let choose = {
                            roomGroupNumberReadId: id_check,
                            userId: parseInt(e.userId),
                            status: "",
                        }
                        commuttee_list.push(choose)
                    }
                })
            }
            this.setState({
                titlename: dataNode.name,
                detail: dataNode.detail,
                chooseFile: dataNode.roomGroupImage?.edges?.map((n)=> ({...n.node, status : "" })) || [],
                tag: Tag, listTag: listTag,
                choose: choosememver,
                id_niti: id_niti,
                id_commuttee: id_commuttee,
                ownCreate: parseFloat(nodeMember[0].node.user.id),
                selectNitiAll: id_niti.length === this.state.managerNiti.length - 1 ? true : false,
                selectcommAll: id_commuttee.length === this.state.commuttee.length ? true : false,
                chooseMemberNiti: choosemember,
                chooseMember: commuttee_list,
                loading: false
            })
        })
    }

    async getManagerNitiandCommuttee() {
        await fetchQuery(environment, query, {
            search: this.state.search
        }).then(data => {
            if (data.allJuristic && data.currentTenants) {
                let currentTenants = []
                let allJuristic = []
                let data_currentTenants = data.currentTenants.edges.filter((n) => n.node.user !== null)
                data_currentTenants.map((n, index) => {
                    _.set(currentTenants, "[" + index + "].image", n.node.image || "");
                    _.set(currentTenants, "[" + index + "].fullName", n.node.firstName + " " + n.node.lastName || "-");
                    _.set(currentTenants, "[" + index + "].userId", n.node.user?.id || "");
                    _.set(currentTenants, "[" + index + "].homeName", n.node.householder?.edges[0]?.node?.residential?.name || "-");
                    _.set(currentTenants, "[" + index + "].selected", false);
                })

                let data_allJuristic = data.allJuristic.edges.filter((n) => n.node.user !== null)
                data_allJuristic.map((n, index) => {
                    if (n.node.user) {
                        _.set(allJuristic, "[" + index + "].image", n.node.image || "");
                        _.set(allJuristic, "[" + index + "].fullName", n.node.firstName + " " + n.node.lastName || "-");
                        _.set(allJuristic, "[" + index + "].userId", n.node.user?.id || "");
                        _.set(allJuristic, "[" + index + "].role", n.node.role || "-");
                        _.set(allJuristic, "[" + index + "].selected", false);
                    }
                })
                this.setState({ managerNiti: allJuristic, commuttee: currentTenants })
                this.getDataById(this.props.match.params.id || "0", allJuristic, currentTenants)
            }
        })
    }

    selectMember(e, index, action, selc) {
        // let owner_room = this.state.choose.edges[0].node.user.id
        // console.log(owner_room);
        if (action === 'All') {
            let clone_Niti = _.cloneDeep(this.state.managerNiti)
            clone_Niti.map((n, inx) => { _.set(clone_Niti, "[" + inx + "].selected", selc ? false : true); })

            let choosemember = _.cloneDeep(this.state.chooseMemberNiti)
            // console.log(choosemember);
            // selc ติ้กออกเป็น true เข้าเป็น false
            if (selc == false) {
                clone_Niti.forEach((n, ind) => {
                    if (this.state.ownCreate !== n.userId) {
                        let find_id_niti = _.find(this.state.id_niti, { 'userId': n.userId });
                        let id_check = find_id_niti ? find_id_niti.roomGroupNumberReadID : ""
                        //ตรวจสอบว่ามีการติ้กไปแล้วหรือยัง
                        let index_cho = choosemember.findIndex((n) => n.userId === n.userId)
                        if (index_cho >= 0) {
                            _.set(choosemember, "[" + ind + "].roomGroupNumberReadId", id_check);
                            _.set(choosemember, "[" + ind + "].userId", parseInt(n.userId));
                            _.set(choosemember, "[" + ind + "].status", n.selected ? "" : "delete");
                        } else {
                            let choose = {
                                roomGroupNumberReadId: id_check,
                                userId: parseInt(n.userId),
                                status: n.selected ? "" : "delete",
                            }
                            choosemember.push(choose)
                        }
                    }
                })
            } else {
                // อัพเดทเฉพาะคนที่เคยติ้กมาแล้วให้ลบออก 
                let update_list = choosemember.filter((n) => n.roomGroupNumberReadId !== "")
                choosemember = []
                update_list.forEach((j) => {
                    if (this.state.ownCreate !== j.userId) {
                        let choose = {
                            roomGroupNumberReadId: j.roomGroupNumberReadId,
                            userId: parseInt(j.userId),
                            status: "delete",
                        }
                        choosemember.push(choose)
                    }
                })
            }
            // console.log(choosemember);
            this.setState({ managerNiti: clone_Niti, selectNitiAll: selc ? false : true, chooseMemberNiti: choosemember })
        }
        else {
            let clone_NitiS = _.cloneDeep(this.state.managerNiti)
            _.set(clone_NitiS, "[" + index + "].selected", e.selected ? false : true);

            let choosemember = _.cloneDeep(this.state.chooseMemberNiti)
            let find_id_niti = _.find(this.state.id_niti, { 'userId': clone_NitiS[index].userId });
            let id_check = find_id_niti ? find_id_niti.roomGroupNumberReadID : ""

            if (this.state.ownCreate !== clone_NitiS[index].userId) {

                //ตรวจสอบว่ามีการติ้กไปแล้วหรือยัง
                let index_cho = choosemember.findIndex((n) => parseInt(n.userId) === parseInt(clone_NitiS[index].userId))
                if (index_cho >= 0) {
                    _.set(choosemember, "[" + index_cho + "].roomGroupNumberReadId", id_check);
                    _.set(choosemember, "[" + index_cho + "].userId", parseInt(clone_NitiS[index].userId));
                    _.set(choosemember, "[" + index_cho + "].status", clone_NitiS[index].selected ? "" : "delete");
                } else {
                    let choose = {
                        roomGroupNumberReadId: id_check,
                        userId: parseInt(clone_NitiS[index].userId),
                        status: clone_NitiS[index].selected ? "" : "delete",
                    }
                    choosemember.push(choose)
                }
            }

            // console.log(choosemember);
            this.setState({ managerNiti: clone_NitiS, chooseMemberNiti: choosemember })

        }
    }

    selectMemberComm(e, index, action, selc) {
        if (action === 'All') {
            let clone_Comm = _.cloneDeep(this.state.commuttee)
            clone_Comm.map((n, inx) => { _.set(clone_Comm, "[" + inx + "].selected", selc ? false : true); })

            let choosemember = _.cloneDeep(this.state.chooseMember)
            // selc ติ้กออกเป็น true เข้าเป็น false
            if (selc == false) {
                clone_Comm.forEach((n, ind) => {
                    if (this.state.ownCreate !== n.userId) {
                        let find_id_niti = _.find(this.state.id_niti, { 'userId': n.userId });
                        let id_check = find_id_niti ? find_id_niti.roomGroupNumberReadID : ""

                        //ตรวจสอบว่ามีการติ้กไปแล้วหรือยัง
                        let index_cho = choosemember.findIndex((n) => n.userId === n.userId)
                        if (index_cho >= 0) {
                            _.set(choosemember, "[" + ind + "].roomGroupNumberReadId", id_check);
                            _.set(choosemember, "[" + ind + "].userId", parseInt(n.userId));
                            _.set(choosemember, "[" + ind + "].status", n.selected ? "" : "delete");
                        } else {
                            let choose = {
                                roomGroupNumberReadId: id_check,
                                userId: parseInt(n.userId),
                                status: n.selected ? "" : "delete",
                            }
                            choosemember.push(choose)
                        }
                    }
                })
            } else {
                // อัพเดทเฉพาะคนที่เคยติ้กมาแล้วให้ลบออก 
                let update_list = choosemember.filter((n) => n.roomGroupNumberReadId !== "")
                choosemember = []
                update_list.forEach((j) => {
                    if (this.state.ownCreate !== j.userId) {
                        let choose = {
                            roomGroupNumberReadId: j.roomGroupNumberReadId,
                            userId: parseInt(j.userId),
                            status: "delete",
                        }
                        choosemember.push(choose)
                    }
                })
            }
            this.setState({ commuttee: clone_Comm, selectcommAll: selc ? false : true, chooseMember: choosemember })

        }
        else {
            let clone_CommS = _.cloneDeep(this.state.commuttee)
            _.set(clone_CommS, "[" + index + "].selected", e.selected ? false : true);

            let find_id_commuttee = _.find(this.state.id_commuttee, { 'userId': clone_CommS[index].userId });
            let id_check = find_id_commuttee ? find_id_commuttee.roomGroupNumberReadID : ""
            let choosemember = _.cloneDeep(this.state.chooseMember)
            if (this.state.ownCreate !== clone_CommS[index].userId) {
                //ตรวจสอบว่ามีการติ้กไปแล้วหรือยัง
                let index_cho = choosemember.findIndex((n) => parseInt(n.userId) === parseInt(clone_CommS[index].userId))
                if (index_cho >= 0) {
                    _.set(choosemember, "[" + index_cho + "].roomGroupNumberReadId", id_check);
                    _.set(choosemember, "[" + index_cho + "].userId", parseInt(clone_CommS[index].userId));
                    _.set(choosemember, "[" + index_cho + "].status", clone_CommS[index].selected ? "" : "delete");
                } else {
                    let choose = {
                        roomGroupNumberReadId: id_check,
                        userId: parseInt(clone_CommS[index].userId),
                        status: clone_CommS[index].selected ? "" : "delete",
                    }
                    choosemember.push(choose)
                }
            }

            this.setState({ commuttee: clone_CommS, chooseMember: choosemember })
        }
    }

    selectTag(e, index) {
        let clone_listTag = _.cloneDeep(this.state.listTag)
        let selected = e.selected == true ? false : true
        clone_listTag.map((n) => { return (_.set(clone_listTag, "[" + index + "].selected", selected)) })
        let selectedData = _.cloneDeep(this.state.tag);
        let indexOf = selectedData.findIndex((n) => n.tag === clone_listTag[index].label)
        if (indexOf >= 0) {
            // อันเก่าต้องดูว่าติ้กเข้าหรือติ้กออก
            if (selectedData[indexOf].roomGroupTagId === ""){ 
                selectedData.pop(indexOf)  
            } else {
                _.set(selectedData, "[" + indexOf + "].status", clone_listTag[index].selected ? "" : "delete")
            }
        } else {
            // เลือกอันใหม่
            let tag_objects = {
                roomGroupTagId: "",
                tag: clone_listTag[index].label,
                status: "",
            }
            selectedData.push(tag_objects)
        } 

        this.setState({ listTag: clone_listTag, tag: selectedData })

    }

    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/groupChat" + path, file);
            resolve(res);
        });
    };
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files) {
            if (files?.size > 2097152) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), `${i18next.t("Allaction:Please upload files in the specified format")} .png, .jpg, .jpeg ${i18next.t("Allaction:Only")} ${i18next.t("Allaction:and each file should not exceed")} 2 MB (${i18next.t("Allaction:maximum of")} 3 ${i18next.t("Allaction:images")} )`, 'error')
            }

            else {
                // fileIsSel = false
                let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                let fileOldList = _.cloneDeep(this.state.chooseFile);
                this.callUpload('', new_file).then((data) => {
                    let dese = "groupChat/" + new_file.name
                    fileOldList.push({ roomGroupImageId: "", fileName: new_file.name, fileUpload: dese, viewPtath: data.location, status: "" })
                    this.setState({ chooseFile: fileOldList })
                });
            }
        }
    }
    // END UPLOAD 
    // Delete File
    dateFile = (index, data) => {
        // let cloneDataFile = _.cloneDeep(this.state.fileUpload)
        let DataFile = _.cloneDeep(this.state.chooseFile)
        let fileupload = []

        // const index_data_file = DataFile.findIndex(ex => ex.fileId === data.fileId)
        _.set(DataFile, "[" + index + "].status", "delete")
        fileupload = _.get(DataFile, "[" + index + "]") 

        if (DataFile.findIndex(ex => ex.fileId === fileupload.fileId) < 0) {
            DataFile.push(fileupload)
        } 

        if (data.status === '') {
            this.setState({ chooseFile: DataFile })
        }
    }
    
    onSubmitSuccess = (response) => {
        this.setState({ loading: false });
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.props.history.push("/contact/committee/pending")
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({ loading: false });
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }
    onSubmit(e) {
        e.preventDefault()

        // console.log(this.state.tag.filter((n)=>  n.roomGroupTagId !== "" && n.status !== "delete"));
        let data = {
            "input": {
                "roomGroup": {
                    "roomGroupId": this.props.match.params.id,
                    "name": this.state.titlename,
                    "detail": this.state.detail,
                    "status": "pending",
                    "note": "",
                    // "group": [...this.state.choose],
                    "group": [...this.state.chooseMemberNiti, ...this.state.chooseMember],
                    "tag": this.state.tag,
                    "image": this.state.chooseFile,
                    "document": []
                }
            }
        } 
        UpdateCreateChatMeeting(data, this.onSubmitSuccess, this.onSubmitError)


    }

    render() {
        if (this.state.redirect) { return <Redirect to="/contact/committee/pending" /> } 
        let seFile = this.state.chooseFile.filter((n) =>n.node?.status === '' || n.status === '')
        let seTag = this.state.tag.filter((n)=> n.node?.status === '' || n.status === '') 

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            <div className="container-fluid box meetingChat" >
                                {
                                    this.state.loading == false ?
                                        <div className='create'>
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                    <h3 className="mb-4">
                                                        <Link to="/contact/committee/pending">
                                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                alt="back" className="back" />
                                                        </Link>
                                                        <span style={{ fontWeight: '400' }}>{t('chatGroup:Update')}</span>

                                                    </h3>
                                                </div>
                                            </div>
                                            {
                                                <React.Fragment>
                                                    <div className='row'>
                                                        <div className="col-10 mx-auto">
                                                            <form onSubmit={this.onSubmit}>
                                                                <div>

                                                                    <section alt='header' style={{ marginTop: 20 }}>
                                                                        <h3>{t("chatGroup:Title")}</h3>
                                                                        <hr />
                                                                    </section>

                                                                    <section alt='titlename'>
                                                                        <label className='labelInput' >{t("chatGroup:Title name")} *</label>
                                                                        <input type={"text"} className='Input' required
                                                                            onKeyUp={(e) => this.setState({ titlename: e.target.value })}
                                                                            onChange={(e) => this.setState({ titlename: e.target.value })}
                                                                            name='titlename' defaultValue={this.state.titlename} />
                                                                    </section>

                                                                    <section alt='Detail'>
                                                                        <label className='labelInput' >{t("chatGroup:Detail")} *</label>
                                                                        <textarea name='Detail' className='textarea'
                                                                            value={this.state.detail}
                                                                            onKeyUp={(e) => this.setState({ detail: e.target.value })}
                                                                            onChange={(e) => this.setState({ detail: e.target.value })}
                                                                            required />
                                                                    </section>

                                                                    <section alt='Tag'>
                                                                        <div className='row'>
                                                                            <div className='col-6'>
                                                                                <label className='labelInput' >{t("chatGroup:Tag Title")} *</label>
                                                                                <label className='labelInput textGray' style={{ float: 'right', marginRight: "-8%" }}>({seTag.length}/5)</label>
                                                                            </div> 
                                                                            <div className='col-10'>
                                                                                {
                                                                                    this.state.listTag.map((n, index) => {
                                                                                        return (
                                                                                            seTag.length >= 5 ?
                                                                                                n.selected == true ?
                                                                                                    <label className={n.selected == true ? 'tag cursor tagBtnBlue' : ' tag cursor tagBtnGray'}
                                                                                                        key={'NewTag' + index}
                                                                                                        onClick={() => this.selectTag(n, index)}>
                                                                                                        {n.label}
                                                                                                    </label>
                                                                                                    :
                                                                                                    <label className={n.selected == true ? 'tag cursor tagBtnBlue' : ' tag cursor tagBtnGray'}
                                                                                                        key={'NewTag' + index}>
                                                                                                        {n.label}
                                                                                                    </label>
                                                                                                :
                                                                                                <label className={n.selected == true ? 'tag cursor tagBtnBlue' : ' tag cursor tagBtnGray'}
                                                                                                    key={'NewTag' + index}
                                                                                                    onClick={() => this.selectTag(n, index)}>
                                                                                                    {n.label}
                                                                                                </label>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div> 
                                                                    </section>


                                                                    <section alt='ChooseFile'>
                                                                        <div style={{ display: 'inline-flex', pointerEvents: seFile.length >= 3 ? 'none' : 'all' }}>
                                                                            <h3 style={{ marginTop: '40px' }}>{t("chatGroup:Choose File")}</h3>
                                                                            <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                                                <button className={seFile.length >= 3 ? "btn disabled" : "btn"}>
                                                                                    {
                                                                                        seFile.length >= 3 ?
                                                                                            <img src={process.env.PUBLIC_URL + '/images/iconAction/chat/attachDisabled.svg'} />
                                                                                            :
                                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />
                                                                                    } {t("chatGroup:Choose File")}
                                                                                </button>
                                                                                &nbsp;&nbsp;
                                                                                <span style={{ color: '#bfbfbf' }}>({seFile.length + " / 3"})</span>

                                                                                <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                                                                    {t("chatGroup:Supports file")} </span>


                                                                                <input type="file" name='file' id="uploadFile"
                                                                                    className='form-control uploadFile'
                                                                                    placeholder='ระบุเบอร์โทร'
                                                                                    accept='application/.jpeg,.png,.jpg' onChange={this.uploadFile}
                                                                                />

                                                                            </label>
                                                                        </div>

                                                                        {/* แสดงเอกสารแนบไฟล์ ที่บันทึกไว้ และ ที่เพิ่มมาใหม่ */} 
                                                                        <React.Fragment>
                                                                        {
                                                                            seFile.length > 0 &&
                                                                            seFile.map((file, inx) => { 
                                                                                let typeFile = file?.node?.fileName.split(".") || file?.fileName.split(".") 
                                                                                return ( 
                                                                                    file.node ?
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;
                                                                                    
                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                window.open(
                                                                                                    typeof file.node.fileUpload === "string"
                                                                                                        ? file.node.fileUpload
                                                                                                        : URL.createObjectURL(file.node.fileUpload)
                                                                                                )
                                                                                            }}
                                                                                            target="blank">{file.node.fileName.substr(0,22)}...{typeFile[1]}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)} style={{marginLeft: 30}}> 
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/closeGray03.svg"} /> </label>
                                                                                    </p>

                                                                                    :
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                window.open(
                                                                                                    typeof file.fileUpload === "string"
                                                                                                        ? file.fileUpload
                                                                                                        : URL.createObjectURL(file.fileUpload)
                                                                                                )
                                                                                            }}
                                                                                            target="blank">{file.fileName.substr(0,22)}...{typeFile[1]}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file)} style={{marginLeft: 30}}> 
                                                                                        <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/closeGray03.svg"} /> </label>
                                                                                    </p>
                                                                                    
                                                                                )
                                                                                
                                                                            })
                                                                        }

                                                                        </React.Fragment>
                                                                    </section>


                                                                    <section alt='chooseMember'>
                                                                        <h3 style={{ marginTop: '40px' }}>{t("chatGroup:Choose member to meeting")} *</h3>
                                                                        {/* <input required type={"text"} defaultValue={this.state.choose[0]}
                                                                    style={{ opacity: 0, marginTop: "-20px" }} /> */}
                                                                        <hr />
                                                                    </section>

                                                                    <section alt='Choose manage niti'>

                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className='text-center' style={{ width: 70 }}
                                                                                        onClick={() => this.selectMember("", "", "All", this.state.selectNitiAll)}>
                                                                                        <span style={{ color: "#BFBFBF", fontSize: "12px", fontWeight: '600' }}>{t("chatGroup:all")} </span><br />

                                                                                        {
                                                                                            this.state.selectNitiAll ?
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/checkbox.png'}
                                                                                                    width={25} height={25}
                                                                                                />
                                                                                                :
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/checkboxNull.png'}
                                                                                                    width={25} height={25} />
                                                                                        }

                                                                                    </th>
                                                                                    <th style={{ verticalAlign: 'bottom', fontSize: 18 }} colSpan={2}>{t("chatGroup:Choose manage niti")}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    this.state.managerNiti.map((n, ind) => {
                                                                                        let jwtCreateRoom = jwtDecode(localStorage.getItem('token'))
                                                                                        return (

                                                                                            <tr key={ind} style={{ verticalAlign: 'middle' }}>
                                                                                                {
                                                                                                    parseFloat(n.userId) !== jwtCreateRoom.user_id &&
                                                                                                    <React.Fragment>
                                                                                                        <td className='text-center'
                                                                                                            onClick={() => this.selectMember(n, ind, "", "")}>
                                                                                                            {
                                                                                                                n.selected == true ?
                                                                                                                    <img src={process.env.PUBLIC_URL + '/images/iconAction/checkbox.png'}
                                                                                                                        width={25} height={25} />
                                                                                                                    :
                                                                                                                    <img src={process.env.PUBLIC_URL + '/images/iconAction/checkboxNull.png'}
                                                                                                                        width={25} height={25} />
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                n.image ?
                                                                                                                    <img src={n.image} width={50} height={50} className='rounded-circle' />
                                                                                                                    :
                                                                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/profileNull.png'}
                                                                                                                        width={50} height={50} className='rounded-circle' />
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td className='tableTextName'>
                                                                                                            <p className='colorBlack'>{n.fullName}</p>
                                                                                                            <p className='colorGray'>{n.role}</p>
                                                                                                        </td>

                                                                                                    </React.Fragment>
                                                                                                }

                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </section>

                                                                    <section alt='Choose commuttee'>

                                                                        <table style={{ marginTop: 20 }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className='text-center' style={{ width: 70 }}
                                                                                        onClick={() => this.selectMemberComm("", "", "All", this.state.selectcommAll)}>
                                                                                        <span style={{ color: "#BFBFBF", fontSize: "12px", fontWeight: '600' }}>{t("chatGroup:all")} </span><br />
                                                                                        {
                                                                                            this.state.selectcommAll ?
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/checkbox.png'}
                                                                                                    width={25} height={25}
                                                                                                />
                                                                                                :
                                                                                                <img src={process.env.PUBLIC_URL + '/images/iconAction/checkboxNull.png'}
                                                                                                    width={25} height={25} />
                                                                                        }
                                                                                    </th>
                                                                                    <th style={{ verticalAlign: 'bottom', fontSize: 18 }} colSpan={2}>{t("chatGroup:Choose commuttee")}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    this.state.commuttee.map((n, ind) => {
                                                                                        return (
                                                                                            <tr key={ind} style={{ verticalAlign: 'middle' }}>
                                                                                                <td className='text-center'
                                                                                                    onClick={() => this.selectMemberComm(n, ind, "", "")}>
                                                                                                    {
                                                                                                        n.selected == true ?
                                                                                                            <img src={process.env.PUBLIC_URL + '/images/iconAction/checkbox.png'}
                                                                                                                width={25} height={25} />
                                                                                                            :
                                                                                                            <img src={process.env.PUBLIC_URL + '/images/iconAction/checkboxNull.png'}
                                                                                                                width={25} height={25} />
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        n.image ?
                                                                                                            <img src={n.image} width={50} height={50} className='rounded-circle' />
                                                                                                            :
                                                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/profileNull.png'}
                                                                                                                width={50} height={50} className='rounded-circle' />
                                                                                                    }

                                                                                                </td>
                                                                                                <td className='tableTextName'>
                                                                                                    <p className='colorBlack'>{n.fullName}</p>
                                                                                                    <p className='colorGray'>{n.homeName}</p>
                                                                                                </td>
                                                                                            </tr>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </section>
                                                                </div>


                                                                <div className='row mt-2'>
                                                                    <div className='col-12 text-right'>
                                                                        <button type='submit' className='btn btn-primary'>{t("Allaction:save")}</button>
                                                                    </div>
                                                                </div>

                                                            </form>

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }


                                        </div>
                                        :
                                        <div className='text-center'>
                                            <img src={process.env.PUBLIC_URL + '/images/icons/alert/loading.gif'} />
                                        </div>
                                }



                            </div>

                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default withRouter(UpdateMeeting);
