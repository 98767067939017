import React, { Component } from 'react'
import Wrapper from '../../../components/wrapper'
import Sidebar from '../../../components/sidebar'
import Header from '../../../components/header'
import WrapperContent from '../../../components/wrapper/wrapperContent'
import _ from "lodash"
import { Translation } from 'react-i18next'
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import HeadContractExpense from './headContractExpense/headContractExpense'
import ContractDetails from './contractDetails/contractDetails'
import ContractContactDetails from './contractContactDetails/contractContactDetails'
import SummarizeContractExpense from './summarizeContractExpense/summarizeContractExpense'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../../env/environment'
import HeadAlertConTractExpense from './headAlert/headAlertConTractExpense'
import SaveNotiDayContractExpense from './mutation/saveNotiDayContractExpense'
import RenewContractExpense from './mutation/renewContractExpense'
import ModalVoid from './modalVoid/modalVoid'
import Swal from 'sweetalert2'
import { Redirect } from "react-router-dom";
import "./style/contractExpense.scss"
import format from 'date-fns/format'
import i18next from 'i18next'
import BackButtonIcon from "../../../components/BackBtn/indexBack";

const query = graphql`
  query updateFormContractExpenseQuery($cxId: String){
    oneContractExpense(cxId: $cxId){
      edges {
        node {
          id
          issuedDate
          docNumber
          status
          notiBeforeEnd
          voidRemark
          voidDate
          purchaseOrderes{
            id
            docNumber
            issuedDate
            status
            procurementForm{
              id
              docNumber
              issuedDate
              creditor{
                id
                name
              }
              contractNumber
              contractDate
              startContractDate
              endContractDate
              contractPeriod
              contractor
              contractorTel
              contractorEmail
              deliveryAddress
              deliveryDate
              remark
              presenter
              total
              chartOfAccount{
                id name nameEn chartOfAccountCode 
              }
              procurementList{
                edges{
                  node{
                    id
                    productAndService{
                      id
                      name
                      productCode
                    }
                    chartOfAccount{
                      id
                      chartOfAccountCode
                      name
                    }
                    description
                    unitItems
                    price
                    discount
                    vat
                    whtRate
                    whtRatePercent
                    preTaxAmount
                    total
                    budget
                  }
                }
              }
              procurementFormContract{
                edges{
                  node{
                    fileUpload
                    pathName
                    fileName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default class updateFormContractExpense extends Component {

  constructor(props) {
    super(props)

    this.state = {
      issuedDate: new Date(),
      creditor: "",
      category: "",
      refNumberPO: "",

      startContractDate: new Date(),
      endContractDate: new Date(),
      contractPeriod: "",
      sumItemsTotal: "",
      serviceFeeOfInstalment: "",
      taxAmount: "",
      haveVat: "",
      whtRate: "",
      procurementFormContract: [],
      procurementList: [],

      contractor: "",
      contractorTel: "",
      contractorEmail: "",
      deliveryAddress: "",
      deliveryDate: new Date(),
      remark: "",
      notiDay: "30",

      statusTimeoutContact: "0",

      closeAlert: false,
      redirectTo: "",
      notConfirm: false,
      statusVoid: "",
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    this.getData()
  }

  getData = () => {
    fetchQuery(environment, query, { cxId: this.props.match.params.id }).then((response) => {
      if (response.oneContractExpense) {



        let item = response.oneContractExpense.edges[0].node

        var q = Math.abs(new Date().getTime() - new Date(format(item.purchaseOrderes.procurementForm.startContractDate, "YYYY-MM-DD") + "T00:00").getTime());
        var d = Math.abs(new Date(format(item.purchaseOrderes.procurementForm.endContractDate, "YYYY-MM-DD") + "T23:59").getTime() - new Date(format(item.purchaseOrderes.procurementForm.startContractDate, "YYYY-MM-DD") + "T00:00").getTime());
        var getPercentDate = Math.round((q / d) * 100)

        this.setState({
          issuedDate: new Date(item.issuedDate),
          creditor: item.purchaseOrderes.procurementForm.creditor.id,
          category: item.purchaseOrderes.procurementForm.chartOfAccount ? item.purchaseOrderes.procurementForm.chartOfAccount.name : "-",
          refNumberPO: item.purchaseOrderes.docNumber,

          startContractDate: new Date(item.purchaseOrderes.procurementForm.startContractDate),
          endContractDate: new Date(item.purchaseOrderes.procurementForm.endContractDate),
          contractPeriod: item.purchaseOrderes.procurementForm.contractPeriod,
          haveVat: item.purchaseOrderes.procurementForm.procurementList.edges[0].node.vat,
          procurementFormContract: item.purchaseOrderes.procurementForm.procurementFormContract.edges,
          procurementList: item.purchaseOrderes.procurementForm.procurementList.edges,

          contractor: item.purchaseOrderes.procurementForm.contractor,
          contractorTel: item.purchaseOrderes.procurementForm.contractorTel,
          contractorEmail: item.purchaseOrderes.procurementForm.contractorEmail,
          deliveryAddress: item.purchaseOrderes.procurementForm.deliveryAddress,
          deliveryDate: new Date(item.purchaseOrderes.procurementForm.deliveryDate),
          remark: item.purchaseOrderes.procurementForm.remark,
          notiDay: item.notiBeforeEnd ? `${item.notiBeforeEnd}` : "30",
          statusTimeoutContact: getPercentDate
        })
        this.calSummary()
      }
    })
  }

  calSummary() {
    let sumItemsTotal = 0
    let taxAmount = 0
    let serviceFeeOfInstalment = 0

    _.forEach(this.state.procurementList, (procurement) => {
      let totalItem = procurement.node.price * procurement.node.unitItems;

      sumItemsTotal += totalItem
      taxAmount += totalItem * procurement.node.vat / 100
    })

    serviceFeeOfInstalment = sumItemsTotal + taxAmount

    this.setState({
      sumItemsTotal: sumItemsTotal,
      taxAmount: taxAmount,
      serviceFeeOfInstalment: serviceFeeOfInstalment
    })
  }

  handleChangeInput = (even) => {
    let { name, value } = even.target;

    this.setState({ [name]: value })
  }

  handleCloseHeaderAlert = () => {
    this.setState({ closeAlert: true })
  }

  handleCloseModal = () => {
    this.setState({ notConfirm: false })
  }

  handleSave = (e) => {
    e.preventDefault()

    let variableSaveNotiDay = {
      input: {
        contractExpenseId: this.props.match.params.id,
        notiDay: this.state.notiDay
      }
    }

    SaveNotiDayContractExpense(
      variableSaveNotiDay,
      (res) => {
        if (res.saveNotiDayContractExpense.ok) {
          Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
            this.setState({ redirectTo: "/accounting/expense/contract_expese/list/all" })
          })

        } else {
          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please try again."), 'warning')
        }
      },
      () => { Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning') }
    )
  }

  handleRenew = () => {
    let variableRenew = {
      input: {
        contractExpenseId: this.props.match.params.id,
      }
    }

    RenewContractExpense(
      variableRenew,
      (res) => {
        if (res.renewContractExpense.ok) {
          Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
            this.setState({ redirectTo: "/accounting/expense/contract_expese/list/all" })
          })

        } else {
          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please try again."), 'warning')
        }
      },
      () => { Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning') }
    )

  }

  handleVoid = (statusVoidInput) => {
    this.setState({
      notConfirm: true,
      statusVoid: statusVoidInput
    })
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={{ pathname: this.state.redirectTo }} />
    }
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box" id="contractExpenseContractExpense">
                <BackButtonIcon
                  LinkBack={"/accounting/expense/contract_expese/list/all"}
                  LinkText={this.props.match.params.docNumber}
                  boxHtmlText={
                    <div className='col-9'>
                      <div className="text-center mr-5">
                        <label style={{ fontSize: "20px" }}>{i18next.t("updateFormContractExpense:Contract Duration")}</label>
                        <div className="progress" style={{ width: "100%" }}>

                          <div className="progress-bar bg-danger" role="progressbar" style={{ width: this.state.statusTimeoutContact + "%" }} aria-valuenow={this.state.statusTimeoutContact} aria-valuemin="0" aria-valuemax="100">
                          </div>
                        </div>
                        <label style={{ float: 'left' }}>
                          {format(this.state.startContractDate, "MMMM YYYY")}
                        </label>
                        <label style={{ float: 'right' }}>
                          {format(this.state.endContractDate, "MMMM YYYY")}
                        </label>
                      </div>
                    </div>
                  }
                />

                <form onSubmit={this.handleSave}>
                  <div className="content-inner">

                    {(this.props.match.params.status === "NEAR_END" || this.props.match.params.status === "RENEW" || this.props.match.params.status === "NOT_RENEW" || this.props.match.params.status === "VOID") && !this.state.closeAlert ?
                      <div className="row">
                        <div className="col">
                          <HeadAlertConTractExpense
                            status={this.props.match.params.status}
                            handleCloseHeaderAlert={this.handleCloseHeaderAlert}
                          />
                        </div>
                      </div> : <div className="spaceTop" />
                    }

                    <div className="row">
                      <div className="col">
                        <HeadContractExpense
                          docNumber={this.props.match.params.docNumber} issuedDate={this.state.issuedDate}
                          creditor={this.state.creditor} category={this.state.category}
                          refNumberPO={this.state.refNumberPO} taxNumber={this.state.taxNumber}
                          contractTerm={this.state.contractTerm} status={this.props.match.params.status}
                        />
                      </div>
                    </div>

                    <div className="row spaceTop">
                      <div className="col">
                        <ContractDetails
                          startContractDate={this.state.startContractDate}
                          endContractDate={this.state.endContractDate}
                          contractPeriod={this.state.contractPeriod}
                          procurementFormContract={this.state.procurementFormContract}
                          procurementList={this.state.procurementList}
                        />
                      </div>
                    </div>

                    <div className="row spaceTop">
                      <div className="col">
                        <ContractContactDetails
                          contractor={this.state.contractor}
                          contractorTel={this.state.contractorTel}
                          contractorEmail={this.state.contractorEmail}
                          deliveryAddress={this.state.deliveryAddress}
                          deliveryDate={this.state.deliveryDate}
                        />
                      </div>
                    </div>

                    <div className="row spaceTop">
                      <div className="col">
                        <SummarizeContractExpense
                          remark={this.state.remark}
                          notiDay={this.state.notiDay}
                          sumItemsTotal={this.state.sumItemsTotal}
                          serviceFeeOfInstalment={this.state.serviceFeeOfInstalment}
                          taxAmount={this.state.taxAmount}
                          status={this.props.match.params.status}
                          handleChangeInput={this.handleChangeInput}
                        />
                      </div>
                    </div>

                    {this.state.notConfirm &&
                      <ModalVoid
                        open={this.state.notConfirm}
                        handleCloseModal={this.handleCloseModal}
                        handleChangeInput={this.handleChangeInput}
                        contractExpenseId={this.props.match.params.id}
                        statusVoid={this.state.statusVoid}
                        docNumber={this.props.match.params.docNumber}
                      />
                    }

                    {(this.props.match.params.status === "WAIT" || this.props.match.params.status === "IN_PROGRESS" || this.props.match.params.status === "NEAR_END" || this.props.match.params.status === "RENEW") &&
                      <div className="row spaceTop mb-5">
                        <div className="col d-flex justify-content-end">
                          {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_contract_expense_delete' }) &&
                            <button type="button" className="btnRed" onClick={() => this.handleVoid("void")}>
                              <span>{i18next.t("updateFormContractExpense:Cancel Contract")}</span>
                            </button>
                          }
                          {this.props.match.params.status === "WAIT" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_contract_expense_edit' }) &&
                            <button type="submit" className="ml-5 btnPrimary">
                              <span>{i18next.t("Allaction:save")}</span>
                            </button>
                          }
                          {this.props.match.params.status === "NEAR_END" && _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_contract_expense_approve' }) &&
                            <React.Fragment>
                              <button type="button" className="ml-5 btnGray" onClick={() => this.handleVoid("not_renew")}>
                                <span>{i18next.t("contract_expese_list:Don't Renew")}</span>
                              </button>
                              <button type="button" className="ml-5 btnPrimary" onClick={this.handleRenew}>
                                <span>{i18next.t("contract_expese_list:Renew")}</span>
                              </button>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    }

                    <div className="mb-5" />
                  </div>
                </form>
              </div>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    )
  }
}
