import React from "react";
import numberWithCommas from "../../../libs/numberWithComma";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import ComponentPagination from "../../../libs/componentPagination";
import Pagination from "../../../libs/newPagination";
import {fetchQuery} from "relay-runtime";
import Swal from "sweetalert2";
import CreateServicePricingEachUnitMutation from "./mutations/CreateServicePricingEachUnitMutation"
import ExportService from "./server_export";

import Loading from "../../../libs/loading"
import i18next from "i18next";

const _ = require('lodash');


const query = graphql`
    query pricingCalculateTableQuery ($first: Int, $last: Int, $service_id: ID!) {
        allResidential(first: $first, last: $last) {
            edges {
                node {
                    id name size ownershipRatio
                    contact {
                        edges {
                            node {
                                id firstName lastName
                            }
                        }
                    }
                    servicePricing(service_Id: $service_id) {
                        edges {
                            node {
                                id pricing
                            }
                        }
                    }
                }
            }
            totalCount
        }
        areaSummary
        totalServicePricingEachUnit(serviceId: $service_id)
        allProjects {
            edges {
                node {
                id
                typeOfProject
                }
            }
        }
    }
`;


class PricingCalculateTable extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state.data = false;
        this.state.loading = false;
        this.state.alldata = false;
        this.state.typeOfProject = "";
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.calPrice = this.calPrice.bind(this);
        this.onMutationError = this.onMutationError.bind(this);
        this.onMutationComplete = this.onMutationComplete.bind(this);
    }


    componentDidMount() {
        this.query();
        this.queryAll()
    }

    calPrice(price, size, ownershipRatio) {
        if (_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'STATIC') {
            return price;
        }

        if (_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'AREA') {
            if (_.get(this.props.state.product.servicePricing, 'edges.0.node.rounding') === 'UP') {
                return numberWithCommas(Math.ceil(parseFloat((price * size).toFixed(2))));
            } else if (_.get(this.props.state.product.servicePricing, 'edges.0.node.rounding') === 'DOWN') {
                return numberWithCommas(Math.floor(parseFloat((price * size).toFixed(2))));
            } else {
                return numberWithCommas(parseFloat((price * size).toFixed(2)));
            }
        }

        if (_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'RATIO') {
            return numberWithCommas( Math.round(((price * ownershipRatio) + Number.EPSILON) * 100) / 100 )
        }

        return "";
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextState.first !== this.state.first || nextState.last !== this.state.last) {
            this.query();
        }
    }

    query() {
        let service_id = this.props.state.product.id;
        this.setState({data: false}, () => {
            fetchQuery(environment, query, {
                first: this.state.first,
                last: this.state.last,
                service_id: service_id
            }).then(data => {
                this.setState({data: data, typeOfProject: data.allProjects.edges[0].node.typeOfProject});
            })
        });

    }

    queryAll() {
        let service_id = this.props.state.product.id;
        this.setState({data: false}, () => {
            fetchQuery(environment, query, {
                service_id: service_id
            }).then(data => {
                this.setState({alldata: data});
            })
        });

    }
    handleChange(event) {
        event.preventDefault();
        let e = _.cloneDeep(event);
        if(e.target.value === ""){
            this.setState(prevState => {
                return _.set(_.cloneDeep(prevState), e.target.name, 0);
            });
        }else{
            this.setState(prevState => {
                return _.set(_.cloneDeep(prevState), e.target.name, e.target.value);
            });
        }

    }
    save() {
        let data2 = this.state.alldata;
        let isFirst = false; //กดบันทึกครั้งแรก
        let isNotInput = true; //จะแจ้งเตือนเฉพาะครั้งแรก ไม่เคยมีการบันทึกค่ายูนิต และเป็นค่าว่างทั้งหมด
        this.state.data.allResidential.edges.map((data,index) => {
            let value = 0
            if(data.node.servicePricing.edges.length > 0){
                value = data.node.servicePricing.edges[0].node.pricing
                data2 = _.set(data2, 'allResidential.edges.'+index+'.node.servicePricing.edges.0.node.pricing', value);
                isNotInput = false;
            }else{
                data2 = _.set(data2, 'allResidential.edges.'+index+'.node.servicePricing.edges.0.node.pricing', 0);
            }
            
        })
        let final_data = data2;
        data2.allResidential.edges.map((data,index) => {
            let value = parseInt(0);
            if(data.node.servicePricing.edges.length > 0){
                value = data.node.servicePricing.edges[0].node.pricing
                final_data = _.set(final_data, 'allResidential.edges.'+index+'.node.servicePricing.edges.0.node.pricing', value);
            }else{
                final_data =  _.set(final_data, 'allResidential.edges.'+index+'.node.servicePricing.edges.0.node.pricing', parseInt(0));
                isFirst = true;
            }
        })
        this.setState({loading: true});
        let data = this.state.data
        if(isFirst){
            data = final_data;
        }
        let variables = {
            input: {
                data: JSON.stringify(data),
                serviceId: this.props.state.product.id
            }
        };
        if(isNotInput){
            Swal.fire(i18next.t("pricingCalculateTable:Unable to save."), i18next.t("pricingCalculateTable:Please enter the amount for collecting (minimum value 0) at least 1 unit."), 'warning');
            this.setState({loading: false});
        }else{
            CreateServicePricingEachUnitMutation(variables, (response) => this.onMutationComplete(response), this.onMutationError)
        }
        

    }

    onMutationComplete(response){
        this.setState({loading: false});
        let totalServicePricingEachUnit = 0;
        if(response.createServicePricingEachUnit !== null){
            totalServicePricingEachUnit = response.createServicePricingEachUnit.totalServicePricingEachUnit;
        }
        this.setState(prevState => {
            return _.set(_.cloneDeep(prevState),
                "data.totalServicePricingEachUnit",
                totalServicePricingEachUnit
            );
        });
        this.query()

    }

    onMutationError() {
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'warning');
        this.setState({loading: false});
    }

    render() {
        return (

            <React.Fragment>
                <div className="w-100"/>
                <p data-toggle="modal" data-target="#pricingTable" className="link float-left">
                    {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'INDIVIDUAL' &&
                    <React.Fragment>
                        <img src={process.env.PUBLIC_URL + '/images/icons/view-table.png'} alt="view" className="mr-1"/>
                        <span style={{fontSize: 12}}>{i18next.t("pricingCalculateTable:View Collection Summary")}</span>
                    </React.Fragment>
                    }
                    {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'INDIVIDUAL' &&
                    this.props.state.product.id &&
                    <React.Fragment>
                        <img src={process.env.PUBLIC_URL + '/images/icons/edit-table.png'} alt="view" className="mr-1"/>
                        <span style={{fontSize: 12}}>{i18next.t("pricingCalculateTable:Edit Collection Amount")}</span>
                    </React.Fragment>
                    }
                </p>

                {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'INDIVIDUAL' &&
                !this.props.state.product.id &&
                <span style={{fontSize: 12}} className="alert-danger p-1">{i18next.t("pricingCalculateTable:Enter prices for each unit after saving")}</span>
                }

                <div className="modal fade" id="pricingTable" role="dialog" ref={modal => this.modal = modal}
                     aria-labelledby="pricingTableLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-gray">

                                {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'STATIC' &&
                                <div className="modal-title" id="pricingTableLabel">
                                    {i18next.t("pricingCalculateTable:Amount to Collect")} <br/>
                                    {this.state.data &&
                                    <strong>
                                        {numberWithCommas(
                                            _.get(this.state.data, 'allResidential.totalCount') *
                                            _.get(this.props.state.product, 'price', 0))
                                        } {i18next.t("Allaction:THB")}
                                    </strong>
                                    }
                                </div>
                                }

                                {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'AREA' &&
                                <div className="modal-title" id="pricingTableLabel">
                                    {i18next.t("pricingCalculateTable:Total Area")} <br/>
                                    <strong>{numberWithCommas(this.state.data.areaSummary)}</strong>
                                    {/*จำนวนที่เรียกเก็บ <span className="cursor">(ข้อมูลจะเปลี่ยนแปลงหลังการบันทึก)</span>*/}
                                    {/*<br/>*/}
                                    {/*<strong>- บาท</strong>*/}
                                </div>
                                }

                                {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'INDIVIDUAL' &&
                                <React.Fragment>
                                    <div className="modal-title" id="pricingTableLabel">
                                    {i18next.t("pricingCalculateTable:Amount to Collect")} <span className="cursor">({i18next.t("pricingCalculateTable:Data will change after saving")})</span>
                                        <br/>
                                        <strong>{numberWithCommas(this.state.data.totalServicePricingEachUnit)} บาท</strong>
                                    </div>

                                    <div className="float-right">
                                        <button type="button" className="btn btn-secondary mr-1"
                                                data-dismiss="modal">{i18next.t("pricingCalculateTable:Close")}
                                        </button>
                                        <button type="button" className="btn btn-primary" onClick={this.save}
                                                disabled={this.state.loading}>
                                            {this.state.loading &&
                                                <span className="spinner-border spinner-border-sm align-middle mr-2"/>
                                            }
                                            {i18next.t("Allaction:save")}
                                        </button>
                                    </div>
                                </React.Fragment>
                                }

                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h6>{i18next.t("pricingCalculateTable:Specify prices by room")}</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <ExportService state={this.props.state}/>
                                    </div>
                                </div>
                                {this.state.data &&
                                <React.Fragment>
                                    <div className="mt-3 table-responsive card">
                                        <table className="table">
                                            <thead>
                                            <tr className="bg-light">
                                                <th>{i18next.t("pricingCalculateTable:Room")}</th>
                                                <th>{i18next.t("pricingCalculateTable:Name Surname")}</th>
                                                {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'RATIO' ?
                                                    <th className="text-right">{i18next.t("pricingCalculateTable:Ownership Ratio")}</th> :
                                                    <React.Fragment>
                                                        <th className="text-right">%</th>
                                                        <th className="text-right">{i18next.t("pricingCalculateTable:Area")} ({this.state.typeOfProject ? i18next.t("pricingCalculateTable:sq.m.") :i18next.t("pricingCalculateTable:sq.w.")})</th>
                                                    </React.Fragment>
                                                }
                                                {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'INDIVIDUAL' &&
                                                <th className="text-right">{i18next.t("pricingCalculateTable:Price per Unit")}</th>
                                                }
                                                <th className="text-right">{i18next.t("pricingCalculateTable:Collect")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.data.allResidential.edges.map((resident, index) => (
                                                <tr key={resident.node.id}>
                                                    <td>{resident.node.name}</td>
                                                    <td>
                                                        {_.get(resident, 'node.contact.edges.0.node.firstName')} &nbsp;
                                                        {_.get(resident, 'node.contact.edges.0.node.lastName')}
                                                    </td>
                                                    {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'RATIO' ?
                                                        <td className="text-right">
                                                            {numberWithCommas(resident.node.ownershipRatio)}
                                                            </td> :
                                                        <React.Fragment>
                                                            <td className="text-right">
                                                                {numberWithCommas((resident.node.size / this.state.data.areaSummary) * 100)}%
                                                            </td>
                                                            <td className="text-right">{numberWithCommas(resident.node.size)}</td>
                                                        </React.Fragment>
                                                    }
                                                    {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'INDIVIDUAL' &&
                                                    <td className="text-right">{_.get(this.props.state.product, 'price', 0)}</td>
                                                    }
                                                    <td className="text-right" width={120}>
                                                        {this.calPrice(_.get(this.props.state.product, 'price', 0), resident.node.size, resident.node.ownershipRatio)}
                                                        {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'INDIVIDUAL' &&
                                                        <input
                                                            onKeyPress={(e)=>{e.key === "Enter" && e.preventDefault()}}
                                                            name={"data.allResidential.edges." + index + ".node.servicePricing.edges.0.node.pricing"}
                                                            value={_.get(this.state, "data.allResidential.edges."+index+".node.servicePricing.edges.0.node.pricing", "")}
                                                            type="number" className="form-control text-right"
                                                            onChange={this.handleChange}/>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <Pagination changePage={this.changePage}
                                                    first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={this.state.data.allResidential.totalCount}/>
                                    </div>
                                </React.Fragment>
                                }

                                {!this.state.data &&
                                <Loading/>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default PricingCalculateTable;
