import i18next from 'i18next';
import React, { Component } from 'react';
import './css/invoiceCreateDetailContact.scss'

const $ = window.jQuery;

class InvoiceCreateDetailContact extends Component {

    constructor(props) {
        super(props);
        this.onClickOpenModal = this.onClickOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

    }

    componentDidMount() {
        $(this.modal).on('hidden.bs.modal');
    }

    onClickOpenModal() {
        $(this.modal).modal('show');

    }

    onCloseModal() {
        $(this.modal).modal('hide');

    }

    render() {
        return (
            <React.Fragment>
                <div id="invoice_detail_contact">

                    <div className="input-group mb-3">
                        <input type="text" className="form-control inputDetailContactLeft" value={i18next.t("AgentRole:Multi-room")}
                            disabled />
                        <div className="input-group-append inputDetailContactRight">
                            <span className="input-group-text" id="basic-addon2"><u onClick={this.onClickOpenModal}
                                className="textDetail">ดูข้อมูล</u></span>
                        </div>
                    </div>

                    <div className="modal fade bd-example-modal-lg" id="modal_create_product" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modal => this.modal = modal}>
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="col" style={{ paddingLeft: 0 }}>
                                        <h5 className="modal-title">
                                            ข้อมูลลูกค้า
                                        </h5>
                                    </div>

                                    <button type="button" className="close" onClick={this.onCloseModal}
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="col-12">
                                        <div className="table-responsive fade-up">
                                            <table className="table table-hover mt-2">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>รหัสลูกค้า</th>
                                                        <th>เลขที่ห้อง/บ้านเลขที่/บริษัท</th>
                                                        {/*อันนี้ให้เช็คด้วยว่า type ที่กดเข้ามาเป็นแบบไหนแล้วโชว์แบบนั้น*/}
                                                        <th>ชื่อ</th>
                                                        <th>นามสกุล</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.contactResidential?.map((contact) => {
                                                        // let first_name_list = contact?.firstName?.split(",");
                                                        // let last_name_list = contact?.lastName?.split(",");
                                                        let owner_name_list = contact?.ownerName?.split(",");
                                                        let renter_name_list = contact?.renterName?.split(",");
                                                        let agent_name_list = contact?.agentName?.split(",");

                                                        if (owner_name_list.length !== 1 || renter_name_list.length !== 1 || agent_name_list.length !== 1) {

                                                            let dataObjectName =
                                                                this.props.contactNameRole === "agent" ? agent_name_list :
                                                                    this.props.contactNameRole === "rent" ? renter_name_list :
                                                                        this.props.contactNameRole === "owner" ? owner_name_list :
                                                                        "-"
                                                                            // first_name_list


                                                            return dataObjectName.map((value, index) =>
                                                                <tr key={'owner' + index}>
                                                                    <td>{index === 0 ? contact.refNumber : ''}</td>
                                                                    <td>{index === 0 ? contact.name : ''}</td>
                                                                    {/* <td>{value}</td> */}
                                                                    <td colSpan={2}>{value}</td>
                                                                </tr>
                                                            )
                                                        }

                                                        return (
                                                            <tr key={contact.id}>
                                                                <td>{contact.refNumber}</td>
                                                                <td>{contact.name}</td>
                                                                {
                                                                    this.props.contactNameRole === "agent" && contact.agentName ?
                                                                        <td colSpan={2}>{contact.agentName}</td> :
                                                                        this.props.contactNameRole === "rent" && contact.renterName ?
                                                                            <td colSpan={2}>{contact.renterName}</td>
                                                                            :
                                                                            this.props.contactNameRole === "owner" ?
                                                                                <td colSpan={2}>{contact.ownerName}</td>
                                                                            :
                                                                            <td colSpan={2}>-</td>

                                                                }
                                                            </tr>
                                                        )

                                                    })

                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default InvoiceCreateDetailContact
