import React, { Component } from 'react';
import Sidebar from '../../components/sidebar';
import Wrapper from '../../components/wrapper';
import Header from "../../components/header";
import WrapperContent from '../../components/wrapper/wrapperContent';
import SettingTopMenuNavigation from '../settingTopMenuNavigation';
import './settingAsset.scss'
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import {graphql} from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import SearchSelect from '../../libs/searchSelect';
import Swal from 'sweetalert2';
import {Redirect} from 'react-router';
import queryAsset from './queryAsset';
import _ from 'lodash'
import Loading from '../../libs/loading';
import i18n from "i18next";

const mutation = graphql`
    mutation settingAssetDetailMutation($input: CreateAndUpdateSettingAssetInput!) {
        createAndUpdateSettingAsset(input: $input) {
            ok
            message
        }
    }
`;

const query = graphql`
    query settingAssetDetailQuery($settingAssetId: String) {
        allSettingAsset(settingAssetId: $settingAssetId){
            edges{
                node{
                    id
                    typeAsset
                    depreciationRatePercent
                    depreciationRate
                    lifeTimeYear
                    chartOfAccountType {
                        id
                        chartOfAccountCode
                        name
                        nameEn
                    }
                    chartOfAccountDepreciation {
                        id
                        chartOfAccountCode
                        name
                        nameEn
                    }
                    chartOfAccountDepreciationCumulative {
                        id
                        chartOfAccountCode
                        name
                        nameEn
                    }
                }
            }
        } 
    }`;

const chartOfAccount = {
    land: {
        chartOfAccountType: '1210-01',
        chartOfAccountDepreciation: '',
        chartOfAccountDepreciationCumulative: '',
        depreciationRatePercent: null,
        lifeTimeYear: null,
        name:'ที่ดิน'
    },
    building: {
        chartOfAccountType: '1210-02',
        chartOfAccountDepreciation: '5700-01',
        chartOfAccountDepreciationCumulative: '1220-01',
        depreciationRatePercent: 5,
        lifeTimeYear: 20,
        name:'อาคาร'
    },
    building_renovation: {
        chartOfAccountType: '1210-03',
        chartOfAccountDepreciation: '5700-02',
        chartOfAccountDepreciationCumulative: '1220-02',
        depreciationRatePercent: 5,
        lifeTimeYear: 20,
        name:'ส่วนต่อเติมและปรับปรุงอาคาร'
    },
    office_decoration: {
        chartOfAccountType: '1210-04',
        chartOfAccountDepreciation: '5700-03',
        chartOfAccountDepreciationCumulative: '1220-03',
        depreciationRatePercent: 20,
        lifeTimeYear: 5,
        name:'เครื่องตกแต่งสำนักงาน'
    },
    office_equipment: {
        chartOfAccountType: '1210-05',
        chartOfAccountDepreciation: '5700-04',
        chartOfAccountDepreciationCumulative: '1220-04',
        depreciationRatePercent: 20,
        lifeTimeYear: 5,
        name:'อุปกรณ์และเครื่องใช้สำนักงาน'

    },
    tools: {
        chartOfAccountType: '1210-06',
        chartOfAccountDepreciation: '5700-05',
        chartOfAccountDepreciationCumulative: '1220-05',
        depreciationRatePercent: 20,
        lifeTimeYear: 5,
        name:'เครื่องมือ / อุปกรณ์งานช่าง'
    },
    training_equipment: {
        chartOfAccountType: '1210-07',
        chartOfAccountDepreciation: '5700-06',
        chartOfAccountDepreciationCumulative: '1220-06',
        name: 'เครื่องออกกำลังกาย / สันทนาการ',
        depreciationRatePercent: 20,
        lifeTimeYear: 5,
    },
    vehicle: {
        chartOfAccountType: '1210-08',
        chartOfAccountDepreciation: '5700-07',
        chartOfAccountDepreciationCumulative: '1220-07',
        name:'ยานพาหนะ',
        depreciationRatePercent: 20,
        lifeTimeYear: 5,
    },
    common_property: {
        chartOfAccountType: '1210-09',
        chartOfAccountDepreciation: '5700-08',
        chartOfAccountDepreciationCumulative: '1220-08',
        name:'ทรัพย์สินส่วนกลาง',
        depreciationRatePercent: 20,
        lifeTimeYear: 5,
    },
    intangible_asset: {
        chartOfAccountType: '1230-01',
        chartOfAccountDepreciation: '5700-10',
        chartOfAccountDepreciationCumulative: '1240-01',
        name:'สินทรัพย์ไม่มีตัวตน',
        depreciationRatePercent: 20,
        lifeTimeYear: 5,
    },
    other_asset: {
        chartOfAccountType: '1210-10',
        chartOfAccountDepreciation: '5700-09',
        chartOfAccountDepreciationCumulative: '1220-09',
        depreciationRatePercent: 20,
        lifeTimeYear: 5,
        name:'สินทรัพย์อื่นๆ'
    },
};

class SettingAssetDetail extends  Component {
    constructor(props){
        super(props)
        this.state = {
            chartOfAccountTypeList: [],
            chartOfAccountDepreciationCumulativeList: [],
            chartOfAccountDepreciationList: [],
            chartOfAccountTypeId: "",
            chartOfAccountDepreciationCumulativeId: "",
            chartOfAccountDepreciationId: "",
            settingAssetId : this.props.match.params.id ? this.props.match.params.id :  '',
            typeAsset : this.props.match.params.type ? this.props.match.params.type : '',
            depreciationRatePercent : 20,
            lifeTimeYear : 5,
            depreciationType : true,
            inputYear : '',
            redirectToList: false,
            loading: false,
            loadingData : false,
            viewer : [],
            permissionEdit : _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'asset_settingasset_edit'}) ?
            _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'asset_settingasset_edit'}) : false
        }
    }

    async componentDidMount(){
        this.setState({
            chart : await queryAsset()
        }, () => {
            let chartOfAccountTypeList = [];
            let chartOfAccountDepreciationCumulativeList = [];
            let chartOfAccountDepreciationList = [];
            this.state.chart.viewer.allChartOfAccount.edges.forEach((chart_of_account) => {
                if (chart_of_account.node.chartOfAccountCode.startsWith("1210") || chart_of_account.node.chartOfAccountCode.startsWith("1230")) {
                    chartOfAccountTypeList.push(chart_of_account);
                } else if (chart_of_account.node.chartOfAccountCode.startsWith("1220") || chart_of_account.node.chartOfAccountCode.startsWith("1240")) {
                    chartOfAccountDepreciationCumulativeList.push(chart_of_account);

                } else if (chart_of_account.node.chartOfAccountCode.startsWith("5700")) {
                    chartOfAccountDepreciationList.push(chart_of_account);
                }
            });
            this.setState({
                chartOfAccountTypeList: chartOfAccountTypeList,
                chartOfAccountDepreciationCumulativeList: chartOfAccountDepreciationCumulativeList,
                chartOfAccountDepreciationList: chartOfAccountDepreciationList,
            })
        })
        
        if(this.state.settingAssetId === ''){
            this.setDefaultData()
        }else{
            this.fetchData()
        }   
    }

    fetchData = async() => {
        this.setState({loadingData: true})
        await fetchQuery(environment, query, {
            settingAssetId : this.state.settingAssetId
        }).then(data => {
            if(data.allSettingAsset.edges.length > 0){
                this.setState({
                    chartOfAccountDepreciationId :  data.allSettingAsset.edges[0].node.chartOfAccountDepreciation ? data.allSettingAsset.edges[0].node.chartOfAccountDepreciation.id : null,
                    chartOfAccountTypeId : data.allSettingAsset.edges[0].node.chartOfAccountType ?  data.allSettingAsset.edges[0].node.chartOfAccountType.id : null,
                    chartOfAccountDepreciationCumulativeId : data.allSettingAsset.edges[0].node.chartOfAccountDepreciationCumulative ? data.allSettingAsset.edges[0].node.chartOfAccountDepreciationCumulative.id : null, 
                    depreciationRatePercent : data.allSettingAsset.edges[0].node.depreciationRatePercent,
                    lifeTimeYear : (data.allSettingAsset.edges[0].node.lifeTimeYear !== 5 && data.allSettingAsset.edges[0].node.lifeTimeYear !== 20) ?
                        'lifeTimeYearOther' : data.allSettingAsset.edges[0].node.lifeTimeYear,
                    inputYear : (data.allSettingAsset.edges[0].node.lifeTimeYear !== 5 && data.allSettingAsset.edges[0].node.lifeTimeYear !== 20) ?
                        data.allSettingAsset.edges[0].node.lifeTimeYear : ''
                },() => this.setState({loadingData:false}))
            }
        })
        .catch((err) => {
            alert(err)
        })
        this.setState({
            loadingData : false
        })
        
    }

    setDefaultData = () => {
        this.setState({
            loadingData : true
        })
        let chartOfAccountTypeId = '';
        let chartOfAccountDepreciationId = '';
        let chartOfAccountDepreciationCumulativeId = '';
        this.state.chartOfAccountTypeList.forEach((type) => {
            if(this.props.match.params.type === 'other_asset' || this.props.match.params.type === 'intangible_asset'){
                if(type.node.name === chartOfAccount[this.state.typeAsset].name){
                    chartOfAccountTypeId = type.node.id;
                }
            }else{
                if (type.node.chartOfAccountCode === chartOfAccount[this.state.typeAsset].chartOfAccountType) {
                    chartOfAccountTypeId = type.node.id;
                }
            }
            
        });
        this.state.chartOfAccountDepreciationList.forEach((depreciation) => {
            if(this.props.match.params.type === 'other_asset' || this.props.match.params.type === 'intangible_asset'){
                if (_.endsWith(depreciation.node.name,chartOfAccount[this.state.typeAsset].name)) {
                    chartOfAccountDepreciationId = depreciation.node.id;
                }
            }else{
                if (depreciation.node.chartOfAccountCode === chartOfAccount[this.state.typeAsset].chartOfAccountDepreciation) {
                    chartOfAccountDepreciationId = depreciation.node.id;
                }
            }
            
        });
        this.state.chartOfAccountDepreciationCumulativeList.forEach((depreciation_cumulative) => {
            if(this.props.match.params.type === 'other_asset' || this.props.match.params.type === 'intangible_asset'){
                if (_.endsWith(depreciation_cumulative.node.name,chartOfAccount[this.state.typeAsset].name)) {
                    chartOfAccountDepreciationCumulativeId = depreciation_cumulative.node.id;
                }

            }else{
                if (depreciation_cumulative.node.chartOfAccountCode === chartOfAccount[this.state.typeAsset].chartOfAccountDepreciationCumulative) {
                    chartOfAccountDepreciationCumulativeId = depreciation_cumulative.node.id;
                }
            }
            
        });

        this.setState({
            chartOfAccountDepreciationId : chartOfAccountDepreciationId,
            chartOfAccountTypeId : chartOfAccountTypeId,
            chartOfAccountDepreciationCumulativeId : chartOfAccountDepreciationCumulativeId,
            depreciationRatePercent : chartOfAccount[this.props.match.params.type].depreciationRatePercent,
            lifeTimeYear : chartOfAccount[this.props.match.params.type].lifeTimeYear
        },()=> this.setState({loadingData : false}))

    }

    handleChange = (e) => {
        if(e.target.name === 'depreciationRatePercent' ){
            this.setState({
                depreciationRatePercent : e.target.value,
                lifeTimeYear : e.target.value === "5" ? 20 : 5
            })
        }else if(e.target.name === 'lifeTimeYear' ){
            if(e.target.value === "5" || e.target.value === "20"){
                this.setState({
                    lifeTimeYear : e.target.value,
                    depreciationRatePercent : e.target.value === "5" ? 20 : 5
                })
            }else{
                this.setState({
                    lifeTimeYear : e.target.value,
                })
            }
           
        }else{
            this.setState({
                [e.target.name] : e.target.value
            })
        }

        
    }

    submit = (e) => {
        e.preventDefault();
        this.setState({loading:true})
        let variables = {
                input: {
                settingAsset: {
                    settingAssetId: this.state.settingAssetId,
                    typeAsset: this.state.typeAsset,
                    depreciationRatePercent: parseInt(this.state.depreciationRatePercent),
                    lifeTimeYear: this.state.lifeTimeYear !== 'lifeTimeYearOther' ? parseInt(this.state.lifeTimeYear) : parseFloat(this.state.inputYear),
                    chartOfAccountType:this.state.chartOfAccountTypeId,
                    chartOfAccountDepreciation:this.state.chartOfAccountDepreciationId,
                    chartOfAccountDepreciationCumulative:this.state.chartOfAccountDepreciationCumulativeId
                }
            } 
        };
        
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createAndUpdateSettingAsset.ok) {
                        Swal.fire(i18n.t('settingAsset:savedSuccessfully'), '', 'success').then(() => {
                            this.setState({redirectToList: true});
                        });
                    } else {
                        this.setState({loading: false});
                        Swal.fire(i18n.t('settingAsset:saveFailed'), i18n.t('settingAsset:pleaseCheckAgain'), 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18n.t('settingAsset:pleaseTryAgain'), 'warning');
                },
            },
        )
    }

    render(){
        if (this.state.redirectToList) {
            return <Redirect to="/setting/asset"/>;
        }
        return(
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <SettingTopMenuNavigation mini={true}/>
                    <Translation>
                        {t => 
                            <div className="container-fluid box" id="asset-setting">
                                <div className="row justify-content-between">
                                    <div className="col">
                                            <Translation>
                                                {
                                                    t => <h4 className="mb-4">
                                                            <Link to="/setting/asset">
                                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                    alt="back" className="back"/>
                                                            </Link> {t("settingAsset:Assets Edit")}
                                                        
                                                        </h4>
                                                }
                                            </Translation>
                                    </div>
                                </div>
                                {this.state.loadingData ?
                                    <div className='content-inner padding'>
                                        <Loading/>
                                        {/* <span><span className='spinner-border spinner-border-sm align-middle mr-2'/>{i18next.t("Allaction:Preparing information")}</span> */}
                                    </div>
                                    :
                                    <form onSubmit={this.submit} id="asset">
                                        <div className="content-inner padding" >
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6>{t("settingAsset:Asset Name")}</h6>
                                                </div>
                                                <div className="col-8 mb-24">
                                                    <input type="text" className="form-control form-border" disabled={true} 
                                                    value={t('assetSetting:'+this.state.typeAsset)}/>
                                                </div>
                                                <div className="col-4 mb-24" />
                                                <div className="col-12">
                                                    <h6>{t("settingAsset:Depreciate calculation")}</h6> 
                                                </div>
                                                <div className="col-6 mb-24">
                                                    <select className="form-control dropdown-custom-arrow form-border"
                                                            value="STRAIGHT_LINE" disabled={true}>
                                                        <option value="STRAIGHT_LINE">{t("settingAsset:Straight Line")}</option>
                                                    </select>
                                                </div>
                                                <div className="col-6 mb-24"/>
                
                                               {this.state.typeAsset !== 'land' &&
                                                <React.Fragment>
                                                        <div className="col-12 mb-24">
                                                            <h5>{t("settingAsset:Depreciation Rate")}</h5>
                                                        </div>
                                                        <div className="col-12 form-inline mb-24">
                                                            <span className="mr-4">{t("settingAsset:Lifetime")}</span> 
                                                            <select className="form-control" name="lifeTimeYear"
                                                                    value={this.state.lifeTimeYear}
                                                                    onChange={this.handleChange}
                                                                    disabled={!this.state.permissionEdit}
                                                            >
                                                                <option value={20}>20</option>
                                                                <option value={5}>5</option>
                                                                {/* <option value="lifeTimeYearOther">ระบุเอง</option> */}
                                                            </select>
                                                            {this.state.lifeTimeYear === 'lifeTimeYearOther' &&
                                                                <input type="number" 
                                                                    className="form-control mr-2 ml-2 text-right" style={{width: '88px'}} 
                                                                    value={this.state.inputYear}
                                                                    name="inputYear"
                                                                    step={1}
                                                                    min={1}
                                                                    onChange={this.handleChange}
                                                                    disabled={!this.state.permissionEdit}
                                                                />
                                                            }
                                                            <span className='ml-2'>{t("settingAsset:Year")}</span>
                                                        </div>
                                                        <div className="col-12 form-inline mb-24">
                                                            <span className="mr-4">{t("settingAsset:% per year")}</span> 
                                                        <select className="form-control" name="depreciationRatePercent"
                                                                    value={this.state.depreciationRatePercent}
                                                                    onChange={this.handleChange}
                                                                    disabled={!this.state.permissionEdit}
                                                            >
                                                                <option value={20}>20</option>
                                                                <option value={5}>5</option>
                                                            </select>
                                                            <span className='ml-2'> % {t("settingAsset:Per Year")}</span>
                                                        </div>
                                                </React.Fragment>
                                               }
                                            </div>
                
                                            <div className="row mt-24">
                                                <div className="col-12">
                                                    <h4 className="mb-24"> {t("accounting:account")}</h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row mt-24 mb-24">
                                                    <div className="col-12">
                                                        <h6>{t("settingAsset:Asset Account")}</h6>
                                                    </div>
                                                    <div className="col-6 mb-24">
                                                            <SearchSelect 
                                                            onChange={this.handleChange} 
                                                            value={this.state.chartOfAccountTypeId}
                                                            name="chartOfAccountTypeId" placeholder="รายการ"
                                                            queryObject={this.state.chartOfAccountTypeList}
                                                            keyOfValue="id" require={true}
                                                            className="form-border"
                                                            keyOfLabel="chartOfAccountCode:name"
                                                            disabled={!this.state.permissionEdit}
                                                            // disabled={this.props.match.params.id && this.props.match.params.type !== 'land'}
                                                            />
                                                    </div>
                                                    <div className="col-6 mb-24" />
                
                                                {this.state.typeAsset !== 'land' && 
                                                    <React.Fragment>
                                                        <div className="col-12">
                                                            <h6>{t("settingAsset:Accumulated depreciation account")}</h6>
                                                        </div>
                                                        <div className="col-6 mb-24">
                                                            <SearchSelect 
                                                                onChange={this.handleChange} 
                                                                value={this.state.chartOfAccountDepreciationCumulativeId}
                                                                name="chartOfAccountDepreciationCumulativeId" placeholder="รายการ"
                                                                queryObject={this.state.chartOfAccountDepreciationCumulativeList}
                                                                keyOfValue="id" require={true}
                                                                keyOfLabel="chartOfAccountCode:name" 
                                                                disabled={!this.state.permissionEdit}
                                                            />
                                                        </div>
                                                        <div className="col-6 mb-24" />
                                                    </React.Fragment>
                                                }
                
                                                {this.state.typeAsset !== 'land' &&
                                                    <React.Fragment>
                                                        <div className="col-12">
                                                            <h6>{t("settingAsset:Depreciation account(Payment)")}</h6>
                                                        </div>
                                                        <div className="col-6 mb-24">
                                                            <SearchSelect 
                                                                onChange={this.handleChange} 
                                                                value={this.state.chartOfAccountDepreciationId}
                                                                name="chartOfAccountDepreciationId" placeholder="รายการ"
                                                                queryObject={this.state.chartOfAccountDepreciationList}
                                                                keyOfValue="id" require={true}
                                                                keyOfLabel="chartOfAccountCode:name"
                                                                disabled={!this.state.permissionEdit}
                                                            />
                                                        </div>
                                                        <div className="col-6 mb-24" />
                                                    </React.Fragment>
                                                }
                                            </div>
                                            <div className="row mt-24 ">
                                                <div className="col-12">
                                                    {this.state.permissionEdit &&
                                                        <button className="float-right submit-btn" type="submit" form="asset">
                                                            <span> {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}{t("Allaction:save")}</span>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </form>
                                }
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }



}
export default SettingAssetDetail;