import React, { Component } from 'react';
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import {commitMutation} from "react-relay";
import environment from '../../../env/environment';
import { Translation } from 'react-i18next';
import Loading from '../../../libs/loading';
import _ from 'lodash';
import Swal from 'sweetalert2';
import CollectionLawFrimSettingViewForm from './collectionLawFrimSettingViewForm';
import { encode } from 'base-64';
import "./collectionLawFrimSetting.scss";

import i18next from 'i18next';

const query = graphql`
     query collectionLawFrimSettingFormQuery($siteId:ID) {
        allCollectionLawFirmFromSetting(documentType: "FM_CF_01", siteId:$siteId){
            edges{
                node{
                    id
                    documentType
                    dueDateDays
                    dueDateDaysOnsite
                    feeRateTh
                    feeRateOther
                    lawyerSignature
                    lawyerName
                    rulesRegulationsjuristic
                    telOffice
                    telJuristic
                    language
                    creator
                    creatorEdit
                    site{
                      id
                      project{
                          edges{
                              node{
                                  id
                                  nameTh
                              }
                          }
                      }
                   } 
                }
            } 
        }
    }
`;


const mutation = graphql`
  mutation collectionLawFrimSettingFormMutation  ($input: CreateAndUpdateCollectionLawFirmFromSettingOnsiteInput!) {
    createAndUpdateCollectionLawFirmFromSettingOnsite(input:$input){
        ok
        massage
    }
}`;

class CollectionLawFrimSettingForm extends Component {
    constructor(props){
        super(props)
        this.state = { 
            dueDateDays:null,
            documentType:'FM_CF_01',
            feeRateTh:null,
            feeRateOther:null
        }

       // this.fetchData = this.fetchData.bind(this)
    }

    componentDidMount(){
        this.fetchData();
    }

     fetchData (){
        
        fetchQuery(environment, query,{
            siteId: encode("SiteNode:" + localStorage.getItem("site_id"))
        }).then((response) => {
            if (response.allCollectionLawFirmFromSetting.edges){
                this.setState({
                   dueDateDays: response.allCollectionLawFirmFromSetting.edges[0].node.dueDateDaysOnsite,
                   feeRateTh: response.allCollectionLawFirmFromSetting.edges[0].node.feeRateTh,
                   feeRateOther: response.allCollectionLawFirmFromSetting.edges[0].node.feeRateOther
                });
            }              
        });           
    }


    handleChange = (event) => {
        let e = _.cloneDeep(event)
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                e.target.name,
                e.target.value
            )
        });
        
    }


    onSubmit= async(e) => {
        e.preventDefault();
        let variables = {
            input:{
                jsonObject: {
                    documentType: this.state.documentType,
                    dueDateDays: this.state.dueDateDays,
                    language:"th",
                    feeRateTh:this.state.feeRateTh,
                    feeRateOther:this.state.feeRateOther,
                }
            }
        }

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createAndUpdateCollectionLawFirmFromSettingOnsite?.ok) {
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                            this.setState({loading: false},() =>{
                                window.location.reload()
                            });
                        });
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() =>{
                            this.setState({loading: false});
                        })
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )
    }
    

    onReset(e) {
        e.preventDefault();
        Swal.fire({
          title: i18next.t("collectionLawFirm:Do you want to reset all?"),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: i18next.t("Allaction:Yes"),
          cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
          if (result.value) {
             this.setState({
                 dueDateDays:''
             });
        }
        });
      }      

    render(){
        return (
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        {this.state.loadingData ?
                         <Loading/>
                            :
                            <React.Fragment>
                                <button type="button" className={'btn blueBtn'}>FM-CF-01</button>
                                <div className="row" id="collection-law-frim-setting">
                                    <div className="ml-2 wraperSettingInfo">
                                        <h6>{t("collectionLawFirm:Setting Debt Collection Attorney")}</h6>                            
                                        <div className="row mt-4">                                          
                                                <div className="col-12">
                                                    <label>
                                                    {t("collectionLawFirm:Number Of Days Overdue After The Debt Collection is Initiated by The Attorney")}
                                                    <div className="tooltipImg">
                                                        <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                                alt="question" className="question ml-1" style={{width:'18px'}}/>
                                                        <span className="tooltiptext">
                                                        {t("collectionLawFirm:Specify the duration of the notice of demand before filing a lawsuit to collect common fees")}<br/>
                                                        {t("collectionLawFirm:(if specified in the registered corporate by laws)")}
                                                        </span>
                                                    </div>
                                                    </label>
                                                    <div className='d-flex'>
                                                        <input className="form-controls pr-2" type="number" style={{width:'40%'}}
                                                            placeholder='จำนวนวันครบกำหนดชำระหลังจากจากออกเอกสารติดตามโดยทนายความ'
                                                            onChange={this.handleChange}
                                                            name="dueDateDays"
                                                            value={this.state.dueDateDays}                                                        
                                                        />
                                                        <span className='p-3'>{t("collectionLawFirm:days")}</span>
                                                        
                                                    </div>
                                                  
                                                </div>                                            
                                        </div>  
                                    </div>
                                    <div className="ml-2 wraperViewInfo">
                                        <div className="col-md-5 input-form">
                                            <CollectionLawFrimSettingViewForm 
                                                settingInfo = {this.state.settingInfoForm1}
                                                newUploadSignature = {this.state.imageSrc}
                                            />
                                        </div>     
                                        <div className='d-flex justify-content-end mt-4 submitBtnContainer'>
                                            <button type="button" className='greyBtn mr-2' onClick={(e)=>this.onReset(e)}>{t("collectionLawFirm:Reset All")}</button>
                                            <button type="button" className='blueBtn' onClick={(e)=>this.onSubmit(e)}>{t("PageDetailProjectInfo:Save")}</button>
                                        </div>                               
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </Translation>

        );
    }



}export default CollectionLawFrimSettingForm;