/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmailMarketingStatus = "DRAFT" | "PUBLISH" | "VOID" | "%future added value";
export type EDMListQueryVariables = {|
  emailMarketingId?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type EDMListQueryResponse = {|
  +allEmailMarketing: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: string,
        +detail: ?string,
        +previewImage: ?string,
        +status: EmailMarketingStatus,
        +scheduleTime: ?any,
        +emailMarketingImage: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileUpload: ?string,
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type EDMListQuery = {|
  variables: EDMListQueryVariables,
  response: EDMListQueryResponse,
|};
*/


/*
query EDMListQuery(
  $emailMarketingId: String
  $startDate: DateTime
  $endDate: DateTime
  $first: Int
  $last: Int
) {
  allEmailMarketing(emailMarketingId: $emailMarketingId, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        title
        detail
        previewImage
        status
        scheduleTime
        emailMarketingImage {
          edges {
            node {
              id
              fileUpload
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "emailMarketingId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "emailMarketingId",
        "variableName": "emailMarketingId"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "concreteType": "EmailMarketingNodeConnection",
    "kind": "LinkedField",
    "name": "allEmailMarketing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailMarketingNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailMarketingNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewImage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EmailMarketingImageNodeConnection",
                "kind": "LinkedField",
                "name": "emailMarketingImage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmailMarketingImageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EmailMarketingImageNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EDMListQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "EDMListQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "e5227f6d51aaf56a98fdfa9646ebed62",
    "id": null,
    "metadata": {},
    "name": "EDMListQuery",
    "operationKind": "query",
    "text": "query EDMListQuery(\n  $emailMarketingId: String\n  $startDate: DateTime\n  $endDate: DateTime\n  $first: Int\n  $last: Int\n) {\n  allEmailMarketing(emailMarketingId: $emailMarketingId, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        title\n        detail\n        previewImage\n        status\n        scheduleTime\n        emailMarketingImage {\n          edges {\n            node {\n              id\n              fileUpload\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7331ec3b80e0b8dbef3a0d61f9895186';

module.exports = node;
