/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createUpdateStaffQueryVariables = {|
  id: string
|};
export type createUpdateStaffQueryResponse = {|
  +projectStaff: ?{|
    +id: string,
    +nameTitle: ?string,
    +nameStaff: string,
    +lastNameStaff: ?string,
    +image: ?string,
    +role: string,
    +dateWork: ?any,
    +endWork: ?any,
    +phone: ?string,
    +email: ?string,
    +department: ?string,
    +document: ?string,
    +note: ?string,
    +status: boolean,
    +projectStaffDocument: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileName: string,
          +fileUpload: ?string,
        |}
      |}>
    |},
  |}
|};
export type createUpdateStaffQuery = {|
  variables: createUpdateStaffQueryVariables,
  response: createUpdateStaffQueryResponse,
|};
*/


/*
query createUpdateStaffQuery(
  $id: ID!
) {
  projectStaff(id: $id) {
    id
    nameTitle
    nameStaff
    lastNameStaff
    image
    role
    dateWork
    endWork
    phone
    email
    department
    document
    note
    status
    projectStaffDocument {
      edges {
        node {
          id
          fileName
          fileUpload
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ProjectStaffNode",
    "kind": "LinkedField",
    "name": "projectStaff",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameTitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameStaff",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastNameStaff",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "image",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateWork",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endWork",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "department",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "document",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "note",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectStaffDocumentNodeConnection",
        "kind": "LinkedField",
        "name": "projectStaffDocument",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectStaffDocumentNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectStaffDocumentNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileUpload",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUpdateStaffQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUpdateStaffQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d5f768f3a4182426c85206afa86874d8",
    "id": null,
    "metadata": {},
    "name": "createUpdateStaffQuery",
    "operationKind": "query",
    "text": "query createUpdateStaffQuery(\n  $id: ID!\n) {\n  projectStaff(id: $id) {\n    id\n    nameTitle\n    nameStaff\n    lastNameStaff\n    image\n    role\n    dateWork\n    endWork\n    phone\n    email\n    department\n    document\n    note\n    status\n    projectStaffDocument {\n      edges {\n        node {\n          id\n          fileName\n          fileUpload\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd7b6c80579e639988159ddf35ddd94d';

module.exports = node;
