import React from 'react';
import { Translation } from "react-i18next";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ExportWaterMeter from '../../accounting/waterMeter/serverExportWaterMeter';
import ServerExportWaterMeterHistory from "../../accounting/waterMeter/serverExportWaterMeterHistory";
import FineCreateModal from '../../accounting/income/fine/fineCreateModal';
import ExportWaterMeterCompare from '../../accounting/waterMeter/serverExportWaterMeterCompare';
import '../../libs/styles/styleColor.scss'
import i18next from 'i18next';

const BackButtonIcon = (props) => {
  return (
    <Translation >
      {t =>
        props?.document ?
          <div>
            <h3 className="colorUse" style={props?.style}>
              <Link to={props?.LinkBack}>
                <img
                  style={{ width: 20, marginTop: -2 }}
                  src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                  alt="back" className="back" />
              </Link>
              <span className='ml-3' style={{ fontSize: 22, fontWeight: 400 }}>{props?.LinkText}</span>
              <span className='text_CA0824 fs-14 ml-3'>{props?.SpanWordRed}</span>
              <span>{props?.status}</span>
            </h3>
          </div>

          :
          <div className="row justify-content-between">
            <div className="col">
              <h3 className="mb-4 colorUse row col-12">
                <Link to={props?.LinkBack}>
                  <img
                    style={{ width: 20, height: 20, marginTop: -2 }}
                    src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                    alt="back" className="back" />
                </Link>
                <span style={{ fontSize: 22, fontWeight: 400 }}>{props?.LinkText} 
                  {
                    props?.isToolTrip &&
                    <div className="tooltipImg">
                      <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                              alt="question" className="question ml-1 mr-1" style={{width:'12px'}}/>
                      <span className="tooltiptext" style={{height:'50px',top:'18%',left:'59%',width:'250px',marginLeft:0}}>
                             {props.toolTripText}
                      </span>
                    </div>
                  }
                </span>
                <span className='text_CA0824 fs-14 ml-3'>{props?.SpanWordRed}</span>
                <span>{props?.status}</span>
              </h3>
            </div>

            {
              props.addLinkChk &&
              <div className={`text-right searchFunction ${props.addLinkChk && props.addLinkChk2 ? '' : 'col'}`}>
                <Link to={props?.addLinkButton}>
                  <button className="btn-primary ml-3">+&nbsp; {props.addLinkText} </button>
                </Link>
              </div>
            }
            {
              props.addLinkChk2 &&
              <div className={`text-right searchFunction ${props.addLinkChk && props.addLinkChk2 ? 'mr-4' : 'col'}`}>
                <Link to={props?.addLinkButton2}>
                  <button className="btn-primary ml-3">+&nbsp; {props.addLinkText2} </button>
                </Link>
              </div>
            }

            {
              props.addOnClickChk &&
              <div className="col text-right searchFunction">
                <button className="btn-primary ml-3" onClick={props.addOnClick}>{props?.addOnClickChk ?? `+  สร้างเมนูใหม่`}</button>
              </div>
            }



            {
              props.printdocLinkChk &&
              <div className="col text-right searchFunction">
                {
                  props?.addLinkButton &&
                  <Link to={props?.addLinkButton}>
                    <button className="btn-primary ml-3 float-right">{props.iconPrintInAddLinkText && <img src={process.env.PUBLIC_URL + '/images/iconAction/E-notice/print.png'} className="w18 mr-3" />}{props.addLinkText} </button>
                  </Link>
                }
                {
                  props?.canceledBtutton &&
                  <button className={`btn ${props.canceledBtuttonClass}`} onClick={props.handleVoid}>{i18next.t("Allaction:cancel")}</button>
                }
                {
                  props.btnOutlineChk &&
                  <Link to={props?.btnOutlineLink}>
                    <button className="btn btn-primary-outline ml-3 float-right" style={{ height: 47 }}>{props.btnOutlineText} </button>
                  </Link>
                }
                {
                  props.btnprintChk &&
                  _.some(JSON.parse(localStorage.getItem("permission_list")), {
                    codename: "accounting_water_meter_print",
                  }) && <ExportWaterMeter state={props.state} />
                }
                {
                  props.btnprintChkCompre &&
                  _.some(JSON.parse(localStorage.getItem("permission_list")), {
                    codename: "accounting_water_meter_print",
                  }) && <ExportWaterMeterCompare state={props.state} />

                }
                {
                  props.btnExportChk &&
                  _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_water_meter_print' }) &&
                  <ServerExportWaterMeterHistory state={props.state} />
                }
                {
                  props.btnCustomExportIs && props?.exportFileProps
                }

              </div>
            }

            {
              props.crateModal &&
              _.some(JSON.parse(localStorage.getItem("permission_list")), {
                codename: "accounting_fine_create",
              }) && <FineCreateModal refreshQuery={props.refreshQuery} />
            }

            {
              props.addOnModalChk &&
              <div className="col text-right searchFunction">
                <button className="btn-primary ml-3" onClick={props.addOnModalClick}>+&nbsp; {props.addOnModalText} </button>
              </div>
            }

            {
              props.boxHtmlText
            }

          </div>
      }
    </Translation>
  );
}

export default BackButtonIcon;