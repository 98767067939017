import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import "../styles/receive.scss"
import _ from "lodash";
import getNameResidential from "../../libs/getNameResidential";
import "../styles/new-styles.scss"
import i18n from "i18next"
import { Translation } from 'react-i18next';
import AddressDataShow from '../../components/FucntionLib/addressDataShow';
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class GuaranteeMoneyReceived extends Component {

    constructor(props) {
        super(props);

        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];

        this.state = {
            bangkokCode: bangkokCode,
            total: 0,
            total_cash_pay: 0,
            total_credit_card_pay: 0,
            total_all_pay: 0,
            total_other_pay: 0,

            sum_amount: 0,
            sum_vat: 0,
            sum_wht_rate: 0,
            receiveDeposit: 0,
            sum_all: 0,
            sum_discount: 0,
            discount: 0,
            discount_receive: 0,
            sum_receive_deposit: 0,

            have_vat: "",
            no_vat: "",
            have_wht_rate_percent: "",
            no_wht_rate_percent: "",
            discount_status: "",
            list_map: [],
            languages: "th"
        };
        this.calSummary = this.calSummary.bind(this);
        this.calPayAmount = this.calPayAmount.bind(this);
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
    }

    componentWillMount() {
        this.calSummary();
    }

    componentDidMount() {
        i18n.changeLanguage(this.props.languages);
        this.setState({ list_map: [1, 2], languages: this.props.languages });

        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            $('tbody tr:last-child td').css({ paddingBottom: diff });
        }, 200);
    }

    calSummary() {
        let sumTotal = 0;// รวม
        this.setState({
            sum_total: sumTotal.toFixed(2),
        }, () => this.calPayAmount());
    }

    calPayAmount() {
        let total_all_pay = 0;
        let total_cash_pay = 0;
        let total_other_pay = 0;

        if (this.props.cash_transaction.length > 0) {
            this.props.cash_transaction.forEach((cash) => {
                total_cash_pay = total_cash_pay + cash.node.price;
                total_all_pay = total_all_pay + cash.node.price;
            })
        }

        if (this.props.bank_transaction.length > 0) {
            this.props.bank_transaction.forEach((bank) => {
                total_all_pay = total_all_pay + bank.node.price;
            })
        }
        if (this.props.cheque_transaction.length > 0) {
            this.props.cheque_transaction.forEach((cheque) => {
                total_all_pay = total_all_pay + cheque.node.price;
            })
        }

        this.setState({
            total_cash_pay: total_cash_pay.toFixed(2),
            total_all_pay: total_all_pay.toFixed(2),
            total_other_pay: total_other_pay.toFixed(2),
        });
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        let languages = this.state.languages
        if (type === "district" && this.isBangkok(code)) {
            return languages == "th" ? "แขวง" : "District "
        }
        else if (type === "city" && this.isBangkok(code)) {
            return languages == "th" ? "เขต" : "County "
        }
        else if (type === "province" && this.isBangkok(code)) {
            return languages == "th" ? "" : ""
        }
        else if (type === "province") {
            return languages == "th" ? "จ." : "Province "
        }
        else if (type === "district") {
            return languages == "th" ? "ต." : "District "
        }
        else if (type === "city") {
            return languages == "th" ? "อ." : "County "
        }
    }

    seleteReceived() {
        if (this.props.guarantee_type === "guarantee") {
            return 'ใบเสร็จรับเงิน'
        } else {
            let find_node = _.find(this.props.detail.guaranteeTransaction.edges, { node: { typeOfTransaction: "RETURN" } });
            if (find_node) {
                return 'ใบสำคัญจ่าย'
            } else {
                return 'ใบเสร็จรับเงิน'
            }
        }
    }

    nextLine(address) {
        return address = address.replace('แขวง', '\nแขวง')
    }

    render() {
        let amount = 0;
        if (this.props.guarantee_type === "guarantee") {
            amount = this.props.sub_detail.amount
        } else if (this.props.detail.guaranteeTransaction) {
            let find_node = _.find(this.props.detail.guaranteeTransaction.edges, { node: { typeOfTransaction: "RETURN" } });
            if (find_node) {
                amount = _.find(this.props.detail.guaranteeTransaction.edges, { node: { typeOfTransaction: "RETURN" } }).node.amount
            }
        }

        return (
            <React.Fragment>
                {
                    this.state.list_map.map((count_receive, index) => {
                        return (
                            <div className="print-page-a4" id="receive" key={index}>
                                <div className="subpage" id="style-document">
                                    <div className="head">
                                        <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                            <div style={{ minHeight: 100, minWidth: 100 }}>
                                                <img src={this.props.selfProject.logo} alt="silverman" />
                                            </div>
                                            <div>                                                
                                                <strong>{this.state.languages === "en" ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>
                                                {/* <Translation>{t=><div>{this.props.selfProject.address} {t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation> */}
                                                {
                                                    this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                                                        <div>
                                                            <Translation>{t => <div className="address-header">{this.nextLine(this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address)} </div>}</Translation>
                                                            <Translation>{t => <div className="address-header">{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                        </div>
                                                        :
                                                        <Translation>{t => <div className="address-header">{this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} {t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                                }
                                                <Translation>{t => <div>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                                            </div>
                                        </div>

                                        <div className="document-name">
                                            <Translation>
                                                {t =>
                                                    <React.Fragment>
                                                        <div className="title-head">{t("document:Receipt") !== "Receipt" && this.seleteReceived()}</div>
                                                        {this.props?.page === 'WrapperClearGuaranteeMoneyReceived' ?
                                                            <div className="title-head">Payment Voucher</div> :
                                                            <div className="title-head">Receipt</div>}

                                                    </React.Fragment>}
                                            </Translation>

                                            {
                                                count_receive === 1 ?
                                                    <Translation>{t => <div className="title small">({t("document:Original")})</div>}</Translation>
                                                    :
                                                    <Translation>{t => <div className="title small">({t("document:Copy")})</div>}</Translation>
                                            }
                                        </div>
                                    </div>

                                    <div className="detail">
                                        <div className="customer-detail">
                                            <Translation>{t => <strong>{t("document:ID")}:</strong>}</Translation> {this.props.contact.refNumber}<br />
                                            <React.Fragment>
                                                <Translation>{t => <strong>{t("document:Customer")}:</strong>}</Translation>
                                                {
                                                    this.props.editClearGuarantee?.firstName ? this.props.editClearGuarantee?.firstName + " " + this.props.editClearGuarantee?.lastName :
                                                    this.props.address?.firstName && this.props.address?.lastName ? this.props.address?.firstName + " " + this.props.address?.lastName :
                                                    this.props.detail.contact?.typeOfContact === 'RESIDENTIAL' ?  
                                                        this.props.detail.firstName && this.props.detail.firstName !== ''?
                                                            getNameResidential(this.props.detail.firstName, this.props.detail.lastName): 
                                                                this.props.detail.contact?.firstName && this.props.detail.contact?.firstName !== '' ?
                                                                    getNameResidential(this.props.detail.contact.firstName, this.props.detail.contact.lastName) : this.props.detail.contact?.name :        
                                                        this.props.sub_detail.contact?.typeOfContact === 'RESIDENTIAL' ?
                                                            this.props.sub_detail.contact?.firstName && this.props.sub_detail.contact?.firstName !== '' ?
                                                                getNameResidential(this.props.sub_detail.contact.firstName, this.props.sub_detail.contact.lastName) : this.props.sub_detail.contact?.name ? this.props.sub_detail.contact.name : 
                                                                this.props.sub_detail.contact.firstName + this.props.sub_detail.contact.lastName:  getNameResidential(this.props.detail.firstName, this.props.detail.lastName)
                                                                }
                                                <br />
                                            </React.Fragment>

                                            <Translation>{t => <strong>{t("document:Address")}:</strong>}</Translation>

                                            <div className="customer-detail address-space">
                                                {
                                                    (this.props?.editClearGuarantee?.address || this.props?.address || this.props.detail?.address || this.props?.contact?.registeredAddress) ?
                                                    <AddressDataShow
                                                        languages={this.state.languages === "en" ? true : false}
                                                        ProjectID={this.props.selfProject.id}
                                                        custom={this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ? true : false}
                                                        from={true}
                                                        address={
                                                            this.props?.editClearGuarantee?.address || this.props?.address?.address ?
                                                                {
                                                                    address: this.props.editClearGuarantee?.address ? this.props.editClearGuarantee?.address : this.props.address.address,
                                                                    district: this.props.editClearGuarantee?.district ? this.props.editClearGuarantee?.district : this.props.address.district,
                                                                    city: this.props.editClearGuarantee?.city ? this.props.editClearGuarantee?.city : this.props.address.city,
                                                                    province: this.props.editClearGuarantee?.province ? this.props.editClearGuarantee?.province : this.props.address.province,
                                                                    postalCode: this.props.editClearGuarantee?.postalCode ? this.props.editClearGuarantee?.postalCode : this.props.address.postalCode,
                                                                } : this.props.detail?.address ?
                                                                {
                                                                    address: this.props.detail.address,
                                                                    district: this.props.detail.district,
                                                                    city: this.props.detail.city,
                                                                    province: this.props.detail.province,
                                                                    postalCode: this.props.detail.postalCode,
                                                                    registeredCountry: this.props.detail.country,
                                                                }:
                                                                {
                                                                    address: this.props.contact.registeredAddress,
                                                                    district: this.props.contact.registeredDistrict,
                                                                    city: this.props.contact.registeredCity,
                                                                    province: this.props.contact.registeredProvince,
                                                                    postalCode: this.props.contact.registeredPostalCode,
                                                                    registeredCountry: this.props.contact.registeredCountry,
                                                                }
                                                        }
                                                    />
                                                    :
                                                    <></>
                                                }


                                                {/* {
                                                    this.state.languages === "en" ? this.props.contact.registeredAddress.replace('เลขที่', 'No.').replace('ซอย', 'Soi').replace('หมู่ที่', 'Moo').replace('ถนน', 'Road') + " " :
                                                        this.props.editClearGuarantee?.address ? this.props.editClearGuarantee?.address + " " : this.props.contact.registeredAddress + " "
                                                }
                                                //{this.props.contact.registeredDistrict && this.getPrefix("district", this.props.contact.registeredPostalCode) + this.props.contact.registeredDistrict + " "}
                                                {this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                                                    <React.Fragment>
                                                        {this.props.contact.registeredDistrict && this.getPrefix("district", this.props.contact.registeredPostalCode) + this.props.contact.registeredDistrict + " "}<br />
                                                    </React.Fragment> :
                                                    <React.Fragment>
                                                        {this.props.editClearGuarantee?.district ? this.props.editClearGuarantee?.district + " " : this.props.contact.registeredDistrict && this.getPrefix("district", this.props.contact.registeredPostalCode) + this.props.contact.registeredDistrict + " "}
                                                    </React.Fragment>
                                                }
                                                {this.props.editClearGuarantee?.city ? this.props.editClearGuarantee?.city + " " : this.props.contact.registeredCity && this.getPrefix("city", this.props.contact.registeredPostalCode) + this.props.contact.registeredCity + " "}
                                                {this.props.editClearGuarantee?.province ? this.props.editClearGuarantee?.province + " " : this.props.contact.registeredProvince && this.getPrefix("province", this.props.contact.registeredPostalCode) + this.props.contact.registeredProvince + " "}
                                                {this.props.editClearGuarantee?.postalCode ? this.props.editClearGuarantee?.postalCode + " " : this.props.contact.registeredPostalCode + " "} */}

                                                {
                                                    (this.props.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.contact.typeOfSupplier === "COMPANY") &&
                                                    <Translation>
                                                        {t =>
                                                            <React.Fragment>
                                                                {
                                                                    this.props.contact.typeOfCompany === "HEADQUARTERS" ?
                                                                        t("document:Head office")
                                                                        : this.props.contact.typeOfCompany === "BRANCH" &&
                                                                        `${t("document:Branch")} ` + this.props.contact.nameBranch
                                                                }
                                                            </React.Fragment>}
                                                    </Translation>
                                                }
                                            </div>
                                            <br />
                                            <Translation>{t => <strong>{t("document:Tax ID")}:</strong>}</Translation>
                                            {this.props.editClearGuarantee?.taxIdNumber ? this.props.editClearGuarantee?.taxIdNumber : this.props.detail.taxIdNumber ? this.props.detail.taxIdNumber: this.props.contact.taxIdNumber ? this.props.contact.taxIdNumber : ''}

                                            <br />
                                        </div>
                                        <div className="document-detail">
                                            <Translation>{t => <strong className="sub-document-detail">{t("document:No.")}:</strong>}</Translation> {this.props.detail.docNumber} <br />
                                            <Translation>{t => <strong className="sub-document-detail">{t("document:Date")}:</strong>}</Translation> {format(this.props.detail.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                                            {this.props.contact.typeOfContact === "RESIDENTIAL" &&
                                                <React.Fragment>
                                                    <Translation>{t => <strong className="sub-document-detail">{this.props.selfProject.typeOfProject === "CONDOMINIUM" ? `${t("document:Room No.")}:` : `${t("document:House No.")}:`}</strong>}</Translation> {this.props.contact.name} &nbsp;
                                                    <br />
                                                    <Translation>{t => <><strong className="sub-document-detail">{t("document:Ratio/Space")}:</strong> {numberWithComma(this.props.contact.residential.ownershipRatio) + "/" + numberWithComma(this.props.contact.residential.size)} </>}</Translation>
                                                    <br />
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>

                                    <div className="invoice-body">
                                        <table className="table table-bordered">
                                            <thead>
                                                <Translation>
                                                    {t =>
                                                        <tr>
                                                            <th
                                                                width={50}
                                                                className="text-center"
                                                                dangerouslySetInnerHTML={{ __html: t("document:No._br") }} />
                                                            <th
                                                                className="text-center"
                                                                dangerouslySetInnerHTML={{ __html: t("document:Description_br") }} />

                                                            <th
                                                                width={100}
                                                                className="text-center"
                                                                dangerouslySetInnerHTML={{ __html: t("document:Amount_br") }} />
                                                        </tr>}
                                                </Translation>
                                            </thead>
                                            <tbody>
                                                <tr key={this.props.sub_detail.id}>
                                                    <td className="text-center">1</td>
                                                    <td>{this.props.sub_detail.description}</td>
                                                    <td className="text-right">{numberWithComma(amount)}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <Translation>{t => <strong>{t("document:Total")}</strong>}</Translation>
                                                            </div>
                                                            <div className="col-10 text-center">
                                                            {i18n.languages[0] === 'th' ?
                                                                <strong>({ThaiBaht(amount)})</strong>:
                                                                <strong>({ConvertNumberToTextEng(amount)})</strong>
                                                            }
                                                            </div>
                                                            <div className="col-1" />
                                                        </div>
                                                    </td>

                                                    <td className="text-right">
                                                        <strong>{numberWithComma(amount, 0)}</strong>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div className="remark">
                                            <Translation>{t => <strong>{t("document:Remarks")}:</strong>}</Translation> <br />
                                        </div>
                                        <div className="remark">
                                            {this.props.remark} <br />

                                        </div>
                                        <div className="remark">
                                            <Translation>{t => <strong>{t("document:Conditions of Payments")}</strong>}</Translation><br />
                                            {this.props.cash_transaction.length > 0 &&
                                                <div className="row col ml-1" key="cash">
                                                    <Translation>{t => <span className="align-middle">{t("document:Cash")} </span>}</Translation>
                                                    <div className="col">
                                                        <Translation>{t => <span>{t("document:Amount_")} {numberWithComma(this.state.total_cash_pay)} {t("document:Baht")} </span>}</Translation>
                                                    </div>
                                                </div>
                                            }
                                            {this.props.bank_transaction.length > 0 &&
                                                <div className="col ml-1" key="bank">
                                                    <Translation>{t => <span className="align-middle">{t("document:Bank Transfer")} </span>}</Translation><br />
                                                    <div className="col ml-2">
                                                        {this.props.bank_transaction.map((bank, index_bank) => {
                                                            return <div className="row" key={bank.node.bankAccount.accountNumber + index_bank}><Translation>{t => <span key={bank.node.bankAccount.accountNumber}> - {bank.node.bankAccount.bankName}&emsp;{`${t("document:Account No.")} ` + bank.node.bankAccount.accountNumber}&emsp;{`${t("document:Date_")} ` + format(bank.node.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;{`${t("document:Amount_")} ` + numberWithComma(bank.node.price)} {t("document:Baht")}<br /></span>}</Translation></div>
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                            {this.props.cheque_transaction.length > 0 &&
                                                <div className="col ml-1" key="cheque">
                                                    <Translation>{t => <span className="align-middle"> {t("document:Cheque Bank")} </span>}</Translation><br />
                                                    <div className="col ml-2">
                                                        {this.props.cheque_transaction.map((cheque, index_cheque) => {
                                                            if (index_cheque > 0){
                                                                if (this.props.cheque_transaction[index_cheque-1].node.chequeNumber != cheque.node.chequeNumber) {
                                                                    return <div className="row" key={cheque.node.chequeNumber + index_cheque}><Translation>{t => <span key={cheque.node.chequeNumber}>- {t("document:Cheque No.")} {cheque.node.chequeNumber}&emsp;{this.props.guarantee_type === "clear_guarantee" ? cheque.node.bankAccount.bankName : cheque.node.bankName}&emsp;{t("document:Branch")} {this.props.guarantee_type === "clear_guarantee" ? cheque.node.bankAccount.branch : cheque.node.branchNumber}&emsp;{t("document:Date_")} {format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;{t("document:Amount_baht")}&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {t("document:Baht")} <br /></span>}</Translation></div>
                                                                }
                                                            } else{
                                                                    return <div className="row" key={cheque.node.chequeNumber + index_cheque}><Translation>{t => <span key={cheque.node.chequeNumber}>- {t("document:Cheque No.")} {cheque.node.chequeNumber}&emsp;{this.props.guarantee_type === "clear_guarantee" ? cheque.node.bankAccount.bankName : cheque.node.bankName}&emsp;{t("document:Branch")} {this.props.guarantee_type === "clear_guarantee" ? cheque.node.bankAccount.branch : cheque.node.branchNumber}&emsp;{t("document:Date_")} {format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;{t("document:Amount_baht")}&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {t("document:Baht")} <br /></span>}</Translation></div>
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                            {this.props.clear_guarantee_money_received_petty_cash_channel.length > 0 &&
                                                <div className="col ml-1" key="bank">
                                                    <Translation>{t => <span className="align-middle">{t("document:PettyCash")} </span>}</Translation><br />
                                                    <div className="col ml-2">
                                                        {this.props.clear_guarantee_money_received_petty_cash_channel.map((petty_cash, index_petty_cash) => {
                                                            return <div className="row" key={petty_cash.node.id + index_petty_cash}><Translation>{t => <span key={petty_cash.node.setPettyCash.docNumber}> - {t("document:PettyCash")}&emsp;{`${t("document:No.")} ` + petty_cash.node.setPettyCash.docNumber}&emsp;{`${t("document:Date_")} ` + format(petty_cash.node.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;{`${t("document:Amount_")} ` + numberWithComma(petty_cash.node.price)} {t("document:Baht")}<br /></span>}</Translation></div>
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.sum_wht_rate > 0 &&
                                                <div className="row col ml-1" key="With holding tax">
                                                    <Translation>{t => <span className="align-middle">{t("document:With holding tax")}</span>}</Translation>
                                                    <div className="col">
                                                        <Translation>{t => <span>{t("document:Amount_")} {numberWithComma(this.state.sum_wht_rate)} {t("document:Baht")} </span>}</Translation>
                                                    </div>
                                                </div>
                                            }

                                            {(parseFloat(this.state.total_other_pay) !== 0.0 || parseFloat(this.state.discount_receive) !== 0.0) &&
                                                <div className="row col ml-1" key="other">
                                                    <Translation>{t => <span className="align-middle">{t("document:Other")}</span>}</Translation>
                                                    <div className="col">
                                                        <Translation>{t => <span>{t("document:Amount_")} {numberWithComma(parseFloat(this.state.total_other_pay) + parseFloat(this.state.discount_receive))} {t("document:Baht")} </span>}</Translation>
                                                    </div>
                                                </div>

                                            }

                                        </div>

                                    </div>
                                    <div className="footer">
                                        <div className="signature-left">
                                            <div className="left">
                                                ...................................................... <br />
                                                <Translation>{t => <strong>{t("document:Approver")}</strong>}</Translation>
                                            </div>
                                            <div className="left">
                                                ...................................................... <br />
                                                <Translation>{t => <strong>{t("document:Organizer")}</strong>}</Translation>
                                            </div>
                                        </div>
                                        <div className="signature-right">
                                        <div className="left">
                                            ...................................................... <br />
                                            <Translation>{t => <strong>{t("document:Receiver")}</strong>}</Translation>
                                        </div>
                                        <div className="right">
                                            ...................................................... <br />
                                            <Translation>{t => <strong>{t("document:Authorized")}</strong>}</Translation>
                                        </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        )
                    })
                }

            </React.Fragment>
        )
            ;
    }
}

export default GuaranteeMoneyReceived;
