import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";

const query = graphql`
  query otherReceiveQueryQuery($search: String) {
    allOtherReceive(search: $search) {
      totalCount
      edges{
        node {
            id
        }
      }
    }
  }
`;


const otherReceiveQuery = (docNumber) => {
    return new Promise((resolve, reject) => {
        fetchQuery(environment, query, {search : docNumber}).then(data => {
            resolve(data)
        });
    });
  
  };
  
  export default otherReceiveQuery;