import React from 'react';
import { format } from 'date-fns';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import numberWithCommas from "../../libs/numberWithComma";
import Loading from "../../libs/loading";
import Link from "react-router-dom/es/Link";
import { fetchQuery } from "relay-runtime";
import _ from "lodash";
import { formatDateISO } from "../../utils";
import i18next from 'i18next';

const query = graphql`
    query generalLedgerTableQuery($startDate: DateTime, $endDate: DateTime,$betweenStartId: String, $betweenEndId: String,$chart:String) {
        #ใช้แค่ครั้งเดียว
        historicalRecord: allAccountRecord(typeOfRecord: "historical", startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId,orderLedger:true,chart :$chart) {
            edges {
                node {
                    id name debit credit
                    chartOfAccountCode {
                        id name chartOfAccountCode
                    }
                }
            }
        }
        currentRecord: allAccountRecord(startDate: $startDate, endDate: $endDate, typeOfRecord: "current", betweenStartId: $betweenStartId, betweenEndId: $betweenEndId,orderLedger:true,chart :$chart) {
            edges {
                node {
                    id name debit credit added
                    group {
                        id name issuedDate refNumber totalDebit totalCredit refTransaction added category
                    }
                    chartOfAccountCode {
                        id name chartOfAccountCode
                    }
                }
            }
        }
        #ใช้แค่ครั้งเดียว
        summaryPreviousAccountRecord (startDate: $startDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId,chart :$chart) {
            sumJson
        }
        #ใช้แค่ครั้งเดียว
        betweenChartOfAccount(betweenStartId: $betweenStartId, betweenEndId: $betweenEndId,chart :$chart) {
            edges{
                node{
                    id
                    chartOfAccountCode
                    name
                }
            }
        }
        #ใช้แค่ครั้งเดียว
        allAccountProjectManager{
            edges{
              node{
                id
                tableAccount
              }
            }
        }
    }
`;


class GeneralLedgerTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                historicalRecord: { edges: [] },
                currentRecord: { edges: [] },
            },
            new_currentRecord: {},
            currentRecord: [],
            currentRecord_test: [],
            allAccountProjectManager: {},
            tableAccountisNull: false,
            loading: true,
        };

        this.calDateCheck = this.calDateCheck.bind(this);
        this.calHistorical = this.calHistorical.bind(this);
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.state.start_date !== this.props.state.start_date) || (prevProps.state.end_date !== this.props.state.end_date) || (prevProps.state.chart !== this.props.state.chart)) {
            this.getData()
        }

    }

    handleInputAwait(e) {
        this.setState({ currentRecord: [...this.state.currentRecord, ...e.currentRecord.edges] })
    }

    getData() {
        this.setState({ loading: true })
        // const res = await API.libbetweenChartOfAccountApi(bodyFormData);
        fetchQuery(environment, query, {
            startDate: this.props.state.start_date,
            endDate: this.props.state.end_date,
            chart: this.props.state.chart
        })
            .then(async response_data => {
                if (response_data) {
                    let data_object = _.cloneDeep(response_data);
                    let tableAccount = new Date(data_object.allAccountProjectManager.edges[0]?.node?.tableAccount)
                    let summaryPreviousAccountRecord = JSON.parse(data_object.summaryPreviousAccountRecord.sumJson)
                    summaryPreviousAccountRecord.map((reslut, index) => {
                        let debit = parseFloat(reslut.debit)
                        let credit = parseFloat(reslut.credit)
                        let data_allAccountProjectManager = [...data_object.allAccountProjectManager.edges]

                        let start_date = new Date(this.props.state.start_date)
                        start_date.setDate(start_date.getDate() - 1)
                        start_date = format(start_date, "DD/MM/YYYY")

                        let name_text = 'ยอดยกมา คงเหลือ ณ ' + start_date
                        return (
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.added", '0'),
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.id", Math.random().toString(36).substring(4)),
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.chartOfAccountCode.chartOfAccountCode", reslut.chart),
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.FindchartOfAccountCode", reslut.chart),
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.name", name_text),
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.debit", debit),
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.credit", credit),
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.group.issuedDate", "-"),
                            _.set(data_object, "summaryPreviousRecord.edges[" + index + "].node.previou", true) //กรณีเป็นตัวยกมาก่อนหน้าจะเป็น true ถ้ายกมาขึ้นระบบจะเป็น false
                        )
                    })

                    // กรณี ยอดยกมาขึ้นระบบ โดยฟิลเตอร์ด้วย วันขึ้นยอดยกมาน้อยกว่า วันที่เริ่มต้นหา จะนำมาพวกเพิ่มเข้าไปใน summaryPreviousRecord
                    if (formatDateISO(tableAccount) < formatDateISO(this.props.state.start_date)) {
                        // if(this.calDateCheck(data_object.allAccountProjectManager.edges[0]) === false){
                        data_object.historicalRecord.edges.map((historicalRecord, index) => {
                            let find_summaryPreviousRecord = _.find(data_object.summaryPreviousRecord.edges, { node: { FindchartOfAccountCode: historicalRecord.node.chartOfAccountCode.chartOfAccountCode } });
                            let credit_ = parseFloat(_.get(find_summaryPreviousRecord, "node.credit") + historicalRecord.node.credit);
                            let debit_ = parseFloat(_.get(find_summaryPreviousRecord, "node.debit") + historicalRecord.node.debit);
                            return (
                                _.set(find_summaryPreviousRecord, 'node.credit', credit_),
                                _.set(find_summaryPreviousRecord, 'node.debit', debit_)
                            )
                        })
                    } //กรณี ยอดยกมาขึ้นระบบ อยู่ในระหว่างวันที่ค้นหา เริ่มต้น และ สิ้นสุด จะทำ summaryPreviousRecord อีก 1 ตัว
                    else if (this.calDateCheck(data_object.allAccountProjectManager.edges[0])) {
                        data_object.historicalRecord.edges.map((historicalRecord, index) => {
                            let credit_ = parseFloat(historicalRecord.node.credit);
                            let debit_ = parseFloat(historicalRecord.node.debit);
                            let indexs = data_object.summaryPreviousRecord.edges.length
                            return (
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.added", '0'),
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.id", Math.random().toString(36).substring(4)),
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.chartOfAccountCode.chartOfAccountCode", historicalRecord.node.chartOfAccountCode.chartOfAccountCode),
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.FindchartOfAccountCode", historicalRecord.node.chartOfAccountCode.chartOfAccountCode),
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.name", 'ยอดยกมา ขึ้นระบบ'),
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.debit", debit_),
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.credit", credit_),
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.group.issuedDate", this.calDateCheck(data_object.allAccountProjectManager.edges[0]) ? format(data_object.allAccountProjectManager.edges[0]?.node?.tableAccount, "YYYY-MM-DD") : "-"),
                                _.set(data_object, "summaryPreviousRecord.edges[" + indexs + "].node.previou", false)
                            )
                        })
                    }

                    let data_object_test = data_object
                    data_object_test = [...data_object.summaryPreviousRecord.edges, ...data_object.currentRecord.edges];
                    data_object_test.sort((a, b) => a.node.group?.issuedDate == b.node.group?.issuedDate && a.node.group?.refTransaction ? 1 : a.node.group?.issuedDate > b.node.group?.issuedDate ? 1 : -1)
                    //Export exlcel
                    this.props.setDataForExport(data_object);

                    this.setState({
                        data: data_object,
                        currentRecord: data_object_test,
                        loading: false
                    });

                    //sort data จากวันที่และเวลาเอกสาร
                    this.sortData()
                }
            })

    }


    sortData() {
        let group_date = {};
        let new_currentRecord = []
        this.state.data.currentRecord.edges.map((record) => {

            if (Object.keys(group_date).includes(record.node.group.issuedDate)) {
                group_date[record.node.group.issuedDate].push(record)
            } else {
                group_date[record.node.group.issuedDate] = [record]
            }
            group_date[record.node.group.issuedDate] = _.sortBy(group_date[record.node.group.issuedDate], sort => sort.node.added)
        })
        Object.entries(group_date).map((value) => {
            new_currentRecord.push(...value[1])
        });
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                currentRecord: { edges: new_currentRecord }
            }
        }))
    }

    highLightMinus(balance) {

        if (balance < 0) {
            balance = "(" + numberWithCommas(Math.abs(balance).toFixed(2)) + ")"
        } else {
            balance = numberWithCommas(balance)
        }
        return balance
    }

    calGroupOneAndFive(accumulate, chart) {
        return parseFloat(accumulate.toFixed(2)) + chart.node.debit - chart.node.credit
    }

    calOtherGroup(accumulate, chart) {
        return parseFloat(accumulate.toFixed(2)) + chart.node.credit - chart.node.debit
    }

    validateEmptyChartOfAccount(chart_of_account) {
        if (this.state.data.currentRecord.edges.find(x => x.node.chartOfAccountCode.chartOfAccountCode === chart_of_account.node.chartOfAccountCode) || this.state.data.historicalRecord.edges.find(x => x.node.chartOfAccountCode.chartOfAccountCode === chart_of_account.node.chartOfAccountCode)
        ) {
            return true;
        }
        return false;
    }

    calDateCheck(data) {

        let start_date = format(new Date(this.props.state.start_date), "YYYY-MM-DD")

        let end_date = format(new Date(this.props.state.end_date), "YYYY-MM-DD");

        let tableAccount = null;
        let tableAccountisNull = null;

        if (this.state.data?.allAccountProjectManager?.edges[0]?.node?.tableAccount) {
            tableAccount = format(new Date(this.state.data?.allAccountProjectManager?.edges[0]?.node?.tableAccount), "YYYY-MM-DD")
            tableAccountisNull = this.state.data?.allAccountProjectManager?.edges[0]?.node?.tableAccount ? true : false;
        }
        else {
            tableAccount = format(new Date(data?.node?.tableAccount), "YYYY-MM-DD")
            tableAccountisNull = data?.node?.tableAccount ? true : false;
        }

        if (start_date <= tableAccount && tableAccountisNull != false && end_date >= tableAccount) {
            return true;
        }

        return false;
    }


    calHistorical(chartOfAccountCode, Type) {
        let sum_debit = 0;
        let sum_credit = 0;

        this.state.data.historicalRecord.edges.map((historicalRecord, index) => {
            if (historicalRecord.node.chartOfAccountCode.chartOfAccountCode === chartOfAccountCode && Type === "debit") {
                sum_debit = historicalRecord.node.debit;
            } else if (historicalRecord.node.chartOfAccountCode.chartOfAccountCode === chartOfAccountCode && Type === "credit") {
                sum_credit = historicalRecord.node.credit;
            }
        })
        return (sum_debit > 0 ? parseFloat(sum_debit) : parseFloat(sum_credit))
    }

    render() {
        return (
            <React.Fragment>

                {
                    this.state.loading ? <Loading />
                        :

                        this.state.data.betweenChartOfAccount && this.state.data.betweenChartOfAccount.edges.map((chart_of_account) => {
                            let totalDebit = 0;
                            let totalCredit = 0;
                            let totalAmount = 0;

                            if (this.validateEmptyChartOfAccount(chart_of_account)) {

                                return (
                                    <React.Fragment key={chart_of_account.node.id}>
                                        <h5 className="mt-4">{chart_of_account.node.chartOfAccountCode + ' ' + chart_of_account.node.name}</h5>
                                        <div className="card table-responsive">


                                            <table className="table table-bordered">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th width={80}>{i18next.t("generalLedgerList:Date")}</th>
                                                        <th width={140}>{i18next.t("generalLedgerList:Document Number")}</th>
                                                        <th>{i18next.t("generalLedgerList:Description")}</th>
                                                        <th width={100} className="text-right">{i18next.t("generalLedgerList:Debit")}</th>
                                                        <th width={100} className="text-right">{i18next.t("generalLedgerList:Credit")}</th>
                                                        <th width={100} className="text-right">{i18next.t("generalLedgerList:Balance")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>



                                                    {/* //จากกดค้นไม่ใช่จากกด tab ข้างๆ */}
                                                    {Object.keys(this.state.currentRecord).length !== 0 && this.state.currentRecord.map((chart, index) => { // this.state.currentRecord.sort((a, b) => a.node.group.issuedDate > b.node.group.issuedDate ? 1 : -1 )  sort จากวันที่ added
                                                        let previou = false
                                                        if (chart.node?.previou) previou = true
                                                        if (!(chart.node.debit === 0 && chart.node.credit === 0) && previou != true && chart.node.chartOfAccountCode.chartOfAccountCode === chart_of_account.node.chartOfAccountCode) {
                                                            if (index == 0) {
                                                                let calDebit = 0;
                                                                let calCredit = 0;
                                                                let calHistoricalCredit = 0;
                                                                let calHistoricalDebit = 0;

                                                                calHistoricalDebit = this.calHistorical(chart_of_account.node.chartOfAccountCode, "debit")
                                                                calHistoricalCredit = this.calHistorical(chart_of_account.node.chartOfAccountCode, "credit")

                                                                calDebit = calHistoricalDebit === '-' ? 0 : parseFloat(calHistoricalDebit)
                                                                calCredit = calHistoricalCredit === '-' ? 0 : parseFloat(calHistoricalCredit)

                                                                if (this.calDateCheck()) {
                                                                    totalDebit += calDebit
                                                                    totalCredit += calCredit
                                                                }
                                                            }

                                                            totalDebit += chart.node.debit;
                                                            totalCredit += chart.node.credit;


                                                            return (
                                                                <tr key={chart.node.id}>
                                                                    <td>
                                                                        {format(chart.node.group.issuedDate, "DD/MM/YYYY")}
                                                                    </td>
                                                                    <td>
                                                                        {chart.node?.name === 'ยอดยกมา ขึ้นระบบ' ? "-" :
                                                                            <Link
                                                                                to={`/accounting/daily-journal/${chart.node.group.category?.toLowerCase()}/detail/${chart.node.group.refNumber}/${(chart.node.group?.refTransaction || chart.node.group?.refNumber)}`}
                                                                            >{chart.node.group?.refNumber || chart.node.group?.refTransaction}
                                                                            </Link>
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        {chart.node?.group?.name === chart.node?.name ? chart.node?.group?.name : chart.node?.name === 'ยอดยกมา ขึ้นระบบ' ? chart.node?.name : chart.node?.group?.name + " " + chart.node?.name}
                                                                    </td>
                                                                    <td className="text-right">{numberWithCommas(chart.node.debit)}</td>
                                                                    <td className="text-right">{numberWithCommas(chart.node.credit)}</td>
                                                                    <td className="text-right">
                                                                        {["1", "5"].includes(chart.node.chartOfAccountCode.chartOfAccountCode.substring(0, 1)) &&
                                                                            <span
                                                                                className={this.calGroupOneAndFive(totalAmount, chart) < 0 ? "text-danger" : undefined}>{this.highLightMinus(totalAmount = this.calGroupOneAndFive(totalAmount, chart))}</span>
                                                                        }
                                                                        {["2", "3", "4"].includes(chart.node.chartOfAccountCode.chartOfAccountCode.substring(0, 1)) &&
                                                                            <span
                                                                                className={this.calOtherGroup(totalAmount, chart) < 0 ? "text-danger" : undefined}>{this.highLightMinus(totalAmount = this.calOtherGroup(totalAmount, chart))}</span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                        else if (previou == true && chart.node.chartOfAccountCode.chartOfAccountCode === chart_of_account.node.chartOfAccountCode) {

                                                            return (

                                                                <tr key={chart.node.id}>
                                                                    <td>{"-"}</td>
                                                                    <td>-</td>
                                                                    <td>{chart.node.name}</td>
                                                                    <td className="text-right">{"-"}</td>
                                                                    <td className="text-right">{"-"}</td>
                                                                    <td className="text-right">
                                                                        {["1", "5"].includes(chart.node.chartOfAccountCode.chartOfAccountCode.substring(0, 1)) &&
                                                                            <span className={this.calGroupOneAndFive(totalAmount, chart) < 0 ? "text-danger" : undefined}>{this.highLightMinus(totalAmount = this.calGroupOneAndFive(totalAmount, chart))}</span>
                                                                        }
                                                                        {["2", "3", "4"].includes(chart.node.chartOfAccountCode.chartOfAccountCode.substring(0, 1)) &&
                                                                            <span className={this.calOtherGroup(totalAmount, chart) < 0 ? "text-danger" : undefined}>{this.highLightMinus(totalAmount = this.calOtherGroup(totalAmount, chart))}</span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )

                                                        }
                                                        else {
                                                            return;
                                                        }



                                                    })}


                                                    <tr>
                                                        <td className="font-weight-bold" colSpan={3}>{i18next.t("generalLedgerList:Total")}</td>
                                                        <td className="font-weight-bold text-right">{numberWithCommas(totalDebit)}</td>
                                                        <td className="font-weight-bold text-right">{numberWithCommas(totalCredit)}</td>
                                                        <td className={totalAmount >= 0 ? "font-weight-bold text-right" : "font-weight-bold text-right text-danger"}>{this.highLightMinus((totalAmount))}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </React.Fragment>
                                )
                            }
                            return (
                                //กรณี ที่ไม่มีรายการ และไม่มียอดยกมา ให้แสดงส่วนนี้แทน
                                <React.Fragment key={chart_of_account.node.id}>
                                    <h5 className="mt-4">{chart_of_account.node.chartOfAccountCode + ' ' + chart_of_account.node.name}</h5>
                                    <div className="card table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th width={80}>{i18next.t("generalLedgerList:Date")}</th>
                                                    <th width={140}>{i18next.t("generalLedgerList:Document Number")}</th>
                                                    <th>{i18next.t("generalLedgerList:Description")}</th>
                                                    <th width={100} className="text-right">{i18next.t("generalLedgerList:Debit")}</th>
                                                    <th width={100} className="text-right">{i18next.t("generalLedgerList:Credit")}</th>
                                                    <th width={100} className="text-right">{i18next.t("generalLedgerList:Balance")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr key={chart_of_account.node.id}>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td className="text-right">-</td>
                                                    <td className="text-right">-</td>
                                                    <td className="text-right">-</td>
                                                </tr>

                                                <tr>
                                                    <td className="font-weight-bold" colSpan={3}>{i18next.t("generalLedgerList:Total")}</td>
                                                    <td className="font-weight-bold text-right">-</td>
                                                    <td className="font-weight-bold text-right">-</td>
                                                    <td className={totalAmount >= 0 ? "font-weight-bold text-right" : "font-weight-bold text-right text-danger"}>{this.highLightMinus((totalAmount))}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            )
                        }
                        )}
            </React.Fragment>
        )
    }
}

export default GeneralLedgerTable;