// import '../../report.scss';
import React from 'react';
import _ from "lodash";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const ExportsvmGuardManageReportExcel = ({ state, namePrint, dataExport }) => {
    const getData = () => downloadExcel()
    const downloadExcel = async (data) => {
        let workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet('total receipt');
        let columns = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 10 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]
        // setBorder
        let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        // let fontSizes = { size: 11.5 }
        let textCenter = { vertical: 'middle', horizontal: 'center' };
        let textRight = { vertical: 'middle', horizontal: 'right' };
        let textLeft = { vertical: 'middle', horizontal: 'left' };
        let fontBold = { size: 11.5, bold: true };
        let fontLight = { size: 11.5, bold: false };

        //textHeader 
        worksheet.getCell('A1').value = state.project_name
        worksheet.getCell('A1').font = { size: 11.5, bold: true };
        worksheet.getCell('A2').value = "รายงานผู้มาติดต่อ"
        worksheet.getCell('A2').font = { size: 11.5, bold: true };

        // //table
        let headerRow, cell, last_index = 0
        let month = state.list_month
        // //header

        // HEADER  Table
        headerRow = worksheet.getRow(4);

        const headerName = ['รหัสผู้ติดต่อ', 'วันที่-เวลาเข้าใช้', 'วันที่-เวลารถออก', 'ชื่อผู้มาติดต่อ', 'เลขประจำตัวประชาชน', 'เลขทะเบียน', 'จังหวัด', 'ประเภทรถ', 'ยี่ห้อรถ', 'สี', 'เลขที่สติกเกอร์', 'ประเภทบุคคล', 'วัตถุประสงค์', 'การคิดค่าที่จอด', 'ประเภทสิทธิ์', 'เครื่องมือ', 'ออกบัตรโดย', 'สถานะ', 'เวลาสิทธิ์เข้าจอด', 'วันที่สิ้นสุดอนุญาตใช้บัตรจอดรถ', 'เวลาสิ้นสุดสิทธิ์จอดรถ']
        _.map(headerName, (keyName, index) => {
            cell = headerRow.getCell(index + 1);
            cell.value = keyName;
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textCenter
        })

        const BobyName = ['codeVisit', 'dateUsingSt', 'dateCheckOut', 'fullnameVisit', 'idCard', 'carRegistration', 'province', 'typeTrackandCar', 'brandCar', 'colorCar', 'codeSticker', 'typePerson', 'reason', 'calPacking', 'typeRole', 'tool', 'ceateBy', 'status', 'timeUsingSt', 'dateUsingEn', 'timeUsingEn']

        // BODY
        _.map(dataExport, (item) => {
            last_index++

            var dataRow = worksheet.addRow();
            _.forEach(BobyName, (property, columnIndex) => {

                cell = dataRow.getCell(columnIndex + 1);
                if (property === "typePerson") {
                    item.typePerson === "tenant" ? cell.value = "ผู้เช่า/ลูกบ้าน" :
                        item.typePerson === "contact" ? cell.value = "ติดต่อสำนักงานขาย" :
                            item.typePerson === "appointment" ? cell.value = "ผู้ติดต่อนัดหมายล่วงหน้า(ผู้เยี่ยมเยือน)" : cell.value = "บุคคลทั่วไป"
                } else if (property === "calPacking") {
                    item.calPacking ? cell.value = "คิดค่าที่จอดรถ" : cell.value = "ไม่คิดค่าที่จอดรถ"
                } else if (property === "typeRole") {
                    item.typeRole === "temp" ? cell.value = "ครั้งเดียว" : cell.value = "หลายครั้ง"
                } else if (property === "tool") {
                    item.tool === "LPR" ? cell.value = "LPR" : cell.value = "QR Code"
                } else if (property === "status") {
                    item.status === "coming" ? cell.value = "กำลังจะเข้า" :
                        item.status === "park" ? cell.value = "อยู่ในที่จอด" :
                            item.status === "cancel" ? cell.value = "ยกเลิก" :
                                item.status === "success" ? cell.value = "ออกจากโครงการ" : cell.value = "อยู่ในที่จอด (จอดเกินเวลา)"
                } else if (property === "typeTrackandCar") {
                    switch (item.typeTrackandCar) {
                        case "sedan": cell.value = "รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน";
                            break;
                        case "pickup": cell.value = "รถยนต์บรรทุกส่วนบุคคล";
                            break;
                        case "van": cell.value = "รถยนต์นั่งส่วนบุคคลเกิน 7 คน";
                            break;
                        case "taxi": cell.value = "รถแท็กซี่";
                            break;
                        case "motorcy": cell.value = "รถจักรยานยนต์ไม่เกิน 150 cc";
                            break;
                        case "motorcycle": cell.value = "รถจักรยานยนต์ 150 cc ขึ้นไป";
                            break;
                        default: cell.value = "รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน";
                            break;
                    }
                } else if (property === "dateUsingSt") {
                    cell.value = item["dateUsingSt"] + " - " + item["timeUsingSt"]
                } else if (property === "dateCheckOut") {
                    cell.value = item["dateCheckOut"] + " - " + item["timeCheckOut"]
                } else {
                    cell.value = item[property] ? item[property] : "-"
                }
                cell.border = borders;
                cell.font = fontLight;
                cell.alignment = textCenter;




            });
        })

        worksheet.getCell('A' + (dataExport.length + 6)).value = `ผู้พิมพ์ : ${namePrint}`
        worksheet.getCell('A' + (dataExport.length + 6)).font = { size: 11 };
        worksheet.getCell('A' + (dataExport.length + 7)).value = `วันที่พิมพ์ : ${format(new Date(), " DD/MM/YYYY เวลา HH:mm น.", { locale: thLocale, })}`
        worksheet.getCell('A' + (dataExport.length + 7)).font = { size: 11 };

        worksheet.columns = columns;

        workbook.xlsx.writeBuffer()
            .then((data) => {
                const blob = new Blob([data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                let nameFile = 'Visitor Report'
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = nameFile;
                a.click();
            });
    }
    return (
        <Dropdown.Item  ><div className="text-black" onClick={getData} style={{ display: 'flex', justifyItems: 'center' }} >Excel</div></Dropdown.Item>
    );
};

export default ExportsvmGuardManageReportExcel;