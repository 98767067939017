import React, {Component} from 'react';
import { Translation } from 'react-i18next';
import {NavLink} from "react-router-dom";
import _ from "lodash"


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <Translation>
                    {t=>
                    <div className="col">
                        <ul>
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_product'}) && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_service'}) && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_expense'}) &&
                            <li>
                                <NavLink to="/accounting/inventory/product/list/all">
                                    {t("inventory_list:All")}
                                </NavLink>
                            </li>
                            }
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_product'}) &&
                            <li>
                                <NavLink to="/accounting/inventory/product/list/product">
                                    {t("inventory_list:Product")}
                                </NavLink>
                            </li>
                            }
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_service'}) &&
                            <li>
                                <NavLink to="/accounting/inventory/product/list/service">
                                    {t("inventory_list:Service")}
                                </NavLink>
                            </li>
                            }
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_expense'}) &&
                            <li>
                                <NavLink to="/accounting/inventory/product/list/expense">
                                    {t("inventory_list:Expense")}
                                </NavLink>
                            </li>
                            }
                        </ul>
                    </div>}
                </Translation>
            </div>
        )
    }
}

export default Navigation;