/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type waterMeterListHistoryByRoomQueryVariables = {|
  search?: ?string,
  year?: ?number,
  month?: ?number,
  issuedDate_Gte?: ?any,
  issuedDate_Lte?: ?any,
|};
export type waterMeterListHistoryByRoomQueryResponse = {|
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +price: ?number,
        |}
      |}>
    |}
  |},
  +allResidential: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +residentialHouseholder: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +tenant: {|
                +id: string,
                +firstName: string,
                +lastName: ?string,
              |},
            |}
          |}>
        |},
        +waterMeterUnit: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +invoiceStatus: ?boolean,
              +month: number,
              +year: number,
              +issuedDate: any,
              +meterValue: ?number,
              +changeWaterMeter: boolean,
              +initialChangeMeterValue: ?number,
              +remark: string,
              +editDate: ?any,
              +creatorApp: string,
              +creator: ?string,
              +editCreatorDate: ?any,
              +waterMeterImage: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +fileName: ?string,
                    +fileUpload: ?string,
                  |}
                |}>
              |},
              +waterMeterTag: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
|};
export type waterMeterListHistoryByRoomQuery = {|
  variables: waterMeterListHistoryByRoomQueryVariables,
  response: waterMeterListHistoryByRoomQueryResponse,
|};
*/


/*
query waterMeterListHistoryByRoomQuery(
  $search: String
  $year: Float
  $month: Float
  $issuedDate_Gte: Date
  $issuedDate_Lte: Date
) {
  productViewer {
    allProduct(isActive: true, type: "service", servicePricing_PricingType: "water_meter") {
      edges {
        node {
          id
          name
          price
        }
      }
    }
    id
  }
  allResidential(search: $search) {
    edges {
      node {
        id
        name
        residentialHouseholder {
          edges {
            node {
              id
              tenant {
                id
                firstName
                lastName
              }
            }
          }
        }
        waterMeterUnit(year: $year, month: $month, issuedDate_Gte: $issuedDate_Gte, issuedDate_Lte: $issuedDate_Lte) {
          edges {
            node {
              id
              invoiceStatus
              month
              year
              issuedDate
              meterValue
              changeWaterMeter
              initialChangeMeterValue
              remark
              editDate
              creatorApp
              creator
              editCreatorDate
              waterMeterImage {
                edges {
                  node {
                    id
                    fileName
                    fileUpload
                  }
                }
              }
              waterMeterTag {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuedDate_Gte"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issuedDate_Lte"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "isActive",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "servicePricing_PricingType",
      "value": "water_meter"
    },
    {
      "kind": "Literal",
      "name": "type",
      "value": "service"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "allProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allProduct(isActive:true,servicePricing_PricingType:\"water_meter\",type:\"service\")"
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    }
  ],
  "concreteType": "ResidentialNodeConnection",
  "kind": "LinkedField",
  "name": "allResidential",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResidentialNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResidentialNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "HouseHolderNodeConnection",
              "kind": "LinkedField",
              "name": "residentialHouseholder",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HouseHolderNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "HouseHolderNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TenantNode",
                          "kind": "LinkedField",
                          "name": "tenant",
                          "plural": false,
                          "selections": [
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lastName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "issuedDate_Gte",
                  "variableName": "issuedDate_Gte"
                },
                {
                  "kind": "Variable",
                  "name": "issuedDate_Lte",
                  "variableName": "issuedDate_Lte"
                },
                {
                  "kind": "Variable",
                  "name": "month",
                  "variableName": "month"
                },
                {
                  "kind": "Variable",
                  "name": "year",
                  "variableName": "year"
                }
              ],
              "concreteType": "WaterMeterUnitNodeConnection",
              "kind": "LinkedField",
              "name": "waterMeterUnit",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WaterMeterUnitNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WaterMeterUnitNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "invoiceStatus",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "month",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "year",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "issuedDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "meterValue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "changeWaterMeter",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "initialChangeMeterValue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remark",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "editDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "creatorApp",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "creator",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "editCreatorDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WaterMeterImageNodeConnection",
                          "kind": "LinkedField",
                          "name": "waterMeterImage",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "WaterMeterImageNodeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "WaterMeterImageNode",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v5/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "fileName",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "fileUpload",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WaterMeterTagNodeConnection",
                          "kind": "LinkedField",
                          "name": "waterMeterTag",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "WaterMeterTagNodeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "WaterMeterTagNode",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v5/*: any*/),
                                    (v6/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "waterMeterListHistoryByRoomQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "waterMeterListHistoryByRoomQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "fa38ec8d433a7bde53b5053327b504c1",
    "id": null,
    "metadata": {},
    "name": "waterMeterListHistoryByRoomQuery",
    "operationKind": "query",
    "text": "query waterMeterListHistoryByRoomQuery(\n  $search: String\n  $year: Float\n  $month: Float\n  $issuedDate_Gte: Date\n  $issuedDate_Lte: Date\n) {\n  productViewer {\n    allProduct(isActive: true, type: \"service\", servicePricing_PricingType: \"water_meter\") {\n      edges {\n        node {\n          id\n          name\n          price\n        }\n      }\n    }\n    id\n  }\n  allResidential(search: $search) {\n    edges {\n      node {\n        id\n        name\n        residentialHouseholder {\n          edges {\n            node {\n              id\n              tenant {\n                id\n                firstName\n                lastName\n              }\n            }\n          }\n        }\n        waterMeterUnit(year: $year, month: $month, issuedDate_Gte: $issuedDate_Gte, issuedDate_Lte: $issuedDate_Lte) {\n          edges {\n            node {\n              id\n              invoiceStatus\n              month\n              year\n              issuedDate\n              meterValue\n              changeWaterMeter\n              initialChangeMeterValue\n              remark\n              editDate\n              creatorApp\n              creator\n              editCreatorDate\n              waterMeterImage {\n                edges {\n                  node {\n                    id\n                    fileName\n                    fileUpload\n                  }\n                }\n              }\n              waterMeterTag {\n                edges {\n                  node {\n                    id\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '28757424f708523a82e9386cb847d8ff';

module.exports = node;
