import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import '../settingSecurity.scss';
import Navigation from './navigation'
import API from '../../../api/setting'
import _ from 'lodash';
import Loading from "../../../libs/loading"
import i18n from "i18next";
class settingPackingCalculetePacking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settingCalu: null || [],
            role: null || [],
            account: null || [],
            etampTimerVistor: "defualt",
            etampTimerVistorHH: null,
            etampTimerVistorMM: null,
            etampTimer: 2,
            toPayCal: null || "",
            toCalOverTime: null || "",

            settingParkingFeeCalculateId: "",
            amountCarofResident: null || "",
            amountCarPerDay: null || "",
            etampTimerHH: null,
            etampTimerMM: null,

            backTab: false,
            loading: true

        }
        this.handlekeyDown = this.handlekeyDown.bind(this)
        this.amountCarPerDay = this.amountCarPerDay.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.keyDownValue = this.keyDownValue.bind(this)
    }


    handlekeyDown(e, type) {
        if (e.key === "Enter") {
            this.handleSearchChange(e);
        }
        let event = _.cloneDeep(e);
        if (type === 'OverTime') {
            this.setState({ toCalOverTime: parseInt(event.target.value) })
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    amountCarPerDay(e) {
        let event = _.cloneDeep(e);
        this.setState({ etampTimerVistor: event.target.value })

    }

    componentWillMount() {
        this.getDataEstamp();
        this.getDataPackingCal();
    }

    getDataEstamp() {
        API.getEstampSetting().then((res) => {
            if (res.data.role) {
                this.setState({ role: res.data.role })
            } else {
                this.setState({ role: [] })

            }
        }, () => Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Unable to connect to the API at this time.'), 'error'))
    }

    getDataPackingCal() {
        API.getcaluletePackingSetting().then((res) => {
            if (res.data.settingCalu) {
                let settingCalu = []
                _.map(res.data, (n) => {
                    settingCalu.push(n)
                })
                this.setState({
                    settingParkingFeeCalculateId: res.data.settingCalu.settingParkingFeeCalculateId,
                    settingCalu: res.data.settingCalu,

                    etampTimerVistor: res.data.settingCalu.etampTimerVistor == null ? "0" : res.data.settingCalu.etampTimerVistor,
                    etampTimerVistorHH: res.data.settingCalu.etampTimerVistorHH,
                    etampTimerVistorMM: res.data.settingCalu.etampTimerVistorMM,
                    etampTimer: res.data.settingCalu.etampTimer,
                    etampTimerHH: res.data.settingCalu.etampTimerHH,
                    etampTimerMM: res.data.settingCalu.etampTimerMM,
                    toPayCal: res.data.settingCalu.toPayCal,
                    toCalOverTime: res.data.settingCalu.toCalOverTime,
                })
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    keyDownValue(e) {
        let event = _.cloneDeep(e);
        if (event.target.name === "etampTimerVistorHH") {
            this.setState({ etampTimerVistorHH: event.target.value })
        } else if (event.target.name === "etampTimerVistorMM") {
            this.setState({ etampTimerVistorMM: event.target.value })
        } else if (event.target.name === "toPayCal") {
            this.setState({ toPayCal: event.target.value })
        } else if (event.target.name === "toCalOverTime") {
            this.setState({ toCalOverTime: event.target.value })
        }


    }


    onSubmit(e) {
        e.preventDefault();
        let data = {
            "settingParkingFeeCalculateId": !this.state.settingParkingFeeCalculateId ? "" : this.state.settingParkingFeeCalculateId,
            "etampTimerVistor": this.state.etampTimerVistor != "0" ? parseFloat(this.state.etampTimerVistor) : null,
            "etampTimerVistorHH": this.state.etampTimerVistorHH != null ? this.state.etampTimerVistorHH : null,
            "etampTimerVistorMM": this.state.etampTimerVistorHH != null && this.state.etampTimerVistorMM == null ? "0" : this.state.etampTimerVistorMM,
            "toPayCal": parseFloat(this.state.toPayCal),
            "toCalOverTime": parseFloat(this.state.toCalOverTime)
        } 

        _.set(data, `etampTimerVistor`, data.etampTimerVistor ? parseFloat(data.etampTimerVistor) : null)
        _.set(data, `etampTimerVistorHH`, data.etampTimerVistorHH ? parseFloat(data.etampTimerVistorHH) : null)
        _.set(data, `etampTimerVistorMM`, data.etampTimerVistorMM ? parseFloat(data.etampTimerVistorMM) : null) 

        API.savecaluletePackingSetting(data).then((res) => {
            if (res.data.statusMessage === "SUCCESS") {
                Swal.fire(i18n.t('settingSecurityCompany:Successful'), i18n.t('settingSecurityCompany:Saved successful'), 'success').then(() => {
                    this.setState({ redirect: true })
                })
            } else {
                Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Save failed'), 'error')
            }
        }, () => {
            Swal.fire(i18n.t('settingSecurityCompany:Unuccessful'), i18n.t('settingSecurityCompany:Unable to connect to the API at this time.'), 'error')
        })

    }



    render() {
        // if (this.state.backTab) {
        //     return <Redirect to="/setting/setting-keep-packing/estamp" />;
        // } 
        let time = ""


        if (this.state.role) {
            let hours = this.state.role.etampTimerHH ? this.state.role.etampTimerHH : "00"
            let minss = this.state.role.etampTimerMM ? this.state.role.etampTimerMM : "00"
            time = this.state.role.etampTimer ? this.state.role.etampTimer + ` ${i18n.t('settingSecurityCompany:hour')}` : hours + ` ${i18n.t('settingSecurityCompany:hour')} ` + minss + ` ${i18n.t('settingSecurityCompany:minute')}`
        }

        let emecsatime = this.state.role !== null ? time : ""
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                   { t =>(
                <WrapperContent>
                    {/* {console.log('this.props.match.params ', this.props.match.params)} */}
                    <div className="container-fluid box" id="settingPacking">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <span style={{ fontWeight: 'bolder' }}>{t('setting:settingKeepPacking')}</span>
                                </h3>
                            </div>
                        </div>

                        <Navigation />

                        {
                            this.state.loading == false &&
                            <div className='row'>
                                <div className='col-sm-9 mx-auto'>
                                    <form onSubmit={this.onSubmit}>
                                        <div className="row mt-2">
                                            <div className="col-8 text-left">
                                                <div className='row' style={{ marginTop: '45px' }}>
                                                    <div className='col'>
                                                        <label>{t('settingSecurityCompany:Amount of free parking time for visitors')}</label>
                                                        <select name='amountCarPerDay' className='form-control' required={true}
                                                            value={this.state.etampTimerVistor} onChange={this.amountCarPerDay}>
                                                            <option value="defualt" disabled>{t('settingSecurityCompany:hour')}</option>
                                                            <option value="1">1 {t('settingSecurityCompany:hour')}</option>
                                                            <option value="2">2 {t('settingSecurityCompany:hour')}</option>
                                                            <option value="3">3 {t('settingSecurityCompany:hour')}</option>
                                                            <option value="4">4 {t('settingSecurityCompany:hour')}</option>
                                                            <option value="6">6 {t('settingSecurityCompany:hour')}</option>
                                                            <option value="24">24 {t('settingSecurityCompany:hour')}</option>
                                                            <option value="0">{t('settingSecurityCompany:Etc.')}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.etampTimerVistor === "0" &&
                                                    <React.Fragment>
                                                        <div className='row' style={{ marginTop: '24px' }}>
                                                            <div className='col'>
                                                                <label>{t('settingSecurityCompany:Specify free parking time for visitors')}</label>
                                                            </div>
                                                        </div>
                                                        <div className='row col'>
                                                            <div style={{ display: 'inline-flex', width: '225px' }}>
                                                                <input type="number" style={{ width: '118px' }} name='etampTimerVistorHH'
                                                                    className='form-control text-right' placeholder='00' required={this.state.etampTimerVistor === "0"}
                                                                    defaultValue={this.state.etampTimerVistorHH == null ? '' : this.state.etampTimerVistorHH}
                                                                    onKeyDown={this.keyDownValue} onKeyUp={this.keyDownValue} min="0"/>
                                                                <span style={{ margin: '12px', marginRight: '26px' }}>{t('settingSecurityCompany:hour')}</span>
                                                            </div>

                                                            <div style={{ display: 'inline-flex', width: '225px' }}>
                                                                <input type="number" style={{ width: '118px' }} name='etampTimerVistorMM'
                                                                    className=' form-control text-right' placeholder='00' max={59}
                                                                    required={this.state.etampTimerVistor === "0"} maxLength={2}
                                                                    defaultValue={this.state.etampTimerVistorMM == null ? '' : this.state.etampTimerVistorMM}
                                                                    onKeyDown={this.keyDownValue} onKeyUp={this.keyDownValue} min="0"/>
                                                                <span style={{ margin: '12px', marginRight: '26px' }}>{t('settingSecurityCompany:minute')}</span>
                                                            </div>
                                                        </div>

                                                        <div className='row' style={{ marginTop: '24px' }}>
                                                            <div className='col'>
                                                                <label>{t('settingSecurityCompany:Specify free parking time for')}</label><label className='tagLabel-blue'>E-Stamp
                                                                    <div className="tooltipsSpan"><img src={process.env.PUBLIC_URL + "/images/iconAlerts/iconInfo-Gray.png"} />
                                                                        <span className='tooltiptext' >{t('settingSecurityCompany:Specify free parking time for E-stamp, depends on the E-Stamp settings')}</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='row col'>
                                                            <div style={{ display: 'inline-flex', width: '225px' }}>
                                                                <input type="number" name='etampTimerHH' style={{ width: '118px' }}
                                                                    className='form-control text-right' placeholder='00'
                                                                    readOnly defaultValue={this.state.role.etampTimerHH || this.state.role.etampTimer} />
                                                                <span style={{ margin: '12px', marginRight: '26px' }}>{t('settingSecurityCompany:hour')}</span>
                                                            </div>

                                                            <div style={{ display: 'inline-flex', width: '225px' }}>
                                                                <input type="text" name='etampTimerMM' style={{ width: '118px' }}
                                                                    className=' form-control text-right' placeholder='00'
                                                                    readOnly defaultValue={this.state.role.etampTimerMM || '00'} />
                                                                <span style={{ margin: '12px', marginRight: '26px' }}>{t('settingSecurityCompany:minute')}</span>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {
                                                    this.state.etampTimerVistor !== "0" &&
                                                    <React.Fragment>
                                                        <div className='row' style={{ marginTop: '24px' }}>
                                                            <div className='col'>
                                                                <label>{t('settingSecurityCompany:Specify free parking time for')}</label> <label className='tagLabel-blue'>E-Stamp
                                                                    <div className="tooltipsSpan"><img src={process.env.PUBLIC_URL + "/images/iconAlerts/iconInfo-Gray.png"} />
                                                                        <span className='tooltiptext' >{t('settingSecurityCompany:Specify free parking time for E-stamp, depends on the E-Stamp settings')}</span>
                                                                    </div>


                                                                </label>
                                                                <input type="text" className='form-control' name='etampTimer' readOnly
                                                                    defaultValue={emecsatime} />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }

                                                <div className='row' style={{ marginTop: '21px' }}>
                                                    <div className='col'>
                                                        <label>{t('settingSecurityCompany:Parking rate (Baht/Hour)')}</label>
                                                    </div>
                                                </div>

                                                <div className='row col' style={{ height: '48px' }}>
                                                    <input type="number" className='form-control'
                                                        required={true} style={{ width: '349px' }} name='toPayCal'
                                                        placeholder='ระบุจำนวน' defaultValue={this.state.toPayCal == null ? "" : this.state.toPayCal}
                                                        onKeyDown={this.keyDownValue} onKeyUp={this.keyDownValue} min="0"/>
                                                </div>


                                                <div className='row' style={{ marginTop: '24px' }}>
                                                    <div className='col'>
                                                        <label>{t('settingSecurityCompany:Start calculating the overtime parking fee equal to the regular 1 hour parking rate.')}</label>
                                                    </div>
                                                </div>

                                                <div className='row col' style={{ height: '48px' }}>
                                                    <input type="number" className='form-control text-right' style={{ width: '118px' }}
                                                        placeholder='ระบุจำนวน' name='toCalOverTime' required={true}
                                                        defaultValue={this.state.toCalOverTime == null ? "" : this.state.toCalOverTime}
                                                        onKeyDown={this.keyDownValue} onKeyUp={this.keyDownValue} min="0"/>
                                                    <span style={{ margin: '12px', marginRight: '26px' }}>{t('settingSecurityCompany:minute or more')}</span>
                                                    {/* {
                                                this.state.toCalOverTime != null ?
                                                    <p>ตัวอย่าง :  จอดเกิน {this.state.toCalOverTime} นาทีขึ้นไป คิดเป็น  1 ชั่วโมง</p> : <p></p>
                                            } */}

                                                </div>

                                            </div>
                                        </div>

                                        <Translation>
                                            {t =>
                                                <div className='row mt-2'>
                                                    <div className='col-12 text-right'>
                                                        <button type='submit' className='btn btn-primary'>{t('Allaction:save')}</button>
                                                    </div>
                                                </div>
                                            }
                                        </Translation>


                                    </form>

                                </div>
                            </div>
                        }

                        {
                            this.state.loading == true && <Loading />
                        }




                    </div>


                </WrapperContent>
                     )}
                </Translation>
            </Wrapper>
        )
    }
}

export default settingPackingCalculetePacking;
