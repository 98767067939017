import React from "react";
import getApiRoot from "../../../../libs/getAPIRoot";
import { format } from "date-fns";
import API from "./receivableOutstandingReportOnLoad";
import axios from "axios";
import Swal from "sweetalert2";
import i18next from "i18next";
class ExportReceivableOutstandingReportOnLoad extends React.Component {


  constructor(props) {
    super(props);
    this.state = { 
      loading: false,
      count: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async () => {
    this.setState({ loading: true })
    // let timerInterval
    // Swal.fire({
    //   type: 'warning',
    //   title: 'ข้อความแจ้งเตือน',
    //   html: '<h2>ระบบกำลังประมวลผล กรุณารอสักครู่!</h2>',
    //   timer: 5000,
    //   onBeforeOpen: () => { Swal.showLoading() },
    //   onClose: () => { clearInterval(timerInterval) }
    // }).then((result) => {
    //   if (result.dismiss === Swal.DismissReason.timer) {
    //     return null;
    //   }
    // })

    if (!this.state.loading) {
      var token_id = localStorage.getItem("token");
      var header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
      };

      
      let datasGet = []
      let count = 0
      let step = 1000
      
      while (count < this.props.state.data.invoiceViewer.allInvoice.totalCount) {

        // fine last page
        let last = step
        if( (count + step) < this.props.state.data.invoiceViewer.allInvoice.totalCount){
          count += step;
        }else{
          count += step;
          last = step - (count - this.props.state.data.invoiceViewer.allInvoice.totalCount)
        }
        
        const res = await API.receivableOutstandingReportOnLoadGraph(count,last,this.props.state)
        datasGet.push(...res.invoiceViewer.allInvoice.edges)
      }


      var data = {
        "start_date": format(this.props.state.start_date, 'DD/MM/YYYY'),
        "end_date": format(this.props.state.end_date, 'DD/MM/YYYY'),
        "datas_get": datasGet,
        // "report_outstanding_receivable_summary": this.props.state.reportOutstandingReceivableSummary,
        "contact":this.props.state.contact,
        "product_and_service":this.props.state.product_and_service,
        "overdue_date": this.props.state.overdue_date,

      }
      await axios.post(`${getApiRoot()}export/export_receivable_outstanding_v2`, data, {
        headers: header,
        responseType: 'arraybuffer',
      })
        //print pdf not worker or job_runner
        .then((response) => {
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
          const fileName = `receivable_outstanding${formattedDate}.xlsx`;

          // Create a Blob from the response data
          const blob = new Blob([response.data], { type: 'application/octet-stream' });

          // Create a temporary URL using createObjectURL
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          // Clean up
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

          this.setState({
            loading: false,
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({
            loading: false
          });
          Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
        })



      this.setState({ loading: false })
    }
  };

  render() {

    return (
      // <button type="buttom" className="btn btn-primary print float-right" disabled={this.state.loading} onClick={this.handleSubmit}>
      <button type="buttom" className="btn print float-right" disabled={this.state.loading} onClick={this.handleSubmit}>
        {this.state.loading ?
          <span>
            <span
              className="spinner-border spinner-border-sm align-middle mr-2" /> {i18next.t("Allaction:Preparing information")}</span> 
              :
          <span>
            <img src={process.env.PUBLIC_URL + '/images/icons/print-icon.png'} alt="print-icon" />
            {i18next.t("Allaction:Print")}
          </span>
        }
      </button>
    )
  }

}

export default ExportReceivableOutstandingReportOnLoad;
