import React, { Component } from 'react'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../node_modules/slick-carousel/slick/slick-theme.css"
import '../styles/project.scss'
import '../styles/modalComponentProjectList.scss'
import SelectPicModal from './selectPicModal';
import { Translation } from 'react-i18next';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <React.Fragment>
        <img {...props}
            src={process.env.PUBLIC_URL + '/images/icons/arrow-left.png'}
            alt="back"
            aria-disabled={currentSlide === 0 ? true : false} />
    </React.Fragment> 
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <React.Fragment>
        <img {...props}
            src={process.env.PUBLIC_URL + '/images/icons/arrow-right.png'} 
            alt="next" aria-disabled={currentSlide === 0 ? true : false} />
    </React.Fragment>
);
export default class planProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            dataPlanProject: this.props.item.length > 0 ? this.props.item : [],
        }
    }

    componentDidUpdate() {
        if (this.state.dataPlanProject !== this.props.item) {
            this.setState({
                dataPlanProject: this.props.item
            })
        }
    }

    getData = (upload, item) => {
        this.props.getPlan(upload, item)
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            prevArrow: <SlickArrowLeft />,
            nextArrow: <SlickArrowRight />
        };

        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="my-5" style={{ width: "100%", height: "1px", background: "#dfdfdf" }} />

                        <div className="content-inner fade-up">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between">
                                    <h6>{t("PageDetailProjectInfo:PlanSheetInsideProject")}</h6>
                                    <SelectPicModal item={this.state.dataPlanProject} getData={this.getData} />
                                </div>
                                <div className="col-8 reactSlick mt-4" style={{ minHeight: '300px' }}>
                                    <Slider {...settings}>
                                        {this.state.dataPlanProject.map((img, index) => {
                                            return (
                                                <div className="d-flex justify-content-center" key={index}>
                                                    <img className="plan" src={img.node.fileUpload} alt="PicturesSub" />
                                                </div>
                                            )
                                        })
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>

                }
            </Translation>
        )
    }
}



