import React, {Component} from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {getTypeProject} from "../../libs/getTypeOfProject";
import i18n from 'i18next'
import '../styles/register.scss'

import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {commitMutation, QueryRenderer} from "react-relay";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import SearchSelect from "../../libs/searchSelect";
import RegisterTopMenuNavigation from "../registerTopMenuNavigation";
import Loading from "../../libs/loading";
import checkResidentialQuery from "./query/checkResidentail"
import { fetchQuery } from "relay-runtime";
const key = {
    "อาคารพาณิชย์": "commercial building",
    "ตึกแถว": "commercial building",
    "ทาวน์โฮม": "townhome",
    "ทาวน์เฮาส์": "townhouse",
    "บ้านแฝด": "semi-detached house",
    "บ้้านเดี่ยว": "detached house",
    "คอนโด": "condominium",
    "สตูดิโอ": "studio",
    "1 ห้องนอน": "1 bedroom",
    "2 ห้องนอน": "2 bedrooms",
    "3 ห้องนอน": "3 bedrooms",
    "4 ห้องนอน": "4 bedrooms",
    "5 ห้องนอน": "5 bedrooms",
    "6 ห้องนอน": "6 bedrooms",
    "7 ห้องนอน": "7 bedrooms",
    "8 ห้องนอน": "8 bedrooms",
    "9 ห้องนอน": "9 bedrooms",
    "10 ห้องนอน": "10 bedrooms",
    "ดูเพล็กซ์": "duplex",
    "เพนท์เฮ้าส์": "penthouse",
    "ออฟฟิศสำนักงาน": "office",
    "ศูนย์การค้า": "shopping center",
    "ร้านค้า": "shop",
    "หอพัก": "dormitory",
    "ที่ดินเปล่า": "vacant land",
    "อื่นๆ": "others",
}

const mutation = graphql`
    mutation createResidentialMutation($input: CreateRoomDetailInput!){
        createRoomDetail(input: $input){
            ok
        }
    }
`;

const query = graphql`
    query createResidentialQuery{
        allResidentialType{
            edges{
                node{
                    id
                    name
                    slug
                }
            }
        }
        allResidential{
            edges{
                node{
                    id
                    name
                }
            }
        }
        selfProject{
            typeOfProject
        }
    }

`;

const _ = require('lodash');
class createResidential extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            name: '',
            type_name: '',
            size: '',
            floor: '',
            status: 'LIVE',
            other_status: null,
            ownership_number: '',
            ownership_first_name: '',
            ownership_last_name: '',
            loading:false,
            redirectToList:false,
        };
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChangeInput(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let roomObject = {
            id: this.state.id,
            name: this.state.name,
            size: this.state.size,
            status: this.state.status,
            other_status: this.state.other_status,
            ownership_number: this.state.ownership_number,
            ownership_first_name: this.state.ownership_first_name,
            ownership_last_name: this.state.ownership_last_name,
            type_name: this.state.type_name,
            floor:this.state.floor,
        };
        let variables = {
            input: {
                roomObject: JSON.stringify(roomObject)
            }
        };

        fetchQuery(environment, checkResidentialQuery,{
             name: this.state.name
        }).then(res => {
            if(res.checkResidential.result){
                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            this.setState({loading: false});
        
                            if (response.createRoomDetail.ok) {
                                Swal.fire(i18n.t("residential:Save successfully!"), '', 'success').then(() => {
                                    this.setState({redirectToList: true})
                                });
                            } else {
                              
                                Swal.fire(i18n.t("residential:Save unsuccessfully!"), '', 'warning');
                            }
        
                        },
                        onError: (err) => {
                            this.setState({loading: false});
                            Swal.fire(i18n.t("residential:Save unsuccessfully!"), '', 'error');
                        },
                    },
                );
            }else{
                this.setState({loading: false});
                Swal.fire(i18n.t("residential:Duplicate Room No."), '', 'error');
            }
        })
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/register/residential/list/all"/>
        }
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));

      

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <RegisterTopMenuNavigation mini={true}/>
                    <div className="container-fluid p-4" id="form-detail">
                        <div className="row justify-content-between">
                            <div className="col header-color">
                                <h3 className="mb-4">
                                    <Link
                                        to="/register/residential/list/all">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>

                                    <Translation>
                                        {
                                            t => 
                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                            <span>{textData?.data?.createResidential.addResidential}</span>
                                            :<span>{t('register:add_residential')}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <QueryRenderer
                            environment={environment}
                            query={query}
                            render={({error, props}) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    const allResidentialType = props.allResidentialType.edges.map(item => {
                                        const newNode = { ...item.node };
                                        const name_en = key[item.node.name]; 
                                        newNode.name_en = name_en;
                                        return { node: newNode };
                                        });

                                    let getType = getTypeProject(props.selfProject.typeOfProject )
                                    return ( <form onSubmit={this.onSubmit}>
                            <div className="content-inner">
                                <div className="row mb-4 fade-up">
                                    <div className="col-md-8">
                                        <div className="card p-3">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h4 className="mb-3">
                                                            <Translation>
                                                                {
                                                                    t => <span>{t('register:detail')}</span>
                                                                }
                                                            </Translation>
                                                        </h4>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t('residential:Room No.')}</h6>}</Translation>
                                                        <div className="input-group">
                                                            <input type="text"
                                                                   className="form-control"
                                                                   value={this.state.name}
                                                                   required
                                                                   onChange={this.handleChangeInput}
                                                                   name="name"/>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Type")}</h6>}</Translation>

                                                        <SearchSelect onChange={this.handleChangeInput}
                                                                      value={this.state.type_name}
                                                                      name="type_name"
                                                                      queryObject={allResidentialType}
                                                                      keyOfValue="id"
                                                                      require={true}
                                                                      keyOfLabel={i18n.language=='th'?'name':'name_en'} />
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Area_size")}</h6>}</Translation>
                                                        <div className="input-group">
                                                            <input type="number"
                                                                   step="0.01"
                                                                   className="form-control"
                                                                   value={this.state.size}
                                                                   required
                                                                   onChange={this.handleChangeInput}
                                                                   name="size"/>

                                                            <div className="input-group-append">
                                                                <Translation>{t=><span className="input-group-text input-date">{getType === "highType" ? t("residential:Sq m") : t("residential:Sq wa")}</span>}</Translation>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Status")}</h6>}</Translation>
                                                        <Translation>
                                                            {t=>
                                                            <select className="custom-select"
                                                                    value={this.state.status}
                                                                    name="status"
                                                                    onChange={this.handleChangeInput}>
                                                                <option value="LIVE">{t("residential:Live")}</option>
                                                                <option value="FOR_RENT">
                                                                    {                                            
                                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                    textData?.data?.createResidential.forRent
                                                                        :
                                                                    t("residential:For rent")
                                                                    }
                                                                </option>
                                                                <option value="SEIZURE">{t("residential:Seizure")}</option>
                                                                <option value="AVAILABILITY">{t("residential:Availability")}</option>
                                                                <option value="OTHER">{t("residential:Other")}</option>
                                                            </select>}
                                                        </Translation>
                                                    </div>
                                                </div>
                                                {this.state.status === "OTHER" &&
                                                    <div className="row mt-4">
                                                            <div className="col">
                                                                <Translation>{t=><h6>{t("resident:Specify")}</h6>}</Translation>
                                                                <input type="text" className="form-control"
                                                                    name="other_status"
                                                                    onChange={this.handleChangeInput}
                                                                    value={this.state.other_status || ""}
                                                                    required={false}
                                                                />
                                                            </div>
                                                    </div>
                                                }

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{props.selfProject.typeOfProject === "CONDOMINIUM" ? t("residential:Floor") : t("residential:Floors")}</h6>}</Translation>
                                                        <input type="text" maxLength={3}
                                                               className="form-control"
                                                               value={this.state.floor}
                                                               onChange={this.handleChangeInput}
                                                               name="floor"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=>
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                        <h6>{textData?.data?.createResidential.nameAndSurname}</h6>
                                                        :
                                                        <h6>{t("residential:Name")} ({t("residential:Ownership")})</h6>
                                                        }</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.ownership_first_name}
                                                               onChange={this.handleChangeInput}
                                                               name="ownership_first_name"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=>
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                        <h6>{textData?.data?.createResidential.departmentName}</h6>
                                                        :
                                                        <h6>{t("residential:Surname")} ({t("residential:Ownership")})</h6>
                                                        }</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.ownership_last_name}
                                                               onChange={this.handleChangeInput}
                                                               name="ownership_last_name"/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>{t=><h6>{t("residential:Ownership No.")}</h6>}</Translation>
                                                        <input type="text"
                                                               className="form-control"
                                                               value={this.state.ownership_number}
                                                               name="ownership_number"
                                                               onChange={this.handleChangeInput}/>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <Translation>
                                                            {t=>
                                                            <button type="submit" disabled={this.state.loading}
                                                                    className="btn btn-primary bt-style form-update">{t("residential:Save")}
                                                                {
                                                                    this.state.loading &&
                                                                        <span className="spinner-border spinner-border-sm align-middle mr-2"/>
                                                                }
                                                        </button>}
                                                        </Translation>
                                                    </div>
                                                    <div className="col">
                                                        <Link to="/register/residential/list/all">
                                                            <Translation>
                                                                {t=>
                                                                <button className="btn btn-secondary bt-style form-update">
                                                                    {t("residential:Cancel")}
                                                                </button>}
                                                            </Translation>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                                    )}
                    return <Loading/>
                }}
            />

                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default createResidential;
