import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import DebtFreeCertificationList from './debtFreeCertificationList'
import DebtSelectCertification from './debtSelectCertification';
import WrapperDebtFreeCertification from './document/wrapperDebtFreeCertification'
import wrapperPrintDebtFree from './document/wrapperPrintDebtFree';

class DebtFreeRequestCertification extends Component {
    
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={DebtFreeCertificationList}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={DebtFreeCertificationList}/>
                <PrivateRoute path={`${this.props.match.url}/create`} component={DebtSelectCertification}/>
                <PrivateRoute path={`${this.props.match.url}/print/all`} component={WrapperDebtFreeCertification}/>
                <PrivateRoute path={`${this.props.match.url}/printed`} component={wrapperPrintDebtFree}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default DebtFreeRequestCertification;