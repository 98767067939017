import React, { Component, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Header from '../components/header/';
import Sidebar from '../components/sidebar/';
import Wrapper from '../components/wrapper/';
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../env/environment";
import { fetchQuery } from "relay-runtime";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import i18n from 'i18next';
import i18next from 'i18next';
import Loading from "../libs/loading";
import './style.scss'
import styled from 'styled-components';
import _ from 'lodash';

const ButtonContainer = styled.span`
  position: fixed;
  bottom: 32px;
  right: 32px;
  align-items: center;
  height: 45px;
  width: 45px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  background: #1567B4;
  border-radius: 4px;
  transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
  display: ${({ isScrollButtonVisible }) =>
    isScrollButtonVisible ? 'flex' : 'none'};

  &:hover {
    opacity: 1;
  }
`;

const ButtonFeedback = styled.span`
  position: fixed;
  bottom: 90px;
  right: 32px;
  align-items: center;
  height: 45px;
  width: 45px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  border-radius: 4px;
  transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
  &:hover {
    opacity: 1;
  }
`;


const query = graphql`
  query announceDashboardQuery(
    $page: Boolean
  ) {
    allJuristicNews(
      dashboard:true,newsTypes:"juristic",page: $page
    ) {
      edges {
        node {
          id
          title
          titleEn
          detail
          detailEn
          status
          creator
          added
          updated
          newsType
          scheduleTime
          previewImage
          highlight
          group{
            id
            name
            nameEn
          }
        }
      }
    }
  }
`;
const mutation = graphql`
mutation announceDashboardMutation ($input: FeedbackCreateInput!) {
    feedbackCreate (input: $input) {
        ok
        message   
    }
}
`;


 export default function AnnounceDashboard() {
   
    const [newsPins, setNewsPins] = useState([])
    const [newsPinsTop1, setNewsPinsTop1] = useState([])
    const [newsPinsTop2, setNewsPinsTop2] = useState([])
    const [posts, setPosts] = useState([])
    const [isMobileView, setisMobileView] = useState(true);
    const [visible, setVisible] = React.useState(false);
    const itemsPerPage = 4;
    const [hasMoreItems, sethasMoreItems] = useState(true);
    const [records, setrecords] = useState(itemsPerPage);
    const [showButton, setShowButton] = useState(false);
    const [isloading, setIsloading] = useState(false);
    const [note, setNote] = useState('');

    useEffect(() => {
      const checkScrollHeight = () => {
        if (!showButton && window.pageYOffset > 400) {
          setShowButton(true);
        } else if (showButton && window.pageYOffset <= 400) {
          setShowButton(false);
        }
      };
  
      window.addEventListener('scroll', checkScrollHeight);
      return () => {
        window.removeEventListener('scroll', checkScrollHeight);
      };
    }, [showButton]);
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const goToFeedback = () => {
        window.location.href = '/feedback-post';
    };
  
    const countword = (detail) =>{
      let detail_new = null;
        if (detail?.length >= 150) {
          detail_new =
            detail?.replaceAll(/<[^>]+>/g, "").substring(0, 150) + "...";
          detail_new = detail_new.replaceAll(
            "&nbsp;",
            " "
          );
        } else {
          detail_new = detail?.replaceAll(
            /<[^>]+>/g,
            ""
          );
          detail_new = detail_new?.replaceAll(
            "&nbsp;",
            " "
          );
        }
        return detail_new
    }

    const Card = (props) => {
      let detail_count = countword(props.detail)
        return (
            <React.Fragment>
                <div className="card" style={{width:'18rem',maxHeight:'40rem'}}>
                    <Link to={"/announce-dashboard/news/detail/"+props.id}>
                      <img src={props.img} className="card-img-top img-fluid" alt="Placeholder Image" style={{height:'10rem'}}/>
                    </Link>
                    <div className="card-body">
                        <Link to={"/announce-dashboard/news/detail/"+props.id}>
                          <h5 className="card-title" style={{color:'#000000'}}>{props.title || '-'}</h5>
                        </Link>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: detail_count,
                          }}/>
                          <p className='pt-2' style={{margin:0}}> 
                            <Translation>{t => <strong>{t('announceDashboard:by')}</strong>}</Translation> {props.creator || '-'} </p>
                          <p><Translation>{t => <strong>{t('announceDashboard:category')}</strong>}</Translation> {props?.group?.name || '-'}</p>
                    </div>
                </div>
            </React.Fragment>
        )
      }
      
    const showItems = posts => {
      var items = [];
      for (var i = 0; i < records && posts && posts[i]; i++) {
        if (posts[i].node) {
          items.push(
            <div key={i} className="col-md-3 pt-6">
              <Card id={posts[i].node.id} title={posts[i].node.title} detail={posts[i].node.detail}
              img={posts[i].node.previewImage} creator={posts[i].node.creator} added={posts[i].node.added}
              group={posts[i].node.group }
               />
            </div>
          );
        }
      }
    
      return items;
    };

    const handleInputChange = (event) => {
      // Update the state with the new input value
      setNote(event.target.value);
    };

    const handleSubmit = e => {
      e.preventDefault();
      
          let variables = {
              input: {
                  feedback: {
                      award: 0,
                      note: note,
                      image: []
                  }
              }
          };

          commitMutation(
              environment,
              {
                  mutation,
                  variables,
                  onCompleted: (response) => {
                      if (response.feedbackCreate.ok) {
                          Swal.fire(i18n.t("ขอบคุณสำหรับการแนะนำ"), 'เราจะนำข้อมูลไปพัฒนาบริการของเราให้ดียิ่งขึ้น', 'success').then(() => {
                              window.location.reload()
                          });

                      } else {
                          Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), 'กรุณาส่งใหม่อีกครั้ง', 'warning')
                      }
                  },
                  onError: (err) => {
                      Swal.fire('Error!', i18n.t("bank_transfer:Please try again"), 'warning')
                      
                  },
              },
          )
  };
    const loadMore = () => {
        if (records === posts.length) {
          sethasMoreItems(false);
        } else {
          setTimeout(() => {
            setrecords(records + itemsPerPage);
          }, 2000);
        }
      };

    useEffect(() => {
      const checkForDevice =  window.innerWidth < 640;
      setisMobileView(checkForDevice)
    },[window.innerWidth < 640]);

    useEffect(() => {
      fetchQuery(environment, query,{
        page:true
      }).then((res) => {
        setNewsPins(res?.allJuristicNews?.edges[0]?.node ?? {})
        setIsloading(true)
        let startingPosition = 1;
        let arr1 = _.cloneDeep(res?.allJuristicNews?.edges)
        let newArray = arr1.slice(startingPosition);
        setPosts(newArray)
        return
      })
    }, [])

        return (
          !isloading ? <Loading/> :
            <Wrapper>
                {/* {
                  !isMobileView ? 
                  <>
                  <Header/>
                  <Sidebar/>
                  </> :''
                } */}
                  <Header/>
                  <Sidebar/>
                <div id="wrapper-content" style={{backgroundColor:'#FAFAFA',overflow:'hidden'}}>
                  <Modal show={visible} id="modal" size="lg" className='modal-popup-banner'
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                       <form className='p-2' onSubmit={handleSubmit}>
                          <div className="feature-box card p-3">
                          <div className='pt-4 ml-5'> 
                          <Translation>{t => <h3>{t('announceDashboard:tell me more')}</h3>}</Translation>
                          </div>
                          <div className="card-body">
                            <textarea rows={8} className='form-control' name='note' onChange={handleInputChange}/>
                          </div>
                          <div style={{padding:'0 20px'}} className='btn-action'>
                              <button className="btn btn-announce-close form-control pt-2" 
                              type='button' onClick={() => setVisible(false)} >
                              <Translation>{t => <span>{t('announceDashboard:close')}</span>}</Translation>
                              </button>
                              <button className="btn btn-announce-submit form-control pt-2"> 
                              <Translation>{t => <span>{t('announceDashboard:send')}</span>}</Translation>
                              </button>
                          </div>
                          </div>
                      </form>
                </Modal>
                <div className="container-fluid box bg-gray" id="dashboard-announce">
                    <div className="row justify-content-between">
                        <div className="col">
                            <h3 className="mb-4">
                                <Link to="/home">
                                    <img
                                        src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                        alt="back" className="back" />
                                </Link>
                                <Translation>
                                    {
                                        t => <span>{t('announceDashboard:announce')}</span>
                                    }
                                </Translation>
                            </h3>
                        </div>
                    </div>

                    <div className="content-inner fade-up">
                        <div className='row pt-5'>
                            <div className='col-md-6'>
                              {
                                newsPins.previewImage ? 
                                <Link to={"/announce-dashboard/news/detail/"+newsPins.id}>
                                  <img src={newsPins.previewImage}
                                  alt="announce-main" className='dashboard-img'/>
                                </Link> : 
                                <img src={process.env.PUBLIC_URL + '/images/dashboard/announce-main.png'}
                                alt="announce-main" className='img-fluid'/>
                              }
                               
                            </div>
                            <div className='col-md-6'>
                                    <Translation>{t => <h4 className='text-primary mt-2'>{t('announceDashboard:last')}</h4>}</Translation>
                                    <Link to={"/announce-dashboard/news/detail/"+newsPins.id}>
                                      <h2 style={{color:'#000000'}}>{newsPins.title}</h2>
                                    </Link>
                                    <div style={{width:'100%'}}>
                                     <div
                                      className="content"
                                      dangerouslySetInnerHTML={{
                                        __html: newsPins.detail,
                                      }}/>
                                    </div>
                                    <p className='pt-8'> 
                                      <Translation>{t => <strong className='pr-2'>{t('announceDashboard:by')}</strong>}</Translation>
                                       {newsPins.creator || '-'} </p>
                            </div>
                        </div>
                        {
                            <InfiniteScroll
                                loadMore={loadMore}
                                hasMore={hasMoreItems}
                                loader={
                                !isloading ? <div className="loader" style={{fontSize:'20px',textAlign:'center'}}> 
                                Loading... </div> :''}
                                // loader={
                                //  <div className="loader" style={{fontSize:'20px',textAlign:'center'}}> 
                                //   Loading... </div>}
                                useWindow={false}
                                pullDownToRefresh={true}
                                >
                                <div className='row' style={{paddingTop:'40px'}}>
                                    {showItems(posts)}
                                </div>
                            </InfiniteScroll>
                        }
                           
                    </div>
                    <div className='feedback-view'>
                    <ButtonFeedback onClick={() => setVisible(true)}>
                    <img src={process.env.PUBLIC_URL + '/images/sidebar/Icon-New-Feature.png'} style={{width:'50px'}}
                                alt="dashboard-side-icon" />
                    </ButtonFeedback>
                    </div>
                    <ButtonContainer isScrollButtonVisible={showButton} onClick={scrollToTop}>
                        <svg
                            width={30}
                            height={30}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#ffffff"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M12 19V5M5 12l7-7 7 7" />
                        </svg>
                    </ButtonContainer>
                  
                </div>
                </div>
            </Wrapper>
        );
    
}


