import React from "react";
import getApiRoot from "../../../../libs/getAPIRoot";
import Helpers from '../../../../libs/file_download_helper';
import {format} from "date-fns";
import { Modal} from 'react-bootstrap';
import { withRouter } from 'react-router';
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import './historyReportPDF.scss'

class ExportHistoryAll extends React.Component {

    constructor(props) {
        super(props);
        let month_before = new Date();
        month_before.setMonth(month_before.getMonth() - 1);

        this.state = {
            loading: false,
            showModal:false,
            showModalPDF: false,
            temp_start_date: month_before,
            temp_end_date : new Date(),
            end_date : new Date()
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.submitPDF = this.submitPDF.bind(this);
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
          });
     }

    submit(e) {
        e.preventDefault();
        this.setState({
            loading: true,
        }, () => {
            Helpers.httpRequest(
                getApiRoot() + 'export/history-all?start_date='
                + format(this.state.temp_start_date, 'DD/MM/YYYY')
                + '&end_date=' + format(this.state.temp_end_date, 'DD/MM/YYYY'),
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "history.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    });
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });
        this.setState({ showModalPDF: false });
    }
    // PDF
    submitPDF(e) {
      e.preventDefault();
      const { temp_start_date, temp_end_date } = this.state;
      const data = { temp_start_date, temp_end_date };
      this.props.history.push("/accounting/report/account-receivable/history/select-customer/PDF", { data, typePrint: 'all' });
      this.setState({ showModalPDF: false });
    }

    handleSubmit = (event) => {
        this.setState({ showModal: true });
     };
    
    handleSubmitPDF = (event) => {
        this.setState({ showModalPDF: true });
    };

    render() {

        return (
            <>
            {/* PDF */}
            <Modal show={this.state.showModalPDF} dialogClassName="modal-approve" id="modal" >
                <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div onSubmit={this.submitPDF}> 
                  <h3 className="pt-2 pb-2">เลือกช่วงวันที่</h3>
                  <form >
                    <div className="form-row">
                      <div className="form-group col-md-6">
                      <label htmlFor="surname">เรียงตามวันที่</label>
                        <DatePickerAdapter
                            name="temp_start_date"
                            className="form-control"
                            selected={this.state.temp_start_date}
                            // minDate={this.state.min_date}
                            maxDate={this.state.end_date}
                            onChange={this.handleInputChange}
                            required={true}
                    />
                      </div>
                      <div className="form-group col-md-6">
                      <label htmlFor="name">ถึง</label>
                        <DatePickerAdapter
                            name="temp_end_date"
                            className="form-control"
                            selected={this.state.temp_end_date}
                            // maxDate={this.state.current_date}
                            onChange={this.handleInputChange}
                            required={true}
                    />      
                      </div>
                    </div>
                   
                   
                
                    <div className="col-12  mt-3 text-right">
                    <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({showModalPDF: false})}>
                        <span>ยกเลิก</span>
                    </button>
                    <button
                          className="btn btn-primary"
                          disabled={this.state.loading} type="submit"
                        >
                          พิมพ์ทั้งหมด (PDF)
                    </button>
                </div>
                  </form>
                  </div>
              </Modal.Body>
            </Modal>
            
            {/* Excel */}
            <Modal show={this.state.showModal} dialogClassName="modal-approve" id="modal" >
                <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div onSubmit={this.submit}> 
                  <h3 className="pt-2 pb-2">เลือกช่วงวันที่</h3>
                  <form >
                    <div className="form-row">
                      <div className="form-group col-md-6">
                      <label htmlFor="surname">เรียงตามวันที่</label>
                        <DatePickerAdapter
                            name="temp_start_date"
                            className="form-control"
                            selected={this.state.temp_start_date}
                            // minDate={this.state.min_date}
                            maxDate={this.state.end_date}
                            onChange={this.handleInputChange}
                            required={true}
                    />
                      </div>
                      <div className="form-group col-md-6">
                      <label htmlFor="name">ถึง</label>
                        <DatePickerAdapter
                            name="temp_end_date"
                            className="form-control"
                            selected={this.state.temp_end_date}
                            // maxDate={this.state.current_date}
                            onChange={this.handleInputChange}
                            required={true}
                    />      
                      </div>
                    </div>
                   
                   
                
                    <div className="col-12  mt-3 text-right">
                    <button type="button" className="btn btn-danger mr-2" onClick={() => this.setState({showModal : false})}>
                        <span>ยกเลิก</span>
                    </button>
                    <button className="btn btn-primary" disabled={this.state.loading} type="submit">
                          พิมพ์ทั้งหมด (Excel)  
                    </button>
                </div>
                  </form>
                  </div>
              </Modal.Body>
            </Modal>

            <div className="historyReport" style={{position:'absolute',top:'130px',right:'22px'}}>
                {/* <button type="submit" className="btn print float-right" onClick={this.handleSubmit}>
                        พิมพ์ทั้งหมด
                </button> */}
                <div className= "dropdown printReport">
                      <React.Fragment>
                        <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                          <span>พิมพ์ทั้งหมด</span>
                        </button>
                      </React.Fragment>
                      <div className={`dropdown-menu`}>
                          {/* <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a> */}
                          <a type="submit" className="dropdown-item" onClick={this.handleSubmitPDF}>PDF</a> 
                          <a type="submit" className="dropdown-item" onClick={this.handleSubmit}>Excel</a> 
                      </div>
                  </div>
            </div>
           
            </>
        )
    }
}

export default withRouter(ExportHistoryAll);
