import SurveyCreateUpdateForm from "./surveyCreateUpdateForm";
import React, {Component} from 'react';
import "./styles/survey.scss"


class SurveyEdit extends Component {

    render() {
        return (
            <div className="container-fluid box" id="news">
                <SurveyCreateUpdateForm survey_id={this.props.survey_id}/>
            </div>

        )
    }
}

export default SurveyEdit;