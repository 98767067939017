import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";


import thLocale from "date-fns/locale/th";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from 'react-relay'

import { Link } from "react-router-dom";
import { QueryRenderer } from "react-relay";
import _ from "lodash";

import './css/contractServiceView.scss';
import environment from "../../../env/environment";


import Swal from "sweetalert2";
import { Redirect } from 'react-router';

import { Translation } from "react-i18next";
import i18n from "i18next"
import { format, } from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import FileInput from '../../../project/projectInfo/componentProjectList/fileInput';
import ModalVoid from './modalVoid'
import RenewContractRevenueMutation from './mutations/RenewContractRevenueMutation'
import ReUploadContractRevenueInput from './mutations/ReUploadContractRevenueMutation'

import Loading from "../../../libs/loading";
import i18next from "i18next";
import upload from '../../../libs/upload';

const query = graphql`
  query contractServiceViewQuery($id: String) {
    oneContractRevenue(crId:$id){
      edges {
          node {
              id
              docNumber
              issuedDate
              debtor{
                  refNumber
                  name
              }
              contractNumber
              contractForm
              otherContractForm
              startContractDate
              endContractDate
              status
              remark
              creator
              voidRemark
              voider
              voidDate
              fileAttachment
              fileName
              notiBeforeEnd
              pricePerPeriod
              total
              chartOfAccount{
                  name
              }
              contractRevenueList{
                  edges{
                      node{
                          id
                          productAndService{
                            productCode
                            name
                          }
                          description
                          numPeriod
                          pricePerPeriod
                          total
                      }
                  }
              }
              areaCompensationMonth
              areaCompensationYear
          }
      }
    }
  }
`;

const mutation = graphql`
  mutation contractServiceViewMutation($input: VoidContractRevenueInput!) {
    voidContractRevenue(input: $input) {
      ok
      message
    }
  }
`;
class ContractServiceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      listOtherUnknown: [],
      closeAlert: false,
      redirectTo: "",
      notConfirm: false,
      statusVoid: "",
      fileAttachment: '',
      fileName: '',
    };
  }

  handleRenew = () => {
    RenewContractRevenueMutation({ contractRevenueId: this.props.match.params.id }, (data) => {
      if (data.renewContractRevenue.ok) {
        Swal.fire(i18n.t("contract_service:Save successfully!"), '', 'success').then(() => {
          this.setState({ redirect: true })
        });
      } else {
        Swal.fire(i18n.t("contract_service:Save unsuccessfully!"), '', 'warning');
        this.setState({ loading: false });
      }
    })
  }

  onError(err) {
    this.setState({ loading: false });
    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
  }

  handleCloseModal = () => {
    this.setState({ notConfirm: false })
  }

  handleChangeInput = (even) => {
    let { name, value } = even.target;
    this.setState({ [name]: value })
  }
  appendFileInputRow = async (fileInput) => {
    let fileAttachment = ''
    if (fileInput.node.fileUpload) {
      await this.callUpload('', fileInput.node.fileUpload).then((data) => {
        fileAttachment = data.key.replace("silverman-storage/private/", "");
      });
    }
  
    ReUploadContractRevenueInput({
        contractRevenueId: this.props.match.params.id,
        fileAttachment: fileAttachment,
        fileName: fileInput.node.nameFile,
      }, (data) => {
      if (data.uploadFileContractRevenue.ok) {
        Swal.fire(i18n.t("contract_service:Save successfully!"), '', 'success').then(() => {
          // this.setState({ redirect: true })
        });
      } else {
        Swal.fire(i18n.t("contract_service:Save unsuccessfully!"), '', 'warning');
        this.setState({ loading: false });
      }
    })
    this.setState({
      fileAttachment: fileInput.node.fileUpload,
      fileName: fileInput.node.nameFile
    })
  }

  callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload("/contractService/fileAttachment" + path, file);
      resolve(res);
    });
  };

  handleVoid = (status) => {
    this.setState({
      notConfirm: true,
      statusVoid: status
    })

  }
  handleSubmit = (input) => {
    let variables = input;
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          this.setState({ loading: false });
          if (response.voidContractRevenue.ok) {
            Swal.fire(i18n.t("contract_service:Save successfully!"), '', 'success').then(() => {
              this.setState({ redirect: true })
            });
          } else {
            Swal.fire(i18n.t("contract_service:Save unsuccessfully!"), '', 'warning');
            this.setState({ loading: false });
          }
        },
        onError: (err) => {
          this.onError();
        },
      },
    );

  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/accounting/income/contract_service/list/void" />;
    }


    // alert("Rounded: " + Math.round((q / d) * 100) + "%");
    // alert("Fraction: " + ((q / d) * 100) + "%");

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <QueryRenderer
            environment={environment}
            query={query}
            variables={{
              id: this.props.match.params.id
            }}
            render={({ error, props }) => {
              if (error) {
                return (
                  <div className="alert alert-danger" role="alert">
                    {error.message}
                  </div>
                );
              } else if (props) {
                var q = Math.abs(new Date().getTime() - new Date(format(props.oneContractRevenue.edges[0].node?.startContractDate, "YYYY-MM-DD") + "T00:00").getTime());
                var d = Math.abs(new Date(format(props.oneContractRevenue.edges[0].node?.endContractDate, "YYYY-MM-DD") + "T23:59").getTime() - new Date(format(props.oneContractRevenue.edges[0].node?.startContractDate, "YYYY-MM-DD") + "T00:00").getTime());
                var getPercentDate = Math.round((q / d) * 100)
                // console.log("Rounded: " + Math.round((q / d) * 100) + "%");
                // console.log("RoFractionunded: " + ((q / d) * 100) + "%");

                let contractForm = ''
                if (props.oneContractRevenue.edges[0].node?.contractForm === "A_3_MONTH") {
                  contractForm = 'ราย 3 เดือน'
                }
                else if (props.oneContractRevenue.edges[0].node?.contractForm === "A_6_MONTH") {
                  contractForm = 'ราย 6 เดือน'
                }
                else if (props.oneContractRevenue.edges[0].node?.contractForm === "A_1_YEAR") {
                  contractForm = 'รายปี'
                } else {
                  contractForm = 'อื่นๆ'
                }

                let status_text = ''
                if (props.oneContractRevenue.edges[0].node?.status === "WAIT") {
                  status_text = i18next.t("contractServiceView:Wait for pending")
                }
                else if (props.oneContractRevenue.edges[0].node?.status === "IN_PROGRESS") {
                  status_text = i18next.t("contractServiceView:In Progress")
                }
                else if (props.oneContractRevenue.edges[0].node?.status === "NEAR_END") {
                  status_text = i18next.t("contractServiceView:Almost due")
                }
                else if (props.oneContractRevenue.edges[0].node?.status === "RENEW") {
                  status_text = i18next.t("contractServiceView:Contract extension")
                }
                else if (props.oneContractRevenue.edges[0].node?.status === "NOT_RENEW") {
                  status_text = i18next.t("contractServiceView:Not renewing contract")
                }
                else {
                  status_text = i18next.t("contractServiceView:Cancel")
                }

                return (
                  <div className="container-fluid box" id="contract-service-view">
                    <ModalVoid
                      open={this.state.notConfirm}
                      handleCloseModal={this.handleCloseModal}
                      handleChangeInput={this.handleChangeInput}
                      contractRevenueId={this.props.match.params.id}
                      statusVoid={this.state.statusVoid}
                      docNumber={props.oneContractRevenue.edges[0].node.docNumber}
                      handleConfirm={this.handleSubmit}
                    />
                    <div className="row">
                      <div className="col-3">
                        <Translation>
                          {
                            t =>
                              <h4 className="mb-4">
                                <Link to={"/accounting/income/contract_service/list/all"}>
                                  <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                    alt="back" className="back" />
                                </Link>{props.oneContractRevenue.edges[0].node.docNumber}
                              </h4>
                          }
                        </Translation>
                      </div>
                      <div className="col-9">
                        <div className="text-center mr-5">
                          <label style={{ fontSize: "20px", fontFamily: "Kanit" }}>{i18next.t("contractServiceView:Contract period")}</label>
                          <div className="progress" style={{ width: "100%" }}>

                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: getPercentDate + "%" }} aria-valuenow={getPercentDate} aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                          <label style={{ float: 'left' }}>
                            {format(props.oneContractRevenue.edges[0].node?.startContractDate, "MMMM YYYY")}
                          </label>
                          <label style={{ float: 'right' }}>
                            {format(props.oneContractRevenue.edges[0].node?.endContractDate, "MMMM YYYY")}
                          </label>


                        </div>
                      </div>
                    </div>

                    <div className="content-inner box">
                      {/* row 1 */}
                      <div className="row mb-24">
                        <div className="col-sm-3 ">
                          <Translation>{t => <h6>{i18next.t("contractServiceView:Status")}: {status_text}</h6>}</Translation>
                          {/* <input type="text" className="form-control" value={this.state.docNumber} disabled /> */}
                        </div>
                      </div>

                      {/* row Edit Show issuedDate */}
                      <div className="row mb-24">
                        <div className="col-sm-3 ">
                          <Translation>{t => <h6>{t("contract_service:On")}</h6>}</Translation>
                          <input type="text" className="form-control" value={format(props.oneContractRevenue.edges[0].node?.issuedDate, 'DD/MM/YYYY', { locale: thLocale })} disabled />
                          {/* <input type="text" className="form-control" value={this.state.docNumber} disabled /> */}
                        </div>
                      </div>

                      {/* row 2 */}
                      <div className="row mb-24">
                        <div className="col-sm-3">
                          <Translation>{t => <h6>{t("contract_service:Receivable/Contract Company")}</h6>}</Translation>
                          <input type="text" className="form-control" value={props.oneContractRevenue.edges[0].node?.debtor.refNumber + " " + props.oneContractRevenue.edges[0].node?.debtor.name} disabled />
                        </div>

                        <div className="col-sm-3">
                          <Translation>{t => <h6>{t("contract_service:Category")}</h6>}</Translation>
                          <input type="text" className="form-control" value={props.oneContractRevenue.edges[0].node?.chartOfAccount.name} disabled />
                        </div>

                      </div>

                      {/* row 3 */}
                      <div className="row mb-24">
                        <div className="col-sm-3">
                          <Translation>{t => <h6>{t("contract_service:Contract Number")}</h6>}</Translation>
                          <input type="text" className="form-control" value={props.oneContractRevenue.edges[0].node?.contractNumber || ""} disabled />
                        </div>
                        <div className="col-sm-3">
                          <Translation>{t => <h6>{t("contract_service:Contract")}</h6>}</Translation>
                          <input type="text" className="form-control" value={contractForm || ""} disabled />
                        </div>
                        {contractForm === "อื่นๆ" && <div className="col-2">
                          <input type="text" className="form-control"
                            value={props.oneContractRevenue.edges[0].node?.otherContractForm}
                            style={{ marginTop: 32 }}
                            disabled
                          />
                        </div>}
                        <div className="col-2">
                          <Translation>{t => <h6>{t("contract_service:Contract Start Date")}</h6>}</Translation>
                          <input type="text" className="form-control" value={format(props.oneContractRevenue.edges[0].node?.startContractDate, 'DD/MM/YYYY', { locale: thLocale })} disabled />
                        </div>
                        <div className="col-2">
                          <Translation>{t => <h6>{t("contract_service:Contract Expiration Date")}</h6>}</Translation>
                          <input type="text" className="form-control" value={format(props.oneContractRevenue.edges[0].node?.endContractDate, 'DD/MM/YYYY', { locale: thLocale })} disabled />
                        </div>
                      </div>

                      <div className="row col-12 mt-24">
                        <h4>{i18next.t("contractServiceView:Revenue lists")}</h4>
                      </div >
                      <div className="fade-up table-responsive ">
                        <table className="table table-contract table-hover mt-2">
                          <thead className="thead-light headTable">
                            <tr className="tableList">
                              <Translation>{t => <th>{t("contract_service:List")}</th>}</Translation>
                              <Translation>{t => <th>{t("contract_service:Description")}</th>}</Translation>
                              <Translation>{t => <th className="text-right">{t("contract_service:Unit")}</th>}</Translation>
                              <Translation>{t => <th className="text-right">{t("contract_service:Price/Month (Baht)")}</th>}</Translation>
                              <Translation>{t => <th className="text-right">{t("contract_service:Total (Baht)")}</th>}</Translation>
                            </tr>
                          </thead>

                          {/* transaction_list */}
                          <tbody>
                            {
                              props.oneContractRevenue.edges[0].node?.contractRevenueList.edges.map((transaction, index) => (

                                <tr key={"transaction-" + index}>
                                  <td width="100">
                                    {transaction.node.productAndService.productCode + " " + transaction.node?.productAndService.name}
                                  </td>

                                  <td width="100">
                                    {transaction.node.description}
                                  </td>

                                  <td className="text-right" width="100">
                                    {numberWithComma(transaction.node.numPeriod, 0)}
                                  </td>

                                  <td className="text-right" width="50">
                                    {numberWithComma(transaction.node.pricePerPeriod, 0)}
                                  </td>

                                  <td className="text-right" width="100">
                                    {numberWithComma(transaction.node.total, 0)}
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                          <tfoot className="footer-table">
                            <tr>
                              <Translation>{t => <th width="100">{t("contract_service:All Total (Baht)")}</th>}</Translation>
                              <th width="100" />
                              <th width="100" />
                              <th className="text-right" width="50">{numberWithComma(props.oneContractRevenue.edges[0].node.pricePerPeriod, 0)}</th>
                              <th className="text-right" width="100" >{numberWithComma(props.oneContractRevenue.edges[0].node.total, 0)}</th>

                            </tr>
                          </tfoot>
                          {/* transaction_list */}

                        </table>
                      </div>

                      <div className="row mt-5">
                        <div className="col d-flex align-items-center">
                          <div className="cursor attach-btn">
                            <label
                              className="text-blue"
                              htmlFor="CustomFile">
                              <img src={process.env.PUBLIC_URL + "/images/icons/attach-i.png"} alt="Document"
                                className="mr-2" style={{ width: '20px', height: '20px' }}
                              />
                              {i18next.t("contractServiceView:Attrach file")}</label>
                            <div className="upload-input custom-file cursor">
                              {!this.state.disibleInput &&
                                <FileInput
                                  id="CustomFile"
                                  appendFileInputRow={this.appendFileInputRow}
                                />}
                            </div>
                          </div>
                          <span
                            className="alert-attach ml-4"
                          >{i18next.t("contractServiceView:.jpg or .pdf file and file size not over 20 MB")}</span>
                        </div>
                      </div>

                      {/* fileAttachment */}
                      {props.oneContractRevenue.edges[0].node?.fileAttachment && this.state.fileAttachment === '' &&
                        <div className="row ml-2 mt-5 d-flex align-items-center">
                          <a href={props.oneContractRevenue.edges[0].node?.fileAttachment} target="_blank">
                            <img src={process.env.PUBLIC_URL + "/images/icons/pdf-icon.png"} alt="Document" style={{ width: '24px', height: '24px' }} />
                            <span className='text-blue' >{props.oneContractRevenue.edges[0].node?.fileName}</span>
                          </a>
                        </div>
                      }
                      {/* new upload file */}
                      {this.state.fileAttachment &&
                        <div className="row ml-2 mt-5 d-flex align-items-center">
                          <img src={process.env.PUBLIC_URL + "/images/icons/pdf-icon.png"} alt="Document" style={{ width: '24px', height: '24px' }} />
                          <span className='text-blue' >{this.state.fileName}</span>
                        </div>
                      }

                      {/* <div className="row mb-24">
                        <div className="col-sm-3">
                          <Translation>{t => <h6>{t("contract_service:AreaCompensationMonth")}</h6>}</Translation>
                          <input type="text" className="form-control" value={props.oneContractRevenue.edges[0].node.areaCompensationMonth} disabled />
                        </div>

                        <div className="col-sm-3">
                          <Translation>{t => <h6>{t("contract_service:AreaCompensationYear")}</h6>}</Translation>
                          <input type="text" className="form-control" value={props.oneContractRevenue.edges[0].node.areaCompensationYear} disabled />
                        </div>
                      </div> */}

                      <div className="row mt-5">
                        <div className="col-12">
                          <h6>{i18next.t("contractServiceView:Note")}</h6>
                        </div>
                        <div className="col-6">
                          <textarea className="form-control"
                            name="remark"
                            value={props.oneContractRevenue.edges[0].node.remark}
                            disabled
                          />
                        </div >
                        <div className="6" />
                      </div>
                      <div className="row mt-5">
                        <div className="col-12">
                          <h6>{i18next.t("contractServiceView:Advance notification")}</h6>
                        </div>
                        <div className="col-6 ">
                          <select className="form-control dropdown-custom-arrow"
                            name="notiDay"
                            value={props.oneContractRevenue.edges[0].node.notiDay}
                            onChange={this.handleInputChange}
                            style={{ fontFamily: 'Kanit' }}
                            disabled
                          >
                            <option value={30}>30 {i18next.t("contractServiceView:Days")}</option>
                            <option value={60}>60 {i18next.t("contractServiceView:Days")}</option>
                            <option value={90}>90 {i18next.t("contractServiceView:Days")}</option>
                          </select>
                        </div >
                        <div className="6" />
                      </div>

                      <div className="row mt-5">
                        <div className="col-12">
                          {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_contract_revenue_approve' }) &&
                            <button className="btn-confirm float-right " type="button" onClick={this.handleRenew} style={{ minWidth: 150 }} >
                              <Translation>{t => <span>{t("contract_service:Renew")}</span>}</Translation>
                            </button>
                          }
                          {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_contract_revenue_approve' }) &&
                            <button className="btn-info float-right mr-6" type="button" onClick={() => this.handleVoid('not_renew')} style={{ minWidth: 150 }}>
                              <Translation>{t => <span>{t("contract_service:Not renew")}</span>}</Translation>
                            </button>
                          }
                          {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_contract_revenue_delete' }) &&
                            <button className="btn-cancel float-right mr-6" type="button" onClick={() => this.handleVoid('void')} style={{ minWidth: 150 }}>
                              <Translation>{t => <span>{t("contract_service:Void")}</span>}</Translation>
                            </button>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                )
              }
              return <Loading />
            }}
          />
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ContractServiceView;
