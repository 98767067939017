import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../components/noMatch";
import DownloadFileDashBoard from './downloadFileDashBoard'

class DownloadFile extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={DownloadFileDashBoard}/> 
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={DownloadFileDashBoard}/> 
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default DownloadFile;