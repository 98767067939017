import React, { Component } from 'react';
import upload from '../../../libs/upload'
import Swal from "sweetalert2";
import format from 'date-fns/format';
import { Link } from "react-router-dom";
import API from '../../../api/sing'
import _ from 'lodash';
import i18n from 'i18next';
import i18next from 'i18next';


class Modalcancle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: [],
            action: this.props.action,
        }
        this.uploadFile = this.uploadFile.bind(this)
        this.ReQuestPayment = this.ReQuestPayment.bind(this)
        this.formatBytes = this.formatBytes.bind(this)
        this.reSetFile = this.reSetFile.bind(this)
        this.deleteFile = this.deleteFile.bind(this)

    } 

    //UPLOADFILE
    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/sing/file" + path, file);
            resolve(res);
        });
    };

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        let sizeReal = parseFloat((bytes / Math.pow(k, i)).toFixed(5))
        let simbol = sizes[i]
        if (sizes[i] == 'Bytes') {
            sizeReal = sizeReal / 1000000
            simbol = 'MB'
        } else if (sizes[i] == 'KB') {
            sizeReal = sizeReal / 1000
            simbol = 'MB'
        }

        let result = sizeReal + ' ' + simbol;

        return result
    }
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files.size > 10485760) {  // RQ147 2097152
            Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
        } else {
            let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);

            let fileOldList = _.cloneDeep(this.state.file);
            let sizeMB = this.formatBytes(files.size)
            this.callUpload('', new_file).then((data) => {
                let dese = "sing/file/" + new_file.name
                fileOldList.push({
                    fileId: "", fileName: new_file.name,
                    fileSize: sizeMB, filePath: dese,
                    fileType: files.type,
                    status: "", viewPtath: data.location
                })
                this.setState({ file: fileOldList , action: true })
            });
        }
    }
    // END UPLOAD

    ReQuestPayment(type) {
        let imageUpDate = type ? this.state.file : "" 
        let data = {
            "id": this.props.onClick,
            "ref_slip": imageUpDate
        }
        API.patchRepairs_room_worksheet(data).then((res) => {
            Swal.fire(i18n.t("Allaction:Saved Successfully"), "", "success").then(()=> {
                window.location.reload()
            })
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), "", "error"))
    }

    reSetFile(){ 
        document.getElementById("uploadFile").value = ""
        this.setState({file : []}) 
    }

    deleteFile(i){

        let updateFileDelete = _.cloneDeep(this.state.file) 
        updateFileDelete.splice(i,1)    

        this.setState({file : updateFileDelete})
    }


    render() {  
        
        return (
            <div className={"modal fade bd-example-modal-lg fixrequestPayment" + this.props.onClick + " modalPayment"} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog  modal-lg" role="document" style={{ marginTop: '3%' }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ borderBottom: '0', padding: "15px 30px" }}>
                            <h2 className="modal-title text-center" style={{ fontSize: "24px" }}>{i18n.t("paymentgateway:Upload receipt")}</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.reSetFile}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ padding: "10px 30px", marginTop: "-10px" }}>
                            <div style={{ border: "3px dashed #E9ECEF", textAlign: 'center', paddingBottom: '60px' }}>
                                {
                                    <table className='table tablePayment mx-auto'>
                                        <tbody>
                                        {
                                            this.state.file.length !== 0?
                                                this.state.file.map((n, index) => {  
                                                    let typeFile = n.fileType.split("/")
                                                    return (

                                                        <tr key={index} >
                                                            <td style={{ marginRight: '12px', textAlign: 'right' }}>
                                                                <img style={{ height: '40px' }}
                                                                    src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1]+ "Icon.png"} />
                                                            </td>
                                                            <td className='paymentTR'>
                                                                <p>
                                                                    <Link
                                                                        to="route"
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            window.open(
                                                                                typeof n.viewPtath === "string"
                                                                                    ? n.viewPtath
                                                                                    : URL.createObjectURL(n.viewPtath)
                                                                            )
                                                                        }}
                                                                        target="blank">{n.fileName}</Link>

                                                                </p>
                                                                <p>{n.fileSize}</p>
                                                            </td>
                                                            <td style={{ textAlign: 'left' }}>
                                                                <button onClick={()=>this.deleteFile(index)}>X</button> 
                                                            </td> 
                                                        </tr> 
                                                    )
                                                }) 
                                                : 
                                                    <tr>
                                                        <td>
                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/TypeFileUpload.png"} style={{ marginTop: '75px' }} />

                                                        </td>
                                                    </tr> 


                                        }
                                        </tbody>
                                    </table>
                                }
                                <br />
                                <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                    <button className="btn">
                                        <img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{i18n.t("FixRequestModal:Attach slip")}</button>
                                    <input type="file" name='file' id="uploadFile"
                                        className='form-control uploadFile'
                                        placeholder='ระบุเบอร์โทร' accept='.pdf,.png,.jpg'
                                        onChange={this.uploadFile} />
                                </label><br />

                                <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>
                                    {i18n.t("FixRequestModal:File size must not exceed 10 MB.")} </span>
                            </div>

                        </div>
                        <div className="modal-footer" style={{ borderTop: '0' }}>
                            <button type="button" className="btn btn-primary-outlie"
                                style={{ width: "112px", height: "48px" }}
                                data-dismiss="modal" aria-label="Close"
                                onClick={() => this.ReQuestPayment(false)}
                            >{i18n.t("FixRequestModal:Skip")}</button>
                            <button type="button" className="btn btn-primary"
                                style={{ width: "112px", height: "48px" }}
                                data-dismiss="modal" aria-label="Close"
                                onClick={() => this.ReQuestPayment(true)}>{i18n.t("FixRequestModal:Save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Modalcancle