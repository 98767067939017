/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CarCarType = "CAR" | "MOTORCYCLE" | "%future added value";
export type vehicleListQueryVariables = {|
  carType?: ?string,
  search?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type vehicleListQueryResponse = {|
  +selfProject: ?{|
    +name: string,
    +address: ?string,
    +logo: ?string,
  |},
  +residentialVehicle: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +car: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +licencePlate: string,
              +carBrand: ?string,
              +carModel: ?string,
              +carDescription: string,
              +carType: CarCarType,
              +carSticker: ?string,
              +chargedFee: boolean,
              +floor: ?string,
              +space: ?string,
              +carImage: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +image: ?string,
                    +description: ?string,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>,
  |},
|};
export type vehicleListQuery = {|
  variables: vehicleListQueryVariables,
  response: vehicleListQueryResponse,
|};
*/


/*
query vehicleListQuery(
  $carType: String
  $search: String
  $first: Int
  $last: Int
) {
  selfProject {
    name
    address
    logo
    id
  }
  residentialVehicle(carType: $carType, search: $search, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        name
        car(carType: $carType) {
          edges {
            node {
              id
              licencePlate
              carBrand
              carModel
              carDescription
              carType
              carSticker
              chargedFee
              floor
              space
              carImage {
                edges {
                  node {
                    id
                    image
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "carType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "carType",
  "variableName": "carType"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    (v7/*: any*/),
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    }
  ],
  "concreteType": "ResidentialNodeConnection",
  "kind": "LinkedField",
  "name": "residentialVehicle",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResidentialNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResidentialNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": [
                (v7/*: any*/)
              ],
              "concreteType": "CarNodeConnection",
              "kind": "LinkedField",
              "name": "car",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CarNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CarNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "licencePlate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "carBrand",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "carModel",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "carDescription",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "carType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "carSticker",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "chargedFee",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "floor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "space",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CarImageNodeConnection",
                          "kind": "LinkedField",
                          "name": "carImage",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CarImageNodeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CarImageNode",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v8/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "image",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "description",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "vehicleListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "vehicleListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "387ce90b29a9eda6b2f633eda3ec2080",
    "id": null,
    "metadata": {},
    "name": "vehicleListQuery",
    "operationKind": "query",
    "text": "query vehicleListQuery(\n  $carType: String\n  $search: String\n  $first: Int\n  $last: Int\n) {\n  selfProject {\n    name\n    address\n    logo\n    id\n  }\n  residentialVehicle(carType: $carType, search: $search, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        car(carType: $carType) {\n          edges {\n            node {\n              id\n              licencePlate\n              carBrand\n              carModel\n              carDescription\n              carType\n              carSticker\n              chargedFee\n              floor\n              space\n              carImage {\n                edges {\n                  node {\n                    id\n                    image\n                    description\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9faaba6638cfc108f02d6836cc2f21e';

module.exports = node;
