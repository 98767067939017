import React, {Component} from 'react';
import PrivateRoute from '../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../components/noMatch";
import UploadfileWrapper from './uploadfileWrapper'

class UploadFileFile extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={UploadfileWrapper}/> 
                <PrivateRoute exact path={`${this.props.match.url}/:status`} component={UploadfileWrapper}/> 
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default UploadFileFile;