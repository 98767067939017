import React, { Component } from 'react';
import Swal from "sweetalert2";
import API from "../../api/claim"
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import BackButtonIcon from '../../components/BackBtn/indexBack';
import './menuSidebar.scss'
import i18next from 'i18next';
// const query = graphql`
//     query settingRoleCreateQuery {
//         getAllKey{
//             edges{
//                 node{
//                     id
//                     keyName
//                     description
//                 }
//             }
//         }
//     }
// `;
// const mutation = graphql`
//     mutation settingRoleCreateMutation($input: CreateAccountProjectManagerInput!) {
//         createAccountProjectManager(input: $input) {
//             ok
//         }
//     }
// `;

class SettingRoleCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuName: "",
            subMenu: [],
            loading: false,
            ircActive: false,
            selected: {}
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    alertActive() {
        Swal.fire({
            icon: 'error',
            title: 'ไม่สามารถบันทึกได้',
            text: 'โปรดระบุการเชื่อมต่อฐานข้อมูลจากกล้องกับระบบรักษาปลอดภัยและผู้มาติดต่อ ใช่ หรือ ไม่'
        })
    }

    componentWillMount() {
        this.getData();
    }

    getData() {
       
    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true })
        let Data = {
            "juristic_consider": parseInt(this.state.juristic_consider),
            "insure_approve": parseInt(this.state.insure_approve),
            "receive_compensation": parseInt(this.state.receive_compensation),
            "button": "True",
        }

        API.settingClaim(Data).then((res) => {
            if (res.data) {
                Swal.fire(i18next.t("Allaction:Saved Successfully"), i18next.t("Allaction:Saved Successfully"), 'success').then(() => {
                    this.setState({ loading: false })
                })
            } else {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Saved Unsuccessful"), 'error')
            }

        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    } 
    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <div className="container-fluid box claim">
                        <BackButtonIcon
                            LinkBack={"/setting"}
                            LinkText={"ตั้งค่ากำหนดสิทธิการใช้งานเมนู"}
                            addChk={true}
                            addTest={"สร้างเมนูใหม่"}
                            addButton={"/setting/SettingRole/create"} />

                        <br />
                        <br />
                        <form method='post' onSubmit={this.onSubmit}>
                             

                        </form>


                    </div>


                </WrapperContent>
            </Wrapper >
        )
    }
}

export default SettingRoleCreate;
