import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReceiveReportPDFTable from './receiveReportPDFTable';
import environment from '../../../../env/environment';
import { graphql } from "babel-plugin-relay/macro";
import Loading from "../../../../libs/loading"
import { QueryRenderer } from "react-relay";
import { Helmet } from "react-helmet";

import './receiveReportPDF.scss'

const query = graphql`
  query receiveReportPDFQuery(
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $status: String
    $order: String
    $customer_type: String
    $payment_channel: String
    $payment_gateway_method_type: String
    $contact: String
    $productAndService: String
  ) {
    receiveViewer {
      allReceive(
        startDate: $start_date
        endDate: $end_date
        status: $status
        search: $search
        order: $order
        customerType: $customer_type
        paymentChannel: $payment_channel
        paymentGatewayMethodType: $payment_gateway_method_type
        contact: $contact
        productAndService: $productAndService
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            total
            status
            payGroup
            firstName
            lastName
            contact {
              name
              refNumber
              firstName
              lastName
              typeOfContact
              residential {
                name
              }
            }
            receiveTransaction {
              totalCount
              edges {
                node {
                  id
                  amount
                  discount
                  receiptDepositTransactionUsed {
                    edges {
                      node {
                        amount
                      }
                    }
                  }
                  transaction {
                    id
                    description
                    price
                    discount
                    total
                    paid
                    status
                    productAndService {
                      id
                      price
                      productCode
                    }
                    invoice {
                      docNumber
                      issuedDate
                    }
                  }
                }
              }
            }
            creditCardTransaction {
              totalCount
              edges {
                node {
                  amount
                }
              }
            }
            cashDepositReceiveTransaction {
              totalCount
              edges {
                node {
                  price
                }
              }
            }
            bankAccountTransactionReceive {
              totalCount
              edges {
                node {
                  price
                  transferMethod
                }
              }
            }
            crossBank: bankAccountTransactionReceive(
              transferMethod: "cross_bank"
            ) {
              totalCount
              edges {
                node {
                  price
                }
              }
            }
            chequeDeposit {
              totalCount
              edges {
                node {
                  price
                }
              }
            }

            unknownReceive {
              totalCount
              edges {
                node {
                  amount
                }
              }
            }
            receiptDeposit {
              totalCount
              edges {
                node {
                  docNumber
                  refNumber
                  status
                  remark
                  total
                  taxType
                  firstName
                  lastName
                  name
                  taxIdNumber
                  voidRemark
                  added
                  updated
                  issuedDate
                  receiptDepositTransaction {
                    totalCount
                    edges {
                      node {
                        price
                        description
                        chartOfAccount {
                          chartOfAccountCode
                          name
                        }
                      }
                    }
                  }
                  receiptDepositTransactionUsed {
                    totalCount
                    edges {
                      node {
                        amount
                      }
                    }
                  }
                  crossBank: bankAccountTransaction(
                    transferMethod: "cross_bank"
                  ) {
                    totalCount
                    edges {
                      node {
                        price
                      }
                    }
                  }
                  bankAccountTransaction {
                    totalCount
                    edges {
                      node {
                        price
                      }
                    }
                  }
                  cashTransaction {
                    totalCount
                    edges {
                      node {
                        price
                      }
                    }
                  }
                  chequeDeposit {
                    totalCount
                    edges {
                      node {
                        price
                      }
                    }
                  }
                  creditCardTransaction {
                    totalCount
                    edges {
                      node {
                        amount
                      }
                    }
                  }
                }
              }
            }
            creditNoteReceive {
              totalCount
              edges {
                node {
                  price
                }
              }
            }
            smallChange {
              totalCount
              edges {
                node {
                  amount
                }
              }
            }
            feeAccounting {
              totalCount
              edges {
                node {
                  amount
                }
              }
            }
            withheldTaxReceive {
              totalCount
              edges {
                node {
                  total
                }
              }
            }
            paymentGatewayMethod{
              totalCount
              edges {
                node {
                    id
                    paymentType
                    paymentGateway{
                      id
                      paymentType
                      fee
                      typeCal
                    }
                    bankAccount{
                      id
                      docNumber
                      accountNumber
                      accountName
                      accountType
                      receivePayment
                      makePayment
                      bankName
                      status
                    }
                    paymentType
                    description
                    amount
                    feeAmount
                    total
                    depositDate
                    image
                }
              }
            }
          }
        }
      }
    }
    currentRecord: allAccountRecord(
      startDate: $start_date
      endDate: $end_date
      typeOfRecord: "current"
    ) {
      edges {
        node {
          id
          name
          debit
          credit
          added
          group {
            id
            name
            issuedDate
            refNumber
            totalDebit
            totalCredit
            refTransaction
            added
            category
          }
          chartOfAccountCode {
            id
            name
            chartOfAccountCode
          }
        }
      }
    }
    allPaymentGatewayMethodType{
      dataTable
    }
  }
`;
class ReceiveReportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            PrintBy: "",
            loading: true
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data
        })
    }

    render() {
        return (
            <React.Fragment>
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }

                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Receive Report</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                {
                    <div id="receiveReportPDF">
                        <div className="print-top-menu">
                            <div className="logo">
                                <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                            </div>
                            <div className="print" onClick={() => window.print()}>
                                PRINT
                            </div>
                        </div>
                        <QueryRenderer
                            environment={environment}
                                            query={query}
                                            variables={{
                                                first: null,
                                                last: null,
                                                start_date: this.state.data.start_date,
                                                end_date: this.state.data.end_date,
                                                search: this.state.data.search,
                                                status: "paid,void",
                                                order: "doc_number",
                                                customer_type: this.state.data.customer_type,
                                                payment_channel: this.state.data.payment_channel,
                                                payment_gateway_method_type: this.state.data.payment_gateway_method_type,
                                                contact: this.state.data.contact,
                                                productAndService: this.state.data.product_and_service,
                                            }}
                                            render={({ error, props }) => { 
                                            if (props) {
                                                let paymentChannel = "";
                                                let contactName = "";
                                                let productAndService = "";
                                                // text paymentChannel 
                                                switch (this.state.data.temp_payment_channel) {
                                                  case "cash":
                                                    paymentChannel = 'เงินสด';
                                                    break;
                                                  case "bank":
                                                    paymentChannel = 'เงินโอน';
                                                    break;
                                                  case "cross_bill":
                                                    paymentChannel = 'Cross bill';
                                                    break;
                                                  case "credit_card":
                                                    paymentChannel = 'บัตรเครดิต';
                                                    break;
                                                  case "cheque":
                                                    paymentChannel = 'เช็ค';
                                                    break;
                                                  case "receipt_deposit_transaction_used":
                                                    paymentChannel = 'รับล่วงหน้า';
                                                    break;
                                                  case "unknown_receive":
                                                    paymentChannel = 'เงินรับรอการตรวจสอบ';
                                                    break;
                                                  case "other":
                                                    paymentChannel = 'อื่นๆ';
                                                    break;
                                                  default:
                                                    paymentChannel = 'ทั้งหมด';
                                                    break;
                                                }
                                                // text contactName
                                                if( this.state.data.contact !== ""){
                                                  if(props.receiveViewer?.allReceive?.edges[0]?.node?.contact?.typeOfContact === "RESIDENTIAL"){
                                                    contactName = props.receiveViewer?.allReceive?.edges[0]?.node?.contact?.refNumber
                                                    + " " + props.receiveViewer?.allReceive?.edges[0]?.node?.contact?.firstName
                                                    + " " + props.receiveViewer?.allReceive?.edges[0]?.node?.contact?.lastName
                                                  } else if(props.receiveViewer?.allReceive?.edges[0]?.node?.contact?.typeOfContact === "SUPPLIER") {
                                                    contactName = props.receiveViewer?.allReceive?.edges[0]?.node?.contact?.refNumber
                                                    + " " + props.receiveViewer?.allReceive?.edges[0]?.node?.contact?.name
                                                  }
                                                } else {
                                                  contactName = 'ทั้งหมด';
                                                } 
                                                // text productAndService
                                                if( this.state.data.contact !== ""){
                                                    productAndService = props.receiveViewer?.allReceive?.edges[0]?.node?.receiveTransaction?.edges[0]?.node?.transaction?.productAndService?.productCode
                                                    + " " +props.receiveViewer?.allReceive?.edges[0]?.node?.receiveTransaction?.edges[0]?.node?.transaction.description;
                                                } else {
                                                  productAndService = 'ทั้งหมด';
                                                }  

                                                return (
                                                <React.Fragment>
                                                    <ReceiveReportPDFTable
                                                        props={props}
                                                        state={this.state.data}
                                                        status={this.state.data.status}
                                                        startDate={this.state.data.start_date}
                                                        endDate={this.state.data.end_date}
                                                        paymentChannel={paymentChannel}
                                                        contactName={contactName}
                                                        productAndService={productAndService}
                                                    />
                                                </React.Fragment>
                                                );
                                            } else {
                                                return <Loading/>
                                            }
                            }}
                        />
                    </div>
                    
                }

            </React.Fragment>
        )
    }
}

export default withRouter(ReceiveReportPDF)