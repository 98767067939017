import React from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import WrapperContent from '../../components/wrapper/wrapperContent'
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next'
import StockTopMenuNavigation from '../stockTopMenuNavigation'
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import ReportStockDetailTopmenu from './reportStockDetailTopmenu'
import DatePickerNoti from '../../accounting/finance/depositInterest/datePickerNoti'
import SearchSelect from '../../libs/searchSelect';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import ComponentPagination from "../../libs/componentPagination";
import _ from "lodash"
import { format } from 'date-fns'
import Loading from "../../libs/loading"
import ReportStockExcel from './documentReportStock/reportStockExcel'
import getNameResidential from "../../libs/getNameResidential"
import allInvoices from "../query/allInvoices"
import allOtherReceive from "../query/allOtherReceives"
import "./style/reportStock.scss"
import i18next from 'i18next'

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    width: 184,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF'
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

const query = graphql`
  query reportStockDetailQuery($productAndServiceId: String, $first: Int, $last: Int, $startDate: DateTime, $endDate: DateTime, $search: String, $listProduct: String){
    reportProduct(productAndServiceId: $productAndServiceId,type_In: "product", noNumber: true,  first: $first, last: $last){
      edges{
        node{
          id
          productCode
          name
          description
          chartOfAccount {
            id 
            name 
            nameEn 
            chartOfAccountCode
          }
        }
      }
    }

    conversionBalanceKeycardTransaction:reportKeycardTransaction(productAndServiceId: $productAndServiceId, startDate: $startDate, endDate: $endDate, search: $search, list: "conversion_balance"){
      edges{
        node{
          id
          date
          list
          ref
          requisitionList{
            edges{
              node{
                requisitionNote{
                  id
                  typeRequisition
                  typeNote
                  recipient
                  firstNameRecipient
                  lastNameRecipient
                  username
                  juristict{
                    id
                    firstName
                    lastName
                  }
                  contact{
                    id
                    name
                    firstName
                    lastName
                  }
                  requisitionNoteDocuments{
                    edges{
                      node{
                        id
                        fileName
                        fileUpload
                      }
                    }
                  }
                }
              }
            }
          }
          numberKeyTransaction{
            edges{
              node{
                id
                numberKey{
                  id
                  noNumber
                }
              }
            }
          }
        }
      }
      totalCount
    }

    keycardTransaction:reportKeycardTransaction(productAndServiceId: $productAndServiceId, startDate: $startDate, endDate: $endDate, search: $search, list_In: $listProduct){
      edges{
        node{
          id
          date
          list
          ref
          requisitionList{
            edges{
              node{
                requisitionNote{
                  id
                  typeRequisition
                  typeNote
                  recipient
                  firstNameRecipient
                  lastNameRecipient
                  username
                  juristict{
                    id
                    firstName
                    lastName
                  }
                  contact{
                    id
                    name
                    firstName
                    lastName
                  }
                  requisitionNoteDocuments{
                    edges{
                      node{
                        id
                        fileName
                        fileUpload
                      }
                    }
                  }
                }
              }
            }
          }
          numberKeyTransaction{
            edges{
              node{
                id
                numberKey{
                  id
                  noNumber
                }
              }
            }
          }
        }
      }
      totalCount
    }
    selfProject{
      name
    }
    myUser {
      id 
      juristic {
        id firstName lastName
      }
    }
  }
`

export default class reportStockDetail extends ComponentPagination {

  constructor(props) {
    super(props)

    this.state = {
      reportProductList: [],
      textSearch: "",
      startDate: null,
      endDate: null,
      keycardTransactionList: [],
      keycardTransactionExport: [],
      loading: false,
      urgentStatus: "",
      totalCount: 0,
      selfProject: {},
      myUser: ""
    }
  }

  componentWillMount() {
    this.goFirst()
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.statusTable !== prevProps.match.params.statusTable || prevState.first !== this.state.first || prevState.last !== this.state.last) {
      this.getData()
    }
    if (this.state.urgentStatus !== prevState.urgentStatus) {
      this.getData(true)
    }

  }

  getData = (update) => {
    this.setState({ loading: true })
    fetchQuery(
      environment,
      query,
      {
        productAndServiceId: this.props.match.params.id,
        listProduct: update
          ? this.state.urgentStatus
          : this.props.match.params.statusTable === "all" ? "buy,sell,unit,project" : this.props.match.params.statusTable,
        first: this.state.first,
        last: this.state.last,
        search: this.state.textSearch,
        startDate: this.state.startDate,
        endDate: this.state.endDate
      }
    ).then(async (res) => {
      let newkeycardTransaction = []
      await this.setReportProductList(newkeycardTransaction, res, "conversionbalance")
      await this.setReportProductList(newkeycardTransaction, res)

      this.setState({
        reportProductList: res.reportProduct.edges[0],
        keycardTransactionList: newkeycardTransaction,
        loading: false,
        keycardTransactionExport: newkeycardTransaction,
        totalCount: res.keycardTransaction.totalCount,
        selfProject: res.selfProject,
        myUser: res.myUser.juristic.firstName + " " + res.myUser.juristic.lastName
      })
    })
  }

  setReportProductList = async (newkeycardTransaction, res, type = "normal") => {

    if (type === 'normal') {

      if (res.keycardTransaction) {
        for (const item of res.keycardTransaction.edges) {
          if (item.node.numberKeyTransaction.edges.length > 0) {
            let contact = ""
            contact = await this.getNewContact(contact, item)

            for (const numberKeyTransaction of item.node.numberKeyTransaction.edges) {
              await newkeycardTransaction.push({
                node: {
                  date: item.node.date,
                  id: item.node.id,
                  list: item.node.list,
                  ref: item.node.ref,
                  requisitionList: item.node.requisitionList,
                  noNumber: numberKeyTransaction.node.numberKey.noNumber,
                  contact: contact,
                }
              })
            }

          } else {
            let contact = ""
            contact = await this.getNewContact(contact, item)

            await newkeycardTransaction.push({
              node: {
                date: item.node.date,
                id: item.node.id,
                list: item.node.list,
                ref: item.node.ref,
                requisitionList: item.node.requisitionList,
                noNumber: "-",
                contact: contact,
              }
            })

          }
        }
      }

    } else {

      if (res.conversionBalanceKeycardTransaction) {
        for (const item of res.conversionBalanceKeycardTransaction.edges) {
          if (item.node.numberKeyTransaction.edges.length > 0) {
            let contact = ""
            contact = await this.getNewContact(contact, item)
            if (item.node.numberKeyTransaction.edges.length === 1) {
              await newkeycardTransaction.push({
                node: {
                  date: item.node.date,
                  id: item.node.id,
                  list: item.node.list,
                  ref: item.node.ref,
                  requisitionList: item.node.requisitionList,
                  noNumber: item.node.numberKeyTransaction.edges[0].node.numberKey.noNumber,
                  contact: contact,
                }
              })
            } else {
              await newkeycardTransaction.push({
                node: {
                  date: item.node.date,
                  id: item.node.id,
                  list: item.node.list,
                  ref: item.node.ref,
                  requisitionList: item.node.requisitionList,
                  noNumber: item.node.numberKeyTransaction.edges[0].node.numberKey.noNumber + " - " + item.node.numberKeyTransaction.edges[item.node.numberKeyTransaction.edges.length - 1].node.numberKey.noNumber,
                  contact: contact,
                }
              })
            }
            // for (const numberKeyTransaction of item.node.numberKeyTransaction.edges) {
            //   await newkeycardTransaction.push({
            //     node: {
            //       date: item.node.date,
            //       id: item.node.id,
            //       list: item.node.list,
            //       ref: item.node.ref,
            //       requisitionList: item.node.requisitionList,
            //       noNumber: numberKeyTransaction.node.numberKey.noNumber,
            //       contact: contact,
            //     }
            //   })
            // }

          }
        }
      }

    }
    return newkeycardTransaction
  }

  getNewContact = async (contact, item) => {
    if (item.node.ref.substring(0, 2) === "IV") {
      await fetchQuery(environment, allInvoices, { search: item.node.ref }).then((res) => {
        contact = res.invoiceViewer.allInvoice.edges[0].node.contact
      })
    }
    if (item.node.ref.substring(0, 2) === "OR") {
      await fetchQuery(environment, allOtherReceive, { search: item.node.ref }).then((res) => {
        contact = res.allOtherReceive.edges[0].node.contact
      })
    }

    return contact;
  }

  getStatusStock = (textInput) => {
    let resultText = ""
    let bgColor = ""

    if (textInput === "ready") {
      resultText = i18next.t("stockCreateAndUpdate:Ready to use")
      bgColor = "statusGreen"
    } else if (textInput === "almost") {
      resultText = i18next.t("stockCreateAndUpdate:Nearly sold out")
      bgColor = "statusRed"
    } else if (textInput === "reachedPoint") {
      resultText = i18next.t("stockCreateAndUpdate:Ordering point")
      bgColor = "statusYellow"
    }

    return (<div className={`${bgColor}` + " ml-4"} style={{ width: 180 }}>
      <span>{resultText}</span>
    </div>)
  }

  handleChange = (e) => {
    let { name, value } = e.target

    this.setState({ [name]: value })
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.getData()
    }
  }

  handleSearch = () => {
    this.getData()
  }

  getStatusList = (inputStatusList) => {
    let result = ""

    switch (inputStatusList) {
      case "SELL":
        result = i18next.t("stockAndReport:Sell")
        break;
      case "BUY":
        result = i18next.t("stockAndReport:Buy")
        break;
      case "CONVERSION_BALANCE":
        result = i18next.t("keyofProduct:Total brought forward")
        break;
      case "UNIT":
        result = i18next.t("stockAndReport:Rights")
        break;
      case "PROJECT":
        result = i18next.t("stockAndReport:Requisition")
        break;
    }

    return <td >{result}</td>
  }

  render() {
    let arrUrl = this.props.match.url.split("/");
    let lastStatusTable = arrUrl.pop(); //ใช้ .pop() ก่อนถึงจะได้ผลลัพธ์ที่ .join()
    let urlTable = arrUrl.join("/");

    return (
      <Wrapper>
        <Sidebar />
        <Header />
        <WrapperContent disabledOverflowX={true}>
          <StockTopMenuNavigation mini={true} />

          <div className="container-fluid box" id="reportStockDetail">
            <div className="row">
              <div className="col">
                <Translation>
                  {
                    t =>
                      <h4 className="mb-4">
                        <Link to="/stockandsupplies/reportStock/list">
                          <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                            alt="back" className="back" />
                        </Link>
                        {t("keyofProduct:Stock and consumables report (sorted by number)")}
                      </h4>
                  }
                </Translation>

              </div>
            </div>

            <div className="content-inner">

              <div className="row mt-5">
                <div className="col-12 d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <h4>{i18next.t("stockAndReport:Product")} {this.state.reportProductList.node?.productCode} - {this.state.reportProductList.node?.name}</h4>
                    {this.getStatusStock(this.props.match.params.status)}
                  </div>
                  <div className="d-flex justify-content-end">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_numkey_print" }) &&
                      <Dropdown>
                        <DropdownToggle id="dropdown-item-button" bsPrefix='dropdown-export dropdown-toggle'>
                          {i18next.t("stockAndReport:Print")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <Dropdown.Item>
                            <Link
                              to={{
                                pathname: `/stockandsupplies/reportStock/document`,
                                state: {
                                  keycardTransactionExport: this.state.keycardTransactionExport,
                                  selfProject: this.state.selfProject,
                                  statusTable: this.props.match.params.statusTable,
                                  startDate: this.state.startDate,
                                  endDate: this.state.endDate,
                                  myUser: this.state.myUser
                                }
                              }}
                              className="text-black"
                            >
                              <p className="text-black">PDF</p>
                            </Link>
                          </Dropdown.Item>
                          <ReportStockExcel
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            selfProject={this.state.selfProject}
                            statusTable={this.props.match.params.statusTable}
                            keycardTransactionExport={this.state.keycardTransactionExport}
                          />
                        </DropdownMenu>
                      </Dropdown>
                    }
                  </div>
                </div>

                <div className="col-12 mt-4">
                  <span>{i18next.t("stockAndReport:Account type")} : {this.state.reportProductList.node?.chartOfAccount.chartOfAccountCode} - {this.state.reportProductList.node?.chartOfAccount.name}</span>
                </div>
                <div className="col-12">
                  <span>{i18next.t("stockAndReport:Description")} : {this.state.reportProductList.node?.description}</span>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12">
                  <ReportStockDetailTopmenu url={urlTable} />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-5 d-flex align-items-center">
                  <h5 className="mr-3">{i18next.t("stockAndReport:Show priority status")}:</h5>
                  <SearchSelect
                    onChange={this.handleChange}
                    value={this.state.urgentStatus}
                    name={"urgentStatus"}
                    placeholder={i18next.t("stockAndReport:List")}
                    queryObject={[
                      { node: { id :'', label : i18next.t("stockAndReport:All")}},
                      { node: { id :'sell', label : i18next.t("stockAndReport:Sell")}},
                      { node: { id :'buy', label : i18next.t("stockAndReport:Buy")}},
                      { node: { id :'conversion_balance', label : i18next.t("keyofProduct:Total brought forward")}},
                      { node: { id :'unit', label : i18next.t("stockAndReport:Rights")}},
                      { node: { id :'project', label : i18next.t("stockAndReport:Requisition")}},
                    ]}
                    keyOfValue="id" require={true}
                    keyOfLabel="label"
                    style={customStyles}
                  />
                </div>
                <div className="col-12 col-lg-7 d-flex justify-content-end">
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">{i18next.t("stockAndReport:Date")}:</h5>
                    <div
                      className="calendars-wrapper mx-4"
                      style={{ width: 160 }}
                    >
                      <DatePickerNoti
                        selected={this.state.startDate}
                        name='startDate'
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">{i18next.t("stockAndReport:to")}:</h5>
                    <div
                      className="calendars-wrapper mx-4"
                      style={{ width: 160 }}
                    >
                      <DatePickerNoti
                        selected={this.state.endDate}
                        name='endDate'
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="calendars-wrapper">
                    <form>
                      <input
                        type="text"
                        placeholder={i18next.t("stockAndReport:Search")}
                        name="textSearch"
                        value={this.state.textSearch}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        className="form-control inputSearch"
                      />
                    </form>
                    <div
                      className="calendars float-right"
                      style={{ paddingRight: 25 }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/search-outline.png"
                        }
                        alt="calendars"
                        style={{ width: "17.81px", height: "17.81px" }}
                        onClick={this.handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {this.state.loading ? <Loading />
                : <React.Fragment>
                  <div className="row mt-4">
                    <div className="col">
                      <div className="fade-up">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead className="thead-light">
                              <tr>
                                <th className="text-center">{i18next.t("stockAndReport:No.")}</th>
                                {this.props.match.params.statusTable === "all" &&
                                  <th>{i18next.t("stockAndReport:Type ")}</th>
                                }
                                <th>{i18next.t("stockAndReport:Date")}</th>
                                <th>{i18next.t("stockAndReport:Document")}</th>
                                <th>{i18next.t("stockAndReport:Requisition purpose")}</th>
                                <th>{i18next.t("stockAndReport:Room No.")}</th>
                                <th>{i18next.t("stockAndReport:Consignee name")}</th>
                                <th>{i18next.t("stockAndReport:Requisition by")}</th>
                                <th>{i18next.t("stockAndReport:Reference")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {_.map(this.state.keycardTransactionList, (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.node.noNumber || "-"}</td>
                                    {this.props.match.params.statusTable === "all" && this.getStatusList(item.node.list)}
                                    <td>{format(item.node.date, "DD/MM/YYYY")}</td>
                                    <td>{item.node.ref}</td>
                                    <td>
                                      {item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT"
                                        ? i18next.t("keyofProduct:Requisition use for rights")
                                        : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                                          ? i18next.t("keyofProduct:Requisition use for tenant")
                                          : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                                            ? i18next.t("keyofProduct:Requisition use for common area")
                                            : "-"
                                      }
                                    </td>
                                    <td>
                                      {(item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
                                        ? item.node.contact.name
                                        : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.name || "-"
                                      }
                                    </td>
                                    <td>
                                      {item.node.requisitionList.edges[0]?.node?.requisitionNote?.typeRequisition === "UNIT" && (item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient)
                                        ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.firstNameRecipient || "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.lastNameRecipient || "")
                                        : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "tenant"
                                          ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.firstName || "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.contact?.lastName || "")
                                          : item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeRequisition === "PROJECT" && _.lowerCase(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.typeNote) === "juristic"
                                            ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName || "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                                            : (item.node.ref.substring(0, 2) === "IV" || item.node.ref.substring(0, 2) === "OR")
                                              ? getNameResidential(item.node.contact.firstName || "", item.node.contact.lastName || "")
                                              : "-"
                                      }
                                    </td>
                                    <td>{(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName || item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName)
                                      ? getNameResidential(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.firstName || "", item.node.requisitionList?.edges[0]?.node?.requisitionNote?.juristict?.lastName || "")
                                      : "-"
                                    }</td>
                                    <td>{item.node.requisitionList?.edges[0]?.node?.requisitionNote?.requisitionNoteDocuments.edges.length > 0
                                      ?
                                      _.map(item.node.requisitionList?.edges[0]?.node?.requisitionNote?.requisitionNoteDocuments.edges, (itemImg, indexItemImg) => {
                                        let fileName = itemImg.node.fileName
                                        let typeFileName = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)

                                        return (
                                          <span key={indexItemImg} className="d-flex">
                                            {typeFileName === "pdf"
                                              ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="pdf" />
                                              : typeFileName === "png"
                                                ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pngIcon.png"} alt="png" />
                                                : (typeFileName === "jpeg" || typeFileName === "jpg") && <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/jpgIcon.png"} alt="jpeg" />
                                            }
                                            <Link
                                              to="route"
                                              onClick={(event) => {
                                                event.preventDefault();
                                                window.open(
                                                  typeof itemImg.node.fileUpload === "string"
                                                    ? itemImg.node.fileUpload
                                                    : URL.createObjectURL(itemImg.node.fileUpload)
                                                )
                                              }}
                                              target="blank">
                                              {fileName.length >= 15 ? fileName.substr(0, 15) : fileName}
                                            </Link>
                                            <br />
                                          </span>
                                        )
                                      })
                                      : "-"
                                    }
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                      <Pagination
                        changePage={this.changePage}
                        first={this.state.first}
                        last={this.state.last}
                        totalCount={this.state.totalCount}
                        />
                    </div> */}
                </React.Fragment>
              }

            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    )
  }
}
