/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type payableClassifiedReportChartTableQueryVariables = {|
  first?: ?number,
  last?: ?number,
  startMonth?: ?number,
  startYear?: ?number,
  endMonth?: ?number,
  endYear?: ?number,
  search?: ?string,
|};
export type payableClassifiedReportChartTableQueryResponse = {|
  +payableClassifiedSummary: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +chartOfAccountCode: string,
        +name: string,
        +col1Total: ?number,
        +col2Total: ?number,
        +col3Total: ?number,
        +col4Total: ?number,
        +col5Total: ?number,
        +col6Total: ?number,
        +col7Total: ?number,
        +col8Total: ?number,
        +col9Total: ?number,
        +col10Total: ?number,
        +col11Total: ?number,
        +col12Total: ?number,
        +colAllTotal: ?number,
        +col1Paid: ?number,
        +col2Paid: ?number,
        +col3Paid: ?number,
        +col4Paid: ?number,
        +col5Paid: ?number,
        +col6Paid: ?number,
        +col7Paid: ?number,
        +col8Paid: ?number,
        +col9Paid: ?number,
        +col10Paid: ?number,
        +col11Paid: ?number,
        +col12Paid: ?number,
        +colAllPaid: ?number,
        +col1NotPay: ?number,
        +col2NotPay: ?number,
        +col3NotPay: ?number,
        +col4NotPay: ?number,
        +col5NotPay: ?number,
        +col6NotPay: ?number,
        +col7NotPay: ?number,
        +col8NotPay: ?number,
        +col9NotPay: ?number,
        +col10NotPay: ?number,
        +col11NotPay: ?number,
        +col12NotPay: ?number,
        +colAllNotPay: ?number,
      |}
    |}>,
  |}
|};
export type payableClassifiedReportChartTableQuery = {|
  variables: payableClassifiedReportChartTableQueryVariables,
  response: payableClassifiedReportChartTableQueryResponse,
|};
*/


/*
query payableClassifiedReportChartTableQuery(
  $first: Int
  $last: Int
  $startMonth: Int
  $startYear: Int
  $endMonth: Int
  $endYear: Int
  $search: String
) {
  payableClassifiedSummary(startMonth: $startMonth, startYear: $startYear, first: $first, last: $last, search: $search, endMonth: $endMonth, endYear: $endYear) {
    totalCount
    edges {
      node {
        id
        chartOfAccountCode
        name
        col1Total
        col2Total
        col3Total
        col4Total
        col5Total
        col6Total
        col7Total
        col8Total
        col9Total
        col10Total
        col11Total
        col12Total
        colAllTotal
        col1Paid
        col2Paid
        col3Paid
        col4Paid
        col5Paid
        col6Paid
        col7Paid
        col8Paid
        col9Paid
        col10Paid
        col11Paid
        col12Paid
        colAllPaid
        col1NotPay
        col2NotPay
        col3NotPay
        col4NotPay
        col5NotPay
        col6NotPay
        col7NotPay
        col8NotPay
        col9NotPay
        col10NotPay
        col11NotPay
        col12NotPay
        colAllNotPay
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endMonth"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endYear"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startMonth"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startYear"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endMonth",
        "variableName": "endMonth"
      },
      {
        "kind": "Variable",
        "name": "endYear",
        "variableName": "endYear"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startMonth",
        "variableName": "startMonth"
      },
      {
        "kind": "Variable",
        "name": "startYear",
        "variableName": "startYear"
      }
    ],
    "concreteType": "ChartOfAccountNodeConnection",
    "kind": "LinkedField",
    "name": "payableClassifiedSummary",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartOfAccountNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chartOfAccountCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col1Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col2Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col3Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col4Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col5Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col6Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col7Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col8Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col9Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col10Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col11Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col12Total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colAllTotal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col1Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col2Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col3Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col4Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col5Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col6Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col7Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col8Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col9Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col10Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col11Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col12Paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colAllPaid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col1NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col2NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col3NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col4NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col5NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col6NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col7NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col8NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col9NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col10NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col11NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "col12NotPay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colAllNotPay",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "payableClassifiedReportChartTableQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "payableClassifiedReportChartTableQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "26d4cf6a36023639299aa78069d32435",
    "id": null,
    "metadata": {},
    "name": "payableClassifiedReportChartTableQuery",
    "operationKind": "query",
    "text": "query payableClassifiedReportChartTableQuery(\n  $first: Int\n  $last: Int\n  $startMonth: Int\n  $startYear: Int\n  $endMonth: Int\n  $endYear: Int\n  $search: String\n) {\n  payableClassifiedSummary(startMonth: $startMonth, startYear: $startYear, first: $first, last: $last, search: $search, endMonth: $endMonth, endYear: $endYear) {\n    totalCount\n    edges {\n      node {\n        id\n        chartOfAccountCode\n        name\n        col1Total\n        col2Total\n        col3Total\n        col4Total\n        col5Total\n        col6Total\n        col7Total\n        col8Total\n        col9Total\n        col10Total\n        col11Total\n        col12Total\n        colAllTotal\n        col1Paid\n        col2Paid\n        col3Paid\n        col4Paid\n        col5Paid\n        col6Paid\n        col7Paid\n        col8Paid\n        col9Paid\n        col10Paid\n        col11Paid\n        col12Paid\n        colAllPaid\n        col1NotPay\n        col2NotPay\n        col3NotPay\n        col4NotPay\n        col5NotPay\n        col6NotPay\n        col7NotPay\n        col8NotPay\n        col9NotPay\n        col10NotPay\n        col11NotPay\n        col12NotPay\n        colAllNotPay\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ba8ffc0a7a2f84bc1d200c5323aec88';

module.exports = node;
