import React, { Component } from 'react'
import numberWithCommas from "../../../libs/numberWithComma";
import {format} from 'date-fns'
import { Translation } from 'react-i18next';

import './style/chequePrintCenter.scss'

const ThaiBaht = require('thai-baht-text');

export default class chequeTransactionPrintCenter extends Component {
    constructor(props){
        super(props);
        this.chequeBank = this.chequeBank.bind(this)
        this.getThaiYear = this.getThaiYear.bind(this)
        this.state = {
            holderName: props.holderName,
            payerName: props.setFirstName !== "" && props.setLastName !== "" ? props.setFirstName+" "+props.setLastName : props.cheque.node.payName
          };
        }
        componentDidUpdate(prevProps) {
          if (prevProps.holderName !== this.props.holderName || prevProps.setFirstName !== this.props.setFirstName || prevProps.setLastName !== this.props.setLastName) {
            const payerName = this.props.setFirstName !== "" ||  this.props.setLastName !== "" ? this.props.setFirstName+" "+this.props.setLastName : this.props.cheque.node.payName;
            this.props.onPayerNameChange(payerName);
            this.setState({ payerName });
          }
        }
        componentDidMount() {
          const payerName = this.state.payerName;
          this.props.onPayerNameChange(payerName);
        }
    chequeBank(bank){
        switch(bank){
            case 'ธนาคารกสิกรไทย' :
                return 'cheque-kbank';
            case 'ธนาคารออมสิน' :
                return 'cheque-gsb';
            case 'ธนาคารไทยพาณิชย์' :
                return 'cheque-scb';
            case 'ธนาคารกรุงเทพ' :
                return 'cheque-bbl';
            case 'ธนาคารกรุงศรีอยุธยา' :
                return 'cheque-bay';
            default :
                return 'cheque-scb'
        }
    }

    getThaiYear(year){
        let year_thai = parseInt(year) + 543
        return (year_thai).toString()
    }

    render(){
        let cheque = this.props.cheque.node
        // // check ถ้า ความยาว text เกิน .... จะ add class
        // const checkLengthDefualt = cheque.payName.length > 40? 'fontFourteen': '';
        // const checkLength = (this.props.setFirstName + this.props.setLastName).length > 40  ? 'fontFourteen' : '';
        return (
            <div id="chequeTrasactionCenter" style={{marginTop: `${this.props.sideTop}cm`}}>
                <div className ="print-page-a4-cheque" id="cheque-transaction" key={this.props.index}>
                    <div className={this.chequeBank(cheque.bankAccount.bankName)} style={{overflow: this.props.totalCount > 1 ? "" : "hidden"}}>
                        {this.props.isACPayee &&
                            <div className="ac-payee-only">
                                <p>A/C PAYEE ONLY</p>
                            </div>
                        }
                        {
                            <div className="row date">
                                {this.props.showDate &&  
                                    (this.props.showDatePicker && 
                                        this.props.selectedDate ?
                                        <p>{format(this.props.selectedDate,'DDMM') + this.getThaiYear(format(this.props.selectedDate,'YYYY'))}</p>
                                        :
                                        <p>{format(cheque.date,'DDMM') + this.getThaiYear(format(cheque.date,'YYYY'))}</p>
                                        )
                                }
                            </div>
                        }
                        {this.props.isCrossOut &&
                            <div className="cross-out">
                                <p>    </p>
                            </div>
                        }
                        <div className="row pay-name">
                        {this.props.setFirstName === "" &&  this.props.setLastName === "" ?  <p>{cheque.payName}</p> : <p>{this.props.setFirstName} {this.props.setLastName}</p>}
                        {/* 
                        {this.props.setFirstName === "" &&  this.props.setLastName === "" ?  <p className={`${checkLengthDefualt}`}>{cheque.payName}</p> 
                        : <p className={`${checkLength}`}>{this.props.setFirstName} {this.props.setLastName}</p>} */}
                        </div>
                        <div className="row description">
                            <p>**{ThaiBaht(cheque.price)}**</p>
                        
                        </div>
                        <div className="row price">
                            <p>**{numberWithCommas(cheque.price)}**</p>
                        </div>
                        
                    </div>
                    <div className="cheque-number mt-2">
                        <Translation>{t=><p><strong>{t("cheque_transaction:Cheque No.")} : {cheque.chequeNumber} </strong> ({t("cheque_transaction:For check, not showing on your cheque")})  </p>}</Translation>

                    </div>
                </div>
            </div>
            
        );
    }
}
