
import React from "react";
import getApiRoot from "../../../libs/getAPIRoot";
import axios from "axios";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import { format } from "date-fns";

class ExportGeneralLedgerExcel extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading: false };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = async () => {
        this.setState({
            loading: true
        })
        let timerInterval
        Swal.fire({
            type: 'warning',
            title: 'ข้อความแจ้งเตือน',
            html: '<h2>ระบบกำลังประมวลผล กรุณารอสักครู่!</h2>',
            timer: 10000,
            onBeforeOpen: () => { Swal.showLoading() },
            onClose: () => { clearInterval(timerInterval) }
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                return null;
            }
        })

        if (!this.state.loading) {
            var token_id = localStorage.getItem("token");
            var header = {
                authorization: `JWT ${token_id}`,
                "Content-Type": "application/json",
            };
            var data = {
                "start_date": format(this.props.state.start_date, 'DD/MM/YYYY'),
                "end_date": format(this.props.state.end_date, 'DD/MM/YYYY'),
                "chart_of_account_start": this.props.state.chart_of_account_start,
                "chart_of_account_end": this.props.state.chart_of_account_end,
                "currentRecord": this.props.state.currentRecord,
                "data": this.props.state.data,
    
            }
            await axios.post(`${getApiRoot()}export/export_general_ledger_excel`, data , {
                headers: header,
                responseType: 'arraybuffer',
            })
                //print pdf not worker or job_runner
                .then((response) => {
                    const currentDate = new Date();
                    const formattedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
                    const fileName = `general_ledger${formattedDate}.xlsx`;
                
                    // Create a Blob from the response data
                    const blob = new Blob([response.data], { type: 'application/octet-stream' });
                
                    // Create a temporary URL using createObjectURL
                    const url = window.URL.createObjectURL(blob);
                
                    // Create an anchor element to trigger the download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                
                    // Clean up
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                
                    this.setState({
                      loading: false,
                    });
                  })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });
                })
        }
    };

    render() {
        return (
            <button onClick={this.handleSubmit} type="button" className="dropdown-item" 
                disabled={this.state.loading}>
                {this.state.loading ?
                    <span>
                        <span className="spinner-border spinner-border-sm align-middle mr-2" /> <Translation>{t => t('invoice:Please wait a moment.')}</Translation></span> :

                    <span>Excel</span>
                }
            </button>
        )
    }
}

export default ExportGeneralLedgerExcel;
