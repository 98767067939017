import '../styles/main.scss';
import '../styles/modify_print.scss'
import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import getNameResidential from "../../libs/getNameResidential";
import _ from "lodash";
import AccountRecord from './accountRecord';
import PurchaseRecord from './purchaseRecord';
import jwtDecode from "jwt-decode";
import i18n from 'i18next'
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';

const ThaiBaht = require('thai-baht-text');

class Purchase extends Component {

    constructor(props) {
        super(props);

        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];

        this.state = {
            bangkokCode: bangkokCode,
            sum_pre_tax: 0,
            sum_vat: 0,
            sum_amount: 0,
            sum_all: 0,
            sum_wht_rate: 0,
            sum_grand_total: 0,
            prepaidDeposit: 0,
            project: atob(this.props.query.selfProject.id).split(":")[1],

            have_vat: "",
            no_vat: "",
            have_wht_rate_percent: "",
            no_wht_rate_percent: "",
            discount_status: "",
            setDiff: 0,
            token: jwtDecode(window.localStorage.getItem('token')),
            languages: "th",
        };
        this.calSummary = this.calSummary.bind(this);
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.allStatus = this.allStatus.bind(this);
    }

    componentWillMount() {
        this.calSummary();
        this.allStatus();
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
        })
        i18n.changeLanguage(this.props.languages);
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let chart = page.find('.chart-of-account').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = (page_height - (head + detail + invoice + chart + signature)) / 2;
            this.setState({setDiff: diff})
            $('tbody tr:last-child td').css({ paddingBottom: diff });
        }, 200);
    }

    allStatus() {
        if (this.props.query.purchaseRecordGroup.purchaseRecord.edges.length > 0) {
            //หัก ณ ที่จ่าย
            if (this.props.query.purchaseRecordGroup.purchaseRecord.edges[0].node.whtRatePercent !== 0) {
                this.setState({
                    have_wht_rate_percent: true,
                    no_wht_rate_percent: false
                })
            } else if (this.props.query.purchaseRecordGroup.purchaseRecord.edges[0].node.whtRatePercent === 0) {
                this.setState({
                    no_wht_rate_percent: true,
                    have_wht_rate_percent: false
                })
            }
            //หัก ณ ที่จ่าย

            //vat
            if (this.props.query.purchaseRecordGroup.purchaseRecord.edges[0].node.vat !== null) {
                this.setState({
                    have_vat: true,
                    no_vat: false
                })
            } else if (this.props.query.purchaseRecordGroup.purchaseRecord.edges[0].node.vat === null) {
                this.setState({
                    no_vat: true,
                    have_vat: false
                })
            }
            //vat

            // discount
            if (this.props.query.purchaseRecordGroup.purchaseRecord.edges[0].node.discount !== 0) {
                this.setState({ discount_status: true })
            } else {
                this.setState({ discount_status: false })
            }
            //discount
        }
    }

    calSummary() {
        let amount = 0; //จน.ของ * ราคา
        let sumAmount = 0; // amountทั้งหมด
        let discount = 0; //ส่วนลด
        let sumDiscount = 0; //ส่วนลดทั้งหมด
        let prepaidDeposit = 0; //จ่ายล่วงหน้า
        let sumVat = 0;//VAT ทั้งหมด
        let sumTotal = 0;// รวมหลังหักจ่ายล่วงหน้า + VAT ทั้งหมด
        let sumGrandTotal = 0; // รวมหลังหักจ่ายล่วงหน้า + VAT ทั้งหมด - WHT
        let sumTotalAmount = 0;

        this.props.query.purchaseRecordGroup.purchaseRecord.edges.forEach((purchase) => {
            amount = parseFloat(purchase.node.preTaxAmount);
            sumAmount += parseFloat(amount);
            discount += parseFloat(purchase.node.discount);
            sumDiscount += amount - parseFloat(purchase.node.discount);

            purchase.node.prepaidDepositTransactionUsed.edges.forEach((prepaid_transaction) => {
                prepaidDeposit += prepaid_transaction.node.amount;
            });

            //คำนวนvat
            sumVat = sumVat + purchase.node.vatAmount

            //คำนวนจำนวนเงินรวม
            sumTotal = sumAmount + sumVat

            //Total Prepaid Deposit
            sumTotalAmount = sumTotal - prepaidDeposit;
            
            //คำนวนเงินรวมทั้งสิ้น
            sumGrandTotal = sumTotalAmount

        });

        this.setState({
            sum_vat: sumVat.toFixed(2),
            sum_amount: sumAmount.toFixed(2),
            sum_total: sumTotal.toFixed(2),
            sum_grand_total: sumGrandTotal.toFixed(2),
            prepaidDeposit: prepaidDeposit.toFixed(2),
            sum_discount: sumDiscount.toFixed(2),
            discount: discount.toFixed(2),

            sum_total_amount: sumTotalAmount.toFixed(2),
        });
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "district") {
            return "ต."
        } else if (type === "city") {
            return "อ."
        }
    }

    sortByaddedDebit() {
        
        const sort_desc = _.orderBy(this.props.query.purchaseAccountRecordGroup.edges[0]?.node.accountRecord.edges, 'node.added', 'desc');
        const sort_purchaseAccountRecordGroup = _.filter(sort_desc, (purchaseAccountRecordGroup) => purchaseAccountRecordGroup.node.debit > 0);
        let purchaseAccountRecordGroup_debit = [];
        let chartOfAccountCode_debitName = null
        let cal_debit = 0;
        let name_debit = "";
        let chartOfAccountCode_debit = null;
        let id_debit = null;
        sort_purchaseAccountRecordGroup.forEach((sort, index) => {
            if (chartOfAccountCode_debit) {
                if (chartOfAccountCode_debit == sort.node.chartOfAccountCode.chartOfAccountCode) {
                    cal_debit += sort.node.debit;
                    if (sort_purchaseAccountRecordGroup.length === index + 1) {
                        id_debit = sort.node.id;
                        name_debit = sort.node.name;
                        chartOfAccountCode_debit = sort.node.chartOfAccountCode.chartOfAccountCode;
                        chartOfAccountCode_debitName = sort.node.chartOfAccountCode.name;
                        const input = {
                            id: id_debit,
                            name: name_debit,
                            chartOfAccountCode: chartOfAccountCode_debit,
                            chartOfAccountCodeName: chartOfAccountCode_debitName,
                            debit: cal_debit
                        }
                        purchaseAccountRecordGroup_debit.push(input);
                        //clean
                        cal_debit = 0;
                    }
                }else {
                    let input = {
                        id: id_debit,
                        name: name_debit,
                        chartOfAccountCode: chartOfAccountCode_debit,
                        chartOfAccountCodeName: chartOfAccountCode_debitName,
                        debit: cal_debit
                    }
                    purchaseAccountRecordGroup_debit.push(input);

                    //clean
                    if (sort_purchaseAccountRecordGroup.length === index + 1) {

                        id_debit = sort.node.id;
                        cal_debit = sort.node.debit;
                        name_debit = sort.node.name;
                        chartOfAccountCode_debit = sort.node.chartOfAccountCode.chartOfAccountCode;
                        chartOfAccountCode_debitName =  sort.node.chartOfAccountCode.name
                        input = {
                            id: id_debit,
                            name: name_debit,
                            chartOfAccountCode: chartOfAccountCode_debit,
                            chartOfAccountCodeName: chartOfAccountCode_debitName,
                            debit: cal_debit
                        }
                        purchaseAccountRecordGroup_debit.push(input);
                    } else {
                        id_debit = sort.node.id;
                        cal_debit = sort.node.debit;
                        name_debit = sort.node.name;
                        chartOfAccountCode_debit = sort.node.chartOfAccountCode.chartOfAccountCode;
                        chartOfAccountCode_debitName =  sort.node.chartOfAccountCode.name
                    }

                }

            }
            else if (sort_purchaseAccountRecordGroup.length === index + 1) {
                id_debit = sort.node.id;
                cal_debit += sort.node.debit;
                name_debit = sort.node.name;
                chartOfAccountCode_debit = sort.node.chartOfAccountCode.chartOfAccountCode;
                chartOfAccountCode_debitName =  sort.node.chartOfAccountCode.name
                const input = {
                    id: id_debit,
                    name: name_debit,
                    chartOfAccountCode: chartOfAccountCode_debit,
                    chartOfAccountCodeName: chartOfAccountCode_debitName,
                    debit: cal_debit
                }
                purchaseAccountRecordGroup_debit.push(input);

            } else {
                id_debit = sort.node.id;
                cal_debit += sort.node.debit;
                name_debit = sort.node.name;
                chartOfAccountCode_debit = sort.node.chartOfAccountCode.chartOfAccountCode;
                chartOfAccountCode_debitName =  sort.node.chartOfAccountCode.name
            }
        })
        return purchaseAccountRecordGroup_debit;
    }

    sortByaddedCredit() {
        const sort_desc = _.orderBy(this.props.query.purchaseAccountRecordGroup.edges[0]?.node.accountRecord.edges, 'node.added', 'desc');
        const sort_purchaseAccountRecordGroup = _.filter(sort_desc, (purchaseAccountRecordGroup) => purchaseAccountRecordGroup.node.credit > 0);
        let purchaseAccountRecordGroup_credit = [];
        let cal_credit = 0;
        let name_credit = "";
        let chartOfAccountCode_credit = null;
        let chartOfAccountCode_debitName = null
        let id_credit = null;
        sort_purchaseAccountRecordGroup.forEach((sort, index) => {
            if (chartOfAccountCode_credit) {
                if (chartOfAccountCode_credit == sort.node.chartOfAccountCode.chartOfAccountCode) {
                    cal_credit += sort.node.credit;

                    if (sort_purchaseAccountRecordGroup.length === index + 1) {
                        id_credit = sort.node.id;
                        name_credit = sort.node.name;
                        chartOfAccountCode_credit = sort.node.chartOfAccountCode.chartOfAccountCode;
                        chartOfAccountCode_debitName  = sort.node.chartOfAccountCode.name;
                        const input_last = {
                            id: id_credit,
                            name: name_credit,
                            chartOfAccountCode: chartOfAccountCode_credit,
                            chartOfAccountCodeName : chartOfAccountCode_debitName,
                            credit: cal_credit
                        }
                        purchaseAccountRecordGroup_credit.push(input_last);
                    }
                }
                else {
                    let input = {
                        id: id_credit,
                        name: name_credit,
                        chartOfAccountCode: chartOfAccountCode_credit,
                        chartOfAccountCodeName : chartOfAccountCode_debitName,
                        credit: cal_credit
                    }
                    purchaseAccountRecordGroup_credit.push(input);

                    //clean
                    if (sort_purchaseAccountRecordGroup.length === index + 1) {

                        id_credit = sort.node.id;
                        cal_credit = sort.node.credit;
                        name_credit = sort.node.name;
                        chartOfAccountCode_credit = sort.node.chartOfAccountCode.chartOfAccountCode;
                        chartOfAccountCode_debitName  = sort.node.chartOfAccountCode.name;

                        input = {
                            id: id_credit,
                            name: name_credit,
                            chartOfAccountCode: chartOfAccountCode_credit,
                            chartOfAccountCodeName : chartOfAccountCode_debitName,
                            credit: cal_credit
                        }
                        purchaseAccountRecordGroup_credit.push(input);
                    } else {
                        id_credit = sort.node.id;
                        cal_credit = sort.node.credit;
                        name_credit = sort.node.name;
                        chartOfAccountCode_credit = sort.node.chartOfAccountCode.chartOfAccountCode;
                        chartOfAccountCode_debitName  = sort.node.chartOfAccountCode.name;
                    }

                }
            }
            //มีตัวเดียว
            else if (sort_purchaseAccountRecordGroup.length === index + 1) {
                id_credit = sort.node.id;
                cal_credit += sort.node.credit;
                name_credit = sort.node.name;
                chartOfAccountCode_credit = sort.node.chartOfAccountCode.chartOfAccountCode;
                chartOfAccountCode_debitName  = sort.node.chartOfAccountCode.name;

                const input_last = {
                    id: id_credit,
                    name: name_credit,
                    chartOfAccountCode: chartOfAccountCode_credit,
                    chartOfAccountCodeName : chartOfAccountCode_debitName,
                    credit: cal_credit
                }
                purchaseAccountRecordGroup_credit.push(input_last);

            }
            //ทำแค่ครั้งแรก
            else {
                id_credit = sort.node.id;
                cal_credit += sort.node.credit;
                name_credit = sort.node.name;
                chartOfAccountCode_credit = sort.node.chartOfAccountCode.chartOfAccountCode;
                chartOfAccountCode_debitName  = sort.node.chartOfAccountCode.name;
            }
        })
        return purchaseAccountRecordGroup_credit;
    }

    render() {
        const purchaseAccountRecordGroup_debit = this.sortByaddedDebit();
        const purchaseAccountRecordGroup_credit = this.sortByaddedCredit();
        if(this.state.setDiff >= 0){
        return (
            <React.Fragment>
                {/*{["original", "copy"].map((paper_type, index) => {*/}
                {["original"].map((paper_type, index) => {
                    return (
                        <div id="style-modify" className="print-page-a4" key={index}>
                            <div className="subpage">
                                <div className="head">
                                    <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                                        <div style={{ minHeight: 100, minWidth: 100 }}>
                                            <img
                                                src={this.props.query.selfProject.logo}
                                                alt="silverman" />
                                        </div>
                                        <div>
                                            <div className="title">                                                
                                                <strong>{this.state.languages === "en" ? (this.props.query.selfProject.nameEn || this.props.query.selfProject.name) : this.props.query.selfProject.name}</strong>
                                            </div>
                                            <div
                                                className="title">{this.state.languages === "en" ? (this.props.query.selfProject.addressEn || this.props.query.selfProject.address) : this.props.query.selfProject.address} โทร {this.props.query.selfProject.juristicContactNumber || '-'}
                                            </div>
                                            <div>เลขประจำตัวผู้เสียภาษี {this.props.query.selfProject.taxIdNumber || '-'}</div>
                                        </div>

                                    </div>

                                    <div className="document-name">
                                        <div className="title">ใบตั้งค่าใช้จ่าย</div>
                                        <div className="title">Expense</div>
                                        {
                                            paper_type === "original" ?
                                                <div className="title small">(ต้นฉบับ/Original)</div>
                                                :
                                                <div className="title small">(สำเนา/Copy)</div>
                                        }
                                    </div>
                                </div>

                                <div className="detail">
                                    <div className="customer-detail">
                                        <strong>รหัสเจ้าหนี้/ID:</strong> {this.props.query.purchaseRecordGroup.contact.refNumber}
                                        <br />
                                        {this.props.query.purchaseRecordGroup.contact.typeOfContact === 'RESIDENTIAL' ?
                                            <React.Fragment>
                                                <strong>ชื่อลูกค้า/Customer:</strong>
                                                {getNameResidential(this.props.query.purchaseRecordGroup.contact.firstName, this.props.query.purchaseRecordGroup.contact.lastName)}
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <strong>ชื่อลูกค้า/Customer:</strong> {this.props.query.purchaseRecordGroup.contact.name}
                                            </React.Fragment>
                                        }
                                        <br />
                                        <strong>ที่อยู่/Address:</strong>

                                        <div className="customer-detail address-space">
                                            {/* {this.props.query.purchaseRecordGroup.contact.registeredAddress + " "}
                                            {this.props.query.purchaseRecordGroup.contact.registeredDistrict && this.getPrefix("district", this.props.query.purchaseRecordGroup.contact.registeredPostalCode) + this.props.query.purchaseRecordGroup.contact.registeredDistrict + " "}
                                            {this.props.query.purchaseRecordGroup.contact.registeredCity && this.getPrefix("city", this.props.query.purchaseRecordGroup.contact.registeredPostalCode) + this.props.query.purchaseRecordGroup.contact.registeredCity + " "}
                                            {this.props.query.purchaseRecordGroup.contact.registeredProvince && this.getPrefix("province", this.props.query.purchaseRecordGroup.contact.registeredPostalCode) + this.props.query.purchaseRecordGroup.contact.registeredProvince + " "}
                                            {this.props.query.purchaseRecordGroup.contact.registeredPostalCode + " "} */}

                                            {/* BTA685 new rq */}
                                            {this.props.query.purchaseRecordGroup?.address + " "}
                                            {this.props.query.purchaseRecordGroup?.district && this.getPrefix("district", this.props.query.purchaseRecordGroup.postalCode) + this.props.query.purchaseRecordGroup.district + " "}
                                            {this.props.query.purchaseRecordGroup?.city && this.getPrefix("city", this.props.query.purchaseRecordGroup.postalCode) + this.props.query.purchaseRecordGroup.city + " "}
                                            {this.props.query.purchaseRecordGroup?.province && this.getPrefix("province", this.props.query.purchaseRecordGroup.postalCode) + this.props.query.purchaseRecordGroup.province + " "}
                                            {this.props.query.purchaseRecordGroup?.postalCode + " "}

                                            {
                                                (this.props.query.purchaseRecordGroup.contact.typeOfSupplier === "PUBLIC_COMPANY" || this.props.query.purchaseRecordGroup.contact.typeOfSupplier === "COMPANY") &&
                                                <React.Fragment>
                                                    {
                                                        this.props.query.purchaseRecordGroup.contact.typeOfCompany === "HEADQUARTERS" ?
                                                            "สำนักงานใหญ่"
                                                            : this.props.query.purchaseRecordGroup.contact.typeOfCompany === "BRANCH" &&
                                                            "สาขา " + this.props.query.purchaseRecordGroup.contact.nameBranch
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                        <br />
                                        <strong>เลขผู้เสียภาษี/Tax
                                            ID:</strong> {this.props.query.purchaseRecordGroup.contact.taxIdNumber}
                                        <br />
                                        <strong>เลขที่ใบกำกับ/Attention:</strong> {this.props.query.purchaseRecordGroup.taxInvoiceNumber}
                                        <span
                                            className="tax-invoice-date">{this.props.query.purchaseRecordGroup.taxInvoiceNumber ? 'ลวท.' + format(this.props.query.purchaseRecordGroup.taxInvoiceDate, 'DD/MM/YYYY', { locale: thLocale })
                                                :
                                                this.props.query.purchaseRecordGroup.taxInvoiceNumber ? 'ลวท.' + format(this.props.query.purchaseRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })
                                                    : null}</span>
                                        <br />
                                    </div>
                                    <div className="document-detail">
                                        <strong>เลขที่/No:</strong> {this.props.query.purchaseRecordGroup.docNumber}
                                        <br />
                                        <strong>วันที่/Date:</strong> {format(this.props.query.purchaseRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}
                                        <br />
                                        <strong>ครบกำหนด/Due
                                            Date:</strong> {format(this.props.query.purchaseRecordGroup.dueDate, 'DD/MM/YYYY', { locale: thLocale })}
                                        <br />
                                    </div>
                                </div>

                                <div className="invoice-body">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width={50} className="text-center">
                                                    ลำดับ <br />
                                                No.
                                            </th>
                                                <th width={60} className="text-center">
                                                    รหัส <br />
                                                Code
                                            </th>
                                                <th className="text-center">
                                                    รายละเอียด <br />
                                                Description
                                            </th>
                                                <th width={100} className="text-center">
                                                    จำนวนหน่วย <br />
                                                Unit
                                            </th>
                                                <th width={100} className="text-center">
                                                    ราคาต่อหน่วย <br />
                                                Unit Price
                                            </th>
                                                <th width={100} className="text-center">
                                                    จำนวนเงิน <br />
                                                Amount
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.query.purchaseRecordGroup.purchaseRecord.edges.map((purchase, index) =>
                                                <tr key={purchase.node.id}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{purchase.node.productAndService ? purchase.node.productAndService.productCode : purchase.node.chartOfAccount.chartOfAccountCode}</td>
                                                    <td>{purchase.node.description}</td>
                                                    <td className="text-right">{numberWithComma(purchase.node.unitItems)}</td>
                                                    <td className="text-right">{numberWithComma(purchase.node.price)}</td>
                                                    <td className="text-right">{numberWithComma(purchase.node.price * purchase.node.unitItems)}</td>
                                                </tr>
                                            )}

                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={3} rowSpan={
                                                    (this.state.no_vat && this.state.no_wht_rate_percent && !this.state.discount_status) ? 2 :
                                                        (
                                                            (this.state.no_vat && this.state.no_wht_rate_percent && this.state.discount_status) ||
                                                            (this.state.no_vat && this.state.have_wht_rate_percent && !this.state.discount_status) ||
                                                            (this.state.have_vat && this.state.no_wht_rate_percent && !this.state.discount_status)
                                                        ) ? 4 :
                                                            (
                                                                (this.state.have_vat && this.state.no_wht_rate_percent && this.state.discount_status) ||
                                                                (this.state.no_vat && this.state.have_wht_rate_percent && this.state.discount_status) ||
                                                                (this.state.have_vat && this.state.have_wht_rate_percent && !this.state.discount_status)
                                                            ) ? 5 :
                                                                this.state.have_vat && this.state.have_wht_rate_percent && this.state.discount_status && 8
                                                } />
                                                <td colSpan={2} className="font-weight-bold">
                                                    จำนวนเงิน /Amount
                                            </td>
                                                <td className="text-right font-weight-bold">
                                                    {numberWithComma(this.state.sum_amount, 0)}
                                                </td>
                                            </tr>

                                            {
                                                this.state.discount_status &&
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <strong>หัก&nbsp;</strong>ส่วนลด/Discount
                                                    </td>
                                                        <td className="text-right">
                                                            {numberWithComma(this.state.discount, 0)}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan={2} className="font-weight-bold">
                                                            จำนวนเงินหลังหักส่วนลด/Total Discount
                                                    </td>
                                                        <td className="text-right font-weight-bold">
                                                            {numberWithComma(this.state.sum_discount, 0)}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            }

                                            {
                                                ((this.state.have_vat && this.state.no_wht_rate_percent) ||
                                                    (this.state.have_vat && this.state.have_wht_rate_percent)) &&
                                                <tr>
                                                    <td colSpan={2} className="font-weight-bold">
                                                        ภาษีมูลค่าเพิ่ม (บาท) /VAT
                                                </td>
                                                    <td className="text-right font-weight-bold">
                                                        {numberWithComma(this.state.sum_vat, 0)}
                                                    </td>
                                                </tr>
                                            }

                                            {
                                                this.state.have_wht_rate_percent && this.state.have_vat  &&
                                                <tr>
                                                    <td colSpan={2} className="font-weight-bold">
                                                        จำนวนเงินรวม/Total cash
                                                </td>
                                                    <td className="text-right font-weight-bold">
                                                        {numberWithComma(this.state.sum_total, 0)}
                                                    </td>
                                                </tr>
                                            }

                                            <tr>
                                                <td colSpan={2} className="font-weight-bold">
                                                    <strong>หัก&nbsp;จ่ายชำระล่วงหน้า/Prepaid Deposit</strong>
                                            </td>
                                                <td className="text-right font-weight-bold">
                                                    {numberWithComma(this.state.prepaidDeposit, 0)}
                                                </td>
                                            </tr>

                                            {/* {
                                                (this.state.have_vat || this.state.have_wht_rate_percent) &&
                                                <tr>
                                                    <td colSpan={2}>
                                                        จำนวนเงินหลังหักจ่ายชำระล่วงหน้า/Total Prepaid Deposit
                                                </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.state.sum_total_amount, 0)}
                                                    </td>
                                                </tr>
                                            } */}

                                            {
                                                // this.state.have_wht_rate_percent &&
                                                // <tr>
                                                //     <td colSpan={2}>
                                                //         <strong>หัก&nbsp;</strong>ภาษีหัก ณ ที่จ่าย/With holding tax
                                                // </td>
                                                //     <td className="text-right">
                                                //         {/*{numberWithComma(this.state.sum_wht_rate, 0)}*/}
                                                //         {numberWithComma(this.state.sum_total_wht_rate, 0)}
                                                //     </td>
                                                // </tr>
                                            }
                                            <tr />
                                            <tr />

                                            <tr>
                                                <td colSpan={3} className="text-center font-weight-bold">
                                                    {i18n.languages[0] === 'th' ? 
                                                    (ThaiBaht(this.state.sum_grand_total))
                                                    :
                                                    (ConvertNumberToTextEng(Number(this.state.sum_grand_total)))
                                                    }
                                            </td>
                                                <td colSpan={2} className="font-weight-bold">
                                                    จำนวนเงินรวมทั้งสิ้น/Grand Total
                                            </td>
                                                <td className="text-right font-weight-bold">
                                                    {numberWithComma(this.state.sum_grand_total, 0)}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <div className="remark">
                                        <strong>หมายเหตุ/Remarks:</strong><br />
                                        {this.props.query.purchaseRecordGroup.remark}
                                    </div>
                                </div>

                                {
                                <div className="chart-of-account">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width={100} className="text-center">
                                                    รหัสบัญชี
                                            </th>
                                                <th className="text-center">
                                                    ชื่อบัญชี
                                            </th>
                                                <th width={100} className="text-center">
                                                    เดบิต
                                            </th>
                                                <th width={100} className="text-center">
                                                    เครดิต
                                            </th>
                                            </tr>
                                        </thead>
                                        {
                                            <React.Fragment >
                                                <tbody>
                                                    {purchaseAccountRecordGroup_debit.map((account_record) =>
                                                    {
                                                        return (
                                                            <tr key={account_record.id}>
                                                            <td className="text-center">{account_record.chartOfAccountCode}</td>
                                                            <td> {account_record.chartOfAccountCodeName} {account_record.name}</td>
                                                            <td className="text-right">{numberWithComma(account_record.debit)}</td>
                                                            <td className="text-right">{numberWithComma(0)}</td>
                                                        </tr>
                                                        )
                                                    }
                                                       
                                                    )}

                                                    {purchaseAccountRecordGroup_credit.map((account_record) =>
                                                        <tr key={account_record.id}>
                                                            <td className="text-center">{account_record.chartOfAccountCode}</td>
                                                            <td>{account_record.chartOfAccountCodeName} {account_record.name}</td>
                                                            <td className="text-right">{numberWithComma(0)}</td>
                                                            <td className="text-right">{numberWithComma(account_record.credit)}</td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <div className="row">
                                                                <div className="col-1 font-weight-bold">
                                                                    รวม/Total
                                                                </div>
                                                                <div className="col-10 text-center font-weight-bold">
                                                                    (
                                                                        { 
                                                                        this.props.query.purchaseAccountRecordGroup.edges[0]?.node.totalDebit ? 
                                                                            i18n.languages[0] === 'th' ? 
                                                                            (ThaiBaht(this.props.query.purchaseAccountRecordGroup.edges[0]?.node.totalDebit))
                                                                                :
                                                                            (ConvertNumberToTextEng(this.props.query.purchaseAccountRecordGroup.edges[0]?.node.totalDebit))                                                                                                                                                        
                                                                            :
                                                                            "-"
                                                                        }
                                                                    )
                                                            </div>
                                                                <div className="col-1" />
                                                            </div>
                                                        </td>
                                                        <td className="text-right font-weight-bold">
                                                            { this.props.query.purchaseAccountRecordGroup.edges[0]?.node.totalDebit ?
                                                                numberWithComma(this.props.query.purchaseAccountRecordGroup.edges[0]?.node.totalDebit.toFixed(2))
                                                                :
                                                                "-"
                                                            }
                                                        </td>
                                                        <td className="text-right font-weight-bold">
                                                            { this.props.query.purchaseAccountRecordGroup.edges[0]?.node.totalCredit ?
                                                                numberWithComma(this.props.query.purchaseAccountRecordGroup.edges[0]?.node.totalCredit.toFixed(2))
                                                                :
                                                                "-"
                                                            }
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </React.Fragment>
                                        }
                                    </table>
                                </div>}

                                <div className="footer">
                                    <div className="d-flex justify-content-end">
                                        <div className="left mt-5 text-center">
                                            .........................................................<br />
                                            <span className="font-weight-bold">ผู้จัดทำ / Organizer</span>
                                        </div>
                                        <div className="center mt-5 text-center">
                                            .........................................................<br />
                                            <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                        </div>
                                        <div className="right mt-5 text-center">
                                            .........................................................<br />
                                            <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                }
            </React.Fragment>
        );
        }else{
            //show 2page
            return (
                <React.Fragment>
                    {/* show purchaseRecord only */}
                    <PurchaseRecord 
                        query={this.props.query}
                        getPrefix={this.getPrefix}
                        state={this.state}
                        />

                    {/* show account_record only */}
                    <AccountRecord
                        query={this.props.query}
                        getPrefix={this.getPrefix}
                        state={this.state}
                        purchaseAccountRecordGroup_debit={purchaseAccountRecordGroup_debit}
                        purchaseAccountRecordGroup_credit={purchaseAccountRecordGroup_credit}
                        />
                </React.Fragment>
            )
        }
    }
}

export default Purchase;
