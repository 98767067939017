/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type electricMeterListRecordQueryVariables = {|
  first?: ?number,
  last?: ?number,
  year?: ?number,
  before_year?: ?number,
  before_month?: ?number,
  search?: ?string,
|};
export type electricMeterListRecordQueryResponse = {|
  +allResidential: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +electricMeterUnit: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +month: number,
              +year: number,
              +meterValue: ?number,
              +changeElectricMeter: boolean,
            |}
          |}>
        |},
        +beforeMeterUnit: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +month: number,
              +year: number,
              +meterValue: ?number,
              +changeElectricMeter: boolean,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |}
|};
export type electricMeterListRecordQuery = {|
  variables: electricMeterListRecordQueryVariables,
  response: electricMeterListRecordQueryResponse,
|};
*/


/*
query electricMeterListRecordQuery(
  $first: Int
  $last: Int
  $year: Float
  $before_year: Float
  $before_month: Float
  $search: String
) {
  allResidential(first: $first, last: $last, order: "added", search: $search) {
    edges {
      node {
        id
        name
        electricMeterUnit(year: $year) {
          edges {
            node {
              id
              month
              year
              meterValue
              changeElectricMeter
            }
          }
        }
        beforeMeterUnit: electricMeterUnit(year: $before_year, month: $before_month) {
          edges {
            node {
              id
              month
              year
              meterValue
              changeElectricMeter
            }
          }
        }
      }
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before_month"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before_year"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ElectricMeterUnitNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ElectricMeterUnitNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "month",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meterValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "changeElectricMeter",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Literal",
        "name": "order",
        "value": "added"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "ResidentialNodeConnection",
    "kind": "LinkedField",
    "name": "allResidential",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "year",
                    "variableName": "year"
                  }
                ],
                "concreteType": "ElectricMeterUnitNodeConnection",
                "kind": "LinkedField",
                "name": "electricMeterUnit",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": "beforeMeterUnit",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "month",
                    "variableName": "before_month"
                  },
                  {
                    "kind": "Variable",
                    "name": "year",
                    "variableName": "before_year"
                  }
                ],
                "concreteType": "ElectricMeterUnitNodeConnection",
                "kind": "LinkedField",
                "name": "electricMeterUnit",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "electricMeterListRecordQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "electricMeterListRecordQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "3ec881dd92215cde3420f29ca6bd24a9",
    "id": null,
    "metadata": {},
    "name": "electricMeterListRecordQuery",
    "operationKind": "query",
    "text": "query electricMeterListRecordQuery(\n  $first: Int\n  $last: Int\n  $year: Float\n  $before_year: Float\n  $before_month: Float\n  $search: String\n) {\n  allResidential(first: $first, last: $last, order: \"added\", search: $search) {\n    edges {\n      node {\n        id\n        name\n        electricMeterUnit(year: $year) {\n          edges {\n            node {\n              id\n              month\n              year\n              meterValue\n              changeElectricMeter\n            }\n          }\n        }\n        beforeMeterUnit: electricMeterUnit(year: $before_year, month: $before_month) {\n          edges {\n            node {\n              id\n              month\n              year\n              meterValue\n              changeElectricMeter\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '899b1f1f25c9985cb1ded845718df2a6';

module.exports = node;
