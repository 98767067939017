import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import {commitMutation} from 'react-relay'
import environment from "../../env/environment";
import { Translation } from 'react-i18next';
import i18next from 'i18next';

const mutation = graphql`
    mutation deleteSurveyQuestionMutation($input: DeleteQuestionInput!){
        deleteQuestion(input:$input){
            ok
        }
    }`;

class QuestionDelete extends Component {
    constructor(props) {
        super(props);
        this.onDeleteItem = this.onDeleteItem.bind(this)
    }

    onDeleteItem() {
        if (this.props.survey_question.id === "" || this.props.survey_question.id === undefined) {
            this.deleteRow()
        } else {
            this.mutationDelete()
        }
    }

    mutationDelete() {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                const variables = {
                    input: {
                        id: this.props.survey_question.id
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.deleteQuestion.ok) {
                                Swal.fire('ลบสำเร็จ!', '', 'success');
                                this.deleteRow()
                            } else {
                                Swal.fire('ลบไม่สำเร็จ!', '', 'warning'
                                );
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                        },
                    },
                )

            }
        })
    }

    deleteRow() {
        let survey_question_list_new = [...this.props.survey_question_list];
        let index = survey_question_list_new.indexOf(this.props.survey_question);
        if (index !== -1) {
            survey_question_list_new.splice(index, 1)
        }

        this.props.setSurveyQuestion(survey_question_list_new)
    }


    render() {
        return (
            <Translation>{t =>
                <label className="cursor text-decoration-none"
                    onClick={this.onDeleteItem}>
                    <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                        alt="delete-icon"
                    />
                    <span className="grey-color ml-1">{t('survey:delete')}</span>
                </label>
            }</Translation>
        )
    }
}

export default QuestionDelete;
