/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CollectionLawFirmFromSettingDocumentType = "FM_CF_01" | "FM_CF_02" | "FM_CF_03" | "FM_CF_04" | "FM_CF_05" | "FM_CF_12" | "%future added value";
export type settingFormCollectionLawQueryVariables = {|
  siteId?: ?string
|};
export type settingFormCollectionLawQueryResponse = {|
  +allCollectionLawFirmFromSetting: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +documentType: CollectionLawFirmFromSettingDocumentType,
        +dueDateDays: number,
        +feeRateTh: number,
        +feeRateOther: number,
        +lawyerSignature: ?string,
        +lawyerName: ?string,
        +telOffice: ?string,
        +telJuristic: ?string,
        +language: string,
        +creator: ?string,
        +creatorEdit: ?string,
        +regulations: ?string,
        +regulationsStatus: boolean,
        +rulesRegulationsjuristic: ?string,
        +site: {|
          +id: string,
          +project: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +nameTh: ?string,
              |}
            |}>
          |},
        |},
      |}
    |}>
  |},
  +selfProject: ?{|
    +name: string
  |},
|};
export type settingFormCollectionLawQuery = {|
  variables: settingFormCollectionLawQueryVariables,
  response: settingFormCollectionLawQueryResponse,
|};
*/


/*
query settingFormCollectionLawQuery(
  $siteId: ID
) {
  allCollectionLawFirmFromSetting(siteId: $siteId) {
    edges {
      node {
        id
        documentType
        dueDateDays
        feeRateTh
        feeRateOther
        lawyerSignature
        lawyerName
        telOffice
        telJuristic
        language
        creator
        creatorEdit
        regulations
        regulationsStatus
        rulesRegulationsjuristic
        site {
          id
          project {
            edges {
              node {
                id
                nameTh
              }
            }
          }
        }
      }
    }
  }
  selfProject {
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "siteId",
      "variableName": "siteId"
    }
  ],
  "concreteType": "CollectionLawFirmFromSettingNodeConnection",
  "kind": "LinkedField",
  "name": "allCollectionLawFirmFromSetting",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionLawFirmFromSettingNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionLawFirmFromSettingNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "documentType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dueDateDays",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeRateTh",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeRateOther",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lawyerSignature",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lawyerName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "telOffice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "telJuristic",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "language",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creatorEdit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regulations",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regulationsStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rulesRegulationsjuristic",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteType",
              "kind": "LinkedField",
              "name": "site",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectNodeConnection",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "nameTh",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingFormCollectionLawQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingFormCollectionLawQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "007d0fa6154f349f2272776bc32ba4f6",
    "id": null,
    "metadata": {},
    "name": "settingFormCollectionLawQuery",
    "operationKind": "query",
    "text": "query settingFormCollectionLawQuery(\n  $siteId: ID\n) {\n  allCollectionLawFirmFromSetting(siteId: $siteId) {\n    edges {\n      node {\n        id\n        documentType\n        dueDateDays\n        feeRateTh\n        feeRateOther\n        lawyerSignature\n        lawyerName\n        telOffice\n        telJuristic\n        language\n        creator\n        creatorEdit\n        regulations\n        regulationsStatus\n        rulesRegulationsjuristic\n        site {\n          id\n          project {\n            edges {\n              node {\n                id\n                nameTh\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  selfProject {\n    name\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48108c2925ac1c4fd20bd5888cde57b8';

module.exports = node;
