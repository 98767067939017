/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VoidPurchaseOrderesInput = {|
  purchaseOrderesId: string,
  voidRemark: string,
  clientMutationId?: ?string,
|};
export type voidPurchaseOrderesMutationVariables = {|
  input: VoidPurchaseOrderesInput
|};
export type voidPurchaseOrderesMutationResponse = {|
  +voidPurchaseOrderes: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type voidPurchaseOrderesMutation = {|
  variables: voidPurchaseOrderesMutationVariables,
  response: voidPurchaseOrderesMutationResponse,
|};
*/


/*
mutation voidPurchaseOrderesMutation(
  $input: VoidPurchaseOrderesInput!
) {
  voidPurchaseOrderes(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VoidPurchaseOrderesPayload",
    "kind": "LinkedField",
    "name": "voidPurchaseOrderes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "voidPurchaseOrderesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "voidPurchaseOrderesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9001d8e25c42cb701194c927c53b34d9",
    "id": null,
    "metadata": {},
    "name": "voidPurchaseOrderesMutation",
    "operationKind": "mutation",
    "text": "mutation voidPurchaseOrderesMutation(\n  $input: VoidPurchaseOrderesInput!\n) {\n  voidPurchaseOrderes(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '01365f2d2188d3628b241133794db320';

module.exports = node;
