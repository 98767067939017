/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcceptPDPAInput = {|
  acceptPolicy?: ?boolean,
  acceptTermOfUse?: ?boolean,
  acceptMarketing?: ?boolean,
  acceptPartner?: ?boolean,
  clientMutationId?: ?string,
|};
export type updatePDPALoginMutationVariables = {|
  input: AcceptPDPAInput
|};
export type updatePDPALoginMutationResponse = {|
  +acceptPDPA: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type updatePDPALoginMutation = {|
  variables: updatePDPALoginMutationVariables,
  response: updatePDPALoginMutationResponse,
|};
*/


/*
mutation updatePDPALoginMutation(
  $input: AcceptPDPAInput!
) {
  acceptPDPA(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AcceptPDPAPayload",
    "kind": "LinkedField",
    "name": "acceptPDPA",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePDPALoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePDPALoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e1e066ad3e8208e64a50c5f20764d42",
    "id": null,
    "metadata": {},
    "name": "updatePDPALoginMutation",
    "operationKind": "mutation",
    "text": "mutation updatePDPALoginMutation(\n  $input: AcceptPDPAInput!\n) {\n  acceptPDPA(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f59c78d9762c3a2e91d911766f0d47ce';

module.exports = node;
