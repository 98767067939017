import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import PaymentGateway from './paymentGateway';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import _ from 'lodash';
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperPaymentGatewayQuery($id: ID!) {
        selfProject {
            id
            name
            address
            taxIdNumber
            juristicContactNumber
            logo
        }
        paymentGatewayGroup(id: $id) {
            id
            docNumber
            image
            depositTotal
            feeTotal
            depositDate
            description
            paymentGatewayTransaction{
                totalCount
                edges{
                    node{
                        id
                        description
                        amount
                        fee
                        total
                        refNumber
                        issuedDate
                        paymentType
                        bank{
                            id
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            depositTotal
            feeTotal
        }
        
        bankAccountViewer{
            allBankAccount(status: true){
                edges{
                    node{
                        id
                        accountNumber
                        accountName
                        bankName

                    }
                }
            }
        }


        userConfig {
            id languages
        }

    }
`;

class WrapperPaymentGateway extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
    }

    print() {
        window.print();
    }

    adjustHeight() {

    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>PaymentGateway</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png"
                             alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id: this.props.match.params.id}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            let count_page = Math.ceil((props.paymentGatewayGroup.paymentGatewayTransaction.totalCount) / 26);
                            let arrX = Array.from(new Array(count_page));
                            let transaction_per_page = 26;

                            let payment_gateway_transaction_total = _.sumBy(props.paymentGatewayGroup.paymentGatewayTransaction.edges,'node.total')
                            let total = props.paymentGatewayGroup.depositTotal + props.paymentGatewayGroup.feeTotal
                            if(total > payment_gateway_transaction_total){
                                total = props.paymentGatewayGroup.depositTotal - props.paymentGatewayGroup.feeTotal
                            }

                            return (
                                <React.Fragment>
                                    {arrX.map((value, index) => {
                                        return (
                                            <PaymentGateway query={props} total={total}
                                                         key={index}
                                                         cash_page={index + 1}
                                                         end_page={count_page - 1}
                                                         current_page={index}
                                                         all_payment_gateway={props.paymentGatewayGroup.paymentGatewayTransaction.edges.slice(transaction_per_page * index, transaction_per_page * (index + 1))}
                                                         languages={props.userConfig.languages}
                                                         />
                                        )
                                    })}

                                </React.Fragment>
                            )
                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperPaymentGateway;
