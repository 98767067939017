import React from "react";
import SearchSelect from "../../../libs/searchSelect";
import PricingCalculateTable from "./pricingCalculateTable";
import { Translation } from 'react-i18next';

const _ = require('lodash');

class SettingsServiceType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pricing_type_list: [
                {"node": {label: "ค่าคงที่", value: "STATIC"}},
                {"node": {label: "คิดจากอัตราส่วนกรรมสิทธิ์", value: "RATIO"}},
                {"node": {label: "คิดตามขนาดพื้นที่", value: "AREA"}},
                {"node": {label: "คิดแยกตามยูนิต", value: "INDIVIDUAL"}},
                {"node": {label: "คิดจากมิเตอร์น้ำ", value: "WATER_METER"}},
                {"node": {label: "คิดจากมิเตอร์ไฟฟ้า", value: "ELECTRIC_METER"}},
                {"node": {label: "คิดค่าที่จอดรถยนต์", value: "CAR_PARK"}},
                {"node": {label: "คิดค่าที่จอดรถจักรยานยนต์", value: "MOTORCYCLE_PARK"}}
            ]
        }
    }

    render() {
        return (
            <div className="form-group row">
                <div className="col-md-6 mt-1">
                    <Translation>{t=><label>{t("revenue_setting:Recorded account")}</label>}</Translation>
                    <SearchSelect onChange={this.props.handleInputChange}
                                  value={this.props.state.product.chartOfAccount.id}
                                  name="product.chartOfAccount.id" placeholder="รายการ"
                                  queryObject={this.props.chart_of_account.group4service.edges}
                                  keyOfValue="id" require={true}
                                  keyOfLabel="chartOfAccountCode:name"
                                  disabled={this.props.project_not_edit_account}
                    />
                </div>
                <div className="col-md-6 mt-1">
                    <Translation>{t=><label>{t("revenue_setting:Debtor account")}</label>}</Translation>
                    <SearchSelect onChange={this.props.handleInputChange}
                                  value={this.props.state.product.customerChartOfAccount.id}
                                  name="product.customerChartOfAccount.id" placeholder="รายการ"
                                  queryObject={this.props.chart_of_account.groupCustomerChartOfAccount.edges}
                                  keyOfValue="id" require={true}
                                  keyOfLabel="chartOfAccountCode:name"
                                  disabled={this.props.project_not_edit_account}
                    />
                </div>
                <div className="col-md-6 mt-4">
                    <Translation>{t=><label>{t("revenue_setting:Pricing")}</label>}</Translation>
                    <SearchSelect onChange={this.props.handleInputChange}
                                  value={_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType')}
                                  name="product.servicePricing.edges.0.node.pricingType" placeholder="รายการ"
                                  keyOfLabel="label" keyOfValue="value" required={true}
                                  queryObject={this.state.pricing_type_list}
                    />
                </div>
                <div className="w-100"/>
                <div className="col-md-12 mt-4">
                    {_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'STATIC' &&
                        <React.Fragment>
                            <Translation>{t=><label>{t("revenue_setting:Price")}</label>}</Translation>
                            <input type="number" className="form-control col-md-2 mb-2" name="product.price"
                                   onChange={this.props.handleInputChange}
                                   value={this.props.state.product.price} required/>
                        </React.Fragment>
                    }
                    {(_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'AREA' ||
                        _.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'RATIO' ) &&
                        <React.Fragment>
                            <Translation>{t=><label>{t("revenue_setting:Price per unit (month)")}</label>}</Translation>
                            {this.props.state.isValid ? null : (<span style={{ color: 'red' }}>  * กรอกมูลค่ามากกว่า 0</span>)}
                            <input type="text" className="form-control col-md-2" name="product.price"
                                   onChange={this.props.handleInputChange}
                                   value={this.props.state.product.price}
                                   style = {{
                                       borderColor : this.props.state.isValid ? '' : 'red'
                                   }}
                                   onKeyDown={(e) => {
                                    const allowedChars = /[0-9./]/; // Regular expression to match digits, dot, and slash
                                    if (!allowedChars.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                                      e.preventDefault();
                                    }
                                  }}
                                   required/>
                            <div className="form-check form-check-inline mt-2 mb-2">
                                <input className="form-check-input" type="radio" value="NOT_ROUNDED" id="not_rounded"
                                    name="product.servicePricing.edges.0.node.rounding"
                                    onChange={this.props.handleInputChange}
                                    checked={_.get(this.props.state.product.servicePricing, 'edges.0.node.rounding') === 'NOT_ROUNDED'}
                                />
                                <Translation>{t=><label className="form-check-label" htmlFor="not_rounded">{t("revenue_setting:Don't round the penny")}</label>}</Translation>
                            </div>
                            <div className="form-check form-check-inline mt-2 mb-2">
                                <input className="form-check-input" type="radio" value="UP" id="up"
                                       name="product.servicePricing.edges.0.node.rounding"
                                       onChange={this.props.handleInputChange}
                                       checked={_.get(this.props.state.product.servicePricing, 'edges.0.node.rounding') === 'UP'}
                                />
                               <Translation>{t=><label className="form-check-label" htmlFor="up">{t("revenue_setting:Round the penny up")}</label>}</Translation>
                            </div>
                            <div className="form-check form-check-inline mt-2 mb-2">
                                <input className="form-check-input" type="radio" value="DOWN" id="down"
                                       name="product.servicePricing.edges.0.node.rounding"
                                       onChange={this.props.handleInputChange}
                                       checked={_.get(this.props.state.product.servicePricing, 'edges.0.node.rounding') === 'DOWN'}
                                />
                               <Translation>{t=><label className="form-check-label" htmlFor="down">{t("revenue_setting:Round the penny down")}</label>}</Translation>
                            </div>
                        </React.Fragment>
                    }
                    {(_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'WATER_METER' && _.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'CAR_PARK' && _.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'MOTORCYCLE_PARK' && _.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'ELECTRIC_METER') && this.props.state.product.id &&
                        <PricingCalculateTable state={this.props.state}/>
                    }
                    {((_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'WATER_METER') || (_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'ELECTRIC_METER')) &&
                        <React.Fragment>
                            <Translation>{t=><label>{t("revenue_setting:Price per unit")}</label>}</Translation>
                            {this.props.state.isValid ? null : (<Translation>{t=><span style={{ color: 'red' }}>  * {t("revenue_setting:Enter more value")} 0</span>}</Translation>)}
                            <input type="number" className="form-control col-md-2" name="product.price"
                                   onChange={this.props.handleInputChange}
                                   value={this.props.state.product.price} required
                                   style = {{
                                    borderColor : this.props.state.isValid ? '' : 'red'
                                }}
                                />
                        </React.Fragment>
                    }
                    {(_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'CAR_PARK' || _.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') === 'MOTORCYCLE_PARK') &&
                        <React.Fragment>
                            <Translation>{t=><label>{t("revenue_setting:Price per vehicle")}</label>}</Translation>
                            {this.props.state.isValid ? null : (<span style={{ color: 'red' }}>  * กรอกมูลค่ามากกว่า 0</span>)}
                            <input type="number" className="form-control col-md-2" name="product.price"
                                   onChange={this.props.handleInputChange}
                                   value={this.props.state.product.price} required
                                   style = {{
                                    borderColor : this.props.state.isValid ? '' : 'red'
                                }}
                                />
                        </React.Fragment>
                    }
                </div>
                <div className="w-100"/>
                {(_.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'WATER_METER' && _.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'CAR_PARK' && _.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'MOTORCYCLE_PARK' && _.get(this.props.state.product.servicePricing, 'edges.0.node.pricingType') !== 'ELECTRIC_METER') &&
                <React.Fragment>
                    <div className="col-md-6 mt-3">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="period-adjustment"
                                   checked={_.get(this.props.state.product.servicePricing, 'edges.0.node.periodAdjustment')}
                                   value={!_.get(this.props.state.product.servicePricing, 'edges.0.node.periodAdjustment', false)}
                                   onChange={this.props.handleInputChange}
                                   name="product.servicePricing.edges.0.node.periodAdjustment"/>
                            <Translation>
                                {t=><label className="form-check-label"
                                       htmlFor="period-adjustment">{t("revenue_setting:Calculate the average income for the period (months)")}</label>}
                            </Translation>
                        </div>
                        <br/>
                        <Translation>{t=><span className="small text-secondary">{t("revenue_setting:For dividing the average income")}</span>}</Translation>
                    </div>
                    <div className="col-md-6"/>
                </React.Fragment>
                }
                {
                    _.get(this.props.state.product.servicePricing, 'edges.0.node.periodAdjustment', false) &&
                    <React.Fragment>
                        {/* <div className="col-md-6 mt-2">
                            <Translation>{t=><label>{t("revenue_setting:Receipt_deposits")}</label>}</Translation>
                            <SearchSelect onChange={this.props.handleInputChange}
                                          value={_.get(this.props.state.product, "servicePricing.edges.0.node.receiveDeposit.id")}
                                          name="product.servicePricing.edges.0.node.receiveDeposit.id" placeholder="รายการ"
                                          queryObject={this.props.chart_of_account.groupReceiveDeposit.edges}
                                          keyOfValue="id" required={true}
                                          keyOfLabel="chartOfAccountCode:name"
                            />
                        </div> */}
                        <div className="col-md-6 mt-2">
                        <Translation>{t=><label>{t("revenue_setting:Deferred")}</label>}</Translation>
                            <SearchSelect onChange={this.props.handleInputChange}
                                          value={_.get(this.props.state.product, "servicePricing.edges.0.node.deferredIncome.id")}
                                          name="product.servicePricing.edges.0.node.deferredIncome.id" placeholder="รายการ"
                                          keyOfLabel="chartOfAccountCode:name" keyOfValue="id" required={true}
                                          queryObject={this.props.chart_of_account.groupDeferredIncome.edges}
                            />
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default SettingsServiceType;
