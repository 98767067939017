/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type GuaranteeMoneyReceivedPayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type GuaranteeMoneyReceivedStatus = "COMPLETED" | "FORFEIT_ALL" | "IN_PROCESS" | "RETURN_FORFEIT" | "VOID" | "%future added value";
export type guaranteeMoneyReceivedReportPDFQueryVariables = {|
  first?: ?number,
  last?: ?number,
  start_date?: ?any,
  end_date?: ?any,
  search?: ?string,
  customerType?: ?string,
  statusType?: ?string,
  contact?: ?string,
  paymentChannel?: ?string,
  order?: ?string,
|};
export type guaranteeMoneyReceivedReportPDFQueryResponse = {|
  +allGuaranteeMoneyReceived: ?{|
    +totalCount: ?number,
    +pageInfo: {|
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +amount: ?number,
        +description: string,
        +status: GuaranteeMoneyReceivedStatus,
        +payGroup: GuaranteeMoneyReceivedPayGroup,
        +contact: {|
          +name: string,
          +refNumber: string,
          +firstName: string,
          +lastName: string,
          +typeOfContact: ContactTypeOfContact,
          +residential: ?{|
            +name: string
          |},
        |},
        +cashDepositReceiveTransaction: ?{|
          +totalCount: ?number
        |},
        +bankAccountTransaction: ?{|
          +totalCount: ?number
        |},
        +chequeDeposit: ?{|
          +totalCount: ?number
        |},
      |}
    |}>,
  |},
  +summaryGuaranteeMoneyReceived: ?number,
|};
export type guaranteeMoneyReceivedReportPDFQuery = {|
  variables: guaranteeMoneyReceivedReportPDFQueryVariables,
  response: guaranteeMoneyReceivedReportPDFQueryResponse,
|};
*/


/*
query guaranteeMoneyReceivedReportPDFQuery(
  $first: Int
  $last: Int
  $start_date: DateTime
  $end_date: DateTime
  $search: String
  $customerType: String
  $statusType: String
  $contact: ID
  $paymentChannel: String
  $order: String
) {
  allGuaranteeMoneyReceived(first: $first, last: $last, startDate: $start_date, endDate: $end_date, search: $search, customerType: $customerType, statusType: $statusType, contact: $contact, paymentChannel: $paymentChannel, order: $order) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        docNumber
        issuedDate
        amount
        description
        status
        payGroup
        contact {
          name
          refNumber
          firstName
          lastName
          typeOfContact
          residential {
            name
            id
          }
          id
        }
        cashDepositReceiveTransaction {
          totalCount
        }
        bankAccountTransaction {
          totalCount
        }
        chequeDeposit {
          totalCount
        }
      }
    }
  }
  summaryGuaranteeMoneyReceived(startDate: $start_date, endDate: $end_date, search: $search, customerType: $customerType, contact: $contact, paymentChannel: $paymentChannel, statusType: $statusType)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentChannel"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "statusType"
},
v10 = {
  "kind": "Variable",
  "name": "contact",
  "variableName": "contact"
},
v11 = {
  "kind": "Variable",
  "name": "customerType",
  "variableName": "customerType"
},
v12 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "end_date"
},
v13 = {
  "kind": "Variable",
  "name": "paymentChannel",
  "variableName": "paymentChannel"
},
v14 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v15 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "start_date"
},
v16 = {
  "kind": "Variable",
  "name": "statusType",
  "variableName": "statusType"
},
v17 = [
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payGroup",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v32 = [
  (v18/*: any*/)
],
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "CashTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "cashDepositReceiveTransaction",
  "plural": false,
  "selections": (v32/*: any*/),
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccountTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "bankAccountTransaction",
  "plural": false,
  "selections": (v32/*: any*/),
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeDepositNodeConnection",
  "kind": "LinkedField",
  "name": "chequeDeposit",
  "plural": false,
  "selections": (v32/*: any*/),
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": [
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "summaryGuaranteeMoneyReceived",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "guaranteeMoneyReceivedReportPDFQuery",
    "selections": [
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "GuaranteeMoneyReceivedNodeConnection",
        "kind": "LinkedField",
        "name": "allGuaranteeMoneyReceived",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GuaranteeMoneyReceivedNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GuaranteeMoneyReceivedNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v27/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v33/*: any*/),
                  (v34/*: any*/),
                  (v35/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v36/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v9/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "guaranteeMoneyReceivedReportPDFQuery",
    "selections": [
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "GuaranteeMoneyReceivedNodeConnection",
        "kind": "LinkedField",
        "name": "allGuaranteeMoneyReceived",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GuaranteeMoneyReceivedNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GuaranteeMoneyReceivedNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResidentialNode",
                        "kind": "LinkedField",
                        "name": "residential",
                        "plural": false,
                        "selections": [
                          (v27/*: any*/),
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v33/*: any*/),
                  (v34/*: any*/),
                  (v35/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v36/*: any*/)
    ]
  },
  "params": {
    "cacheID": "7fc4fccd512af16f5f53c9338af632d8",
    "id": null,
    "metadata": {},
    "name": "guaranteeMoneyReceivedReportPDFQuery",
    "operationKind": "query",
    "text": "query guaranteeMoneyReceivedReportPDFQuery(\n  $first: Int\n  $last: Int\n  $start_date: DateTime\n  $end_date: DateTime\n  $search: String\n  $customerType: String\n  $statusType: String\n  $contact: ID\n  $paymentChannel: String\n  $order: String\n) {\n  allGuaranteeMoneyReceived(first: $first, last: $last, startDate: $start_date, endDate: $end_date, search: $search, customerType: $customerType, statusType: $statusType, contact: $contact, paymentChannel: $paymentChannel, order: $order) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n    }\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        amount\n        description\n        status\n        payGroup\n        contact {\n          name\n          refNumber\n          firstName\n          lastName\n          typeOfContact\n          residential {\n            name\n            id\n          }\n          id\n        }\n        cashDepositReceiveTransaction {\n          totalCount\n        }\n        bankAccountTransaction {\n          totalCount\n        }\n        chequeDeposit {\n          totalCount\n        }\n      }\n    }\n  }\n  summaryGuaranteeMoneyReceived(startDate: $start_date, endDate: $end_date, search: $search, customerType: $customerType, contact: $contact, paymentChannel: $paymentChannel, statusType: $statusType)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '52ff5619cba9210eca94b70f320b3029';

module.exports = node;
