/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type collectionLawFrimDocsStatusQueryVariables = {|
  siteId?: ?number
|};
export type collectionLawFrimDocsStatusQueryResponse = {|
  +summaryRegisterCollectionLawFirm: ?any
|};
export type collectionLawFrimDocsStatusQuery = {|
  variables: collectionLawFrimDocsStatusQueryVariables,
  response: collectionLawFrimDocsStatusQueryResponse,
|};
*/


/*
query collectionLawFrimDocsStatusQuery(
  $siteId: Int
) {
  summaryRegisterCollectionLawFirm(siteId: $siteId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "siteId",
        "variableName": "siteId"
      }
    ],
    "kind": "ScalarField",
    "name": "summaryRegisterCollectionLawFirm",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collectionLawFrimDocsStatusQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collectionLawFrimDocsStatusQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "45b55f355d16fb3ec97afea546e5347b",
    "id": null,
    "metadata": {},
    "name": "collectionLawFrimDocsStatusQuery",
    "operationKind": "query",
    "text": "query collectionLawFrimDocsStatusQuery(\n  $siteId: Int\n) {\n  summaryRegisterCollectionLawFirm(siteId: $siteId)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '71372993a202d2573748a1955cdd1b89';

module.exports = node;
