import React from "react";
import localStoragePage from "../../../../libs/localstorage";
import numberWithComma from "../../../../libs/numberWithComma";
import {format} from "date-fns";
import _ from "lodash";
import i18next from "i18next";

class OtherExpenseReportTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            other_list : ["small-change" , "small-change-income", "fee"], //ค่าส่วนต่างทั้งหมด
        }
        this.getAmountPayment = this.getAmountPayment.bind(this);
    }

    getAmountPayment(default_value,price_NotwhtRate){
        if(default_value > 0 && price_NotwhtRate > 0){   
            if(default_value >= price_NotwhtRate){
                return {default_value : (default_value - price_NotwhtRate), price :price_NotwhtRate, price_NotwhtRate : 0 }
            }             
            else {
                return { default_value: 0, price: default_value, price_NotwhtRate: price_NotwhtRate - default_value }
            }
        }
    }

    isOther(slug) {
        if(_.indexOf(this.state.other_list, slug) >= 0) {
            return true;
        }
        return false;
    }



    render() {
        let total = 0;
        // let col_span = 11;
        let summary = {};
        // if (this.props.state.payment_channel !== "") {
        //     col_span = 9;
        // }
        if (!this.props.props.allOtherExpense.pageInfo.hasNextPage) {
            summary = JSON.parse(this.props.props.summaryOtherExpense)
        }
        
        summary = JSON.parse(this.props.props.summaryOtherExpense)
        let color 
        let status_void = false;
        let amount_cash = 0;
        let amount_bank = 0;
        let amount_cheque = 0;
        let amount_other_expense =0
        let total_other = 0;
        let total_wht_rate =0;
        return (
            <React.Fragment>
                {this.props.props.allOtherExpense.edges.map((other_expense, index) => {
                    let order_id = this.props.state.first - localStoragePage.getPageLimit();
                    let accumulate = 0;
                    let default_cash = other_expense.node.cashTransaction.totalCount > 0 ? _.sumBy(other_expense.node.cashTransaction.edges,'node.price') : 0;
                    let default_bank = other_expense.node.bankAccountTransaction.totalCount > 0 ? _.sumBy(other_expense.node.bankAccountTransaction.edges,'node.price') : 0;
                    let default_cheque = other_expense.node.chequeTransaction.totalCount > 0 ? _.sumBy(other_expense.node.chequeTransaction.edges,'node.price') : 0;
                    let default_other_expense = other_expense.node.otherExpensePaymentChannel.totalCount > 0 ? _.sumBy(other_expense.node.otherExpensePaymentChannel.edges, function(payment){
                        if(payment.node?.slug ==="petty_cash"){
                            return payment.node.price
                        } 
                        return 0
                    }):0
                    let total_transaction = other_expense.node.otherExpenseTransaction.edges.length > 0 ? _.sumBy(other_expense.node.otherExpenseTransaction.edges,'node.total') : 0;
                    amount_cash += default_cash;
                    amount_bank += default_bank;
                    amount_cheque += default_cheque;
                    let cash = 0
                    let bank = 0
                    let cheque = 0;
                    let otherExpense =0
                    let showCash,showBank,showCheque,showOtherExpense = false;
                    let otherExpensePaymentChannel = other_expense.node.otherExpensePaymentChannel.edges
                    amount_other_expense += default_other_expense
                    status_void = other_expense.node.status === 'VOID'?true:false
                    color = status_void?{color:'red'}:null
                    let amount_other = _.sum(otherExpensePaymentChannel.map(value => 
                        {return this.isOther(value.node.slug) ? value.node.price : 0}))
                    return (
                        other_expense.node.otherExpenseTransaction.edges.map((transaction, t_index) => {
                            // let number_of_transaction = other_expense.node.otherExpenseTransaction.totalCount;
                            let price_NotwhtRate = transaction.node.total - transaction.node.whtRate //ยอดต้องชำระของแต่ละรายการแบบไม่รวมหัก ณ ที่จ่าย
                            accumulate = accumulate + transaction.node.total;
                            total_wht_rate = status_void ? total_wht_rate  : total_wht_rate + transaction.node.whtRate;
                            total = status_void ? total : total +  transaction.node.total;
                            let row_number = (t_index === 0 && order_id + index + 1);
                            showCash = default_cash > 0 ? true : false;
                            showBank = default_bank > 0 ? true : false;
                            showCheque = default_cheque > 0 ? true : false;
                            showOtherExpense = default_other_expense > 0 ? true : false
                            if(default_cash > 0 && price_NotwhtRate > 0){  
                                let amountPayment = this.getAmountPayment(default_cash,price_NotwhtRate) 
                                default_cash = amountPayment.default_value;
                                cash = amountPayment.price;
                                price_NotwhtRate = amountPayment.price_NotwhtRate
                            }
                            if(default_bank > 0 && price_NotwhtRate > 0){
                                let amountPayment = this.getAmountPayment(default_bank,price_NotwhtRate)
                                default_bank = amountPayment.default_value;
                                bank = amountPayment.price;
                                price_NotwhtRate = amountPayment.price_NotwhtRate 
                            }
                            if(default_cheque > 0 && price_NotwhtRate > 0){
                                let amountPayment = this.getAmountPayment(default_cheque,price_NotwhtRate)
                                default_cheque = amountPayment.default_value;
                                cheque = amountPayment.price;
                                price_NotwhtRate = amountPayment.price_NotwhtRate
                            }
                            if (default_other_expense > 0 && price_NotwhtRate > 0 ){
                                let amountPayment = this.getAmountPayment(default_other_expense, price_NotwhtRate)
                                default_other_expense = amountPayment.default_value
                                otherExpense = amountPayment.price
                                price_NotwhtRate = amountPayment.price_NotwhtRate
                            }
                            if(t_index+1 === other_expense.node.otherExpenseTransaction.edges.length){
                                if(_.findIndex(otherExpensePaymentChannel, {'node' : {'slug' : 'small-change'}}) !== -1 || _.findIndex(otherExpensePaymentChannel, {'node' : {'slug' : 'fee'}}) !== -1){
                                    if(!status_void){
                                        total_other -= amount_other
                                    }
                                    if(default_cash > 0 ){
                                        cash = cash+default_cash;
                                    }
                                    if(default_bank >0){
                                        bank = bank+default_bank
                                    }
                                    if(default_cheque >0 ){   
                                        cheque = cheque+default_cheque 
                                    } 
                                    if (default_other_expense > 0) {
                                        otherExpense = otherExpense + default_other_expense
                                    }
                                    
                                }else if(_.findIndex(otherExpensePaymentChannel, {'node' : {'slug' : 'small-change-income'}}) !== -1){
                                    if(!status_void){
                                        total_other += amount_other
                                    }

                                } 

                            }
                            return (
                                (
                                    <tr key={transaction.node.id}>
                                        <td style={color} className="text-center">{row_number}</td>
                                        <td style={color} className="text-center">
                                            {t_index === 0 && other_expense.node.docNumber}
                                        </td>
                                        <td style={color} className="text-center">{t_index === 0 && format(other_expense.node.issuedDate, 'DD/MM/YYYY')}</td>
                                        <td style={color} className="text-center">

                                            {t_index === 0 && (other_expense.node.contact ? other_expense.node.contact.refNumber : "-")}
                                        </td>
                                        <td style={color} >
                                            {t_index === 0 && (other_expense.node.contact ? other_expense.node.contact.name : other_expense.node.unknownContact ? other_expense.node.unknownContact : "-")}
                                        </td>
                                        <td style={color} >{transaction.node.description}</td>
                                        <td style={color} className="text-right">
                                            {status_void ? '-' : t_index === 0 && numberWithComma(total_transaction,"")}
                                        </td>

                                        <React.Fragment>
                                            <td style={color} className="text-center">{status_void ? "-" : other_expense.node.cashTransaction.totalCount > 0 &&
                                            <span>{showCash && numberWithComma(cash, "")}</span>}</td>
                                            <td style={color} className="text-center">{status_void ? "-" : other_expense.node.bankAccountTransaction.totalCount > 0 &&
                                            <span>{showBank && numberWithComma(bank,"")}</span>}</td>
                                            <td style={color} className="text-center">{status_void ? "-" : other_expense.node.chequeTransaction.totalCount > 0 &&
                                            <span>{showCheque && numberWithComma(cheque,"")}</span>}</td>
                                            <td style={color} className="text-center">{status_void? "-": other_expense.node.otherExpensePaymentChannel.totalCount>0&&
                                            <span>{showOtherExpense && numberWithComma(otherExpense,"")}</span>}</td>
                                            <td style={color} className="text-center">{status_void ? "-" : 
                                            t_index+1 === other_expense.node.otherExpenseTransaction.edges.length &&<span>{ _.findIndex(other_expense.node.otherExpensePaymentChannel.edges, {'node' : {'slug' : 'small-change-income'}}) !== -1 ? numberWithComma(amount_other,"") : numberWithComma(-amount_other,"")}</span>}</td>
                                        </React.Fragment>
                                        <td style={color} className="text-center">
                                            {status_void ? "-" : numberWithComma(transaction.node.whtRate,"")}
                                        </td>
                                        
                                    </tr>
                                )
                            )
                        })
                    )
                })
                }
                {(this.props.props.allOtherExpense.pageInfo.hasNextPage || this.props.props.allOtherExpense.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={6}
                            className="text-right"><strong>{i18next.t("OtherExpenseReport:Sum")} </strong>
                        </td>
                        <td className="text-right"><strong>{numberWithComma(total, "-")}</strong></td>
                        <td className="text-center"><strong>{numberWithComma(amount_cash,"-")}</strong></td>
                        <td className="text-center"><strong>{numberWithComma(amount_bank,"-")}</strong></td>
                        <td className="text-center"><strong>{numberWithComma(amount_cheque,"-")}</strong></td>
                        <td className='text-center'><strong>{numberWithComma(amount_other_expense, "-")}</strong></td>
                        <td className="text-center"><strong>{numberWithComma(total_other,"-")}</strong></td>
                        <td className="text-center"><strong>{numberWithComma(total_wht_rate,"-")}</strong></td>
                        
                    </tr>
                }
               
                <tr>
                    <td colSpan={6}
                        className="text-right"><strong>{`${i18next.t("OtherExpenseReport:Total Number of")} `} {this.props.props.allOtherExpense.totalCount} {i18next.t("OtherExpenseReport:Transactions")}</strong>
                    </td>
                    <td className="text-right"><strong>{numberWithComma(parseFloat(summary.summary_all), "-")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(summary.count_pay_by_cash),"-")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(summary.count_pay_by_bank),"-")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(summary.count_pay_by_cheque),"-")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(summary.summary_petty_cash), "-")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(summary.summary_other),"-")}</strong></td>
                    <td className="text-center"><strong>{numberWithComma(parseFloat(summary.summary_wht_rate),"-")}</strong></td>
                    
                </tr>
            </React.Fragment>
        )
    }
}

export default OtherExpenseReportTable;
