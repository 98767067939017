import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import '../invoice_list/style/navigation.scss';
import {Translation} from "react-i18next";
class Navigation extends Component {

    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/income/invoice/list/all">
                                <Translation>{t => t('invoice:all')}</Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/income/invoice/list/draft">
                                <Translation>{t => t('invoice:draft')}</Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/income/invoice/list/active">
                                <Translation>{t => t('invoice:waiting_for_payment')}</Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/income/invoice/list/overdue">
                                <Translation>{t => t('invoice:overdue')}</Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/income/invoice/list/paid">
                                <Translation>{t => t('invoice:paid')}</Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/income/invoice/list/partial_payment">
                                <Translation>{t => t('invoice:partial_payment')}</Translation>
                            </NavLink>

                        </li>
                        <li>
                            <NavLink to="/accounting/income/invoice/list/waiting_fix">
                                <Translation>{t => t('invoice:pending_edit_list')}</Translation>
                                {
                                    this.props.total_count_wait_fix !== 0 &&
                                    <span className="badge">{this.props.total_count_wait_fix}</span>
                                }

                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/income/invoice/list/void">
                                <Translation>{t => t('invoice:canceled_list')}</Translation>
                            </NavLink>

                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;