/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssetCalSchedule = "MONTHLY" | "YEARLY" | "%future added value";
export type AssetType = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type assetEditQueryVariables = {|
  id: string
|};
export type assetEditQueryResponse = {|
  +asset: ?{|
    +id: string,
    +type: AssetType,
    +docNumber: string,
    +nounClassifier: string,
    +chartOfAccountType: {|
      +id: string
    |},
    +chartOfAccountDepreciation: ?{|
      +id: string
    |},
    +chartOfAccountDepreciationCumulative: ?{|
      +id: string
    |},
    +name: string,
    +description: ?string,
    +storage: ?string,
    +remark: string,
    +seller: ?{|
      +id: string
    |},
    +sellerOther: ?string,
    +calDepreciation: boolean,
    +depreciationRatePercent: ?number,
    +depreciationRate: ?number,
    +lifeTimeYear: ?number,
    +purchaseDate: ?any,
    +saleDate: ?any,
    +calDepreciationDate: ?any,
    +costPrice: ?number,
    +carcassPrice: ?number,
    +calSchedule: AssetCalSchedule,
    +historicalValue: number,
    +historicalAmount: number,
  |},
  +allAssetImage: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +source: string,
      |}
    |}>
  |},
|};
export type assetEditQuery = {|
  variables: assetEditQueryVariables,
  response: assetEditQueryResponse,
|};
*/


/*
query assetEditQuery(
  $id: ID!
) {
  asset(id: $id) {
    id
    type
    docNumber
    nounClassifier
    chartOfAccountType {
      id
    }
    chartOfAccountDepreciation {
      id
    }
    chartOfAccountDepreciationCumulative {
      id
    }
    name
    description
    storage
    remark
    seller {
      id
    }
    sellerOther
    calDepreciation
    depreciationRatePercent
    depreciationRate
    lifeTimeYear
    purchaseDate
    saleDate
    calDepreciationDate
    costPrice
    carcassPrice
    calSchedule
    historicalValue
    historicalAmount
  }
  allAssetImage(asset_Id: $id) {
    edges {
      node {
        id
        source
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "AssetNode",
    "kind": "LinkedField",
    "name": "asset",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "docNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nounClassifier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNode",
        "kind": "LinkedField",
        "name": "chartOfAccountType",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNode",
        "kind": "LinkedField",
        "name": "chartOfAccountDepreciation",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNode",
        "kind": "LinkedField",
        "name": "chartOfAccountDepreciationCumulative",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remark",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactNode",
        "kind": "LinkedField",
        "name": "seller",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sellerOther",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "calDepreciation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "depreciationRatePercent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "depreciationRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lifeTimeYear",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "purchaseDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "saleDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "calDepreciationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "costPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "carcassPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "calSchedule",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "historicalValue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "historicalAmount",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "asset_Id",
        "variableName": "id"
      }
    ],
    "concreteType": "AssetImageNodeConnection",
    "kind": "LinkedField",
    "name": "allAssetImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetImageNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetImageNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "source",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assetEditQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assetEditQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "299bca0600e0d59d8b2353738b13010d",
    "id": null,
    "metadata": {},
    "name": "assetEditQuery",
    "operationKind": "query",
    "text": "query assetEditQuery(\n  $id: ID!\n) {\n  asset(id: $id) {\n    id\n    type\n    docNumber\n    nounClassifier\n    chartOfAccountType {\n      id\n    }\n    chartOfAccountDepreciation {\n      id\n    }\n    chartOfAccountDepreciationCumulative {\n      id\n    }\n    name\n    description\n    storage\n    remark\n    seller {\n      id\n    }\n    sellerOther\n    calDepreciation\n    depreciationRatePercent\n    depreciationRate\n    lifeTimeYear\n    purchaseDate\n    saleDate\n    calDepreciationDate\n    costPrice\n    carcassPrice\n    calSchedule\n    historicalValue\n    historicalAmount\n  }\n  allAssetImage(asset_Id: $id) {\n    edges {\n      node {\n        id\n        source\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '875ffa13e967b94ff99ced33aa532b3b';

module.exports = node;
