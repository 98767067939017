import i18next from 'i18next';
import React from 'react';
import { Component } from 'react';
import Swal from "sweetalert2";

class OnDelete extends Component {
    constructor(props){
        super(props);
        this.onDeleteItem = this.onDeleteItem.bind(this)
    }

    onDeleteItem() {
        let newTransactionList = [...this.props.transactionList];
        let index = newTransactionList.indexOf(this.props.transaction);
        if (index !== -1) {
            newTransactionList.splice(index, 1)
        }
        this.props.setTransactionList(newTransactionList)
    }

    mutationDelete = () => {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                this.onDeleteItem()
            }
        })
    }


    render(){
        return(
            <React.Fragment>
                {
                    <img src={process.env.PUBLIC_URL + '/images/icons/icon_delete.png'}
                        alt="delete" className="delete cursor"
                        onClick={this.mutationDelete}/>
                } 
            </React.Fragment>
        );
    }


}

export default OnDelete;