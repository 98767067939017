import React from "react";
import numberWithComma from "../../../../libs/numberWithComma";
import localStoragePage from "../../../../libs/localstorage";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import i18next from "i18next";


class AcceptCreditNoteReportTable extends React.Component {

    render() {
         let total = 0;
         let color 
         let status_void = false
         let total_accept_creditnote =0
         return (
             <React.Fragment>
                {this.props.props.acceptCreditNoteViewer.allAcceptCreditNote.edges.map((accept_credit_note, index) => {
                    let order_id = this.props.state.first - localStoragePage.getPageLimit();
                    total += accept_credit_note.node.price;
                    status_void = accept_credit_note.node.status === 'VOID'?true:false
                    color = status_void?{color:'red'}:null
                    if (accept_credit_note.node.status !=='VOID'){
                        total_accept_creditnote += accept_credit_note.node.total
                    }

                    return (
                        <React.Fragment key={accept_credit_note.node.id}>
                            <tr>
                                <td style={color} className="text-center">{order_id + index + 1}</td>
                                <td style={color} className="text-center">{accept_credit_note.node.docNumber}</td>
                                <td style={color} className="text-center">{format(accept_credit_note.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                <td style={color} className="text-center">{accept_credit_note.node.contact.refNumber}</td>
                                <td style={color} >{accept_credit_note.node.contact.name}</td>
                                <td style={color} className="text-center">{accept_credit_note.node.purchaseRecord.purchaseRecordGroup.docNumber}</td>
                                <td style={color} className="text-center">{format(accept_credit_note.node.purchaseRecord.purchaseRecordGroup.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                <td style={color} >{accept_credit_note.node.purchaseRecord.description}</td>
                                <td style={color} className="text-right">{status_void? '-' :numberWithComma(accept_credit_note.node.purchaseRecord.total)}</td>
                                <td style={color} className="text-right">{status_void?'-': numberWithComma(accept_credit_note.node.total)}</td>
                                <td style={color} className="text-center">{accept_credit_note.node.payRecordGroup ? accept_credit_note.node.payRecordGroup.docNumber: "-"}</td>
                            </tr>
                        </React.Fragment>

                    )
                })}

                {
                    (this.props.props.acceptCreditNoteViewer.allAcceptCreditNote.pageInfo.hasNextPage || this.props.props.acceptCreditNoteViewer.allAcceptCreditNote.pageInfo.hasPreviousPage) &&
                    <tr>
                        <td colSpan={9} className="text-right"><strong>{i18next.t("AcceptCreditNoteReport:Sum")}</strong></td>
                        <td className="text-right"><strong>{numberWithComma(total)}</strong></td>
                        <td/>
                    </tr>
                }
                {!this.props.props.acceptCreditNoteViewer.allAcceptCreditNote.pageInfo.hasNextPage &&
                <tr>
                    <td colSpan={9} className="text-right"><strong>{i18next.t("AcceptCreditNoteReport:Total")}</strong></td>
                    <td className="text-right">
                         <strong>{numberWithComma(this.props.props.acceptCreditNoteViewer.summaryAcceptCreditNote)}</strong></td>
                    <td/>
                </tr>
                }
            </React.Fragment>
        )
    }
}

export default AcceptCreditNoteReportTable;
