import React from "react";
import { QueryRenderer, commitMutation } from "react-relay";
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import getNameResidential from "../../../libs/getNameResidential";
import localStorage from "../../../libs/localstorage";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import { Translation } from "react-i18next";
import Loading from "../../../libs/loading";
import i18n from "i18next";
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import i18next from 'i18next'

const mutation = graphql`
  mutation receiptDepositListTableMutation(
    $input: UpdateReceiptDepositAndTransactionStatusInput!
  ) {
    updateReceiptDepositAndTransactionStatus(input: $input) {
      ok
      warningText
    }
  }
`;
class ReceiptDepositListTable extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.start_date = null;
    this.state.end_date = null;
    this.state.temp_start_date = null;
    this.state.temp_end_date = null;
    this.state.search = "";
    this.state.search_input = "";
    this.state.status = this.props.status;
    this.state.redirectToPrint = false;
    this.state.advance_search = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.deleteReceiptDeposit = this.deleteReceiptDeposit.bind(this)

  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    if (this.state.status !== this.props.status) {
      this.setState(
        {
          status: this.props.status,
          checkList: [],
          check_all: false,
        },
        () => {
          this.goFirst();
        }
      );
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }

  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  getColorAndText(object) {
    let text = "";
    let color = "text-center";
    switch (object.node.status) {
      case "DRAFT":
        text = "แบบร่าง";
        break;
      case "ACTIVE":
        text = "ยังไม่ตัดชำระ";
        break;
      case "USE_PARTIAL":
        text = "ตัดชำระแล้วบางส่วน";
        break;
      case "USE_ALL":
        text = "ตัดชำระแล้วทั้งจำนวน";
        break;
      default:
        text = "รายการที่ยกเลิก";
        color = "text-center text-danger";
    }
    return <td className={color}>{text}</td>;
  }

  deleteReceiptDeposit(){
    Swal.fire({
      title: i18next.t("receipt_deposit:Are you sure to cancel this receipt deposit?"),
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18next.t("receipt_deposit:Yes"),
      cancelButtonText: i18next.t("receipt_deposit:Cancel"),
    }).then(async (result) => { 
        if(result.value){
          let { value: void_remark } = await Swal.fire({
            title: i18n.t('receipt_deposit:Note'),
            input: "text",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: i18n.t('receipt_deposit:Cancel'),
            confirmButtonText: i18n.t('receipt_deposit:Confirm'),
            inputValidator: (value) => {
              if (!value) {
                return i18n.t('receipt_deposit:Please enter the cancellation note!')
              }
            },
          });

          if (void_remark) {
            let variables = {
              input: {
                status: "void",
                receiptDepositIdList: this.state.checkList,
                voidRemark: void_remark,
              },
            };
            commitMutation(environment, {
              mutation,
              variables,
              onCompleted: (response) => {
                if (response) {
                  if (response.updateReceiptDepositAndTransactionStatus.ok) {
                    Swal.fire(i18next.t("receipt_deposit:Save successfully!"), "", "success").then(() =>
                      window.location.reload()
                    );
                  } else {
                    Swal.fire(i18next.t("receipt_deposit:Save unsuccessfully!"), response.updateReceiptDepositAndTransactionStatus.warningText, "warning").then(() =>
                      this.setState({ loading: false })
                    );
                  }
                }
              },
              onError: (err) => {
                this.setState({ loading: false });
                Swal.fire(i18next.t("receipt_deposit:Save unsuccessfully!"), "", "warning");
              },
            });
          } else {
            this.setState({
              loading: false,
            });
            Swal.fire(i18next.t("receipt_deposit:Save unsuccessfully!"), "", "warning");
          }
        }else {
          this.setState({ loading: false });
        }
       
    });
  }


  render() {
    if (this.state.redirectToPrint) {
      return (
        <Redirect
          to={{
            pathname: "/document/receipt_deposit/all/",
            state: {
              checkList: this.state.checkList,
            },
          }}
        />
      );
    }

    return (
      <div>
        <div className="row mb-2 mt-1">
          <div className="col-2">
          <Link
              to={{
                pathname: "/document/receipt_deposit/all/",
                state: {
                  checkList: this.state.checkList,
                },
              }}
            >
              {
                 this.state.status !== 'void' && 
                 <button type="button" className="btn btn-light border mb-3">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/icons/print-icon.png"
                    }
                    alt="print-icon"
                  />
                 </button>
              }
              
            </Link> 
            
            {
              this.state.status !== 'void' &&  this.state.status !== 'use_all' && this.state.status !== 'use_partial' &&
               <button type="button" className="btn btn-light border mb-3 ml-3"
                onClick={() => this.deleteReceiptDeposit()}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/icons/delete.png"
                  }
                  alt="print-icon"
                />
              </button>
            }
            
          </div>
          <div className="col-10 text-right">
            <Translation>
              {t =>
                <div className="input-group float-right w-auto ml-2">
                  <input
                    type="text"
                    className="form-control input-size"
                    placeholder={t("receipt_deposit:Search")}
                    value={this.state.search_input}
                    name="search"
                    onChange={(e) =>
                      this.setState({ search_input: e.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary form-control search-button"
                    name="search"
                    value={this.state.search_input}
                    onClick={(e) => this.handleSearch(e)}
                  >
                    {t("receipt_deposit:Search")}
                  </button>
                </div>}
            </Translation>
            {/* <button
              type="button"
              className="btn btn-outline-secondary float-right"
              onClick={() =>
                this.setState({ advance_search: !this.state.advance_search })
              }
            >
              <img src="/images/icons/filter-icon.png" alt="filter-icon" />
            </button> */}
            <InlineAdvanceFilter
              show_filter={this.state.advance_search}
              start_date={this.state.temp_start_date}
              end_date={this.state.temp_end_date}
              handleChange={this.handleChange}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col"> 
            <QueryRenderer
              environment={environment}
              query={this.props.query}
              variables={{
                search: this.state.search,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                first: this.state.first,
                last: this.state.last,
                status: this.state.status
              }}
              render={({ error, props }) => {
                if (error) {
                  return <div>{error.message}</div>;
                } else if (props) {
                  return (
                    <React.Fragment>
                      <div className="table-responsive fade-up card">
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <Translation>
                              {t =>
                                <tr>
                                  <th>
                                  {
                                     this.state.status !== 'void' && 
                                     <input type="checkbox"
                                     onChange={() => this.getAllList(props.receiptDepositViewer.allReceiptDeposit.edges,true)}
                                     checked={this.state.check_all}
                                     />
                                  }
                                  
                                  </th>
                                  <th className="text-center">{t("receipt_deposit:No.")}</th>
                                  <th className="text-center">{t("receipt_deposit:Date")}</th>
                                  <th className="text-center">{t("receipt_deposit:Code")}</th>
                                  <th>{t("receipt_deposit:Room No.")}</th>
                                  <th>{t("receipt_deposit:Name")}</th>
                                  <th>{t("receipt_deposit:Detail")}</th>
                                  <th className="text-right">{t("receipt_deposit:Amount")}</th>
                                  {this.props.status === "use_partial" && (
                                    <th className="text-right">
                                      {t("receipt_deposit:Amount of payment")}
                                    </th>
                                  )}
                                  {this.props.status === "use_partial" && (
                                    <th className="text-right">{t("receipt_deposit:Balance amount")}</th>
                                  )}
                                  <th className="text-center">{t("receipt_deposit:Date of creation")}</th>
                                  {this.props.status === "all" && (
                                    <th className="text-center">{t("receipt_deposit:Status")}</th>
                                  )}
                                  {this.props.status === "void" && (
                                    <th className="text-center">{t("receipt_deposit:Date of cancellation")}</th>
                                  )}
                                  <th className="text-center">{t("receipt_deposit:By")}</th>
                                  {this.props.status === "void" && (
                                    <th className="text-center">{t("receipt_deposit:Reason of cancellation")}</th>
                                  )}
                                </tr>}
                            </Translation>
                          </thead>
                          <tbody>
                            {props.receiptDepositViewer.allReceiptDeposit.edges.map(
                              (receipt_deposit, index) => {
                                let accumulate = 0;
                                let order_id =
                                  this.state.first -
                                  localStorage.getPageLimit();
                                return receipt_deposit.node.receiptDepositTransaction.edges.map(
                                  (transaction, t_index) => {
                                    let row_number =
                                      t_index === 0 && order_id + index + 1;
                                    let number_of_transaction =
                                      receipt_deposit.node
                                        .receiptDepositTransaction.edges.length;
                                    accumulate =
                                      accumulate + transaction.node.price;
                                    return (
                                      <tr key={transaction.node.id}>
                                        <td>
                                        {
                                          receipt_deposit.node.status !==
                                          "VOID" &&  
                                            <input type="checkbox" onChange={() =>  this.appendToCheckList(receipt_deposit.node.id)}
                                              checked={this.checkList(receipt_deposit.node.id)}
                                              />
                                         }
                                        </td>
                                        {receipt_deposit.node.status ===
                                          "VOID" ? (
                                          <td className="text-center">
                                            {row_number &&
                                              receipt_deposit.node.docNumber}
                                          </td>
                                        ) : (
                                          <td className="text-center">
                                            {row_number && (
                                              <Link
                                                to={
                                                  "/accounting/income/receipt_deposit/view/" +
                                                  receipt_deposit.node.id
                                                }
                                              >
                                                {receipt_deposit.node.docNumber}
                                              </Link>
                                            )}
                                          </td>
                                        )}
                                        <td className="text-center">
                                          {format(
                                            receipt_deposit.node.issuedDate,
                                            "DD/MM/YYYY",
                                            { locale: thLocale }
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {
                                            receipt_deposit.node.contact
                                              .refNumber
                                          }
                                        </td>
                                        <td>
                                          {receipt_deposit.node.contact
                                            .typeOfContact === "RESIDENTIAL"
                                            ? receipt_deposit.node.contact.name
                                            : "-"}
                                        </td>
                                        <td className="colorUse notKanit">
                                          {
                                            receipt_deposit.node.firstName || receipt_deposit.node.lastName ?
                                              getNameResidential(receipt_deposit.node.firstName, receipt_deposit.node.lastName)
                                              :
                                              receipt_deposit.node.contact
                                                .typeOfContact === "RESIDENTIAL"
                                                ? getNameResidential(
                                                  receipt_deposit.node.contact
                                                    .firstName,
                                                  receipt_deposit.node.contact
                                                    .lastName
                                                )
                                                : receipt_deposit.node.contact
                                                  .name}{" "}
                                          {/* {receipt_deposit.node.contact
                                            .typeOfContact === "RESIDENTIAL"
                                            ? getNameResidential(
                                                receipt_deposit.node.contact
                                                  .firstName,
                                                receipt_deposit.node.contact
                                                  .lastName
                                              )
                                            : receipt_deposit.node.contact
                                                .name}{" "} */}
                                          {
                                            receipt_deposit?.node?.payGroup === "AGENT" || receipt_deposit?.node?.payGroup === "RENT" ?
                                              <label className='text-red'>({i18n.t("AgentRole:" + receipt_deposit.node.payGroup)})</label>
                                              :
                                              ""
                                          }
                                        </td>
                                        <td>{transaction.node.description}</td>
                                        <td className="text-right">
                                          {numberWithComma(
                                            transaction.node.price
                                          )}
                                        </td>
                                        {this.props.status ===
                                          "use_partial" && (
                                            <td className="text-right">
                                              {numberWithComma(
                                                transaction.node.price -
                                                transaction.node.balance
                                              )}
                                            </td>
                                          )}
                                        {
                                          this.props.status ===
                                          "use_partial" && (
                                            <td className="text-right">
                                              {numberWithComma(
                                                transaction.node.balance
                                              )}
                                            </td>
                                          )
                                          // :
                                          // <td className="text-right">{t_index === number_of_transaction - 1 && numberWithComma(accumulate)}</td>
                                        }
                                        <td className="text-center">
                                          {format(
                                            receipt_deposit.node.added,
                                            "DD/MM/YYYY",
                                            { locale: thLocale }
                                          )}
                                        </td>
                                        {this.props.status === "void" && (
                                          <td className="text-center">
                                            {format(
                                              receipt_deposit.node.updated,
                                              "DD/MM/YYYY",
                                              { locale: thLocale }
                                            )}
                                          </td>
                                        )}
                                        {this.props.status === "all" &&
                                          this.getColorAndText(receipt_deposit)}

                                        <td>{receipt_deposit.node.creator}</td>

                                        {this.props.status === "void" && (
                                          <td className="text-center">
                                            {receipt_deposit.node.voidRemark ||
                                              "-"}
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  }
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={
                            props.receiptDepositViewer.allReceiptDeposit
                              .totalCount
                          }
                        />
                      </div>
                    </React.Fragment>
                  );
                }
                return <Loading />
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ReceiptDepositListTable;
