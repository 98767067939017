/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type genCodeA4QueryVariables = {|
  type_download?: ?string
|};
export type genCodeA4QueryResponse = {|
  +genInviteCode: ?$ReadOnlyArray<?string>,
  +selfProject: ?{|
    +juristicContactNumber: ?string
  |},
|};
export type genCodeA4Query = {|
  variables: genCodeA4QueryVariables,
  response: genCodeA4QueryResponse,
|};
*/


/*
query genCodeA4Query(
  $type_download: String
) {
  genInviteCode(typeDownload: $type_download)
  selfProject {
    juristicContactNumber
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type_download"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "typeDownload",
      "variableName": "type_download"
    }
  ],
  "kind": "ScalarField",
  "name": "genInviteCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "juristicContactNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "genCodeA4Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "genCodeA4Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1056d970c4f2ddd016a4e9abece7771a",
    "id": null,
    "metadata": {},
    "name": "genCodeA4Query",
    "operationKind": "query",
    "text": "query genCodeA4Query(\n  $type_download: String\n) {\n  genInviteCode(typeDownload: $type_download)\n  selfProject {\n    juristicContactNumber\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94d7cca8d745f140aba01a8407adad90';

module.exports = node;
