import React from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { Link } from "react-router-dom";
import getNameResidential from "../../../libs/getNameResidential";
import localStorage from "../../../libs/localstorage";
// import AdvanceFilter from "../../../libs/advanceFilter";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";

import { formatDateLocale, numberWithCommas } from "../../../utils";
import i18next from "i18next";
class PrepaidDepositListTable extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.start_date = null;
    this.state.end_date = null;
    this.state.temp_start_date = null;
    this.state.temp_end_date = null;
    this.state.search = "";
    this.state.search_input = "";
    this.state.status = this.props.status;
    this.state.checkList = [];
    this.state.check_all = false;
    this.state.advance_search = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    if (this.state.status !== this.props.status) {
      this.setState(
        {
          status: this.props.status,
          checkList: [],
          check_all: false,
        },
        () => {
          this.goFirst();
        }
      );
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  getStatusName(key) {
    let status = {
      DRAFT: "แบบร่าง",
      ACTIVE: "ยังไม่ตัดชำระ",
      USE_PARTIAL: "ตัดจ่ายชำระแล้วบางส่วน",
      USE_ALL: "ตัดจ่ายชำระแล้วทั้งจำนวน",
      VOID: "รายการที่ยกเลิก",
    };
    return status[key];
  }

  getColorAndText(object) {
    let text = "";
    let color = "text-center";
    switch (object.node.status) {
      case "DRAFT":
        text = "แบบร่าง";
        break;
      case "ACTIVE":
        text = "ยังไม่ตัดชำระ";
        break;
      case "USE_PARTIAL":
        text = "ตัดจ่ายชำระแล้วบางส่วน";
        break;
      case "USE_ALL":
        text = "ตัดจ่ายชำระแล้วทั้งจำนวน";
        break;
      default:
        text = "รายการที่ยกเลิก";
        color = "text-center text-danger";
    }
    return <td className={color}>{text}</td>;
  }

  render() {
    return (
      <div>
        <div className="row mb-2 mt-1">
          <div className="col-12 text-right">
            <div className="input-group float-right w-auto ml-2">
              <input
                type="text"
                className="form-control input-size"
                placeholder={i18next.t("PrepaidDeposit:Search")}
                value={this.state.search_input}
                name="search"
                onChange={(e) =>
                  this.setState({ search_input: e.target.value })
                }
                onKeyDown={this.handleKeyDown}
              />
              <button
                type="submit"
                className="btn btn-primary form-control search-button"
                name="search"
                value={this.state.search_input}
                onClick={(e) => this.handleSearch(e)}
              >
                {i18next.t("PrepaidDeposit:Search")}
              </button>
            </div>
            {/* <button
              type="button"
              className="btn btn-outline-secondary float-right"
              onClick={() =>
                this.setState({ advance_search: !this.state.advance_search })
              }
            >
              <img src="/images/icons/filter-icon.png" alt="filter-icon" />
            </button> */}
            <InlineAdvanceFilter
              start_date={this.state.temp_start_date}
              end_date={this.state.temp_end_date}
              handleChange={this.handleChange}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <QueryRenderer
              environment={environment}
              query={this.props.query}
              variables={{
                search: this.state.search,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                first: this.state.first,
                last: this.state.last,
                status: this.state.status,
              }}
              render={({ error, props }) => {
                if (error) {
                  return <div>{error.message}</div>;
                } else if (props) {
                  return (
                    <React.Fragment>
                      <div className="table-responsive fade-up card">
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th className="text-center"> {i18next.t("PrepaidDeposit:No.")}</th>
                              <th className="text-center"> {i18next.t("PrepaidDeposit:Date")}</th>
                              <th className="text-center"> {i18next.t("PrepaidDeposit:Payable code")}</th>
                              {/*<th>เลขห้อง</th>*/}
                              <th> {i18next.t("PrepaidDeposit:Name")}</th>
                              <th> {i18next.t("PrepaidDeposit:Description")}</th>
                              <th className="text-right"> {i18next.t("PrepaidDeposit:Amount")}</th>
                              {/* <th className="text-right">จำนวนเงินรวม</th> */}
                              <th className="text-center"> {i18next.t("PrepaidDeposit:Create Date")}</th>
                              {this.props.status === "void" &&
                                <th className="text-center"> {i18next.t("PrepaidDeposit:Canceled Date")}</th>
                              }
                              {this.props.status === "all" && (<th className="text-center"> {i18next.t("PrepaidDeposit:Status")}</th>
                              )}
                              <th className="text-center"> {i18next.t("PrepaidDeposit:By")}</th>
                              {this.props.status === "void" && (<th className="text-center"> {i18next.t("PrepaidDeposit:Reason")}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {props.prepaidDepositViewer.allPrepaidDeposit.edges.map((prepaid_deposit, index) => {
                              let order_id = this.state.first - localStorage.getPageLimit();
                              return prepaid_deposit.node.prepaidDepositTransaction.edges.map((transaction, t_index) => {
                                let row_number = t_index === 0 && order_id + index + 1;
                                if (transaction.node.price !== transaction.node.balance) {
                                  row_number = index + 1;
                                }

                                return (
                                  <tr key={transaction.node.id}>
                                    <td className="text-center">
                                      {row_number && (
                                        <React.Fragment>
                                          {prepaid_deposit.node.status === "VOID" ? (prepaid_deposit.node.docNumber)
                                            : (
                                              <Link
                                                to={
                                                  "/accounting/expense/prepaid-deposit/view/" +
                                                  prepaid_deposit.node.id
                                                }
                                              >
                                                {prepaid_deposit.node.docNumber}
                                              </Link>
                                            )}
                                        </React.Fragment>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {formatDateLocale(prepaid_deposit.node.issuedDate)}
                                    </td>
                                    <td className="text-center">
                                      {
                                        prepaid_deposit.node.contact
                                          .refNumber
                                      }
                                    </td>
                                    {/*<td>{prepaid_deposit.node.contact.typeOfContact === 'RESIDENTIAL' ? prepaid_deposit.node.contact.name : '-'}</td>*/}
                                    <td>
                                      {prepaid_deposit.node.contact.typeOfContact === "RESIDENTIAL" ?
                                        getNameResidential(prepaid_deposit.node.contact.firstName, prepaid_deposit.node.contact.lastName)
                                        : prepaid_deposit.node.contact.name}{" "}
                                    </td>
                                    <td>{transaction.node.description}</td>
                                    <td className="text-right">
                                      {numberWithCommas(transaction.node.total)}
                                    </td>

                                    {/* <td className="text-right">
                                          {t_index === number_of_transaction - 1 && numberWithCommas(accumulate)}
                                        </td> */}

                                    <td className="text-center">
                                      {formatDateLocale(prepaid_deposit.node.added)}
                                    </td>
                                    {this.props.status === "void" &&
                                      <td className="text-center">
                                        {
                                          formatDateLocale(prepaid_deposit.node.updated)
                                        }
                                      </td>
                                    }
                                    {this.props.status === "all" &&
                                      this.getColorAndText(prepaid_deposit)}
                                    <td className="text-center">{prepaid_deposit.node.creator}</td>
                                    {this.props.status === "void" &&
                                      (<td className="text-center">
                                        {prepaid_deposit.node.voidRemark || "-"}
                                      </td>
                                      )}
                                  </tr>
                                );
                              }
                              );
                            }
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={props.prepaidDepositViewer.allPrepaidDeposit.totalCount}
                        />
                      </div>
                    </React.Fragment>
                  );
                }
                return <div>Loading</div>;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PrepaidDepositListTable;
