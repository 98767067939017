import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import ProductServicePayableList from "../productServicePayableList";
import ContactPayableReportList from "../contactPayableReportList";
import PayableOutstandingReportTable from "./payableOutstandingReportTable";
import ExportPayableOutstandingReport from "./server_export";
import _ from "lodash";

import Loading from "../../../../libs/loading";
import postApiUseing from '../../../../libs/postApiUseing';
import i18next from "i18next";
import BackButtonIcon from '../../../../components/BackBtn/indexBack';

const query = graphql`
  query payableOutstandingReportQuery(
    $first: Int
    $last: Int
    $startDate: DateTime
    $endDate: DateTime
    $dueDate: Date!
    $search: String
    $productAndService: String
    $receiveIssuedDate: Date
    $contact: String
    $rangeOverdueDate: [Int]
    $overdueDate: String
  ) {
    allPurchaseRecordGroup(
      first: $first
      last: $last
      startDate: $startDate
      endDate: $endDate
      search: $search
      productAndService: $productAndService
      contact: $contact
      order: "doc_number"
      payableOutstanding: true
      status_In: "active, partial_payment, paid, waiting_pay"
      rangeOverdueDate: $rangeOverdueDate
      overdueDate: $overdueDate
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          dueDate
          contact {
            id
            name
            refNumber
          }
          total
          purchaseRecord {
            edges {
              node {
                id
                description
                total
                payRecord(payRecordGroup_IssuedDate_Lte: $receiveIssuedDate,payRecordGroup_Status: "paid"){
                  edges {
                    node {
                      amount
                      whtAmount
                      payRecordGroup{
                        issuedDate
                      }
                    }
                  }
                }
                acceptCreditNoteTransaction(status: "paid"){
                  edges {
                    node {
                      total
                      issuedDate
                    }
                  }
                }
                prepaidDepositTransactionUsed{
                  edges {
                    node {
                      amount
                    }
                  }
                }
              
              }
            }
          }
        }
      }
    }
    summaryPurchaseRecordGroup(
      startDate: $startDate
      endDate: $endDate
      search: $search
      productAndService: $productAndService
      contact: $contact
      payableOutstanding: true
      statusIn: ["active" , "partial_payment", "paid" , "waiting_pay"]
      rangeOverdueDate: $rangeOverdueDate
      overdueDate: $overdueDate
    )
    reportOutstandingPayableSummary(
      dueDate: $dueDate
      contactId: $contact
      search: $search
      rangeOverdueDate: $rangeOverdueDate
      overdueDate: $overdueDate
    ) {
      edges {
        node {
          id
          name
          refNumber
          firstName
          lastName
          typeOfContact
          outstandingBalance
        }
      }
    }
  }
`;

class PayableOutstandingReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.product_and_service = "";
    this.state.temp_product_and_service = "";
    this.state.customer_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.overdue_date = "";
    this.state.temp_overdue_date = "";
    this.state.range_overdue_date = [null, null];
    this.state.temp_range_overdue_date = [null, null]

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "payableOutstandingReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {

    if (e.target.name === "temp_overdue_date") {
      let min_date = null;
      let max_date = null;
      if (e.target.value === "1-30") {
        min_date = 1;
        max_date = 30;
      } else if (e.target.value === "31-60") {
        min_date = 31;
        max_date = 60;
      } else if (e.target.value === "61-90") {
        min_date = 61;
        max_date = 90;
      } else if (e.target.value === "91-120") {
        min_date = 91;
        max_date = 120;
      } else if (e.target.value === "121-150") {
        min_date = 121;
        max_date = 150;
      } else if (e.target.value === "151-180") {
        min_date = 151;
        max_date = 180;
      } else if (e.target.value === "over180") {
        min_date = 181;
        max_date = null;
      } else if (e.target.value === "not_overdue") {
        min_date = null;
        max_date = 0;
      }
      this.setState({
        temp_range_overdue_date: [min_date, max_date],
      })
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      product_and_service: this.state.temp_product_and_service,
      contact: this.state.temp_contact,
      range_overdue_date: this.state.temp_range_overdue_date,
      overdue_date: this.state.temp_overdue_date,
    });
    super.goFirst()
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-payable"}
                  LinkText={i18next.t("DashboardPayable:Detailed Outstanding Payables Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_payable_print",
                      }) && <ExportPayableOutstandingReport state={this.state} />}
                    </div>
                  }
                /> 
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>
                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      {/* <div className="float-right ml-2">
                  <DatePickerAdapter
                    name="temp_start_date"
                    className="form-control"
                    selected={this.state.temp_start_date}
                    maxDate={this.state.end_date}
                    onChange={this.handleChange}
                    required={true}
                  />
                </div> */}
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactPayableReportList
                            handleChange={this.handleChange}
                            contact={this.state.temp_contact}
                          />

                          <ProductServicePayableList
                            handleChange={this.handleChange}
                            product_and_service={
                              this.state.temp_product_and_service
                            }
                          />

                          <div className="col-md-4 border-right">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Overdue Date (Days)")}</label>
                              <select name="temp_overdue_date" onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow">
                                <option value="">{i18next.t("FilterPayable:All")}</option>
                                <option value="not_overdue">{i18next.t("receivableOutstandingSummaryReport:Not Exceeding Due Date")}</option>
                                <option value="1-30">1 - 30</option>
                                <option value="31-60">31 - 60</option>
                                <option value="61-90">61 - 90</option>
                                <option value="91-120">91 - 120</option>
                                <option value="121-150">121 - 150</option>
                                <option value="151-180">151 - 180</option>
                                <option value="over180">{i18next.t("receivableOutstandingReport:More Than")} 180</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{
                      first: this.state.first,
                      last: this.state.last,
                      startDate: this.state.start_date,
                      endDate: this.state.end_date,
                      dueDate: this.state.end_date.toISOString().slice(0, 10),
                      search: this.state.search,
                      productAndService: this.state.product_and_service,
                      contact: this.state.contact,
                      rangeOverdueDate: this.state.range_overdue_date,
                      overdueDate: this.state.overdue_date
                    }}
                    render={({ error, props }) => {
                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover"
                                      style={{ fontSize: 12 }}
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("PayableOutstandingReport:Order")}
                                          </th>
                                          <th rowSpan={2} className="align-middle">
                                          {i18next.t("PayableOutstandingReport:Code")}
                                          </th>
                                          <th rowSpan={2} className="align-middle">
                                          {i18next.t("PayableOutstandingReport:Name")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            width={130}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("PayableOutstandingReport:Not Due Yet")}
                                          </th>
                                          <th rowSpan={2} className="align-middle">
                                          {i18next.t("PayableOutstandingReport:Detail")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("PayableOutstandingReport:Date")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-center"
                                          >
                                            {i18next.t("PayableOutstandingReport:Outstanding")}
                                          </th>
                                          <th
                                            rowSpan={2}
                                            className="align-middle text-right"
                                          >
                                            {i18next.t("PayableOutstandingReport:Outstanding (Days)")}
                                          </th>
                                          {(this.state.overdue_date === "" || this.state.overdue_date === "not_overdue") &&
                                            <th rowSpan={2}
                                              className="align-middle text-center">{i18next.t("PayableOutstandingReport:Total")}
                                            </th>
                                          }
                                          {this.state.overdue_date !== "not_overdue" &&
                                            <th colSpan={this.state.overdue_date === "" ? 7 : 1}
                                              className="text-center">{i18next.t("PayableOutstandingReport:Overdue (Days)")}
                                            </th>
                                          }
                                          <th rowSpan={2}
                                            className="align-middle text-right"
                                          >
                                            {i18next.t("PayableOutstandingReport:Outstanding")}
                                          </th>
                                          <th rowSpan={2}
                                            className="align-middle text-right"
                                          >
                                            {i18next.t("PayableOutstandingReport:Net Outstanding")}
                                          </th>
                                        </tr>
                                        {this.state.overdue_date === "" ?
                                          <tr className="border-top">
                                            <th className="text-center" width={50}>1-30</th>
                                            <th className="text-center" width={60}>31-60</th>
                                            <th className="text-center" width={60}>61-90</th>
                                            <th className="text-center" width={60}>91-120</th>
                                            <th className="text-center" width={60}>121-150</th>
                                            <th className="text-center" width={60}>151-180</th>
                                            <th className="text-center">{i18next.t("PayableOutstandingReport:More than")} 180</th>
                                          </tr> :
                                          this.state.overdue_date !== "not_overdue" &&
                                          <tr>
                                            <th className="text-center">{this.state.overdue_date === "over180" ? "มากกว่า 180" : this.state.overdue_date}</th>
                                          </tr>
                                        }
                                      </thead>
                                      <tbody>
                                        <PayableOutstandingReportTable
                                          props={props}
                                          state={this.state}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.allPurchaseRecordGroup.totalCount}
                              />
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>
            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PayableOutstandingReport;
