import React from 'react'
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import WrapperContent from '../../../components/wrapper/wrapperContent'
import _ from "lodash"
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next'
import SeacrhDetails from '../../../components/search/indexSearch';
import ComponentPagination from "../../../libs/componentPagination";
import APIClaimReport from '../../../api/claimReport'
import ReactPaginate from 'react-paginate';
import ClaimReportTopMenuNavigation from './claimReportTopMenuNavigation';
import ClaimReportExcel from './claimReportExcel'
import { Dropdown  } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import '../style/claimReport.scss'
import i18n from "i18next";
import {getTime, lastDayOfMonth, setDate ,format } from "date-fns";
import ClaimTopMenuNavigation from '../claimTopMenuNavigation';
import i18next from 'i18next';

const head_table = [
    { value: "no", label: i18n.t("claimReport:no.") },
    { value: "date", label: i18n.t("claimReport:date") },
    { value: "title", label: i18n.t("claimReport:title") },
    { value: "claimarea", label: i18n.t("claimReport:claimArea") },
    { value: "tel", label: i18n.t("claimReport:tel") },
    { value: "price", label: i18n.t("claimReport:price") },
    { value: "numofapprove", label: i18n.t("claimReport:numofapprove") },
    { value: "status", label: i18n.t("claimReport:status") },
  ];
class ClaimReportList extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state = {
            status : this.props.match.params.status,
            search: "",
            // startDate:'',
            // endDate:'',
            head_table: head_table,
            startDate : setDate(new Date(),1),
            endDate : lastDayOfMonth(new Date()),
            image_light_box: "",
            open_light_box: false,
            redirect: false,
            first: 0,
            last: 10,
            index_first: 0,
            index_last: 10,
            pageSize: 10,
            pageNow: 1,
            pageAll: 0,
            claimReportList: [],
            insuranceData:[],
            allApprovedAmount:null,
            allClaimAmount:null,
            selectedShow: [
                { value: "", label: i18next.t("claimRequstList:All") },
                { value: "finish", label: i18next.t("claimRequstList:Finished") },
                { value: "void", label: i18next.t("claimRequstList:Canceled") }
            ]

        }

        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.handlePropsChange = this.handlePropsChange.bind(this);
        this.handlePageSize = this.handlePageSize.bind(this)
    }

    

    getStatus(status){
        let valueResult = ""
        let colorResult = ""
    
        switch (status) {
            case "consider_fail": 
                valueResult = i18next.t("claimRequstList:Canceled") 
                colorResult = "statusRed"
                break;
            case "not_approve":
                valueResult = i18next.t("claimRequstList:Canceled") 
                colorResult = "statusRed"
                break;
            case "void":
                valueResult = i18next.t("claimRequstList:Canceled") 
                colorResult = "statusRed"
                break;
            case "finish": 
                valueResult = i18next.t("claimRequstList:Finished")
                colorResult = "statusGreen"
                break;
        }
        return (
          <div className={`${colorResult}`}>
            <span>{valueResult}</span>
          </div>
        )
    }

    getData() {
        let dataParams = {
            first: this.state.first,
            last: this.state.last,
            status: this.state.status,
            search: this.state.search,
            startDate : this.state.startDate,
            endDate: this.state.endDate,
            report: true
        }



        APIClaimReport.getClaimReportALL(dataParams).then((res) => {
            if (res.data) {
                this.setState({ 
                    claimReportList: res.data.claim_list, 
                    allApprovedAmount: res.data.all_approved_amount,
                    allClaimAmount: res.data.all_claim_amount,
                    totalCountRecord: res.data.num_record,
                    pageAll: Math.ceil(res.data.num_record / this.state.last)
                })
            }
        })
    }

    getInsurance() {
        APIClaimReport.getInsuranceCurrent().then((res) => {
            if (res.data) {
                this.setState({ 
                    insuranceData : res.data.insurance_list[0]
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevState.search !==  this.state.search || prevState.first !== this.state.first || prevState.last !== this.state.last || prevState.status !==  this.state.status || 
            prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
          this.getData()
        }
    
        if(this.state.status !== this.props.match.params.status){
            this.setState({
                status : this.props.match.params.status
            }) 
        }

    }

    componentDidMount() {
        this.getData()
        this.getInsurance()
    }

    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({ page });
    };


    onChangeSearch(text) {
        this.setState({ search: text });
    }

    handlePageSize(e){
        this.setState({
             pageSize: parseInt(e.target.value), 
             pageNow: 1, 
             index_first: 0, 
             index_last: parseInt(e.target.value), 
             data: [] 
            }, () => {
            this.getData()
        })
    }
    handlePropsChange(channel_list) {

        let search = ""
        let start_date = null
        let end_date = null

        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            }
            else if (e.name === "start_date" && e.value !== '') {
                start_date = e.value
            }
            else if (e.name === "end_date" && e.value !== '') {
                end_date = e.value
            }
        

        });

        this.setState({
            search: search,
            startDate: format(start_date, "DD/MM/YYYY") != "Invalid Date" ? format(start_date, "DD/MM/YYYY") : start_date,
            endDate: format(end_date, "DD/MM/YYYY") != "Invalid Date" ? format(end_date, "DD/MM/YYYY") : end_date,
        })
    }


  render() {
    return (
      <Wrapper>
        <Header/>
        <Sidebar/>
        <WrapperContent disabledOverflowX={true}>
        <ClaimTopMenuNavigation mini={true} />
        <Translation >
                        {
                            t =>
                                <div className="container-fluid box claim-report">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/purchasing">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t('claimReport:SinMai Reports Lists')}</span>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                     <div className="col-4">
                                     <Link to="/purchasing/claim/view-report-all">
                                            <button className="btn mr-2 btn-view-all">
                                                {t("claimRequstList:Overview")}
                                            </button>
                                     </Link>
                                     </div>
                                     <div className="col-5">
                                     <div style={{ backgroundColor: "#E6F2FE", borderRadius: 4 ,padding: "16px 24px" }}>
                                         <label className='label16' style={{ color: "#1567B4" }}>
                                            <div style={{display:'flex',fontSize:'16px'}}>
                                            <div className='pr-8'>
                                                <u>{t("claimRequst:WarrantyBy")} {this.state.insuranceData?.insurance_detail
                                             && this.state.insuranceData?.insurance_detail.main_insurance_company.name
                                             } </u>
                                            
                                            </div>
                                            <div>
                                            {t("claimRequst:WarrantyWhen")}  {this.state.insuranceData?.insurance_detail?.insurance_start_date} {this.state.insuranceData?.insurance_detail?.insurance_end_date} 
                                            </div>
                                            </div>

                                            </label>
                                     </div>
                                     </div>
                                     <div>
                                     <div className="col-md-8 d-flex justify-content-end">
                                     {
                                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'claim_print' }) &&
                                        <Dropdown>
                                            <DropdownToggle id="dropdown-item-button" style={{padding:'6px 10px'}} bsPrefix = 'dropdown-export dropdown-toggle'>
                                                {t("Allaction:Print")}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ClaimReportExcel head_table={this.state.head_table} state={this.state}/>
                                                <Dropdown.Item>
                                                     <Link to={"/purchasing/claim/report-claim-list-pdf/" + 
                                                        (this.state.startDate === '' ? this.state.startDate : getTime(this.state.startDate)) + "/"+(this.state.endDate === '' ? '' : getTime(this.state.endDate)) + "/" + this.state.search} className="text-black">
                                                            <p className="text-black" style={{margin:'0',padding:'0'}}>PDF</p>
                                                    </Link>
                                                </Dropdown.Item>
                                            </DropdownMenu>
                                        </Dropdown>
                                        }
                                      </div>
                                     </div>
                                    </div>
                                    <ClaimReportTopMenuNavigation />
                                    <SeacrhDetails
                                        text={[t("claim:Choose Show")]}
                                        seleteDetail={this.state.selectedShow}
                                        selectIs={false}
                                        startDateIs={true}
                                        endDateIs={true}
                                        searchBoxId={true}
                                        search={this.state.search}
                                        start_date={this.state.start_date}
                                        end_date={this.state.end_date}
                                        handlePropsChange={this.handlePropsChange}
                                    />

                                    <div className="content-inner">
                                        <div className="row justify-content-end mb-2">
                                            <div className="col-3">
                                            </div>
                                        </div>

                                        <div className="card fade-up">
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead className="thead-light">
                                                        <tr className='text-center'>
                                                            <th />
                                                            <th style={{width:160}}>{t("claimReport:no.")}</th>
                                                            <th style={{width:160}}>{t("claimReport:date")}</th>
                                                            <th>{t("claimReport:title")}</th>
                                                            <th>{t("claimReport:claimArea")}</th>
                                                            <th>{t("claimReport:tel")}</th>
                                                            <th>{t("claimReport:price")}</th>
                                                            <th>{t("claimReport:numofapprove")}</th>
                                                            <th>{t("claimReport:status")}</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                             _.map(this.state.claimReportList, (n, inx) => {
                                                                return (
                                                                    <>
                                                                   <tr key={inx} className='text-center'>
                                                                            <td></td>
                                                                            <td>
                                                                            <Link to={"/purchasing/claim/report-detail/" + n.claim_detail.id}>
                                                                                {n.claim_detail.doc_number}
                                                                            </Link>
                                                                            </td>
                                                                            <td>{n.claim_detail.issued_date}</td>
                                                                            <td>{n.claim_detail.header}</td>
                                                                            <td>{n.claim_detail.claim_type === 'public' ? t("claimRequstList:Common Area") : n.claim_detail.residential.name}</td>
                                                                            <td>{n.claim_detail.phone_contact}</td>
                                                                            <td>{n.claim_detail.claim_amount}</td>
                                                                            <td>{n.claim_detail.approved_amount}</td>
                                                                            <td style={{width:'100px'}}>
                                                                            {this.getStatus(n.claim_detail.status)}
                                                                            </td>
                                                                    </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                                    <tr>
                                                                        <td colSpan={"5"}> </td>
                                                                        <td style={{textAlign:'right'}}>{t("claimRequstList:Total")}</td>
                                                                        <td className='text-center'>{this.state.allClaimAmount}</td>
                                                                        <td className='text-center'>{this.state.allApprovedAmount}</td>
                                                                        <td ></td>
                                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='row mt-5'>
                                            <div className='col-6'>
                                                <select className='page'  onChange={this.handlePageSize}>
                                                    <option value="10">{t("silvermanguard:show")} 10 {t("silvermanguard:items")}</option>
                                                    <option value="20">{t("silvermanguard:show")} 20 {t("silvermanguard:items")}</option>
                                                    <option value="50">{t("silvermanguard:show")} 50 {t("silvermanguard:items")}</option>
                                                    <option value="100">{t("silvermanguard:show")} 100 {t("silvermanguard:items")}</option>
                                                </select>
                                            </div>
                                            <div className='col-6 text-right'>
                                                <div className='pullright' style={{ marginTop: '22px' }}>
                                                    {t("silvermanguard:listAll")} {this.state.totalCountRecord} &nbsp;
                                                    {this.state.pageAll > 0 && (
                                                        <ReactPaginate style={{ marginTop: '-7px' }}
                                                            previousLabel={"previous"}
                                                            nextLabel={"next"}
                                                            breakLabel={ <span className="btn page-link">...</span>}
                                                            breakClassName={"break-me"}
                                                            pageClassName="page-item"
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={4}
                                                            previousClassName="sr-only"
                                                            containerClassName={"pagination float-right"}
                                                            subContainerClassName={"pagination-page"}
                                                            nextLinkClassName="sr-only"
                                                            pageLinkClassName="page-link"
                                                            activeClassName={"active"}
                                                            pageCount={this.state.totalCountRecord}
                                                            onPageChange={this.handlePageClick}
                                                        />
                                                    )}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </Translation>
        </WrapperContent>
      </Wrapper>
    )
  }
}
export default ClaimReportList