import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';


class GuaranteeNavigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t=>
                        <ul>
                            <li>
                                <NavLink to="/accounting/guarantee-money-received/list/received">
                                    {t("guarantee_money_received:Guarantee money received")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/guarantee-money-received/list/clear">
                                    {t("guarantee_money_received:Clear the guarantee")}
                                </NavLink>
                            </li>
                        </ul>}
                    </Translation>
                </div>
            </div>
        )
    }
}

export default GuaranteeNavigation;