const _ = require('lodash');

export function getTypeProject(type) {

    let typeText = ""

    let typeHeighName = [
        "condominium",
        "apartment",
        "dormitory",
    ]

    let typeLowName = [
        "building",
        "single_home",
        "town_house",
        "land_allocate",
        "village",
    ]

    let typeCommercialName = [
        "office",
        "mall",
        "other",
    ]

    try {
        if(_.includes(typeHeighName, _.toLower(type))){
            typeText = "highType"
        }else if(_.includes(typeLowName, _.toLower(type))){
            typeText = "lowType"
        }else if(_.includes(typeCommercialName,_.toLower(type))){
            typeText = "commercialType"
        } 
    }catch (err) {
        console.log("err",err);
    }
    
    return typeText   
}



export function getWordType(val) {
    let result = ""

    try {
        switch (val) {
            case "highType":
            result = "ห้องชุด"
            break;
            case "lowType":
            result = "หมู่บ้าน"
            break;
            case "commercialType":
            result = 'อาคารพาณิชย์'
            break;
            default:
            result = 'ห้องชุด'
        }
    }catch (err) {
        console.log("err",err);
    }

    return result
}

export function getTypeNiti(type) {

    let result; 

    try {
        switch (type) {
            case "highType":
            result = 'อาคารชุดฯ'
            break;
            case "lowType":
            result = 'หมู่บ้านจัดสรร'
            break;
            case "commercialType":
            result = 'อาคารพาณิชย์'
            break;
            default:
            result = 'อาคารชุดฯ'
       }
    }catch (err) {
        console.log("err",err);
    }
    return result;
}

export function getTypeNiti2(type) {

    let result; 

    try {
        switch (type) {
            case "highType":
            result = 'อาคารชุด'
            break;
            case "lowType":
            result = 'หมู่บ้านจัดสรร'
            break;
            case "commercialType":
            result = 'อาคารพาณิชย์'
            break;
            default:
            result = 'อาคารชุดฯ'
       }
    }catch (err) {
        console.log("err",err);
    }
    return result;
}

export function getProductTypeName(type){
    let result = ""
    try {
        switch (type) {
            case "highType":
            result = 'highType'
            break;
            case "lowType":
            result = 'lowType'
            break;
            case "commercialType":
            result = 'commercialType'
            break;
            default:
            result = 'highType'
        }
    }catch (err) {
        console.log("err",err);
    }
    return result; 

    // let result = ""
    // try {
    //     switch (type) {
    //         case "highType":
    //         result = 'เจ้าของห้องชุด'
    //         break;
    //         case "lowType":
    //         result = 'เจ้าของบ้าน'
    //         break;
    //         case "commercialType":
    //         result = 'เจ้าของอาคารพาณิชย์'
    //         break;
    //         default:
    //         result = 'เจ้าของห้องชุด'
    //     }
    // }catch (err) {
    //     console.log("err",err);
    // }
    // return result; 
}



