import i18next from 'i18next';
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import './collection.scss'

class Navigation extends Component {

    render() {
        return (
            <div className="row" id="collection-letter">
                <div className="col">
                    <ul className="navigate">
                        <li>
                            <NavLink to="/accounting/collection_letters/list/all" >
                                <span>{i18next.t("collectionLettersListTable:All")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection_letters/list/draft" >
                                <span>{i18next.t("collectionLettersListTable:Awaiting Collection")}</span> 
                                {
                                    this.props.noti ?
                                    <span style={{ backgroundColor: 'red', padding: '4px 12px', borderRadius: 26,color: 'white',fontWeight: 600 }}>{this.props.noti}</span>
                                    :
                                    ''
                                }
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection_letters/list/1" >
                                <span>{i18next.t("collectionLettersListTable:Collection Letter No.")} 1</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection_letters/list/2" >
                                <span>{i18next.t("collectionLettersListTable:Collection Letter No.")} 2</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection_letters/list/3" >
                                <span>{i18next.t("collectionLettersListTable:Collection Letter No.")} 3</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection_letters/list/4" >
                                <span>{i18next.t("collectionLettersListTable:Legal Proceedings")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection_letters/list/paid" >
                                <span>{i18next.t("collectionLettersListTable:Paid")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/collection_letters/list/void">
                                <span>{i18next.t("collectionLettersListTable:Canceled")}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;