/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClearAdvanceStatus = "CLEAR" | "VOID" | "%future added value";
export type clearAdvanceQueryVariables = {|
  id: string,
  status?: ?string,
|};
export type clearAdvanceQueryResponse = {|
  +allAdvance: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +issuedDate: any,
        +docNumber: string,
        +description: string,
        +withdrawer: string,
        +amount: number,
      |}
    |}>,
  |},
  +clearAdvance: ?{|
    +id: string,
    +docNumber: string,
    +issuedDate: any,
    +description: string,
    +returnPrice: number,
    +overPrice: number,
    +status: ClearAdvanceStatus,
    +voidRemark: ?string,
    +clearAdvanceTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccount: {|
            +id: string,
            +chartOfAccountCode: string,
            +name: string,
          |},
          +description: string,
          +price: number,
          +refClearAdvanceTransactionDoc: ?string,
        |}
      |}>
    |},
    +advance: {|
      +id: string,
      +amount: number,
    |},
    +bankAccountTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
          +date: ?any,
          +bankAccount: {|
            +bankName: string,
            +accountNumber: string,
          |},
        |}
      |}>
    |},
    +cashTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
        |}
      |}>
    |},
    +chequeTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +date: any,
          +chequeNumber: string,
          +bankAccount: ?{|
            +id: string,
            +branch: string,
            +accountNumber: string,
            +accountName: string,
            +bankName: string,
          |},
          +price: number,
        |}
      |}>
    |},
    +advancePettyCashChannel: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
          +date: ?any,
          +setPettyCash: ?{|
            +docNumber: string,
            +description: ?string,
            +withdrawer: ?string,
          |},
        |}
      |}>
    |},
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
|};
export type clearAdvanceQuery = {|
  variables: clearAdvanceQueryVariables,
  response: clearAdvanceQueryResponse,
|};
*/


/*
query clearAdvanceQuery(
  $id: ID!
  $status: String
) {
  allAdvance(status_Icontains: $status) {
    totalCount
    edges {
      node {
        id
        issuedDate
        docNumber
        description
        withdrawer
        amount
      }
    }
  }
  clearAdvance(id: $id) {
    id
    docNumber
    issuedDate
    description
    returnPrice
    overPrice
    status
    voidRemark
    clearAdvanceTransaction {
      edges {
        node {
          id
          chartOfAccount {
            id
            chartOfAccountCode
            name
          }
          description
          price
          refClearAdvanceTransactionDoc
        }
      }
    }
    advance {
      id
      amount
    }
    bankAccountTransaction {
      edges {
        node {
          id
          price
          date
          bankAccount {
            bankName
            accountNumber
            id
          }
        }
      }
    }
    cashTransaction {
      edges {
        node {
          id
          price
        }
      }
    }
    chequeTransaction {
      edges {
        node {
          id
          date
          chequeNumber
          bankAccount {
            id
            branch
            accountNumber
            accountName
            bankName
          }
          price
        }
      }
    }
    advancePettyCashChannel {
      edges {
        node {
          id
          price
          date
          setPettyCash {
            docNumber
            description
            withdrawer
            id
          }
        }
      }
    }
  }
  viewer {
    allChartOfAccount(codeList: ["5"]) {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "withdrawer",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "status_Icontains",
      "variableName": "status"
    }
  ],
  "concreteType": "AdvanceNodeConnection",
  "kind": "LinkedField",
  "name": "allAdvance",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdvanceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdvanceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returnPrice",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overPrice",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ClearAdvanceTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "clearAdvanceTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClearAdvanceTransactionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClearAdvanceTransactionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ChartOfAccountNode",
              "kind": "LinkedField",
              "name": "chartOfAccount",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/),
            (v15/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refClearAdvanceTransactionDoc",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "AdvanceNode",
  "kind": "LinkedField",
  "name": "advance",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "CashTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "cashTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CashTransactionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CashTransactionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "chequeTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChequeTransactionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChequeTransactionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v18/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chequeNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "bankAccount",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "branch",
                  "storageKey": null
                },
                (v20/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accountName",
                  "storageKey": null
                },
                (v19/*: any*/)
              ],
              "storageKey": null
            },
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "codeList",
      "value": [
        "5"
      ]
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v14/*: any*/),
            (v13/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(codeList:[\"5\"])"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clearAdvanceQuery",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ClearAdvanceNode",
        "kind": "LinkedField",
        "name": "clearAdvance",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "bankAccountTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v15/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v21/*: any*/),
          (v22/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvancePettyCashChannelNodeConnection",
            "kind": "LinkedField",
            "name": "advancePettyCashChannel",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvancePettyCashChannelNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdvancePettyCashChannelNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v15/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SetPettyCashNode",
                        "kind": "LinkedField",
                        "name": "setPettyCash",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clearAdvanceQuery",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ClearAdvanceNode",
        "kind": "LinkedField",
        "name": "clearAdvance",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "bankAccountTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v15/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v21/*: any*/),
          (v22/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvancePettyCashChannelNodeConnection",
            "kind": "LinkedField",
            "name": "advancePettyCashChannel",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvancePettyCashChannelNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdvancePettyCashChannelNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v15/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SetPettyCashNode",
                        "kind": "LinkedField",
                        "name": "setPettyCash",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v23/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d22b97f4fb7a95b379ae4d6385e769a",
    "id": null,
    "metadata": {},
    "name": "clearAdvanceQuery",
    "operationKind": "query",
    "text": "query clearAdvanceQuery(\n  $id: ID!\n  $status: String\n) {\n  allAdvance(status_Icontains: $status) {\n    totalCount\n    edges {\n      node {\n        id\n        issuedDate\n        docNumber\n        description\n        withdrawer\n        amount\n      }\n    }\n  }\n  clearAdvance(id: $id) {\n    id\n    docNumber\n    issuedDate\n    description\n    returnPrice\n    overPrice\n    status\n    voidRemark\n    clearAdvanceTransaction {\n      edges {\n        node {\n          id\n          chartOfAccount {\n            id\n            chartOfAccountCode\n            name\n          }\n          description\n          price\n          refClearAdvanceTransactionDoc\n        }\n      }\n    }\n    advance {\n      id\n      amount\n    }\n    bankAccountTransaction {\n      edges {\n        node {\n          id\n          price\n          date\n          bankAccount {\n            bankName\n            accountNumber\n            id\n          }\n        }\n      }\n    }\n    cashTransaction {\n      edges {\n        node {\n          id\n          price\n        }\n      }\n    }\n    chequeTransaction {\n      edges {\n        node {\n          id\n          date\n          chequeNumber\n          bankAccount {\n            id\n            branch\n            accountNumber\n            accountName\n            bankName\n          }\n          price\n        }\n      }\n    }\n    advancePettyCashChannel {\n      edges {\n        node {\n          id\n          price\n          date\n          setPettyCash {\n            docNumber\n            description\n            withdrawer\n            id\n          }\n        }\n      }\n    }\n  }\n  viewer {\n    allChartOfAccount(codeList: [\"5\"]) {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12d932495fb2e71e626b075c2cceccfd';

module.exports = node;
