import getApiRoot from "../../libs/getAPIRoot";
import axios from "axios";
import {format} from 'date-fns';

async function getOwnershipList(token,residentialID,orderBy,startDate,endDate,search,indexFirst,indexLast){
    let start_date = startDate !== '' ? format(startDate,'YYYY-MM-DD') : ''
    let end_date = endDate !== '' ? format(endDate,'YYYY-MM-DD') : ''

    return axios.get(`${getApiRoot()}ownership/tenant`, {
        headers: {
            'authorization' : `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            residentialID: residentialID,
            orderBy : orderBy,
            startDate : start_date,
            endDate : end_date,
            search : search,
            indexFirst : indexFirst,
            indexLast : indexLast,
        },
    });
}

async function changeOwnership(data,token){
    const response = await fetch(
        getApiRoot() + 'ownership/tenant',
        {
            method : 'POST',
            headers: {
                'authorization' : `JWT ${token}`,
                'Content-Type': 'application/json'
            },
            body : JSON.stringify(data)
        }
    ).then((response) => {
        // Check if the request is 200
        if (response.ok) {
            let data = response;
            if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
                data = response.json();
            }
            return data;
        }
        return Promise.reject(response);
    });
   return response
}

export default {getOwnershipList,changeOwnership}