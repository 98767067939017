import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigationMenuSetup from "../libs/navigationMenuSetup";

const _ = require('lodash');

class DeveloperTopMenuNavigation extends Component {

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }

    render() {
        return (
            <Translation>
                {t =>
                
                    <div className="container-fluid bg-gray">
                        <div className={"row "
                            + (this.props.mini ? "mini " : "")
                            + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                            {this.props.center &&
                                <div className="col-12 mt-5 mb-5"><h3 className="text-center">{t('navigation:developer')}</h3></div>
                            }

                            <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                                <ul>
                                    {
                                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'ownership_ownership' }) &&
                                        <li>
                                            <Link to="/developer/unit-status/list/all">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/topMenuNavigation/developer/unit-status-icon.png'}
                                                    alt="project-info-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <div>{t('developer:Unit Status')}</div>
                                            </Link>
                                        </li>
                                    }
                                    
                                    {
                                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'ownership_obligation' }) &&
                                        <li>
                                            <Link to="/developer/revenue-contract/list/all">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/topMenuNavigation/developer/revenue-contract-icon.png'}
                                                    alt="project-info-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <div>{t('developer:Revenue Contract')}</div>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'ownership_unitreport' }) &&
                                        <li>
                                            <Link to="/developer/unit-report/list">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/topMenuNavigation/developer/unit-report-icon.png'}
                                                    alt="project-info-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <div>{t('developer:Unit Report')}</div>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'ownership_developer' }) &&
                                        <li>
                                            <Link to="/developer/general-info">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/topMenuNavigation/developer/general-info-icon.png'}
                                                    alt="project-info-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <div>{t('developer:General Information')}</div>
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>}
            </Translation>

        );
    }
}

export default DeveloperTopMenuNavigation;
