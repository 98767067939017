import React, { useEffect, useState } from "react"
import { Translation } from "react-i18next"

const SelectRoleAccounting = (props) => {
    const [Role, SetRole] = useState(props.nameRole ? props.nameRole : "owner")

    useEffect(() => {
        SetRole(props.value)
    }, [props.nameRole, props.value])

    const handleChangeSelect = (value) => {
        SetRole(value)
        props.handleChangeSelect(value)
    }


    return (
        <Translation>
            {t =>
                props.useDiffentDesign ?
                    <div className="col-2">
                        <select onChange={(e) => handleChangeSelect(e.target.value)} disabled={props.editor}
                            value={Role} className="form-control" name="typeOfContact">
                            <option value={"owner"}>{t('AgentRole:Resident/Co-owner')}</option>
                            <option value={"rent"}>{t('AgentRole:Tenant')}</option>
                            <option value={"agent"}>{t('AgentRole:Agent')}</option>
                        </select>
                    </div>
                    :
                    <div className="col-2">
                        <p>
                            <strong>{t('AgentRole:Load Recipien')}</strong>
                        </p>
                        <select onChange={(e) => handleChangeSelect(e.target.value)} disabled={props.editor}
                            value={Role} className="form-control" name="typeOfContact">
                            <option value={"owner"}>{t('AgentRole:Resident/Co-owner')}</option>
                            <option value={"rent"}>{t('AgentRole:Tenant')}</option>
                            <option value={"agent"}>{t('AgentRole:Agent')}</option>
                            {
                                props.isHasOther && 
                                 <option value={"other"}>{t('AgentRole:Other')}</option>
                            }
                        </select>
                    </div>
            }
        </Translation>
    )
}

export default SelectRoleAccounting