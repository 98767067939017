/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreatePayRecordGroupAndPayRecordInput = {|
  contactId: string,
  issuedDate: string,
  remark?: ?string,
  payChannel?: ?any,
  transactions?: ?any,
  clientMutationId?: ?string,
|};
export type payCreateFormMutationVariables = {|
  input: CreatePayRecordGroupAndPayRecordInput
|};
export type payCreateFormMutationResponse = {|
  +createPayRecordGroupAndPayRecord: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type payCreateFormMutation = {|
  variables: payCreateFormMutationVariables,
  response: payCreateFormMutationResponse,
|};
*/


/*
mutation payCreateFormMutation(
  $input: CreatePayRecordGroupAndPayRecordInput!
) {
  createPayRecordGroupAndPayRecord(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePayRecordGroupAndPayRecordPayload",
    "kind": "LinkedField",
    "name": "createPayRecordGroupAndPayRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "payCreateFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "payCreateFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8eb9fde59535330730efa660cf9f5afe",
    "id": null,
    "metadata": {},
    "name": "payCreateFormMutation",
    "operationKind": "mutation",
    "text": "mutation payCreateFormMutation(\n  $input: CreatePayRecordGroupAndPayRecordInput!\n) {\n  createPayRecordGroupAndPayRecord(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '979e193aa866a48b93dfe3db0923eebc';

module.exports = node;
