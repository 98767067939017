import React from "react";
import { QueryRenderer } from "react-relay";
import { Translation } from "react-i18next";
import i18n from "i18next";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import "./style/productListTable.scss";
import Navigation from "./navigation";
import DeleteProductMutation from "./mutations/DeleteProductMutation";
import numberWithComma from "../../../libs/numberWithComma";
import ChangeProductStatus from "./mutations/ChangeProductStatus";
import localStoragePage from "../../../libs/localstorage";
import _ from "lodash";
import Loading from "../../../libs/loading";
import i18next from "i18next";

class ProductListTable extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.search = "";
    this.state.search_input = "";
    this.state.reQuery = false;
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.onDeleteError = this.onDeleteError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidUpdate(prevProps, nextState, nextContext) {
    if (prevProps.type !== this.props.type) {
      this.setState({ type: this.props.type, }, () => { this.goFirst(); });
    }
  }

  onDeleteProduct(id_list) {
    Swal.fire({
      title: i18n.t("inventory_list:Are you sure to remove?"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18n.t("inventory_list:Yes"),
      cancelButtonText: i18n.t("inventory_list:Cancel"),
    }).then((result) => {
      if (result.value) {
        let variables = {
          input: {
            idList: id_list,
          },
        };
        DeleteProductMutation(
          variables,
          (response) => this.onDeleteSuccess(response),
          this.onDeleteError
        );
      }
    });
  }

  changeActiveStatus(id_list, status) {
    let display_text = "";
    if (status) {
      display_text = i18n.t("inventory_list:Are you sure to enable?");
    } else {
      display_text = i18n.t("inventory_list:Are you sure to disable?");
    }
    Swal.fire({
      title: display_text,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18n.t("inventory_list:Yes"),
      cancelButtonText: i18n.t("inventory_list:Cancel"),
    }).then((result) => {
      if (result.value) {
        let variables = {
          input: {
            idList: id_list,
            status: status,
          },
        };
        ChangeProductStatus(
          variables,
          (response) => this.onDeleteSuccess(response),
          this.onDeleteError
        );
      }
    });
  }

  onDeleteSuccess(response) {
    this.setState({ reQuery: true, checkList: [], check_all: false }, () => {
      this.setState({ reQuery: false });
    });

    Swal.fire(i18n.t("inventory_list:Edit successfully!"), "", "success");
  }

  onDeleteError() {
    Swal.fire(i18n.t("inventory_list:Edit unsuccessfully!"), "", "warning");
    this.setState({ loading: false });
  }

  handleChange(event) {
    this.setState({ search: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleChange(event);
    }
  }

  getStatusList(key) {
    let type = {
      PRODUCT: "Product",
      SERVICE: "Service",
      EXPENSE: "Expense",
      ALL: "All",
    };

    return type[key];
  }

  getStatusStock = (totalStock, reorderPoint) => {
    let sumResultInput = 0
    let resultText = ""
    let bgColor = ""
    sumResultInput = totalStock - reorderPoint

    if (sumResultInput > 0) {
      resultText = "Ready to use"
      bgColor = "statusGreen"
    } else if (sumResultInput < 0) {
      resultText = "Nearly sold out"
      bgColor = "statusRed"
    } else if (sumResultInput === 0) {
      resultText = "Ordering point"
      bgColor = "statusYellow"
    }

    return (<div className={`${bgColor} w150`}>
      <span><Translation>{t => i18next.t("stockCreateAndUpdate:" + resultText)}</Translation></span>
    </div>)
  }

  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-between">
          <div className="col">
            <h3 className="mb-4">
              <Link to="/accounting">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/wrapperContent/back.png"
                  }
                  alt="back"
                  className="back"
                />
              </Link>
              <Translation>{t => <>{t(`inventory_list:${this.getStatusList(this.props.type.toUpperCase())}`)}</>}</Translation>
            </h3>
          </div>

          {this.props.type !== "all" &&
            _.some(JSON.parse(localStorage.getItem("permission_list")), {
              codename: "accounting_" + this.props.type + "_create",
            }) && (
              <div className="col">
                <Link
                  to={"/accounting/inventory/product/" + this.props.type + "/"}
                >
                  <button
                    type="button"
                    className="btn btn-primary add float-right"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                      alt="plus"
                    />
                    <Translation>
                      {(t) => (
                        <span>
                          {t("productAndService:add_" + this.props.type)}
                        </span>
                      )}
                    </Translation>
                  </button>
                </Link>
              </div>
            )}
        </div>
        <div className="content-inner">
          <div className="row">
            <div className="col-9">
              <div className="btn-group" id="product-DropDown">
                <Translation>
                  {t =>
                    <button
                      type="button"
                      className="btn btn-light dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {t("inventory_list:Manage multiple list")}
                    </button>}
                </Translation>
                <div className="dropdown-menu">
                  {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                    codename: "accounting_" + this.props.type + "_edit",
                  }) && (
                      <React.Fragment>
                        <Translation>
                          {t =>
                            <>
                              <span
                                className="dropdown-item cursor"
                                onClick={() =>
                                  this.changeActiveStatus(this.state.checkList, true)
                                }
                              >
                                {t("inventory_list:Enable")}
                              </span>


                              <span
                                className="dropdown-item cursor"
                                onClick={() =>
                                  this.changeActiveStatus(this.state.checkList, false)
                                }
                              >
                                {t("inventory_list:Disable")}
                              </span>
                            </>
                          }
                        </Translation>
                      </React.Fragment>
                    )}
                </div>
              </div>
            </div>
            <div className="col-3 text-right">
              <div className="input-group ">
                <Translation>
                  {t =>
                    <>
                      <input
                        type="text"
                        className="form-control input-size"
                        placeholder={t("inventory_list:Search")}
                        value={this.state.search_input}
                        name="search"
                        onChange={(e) =>
                          this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                      />

                      <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleChange(e)}
                      >
                        {t("inventory_list:Search")}
                      </button>
                    </>
                  }
                </Translation>
              </div>
            </div>
          </div>
          <Navigation />

          <QueryRenderer
            environment={environment}
            query={this.props.query}
            cacheConfig={{ use_cache: false }}
            variables={{
              search: this.state.search,
              first: this.state.first,
              last: this.state.last,
              type: this.props.type,
              reQuery: this.state.reQuery,
            }}
            render={({ error, props }) => {
              if (error) {
                return (
                  <div className="alert alert-danger" role="alert">
                    {error.message}
                  </div>
                );
              } else if (props) {
                return (
                  <React.Fragment>
                    <div
                      className="table-responsive fade-up card"
                      id="product-list-table"
                    >
                      <table className="table table-hover colorUse">
                        <thead className="thead-light">
                          <tr
                            onClick={() =>
                              this.getAllList(
                                props.productViewer.allProduct.edges
                              )
                            }
                          >
                            <th style={{ width: 35 }}>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  this.getAllList(
                                    props.productViewer.allProduct.edges
                                  )
                                }
                                checked={this.state.check_all}
                              />
                            </th>
                            <th className="text-left" style={{ width: 150 }}>
                              <Translation>
                                {(t) => (
                                  <span>
                                    {t("productAndService:product_code")}
                                  </span>
                                )}
                              </Translation>
                            </th>
                            <Translation>
                              {t =>
                                <th className="text-left">
                                  {/* {"ชื่อ" +
                                  this.getStatusList(
                                    this.props.type.toUpperCase()
                                  )
                                } */}
                                  {t("inventory_list:All name")}
                                </th>
                              }
                            </Translation>
                            {/* {this.props.type === "product" && (
                              <Translation>{t=><th className="text-center">{t("inventory_list:Date")}</th>}</Translation>
                            )} */}
                            {this.props.type === "product" && (
                              <th className="text-right">
                                <Translation>
                                  {(t) => (
                                    <span className="mr-5">
                                      {t("productAndService:balance")}
                                    </span>
                                  )}
                                </Translation>
                              </th>
                            )}
                            <Translation>
                              {t =>
                                <>
                                  {/* {this.props.type === "product" && (
                                  <th className="text-right">{t("inventory_list:Cost price")}</th>
                                )} */}
                                  {/* {this.props.type === "product" && (
                                  <th className="text-right">{t("inventory_list:Total reidual vlalue")}</th>
                                )} */}
                                  {this.props.type === "all" && <th>{t("inventory_list:Status")}</th>}
                                  <th style={{ width: 100 }} className="text-center">
                                    {t("inventory_list:Status")}
                                  </th>
                                </>
                              }
                            </Translation>
                            <th style={{ width: 120 }} />
                          </tr>
                        </thead>
                        <tbody>
                          {/*<ProductTableRow product={props.productViewer.allProduct.edges} order_id={this.state.first - localStoragePage.getPageLimit()}/>*/}
                          {props.productViewer.allProduct.edges.map(
                            (product, index) => {
                              let order_id =
                                this.state.first -
                                localStoragePage.getPageLimit();
                              let total_stock = 0;
                              let total = 0;


                              if (product.node.stock.edges.length > 0) {
                                product.node.stock.edges.forEach(
                                  (stock, t_index) => {

                                    total_stock = total_stock + stock.node.leftInStock;
                                    total = total + parseFloat(stock.node.cost * stock.node.leftInStock);
                                  }
                                )
                              }

                              return (
                                <tr
                                  onClick={() =>
                                    this.appendToCheckList(product.node.id)
                                  }
                                  key={product.node.id}
                                >
                                  <td>
                                    <input
                                      type="checkbox"
                                      onChange={() =>
                                        this.appendToCheckList(
                                          product.node.id
                                        )
                                      }
                                      checked={this.checkList(
                                        product.node.id
                                      )}
                                    />
                                  </td>
                                  <td>
                                    {product.node.stock.edges.length > 0 && this.props.type === "product" ?
                                      <Link
                                        // to={
                                        //   "/accounting/inventory/product-detail/" +
                                        //   product.node.type.toLowerCase() +
                                        //   "/" +
                                        //   product.node.id
                                        // }
                                        to={{
                                          pathname: `/stockandsupplies/stockMovementReport/stockMovementReportDetail/${product.node.id}/almost/all`, 
                                          state: { LinkBack: window.location.pathname }}}
                                      >
                                        {product.node.productCode}
                                      </Link>
                                      :
                                      <Link
                                        to={
                                          "/accounting/inventory/product/" +
                                          product.node.type.toLowerCase() +
                                          "/" +
                                          product.node.id
                                        }
                                      >
                                        {product.node.productCode}
                                      </Link>
                                    }
                                  </td>
                                  <td>{product.node.name}</td>
                                  {/* {this.props.type === "product" && <td />} */}
                                  {/* {this.props.type === "product" && <td />} */}
                                  {this.props.type === "product"
                                    ? <td className="text-right">
                                      <span className="mr-5">
                                        {numberWithComma(
                                          total_stock,
                                          0,
                                          0
                                        )}
                                      </span>
                                    </td>
                                    : false
                                  }
                                  {/* {this.props.type === "product" && 
                                    <React.Fragment>
                                      <td className="text-right">
                                        <b className="doubleUnderline">
                                          {numberWithComma(
                                            total,
                                            0,
                                            0
                                          )}
                                        </b>
                                      </td>
                                    </React.Fragment>
                                  } */}
                                  {this.props.type === "all" &&
                                    <Translation>
                                      {t => <td>
                                        {t(`inventory_list:${this.getStatusList(product.node.type)}`)}
                                      </td>}
                                    </Translation>
                                  }
                                  {this.props.type === "product"
                                    ? <td className="d-flex justify-content-center">{this.getStatusStock(total_stock, (product.node.reorderPoint || 0))}</td>
                                    : <td className="text-center">
                                      {product.node.isActive ? (
                                        <span>✓</span>
                                      ) : (
                                        <span>✕</span>
                                      )}
                                    </td>
                                  }
                                  <td className="text-center">
                                    {_.some(
                                      JSON.parse(
                                        localStorage.getItem(
                                          "permission_list"
                                        )
                                      ),
                                      {
                                        codename:
                                          "accounting_" +
                                          this.props.type +
                                          "_edit",
                                      }
                                    ) && (
                                        <Link
                                          to={
                                            "/accounting/inventory/product/" +
                                            product.node.type.toLowerCase() +
                                            "/" +
                                            product.node.id
                                          }
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/images/icons/edit.png"
                                            }
                                            alt="product-edit-icon"
                                            className="show-icon"
                                          />
                                          <Translation>
                                            {t =>
                                              <span className="grey-color ml-1">
                                                {t("inventory_list:Edit")}
                                              </span>}
                                          </Translation>
                                        </Link>
                                      )}
                                    {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_' + this.props.type + '_delete'}) &&
                                                              <span className="btn">
                                                                  <img
                                                                      src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                      alt="delete" className="btn-delete cursor"
                                                                      onClick={() => this.onDeleteProduct([product.node.id])}/>
                                                              </span>
                                                              } */}
                                  </td>
                                </tr>
                              )

                            }
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="row">
                      <Pagination
                        changePage={this.changePage}
                        first={this.state.first}
                        last={this.state.last}
                        totalCount={props.productViewer.allProduct.totalCount}
                      />
                    </div>
                  </React.Fragment>
                );
              }
              return <Loading />
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ProductListTable;
