import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {Link} from "react-router-dom";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Navigation from "./navigation";
import Wrapper from "../../components/wrapper";
import CreateUpdateVehicle from "./createUpdateVehicle"
import Loading from '../../libs/loading';

const query = graphql`
    query vehicleEditQuery($id: ID!, $residential_id: ID!) {
        residential(id: $residential_id){
            name
        }
        car(id: $id) {
            id
            licencePlate
            carDescription
            province
            carBrand
            carModel
            carYear
            startDate
            carType
            chargedFee
            carSticker
            carSize
            floor
            space
            carImage{
                edges{
                    node{
                        id
                        image
                        description
                    } 
                } 
            }
      }  
    }`
;

class VehicleEdit extends Component {

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{
                            "id": this.props.match.params.vehicle_id || "",
                            "residential_id": this.props.match.params.id
                        }}
                        render={({error, props}) => {
                            if (props) {
                                return <div className="container-fluid p-4" id="form-detail">
                                    <div className="row justify-content-between">
                                        <div className="col header-color">
                                            <h3 className="mb-4">
                                                <Link
                                                    to={{
                                                        pathname: "/register/residential/detail/vehicle-detail/" + this.props.match.params.id
                                                    }}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back"/>
                                                </Link>
                                                {props.residential.name}
                                            </h3>
                                            <div className="content-inner">
                                                <Navigation id={this.props.match.params.id}/>
                                            </div>
                                        </div>
                                    </div>
                                    <CreateUpdateVehicle detail_car={props} id={this.props.match.params.id}
                                                         name={props.residential.name}/>;
                                </div>

                            } else {
                                return <Loading/>
                            }
                        }}
                    />
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default VehicleEdit;
