/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HouseHolderType = "AGENT" | "OWNER" | "RENTER" | "RESIDENT" | "%future added value";
export type ownerDetailQueryVariables = {|
  id: string
|};
export type ownerDetailQueryResponse = {|
  +currentTenants: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: ?string,
        +householder: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +residential: {|
                +name: string
              |}
            |}
          |}>
        |},
        +boadPersonal: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +position: ?string,
              +status: string,
            |}
          |}>
        |},
      |}
    |}>,
  |},
  +residential: ?{|
    +id: string,
    +name: string,
    +residentialHouseholder: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +type: HouseHolderType,
          +active: boolean,
          +added: any,
          +detailTenantPerResidential: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +dateIn: ?any,
                +remark: ?string,
              |}
            |}>
          |},
          +tenant: {|
            +id: string,
            +firstName: string,
            +lastName: ?string,
            +idCardNumber: ?string,
            +image: string,
            +countries: string,
            +phone: ?string,
            +email: ?string,
            +nameTitle: ?string,
            +channel: ?string,
            +user: ?{|
              +id: string,
              +username: string,
            |},
            +boadPersonal: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +position: ?string,
                  +status: string,
                |}
              |}>
            |},
          |},
        |}
      |}>,
    |},
  |},
|};
export type ownerDetailQuery = {|
  variables: ownerDetailQueryVariables,
  response: ownerDetailQueryResponse,
|};
*/


/*
query ownerDetailQuery(
  $id: ID!
) {
  currentTenants {
    totalCount
    edges {
      node {
        id
        firstName
        lastName
        householder {
          edges {
            node {
              residential {
                name
                id
              }
              id
            }
          }
        }
        boadPersonal {
          edges {
            node {
              id
              position
              status
            }
          }
        }
      }
    }
  }
  residential(id: $id) {
    id
    name
    residentialHouseholder {
      totalCount
      edges {
        node {
          id
          type
          active
          added
          detailTenantPerResidential {
            edges {
              node {
                id
                dateIn
                remark
              }
            }
          }
          tenant {
            id
            firstName
            lastName
            idCardNumber
            image
            countries
            phone
            email
            nameTitle
            channel
            user {
              id
              username
            }
            boadPersonal(status: "current") {
              edges {
                node {
                  id
                  position
                  status
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BoardPersonalNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardPersonalNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "position",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "BoardPersonalNodeConnection",
  "kind": "LinkedField",
  "name": "boadPersonal",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "ResidentialNode",
  "kind": "LinkedField",
  "name": "residential",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseHolderNodeConnection",
      "kind": "LinkedField",
      "name": "residentialHouseholder",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HouseHolderNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HouseHolderNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "active",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "added",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DetailTenantPerResidentialNodeConnection",
                  "kind": "LinkedField",
                  "name": "detailTenantPerResidential",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DetailTenantPerResidentialNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DetailTenantPerResidentialNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "dateIn",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "remark",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TenantNode",
                  "kind": "LinkedField",
                  "name": "tenant",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "idCardNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "image",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "countries",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phone",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "nameTitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "channel",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserNode",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "status",
                          "value": "current"
                        }
                      ],
                      "concreteType": "BoardPersonalNodeConnection",
                      "kind": "LinkedField",
                      "name": "boadPersonal",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": "boadPersonal(status:\"current\")"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ownerDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TenantNodeConnection",
        "kind": "LinkedField",
        "name": "currentTenants",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "householder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResidentialNode",
                                "kind": "LinkedField",
                                "name": "residential",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ownerDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TenantNodeConnection",
        "kind": "LinkedField",
        "name": "currentTenants",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HouseHolderNodeConnection",
                    "kind": "LinkedField",
                    "name": "householder",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HouseHolderNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HouseHolderNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ResidentialNode",
                                "kind": "LinkedField",
                                "name": "residential",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9aeb835f491794bc0d7ac6f7442836e1",
    "id": null,
    "metadata": {},
    "name": "ownerDetailQuery",
    "operationKind": "query",
    "text": "query ownerDetailQuery(\n  $id: ID!\n) {\n  currentTenants {\n    totalCount\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        householder {\n          edges {\n            node {\n              residential {\n                name\n                id\n              }\n              id\n            }\n          }\n        }\n        boadPersonal {\n          edges {\n            node {\n              id\n              position\n              status\n            }\n          }\n        }\n      }\n    }\n  }\n  residential(id: $id) {\n    id\n    name\n    residentialHouseholder {\n      totalCount\n      edges {\n        node {\n          id\n          type\n          active\n          added\n          detailTenantPerResidential {\n            edges {\n              node {\n                id\n                dateIn\n                remark\n              }\n            }\n          }\n          tenant {\n            id\n            firstName\n            lastName\n            idCardNumber\n            image\n            countries\n            phone\n            email\n            nameTitle\n            channel\n            user {\n              id\n              username\n            }\n            boadPersonal(status: \"current\") {\n              edges {\n                node {\n                  id\n                  position\n                  status\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62188dadb4084cac84703d37467015b8';

module.exports = node;
