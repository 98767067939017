import React, { Component } from 'react';
import "./styles/emergencyListTable.scss"
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import DeleteEmergencyCallMutation from "./mutations/deleteEmergencyCallMutation"
import { Translation } from "react-i18next";
import i18n from "i18next";

const _ = require('lodash');

const mutation = graphql`
 mutation emergencyListTableMutation($input: CreateAndUpdateEmergencyCallInput!){
      createAndUpdateEmergencyCall(input: $input){
        ok
      }
    }
`;

const emergency_object = {

    id: '',
    name: '',
    contactNumber: '',
    callOutContactNumber: '',

};

class EmergencyListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emergency_list: [],
            create: false,
            delete_loading: false,
            loading: false,
            show_icon: true,
            group: this.props.groupPhone

        };
        this.appendNewRow = this.appendNewRow.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
        this.onDeleteError = this.onDeleteError.bind(this);
        this.deleteItemRow = this.deleteItemRow.bind(this);
        this.onChangeEdit = this.onChangeEdit.bind(this);
    }

    componentWillMount() {

        if (_.isObject(this.props.emergency_list)) {
            let data_set = _.cloneDeep(this.props.emergency_list);
            data_set.forEach(function (data) {
                _.set(data, 'node.status', "");
            });

            this.setState({ emergency_list: data_set });
        }

    }
    onChangeEdit(id) {
        let data_set = _.cloneDeep(this.state.emergency_list);
        data_set.forEach(function (data) {
            if (id === data.node.id) {
                _.set(data, 'node.status', 'edit');
            }
        });
        this.setState({ emergency_list: data_set, show_icon: false });

    }

    appendNewRow() {
        this.setState({ show_icon: false, emergency_list: [...this.state.emergency_list, { node: { ...emergency_object } }] });
    }

    handleInputChange(e) {
        let emergency_list = this.state.emergency_list;
        emergency_list[e.target.dataset.id].node[e.target.name] = e.target.value;
        this.setState({ emergency_list: emergency_list })
    }

    onDeleteItem(emergency, index) {

        Swal.fire({
            title: i18n.t("emergency_contact:Are you sure to remove this data?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("emergency_contact:Yes"),
            cancelButtonText: i18n.t("emergency_contact:No")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        id: emergency.node.id
                    }
                };
                DeleteEmergencyCallMutation(variables, (res) => this.onDeleteSuccess(res, index), this.onDeleteError)
            }
        });



    }

    deleteItemRow(index, status) {
        if (status === 'edit') {
            let data_set = _.cloneDeep(this.state.emergency_list);
            data_set.forEach(function (data, data_index) {
                if (index === data_index) {
                    _.set(data, 'node.status', "");
                }
            });
            this.setState({ emergency_list: data_set, show_icon: true });

        } else {
            let emergency_list_new = this.state.emergency_list;
            emergency_list_new.splice(index, 1);
            this.setState({ emergency_list: emergency_list_new, show_icon: true })
        }

    }

    onDeleteSuccess(response, index) {
        this.setState({ delete_loading: false });
        if (response.deleteEmergencyCall.ok) {
            Swal.fire(i18n.t("emergency_contact:Remove successfully!"), '', 'success').then(() => {
                this.deleteItemRow(index, '')
            });
        } else {
            Swal.fire(i18n.t("emergency_contact:Remove unsuccessfully!"), '', 'warning');
        }
    }

    onDeleteError() {
        this.setState({ delete_loading: false });
        Swal.fire('Error!', i18n.t("emergency_contact:Please try again"), 'warning')

    }

    onSubmit(e) {
        e.preventDefault();

        this.setState({ loading: true });
        let index = this.state.emergency_list.findIndex(x => x.node.id === "");
        if (index === -1) {
            index = this.state.emergency_list.findIndex(x => x.node.status === "edit");
        }
        let variables = {
            input: {
                emergencyObject: JSON.stringify(this.state.emergency_list[index].node)
            }
        };

        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({ loading: false, show_icon: true });
                    if (response.createAndUpdateEmergencyCall.ok) {
                        Swal.fire(i18n.t("emergency_contact:Save successfully!"), '', 'success').then(() => {
                            this.props.refreshQuery()
                        });
                    } else {
                        Swal.fire(i18n.t("emergency_contact:Save unsuccessfully!"), '', 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({ loading: false });
                    Swal.fire('Error!', i18n.t("emergency_contact:Please try again"), 'warning')
                },
            },
        )

    }


    render() {
        return (
            <Translation>
                {t =>
                    <div className="content-inner mt-5" id="emergency-list">
                        <div className="card fade-up">
                            <form action="" onSubmit={this.onSubmit} id="form-add">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>{t("emergency_contact:Contact name")}</th>
                                                <th>{t("emergency_contact:Contact number")}</th>
                                                <th>{t("emergency_contact:Contact number(outgoing calls)")}</th>
                                                <th>{t("emergency_contact:PhoneCatagory")}</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                this.state.emergency_list.map((emergency, index) => {
                                                    return (
                                                        <tr className="show" key={'emergency-' + index}>
                                                            {emergency.node.id && emergency.node.status !== 'edit' ?
                                                                <React.Fragment>
                                                                    <td>{emergency.node.name}</td>
                                                                    <td>{emergency.node.contactNumber}</td>
                                                                    <td width="250">{emergency.node.callOutContactNumber}</td>
                                                                    <td width="250">{emergency.node.group ? emergency.node.group.name : ""}</td>
                                                                    <td width="250">
                                                                        {this.state.show_icon &&
                                                                            <React.Fragment>
                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_emergency_delete' }) &&
                                                                                    <React.Fragment>
                                                                                        <img
                                                                                            src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                                            alt="delete-icon" className="show-icon"
                                                                                        />
                                                                                        <span
                                                                                            className="cursor grey-color show-icon ml-1 underline hover"
                                                                                            onClick={() => this.onDeleteItem(emergency, index)}>{t("emergency_contact:Remove")}</span>
                                                                                    </React.Fragment>
                                                                                }

                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_emergency_edit' }) &&
                                                                                    <React.Fragment>
                                                                                        <img
                                                                                            src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                                            alt="edit-icon" className="ml-4 show-icon"
                                                                                        />
                                                                                        <span
                                                                                            className="cursor show-icon grey-color ml-1 underline hover"
                                                                                            onClick={() => this.onChangeEdit(emergency.node.id)}>{t("emergency_contact:Edit")}</span>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>

                                                                        }
                                                                    </td>
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    <td>
                                                                        <input type="text" className="form-control"
                                                                            value={emergency.node.name}
                                                                            onChange={this.handleInputChange} data-id={index}
                                                                            name="name" required />
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" className="form-control"
                                                                            value={emergency.node.contactNumber}
                                                                            onChange={this.handleInputChange}
                                                                            data-id={index}
                                                                            name="contactNumber" required />
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" className="form-control"
                                                                            value={emergency.node.callOutContactNumber}
                                                                            onChange={this.handleInputChange}
                                                                            data-id={index}
                                                                            name="callOutContactNumber"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            required
                                                                            onChange={this.handleInputChange}
                                                                            name='groupId'
                                                                            data-id={index}
                                                                            defaultValue={emergency.node.group?.id}
                                                                            className='form-control'>
                                                                            <option value={""}>{t("claimRequst:Choose catagory")}</option>
                                                                            {
                                                                                this.state?.group?.map((n, indexGroup) => {
                                                                                    return (
                                                                                        <option
                                                                                            value={n.node.id}
                                                                                            key={indexGroup}
                                                                                        >
                                                                                            {n.node.name}
                                                                                        </option>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <button type="submit"
                                                                            className="btn btn-primary"
                                                                            form="form-add" data-id={index} disabled={this.state.loading}>
                                                                            {this.state.loading && <span
                                                                                className="spinner-border spinner-border-sm align-middle mr-1" />}
                                                                            {t("emergency_contact:Save")}
                                                                        </button>
                                                                        <button type="button"
                                                                            className="btn btn-secondary ml-1"
                                                                            onClick={() => this.deleteItemRow(index, emergency.node.status)}>{t("emergency_contact:Cancel")}
                                                                        </button>
                                                                    </td>
                                                                </React.Fragment>
                                                            }
                                                        </tr>
                                                    )
                                                }


                                                )
                                            }

                                        </tbody>

                                    </table>
                                </div>
                            </form>
                        </div>

                        {
                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_emergency_create' }) &&
                            <React.Fragment>
                                {this.state.show_icon &&
                                    <div className="row mt-4">
                                        <button className="btn btn-light bt-style ml-3" onClick={this.appendNewRow}>+&nbsp;
                                            {t("emergency_contact:Add emergency contact")}
                                        </button>
                                    </div>
                                }
                            </React.Fragment>
                        }

                    </div >
                }
            </Translation>
        );
    }
}

export default EmergencyListTable;
