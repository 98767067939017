import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { QueryRenderer } from "react-relay";
import ContactReportList from "../contactPayableReportList";
import PrepaidDepositReportTable from "./prepaidDepositReportTable";
import _ from "lodash";
import ExportPrepaidDepositReport from "./server_export";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from '../../../../components/BackBtn/indexBack';
import i18next from "i18next";

const query = graphql`
  query prepaidDepositReportQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $status: String
    $search: String
    $contact: String
    $payment_channel: String
  ) {
    prepaidDepositViewer {
      allPrepaidDeposit(
        first: $first
        last: $last
        startDate: $start_date
        endDate: $end_date
        status: $status
        search: $search
        report: true
        contact: $contact
        paymentChannel: $payment_channel
        order: "doc_number"
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            status
            contact {
              name
              firstName
              lastName
              refNumber
              typeOfContact
            }
            prepaidDepositTransaction {
              edges {
                node {
                  id
                  description
                  total
                  balance
                }
              }
            }
            prepaidDepositTransactionUsed {
              edges {
                node {
                  id
                  amount
                }
              }
            }
            cashTransaction {
              totalCount
            }
            bankAccountTransaction {
              totalCount
            }
            chequeTransaction {
              totalCount
            }
          }
        }
      }
      summaryPrepaidDeposit(
        startDate: $start_date
        endDate: $end_date
        status: $status
        search: $search
        report: true
        contact: $contact
        paymentChannel: $payment_channel
      )
    }
  }
`;

class PrepaidDepositReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    this.state.start_date = month_before;
    this.state.end_date = new Date();
    this.state.temp_start_date = month_before;
    this.state.temp_end_date = new Date();
    this.state.search = "";
    this.state.search_input = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.status = "";
    this.state.temp_status = "";
    this.state.payment_channel = "";
    this.state.temp_payment_channel = "";

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "prepaidDepositReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      contact: this.state.temp_contact,
      status: this.state.temp_status,
      payment_channel: this.state.temp_payment_channel,

    });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-payable"}
                  LinkText={i18next.t("DashboardPayable:Advance Payment Report")}
                  boxHtmlText={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_payable_print",
                      }) && <ExportPrepaidDepositReport state={this.state} />}
                    </div>
                  }
                />

                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>
                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactReportList
                            handleChange={this.handleChange}
                            contact={this.state.temp_contact}
                          />
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Payment Method")}</label>
                              <select
                                name="temp_payment_channel"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("FilterPayable:All")}</option>
                                <option value="cash">{i18next.t("PaymentChannel:Cash")}</option>
                                <option value="bank">{i18next.t("PaymentChannel:Transfer")}</option>
                                <option value="cheque">{i18next.t("PaymentChannel:Cheque")}</option>
                                <option value="other">{i18next.t("PaymentChannel:Other")}</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Status")}</label>
                              <select
                                name="temp_status"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("FilterPayable:All")}</option>
                                <option value="active">{i18next.t("PrepaidDeposit:Unpaid")}</option>
                                <option value="use_partial">{i18next.t("PrepaidDeposit:Partial pay off")}</option>
                                <option value="use_all">{i18next.t("PrepaidDeposit:Full amount paid")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <QueryRenderer
                    environment={environment}
                    query={query}
                    variables={{
                      first: this.state.first,
                      last: this.state.last,
                      start_date: this.state.start_date,
                      end_date: this.state.end_date,
                      status: this.state.status,
                      search: this.state.search,
                      contact: this.state.contact,
                      payment_channel: this.state.payment_channel,
                    }}
                    render={({ error, props }) => {
                      if (props) {
                        return (
                          <React.Fragment>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="card fade-up">
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover"
                                      style={{ fontSize: 12 }}
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th className="text-center">{i18next.t("PrepaidDepositReport:Order")}</th>
                                          <th className="text-center">
                                          {i18next.t("PrepaidDepositReport:Payment Voucher Number")}
                                          </th>
                                          <th className="text-center">
                                          {i18next.t("PrepaidDepositReport:Payment Date")}
                                          </th>
                                          <th className="text-center">
                                          {i18next.t("PrepaidDepositReport:Vendor Code")}
                                          </th>
                                          <th>{i18next.t("PrepaidDepositReport:Name")}</th>
                                          <th>{i18next.t("PrepaidDepositReport:Description")}</th>
                                          <th className="text-right">{i18next.t("PrepaidDepositReport:Amount")}</th>
                                          {this.state.status === 'use_partial' &&
                                            <>
                                              <th className="text-right">{i18next.t("PrepaidDepositReport:Paid")}</th>
                                              <th className="text-right">{i18next.t("PrepaidDepositReport:Remaining")}</th>
                                            </>
                                          }
                                          <th className="text-center">
                                          {i18next.t("PrepaidDepositReport:Payment Method")}
                                          </th>
                                          <th className="text-center">
                                          {i18next.t("PrepaidDepositReport:Status")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <PrepaidDepositReportTable
                                          props={props}
                                          state={this.state}
                                        />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={
                                  props.prepaidDepositViewer.allPrepaidDeposit
                                    .totalCount
                                }
                              />
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return <Loading />
                      }
                    }}
                  />
                </div>
              </div>

            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PrepaidDepositReport;
