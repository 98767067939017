import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import AccrualBasis from "./accrualBasis"

class accrualBasis extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={AccrualBasis}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default accrualBasis;
