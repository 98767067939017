import React, {Component} from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar';
import Wrapper from '../../../../components/wrapper';
import WrapperContent from '../../../../components/wrapper/wrapperContent';
import SelectContactPayable from '../../../income/selectContactPayable';
import PayCreateForm from "./payCreateForm";
import PaySelect from "./paySelect";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";


class PurchaseCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact_list: [],
            accountType: "pay",
            transactions: [],
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
        this.updateSelectPurchaseList = this.updateSelectPurchaseList.bind(this);
    }

    updateCustomerList(contact_list) {
        this.setState({contact_list: contact_list})
    }

    updateSelectPurchaseList(transactions) {
        this.setState({ transactions: transactions})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContactPayable updateCustomerList={this.updateCustomerList} accountType={this.state.accountType}
                                   nextLink="/accounting/expense/pay/form/select_purchase"
                                   backLink="/accounting/expense/pay/list/all"
                                   disable_select_type={true}
                    />
                    }
                    {this.props.match.params.status === 'select_purchase' &&
                    <PaySelect contactList={this.state.contact_list}
                                          updateSelectPurchaseList={this.updateSelectPurchaseList}/>
                    }
                    {this.props.match.params.status === 'create' &&
                    <PayCreateForm contactList={this.state.contact_list}
                                   transactions={this.state.transactions}/>
                    }

                </WrapperContent>
            </Wrapper>


        )
    }
}

export default PurchaseCreate;