import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { Translation } from "react-i18next";
import UploadImageInput from "../../libs/uploadImageInput";
import environment from "../../env/environment";
import { commitMutation, fetchQuery } from "react-relay";
import SearchSelect from "../../libs/searchSelect";
import Swal from "sweetalert2";
import i18n from "i18next";

const _ = require('lodash');
const $ = window.jQuery;

const mutation = graphql`
    mutation complaintCreateModalMutation($input: CreateRoomInput!){
        createRoom(input: $input){
            newRoom{
                id
            }
        }
    }
`;

const query = graphql`
    query complaintCreateModalQuery{
        allResidential{
            edges {
                node {
                    id
                    name
                    isUserActivate
                    residentialHouseholder{
                        edges{
                            node{
                                id                                
                                tenant{
                                    id
                                    firstName
                                    lastName                                          
                                    householder(first: 1, last: 1) {
                                        edges {
                                            node {
                                                active
                                            }
                                        }
                                    }                              
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

class ComplaintCreateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            complaint: {
                name: '',
                detail: '',
                status: 'open',
                residentialId: '',
                roomType: 'contact',
            },
            houseHolderId: '',
            residential_house_holder: [],
            allResidential:[],
            image: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this)
        this.getresidential_house_holder = this.getresidential_house_holder.bind(this)
    }

    componentDidMount() {
        fetchQuery(environment, query).then(data => {
            if (data.allResidential) {
                this.setState({ allResidential: data.allResidential.edges })
            }
        }, () => {
            $('#createComplaintModal').on('hidden.bs.modal');          
        })

    }

    getresidential_house_holder(residentialId) {
        let all_residential = _.cloneDeep(this.state.allResidential);
        let fine_id_residential = _.find(all_residential,{node:{id:residentialId}})
        if(fine_id_residential){
            this.setState({residential_house_holder:fine_id_residential.node.residentialHouseholder.edges,houseHolderId:''})
        }else{
            this.setState({residential_house_holder:[],houseHolderId:''})
        }
    }


    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState(prevState => {
            if (event.target.name === "complaint.residentialId") {
                this.getresidential_house_holder(value)
            }
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        let variables = {
            input: {
                name: this.state.complaint.name,
                detail: this.state.complaint.detail,
                status: this.state.complaint.status,
                residentialId: this.state.complaint.residentialId,
                roomType: this.state.complaint.roomType,
                houseHolderId: this.state.houseHolderId,            
            }
        };
        let uploadables = { image: this.state.image };
        commitMutation(
            environment,
            {
                mutation,
                variables,
                uploadables,
                onCompleted: (response) => {
                    Swal.fire(i18n.t('complaint:save_success'), '', 'success')
                    this.resetForm()
                    $('#createComplaintModal').modal('hide');
                },
                onError: (err) => {
                    Swal.fire(i18n.t('complaint:save_unsuccess'), i18n.t('complaint:try_again'), 'error');
                    this.resetForm()
                },
            },
        );
    }

    resetForm() {
        this.setState({
            loading: false,
            complaint: {
                name: '',
                detail: '',
                status: 'open',
                residentialId: '',
                roomType: 'contact',
                tenantId: ''
            },
            image: ''
        })
    }

    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"))
        return (
            <React.Fragment>
                <button type="button" className="btn btn-primary add float-right" data-toggle="modal"
                    data-target="#createComplaintModal">
                    <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus" />
                    <Translation>
                        {
                            t => 
                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                            <span>{textData?.data?.complain.addComplaint}</span>
                            :
                            <span>{t('complaint:add_complaint')}</span>
                        }
                    </Translation>
                </button>

                <Translation>
                    {t =>
                        <div className="modal fade" id="createComplaintModal" tabIndex="-1" role="dialog"
                            aria-labelledby="createComplaintModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header ml-1">
                                        <h4 className="modal-title">{t("complaint:send_message")}</h4>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={this.onSubmit}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="modal-title">{t("complaint:unit_no")}</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <SearchSelect
                                                                onChange={this.handleInputChange}
                                                                value={this.state.complaint.residentialId}
                                                                name="complaint.residentialId"
                                                                placeholder="เลขที่"
                                                                queryObject={this.state.allResidential}
                                                                keyOfValue="id" require={true}
                                                                keyOfLabel="name"
                                                                showUserActive={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="modal-title">{t("PageCreate:Choose Name Request")}</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">                                                            
                                                            <SearchSelect
                                                                onChange={this.handleInputChange}
                                                                value={this.state.houseHolderId}
                                                                name="houseHolderId"
                                                                placeholder="เลือกชื่อผู้แจ้ง"
                                                                queryObject={this.state.residential_house_holder}
                                                                keyOfValue="id" require={true}
                                                                keyOfLabel="tenant.firstName:tenant.lastName"
                                                                showTenantActive={true}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="modal-title">{t("complaint:topic")}</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <input className="form-control" name="complaint.name"
                                                                value={this.state.complaint.name}
                                                                onChange={this.handleInputChange} required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="modal-title">{t("complaint:detail")}</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <textarea className="form-control" rows="4"
                                                                name="complaint.detail"
                                                                value={this.state.complaint.detail}
                                                                onChange={this.handleInputChange} />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    {
                                                        this.state.image ?
                                                            <div className="col">
                                                                <div>
                                                                    <img src={URL.createObjectURL(this.state.image)}
                                                                        alt="preview"
                                                                        className="img-fluid" />
                                                                </div>
                                                                <div>
                                                                    <label className="btn-link" onClick={() => {
                                                                        this.setState({ image: '' })
                                                                    }}>
                                                                        {t("complaint:remove_image")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col">
                                                                <div className="upload-file">
                                                                    <div>
                                                                        <label className="btn-link" htmlFor="uploadImage">
                                                                            {t("complaint:attache_image")}
                                                                        </label>
                                                                    </div>
                                                                    <div className="upload-input">
                                                                        <UploadImageInput
                                                                            id="uploadImage"
                                                                            setField="image"
                                                                            required={false}
                                                                            onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col text-right">
                                                        <div className="btn-group mr-2">
                                                            <button type="button" data-dismiss="modal"
                                                                className="btn btn-secondary add">
                                                                {t("complaint:cancel")}
                                                            </button>
                                                        </div>
                                                        <div className="btn-group mr-2">
                                                            <button type="submit" className="btn btn-primary add"
                                                                disabled={this.state.loading}>
                                                                {this.state.loading && <span
                                                                    className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                                {t("complaint:save")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Translation>
            </React.Fragment>
        )
    }
}

export default ComplaintCreateModal;
