/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VoidPaymentGatewayGroupInput = {|
  id?: ?string,
  clientMutationId?: ?string,
|};
export type paymentGatewayDetailMutationVariables = {|
  input: VoidPaymentGatewayGroupInput
|};
export type paymentGatewayDetailMutationResponse = {|
  +voidPaymentGatewayGroup: ?{|
    +clientMutationId: ?string,
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type paymentGatewayDetailMutation = {|
  variables: paymentGatewayDetailMutationVariables,
  response: paymentGatewayDetailMutationResponse,
|};
*/


/*
mutation paymentGatewayDetailMutation(
  $input: VoidPaymentGatewayGroupInput!
) {
  voidPaymentGatewayGroup(input: $input) {
    clientMutationId
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VoidPaymentGatewayGroupPayload",
    "kind": "LinkedField",
    "name": "voidPaymentGatewayGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentGatewayDetailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "paymentGatewayDetailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d686f9dfbb68354cfd1a8fa643a9bf4f",
    "id": null,
    "metadata": {},
    "name": "paymentGatewayDetailMutation",
    "operationKind": "mutation",
    "text": "mutation paymentGatewayDetailMutation(\n  $input: VoidPaymentGatewayGroupInput!\n) {\n  voidPaymentGatewayGroup(input: $input) {\n    clientMutationId\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '907bc9786a2d8a79bdca5fa3e0b29d13';

module.exports = node;
