import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../../env/environment';

const query = graphql`
    query getCollectionsLettersQuery($id: ID!) {
      collectionLetters(id: $id){
            id
            recordCollectionLetters{
                edges{
                    node{
                        transaction {
                            id
                            creditNoteTransaction(status: "paid"){
                                edges{
                                    node{
                                        id
                                        docNumber
                                        issuedDate
                                    }
                                }
                            }
                            creditNoteTransactionRecord(status: "paid"){
                                edges{
                                    node{
                                        id
                                        price
                                        creditNote{
                                            id
                                            docNumber
                                            issuedDate
                                            status
                                        }
                                        status
                                    }
                                }
                            }
                            receiptDepositTransactionUsed{
                                edges{
                                    node{
                                        id
                                        receiptDeposit{
                                            id
                                            docNumber
                                            issuedDate
                                        }

                                    }
                                }
                            }
                            receiveTransaction {
                                edges {
                                    node {
                                        id
                                        receive {
                                            id
                                            docNumber
                                            issuedDate
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
}`
export const getCollectionsLetters = (id) => {
    return fetchQuery(environment, query,
        {
            id: id,
        })
};
