/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountRecordGroupCategory = "GENERAL" | "HISTORICAL" | "PAYMENT" | "PURCHASE" | "RECEIVE" | "SALES" | "VOIDED" | "%future added value";
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type bankTransferDetailQueryVariables = {|
  iddoc?: ?string
|};
export type bankTransferDetailQueryResponse = {|
  +bankTransferViewer: ?{|
    +allBankTransferDetail: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +transferOut: ?{|
            +id: string,
            +bankName: string,
            +accountNumber: string,
            +accountType: BankAccountAccountType,
          |},
          +transferIn: ?{|
            +id: string,
            +bankName: string,
            +accountNumber: string,
            +accountType: BankAccountAccountType,
          |},
          +fee: ?number,
          +amount: number,
          +date: any,
          +description: ?string,
          +added: any,
        |}
      |}>
    |}
  |},
  +allAccountRecordGroup: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +refNumber: ?string,
        +name: string,
        +added: any,
        +issuedDate: ?any,
        +category: AccountRecordGroupCategory,
        +totalDebit: number,
        +totalCredit: number,
        +refTransaction: ?string,
        +creator: ?string,
        +contact: ?{|
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
        +accountRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: string,
              +debit: number,
              +credit: number,
              +chartOfAccountCode: ?{|
                +id: string,
                +name: string,
                +chartOfAccountCode: string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +allBankAccountTransaction: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +price: number,
        +date: ?any,
        +bankAccount: {|
          +bankName: string,
          +accountNumber: string,
        |},
        +description: ?string,
      |}
    |}>
  |},
|};
export type bankTransferDetailQuery = {|
  variables: bankTransferDetailQueryVariables,
  response: bankTransferDetailQueryResponse,
|};
*/


/*
query bankTransferDetailQuery(
  $iddoc: String
) {
  bankTransferViewer {
    allBankTransferDetail(iddoc: $iddoc) {
      edges {
        node {
          id
          docNumber
          transferOut {
            id
            bankName
            accountNumber
            accountType
          }
          transferIn {
            id
            bankName
            accountNumber
            accountType
          }
          fee
          amount
          date
          description
          added
        }
      }
    }
    id
  }
  allAccountRecordGroup(refTransaction: $iddoc) {
    edges {
      node {
        id
        refNumber
        name
        added
        issuedDate
        category
        totalDebit
        totalCredit
        refTransaction
        creator
        contact {
          id
          name
          firstName
          lastName
        }
        accountRecord {
          edges {
            node {
              id
              name
              debit
              credit
              chartOfAccountCode {
                id
                name
                chartOfAccountCode
              }
            }
          }
        }
      }
    }
  }
  allBankAccountTransaction(refTransaction: $iddoc) {
    edges {
      node {
        id
        price
        date
        bankAccount {
          bankName
          accountNumber
          id
        }
        description
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "iddoc"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountType",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "iddoc",
      "variableName": "iddoc"
    }
  ],
  "concreteType": "BankTransferNodeConnection",
  "kind": "LinkedField",
  "name": "allBankTransferDetail",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankTransferNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankTransferNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "transferOut",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "transferIn",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "refTransaction",
    "variableName": "iddoc"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": (v9/*: any*/),
  "concreteType": "AccountRecordGroupNodeConnection",
  "kind": "LinkedField",
  "name": "allAccountRecordGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountRecordGroupNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountRecordGroupNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refNumber",
              "storageKey": null
            },
            (v10/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "issuedDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCredit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refTransaction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creator",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContactNode",
              "kind": "LinkedField",
              "name": "contact",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountRecordNodeConnection",
              "kind": "LinkedField",
              "name": "accountRecord",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountRecordNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AccountRecordNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "debit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "credit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ChartOfAccountNode",
                          "kind": "LinkedField",
                          "name": "chartOfAccountCode",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v10/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "chartOfAccountCode",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankTransferDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankTransferViewer",
        "kind": "LinkedField",
        "name": "bankTransferViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "BankAccountTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "allBankAccountTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v12/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountNode",
                    "kind": "LinkedField",
                    "name": "bankAccount",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankTransferDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankTransferViewer",
        "kind": "LinkedField",
        "name": "bankTransferViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "BankAccountTransactionNodeConnection",
        "kind": "LinkedField",
        "name": "allBankAccountTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v12/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountNode",
                    "kind": "LinkedField",
                    "name": "bankAccount",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4feaacc60fe6ab7ad6bee520f11d9c0",
    "id": null,
    "metadata": {},
    "name": "bankTransferDetailQuery",
    "operationKind": "query",
    "text": "query bankTransferDetailQuery(\n  $iddoc: String\n) {\n  bankTransferViewer {\n    allBankTransferDetail(iddoc: $iddoc) {\n      edges {\n        node {\n          id\n          docNumber\n          transferOut {\n            id\n            bankName\n            accountNumber\n            accountType\n          }\n          transferIn {\n            id\n            bankName\n            accountNumber\n            accountType\n          }\n          fee\n          amount\n          date\n          description\n          added\n        }\n      }\n    }\n    id\n  }\n  allAccountRecordGroup(refTransaction: $iddoc) {\n    edges {\n      node {\n        id\n        refNumber\n        name\n        added\n        issuedDate\n        category\n        totalDebit\n        totalCredit\n        refTransaction\n        creator\n        contact {\n          id\n          name\n          firstName\n          lastName\n        }\n        accountRecord {\n          edges {\n            node {\n              id\n              name\n              debit\n              credit\n              chartOfAccountCode {\n                id\n                name\n                chartOfAccountCode\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  allBankAccountTransaction(refTransaction: $iddoc) {\n    edges {\n      node {\n        id\n        price\n        date\n        bankAccount {\n          bankName\n          accountNumber\n          id\n        }\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4f90f1f024f44383d8592a2b0c90dad';

module.exports = node;
