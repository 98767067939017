import React from 'react';
import { formatDateISOThai } from "../../libs/date"
import Pagination from "../../libs/newPagination";
import { Link } from "react-router-dom";
import ComponentPagination from "../../libs/componentPagination";
import environment from "../../env/environment";
import { QueryRenderer } from 'react-relay';
import Loading from '../../libs/loading';
import { Translation } from "react-i18next";
import localStoragePage from "../../libs/localstorage";

class SettingInvoiceTermsListTable extends ComponentPagination {

    componentDidUpdate(nextProps, nextState, nextContext) {
        if (this.state.status !== this.props.status) {
            this.setState({
                status: this.props.status,
                checkList: [],
                check_all: false,
            }, () => {
                this.goFirst();
            })
        }
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={this.props.query}
                cacheConfig={{ use_cache: false }}
                variables={{
                    first: this.state.first, last: this.state.last,
                    search: this.props.search
                }}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        let order_id = this.state.first - localStoragePage.getPageLimit();
                        return <React.Fragment>
                            
                            <div className="card-table">
                                <div className="table-responsive fade-up">
                          
                                    <table className="table table-hover">
                                        <thead className="table-list text-left">
                                        <Translation>
                                            {t =>
                                            <tr>
                                                <th className="text-global-table">{t("settingInvoiceTerms:no")}</th>
                                                <th className="text-global-table">{t("settingInvoiceTerms:code")}</th>
                                                <th className="text-global-table">{t("settingInvoiceTerms:number")}</th>
                                                <th className="text-global-table">{t("settingInvoiceTerms:name")}</th>
                                                {this.props.developer &&
                                                    <th className="text-global-table">{t("settingInvoiceTerms:status")}</th>
                                                }
                                                <th className="text-global-table">{t("settingInvoiceTerms:last_update_date")}</th>
                                                <th className="text-global-table">{t("settingInvoiceTerms:save_by")}</th>
                                            </tr>
                                            }
                                             </Translation>
                                        </thead>
                                        <tbody>
                                            {
                                                props.allPreInvoiceGroup.edges.map((preinvoicegroup, index) => {

                                                    let status = ""
                                                    let status_link = ""
                                                    if (preinvoicegroup.node.status === "DEVELOPER") {
                                                        status = "ยังไม่ได้โอนกรรมสิทธิ์"
                                                        status_link = "not"
                                                    } else if (preinvoicegroup.node.status === "DEVELOPER_SUPPORT") {
                                                        status = "DEVELOPER สนับสนุน"
                                                        status_link = "developer"
                                                    } else {
                                                        status = "เจ้าของ"
                                                        status_link = "owner"
                                                    }

                                                    let row_number = (order_id + index + 1);
                                                    return (
                                                        <tr key={"headPreInvoice" + index}>
                                                            <td>{row_number}</td>
                                                            <td>
                                                                <Link to={{ pathname: "/setting/invoice-setting-terms/detail/"+status_link+"/" + preinvoicegroup.node.id }}>
                                                                    <span className="blue-color">{preinvoicegroup.node.contactCode}</span>
                                                                </Link>
                                                            </td>
                                                            <td>{preinvoicegroup.node.contact.name}</td>
                                                            <td>{preinvoicegroup.node.contact.firstName + " " + preinvoicegroup.node.contact.lastName}</td>
                                                            {/* <td>{status}</td> */}
                                                            {this.props.developer &&
                                                                <td>{status}</td>
                                                            }
                                                            <td>{formatDateISOThai(preinvoicegroup.node.updated)}</td>
                                                            <td>{preinvoicegroup.node.creator}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.allPreInvoiceGroup.totalCount} />
                            </div>
                        </React.Fragment>
                        

                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default SettingInvoiceTermsListTable