import React, {Component} from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Wrapper from '../../../components/wrapper';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import '../styles/fixRequest.scss';

import BigCalendar from 'react-big-calendar-like-google';
import 'react-big-calendar-like-google/lib/css/react-big-calendar.css';
import moment from 'moment';

BigCalendar.momentLocalizer(moment);

const myEventsList = [
    {
      allDay: false,
      end: new Date('December 10, 2017 11:13:00'),
      start: new Date('December 09, 2017 11:13:00'),
      title: 'hi',
    },
    {
      allDay: true,
      end: new Date('December 09, 2017 11:13:00'),
      start: new Date('December 09, 2017 11:13:00'),
      title: 'All Day Event',
    },
  ];

class ScheduleList extends Component {
    //https://onursimsek94.github.io/react-big-calendar/examples/index.html#intro
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>

                    <BigCalendar
                        events={myEventsList}
                        startAccessor='startDate'
                        endAccessor='endDate'
                        drilldownView="day"
                    />

                </WrapperContent>
            </Wrapper>
        );
    }
}

export default ScheduleList;