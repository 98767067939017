/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type chequeControlListAllQueryVariables = {|
  bankAccountId?: ?string
|};
export type chequeControlListAllQueryResponse = {|
  +chequeControl: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +bankName: string,
        +branch: string,
        +bankLogo: string,
        +accountType: BankAccountAccountType,
        +accountName: string,
        +accountNameEn: ?string,
        +accountNumber: string,
        +updated: any,
        +reorderPoint: number,
        +chequeDetails: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +status: boolean,
              +noNumber: string,
              +startCheque: ?string,
              +endCheque: ?string,
              +lastCheque: ?string,
              +chequeNo: ?{|
                +totalCount: ?number,
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +status: boolean,
                    +noNumber: string,
                  |}
                |}>,
              |},
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type chequeControlListAllQuery = {|
  variables: chequeControlListAllQueryVariables,
  response: chequeControlListAllQueryResponse,
|};
*/


/*
query chequeControlListAllQuery(
  $bankAccountId: String
) {
  chequeControl(status: true, bankAccountId: $bankAccountId) {
    totalCount
    edges {
      node {
        id
        docNumber
        bankName
        branch
        bankLogo
        accountType
        accountName
        accountNameEn
        accountNumber
        updated
        reorderPoint
        chequeDetails(status: true) {
          edges {
            node {
              id
              status
              noNumber
              startCheque
              endCheque
              lastCheque
              chequeNo(status: true) {
                totalCount
                edges {
                  node {
                    id
                    status
                    noNumber
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bankAccountId"
  }
],
v1 = {
  "kind": "Literal",
  "name": "status",
  "value": true
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noNumber",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bankAccountId",
        "variableName": "bankAccountId"
      },
      (v1/*: any*/)
    ],
    "concreteType": "BankAccountNodeConnection",
    "kind": "LinkedField",
    "name": "chequeControl",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bankName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "branch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bankLogo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNameEn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reorderPoint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "ChequeDetailsNodeConnection",
                "kind": "LinkedField",
                "name": "chequeDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChequeDetailsNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChequeDetailsNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startCheque",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCheque",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastCheque",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "concreteType": "ChequeNoNodeConnection",
                            "kind": "LinkedField",
                            "name": "chequeNo",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChequeNoNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ChequeNoNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v5/*: any*/),
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "chequeNo(status:true)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "chequeDetails(status:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "chequeControlListAllQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "chequeControlListAllQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "63e942aec2125c4ec28220bb4b30c7da",
    "id": null,
    "metadata": {},
    "name": "chequeControlListAllQuery",
    "operationKind": "query",
    "text": "query chequeControlListAllQuery(\n  $bankAccountId: String\n) {\n  chequeControl(status: true, bankAccountId: $bankAccountId) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        bankName\n        branch\n        bankLogo\n        accountType\n        accountName\n        accountNameEn\n        accountNumber\n        updated\n        reorderPoint\n        chequeDetails(status: true) {\n          edges {\n            node {\n              id\n              status\n              noNumber\n              startCheque\n              endCheque\n              lastCheque\n              chequeNo(status: true) {\n                totalCount\n                edges {\n                  node {\n                    id\n                    status\n                    noNumber\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aad49fc836a15ef2aa352adad555081b';

module.exports = node;
