/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfCompany = "BRANCH" | "HEADQUARTERS" | "NOBRANCH" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ContactTypeOfCountry = "INSIDE" | "OUTSIDE" | "%future added value";
export type ContactTypeOfPayment = "PAYABLE" | "RECEIVABLE" | "%future added value";
export type ContactTypeOfPeople = "GENERAL_PEOPLE" | "JURISTIC_PEOPLE" | "%future added value";
export type ContactTypeOfSetting = "DEFAULT" | "LASTMONTH_NEXT" | "LASTMONTH_OUT" | "X_AFTER_DUE" | "X_AFTER_OUT" | "X_NEXT_MONTH" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type contactCreateUpdateQueryVariables = {|
  id: string
|};
export type contactCreateUpdateQueryResponse = {|
  +contact: ?{|
    +id: string,
    +refNumber: string,
    +name: string,
    +taxIdNumber: ?string,
    +firstName: string,
    +lastName: string,
    +phone: string,
    +email: ?string,
    +webSite: string,
    +registeredName: string,
    +registeredAddress: string,
    +registeredCountry: string,
    +registeredProvince: string,
    +registeredCity: string,
    +registeredDistrict: string,
    +registeredPostalCode: string,
    +mailingName: string,
    +mailingAddress: string,
    +mailingCountry: string,
    +mailingDistrict: string,
    +mailingProvince: string,
    +mailingCity: string,
    +mailingPostalCode: string,
    +renterAddress: ?string,
    +renterCountry: string,
    +renterProvince: string,
    +renterDistrict: string,
    +renterCity: string,
    +renterPostalCode: string,
    +agentAddress: ?string,
    +agentCountry: string,
    +agentProvince: string,
    +agentDistrict: string,
    +agentCity: string,
    +agentPostalCode: string,
    +typeOfSupplier: ContactTypeOfSupplier,
    +totalAmount: number,
    +typeOfPayment: ContactTypeOfPayment,
    +typeOfContact: ContactTypeOfContact,
    +position: string,
    +nickName: string,
    +mainPhone: string,
    +mainEmail: ?string,
    +fax: string,
    +note: string,
    +vat: ?number,
    +typeOfCompany: ?ContactTypeOfCompany,
    +nameBranch: string,
    +prefixName: ?string,
    +typeOfSetting: ?ContactTypeOfSetting,
    +settingDate: ?any,
    +typeOfCountry: ?ContactTypeOfCountry,
    +typeOfPeople: ?ContactTypeOfPeople,
    +settingNumberDate: ?number,
    +bankName: ?string,
    +bankBranchNumber: ?string,
    +accountNumber: ?string,
    +accountName: ?string,
    +accountNameEn: ?string,
    +imageBookBankName: ?string,
    +imageBookBankUpload: ?string,
    +imageBookBankShow: ?string,
    +prefixNameContact: ?string,
    +firstNameContact: ?string,
    +lastNameContact: ?string,
    +nickNameContact: ?string,
    +phoneContact: ?string,
    +emailContact: ?string,
    +contactFile: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +fileName: ?string,
          +fileUpload: string,
          +fileShow: ?string,
        |}
      |}>
    |},
    +statusOfCompany: ?string,
    +registeredCapital: ?string,
  |}
|};
export type contactCreateUpdateQuery = {|
  variables: contactCreateUpdateQueryVariables,
  response: contactCreateUpdateQueryResponse,
|};
*/


/*
query contactCreateUpdateQuery(
  $id: ID!
) {
  contact(id: $id) {
    id
    refNumber
    name
    taxIdNumber
    firstName
    lastName
    phone
    email
    webSite
    registeredName
    registeredAddress
    registeredCountry
    registeredProvince
    registeredCity
    registeredDistrict
    registeredPostalCode
    mailingName
    mailingAddress
    mailingCountry
    mailingDistrict
    mailingProvince
    mailingCity
    mailingPostalCode
    renterAddress
    renterCountry
    renterProvince
    renterDistrict
    renterCity
    renterPostalCode
    agentAddress
    agentCountry
    agentProvince
    agentDistrict
    agentCity
    agentPostalCode
    typeOfSupplier
    totalAmount
    typeOfPayment
    typeOfContact
    position
    nickName
    mainPhone
    mainEmail
    fax
    note
    vat
    typeOfCompany
    nameBranch
    prefixName
    typeOfSetting
    settingDate
    typeOfCountry
    typeOfPeople
    settingNumberDate
    bankName
    bankBranchNumber
    accountNumber
    accountName
    accountNameEn
    imageBookBankName
    imageBookBankUpload
    imageBookBankShow
    prefixNameContact
    firstNameContact
    lastNameContact
    nickNameContact
    phoneContact
    emailContact
    contactFile {
      edges {
        node {
          fileName
          fileUpload
          fileShow
          id
        }
      }
    }
    statusOfCompany
    registeredCapital
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxIdNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "webSite",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredAddress",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCountry",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredProvince",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCity",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredDistrict",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredPostalCode",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mailingName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mailingAddress",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mailingCountry",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mailingDistrict",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mailingProvince",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mailingCity",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mailingPostalCode",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterAddress",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterCountry",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterProvince",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterDistrict",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterCity",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "renterPostalCode",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentAddress",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentCountry",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentProvince",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentDistrict",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentCity",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentPostalCode",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfSupplier",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAmount",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfPayment",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickName",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainPhone",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainEmail",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fax",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfCompany",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameBranch",
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefixName",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfSetting",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settingDate",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfCountry",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfPeople",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settingNumberDate",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankBranchNumber",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountName",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNameEn",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageBookBankName",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageBookBankUpload",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageBookBankShow",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefixNameContact",
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstNameContact",
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastNameContact",
  "storageKey": null
},
v67 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickNameContact",
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneContact",
  "storageKey": null
},
v69 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailContact",
  "storageKey": null
},
v70 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v71 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUpload",
  "storageKey": null
},
v72 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileShow",
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusOfCompany",
  "storageKey": null
},
v74 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCapital",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contactCreateUpdateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContactNode",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          (v58/*: any*/),
          (v59/*: any*/),
          (v60/*: any*/),
          (v61/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          (v65/*: any*/),
          (v66/*: any*/),
          (v67/*: any*/),
          (v68/*: any*/),
          (v69/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactFileNodeConnection",
            "kind": "LinkedField",
            "name": "contactFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v70/*: any*/),
                      (v71/*: any*/),
                      (v72/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v73/*: any*/),
          (v74/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contactCreateUpdateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContactNode",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          (v58/*: any*/),
          (v59/*: any*/),
          (v60/*: any*/),
          (v61/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          (v65/*: any*/),
          (v66/*: any*/),
          (v67/*: any*/),
          (v68/*: any*/),
          (v69/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactFileNodeConnection",
            "kind": "LinkedField",
            "name": "contactFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v70/*: any*/),
                      (v71/*: any*/),
                      (v72/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v73/*: any*/),
          (v74/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "856b8b25cbce883e0b32a2bb0a237d08",
    "id": null,
    "metadata": {},
    "name": "contactCreateUpdateQuery",
    "operationKind": "query",
    "text": "query contactCreateUpdateQuery(\n  $id: ID!\n) {\n  contact(id: $id) {\n    id\n    refNumber\n    name\n    taxIdNumber\n    firstName\n    lastName\n    phone\n    email\n    webSite\n    registeredName\n    registeredAddress\n    registeredCountry\n    registeredProvince\n    registeredCity\n    registeredDistrict\n    registeredPostalCode\n    mailingName\n    mailingAddress\n    mailingCountry\n    mailingDistrict\n    mailingProvince\n    mailingCity\n    mailingPostalCode\n    renterAddress\n    renterCountry\n    renterProvince\n    renterDistrict\n    renterCity\n    renterPostalCode\n    agentAddress\n    agentCountry\n    agentProvince\n    agentDistrict\n    agentCity\n    agentPostalCode\n    typeOfSupplier\n    totalAmount\n    typeOfPayment\n    typeOfContact\n    position\n    nickName\n    mainPhone\n    mainEmail\n    fax\n    note\n    vat\n    typeOfCompany\n    nameBranch\n    prefixName\n    typeOfSetting\n    settingDate\n    typeOfCountry\n    typeOfPeople\n    settingNumberDate\n    bankName\n    bankBranchNumber\n    accountNumber\n    accountName\n    accountNameEn\n    imageBookBankName\n    imageBookBankUpload\n    imageBookBankShow\n    prefixNameContact\n    firstNameContact\n    lastNameContact\n    nickNameContact\n    phoneContact\n    emailContact\n    contactFile {\n      edges {\n        node {\n          fileName\n          fileUpload\n          fileShow\n          id\n        }\n      }\n    }\n    statusOfCompany\n    registeredCapital\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca87ba80a5aad30dde8f6dcf95b9c3d6';

module.exports = node;
