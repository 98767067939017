
import { graphql } from "babel-plugin-relay/macro";

const queryGetAllSite = graphql`
  query getAllSiteQuery{
    getAllSite{
            edges{
                node{
                    id 
                    name
                    project{
                      edges{
                          node{
                            name
                          }
                      }
                    }
                }
            }
    },
  }          
`;


export default queryGetAllSite;
