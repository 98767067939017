import React, { Component } from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import "./collection.scss";
import CollectionLetterAttachMutation from "./collectionLetterAttachMutation";
import { Translation } from "react-i18next";
import upload from "../../libs/upload";
import i18next from "i18next";

class CollectionLetterAttach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      reQuery: false,
      contentItems: [],
      startNumberOfAttach: this.props.location.state.startNumberOfAttach,
      imageName: '',
    };
    this.handleAddAttachForm = this.handleAddAttachForm.bind(this);
    this.handleDelAttachForm = this.handleDelAttachForm.bind(this);
    this.onSubmitAttach = this.onSubmitAttach.bind(this);
  }

  componentWillMount() {
    this.handleAddAttachForm();
  }

  handleAddAttachForm = () => {
    const { contentItems } = this.state;

    const newItem = {
      id: "",
      fileName: "",
      numPage: "",
      fileUpload: "",
      status: "",
    };

    this.setState({
      contentItems: [...contentItems, newItem],
    });
  };

  handleDelAttachForm = (index) => {
    const { contentItems } = this.state;
    
    if (index >= 0 && index < contentItems.length) {
      contentItems.splice(index, 1); 
      this.setState({
        contentItems: [...contentItems],
      });
    }
  };

  onSubmitAttach = async () => {
    this.setState({ loading: true });

    const updatedContentItems = this.state.contentItems.map(async (item) => {
      const numPage = await this.countPagePDF(item.fileUpload);
      return { ...item, numPage };
    });

    Promise.all(updatedContentItems).then((updatedItems) => {
      this.setState(
        {
          contentItems: updatedItems,
        },
        () => this.uploadData(this.state.contentItems)
      );
    });
  };

  uploadData = async (dataUploaded) => {
    let documentAdded = [];
    for (const file of dataUploaded) {
      await this.callUpload("", file.fileUpload, file.fileName).then((data) => {
        documentAdded.push({
          id: "",
          fileUpload: data.key.replace("silverman-storage/private/", ""),
          status: "",
          fileName: file.fileName,
          numPage: file.numPage,
        });
      });
    }

    let variables = {
      input: {
        fileDocument: {
          collectionLettersId: `${this.props.match.params.id}`,
          pdf: documentAdded,
        },
      },
    };

    CollectionLetterAttachMutation(
      variables,
      () =>
        Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
          this.setState({
            loading: false,
            redirect: true,
          });
        }),
      () =>
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "error").then(() => {
          this.setState({ loading: false });
        })
    );
  };

  callUpload = async (path, file, fileName) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload(`/collection/${fileName}/data` + path, file);
      resolve(res);
    });
  };

  handleContentChange = (index, field, value) => {
    const { contentItems } = this.state;
    const updatedItems = [...contentItems];
    updatedItems[index][field] = value;

    this.setState({
      contentItems: updatedItems,
    });
  };

  countPagePDF = (fileUpload) => {
    const file = fileUpload;
    let countPagePdf = 0; // Initialize the count to 0

    const blob = new Blob([file], { type: file.type });
    const url = URL.createObjectURL(blob);

    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const matchResult = result.match(/\/Type[\s]*\/Page[^s]/g);
        if (matchResult) {
          countPagePdf = matchResult.length;
        }
        return countPagePdf;
      })
      .catch((error) => {
        console.log("error", error);
        return 0;
      });
  };

  onCancelAttach = () => {
    const { contentItems } = this.state;

    const newItem = {
      id: "",
      fileName: "",
      numPage: "",
      fileUpload: "",
      status: "",
    };

    this.setState({
      contentItems: [newItem],
    });
  }

  handleImageChange = (event) => {
    const inputImage = event.target.files[0];
    const imageName = inputImage.name;

    this.setState({ imageName });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={`/accounting/collection_letters/detail/ACTIVE/${this.props.match.params.id}`}
        />
      );
    }

    return (
      
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="collection-letter">
            <div className="row justify-content-between">
              <div className="col">
                <h4 className="mb-4">
                  <Link
                    to={`/accounting/collection_letters/detail/ACTIVE/${this.props.match.params.id}`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  {this.props.location.state.documentNum}
                </h4>
              </div>
            </div>
            <div className="collectionAttachContainer">         
                {this.state.contentItems.map((item, index) => (
                <React.Fragment key={index}>
                    <div className="attachText">
                    <Translation>{t=>
                        <h5>{t("collectionLetter:Attached documents No.")} {this.state.startNumberOfAttach + index + 1}</h5>} 
                    </Translation>
                    <hr />
                    </div>
                    <div className="fromAttachFile">
                    <div className="inputContainer">
                      <Translation>{t=>
                        <label>{t("collectionLetter:Attached documents No.")} {this.state.startNumberOfAttach + index + 1}</label>} 
                      </Translation>
                        <input
                        className="inputFileName"  
                        placeholder=""
                        type="text"
                        value={item.fileName}
                        onChange={(e) =>
                            this.handleContentChange(
                            index,
                            "fileName",
                            e.target.value
                            )
                        }
                        /> 
                    </div>
                    <div className="attContainer">
                    <Translation>{t=>
                        <label>                          
                           {t("collectionLetter:Attached file")}
                            <div className="tooltipImg">
                                <img src={process.env.PUBLIC_URL + "/images/tooltip/light.png"} alt="question" className="question ml-2"/>
                                <Translation>{t=>
                                <span className="tooltiptext">
                                  {t("residential:Only PDF, DOC, PNG and JPG files are supported, and their size must not exceed 10 MB.")}
                                </span> }</Translation>
                                <span className="tooltipTriangle">
                                <img src={process.env.PUBLIC_URL + '/images/tooltip/tooltipTriangle.png'} alt="tooltipTriangle"/>
                                </span>
                            </div>
                        </label>}
                          </Translation>
                        <div>
                        <Translation>{t=>
                        <label className="attBtn" htmlFor={`attachFileCollection ${index}`}>@
                          {t("projectManager:Attach File")}</label> }</Translation>
                        <input
                            id={`attachFileCollection ${index}`}
                            type="file"
                            style={{display:'none'}}
                            onChange={(e) => {
                                    const attachFile = e.target.files[0];
                                    const maxSize = 10 * 1024 * 1024; // 10MB limit
                                    if (attachFile && attachFile.size <= maxSize) {
                                        this.handleContentChange(index, "fileUpload", attachFile);
                                    } else {
                                        Swal.fire(i18next.t("collectionLawFirm:Size is over"), i18next.t("collectionLawFirm:Please choose new"), 'warning');
                                        return
                                    } 
                                }
                            }
                        />
                        {item.fileUpload?.name && <p>{item.fileUpload.name}</p>}
                        </div>
                    </div>
                    <div className="deleteBtnContainer">
                        <button className="deleteBtn" onClick={()=>this.handleDelAttachForm(index)}>
                            <img src={process.env.PUBLIC_URL +"/images/icons/bin-outline.png"} alt="delete-icon"/>
                            {" "}<Translation>{t=> t("Allaction:Delete")}</Translation>
                        </button>
                    </div>
                    </div>
                </React.Fragment>
                ))}
              
                <button className="attachBtn" onClick={() => this.handleAddAttachForm()}>+ 
                <Translation>{t=> t("collectionLawFirm:Add Attach document")}</Translation></button>
            </div>            
            <div className="mt-4 confirmContainer">
              <button className="mr-2 btn btn-secondary add" onClick={() => this.onCancelAttach()}> <Translation>{t=>t("claimRequst:Cancle")}</Translation></button>
              <button className="btn btn-primary add" onClick={() => this.onSubmitAttach()}><Translation>{t=>t("Allaction:save")}</Translation></button>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default CollectionLetterAttach;
