/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type ProductAndServiceType = "EXPENSE" | "FINE" | "PRODUCT" | "SERVICE" | "%future added value";
export type SettingAssetTypeAsset = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type otherExpenseCreateFormQueryVariables = {|
  id: string
|};
export type otherExpenseCreateFormQueryResponse = {|
  +contact: ?{|
    +id: string,
    +refNumber: string,
    +name: string,
    +firstName: string,
    +lastName: string,
    +vat: ?number,
    +registeredAddress: string,
    +registeredCountry: string,
    +registeredProvince: string,
    +registeredDistrict: string,
    +registeredCity: string,
    +registeredPostalCode: string,
    +typeOfContact: ContactTypeOfContact,
    +typeOfSupplier: ContactTypeOfSupplier,
  |},
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +productCode: string,
          +type: ProductAndServiceType,
          +price: ?number,
          +chartOfAccount: ?{|
            +id: string,
            +chartOfAccountCode: string,
            +name: string,
          |},
          +description: ?string,
          +totalLeftInStock: ?number,
          +taxRate: ?string,
          +typeOfIncomeOther: ?string,
        |}
      |}>
    |}
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +chartOfAccountCode: string,
          +name: string,
        |}
      |}>
    |}
  |},
  +selfProject: ?{|
    +haveVat: boolean
  |},
  +allSettingAsset: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +typeAsset: SettingAssetTypeAsset,
        +depreciationRatePercent: ?number,
        +depreciationRate: ?number,
        +lifeTimeYear: ?number,
      |}
    |}>
  |},
|};
export type otherExpenseCreateFormQuery = {|
  variables: otherExpenseCreateFormQueryVariables,
  response: otherExpenseCreateFormQueryResponse,
|};
*/


/*
query otherExpenseCreateFormQuery(
  $id: ID!
) {
  contact(id: $id) {
    id
    refNumber
    name
    firstName
    lastName
    vat
    registeredAddress
    registeredCountry
    registeredProvince
    registeredDistrict
    registeredCity
    registeredPostalCode
    typeOfContact
    typeOfSupplier
  }
  productViewer {
    allProduct(type_In: "expense", isActive: true) {
      edges {
        node {
          id
          name
          productCode
          type
          price
          chartOfAccount {
            id
            chartOfAccountCode
            name
          }
          description
          totalLeftInStock
          taxRate
          typeOfIncomeOther
        }
      }
    }
    id
  }
  viewer {
    allChartOfAccount(codeList: ["1111", "1151", "1152", "1153", "1154", "1210", "1230-01", "2121", "2122", "2123", "2124-01", "2124-02", "2124-03", "2124-04", "2125", "2126", "2210-09", "2124-05", "2410", "51", "52", "53", "54", "55", "56", "58"]) {
      edges {
        node {
          id
          chartOfAccountCode
          name
        }
      }
    }
    id
  }
  selfProject {
    haveVat
    id
  }
  allSettingAsset(settingAssetId: "", typeAsset: "") {
    edges {
      node {
        id
        typeAsset
        depreciationRatePercent
        depreciationRate
        lifeTimeYear
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredPostalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfSupplier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "chartOfAccountCode",
    "storageKey": null
  },
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "isActive",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "type_In",
      "value": "expense"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "allProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChartOfAccountNode",
              "kind": "LinkedField",
              "name": "chartOfAccount",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalLeftInStock",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeOfIncomeOther",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allProduct(isActive:true,type_In:\"expense\")"
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "codeList",
      "value": [
        "1111",
        "1151",
        "1152",
        "1153",
        "1154",
        "1210",
        "1230-01",
        "2121",
        "2122",
        "2123",
        "2124-01",
        "2124-02",
        "2124-03",
        "2124-04",
        "2125",
        "2126",
        "2210-09",
        "2124-05",
        "2410",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "58"
      ]
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(codeList:[\"1111\",\"1151\",\"1152\",\"1153\",\"1154\",\"1210\",\"1230-01\",\"2121\",\"2122\",\"2123\",\"2124-01\",\"2124-02\",\"2124-03\",\"2124-04\",\"2125\",\"2126\",\"2210-09\",\"2124-05\",\"2410\",\"51\",\"52\",\"53\",\"54\",\"55\",\"56\",\"58\"])"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "haveVat",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "settingAssetId",
      "value": ""
    },
    {
      "kind": "Literal",
      "name": "typeAsset",
      "value": ""
    }
  ],
  "concreteType": "SettingAssetNodeConnection",
  "kind": "LinkedField",
  "name": "allSettingAsset",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SettingAssetNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SettingAssetNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "typeAsset",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciationRatePercent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depreciationRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lifeTimeYear",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allSettingAsset(settingAssetId:\"\",typeAsset:\"\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "otherExpenseCreateFormQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "otherExpenseCreateFormQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "5342c1a528c02f9bcb488ad3890f8476",
    "id": null,
    "metadata": {},
    "name": "otherExpenseCreateFormQuery",
    "operationKind": "query",
    "text": "query otherExpenseCreateFormQuery(\n  $id: ID!\n) {\n  contact(id: $id) {\n    id\n    refNumber\n    name\n    firstName\n    lastName\n    vat\n    registeredAddress\n    registeredCountry\n    registeredProvince\n    registeredDistrict\n    registeredCity\n    registeredPostalCode\n    typeOfContact\n    typeOfSupplier\n  }\n  productViewer {\n    allProduct(type_In: \"expense\", isActive: true) {\n      edges {\n        node {\n          id\n          name\n          productCode\n          type\n          price\n          chartOfAccount {\n            id\n            chartOfAccountCode\n            name\n          }\n          description\n          totalLeftInStock\n          taxRate\n          typeOfIncomeOther\n        }\n      }\n    }\n    id\n  }\n  viewer {\n    allChartOfAccount(codeList: [\"1111\", \"1151\", \"1152\", \"1153\", \"1154\", \"1210\", \"1230-01\", \"2121\", \"2122\", \"2123\", \"2124-01\", \"2124-02\", \"2124-03\", \"2124-04\", \"2125\", \"2126\", \"2210-09\", \"2124-05\", \"2410\", \"51\", \"52\", \"53\", \"54\", \"55\", \"56\", \"58\"]) {\n      edges {\n        node {\n          id\n          chartOfAccountCode\n          name\n        }\n      }\n    }\n    id\n  }\n  selfProject {\n    haveVat\n    id\n  }\n  allSettingAsset(settingAssetId: \"\", typeAsset: \"\") {\n    edges {\n      node {\n        id\n        typeAsset\n        depreciationRatePercent\n        depreciationRate\n        lifeTimeYear\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '66991c19f2b9c24fa0af6751454bba4c';

module.exports = node;
