import React from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../env/environment";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Pagination from "../../libs/newPagination";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import Swal from "sweetalert2";
import i18n from 'i18next';
import getDocumentNumber from "../../libs/getDocumentNumber";
import Loading from "../../libs/loading";
import i18next from "i18next";
import "./collectionLawFirm.scss";
import StatusLawDebt from "./status";
import ReactModal from "react-modal";
import upload from '../../libs/upload'
import createAndUpdateReceiptMutation from "./mutation/createAndUpdateReceipt";
import { Translation } from "react-i18next";
import { getCollectionsLetters } from "./query/getCollectionsLetters";
const query = graphql`
    query collectionLawFirmListTableQuery {
        allContactCollectionLawFirm {
            totalCount
            edges{
                node{
                    id
                    refNumber
                    name
                    collectionLettersLastId
                    collectionLettersSumTotal
                    collectionLettersIssuedDate
                    collectionLettersDateCreate
                    collectionLettersCreator
                    collectionLettersFee
                    firstName lastName
            }
        } 
    }
}
    
`;
class CollectionLawFirmListTable extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            search: "",
            selectedId: [],
            reQuery: false,
            checkAll: false,
            tempStartDate: null,
            tempEndDate: null,
            noti: 0,
            isModalOpen: false,
            selectedDocNumberBefore: null,
            selectedDocNumberAfter: null,
            file: [],
            fileIpload: [],
        }
        this.handleChange = this.handleChange.bind(this)

    }

    componentDidMount() {
        this.goFirst()

    }


    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }



    handleKeyDown(event) {
        if (event.key === "Enter") {
            this.handleSearch(event);
            this.setState({
                startDate: new Date(new Date(this.state.tempStartDate).setHours(0, 0, 0, 0)),
                endDate: new Date(new Date(this.state.tempEndDate).setHours(24, 0, 0, 0)),
            })
        }
    }


    handleSearch = (text) => {
        this.setState({
            search: text,
            startDate: this.state.tempStartDate ? new Date(new Date(this.state.tempStartDate).setHours(0, 0, 0, 0)) : null,
            endDate: this.state.tempEndDate ? new Date(new Date(this.state.tempEndDate).setHours(24, 0, 0, 0)) : null,
        })
    }

    onCheck = (id) => {
        let index = this.state.selectedId.indexOf(id);
        let selected = _.cloneDeep(this.state.selectedId)
        if (index === -1) {
            selected.push(id)
        } else {
            selected.splice(index, 1)
        }
        this.setState({
            selectedId: selected
        })
        this.props.setselectedId(selected)
    }

    onCheckAll = (props) => {
        let selected = []
        this.setState({ checkAll: !this.state.checkAll }, () => {
            if (this.state.checkAll) {
                _.forEach(props.allCollectionLawFirm.edges, collection => {
                    selected.push(collection.node.id)
                })
                this.setState({
                    selectedId: selected
                })
                this.props.setselectedId(selected)
            } else {
                this.setState({
                    selectedId: []
                })
                this.props.setselectedId([])
            }
        })

    }

    openModal = (id , issuedDate) => {

        getCollectionsLetters(id).then(data => {
            
            let ref_doc_number_list_after = [];
            let ref_doc_number_list_before = [];
            let ref_doc_check = []
            _.forEach(data.collectionLetters?.recordCollectionLetters?.edges, record_collection_letters => {
                if (record_collection_letters.node.transaction.receiptDepositTransactionUsed.edges.length !== 0) {
                    record_collection_letters.node.transaction.receiptDepositTransactionUsed.edges.forEach(
                        (receive_deposit) => {

                            if (format(new Date(issuedDate), "YYYY-MM-DD") >= format(new Date(receive_deposit.node.receiptDeposit.issuedDate), "YYYY-MM-DD")) {
                                if (!ref_doc_check.includes(receive_deposit.node.receiptDeposit.docNumber)) {
                                    ref_doc_number_list_before.push({
                                        type: "receive_deposit",
                                        id: receive_deposit.node.receiptDeposit.id,
                                        doc_number: receive_deposit.node.receiptDeposit.docNumber,
                                    });
                                    ref_doc_check.push(receive_deposit.node.receiptDeposit.docNumber)
                                }
                            } else {
                                if (!ref_doc_check.includes(receive_deposit.node.receiptDeposit.docNumber)) {
                                    ref_doc_number_list_after.push({
                                        type: "receive_deposit",
                                        id: receive_deposit.node.receiptDeposit.id,
                                        doc_number: receive_deposit.node.receiptDeposit.docNumber,
                                    });
                                    ref_doc_check.push(receive_deposit.node.receiptDeposit.docNumber)
                                }
                            }


                        }
                    );
                }

                if (record_collection_letters.node.transaction.creditNoteTransaction.edges.length !== 0) {
                    record_collection_letters.node.transaction.creditNoteTransaction.edges.forEach(
                        (credit_note) => {
                            if (format(new Date(issuedDate), "YYYY-MM-DD") > format(new Date(credit_note.node.issuedDate), "YYYY-MM-DD")) {
                                if (!ref_doc_check.includes(credit_note.node.docNumber)) {
                                    ref_doc_number_list_before.push({
                                        type: "credit_note",
                                        id: credit_note.node.id,
                                        doc_number: credit_note.node.docNumber,
                                    });
                                }
                            } else {
                                if (!ref_doc_check.includes(credit_note.node.docNumber)) {
                                    ref_doc_number_list_after.push({
                                        type: "credit_note",
                                        id: credit_note.node.id,
                                        doc_number: credit_note.node.docNumber,
                                    });
                                }
                            }


                        }
                    );
                }

                if (record_collection_letters.node.transaction.creditNoteTransactionRecord.edges.length !== 0) {
                    record_collection_letters.node.transaction.creditNoteTransactionRecord.edges.forEach(
                        (credit_note_record) => {
                            if (format(new Date(issuedDate), "YYYY-MM-DD") > format(new Date(credit_note_record.node.creditNote.issuedDate), "YYYY-MM-DD")) {
                                if (!ref_doc_check.includes(credit_note_record.node.creditNote.docNumber)) {
                                    ref_doc_number_list_before.push({
                                        type: "credit_note",
                                        id: credit_note_record.node.creditNote.id,
                                        doc_number: credit_note_record.node.creditNote.docNumber,
                                    });
                                }
                            } else {
                                if (!ref_doc_check.includes(credit_note_record.node.creditNote.docNumber)) {
                                    ref_doc_number_list_after.push({
                                        type: "credit_note",
                                        id: credit_note_record.node.creditNote.id,
                                        doc_number: credit_note_record.node.creditNote.docNumber,
                                    });
                                }
                            }


                        }
                    );
                }

                if (record_collection_letters.node.transaction.receiveTransaction.edges.length !== 0) {
                    record_collection_letters.node.transaction.receiveTransaction.edges.forEach(
                        (receive) => {
                            if (receive.node.receive.status !== "VOID") {

                                let docNumber = receive.node.receive.docNumber
                                if (receive.node.receive.status === 'WAIT') {
                                    docNumber = getDocumentNumber.get(
                                        "receive",
                                        "XXXX",
                                        new Date(receive.node.receive.issuedDate))
                                }

                                if (format(new Date(issuedDate), "YYYY-MM-DD") > format(new Date(receive.node.receive.issuedDate), "YYYY-MM-DD")) {
                                    if (!ref_doc_check.includes(docNumber)) {
                                        ref_doc_number_list_before.push({
                                            type: "receive",
                                            id: receive.node.receive.id,
                                            doc_number: docNumber,
                                        });

                                    }
                                } else {

                                    if (!ref_doc_check.includes(docNumber)) {
                                        ref_doc_number_list_after.push({
                                            type: "receive",
                                            id: receive.node.receive.id,
                                            doc_number: docNumber,
                                        });

                                    }
                                }


                            }
                        }
                    );
                }
            })


            this.setState({
                isModalOpen: true,
                selectedDocNumberBefore: ref_doc_number_list_before,
                selectedDocNumberAfter: ref_doc_number_list_after,
            });
        })

    }
    closeModal = () => {
        this.setState({
            isModalOpen: false,
            selectedDocNumberBefore: null,
            selectedDocNumberAfter: null,
        });
    }
    onSubmitSuccess = (response) => {
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                this.setState({ reQuery: !this.state.reQuery, selectedId: [] });
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({ reQuery: !this.state.reQuery });
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    getStatus = (status, numberOfTimes) => {
        if (status === 'ACTIVE' || status === 'DRAFT') {
            if (numberOfTimes < 4) {
                return 'ทวงถามครั้งที่' + numberOfTimes
            } else {
                return 'ดำเนินตามกฏหมาย'
            }
        } else if (status === 'PAID') {
            return 'ขำระเงินแล้ว'
        } else if (status === 'VOID') {
            return 'ยกเลิก'
        }
    }

    getClassName = (status, numberOfTimes) => {
        if (status === 'ACTIVE' || status === 'DRAFT') {
            if (numberOfTimes < 4) {
                return 'status-yellow'
            } else {
                return 'status-red'
            }
        } else if (status === 'PAID') {
            return 'status-green'
        } else if (status === 'VOID') {
            return 'status-void'
        }
    }

    uploadFile(e, id) {
        let event = _.cloneDeep(e);
        // let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files.size > 2097152) {
            Swal.fire("บันทึกไม่สำเร็จ", "กรุณาอัพโหลดไฟล์ใหม่เนื่องจากเกินขนาด 20 MB", 'error')
        } else {

            Swal.fire({
                customClass: {
                    title: 'swal2-title-custom',
                    header: 'swal2-header-custom',
                    container: 'swal2-container-custom colorUse',
                    content: 'swal2-content-custom',
                    confirmButton: 'swal2-confirmToRight btn bg1567B4-textfff h48 w200',
                    cancelButton: 'btn bg-BFBFBF h48 w200 fw-300',

                },
                imageUrl: '/images/iconAlerts/iconAlert-Blue4x.png',
                imageWidth: 122,
                html: `
                    <div class="text-center colorUse">
                        <label class="fs-18 fw-400">ใบตอบรับ</label><br/>
                        <label class="fs-18 fw-400">ยืนยันการอัพโหลดใบตอบรับ</label><br/>
                    </div>
                `, 
                showCancelButton: true,
                confirmButtonText: i18next.t("Allaction:yes"),
                cancelButtonText: i18next.t("Allaction:cancel"),
            }).then((result) => {
                if (result.value) {
                    let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                    let fileOldList = _.cloneDeep(this.state.file);
                    this.callUpload('', new_file).then((data) => {
                        let dese = "collectionLawFirmList/" + new_file.name
                        let get_index = _.findIndex(fileOldList, { 'fileId': event.target.dataset.id })
                        if (get_index >= 0) {
                            fileOldList[get_index] = {
                                fileId: event.target.dataset.id, fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location
                            }
                        } else {
                            fileOldList.push({ fileId: event.target.dataset.id, fileName: new_file.name, filePath: dese, status: "", viewPtath: data.location })
                        }
                        this.setState({ file: fileOldList })
                        let variables = {
                            "input": {
                                "jsonObject": {
                                    "Id": id,
                                    "receiptName": new_file.name,
                                    "receiptUpload": "collectionLawFirmList/" + new_file.name
                                }
                            }
                        }

                        createAndUpdateReceiptMutation(variables, this.onSuccess, this.onError)
                    });
                }

            })
        }
    }

    onSuccess = (response) => {
        if (response.ok) {
            Swal.fire('อัพโหลดเอกสารสำเร็จ', '', 'success')
            this.props.ResetreQuery()
        } else {
            Swal.fire('อัพโหลดเอกสารไม่สำเร็จ', response.massage, 'warning')
        }
    }

    onError = (err) => {
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/collectionLawFirmList" + path, file);
            resolve(res);
        });
    };

    render() {
        let variables = window.location.pathname === "/accounting/collection-law-firm/list/law" ?
            {
                "status": ["active"],
                "numberOfTimes": 4,
                "startDate": this.props.startDate,
                "endDate": this.props.endDate,
                "search": this.props.search,
                "first": this.state.first,
                "last": this.state.last,
                "reQuery": this.props.reQuery,
            } : {
                "status": this.props.status.toLowerCase(),
                "search": this.props.search,
                "first": this.state.first,
                "last": this.state.last,
                "startDate": this.props.startDate,
                "endDate": this.props.endDate,
                "reQuery": this.props.reQuery,
            }
        return (

             <QueryRenderer
                environment={environment}
                query={window.location.pathname === "/accounting/collection-law-firm/list/law" ? query : this.props.query}
                variables={variables}
                render={({ error, props }) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return (
                            <React.Fragment>

                                <ReactModal
                                    isOpen={this.state.isModalOpen}
                                    onRequestClose={this.closeModal}
                                    appElement={document.getElementById("root")}
                                    style={{
                                        content: {
                                            width: "640px",
                                            height: "720px",
                                            top: '50%',
                                            left: '50%',
                                            right: 'auto',
                                            bottom: 'auto',
                                            marginRight: '-50%',
                                            transform: 'translate(-50%, -50%)',
                                            padding: '2%'
                                        },
                                        overlay: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        }
                                    }}
                                >
                                 <Translation>
                                    {
                                    (t) => (
                                    <>
                                    <div className="row mt-2 colorUse">
                                        <div className="col">
                                            <div className="table-responsive fade-up card mt-3">
                                          
                                                <table className="table table-hover table-collection">
                                                    <thead className="thead-light headTable">
                                                        <tr className="fs-16">
                                                            <th className="text-center" colSpan={2}>{t('collectionLawFirm:Previously Debt Collection Letter')}</th>
                                                        </tr>
                                                        <tr className="fs-16">
                                                            <th className="text-center">{t('collectionLawFirm:No.')}</th>
                                                            <th className="text-center">{t('cheque:Document No.')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {_.map(this.state.selectedDocNumberBefore, (ref_doc, index) => {
                                                            return (
                                                                <tr key={"propsListTable::" + index} className="fw-300 fs-16">
                                                                    <td className="text-center">{index + 1}</td>
                                                                    <td className="text-center">
                                                                        <span key={ref_doc.id}>
                                                                            {ref_doc.type ===
                                                                                "receive_deposit" ||
                                                                                ref_doc.type ===
                                                                                "credit_note" ? (
                                                                                ref_doc.type ===
                                                                                    "receive_deposit" ? (
                                                                                    <Link
                                                                                        to={
                                                                                            "/accounting/income/receipt_deposit/view/" +
                                                                                            ref_doc.id
                                                                                        }
                                                                                    >
                                                                                        {ref_doc.doc_number}
                                                                                    </Link>
                                                                                ) : (
                                                                                    <Link
                                                                                        to={
                                                                                            "/accounting/income/credit-note/detail/" +
                                                                                            ref_doc.id
                                                                                        }
                                                                                    >
                                                                                        {ref_doc.doc_number}
                                                                                    </Link>
                                                                                )
                                                                            ) : (
                                                                                <Link
                                                                                    to={
                                                                                        "/accounting/income/receive/detail/" +
                                                                                        ref_doc.id +
                                                                                        "/" +
                                                                                        ref_doc.doc_number
                                                                                    }
                                                                                >
                                                                                    {ref_doc.doc_number}
                                                                                </Link>
                                                                            )}

                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>
                                                </table>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2 colorUse">
                                        <div className="col">
                                            <div className="table-responsive fade-up card mt-3">
                                                <table className="table table-hover table-collection">
                                                    <thead className="thead-light headTable">
                                                        <tr className="fs-16">
                                                            <th className="text-center" colSpan={2}>{t('collectionLawFirm:After Debt Collection Letter')}</th>
                                                        </tr>
                                                        <tr className="fs-16">
                                                            <th className="text-center">{t('collectionLawFirm:No.')}</th>
                                                            <th className="text-center">{t('cheque:Document No.')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {_.map(this.state.selectedDocNumberAfter, (ref_doc, index) => {
                                                            return (
                                                                <tr key={"propsListTable::" + index} className="fw-300 fs-16">
                                                                    <td className="text-center">{index + 1}</td>
                                                                    <td className="text-center">
                                                                        <span key={ref_doc.id}>
                                                                            {ref_doc.type ===
                                                                                "receive_deposit" ||
                                                                                ref_doc.type ===
                                                                                "credit_note" ? (
                                                                                ref_doc.type ===
                                                                                    "receive_deposit" ? (
                                                                                    <Link
                                                                                        to={
                                                                                            "/accounting/income/receipt_deposit/view/" +
                                                                                            ref_doc.id
                                                                                        }
                                                                                    >
                                                                                        {ref_doc.doc_number}
                                                                                    </Link>
                                                                                ) : (
                                                                                    <Link
                                                                                        to={
                                                                                            "/accounting/income/credit-note/detail/" +
                                                                                            ref_doc.id
                                                                                        }
                                                                                    >
                                                                                        {ref_doc.doc_number}
                                                                                    </Link>
                                                                                )
                                                                            ) : (
                                                                                <Link
                                                                                    to={
                                                                                        "/accounting/income/receive/detail/" +
                                                                                        ref_doc.id +
                                                                                        "/" +
                                                                                        ref_doc.doc_number
                                                                                    }
                                                                                >
                                                                                    {ref_doc.doc_number}
                                                                                </Link>
                                                                            )}

                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </>)
                                        }
                                        </Translation>
                                </ReactModal>
                                <div className="row mt-2 colorUse">
                                <Translation>
                                    {
                                    (t) => (
                                        <div className="col">
                                            <div className="table-responsive fade-up card mt-3" style={{ width: '100%' }}>
                                                <table className="table table-hover table-collection" style={{ width: 'max-content', minWidth: '-webkit-fill-available' }}>
                                                    <thead className="thead-light headTable">
                                                        <tr className="fs-16">
                                                            {this.props.status !== 'law' && <th><input type={'checkbox'} onChange={() => this.onCheckAll(props)} /></th>}
                                                            <th>{t('collectionLawFirm:No.')}</th>
                                                            <th>{t('collectionLawFirm:Document Date')}</th>
                                                            <th>{t('collectionLawFirm:Unit No.')}</th>
                                                            <th width="400">{t('collectionLawFirm:Name')}</th>

                                                            {this.props.status !== 'partial_payment' && <th className="text-right">{t('collectionLawFirm:Total Due (Baht)')}</th>}

                                                            {this.props.status === 'partial_payment' && <th className="text-right">{t('collectionLawFirm:Overdue (Baht)')}</th>}
                                                            {this.props.status === 'partial_payment' && <th className="text-right">{t('collectionLawFirm:Paid (Baht)')}</th>}
                                                            {this.props.status === 'partial_payment' && <th className="text-right">{t('collectionLawFirm:Balance (Baht)')}</th>}

                                                            <th>{t('collectionLawFirm:Status')}</th>
                                                            <th>{t('collectionLawFirm:E-Notice Document Issue Date')}</th>
                                                            <th>{t('collectionLawFirm:Creator')}</th>
                                                            <th className="text-center">{t('collectionLawFirm:Reference Receipt Document')}</th>
                                                            {this.props.status !== 'law' && <th>{t('collectionLawFirm:View Document')}</th>}
                                                            {this.props.status !== 'law' && <th  className="text-center">{t('collectionLawFirm:Document Receipt')}</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {_.map(props.allContactCollectionLawFirm?.edges || props?.allCollectionLawFirm?.edges, (item, index) => {
                                                            // const number = (index + 1);
                                                            // const desiredLength = 4;
                                                            // const formattedString = String(number).padStart(desiredLength, '0');

                                                            const formattedString = "XXXX"
                                                            let Ex_DocNumber = `CLF-${format(new Date(item?.node?.collectionLettersIssuedDate), "YYYYMM")}${formattedString}`
                                                            let checkLawStatus = window.location.pathname === "/accounting/collection-law-firm/list/law" ? true : false

                                                            let CreatedBy = !checkLawStatus ? item.node.creator : item.node.collectionLettersCreator
                                                            let CreatedDate = !checkLawStatus ? item.node.added : item?.node?.collectionLettersDateCreate ?? new Date
                                                            let Name = !checkLawStatus ? item.node.debtName : item?.node?.firstName + " " + item?.node?.lastName
                                                            let RoomNumber = !checkLawStatus ? item.node.contact.name : item?.node?.name
                                                            // let refNumber = !checkLawStatus ? item.node.contact.refNumber : item?.node?.refNumber

                                                            let sumTotal = !checkLawStatus ? item.node.totalDebt : item?.node?.collectionLettersSumTotal

                                                            // ใช้คำนวนรับชำระบางส่วน
                                                            let sumTotalPaidBeforeLegalclaim = !checkLawStatus ? item.node.collectionLawFirmRecord.edges[0]?.node.collectionLetters?.sumTotalPaidBeforeLegalclaim : 0
                                                            let sumTotalPaidAfterLegalclaim = !checkLawStatus ? item.node.collectionLawFirmRecord.edges[0]?.node.collectionLetters?.sumTotalPaidAfterLegalclaim : 0
                                                            let sumTotalPaid = sumTotalPaidBeforeLegalclaim - sumTotalPaidAfterLegalclaim
                                                            let sumTotalBalance = sumTotalPaidBeforeLegalclaim - sumTotalPaid

                                                            let receiptName = !checkLawStatus ? item.node.collectionLawFirmRecord.edges[0]?.node.receiptName : ""
                                                            let receiptUpload = !checkLawStatus ? item.node.collectionLawFirmRecord.edges[0]?.node.receiptUpload : ""
                                                            let typeFileName = ''
                                                            if (receiptName) {
                                                                typeFileName = receiptName.substring(receiptName.lastIndexOf(".") + 1, receiptName.length)
                                                            }

                                                            // let totalSum = sumTotal - sumTotalPaidAfterLegalclaim
                                                            let IssuedDate = !checkLawStatus ? item.node.issuedDate : item?.node?.collectionLettersIssuedDate ?? new Date
                                                            let Id = !checkLawStatus ? item.node.id : item?.node?.collectionLettersLastId ?? new Date

                                                            let DocPreView = !checkLawStatus ? item.node.fmcfCollectionLawFirm.edges[0]?.node.docNumber : "-"
                                                            let docNumber = !checkLawStatus ? item.node.docNumber : Ex_DocNumber
                                                            let status = window.location.pathname === "/accounting/collection-law-firm/list/law" ? "law" : item.node?.status?.toLowerCase() ?? ""
                                                            let statusText = ""
                                                            let colorBG = ""

                                                            switch (status) {
                                                                case "law":
                                                                    statusText = `${i18next.t("collectionLawFirm:Legal Pending")}`
                                                                    colorBG = "bg_FFBD2D"
                                                                    break;
                                                                case "follow":
                                                                    statusText = `${i18next.t("collectionLawFirm:Issued Notice")}`
                                                                    colorBG = "bg_678ED7"
                                                                    break;
                                                                case "overdue":
                                                                    statusText = `${i18next.t("collectionLawFirm:Due Completed")}`
                                                                    colorBG = "bg_E14558"
                                                                    break;
                                                                case "partial_payment":
                                                                    statusText = `${i18next.t("collectionLawFirm:Partially Paid")}`
                                                                    colorBG = "bg_FF922D"
                                                                    break;
                                                                case "paid":
                                                                    statusText = `${i18next.t("collectionLawFirm:Paid")}`
                                                                    colorBG = "bg_68DD6E"
                                                                    break;
                                                                case "void":
                                                                    statusText = `${i18next.t("invoiceDetail:Cancel")}`
                                                                    colorBG = "bg_0F0F0F"
                                                                    break;

                                                                default:
                                                                    break;
                                                            }
                                                            // let Ex_FW = `FM-CF-${format(new Date(IssuedDate), "YYYYMM")}${formattedString}`
                                                            return (
                                                                <tr key={"propsListTable::" + index} className="fw-300 fs-16">



                                                                    {this.props.status !== 'law' && <td>{item.node.status !== 'VOID' && <input type="checkbox" onChange={() => this.onCheck(item.node.id)} checked={this.state.selectedId.includes(item.node.id)} ></input>}</td>}
                                                                    <td>
                                                                        {
                                                                            checkLawStatus ?
                                                                                <Link to={{ pathname: "/accounting/collection_letters/detail/ACTIVE/" + Id, state: { linkBack: window.location.pathname } }}>{docNumber}</Link> :
                                                                                <Link to={{ pathname: "/accounting/collection-law-firm/detail/" + Id }}>{docNumber}</Link>
                                                                        }

                                                                    </td>
                                                                    <td>{format(new Date(IssuedDate), "DD/MM/YYYY") ?? ""}</td>
                                                                    <td>{RoomNumber ?? ""}</td>
                                                                    <td>{Name ?? ""}</td>

                                                                    {this.props.status !== 'partial_payment' && <td className="text-right">{sumTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ?? ""}</td>}

                                                                    {this.props.status === 'partial_payment' && <td className="text-right">{sumTotalPaidBeforeLegalclaim?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ?? ""}</td>}
                                                                    {this.props.status === 'partial_payment' && <td className="text-right">{sumTotalPaid?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ?? ""}</td>}
                                                                    {this.props.status === 'partial_payment' && <td className="text-right">{sumTotalBalance?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ?? ""}</td>}

                                                                    <td><StatusLawDebt
                                                                        className={`${colorBG} pb-1 h32`} status={statusText} /></td>
                                                                    <td>{format(new Date(CreatedDate), "DD/MM/YYYY") ?? ""}</td>
                                                                    <td>{CreatedBy ?? ""}</td>
                                                                    <td className="text-center">
                                                                        {/* {ref_doc_number_list_before.length > 0 || ref_doc_number_list_after.length > 0 ? (
                                                                            <button type="button" className="btn btn-primary" onClick={() => this.openModal(ref_doc_number_list_before, ref_doc_number_list_after)}>
                                                                                View Documents
                                                                            </button>
                                                                        ) : (
                                                                            "-"
                                                                        )} */}
                                                                        {
                                                                            <button type="button" className="btn btn-primary" onClick={() => this.openModal(item.node.collectionLawFirmRecord.edges[0]?.node.collectionLetters.id , item.node.issuedDate)}>
                                                                                View Documents
                                                                            </button>
                                                                        }
                                                                    </td>


                                                                    {this.props.status !== 'law' &&
                                                                        <td>
                                                                            <Link to={{
                                                                                pathname: "/accounting/collection-law-firm/printDocument/" + Id
                                                                            }}>{DocPreView}</Link>
                                                                        </td>
                                                                    }
                                                                    {
                                                                        this.props.status !== 'law' &&
                                                                        <td className="text-center">
                                                                            {
                                                                                receiptName ?
                                                                                    <p key={index}>

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                window.open(
                                                                                                    typeof receiptUpload === "string"
                                                                                                        ? receiptUpload
                                                                                                        : URL.createObjectURL(receiptUpload)
                                                                                                )
                                                                                            }}
                                                                                            target="blank">

                                                                                            {
                                                                                                typeFileName === "pdf"
                                                                                                    ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="pdf" style={{ width: '30px' }} />
                                                                                                    : typeFileName === "png"
                                                                                                        ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pngIcon.png"} alt="png" style={{ width: '30px' }} />
                                                                                                        : typeFileName === "jpeg" || typeFileName === "jpg" && <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/jpgIcon.png"} alt="jpeg" style={{ width: '30px' }} />
                                                                                            }
                                                                                        </Link>
                                                                                    </p>
                                                                                    :
                                                                                    <label className="btn btn-secondary cursor">
                                                                                        {/* {"แนบใบตอบรับ"} */}
                                                                                        {t('collectionLawFirm:Attach debt collection document')}
                                                                                        <input type="file" name='file'
                                                                                            id="uploadFile"
                                                                                            data-key="receipt"
                                                                                            data-id={index}
                                                                                            accept='.pdf,.png,.jpg'
                                                                                            onChange={(e) => this.uploadFile(e, item.node.collectionLawFirmRecord.edges[0]?.node.id)}
                                                                                            hidden
                                                                                        />
                                                                                    </label>
                                                                            }

                                                                        </td>
                                                                    }

                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <Pagination
                                                    changePage={this.changePage}
                                                    first={this.state.first}
                                                    last={this.state.last}
                                                    totalCount={props.allContactCollectionLawFirm?.totalCount || props?.allCollectionLawFirm?.totalCount || 0}
                                                />
                                            </div>
                                        </div>
                                    )} 
                                </Translation>
                                </div>
                                                
                            </React.Fragment>
                        )
                    }
                    return <Loading />
                }}
                />
        );
    }
} export default CollectionLawFirmListTable;