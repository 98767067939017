/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PeriodUnlockModes = "AUTO" | "MANUAL" | "OFF" | "%future added value";
export type PeriodUnlockRecordModes = "OFF" | "ON" | "%future added value";
export type PeriodUnlockUserModes = "ALL" | "OFF" | "USER" | "%future added value";
export type settingPeriodLockFormQueryVariables = {|
  years: string,
  search?: ?string,
|};
export type settingPeriodLockFormQueryResponse = {|
  +allJuristic: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
        +role: string,
        +image: ?string,
        +user: ?{|
          +id: string,
          +username: string,
          +lastLogin: ?any,
        |},
      |}
    |}>,
  |},
  +checkUserAdmin: ?boolean,
  +current: ?{|
    +user: ?{|
      +id: string
    |}
  |},
  +lastDateYear: ?any,
  +allPeriodUnlock: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +dateAuto: ?string,
        +modes: PeriodUnlockModes,
        +years: string,
        +periodUnlockRecord: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +modes: PeriodUnlockRecordModes,
              +startDate: ?any,
              +endDate: ?any,
              +periodDate: ?any,
              +timer: ?any,
              +periodUnlockUser: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +modes: PeriodUnlockUserModes,
                    +creator: ?string,
                    +periodUnlockRecord: {|
                      +id: string
                    |},
                    +periodUnlockUserRecord: ?{|
                      +edges: $ReadOnlyArray<?{|
                        +node: ?{|
                          +id: string,
                          +edited: boolean,
                          +juristic: {|
                            +id: string,
                            +firstName: string,
                            +lastName: string,
                            +image: ?string,
                            +role: string,
                            +user: ?{|
                              +id: string,
                              +username: string,
                              +lastLogin: ?any,
                            |},
                          |},
                        |}
                      |}>
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
|};
export type settingPeriodLockFormQuery = {|
  variables: settingPeriodLockFormQueryVariables,
  response: settingPeriodLockFormQueryResponse,
|};
*/


/*
query settingPeriodLockFormQuery(
  $years: String!
  $search: String
) {
  allJuristic(search: $search) {
    totalCount
    edges {
      node {
        id
        firstName
        lastName
        role
        image
        user {
          id
          username
          lastLogin
        }
      }
    }
  }
  checkUserAdmin
  current {
    user {
      id
    }
    id
  }
  lastDateYear(years: $years)
  allPeriodUnlock(years: $years) {
    edges {
      node {
        id
        dateAuto
        modes
        years
        periodUnlockRecord {
          edges {
            node {
              id
              modes
              startDate
              endDate
              periodDate
              timer
              periodUnlockUser {
                edges {
                  node {
                    id
                    modes
                    creator
                    periodUnlockRecord {
                      id
                    }
                    periodUnlockUserRecord {
                      edges {
                        node {
                          id
                          edited
                          juristic {
                            id
                            firstName
                            lastName
                            image
                            role
                            user {
                              id
                              username
                              lastLogin
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "years"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastLogin",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    }
  ],
  "concreteType": "JuristicNodeConnection",
  "kind": "LinkedField",
  "name": "allJuristic",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JuristicNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkUserAdmin",
  "storageKey": null
},
v10 = [
  (v2/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "years",
    "variableName": "years"
  }
],
v13 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "lastDateYear",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modes",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": (v12/*: any*/),
  "concreteType": "PeriodUnlockNodeConnection",
  "kind": "LinkedField",
  "name": "allPeriodUnlock",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodUnlockNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PeriodUnlockNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateAuto",
              "storageKey": null
            },
            (v14/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "years",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PeriodUnlockRecordNodeConnection",
              "kind": "LinkedField",
              "name": "periodUnlockRecord",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PeriodUnlockRecordNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PeriodUnlockRecordNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v14/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "periodDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "timer",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PeriodUnlockUserNodeConnection",
                          "kind": "LinkedField",
                          "name": "periodUnlockUser",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PeriodUnlockUserNodeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "PeriodUnlockUserNode",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v2/*: any*/),
                                    (v14/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "creator",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "PeriodUnlockRecordNode",
                                      "kind": "LinkedField",
                                      "name": "periodUnlockRecord",
                                      "plural": false,
                                      "selections": (v10/*: any*/),
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "PeriodUnlockUserRecordNodeConnection",
                                      "kind": "LinkedField",
                                      "name": "periodUnlockUserRecord",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "PeriodUnlockUserRecordNodeEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "PeriodUnlockUserRecordNode",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v2/*: any*/),
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "edited",
                                                  "storageKey": null
                                                },
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "concreteType": "JuristicNode",
                                                  "kind": "LinkedField",
                                                  "name": "juristic",
                                                  "plural": false,
                                                  "selections": [
                                                    (v2/*: any*/),
                                                    (v3/*: any*/),
                                                    (v4/*: any*/),
                                                    (v6/*: any*/),
                                                    (v5/*: any*/),
                                                    (v7/*: any*/)
                                                  ],
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingPeriodLockFormQuery",
    "selections": [
      (v8/*: any*/),
      (v9/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNode",
        "kind": "LinkedField",
        "name": "current",
        "plural": false,
        "selections": [
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      (v13/*: any*/),
      (v15/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "settingPeriodLockFormQuery",
    "selections": [
      (v8/*: any*/),
      (v9/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicNode",
        "kind": "LinkedField",
        "name": "current",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v13/*: any*/),
      (v15/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a9f74ad21abfdb81a817b3d480f9c083",
    "id": null,
    "metadata": {},
    "name": "settingPeriodLockFormQuery",
    "operationKind": "query",
    "text": "query settingPeriodLockFormQuery(\n  $years: String!\n  $search: String\n) {\n  allJuristic(search: $search) {\n    totalCount\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        role\n        image\n        user {\n          id\n          username\n          lastLogin\n        }\n      }\n    }\n  }\n  checkUserAdmin\n  current {\n    user {\n      id\n    }\n    id\n  }\n  lastDateYear(years: $years)\n  allPeriodUnlock(years: $years) {\n    edges {\n      node {\n        id\n        dateAuto\n        modes\n        years\n        periodUnlockRecord {\n          edges {\n            node {\n              id\n              modes\n              startDate\n              endDate\n              periodDate\n              timer\n              periodUnlockUser {\n                edges {\n                  node {\n                    id\n                    modes\n                    creator\n                    periodUnlockRecord {\n                      id\n                    }\n                    periodUnlockUserRecord {\n                      edges {\n                        node {\n                          id\n                          edited\n                          juristic {\n                            id\n                            firstName\n                            lastName\n                            image\n                            role\n                            user {\n                              id\n                              username\n                              lastLogin\n                            }\n                          }\n                        }\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5f153090e4aa54426eb79f0fff922a70';

module.exports = node;
