/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDebitManualInput = {|
  deleteDebitManual: any,
  clientMutationId?: ?string,
|};
export type deleteInvoiceMutationVariables = {|
  input: DeleteDebitManualInput
|};
export type deleteInvoiceMutationResponse = {|
  +deleteDebitManual: ?{|
    +ok: ?boolean,
    +message: ?string,
  |}
|};
export type deleteInvoiceMutation = {|
  variables: deleteInvoiceMutationVariables,
  response: deleteInvoiceMutationResponse,
|};
*/


/*
mutation deleteInvoiceMutation(
  $input: DeleteDebitManualInput!
) {
  deleteDebitManual(input: $input) {
    ok
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteDebitManualPayload",
    "kind": "LinkedField",
    "name": "deleteDebitManual",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteInvoiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteInvoiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "70d276c7ec18274bfbfebcc74980b35d",
    "id": null,
    "metadata": {},
    "name": "deleteInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation deleteInvoiceMutation(\n  $input: DeleteDebitManualInput!\n) {\n  deleteDebitManual(input: $input) {\n    ok\n    message\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b67001e7b92eb551f68f74f5c99a634';

module.exports = node;
