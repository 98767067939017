import "../../accounting/budget/styles/createBudget.scss";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, commitMutation } from "relay-runtime";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import { addDays, format, addMonths, setMinutes, setHours, getYear, getHours, getMinutes, getMonth, setYear } from "date-fns";
// import "./settingAccountForm.scss"F
import _ from "lodash";
import { BL2, CButton, H4, H5, HS5, Input, LButton, Line } from '../../components/Text/Text';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SettingPeriodLockAutoTable from "./settingPeriodLockAutoTable";
import { ButtonYear } from "./DateCustom";
import SettingPeriodLockManualTable from "./settingPeriodLockManualTable";
import PeriodLockModal from './periodLockModal'
import ModalPeriodLock from "./ModalPeriodLock";
import updatePeriodUnlockUser from "./mutations/updatePeriodUnlockUser";
import i18next from "i18next";


const query = graphql`
    query settingPeriodLockFormQuery( $years : String!,$search: String) {
        allJuristic(search: $search) {
            totalCount
            edges {
              node {
                id
                firstName
                lastName
                role
                image
                user {
                  id
                  username
                  lastLogin
                }
              }
            }
          }
          checkUserAdmin
          current {
            user {
              id
            }
          }
        lastDateYear(years : $years)
        allPeriodUnlock(years : $years){
            edges{
                node{
                    id
                    dateAuto
                    modes
                    years
                    periodUnlockRecord{
                        edges{
                            node{
                                id
                                modes
                                startDate
                                endDate
                                periodDate
                                timer
    
                                periodUnlockUser{
                                    edges{
                                        node{
                                            id
                                            modes
                                            creator
                                            periodUnlockRecord{
                                                id
                                            }
                                            periodUnlockUserRecord{
                                                edges{
                                                    node{
                                                        id
                                                        edited
                                                        juristic{
                                                            id
                                                            firstName
                                                            lastName
                                                            image
                                                            role
                                                            user {
                                                                id
                                                                username
                                                                lastLogin
                                                              }
                                                        }
                                                    }
                                                }
                                            }
    
                                        }
                                    }
                                }
    
                            }
                        }
                    }
                }
            }
        }
    }
    `;

const mutation = graphql`
    mutation settingPeriodLockFormMutation($input: CreateUpdatePeriodUnlockInput!) {
        createUpdatePeriodUnlock(input: $input) {
            ok
        }
    }
`;

const list_default = [
    { node: { no: 1, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 2, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 3, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 4, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 5, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 6, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 7, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 8, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 9, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 10, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 11, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
    { node: { no: 12, period_closing: true, startDate: '', endDate: '', periodDate: '', timer: '', modes: 'off' } },
]

class SettingPeriodLockForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            redirectToList: false,
            start_date: new Date(),
            start_date_account: new Date(),
            start_date_account_period: new Date(),
            end_date_account_period: new Date(),
            year: new Date(),
            lists: '',
            period_lock_date: '16',
            select_date: new Date().setFullYear(2021, 0, 1),
            status: 'auto',
            save_status: false,
            new: true,
            modal: false,
            no_data: true,
            item_select: {},
            item_select_index: 0,
            modes: '',
            allJuristic: [],
            checkUserAdmin: '',
            current: {},
            modal_confirm: false,
            selectedOption: []

        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    getData = async () => {
        this.setState({ loading: true })
        await fetchQuery(environment, query, { years: format(this.state.year, 'YYYY') }).then(data => {
            if (data) {
                let allJuristic = []
                _.get(data, 'allJuristic.edges').forEach((element, index) => {
                    allJuristic.push({
                        value: element.node.id,
                        text: `${element.node.firstName} ${element.node.lastName}`,
                        image: element.node.image || '',
                        role: element.node.role,
                        user: element.node.user,
                    })
                });
                if ((_.get(data, 'allPeriodUnlock.edges[0].node.modes') || 'auto').toLocaleLowerCase() === 'manual') {
                    let radiobtn = document.getElementById("status_manual");
                    radiobtn.checked = 'manual';
                } else {
                    let radiobtn = document.getElementById("status_auto");
                    radiobtn.checked = 'auto';
                }

                this.setState({
                    lists: _.get(data, 'allPeriodUnlock.edges[0].node.periodUnlockRecord.edges') || _.cloneDeep(list_default),
                    years: _.get(data, 'allPeriodUnlock.edges[0].node.years'),
                    date_auto: _.get(data, 'allPeriodUnlock.edges[0].node.dateAuto'),
                    period_lock_date: _.get(data, 'allPeriodUnlock.edges[0].node.dateAuto') || '16',
                    modes: _.get(data, 'allPeriodUnlock.edges[0].node.modes') || '',
                    id: _.get(data, 'allPeriodUnlock.edges[0].node.id'),
                    loading: false,
                    status: _.get(data, 'allPeriodUnlock.edges[0].node.modes') || 'auto',
                    new: _.get(data, 'allPeriodUnlock.edges[0].node.periodUnlockRecord.edges') ? false : true,
                    save_status: data.allPeriodUnlock.edges.length > 0 ? true : false,
                    allJuristic: allJuristic,
                    checkUserAdmin: _.get(data, 'checkUserAdmin'),
                    current: _.get(data, 'current'),
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.year !== this.state.year) { this.getData() }
    }

    componentWillMount() {
        this.getData()
    }

    handleInputChange(e) {
        let { name, value } = e.target
        const re = /^[0-9\b]+$/;
        if (name === 'period_lock_date') {
            if (e.target.value === '' || re.test(e.target.value)) {
                this.setState({ [name]: value });
            }
        } else {
            this.setState({ [name]: value });
        }
    }

    autoCal = () => {
        let { lists, select_date, year, period_lock_date, status } = this.state
        if (period_lock_date && select_date && year) {
            let data = _.cloneDeep(lists)
            const start_date = setYear(select_date, getYear(year))
            const periodDate = new Date().setFullYear(getYear(year), getMonth(start_date), period_lock_date)
            data.map((data, index) => {
                let date1 = new Date(addMonths(periodDate, index + 1))
                let date2 = new Date()
                data.node.modes = status === 'auto' ? date2 >= date1 ? 'on' : 'off' : 'off'
                data.node.startDate = addMonths(start_date, index)
                data.node.endDate = addMonths(addDays(start_date, 30), index)
                data.node.periodDate = status === 'auto' ? addMonths(periodDate, index + 1) : ''
                data.node.timer = new Date()
            })
            this.setState({ lists: data, select_date: start_date, no_data: false })
        } else {
            alert('กรอกข้อมูลให้ครบ')
        }
    }

    handleTime = (timer) => {
        let { lists } = this.state
        let data = _.cloneDeep(lists)
        data.forEach((data, index) => data.node.timer = setMinutes(setHours(new Date(), getHours(timer)), getMinutes(timer)))
        this.setState({ lists: data })
    }

    handleTimeManual = (key, date) => {
        this.setState({ lists: _.set(_.cloneDeep(this.state.lists), key, setMinutes(setHours(new Date(), getHours(date)), getMinutes(date))) })
    }

    handleDate = (date) => {
        this.setState({ select_date: date })
    }

    handleDateManual = (key, date) => {
        this.setState({ lists: _.set(_.cloneDeep(this.state.lists), key, date) })
    }

    handleSubmit = async () => {
        let validate = true
        var BreakException = {};
        // try {
        //     this.state.lists.forEach(element => {
        //         if (element.node.startDate === '' ||
        //             element.node.endDate === '' ||
        //             element.node.periodDate === '' ||
        //             element.node.timer === '' ||
        //             element.node.modes === ''
        //         ) {
        //             validate = false
        //             throw BreakException
        //         }
        //     })
        // } catch (e) {
        //     if (e !== BreakException) throw e;
        // }

        try {
            this.state.lists.forEach(element => {
                if (element.node.startDate === '' ||
                    element.node.endDate === '' ||
                    element.node.timer === '' ||
                    element.node.modes === ''
                ) {
                    validate = false
                    throw BreakException
                }
            })
        } catch (e) {
            if (e !== BreakException) throw e;
        }

        if (this.state.status && this.state.period_lock_date && this.state.year && validate) {
            this.setState({ save_status: true })
            let periodUnlockRecord = []
            this.state.lists.forEach(element => {
                periodUnlockRecord.push({
                    id:element.node.id || '',
                    modes: element.node.modes,
                    start_date: element.node.startDate,
                    end_date: element.node.endDate,
                    period_date: element.node.periodDate,
                    timer: element.node.timer,
                    remark: ''
                })
            });

            let variables = {
                input: {
                    periodUnlock: {
                        modes: this.state.status,
                        date_auto: this.state.period_lock_date,
                        years: format(this.state.year, 'YYYY'),
                        periodUnlockRecord: periodUnlockRecord
                    }
                }
            };
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        this.setState({ loading: false });
                        if (response.createUpdatePeriodUnlock.ok) {
                            Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                                this.setState({ new: false });
                                this.getData()
                            });
                        } else {
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({ loading: false });
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                    },
                },
            )
        } else {
            alert('กรอกข้อมูลให้ครบ')
        }
    }
    handleModal = (key) => {
        this.setState({ modal: !this.state.modal, item_select_index: key })
    }



    handleModalSubmit = () => {
        if (_.get(_.cloneDeep(this.state.lists), `[${this.state.item_select_index}].node.modes`).toLocaleLowerCase() !== 'off' && !this.state.new) {
            let data = []
            _.get(_.cloneDeep(this.state.lists), `[${this.state.item_select_index}].node.periodUnlockUser.edges[0].node.periodUnlockUserRecord.edges`).forEach(element => {
                data.push({
                    value: element.node.juristic.id,
                    text: `${element.node.juristic.firstName} ${element.node.juristic.lastName}`,
                    image: element.node.juristic.image,
                    role: element.node.juristic.role,
                    user: element.node.juristic.user,
                })
            });
            this.setState({ modal: false, selectedOption: data }, () => {
                this.setState({ modal_confirm: true })
            })
        } else {
            this.updatePeriodUnlock()
            this.setState({ modal: false })
        }
    }

    updatePeriodUnlock = async () => {
        if (!this.state.new) {
            let item_select = _.get(_.cloneDeep(this.state.lists), `[${this.state.item_select_index}].node.periodUnlockUser.edges[0].node`)
            let data = _.get(_.cloneDeep(this.state.lists), `[${this.state.item_select_index}].node`)
            let periodUnlockUserRecord = []
            await item_select.periodUnlockUserRecord.edges.forEach(element => {
                periodUnlockUserRecord.push({
                    juristicID: element.node.juristic.id,
                    delete: false
                })
            });

            this.state.selectedOption.forEach((element) => {
                periodUnlockUserRecord.push({
                    juristicID: element.value,
                    delete: false
                })
            })
            let variables = {
                input: {
                    updatePeriodUnlock:
                    {
                        id: item_select.periodUnlockRecord.id,
                        mods: item_select.modes.toLocaleLowerCase(),
                        stautsUnlock: data.modes.toLocaleLowerCase() === 'on' ? 'off' : 'on',
                        periodUnlockUserRecord: periodUnlockUserRecord
                    }
                }
            }
            updatePeriodUnlockUser(variables, (res) => {
                this.setState({
                    lists: _.set(_.cloneDeep(this.state.lists), `[${this.state.item_select_index}].node.modes`,
                        _.get(_.cloneDeep(this.state.lists), `[${this.state.item_select_index}].node.modes`).toLocaleLowerCase() === 'on' ? 'off' : 'on'), modal: false,
                    modal_confirm: false
                }, () => this.getData())
            }, (err) => {
                console.log('err ', err);
                this.setState({ modal_confirm: false })
            })
        } else {
            let lists = _.set(_.cloneDeep(this.state.lists),
                `[${this.state.item_select_index}].node.modes`,
                _.get(_.cloneDeep(this.state.lists),
                    `[${this.state.item_select_index}].node.modes`) === 'off' ? 'on' : 'off')
            this.setState({ lists })
        }
    }

    handleChange = e => {
        this.setState({ selectedOption: e })
    }

    handleRemoveTextSelect = async (textRemove) => {
        this.setState({ selectedOption: _.filter(this.state.selectedOption, (o) => o.value !== textRemove) })
    }

    handleChangeCheckbox = (data) => {
        let { lists, item_select_index } = this.state
        this.setState({
            lists: _.set(_.cloneDeep(lists), `[${item_select_index}].node.periodUnlockUser.edges[0].node.modes`,
                _.get(_.cloneDeep(lists), `[${item_select_index}].node.periodUnlockUser.edges[0].node`).modes.toLocaleLowerCase() === 'all' ? 'user' : 'all')
        })
    }

    closeModal = (name) => {
        this.setState({ [name]: false })
    }

    render() {
        let { status, modes, modal, lists, item_select_index, save_status, period_lock_date, year } = this.state
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <ModalPeriodLock state={this.state} modal={this.state.modal_confirm}
                        closeModal={() => this.closeModal('modal_confirm')} updatePeriodUnlock={this.updatePeriodUnlock}
                        handleRemoveTextSelect={this.handleRemoveTextSelect} handleChange={this.handleChange}
                        periodUnlockRecord={_.get(_.cloneDeep(this.state.lists), `[${this.state.item_select_index}].node.periodUnlockUser.edges[0].node`)}
                        handleChangeCheckbox={this.handleChangeCheckbox}
                    />
                    <div className="container-fluid box pl-9 pr-9" id='periodLook' >
                        <PeriodLockModal modal={modal} handleModalSubmit={this.handleModalSubmit} closeModal={() => this.closeModal('modal')}
                            status={lists && _.get(_.cloneDeep(lists), `[${item_select_index}].node.modes`).toLocaleLowerCase() === 'on' ? true : false}
                        />
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/setting"><img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'} alt="back" className="back" /></Link>
                                    <Translation>{t => <span>{t("setting:set period lock")}</span>}</Translation>
                                </h3>
                            </div>
                        </div>
                        
                        {/* <Translation>
                            {t => */}
                        <div className="mt-6" >
                        <Translation>{t => <H4>{t("setting:set period lock")}</H4>}</Translation>
                            <div className="d-flex mt-5"  >
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="status" id="status_auto" value="auto"
                                        defaultChecked={status.toLocaleLowerCase() === 'auto'} onChange={this.handleInputChange}
                                        disabled={modes.toLocaleLowerCase() === 'manual'} 
                                        />
                                    <BL2 className="form-check-label" htmlFor="status_auto">Auto</BL2>
                                </div>
                                <div className="form-check " style={{ marginLeft: 72 }} >
                                    <input className="form-check-input" type="radio" name="status" id="status_manual" value="manual"
                                        defaultChecked={status.toLocaleLowerCase() === 'manual'} onChange={this.handleInputChange} 
                                        disabled={modes.toLocaleLowerCase() === 'auto'} 
                                        />
                                    <BL2 className="form-check-label" htmlFor="status_manual">Manual</BL2>
                                </div>
                            </div>
                            <Line className='mt-3' />
                            {status.toLocaleLowerCase() === 'auto' && <div>
                            <Translation>{t =>  <H5 className="mt-9">{t("settingPeriodLock:Period lock date")}</H5>}</Translation>
                                <div className="d-flex align-items-center">
                                    <Translation>{t =>  <h6>{t("settingPeriodLock:Set your period lock date since")}</h6>}</Translation>
                                    <Input
                                        disabled={save_status}
                                        className="ml-12" height='48px'
                                        maxWidth='80px' value={period_lock_date}
                                        onChange={this.handleInputChange} name='period_lock_date'
                                        background={save_status && '#e9ecef'}
                                        maxLength={2}
                                    />
                                </div>
                            </div>}
                            <div className="d-flex align-items-center mt-6 justify-items-center">
                            <Translation>{t =>  <h5>{t("settingPeriodLock:Year")} :</h5>}</Translation>
                                <div className="col-2 customDatePickerWidth">
                                    <DatePicker
                                        className="form-control float-left colorborder"
                                        selected={year}
                                        onChange={(date) => this.setState({ year: date })}
                                        showYearPicker
                                        locale="th-TH"
                                        dateFormat="yyyy"
                                        customInput={<ButtonYear date />}
                                        value={year}
                                    />
                                </div>
                                <div className="col-2 ">
                                    <CButton disabled={save_status} onClick={this.autoCal} background={save_status && '#e9ecef'}>
                                        <img src={process.env.PUBLIC_URL + "/images/icons/periodLock/icon_calendar.png"} alt="calendars" style={{ width: 31, height: 31 }} />
                                    </CButton>
                                </div>
                            </div>
                        </div>
                         {/* } </Translation> */}
                        {status.toLocaleLowerCase() === 'auto' ?
                            <SettingPeriodLockAutoTable handleModal={this.handleModal} state={this.state} handleTime={this.handleTime} handleDate={this.handleDate} handleDateManual={this.handleDateManual} handleTimeManual={this.handleTimeManual} />
                            :
                            <SettingPeriodLockManualTable handleModal={this.handleModal} state={this.state} handleDate={this.handleDate} handleDateManual={this.handleDateManual} handleTimeManual={this.handleTimeManual} />}

                        <div className='col-12 mt-16' style={{ marginTop: 64 }} >
                            <div className='row justify-content-between' >
                                <div className='col'>
                                    <div className='row'>
                                    <Translation>{t =>  <h5>{t("settingPeriodLock:Remark")} :</h5>}</Translation>
                                        <div className="ml-12" >
                                            <div>
                                                <img src={process.env.PUBLIC_URL + '/images/icons/periodLock/icon_lock.png'} style={{ width: 19.6, height: 22.4 }} />
                                                <Translation>{t =>  <HS5>{t("settingPeriodLock:Closed period")}</HS5>}</Translation>
                                            </div>
                                            <div className='mt-5' >
                                                <img src={process.env.PUBLIC_URL + '/images/icons/periodLock/icon_unlock.png'} style={{ width: 19.6, height: 22.4 }} />
                                                <Translation>{t =>  <HS5>{t("settingPeriodLock:Not close period")}</HS5>}</Translation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {(!save_status || status === "MANUAL") && <LButton minWidth={100} onClick={this.handleSubmit} disabled={ status === "MANUAL" ? false : save_status || this.state.no_data} ><Translation>{t => <span>{t("settingPeriodLock:Save")}</span>}</Translation></LButton>}
                                </div>
                            </div>
                        </div>
                    </div>

                </WrapperContent>
            </Wrapper>
        )
    }
}



export default SettingPeriodLockForm;
