import React from "react";
import getApiRoot from "../../../../libs/getAPIRoot";
import Helpers from '../../../../libs/file_download_helper';
import {format} from "date-fns";


class ExportReceive extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event) => {
        this.props.checkLoadingExcel(false)
        this.setState({
            errors: null,
            loading: true,
        }, () => {
            Helpers.httpRequest(
                getApiRoot() + 'export/receive?start_date='
                + format(this.props.state.start_date, 'DD/MM/YYYY')
                + '&end_date=' + format(this.props.state.end_date, 'DD/MM/YYYY')
                + '&order=doc_number'
                + '&status=paid'
                + '&customer_type=' + this.props.state.customer_type
                + '&payment_channel=' + this.props.state.payment_channel
                + '&payment_gateway_method_type=' + this.props.state.payment_gateway_method_type
                + '&contact=' + this.props.state.contact
                + '&search=' + this.props.state.search
                + '&product_and_service=' + this.props.state.product_and_service,
                'GET',
            )
                .then((response) => response.blob())
                .then((blob) => {
                    // create blob link
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "receive.xls");

                    // append to html
                    document.body.appendChild(link);

                    // download
                    link.click();

                    // remove
                    link.parentNode.removeChild(link);

                    this.setState({
                        loading: false
                    }, ()=> this.props.checkLoadingExcel(true));
                })
                .catch((error) => {
                    error.json().then((json) => {
                        this.setState({
                            errors: json,
                            loading: false
                        });
                    })
                });
        });

        event.preventDefault();
    };

    render() {

        return (
            <a className="dropdown-item" target={"_blank"} onClick={this.handleSubmit}>Excel</a>
        )
    }
}

export default ExportReceive;
