import React, {Component} from 'react';
import {commitMutation, QueryRenderer} from 'react-relay';
import environment from "../../../env/environment";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Wrapper from "../../../components/wrapper";
import {graphql} from "babel-plugin-relay/macro";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from "i18next"
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import SearchSelect from "../../../libs/searchSelect";
import {parse} from "date-fns";
import Swal from "sweetalert2";
import Redirect from "react-router-dom/es/Redirect";
import _ from "lodash";
import { convertToTimeZone } from '../../../libs/date';
import Loading from "../../../libs/loading";

const query = graphql`
    query creditCardDetailQuery($id: ID!) {
        creditCardDepositGroup(id: $id) {
            docNumber
            depositDate
            bank {
                id
            }
            image
            depositTotal
            feeTotal
            description
            creditCardTransaction {
                edges {
                    node {
                        id
                        receive {
                            docNumber
                            issuedDate
                        }
                        otherReceive{
                            docNumber
                            issuedDate
                        }
                        receiptDeposit{
                            docNumber
                            issuedDate
                        }
                        description
                        amount
                    }
                }
            }
        }
        bankAccountViewer{
            allBankAccount(status: true){
                edges{
                    node{
                        id
                        accountNumber
                        accountName
                        bankName

                    }
                }
            }
        }
        selfProject {
            timeZone
        }
    }
`;

const mutation = graphql`
    mutation creditCardDetailMutation($input: CreditCardDepositGroupDeleteInput!){
        creditCardDepositGroupDelete(input: $input){
            clientMutationId
            ok
            warningText
        }
    }
`;

class CreditCardDetail extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            redirect: false,
        };
        this.deleteGroup = this.deleteGroup.bind(this);
    }

    deleteGroup() {
        this.setState({loading: true});

        Swal.fire({
            title: i18n.t("credit_card:Are you sure to cancel credit card deposits?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18n.t("credit_card:Cancel"),
            confirmButtonText: i18n.t("credit_card:Yes")
        }).then((result) => {
            if (result.value) {

                let variables = {
                    input: {
                        id: this.props.match.params.id,
                    }
                };

                commitMutation(
                    environment,
                    {
                        mutation,
                        variables,
                        onCompleted: (response) => {
                            if (response.creditCardDepositGroupDelete.ok) {
                                Swal.fire(
                                    i18n.t("credit_card:Success"),
                                    i18n.t("credit_card:Cancel credit card deposits"),
                                    'success'
                                ).then(() => {
                                    this.setState({redirect: true, loading: false});
                                });
                            } else {
                                // Swal.fire(i18n.t("credit_card:Failed to cancel"), i18n.t("credit_card:Please_try_again"), 'warning')
                                Swal.fire(i18n.t("credit_card:Failed to cancel"), response.creditCardDepositGroupDelete.warningText, 'warning')
                                this.setState({loading: false});
                            }
                        },
                        onError: (err) => {
                            Swal.fire('Error!', i18n.t("credit_card:Please try again"), 'warning');
                            this.setState({loading: false});
                        },
                    },
                )

            } else {
                this.setState({loading: false});
            }
        })
    }


    render() {

         if (this.state.redirect) {
            return <Redirect to="/accounting/finance/credit-card/list"/>
        }

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid box">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/finance/credit-card/list">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('credit_card:credit_card')+" "+"(CL)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col text-right">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_credit_card_delete'}) &&
                                <Translation>
                                    {t=>
                                    <button className="btn btn-danger add mr-3"
                                            disabled={this.state.loading}
                                            onClick={this.deleteGroup}>
                                        {this.state.loading && <span
                                            className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                            {t("credit_card:Cancel credit card deposits")}
                                    </button>}
                                </Translation>
                                }
                            </div>
                        </div>
                        <div className="content-inner">
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                variables={{id: this.props.match.params.id}}
                                render={({error, props}) => {
                                    if (error) {
                                        return <div>{error.message}</div>;
                                    } else if (props) {
                                        return <React.Fragment>

                                            <div className="table-responsive fade-up">
                                                <table className="table table-hover mt-2">
                                                    <thead className="thead-light">
                                                    <Translation>
                                                        {t=>
                                                        <tr>
                                                            <th>{t("credit_card:No_deposit")}</th>
                                                            <th>{t("credit_card:Date")}</th>
                                                            <th>{t("credit_card:Description")}</th>
                                                            <th className="text-right">{t("credit_card:Amount")}</th>
                                                        </tr>}
                                                    </Translation>
                                                    </thead>
                                                    <tbody>
                                                    {props.creditCardDepositGroup.creditCardTransaction.edges.map((transaction) => {
                                                        let docNumber = '';
                                                        let issuedDate = '';

                                                        if (transaction.node.receive) {
                                                            docNumber = transaction.node.receive.docNumber;
                                                            issuedDate = transaction.node.receive.issuedDate
                                                        } else if (transaction.node.invoice) {
                                                            docNumber = transaction.node.invoice.docNumber;
                                                            issuedDate = transaction.node.invoice.issuedDate
                                                        } else if (transaction.node.receiptDeposit) {
                                                            docNumber = transaction.node.receiptDeposit.docNumber;
                                                            issuedDate = transaction.node.receiptDeposit.issuedDate
                                                        } else {
                                                            docNumber = transaction.node.otherReceive.docNumber;
                                                            issuedDate = transaction.node.otherReceive.issuedDate
                                                        }

                                                        return (
                                                            <tr key={transaction.node.id}>
                                                                <td>
                                                                    {docNumber}
                                                                </td>
                                                                <td>{format(issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                                <td>{transaction.node.description}</td>
                                                                <td className="text-right">{numberWithComma(transaction.node.amount)} บาท</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row mt-3 fade-up">
                                                <div className="col-9"/>
                                                <div className="col-3">
                                                    <div className="col">
                                                        <div className="row p-3 mb-2 bg-light text-dark">
                                                            <Translation>{t=><div className="col text-left"><strong>{t("credit_card:All amount")}</strong></div>}</Translation>
                                                            <div
                                                                className="col text-right"><Translation>{t=><strong>{numberWithComma(props.creditCardDepositGroup.depositTotal + props.creditCardDepositGroup.feeTotal)} {t("credit_card:Baht")}</strong>}</Translation>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-3 fade-up">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <Translation>{t=><label className="col-2 col-form-label">{t("credit_card:No.")}</label>}</Translation>
                                                        <label className="col-2 col-form-label">{props.creditCardDepositGroup.docNumber}</label>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <Translation>{t=><label className="col-2 col-form-label">{t("credit_card:Description")}</label>}</Translation>
                                                        <div className="col-5">
                                                            <textarea className="form-control" rows="4"
                                                                      value={props.creditCardDepositGroup.description}
                                                                      disabled={true}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <Translation>{t=><label className="col-2 col-form-label">{t("credit_card:Deposit date")}</label>}</Translation>
                                                        <div className="col">
                                                            <DatePickerAdapter
                                                                name="depositDate" className="form-control"
                                                                selected={parse(convertToTimeZone(props.creditCardDepositGroup.depositDate, props.selfProject.timeZone))}
                                                                disable={true}
                                                            />

                                                        </div>
                                                    </div>


                                                    <div className="row mt-3">
                                                        <Translation>{t=><label className="col-2 col-form-label">{t("credit_card:Deposit method")}</label>}</Translation>
                                                        <div className="col-3">
                                                            <SearchSelect value={props.creditCardDepositGroup.bank.id}
                                                                          name="bank_select"
                                                                          placeholder="กรุณาทำการตั้งค่าบัตรเครดิต"
                                                                          queryObject={props.bankAccountViewer.allBankAccount.edges}
                                                                          keyOfValue="id"
                                                                          keyOfLabel="bankName:accountName:accountNumber"
                                                                          disabled={true}

                                                            />

                                                        </div>
                                                        <Translation>
                                                            {t=><label
                                                                className="col-2 col-form-label ml-3 text-center">{t("credit_card:Amount")}</label>}
                                                        </Translation>
                                                        <div className="col-2">
                                                            <input type="number" className="form-control text-right"
                                                                   step="0.01"
                                                                   name="depositTotal"
                                                                   value={props.creditCardDepositGroup.depositTotal}
                                                                   disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <Translation>{t=><label className="col-2 col-form-label">{t("credit_card:Bank fee")}</label>}</Translation>
                                                        <div className="col-3">
                                                            <input type="text" className="form-control text-center"
                                                                   name="depositTotal"
                                                                   value="ค่าธรรมเนียมธนาคาร"
                                                                   disabled={true}
                                                            />
                                                        </div>
                                                        <Translation>
                                                            {t=><label
                                                                className="col-2 col-form-label ml-3 text-center">{t("credit_card:Amount")}</label>}
                                                        </Translation>
                                                        <div className="col-2">
                                                            <input type="number" className="form-control text-right"
                                                                   step="0.01"
                                                                   name="feeTotal"
                                                                   value={props.creditCardDepositGroup.feeTotal}
                                                                   disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-5">
                                                        <div className="col">
                                                            <img
                                                                src={props.creditCardDepositGroup.image}
                                                                alt=""/>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    }
                                    return <Loading/>
                                }}
                            />
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default CreditCardDetail;
