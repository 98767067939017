/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfCompany = "BRANCH" | "HEADQUARTERS" | "NOBRANCH" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type GuaranteeMoneyReceivedStatus = "COMPLETED" | "FORFEIT_ALL" | "IN_PROCESS" | "RETURN_FORFEIT" | "VOID" | "%future added value";
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type wrapperGuaranteeMoneyReceivedQueryVariables = {|
  id: string
|};
export type wrapperGuaranteeMoneyReceivedQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +nameEn: ?string,
    +address: ?string,
    +addressEn: ?string,
    +logo: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +taxIdNumber: ?string,
    +typeOfProject: ?ProjectTypeOfProject,
  |},
  +guaranteeMoneyReceived: ?{|
    +id: string,
    +docNumber: string,
    +issuedDate: any,
    +status: GuaranteeMoneyReceivedStatus,
    +amount: ?number,
    +firstName: string,
    +lastName: string,
    +address: string,
    +district: string,
    +city: string,
    +province: string,
    +postalCode: string,
    +name: string,
    +taxIdNumber: ?string,
    +email: ?string,
    +phone: string,
    +note: ?string,
    +chartOfAccount: ?{|
      +id: string,
      +chartOfAccountCode: string,
      +name: string,
    |},
    +description: string,
    +contact: {|
      +refNumber: string,
      +name: string,
      +taxIdNumber: ?string,
      +firstName: string,
      +lastName: string,
      +phone: string,
      +email: ?string,
      +typeOfCompany: ?ContactTypeOfCompany,
      +typeOfSupplier: ContactTypeOfSupplier,
      +typeOfContact: ContactTypeOfContact,
      +nameBranch: string,
      +registeredAddress: string,
      +registeredCountry: string,
      +registeredProvince: string,
      +registeredDistrict: string,
      +registeredCity: string,
      +registeredPostalCode: string,
      +residential: ?{|
        +id: string,
        +size: number,
        +ownershipRatio: ?number,
      |},
    |},
    +bankAccountTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
          +date: ?any,
          +imageSlip: ?string,
          +bankAccount: {|
            +bankName: string,
            +accountNumber: string,
          |},
        |}
      |}>
    |},
    +cashDepositReceiveTransaction: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +price: number,
        |}
      |}>
    |},
    +chequeDeposit: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +date: any,
          +bankName: string,
          +price: number,
          +chequeNumber: string,
          +branchNumber: string,
        |}
      |}>
    |},
  |},
  +userConfig: ?{|
    +id: string,
    +languages: string,
  |},
|};
export type wrapperGuaranteeMoneyReceivedQuery = {|
  variables: wrapperGuaranteeMoneyReceivedQueryVariables,
  response: wrapperGuaranteeMoneyReceivedQueryResponse,
|};
*/


/*
query wrapperGuaranteeMoneyReceivedQuery(
  $id: ID!
) {
  selfProject {
    id
    name
    nameEn
    address
    addressEn
    logo
    juristicContactNumber
    keyProjectQr
    taxIdNumber
    typeOfProject
  }
  guaranteeMoneyReceived(id: $id) {
    id
    docNumber
    issuedDate
    status
    amount
    firstName
    lastName
    address
    district
    city
    province
    postalCode
    name
    taxIdNumber
    email
    phone
    note
    chartOfAccount {
      id
      chartOfAccountCode
      name
    }
    description
    contact {
      refNumber
      name
      taxIdNumber
      firstName
      lastName
      phone
      email
      typeOfCompany
      typeOfSupplier
      typeOfContact
      nameBranch
      registeredAddress
      registeredCountry
      registeredProvince
      registeredDistrict
      registeredCity
      registeredPostalCode
      residential {
        id
        size
        ownershipRatio
      }
      id
    }
    bankAccountTransaction {
      edges {
        node {
          id
          price
          date
          imageSlip
          bankAccount {
            bankName
            accountNumber
            id
          }
        }
      }
    }
    cashDepositReceiveTransaction {
      edges {
        node {
          id
          price
        }
      }
    }
    chequeDeposit {
      edges {
        node {
          id
          date
          bankName
          price
          chequeNumber
          branchNumber
        }
      }
    }
  }
  userConfig {
    id
    languages
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxIdNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectNode",
  "kind": "LinkedField",
  "name": "selfProject",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameEn",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "juristicContactNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keyProjectQr",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfProject",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "district",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "province",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chartOfAccountCode",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfCompany",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfSupplier",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOfContact",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameBranch",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredAddress",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCountry",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredProvince",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredDistrict",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredCity",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredPostalCode",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "ResidentialNode",
  "kind": "LinkedField",
  "name": "residential",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownershipRatio",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageSlip",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bankName",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountNumber",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "concreteType": "CashTransactionNodeConnection",
  "kind": "LinkedField",
  "name": "cashDepositReceiveTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CashTransactionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CashTransactionNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v34/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "concreteType": "ChequeDepositNodeConnection",
  "kind": "LinkedField",
  "name": "chequeDeposit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChequeDepositNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChequeDepositNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v35/*: any*/),
            (v37/*: any*/),
            (v34/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chequeNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branchNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "concreteType": "UserConfigNode",
  "kind": "LinkedField",
  "name": "userConfig",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperGuaranteeMoneyReceivedQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "GuaranteeMoneyReceivedNode",
        "kind": "LinkedField",
        "name": "guaranteeMoneyReceived",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v3/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v18/*: any*/),
              (v17/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "bankAccountTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v34/*: any*/),
                      (v35/*: any*/),
                      (v36/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v37/*: any*/),
                          (v38/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v39/*: any*/),
          (v40/*: any*/)
        ],
        "storageKey": null
      },
      (v41/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperGuaranteeMoneyReceivedQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "GuaranteeMoneyReceivedNode",
        "kind": "LinkedField",
        "name": "guaranteeMoneyReceived",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v3/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNode",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v18/*: any*/),
              (v17/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountTransactionNodeConnection",
            "kind": "LinkedField",
            "name": "bankAccountTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountTransactionNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountTransactionNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v34/*: any*/),
                      (v35/*: any*/),
                      (v36/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "kind": "LinkedField",
                        "name": "bankAccount",
                        "plural": false,
                        "selections": [
                          (v37/*: any*/),
                          (v38/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v39/*: any*/),
          (v40/*: any*/)
        ],
        "storageKey": null
      },
      (v41/*: any*/)
    ]
  },
  "params": {
    "cacheID": "73f742a0dbb075bb7fac9c421c89efbb",
    "id": null,
    "metadata": {},
    "name": "wrapperGuaranteeMoneyReceivedQuery",
    "operationKind": "query",
    "text": "query wrapperGuaranteeMoneyReceivedQuery(\n  $id: ID!\n) {\n  selfProject {\n    id\n    name\n    nameEn\n    address\n    addressEn\n    logo\n    juristicContactNumber\n    keyProjectQr\n    taxIdNumber\n    typeOfProject\n  }\n  guaranteeMoneyReceived(id: $id) {\n    id\n    docNumber\n    issuedDate\n    status\n    amount\n    firstName\n    lastName\n    address\n    district\n    city\n    province\n    postalCode\n    name\n    taxIdNumber\n    email\n    phone\n    note\n    chartOfAccount {\n      id\n      chartOfAccountCode\n      name\n    }\n    description\n    contact {\n      refNumber\n      name\n      taxIdNumber\n      firstName\n      lastName\n      phone\n      email\n      typeOfCompany\n      typeOfSupplier\n      typeOfContact\n      nameBranch\n      registeredAddress\n      registeredCountry\n      registeredProvince\n      registeredDistrict\n      registeredCity\n      registeredPostalCode\n      residential {\n        id\n        size\n        ownershipRatio\n      }\n      id\n    }\n    bankAccountTransaction {\n      edges {\n        node {\n          id\n          price\n          date\n          imageSlip\n          bankAccount {\n            bankName\n            accountNumber\n            id\n          }\n        }\n      }\n    }\n    cashDepositReceiveTransaction {\n      edges {\n        node {\n          id\n          price\n        }\n      }\n    }\n    chequeDeposit {\n      edges {\n        node {\n          id\n          date\n          bankName\n          price\n          chequeNumber\n          branchNumber\n        }\n      }\n    }\n  }\n  userConfig {\n    id\n    languages\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '99d390fc2554e66afc0110fcf09ec632';

module.exports = node;
