import React, {Component} from 'react';
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import {commitMutation} from "react-relay";
import environment from "../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {Redirect} from 'react-router'
import {parse} from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import AddWithdrawAmountMutation from "./mutations/addWithdrawAmountMutation"
import _ from "lodash"
import i18next from 'i18next';

const getdocNumberNextAndPettyCashSetCreateQuery = graphql`
    query pettyCashBringForwardSetCreateQuery($id: ID!){
        setPettyCashDocNumberNext
        setPettyCash(id:$id) {
            id
            docNumber
            added
            withdrawer
            description
            withdrawAmount
            issuedDate
            bankAccountTransaction{
                edges{
                    node{
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction{
                edges{
                    node{
                        id
                        price
                    }
                }
            }
            chequeTransaction{
                edges{
                    node{
                        id
                        date
                        price
                        chequeNumber
                        bankAccount{
                            id
                            accountName
                            branch
                            bankName
                        }
                    }
                }
            }
        }
        allProjects {
        edges {
            node {
                periodCreate
            }
        }
       }
    }
`;


const mutation = graphql`
    mutation pettyCashBringForwardSetCreateMutation($input: CreateSetPettyCashInput!){
        createSetPettyCash(input: $input){
            ok
            warningText
        }
    }`;


class PettyCashSetCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issuedDate: new Date(),
            description: 'เงินสดย่อยยกมา',
            price: '',
            image_upload: '',
            new_code: 'Loading...',
            withdrawer: '',
            redirectToList: false,
            loading: false,
            withdraw_amount: '',
            bank_account: '',
            cash_transaction: '',
            cheque_transaction: '',

            payChannel: [],
            upload: {},
            totalPay: 0,
            edit: false,
            add_price_limit: false,
            add_withdraw_amount: '',
            date: new Date(),
            can_edit: _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_petty_cash_edit'}),
            can_create: _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_petty_cash_create'}),
            disabled_input: false,

            temp_month_before : '',
            temp_month_after : '',
            period_create:''

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.refreshPettyCashDocNumberNext = this.refreshPettyCashDocNumberNext.bind(this);
        this.updatePayChannel = this.updatePayChannel.bind(this);
        this.changeStatusButton = this.changeStatusButton.bind(this);
        this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
        this.onUpdateError = this.onUpdateError.bind(this);
    }

    componentWillMount() {
        this.refreshPettyCashDocNumberNext();
        let check_perm = false;
        if(this.props.match.params.id){
            // edit
           check_perm = ! _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_petty_cash_edit'})
        }
        else {
           check_perm = ! _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_petty_cash_create'})
        }
        this.setState({disabled_input: check_perm})
    }


    refreshPettyCashDocNumberNext() {
        fetchQuery(environment, getdocNumberNextAndPettyCashSetCreateQuery, {id: this.props.match.params.id || ""}).then(data => {
            if (data.setPettyCash) {
                this.setState({
                    new_code: data.setPettyCash.docNumber,
                    issuedDate: parse(data.setPettyCash.issuedDate ? data.setPettyCash.issuedDate : data.setPettyCash.added),
                    withdrawer: data.setPettyCash.withdrawer,
                    description: data.setPettyCash.description,
                    withdraw_amount: data.setPettyCash.withdrawAmount,
                    bank_account: data.setPettyCash.bankAccountTransaction.edges,
                    cash_transaction: data.setPettyCash.cashTransaction.edges,
                    cheque_transaction: data.setPettyCash.chequeTransaction.edges,
                    edit: true
                })

            } else {
                if (data.setPettyCashDocNumberNext) {
                    this.setState({new_code: data.setPettyCashDocNumberNext, edit:false})
                }
            }
            if(data){
                this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
            }

        });
    }

    calPeriodMouth(val){
        let month_before = new Date();
        let month_after= new Date();
    
        month_before.setMonth(month_before.getMonth() -  parseInt(val));
        month_after.setMonth(month_after.getMonth() + parseInt(val));
    
        this.setState({
            temp_month_before : month_before,
            temp_month_after : month_after,
        })
    }


    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }


    onSubmit(e) {
        e.preventDefault();
        if (this.state.edit) {
            // edit
            if (this.state.add_price_limit && parseFloat(this.state.totalPay) !== parseFloat(this.state.add_withdraw_amount)) {
                Swal.fire(
                    'จำนวนเงินไม่ถูกต้อง',
                    'กรุณากรอกจำนวนเงินเบิกเงิน ให้เท่ากับ ' + numberWithComma(this.state.add_withdraw_amount) + ' บาท',
                    'warning');
            } else {

                this.setState({loading: true});
                let variables = {
                    input: {
                        id: this.props.match.params.id,
                        price: parseFloat(this.state.add_withdraw_amount),
                        paymentChannel: JSON.stringify(this.state.payChannel),
                        date: this.state.date,
                        description: this.state.description,
                        withdrawer: this.state.withdrawer,
                        addWithdrawAmount: this.state.add_price_limit,
                        pettyBringForward: "pettyBringForward",
                    }
                };
                let uploadables = this.state.upload;
                AddWithdrawAmountMutation(variables,uploadables,this.onUpdateSuccess,this.onUpdateError)

            }
        } else {
            // create
            this.setState({loading: true});
            let variables = {
                input: {
                    description: this.state.description,
                    pettyBringForward: "pettyBringForward",
                    price: this.state.totalPay,
                    paymentChannel: JSON.stringify(this.state.payChannel),
                    withdrawer: this.state.withdrawer,
                    issuedDate: this.state.issuedDate,
                }
            };
            let uploadables = this.state.upload;
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        this.setState({loading: false});
                        if (response.createSetPettyCash.ok) {
                            Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                                this.setState({redirectToList: true});
                            });
                        } else {
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createSetPettyCash.warningText, 'warning');
                        }

                    },
                    onError: (err) => {
                        this.setState({loading: false});
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning')
                    },
                },
            );
        }
    }

    updatePayChannel(channel_list) {
        let totalPay = 0;
        let upload = {};
        channel_list.forEach(channel => {
            if (channel.slug === "small-change" || channel.slug === "receipt-deposit") {
                totalPay -= parseFloat(channel.pay);
            } else {
                totalPay += parseFloat(channel.pay);
            }
            if (channel.image) {
                Object.assign(upload, {[channel.slug]: channel.image});
            }
        });
        this.setState({
            payChannel: channel_list,
            totalPay: totalPay,
            upload: upload
        });
    }

    changeStatusButton() {
        if (this.state.add_price_limit) {
            this.setState({add_withdraw_amount: '', date: new Date()})
        }

        this.setState({add_price_limit: !this.state.add_price_limit})
    }

    onUpdateSuccess(response){
        this.setState({loading: false});
        if(response){
            Swal.fire('เพิ่มวงเงินสำเร็จ!', '', 'success').then(() => {
                this.setState({redirectToList: true});
            });
        }else {
             Swal.fire('เพิ่มวงเงินไม่สำเร็จ!', 'กรุณาลองอีกครั้ง', 'warning');
        }
    }

    onUpdateError(response) {
        this.setState({loading: false});
        Swal.fire('เพิ่มวงเงินไม่สำเร็จ!', '', 'warning');
    }


    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/accounting/expense/petty_cash/list/set"/>
        }

        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/expense/petty_cash/list/set">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => 
                                            <React.Fragment>
                                                <span>{t('accounting:add_petty_cash_bring_forward')}</span>
                                                <span style={{fontSize : 16}} className='text-red' > **{t("accountWarning:expense_petty_cash")}</span>
                                            </React.Fragment>
                                            
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>
                        <Translation>
                            {t=>
                        <div className="content-inner">
                            <form id="form-add" onSubmit={this.onSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <label htmlFor="docNumber"
                                                className="col-2 col-form-label">
                                                <strong>{t("pettyCashView:Document No.")}</strong>
                                            </label>
                                            <div className="col-5">
                                                <input type="text" className="form-control" id="docNumber"
                                                    value={this.state.new_code}
                                                    name="docNumber" disabled/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountName"
                                                className="col-2 col-form-label">
                                                <strong>{t("pettyCashView:Date ")}</strong>
                                            </label>
                                            <div className="col-5">
                                                <div className="input-group">
                                                    {/* <DatePickerAdapter
                                                        name="issuedDate" className="form-control"
                                                        selected={this.state.issuedDate}
                                                        onChange={this.handleInputChange}
                                                        disable={this.state.disabled_input}
                                                    /> */}
                                                    {
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                                                        <DatePickerAdapter
                                                            name="issuedDate"
                                                            id="issuedDate"
                                                            className="form-control"
                                                            selected={this.state.issuedDate}
                                                            minDate={this.state.temp_month_before}
                                                            // maxDate={this.state.temp_month_after}
                                                            onChange={this.handleInputChange}
                                                            required={true}
                                                        /> : 
                                                        <DatePickerAdapter
                                                            name="issuedDate" className="form-control"
                                                            selected={this.state.issuedDate}
                                                            onChange={this.handleInputChange}
                                                            disable={this.state.disabled_input}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="withdrawer"
                                                className="col-2 col-form-label">
                                                <strong>{t("pettyCash:career")}</strong>
                                            </label>
                                            <div className="col-5">
                                                <input type="text" className="form-control" id="withdrawer"
                                                    value={this.state.withdrawer}
                                                    name="withdrawer" onChange={this.handleInputChange}
                                                    disabled={this.state.disabled_input}
                                                    required/>
                                            </div>
                                            {!this.state.edit &&
                                            <label htmlFor="date"
                                                className="col-3 col-form-label text-muted">{t("pettyCash:custodian")}</label>
                                            }

                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountNum"
                                                className="col-2 col-form-label">
                                                <strong>{t("PrepaidDepositView:Description")}</strong>
                                            </label>
                                            <div className="col-5">
                                        <textarea className="form-control" maxLength={256}
                                                    rows="3" name="description" 
                                                    value={this.state.description}
                                                    disabled={this.state.disabled_input}
                                                    onChange={this.handleInputChange}
                                                    required/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mt-3">
                                            <label htmlFor="InputBankAccountNum"
                                                className="col-2 col-form-label">
                                                <strong>{t("PrepaidDepositView:Amount")}</strong>
                                            </label>
                                            <div className="col-3">
                                            <input type="text" className="form-control" id="money"
                                                    value={this.state.totalPay}
                                                    name="totalPay" onChange={this.handleInputChange}
                                                    disabled={this.state.disabled_input}
                                                    required/>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                    </div>
                                </div>

                            </form>

                            {/* {this.state.edit &&
                            <PaymentChannel
                                cashTransaction={this.state.cash_transaction}
                                chequeTransaction={this.state.cheque_transaction}
                                allBankAccountTransaction={this.state.bank_account}
                            />
                            } */}
                            {!this.state.edit &&
                            <div className="row mt-4">
                                <div className="col-12 text-right">
                                    <div className="btn-group mr-2">
                                        <Link to="/accounting/expense/petty_cash/list/set">
                                            <button type="button" className="btn btn-secondary add">
                                            {t("Allaction:cancel")}
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="btn-group mr-2">
                                        <button type="submit" className="btn btn-primary add"
                                                form="form-add"
                                                disabled={this.state.loading}>
                                            {this.state.loading &&
                                            <span
                                                className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                            {t("pettyCash:create_petty_cash")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                            }
                        </Translation>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}

export default PettyCashSetCreate;
