import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";

const queryGroup = graphql`
    query queryAnnounceDashboardGroupQuery {
        allJuristicNewsGroup{
            edges {
                node {
                    id
                    name
                    nameEn
                    pinIt
                }
            }
        }
    }
`;


  
  export default queryGroup;