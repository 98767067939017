import React from "react";
import localStorage from "../../../../libs/localstorage";
import numberWithComma from "../../../../libs/numberWithComma";
import { format } from "date-fns";
import Link from "react-router-dom/es/Link";
import getNameResidential from "../../../../libs/getNameResidential";
import _ from 'lodash';
import i18next from "i18next";


class InvoiceReportTable extends React.Component {
    removeTrailingZeros(description){
        if(description.includes("ค่าน้ำประปา")){
            description = description.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    getStatus(invoice) {
        let text = '';
        switch (invoice.node.status) {
            case 'DRAFT':
                text = 'แบบร่าง';
                break;
            case 'ACTIVE':
                text = 'รอรับชำระ';
                break;
            case 'OVERDUE':
                text = 'เกินกำหนดชำระ';
                break;
            case 'PAID':
                text = 'รับชำระแล้ว';
                break;
            case 'PARTIAL_PAYMENT':
                text = 'รับชำระบางส่วน';
                break;
            case 'WAITING_FIX':
                text = 'รายการที่รอการแก้ไข';
                break;
            default:
                text = 'รายการที่ยกเลิก';
        }
        return text
    }

    render() {
        let total = 0.0;
        let total_partial_payment = 0;
        let total_amount_partial_payment = 0;
        let summary = this.props.props.invoiceViewer.summaryOnlyReportInvoice !== null  ? JSON.parse(this.props.props.invoiceViewer?.summaryOnlyReportInvoice[0]) : null
        return (
            <React.Fragment>
                {this.props.props.invoiceViewer.allInvoice.edges.map((invoice, index) => {
                    let order_id = this.props.state.first - localStorage.getPageLimit();
                    let accumulate = 0;
                    let invoiceTransactionSort = _.orderBy(invoice.node.transaction.edges, ['node.total'], [this.props.state.typeSort || "asc"]);
                    let old_order = 0;
                    let multi_invoice = false;
                    let partial_payment = 0; 
                    let amount_partial_payment = 0;
                    

                    return (
                        invoiceTransactionSort.map((transaction, t_index) => {
                            let number_of_transaction = invoiceTransactionSort.length;
                            let ref_doc_number_list = [];
                            
                            partial_payment = transaction.node.paid
                            amount_partial_payment = transaction.node.total - partial_payment;

                            total_partial_payment += partial_payment;
                            total_amount_partial_payment += amount_partial_payment;

                            //คำนวน transaction แต่ละยอด
                            if(this.props.state.product_and_service &&
                                this.props.state.product_and_service !== transaction.node.productAndService.id){
                                    return true;
                                }
                            if (invoice.node.status !== "VOID"){
                                accumulate = accumulate + transaction.node.total;
                            }

                            let row_number = (t_index === 0 && order_id + index + 1);
                            if (this.props.state.product_and_service &&
                                    this.props.state.product_and_service === transaction.node.productAndService.id) {
                                    row_number = order_id  + index + 1;
                                    if(old_order === row_number){
                                        multi_invoice = true; //ใน invoice มีหลายรายการ ให้แสดงเลขลำดับเฉพาะค่าบนสุดเท่านั้น true = ไม่ใช่ลำดับแรก
                                    }else{
                                        multi_invoice = false;
                                        old_order = row_number;
                                    }
                            }
                            // if (this.props.state.product_and_service &&
                            //     this.props.state.product_and_service !== transaction.node.productAndService.id) {
                            //     return true;
                            // } else if (this.props.state.product_and_service &&
                            //     this.props.state.product_and_service === transaction.node.productAndService.id) {
                            //     accumulate = transaction.node.total;
                            //     if(old_order === row_number){
                            //         multi_invoice = true;
                            //     }else{
                            //         multi_invoice = false;
                            //         old_order = row_number;
                            //     }
                            //     number_of_transaction = t_index + 1;
                            // }

                            // คำนวนยอดรวมทั้งหมด
                            if (t_index === number_of_transaction - 1) {
                                total += accumulate;
                            }
                            // แสดง Ref Number
                            if (transaction.node.receiptDepositTransactionUsed.edges.length !== 0) {
                                transaction.node.receiptDepositTransactionUsed.edges.forEach((receive_deposit) => {
                                    if (receive_deposit.node.receiptDepositTransaction.couple !== "NO" && receive_deposit.node.coupleUse !== "NO")
                                        ref_doc_number_list.push({
                                            type: 'receive_deposit',
                                            id: receive_deposit.node.receiptDeposit.id,
                                            doc_number: receive_deposit.node.receiptDeposit.docNumber
                                        });
                                })
                            }
                            if (transaction.node.creditNoteTransaction.edges.length !== 0) {
                                transaction.node.creditNoteTransaction.edges.forEach((credit_note) => {
                                    ref_doc_number_list.push({
                                        type: 'credit_note',
                                        id: credit_note.node.id,
                                        doc_number: credit_note.node.docNumber
                                    });
                                })
                            }
                            if (transaction.node.creditNoteTransactionRecord.edges.length !== 0) {
                                transaction.node.creditNoteTransactionRecord.edges.forEach(
                                  (credit_note_transaction) => {
                                    ref_doc_number_list.push({
                                      type: "credit_note",
                                      id: credit_note_transaction.node.creditNote.id,
                                      doc_number: credit_note_transaction.node.creditNote.docNumber,
                                    });
                                  }
                                );
                            }
                            
                            if (transaction.node.receiveTransaction.edges.length !== 0) {
                                transaction.node.receiveTransaction.edges.forEach((receive) => {
                                    if (receive.node.receive.status !== 'VOID') {
                                        ref_doc_number_list.push({
                                            type: 'receive',
                                            id: receive.node.receive.id,
                                            doc_number: receive.node.receive.docNumber
                                        });
                                    }
                                })
                            }

                            if (invoice.node.status !== "VOID") {
                                return (
                                    <tr key={transaction.node.id}>
                                        <td className="text-center">{!multi_invoice && row_number}</td>
                                        <td className="text-center">
                                            <Link
                                                to={"/accounting/income/invoice/invoice-detail/" + invoice.node.id}
                                                target={"_blank"}>  
                                                {invoice.node.docNumber}
                                            </Link>
                                        </td>
                                        <td className="text-center">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                        <td className="text-center">{format(invoice.node.dueDate, 'DD/MM/YYYY')}</td>
                                        <td>
                                            {invoice.node.contact.residential ? invoice.node.name : "-"}
                                        </td>
                                        <td>
                                            {invoice.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                getNameResidential(invoice.node.firstName, invoice.node.lastName)
                                            }

                                            {invoice.node.contact.typeOfContact === "SUPPLIER" &&
                                                invoice.node.name
                                            }

                                            {" "}                                                                                        
                                            {
                                                (invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER") &&
                                                <label className="text-danger">
                                                    ({invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${invoice.node.payGroup}`) : ""})
                                                </label>
                                            }     
                                        </td>
                                        <td>
                                            {(transaction.node.productAndService.productCode)}
                                        </td>
                                        <td>{
                                            this.removeTrailingZeros(transaction.node.description)
                                            }</td>
                                        <td className="text-center">{this.getStatus(invoice)}</td>
                                        <td className="text-right">{numberWithComma(transaction.node.total)}</td>
                                        {this.props.state.status === 'partial_payment' && <td className="text-right">{numberWithComma(partial_payment,'0.00')}</td>}
                                        {this.props.state.status === 'partial_payment' && <td className="text-right">{numberWithComma(amount_partial_payment,'0.00')}</td>}
                                        {this.props.state.status !== 'partial_payment' &&
                                        <td className="text-right">{
                                            t_index === number_of_transaction - 1 && numberWithComma(accumulate)
                                            }</td>
                                        }
                                        {/* ลิงค์ RT RD CN  */}
                                        {<td className="text-center" width={130}>
                                            {ref_doc_number_list.length > 0 ?
                                                ref_doc_number_list.map((ref_doc, index) =>
                                                    <span key={ref_doc.id}>
                                                        {ref_doc.type === 'receive_deposit' || ref_doc.type === 'credit_note' ?
                                                            ref_doc.type === 'receive_deposit' ?
                                                                <Link
                                                                    to={"/accounting/income/receipt_deposit/view/" + ref_doc.id}
                                                                    >
                                                                    {ref_doc.doc_number}
                                                                </Link>
                                                                :
                                                                <Link
                                                                    to={"/accounting/income/credit-note/detail/" + ref_doc.id}
                                                                    >
                                                                    {ref_doc.doc_number}
                                                                </Link>
                                                            :
                                                            <Link
                                                                to={"/accounting/income/receive/detail/" + ref_doc.id + "/" + ref_doc.doc_number}
                                                                >
                                                                {ref_doc.doc_number}
                                                            </Link>
                                                        }

                                                        {index + 1 < ref_doc_number_list.length && ', '}
                                                    </span>
                                                )
                                                : '-'
                                            }
                                        </td>
                                        }

                                    </tr>
                                )
                            } else {
                                return (
                                    <tr key={transaction.node.id}>
                                        <td className="text-center text-danger">{!multi_invoice && row_number}</td>
                                        <td className="text-center text-danger">
                                                {invoice.node.docNumber}
                                        </td>
                                        <td className="text-center text-danger">{format(invoice.node.issuedDate, 'DD/MM/YYYY')}</td>
                                        <td className="text-center text-danger">{format(invoice.node.dueDate, 'DD/MM/YYYY')}</td>
                                        <td className="text-danger">
                                            {invoice.node.contact.residential ? invoice.node.name : "-"}
                                        </td>
                                        <td className="text-danger">
                                            {invoice.node.contact.typeOfContact === "RESIDENTIAL" &&
                                                getNameResidential(invoice.node.firstName, invoice.node.lastName)
                                            }

                                            {invoice.node.contact.typeOfContact === "SUPPLIER" &&
                                                invoice.node.name
                                            }
                                            {" "}
                                            {
                                                (invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER") &&
                                                <label className="text-danger">
                                                    ({invoice.node.payGroup === "RENT" || invoice.node.payGroup === "AGENT" || invoice.node.payGroup === "OWNER" || invoice.node.payGroup === "DEVELOPER" ? i18next.t(`AgentRole:${invoice.node.payGroup}`) : ""})
                                                </label>
                                            }    
                                        </td>
                                        <td className="text-danger">{(transaction.node.productAndService.productCode)}</td>
                                        <td className="text-danger">{
                                            this.removeTrailingZeros(transaction.node.description)
                                            }</td>
                                        <td className="text-center text-danger">{this.getStatus(invoice)}</td>
                                        <td className="text-right text-danger">{numberWithComma(0)}</td>
                                        <td className="text-right text-danger">{
                                            t_index === number_of_transaction - 1 && numberWithComma(0)
                                        }</td>
                                        <td className="text-center">-</td>

                                    </tr>
                                )
                            }
                        })
                    )
                })}
                {((this.props.props.invoiceViewer.allInvoice.pageInfo.hasNextPage || this.props.props.invoiceViewer.allInvoice.pageInfo.hasPreviousPage)) &&
                    <React.Fragment>
                        {this.props.state.status !== 'partial_payment' ? 
                            <tr>
                                <td colSpan={11} className="text-right"><strong>รวม</strong></td>
                                <td className="text-right"><strong>{numberWithComma(total)}</strong></td>
                            </tr>  
                            :
                            <tr>
                                <td colSpan={9} className="text-right"><strong>รวม</strong></td>
                                {/* <td className="text-right"><strong>{numberWithComma(this.props.props.invoiceViewer.summaryReportInvoice[0])}</strong></td> */}
                                <td className="text-right"><strong>{numberWithComma(total)}</strong></td>
                                <td className="text-right"><strong>{numberWithComma(total_partial_payment)}</strong></td>
                                <td className="text-right"><strong>{numberWithComma(total_amount_partial_payment)}</strong></td>
                                <td/>
                            </tr> 
                        }
                    </React.Fragment>
                }
                {/* {!this.props.props.invoiceViewer.allInvoice.pageInfo.hasNextPage && */}
                    {this.props.state.status !== 'partial_payment' ? 
                        <tr>
                            <td colSpan={11} className="text-right"><strong>รวมทั้งหมด</strong></td>
                            <td className="text-right"><strong>{numberWithComma(summary !== null ? summary.sum_total : 0)}</strong></td>
                        </tr>
                        :
                        <tr>
                            <td colSpan={9} className="text-right"><strong>รวมทั้งหมด</strong></td>
                            <td className="text-right"><strong>{numberWithComma(summary !== null ? summary.sum_total:0)}</strong></td>
                            <td className="text-right"><strong>{numberWithComma(summary !== null ? summary.sum_total_partial_payment:0)}</strong></td>
                            <td className="text-right"><strong>{numberWithComma(summary !== null ? summary.amount_partial_payment:0)}</strong></td>
                            <td/>
                        </tr>

                    }
                {/* } */}
            </React.Fragment>

        )
    }
}

export default InvoiceReportTable;
