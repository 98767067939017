import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { addMonths, differenceInMonths } from "date-fns";
import PayableClassifiedReportProductTable from "./payableClassifiedReportProductTable";
import PayableClassifiedReportChartTable from "./payableClassifiedReportChartTable";
import ExportPayableClassifiedReport from "./server_export";
import "../styles/payable_classified_report.scss";
import _ from "lodash";
import postApiUseing from '../../../../libs/postApiUseing';
import BackButtonIcon from '../../../../components/BackBtn/indexBack';
import i18next from "i18next";

class PayableClassifiedReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let now = new Date();
    this.state.start_period = new Date(now.getFullYear(), 0);
    this.state.end_period = new Date(now.getFullYear(), 11);
    this.state.temp_start_period = new Date(now.getFullYear(), 0);
    this.state.temp_end_period = new Date(now.getFullYear(), 11);
    this.state.search = "";
    this.state.search_input = "";
    this.state.month_list = [];
    this.state.productSummaryByStatusPayable = [];
    this.state.payableSummary = [];
    this.state.total_row = 0;
    this.state.all_total = 0;
    this.state.all_paid = 0;
    this.state.all_not_pay = 0;
    this.state.product_and_service = "";
    this.state.last_index = 0;
    this.state.expense_type = "product_and_service";
    this.state.temp_expense_type = "product_and_service";

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "payableClassifiedReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

  }

  async handleChange(e) {
    if (e.target.name === "temp_start_period") {
      let dif = differenceInMonths(this.state.end_period, e.target.value);
      if ((dif < 0 && dif !== -0) || dif > 12) {
        this.setState({ end_period: addMonths(e.target.value, 11) });
      }
    }
    await this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState(
      {
        start_period: this.state.temp_start_period,
        end_period: this.state.temp_end_period,
        search: event.target.value,
        expense_type: this.state.temp_expense_type,
      },
      () => {
        this.setPeriod();
      }
    );
  }

  componentDidMount() {
    this.setPeriod();
  }

  setPeriod() {
    let start_month = this.state.start_period.getMonth();
    let start_year = this.state.start_period.getFullYear();
    let month_list = [this.state.start_period];
    let diff_month = Math.abs(
      differenceInMonths(this.state.start_period, this.state.end_period)
    );

    for (let step = 1; step <= diff_month; step++) {
      let new_date = new Date(start_year, start_month + step);
      month_list.push(new_date);
    }

    this.setState({ month_list: month_list, last_index: diff_month });
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box" id="payable_classified_report">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-payable"}
                  LinkText={i18next.t("DashboardPayable:Summary Report of All Payables by Type")}
                  boxHtml={
                    <div className="col">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_payable_print",
                      }) && <ExportPayableClassifiedReport state={this.state} />}
                    </div>
                  }

                />
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>
                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_period"
                          showMonthYearPicker={true}
                          className="form-control"
                          dateFormat="MM/yyyy"
                          selected={this.state.temp_end_period}
                          maxDate={addMonths(this.state.temp_start_period, 11)}
                          minDate={this.state.temp_start_period}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_period"
                          showMonthYearPicker={true}
                          className="form-control"
                          dateFormat="MM/yyyy"
                          selected={this.state.temp_start_period}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <div className="col-md-4 border-right">
                            <div className="form-group">
                              <label>{i18next.t("FilterPayable:Product/Expense Type")}</label>
                              <select
                                name="temp_expense_type"
                                value={this.state.temp_expense_type}
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="product_and_service">
                                  {i18next.t("FilterPayable:Products and Services")}
                                </option>
                                <option value="chart">{i18next.t("FilterPayable:Expenses")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.expense_type === "product_and_service" ? (
                    <PayableClassifiedReportProductTable state={this.state} />
                  ) : (
                    <PayableClassifiedReportChartTable state={this.state} />
                  )}
                </div>
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default PayableClassifiedReport;
