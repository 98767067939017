import "./styles/styles.scss";
import React from 'react';
import Wrapper from "../../components/wrapper";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
import { getDaysInMonth, setDate, setMonth, setYear, lastDayOfMonth } from "date-fns";
import BalanceSheetAccountHeader from "./balanceSheetAccountHeader";
import BalanceSheetAccountAssets from "./balanceSheetAccountAssets";
import BalanceSheetAccountCurrent from "./balanceSheetAccountCurrent";
import BalanceSheetAccountCoOwner from "./balanceSheetAccountCoOwner";
import ExportBalanceSheetAccount from "./serverExport";
import BalanceSheetAccountCharts from "./balanceSheetAccountCharts";
import { formatDateISO } from "../../utils";
import Loading from "../../libs/loading";
import i18next from "i18next";
const query = graphql`
    query balanceSheetAccountQuery($startDateFirst : DateTime, $endDateFirst: DateTime, $startDateSecond: DateTime, $endDateSecond: DateTime, $period: String) {
        allAccountProjectManager{
            edges{
              node{
                id
                tableAccount
              }
            }
        }

        chartOfAccountBalanceSheetAccount(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond){
            edges{
                node{
                    id
                    name
                    chartOfAccountCode
                    col1Debit
                    col1Credit
                    col2Debit
                    col2Credit
                    totalHistoricalDebit
                    totalHistoricalCredit
                }
            }
        }
        capitalCash: chartOfAccountBalanceSheetAccount(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond, typeAccount: "3000"){
            edges{
                node{
                    id
                    name
                    nameEn
                    chartOfAccountCode
                    col1Debit
                    col1Credit
                    col2Debit
                    col2Credit
                    totalHistoricalDebit
                    totalHistoricalCredit
                }
            }
        }
        chartOfAccountSummaryIncomeExpenseDiff(startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond, period: $period) {
            yearFirst
            yearSecond
            accruedSurplusFirst
            accruedSurplusSecond
        }
    }`;

class BalanceSheetAccount extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        let before_date = setMonth(new Date(), new Date().getMonth() - 1);
        this.state = {
            start_date_first: before_date,
            end_date_first: new Date(),

            start_date_second: setMonth(new Date(), new Date().getMonth() - 2),
            end_date_second: setDate(before_date, getDaysInMonth(before_date)),

            selected_date: new Date(),
            period: 'month',
            dateSet: [
                new Date(now.getFullYear(), now.getMonth()),
                new Date(now.getFullYear(), now.getMonth() + 1),
            ],
            co_owner: [],
            co_owner_ex: {},
            data: [],
            totalAsset: 0,
            totalCash: 0,
            totalCurrentAsset: 0,
            totalNonCurrentAsset: 0,
            totalLiability: 0,
            totalEquity: 0,
            sinkingFund: 0,
            surplusDeficitAccum: 0,
            loading: true,
        };
        this.handleChange = this.handleChange.bind(this);
        // this.calDate = this.calDate.bind(this);
        this.queryData = this.queryData.bind(this);
        this.setDataOwner = this.setDataOwner.bind(this);

    }


    componentDidMount() {
        // this.calDate();
        this.queryData();
    }

    queryData() {
        let now = new Date();
        this.setState({
            dateSet: [
                new Date(now.getFullYear(), now.getMonth()),
                new Date(now.getFullYear(), now.getMonth() + 1),
            ], co_owner: [], loading: true,
        }, () => {
            fetchQuery(environment, query, {
                startDateFirst: this.state.start_date_first,
                endDateFirst: this.state.end_date_first,
                startDateSecond: this.state.start_date_second,
                endDateSecond: this.state.end_date_second,
                period: this.state.period,
            }).then((data) => {
                const tableAccount = data.allAccountProjectManager.edges[0]?.node.tableAccount;
                // const start_date_first = formatDateISO(this.state.start_date_first);
                const end_date_first = formatDateISO(this.state.end_date_first);
                // const start_date_second = formatDateISO(this.state.start_date_second);
                const end_date_second = formatDateISO(this.state.end_date_second);
                let tableAccount_1 = false;
                let tableAccount_2 = false;

                if (this.state.period === 'year') {
                    tableAccount_1 = tableAccount <= end_date_first ? true : false
                    tableAccount_2 = tableAccount <= end_date_second ? true : false
                } else {
                    tableAccount_1 = tableAccount <= end_date_first ? true : false
                    tableAccount_2 = tableAccount <= end_date_second ? true : false
                }
                let dataSet = data.chartOfAccountBalanceSheetAccount;
                this._data_set(dataSet, 'beforeSet', tableAccount_1);
                this._data_set(dataSet, 'afterSet', tableAccount_2);
                this.setDataOwner(data, tableAccount_1, tableAccount_2);
                this.setState({ loading: false });
            });
        })

    }

    setDataOwner(data_query, tableAccount_1, tableAccount_2) {
        let dataSet = data_query.capitalCash;
        let co_owner = [];
        let before_amount_1 = 0;
        let after_amount_1 = 0;
        let before_amount_2 = 0;
        let after_amount_2 = 0;
        let chart_first = 0;
        let chart_second = 0;
        let name_use = "";
        let objests = {};
        for (let i = 0; i < dataSet.edges.length; i++) {
            let data = dataSet.edges[i].node;
            if (data.chartOfAccountCode.substring(0, 7) === "3000-01") {

                if (tableAccount_1) {
                    before_amount_1 += (_.get(data, 'col1Credit') + data.totalHistoricalCredit) - (_.get(data, 'col1Debit') + data.totalHistoricalDebit);
                } else {
                    before_amount_1 += (_.get(data, 'col1Credit')) - (_.get(data, 'col1Debit'));
                }

                if (tableAccount_2) {
                    after_amount_1 += (_.get(data, 'col2Credit') + data.totalHistoricalCredit) - (_.get(data, 'col2Debit') + data.totalHistoricalDebit);
                } else {
                    after_amount_1 += (_.get(data, 'col2Credit')) - (_.get(data, 'col2Debit'));
                }

                continue;
            }
            if (data.chartOfAccountCode.substring(0, 7) === "3000-02") {

                if (tableAccount_1) {
                    before_amount_2 += (_.get(data, 'col1Credit') + data.totalHistoricalCredit) - (_.get(data, 'col1Debit') + data.totalHistoricalDebit);
                } else {
                    before_amount_2 += (_.get(data, 'col1Credit')) - (_.get(data, 'col1Debit'));
                }

                if (tableAccount_2) {
                    after_amount_2 += (_.get(data, 'col2Credit') + data.totalHistoricalCredit) - (_.get(data, 'col2Debit') + data.totalHistoricalDebit);
                } else {
                    after_amount_2 += (_.get(data, 'col2Credit')) - (_.get(data, 'col2Debit'));
                }

                continue;
            }

            //หมวดพิเศษ
            if (data.chartOfAccountCode.substring(0, 4) === "3000" && data.chartOfAccountCode.substring(0, 7) !== "3000-02" || data.chartOfAccountCode.substring(0, 7) !== "3000-01") {
                name_use = data.name + " / " + data.nameEn
                if (tableAccount_1) {
                    chart_first = (_.get(data, 'col1Credit') + data.totalHistoricalCredit) - (_.get(data, 'col1Debit') + data.totalHistoricalDebit);
                }
                else {
                    chart_first = (_.get(data, 'col1Credit')) - (_.get(data, 'col1Debit'));
                }

                if (tableAccount_2) {
                    chart_second = (_.get(data, 'col2Credit') + data.totalHistoricalCredit) - (_.get(data, 'col2Debit') + data.totalHistoricalDebit);
                } else {
                    chart_second = (_.get(data, 'col2Credit')) - (_.get(data, 'col2Debit'));
                }

                let list_objects = [name_use, chart_first, chart_second]
                objests[data.chartOfAccountCode.substring(0, 7)] = list_objects
                continue;
            }

        }
        co_owner.push({ before_amount_1, after_amount_1 });
        co_owner.push({ before_amount_2, after_amount_2 });
        if (_.isObject(data_query.chartOfAccountSummaryIncomeExpenseDiff)) {
            let yearFirst = data_query.chartOfAccountSummaryIncomeExpenseDiff.yearFirst;
            let yearSecond = data_query.chartOfAccountSummaryIncomeExpenseDiff.yearSecond;
            let accruedSurplusFirst = data_query.chartOfAccountSummaryIncomeExpenseDiff.accruedSurplusFirst;
            let accruedSurplusSecond = data_query.chartOfAccountSummaryIncomeExpenseDiff.accruedSurplusSecond;
            co_owner.push({ yearFirst, yearSecond, accruedSurplusFirst, accruedSurplusSecond })
        }
        this.setState({
            co_owner: co_owner,
            co_owner_ex: objests
            // this.setState({ isLoading: false });
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.period !== prevState.period) || (this.state.selected_date !== prevState.selected_date)) {
            this.setState({ isLoading: true });
            // this.calDate();
            // this.queryData();
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    calDate() {
        if (this.state.period === 'year') {
            let before_date = setYear(this.state.selected_date, this.state.selected_date.getFullYear() - 1);

            //Set localStorage
            localStorage.setItem('balance_start_date_first', before_date)
            localStorage.setItem('balance_end_date_first', this.state.selected_date)
            localStorage.setItem('balance_start_date_second', setYear(this.state.selected_date, this.state.selected_date.getFullYear() - 2))
            localStorage.setItem('balance_end_date_second', setDate(before_date, getDaysInMonth(before_date)))

            this.setState({
                start_date_first: before_date,
                end_date_first: this.state.selected_date,

                start_date_second: setYear(this.state.selected_date, this.state.selected_date.getFullYear() - 2),
                end_date_second: setDate(before_date, getDaysInMonth(before_date)),
            })
            this.queryData();
        } else {
            let start_date_first = new Date();
            let end_date_first = new Date();
            let start_date_second = new Date();
            let end_date_second = new Date();
            const month_select = this.state.period

            let month_minus = month_select === 'month' && this.state.selected_date.getDate() !== lastDayOfMonth(this.state.selected_date).getDate() ? 1 : 0;
            if (month_select === 'month3') {
                month_minus = 2
            }
            else if (month_select === 'month6') {
                month_minus = 5
            }
            else if (month_select === 'month9') {
                month_minus = 8
            }

            if (this.state.selected_date.getDate() === lastDayOfMonth(this.state.selected_date).getDate()) {
                start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), 1);
                end_date_first = this.state.selected_date;

                end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), 1);
            } else {
                start_date_first = setDate(setMonth(this.state.selected_date, this.state.selected_date.getMonth() - month_minus), this.state.selected_date.getDate() + 1);
                end_date_first = this.state.selected_date;

                end_date_second = setDate(start_date_first, start_date_first.getDate() - 1);
                start_date_second = setDate(setMonth(end_date_second, end_date_second.getMonth() - (month_minus)), end_date_second.getDate() + 1);
            }

            //Set localStorage
            localStorage.setItem('balance_start_date_first', start_date_first)
            localStorage.setItem('balance_end_date_first', end_date_first)
            localStorage.setItem('balance_start_date_second', start_date_second)
            localStorage.setItem('balance_end_date_second', end_date_second)

            // month
            this.setState({
                start_date_first: start_date_first,
                end_date_first: end_date_first,

                start_date_second: start_date_second,
                end_date_second: end_date_second,
            })
        }
        this.queryData();
    }

    _data_set(dataSet, type, Htc) {
        let typeIndex = type === 'beforeSet' ? 1 : 2;
        let total_ref_1 = 0;
        let total_ref_2 = 0;
        let total_ref_3 = 0;
        let total_ref_4 = 0;
        let total_ref_5 = 0;
        let total_ref_6 = 0;
        let total_ref_7 = 0;
        let total_ref_8 = 0;
        let total_ref_9 = 0;
        let total_ref_10 = 0;
        let total_ref_11 = 0;
        let total_ref_12 = 0;
        let total_ref_13 = 0;
        let total_ref_14 = 0;
        let total_ref_15 = 0;

        for (let i = 0; i < dataSet.edges.length; i++) {
            let data = dataSet.edges[i].node;
            // ref 1
            // = เงินสดและเงินฝากธนาคาร
            if (data.chartOfAccountCode.substring(0, 3) === "111" || data.chartOfAccountCode.substring(0, 3) === "112") {
                const totalHistoricalDebit = Htc ? data.totalHistoricalDebit : 0;
                total_ref_1 += _.get(data, 'col' + typeIndex + 'Debit') + totalHistoricalDebit - _.get(data, 'col' + typeIndex + 'Credit');
                continue;
            }

            // ref 2
            // = ลูกหนี้เจ้าของร่วมและเช็ครับ
            if (data.chartOfAccountCode.substring(0, 4) === "1131") {
                const totalHistoricalDebit = Htc ? data.totalHistoricalDebit : 0;
                total_ref_2 += _.get(data, 'col' + typeIndex + 'Debit') + totalHistoricalDebit - _.get(data, 'col' + typeIndex + 'Credit');
                continue;
            }

            // ref 3
            // = สินทรัพย์หมุนเวียนอื่นๆ / Other Current Assets
            if (data.chartOfAccountCode.substring(0, 4) === "1132"
                || data.chartOfAccountCode.substring(0, 4) === "1140"
                || data.chartOfAccountCode.substring(0, 3) === "115") {
                const totalHistoricalDebit = Htc ? data.totalHistoricalDebit : 0;
                total_ref_3 += _.get(data, 'col' + typeIndex + 'Debit') + totalHistoricalDebit - _.get(data, 'col' + typeIndex + 'Credit');
                continue;
            }

            // ref 4
            // = สินทรัพย์ไม่หมุนเวียน (สินทรัพย์ถาวร)
            if (data.chartOfAccountCode.substring(0, 4) === "1210" || data.chartOfAccountCode.substring(0, 4) === "1220") {
                const totalHistoricalDebit = Htc ? data.totalHistoricalDebit : 0;
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_4 += (_.get(data, 'col' + typeIndex + 'Debit') + totalHistoricalDebit) - (_.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit);
                continue;
            }

            // ref 5
            // = สินทรัพย์อื่น ๆ
            if (data.chartOfAccountCode.substring(0, 4) === "1230" || data.chartOfAccountCode.substring(0, 4) === "1240" || data.chartOfAccountCode.substring(0, 4) === "1310") {
                const totalHistoricalDebit = Htc ? data.totalHistoricalDebit : 0;
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                if (data.chartOfAccountCode.substring(0, 4) === "1240") {
                    total_ref_5 += (_.get(data, 'col' + typeIndex + 'Debit') + totalHistoricalDebit) - (_.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit);
                }
                else {
                    total_ref_5 += _.get(data, 'col' + typeIndex + 'Debit') + totalHistoricalDebit - _.get(data, 'col' + typeIndex + 'Credit');
                }
                continue;
            }

            // ref 6
            // = เจ้าหนี้และเช็คจ่าย
            if (data.chartOfAccountCode.substring(0, 4) === "2110" || data.chartOfAccountCode.substring(0, 4) === "2111") {
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_6 += _.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(data, 'col' + typeIndex + 'Debit');
                continue;
            }

            // ref 7
            if (["2121", "2122", "2123"].includes(data.chartOfAccountCode.substring(0, 4))) {
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_7 += _.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(data, 'col' + typeIndex + 'Debit');
                continue;
            }

            // ref 8
            if (data.chartOfAccountCode.substring(0, 4) === "2230") {
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_8 += _.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(data, 'col' + typeIndex + 'Debit');
                continue;
            }

            // ref 9
            if (data.chartOfAccountCode.substring(0, 4) === "2220") {
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_9 += _.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(data, 'col' + typeIndex + 'Debit');
                continue;
            }

            // ref 10
            if (data.chartOfAccountCode.substring(0, 4) === "2125") {
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_10 += _.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(data, 'col' + typeIndex + 'Debit');
                continue;
            }

            // ref 11
            if (data.chartOfAccountCode.substring(0, 4) === "2210") {
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_11 += _.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(data, 'col' + typeIndex + 'Debit');
                continue;
            }

            // ref 12
            if (data.chartOfAccountCode.substring(0, 4) === "2124" || data.chartOfAccountCode.substring(0, 4) === "2126") {
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_12 += _.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(data, 'col' + typeIndex + 'Debit');
                continue;
            }

            // ref 13
            if (["2300", "2400", "2410"].includes(data.chartOfAccountCode.substring(0, 4))) {
                const totalHistoricalCredit = Htc ? data.totalHistoricalCredit : 0;
                total_ref_13 += _.get(data, 'col' + typeIndex + 'Credit') + totalHistoricalCredit - _.get(data, 'col' + typeIndex + 'Debit');
                continue;
            }

        }

        this.setState({
            [type]: {
                total_ref_1: total_ref_1,
                total_ref_2: total_ref_2,
                total_ref_3: total_ref_3,
                total_ref_4: total_ref_4,
                total_ref_5: total_ref_5,
                total_ref_6: total_ref_6,
                total_ref_7: total_ref_7,
                total_ref_8: total_ref_8,
                total_ref_9: total_ref_9,
                total_ref_10: total_ref_10,
                total_ref_11: total_ref_11,
                total_ref_12: total_ref_12,
                total_ref_13: total_ref_13,
                total_ref_14: total_ref_14,
                total_ref_15: total_ref_15,
            }
        })
    }

    render() {
        const { loading } = this.state;
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true} />

                    <div className="container-fluid box">

                        <div className="row justify-content-between">
                            <div className="col-8">
                                <h4 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('balanceSheet:balance sheet')} </span>

                                        }
                                    </Translation>
                                    <a href="#balanceSheetChartsContainer"><img src={process.env.PUBLIC_URL + '/images/topMenuNavigation/accounting/piechart.png'} /></a>
                                </h4>


                            </div>

                            <div className="col text-right">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_balance_sheet_print' }) &&
                                    <ExportBalanceSheetAccount state={this.state} />
                                }
                            </div>
                        </div>

                        <Translation>
                            {t => 
                                <div className="content-inner">

                                    <div className="row mb-4">
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group">
                                                <select className="form-control dropdown-custom-arrow" name="period" id="period"
                                                    onChange={this.handleChange} value={this.state.period}>
                                                    <option value="month">{i18next.t("BalanceSheet:Monthly")}</option>
                                                    <option value="month3">{i18next.t("BalanceSheet:With")} 3 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="month6">{i18next.t("BalanceSheet:With")} 6 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="month9">{i18next.t("BalanceSheet:With")} 9 {i18next.t("BalanceSheet:Months")}</option>
                                                    <option value="year">{i18next.t("BalanceSheet:Yearly")}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="float-left mr-1">
                                                <DatePickerAdapter onChange={this.handleChange} className="form-control"
                                                    selected={this.state.selected_date} name="selected_date" />
                                            </div>
                                        </div>
                                        <div className="ml-n2">
                                            <button type="button" className="btn btn-primary form-control"
                                                name="search" onClick={() => this.calDate()}>{i18next.t("BalanceSheet:Search")}</button>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col balance-sheet">
                                            {loading ?
                                                <Loading />
                                                :
                                                <div className="table-responsive fade-up">
                                                    <table className="table table-borderless mb-5">
                                                        <BalanceSheetAccountHeader header_text={"สินทรัพย์ / Assets"}
                                                            state={this.state}
                                                            first_title={"สินทรัพย์หมุนเวียน / Current Assets"} />
                                                        <BalanceSheetAccountAssets state={this.state} />
                                                        <BalanceSheetAccountHeader
                                                            header_text={"หนี้สินหมุนเวียนและส่วนเจ้าของร่วม / Current Liabilities and Co-Owner's Equity"}
                                                            state={this.state}
                                                            first_title={"หนี้สินหมุนเวียน / Current Liabilities"} />
                                                        <BalanceSheetAccountCurrent state={this.state} />
                                                        <BalanceSheetAccountHeader
                                                            header_text={"ส่วนของเจ้าของร่วม / Co-Owner's Equity"}
                                                            state={this.state}
                                                            first_title={"ส่วนของเจ้าของร่วม / Co-Owner's Equity"} />
                                                        <BalanceSheetAccountCoOwner state={this.state} />
                                                    </table>
                                                    <BalanceSheetAccountCharts state={this.state} />
                                                </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </Translation>

                    </div>

                </WrapperContent>
            </Wrapper>
        )
    }
}

export default BalanceSheetAccount;