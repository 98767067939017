import React, { Component } from "react";
import DatePicker from "react-datepicker";
import './deposit-interest.scss'

class DatePickerNoti extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMonthDropdown: !props.showMonthYearPicker,
            showYearDropdown:!props.showMonthYearPicker,
            showMonthYearPicker: props.showMonthYearPicker,
            dateFormat: props.dateFormat ? props.dateFormat : "dd/MM/yyyy",
            disabled: props.disabled ? props.disabled : false,
            page:props.page === 'FineCreateModal' ? {zIndex:0} : {}
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.disable !== this.props.disable) {
            this.setState({disable: nextProps.disable})
        }
    }

    handleChange(value) {
        let event = {
            target: {
                name: this.props.name,
                value: value,
                className: this.props.className,
                dataset: {id: this.props['data-id'], key: this.props['data-key']},
            }
        };
        this.props.onChange(event);
    }
    render(){
        return(
            <div className="calendars-wrapper d-flex align-items-center">
                <DatePicker type="text" className="form-control float-left"
                            dateFormat={this.state.dateFormat}
                            name={this.props.name}
                            selected={this.props.selected}
                            onChange={this.handleChange}
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            showMonthDropdown={this.state.showMonthDropdown}
                            showYearDropdown={this.state.showYearDropdown}
                            showMonthYearPicker={this.state.showMonthYearPicker}
                            dropdownMode="select" required={this.props.required}
                            disabled={this.props.disabled}
                            autoComplete="off"
                            popperPlacement={this.props.popperPlacement}
                            selectsStart={this.props.selectsStart}
                            selectsEnd={this.props.selectsEnd}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                />
                <div className="calendars float-right">
                    {this.props.noti && <div className="noti-alert" />}
                    <img src={process.env.PUBLIC_URL + '/images/icons/date-pic.png'} alt="calendar" 
                    style={{height:this.props.height ? this.props.height : '24px',width: this.props.width ?this.props.width : '24px'}} className="mt-1" />
                </div>
            </div>
        );
    }



}

export default DatePickerNoti;