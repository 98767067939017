import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {

    render() {
        return (
            <Translation>
            {
              (t) =>(
                <div className="row" id="navigation-tab">
                    <div className="col">
                        <ul>
                            <li>
                                <NavLink to="/accounting/collection-law-firm/report/all" >
                                    {t('collectionLawFirm:All')}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/collection-law-firm/report/wait" >
                                  {t('collectionLawFirm:Payment Pending')}
                                {this.props.countFollow >= 1 && <p className='float-right font-weight-bold badge text-light bg-danger'>{this.props.countFollow}</p> }
                                
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/collection-law-firm/report/paid" >
                                    {t('collectionLawFirm:Paid')}  {this.props.countPaid >= 1 && <p className='float-right font-weight-bold badge text-light bg-danger'>{this.props.countPaid}</p> }
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            </Translation>
        )
    }
}

export default Navigation;