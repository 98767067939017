/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type generalInformationQueryVariables = {||};
export type generalInformationQueryResponse = {|
  +allJuristicCompany: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +address: ?string,
        +taxId: ?string,
        +website: ?string,
        +phone: ?string,
        +email: ?string,
        +facebook: ?string,
        +line: ?string,
        +logo: ?string,
        +description: ?string,
        +contactPerson: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: string,
              +phone: string,
              +email: string,
            |}
          |}>
        |},
        +documentation: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: string,
              +fileUpload: ?string,
              +fileUploadUrl: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type generalInformationQuery = {|
  variables: generalInformationQueryVariables,
  response: generalInformationQueryResponse,
|};
*/


/*
query generalInformationQuery {
  allJuristicCompany {
    edges {
      node {
        id
        name
        address
        taxId
        website
        phone
        email
        facebook
        line
        logo
        description
        contactPerson {
          edges {
            node {
              id
              name
              phone
              email
            }
          }
        }
        documentation {
          edges {
            node {
              id
              fileName
              fileUpload
              fileUploadUrl
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JuristicCompanyNodeConnection",
    "kind": "LinkedField",
    "name": "allJuristicCompany",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JuristicCompanyNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicCompanyNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "facebook",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "line",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactPersonCompanyNodeConnection",
                "kind": "LinkedField",
                "name": "contactPerson",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactPersonCompanyNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactPersonCompanyNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentationNodeConnection",
                "kind": "LinkedField",
                "name": "documentation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocumentationNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DocumentationNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUploadUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "generalInformationQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "generalInformationQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "5747c6df7855edfc596391b492f9991b",
    "id": null,
    "metadata": {},
    "name": "generalInformationQuery",
    "operationKind": "query",
    "text": "query generalInformationQuery {\n  allJuristicCompany {\n    edges {\n      node {\n        id\n        name\n        address\n        taxId\n        website\n        phone\n        email\n        facebook\n        line\n        logo\n        description\n        contactPerson {\n          edges {\n            node {\n              id\n              name\n              phone\n              email\n            }\n          }\n        }\n        documentation {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n              fileUploadUrl\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e82c759e759986b0ae9213d39de9e952';

module.exports = node;
