import React, { Component } from "react";
import numberWithComma from "../../../libs/numberWithComma";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import LightBox from "../../../libs/lightBox";
import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../../env/environment";
import { Translation } from "react-i18next";
const query = graphql`
    query otherExpensePayChannelQuery($invoiceId: String) {
            resultNotAutoReconcileLog (invoiceId: $invoiceId){
                resultCompare
                resultText
            }
        }
`;

class OtherExpensePayChannel extends Component {
    constructor(props) {
        super(props);

        //รับล่วงหน้า 2210 รายรับจากการปรับเศษทศนิยม 4300 ค่าธรรมเนียมธนาคาร 5400 เงินคืนอื่นๆ 5830 2230 เงินรอตรวจสอบ
        let otherCode = ["2210-01" , "2210-02" , "2210-03" , "4300-12"  , "5400-07" ,"5830-01","2230-01" , "2125-03"];
        this.state = {
            other_price: 0,
            otherCode : otherCode,
            payTransaction : {},
            resultCompare: 'True',
            resultText: ''
        }
    }

    isOther(code) {
        //รับล่วงหน้ารหัส 2210 จะผ่านทุกรหัส
        if(_.startsWith(code,'2210')){
            return true;
        }
        else if(_.indexOf(this.state.otherCode, code) >= 0) {
            return true;
        }
        
        return false;
    }

    componentDidMount(){
        {this.props.account_type === 'draft' &&
            this.cutString(this.props.payType)
        }
        if(this.props.receive_transactions){
            
            let variables={"invoiceId": this.props.receive_transactions[0].node.transaction.invoice.id?this.props.receive_transactions[0].node.transaction.invoice.id:'none'}
            fetchQuery(environment, query, variables).then(response_data => {
                if (response_data.resultNotAutoReconcileLog.resultCompare === 'False') {
                    let resultCode = response_data.resultNotAutoReconcileLog.resultText
                    let codes = resultCode.split("SE");
                    let resultText = ''
                    codes.forEach(element => {
                        if(element === '001') { resultText += 'SE001 ยอดเงินในสลิปต่ำกว่า 0 '}
                        if(element === '002') { resultText += 'SE002 ยอดเงินใบแจ้งหนี้และสลิปไม่เท่ากัน '}
                        if(element === '003') { resultText += 'SE003 วันที่ชำระเงินในสลิปเกิดก่อนวันที่ออกใบแจ้งหนี้ กรุณาสร้างรับล่วงหน้าก่อนตัดชำระ '}
                        if(element === '004') { resultText += 'SE004 วันที่ในสลิปและวันที่แจ้งชำระเงินไม่อยู่ในช่วงเดือนเดียวกัน '}
                        if(element === '005') { resultText += 'SE005 วันที่ในสลิปและวันที่ออกใบแจ้งหนี้ไม่อยู่ในช่วงเดือนเดียวกัน '}
                        if(element === '006') { resultText += 'SE006 วันที่ใบแจ้งหนี้และวันที่ที่ลูกบ้านแจ้งชำระมาในแอปพลิเคชั่นไม่อยู่ในช่วงเดือนเดียวกัน ' }
                        if(element === '007') { resultText += 'SE007 ชื่อบัญชีหรือเลขบัญชีไม่ตรงกับในระบบ ' }
                        if(element === '008') { resultText += 'SE008 สลิปที่แนบซ้ำกับในระบบ '}
                        if(element === '009') { resultText += 'SE009 อื่นๆ '}
                    });
                    
                    this.setState({resultCompare: 'False', resultText: resultText})
                }
            });
        }
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props) {
            this.setState({ other_price: 0 }, () => {
                this.calOtherPrice()
            });
        }
    }

    calOtherPrice() {
        let sum_bank = this.props.bankTransaction.reduce(function (total, obj) {
            return total + parseFloat(obj.node.price)
        }, 0);
        let sum_cash = this.props.cashTransaction.reduce(function (total, obj) {
            return total + parseFloat(obj.node.price)
        }, 0);
        let sum_cheque = this.props.chequeTransaction.reduce(function (total, obj) {
            return total + parseFloat(obj.node.price)
        }, 0);
        let sum_credit_card = 0;
        if (this.props.creditCardTransaction && this.props.creditCardTransaction.length > 0) {
            sum_credit_card = this.props.creditCardTransaction.reduce(function (total, obj) {
                return total + parseFloat(obj.node.amount)
            }, 0);
        }

        let sum_pay = sum_bank + sum_cash + sum_cheque + sum_credit_card;

        this.setState({ other_price: Math.abs(this.props.total - sum_pay) });
    }

    cutString(payType){
        let payTransactionBank = []
        let payTransactionCash = []
        let payTransactionCheque = []
        let payTransactionOther = []
        let total = 0

        payType.forEach((value) => {
            if(RegExp('ธนาคาร').test(value)){
                if(RegExp('เลขที่เช็ค').test(value)){
                    let transaction = value.split(",")
                    payTransactionCheque.push({type : 'cheque', bankName : transaction[0].split(":")[1].replace("ธนาคารธนาคาร","ธนาคาร") , bankAccount : transaction[1].split(":")[1],chequeNumber : transaction[2].split(":")[1], price : transaction[3].split(":")[1].replace("}","")})
                }else{
                    let transaction = value.split(",")
                    payTransactionBank.push({type: 'bank' , bankName : transaction[0].split(":")[1].replace("ธนาคารธนาคาร","ธนาคาร") , bankAccount : transaction[1].split(":")[1] , price : transaction[2].split(":")[1].replace("}","")})
                }
            }else if(RegExp('เงินสด').test(value)){
                let transaction = value.split(",")
                total += parseFloat(transaction[1].split(":")[1].replace("}",""))
            }else{
                let transaction = value.split(",")
                payTransactionOther.push({type : 'other' ,detail : transaction[0].split(":")[1], price : transaction[1].split(":")[1].replace("}","")})
            }
        })
        total !== 0 && payTransactionCash.push({type : 'cash', price : total})
        this.setState({
            payTransaction:{payTransactionBank,payTransactionCheque,payTransactionOther,payTransactionCash},
        })
    }

    render() {
        
        return (
            <div className={this.props.clear_guarantee ? "" : "card mt-3"}>
                <div className={this.props.clear_guarantee ? "" : "card-body"}>
                    <div className="row">
                        {this.props.account_type !== "draft" &&
                            <div className="col">
                            {!this.props.clear_guarantee &&
                                <Translation>{t=><p><strong>{this.props.account_type === 'pay' ? t("createReceive:Paid by") : t("createReceive:Received_by")}</strong></p>}</Translation>
                            }
                            {
                                this.props.cashTransaction.length !== 0 &&
                                <Translation>
                                    {t=>
                                    <p>เงินสด/Cash {numberWithComma(this.props.cashTransaction.reduce(function (total, obj) {
                                        return total + parseFloat(obj.node.price)
                                    }, 0))} {t("createReceive:Baht")}</p>}
                                </Translation>
                            }

                            {
                                this.props.bankTransaction.length !== 0 &&
                                <React.Fragment>
                                    <p>เงินโอน/Bank Transfer</p>
                                    {
                                        this.props.bankTransaction.map((bank) => (
                                            <React.Fragment key={bank.node.id}>
                                                <Translation>
                                                    {t=>
                                                    <p>-
                                                        {t("createReceive:Bank")}&nbsp;&nbsp;{bank.node.bankAccount.bankName}
                                                        &emsp;{t("createReceive:Account No.")}&nbsp;&nbsp;{bank.node.bankAccount.accountNumber}
                                                        &emsp;{t("createReceive:date")}&nbsp;&nbsp;{format(bank.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                        &emsp;{t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(bank.node.price)} {t("createReceive:Baht")}</p>}
                                                </Translation>

                                                <div className="row">
                                                    {
                                                        bank.node.imageSlip &&
                                                        <div className="p-2">
                                                            <LightBox image={bank.node.imageSlip} />
                                                        </div>
                                                    }
                                                    {
                                                        bank.node.imageSlipAdditional &&
                                                        <div className="p-2">
                                                            <LightBox image={bank.node.imageSlipAdditional} />
                                                        </div>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </React.Fragment>
                            }
                            {this.props.account_type === 'pay' && this.props.chequeTransaction.length !== 0 &&
                                <React.Fragment>
                                    <p>เช็คธนาคาร/Cheque Bank</p>
                                    {
                                        this.props.chequeTransaction.map((cheque) => (
                                            <Translation>
                                                {t=>
                                                <p key={cheque.node.id}>- {t("createReceive:Cheque No.")}&nbsp;&nbsp;{cheque.node.chequeNumber}
                                                &emsp;{t("createReceive:Bank")}&nbsp;&nbsp;{cheque.node.bankAccount.bankName}
                                                &emsp;{t("createReceive:Branch")}&nbsp;&nbsp;{cheque.node.bankAccount.branch}
                                                &emsp;{t("createReceive:date")}&nbsp;&nbsp;{format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                &emsp;{t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {t("createReceive:Baht")}</p>}
                                            </Translation>

                                        ))
                                    }
                                </React.Fragment>
                            }

                            {this.props.account_type === 'pay' && this.props.paymentChannel &&this.props.paymentChannel?.length !== 0 &&
                                <React.Fragment>
                                    
                                    {   
                                        this.props.paymentChannel?.map((cash) => { 
                                            if(cash.node.setPettyCash === null && cash.node.rdCash === null) {}
                                            else if(cash.node.setPettyCash === null) 
                                            {   
                                                return (
                                                    <>
                                                        <p>เงินรับล่วงหน้า/Receipt Deposit Cash</p>
                                                        <p key={cash.node.id}>- เลขที่&nbsp;&nbsp;{cash.node.rdCash.docNumber}
                                                        &emsp;วันที่&nbsp;&nbsp;{format(cash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                        &emsp;จำนวนเงิน&nbsp;&nbsp;{numberWithComma(cash.node.price)} บาท</p>
                                                    </>
                                                )
                                            }
                                            else 
                                            {
                                                return (
                                                    <>
                                                    <p>เงินสดย่อย/Petty Cash</p>
                                                    <p key={cash.node.id}>- เลขที่&nbsp;&nbsp;{cash.node.setPettyCash.docNumber}
                                                    &emsp;วันที่&nbsp;&nbsp;{format(cash.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                    &emsp;จำนวนเงิน&nbsp;&nbsp;{numberWithComma(cash.node.price)} บาท</p>
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                </React.Fragment>
                            }

                            {this.props.account_type === 'receive' && this.props.chequeTransaction.length !== 0 &&
                                <React.Fragment>
                                    <p>เช็คธนาคาร/Cheque Bank</p>
                                    {
                                        this.props.chequeTransaction.map((cheque) => (
                                            <React.Fragment key={cheque.node.id}>
                                                <Translation>
                                                    {t=>
                                                    <p>- {t("createReceive:Cheque No.")}&nbsp;&nbsp;{cheque.node.chequeNumber}
                                                    &emsp;{cheque.node.bankName}
                                                    &emsp;{t("createReceive:Branch")}&nbsp;&nbsp;{cheque.node.branchNumber}
                                                    &emsp;{t("createReceive:date")}&nbsp;&nbsp;{format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                    &emsp;{t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {t("createReceive:Baht")}</p>}
                                                </Translation>
                                                {cheque.node.chequeImage &&
                                                    <div className="row">
                                                        <div className="p-2">
                                                            <LightBox image={cheque.node.chequeImage} />
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </React.Fragment>
                            }
                            
                            
                            
                            {this.props.allAccountRecordGroup && this.props.account_type === "pay" &&
                                <React.Fragment>
                                    {
                                        this.props.allAccountRecordGroup.map((Other) => (
                                            Other.node.accountRecord.edges.map((accountRecord) => {
                                                if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                                                    (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07" && !this.props.oe540007)
                                                    ){
                                                        let name = accountRecord.node.chartOfAccountCode.name
                                                        if(accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" && accountRecord.node.debit > 0){
                                                            name = name.replace("รายรับ","รายจ่าย")
                                                        }
                                                        return (
                                                            <p key={accountRecord.node.id}>{name} {numberWithComma(accountRecord.node.credit > 0 ?
                                                                accountRecord.node.credit : accountRecord.node.debit)} บาท</p>
                                                        )
                                                        

                                                }
                                                else{
                                                    return null;
                                                }
                                            })

                                        )
                                        )
                                    }
                                </React.Fragment>
                            }



                        </div>
                        }
                    </div>
                    {this.state.resultCompare === 'False' &&
                        <p><br></br>*** <strong>{this.state.resultText}</strong></p>
                    }
                    
                </div>  
            </div>
        )
    }
}

export default OtherExpensePayChannel