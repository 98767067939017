import React, {Component} from 'react';
import ReactQuillAdapter from "../../libs/reactQuillAdapter";
import 'react-quill/dist/quill.snow.css';
import DatePickerAdapter from "../../libs/datePickerAdapter";
import "./styles/survey.scss"
import UploadImageInput from "../../libs/uploadImageInput";
import {commitMutation} from "react-relay";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import {graphql} from "babel-plugin-relay/macro";
import {Link} from "react-router-dom";
import {Redirect} from 'react-router';
import {Translation} from "react-i18next";
import {parse, format} from "date-fns";
import DeleteSurveyQuestion from "./deleteSurveyQuestion";
import DeleteSurveyChoice from "./deleteSurveyChoice";
import {fetchQuery} from "relay-runtime";
import i18next from 'i18next';

const _ = require('lodash');

const mutation = graphql`
    mutation surveyCreateUpdateFormMutation($input: CreateSurveyInput!){
        createSurvey(input: $input){
            ok
        }
    }`
;

const query = graphql`
    query surveyCreateUpdateFormQuery($id: ID!) {
        survey(id: $id){
            id
            title
            detail
            previewImage
            status
            postDate
            dueDate
            surveyQuestion{
                edges{
                    node{
                        id
                        title
                        type
                        questionChoice{
                            edges{
                                node{
                                    id
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const question = {
    id: '',
    title: '',
    type: '',
    questionChoice: {
        edges: []
    },
};

const choice = {
    node: {
        id: '',
        title: '',
    }
};

class SurveyCreateUpdateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            detail: '',
            title: '',
            dueDate: new Date(),
            today: new Date(),
            previewImage: false,
            upload: {
                image: false,
            },
            status: "draft",
            survey_question_answer: [],
            redirectToList: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.setSurveyQuestion = this.setSurveyQuestion.bind(this);
        this.resetPreviewImage = this.resetPreviewImage.bind(this);
    }

    componentWillMount() {
        if (this.props.survey_id) {
            fetchQuery(environment, query, {id: this.props.survey_id}).then((data) => {
                if (_.isObject(data.survey)) {
                    let survey_question = [];
                    data.survey.surveyQuestion.edges.forEach((question) => {
                        let question_new = _.cloneDeep(question.node);
                        survey_question = [...survey_question, question_new];
                    });
                    this.setState({
                        id: data.survey.id,
                        title: data.survey.title,
                        detail: data.survey.detail,
                        dueDate: parse(data.survey.dueDate),
                        previewImage: data.survey.previewImage,
                        status: data.survey.status,
                        survey_question_answer: survey_question,
                    });
                }
            });
        }
    }

    addQuestion(type) {
        let survey_question = {...question};
        survey_question.type = type;
        this.setState({
            survey_question_answer: [...this.state.survey_question_answer, {...survey_question}]
        });
    }

    addAnswer(index_question) {
        let choice_object = _.cloneDeep(choice);
        let question_answer = [...this.state.survey_question_answer[index_question].questionChoice.edges, choice_object];

        let name_field = "survey_question_answer[" + index_question + "].questionChoice.edges";
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                name_field,
                question_answer
            );
        });
    }

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                event.target.name,
                value
            );
        });
    }

    validateQuestionChoiceEmpty() {
        let not_empty = true;
        if (this.state.detail === "") {
            // เช็คเนื้อหาว่าง
            not_empty = false;
            return { status: not_empty, type: 'detail', message: i18next.t('survey:please_enter_content')}
        } else if (_.isEmpty(this.state.survey_question_answer)) {
            not_empty = false;
            return { status: not_empty, type: 'question', message: i18next.t('survey:please_enter_question')}
        } else {
            this.state.survey_question_answer.forEach((question) => {
                if (_.isEmpty(question.questionChoice.edges)) {
                    not_empty = false;
                }
            });
            return { status: not_empty, type: 'choice', message: i18next.t('survey:please_enter_choice')}
        }
    }

    onSubmit(e) {
        e.preventDefault();
        let validate = this.validateQuestionChoiceEmpty();
        if (validate.status) {
            this.setState({loading: true});
            let uploadables = this.state.upload;
            let survey = {
                id: this.state.id,
                detail: this.state.detail,
                title: this.state.title,
                dueDate: format(this.state.dueDate, 'YYYY-MM-DD'),
                status: this.state.status,
            };

            let variables = {
                input: {
                    survey: JSON.stringify(survey),
                    surveyQuestionAnswer: JSON.stringify(this.state.survey_question_answer),
                }
            };

            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        this.setState({loading: false});
                        if (response.createSurvey.ok) {
                            Swal.fire(i18next.t('survey:created_success'), '', 'success').then(() => {
                                this.setState({redirectToList: true});
                            });
                        } else {
                            Swal.fire(i18next.t('survey:fail_to_create'), i18next.t('survey:check_your_internet'), 'warning');
                        }
                    },
                    onError: (err) => {
                        this.setState({loading: false});
                        Swal.fire('Error!', i18next.t('survey:try_again'), 'warning')
                    },
                },
            )
        } else {
            Swal.fire(i18next.t('survey:fail_to_create'), validate.message, 'warning');
        }
    }

    setSurveyQuestion(survey_question) {
        this.setState({
            survey_question_answer: survey_question
        });
    }

    resetPreviewImage(){
        this.setState(prevState => {
            return _.set(_.cloneDeep(prevState), "previewImage", "");
        });
        this.setState(prevState => {
            return _.set(_.cloneDeep(prevState), "upload.image", "");
        });
    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to="/contact/survey"/>
        }
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className="row justify-content-between">
                        <div className="col">
                            <h3>
                                <Link to="/contact/survey">
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                         alt="back" className="back"/>
                                </Link>
                                <Translation>
                                    {
                                        t => <span>{t('contact_menu:add_survey')}</span>
                                    }
                                </Translation>
                            </h3>
                        </div>

                        <div className="col">
                            {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_approve'}) &&
                            <button type="submit" onClick={() => this.setState({status: "publish"})}
                                    disabled={this.state.loading}
                                    className="btn btn-primary add float-right">
                                {this.state.loading &&
                                <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                <Translation>
                                    {
                                        t => <span>{t('contact_menu:post_now')}</span>
                                    }
                                </Translation>
                            </button>
                            }

                            {((this.props.survey_id && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_edit'})) || ( !this.props.survey_id && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_create'}))) &&
                            <React.Fragment>
                                <button type="submit" className="add btn btn-outline-primary float-right mr-3"
                                        disabled={this.state.loading}>
                                    {this.state.loading &&
                                    <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                    <Translation>
                                        {
                                            t => <span>{t('contact_menu:save_draft')}</span>
                                        }
                                    </Translation>
                                </button>
                                <Link to="/contact/survey">
                                    <button type="button" className="btn btn-outline-secondary float-right add mr-3"
                                            disabled={this.state.loading}>
                                        {/*{this.state.loading &&*/}
                                        {/*<span className="spinner-border spinner-border-sm align-middle mr-2"/>}*/}
                                        <Translation>
                                            {
                                                t => <span>{t('contact_menu:cancel')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                            </React.Fragment>
                            }

                        </div>
                    </div>

                    <div className="content-inner mt-4">
                        <Translation>
                            {t=>                        
                        <div className="row">
                            <div className="col-md-7 col-xl-6">
                                {/*<ul className="switch-languages">*/}
                                {/*    <li className="active">TH</li>*/}
                                {/*    <li>EN</li>*/}
                                {/*</ul>*/}
                                <div className="card p-4">
                                    <div className="row">
                                        <div className="col">
                                            <h6>{t('survey:topic')}</h6>
                                            <input type="text" name="title" className="form-control"
                                                   value={this.state.title} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col">
                                            <h6>{t('survey:voting_end')}</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-inline">
                                                <DatePickerAdapter id="start_date"
                                                className="form-control" name="dueDate"
                                                selected={this.state.dueDate}
                                                minDate={this.state.today}
                                                onChange={this.handleInputChange}
                                                dateFormat="d/MM/yyyy"/>
                                            </div>
                                        </div>
                                        {/*<div className="col">*/}
                                        {/*    <div className="form-inline float-right">*/}
                                        {/*        <h6 className="pr-3 mt-2">สำหรับคณะกรรมการเท่านั้น</h6>*/}
                                        {/*        <label className="switch">*/}
                                        {/*            <input type="checkbox" defaultChecked/>*/}
                                        {/*            <span className="slider round"/>*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col">
                                                    <h6>{t('survey:title_image')} <span className="ml-2 color thin red-color">{t('survey:title_image_description')}</span></h6>
                                            {this.state.previewImage ?
                                                    <React.Fragment>

                                                            <img src={this.state.previewImage} alt="news"
                                                                 className="img-fluid card-img"/>
                                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'contact_survey_edit'}) &&
                                                        <div className="change-preview-image"
                                                                onClick={this.resetPreviewImage}>{t('survey:change_image')}
                                                        </div>
                                                        }

                                                    </React.Fragment>
                                                    :
                                                    <div className="btn card-wrapper border-wrapper">
                                                        <label htmlFor="uploadSurveyImages">
                                                            <div className="card-body cursor">
                                                                <div className="col text-center">
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + '/images/icons/select-photo.png'}
                                                                        alt="select-icon"
                                                                    />
                                                                </div>
                                                                <div className="text-center mt-4">
                                                                    <div className="upload-file">
                                                                        <div>
                                                                            {t('survey:click_hear')}
                                                                        </div>
                                                                        <div className="upload-input">
                                                                            <UploadImageInput
                                                                                id="uploadSurveyImages"
                                                                                setField="upload.image:previewImage"
                                                                                onChange={this.handleInputChange}
                                                                                required={true}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col">
                                            <h6>{t('survey:content')}</h6>
                                            <ReactQuillAdapter name="detail" value={this.state.detail}
                                                               onChange={this.handleInputChange}/>
                                        </div>
                                    </div>
                                </div>

                                {/*คำตอบเดียว*/}
                                {this.state.survey_question_answer.map((question, index_question) =>
                                    <div className="row mt-4" key={index_question}>
                                        <div className="col">
                                            <div className="card p-4">
                                                {/*question start*/}
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6>{t('survey:question')} #{index_question + 1}</h6>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <DeleteSurveyQuestion survey_question={question}
                                                                              survey_question_list={this.state.survey_question_answer}
                                                                              setSurveyQuestion={this.setSurveyQuestion}/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <input type="text"
                                                               name={"survey_question_answer[" + index_question + "].title"}
                                                               value={this.state.survey_question_answer[index_question].title}
                                                               className="form-control" required
                                                               onChange={this.handleInputChange}/>
                                                    </div>
                                                </div>
                                                {/*question end*/}

                                                {/*choice start*/}
                                                {question.questionChoice.edges.map((questionChoice, index_choice) =>
                                                    <div className="row mt-4" key={index_choice}>
                                                        <div className="col-md-1 mt-2 pl-4">
                                                            <div
                                                                className={this.state.survey_question_answer[index_question].type === "radio" ? "custom-control custom-radio custom-control-inline" : "custom-control custom-checkbox"}>
                                                                <input
                                                                    type={this.state.survey_question_answer[index_question].type === "radio" ? "radio" : "checkbox"}
                                                                    // name={"survey_question_answer[" + index_question + "].questionChoice[" + index_choice + "].title"}
                                                                    className="custom-control-input" disabled={true}
                                                                    id={this.state.survey_question_answer[index_question].type === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}
                                                                />
                                                                <label className="custom-control-label"
                                                                       htmlFor={this.state.survey_question_answer[index_question].type === "radio" ? "question" + index_question : "question" + index_question + "questionChoice" + index_choice}/>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-9">
                                                            <input type="text" className="form-control"
                                                                   name={"survey_question_answer[" + index_question + "].questionChoice.edges[" + index_choice + "].node.title"}
                                                                   value={this.state.survey_question_answer[index_question].questionChoice.edges[index_choice].node.title}
                                                                   required
                                                                   onChange={this.handleInputChange}
                                                                   placeholder={t('survey:choice')+' ' + (index_choice + 1)}/>
                                                        </div>

                                                        <div className="col-md-1 mt-2">
                                                            <DeleteSurveyChoice survey_question={question}
                                                                                survey_question_list={this.state.survey_question_answer}
                                                                                setSurveyQuestion={this.setSurveyQuestion}
                                                                                survey_choice={questionChoice}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {/*choice end*/}
                                                <div className="row mt-2">
                                                    <div className="col-md-1"/>
                                                    <div className="col-md-10">
                                                        <span className="blue-color cursor"
                                                              onClick={() => this.addAnswer(index_question)}>+ {t('survey:add_choice')}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/*คำตอบเดียว*/}

                                <div className="row mt-4">
                                    <div className="col">
                                        <button type="button" className="btn btn-light btn-add-question"
                                                id="dropdownMenuButton" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <h6 className="pt-2">+ {t('survey:add_question')}</h6>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <span className="dropdown-item cursor"
                                                  onClick={() => this.addQuestion("radio")}>
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/icons/single-question-icon.png'}
                                                    alt="single-question-icon"/>
                                                <span className="ml-3">{t('survey:only_answer')}</span>
                                            </span>

                                            <div className="dropdown-divider"/>

                                            <span className="dropdown-item"
                                                  onClick={() => this.addQuestion("choice")}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/icons/many-question-icon.png'}
                                                        alt="many-question-icon cursor"/>
                                                    <span className="ml-3">{t('survey:multiple_answer')}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <h6 className="text-center">{t('survey:display_ex')}</h6>
                                <div className="phone-preview">
                                    <div className="inner">
                                        <h4>{this.state.title}</h4>
                                        {this.state.previewImage &&
                                        <img src={this.state.previewImage} alt="news"
                                             className="img-fluid mt-2 mb-2"/>
                                        }
                                        <div className="content"
                                             dangerouslySetInnerHTML={{__html: this.state.detail}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        </Translation>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default SurveyCreateUpdateForm;
