import React, { Component } from "react";
import Wrapper from "../../../components/wrapper";
import Sidebar from "../../../components/sidebar";
import Header from "../../../components/header";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import ContractExpenseTable from "./contractExpenseTable";
import ExportContractExpense from "./exportContractExpense";
import BackButtonIcon from "../../../components/BackBtn/indexBack";
import "./style/contractExpense.scss";
import i18next from "i18next";

const query = graphql`
  query contractExpenseListQuery(
    $status: String
    $search: String
    $startDate: Date
    $endDate: Date
    $first: Int
    $last: Int
  ) {
    allContractExpense(
      status: $status
      search: $search
      startDate: $startDate
      endDate: $endDate
      first: $first
      last: $last
    ) {
      edges {
        node {
          id
          docNumber
          status
          issuedDate
          renewer
          renewDate
          voidRemark
          voidDate
          voider
          added
          purchaseOrderes {
            id
            docNumber
            issuedDate
            status
            approver
            procurementForm {
              id
              docNumber
              issuedDate
              creditor {
                refNumber
                name
              }
              chartOfAccount {
                id
                name
                nameEn
                chartOfAccountCode
              }
              startContractDate
              endContractDate
              total
            }
          }
        }
      }
      totalCount
    }
  }
`;
export default class contractExpenseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      status: "all",
      query: query,
    };
    this.handleCallback = this.handleCallback.bind(this);
  }

  handleCallback(status, startDate, endDate) {
    this.setState({
      status: status,
      startDate: startDate,
      endDate: endDate,
    })
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="contractExpenseList">
            <Translation>
              {t =>
                <React.Fragment>
                  <BackButtonIcon
                    LinkBack={"/accounting"}
                    LinkText={i18next.t("contract_expese_list:Expense contract")+ " "+"(CX)"}
                    printdocLinkChk={true}
                    btnCustomExportIs={true}
                    exportFileProps={
                      <ExportContractExpense
                        query={query}
                        status={this.state.status}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                      />
                    }
                  />

                  <div className="content-inner">
                    <ContractExpenseTable status={this.props.match.params.status} parentCallback={this.handleCallback} />
                  </div>
                </React.Fragment>
              }
            </Translation>
          </div>
        </div>
      </Wrapper>
    );
  }
}
