
import React, { Component } from 'react';
import './silvermanAgent.scss'

class SilvermanAgentStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            step1 : true
        }
    }

    render() {
        return (
            <div className="svm-step-container">
                <section class="step-indicator">
                        <div>
                            {
                                this.props.svmAgentStepFinish ?  
                                <img style={{width:'32px'}} src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/step1-active.png`} /> : 
                                <img style={{width:'32px'}} src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/step1.png`} />
                            }
                           
                        </div>
                        <div className='indicator-line'></div>
                        <div>
                            {
                                this.props.svmAgentStepFinish2 ?  
                                <img style={{width:'32px'}} src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/step2-active.png`} /> : 
                                <img style={{width:'32px'}} src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/step2.png`} />
                            }
                        </div>
                        <div className='indicator-line'></div>
                        <div>
                            {
                                this.props.svmAgentStepFinish3 ?  
                                <img style={{width:'32px'}} src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/step3-active.png`} /> : 
                                <img style={{width:'32px'}} src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/step3.png`} />
                            }
                        </div>
                </section>
                <section className="step-indicator">
                        <div>
                            {
                                this.props.svmAgentStepFinish ? <p style={{color:'#FFBD2D'}}>{this.props.lang === 'en' ? 'Location': 'ทำเล'}</p> : <p style={{color:'#E2E2E2'}}>{this.props.lang === 'en' ? 'Location': 'ทำเล'}</p>
                            }
                        </div>
                        <div className='indicator'></div>
                        <div>
                            {
                                this.props.svmAgentStepFinish2 ? <p style={{color:'#1578D7'}}>{this.props.lang === 'en' ? 'Detail': 'รายละเอียด'}</p> : <p style={{color:'#E2E2E2'}}>{this.props.lang === 'en' ? 'Detail': 'รายละเอียด'}</p>
                            }
                        </div>
                        <div className='indicator'></div>
                        <div>
                            {
                                this.props.svmAgentStepFinish3 ? <p style={{color:'#68DD6E'}}>{this.props.lang === 'en' ? 'Price': 'ราคา'}</p> : <p style={{color:'#E2E2E2'}}>{this.props.lang === 'en' ? 'Price': 'ราคา'}</p>
                            }
                        </div>
                </section>
            </div>
        )
    }
}

export default SilvermanAgentStep;