/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ContactTypeOfPayment = "PAYABLE" | "RECEIVABLE" | "%future added value";
export type wrapperContactAllSelectQueryVariables = {|
  search?: ?string
|};
export type wrapperContactAllSelectQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +taxIdNumber: ?string,
    +juristicContactNumber: ?string,
    +logo: ?string,
  |},
  +contactViewer: ?{|
    +id: string,
    +allContact: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +mainEmail: ?string,
          +email: ?string,
          +typeOfPayment: ContactTypeOfPayment,
          +typeOfContact: ContactTypeOfContact,
          +totalAmount: number,
          +residential: ?{|
            +id: string,
            +name: string,
          |},
          +firstName: string,
          +lastName: string,
          +mainPhone: string,
          +phone: string,
          +firstNameRenter: ?string,
          +lastNameRenter: ?string,
          +emailRenter: ?string,
          +phoneRenter: ?string,
          +firstNameAgent: string,
          +lastNameAgent: string,
          +emailAgent: ?string,
          +phoneAgent: string,
          +isActive: boolean,
          +mailingAddress: string,
          +mailingCity: string,
          +mailingCountry: string,
          +mailingDistrict: string,
          +mailingName: string,
          +mailingPostalCode: string,
          +mailingProvince: string,
          +registeredName: string,
          +registeredAddress: string,
          +registeredCountry: string,
          +registeredProvince: string,
          +registeredCity: string,
          +registeredDistrict: string,
          +registeredPostalCode: string,
          +renterAddress: ?string,
          +renterCountry: string,
          +renterProvince: string,
          +renterDistrict: string,
          +renterCity: string,
          +renterPostalCode: string,
          +agentAddress: ?string,
          +agentCountry: string,
          +agentProvince: string,
          +agentDistrict: string,
          +agentCity: string,
          +agentPostalCode: string,
        |}
      |}>,
    |},
  |},
|};
export type wrapperContactAllSelectQuery = {|
  variables: wrapperContactAllSelectQueryVariables,
  response: wrapperContactAllSelectQueryResponse,
|};
*/


/*
query wrapperContactAllSelectQuery(
  $search: String
) {
  selfProject {
    id
    name
    address
    taxIdNumber
    juristicContactNumber
    logo
  }
  contactViewer {
    id
    allContact(search: $search, order: "ref_number") {
      totalCount
      edges {
        node {
          id
          refNumber
          name
          mainEmail
          email
          typeOfPayment
          typeOfContact
          totalAmount
          residential {
            id
            name
          }
          firstName
          lastName
          mainPhone
          phone
          firstNameRenter
          lastNameRenter
          emailRenter
          phoneRenter
          firstNameAgent
          lastNameAgent
          emailAgent
          phoneAgent
          isActive
          mailingAddress
          mailingCity
          mailingCountry
          mailingDistrict
          mailingName
          mailingPostalCode
          mailingProvince
          registeredName
          registeredAddress
          registeredCountry
          registeredProvince
          registeredCity
          registeredDistrict
          registeredPostalCode
          renterAddress
          renterCountry
          renterProvince
          renterDistrict
          renterCity
          renterPostalCode
          agentAddress
          agentCountry
          agentProvince
          agentDistrict
          agentCity
          agentPostalCode
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxIdNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "juristicContactNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactViewer",
    "kind": "LinkedField",
    "name": "contactViewer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": "ref_number"
          },
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "search"
          }
        ],
        "concreteType": "ContactNodeConnection",
        "kind": "LinkedField",
        "name": "allContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mainEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfPayment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typeOfContact",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResidentialNode",
                    "kind": "LinkedField",
                    "name": "residential",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mainPhone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstNameRenter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastNameRenter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailRenter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneRenter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstNameAgent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastNameAgent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailAgent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneAgent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingCountry",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mailingProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredCountry",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterCountry",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renterPostalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentCountry",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentProvince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentDistrict",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "agentPostalCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wrapperContactAllSelectQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wrapperContactAllSelectQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "630a876426c1feb01d613348a5f6bf6a",
    "id": null,
    "metadata": {},
    "name": "wrapperContactAllSelectQuery",
    "operationKind": "query",
    "text": "query wrapperContactAllSelectQuery(\n  $search: String\n) {\n  selfProject {\n    id\n    name\n    address\n    taxIdNumber\n    juristicContactNumber\n    logo\n  }\n  contactViewer {\n    id\n    allContact(search: $search, order: \"ref_number\") {\n      totalCount\n      edges {\n        node {\n          id\n          refNumber\n          name\n          mainEmail\n          email\n          typeOfPayment\n          typeOfContact\n          totalAmount\n          residential {\n            id\n            name\n          }\n          firstName\n          lastName\n          mainPhone\n          phone\n          firstNameRenter\n          lastNameRenter\n          emailRenter\n          phoneRenter\n          firstNameAgent\n          lastNameAgent\n          emailAgent\n          phoneAgent\n          isActive\n          mailingAddress\n          mailingCity\n          mailingCountry\n          mailingDistrict\n          mailingName\n          mailingPostalCode\n          mailingProvince\n          registeredName\n          registeredAddress\n          registeredCountry\n          registeredProvince\n          registeredCity\n          registeredDistrict\n          registeredPostalCode\n          renterAddress\n          renterCountry\n          renterProvince\n          renterDistrict\n          renterCity\n          renterPostalCode\n          agentAddress\n          agentCountry\n          agentProvince\n          agentDistrict\n          agentCity\n          agentPostalCode\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '550b9b335deaa3c43192402e6816a556';

module.exports = node;
