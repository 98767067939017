import React, {Component} from "react";
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import Loading from '../../../../libs/loading';
import { QueryRenderer } from "react-relay";
import OtherReceiveReportPDFRenderTable from "./otherReceiveReportPDFRenderTable";
import './otherReceiveReportPDF.scss';

const query = graphql`
  query otherReceiveReportPDFQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $status: String
    $order: String
    $customer_type: String
    $payment_channel: String
    $contact: String
    $productAndService: String
  ) {
    allOtherReceive(
      first: $first
      last: $last
      startDate: $start_date
      endDate: $end_date
      status: $status
      search: $search
      order: $order
      customerType: $customer_type
      paymentChannel: $payment_channel
      productAndService: $productAndService
      contact: $contact
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          total
          status
          payGroup
          contact {
            name
            refNumber
            firstName
            lastName
            typeOfContact
            residential {
              name
            }
          }
          unknownContact
          otherReceiveTransaction {
            totalCount
            edges {
              node {
                id
                description
                total
                accountRecord{
                  id
                }
                productAndService{
                  productCode
                }
                chartOfAccount{
                  chartOfAccountCode
                }
              }
            }
          }
          cashTransaction {
            edges {
              node {
                price
              }
            }
            totalCount
          }
          bankAccountTransaction {
            edges {
              node {
                price
              }
            }
            totalCount
          }
          chequeDeposit {
            edges {
              node {
                price
              }
            }
            totalCount
          }
          creditCardTransaction {
            edges {
              node {
                amount
              }
            }
            totalCount
          }
          receiptDeposit {
            edges {
              node {
                id
                docNumber
                total
                name
                receiptDepositTransaction {
                  edges {
                    node {
                      chartOfAccount {
                        chartOfAccountCode
                        name
                      }
                    }
                  }
                }
              }
            }

          }
          unknownReceive {
            edges {
              node {
                amount
              }
            }
            
          }

          feeAccounting {
            edges {
              node {
                amount
              }
            }
          }
          
        }
      }
    }
    allOtherReceiveReport{
      accountRecordGroupNode{
        edges {
            node {
              id
              refNumber
              refTransaction
              name
              added
              issuedDate
              category
              totalDebit
              totalCredit
              refTransaction
              creator
              contact {
                  id
                  name
                  firstName
                  lastName
              }
              accountRecord {
                edges {
                  node {
                    id
                    name
                    debit
                    credit
                    chartOfAccountCode {
                    id
                    name
                    chartOfAccountCode
                    }
                  }
                }
              }
            }
          }
      }
  }
    summaryOtherReceive(
      startDate: $start_date
      endDate: $end_date
      status: $status
      search: $search
      customerType: $customer_type
      paymentChannel: $payment_channel
      contact: $contact
      productAndService: $productAndService
      report: true
    )
  }
`;

class OtherReceiveReportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data,
        })
    }

    render() {
        return (
            <React.Fragment>
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }

                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Other Receipt</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div id="otherReceiveReportPDF">
                            <div className="print-top-menu">
                                    <div className="logo">
                                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                    </div>
                                    <div className="print" onClick={() => window.print()}>
                                        PRINT
                                    </div>
                            </div>                      
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                cacheConfig={{ use_cache: false }}
                                variables={{
                                    first: null,
                                    last: null,
                                    start_date: this.state.data.start_date,
                                    end_date: this.state.data.end_date,
                                    search: this.state.data.search,
                                    status: "",
                                    order: "doc_number",
                                    customer_type: this.state.data.customer_type,
                                    payment_channel: this.state.data.payment_channel,
                                    contact: this.state.data.contact,
                                    productAndService: this.state.data.product_and_service,
                                }}
                                render={({ error, props }) => {
                                if (props) {
                                    let contactName = 'ทั้งหมด';
                                    let productAndServiceType = 'ทั้งหมด';
                                    let payment_channel = 'ทั้งหมด';

                                    if(this.state.data.contact !== "") {
                                        if(props.allOtherReceive?.edges[0].node.contact.typeOfContact === "RESIDENTIAL"){
                                            contactName = props.allOtherReceive.edges[0].node.contact.refNumber
                                            +" "+props.allOtherReceive.edges[0].node.contact.firstName
                                            +" "+props.allOtherReceive.edges[0].node.contact.lastName
                                        } 
                                        if(props.allOtherReceive?.edges[0].node.contact.typeOfContact === "SUPPLIER"){
                                            contactName = props.allOtherReceive.edges[0].node.contact.refNumber
                                            +" "+props.allOtherReceive.edges[0].node.contact.name                                        
                                        }
                                    }
                                    
                                    if(this.state.data.product_and_service !== "") { 
                                      productAndServiceType = props.allOtherReceive?.edges[0]?.node.otherReceiveTransaction.edges[0]?.node.productAndService?.productCode
                                      +" "+props.allOtherReceive?.edges[0]?.node.otherReceiveTransaction.edges[0]?.node.description
                                    }

                                    switch (this.state.data.payment_channel) {
                                        case "cash":
                                          payment_channel = "เงินสด";
                                          break;
                                        case "bank":
                                          payment_channel = "เงินโอน";
                                          break;      
                                        case "credit_card":
                                          payment_channel = "บัตรเครดิต";
                                          break;      
                                        case "cheque":
                                          payment_channel = "เช็ค";
                                          break;      
                                          
                                        default:
                                          break;
                                    }    
                                    return (
                                        <OtherReceiveReportPDFRenderTable 
                                            props={props}
                                            start_date={this.state.data.start_date}
                                            end_date={this.state.data.end_date}
                                            contactName={contactName}
                                            productAndServiceType={productAndServiceType}
                                            payment_channel={payment_channel}
                                            summary={JSON.parse(props.summaryOtherReceive)}
                                        />
                                    );
                                } else {
                                    return <Loading/>
                                }
                                }}
                            />
                        </div>    
            </React.Fragment>
        )
    }
}

export default OtherReceiveReportPDF;