/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type receivableOutstandingSummaryReportQueryVariables = {|
  dueDate: any,
  contactId?: ?string,
  typeOfContact?: ?string,
  search?: ?string,
|};
export type receivableOutstandingSummaryReportQueryResponse = {|
  +reportOutstandingReceivableSummary: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +refNumber: string,
        +firstName: string,
        +lastName: string,
        +typeOfContact: ContactTypeOfContact,
        +outstandingBalance: number,
        +notOverdueBalance: number,
        +overdueOneMonthBalance: number,
        +overdueTwoMonthsBalance: number,
        +overdueThreeMonthsBalance: number,
        +overdueFourMonthsBalance: number,
        +overdueFiveMonthsBalance: number,
        +overdueSixMonthsBalance: number,
        +overdueOverSixMonthsBalance: number,
      |}
    |}>
  |}
|};
export type receivableOutstandingSummaryReportQuery = {|
  variables: receivableOutstandingSummaryReportQueryVariables,
  response: receivableOutstandingSummaryReportQueryResponse,
|};
*/


/*
query receivableOutstandingSummaryReportQuery(
  $dueDate: Date!
  $contactId: ID
  $typeOfContact: String
  $search: String
) {
  reportOutstandingReceivableSummary(dueDate: $dueDate, contactId: $contactId, typeOfContact: $typeOfContact, search: $search) {
    edges {
      node {
        id
        name
        refNumber
        firstName
        lastName
        typeOfContact
        outstandingBalance
        notOverdueBalance
        overdueOneMonthBalance
        overdueTwoMonthsBalance
        overdueThreeMonthsBalance
        overdueFourMonthsBalance
        overdueFiveMonthsBalance
        overdueSixMonthsBalance
        overdueOverSixMonthsBalance
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dueDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfContact"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contactId",
        "variableName": "contactId"
      },
      {
        "kind": "Variable",
        "name": "dueDate",
        "variableName": "dueDate"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "typeOfContact",
        "variableName": "typeOfContact"
      }
    ],
    "concreteType": "OutstandingReceivableSummaryConnection",
    "kind": "LinkedField",
    "name": "reportOutstandingReceivableSummary",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OutstandingReceivableSummaryEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutstandingReceivableSummary",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeOfContact",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "outstandingBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notOverdueBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overdueOneMonthBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overdueTwoMonthsBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overdueThreeMonthsBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overdueFourMonthsBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overdueFiveMonthsBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overdueSixMonthsBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overdueOverSixMonthsBalance",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "receivableOutstandingSummaryReportQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "receivableOutstandingSummaryReportQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "09a422cddd9bb30fe140ad634d3e238e",
    "id": null,
    "metadata": {},
    "name": "receivableOutstandingSummaryReportQuery",
    "operationKind": "query",
    "text": "query receivableOutstandingSummaryReportQuery(\n  $dueDate: Date!\n  $contactId: ID\n  $typeOfContact: String\n  $search: String\n) {\n  reportOutstandingReceivableSummary(dueDate: $dueDate, contactId: $contactId, typeOfContact: $typeOfContact, search: $search) {\n    edges {\n      node {\n        id\n        name\n        refNumber\n        firstName\n        lastName\n        typeOfContact\n        outstandingBalance\n        notOverdueBalance\n        overdueOneMonthBalance\n        overdueTwoMonthsBalance\n        overdueThreeMonthsBalance\n        overdueFourMonthsBalance\n        overdueFiveMonthsBalance\n        overdueSixMonthsBalance\n        overdueOverSixMonthsBalance\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ccbd57a55a7d5fb3e71b596777e8826';

module.exports = node;
