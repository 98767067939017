import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import environment from "../../../env/environment";
import { QueryRenderer } from "react-relay";
import Loading from '../../../libs/loading'
import UnknownReceivePaper from './unknownReceivePaper';
import _ from "lodash"
import "./css/unknownReceivePaper.scss"

export default class wrapperUnknownReceive extends Component {

  print() {
    window.print();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet
          style={[{
            "cssText": `
              body {
                  background-color: #ececec;
                  width: 100%;
                  height: 100%;
              }
            `
          }]}>
          <meta charSet="utf-8"/>
          <title>UnknownReceive Print</title>
          <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
        </Helmet>
        <div id="wrapperUnknownReceive">
          <div className="print-top-menu">
            <div className="logoUnknownReceive">
              <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
            </div>
            <button className="printUnknownReceive" onClick={this.print}>
              <img src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'} alt="silverman"/>
              <span>พิมพ์</span>
            </button>
          </div>
        </div>

        <QueryRenderer
          environment={environment}
          query={this.props.location.state.query}
          variables={{
            search: this.props.location.state.search,
            status: this.props.location.state.status,
            start_date: this.props.location.state.start_date,
            end_date: this.props.location.state.end_date,
          }}
          render={({error, props}) => {
              if (error) {
                return <div className="alert alert-danger"
                            role="alert">{error.message}</div>;
              } else if (props) {
                // let itemPrePage;

                // if(this.props.location.state.start_date && this.props.location.state.end_date){
                //    itemPrePage = 13
                // }else{
                //   itemPrePage = 10
                // }
                // if(this.props.location.state.status === "clear" || this.props.location.state.status === "void"){
                //   itemPrePage = 8
                // }

                let allsort = _.map(props.unknownReceiveViewer.allUnknownReceive.edges, (val) =>  {
                  return val.node.description.length
                }); 

               let descriptionMaxlength = _.sortBy(allsort,sort => sort).reverse().shift();
           
               let itemPerPage = 0;
   
               let getLine = 25; //25 per one line on description
   
               let rowPerLine = descriptionMaxlength / getLine;
   
               rowPerLine = parseInt(rowPerLine);
               
               if(descriptionMaxlength <= 43 && rowPerLine === 0){
                  itemPerPage = 12;
               }else if(descriptionMaxlength > 45){
                  itemPerPage = 10;
               }else{
                  itemPerPage = 14;
               }

                return(
                  <UnknownReceivePaper
                    allUnknownReceivePerPage={_.chunk(props.unknownReceiveViewer.allUnknownReceive.edges, itemPerPage)}
                    allUnknownReceive={props.unknownReceiveViewer.allUnknownReceive.edges}
                    status={this.props.location.state.status}
                    startDate={this.props.location.state.start_date}
                    endDate={this.props.location.state.end_date}
                    selfProject={props.selfProject}
                    myUser={`${props.myUser.juristic.firstName} ${props.myUser.juristic.lastName}`}
                    />
                )
              }
              return <Loading/>;
          }}
        />
      </React.Fragment>
    )
  }
}
