import React from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header/";
import Sidebar from "../../components/sidebar/";
import Wrapper from "../../components/wrapper/";
import ComponentPagination from "../../libs/componentPagination";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import GeneralLedgerTable from "./generalLedgerTable";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import "./styles/generalLedger.scss";
import _ from "lodash";
import ExportGeneralLedgerExcel from "./restapi/server_export_excel"
import ExportGeneralLedgerPDF from "./restapi/server_export_pdf";
// import ChartSelect from "../trialBalance/chartSelect";
import ChartSelect from "../trialBalance/chartSelectNew";
import GeneralLedgerTableBetweenChartOfAccount from "./generalLedgerTableBetweenChartOfAccount";
import Loading from "../../libs/loading";

import {graphql} from "babel-plugin-relay/macro";
import {QueryRenderer} from "react-relay";
import environment from "../../env/environment";
import './styles/generalLedger.scss'
import i18next from "i18next";

const query = graphql`
    query generalLedgerListQuery {
        viewer {
            allChartOfAccount {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
    }
`;

class GeneralLedger extends ComponentPagination {
  constructor(props) {
    super(props);

    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let query_code = _.get(props.match.params, "code");

    let query_start_date = _.get(props.match.params, "start_date");
    if (query_start_date) {
      query_start_date = new Date(
        Date.UTC(
          query_start_date.split("-")[0],
          query_start_date.split("-")[1] - 1,
          query_start_date.split("-")[2]
        )
      );
    } else {
      query_start_date = month_before;
    }

    let query_end_date = _.get(props.match.params, "end_date");
    if (query_end_date) {
      query_end_date = new Date(
        Date.UTC(
          query_end_date.split("-")[0],
          query_end_date.split("-")[1] - 1,
          query_end_date.split("-")[2]
        )
      );
    } else {
      query_end_date = new Date();
    }

    this.state = {
      start_date: query_start_date,
      end_date: query_end_date,
      chart: "",
      search: query_code,
      search_input: "",
      name: "",
      data: "",
      chart_of_account_start: query_code ? query_code : "",
      chart_of_account_end: query_code ? query_code : "",
      input_acc_start: query_code ? query_code : "",
      input_acc_end: query_code ? query_code : "",
      loading:false,
      currentRecord:"",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.setLocalState = this.setLocalState.bind(this);
    this.setDataForExport = this.setDataForExport.bind(this);
    this.setDataForExportCurrentRecord = this.setDataForExportCurrentRecord.bind(this);
    this.setloading = this.setloading.bind(this)
  }

  handleChange(event) {

    let name = event.target.name;

    this.setState({ [event.target.name]: event.target.value }, () => {
      if (
        name === "chart_of_account_start" ||
        name === "chart_of_account_end"
      ) {
        this.setState({ chart: "", name: "" });
      }
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleChange(e);
    }
  }

  handleSearchClick() {
    this.setState({
      chart_of_account_start: this.state.input_acc_start,
      chart_of_account_end: this.state.input_acc_end,
      chart: "",
      name: "",
    }); 
  }

  setLocalState(key, value, name) {
    this.setState(
      {
        [key]: value,
        name: name,
      },
      () => {
        if (key === "chart") {
          this.setState({
            chart_of_account_start: "",
            chart_of_account_end: "",
          });
        }
      }
    );
  }

  setDataForExport(data) {
    this.setState({ data });
  }

  setDataForExportCurrentRecord(currentRecord){
    this.setState({ currentRecord });
  }

  setloading(){
    this.setState({loading:!this.state.loading})
  }

  onClickGetData(data, startDate, endDate) {
    // href={`/accounting/report/account-receivable/dailyPaymentReceiptReport/PDF`}
    this.props.history.push(`/accounting/general-ledger/PDF`, { data: data, startDate:startDate, endDate:endDate })
  }
  render() {

    return (
      <QueryRenderer
                environment={environment}
                query={query}
                fetchPolicy="no-cache"
                variables={{}}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return (
                            //  <SeachSelectGeneralLedger onChange={this.props.handleChange}
                            //               value={this.props.value}
                            //               name={this.props.name} placeholder="รายการ"
                            //               queryObject={props.viewer.allChartOfAccount.edges}
                            //               keyOfValue="id" require={true}
                            //               isClearable={true}
                            //               disabled = {this.props.disabled}
                            //               keyOfLabel="chartOfAccountCode:name"/>      
                        
                              <Wrapper>
                                <Header />
                                <Sidebar />
                                <div id="wrapper-content">
                                {/* <WrapperContent> */}
                                  <AccountingTopMenuNavigation mini={true} />
                                  <div className="container-fluid box">
                                    <div className="row justify-content-between">
                                      <div className="col">
                                        <h3 className="mb-4">
                                          <Link to="/accounting">
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/images/wrapperContent/back.png"
                                              }
                                              alt="back"
                                              className="back"
                                            />
                                          </Link>
                                          <Translation>
                                            {(t) => <span>{t("accounting:general ledger")}</span>}
                                          </Translation>
                                        </h3>
                                      </div>

                                      {/* {this.state.chart && (
                                        <div className="col">
                                          {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                                            codename: "accounting_general_ledger_print",
                                          }) && (
                                            <ExportGeneralLedger
                                              start_date={this.state.start_date}
                                              data={this.state.data}
                                              chartOfAccount={this.state.chart + " " + this.state.name}
                                            />
                                          )}
                                        </div>
                                      )} */}

                                      {this.state.chart && (
                                        <React.Fragment>
                                          {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                                            codename: "accounting_general_ledger_print",
                                          }) && (
                                            <div className="dropdown ">
                                            <button type="button" className="btn-primary-scale-outline floatRight dropdown-toggle dropdown-blue" data-toggle="dropdown">
                                              <Translation>{t => t("PageList:Print")}</Translation>
                                            </button>          
                                            <div className="dropdown-menu" id='generalLedgerList'> 
                                              <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state.data, this.state.start_date, this.state.end_date)}><b>PDF</b></a>
                                              {/* <ExportGeneralLedgerBetween
                                                data={this.state.data}
                                                start_date={this.state.start_date}
                                                end_date={this.state.end_date}
                                              /> */}
                                            </div>
                                          </div>
                                          )}
                                        </React.Fragment>
                                      )}


                                      {this.state.chart_of_account_start &&
                                        this.state.chart_of_account_end &&
                                        this.state.loading === false &&
                                        (
                                          
                                        <div className="dropdown ">
                                          <button type="button" className="btn-primary-scale-outline floatRight dropdown-toggle dropdown-blue" data-toggle="dropdown">
                                            <Translation>{t => t("PageList:Print")}</Translation>
                                          </button>          
                                          <div className="dropdown-menu" id='generalLedgerList'> 
                                            
                                            
                                            {/* <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state.data, this.state.start_date, this.state.end_date)}><b>PDF</b></a> */}
                                            

                                            <ExportGeneralLedgerPDF
                                                state={this.state}/>

                                            {/* <ExportGeneralLedgerBetween
                                                data={this.state.data}
                                                start_date={this.state.start_date}
                                                end_date={this.state.end_date}
                                              /> */}
                                              
                                             <ExportGeneralLedgerExcel
                                                state={this.state}/>

                                          </div>
                                        </div>
                                        
                                        )}
                                    </div>

                                    <div className="content-inner fade-up" id="general-ledger-chart">
                                      <div className="row">
                                        {/* <div className="col-3">
                                          <div className="border radius">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span style={{width: "40px"}} className="search-input" />
                                              </div>
                                              <input
                                                type="text"
                                                className="border-0 form-control pl-5 pt-3 input-size"
                                                placeholder="ค้นหา"
                                                name="search"
                                                value={this.state.search_input}
                                                onChange={(e) =>
                                                  {
                                                    this.setState({ search_input: e.target.value })
                                                  }
                                                
                                                }
                                                onKeyDown={this.handleKeyDown}
                                              />
                                            </div>
                                            <hr />


                                            <GeneralLedgerChart
                                              state={this.state}
                                              setLocalState={this.setLocalState}
                                            />
                                          </div>
                                        </div> */}

                                        <Translation>
                                          {t=>
                                            <div className="col-12 mt-8">
                                              <div className="row mb-2">
                                                <div className="col-6 " style={{padding:'0'}}>
                                                  <div className="form-inline float-left">
                                                    <h4>{i18next.t("filterFunction:sort_by_date")}:</h4>
                                                    <div className="form-group mb-2 pl-4">
                                                      <DatePickerAdapter
                                                        id="start_date"
                                                        className="form-control"
                                                        name="start_date"
                                                        selected={this.state.start_date}
                                                        onChange={this.handleChange}
                                                        // popperPlacement="right-start"
                                                      />
                                                    </div>
                                                    <div className="form-group mb-2">
                                                      <div style={{padding: '0 20px'}}><h4>{i18next.t("filterFunction:to")}</h4></div>
                                                      <DatePickerAdapter
                                                        id="end_date"
                                                        name="end_date"
                                                        className="form-control"
                                                        minDate={this.state.start_date}
                                                        selected={this.state.end_date}
                                                        onChange={this.handleChange}
                                                        // popperPlacement="right-start"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-6">
                                                  <h4>{this.state.name}</h4>
                                                </div>
                                              </div>

                                              <div className="row mb-2 form-inline">
                                                {/* <div className="col" /> */}
                                                  <h5 style={{margin:'0'}}>{i18next.t("AllFilter:Specify the account number.")}: </h5>
                                                  <div className="col-3">
                                                  <ChartSelect
                                                    handleChange={this.handleChange}
                                                    name={"input_acc_start"}
                                                    value={this.state.input_acc_start}
                                                    chart={props}
                                                  />
                                                  </div>
                                                  <h5 style={{margin:'0'}}>{i18next.t("AllFilter:to")}</h5>
                                                  <div className="col-3">
                                                  <ChartSelect
                                                    handleChange={this.handleChange}
                                                    name={"input_acc_end"}
                                                    value={this.state.input_acc_end}
                                                    chart={props}
                                                  />
                                                </div>
                                                <div className="col-2 mt-auto mb-0">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-primary form-control btn-block search-button"
                                                    onClick={() => this.handleSearchClick()}
                                                  >
                                                    {i18next.t("AllFilter:Search")}
                                                  </button>
                                                </div>
                                              </div>

                                              {
                                              this.state.chart && (
                                                <GeneralLedgerTable
                                                  state={this.state}
                                                  setDataForExport={this.setDataForExport}
                                                />)
                                              }

                                              {this.state.chart_of_account_start &&
                                                this.state.chart_of_account_end && (
                                                  <GeneralLedgerTableBetweenChartOfAccount
                                                    state={this.state}
                                                    setDataForExport={this.setDataForExport}
                                                    setDataForExportCurrentRecord={this.setDataForExportCurrentRecord}
                                                    setloading={this.setloading}
                                                  />
                                                )}
                                            </div>
                                          }
                                        </Translation>

                                      </div>
                                    </div>
                                  </div>
                                {/* </WrapperContent> */}
                                </div>
                              </Wrapper>
                            )
                    }
                        return <Loading/>
                }}/>
    );
  }
}

export default GeneralLedger;
