import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import InvoiceForm from './invoiceForm';
import SelectContact from '../selectContact';
import InvoiceEdit from './invoiceEdit';
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";


class InvoiceCreateUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer_list: [],
            contact_residential: [],
            accountType: "invoice",
            preinvoice:true,
            role: "",
        };
        this.updateCustomerList = this.updateCustomerList.bind(this);
        this.updatePreinvoice = this.updatePreinvoice.bind(this);
    }

    updateCustomerList(contact_list,contact_residential=[],role) {
        this.setState({
            customer_list: contact_list, 
            contact_residential:contact_residential,
            role:role,
        })
    }
    updatePreinvoice(event){
        this.setState({preinvoice:event})
    }

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true}/>
                    {this.props.match.params.status === 'select_customer' &&
                    <SelectContact updateCustomerList={this.updateCustomerList}
                                   updatePreinvoice={this.updatePreinvoice} 
                                   accountType={this.state.accountType}
                                   initialContactType="residential"
                                   nextLink="/accounting/income/invoice/form/create"
                                   backLink="/accounting/income/invoice/list/all"/>
                    }
                    {this.props.match.params.status === 'create' &&
                        //เงื่อนไข SENA
                        <InvoiceForm 
                            customerList={this.state.customer_list}
                            contactResidential={this.state.contact_residential}
                            preinvoice={this.state.preinvoice}
                            role={this.state.role}
                        />
                    }

                    {this.props.match.params.status === 'edit' &&
                    <InvoiceEdit invoice_id={this.props.match.params.id}/>
                    }
                </div>
            </Wrapper>
        );
    }
}

export default InvoiceCreateUpdate;