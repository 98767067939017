import React, { Component } from 'react'
import _ from 'lodash'

export default class boxImage extends Component {
    render() {
        let isSelect = false;
        if(_.findIndex(this.props.selectedItem,['id',this.props.box.node.id]) !== -1){
            isSelect = true
        }
        return (
            <div className="col mb-3"
                draggable={this.props.draggable}
                onDragStart={this.props.onDragStart({ id: this.props.box.node.id })}
                onDragOver={this.props.onDragOver({ id: this.props.box.node.id })}
                onDrop={this.props.onDrop({ id: this.props.box.node.id })}
                onClick={this.props.onSelect({ index: this.props.index, datas: {id: this.props.box.node.id, fileUploads: this.props.box.node.fileUpload, description: this.props.box.node.description} })}
                >
                <img className={isSelect ? "selected-border" : ""}
                    style={{width: "auto", height: "204px" }} 
                    src={this.props.box.node.fileUpload}
                    />
            </div>
        )
    }
}
