import i18next from 'i18next';
import React, {Component} from 'react';
import { Translation } from 'react-i18next';
import {NavLink} from "react-router-dom";
import './settingFee.scss'

class Navigation extends Component {

    render() {
        return (
            <div className="row" id="setting-fee">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/fee/settings" >
                                <span><Translation>{t=> i18next.t("debtFreeCertification:Debt Acknowledgment Letter")}</Translation></span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;