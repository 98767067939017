import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import QRCode from "qrcode.react";
import API from '../../api/sing'
import Swal from 'sweetalert2';
import { Redirect } from 'react-router';
import './styles/printQrAssetSelected.scss'
import i18next from 'i18next';
class AssetPrintQrCodeSelected extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
        this.state = {
            data: [],
            urlPath: "",
            redidect: false,
            loading: true,
        }
        this.colorPickerRef = React.createRef();

    }

    componentWillMount() {
        this.getDataId()
    }

    filterBetweenArrays(allAsset, selectedAsset) {
        const filteredArray = allAsset.filter(item => selectedAsset.includes(item.docNumber));
        return filteredArray.sort((a, b) => {
          const indexA = selectedAsset.indexOf(a.docNumber);
          const indexB = selectedAsset.indexOf(b.docNumber);
          return indexA - indexB;
        });
      }
      

    getDataId() {
        const order = ['LAN', 'B', 'BR', 'OD', 'EQ', 'T', 'TE', 'V', 'CP', 'OA', 'ITG'];
        let selectedPrintQr = this.props.location.state.data? this.props.location.state.data: "";
        API.getassetAll().then((res) => {
            if (res.data.asset) {
                let url = window.location.origin
                let filteredData = ""
                if(selectedPrintQr) {
                     filteredData = this.filterBetweenArrays(res.data.asset, selectedPrintQr)
                } else {
                    filteredData = res.data.asset.sort((a, b) => {
                        const indexA = order.findIndex(prefix => a.docNumber.startsWith(prefix));
                        const indexB = order.findIndex(prefix => b.docNumber.startsWith(prefix));
                        return indexA - indexB;
                      });
                }

                if (filteredData.length > 0) {
                    this.setState({ data: filteredData, loading: false, urlPath: url })
                } else {
                    Swal.fire(i18next.t("Allaction:Not found"), i18next.t("Allaction:Please try again."), 'error').then(() => {
                        this.setState({ redidect: true, loading: false })
                    })
                }

                this.setState({ loading: false })
            }
        }
        , () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error').then(() => this.setState({ loading: false }))
        )
    }



    print() {       
        window.print()
    }

    render() {
        if (this.state.redidect) {
            return <Redirect to={"/accounting/asset/all"} />
        }
        
        const array = this.state.data;
        const totalPages = 8;


        const chunkedArray = [];

        for (let i = 0; i < array.length; i += totalPages) {
            const chunk = array.slice(i, i + totalPages);
            chunkedArray.push(chunk);
        }
        
        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>QRCode Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div id="qrSelectedTopBar"className="print-top-menu">
                    <div className="logo topBar" >
                    <div className="d-flex justify-content-center mt-3">
                            <div>
                                <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="btn btn-primary btnPrint" onClick={this.print}>
                                <img className='imgPrint' src={process.env.PUBLIC_URL + '/images/icons/iconPrint.png'}/> 
                                <div className='textPrint'>พิมพ์</div>
                            </div>
                        </div>
                    </div>

                </div>
                
                {
                    this.state.loading == false &&      
                    <div id="printQrSeleted">             
                        {chunkedArray.map((dataPerA4, pageIndex) => (
                        <div className='print-page-a4 page divToPrint' key={pageIndex}>
                            {dataPerA4.map((data, index) => (
                            <div className='card text-left' key={`${data.qr}${index}`}>
                                <label>Code: {data.docNumber}</label>
                                {data.name.length > 22 ? (
                                <label style={{ fontSize: "9px" }}>Name: {data.name}</label>
                                ) : (
                                <label>Name: {data.name}</label>
                                )}
                                <div className='boxQRCode'>
                                <QRCode value={this.state.urlPath + "/scanToRepair/" + data.qr} size={60} />
                                </div>

                                {data.type === 'office_equipment' || data.type === 'training_equipment' || data.type === 'tools' ?
                                <label className='labelTypeAsset'>
                                Type:{" "}
                                {data.type === "office_equipment"
                                    ? "อุปกรณ์และเครื่องใช้สำนักงาน"
                                    : data.type === "tools"
                                    ? "เครื่องมือ / อุปกรณ์งานช่าง"
                                    : data.type === "building_renovation"
                                    ? "ส่วนต่อเติมและปรับปรุงอาคาร"
                                    : data.type === "training_equipment"
                                    ? "เครื่องออกกำลังกาย / สันทนาการ"
                                    : "-"}
                                </label>                                
                                :
                                <label className='lcabel-20'>
                                Type:{" "}
                                {data.type === "land"
                                    ? "ที่ดิน"
                                    : data.type === "building"
                                    ? "อาคาร"
                                    : data.type === "office_decoration"
                                    ? "เครื่องตกแต่งสำนักงาน"
                                    : data.type === "vehicle"
                                    ? "ยานพาหนะ"
                                    : data.type === "common_property"
                                    ? "ทรัพย์สินส่วนกลาง"
                                    : data.type === "intangible_asset"
                                    ? "สินทรัพย์ไม่มีตัวตน"
                                    : data.type === "other_asset"
                                    ? "สินทรัพย์อื่นๆ"
                                    : "-"}
                                </label>
                                }
                                <label className='lcabel-20'>Location: {data.storage}</label>
                                <label className='lcabel-20'>Received Date: {format(data.purchase_date + "T00:00", "DD/MM/YYYY")}</label>
                                <label className='lcabel-20'>Remark: {data.remark}</label>
                            </div>
                            ))}
                        </div>
                        ))}                    
                     </div>   
                }
            </React.Fragment>
        )
    }
}

export default AssetPrintQrCodeSelected;
