import React, { Component } from "react";
import PrivateRoute from "../../libs/privateRoute";
import { Route, Switch } from "react-router-dom";
import CreateBudget from "./budgetRecord/createBudget";
import EditBudget from "./budgetRecord/editBudget";
import budgetSetingReport from "./budgetReport/budgetSetingReport";
// import budgetSetingReport from "./budgetReport/budgetSetingTable";
import budgetUsageSummaryReport from "./budgetReport/budgetUsageSummaryReport";
import MenuBudget from "./menuBudget";
import NoMatch from "../../components/noMatch";
import BudGetList from "./budgetRecord/budgetAllList";
import BudGetReportList from "./budgetReport/budgetReportList";
import BudgetDraft from "./budgetDraft";
import MonthlyBudgetUsageReport from "./budgetReport/monthlyBudgetUsageReport";
import MonthlyBudgetUsageReportSummary from "./budgetReport/monthlyBudgetUsageReportSummary";
import BudgetReportRegister from "./reportRegister"
import WrapperBudgetSetingReport from './budgetReport/wrapperBudgetSetingReport'

class Account extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/`} component={MenuBudget} />
        <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={BudGetList} />
        <PrivateRoute exact path={`${this.props.match.url}/report/:menu`} component={BudGetReportList} />
        <PrivateRoute exact path={`${this.props.match.url}/create/`} component={CreateBudget} />
        <PrivateRoute exact path={`${this.props.match.url}/edit/:id/:year`} component={EditBudget} />
        <PrivateRoute exact path={`${this.props.match.url}/report-seting/:id/:year`} component={budgetSetingReport} />
        <PrivateRoute exact path={`${this.props.match.url}/wrapper-report-seting/:id/:year`} component={WrapperBudgetSetingReport} />
        <PrivateRoute exact path={`${this.props.match.url}/report-usage-summary/:id/:endDate`} component={budgetUsageSummaryReport} />
        <PrivateRoute exact path={`${this.props.match.url}/report-month-budget-usage/:id/:endDate`} component={MonthlyBudgetUsageReport} />
        <PrivateRoute exact path={`${this.props.match.url}/report-month-budget-usage-summary/:id/:endDate`} component={MonthlyBudgetUsageReportSummary} />
        <PrivateRoute path={`${this.props.match.url}/budgetDraft`} component={BudgetDraft} />
        <PrivateRoute path={`${this.props.match.url}/reportRegister`} component={BudgetReportRegister}/>
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default Account;
