import '../styles/main.scss';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import ReceiptDepositAll from './receiptDepositAll';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperReceiptDepositAllQuery($id_in: [String]!) {
        selfProject {
            id
            name
            address
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }

        userConfig {
            id
            languages
        }

        receiptDepositViewer {
            allReceiptDeposit(id_In: $id_in) {
            totalCount
            edges {
                node {
                    id
                    docNumber
                    issuedDate
                    status
                    total
                    taxType
                    firstName lastName
                    address district city province postalCode
                    name
                    taxIdNumber
                    email
                    phone
                contact {
                    refNumber
                    name
                    taxIdNumber
                    firstName
                    lastName
                    phone
                    email
                    typeOfCompany
                    typeOfSupplier
                    typeOfContact
                    nameBranch
                    registeredAddress
                    registeredCountry
                    registeredProvince
                    registeredDistrict
                    registeredCity
                    registeredPostalCode
                    residential {
                        id
                        size
                        ownershipRatio
                    }
                }
            remark
            receiptDepositTransaction{
                edges{
                    node{
                        id
                        chartOfAccount{
                            id name chartOfAccountCode
                        }
                        vat
                        price
                        description
                        receiptDepositTransactionUsed{
                            edges{
                                node{
                                    id
                                    amount
                                    transaction{
                                        invoice{
                                            docNumber
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            receiptDepositTransactionUsed{
                edges{
                    node{
                        id
                        amount
                    }
                }
            }
            bankAccountTransaction{
                edges{
                    node{
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction{
                edges{
                    node{
                        id
                        price
                    }
                }
            }
            chequeDeposit{
                edges{
                    node{
                        id
                        date
                        bankName
                        price
                        chequeNumber
                        branchNumber
                    }
                }
            }
            creditCardTransaction{
                edges{
                    node{
                        amount
                    }
                }

            }
            }
            }
            }
        }
    }
`;

class WrapperReceiptDepositAll extends Component {
    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    print() {
        window.print();
    }

    render() {
        return (
            <React.Fragment>
                <Helmet
                    style={[
                        {
                            cssText: `
                        body {
                            background-color: #ececec;
                        }
                    `
                        }
                    ]}
                >
                    <meta charSet="utf-8" />
                    <title>Receipt deposit</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{ use_cache: false }}
                    variables={{
                       id_in: this.props.location.state.checkList
                    }}
                    render={({ error, props }) => {
                        if (error) {
                            return <div className="alert alert-danger" role="alert">{error.message}</div>;
                        } else if (props) {
                            return (
                                <div>
                                    {props.receiptDepositViewer.allReceiptDeposit.edges.map((rd, index) => (
                                        <ReceiptDepositAll
                                            selfProject = {props.selfProject}
                                            userConfig = {props.userConfig}
                                            key={rd.node.id}
                                            query={rd.node}
                                            bank_transaction={rd.node.bankAccountTransaction.edges}
                                            cash_transaction={rd.node.cashTransaction.edges}
                                            cheque_transaction={rd.node.chequeDeposit.edges}
                                            remark={rd.node.remark}
                                            credit_card_transaction={rd.node.creditCardTransaction.edges}
                                        />
                                    ))}
                                </div>
                            );
                        } else {
                           return <div className="text-center"><Loading /></div>;
                        }
                    }}
                />
            </React.Fragment>
        );
    }
}

export default WrapperReceiptDepositAll;
