import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import i18next from 'i18next';

const query = graphql`
    query navigationAdvanceQuery{
        getNotificationAdvance {
            type
            count
        }
    }
`;

class navigationAdvance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count_notification: 0
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then(response_data => {
            if (response_data.getNotificationAdvance) {
                response_data.getNotificationAdvance.forEach((notification) => {
                    if (notification.type === "num_due") {
                        this.setState({ count_notification: notification.count })
                    }
                });
            }
        });
    }

    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/expense/advance/list/withdraw">
                                {i18next.t("advanceWithdrawList:Withdraw Advance Payment")}
                                {(this.state.count_notification > 0) ?
                                    <p className="float-right font-weight-bold badge text-light bg-danger">{this.state.count_notification}</p> : false}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/advance/list/clear-advance">
                                {i18next.t("advanceWithdrawList:Clear Advance Payment")}
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

export default navigationAdvance;