/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BatchReceiveStatus = "ACTIVE" | "VOID" | "%future added value";
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type InvoicePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type ReceivePayGroup = "AGENT" | "DEVELOPER" | "OWNER" | "RENT" | "%future added value";
export type ReceiveStatus = "PAID" | "VOID" | "WAIT" | "%future added value";
export type batchDetailQueryVariables = {|
  id: string
|};
export type batchDetailQueryResponse = {|
  +selfProject: ?{|
    +name: string,
    +nameEn: ?string,
    +address: ?string,
    +addressEn: ?string,
    +logoBase64: ?string,
    +juristicContactNumber: ?string,
    +keyProjectQr: ?string,
    +taxIdNumber: ?string,
    +logo: ?string,
  |},
  +batchReceive: ?{|
    +id: string,
    +docNumber: string,
    +batchDate: any,
    +startRt: ?string,
    +endRt: ?string,
    +creator: ?string,
    +voider: ?string,
    +remark: ?string,
    +status: BatchReceiveStatus,
    +added: any,
    +receiveCount: ?number,
    +receiveBatch: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +receive: {|
            +id: string,
            +docNumber: string,
            +firstName: string,
            +lastName: string,
            +payGroup: ReceivePayGroup,
            +contact: {|
              +id: string,
              +name: string,
              +refNumber: string,
              +firstName: string,
              +lastName: string,
              +typeOfContact: ContactTypeOfContact,
            |},
            +issuedDate: any,
            +added: any,
            +total: ?number,
            +status: ReceiveStatus,
            +voidRemark: ?string,
            +creator: ?string,
            +updated: any,
            +receiveTransaction: ?{|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +amount: ?number,
                  +receiver: ?string,
                  +transaction: {|
                    +id: string,
                    +productAndService: ?{|
                      +id: string
                    |},
                    +description: string,
                    +total: ?number,
                    +paid: ?number,
                    +invoice: {|
                      +firstName: string,
                      +lastName: string,
                      +payGroup: InvoicePayGroup,
                    |},
                  |},
                |}
              |}>
            |},
          |}
        |}
      |}>
    |},
  |},
|};
export type batchDetailQuery = {|
  variables: batchDetailQueryVariables,
  response: batchDetailQueryResponse,
|};
*/


/*
query batchDetailQuery(
  $id: ID!
) {
  selfProject {
    name
    nameEn
    address
    addressEn
    logoBase64
    juristicContactNumber
    keyProjectQr
    taxIdNumber
    id
    logo
  }
  batchReceive(id: $id) {
    id
    docNumber
    batchDate
    startRt
    endRt
    creator
    voider
    remark
    status
    added
    receiveCount
    receiveBatch {
      edges {
        node {
          receive {
            id
            docNumber
            firstName
            lastName
            payGroup
            contact {
              id
              name
              refNumber
              firstName
              lastName
              typeOfContact
            }
            issuedDate
            added
            total
            status
            voidRemark
            creator
            updated
            receiveTransaction {
              edges {
                node {
                  id
                  amount
                  receiver
                  transaction {
                    id
                    productAndService {
                      id
                    }
                    description
                    total
                    paid
                    invoice {
                      firstName
                      lastName
                      payGroup
                      id
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameEn",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressEn",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoBase64",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "juristicContactNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyProjectQr",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxIdNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "batchDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startRt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endRt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voider",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiveCount",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payGroup",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v11/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    (v22/*: any*/),
    (v23/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiver",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductAndServiceNode",
  "kind": "LinkedField",
  "name": "productAndService",
  "plural": false,
  "selections": [
    (v11/*: any*/)
  ],
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "batchDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "BatchReceiveNode",
        "kind": "LinkedField",
        "name": "batchReceive",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ReceiveBatchNodeConnection",
            "kind": "LinkedField",
            "name": "receiveBatch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveBatchNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveBatchNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          (v20/*: any*/),
                          (v27/*: any*/),
                          (v19/*: any*/),
                          (v28/*: any*/),
                          (v16/*: any*/),
                          (v29/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReceiveTransactionNodeConnection",
                            "kind": "LinkedField",
                            "name": "receiveTransaction",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReceiveTransactionNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReceiveTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v11/*: any*/),
                                      (v30/*: any*/),
                                      (v31/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TransactionNode",
                                        "kind": "LinkedField",
                                        "name": "transaction",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v32/*: any*/),
                                          (v33/*: any*/),
                                          (v27/*: any*/),
                                          (v34/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "InvoiceNode",
                                            "kind": "LinkedField",
                                            "name": "invoice",
                                            "plural": false,
                                            "selections": [
                                              (v22/*: any*/),
                                              (v23/*: any*/),
                                              (v24/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "batchDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v11/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "BatchReceiveNode",
        "kind": "LinkedField",
        "name": "batchReceive",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ReceiveBatchNodeConnection",
            "kind": "LinkedField",
            "name": "receiveBatch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveBatchNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReceiveBatchNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceiveNode",
                        "kind": "LinkedField",
                        "name": "receive",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          (v20/*: any*/),
                          (v27/*: any*/),
                          (v19/*: any*/),
                          (v28/*: any*/),
                          (v16/*: any*/),
                          (v29/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReceiveTransactionNodeConnection",
                            "kind": "LinkedField",
                            "name": "receiveTransaction",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReceiveTransactionNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReceiveTransactionNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v11/*: any*/),
                                      (v30/*: any*/),
                                      (v31/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TransactionNode",
                                        "kind": "LinkedField",
                                        "name": "transaction",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v32/*: any*/),
                                          (v33/*: any*/),
                                          (v27/*: any*/),
                                          (v34/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "InvoiceNode",
                                            "kind": "LinkedField",
                                            "name": "invoice",
                                            "plural": false,
                                            "selections": [
                                              (v22/*: any*/),
                                              (v23/*: any*/),
                                              (v24/*: any*/),
                                              (v11/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "149a1408ab45441ef11f3c1039b99505",
    "id": null,
    "metadata": {},
    "name": "batchDetailQuery",
    "operationKind": "query",
    "text": "query batchDetailQuery(\n  $id: ID!\n) {\n  selfProject {\n    name\n    nameEn\n    address\n    addressEn\n    logoBase64\n    juristicContactNumber\n    keyProjectQr\n    taxIdNumber\n    id\n    logo\n  }\n  batchReceive(id: $id) {\n    id\n    docNumber\n    batchDate\n    startRt\n    endRt\n    creator\n    voider\n    remark\n    status\n    added\n    receiveCount\n    receiveBatch {\n      edges {\n        node {\n          receive {\n            id\n            docNumber\n            firstName\n            lastName\n            payGroup\n            contact {\n              id\n              name\n              refNumber\n              firstName\n              lastName\n              typeOfContact\n            }\n            issuedDate\n            added\n            total\n            status\n            voidRemark\n            creator\n            updated\n            receiveTransaction {\n              edges {\n                node {\n                  id\n                  amount\n                  receiver\n                  transaction {\n                    id\n                    productAndService {\n                      id\n                    }\n                    description\n                    total\n                    paid\n                    invoice {\n                      firstName\n                      lastName\n                      payGroup\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '96c23ec530db2648f52a63b7c48edcb5';

module.exports = node;
