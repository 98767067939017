
import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../env/environment";
import DatePicker from "react-datepicker";
import numberWithComma from "../libs/numberWithComma";
import { Link } from "react-router-dom";
import './silvermanAgent.scss'


const query = graphql`
    query silvermanAgentViewQuery ($silvermanAgentId: String) {
    allSilvermanAgent (silvermanAgentId: $silvermanAgentId) {
        edges{
            node{
                id
                userType
                residentialType
                residentialOther
                announceSell
                announceForRent
                announceBuy
                announceDown
                announceLease
                announceLookRent
                province
                district
                projectName
                area
                topic
                description
                sizeArea
                bedroom
                bathroom
                floor
                floorSize
                rai
                ngan
                squareWa
                remarkPet
                remarkForeign
                remarkDuplex
                remarkPenthouse
                remarkHighFloor
                remark
                contactName
                callName
                phone
                line
                email
                silvermanPhone
                silvermanLine
                silvermanEmail
                price
                rentDate
                rentPrice
                downPrice
                realPrice
                leasePrice
                buyPrice
                lookRentPrice
                thirdTerm
                sixTerm
                twelveTerm
                province
                district
                silvermanAgentImage{
                    edges{
                        node{
                            id
                            fileName
                            fileUpload
                        }
                    }
                }   
            }
        }
    }
}
`;

class SilvermanAgentView extends Component {

    constructor(props) {
        super(props)
        this.state = {
           lang:false,
           agent:{
            userType:'',
            residentialType:'',
            announceSell:false, //ขาย
            announceForRent:false, //ให้เช่า
            announceBuy:false, //ซื้อ
            announceDown:false, //ขายดาวน์
            announceLease:false, //เซ้ง
            announceLookRent:false, // หาเช่า
            remarkHighFloor:false,
            province:'',
            district:'',
            area:'',
            projectName:'',
            topic:'',
            description:'',
            sizeArea:0.00,
            bedroom:0,
            bathroom:0,
            floor:"",
            floorSize:"",
            rai:0,
            ngan:0,
            squareWa:0,
            remarkPet:false,
            remarkForeign:false,
            remarkDuplex:false,
            remarkPenthouse:false,
            residentialOther:'',
            remark:'',
            contactName:'',
            callName:'',
            phone:'',
            line:'',
            email:'',
            silvermanPhone:false,
            silvermanLine:false,
            silvermanEmail:false,
            price:0.00,
            rentDate: null,
            rentPrice: 0.00,
            downPrice: 0.00,
            realPrice: 0.00,
            leasePrice: 0.00,
            buyPrice: 0.00,
            lookRentPrice: 0.00,
            thirdTerm: false,
            sixTerm: false,
            twelveTerm: false,
            silvermanAgentImage: [],
            silvermanAgentImageShow:[],
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.passwordDecode === ''){
            this.fetchData()
        }
    }

    fetchData () {
        fetchQuery(environment , query , {
                silvermanAgentId : this.props.passwordDecode,
            }).then(response => {
                this.setState({
                    agent: response.allSilvermanAgent.edges[0].node
                })
    
            }).catch(err => {
                alert(err)
        })
    }


    render() {
        let residentialTypeLower = this.state?.agent?.residentialType.toLowerCase()

        return (
            <div id="wrapper-content">
                <div id='svm-agent-status'>
                <div className='p-4'>
                    <div>
                    <span onClick={ () => this.setState({lang : !this.state.lang})} style={{ cursor: 'pointer' }} className="row">
                         <img src={this.state.lang ? (process.env.PUBLIC_URL + '/images/flags/en.png') : (process.env.PUBLIC_URL + '/images/flags/th.png')} alt="flag-icon" className="flag" />
                         <h6><small>{this.state.lang ? 'EN':'ไทย'}</small></h6>
                    </span>
                    </div>
                    <div>
                        <h5>{this.state.lang ? 'Select your status':'เลือกสถานะของคุณ'}</h5>
                        <div className='row'>
                                <div className='col'>
                                    <div className={`svm-agent-select text-center ${this.state.agent?.userType === 'OWNER' ? 'active':''}`}>
                                        <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/owner.png"} className="pt-5"/>
                                        <p>{this.state.lang ? 'Landlord' : 'เจ้าของทรัพย์'}</p>
                                    </div>
                                </div>
                                {/* <div className='col'>
                                    <div className={`svm-agent-select text-center ${this.state.agent?.userType === 'AGENT' ? 'active' :''}`}>
                                        <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/agent.png"} className="pt-5"/>
                                        <p>ตัวแทน (Agent)</p>
                                    </div>
                                </div> */}
                        </div>
                        <div className='row pt-4'>
                                <div className='col'>
                                    <div className={`svm-agent-select text-center ${this.state.agent?.userType === 'TENANT' ? 'active' :''}`}>
                                        <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/tenant.png"} className="pt-5"/>
                                        <p> {this.state.lang ? 'Tenant' : 'ผู้เช่า'}</p>
                                        </div>
                                    </div>
                                <div className='col'>
                                    <div className={`svm-agent-select text-center ${this.state.agent?.userType === 'BUYER' ? 'active' :''}`}>
                                        <img src={process.env.PUBLIC_URL + "/images/silvermanAgent/icon/buyer.png"} className="pt-5"/>
                                        <p>{this.state.lang ? 'Buyer' : 'ผู้ซื้อ'}</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                <div className='mt-6'>
                        <h5>{this.state.lang ? 'Select your property type' : 'ประเภทอสังหาริมทรัพย์' }</h5>
                        <div className='btn-select-property'>
                            <img src={process.env.PUBLIC_URL + `/images/silvermanAgent/icon/propertyIcon/${residentialTypeLower}.png`} style={{width:'30px'}} /> 
                            <span>{this.state.agent.residentialType}</span>
                        </div>
                        {
                            this.state.agent.residentialType === 'OTHER' ? 
                                <>
                                <div className='pt-2'>
                                    <span>{this.state.lang ? 'Other':'อื่นๆ'}</span>
                                    <input type='text' name='residentialOther' className='form-control' value={this.state.agent.residentialOther} disabled/>
                                </div>
                                </>
                                :''
                        }
                </div>
                <div className='mt-6'>
                        <h5>{this.state.lang ? 'Announcement type' : 'ประเภทประกาศ'}</h5>
                        <div className='box-property-typesale'>  
           
                            {/* <div className='ml-3 pb-2'>
                                 <input type="checkbox" id="announceForRent" name="announceForRent" checked={this.state.agent?.announceForRent} /> 
                                 <span className='pl-2'>เช่า</span>
                            </div>
                            <div className='ml-3 pb-2'>
                                <input type="checkbox" id="announceSell" name="announceSell"  checked={this.state.agent?.announceSell} /> 
                                <span className='pl-2'>ขาย</span>
                            </div>

                            <div className='ml-3 pb-2'>
                                 <input type="checkbox" id="announceBuy" name="announceBuy"  checked={this.state.agent.announceBuy}/> 
                                 <span className='pl-2'>ซื้อ</span>
                            </div>

                            <div className='ml-3 pb-2'>
                                 <input type="checkbox" id="announceForRent" name="announceForRent" checked={this.state.agent?.announceForRent} /> 
                                 <span className='pl-2'>ให้เช่า</span>
                            </div> */}

                            {
                                    this.state.agent.userType === 'OWNER' ? 
                                    <>
                                    <div className='ml-3 pb-2'>
                                        <input type="checkbox" id="announceForRent" name="announceForRent" checked={this.state.agent?.announceForRent} disabled/> 
                                        <span className='pl-2'>{this.state.lang ?'Rent':'เช่า'}</span>
                                    </div> 
                                    <div className='ml-3 pb-2'>
                                        <input type="checkbox" id="announceSell" name="announceSell"  checked={this.state.agent?.announceSell} disabled/> 
                                        <span className='pl-2'>{this.state.lang ?'Sell':'ขาย'}</span>
                                    </div>
                                    </>
                                    :''
                                }
                                {
                                    this.state.agent.userType === 'TENANT' || this.state.agent.userType=== 'BUYER' ?
                                    <>
                                      <div className='ml-3 pb-2'>
                                        <input type="checkbox" id="announceLookRent" name="announceLookRent" checked={this.state.agent?.announceLookRent} disabled/> 
                                         <span className='pl-2'>{this.state.lang ?'Rent':'เช่า'}</span>
                                     </div>
                                     <div className='ml-3 pb-2'>
                                        <input type="checkbox" id="announceBuy" name="announceBuy"  checked={this.state.agent.announceBuy} disabled/> 
                                        <span className='pl-2'>{this.state.lang ?'Buy':'ซื้อ'}</span>
                                      </div>
                                     </>
                                    :''
                                }
                            {/* <div className='ml-3 pb-2'>
                                <input type="checkbox" id="announceDown" name="announceDown" checked={this.state.agent?.announceDown} /> 
                                <span className='pl-2'>ขายดาวน์</span>
                            </div>
                                             <div className='ml-3 pb-2'>
                                 <input type="checkbox" id="announceBuy" name="announceBuy"  checked={this.state.agent.announceBuy}/> 
                                 <span className='pl-2'>ซื้อ</span>
                            </div>
                            <div className='ml-3 pb-2'>
                                 <input type="checkbox" id="announceForRent" name="announceForRent" checked={this.state.agent?.announceForRent} /> 
                                 <span className='pl-2'>ให้เช่า</span>
                            </div>
                            <div className='ml-3 pb-2'>
                                 <input type="checkbox" id="announceLease" name="announceLease"  checked={this.state.agent?.announceLease}/> 
                                 <span className='pl-2'>เซ้ง</span>
                            </div>
                            <div className='ml-3 pb-2'>
                                 <input type="checkbox" id="announceBuy" name="announceBuy"  checked={this.state.agent.announceBuy}/> 
                                 <span className='pl-2'>ซื้อ</span>
                            </div>
                            <div className='ml-3 pb-2'>
                                 <input type="checkbox" id="announceLookRent" name="announceLookRent"  checked={this.state.agent.announceLookRent}/> 
                                 <span className='pl-2'>หาเช่า</span>
                            </div> */}
                        </div>
                </div>
                <div className='mt-6'>
                    <h5>{this.state.lang  ? 'Location':'ทำเล/ตำแหน่ง'}</h5>
                    <span>{this.state.lang ? 'District':'อำเภอ / เขต'}</span>
                    <div className='mb-2'><input type="text" className="form-control" value={this.state.agent?.district} disabled/></div>
                    <span>{this.state.lang ? 'Province':'จังหวัด'}</span>
                    <div className='mb-2'><input type="text" className="form-control" value={this.state.agent?.province} disabled/></div>
                    <span> {this.state.lang ? 'Area (Near BTS,MRT)':'บริเวณ (เช่นใกล้ BTS, MRT)'}</span>
                    <div className='mb-2'><input type="text" className="form-control" value={this.state.agent?.area} disabled/></div>
                    <h5 className='mt-6'>{this.state.lang ? 'Project name':'ชื่อโครงการ'}</h5>
                    <div className='mb-2'><input type="text" className="form-control" value={this.state.agent?.projectName} disabled/></div>
                </div>   
                {/* <div className='mt-6'>
                    <h5>ชื่อประกาศ</h5>
                    <span>ชื่อหัวเรื่อง </span>
                    <div className='mb-2'><input type="text" className="form-control" value={this.state.agent?.topic} disabled/></div>
                    <span>รายละเอียด </span>
                    <div className='mb-2'><input type="text" className="form-control" value={this.state.agent?.description} disabled/></div>
                </div>       */}
                <div className='mt-6'>
                        <h5>{this.state.lang  ? 'Property information':'ข้อมูลทรัพย์'} <span className='text-danger'>*</span></h5>
                            {/* {this.state.agent.residentialType !== 'LAND' ?   */}
                                <div>
                                    <span>{this.state.lang ? 'Size Area (sq.m.)':'ขนาดพื้นที่ (ตร.ม.)'}<span className='text-danger'>*</span></span>
                                    <input type="text" className="form-control" name='sizeArea' value={this.state.agent.sizeArea} disabled/> 
                                </div>
                                {/* :''
                            } */}
                            <div>
                                <div className='row mt-3'>
                                {/* {this.state.agent.residentialType  !== 'LAND' ? */}
                                 <>
                                    <div className='col'>
                                        <span>{this.state.lang ? 'Bedroom':'ห้องนอน'} <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control"  name="bedroom" value={this.state.agent.bedroom} disabled/>
                                    </div>
                                    <div className='col'>
                                        <span>{this.state.lang ? 'Bathroom':'ห้องน้ำ'} <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" name="bathroom" value={this.state.agent.bathroom} disabled/>
                                    </div>
                                    { 
                                     this.state.agent.userType === 'OWNER' ? 
                                            <div className='col'>
                                            <span>{this.state.lang ? 'Floor':'จำนวนชั้น'}</span>
                                            <input type="text" className="form-control" name='floor' value={this.state.agent.floor} disabled/>
                                            </div>
                                        :''
                                    }
                                    </>
                                    {/* :''
                                } */}
          
                                {/* {this.state.agent.residentialType  === 'LAND' ?
                                    <>
                                    <div className='col'>
                                        <span>ไร่ <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" name='rai'  value={this.state.agent.rai} disabled/>
                                    </div>
                                    <div className='col'>
                                        <span>งาน <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" name='ngan' value={this.state.agent.ngan} disabled/>
                                    </div>
                                    <div className='col'>
                                        <span>ตารางวา <span className='text-danger'>*</span></span>
                                        <input type="text" className="form-control" name='squareWa' value={this.state.agent.squareWa} disabled/>
                                    </div>
                                    </>
                                    :''
                                } */}
                                   
                                </div>
                                <div className='mt-3'>
                                    <span>{this.state.lang ? 'ขนาด (sq.m.)':'ขนาด (ตร.ม.)'}</span>
                                    <input type="text" className="form-control" name='floorSize' value={this.state.agent.floorSize} disabled/>
                                </div> 
                            </div>
                </div>
                {/* {this.state.agent.residentialType !== 'LAND' ? */}
                    <div className='mt-6'>
                        <h5>{this.state.lang ? 'Special conditions':'ลักษณะพิเศษ'}</h5>
                        <div className='ml-3 pb-2'>
                            <div><input type="checkbox" name="remarkPet" checked={this.state.agent?.remarkPet}  disabled/> <span className='pl-2'>{this.state.lang  ? 'Pets Allowed':'อนุญาตให้เลี้ยงสัตว์'}</span></div>
                            <div><input type="checkbox" name="remarkForeign"  checked={this.state.agent?.remarkForeign}  disabled/> <span className='pl-2'>{this.state.lang  ? 'Foreigner quota':'มีโควตาต่างชาติ'}</span></div>
                            <div><input type="checkbox" name="remarkDuplex" checked={this.state.agent?.remarkDuplex} disabled/> <span className='pl-2'>{this.state.lang ? 'Duplex':'ห้องแบบ Duplex'}</span></div>
                            <div><input type="checkbox" name="remarkPenthouse"  checked={this.state.agent?.remarkPenthouse} disabled/> <span className='pl-2'>{this.state.lang ? 'Penthouse':'ห้องแบบ Penthouse'}</span></div>
                            {
                                 this.state.agent?.userType === 'TENANT' || this.state.agent?.userType === 'BUYER' ? 
                                 <div><input type="checkbox" className='myRemark' name="remarkHighFloor" checked={this.state.agent?.remarkHighFloor}  disabled/> <span className='pl-2'>HighFloor</span></div> :
                                 ''
                               }
                            <div><input type="checkbox" name="autoCheckOther"  checked={this.state.agent?.remark ? true : false} disabled/> 
                            <span className='pl-2'> {this.state.lang ? 'Other':'อื่นๆ'}
                                {
                                    this.state.agent.remark && <input type="text" className="form-control" value={this.state.agent?.remark} disabled/>
                                }
                             </span></div>
                        </div>
                    </div>
                            {/* :''
                } */}
                    <div className='mt-6'>
                        <h5>{this.state.lang ? 'Image':'รูปภาพ'}</h5>
                        <div className='svm-agent-img'>
                                {
                                this.state.agent?.silvermanAgentImage?.edges?.length > 0   ?
                                    this.state.agent?.silvermanAgentImage?.edges.map((n, inx) => {
                                            return (
                                            // <div style={{paddingBottom:'10px'}}>
                                            //     <img src={n.node.fileUpload} className="img-agent-items" />
                                            // </div>
                                            <div style={{paddingBottom:'10px'}}>
                                                <Link to="route"  onClick={(event) => {
                                                    event.preventDefault();
                                                    window.open(
                                                    typeof n.node.fileUpload === "string"
                                                        ? n.node.fileUpload
                                                        : URL.createObjectURL(n.node.fileUpload))
                                                    }}
                                                        target="blank">
                                                    <img src={n.node.fileUpload} className="img-agent-items"/>
                                                </Link>
                                             </div>
                                        )
                                    })
                                    :<span className='text-danger'>{this.state.lang ? 'No Image':'ไม่มีรูปภาพ'}</span>
                                }
                        </div>
                    </div>
                    <div className='mt-6'>
                        <h5>{this.state.lang ? 'Price':'ราคา'}</h5>
                            {
                                this.state.agent.announceSell &&
                                <div className='mt-2'>
                                    <span>{this.state.lang ? 'Price':'ราคาขาย'} <span className='text-danger'>*</span> </span>
                                    <input type="text" className="form-control" name='price' value={numberWithComma(this.state.agent.price,'0.00')} disabled/> 
                                </div>
                            }
                             {
                                this.state.agent.announceForRent && 
                                <div className='mt-2'>
                                    <span>{this.state.lang ? '':'วันที่พร้อมให้เช่า'}</span>
                                    <div className='datePicker mb-4'>
                                        <DatePicker
                                            name='rentDate'
                                            className="form-control searchDateBox"
                                            value={this.state.agent.rentDate}
                                            dateFormat="dd/MM/yyyy"
                                            disabled
                                        />
                                    </div>
                                    <span className='mt-2'>{this.state.lang ? 'Rent Price':'ค่าเช่ารายเดือน'} <span className='text-danger'>*</span> </span>
                                    <input type="text" className="form-control" name='rentPrice' value={numberWithComma(this.state.agent.rentPrice,'0.00')} disabled/> 
                                </div>
                            }
                            {
                                this.state.agent.announceBuy && 
                                <div className='mt-2'>
                                    <span>{this.state.lang ? 'Buy':'งบซื้อ'}<span className='text-danger'>*</span> </span>
                                    <input type="text" name="buyPrice" className="form-control" value={numberWithComma(this.state.agent.buyPrice,'0.00')} disabled />
                                </div>
                            }
                            {
                                this.state.agent.announceLookRent && 
                                <div className='mt-2'>
                                    <span>{this.state.lang ? 'Rent':'งบจ่ายค่าเช่า'}<span className='text-danger'>*</span> </span>
                                    <input type="text" className="form-control" name="lookRentPrice" value={numberWithComma(this.state.agent.lookRentPrice,'0.00')} disabled />
                                    <h5 className='mt-2'>{this.state.lang  ? 'Period contact':'ระยะสัญญา'}</h5>
                                    <div className='row'>
                                        <div className='col'><input type="checkbox" name="twelveTerm" checked={this.state.agent.twelveTerm} disabled/> {this.state.lang ? '12 Month':'12 เดือน'}</div>
                                        <div className='col'><input type="checkbox" name="sixTerm" checked={this.state.agent.sixTerm} disabled/> {this.state.lang ? '6 Month':'6เดือน'}</div>
                                        <div className='col'><input type="checkbox" name="thirdTerm" checked={this.state.agent.thirdTerm} disabled/> {this.state.lang ? '3 Month':'3 เดือน'}</div>
                                    </div>
                                </div>
                            }
                            {/* {
                                this.state.agent.announceDown && 
                                <div className='mt-2'>
                                    <span>ราคาขายดาวน์ <span className='text-danger'>*</span> </span>
                                    <div className='mb-2'><input type="text" className="form-control" name='downPrice' value={this.state.agent.downPrice} disabled /></div>
                                    <span className='mt-2'>ราคาขายจริง <span className='text-danger'>*</span> </span>
                                    <input type="text" name="realPrice" className="form-control" value={this.state.agent.realPrice} disabled/>
                                </div>
                            }
                            {
                                this.state.agent.announceLease && 
                                <div className='mt-2'>
                                    <span>ค่าเซ้ง <span className='text-danger'>*</span> </span>
                                    <input type="text" name="leasePrice" className="form-control" value={this.state.agent.leasePrice} disabled />
                                </div>
                            }
                            {
                                this.state.agent.announceBuy && 
                                <div className='mt-2'>
                                    <span>งบซื้อ<span className='text-danger'>*</span> </span>
                                    <input type="text" name="buyPrice" className="form-control" value={this.state.agent.buyPrice} disabled />
                                </div>
                            }
                            {
                                this.state.agent.announceLookRent && 
                                <div className='mt-2'>
                                    <span>งบจ่ายค่าเช่า<span className='text-danger'>*</span> </span>
                                    <input type="text" className="form-control" name="lookRentPrice" value={this.state.agent.lookRentPrice} disabled />
                                    <h5 className='mt-2'>ระยะสัญญา</h5>
                                    <div className='row'>
                                        <div className='col'><input type="checkbox" name="thirdTerm" checked={this.state.agent.thirdTerm} disabled/> 12เดือน</div>
                                        <div className='col'><input type="checkbox" name="sixTerm" checked={this.state.agent.sixTerm} disabled/> 6เดือน</div>
                                        <div className='col'><input type="checkbox" name="twelveTerm" checked={this.state.agent.twelveTerm} disabled/> 3เดือน</div>
                                    </div>
                                </div>
                            }
                         */}
                    </div>
                   
                    <div className='mt-6'>
                        <h5>{this.state.lang ? 'Contact Name':'ชื่อผู้ติดต่อ'}</h5>
                        <div><input type="text" className="form-control" value={this.state.agent.contactName} disabled/></div>
                        {/* <h5 className='mt-2'>ชื่อผู้ประกาศ</h5>
                        <div><input type="text" className="form-control" value={this.state.agent.callName} disabled/></div> */}
                    </div>
                    <div className='mt-6'>
                        <h5>{this.state.lang  ? 'Contact':'ช่องทางติดต่อ'}</h5>
                        <span>{this.state.lang ? 'Phone':'เบอร์'}</span>
                        <div className='mb-2'><input type="text" className="form-control" value={this.state.agent.phone} disabled/></div>
                        <span>ID Line</span>
                        <div className='mb-2'><input type="text" className="form-control" value={this.state.agent.line} disabled/></div>
                        <span>E-mail </span>
                        <div className='mb-2'><input type="text" className="form-control" value={this.state.agent.email} disabled/></div>
                    </div>
                    <div className='mt-6 mb-6'>
                    <div style={{width:'62%'}}>
                        <h5>{this.state.lang ? 'Which channels do you want Silverman Agent to contact?':'อยากให้ Silverman Agent ติดต่อผ่านช่องทางไหน?'}  </h5>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <input type="checkbox" name="silvermanPhone" checked={this.state.agent.silvermanPhone} disabled /><span className='pl-2'>{this.state.lang ? 'Phone':'โทรศัพท์'}</span>
                        </div>
                        <div className='col'>
                            <input type="checkbox" name="silvermanLine" checked={this.state.agent.silvermanLine} disabled /><span className='pl-2'>Line</span>
                        </div>
                       <div className='col'>
                            <input type="checkbox" name="silvermanEmail" checked={this.state.agent.silvermanEmail} disabled /><span className='pl-2'>{this.state.lang ? 'Email':'อีเมลล์'}</span>
                       </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}


export default SilvermanAgentView;