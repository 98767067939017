
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import '../receivable.scss'
import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import PrintA4horizontal from '../../../../libs/printA4horizontal';
import environment from '../../../../env/environment';
import ReceivablePDFTable from './receivablePDFTable';
import jwtDecode from 'jwt-decode'
import { format } from 'date-fns';
const query = graphql`
  query receivablePDFAllQuery{
    selfProject{
      name
      address
      logo
    }
  }
`;

//รวมการชำระเงินที่เป็นไปได้
class ReceivablePDFAll extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            optionAction: false,
            data: null,
            dataReport: [],
            wroksheetView: [],
            allResidential: [],
            page: 1,
            pageAll: 0,
            pageSize: 100,
            totalCount: 0,
            index_first: 0,
            index_last: 100,
            startDate: "",
            endDate: "",
            projectName: "",
            projectLogo: "",
            projectAddr: "",
            tableHeader: [],
            tableSummary: [],
            dataAllParse: [],
            summaryTableFooter: [],
            temp_payment_channel: "",
            temp_product_and_service: "",
            product_list: [],

            PrintBy: "",
            loading: true
        }
    }

    componentWillMount() {
        fetchQuery(environment, query).then((res) => {
            if (res.selfProject) {
                let dataAllParse = []
                let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
                let numberChkPage = 0
                let dataMap = []
                // let dataOver = null
                // let chkRecordIsOver = false

                // let dataMap_test = this.MapOnlyTran(this.props.location?.state?.data?.queryListExport?.dataTable)
                let dataMap_test = this.MapOnlyTran(this.props.location?.state?.data?.queryListExport?.dataTable)
                if (dataMap_test) {
                    dataMap_test.map((n, indexdataTable) => {
                        numberChkPage += 1
    
                        dataMap.push(n)
    
                        if (numberChkPage === 14) {
                            dataAllParse.push(dataMap)
                            dataMap = []
                            numberChkPage = 0
                        } else if (dataMap_test.length === (indexdataTable + 1)) {
                            dataAllParse.push(dataMap)
                        }
    
                        // if (chkRecordIsOver == false && dataOver) {
                        //     console.log("chkRecordIsOver == false && dataOver");
                        //     numberChkPage = numberChkPage + (mapparse.receive.other.detail.length - 1)
                        //      _.set(mapparse , `Isover` , true)
                        //     dataMap.push(dataOver);
                        //     dataOver = null
                        // }
    
                        // // เช็คประเภท ที่มีมากว่า 1 รายการ
                        // numberChkPage = mapparse.receive.other.detail.length > 1 ? numberChkPage + (mapparse.receive.other.detail.length - 1) : numberChkPage + 0
                        // // เช็คประเภท ที่มีรายการ
                        // numberChkPage = mapparse.payment.type.length > 1 ? numberChkPage + (mapparse.payment.type.length - 1) : numberChkPage + 0
    
                        // if (numberChkPage <= 14) {
                        //     dataMap.push(mapparse);
                        // }
    
                        // if (numberChkPage > 14) {
                        //     // console.log(mapparse.payment.type.length, numberChkPage , mapparse);
                        //     chkRecordIsOver = true
                        //     dataOver = mapparse
                        //     _.set(mapparse , `Isover` , true)
                        //     dataMap.push(mapparse);
    
                        //     dataAllParse.push(dataMap);
                        // } else {
                        //     (indexdataTable + 1) === this.props.location?.state?.data?.queryListExport?.dataTable?.length && dataAllParse.push(dataMap);
                        //     numberChkPage >= 13 && dataAllParse.push(dataMap);
                        // }
    
                        // if (numberChkPage >= 13 || chkRecordIsOver) {
                        //     numberChkPage = 0
                        //     dataMap = []
                        // }
                        return n
                    })
                }



                this.setState({
                    projectLogo: res.selfProject.logo,
                    projectName: res.selfProject.name,
                    projectAddr: res.selfProject.address,
                    data: this.props.location?.state?.data?.queryListExport?.dataTable, //queryListExport
                    tableHeader: this.props.location?.state?.data?.queryListExport?.productAndServiceHeadTable,
                    tableSummary: JSON.parse(this.props.location?.state?.data?.queryListExport?.paymentChannelTable),
                    summaryTableFooter: JSON.parse(this.props.location?.state?.data?.queryListExport?.summaryTable),
                    PrintBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
                    loading: false,
                    dataAllParse: dataAllParse,
                    startDate: this.props.location?.state?.data?.temp_start_date,
                    endDate: this.props.location?.state?.data?.temp_end_date,
                    temp_payment_channel: this.props.location?.state?.data?.temp_payment_channel,
                    temp_product_and_service: this.props.location?.state?.data?.temp_product_and_service,
                    product_list: this.props.location?.state?.product_list,
                })
            }
        })
    }

    MapOnlyTran(date) {
        let map_date = []
        let map_objects = []
        const lastRecorde = 14

        date.forEach((date_s, index) => {
            let mapparse = JSON.parse(date_s)

            // First บรรทัดแรกที่เจอ
            let objects = {
                no: mapparse.no,
                issued_date: mapparse.issued_date,
                doc_number: mapparse.doc_number,
                room: mapparse.room,
                name: mapparse.name,
                payment1: mapparse.receive[1],
                payment2: mapparse.receive[2],
                payment3: mapparse.receive[3],
                payment4: mapparse.receive[4],
                payment5: mapparse.receive[5],
                other_detail: mapparse.receive.other.detail.length >= 1 ? mapparse.receive.other.detail[0] : '',
                other_price: mapparse.receive.other.price.length >= 1 ? mapparse.receive.other.price[0] : 0,
                type: mapparse.payment.type.length >= 1 ? mapparse.payment.type[0] : '',
                type_datail: mapparse.payment.detail.length >= 1 ? mapparse.payment.detail[0] : '',
                type_pay: mapparse.payment.total.length >= 1 ? mapparse.payment.total[0] : 0,
                total: null,
                // total: mapparse.payment.type.length === (index + 1) ? mapparse.amount : null,
            }

            map_objects.push(objects)

            //Other
            mapparse.receive.other.detail.forEach((e, index) => {
                if (index !== 0) {
                    let objects_other = {
                        no: null,
                        issued_date: null,
                        doc_number: null,
                        room: null,
                        name: null,
                        payment1: null,
                        payment2: null,
                        payment3: null,
                        payment4: null,
                        payment5: null,
                        type: '',
                        other_detail: e,
                        other_price: mapparse.receive.other.price[index],
                        type: '',
                        type_datail: '',
                        type_pay: 0,
                        total: null,
                        // total: null,
                    }
                    map_objects.push(objects_other)
                }
            })

            //Payment
            mapparse.payment.type.forEach((e, index) => {
                if (index !== 0) {
                    let objects_other = {
                        no: null,
                        issued_date: null,
                        doc_number: null,
                        room: null,
                        name: null,
                        payment1: null,
                        payment2: null,
                        payment3: null,
                        payment4: null,
                        payment5: null,
                        type: '',
                        other_detail: '',
                        other_price: 0,
                        type: e,
                        type_datail: mapparse.payment.detail[index],
                        type_pay: mapparse.payment.total[index],
                        total: null,
                    }
                    map_objects.push(objects_other)
                }
            })

            // console.log(map_objects);
            _.set(map_objects[map_objects.length - 1], "total", mapparse.amount)


            // Bug ไม่อยากวน Loop หน้าล่าง เดวจะมาแก้
            // if (map_objects.length == lastRecorde){
            //     map_date.push(map_objects)
            //     map_objects = []
            // }
            // else if (map_objects.length > lastRecorde){
            //     map_date.push(map_objects.slice(0,lastRecorde))
            //     map_objects = map_objects.slice(lastRecorde+1,map_objects.length)
            // }else if (index + 1 === date.length){
            //     map_date.push(map_objects)
            // }

        });

        //slice array
        let array_recorde = []
        let count = 0
        map_objects.forEach((e, index) => {
            // console.log(index);
            if (count === lastRecorde) {
                map_date.push(array_recorde)
                array_recorde = []
                count = 0
            } else if (map_objects.length === index + 1) {
                map_date.push(array_recorde)
            }
            array_recorde.push(e)
            count++;
        })
        // console.log(map_objects);

        return map_objects;

    }

    chkNumberMinus(number) {
        return (
            parseInt(number) > 0 ? parseFloat(number).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') :
                parseInt(number) === 0 && number === "0.0" ? "-" : 
                <strong className='text-red'>({parseFloat(number).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})</strong>
        )
    }

    getKeyPaymentChannel(keyWord) {
        let valueWord = ""

        switch (keyWord) {
            case "cash":
                valueWord = "เงินสด"
                break;
            case "bank":
                valueWord = "เงินโอน"
                break;
            case "bank_account":
                valueWord = "เงินโอน"
                break;
            case "cross_bill":
                valueWord = "Cross bill"
                break;
            case "payment_gateway":
                valueWord = "เพย์เมนท์เกตเวย์"
                break;
            case "credit_card":
                valueWord = "บัตรเครดิต"
                break;
            case "cheque":
                valueWord = "เช็ค"
                break;
            case "other":
                valueWord = "อื่นๆ"
                break;
            default:
                valueWord = "ทั้งหมด"
                break;
        }
        return valueWord;
    }

    getProductList(temp_product_and_service) {
        let value = ""
        let listProductAndService = []

        listProductAndService = _.filter(this.state.product_list, (o) => o.node.id === temp_product_and_service)

        if (listProductAndService.length > 0) {
            value = `${listProductAndService[0].node?.productCode} ${listProductAndService[0].node?.name}`
        } else {
            value = "ทั้งหมด"
        }

        return value
    }

    chkNumberEqualNotZero(number) {
        let data = parseInt(number) > 0 ? number : "-"
        return data
    }



    render() {
        let numTpyePay = parseInt(this.state.tableSummary?.cash?.num) +
            parseInt(this.state.tableSummary?.bank_account?.num) +
            parseInt(this.state.tableSummary?.cross_bank?.num) +
            parseInt(this.state.tableSummary?.credit_card?.num) +
            parseInt(this.state.tableSummary?.cheque?.num) +
            parseInt(this.state.tableSummary?.payment_gateway?.num) +
            parseInt(this.state.tableSummary?.other?.num);
        let totalTpyePay = parseFloat(this.state.tableSummary?.cash?.total) +
            parseFloat(this.state.tableSummary?.bank_account?.total) +
            parseFloat(this.state.tableSummary?.cross_bank?.total) +
            parseFloat(this.state.tableSummary?.credit_card?.total) +
            parseFloat(this.state.tableSummary?.cheque?.total) +
            parseFloat(this.state.tableSummary?.payment_gateway?.total) +
            parseFloat(this.state.tableSummary?.other?.total);
        return (
            <React.Fragment >
                <PrintA4horizontal />
                {
                    <div id="receivablePDF" key={"receivablePDF"}>
                        <div className="print-top-menu">
                            <div className="logo">
                                <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                            </div>
                            <div className="print" onClick={() => window.print()}>
                                PRINT
                            </div>
                        </div>

                        {
                            this.state.dataAllParse.map((nn, index) => {
                                return (
                                    
                                    <div className={"print-page-a4 hoziA4"} key={"index-reciceve" + index}>
                                        <div className="" >
                                            {/* subpage head */}
                                            <div className="row">
                                                <div className='col-4 text-left'>
                                                    <p className='f12' style={{ lineHeight: 1.5 }}>{this.state.projectName}</p>
                                                    <p className='f12'>รายงานรวมรับชำระเงินประจำวัน</p>
                                                    <p className='f14'>ประเภทสินค้าและบริการ - {this.getProductList(this.state.temp_product_and_service)}</p>
                                                    <p className='f14'>วิธีชำระเงิน - {this.getKeyPaymentChannel(this.state.temp_payment_channel)}</p>
                                                    <p className='f14'>เรียงตามวันที่ {format(this.state.startDate, "DD/MM/YYYY")} ถึง {format(this.state.endDate, "DD/MM/YYYY")}</p>
                                                </div>
                                                <div className='col-8 text-right'>
                                                    {/* ประเภทการรับชำระเงิน */}
                                                    {index === 0 &&
                                                        <section className="bodys">
                                                            <table className='table'>
                                                                <thead className=' text-center'>
                                                                    <tr>
                                                                        <th style={{ maxWidth: 150 }}>ประเภทการรับชำระเงิน</th>
                                                                        <th>เงินสด</th>
                                                                        <th>เงินโอน</th>
                                                                        <th>Crossbill</th>
                                                                        <th>เช็ค</th>
                                                                        <th>บัตรเครดิต</th>
                                                                        <th>เพย์เมนท์เกตเวย์</th>
                                                                        <th>อื่นๆ</th>
                                                                        <th>รวม</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='text-center'>
                                                                    <tr key={"ประเภทการรับชำระเงิน"}>
                                                                        <th className=''>รายการ</th>
                                                                        <td>{this.chkNumberEqualNotZero(this.state.tableSummary?.cash?.num)}</td>
                                                                        <td>{this.chkNumberEqualNotZero(this.state.tableSummary?.bank_account?.num)}</td>
                                                                        <td>{this.chkNumberEqualNotZero(this.state.tableSummary?.cross_bank?.num)}</td>
                                                                        <td>{this.chkNumberEqualNotZero(this.state.tableSummary?.cheque?.num)}</td>
                                                                        <td>{this.chkNumberEqualNotZero(this.state.tableSummary?.credit_card?.num)}</td>
                                                                        <td>{this.chkNumberEqualNotZero(this.state.tableSummary?.payment_gateway?.num)}</td>
                                                                        <td>{this.chkNumberEqualNotZero(this.state.tableSummary?.other?.num)}</td>
                                                                        <th className=''>{
                                                                            parseInt(numTpyePay).toFixed(0) !== 0 ? parseInt(numTpyePay).toFixed(0) : "-"
                                                                        }</th>
                                                                    </tr>
                                                                    <tr key={"จำนวนเงิน"}>
                                                                        <th className=''>จำนวนเงิน</th>
                                                                        <td>{this.chkNumberMinus(this.state.tableSummary?.cash?.total)}</td>
                                                                        <td>{this.chkNumberMinus(this.state.tableSummary?.bank_account?.total)}</td>
                                                                        <td>{this.chkNumberMinus(this.state.tableSummary?.cross_bank?.total)}</td>
                                                                        <td>{this.chkNumberMinus(this.state.tableSummary?.cheque?.total)}</td>
                                                                        <td>{this.chkNumberMinus(this.state.tableSummary?.credit_card?.total)}</td>
                                                                        <td>{this.chkNumberMinus(this.state.tableSummary?.payment_gateway?.total)}</td>
                                                                        <td>{this.chkNumberMinus(this.state.tableSummary?.other?.total)}</td>
                                                                        <th className=''>{
                                                                            this.chkNumberMinus(totalTpyePay)
                                                                        }</th>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot></tfoot>
                                                            </table>
                                                        </section>
                                                    }
                                                </div>
                                            </div>
                                            {/* รายงานรวมรับชำระเงินประจำวัน */}
                                            <ReceivablePDFTable
                                                data={nn} lastPage={(index + 1) === this.state.dataAllParse.length}
                                                tableHeader={this.state.tableHeader}
                                                summaryTable={this.state.summaryTableFooter}
                                            />
                                        </div>

                                        <div className='footer'>
                                            <div className='row'>
                                                <div className='col-5' />
                                                <div className='col-2 text-center'>
                                                    <p className='f10 pb-0 mb-0'>{index + 1}/{this.state.dataAllParse.length}</p>
                                                </div>
                                                <div className='col-5 text-right'>
                                                    <p className='f10 pb-0 mb-0'>ผู้พิมพ์ {this.state.PrintBy} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(ReceivablePDFAll)






