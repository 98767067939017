import '../styles/withholdingTax53Print.scss';
import React, {Component} from 'react';
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import getNumberSplitDecimal from "../../libs/getNumberSplitDecimal";
import { getTotalpayRecord } from "../../libs/getTotalpayRecord";

class WithholdingAttachmentTax53WithDataPrint extends Component {

    constructor(props) {
        super(props);
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];

        this.state = {
            bangkokCode: bangkokCode,
            withData: false,
            taxIdNumber: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
            data_list: [],
        };
        this.print = this.print.bind(this);
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.dynamicFont = this.dynamicFont.bind(this);
    }

    componentDidMount() {

        if (this.props.project) {
            this.setState({withData: true});
            if (_.isObject(this.props.project)) {
                if (this.props.project.taxIdNumber) {
                    this.setState({taxIdNumber: this.props.project.taxIdNumber.split('')})
                }
            }
        }
        if (this.props.data_array) {
            this.setState({
                data_list: this.props.data_array,
            });
        }
    }

    print() {
        window.print();
    }

    displayBranch(branch, key, have_data = true,isSliceData) {
        return branch.map((value, index) => {
            return (
                <React.Fragment key={key + index}>
                    {have_data && !isSliceData ?
                        <span className="border-branch-number">{value}</span>
                        :
                        <span className="border-branch-number">&nbsp;&nbsp;</span>
                    }
                </React.Fragment>
            )
        })
    }

    displayTaxIdNumber(tax_id, key, have_data = true,isSliceData) {
        return tax_id.map((value, index) => {
            if (index === 1 || index === 5 || index === 10 || index === 12) {
                return (
                    <React.Fragment key={key + index}>
                        <span>-</span>
                        {this.state.withData && have_data && !isSliceData ?
                            <span className="border-id-number">{value}</span>
                            :
                            <span className="border-id-number">&nbsp;&nbsp;</span>
                        }
                    </React.Fragment>
                )
            }
            return (
                <React.Fragment key={key + index}>
                    {
                        this.state.withData && have_data && !isSliceData ?
                            <span className="border-id-number">{value}</span> :
                            <span className="border-id-number">&nbsp;&nbsp;</span>
                    }
                </React.Fragment>
            )
        })
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(type, code) {
        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        } else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return "จ."
        } else if (type === "district") {
            return "ต."
        } else if (type === "city") {
            return "อ."
        }
    }

    dynamicFont(){
        if (this.props.maxLength <= 24) {
            return { zoom : '100%'}
        }else if (this.props.maxLength <= 35) {
            return { zoom : '92%'}
        }else if (this.props.maxLength <= 50) {
            return { zoom : '55%'}
        }else{
            return { zoom : '55%'}
        }
    }
    
    render() {
        let total = 0.0;
        let total_rate = 0.0;
        return (
            <div id="withholding-tax-53-print">
                <div className="print-page-withholding-a4">
                    <div className="subpage">
                        <div className="withholding-tax-body">
                            <div>
                                <span className="text-header">ใบแนบ <span className="text-header-53">ภ.ง.ด.53</span> เลขประจำตัวผู้เสียภาษีอากร (ของผู้มีหน้าที่หักภาษี ณ ที่จ่าย )</span>
                                <span className="ml-3 mr-3">
                                    {this.displayTaxIdNumber(this.state.taxIdNumber, 'taxNum')}
                                </span>
                                <span className="text-header mr-2">สาขาที่</span>
                                {this.displayBranch([0, 0, 0, 0, 0], 'branchNum',true,false)}
                            </div>

                            <div
                                className="header-right">แผ่นที่.....{numberWithComma(this.props.page, '-', 0)}.....ใบจำนวน.....{numberWithComma(this.props.total_page, '-', 0)}.....แผ่น
                            </div>

                            <table className="table table-bordered mt-1">
                                <thead>
                                <tr>
                                    <th rowSpan="2" className="text-header-center" width={"4%"}>ลำดับที่</th>
                                    <th colSpan="4" className="text-center" width={"35%"}>
                                        <span className="text-th-bold">เลขประจำตัวผู้เสียภาษีอากร</span> (ของผู้มีเงินได้)
                                    </th>
                                    <th rowSpan="2" className="text-header-center" width={"8%"}>
                                        <span className="text-th-bold">สาขาที่</span>
                                    </th>
                                    <th colSpan="6" className="text-center" width={"40%"}>
                                        <strong>รายละเอียดเกี่ยวกับการจ่ายเงิน</strong>
                                    </th>
                                    <th colSpan="2" rowSpan="2" className="text-header-center" width={"10%"}>
                                        <span className="text-th-bold">จำนวนเงินภาษีที่หักและนำส่งในครั้งนี้</span>
                                    </th>
                                    <th rowSpan="2" className="text-header-center text-land p-0" width={"4%"}>
                                        เงื่อนไข <img src={process.env.PUBLIC_URL + '/images/print/number-two.png'}
                                                      alt="correct-signal" className="number"/></th>
                                </tr>
                                <tr>
                                    <th colSpan="4" className="text-center"><span
                                        className="text-th-bold">ชื่อและที่อยู่ของผู้มีเงินได้</span>
                                        <br/> (ให้ระบุให้ชัดเจนว่าเป็น บริษัทจำกัด
                                        ห้างหุ้นส่วนจำกัด หรือห้างหุ้นส่วนสามัญนิติบุคคล<br/>
                                        และให้ระบุเลขที่ ตรอก/ซอย ถนน ตำ บล/แขวง อำ เภอ/เขต จังหวัด)
                                    </th>
                                    <th className="text-header-center" width={"7%"}>
                                        <span className="text-th-bold">วัน เดือน ปี <br/>ที่จ่าย</span>
                                    </th>
                                    <th colSpan="2" className="text-header-center text-th-number" width={"13%"}>
                                        <img src={process.env.PUBLIC_URL + '/images/print/number-one.png'}
                                             alt="correct-signal" className="number"/>
                                        <span className="text-th-bold"> ประเภทเงินได้ <br/>พึงประเมินที่จ่าย</span>
                                    </th>
                                    <th className="text-header-center" width={"4%"}>อัตราภาษีร้อยละ</th>
                                    <th colSpan="2" className="text-header-center" width={"11%"}><span
                                        className="text-th-bold">จำนวนเงินที่จ่ายในครั้งนี้</span></th>
                                </tr>
                                </thead>
                                <tbody>

                                {[0, 1, 2, 3, 4, 5].map((index) => {
                                    let tax_id = ["\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0", "\u00a0\u00a0"];
                                    if (this.props.data_array[index]) {
                                        let value = this.props.data_array[index];
                                        if (this.props.data_array[index].node.contact.taxIdNumber) {
                                            tax_id = this.props.data_array[index].node.contact.taxIdNumber.split('')
                                        }

                                        let description = [];
                                        let rate_list = [];
                                        let total_tax_list = [];
                                        let type_of_tax = [];
                                        let total_list = [];
                                        let PayRecordSum = 0
                                        let OtherExpenseSum = 0

                                        let isSliceData = false;
                                        let order = 0;
                                        if(this.props.data_array[index].isSliceData){
                                            isSliceData = this.props.data_array[index].isSliceData
                                        }
                                        if(this.props.data_array[index].order){
                                            order = this.props.data_array[index].order
                                        }

                      
                                        if (value.node.payRecordGroup) {
                                            let purchase = value.node.payRecordGroup.payRecord.edges[0].node.purchaseRecord;
                                            value.node.payRecordGroup.payRecord.edges.map((payRecord)=>{
                                                if (payRecord.node.purchaseRecord.whtRate) {
                                                    PayRecordSum += getTotalpayRecord(payRecord);
                                                    total_list.push(getTotalpayRecord(payRecord))
                                                    // description.push(payRecord.node.purchaseRecord.description)
                                                    description.push(payRecord.node.purchaseRecord.typeOfIncomeOther ? payRecord.node.purchaseRecord.typeOfIncomeOther : payRecord.node.purchaseRecord.description)
                                                    total_tax_list.push(payRecord.node.whtAmount)
                                                    if (payRecord.productAndService) {
                                                        let product = payRecord.productAndService;
                                                        rate_list.push(product.taxRate ? product.taxRate : product.taxRateOther)
                                                    }else{
                                                        rate_list.push(payRecord.node.purchaseRecord.whtRatePercent)
                                                    }
                                                }
                                            })
                                            if (purchase.productAndService) {
                                                let product = purchase.productAndService;
                                                // if (product.typeOfTax) {
                                                //     type_of_tax = product.typeOfTax
                                                // }
                                            } 
                                        }else if(value.node.prepaidDeposit){
                                            value.node.prepaidDeposit.prepaidDepositTransaction.edges.map((payRecord)=>{
                                                if (payRecord.node.whtRatePercent) {
                                                    total_list.push(payRecord.node.price)
                                                    description.push(payRecord.node.description)
                                                    rate_list.push(payRecord.node.whtRatePercent)
                                                    total_tax_list.push(payRecord.node.whtAmount)
                                                }
                                            })
                                        }else {
                                            value.node.otherExpense.otherExpenseTransaction.edges.map((payRecord)=>{
                                                if (payRecord.node.whtRate) {
                                                    OtherExpenseSum += payRecord.node.preTaxAmount
                                                    total_list.push(payRecord.node.preTaxAmount)
                                                    // description.push(payRecord.node.description)

                                                    description.push(payRecord.node.typeOfIncomeOther ? payRecord.node.typeOfIncomeOther : payRecord.node.description)
                                                    rate_list.push(payRecord.node.whtRatePercent)
                                                    total_tax_list.push(payRecord.node.whtRate)
                                                }
                                                if(payRecord.node.productAndService){
                                                    type_of_tax.push(payRecord.node.productAndService.typeOfTax)
                                                }
                                            })
                                        }

                                        total += PayRecordSum + OtherExpenseSum + (value.node.totalPrepaidDeposit || 0);
                                        total_rate += value.node.total;

                                        return (

                                            <React.Fragment key={'row-data' + index}>
                                                <tr>
                                                    <td rowSpan="3" className="text-index-center">{(this.props.start_page + index && !isSliceData) && order}</td>
                                                    <td colSpan={4} className="no-border-bottom">
                                                        {this.displayTaxIdNumber(tax_id, 'rowTaxNum',true,isSliceData)}
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {this.state.withData && value.node.contact.nameBranch && value.node.contact.nameBranch.length === 5 ?
                                                            this.displayBranch(value.node.contact.nameBranch.split(''), 'rowBranchNum',true,isSliceData)
                                                            :
                                                            this.displayBranch([0, 0, 0, 0, 0], 'rowBranchNum',true,isSliceData)
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {/*วัน เดือน ปี*/}
                                                        <p className="border-dot">{isSliceData ? "\u00a0" : format(value.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</p>
                                                    </td>
                                                    <td colSpan={2} className="no-border-bottom">
                                                        {/*ประเภทเงินได้พึงประเมินที่จ่าย*/}
                                                        {description[0]?
                                                            <p className="border-dot" style={this.dynamicFont()} >{description[0]?.substr(0, 50)}</p>:
                                                            <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                        
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {/*อัตราภาษีร้อยละ*/}
                                                        {rate_list[0]?<p className="border-dot">{numberWithComma(rate_list[0], '-')}</p>:
                                                        <p className="border-dot">{"\u00a0"}</p>}
                                                    </td>
                                                    <td className="no-border-bottom text-right">
                                                        {/*จำนวนเงินที่จ่ายในครั้งนี้*/}
                                                        {total_list[0]?<p className="border-dot">{getNumberSplitDecimal(total_list[0], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-left" width={"4%"}>
                                                        {/*ทศนิยม*/}
                                                        {total_list[0]?<p className="border-dot">{getNumberSplitDecimal(total_list[0], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-right">
                                                        {/*จำนวนเงินภาษีที่หักและนำส่งในครั้งนี้*/}
                                                        {total_tax_list[0]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[0], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-left" width={"4%"}>
                                                        {/*ทศนิยม*/}
                                                        {total_tax_list[0]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[0], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {/*เงื่อนไข*/}
                                                        <p className="border-dot">{type_of_tax[0] ? type_of_tax[0] : 1}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} className="no-border-bottom">
                                                        <p className="border-dot">ชื่อ {!isSliceData ? value.node.contact.name : ""}</p>
                                                    </td>
                                                    <td className="no-border-bottom"/>
                                                    <td className="no-border-bottom">
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td colSpan={2} className="no-border-bottom">
                                                        {/* <p className="border-dot">{"\u00a0"}</p> */}
                                                        {description[1]?
                                                            <p className="border-dot" style={this.dynamicFont()} >{description[1]?.substr(0, 50)}</p>:
                                                            <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {/*อัตราภาษีร้อยละ*/}
                                                        {rate_list[1]?<p className="border-dot">{numberWithComma(rate_list[1], '-')}</p>:
                                                        <p className="border-dot">{"\u00a0"}</p>}
                                                    </td>
                                                    <td className="no-border-bottom text-right">
                                                        {/*จำนวนเงินที่จ่ายในครั้งนี้*/}
                                                        {total_list[1]?<p className="border-dot">{getNumberSplitDecimal(total_list[1], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-left" width={"4%"}>
                                                        {/*ทศนิยม*/}
                                                        {total_list[1]?<p className="border-dot">{getNumberSplitDecimal(total_list[1], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-right">
                                                        {/*จำนวนเงินภาษีที่หักและนำส่งในครั้งนี้*/}
                                                        {total_tax_list[1]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[1], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-left" width={"4%"}>
                                                        {/*ทศนิยม*/}
                                                        {total_tax_list[1]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[1], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {total_tax_list[1]?<p className="border-dot">{type_of_tax[1]? type_of_tax[1] : 1 }</p>:<p className="border-dot">{"\u00a0"}</p>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>
                                                        {!isSliceData ? 
                                                        <p className="border-dot">
                                                            ที่อยู่ {value.node.contact.registeredAddress + " "}
                                                            {value.node.contact.registeredDistrict && this.getPrefix("district", value.node.contact.registeredPostalCode) + value.node.contact.registeredDistrict + " "}
                                                            {value.node.contact.registeredCity && this.getPrefix("city", value.node.contact.registeredPostalCode) + value.node.contact.registeredCity + " "}
                                                            {value.node.contact.registeredProvince && this.getPrefix("province", value.node.contact.registeredPostalCode) + value.node.contact.registeredProvince + " "}
                                                            {value.node.contact.registeredPostalCode + " "}
                                                        </p> :
                                                        <p className="border-dot">
                                                            ที่อยู่
                                                        </p>
                                                        }
                                                    </td>
                                                    <td/>
                                                    <td>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        {/* <p className="border-dot">{"\u00a0"}</p> */}
                                                        {description[2]?
                                                            <p className="border-dot" style={this.dynamicFont()} >{description[2]?.substr(0, 50)}</p>:
                                                            <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {/*อัตราภาษีร้อยละ*/}
                                                        {rate_list[2]?<p className="border-dot">{numberWithComma(rate_list[2], '-')}</p>:
                                                        <p className="border-dot">{"\u00a0"}</p>}
                                                    </td>
                                                    <td className="text-right">
                                                        {/*จำนวนเงินที่จ่ายในครั้งนี้*/}
                                                        {total_list[2]?<p className="border-dot">{getNumberSplitDecimal(total_list[2], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="text-left" width={"4%"}>
                                                        {/*ทศนิยม*/}
                                                        {total_list[2]?<p className="border-dot">{getNumberSplitDecimal(total_list[2], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="text-right">
                                                        {/*จำนวนเงินภาษีที่หักและนำส่งในครั้งนี้*/}
                                                        {total_tax_list[2]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[2], "number")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="text-left" width={"4%"}>
                                                        {/*ทศนิยม*/}
                                                        {total_tax_list[2]?<p className="border-dot">{getNumberSplitDecimal(total_tax_list[2], "decimal")}</p>:
                                                         <p className="border-dot">{"\u00a0"}</p>
                                                        }
                                                    </td>
                                                    <td className="text-center" >
                                                        {total_tax_list[2]?<p className="border-dot">{type_of_tax[2] ? type_of_tax[2] : 1}</p>:<p className="border-dot">{"\u00a0"}</p>}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <React.Fragment key={'row-data' + index}>
                                                 <tr>
                                                    <td rowSpan="3"/>
                                                    <td colSpan={4} className="no-border-bottom">
                                                         {this.displayTaxIdNumber([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 'rowTaxNum', false)}
                                                    </td>
                                                    <td className="no-border-bottom">
                                                         {this.displayBranch([0, 0, 0, 0, 0], 'rowBranchNum', false)}
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {/*วัน เดือน ปี*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td colSpan={2} className="no-border-bottom">
                                                        {/*ประเภทเงินได้พึงประเมินที่จ่าย*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {/*อัตราภาษีร้อยละ*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom text-right">
                                                        {/*จำนวนเงินที่จ่ายในครั้งนี้*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom text-right">
                                                        {/*ทศนิยม*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom text-right">
                                                        {/*จำนวนเงินภาษีที่หักและนำส่งในครั้งนี้*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom text-right">
                                                        {/*ทศนิยม*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom text-center">
                                                        {/*เงื่อนไข*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} className="no-border-bottom">
                                                        <p className="border-dot">ชื่อ {"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom"/>
                                                    <td className="no-border-bottom">
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td colSpan={2} className="no-border-bottom">
                                                        {/*ประเภทเงินได้พึงประเมินที่จ่าย*/}
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom">
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom">
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom">
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom">
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom">
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td className="no-border-bottom">
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <p className="border-dot">
                                                            ที่อยู่ {"\u00a0"}
                                                        </p>
                                                    </td>
                                                    <td/>
                                                    <td>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                    <td>
                                                        <p className="border-dot">{"\u00a0"}</p>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )

                                    }

                                })}

                                <tr>
                                    <td colSpan="10" className="text-right mt-1">
                                        <strong>รวมยอดเงินได้และภาษีที่นำส่ง</strong> <span className="font-italic">(นำ
                                        ไปรวมกับ</span> <strong>ใบแนบ ภ.ง.ด.53</strong> <span className="font-italic">แผ่นอื่น (ถ้ามี))</span>
                                    </td>
                                    <td>
                                        <p className="border-dot text-right">{getNumberSplitDecimal(total, "number")}</p>
                                    </td>
                                    <td>
                                        <p className="border-dot text-left">{getNumberSplitDecimal(total, "decimal")}</p>
                                    </td>
                                    <td>
                                        <p className="border-dot text-right">{getNumberSplitDecimal(total_rate, "number")}</p>
                                    </td>
                                    <td>
                                        <p className="border-dot text-left">{getNumberSplitDecimal(total_rate, "decimal")}</p>
                                    </td>
                                    <td className="no-border-bottom"/>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={7}>
                                        <div className="row mt-1">
                                            <div className="col"><i>(ให้กรอกลำดับที่ต่อเนื่องกันไปทุกแผ่น)</i></div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-1">
                                                <strong><u>หมายเหตุ</u></strong>
                                            </div>
                                            <img src={process.env.PUBLIC_URL + '/images/print/number-one.png'}
                                                 alt="correct-signal" className="number-foot ml-4"/>
                                            <div className="col">
                                                ให้ระบุว่าจ่ายเป็นค่าอะไร เช่น ค่านายหน้า ค่าแห่งกู๊ดวิลล์
                                                ดอกเบี้ยเงินฝาก ดอกเบี้ยตั๋วเงิน เงินปันผล เงินส่วนแบ่งกำไร
                                                ค่าเช่าอาคาร ค่าสอบบัญชี ค่าออกแบบ ค่าก่อสร้างโรงเรียน
                                                ค่าซื้อเครื่องพิมพ์ดีด ค่าซื้อพืชผลทางการเกษตร (ยางพารา
                                                มันสำ ปะหลัง ปอ ข้าว ฯลฯ) ค่าจ้างทำของ ค่าจ้างโฆษณา รางวัล
                                                ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขาย
                                                รางวัลในการประกวด การแข่งขัน การชิงโชค ค่าขนส่งสินค้า
                                                ค่าเบี้ยประกันวินาศภัย
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-1"/>
                                            <img src={process.env.PUBLIC_URL + '/images/print/number-two.png'}
                                                 alt="correct-signal" className="number-foot ml-4"/>
                                            <div className="col">
                                                เงื่อนไขการหักภาษี ณ ที่จ่ายให้กรอกดังนี้
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9745;</span> หัก ณ ที่จ่าย กรอก 1</p>
                                                <p className="mb-0 mt-1"><span className="ballot-box">&#9745;</span> ออกภาษีให้ กรอก 2</p>
                                                {/*<p className="mb-0 mt-1"><span className="ballot-box">&#9744;</span> ออกให้ครั้งเดียว กรอก 3</p>*/}
                                            </div>
                                        </div>
                                    </td>
                                    <td colSpan={8}>
                                        <div className="row mt-5">
                                            <div className="col-3 div-seal pr-0">
                                                <div className="border-seal">
                                                    <span className="text-center">ประทับตรานิติบุคคล (ถ้ามี)</span>
                                                </div>
                                            </div>
                                            <div className="col-9 pl-0">
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <p className="mb-2">ลงชื่อ..................................................................................ผู้จ่ายเงิน</p>
                                                        <p className="mb-2">(..........................................................................)</p>
                                                        <p className="mb-2">ตำแหน่ง........................................................................................</p>
                                                        <p className="mb-2">ยื่นวันที่.......................เดือน...................................พ.ศ....................</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            <div className="row mt-1">
                                <div className="col">
                                    <strong><i>สอบถามข้อมูลเพิ่มเติมได้ที่ศูนย์สารนิเทศสรรพากร RD Intelligence Center
                                        โทร. 1161</i></strong>
                                </div>
                                <div className="col text-right">
                                    <strong>พิมพ์ มี.ค. 2560</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithholdingAttachmentTax53WithDataPrint;
