import React, { Component } from 'react';
import numberWithComma from "../../../../libs/numberWithComma";

import "./css/PurchaseSummary.scss";
import SearchSelect from "../../../../libs/searchSelect";
import { graphql } from "babel-plugin-relay/macro";

import _ from "lodash";
import { fetchQuery } from "react-relay";
import environment from "../../../../env/environment";
import i18next from 'i18next';

const query = graphql`
    query PurchaseSummaryQuery($type:String) {
        quickMassageViewer(typeNote:$type){
            edges{
              node{
                id
                massageNote
                typeNote
                creator
              }
            }
        }
    }`;

class PurchaseSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wht_percent: 0,
            vat: 0,
            other: "",
            loading: false,
            reQuery: false,
            note_list: [],
            note_other: '',
            isOther: false,
            note_id: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this)
        this.notInvoice = this.notInvoice.bind(this)
    }

    queryChartOfAccount() {
        fetchQuery(environment, query, { type: 'invoice' }).then(data => {
            let data_clone = _.cloneDeep(data);
            let note_list = []
            // let name = ''
            if (data_clone.quickMassageViewer.edges.length > 0) {
                data_clone.quickMassageViewer.edges.map((data) => {
                    note_list.push({ node: { creator: data.node.creator, id: data.node.id, name: data.node.massageNote, typeNote: data.node.typeNote } })
                    return data
                })
            }
            note_list.push({ node: { creator: " ", id: "other", name: "อื่นๆ", typeNote: "INVOICE" } })
            this.setState({ note_list: note_list })
        })
    }

    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    componentWillMount() {
        this.calSummary();
        this.queryChartOfAccount();
        this.props.remark && this.state.note_list.map((value) => {
            if (value.node.name === this.props.remark) {
                this.setState({ note_id: value.node.id })
            } else {
                this.setState({ note_id: "other" })
            }
            return value
        })

        //check หมายเหตุในส่วนที่ไม่ใช่ invoice
        this.notInvoice();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.transaction_list !== prevProps.transaction_list || this.props.prepaidDeposit !== prevProps.prepaidDeposit) {
            this.calSummary()
        }
    }

    calSummary() {
        // set vat
        if (this.props.transaction_list.length > 0) {
            this.setState({ vat: this.props.transaction_list[0].vat || 0 })
        }
        let sumDiscount = 0;
        let sumPrice = 0;

        let total = 0;
        let sumVat = 0;
        let sumPreTax = 0;
        let sumWhtRate = 0;
        let totalWhtRate = 0;

        // ยอด *100 /107

        this.props.transaction_list.forEach((transactions) => {
            sumDiscount = parseFloat(sumDiscount) + parseFloat(transactions.discount || 0);
            sumPrice = parseFloat(sumPrice) + (transactions.unitItems * parseFloat(transactions.price || 0));

            let preTaxAmount = transactions.preTaxAmount;

            // sumVat = parseFloat(sumVat) + parseFloat(preTaxAmount || 0) * (transactions.vat / 100 || 0);
            sumVat = parseFloat(sumVat) + parseFloat(transactions.vatAmount);
            sumPreTax = parseFloat(sumPreTax) + parseFloat(preTaxAmount || 0);
            sumWhtRate = parseFloat(sumWhtRate) + parseFloat(transactions.whtRate || 0);
        });
        total = parseFloat(sumVat) + parseFloat(sumPreTax) - (parseFloat(this.props.prepaidDeposit) * (1 + ((this.state.vat) / 100)));


        totalWhtRate = parseFloat(this.roundToTwo(total)) - parseFloat(this.roundToTwo(sumWhtRate));

        let transaction_use_wht = this.props.transaction_list.find((transaction) => {
            if (transaction.whtRatePercent && transaction.whtRatePercent !== "0") {
                return transaction
            } else {
                return false
            }
        });

        if (transaction_use_wht) {
            this.setState({ wht_percent: transaction_use_wht.whtRatePercent });
        } else {
            this.setState({ wht_percent: 0 })
        }

        this.setState({
            sum_discount: sumDiscount,
            sum_price: sumPrice,
            sum_pre_tax: sumPreTax,
            sum_vat: sumVat,
        }, () => {

            let event = {
                target: {
                    name: 'total',
                    value: this.calTotalAmount(),
                    dataset: { id: '', key: '' },
                }
            };
            if (this.props.handleInputChange) {
                this.props.handleInputChange(event);
            }
        });

        let total_wht_rate = {
            target: {
                name: 'total_wht_rate',
                value: this.roundToTwo(totalWhtRate) < 0 ? 0 : this.roundToTwo(totalWhtRate),
                dataset: { id: '', key: '' },
            }
        };

        if (this.props.handleInputChange) {
            this.props.handleInputChange(total_wht_rate);
        }
    }

    getSumAmount() {
        let amount = parseFloat(this.state.sum_pre_tax);
        return amount > 0 ? amount : 0
    }

    getSumAmountPrepaid(){
        let amount = this.calTotalSumAmount() - (this.props.prepaidDeposit || 0 );
        return amount > 0 ? amount : 0
    }

    getSumVat() {
        let vatAmount = 0;
        this.props.transaction_list.forEach((result, index) => {
            vatAmount = parseFloat(vatAmount) + parseFloat(result.vatAmount) 
        })
        const sumVat = parseFloat(vatAmount) || 0;
        return sumVat > 0 ? sumVat : 0
    }

    getPrice() {
        let amount = parseFloat(this.state.sum_price);
        return amount > 0 ? amount : 0
    }

    calTotalAmount() {
        // let amount = parseFloat(this.getSumAmount()) + parseFloat(this.getSum.Vat()) - (this.props.prepaidDeposit || 0 );
        let amount = Math.round( (this.getSumAmount() + this.getSumVat() - (this.props.prepaidDeposit || 0 ) + Number.EPSILON) * 100) / 100;
        return amount > 0 ? parseFloat(amount.toFixed(2)) : 0
    }
    
    calTotalSumAmount() {
        let amount = parseFloat(this.getSumAmount()) + parseFloat(this.getSumVat());
        return amount > 0 ? amount : 0
    }

    getVat(price, vat) {
        let amount = 0;
        if (this.props.taxType === 'INCLUDE_TAX') {
            amount = parseFloat(price) * ((vat || 0) / 100)
        }
        return parseFloat(this.roundToTwo(amount))
    }

    handleInputChange(e) {
        if (e.target.name === 'noteListInvoice') {
            if (e.target.value !== "other") {
                this.state.note_list.map((value) => {
                    if (value.node.id === e.target.value) {
                        this.setState({
                            note_other: value.node.name,
                            isOther: false
                        })
                        this.props.getRemarkCallback(value.node.name)
                    }
                })

            } else {
                this.setState({
                    isOther: true,
                })
                this.props.getRemarkCallback("")
            }
        }
    }

    notInvoice() {
        if (this.props.category !== 'invoice') {
            this.setState({ isOther: true })
        }
    }

    render() {
        return (
            <div className="row mt-5" id="invoice-summary">
                <div className="col-8">
                    <label htmlFor="note"><strong>{i18next.t("PurchaseCreate:Note")}</strong></label>
                    {(this.props.mode === 'edit' || this.props.mode === 'create') &&
                        <SearchSelect
                            onChange={this.handleInputChange}
                            value={this.state.note_id}
                            name="noteListInvoice"
                            placeholder={i18next.t("PurchaseCreate:Choose")}
                            queryObject={this.state.note_list}
                            keyOfValue="id"
                            keyOfLabel="name"
                            required={false}
                            pre_wrap={true}
                        />
                    }

                    {(this.state.isOther || (this.props.mode === 'edit' && this.props.remark) || (this.props.mode !== 'edit' && this.props.mode !== 'create')) &&
                        <div className="mt-3">
                            <textarea className="form-control" rows="5" name="remark"
                                value={this.props.remark}
                                disabled={this.props.remark_edit || (this.state.isOther === false)}
                                onChange={(event) => this.props.handleInputChange(event)}
                                required={!(this.props.category === 'other_receiver') && !(this.props.category === 'purchase')}
                            />
                        </div>
                    }
                </div>

                <div className="col-4">
                    <div className="box-summary">
                        <div className="row">
                            <div className="col">
                                <p>{i18next.t("PurchaseCreate:Amount")}</p>
                            </div>
                            <div className="col text-right">
                                <p>{numberWithComma(this.state.sum_price, '0.00')} {i18next.t("PurchaseCreate:Baht")}</p>
                            </div>
                        </div>

                         {/*============== have vat ===============*/}
                         {(this.props.taxType !== 'NO_TAX') &&
                            <React.Fragment>
                                <div className="row">
                                    <div className="col">
                                        <p>{i18next.t("PurchaseCreate:Vat")}</p>
                                    </div>
                                    <div className="col text-right">
                                        <p>{numberWithComma(this.getSumVat(), '0.00')} {i18next.t("PurchaseCreate:Baht")}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p>{i18next.t("PurchaseCreate:Total Amount")}</p>
                                    </div>
                                    <div className="col text-right">
                                        <p>{numberWithComma(parseFloat(this.calTotalSumAmount()), '0.00')} {i18next.t("PurchaseCreate:Baht")}</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {/*============== have vat ===============*/}

                        <div className="row">
                            <div className="col">
                                <p>{i18next.t("PurchaseCreate:Deduct by receipt deposit")}</p>
                            </div>

                            <div className="col text-right">
                                {
                                    this.props.category !== "prepaid_deposit" && this.props.category !== 'other_receiver' &&
                                    // <p>{numberWithComma(this.props.prepaidDeposit + this.getVat(this.props.prepaidDeposit, this.state.vat) || 0, '0.00')} บาท</p>
                                    <p>{numberWithComma(this.props.prepaidDeposit || 0, '0.00')} {i18next.t("PurchaseCreate:Baht")}</p>
                                }
                                {
                                    this.props.category === 'other_receiver' &&
                                    <p></p>
                                }
                            </div>
                        </div>

                        {
                            (this.props.taxType !== 'NO_TAX' || parseFloat(this.state.wht_percent) !== 0) &&
                            <div className="row">
                                <div className="col">
                                    <p>{i18next.t("PurchaseCreate:Amount after deduct by receipt deposit")}</p>
                                </div>
                                <div className="col text-right">
                                    <p>{numberWithComma(this.getSumAmountPrepaid(), '0.00')} {i18next.t("PurchaseCreate:Baht")}</p>
                                </div>
                            </div>

                        }

                        <div className="row">
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col">
                                <strong>{i18next.t("PurchaseCreate:Total Amount")}</strong>
                            </div>
                            <div className="col text-right">
                                <strong className="red">
                                    {numberWithComma(this.calTotalAmount(), '0.00')} {i18next.t("PurchaseCreate:Baht")}
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PurchaseSummary;