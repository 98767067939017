import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import Wrapper from "../../components/wrapper";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery, commitMutation } from "relay-runtime";
import environment from "../../env/environment";
import Swal from "sweetalert2";
import { format } from "date-fns";
import _ from "lodash";
import i18n from "i18next";
import "./settingEnterpriseInformation.scss"

const mutation = graphql`
    mutation settingEnterpriseInformationMutation($input: CreateSettingProjectInput!){
        createSettingProject(input:$input){
            ok
        }
    }`

const query = graphql`
    query settingEnterpriseInformationQuery {
        allSettingProject{
        edges{
            node{
                id
                hireJuristic
                hireJuristicDate
                creator
                editor
            }
        }
    }
    }`;

let settingProjects ={
    hireJuristic: "",
    hireJuristicDate: format(new Date())
}

const SettingEnterpriseInformation = () => {
    const [status, setStatus] = useState("unidentified")
    const [statusApi] = useState(null)

    useEffect(() => {
        fetchData()
    }, [])

    const onSubmit=()=>{
        settingProjects={
            hireJuristic: status,
            hireJuristicDate: format(new Date(),"YYYY-MM-DD")
        }

        let variables = {
            input: {
                settingProject: JSON.stringify(settingProjects)
            }
        }
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    if (response.createSettingProject !== null) {
                        Swal.fire(i18n.t('settingNote:Save successfully'), '', 'success').then(() => {
                            // this.setState({ redirectToList: true, loading: false });
                            fetchData()
                        })
                    } else {
                        Swal.fire(i18n.t('settingNote:Can\'t save'), '', 'warning');

                    }
                },
                onError: (err) => {
                    console.log("error", err);
                },
            },
        );
    }

    const fetchData = () => {
        fetchQuery(environment, query, {
        }).then(data => {
            let data_clone = _.cloneDeep(data);
            if (data_clone) {
                let data = data_clone.allSettingProject.edges
                if(data.length > 0){
                    let text = ""
                    switch (data[0].node.hireJuristic) {
                        case "ALL":
                            text = "all";
                            break;
                        case "NO":
                            text = "no";
                            break;
                        default:
                            text = "unidentified";
                    }
                    setStatus(text)
                }
            }
        })
    }
    return (
        <Wrapper>
            <Header />
            <Sidebar />
            <Translation>
                {
                    t =>
                        <WrapperContent>
                            <div className="container-fluid box">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            <span>{t('setting:settingEnterpriseInformation')}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="content-inner row fade-up" id="setting-enterprise-information">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <form>
                                            <div className="col">
                                                <span className="h1">{t('settingEnterpriseInformation:Question')}</span>
                                                <div className="custom-control custom-radio mt-4">
                                                    <input type="radio" className="custom-control-input"
                                                        id="conveyance_yes" name="hire_manage"
                                                        value="all"
                                                        checked={statusApi ? statusApi === "ALL" :status === "all"}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_settingproject_edit'})}
                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="conveyance_yes">
                                                        <span className="h2">{t('settingEnterpriseInformation:ans1')}</span>
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio mt-2" style={{alignItems:"center"}}>
                                                    <input type="radio" className="custom-control-input"
                                                        id="conveyance_no" name="hire_manage"
                                                        value="no"
                                                        checked={statusApi ? statusApi === "NO" :status === "no"}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_settingproject_edit'})}
                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="conveyance_no">
                                                        <span className="h2">{t('settingEnterpriseInformation:ans2')}</span>
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio mt-2">
                                                    <input type="radio" className="custom-control-input"
                                                        id="conveyance_none" name="hire_manage"
                                                        value="unidentified"
                                                        checked={statusApi ? statusApi === "UNIDENTIFIED":status === "unidentified"}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                        disabled={!_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_settingproject_edit'})}
                                                    />
                                                    <label className="custom-control-label"
                                                        htmlFor="conveyance_none">
                                                        <span className="h2">{t('settingEnterpriseInformation:NA')}</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col-6"></div>
                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'project_settingproject_edit'}) &&
                                                    <div className="row text-right mt-2 button-grp">

                                                        <div className="col">
                                                            <button
                                                            onClick={onSubmit}
                                                                type="button"
                                                                className="btn btn-primary add"
                                                            >
                                                                <span>{t('settingEnterpriseInformation:save')}</span>
                                                            </button>
                                                        </div>
                                                        <div className="col-2">
                                                            <Link to={"/setting"}>
                                                                <button type="button" className="btn btn-secondary add">
                                                                {t('settingEnterpriseInformation:cancel')}
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </WrapperContent>
                }
            </Translation>
        </Wrapper>


    )
}

export default SettingEnterpriseInformation