import React, {Component} from 'react';
import numberWithComma from "../../../../libs/numberWithComma";
import { Translation } from "react-i18next";
class InvoiceDetailTable extends Component {

    removeTrailingZeros(description){
        if(description?.includes("ค่าน้ำประปา")){
            description = description?.replace(/ *\[[^)]*\] */g, " ")
            // description = description.replace(/ *\[[^)]*\] */g, " ").replace(/.50 /gi, ".5 ")
            // description = description.replace(/.00 /gi, " ")
        } else {
            description = description?.replace(/ *\[[^)]*\] */g, " ")
        }
        return description
    }

    render() {
        return (
            <Translation>
                { t =>
                    <React.Fragment>
                        <div className="table-responsive fade-up">
                            <table className="table table-hover mt-2">
                                <thead className="thead-light">
                                <tr>
                                    <th>{t('invoiceDetail:Products/Services')}</th>
                                    <th>{t('invoiceDetail:Account')}</th>
                                    <th>{t('invoiceDetail:Description')}</th>
                                    <th className="text-center">{t('invoiceDetail:Amount')}</th>
                                    <th className="text-right">{t('invoiceDetail:Price')}</th>
                                    <th className="text-right">{t('invoiceDetail:Discount')}</th>
                                    <th className="text-right">{t('invoiceDetail:Tax')}</th>
                                    <th className="text-right">{t('invoiceDetail:Balance before tax')}</th>
                                    <th className="text-right">{t('invoiceDetail:Withholding')}</th>
                                    <th className="text-right">{t('invoiceDetail:No.Number')}</th>
                                </tr>
                                </thead>
                                {
                                    this.props?.invoice?.transaction?.edges?.map((transactions) => {
                                        
                                        return(
                                            <tbody key={transactions.node.id}>
                                            <tr>
                                                <td>{transactions.node.productAndService ? transactions.node.productAndService.productCode : "-"}</td>
                                                <td>{transactions.node.productAndService ? transactions.node.productAndService.chartOfAccount.chartOfAccountCode : transactions.node.chartOfAccount.chartOfAccountCode}</td>
                                                <td>{
                                                    this.removeTrailingZeros(transactions?.node?.description)
                                                    }</td>
                                                <td className="text-center">{transactions.node.unitItems}</td>
                                                <td className="text-right">{numberWithComma(transactions.node.price)}</td>
                                                <td className="text-right">{numberWithComma(transactions.node.discount)}</td>
            
                                                {
                                                    transactions.node.vat === 0 ?
                                                        <td className="text-right">0%</td>
                                                        :
                                                        transactions.node.vat === 7 ?
                                                            <td className="text-right">7%</td>
                                                            :
                                                            <td className="text-right">{t('invoiceDetail:None')}</td>
                                                }
            
                                                <td className="text-right">{numberWithComma(transactions.node.preTaxAmount)}</td>
                                                <td className="text-right">{numberWithComma(transactions.node.whtRate) || '-'}</td>

                                                {transactions.node.keycardPerInvoice.edges.length > 0 ?
                                                    <td className="text-right">{transactions.node.keycardPerInvoice.edges[0].node.numberKey.noNumber}</td>
                                                :
                                                    <td/>
                                                }
                                            </tr>
                                            </tbody>
                                        )}
                                    )
                                }
                            </table>
                        </div>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default InvoiceDetailTable;