import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import listBank from './bankList';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";

class Bank extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}`} component={listBank}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Bank;