import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";

const query = graphql`
query chequeControlQueryQuery($bankAccountId: String) {
    chequeControl(bankAccountId: $bankAccountId) {
      totalCount
      edges{
          node{
              id
              docNumber
              bankName
              branch
              bankLogo
              accountType
              accountName
              accountNameEn
              accountNumber
              updated
              chequeDetails(status:true,chequeNo_Status:true){
                  edges{
                      node{
                          id
                          status
                          noNumber
                          startCheque
                          endCheque
                          lastCheque
                          chequeNo(status:true){
                              totalCount
                              edges{
                                  node{
                                      id
                                      status
                                      noNumber
                                  }
                              }
                          }
                      }
                  }
              }
              
          }
      }          
  }
  }
  `

const chequeControlQuery = (id) => {
    return new Promise((resolve, reject) => {
        fetchQuery(environment, query, { bankAccountId: id }).then(data => {
            resolve(data)
        });
    });

};

export default chequeControlQuery;
