/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClearGuaranteeMoneyReceivedStatus = "ACTIVE" | "VOID" | "%future added value";
export type GuaranteeMoneyReceivedStatus = "COMPLETED" | "FORFEIT_ALL" | "IN_PROCESS" | "RETURN_FORFEIT" | "VOID" | "%future added value";
export type GuaranteeMoneyReceivedTransactionTypeOfTransaction = "FORFEIT" | "RETURN" | "%future added value";
export type guaranteeMoneyReceiveMovementReportQueryVariables = {|
  first?: ?number,
  last?: ?number,
  start_date?: ?any,
  end_date?: ?any,
  search?: ?string,
  customerType?: ?string,
  contact?: ?string,
  paymentChannel?: ?string,
  order?: ?string,
  status?: ?string,
|};
export type guaranteeMoneyReceiveMovementReportQueryResponse = {|
  +allGuaranteeMoneyReceived: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +chartOfAccount: ?{|
          +chartOfAccountCode: string,
          +name: string,
        |},
        +description: string,
        +amount: ?number,
        +status: GuaranteeMoneyReceivedStatus,
        +clearGuarantee: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +status: ClearGuaranteeMoneyReceivedStatus,
              +docNumber: string,
              +issuedDate: any,
              +guaranteeTransaction: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +amount: ?number,
                    +typeOfTransaction: GuaranteeMoneyReceivedTransactionTypeOfTransaction,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type guaranteeMoneyReceiveMovementReportQuery = {|
  variables: guaranteeMoneyReceiveMovementReportQueryVariables,
  response: guaranteeMoneyReceiveMovementReportQueryResponse,
|};
*/


/*
query guaranteeMoneyReceiveMovementReportQuery(
  $first: Int
  $last: Int
  $start_date: DateTime
  $end_date: DateTime
  $search: String
  $customerType: String
  $contact: ID
  $paymentChannel: String
  $order: String
  $status: String
) {
  allGuaranteeMoneyReceived(first: $first, last: $last, startDate: $start_date, endDate: $end_date, search: $search, customerType: $customerType, contact: $contact, paymentChannel: $paymentChannel, order: $order, status: $status) {
    totalCount
    edges {
      node {
        id
        docNumber
        issuedDate
        chartOfAccount {
          chartOfAccountCode
          name
          id
        }
        description
        amount
        status
        clearGuarantee {
          edges {
            node {
              id
              status
              docNumber
              issuedDate
              guaranteeTransaction {
                edges {
                  node {
                    id
                    amount
                    typeOfTransaction
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentChannel"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v10 = [
  {
    "kind": "Variable",
    "name": "contact",
    "variableName": "contact"
  },
  {
    "kind": "Variable",
    "name": "customerType",
    "variableName": "customerType"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "end_date"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "paymentChannel",
    "variableName": "paymentChannel"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "start_date"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ClearGuaranteeMoneyReceivedNodeConnection",
  "kind": "LinkedField",
  "name": "clearGuarantee",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClearGuaranteeMoneyReceivedNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClearGuaranteeMoneyReceivedNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v12/*: any*/),
            (v19/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GuaranteeMoneyReceivedTransactionNodeConnection",
              "kind": "LinkedField",
              "name": "guaranteeTransaction",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GuaranteeMoneyReceivedTransactionNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GuaranteeMoneyReceivedTransactionNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v12/*: any*/),
                        (v18/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "typeOfTransaction",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "guaranteeMoneyReceiveMovementReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "GuaranteeMoneyReceivedNodeConnection",
        "kind": "LinkedField",
        "name": "allGuaranteeMoneyReceived",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GuaranteeMoneyReceivedNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GuaranteeMoneyReceivedNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "guaranteeMoneyReceiveMovementReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "GuaranteeMoneyReceivedNodeConnection",
        "kind": "LinkedField",
        "name": "allGuaranteeMoneyReceived",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GuaranteeMoneyReceivedNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GuaranteeMoneyReceivedNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "276a7781592ddcbc4567238fa86e8302",
    "id": null,
    "metadata": {},
    "name": "guaranteeMoneyReceiveMovementReportQuery",
    "operationKind": "query",
    "text": "query guaranteeMoneyReceiveMovementReportQuery(\n  $first: Int\n  $last: Int\n  $start_date: DateTime\n  $end_date: DateTime\n  $search: String\n  $customerType: String\n  $contact: ID\n  $paymentChannel: String\n  $order: String\n  $status: String\n) {\n  allGuaranteeMoneyReceived(first: $first, last: $last, startDate: $start_date, endDate: $end_date, search: $search, customerType: $customerType, contact: $contact, paymentChannel: $paymentChannel, order: $order, status: $status) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        chartOfAccount {\n          chartOfAccountCode\n          name\n          id\n        }\n        description\n        amount\n        status\n        clearGuarantee {\n          edges {\n            node {\n              id\n              status\n              docNumber\n              issuedDate\n              guaranteeTransaction {\n                edges {\n                  node {\n                    id\n                    amount\n                    typeOfTransaction\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c970bf5daf5ca4a1fcbf2fcae469b512';

module.exports = node;
