import React from "react";
import {
    Route,
    Redirect,
} from "react-router-dom";

const PrivateRoute = ({component: Component, ...rest}) => {
    let token = localStorage.getItem('token');
    let checkAcceptForTermofUses = localStorage.getItem('checkStatusPolicyAndTerms');
    if (Component) {
        return (
            <Route
                {...rest}
                render={props =>
                    token && checkAcceptForTermofUses ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {from: props.location}
                            }}
                        />
                    )
                }
            />
        );
    } else {
        return (
            // ROOT REDIRECT TO HOME
            <Redirect to={{
                pathname: "/home",
            }}/>
        )
    }
};

export default PrivateRoute;
