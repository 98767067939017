/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type debtFreeCertificationQueryVariables = {||};
export type debtFreeCertificationQueryResponse = {|
  +allSettingDebsFreeCertification: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +fee: boolean,
        +charge: ?number,
        +waiting: ?number,
        +lifetime: ?number,
      |}
    |}>
  |}
|};
export type debtFreeCertificationQuery = {|
  variables: debtFreeCertificationQueryVariables,
  response: debtFreeCertificationQueryResponse,
|};
*/


/*
query debtFreeCertificationQuery {
  allSettingDebsFreeCertification {
    edges {
      node {
        id
        fee
        charge
        waiting
        lifetime
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SettingDebsFreeCertificationNodeConnection",
    "kind": "LinkedField",
    "name": "allSettingDebsFreeCertification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingDebsFreeCertificationNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingDebsFreeCertificationNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "charge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "waiting",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lifetime",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "debtFreeCertificationQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "debtFreeCertificationQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "fcf021c9a646a61e0e38163d22ba8ff0",
    "id": null,
    "metadata": {},
    "name": "debtFreeCertificationQuery",
    "operationKind": "query",
    "text": "query debtFreeCertificationQuery {\n  allSettingDebsFreeCertification {\n    edges {\n      node {\n        id\n        fee\n        charge\n        waiting\n        lifetime\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '60f7ee7b35c6f3d94a60c3730ff846f6';

module.exports = node;
