import getApiRoot from "../../../libs/getAPIRoot";
import axios from "axios";

async function getOneFacility(id) {
  let token_id = await localStorage.getItem("token");
  const response = await fetch(
    `${getApiRoot()}booking_facility/one_facility?facility_id=${id}`,
    {
      method: "get",
      headers: {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
      },
    }
  ).catch((error) => {
    console.error("Error:", error);
  });

  return response.json();
}

const editBookingFacility = async (data, id) => {
  let token_id = await localStorage.getItem("token");
  let header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
  };

  return axios.post(
    `${getApiRoot()}booking_facility/edit_facility/?facility_id=${id}`,
    data,
    {
      headers: header,
    }
  );
};

const deleteFacility = async (id) => {
  let token_id = await localStorage.getItem("token");
  let header = {
    authorization: `JWT ${token_id}`,
    "Content-Type": "application/json",
  };
  return axios.post(
    `${getApiRoot()}booking_facility/delete_facility/?facility_id=${id}`,
    {},
    {
      headers: header,
    }
  );
};

export default {
  getOneFacility,
  editBookingFacility,
  deleteFacility,
};
