import React, { Component } from 'react';
import $ from "jquery";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import i18n from "i18next";
import ConvertNumberToTextEng from '../../libs/convertNumberToTextEng';
const ThaiBaht = require('thai-baht-text');

class AdvanceDetail extends Component {

    constructor(props) {
        super(props);

        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];

        this.state = {
            bangkokCode: bangkokCode,
            isOther : false,
            languages: "th",
        };
        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.isOther = this.isOther.bind(this)
    }

    componentWillMount() {
        i18n.changeLanguage(this.props.languages);
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
        })
        setTimeout(() => {
            let page = $('.' + this.props.pageNumber);
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = 0
            let chartOfAccount = 0
            let remark = 0
            remark = page.find('.remark').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice +  remark);
            if(this.props.count_page <= 1.0){
                chartOfAccount = page.find('.chart-of-account').height();
                signature = page.find('.footer').height();
                diff = page_height - (head + detail + invoice + signature + chartOfAccount+ remark);
            }
            $('.' + this.props.pageNumber + ' table').css({ borderBottom: "1px solid" });
            $('.' + this.props.pageNumber + ' tbody tr:last-child td').css({ paddingBottom: diff  });
        }, 200);
        this.isOther()
    }

    isBangkok(code) {
        if (_.indexOf(this.state.bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }
    getPrefix(type, code) {
        if (type === "district" && this.isBangkok(code)) {
            return "แขวง"
        }
        else if (type === "city" && this.isBangkok(code)) {
            return "เขต"
        }
        else if (type === "province" && this.isBangkok(code)) {
            return ""
        }
        else if (type === "province") {
            return "จ."
        }
        else if (type === "district") {
            return "ต."
        }
        else if (type === "city") {
            return "อ."
        }
    }

    paymentChannel() {
        let cash_text = [];
        let bank_detail = [{bankName2 : "", accountNumber : "", price : 0 }]
        let cheque_detail = [{chequeNumber : "", bankName : "", branch : "", date : "", id : "", price : 0}]
        let channel = {};
        if (this.props.advance.cashTransaction.edges.length) {
            let cash = this.props.advance.cashTransaction.edges
            let price_cash = 0;
            cash.map((val)=>{
                price_cash = price_cash + val.node.price
                return null
            })
            cash_text.push(" จำนวน "+ numberWithComma(price_cash, "0.00") +" บาท ")
            channel['cash'] = true 
         
        }
        
        if (this.props.advance.bankAccountTransaction.edges.length) {
            let bank = this.props.advance.bankAccountTransaction.edges;
            bank.map((val,index)=>{
                let find_index = bank_detail.findIndex((i) => i?.bankName2 === val.node.bankAccount.bankName && i?.accountNumber === val.node.bankAccount.accountNumber)
                if(find_index !== -1){
                    let new_price = val.node.price + bank_detail[find_index].price
                    bank_detail[find_index].price = new_price
                }else{
                    bank_detail[index] = { bankName2:val.node.bankAccount.bankName , accountNumber:val.node.bankAccount.accountNumber ,price:val.node.price, date:val.node.date }                    
                }
                return null
            })
            channel['bank'] = true
        }
        if (this.props.advance.chequeTransaction.edges.length) {
            let cheque = this.props.advance.chequeTransaction.edges;
            cheque.map((val,index)=>{
                let find_index = cheque_detail.findIndex((i) => i?.bankName === val.node.bankAccount.bankName && i?.chequeNumber === val.node.chequeNumber)
                if(find_index !== -1){
                    // กรณีที่ื เป็น chequeNumber bank เดียวกันจะเช็คเพิ่มว่า price กับ bankaccount.id เท่ากันมั้ย
                    let checkedSamePrice = cheque_detail[0].price;
                    let price = val.node.price;
                    let checkedSameId = cheque_detail[0].id;
                    let id = val.node.bankAccount.id;
                    let checkedSameDate = cheque_detail[0].date;
                    let date = val.node.date;
                    if(checkedSamePrice === price && checkedSameId === id && checkedSameDate === date) {
                        return
                    } else {
                        let new_price = cheque_detail[find_index].price + val.node.price
                        cheque_detail[find_index].price = new_price
                    }
                } else {
                    cheque_detail[index] = {chequeNumber:val.node.chequeNumber , bankName:val.node.bankAccount.bankName , branch:val.node.bankAccount.branch , price:val.node.price, date:val.node.date ,id : val.node.bankAccount.id}
                }
                return null
            })
            channel['cheque']=true
            
        }
        return { channel,cash_text,bank_detail, cheque_detail}
    }

    isOther(){
        this.props.allAccountRecordGroup.edges.length > 0 &&
        this.props.allAccountRecordGroup.edges.forEach((Other) => (
            Other.node.accountRecord.edges.forEach((accountRecord) => {
                if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                    accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07") {
                        this.setState({
                            isOther:true
                        })                    
                }
            })

        ))
    }

    render() {
        let paymentChannel = this.paymentChannel()
        return (
            <div className={"print-page-a4 " + this.props.pageNumber} id="receive">
                <div className="subpage">
                    <div className="head">
                        <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minWidth:100,minHeight:100}}>
                                <img src={this.props.selfProject.logo} alt="silverman" />
                            </div>
                            <div>                                
                                <strong>{this.state.languages === 'en' ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>
                                <div>{this.state.languages === "en" ?  (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} โทร {this.props.selfProject.juristicContactNumber || '-'}</div>
                                <div>เลขประจำตัวผู้เสียภาษี {this.props.selfProject.taxIdNumber || '-'}</div>
                            </div>
                           
                        </div>

                        <div className= "document-name">
                                <React.Fragment>
                                    <div className="title">ใบสำคัญจ่าย</div>
                                    <div className="title">Payment Voucher</div>
                                </React.Fragment>

                            {
                                this.props.check_type === "manuscript" ?
                                    <div className="title small">(ต้นฉบับ/Original)</div>
                                    :
                                    <div className="title small">(สำเนา/Copy)</div>
                            }
                        </div>
                    </div>

                    <div className="detail">
                        <div className="customer-detail"> 
                            <strong>ผู้เบิก/Withdrawer:</strong>{this.props.advance.withdrawer} <br />
                            <br />
                        </div>

                        <div className= "document-detail" >
                            <strong style={{width: "138px"}}>เลขที่/No:</strong> {this.props.allAccountRecordGroup.edges[0].node.refTransaction}
                            <br />
                            <strong style={{width: "138px"}}>วันที่บันทึก/Date:</strong> {format(this.props.allAccountRecordGroup.edges[0].node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}<br />
                            <strong style={{width: "138px"}}>ครบกำหนด/Due date:</strong> {this.props.advance.dueDate ? format(this.props.advance.dueDate, 'DD/MM/YYYY', { locale: thLocale }) : ""} <br />
                        </div>
                    </div>

                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th width={40} className="text-center">
                                        ลำดับ <br />
                                    No.
                                </th>

                                    <th width={110} className="text-center">
                                        เลขใบสำคัญจ่าย <br />
                                    Ref. No.
                                </th>

                                    <th width={50} className="text-center">
                                        วันที่บันทึก <br />
                                    Date
                                </th>

                                    <th className="text-center">
                                        รายละเอียด <br />
                                    Description
                                </th>

                                    <th width={110} className="text-center">
                                        จำนวนหน่วย <br />
                                    Unit
                                </th>
                                    <th width={100} className="text-center">
                                        จำนวนเงิน <br />
                                    Amount
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td className="text-center">1</td>

                                    <td className="text-center">
                                        {this.props.allAccountRecordGroup.edges[0].node.refTransaction}
                                    </td>
                                    <td className="text-center">{format(this.props.allAccountRecordGroup.edges[0].node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>

                                    <td>
                                    {this.props.advance.description}
                                    </td>
                                    <td className="text-center">
                                        1
                                    </td>

                                    <td className="text-right">
                                    {numberWithComma(this.props.advance.amount, "0.00")}
                                    </td>
                                </tr>
                                    

                            </tbody>
                            <tfoot className="font-weight-bold">
                                {this.props.current_page === this.props.end_page &&
                                    <tr>
                                        <td colSpan={4} className="text-center">
                                            {i18n.languages[0] === 'th' ?
                                            (ThaiBaht(this.props.advance.amount)):
                                            ConvertNumberToTextEng(this.props.advance.amount)}
                                    </td>

                                        <td>
                                            จำนวนเงิน/Amount
                                    </td>
                                        <td className="text-right">
                                            {numberWithComma(this.props.advance.amount, "0.00")}
                                        </td>
                                    </tr>
                                }
                            </tfoot>
                        </table>

                        {this.props.count_page <= 1.0 &&
                            <div className="chart-of-account">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width={100} className="text-center">
                                                รหัสบัญชี
                                        </th>
                                            <th className="text-center">
                                                ชื่อบัญชี
                                        </th>
                                            <th width={110} className="text-center">
                                                เดบิต
                                        </th>
                                            <th width={100} className="text-center">
                                                เครดิต
                                        </th>
                                        </tr>
                                    </thead>

                                    <React.Fragment>
                                        <tbody>
                                            {_.orderBy(this.props.allAccountRecordGroup.edges[0].node.accountRecord.edges, 'node.id' , 'asc').map((account_record) =>
                                                account_record.node.debit > 0 &&
                                                <tr key={account_record.node.id}>
                                                    <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                    <td>{account_record.node.name}</td>
                                                    <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                    <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                                </tr>
                                            )}
                                            {this.props.allAccountRecordGroup.edges[0].node.accountRecord.edges.map((account_record) =>
                                                account_record.node.credit > 0 &&
                                                <tr key={account_record.node.id}>
                                                    <td className="text-center">{account_record.node.chartOfAccountCode.chartOfAccountCode}</td>
                                                    <td>{account_record.node.name}</td>
                                                    <td className="text-right">{numberWithComma(account_record.node.debit)}</td>
                                                    <td className="text-right">{numberWithComma(account_record.node.credit)}</td>
                                                </tr>
                                            )}
                                        </tbody>

                                        <tfoot className="font-weight-bold">
                                            {(this.props.current_page === this.props.end_page) &&
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div className="row">
                                                            <div className="col-1">
                                                                รวม/Total
                                                        </div>
                                                            <div className="col-10 text-center">
                                                                {i18n.languages[0] === 'th' ?
                                                                (ThaiBaht(this.props.allAccountRecordGroup.edges[0].node.totalDebit))
                                                                :
                                                                (ConvertNumberToTextEng(this.props.allAccountRecordGroup.edges[0].node.totalDebit))
                                                                }
                                                        </div>
                                                            <div className="col-1" />
                                                        </div>
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.props.allAccountRecordGroup.edges[0].node.totalDebit.toFixed(2))}
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithComma(this.props.allAccountRecordGroup.edges[0].node.totalCredit.toFixed(2))}
                                                    </td>
                                                </tr>
                                            }
                                        </tfoot>
                                    </React.Fragment>
                                </table>
                            </div>
                        }

                        {this.props.current_page === this.props.end_page && 
                            <React.Fragment>
                                <div className="remark">
                                    <div>
                                        <span className="font-weight-bold">หมายเหตุ/Remarks:</span> <br />
                                        {this.props.advance.voidRemark || "-"}
                                    </div>
                                    <span className="font-weight-bold">การชำระเงิน(Conditions of Payments)</span><br />
                                    {paymentChannel.channel['cash'] &&
                                        <div className="row col ml-1">
                                            <span className=" align-middle"> เงินสด/Cash </span>
                                                <div className="col">
                                                    <span>{paymentChannel.cash_text+""} </span>
                                                </div>
                                        </div>
                                    }
                                    {paymentChannel.channel['bank'] && 
                                        <div className="col ml-1">
                                            <span className="align-middle"> เงินโอน/Bank Transfer </span><br/>
                                            <div className = "col ml-2">
                                                {paymentChannel.bank_detail.map((text,index_bank)=>{
                                                    return <div className="row" key = {text.accountNumber + index_bank}><span key = {text.accountNumber}> - {text.bankName2}&emsp;{"เลขที่บัญชี " + text.accountNumber}&emsp;{"วันที่ " + format(text.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;จำนวน&emsp;{numberWithComma(text.price,"0.00")} บาท<br /></span></div>
                                                })}
                                            </div> 
                                        </div>
                                    }
                                    
                                    {paymentChannel.channel['cheque'] &&
                                        <div className="col ml-1">
                                            <span className="align-middle"> เช็คธนาคาร/Cheque Bank </span><br/>
                                            <div className = "col ml-2">
                                                {paymentChannel.cheque_detail.map((text,index_cheque)=>{
                                                    return <div className="row" key = {text.chequeNumber+index_cheque}><span key = {text.chequeNumber}>- เช็คเลขที่ {text.chequeNumber}&emsp;{text.bankName}&emsp;{text.branch && "สาขา " + text.branch}&emsp;{"วันที่  " + format(text.date, 'DD/MM/YYYY', { locale: thLocale })}&emsp;จำนวน {numberWithComma(text.price,"0.00")} บาท <br /></span></div>
                                                })}
                                            </div>
                                        </div>
                                    }
                                    {this.props.advance.advancePettyCashChannel.edges.length > 0 &&
                                        <div className="col ml-1" key="bank">
                                            <span className="align-middle"> เงินสดย่อย/Petty Cash </span><br />
                                            <div className="col ml-2">
                                                {this.props.advance.advancePettyCashChannel.edges.map((petty_cash, index) => {
                                                    return <div className="row" key={petty_cash.node.id + index}><span key={petty_cash.node.id}> - เลขที่ {petty_cash.node.setPettyCash.docNumber}&emsp;{"วันที่ " + format(petty_cash.node.date, 'DD/MM/YYYY', { locale: thLocale })} จำนวน {petty_cash.node.price > 0 ? numberWithComma(petty_cash.node.price) : numberWithComma(petty_cash.node.price,"0.00",false,true)} บาท</span></div>
                                                })}
                                            </div>
                                        </div>
                                    }

                                    {(paymentChannel.channel['other'] || (this.props.allAccountRecordGroup.edges.length > 0 && this.state.isOther)) &&
                                        <div className="col ml-1">
                                            <span className="align-middle"> อื่นๆ/Other </span>
                                            <div className = "col ml-2">
                                                {paymentChannel.other_detail.map((value,index)=>{
                                                    return <div className="row" key = {value.detail+index}><span key = {value.detail}>- {value.detail}&emsp;จำนวน&emsp;{numberWithComma(parseFloat(value.price),"0.00")} บาท <br /></span></div>
                                                })}
                                                {
                                                    this.props.allAccountRecordGroup.edges.map((Other) => (
                                                        Other.node.accountRecord.edges.map((accountRecord,index_account) => {
                                                            if (accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" ||
                                                                accountRecord.node.chartOfAccountCode.chartOfAccountCode === "5400-07") {
                                                                    let name = accountRecord.node.chartOfAccountCode.name
                                                                    if(accountRecord.node.chartOfAccountCode.chartOfAccountCode === "4300-12" && accountRecord.node.debit > 0){
                                                                        name = name.replace("รายรับ","รายจ่าย")
                                                                    }
                                                                return (
                                                                    <div className = "row" key={accountRecord.node.id + index_account}>
                                                                        <span key={accountRecord.node.id} >- {name}&emsp;จำนวน&emsp;{numberWithComma(accountRecord.node.credit > 0 ? accountRecord.node.credit : accountRecord.node.debit)} บาท</span><br />
                                                                    </div>

                                                                )
                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })

                                                    )
                                                    )
                                                }
                                            </div> 

                                        </div>
                                    } 
                                    <br/>
                                </div>
                                
                            </React.Fragment>
                        }
                    </div>

                    {this.props.current_page === this.props.end_page && this.props.count_page <= 1.0 &&
                        <div className="footer">
                            {(this.props.current_page === this.props.end_page) &&
                                <div className="signature-large">
                                    <div className="column-foursign" >
                                        ......................................................<br />
                                        <span className="font-weight-bold">ผู้จัดทำ / Organizer</span>
                                    </div >
                                    <div className="column-foursign">
                                        ......................................................<br />
                                        <span className="font-weight-bold">ผู้ตรวจสอบ / Approver</span>
                                    </div>
                                    <div  className="column-foursign">
                                        ......................................................<br />
                                        <span className="font-weight-bold">ผู้มีอำนาจลงนาม / Authorized</span>
                                    </div>
                                    <div className="column-foursign">
                                        ......................................................<br />
                                        <span className="font-weight-bold">ผู้รับเงิน / Receiver</span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <div className="number-page">
                        <br />
                        {this.props.current_page}/{Math.ceil(this.props.count_page)}
                    </div>

                </div>
            </div>

            
        );
    }
}

export default AdvanceDetail;
