import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigationMenuSetup from "../libs/navigationMenuSetup";
import getSettingMenuData from "../libs/settingMenuData";
import _ from "lodash";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../env/environment";
import Swal from "sweetalert2";
import './setting.scss'
import jwtDecode from "jwt-decode";

const query = graphql`
    query settingTopMenuNavigationsQuery{
        selfProject {
            useAccount
        }
    }
`;

class SettingTopMenuNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = getSettingMenuData()
        this.state.useAccount = false
        this.state.user_super_admin = false
        this.state.token = jwtDecode(window.localStorage.getItem('token'))
        this.state.codename_contact = ["contact_residential", "contact_receivable", "contact_payable"]
        this.state.codename_product = ["accounting_product", "accounting_service", "accounting_expense"]

    }

    componentWillMount() {
        let token = window.localStorage.getItem('token');
        let token_decode = jwtDecode(token)
        fetchQuery(environment, query).then(response_data => {
            let useAccount = false;
            if (response_data.selfProject.useAccount) {
                useAccount = response_data.selfProject.useAccount
                this.setState({
                    useAccount: useAccount,
                    user_super_admin: token_decode.user_super_admin
                })
            }
        });
    }

    componentDidMount() {
        if (this.props.center) {
            NavigationMenuSetup();
        }
    }

    checkUseAccount(useAccount) {
        if (useAccount === false) {
            Swal.fire({
                // title: 'เพราะเรื่องเงิน \n คือเรื่องใหญ่',
                html:
                    '<p style="color: #2D2D30; font-weight: 500; font-size: 48px; line-height: 30px; margin-top: 40px">เพราะเรื่องเงิน</p>' +
                    '<br><p style="color: #2D2D30; font-weight: 600; font-size: 48px; line-height: 20px;">คือเรื่องใหญ่</p>' +
                    '<br><br><p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">หมดปัญหาเก็บเงินค่าส่วนกลางไม่ได้</p>' +
                    '<p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">ตรวจสอบยาก - เงินสูญหาย - หนี้ค้างชำระเยอะ</p>' +
                    '<br><p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">ขับเคลื่อนงานนิติบุคคลด้วยระบบ AI</p>' +
                    '<p style="color: #2D2D30; font-weight: 300; font-size: 18px; line-height: 10px;">จัดการทุกขั้นตอนเกี่ยวกับการเงินได้อย่างมีประสิทธิภาพ</p>' +
                    '<br><br><p style="color: #1578D7; font-weight: 500; font-size: 16px; line-height: 5px;">"ขณะนี้นิติบุคคลโครงการท่าน ยังไม่ได้เปิดใช้บริการฟังก์ชันสำหรับบัญชีและการเงิน</p>' +
                    '<p style="color: #1578D7; font-weight: 500; font-size: 16px; line-height: 10px;">กรุณาติดต่อทีมงาน Silverman Support เพื่อเปิดใช้บริการฟังก์ชันนี้"</p>' +
                    '<br><a href="https://silverman.app/" style="background-color:#1578D7; color: #FFFFFF; text-decoration: none; border: none; padding: 12px 24px; border-radius: 4px;">สมัครใช้บริการ</a>' +
                    '<br><br><br><p style="color: #B3B3B3; font-weight: 300; font-size: 12px; line-height: 5px;">ติดต่อสอบถาม www.silverman.app / E-mail: hello@silverman.app</p>' +
                    '<p style="color: #B3B3B3; font-weight: 300; font-size: 12px; line-height: 5px;">Tel: 081-4426888 / Line: @Silvermanapp / Facebook: Silverman App</p>',
                showConfirmButton: false,
                showCloseButton: true,
                width: 860,
            }).then(() => {
                // Reload the Page
                window.location.reload(false);
            });
        }
    }

    render() {
        let domainName = "";
        if ((this.state.token?.site?.includes('sklasmanagement')) && (localStorage.getItem('site_id') === '49')) {
            domainName = "devsingha.sklasmanagement.co.th"
        } else if ((this.state.token?.site?.includes('victorymanagement')) && (localStorage.getItem('site_id') === '47')) {
            domainName = "devsilverman.victorymanagement.cloud"
        } else if ((localStorage.getItem('site_id') === '331')) {
            domainName = "svmsupport.silverman.cloud"
        }
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="container-fluid bg-gray" id="setting-menu">
                            <div className={"row "
                                + (this.props.mini ? "mini " : "")
                                + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                                {this.props.center &&
                                    <div className="col-12 mt-5 mb-5"><h3 className="text-center">{t('navigation:setting')}</h3></div>
                                }

                                <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                                    <ul>
                                        {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_chart_of_account'}) && */}
                                        <li onClick={() => this.checkUseAccount(this.state.useAccount)}>
                                            <a className="dropdown-toggle" href="/" role="button"
                                                id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="account-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('setting:account_setting')}</span>
                                            </a>
                                            {/* //เงื่อนไข SENA */}
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {/* extraMenu */}
                                                {this.state.account.map((account, index) => {

                                                    if ('set period lock' === account.name || 'setupchartofaccount' === account.name) {
                                                        if (this.state.user_super_admin) return <Link key={index} to={account.path}> {t('setting:' + account.name)} </Link>
                                                    } else if (account.name === 'settingInvoiceTerms' &&
                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on) {
                                                        if (_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: account.codename })
                                                            // && localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=')
                                                        ) {
                                                            return <Link key={index} to={account.path}> {t('setting:' + account.name)} </Link>
                                                        }
                                                    } else if (account.name === 'product') {
                                                        if (_.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_product, element.codename))) {
                                                            return <Link key={index} to={account.path}> {t('setting:' + account.name)} </Link>
                                                        }
                                                    } else if (account.name === 'contact') {
                                                        if (_.some(JSON.parse(localStorage.getItem("permission_list")), (element) => _.includes(this.state.codename_contact, element.codename))) {
                                                            return <Link key={index} to={account.path}> {t('setting:' + account.name)} </Link>
                                                        }

                                                    }
                                                    else if (account.name === 'System Start Date') {
                                                        return <Link key={index} to={account.path}> {t('setting:' + account.name)} </Link>
                                                    }
                                                    //extraMenu
                                                    else if (account.name === 'setuppaymentgateway') {
                                                        if (_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: account.codename }) &&
                                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on) {
                                                            return <Link key={index} to={account.path}> {t('setting:' + account.name)} </Link>
                                                        }
                                                    }
                                                    else if (_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: account.codename })) {
                                                        return <Link key={index} to={account.path}> {t('setting:' + account.name)} </Link>
                                                    }
                                                    else if (account.name === 'setupReceiptDepositUse' && _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'rd_used'])?.menu_on) {
                                                        return <Link key={index} to={account.path}> {t('setting:' + account.name)} </Link>
                                                    }
                                                })}
                                            </div>
                                        </li>
                                        {/* } */}

                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'asset_settingasset' }) &&
                                            //  localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                                            <li>
                                                <Link to="/setting/asset">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/sidebar/setting-side-icon.png'}
                                                        alt="emergency-icon"
                                                        className={this.props.mini && 'd-none'}
                                                    />
                                                    <div><span>{t('setting:Asset setting')}</span></div>
                                                </Link>
                                            </li>
                                        }

                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_settingproject' }) &&
                                            //  localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                                            <li>
                                                <Link to="/setting/enterprise-information-setting">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                        alt="info-icon"
                                                        className={this.props.mini && 'd-none'}
                                                    />
                                                    <span>{t('setting:settingEnterpriseInformation')}</span>
                                                </Link>
                                            </li>
                                        }

                      
                                        {/* } */}

                                        {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_chart_of_account'}) && */}
                                        <li onClick={() => this.checkUseAccount(this.state.useAccount)}>
                                            <a className="dropdown-toggle" href="/" role="button"
                                                id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="note-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('setting:note_setting')}</span>
                                            </a>

                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {this.state.document.map((document, index) => {

                                                    return _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: document.codename }) &&
                                                        (<Link key={index} to={document.path}> {t('setting:' + document.name)}</Link>)
                                                })}
                                            </div>
                                        </li>
                                        {/* } */}
                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_boardpersonal' }) &&
                                            <li className="form-inline">
                                                <Link to="/setting/project-manager">
                                                    <span>{t('setting:Setting Juristic Committee')}</span>
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                        alt="committee-icon"
                                                        className={this.props.mini && 'd-none'}
                                                    />
                                                </Link>

                                            </li>
                                        }
                                         
                                         {/* ปิดไว้ของ Sena */}
                                         {
                                            //  localStorage.getItem('site_id') && (localStorage.getItem('site_id') === '331'||localStorage.getItem('site_id') === '524') &&
                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'silverman_guard'])?.menu_on &&
                                            // _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_chart_of_account'}) &&
                                            <li onClick={() => this.checkUseAccount(this.state.useAccount)}>
                                                <a className="dropdown-toggle" href="/" role="button"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                        alt="account-icon"
                                                        className={this.props.mini && 'd-none'}
                                                    />
                                                    <span>{t('setting:security_setting')} {this.props.mini ? '' : <br />} {t('setting:security_setting2')}</span>
                                                </a>

                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    {this.state.securityVister.map((names, index) => {
                                                        return <Link key={index} to={names.path}> {t('setting:' + names.name)} </Link>
                                                        // if(account.name === 'settingInvoiceTerms'){
                                                        //     if(localStorage.getItem('site_id') && (localStorage.getItem('site_id') === '331'||localStorage.getItem('site_id') === '487')){
                                                        //         return <Link key={index} to = {names.path}> {t('setting:'+names.name)} </Link>
                                                        //     }
                                                        // }else{
                                                        //     return <Link key={index} to = {names.path}> {t('setting:'+names.name)} </Link>
                                                        // }

                                                    })}
                                                </div>
                                            </li>
                                        }

                                        {
                                            //extraMenu
                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on &&
                                            _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_developerproject' }) &&
                                            //  localStorage.getItem('execute') && (localStorage.getItem('execute') === 'dmljdG9yeW1hbmFnZW1lbnQ=') &&
                                            <li>
                                                <Link to="/setting/developer-setting">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                        alt="developer-icon"
                                                        className={this.props.mini && 'd-none'}
                                                    />

                                                    <span>{t('setting:settingDeveloper')}</span>
                                                </Link>
                                            </li>
                                        }
                                       

                                        <li>
                                            <Link to="/setting/setting-invoice-role">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="juristic-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('setting:Setting invoice visibility by status (Tenant app)')}</span>
                                            </Link>
                                        </li>

                                        {/* {localStorage.getItem('site_id') && (localStorage.getItem('site_id') !== '487') && */}
                                        {
                                            //extraMenu
                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'set_menu_customization'])?.menu_on &&
                                            <li>
                                                <Link to="/setting/setting-favorite">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                        alt="developer-icon"
                                                        className={this.props.mini && 'd-none'}
                                                    />

                                                    <span>{t("MenuName:Menu Moblie Setting")}</span>
                                                </Link>
                                            </li>
                                        }
                                        <li>
                                            <a className="dropdown-toggle" href="/" role="button"
                                                id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="note-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('MenuName:foreign ownership setting')}</span>
                                            </a>

                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {this.state.foreignOwnership?.map((document, index) => {
                                                    return <Link key={index} to={document.path}> {t('setting:' + document.name)}</Link>
                                                })}
                                            </div>
                                        </li>
                                        
                                        {/* {
                                         //extraMenu
                                        localStorage.getItem('site_id') && localStorage.getItem('site_id') === '331' &&
                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'set_up_ads'])?.menu_on &&
                                          !this.state.token?.site?.includes('sklasmanagement') &&
                                            <li>
                                                <Link to="/setting/setting-advertise">
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                            alt="developer-icon"
                                                            className={this.props.mini && 'd-none'}
                                                        />
                                                    
                                                    <span>{t("MenuName:Ads Setting")}</span>
                                                </Link>
                                            </li>
                                        } */}

                                        {
                                            this.props.shortMenuOther &&
                                            <li>
                                            <a className="dropdown-toggle" href="/" role="button"
                                                id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="note-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('setting:Other')}</span>
                                            </a>

                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {this.state.otherMenuMiniSetting?.map((document, index) => {
                                                    return <Link key={index} to={document.path}> {t('setting:' + document.name)}</Link>
                                                })}
                                            </div>
                                        </li>
                                        }
                                       

                                    </ul>
                                </div>
                            </div>
                        </div>
                        { !this.props.mini &&
                        <div className="container-fluid bg-gray" id="setting-menu">
                            <div className={"row "
                                + (this.props.mini ? "mini " : "")
                                + (this.props.center ? "center-navigation" : "top-menu-navigation")}>

                                {/* {this.props.center &&
                                    <div className="col-12 mt-5 mb-5"><h3 className="text-center">{t('navigation:setting')}</h3></div>
                                } */}

                                <div className={"col-12 " + (this.props.center ? " fade-up" : "")}>
                                    <ul>
                                    <li>
                                            <Link to="/setting/juristic-setting">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="juristic-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('setting:permission_setting')}</span>
                                            </Link>
                                        </li>

                                        {/* { (localStorage.getItem('site_id') && (localStorage.getItem('site_id') === '331' || this.state.token?.site?.includes('sklasmanagement') )) && */}
                                        <li>
                                            <Link to="/setting/SettingReplyAutoChat">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-side-icon.png'}
                                                    alt="juristic-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <Translation>
                                                    {
                                                        t => <span>{t('setting:auto_message_setting')} </span>
                                                    }
                                                </Translation>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/setting/settingParcel">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="juristic-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('MenuName:parcel setting')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/setting/settingChat">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="juristic-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('MenuName:chat setting')}</span>
                                            </Link>
                                        </li> 
                                        <li>
                                            <Link to="/setting/setting-invoice-template">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="juristic-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('MenuName:invoice template setting')}</span>
                                            </Link>
                                        </li>

                                        
                                        {/* <li>
                                        {
                                      (localStorage.getItem('site_id') && (localStorage.getItem('site_id') === '331') || (localStorage.getItem('site_id') === '163'))  && 
                                        <Link to="/setting/setting-collection-law/register">
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                alt="juristic-icon"
                                                className={this.props.mini && 'd-none'}
                                            />
                                            <span>ตั้งค่าเอกสารติดตามโดยทนายความ</span>
                                        </Link>
                                        }
                                       </li>  
                                       {
                                        (localStorage.getItem('site_id') && (localStorage.getItem('site_id') === '331') || (localStorage.getItem('site_id') === '163'))  &&                       
                                        <li>
                                            <Link to="/setting/lawyer-summary-report/all">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="juristic-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>รายงานสรุปค่าใช้จ่ายออกเอกสารติดตามทวงถามโดยทนายความ</span>
                                            </Link>
                                        </li>  
                                        }      */}
                                        {
                                            domainName &&
                                            <li>
                                                <a href={`https://${domainName}/administrator/auth_setting_dashboard/`}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                        alt="developer-icon"
                                                        className={this.props.mini && 'd-none'}
                                                    />
                                                    <span>{t('MenuName:admin setting')}</span>
                                                </a>
                                            </li>
                                        }
                                        {/* check site siriraj */}
                                        {localStorage.getItem('site_id') === '199' && <li>
                                            <Link to="/setting/setting-receipt-issue-and-holiday">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/images/sidebar/setting-new.png'}
                                                    alt="developer-icon"
                                                    className={this.props.mini && 'd-none'}
                                                />
                                                <span>{t('MenuName:Date of receipt issue and annual holidays Setting')}</span>
                                            </Link>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>}
                    </React.Fragment>
                }
            </Translation>
        );
    }
}

export default SettingTopMenuNavigation;