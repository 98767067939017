import React from 'react';
import { Component } from 'react';
import {NavLink} from "react-router-dom";
import {Translation} from 'react-i18next'

class Navigation extends Component{

    render(){
        return(
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink exact={true} to="/developer/revenue-contract/list/all">
                                    {t("revenueContract:All")}
                                </NavLink>}
                            </Translation>
                        </li>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/developer/revenue-contract/list/uncontracted">
                                    {t("revenueContract:Uncontracted")}
                                </NavLink>}
                            </Translation>
                        </li>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/developer/revenue-contract/list/contracted">
                                    {t("revenueContract:Contracted")}
                                </NavLink>}
                            </Translation>
                        </li>
                    </ul>
                </div>
            </div>

        );
    }

}export default Navigation;