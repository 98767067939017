/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactTypeOfContact = "RESIDENTIAL" | "SUPPLIER" | "%future added value";
export type ContactTypeOfSupplier = "ASSOCIATION" | "COMPANY" | "FOUNDATION" | "GROUP_OF_PERSON" | "JOINT_VENTURE" | "MERCHANT" | "ORDINARY_PARTNERSHIP" | "OTHER" | "PARTNERSHIP" | "PERSON" | "PUBLIC_COMPANY" | "%future added value";
export type prepaidDepositCreateFormQueryVariables = {|
  id: string
|};
export type prepaidDepositCreateFormQueryResponse = {|
  +contact: ?{|
    +id: string,
    +refNumber: string,
    +name: string,
    +firstName: string,
    +lastName: string,
    +registeredAddress: string,
    +registeredCountry: string,
    +registeredProvince: string,
    +registeredDistrict: string,
    +registeredCity: string,
    +registeredPostalCode: string,
    +typeOfContact: ContactTypeOfContact,
    +typeOfSupplier: ContactTypeOfSupplier,
    +vat: ?number,
  |},
  +viewer: ?{|
    +allChartOfAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +chartOfAccountCode: string,
        |}
      |}>
    |}
  |},
  +selfProject: ?{|
    +haveVat: boolean
  |},
|};
export type prepaidDepositCreateFormQuery = {|
  variables: prepaidDepositCreateFormQueryVariables,
  response: prepaidDepositCreateFormQueryResponse,
|};
*/


/*
query prepaidDepositCreateFormQuery(
  $id: ID!
) {
  contact(id: $id) {
    id
    refNumber
    name
    firstName
    lastName
    registeredAddress
    registeredCountry
    registeredProvince
    registeredDistrict
    registeredCity
    registeredPostalCode
    typeOfContact
    typeOfSupplier
    vat
  }
  viewer {
    allChartOfAccount(chartOfAccountCode_Istartswith: "1152") {
      edges {
        node {
          id
          name
          chartOfAccountCode
        }
      }
    }
    id
  }
  selfProject {
    haveVat
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refNumber",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredProvince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredDistrict",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredPostalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfContact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeOfSupplier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vat",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "chartOfAccountCode_Istartswith",
      "value": "1152"
    }
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allChartOfAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chartOfAccountCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allChartOfAccount(chartOfAccountCode_Istartswith:\"1152\")"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "haveVat",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "prepaidDepositCreateFormQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "prepaidDepositCreateFormQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f951adcf450a08139ef74f8876cf6b3",
    "id": null,
    "metadata": {},
    "name": "prepaidDepositCreateFormQuery",
    "operationKind": "query",
    "text": "query prepaidDepositCreateFormQuery(\n  $id: ID!\n) {\n  contact(id: $id) {\n    id\n    refNumber\n    name\n    firstName\n    lastName\n    registeredAddress\n    registeredCountry\n    registeredProvince\n    registeredDistrict\n    registeredCity\n    registeredPostalCode\n    typeOfContact\n    typeOfSupplier\n    vat\n  }\n  viewer {\n    allChartOfAccount(chartOfAccountCode_Istartswith: \"1152\") {\n      edges {\n        node {\n          id\n          name\n          chartOfAccountCode\n        }\n      }\n    }\n    id\n  }\n  selfProject {\n    haveVat\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5341905f9f04ddf693ea87ed94c693d8';

module.exports = node;
