import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import PrivateRoute from '../../libs/privateRoute';
import NoMatch from "../../components/noMatch";
import Guarantee from "./guaranteeMoneyReceived";
import GuaranteeForm from "./guaranteeMoneyReceivedForm";
import GuaranteeView from "./guaranteeMoneyReceivedView";
import ClearGuaranteeView from "./clearGuaranteeMoneyReceivedView";


class Account extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/form/:method/:id?`} component={GuaranteeForm}/>
                <PrivateRoute exact path={`${this.props.match.url}/list/:page`} component={Guarantee}/>
                <PrivateRoute exact path={`${this.props.match.url}/clear-guarantee-money/:id`} component={Guarantee}/>
                <PrivateRoute exact path={`${this.props.match.url}/view/:id`} component={GuaranteeView}/>
                <Route  path={`${this.props.match.url}/view-clear/:id`} component={ClearGuaranteeView}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default Account;