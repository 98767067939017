import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import WrapperContent from '../../components/wrapper/wrapperContent'
import Header from '../../components/header/index'
import Sidebar from '../../components/sidebar'
import { Link } from 'react-router-dom'
import Pagination from '../../libs/newPagination'
import Navigation from './navigationServiceSetting'

const itemService = [
    {no: "P-001", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
    {no: "P-002", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "ยังไม่ชำระ"},
    {no: "P-003", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
    {no: "P-004", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
    {no: "P-005", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
    {no: "P-006", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
    {no: "P-007", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
    {no: "P-008", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
    {no: "P-009", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
    {no: "P-010", nameService: "ค่าบริการทำความสะอาดห้องประชุม", room: "ห้องประชุม1", service_rate: "250 บาท/ชม.", updateDate: "16/08/2021", by: "Marley George", status: "รับชำระแล้ว"},
]

export default class serviceSettingList extends Component {

    status(item){
        let text = ""
        let color = ""
        switch (item) {
            case "รับชำระแล้ว":
                text = "รับชำระแล้ว"
                color = "#68DD6E"
                break;
        
            default:
                text = item
                color = "#DC143C"
                break;
        }
        return <td style={{color: `${color}`}}>{text}</td>
    }


    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid gray" style={{ width: "100%", height: "48px" }}/>
                    <div className="container-fluid box">
                        <div className="row">
                            <div className="col">
                                <h3>
                                    <Link to="/contact">
                                        <img
                                            src={process.env.PUBLIC_URL +
                                                "/images/icons/chevron-back.png"}
                                            alt="back"
                                            className="back"
                                            />
                                    </Link>
                                    บริการเสริมพื้นที่ส่วนกลาง
                                </h3>
                            </div>
                        </div>

                        <div className="content-inner">
                            <Navigation/>
                            <div className="row justify-content-end m-0">
                                <Link to="/contact/global-area-singha/services/setting/create">
                                    <button className="btn btn-primary">
                                        <img src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                                            alt="plus"/>&nbsp;
                                        เพิ่มบริการ
                                    </button>
                                </Link>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <form className="form-inline my-3">
                                        <label className="mr-2 font-weight-bold">แสดงเฉพาะสถานะ:</label>
                                        <select className="form-control col-6">
                                            <option value="เปิดให้บริการ">เปิดให้บริการ</option>
                                        </select>
                                    </form>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="float-lg-right">
                                        <form className="form-inline">
                                            <label className="mr-2"><span className="font-weight-bold">แสดงช่วงเวลา</span>:</label>
                                            <select className="form-control my-3">
                                                <option value="">ทุกช่วงเวลา</option>
                                                <option value="today">วันนี้</option>
                                                <option value="threeDay">3 วัน</option>
                                                <option value="week">สัปดาห์นี้</option>
                                                <option value="mounth">เดือนนี้</option>
                                                <option value="threeMounth">3 เดือนนี้</option>
                                            </select>
                                            <div className="input-group ml-4 my-3">
                                                <input type="text" 
                                                    placeholder="ค้นหา" 
                                                    style={{borderTopLeftRadius: "3px", borderBottomLeftRadius: "3px", border: "1px solid #ced4da", borderRight:"none", padding: "6px 12px"}}
                                                    />
                                                <div className="input-group-prepend">
                                                    <button 
                                                        type="button" 
                                                        className="btn rounded-right input-group-text btn-light" 
                                                        style={{borderLeft:"none",color:"none", background: 'transparent'}}
                                                        >
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/images/icons/search-outline.png"
                                                            }
                                                            alt="calendars"
                                                            style={{ width: "17.81px", height: "17.81px" }}
                                                            />
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive fade-up">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th style={{width: "80px"}}>เลขที่</th>
                                            <th>ชื่อบริการ</th>
                                            <th>ผูกกับส่วนกลาง</th>
                                            <th>อัตราค่าบริการ</th>
                                            <th>วันที่แก้ไขล่าสุด</th>
                                            <th>สร้างโดย</th>
                                            <th>สถานะ</th>
                                            <th style={{width: "10px"}}/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itemService.map((service, index) =>{
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Link 
                                                            to={`/contact/global-area-singha/services/setting/update/${service.no}`}  
                                                            className="text-decoration-none"
                                                            >
                                                            {service.no}
                                                        </Link>
                                                    </td>
                                                    <td>{service.nameService}</td>
                                                    <td>{service.room}</td>
                                                    <td>{service.service_rate}</td>
                                                    <td>{service.updateDate}</td>
                                                    <td>{service.by}</td>
                                                    {this.status(service.status)}
                                                    <td className="pr-0">
                                                        <div className="d-flex justify-content-end">
                                                            <div className="btn-group dropdown">
                                                                <span className="btn pr-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                                    <img src={"/images/icons/choiceNoBG.png"} alt="choice"/>
                                                                </span>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    <React.Fragment>
                                                                        <Link to={`/contact/global-area-singha/services/setting/update/${service.no}`} className="text-decoration-none">
                                                                            <span className="dropdown-item">
                                                                                <img src="/images/icons/edit.png" alt="edit"/>
                                                                                <span className="ml-3">แก้ไข</span>
                                                                            </span>
                                                                        </Link>
                                                                        <div className="dropdown-divider" />
                                                                        <span className="dropdown-item">
                                                                                <img src="/images/icons/bin.png" alt="bin"/>
                                                                                <span className="ml-3">ลบ</span>
                                                                        </span>
                                                                    </React.Fragment>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>           
                                            )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination/>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}
