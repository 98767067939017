import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import PettyCash from "./pettyCash"
import Loading from "../../libs/loading";

const query = graphql`
    query wrapperPettyCashQuery($id: ID!,$docNumber: String!) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            taxIdNumber
            juristicContactNumber
            logo
        }
        
        pettyCashRecord(docNumber: $docNumber){
            edges{
                node{
                    id
                    issuedDate
                    docNumber
                    chartOfAccount {
                        id
                        chartOfAccountCode
                        name
                    }
                    description
                    price
                    status
                    setPettyCash{
                        docNumber
                        withdrawer
                        description
                    }
                }
            }
        }

        pettyCashRecordAccountRecordGroup(pettyCashRecord: $id){
            edges{
                node{
                    id
                    totalDebit
                    totalCredit
                    accountRecord {
                        edges {
                            node {
                                id
                                debit
                                credit
                                name
                                chartOfAccountCode {
                                    id
                                    chartOfAccountCode
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
        userConfig {
            id languages
        }
    }
`;

class WrapperPettyCash extends Component {

    constructor(props) {
        super(props);
        this.print = this.print.bind(this);
    }

    print() {
        window.print();
    }

    render() {
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>CashDeposit</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id: this.props.match.params.id,docNumber: this.props.match.params.id}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            return(
                                <PettyCash 
                                    query={props}
                                    languages={props.userConfig.languages}
                                />
                            )
                        }
                         return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperPettyCash;
