import React, { useState, useEffect } from 'react';
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import "./style/settingInvoiceTermsList.scss"
import DatePicker from "react-datepicker";
import SearchSelect from "../../libs/searchSelect";
import { Redirect } from "react-router";

import Swal from "sweetalert2";
import { graphql } from "babel-plugin-relay/macro";

import environment from "../../env/environment";
import { commitMutation } from "react-relay";

import {billingperiodlistObjects , invoiceAdvanceObjects , billingPeriodMonthObjects, pricingTypesObjects, PeriodAdjust} from './dataFilter'
import { Translation } from "react-i18next";
import i18n from "i18next";

const mutation = graphql`
  mutation settingInvoiceCreateFormMutation($input: CreatePreInvoiceGroupInput!) {
    createPreInvoiceGroup(input: $input) {
      ok
    }
  }
`;
let residentListData = []

const preInvoice_object = {
    amount: 0, //ราคา / งวดเรียกเก็บ
    unit_items: 1, //จำนวนยูนิต
    income_avg: null, //รายได้เฉลี่ย
    pricing_type:null,
    rounding:null,
    
    billing_period: null, //งวดการเรียกเก็บ
    billing_period_month: "no", //เดือนที่เรียกเก็บ

    advance_invoice: "no", //แจ้งหนี้ล้วงหน้า
    rent_pay: false, //ผู้เช่ารับภาระ
    agent_pay: false, //ตัวแทนรับภาระ

    //รหัสบัญชี
    //productAndService:{}
    productAndService: null,
}

const SettingInvoiceCreateForm = (props) => {
    const [residentList, setResidentList] = useState(residentListData);
    const [PreInvoiceList] = useState(preInvoice_object);
    const [BillingPeriodList] = useState(billingperiodlistObjects());
    const [invoiceAdvance] = useState(invoiceAdvanceObjects());
    const [contactData, setcontactData] = useState(null);
    const [redirectToList, setredirectToList] = useState(false);
    const [loading, setloading] = useState(false);
    const [signreq] = useState(Math.random().toString(36));
    const [flgModal, setFlgModal] = useState(false);
    const [AllProduct,setProduct] = useState(props.product);
    useEffect(() => {
        AppendS000()
        setResidentList([...residentList, { ...PreInvoiceList },])
        SenaCheck()
    }, [])

    const AppendS000 = () =>{
        if (!AllProduct?.some((item) => item.node.productCode === "S000")) {
            let fine_object_node = {
              chartOfAccount: props.fine_chart_of_account4300[0].node,
              customerChartOfAccount: props.fine_chart_of_account[0].node,
              description: "",
              id: "fine",
              name: "คำนวณค่าปรับอัตโนมัติ",
              price: 0,
              productCode: "S000",
              servicePricing: { edges: [] },
              totalLeftInStock: null,
              type: "FINE",
            };
            let update_product_and_service = [...AllProduct,{ node: fine_object_node } ];
            setProduct(update_product_and_service);
            // ([...AllProduct, ...update_product_and_service,])
          }
    }
    const SenaCheck = () => {
        //เงื่อนไข SENA
        const sena =  props.sena
        if(sena){
            //Find S001 - S003
            const s001 = _.find(props.product, { 'node': { 'productCode': "S031" } });
            const s002 = _.find(props.product, { 'node': { 'productCode': "S032" } });
            const s003 = _.find(props.product, { 'node': { 'productCode': "S033" } });
            const preInvoice_object_s001 = {
                amount: s001?.node.price | 0, 
                unit_items: 1, 
                income_avg: s001?.node.servicePricing.edges[0].node.periodAdjustment | null,
                pricing_type:s001 ? s001.node.servicePricing.edges[0].node.pricingType : null,
                rounding:s001? s001.node.servicePricing.edges[0].node.rounding : null,
                billing_period: null, 
                billing_period_month: "no", 
                advance_invoice: "no", 
                rent_pay: false,
                productAndService: s001 ? s001.node : null,
            }

            const preInvoice_object_s002 = {
                amount: s002?.node.price | 0, 
                unit_items: 1, 
                income_avg: s002?.node.servicePricing.edges[0].node.periodAdjustment | null,
                pricing_type:s002? s002.node.servicePricing.edges[0].node.pricingType : null,
                rounding:s002? s002.node.servicePricing.edges[0].node.rounding : null,
                billing_period: null, 
                billing_period_month: "no", 
                advance_invoice: "no", 
                rent_pay: false,
                productAndService: s002 ? s002.node : null,
            }

            const preInvoice_object_s003 = {
                amount: s003?.node.price | 0, 
                unit_items: 1, 
                income_avg: s003?.node.servicePricing.edges[0].node.periodAdjustment | null,
                pricing_type:s003 ? s003.node.servicePricing.edges[0].node.pricingType : null,
                rounding:s003? s003.node.servicePricing.edges[0].node.rounding : null,
                billing_period: null, 
                billing_period_month: "no", 
                advance_invoice: "no", 
                rent_pay: false,
                productAndService: s003 ? s003.node : null,
            }

            let preInvoice_object_set = []
            if(s001)preInvoice_object_set.push(preInvoice_object_s001);
            if(s002)preInvoice_object_set.push(preInvoice_object_s002);
            if(s003)preInvoice_object_set.push(preInvoice_object_s003);
            if(preInvoice_object_set.length === 0){
                setResidentList([...residentList, { ...PreInvoiceList },])
            }else{
                setResidentList([...residentList, ...preInvoice_object_set,])
            }
            
        }else{
            setResidentList([...residentList, { ...PreInvoiceList },])
        }
    }
    
    const onOpenModal = () => {
        setFlgModal(true)
    }

    const onCloseModal = () => {
        setFlgModal(false)
    }

    const contactDataInputChange = (e) => {
        setcontactData(e)
    }

    const appendNewRow = () => {
        setResidentList([...residentList, { ...preInvoice_object },])
    }

    const RemoveRow = (e) => {
        let transaction_list = _.cloneDeep(residentList);
        transaction_list.splice(e, 1)
        setResidentList(transaction_list);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setloading(true)
        let variables = {
            input: {
                preInvoiceGroup: {
                    contactId: props.listContactId,
                    preInvoice: JSON.stringify(residentList),
                    effect_date: contactData,
                },
                clientMutationId: "PreInvoice"+signreq,
            },
        };
        //create preinvoice
        commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
                setredirectToList(false);
                if (response.createPreInvoiceGroup.ok) {
                    Swal.fire(i18n.t('settingInvoiceTerms:savedSuccessfully'), "", "success").then(() => {
                        setredirectToList(true);
                    });
                } else {
                    Swal.fire(i18n.t('settingInvoiceTerms:saveFailed'), "", "warning").then(() => {
                        setloading(false);
                    });
                }
            },
            onError: (err) => {
                Swal.fire("Error!", i18n.t('settingInvoiceTerms:pleaseTryAgain'), "warning").then(() => {
                    setredirectToList(false);
                    setloading(false);
                });
            },
        });
    }

    function handleInputAmount(value,index){
        let transaction_list = _.cloneDeep(residentList);
        if(transaction_list[index].amount != value){
            transaction_list[index].amount = value;
            setResidentList(transaction_list);
        }
    }

    const handleInputChange = (e) => {
        let transaction_list = _.cloneDeep(residentList);
        if (e.target.name === "productAndService") {

            if( _.find(transaction_list, { 'productAndService': { 'id': e.target.value } }) !== undefined ){
                transaction_list[e.target.dataset.id].productAndService = null;
                // Swal.fire("มีรายการซ้ำ!", "", "warning").then(() => {
                //     setResidentList(transaction_list);
                // })
                Swal.fire({
                    html: '<h1>มีรายการซ้ำ!</h1>',
                    type : 'warning',
                    showConfirmButton: true,
                    confirmButtonText: 'ยอมรับ',
                    })
                return;
            }

            let product_and_service = _.find(AllProduct, { 'node': { 'id': e.target.value } });
            transaction_list[e.target.dataset.id].productAndService = product_and_service.node
            if(e.target.value === 'fine' || product_and_service.node.type === 'FINE'){
                transaction_list[e.target.dataset.id].billing_period = 'm'
            }else{
                transaction_list[e.target.dataset.id].income_avg = product_and_service.node.servicePricing?.edges[0].node.periodAdjustment || null;
                transaction_list[e.target.dataset.id].pricing_type = product_and_service.node.servicePricing?.edges[0].node.pricingType || null;
                transaction_list[e.target.dataset.id].rounding = product_and_service.node.servicePricing?.edges[0].node.rounding || null;
                
                if (product_and_service.node.servicePricing.edges[0].node.pricingType === "STATIC" || 
                    product_and_service.node.servicePricing.edges[0].node.pricingType === "WATER_METER" || 
                    product_and_service.node.servicePricing.edges[0].node.pricingType === "ELECTRIC_METER" || 
                    product_and_service.node.servicePricing.edges[0].node.pricingType === "CAR_PARK" ||
                    product_and_service.node.servicePricing.edges[0].node.pricingType === "MOTORCYCLE_PARK"){
                    transaction_list[e.target.dataset.id].advance_invoice = 'no';
                }
            }
            // transaction_list[e.target.dataset.id].amount = product_and_service.node.price;
        }
        else if (e.target.name === "BillingPeriodList") {
            transaction_list[e.target.dataset.id].billing_period = e.target.value;
            if (e.target.value === "oy1" || e.target.value === "m")
                transaction_list[e.target.dataset.id].billing_period_month = "no";
            else {
                transaction_list[e.target.dataset.id].billing_period_month = null;
            }

        } else if (e.target.name === "BillingPeriodMonth") {
            transaction_list[e.target.dataset.id].billing_period_month = e.target.value;
        } else if (e.target.name === "rentPay") {
            if (transaction_list[e.target.dataset.id].rent_pay) {
                transaction_list[e.target.dataset.id].rent_pay = false;
            }
            else {
                transaction_list[e.target.dataset.id].agent_pay = false;
                transaction_list[e.target.dataset.id].rent_pay = e.target.value;
            }
        }else if (e.target.name === "agentPay") {
            if (transaction_list[e.target.dataset.id].agent_pay){
                transaction_list[e.target.dataset.id].agent_pay = false;
            }
            else{ 
                transaction_list[e.target.dataset.id].agent_pay = e.target.value;
                transaction_list[e.target.dataset.id].rent_pay = false;
            }
        } else if (e.target.name === "invoiceAdvance") {
            transaction_list[e.target.dataset.id].advance_invoice = e.target.value;
        }
        setResidentList(transaction_list);

    }

    if (props.contact.length === 0) {
        return <Redirect to={"/setting/invoice-setting-terms/form/select"} />;
    }
    if (redirectToList) {
        return <Redirect to={"/setting/invoice-setting-terms"} />;
    }
    return (
        <>
            {/* Modal funtion */}
            {flgModal ?
                <Translation>
                        {t => (
                            <div className="modall-overlay">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header" >
                                            <div className="col" style={{ paddingLeft: 0 }}>
                                                <h5 className="modal-title">
                                                {t("settingInvoiceTerms:customerInformation")}
                                                </h5>
                                            </div>

                                            <button type="button" className="close" onClick={onCloseModal}
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div className="modal-body" style={{height:'500px' , overflowY:'scroll' , overflowX: 'hidden'}}>
                                            <div className="col-12">
                                                <div className="table-responsive fade-up">
                                                    <table className="table table-hover mt-2">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th>{t("settingInvoiceTerms:customerInformation")}</th>
                                                                <th>{t("settingInvoiceTerms:roomnumber/housenumber/company")}</th>
                                                                <th>{t("settingInvoiceTerms:firstName")}</th>
                                                                <th>{t("settingInvoiceTerms:lastName")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                _.orderBy(props.contact,'node.name','desc' ).map((contacts) => {
                                                                    let first_name_list = contacts.node.firstName.split(",");
                                                                    let last_name_list = contacts.node.lastName.split(",");
                                                                    if (first_name_list.length !== 1) {
                                                                        return first_name_list.map((value, index) =>
                                                                            <tr key={'owner' + index}>
                                                                                <td>{index === 0 ? contacts.node.refNumber : ''}</td>
                                                                                <td>{index === 0 ? contacts.node.name : ''}</td>
                                                                                <td>{value}</td>
                                                                                <td>{last_name_list[index]}</td>
                                                                            </tr>
                                                                        )
                                                                    }

                                                                    return (
                                                                        <tr key={contacts.node.id}>
                                                                            <td>{contacts.node.refNumber}</td>
                                                                            <td>{contacts.node.name}</td>
                                                                            <td>{first_name_list[0]}</td>
                                                                            <td>{last_name_list[0]}</td>
                                                                        </tr>
                                                                    )

                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </Translation>
                : null}
            <Translation>
                {t => (
                    <div className="content-inner mx-2">
                        <form onSubmit={handleSubmit}>
                            <div className="mt-6">
                                <p className="text-headline-5">{t("settingInvoiceTerms:customerList")}</p>
                            </div>
                            <hr />
                            <div className="col-12 pl-0 mt-6 row">
                                <div className="col-6 pdr">
                                    {props.listContactId.length > 1 ?
                                        <div className="calendars-wrapper mt">
                                            <input
                                                type="text"
                                                className="inputNumber col-12 mt-5"
                                                placeholder="แบบหลายห้อง"
                                                disabled
                                            ></input>
                                            <button onClick={onOpenModal} className="calendars float-right btn-event mt-5" type="button">
                                                <span className="label-select-time">{t("settingInvoiceTerms:view")}</span>
                                            </button>
                                        </div>
                                        :
                                        <div className="calendars-wrapper mt">
                                            <input
                                                type="text"
                                                className="inputNumber col-12 mt-5"
                                                value={props.contact[0].node.name + " " + props.contact[0].node.refNumber + " " + props.contact[0].node.firstName + " " + props.contact[0].node.lastName}
                                                disabled
                                            ></input>
                                        </div>
                                    }
                                </div>
                                <div className="col-4 pdr">
                                    <h5>{t("settingInvoiceTerms:billingStartDate")}</h5>
                                    <div className="calendars-wrapper mt mt-3">
                                        <DatePicker
                                            id="calDepreciationDate"
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control float-left colorborder"
                                            selected={contactData}
                                            onChange={contactDataInputChange}
                                            // required
                                        />
                                        <div className="calendars float-right">
                                            <img className="icon" onClick={() => this.onClickCalendar()}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/images/icons/calendar-outline.png"
                                                }
                                                alt="calendars"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10">
                                <div className="row">
                                    <p className="col-md-2 text-headline-5">{t("settingInvoiceTerms:conditionLists")}</p>
                                </div>
                            </div>
                            <React.Fragment>
                                <div className="card-table fade-up">
                                    <table className="table table-hover">
                                        <thead className="table-list">
                                            <tr> 
                                                <th width="200" className="text-global-table">{t("settingInvoiceTerms:codeRevenuename")}</th>
                                                <th width="120" className="text-global-table">{t("settingInvoiceTerms:incomeAccount")}</th>
                                                <th width="120" className="text-global-table">{t("settingInvoiceTerms:accountsReceivableRecord")}</th>
                                                <th width="80" className="text-global-table">{t("settingInvoiceTerms:averageIncome")}</th>
                                                <th width="120" className="text-global-table">{t("settingInvoiceTerms:deferred")}</th>
                                                <th width="60" className="text-global-table">{t("settingInvoiceTerms:calculationMethod")}</th>
                                                <th width="60" className="text-global-table">{t("settingInvoiceTerms:specifyPrice")}</th>
                                                <th width="120" className="text-global-table">{t("settingInvoiceTerms:billingPeriod")}</th>
                                                <th width="120" className="text-global-table">{t("settingInvoiceTerms:billingMonth")}</th>
                                                <th width="100" className="text-global-table">{t("settingInvoiceTerms:advanceNotice(month)")}</th>
                                                <th width="60" className="text-global-table">{t("settingInvoiceTerms:price/BillingPeriod")}</th>
                                                <th width="60" className="text-global-table">{t("settingInvoiceTerms:theTenantBearsTheBurden")}</th>
                                                <th width="60" className="text-global-table">{t("settingInvoiceTerms:theAgentBearsTheBurden")}</th>
                                                <th width="80" className="text-global-table"></th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {residentList.map((translation, index) => {
                                                let productAndServiceOrChartOfAccount = "";
                                                let chartOfAccountName = "";
                                                let customerChartOfAccount = "";
                                                let periodAdjustment = false;

                                                let price = 0;
                                                let rounding = "";
                                                let pricingType_cal = "";
                                                let pricingType = "";
                                                let receiveDeposit_code = "";
                                                let fine = false
                                                if (translation.productAndService) {
                                                    productAndServiceOrChartOfAccount = translation.productAndService;
                                                    if(translation.productAndService.id === 'fine' || translation.productAndService.type === 'FINE'){
                                                        fine = true
                                                        customerChartOfAccount = translation.productAndService.customerChartOfAccount.chartOfAccountCode + " " + translation.productAndService.customerChartOfAccount.name;
                                                        chartOfAccountName = translation.productAndService.chartOfAccount.chartOfAccountCode + " " + translation.productAndService.chartOfAccount.name;
                                                    }
                                                    else if(translation.productAndService.id !== 'fine' || translation.productAndService.type !== 'FINE'){
                                                                                                        
                                                        chartOfAccountName = translation.productAndService.chartOfAccount.chartOfAccountCode + " " + translation.productAndService.chartOfAccount.name;
                                                        customerChartOfAccount = translation.productAndService.customerChartOfAccount.chartOfAccountCode + " " + translation.productAndService.customerChartOfAccount.name;
                                                        if (translation.productAndService.servicePricing.edges[0]?.node.deferredIncome) {
                                                            receiveDeposit_code = translation.productAndService.servicePricing.edges[0].node.deferredIncome.chartOfAccountCode + " " + translation.productAndService.servicePricing.edges[0].node.deferredIncome.name
                                                        }
                                                        periodAdjustment = translation.productAndService.servicePricing.edges[0].node.periodAdjustment;
                                                        rounding = translation.productAndService.servicePricing.edges[0].node.rounding;

                                                        pricingType = pricingTypesObjects(translation.productAndService.servicePricing.edges[0].node.pricingType);
                                                        pricingType_cal = translation.productAndService.servicePricing.edges[0].node.pricingType;
                                                    
                                                    }

                                                    if(pricingType_cal === "INDIVIDUAL"){
                                                        let Individual = translation.productAndService.servicePricingEachUnit.edges
                                                        const Individual_find = _.find(Individual,{ 'node': { 'unit': { 'id' : props.contact[0].node.residential.id } } })
                                                        price = Individual_find?.node.pricing | 0
                                                    }
                                                    else{
                                                        price = translation.productAndService.price
                                                    }

                                                    if(pricingType_cal !== "RATIO"){
                                                        PeriodAdjust(pricingType_cal,
                                                            price,
                                                            rounding,
                                                            translation.billing_period,
                                                            props.contact[0].node.residential.size,
                                                            translation.productAndService.id,
                                                            props.contact[0].node.id
                                                        ).then((value) =>{
                                                            handleInputAmount(value,index)
                                                        })
                                                    }
                                                    else{
                                                        PeriodAdjust(pricingType_cal,
                                                            price,
                                                            rounding,
                                                            translation.billing_period,
                                                            props.contact[0].node.residential.ownershipRatio,
                                                            translation.productAndService.id,
                                                            props.contact[0].node.id
                                                        ).then((value) =>{
                                                            handleInputAmount(value,index)
                                                        })
                                                    }
                                                }
                                                let billing_period_month = billingPeriodMonthObjects(translation.billing_period)
                                                return (
                                                    <tr key={"transaction" + index}>
                                                        <td width="200">
                                                            <SearchSelect
                                                                onChange={handleInputChange}
                                                                value={productAndServiceOrChartOfAccount.id}
                                                                name="productAndService"
                                                                placeholder="รายการ"
                                                                data-id={index}
                                                                data-key="transaction_list"
                                                                queryObject={AllProduct}
                                                                keyOfValue="id"
                                                                require={true}
                                                                keyOfLabel="productCode:name"
                                                            />
                                                        </td>

                                                        <td>
                                                            <span>
                                                                {
                                                                    chartOfAccountName
                                                                }
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span>
                                                                {
                                                                    customerChartOfAccount
                                                                }
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <input className="checkbox-select"
                                                                type="checkbox"
                                                                checked={periodAdjustment}
                                                                disabled />
                                                        </td>

                                                        <td>
                                                            <span>{
                                                                periodAdjustment ? receiveDeposit_code : null
                                                                }
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span>
                                                                {
                                                                    pricingType
                                                                }
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span>
                                                                {
                                                                    numberWithComma(price)
                                                                }
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <SearchSelect
                                                                onChange={handleInputChange}
                                                                value={translation.billing_period}
                                                                name="BillingPeriodList"
                                                                placeholder="รายการ"
                                                                data-id={index}
                                                                data-key="transaction_list"
                                                                queryObject={BillingPeriodList}
                                                                keyOfValue="value"
                                                                require={true}
                                                                keyOfLabel="label"
                                                                disabled={ fine }
                                                            />
                                                        </td>

                                                        <td>
                                                            <SearchSelect
                                                                onChange={handleInputChange}
                                                                value={translation.billing_period_month}
                                                                name="BillingPeriodMonth"
                                                                placeholder="เลือกรายเดือน"
                                                                data-id={index}
                                                                data-key="transaction_list"
                                                                queryObject={billing_period_month}
                                                                keyOfValue="value"
                                                                require={true}
                                                                keyOfLabel="label"
                                                                disabled={ fine || translation.billing_period === "oy1" || translation.billing_period === "m" ? true : false}
                                                            />
                                                        </td>

                                                        <td>
                                                            <SearchSelect
                                                                onChange={handleInputChange}
                                                                value={translation.advance_invoice}
                                                                name="invoiceAdvance"
                                                                placeholder="เลือกแจ้งหนี้ล้วงหน้า"
                                                                data-id={index}
                                                                data-key="transaction_list"
                                                                queryObject={invoiceAdvance}
                                                                keyOfValue="value"
                                                                require={true}
                                                                keyOfLabel="label"
                                                                disabled={ fine ||
                                                                    translation.billing_period === "oy1" || pricingType_cal === "STATIC" || pricingType_cal === "WATER_METER" ||
                                                                    pricingType_cal === "ELECTRIC_METER" || pricingType_cal === "CAR_PARK" ||
                                                                    pricingType_cal === "MOTORCYCLE_PARK" ? true : false
                                                                }
                                                            />
                                                        </td>

                                                        <td>
                                                            <span>
                                                                {
                                                                    numberWithComma(translation.amount)
                                                                }
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <input className="checkbox-select"
                                                                onChange={handleInputChange}
                                                                type="checkbox"
                                                                name="rentPay"
                                                                data-id={index}
                                                                data-key="transaction_list"
                                                                checked={translation.rent_pay}
                                                            />
                                                        </td>

                                                        <td>
                                                            <input className="checkbox-select"
                                                                onChange={handleInputChange}
                                                                type="checkbox"
                                                                name="agentPay"
                                                                data-id={index}
                                                                data-key="transaction_list"
                                                                checked={translation.agent_pay}
                                                            />
                                                        </td>

                                                        <td>
                                                            {residentList.length > 1 ?
                                                                <button onClick={() => RemoveRow(index)} className="btn-event" type="button">

                                                                    <img className="icon-bin-outline"
                                                                        src={process.env.PUBLIC_URL + "/images/icons/bin-outline.png"}
                                                                        alt="bin"
                                                                    />

                                                                </button>
                                                                :
                                                                null
                                                            }
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    type="button"
                                    className="btn add-button add mt-3"
                                    onClick={appendNewRow}
                                >
                                    +{t("createUpdateForm:add_list")}
                                </button>
                            </React.Fragment>
                            <div className="row">
                                <div className="col text-right mt-2 button-grp">
                                    {/* <Link to={"/setting/invoice-setting-terms"}> */}
                                    <button type="submit" className="btn btn-new-search add mr-3 text-primary-button"
                                        disabled={loading}>
                                        {loading &&
                                                    <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                        <span>{t("settingInvoiceTerms:saveInvoiceTerms")}</span>
                                    </button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </Translation>
        </>
    )
}

export default SettingInvoiceCreateForm