/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssetSaleStatus = "ACTIVE" | "DETERIORATE" | "DISAPPEAR" | "SOLD" | "%future added value";
export type AssetType = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type assetAmortizedReportQueryVariables = {|
  type?: ?string,
  search?: ?string,
  first?: ?number,
  last?: ?number,
  order?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  date?: ?any,
  saleStatusAsset?: ?$ReadOnlyArray<?string>,
|};
export type assetAmortizedReportQueryResponse = {|
  +allAsset: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +name: string,
        +type: AssetType,
        +storage: ?string,
        +purchaseDate: ?any,
        +costPrice: ?number,
        +calDepreciation: boolean,
        +lifeTimeYear: ?number,
        +depreciationRatePercent: ?number,
        +description: ?string,
        +remark: string,
        +nounClassifier: string,
        +depreciationAmount: ?number,
        +sellerOther: ?string,
        +calDepreciationDate: ?any,
        +saleStatus: AssetSaleStatus,
        +seller: ?{|
          +id: string,
          +refNumber: string,
          +taxIdNumber: ?string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
        +historicalAmount: number,
        +assetDepreciate: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +depreciatePrice: number,
            |}
          |}>
        |},
        +internalAssetCode: ?string,
        +carcassPrice: ?number,
        +saleDate: ?any,
      |}
    |}>,
  |}
|};
export type assetAmortizedReportQuery = {|
  variables: assetAmortizedReportQueryVariables,
  response: assetAmortizedReportQueryResponse,
|};
*/


/*
query assetAmortizedReportQuery(
  $type: String
  $search: String
  $first: Int
  $last: Int
  $order: String
  $startDate: DateTime
  $endDate: DateTime
  $date: Date
  $saleStatusAsset: [String]
) {
  allAsset(type: $type, search: $search, first: $first, last: $last, order: $order, startDate: $startDate, endDate: $endDate, saleStatusAsset: $saleStatusAsset) {
    totalCount
    edges {
      node {
        id
        docNumber
        name
        type
        storage
        purchaseDate
        costPrice
        calDepreciation
        lifeTimeYear
        depreciationRatePercent
        description
        remark
        nounClassifier
        depreciationAmount
        sellerOther
        calDepreciationDate
        saleStatus
        seller {
          id
          refNumber
          taxIdNumber
          name
          firstName
          lastName
        }
        historicalAmount
        assetDepreciate(date_Lte: $date) {
          edges {
            node {
              id
              depreciatePrice
            }
          }
        }
        internalAssetCode
        carcassPrice
        saleDate
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "saleStatusAsset"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "saleStatusAsset",
        "variableName": "saleStatusAsset"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "AssetNodeConnection",
    "kind": "LinkedField",
    "name": "allAsset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "storage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purchaseDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "costPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calDepreciation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lifeTimeYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depreciationRatePercent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remark",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nounClassifier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depreciationAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sellerOther",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calDepreciationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saleStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxIdNumber",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "historicalAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "date_Lte",
                    "variableName": "date"
                  }
                ],
                "concreteType": "AssetDepreciateNodeConnection",
                "kind": "LinkedField",
                "name": "assetDepreciate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetDepreciateNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetDepreciateNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "depreciatePrice",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalAssetCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "carcassPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saleDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "assetAmortizedReportQuery",
    "selections": (v11/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v8/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "assetAmortizedReportQuery",
    "selections": (v11/*: any*/)
  },
  "params": {
    "cacheID": "02080ffe6bddc28e13c29f70c54e3620",
    "id": null,
    "metadata": {},
    "name": "assetAmortizedReportQuery",
    "operationKind": "query",
    "text": "query assetAmortizedReportQuery(\n  $type: String\n  $search: String\n  $first: Int\n  $last: Int\n  $order: String\n  $startDate: DateTime\n  $endDate: DateTime\n  $date: Date\n  $saleStatusAsset: [String]\n) {\n  allAsset(type: $type, search: $search, first: $first, last: $last, order: $order, startDate: $startDate, endDate: $endDate, saleStatusAsset: $saleStatusAsset) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        name\n        type\n        storage\n        purchaseDate\n        costPrice\n        calDepreciation\n        lifeTimeYear\n        depreciationRatePercent\n        description\n        remark\n        nounClassifier\n        depreciationAmount\n        sellerOther\n        calDepreciationDate\n        saleStatus\n        seller {\n          id\n          refNumber\n          taxIdNumber\n          name\n          firstName\n          lastName\n        }\n        historicalAmount\n        assetDepreciate(date_Lte: $date) {\n          edges {\n            node {\n              id\n              depreciatePrice\n            }\n          }\n        }\n        internalAssetCode\n        carcassPrice\n        saleDate\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad4d7a357886745f394476b6c184616f';

module.exports = node;
