/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type decodeUrlQueryVariables = {|
  param?: ?string
|};
export type decodeUrlQueryResponse = {|
  +decodeUrl: ?string
|};
export type decodeUrlQuery = {|
  variables: decodeUrlQueryVariables,
  response: decodeUrlQueryResponse,
|};
*/


/*
query decodeUrlQuery(
  $param: String
) {
  decodeUrl(param: $param)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "param"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "param",
        "variableName": "param"
      }
    ],
    "kind": "ScalarField",
    "name": "decodeUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "decodeUrlQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "decodeUrlQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a253ea12a242402eb1a76a730c60f12c",
    "id": null,
    "metadata": {},
    "name": "decodeUrlQuery",
    "operationKind": "query",
    "text": "query decodeUrlQuery(\n  $param: String\n) {\n  decodeUrl(param: $param)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a78b6f09281d5431084454ca938827b7';

module.exports = node;
