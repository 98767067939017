import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import CreditCardListAll from './creditCardListAll';
import CreditCardDepositCreate from './creditCardDepositCreate';
import CreditCardDetail from './creditCardDetail';



class CreditCard extends Component {

    render() {
        return (
            <React.Fragment>
                <PrivateRoute exact path={`${this.props.match.url}`} component={CreditCardListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/list`} component={CreditCardListAll}/>
                <PrivateRoute exact path={`${this.props.match.url}/create/:status`} component={CreditCardDepositCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={CreditCardDetail}/>
            </React.Fragment>
        );
    }
}

export default CreditCard;