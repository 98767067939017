import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "./selectContact.scss";
import ListContactSupplierPayable from './listContactSupplierPayable'
import { graphql } from "babel-plugin-relay/macro";
import _ from "lodash";
const allContact = graphql`
    query selectContactPayableQuery($search: String, $contact_type: String, $payment_type: String, $conveyance: String, $payable_filter: Boolean) {
        contactViewer {
            id
            allContact(search: $search, typeOfContact: $contact_type , typeOfPayment: $payment_type, conveyance: $conveyance, isActive: true, payableFilter: $payable_filter){
                edges{
                    node{
                        id
                        refNumber
                        name
                        firstName
                        lastName
                        residential {
                            id
                            conveyance
                        }
                        ownerName
                        mailingAddress
                        mailingDistrict
                        mailingCity
                        mailingProvince
                        mailingPostalCode
                        
                        renterName
                        renterAddress
                        renterDistrict
                        renterCity
                        renterProvince
                        renterPostalCode

                        agentName
                        agentAddress
                        agentDistrict
                        agentCity
                        agentProvince
                        agentPostalCode

                        allAmountNotPaid
                    }
                }
            }
        }
    }
`;

const list_expense = ['purchase', 'pay', 'accept_credit_note', 'prepaid_deposit', 'other_expense', 'payable_movement', 'flow_of_prepaid_deposit'];


class SelectContactPayable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: [],
            contact_residential: [],
            contact_count: 0,
            select_value:list_expense.includes(props.accountType) ? 'payable' : 'receivable',
            contact_type: 'supplier',
            payment_type: list_expense.includes(props.accountType) ? 'payable' : 'receivable',
            header_text: list_expense.includes(props.accountType) ? 'creditor_information' : 'debtor_information', //ข้อมูลเจ้าหนี้ ลูกหนี้
            //เงื่อนไข SENA
            preinvoice: true,
            carReportDocListData:this.props?.carReportDocList,
            carTransactionIdListData:this.props?.carTransactionIdList,
            role: "",
        };

        this.selectContact = this.selectContact.bind(this);
        this.toggle_checkbox = this.toggle_checkbox.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSelectAllContact = this.onSelectAllContact.bind(this);
        this.onContactTypeChange = this.onContactTypeChange.bind(this);
    }

    componentDidMount() {
        if(this.props.carTransactionIdList && this.props.carReportDocList){
            this.props.getcarReportDocList(this.state.carReportDocListData)
            this.props.getcarTransactionIdList(this.state.carTransactionIdListData)
            let custom_event = {
                target: {
                    name: "contact_type",
                    value: 'unknown',
                }
            };
            this.handleInputChange(custom_event)  
        }
        else if (this.props.initialContactType) {
            
            let custom_event = {
                target: {
                    name: "contact_type",
                    value: this.props.initialContactType,
                }
            };
            this.handleInputChange(custom_event)
        }
    }

    selectContact(param, detail) {
        let contact = [...this.state.contact];
        let contact_residential = [...this.state.contact_residential];
        let contact_count = this.state.contact_count;

        //เช็คว่าใน this.state.contact มี id นี้อยู่รึป่าว
        if (this.props.accountType === "invoice") {
            if (contact.includes(param)) {
                // eslint-disable-next-line
                for (let selected in contact) {
                    if (contact[selected] === param) {
                        contact.splice(selected, 1);
                        contact_count = contact_count - 1;
                        if (detail) contact_residential.splice(selected, 1);
                        break
                    }
                }
            } else {
                contact.push(param);
                contact_count = contact_count + 1;
                if (detail) contact_residential.push(detail.node);
            }

        } else {
            contact = [param];
            contact_count = 1;
            if (detail) contact_residential = [detail.node];
        }
        this.setState({
            contact: contact,
            contact_residential: contact_residential,
            contact_count: contact_count,
        }, () => this.props.updateCustomerList(this.state.contact,this.state.contact_residential));
    }
    toggle_checkbox(e){
        this.setState({ preinvoice: e.currentTarget.checked ? true: false }, () => this.props.updatePreinvoice(this.state.preinvoice))
    }

    handleInputChange(e) {
        if (e.target.name === "contact_type") {
            this.onContactTypeChange();

            if (e.target.value === 'residential') {
                this.setState({ select_value: e.target.value, payment_type: '', contact_type: 'residential' })
            } else if (e.target.value === 'unknown') {
                this.setState({ select_value: e.target.value, payment_type: e.target.value, contact_type: 'unknown', contact: [''] }, () => this.props.accountType === "other_receiver" && this.props.getContactType(this.state.contact_type))
            } else {
                this.setState({ select_value: e.target.value, payment_type: e.target.value, contact_type: 'supplier', preinvoice:false }, () => this.props.accountType === "invoice" && this.props.updatePreinvoice(this.state.preinvoice))
            }

        }

        if (e.target.name === "unknown_contact") {
            this.setState({ contact: [e.target.value] }, () => this.props.updateCustomerList(this.state.contact));
        }
    }

    onContactTypeChange() {
        this.setState({
            contact: [],
            contact_residential: [],
            contact_count: 0,
        }, () => this.props.updateCustomerList(this.state.contact,this.state.contact_residential));
    }

    onSelectAllContact(all_contact, role) {
        let allContact = all_contact;
        if(role === "rent") {
            allContact = all_contact.filter(item => item.node.renterName);
        } else if(role === "agent") {
            allContact = all_contact.filter(item => item.node.agentName);
        }

        let contact = [];
        let contact_residential = [];
        let contact_count = allContact.length;

        allContact.forEach((contact_detail) => {
            contact.push(contact_detail.node.id);
            contact_residential.push(contact_detail.node);
        });

        this.setState({
            contact: contact,
            contact_residential: contact_residential,
            contact_count: contact_count,
            role: role,
        }, () => this.props.updateCustomerList(this.state.contact,this.state.contact_residential,role));
    }
    

    render() {    
        return (
            <div className="container-fluid box" id="select-contact">
                <div className="row">
                    <div className="col">
                        <h3 className="mb-4">
                            <Link to={this.props.backLink}>
                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                    alt="back" className="back cursor" />
                            </Link>
                            <Translation>
                                {
                                    t => t('accounting:add_' + this.props.accountType)
                                }
                            </Translation>
                        </h3>
                    </div>
                </div>
                <div className="content-inner">
                    <div className="card-add-table-invoice fade-up">
                        <div className="row">
                            <div className="col">
                                <Translation>{t => <strong>{t('selectContact:' + this.state.header_text)}</strong>}</Translation>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-3">
                                <select className="form-control" name="contact_type"
                                    onChange={this.handleInputChange}
                                    value={this.state.select_value}
                                    disabled={this.props.disable_select_type}>
                                    {list_expense.includes(this.props.accountType) ?
                                        <Translation>{t => <option value="payable">{t('selectContact:creditor_info')}</option>}</Translation>
                                        :
                                        <React.Fragment>
                                            {!this.props.disableResidential && <Translation>{t => <option value="residential">{t('selectContact:co-owner_member')}</option>}</Translation>}
                                            {!this.props.disableReceivable && <Translation>{t => <option value="receivable">{t('selectContact:debtor_info')}</option>}</Translation>}
                                        </React.Fragment>
                                    }
                                    {(this.props.accountType === "other_expense" || this.props.accountType === "other_receiver") &&
                                        <Translation>{t => <option value="unknown">{t('selectContact:outsider')}</option>}</Translation>
                                    }

                                </select>
                            </div>
                            
                            {/* //เงื่อนไข SENA */}
                            {this.props.accountType === 'invoice' && this.state.select_value === 'residential' &&
                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on &&
                                <div className="btn-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="preinvoiceCheck" checked={this.state.preinvoice}
                                        onChange={this.toggle_checkbox} name="preinvoice" />
                                    <label className="custom-control-label" htmlFor="preinvoiceCheck">
                                        <Translation>{t => t('selectContact:setting_preinvoice_sena')}</Translation>
                                        </label>
                                </div>
                            }

                            {/* //เงื่อนไข ลูกค้าทั่วไป */}
                            {this.props.accountType === 'invoice' && this.state.select_value === 'residential' &&
                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'developer'])?.menu_on === false &&
                                <div className="btn-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="preinvoiceCheck" checked={this.state.preinvoice}
                                        onChange={this.toggle_checkbox} name="preinvoice" />
                                    <label className="custom-control-label" htmlFor="preinvoiceCheck">
                                        <Translation>{t => t('selectContact:setting_preinvoice')}</Translation>
                                        </label>
                                </div>
                            }

                            {this.props.accountType === 'invoice' && this.state.select_value === 'residential' &&
                                <div className="btn-group custom-control custom-checkbox">
                                    <h5 className="mb-0 mx-4" style={{color: '#F43853'}}><Translation>{t => t('selectContact:announce_setting_preinvoice')}</Translation></h5>
                                </div>
                            }
                        
                        </div>
                        {(this.state.select_value === 'receivable' || this.state.select_value === 'payable') &&
                            <ListContactSupplierPayable query={allContact}
                                selectContact={this.selectContact}
                                contactSelected={this.state.contact}
                                contact_type={this.state.contact_type}
                                payment_type={this.state.payment_type}
                                accountType={this.props.accountType}
                            />
                        }

                        {this.state.select_value === 'unknown' &&
                            <input type="text" className="form-control col-4 mt-5" name="unknown_contact" placeholder="ชื่อ" onChange={this.handleInputChange} />
                        }
                        <div className="row mt-3">
                            <div className="col-8">
                                {this.state.select_value !== 'unknown' &&
                                    <u onClick={this.onContactTypeChange} className="cursor"><Translation>{t => t('selectContact:clear')}</Translation></u>
                                }
                            </div>

                            <div className="col-4 text-right">
                                <div className="btn-group mr-2">
                                    <Link to={this.props.backLink}>
                                        <button type="button" className="btn btn-secondary add">
                                            <Translation>{t => t('selectContact:cancel')}</Translation>
                                        </button>
                                    </Link>
                                </div>

                                <div className="btn-group mr-2">
                                    <Link to={this.props.nextLink}>
                                    {/* <button type="button" className="btn btn-primary add" disabled={ !(this.state.contact.length > 0 || this.state.select_value === "unknown" || this.state.preinvoice)}> */}
                                    {
                                    this.state.select_value === "unknown"  && 
                                        <button type="button" className="btn btn-primary add" disabled={ !(this.state.contact.length > 0 || this.state.select_value === "unknown" || this.state.preinvoice)}>
                                            <Translation>{t => t('selectContact:next')}</Translation>
                                        </button>
                                    }
                                     <button type="button" className="btn btn-primary add" disabled={ !(this.state.contact.length > 0)}>
                                            <Translation>{t => t('selectContact:next')}</Translation>
                                    </button>
                                    </Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SelectContactPayable;