import React from 'react';
import ComponentPagination from "../../libs/componentPagination";
import {Link} from "react-router-dom";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import Pagination from "../../libs/newPagination";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import "./style/guaranteeMoneyReceived.scss"
import getNameResidential from "../../libs/getNameResidential";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash";
import { Translation } from 'react-i18next';
import i18next from 'i18next'
import Loading from "../../libs/loading"

const query = graphql`
    query guaranteeMoneyReceivedListTableQuery($status: String, $first: Int, $last: Int, $start_date: DateTime, $end_date: DateTime, $search: String){
        allGuaranteeMoneyReceived(status:$status, first: $first, last: $last,search:$search, startDate:$start_date, endDate:$end_date){
            totalCount
            edges{
                node{

                    id
                    docNumber
                    amount


                    firstName
                    lastName
                    payGroup
                    name
                    taxIdNumber
                    email
                    phone
                    note

                    contact{
                        id
                        name
                        firstName
                        lastName
                        typeOfContact
                        refNumber
                        registeredName
                        registeredAddress
                        registeredCountry
                        registeredProvince
                        registeredCity
                        registeredDistrict
                        registeredPostalCode
                    }
                    issuedDate
                    status
                    creator
                    description
                    updated
                    voidRemark
                }
            }
        }
    }
`;

class GuaranteeMoneyReceivedListTable extends ComponentPagination {

    getColorAndText(object) {
        let text = '';
        let color = 'text-center';
        switch (object.node.status) {
            case 'IN_PROCESS':
                text = 'Wating to clear';
                break;
            case 'COMPLETED':
                text = 'Make a return';
                break;
            case 'RETURN_FORFEIT':
                text = 'Partial seizure/return';
                break;
            case 'FORFEIT_ALL':
                text = 'Seizure';
                break;
            default:
                text = 'List of cancellation';
                color = 'text-center text-danger';
        }
        return <Translation>{t=><td className={color}>{t(`guarantee_money_received:${text}`)}</td>}</Translation>
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                variables={{
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status,
                    start_date:this.props.start_date,
                    end_date:this.props.end_date,
                    search:this.props.search
                }}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                
                        return <React.Fragment>
                            <div className="table-responsive fade-up">
                                <table className="table table-hover mt-2">
                                    <thead className="thead-light">
                                    <Translation>
                                        {t=>
                                        <tr>
                                            <th className="text-center">{t("guarantee_money_received:No.")}</th>
                                            <th className="text-center">{t("guarantee_money_received:Date of issue")}</th>
                                            <th className="text-center">{t("guarantee_money_received:Code")}</th>
                                            <th>{t("guarantee_money_received:Room No.")}</th>
                                            <th>{t("guarantee_money_received:Name")}</th>
                                            <th>{t("guarantee_money_received:Detail")}</th>
                                            <th className="text-right">{t("guarantee_money_received:Amount")}</th>
                                            {this.props.status === 'void' && <th className="text-center">{t("guarantee_money_received:Cancellation date")}</th>}
    
                                            {this.props.status === "" && <th className="text-center">{t("guarantee_money_received:Staus")}</th>}
                                            <th className="text-center">{t("guarantee_money_received:By")}</th>
                                            {this.props.status === 'void' && <th className="text-center">{t("guarantee_money_received:Reason for cancellation")}</th>}
                                            {(this.props.status === 'in_process' ||this.props.status === '') &&<th width="200"/>}
    
    
                                        </tr>}
                                    </Translation>
                                    </thead>
                                    <tbody>
                                    {props.allGuaranteeMoneyReceived.edges.map((guarantee_money) => (
                                        
                                        <tr key={guarantee_money.node.id}>
                                            <td className="text-center">
                                                {guarantee_money.node.status === 'VOID' ?
                                                    guarantee_money.node.docNumber
                                                    :
                                                    <Link
                                                        //to={"/accounting/guarantee-money-received/view/" + guarantee_money.node.id}
                                                        to={{
                                                            pathname:"/accounting/guarantee-money-received/view/" + guarantee_money.node.id,
                                                            state:guarantee_money.node.contact
                                                        }} 
                                                        >
                                                        {guarantee_money.node.docNumber}
                                                    </Link>
                                                     
                                                }
                                            </td>
                                            <td className="text-center">{format(guarantee_money.node.issuedDate, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                            <td className="text-center">{guarantee_money.node.contact.refNumber}</td>
                                            <td>{guarantee_money.node.contact.typeOfContact === 'RESIDENTIAL' ? guarantee_money.node.name : '-'}</td>
                                            <td className='colorUse notKanit'>
                                                {guarantee_money.node.contact.typeOfContact === 'RESIDENTIAL' ? 
                                            guarantee_money.node.firstName &&  guarantee_money.node.firstName !== '' ? getNameResidential(guarantee_money.node.firstName, guarantee_money.node.lastName) : guarantee_money.node.name : 
                                            guarantee_money.node.contact.name || guarantee_money.node.contact.firstName + guarantee_money.node.contact.lastName}{" "}

                                            {
                                                guarantee_money?.node?.payGroup === "AGENT" || guarantee_money?.node?.payGroup === "RENT" ?
                                                <label className='text-red'>({i18next.t("AgentRole:" + guarantee_money.node.payGroup)})</label>
                                                :
                                                ""
                                            }


                                            </td>
                                            <td>{guarantee_money.node.description} </td>
                                            <td className="text-right">{numberWithComma(guarantee_money.node.amount)}</td>
                                            {this.props.status === "" &&
                                                <React.Fragment>
                                                    {this.getColorAndText(guarantee_money)}
                                                </React.Fragment>
                                            }
                                            {this.props.status === 'void' && <td className ="text-center"> {format(guarantee_money.node.updated,'DD/MM/YYYY',{locale: thLocale})} </td>}
                                            <td className="text-center">{guarantee_money.node.creator}</td>
                                            {this.props.status === 'void' && <td> {guarantee_money.node.voidRemark}</td>}

                                            {(this.props.status === 'in_process' || this.props.status === '') &&
                                            <td>
                                                {guarantee_money.node.status === 'IN_PROCESS' &&
                                                <Link
                                                    to={"/accounting/guarantee-money-received/form/clear/" + guarantee_money.node.id}
                                                    >
                                                    <Translation>
                                                        {t=>
                                                        <button type="button" disabled={_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_guarantee_receive_edit'}) ? false: "disabled"}
                                                                className="btn btn-primary add button-clear">
                                                            {t("guarantee_money_received:Clear guarantee money received")}
                                                        </button>}
                                                    </Translation>
                                                </Link>
                                                }
                                            </td>}
                                        </tr>
                                    ))}

                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage}
                                            first={this.state.first}
                                            last={this.state.last}
                                            totalCount={props.allGuaranteeMoneyReceived.totalCount}/>
                            </div>
                        </React.Fragment>

                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default GuaranteeMoneyReceivedListTable;
