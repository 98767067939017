import { Modal} from 'react-bootstrap';
import React , {useState} from 'react'
import {Translation} from "react-i18next"
import "../../developer.scss"
import _ from 'lodash'

function ModalResidential(props){
    const [show, setShow] = useState(false);
    const [contact] = useState(props.contact)

    const handleShow = () => {
        setShow(true)
    }

    const handelClose = () => {
        setShow(false)
    }

    return(
        <Translation>
            {t => 
                <React.Fragment>
                    <div className="input-group-append">
                        <button className="btn form-control" type="button" 
                        style={{borderColor : '#BFBFBF', borderLeft:'none'}}
                        >
                            <span className="cursor text-blue" onClick={handleShow}>ดูข้อมูล</span>
                        </button>
                    </div>
                    <Modal show={show} onHide={handelClose}>
                        <Modal.Header style={{borderBottom:'none'}} closeButton>
                            <Modal.Title>
                            <h4 className="modal-title">รายชื่อลูกค้า</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                            <table className="table table-hover">
                                <thead className="grey-head">
                                    <tr>
                                        <th width={100}>รหัส</th>
                                        <th width={100}>เลขห้อง</th>
                                        <th width={200}>ชื่อ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.sortBy(contact,'unitStatusList.refNumber').reverse().map((contact,index) => {
                                        return (
                                            <tr key={contact.unitStatusList.residentialID}>
                                                <td>{contact.unitStatusList.refNumber}</td>
                                                <td>{contact.unitStatusList.roomNo}</td>
                                                <td>
                                                    {contact.unitStatusList.ownershipName.map((name,index_name) => {
                                                        return <div key={index_name}><span>{name}</span><br/></div>
                                                    })}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            }
        </Translation>

    );


}
export default ModalResidential;