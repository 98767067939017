import i18n from "i18next";
import React, { Component } from 'react';
import { Translation } from "react-i18next";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation, QueryRenderer } from "react-relay";

const query = graphql`
    query switchLanguagesSwitchQuery {
        userConfig {
            id languages
        }
    }
`;

const mutation = graphql`
    mutation switchLanguagesMutation($input: UpdateUserConfigInput!) {
        UpdateUserConfig(input: $input) {
            newUserConfig {
                id languages
            }
        }
    }
`;

class switchLanguages extends Component {
    render() {
        return (

            <QueryRenderer
                cacheConfig={{ use_cache: true }}
                environment={environment}
                query={query}
                render={({ error, props }) => {
                    if (props) {
                        i18n.changeLanguage(props?.userConfig?.languages);
                        return (
                            <Translation>
                                {
                                    t =>
                                        <a className={this.props.isLawlang ? 'dropdown-item':'law-lang'} href="/" onClick={(e) => {
                                            e.preventDefault();
                                            let languages = "";
                                            if (props.userConfig.languages === "th") {
                                                languages = "en";
                                            } else {
                                                languages = "th";
                                            }
                                            let variables = {
                                                input: { languages: languages }
                                            };
                                            commitMutation(environment, {
                                                mutation,
                                                variables,
                                                onCompleted: (response, errors) => {
                                                    localStorage.setItem("language", languages)
                                                },
                                                onError: error => {
                                                    alert('server error')
                                                },
                                                optimisticUpdater: (store) => {
                                                    store.get(props.userConfig.id).setValue(languages, 'languages');
                                                    i18n.changeLanguage(languages);
                                                }
                                            });
                                        }}>
                                            <img src={process.env.PUBLIC_URL + '/images/header/lang.png'} alt="manual"/>&nbsp;&nbsp;{t('headerMenu:change language')}
                                            <img src={process.env.PUBLIC_URL + '/images/flags/' + t('custom:flag')}
                                                alt="flag" className="flag" />
                                        </a>
                                }
                            </Translation>
                        )
                    }
                }}
            />

        );
    }
}

export default switchLanguages;
