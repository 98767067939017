/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type surveyDetailListQueryVariables = {|
  surveyId: string
|};
export type surveyDetailListQueryResponse = {|
  +survey: ?{|
    +id: string,
    +title: string,
  |},
  +getResidentialVoteBySurvey: ?{|
    +surveyId: ?string,
    +countVoted: ?string,
    +countAll: ?string,
  |},
|};
export type surveyDetailListQuery = {|
  variables: surveyDetailListQueryVariables,
  response: surveyDetailListQueryResponse,
|};
*/


/*
query surveyDetailListQuery(
  $surveyId: ID!
) {
  survey(id: $surveyId) {
    id
    title
  }
  getResidentialVoteBySurvey(surveyId: $surveyId) {
    surveyId
    countVoted
    countAll
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "surveyId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "surveyId"
      }
    ],
    "concreteType": "SurveyNode",
    "kind": "LinkedField",
    "name": "survey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "surveyId",
        "variableName": "surveyId"
      }
    ],
    "concreteType": "SurveyResidentialVote",
    "kind": "LinkedField",
    "name": "getResidentialVoteBySurvey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "surveyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countVoted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countAll",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "surveyDetailListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "surveyDetailListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0e27465596cf04805c4c47a84317cdbb",
    "id": null,
    "metadata": {},
    "name": "surveyDetailListQuery",
    "operationKind": "query",
    "text": "query surveyDetailListQuery(\n  $surveyId: ID!\n) {\n  survey(id: $surveyId) {\n    id\n    title\n  }\n  getResidentialVoteBySurvey(surveyId: $surveyId) {\n    surveyId\n    countVoted\n    countAll\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2293976f2322738b435c3bf2a0775111';

module.exports = node;
