import React, {Component} from 'react';
import { Translation } from 'react-i18next';

class PermissionAccountingForJob extends Component {
    constructor(props){
        super(props);
        //ถ้ามีชื่อใน delete create -> แสดงว่าติ้กไม่ได้ ปุ่มติ้ก disable
        //ถ้ามีชื่อใน edit approve print -> แสดงว่าสามารถติ้กไม่ติ้กได้ 
        this.state = {
            edit_input: ['accounting_invoice', 'accounting_guarantee_receive', 'accounting_unknown_receive',
                'accounting_water_meter', 'accounting_electric_meter', 'accounting_petty_cash', 'contact_residential',
                'contact_residential', 'contact_receivable', 'contact_payable', 'accounting_product', 'accounting_service',
                'accounting_expense', 'accounting_bank', 'accounting_cheque_deposit',
                'accounting_cheque', 'accounting_chart_of_account', 'accounting_asset', 'daily_journal_payment',
                'daily_journal_receive', 'daily_journal_general','accounting_settingautomaticdebit','accounting_collectionletters','accounting_settingdebsfreecertification','accounting_productstock',
                'accounting_debfreecertificationrequest','accounting_budget','accounting_bankunit','accounting_procurement_form','accounting_contract_expense',
                'asset_settingasset','preinvoicegroup','accounting_accountrecord','accounting_chartofaccount'
            ],
            delete_input: ['accounting_fine', 'accounting_cross_billing', 'contact_residential'
                , 'contact_receivable', 'contact_payable', 'accounting_bank', 'accounting_tax', 'accounting_withholdingTax',
                'accounting_withheldTax', 'daily_journal_purchase', 'daily_journal_sales', 'daily_journal_payment',
                'daily_journal_receive', 'daily_journal_general', 'report_receivable', 'report_payable', 'report_asset',
                'accounting_chart_of_account', 'accounting_general_ledger', 'accounting_trial_balance',
                'accounting_balance_sheet', 'accounting_accrued_revenue_expenditure', 'accounting_cash_flow_statement', 'accounting_summary_income_expense', 'accounting_bank_transfer',
                'accounting_settingautomaticdebit','accounting_settingdebsfreecertification','accounting_debfreecertificationrequest','accounting_budget',
                'accounting_work_sheet','accounting_bankunit','accounting_producttransaction' , 'accounting_numkey','accounting_procurement_form','accounting_purchase_orderes',
                'asset_settingasset','preinvoicegroup','accounting_accountrecord','accounting_chartofaccount','report_financial','accounting_chequecontrol','slip_verify_log'
            ],
            create_input: ['contact_residential', 'accounting_tax', 'accounting_withholdingTax', 'accounting_withheldTax',
                'accounting_general_ledger', 'accounting_trial_balance', 'accounting_summary_income_expense',
                'accounting_balance_sheet', 'accounting_accrued_revenue_expenditure',
                'accounting_cash_flow_statement','accounting_water_meter','accounting_electric_meter','daily_journal_purchase', 'daily_journal_sales',
                'report_receivable',
                'report_payable',
                'report_asset','accounting_settingautomaticdebit','accounting_settingdebsfreecertification','accounting_work_sheet','accounting_asset',
                'accounting_producttransaction' , 'accounting_numkey','accounting_contract_expense','asset_settingasset','accounting_accountrecord','report_financial','slip_verify_log'
            ],
            approve_input:['accounting_invoice','accounting_collectionletters','accounting_debfreecertificationrequest','accounting_budget',
                'accounting_procurement_form','accounting_purchase_orderes','accounting_contract_revenue','accounting_contract_expense'
            ],
            print_input:['accounting_invoice',
            'accounting_receive',
            'accounting_credit_note',
            'accounting_guarantee_receive',
            'accounting_other_receiver',
            'accounting_unknown_receive',
            'accounting_water_meter',
            'accounting_electric_meter',
            'accounting_fine',
            'accounting_purchase',
            'accounting_pay',
            'accounting_accept_credit_note',
            'accounting_prepaid_deposit',
            'accounting_petty_cash',
            'accounting_other_expense',
            'accounting_withholdingTax',
            'daily_journal_purchase',
            'daily_journal_sales',
            'daily_journal_payment',
            'daily_journal_receive',
            'daily_journal_general',
            'accounting_chart_of_account',
            'accounting_general_ledger',
            'accounting_trial_balance',
            'accounting_balance_sheet',
            'accounting_accrued_revenue_expenditure',
            'accounting_summary_income_expense',
            'accounting_cash_flow_statement',
            'accounting_bank_transfer',
            'accounting_receipt_deposit',
            'accounting_product',
            'report_receivable',
            'report_payable',
            'report_asset',
            'contact_survey',
            'contact_residential',
            'accounting_cheque_deposit',
            'accounting_cheque',
            'accounting_collectionletters','accounting_debfreecertificationrequest',
            'accounting_debfreecertification',
            'accounting_work_sheet',
            'accounting_budget', 'accounting_productstock','accounting_producttransaction' , 'accounting_numkey',
            'accounting_procurement_form','accounting_purchase_orderes','contact_receivable','contact_payable','accounting_accountrecord','accounting_chartofaccount',
            'report_financial',
            'claim'
        ],
        _input:['accounting_cross_billing'],

        list_not_disable: this.props.account_list_not_disable,
        };
    }

    render() {
        return (
            <div className="row">
                <div className="col-8">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <tbody>
                            {this.props.account_list.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Translation>
                                            {t=>
                                            <tr className="table-active">
                                                <td><h5>{t(`set_permission:${value.group_name}`)}</h5></td>
                                                <th className="text-center">{t("set_permission:All")}</th>
                                                <th className="text-center">{t("set_permission:View")}</th>
                                                <th className="text-center">{t("set_permission:Create")}</th>
                                                <th className="text-center">{t("set_permission:Edit")}</th>
                                                <th className="text-center">{t("set_permission:Approve")}</th>
                                                <th className="text-center">{t("set_permission:Print")}</th>
                                                <th className="text-center">{t("set_permission:Remove")}</th>
                                                
                                                {value.group_name.includes('Procurement and Purchase')&&
                                                <th className="text-center" style={{width:'150px'}} >{t("set_permission:Approval limit")}</th>}
                                            </tr>}
                                        </Translation>
                                        {value.child.map((child, c_index) => {
                                            return (
                                                <tr key={'child-'+c_index}>
                                                    <Translation>{t=><td>{t(`set_permission:${child.name}`)}</td>}</Translation>
                                                    <td className="text-center">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                id={child.key+'_all'}
                                                                checked={this.props.checkAllInput(child.key,this.state) || this.props.checkUserJuristicAdmin}
                                                                onChange={(e)=> this.props.clickCheckAllList(child.key,this.state)}
                                                            />
                                                            <label className="custom-control-label"
                                                                htmlFor={child.key+'_all'}/>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                   id={child.key}
                                                                   checked={this.props.permissions[child.key] || this.props.checkUserJuristicAdmin}
                                                                   disabled={child.key === 'accounting_cross_billing'}
                                                                   onChange={(e)=> this.props.updatePermissionCheck(child.key,e)}
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor={child.key}/>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                   id={child.key + '_create'}
                                                                   disabled={this.state.create_input.includes(child.key) ? 'disabled': false}
                                                                   checked={this.props.permissions[child.key + '_create'] || this.props.checkUserJuristicAdmin}
                                                                   onChange={(e) => this.props.updatePermissionCheck(child.key + '_create', e)}
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor={child.key+'_create'}/>
                                                        </div>
                                                    </td>

                                                    <td className="text-center">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                   id={child.key + '_edit'}
                                                                   disabled={this.state.edit_input.includes(child.key) ? false : 'disabled'}
                                                                   checked={this.props.permissions[child.key + '_edit'] || this.props.checkUserJuristicAdmin}
                                                                   onChange={(e) => this.props.updatePermissionCheck(child.key + '_edit', e)}
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor={child.key+'_edit'}/>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                   id={child.key + '_approve'}
                                                                   disabled={this.state.approve_input.includes(child.key) ? false: "disabled"}
                                                                   checked={this.props.permissions[child.key + '_approve'] || this.props.checkUserJuristicAdmin}
                                                                   onChange={(e) => this.props.updatePermissionCheck(child.key + '_approve', e)}
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor={child.key+'_approve'}/>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                   id={child.key + '_print'}
                                                                   disabled={this.state.print_input.includes(child.key) ? false: "disabled"}
                                                                   checked={this.props.permissions[child.key + '_print'] || this.props.checkUserJuristicAdmin}
                                                                   onChange={(e) => this.props.updatePermissionCheck(child.key + '_print', e)}
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor={child.key+'_print'}/>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input"
                                                                   id={child.key + '_delete'}
                                                                   checked={this.props.permissions[child.key + '_delete'] || this.props.checkUserJuristicAdmin}
                                                                   disabled={this.state.delete_input.includes(child.key) ?  'disabled': false}
                                                                   onChange={(e) => this.props.updatePermissionCheck(child.key + '_delete', e)}
                                                            />
                                                            <label className="custom-control-label"
                                                                   htmlFor={child.key+'_delete'}/>
                                                        </div>
                                                    </td>
                                                    {value.group_name.includes('Procurement and Purchase')&&
                                                    <td className="text-center">
                                                        <div className="custom-control custom-checkbox">
                                                            <input 
                                                             type="number" step="0.01"
                                                             className="form-control text-right"
                                                             id={child.key + '_limit'}
                                                             disabled={!this.props.permissions[child.key + '_approve']}
                                                             onChange={(e) => this.props.updatePermissionLimit(child.key + '_limit', e)}
                                                             value={this.props.permissions[child.key + '_limit']}
                                                            />
                                                        </div>
                                                    </td>}
                                                </tr>
                                            )
                                        })}


                                    </React.Fragment>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export default PermissionAccountingForJob;