import React, { Component } from 'react'
import _ from "lodash"
import numberWithComma from "../../../libs/numberWithComma"
import "../style/manageRequest.scss"
import format from 'date-fns/format'
import i18next from 'i18next'
import jwtDecode from 'jwt-decode'
export default class formSummaryAndAdditional extends Component {

  constructor(props) {
    super(props)

    this.state = {
      sumItemsTotal: "",
      sumVat: "",
      sumTotalAmount: ""
    }
  }

  componentDidMount() {
    this.calSummary()
  }

  componentDidUpdate(prevProps) {
    if (this.props.purchaseRequisition.procurementFormList !== prevProps.purchaseRequisition.procurementFormList || this.props.vat !== prevProps.vat) {
      this.calSummary()
    }
  }

  calSummary() {
    let sumItemsTotal = 0
    let sumVat = 0
    let sumTotalAmount = 0
    let chkSite = jwtDecode(localStorage.getItem("token"))
    
    _.forEach(this.props.purchaseRequisition.procurementFormList, (procurement) => {
     //let totalItem = procurement.pre_tax_amount;
      let totalItem 
      if(procurement?.pre_tax_amount){
        totalItem = procurement.pre_tax_amount;
      }else{
        totalItem = procurement.price * procurement.unit_items;
      }
     
      sumItemsTotal += totalItem
      
      if (this.props.vat) {
          sumVat += totalItem * this.props.vat / 100
          sumVat = Math.round((sumVat+ Number.EPSILON) * 100) / 100; 
      }

    })

    // 24-R89.(SENA)รบกวนแก้ไขยอดในเอกสาร PRF เกิดเพราะว่าเขาตัดทศนิยมที่ 2 ตำแหน่งที่ระบบเรา support ที่ 3 ตำแหน่ง จึงใช้เฉพาะโครงการนี้
    if (chkSite.site?.includes("victorymanagement") && localStorage.getItem('site_id') === '25'){
      sumVat = this.props.purchaseRequisition.total - sumItemsTotal
    }

    sumTotalAmount = sumItemsTotal + sumVat
    
    this.setState({
      sumItemsTotal: parseFloat(sumItemsTotal).toFixed(2),
      sumVat: parseFloat(sumVat).toFixed(2),
      sumTotalAmount: parseFloat(sumTotalAmount).toFixed(2)
    })
  }


  render() {
    return (
      <div id="formSummaryAndAdditional">
        <h4>{i18next.t("purchaseRequisition:Summary lists and additional information")}</h4>
        <div className="lineBottom" />

        <div className="row mt-5">
          <div className="col-12">
            <h5>{i18next.t("purchaseRequisition:Album")}</h5>
          </div>

          <div className="col-6">
            <div className="row col-12">
              {_.map(this.props.purchaseRequisition.procurementFormImage, (item, index) => {
                if (item.status !== "delete") {
                  return (
                    <div className="mt-5 mr-2 position-relative show" key={index}>
                      <img className="imgAlbum"
                        src={
                          typeof item.node.fileUpload === "string"
                            ? item.node.fileUpload
                            : URL.createObjectURL(item.node.fileUpload)
                        }
                        alt="picture"
                      />

                      {!this.props.disibleInput &&
                        <div className="position-absolute btnDelete show-icon"
                          onClick={() => this.props.handleRemoveImgSelect("purchaseRequisition.procurementFormImage", index)}
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/images/icons/icon_delete.png"}
                            alt="delete"
                            className="position-absolute iconDelete"
                          />
                        </div>
                      }
                    </div>
                  )
                }
              })}
              {!this.props.disibleInput &&
                <div className="mt-5 mr-2">
                  <div className="upload-new-image framePlus">
                    <label htmlFor="uploadNewsImages" className="d-flex align-items-center justify-content-center w-100 h-100">
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/icon_plus.png"}
                        alt="plus-icon"
                        className="plus"
                      />
                      <input
                        type="file"
                        name="image"
                        id="uploadNewsImages"
                        value=""
                        accept="image/*"
                        hidden
                        onChange={this.props.handleInputImage}
                      />
                    </label>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-6">
            <h6>{i18next.t("purchaseRequisition:Reason for Purchase requisition")} </h6>
            <div className="input-group">
              <textarea
                disabled={this.props.disibleInput}
                className={this.props.disibleInput ? "bodyTextArea-disibled" : "bodyTextArea"}
                name="purchaseRequisition.reason"
                value={this.props.purchaseRequisition.reason}
                onChange={this.props.handleChangeInput}
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-6">
            <h6>{i18next.t("purchaseRequisition:Comment from presenter")} </h6>
            <div className="input-group">
              <textarea
                disabled={this.props.disibleInput}
                className={this.props.disibleInput ? "bodyTextArea-disibled" : "bodyTextArea"}
                name="purchaseRequisition.feedback"
                value={this.props.purchaseRequisition.feedback}
                onChange={this.props.handleChangeInput}
              />
            </div>
          </div>

          <div className="col-4">
            <h6>{i18next.t("purchaseRequisition:Presenter name")} </h6>
            <div className="input-group">
              <input type="text"
                disabled={true}
                className="form-control"
                name="purchaseRequisition.presenterName"
                value={this.props.presenterName}
                style={{ height: '36px' }}
              />
            </div>

            <h6>{i18next.t("purchaseRequisition:Postion")} </h6>
            <div className="input-group">
              <input type="text"
                disabled={true}
                className="form-control"
                name="purchaseRequisition.presenterRole"
                value={this.props.presenterRole}
                style={{ height: '36px' }}
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-6">
            <h6>{i18next.t("purchaseRequisition:Note")} </h6>
            <div className="input-group">
              <textarea
                disabled={this.props.disibleInput}
                className={this.props.disibleInput ? "bodyTextArea-disibled" : "bodyTextArea"}
                name="purchaseRequisition.remark"
                value={this.props.purchaseRequisition.remark}
                onChange={this.props.handleChangeInput}
              />
            </div>
            <br />
            <div style={{ backgroundColor: "#ededed", padding: "20px 10px", border: "1px solid lightgray" }}>
              <h4><img src={process.env.PUBLIC_URL + '/images/icons/clock.png'} width={20} alt="clock" />{i18next.t("purchaseRequisition:Document history")}  </h4>
              <hr />
              <div className="input-group">
                {i18next.t("purchaseRequisition:Create by")}  {this.props.presenterName} | {format(this.props.purchaseRequisition.added || new Date(), "DD/MM/YYYY ● HH:mm")}
              </div>
            </div>

          </div>

          <div className="col-6">
            <div className="frameSummary">
              <div className="textSummary">
                <h5 className="headItems">{i18next.t("purchaseRequisition:Amount")} </h5>
                <p className="detailItems">{numberWithComma(this.state.sumItemsTotal)}</p>
              </div>
              <div className="textSummary">
                <h5 className="headItems">{i18next.t("purchaseRequisition:VAT")} </h5>
                <p className="detailItems">{numberWithComma(this.state.sumVat) || '0.00'}</p>
              </div>
              <div className="lineBetweenSummary" />
              <div className="textSummary">
                <h5 className="headSummary">{i18next.t("purchaseRequisition:Total Amount")}</h5>
                <p className="detailSummary">{numberWithComma(this.state.sumTotalAmount)}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
