/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type payPaymentChannelFormInPayQueryVariables = {|
  makePayment?: ?boolean,
  receivePayment?: ?boolean,
  accountType?: ?string,
|};
export type payPaymentChannelFormInPayQueryResponse = {|
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
          +hasCheque: boolean,
          +branch: string,
        |}
      |}>,
      +totalCount: ?number,
    |},
    +allBankAccountCheque: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
          +hasCheque: boolean,
          +branch: string,
        |}
      |}>,
      +totalCount: ?number,
    |},
  |},
  +allSetPettyCash: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +description: ?string,
        +withdrawer: ?string,
        +withdrawAmount: number,
        +totalPettyCashRecord: ?number,
      |}
    |}>,
  |},
|};
export type payPaymentChannelFormInPayQuery = {|
  variables: payPaymentChannelFormInPayQueryVariables,
  response: payPaymentChannelFormInPayQueryResponse,
|};
*/


/*
query payPaymentChannelFormInPayQuery(
  $makePayment: Boolean
  $receivePayment: Boolean
  $accountType: String
) {
  bankAccountViewer {
    allBankAccount(status: true, accountType_In: $accountType, makePayment: $makePayment, receivePayment: $receivePayment) {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
          hasCheque
          branch
        }
      }
      totalCount
    }
    allBankAccountCheque(status: true, accountType_In: $accountType, makePayment: $makePayment, receivePayment: $receivePayment) {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
          hasCheque
          branch
        }
      }
      totalCount
    }
    id
  }
  allSetPettyCash(status: "active") {
    totalCount
    edges {
      node {
        id
        docNumber
        description
        withdrawer
        withdrawAmount
        totalPettyCashRecord
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "makePayment"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "receivePayment"
},
v3 = [
  {
    "kind": "Variable",
    "name": "accountType_In",
    "variableName": "accountType"
  },
  {
    "kind": "Variable",
    "name": "makePayment",
    "variableName": "makePayment"
  },
  {
    "kind": "Variable",
    "name": "receivePayment",
    "variableName": "receivePayment"
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": true
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BankAccountNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receivePayment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "makePayment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bankName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasCheque",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "branch",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": (v3/*: any*/),
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": (v3/*: any*/),
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccountCheque",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "status",
      "value": "active"
    }
  ],
  "concreteType": "SetPettyCashNodeConnection",
  "kind": "LinkedField",
  "name": "allSetPettyCash",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SetPettyCashNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SetPettyCashNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "withdrawer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "withdrawAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPettyCashRecord",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allSetPettyCash(status:\"active\")"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "payPaymentChannelFormInPayQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "payPaymentChannelFormInPayQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "bb7488d2ea96ea3782280cec92443874",
    "id": null,
    "metadata": {},
    "name": "payPaymentChannelFormInPayQuery",
    "operationKind": "query",
    "text": "query payPaymentChannelFormInPayQuery(\n  $makePayment: Boolean\n  $receivePayment: Boolean\n  $accountType: String\n) {\n  bankAccountViewer {\n    allBankAccount(status: true, accountType_In: $accountType, makePayment: $makePayment, receivePayment: $receivePayment) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n          hasCheque\n          branch\n        }\n      }\n      totalCount\n    }\n    allBankAccountCheque(status: true, accountType_In: $accountType, makePayment: $makePayment, receivePayment: $receivePayment) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n          hasCheque\n          branch\n        }\n      }\n      totalCount\n    }\n    id\n  }\n  allSetPettyCash(status: \"active\") {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        description\n        withdrawer\n        withdrawAmount\n        totalPettyCashRecord\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e0767be5a1ca568cff6683e71d04434';

module.exports = node;
