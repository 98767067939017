import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import './styles/project.scss'
import getDateTime from '../../libs/getDateTime';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import _ from "lodash"
import Swal from 'sweetalert2';
import "../projectDashboard.scss"
import i18n from 'i18next';
import { fetchQuery } from 'relay-runtime';
import upload from '../../libs/upload';
import uploadPublic from '../../libs/uploadPublic';
//componentProjectList
import BasicInformation from './componentProjectList/basicInformation';
import DetailsPhase from './componentProjectList/detailsPhase';
import RegistrationDate from './componentProjectList/registrationDate';
import ContactInfomation from './componentProjectList/contactInfomation';
import PictureProjectInformation from './componentProjectList/pictureProjectInformation';
import { format } from 'date-fns';
import createProject from './graphQL/createProject';
import PlanProject from './componentProjectList/planProject';
import UnitsPlanProject from './componentProjectList/unitsPlanProject';
import i18next from 'i18next';

const query = graphql`
    query projectListQuery{
        selfProject{
            id
            name
            nameTh
            nameEn
            address
            addressEn
            logo
            space
            nameDeveloper
            lat
            long
            numberOfBuilding
            numberOfFloor
            numberOfRoom
            description
            juristicContactNumber
            securityContactNumber
            technicianContactNumber
            registrationDate
            bringForwardStartDate
            typeOfProject
            keyProjectQr
            registrationNumber
            haveVat
            taxIdNumber
            typeOfCompany
            establishmentStatus
            juristicContactNumber
            securityContactNumber
            technicianContactNumber
            space
            usableSpace
            parkingSpace
            parkingSlot
            openTime
            endTime
            image {
                edges {
                    node {
                        id
                        source
                    }
                }
            }
            floorPlan {
                edges {
                    node {
                        id
                        source
                        title
                    }
                }
            }
            fileBuilding {
                edges {
                    node {
                        id
                        nameFile
                        fileUpload
                    }
                }
            }

            projectBuilding {
                edges {
                    node {
                        id
                        name
                        projectBuildingUnit {
                            edges {
                                node {
                                    id
                                    building
                                    numberOfFloor
                                    numberOfRoom
                                    }
                                }
                            }
                    }
                }
            }

           

            insideBuilding {
                edges {
                    node {
                        id
                        fileUpload
                    }
                }
            }

            unitBuilding {
                edges {
                    node {
                        id
                        fileUpload
                    }
                }
            }
        }
        allDeveloperProject{
            edges{
                node{
                    id
                    transferOwnership
                }
            }
        }

    }
`;
class ProjectList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projectInfo: {},
            editProjectInfo: false,
            loading: false,
            redirectToList: false,
            settingDeveloper: false, //ใช้ส่วนผู้พัฒนา มาจากการตั้งค่าส่วนผู้พัฒนา ยังโอนกรรมสิทธิ์ไม่ครบ
            phase: {
                node:
                {
                    id: '',
                    name: '',
                    status: '',
                    projectBuildingUnit: { edges: [] }
                }
            },
            buildingUnit: {
                node: {
                    id: '',
                    building: '',
                    numberOfFloor: '',
                    numberOfRoom: '',
                    status: '',
                }
            },
            uploadLogo: '',
            uploadImage: '',
            uploadUnit: [],
            uploadPlan: [],
            uploadFloor: []
        }

        this.getProjectLife = this.getProjectLife.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    async componentWillMount() {
        await fetchQuery(environment, query).then(data => {
            this.setState({
                projectInfo: data.selfProject,
                settingDeveloper: (data.allDeveloperProject.edges && data.allDeveloperProject.edges.length > 0) ?
                    data.allDeveloperProject.edges[0].node.transferOwnership === 'NO' ? true : false : false
            }, () => this.getProjectLife())
        });
    }

    fetchData = () => {
        this.setState({ redirectToList: true, editProjectInfo: false })
        fetchQuery(environment, query).then(data => {
            this.setState({
                projectInfo: data.selfProject,
                settingDeveloper: (data.allDeveloperProject.edges && data.allDeveloperProject.edges.length > 0) ?
                    data.allDeveloperProject.edges[0].node.transferOwnership === 'NO' ? true : false : false
            }, () => this.getProjectLife())
        });
    }

    getProjectLife() {
        if (this.state.projectInfo.registrationDate && this.state.projectInfo.registrationDate !== '') {
            let diffDate = new Date(new Date() - getDateTime(this.state.projectInfo.registrationDate))
            let year = (diffDate.toISOString().slice(0, 4) - 1970)
            let month = diffDate.getMonth()
            let day = (diffDate.getDate() - 1)
            let projectLife = (year > 0 ? (year + " " + i18n.t('project:Year') + " ") : '') + (month > 0 ? (month + " " + i18n.t('project:Month') + " ") : '') + (day > 0 ? (day + " " + i18n.t('project:Day') + " ") : '')
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), 'projectInfo.projectLife', projectLife);
            });
        } else {
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), 'projectInfo.registrationDate', '');
            });
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), 'projectInfo.projectLife', '-');
            });
        }

    }


    handleChangInput = (e) => {
        let event = _.cloneDeep(e);
        let value = event.target.value;
        if (_.endsWith(event.target.name, 'registrationDate')) {
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), event.target.name, value);
            }, () => this.getProjectLife());
        } else if (event.target.name === 'projectInfo.haveVat') {
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), event.target.name, !this.state.projectInfo.haveVat);
            });
        } else if (event.target.name === 'projectInfo.upload_image_logo') {
            this.setState({
                uploadLogo: value
            })
        } else if (event.target.name === 'projectInfo.upload_image') {
            this.setState({
                uploadImage: value
            })
        }
        else {
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), event.target.name, value);
            });
        }


    }

    appendRow = (name, index) => {
        if (name === "projectInfo.projectBuilding.edges") {
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), name, [...this.state.projectInfo.projectBuilding.edges, { ...this.state.phase }]);
            });
        }
        else {
            this.setState((prevState) => {
                return _.set(_.cloneDeep(prevState), name, [...this.state.projectInfo.projectBuilding.edges[index].node.projectBuildingUnit.edges, { ...this.state.buildingUnit }]);
            });
        }
    }



    appendFileInputRow = (fileInput) => {
        let file = {
            node: {
                id: '', nameFile: fileInput.node.nameFile,
                fileUpload: fileInput.node.fileUpload, status: ''
            }
        }
        let newFile = [...this.state.projectInfo.fileBuilding.edges, { ...file }]
        
        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), "projectInfo.fileBuilding.edges", newFile);
        });

    }

    onDelete = (data, id, name) => {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                let getData = _.cloneDeep(data)
                let index = _.findIndex(_.cloneDeep(getData), { node: { id: id } })
                if (index === -1) {
                    getData.splice(id, 1)
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name,
                            getData
                        );
                    });
                } else {
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            name + '[' + index + '].node.status',
                            'delete'
                        );
                    });
                }
            }
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/projectDetail/data" + path, file);
            resolve(res);
        });
    };

    callUploadPublic = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await uploadPublic("/projectDetail/data" + path, file);
            resolve(res);
        });
    };

    async onSubmit() {
        this.setState({ loading: false });
        let variables = {
            input: {
            }
        }
        let notKeyUpdate = ["keyProjectQr", "registrationDate", "nameDeveloper", "bringForwardStartDate", "id", "name", "logo", "numberOfFloor","image", "floorPlan", "fileBuilding", "projectBuilding", "insideBuilding", "unitBuilding", "projectLife", "openTime", "endTime"]
        for (let [key, value] of Object.entries(this.state.projectInfo)) {             
            if (!_.includes(notKeyUpdate, key)) {
                
                if (key === 'numberOfBuilding' || key === 'numberOfRoom') {
                    variables.input[key] = value !== null ? value !== '' ? value : null : null
                }else {
                    variables.input[key] = value !== null ? value !== '' ? value : '-' : '-'
                }
        
            } else if (key === 'registrationDate') {
                variables.input['registrationDate'] = value === '' ? null : value

            } else if (key === 'image') {
                if (this.state.uploadImage !== '') {
                    await this.callUploadPublic('', this.state.uploadImage).then((data) => {
                        variables.input['projectImage'] = JSON.stringify({
                            projectImage: [{
                                id: value.edges[0].node.id !== '' ? value.edges[0].node.id : '',
                                source: data.key.replace("silverman-storage/public/", ""), status: ''
                            }]
                        })
                    });
                } else {
                    variables.input['projectImage'] = JSON.stringify({ projectImage: [] })
                }
            } else if (key === 'fileBuilding') {
                let newFileBuilding = []
                for (let file of value.edges) {
                    if (file.node.id === '' && file.node.fileUpload !== '') {
                        await this.callUpload('', file.node.fileUpload).then((data) => {
                            newFileBuilding.push({
                                id: file.node.id, nameFile: file.node.nameFile,
                                fileUpload: data.key.replace("silverman-storage/private/", ""), status: file.node.status ? file.node.status : ''
                            })
                        });
                    } else if (file.node.status === 'delete') {
                        newFileBuilding.push({
                            id: file.node.id, nameFile: '',
                            fileUpload: '', status: file.node.status
                        })
                    }
                }
                variables.input['fileBuilding'] = JSON.stringify({ fileBuilding: newFileBuilding })
            } else if (key === 'projectBuilding') {
                let newBuilding = []
                for (let building of value.edges) {
                    let set = []
                    building.node.projectBuildingUnit.edges.length > 0 && building.node.projectBuildingUnit.edges.forEach((unit, index) => {
                        set.push(unit.node)
                        if (!unit.node.status) {
                            _.set(set[index], 'status', '')
                        }
                    })
                    newBuilding.push({
                        id: building.node.id, name: building.node.name,
                        status: building.node.status ? building.node.status : '', set: set
                    })
                }
                variables.input['projectBuilding'] = JSON.stringify({ projectBuilding: newBuilding })
            } else if (key === 'floorPlan') {
                let newFloor = [] 
                if (this.state.uploadFloor.length !== 0) {
                    for (let floor of this.state.uploadFloor) {
                        await this.callUploadPublic('', floor).then((data) => {
                            newFloor.push({
                                id: '',
                                source: data.key.replace("silverman-storage/public/", ""), status: '', title: ''
                            })
                        });
                    }
                }

                
                let data = []
                value.edges.forEach(ele => { 
                    let dataPush =  _.set(ele, "node.source", "")
                    data.push(dataPush) 
                })
                
                for (let floor of data) { 

                    if (floor.node.status === 'delete') { 
                        newFloor.push(floor.node)
                    }
                }
                variables.input['floorPlan'] = JSON.stringify({ floorPlan: newFloor })
            } else if (key === 'insideBuilding') {
                let newPlan = []
                if (this.state.uploadPlan.length !== 0) {
                    for (let plan of this.state.uploadPlan) {
                        await this.callUpload('', plan).then((data) => {
                            newPlan.push({
                                id: '',
                                fileUpload: data.key.replace("silverman-storage/private/", ""),
                                status: ''
                            })
                        });
                    }
                }
                for (let plan of value.edges) {
                    if (plan.node.status && plan.node.status === 'delete') {
                        // newPlan.push(plan.node)
                        newPlan.push({
                            id: plan.node.id,
                            fileUpload:'',
                            status:plan.node.status
                        })
                    }
                }
                variables.input['insideBuilding'] = JSON.stringify({ insideBuilding: newPlan })

            } else if (key === 'unitBuilding') {
                let newUnit = []
                if (this.state.uploadUnit.length !== 0) {
                    for (let unit of this.state.uploadUnit) {
                        await this.callUpload('', unit).then((data) => {
                            newUnit.push({
                                id: '',
                                fileUpload: data.key.replace("silverman-storage/private/", ""),
                                status: ''
                            })
                        });
                    }
                }
                for (let unit of value.edges) {
                    if (unit.node.status && unit.node.status === 'delete') {
                        //  newUnit.push(unit.node)
                        newUnit.push({
                            id: unit.node.id,
                            fileUpload:'',
                            status:unit.node.status
                        })
                    }
                }
                variables.input['unitBuilding'] = JSON.stringify({ unitBuilding: newUnit })
            } else if (key === 'bringForwardStartDate') {
                variables.input['bringForwardStartDate'] = new Date(value)
            } else if (key === 'logo') {
                if (this.state.uploadLogo !== '') {
                    await this.callUploadPublic('', this.state.uploadLogo).then((data) => {
                        variables.input['logo'] = data.key.replace("silverman-storage/public/", "")
                    });
                }
            } else if (key === 'openTime') { 
                value = value ? format(new Date(), "YYYY-MM-DD") + "T" + value : null 
                variables.input['openTime'] = format(value , "HH:mm") 
            } else if (key === 'endTime') {
                value = value ? format(new Date(), "YYYY-MM-DD") + "T" + value : null
                variables.input['endTime'] = format(value , "HH:mm")   
            } 

        }
        createProject(variables, this.onSubmitSuccess, this.onSubmitError)
    }

    onSubmitSuccess = (response) => {
        this.setState({ loading: false });
        if (response) {
            Swal.fire(i18n.t('project:Save successfully!'), '', 'success').then(() => {
                window.location.reload();
            });
        } else {
            Swal.fire(i18n.t('project:Fail to saved!'), '', 'warning');
        }
    }

    onSubmitError = (err) => {
        this.setState({ loading: false });
        Swal.fire('Error!', i18n.t('project:Please try again'), 'warning')
    }

    getUnitPlan = (upload, unit) => {
        let newUnit = [...this.state.projectInfo.unitBuilding.edges, ...unit]
        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), 'projectInfo.unitBuilding.edges', newUnit);
        })
        this.setState({
            uploadUnit: upload
        }, () => this.onSubmit())
    }

    getPlan = (upload, plan) => {
        let newPlan = [...this.state.projectInfo.insideBuilding.edges, ...plan]
        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), 'projectInfo.insideBuilding.edges', newPlan);
        })
        this.setState({
            uploadPlan: upload
        }, () => this.onSubmit())
    }

    getFloor = (upload, floor) => {
        let newFloor = [...this.state.projectInfo.floorPlan.edges, ...floor]
        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), 'projectInfo.floorPlan.edges', newFloor);
        })
        this.setState({
            uploadFloor: upload
        }, () => this.onSubmit())
    }

    onCancel = () => {
        this.setState({ editProjectInfo: false }, () => window.location.reload())
    }

    render() {

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <ProjectTopMenuNavigation mini={true} />
                    <Translation>
                        {t =>
                            <div className="container-fluid box" id="projectDashboard">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/project">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>

                                            <span>{t("PageDetailProjectInfo:DetailProject")}</span>

                                        </h3>
                                    </div>
                                </div>

                                <div className="content-inner fade-up">
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-12 d-flex justify-content-between">
                                            <h4 className="ml-3">{t("PageDetailProjectInfo:InfoGeneral")}</h4>
                                            {!this.state.editProjectInfo &&
                                                <span className="cursor text-primary mr-3" onClick={() => this.setState({ editProjectInfo: true })}>
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/images/icons/edit-blue-color.png'}
                                                        alt="project-info-icon"
                                                    />
                                                    <span className="ml-1">{t('project_detail:Edit')}</span>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-6 mt-3">
                                            <div className="col-12">
                                                <div className="card show h-100">
                                                    <div className="card-body">
                                                        <BasicInformation
                                                            projectInfo={this.state.projectInfo}
                                                            editProjectInfo={this.state.editProjectInfo}
                                                            onChange={this.handleChangInput}
                                                            settingDeveloper={this.state.settingDeveloper}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card show mt-3">
                                                    <div className="card-body">
                                                        <DetailsPhase
                                                            projectInfo={this.state.projectInfo}
                                                            editProjectInfo={this.state.editProjectInfo}
                                                            onChange={this.handleChangInput}
                                                            appendRow={this.appendRow}
                                                            deleteRow={this.onDelete}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card show mt-3">
                                                    <div className="card-body">
                                                        <div className="row mb-3">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <h6 className="mb-3">{t("PageDetailProjectInfo:DetailInfoProject")}</h6>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            {this.state.editProjectInfo
                                                                                ?
                                                                                <textarea className="form-control"
                                                                                    style={{ height: "100px", maxHeight: "100px" }}
                                                                                    name="projectInfo.description"
                                                                                    value={this.state.projectInfo.description}
                                                                                    onChange={this.handleChangInput}
                                                                                />
                                                                                :
                                                                                <p>
                                                                                    {this.state.projectInfo.description}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="col-12 mt-3 mt-md-0 ">
                                                <div className="card show h-100">
                                                    <div className="card-body">
                                                        <span>{t("PageDetailProjectInfo:ImageProject")}</span>
                                                        {this.state.projectInfo.image &&
                                                            this.state.projectInfo.floorPlan &&
                                                            <PictureProjectInformation
                                                                projectInfo={this.state.projectInfo}
                                                                editProjectInfo={this.state.editProjectInfo}
                                                                onChange={this.handleChangInput}
                                                                getFloor={this.getFloor}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3 mt-md-0">
                                                <div className="card show mt-3">
                                                    <div className="card-body">
                                                        <RegistrationDate
                                                            projectInfo={this.state.projectInfo}
                                                            editProjectInfo={this.state.editProjectInfo}
                                                            onChange={this.handleChangInput}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="card show  mt-3">
                                                    <div className="card-body">
                                                        <ContactInfomation
                                                            projectInfo={this.state.projectInfo}
                                                            editProjectInfo={this.state.editProjectInfo}
                                                            deleteRow={this.onDelete}
                                                            onChange={this.handleChangInput}
                                                            appendFileInputRow={this.appendFileInputRow}
                                                        />
                                                    </div>
                                                </div>

                                                {this.state.editProjectInfo &&
                                                    <div className="row mt-4">
                                                        <div className="col d-flex justify-content-end">
                                                            <button
                                                                className="btn btn-primary btn-sm mr-5"
                                                                type="text"
                                                                onClick={this.onSubmit}
                                                            >{t("PageDetailProjectInfo:Save")}</button>
                                                            <button
                                                                className="btn btn-secondary btn-sm"
                                                                type="text"
                                                                style={{ width: "97px" }}
                                                                onClick={() => this.onCancel()}
                                                            >{t("PageDetailProjectInfo:Cancle")}</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.projectInfo.insideBuilding &&
                                    <PlanProject loading={this.state.loading}
                                        getPlan={this.getPlan} item={this.state.projectInfo.insideBuilding.edges} />
                                }
                                {this.state.projectInfo.unitBuilding &&
                                    <UnitsPlanProject loading={this.state.loading}
                                        getUnitPlan={this.getUnitPlan} item={this.state.projectInfo.unitBuilding.edges} />
                                }
                            </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default ProjectList;
