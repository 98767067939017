/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type carParkSelectQueryVariables = {|
  contact?: ?$ReadOnlyArray<?string>,
  car_type?: ?string,
|};
export type carParkSelectQueryResponse = {|
  +allCars: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +licencePlate: string,
      |}
    |}>
  |}
|};
export type carParkSelectQuery = {|
  variables: carParkSelectQueryVariables,
  response: carParkSelectQueryResponse,
|};
*/


/*
query carParkSelectQuery(
  $contact: [String]
  $car_type: String
) {
  allCars(contactList: $contact, chargedFee: "true", carType: $car_type) {
    edges {
      node {
        id
        licencePlate
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "car_type"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contact"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "carType",
        "variableName": "car_type"
      },
      {
        "kind": "Literal",
        "name": "chargedFee",
        "value": "true"
      },
      {
        "kind": "Variable",
        "name": "contactList",
        "variableName": "contact"
      }
    ],
    "concreteType": "CarNodeConnection",
    "kind": "LinkedField",
    "name": "allCars",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CarNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CarNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "licencePlate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "carParkSelectQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "carParkSelectQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "459663280c21a21020b9744cc7399be3",
    "id": null,
    "metadata": {},
    "name": "carParkSelectQuery",
    "operationKind": "query",
    "text": "query carParkSelectQuery(\n  $contact: [String]\n  $car_type: String\n) {\n  allCars(contactList: $contact, chargedFee: \"true\", carType: $car_type) {\n    edges {\n      node {\n        id\n        licencePlate\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '96762d0402cfad90248842c44c7e3156';

module.exports = node;
