/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createUpdatePersonnelQueryVariables = {|
  idTeam?: ?string
|};
export type createUpdatePersonnelQueryResponse = {|
  +allTeam: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +teamType: ?string,
        +namePrefix: ?string,
        +firstName: string,
        +lastName: string,
        +department: ?string,
        +position: ?string,
        +authorizationRight: boolean,
        +maximumApprovalLimit: ?string,
        +dateOfPosition: ?any,
        +onlineStatus: ?any,
        +image: ?string,
        +dateOfEndPosition: ?any,
        +employmentStatus: ?string,
        +address: ?string,
        +affiliation: ?string,
        +note: ?string,
        +email: ?string,
        +phone: ?string,
        +documentteamSet: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: string,
              +fileUpload: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type createUpdatePersonnelQuery = {|
  variables: createUpdatePersonnelQueryVariables,
  response: createUpdatePersonnelQueryResponse,
|};
*/


/*
query createUpdatePersonnelQuery(
  $idTeam: String
) {
  allTeam(idTeam: $idTeam) {
    edges {
      node {
        id
        teamType
        namePrefix
        firstName
        lastName
        department
        position
        authorizationRight
        maximumApprovalLimit
        dateOfPosition
        onlineStatus
        image
        dateOfEndPosition
        employmentStatus
        address
        affiliation
        note
        email
        phone
        documentteamSet {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "idTeam"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "idTeam",
        "variableName": "idTeam"
      }
    ],
    "concreteType": "TeamNodeConnection",
    "kind": "LinkedField",
    "name": "allTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "namePrefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "department",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "authorizationRight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maximumApprovalLimit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateOfPosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onlineStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateOfEndPosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "affiliation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentTeamNodeConnection",
                "kind": "LinkedField",
                "name": "documentteamSet",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocumentTeamNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DocumentTeamNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUpdatePersonnelQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUpdatePersonnelQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1f4d497a5bcc802f86f95da437e2db59",
    "id": null,
    "metadata": {},
    "name": "createUpdatePersonnelQuery",
    "operationKind": "query",
    "text": "query createUpdatePersonnelQuery(\n  $idTeam: String\n) {\n  allTeam(idTeam: $idTeam) {\n    edges {\n      node {\n        id\n        teamType\n        namePrefix\n        firstName\n        lastName\n        department\n        position\n        authorizationRight\n        maximumApprovalLimit\n        dateOfPosition\n        onlineStatus\n        image\n        dateOfEndPosition\n        employmentStatus\n        address\n        affiliation\n        note\n        email\n        phone\n        documentteamSet {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9fbfdd590d6c89b87cadf608b03e552a';

module.exports = node;
