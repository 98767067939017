import React from "react";
import SeachSelectGeneralLedger from "../../libs/seachSelectGeneralLedger";

// import Loading from '../../libs/loading'
// import {graphql} from "babel-plugin-relay/macro";
// import {QueryRenderer} from "react-relay";
// import environment from "../../env/environment";

// const query = graphql`
//     query chartSelectQuery {
//         viewer {
//             allChartOfAccount {
//                 edges {
//                     node {
//                         id
//                         name
//                         chartOfAccountCode
//                     }
//                 }
//             }
//         }
//     }
// `;

class chartSelect extends React.Component {
    render() {

        return (
            <SeachSelectGeneralLedger onChange={this.props.handleChange}
                                          value={this.props.value}
                                          name={this.props.name} placeholder="รายการ"
                                          queryObject={this.props.chart.viewer.allChartOfAccount.edges}
                                          keyOfValue="id" require={true}
                                          isClearable={true}
                                          disabled = {this.props.disabled}
                                          keyOfLabel="chartOfAccountCode:name"/>
            // <QueryRenderer
            //     environment={environment}
            //     query={query}
            //     fetchPolicy="no-cache"
            //     variables={{}}
            //     render={({error, props}) => {
            //         if (error) {
            //             return <div>{error.message}</div>;
            //         } else if (props) {
            //             return (
            //                  <SeachSelectGeneralLedger onChange={this.props.handleChange}
            //                               value={this.props.value}
            //                               name={this.props.name} placeholder="รายการ"
            //                               queryObject={props.viewer.allChartOfAccount.edges}
            //                               keyOfValue="id" require={true}
            //                               isClearable={true}
            //                               disabled = {this.props.disabled}
            //                               keyOfLabel="chartOfAccountCode:name"/>      
            //             )
            //         }
            //         return <Loading/>
            //     }}/>
        )
    }

}

export default chartSelect;
