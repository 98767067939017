import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import API from '../../api/silverGraund'
import numberWithComma from "../../libs/numberWithComma";
import { format } from "date-fns";
import { encode } from 'base-64'
import SettingTopMenuNavigation from '../settingTopMenuNavigation';
import settingAPI from '../../api/setting'
import './parkingfee.scss'
import i18next from 'i18next';

class ParkingFeeReportDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            settingCalu:[],
            hours:null,
            minutes:null
        }

        this.getTime = this.getTime.bind(this);
        this.getPersonType = this.getPersonType.bind(this);
        this.getMethodPayment = this.getMethodPayment.bind(this);
    }


    componentWillMount() {
        this.getDataReportDetail()
        this.getcaluletePackingSetting()
    }

    getDataReportDetail() {
        let idCode = this.props.match.params.id
        API.getDataReportDetail(idCode).then((ress) => {
            if (ress.data.data) {
                this.setState({ data: ress.data.data[0] })
            }
        }).catch(err => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getPersonType(val){
        let result;
        switch (val) {
            case "tenant":
              result = "ผู้เช่า/ลูกบ้าน"
              break;
            case "contact":
              result = "ติดต่อสำนักงานขาย"
              break;
            case "appointment":
              result = "ผู้ติดต่อนัดหมายล่วงหน้า"
            case "person":
              result = "บุคคลทั่วไป"
              break;
          }
          return result;
    }
    getcaluletePackingSetting(){
        settingAPI.getcaluletePackingSetting().then((res) => {
            if(res.data.settingCalu){
                 this.setState({
                    settingCalu: res.data.settingCalu,
                    hours : res.data.settingCalu.estampStatus ? res.data.settingCalu.etampTimer ? res.data.settingCalu.etampTimer : res.data.settingCalu.etampTimerHH : res.data.settingCalu.etampTimerVistor ? res.data.settingCalu.etampTimerVistor : res.data.settingCalu.etampTimerVistorHH,
                    minutes : res.data.settingCalu.estampStatus ? res.data.settingCalu.etampTimer ? 0 : res.data.settingCalu.etampTimerMM : res.data.settingCalu.etampTimerVistor ? 0 : res.data.settingCalu.etampTimerVistorMM
                 })
            }
         }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    getTime (type) {
        if (type === 'hours') {
            return parseInt(Math.abs(new Date(this.state.data.outDate) - new Date(this.state.data.startDate)) / (1000 * 60 * 60))
        } else {
           // return Math.ceil(new Date(this.state.data.paymentDate).getTime() - new Date(this.state.data.startDate).getTime() / (1000 * 60) % 60)
            return parseInt(Math.abs(new Date(this.state.data.outDate).getTime() - new Date(this.state.data.startDate).getTime()) / (1000 * 60) % 60)
        }
    }

    getMethodPayment(value){
        let result
        if(value === 'cash'){
            result = 'เงินสด'
        }else{
            result = 'QR Code'
        }
        return result
    }

    render() {

        const {estampStatus,etampTimer,etampTimerHH,etampTimerMM,etampTimerVistor,etampTimerVistorHH,etampTimerVistorMM} = this.state.settingCalu
        let A = (this.getTime('hours') * 3600) + (this.getTime() * 60)
        let B = estampStatus ? etampTimer ? (etampTimer * 3600) : (etampTimerHH * 3600 + etampTimerMM * 60) : etampTimerVistor ? (etampTimerVistor * 3600) : (etampTimerVistorHH * 3600 + etampTimerVistorMM * 60)

        let hours;
        let minutes;
        let price;

        if (Math.sign(A - B) === 1) {
             hours = Math.floor((A - B) / 3600); // get hours
             minutes = Math.floor(((A - B) - (hours * 3600)) / 60); // get minutes
             price = (hours * parseFloat(this.state.settingCalu?.toPayCal) + (minutes >= this.state.settingCalu?.toCalOverTime ? parseFloat(this.state.settingCalu.toPayCal) : 0))
           // setOverTime({ hours, minutes, price: (hours * parseFloat(toPayCal) + (minutes >= toCalOverTime ? parseFloat(toPayCal) : 0)) })
        } else {
            //setOverTime(false)
        }
 

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                <SettingTopMenuNavigation mini={true}  />
                    <div className="container-fluid box" id="reportGraund">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/silvermanguard">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('vmsDashboard:Summary of Paid Parking Fees')} {this.state.data.reportCode && this.state.data.reportCode}</span>
                                        }
                                    </Translation>                                    
                                </h3>
                            </div>
                           
                            
                        </div>


                        {
                            // this.state.loading == false &&
                            <React.Fragment>
                                <div>
                                    <div>
                                    {
                                        this.state.data.paymentStatus ?
                                            <span className='span-green'> <Translation>{t => t("dashboard:Paid")}</Translation></span>
                                        :''}  <span className='span-blue'> {this.getPersonType(this.state.data.typePerson)}</span> 
                                    </div>
                                    <div className='pt-6'>
                                        <h3><Translation>{t => t("vmsDashboard:Personal Information")}</Translation></h3>
                                        <hr/>
                                        <div>
                                            <img src={this.state.data?.idCardImage}
                                            style={{width:'398.71px',height:'254.54px',filter:'blur(10px)'}} />
                                        </div>
                                    </div>
                                    <div className='pt-8'>
                                         <h3><Translation>{t => t("vmsDashboard:Vehicle Information")}</Translation></h3>
                                         <hr/>
                                         <div className='row'>
                                            <div className='col-4'>
                                                <img src={this.state.data?.img} 
                                                style={{width:'398.71px',height:'254.54px'}} />
                                            </div>
                                            <div className='col-3'>
                                                <label><Translation>{t => t("vmsDashboard:Vehicle Registration No.")}</Translation></label>
                                                <input className='form-control' disabled={true} value={this.state.data?.carRegistration}/>
                                                <label><Translation>{t => t("vmsDashboard:Province")}</Translation></label>
                                                <input className='form-control' disabled={true} value={this.state.data?.province}/>
                                            </div>
                                         </div>
                                    </div>
                                    <div className='pt-8'>
                                        <h3><Translation>{t => t("vmsDashboard:Access Rights")}</Translation></h3>
                                        <hr/>
                                        <div>
                                             <h3><Translation>{t => t("vmsDashboard:Visitors Type")}</Translation></h3>  
                                             <div className="form-check m-2">
                                                <input className="form-check-input checkType" 
                                                    type="radio"  disabled={true}
                                                    name="check_type" checked={this.state.data.typePerson === "contact"}
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeAll" style={{marginTop:'0'}}>
                                                <Translation>{t => t("vmsDashboard:Contact")}</Translation>
                                                </label>
                                            </div>
                                            <div className="form-check m-2">
                                                <input className="form-check-input checkType" 
                                                    type="radio" disabled={true}
                                                    name="check_type" checked={this.state.data.typePerson === "appointment"}
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeAll" style={{marginTop:'0'}}>
                                                <Translation>{t => t("vmsDashboard:Appointment")}</Translation>
                                                </label>
                                            </div>
                                            <div className="form-check m-2">
                                                <input className="form-check-input checkType" 
                                                    type="radio" name="check_type" disabled={true}
                                                    checked={this.state.data.typePerson === "person"}
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeAll" style={{marginTop:'0'}}>
                                                <Translation>{t => t("vmsDashboard:Person")}</Translation>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='pt-4'>
                                             <h3><Translation>{t => t("vmsDashboard:RoleType")}</Translation></h3>  
                                             <div className="form-check m-2">
                                                <input className="form-check-input checkType" type="radio"
                                                    disabled={true}
                                                    name="typeRole" checked={this.state.data.typeRole === "temp"}
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeAll" style={{marginTop:'0'}}>
                                                <Translation>{t => t("vmsDashboard:Temporary")}</Translation>
                                                </label>
                                            </div>
                                            <div className="form-check m-2">
                                                <input className="form-check-input checkType" type="radio"
                                                    name="typeRole" disabled={true} 
                                                    checked={this.state.data.typePerson === "tenant" && this.state.data.typeRole === "perm"}
                                                />
                                                <label className="form-check-label" htmlFor="checkTypeAll" style={{marginTop:'0'}}>
                                                <Translation>{t => t("vmsDashboard:Permanent (with expiration date)")}</Translation> 
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                        (this.state.data.typePerson === "contact" || this.state.typePerson === "appointment") &&
                                             <div className='row'>
                                            <div className='col-4'>
                                                <label><Translation>{t => t("silvermanguard:HomeIdContact")}</Translation>* </label>
                                                <input className='form-control' disabled={true}
                                                    value={this.state.data.homeName} 
                                                />
                                            </div>  
                                        </div>
                                        }
                                        
                                        <div className='row'>
                                            <div className='col-2'>
                                                <label><Translation>{t => t("vmsDashboard:Parking Date")}</Translation></label>
                                                <input className='form-control' 
                                                    value={format(this.state.data.startDate, "YYYY-MM-DD")} disabled={true}/>
                                            </div>
                                            <div className='col-2'>
                                                <label><Translation>{t => t("vmsDashboard:Parking Time")}</Translation></label>
                                                <input className='form-control' 
                                                    value={this.state.data.timeUsingSt} disabled={true}/>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <label style={{fontSize:'14px'}}><Translation>{t => t("vmsDashboard:End date for parking rights")}</Translation></label>
                                                <input className='form-control' value={this.state.data.endDate && format(this.state.data.endDate, "YYYY-MM-DD")} disabled={true}/>
                                            </div>
                                            <div className='col-2'>
                                                <label><Translation>{t => t("vmsDashboard:End time for parking rights")}</Translation></label>
                                                <input className='form-control' value={this.state.data.timeUsingEn} disabled={true}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4'> 
                                        <h3><Translation>{t => t("vmsDashboard:Exit Car Time")}</Translation></h3>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <label style={{marginTop:'0'}}><Translation>{t => t("vmsDashboard:Exit Date")}</Translation></label>
                                                <input className='form-control' value={this.state.data.outDate && format(this.state.data.outDate, "YYYY-MM-DD")}  disabled={true}/>
                                            </div>
                                            <div className='col-2'>
                                                <label style={{marginTop:'0'}}><Translation>{t => t("vmsDashboard:Exit Time")}</Translation></label>
                                                <input className='form-control' value={this.state.data.outDate && format(this.state.data.outDate, "HH:mm A")} disabled={true}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-8'> 
                                        <div className='row'>
                                            <div className='col-2'>
                                                <p><Translation>{t => t("vmsDashboard:Total parking time")}</Translation></p>
                                            </div>
                                            <div className='col-2'>
                                                <p>{this.getTime('hours')}<Translation>{t => t("newBookingSingha_Create_step1:Hours")}</Translation> {this.getTime('minutes')} <Translation>{t => t("newBookingSingha_Create_step1:Minute")}</Translation> </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <p><Translation>{t => t("vmsDashboard:Free")}</Translation></p>
                                            </div>
                                            <div className='col-2'>
                                               <p>{this.state.hours || 0} <Translation>{t => t("newBookingSingha_Create_step1:Hours")}</Translation>  {this.state.minutes || 0} <Translation>{t => t("newBookingSingha_Create_step1:Minute")}</Translation></p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <p><Translation>{t => t("vmsDashboard:Over-parking time")}</Translation></p>
                                            </div>
                                            <div className='col-2'>
                                               <p>{hours || 0} <Translation>{t => t("newBookingSingha_Create_step1:Hours")}</Translation>  {minutes || 0 } <Translation>{t => t("newBookingSingha_Create_step1:Minute")}</Translation></p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <p><Translation>{t => t("vmsDashboard:Parking Fee")}</Translation></p>
                                            </div>
                                            <div className='col-2'>
                                               <p>{this.state.settingCalu.toPayCal || 0} <Translation>{t => t("newBookingSingha_Create_step1:Hours")}</Translation>/<Translation>{t => t("newBookingSingha_Create_step1:Minute")}</Translation></p>
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{color:'#4D4D4D'}}>
                                            <Translation>{t => t("vmsDashboard:Remarks Parking more than")}</Translation>
                                                30 <Translation>{t => t("vmsDashboard:minutes will be charged as 1 hour")}</Translation></p>
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className='pt-4'> 
                                        <div className='row'>
                                            <div className='col-2'>
                                                <h3><Translation>{t => t("vmsDashboard:Total Fee")}</Translation></h3>
                                            </div>
                                            <div className='col-2'>
                                                <h3>{numberWithComma(price || 0, "0.00")} <Translation>{t => t("vmsDashboard:Baht")}</Translation></h3> 
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <p style={{fontWeight:'600'}}><Translation>{t => t("vmsDashboard:Payment Method")}</Translation>: {this.state.data.methodPayment && this.getMethodPayment(this.state.data.methodPayment)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4'> 
                                        <h3><Translation>{t => t("vmsDashboard:The payment evidence")}</Translation></h3>
                                        <hr/>
                                        <div className='pb-4'>
                                            <img src={this.state.data.refPayment}
                                            style={{width:'398.71px',height:'auto'}} />
                                        </div>
                                        <span className='pt-4' style={{color:'#4D4D4D'}}><Translation>{t => t("vmsDashboard:Date and time of payment")}</Translation>: {this.state.data.paymentDate ? format(this.state.data.paymentDate, "YYYY-MM-DD") : '-'}, {this.state.data.paymentDate ? format(this.state.data.paymentDate, "HH:mm A") : '-'}</span>
                                    </div>
                                    {
                                        this.state.data.rtStatus === 'finished' ? 
                                            <div className='pt-8'> 
                                                {/* เฉพาะ vms เฉยๆ */}
                                                <h3>
                                                <Translation>{t => t("document:Receipt")}</Translation>
                                                </h3>
                                                <hr/>
                                                <Link to={'/silvermanguard/report-parking-fee/receipt/'+this.state.data.carTransactionId}>
                                                    <img
                                                        className="pb-2"
                                                        src={process.env.PUBLIC_URL + "/images/icons/icon_pdf.png"}
                                                        alt="pdf"
                                                        style={{ width: 24, height: 24 }}
                                                    /> ออกใบเสร็จ-{this.state.data.reportCode}
                                                </Link>
                                                <p className='pt-2'><Translation>{t => t("vmsDashboard:Email Sent Date and Time")}</Translation>:  {this.state.data.emailDate && format(this.state.data.emailDate, "YYYY-MM-DD")}, {format(this.state.data.emailDate, "HH:mm A")}</p>
                                                <p><Translation>{t => t("vmsDashboard:Email sender's name")}</Translation>:  {this.state.data.emailBy ? this.state.data.emailBy : '-'}</p>
                                                <p><Translation>{t => t("vmsDashboard:Email Name")}</Translation>: {this.state.data.email ? this.state.data.email : '-'}</p>
                                            </div> 
                                            : ''
                                    }
                                    
                                    {
                                        this.state.data.orID ? 
                                        <div className='pt-8'> 
                                         {/* เฉพาะ vms กะ account */}
                                            <h3><Translation>{t => t("accounting:add_other_receiver")}</Translation></h3>
                                            <hr/>
                                            <p>
                                            <Link
                                                   to={{
                                                        pathname:"/accounting/income/other_receiver/view/"+ encode("OtherReceiveNode:"+this.state.data.orID)+"/"+this.state.data.orDocnumber
                                                       }} 
                                            >
                                              {this.state.data.orDocnumber ? this.state.data.orDocnumber : '-'}
                                            </Link>
                                            </p>
                                            <p><Translation>{t => t("vmsDashboard:By")}</Translation> : {this.state.data.orCreator}</p>
                                            <p><Translation>{t => t("vmsDashboard:Date")}</Translation> : {this.state.data.orIssuedDate}</p>
                                        </div>
                                        :''
                                    }
                                    
                                </div>
                            </React.Fragment>

                        }
                        {/* {
                            this.state.loading == true && <Loading/>
                        } */}

                    </div>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default ParkingFeeReportDetail;
