import React, {Component} from 'react';
import { Translation } from 'react-i18next';
import {NavLink} from "react-router-dom";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink exact={true} to="/developer/unit-status/list/all">
                                    {t("developer:All")}
                                </NavLink>}
                            </Translation>
                        </li>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/developer/unit-status/list/not-transfer-ownership">
                                    {t("developer:Not Transfer Ownership")}
                                </NavLink>}
                            </Translation>
                        </li>
                        <li>
                            <Translation>
                                {t=>
                                <NavLink to="/developer/unit-status/list/transfer-ownership">
                                    {t("developer:Transfer Ownership")}
                                </NavLink>}
                            </Translation>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;