import React, { Component } from 'react'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import SearchSelect from '../../libs/searchSelect';
import _ from "lodash"

// const query = graphql`
//   query selectCatagoryServiceContactQuery{
//     productViewer{
//       allProduct (type_In: "expense",isActive: true){
//           edges{
//               node{
//                   id
//                   name
//                   productCode
//               }
//           }
//       }
//     }
//     viewer {
//       allChartOfAccount(codeList: ["5"]) {
//           edges {
//               node {
//                   id chartOfAccountCode name
//               }
//           }
//       }
//     }
//   }
// ` 

const query = graphql`
  query selectCatagoryServiceContactQuery{
    viewer {
      allChartOfAccount(codeList: ["5300", "5310"]) {
          edges {
              node {
                  id chartOfAccountCode name
              }
          }
      }
    }
  }
` 

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF'
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

export default class selectCatagoryServiceContact extends Component {

  constructor(props){
    super(props)

    this.state = {
      selectData: [],
    }
  }

  componentDidMount(){
    this.getData()
  }

  getData = () => {

    fetchQuery(environment, query).then((response) => {
      let textInput = []
      if(response.viewer){

        _.map(response.viewer.allChartOfAccount.edges, (item) => {
            textInput.push({node: {id: item.node.id, name: item.node.name ,productCode: item.node.chartOfAccountCode}})
        })

      }

      this.setState({selectData: textInput})
    })

  }

  render() {
    return (
      <SearchSelect 
        onChange={this.props.handleChangeInput} 
        disabled={this.props.disibleInput}
        value={this.props.chartOfAccountId}
        name={"purchaseRequisition.chartOfAccountId"} 
        placeholder="รายการ"
        queryObject={this.state.selectData}
        keyOfValue="id"
        keyOfLabel="productCode:name"
        style={customStyles}
        require={true}
        />
    )
  }
}
