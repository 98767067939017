/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAndUpdateCollectionLawFirmFromSettingInput = {|
  jsonObject: any,
  clientMutationId?: ?string,
|};
export type settingFormCollectionLawMutationVariables = {|
  input: CreateAndUpdateCollectionLawFirmFromSettingInput
|};
export type settingFormCollectionLawMutationResponse = {|
  +createAndUpdateCollectionLawFirmFromSetting: ?{|
    +ok: ?boolean,
    +massage: ?string,
  |}
|};
export type settingFormCollectionLawMutation = {|
  variables: settingFormCollectionLawMutationVariables,
  response: settingFormCollectionLawMutationResponse,
|};
*/


/*
mutation settingFormCollectionLawMutation(
  $input: CreateAndUpdateCollectionLawFirmFromSettingInput!
) {
  createAndUpdateCollectionLawFirmFromSetting(input: $input) {
    ok
    massage
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAndUpdateCollectionLawFirmFromSettingPayload",
    "kind": "LinkedField",
    "name": "createAndUpdateCollectionLawFirmFromSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "massage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingFormCollectionLawMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingFormCollectionLawMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "21c5d00ae1e02ad5d89a299e4edb0101",
    "id": null,
    "metadata": {},
    "name": "settingFormCollectionLawMutation",
    "operationKind": "mutation",
    "text": "mutation settingFormCollectionLawMutation(\n  $input: CreateAndUpdateCollectionLawFirmFromSettingInput!\n) {\n  createAndUpdateCollectionLawFirmFromSetting(input: $input) {\n    ok\n    massage\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc2c632666fdad66208f6c4c351f7e20';

module.exports = node;
