// localstorage management

class localstorage {
    static getSidebarSize() {
        return window.localStorage.getItem('sidebarSize') || "full";
    }
    static setSidebarSize(value) {
        if (value) {
            window.localStorage.setItem('sidebarSize', value);
            return value;
        } else {
            let currentSize = window.localStorage.getItem('sidebarSize');
            if (currentSize === 'full') {
                window.localStorage.setItem('sidebarSize', 'half');
                return 'half';
            } else {
                window.localStorage.setItem('sidebarSize', 'full');
                return 'full';
            }
        }
    }
    static getAdminLanguage() {
        return window.localStorage.getItem('adminLanguage') || "full";
    }
    static setAdminLanguage(value) {
        window.localStorage.setItem('adminLanguage', value);
        return value;
    }

    static getPageLimit() {
        return window.localStorage.getItem('pageLimit') || 10;
    }

    static setPageLimit(value) {
        window.localStorage.setItem('pageLimit', value);
        return value;
    }

    static getCheckPage() {
        return window.localStorage.getItem('CheckPage');
    }
    static setCheckPage(value,value1,value2) {
        const value_all = value+","+value1+","+value2
        window.localStorage.setItem('CheckPage', value_all);
        return value_all;
    }
    static removeCheckPage() {
        return window.localStorage.removeItem('CheckPage');
    }

}

export default localstorage