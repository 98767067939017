import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import numberWithComma from "../../libs/numberWithComma";
import {Link} from "react-router-dom";
import Loading from "../../libs/loading";

const query = graphql`
    query incomeTableQuery ($startDateFirst: DateTime, $endDateFirst: DateTime, $startDateSecond: DateTime, $endDateSecond: DateTime) {
        chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "4", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
            edges {
                node {
                    chartOfAccountCode
                    yearFirst
                    yearSecond
                    totalHistoricalDiffFirst
                    totalHistoricalDiffSecond
                }
            }
        }
    }
`;

class IncomeTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.setDataRender = this.setDataRender.bind(this)

    }

    setDataRender(data_render, chart_of_account, key, code) {
        let year_first = chart_of_account.node.yearFirst;
        let year_second = chart_of_account.node.yearSecond;
        let total_historical_diff_first = chart_of_account.node.totalHistoricalDiffFirst;
        let total_historical_diff_second = chart_of_account.node.totalHistoricalDiffSecond;
        Object.assign(data_render, {
            [key]: {
                year_first: year_first + data_render[key].year_first + total_historical_diff_first,
                year_second: year_second + data_render[key].year_second + total_historical_diff_second,
                code: code,
            }
        })
    }

    renderRow(name, year_first, year_second, code) {
        return (
            (year_first !== 0 || year_second !== 0) &&
            <tr>
                {/* <td>&nbsp;&nbsp;&nbsp;&nbsp;{name}</td> */}
                <td>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link key={code} to={"balance-sheet-statements/"+code+"/"+this.props.state.period} target='_blank'>{name}</Link>
                </td>
                <td className="text-right">{numberWithComma(year_first, '0.00')}</td>
                <td className="text-right">{numberWithComma(year_second, '0.00')}</td>
            </tr>
        )
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{use_cache: false}}
                variables={{
                    startDateFirst: this.props.state.start_date_first,
                    endDateFirst: this.props.state.end_date_first,
                    startDateSecond: this.props.state.start_date_second,
                    endDateSecond: this.props.state.end_date_second,
                }}

                render={({error, props}) => {
                    if (props) {
                        let data_render = {
                            common_fee: {year_first: 0, year_second: 0, code:"4100-01"},
                            utility_bills: {year_first: 0, year_second: 0, code:"4100-04"},
                            common_area_fee: {year_first: 0, year_second: 0, code:"4200"},
                            other: {year_first: 0, year_second: 0, code:"other_income"},
                        };
                        let year_first_total = 0;
                        let year_second_total = 0;
                        props.chartOfAccountSummaryIncomeExpense.edges.forEach((chart_of_account => {
                            let code = chart_of_account.node.chartOfAccountCode;
                            year_first_total += (chart_of_account.node.yearFirst + chart_of_account.node.totalHistoricalDiffFirst);
                            year_second_total += (chart_of_account.node.yearSecond + chart_of_account.node.totalHistoricalDiffSecond);

                            if (code === '4100-01') {
                                this.setDataRender(data_render, chart_of_account, 'common_fee', code)

                            }else if (code === '4100-04') {
                                this.setDataRender(data_render, chart_of_account, 'utility_bills', code)

                            }else if (code.startsWith('4200')) {
                                this.setDataRender(data_render, chart_of_account, 'common_area_fee', "4200")

                            }else {
                                this.setDataRender(data_render, chart_of_account, 'other', "other_income")

                            }

                        }));

                        return (
                            <React.Fragment>
                                <tr>
                                    <td>
                                        <strong>รายรับ / Revenue</strong>
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>

                                {this.renderRow("รายรับค่าส่วนกลาง / Revenue - Common area fees", data_render.common_fee.year_first, data_render.common_fee.year_second, data_render.common_fee.code )}
                                {this.renderRow("รายรับค่าน้ำประปา / Revenue - Waer supply", data_render.utility_bills.year_first, data_render.utility_bills.year_second, data_render.utility_bills.code)}
                                {this.renderRow("รายรับค่าใช้ประโยชน์พื้นที่ส่วนกลาง / Revenue Common area utilization", data_render.common_area_fee.year_first, data_render.common_area_fee.year_second, data_render.common_area_fee.code)}
                                {this.renderRow("รายรับอื่นๆ / Other Revenue", data_render.other.year_first, data_render.other.year_second, data_render.other.code)}

                                <tr>
                                    <td>
                                        <strong>รวมรายรับ / Total Revenue</strong>
                                    </td>
                                    <td className="text-right"><strong>{numberWithComma(year_first_total, '0.00')}</strong></td>
                                    <td className="text-right"><strong>{numberWithComma(year_second_total, '0.00')}</strong></td>
                                </tr>
                            </React.Fragment>
                        )
                    } else {
                        return <Loading />
                    }
                }}
            />

        )
    }
}

export default IncomeTable;