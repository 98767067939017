import i18next from 'i18next'
import React, { Component } from 'react'
import DatePickerNoti from '../../../finance/depositInterest/datePickerNoti'
import "../style/contractExpense.scss"

export default class contractContactDetails extends Component {
  render() {
    return (
      <div id="contractContactDetails">
        <div className="row">
          <div className="col">
            <h4>{i18next.t("updateFormContractExpense:Contact Person Details")}</h4>
            <div className="lineBottom mb-5"/>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <h6>{i18next.t("updateFormContractExpense:Contact Person's Name")}</h6>
            <div className="input-group">
              <input type="text"
                disabled
                className="form-control"
                value={this.props.contractor}
                />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-4">
            <h6>{i18next.t("updateFormContractExpense:Phone Number")}</h6>
            <div className="input-group">
              <input type="text"
                disabled
                className="form-control"
                value={this.props.contractorTel}
                />
            </div>
          </div>

          <div className="col-4">
            <h6>{i18next.t("updateFormContractExpense:Email")}</h6>
            <div className="input-group">
              <input type="text"
                disabled
                className="form-control"
                value={this.props.contractorEmail}
                />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-4">
            <h6>{i18next.t("updateFormContractExpense:Delivery Address")}</h6>
            <div className="input-group">
              <textarea 
                disabled
                className="bodyTextArea-disibled"
                value={this.props.deliveryAddress}
                />
            </div>
          </div>

          <div className="col-2 customDatePickerWidth">
            <h6>{i18next.t("updateFormContractExpense:Delivery Date")}</h6>
            <DatePickerNoti
              disabled
              selected={this.props.deliveryDate}
              />
          </div>
        </div>

      </div>
    )
  }
}
