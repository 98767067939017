/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResidentialVoteStatus = "APPROVE" | "NOT_APPROVE" | "NOT_VOTE" | "%future added value";
export type UpdateResidentialVoteInput = {|
  residentialVoteId: string,
  clientMutationId?: ?string,
|};
export type residentialVoteMutationMutationVariables = {|
  input: UpdateResidentialVoteInput
|};
export type residentialVoteMutationMutationResponse = {|
  +updateResidentialVote: ?{|
    +residentialVote: ?{|
      +id: string,
      +status: ResidentialVoteStatus,
    |}
  |}
|};
export type residentialVoteMutationMutation = {|
  variables: residentialVoteMutationMutationVariables,
  response: residentialVoteMutationMutationResponse,
|};
*/


/*
mutation residentialVoteMutationMutation(
  $input: UpdateResidentialVoteInput!
) {
  updateResidentialVote(input: $input) {
    residentialVote {
      id
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateResidentialVotePayload",
    "kind": "LinkedField",
    "name": "updateResidentialVote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialVoteNode",
        "kind": "LinkedField",
        "name": "residentialVote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "residentialVoteMutationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "residentialVoteMutationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1a1e188ddac76bffa9d90debb7b1ec1a",
    "id": null,
    "metadata": {},
    "name": "residentialVoteMutationMutation",
    "operationKind": "mutation",
    "text": "mutation residentialVoteMutationMutation(\n  $input: UpdateResidentialVoteInput!\n) {\n  updateResidentialVote(input: $input) {\n    residentialVote {\n      id\n      status\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd375a1a37aca63823d5488022c609d1d';

module.exports = node;
