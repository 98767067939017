import {
    commitMutation,

} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from '../../../env/environment';

const mutation = graphql`
mutation pettyCashRecordEditDescriptionMutation ($input: editPettyCashRecordInput!) {
    editPettyCashRecord(input: $input) {
        ok
        warningText
    }
}
`;

export default function EditPettyCashRecord(variables, callback, error_callback) {

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}

