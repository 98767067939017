/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type cashListAllQueryVariables = {|
  first?: ?number,
  last?: ?number,
|};
export type cashListAllQueryResponse = {|
  +cashDepositGroups: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +depositDate: any,
        +description: ?string,
        +total: ?number,
        +added: any,
        +bank: ?{|
          +id: string,
          +bankName: string,
          +accountNumber: string,
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
  +selfProject: ?{|
    +timeZone: ?string
  |},
|};
export type cashListAllQuery = {|
  variables: cashListAllQueryVariables,
  response: cashListAllQueryResponse,
|};
*/


/*
query cashListAllQuery(
  $first: Int
  $last: Int
) {
  cashDepositGroups(first: $first, last: $last) {
    edges {
      node {
        id
        docNumber
        depositDate
        description
        total
        added
        bank {
          id
          bankName
          accountNumber
        }
      }
    }
    totalCount
  }
  selfProject {
    timeZone
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    }
  ],
  "concreteType": "CashDepositGroupConnection",
  "kind": "LinkedField",
  "name": "cashDepositGroups",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CashDepositGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CashDepositGroup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "depositDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "added",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "bank",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bankName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accountNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cashListAllQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cashListAllQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65206debbdad83e57a643c9504d7c25a",
    "id": null,
    "metadata": {},
    "name": "cashListAllQuery",
    "operationKind": "query",
    "text": "query cashListAllQuery(\n  $first: Int\n  $last: Int\n) {\n  cashDepositGroups(first: $first, last: $last) {\n    edges {\n      node {\n        id\n        docNumber\n        depositDate\n        description\n        total\n        added\n        bank {\n          id\n          bankName\n          accountNumber\n        }\n      }\n    }\n    totalCount\n  }\n  selfProject {\n    timeZone\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62bddd941963c085bdd915762c74c131';

module.exports = node;
