import React, {Component} from 'react';
import {graphql} from 'babel-plugin-relay/macro';
import environment from '../../env/environment';
import SearchSelectChartOfAccount from "../../libs/searchSelectChartOfAccount";
import {fetchQuery} from "relay-runtime";
import Swal from "sweetalert2";
import {Redirect} from 'react-router';
import _ from "lodash";
import ChartOfAccountCreateMutation from "./mutations/ChartOfAccountCreateMutation"
import ChartOfAccountUpdateMutation from "./mutations/ChartOfAccountUpdateMutation"
import i18next from 'i18next';


const query = graphql`
    query chartOfAccountFormQuery($code: String) {
        nextChartOfAccount(code:$code)
    }
`;

class ChartOfAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            name: "",
            nameEn: "",
            historical: "",
            historicalDebit: 0,
            historicalCredit: 0,
            error: '',
            loading: '',
            id: "",
            chart_of_account: [],
            main_select: '',
            secondary_select: '',
            subordinate_select: '',
            last_group_select: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getNextChartOfAccountCode = this.getNextChartOfAccountCode.bind(this);
    }

    componentWillMount() {
        this.setState({chart_of_account: require('./json/chart_of_account.json')});
         // { ถ้าอยากใช้รหัส 9999 ให้เอาไปใส่ในไฟล์ json และ uncomment rowRenderBringForward.js settingBringForward.js chartOfAccount.js
        //   "key": "9999",
        //   "name": "9999-00 บัญชีพัก / Suspense account",
        //   "child": []
        // }


        if (this.props.chart) {
            let historical_debit = 0;
            let historical_credit = 0;
            if (this.props.chart.chartOfAccount.accountRecord.edges.length > 0) {
                historical_debit = this.props.chart.chartOfAccount.accountRecord.edges[0].node.debit;
                historical_credit = this.props.chart.chartOfAccount.accountRecord.edges[0].node.credit;
            }

            this.setState({
                id: this.props.chart.chartOfAccount.id,
                code: this.props.chart.chartOfAccount.chartOfAccountCode,
                name: this.props.chart.chartOfAccount.name,
                nameEn: this.props.chart.chartOfAccount.nameEn,
                historicalDebit: historical_debit,
                historicalCredit: historical_credit,
            });
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        // loading status equal true update to false after oncomplete call
        this.setState({loading: true, error: ''});
        const input = {
            "chartOfAccount": {
                "name": this.state.name,
                "nameEn": this.state.nameEn,
                "chartOfAccountCode": this.state.code,
                "historicalDebit": parseFloat(this.state.historicalDebit),
                "historicalCredit": parseFloat(this.state.historicalCredit)
            }
        }

        if (this.state.id) {
            input.id = this.state.id
            ChartOfAccountUpdateMutation.commit(
                input, 
                {}, 
                (response, errors) => {
                    if (errors) {
                        this.setState({loading: false, error: true});
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error')
                    } else {

                        if (response.chartOfAccountUpdate.ok) {
                            this.setState({loading: false});
                            Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                                this.setState({redirectToList: true});
                            });
                        } else {
                            this.setState({loading: false, error: true});
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.chartOfAccountUpdate.warningText, 'error')
                        }

                    }
                },
                error => {
                    this.setState({loading: false, error: true});
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please try again."), 'error')
                }
            )
        }
        else{
            ChartOfAccountCreateMutation.commit(
                input, 
                {}, 
                (response, errors) => {
                    if (errors) {
                        this.setState({loading: false, error: true});
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error')
                    } else {
                        this.setState({loading: false});
                        if (response.chartOfAccountCreate.ok) {
                            this.setState({loading: false});
                            Swal.fire(i18next.t("Allaction:successcompleate"), '', 'success').then(() => {
                                this.setState({redirectToList: true});
                            });
                        } else {
                            this.setState({loading: false, error: true});
                            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.chartOfAccountCreate.warningText, 'error')
                        }
                    }
                },
                error => {
                    this.setState({loading: false, error: true});
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please try again."), 'error')
                }
            )
        }
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
        if (e.target.name === 'main_select') {
            this.setState({secondary_select: '', subordinate_select: '', last_group_select: ''});
        }
        if (e.target.name === 'main_select' || e.target.name === 'secondary_select' || e.target.name === 'subordinate_select' || e.target.name === 'last_group_select') {
            if(e.target.name === 'secondary_select'){
                this.setState({subordinate_select: '', last_group_select: ''})
            }else if(e.target.name === 'subordinate_select'){
                this.setState({last_group_select: ''})
            }
            this.getNextChartOfAccountCode(e.target.name, e.target.value)
        }
    }

    getNextChartOfAccountCode(name, value) {
        let code = '';
        if(name === 'main_select'){
            code = this.state.chart_of_account[value].key
        }
        else if(name === 'secondary_select'){
            code = this.state.chart_of_account[this.state.main_select].child[value].key
        }
        else if(name === 'subordinate_select'){
            code = this.state.chart_of_account[this.state.main_select].child[this.state.secondary_select].child[value].key
        }
        else if(name === 'last_group_select'){
            code = this.state.chart_of_account[this.state.main_select].child[this.state.secondary_select].child[this.state.subordinate_select].child[value].key
        }

        fetchQuery(environment, query, {code: code}).then(data => {
             if (data.nextChartOfAccount) {
               this.setState({
                   code: data.nextChartOfAccount
               })
             }
         });
    }

    checkEmtpyOrZero(historical) {
        let empty_or_zero = false;
        if (historical > 0) {
            empty_or_zero = true
        }
        return  empty_or_zero
    }

    render() {
        if(this.state.redirectToList){
             return <Redirect to="/accounting/chart-of-account/all"/>
        }

        return (
            <div className="col-6">

                <form id="create-chart-of-account" onSubmit={this.onSubmit}>

                    {!this.props.chart &&
                    <React.Fragment>
                        <div className="row">
                            <label htmlFor="code" className="col-form-label col-3">บัญชีหลัก</label>
                            <div className="col">
                                <SearchSelectChartOfAccount onChange={this.handleInputChange}
                                                            value={this.state.main_select}
                                                            name={"main_select"} placeholder="รายการ"
                                                            queryObject={this.state.chart_of_account}
                                                            keyOfValue="main_select"
                                                            keyOfLabel="name"
                                                            chart_of_account={true}
                                                            require={this.state.main_select === 0 ? false : "required"}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <label htmlFor="code" className="col-form-label col-3">บัญชีรอง</label>
                            <div className="col">
                                <SearchSelectChartOfAccount onChange={this.handleInputChange}
                                                            value={this.state.secondary_select}
                                                            name="secondary_select" placeholder="รายการ"
                                                            queryObject={(this.state.main_select || this.state.main_select !== '') ? this.state.chart_of_account[this.state.main_select].child : []}
                                                            keyOfValue="secondary_select"
                                                            keyOfLabel="name"
                                                            chart_of_account={true}
                                                            require={(this.state.main_select || this.state.main_select !== '') && this.state.chart_of_account[this.state.main_select].child.length > 0 && this.state.secondary_select === '' ? "required": false }
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <label htmlFor="code" className="col-form-label col-3">บัญชีคุม</label>
                            <div className="col">
                                <SearchSelectChartOfAccount onChange={this.handleInputChange}
                                                            value={this.state.subordinate_select}
                                                            name="subordinate_select" placeholder="รายการ"
                                                            queryObject={((this.state.main_select || this.state.main_select !== '') && (this.state.secondary_select || this.state.secondary_select !== '')) ? this.state.chart_of_account[this.state.main_select].child[this.state.secondary_select].child : []}
                                                            keyOfValue="subordinate_select"
                                                            keyOfLabel="name"
                                                            chart_of_account={true}
                                                            require={(this.state.main_select || this.state.main_select !== '') && (this.state.secondary_select || this.state.secondary_select !== '') && this.state.chart_of_account[this.state.main_select].child[this.state.secondary_select].child.length > 0 && this.state.subordinate_select === '' ? "required": false }

                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <label htmlFor="code" className="col-form-label col-3">บัญชีย่อย</label>
                            <div className="col">
                                <SearchSelectChartOfAccount onChange={this.handleInputChange}
                                                            value={this.state.last_group_select}
                                                            name="last_group_select" placeholder="รายการ"
                                                            queryObject={((this.state.main_select || this.state.main_select !== '') && (this.state.secondary_select || this.state.secondary_select !== '')) && (this.state.subordinate_select || this.state.subordinate_select !== '')
                                                                ? this.state.chart_of_account[this.state.main_select].child[this.state.secondary_select].child[this.state.subordinate_select].child : []}
                                                            keyOfValue="last_group_select"
                                                            keyOfLabel="name"
                                                            chart_of_account={true}
                                                            require={((this.state.main_select || this.state.main_select !== '') && (this.state.secondary_select || this.state.secondary_select !== '')) && (this.state.subordinate_select || this.state.subordinate_select !== '')
                                                            && this.state.chart_of_account[this.state.main_select].child[this.state.secondary_select].child[this.state.subordinate_select].child.length > 0 && this.state.last_group_select === '' ? "required" : false}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                    }

                    {!this.props.chart ?
                        <div className="row mt-3">
                            <label htmlFor="code" className="col-form-label col-3">เลขที่บัญชี</label>
                            <label htmlFor="code" className="col-form-label col-3">{this.state.code || '-'}</label>
                        </div> :
                        <div className="row mt-3">
                            <label htmlFor="code" className="col-form-label col-3">เลขที่บัญชี</label>
                            <div className="col">
                                <input type="text" className="form-control" name="code"
                                       value={this.state.code} onChange={this.handleChange}
                                       placeholder="ชื่อบัญชี" disabled required/>
                            </div>
                        </div>
                    }

                    <div className="row mt-3">
                        <label htmlFor="code" className="col-form-label col-3">ชื่อบัญชี</label>
                        <div className="col">
                            <input type="text" className="form-control" name="name"
                               value={this.state.name} onChange={this.handleChange}
                               placeholder="ชื่อบัญชี" required/>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <label htmlFor="code" className="col-form-label col-3">ชื่อบัญชี-ภาษาอังกฤษ</label>
                        <div className="col">
                            <input type="text" className="form-control" name="nameEn"
                               value={this.state.nameEn} onChange={this.handleChange}
                               placeholder="ชื่อบัญชี-ภาษาอังกฤษ" required/>
                        </div>
                    </div>

                    {/**/}
                    {/* <div className="row mt-3">
                        <label htmlFor="code" className="col-form-label col-3">เดบิต</label>
                        <div className="col">
                          <input type="number" className="form-control" name="historicalDebit"
                               value={this.state.historicalDebit} onChange={this.handleChange}
                               placeholder="ยอดเดบิต" disabled={this.checkEmtpyOrZero(this.state.historicalCredit)} required/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <label htmlFor="code" className="col-form-label col-3">เครดิต</label>
                        <div className="col">
                          <input type="number" className="form-control" name="historicalCredit"
                               value={this.state.historicalCredit} onChange={this.handleChange}
                               placeholder="ยอดเครดิต" disabled={this.checkEmtpyOrZero(this.state.historicalDebit)} required/>
                        </div>
                    </div> */}
                    {/**/}

                    {((this.props.chart && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_chart_of_account_edit'})) || (!this.props.chart && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_chart_of_account_create'}))) &&
                    <div className="row mt-3 float-right">
                        <div className="col ">
                            {/* <Link to="/accounting/chart-of-account/all">
                                <button type="button" className="btn btn-secondary mr-2">
                                    ยกเลิก
                                </button>
                            </Link> */}
                            <button type="submit" className="btn btn-primary " form="create-chart-of-account"
                                    value="submit" disabled={this.state.loading}>
                                {this.state.loading && <span
                                    className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                บันทึก
                            </button>
                        </div>
                    </div>
                    }
                </form>
            </div>


        )
    }
}

export default ChartOfAccountForm;
