import React, { Component } from 'react'
import _ from "lodash"
import "./style/chequeListTable.scss"
import { Translation } from 'react-i18next';
import i18next from 'i18next';


export default class setFormatNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'auto',
      cheque_no: '',
      cheque_no_start: '',
      cheque_amount: '',
      items: [],
      items_auto: [],
      chequeDetails: [],
      validate: {
        cheque_no: true,
        cheque_no_start: true,
        cheque_amount: true,
      },
      validate_custom: true
    };
  }

  setDefault = () => {
    this.setState({
      status: 'auto',
      cheque_no: '',
      cheque_no_start: '',
      cheque_amount: '',
      items: [],
      items_auto: [],
      chequeDetails: [],
      validate: {
        cheque_no: true,
        cheque_no_start: true,
        cheque_amount: true,
      },
      validate_custom: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.model !== this.props.model) {
      if (this.props.model === 'close') {
        this.setDefault()
      }
    }
  }

  handleChange = (e) => {
    const reg = /^[0-9\b]+$/;
    if (reg.test(e.target.value) || e.target.value === '') {
      let validate = _.cloneDeep(this.state.validate)
      if ('cheque_no' === e.target.name || 'cheque_amount' === e.target.name) {
        e.target.value.length > 0 ? validate[e.target.name] = false : validate[e.target.name] = true
      } else if ('cheque_no_start' === e.target.name) {
        e.target.value.length > 7 ? validate[e.target.name] = false : validate[e.target.name] = true
      }
      this.setState({ [e.target.name]: e.target.value, validate })
    }
  }

  handleRadioChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleInputChange = (e) => {
    const reg = /^[0-9\b]+$/;
    if (reg.test(e.target.value) || e.target.value === '') {
      this.setState({ items: _.set(_.cloneDeep(this.state.items), e.target.name, e.target.value) }, () => {
        let validate_custom = _.cloneDeep(this.state.validate_custom)
        _.findIndex(this.state.items, function (o) { return o.noNumber.length < 8; }) === -1 ? validate_custom = false : validate_custom = true
        this.setState({ validate_custom })
      })
    }
  }

  setKeycardArr = () => {
    if (this.state.cheque_amount) {
      let data = []
      for (let index = 0; index < this.state.cheque_amount || 0; index++) {
        data.push({ "chequeNoId": "", "noNumber": "", "status": true })
      }
      this.setState({ items: data })
    }
  }

  setKeycardArrAuto = () => {
    if (this.state.cheque_amount) {
      let data = []
      for (let index = 0; index < this.state.cheque_amount || 0; index++) {

        let number = parseInt(this.state.cheque_no_start) + index
        let customNum = this.state.cheque_no_start
        customNum = customNum.substring(0, customNum.length - number.toString().length);
        customNum = customNum + number

        data.push({ "chequeNoId": "", "noNumber": customNum, "status": true })
        // data.push({ "chequeNoId": "", "noNumber": (parseInt(this.state.cheque_no_start) + index).toString(), "status": true })
      }
      this.setState({ items_auto: data }, () => {
        this.props.setDetail(this.state)
      })
    }
  }


  render() {
    let status_save = this.state.status === 'auto' ? (this.state.validate.cheque_amount || this.state.validate.cheque_no || this.state.validate.cheque_no_start) : this.state.validate_custom
    return (
      <Translation>{t=> 
       <React.Fragment >
        <div className="modal fade" id="modalSetFormatNumber2" role="dialog" aria-labelledby="modalSetFormatNumberTitle" aria-hidden="true" >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title" id="modalSetFormatNumberTitle">{i18next.t("chequeControlView:Specify the numbering format for No.")}</h5>
              </div>
              <div className="lineModal" />
              <div className="modal-body" id='modalSetFormatNumber' >
                <div className="row">
                  <div className="col-12">
                    <h5>{i18next.t("chequeControlView:Numbering format")}</h5>
                  </div>

                  <div className="col-12">
                    <input type="radio" name="status"
                      value="auto"
                      checked={this.state.status === "auto"}
                      onChange={this.handleRadioChange}
                    />
                    <span className="ml-2">{i18next.t("chequeControlView:Automatic")}</span>
                  </div>

                  <div className="col-12 mt-5">
                    <input type="radio" name="status"
                      value="custom"
                      checked={this.state.status === "custom"}
                      onChange={this.handleRadioChange}
                    />
                    <span className="ml-2">{i18next.t("chequeControlView:Custom")}</span>
                  </div>
                  {
                    this.state.status === "auto" && <div className="col-12 mt-5">
                      <span >{i18next.t("chequeControlView:Checkbook No.")}<span style={{ color: 'red' }} >*</span> </span>
                      <input type="text" className="form-control mt-2 mb-4" name='cheque_no' onChange={this.handleChange} value={this.state.cheque_no} required />
                      <span >{i18next.t("chequeControlView:Starting cheque number")}<span style={{ color: 'red' }} >* {i18next.t("chequeControlView:Please fill in all 8 digits.")}</span></span>
                      <input type="text" className="form-control mt-2 mb-4" name='cheque_no_start' onChange={this.handleChange} maxLength={8} value={this.state.cheque_no_start}
                       required />

                      <span >{i18next.t("chequeControlView:Amount")}<span style={{ color: 'red' }} >*</span></span>
                      <input type="text" className="form-control mt-2 mb-4" name='cheque_amount' onChange={this.handleChange} value={this.state.cheque_amount} required />
                    </div>
                  }

                  {this.state.status === "custom" && <div className="col-12 mt-5">
                    <span >{i18next.t("chequeControlView:Checkbook No.")}<span style={{ color: 'red' }} >*</span></span>
                    <input type="text" className="form-control mt-2 mb-4" name='cheque_no' onChange={this.handleChange} value={this.state.cheque_no} />
                    <span >{i18next.t("chequeControlView:Amount")}<span style={{ color: 'red' }} >*</span></span>
                    <input type="text" className="form-control mt-2 mb-4" name='cheque_amount' onChange={this.handleChange} value={this.state.cheque_amount} />
                    <div className="d-flex">
                      <button className="btn btn-primary mt-6 mb-6" type="button" onClick={this.setKeycardArr}>สร้างรายการ</button>
                    </div>

                    {this.state.items.length > 0 && <div className="bglight">
                      <div className="row items">
                        <div className="col-2 text-center">{i18next.t("receiveReport:Sequence")}</div>
                        <div className="col-10">Run No.</div>
                      </div>
                    </div>}

                    <div className="slide">
                      {this.state.items.length > 0 && _.map(this.state.items, (item, indexItem) => {
                        return (
                          <React.Fragment key={indexItem}>
                            <div className="lineTop" />
                            <div className="row items">
                              <div className="col-2 text-center">{(indexItem + 1)}</div>
                              <div className="col-10">
                                <input className="form-control" maxLength={8} value={item['noNumber'] || ''} name={`[${indexItem}].noNumber`} onChange={this.handleInputChange} />
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </div>
                  }
                </div>
              </div>

              <div className="lineModal" />
              <div className="modal-footer border-0" id='modalSetFormatNumber'>
                <React.Fragment>
                  <button type="button" className="btnClose" data-dismiss="modal" onClick={this.props.setModel}  > {t("Allaction:cancel")} </button>
                  <button type="button" data-dismiss="modal"
                    className={status_save ? 'btnClose' : 'btnSave'}
                    disabled={status_save}
                    onClick={() => { this.state.status === 'auto' ? this.setKeycardArrAuto() : this.props.setDetail(this.state) }} >{t("settingAll:Add list")}</button>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
      
      }</Translation>
     
    )
  }
}
