import _ from "lodash";
import React from "react";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import SearchSelect from "../../../../libs/searchSelect";
import chequeControlQuery from '../query/chequeControlQuery'

const cheque = {
    chequeIssuedDate: new Date(),
    chequeNumber: '',
    accountNumber: '',
    branch: '',
    bankName: '',
    ChequeDetailsId: '',
    ChequeNoId: '',
    id: ''
};


class PayPaymentChannelChequeInPay extends React.Component {

    constructor(props) {
        super(props);
        this.state = { cheque: cheque, chequeDetails: [], chequeNo: [] };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        let cheque = { ...this.state.cheque };
        this.props.cheque_choice.forEach((cheque_detail) => {
            if (e.target.value === cheque_detail.node.id) {
                chequeControlQuery(cheque_detail.node.id).then((res) => this.setState({ chequeDetails: res.chequeControl.edges[0].node.chequeDetails }))
                cheque.accountNumber = cheque_detail.node.accountNumber;
                cheque.branch = cheque_detail.node.branch;
                cheque.id = cheque_detail.node.id;
            }
        });

        this.setState({ cheque: cheque }, () => this.props.handleInputChange(e))
    }
    
    handleChequeDetails = (e) => {
        let cheque = { ...this.state.cheque };
        this.props.cheque_choice.forEach((cheque_detail) => {
            if (cheque.id === cheque_detail.node.id) {
                if (this.state.chequeDetails.edges.length > 0) {
                    this.state.chequeDetails.edges.forEach((cheque) => {
                        if (cheque.node.id === e.target.value) {
                            this.setState(prevState => _.set(_.cloneDeep(prevState), "cheque.ChequeDetailsId", cheque.node.id));
                            this.setState(prevState => _.set(_.cloneDeep(prevState), "chequeNo", cheque.node.chequeNo));
                        }

                    })
                }

            }
        });
        this.setState({ cheque: cheque }, () => this.props.handleInputChange(e))

    }

    handleChequeNo = (e) => {
        let cheque = { ...this.state.cheque };
        this.props.cheque_choice.forEach((cheque_detail) => {
            if (cheque.id === cheque_detail.node.id) {
                if (this.state.chequeNo.edges.length > 0) {
                    this.state.chequeNo.edges.forEach((chequeNo) => {
                        if (chequeNo.node.id === e.target.value) {
                            this.setState(prevState => { return _.set(_.cloneDeep(prevState), "ChequeNoId", chequeNo.node.id); });
                            this.props.handleInputChange({
                                target : {
                                  name: "pay_channel[" + this.props.index + "]cheque.chequeNumber",
                                  value: chequeNo.node.noNumber
                                }
                              })
                        }
                    })
                }

            }
        });
        this.setState({ cheque: cheque }, () => this.props.handleInputChange(e))
    }

    render() {
        let index = [this.props.index];
        let pay_channel = this.props.pay_channel[this.props.index];
        return (

            <div className="mt-4 border p-4">
                <div className="row">
                    <div className="col">
                        <h6>รายละเอียดเช็ค</h6>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <p>ธนาคาร</p>
                        <SearchSelect onChange={this.handleInputChange}
                            value={pay_channel.cheque.bankName}
                            name={"pay_channel[" + index + "]cheque.bankName"} placeholder="รายการ"
                            queryObject={this.props.cheque_choice}
                            require={true}
                            keyOfValue="id"
                            keyOfLabel="bankName:account_type:accountNumber"
                        />
                    </div>
                    <div className="col">
                        <p>เลขบัญชี</p>
                        <input type="text" name={"pay_channel[" + index + "]cheque.accountNumber"} className="form-control"
                            value={this.state.cheque.accountNumber} disabled />
                    </div>
                    <div className="col">
                        <p>สาขา</p>
                        <input type="text" name={"pay_channel[" + index + ".]cheque.branchNumber"} className="form-control"
                            value={this.state.cheque.branch} disabled />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col">
                        <p>เล่มเช็ค</p>
                        <SearchSelect
                            onChange={this.handleChequeDetails}
                            value={pay_channel.cheque.ChequeDetailsId}
                            name={"pay_channel[" + index + "]cheque.ChequeDetailsId"}
                            // name={'chequeDetails.ChequeDetailsId'}
                            placeholder="รายการ"
                            queryObject={this.state.chequeDetails.edges || []}
                            require={true}
                            keyOfValue="id"
                            keyOfLabel="noNumber"
                        />

                    </div>
                    <div className="col">
                        <p>เลขที่เช็ค</p>
                        <SearchSelect
                            onChange={this.handleChequeNo}
                            value={pay_channel.cheque.ChequeNoId}
                            name={"pay_channel[" + index + "]cheque.ChequeNoId"}
                            // name={'chequeNo.ChequeNoId'}
                            placeholder="รายการ"
                            queryObject={this.state.chequeNo.edges || []}
                            require={true}
                            keyOfValue="id"
                            keyOfLabel="noNumber"
                        />
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label htmlFor="chequeIssuedDate"
                            className="mr-3">วันที่</label><br />
                        <DatePickerAdapter id="chequeIssuedDate"
                            className="form-control"
                            name={"pay_channel[" + index + "].cheque.chequeIssuedDate"}
                            data-key="cheque"
                            selected={pay_channel.cheque.chequeIssuedDate}
                            onChange={this.props.handleInputChange}

                            required={true} />
                    </div>
                </div>


            </div>

        )
    }
}

export default PayPaymentChannelChequeInPay;
