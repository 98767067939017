const getApiRoot = () => {
    // let host = "svmsupport-admin.silverman.cloud"
     let host = window.location.hostname;
     let host_array = host.split('.');
     let project_name = host_array[0].split('-')[0];
 
     host_array.shift();
 
     let language = localStorage.getItem("language");
     if ((language !== null) && (language !== 'th')) {
         return window.location.protocol + '//' + project_name + '.' + host_array.join('.') + '/'
     } else {
         return window.location.protocol + '//' + project_name + '.' + host_array.join('.') + '/'
     }
 
    
     // return window.location.protocol + '//' + "kitipob.silverman" + '/'
 };
 
 export default getApiRoot;
