import React from 'react'
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './settingAdvertise.scss'
import Navigation from './navigation';
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import { QueryRenderer } from 'react-relay';
import { format } from 'date-fns';
import Loading from "../../libs/loading";

const query = graphql`
query settingAdvertiseListQuery{
    allAdvertiseJuristic{
            edges{
                node{
                    id
                    title
                    detail
                    link
                    image
                    customerName
                    types
                    click
                    status
                    added
                }
            }
        }
}`;

const listAdvertise = (item) => {
    return (
        <div className='row box-list mb-4' key ={item.node.id}>
            <div className='col-8'>
                <h6>{item.node.title}</h6>
            </div>
            <div className='col-4'>
            <Translation>
                     {
                        t => <span className='float-right date'>{t('settingAds:Created Date') +" "+ format(item.node.added , 'DD/MM/YYYY')}</span>
                     }
            </Translation>
            </div>
            <div className='col-12'>
                <div className={item.node.status === 'NOT_SHOW' ? 'status-off': 'status-on'}>
                    <span>{item.node.status === 'NOT_SHOW' ? 'ไม่แสดง': 'แสดง'}</span>
                </div>
            </div>
            <div className='row col-12 detail'>
                <div className='col-12'>
                    <span>{item.node.detail}</span>
                </div>
            </div>
            <div className='row col-12 detail'>
                <div className='col-12'>
                    <span>Link: {item.node.link}</span>
                </div>
            </div>
            <div className='row col-12'>
                    <img
                        src={item.node.image}
                        alt="advertise-icon"
                        style={{width:'auto', height:350}}
                    />
            </div>
            <div className='row col-12 detail'>
                <div className='col-12'>
                    <span>ชื่อบริษัท: {item.node.customerName}</span>
                </div>
            </div>
        </div>
    )
}

const SettingAdvertiseList = () => {

    return(
        <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent>
                <div className='container-fluid box' id='advertise'>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <h3 className="mb-4">
                                <Link to="/setting">
                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                        alt="back" className="back" />
                                </Link>
                                <Translation>
                                        {
                                            t => <span>{t('MenuName:Ads Setting')}</span>
                                        }
                                </Translation>
                                
                            </h3>
                        </div>
                        <div className='col-12 pr-4'>
                            <Link to="/setting/setting-advertise/create">
                                <button type="button" className="btn btn-create float-right">
                                    <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                    <Translation>
                                        {
                                            t => <span className='ml-2'>{t('settingAds:Create Ads')}</span>
                                        }
                                    </Translation>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <Translation>
                        {
                            t => 
                                <div className='content-inner'>
                                    <Navigation />
                                    <QueryRenderer 
                                        environment={environment}
                                        query={query}
                                        variables={{}}
                                        render = {({error, props}) => {
                                            if (error) {
                                                return <div>{error.message}</div>;
                                            } else if (props) {
                                                return (
                                                    props.allAdvertiseJuristic.edges.map((item,index) => {
                                                        return (
                                                            listAdvertise(item)
                                                        );
                                                    })
                                                )
                                                
                                            }
                                            return <Loading/>
                                        }}
                                    
                                    />


                                </div>
                        }
                    </Translation>
                </div>
            </WrapperContent>
        </Wrapper>
    );



}
export default SettingAdvertiseList