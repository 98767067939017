/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingForeignOwnershipViewQueryVariables = {|
  id: string
|};
export type settingForeignOwnershipViewQueryResponse = {|
  +juristic: ?{|
    +signature: string
  |}
|};
export type settingForeignOwnershipViewQuery = {|
  variables: settingForeignOwnershipViewQueryVariables,
  response: settingForeignOwnershipViewQueryResponse,
|};
*/


/*
query settingForeignOwnershipViewQuery(
  $id: ID!
) {
  juristic(id: $id) {
    signature
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signature",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingForeignOwnershipViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JuristicNode",
        "kind": "LinkedField",
        "name": "juristic",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingForeignOwnershipViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JuristicNode",
        "kind": "LinkedField",
        "name": "juristic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b1e5172196ce15cfafd856221b6fc04",
    "id": null,
    "metadata": {},
    "name": "settingForeignOwnershipViewQuery",
    "operationKind": "query",
    "text": "query settingForeignOwnershipViewQuery(\n  $id: ID!\n) {\n  juristic(id: $id) {\n    signature\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '44b4524148956defeaaea3992ad5f761';

module.exports = node;
