import React, { Component } from 'react'
import Wrapper from '../../../components/wrapper';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import WrapperContent from '../../../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import Navigation from './navigation';
import { Link } from 'react-router-dom';
import CollectionLawFrimSettingForm from './collectionLawFrimSettingForm';

class CollectionLawFrimSetting extends Component {

    render(){
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <Translation>
                        {
                            t => 
                            <div className="container-fluid box" id="setting-collection-wrapper">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/accounting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <Translation>
                                                {
                                                    t => <span>{t("collectionLawFirm:Setting Debt Collection Attorney")}</span>
                                                }
                                            </Translation>
                                        </h3>
                                    </div>
                                </div>
                                <Navigation />
                                <CollectionLawFrimSettingForm 
                                    status={this.props.match.params.status}   
                                />
                            </div>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        );
    }




}
export default CollectionLawFrimSetting;