import React, {Component} from "react";
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

class LightBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {

        let fileName = ""
        let fileName_display = ""
        let frist_index_type = 0
        let last_index_type = 0
        let last_index_filename = 0
        let typeFileName = "Images"

        if(this.props.image){
            fileName = this.props.image
            frist_index_type = fileName.lastIndexOf(".")
            last_index_type = fileName.lastIndexOf("?")
            fileName_display = fileName.substring(0, last_index_type)
            last_index_filename = fileName_display.lastIndexOf("/")
            fileName_display = fileName_display.substring(last_index_filename+1, fileName_display.length-4)
            fileName_display = decodeURIComponent(fileName_display);
            typeFileName = fileName.substring(frist_index_type+1, last_index_type)
            typeFileName = typeFileName.toLowerCase()
        }
        return (
            <div>
                {typeFileName !== 'pdf'&&
                    <img src={this.props.image} alt="light-box"
                        style={{width: this.props.width, height: this.props.height, cursor: 'pointer' ,}}
                        onClick={() => this.setState({isOpen: true})}/>
                    }

                {this.state.isOpen && typeFileName !== 'pdf' &&
                    <Lightbox
                        mainSrc={this.props.image}
                        onCloseRequest={() => this.setState({isOpen: false})}
                    />
                }

                {typeFileName === 'pdf' &&
                    <a href={this.props.image} target="_blank">
                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="Document" style={{ width: '24px', height: '24px' , cursor: 'pointer' }} />
                        <span className='text-blue' >{fileName_display}</span>
                    </a>
                }
            </div>
        );
    }
}

LightBox.defaultProps = {
    width: 100,
    height: 100,
};

export default LightBox