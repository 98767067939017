import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import _ from "lodash"
import $ from "jquery";

const ThaiBaht = require('thai-baht-text');

export default class manageOrder extends Component {

  setPage = () => {
    let itemPerOnePage = 10
    let countPerItem = 25
    let list = []
    let listItemArr = []

    listItemArr = _.chunk(this.props.onePurchaseOrderes.procurementForm.procurementList.edges, countPerItem)

    listItemArr.forEach((item, index , indexlast) => {
      list.push(item)
      if(indexlast.length -1 === index ){
        if(item.length > itemPerOnePage){
          list.push([])
        }
      }
    })

    return list
  }

  isBangkok(code) {
    let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
        10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
        10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
        10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
        10160, 10210, 10310 ];
    if(_.indexOf(bangkokCode, parseInt(code)) >= 0) {
        return true;
    }
    return false;
  }

  getPrefix(type, code) {
    if(type === "district" && this.isBangkok(code)){
        return "แขวง"
    }
    else if(type === "city" && this.isBangkok(code)){
        return "เขต"
    }
    else if(type === "province" && this.isBangkok(code)){
        return ""
    }
    else if(type === "province") {
        return "จ."
    }
    else if(type === "district") {
        return "ต."
    }
    else if(type === "city"){
        return "อ."
    }
  }

  getType = (type) => {

    let valueResult = ""

    switch (type) {
      case "PRODUCT":
        valueResult = "สินค้า"
        break;
      case "SERVICE":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (งานทั่วไป)"
        break;
      case "SERVICE_CONTACT":
        valueResult = "จ้างบริการ / ค่าใช้จ่าย (สัญญาจ้าง)"
        break;
      case "ASSET":
        valueResult = "สินทรัพย์"
        break;
        
      default:
        break;
    }
    return valueResult
  }

  getPreTaxAmount = () => {
    let sumPreTaxAmount = _.sumBy(this.props.onePurchaseOrderes.procurementForm.procurementList.edges, (o) => o.node.preTaxAmount)

    return numberWithComma(sumPreTaxAmount)
  }

  getVat = () => {

    let sumVat = _.sumBy(this.props.onePurchaseOrderes.procurementForm.procurementList.edges, (o) => o.node.preTaxAmount * o.node.vat / 100 )
    let parseFloatSumVate = parseFloat(sumVat).toFixed(2)
    return  numberWithComma(parseFloatSumVate)
  }

  getDiff(indexProcurementList, paper_type){
    setTimeout(() => {
      let page = $('.' + this.props.numberManageOrder +'-'+indexProcurementList+'-'+paper_type);
      let head = page.find('.head').height();
      let detail = page.find('.detail').height();
      let manageOrderBody = page.find('.manageOrder-body table').height();
      let manageOrderBodyBottom = page.find('.manageOrder-body-bottom').height();
      let footer = page.find('.footer').height();
      let page_height = 1125 - 150;
      let diff = 0
      if(head && detail && manageOrderBody && manageOrderBodyBottom && footer){

        diff = page_height - (head + detail + manageOrderBody + manageOrderBodyBottom + footer);
        $('.' + this.props.numberManageOrder + '-' + indexProcurementList + '-' + paper_type + ' .lastRow td').css({paddingBottom: diff});

      }else{
        diff = page_height - (head + detail + manageOrderBody);
        $('.' + this.props.numberManageOrder + '-' + indexProcurementList + '-' + paper_type + ' .lastRow td').css({paddingBottom: diff});

      }
    }, 200)
  }

  render() {

    let data = this.props.onePurchaseOrderes;

    return (
      <React.Fragment>
        {_.map(["original", "copy"], (paper_type, indexPaperType) => {
          let index = 0
          return (
            <React.Fragment key={indexPaperType}>
              {_.map(this.setPage(), (procurementList, indexProcurementList, indexProcurementListLast) => {
                this.getDiff(indexProcurementList, paper_type);
                return (
                  <div className={"print-page-a4 " + this.props.numberManageOrder+'-'+indexProcurementList+'-'+paper_type} id="manageOrder" key={indexProcurementList}>
                    <div className="subpage">

                      <div className="head">
                        <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "2fr 5fr" }}>
                          <div style={{ minHeight: 100, minWidth: 100 }}>
                            <img src={this.props.selfProject.logo} alt="silverman" />
                          </div>
                          <div>
                            <strong>{this.props.selfProject.name}</strong>
                            {
                              this.props.selfProject.id === "UHJvamVjdE5vZGU6NDE2" ?
                                <div>
                                  <Translation>{t => <div className="address-header">{this.nextLine(this.props.selfProject.address)} </div>}</Translation>
                                  <Translation>{t => <div className="address-header">{t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                                </div>
                                :
                                <Translation>{t => <div className="address-header">{this.props.selfProject.address} {t("document:Tel")} {this.props.selfProject.juristicContactNumber || '-'}</div>}</Translation>
                            }
                            <Translation>{t => <div>{t("document:Taxpayer identification number")} {this.props.selfProject.taxIdNumber || '-'}</div>}</Translation>
                          </div>
                        </div>

                        <div className="document-name">
                          <Translation>
                            {t =>
                              <React.Fragment>
                                <div className="title-document-name">ใบสั่งซื้อ</div>
                                <div className="title-document-name">Purchase Order</div>
                              </React.Fragment>
                            }
                          </Translation>
                          <React.Fragment>
                            { 
                              paper_type === "original" 
                              ? <Translation>{t => <div className="title small">({t("document:Original")})</div>}</Translation>
                              : <Translation>{t=><div className="title small">({t("document:Copy")})</div>}</Translation>
                            }
                          </React.Fragment>
                        </div>
                      </div>

                      <div className="detail">
                        <div className="customer-detail">
                          <React.Fragment>
                            <strong className="sub-customer-detail">รหัสเจ้าหนี้/ID:</strong>{data.procurementForm.creditor.refNumber}<br/>
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-customer-detail">ชื่อลูกค้า/Customer:</strong>{data.procurementForm.creditor.name || "-"}<br/>
                          </React.Fragment>
                          <div className="d-flex">
                            <Translation>{t=><strong className="sub-customer-detail">{t("document:Address")}:</strong>}</Translation>
                            <div className="customer-detail">
                              {data.procurementForm.creditor.registeredAddress + " "}
                              {data.procurementForm.creditor.registeredDistrict && this.getPrefix("district", data.procurementForm.creditor.registeredPostalCode) + data.procurementForm.creditor.registeredDistrict + " "}
                              {data.procurementForm.creditor.registeredCity && this.getPrefix("city", data.procurementForm.creditor.registeredPostalCode) + data.procurementForm.creditor.registeredCity + " "}
                              {data.procurementForm.creditor.registeredProvince && this.getPrefix("province", data.procurementForm.creditor.registeredPostalCode) + data.procurementForm.creditor.registeredProvince + " "}
                              {data.procurementForm.creditor.registeredPostalCode + " "}
                              {
                                (data.procurementForm.creditor.typeOfSupplier === "PUBLIC_COMPANY" || data.procurementForm.creditor.typeOfSupplier === "COMPANY") &&
                                <React.Fragment>
                                    {
                                        data.procurementForm.creditor.creditortypeOfCompany === "HEADQUARTERS" ?
                                            "สำนักงานใหญ่"
                                            : data.procurementForm.creditor.typeOfCompany === "BRANCH" &&
                                            "สาขา " + data.procurementForm.creditor.nameBranch
                                    }
                                </React.Fragment>
                              }
                            </div><br/>
                          </div>
                          <React.Fragment>
                            <strong className="sub-customer-detail">เลขประจำตัวผู้เสียภาษี/Tax ID:</strong>{data.procurementForm.creditor.taxIdNumber}<br/>
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-customer-detail">เลขที่สัญญา/เอกสารอ้างอิง/Reference ID: </strong>{data.procurementForm.contractNumber}<br/>
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-customer-detail">วันที่เอกสาร/Document Date:</strong>
                            { data.procurementForm.contractDate && format(data.procurementForm.contractDate, 'DD/MM/YYYY', { locale: thLocale })}<br/>
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-customer-detail">เงื่อนไขการชำระ/Condition:</strong>
                            {data.procurementForm.paymentTerms === "OTHER"
                              ? `อื่นๆ ระบุเอง ${data.procurementForm.otherPaymentTerms}`
                              : `${data.procurementForm.paymentTerms.substring(2)} วันหลังจากวันที่วางบิล`
                            }<br/>
                          </React.Fragment>
                        </div>
                        <div className="document-detail">
                          <div className="d-flex">
                            <strong className="sub-document-detail">ประเภท/Type:</strong>
                            <div className="type-detail">
                              {this.getType(data.procurementForm.type)}
                            </div><br/>
                          </div>
                          <React.Fragment>
                            <strong className="sub-document-detail">เลขที่/No.:</strong>{data.docNumber}<br/>
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-document-detail">อ้างอิง/Reference:</strong>{data.procurementForm.docNumber}<br/>
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-document-detail">วันที่/Date:</strong>
                            {format(data.approveDate, 'DD/MM/YYYY', { locale: thLocale })}<br/>
                          </React.Fragment>
                          <React.Fragment>
                            <strong className="sub-document-detail">ผู้อนุมัติ/Approver:</strong>
                            {data.approver}<br/>
                          </React.Fragment>
                        </div>
                      </div>

                      <div className="manageOrder-body">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="th-dark">
                              <th>ลำดับที่<br/>No.</th>
                              <th>ชื่อรายการ<br/>Name</th>
                              <th>รายละเอียด<br/>Description</th>
                              <th>จำนวน<br/>Quantity</th>
                              <th>ราคาต่อหน่วย<br/>Sale price</th>
                              <th>ราคารวม<br/>Total price</th>
                              <th>งบประมาณ<br/>Funds</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(procurementList, (item, indexItem) => {
                              index +=1
                              let productAndService = item.node.productAndService  ? item.node.productAndService?.name : item.node.chartOfAccount?.name;                              
                              return (
                                <tr key={indexItem}>
                                  <td className="text-center tdBodyNone">{index}</td>
                                  <td className={`tdBodyNone ${productAndService?.length > 18 ? "f10" : ""}`}>
                                    {
                                      productAndService
                                    }
                                  </td>
                                  <td className="tdBodyNone">{item.node.description}</td>
                                  <td className="text-center tdBodyNone">{item.node.unitItems}</td>
                                  <td className="text-center tdBodyNone">{numberWithComma(item.node.price)}</td>
                                  <td className="text-center tdBodyNone">{numberWithComma(item.node.preTaxAmount)}</td>
                                  <td className="text-center tdBodyNone">{numberWithComma(item.node.budget)}</td>
                                </tr>
                              )
                            })}
                            <tr className="lastRow">
                              <td className="lasttd tdBodyTopNone"/>
                              <td className="lasttd tdBodyTopNone"/>
                              <td className="lasttd tdBodyTopNone"/>
                              <td className="lasttd tdBodyTopNone"/>
                              <td className="lasttd tdBodyTopNone"/>
                              <td className="lasttd tdBodyTopNone"/>
                              <td className="lasttd tdBodyTopNone"/>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {indexProcurementListLast.length - 1 === indexProcurementList && 
                        <React.Fragment>
                          <div className="manageOrder-body-bottom">
                            <div className="moreInformation" />
                            
                            <div className="summarizeDetail">
                              <div className="summarizeAmount mb-4">
                                <div className="d-flex justify-content-between">
                                  <div className="itemTitle">จำนวนเงิน</div>
                                  <div className="itemDetail">{this.getPreTaxAmount()}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="itemTitle">ภาษีมูลค่าเพิ่ม</div>
                                  <div className="itemDetail">{this.getVat()}</div>
                                </div>
                              </div>

                              <div className="summarizeTotal">
                                <div className="d-flex justify-content-between">
                                  <div className="summarizeTotalDetail">จำนวนเงินรวมทั้งสิ้น (บาท)</div>
                                  <div className="summarizeTotalDetail">{numberWithComma(data.procurementForm.total)}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="summarizeTotalDetail">{ThaiBaht(data.procurementForm.total)}</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="footer">
                            <div className="row">
                              <div className="col-4">
                                <div className="footerTitle text-center"><strong>ผู้ยื่นอนุมัติ</strong></div>
                                <div className="footerSignatureDot">
                                  ...........................................................<br/>
                                  (..........................................................)
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="footerTitle text-center"><strong>ผู้ตรวจสอบ</strong></div>
                                <div className="footerSignatureDot"> 
                                ...........................................................<br/>
                                  (..........................................................)
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="footerTitle text-center"><strong>ผู้อนุมัติ</strong></div>
                                <div className="footerSignatureDot">
                                  ...........................................................<br/>
                                  (..........................................................)
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      }

                      </div>
                    </div>
                )
              })}
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }
}
