import React from "react";
import SearchSelect from "./searchSelect";
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../../../env/environment";
import './styles/receiveMethod.scss';
import { Translation } from "react-i18next";
import i18next from "i18next";

const _ = require('lodash');
const $ = window.jQuery;

const query = graphql`
    query receiveMethodQuery($makePayment: Boolean, $receivePayment: Boolean) {
        bankAccountViewer {
            allBankAccount(status: true, accountType_In:"current_account, saving_account, saving_deposit, fixed_deposit", makePayment:$makePayment, receivePayment:$receivePayment) {
                edges {
                    node {
                        id
                        docNumber
                        accountNumber
                        accountName
                        accountType
                        receivePayment
                        makePayment
                        bankName
                        status
                    }
                }
                totalCount
            }
        }        
    }
`;


const receive_channel = {
    slug: "",
    // pay: "",
    pay: 0,
    image: false,
    image_slip:"", 
    cheque: {},
    payment_gateway: {},
    ref: "",
    unknown_ref: "",
    date: new Date(),
    type_other_pay: false,
};

const payment_gateway_channel = {
    payment_type: "",
    payment_gateway: "",
    description: "",
    amount: 0,
    fee_amount: 0,
};


class ReceiveMethod extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            receive_channel: [],
            channel_choice: [],
            unknown_receive: [],
            channel_other_choice: [],
            payment_gateway_channel: [],
            bank_list:[],
        };
        this.state.single_payment = props.single_payment ? props.single_payment : false;
        this.state.receivePayment = props.payment_type === 'receive' ? true : null;
        this.state.makePayment = props.payment_type === 'receive' ? null : true;
        this.state.headerText = props.payment_type === 'receive' ? 'Payment details' : 'รายละเอียดการจ่ายเงิน';
        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteChannel = this.deleteChannel.bind(this);
        this.queryBank = this.queryBank.bind(this);
        this.changeDateFromIssuedDate = this.changeDateFromIssuedDate.bind(this);

    }

    componentWillMount() {
        this.queryBank()
    }

    queryBank() {
        if (this.props.page === "clear_advance") {
            this.setState({ headerText: "รายละเอียดการรับเงินคืน" })
        }
        fetchQuery(environment, query, { makePayment: this.state.makePayment, receivePayment: this.state.receivePayment })
            .then(response_data => {
                let bank_list = [];
                if (_.isObject(response_data.bankAccountViewer)) {
                    let query_bank_data = _.cloneDeep(response_data);
                    query_bank_data.bankAccountViewer.allBankAccount.edges.forEach((bank) => {
                        _.set(bank, 'node.slug', 'bank:' + bank.node.id);
                        _.set(bank, 'node.type', 'bank');
                        let account_type = 'ออมทรัพย์';
                        if (bank.node.accountType === 'CURRENT_ACCOUNT') {
                            account_type = 'กระแสรายวัน'
                        } else if (bank.node.accountType === 'FIXED_DEPOSIT') {
                            account_type = 'ฝากประจำ'
                        }
                        _.set(bank, 'node.account_type', account_type);
                    });
                    bank_list = query_bank_data.bankAccountViewer.allBankAccount.edges;
                }
                let update_channel_choice = [];
                let channel_other_choice = [];
                update_channel_choice = [...bank_list];
                let receive_channel_object = { ...receive_channel };
                receive_channel_object.pay = this.props.total || "";

                this.setState(
                    {
                        receive_channel: [receive_channel_object],
                        payment_gateway_channel: [{ ...payment_gateway_channel }],
                        channel_choice: update_channel_choice,
                        channel_other_choice: channel_other_choice,
                        bank_list:bank_list
                    })
            });


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.payment_type !== this.props.payment_type) {
            if (this.props.payment_type === 'receive') {
                this.setState({ receivePayment: true, makePayment: null, headerText: i18next.t("TransactionChannel:Details of Receipt") })
            } else {
                this.setState({ receivePayment: null, makePayment: true, headerText: i18next.t("TransactionChannel:Details of Payment") })
            }

            this.queryBank()
        }

        // กรณีเป็นลูกหนี้ให้เอา รับล่วงหน้า กับ รับเงินรอการตรวจสอบ ออก
        if (prevProps.typeSupplier !== this.props.typeSupplier) {
            this.queryBank()
        }
        if (prevProps.issued_date !== this.props.issued_date) {
            this.changeDateFromIssuedDate('date')
        }
        if (prevProps.total !== this.props.total) {
            this.changeDateFromIssuedDate('pay')
        }
    }

    changeDateFromIssuedDate(name) {
        let channel_list = _.cloneDeep(this.state.receive_channel);
        let value = '';
        if (name === 'date') {
            if (this.props.page === "clear_advance" && channel_list.length > 0) {
                value = channel_list[0].date || this.props.issued_date
            } else {
                value = this.props.issued_date
            }
        } else {
            value = parseFloat(this.props.total)
        }
        channel_list.forEach((channel, index) => {
            if ((name === 'pay' && index === 0) || name === 'date') {
                channel[name] = value;
            } else {
                if (name === 'pay') {
                    channel[name] = 0;
                }
            }
            if (channel['slug'] !== "" && this.props.page === "other_receiver") {
                channel['slug'] = ""
            }

        });
        this.setState({ receive_channel: channel_list });
        if (name === 'pay') {
            this.props.updateReceiveChannel(channel_list);
        }
    }

    deleteChannel(index) {
        let channel = _.cloneDeep(this.state.receive_channel);
        channel.splice(index, 1);
        if (channel.length > 0) {
            this.setState({ receive_channel: channel }, () => {
                this.props.updateReceiveChannel(this.state.receive_channel);
            });
        }
    }

    handleInputChange(e) {
        let event = null;
        try {
            event = _.cloneDeep(e);
        } catch (error) {
            event = _.clone(e);
        }

        let bankId = event.target.value.split(':')[1];
        this.props.changeBankId(bankId)

    }


    render() {        
        return (
            <Translation>
                {t =>
                    <React.Fragment>

                        {this.state.receive_channel.map((payment_channel, index) =>
                            <div className="card fade-up mb-3" key={index}>
                                <div className="card-body">
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <h6>
                                                {
                                                    !this.props.single_payment && <span>{t('createReceive:Method')} {index + 1}</span>
                                                }

                                                {index !== 0 &&
                                                    <button className="btn btn-danger btn-sm ml-1" type="button"
                                                        onClick={() => this.deleteChannel(index)}>
                                                        {t('createReceive:Delete')}
                                                    </button>
                                                }
                                            </h6>
                                        </div>

                                        <div className="col-4">
                                            <label>{t('createReceive:Received by')}</label>                                            
                                            <SearchSelect onChange={this.handleInputChange}
                                                value={this.state.receive_channel[index].slug}
                                                name={"receive_channel[" + index + "].slug"} placeholder="รายการ"
                                                queryObject={payment_channel.type_other_pay ? this.state.channel_other_choice : this.state.channel_choice}
                                                disabled={payment_channel.type_other_pay ? "disabled" : false}
                                                keyOfValue="slug" require={true}
                                                keyOfLabel="bankName:account_type:accountName:accountNumber"
                                                defaultBankId={this.props.changeBankId}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                }
            </Translation>
        )
    }
}

export default ReceiveMethod;