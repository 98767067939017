import {
    Environment,
    Network,
    RecordSource,
    Store,
} from 'relay-runtime';
import getAPIRoot from '../libs/getAPIRoot';
import jwtDecode from "jwt-decode";
import RelayQueryResponseCache from 'relay-runtime/lib/network/RelayQueryResponseCache';
import API from '../api/invoiceEmail'

function isTokenExpire(token, hour=2) {
    if(token.exp*1000 - Date.now()  < 1000*60*60*hour) {
        return true;
    } else {
        return false;
    }
}


function chkPathname(){
    let pathname = window?.location?.pathname
    let dataSplit = pathname?.split("/")
    return dataSplit[1] || null
}

 async function getTokenEmail (){
    let access_token =  await API.getToken()
    return access_token.data.access_token
}



function isEndpointChange(isQuery, queryID) {
    let graphqlEndpoint = 'graphql'

    if(chkPathname() === 'accounting'){
        graphqlEndpoint = 'graphql/account'
    }

    if (isQuery) {

        //report
        if(queryID.startsWith("receiveReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("contactReportListQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("invoiceReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("receivableOutstandingReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("receivableOutstandingSummaryReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("receivableClassifiedReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("creditNoteReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("guaranteeMoneyReceivedReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("guaranteeMoneyReceiveMovementReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("receiptDepositReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("receiptDepositMovementReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("otherReceiveReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("dailyPaymentReceiptReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("historyQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("purchaseReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("paySummaryReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("payableOutstandingReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("payableOutstandingSummaryReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("payableClassifiedReportProductTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("payableClassifiedReportChartTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("payableMovementQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("acceptCreditNoteReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("prepaidDepositReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("flowOfPrepaidDepositReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("pettyCashReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("advanceReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("otherExpenseReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }

        else if(queryID.startsWith("wrapperBankDepositReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("interestTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("chartOfAccountAllQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("generalLedgerTableBetweenChartOfAccountQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("generalLedgerTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("trialBalanceListQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("wrapperWorkSheetQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("balanceSheetAccountQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("balanceSheetAccountStatementsQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("balanceSheetQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("incomeTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("expenseTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("differentTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("revenueTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("expenditureTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("receiveListTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("payListTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("budgetReportListQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("budgetUsageSummaryReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("monthlyBudgetUsageReportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("monthlyBudgetUsageReportSummaryQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("reportRegisterListQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("generalLedgerTableQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("generalLedgerTableBetweenChartOfAccountQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("libcurrentRecordQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("generalLedgerChartQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("chartSelectQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("workSheetQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        else if(queryID.startsWith("workSheetExcelExportQuery", 6)) {
            graphqlEndpoint = 'graphql/report'
        }
        
        //parcel
        else if(queryID.startsWith("parcelListQuery", 6)) {
            graphqlEndpoint = 'graphql/parcel'
        }
        else if(queryID.startsWith("createParcelQuery", 6)) {
            graphqlEndpoint = 'graphql/parcel'
        }
        else if(queryID.startsWith("createParcelMutation", 9)) {
            graphqlEndpoint = 'graphql/parcel'
        }
        else if(queryID.startsWith("CreateParcelNotiMutation", 9)) {
            graphqlEndpoint = 'graphql/parcel'
        }
        else if(queryID.startsWith("UpdateParcelMutation", 9)) {
            graphqlEndpoint = 'graphql/parcel'
        }

        //utility
        else if(queryID.startsWith("checkSiteOnlineQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("profileQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("navigationQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("notificationQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("addonTopMenuNavigationQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("purchasingTopMenuNavigationQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("stockTopMenuNavigationQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("contactTopMenuNavigationQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("sendDocTopMenuNavigationQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("getAdvertiseQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("bankAccountQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }
        else if(queryID.startsWith("accountingTopMenuNavigationQuery", 6)) { //โนติ
            graphqlEndpoint = 'graphql/mobiletenant' //ก่อนหน้านี้ยิงเข้า utility ทดสอบมายิงเข้า mobiletenant แทน
        }
        else if(queryID.startsWith("settingTopMenuNavigationsQuery", 6)) {
            graphqlEndpoint = 'graphql/utility'
        }

        //dashboard
        else if(queryID.startsWith("dashboardQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("incomeGraphQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("parcelReportQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("ownerChangeQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("wrapperQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("toggleMenuSwitchQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("changeProjectQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("searchMenuQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("switchLanguagesSwitchQuery", 6)) {
            graphqlEndpoint = 'graphql/dashboard'
        }
        else if(queryID.startsWith("loginQuery", 6)) { // Login Web
            graphqlEndpoint = 'graphql/dashboard'
        }

        //invoice
        else if(queryID.startsWith("invoiceListAllQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("invoiceListTableQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("invoiceQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("wrapperInvoiceAllQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("invoiceFormQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("invoiceCreateUpdateFormQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("invoiceSummaryQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("invoiceDetailQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("wrapperInvoiceQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("wrapperInvoiceAllQuery", 6)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("invoiceListAllMutation", 9)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("ChangeStatusInvoiceMutation", 9)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("invoiceCreateUpdateFormMutation", 9)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("CreatePreinvoiceMutation", 9)) {
            graphqlEndpoint = 'graphql/invoice'
        }
        else if(queryID.startsWith("CreateEditorDocumentLogMutation", 9)) {
            graphqlEndpoint = 'graphql/invoice'
        } 
  

        //receive
        else if(queryID.startsWith("navigationReceiveQuery", 6)) {
            graphqlEndpoint = 'graphql/receive'
        }
        else if(queryID.startsWith("receiveListQuery", 6)) {
            graphqlEndpoint = 'graphql/receive'
        }
        else if(queryID.startsWith("wrapperReceiveAllQuery", 6)) {
            graphqlEndpoint = 'graphql/receive'
        }
        else if(queryID.startsWith("wrapperReceiveQuery", 6)) {
            graphqlEndpoint = 'graphql/receive'
        }
        else if(queryID.startsWith("receiveDetailQuery", 6)) {
            graphqlEndpoint = 'graphql/receive'
        }
        else if(queryID.startsWith("payChannelQuery", 6)) {
            graphqlEndpoint = 'graphql/receive'
        }
        else if(queryID.startsWith("navigationReceiveQuery", 6)) {
            graphqlEndpoint = 'graphql/receive'
        }
        else if(queryID.startsWith("receiveListTableMutation", 9)) {
            graphqlEndpoint = 'graphql/receive'
        }
        else if(queryID.startsWith("receiveDetailMutation", 9)) {
            graphqlEndpoint = 'graphql/receive'
        }

        //Account
        else if(queryID.startsWith("selectContactQuery", 6)) {
            graphqlEndpoint = 'graphql/account'
        }
        else if(queryID.startsWith("periodAdjustSettingQuery", 6)) {
            graphqlEndpoint = 'graphql/account'
        }
        else if(queryID.startsWith("waterMeterSelectDateQuery", 6)) {
            graphqlEndpoint = 'graphql/account'
        }
        else if(queryID.startsWith("individualAreaPriceSettingQuery", 6)) {
            graphqlEndpoint = 'graphql/account'
        }
        else if(queryID.startsWith("carParkSelectQuery", 6)) {
            graphqlEndpoint = 'graphql/account'
        }
        else if(queryID.startsWith("CreateEditorDocumentLogMutation", 6)) {
            graphqlEndpoint = 'graphql/account'
        }

    }
    return graphqlEndpoint
}


// cache time
const oneMonth = 60 * 1000 * 60 * 24 * 30;
const cache = new RelayQueryResponseCache({size: 250, ttl: oneMonth});

async function fetchQuery(
    operation,
    variables,
    cacheConfig,
    uploadables,
) {
    const queryID = operation.text;
    const isMutation = operation.operationKind === 'mutation';
    const isQuery = operation.operationKind === 'query';
    const fromCache = cache.get(queryID, variables);

    if (
        isQuery &&
        fromCache !== null &&
        cacheConfig.use_cache
    ) {
        return fromCache;
    }

    // check token
    let token = window.localStorage.getItem('token');
    if(token && (token !== 'undefined')) {
        let token_decode = jwtDecode(token);
        // if (Date.now() / 1000 > token_decode.exp) {
        //     window.location = '/login';
        // }
        if(isTokenExpire(token_decode, 6)) {
            let token_request = {
                method: 'POST',
                headers: {
                    authorization: token ? `JWT ${token}` : "",
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: 'token=' + token
            };
            fetch(getAPIRoot() + 'token/refresh/', token_request).then(response => {
                return response.json();
            }).then(json => {
                window.localStorage.setItem('token', json['token']);
            }).then( () => window.location = '/login' );
        }
    }


    const request = {
        method: 'POST',
        headers: {
            authorization: token ? `JWT ${token}` : "",
        },
    };

    if (uploadables) {
        if (!window.FormData) {
            throw new Error('Uploading files without `FormData` not supported.');
        }

        const formData = new FormData();
        formData.append('query', operation.text);
        formData.append('variables', JSON.stringify(variables));

        Object.keys(uploadables).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
                formData.append(key, uploadables[key]);
            }
        });
        request.body = formData;
    } else {
        request.headers['Content-Type'] = 'application/json';
        
         if(queryID.startsWith("wrapperInvoiceEmailQuery", 6) || queryID.startsWith("wrapperCollectionLetterEmailQuery", 6)) {
           let tokenEmail = await getTokenEmail()
           request.headers['authorization'] = `Bearer ${tokenEmail}`
         } 
        request.body = JSON.stringify({
            query: operation.text,
            variables,
        });
    }
    
    let graphqlEndpoint = 'graphql'

    if(isQuery){
        graphqlEndpoint = isEndpointChange(isQuery, queryID)
    }
    
    if(isMutation){
        graphqlEndpoint = isEndpointChange(isMutation, queryID)
    }

    return fetch(getAPIRoot() + graphqlEndpoint, request).then(response => {
        return response.json();
    }).then(json => {
        if (isQuery && json) {
            cache.set(queryID, variables, json);
        }
        // Clear cache on mutations
        if (isMutation) {
            cache.clear();
        }
        return json;
    });
}

const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
});

export default environment;
