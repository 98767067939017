import i18next from 'i18next';
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <ul>
                        <li>
                            <NavLink to="/accounting/expense/petty_cash/list/set">
                                {i18next.t("pettyCashList:Petty cash lists")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/petty_cash/list/record">
                                {i18next.t("pettyCashList:Petty cash payment")}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/accounting/expense/petty_cash/list/payment">
                                {i18next.t("pettyCashList:Petty cash reimbursement")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation;