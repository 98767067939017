// import '../../report.scss';
import React from 'react';
import _ from "lodash";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
import numberWithCommaReport from '../../libs/numberWithCommaReport'
require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

const ExportKeyCardExcel = ({ state }) => {
    const getKeyCardName = (dataKey) => {
        let text = ""
        dataKey.map((keyeCard) => {
            text = keyeCard.node.keyCardActive ? text + " " + keyeCard.node.keyCardCode : text
        })
        return text;
    }


    const getData = () => downloadExcel()
    const downloadExcel = async (data) => {
        let workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet('total receipt');
        let columns = [{ width: 10 }, { width: 20 }, { width: 50 }]
        // setBorder
        let borders = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        // let fontSizes = { size: 11.5 }
        let textCenter = { vertical: 'middle', horizontal: 'center' };
        let textRight = { vertical: 'middle', horizontal: 'right' };
        let textLeft = { vertical: 'middle', horizontal: 'left' };
        let fontBold = { size: 11.5, bold: true };

        //textHeader 
        worksheet.getCell('A1').value = state.project_name
        worksheet.getCell('A1').font = { size: 11.5, bold: true };
        worksheet.getCell('A2').value = "สรุปรายการคีย์การ์ด"
        worksheet.getCell('A2').font = { size: 11.5, bold: true };
        worksheet.getCell('A3').value = `${format(new Date(), " DD MMMM YYYY", { locale: thLocale, })}`
        worksheet.getCell('A3').font = { size: 11.5, bold: true };

        // //table
        let headerRow, cell, last_index = 0
        let month = state.list_month
        // //header
        worksheet.mergeCells("A5", "A6");
        headerRow = worksheet.getRow(5);
        cell = headerRow.getCell(1);
        cell.value = 'ลำดับ';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        worksheet.mergeCells("B5", "B6");
        cell = headerRow.getCell(2);
        cell.value = 'บ้านเลขที่/ห้องเลขที่';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        worksheet.mergeCells("C5", "C6");
        headerRow = worksheet.getRow(5);
        cell = headerRow.getCell(3);
        cell.value = 'รายการเลขคีย์การ์ด';
        cell.border = borders
        cell.font = fontBold
        cell.alignment = textCenter

        _.map(state.allResidentialKeyCard, (item, index_code) => {
            last_index++

            var dataRow = worksheet.addRow();
            cell = dataRow.getCell(1);
            cell.value = last_index;
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textCenter

            cell = dataRow.getCell(2);
            cell.value = item.node.name;
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textCenter

            cell = dataRow.getCell(3);
            cell.value = getKeyCardName(item.node.keyCard.edges)
            cell.border = borders
            cell.font = fontBold
            cell.alignment = textLeft
        })

        worksheet.columns = columns;

        workbook.xlsx.writeBuffer()
            .then((data) => {
                const blob = new Blob([data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                let nameFile = 'Key Card Report'
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = nameFile;
                a.click();
            });
    }
    return (
        <Dropdown.Item  ><div className="text-black" onClick={getData} style={{ display: 'flex', justifyItems: 'center' }} >Excel</div></Dropdown.Item>
    );
};

export default ExportKeyCardExcel;