import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from 'i18next'
import '../styles/register.scss'

import Navigation from "./navigation";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { format, parse } from "date-fns";
import { Redirect } from 'react-router';
import SendEmailResetPasswordMutation from "./mutations/SendEmailResetPassword"

import { commitMutation } from "react-relay";
import Swal from "sweetalert2";

import upload from '../../libs/upload'
import DatePicker from "react-datepicker";
import jwtDecode from 'jwt-decode'
import changeName from '../../libs/changeName'
import checkIdCardNumber from './query/checkIdCardNumber'
import i18next from 'i18next';

var time;

const query = graphql`
    query createUpdateOwnerDetailQuery($id:ID!, $residential_id: ID!){
        residential(id: $residential_id){
            name
        }
        houseHolder(id:$id){
            type
            detailTenantPerResidential{
                edges 
                { 
                    node { 
                        id
                        dateIn
                        remark
                    } 
                }
            }

            active
            tenant{
                id
                dateIn
                dateOut
                note
                image
                nameTitle
                firstName
                lastName
                firstNameEng
                lastNameEng
                occupation
                tenantStatus
                countries
                ethnicity
                dateOfBirth
                gender
                idCardNumber
                cardType
                phone
                phone2
                email

                householder { edges { node { id } } }
                verifyIdentityDocument{ edges { node { id fileName fileUpload } } }
                passport{ edges { node { id fileName fileUpload } } }
                lease{ edges { node { id fileName fileUpload } } }
                rentOwnerLease{ edges { node { id fileName fileUpload } } }
                dailyForm{ edges { node { id fileName fileUpload } } }

                acceptPolicy
                acceptTermOfUse

            }
        }
    }

`;

const mutation = graphql`
 mutation createUpdateOwnerDetailMutation($input: CreateAndUpdateHouseHolderAndTenantInput!){
      createAndUpdateHouseHolderAndTenant(input: $input){
        ok
      }
    }
`;

const owner_object = {
    id: "",
    firstName: "",
    lastName: "",
    firstNameEng: "",
    lastNameEng: "",
    idCardNumber: "",
    cardType: "unspecified",
    occupation: "",
    dateOfBirth: "",
    gender: "",
    email: "",
    countries: "TH",
    phone: "",
    nameTitle: "",
    nameTitleSelect: "",
    tenantStatus: "unspecified",
    ethnicity: 'TH',
    user: {
        id: "",
        username: ""
    },
    filepdf: [],
    phone2: '',
    //New Add
    verifyIdentityDocument: [],
    passport: [],
    lease: [],
    rentOwnerLease: [],
    dailyForm: [],
    dateIn: new Date(),
     //New Add 6 jan 23
};

const name_title_list = ["นาย", "นาง", "นางสาว", "เด็กหญิง", "เด็กชาย", "Miss", "Mrs.", "Mr.", "Master", "บริษัท", "คุณ"];
const name_title_listJson = require('../../api/Json/listTitleName.json');
const country_nationality_list = require('../../api/Json/countryNationalityList.json');
const _ = require('lodash');


class CreateUpdateOwnerDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            residential_name: "",
            active: false,
            owner_object: owner_object,
            type_resident: 'owner',
            country_list: [],
            image: '',
            image_upload: '',
            redirectToList: false,
            loading: false,
            isFirstName: false, //เช็คว่ามีข้อมูลชื่อจากในระบบหรือไม่มี ถ้ามีจะ disable input
            isLastName: false,
            isFirstNameEng: false, //เช็คว่ามีข้อมูลชื่อจากในระบบหรือไม่มี ถ้ามีจะ disable input
            isLastNameEng: false,
            idCardNumber: false,
            idCardNumberEdit: false,
            isAgent: false,
            remark:''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputImage = this.handleInputImage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClickResetPassword = this.onClickResetPassword.bind(this);
        this.uploadFile = this.uploadFile.bind(this)
        this.dateFile = this.dateFile.bind(this)
    }

    componentWillMount() {
        // let country_list = require('country-list').getData()
        let country_nationality = require('../../api/Json/countryNationalityList.json');
        country_nationality = country_nationality.sort((a,b) => (a.nationality.toLowerCase() < b.nationality.toLowerCase()) ? -1 : ((b.nationality.toLowerCase() > a.nationality.toLowerCase()) ? 1 : 0));
        let country_listmore = { alpha_2_code: 'other', nationality: "ระบุเอง" ,en_short_name:'' }
        this.setState({ country_list: [...country_nationality, country_listmore] });


        fetchQuery(environment, query, { id: this.props.match.params.owner_id || "", residential_id: this.props.match.params.id }).then(data => {
   
            if (_.isObject(data.houseHolder)) {
                let data_set = _.cloneDeep(data.houseHolder.tenant);
                let data_set_remark = ""

                if(data.houseHolder?.detailTenantPerResidential?.edges.length > 0){
                    data_set_remark =  _.cloneDeep(data.houseHolder?.detailTenantPerResidential?.edges[0].node.remark)
                }

                if (!_.isNull(_.get(data_set, 'dateOfBirth'))) {
                    _.set(data_set, 'dateOfBirth', parse(_.get(data_set, 'dateOfBirth')));
                }
                if (!_.isNull(_.get(data_set, 'cardType'))) {
                    if (_.get(data_set, 'cardType') === 'GOVERNMENT_OFFICER') {
                        _.set(data_set, 'cardType', 'government_officer');
                    } else {
                        _.set(data_set, 'cardType', _.get(data_set, 'cardType') === null ? 'unspecified' : _.get(data_set, 'cardType').toLowerCase());
                    }
                } else {
                    _.set(data_set, 'cardType', 'unspecified');
                }

                if (!_.isNull(_.get(data_set, 'tenantStatus'))) {
                    _.set(data_set, 'tenantStatus', _.get(data_set, 'tenantStatus') === null ? 'unspecified' : _.lowerCase(_.get(data_set, 'tenantStatus')));
                } else {
                    _.set(data_set, 'tenantStatus', 'unspecified');
                }

                if (_.isNull(_.get(data_set, 'nameTitle'))) {
                    _.set(data_set, 'nameTitle', "");
                    _.set(data_set, 'nameTitleSelect', "");

                } else if (name_title_list.some(e => e === _.get(data_set, 'nameTitle'))) {
                    _.set(data_set, 'nameTitleSelect', _.get(data_set, 'nameTitle'));

                } else {
                    _.set(data_set, 'nameTitleSelect', "other_input");
                }


                if (!_.isNull(_.get(data_set, 'firstName'))) {
                    this.setState({ isFirstName: true });
                }
                if (!_.isNull(_.get(data_set, 'lastName'))) {
                    this.setState({ isLastName: true });
                }

                if (_.isNull(_.get(data_set, 'firstNameEng')) || _.isEmpty(_.get(data_set, 'firstNameEng'))) {
                    this.setState({ isFirstNameEng: false });
                  } else {
                    this.setState({ isFirstNameEng: true });
                  }

                if (_.isNull(_.get(data_set, 'lastNameEng')) || _.isEmpty(_.get(data_set, 'lastNameEng'))) {
                    this.setState({ isLastNameEng: false });
                  } else {
                    this.setState({ isLastNameEng: true });
                  }

                //เอา edges , node ออก

                let verifyIdentityDocument = _.get(data_set, 'verifyIdentityDocument.edges') ? _.get(data_set, 'verifyIdentityDocument.edges') : []
                let passport = _.get(data_set, 'passport.edges') ? _.get(data_set, 'passport.edges') : []
                let lease = _.get(data_set, 'lease.edges') ? _.get(data_set, 'lease.edges') : []
                let rentOwnerLease = _.get(data_set, 'rentOwnerLease.edges') ? _.get(data_set, 'rentOwnerLease.edges') : []
                let dailyForm = _.get(data_set, 'dailyForm.edges') ? _.get(data_set, 'dailyForm.edges') : []

                let verifyIdentityDocumentArray = []
                let passportArray = []
                let leaseArray = []
                let rentOwnerLeaseArray = []
                let dailyFormArray = []

                verifyIdentityDocument.forEach((element) => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    verifyIdentityDocumentArray.push(objects)
                });
                passport.forEach(element => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    passportArray.push(objects)
                });
                lease.forEach(element => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    leaseArray.push(objects)
                });
                rentOwnerLease.forEach(element => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    rentOwnerLeaseArray.push(objects)
                });
                dailyForm.forEach(element => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    dailyFormArray.push(objects)
                });

                _.set(data_set, 'verifyIdentityDocument', verifyIdentityDocumentArray);
                _.set(data_set, 'passport', passportArray);
                _.set(data_set, 'lease', leaseArray);
                _.set(data_set, 'rentOwnerLease', rentOwnerLeaseArray);
                _.set(data_set, 'dailyForm', dailyFormArray);

                this.setState({
                    active: data.houseHolder.active,
                    owner_object: data_set,
                    type_resident: data.houseHolder.type.toLowerCase(),
                    image: _.get(data_set, 'image'),
                    remark: data_set_remark
                });
            }
            if (_.isObject(data.residential)) {
                this.setState({ residential_name: data.residential.name })
            }
        });
    }

    async handleInputChange(e) {

        let event = _.cloneDeep(e);
        let name = event.target.name;
        let value = event.target.value;

        if (event.target.type === 'radio') {
            name = 'type_resident';
            value = event.target.name
        }
       

        if (name === 'owner_object.dateOfBirth') {
            value = new Date(value.getTime() - (value.getTimezoneOffset() * 60000))
        }

        if (name === "owner_object.idCardNumber" && value.length >= 7) {
            clearTimeout(time);
            time = setTimeout(async () => {
                await checkIdCardNumber(value).then(res => {
                    this.setState({ idCardNumber: res?.checkIdCardNumber?.result, idCardNumberEdit: true })
                }).catch(err => {
                    console.log('err ', err);
                })
            }, 500);
        }

        this.setState(prevState => {
            if(name === "type_resident"){
                if(value === "agent"){
                    _.set(prevState, "isAgent", true)
                }else{
                    _.set(prevState, "isAgent", false)
                }
            }
            if (name === "owner_object.nameTitleSelect") {
                if (value !== "other_input") {
                    _.set(prevState, "owner_object.nameTitle", value)
                }
                if (value === "บริษัท") {
                    _.set(prevState, "owner_object.lastName", "")
                    _.set(prevState, "owner_object.tenantStatus", "unspecified")
                    _.set(prevState, "owner_object.cardType", "unspecified")
                }
            }
           

            return _.set(
                _.cloneDeep(prevState),
                name,
                value
            );
        });
    }

    handleInputImage(e) {
        if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
            this.setState({
                image_upload: changeName(e.currentTarget.files[0]),
                image: URL.createObjectURL(e.target.files[0])
            });
        }
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/ownerDetail/data" + path, file);
            resolve(res);
        });
    };

    async onSubmit(e) {
        e.preventDefault();
        // if (this.validate()) {
            this.setState({ loading: true });
            let houseHolderDetail = {
                houseHolderID: this.props.match.params.owner_id ? this.props.match.params.owner_id : "",
                residentialID: this.props.match.params.id,
                type: this.state.type_resident,
            };

            let image = ''
            if (this.state.image_upload !== '') {
                await this.callUpload('', this.state.image_upload).then((data) => {
                    image = data.key.replace("silverman-storage/private/", "");
                });
            }

            let obj = this.state.owner_object

            let tenantObject = {
                tenantID: obj.id,
                image: image,
                nameTitle: obj.nameTitle,
                firstName: obj.firstName,
                lastName: obj.lastName,
                firstNameEng: obj.firstNameEng,
                lastNameEng: obj.lastNameEng,
                occupation: obj.occupation,
                tenantStatus: obj.tenantStatus,
                countries: obj.countries === "other" ? obj.countriesOther : obj.countries,
                ethnicity: obj.ethnicity === "other" ? obj.ethnicityOther : obj.ethnicity,
                dateOfBirth: obj.dateOfBirth !== '' ? format(obj.dateOfBirth, 'YYYY-MM-DD') : '',
                gender: obj.gender,
                idCardNumber: obj.idCardNumber,
                cardType: obj.cardType,
                phone: obj.phone,
                phone2: obj.phone2,
                email: obj.email,
                filepdf: [],

                verifyIdentityDocument: obj.verifyIdentityDocument,
                passport: obj.passport,
                lease: obj.lease,
                rentOwnerLease: obj.rentOwnerLease,
                dailyForm: obj.dailyForm,

                dateIn: format(obj.dateIn, 'YYYY-MM-DD'),
                remark: this.state.remark
            }

            let variables = {
                input: {
                    houseHolderDetail: JSON.stringify(houseHolderDetail),
                    tenantObject: JSON.stringify(tenantObject),
                }
            };

            const uploadables = { image_owner: this.state.image_upload };
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    uploadables,
                    onCompleted: (response) => {
                        this.setState({ loading: false });
                        if (response.createAndUpdateHouseHolderAndTenant.ok) {
                            Swal.fire(i18n.t("resident:Save successfully!"), '', 'success').then(() => {
                                this.setState({ redirectToList: true });
                            });
                        } else {
                            Swal.fire(i18n.t("resident:Save unsuccessfully!"), '', 'warning');
                        }

                    },
                    onError: (err) => {
                        this.setState({ loading: false });
                        Swal.fire('Error!', i18n.t("resident:Please try again"), 'warning')
                    },
                },
            );

        // }
    }

    validate = () => {
        if (this.state.idCardNumberEdit && this.state.idCardNumber === false) {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), 'เลขบัตรประชาชนซ้ำ', 'warning')
            return false
        }
        return true
    }


    onClickResetPassword(id_user, email) {

        Swal.fire({
            title: i18n.t("resident:Are you sure to reset this password?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t("resident:Yes"),
            cancelButtonText: i18n.t("resident:Cancel")
        }).then((result) => {
            if (result.value) {
                let variables = {
                    input: {
                        idUser: id_user,
                        email: email,
                    }
                };
                SendEmailResetPasswordMutation(variables, this.onSendEmailSuccess, this.onSendEmailError)
            }
        })

    }

    onSendEmailSuccess() {
        Swal.fire(i18n.t("resident:Send an email to reset your password successfully!"), '', 'success');

    }

    onSendEmailError() {
        Swal.fire(i18n.t("resident:Send an email to reset your password unsuccessfully!"), '', 'warning');

    }

    handleChangeTenantStatus = (e) => {
        let value = e.target.value;
        if (this.state.owner_object?.nameTitleSelect === 'บริษัท') {
            value = ''
        }
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'owner_object.tenantStatus',
                value
            );
        });
    }

    handleChangeCardType = (e) => {
        let value = e.target.value
        if (this.state.owner_object?.nameTitleSelect === 'บริษัท') {
            value = 'other'
        }
        this.setState(prevState => {
            return _.set(
                _.cloneDeep(prevState),
                'owner_object.cardType',
                value
            );
        });
    }

    // callUpload = async (path, file) => {
    //     return new Promise(async (resolve, reject) => {
    //         let res = await upload("/groupChat" + path, file);
    //         resolve(res);
    //     });
    // };

    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        let owner_object = _.cloneDeep(this.state.owner_object)
        if (files) {
            if (files?.size > 10485760) { //2097152
                Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("Allaction:Please upload a different file because it exceeds the size limit."), 'error')
            }

            else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
                Swal.fire(i18next.t("Allaction:Unsuccessful. Uploaded the wrong file type."), 'กรุณาอัพโหลดไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 10 MB (สูงสุด 3 รูป)', 'error')
            }

            else {
                // {Math.floor(Math.random() * 1000000001)}
                let user = jwtDecode(localStorage.getItem("token"))
                let typeFile = files.type.split("/")
                let new_file = new File([files], `${user.user_id}-${Math.floor(Math.random() * 1000000001)}-${format(new Date(), "YYYYMMDDHHmmss")}.${typeFile[1]}`);
                let fileOldList = []
                let fileNewList = []

                if (event.target.name === "verifyIdentityDocument") {
                    fileOldList = owner_object.verifyIdentityDocument
                } else if (event.target.name === "lease") {
                    fileOldList = owner_object.lease
                } else if (event.target.name === "rentOwnerLease") {
                    fileOldList = owner_object.rentOwnerLease
                } else if (event.target.name === "passport") {
                    fileOldList = owner_object.passport
                }

                //Check Status Delete
                let fileOldList_nodelte = _.filter(fileOldList, (o) => o.status !== "delete")

                if (fileOldList_nodelte.length < 3) {
                    // //Set Old file to delete
                    fileOldList.forEach(data => {
                        // if(data.id !== ''){
                        //     let objects = {
                        //         id: data.id,
                        //         fileName: data.fileName,
                        //         fileUpload: data.fileUpload,
                        //         status: "",
                        //     }
                        //     fileNewList.push(objects)
                        // }

                        let objects = {
                            id: data.id,
                            fileName: data.fileName,
                            fileUpload: data.fileUpload,
                            status: data.status,
                        }
                        fileNewList.push(objects)
                    })


                    this.callUpload('', new_file).then((data) => {
                        let dese = "ownerDetail/data/" + new_file.name
                        let dataFile = {
                            "id": "",
                            "fileName": new_file.name,
                            "fileUpload": dese,
                            "viewPtath": data.location,
                            "status": "",
                        }
                        fileNewList.push(dataFile)
                        _.set(owner_object, event.target.name, fileNewList)
                        this.setState({ owner_object: owner_object })
                    });
                } else {
                    Swal.fire(i18next.t("Allaction:Unsuccessful "), 'อัพโหลดไฟล์ไม่ได้เกิน 3 ไฟล์', 'error')
                }
            }
        }
    }

    dateFile(index, data, name) {
        let owner_object = _.cloneDeep(this.state.owner_object)

        if (data.id !== '') {
            let objects = {
                id: data.id,
                fileName: data.fileName,
                fileUpload: data.fileUpload,
                status: "delete",
            }
            _.set(owner_object[name], index, objects)
        } else {
            owner_object[name].pop(index)
        }

        this.setState({ owner_object: owner_object })
    }

    checkNumberInput(e){
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(e.charCode);
        if (!pattern.test(inputChar)) {
            e.preventDefault();
        }
    }

    checkNumberAndEngInput(e){
        const pattern = /[a-zA-Z0-9]/;
        const inputChar = String.fromCharCode(e.charCode);
        if (!pattern.test(inputChar)) {
            e.preventDefault();
        }
    }

    render() {
        let countriesOther = this.state.country_list?.filter((n) => n.alpha_2_code  === this.state.owner_object.countries)
        let ethnicityOther = this.state.country_list?.filter((n) => n.alpha_2_code === this.state.owner_object.ethnicity)

        if (this.state.redirectToList) {
            return <Redirect to={{
                pathname: "/register/residential/detail/owner-detail/" + this.props.match.params.id
            }} />
        }
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {
                            t =>
                                <div className="container-fluid p-4 staff-create" id="form-detail">
                                    <div className="row justify-content-between">
                                        <div className="col header-color">
                                            <h3 className="mb-4">
                                                <Link to={{ pathname: "/register/residential/detail/owner-detail/" + this.props.match.params.id }}>
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                {this.state.residential_name}
                                            </h3>
                                            <div >
                                                <Navigation id={this.props.match.params.id} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-5'>
                                        <form action="" onSubmit={this.onSubmit} id="form-update-owner">

                                            <div className="row mb-4 fade-up">
                                                <div className="col-md-6 col-xl-6">
                                                    <div className="card p-3">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                <span className="mb-3 lable-owner-detail-16">{t('register:owner')}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-3">
                                                                    {this.state.image ?
                                                                        <div className="rounded-circle avatar-img profile-img"
                                                                            style={{ backgroundImage: `url(${this.state.image})` }}>
                                                                        </div>
                                                                        :
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                            alt="owner-profile"
                                                                            className="rounded-circle avatar-img profile-img" />
                                                                    }
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="uploadBTN">
                                                                        <label htmlFor="uploadImgProfile" className="upload-btn-wrapper">
                                                                            <button className={"btn"} type='button'>
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/image.png'}
                                                                                    className='mr-3' />
                                                                                {t("staff:Change picture")}
                                                                            </button>

                                                                            <input type="file" name='imageProfile' id="uploadImgProfile"
                                                                                className='form-control uploadFile'
                                                                                accept='image/*' onChange={this.handleInputImage}
                                                                                defaultValue={this.state.image}
                                                                            />

                                                                        </label>
                                                                    </div>
                                                                    {/* <div className="upload-file">
                                                                <div>
                                                                    <Translation>
                                                                        {t => <label className="btn-link"
                                                                            htmlFor="uploadImage">{t("resident:Change picture")}</label>}
                                                                    </Translation>
                                                                </div>
                                                                <div className="upload-input">
                                                                    <input type="file" className="custom-file-input"
                                                                        id="uploadImage" name="image"
                                                                        accept="image/*"
                                                                        onChange={this.handleInputImage} />
                                                                </div>
                                                            </div> */}
                                                                </div>
                                                                <div className="col-3">
                                                                    {(this.state.owner_object?.user && this.state.owner_object?.user?.id !== "" && this.state.owner_object?.channel === null) &&
                                                                        <button type="button" className="btn btn-link p-0"
                                                                            onClick={() => this.onClickResetPassword(this.state.owner_object?.user?.id, this.state.owner_object?.email)}> <h6 className="black">{t('resident:Reset Password')}</h6>

                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" className="custom-control-input"
                                                                            id="owner_radio" name="owner"
                                                                            checked={this.state.type_resident === 'owner'}
                                                                            onChange={this.handleInputChange} />
                                                                        <label className="custom-control-label label16"
                                                                            htmlFor="owner_radio">{
                                                                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                textData?.data?.ownerDetail.owner
                                                                                    :                                                                                
                                                                                t("resident:Owner")
                                                                            }</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" className="custom-control-input"
                                                                            id="resident_radio" name="resident"
                                                                            checked={this.state.type_resident === 'resident'}
                                                                            onChange={this.handleInputChange} />
                                                                        <label className="custom-control-label label16"
                                                                            htmlFor="resident_radio">{
                                                                                _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                textData?.data?.ownerDetail.resident
                                                                                :
                                                                                t("resident:Resident")
                                                                            }</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" className="custom-control-input"
                                                                            id="renter_radio" name="renter"
                                                                            checked={this.state.type_resident === 'renter'}
                                                                            onChange={this.handleInputChange} />
                                                                        <label className="custom-control-label label16"
                                                                            htmlFor="renter_radio">{t("resident:Tenant")}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" className="custom-control-input"
                                                                            id="agent_radio" name="agent"
                                                                            checked={this.state.type_resident === 'agent'}
                                                                            onChange={this.handleInputChange} />
                                                                        <label className="custom-control-label label16"
                                                                            htmlFor="agent_radio">{t("resident:Agent")}</label>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col-6">
                                                                    {/* <div style={{ position: 'relative' }}>
                                                                        <label className='label16'>{t("resident:Name prefix")}  <span className="text-danger">*</span></label>
                                                                        <div style={{ position: 'relative' }}>
                                                                            <Select
                                                                                placeholder={"กรุณาเลือก"}
                                                                                options={name_title_listJson}
                                                                                isSearchable={false}
                                                                                onChange={this.handleInputChange}
                                                                            />
                                                                            <input name="purchaseRequisition.conditionWaranty" type="text"
                                                                                defaultValue={this.state.owner_object?.nameTitleSelect || ""}
                                                                                style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1, opacity: 0 }}
                                                                                onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                            />
                                                                        </div>



                                                                    </div> */}
                                                                    <span className="lable-owner-detail-16">
                                                                        {t("resident:Name prefix")} <span className="text-danger">*</span>
                                                                    </span>
                                                                    <select className="form-control inputBox pt-0 pb-0 dropdown-custom-arrow"
                                                                        value={this.state.owner_object?.nameTitleSelect}
                                                                        onChange={this.handleInputChange}
                                                                        required={true}
                                                                        name="owner_object.nameTitleSelect">
                                                                        <option value="">{t("resident:Please select")}</option>

                                                                        <option value="นาย">นาย</option>
                                                                        <option value="นาง">นาง</option>
                                                                        <option value="นางสาว">นางสาว</option>
                                                                        <option value="เด็กหญิง">เด็กหญิง</option>
                                                                        <option value="เด็กชาย">เด็กชาย</option>
                                                                        <option value="Miss">Miss</option>
                                                                        <option value="Mrs.">Mrs.</option>
                                                                        <option value="Mr.">Mr</option>
                                                                        <option value="Master">Master</option>
                                                                        <option value="บริษัท">บริษัท</option>
                                                                        <option value="คุณ">คุณ</option>
                                                                        <option value="other_input">{t("resident:Specify")}</option>
                                                                    </select>
                                                                </div>

                                                                {this.state.owner_object?.nameTitleSelect === "other_input" &&
                                                                    <div className="col">
                                                                        <span className="lable-owner-detail-16">
                                                                            {t("resident:Specify")} <span className="text-danger">*</span>
                                                                        </span>
                                                                        <input type="text" className="form-control inputBox"
                                                                            name="owner_object.nameTitle"
                                                                            onChange={this.handleInputChange}
                                                                            defaultValue={this.state.owner_object?.nameTitle || ""}
                                                                            //defaultValue={""}
                                                                            required={true}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <span className="lable-owner-detail-16">
                                                                        {(_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on 
                                                                            && this.state.owner_object?.nameTitleSelect !== 'บริษัท') ?
                                                                            textData?.data?.createResidential.nameSurname
                                                                            :
                                                                            t("resident:Name")}                                                                     
                                                                        <span className="text-danger">*</span>
                                                                    </span>
                                                                    <input type="text" className="form-control inputBox"
                                                                        name="owner_object.firstName"
                                                                        onChange={this.handleInputChange}
                                                                        defaultValue={this.state.owner_object?.firstName || ""}
                                                                        required={true}
                                                                        disabled={(this.state.owner_object?.user && this.state.owner_object?.user.username) ? "disabled" : false || this.state.isFirstName}

                                                                    />
                                                                </div>
                                                                {this.state.owner_object?.nameTitleSelect !== 'บริษัท' &&
                                                                    <div className="col">
                                                                        <span className="lable-owner-detail-16">
                                                                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                            textData?.data?.createResidential.department
                                                                            :
                                                                            t("resident:Surname")} 
                                                                            <span className="text-danger">*</span>
                                                                        </span>

                                                                        <input type="text" className="form-control inputBox"
                                                                            name="owner_object.lastName"
                                                                            onChange={this.handleInputChange}
                                                                            defaultValue={this.state.owner_object?.lastName || ""}
                                                                            required={true}
                                                                            disabled={(this.state.owner_object?.user && this.state.owner_object?.user.username) ? "disabled" : false || this.state.isLastName}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <span className="lable-owner-detail-16">
                                                                        {(_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on
                                                                             && this.state.owner_object?.nameTitleSelect !== 'บริษัท') ?
                                                                            textData?.data?.createResidential.nameSurnameEng
                                                                            :
                                                                            "Name"}
                                                                        <span className="text-danger">*</span>
                                                                    </span>
                                                                    <input type="text" className="form-control inputBox"
                                                                        name="owner_object.firstNameEng"
                                                                        onChange={this.handleInputChange}
                                                                        required={true}
                                                                        // required={!this.state.owner_object?.firstName}
                                                                        // pattern="[A-Za-z]+"
                                                                       // defaultValue={this.state.owner_object?.firstNameEng || this.state.owner_object.firstName}
                                                                        defaultValue={this.state.owner_object?.firstNameEng}
                                                                        // disabled={(this.state.owner_object && this.state.owner_object?.firstNameEng) ? "disabled" : false || this.state.isFirstNameEng}
                                                                        disabled={this.props.match.params.id ? true : false}

                                                                    />
                                                                </div>
                                                                {
                                                                this.state.owner_object?.nameTitleSelect !== 'บริษัท' &&
                                                                    <div className="col">
                                                                        <span className="lable-owner-detail-16">
                                                                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                            textData?.data?.createResidential.departmentEng
                                                                            :
                                                                            "Surname"} 
                                                                            <span className="text-danger">*</span>
                                                                        </span>
                                                                        <input type="text" className="form-control inputBox"
                                                                            name="owner_object.lastNameEng"
                                                                            onChange={this.handleInputChange}
                                                                            required={true}
                                                                            // required={!this.state.owner_object?.lastName}
                                                                            // pattern="[A-Za-z]+"
                                                                            //defaultValue={this.state.owner_object?.lastNameEng || this.state.owner_object.lastName}
                                                                            defaultValue={this.state.owner_object?.lastNameEng}
                                                                            disabled={this.props.match.params.id ? true : false}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>

                                                            {this.state.owner_object?.nameTitleSelect !== 'บริษัท' &&
                                                                <React.Fragment>
                                                                    <div className="row mt-4">
                                                                        <div className="col">
                                                                        <span className="lable-owner-detail-16"> <strong>{t("resident:Occupation")}</strong></span>
                                                                        {/* <span className="lable-owner-detail-16"> <strong>อาชีพ</strong></span> */}
                                                                            <input type="text" className="form-control mt-1"
                                                                                name="owner_object.occupation"
                                                                                onChange={this.handleInputChange}
                                                                                defaultValue={this.state.owner_object?.occupation || ''}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">

                                                                        <div className="col">
                                                                        <span className="lable-owner-detail-16"><strong></strong></span>
                                                                            <div className="row label16">
                                                                                <div className="col">
                                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                                        <input type="radio" className="custom-control-input"
                                                                                            id="unspecified_radio" name="tenantStatus"
                                                                                            value="unspecified"
                                                                                            checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'unspecified'}
                                                                                            onChange={this.handleChangeTenantStatus}
                                                                                        />
                                                                                        <label className="custom-control-label"
                                                                                            htmlFor="unspecified_radio">{t("resident:N/A")}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                                        <input type="radio" className="custom-control-input"
                                                                                            id="single_radio" name="tenantStatus"
                                                                                            value="single"
                                                                                            checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'single'}
                                                                                            onChange={this.handleChangeTenantStatus}
                                                                                        />
                                                                                        <label className="custom-control-label"
                                                                                            htmlFor="single_radio">{t("resident:Single")}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                                        <input type="radio" className="custom-control-input"
                                                                                            id="married_radio" name="tenantStatus"
                                                                                            value="married"
                                                                                            checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'married'}
                                                                                            onChange={this.handleChangeTenantStatus}
                                                                                        />
                                                                                        <label className="custom-control-label"
                                                                                            htmlFor="married_radio">{t("resident:Married")}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                                        <input type="radio" className="custom-control-input"
                                                                                            id="divorce_radio" name="tenantStatus"
                                                                                            value="divorce"
                                                                                            checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'divorce'}
                                                                                            onChange={this.handleChangeTenantStatus}
                                                                                        />
                                                                                        <label className="custom-control-label"
                                                                                            htmlFor="divorce_radio">{t("resident:Divorce")}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                                        <input type="radio" className="custom-control-input"
                                                                                            id="widow_radio" name="tenantStatus"
                                                                                            value="widow"
                                                                                            checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'widow'}
                                                                                            onChange={this.handleChangeTenantStatus}
                                                                                        />
                                                                                        <label className="custom-control-label"
                                                                                            htmlFor="widow_radio">{t("resident:Widowed")}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <div className="col">
                                                                            <div className='d-flex'>
                                                                                <div className='pr-2'>
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/flag.png'}
                                                                                    alt="flag-icon" />
                                                                                </div>
                                                                               <div>
                                                                               <span>
                                                                               <span className="lable-owner-detail-16">{t("resident:Nationality")}</span>
                                                                                </span>
                                                                               </div>
                                                                            </div>
                                                                          
                                                                            <div className='row'>

                                                                                <div className={countriesOther.length > 0 ? 'col' : 'col-6'}>
                                                                                    {
                                                                                        countriesOther.length > 0 ?
                                                                                            <select className="form-control mt-1 dropdown-custom-arrow"
                                                                                                value={this.state.owner_object?.countries}
                                                                                                onChange={this.handleInputChange}
                                                                                                required={true} name="owner_object.countries"
                                                                                            >
                                                                                                {this.state.country_list.map((country) =>
                                                                                                    <option value={country.alpha_2_code}
                                                                                                        key={country.alpha_2_code}>{country.nationality}</option>
                                                                                                )}
                                                                                            </select>
                                                                                            :
                                                                                            <select className="form-control mt-1 dropdown-custom-arrow"
                                                                                                value={"other"}
                                                                                                onChange={this.handleInputChange}
                                                                                                required={true} name="owner_object.countries"
                                                                                            >
                                                                                                {this.state.country_list.map((country) =>
                                                                                                    <option value={country.alpha_2_code}
                                                                                                        key={country.alpha_2_code}>{country.nationality}</option>
                                                                                                )}
                                                                                            </select>
                                                                                    }

                                                                                </div>
                                                                                {
                                                                                    (this.state.owner_object?.countries === "other" || countriesOther.length === 0) &&
                                                                                    <div className='col-6'>
                                                                                        <input type="text" name="owner_object.countriesOther"
                                                                                            className='form-control mt-1' onChange={this.handleInputChange}
                                                                                            defaultValue={this.state.owner_object?.countries}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <div className="col">
                                                                        <span className="lable-owner-detail-16"><strong>{t("resident:Ethnicity")}</strong></span>                                                                            
                                                                            <div className='row'>
                                                                                <div className={ethnicityOther.length > 0 ? 'col' : 'col-6'}>
                                                                                    {
                                                                                        ethnicityOther.length > 0 ?
                                                                                            <select className="form-control mt-1 dropdown-custom-arrow"
                                                                                                value={this.state.owner_object?.ethnicity}
                                                                                                onChange={this.handleInputChange}
                                                                                                required={true} name="owner_object.ethnicity"
                                                                                            >
                                                                                                {this.state.country_list.map((country) =>
                                                                                                    <option value={country.alpha_2_code}
                                                                                                        key={country.alpha_2_code}>{country.nationality}</option>
                                                                                                )}

                                                                                            </select>
                                                                                            :
                                                                                            <select className="form-control mt-1 dropdown-custom-arrow"
                                                                                                value={"other"}
                                                                                                onChange={this.handleInputChange}
                                                                                                required={true} name="owner_object.ethnicity"
                                                                                            >
                                                                                                {this.state.country_list.map((country) =>
                                                                                                    <option value={country.alpha_2_code}
                                                                                                        key={country.alpha_2_code}>{country.nationality}</option>
                                                                                                )}
                                                                                            </select>
                                                                                    }


                                                                                </div>
                                                                                {
                                                                                    (this.state.owner_object?.ethnicity === "other" || ethnicityOther.length === 0) &&
                                                                                    <div className='col-6'>
                                                                                        <input type="text" name="owner_object.ethnicityOther"
                                                                                            className='form-control mt-1' onChange={this.handleInputChange}
                                                                                            defaultValue={this.state.owner_object?.ethnicity}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        <div className="col">
                                                                            <span className="lable-owner-detail-16">
                                                                                {t("resident:Date of birth")}
                                                                            </span>

                                                                            <div className="datePicker">
                                                                                <DatePicker
                                                                                    name='owner_object.dateOfBirth'
                                                                                    className="form-control inputBoxDate"
                                                                                    selected={this.state.owner_object?.dateOfBirth}
                                                                                    onChange={(date) => this.setState(n => { return _.set(n, "owner_object.dateOfBirth", date) })}
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                // disabled={!this.state.status}
                                                                                />
                                                                            </div>


                                                                            {/* <DatePickerAdapter
                                                                                className="form-control inputBox" name="owner_object.dateOfBirth"
                                                                                selected={this.state.owner_object?.dateOfBirth}
                                                                                onChange={this.handleInputChange}
                                                                            /> */}
                                                                        </div>
                                                                        <div className="col">
                                                                            <span className="lable-owner-detail-16">
                                                                                {t("resident:Sex")}
                                                                            </span>
                                                                            <select className="form-control inputBox pt-0 pb-0 mt-1 dropdown-custom-arrow"
                                                                                name="owner_object.gender"
                                                                                onChange={this.handleInputChange}
                                                                                value={this.state.owner_object?.gender ? this.state.owner_object?.gender.toLowerCase() : ''}>
                                                                                <option value="">{t("resident:Please select")}</option>
                                                                                <option value="female">{t("resident:Female")}</option>
                                                                                <option value="male">{t("resident:Male")}</option>
                                                                                <option value="unspecified">ไม่ระบุ</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mt-4">
                                                                        {
                                                                            !this.state.isAgent && 
                                                                            <div className="col-6">
                                                                            <span className="lable-owner-detail-16">
                                                                                {t("resident:dateIn")}
                                                                                <span className='text-red'> *</span>
                                                                            </span>

                                                                            <div className="datePicker">
                                                                                <DatePicker
                                                                                    required
                                                                                    name='owner_object.dateIn'
                                                                                    className="form-control inputBoxDate"
                                                                                    selected={this.state.owner_object.dateIn ? new Date(this.state.owner_object.dateIn) : new Date()}
                                                                                    onChange={(date) => this.setState(n => { return _.set(n, "owner_object.dateIn", date) })}
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                // disabled={!this.state.status}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        }

                                                                        {
                                                                            this.state.owner_object?.dateOut &&
                                                                            <div className="col-6">
                                                                                <span className="lable-owner-detail-16">
                                                                                    {t("resident:dateOut")}
                                                                                </span>
                                                                                <div className="datePicker">
                                                                                    <DatePicker
                                                                                        name='owner_object.dateOut'
                                                                                        className="form-control inputBoxDate"
                                                                                        // selected={this.state.owner_object?.dateOut}
                                                                                        selected={new Date(this.state.owner_object?.dateOut)}
                                                                                        // onChange={(date) => this.setState({ []: date })}
                                                                                        onChange={this.handleInputChange}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                    // disabled={!this.state.status}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="card p-3 mt-3">
                                                        <div className="card-body">
                                                             <div className="row">
                                                                <div className="col">
                                                                <span className="lable-owner-detail-16">{t("resident:Note")} </span>
                                                                <textarea  className='form-control form-input' style={{minHeight:'100px'}} name="remark"  
                                                                value={this.state.remark}
                                                                onChange={this.handleInputChange}/>
                                                                </div>
                                                             </div>
                                                         </div>  
                                                   </div>
                                                   
                                                </div>

                                                <div className="col-md-6 col-xl-6">
                                                    <div className="card p-4 ">
                                                        <div className="card-body">
                                                            {/* {this.props.match.params.owner_id && */}
                                                                <div className="row  mb-3">
                                                                    <div className="col-8">
                                                                    <span className="lable-owner-detail-16">
                                                                    {t("resident:Application authentication status")} : <strong>{(this.state.owner_object?.acceptPolicy && this.state.owner_object?.acceptTermOfUse) ? t("resident:Verified") : t("resident:Unverified")}</strong>
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            {/* } */}
                                                            <div className="row mt-4">
                                                                <div className="col label16">
                                                                    {/* <img
                                                                        src={process.env.PUBLIC_URL + '/images/icons/id-card-icon.png'}
                                                                        alt="id-card-icon" />
                                                                    <span className="ml-3">
                                                                        <strong>ประเภทบัตร</strong></span> */}
                                                                    <div className="row mt-2">
                                                                        <div className="col">
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input"
                                                                                    id="id_card" name="typeOfCard"
                                                                                    value="id_card"
                                                                                    checked={this.state.owner_object?.cardType === 'id card' || this.state.owner_object?.cardType === 'id_card'}
                                                                                    onChange={this.handleChangeCardType}
                                                                                    disabled={this.state.owner_object?.nameTitleSelect === 'บริษัท'}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor="id_card" >{t("resident:ID card")}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input"
                                                                                    id="government_officer" name="typeOfCard"
                                                                                    value="government_officer"
                                                                                    checked={this.state.owner_object?.cardType === 'government_officer'}
                                                                                    onChange={this.handleChangeCardType}
                                                                                    disabled={this.state.owner_object?.nameTitleSelect === 'บริษัท'}
                                                                                />
                                                                                <label className="custom-control-label"
                                                                                    htmlFor="government_officer">{t("resident:Civil servant card")}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input"
                                                                                    id="driving" name="typeOfCard"
                                                                                    value="driving"
                                                                                    checked={this.state.owner_object?.cardType === 'driving'}
                                                                                    onChange={this.handleChangeCardType}
                                                                                    disabled={this.state.owner_object?.nameTitleSelect === 'บริษัท'}
                                                                                />
                                                                                <label className="custom-control-label"
                                                                                    htmlFor="driving">{t("resident:Driving license")}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <div className="col-4">
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input"
                                                                                    id="passport" name="typeOfCard"
                                                                                    value="passport"
                                                                                    checked={this.state.owner_object?.cardType === 'passport'}
                                                                                    onChange={this.handleChangeCardType}
                                                                                    disabled={this.state.owner_object?.nameTitleSelect === 'บริษัท'}
                                                                                />
                                                                                <label className="custom-control-label"
                                                                                    htmlFor="passport">Passport</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input"
                                                                                    id="other" name="typeOfCard"
                                                                                    checked={this.state.owner_object?.cardType === 'other'}
                                                                                    onChange={this.handleChangeCardType}
                                                                                    value="other"
                                                                                    disabled={this.state.owner_object?.nameTitleSelect === 'บริษัท'}
                                                                                />
                                                                                <label className="custom-control-label"
                                                                                    htmlFor="other">{t("resident:etc.")}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" className="custom-control-input"
                                                                                    id="unspecified" name="typeOfCard"
                                                                                    checked={this.state.owner_object?.cardType === 'unspecified'}
                                                                                    onChange={this.handleChangeCardType}
                                                                                    value="unspecified"
                                                                                />
                                                                                <label className="custom-control-label"
                                                                                    htmlFor="unspecified">{t("resident:N/A")}</label>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                    <>
                                                                        {
                                                                            this.state.owner_object?.cardType === "id_card" || this.state.owner_object?.cardType === "id card" ?
                                                                                <>
                                                                                    <input type="text" className="form-control inputBox mt-2"
                                                                                        name="owner_object.idCardNumber"
                                                                                        onChange={this.handleInputChange}
                                                                                        defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                        placeholder="ระบุหมายเลขบัตรประชาชน"
                                                                                        pattern="[0-9]{13}" maxLength={13} minLength={13}
                                                                                        required={true}
                                                                                        onKeyPress={this.checkNumberInput}
                                                                                    />

                                                                                </>
                                                                                :
                                                                                this.state.owner_object?.cardType === "government_officer" ?
                                                                                    <input type="text" className="form-control inputBox mt-2"
                                                                                        name="owner_object.idCardNumber"
                                                                                        onChange={this.handleInputChange}
                                                                                        defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                        placeholder="ระบุหมายเลขบัตรข้าราชการ"
                                                                                        pattern="[0-9]+$" maxLength={13}
                                                                                        required={true}
                                                                                        onKeyPress={this.checkNumberInput}

                                                                                    />
                                                                                    :
                                                                                    this.state.owner_object?.cardType === "unspecified" ?
                                                                                        <input type="text" className="form-control inputBox mt-2"
                                                                                            name="owner_object.idCardNumber"
                                                                                            onChange={this.handleInputChange}
                                                                                            defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                            placeholder={t("resident:Specify card number / taxpayer number")}
                                                                                            onKeyPress={this.checkNumberInput}    
                                                                                        />
                                                                                        :
                                                                                        this.state.owner_object?.cardType === "passport" ?
                                                                                            <input type="text" className="form-control inputBox mt-2"
                                                                                                name="owner_object.idCardNumber"
                                                                                                onChange={this.handleInputChange}
                                                                                                defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                                placeholder={t("resident:Specify card number / taxpayer number")}
                                                                                                required={true}
                                                                                                onKeyPress={this.checkNumberAndEngInput}    
                                                                                            />
                                                                                                :
                                                                                            <input type="text" className="form-control inputBox mt-2"
                                                                                                name="owner_object.idCardNumber"
                                                                                                onChange={this.handleInputChange}
                                                                                                defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                                placeholder={t("resident:Specify card number / taxpayer number")}
                                                                                                required={true}
                                                                                                onKeyPress={this.checkNumberInput}                                                                                                     
                                                                                            />
                                                                        }
                                                                    </>

                                                                    <div className='FileApprove'>
                                                                        <div className="uploadBTN">
                                                                            <label htmlFor="FileApprove" className="upload-btn-wrapper">
                                                                                <button className={"btn"} type='button'>
                                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                        className='mr-3' />
                                                                                    {t("resident:Attach file")}
                                                                                </button>

                                                                                <span>{t("resident:Only png, jpg, jpeg files are supported, and their size must not exceed 10 MB.")}</span>

                                                                                <input type="file" name='verifyIdentityDocument' id="FileApprove"
                                                                                    className='form-control uploadFile'
                                                                                    accept='image/*' onChange={this.uploadFile}
                                                                                />

                                                                            </label>
                                                                        </div>
                                                                        {
                                                                            this.state.owner_object?.verifyIdentityDocument?.length > 0 &&
                                                                            this.state.owner_object?.verifyIdentityDocument?.map((file, inx) => {

                                                                                if (file.node) {
                                                                                    let typeFile = file.node.fileName.split(".")
                                                                                    return (
                                                                                        file.node.status !== "delete" &&
                                                                                        <p key={inx}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                            <Link
                                                                                                to="route"
                                                                                                onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    file.node.viewPtath ?
                                                                                                        window.open(
                                                                                                            typeof file.node.viewPtath === "string"
                                                                                                                ? file.node.viewPtath
                                                                                                                : URL.createObjectURL(file.node.viewPtath)
                                                                                                        )
                                                                                                        :
                                                                                                        window.open(
                                                                                                            typeof file.node.fileUpload === "string"
                                                                                                                ? file.node.fileUpload
                                                                                                                : URL.createObjectURL(file.node.fileUpload)
                                                                                                        )
                                                                                                }}
                                                                                                target="blank">
                                                                                                {file.node.fileName}
                                                                                            </Link>
                                                                                            &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "verifyIdentityDocument")}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                            </label>
                                                                                        </p>
                                                                                    )
                                                                                } else {
                                                                                    let typeFile = file.fileName.split(".")
                                                                                    return (
                                                                                        file.status !== "delete" &&
                                                                                        <p key={inx}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                            <Link
                                                                                                to="route"
                                                                                                onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    file.viewPtath ?
                                                                                                        window.open(
                                                                                                            typeof file.viewPtath === "string"
                                                                                                                ? file.viewPtath
                                                                                                                : URL.createObjectURL(file.viewPtath)
                                                                                                        )
                                                                                                        :
                                                                                                        window.open(
                                                                                                            typeof file.fileUpload === "string"
                                                                                                                ? file.fileUpload
                                                                                                                : URL.createObjectURL(file.fileUpload)
                                                                                                        )
                                                                                                }}
                                                                                                target="blank">
                                                                                                {file.fileName}
                                                                                            </Link>
                                                                                            &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "verifyIdentityDocument")}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                            </label>
                                                                                        </p>
                                                                                    )
                                                                                }
                                                                            })

                                                                        }



                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    {/* แนบเอกสารประกอบ */}
                                                    <div className="card p-4 mt-3">
                                                        <div className="card-body">
                                                            <div className='withDocument'>
                                                                <label className='headerLabel'>{t("resident:Attach document")}</label><br />
                                                                <span>{t("resident:Only png, jpg, jpeg files are supported, and their size must not exceed 10 MB.")}</span> 

                                                            </div>
                                                            <hr />

                                                            {/* 1.Passport */}
                                                            <div className="row mt-4">
                                                                <div className='col-8'>
                                                                    <label className='label16'>1. Passport</label>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="uploadBTN">
                                                                        <label htmlFor="uploadPassport" className="upload-btn-wrapper mt-0">
                                                                            <button className={"btn"} type='button'>
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                    className='mr-3' />
                                                                                {t("resident:Attach file")}
                                                                            </button>

                                                                            <input type="file" name='passport' id="uploadPassport"
                                                                                className='form-control uploadFile'
                                                                                accept='image/*' onChange={this.uploadFile}
                                                                            />

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    {
                                                                        this.state.owner_object?.passport?.length > 0 &&
                                                                        this.state.owner_object?.passport?.map((file, inx) => {
                                                                            if (file.node) {
                                                                                let typeFile = file.node.fileName.split(".")
                                                                                return (
                                                                                    file.node.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.node.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.node.viewPtath === "string"
                                                                                                            ? file.node.viewPtath
                                                                                                            : URL.createObjectURL(file.node.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.node.fileUpload === "string"
                                                                                                            ? file.node.fileUpload
                                                                                                            : URL.createObjectURL(file.node.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.node.fileName}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "passport")}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            } else {
                                                                                let typeFile = file.fileName.split(".")
                                                                                return (
                                                                                    file.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.viewPtath === "string"
                                                                                                            ? file.viewPtath
                                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.fileUpload === "string"
                                                                                                            ? file.fileUpload
                                                                                                            : URL.createObjectURL(file.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.fileName}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "passport")}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            }

                                                                        })

                                                                    }
                                                                </div>
                                                            </div>

                                                            {/* 2. เอกสารสัญญาเช่า */}

                                                            <div className="row mt-4">
                                                                <div className='col-8'>
                                                                    <label className='label16'>2. {t("resident:Lease agreement document")}</label>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="uploadBTN">
                                                                        <label htmlFor="uploadLease" className="upload-btn-wrapper mt-0">
                                                                            <button className={"btn"} type='button'>
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                    className='mr-3' />
                                                                                {t("resident:Attach file")}
                                                                            </button>

                                                                            <input type="file" name='lease' id="uploadLease"
                                                                                className='form-control uploadFile'
                                                                                accept='image/*' onChange={this.uploadFile}
                                                                            />

                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col">
                                                                    {
                                                                        this.state.owner_object?.lease?.length > 0 &&
                                                                        this.state.owner_object?.lease?.map((file, inx) => {
                                                                            if (file.node) {
                                                                                let typeFile = file.node.fileName.split(".")
                                                                                return (
                                                                                    file.node.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.node.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.node.viewPtath === "string"
                                                                                                            ? file.node.viewPtath
                                                                                                            : URL.createObjectURL(file.node.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.node.fileUpload === "string"
                                                                                                            ? file.node.fileUpload
                                                                                                            : URL.createObjectURL(file.node.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.node.fileName}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "lease")}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            } else {
                                                                                let typeFile = file.fileName.split(".")
                                                                                return (
                                                                                    file.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.viewPtath === "string"
                                                                                                            ? file.viewPtath
                                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.fileUpload === "string"
                                                                                                            ? file.fileUpload
                                                                                                            : URL.createObjectURL(file.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.fileName}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "lease")}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            }
                                                                        })

                                                                    }
                                                                </div>
                                                            </div>


                                                            {/* 3. สัญญาระหว่างผู้เช่ากับเจ้าของห้อง */}

                                                            <div className="row mt-4">
                                                                <div className='col-8'>
                                                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?                                                                    
                                                                    <label  className='label16'>3. สัญญาระหว่างผู้เช่ากับ{textData?.data?.ownerDetail.ownership}</label>
                                                                    :
                                                                    <label className='label16'>3. {t("resident:Contract between the tenant and the owner")}</label>}                                                                
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="uploadBTN">
                                                                        <label htmlFor="uploadRentOwnerLease" className="upload-btn-wrapper mt-0">
                                                                            <button className={"btn"} type='button'>
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                    className='mr-3' />
                                                                                {t("resident:Attach file")}
                                                                            </button>

                                                                            <input type="file" name='rentOwnerLease' id="uploadRentOwnerLease"
                                                                                className='form-control uploadFile'
                                                                                accept='image/*' onChange={this.uploadFile}
                                                                            />

                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col">
                                                                    {
                                                                        this.state.owner_object?.rentOwnerLease?.length > 0 &&
                                                                        this.state.owner_object?.rentOwnerLease?.map((file, inx) => {
                                                                            if (file.node) {
                                                                                let typeFile = file.node.fileName.split(".")
                                                                                return (
                                                                                    file.node.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.node.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.node.viewPtath === "string"
                                                                                                            ? file.node.viewPtath
                                                                                                            : URL.createObjectURL(file.node.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.node.fileUpload === "string"
                                                                                                            ? file.node.fileUpload
                                                                                                            : URL.createObjectURL(file.node.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.node.fileName}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "rentOwnerLease")}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            } else {
                                                                                let typeFile = file.fileName.split(".")
                                                                                return (
                                                                                    file.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.viewPtath === "string"
                                                                                                            ? file.viewPtath
                                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.fileUpload === "string"
                                                                                                            ? file.fileUpload
                                                                                                            : URL.createObjectURL(file.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.fileName}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "rentOwnerLease")}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            }
                                                                        })

                                                                    }
                                                                </div>

                                                            </div>

                                                            {/* 4. แบบฟอร์มรายวัน (การเปลี่ยนคีย์การ์ด) */}

                                                            <div className="row mt-4">
                                                                <div className='col-8'>
                                                                    <label className='label16'>4. {t("resident:Daily form (changing key card)")}</label>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="uploadBTN">
                                                                        <label htmlFor="uploadFormForDaily" className="upload-btn-wrapper mt-0">
                                                                            <button className={"btn"} type='button'>
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                    className='mr-3' />
                                                                                {t("resident:Attach file")}
                                                                            </button>

                                                                            <input type="file" name='dailyForm' id="uploadFormForDaily"
                                                                                className='form-control uploadFile'
                                                                                accept='image/*' onChange={this.uploadFile}
                                                                            />

                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col">
                                                                    {
                                                                        this.state.owner_object?.dailyForm?.length > 0 &&
                                                                        this.state.owner_object?.dailyForm?.map((file, inx) => {
                                                                            if (file.node) {
                                                                                let typeFile = file.node.fileName.split(".")
                                                                                return (
                                                                                    file.node.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.node.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.node.viewPtath === "string"
                                                                                                            ? file.node.viewPtath
                                                                                                            : URL.createObjectURL(file.node.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.node.fileUpload === "string"
                                                                                                            ? file.node.fileUpload
                                                                                                            : URL.createObjectURL(file.node.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.node.fileName}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "dailyForm")}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            } else {
                                                                                let typeFile = file.fileName.split(".")
                                                                                return (
                                                                                    file.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.viewPtath === "string"
                                                                                                            ? file.viewPtath
                                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.fileUpload === "string"
                                                                                                            ? file.fileUpload
                                                                                                            : URL.createObjectURL(file.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.fileName}
                                                                                        </Link>
                                                                                        &nbsp;&nbsp; <label onClick={() => this.dateFile(inx, file, "dailyForm")}>
                                                                                            <img src={process.env.PUBLIC_URL + "/images/iconAction/close.png"} width={16} height={16} />
                                                                                        </label>
                                                                                    </p>
                                                                                )
                                                                            }
                                                                        })

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="card p-4 mt-3">
                                                        <div className="card-body">
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/vector-phone.png'}
                                                                        alt="phone-icon" style={{ width: '3%' }} />
                                                                    <span className="ml-3 lable-owner-detail-16">
                                                                        <strong>{t("resident:Phone number")}</strong>
                                                                    </span>
                                                                    <input type="text" className="form-control inputBox mt-1"
                                                                        name="owner_object.phone" pattern='^[0-9-  \t+#()]*$'
                                                                        title="กรุณาใส่แค่ตัวเลข เครื่องหมาย (- , # , ( ) , + หรือ กด spacebar) เท่านั้น"
                                                                        onChange={this.handleInputChange}
                                                                        // required={true}
                                                                        value={this.state.owner_object?.phone || ""}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/vector-phone.png'}
                                                                        alt="phone-icon" style={{ width: '3%' }} />
                                                                    <span className="ml-3 lable-owner-detail-16">
                                                                        <strong>{t("resident:Phone number")}</strong>
                                                                    </span>
                                                                    <input type="text" className="form-control inputBox mt-1"
                                                                        name="owner_object.phone2" pattern='^[0-9-  \t+#()]*$'
                                                                        title="กรุณาใส่แค่ตัวเลข เครื่องหมาย (- , # , ( ) , + หรือ กด spacebar) เท่านั้น"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.owner_object?.phone2 || ""}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col">
                                                                    <img src={process.env.PUBLIC_URL + '/images/icons/icon-mail-residential.png'}
                                                                        alt="mail-icon" style={{ width: '3%' }} />
                                                                    <span className="ml-3 lable-owner-detail-16">
                                                                        <strong>{t("resident:E-mail")}</strong>
                                                                    </span>
                                                                    <input type="text" className="form-control inputBox mt-1"
                                                                        name="owner_object.email"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.owner_object?.email || ""}
                                                                        required={this.state.active}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4 d-flex justify-content-center">
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <button type="submit" className="btn btn-primary" form="form-update-owner"
                                                                disabled={this.state.loading} >
                                                                {this.state.loading && <span
                                                                    className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                                {t("resident:Save")}
                                                            </button>
                                                            <Link
                                                                to={{
                                                                    pathname: "/register/residential/detail/owner-detail/" + this.props.match.params.id
                                                                }}>
                                                                <button className="btn btn-secondary ml-3" >
                                                                    {t("resident:Cancel")}
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>

                                    </div>

                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default CreateUpdateOwnerDetail;
