import React from 'react';
import Modal from 'react-modal';
import { H5, LButton, H3, B1 } from '../../components/Text/Text';
import Select from 'react-select';
import _ from "lodash";
import './settingPeriodLockForm.scss'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '24px 24px 16px 24px',
        width: '810px',
        height: '500px'
    },
};


function ModalPeriodLock({ state, modal, closeModal, updatePeriodUnlock, handleChange, handleRemoveTextSelect, periodUnlockRecord, handleChangeCheckbox }) {
    return (
        <Modal
            id='modalPeriodLock'
            isOpen={modal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
            appElement={document.getElementById('root') || undefined}
        >
            <div className="d-flex flex-column " id='periodLook'>
                <div className='d-flex' >
                    <img src={process.env.PUBLIC_URL + "/images/icons/periodLock/icon_add_user.png"} style={{ width: 48, height: 48 }} className='mr-3' alt='icon_add_user'></img>
                    <H3>เพิ่มสิทธิ์แก้ไข</H3>
                </div>
                <div className="col-12 mt-5">
                    <Select
                        hideSelectedOptions={false}
                        isClearable={false}
                        isMulti
                        placeholder="เพิ่มบุคคล ใส่ชื่อ หรือ ID"
                        closeMenuOnSelect={false}
                        options={state.allJuristic || null}
                        value={state.selectedOption}
                        onChange={handleChange}
                        getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center', zIndex: 999 }}>
                                <img src={e.image || process.env.PUBLIC_URL + "/images/icons/periodLock/icon_add_user.png"} style={{ width: 48, height: 48, borderRadius: 24 }} className='mr-3' alt='adduser' ></img>
                                <span >
                                    <H5 style={{ marginLeft: 5 }}>{e.text}</H5>
                                    <B1 style={{ marginLeft: 5 }}>{e.role || '-'}</B1>
                                </span>
                            </div>
                        )}
                    />

                    <div className="slide mt-4">
                        <div style={{ padding: "8px 16px" }}>
                            {_.map(state.selectedOption, (item, index) => {
                                return (
                                    <div className="mt-3" key={index} >
                                        <div className='d-flex justify-content-between' style={{ width: '100%' }} >
                                            <div className='d-flex'>
                                                <img src={item.image || process.env.PUBLIC_URL + "/images/icons/periodLock/icon_add_user.png"} style={{ width: 48, height: 48, borderRadius: 24 }} className='mr-3' alt='icon_add_user' ></img>
                                                <div>
                                                    <H5>{item.text}</H5>
                                                    <B1>{item.role || '-'}</B1>
                                                </div>
                                            </div>
                                            <H5 onClick={() => handleRemoveTextSelect(item.value)} >นำออก</H5>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3" >
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="all" 
                            defaultChecked={periodUnlockRecord?.modes?.toLocaleLowerCase() === 'all'} onClick={handleChangeCheckbox} />
                        <label className="form-check-label" htmlFor="exampleCheck1"><B1>เปิดงวดให้ทุกคนแก้ไขได้</B1></label>
                    </div>
                </div>
                <div className="d-flex flex-row-reverse bd-highlight" >
                    {<LButton minWidth={100} onClick={updatePeriodUnlock} >เสร็จสิ้น</LButton>}
                </div>
            </div>

        </Modal >
    );
}

export default ModalPeriodLock