import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Navigation from "./navigation";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Translation } from "react-i18next";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { graphql } from "babel-plugin-relay/macro";
import UnknownReceiveListTable from "./unknownReceiveListTable";
import _ from "lodash";
import ExportUnknownReceive from "./exportUnknownReceive";
import InlineAdvanceFilter from "../../../libs/inlineAdvanceFilter";
import { Dropdown  } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import i18next from "i18next";

const query = graphql`
  query unknownReceiveListQuery(
    $status: String!
    $first: Int
    $last: Int
    $search: String
    $start_date: DateTime
    $end_date: DateTime
  ) {
    unknownReceiveViewer {
      allUnknownReceive(
        first: $first
        last: $last
        status: $status
        search: $search
        startDate: $start_date
        endDate: $end_date
      ) {
        totalCount
        edges {
          node {
            id
            docNumber
            issuedDate
            updated
            amount
            status
            description
            voidRemark
            bankAccount {
              accountNumber
              accountName
              bankName
            }
            receiptDeposit{
                edges{
                    node{
                        id
                        docNumber
                        total
                        issuedDate
                        firstName lastName
                        payGroup
                    }
                }
            }
            receiveChannel
            creator
            refNumber
            contact {
              refNumber
              name
              firstName
              lastName
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
      summaryUnknownReceive(
        status: $status
        search: $search
        startDate: $start_date
        endDate: $end_date
      )
    }
    selfProject{
      name
    }
    myUser {
      id 
      juristic {
        id firstName lastName
      }
    }
  }
`;

class UnknownReceiveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      temp_start_date: null,
      temp_end_date: null,
      search: "",
      search_input: "",
      advance_search: false,
      status: this.props.match.params.status
        ? this.props.match.params.status
        : "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSearch(event);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  componentDidUpdate() {
    if (this.state.status !== this.props.match.params.status) {
      this.setState({ status: this.props.match.params.status });
    }
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="receive-list">
            <div className="row justify-content-between">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("accounting:unknown receive")+ " "+"(UR)"}</span>}
                  </Translation>
                </h3>
              </div>
              <div className="col">
                <div
                  className="row mr-1"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                    codename: "accounting_unknown_receive_create",
                  }) && (
                    <Link to="/accounting/income/unknown-receive/create">
                      <button
                        type="button"
                        className="btn btn-primary add float-right"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/plus.png"
                          }
                          alt="plus"
                        />
                        <Translation>
                          {(t) => (
                            <span>{t("accounting:add unknown receive")}</span>
                          )}
                        </Translation>
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end">
                <Dropdown>
                  <DropdownToggle id="dropdown-item-button" bsPrefix = 'dropdown-export dropdown-toggle'>
                    {i18next.t("Allaction:Print")}
                  </DropdownToggle>
                  <DropdownMenu>
                    <Dropdown.Item>
                      <Link 
                        to={{pathname : `/accounting/income/unknown-receive/document/unknownreceive` ,
                            state : {query : query , search: this.state.search, start_date: this.state.start_date, end_date: this.state.end_date, status: this.props.match.params.status}}} 
                        className="text-black"
                      >
                          <p className="text-black">PDF</p>
                      </Link>
                    </Dropdown.Item>
                    <ExportUnknownReceive
                      state={this.state}
                      status={this.props.match.params.status}
                    />
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>

            <div className="content-inner">
              <Navigation />

              <div className="row mb-2 mt-1">
                <div className="col-12 text-right">
                  <Translation>
                    {t=>
                    <div className="input-group float-right w-auto ml-2">
                      <input
                        type="text"
                        className="form-control input-size"
                        placeholder={t("unknown_receive:Search")}
                        value={this.state.search_input}
                        name="search"
                        onChange={(e) =>
                          this.setState({ search_input: e.target.value })
                        }
                        onKeyDown={this.handleKeyDown}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary form-control search-button"
                        name="search"
                        value={this.state.search_input}
                        onClick={(e) => this.handleSearch(e)}
                      >
                        {t("unknown_receive:Search")}
                      </button>
                    </div>}
                  </Translation>
                  {/* <button
                    type="button"
                    className="btn btn-outline-secondary float-right"
                    onClick={() =>
                      this.setState({
                        advance_search: !this.state.advance_search,
                      })
                    }
                  >
                    <img
                      src="/images/icons/filter-icon.png"
                      alt="filter-icon"
                    />
                  </button> */}
                  <InlineAdvanceFilter
                    start_date={this.state.temp_start_date}
                    end_date={this.state.temp_end_date}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <UnknownReceiveListTable
                    query={query}
                    search={this.state.search}
                    start_date={this.state.start_date}
                    end_date={this.state.end_date}
                    status={this.props.match.params.status}
                  />
                </div>
              </div>
            </div>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default UnknownReceiveList;
