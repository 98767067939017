import Swal from "sweetalert2";

const Helpers = {
    // Main wrapper for Fetch API
    httpRequest: (url, method, payload, headers) => {
        
        const token_id = localStorage.getItem("token");
        // Configuration to accept json as a default
        const config = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `JWT ${token_id}`
            }
        };
        // method = post and payload, add it to the fetch request
        if (method.toLowerCase() === 'post' && payload && payload.length > 0) {
            config.body = JSON.stringify(payload);
        }

        // if custom headers need to be set for the specific request
        // override them here
        if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
            config.headers = headers;
        }

        return fetch(
            url,
            config
        ).then((response) => {
            // Check if the request is 200
            if (response.ok) {
                let data = response;
                // if the type is json return, interpret it as json
                if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
                    data = response.json();
                }
                return data;
            }else{
               

                if(response.status.startsWith("1")){
                    Swal.fire("!", "Informational responses ", "info");
                }
                else if(response.status.startsWith("3")){
                    Swal.fire("Error!", "Redirection messages ", "error");
                }
                else if(response.status.startsWith("4")){
                    Swal.fire("Error!", "Client error responses ", "error");
                }
                else if(response.status.startsWith("5")){
                    Swal.fire("Error!", "Server error responses", "error");
                }else{
                    Swal.fire("Error!", "", "error");
                }
            }

            // if an errors, anything but 200 then reject with the actual response
            return Promise.reject(response);
        });
    },
};

export default Helpers;
