/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type modalResultVoteQueryVariables = {|
  id: string,
  residential_vote_id?: ?string,
|};
export type modalResultVoteQueryResponse = {|
  +allVote: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +choice: {|
          +id: string
        |},
      |}
    |}>
  |}
|};
export type modalResultVoteQuery = {|
  variables: modalResultVoteQueryVariables,
  response: modalResultVoteQueryResponse,
|};
*/


/*
query modalResultVoteQuery(
  $id: ID!
  $residential_vote_id: ID
) {
  allVote(residentialVote_Survey_Id: $id, residentialVote_Id: $residential_vote_id) {
    edges {
      node {
        id
        choice {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "residential_vote_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "residentialVote_Id",
        "variableName": "residential_vote_id"
      },
      {
        "kind": "Variable",
        "name": "residentialVote_Survey_Id",
        "variableName": "id"
      }
    ],
    "concreteType": "VoteNodeConnection",
    "kind": "LinkedField",
    "name": "allVote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VoteNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VoteNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChoiceNode",
                "kind": "LinkedField",
                "name": "choice",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "modalResultVoteQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "modalResultVoteQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "85d9349931b316c7e9b0a39f5d3636ff",
    "id": null,
    "metadata": {},
    "name": "modalResultVoteQuery",
    "operationKind": "query",
    "text": "query modalResultVoteQuery(\n  $id: ID!\n  $residential_vote_id: ID\n) {\n  allVote(residentialVote_Survey_Id: $id, residentialVote_Id: $residential_vote_id) {\n    edges {\n      node {\n        id\n        choice {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a6f3abfd0ff32fb57d70f7c18a836e8c';

module.exports = node;
