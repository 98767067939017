import React, { Component } from 'react'
import PrivateRoute from '../../libs/privateRoute'
import NoMatch from '../../components/noMatch'
import { Route, Switch } from "react-router-dom";
import ReportStockList from './reportStockList';
import ReportStockDetail from './reportStockDetail';
import WrapperReportStock from './documentReportStock/wrapperReportStock';

export default class index extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/list`} component={ReportStockList} />
        <PrivateRoute exact path={`${this.props.match.url}/reportStockDetail/:id/:status/:statusTable`} component={ReportStockDetail} />
        <PrivateRoute exact path={`${this.props.match.url}/document`} component={WrapperReportStock} />
        <Route component={NoMatch} />
      </Switch>
    )
  }
}
