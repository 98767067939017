import React from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import WrapperContent from "../../components/wrapper/wrapperContent";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Navigation from "./navigation";
import ComponentPagination from "../../libs/componentPagination";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Pagination from "../../libs/newPagination";
import { format, subMonths } from "date-fns";
import thLocale from "date-fns/locale/th";
import UpdateNotification from "../fixRequest/updateNotification";
import getWSRoot from "../../libs/getWSRoot";
import * as Swal from "sweetalert2";
import ComplaintCreateModal from "./complaintCreateModal";
import ExportComplaint from "./server_export_complaint";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import _ from "lodash";
import Search from "../SearchInput";
import i18n from "i18next"
import Loading from "../../libs/loading";

const query = graphql`
  query complaintListQuery(
    $first: Int
    $last: Int
    $status: String
    $search: String
    $start_date: DateTime
    $end_date: DateTime
  ) {
    allRooms(
      roomType: "contact"
      first: $first
      last: $last
      status: $status
      search: $search
      startDate: $start_date
      endDate: $end_date
    ) {
      totalCount
      edges {
        node {
          id
          numberNotRead
          residential {
            isUserActivate
            name
          }
          name
          detail
          status
          updated
          note
          handle {
            tenant {
              firstName
              lastName
              phone
            }
            juristic {
              firstName
              lastName
              phone
            }
          }
        }
      }
    }
  }
`;

let chatListSocket = {};

class ComplaintList extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.fix_request_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.reQuery = false;
    this.handleChange = this.handleChange.bind(this);
    // this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state.start_date = subMonths(new Date(), 1);
    this.state.end_date = new Date();
    this.state.temp_start_date = subMonths(new Date(), 1);
    this.state.temp_end_date = new Date();
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  // handleKeyDown(e) {
  //   if (e.key === "Enter") {
  //     this.handleChange(e);
  //   }
  // }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }
  onChangeSearch(text) {
    this.setState({ search: text });
  }

  componentWillMount() {
    UpdateNotification("contact");

    // connect ws
    chatListSocket = new WebSocket(
      getWSRoot() + "/chat_list/",
      window.localStorage.getItem("token")
    );

    let _this = this;
    chatListSocket.onmessage = function (e) {
      let data = JSON.parse(e.data);
      if (data.room_detail.id) {
        _this.setState({ reQuery: !_this.state.reQuery });
      }
    };

    chatListSocket.onclose = function (event) {
      if (event.code !== 1000 && event.code !== 1006) {
        Swal.fire({
          title: i18n.t("complaint:update_sys_fail"),
          text: i18n.t("complaint:connection_fail"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: i18n.t("complaint:Try again"),
          cancelButtonText: i18n.t("complaint:close"),
        }).then((result) => {
          if (result.value) {
            window.location.reload();
          }
        });
      }
    };
  }

  componentWillUnmount() {
    chatListSocket.close();
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <ContactTopMenuNavigation mini={true} />
          <div className="container-fluid box" id="fixRequest">
            <div className="row justify-content-between">
              <div className="col">
                <h3>
                  <Link to="/contact">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{t("complaint:complaint_list")}</span>}
                  </Translation>
                </h3>
              </div>
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "contact_chat_create",
                }) && <ComplaintCreateModal />}
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "contact_chat_print",
                }) && (
                  <ExportComplaint
                    state={this.state}
                    status={this.props.match.params.status}
                  />
                )}
              </div>
            </div>

            <Translation>
              {t=>
            <div className="content-inner">
              <Navigation />

              <div className="row mb-2 mt-4">
                <div className="col-2"></div>
              <div className="col-7">
                <div className="form-inline float-right">
                  <div className="form-group mb-2">
                    <label htmlFor="end_date" className="ml-3 mr-3">
                      {t("press_release:sort_by_date")}
                    </label>
                    <DatePickerAdapter
                      id="temp_start_date"
                      className="form-control"
                      name="temp_start_date"
                      selected={this.state.temp_start_date}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="end_date" className="ml-3 mr-3">
                      {t("press_release:to")}
                    </label>
                    <DatePickerAdapter
                      id="temp_end_date"
                      name="temp_end_date"
                      className="form-control"
                      selected={this.state.temp_end_date}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-3">
                <Search
                  className="col-8"
                  callback_function={this.onChangeSearch}
                  search={this.state.search}
                  handleSearch={this.handleSearch}
                  temp_end_date={this.state.temp_end_date}
                  temp_start_date={this.state.temp_start_date}
                />
              </div>
              </div>

              <QueryRenderer
                environment={environment}
                query={query}
                cacheConfig={{ use_cache: false }}
                variables={{
                  first: this.state.first,
                  last: this.state.last,
                  status: this.props.match.params.status,
                  fix_type: this.state.fix_request_type,
                  start_date: this.state.start_date,
                  end_date: this.state.end_date,
                  search: this.state.search,
                  reQuery: this.state.reQuery,
                }}
                render={({ error, props }) => {                  
                  if (error) {
                    return (
                      <div className="alert alert-danger" role="alert">
                        {error.message}
                      </div>
                    );
                  } else if (props) {
                    return (
                      <React.Fragment>
                        {props.allRooms.edges.map((room) => {
                          let handle = {};
                          if (room.node.handle.juristic != null) {
                            handle = room.node.handle.juristic;
                          } else {
                            handle = room.node.handle.tenant;
                          }

                          return (
                            <div
                              className="card show mt-2 fade-up"
                              key={room.node.id}
                            >
                              <div className="card-body p-4">
                                <div className="row">
                                  <div className="col-md-2">
                                    <h6>{room.node.residential.name} {room.node.residential.isUserActivate && <div className="green-dot" />}</h6>
                                    {handle !== null ? (
                                      <p>
                                        {handle.firstName} {handle.lastName}                                        
                                      </p>
                                    ) : (
                                      <p>-</p>
                                    )}
                                  </div>
                                  <div className="col-md-7">
                                    <Link
                                      to={
                                        "/contact/complaint/chat/complaint/" +
                                        room.node.id
                                      }
                                    >
                                      <h6 className="mt-1">
                                        {room.node.name.length >= 80
                                          ? room.node.name.substring(0, 80) +
                                            "..."
                                          : room.node.name}
                                      </h6>
                                    </Link>
                                  </div>

                                  <div className="col-md-3 text-right">
                                    <p className="grey-color">
                                      {format(
                                        room.node.updated,
                                        `DD/MM/YYYY - HH:mm ${i18n.t("Allaction:A")}`,
                                        { locale: thLocale }
                                      )}
                                    </p>
                                    {room.node.numberNotRead !== 0 &&
                                      room.node.numberNotRead !== null && (
                                        <p className="badge mr-4">
                                          {room.node.numberNotRead}
                                        </p>
                                      )}
                                    {this.props.match.params.status !==
                                      "finished" &&
                                      room.node.numberNotRead === null && (
                                        <p className="badge mr-4">new</p>
                                      )}
                                    {/*<img*/}
                                    {/*    src={process.env.PUBLIC_URL + '/images/icons/choice.png'}*/}
                                    {/*    alt="choice-icon" className="show-icon"/>*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="row">
                          <Pagination
                            changePage={this.changePage}
                            first={this.state.first}
                            last={this.state.last}
                            totalCount={props.allRooms.totalCount}
                          />
                        </div>
                      </React.Fragment>
                    );
                  }
                  return <Loading/>
                }}
              />
            </div>
            }
            </Translation>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ComplaintList;
